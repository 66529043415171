"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611105;
const slug = exports.slug = 'reference/cms/hubl/filters';
const title = exports.title = 'Filtros de HubL';
const name = exports.name = 'Filtros de HubL';
const metaDescription = exports.metaDescription = 'Lista de filtros de HubL y ejemplos para desarrolladores de CMS de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "abs",
  "label": "abs",
  "parent": null
}, {
  "depth": 0,
  "id": "add",
  "label": "add",
  "parent": null
}, {
  "depth": 0,
  "id": "attr",
  "label": "attr",
  "parent": null
}, {
  "depth": 0,
  "id": "batch",
  "label": "batch",
  "parent": null
}, {
  "depth": 0,
  "id": "between_times",
  "label": "between_times",
  "parent": null
}, {
  "depth": 0,
  "id": "bool",
  "label": "bool",
  "parent": null
}, {
  "depth": 0,
  "id": "capitalize",
  "label": "capitalize",
  "parent": null
}, {
  "depth": 0,
  "id": "center",
  "label": "center",
  "parent": null
}, {
  "depth": 0,
  "id": "convert_rgb",
  "label": "convert_rgb",
  "parent": null
}, {
  "depth": 0,
  "id": "cut",
  "label": "cut",
  "parent": null
}, {
  "depth": 0,
  "id": "datetimeformat-obsoleto",
  "label": "datetimeformat (obsoleto)",
  "parent": null
}, {
  "depth": 0,
  "id": "default",
  "label": "default",
  "parent": null
}, {
  "depth": 0,
  "id": "dictsort",
  "label": "dictsort",
  "parent": null
}, {
  "depth": 0,
  "id": "difference",
  "label": "difference",
  "parent": null
}, {
  "depth": 0,
  "id": "divide",
  "label": "divide",
  "parent": null
}, {
  "depth": 0,
  "id": "divisible",
  "label": "divisible",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_html",
  "label": "escape_html",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_attr",
  "label": "escape_attr",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_jinjava",
  "label": "escape_jinjava",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_js",
  "label": "escape_js",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_url",
  "label": "escape_url",
  "parent": null
}, {
  "depth": 0,
  "id": "escapejson",
  "label": "escapejson",
  "parent": null
}, {
  "depth": 0,
  "id": "filesizeformat",
  "label": "filesizeformat",
  "parent": null
}, {
  "depth": 0,
  "id": "first",
  "label": "first",
  "parent": null
}, {
  "depth": 0,
  "id": "float",
  "label": "float",
  "parent": null
}, {
  "depth": 0,
  "id": "forceescape",
  "label": "forceescape",
  "parent": null
}, {
  "depth": 0,
  "id": "format",
  "label": "format",
  "parent": null
}, {
  "depth": 0,
  "id": "format_currency-obsoleto",
  "label": "format_currency (obsoleto)",
  "parent": null
}, {
  "depth": 0,
  "id": "format_currency_value",
  "label": "format_currency_value",
  "parent": null
}, {
  "depth": 0,
  "id": "format_date",
  "label": "format_date",
  "parent": null
}, {
  "depth": 0,
  "id": "format_datetime",
  "label": "format_datetime",
  "parent": null
}, {
  "depth": 0,
  "id": "format_time",
  "label": "format_time",
  "parent": null
}, {
  "depth": 0,
  "id": "fromjson",
  "label": "fromjson",
  "parent": null
}, {
  "depth": 0,
  "id": "geo_distance",
  "label": "geo_distance",
  "parent": null
}, {
  "depth": 0,
  "id": "groupby",
  "label": "groupby",
  "parent": null
}, {
  "depth": 0,
  "id": "indent",
  "label": "indent",
  "parent": null
}, {
  "depth": 0,
  "id": "int",
  "label": "int",
  "parent": null
}, {
  "depth": 0,
  "id": "intersect",
  "label": "intersect",
  "parent": null
}, {
  "depth": 0,
  "id": "ipaddr",
  "label": "ipaddr",
  "parent": null
}, {
  "depth": 0,
  "id": "join",
  "label": "join",
  "parent": null
}, {
  "depth": 0,
  "id": "last",
  "label": "last",
  "parent": null
}, {
  "depth": 0,
  "id": "length",
  "label": "length",
  "parent": null
}, {
  "depth": 0,
  "id": "list",
  "label": "list",
  "parent": null
}, {
  "depth": 0,
  "id": "log",
  "label": "log",
  "parent": null
}, {
  "depth": 0,
  "id": "lower",
  "label": "lower",
  "parent": null
}, {
  "depth": 0,
  "id": "map",
  "label": "map",
  "parent": null
}, {
  "depth": 0,
  "id": "md5",
  "label": "md5",
  "parent": null
}, {
  "depth": 0,
  "id": "minus_time",
  "label": "minus_time",
  "parent": null
}, {
  "depth": 0,
  "id": "multiply",
  "label": "multiply",
  "parent": null
}, {
  "depth": 0,
  "id": "plus_time",
  "label": "plus_time",
  "parent": null
}, {
  "depth": 0,
  "id": "pprint",
  "label": "pprint",
  "parent": null
}, {
  "depth": 0,
  "id": "random",
  "label": "random",
  "parent": null
}, {
  "depth": 0,
  "id": "regex_replace",
  "label": "regex_replace",
  "parent": null
}, {
  "depth": 0,
  "id": "reject",
  "label": "reject",
  "parent": null
}, {
  "depth": 0,
  "id": "rejectattr",
  "label": "rejectattr",
  "parent": null
}, {
  "depth": 0,
  "id": "render",
  "label": "render",
  "parent": null
}, {
  "depth": 0,
  "id": "replace",
  "label": "replace",
  "parent": null
}, {
  "depth": 0,
  "id": "reverse",
  "label": "reverse",
  "parent": null
}, {
  "depth": 0,
  "id": "root",
  "label": "root",
  "parent": null
}, {
  "depth": 0,
  "id": "round",
  "label": "round",
  "parent": null
}, {
  "depth": 0,
  "id": "safe",
  "label": "safe",
  "parent": null
}, {
  "depth": 0,
  "id": "sanitize_html",
  "label": "sanitize_html",
  "parent": null
}, {
  "depth": 0,
  "id": "select",
  "label": "select",
  "parent": null
}, {
  "depth": 0,
  "id": "selectattr",
  "label": "selectattr",
  "parent": null
}, {
  "depth": 0,
  "id": "shuffle",
  "label": "shuffle",
  "parent": null
}, {
  "depth": 0,
  "id": "slice",
  "label": "slice",
  "parent": null
}, {
  "depth": 0,
  "id": "sort",
  "label": "sort",
  "parent": null
}, {
  "depth": 0,
  "id": "split",
  "label": "split",
  "parent": null
}, {
  "depth": 0,
  "id": "string",
  "label": "string",
  "parent": null
}, {
  "depth": 0,
  "id": "striptags",
  "label": "striptags",
  "parent": null
}, {
  "depth": 0,
  "id": "strtotime",
  "label": "strtotime",
  "parent": null
}, {
  "depth": 0,
  "id": "sum",
  "label": "sum",
  "parent": null
}, {
  "depth": 0,
  "id": "symmetric_difference",
  "label": "symmetric_difference",
  "parent": null
}, {
  "depth": 0,
  "id": "title",
  "label": "title",
  "parent": null
}, {
  "depth": 0,
  "id": "tojson",
  "label": "tojson",
  "parent": null
}, {
  "depth": 0,
  "id": "trim",
  "label": "trim",
  "parent": null
}, {
  "depth": 0,
  "id": "truncate",
  "label": "truncate",
  "parent": null
}, {
  "depth": 0,
  "id": "truncatehtml",
  "label": "truncatehtml",
  "parent": null
}, {
  "depth": 0,
  "id": "unescape_html",
  "label": "unescape_html",
  "parent": null
}, {
  "depth": 0,
  "id": "union",
  "label": "union",
  "parent": null
}, {
  "depth": 0,
  "id": "unique",
  "label": "unique",
  "parent": null
}, {
  "depth": 0,
  "id": "unixtimestamp",
  "label": "unixtimestamp",
  "parent": null
}, {
  "depth": 0,
  "id": "upper",
  "label": "upper",
  "parent": null
}, {
  "depth": 0,
  "id": "urlencode",
  "label": "urlencode",
  "parent": null
}, {
  "depth": 0,
  "id": "urldecode",
  "label": "urldecode",
  "parent": null
}, {
  "depth": 0,
  "id": "urlize",
  "label": "urlize",
  "parent": null
}, {
  "depth": 0,
  "id": "wordcount",
  "label": "wordcount",
  "parent": null
}, {
  "depth": 0,
  "id": "wordwrap",
  "label": "wordwrap",
  "parent": null
}, {
  "depth": 0,
  "id": "xmlattr",
  "label": "xmlattr",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      strong: "strong",
      a: "a",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Filtros de HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los filtros afectan la salida final de tu HubL. Pueden aplicarse a varias sentencias y expresiones de HubL para alterar el marcador de plantilla que emite el servidor."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La sintaxis básica de un filtro es ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|filtername"
      }), ". El filtro se agrega directamente a continuación de la sentencia o de la expresión, dentro de sus delimitadores. Algunos filtros tienen parámetros adicionales que se pueden agregar entre paréntesis. La sintaxis básica de un filtro con una cadena, un número y un parámetro booleano es: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|filtername(\"stringParameter\", 10, true)"
      }), ". Ten en cuenta que los parámetros de cadena deben escribirse entre comillas. También hay que tener en cuenta que los filtros de HubL tienen un alias que puede ser utilizado para servir el mismo propósito que el filtro principal."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El siguiente artículo contiene todos los filtros HubL compatibles."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " puedes aplicar filtros de HubL a los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/personalize-your-content",
          children: "tokens de personalización"
        }), ", como tokens de contacto y de empresa, en las páginas de blog y CMS de HubSpot, pero ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " en los correos electrónicos."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "abs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Obtiene el valor absoluto de un número Puedes usar este filtro para garantizar que un número sea positivo."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_number = -53 %}\n{{ my_number|abs }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "add"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agrega un valor numérico a otro valor numérico. Este filtro funciona igual que el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#math",
        children: "operador +"
      }), ". El parámetro entre paréntesis es el sumando que estás combinando con tu valor numérico inicial."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 40 %}\n{{ my_num|add(13) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "attr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renderiza el atributo de un diccionario. Este filtro es el equivalente a imprimir una variable, que existe dentro de un diccionario, como contenido.absolute_url"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica el atributo a imprimir"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content|attr(\"absolute_url\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "https://developers.hubspot.com/docs/cms/hubl/filters\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "batch"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un filtro por lotes agrupa elementos dentro de una secuencia."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "linecount"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número de elementos a incluir en el lote"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "fill_with"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica lo que se debe incluir para completar los elementos que faltan"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el ejemplo siguiente, hay una variable que contiene una secuencia de tipos de frutas. El filtro por lotes se aplica a un bucle que itera a través de la secuencia. El bucle anidado se ejecuta tres veces para imprimir 3 tipos de fruta por fila, antes de que el bucle exterior se ejecute de nuevo. Observa en la salida final que, como sólo hay 5 tipos de fruta, el último elemento se sustituye por un &nbsp (el segundo parámetro)."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set rows = [\"apples\", \"oranges\", \"pears\", \"grapes\", \"blueberries\"] %}\n\n<table>\n{% for row in rows|batch(3, \" \") %}\n   <tr>\n    {% for column in row %}\n        <td>{{ column }}</td>\n    {% endfor %}\n    </tr>\n{% endfor %}\n</table>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<table>\n  <tbody>\n    <tr>\n      <td>apples</td>\n      <td>oranges</td>\n      <td>pears</td>\n    </tr>\n    <tr>\n      <td>grapes</td>\n      <td>blueberries</td>\n      <td></td>\n    </tr>\n  </tbody>\n</table>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "between_times"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcula el tiempo entre dos objetos datetime en una unidad de tiempo especificada."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debes usar este filtro ", (0, _jsxRuntime.jsx)("u", {
          children: "solo"
        }), " con variables que devuelvan una fecha. A partir del 30 de septiembre de 2024, este filtro ya no devolverá la fecha actual cuando se pase un valor nulo. Después de esa fecha, un valor nulo en el filtro devolverá el 30 de septiembre de 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El objeto datetime final"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Las unidades de tiempo válidas son ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set begin = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{% set end = \"2018-07-20T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ begin|between_times(end, \"days\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "6\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "bool"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convierte un valor de cadena de texto en un booleano."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if \"true\"|bool == true %}hello world{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "hello world\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "capitalize"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Escribe en mayúsculas la primera letra de un valor variable. El primer carácter será mayúscula, todas las demás letras serán minúsculas. Las palabras siguientes separadas por espacios o guiones no tendrán la primera letra en mayúscula."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set sentence = \"the first letter of a sentence should always be capitalized.\" %}\n{{ sentence|capitalize }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The first letter of a sentence should always be capitalized.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "center"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El filtro de centrado utiliza los espacios en blanco para centrar el texto dentro de una longitud de campo determinada. Este filtro no es recomendable ni especialmente útil, ya que el compilador HTML de HubSpot eliminará automáticamente los espacios en blanco; sin embargo, se incluye aquí en aras de la exhaustividad."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica la longitud del espacio en blanco para centrar el texto."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El ejemplo siguiente muestra un filtro de centrado aplicado a una variable en una etiqueta pre, de modo que no se eliminan los espacios en blanco."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<pre>\n{% set var = \"string to center\" %}\nbefore{{ var|center(80) }}after\n</pre>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<pre>\nbefore                                string to center                                after\n</pre>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "convert_rgb"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convierte un valor HEX en una cadena RGB. Esto es útil si necesitas convertir las variables de color a RGB para utilizarlas con una declaración CSS RGBA. En el ejemplo siguiente, el valor establecido por un módulo de color se convierte en un valor RGB y se utiliza en una declaración CSS RGBA."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_color = \"#FFFFFF\" %}\n{{ my_color|convert_rgb }}\n\n{% color \"my_color\" color=\"#000000\", export_to_template_context=True %}\n<div style=\"background: rgba({{ widget_data.my_color.color|convert_rgb }}, .5)\"></div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "255, 255, 255\n\n<div style=\"background: rgba(0, 0, 0, .5)\"></div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "cut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Elimina una cadena de un valor. Este filtro se puede utilizar para hacer coincidir y cortar una parte específica de una cadena. El parámetro especifica la parte de la cadena que debe ser eliminada. El ejemplo siguiente elimina el espacio y la palabra world del valor original de la variable."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "characters_to_cut"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La parte de la cadena que debe ser eliminada."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_string = \"Hello world.\" %}\n{{ my_string|cut(\" world\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Hello.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "datetimeformat (obsoleto)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": este filtro está ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/deprecated#datetimeformat",
          children: "obsoleto"
        }), ". En su lugar, usa el filtro ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-datetime",
          children: "format_datetime"
        }), ", que tiene una sintaxis más estandarizada."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "default"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si el valor es indefinido devolverá el primer parámetro, en caso contrario se imprimirá el valor de la variable. Si quieres usar default con variables que se evalúan como falsas, tienes que poner el segundo parámetro como ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "true"
      }), ". El primer ejemplo de abajo imprimiría el mensaje si la variable no está definida. El segundo ejemplo aplicó el filtro a una cadena vacía, que no es indefinida, pero imprime un mensaje debido al segundo parámetro."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default_variable"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor que se devolverá si la variable es indefinida. Si la variable está definida, se devolverá el valor de la variable en su lugar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Devuelve el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_value"
            }), " si la variable es una cadena vacía"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ my_variable|default(\"my_variable is not defined\") }}\n{{ \"\"|default(\"the string was empty\", true) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "my_variable is not defined the string was empty\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dictsort"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ordena un dict y produce pares (clave, valor). Los diccionarios no están ordenados por opción predeterminada, pero se puede imprimir un diccionario ordenado por clave o valor. El primer parámetro es un booleano para determinar si la ordenación distingue entre mayúsculas y minúsculas. El segundo parámetro determina si se ordena el dict por clave o por valor. El ejemplo siguiente imprime un diccionario de contactos ordenado, con todos los detalles conocidos sobre el contacto."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "case_sensitive"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Determina si la ordenación distingue entre mayúsculas y minúsculas"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sort_by"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Determina si se ordena por ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "key"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for item in contact|dictsort(false, \"value\") %}\n    {{item}}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A sorted contact dictionary\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "difference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro devuelve la diferencia de dos conjuntos o listas. La lista devuelta por el filtro contiene todos los elementos únicos que están en la primera lista pero no en la segunda."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La segunda lista con la que se comparará para encontrar diferencias con respecto a la lista original."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|difference([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "divide"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Divide el valor actual entre un divisor. El parámetro pasado es el divisor. Este filtro es una alternativa al operador /."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "divisor"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número entre el que se dividirá la variable."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numerator = 106 %}\n{{ numerator|divide(2) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "divisible"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una alternativa a la prueba de divisible por expresión, el filtro divisible se evaluará como verdadero si el valor es divisible entre el número dado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "divisor"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número a utilizar cuando se evalúa si el valor es divisible."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set num = 10 %}\n{% if num|divisible(2) %}\nThe number is divisble by 2\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The number is divisible by 2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Escapa el contenido de una entrada HTML. Acepta una cadena y convierte los caracteres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ">"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "‘"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "”"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " en secuencias seguras para HTML. Utiliza este filtro para las variables HubL que se usan en HTML pero que no deberían permitir ningún HTML."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<div>This markup is printed as text</div>\" %}\n{{ escape_string|escape_html }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div>This markup is printed as text</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_attr"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Escapa el contenido de una entrada de atributo HTML. Acepta una cadena y convierte los caracteres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "‘"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "”"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " en secuencias seguras para HTML. Utiliza este filtro para las variables HubL que se están agregando a los atributos HTML."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ten en cuenta que cuando escapes valores de atributos que acepten URL, como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "href"
      }), ", deberías usar el filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_url"
      }), " en su lugar."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"This <br> markup is printed as text\" %}\n<img src=\"test.com/imageurl\" alt=\"{{escape_string|escape_attr}}\">\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<img src=\"test.com/imageurl\" alt=\"This <br> markup is printed as text\" />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_jinjava"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Convierte los caracteres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "}"
      }), " en cadenas en secuencias seguras para Jinjava. Utiliza este filtro si necesitas mostrar un texto que pueda contener dichos caracteres en Jinjava."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"{{This markup is printed as text}}\" %}\n{{ escape_string|escape_jinjava }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{This markup is printed as text}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Escapa cadenas, incluyendo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), ", para que puedan ser insertadas con seguridad en una declaración de variable de JavaScript. utiliza este filtro para las variables HubL que se usan dentro de los elementos de script HTML."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"\\tThey said 'This string can safely be inserted into JavaScript.'\" %}\n{{ escape_string|escape_js }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "\\tThey said \\x27This string can safely be inserted into JavaScript.\\x27\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_url"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Escapa el contenido de una entrada de URL, aplicando protocolos especificados, eliminando caracteres no válidos y peligrosos, y codifica entidades HTML. Devuelve vacío si una URL es válida. Utiliza este filtro para las variables HubL que se usan dentro de los atributos HTML que deberían ser URL válidas."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"https://www.google.com<\" %}\n <a href=\"{{ escape_string|escape_url }}\">\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a href=\"\"></a>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escapejson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Escapa cadenas para que puedan ser utilizadas como valores JSON."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<script>alert('oh no!')</script>\" %}\n{% require_js position=\"head\" %}\n<script data-search_input-config=\"config_{{ name }}\" type=\"application/json\">\n{\n  \"autosuggest_results_message\": \"{{ escape_string|escapejson }}\"\n}\n</script>\n{% end_require_js %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<script\n  data-search_input-config=\"config_widget_1234567\"\n  type=\"application/json\"\n>\n  {\n    \"autosuggest_results_message\": \"<script>alert('oh no!')<\\/script>\"\n  }\n</script>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "filesizeformat"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formatea el valor como un tamaño de archivo \"legible para el ser humano\" (es decir, 13 kB, 4,1 MB, 102 Bytes, etcétera). Por opción predeterminada se utilizan prefijos decimales (Mega, Giga, etc.), si el parámetro se establece en True se utilizan los prefijos binarios (Mebi, Gibi)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Si se establece en true, se utilizan prefijos binarios como Mebi y Gibi."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bytes = 100000 %}\n{{ bytes|filesizeformat }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "100.0 KB\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "first"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Devuelve el primer elemento de una secuencia."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{{ my_sequence|first }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Item 1\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convierte el valor en un número de punto flotante. Si la conversión no funciona, devolverá 0,0. Puedes anular este valor predeterminado utilizando el primer parámetro."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entero que se devolverá si la conversión no funciona."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_text\" value=\"25\", export_to_template_context=True %}\n{{ widget_data.my_text.value|float + 28 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "forceescape"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aplicar estrictamente el escape HTML. En el entorno de HubSpot no hay realmente un caso de uso para el doble escape, por lo que esto se comporta generalmente igual que el filtro de escape."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<div>This markup is printed as text</div>\" %}\n{{ escape_string|forceescape }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div>This markup is printed as text</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aplica el formato de cadena de Python a un objeto. %s puede ser sustituido por otra variable."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"Hi %s %s\"|format(contact.firstname, contact.lastname) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Hi Brian Halligan\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_currency (obsoleto)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": este filtro está ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/deprecated",
          children: "obsoleto"
        }), ". En su lugar, utiliza el filtro ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-currency-value",
          children: "format_currency_value"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_currency_value"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formatea un número dado como moneda basándose en la moneda predeterminada del portal y en la configuración regional pasada como parámetro. Reemplaza el filtro obsoleto ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/deprecated#format-currency",
        children: "format_currency"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 100 | format_currency_value(locale='en-GB', currency='EUR', maxDecimalDigits=6, minDecimalDigits=1) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "€100.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.oracle.com/java/technologies/javase/jdk8-jre8-suported-locales.html",
              children: "La etiqueta de lenguaje local de Java"
            }), ". El valor predeterminado es la configuración regional de la página. Formato: ISO639LanguageCodeInLowercase-ISO3166CountryCodeInUppercase"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["el ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_4217",
              children: "código ISO 4217 alfabético"
            }), " de la divisa, por opción predeterminada, es la divisa predeterminada del portal. No se aceptan códigos numéricos."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "minDecimalDigits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número mínimo de dígitos decimales a utilizar. El valor predeterminado es el número predeterminado de dígitos decimales de la moneda."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "maxDecimalDigits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número máximo de dígitos decimales a utilizar. El valor predeterminado es el número predeterminado de dígitos decimales de la moneda."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_date"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formatea el componente de fecha de un objeto fecha."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debes usar este filtro ", (0, _jsxRuntime.jsx)("u", {
          children: "solo"
        }), " con variables que devuelvan una fecha. A partir del 30 de septiembre de 2024, este filtro ya no devolverá la fecha actual cuando se pase un valor nulo. Después de esa fecha, un valor nulo en el filtro devolverá el 30 de septiembre de 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El formato que se va a utilizar. Puede ser uno de los siguientes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full``medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long``short"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["un patrón personalizado que se basa en ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La zona horaria de la fecha de salida en ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "formato IANA TZDB"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La configuración regional que se utilizará en los formatos adaptados a la configuración regional."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date | format_date('long') }}\n{{ content.publish_date | format_date('yyyyy.MMMM.dd') }}\n{{ content.publish_date | format_date('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "November 28, 2022 02022.November.28 28.11.2022\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_datetime"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formatea los componentes de fecha y hora de un objeto fecha. El filtro reemplaza el filtro obsoleto ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/deprecated#datetimeformat-nbsp-",
        children: "datetimeformat"
      }), ". De manera predeterminada, devuelve una fecha y hora en la zona horaria UTC-00:00."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debes usar este filtro ", (0, _jsxRuntime.jsx)("u", {
          children: "solo"
        }), " con variables que devuelvan una fecha. A partir del 30 de septiembre de 2024, este filtro ya no devolverá la fecha actual cuando se pase un valor nulo. Después de esa fecha, un valor nulo en el filtro devolverá el 30 de septiembre de 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El formato que se va a utilizar. Puede ser uno de los siguientes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["un patrón personalizado que sigue ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            }), "Cuando se utiliza ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "long"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "full"
            }), ", la marca de tiempo incluirá una Z para indicar la hora UTC de desplazamiento cero (es decir, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2:23:00 PM Z"
            }), "). Para eliminar el designador Z, especifica un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La zona horaria de la fecha de salida en ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "formato IANA TZDB"
            }), ". De forma predeterminada, devuelve la hora UTC."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La configuración regional que se utilizará en los formatos adaptados a la configuración regional."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date | format_datetime('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "12/31/69 7:00 PM\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formatea el componente de hora de un objeto fecha."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debes usar este filtro ", (0, _jsxRuntime.jsx)("u", {
          children: "solo"
        }), " con variables que devuelvan una fecha. A partir del 30 de septiembre de 2024, este filtro ya no devolverá la fecha actual cuando se pase un valor nulo. Después de esa fecha, un valor nulo en el filtro devolverá el 30 de septiembre de 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El formato que se va a utilizar. Puede ser uno de los siguientes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full``medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long``short"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["un patrón personalizado que se basa en ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La zona horaria de la fecha de salida en ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "formato IANA TZDB"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La configuración regional que se utilizará en los formatos adaptados a la configuración regional."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.updated | format_time('long') }}\n{{ content.updated | format_time('hh:mm a') }}\n{{ content.updated | format_time('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "3:25:06 PM Z 03:25 PM 10:25:44\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "fromjson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convierte una cadena JSON en un objeto."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set obj =\"{ \"name\":\"Brian\",\"role\":\"Owner\" }\" %}\n{{ obj|fromjson }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{role=Owner, name=Brian}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "geo_distance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcula la distancia 2D elipsoidal entre dos puntos de la Tierra."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<!-- in the example below\nthe HubDB Location =\n42.3667, -71.1060 (Cambridge, MA) |\nChicago, IL = 37.3435, -122.0344 -->\n{{ row.location | geo_distance(37.3435, -122.0344, \"mi\") }} MI\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "861.1655563461395 MI\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "groupby"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El filtro groupby agrupa una secuencia de objetos por un atributo en común. El parámetro establece el atributo común en agrupar por."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El atributo por el que se va a agrupar."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<ul>\n{% for group in contents|groupby(\"blog_post_author\") %}\n    <li>{{ group.grouper }}\n      <ul>\n        {% for content in group.list %}\n          <li>{{ content.name }}</li>\n        {% endfor %}\n      </ul>\n    </li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    Blog author 1\n    <ul>\n      <li>Post by Blog author 1</li>\n      <li></li>\n      <li>Post by Blog author 1</li>\n      <li></li>\n      <li>Post by Blog author 1</li>\n      <li></li>\n    </ul>\n  </li>\n  <li>\n    Blog author 2\n    <ul>\n      <li>Post by Blog author 2</li>\n      <li></li>\n      <li>Post by Blog author 2</li>\n      <li></li>\n      <li>Post by Blog author 2</li>\n      <li></li>\n    </ul>\n  </li>\n  <li>\n    Blog author 3\n    <ul>\n      <li>Post by Blog author 3</li>\n      <li></li>\n      <li>Post by Blog author 3</li>\n      <li></li>\n      <li>Post by Blog author 3</li>\n      <li></li>\n    </ul>\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "indent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El filtro de sangría utiliza espacios en blanco para sangrar el texto dentro de una longitud de campo determinada. Este filtro no es recomendable ni especialmente útil, ya que el compilador HTML de HubSpot eliminará automáticamente los espacios en blanco; sin embargo, se incluye aquí en aras de la exhaustividad. El ejemplo siguiente muestra un filtro de centrado aplicado a una variable en una etiqueta pre, de modo que no se eliminan los espacios en blanco. El primer parámetro controla la cantidad de espacios en blanco y el segundo booleano cambia la sangría de la primera línea."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La cantidad de espacios en blanco que se aplicará."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor booleano sobre si se debe sangrar la primera línea."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<pre>\n{% set var = \"string to indent\" %}\n{{ var|indent(2, true) }}\n</pre>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "string to indent\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "int"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convierte el valor en un entero. Si la conversión no funciona, devolverá 0. Puedes anular este valor predeterminado utilizando el primer parámetro."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entero que se devolverá si la conversión no funciona."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_text\" value=\"25\", export_to_template_context=True %}\n{{ widget_data.my_text.value|int + 28 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "intersect"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro devuelve la intersección de dos conjuntos o listas. La lista devuelta por el filtro contiene todos los elementos únicos que están contenidos en ambas listas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La segunda lista con la que se comparará para encontrar el punto de intersección de la lista con la lista original."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|intersect([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[2, 3]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ipaddr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se evalúa como verdadero si el valor es una dirección IPv4 o IPv6 válida."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set ip = \"1.0.0.1\" %}\n{% if ip|ipaddr %}\n    The string is a valid IP address\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The string is a valid IP address\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "join"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Devuelve una cadena que es la concatenación de las cadenas de la secuencia. El separador entre elementos es una cadena vacía por opción predeterminada, puedes definirlo con el parámetro opcional. El segundo parámetro puede utilizarse para especificar un atributo a unir."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "delimiter"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El delimitador a utilizar al concatenar cadenas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Variable de HubL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Atributo de valor para unir en un objeto."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_list = [1, 2, 3] %}\n{% set sep = \"---\" %}\n{{ my_list|join }}\n{{ my_list|join(\"|\") }}\n{{ my_list|join(sep) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "123 1|2|3 1---2---3\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "last"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Devuelve el último elemento de una secuencia."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{% my_sequence|last %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Item 3\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "length"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Devuelve el número de elementos de una secuencia o mapeo."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set services = [\"Web design\", \"SEO\", \"Inbound Marketing\", \"PPC\"] %}\n{{ services|length }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "4\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "list"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convierte los valores numéricos en una lista. Si se trata de una cadena, la lista devuelta será una lista de caracteres. Para agregar cadenas a una secuencia, simplemente agrégalas a las variables de cadena a los delimitadores de secuencia [ ]."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set one = 1 %}\n{% set two = 2 %}\n{% set three = 3 %}\n{% set four = [\"four\"] %}\n{% set list_num = one|list + two|list + three|list + four|list %}\n{{ list_num }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1,2,3]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "log"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcula el logaritmo natural de un número."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "base"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Calcula el logaritmo a la n-ésima base."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 10|log }}\n{{ 65536|log(2) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2.302585092994046 16.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertir un valor a todas las letras minúsculas."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ text \"text\" value=\"Text to MAKE Lowercase\", export_to_template_context=True }}\n{{ widget_data.text.value|lower }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "text to make lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "map"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aplica un filtro a una secuencia de objetos o busca un atributo. Esto es útil cuando se trata con listas de objetos, pero en realidad sólo te interesa un valor determinado de la misma."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El uso básico es el mapeo en un atributo. Por ejemplo, si deseas utilizar la lógica condicional para comprobar si un valor está presente en un atributo particular de un dict. Alternativamente, puedes dejar que invoque un filtro pasando el nombre del filtro y los argumentos a continuación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Atributo a devolver en la secuencia de objetos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Filtro a aplicar a la secuencia de objetos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set seq = [\"item1\", \"item2\", \"item3\"] %}\n{{ seq|map(\"upper\") }}\n{{ content|map(\"currentState\")}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[ITEM1, ITEM2, ITEM3] DRAFT\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "md5"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Calcula el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/MD5",
        children: "hash md5"
      }), " del objeto dado"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.absolute_url|md5 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "923adb4ce05a4c6342c04c80be88d15e\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "minus_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Resta una cantidad de tiempo a un objeto datetime."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "diff"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cantidad que se restará."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Las unidades de tiempo válidas son ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set date = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ date }}\n{{ date|minus_time(2, \"months\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2018-07-14 14:31:30 2018-05-14 14:31:30\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "multiply"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Multiplica un valor por un número. Funciona igual que el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "operador *"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set n = 20 %}\n{{ n|multiply(3) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "60\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "plus_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agrega una cantidad de tiempo a un objeto datetime."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "diff"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cantidad que se sumará."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Las unidades de tiempo válidas son ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set date = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ date }}\n{{ date|plus_time(5, \"days\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2018-07-14 14:31:30 2018-07-19 14:31:30\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "pprint"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Imprimir una variable. Esto imprime el tipo de variable y otra información útil para la depuración."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set this_var =\"Variable that I want to debug\" %}\n{{ this_var|pprint }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(String: Variable that I want to debug)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "random"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Devuelve un elemento aleatorio de la secuencia."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " cuando uses este filtro, la página se ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "prerrenderizará"
        }), " periódicamente en lugar de cada vez que se actualice el contenido de la página. Esto significa que el contenido filtrado ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " se actualizará en cada recarga de página."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Esto puede no ser un problema para ciertos tipos de contenido, como mostrar una lista aleatoria de publicaciones de blog. Sin embargo, si necesitas que el contenido cambie al azar en cada carga de página, deberías usar JavaScript para aleatorizar el contenido del lado del cliente."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|random %}\n<div class=\"post-item\">Post item markup</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Random post</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "regex_replace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Busca un patrón de expresión regular y lo sustituye por una secuencia de caracteres. El primer argumento es un patrón de expresión regular estilo RE2, el segundo es la cadena de sustitución."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes encontrar información sobre la sintaxis de expresiones regulares RE2 ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/re2/wiki/Syntax",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"contact-us-2\"|regex_replace(\"[^a-zA-Z]\", \"\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "contactus\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "reject"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Filtra una secuencia de objetos aplicando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "prueba de expresión"
      }), " al objeto y rechazando los que tienen éxito en la prueba."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La prueba de expresión que se aplicará al objeto."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_numbers = [10, 12, 13, 3, 5, 17, 22] %}\n{{ some_numbers|reject(\"even\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[13, 3, 5, 17]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "rejectattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtra una secuencia de objetos aplicando una prueba a un atributo de un objeto y rechazando los que tienen éxito en la prueba."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica el atributo a seleccionar. Puedes acceder a los atributos anidados utilizando la notación de puntos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_test"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La expresión a probar"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "val"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor con el que se realizará la prueba."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|rejectattr(\"post_list_summary_featured_image\") %}\n<div class=\"post-item\">\n{% if content.post_list_summary_featured_image %}\n    <div class=\"hs-featured-image-wrapper\">\n            <a href=\"{{content.absolute_url}}\" title=\"\" class=\"hs-featured-image-link\">\n            <img src=\"{{ content.post_list_summary_featured_image }}\" class=\"hs-featured-image\">\n            </a>\n    </div>\n{% endif %}\n{{ content.post_list_content|safe }}\n</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Post with no featured image</div>\n<div class=\"post-item\">Post with no featured image</div>\n<div class=\"post-item\">Post with no featured image</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "render"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renderiza cadenas que contienen HubL antes de tiempo para que la salida pueda ser pasada a otros filtros."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ personalization_token(\"contact.lastname\", \"default value\")|render|lower }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "mclaren\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "replace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Reemplaza todas las instancias de una subcadena por una nueva."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "old"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La subcadena que debe ser reemplazada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "new"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena de reemplazo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "count"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Si se proporciona, solo se reemplazan las primeras ocurrencias."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if topic %}\n<h3>Posts about {{ page_meta.html_title|replace(\"Blog | \", \"\") }}</h3>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Posts about topic name</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "reverse"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Invierte el objeto o devuelve un iterador que itera sobre él al revés. Para invertir una lista utiliza ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#reverse",
        children: ".reverse()"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] %}\n{% for num in nums|reverse %}\n{{ num }}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "10 9 8 7 6 5 4 3 2 1\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "root"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Extraer la raíz cuadrada de un valor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "nth_root"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Calcula la raíz n-ésima de un número."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 16|root }}\n{{ 625|root(4) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "4 5\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "round"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Redondea el número a una precisión determinada."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "precision"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica la precisión del redondeo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "rounding_method"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Las opciones incluyen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "common"
            }), " redondea hacia arriba o hacia abajo (opción predeterminada); ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceil"
            }), " siempre redondea hacia arriba; ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "floor"
            }), " siempre redondea hacia abajo. Si no se especifica se utiliza un método ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "common"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 52.5|round }}\n{{ 52.5|round(0, \"floor\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53 52\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "safe"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Marca el valor como seguro, lo que significa que en un entorno con el escape automático activado esta variable no será escapada."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.post_list_content|safe }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>HTML post content that is not escaped.</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sanitize_html"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depura el contenido de una entrada HTML para la salida de contenido de texto enriquecido. Acepta una cadena y luego elimina las etiquetas HTML que no están permitidas. Utiliza este filtro para las variables HubL que se usan en HTML que deberían permitir HTML seguro."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al usar este filtro, puedes incluir los siguientes parámetros para permitir tipos específicos de etiquetas HTML: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORMATTING"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BLOCKS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STYLES"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LINKS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TABLES"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IMAGES"
      }), ". Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sanitize_html(IMAGES)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El uso de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sanitize_html"
      }), " incluirá todos los parámetros en el filtro."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes incluir un parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STRIP"
      }), " para eliminar ", (0, _jsxRuntime.jsx)("u", {
        children: "todo"
      }), " el HTML. Todo el contenido también se ejecuta mediante ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " para evitar la interpretación anidada."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"This <br> <div>markup is <img src='test.com/image'> <span>printed</span> as text.</div>\" %}\n{{ escape_string|sanitize_html(\"IMAGES\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This  markup is <img src=\"test.com/image\"> printed as text.</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "select"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtra una secuencia de objetos aplicando una prueba al objeto y seleccionando sólo los que tienen éxito en la prueba."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La prueba de expresión que se aplicará al objeto."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_numbers = [10, 12, 13, 3, 5, 17, 22] %}\n{{ some_numbers|select(\"even\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[10, 12, 22]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "selectattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtra una secuencia de objetos aplicando una prueba a un atributo de un objeto y seleccionando sólo los que tienen éxito en la prueba."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica el atributo a seleccionar. Puedes acceder a los atributos anidados utilizando la notación de puntos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_test"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La expresión a probar"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "val"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor con el que se realizará la prueba."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|selectattr(\"post_list_summary_featured_image\") %}\n<div class=\"post-item\">\n  {% if content.post_list_summary_featured_image %}\n    <div class=\"hs-featured-image-wrapper\">\n       <a href=\"{{content.absolute_url}}\" title=\"\" class=\"hs-featured-image-link\">\n         <img src=\"{{ content.post_list_summary_featured_image }}\" class=\"hs-featured-image\">\n       </a>\n    </div>\n  {% endif %}\n{{ content.post_list_content|safe }}\n</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">\n  <div class=\"hs-featured-image-wrapper\">\n    <a\n      href=\"http://blog.hubspot.com/marketing/how-to-get-a-job\"\n      title=\"\"\n      class=\"hs-featured-image-link\"\n    >\n      <img\n        src=\"//cdn2.hubspot.net/hub/53/hubfs/00-Blog-Related_Images/landing-a-job-featured-image.png?t=1431452322770&width=761\"\n        class=\"hs-featured-image\"\n      />\n    </a>\n  </div>\n  Post with featured image\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "shuffle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aleatoriza el orden de iteración a través de una secuencia. El ejemplo siguiente mezcla un bucle de blog estándar."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " cuando uses este filtro, la página se ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "prerrenderizará"
        }), " periódicamente en lugar de cada vez que se actualice el contenido de la página. Esto significa que el contenido filtrado ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " se actualizará en cada recarga de página."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Esto puede no ser un problema para ciertos tipos de contenido, como mostrar una lista aleatoria de publicaciones de blog. Sin embargo, si necesitas que el contenido cambie al azar en cada carga de página, deberías usar JavaScript para aleatorizar el contenido del lado del cliente."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|shuffle %}\n<div class=\"post-item\">Markup of each post</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Markup of each post 5</div>\n<div class=\"post-item\">Markup of each post 3</div>\n<div class=\"post-item\">Markup of each post 1</div>\n<div class=\"post-item\">Markup of each post 2</div>\n<div class=\"post-item\">Markup of each post 4</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "slice"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Corta un iterador y devuelve una lista de listas que contienen esos elementos. El primer parámetro especifica cuántos elementos serán cortados, y el segundo parámetro especifica los caracteres para rellenar los cortes vacíos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "slices"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuántos elementos se cortarán."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "filler"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica los caracteres para rellenar los cortes vacíos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set items = [\"laptops\", \"tablets\", \"smartphones\", \"smart watches\", \"TVs\"] %}\n<div class=\"columwrapper\">\n  {% for column in items|slice(3,\" \") %}\n    <ul class=\"column-{{ loop.index }}\">\n    {% for item in column %}\n      <li>{{ item }}</li>\n    {% endfor %}\n    </ul>\n  {% endfor %}\n</div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"columwrapper\">\n  <ul class=\"column-1\">\n    <li>laptops</li>\n    <li>tablets</li>\n    <li>smartphones</li>\n  </ul>\n\n  <ul class=\"column-2\">\n    <li>smart watches</li>\n    <li>TVs</li>\n    <li></li>\n  </ul>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sort"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ordena un iterable. Este filtro requiere que todos los parámetros se ordenen por un atributo en HubSpot. El primer parámetro es un booleano para invertir el orden de clasificación. El segundo parámetro determina si la ordenación distingue entre mayúsculas y minúsculas. Y el último parámetro especifica un atributo por el que ordenar. En el ejemplo siguiente, las entradas de un blog se representan y se ordenan alfabéticamente por nombre."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| Parámetro | Descripción |\n| --- | --- | --- |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "reverse"
      }), (0, _jsxRuntime.jsx)(Tag, {
        type: "error",
        children: "Obligatorio"
      }), " | Valor booleano para invertir el orden de clasificación. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "case_sensitive"
      }), (0, _jsxRuntime.jsx)(Tag, {
        type: "error",
        children: "Obligatorio"
      }), " | Valor booleano que determina si la ordenación distingue entre mayúsculas y minúsculas. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "attribute"
      }), (0, _jsxRuntime.jsx)(Tag, {
        type: "error",
        children: "Obligatorio"
      }), " | Atributo por el que se ordenará. | Omitir al ordenar una lista. |"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_posts = blog_recent_posts(\"default\", limit=5) %}\n\n{% for item in my_posts|sort(False, False, \"name\") %}\n{{ item.name }}<br>\n\n\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A post<br />\nB post<br />\nC post<br />\nD post<br />\nE post<br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "split"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Divide la cadena de entrada en una lista en el separador dado. El primer parámetro especifica el separador entre el que se divide la variable. El segundo parámetro determina cuántas veces debe dividirse la variable. El resto de los artículos permanecerían en el grupo. En el ejemplo siguiente, una cadena de nombres se divide en el \";\" para los 4 primeros nombres."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "character_to_split_by"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica el separador entre el que se divide la variable."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_splits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Determina cuántas veces debe dividirse la variable. Los elementos restantes permanecerían agrupados."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set string_to_split = \"Stephen; David; Cait; Nancy; Mike; Joe; Niall; Tim; Amanda\" %}\n{% set names = string_to_split|split(\";\", 4) %}\n<ul>\n{% for name in names %}\n  <li>{{ name }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Stephen</li>\n  <li>David</li>\n  <li>Cait</li>\n  <li>Nancy; Mike; Joe; Niall; Tim; Amanda</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "string"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convierte un tipo de variable diferente en una cadena. En el ejemplo siguiente, un número entero se convierte en una cadena (se utiliza pprint para confirmar el cambio de tipo de la variable)."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set number_to_string = 45 %}\n{{ number_to_string|string|pprint }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(String: 45)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "striptags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Elimina las etiquetas SGML/XML y sustituye los espacios en blanco adyacentes por un espacio. Este filtro puede utilizarse para eliminar cualquier etiqueta HTML de una variable."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_html = \"<div><strong>Some text</strong></div>\" %}\n{{ some_html|striptags }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "strtotime"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convierte una cadena datetime y un formato datetime en un objeto datetime."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "datetimeFormat"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://docs.oracle.com/javase/7/docs/api/java/text/SimpleDateFormat.html",
              children: "Patrones de fecha y hora."
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\")|unixtimestamp }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1531558890000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sum"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agrega valores numéricos en una secuencia. El primer parámetro puede especificar un atributo opcional y el segundo parámetro establece un valor a devolver si no hay nada en la variable a sumar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Atributo que se sumará."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "return_if_nothing"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor a devolver si no hay nada en la variable a sumar."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set sum_this = [1, 2, 3, 4, 5] %}\n{{ sum_this|sum }}\nTotal: {{ items|sum(attribute=\"price:\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "15 Total: 20\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "symmetric_difference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro devuelve la diferencia simétrica de dos conjuntos o listas. La lista devuelta por el filtro contiene todos los elementos únicos que están en la primera lista pero no en la segunda, o están en la segunda lista pero no en la primera"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La segunda lista con la cual comparar para encontrar la diferencia simétrica con la lista original."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|symmetric_difference([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1, 4, 5]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "title"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Devuelve una versión con título del valor. Es decir, las palabras comenzarán con letras mayúsculas, el resto de los caracteres son minúsculas."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_title\" label=\"Enter a title\", value=\"My title should be titlecase\", export_to_template_context=True %}\n{{ widget_data.my_title.value|title }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "My Title Should Be Titlecase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "tojson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Escribe un objeto como una cadena JSON."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n  {{ content.blog_post_author|tojson }}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida JSON renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 1234567,\n  \"id\": 12312253109,\n  \"created\": 1566413741989,\n  \"updated\": 1566414012799,\n  \"deletedAt\": 0,\n  \"fullName\": \"Sample User\",\n  \"email\": \"sampleUser@example.com\",\n  \"userId\": null,\n  \"username\": null,\n  \"slug\": \"sample-user\",\n  \"jsonBody\": {\n    \"avatar\": \"https://app.hubspot.com/settings/avatar/109d6874a0cb066c1c7263ac5df6ce7a\",\n    \"bio\": \"Sample Bio\",\n    \"facebook\": \"\",\n    \"linkedin\": \"\",\n    \"twitter\": \"\",\n    \"website\": \"https://www.hubspot.com\"\n  },\n  \"bio\": \"Sample Bio\",\n  \"facebook\": \"\",\n  \"linkedin\": \"\",\n  \"avatar\": \"https://app.hubspot.com/settings/avatar/109d6874a0cb066c1c7263ac5df6ce7a\",\n  \"gravatarUrl\": \"https://app.hubspot.com/settings/avatar/108bb5ac667ded34796271437dfe8d58\",\n  \"twitterUsername\": \"\",\n  \"hasSocialProfiles\": false,\n  \"website\": \"https://www.hubspot.com\",\n  \"twitter\": \"\",\n  \"displayName\": \"Sample User\"\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "trim"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Elimina los espacios en blanco iniciales y finales. HubSpot ya recorta los espacios en blanco del marcado, pero este filtro está documentado para que sea más completo."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \" remove whitespace \" }}\n{{ \" remove whitespace \"|trim }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "remove whitespace remove whitespace\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "truncate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Corta el texto después de un determinado número de caracteres. El valor predeterminado es 255. Ten en cuenta que los caracteres HTML se incluyen en este recuento. La longitud se especifica con el primer parámetro, que por opción predeterminada es 255. Si el segundo parámetro es verdadero, el filtro cortará el texto a lo largo. En caso contrario, descartará la última palabra. Si el texto se truncó, se agregará un signo de puntos suspensivos (\"...\"). Si quieres un signo de elipsis diferente a \"...\" puedes especificarlo usando el tercer parámetro."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_characters"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de caracteres para truncar el texto. Por opción predeterminada es 255."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "breakword"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor booleano. Si es verdadero, el filtro cortará el texto a lo largo. Si es falso, descartará la última palabra."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anular los caracteres finales \"...\" predeterminados después del truncamiento."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"I only want to show the first sentence. Not the second.\"|truncate(40) }}\n{{ \"I only want to show the first sentence. Not the second.\"|truncate(35, True, \"..........\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "I only want to show the first sentence. I only want to show the first\nsente..........\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "truncatehtml"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Trunca una cadena dada, respetando el marcado html (es decir, cerrará correctamente todas las etiquetas anidadas). Esto evitará que una etiqueta permanezca abierta después del truncamiento. Los caracteres HTML no cuentan para el total de caracteres. Este filtro tiene un parámetro de longitud y un parámetro de símbolo de truncamiento. Hay un tercer parámetro booleano que especifica si las palabras se romperán a lo largo. Este parámetro es falso por opción predeterminada para preservar la longitud de las palabras. Si sólo utilizas uno de los parámetros opcionales, utiliza argumentos de palabra clave, como truncatehtml(70, breakwords = false)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_characters"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de caracteres para truncar el texto. Por opción predeterminada es 255."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anular los caracteres finales \"...\" predeterminados después del truncamiento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "breakword"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor booleano. Si es verdadero, el filtro cortará el texto a lo largo. Si es falso, descartará la última palabra."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set html_text = \"<p>I want to truncate this text without breaking my HTML<p>\" %}\n{{ html_text|truncatehtml(28, \"...\" , false) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>I want to truncate this..</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unescape_html"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convierte texto con entidades codificadas en HTML a sus equivalentes de Unicode."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"me & you\" %}\n{{ escape_string|unescape_html }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "me & you\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "union"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro devuelve la unión de dos conjuntos o listas. La lista devuelta por el filtro contiene todos los elementos únicos que están en cualquiera de las dos listas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La segunda lista a unir con la lista original."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|union([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1, 2, 3, 4, 5]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro extrae un conjunto único de una secuencia o dictado de objetos. Al filtrar un dict, como una lista de mensajes devueltos por una función, puedes especificar qué atributo se utiliza para desduplicar los elementos en el dict."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attr"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica el atributo que debe utilizarse al filtrar un valor de dicc."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"one\", \"one\", \"two\", \"three\" ] %}\n{{ my_sequence|unique }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[one, two, three]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unixtimestamp"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro convierte un objeto datetime en una marca de tiempo de unix."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debes usar este filtro ", (0, _jsxRuntime.jsx)("u", {
          children: "solo"
        }), " con variables que devuelvan una fecha. A partir del 30 de septiembre de 2024, este filtro ya no devolverá la fecha actual cuando se pase un valor nulo. Después de esa fecha, un valor nulo en el filtro devolverá el 30 de septiembre de 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|unixtimestamp }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2017-01-30 17:11:44 1485814304000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertir un valor a todas las letras mayúsculas."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"text\" value=\"text to make uppercase\", export_to_template_context=True %}\n{{ widget_data.text.value|upper }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "TEXT TO MAKE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urlencode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sal y codifica la URL de una cadena usando el formato UTF-8. Acepta tanto diccionarios como cadenas regulares, así como iterables por pares."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"encode\" value=\"Escape & URL encode this string\", label=\"Enter slug\", export_to_template_context=True %}\n{{ widget_data.encode.value|urlencode }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Escape+%26+URL+encode+this+string\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urldecode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Decodifica las cadenas de URL codificadas de vuelta a la URL original. Acepta tanto diccionarios como cadenas regulares, así como iterables por pares."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"decode\" value=\"Escape+%26+URL+decode+this+string\", label=\"Enter slug\", export_to_template_context=True %}\n{{ widget_data.decode.value|urldecode }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Escape & URL encode this string\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urlize"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convierte direcciones URL en texto sin formato en enlaces en los que se puede hacer clic. Si le pasas al filtro un número entero adicional, acortará las URL a ese número. El segundo parámetro es un booleano que dicta si el enlace es rel=\"no follow\". El último parámetro permite especificar si el enlace se abrirá en una nueva pestaña."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "shorten_text"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entero que acortará las URL al número deseado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "no_follow"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor booleano para indicar si el enlace es rel=\"no follow\"."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "target=\"_blank\""
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica si el enlace se abrirá en una pestaña nueva."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"http://hubspot.com/\"|urlize }}\n{{ \"http://hubspot.com/\"|urlize(10,true) }}\n{{ \"http://hubspot.com/\"|urlize(\"\",true) }}\n{{ \"http://hubspot.com/\"|urlize(\"\",false,target=\"_blank\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a href=\"//hubspot.com/\">http://hubspot.com/</a>\n<a href=\"//hubspot.com/\" rel=\"nofollow\">http://...</a>\n<a href=\"//hubspot.com/\" rel=\"nofollow\">http://hubspot.com/</a>\n<a href=\"//hubspot.com/\" target=\"_blank\">http://hubspot.com/</a>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "wordcount"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuenta el número de palabras de una cadena."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Si la cadena contiene HTML, utiliza el filtro striptags para obtener un recuento preciso."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{%  set count_words = \"Count the number of words in this variable\"  %}\n{{ count_words|wordcount }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "8\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "wordwrap"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ocasiona que las palabras se cierren con un número determinado de caracteres. Esto funciona mejor en una <pre> porque HubSpot elimina los espacios en blanco de forma predeterminada."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "character_count"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de caracteres para envolver el contenido."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set wrap_text = \"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam efficitur, ipsum non sagittis euismod, ex risus rhoncus lectus, vel maximus leo enim sit amet dui. Ut laoreet ultricies quam at fermentum.\" %}\n{{ wrap_text|wordwrap(10) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam efficitur, ipsum\nnon sagittis euismod, ex risus rhoncus lectus, vel maximus leo enim sit amet\ndui. Ut laoreet ultricies quam at fermentum.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "xmlattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Crea una cadena de atributos HTML/XML, basada en los elementos de un dict. Todos los valores que no son ninguno ni indefinidos se escapan automáticamente. Automáticamente antepone un espacio delante del elemento si el filtro devuelve algo, a menos que el primer parámetro sea falso."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "autospace"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor booleano que antepondrá automáticamente un espacio delante del elemento a menos que se establezca como falso."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set html_attributes = {\"class\": \"bold\", \"id\": \"sidebar\"} %}\n<div {{ html_attributes|xmlattr }}></div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"bold\" id=\"sidebar\"></div>\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}