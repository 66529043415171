"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 30866940858;
const slug = exports.slug = 'guides/api/conversations/visitor-identification';
const title = exports.title = 'Identification des visiteurs';
const name = exports.name = 'vNext Docs DP - Identification des visiteurs';
const metaDescription = exports.metaDescription = "L'API d'identification des visiteurs est utilisée pour identifier les visiteurs de votre site qui ont été authentifiés à l'aide de systèmes d'authentification externes.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "exemple-de-flux-d-int%C3%A9gration",
  "label": "Exemple de flux d'intégration",
  "parent": null
}, {
  "depth": 0,
  "id": "v%C3%A9rifier-l-int%C3%A9gration",
  "label": "Vérifier l'intégration",
  "parent": null
}, {
  "depth": 0,
  "id": "principes-du-kit-de-d%C3%A9veloppement-logiciel-pour-le-widget-de-chat",
  "label": "Principes du kit de développement logiciel pour le widget de chat",
  "parent": null
}, {
  "depth": 1,
  "id": "r%C3%A9f%C3%A9rence-pour-le-kit-de-d%C3%A9veloppement-logiciel",
  "label": "Référence pour le kit de développement logiciel",
  "parent": "principes-du-kit-de-d%C3%A9veloppement-logiciel-pour-le-widget-de-chat"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      h2: "h2",
      a: "a",
      img: "img",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Identification des visiteurs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise', 'sales_hub-professional', 'sales_hub-enterprise', 'cms-professional', 'cms-enterprise', 'service_hub-professional', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisez l'API d'identification des visiteurs pour identifier les visiteurs de votre site qui ont été authentifiés à l'aide de votre propre système d'authentification externe."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un jeton d'identification renvoyé par cette API peut être utilisé pour transmettre des informations sur votre visiteur déjà authentifié au widget de chat, afin qu'il le traite comme un contact connu. Les agents de la boîte de réception peuvent alors savoir avec certitude à qui ils parlent et les visiteurs peuvent accéder à l'historique des discussions précédentes sur tous les appareils. Par exemple :"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "L'API d'identification des visiteurs permet d'indiquer à HubSpot qui est le visiteur. Vous ne devez pas vous appuyer sur ce système pour authentifier les utilisateurs de votre plateforme."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["L'accès à l'API d'identification des visiteurs nécessite un abonnement ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Pro"
          }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Entreprise"
          }), ". Si le compte n'a pas d'abonnement adéquat, vous recevrez une réponse d'erreur ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "403"
          }), " de la part de l'API."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemple de flux d'intégration"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Afin d'intégrer cette fonctionnalité, vous devez avoir une application web existante avec un système d'authentification."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Avant de commencer, assurez-vous que vous disposez d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "application privée"
      }), " configurée et que le compte que vous essayez d'intégrer dispose d'un abonnement ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Pro"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), " éligible."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Voici un exemple de flux d'intégration possible :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hubfs/Possible%20User%20Identification%20Flow.png",
        alt: "Flux d'identification d'utilisateur possible"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que votre client est connecté et vérifié dans votre système, suivez les étapes suivantes pour les identifier dans le chat en direct :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "1."
      }), " En front-end, définissez loadImmediately sur false dans l'objet hsConversationsSettings de la fenêtre. Autrement, le widget de chat pourrait se charger avant que les informations d'identification ne soient transmises. Reportez-vous aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#chat-widget-sdk-primer",
        children: "principes du kit de développement logiciel pour le widget de chat ci-dessous"
      }), " pour plus d'informations."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Définissez les propriétés de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), " en dehors de la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isConversationsAPIReady"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En outre, l'adresse ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), " doit être définie avant l'appel. Autrement, vous risquez d'être confronté à une condition de course qui interfère avec le chargement des widgets."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["**2.**Générez un jeton à partir de l'API d'identification des visiteurs, en transmettant l'adresse e-mail de votre visiteur authentifié. Cela doit être fait en back-end de votre application web. Consultez l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Points de terminaison"
      }), " pour obtenir un exemple de demande."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubspot.com/conversations/v3/visitor-identification/tokens/create \\\n--data '{\n  \"email\": \"gob@bluth.com\",\n  \"firstName\": \"Gob\",\n  \"lastName\": \"Bluth\"\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Le prénom et le nom de famille fournis seront placés sur la fiche d'informations du contact dans HubSpot après le début du chat si :"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "il s'agit d'un nouveau contact créé par l'API d'identification des visiteurs ;"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "il s'agit d'un contact existant dont le nom n'est pas encore connu."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Cela peut être utile lors de la personnalisation des messages pour les visiteurs identifiés lorsque votre système externe dispose déjà d'informations sur le nom, mais qu'il n'existe pas encore dans HubSpot. Il s'agit de paramètres facultatifs et non obligatoires."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["**3.**En utilisant le jeton de l'étape 2, définissez les propriétés suivantes sur l'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " de la fenêtre."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**4.**Chargez le widget."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le jeton et l'e-mail doivent être définis dans l'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " de la fenêtre chaque fois que la page est chargée pour un visiteur authentifié. Ce contexte ne sera pas automatiquement répercuté sur les chargements de page si ces paramètres ne sont plus définis. Les jetons sont temporaires et expireront au bout de 12 heures. Les jetons peuvent être mis en cache pour éviter de récupérer le jeton à chaque chargement de page, tant qu'ils sont actualisés au moins toutes les 12 heures."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vérifier l'intégration"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois votre intégration de la fonction d'identification des visiteurs terminée, vous pouvez vérifier son bon fonctionnement. Cela peut être fait de plusieurs façons, en fonction de votre mise en œuvre. Ainsi, vous devrez peut-être adapter les exemples ci-dessous selon vos exigences spécifiques."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Si vous avez ajouté le widget de chat sur une ou plusieurs pages publiques ainsi que derrière un système d'authentification :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "Accédez à une page où le widget de chat ne doit pas identifier les visiteurs et lancez une conversation."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Dans HubSpot, ouvrez la boîte de réception et vérifiez que le chat qui vient d'arriver appartient à un ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "visiteur inconnu"
              }), ". Si ce n'est pas le cas, essayez de suivre ces étapes dans une fenêtre de navigation privée :"]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "Accédez à une page où le widget de chat doit identifier les visiteurs via l'API d'identification des visiteurs et lancez une conversation."
                }), "\n"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "Dans HubSpot, ouvrez la boîte de réception et vérifiez que le chat est correctement attribué au contact sous lequel vous êtes connecté. Vous devriez voir un badge à côté du nom du contact indiquant que ce contact a été identifié avec succès via cette API."
                }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: (0, _jsxRuntime.jsx)(_components.img, {
                    src: "https://f.hubspotusercontent00.net/hubfs/53/visitor_identity_badge.png",
                    alt: "visitor_identity_badge"
                  })
                }), "\n"]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Si vous avez ajouté le widget de chat uniquement sur les pages derrière un système d'authentification et que vous avez accès à plusieurs comptes de test :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Connectez-vous à HubSpot en tant que premier utilisateur test, puis accédez à une page où le widget de chat se charge et lancez une conversation."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Déconnectez-vous de HubSpot et reconnectez-vous en tant que deuxième utilisateur. Accédez à une page où le widget de chat se charge et démarrez une conversation."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Dans HubSpot, ouvrez la boîte de réception et vérifiez que les conversations entrantes provenaient respectivement des premier et deuxième comptes de test, et que vous voyez le badge à côté des noms de contact pour les deux fiches d'informations."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Pour les visiteurs identifiés avec cette API, HubSpot ne déconnectera pas le cookie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), ". HubSpot ignorera également toutes les questions de collecte d'adresse e-mail car celle-ci est déjà connue. Comme le cookie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), " et la collecte d'adresse e-mail ne s'appliquent pas à ces chats, les paramètres associés dans le chatflow ne s'afficheront pas pour les visiteurs identifiés via l'API d'identification des visiteurs."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Principes du kit de développement logiciel pour le widget de chat"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'API est hébergée dans l'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), ". Toutes les méthodes disponibles peuvent être consultées via cet objet. Le chargeur de scripts HubSpot sur votre page créera cet objet pour vous, mais il ne pourra pas être immédiatement disponible. Pour reporter l'accès à l'API jusqu'à son initialisation, vous pouvez utiliser l'assistant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), ". Par exemple :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Référence pour le kit de développement logiciel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "tableau"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il s'agit d'un champ facultatif que vous pouvez définir sur l'objet de la fenêtre qui vous permet de spécifier le code à exécuter dès que le widget est disponible. Une fois que l'API a été initialisée, elle vérifiera l'existence de ce tableau et exécutera ses fonctions en série."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "if (window.HubSpotConversations) {\n  console.log('The api is ready already');\n} else {\n  window.hsConversationsOnReady = [\n    () => {\n      console.log('Now the api is ready');\n    },\n  ];\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "objet"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cet objet vous permet de fournir des options de configuration au widget avant son initialisation. Pour utiliser la fonction d'identification des visiteurs, vous devez définir les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Par défaut"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si le widget doit se charger implicitement ou attendre que la méthode ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            }), " soit appelée"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationToken"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Utilisé pour intégrer l'API d'identification des visiteurs. Il s'agit du jeton fourni par le point de terminaison de génération de jeton sur l'API d'identification des visiteurs qui est utilisé comme preuve que ce visiteur a été identifié."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationEmail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'adresse e-mail du visiteur que vous avez identifié comme chargeant le widget."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/conversations/chat-widget-sdk",
        children: "kit de développement logiciel pour les conversations"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}