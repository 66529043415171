"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921797;
const slug = exports.slug = 'guides/api/crm/extensions/timeline';
const title = exports.title = 'API do CRM | Eventos de linha do tempo';
const name = exports.name = 'CRM | Eventos de linha do tempo';
const metaDescription = exports.metaDescription = 'Visão geral e passo a passo sobre a API da Linha do tempo.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-um-modelo-de-evento",
  "label": "Criar um modelo de evento",
  "parent": null
}, {
  "depth": 1,
  "id": "criar-modelos-de-evento-por-meio-da-api",
  "label": "Criar modelos de evento por meio da API",
  "parent": "criar-um-modelo-de-evento"
}, {
  "depth": 1,
  "id": "criar-modelos-de-evento-no-hubspot",
  "label": "Criar modelos de evento no HubSpot",
  "parent": "criar-um-modelo-de-evento"
}, {
  "depth": 1,
  "id": "definir-tokens-de-evento",
  "label": "Definir tokens de evento",
  "parent": "criar-um-modelo-de-evento"
}, {
  "depth": 2,
  "id": "criar-tokens-de-evento-por-meio-da-api",
  "label": "Criar tokens de evento por meio da API",
  "parent": "criar-um-modelo-de-evento"
}, {
  "depth": 1,
  "id": "definir-modelos-de-cabe%C3%A7alho-e-de-detalhes",
  "label": "Definir modelos de cabeçalho e de detalhes",
  "parent": "criar-um-modelo-de-evento"
}, {
  "depth": 2,
  "id": "definir-modelos-de-cabe%C3%A7alho-e-de-detalhes-por-meio-da-api",
  "label": "Definir modelos de cabeçalho e de detalhes por meio da API",
  "parent": "criar-um-modelo-de-evento"
}, {
  "depth": 1,
  "id": "definir-todos-os-aspectos-de-um-modelo-de-evento-em-uma-%C3%BAnica-chamada",
  "label": "Definir todos os aspectos de um modelo de evento em uma única chamada",
  "parent": "criar-um-modelo-de-evento"
}, {
  "depth": 0,
  "id": "criar-um-evento",
  "label": "Criar um evento",
  "parent": null
}, {
  "depth": 1,
  "id": "definir-o-registro-de-data%2Fhora-do-evento",
  "label": "Definir o registro de data/hora do evento",
  "parent": "criar-um-evento"
}, {
  "depth": 1,
  "id": "associar-um-evento-a-um-objeto-do-crm",
  "label": "Associar um evento a um objeto do CRM",
  "parent": "criar-um-evento"
}, {
  "depth": 1,
  "id": "extens%C3%B5es-de-linha-do-tempo",
  "label": "Extensões de linha do tempo",
  "parent": "criar-um-evento"
}, {
  "depth": 1,
  "id": "marcar-dados-de-evento-nas-propriedades-do-objeto-do-crm",
  "label": "Marcar dados de evento nas propriedades do objeto do CRM",
  "parent": "criar-um-evento"
}, {
  "depth": 1,
  "id": "no%C3%A7%C3%B5es-b%C3%A1sicas-sobre-",
  "label": "Noções básicas sobre ",
  "parent": "criar-um-evento"
}, {
  "depth": 1,
  "id": "configurar-um-%C3%ADcone-personalizado",
  "label": "Configurar um ícone personalizado",
  "parent": "criar-um-evento"
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "criar-um-evento"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      h3: "h3",
      pre: "pre",
      em: "em",
      strong: "strong",
      h4: "h4",
      ul: "ul",
      li: "li",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Eventos de linha do tempo"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As extensões de CRM permitem que as informações de outros sistemas apareçam no contato, na empresa ou nos negócios do HubSpot. Os pontos de extremidade dos eventos de linha do tempo permitem fazer isso criando eventos de linha do tempo personalizados. Se você preferir que seus dados possam ser editados pelos usuários, mas nenhum dos objetos de CRM padrão atender às suas necessidades, considere o uso de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_api/event_expanded-2.png",
        alt: "event_expanded-2"
      }), "Por exemplo, você quer segmentar melhor seus contatos com base nas interações deles com a empresa e o conteúdo. Para fazer isso, você precisa de mais informações sobre eles. O aplicativo pode criar eventos personalizados (contatos que se registraram, mas não participaram de um webinar recente, qual variante de um fluxo de inscrição um contato concluiu, etc.) que forneçam mais contexto sobre as interações dos contatos com a empresa."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um modelo de evento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de começar a criar eventos, crie um modelo de evento. Modelos de evento descrevem ações que seu aplicativo adicionará à linha do tempo de um contato, uma empresa ou um negócio no HubSpot. Exemplos dessas ações incluem a visualização de um vídeo, o registro para participar de um webinar ou a resposta a uma pesquisa. Um único aplicativo pode criar até 750 modelos de evento."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por padrão, os modelos de evento são criados para contatos, mas podem ser criados para empresas ou negócios usando o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), ". Para obter mais informações, veja a criação de um modelo de evento de linha do tempo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada modelo de evento tem seu próprio conjunto de tokens e modelos. Você pode usar eventos criados para contatos como critérios ao criar novas listas de contatos ou fluxos de trabalho, como: \"Create a list of all contacts with a Video Like where the video name contains XYZ\", onde o nome do modelo de evento é \"Video Like\" e tem um token de evento denominado \"video name\"."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar modelos de evento por meio da API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para este exemplo, criaremos o novo modelo de evento “Exemplo de Registro no Webinar”. Para autenticação, use a chave de API do desenvolvedor encontrada na sua conta de desenvolvedor de apps."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST\n-H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Example Webinar Registration\",\n  \"objectType\": \"contacts\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>''\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lembre-se de substituir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<appId>>"
      }), " pelo ID do próprio aplicativo, que pode ser encontrado nas páginas ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Meus aplicativos"
      }), " e Detalhes do aplicativo em sua conta do desenvolvedor. Você também precisará substituir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<developerHapikey>>"
      }), " por sua própria chave de API de desenvolvedor; ela pode ser encontrada em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Aplicativos"
      }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Receber a chave de API da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As propriedades ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "headerTemplate"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "detailTemplate"
      }), " também podem ser fornecidas aqui. (Consulte “Definindo Modelos” abaixo.)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Essa solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " retornará a definição completa do modelo de evento salvo. Observe a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " nessa resposta. Este é o ID do modelo de evento; ele será necessário caso você precise fazer qualquer atualização nesse modelo de evento ou em tokens no futuro."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode ver todos os modelos de evento definidos para um aplicativo usando esse comando GET, que também retornará os ID do modelo de evento:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar modelos de evento no HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além de usar a API para criar e gerenciar modelos de eventos de linha do tempo, você também pode gerenciar modelos de evento em sua conta de desenvolvedor da HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nas configurações do aplicativo, acesse Eventos de linha do tempo e use o botão “Criar tipo de evento” para criar um novo modelo de evento para esse aplicativo. Se você já tiver criado algum modelo de evento anteriormente, ele aparecerá aqui."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/image%20(3)-Jul-23-2020-10-02-24-50-PM.png",
        alt: "image (3)-Jul-23-2020-10-02-24-50-PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você começará com um rascunho do novo modelo de evento. Depois de definir o tipo de objeto e os modelos de cabeçalho e de detalhes do evento, clique em \"Criar\".\""
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/image%20(4)-Jul-23-2020-10-02-24-66-PM.png",
        alt: "image (4)-Jul-23-2020-10-02-24-66-PM"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao criar ou editar seu modelo de evento, defina quaisquer tokens que deseje usar com ele na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Dados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/data-tab-1.png",
        alt: "data-tab-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**Observação: **se você excluir um modelo, os eventos existentes que usam esse modelo serão removidos permanentemente das contas com seu aplicativo conectado. Você não poderá mais criar novos eventos desse tipo, mas continuará vendo dados de eventos antigos em listas e relatórios. Essas alterações podem demorar várias horas para serem refletidas no HubSpot."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir tokens de evento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de definir um modelo de evento, provavelmente você vai querer definir também os respectivos tokens. Os tokens de modelos de evento permitem anexar dados personalizados aos eventos, que podem ser exibidos na linha do tempo e, para contatos, podem ser usados para segmentação de lista e automação. Você pode criar até 500 tokens por modelo de evento de linha do tempo."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " os eventos de empresa e negócio ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " podem ser usados na automação ou segmentação de lista."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Criar tokens de evento por meio da API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando o ID do modelo de evento criado na Etapa 1, adicionaremos alguns tokens para identificar o webinar em que nossos seus contatos se registraram."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarName\",\n  \"label\": \"Webinar Name\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarId\",\n  \"label\": \"Webinar Id\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarType\",\n  \"label\": \"Webinar Type\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"value\": \"regular\",\n      \"label\": \"Regular\"\n    },\n    {\n      \"value\": \"ama\",\n      \"label\": \"Ask me anything\"\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da mesma forma, o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " retornará todos os tokens definidos em um modelo de evento:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET -H \"Content-Type: application/json\" 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os tipos de token permitidos incluem:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), " — Uma dentre um conjunto de opções. Veja o exemplo webinarType abaixo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "date"
        }), " — Todas as datas devem estar em milissegundos no horário do Unix."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": não é possível definir os nomes log ou lookup para tokens de evento. Esses tokens são reservados como auxiliares por Handlebars.js, a biblioteca usada para renderizar eventos no aplicativo. Para obter mais informações, consulte os documentos de Handlebars.js ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://handlebarsjs.com/builtin_helpers.html",
          children: "aqui."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir modelos de cabeçalho e de detalhes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos de cabeçalho e de detalhes definem como exibir um evento de linha do tempo. Você pode especificar documentos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://daringfireball.net/projects/markdown/syntax",
        children: "Marcação"
      }), " com modelos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://handlebarsjs.com/",
        children: "Handlebars"
      }), ". O modelo de cabeçalho deve ser uma descrição de uma linha do evento; o modelo de detalhes é a exibição detalhada do evento (exemplos abaixo)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os tokens de evento são transmitidos como dados para os modelos. Usando nosso exemplo, você pode fazer referência ao token ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " no modelo usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{webinarName}}"
      }), " ."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " de um evento (analisado em “Noções básicas sobre extraData\" abaixo) pode ser referenciado apenas no modelo de detalhes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Definir modelos de cabeçalho e de detalhes por meio da API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos de cabeçalho e de detalhes podem ser definidos no modelo de evento usando pontos de extremidadede modelos de evento. Por exemplo, podemos adicionar modelos ao nosso 'Exemplo de Registro no Webinar', modificando-o com o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"id\": \"<<eventTemplateId>>\",\n  \"name\": \"Example Name Change\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Observe o uso da diretiva ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "#formatDate"
      }), ". Definimos essa diretiva para permitir uma formatação de data de fácil compreensão para o usuário."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Assim que um evento for criado para um contato usando essa diretiva (consulte \"", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#creating-an-event",
        children: "Criando um evento"
      }), "\" abaixo), veja o que será exibido na linha do tempo do contato:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Clicar em “Mostrar detalhes” exibe o modelo de detalhes:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_expanded.png?width=640&name=event_expanded.png",
        alt: "event_expanded.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para definir o ícone que é exibido ao lado dos eventos, consulte \"", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline#customicon",
        children: "Configurando um ícone personalizado\""
      }), " abaixo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O texto “Exemplo de nome do aplicativo” acima consiste no nome do aplicativo. Na linha do tempo do CRM, os eventos podem ser filtrados por aplicativo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir todos os aspectos de um modelo de evento em uma única chamada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora que você viu que cada aspecto de um modelo de evento é definido progressivamente, poderá defini-lo em uma chamada do comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Another Webinar Registration\",\n  \"objectType\": \"contacts\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\",\n  \"tokens\": [\n    {\n      \"name\": \"webinarName\",\n      \"label\": \"Webinar Name\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarId\",\n      \"label\": \"Webinar Id\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarType\",\n      \"label\": \"Webinar Type\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"value\": \"regular\",\n          \"label\": \"Regular\"\n        },\n        {\n          \"value\": \"ama\",\n          \"label\": \"Ask me anything\"\n        }\n      ]\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora que um modelo de evento está configurado com tokens e modelos, estamos prontos para criar eventos para os contatos, as empresas, os negócios e os tickets de nossos clientes. Os exemplos abaixo pressupõem que estamos trabalhando com o modelo de evento de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contacts"
      }), " criado acima. Se o modelo de evento acima não estiver configurado para ter os tokens ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarId"
      }), ", você receberá um erro ao tentar criar o evento. Este é um exemplo do comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para a criação de um evento:"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": as chaves de API do desenvolvedor e os tokens de acesso ao aplicativo privado ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " podem ser usados como autenticação ao criar eventos. Para criar um evento, a conta associada da HubSpot precisará conceder acesso ao seu aplicativo por meio de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth"
        }), ". Depois de obter um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "token de acesso de OAuth"
        }), ", você poderá usá-lo para adicionar eventos à conta."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ele gera um evento na linha do tempo de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a.test.contact@email.com"
      }), "' (considerando os modelos descritos em “Definindo modelos” acima):"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir o registro de data/hora do evento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O registro de data/hora do evento determina onde o evento aparecerá na linha do tempo do objeto. Por padrão, o registro de data/hora do evento consiste no momento em que o comando POST é enviado. Você pode personalizar o horário do evento fornecendo-o no corpo da solicitação em uma propriedade de registro e data/hora:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"timestamp\": \"2020-03-18T15:30:32Z\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Isso será o ideal se você souber o horário exato em que uma ação ocorreu. Nesse exemplo, se tivermos o registro de data/hora da inscrição no webinar, deveremos fornecê-lo no comando POST."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os registros de data/hora podem estar no horário da época em milissegundos ou no formato ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/ISO_8601",
        children: "ISO 8601"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associar um evento a um objeto do CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar um evento, você precisa ser capaz de associar o evento a um contato, uma empresa ou um negócio na conta de um cliente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nos exemplos acima, objectType foi definido como contato e usamos o parâmetro email para associar o evento a um contato. Os endereços de e-mail devem ser exclusivos para contatos no HubSpot. Portanto, se houver um contato existente com o e-mail fornecido, esse contato será atualizado. Se não houver um contato existente, um novo será criado. Por padrão, esse novo contato terá apenas a propriedade de contato de e-mail fornecida. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#stamp-event-data-onto-crm-object-properties",
        children: "marcação de dados de eventos em propriedades de contato"
      }), " para adicionar mais dados às propriedades de contato."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver trabalhando com contatos conhecidos, também poderá usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), " do contato para associar o evento. Nesses casos, você usaria ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " no JSON da solicitação. Você precisa incluir o vid de um contato existente, pois você não poderá criar novos contatos usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". Este exemplo usa o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ", em vez do e-mail:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"29851\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode associar um evento a um contato por usertoken ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ". Usertoken é usado pelo código de rastreamento da HubSpot para rastrear visitantes e é armazenado no cookie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspotutk"
      }), ". Use o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " para associar um evento a um contato por usertoken. Observação: não é possível associar eventos a visitantes anônimos usando usertoken. Portanto, se o evento estiver associado somente ao parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " e o usertoken fornecido não estiver associado a um contato ainda, nenhum novo contato será criado, e o evento não estará visível no HubSpot. No entanto, o evento aparecerá na linha do tempo se um novo contato tiver sido associado ao usertoken de outra maneira (geralmente por meio de um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "envio de formulário incluindo hutk"
      }), " ou por meio do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code",
        children: "método de identificação da API do código de rastreamento"
      }), "). Por isso, recomendamos que você inclua o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", além do ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ", para garantir que o evento seja associado a um contato novo ou existente."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver trabalhando com um modelo de evento para contatos, poderá incluir vários parâmetros de identificação com o evento para que seja possível incluir qualquer combinação dos parâmetros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ". Se vários parâmetros forem incluídos, o parâmetro objectId (vid) prevalecerá no momento de determinar qual contato deve ser associado ao evento, seguido do parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ". O parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " é o de menor prioridade. Isso significa que você pode atualizar o endereço de e-mail de um objeto existente, incluindo um novo endereço de e-mail no parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "email com ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), " de um objeto conhecido em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". Este exemplo usa o endereço de e-mail e o token de usuário juntos:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"utk\": \"89b5afb740d41f4cd6651ac5237edf09\"\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além de trabalhar com contatos, também é possível criar modelos de evento para empresas e negócios. Para esses modelos de evento, você deve usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " para associar o evento à empresa ou ao negócio. Para empresas, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " deve ser definido com o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " da empresa a que você deseja associar o evento; para negócios, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " deve ser definido com o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealId"
      }), " do objeto de negócios."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No exemplo abaixo, considerando que o modelo de evento foi definido como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPANY"
      }), ", esse evento seria associado ao objeto de empresa com ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " 528253914:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"3001\",\n  \"tokens\": {\n    \"dealProperty\": \"Custom property for deal\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Extensões de linha do tempo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O recurso de extensões de linha do tempo pode ser usado para exibir dados de um sistema externo usando um iFrame. Se um link for incluído, ele será exibido pelo evento. Quando clicado, o link abrirá uma janela modal com o conteúdo do iFrame. Os detalhes do iFrame são definidos no campo timelineIFrame, que é um objeto com os campos a seguir:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "linkLabel"
        }), " - O texto usado para mostrar o link que exibirá o IFrame."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "headerLabel"
        }), " - O rótulo da janela modal que exibe o conteúdo do IFrame."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), " - O URI do conteúdo do IFrame."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "width"
        }), " - A largura da janela modal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "height"
        }), " - Height - A altura da janela modal."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, usando esses dados para um evento:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"timelineIFrame\": {\n    \"linkLabel\":\"View external data\",\n    \"headerLabel\":\"Example iframe\",\n    \"url\":\"https://www.example.com\",\n    \"width\":800,\n    \"height\":300\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você criaria esse evento, incluindo o link “Exibir dados externos”:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/external_data_link.png?width=640&name=external_data_link.png",
        alt: "external_data_link.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Clicar nesse link abriria uma janela modal com a página definida no ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "url"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/iframe_modal.png?width=640&name=iframe_modal.png",
        alt: "iframe_modal.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Marcar dados de evento nas propriedades do objeto do CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em muitos casos, você vai querer modificar as propriedades dos contatos, das empresas ou dos negócios aos quais está adicionando eventos. Geralmente isso acontece nos casos em que a adição do evento criará, de fato, um contato. Você provavelmente desejará atualizar as propriedades de nome e sobrenome no contato para não criar um contato que tenha apenas um endereço de e-mail e um evento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode marcar dados no objeto associado a partir de um evento mapeando seus tokens de eventos personalizados para propriedades de contatos, empresa ou negócios."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Considere esse comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para atualizar um modelo de evento personalizado. Observe o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"label\" : \"Updated Webinar Name\",\n  \"objectPropertyName\": \"zz_webinar_name\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens/<<tokenName>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ele usa ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), " para mapear esse token de evento personalizado à propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " do objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), ". Isso significa que, quando criamos um novo evento que especifica um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), ", a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " do ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), " associado também é definida. Você pode definir essas propriedades para propriedades personalizadas ou predefinidas do HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, suponha que já tenhamos criado um token ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyName"
      }), " que faça referência a uma propriedade personalizada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), ". Em seguida, a criação de um evento como este faz com que as propriedades ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " sejam definidas no contato com o endereço de e-mail ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "mailto:a.test.contact@email.com",
        children: "a.test.contact@email.com"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/set_property.png?width=1024&name=set_property.png",
        alt: "set_property.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Observação: se um token de evento estiver marcado para uma propriedade personalizada e essa propriedade personalizada não estiver presente para uma conta da HubSpot, o valor continuará definido para o evento, mas será ignorado para o objeto correspondente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h3, {
      children: ["Noções básicas sobre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Talvez seja preciso adicionar dados detalhados a um evento que não se encaixa na estrutura simples de token-valor usada pelo modelo de evento. Pode ser necessário adicionar uma lista ou detalhamento hierárquico a um evento de integração. É aqui que entra o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode adicionar um atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " ao corpo JSON do evento. O valor desse ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " pode ser qualquer JSON válido. Por exemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"extraData\": {\n    \"pollData\": [\n      {\n        \"question\": \"How excited are you for this webinar?\",\n        \"answer\":\"Quite!\"\n      },\n      {\n        \"question\": \"How frequently do you use our product?\",\n        \"answer\":\"Daily\"\n      }\n    ],\n    \"coWorkers\": [\n      {\n        \"name\": \"Joe Coworker\",\n        \"email\":\"joe.coworker@testco.com\"\n      },\n      {\n        \"name\": \"Jane Coworker\",\n        \"email\":\"jane.coworker@testco.com\"\n      }\n    ]\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Exemplo do uso de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " em um modelo de detalhes:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "//\nRegistration occurred at {{#formatDate timestamp}}{{/formatDate}}\n\n#### Poll Questions\n{{#each extraData.pollData}}\n  **{{question}}**: {{answer}}\n{{/each}}\n\n#### Co-Workers\n{{#each extraData.coWorkers}}\n  * {{name}}\n{{/each}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Isso resultará em um evento de linha do tempo semelhante ao mostrado a seguir:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/extra_data.png?width=640&name=extra_data.png",
        alt: "extra_data.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Observação: o atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " só pode ser referenciado no modelo de detalhes de um evento. Ele não pode ser usado no modelo de cabeçalho nem na segmentação de lista."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurar um ícone personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar um apelo visual a seus itens de linha do tempo, você pode adicionar um ícone personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este arquivo de imagem desse ícone deve:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ter dimensões quadradas"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ter um fundo transparente"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ter o conteúdo no centro do ícone"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Poder ser reduzido para até 30 x 30 pixels"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ter um tamanho máximo de arquivo de 5 MB"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para definir o ícone usado para eventos de linha do tempo, vá para Eventos da linha do tempo. Clique na imagem do espaço reservado ou no ícone existente para definir ou atualizar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_assets.png",
        alt: "timeline_assets"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois que os ícones forem definidos, eles aparecerão ao lado de todos os eventos de linha do tempo associados a este aplicativo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/timeline_icon.png?width=640&name=timeline_icon.png",
        alt: "timeline_icon.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Noções básicas do CRM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "Cartões de CRM"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}