"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611088;
const slug = exports.slug = 'getting-started/slack/developer-slack';
const title = exports.title = 'Mitglied der HubSpot-Entwickler-Community bei Slack (Developer Slack) werden';
const name = exports.name = 'Slack-Anmeldung - Designer Entwickler';
const metaDescription = exports.metaDescription = 'Die HubSpot-Entwickler-Community bei Slack (Developer Slack) ist eine spezielle Community für HubSpot-Entwickler, in der sich Entwickler für den Support und die Zusammenarbeit von Entwickler zu Entwickler rund um die HubSpot-Plattform zusammenfinden. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Zusammenarbeit im Developer-Slack';
const toc = exports.toc = [{
  "depth": 0,
  "id": "f%C3%BCr-wen-ist-die-%E2%80%9Edeveloper-slack%E2%80%9C-community-gedacht%3F",
  "label": "Für wen ist die „Developer Slack“-Community gedacht?",
  "parent": null
}, {
  "depth": 0,
  "id": "was-kann-ich-erwarten%2C-wenn-ich-der-community-beitrete%3F",
  "label": "Was kann ich erwarten, wenn ich der Community beitrete?",
  "parent": null
}, {
  "depth": 0,
  "id": "treten-sie-unserer-%E2%80%9Edeveloper-slack%E2%80%9C-community-bei%2C-indem-sie-sich-unten-anmelden",
  "label": "Treten Sie unserer „Developer Slack“-Community bei, indem Sie sich unten anmelden",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Form
    } = _components;
  if (!Form) _missingMdxReference("Form", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Mitglied der HubSpot-Entwickler-Community bei Slack (Developer Slack) werden"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die HubSpot-Entwickler-Community bei Slack (Developer Slack) ist eine spezielle Community für HubSpot-Entwickler, in der sich Entwickler für den Support und die Zusammenarbeit von Entwickler zu Entwickler rund um die HubSpot-Plattform zusammenfinden. Die 2016 gegründete Slack-Community für Entwickler ist auf Hunderte von aktiven Entwicklern aus der ganzen Welt angewachsen, die untereinander ihr Wissen und ihr Know-how austauschen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Für wen ist die „Developer Slack“-Community gedacht?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ganz gleich, ob Sie gerade erst mit dem Entwickeln in der HubSpot-Plattform beginnen oder bereits ein erfahrener Experte sind, die „Developer Slack“-Community ist genau das Richtige für Sie. Wir haben Channels, die sich mit verschiedenen Aspekten unserer Plattform befassen: APIs, E-Mail-Entwicklung, lokale Entwicklung unter Verwendung unseres ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Vorlagen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Designs"
      }), " und mehr."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Was kann ich erwarten, wenn ich der Community beitrete?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es erwartet Sie eine aktive Community von Entwicklern, die sich gegenseitig unterstützen und unterrichten. Dies ist in erster Linie eine Community, und je mehr man hineinsteckt, desto mehr hat man auch davon. Diese Community ist global, aber die Mehrheit der Mitglieder befindet sich in der westlichen Hemisphäre, d. h., wenn Sie in der östlichen Hemisphäre leben, erhalten Sie möglicherweise keine schnellen Antworten. Dies liegt nicht daran, dass Ihre Beiträge ignoriert oder nicht gesehen werden, sondern daran, dass die Leute wahrscheinlich schlafen. Das kann man ändern, indem man in seiner Zeitzone zu den Leuten gehört, die aktiv sind und anderen helfen. Dadurch wächst die Community und wird für Sie nützlicher. Uns bei HubSpot liegt diese Community sehr am Herzen, und wir sind ein Teil dieser Community. Behandeln Sie andere mit Respekt, behandeln Sie HubSpot-Mitarbeitende mit Respekt. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/code-of-conduct",
        children: "Sehen Sie sich unseren Verhaltenskodex für Slack-Communitys an"
      }), ". Lassen Sie uns gemeinsam mit System wachsen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Treten Sie unserer „Developer Slack“-Community bei, indem Sie sich unten anmelden"
    }), "\n", ' ', "\n", (0, _jsxRuntime.jsx)(Form, {
      formId: "7fd756b1-d981-4b81-9caf-8a79a25bffb2",
      responseType: "inline",
      message: `<br/><button target="_blank" href="https://cta-redirect.hubspot.com/cta/redirect/53/18a2bcd4-227f-46b9-b7e4-52dba8955ae5">Enter the Slack community</button>`
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}