"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32924700882;
const slug = exports.slug = 'guides/apps/marketplace/measuring-app-performance';
const title = exports.title = 'アプリのパフォーマンスの測定';
const name = exports.name = 'アプリのパフォーマンスの測定';
const metaDescription = exports.metaDescription = 'アプリ掲載ページの詳細でアプリのパフォーマンスをご覧ください。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E3%82%A2%E3%83%8A%E3%83%AA%E3%83%86%E3%82%A3%E3%82%AF%E3%82%B9%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B",
  "label": "アプリのアナリティクスを確認する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9%E3%82%A2%E3%83%8A%E3%83%AA%E3%83%86%E3%82%A3%E3%82%AF%E3%82%B9",
  "label": "マーケットプレイスアナリティクス",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E3%82%A2%E3%83%8A%E3%83%AA%E3%83%86%E3%82%A3%E3%82%AF%E3%82%B9%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E9%A1%A7%E5%AE%A2%E3%83%95%E3%82%A3%E3%83%BC%E3%83%89%E3%83%90%E3%83%83%E3%82%AF",
  "label": "顧客フィードバック",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%87%8D%E8%A6%81%E3%81%AA%E3%83%AA%E3%83%AA%E3%83%BC%E3%82%B9",
  "label": "重要なリリース",
  "parent": null
}, {
  "depth": 0,
  "id": "utm%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%82%A8%E3%83%B3%E3%82%B2%E3%83%BC%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88%E3%82%92%E8%A9%95%E4%BE%A1%E3%81%99%E3%82%8B",
  "label": "UTMパラメーターを使用してエンゲージメントを評価する",
  "parent": null
}, {
  "depth": 2,
  "id": "%E9%96%A2%E9%80%A3%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "関連ドキュメント",
  "parent": "utm%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%82%A8%E3%83%B3%E3%82%B2%E3%83%BC%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88%E3%82%92%E8%A9%95%E4%BE%A1%E3%81%99%E3%82%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      img: "img",
      code: "code",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "アプリのパフォーマンスの測定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者アカウントでは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "アプリマーケットプレイス"
      }), "に掲載されているアプリのパフォーマンス測定指標を確認できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリのアナリティクスを確認する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "アプリ開発者アカウント"
        }), "で［マーケットプレイス］>［掲載情報］に移動します。これにより、アプリマーケットプレイスの全ての掲載情報を含むダッシュボードが表示されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "アプリ"
        }), "をクリックします。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "マーケットプレイスアナリティクス"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［インストール数］セクションで、アプリのパフォーマンスに関する詳細な測定指標を確認します。特定の期間のデータを分析するには、ページ上部の［頻度］ドロップダウンメニューをクリックして、期間を選択します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のデータを分析できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリのインストール数"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリのアンインストール数"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリのインストール数とアンインストール数"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "無料と有料のインストール数の比較"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "無料と有料のアンインストール数の比較"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "国別のインストール数"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hub別のインストール数"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/marketplace-app-analytics.png",
        alt: "marketplace-app-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［掲載情報アナリティクス］セクションで、アプリの掲載情報ページの測定指標を確認します。特定の期間のデータを分析するには、ページ上部の［頻度］ドロップダウンメニューをクリックして、期間を選択します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のデータを分析できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページビュー数"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページビューのソース"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CTAの使用状況"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "無料と有料のページビュー数の比較"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "国別のページビュー数"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hub別のページビュー数"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-analytics.png",
        alt: "listing-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "顧客フィードバック"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "お客様がアプリについて投稿した全てのお客様のレビューを表示するには、［顧客フィードバック］タブをクリックします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/customer-feedback-tab.png",
        alt: "customer-feedback-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［評価とレビュー］セクションでは、星の数、お客様の業界、お客様の会社の規模でレビューを絞り込むことができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レビューに返信するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "レビューで［返信］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ポップアップボックスにお客様への", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "返信"
        }), "を入力し、［送信］をクリックします。この返信は貴社のアプリ掲載情報に表示されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レビュー受信の通知を編集するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［アクション］ドロップダウンメニューをクリックし、［通知を編集］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［通知］ページで「", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "マーケットプレイス"
        }), "」を検索し、［掲載情報の新しいレビュー］を選択します。これで、お客様がレビューを投稿したときに通知が届きます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "お客様にレビューリンクを送信するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［アクション］ドロップダウンメニューをクリックし、［レビューリンクをコピー］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "お客様がレビューを投稿できるように、このリンクをお客様に送信します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［アンインストールに関するアンケートの回答］セクションで、全てのアンケート回答を確認します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "回答をエクスポートするには、［エクスポート］をクリックしてファイル形式を選択します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［非公開のフィードバック］セクションで、チームに寄せられた非公開フィードバックを確認します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "重要なリリース"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［重要なリソース］セクションで、次のリソースを確認します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ パートナー プログラムの特典ガイド。このガイドには、HubSpotアプリマーケットプレイスにアプリを掲載しているパートナーとして得られる全ての特典が記載されています。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリパートナーの体験に関するフィードバックを提供するために入力できるフィードバックアンケート。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ パートナー マネージャーの連絡先情報。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "UTMパラメーターを使用してエンゲージメントを評価する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記の測定指標に加えて、アプリの掲載情報ページのURLにUTMパラメーターを含めることで、エンゲージメントをさらに測定できます。これにより、HubSpot、Googleアナリティクス、またはその他の分析プラットフォームを使用して、掲載情報ページからウェブサイトへのトラフィック量を確認できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "掲載情報ページに含まれる次のURLにUTMパラメーターを追加することをお勧めします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリマーケットプレイスの掲載情報ページ上のサポートコンテンツ（会社のウェブサイト、サポートドキュメント、ケーススタディー、プライバシーポリシーなど）。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリのインストール時に顧客が使用するOAuthインストールURL。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "たとえば、ドキュメントURLの末尾に次のUTMパラメーターの文字列を追加できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "?utm_campaign=appmarketplacelisting&utm_medium=referral&utm_source=hubspot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**貴社または貴社のマーケティングチームが他のUTMトラッキングを使用している場合、それに一致するUTMパラメーターを使用することをお勧めします。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/customers/understanding-basics-utm-parameters",
          children: "UTMパラメーターの基本"
        }), "について、および", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/how-do-i-create-a-tracking-url",
          children: "HubSpot"
        }), "と", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/what-are-utm-tracking-codes-ht",
          children: "Googleアナリティクス"
        }), "を使用してUTMトラッキングURLを作成する方法について、詳しくご確認ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UTMパラメーターをOAuthインストールURLに追加するには："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ開発者アカウントで、メイン ナビゲーション バーの［アプリ］に移動します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "名前"
        }), "をクリックして、その詳細を編集します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左側のサイドバーで［基本情報］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［Auth］タブをクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［リダイレクトURL］セクションで、UTMパラメーターを含むようにリダイレクトURLを更新します。保存後にアプリのインストールURLが更新されます。したがって、ユーザーに表示される全てのリンクで新しいURLが使用されるように確実に更新する必要があります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/update-redirect-url.png",
        alt: "update-redirect-url"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［保存］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**インストールボタンURLフィールドには250文字の制限があります。UTMパラメーターによってその制限を超えてしまう場合、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://bitly.com/",
          children: "Bitly"
        }), "などのURL短縮機能を使用する必要が生じることがあります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリのサポートコンテンツにUTMパラメーターを追加するには："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "アプリ開発者アカウントで［アプリマーケットプレイス］>［掲載情報］に移動します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["［マーケットプレイス掲載情報］表で、アプリの", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "名前"
          }), "をクリックします。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["［掲載情報］タブで、UTMパラメーターを使って［インストールボタンのURL］", (0, _jsxRuntime.jsx)(_components.em, {
            children: "URL"
          }), " フィールドのURLを更新します。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/install-button-url.png",
            alt: "install-button-url"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［サポート情報］タブをクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "［コンタクト情報］、"
          }), "［サポートリソース］、［サービス利用規約とプライバシーポリシー］セクションで、UTMパラメーターを使用してURLを更新します。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/support-resources-section.png",
            alt: "support-resources-section"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "URLを更新したら、右上隅の［審査に提出］をクリックします。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["審査と承認が完了すると、アプリの掲載情報ページ上のURLが更新されてUTMパラメーターが含まれるようになります。その後、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "HubSpot"
      }), "やGoogleアナリティクスなどの分析ツールを使用して、URLからのトラフィックをUTMパラメーターで分類して表示できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**アプリ掲載情報はGoogleやその他の検索エンジンで検出される可能性がありますから、SEOを考慮した掲載情報にすることも重要です。SEOを改善するための推奨される戦略の1つは、バックリンクを使用することです。ウェブサイトのコンテンツを作成したり、メールニュースレターを送信したり、ソーシャル メディア メッセージを作成したりするときには、連携に関する関連情報とともに、掲載情報ページにリンクを追加することを検討してください。SEOオーソリティーを向上させるには", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/guest-blogging",
          children: "ゲストによるブログ投稿"
        }), "などの戦略を通じてリーチをさらに拡大できます。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/backlink-strategies",
          children: "バックリンクを獲得するためのクリエイティブな方法"
        }), "と、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/link-building-tutorial",
          children: "リンク構築に関するHubSpotアカデミーの無料レッスン"
        }), "をご覧ください。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "関連ドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "アプリの認定に必要な条件"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "アプリの掲載に必要な条件"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}