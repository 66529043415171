import enviro from 'enviro';
import I18n from 'I18n';
import { PageTypes, Paths } from '../../../components/RoutesPublic';
import memoize from 'react-utils/memoize';
import { keyToNavData } from 'developer-docs-shared-components/config/navData/keys';
import { breadcrumbs } from 'developer-docs-shared-components/config/navData/translations/breadcrumbs';
const apiEndpointsNavData = keyToNavData['/reference/api'];
/**
 * 'API & Reference' > 'API Endpoints' nav config.
 * TODO: Localize
 */
export const apiEndpointsNavConfig = memoize(() => {
  const commonPath = `${Paths.REFERENCE}/api`;
  const config = {
    commonPath,
    locatorPageType: PageTypes.REFERENCE,
    sectionLabel: I18n.text('developer-docs-ui.navigation.reference.apiEndpoints'),
    items: [{
      label: apiEndpointsNavData['/overview'].label(),
      url: `${commonPath}/overview`
    }, {
      label: breadcrumbs['analytics-and-events'](),
      items: [{
        label: breadcrumbs['custom-events'](),
        items: [{
          label: apiEndpointsNavData['/analytics-and-events/custom-events/custom-event-completions'].label(),
          url: `${commonPath}/analytics-and-events/custom-events/custom-event-completions`
        }, {
          label: apiEndpointsNavData['/analytics-and-events/custom-events/custom-event-definitions'].label(),
          url: `${commonPath}/analytics-and-events/custom-events/custom-event-definitions`,
          badge: 'BETA'
        }]
      }, {
        label: apiEndpointsNavData['/analytics-and-events/email-analytics'].label(),
        url: `${commonPath}/analytics-and-events/email-analytics`
      }, {
        label: apiEndpointsNavData['/analytics-and-events/event-analytics'].label(),
        url: `${commonPath}/analytics-and-events/event-analytics`
      }, {
        label: apiEndpointsNavData['/analytics-and-events/reporting'].label(),
        url: `${commonPath}/analytics-and-events/reporting`
      }, {
        label: breadcrumbs['cookie-banner'](),
        items: [{
          label: apiEndpointsNavData['/analytics-and-events/cookie-banner/cookie-banner-api'].label(),
          url: `${commonPath}/analytics-and-events/cookie-banner/cookie-banner-api`
        }, {
          label: apiEndpointsNavData['/analytics-and-events/cookie-banner/google-consent-mode'].label(),
          url: `${commonPath}/analytics-and-events/cookie-banner/google-consent-mode`
        }]
      }, {
        label: apiEndpointsNavData['/analytics-and-events/tracking-code'].label(),
        url: `${commonPath}/analytics-and-events/tracking-code`
      }]
    }, {
      label: breadcrumbs['app-management'](),
      items: [{
        label: apiEndpointsNavData['/app-management/feature-flags'].label(),
        url: `${commonPath}/app-management/feature-flags`,
        badge: 'BETA'
      }, {
        label: apiEndpointsNavData['/app-management/oauth'].label(),
        url: `${commonPath}/app-management/oauth`
      }, {
        label: apiEndpointsNavData['/app-management/webhooks'].label(),
        url: `${commonPath}/app-management/webhooks`
      }]
    }, {
      label: breadcrumbs['automation'](),
      items: [{
        label: apiEndpointsNavData['/automation/create-manage-workflows'].label(),
        url: `${commonPath}/automation/create-manage-workflows`
      }, {
        label: apiEndpointsNavData['/automation/custom-workflow-actions'].label(),
        url: `${commonPath}/automation/custom-workflow-actions`
      }, {
        label: apiEndpointsNavData['/automation/custom-code-actions'].label(),
        url: `${commonPath}/automation/custom-code-actions`
      }, {
        label: apiEndpointsNavData['/automation/sequences'].label(),
        url: `${commonPath}/automation/sequences`,
        badge: 'BETA'
      }]
    }, {
      label: breadcrumbs['cms'](),
      items: [{
        label: breadcrumbs['blogs'](),
        items: [{
          label: apiEndpointsNavData['/cms/blogs/blog-details'].label(),
          url: `${commonPath}/cms/blogs/blog-details`
        }, {
          label: apiEndpointsNavData['/cms/blogs/blog-authors'].label(),
          url: `${commonPath}/cms/blogs/blog-authors`
        }, {
          label: apiEndpointsNavData['/cms/blogs/blog-posts'].label(),
          url: `${commonPath}/cms/blogs/blog-posts`
        }, {
          label: apiEndpointsNavData['/cms/blogs/blog-tags'].label(),
          url: `${commonPath}/cms/blogs/blog-tags`
        }, {
          label: apiEndpointsNavData['/cms/blogs/blog-topics'].label(),
          url: `${commonPath}/cms/blogs/blog-topics`
        }, {
          label: apiEndpointsNavData['/cms/blogs/blog-comments'].label(),
          url: `${commonPath}/cms/blogs/blog-comments`
        }]
      }, {
        label: apiEndpointsNavData['/cms/content-audit'].label(),
        url: `${commonPath}/cms/content-audit`
      }, {
        label: apiEndpointsNavData['/cms/domains'].label(),
        url: `${commonPath}/cms/domains`
      }, {
        label: apiEndpointsNavData['/cms/hubdb'].label(),
        url: `${commonPath}/cms/hubdb`
      }, {
        label: apiEndpointsNavData['/cms/layouts'].label(),
        url: `${commonPath}/cms/layouts`
      }, {
        label: apiEndpointsNavData['/cms/media-bridge'].label(),
        url: `${commonPath}/cms/media-bridge`
      }, {
        label: apiEndpointsNavData['/cms/modules'].label(),
        url: `${commonPath}/cms/modules`
      }, {
        label: apiEndpointsNavData['/cms/pages'].label(),
        url: `${commonPath}/cms/pages`
      }, {
        label: apiEndpointsNavData['/cms/site-search'].label(),
        url: `${commonPath}/cms/site-search`
      }, {
        label: apiEndpointsNavData['/cms/source-code'].label(),
        url: `${commonPath}/cms/source-code`
      }, {
        label: apiEndpointsNavData['/cms/templates'].label(),
        url: `${commonPath}/cms/templates`
      }, {
        label: apiEndpointsNavData['/cms/url-redirects'].label(),
        url: `${commonPath}/cms/url-redirects`
      }]
    }, {
      label: breadcrumbs['conversations'](),
      items: [
      /* {
      label: 'Chat widget SDK',
      items: [
        {
          // TODO: added this page to avoid the discouraged "folder click navigates to page" pattern
          label: 'Conversations SDK',
          url: '/conversation/chat-widget-sdk',
        },
        {
          label: 'Open specific chatflow',
          url: '/conversations/open-to-chatflow',
        },
      ],
      }, */
      {
        label: apiEndpointsNavData['/conversations/chat-widget-sdk'].label(),
        url: `${commonPath}/conversations/chat-widget-sdk`
      }, {
        label: apiEndpointsNavData['/conversations/create-custom-channels'].label(),
        url: `${commonPath}/conversations/create-custom-channels`,
        badge: 'BETA'
      }, {
        label: apiEndpointsNavData['/conversations/inbox-and-messages'].label(),
        url: `${commonPath}/conversations/inbox-and-messages`
      }, {
        label: apiEndpointsNavData['/conversations/visitor-identification'].label(),
        url: `${commonPath}/conversations/visitor-identification`
      }
      /* {
      label: 'Working with chat bots',
      items: [
        {
          label: 'Code snippets in bots',
          url: '/conversations/code-snippets-in-bots',
        },
        {
          label: 'Webhooks in bots',
          url: '/conversations/webhooks-in-bots',
        },
      ],
      }, */]
    }, {
      label: breadcrumbs['crm'](),
      items: [{
        label: breadcrumbs['associations'](),
        items: [{
          label: apiEndpointsNavData['/crm/associations/association-details'].label(),
          url: `${commonPath}/crm/associations/association-details`
        }, {
          label: apiEndpointsNavData['/crm/associations/associations-schema'].label(),
          url: `${commonPath}/crm/associations/associations-schema`
        }]
      }, {
        label: breadcrumbs['commerce'](),
        items: [{
          label: apiEndpointsNavData['/crm/commerce/carts'].label(),
          url: `${commonPath}/crm/commerce/carts`,
          badge: 'NEW'
        }, {
          label: apiEndpointsNavData['/crm/commerce/discounts'].label(),
          url: `${commonPath}/crm/commerce/discounts`
        }, {
          label: apiEndpointsNavData['/crm/commerce/fees'].label(),
          url: `${commonPath}/crm/commerce/fees`
        }, {
          label: apiEndpointsNavData['/crm/commerce/invoices'].label(),
          url: `${commonPath}/crm/commerce/invoices`
        }, {
          label: apiEndpointsNavData['/crm/commerce/orders'].label(),
          url: `${commonPath}/crm/commerce/orders`,
          badge: 'NEW'
        }, {
          label: apiEndpointsNavData['/crm/commerce/payments'].label(),
          url: `${commonPath}/crm/commerce/payments`
        }, {
          label: apiEndpointsNavData['/crm/commerce/quotes'].label(),
          url: `${commonPath}/crm/commerce/quotes`
        }, {
          label: apiEndpointsNavData['/crm/commerce/subscriptions'].label(),
          url: `${commonPath}/crm/commerce/subscriptions`
        }, {
          label: apiEndpointsNavData['/crm/commerce/taxes'].label(),
          url: `${commonPath}/crm/commerce/taxes`
        }]
      }, {
        label: breadcrumbs['engagements'](),
        items: [{
          label: apiEndpointsNavData['/crm/engagements/engagement-details'].label(),
          url: `${commonPath}/crm/engagements/engagement-details`
        }, {
          label: apiEndpointsNavData['/crm/engagements/calls'].label(),
          url: `${commonPath}/crm/engagements/calls`
        }, {
          label: apiEndpointsNavData['/crm/engagements/communications'].label(),
          badge: 'NEW',
          url: `${commonPath}/crm/engagements/communications`
        }, {
          label: apiEndpointsNavData['/crm/engagements/email'].label(),
          url: `${commonPath}/crm/engagements/email`
        }, {
          label: apiEndpointsNavData['/crm/engagements/meetings'].label(),
          url: `${commonPath}/crm/engagements/meetings`
        }, {
          label: apiEndpointsNavData['/crm/engagements/notes'].label(),
          url: `${commonPath}/crm/engagements/notes`
        }, {
          label: apiEndpointsNavData['/crm/engagements/postal-mail'].label(),
          url: `${commonPath}/crm/engagements/postal-mail`
        }, {
          label: apiEndpointsNavData['/crm/engagements/tasks'].label(),
          url: `${commonPath}/crm/engagements/tasks`
        }]
      }, {
        label: breadcrumbs['extensions'](),
        items: [{
          label: apiEndpointsNavData['/crm/extensions/calling-sdk'].label(),
          url: `${commonPath}/crm/extensions/calling-sdk`
        }, {
          label: apiEndpointsNavData['/crm/extensions/crm-cards'].label(),
          url: `${commonPath}/crm/extensions/crm-cards`
        }, {
          label: apiEndpointsNavData['/crm/extensions/extension-details'].label(),
          url: `${commonPath}/crm/extensions/extension-details`
        }, {
          label: apiEndpointsNavData['/crm/extensions/timeline'].label(),
          url: `${commonPath}/crm/extensions/timeline`
        }, {
          label: apiEndpointsNavData['/crm/extensions/video-conferencing'].label(),
          url: `${commonPath}/crm/extensions/video-conferencing`
        }]
      }, {
        label: apiEndpointsNavData['/crm/contacts-timeline-embed'].label(),
        url: `${commonPath}/crm/contacts-timeline-embed`
      }, {
        label: apiEndpointsNavData['/crm/exports'].label(),
        url: `${commonPath}/crm/exports`
      }, {
        label: apiEndpointsNavData['/crm/imports'].label(),
        url: `${commonPath}/crm/imports`
      }, {
        label: apiEndpointsNavData['/crm/lists'].label(),
        url: `${commonPath}/crm/lists`,
        badge: 'BETA'
      }, {
        label: breadcrumbs['objects'](),
        items: [{
          label: apiEndpointsNavData['/crm/objects/companies'].label(),
          url: `${commonPath}/crm/objects/companies`
        }, {
          label: apiEndpointsNavData['/crm/objects/contacts'].label(),
          url: `${commonPath}/crm/objects/contacts`
        }, {
          label: apiEndpointsNavData['/crm/objects/custom-objects'].label(),
          url: `${commonPath}/crm/objects/custom-objects`
        }, {
          label: apiEndpointsNavData['/crm/objects/deals'].label(),
          url: `${commonPath}/crm/objects/deals`
        }, {
          label: apiEndpointsNavData['/crm/objects/deal-splits'].label(),
          url: `${commonPath}/crm/objects/deal-splits`,
          badge: 'BETA'
        }, {
          label: apiEndpointsNavData['/crm/objects/feedback-submissions'].label(),
          url: `${commonPath}/crm/objects/feedback-submissions`
        }, {
          label: apiEndpointsNavData['/crm/objects/goals'].label(),
          url: `${commonPath}/crm/objects/goals`,
          badge: 'NEW'
        }, {
          label: apiEndpointsNavData['/crm/objects/leads'].label(),
          url: `${commonPath}/crm/objects/leads`
        }, {
          label: apiEndpointsNavData['/crm/objects/line-items'].label(),
          url: `${commonPath}/crm/objects/line-items`
        }, {
          label: apiEndpointsNavData['/crm/objects/schemas'].label(),
          url: `${commonPath}/crm/objects/schemas`
        }, {
          label: apiEndpointsNavData['/crm/objects/products'].label(),
          url: `${commonPath}/crm/objects/products`
        }, {
          label: apiEndpointsNavData['/crm/objects/tickets'].label(),
          url: `${commonPath}/crm/objects/tickets`
        }]
      }, {
        label: apiEndpointsNavData['/crm/owners'].label(),
        url: `${commonPath}/crm/owners`
      }, {
        label: apiEndpointsNavData['/crm/pipelines'].label(),
        url: `${commonPath}/crm/pipelines`
      }, {
        label: apiEndpointsNavData['/crm/properties'].label(),
        url: `${commonPath}/crm/properties`
      }, {
        label: apiEndpointsNavData['/crm/sensitive-data'].label(),
        url: `${commonPath}/crm/sensitive-data`
      }]
    }, {
      label: breadcrumbs['library'](),
      items: [{
        label: apiEndpointsNavData['/library/files'].label(),
        url: `${commonPath}/library/files`
      }, {
        label: apiEndpointsNavData['/library/meetings'].label(),
        url: `${commonPath}/library/meetings`
      }]
    }, {
      label: breadcrumbs['marketing'](),
      items: [{
        label: apiEndpointsNavData['/marketing/calls-to-action-sdk'].label(),
        url: `${commonPath}/marketing/calls-to-action-sdk`
      }, {
        label: apiEndpointsNavData['/marketing/campaigns'].label(),
        url: `${commonPath}/marketing/campaigns`,
        badge: 'BETA'
      }, {
        label: breadcrumbs['emails'](),
        items: [{
          label: apiEndpointsNavData['/marketing/emails/marketing-emails'].label(),
          url: `${commonPath}/marketing/emails/marketing-emails`,
          badge: 'BETA'
        }, {
          label: apiEndpointsNavData['/marketing/emails/single-send-api'].label(),
          url: `${commonPath}/marketing/emails/single-send-api`,
          badge: 'NEW'
        }, {
          label: apiEndpointsNavData['/marketing/emails/transactional-emails'].label(),
          url: `${commonPath}/marketing/emails/transactional-emails`
        }]
      }, {
        label: apiEndpointsNavData['/marketing/forms'].label(),
        url: `${commonPath}/marketing/forms`
      }, {
        label: apiEndpointsNavData['/marketing/marketing-events'].label(),
        badge: 'NEW',
        url: `${commonPath}/marketing/marketing-events`
      }, ...(enviro.isProd() ? [{
        label: apiEndpointsNavData['/marketing/subscriptions'].label(),
        url: `${commonPath}/marketing/subscriptions`
      }] : []), {
        label: apiEndpointsNavData['/marketing/subscriptions-preferences'].label(),
        url: `${commonPath}/marketing/subscriptions-preferences`
      }

      // {
      //   label: apiEndpointsNavData['/marketing/social-media'].label(),
      //   url: '/marketing/social-media',
      // },
      // { label: 'Ecommerce bridge', url: generateUrlWithVersionFromBaseUrl('marketing/ecomm-bridge') },
      // TODO: both of these point to the same 'deprecated-apis') endpoint, kind of strange
      // { label: 'Marketing calendar', url: generateUrlWithVersionFromBaseUrl('deprecated-apis') },
      // { label: 'Social posts', url: generateUrlWithVersionFromBaseUrl('deprecated-apis') },
      ]
    }, {
      label: breadcrumbs['settings'](),
      items: [{
        label: apiEndpointsNavData['/settings/account-activity-api'].label(),
        url: `${commonPath}/settings/account-activity-api`
      }, {
        label: apiEndpointsNavData['/settings/account-information-api'].label(),
        url: `${commonPath}/settings/account-information-api`
      }, {
        label: apiEndpointsNavData['/settings/business-units-api'].label(),
        url: `${commonPath}/settings/business-units-api`
      }, {
        label: apiEndpointsNavData['/settings/currencies'].label(),
        url: `${commonPath}/settings/currencies`,
        badge: 'NEW'
      }, {
        label: breadcrumbs['users'](),
        items: [{
          label: apiEndpointsNavData['/settings/users/user-details'].label(),
          url: `${commonPath}/settings/users/user-details`,
          badge: 'NEW'
        }, {
          label: apiEndpointsNavData['/settings/users/user-provisioning'].label(),
          url: `${commonPath}/settings/users/user-provisioning`
        }]
      }]
    }, {
      label: breadcrumbs.otherResources(),
      items: [{
        label: apiEndpointsNavData['/other-resources/error-handling'].label(),
        url: `${commonPath}/other-resources/error-handling`
      }]
    }, {
      label: breadcrumbs.legal(),
      items: [{
        label: breadcrumbs.developerPolicy(),
        url: 'https://developers.hubspot.com/legal/developer-policy'
      }, {
        label: breadcrumbs.developerTerms(),
        url: 'https://legal.hubspot.com/developer-terms'
      }, {
        label: breadcrumbs.acceptableUsePolicy(),
        url: 'https://legal.hubspot.com/acceptable-use'
      }]
    }, {
      label: apiEndpointsNavData['/deprecated/overview'].label(),
      url: `${commonPath}/deprecated/overview`
    }]
  };
  return config;
});