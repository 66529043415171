"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50631386775;
const slug = exports.slug = 'guides/cms/content/overview';
const title = exports.title = 'HubSpotのCMS構成要素';
const name = exports.name = 'APAC JAPAN (ja) | [new] CMS Building Blocks_JA | 202108';
const metaDescription = exports.metaDescription = 'HubSpotのCMS上にウェブサイトを構築するために、開発者はさまざまな構成要素を利用します。この構成要素は、ビジネスニーズに応じたさまざまな柔軟性と制御を備えた、制作担当者の作業のためのデザインシステムを実現する際に活用できます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "テーマ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
  "label": "テンプレート",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "モジュール",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%A8%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89",
  "label": "モジュールとテーマのフィールド",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "グローバルコンテンツ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC%E3%81%A8%E3%83%8A%E3%83%93%E3%82%B2%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "メニューとナビゲーション",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0",
  "label": "フォーム",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E8%A8%AD%E5%AE%9A",
  "label": "ウェブサイト設定",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS構成要素"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMSソフトウェア上でウェブサイトを構築するために、開発者はさまざまな構成要素を利用します。この構成要素は、ビジネスニーズに応じたさまざまな柔軟性と制御を備えた、制作担当者の作業のためのデザインシステムを実現する際に活用できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "テーマ"
      }), "は、さまざまな開発者作成のアセットが移植可能な形式に統合されたパッケージであり、マーケティング担当者にとって快適なコンテンツ編集環境の提供に役立ちます。テーマは、ウェブサイトの外観と操作感を定義してマーケティング担当者に快適なコンテンツ編集環境を提供するための最上位の構成要素です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "テンプレート"
      }), "は、ページで使用する、基礎となるマークアップとスタイルを定義します。テンプレートを使用するページには、そのテンプレートで定義されたマークアップとスタイルが適用されます。制作担当者はページまたはEメールを作成するときに、まずテンプレートを選択し、そのテンプレートにコンテンツを追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "は、HubSpotのCMSのテンプレートやページに配置できる、再利用可能なコンポーネントです。モジュールにはマーケティング担当者用のコントロールを組み込み、ウェブサイトの再利用可能かつ編集可能なコンポーネントを作成するために連動するHubL/HTMLマークアップ、CSS、JavaScriptを追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールとテーマのフィールド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "フィールド"
      }), "は、制作担当者がテーマやモジュールに渡されるパラメーターを調整するために使用するコントロールです。フィールドを使用することで、開発者によって作成された構成要素を、コンテンツのさまざまなパーツにわたって再利用できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "グローバルコンテンツ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "グローバルコンテンツ"
      }), "を利用すると、マークアップとコンテンツをウェブサイト全体で共有できます。グローバルコンテンツを活用することで、ヘッダー、フッター、サイドバー、さらにウェブサイトのその他のグローバル要素などの管理がシンプルになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メニューとナビゲーション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/menus-and-navigation",
        children: "メニュー"
      }), "を利用すると、ウェブサイトのナビゲーション階層と情報アーキテクチャーを簡単に構築できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フォーム"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/forms",
        children: "フォーム"
      }), "を使用すると、ユーザーからさまざまな種類の情報（住所情報、Eメールアドレス、フィードバックなど）を取得できます。この情報をスマートコンテンツ、ワークフロー、コンテンツパーソナライズなどのHubSpot環境全体で、日々の業務に活用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ウェブサイト設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/website-settings",
        children: "ウェブサイト設定"
      }), "は、ロゴ、ファビコン、システムテンプレートなど、ウェブサイトの全体的なシステムのさまざまな設定を一元的に設定できる場所です。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}