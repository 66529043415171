"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79942329451;
const slug = exports.slug = 'guides/api/crm/commerce/quotes';
const title = exports.title = 'API do CRM | Orçamentos';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Quotes';
const metaDescription = exports.metaDescription = 'Os orçamentos são usados para compartilhar informações de preços com possíveis compradores. Os pontos de extremidade dos orçamentos permitem recuperar esses dados e sincronizá-los entre o HubSpot e outros sistemas.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "vis%C3%A3o-geral",
  "label": "Visão geral",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-um-or%C3%A7amento",
  "label": "Criar um orçamento",
  "parent": null
}, {
  "depth": 1,
  "id": "habilitar-assinaturas-eletr%C3%B4nicas",
  "label": "Habilitar assinaturas eletrônicas",
  "parent": "criar-um-or%C3%A7amento"
}, {
  "depth": 1,
  "id": "habilitar-pagamentos",
  "label": "Habilitar pagamentos",
  "parent": "criar-um-or%C3%A7amento"
}, {
  "depth": 0,
  "id": "adi%C3%A7%C3%A3o-de-associa%C3%A7%C3%B5es",
  "label": "Adição de associações",
  "parent": null
}, {
  "depth": 1,
  "id": "recupera%C3%A7%C3%A3o-de-ids-para-associa%C3%A7%C3%B5es",
  "label": "Recuperação de IDs para associações",
  "parent": "adi%C3%A7%C3%A3o-de-associa%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "cria%C3%A7%C3%A3o-de-associa%C3%A7%C3%B5es",
  "label": "Criação de associações",
  "parent": "adi%C3%A7%C3%A3o-de-associa%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "associa%C3%A7%C3%A3o-de-signat%C3%A1rios-do-or%C3%A7amento",
  "label": "Associação de signatários do orçamento",
  "parent": "adi%C3%A7%C3%A3o-de-associa%C3%A7%C3%B5es"
}, {
  "depth": 0,
  "id": "criar-um-or%C3%A7amento-com-associa%C3%A7%C3%B5es-solicita%C3%A7%C3%A3o-%C3%BAnica",
  "label": "Criar um orçamento com associações (solicitação única)",
  "parent": null
}, {
  "depth": 0,
  "id": "atualizar-estado-do-or%C3%A7amento",
  "label": "Atualizar estado do orçamento",
  "parent": null
}, {
  "depth": 0,
  "id": "recuperar-or%C3%A7amentos",
  "label": "Recuperar orçamentos",
  "parent": null
}, {
  "depth": 1,
  "id": "propriedades-definidas-pelo-estado-do-or%C3%A7amento",
  "label": "Propriedades definidas pelo estado do orçamento",
  "parent": "recuperar-or%C3%A7amentos"
}, {
  "depth": 0,
  "id": "escopos",
  "label": "Escopos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      h2: "h2",
      ol: "ol",
      li: "li",
      code: "code",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Table,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Table) _missingMdxReference("Table", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Orçamentos"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a API de orçamentos para criar, gerenciar e recuperar orçamentos de vendas para compartilhar informações de preços com potenciais compradores. Uma vez configurado, um orçamento pode ser compartilhado com um comprador em um URL especificado ou por meio de um PDF. Os usuários também podem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-quotes#manage-quotes",
        children: "gerenciar orçamentos no HubSpot"
      }), " para adicionar detalhes, atualizar associações e muito mais."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você configurou os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/set-up-payments",
        children: "pagamentos do HubSpot"
      }), " ou o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
        children: "processamento de pagamentos do Stripe"
      }), ", poderá configurar um orçamento para ser pago por meio desta API. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes#enable-payments",
        children: "orçamentos a pagar"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/example-quote-api.png",
        alt: "example-quote-api"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de caso de uso:"
      }), " você precisa criar uma proposta de contrato para um cliente que está interessado em comprar um dos seus pacotes anuais de serviços de auditoria de SEO."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba como criar um orçamento por meio da API e configurar suas várias propriedades, associações, estados e muito mais."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visão geral"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O processo de criação de orçamentos pode ser dividido nas seguintes etapas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar um orçamento:"
        }), " crie um orçamento com alguns detalhes, como nome e data de validade. Você também pode configurar o orçamento para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-e-signatures",
          children: "habilitar assinaturas eletrônicas"
        }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-payments",
          children: "pagamentos"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configurar associações:"
        }), " associe o orçamento a outros tipos de objetos do CRM, como itens de linha, um modelo de orçamento, um negócio e muito mais. Durante a próxima etapa, o orçamento herdará os valores de propriedade de alguns desses registros associados, bem como das configurações da conta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Definir o estado do orçamento:"
        }), " defina o estado do orçamento para refletir sua disponibilidade para ser compartilhada com os compradores. Ao definir o estado do orçamento, como torná-lo um rascunho editável ou um orçamento totalmente publicado e acessível ao público, ele ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#properties-set-by-quote-state",
          children: "herdará certas propriedades"
        }), " de seus registros de CRM e das configurações de conta associados."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Compartilhar o orçamento:"
        }), " assim que um orçamento for publicado, você pode compartilhá-lo com os seus compradores."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um orçamento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um orçamento, configure primeiro seus detalhes básicos, fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes"
      }), ". Posteriormente, você fará uma chamada separada para associar o orçamento a outros objetos, como o modelo de orçamento, itens de linha ou um negócio."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Dependendo do seu fluxo de trabalho preferido, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-quote-with-associations-single-request-",
          children: "criar um orçamento com associações por meio de uma solicitação POST"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No corpo do post, inclua as seguintes propriedades exigidas para configurar seus detalhes básicos."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100.018%",
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the quote."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100.018%",
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The date that the quote expires."
                  })]
                })
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The above are just the minimum required properties to get a quote started but ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes#adding-associations",
              children: "other properties"
            }), " are required to publish a quote. To see all available quote properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm/v3/properties/quotes"
            }), ". Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/properties",
              children: "properties API"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\"\n  }\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A resposta incluirá um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ID"
      }), ", que você usará para continuar a configurar o orçamento. Você pode atualizar as propriedades do orçamento a qualquer momento, fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes/{quoteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Habilitar assinaturas eletrônicas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para habilitar as assinaturas eletrônicas no orçamento, inclua a propriedade booleana ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_esign_enabled"
      }), " no corpo da solicitação com um valor de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Observe que você não poderá adicionar contrassignatários por meio da API; portanto, eles precisarão ser adicionados ao HubSpot antes de publicar o orçamento. Você também não pode publicar um orçamento com a assinatura eletrônica habilitada se tiver ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes#monitor-e-signature-usage",
        children: "excedido o limite mensal de assinaturas eletrônicas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\",\n    \"hs_esign_enabled\": \"true\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Posteriormente, você precisará associar o orçamento aos signatários. Embora os contatos que assinem o orçamento existam como contatos no HubSpot, eles são armazenados como um tipo de associação separado dos contatos. Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#associating-quote-signers",
        children: "associar orçamentos a signatários do orçamento"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Habilitar pagamentos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ativar os pagamentos em um orçamento, inclua a propriedade booleana ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_enabled"
      }), " no corpo da solicitação com um valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Dependendo do seu processador de pagamentos e dos métodos de pagamento aceitos, você também terá que definir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_type"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_allowed_payment_methods"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " a conta da HubSpot deve ter os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/set-up-payments",
          children: "os pagamentos da HubSpot"
        }), " ou o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
          children: "processamento de pagamentos do Stripe"
        }), " configurados antes de usar esse recurso."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_payment_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quando definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", permite que o orçamento receba pagamentos usando os pagamentos da HubSpot ou o processamento de pagamentos do Stripe. O valor padrão é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_payment_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Determina qual processador de pagamentos usar. O valor pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BYO_STRIPE"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_allowed_payment_methods"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os métodos de pagamento a serem usados (por exemplo, cartão de crédito)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_collect_billing_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quando definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", permite que o comprador insira o seu endereço de cobrança ao finalizar a compra."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_collect_shipping_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quando definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", permite que o comprador insira seu endereço de entrega ao finalizar a compra."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, para criar um orçamento e habilitar os pagamentos da HubSpot via cartão de crédito/débito ou ACH, sua solicitação seria:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\",\n    \"hs_payment_enabled\": \"true\",\n    \"hs_payment_type\": \"HUBSPOT\",\n    \"hs_allowed_payment_methods\": \"CREDIT_OR_DEBIT_CARD;ACH\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para acompanhar o pagamento, a HubSpot atualizará automaticamente as propriedades ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_status"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_date"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Quando você publica um orçamento com os pagamentos habilitados, a HubSpot define automaticamente a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), " como PENDENTE."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você usar ACH, quando o pagamento for processado, a HubSpot definirá automaticamente a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), " para EM PROCESSAMENTO."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Quando o pagamento for confirmado, a HubSpot definirá automaticamente a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), " como PAGO."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Quando o orçamento for pago, a HubSpot definirá automaticamente ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_date"
        }), " para a data e hora em que o pagamento foi confirmado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Uma vez confirmado, o pagamento será associado automaticamente ao orçamento. Se quiser obter mais informações sobre o pagamento, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "API de pagamentos"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adição de associações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um orçamento completo, você precisará associá-lo a outros registros do CRM, como itens de linha, usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de associações"
      }), ". A tabela abaixo mostra quais associações de registro do CRM são necessárias para um orçamento completo e quais são opcionais. Continue lendo para saber mais sobre como recuperar IDs e usá-los para criar as associações necessárias."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de objeto"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Obrigatório"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/line-items",
              children: "Itens de linha"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sim"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Os bens e/ou serviços que estão sendo vendidos por meio do orçamento. Você pode criar itens de linha de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "produtos da sua biblioteca de produtos"
            }), " ou criar itens de linha personalizados independentes."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/quotes/create-custom-quote-templates",
              children: "Modelo de orçamento"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sim"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O modelo que renderiza o orçamento, juntamente com o fornecimento de algumas definições de configuração padrão para o orçamento, como o idioma. Cada orçamento pode ser associado a um modelo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "Negócio"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sim"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O registro de negócio para acompanhar a receita e o ciclo de vida das vendas. Um orçamento herda valores do negócio associado, incluindo o proprietário e a moeda. Cada orçamento pode ser associado a um negócio."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Contato"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Compradores específicos que você está atendendo no orçamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "Empresa"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma empresa específica que você está atendendo no orçamento. Cada orçamento pode ser associado a uma empresa."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/discounts",
              children: "Descontos"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/fees",
              children: "taxas"
            }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/taxes",
              children: "impostos"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quaisquer descontos, taxas e impostos a serem aplicados no checkout. Ao determinar o valor total do orçamento, o HubSpot primeiro aplica descontos, seguido de taxas e impostos. Você pode usar o campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sort_order"
            }), " para reordenar objetos do mesmo tipo. Pode ser definido como valores fixos ou porcentagens, definindo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_type"
            }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FIXED"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PERCENT"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Recuperação de IDs para associações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para fazer cada associação, primeiro você precisará recuperar o ID de cada objeto que deseja associar. Para recuperar cada ID, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para o pontos de extremidade do objeto relevante, que segue o mesmo padrão em cada objeto do CRM. Ao fazer cada solicitação, você também pode incluir um parâmetro de consulta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " para retornar propriedades específicas quando necessário. Veja exemplos de solicitações ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para cada tipo de objeto."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "GET request for line items\n/crm/v3/objects/line_items?properties=name\n\nGET request for quote templates\n/crm/v3/objects/quote_template?properties=hs_name\n\nGET request for deals\n/crm/v3/objects/deals?properties=dealname\n\nGET request for contacts\n/crm/v3/objects/contacts?properties=email\n\nGET request for companies\n/crm/v3/objects/companies?properties=name\n\nGET request for discounts\ncrm/v3/objects/discounts?properties=hs_type,hs_value\n\nGET request for fees\ncrm/v3/objects/fees?properties=hs_type,hs_value\n\nGET request for taxes\ncrm/v3/objects/taxes?properties=hs_type,hs_value\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#GET request for line items\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/line_items?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for quote templates\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/quote_templates?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for deals\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/deals?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for contacts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/contacts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for companies\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/companies?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for discounts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/discounts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for fees\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/fees?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for taxes\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/taxes?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada chamada bem-sucedida retornará uma resposta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " com detalhes para cada tipo de objeto buscado. Você usará o valor no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " para definir associações na próxima etapa."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example quote template GET response\n{\n  \"results\": [\n    {\n      \"id\": \"235425923863\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Basic\",\n        \"hs_object_id\": \"235425923863\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"235425923864\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Modern\",\n        \"hs_object_id\": \"235425923864\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"235425923865\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Original\",\n        \"hs_object_id\": \"235425923865\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criação de associações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com os IDs recuperados, agora você pode fazer chamadas para a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de associações"
      }), " para criar associações."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para cada tipo de objeto que você deseja associar a um orçamento, será necessário realizar uma chamada separada, fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " com a estrutura de URL abaixo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/quotes/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do orçamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de objeto ao qual você está fazendo a associação. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_items"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deals"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote_template"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do objeto ao qual você está associando o orçamento."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja exemplos de solicitações ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para cada tipo de objeto."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "PUT request to associate a line item\n/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{lineItemId}\n\nPUT request to associate a quote template\n/crm/v4/objects/quotes/{quoteId}/associations/default/quote_template/{quoteTemplateId}\n\nPUT request to associate a deal\n/crm/v4/objects/quotes/{quoteId}/associations/default/deals/{dealId}\n\nPUT request to associate contacts\n/crm/v4/objects/quotes/{quoteId}/associations/default/contacts/{contactId}\n\nPUT request to associate companies\n/crm/v4/objects/quotes/{quoteId}/associations/default/companies/{companyId}\n\nPUT request to associate discounts\n/crm/v4/objects/quotes/{quoteId}/associations/default/discounts/{discountId}\n\nPUT request to associate fees\n/crm/v4/objects/quotes/{quoteId}/associations/default/fees/{feeId}\n\nPUT request to associate taxes\n/crm/v4/objects/quotes/{quoteId}/associations/default/taxes/{taxId}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#PUT request to associate line items\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{lineItemId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a quote template\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quote_ID}/associations/default/quote_template/{quoteTemplateId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a deal\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/deals/{dealId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate contacts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/contacts/{contactId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a company\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/companies/{companyId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate discounts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/discounts/{discountId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate fees\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/fees/{feeId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate taxes\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{taxId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As an example, if your quote has an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456"
      }), ", the requests to associate the quote might include the following:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Line items (IDs: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "55555"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "66666"
          }), "):"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/quotes/123456/associations/default/line_items/55555"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/quotes/123456/associations/default/line_items/66666"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quote template (ID:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "987654"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: "):"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/quotes/123456/associations/default/quote_template/987654"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Deal (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "345345"
          }), "):"]
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/quotes/123456/associations/default/deals/345345"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada associação bem-sucedida retornará uma resposta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " com detalhes sobre a associação. As chamadas acima irão associar os objetos em ambas as direções, com cada direção tendo o seu próprio ID. Por exemplo, se você associar o orçamento a um modelo de orçamento, a resposta descreverá a associação de ambos. Na resposta de exemplo abaixo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "286"
      }), " é o ID do tipo de associação de orçamento para modelo de orçamento e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "285"
      }), " é o ID do tipo de associação modelo de orçamento para orçamento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"115045534742\"\n      },\n      \"to\": {\n        \"id\": \"102848290\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 285\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"102848290\"\n      },\n      \"to\": {\n        \"id\": \"115045534742\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 286\n      }\n    }\n  ],\n  \"startedAt\": \"2023-10-12T16:48:40.624Z\",\n  \"completedAt\": \"2023-10-12T16:48:40.712Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " ao associar um orçamento a um modelo de orçamento, lembre-se dos seguintes limites:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos de orçamento devem ser ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/quotes/create-custom-quote-templates",
            children: "criados"
          }), " antes de serem associados a um orçamento."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Um orçamento pode ser associado somente a um modelo de orçamento."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Esta API não é compatível com orçamentos herdados ou de proposta. Somente o tipo de modelo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CUSTOMIZABLE_QUOTE_TEMPLATE"
          }), " pode ser usado."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associação de signatários do orçamento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se estiver ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#enable-e-signatures",
        children: "habilitando o orçamento para assinaturas eletrônicas"
      }), ", você também precisará criar uma associação entre o orçamento e os contatos que estão assinando, usando um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations#associate-records-with-a-label",
        children: "rótulo de associação"
      }), " específico de orçamento para contato."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em vez de usar os pontos de extremidade de associação padrão mostrados acima, você precisará fazer uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para o seguinte URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/quote/{quoteId}/associations/contact/{contactId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No corpo da solicitação, você precisará especificar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), ", conforme mostrado abaixo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body\n[\n  {\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 702\n  }\n]\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body\n[\n  {\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 702\n  }\n]\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um orçamento com associações (solicitação única)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O corpo da solicitação a seguir criará um novo orçamento com associações para um modelo de orçamento, um negócio, dois itens de linha e um contato."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quote"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%",
              "height": "590.032px"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "135.672px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px",
                    "height": "135.672px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "properties"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "object"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Quote details, which can be retrieved through the ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/api/crm/properties",
                      children: "properties API"
                    }), ". Required properties are: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), "."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the quote."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The date that the quote expires."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "135.672px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "135.672px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_status"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "#update-quote-status",
                      children: "quote status"
                    }), ". Omitting this property on create will prevent users from being able to edit the quote in HubSpot."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "associations"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "array"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The quote's associated records. For a quote to be publishable, it must have an associated deal and quote template. The line items should be created separately from the line items on the associated deal."
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "To set each association, include a separate object in the associations array with the following fields:"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "to: the ID of the record to associate with the quote."
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: ["associationCategory: the ", (0, _jsxRuntime.jsx)(_components.a, {
                        href: "/guides/api/crm/associations#retrieve-association-types",
                        children: "type of association"
                      }), ". Can be HUBSPOT_DEFINED or USER_DEFINED."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: ["associationTypeId: the ID of the type of association being made:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
                        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "286: quote to quote template"
                        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "64: quote to deal"
                        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "67: quote to line item"
                        }), "\n"]
                      }), "\n"]
                    }), "\n"]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/api/crm/associations#association-type-id-values",
                      children: "association type IDs"
                    }), "."]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// POST request to https://api.hubapi.com/crm/v3/objects/quote\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-09-30\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 115045534742\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 286\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 14795354663\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 64\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 75895447\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 67\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 256143985\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 67\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " esses itens de linha devem ser diferentes dos itens de linha em outros objetos, mesmo que estejam associados (por exemplo, associar um orçamento a um negócio). Consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "documentação da API de itens de linha"
        }), " para obter mais informações."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizar estado do orçamento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O estado de um orçamento descreve até que ponto ele está no processo de criação, desde a configuração inicial até a publicação e o acesso público. O estado do orçamento também pode refletir o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/approve-quotes",
        children: "processo de aprovação do orçamento"
      }), ", se as aprovações do orçamento estiverem ativadas para a conta. Ao definir o estado de um orçamento, o HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#properties-set-by-quote-state",
        children: "preencherá automaticamente certas propriedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode atualizar o estado de um orçamento fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quote/{quoteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O estado de um orçamento é baseado no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_status"
      }), ". Alguns estados do orçamento permitem que os usuários editem, publiquem e usem o orçamento nos fluxos de trabalho de aprovação. Veja os estados de orçamento disponíveis."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sem estado:"
        }), " se nenhum valor for fornecido para o campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_status"
        }), ", o orçamento estará no estado ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Mínimo"
        }), ". O orçamento aparecerá na página de índice da ferramenta de orçamentos, mas não pode ser editada diretamente. Os orçamentos nesse estado ainda podem ser usados na automação, como a ferramenta de sequências, e também estão disponíveis para análise dentro da ferramenta de relatório."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DRAFT"
        }), ": permite que o orçamento seja editado no HubSpot. Esse estado pode ser útil quando o orçamento não estiver totalmente configurado ou se você preferir que os representantes de vendas concluam o processo de configuração do orçamento no HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "APPROVAL_NOT_NEEDED"
        }), ": publica o orçamento em um URL de acesso público (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), ") sem precisar ser ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "aprovado"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING_APPROVAL"
        }), ": indica que o orçamento está ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "aguardando aprovação"
        }), " para que possa ser publicado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "APPROVED"
        }), ": o orçamento foi ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "aprovado"
        }), " e agora está publicado em um URL de acesso público (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "REJECTED"
        }), ": indica que o orçamento foi configurado, mas não foi ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "aprovado"
        }), " para publicação e deve ser editado antes que possa ser enviado para aprovação novamente."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " se estiver ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enabling-e-signatures",
          children: "habilitando as assinaturas eletrônicas"
        }), " no orçamento, não poderá publicar o orçamento se exceder o seu ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes#monitor-e-signature-usage",
          children: "limite mensal de assinaturas eletrônicas"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, a solicitação a seguir publicaria o orçamento em um URL acessível ao público."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/quote/{QUOTE_ID}\n{\n  \"properties\": {\n    \"hs_status\": \"APPROVAL_NOT_NEEDED\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " por padrão, o HubSpot definirá a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_template_type"
        }), " do orçamento como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CUSTOMIZABLE_QUOTE_TEMPLATE"
        }), " depois de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#update-quote-state",
          children: "atualizar o estado do orçamento"
        }), ". Este tipo de modelo é suportado pela API v3, enquanto que os seguintes tipos são modelos antigos que não são mais suportados:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "QUOTE"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROPOSAL"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar orçamentos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode recuperar contatos individualmente ou em lotes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar uma propriedade individual, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/properties/{object}/{propertyName}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para solicitar uma lista de todos os orçamentos, faça uma solicitação", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/quotes"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ambos os pontos de extremidade, você pode incluir os seguintes parâmetros de consulta no URL da solicitação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propriedades"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades a serem retornadas em resposta. Se o quotecontact solicitado não tiver um valor para uma propriedade, ele não será exibido na resposta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades atuais e do histórico a serem retornadas em resposta. Se o orçamento solicitado não tiver um valor para uma propriedade, ele não será exibido na resposta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista separada por vírgulas de objetos para recuperar IDs associados. Todas as associações especificadas que não existem não serão retornadas na resposta. Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de associações."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar um lote de orçamentos específicos por seus IDs, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/quotes/batch/read"
      }), " e inclua os IDs no corpo da solicitação. Você também pode incluir uma matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " para especificar quais propriedades retornar. O ponto de extremidade em lote não pode recuperar associações. Saiba como fazer associações de leitura em lote com a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de associações"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n \"inputs\": [\n  {\"id\": \"342007287\"},\n  {\"id\":\"81204505203\"}\n ],\n \"properties\": [\n  \"hs_content\", \"hs_sentiment\",\n  \"hs_submission_timestamp\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriedades definidas pelo estado do orçamento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A atualização do estado do orçamento atualizará as seguintes propriedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_amount"
        }), ": calculado com base em quaisquer itens de linha, impostos, descontos e taxas associados."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_domain"
        }), ": definido a partir do modelo de orçamento associado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_slug"
        }), ": gerado aleatoriamente se um não for fornecido explicitamente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_total_preference"
        }), ": definido com base nas configurações da sua conta. Se você não tiver configurado essa configuração, ela será padronizada para o valor do campo total_first_payment."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_timezone"
        }), ": o padrão é o fuso horário da sua conta da HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_locale"
        }), ": definido a partir do modelo de orçamento associado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_number"
        }), ": define com base na data e hora atuais, a menos que uma seja fornecida."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_language"
        }), ": definido a partir do modelo de orçamento associado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_currency"
        }), ": definido com base no negócio associado. Se você não associou um negócio ao orçamento, ele será padronizado para a moeda padrão da sua conta da HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além disso, as propriedades abaixo serão calculadas quando o orçamento estiver definiu como um estado publicado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_pdf_download_link"
        }), ": preenchido com uma URL de um PDF para o orçamento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_locked"
        }), ": set to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), ". Para modificar qualquer propriedade depois de publicar um orçamento, você deve primeiro atualizar o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_status"
        }), " do orçamento de volta para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DRAFT"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING_APPROVAL"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "REJECTED"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), ": o URL de acesso público do orçamento. Esta é uma propriedade somente leitura e não pode ser definida por meio da API após a publicação."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_esign_num_signers_required"
        }), ": exibe o número de assinaturas necessárias se as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enable-e-signatures",
          children: "assinaturas eletrônicas estiverem habilitadas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), ": o status do pagamento, caso tenha habilitado os pagamentos. Após a publicação com os pagamentos ativados, esta propriedade será definida como PENDENTE. Assim que o comprador fizer o pagamento por meio do orçamento, o status será atualizado automaticamente. Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-payments",
          children: "habilitar os pagamentos"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Escopos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os seguintes escopos são necessários para que um aplicativo crie um orçamento publicável válido:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.quotes.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.quotes.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.line_items.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.line_items.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.owners.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.read"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.quote.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.line_items.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.contacts.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.deals.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.companies.read"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}