"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 38575989237;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-completions';
const title = exports.title = 'Enviar finalizaciones de evento personalizado';
const name = exports.name = 'Enviar finalizaciones de evento personalizado';
const metaDescription = exports.metaDescription = 'Los eventos personalizados te permiten hacer seguimiento de la actividad avanzada a través de una API de JavaScript o HTTP. La API de eventos se puede usar para obtener detalles sobre tus eventos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "definir-el-evento",
  "label": "Definir el evento",
  "parent": null
}, {
  "depth": 0,
  "id": "enviar-datos-de-eventos",
  "label": "Enviar datos de eventos",
  "parent": null
}, {
  "depth": 0,
  "id": "recuperar-datos-de-eventos",
  "label": "Recuperar datos de eventos",
  "parent": null
}, {
  "depth": 0,
  "id": "propiedades-de-eventos",
  "label": "Propiedades de eventos",
  "parent": null
}, {
  "depth": 0,
  "id": "informes-de-atribuciones",
  "label": "Informes de atribuciones",
  "parent": null
}, {
  "depth": 1,
  "id": "tipo-de-recurso",
  "label": "Tipo de recurso",
  "parent": "informes-de-atribuciones"
}, {
  "depth": 1,
  "id": "t%C3%ADtulo-del-recurso",
  "label": "Título del recurso",
  "parent": "informes-de-atribuciones"
}, {
  "depth": 1,
  "id": "fuentes-de-interacci%C3%B3n",
  "label": "Fuentes de interacción",
  "parent": "informes-de-atribuciones"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      em: "em",
      strong: "strong",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Tag,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Enviar finalizaciones de evento personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los eventos personalizados son eventos definidos por la cuenta que almacenan detalles del evento en las propiedades del evento. Hay tres tipos de eventos personalizados que puedes crear en HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "Los eventos Elemento al que se hizo clic "
        }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "URL visitada"
        }), " son eventos personalizados que el código de seguimiento rellena automáticamente con datos. Puedes personalizar aún más estos eventos actualizando tu código de seguimiento con la función ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code#events-js-api",
          children: "trackCustomEvent"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
          children: "Los eventos con seguimiento manual "
        }), " son eventos personalizados que son exclusivos de tu negocio y que no son capturados automáticamente por HubSpot o por una integración. Puedes enviar manualmente datos a estos eventos a través de esta API."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, descubre cómo crear un evento personalizado con seguimiento manual, enviar datos de eventos a través de la API y usar los datos de eventos una vez capturados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Definir el evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar datos de finalización de eventos a HubSpot, primero debes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "definir el evento en sí"
      }), ", incluidos sus metadatos, asociaciones de objetos de CRM y propiedades. Puedes definir eventos usando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions",
        children: "API de definición de evento personalizada"
      }), " o, si tienes una suscripción a ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        }), " Enterprise"]
      }), ", puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
        children: "crear el evento en HubSpot"
      }), ". Al crear el evento, HubSpot incluirá un conjunto de propiedades de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "evento predeterminadas"
      }), " que puedes usar para almacenar datos del evento. También puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "crear propiedades adicionales"
      }), " para el evento. Estas propiedades se pueden crear o editar en cualquier momento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que hayas configurado tu evento, puedes enviarle datos a través de la API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Enviar datos de eventos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar datos de eventos a HubSpot, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/events/v3/send"
      }), " con los datos del evento en el cuerpo de la solicitud. Antes de enviar datos de eventos, haz una reseña los límites a continuación, ya que exceder estos límites ocasionará un error."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST to https://api.hubspot.com/events/v3/send\n{\n  \"eventName\": \"pe1234567_login_event\",\n  \"objectId\": \"608051\",\n  \"occurredAt\": \"2024-06-28T12:09:31Z\",\n  \"properties\": {\n    \"hs_city\": \"Cambridge\",\n    \"hs_country\": \"United States\",\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\",\n    \"hs_touchpoint_source\": \"DIRECT_TRAFFIC\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El nombre interno del evento. Puedes encontrar esto ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "consultando tus definiciones de eventos existentes"
            }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/reports/create-custom-behavioral-events-with-the-code-wizard#find-internal-name",
              children: "dentro de la aplicación HubSpot"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID del registro del CRM al que se asociará el evento. Para contactos, alternativamente puedes usar el campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utk"
            }), " para identificar el contacto por dirección de correo electrónico o por el ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/tracking-code",
              children: "token de usuario de HubSpot"
            }), ". Todos los demás tipos de objetos requieren ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["De forma predeterminada, HubSpot establecerá la marca de tiempo de finalización del evento en el momento en que se envía la solicitud. Para especificar la hora de finalización del evento, incluye una marca de tiempo en un campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            }), " del cuerpo de la solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " (", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_8601",
              children: "formato ISO 8601"
            }), "). Esto puede ser especialmente útil para que los datos de eventos se actualicen para reflejar con mayor precisión la finalización de eventos en la vida real."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Las propiedades del evento al que se enviarán los datos. Esto puede incluir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
              children: "las propiedades de evento predeterminadas de HubSpot"
            }), " o cualquier ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
              children: "propiedad personalizada"
            }), " que hayas definido para el evento. La mayoría de las propiedades de evento predeterminadas son propiedades de cadena, pero puedes reseñar todas las propiedades de evento ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "consultando la definición del evento "
            }), " o navegando al evento en HubSpot. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#event-properties",
              children: "propiedades personalizadas del evento"
            }), " a continuación."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Exceder cualquiera de los siguientes límites hará que la solicitud falle:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "La etiqueta de la propiedad y el nombre interno están limitados a 50 caracteres."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Las propiedades de URL y referencia pueden recibir hasta 1024 caracteres, mientras que todas las demás propiedades pueden recibir hasta 256 caracteres."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Cada finalización de evento puede contener datos de hasta 50 propiedades."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Los nombres internos de las propiedades deben empezar por una letra y contener sólo letras minúsculas de la a a la z, números del 0 al 9 y guiones bajos."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Las propiedades con el mismo nombre interno después de las minúsculas se consideran duplicadas, y sólo se utilizará una de las propiedades al finalizar. HubSpot ordenará en orden lexicográfico ascendente y mantendrá las últimas propiedades vistas entre las primeras 50 propiedades."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Hay un límite de 500 definiciones de eventos únicos por cuenta."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Hay un límite de 30 millones de finalizaciones de eventos por mes."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar datos de eventos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "recuperar los datos de eventos de un contacto"
      }), ", haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId={CONTACT_ID}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La solicitud anterior incluye:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**eventType:**El nombre interno del evento."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**objectType:**Selecciona el tipo de objeto del registro."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectId:"
        }), " el ID del contacto."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propiedades de eventos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los datos de eventos se almacenan en propiedades, ya sea en el conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "propiedades de eventos predeterminadas"
      }), " o en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "propiedades definidas personalizadas"
      }), ". Al enviar datos de eventos, podrás actualizar las propiedades incluyéndolas en un objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"properties\": {\n    \"property1\": \"string\",\n    \"property2\": \"string\",\n    \"property3\": \"string\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los valores que envíes dependerán del tipo de propiedad del evento. La mayoría de las propiedades de evento predeterminadas son texto de una sola línea (cadena). Sin embargo, puedes crear propiedades personalizadas de cualquier tipo para cada evento. Reseña la siguiente tabla al formatear valores de propiedad."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una cadena que representa un conjunto de opciones. Al enviar varios valores, sepáralos con punto y coma. En HubSpot, este tipo corresponde a las propiedades de selección desplegable, selección en lista y múltiples casilla de verificación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una marca de tiempo en forma de milisegundos de época o ISO8601. En HubSpot, este tipo corresponde a las propiedades del selector de fechas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una cadena de texto sin formato, limitada a 65.536 caracteres. En HubSpot, este tipo corresponde a propiedades de texto de una sola línea y de varias líneas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor de número que contiene dígitos numéricos y, como máximo, un decimal. En HubSpot, este tipo corresponde a las propiedades numéricas y calculadas."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ver las propiedades disponibles de un evento:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Informes"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Gestión de datos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eventos personalizados"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haga clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " del evento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Propiedades"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "En la tabla de propiedades, ve el tipo de propiedad bajo el nombre de la propiedad."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-event-properties-table.png",
        alt: "tabla-de-propiedades-de-eventos-personalizados"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informes de atribuciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los eventos de JavaScript, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "el elemento seleccionado"
      }), " y los eventos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "URL visitada"
      }), ", se rellenan automáticamente con datos de tipo de recurso e interacción para los informes de atribución. Para incluir los mismos datos para eventos con seguimiento manual, deberás incluir manualmente los datos en el cuerpo de la solicitud usando propiedades de evento. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/analyze-custom-behavioral-events",
        children: "analizando eventos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, conoce los valores disponibles para los tipos de recursos y las fuentes de interacción, junto con las solicitudes de ejemplo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tipo de recurso"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para atribuir un tipo de recurso específico a una solicitud personalizada de evento de comportamiento, incluye la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_content_type"
      }), " en el cuerpo de la solicitud. Por ejemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["También puedes utilizar la propiedad ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), ". Si ambos ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), " y ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), " están incluidos en una solicitud, ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), " anulará el valor ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los tipos de contenido estándar de HubSpot, como landing pages y publicaciones de blog, se pueden representar con los siguientes valores:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Valor"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "STANDARD_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con una página de sitio web."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con una landing page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con un artículo de blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con un artículo de la base de conocimientos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para todos los demás tipos de activos, usa los siguientes valores:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Valor"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "AD"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con un anuncio, como un anuncio de Facebook o Google."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con una llamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT_IMPORT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción a través de una importación de contacto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción relacionada con una conversación de HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CUSTOM_BEHAVIORAL_EVENT_NAME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El nombre interno de un evento personalizado, como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pe123456_manually_tracked_event"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con un correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con una página externa."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción a través de una integración."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una interacción con un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "evento de marketing"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEDIA_BRIDGE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una interacción a través del ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/cms/media-bridge",
              children: "centro multimedia"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con una reunión."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES_EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con un correo electrónico de ventas individual."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SEQUENCE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con una secuencia."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con una publicación en redes sociales."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una interacción con un recurso que no pertenece a una de las categorías anteriores."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Título del recurso"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para atribuir un evento personalizado a un recurso, incluye la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_asset_title"
      }), " en tu solicitud con el nombre del recurso formateado como cadena. Por ejemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_title\": \"Sweepstakes Sign Up\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fuentes de interacción"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para atribuir un evento personalizado de comportamiento a una fuente específica, incluye la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_touchpoint_source"
      }), " en tu solicitud con uno de los siguientes valores:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Valor"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es una conversación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DIRECT_TRAFFIC"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es el tráfico directo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL_MARKETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es un correo electrónico de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_CRM"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es el HubSpot CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es una integración."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La fuente de interacción es un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "evento de marketing"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OFFLINE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción está offline."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ORGANIC_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es la búsqueda orgánica."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER_CAMPAIGNS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es de una campaña sin categorizar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es un anuncio de búsqueda de pago."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SOCIAL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es un anuncio pagado de redes sociales."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "REFERRALS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es una referencia."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es ventas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_MEDIA"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente de interacción es redes sociales (no un anuncio pagado de redes sociales)."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}