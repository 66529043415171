"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611100;
const slug = exports.slug = 'guides/cms/content/accessibility';
const title = exports.title = 'Accessibility';
const name = exports.name = '[new] Accessibility';
const metaDescription = exports.metaDescription = 'HubSpot is committed to making sure that we have provided the necessary tools to help you make your content accessible. While not a definitive guide, here are some steps that you can take to make your content accessible.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "accessibility-is-not-a-feature",
  "label": "Accessibility is not a feature",
  "parent": null
}, {
  "depth": 0,
  "id": "provide-text-alternatives-for-any-non-text-content",
  "label": "Provide text alternatives for any non-text content",
  "parent": null
}, {
  "depth": 1,
  "id": "use-a11y-friendly-lazy-loading-solutions",
  "label": "Use a11y friendly lazy loading solutions",
  "parent": "provide-text-alternatives-for-any-non-text-content"
}, {
  "depth": 0,
  "id": "make-sure-that-all-links-and-form-inputs-have-descriptive-text",
  "label": "Make sure that all links and form inputs have descriptive text",
  "parent": null
}, {
  "depth": 0,
  "id": "adding-a-skip-to-content-link",
  "label": "Adding a skip to content link",
  "parent": null
}, {
  "depth": 0,
  "id": "use-semantic-markup",
  "label": "Use semantic markup",
  "parent": null
}, {
  "depth": 0,
  "id": "keyboard-navigation",
  "label": "Keyboard navigation",
  "parent": null
}, {
  "depth": 0,
  "id": "fallback-to-roles-if-needed",
  "label": "Fallback to roles if needed",
  "parent": null
}, {
  "depth": 0,
  "id": "additional-design-tips",
  "label": "Additional design tips",
  "parent": null
}, {
  "depth": 0,
  "id": "content-writing",
  "label": "Content writing",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-specific-recommendations",
  "label": "HubSpot specific recommendations",
  "parent": null
}, {
  "depth": 1,
  "id": "use-custom-modules-and-templates-that-follow-a11y-best-practices",
  "label": "Use custom modules and templates that follow a11y best practices",
  "parent": "hubspot-specific-recommendations"
}, {
  "depth": 0,
  "id": "beware-of-one-stop-solutions",
  "label": "Beware of one-stop solutions",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-developer-podcast",
  "label": "HubSpot Developer Podcast",
  "parent": null
}, {
  "depth": 0,
  "id": "more-accessibility-information",
  "label": "More accessibility information",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      code: "code",
      pre: "pre",
      h3: "h3",
      ul: "ul",
      li: "li",
      ol: "ol",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      IFrame
    } = _components;
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Accessibility"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As websites continue to become more and more critical to businesses, ensuring that content on websites is accessible to all visitors has become more vital than ever. In the United States, this is often called ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www.section508.gov/",
        children: "section 508"
      }), " compliance, which refers to the section of the Reauthorized Rehabilitation Act of 1998 that requires federal agencies to make electronic and information technology accessible to people with disabilities. While section 508 compliance is a good baseline, increasingly the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility/Understanding_WCAG",
        children: "Web Content Accessibility Guidelines (WCAG)"
      }), " are being used as the standard for creating accessible content on the web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Accessibility is not a feature"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The legal aspect shouldn't be your motivator for providing a good experience for your users. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.cdc.gov/ncbddd/disabilityandhealth/infographic-disability-impacts-all.html",
        children: "One in four Americans have a disability according to the CDC"
      }), ". Choosing not to provide a good experience for 25% of visitors is like a physical store denying every fourth visitor from entering. Not only will those customers be unhappy, they likely will not come back or recommend your services."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A common phrase among developers is that \"accessibility is not a feature\". The idea is don't treat accessibility like a bonus thing to add, or something to deal with at the end of a project."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Accessibility requires thought, empathy, and understanding. When approached early on in a project you can design and develop solutions instead of needing to re-architect something later."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Often times solving for accessibility solves for your other priorities as a developer"
      }), ", user experience, performance, and SEO are a few top concerns that are directly intertwined with accessibility. Improving one often improves the other."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Empathy is a huge part of accessibility that is easy to overlook. As developers we want to automate everything, if something is hard or time-consuming to do, we want tools to either do it for us or tell us the right way to do it. Unfortunately, these tools can only scratch the surface, because they are not human, they can't truly experience the page the way a human can. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "It is technically possible to create a web page that passes most automated accessibility tests with flying colors but is completely unusable to all humans, sighted, blind, deaf, color blind, limited or unlimited motor function."
      }), " You can technically meet requirements, but provide an unusable frustrating experience that completely alienates people. The takeaway is this: don't rely on the tools, test your work, empathize with your users, collect feedback."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While not a definitive guide, here are some steps that you can take to make your content accessible, as well as resources for further diving in."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Provide text alternatives for any non-text content"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All images, icons, videos, and buttons that convey meaning or are interactive should have a text alternative. Not only is this good for visitors that are consuming your content via a screen reader, but it is also great for search engines. The alternative text should describe the content of the image. Most images on your site can likely be edited in the page editor, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-pages-editor/how-do-i-add-alt-text-to-my-image",
        children: "setting alt text in the page editor is easy"
      }), ". Inside of custom modules and templates, however, you need to make sure that alt text specified in page editors is honored."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This means making sure if you have an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<img>"
      }), " element that the alt text the user provides is actually used."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" />\n\nBad:\n<img src=\"{{ module.image.src }}\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If for some reason you are not making an image editable in the page editor, you should hard-code the alt text."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There is one exception to this alt text rule. If your image provides no additional context and is purely just presentational it is better to have a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.w3.org/WAI/tutorials/images/tips/",
        children: "\"null\" alt text"
      }), " value than to go without an alt attribute altogether."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good if only presentational: <img src=\"{{ module.image.src }}\" alt=\"\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For videos, use a service that supports captions and consider including a transcript. Some services that support uploading transcripts include ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.vidyard.com/hc/en-us/articles/360009876234-Make-your-Vidyard-players-accessible",
        children: "Vidyard"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://support.google.com/youtube/answer/2734799",
        children: "YouTube"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://help.vimeo.com/hc/en-us/articles/224968828-Captions-and-subtitles",
        children: "Vimeo"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://wistia.com/support/player/captions",
        children: "Wistia"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Use a11y friendly lazy loading solutions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lazy image loading is a common performance enhancement technique for building websites. The method used to actually lazy load matters for accessibility."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScript solutions for this typically rely on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " attribute of images to be something untrue (like a small ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".gif"
      }), " file) instead of the true source of the image, which is held in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-src"
      }), " attribute until the user scrolls the image close to view. This means that initially the image is inaccessible to a screen reader user who is navigating the page, especially one using a rotor to look through the entire page's contents without even scrolling. Additionally, if JavaScript isn't loaded on a page, these lazy loading solutions will fail, and therefore, leave assistive technology users without the proper images on the page."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/native-lazy-loading/",
        children: "native image lazy loading"
      }), " avoids this problem, keeping the image markup the same, regardless of whether JavaScript loads."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img\n  src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n  loading=\"lazy\"\n  height=\"200px\"\n  width=\"400px\"\n/>\n\nBad:\n<img\n  src=\"1px.gif\"\n  data-src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n/>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot supports ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/lazy-loading",
        children: "browser-based lazy loading"
      }), " in custom modules. It is recommended you use it."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Make sure that all links and form inputs have descriptive text"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Links, buttons, and form elements all need to have actual text that indicates what it does or where it goes. Otherwise screen readers will read out that the user has a link or button selected, but will have no idea what it does."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If using an icon, provide screen reader text. For example, some standard HubSpot templates use an icon shortcode:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"https://www.facebook.com\"\n  ><i class=\"fab fa-facebook-f\"></i\n  ><span class=\"screen-reader-text\">Facebook</span></a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Don’t use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'display: none'"
      }), " to hide form labels and other descriptive text. This prevents text from being read aloud for those with difficulty seeing, as well as hurts users who can see but have trouble seeing the text due to lack of contrast on the placeholder text. Not to mention, who hasn't started filling out a form or had a form get auto-filled for them, but had no idea if it was entered correctly because the form field labels were invisible."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you absolutely have to have hidden text or hidden text could provide additional context that a sighted user wouldn't need. Use CSS that makes the text invisible without hiding it from screen readers or add the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'.screen-reader-text'"
      }), " class."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".screen-reader-text {\n  clip: rect(1px, 1px, 1px, 1px);\n  height: 1px;\n  overflow: hidden;\n  position: absolute !important;\n  width: 1px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adding a skip to content link"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When a visitor is navigating using a screen reader, or just simply using their keyboard, it is super helpful if there is a way to skip over the portions of the page that are repeated such as a header. We can do this by adding a link that points to the main content of the page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In your header, add HTML with the following content:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"#content\" class=\"screen-reader-text\"\n  >Five ways to make your site more accessible</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Then, in every template ensure that there is an element with an ID attribute of content. In our example, we used the article title as the text to jump to. This enables search engines to understand what is being linked to. It's a more semantic way of jumping to the content."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<main id=\"content\">\n  <!-- your page or post's actual content -->\n</main>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Use semantic markup"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Semantic markup is HTML that conveys meaning. Some examples of elements that are semantic are ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<time>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<code>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<aside>"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<article>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Some examples of elements that are not semantic are ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span>"
      }), ". Non semantic elements still have their place, but should be used primarily for presentation and not for conveying meaning."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Semantic elements can be understood by search engines and assistive technologies, both positively affecting SEO and improving your user experience."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Do not use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " elements as interactive elements like buttons, tabs, or links unless you're sure you've provided a good experience via aria attributes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use links (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<a />"
      }), ") and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<button />"
      }), " appropriately."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use links for linking to sections of a page and navigating to other pages."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use Buttons for interactions that do not result in leaving the page or navigating to an ID."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When working with tables - if a table has a descriptive title include it in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<caption>"
      }), " element just after the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<table>"
      }), " element."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), " elements with the proper scope attribute in tables for column and row headings and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<thead>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tbody>"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tfoot>"
      }), " to denote sections."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the scope attribute on ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), " attributes to denote whether the heading is for the row or column."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Need an accordion? Don't forget about the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<details>"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<summary>"
      }), " elements they give you most of this functionality for free, and it's accessible. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://caniuse.com/#feat=details",
        children: "Be sure to add a polyfill or fallback if you need to support old browsers"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Keyboard navigation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Some users use their keyboard to navigate webpages and forms out of preference. Some visitors must use the keyboard or some sort of assistive device that emulates a keyboard to navigate websites."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Make sure that ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_focus.asp",
          children: "':focus'"
        }), " is styled so that when a user is navigating via the keyboard they can see what is focused. Only disable the CSS outline property if you are providing an acceptable alternate visual indicator. Use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/almanac/selectors/f/focus-within/",
          children: ":focus-within"
        }), " if it can help direct users attention usefully."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When hiding/showing portions of the page with ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_hover.asp",
          children: "':hover'"
        }), " or via Javascript, make sure that users are also able to navigate those controls via Javascript or that there is alternative path to the information."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Make sure your site's main navigation supports keyboard navigation, a commonly missed issue is that drop-downs and fly-outs are not made accessible. This prevents users from getting to parts of websites that may be critical."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Provide and update ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hidden"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-expanded"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-current"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-selected"
        }), ", and other state attributes as necessary to ensure screen readers properly communicate the state of elements."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fallback to roles if needed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating templates or coded files, you should use correct semantic elements (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dialog>"
      }), ", etc.) to communicate to web browsers and screen readers what type of content is inside your elements."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the off-chance semantic elements are not appropriate for your scenario you should add ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles",
        children: "role attributes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Adding landmarks make it easier for users that are using screen readers to jump between the major sections of a web page. HubSpot includes 'role' attributes to the menu, simple menu, and Google Search modules."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Additional design tips"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Make sure that when a user zooms their browser to 200% content remains visible and readable."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "It is not advised to make fonts much smaller than 16px as it may become hard for visitors to read."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avoid using color as the only way of communicating information. A surprising percentage of the world population is color blind."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Make sure that there is sufficient contrast between the color of text and the background so that users with limited vision can read the content."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avoid animations that flash rapidly (more than three times per second) as it could trigger seizures in some visitors."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consider supporting ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/introduction-reduced-motion-media-query/",
          children: "prefers-reduced-motion"
        }), " if you have animations on your site."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Today building a responsive website is considered a must. Make sure all interactive elements function as expected on mobile. Interactive elements must be easy to tap, and there should plenty of room between tap regions."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Content writing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "An easy thing to forget is that accessibility should also be considered in your text content."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avoid directional language \"see sidebar on the left\". If the user is on mobile, odds are this makes no sense to them as content usually stacks on mobile."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use headings like ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h1>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h2>"
        }), " etc. and nest them consecutively. Do not skip a heading for visual effect."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "When adding a link, never have the link text be \"Click here\", it conveys no meaning to screen readers, doesn't make any sense on touch screens or other types of devices. Instead, your text should state what is at that link. This is also better for SEO, because search engines understand what is being linked to."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Make sure if you use any special styles on the text to convey meaning, you are using the appropriate ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<mark>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<strong>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<em>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<sup>"
        }), " etc. semantic tag, otherwise your meaning may be lost to some visitors."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you are targeting visitors worldwide, avoid jokes that are region specific. If you are having your site translated, avoid puns. As much as we love a good dad joke, they often don't translate well."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use tools to help improve your grammar and spelling. This helps comprehension and results in better translations."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Write your content in a way that fits your site's audience. You wouldn't explain astrophysics to 5th grader, the same as you would an accomplished physicist. That said, avoid fancy words just for the sake of using them, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/plain-language-experts/",
          children: "plain is better"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot specific recommendations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Most of the work toward improving accessibility is not HubSpot specific. That said we want to set you up for success on HubSpot and there are some things you can do either as a developer or content creator to provide a better experience for users."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Use custom modules and templates that follow a11y best practices"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Whether you are installing a module or template from the marketplace, developing them or having them built for you, one of the best things you can do is to make sure they follow a11y best practices. Custom Modules can have the greatest impact on the accessibility of your site because they are often used many times over, sometimes even on the same page. Similarly, a single template may be used hundreds or thousands of times on a single site. If your site is not accessible you are likely cutting your profits. Like with advertising it naturally makes sense to pay a little more to make sure your website reaches a wider audience. One of the benefits of modules is that you can often improve the code later as guidelines and best practices evolve, improving accessibility for every page that module lives on at once."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "On the HubSpot marketplace look for modules and templates that talk about accessibility, and meeting WCAG requirements in their descriptions. If you're working with a developer tell them from the beginning that accessibility is important for you. Not all developers build with a11y in mind from the start. Telling them late in a project will likely cost you more than telling them from the get-go because instead of prioritizing it from the beginning, they now need to retroactively fix accessibility issues."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you are a developer, build modules and templates that make meeting a11y guidelines easy for content creators. Use appropriate headings, semantic HTML, make interactive elements provide proper roles, and context. Content creators that are aware of accessibility issues are generally happy to pay a little more for modules and templates that are inclusive, that said you need to show that your modules take accessibility into account. Include skip links in templates, make global groups and global partials used in your templates a11y friendly, these get used throughout an entire website and can have the greatest impact on the usability of a site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you build for the HubSpot marketplace know that your content could get used across thousands, even millions of pages on the web. You make a choice that affects people when publishing your content and sharing your work, whether your work is inclusive or not."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beware of one-stop solutions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are dozens of tools that make claims like ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "\"Just add our script to your website and your site will be accessible\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Some of these tools will try to make sense of parts of the page and change html and attributes to try to fix some issues. That said they are guessing, and can be wrong or could break functionality on your site or make matters worse for users."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "These tools may not always operate as expected, sometimes supplying their own screen reader system. If you have a complicated UI, your website may actually become harder to operate through the injected tool."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Because of these issues tools like this are not a substitute for building your site with accessibility in mind. Invest time and money into providing the best experience for all of your users. Reliance on one-stop solutions could cost you the same or more, than simply building things the correct way. By testing and building with accessibility in mind you'll also get the benefit of empathy and understanding of your customers."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To be clear this is not referring to testing tools, specifically this is regarding tools that claim to solve accessibility woes for you. Testing tools themselves are great and you should be using them, in-addition to manual testing."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://overlayfactsheet.com/",
        children: "Learn more about accessibility scripts/overlays."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot Developer Podcast"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In January 2021 HubSpotters along with Jon Sasala of Morey Creative chatted about accessibility."
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/xOVTQN8phIs?feature=oembed",
      maxHeight: "113px",
      maxWidth: "200px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "More accessibility information"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are some really great resources out there for building accessible-minded websites. We highly, highly encourage you to check them out."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://a11yproject.com/",
          children: "The A11Y Project"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility",
          children: "MDN Accessibility Docs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/WAI/",
          children: "Web Accessibility Initiative"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/",
          children: "WebAIM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/wcag/checklist",
          children: "Simplified WCAG 2 checklist"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/508/checklist",
          children: "Section 508 checklist"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/TR/WCAG22/",
          children: "Detailed WCAG 2.2 recommendations"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.deque.com/axe/",
          children: "AXE by Deque"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://wave.webaim.org/",
          children: "WAVE"
        }), " - a tool for testing the accessibility of a webpage"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/website/web-accessibility",
          children: "The Ultimate guide to web accessibility"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}