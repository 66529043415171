"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31120285640;
const slug = exports.slug = 'guides/apps/marketplace/applying-for-app-certification';
const title = exports.title = 'Applying for app certification';
const name = exports.name = 'Applying for app certification';
const metaDescription = exports.metaDescription = 'App certification lets users know that your app was reviewed and approved by HubSpot, building trust and assuring prospective users of its quality.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h3: "h3",
      p: "p",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Apply for app certification"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "What is app certification?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["App certification involves the HubSpot Ecosystem Quality team reviewing and confirming that your listed app meets ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "these requirements"
      }), " for security, privacy, reliability, performance, usability, accessibility, and value. Once approved, your app listing page will show a “HubSpot Certified App” badge."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Why does it matter?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A certified badge on your app listing lets existing and prospective customers know the HubSpot Ecosystem Quality team reviewed and approved your app. It’s ultimately a way to symbolize quality and build trust with app users. Learn more about how to build customer trust through app certification ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-customer-trust-through-certification",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "How does it work?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "eligible"
      }), " app partner can apply for certification through their app developer account. The HubSpot Ecosystem Quality team will then review your submission and contact you to provide feedback or confirm your app’s certification."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Is my app eligible for certification?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Make sure your app is eligible for certification by reviewing our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "certification requirements"
      }), ". You will not be able to apply unless your app has at least 60 active installs and the needed amount of API traffic. Active installs are the number of unique HubSpot production accounts, unaffiliated with your organization, showing successful app activity in the last 30 days."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "How do I apply for certification?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can only submit one app at a time for certification. If you submit more than one app for certification at the same time, they will be rejected based on the order of submission. Once your app is certified, you can then submit another for certification."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To apply for certification:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/account/why-can-t-i-log-into-hubspot",
            children: "Log in"
          }), " to your developer account and navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "App Marketplace > Listings"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Hover to the right of the app you’d like to certify and click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Certify app"
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/undefined-Jun-28-2021-07-51-13-13-PM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL"
        }), " for the demo video. Review the necessary requirements for the app demo video ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#requirements-for-the-app-demo-video",
          children: "below"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Provide testing credentials for your app so that the HubSpot Ecosystem Quality team can evaluate its functionality."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-certification.png",
        alt: "app-certification"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Submit certification application"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Requirements for the app demo video"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " demo videos help the HubSpot Ecosystem Quality team test your app. The team ", (0, _jsxRuntime.jsx)("u", {
          children: "will not"
        }), " review your app unless you submit a demo video that meets all requirements. Promotional sales and marketing videos will be rejected. HubSpot will not share or publish your demo videos."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The demo video must meet the following requirements:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Be at least three minutes long."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Include audio (preferred) or text descriptions."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Include descriptions of your app's purpose and common use cases.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "E.g. \"Acme App helps sales and onboarding reps coordinate across CRMs. Closed won deals in any of Acme's supported CRMs can automatically generate tickets and onboarding tasks in HubSpot.\""
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Demonstrate and describe how new users should:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Install your app.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "E.g. \" From the Acme App listing on the HubSpot App Marketplace, click Install app, select your CRM, enter your credentials, click Done, select your HubSpot account, review the requested scopes, click Connect app.\""
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Set up or configure your app after installation.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "E.g. \"Once the app is installed, select a ticket pipeline by navigating to Settings > Integrations > Connected Apps > Acme App > Ticket Pipeline. Then, configure up to 10 default tasks in the 'Task Templates' section. When ready to enable the sync, toggle 'Ticket Sync' on.\""
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Use your app's primary features to support common use cases.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "E.g. \"For each closed won deal in the connected CRM, the Ticket Sync feature will create a ticket record in HubSpot with any associated contacts and your configured tasks. This enables your onboarding team to immediately connect with the new customer.\""
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Interact with your app inside their HubSpot account to support common use cases (if applicable).", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "E.g. \"To create onboarding tickets from HubSpot deals, use the \"Create Acme Ticket\" custom workflow action in deal-based workflows. These actions allow for more customization than the app settings for other CRMs.\""
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Disconnect your app from their HubSpot account."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Uninstall your app from their HubSpot account, describing how this affects users' HubSpot accounts and data."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tip:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.loom.com/",
          children: "Loom"
        }), "is a free tool you can use to record a demo video."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Why is the certification CTA not appearing for my app?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The “Certify app” button will only appear if your app is eligible to apply. Please review our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "certification requirements"
      }), " or reach out to your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements#related-resources",
        children: "App Partner Manager"
      }), " if you have any questions about eligibility."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "How will users know my app is certified?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once certified, your App Marketplace listing will show a prominent “HubSpot Certified App” badge."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/dw7gvVRNzsrmtUwOYBsLVhpAEniaAZ5Z-_G70J-Bzs596yGR_ZzLXJyw821l7FVCV53XmKoI1VVEFxX9YKAJxtI-4NjwvCJadqfWyLG8IyFOWROZ6k8Nx-1307mp6cE0T8p980-N",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When a customer hovers over the badge, they will see additional information on how apps are certified."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Find your ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Partner Manager"
        }), " and their email information by logging into your developer account and navigating to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), ". Hover over your app, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "More"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "View Listing Details"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Related docs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "App certification requirements"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "App listing requirements"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}