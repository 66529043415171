"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 182037251995;
const slug = exports.slug = 'guides/cms/content/fields/alias-mapping';
const title = exports.title = 'Alias mapping';
const name = exports.name = 'Alias mapping';
const metaDescription = exports.metaDescription = 'Learn how to use alias mapping to update module fields without breaking existing content.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "simple-implementation",
  "label": "Simple implementation",
  "parent": null
}, {
  "depth": 0,
  "id": "complex-implementation",
  "label": "Complex implementation",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      code: "code",
      ol: "ol",
      li: "li",
      strong: "strong",
      ul: "ul",
      h2: "h2",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      IFrame,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Alias mapping"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alias mapping enables you to create field mappings in a module so that you can move, rename, or replace its fields without impacting pages that are using the module."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, a module is being used on a live page. You want to move some fields into the ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "Styles"
        }), " tab"]
      }), ", such as color or font, but a content creator has already selected values for those fields in the editor. If you were to move those fields without setting up alias mapping, HubSpot would not be able to relocate those fields and they would revert to their default values, which would undo the styling on the live page."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Instead, you can include an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), " property to map the field to another one in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ". Then, when a value has not been set for the original field, HubSpot will check if a value exists in the mapped field. If no value exists in the mapped field either, it will use the default value instead. This property can be used to map field values between different versions of a module only when the stored data type of the old field is the same as the new field's stored data type."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For a visual walkthrough of this feature, check out the video below."
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SvESTX8SLJQ",
      maxHeight: "367px",
      maxWidth: "700px",
      allowFullScreen: true
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To migrate existing fields to aliases:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create new fields and map them to old fields using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aliases_mapping"
        }), " property in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " file."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Remove the old field definition."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Update the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " file to use the new fields definition."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "You cannot map fields that are of a different data type to each other. For example, you can't map a background gradient field to an image field. The stored value has to be a valid value for the new field's type."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "When creating a new field with an alias mapping to an old field, the default values and required properties of both fields should be the same."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are examples of implementing this for both simple and complex changes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#simple-implementation",
          children: "Simple implementation"
        }), ": mapping a color field to another new color field."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#complex-implementation",
          children: "Complex implementation"
        }), ": mapping a number field to a font field's ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "size"
        }), " subfield to control font size."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Simple implementation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In simple situations, the field type of the old field and the field type of the new field should be the same. For example:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Old color field to new color field."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Old text field to new text field."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Old spacing field to new spacing field."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is an example of using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), " when moving a color field from the module's ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Content"
      }), " tab to the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Styles"
      }), " tab."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Original module code:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Button Color\",\n    \"name\": \"old_button_color_field\",\n    \"type\": \"color\",\n    \"required\": true,\n    \"default\": {\n      \"color\": \"#FFFFFF\",\n      \"opacity\": 100\n    }\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Updated module code:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Styles\",\n    \"name\": \"styles\",\n    \"type\": \"group\",\n    \"tab\": \"STYLE\",\n    \"children\": [\n      {\n        \"label\": \"Button Color\",\n        \"name\": \"new_button_color_field\",\n        \"type\": \"color\",\n        \"required\": true,\n        \"aliases_mapping\": {\n          \"property_aliases_paths\": {\n            \"new_button_color_field\": [\"old_button_color_field\"]\n          }\n        },\n        \"default\": {\n          \"color\": \"#FFFFFF\",\n          \"opacity\": 100\n        }\n      }\n    ]\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Complex implementation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In more complex situations, you can also map fields to subfields or other module field types as long as the data type is the same, and the new field's subfield type matches. Subfields are the properties within the field's stored value object. For example:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Mapping a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Rich text"
        }), " field to a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Text"
        }), " field, as the values in both fields are stored as strings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consolidating typography fields, such as changing from a number field for font size, to use a font field (which has a font size sub field). You can add an alias for the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "size"
        }), " subfield to map it to the old number field by using dot notation."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is an example of changing the font sizing option from a number field to a font field which has a font size sub field."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Original module code:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_number_field\",\n    \"label\": \"Number field\",\n    \"required\": false,\n    \"locked\": false,\n    \"display\": \"text\",\n    \"step\": 1,\n    \"type\": \"number\",\n    \"min\": null,\n    \"max\": null,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"default\": null\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Updated module code:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_font_field\",\n    \"label\": \"font_field\",\n    \"required\": false,\n    \"locked\": false,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"load_external_fonts\": true,\n    \"type\": \"font\",\n    \"aliases_mapping\": {\n      \"property_aliases_paths\": {\n        \"my_font_field.size\": [\"my_number_field\"]\n      }\n    },\n    \"default\": {\n      \"size\": 12,\n      \"font\": \"Merriweather\",\n      \"font_set\": \"GOOGLE\",\n      \"size_unit\": \"px\",\n      \"color\": \"#000\",\n      \"styles\": {}\n    }\n  }\n]\n"
          })
        })
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}