"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044632;
const slug = exports.slug = 'guides/cms/content/themes/default-themes';
const title = exports.title = 'Temas padrão';
const name = exports.name = 'EMEA PT (pt-BR) Default Themes';
const metaDescription = exports.metaDescription = 'A HubSpot oferece uma variedade de temas para você usar, modificar ou usar como referência para o seu próprio tema de site. Você também pode fazer download desses temas no seu ambiente local usando nossas ferramentas da CLI.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "modificar-os-temas-padr%C3%A3o",
  "label": "Modificar os temas padrão",
  "parent": null
}, {
  "depth": 0,
  "id": "lista-de-temas-padr%C3%A3o",
  "label": "Lista de temas padrão",
  "parent": null
}, {
  "depth": 1,
  "id": "tema-growth",
  "label": "Tema Growth",
  "parent": "lista-de-temas-padr%C3%A3o"
}, {
  "depth": 0,
  "id": "temas-padr%C3%A3o-no-modo-de-manuten%C3%A7%C3%A3o",
  "label": "Temas padrão no modo de manutenção",
  "parent": null
}, {
  "depth": 1,
  "id": "tema-barricade",
  "label": "Tema Barricade",
  "parent": "temas-padr%C3%A3o-no-modo-de-manuten%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "tema-education",
  "label": "Tema Education",
  "parent": "temas-padr%C3%A3o-no-modo-de-manuten%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "tema-martech",
  "label": "Tema Martech",
  "parent": "temas-padr%C3%A3o-no-modo-de-manuten%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "tema-rally",
  "label": "Tema Rally",
  "parent": "temas-padr%C3%A3o-no-modo-de-manuten%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "tema-session",
  "label": "Tema Session",
  "parent": "temas-padr%C3%A3o-no-modo-de-manuten%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "tema-sprout",
  "label": "Tema Sprout",
  "parent": "temas-padr%C3%A3o-no-modo-de-manuten%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "tema-vitality",
  "label": "Tema Vitality",
  "parent": "temas-padr%C3%A3o-no-modo-de-manuten%C3%A7%C3%A3o"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      em: "em",
      h2: "h2",
      h3: "h3",
      img: "img",
      h4: "h4",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Temas padrão"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A HubSpot fornece um conjunto de temas padrão que os criadores de conteúdo podem usar para criar páginas de site sem a necessidade de modificar o tema base. Esses temas são mais adequados para criadores de conteúdo que têm menos acesso aos recursos de desenvolvedor."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você quiser desenvolver seu próprio tema, é recomendável começar com o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "HubSpot CMS Boilerplate"
      }), ". No entanto, você pode baixar e modificar temas padrão usando a CLI ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "do HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba mais sobre os temas padrão disponíveis atualmente."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " as contas ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Starter"
        }), " somente têm acesso ao tema Growth."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modificar os temas padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os temas padrão do HubSpot estão localizados em uma pasta chamada @hubspot no sistema de arquivos do desenvolvedor. Essa pasta é somente leitura e o conteúdo somete pode ser modificado pela HubSpot. Se quiser modificar um tema, você precisará ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "buscar"
      }), " o tema desejado e, em seguida, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "fazer upload"
      }), " dele com suas alterações em uma pasta diferente de @hubspot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lista de temas padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sinta-se à vontade para fazer download dos nossos temas padrão usando os comandos da CLI listados. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "Saiba mais sobre o comando fetch"
      }), " na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI do CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Growth"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/5Cdocs/default-themes/growth-homepage.jpg",
            alt: "Página inicial do tema Growth"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/growth/home",
              children: "View Growth theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Any"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "12 Sections"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "16 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/growth <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Temas padrão no modo de manutenção"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os seguintes temas foram movidos para o modo de manutenção. Eles não serão mais atualizados com novos recursos e somente receberão atualizações relacionadas à segurança, acessibilidade e correções de bugs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Barricade"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um tema voltado para as empresas de construção."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-barricade-theme-thumb-4.jpg",
            alt: "Captura de tela do tema Barricade"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/barricade/home",
              children: "View Barricade theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Construction"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "10 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/barricade <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Education"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um tema projetado para escolas, faculdades ou outras instituições de ensino."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-education-theme-thumb-4.jpg",
            alt: "Captura de tela do tema Education"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/education/home",
              children: "View Education theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Education"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "13 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/education <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Martech"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um tema para sites voltados para empresas de tecnologia de marketing."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-martech-theme-thumb-4.jpg",
            alt: "Captura de tela do tema Martech"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/martech/home",
              children: "View Martech theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Marketing Agency"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "9 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/martech <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Rally"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um tema projetado para empresas e agências."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-rally-theme-thumbnail-1-4.png",
            alt: "Captura de tela do tema Rally"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/rally/home",
              children: "View Rally theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Marketing Agency"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "14 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/cms-rally <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Session"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um tema projetado para empresas."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-session-theme-thumb-4.jpg",
            alt: "Captura de tela do tema Session"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/session/home",
              children: "View Session theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "9 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/session <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Sprout"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um tema projetado para empresas e agências."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-sprout-theme-thumb-4.jpg",
            alt: "Captura de tela do tema Sprout"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/sprout/home",
              children: "View Sprout theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "15 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "21 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/sprout <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Vitality"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um tema projetado para empresas e agências."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-vitality-theme-thumb-4.jpg",
            alt: "Captura de tela do tema Vitality"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/vitality/home",
              children: "View Vitality theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Healthcare, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "8 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/vitality <destination-folder>\n"
          })
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}