"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42817161646;
const slug = exports.slug = 'guides/apps/overview';
const title = exports.title = 'Criar uma visão geral dos aplicativos';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Developer tools and accounts';
const metaDescription = exports.metaDescription = 'Saiba mais sobre as ferramentas e as contas de desenvolvedor do HubSpot. Utilize-as para criar uma integração, listar um aplicativo no Marketplace ou criar uma conta de teste.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tipos-de-aplicativos",
  "label": "Tipos de aplicativos",
  "parent": null
}, {
  "depth": 1,
  "id": "aplicativos-privados",
  "label": "Aplicativos privados",
  "parent": "tipos-de-aplicativos"
}, {
  "depth": 2,
  "id": "aplicativos-privados-em-projetos-beta",
  "label": "Aplicativos privados em projetos (BETA)",
  "parent": "tipos-de-aplicativos"
}, {
  "depth": 1,
  "id": "aplicativos-p%C3%BAblicos",
  "label": "Aplicativos públicos",
  "parent": "tipos-de-aplicativos"
}, {
  "depth": 1,
  "id": "extens%C3%B5es-compat%C3%ADveis-por-tipo-de-aplicativo",
  "label": "Extensões compatíveis por tipo de aplicativo",
  "parent": "tipos-de-aplicativos"
}, {
  "depth": 0,
  "id": "tipos-de-contas-para-o-desenvolvimento-de-aplicativos",
  "label": "Tipos de contas para o desenvolvimento de aplicativos",
  "parent": null
}, {
  "depth": 0,
  "id": "autentica%C3%A7%C3%A3o",
  "label": "Autenticação",
  "parent": null
}, {
  "depth": 1,
  "id": "chaves-de-api-da-conta-de-desenvolvedor",
  "label": "Chaves de API da conta de desenvolvedor",
  "parent": "autentica%C3%A7%C3%A3o"
}, {
  "depth": 0,
  "id": "delete-a-developer-account",
  "label": "Delete a developer account",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      ul: "ul",
      li: "li",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Criar uma visão geral dos aplicativos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os aplicativos permitem autenticar as chamadas de API para sua conta da HubSpot, assinar eventos com webhooks e estender a interface do usuário da HubSpot, como cartões personalizados. A HubSpot oferece vários tipos de aplicativos e extensões, dependendo das necessidades da sua integração. Saiba mais sobre os tipos de aplicativos e extensões que você pode criar com o HubSpot, como começar a criá-los e como gerenciá-los no HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de aplicativos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dependendo do tipo de integração que você deseja criar, será necessário escolher o tipo certo de aplicativo. Saiba mais sobre os tipos de aplicativos que você pode criar e as funcionalidades compatíveis."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar extensões, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#supported-extensions-by-app-type",
        children: "consulte a tabela de referência abaixo"
      }), " para obter uma visão geral rápida de quais extensões podem ser criadas com quais tipos de aplicativos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicativos privados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os aplicativos privados podem ser criados para uma única conta da HubSpot e são mais adequados para scripts únicos ou extensões com uma única finalidade. Por exemplo, você pode criar um aplicativo privado para a sua conta da HubSpot para:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Criar um novo objeto personalizado por meio da API."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Importar registros do CRM de um arquivo CSV."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Autenticar solicitações de API em ações de automação personalizadas e chatbots."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "Crie e edite assinaturas de webhook"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em geral, os aplicativos privados são mais simples de implementar do que os aplicativos públicos. Os aplicativos privados são autenticados com tokens de acesso e não podem ser listados no Marketplace de aplicativos da HubSpot. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "quando criar aplicativos privados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se esse tipo de aplicativo atender às suas necessidades, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "comece a criar um aplicativo privado na sua conta da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Aplicativos privados em projetos (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os aplicativos privados criados com projetos permitem criar extensões de interface do usuário para registros do CRM. A diferença de criar um aplicativo privado por meio desse método é que os projetos suportam apenas a criação de extensões de interface do usuário, e os aplicativos privados fora dos projetos não podem fazer isso."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se esse tipo de aplicativo atender às suas necessidades, confira o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/quickstart",
        children: "guia de início rápido para projetos"
      }), " para começar a criar um aplicativo privado e um projeto usando a CLI."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicativos públicos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os aplicativos públicos podem ser instalados em várias contas. Além dos tipos de extensões que você pode criar com um aplicativo privado, os aplicativos públicos suportam funcionalidades avançadas, como:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Assinar os eventos de toda a conta usando a API de webhooks."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Criar eventos de linha do tempo personalizados em registros de CRM usando a API de eventos da linha do tempo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Criar páginas de configurações de aplicativos personalizadas no HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os aplicativos públicos são autenticados com OAuth e podem ser listados no Marketplace de aplicativos da HubSpot. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/ptr/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "quando criar aplicativos públicos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se esse tipo de aplicativo atender às suas necessidades, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "comece a criar um aplicativo público na sua conta de desenvolvedor de aplicativos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Extensões compatíveis por tipo de aplicativo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de aplicativo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Extensões compatíveis"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicativo privado"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/video-conferencing",
                  children: "Extensão de videoconferência"
                })
              })
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicativo público"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/calling-sdk",
                  children: "SDK de chamadas"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "Cartões de CRM"
                }), "*"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/timeline",
                  children: "Eventos de linha do tempo"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Extensão de videoconferência"
              })]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicativo privado criado com projetos (BETA)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/crm/ui-extensions/create",
                  children: "Extensões de UI"
                })
              })
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["* Os cartões de CRM que você pode criar com aplicativos privados são diferentes dos cartões personalizados que você pode criar como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/create",
          children: "extensões de interface do usuário com projetos (BETA)"
        }), ". As extensões de interface do usuário oferecem funcionalidades mais avançadas e componentes personalizáveis."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de contas para o desenvolvimento de aplicativos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Embora as contas de desenvolvedor de app e as contas de teste funcionem em conjunto, elas possuem propósitos diferentes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "As contas de desenvolvedor de aplicativos devem ser usadas para criar e listar aplicativos no Marketplace de aplicativos."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Contas de teste para testar as APIs e os aplicativos que você está criando sem afetar os dados em uma conta real da HubSpot. Isso é separado de uma conta de área restrita que você pode criar em contas padrão do HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "tipos de conta da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autenticação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se quiser criar uma integração personalizada com uma única conta da HubSpot, você pode criar um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "aplicativo privado"
      }), " e usar seu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "token de acesso"
      }), " para autenticar chamadas de API ou usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " com um aplicativo público. Qualquer aplicativo desenvolvido para ser instalado em várias contas da HubSpot ou para a listagem no Marketplace de aplicativos deve usar o OAuth."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " a partir de 30 de novembro de 2022, as chaves de API da HubSpot serão desativadas e não terão mais suporte. Continuar usando as chaves de API da HubSpot representará um risco de segurança para sua conta e dados. Durante esta fase, a HubSpot poderá desativar sua chave a qualquer momento."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Você deve autenticar usando um token de acesso de aplicativo privado ou OAuth. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "essa alteração"
        }), " e como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migrar uma integração de chave de API"
        }), " para usar um aplicativo privado."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se quiser se inscrever em webhooks ou configurar o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth para seu app"
      }), ", você deve ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "criar uma conta de desenvolvedor"
      }), ". Com as contas de desenvolvedor, você também pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "listar seus apps"
      }), " no marketplace de apps ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "criar uma conta de teste"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Chaves de API da conta de desenvolvedor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para gerenciar as configurações do app por meio da API, você pode usar uma chave de API do desenvolvedor. Esta chave de API é separada das chaves de API padrão, que foram descontinuadas. As chaves da API do desenvolvedor podem ser usadas para gerenciar assinaturas da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "API de webhooks"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "criar ou atualizar tipos de eventos para o recurso de eventos de linha do tempo"
      }), ". Todas as outras chamadas de API precisam ser feitas usando um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "token de acesso ao aplicativo privado"
      }), " ou OAuth."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para acessar sua chave de API de conta de desenvolvedor de apps:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de desenvolvedor de apps, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " na barra de navegação superior."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique na ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "chave de API do Get HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa de diálogo, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mostrar chave"
        }), ". A tecla será revelada e você poderá clicar em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar"
        }), " próximo à tecla."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Também é possível desativar a chave de API anterior e gerar uma nova clicando em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Gerar novamente a chave"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/show_dev_api_key.webp",
        alt: "show_dev_api_key"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a developer account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "É possível excluir contas de desenvolvedor de app se elas não contiverem apps com instalações ou listas de marketplace ativas. Se sua conta tiver aplicativos com instalações ou com listas de marketplace ativas e você quiser excluí-la, entre em contato com o suporte para obter assistência."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de excluir sua conta, você não poderá mais acessá-la. Se você puder alternar entre várias contas da HubSpot, a conta excluída não aparecerá mais."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na conta de desenvolvedor de API da HubSpot, clique no nome da sua conta, no canto superior direito, e depois clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Conta."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/account.jpeg",
        alt: "account"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Excluir conta"
      }), ". Se sua conta tiver aplicativos com instalações ou listas do marketplace ativas, esse botão aparecerá desativado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Account2.jpg",
        alt: "Account2"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na caixa de diálogo, insira o ID da conta e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Excluir conta de desenvolvedor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Confirm2.jpg",
        alt: "Confirm2"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}