import { KOALA } from 'HubStyleTokens/colors';
import styled, { css } from 'styled-components';
import UIRouterLink from 'ui-addon-react-router-dom/UIRouterLink';
const baseZIndex = 1;

/**
 * Styles applied to the hovered card (e.g.g `UICardWrapper`).
 */
export const cardStyles = css(["position:relative;transition:background-color 0.3s ease-in-out;&:hover{background-color:", ";}"], KOALA);

/**
 * Positions the card's link above the overlay.
 */
export const embeddedLinkStyles = css(["position:relative;z-index:", ";"], baseZIndex + 1);

/**
 * Overlay to allow the entire card to be linked, yet still retain separate
 * link hover functionality for the embedded <a>. Must be positioned in
 * `relative` container. Visual only and hidden from screen readers.
 */
const LinkOverlay = styled(UIRouterLink).attrs({
  'aria-hidden': true
}).withConfig({
  displayName: "LinkOverlay",
  componentId: "sc-1yuzor8-0"
})(["width:100%;height:100%;position:absolute;top:0;left:0;z-index:", ";"], baseZIndex);
export default LinkOverlay;