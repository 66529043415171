"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611087;
const slug = exports.slug = 'guides/cms/content/templates/types/drag-and-drop-templates';
const title = exports.title = 'Drag and Drop Templates';
const name = exports.name = '[new] Templates - Drag and Drop';
const metaDescription = exports.metaDescription = 'Drag and Drop Design Manager templates are templates built using a visual builder. They are not recommended for new templates as coded templates provide a better experience.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image2-3.png';
const featuredImageAltText = exports.featuredImageAltText = 'Design Manager interface with modules selected';
const toc = exports.toc = [{
  "depth": 0,
  "id": "the-drag-and-drop-template-builder",
  "label": "The Drag and Drop Template Builder",
  "parent": null
}, {
  "depth": 1,
  "id": "modules",
  "label": "Modules",
  "parent": "the-drag-and-drop-template-builder"
}, {
  "depth": 1,
  "id": "groups",
  "label": "Groups",
  "parent": "the-drag-and-drop-template-builder"
}, {
  "depth": 1,
  "id": "global-groups",
  "label": "Global Groups",
  "parent": "the-drag-and-drop-template-builder"
}, {
  "depth": 1,
  "id": "flexible-columns",
  "label": "Flexible columns",
  "parent": "the-drag-and-drop-template-builder"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      code: "code",
      a: "a",
      h2: "h2",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Drag and drop templates"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Drag and drop templates are not recommended for new templates."
        }), " These templates are not able to be part of a theme, and so they do not support theme functionality like theme fields, and theme modules. Drag and drop templates are ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "NOT"
        }), " supported in CMS Hub Starter, use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " instead. Drag and drop templates do not support several of the newer features of the CMS (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/memberships/overview",
          children: "memberships"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "drag and drop areas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections",
          children: "reusable sections"
        }), ", GraphQL and many other features). They lack extensibility and do not provide as good of a content creator and developer experience as coded templates. Instead, we recommend coded ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML + HubL templates"
        }), " with ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "drag and drop areas"
        }), ", which deliver a better experience for developers and marketers. For a quick start on the CMS, we recommend checking out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate",
          children: "HubSpot Theme Boilerplate"
        }), " which is built using coded templates."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'marketing_hub-professional', 'cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag and drop ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "templates"
      }), " were designed for less technical users to be able to easily create a website on the HubSpot CMS. Drag and Drop templates take advantage of a visual template builder, which generates HTML + HubL under the hood."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In order to make the visual builder work the HTML output is controlled by HubSpot and you do not have direct control over the structural portions of it. In-addition ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "a layout.css file is loaded onto the page which enables a basic 12 column grid"
      }), " based on bootstrap 2. This makes all sites built with drag and drop templates responsive by default causing rows of content to stack vertically, for more complicated and custom responsiveness you'll want to add your own responsive styles."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag and Drop templates are built in the Design Manager, when interacting with them via the API or the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "local development tools"
      }), " they are represented as JSON. Due to the nature of drag and drop templates the only recommended way to edit them is through the Design Manager interface. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "If you prefer to code, use version control or simply want to be able to edit using your preferred tools locally, HTML + HubL coded templates offer the best experience for developers."
      }), " The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), " functionality for HTML + HubL templates also provides a better experience for content creators than the design manager drag and drop interface as it keeps them in the visual content editor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "The Drag and Drop Template Builder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a drag and drop template, open the Design Manager and in the finder, create a new file, choose a template, and the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "type of template"
      }), " you're creating."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Drag and drop templates are made up of building blocks, these blocks are modules, groups, global groups, and flexible columns."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts",
        children: "Learn how to use the Drag and Drop template builder."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Modules are reusable components that are editable portions of a page. Modules are made up of an HTML + HubL template fragment, CSS, JS, and fields. Modules can be placed inside of flexible columns and dnd_areas by content editors, and are the primary way that content editors manage the content of their website. You can build modules to handle any number of functions, search, image galleries, menus, complex marketing animations, calculators, product comparisons, the possibilities are down to your imagination and what you think provides a good experience for content creators. The fields are how the content editor controls their output. Modules are not unique to drag and drop templates, they are a very important core building block for the HubSpot CMS. In Drag and Drop templates the default values for module fields can be configured in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
        children: "inspector"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Learn more about Modules."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image2-3.png",
        alt: "Design Manager interface with modules highlighted"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Groups"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Groups are wrappers for other groups and modules, they can have CSS classes, and wrapping HTML. Groups manifest themselves as wrapping HTML with layout classes to facilitate placement and sizing of the groups. Groups can also have an internal-only name. With this, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#group-modules",
        children: "group modules"
      }), " in the Design Manager making it easier to visually identify parts of a page. An example of this might be for sidebars or banners."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Being that ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML + HubL"
        }), " files are the recommended path for new sites, columns, sections, and rows of ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "Drag and drop areas"
        }), " largely replace the purposes of groups."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Additionally a developer can create partials, and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/global-content",
          children: "global partials"
        }), ", which can contain freeform code in-addition to drag and drop areas."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Drag and drop areas, partials, and global partials are not supported in drag and drop templates."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image4-1.png",
        alt: "Design manager screenshot with sidebar group selected"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Global Groups"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Global groups are groups that when edited affect the entire website and not just the page they are on. Global groups can exist in multiple templates and most commonly are used for site headers and footers. Global groups are similar to partials but are limited to the structure that drag and drop templates enforce. They can be embedded into HTML + HubL files if needed, but most of the time it makes more sense to use a global partial instead."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-3.png",
        alt: "Design Manager interface with header and footer global groups highlighted"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Flexible columns"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Flexible columns are a special type of group. They can contain a default set of modules, but content editors can add, remove, and move modules within them. Flexible columns allow reordering of modules in a one dimensional way, vertically up and down. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Unlike dnd_area tags"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "flexible columns do not support sections, or the styling capabilities, afforded to modules within them."
      }), " Flexible columns are not unique to drag and drop templates, there is a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "HubL tag"
      }), " which can be used in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), " templates. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#add-a-flexible-column",
        children: "Learn how to add a flexible column to a drag and drop template."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "It is generally recommended to use drag and drop areas, as most of the time they are more useful and provide all of the capabilities flexible columns provide."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "There may still be useful times to use a flexible column, for something like a sidebar. For main content areas drag and drop areas are much more flexible."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image3-2.png",
        alt: "Design manager screenshot with Main content group selected"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Why use flexible columns? Websites are not usually rigid. They are built and maintained over long periods of time. A homepage for example for a business may show off featured products, and may frequently change as the business's marketing needs change over time. Since content within a flex column can be added/removed and modified by content editors, the experience is less painful for marketers and enables developers to focus on what they enjoy, building cool stuff instead of making minor page adjustments."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Similarly, pages throughout a site may have different structural needs. Flex columns give the marketers control to create those pages using custom modules."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 8,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Add custom code to your drag and drop templates"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "There are a handful of ways to add custom code to your drag and drop templates. The primary method is through custom modules. Sometimes though you may need to add code that wraps around modules or groups. In order to do this, click on the module or group to view it in the inspector and find the wrapping HTML field. Add your HTML there."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Sometimes you may need to add code to the head of your HTML or code right above the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), ". With your template open make sure you don't have anything selected. The inspector will show fields for the template itself. Here you can link Stylesheets, and javascript files while also adding additional HTML in the head or just before the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), " tag."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["For this you'll use the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
            children: "inspector"
          }), ". The inspector has fields at the template level for ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template##attach-stylesheets-and-javascript-files",
            children: "stylesheets, javascript"
          }), ", ", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template#customize-your-template-s-head-and-body-options",
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "<head>"
            }), " markup"]
          }), ", etc."]
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://designers.hubspot.com/hubfs/image5.png",
            alt: "custom code inspector panel of Design Manager"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}