"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 52096466546;
const slug = exports.slug = 'guides/cms/setup/js-frameworks';
const title = exports.title = 'HubSpotでのJavaScriptフレームワークの使用方法';
const name = exports.name = 'APAC JAPAN (JA) | WEB | How to use JavaScript frameworks on HubSpot | 202207';
const metaDescription = exports.metaDescription = 'HubSpot CMSを使用すると、高度なJSベースのウェブアプリを作成できます。簡単に作業を開始するためのボイラープレートが用意されています。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%BF%85%E8%A6%81%E3%81%AAcms%E3%81%AE%E3%82%A8%E3%83%87%E3%82%A3%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "必要なCMSのエディション",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%AF%E3%83%BC%E3%82%AF%E3%81%A8%E3%83%A9%E3%82%A4%E3%83%96%E3%83%A9%E3%83%AA%E3%83%BC",
  "label": "フレームワークとライブラリー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%9F%A5%E3%81%A3%E3%81%A6%E3%81%8A%E3%81%8F%E3%81%B9%E3%81%8D%E3%81%93%E3%81%A8",
  "label": "知っておくべきこと",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%96%8B%E7%99%BA%E6%99%82%E3%81%AE%E8%80%83%E6%85%AE%E4%BA%8B%E9%A0%85",
  "label": "開発時の考慮事項",
  "parent": "%E7%9F%A5%E3%81%A3%E3%81%A6%E3%81%8A%E3%81%8F%E3%81%B9%E3%81%8D%E3%81%93%E3%81%A8"
}, {
  "depth": 2,
  "id": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%82%B5%E3%82%A4%E3%83%89%E3%81%A8%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E3%82%B5%E3%82%A4%E3%83%89%E3%81%AE%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0",
  "label": "サーバーサイドとクライアントサイドのレンダリング",
  "parent": "%E7%9F%A5%E3%81%A3%E3%81%A6%E3%81%8A%E3%81%8F%E3%81%B9%E3%81%8D%E3%81%93%E3%81%A8"
}, {
  "depth": 2,
  "id": "%E3%82%B7%E3%83%B3%E3%82%B0%E3%83%AB-%E3%83%9A%E3%83%BC%E3%82%B8-%E3%82%A2%E3%83%97%E3%83%AA%EF%BC%88spa%EF%BC%89%E3%82%A2%E3%83%8A%E3%83%AA%E3%83%86%E3%82%A3%E3%82%AF%E3%82%B9",
  "label": "シングル ページ アプリ（SPA）アナリティクス",
  "parent": "%E7%9F%A5%E3%81%A3%E3%81%A6%E3%81%8A%E3%81%8F%E3%81%B9%E3%81%8D%E3%81%93%E3%81%A8"
}, {
  "depth": 2,
  "id": "hubspot%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E5%88%A9%E7%94%A8%E3%81%97%E3%81%A6%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E9%96%8B%E7%99%BA%E3%81%99%E3%82%8B",
  "label": "HubSpotモジュールを利用してアプリを開発する",
  "parent": "%E7%9F%A5%E3%81%A3%E3%81%A6%E3%81%8A%E3%81%8F%E3%81%B9%E3%81%8D%E3%81%93%E3%81%A8"
}, {
  "depth": 2,
  "id": "%E4%BB%A3%E3%82%8F%E3%82%8A%E3%81%AB%E5%AE%8C%E5%85%A8%E3%81%AA%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E6%A7%8B%E7%AF%89%E3%81%99%E3%82%8B",
  "label": "代わりに完全なテンプレートを構築する",
  "parent": "%E7%9F%A5%E3%81%A3%E3%81%A6%E3%81%8A%E3%81%8F%E3%81%B9%E3%81%8D%E3%81%93%E3%81%A8"
}, {
  "depth": 2,
  "id": "%E5%8C%BA%E5%88%87%E3%82%8A%E8%A8%98%E5%8F%B7",
  "label": "区切り記号",
  "parent": "%E7%9F%A5%E3%81%A3%E3%81%A6%E3%81%8A%E3%81%8F%E3%81%B9%E3%81%8D%E3%81%93%E3%81%A8"
}, {
  "depth": 0,
  "id": "vuejs",
  "label": "VueJS",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%93%E3%83%AB%E3%83%89%E3%82%B9%E3%83%86%E3%83%83%E3%83%97%E3%81%AA%E3%81%97%E3%81%AE%E5%A0%B4%E5%90%88",
  "label": "ビルドステップなしの場合",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "vue%E3%83%A9%E3%82%A4%E3%83%96%E3%83%A9%E3%83%AA%E3%83%BC%E3%82%92%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AB%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "vueライブラリーをモジュールに追加する",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "html%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "HTMLコードを追加する",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "javascript%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "JavaScriptコードを追加する",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "%E3%83%93%E3%83%AB%E3%83%89%E3%82%B9%E3%83%86%E3%83%83%E3%83%97%E3%81%82%E3%82%8A%E3%81%AE%E5%A0%B4%E5%90%88",
  "label": "ビルドステップありの場合",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "vue%E3%82%B3%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%8D%E3%83%B3%E3%83%88%E5%86%85%E3%81%AEhubspot%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%A8cta%E3%82%92%E6%93%8D%E4%BD%9C%E3%81%99%E3%82%8B",
  "label": "Vueコンポーネント内のHubSpotフォームとCTAを操作する",
  "parent": "vuejs"
}, {
  "depth": 0,
  "id": "reactjs",
  "label": "ReactJS",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AEjavascript%E3%83%A9%E3%82%A4%E3%83%96%E3%83%A9%E3%83%AA%E3%83%BC",
  "label": "その他のJavaScriptライブラリー",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%83%88%E3%82%BF%E3%82%B0%E3%81%A7%E3%81%AF%E3%81%AA%E3%81%8Frequire_js%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "スクリプトタグではなくrequire_jsを使用する",
  "parent": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AEjavascript%E3%83%A9%E3%82%A4%E3%83%96%E3%83%A9%E3%83%AA%E3%83%BC"
}, {
  "depth": 0,
  "id": "%E6%8E%A8%E5%A5%A8%E3%81%95%E3%82%8C%E3%82%8B%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%83%AA%E3%82%A2%E3%83%AB%E3%81%A8%E3%82%AC%E3%82%A4%E3%83%89",
  "label": "推奨されるチュートリアルとガイド",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotでのJavaScriptフレームワークとライブラリーの使用方法"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMSを使用すると、JavaScriptベースのウェブアプリを作成できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "必要なCMSのエディション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ウェブサイトでサーバー側のコードまたはコンテンツメンバーシップのメカニズムが必要な場合、Enterpriseサブスクリプションをお持ちであれば、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "サーバーレス関数"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "コンテンツメンバーシップ"
      }), "機能を利用できます。ただし、AWS LambdaなどのサードパーティープロバイダーとAPIゲートウェイを組み合わせて独自のシステムを構築し、サーバー側のコードを実行することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "非公開アプリのアクセストークン"
      }), "などの認証を求めるAPIエンドポイントへのアクセスが必要なウェブアプリを開発する場合、そのようなコードはブラウザー上で実行しないでください。ページを閲覧する全てのユーザーに対して資格情報が公開されることになります。適切なアプローチは、ブラウザーと認証されるAPIの間に抽象化レイヤー（資格情報の公開を必要としない、呼び出し元ウェブサイトと同じドメインから提供されるカスタムAPIエンドポイント）を作ることです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタムAPIエンドポイントへのアクセスにより、認証付きのリクエストが可能なサーバーサイドコードが実行されます。次に、機密性が必要なデータやビジネスロジックの書式を設定し、その結果をブラウザーに送信できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このような処理には通常、サーバーレス関数を使用します。サーバーレス関数は非常に拡張性に優れ、自社でサーバーを保持および管理する必要もないからです。APIゲートウェイと組み合わせてAWS Lambdaなどのプロバイダーを使用できます。または、HubSpotのファーストパーティー", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/features/serverless-functions",
        children: "サーバーレス関数"
      }), "を使用できます。HubSpotサーバーレス関数には、複数の個別のサービスを管理する必要がないという利点があります。利用が簡素化され、テーマ、テンプレート、モジュールなどのあらゆる素材と共通のデベロッパー ファイル システムに直接統合されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "認証付きのAPI呼び出しが必要ないアプリの場合は、Enterpriseは必要ありません。ReactとVueは、動作にサーバーレス関数を必要としないフロント エンド フレームワークです。重要なのはこのフレームワークを使って何を行うかです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フレームワークとライブラリー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ウェブアプリの開発では、状態とユーザーインターフェイス（UI）の管理にJavaScriptフレームワークを使用することが一般的です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hubは特定のフレームワークの動作を念頭に置いて開発されていませんが、多くの一般的なJavaScriptフレームワークがHubSpotのCMS上で動作します。HubSpotでの開発時には、このようなフレームワークとのさまざまな連携方法に関する考慮が必要になる場合があります。とはいえ、これらのフレームワークの利用に欠かせない本質、つまり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates",
        children: "カスタムテンプレート"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/modules",
        children: "モジュール"
      }), "、およびJavaScriptを記述する機能が利用できます。また、ビルドステップを使用できるように、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/developer-reference/local-development-cms-cli",
        children: "ローカルでコーディング"
      }), "することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "知っておくべきこと"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "当社では、一般的なJavaScriptフレームワークを使用したHubSpot開発のベストプラクティスを確立するために、開発者コミュニティーと連携しています。HubSpotのCMSでも単純なHTMLページのような設定は可能ですが、単純なHTMLページとの違いを意識してプロジェクトを設定することが必要になる場面があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、慣れている開発ワークフローとは異なる部分があるかもしれません。さまざまな開発者の方の利便性を高めるためにも、ご意見をお寄せください。現在最適なのは、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "開発者フォーラム"
      }), "です。HubSpotでは、テストで得られた新たな情報により、引き続きドキュメントを更新していきます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "開発時の考慮事項"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMSには優れた", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュールシステム"
      }), "があるため、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubSpotのテンプレート言語「HubL」"
      }), "を利用できるCSS、JavaScript、およびHTMLの再利用可能なコードを作成できます。HubSpotのモジュールは、制作担当者に多くの制御と機能を提供する方法として役立ちます。最新のJavaScriptフレームワークには、多くの場合、独自のモジュールシステムがあります。こうしたシステムはそれぞれ独立して設計されています。その結果、課題への対処方法も多様です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "サーバーサイドとクライアントサイドのレンダリング"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サーバーサイドのレンダリングとは、サーバーのテンプレートロジックからページのHTMLを生成してから、HTMLをブラウザーに送信することです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "クライアントサイドのレンダリングとは、サーバーから軽量の「未完成」版のHTMLを送信し、JavaScriptを使用してHTMLを出力することです。これにより、サーバーからウェブブラウザー（クライアント）側にロジックの処理が移管されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ハイドレーションは、両方の手法を組み合わせることです。まず、サーバー側でできるだけHTMLを生成します。次にJavaScript側で、受け取ったHTMLを評価し、ページ上でのユーザーとのやり取りやデータの受領が発生したときに、必要に応じて小さな変更を行います。これにより、クライアントの負荷が軽減され、読み込まれたコンテンツがユーザーに見える状態になるまでにかかる時間も短縮される可能性があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMSでは、HubLがサーバーサイドで処理され、CDNレベルでキャッシュされます。次にJavaScriptを使用して、ブラウザーがサイト訪問者に表示するHTMLのハイドレーション（つまり、クライアントサイドレンダリング）を処理することができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "シングル ページ アプリ（SPA）アナリティクス"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "企業が成長し、お客様とプロスペクト（見込み客）に適応して問題を解決できるようになるためには、アナリティクス（分析）が重要です。複数のビューを含むシングル ページ アプリを構築する際は、さまざまなビューをページとして表示する訪問者をトラッキングすることをお勧めします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ほとんどのアナリティクスプラットフォームでは、JavaScriptを使用してこれを行う方法を提供しており、HubSpotもその例外ではありません。アプリでビューが変更されると、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code#tracking-in-single-page-apps",
        children: "ページビューをプッシュ"
      }), "します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HubSpotモジュールを利用してアプリを開発する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのモジュールシステムはサーバーサイド モジュール システムです。HubL＋HTMLのパーシャルからHTMLドキュメントを生成するとともに、ページ内の各モジュールの軽量化（ミニフィケーション）されたCSSおよびJavaScriptを生成します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotモジュールを使用した開発には、次のようなメリットもあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "制作担当者が、ドラッグ＆ドロップエリアまたはフレキシブルカラムを含むページにモジュールを追加できます。モジュール自体の移動や削除もできます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリの設定ができるフィールドを制作担当者に提供できます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コードは、モジュールが実際に使用されている場合にのみ、ページにレンダリングされます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Module.css"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.js"
        }), "は自動的に軽量化されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotモジュールシステムを使用することによる負担は、モジュールを特定のファイルで構成し、コードの通常の配置場所とは別に配置する必要があることです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "代わりに完全なテンプレートを構築する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリはモジュールフレームワーク内ではなく、テンプレートとして構築することもできます。これにより、ファイル構造の柔軟性が高まります。ただし、モジュール提供のようなメリットは得られません。制作担当者はページのドラッグ＆ドロップエリアおよびフレキシブルカラム内にこのアプリを追加できなくなります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "区切り記号"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["一部のJavaScriptフレームワークでは、コードの区切り記号として中括弧", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ }"
      }), "が使用されます。HubL言語でも、この中括弧を使用します。フレームワークとHubLの間で競合が生じないようにするには、未加工のHubLタグを使用してJSXをラップする、異なる区切り文字を使用するようにフレームワークを設定する、JavaScriptを事前にコンパイルするビルドステップを使用するという3つの戦略があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "VueJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["人気の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jp.vuejs.org/",
        children: "Vue.js"
      }), "フレームワークでは、ビルドステップあり、なしのどちらでも利用できます。各手法の長所と短所の詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jp.vuejs.org/v2/guide/installation.html",
        children: "Vueのドキュメント"
      }), "を参照してください。HubSpot上では、留意すべき特有の長所と短所があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ビルドステップなしの場合"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ビルドステップなしのVue.jsは、モジュールに簡単に連携できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "vueライブラリーをモジュールに追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "ファイル内に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), "を指定して", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jp.vuejs.org/v2/guide/installation.html#Direct-lt-script-gt-Include",
        children: "Vueライブラリー"
      }), "を追加し、ページにモジュールが追加されたときに1回だけ読み込まれるようにします。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["開発中は、devビルドを使用してデバッグに役立つ情報を取得します。本番環境では、特定のVueバージョンのCDN URLを使用するか、それをダウンロードし、HubSpot", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/key-concepts#developer-file-system",
          children: "デベロッパー ファイル システム"
        }), "上にJavaScriptファイルとしてホスティングすることをお勧めします。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTMLコードを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jp.vuejs.org/v2/guide/index.html#%E5%AE%A3%E8%A8%80%E7%9A%84%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0",
        children: "Vue.jsの「はじめに」"
      }), "からHTMLコードをコピーし、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "ファイルに貼り付けます。HubLとして評価されないように、このコードをHubLのrawタグで囲みます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# raw prevents code within it from being evaluated as HubL #}\n\n<div id=\"app\">\n  {{ message }}\n</div>\n\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "JavaScriptコードを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jp.vuejs.org/v2/guide/index.html#%E5%AE%A3%E8%A8%80%E7%9A%84%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0",
        children: "Vue.jsの「はじめに」"
      }), "からJavaScriptをコピーして", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "に貼り付けます。DOMコンテンツの読み込みが終了してから実行されるように、このコードを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/ja-JP/docs/Web/API/Window/DOMContentLoaded_event",
        children: "イベントリスナーで囲みます"
      }), "。モジュールを公開してプレビューします。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "これで、基本的なVueアプリが機能していることを確認できます。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var app = new Vue({\n  el: '#app',\n  data: {\n    message: 'Hello Vue!',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ビルドステップありの場合"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["VueJSアプリのビルドにHubSpotモジュールをご活用いただくための", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate",
        children: "ボイラープレート"
      }), "（ベータ版）を作成しました。最も簡単な利用方法は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/developer-reference/local-development-cms-cli",
        children: "CMS CLI"
      }), "で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create vue-app"
      }), "コマンドを実行することです。手順は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/",
        children: "リポジトリー"
      }), "に含まれています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この新しいボイラープレートに関するご意見をお聞かせください。改善可能な点や、発生した問題点をお知らせください。フィードバックのご提供方法としては、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/issues",
        children: "GitHubリポジトリーにイシューをお送り"
      }), "いただくことが最適です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vueコンポーネント内のHubSpotフォームとCTAを操作する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCTAとフォームには独自のスクリプトタグがあり、それぞれ独自のHTMLを管理します。VueコンポーネントがフォームやCTAを変更しないようにするためには、CTA／フォームの埋め込みコードを包含するHTML要素を作成します。", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://vuejs.org/v2/api/#v-once",
        children: ["その要素に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v-once"
        }), "を適用"]
      }), "します。これにより、コードが1回レンダリングされた後は、Vueコンポーネントでそのコードが無視されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ReactJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLを使用してモジュールとパーシャルを作成する代わりに、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
        children: "JavaScriptとReact"
      }), "を使用することもできます。JavaScriptのモジュールとパーシャルは、サーバーでレンダリングされたReactコンポーネントをHubLによって生成されたHTMLに組み込む処理に加えて、サーバー側とクライアント側の両方の双方向性をサポートしています。詳しくは、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "JSビルディングブロックの概要"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、HubSpotモジュール内で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://reactjs.org/",
        children: "React"
      }), "アプリを迅速に導入して運用を開始するための", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "Reactボイラープレート"
      }), "をご確認ください。最も簡単な利用方法は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/developer-reference/local-development-cms-cli",
        children: "CMS CLI"
      }), "で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create react-app"
      }), "コマンドを実行することです。ここから、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "リポジトリー"
      }), "内の手順に従ってください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この新しいボイラープレートに関するご意見をお聞かせください。改善可能な点や、発生した問題点をお知らせください。フィードバックのご提供方法としては、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate/issues",
        children: "GitHubリポジトリーにイシューをお送り"
      }), "いただくことが最適です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "その他のJavaScriptライブラリー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "現在提供されているさまざまなJavaScriptライブラリーを個別に文書化することは不可能です。HubSpot上でJavaScriptライブラリーを使用する際には、理解しておくべき重要なベストプラクティスがあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "スクリプトタグではなくrequire_jsを使用する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["数多くのモジュールやテンプレートの間でJavaScriptライブラリー共有できますが、このようなライブラリーが複数回読み込まれることを心配する必要はなくなります。そのためには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " HubL関数を使用する必要があります。この関数を使用して読み込まれたスクリプトは、このスクリプトを必要とするモジュール数、パーシャル数、テンプレート数に関係なく、ページごとに1回だけ読み込まれます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{{ require_js(get_asset_url('/js/jquery-latest.js')) }}\n\n{{ require_js(\"https://cdnjs.cloudflare.com/ajax/libs/d3/6.2.0/d3.min.js\") }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["デベロッパー ファイル システム内に保存されたファイルを要求するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get_asset_url()"
        }), "を使用します。開発ファイルを同じ場所に配置してファイルのセキュリティーを一元管理できるだけでなく、DNSルックアップ数が減少する利点もあります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "requireを使用してパフォーマンスを向上できる理由は、ファイルの読み込みを1回に抑えることだけではありません。ページ上のアセットが特定のライブラリーを必要としない場合、そのライブラリーは全く読み込まれません。HubLロジックとrequireを併用しても、本当に必要な場合にのみリソースが読み込まれます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "推奨されるチュートリアルとガイド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "パフォーマンスの最適化"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "アクセシビリティーは機能ではない"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "HubSpotでWebコンポーネントを使用する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "モジュールを使ってみる"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "サーバーレス関数の使い方"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "効率的な開発ワークフローの構築"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/cms/guides/building-dynamic-pages-with-hubdbf",
          children: "HubDBを使って動的ページを作成する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
          children: "JavaScriptを使用してモジュールとパーシャルを作成する"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}