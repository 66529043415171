"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32781982960;
const slug = exports.slug = 'guides/api/crm/objects/line-items';
const title = exports.title = 'CRM API | 商品項目';
const name = exports.name = 'APAC JAPAN (JA) | vNext Docs DP - Line Items | 202008';
const metaDescription = exports.metaDescription = '製品は取引に紐付けられると、商品項目になります。商品項目エンドポイントを使用することにより、商品項目データを管理したり同期したりできます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%95%86%E5%93%81%E9%A0%85%E7%9B%AE%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "商品項目を作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%95%86%E5%93%81%E9%A0%85%E7%9B%AE%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "商品項目を取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%95%86%E5%93%81%E9%A0%85%E7%9B%AE%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "商品項目を更新する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%95%86%E5%93%81%E9%A0%85%E7%9B%AE%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "商品項目を削除する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%95%86%E5%93%81%E9%A0%85%E7%9B%AE%E3%81%AE%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "商品項目のプロパティー",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "商品項目"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotにおいて商品項目は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "製品"
      }), "の個々のインスタンスです。製品は取引に紐付けられると、商品項目になります。個別の見積もり上に作成した商品項目は製品ライブラリーには追加されません。商品項目エンドポイントを使用することにより、商品項目データを管理したり、HubSpotと他のシステムとの間で同期したりできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["**使用例：**営業担当者から潜在顧客に送信するための", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes",
        children: "見積もり"
      }), "一式を作成する際に、商品項目APIを使用して、見積もりごとにスタンドアロンの商品項目ならびに既存の製品に関連付けられた商品項目を作成できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "商品項目を作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["商品項目を作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_item"
      }), "に送信します。POSTリクエスト本文に、商品項目の詳細（名前、数量、価格など）を含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既存の製品（", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "製品API"
      }), "を使用して作成された、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
        children: "HubSpot内"
      }), "で作成された）に基づく商品項目を作成するには、POSTリクエスト本文に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_product_id"
      }), "を含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、POSTリクエスト本文に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), "配列を含めることで、商品項目を取引、見積もり、請求書、支払いリンク、サブスクリプションに関連付けることも可能です。例えば、以下のような本文でPOSTリクエストを送信すると、取引（ID：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "12345"
      }), "）が関連付けられた「New standalone line item」という名前の商品項目が作成されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"price\": 10,\n    \"quantity\": 1,\n    \"name\": \"New standalone line item\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 12345\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "商品項目は、1つの単一親オブジェクトに属します。オブジェクトを関連付ける場合、商品項目はオブジェクトごとに個別にする必要があります。例えば取引と見積もりを作成する場合は、取引用に1つの商品項目セットを作成し、見積もり用に別の商品項目セットを作成する必要があります。これは、オブジェクト間でCRMデータを合理化し、商品項目を変更する必要があるときに予期しないデータ損失が発生しないようにするのに役立ちます（例えば、見積もりを削除すると見積もりの商品項目が削除され、それらの商品項目が取引に関連付けられている場合には、取引の商品項目も削除されます）。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "properties"
          }), "フィールド内で指定された", (0, _jsxRuntime.jsx)(_components.code, {
            children: "price"
          }), "を負の値にすることは", (0, _jsxRuntime.jsx)("u", {
            children: "できません"
          }), "。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["商品項目［期間］プロパティー（", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_recurring_billing_period"
          }), "）では、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://docs.oracle.com/javase/8/docs/api/java/time/Period.html#:~:text=exceeds%20an%20int-,parse,-public%20static%C2%A0",
            children: "期間表記の形式ISO-8601"
          }), "のPnYnMnDおよびPnWを使用できます。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "商品項目を取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "商品項目を個別に、または一括で取得できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["特定の商品項目を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items/{lineItemId}"
        }), "に送信します。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lineItemId"
        }), "は商品項目のIDを指します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["全ての商品項目を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items"
        }), "に送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエストのURLには、次のパラメーターを含められます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レスポンスで取得するプロパティーのカンマ区切りのリスト。指定したプロパティーのいずれかがリクエスト対象のオブジェクトに存在しない場合、これらのプロパティーは無視されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "以前の値の履歴と併せて取得するプロパティーのカンマ区切りのリスト。指定したプロパティーのいずれかがリクエスト対象のオブジェクトに存在しない場合、これらのプロパティーは無視されます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "商品項目を更新する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["商品項目を更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), "に送信します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), "は商品項目のIDを指します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエスト本文に、更新するプロパティー値を含めます。このメソッドを使用して関連付けを", (0, _jsxRuntime.jsx)("u", {
        children: "更新することはできません"
      }), "。代わりに、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "関連付けAPI"
      }), "を使用する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、リクエスト本文は次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/line_item/{lineItemId}\n{\n  \"properties\": {\n    \"price\": 25,\n    \"quantity\": 3,\n    \"name\": \"Updated line item\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "商品項目を削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["商品項目を削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), "に送信します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), "は商品項目のIDを指します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "商品項目のプロパティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["商品項目データを管理する場合は、以下の表に示す一般的なプロパティーのいくつかを使用することをお勧めします。全ての商品項目プロパティーを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/line_item"
      }), "に送信します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "プロパティーAPI"
      }), "の使用について詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "UIのラベル"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "名前"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目の名前。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "製品の詳細な説明"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "SKU"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "製品の固有ID"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "請求開始日"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "固定の請求期間の開始日"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "請求終了日"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "固定の請求期間の終了日"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recurringbillingfrequency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "請求の頻度"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "定期請求がある商品項目の請求頻度。取引および見積もりの価格計算の情報が取得されます。1回限りの請求がある商品項目は含まれていません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数量"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この商品項目に含まれる製品の単位数"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "単価"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "製品のコスト"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "正価"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目の合計コスト（数量×単価）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "通貨"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目の通貨コード"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}