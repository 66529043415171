"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 38812612977;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page';
const title = exports.title = 'How To build a Dynamic Team Member Page with HubDB';
const name = exports.name = '[new] How To build a Dynamic Team Member Page with HubDB';
const metaDescription = exports.metaDescription = 'Building dynamic pages with HubDB on the HubSpot CMS. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202019-01-31%20at%209.35.06%20AM.png';
const featuredImageAltText = exports.featuredImageAltText = 'Building Dynamic Pages with HubDB';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-build-your-hubdb-team-member-table",
  "label": "1. Build your HubDB Team Member Table",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-create-a-custom-module",
  "label": "2. Create a Custom Module",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-add-custom-field-to-the-custom-module",
  "label": "3. Add Custom Field to the Custom Module",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-add-logic-to-handle-dynamic-filtering-by-page-url",
  "label": "4. Add Logic to Handle Dynamic Filtering by Page URL",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-generate-your-team-member-pages",
  "label": "5. Generate your Team Member Pages",
  "parent": null
}, {
  "depth": 0,
  "id": "more-hubdb-focused-tutorials",
  "label": "More HubDB focused tutorials",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      br: "br",
      em: "em",
      h2: "h2",
      img: "img",
      pre: "pre",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "How To build a Dynamic Team Member Page with HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In this tutorial, I’ll show you how to create a Dynamic Team Member Page on HubSpot’s CMS, using HubDB. You can reference more detailed HubDB documentation ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "here."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You’ll need:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["**A HubSpot account with the CMS Add-on", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "**"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Portals with ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Website Starter"
          }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "without the Website Add-On"
          }), " do not support HubDB"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You can check if your portal has the CMS Add-on by ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/l/account-dashboard/",
            children: "signing in here"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Approximately 3 hours"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Some prior knowledge of HubSpot's CMS, HTML and CSS will be needed to customize your page"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["At the conclusion of this tutorial, we will have a working Dynamic Team Member Page based on the requesting page's URL, like this example here: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://designers.hubspot.com/team-members",
        children: "Dynamic Team Member Example"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Build your HubDB Team Member Table"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This tutorial will focus on adding logic that allows your Team Member page to be dynamic, based on the requested CMS page's URL. If you are new to HubDB and need help generating an initial HubDB Team Member Table, take a look at this tutorial here: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "Static Team Member Page"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you are familiar with building HubDB tables you can import some testing data for walking through this example, here's a file you can use: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/327485/HubDB/team-member-example.csv",
        children: "team-member.csv"
      }), ". Upload the file to HubDB, to follow along with this example."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once done, be sure to publish your table, making note of the ID of the table (the last number in the URL) and you will be ready to start using your data."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/hubdb-table-id.jpg",
        alt: "hubdb-table-id"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Create a Custom Module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Okay, so we have our HubDB Table generated which will showcase our amazing team members."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "First, we will need to create a new template in Design Manager (if you haven't already) and a new Custom Module. You can name it something like \"Dynamic Team Member Module\". We want to generate a Custom Module to allow for easier use across multiple templates or allow for potential custom fields to be created, depending on your needs. In this example, we are going to incorporate two custom fields which will allow each page to apply different styling to our rendered Table (more on that later). Be sure to add your new Custom Module to your desired template as well."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Within your new Custom Module, add the following code. This code is generating the framework for which our logic will be applied to, that allows our Team Member section to be dynamic."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Get Team Members from HubDB #}\n{% set table = hubdb_table_rows([Insert YOUR Table ID], queryparam)%}\n\n{% if table == [] %}\n    <p class='align-center'>Sorry, no one found for that Search. Try changing your filter and search again.</p>\n{% else %}\n  {% for row in table %}\n<div class=\"team-member-card-container {{ widget.cards_in_row }}\">\n    <div class=\"team-member-card\">\n        <div class=\"image-container\">\n            <img src=\"{{ row.image.url }}\" width=\"500\" {% if row.image.width > row.image.height  %}class=\"landscape\"{% endif %} alt=\"{{ row.name }} Headshot\">\n        </div>\n        <div class=\"team-member-info\">\n            <h3>{{ row.name }}</h3>\n            <h4>{{ row.title }}</h4>\n            <p>{{ row.description }}</p>\n       </div>\n    </div>\n</div>\n{% endfor %}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Click over to the preview of the template and you should see the simple Team Listing. If nothing appears, double-check that your table ID is correct, the table is published and that your variable names match what is set within your HubDB table."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Let's breakdown what we are doing here. Don't worry about the 'queryparam' just yet, this will be needed for later in the tutorial when we filter our Team Member Table based on the requesting page's URL path!"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "First, we add a little logic to render an error if for some reason our table returns an empty object. Next, we map out the rows for our Team Members. If done correctly, our preview should be rendering our entire Team. Feel free to modify the HTML structure as needed."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It is worth noting that we are accessing the values of our HubDB rows via dot notation here. It is also perfectly acceptable to adjust this to use 'bracket' notation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Add Custom Field to the Custom Module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This example is using a custom field within the module to further enhance the functionality of the rendered Team Member page. Our Custom Module will ultimately be applied to multiple pages so we want the ability to easily adjust the HTML structure of Table, if one of our Locations only has, say, 2 employees verse another that might have 6 employees."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "We can handle this by including CSS that controls the size of our team member containers based on a custom class that is applied to the HTML! We can accomplish this by adding a Choice field to our module. As seen below."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/team-member-choice-card-size.jpg",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In order for this field to have an impact, on the rendered layout of our widget, you will need to generate some CSS that can handle this. For simplicity, here is some CSS to get you going: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/327485/HubDB/team-member-card.css",
        children: "team-member-card.css"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By default, our Module will set the 'half' class on our containers. Now, each card will render with 50% width. Now, if we generate a page, using this Module, and we know that we will have fewer team members rendered, we can dynamically adjust the CSS to make the Table display better!"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Be sure to include this CSS within the custom module too. Our updated module will now look like this."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Load Card Stylesheet #}\n{{ require_css([insert link to CSS]) }}\n\n{# Get Team Members from HubDB #}\n{% set table = hubdb_table_rows([Insert YOUR Table ID], queryparam)%}\n\n{% if table == [] %}\n    <p class='align-center'>Sorry, no one found for that Search. Try changing your filter and search again.</p>\n{% else %}\n  {% for row in table %}\n<div class=\"team-member-card-container {{ widget.cards_in_row }}\">\n    <div class=\"team-member-card\">\n        <div class=\"image-container\">\n            <img src=\"{{ row.image.url }}\" width=\"500\" {% if row.image.width > row.image.height  %}class=\"landscape\"{% endif %} alt=\"{{ row.name }} Headshot\">\n        </div>\n        <div class=\"team-member-info\">\n            <h3>{{ row.name }}</h3>\n            <h4>{{ row.title }}</h4>\n            <p>{{ row.description }}</p>\n       </div>\n    </div>\n</div>\n{% endfor %}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Add Logic to Handle Dynamic Filtering by Page URL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Now that we have our Team Members rendering, we need to write some logic to handle rendering the Table, based on the requesting page's URL path."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["We can do this with a few ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "If Requests"
      }), " that will pass a 'queryparameter' variable into our HubDB function to control which rows are returned onto the page. Insert the following code into your Custom Module, after our CSS include, but before the main HubDB table code."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# sets the different query parameters using request path for hubdb query #}\n{% set queryparam = \"\" %}\n{% if request.path == \"/team-members/location/ma\" %}\n    {% set queryparam = queryparam ~ \"&location=1\" %}\n{% endif %}\n{% if request.path == \"/team-members/location/nh\" %}\n    {% set queryparam = queryparam ~ \"&location=2\" %}\n{% endif %}\n{% if request.path == \"/team-members/location/pa\" %}\n    {% set queryparam = queryparam ~ \"&location=3\" %}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Remember the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "queryparam"
      }), " variable from the beginning of this tutorial? This code puts it to use! Using the request object on the CMS page, specifically the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "path"
      }), ", we can control which team members are returned. Depending on the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "path"
      }), " of the page we set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "queryparam"
      }), " equal to a specific ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "value"
      }), " from our HubDB table's location field. When this value is appended as our Table's filter option, our Team Member page will be dynamically updated to reflect only those members!"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Generate your Team Member Pages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Our Dynamic Team Member module is set and ready to go. All that is left to do is generate our pages. This example uses 4 pages. One that is the main Team Member 'Parent' page and three child pages. Feel free to utilize the example paths (slug) when generating your pages. Otherwise, you will need to remember to update the Custom Module to use the path's you are expecting your visitors to land on."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This tutorial uses fairly simple logic so please feel free to modify or adapt anything you see here that is specific for your needs."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Here is an example Dynamic Team Member Directory that we generated based on this tutorial: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://designers.hubspot.com/team-members",
        children: "Team Members Example"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "More HubDB focused tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "How to join multiple HubDB tables"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "How to add videos to dynamic pages"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "How to build multilevel dynamic pages using HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Let's build a page with a map using HubDB"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}