"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400714;
const slug = exports.slug = 'guides/cms/setup/creating-an-efficient-development-workflow';
const title = exports.title = 'Otimizar o fluxo de trabalho de desenvolvimento do HubSpot';
const name = exports.name = 'LATAM BR (PT) Development Workflow';
const metaDescription = exports.metaDescription = 'Criando um fluxo de trabalho de desenvolvimento eficaz ao construir sites no HubSpot CMS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-considerando-a-portabilidade",
  "label": "Criar considerando a portabilidade",
  "parent": null
}, {
  "depth": 0,
  "id": "configurar-seu-ambiente-de-desenvolvimento",
  "label": "Configurar seu ambiente de desenvolvimento",
  "parent": null
}, {
  "depth": 1,
  "id": "configurar-seu-editor-de-conte%C3%BAdo",
  "label": "Configurar seu editor de conteúdo",
  "parent": "configurar-seu-ambiente-de-desenvolvimento"
}, {
  "depth": 2,
  "id": "vs-code",
  "label": "VS Code",
  "parent": "configurar-seu-ambiente-de-desenvolvimento"
}, {
  "depth": 2,
  "id": "outros-editores-de-c%C3%B3digo-e-ides",
  "label": "Outros editores de código e IDEs",
  "parent": "configurar-seu-ambiente-de-desenvolvimento"
}, {
  "depth": 0,
  "id": "testes",
  "label": "Testes",
  "parent": null
}, {
  "depth": 2,
  "id": "editor",
  "label": "Editor",
  "parent": "testes"
}, {
  "depth": 2,
  "id": "visualiza%C3%A7%C3%A3o-do-m%C3%B3dulo",
  "label": "Visualização do módulo",
  "parent": "testes"
}, {
  "depth": 2,
  "id": "depura%C3%A7%C3%A3o",
  "label": "Depuração",
  "parent": "testes"
}, {
  "depth": 2,
  "id": "ambientes-de-teste",
  "label": "Ambientes de teste",
  "parent": "testes"
}, {
  "depth": 0,
  "id": "implanta%C3%A7%C3%A3o",
  "label": "Implantação",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      h4: "h4",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Otimizar o fluxo de trabalho de desenvolvimento do HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A criação de um fluxo de trabalho eficiente para desenvolvedores o ajudará a trabalhar de forma mais eficaz na construção de sites no HubSpot CMS. O seu fluxo de trabalho pode ser diferente, dependendo da natureza da sua equipe de desenvolvimento web, ou da natureza de um projeto específico."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, um desenvolvedor construindo um site novo em uma conta nova do HubSpot CMS não precisa se preocupar muito com testes e colaboração. Por outro lado, uma equipe de desenvolvedores trabalhando em um site maior precisará ter um processo de desenvolvimento e preparação mais claro, um fluxo de trabalho de implantação e controle de código fonte para poder trabalhar de forma eficiente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este guia foi projetado para orientá-lo sobre a criação de um fluxo de trabalho eficaz para desenvolvedores, que pode ser adaptado de acordo com as suas necessidades."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este guia assume que você já cria sites usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI do CMS"
      }), ". Siga o tutorial de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "introdução ao desenvolvimento local"
      }), " para começar. Este guia também assume que você tenha passado pelo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart#quick_start",
        children: "guia de início rápido para desenvolver no HubSpot CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar considerando a portabilidade"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de começarmos a definir o nosso fluxo de trabalho para desenvolvedores, é importante reconhecer a portabilidade como um conceito fundamental para ter um fluxo de trabalho eficiente. A portabilidade do seu projeto garante facilidade de movimentação entre ambientes com pouco atrito e explicação, facilitando a realização de testes e alterações antes de colocá-lo no ar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Boilerplate de temas do CMS"
      }), " é um projeto de exemplo transferível que utiliza recursos como caminhos de arquivos relativos e formato de arquivo verdadeiro para todos os ativos do projeto por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI do CMS"
      }), ", o que permite que ele tenha controle de código fonte e funcione em qualquer conta da HubSpot. Este projeto é um ótimo ponto de partida ou referência para desenvolvedores que estão trabalhando em um novo projeto. Todos os temas padrão do HubSpot foram desenvolvidos usando este boilerplate, e também podem ser usados como um ponto de partida transferível e eficaz."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurar seu ambiente de desenvolvimento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para o ambiente de desenvolvimento, cada desenvolvedor da sua equipe deve criar uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "conta gratuita de ambiente de testes para desenvolvedores do CMS"
      }), ". Essas contas nunca expiram e têm todas as funcionalidades das contas pagas do HubSpot CMS (com exceção da possibilidade de conectar domínios personalizados)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A CLI do CMS facilita a interação com várias contas do HubSpot CMS. Crie uma nova ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "entrada de configuração"
      }), " para a sua conta de ambiente de testes para desenvolvedores do CMS. Defina o nome da entrada para que seu ambiente de testes como \"DEV\" ou \"SANDBOX\", para que fique claro que esta conta é um ambiente de desenvolvimento. Além disso, defina ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "defaultPortal"
      }), " como sua conta de ambiente de testes, para que quando você executar comandos usando a CLI do CMS, ela interaja automaticamente com seu ambiente de testes, e também para reduzir implantações acidentais em produção. Neste momento, seu arquivo de configuração será parecido com isso:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: DEV\nportals:\n  - name: PROD\n    portalId: 123\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n  - name: DEV\n    portalId: 456\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora, ao executar comandos na CLI do CMS, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload"
        })
      }), ", se você não especificar um portal, os arquivos serão carregados na sua conta \"DEV\"."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurar seu editor de conteúdo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode utilizar o seu editor de código preferido para criar no HubSpot, seja ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#vs-code",
        children: "VS Code"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-code-editors-and-ides",
        children: "outros editores de código e IDEs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "VS Code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma quantidade significativa de desenvolvedores que trabalham no HubSpot usam o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://code.visualstudio.com/",
        children: "Visual Studio Code"
      }), ". Isso inspirou a criação da extensão do VS Code para HubSpot. A extensão adiciona snippets intellisense úteis, completação de códigos do HubL, destaque de sintaxes do HubL e linting do HubL. O projeto é de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode",
        children: "código aberto"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/blob/master/CONTRIBUTING.md",
        children: "contribuições são bem-vindas"
      }), ". Se quiser compartilhar sua opinião, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/issues",
        children: "registre um problema no repositório"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "c0ab15c7-27f7-4f4d-a884-5468e852577a",
      external: true,
      label: "Get VS Code Extension"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/vs%20code%20extension%20-%20hubl%20variable%20suggestion.gif",
        alt: "captura de tela animada da extensão do vs code mostrando uma sugestão de variável do hubl"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Outros editores de código e IDEs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Embora exista uma extensão oficial do VS Code, não há razão para não poder usar seu editor preferido. O HubL é a variação privada criada pela HubSpot do Jinjava, que é baseado em Jinja. Devido às semelhanças de sintaxe, as extensões de realce de sintaxes do Jinja tendem a funcionar bem. As extensões e as ferramentas complementares variam por editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Testes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existem dois métodos principais para testar as alterações:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testar com watch/upload:"
        }), " ao trabalhar em seu ambiente de desenvolvimento, é seguro usar o comando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#watch",
          children: "watch"
        }), " para carregar automaticamente as alterações quando você salvar arquivos no editor de texto a fim de desenvolver rapidamente. Se você usar a ferramenta “Visualização dinâmica com opções de exibição” do Gerenciador de design para um modelo, ao salvar as alterações, você as verá automaticamente refletidas na saída renderizada da visualização do modelo. Para ver a visualização dinâmica de um modelo, selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualização > Visualização dinâmica"
        }), " com opções de exibição dentro do editor de modelos do Gerenciador de design."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testar localmente:"
        }), " para visualizar as alterações localmente sem carregá-las na conta, você pode executar o comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs theme preview"
        }), " no diretório raiz do tema. Este comando executará um servidor proxy local em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://hslocal.net:3000/",
          children: "https://hslocal.net:3000/"
        }), ", que você pode usar para visualizar modelos e módulos do tema. Saiba mais sobre o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#locally-preview-theme",
          children: "comando hs theme preview"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Editor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Outra peça fundamental da fase de desenvolvimento é testar as alterações nas ferramentas de criação de conteúdo. Se você está construindo módulos, ou modelos projetados para serem manipulados no editor de conteúdo, crie páginas em seu ambiente de desenvolvimento para garantir que a experiência de edição de conteúdo seja como você pretende. Arraste os módulos em configurações diferentes e introduza conteúdo fictício para garantir que os profissionais não possam “quebrá-los” ao construir páginas. Usar os editores de conteúdo ajudará a ilustrar as contenções que você deseja construir em seus modelos e módulos. Atualmente, não é possível mover conteúdo, como páginas ou posts de blogs, entre contas da HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Visualização do módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No editor de módulos dentro do Gerenciador de design, selecione o botão \"Visualização\". Isso abrirá um editor com uma visualização do módulo e dos campos nos editores de conteúdo. Assim, você poderá testar campos, grupos e repetidores no módulo com conteúdo fictício em um ambiente seguro."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/module-preview.gif",
        alt: "Visualização do módulo"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Depuração"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saber como depurar e resolver problemas é fundamental para a saúde e o sucesso contínuo do seu site. Familiarize-se com as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting",
        children: "técnicas de depuração ao desenvolver no HubSpot CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ambientes de teste"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Conforme mencionado na seção sobre como configurar seu ambiente de desenvolvimento, você pode criar contas gratuitas de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "Ambiente de testes do desenvolvedor de CMS"
      }), " para usar como um ambiente de desenvolvimento e teste seguro."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implantação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma vez que você tenha testado suas alterações e esteja pronto para colocá-las no ar, é hora de implantar as alterações no seu portal de produção. Com base na configuração local, você precisará executar o comando da CLI do CMS com o argumento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "--portal"
      }), " para interagir com sua conta de produção, como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme/src my-theme --portal=PROD"
      }), ". Ao fazer upload de arquivos na sua conta de produção, preste atenção se houve algum erro a ser diagnosticado e certifique-se de percorrer rapidamente o seu site para garantir que não houve nenhuma consequência não intencional da implantação."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você faz parte de uma equipe de desenvolvimento web, é recomendado ter controle de versão em todo o seu código fonte de produção, e implantar no seu portal do produto somente depois de mesclar as alterações com a versão original. Dessa forma, sua equipe de desenvolvedores pode usar seu sistema de controle de versão favorito para colaborar, acompanhar as alterações e revertê-las com facilidade."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para saber mais sobre como configurar a integração contínua com os repositórios git, siga este guia sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "utilizar as ações do GitHub para implantar na sua conta de produção depois de mesclar as alterações com a versão original."
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}