"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890248;
const slug = exports.slug = 'guides/cms/debugging/troubleshooting';
const title = exports.title = 'Débogage et erreurs';
const name = exports.name = 'EMEA FR Debugging and Errors';
const metaDescription = exports.metaDescription = 'Comprendre comment déboguer et examiner les erreurs lors du développement de sites Web sur HubSpot CMS. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/fatal%20error%20-%20CMS%20CLI.png';
const featuredImageAltText = exports.featuredImageAltText = 'Error publishing template';
const toc = exports.toc = [{
  "depth": 0,
  "id": "erreurs",
  "label": "Erreurs",
  "parent": null
}, {
  "depth": 1,
  "id": "erreurs-fatales",
  "label": "Erreurs fatales",
  "parent": "erreurs"
}, {
  "depth": 1,
  "id": "avertissements",
  "label": "Avertissements",
  "parent": "erreurs"
}, {
  "depth": 0,
  "id": "mode-d%C3%A9bogage-sur-les-pages-en-ligne",
  "label": "Mode débogage sur les pages en ligne",
  "parent": null
}, {
  "depth": 0,
  "id": "mode-d%C3%A9veloppeur-dans-l-%C3%A9diteur-de-page",
  "label": "Mode développeur dans l'éditeur de page",
  "parent": null
}, {
  "depth": 0,
  "id": "voir-la-sortie-hubl",
  "label": "Voir la sortie HubL",
  "parent": null
}, {
  "depth": 0,
  "id": "filtre-hubl",
  "label": "Filtre HubL",
  "parent": null
}, {
  "depth": 0,
  "id": "infos-d%C3%A9veloppeur",
  "label": "Infos développeur",
  "parent": null
}, {
  "depth": 0,
  "id": "examiner-les-performances-du-site-web-et-les-liens-cass%C3%A9s",
  "label": "Examiner les performances du site Web et les liens cassés",
  "parent": null
}, {
  "depth": 0,
  "id": "am%C3%A9liorer-la-rapidit%C3%A9-du-site-web",
  "label": "Améliorer la rapidité du site Web",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      img: "img",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab,
      CTA
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Débogage et erreurs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Déboguer le code et comprendre où et comment afficher les erreurs est une partie essentielle au développement sur HubSpot CMS. Il existe un certain nombre d'outils que vous pouvez utiliser pour gagner en efficacité lors de l'élaboration et du débogage et pour vous assurer que votre site Web est optimisé au fur et à mesure que vous le développez."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erreurs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/overview#developer-file-system",
        children: "système de fichiers développeur"
      }), " HubSpot CMS comporte de nombreuses formes de validation pour garantir que vos modèles et modules s'affichent correctement sur les pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Erreurs fatales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les erreurs fatales sont des erreurs qui empêcheraient une page de s'afficher correctement. Pour garantir un rendu correct du contenu en direct, HubSpot CMS empêche la publication des modèles qui présentent des erreurs fatales. Un exemple d'erreur fatale serait l'absence de variables HubL requises, telles que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), ". Cela entraînera des erreurs lors du développement dans le gestionnaire de conception ou lors du chargement de fichiers via le CMS CLI. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "L'extension VS Code"
      }), " prend en charge le linting HubL et peut afficher les erreurs fatales dans le contexte avant de charger le fichier."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20design%20manager.png",
        alt: "capture d'écran d'une erreur fatale - gestionnaire de conception"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20CMS%20CLI.png",
        alt: "capture d'écran d'une erreur fatale - CMS CLI"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les erreurs fatales doivent être résolues afin de pouvoir publier les fichiers."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Avertissements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les avertissements sont des erreurs ou des problèmes qui n'empêchent pas la publication des fichiers. Les avertissements sont souvent des suggestions de syntaxe ou des problèmes potentiels qu'un développeur pourrait manquer. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "L'extension VS Code"
      }), " prend en charge le linting HubL et peut afficher les avertissements en contexte avant de charger le fichier. Par exemple, si vous essayez d'inclure un fichier qui n'existe pas, un avertissement est émis pour avertir le développeur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/warning%20-design%20manager.png",
        alt: "avertissement - gestionnaire de conception"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les avertissements n'empêcheront jamais la publication des fichiers, mais il est recommandé d'examiner les avertissements."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mode débogage sur les pages en ligne"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez activer le mode débogage sur une page en ligne en chargeant la page avec une chaîne de demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), " dans l'URL."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Le mode débogage n'est pas pris en charge sur les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#system-pages",
          children: "pages système"
        }), ", telles que les pages 404 et de mot de passe."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors du chargement d'une page en ligne avec cette chaîne de requête, la page sera rendue :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "avec des fichiers non minifiés ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "avec des fichiers CSS non combinés (fichiers CSS individuels proposés) ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "sans servir les fichiers en cache."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par ailleurs, si vous chargez une page avec ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), ", les informations de débogage seront ajoutées en bas du code source de la page, y compris :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["si la page peut être ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "pré-rendue"
        }), " et les raisons pour lesquelles elle ne peut pas être pré-rendue ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["une répartition du délai de demande de rendu, qui peut être utile pour identifier les composants de la page qui prennent le plus de temps à être rendus. Cette répartition sera également ajoutée à l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Délai"
        }), " de la console de développement de votre navigateur sous ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Demandes de documents"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/timing-tab-request-details.png",
        alt: "timing-tab-request-details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Des erreurs et des avertissements, tels que les limites de fonction HubL ou les fichiers manquants."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/debug-page-source-elements-tab.png",
        alt: "debug-page-source-elements-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mode développeur dans l'éditeur de page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également charger l'éditeur de page dans HubSpot avec la chaîne de demande pour activer les fonctionnalités de développeur, telles que les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#copy-section-hubl",
        children: "sections de copie comme HubL"
      }), " et la possibilité d'ouvrir des modules spécifiques dans le gestionnaire de conception à partir de l'éditeur de page."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'éditeur de page, ajoutez le paramètre suivant à l'URL, puis appuyez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Entrée"
        }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?developerMode=true"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Avec la page rechargée, vous serez maintenant en mode développeur. Vous pouvez quitter le mode développeur à tout moment en cliquant sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quitter le mode développeur"
        }), " dans l'angle supérieur droit."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-top-navigation-bar.png",
        alt: "developer-mode-top-navigation-bar"
      }), "Lorsque vous êtes en mode développeur, vous pouvez accéder au code d'un module spécifique en cliquant sur le module associé, puis en cliquant sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ouvrir dans le gestionnaire de conception"
      }), " dans l'éditeur de barre latérale."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-open-design-manager-for-module.png",
        alt: "developer-mode-open-design-manager-for-module"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez également réinitialiser toute modification non publiée avec le contenu par défaut du modèle :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contenu"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["À droite du nom du modèle, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Réinitialiser le contenu"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-reset-contents.png",
        alt: "developer-mode-reset-contents"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la boîte de dialogue, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Oui, réinitialiser"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Voir la sortie HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le Gestionnaire de conception, les fichiers codés disposent d'un bouton « Afficher la sortie » qui ouvre un deuxième panneau d'édition de code, avec un code transposé du fichier que vous regardez. Ceci est utile pour voir comment votre code HubL sera transposé en CSS, HTML ou JavaScript, plutôt que de recharger les pages publiées sur lesquelles le fichier est inclus. C'est également un outil utile pour explorer les nouvelles fonctionnalités de HubL, ou pour apprendre les bases de HubL, car vous pouvez facilement voir ce que votre entrée HubL donnera en sortie."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/View%20HubL%20output.png",
        alt: "Voir la sortie HubL"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h2, {
      children: ["Filtre HubL", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le filtre HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), " peut être utilisé sur les variables HubL pour imprimer de précieuses informations de débogage. Il imprimera le type de variable HubL, ce qui peut être utile pour comprendre avec quels expressions, filtres, opérateurs ou fonctions il peut être utilisé."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ local_dt }}"
      }), " imprimera ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2020-02-21 12:52:20"
      }), ". Si nous imprimons cette variable, nous pouvons voir que la valeur est une date ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "(PyishDate: 2020-02-21 12:52:20)"
      }), ". Cela signifie que nous pouvons utiliser les filtres HubL qui exploitent ou formatent les objets de type date, comme le filtre HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|datetimeformat"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|pprint }}\n{{ local_dt|datetimeformat('%B %e, %Y') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2020-02-21 12:55:13 (PyishDate: 2020-02-21 12:55:13) February 21, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Infos développeur"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "La plupart des données contenues dans les infos développeurs sont utilisées en interne et sont susceptibles d'être modifiées si elles ne sont pas documentées."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les infos développeur d'une page établissent le contexte de toutes les données disponibles lors du rendu de la page. Ce contexte de rendu est entièrement accessible via HubL. Pour accéder aux infos développeur d'une page, sélectionnez le ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "logo HubSpot dans le coin supérieur droit des pages publiées > Infos développeur."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20sprocket%20menu.png",
        alt: "Menu logo HubSpot Infos développeur"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cela ouvrira un nouvel onglet qui renvoie le contexte de rendu pour une page donnée au format JSON. Il est recommandé d'installer un formateur JSON dans votre navigateur pour faciliter la lecture des infos développeurs, comme cette ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa",
        children: "extension Chrome JSON formatter"
      }), ". Bien que la plupart des informations contenues dans le contexte de la page soient destinées à des fins internes, cet outil peut être précieux pour voir quelles données sont disponibles via HubL lors de la création des modèles."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, l'image suivante représente les infos développeur sur : ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "https://desigers.hubspot.com/docs/developer-reference/cdn."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20example.png",
        alt: "Exemple d'infos développeur"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les valeurs de ces données sont définies dans l'onglet Paramètres de l'éditeur de contenu :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/content%20editor%20-%20settings.png",
        alt: "éditeur de contenu - paramètres"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les valeurs sont ensuite accessibles pour être rendues sur les pages via HubL. Pour imprimer le titre et la métadescription dans un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "modèle de base"
      }), ", vous utiliseriez le HubL suivant."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<title>{{ page_meta.html_title }}</title>\n<meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les données du contexte de rendu sont disponibles via HubL, et l'arborescence JSON peut être parcourue en utilisant la notation par points. Les données contenues dans les infos développeur que les développeurs impriment fréquemment comprennent les valeurs des champs de module et les balises qui ont été ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "exportées vers le contexte du modèle"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Examiner les performances du site Web et les liens cassés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il est important de vérifier que les visiteurs de votre site ne vont pas vers des liens cassés. Il existe deux outils pour vous assurer que les visiteurs de votre site arrivent au bon endroit. Vous pouvez utiliser l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/overview",
        children: "API de performance des sites Web"
      }), " pour obtenir des statuts HTTP comme les 404 et voir le temps de fonctionnement de vos sites."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous constatez des erreurs 404, il convient de rediriger le visiteur vers une URL valide."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également utiliser l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/seo/view-seo-recommendations-in-hubspot",
        children: "outil Recommandations SEO"
      }), " pour identifier les liens cassés dans le contenu de vos pages et les corriger rapidement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Améliorer la rapidité du site Web"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "De nombreux facteurs sont à prendre en compte pour optimiser et tester la vitesse d'un site Web. Pour des outils et des conseils pour optimiser la vitesse de votre site, consultez notre guide."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "841bbe22-70a6-4222-a6f0-85dd019711ae",
      label: "Optimize your CMS Hub site speed"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}