"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42376801971;
const slug = exports.slug = 'reference/api/deprecated/overview';
const title = exports.title = 'HubSpot API ｜非推奨のAPI';
const name = exports.name = 'APAC JAPAN (JA) | vNext Docs DP - Deprecated API | 202104';
const metaDescription = exports.metaDescription = '非推奨のHubSpot APIのリスト';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%BB%83%E6%AD%A2%E3%81%95%E3%82%8C%E3%81%9Fapi",
  "label": "廃止されたAPI",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%9D%9E%E6%8E%A8%E5%A5%A8%E3%81%AEapi",
  "label": "非推奨のAPI",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "廃止されたAPIおよび非推奨のAPI"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下のセクションに記載されているAPIは、今後機能が更新", (0, _jsxRuntime.jsx)("u", {
        children: "されることはなく"
      }), "、場合によっては完全に廃止され連携に使用できなくなります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["最新の更新、互換性が保たれなくなる変更、その他の重要な機能変更についていくために、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "HubSpot開発者変更ログ"
      }), "を購読することを強くお勧めします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "廃止されたAPI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のAPIは完全に削除されました。関連するエンドポイントへの呼び出しを行うとエラーが返されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "廃止日付"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "メモ"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CMSパフォーマンス"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2024年4月9日"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ウェブサイト アナリティクス データには", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/reporting",
              children: "アナリティクスAPI"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Eコマースブリッジ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2023年2月28日"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["以前にこのAPIを使用して連携を作成した場合は、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "移行ガイド"
            }), "に従って連携を切り替え、非公開アプリを使用できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "会計拡張機能"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2023年2月28日"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["以前にこのAPIを使用して連携を作成した場合は、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration",
              children: "この移行ガイド"
            }), "に従って連携を切り替え、非公開アプリを使用できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "マーケティングカレンダー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2023年8月31日"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["引き続き、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/campaigns/use-your-marketing-calendar",
              children: "HubSpotのマーケティングカレンダーを使用"
            }), "できます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "非推奨のAPI"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下にリストされているレガシーエンドポイントのバージョンは更新", (0, _jsxRuntime.jsx)("u", {
        children: "されません"
      }), "。こうしたエンドポイントは安定的に動作しますが、現在のバージョン以降は更新されません。HubSpotは非推奨エンドポイントも当面はサポートする予定です。今後の変更については、十分な時間的余裕を持ってHubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "開発者変更ログ"
      }), "（英語）で通知されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "メモ"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ソーシャルメディア"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["以下のエンドポイントが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-publishing-channels",
                  children: "公開チャネルの取得"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
                  children: "複数のブロードキャストメッセージの取得"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
                  children: "単一のブロードキャストメッセージの取得"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message",
                  children: "ブロードキャストメッセージの作成"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message",
                  children: "ブロードキャストメッセージのキャンセル"
                })
              })]
            })]
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}