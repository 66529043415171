"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611098;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/hubdb';
const title = exports.title = 'HubDBを使った動的ページの構築';
const name = exports.name = 'HubDBを使った動的ページの構築';
const metaDescription = exports.metaDescription = 'HubDBデータを使用して動的なウェブサイトページを作成する方法をご紹介します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.hubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "1.HubDBテーブルを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "2. テンプレートを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "3. 動的ページを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-%E5%85%AC%E9%96%8B%E4%B8%AD%E3%81%AE%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B",
  "label": "4. 公開中のページを表示する",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-%E6%96%B0%E3%81%97%E3%81%84%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E8%A1%8C%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "5. 新しいテーブル行を追加する",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B",
  "label": "6. 動的ページのデータを確認する",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AE%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%83%AA%E3%82%A2%E3%83%AB",
  "label": "HubDBに関するその他のチュートリアル",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubDBを使った動的ページの構築"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "動的ページ"
      }), "とは、構造化データソースからコンテンツを取得するCMSページのことです。動的ページがどのように設定されているかによって、HubSpotは選択されているソースからデータを取得し、一連のページを自動的に作成します。自動的に作成されるページには、データの概要を表示するリスティングページと、データ ソース エントリーごとの個別のページが含まれます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDBテーブル"
      }), "をデータソースとして使用すると、動的ページを作成してから、テーブルの各行に対応する個別のページを生成できます。それぞれの動的ページに個別のURLが作成されてSEO効果が期待できるほか、ページ単位の効果分析も可能です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["1つのデータソースにつき最大10個の動的ページを作成できます。HubDBを使った動的ページの場合、これは最大10個の動的ページで同じHubDBテーブルを使用できることを意味します。テーブルの行数（したがって、子ページの数）は、この上限に対して考慮されません。", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/storage/hubdb/overview#hubdb-technical-limits",
            children: "HuDBの技術的な制限事項"
          }), "について詳細をご確認ください。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubDBを使った動的ページでは、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/content-search",
            children: "コンテンツ検索"
          }), "インデックスの更新がアカウントごとに1日あたり50,000回に制限されます。この上限を超えたページの更新は、その日のコンテンツ検索で反映されません。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このチュートリアルでは、HubDBをデータソースとして使用して一連の動的ページを作成する手順を説明します。このチュートリアルに従うには、以下のものが必要です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTMLとCSSに関する予備知識"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["データに基づくCMSページの構築について詳しくは、HubSpotアカデミーの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "CMSデータドリブン コンテンツ コース"
        }), "を参照してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1.HubDBテーブルを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の手順に従って、新しいHubDBテーブルを作成します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントで［コンテンツ］＞［HubDB］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上の［テーブルを作成］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ダイアログボックスにテーブルの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "関連付けラベル"
        }), "と", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "名前"
        }), "を入力し、［作成］をクリックします。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーブルを作成したら、テーブルが動的ページのコンテンツとして使用されるように設定できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上の［アクション］をクリックし、［設定を管理］を選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右側のパネルで、［行データを使用した動的ページ作成を有効化］をクリックしてオンに切り替えます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "必要に応じて、個別の動的ページのメタディスクリプション、キービジュアル、canonical URLを選択できます。これらの値を空白のままにした場合、各ページには親ページからそれぞれの値が継承されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**ページでメタディスクリプション、キービジュアル、およびcanonical URLの列の値を使用するには、ページに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), "変数ではなく、次の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), " HubL変数を含める必要があります。"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.meta_description}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.featured_image_URL}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.canonical_url}}"
          })
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["例えば、HubSpotテンプレートは既定では", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{content.meta_description}}"
        }), "タグからメタディスクリプションを取得します。代わりに、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{page_meta.meta_description}}"
        }), "を使用する必要があります。"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［保存］をクリックして変更内容を保存します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-settings-sidebar-save.png",
        alt: "hubdb-table-settings-sidebar-save"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーブル設定を更新すると、［ページタイトル］**列と［ページのパス］**列がテーブルに追加されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**ページタイトル：**HTML titleタグの中で指定されるこのページの名前。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**ページのパス：**テーブルの各行により作成される動的ページのURLの最後のセグメント。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下のテーブルは、会社概要ページの経営幹部紹介を制作した例です。このテーブルは、パスが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cfo-harlow"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ceo-jeff"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cto-bristow"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pd-hugo"
      }), "で終わる動的ページを作成するために使用されることになります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "ページタイトル"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "ページのパス"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "役職"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "名前"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "プロフィール"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CFO Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cfo-harlow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CFO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Harlowは日ごろから倹約家です。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CEO Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceo-jeff"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CEO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JeffはCEOなので、普段は経営に勤しんでいます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTOのBristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cto-bristow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CTO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CTOのBristowはいろいろと試行錯誤することが好きです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "主任製品デザイナー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pd-hugo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CPD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CPDのHugoは、製品設計に情熱を傾けています。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-hubdb-table.png",
        alt: "example-hubdb-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**ページのパスは小文字で入力する必要がありますが、生成されるURLでは大文字と小文字は区別されません。上記の例では、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/CEO-Jeff"
        }), "にアクセスすると、404エラーではなく", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/ceo-jeff"
        }), "と同じページが表示されます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーブルのデータを使用してページを作成する準備ができたら、右上の［公開］をクリックします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. テンプレートを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次に、リストページおよび各行の個々の詳細ページの両方のために1つのテンプレートを作成します。これはリストページと記事詳細ページの両方にブログテンプレートを使用できるのと似ています。ページテンプレートを作成するには、以下の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントで、［コンテンツ］＞［デザインマネージャー］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左のサイドバーメニューで、テンプレートを作成するフォルダーに移動します。新しいフォルダーを作成するには、左上の［ファイル］をクリックし、［新規フォルダー］を選択します。次に、［ファイル］をクリックし、［新規ファイル］を選択します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ダイアログボックスで", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ドロップダウンメニュー"
        }), "を使用して、ファイルタイプとして［HTML＋HubL］を選択します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［次へ］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/create-html-hubl-template.jpg",
        alt: "新しいHTML＋HubLテンプレートを作成する"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［ファイル名］フィールドにテンプレートの名前を入力します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "［ファイルの場所］で［変更］をクリックすると、デザインマネージャーでテンプレートを配置する場所を変更できます。"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［作成］をクリックしてテンプレートを作成します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["動的ページがこのテンプレートを使用するように設定されていて、そのページのURLの末尾がパス列の値と一致した場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), "変数と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_table_id"
      }), "変数をテンプレートで使用できます。以下のコードでは、例えば経営幹部紹介ページを作成する場合に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), "のフィールドを使用して経営幹部の情報を表示する方法を示しています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_name"
        }), "：HubDBの行に関連付けられた「ページタイトル」__。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), "：経営幹部の名前。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "role"
        }), "：経営幹部の役職。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次に、テーブルからのパスを追加することなく、動的ページが読み込まれたケースの処理を追加できます。通常これは、HubDBテーブルの行のページへのリンク一覧を表示するリストページとして使用されます。コードを以下の内容に置き換えます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n{% elif dynamic_page_hubdb_table_id %}\n    <ul>\n    {% for row in hubdb_table_rows(dynamic_page_hubdb_table_id) %}\n        <li><a href=\"{{ request.path }}/{{ row.hs_path }}\">{{ row.hs_name }}</a></li>\n    {% endfor %}\n    </ul>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "ブロック内のコードは、経営幹部のテーブルの全ての行を繰り返し処理し、リストの各エントリーとその固有パスへのリンクをリストに表示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["デザインマネージャーで、［プレビュー］をクリックしてテンプレートをプレビューします。このテンプレートではページのコンテキストに応じて", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_row"
        }), "変数または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_table_id"
        }), "変数が設定されるため、プレビューには何も表示されません。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テンプレートレベルでコードをテストするには、以下の一時的なコードをテンプレートの先頭に追加します。このコードは、公開する前に必ず削除してください。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set dynamic_page_hubdb_table_id = <YOUR_HUBDB_TABLE_ID> %}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "上記のコードを追加すると、このテンプレートでハイパーリンクのリストがレンダリングされ、作成したHubDBテーブルからデータが取得されるようになります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-template-preview.png",
        alt: "hubdb-template-preview"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テンプレートをプレビューした後、上記の一時的なコードを削除します。次に、右上の［公開］をクリックして、ページを作成できるようにします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. 動的ページを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テンプレートから動的ページを作成するには、以下の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "デザインマネージャーで新しいテンプレートを開いた状態で、ファインダーの上部にある［アクション］ドロップダウンメニューをクリックし、［ページを作成］を選択します。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-page-from-design-manager.png",
            alt: "create-page-from-design-manager"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "ダイアログボックス内で［ウェブサイトページ］を選択し、ページの名前を入力します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［ページを作成］をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "ページエディター上部の［設定］タブをクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［ページタイトル］フィールドにページの名前を入力します。この名前を使用して、トラフィック分析を検索できるようになります。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["［", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), "］フィールドに、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/executives"
          }), "の", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), "を入力します。このURLは動的ページのベースURLになります。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［詳細オプション］をクリックして詳細設定を展開します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["［動的ページ］セクションまで下にスクロールして、［データソース］ドロップダウンメニューをクリックします。作成した", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "HubDBテーブル"
          }), "を選択します。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/page%20editor%20hubdb%20dynamic%20.png",
        alt: "HubDBテーブルにリンクするためのページ設定の詳細オプション"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "完了したら、右上の［公開］をクリックします。これで、ページを表示できる状態になりました。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. 公開中のページを表示する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDBテーブルで定義された新しい動的ページとその全てのパスにアクセスできる状態になりました。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ページエディターで設定したURLにある動的リスティングページに移動します。このチュートリアルでは動的ページのURLとして", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), "を使用しているため、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.yourdomain.com/executives"
        }), "に移動します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["リスティングページの箇条書きリストで経営幹部の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "名前"
        }), "をクリックすると、その経営幹部の詳細ページが表示されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/2022-11-28_15-55-47%20(1).gif",
        alt: "2022-11-28_15-55-47 (1)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. 新しいテーブル行を追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "動的ページでHubDBデータを読み込んだ後、テーブルに戻って新しい行を追加します。テーブルを公開した後は、公開中のページが新しいHubDBデータで動的に更新されるようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントで、［マーケティング］＞［ファイルとテンプレート］＞［HubDB］に移動します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["作成したテーブルの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "名前"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［行を追加］をクリックし、各列に値を入力します。以下はサンプルのデータセットです。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "ページタイトル"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "ページのパス"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "役職"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "名前"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "プロフィール"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CMO Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cmo-hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "猫のことならHobbesにお任せください。"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上の［公開］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["別のタブで、リストページ（この例では", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), "）に戻ります。新しい経営幹部がリスティングページに表示されているはずです。名前をクリックすると、この経営幹部の詳細ページが表示されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. 動的ページのデータを確認する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["動的ページへのアクセスが発生すると、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/analyze-performance-for-individual-pages-and-blog-posts",
        children: "個々のページのパフォーマンスを評価"
      }), "したり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "トラフィック アナリティクス ツールで全てのページのデータを確認"
      }), "したりできるようになります。各経営幹部に関する個別のページが同じ動的ページから構築されていても、ページビュー数などのトラフィックデータはそれぞれのページに帰属します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotでページ訪問データを確認するには、以下の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "HubSpotアカウントで、［レポート］＞［マーケティングアナリティクス］に移動します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "左のサイドバーで、［ウェブトラフィック分析］＞［ページ］に移動します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["テーブルを表示して、個々の親ページと子ページのトラフィックデータを確認します。子ページは、親ページとの関係を示すために", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "＞矢印アイコン"
          }), "で示されます。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-traffic-data-2.png",
            alt: "example-traffic-data-2"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "想定されるトラフィックデータが表示されない場合は、以下の点に留意してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/exclude-traffic-from-your-site-analytics",
          children: "アカウントのレポート設定でIPアドレスを除外"
        }), "している場合は、ページビューが登録されるように、ネットワークの外部からページにアクセスしてください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/how-often-do-analytics-in-hubspot-update",
          children: "新しいページデータがHubSpotに表示されるまでに最大40分かかる"
        }), "ことがあります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDBに関するその他のチュートリアル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "複数のHubDBテーブルを結合する方法"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "HubDBを使用して動的なチーム メンバー ページを作成する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "動的ページに動画を追加する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "HubDBを使ってマルチレベルの動的ページを作成する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "HubDBを使って地図付きのページを作成する"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}