"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125527;
const slug = exports.slug = 'guides/cms/content/templates/types/blog';
const title = exports.title = 'Balisage des modèles de blogs';
const name = exports.name = 'EMEA FR Blog template markup';
const metaDescription = exports.metaDescription = "Les modèles de listing de blog et d'article de blog peuvent être utilisés pour personnaliser l'apparence du blog d'un site. ";
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/Boilerplate%20Blog%20Templates.png';
const featuredImageAltText = exports.featuredImageAltText = 'Blog settings template selection';
const toc = exports.toc = [{
  "depth": 0,
  "id": "restituer-les-pages-de-listing-et-les-articles-avec-un-seul-mod%C3%A8le",
  "label": "Restituer les pages de listing et les articles avec un seul modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "listing-de-blog-pour-la-boucle",
  "label": "Listing de blog pour la boucle",
  "parent": null
}, {
  "depth": 0,
  "id": "balisage-des-mod%C3%A8les-de-listing",
  "label": "Balisage des modèles de listing",
  "parent": null
}, {
  "depth": 0,
  "id": "fonction-if-blog_author",
  "label": "Fonction if blog_author",
  "parent": null
}, {
  "depth": 0,
  "id": "fonction-if-tag",
  "label": "Fonction if tag",
  "parent": null
}, {
  "depth": 0,
  "id": "fonction-if-not-simple_list_page",
  "label": "Fonction if not simple_list_page",
  "parent": null
}, {
  "depth": 0,
  "id": "pagination-de-listing",
  "label": "Pagination de listing",
  "parent": null
}, {
  "depth": 0,
  "id": "balisage-des-articles-de-blog",
  "label": "Balisage des articles de blog",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      code: "code",
      a: "a",
      pre: "pre",
      strong: "strong",
      img: "img",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Balisage des modèles de blogs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les blogs HubSpot sont constitués de pages de listing de blog et des articles de blog individuels. En plus de répertorier les articles de blog individuels, le modèle de listing de blog est également utilisé pour restituer les pages de listing d'auteur et de balise. Vous pouvez créer un modèle unique pour restituer toutes les pages de listing et d'article de blog ou créer deux modèles distincts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous le balisage des modèles de blog, les composants des modèles et les options de personnalisation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Restituer les pages de listing et les articles avec un seul modèle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un modèle qui restitue les pages de listing et d'article, ajoutez l'annotation ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: blog"
      }), " en haut de votre fichier de modèle. Si vous utilisez un modèle pour restituer les deux, vous utiliserez une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "fonction Si"
      }), " qui détermine si l'utilisateur consulte une page de listing ou un article individuel. Si vous utilisez les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "mises en page du gestionnaire de conception en glisser-déposer"
      }), ", cette fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " est intégrée à l'interface utilisateur des boutons de module de contenu du blog."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", vous saisirez à la fois votre code d'article et de listing."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if is_listing_view %}\n    Markup for blog listing template\n{% else %}\n    Markup for blog post template\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également choisir d'avoir un modèle distinct pour les pages d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/fr/docs/cms/building-blocks/templates#blog-post",
        children: "article de blog"
      }), " et de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/fr/docs/cms/building-blocks/templates#blog-listing",
        children: "listing"
      }), ". Ainsi, votre code est plus propre et plus facile à lire en tant que développeur, et les modèles sont plus faciles à sélectionner pour les créateurs de contenu. Le boilerplate du CMS comporte un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-index.html",
        children: "modèle de listing"
      }), " et un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-post.html",
        children: "modèle d'article"
      }), " distincts. Si vous créez des modèles d'article et de listing distincts, la vérification de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_listing_view"
      }), " n'est pas nécessaire. Vous devez simplement vous assurer de sélectionner les modèles distincts dans ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/blogs/templates",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Paramètres > Site web > Blog > Modèles"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Boilerplate%20Blog%20Templates.png",
        alt: "Sélection du modèle des paramètres du blog"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listing de blog pour la boucle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La liste des articles est générée par une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "boucle for"
      }), " qui parcourt les articles de votre blog. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Contents"
      }), " est une séquence prédéfinie de contenu qui contient tous les articles contenus dans ce blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n    <div class=\"post-item\">\n        Post item markup that renders with each iteration.\n    </div>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Balisage des modèles de listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le contenu de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "page de listing de blog du boilerplate"
      }), " pour la boucle for est restitué avec le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-index.html",
        children: "balisage suivant"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n    {# On the blog homepage the first post will be featured above older posts #}\n    {% if (loop.first && current_page_num == 1 && !topic) %}\n    <div class=\"blog-index__post blog-index__post--large\">\n        <a class=\"blog-index__post-image blog-index__post-image--large\"\n        {% if content.featured_image %}\n            style=\"background-image: url('{{ content.featured_image }}')\";\n        {% endif %}\n        href=\"{{ content.absolute_url }}\"></a>\n        <div class=\"blog-index__post-content  blog-index__post-content--large\">\n        <h2><a href=\"{{ content.absolute_url }}\">{{ content.name }}</a></h2>\n        {{ content.post_list_content }}\n        </div>\n    </div>\n    {% else %}\n    <div class=\"blog-index__post blog-index__post--small\">\n        <a class=\"blog-index__post-image blog-index__post-image--small\"\n        {% if content.featured_image %}\n            style=\"background-image: url('{{ content.featured_image }}')\";\n        {% endif %}\n        href=\"{{ content.absolute_url }}\"></a>\n        <div class=\"blog-index__post-content  blog-index__post-content--small\">\n        <h2><a href=\"{{ content.absolute_url }}\">{{ content.name }}</a></h2>\n        {{ content.post_list_content|truncatehtml(100) }}\n        </div>\n    </div>\n    {% endif %}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fonction if blog_author"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le balisage standard de listing de blog de HubSpot, il existe une fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if blog_author"
      }), ". Cette fonction aura la valeur true si vous consultez une page de listing d'auteur. Une page de listing d'auteurs est une page regroupant les articles d'un seul auteur. Le modèle de boilerplate comprend le nom de l'auteur, sa biographie et ses comptes de réseaux sociaux."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if blog_author %}\n  <div class=\"blog-header\">\n    <div class=\"blog-header__inner\">\n      {% if blog_author.avatar %}\n      <div class=\"blog-header__author-avatar\" style=\"background-image: url('{{ blog_author.avatar }}');\"></div>\n      {% endif %}\n      <h1 class=\"blog-header__title\">{{ blog_author.display_name }}</h1>\n      <h4 class=\"blog-header__subtitle\">{{ blog_author.bio }}</h4>\n      {% if blog_author.has_social_profiles %}\n        <div class=\"blog-header__author-social-links\">\n          {% if blog_author.website %}\n            <a href=\"{{ blog_author.website }}\" target=\"_blank\">\n              {% icon name=\"link\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.facebook %}\n            <a href=\"{{ blog_author.facebook }}\" target=\"_blank\">\n              {% icon name=\"facebook-f\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.linkedin %}\n            <a href=\"{{ blog_author.linkedin }}\" target=\"_blank\">\n              {% icon name=\"linkedin-in\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.twitter %}\n            <a href=\"{{ blog_author.twitter }}\" target=\"_blank\">\n              {% icon name=\"twitter\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n        </div>\n      {% endif %}\n    </div>\n  </div>\n{% else %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fonction if tag"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En plus de la fonction if ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_author"
      }), " qui définit le balisage qui doit être restitué uniquement sur les pages de listing d'auteur, il existe une variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "tag"
      }), " qui peut être utilisée pour restituer le code uniquement sur une page de listing de thème de blog. L'exemple ci-dessous est un bloc de texte prédéfini qui utilise la variable de titre de page pour imprimer automatiquement le nom de la balise en haut d'une page de listing de balise. Ce bloc de texte prédéfini peut être ajouté au code de listing de blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if tag %}\n    <h3>Posts about {{ page_meta.html_title|split(\" | \")|last }}</h3>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fonction if not simple_list_page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans la boucle for, une fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " détermine ce qui doit être restitué dans une liste simple par rapport à une liste ordinaire."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Une liste simple est une liste de tous vos articles et ne prend pas en charge la pagination. La liste simple n'est pas affectée par le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/fr/docs/cms/building-blocks/website-settings#number-of-posts-per-listing-page",
          children: "paramètre de limite d'articles de blog"
        }), " et contient généralement les liens vers les 200 articles de blog les plus récents."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La liste ordinaire parcourt le nombre d'articles spécifié par le paramètre de blog de listing d'article et pagine en conséquence."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'adresse de votre page de listing simple est l'URL de votre blog avec ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/all"
      }), " ajouté à la fin du chemin."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le code suivant est une version simplifiée de cette fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " qui définit ce qui doit être itéré dans une page de listing simple. Notez que la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " utilise une logique inverse ; par conséquent, la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " définit la vue de liste simple. Vous pouvez utiliser une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/fr/docs/cms/hubl/if-statements#unless-statements",
        children: "fonction unless"
      }), " à la place."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if not simple_list_page %}\n    Iterated post markup for regular listing\n{% else %}\n    <h2 class=\"post-listing-simple\"><a href=\"{{content.absolute_url}}\">{{ content.name }}</a></h2>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pagination de listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les pages de listing de blog ont une pagination générée automatiquement. Votre modèle de listing peut inclure une logique permettant aux visiteurs de consulter facilement les articles de votre blog. Le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "blog de boilerplate"
      }), " permet une pagination simple et numérique grâce au ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/modules/blog-pagination.module/module.html",
        children: "balisage suivant"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if contents.total_page_count > 1 %}\n<div class=\"blog-pagination\">\n    {% set page_list = [-2, -1, 0, 1, 2] %}\n    {% if contents.total_page_count - current_page_num == 1 %}{% set offset = -1 %}\n    {% elif contents.total_page_count - current_page_num == 0 %}{% set offset = -2 %}\n    {% elif current_page_num == 2 %}{% set offset = 1 %}\n    {% elif current_page_num == 1 %}{% set offset = 2 %}\n    {% else %}{% set offset = 0 %}{% endif %}\n\n    <a class=\"blog-pagination__link blog-pagination__prev-link {{ \"blog-pagination__prev-link--disabled\" if !last_page_num }}\" href=\"{{ blog_page_link(last_page_num) }}\">\n    {% icon name=\"chevron-left\" style=\"SOLID\", width=\"13\", no_wrapper=True %}\n    Prev\n    </a>\n    {% for page in page_list %}\n    {% set this_page = current_page_num + page + offset %}\n    {% if this_page > 0 and this_page <= contents.total_page_count %}\n        <a class=\"blog-pagination__link blog-pagination__number-link {{ \"blog-pagination__link--active\" if this_page == current_page_num }}\" href=\"{{ blog_page_link(this_page) }}\">{{ this_page }}</a>\n    {% endif %}\n    {% endfor %}\n    <a class=\"blog-pagination__link blog-pagination__next-link {{ \"blog-pagination__next-link--disabled\" if !next_page_num }}\" href=\"{{ blog_page_link(current_page_num + 1) }}\">\n    Next\n    {% icon name=\"chevron-right\" style=\"SOLID\", width=\"13\", no_wrapper=True %}\n    </a>\n</div>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Balisage des articles de blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les articles d'un blog sont générés par un seul modèle de blog. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Content"
      }), " est un objet de données prédéfini qui contient des informations sur l'article de blog demandé. Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "articles de boilerplate"
      }), " sont restitués avec le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-post.html",
        children: "balisage suivant"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"content-wrapper\">\n  <div class=\"blog-post\">\n    <h1>{{ content.name }}</h1>\n    <div class=\"blog-post__meta\">\n      <a href=\"{{ blog_author_url(group.id, content.blog_post_author.slug) }}\">\n        {{ content.blog_post_author.display_name }}\n      </a>\n      <div class=\"blog-post__timestamp\">\n        {{ content.publish_date_localized }}\n      </div>\n    </div>\n    <div class=\"blog-post__body\">\n      {{ content.post_body }}\n    </div>\n    {% if content.tag_list %}\n    <div class=\"blog-post__tags\">\n      {% icon name=\"tag\" style=\"SOLID\" %}\n      {% for tag in content.tag_list %}\n        <a class=\"blog-post__tag-link\" href=\"{{ blog_tag_url(group.id, tag.slug) }}\">{{ tag.name }}</a>{% if not loop.last %},{% endif %}\n      {% endfor %}\n    </div>\n    {% endif %}\n  </div>\n  <div class=\"blog-comments\">\n    {% module \"blog_comments\" path=\"@hubspot/blog_comments\", label=\"Blog Comments\" %}\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les informations sur l'auteur de l'article de blog sont également disponibles dans les données ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<img alt=\"{{ content.blog_post_author.display_name }}\" src=\"{{ content.blog_post_author.avatar }}\">\n<h3>Written by <a class=\"author-link\" href=\"{{ blog_author_url(group.id, content.blog_post_author.slug) }}\">{{ content.blog_post_author.display_name }}</a></h3>\n<p>{{ content.blog_post_author.bio }}</p>\n{% if content.blog_post_author.has_social_profiles %}\n    <div class=\"hs-author-social-section\">\n        <div class=\"hs-author-social-links\">\n            {% if content.blog_post_author.facebook %}\n                <a href=\"{{ content.blog_post_author.facebook }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-facebook\">Facebook</a>\n            {% endif %}\n            {% if content.blog_post_author.linkedin %}\n                <a href=\"{{ content.blog_post_author.linkedin }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-linkedin\">LinkedIn</a>\n            {% endif %}\n            {% if content.blog_post_author.twitter %}\n                <a href=\"{{ content.blog_post_author.twitter }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-twitter\">Twitter</a>\n            {% endif %}\n            {% if content.blog_post_author.google_plus %}\n                <a href=\"{{ content.blog_post_author.google_plus }}?rel=author\" target=\"_blank\" class=\"hs-author-social-link hs-social-google-plus\">Google+</a>\n            {% endif %}\n        </div>\n    </div>\n{% endif %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}