"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 176124027255;
const slug = exports.slug = 'guides/crm/public-apps/fetching-data';
const title = exports.title = 'Fetching data for public apps (BETA)';
const name = exports.name = 'Fetching data for public apps (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to  use the hubspot.fetch() API to fetch data for your project-based public app.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "method",
  "label": "Method",
  "parent": null
}, {
  "depth": 0,
  "id": "specify-urls-to-fetch-from",
  "label": "Specify URLs to fetch from",
  "parent": null
}, {
  "depth": 0,
  "id": "headers-and-metadata",
  "label": "Headers and metadata",
  "parent": null
}, {
  "depth": 0,
  "id": "proxying-requests-to-a-locally-running-back-end",
  "label": "Proxying requests to a locally running back-end",
  "parent": null
}, {
  "depth": 1,
  "id": "request-signatures-during-proxied-local-development",
  "label": "Request signatures during proxied local development",
  "parent": "proxying-requests-to-a-locally-running-back-end"
}, {
  "depth": 2,
  "id": "injecting-client_secret",
  "label": "Injecting CLIENT_SECRET",
  "parent": "proxying-requests-to-a-locally-running-back-end"
}, {
  "depth": 0,
  "id": "hubspot.fetch-examples",
  "label": "hubspot.fetch examples",
  "parent": null
}, {
  "depth": 1,
  "id": "promise-chaining",
  "label": "Promise chaining",
  "parent": "hubspot.fetch-examples"
}, {
  "depth": 1,
  "id": "async%2Fawait",
  "label": "Async/await",
  "parent": "hubspot.fetch-examples"
}, {
  "depth": 1,
  "id": "authorization-header",
  "label": "Authorization header",
  "parent": "hubspot.fetch-examples"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      em: "em",
      a: "a",
      h1: "h1",
      code: "code",
      ul: "ul",
      li: "li",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      img: "img",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The features described in this guide are in Early Access beta, separate from the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "CRM development tools to build UI extensions with React as frontend"
        }), " beta for private app UI extension development."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/build-app-cards",
          children: "Request access to the UI extensions for public apps beta"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Fetching data for public apps (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While private apps can use serverless functions to fetch data, when you build UI extensions inside public apps, you need to bring your own REST-based back-end and use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.fetch()"
      }), " API to fetch data."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Requests made with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.fetch()"
      }), " are subject to the following limits:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Each app is allowed up to 20 concurrent requests per account that it's installed in. Additional requests will be rejected with the status code ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "429"
        }), ", and can be retried after a delay."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Each request has a maximum timeout of 15 seconds. Both request and response payloads are limited to 1MB. You can specify a lower timeout per request using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "timeout"
        }), " field. Request duration time includes the time required to establish an HTTP connection. HubSpot will automatically retry a request once if there are issues establishing a connection, or if the request fails with a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "5XX"
        }), " status code within the 15 second window."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, learn more about using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.fetch()"
      }), " API along with examples."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Method"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The method contract for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.fetch()"
      }), " is as follows:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { hubspot } from '@hubspot/ui-extensions';\n\ninterface Options {\n  method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';\n  timeout?: number;\n  body?: {\n    [key: string  | number]: unknown;\n  }\n}\n\nhubspot.fetch(resource: string | URL): Promise<Response>\nhubspot.fetch(resource: string, options?: Options): Promise<Response>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "method"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The method to use."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeout"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Time in milliseconds to allow for the request to complete before timing out. Timeout will occur when the back-end request duration exceeds this value ", (0, _jsxRuntime.jsx)("u", {
              children: "or"
            }), " 15 seconds—whichever is smaller. Request duration time includes the time required to establish an HTTP connection.HubSpot will retry a request once if there are issues establishing a connection, or if the request fails with a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "5XX"
            }), " status code within the 15 second window."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The request body."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.fetch()"
        }), " does ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " support request and response headers. The HubSpot signature must be used to perform authentication/authorization in your back-end. For security reasons, you should ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " store secrets in your React code to communicate with third-party back-ends. Instead, use your own back-end to fetch data from other third-party APIs after ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests",
          children: "validating the HubSpot signature"
        }), " on the request."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Specify URLs to fetch from"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To enable your extension to request data from a URL, you'll first need to add it to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "allowedUrls"
      }), " array in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/creating-public-apps#app-configuration",
        children: "public-app.json file"
      }), ". URLs not included in this array cannot be requested by the extension."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example public-app.json\n{\n  \"name\": \"My public app\",\n  \"uid\": \"my-public-app\",\n  \"description\": \"This is my public app.\",\n  \"logo\": \"./my-company.png\"\n  \"allowedUrls\": [\n    \"https://app.example.com\",\n    \"https://app.example2.com:8080\",\n    \"https://api.example.com/user/\",\n    \"https://api.example2.com/user\"\n  ]\n ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when running the local development server, any ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.fetch()"
        }), " request will still go to your hosted back-end via a HubSpot-managed data fetch service, and the request URL must be on the allowlist in ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "public-app.json"
        }), ". If you need to update the allowlist, you'll need to run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload"
        }), " for the change to take effect."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Headers and metadata"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To ensure that the requests hitting your back-end are coming from HubSpot, several headers are included in the request. You can use these headers, along with the incoming request fields, to verify the signature of the request. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "validating HubSpot requests"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot will always populate headers related to request signing and also allow you to pass an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " header from ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.fetch()"
      }), ". See the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#authorization-header",
        children: "example hubspot.fetch() request with Authorization header"
      }), " for more information."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " while you can use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.fetch()"
        }), " to pass an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Authorization"
        }), " request header, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.fetch()"
        }), " does ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " pass other client-supplied request headers or return response headers set by your back-end server."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot also automatically adds the following query parameters to each request to supply metadata: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userId"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userEmail"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "appId"
      }), ". As the request URL is hashed as part of the signature header, this will help you securely retrieve the identity of the user making requests to your back-end."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If you're not seeing appended metadata passed with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.fetch()"
        }), " requests, check whether you have a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "local.json"
        }), " file that's currently rerouting requests via a proxy. If so, disable this local data fetch feature by renaming the file to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "local.json.bak"
        }), " and restarting the development server."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Proxying requests to a locally running back-end"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a locally running back-end, you can set up a proxy to remap ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.fetch()"
      }), " requests made during local development. This proxy is configured through a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "local.json"
      }), " file in your project, and will prevent requests from being routed through HubSpot's data fetch service."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To proxy requests to a locally running back-end:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "local.json"
        }), " file in the same directory as your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "public-app.json"
        }), " file. In this file, define a proxy that remaps requests made using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.fetch()"
        }), ". This mapping will only happen for the locally running extension. You can include multiple proxy key-value pairs in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "proxy"
        }), " object."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example local.json file\n{\n  \"proxy\": {\n    \"https://example.com\": \"http://localhost:8080\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Each proxy URL must be a valid URL and use HTTPS."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Path-based routing is not supported. For example, the following proxy won't work: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"https://example.com/a\": \"http://localhost:8080\""
          })]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Upload your project by running ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs project upload"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["With your project uploaded, run ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs project dev"
          }), " to start the development server. The CLI should confirm that it has detected your proxy."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-local-proxy-detected.png",
            alt: "public-app-local-proxy-detected"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["When you request the mapped URL using ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hubspot.fetch()"
          }), ", the CLI will confirm the remapping."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-local-proxy-remapping.png",
            alt: "public-app-local-proxy-remapping"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Request signatures during proxied local development"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, when proxying requests during local development, requests will ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " be signed or include metadata in query parameters. However, if you want to introduce request signing into the local development process, you can inject the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLIENT_SECRET"
      }), " environment variable into the local development process."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Injecting CLIENT_SECRET"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After setting up your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "local.json"
      }), " file to proxy specific domains, you can inject the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLIENT_SECRET"
      }), " variable when starting the local development server by prepending the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project dev"
      }), " command with the variable:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "CLIENT_SECRET=\"abc123\" hs project dev\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note that this doesn't have to be a real ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLIENT_SECRET"
      }), " value, as long as you inject the same variable in your locally running back-end that you're using for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project dev"
      }), ". For example, your back-end might include the following Node or Python code:"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "CLIENT_SECRET=\"abc123\" node my-app.js\n# and also\nCLIENT_SECRET=\"abc123\" npm run dev\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "CLIENT_SECRET=\"abc123\" python my-app.py\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["And to access the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLIENT_SECRET"
      }), " variable:"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "console.log(process.env.CLIENT_SECRET);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "import os\nprint(os.environ['CLIENT_SECRET'])\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you've finalized your request signing logic and have permanently added it to your back-end code, you'll need to inject the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLIENT_SECRET"
      }), " variable from your app into the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project dev"
      }), " command permanently. For ease of use, consider baking the variable into your start scripts for local development."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To validate HubSpot request signatures in your custom back-end, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "request validation guide"
      }), ". You can also use HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.npmjs.com/package/@hubspot/api-client",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot/api-client"
        })
      }), " npm module to verify requests and sign them yourself. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Signature } = from '@hubspot/api-client'\n\nconst url = `${req.protocol}://${req.header('host')}${req.url}`\nconst method = req.method;\nconst clientSecret = process.env.CLIENT_SECRET\nconst signatureV3 = req.header('X-HubSpot-Signature-v3');\nconst timestamp = req.header('X-HubSpot-Request-Timestamp');\n\n// Reject the request if the timestamp is older than 5 minutes.\nif (parseInt(timestamp, 10) < (Date.now() - 5 * 60 * 1000)) {\n  throw Error('Bad request. Timestamp too old.')\n}\n\nconst requestBody = req.body === undefined || req.body === null\n  ? ''\n  : req.body;\n\nconst validV3 = Signature.isValid({\n  signatureVersion: 'v3',\n  signature: signatureV3,\n  method,\n  clientSecret,\n  requestBody,\n  url,\n  timestamp,\n});\n\nif (!validV3) {\n  throw Error('Bad request. Invalid signature.')\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "hubspot.fetch examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below are examples of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.fetch"
      }), " requests to illustrate promise chaining, async/await, and authorization header usage."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Promise chaining"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import React, { useEffect } from 'react';\nimport { hubspot, Text } from '@hubspot/ui-extensions';\n\nhubspot.extend(({ context }) => <Hello context={context} />);\n\nconst Hello = ({ context }) => {\n  useEffect(() => {\n    let url = 'https://run.mocky.io/v3/98c56581'; // replace with your own\n    hubspot\n      .fetch(url, {\n        timeout: 2_000,\n        method: 'GET',\n      })\n      .then((response) => {\n        console.log('Server response:', response.status);\n        response\n          .json()\n          .then((data) => console.log(data))\n          .catch((err) => console.error('Failed to parse as json', err));\n      })\n      .catch((err) => {\n        console.error('Something went wrong', err);\n      });\n  }, []);\n\n  return <Text>Hello world</Text>;\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Async/await"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import React, { useEffect } from 'react';\nimport { hubspot, Text } from '@hubspot/ui-extensions';\n\nhubspot.extend(({ context }) => <Hello context={context} />);\n\nconst Hello = ({ context }) => {\n  useEffect(() => {\n    const fetchData = async () => {\n      let url = 'https://run.mocky.io/v3/98c56581'; // replace with your own\n      const response = await hubspot.fetch(url, {\n        timeout: 2_000,\n        method: 'GET',\n      });\n      console.log('Server response:', response.status);\n      try {\n        const data = await response.json();\n        console.log(data);\n      } catch (err) {\n        console.error('Failed to parse as json', err);\n      }\n    };\n    fetchData().catch((err) => console.error('Something went wrong', err));\n  }, []);\n\n  return <Text>Hello world</Text>;\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Authorization header"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You may return a short-lived authorization token from your back-end service after validating the HubSpot signature. You can then use this token to access other resources."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To get the access token from your back-end server in the UI extension:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hubspot.fetch(`${BACKEND_ENDPOINT}/get-access-token`, {\n  timeout: 3000,\n  method: 'GET',\n})\n.then((response: Response) => {\n  response.json().then((data) => setAccessToken(data.accessToken));\n})\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To return a short-lived access token from your back-end server:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "app.get('/get-access-token', (req, res) => {\n  validateHubspotSignatureOrThrow(req);\n  res.json({\n    accessToken: generateShortLivedAccessToken(req.query.userEmail),\n    expiryTime: addMinutes(currentTime, 10),\n  });\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To attach access tokens to other UI extension requests:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hubspot.fetch('https://www.oauth-enabled-api.com/', {\n  timeout: 3000,\n  method: 'GET',\n  headers: {\n    'Authorization': `Bearer ${accessToken}`\n  }\n}).then((response: Response) => {\n  ...\n})\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}