'use es6';

import { argumentType } from '../../common/constants.js';
import { toSnakeCase } from '../../common/stringutils';
export function formatArgumentKey(key) {
  const formattedKey = toSnakeCase(key);
  return formattedKey;
}
export function formatArgumentValue({
  key,
  value,
  type
}) {
  switch (type) {
    case argumentType.OBJECT:
    case argumentType.REF:
      return typeof value === 'object' ? formatArgumentKey(key) : formatArgumentKey(value);
    case argumentType.STRING:
      return `"${value}"`;
    case argumentType.ARRAY:
      return JSON.stringify([value].flat());
    default:
      return value;
  }
}