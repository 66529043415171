"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 176115251496;
const slug = exports.slug = 'guides/crm/public-apps/webhooks';
const title = exports.title = 'Working with webhooks (BETA)';
const name = exports.name = 'Working with webhooks (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to add webhooks to your public app built using a developer project.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "defining-webhook-subscriptions",
  "label": "Defining webhook subscriptions",
  "parent": null
}, {
  "depth": 0,
  "id": "subscription-types",
  "label": "Subscription types",
  "parent": null
}, {
  "depth": 0,
  "id": "response-payloads",
  "label": "Response payloads",
  "parent": null
}, {
  "depth": 0,
  "id": "view-webhook-subscriptions-in-hubspot",
  "label": "View webhook subscriptions in HubSpot",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      em: "em",
      a: "a",
      h1: "h1",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The features described in this guide are in Early Access beta, separate from the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "CRM development tools to build UI extensions with React as frontend"
        }), " beta for private app UI extension development."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/build-app-cards",
          children: "Request access to the UI extensions for public apps beta"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Working with webhooks (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Build webhooks into your public app to subscribe to events happening in the account that the app is installed in."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this guide, learn about:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#defining-webhook-subscriptions",
          children: "Defining webhook subscriptions"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#subscription-types",
          children: "Subscription types"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#response-payloads",
          children: "Response payloads"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#view-webhook-subscriptions-in-hubspot",
          children: "Viewing webhook subscriptions in HubSpot"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Defining webhook subscriptions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhook subscriptions are defined in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks.json"
      }), " file within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks"
      }), " folder in the same directory as your app (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src/app"
      }), "). You'll also need to update your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/creating-public-apps#app-configuration",
        children: "public-app.json"
      }), " configuration file to reference that file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-webhooks-folder.png",
        alt: "public-app-webhooks-folder"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks.json"
      }), " file contains fields for defining the webhook's settings and event subscriptions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example webhooks.json\n{\n  \"settings\": {\n    \"targetUrl\": \"https://example.com/webhook\",\n    \"maxConcurrentRequests\": 10\n  },\n  \"subscriptions\": {\n    \"crmObjects\": [\n      {\n        \"subscriptionType\": \"object.propertyChange\",\n        \"objectName\": \"contact\",\n        \"propertyName\": \"firstname\",\n        \"active\": true\n      },\n      {\n        \"subscriptionType\": \"object.creation\",\n        \"objectName\": \"contact\",\n        \"active\": true\n      }\n    ],\n    \"legacyCrmObjects\": [\n      {\n        \"subscriptionType\": \"contact.propertyChange\",\n        \"propertyName\": \"lastname\",\n        \"active\": true\n      },\n      {\n        \"subscriptionType\": \"contact.deletion\",\n        \"active\": true\n      }\n    ],\n    \"hubEvents\": [\n      {\n        \"subscriptionType\": \"contact.privacyDeletion\",\n        \"active\": true\n      }\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object that specifies your webhook settings, including ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "targetUrl"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "targetUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The URL that webhooks will be sent to. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#response-payload",
              children: "response payload"
            }), " sent with the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " request."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The maximum number of concurrent requests that will be sent."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object that specifies the webhook subscriptions."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crmObjects"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array containing event subscription definitions. This is the standard array to include, and should be used for all events in the new format (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.*"
            }), "). Classic webhook subscription types should instead be included in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "legacyCrmObjects"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubEvents"
            }), " arrays, depending on the event. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#subscription-types",
              children: "subscription types"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of event being subscribed to. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#subscription-types",
              children: "subscription types"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The CRM object being subscribed to."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The property on the CRM object being subscribed to."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether webhooks will be sent for this subscription."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "legacyCrmObjects"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array containing classic subscription types, such as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.creation"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.deletion"
            }), ". See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks#webhook-subscriptions",
              children: "webhooks API guide"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubEvents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array containing the classic subscription types ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.privacyDeletion"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.*"
            }), ". See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks#webhook-subscriptions",
              children: "webhooks API guide"
            }), " for more information."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Subscription types"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Public apps built with projects use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/create-generic-webhook-subscriptions",
        children: "generic webhook subscription"
      }), " syntax, using the format ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "object.*"
      }), " rather than specifying the object name in the subscription type (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact.*"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Subscription type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Format"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Creation"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.creation"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Deletion"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.deletion"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Merge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.merge"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Restore"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.restore"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Property change"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.propertyChange"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Association change"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.associationChange"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To specify the type of CRM object you're subscribing to, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectName"
      }), " field in the subscription definition object using any of the supported objects in the table below. You'll need to include the object's corresponding scopes in your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "public-app.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "appointment"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "fee"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "quote_template"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "call"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "feedback_submission"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "task"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "cart"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "goal_target"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "tax"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "commerce_payment"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "line_item"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "ticket"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "communication"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "listing"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "partner_client"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "company"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "meeting_event"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "lead"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "note"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "service"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "course"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "order"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "subscription"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "deal"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "postal_mail"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "invoice"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "discount"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "user"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "email"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "quote"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "partner_account"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "engagement"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: " "
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: " "
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example webhooks.json\n{\n  \"settings\": {\n    \"targetUrl\": \"https://example.com/webhook\",\n    \"maxConcurrentRequests\": 10\n  },\n  \"subscriptions\": {\n    \"crmObjects\": [\n      {\n        \"subscriptionType\": \"object.propertyChange\",\n        \"objectName\": \"contact\",\n        \"propertyName\": \"firstname\",\n        \"active\": true\n      }\n      ...\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you need to subscribe to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks#webhook-subscriptions",
        children: "classic subscription types"
      }), ", you can store them in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "legacyCrmObjects"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubEvents"
      }), " arrays instead, depending on the type of subscription."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example webhooks.json\n{\n  \"settings\": {\n    \"targetUrl\": \"https://example.com/webhook\",\n    \"maxConcurrentRequests\": 10\n  },\n  \"subscriptions\": {\n    \"legacyCrmObjects\": [\n      {\n        \"subscriptionType\": \"contact.propertyChange\",\n        \"propertyName\": \"lastname\",\n        \"active\": true\n      },\n      {\n        \"subscriptionType\": \"contact.deletion\",\n        \"active\": true\n      }\n    ],\n    \"hubEvents\": [\n      {\n        \"subscriptionType\": \"contact.privacyDeletion\",\n        \"active\": true\n      }\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "legacyCrmObjects"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array containing ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks#webhook-subscriptions",
              children: "classic subscription types"
            }), " (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.propertyChange"
            }), ")"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubEvents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array that can contain the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.privacyDeletion"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.*"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks#webhook-subscriptions",
              children: "classic subscription types"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Response payloads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When an event that the app is subscribed to occurs, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "targetUrl"
      }), " you specify in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks.json"
      }), " will receive a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request containing JSON formatted data from HubSpot. All events will include the same base set of fields, with other fields being added depending on the event type. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/create-generic-webhook-subscriptions#parse-generic-webhook-payloads",
        children: "parsing webhook payloads for specific event types."
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is an example payload for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyChange"
      }), " event. This type of event contains all generic fields, plus the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyName"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyValue"
      }), " fields to show which property changed and the new value."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example webhooks response payload\n[\n  {\n    \"appId\": 3715530,\n    \"eventId\": 100,\n    \"subscriptionId\": 2764026,\n    \"portalId\": 123456,\n    \"occurredAt\": 1723651850844,\n    \"subscriptionType\": \"object.propertyChange\",\n    \"attemptNumber\": 0,\n    \"objectId\": 987654,\n    \"changeSource\": \"CRM\",\n    \"objectTypeId\": \"0-1\",\n    \"propertyName\": \"firstname\",\n    \"propertyValue\": \"sample-value\",\n    \"isSensitive\": false\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of your public app. This can be helpful if you have multiple applications pointing to the same webhook URL."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the event that triggered this notification. This value is not guaranteed to be unique."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the subscription that triggered the event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/manage-multiple-hubspot-accounts#check-your-current-account",
              children: "ID of the HubSpot account"
            }), " where the event occurred."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When the event occurred as a unix timestamp (in milliseconds)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The webhook subscription type. Can be one of the following:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "object.creation"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "object.deletion"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "object.merge"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "object.restore"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "object.propertyChange"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "object.associationChange"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attemptNumber"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Starting at ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", which number attempt this is to notify your service of this event.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the object that was created, changed, or deleted. For example, for a contact-related event, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), " would be the contact's ID."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "changeSource"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The source of the change. This can be any of the change sources that appear in contact property histories."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of object that triggered the event. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/understanding-the-crm#object-type-ids",
              children: "full list of object type IDs"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The name of the property that was updated (for ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.propertyChange"
            }), " events only)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyValue"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The new property value that resulted from the change (for ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.propertyChange"
            }), " events only)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isSensitive"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Will be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " if the property is a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/sensitive-data",
              children: "sensitive data property"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sourceId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the source that triggered the event (e.g., a user ID if it was a user that updated the property data)."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "object.associationChange"
      }), " subscription will trigger for all associations, including custom association labels. Association change events will also trigger on both incoming and outgoing association changes. This means that an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "object.associationChange"
      }), " event defined for an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectName"
      }), " of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), " will not only trigger on a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CONTACT_TO_DEAL"
      }), " association change, but also on a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DEAL_TO_CONTACT"
      }), " association change."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example webhooks response payload\n[\n  {\n    \"eventId\": 668521389,\n    \"subscriptionId\": 621550,\n    \"portalId\": 123456,\n    \"appId\": 3715530,\n    \"occurredAt\": 1715708228603,\n    \"subscriptionType\": \"object.associationChange\",\n    \"attemptNumber\": 0,\n    \"changeSource\": \"USER\",\n    \"associationType\": \"CONTACT_TO_DEAL\",\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 4,\n    \"fromObjectId\": 3788,\n    \"fromObjectTypeId\": \"0-3\",\n    \"toObjectId\": 4658499728,\n    \"toObjectTypeId\": \"0-1\",\n    \"associationRemoved\": true,\n    \"isPrimaryAssociation\": false,\n    \"sourceId\": \"userId:864745280\"\n  },\n  {\n    \"eventId\": 2975980077,\n    \"subscriptionId\": 621550,\n    \"portalId\": 885814039,\n    \"appId\": 5553555,\n    \"occurredAt\": 1715708228603,\n    \"subscriptionType\": \"object.associationChange\",\n    \"attemptNumber\": 0,\n    \"changeSource\": \"USER\",\n    \"associationType\": \"DEAL_TO_CONTACT\",\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 3,\n    \"fromObjectId\": 4658499728,\n    \"fromObjectTypeId\": \"0-3\",\n    \"toObjectId\": 3788,\n    \"toObjectTypeId\": \"0-1\",\n    \"associationRemoved\": true,\n    \"isPrimaryAssociation\": false,\n    \"sourceId\": \"userId:864745280\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/apps/public-apps/create-generic-webhook-subscriptions#association-events",
        children: ["fields included in ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationChange"
        }), " event payloads"]
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View webhook subscriptions in HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the app settings page in HubSpot, you can view a list of each event subscription for each subscription in the app's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To view an app's webhook subscriptions in HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar of your developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Apps"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-account-app-navigation.png",
            alt: "developer-account-app-navigation"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of the app."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar, under ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Features"
          }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Webhooks"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-webhooks.png",
            alt: "public-app-webhooks"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Event subscriptions"
          }), ", you can view each of the app's webhook subscriptions."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["To view more information about a subscription, including number of times triggered and number of errors, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of the subscription."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Click the numbers in the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Total count"
              }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Errors"
              }), " columns to navigate to the webhooks monitoring tab."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Hover over a subscription type then click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Details"
              }), " to open the details panel on the right. This panel includes a sample event response payload and a testing feature."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-webhooks-subscription-details.png",
                alt: "public-app-webhooks-subscription-details"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}