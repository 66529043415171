'use es6';

import { createSelector } from 'reselect';
import { getHasScopeSplitAccess } from '../../Auth/selectors';
import { getSecurityTypes as getSecurityTypesUtil } from '../utils/SpecificationUtils';
import { AuthTypesWithVersions, DocsToSwaggerAuthTypes } from '../../TryIt/constants';
import { AuthTypes } from '../../TryIt/constants';
const getSpecState = state => state.doc;
export const getSpecificationStatus = createSelector([getSpecState], specState => specState.get('status'));
export const getSpecificationWithExtensions = createSelector([getSpecState], specState => specState.get('specificationWithExtensions'));
export const getPublicApiSpec = createSelector([getSpecState], specState => specState.get('publicApiSpec') && specState.get('publicApiSpec').info || undefined);
export const getOasDocs = createSelector([getSpecState], specState => specState.get('oasDocs'));
export const getFilteredOasDocs = createSelector([getSpecState], specState => specState.get('filteredOasDocs'));
export const getOas = createSelector([getSpecState], specState => specState.get('oas'));
export const getDocDetailsMap = createSelector([getSpecState], specState => specState.get('docDetails'));
export const getSectionHeadings = createSelector([getOas], specState => specState.tags || []);

// Individual doc details selectors
export const getDocDetails = (state, ownProps) => {
  return state.doc.getIn(['docDetails', ownProps.doc._id]);
};
export const getFormData = createSelector([getDocDetails], details => details.get('formData'));
export const getHar = createSelector([getDocDetails], details => details.get('har'));
export const getOperation = createSelector([getDocDetails], details => details.get('operation'));
export const getIsMultipart = createSelector([getOperation], operation => !!(operation && operation.requestBody && operation.requestBody.content && 'multipart/form-data' in operation.requestBody.content));

// Every security type, including legacy/granular verions
export const getSecurityTypesWithVersions = createSelector([getOperation], operation => getSecurityTypesUtil(operation.security));

// Each unique security type, not including legacy/granular
export const getSecurityTypes = createSelector([getSecurityTypesWithVersions], securityTypes => {
  const tags = [];
  for (const securityType of securityTypes) {
    if (securityType.endsWith('_OAUTH_TOKEN')) {
      if (!tags.includes(AuthTypes.OAUTH_TOKEN)) {
        tags.push(AuthTypes.OAUTH_TOKEN);
      }
    } else if (securityType.endsWith('PRIVATE_APPS')) {
      if (!tags.includes(AuthTypes.PRIVATE_APPS)) {
        tags.push(AuthTypes.PRIVATE_APPS);
      }
    } else {
      tags.push(securityType);
    }
  }
  return tags;
});
export const getHasSplitScopesInSpec = createSelector([getSecurityTypesWithVersions], securityTypesWithVersions => securityTypesWithVersions.includes(AuthTypesWithVersions.LEGACY_OAUTH_TOKEN));

// What is now known as legacy oauth2 scopes ('oauth2_legacy') used to just be
// the only type of oauth scope, and was keyed as just 'oauth2'. This determines
// if we should be using the new key or the old, and allows for a gradual
// rollout.
export const getLegacyOauthSecurityTypeKey = createSelector([getHasSplitScopesInSpec], hasSplitScopes => hasSplitScopes ? DocsToSwaggerAuthTypes.LEGACY_OAUTH_TOKEN : DocsToSwaggerAuthTypes.OAUTH_TOKEN);
const getScopeElementsWithKey = (scopes, key) => {
  if (!scopes || !key) {
    return null;
  }
  return scopes.reduce((acc, el) => {
    if (key in el) {
      acc.push(el[key]);
    }
    return acc;
  }, []);
};
export const getLegacyScopes = createSelector([getOperation, getLegacyOauthSecurityTypeKey], (operation, legacyKey) => getScopeElementsWithKey(operation.security, legacyKey));
export const getGranularScopes = createSelector([getHasScopeSplitAccess, getOperation], (hasAccess, operation) => {
  if (!hasAccess || !operation.security) {
    return null;
  }
  const hasLegacyScopes = !!operation.security.find(el => DocsToSwaggerAuthTypes.LEGACY_OAUTH_TOKEN in el);
  if (!hasLegacyScopes) {
    // If there's no oauth2_legacy key in the security list, then this endpoint
    // hasn't migrated to having both granular & legacy scopes, so the scopes
    // listed under oauth2 are actually the legacy ones.
    return null;
  }
  return getScopeElementsWithKey(operation.security, DocsToSwaggerAuthTypes.GRANULAR_OAUTH_TOKEN);
});
export const getAvailableClientLibraries = createSelector([getSpecState], specState => specState.get('availableClientLibraries'));