"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313503;
const slug = exports.slug = 'guides/cms/marketplace/listing-templates';
const title = exports.title = 'Anunciar y actualizar plantillas en el Mercado de plantillas de HubSpot';
const name = exports.name = 'LATAM (ES) Marketplace Requirements Overview > Listing CMS Assets';
const metaDescription = exports.metaDescription = 'Una vez que hayas creado una cuenta de proveedor del mercado de plantillas, puedes usarla para crear anuncios de plantillas y luego enviarlos para su aprobación. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-una-plantilla-de-anuncio",
  "label": "Crear una plantilla de anuncio",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-un-anuncio-para-plantillas",
  "label": "Actualizar un anuncio para plantillas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      img: "img",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Anunciar y actualizar plantillas en el Mercado de plantillas de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que hayas creado una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "cuenta de proveedor de mercado de plantillas"
      }), ", puedes usarla para crear anuncios de plantillas y enviarlos para su aprobación para que aparezcan en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "mercado de plantillas de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " solo puedes tener ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "un"
        }), " nuevo envío de cada tipo (módulo o plantilla) en revisión a la vez. Un envío se considera “nuevo\" si no está publicado o aprobado actualmente."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de publicar tu plantilla en el mercado de plantillas, completa tu perfil de proveedor:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de proveedor del mercado de plantillas, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mercado de plantillas"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Perfil del proveedor"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Información de la empresa"
        }), ", rellena todos los campos. Estos campos van a aparecer en tus plantillas. No podrás editar el nombre de tu empresa una vez que se haya elegido y tendrás que ponerte en contacto con el equipo de asistencia de HubSpot para cambiarlo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Información de contacto"
        }), ", completa todos los campos para las secciones ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contacto principal"
        }), " y ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contacto del desarrollador"
        }), ". Esta información solo se utilizará si HubSpot necesita ponerse en contacto contigo en relación con un anuncio y ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " aparecerá en ninguna página del anuncio."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Información de pago"
        }), ", agrega los métodos de pago admitidos. Puedes conectar PayPal o Stripe como gateway de pago. Si tu información de pago hace que el gateway de pago deje de funcionar, tus plantillas se eliminarán temporalmente para evitar un impacto negativo en el cliente."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una plantilla de anuncio"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear y enviar un anuncio de temas o módulos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de proveedor del mercado de plantillas, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mercado de plantillas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear anuncio de venta"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear anuncio de tema"
        }), " o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear anuncio de módulo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revisa la información en la página ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Información del proveedor"
        }), " y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Siguiente"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revisa tu información de pago en la página ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Información de pago"
        }), " y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Siguiente"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tema o módulo"
        }), " que deseas anunciar, luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Guardar y continúa"
        }), ". Se te dirigirá al editor de anuncios."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/theme-listing-marketplace.png",
        alt: "theme-listing-marketplace"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El editor de anuncios se divide en cinco pestañas: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Información del anuncio"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Detalles del tema/módulo"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Categoría"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Asistencia"
      }), " y ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Revisión"
      }), ". Toda la información es obligatoria a menos que se indique lo contrario: Consulta todos los requisitos de la página del anuncio ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/marketplace-guidelines/general-requirements",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Revisar"
        }), ", valida el código de tu plantilla y obtén una vista previa de tu anuncio. Si todo se ve bien, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar para revisión."
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " no cambies el nombre de la carpeta de la plantilla una vez enviada. El anuncio de la plantilla está directamente vinculado a esta carpeta cuando se envía. No podrás volver a enviar o actualizar la plantilla después de cambiar el nombre de la carpeta."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar un anuncio para plantillas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de crear un anuncio de plantilla, puedes actualizarlo más tarde desde tu cuenta de proveedor del mercado de plantillas. Si has realizado cambios en una plantilla, deberás revalidarla antes de que se eliminen esos cambios. Si no revalidas, solo se actualizará el contenido de la página del anuncio del mercado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para actualizar un anuncio de plantilla:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu cuenta de proveedor del mercado de plantillas, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Mercado de plantillas"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Anuncios"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre"
          }), " del anuncio que quieres actualizar. Serás redirigido al editor de anuncios."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz los cambios necesarios en la información del anuncio, los detalles de la plantilla, las categorías y las opciones de asistencia y luego haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Revisar"
          }), " para revisar los cambios."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Revisar"
          }), ", verifica si el código de tu plantilla ha cambiado desde su última validación:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si no has realizado ningún cambio en la plantilla, HubSpot mostrará un mensaje de confirmación en el mosaico de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "código fuente del Tema"
            }), " o en el mosaico de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "código fuente del Módulo"
            }), " que muestra que tu validación está actualizada."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-validation-up-to-date.png",
            alt: "asset-marketplace-validation-up-to-date"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si has realizado cambios en la plantilla, HubSpot mostrará una alerta en los mosaicos del código fuente. Para enviar estos cambios al mercado, primero deberás hacer clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Ejecutar validación"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-run-validation.png",
            alt: "asset-marketplace-run-validation"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "5."
          }), " En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enviar para revisión"
          }), "."]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}