"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35091670308;
const slug = exports.slug = 'guides/api/marketing/marketing-events';
const title = exports.title = 'Marketing API | Marketing Events';
const name = exports.name = 'vNext Docs DP - Marketing Events';
const metaDescription = exports.metaDescription = 'An overview of the Marketing Events object in HubSpot.';
const badge = exports.badge = 'NEW';
const toc = exports.toc = [{
  "depth": 0,
  "id": "in-this-article",
  "label": "In this article",
  "parent": null
}, {
  "depth": 0,
  "id": "scope-requirements",
  "label": "Scope requirements",
  "parent": null
}, {
  "depth": 0,
  "id": "differences-between-internal-id-and-external-id-endpoints",
  "label": "Differences between internal ID and external ID endpoints",
  "parent": null
}, {
  "depth": 0,
  "id": "event-management-endpoints",
  "label": "Event management endpoints",
  "parent": null
}, {
  "depth": 1,
  "id": "event-properties",
  "label": "Event properties",
  "parent": "event-management-endpoints"
}, {
  "depth": 1,
  "id": "create-an-event",
  "label": "Create an event",
  "parent": "event-management-endpoints"
}, {
  "depth": 1,
  "id": "update-event-properties-using-external-ids",
  "label": "Update event properties using external IDs",
  "parent": "event-management-endpoints"
}, {
  "depth": 1,
  "id": "update-event-properties-using-its-objectid",
  "label": "Update event properties using its objectId",
  "parent": "event-management-endpoints"
}, {
  "depth": 1,
  "id": "get-event-details",
  "label": "Get event details",
  "parent": "event-management-endpoints"
}, {
  "depth": 1,
  "id": "delete-an-event",
  "label": "Delete an event",
  "parent": "event-management-endpoints"
}, {
  "depth": 1,
  "id": "update-multiple-events-in-bulk",
  "label": "Update multiple events in bulk",
  "parent": "event-management-endpoints"
}, {
  "depth": 0,
  "id": "event-attendance-endpoints",
  "label": "Event attendance endpoints",
  "parent": null
}, {
  "depth": 1,
  "id": "update-attendance-using-the-event-objectid",
  "label": "Update attendance using the event objectId",
  "parent": "event-attendance-endpoints"
}, {
  "depth": 1,
  "id": "update-attendance-using-the-external-ids-of-the-event",
  "label": "Update attendance using the external IDs of the event",
  "parent": "event-attendance-endpoints"
}, {
  "depth": 0,
  "id": "participant-state-endpoints",
  "label": "Participant state endpoints",
  "parent": null
}, {
  "depth": 1,
  "id": "read-participations-for-a-specific-contact",
  "label": "Read participations for a specific contact",
  "parent": "participant-state-endpoints"
}, {
  "depth": 1,
  "id": "read-participation-breakdown-data",
  "label": "Read participation breakdown data",
  "parent": "participant-state-endpoints"
}, {
  "depth": 1,
  "id": "read-participation-counters",
  "label": "Read participation counters",
  "parent": "participant-state-endpoints"
}, {
  "depth": 1,
  "id": "filtering-participation-breakdown-data",
  "label": "Filtering participation breakdown data",
  "parent": "participant-state-endpoints"
}, {
  "depth": 0,
  "id": "list-association-endpoints",
  "label": "List association endpoints",
  "parent": null
}, {
  "depth": 1,
  "id": "create-list-association-with-a-marketing-event-id",
  "label": "Create list association with a marketing event ID",
  "parent": "list-association-endpoints"
}, {
  "depth": 1,
  "id": "create-list-association-with-external-event-and-account-ids",
  "label": "Create list association with external event and account IDs",
  "parent": "list-association-endpoints"
}, {
  "depth": 1,
  "id": "get-lists-associated-with-a-marketing-event-using-a-marketing-event-id",
  "label": "Get lists associated with a marketing event using a marketing event ID",
  "parent": "list-association-endpoints"
}, {
  "depth": 1,
  "id": "get-lists-associated-with-a-event-using-external-event-and-account-ids",
  "label": "Get lists associated with a event using external event and account IDs",
  "parent": "list-association-endpoints"
}, {
  "depth": 1,
  "id": "delete-list-association-using-a-marketing-event-id",
  "label": "Delete list association using a marketing event ID",
  "parent": "list-association-endpoints"
}, {
  "depth": 1,
  "id": "delete-list-association-using-external-event-and-account-ids",
  "label": "Delete list association using external event and account IDs",
  "parent": "list-association-endpoints"
}, {
  "depth": 0,
  "id": "configure-app-settings",
  "label": "Configure app settings",
  "parent": null
}, {
  "depth": 1,
  "id": "step-1-create-an-api-in-your-app",
  "label": "Step 1: Create an API in your app",
  "parent": "configure-app-settings"
}, {
  "depth": 1,
  "id": "step-2-provide-hubspot-with-the-url-path-to-your-api",
  "label": "Step 2: Provide HubSpot with the URL path to your API",
  "parent": "configure-app-settings"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      code: "code",
      strong: "strong",
      em: "em",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Marketing Events"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A marketing event is a CRM object, similar to contacts and companies, that enables you to track and associate marketing events, such as a webinar, with other HubSpot CRM objects. Below, learn more about working with the marketing event API to integrate marketing events into an app."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In this article"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "Scope requirements"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#differences-between-internal-id-and-external-id-endpoints",
          children: "Differences between internal ID and external ID endpoints"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-update-events",
          children: "Event management endpoints"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#event-attendance-endpoints",
          children: "Event attendance endpoints"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#participant-state-endpoints",
          children: "Participant state endpoints"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#list-association-endpoints",
          children: "List association endpoints"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-app-settings",
          children: "Configure app settings"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-1-create-an-api-in-your-app",
              children: "Step 1: Create an API in your app"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-2-provide-hubspot-with-the-url-path-to-your-api",
              children: "Step 2: Provide HubSpot with the URL path to your API"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Scope requirements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To make an API request to one of the marketing event endpoints, the following ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "scopes"
      }), " are required:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.read"
        }), ": grants permission to retrieve marketing event and attendance data."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.write"
        }), ": grants permission to create, delete, or make changes to marketing event information."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When authenticating calls made by your app, you can either use a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private app access token"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "OAuth"
      }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "authentication methods"
      }), ". For the full list of endpoints available, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/marketing/marketing-events",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Differences between internal ID and external ID endpoints"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Many endpoints below provide two different ways to identify an event you want to fetch or update. Though the end result for similar endpoints might be the same, they differ mainly in the associated IDs you provide:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Endpoints using external IDs:"
        }), " endpoints that require ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalAccountId"
        }), " parameters will only work in the same app that originally created the event. For example, if you created two public apps, called ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App A"
        }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App B"
        }), ", and you created a marketing event via the authentication and IDs associated with ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App A"
        }), ", only ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App A"
        }), " can read, update, or add new participants to the event. If you attempt to access the same event with ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App B"
        }), " using the same externalEventId and externalAccountId, a 404 error will result."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Endpoints using an objectId (BETA):"
        }), " endpoints that require an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), " can be used to access an event by any app with the associated scopes listed in the section above, regardless of the app that originally created the event. If ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App A"
        }), " created a marketing event, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App B"
        }), " can still read, update, or add participants through ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), "-based endpoints."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the endpoints that require the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), " of an event are currently in beta, and are subject to change based on testing and feedback. By using these endpoints, you agree to adhere to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/hubspot-developer-terms",
          children: "HubSpot's Developer Terms"
        }), " & ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "Developer Beta Terms"
        }), ". You also acknowledge and understand the risk associated with testing an unstable API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event management endpoints"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following sections provide context on common event properties, and detail how to use the various event management endpoints to create, read, update, and archive events."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Event properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following properties are available to fetch and update when using the event management endpoints:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The title of your event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The type of the event (e.g., webinar, tradeshow, etc.)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventOrganizer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The individual or organization that's hosting the event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventDescription"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A description for your event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL that users can navigate to where they can learn more details and/or register for your event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventCancelled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether or not the event is cancelled."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventStartTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An ISO 8601 formatted timestamp of the event's start time."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventEndTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An ISO 8601 formatted timestamp of the event's start time."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create an event"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a marketing event you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events"
      }), " and provide the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventName"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "externalEventId"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "externalAccountId"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventOrganizer"
      }), " in the body of your request. You can optionally provide any additional properties listed in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#event-properties",
        children: "table above"
      }), " in your request."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "externalAccountId"
      }), " of your app is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"12345\""
      }), ", and the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "externalEventId"
      }), " of your event in your app is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"67890\""
      }), ", you could create a new event called ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Winter webinar\""
      }), " with a request that would resemble the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for POST request to /marketing/v3/marketing-events\n{\n  \"externalAccountId\": \"12345\",\n  \"externalEventId\": \"67890\",\n  \"eventName\": \"Winter webinar\",\n  \"eventOrganizer\": \"Snowman Fellowship\",\n  \"eventCancelled\": false,\n  \"eventUrl\": \"https://example.com/holiday-jam\",\n  \"eventDescription\": \"Let's get together to plan for the holidays\",\n  \"eventCompleted\": false,\n  \"startDateTime\": \"2024-08-07T12:36:59.286Z\",\n  \"endDateTime\": \"2024-08-07T12:36:59.286Z\",\n  \"customProperties\": [\n    {\n      \"eventSeason\": \"winter\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update event properties using external IDs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can update marketing events by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/upsert"
      }), " endpoint. You can include any ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " along with any other details of your event (including its name, start time, and description)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If a marketing event already exists with the specified ID in your request, it will be updated. Otherwise, a new event will be created."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the following request would create an event with an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "4"
      }), " named \"Virtual cooking class\":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for POST request to /marketing/v3/marketing-events/events/upsert\n{\n  \"inputs\": [\n    {\n      \"customProperties\": [\n        {\n          \"name\": \"property1\",\n          \"value\": \"1234\"\n        }\n      ],\n      \"eventName\": \"Virtual cooking class\",\n      \"startDateTime\": \"2023-11-30T17:46:20.461Z\",\n      \"eventOrganizer\": \"Chef Joe\",\n      \"eventDescription\": \"Join us for a virtual cooking class! Yum.\"\n      \"eventCancelled\": false,\n      \"externalAccountId\": \"CookingCo\",\n      \"externalEventId\": \"4\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update event properties using its objectId"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once an event is created, you can update its properties by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/{objectId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To get the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), " for a specific marketing event, follow the instructions in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events#view-and-analyze-marketing-events",
          children: "this knowledge base article"
        }), " to view the details for an event in your HubSpot account, then locate the ID under the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Record ID"
        }), " field. The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), " will also be returned in the response when you successfully create an event."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You can also make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/marketing/v3/marketing-events"
        }), " endpoint described in the next section."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you have the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), " of an event, you can include it as a path when making a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/marketing/v3/marketing-events/{externalEventId}/identifiers"
        }), ". The response will include all marketing events along with the relevant identifiers for each event (i.e., the event's ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), ", its ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "appInfo"
        }), ", the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketingEventName"
        }), ", and the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalAccountId"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get event details"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get a list of all marketing events along with their properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you need to retrieve the details for a specific marketing event by its ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Record ID"
      }), " in HubSpot, you can provide the ID as the objectId in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/{objectId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for GET request to /marketing/v3/marketing-events/{objectId}\n{\n  \"eventName\": \"Test Marketing Event\",\n  \"eventType\": \"test-type\",\n  \"startDateTime\": \"2024-05-22T12:29:50.734Z\",\n  \"endDateTime\": \"2024-05-25T12:29:50.734Z\",\n  \"eventOrganizer\": \"testEventOrganizer\",\n  \"eventDescription\": \"testDescription\",\n  \"eventUrl\": \"testURL\",\n  \"eventCancelled\": true,\n  \"eventCompleted\": false,\n  \"customProperties\": [\n    {\n      \"name\": \"test_custom_prop\",\n      \"value\": \"1\"\n    },\n    {\n      \"name\": \"test_prop\",\n      \"value\": \"2\"\n    }\n  ],\n  \"objectId\": \"58237132332\",\n  \"externalEventId\": null,\n  \"eventStatus\": \"CANCELLED\",\n  \"appInfo\": {\n    \"id\": \"111\",\n    \"name\": \"Zoom\"\n  },\n  \"registrants\": 1,\n  \"attendees\": 1,\n  \"cancellations\": 2,\n  \"noShows\": 0,\n  \"createdAt\": \"2024-08-07T12:58:40.635Z\",\n  \"updatedAt\": \"2024-10-15T13:35:03.353Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Delete an event"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete a marketing event, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/{objectId}"
      }), " with the event's associated ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If successful, you'll receive a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "204 No Content"
      }), " response."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update multiple events in bulk"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update multiple marketing events in bulk, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing-events/v3/marketing-events/batch/update"
      }), " and provide the properties you want to update for each event within the inputs array of the request body."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you wanted to update several properties of two marketing events with object IDs of 58237132332 and 54073507364 in a single request, the body of your request would resemble the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for batch request to update marketing events\n{\n  \"inputs\": [\n    {\n      \"objectId\": \"58237132332\",\n      \"eventCancelled\": true,\n      \"eventOrganizer\": \"testEventOrganizer\",\n      \"eventUrl\": \"testURL\",\n      \"eventDescription\": \"testDescription\",\n      \"eventName\": \"Test Marketing Event Update\",\n      \"eventType\": \"test-type\"\n    },\n    {\n      \"objectId\": \"54073507364\",\n      \"eventCancelled\": true,\n      \"eventOrganizer\": \"testEventOrganizer\",\n      \"eventUrl\": \"testURL\",\n      \"eventDescription\": \"testDescription\",\n      \"eventName\": \"Test Marketing Event Update 2\",\n      \"eventType\": \"test-type\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event attendance endpoints"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The event attendance state endpoints allow you to record registration activities for a contact, such as whether they registered, attended, or cancelled their registration for your event. For example, you can use this endpoint to record that a HubSpot contact has registered for a marketing event."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update attendance using the event objectId"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " of a marketing event, you can either use the contact ID of the contact you want to record participation state for, or you can use their email address."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To use a contact's ID, make a POST request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/marketing/v3/marketing-events/{objectId}/attendance/{subscribeState}/create"
        }), " then provide the ID of the contact using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "vid"
        }), " field within the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inputs"
        }), " array of your request body. For example, the request body below provides an example of updating the attendance data for a contact with an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "47733471576"
        }), " and specifying when the attendee joined and left the event via the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "joinedAt"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "leftAt"
        }), " properties:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /marketing/v3/marketing-events/{objectId}/attendance/{subscriberState}/create\n{\n  \"inputs\": [\n    {\n      \"vid\": 47733471576,\n      \"properties\": {\n        \"joinedAt\": \"2024-05-22T13:38:16.500Z\",\n        \"leftAt\": \"2024-05-22T15:40:16.500Z\"\n      },\n      \"interactionDateTime\": 1716382579000\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To use a contact's email, make a POST request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/marketing/v3/marketing-events/{objectId}/attendance/{subscribeState}/email-create"
        }), " then provide the email of the contact using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "email"
        }), " field within the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inputs"
        }), " array of your request body.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you're creating a new contact, you can include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactProperties"
            }), " field within the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), " array of your request body to set any associated properties on the newly created contact. Otherwise, if the contact already exists, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactProperties"
            }), " provided in the request will ", (0, _jsxRuntime.jsx)("u", {
              children: "not"
            }), " be updated."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For example, the request body below provides an example of updating the attendance data for a contact with an email address of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "john@example.com"
            }), " and specifying when the attendee joined and left the event via the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "joinedAt"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "leftAt"
            }), " fields within the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            }), " object of your ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), " array:"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /marketing/v3/marketing-events/{objectId}/attendance/{subscriberState}/create\n{\n  \"inputs\": [\n    {\n      \"contactProperties\": {\n        \"additionalProp1\": \"string\",\n        \"additionalProp2\": \"string\"\n      },\n      \"properties\": {\n        \"joinedAt\": \"2024-05-22T13:38:16.500Z\",\n        \"leftAt\": \"2024-05-22T15:40:16.500Z\"\n      },\n      \"email\": \"john@example.com\",\n      \"interactionDateTime\": 1716382579000\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For either of the approaches above, provide the following values for the corresponding path parameters:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), ": the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Record ID"
        }), " of the marketing event in your HubSpot account. Check out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#differences-between-internal-id-and-external-id-endpoints",
          children: "section above"
        }), " for more details on using the objectId of an event versus using its external IDs."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriberState"
        }), ": an enumeration that matches the new attendance status of the contact:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "REGISTERED"
        }), ": indicates that the HubSpot contact has registered for the event."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "ATTENDED"
        }), ": indicates that the HubSpot contact has attended the event. If you're updating a contact's status to ATTENDED, you can also include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "joinedAt"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "leftAt"
        }), " timestamps as parameters in the request body, specified in the ISO8601 Instant format."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELLED"
        }), ": indicates that the HubSpot contact, who had previously registered for the event, has cancelled their registration."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update attendance using the external IDs of the event"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you were previously using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/upsert"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/email-upsert"
        }), " endpoints to update an attendee's status, you can instead use the alternate endpoints listed below. However, compared to the event attendance endpoints above, using these endpoints will ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " provide support for the following:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Creating a new contact if it doesn't already exist."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Showing timeline events on the contact record page."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Specifying the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "joinedAt"
          }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "leftAt"
          }), " properties."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Providing a detailed response upon success."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you do use the endpoints that require the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "externalEventId"
      }), " from your app, you can either use the contact IDs or email address of existing contacts:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you want to use the contact IDs of existing contacts:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Make a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/create"
            }), ", using the ID of the event from your external application as the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the request body, provide an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), " object that includes the following fields:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), ": the date and time at which the contact subscribed to the event."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), ": the contact ID of an existing contact."]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you want to use the email address of one of the event's attendees:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Make a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/email-create"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the request body, provide an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), " object that includes the following fields:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), ": the date and time at which the contact subscribed to the event."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "email"
                }), ": the email address of the attendee as the value of the email field within an inputs"]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If the email address you include don't match the address of an existing contact, a new contact will be created."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For both of the endpoints above, provide the following values for the corresponding path parameters:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), ": the ID of the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events#view-edit-and-analyze-marketing-events",
          children: "marketing event"
        }), ". Check out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#differences-between-internal-id-and-external-id-endpoints",
          children: "section above"
        }), " for more details on using the objectId of an event versus using its external IDs."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriberState"
        }), ": an enumeration that matches the new attendance status of the contact:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "REGISTERED"
        }), ": indicates that the HubSpot contact has registered for the event."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "ATTENDED"
        }), ": indicates that the HubSpot contact has attended the event. If you're updating a contact's status to ATTENDED, you can also include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "joinedAt"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "leftAt"
        }), " timestamps as parameters in the request body, specified in the ISO8601 Instant format."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELLED"
        }), ": indicates that the HubSpot contact, who had previously registered for the event, has cancelled their registration."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " these APIs are idempotent so long as the ID of the contact and the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "interactionDateTime"
        }), " value in the event has not changed. This enables you to safely set attendance state multiple times without HubSpot creating duplicate events in the marketing events properties."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Participant state endpoints"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use the participation endpoints to retrieve event participant data for your marketing events. You can query data such as aggregate metrics for a specific event, as well as participant data for a specific contact or event."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Review the available participation endpoints below. For a full reference of all available parameters for each endpoint, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/marketing/marketing-events",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the activity counts in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events",
          children: "marketing events page"
        }), " in your HubSpot account may differ from the corresponding aggregate metrics from the participation counters API endpoint."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["For example, if a participant registered for an event, then cancelled, then re-registered for the same event, each of those activities will be included in the totals you see in the marketing events UI in your account. If you're using the participant state endpoints below, only the current state of a participant is included in the associated counter for that metric (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "attended"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "registered"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cancelled"
        }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "noShows"
        }), ")."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Read participations for a specific contact"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get event participation data for a specific contact, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/participations/contacts/{contactIdentifier}/breakdown"
      }), ", using's the contact's ID or email address as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactIdentifier"
      }), " path parameter."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response will include a summary of the contact's event participation in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " field:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for GET request for contact participation data\n{\n  \"results\": [\n    {\n      \"associations\": {\n        \"marketingEvent\": {\n          \"externalAccountId\": \"4\",\n          \"marketingEventId\": \"123\",\n          \"externalEventId\": \"456\",\n          \"name\": \"Virtual baking workshop\"\n        },\n        \"contact\": {\n          \"firstname\": \"Jane\",\n          \"contactId\": \"156792341\",\n          \"email\": \"jdoe@example.com\",\n          \"lastname\": \"Doe\"\n        }\n      },\n      \"createdAt\": \"2024-05-21T18:35:04.838Z\",\n      \"id\": \"string\",\n      \"properties\": {\n        \"occurredAt\": \"2024-05-22T10:35:04.838Z\",\n        \"attendancePercentage\": \"string\",\n        \"attendanceState\": \"REGISTERED\",\n        \"attendanceDurationSeconds\": 3600\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Read participation breakdown data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get a breakdown of participation data for a specific event, use your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "externalAccountId"
      }), " and the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "externalEventId"
      }), " of your event to make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/participations/{externalAccountId}/{externalEventId}/breakdown"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Read participation counters"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get an aggregate participation summary for an event, use your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "externalAccountId"
      }), " and the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "externalEventId"
      }), " of your event to make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/participations/{externalAccountId}/{externalEventId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include the total attendance counts:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for GET request for event participation counters\n{\n  \"attended\": 152,\n  \"registered\": 200,\n  \"cancelled\": 3,\n  \"noShows\": 8\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Filtering participation breakdown data"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When fetching breakdown data or event participation data for a specific contact, you can filter the resulting data using the contactIdentifier, state, limit, or after fields as query parameters in your request."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Query parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactIdentifier"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email address or ID of a specific contact"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The participation state for the event. The possible participation states are:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "REGISTERED"
                }), ": The contact has registered for the event"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CANCELLED"
                }), ": The contact's registration has been cancelled."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ATTENDED"
                }), ": The contact attended the event."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NO_SHOW"
                }), ": The contact registered but did not end up attending the event."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Limit the results returned. By default, the limit is set to 10. The valid range is 1-100."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "after"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used for paging between results in the response. Consult the provided offset in the previous page of response data to determine the next index of results to return."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "List association endpoints"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use the endpoints described in the sections below to manage associations between lists and your marketing events."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Many of these endpoints require a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "listId"
      }), " as a path parameter, which you can find on the list details page in your HubSpot account:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lists"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of a list."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Details"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, the list ID will appear under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "List IDs for API integrations"
        }), ". You can click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy list ID"
        }), " to copy the ID to the clipboard."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-details-panel-list-associations-api.png",
        alt: "list-details-panel-list-associations-api"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As you associate lists with your marketing events, they'll appear on the details page for a marketing event in your HubSpot account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contacts"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper left, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contacts"
        }), " and in the dropdown menu, select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing events"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of a marketing event."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Performance"
        }), " tab, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lists"
        }), " to expand the section, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lists added through associations"
        }), " tab."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/review-list-associations-for-marketing-events-api.png",
        alt: "review-list-associations-for-marketing-events-api"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create list association with a marketing event ID"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a new association between a marketing event and an existing list, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/associations/{marketingEventId}/lists/{listId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If successful, you'll receive a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "204 No content"
      }), " response."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create list association with external event and account IDs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a new association between a marketing event and an existing list using the external account ID and the external event ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/associations/{externalAccountId}/{externalEventId}/lists/{listId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If successful, you'll receive a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "204 No content"
      }), " response."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get lists associated with a marketing event using a marketing event ID"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get all lists associated with a marketing event, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/associations/{marketingEventId}/lists"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will resemble the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for GET request for all associated lists for a marketing event\n{\n  \"total\": 1,\n  \"results\": [\n    {\n      \"listId\": \"string\",\n      \"listVersion\": 0,\n      \"createdAt\": \"2024-05-10T08:58:35.769Z\",\n      \"updatedAt\": \"2024-05-10T08:58:35.769Z\",\n      \"filtersUpdatedAt\": \"2024-05-10T08:58:35.769Z\",\n      \"processingStatus\": \"string\",\n      \"createdById\": \"string\",\n      \"updatedById\": \"string\",\n      \"processingType\": \"string\",\n      \"objectTypeId\": \"string\",\n      \"name\": \"string\",\n      \"size\": 0\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get lists associated with a event using external event and account IDs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also get lists associated with a marketing event using an external account ID and the external event ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/associations/{externalAccountId}/{externalEventId}/lists"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Delete list association using a marketing event ID"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete a list association for a marketing event using a marketing event ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/associations/{marketingEventId}/lists/{listId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If successful, you'll receive a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "204 No content"
      }), " response."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Delete list association using external event and account IDs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete a list association for a marketing event using the external account ID and an external event ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/associations/{externalAccountId}/{externalEventId}/lists/{listId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If successful, you'll receive a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "204 No content"
      }), " response."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configure app settings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There's some setup required to allow marketing events to sync properly with HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you send HubSpot an attendance state change (e.g., a registration or cancellation), HubSpot will first check to see if a Marketing Event exists for the specified event ID. If it doesn't, HubSpot will call the configured endpoint for your app to retrieve the details of the marketing event, then create the event in HubSpot and then publish the attendance state change."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This is provided for convenience; however, it's still recommended that you create the Marketing Events yourself via the CRUD methods provided in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/marketing/marketing-events",
        children: "reference documentation"
      }), ", and don't rely on this functionality to create your marketing events in HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Step 1: Create an API in your app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In order to support this, HubSpot requires each app that uses Marketing Events to define an API to fetch information about a specific marketing event."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Requirements:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Accepts:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalAccountId"
            }), ": a query parameter that specifies the accountId of the customer in the external app."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            }), ": a query parameter that specifies the ID of the HubSpot application that is requesting the event details. This will be the ID of your app."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), ": a path parameter in the URL of the request that specifies the ID of the event in the external app that HubSpot requires details about."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Returns:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "A JSON object that provides details for the marketing event, that includes the following fields detailed in the table below:"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field Name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Required"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Field Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the marketing event"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventOrganizer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the organizer of the marketing event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Describes what type of event this is. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBINAR"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONFERENCE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WORKSHOP"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The start date and time of the marketing event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The end date and time of the marketing event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventDescription"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The description of the marketing event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL in the external event application where the marketing event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventCancelled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "bool"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indicates if the marketing event has been cancelled. Defaults to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot will also send a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), " header that you can use to verify that the request came from HubSpot. Read more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "request signatures"
      }), " for additional details on the signature and how to validate it."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Step 2: Provide HubSpot with the URL path to your API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now that you've created the API in your app that will return an object that provides the details of a specific marketing event, you will need to provide HubSpot with the URL path to your API by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/{appId}/settings"
      }), ". This will allow HubSpot to determine how to make requests to your app to get the details of a marketing event."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the body of your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request, specify your URL using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " field. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " must adhere to the following two requirements:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Contain a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "%s"
        }), " character sequence, which HubSpot will use to substitute in the ID of the event (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), ") as a path parameter."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["It must be the full path to the API resource, including the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://"
        }), " prefix and the domain name (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my.event.app"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you configure an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/%s"
      }), ", and you need to make a request to fetch details of an event with id ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234-event-XYZ"
      }), ", for the HubSpot app with id ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "app-101"
      }), " and account with id ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ABC-account-789"
      }), ", HubSpot will make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/1234-event-XYZ?appId=app-101&externalAccountId=ABC-account-789"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}