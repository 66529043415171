"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 21611793414;
const slug = exports.slug = 'guides/api/analytics-and-events/email-analytics';
const title = exports.title = 'EメールイベントAPIの概要 | EメールイベントAPI';
const name = exports.name = 'EメールイベントAPIの概要';
const metaDescription = exports.metaDescription = 'EメールイベントAPIは、HubSpotアカウントから送信されたマーケティングEメールやEメールキャンペーンによって生成されたイベントに関する情報を取得するために使用します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97",
  "label": "イベントタイプ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%80%81%E4%BF%A1%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88",
  "label": "送信イベント",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%81%AE%E7%90%86%E7%94%B1",
  "label": "ドロップの理由",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E9%85%8D%E4%BF%A1%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88",
  "label": "配信イベント",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E9%85%8D%E4%BF%A1%E6%B8%88%E3%81%BF",
  "label": "配信済み",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E5%BB%B6%E6%9C%9F%E6%B8%88%E3%81%BF",
  "label": "延期済み",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E3%83%90%E3%82%A6%E3%83%B3%E3%82%B9",
  "label": "バウンス",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E3%83%90%E3%82%A6%E3%83%B3%E3%82%B9%E3%81%AE%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E3%83%BC",
  "label": "バウンスのカテゴリー",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC-%E3%82%A8%E3%83%B3%E3%82%B2%E3%83%BC%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88-%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88",
  "label": "ユーザー エンゲージメント イベント",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC-%E3%82%B9%E3%83%86%E3%83%BC%E3%82%BF%E3%82%B9-%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88",
  "label": "ユーザー ステータス イベント",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E9%85%8D%E4%BF%A1%E7%99%BB%E9%8C%B2%E5%A4%89%E6%9B%B4%E3%81%AE%E5%8E%9F%E5%9B%A0",
  "label": "配信登録変更の原因",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "-%E3%83%90%E3%82%A6%E3%83%B3%E3%82%B9%E8%A7%A3%E6%B6%88-%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88",
  "label": "'バウンス解消'イベント",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%8F%82%E7%85%A7",
  "label": "イベント参照",
  "parent": null
}, {
  "depth": 1,
  "id": "-sentby-",
  "label": "'sentBy'",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%8F%82%E7%85%A7"
}, {
  "depth": 1,
  "id": "-obsoletedby-",
  "label": "'obsoletedBy'",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%8F%82%E7%85%A7"
}, {
  "depth": 1,
  "id": "-causedby-",
  "label": "'causedBy'",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%8F%82%E7%85%A7"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h3: "h3",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      img: "img",
      strong: "strong",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "EメールイベントAPIの概要"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "EメールイベントAPIは、HubSpotアカウントから送信されたマーケティングEメールやEメールキャンペーンによって生成されたイベントに関する情報を取得するために使用します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**このAPIのユースケース：**マーケティングチームのメンバーがEメールキャンペーンの最適化を目指している場合、EメールイベントAPIを使用してデータを取得し、そのデータを使って、さまざまなコンタクトセグメントにEメールを送信するのに最適なタイミングを判断する機械学習モデルを強化できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eメールイベント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotを介して送信される全てのEメールは、それぞれのEメールのライフサイクルと、受信者がEメールのコンテンツとどのようにやりとりしているかを詳述する、多数のイベントを生成します。各イベントは、以下のプロパティーからなる", (0, _jsxRuntime.jsx)(_components.code, {
        children: "EventId"
      }), "によって一意に識別されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "当該イベントに対してランダムに生成されたID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "created"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "64-bit integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "当該イベントが作成された時点のタイムスタンプ（エポック以降のミリ秒数）。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらのプロパティーを使用して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-event-by-id",
        children: "このエンドポイント"
      }), "で特定のイベントを検索することができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["さらに、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), "を除く全てのイベントタイプには以下のプロパティーもあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "type"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string（列挙）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "イベントのタイプ。イベントタイプの詳細については、次のセクションをご覧ください。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "recipient"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールメッセージ受信者のEメールアドレス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "32-bit integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールメッセージを送信したHubSpotアカウントを参照するID。これは、ご利用のアカウントに対応します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "32-bit integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールメッセージを送信したHubSpotアプリケーションを参照するID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appName"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールメッセージを送信したHubSpotアプリケーションの名前。これは派生値であり、随時変更される可能性があります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "emailCampaignId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "64-bit integer"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eメールメッセージが属しているEメールキャンペーンを参照するID。Eメールキャンペーンの詳細は、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/email-analytics#get-campaign-data-for-a-given-campaign",
              children: "このエンドポイント"
            }), "を介して確認できます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-events",
        children: "このエンドポイント"
      }), "を介して、イベントを一括で検索できます。それには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'recipient'"
      }), "と、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'appId'"
      }), "および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'campaignId'"
      }), "の両方を使用するか、上記のプロパティーの任意の組み合わせを使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["さらに以下のプロパティーも、全てのイベントタイプ（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), "を含む）で使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "sentBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eメールメッセージの", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), "イベントを一意に識別するEventId。該当しない場合、このプロパティーは省略されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "obsoletedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在のイベントを「廃止」する後続のイベントを一意に識別するEventId。該当しない場合、このプロパティーは省略されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "causedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "当該イベントが発生する直接の原因となったイベントを一意に識別するEventId。該当しない場合、このプロパティーは省略されます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベント参照プロパティー -- ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "、および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " -- については、このドキュメントで後ほど詳しく説明します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントタイプ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eメールメッセージのライフサイクルにおいて、HubSpotのEメールAPIによって生成される可能性があるイベントは、12タイプあります。 これらのイベントタイプは、送信、配信、ユーザーエンゲージメント、ユーザーステータスというカテゴリーに大まかに分類できます。以下の図は、イベントタイプ、イベントカテゴリーと、これらの間の関係を示しています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://static.hsappstatic.net/developer_site_web/static-2.153/img/events.svg",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "イベントタイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メッセージはHubSpotの配信プロバイダー宛に送信されて受信され、さらに処理を行うためにキューに追加されています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DROPPED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メッセージはHubSpotまたはHubSpotの配信プロバイダーによって拒否されました。メッセージの配信試行は行われません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PROCESSED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotの配信プロバイダーがメッセージを受信し、これから受信者のEメールサーバーへの配信を試みることが示されています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DELIVERED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のEメールサーバーがメッセージを受け入れて、メッセージが受信者に正常に配信されました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DEFERRED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のEメールサーバーから一時的にメッセージが拒否されました。後でメッセージの配信試行が行われます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BOUNCE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のEメールサーバーがメッセージを受信できなかったか、メッセージを受け入れようとしていません。後でメッセージの配信試行が行われることはありません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "OPEN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者がメッセージを開封しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CLICK"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者がメッセージ内のリンクをクリックしました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "STATUSCHANGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者が何らかの方法でEメール配信登録を変更しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAMREPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者がメッセージをスパムとして報告しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SUPPRESSION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "これまでのマーケティングEメールに対する受信者のエンゲージ（反応）がなかったため、メッセージは送信されませんでした。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/events"
        }), "APIに対するレスポンスには、ボットイベントなどの一部のイベントタイプが含まれることもありますが、\n", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
          children: "HubSpotアカウントでのマーケティングEメールの［受信者］__タブ\n"
        }), "を分析する際に、これらのイベントタイプは表示されません。\nこのタブの分析では、特定の未加工のイベントタイプがフィルターで除外され、イベントタイプごとに表示されるイベントの合計数は\n30件に制限されます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "送信イベント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["お客様に代わってHubSpotがEメールメッセージを作成して送信する場合、HubSpotではまず、受信者がEメールメッセージの受信対象となるかどうかを確認します。対象ではない場合、HubSpotはメッセージを拒否し、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "イベントの生成をトリガーします。それ以外の場合は、さらに処理するためにEメールメッセージを配信プロバイダーに送信し、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "イベントをトリガーします。Eメールメッセージにはほぼ常に、1つの送信イベントだけが関連付けられます。例えば、1つのメッセージで複数の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "イベントが発生することは決してありません。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでは、配信プロバイダーにメッセージを渡す前に、適格でない対象者へのメッセージを拒否するよう最大限の努力を払っています。ただし、HubSpotがメッセージの適格性を確認した後であっても、配信パートナーがメッセージを拒否する場合があります。この場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "イベント生成後の拒否によって、このイベントに加え、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "イベントが作成されることになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "全ての送信イベントは以下のプロパティーを共有します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "subject"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールメッセージの件名。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "from"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールメッセージの'from'フィールド。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "replyTo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列のリスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールメッセージの'reply-to'フィールド。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "cc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列のリスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールメッセージの'cc'フィールド。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bcc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列のリスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールメッセージの'bcc'フィールド。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "イベントには、さらに次のプロパティーもあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropReason"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string（列挙）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールメッセージがドロップされた理由。取り得る値については、次の表を参照してください。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropMessage"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eメールメッセージがドロップされた理由を説明する未加工のメッセージ。これは通常、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), "よりも詳しい情報を提供します。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ドロップの理由"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "ドロップの理由"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_BOUNCED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この受信者への以前のメッセージの結果はバウンス（不達）でした。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUS_SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この受信者への以前のメッセージがスパムとして報告されています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_MESSAGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この受信者は、この配信カテゴリーの配信を停止済みです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_PORTAL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この受信者は、このアカウントからの全ての配信登録による配信を停止済みです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_TO_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "「宛先」フィールドで指定されているEメールアドレスが検証に失敗しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_FROM_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "「送信元」フィールドで指定されているEメールアドレスが検証に失敗しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_DOMAIN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のドメインがブロックされています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者がHubSpot経由で送信されたEメールを受信しないことを明示的に要求しています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "EMAIL_UNCONFIRMED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ダブルオプトインが有効にされましたが、受信者はまだ自分の配信登録を確認していません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CAMPAIGN_CANCELLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "関連するEメールキャンペーンはキャンセルされています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MTA_IGNORE"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["配信プロバイダーがメッセージをドロップすることを決定しました。追加の詳細は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropMessage'"
            }), "で提供されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_OVER_LIMIT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ご使用のアカウントでのEメール送信数が月間の上限を超えています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_SUSPENDED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンプライアンス違反または配信可能性の問題により、ご使用のアカウントは一時停止されています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "QUARANTINED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ハードバウンス（重大な不達）が繰り返し発生したため、この受信者は隔離されています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ADDRESS_LIST_BOMBED"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" 不審なフォーム操作により、この受信者は隔離されています。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/remediate-list-bombing",
              children: "詳細"
            }), "をご確認ください。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "配信イベント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["配達プロバイダーがEメールメッセージを受け入れると、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSED"
      }), "イベントが作成されます。この時点で、配信プロバイダーによってメッセージは配信対象としてキューに追加されています。全てがスムーズに進むと、配信プロバイダーはメッセージをキューから取り出して、受信者のEメールサーバーに配信し、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELIVERED"
      }), "イベントを生成します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "場合によっては、物事がスムーズに進まないこともあります。その場合、メッセージが一時的に拒否されて配信が延期されるか、配信が失敗して再試行は行われないかのどちらかになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["前者の場合、何らかの致命的ではない理由（通常は、誤ったタイムアウトなどの一時的な理由）により、受信者のEメールサーバーにメッセージを配信できなかったことを意味します。配信プロバイダーが後で配信するためにメッセージを再びキューに入れると、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DEFERRED"
      }), "イベントが生成されます。配信フェーズを完了するまで、メッセージが複数回延期される場合があります。この場合、配信が試行されるごとに新しいイベントが生成されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["配信に失敗した場合、以降のメッセージ配信試行は行われず、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), "イベントが作成されます。これは、Eメールサーバーが受信者を認識できないなど、さまざまな理由で発生する可能性があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "特定の配信イベントタイプごとのプロパティーは以下のとおりです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "配信済み"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のEメールサーバーからの完全なレスポンス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "smtpId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotによってメッセージに関連付けられたID。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "延期済み"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のEメールサーバーからの完全なレスポンス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "attempt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "32-bit integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信の試行回数。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "バウンス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "category"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string（列挙）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "発生したバウンス（不達）の種類の推定。適切なカテゴリーを判定できなかった場合、このプロパティーは省略されます。取り得る値については、次の表を参照してください。これは派生値であり、分類の精度を向上させるために随時変更される可能性があります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のEメールサーバーからの完全なレスポンス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "status"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のEメールサーバーから返されたステータスコード。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "バウンスのカテゴリー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "バウンスのカテゴリー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNKNOWN_USER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "該当する受信者が存在しませんでした。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MAILBOX_FULL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のメールボックスがいっぱいで、メッセージを一切受信できない状態でした。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CONTENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のフィルターにより、Eメール本文のコンテンツが不審なもの、またはスパムとして識別されました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メッセージがスパムとして報告されました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "POLICY"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メッセージがスパムとして報告されました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "GREYLISTING"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールサーバーが、送信者によるEメールアクティビティーのさらに長い履歴を要求しています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MAILBOX_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メールボックスの設定ミスが検出されました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ISP_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ISPの設定ミスが検出されました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DOMAIN_REPUTATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "送信元ドメインの評価が低いか、受信側サーバーの基準を満たす評価ではありません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DMARC"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "送信者のドメインがDMARCチェックに合格しません。SPFおよびDMARCポリシーをご確認ください。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENDING_DOMAIN_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者側のポリシーにより、ドメイン認証に失敗しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TIMEOUT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信側Eメールサーバーがタイムアウトになり、メールを受け入れなくなりました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "THROTTLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者のEメールサーバーがメッセージを抑制していました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNCATEGORIZED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "未分類エラーが検出されました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "IP_REPUTATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "不審な（または、これまで不明な）IPアドレスから送信されたメッセージです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DNS_FAILURE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールが送信された時点で、受信者のドメイン ネーム サーバー設定が誤って構成されていました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TEMPORARY_PROBLEM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "一時的な問題が発生しました。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ユーザー エンゲージメント イベント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメールメッセージが受信者に到達すると発生する可能性のあるイベントのタイプには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PRINT"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORWARD"
      }), "の4つがあります。これらのイベントは、受信者とメッセージおよびそのコンテンツとのやりとりを表し、いずれも複数回発生する可能性があります。例えば、任意のURLがクリックされるたびに、そのURLが以前にクリックされて", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), "イベントが生成されているとしても、同じイベントが新しく生成されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "全てのユーザー エンゲージメント イベントは以下のプロパティーを共有します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "userAgent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "イベントに対応するユーザーエージェント。例：“Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/28.0.1500.95 Safari/537.36”"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "browser"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["イベントに対応したブラウザーを表すJSONオブジェクト。これを構成するプロパティーは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'name'"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'family'"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer'"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer_url'"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'type'"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'version'"
            }), "です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "location"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["イベントが発生した場所を表すJSONオブジェクト。これを構成するプロパティーは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'city'"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'state'"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'country'"
            }), "です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "filteredEvent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客レポートの設定に基づいてイベントがフィルターで除外されたかどうかを表すブール値。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), "イベントには、さらに次のプロパティーもあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "url"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者がクリックした、メッセージ内のURL。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "referer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "クリックされたURLにリンクされていたウェブページのURL。このプロパティーが表示されるかどうか、表示されるとしたらどのような値になるかどうかは、受信者のEメールクライアントによって決まります。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), "イベントには、次のプロパティーがある場合もあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "duration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "64-bit integer（ミリ秒数）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このプロパティーが表示され、その値がゼロ以外である場合、これは、ユーザーがEメールを開いていたおおよその時間（ミリ秒数）を表します。"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ユーザー ステータス イベント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["受信者がEメールメッセージを介してコミュニケーション設定を更新することもできます。さまざまなリストに対する配信登録または配信停止のいずれにしても、受信者はメッセージ内の配信設定リンクをクリックして、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), "イベントをトリガーするための配信登録を変更できます。ステータスの変更は、現在のEメールメッセージに関連付けられているリストだけでなく、全てのリストに適用される場合があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["受信者によりEメールメッセージがスパムとして報告されて、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SPAMREPORT"
      }), "イベントが発生する場合もあります。これは配信登録ステータスとは無関係であることにご注意ください。受信者がメッセージをスパムとして報告すると、その受信者が問題のリストから単純に登録解除されるわけではありません。むしろ、配信登録ステータスは変更されないまま、登録者がHubSpotからの以降のEメールメッセージを受信しないことを示すフラグが設定されることになります。この状態になった場合、フラグを削除するには、HubSpotによる手動の介入が必要になります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), "イベントには、以下のプロパティーも追加されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "source"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string（列挙）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信登録変更の原因。取り得る値については、次の表を参照してください。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "requestedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者に代わって変更を要求している代理人のEメールアドレス。該当しない場合、このプロパティーは省略されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalSubscriptionStatus"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["string（列挙、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'SUBSCRIBED'"
            }), "、または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), "）"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["受信者のポータル サブスクリプション ステータス（ステータスが", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), "の場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'subscriptions'"
            }), "プロパティーは必ずしも空の配列になるわけではなく__、列挙される全ての配信登録のステータスが", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), "に設定されるわけでもありません）。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "subscriptions"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["受信者の配信登録ステータスを表すJSONオブジェクトの配列。各JSON配信登録オブジェクトは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'id'"
            }), "プロパティーと", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'status'"
            }), "プロパティーからなります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bounced"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["boolean（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "、または完全に省略）"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["受信者へのメッセージをブロックするために、HubSpot従業員がステータス変更を明示的に開始しました（このプロパティーの使用は廃止されており、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOCKED\\_ADDRESS"
            }), "の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), "を使用したドロップメッセージが優先されるようになっています）。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "配信登録変更の原因"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "原因"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_LIST_UNSUBSCRIBE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者がlist-unsubscribeヘッダーを使用しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_RECIPIENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者が配信登録UIを使用しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_IMPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "お客様が自社のポータルに配信登録をインポートしました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CUSTOMER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "お客様が配信登録UIを使用しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_SPAM_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "自動化されたシステムによって生成されたスパムレポートを受信しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_NON_DELIVERY_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "不達レポート（通常はバウンスレポート）を受信しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_DIRECT_COMPLAINT"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "abuse@hubspot.com"
            }), "を介して直接苦情が寄せられました。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MTA_RECORD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信プロバイダーの記録システムとの通常のHubSpotの同期中に、配信プロバイダーが変更を加えました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot従業員が変更を加えました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MIGRATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotが製品の以前のバージョンから配信登録を移行しました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CLEANUP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotが配信登録をクリーンアップしました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_KNOWN_SPAM_TRAP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この受信者のEメールアドレスは既知のスパムトラップであり、Eメールを受信すべきではありません。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'バウンス解消'イベント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定のEメールメッセージとは無関係な13番目のイベントタイプがあります。HubSpotによって自動的に、または手動で特定のEメールアドレスのバウンスが「解消」されると、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), "イベントが発生します__。これにより、受信者のバウンスステータスがリセットされ、受信者がポータルからのメールを受信できるようになる可能性があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "user"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "バウンス解消リクエストを送信したユーザーのEメールアドレス。"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベント参照"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "多くのイベントは、その前後に発生した他のイベントに関連しています。上記の最初のセクションで説明したように、HubSpotではこの参照チェーンを作成するためにEventIdを使用しています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベント参照は比較的新しいものであり、古いイベントではイベント参照が取り込まれない場合があることにご注意ください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'sentBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["前述のとおり、各Eメールメッセージには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "イベントまたは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "イベント（あるいは、これらのイベントのそれぞれ1つ）が関連付けられます。これは、所定のメッセージに対して生成される最初のイベントです。メッセージで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "イベントが生成された場合、以降の全てのイベントは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), "プロパティーを介してこのイベントを参照します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この逆方向の参照は、親", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "イベントに関する詳細情報を取得する場合や、特定のメッセージに関連付けられた全てのイベントを手動で検索する場合に役立ちます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'obsoletedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["場合によっては、所定のメッセージで前のイベントを無視すべきことを意味する後続のイベントが発生することがあります。この関係は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "プロパティーの順方向の参照に取り込まれます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "イベントが生成され、その後に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "イベントが生成された場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "イベントは最終的には無関係になり、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "イベントによって「廃止」__にされます。したがって、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "イベントは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "を介して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "イベントを参照することになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'causedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["一部のイベントが発生する原因は、まさに以前のイベントにあります。多くの場合、こうしたイベントは異なるメッセージに対して発生します。この関係は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), "プロパティーの逆方向の参照に取り込まれます。これを使用して、特定のイベントが次のイベントを引き起こした理由に関する追加の詳細を取得できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、ある受信者に対して以前に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), "イベントが発生した場合、同じ受信者に対して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "イベントが発生したとします。この場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "イベントの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'dropReason'"
      }), "は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PREVIOUSLY\\_BOUNCED"
      }), "に設定され、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), "で以前の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), "イベントが参照されます。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}