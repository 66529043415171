'use es6';

import debounce from 'react-utils/debounce';
const refreshDebounceCache = new WeakMap();
const refreshDebounceDelay = 100;

/**
 * Refreshes a CodeMirror editor instance.
 * Internally refreshes are debounced and debounced functions are
 * cached by CodeMirror instance.
 *
 * @param {CodeMirror} codemirror
 */
export function refresh(codemirror) {
  if (!codemirror) return void 0;
  let fn = refreshDebounceCache.get(codemirror);
  if (!fn) {
    fn = debounce(() => {
      let result;
      if (!codemirror) {
        // Codemirror component may have unmounted during the delay.
        return result;
      }
      try {
        result = codemirror.refresh();
      } catch (e) {
        // Just in case of an unmount or detached from DOM.
      }
      return result;
    }, refreshDebounceDelay);
    refreshDebounceCache.set(codemirror, fn);
  }
  return fn();
}