"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021628;
const slug = exports.slug = 'guides/apps/authentication/working-with-oauth';
const title = exports.title = 'OAuthの使用';
const name = exports.name = 'vNext Docs DP - OAuthの使用';
const metaDescription = exports.metaDescription = 'OAuthは、アプリ向けのセキュリティーで保護された認証手段です。アプリをユーザーアカウントに接続するには、パスワードではなく認証トークンを使用します。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "oauth-2.0%E3%81%A8%E3%81%AE%E9%80%A3%E6%90%BA%E3%82%92%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B",
  "label": "OAuth 2.0との連携を開始する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC",
  "label": "クエリーパラメーター",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B9%E3%82%B3%E3%83%BC%E3%83%97%E3%81%AE%E8%A8%AD%E5%AE%9A",
  "label": "スコープの設定",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "関連ドキュメント",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h5: "h5",
      h2: "h2",
      img: "img",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "OAuthの使用"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["OAuthはアプリをユーザーアカウントに接続するための安全な認証手段で、パスワードではなく認証トークンが使用されます。ユーザーが自分のHubSpotアカウントに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#install-an-app",
        children: "アプリをインストール"
      }), "できるようにするには、まず、OAuthの使用を開始します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "複数のHubSpotアカウントによるインストール用に設計されたアプリ、またはアプリマーケットプレイスに掲載されるアプリでは、OAuthを使用する必要があります。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpotアカウントでアプリをインストールするユーザーには、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
            children: "スーパー管理者"
          }), "の権限、または", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: "アプリマーケットプレイスのアクセス"
          }), "権限が付与されている必要があります。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "参考資料"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "OAuthクイックスタートガイド"
        }), "で紹介されているサンプルアプリは、OAuth 2.0の利用をできるだけ短時間で開始することを意図して設計されており、全ての手順を実際にご利用いただけます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/academy/tracks/71/593/2967",
          children: "HubSpotアカデミーのコース"
        }), "（英語）では、HubSpotでOAuthを使い始める方法を短時間で習得できます。HubSpot-OAuthフローの詳細や、アクセストークンの更新方法を説明しています。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth 2.0との連携を開始する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "OAuth 2.0との連携を開始するために必要な手順は以下です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["まず、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers/",
          children: "HubSpot開発者アカウント"
        }), "で", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "アプリを作成"
        }), "します。アプリを作成すると、アプリ設定の［認証］ページで作成したアプリのクライアントIDとクライアントシークレットを確認できるようになります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/MyHubSpotApp.png",
        alt: "MyHubSpotApp"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["下記のように、クライアントIDとクライアントシークレット、および", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-parameters",
            children: "クエリーパラメーター"
          }), "と", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#scopes",
            children: "スコープ"
          }), "を使用して、認証URLを作成します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["アプリをインストールするユーザーを認証URLに誘導し、ユーザーがアカウントを選択する画面を表示して、連携機能へのアクセスを許可できるようにします。以下に示すように、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/oauth/"
          }), "と", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/authorize"
          }), "の間にアカウントIDを追加することで、特定のHubSpotアカウント用の認証URLを設定できます。アクセス権が付与されると、コード クエリー パラメーターが付加された", (0, _jsxRuntime.jsx)(_components.code, {
            children: "redirect_url"
          }), "によってユーザーがアプリにリダイレクトされます。貴社はこのコードとクライアントシークレットを使用して、HubSpotから", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/oauth/tokens",
            children: "access_tokenとrefresh_token"
          }), "を取得することになります。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "認証URLの例"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "任意のアカウント："
                }), (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "特定のアカウント（ID 123456）："
                }), (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/123456/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "リダイレクトURLの例："
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://example.com/?code=xxxx"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "エラーの例："
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/?error=error_code&error_description=Human%20readable%20description%20of%20the%20error"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), "を使用して、当該HubSpotアカウントに対して行われたAPI呼び出しを認証します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), "の有効期限が切れたら、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "refresh_token"
          }), "を使用して新しい", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), "を生成します。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "リフレッシュトークンと初期アクセストークンを生成しない限り、ユーザーのアカウントに貴社のアプリが［接続されたアプリ］として表示されることはありません。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["アクセストークンにはアプリから要求されたスコープが反映されますが、ユーザーがHubSpotアカウントで実行する操作に関する権限や制限は", (0, _jsxRuntime.jsx)("u", {
            children: "反映されません"
          }), "。例えば、ユーザーに自分が担当するコンタクトのみを閲覧する権限が付与されている場合、このユーザーが", (0, _jsxRuntime.jsx)(_components.code, {
            children: "crm.objects.contacts.read"
          }), "スコープを対象としたリクエストを許可したとしても、生成されるアクセストークンでは、このユーザーが担当するコンタクトだけでなく、アカウント内の全てのコンタクトを閲覧できます。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "クエリーパラメーター"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリの認証URLを作成する際には、以下のパラメーターが必須です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "パラメーター"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "説明"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "使い方"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "クライアントID"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id=x"
            }), "URLで使用されます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アプリの認証設定ページから取得します（上記の説明を参照）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "リダイレクトURL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri=x"
            }), "訪問者がアプリへのアクセス権を取得した後にリダイレクトされる宛先URL。"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アプリの認証設定ページでも、このURLを指定します。", (0, _jsxRuntime.jsxs)(_components.strong, {
              children: ["注：", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "セキュリティー上の理由により、本番環境では"
              }), "必ず"]
            }), "このこのURLにHTTPSを使用する必要があります。（localhostを使用したテスト環境では、HTTPを使用することも可能です）。また、IPアドレスはサポートされていないため、", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "必ず"
            }), "ドメインを使用してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "スコープ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "貴社のアプリに対する権限セット（権限ごとにスペースで区切ります）。アプリの認証設定でチェックマークを付けたスコープは必須と見なされるため、それらのスコープをこのパラメーターに含めないと、認証ページにエラーが表示されます。また、ここに含まれるスコープへのアクセス権がないアカウントにアプリをインストールしようとした場合にも、エラーが発生します。特定のスコープでアクセスできるAPIエンドポイントの詳細については、下記の表をご覧ください。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のパラメーターは任意で指定できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "パラメーター"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "使い方"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "説明"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "任意指定のスコープ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "&optional_scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "貴社のアプリに対する任意の権限セット（権限ごとにスペースで区切ります）。ユーザーがそのツールへのアクセス権を持っていないHubSpotアカウントを選択した場合（CRM専用ポータルでソーシャルスコープをリクエストする場合など）、任意指定のスコープは自動的に認証リクエストから除かれます。任意指定のスコープを使用する場合、許可されたスコープを知るためにはアクセストークンまたはリフレッシュトークンを確認する必要があります。スコープの詳細については下記の表を参照してください。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "状態"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "&state=y"
            }), "認証URLにこのパラメーターを含めた場合、ユーザーが", (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_url"
            }), "に誘導される際に、ここで指定した値が状態クエリーパラメーターに格納されます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リダイレクトされてアプリに戻ってきたユーザーの状態を維持するために使用できる文字列値。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "スコープの設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "OAuthでは、アプリに対するスコープ（権限）を設定する必要があります。各スコープによって、一連のHubSpot APIエンドポイントへのアクセス権が与えられます。また、アプリによるHubSpotアカウント上の特定のツールへのアクセスを、ユーザーが許可できるようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/granular-scopes-selection.png",
        alt: "granular-scopes-selection"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定のAPIまたはAPIエンドポイントへのアクセスは、HubSpotアカウントのティアによって異なります。下記の表に、全ての利用可能なスコープとアクセス可能なAPIエンドポイントを一覧します。複数の異なるタイプのHubSpotアカウントで機能するアプリの場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), "パラメーターを使用して、対応するあらゆるティアのスコープを含めることができます。これにより、CRMのみのアカウントを使用している顧客も、全てのスコープにはアクセスできなくても、アプリを認証できるようになります。認証されていない全てのスコープについては、アプリ側で確認および処理する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "スコープ"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "説明"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "アクセスが許可される対象"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "必要なアカウントティア"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インテグレーターは、顧客のアカウントにCMSドメインをリストできます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インテグレーターは、CMSカスタムドメインを作成、更新、および削除できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インテグレーターは、全てのCMSサーバーレス関数、関連するシークレット、および関数の実行結果を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インテグレーターは、CMSサーバーレス関数とシークレットを書き込むことができます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ナレッジ記事の詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ナレッジ記事を更新するためのアクセス権を付与します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.publish"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ナレッジ記事を更新および公開するためのアクセス権を付与します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ドメインやルートURLなどの一般的なナレッジベース設定とテンプレートのナレッジベース設定を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "一般的なナレッジベース設定とテンプレートのナレッジベース設定を更新するためのアクセス権を付与します。これには、ナレッジ記事への書き込みアクセス権が含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.performance.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インテグレーターは、全てのサイトのCMSパフォーマンスデータを閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトリストに関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リストエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトリストを作成、削除、変更できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リストエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会社のプロパティーやその他の詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会社エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会社のプロパティーの閲覧、会社レコードの作成、削除、変更を行うことができます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会社エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトのプロパティーやその他の詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトのプロパティーの閲覧、コンタクトの作成、削除、変更を行うことができます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotのCRM内のカスタムオブジェクトに関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "カスタム オブジェクト エンドポイント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["任意の ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotのCRM内でカスタムオブジェクトを作成、削除、変更できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "カスタム オブジェクト エンドポイント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["任意の ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取引のプロパティーやその他の詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取引エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取引のプロパティーの閲覧、取引レコードの作成、削除、変更を行うことができます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取引エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.feedback_submission.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィードバックアンケートに対する送信に関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィードバック アンケート エンドポイント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.goals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全ての目標タイプを閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "目標エンドポイント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Sales Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), "、", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), "、または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目のプロパティーやその他の詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目を作成、削除、変更できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マーケティングイベントに関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マーケティング イベント エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マーケティングイベントを作成、削除、変更できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マーケティング イベント エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.owners.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRMレコードに割り当てられたユーザーに関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "所有者エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりおよび見積もりテンプレートのプロパティーやその他の詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりの作成、削除、変更を行うことができます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会社のプロパティー設定に関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティーエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会社のプロパティー設定を作成、削除、変更できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティーエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトのプロパティー設定に関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティーエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトのプロパティー設定を作成、削除、変更できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティーエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotのCRM内のカスタムオブジェクト定義に関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "カスタム オブジェクト エンドポイント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["任意の ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取引のプロパティー設定に関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティーエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取引のプロパティー設定を作成、削除、変更できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティーエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目に関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりおよび見積もりテンプレートに関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.billing.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントの請求設定を変更できます。これには、ユーザーの有料シートの管理および割り当てが含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "設定エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "既存の為替レートと併せて、ポータルに関連付けられている現在の会社の通貨を読み取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウント情報エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ポータルに関連付けられている会社の通貨を更新できるとともに、為替レートを作成、更新、削除できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウント情報エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントユーザーおよびその権限に関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザー プロビジョニング エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotアカウントのユーザーとユーザー権限を管理できます。これには、新しいユーザーの作成、権限とロールの割り当て、既存のユーザーの削除が含まれます"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザー プロビジョニング エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.teams.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントのチームに関する詳細を確認できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザー プロビジョニング エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.team.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotアカウントのチームにユーザーを割り当てることができます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザー プロビジョニング エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account-info.security.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウント アクティビティー ログおよびその他のアカウントセキュリティー情報へのアクセス権が含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントアクティビティーAPI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accounting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotと会計・経理の連携で請求書、製品、コンタクトの詳細を共有できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会計拡張API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "actions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトのページにカスタムアクションを実行するフォームを追加できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM拡張API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "analytics.behavioral_events.send"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "カスタム行動イベントの送信アクセス権が含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アナリティクスAPI"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "これにはワークフローが含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "自動化API（ワークフローエンドポイント）"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "behavioral_events.event_definitions.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "行動イベントの作成、読み取り、更新、または削除を行うことができます。これには、行動イベントプロパティーが含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アナリティクスAPI"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business_units.view.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ロゴ情報を含め、ビジネスユニットのデータを閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ビジネスユニットAPI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ビジネスユニット追加オプション"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business-intelligence"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ソースおよびEメールを制御するAPIエンドポイントが含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アナリティクスAPI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_query.execute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotアカウントからGraphQL APIエンドポイントを使用してデータのクエリーを実行できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphQL APIエンドポイント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_schema.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphiQLなどのGraphQLアプリケーションクライアントを介してイントロスペクションクエリーを実行できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphiQLおよびその他のサードパーティーGraphQLクライアント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトの配信設定の詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信設定API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信カテゴリーに対するコンタクトの配信登録／配信停止を行うことができます。配信停止したコンタクトは配信登録されません。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信設定API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信カテゴリーに対するコンタクトの配信登録／配信停止を行うことができます。配信停止したコンタクトは配信登録されません。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信設定API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サイト、ランディングページ、Eメール、ブログ、キャンペーンが含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS APIおよびカレンダー、Eメール、Eメールイベントのエンドポイント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), "、", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コミュニケーション受信トレイ内のスレッドに関する詳細を閲覧できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コミュニケーション受信トレイおよびメッセージAPI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.visitor_identification.tokens.create"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotチャットウィジェットを操作している認証済みウェブサイト訪問者の識別トークンを取得できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "訪問者の識別API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: "任意のProfessional"
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "またはEnterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コミュニケーションでメッセージを送信できます。メッセージスレッドを作成および更新できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コミュニケーション受信トレイおよびメッセージAPI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.export"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全てのCRMデータタイプのCRMからレコードをエクスポートできます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRMエクスポートAPI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.import"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRMへのレコードのインポートを許可します。これには、全てのタイプのCRMデータ（コンタクト、会社、取引、チケットなど）の新しいレコードの作成、または既存のレコードの変更が含まれます。データのアーカイブや削除は含まれません。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRMインポートAPI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ctas.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CTAの読み取りアクセスを許可します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "使用可能なpublicAPIはなし"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " あるいは ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), "、", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), "、または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eコマース機能へのアクセス権が含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "製品と商品項目のエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "external_integrations.forms.access"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "既存のフォームの名前変更、削除、複製を行うことができます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォームエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ファイルマネージャーへのアクセス権が含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ファイル（ファイルマネージャー）およびファイルマッパー（CMSテンプレート、モジュール、レイアウト）のエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files.ui_hidden.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全てのHubSpotツールに含まれるユーザーファイル、添付ファイル、システムファイルの詳細を閲覧したり、ダウンロードしたりできます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ファイル（ファイルマネージャー）およびファイルマッパー（CMSテンプレート、モジュール、レイアウト）のエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォームエンドポイントへのアクセス権が含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォームエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms-uploaded-files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォームを介して送信されたファイルをダウンロードできます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォーム送信エンドポイント経由でアップロードされたファイルの取得"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDBへのアクセス権が含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDBエンドポイント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), "、あるいは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/website",
              children: "ウェブサイト追加オプション"
            }), "が有効な ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "integration-sync"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "これにより、同期APIが公開され、ほとんどのCRMオブジェクトの同期が可能になります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ecommerce Bridge API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアブリッジからイベントとオブジェクトへのアクセス権を付与します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアブリッジAPI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアブリッジからイベントとオブジェクトを作成および更新するためのアクセス権を付与します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアブリッジAPI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oauth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "OAuthに必要な基本的なスコープ。このスコープは既定で全てのアプリに追加されます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sales-email-read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトに送信された1対1のEメールの全ての詳細の読み取りアクセス権を付与します。"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["エンゲージメントエンドポイント**注：**Eメールエンゲージメントの内容を取得するには、このスコープが必要です。詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/engagement-details#email-content",
              children: "エンゲージメントの概要"
            }), "をご覧ください。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ソーシャル受信トレイが含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ソーシャルメディアAPI"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "チケットへのアクセス権が含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "チケットエンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotのCRMレコードでのカスタムイベントの管理アクセス権を付与します。これには、レコードの作成または更新が含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "タイムライン イベント エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意のアカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transactional-email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "トランザクションEメールとトランザクションEメールエンドポイントが含まれます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "トランザクションEメールエンドポイント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/email/transactional-email",
              children: "トランザクションEメール追加オプション"
            }), "が有効にされたMarketing Hub**** ProfessionalまたはEnterprise"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連ドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "HubSpotでの認証方式"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "OAuthクイックスタートガイド"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/oauth/tokens",
        children: "トークンの管理"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}