"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694130;
const slug = exports.slug = 'guides/cms/content/themes/getting-started';
const title = exports.title = 'テーマを使ってみる';
const name = exports.name = '[新規]テーマを使ってみる';
const metaDescription = exports.metaDescription = 'HubSpotのCMSでテーマを使用します。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.%E3%83%9C%E3%82%A4%E3%83%A9%E3%83%BC%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88-%E3%83%86%E3%83%BC%E3%83%9E-%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%82%92%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B",
  "label": "1.ボイラープレート テーマ プロジェクトを開始する",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-cms%E3%83%9C%E3%82%A4%E3%83%A9%E3%83%BC%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92hubspot%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%AB%E3%82%A2%E3%83%83%E3%83%97%E3%83%AD%E3%83%BC%E3%83%89%E3%81%99%E3%82%8B",
  "label": "2. CMSボイラープレートをHubSpotアカウントにアップロードする",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "3. ページを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-%E3%83%86%E3%83%BC%E3%83%9E%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E7%B7%A8%E9%9B%86%E3%81%99%E3%82%8B",
  "label": "4. テーマフィールドを編集する",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E3%81%A7%E3%81%AE%E5%A4%89%E6%9B%B4%E3%81%AB%E5%90%91%E3%81%91%E3%81%A6%E6%BA%96%E5%82%99%E3%81%99%E3%82%8B",
  "label": "5. ローカルでの変更に向けて準備する",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-%E3%83%86%E3%83%BC%E3%83%9E%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "6. テーマフィールドを追加する",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-css%E3%81%A7%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E5%8F%82%E7%85%A7%E3%81%99%E3%82%8B",
  "label": "7. CSSでフィールドを参照する",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-%E5%A4%89%E6%9B%B4%E3%82%92%E3%83%86%E3%82%B9%E3%83%88%E3%81%99%E3%82%8B",
  "label": "8. 変更をテストする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%AC%A1%E3%81%AE%E3%82%B9%E3%83%86%E3%83%83%E3%83%97",
  "label": "次のステップ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      img: "img",
      a: "a",
      h2: "h2",
      code: "code",
      em: "em",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "テーマを使ってみる"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマとは、開発者向けのアセットから成る、移植可能で自己完結型のパッケージであり、これらのアセットを連動させて柔軟なコンテンツ編集操作を可能にするように設計されています。テーマには、テンプレート、モジュール、CSSファイル、JavaScriptファイル、画像などを組み込むことができます。開発者はテーマを使用することで、モジュールフィールドと似た一連のテーマフィールドを作成できます。制作担当者はこのテーマフィールドを使用して、CSSを編集せずにウェブサイト全体のスタイルを制御できるようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubLは、テーマのCSS全体のテーマフィールドの値にアクセスする際に活用できます。次に制作担当者はテーマエディターを使用して、テーマフィールドの値を変更し、テーマに含まれる既存のテンプレートに対する変更をプレビューしてから、その変更を公開できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/cms-themes-animation.gif",
        alt: "テーマの要素の色を選択する方法を説明する、テーマ設定の編集UIのアニメーション"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このドキュメントでは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "HubSpotのCMSボイラープレート"
      }), "に基づいて最初のテーマを作成する方法を説明します。テーマの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "テーマのリファレンスドキュメント"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "CMS Hubを使って初めて開発する場合には、以下のチュートリアルをお勧めします。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "CMS Hub開発のクイックスタート"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**開始する前に、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI"
        }), "をインストールする必要があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1.ボイラープレート テーマ プロジェクトを開始する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create website-theme my-website-theme"
      }), "を実行します。これにより、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-website-theme"
      }), "ディレクトリーが作成されて、そこに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "CMSテーマボイラープレート"
      }), "のファイルが格納されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. CMSボイラープレートをHubSpotアカウントにアップロードする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-website-theme my-website-theme"
      }), "を実行します。これにより、ボイラープレートがアカウントのデザインマネージャーにアップロードされ、「", (0, _jsxRuntime.jsx)(_components.em, {
        children: "my-website-theme"
      }), "」というタイトルのフォルダーにアップロードされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. ページを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アップロードしたテーマからページを作成するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントで、［マーケティング］＞［ウェブサイト］＞［ウェブサイトページ］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上にある［作成］をクリックし、［ウェブサイトページ］を選択します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ダイアログボックスで、ページを公開する", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ドメイン"
        }), "を選択し、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ページ名"
        }), "を入力します。［ページを作成］をクリックします。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/create-page-from-dashboard.gif",
        alt: "create-page-from-dashboard"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テンプレート選択画面のページ上部に、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/use-themes#use-an-active-theme",
          children: "有効なテーマ"
        }), "に属するテンプレートが表示されます。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "有効なテーマをまだ選択していない場合は、［CMSテーマボイラープレート］にカーソルを合わせ、［有効なテーマに設定］をクリックします。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["既に有効なテーマを設定している場合は、", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "テーマセレクター"
            }), "のドロップダウンメニューをクリックし、新しいテーマを選択してから［テーマを変更］を選択します。次に、［CMSテーマボイラープレート］にカーソルを合わせて、［有効なテーマに設定］をクリックします。次の画面で、テンプレートを選択します。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/theme-selector.gif",
        alt: "theme-selector"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これにより、テーマのフィールドを編集できるページエディターが表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. テーマフィールドを編集する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページエディターの左のサイドバーで、［テーマ］タブをクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［テーマ］タブで、［テーマ設定を編集］をクリックします。ここで、既存のテーマ設定を変更できます。テーマ設定への変更を公開すると、この更新したテーマが使用されている全てのページのスタイルが更新されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-theme-settings.gif",
        alt: "edit-theme-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. ローカルでの変更に向けて準備する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ターミナルに戻り、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch my-website-theme my-website-theme"
      }), "を実行します。この", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "コマンド"
      }), "は、ローカルディレクトリーを監視し、以後、ファイルが保存されると変更内容をHubSpotアカウントに自動的にアップロードします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. テーマフィールドを追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ローカルでの変更が監視されるようになりました。新しいテーマフィールドを追加します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["エディターで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), "ファイルを開きます。これは、テーマエディターのサイドバーで利用可能なフィールドを制御するためのファイルです。フッターの高さを指定するための新しい", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview#fields-json",
          children: "フィールド"
        }), "を追加します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ファイルの末尾近くにある", (0, _jsxRuntime.jsx)(_components.code, {
          children: "footer"
        }), "グループを見つけます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "次のコードをコピーし、ファイル内のfooterグループの子配列に含まれる最初の項目の上にJSONを貼り付けます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n      {\n      \"id\" : \"\",\n      \"name\" : \"height\",\n      \"label\" : \"Footer height\",\n      \"required\" : false,\n      \"locked\" : false,\n      \"display\" : \"text\",\n      \"step\" : 1,\n      \"type\" : \"number\",\n      \"min\" : 10,\n      \"max\" : 900,\n      \"help_text\":\"This footer will expand in height to accomodate any content added to the footer. You are setting the minimum height in px\",\n      \"default\" : 100\n      },\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), "を保存し、HubSpotでテーマのプレビューアーを再読み込みします。左のサイドバーに新しいフィールドが表示されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. CSSでフィールドを参照する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["コードエディターで、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-overrides.css"
        }), "ファイルを開きます。次に、", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".footer"
        }), "のCSSセレクターを見つけます。続いて、このセレクターに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "min-height"
        }), "を追加します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマ内の値にアクセスするには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme"
        }), "オブジェクトを使用します。例えば", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ theme.footer.height }}"
        }), "を使用して、高さフィールドに設定された高さの値にアクセスします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["theme-overrides.css内の", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".footer"
        }), "宣言を次のコードで置き換えます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".footer {\n  background-color: {{ footer_bg_color }};\n  min-height: {{theme.footer.height}}px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-overrides.css"
        }), "を保存し、HubSpotアカウントにアップロードします。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. 変更をテストする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマエディターに戻り、新しいフィールドをフッターに表示するためページを再読み込みします。高さの値を変更すると、プレビューに直ちに反映されます。フッターに背景色を設定すると、変化が分かりやすくなります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "次のステップ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマの作成、更新が完了しました。ここからは、テーマフィールドをさらに作成してプロジェクト用にカスタマイズできます。他のカスタマイズオプションについては、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "テーマの概要"
      }), "を参照してください。テーマを作成する際には、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "HubSpot CMS上のウェブサイトを最適化する"
      }), "方法を確認すると参考になります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot CMS Hubには", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/default-themes",
        children: "既定のテーマ"
      }), "が複数用意されています。これらのテーマを表示、複製、更新するなどして、テーマの具体的な使用方法について学習できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマについて把握したら、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "最初のカスタムモジュールを作成する方法"
      }), "を確認してください。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}