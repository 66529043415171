"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 145855338905;
const slug = exports.slug = 'guides/api/marketing/emails/marketing-emails';
const title = exports.title = 'Marketing API | Marketing Email (BETA)';
const name = exports.name = 'vNext Docs DP - Marketing Email';
const metaDescription = exports.metaDescription = 'Learn how to create, update, and retrieve data for your emails using the Marketing Emails v3 API.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-marketing-email",
  "label": "Create a marketing email",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-a-marketing-email",
  "label": "Retrieve a marketing email",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      em: "em",
      strong: "strong",
      ul: "ul",
      li: "li",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Marketing Email"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        }), " Professional"]
      }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " account, you can use the Marketing Emails API to programmatically create, update, and get details about marketing emails. You can also query details about the post-send statistics of a specific email or set of emails. These statistics should match what you can access in the app on the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Details"
      }), " section of a particular email and will be returned under the stats object in your JSON response."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The Marketing Email API cannot be used to create or retrieve data for sales emails that are created and sent via the contact record. To get details for sales emails, use the Engagements API."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["To programmatically send transactional emails to contacts, use the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/marketing/emails/transactional-emails#single-send-api",
            children: "Single-send API"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["To use the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/publish"
          }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/unpublish"
          }), " endpoints, you must have a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), " account or the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.hubspot.com/products/marketing/transactional-email",
            children: "transactional email add-on"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a marketing email"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create an email, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/emails"
      }), " and include the following fields in the body of your request:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for POST request to /marketing/v3/emails\n{\n  \"name\": \"A new marketing email\",\n  \"subject\": \"Behold the latest version of our newsletter!\",\n  \"templatePath\": \"@hubspot/email/dnd/welcome.html\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you purchased the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account-settings/manage-brands-with-business-unit",
          children: "business units add-on"
        }), ", you must include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "businessUnitId"
        }), " field in the request body. You can get a list of business units in your account using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/business-units-api",
          children: "business units API"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve a marketing email"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can retrieve existing emails in your account individually or in batches:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve an individual email, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/marketing/v3/emails/{emailId}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a list of all emails, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/marketing/v3/emails"
        }), ", and include any filters as query parameters in your request (e.g., add ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAfter"
        }), " and a date in ISO8601 format to get all emails created after a specific date)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/marketing/emails/marketing-emails",
        children: "reference documentation"
      }), " for a full list of endpoints and the associated parameters available."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}