"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50633310866;
const slug = exports.slug = 'guides/cms/content/themes/default-themes';
const title = exports.title = '既定のテーマ｜HubSpot（ハブスポット）';
const name = exports.name = 'APAC JAPAN (ja) | [new] Default Themes_JA | 202108';
const metaDescription = exports.metaDescription = 'HubSpotでは、そのまま使用したり変更を加えたり、または自社のウェブサイトテーマ制作時の参考にしたりすることも可能な数多くのテーマを提供しています。テーマはCLIツールを使用してローカル環境にダウンロードすることも可能です。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AB%E5%AF%BE%E3%81%99%E3%82%8B%E5%A4%89%E6%9B%B4",
  "label": "既定のテーマに対する変更",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E3%83%AA%E3%82%B9%E3%83%88",
  "label": "既定のテーマのリスト",
  "parent": null
}, {
  "depth": 1,
  "id": "growth%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "Growthテーマ",
  "parent": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E3%83%AA%E3%82%B9%E3%83%88"
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%B3%E3%83%86%E3%83%8A%E3%83%B3%E3%82%B9%E3%83%A2%E3%83%BC%E3%83%89%E3%81%AE%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "メンテナンスモードの既定のテーマ",
  "parent": null
}, {
  "depth": 1,
  "id": "barricade%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "Barricadeテーマ",
  "parent": "%E3%83%A1%E3%83%B3%E3%83%86%E3%83%8A%E3%83%B3%E3%82%B9%E3%83%A2%E3%83%BC%E3%83%89%E3%81%AE%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 1,
  "id": "education%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "Educationテーマ",
  "parent": "%E3%83%A1%E3%83%B3%E3%83%86%E3%83%8A%E3%83%B3%E3%82%B9%E3%83%A2%E3%83%BC%E3%83%89%E3%81%AE%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 1,
  "id": "martech%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "Martechテーマ",
  "parent": "%E3%83%A1%E3%83%B3%E3%83%86%E3%83%8A%E3%83%B3%E3%82%B9%E3%83%A2%E3%83%BC%E3%83%89%E3%81%AE%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 1,
  "id": "rally%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "Rallyテーマ",
  "parent": "%E3%83%A1%E3%83%B3%E3%83%86%E3%83%8A%E3%83%B3%E3%82%B9%E3%83%A2%E3%83%BC%E3%83%89%E3%81%AE%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 1,
  "id": "session%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "Sessionテーマ",
  "parent": "%E3%83%A1%E3%83%B3%E3%83%86%E3%83%8A%E3%83%B3%E3%82%B9%E3%83%A2%E3%83%BC%E3%83%89%E3%81%AE%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 1,
  "id": "sprout%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "Sproutテーマ",
  "parent": "%E3%83%A1%E3%83%B3%E3%83%86%E3%83%8A%E3%83%B3%E3%82%B9%E3%83%A2%E3%83%BC%E3%83%89%E3%81%AE%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 1,
  "id": "vitality%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "Vitalityテーマ",
  "parent": "%E3%83%A1%E3%83%B3%E3%83%86%E3%83%8A%E3%83%B3%E3%82%B9%E3%83%A2%E3%83%BC%E3%83%89%E3%81%AE%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      h3: "h3",
      img: "img",
      h4: "h4",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "既定のテーマ"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotには、制作担当者が基本テーマを修正することなくウェブサイトページの作成に使用できる、既定の一連のテーマが用意されています。こうしたテーマは、開発の知識や要員が限られている制作担当者に最適です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["独自のテーマを作成する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "HubSpot CMSボイラープレート"
      }), "から始めることをお勧めします。ただし既定のテーマは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "HubSpot CLI"
      }), "経由でダウンロードし、修正することができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下では、現在利用可能な既定のテーマについて説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注：**"
        }), "CMS Hub Starter**アカウントでは、Growthテーマのみ利用可能です。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "既定のテーマに対する変更"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotの既定のテーマは、デベロッパー ファイル システム内の@hubspotというフォルダーにあります。このフォルダーは読み取り専用で、HubSpotだけが変更できます。テーマに変更を加える場合は、必要なテーマを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "fetch"
      }), "してから、テーマに変更を加えて、@hubspot以外のフォルダーに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "アップロード"
      }), "する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "既定のテーマのリスト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下に記載されているCLIコマンドを使用して、HubSpotの既定のテーマを自由にダウンロードしてください。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), "での", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "fetchコマンド"
      }), "については、詳細をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Growthテーマ"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/5Cdocs/default-themes/growth-homepage.jpg",
            alt: "Growthテーマのホームページ"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/growth/home",
              children: "Growthテーマのデモページにアクセスする"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Any"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "12 Sections"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "16 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/growth <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メンテナンスモードの既定のテーマ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のテーマはメンテナンスモードに移行されました。今後、新しい機能を追加する更新は行われず、セキュリティー、アクセシビリティー、バグ修正に関連する更新のみが行われます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Barricadeテーマ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "建設企業向けのテーマ。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-barricade-theme-thumb-4.jpg",
            alt: "Barricadeテーマのスクリーンショット"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/barricade/home",
              children: "Barricadeテーマのデモページにアクセスする"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Construction"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "10 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/barricade <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Educationテーマ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "学校、大学などの教育機関向けに作成されたテーマ。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-education-theme-thumb-4.jpg",
            alt: "Educationテーマのスクリーンショット"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/education/home",
              children: "Educationテーマのデモページにアクセスする"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Education"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "13 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/education <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Martechテーマ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "マーケティングテクノロジー企業向けのウェブサイトテーマ。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-martech-theme-thumb-4.jpg",
            alt: "Martechテーマのスクリーンショット"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/martech/home",
              children: "Martechテーマのデモページにアクセスする"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Marketing Agency"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "9 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/martech <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Rallyテーマ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "企業や代理店向けに作成されたテーマ。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-rally-theme-thumbnail-1-4.png",
            alt: "Rallyテーマのスクリーンショット"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/rally/home",
              children: "Rallyテーマのデモページにアクセスする"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Marketing Agency"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "14 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/cms-rally <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sessionテーマ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "企業向けに作成されたテーマ。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-session-theme-thumb-4.jpg",
            alt: "Sessionテーマのスクリーンショット"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/session/home",
              children: "Sessionテーマのデモページにアクセスする"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "9 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/session <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sproutテーマ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "企業や代理店向けに作成されたテーマ。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-sprout-theme-thumb-4.jpg",
            alt: "Sproutテーマのスクリーンショット"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/sprout/home",
              children: "Sproutテーマのデモページにアクセスする"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "15 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "21 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/sprout <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vitalityテーマ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "企業や代理店向けに作成されたテーマ。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-vitality-theme-thumb-4.jpg",
            alt: "Vitalityテーマのスクリーンショット"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/vitality/home",
              children: "Vitalityテーマのデモページにアクセスする"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Healthcare, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "8 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/vitality <destination-folder>\n"
          })
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}