"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 43188373176;
const slug = exports.slug = 'guides/api/app-management/webhooks/overview';
const title = exports.title = 'Webhook API ';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | Webhooks API | 202304';
const metaDescription = exports.metaDescription = 'Webhook APIの概要をご紹介しています。連携機能がインストールされている場合、このAPIを使用してHubSpotアカウント上で発生するイベントの通知を登録できます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%B9%E3%82%B3%E3%83%BC%E3%83%97",
  "label": "スコープ",
  "parent": null
}, {
  "depth": 0,
  "id": "webhook%E3%81%AE%E8%A8%AD%E5%AE%9A",
  "label": "Webhookの設定",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%96%8B%E7%99%BA%E8%80%85%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%A7%E3%81%AE%E8%A8%AD%E5%AE%9A%E3%81%AE%E7%AE%A1%E7%90%86",
  "label": "開発者アカウントでの設定の管理",
  "parent": "webhook%E3%81%AE%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "api%E3%81%AB%E3%82%88%E3%82%8B%E8%A8%AD%E5%AE%9A%E3%81%AE%E7%AE%A1%E7%90%86",
  "label": "APIによる設定の管理",
  "parent": "webhook%E3%81%AE%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 0,
  "id": "webhook%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "Webhookサブスクリプション",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%96%8B%E7%99%BA%E8%80%85%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%A7%E3%81%AE%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "開発者アカウントでのサブスクリプションの作成",
  "parent": "webhook%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3"
}, {
  "depth": 1,
  "id": "api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "APIを使用したサブスクリプションの作成",
  "parent": "webhook%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3"
}, {
  "depth": 1,
  "id": "%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E5%8F%96%E5%BE%97",
  "label": "サブスクリプションの取得",
  "parent": "webhook%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3"
}, {
  "depth": 1,
  "id": "%E6%96%B0%E8%A6%8F%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "新規サブスクリプションの作成",
  "parent": "webhook%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3"
}, {
  "depth": 1,
  "id": "%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E6%9B%B4%E6%96%B0",
  "label": "サブスクリプションの更新",
  "parent": "webhook%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3"
}, {
  "depth": 1,
  "id": "%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E5%89%8A%E9%99%A4",
  "label": "サブスクリプションの削除",
  "parent": "webhook%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3"
}, {
  "depth": 0,
  "id": "webhook%E3%83%9A%E3%82%A4%E3%83%AD%E3%83%BC%E3%83%89",
  "label": "Webhookペイロード",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC%E8%A6%8F%E5%89%87%E3%81%AB%E6%BA%96%E6%8B%A0%E3%81%97%E3%81%9F%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%81%AE%E5%89%8A%E9%99%A4",
  "label": "プライバシー規則に準拠したコンタクトの削除",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "セキュリティー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%86%8D%E8%A9%A6%E8%A1%8C",
  "label": "再試行",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%88%B6%E9%99%90",
  "label": "制限",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Webhook"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "連携機能がインストールされている場合、Webhook APIを使用して、HubSpotアカウント上で発生したイベントを受信できます。接続されたアカウントでイベントが発生したときにAPI呼び出しを実行する代わりに、設定したエンドポイントにHubSpotからHTTPリクエストを送信します。受信を登録するイベントは、アプリの設定、または以下で詳述するエンドポイントを使用して設定できます。場合によっては、定期的に変更をポーリングするよりも、Webhookを使用する方法のほうが拡張性に優れています（特にインストールベースが大規模なアプリの場合）。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Webhook APIの使用にあたり、次の要件を満たす必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["通知を受ける対象のイベントに登録し、通知の送信先URLを指定することにより、Webhookを使用するHubSpotアプリを設定してあること。アプリの作成方法の詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/integration-platform-api-requirements",
          children: "前提条件に関するドキュメント"
        }), "（英語）をご覧ください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "このドキュメントに規定されたWebhookペイロードを処理できる、公開URLのセキュア（HTTPS）エンドポイントが展開されていること。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhookは、アカウント単位ではなく", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "HubSpotアプリ"
      }), "（英語）に対して設定されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0",
        children: "OAuthフロー"
      }), "（英語）に従ってアプリをインストールする全てのアカウントが、そのフローのWebhookサブスクリプションに配信登録されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CRMオブジェクト（コンタクト、会社、取引、チケット、製品、商品項目など）のイベントとコミュニケーションイベントに対して配信登録できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
            children: "非公開アプリでWebhookを管理する"
          }), "こともできます。非公開アプリでは、非公開アプリのアプリ内設定でのみWebhookを編集できます。現在のところ、APIを介して編集することはできません。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["コミュニケーションWebhookに登録するには、現在", (0, _jsxRuntime.jsx)("u", {
            children: "ベータ版"
          }), "となっている", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/conversations/inbox-and-messages",
            children: "コミュニケーション受信トレイおよびメッセージAPI"
          }), "にアクセスする必要があります"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "スコープ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhookを使用してCRMイベントの配信登録を行うには、登録する対象のCRMオブジェクトタイプに対応する、イベントに関連付けられたスコープを求めるようにアプリを設定する必要があります。例えば、コンタクトイベントの配信登録を行う場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm.objects.contacts.read"
      }), "スコープをリクエストする必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "公開アプリの設定UIでサブスクリプションを作成する場合、サブスクリプションの作成を完了する前に、［新しいWebhook配信登録を作成］パネルで必要なスコープを追加するように求められます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/webhooks/v3/{appId}/subscriptions"
        }), "エンドポイントに送信してサブスクリプションを作成する場合、レスポンスに含まれるエラーに、公開アプリの設定UIで設定する必要があるスコープの名前が示されます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリですでにWebhookを使用している場合、アクティブなWebhookサブスクリプションで必要とされているスコープを削除するには、その前に、該当するWebhookサブスクリプションを一時停止して削除する必要があります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Webhookサブスクリプションタイプごとに必要となるスコープは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#webhook-subscriptions",
          children: "下にある表"
        }), "で確認できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0#scopes",
        children: "スコープの詳細"
      }), "（英語）およびアプリの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0",
        children: "認証認証URLの設定"
      }), "（英語）については、OAuthのドキュメントをご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhookの設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Webhookサブスクリプションを設定する前に、これらの通知の送信先URLを指定する必要があります。アプリのサブスクリプションを完全に設定する方法については、以下のセクションの手順に従ってください。"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Webhook設定は最大5分までキャッシュできます。WebhookのURL、同時接続数上限、またはサブスクリプションの設定を変更すると、変更が適用されるまでに最大5分かかる場合があります。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "HubSpotは、アプリをインストールしたアカウントに関連付けられたサブスクリプション イベント データを送信するときに、10件のリクエストの同時実行制限を設定します。この同時実行制限は、HubSpotが一度に試行する実行中リクエストの最大数です。各リクエストには最大100件のイベントを含めることができます。"
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "開発者アカウントでの設定の管理"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者アカウントのアプリの設定ページでは、URLとスロットリング制限を管理できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "開発者アカウントで、［アプリ］ダッシュボードに移動します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Webhookを設定する対象のアプリの", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "名前"
          }), "をクリックします。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/app_id_list.png?width=600&name=app_id_list.png",
            alt: "app_id_list"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "左のサイドバーメニューで［Webhook］に移動します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["［ターゲットURL］フィールドに、イベントがトリガーされたときにHubSpotでPOSTリクエストを送信する宛先の", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), "を入力します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［イベントの抑制］設定を使用して、HubSpotで送信を試みるイベントの最大数を調整します。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_settings.png?width=600&name=webhook_settings.png",
        alt: "webhook_settings"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［保存］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "APIによる設定の管理"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下のエンドポイントと", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/how-do-i-get-my-hubspot-api-key",
        children: "開発者APIキー"
      }), "を使用することで、アプリのWebhook設定をプログラムによって構成できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリに現在構成されているWebhookの設定を表示するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエストには", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/faq/how-do-i-find-the-app-id",
        children: "アプリID"
      }), "（英語）を含める必要があります。アプリIDは、［アプリ］ダッシュボード内のアプリの名前の下、またはアプリの設定の［認証］タブで確認できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "settingsオブジェクトは、以下のフィールドからなります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "webhookUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotがWebhook通知を送信する宛先のURL。このURLはHTTPS経由で提供する必要があります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Webhook URLでの同時処理の制限。このフィールドには5より大きい値を設定する必要があります。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["上記の設定を編集するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), "に送信します。このリクエスト本文には、以下のフィールドを含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "targetUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotがイベントペイロードを配信するために呼び出す、一般公開されたURL。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "throttling"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このオブジェクトでWebhookスロットリングの詳細を構成します。throttlingオブジェクト内には", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), "フィールドと", (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            }), "フィールドがあります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["この設定で使用する時間の単位。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SECONDLY"
            }), "（1秒あたり）または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ROLLING_MINUTE"
            }), "（1分あたり）のいずれかの値を指定できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), "で指定された一定の期間にHubSpotがアプリへの送信を試行するHTTPリクエストの最大数。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下に、リクエストの例を示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to https://api.hubapi.com/webhooks/v3/{appId}/settings\n\n{\n  \"throttling\": {\n    \"period\": \"SECONDLY\",\n    \"maxConcurrentRequests\": 10\n  },\n  \"targetUrl\": \"https://www.example.com/hubspot/target\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhookサブスクリプション"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Webhook URLとイベントスロットリングを設定したら、1つ以上のサブスクリプションを作成する必要があります。WebhookサブスクリプションでHubSpotに対し、特定のアプリで受信するイベントを指示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サブスクリプションは、貴社の連携機能をインストールした全ての顧客に適用されます。つまり、必要なサブスクリプションを指定するのは一度だけでよいことになります。アプリケーションでサブスクリプションを有効にすると、そのアプリケーションをインストールした全ての顧客のWebhookの受信が自動的に開始されます。また、連携機能によって新しい顧客からのWebhookトリガーの受信も開始されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全ての", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationChange"
      }), " Webhookサブスクリプションに共通して、Webhookは関連付けの両方の対象に対する2つのイベントをトリガーします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["2つのコンタクトが関連付けられると、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), "のサブスクリプションによって、それぞれ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 1 to contact 2"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 2 to contact 1"
        }), "を表す2つのイベントがトリガーされます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company.associationChange"
        }), "の2つのWebhookサブスクリプションを使用している場合、会社が関連付けられると、2つのイベントを受信します。これらのイベントは、それぞれ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 1 to company 1"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company 1 to contact 1"
        }), "を表します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サポートされているサブスクリプションタイプは次のとおりです。API経由でサブスクリプションを作成する際に、これらのタイプを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), "フィールドの値として使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "サブスクリプションタイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "必要なスコープ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウントでコンタクトが作成された場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウントからコンタクトが削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトが別のコンタクトとマージされた場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトと他のサポート対象のWebhookオブジェクト（コンタクト、会社、取引、チケット、商品項目、または製品）との間の関連付けが追加または削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "削除されたコンタクトが復元された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview",
              children: "プライバシー規則への順守"
            }), "を理由としてコンタクトが削除された場合に通知を受け取ります。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントの全てのコンタクトについて、指定したプロパティーが変更された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウントで会社レコードが作成された場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウントから会社レコードが削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウントの全ての会社レコードについて、指定したプロパティーが変更された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会社オブジェクトと他のサポート対象のWebhookオブジェクト（コンタクト、会社、取引、チケット、商品項目、または製品）との間の関連付けが追加または削除された場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "削除された会社レコードが復元された場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会社レコードが別の会社レコードとマージされた場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウントで取引レコードが作成された場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウントから取引レコードが削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取引と他のサポート対象のWebhookオブジェクト（コンタクト、会社、取引、チケット、商品項目、または製品）との間の関連付けが追加または削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "削除された取引レコードが復元された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取引レコードが別の取引レコードとマージされた場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウント上の全ての取引について、指定したプロパティーが変更された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウントでチケットが作成された場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウントからチケットが削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウント上のチケットのいずれかで、指定したプロパティーが変更された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "チケットと他のサポート対象のWebhookオブジェクト（コンタクト、会社、取引、チケット、商品項目、または製品）との間の関連付けが追加または削除された場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "削除されたチケットが復元された場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "チケットが別のチケットとマージされた場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウント上で製品レコードが作成された場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウント上で製品レコードが削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "削除された製品レコードが復元された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "製品が別の製品とマージされた場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウント上の製品のいずれかで、指定したプロパティーが変更された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウントで商品項目が作成された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウント上で商品項目が削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目と他のサポート対象のWebhookオブジェクト（コンタクト、会社、取引、チケット、商品項目、または製品）との間の関連付けが追加または削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "削除された商品項目が復元された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目が別の商品項目とマージされた場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客のアカウント上の商品項目のいずれかで、指定したプロパティーが変更された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["現在", (0, _jsxRuntime.jsx)("u", {
        children: "ベータ版"
      }), "となっている", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "コミュニケーション受信トレイおよびメッセージAPI"
      }), "を使用している場合は、以下のコミュニケーション サブスクリプション タイプの配信登録を行うことができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "サブスクリプションタイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "スコープ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントで新しいスレッドが作成された場合に通知を受け取ります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントでスレッドがアーカイブされた場合、またはソフト削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントからスレッドが完全に削除された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "スレッドのプロパティーが変更された場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.newMessage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "スレッドで新しいメッセージを受信した場合に通知を受け取ります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロパティー変更サブスクリプションの場合、通知の対象とするプロパティーを指定する必要があります。複数のプロパティー変更サブスクリプションを指定できます。サブスクリプションで指定されているプロパティーが顧客のアカウントに存在しない場合、顧客からそのプロパティーに関するWebhookを受け取ることはありません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "一部のプロパティーは、CRMプロパティー変更サブスクリプションでは使用できません。該当するプロパティーは次の通りです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "num_unique_conversion_events"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_lastmodifieddate"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["現在", (0, _jsxRuntime.jsx)("u", {
        children: "ベータ版"
      }), "となっている", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "コミュニケーション受信トレイおよびメッセージAPI"
      }), "を使用している場合、以下のプロパティーを使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "assignedTo"
        }), "**：**スレッドが再割り当てまたは割り当て解除されたコミュニケーション。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), "は、スレッドが再割り当てされた場合はWebhookのペイロードに含まれるアクターIDとなり、スレッドが割り当て解除された場合は空白となります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "status"
        }), "**：**スレッドによって変更されたコミュニケーションのステータス。Webhookペイロード内の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), "は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OPEN"
        }), "または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CLOSED"
        }), "のいずれかになります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "isArchived"
        }), "**：**スレッドが復元されたコミュニケーション。Webhookペイロード内の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), "は、常に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "FALSE"
        }), "となります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "開発者アカウントでのサブスクリプションの作成"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot開発者アカウント内でWebhookサブスクリプションを作成できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotの開発者アカウントで、［アプリ］ダッシュボードに移動します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "名前"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左のサイドバーメニューで［Webhook］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［サブスクリプションを作成］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["右側のパネルで［どのオブジェクトタイプを選びますか？］のドロップダウンメニューをクリックし、サブスクリプションを作成する対象の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "オブジェクト"
        }), "を選択します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［どのイベントについて知りたいですか？］のドロップダウンメニューをクリックし、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "イベントタイプ"
        }), "を選択します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-contact-create-subscription.png",
        alt: "create-contact-create-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["プロパティー変更イベントのサブスクリプションを作成する場合は、［どのプロパティーを選びますか？］のドロップダウンメニューをクリックし、対象とする", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "プロパティー"
          }), "を選択します。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_select_properties.png?width=450&name=webhook_select_properties.png",
            alt: ""
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［配信登録］をクリックします。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Webhook設定にサブスクリプションが表示されます。新しいサブスクリプションは一時停止状態で作成されるため、Webhookが送信されるようにするためにはサブスクリプションを有効にする必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［イベントのサブスクリプション］セクションで、オブジェクトタイプにカーソルを合わせ、［サブスクリプションを表示］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["イベントの横にある", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "チェックボックス"
        }), "をオンにしてから、テーブルのヘッダーで［アクティベート］をクリックします。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/activate-subscription.png",
        alt: "activate-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "APIを使用したサブスクリプションの作成"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のエンドポイントを使用すれば、プログラムに従ってサブスクリプションを作成できます。これらのエンドポイントにリクエストを送信する際は、開発者APIキーを使用する必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "subscriptionオブジェクトには、以下のフィールドを格納できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サブスクリプションごとのIDを表す数値。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サブスクリプションが作成された時間（ミリ秒単位）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サブスクリプションを作成したユーザーに関連付けられているユーザーID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["サブスクリプションが有効になっており、アクティブに通知をトリガーしているかどうかを示します。値には", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "を指定できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["サブスクリプションのタイプ。このセクションの冒頭の", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview#webhook-subscriptions",
              children: "表"
            }), "に、利用可能なサブスクリプションタイプが記載されています。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サブスクリプションで変更をリッスンする対象のプロパティーの名前。これはプロパティー変更サブスクリプションタイプにのみ必要です。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "サブスクリプションの取得"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サブスクリプションのリストを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レスポンスとして、サブスクリプションを表すオブジェクトの配列が返されます。各オブジェクトには、サブスクリプションに関する情報（ID、作成日、タイプ、現在有効になっているかどうかなど）が格納されています。レスポンスの例を次に示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example GET request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n[\n  {\n    \"id\": 25,\n    \"createdAt\": 1461704185000,\n    \"createdBy\": 529872,\n    \"eventType\": \"contact.propertyChange\",\n    \"propertyName\": \"lifecyclestage\",\n    \"active\": false\n  },\n  {\n    \"id\": 59,\n    \"createdAt\": 1462388498000,\n    \"createdBy\": 529872,\n    \"eventType\": \"company.creation\",\n    \"active\": false\n  },\n  {\n    \"id\": 108,\n    \"createdAt\": 1463423132000,\n    \"createdBy\": 529872,\n    \"eventType\": \"deal.creation\",\n    \"active\": true\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "新規サブスクリプションの作成"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しいサブスクリプションを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエスト本文には、以下のフィールドを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サブスクリプションのタイプ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サブスクリプションで変更をリッスンする対象のプロパティーの名前。これはプロパティー変更サブスクリプションタイプにのみ必要です。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["サブスクリプションが有効になっており、アクティブに通知をトリガーしているかどうかを示します。値には", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "を指定できます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdAt"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdByの"
      }), "各フィールドは自動的に設定されるため、リクエスト本文に含める必要はありません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のリクエスト本文の例をご参照ください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n{\n  \"eventType\": \"company.propertyChange\",\n  \"propertyName\": \"companyname\",\n  \"active\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), "には、前のセクションで定義されている有効なサブスクリプションタイプを指定する必要があります。また、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyName"
      }), "は有効なプロパティー名でなければなりません。顧客がこの値に一致するプロパティーを定義していない場合、このサブスクリプションによる通知は行われません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "サブスクリプションの更新"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サブスクリプションを有効化または一時停止するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエスト本文には、以下のフィールドを含めます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["サブスクリプションが有効になっており、アクティブに通知をトリガーしているかどうかを示します。値には", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "を指定できます。"]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "サブスクリプションの削除"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サブスクリプションを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhookペイロード"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリのWebhook設定で指定したターゲットURLにあるエンドポイントでは、HubSpotから送信される、JSON形式のデータを含む", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを受信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhookエンドポイントで取得しているリクエストが実際にHubSpotから送信されたものであることを保証するために、HubSpotは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), "ヘッダーを、貴社のアプリのクライアントシークレットとリクエスト詳細の組み合わせに基づくSHA-256ハッシュにします。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "リクエスト署名の検証方法"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の表を使用して、ペイロードに含まれる可能性のあるフィールドの詳細を確認してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["作成／変更／削除されたオブジェクトのID。コンタクトの場合はコンタクトID、会社の場合は会社ID、取引の場合は取引ID、コミュニケーションの場合は", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "スレッドID"
            }), "になります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティー変更サブスクリプションの場合にのみ送信される、変更されたプロパティーの名前。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyValue"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティー変更サブスクリプションの場合にのみ送信される、通知をトリガーしたプロパティーに新しく設定された値。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "changeSource"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "変更のソース。コンタクトプロパティー履歴に表示される変更ソースのいずれかを指定できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この通知をトリガーしたイベントのID。この値が一意であることは保証されません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "イベントに関する通知をトリガーしたサブスクリプションのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["イベントが発生した顧客の", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/manage-multiple-hubspot-accounts#check-your-current-account",
              children: "HubSpotアカウントID"
            }), "。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アプリケーションのID。複数のアプリが同じWebhook URLを示している場合に使用されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このイベントが発生した時点のミリ秒単位のタイムスタンプ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この通知の対象となっているイベントタイプ。「Webhookサブスクリプション」のセクションに記載されている、サポートされているサブスクリプションタイプのリストを確認してください。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attemptNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "0で始まる、貴社のサービスに通知するイベントの試行番号。サービスがタイムアウトした場合や、以下の「再試行」の項目に記載されているエラーが発生した場合、HubSpotは通知の再送信を試みます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Webhookでスレッドへの新しいメッセージをリッスンしている場合にのみ送信されます。新しいメッセージのIDです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Webhookでスレッドへの新しいメッセージをリッスンしている場合にのみ送信されます。送信するメッセージのタイプです。この値には、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "MESSAGE"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMMENT"
            }), "のいずれかを指定できます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マージ先のID。このIDに該当するレコードが、マージ後に残されます。これは、HubSpotのマージUIでは右側に表示されるレコードに該当します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mergedObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マージ先にマージされるレコードを表すIDの配列。これは、HubSpotのマージUIでは左側に表示されるレコードに該当します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "newObjectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["マージの結果として作成されたレコードのID。場合によっては、マージの結果として新しいレコードが作成されることがあるため、これは", (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            }), "とは別のものです。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numberOfPropertiesMoved"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マージ中に転送されたプロパティーの数を表す整数。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["関連付けは、次のいずれかのタイプになります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_CONTACT"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_COMPANY"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_LINE_ITEM"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_DEAL"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_TICKET"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINE_ITEM_TO_DEAL"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "関連付けの変更が行われたレコードのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "関連付けイベントのセカンダリーレコードのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationRemoved"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["以下を表すブール値：", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "：関連付けの削除によってWebhookがトリガーされました。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "：関連付けの作成によってWebhookがトリガーされました。"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isPrimaryAssociation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["以下を表すブール値：", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "：セカンダリーレコードは、関連付けの変更を行ったレコードの", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/crm-setup/associate-records#primary-company-information",
                  children: "プライマリーの関連付け"
                }), "です。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "：レコードは、関連付けの変更を行ったレコードのプライマリーの関連付けでは", (0, _jsxRuntime.jsx)("u", {
                  children: "ありません"
                }), "。 "]
              })]
            }), "**注意：**2つのオブジェクトレコード間にプライマリーの関連付けインスタンスを作成すると、対応する非プライマリーの関連付けも作成されます。これにより、2つのWebhookメッセージが生成される可能性があります。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n[\n  {\n    \"objectId\": 1246965,\n    \"propertyName\": \"lifecyclestage\",\n    \"propertyValue\": \"subscriber\",\n    \"changeSource\": \"ACADEMY\",\n    \"eventId\": 3816279340,\n    \"subscriptionId\": 25,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.propertyChange\",\n    \"attemptNumber\": 0\n  },\n  {\n    \"objectId\": 1246978,\n    \"changeSource\": \"IMPORT\",\n    \"eventId\": 3816279480,\n    \"subscriptionId\": 22,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.creation\",\n    \"attemptNumber\": 0\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上に示されているように、単一のリクエストによって複数のオブジェクトからなる配列が返されるはずです。一括処理のサイズはさまざまですが、通知は100件未満になります。短時間に多数のイベントが発生した場合に、HubSpotは複数の通知を送信します。例えば、新しいコンタクトの受信を登録している状態で、顧客が多数のコンタクトをインポートした場合、HubSpotはリクエストに対して1つではなく、一括でインポートされたコンタクトに対して複数の通知を送信します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotは、ユーザーがこれらの通知をイベントの発生順に受信することを保証できません。イベントが通知の発生をトリガーしたタイミングを判定するには、各通知の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "occurredAt"
      }), "プロパティーを使用してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "また、HubSpotはイベントあたり1件の通知のみを受信することを保証できません。非常にまれですが、HubSpotが同じ通知を複数回送信する場合があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プライバシー規則に準拠したコンタクトの削除"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotユーザーは、プライバシーに関する法律に準拠するためにコンタクトレコードを完全に削除することができます。詳細は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-do-i-perform-a-gdpr-delete-in-hubspot",
        children: "GDPRに準拠した削除に関するナレッジベース記事"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "contact.privacyDeletion"
      }), "配信カテゴリー(サブスクリプションタイプ)の配信登録を行うことにより、ユーザーがプライバシーに準拠するためにコンタクトを削除した時点で、Webhook通知を受信できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プライバシー削除通知には特別な動作があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プライバシー削除イベントによってコンタクト削除イベントもトリガーされるため、両方のイベントの受信を登録している場合は2つの通知を受け取ります。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "これらの通知は、必ずしも特定の順序で送信されたり、同じ一括配信で送信されたりするわけではありません。個別のメッセージとの照合にはオブジェクトIDを使用する必要があります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "セキュリティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhookのエンドポイントで取得しているリクエストが実際にHubSpotから送信されたことを保証するために、HubSpotは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), "ヘッダーに、アプリのクライアントシークレットと、HubSpotが送信するリクエスト本文を連結して作成したSHA-256ハッシュを取り込みます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この署名を検証するには、アプリケーションのアプリシークレットと、処理中のリクエストに含まれる未解析のリクエスト本文を連結して生成されたSHA-256ハッシュを取得します。生成されたハッシュと", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), "の値を比較します。これらの値が一致する場合、リクエストはHubSpotから送信されたことが検証されます。一致しない場合、アプリシークレットを知っている他のユーザーからリクエストが送信されたことになります。従って、この値の機密保持には十分ご注意ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらの値が一致しない場合、転送中のリクエストの改ざん、またはエンドポイントに対してWebhook通知を用いたなりすましが発生した恐れがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "署名の検証"
      }), "について詳細を確認してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "再試行"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "貴社のサービスで通知処理に問題が発生した場合、常に、HubSpotから失敗した通知の再送信が最大10回試行されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotが再試行する場合は次のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**接続失敗：**指定されたWebhook URLへのHTTP接続をHubSpotが開始できない場合。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**タイムアウト：**貴社のサービスから一括の通知に対するレスポンスの返送に5秒を超える時間がかかった場合"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**エラーコード：**貴社のサービスがHTTPステータスコード（4xxまたは5xx）でレスポンスした場合"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "通知の送信は最大で10回再試行されます。再試行は、24時間にわたりリクエストの送信間隔を変えながら実行されます。失敗が同時に多発した場合の再試行時刻が完全に同じにならないように、個々の通知にある程度のばらつきが確保されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "制限"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotがWebhookサブスクリプションを介してお客様のサービスに送信する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストは、お客様の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "アプリのAPIレート制限"
      }), "には", (0, _jsxRuntime.jsx)("u", {
        children: "計上されません"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "1つのアプリあたり最大1,000件のサブスクリプションを作成できます。この制限を超えて作成しようとすると、以下の本文を含む400 bad requestが返されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"status\": \"error\",\n  \"message\": \"Couldn't create another subscription. You've reached the maximum number allowed per application (1000).\",\n  \"correlationId\": \"2c9beb86-387b-4ff6-96f7-dbb486c00a95\",\n  \"requestId\": \"919c4c84f66769e53b2c5713d192fca7\"\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}