"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400668;
const slug = exports.slug = 'reference/cms/hubl/tags/dnd-areas';
const title = exports.title = 'Balises HubL de la zone de glisser-déposer';
const name = exports.name = 'EMEA FR HubL Supported Tags | dnd_areas';
const metaDescription = exports.metaDescription = 'Les balises HubL sont utilisées pour construire des zones de glisser-déposer qui permettent aux développeurs de créer des sections de pages qui prennent en charge les modifications de mise en page, de style et de contenu directement dans les éditeurs de contenu.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "dnd_area",
  "label": "dnd_area",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_section",
  "label": "dnd_section",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_column",
  "label": "dnd_column",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_row",
  "label": "dnd_row",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_module",
  "label": "dnd_module",
  "parent": null
}, {
  "depth": 0,
  "id": "arri%C3%A8re-plan",
  "label": "Arrière-plan",
  "parent": null
}, {
  "depth": 1,
  "id": "background_color",
  "label": "background_color",
  "parent": "arri%C3%A8re-plan"
}, {
  "depth": 1,
  "id": "background_linear_gradient",
  "label": "background_linear_gradient",
  "parent": "arri%C3%A8re-plan"
}, {
  "depth": 1,
  "id": "background_image",
  "label": "background_image",
  "parent": "arri%C3%A8re-plan"
}, {
  "depth": 0,
  "id": "comment-les-param%C3%A8tres-de-style-dnd-se-traduisent-sur-la-page",
  "label": "Comment les paramètres de style dnd se traduisent sur la page",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      ul: "ul",
      li: "li",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Balises HubL de zone de glisser-déposer"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "zones de glisser-déposer"
      }), " permettent aux développeurs de créer des sections de pages et des partials globaux qui prennent en charge les modifications de mise en page, de style et de contenu directement dans les éditeurs de contenu. Voir le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "tutoriel sur la création d'une zone de glisser-déposer"
      }), " pour une introduction à la création de zones de glisser-déposer"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les zones de glisser-déposer sont basées sur une grille réactive à 12 colonnes. Les balises par glisser-déposer rendent le balisage avec des noms de classe désignant les colonnes et les lignes. Vous devrez ajouter une feuille de style pour cibler ces noms de classe. Un exemple de styles de mise en page que vous pouvez mettre en œuvre se trouve dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "Boilerplate de HubSpot CMS"
      }), ". Votre feuille de style peut être ajoutée au modèle en utilisant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172854037902"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Veuillez noter que"
        }), " les zones de glisser-déposer ne peuvent pas être utilisées dans des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "articles de blog"
        }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#email",
          children: "modèles d'e-mails"
        }), " pour le moment."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_area"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "zone de glisser-déposer"
      }), " est un conteneur qui rend une partie de la page web modifiable en termes de structure, de conception et de contenu. Le corps d'une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{%dnd_area%}"
      }), " fournit le contenu par défaut de la zone de glisser-déposer."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modules eux-mêmes ne peuvent pas contenir de zones de glisser-déposer. Pour fournir aux créateurs de contenu une interface permettant d'ajouter du contenu uniforme dans un module, utilisez plutôt des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#repeaters",
        children: "champs et des groupes reproductibles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " peut contenir les paramètres suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "class"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une classe ajoutée avec la balise div d'une dnd_area"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Utilisé dans l'éditeur pour libeller la zone dans la barre latérale."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrée",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_area \"unique_name\", class=\"main\" %}\n\n{% end_dnd_area %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"container-fluid main\">\n  <div class=\"row-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n    </div>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les balises", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " peuvent également contenir des balises ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Un créateur de contenu peut échanger le modèle d'une page contre un autre modèle du même type, selon qu'il possède ou non des balises ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles construits avec l'éditeur visuel de mise en page par glisser-déposer peuvent être remplacés par d'autres modèles par glisser-déposer ou par des modèles codés avec ou sans les balises", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles codés avec des balises ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " ne peuvent être échangés que contre d'autres modèles codés avec des balises ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles codés ", (0, _jsxRuntime.jsx)("u", {
            children: "sans"
          }), " balises ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " ne peuvent être échangés que contre d'autres modèles codés sans balises ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_section"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{%dnd_section%}"
        })
      }), " est une ligne de niveau supérieur et doit être imbriqué dans une balise", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{%dnd_area%}"
      }), " . ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-templates",
        children: "Les sections peuvent également être définies comme un modèle"
      }), ", puis ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-context",
        children: "incluses"
      }), " dans un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", ce qui les rend idéales pour élaborer rapidement un modèle."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " peut contenir les paramètres suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier une ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "couleur d'arrière-plan"
            }), ". Peut également être fourni en tant que chaîne."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier une ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "image d'arrière-plan"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "arrière-plan à dégradé linéaire"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un booléen qui détermine si la section est destinée à être pleine page ou limitée par un conteneur interne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier les valeurs de marge en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Si aucune unité de mesure n'est fournie, la valeur par défaut est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une valeur en pixels qui définit la largeur maximale d'un contenu sur une balise dict."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier les valeurs de remplissage en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), ", et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Si aucune unité de mesure n'est fournie, la valeur par défaut est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alignement vertical du contenu enfant. Les options disponibles incluent :", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Vous ne pouvez utiliser qu'un seul paramètre d'arrière-plan par balise ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrée",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_section\n  background_image={\n    \"backgroundPosition\": \"MIDDLE_CENTER\",\n    \"backgroundSize\": \"cover\",\n    \"imageUrl\": \"https://example.com/path/to/image.jpg\"\n  },\n  margin={\n    \"top\": 32,\n    \"bottom\": 32\n  },\n  padding={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\",\n    \"left\": \"1em\",\n    \"right\": \"1em\"\n  },\n  max_width=1200,\n  vertical_alignment=\"MIDDLE\"\n%}\n\n{% end_dnd_section %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 unique_name-row-0-background-image dnd-section unique_name-row-0-max-width-section-centering unique_name-row-0-margin unique_name-row-0-padding\"\n>\n  <div class=\"row-fluid \"></div>\n  <!--end row-->\n</div>\n<!--end row-wrapper -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les balises", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " peuvent également contenir les balises suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_column"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#column",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{%dnd_column%}"
        })
      }), " est un bloc de construction structurel vertical qui occupe une ou plusieurs colonnes de mise en page définies par sa ligne parent."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette balise HubL doit être imbriquée dans une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area%}"
      }), " ."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " peut contenir les paramètres suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier une ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "couleur d'arrière-plan"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier une ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "image d'arrière-plan"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "arrière-plan à dégradé linéaire"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier les valeurs de marge en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Si aucune unité de mesure n'est fournie, la valeur par défaut est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une valeur en pixels qui définit la largeur maximale d'un contenu sur une balise dict."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier les valeurs de remplissage en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), ", et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Si aucune unité de mesure n'est fournie, la valeur par défaut est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alignement vertical du contenu enfant. Les options disponibles incluent :", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Veuillez noter que"
        }), " vous ne pouvez utiliser qu'un seul paramètre d'arrière-plan par balise ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrée",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_column\n  offset=0,\n  width=12,\n  background_color={\n    r: 255,\n    g: 0,\n    b: 0,\n    a: 1\n  },\n  margin={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\"\n  },\n%}\n\n{% end_dnd_column %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span12 widget-span widget-type-cell unique_name-column-1-margin unique_name-column-1-background-color unique_name-column-1-vertical-alignment dnd-column\"\n  style=\"\"\n  data-widget-type=\"cell\"\n  data-x=\"0\"\n  data-w=\"12\"\n></div>\n<!--end widget-span -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " peut également contenir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_row"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#row",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{%dnd_row%}"
        })
      }), " est un bloc de construction structurel horizontal qui crée une grille de mise en page imbriquée à 12 colonnes dans laquelle des colonnes et des modules peuvent être placés."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette balise HubL doit être imbriquée dans une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area%}"
      }), " ."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), " peut inclure les paramètres suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier une ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "couleur d'arrière-plan"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier une ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "image d'arrière-plan"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "arrière-plan à dégradé linéaire"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier les valeurs de marge en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Si aucune unité de mesure n'est fournie, la valeur par défaut est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une valeur en pixels qui définit la largeur maximale d'un contenu sur une balise dict."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dictionnaire qui permet de spécifier les valeurs de remplissage en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), ", et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Si aucune unité de mesure n'est fournie, la valeur par défaut est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alignement vertical du contenu enfant. Les options disponibles incluent :", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Veuillez noter que"
        }), " vous ne pouvez utiliser qu'un seul paramètre d'arrière-plan par balise ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrée",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_row\n  background_color={\n    r: 123,\n    g: 123,\n    b: 123,\n    a: 1.0\n  },\n  margin={\n    \"top\": 20,\n    \"bottom\": 200\n  },\n  padding={\n    \"top\": 20,\n    \"bottom\": 200,\n    \"left\": 20,\n    \"right\": 20\n  }\n%}\n\n{% end_dnd_row %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 main-row-0-padding main-row-0-background-color main-row-0-margin\"\n>\n  <div class=\"row-fluid \"></div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un dnd_row peut également contenir les balises suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#module",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{%dnd_module%}"
        })
      }), " est un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "module"
      }), " enveloppé dans un div où la mise en page, les styles et le contenu peuvent être ajoutés. Le module est spécifié en faisant référence à son chemin d'accès, qui peut être soit un module par défaut de HubSpot (en utilisant l'espace de noms ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/"
      }), " ), soit des modules que vous avez construits, en spécifiant leur chemin d'accès dans l'arborescence des fichiers du gestionnaire de conception."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette balise HubL doit être imbriquée dans une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area%}"
      }), " ."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " peut contenir les paramètres suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chemin d'accès à un module."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Positionnement horizontal, prend en charge :", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LEFT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CENTER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RIGHT"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Décalage par rapport à 0 dans la grille à 12 colonnes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre de colonnes occupant la grille de 12 colonnes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "flexbox_positioning"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Obsolète"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Obsolète, ne pas utiliser."
            }), " Utilisez plutôt le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            }), " en tandem avec le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            }), " de la ligne ou de la section.", (0, _jsxRuntime.jsx)("br", {}), "Valeur de la position Flexbox pour le module. Chaîne compatible indiquant la position verticale suivie de la position horizontale :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Vous avez un ancien module dont le nom de champ correspond à l'un des paramètres ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " ci-dessus ? Vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates#passing-fields-that-have-dnd-associated-parameters",
          children: "transmettre des valeurs par défaut par le biais d'un paramètre de champ"
        }), ", tout comme vous le feriez pour un groupe de champs."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrée",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n  path=\"@hubspot/rich_text\",\n  offset=0,\n  width=8,\n%}\n  {% module_attribute \"html\" %}\n    <h1>Hello, world!</h1>\n  {% end_module_attribute %}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span8 widget-span widget-type-custom_widget\"\n  style=\"\"\n  data-widget-type=\"custom_widget\"\n  data-x=\"0\"\n  data-w=\"12\"\n>\n  <div\n    id=\"hs_cos_wrapper_main-module-1\"\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module\"\n    style=\"\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"module\"\n  >\n    <span\n      id=\"hs_cos_wrapper_module-1_\"\n      class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n      style=\"\"\n      data-hs-cos-general-type=\"widget\"\n      data-hs-cos-type=\"rich_text\"\n    >\n      <h1>Hello, world!</h1>\n    </span>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Arrière-plan"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il existe plusieurs façons de définir des arrière-plans pour les éléments de colonne, de section et de ligne du tableau de bord, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-image",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_image"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-linear-gradient",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_linear_gradient"
        })
      }), ", et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-color",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_color"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_color"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les balises dnd de colonne, de section et de ligne prennent en charge les couleurs d'arrière-plan. Vous pouvez définir la couleur d'arrière-plan par défaut pour un élément de glisser-déposer en utilisant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_color"
      }), ". Ce paramètre est un paramètre basé sur une chaîne de caractères et peut inclure les formats suivants décrits dans l'exemple ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section %}\n  // Hex Value (both 3 and 6 char length)\n  {% dnd_column background_color=\"#F7F7F7\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"#FFF\" %}\n  {% end_dnd_column %}\n// Both RGB and RGBA\n  {% dnd_column background_color=\"rgb(255,255,255)\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"rgba(0,0,0,.25)\" %}\n  {% end_dnd_column %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_linear_gradient"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les éléments dnd de colonne, de section et de ligne prennent en charge les dégradés linéaires d'arrière-plan. Vous pouvez définir un dégradé par défaut à l'aide du paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_linear_gradient"
      }), ". Le paramètre attend un dictionnaire. Actuellement, il ne prend en charge que les arrêts de deux couleurs."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "direction"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La direction du dégradé.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to bottom"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to top"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to left"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to right"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tableau de chaînes de couleurs. Actuellement, il prend en charge 2 valeurs, le début et la fin. Les valeurs sont fournies sous forme de chaînes de caractères, et les formats suivants sont pris en charge :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGB"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGBA"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3 char hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6 char hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "8 char hex"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_linear_gradient={\n    \"direction\": \"to bottom\",\n    \"colors\": [\n      \"#1EB6C3\",\n      \"#2A2859\"\n    ]\n  }\n  %}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_image"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les éléments dnd de colonne, de section et de ligne prennent en charge les images d'arrière-plan. Vous pouvez fournir une image d'arrière-plan par défaut en utilisant le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_image"
      }), " qui attend un dictionnaire."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Clé"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundPosition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La position de l'image en arrière-plan. Prend en charge une chaîne indiquant la position verticale suivie de la position horizontale.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundSize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Propriété de taille d'arrière-plan de la CSS utilisée pour l'image.", (0, _jsxRuntime.jsx)("br", {}), "Les valeurs prises en charge sont les suivantes :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "cover"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "contain"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "auto"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "imageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL absolue de l'image."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_image = {\n      \"backgroundPosition\": \"MIDDLE_CENTER\",\n      \"backgroundSize\": \"cover\",\n      \"imageUrl\": \"https://www.example.com/bg-image.jpg\"\n    },\n%}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comment les paramètres de style dnd se traduisent sur la page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous utilisez des paramètres basés sur le style, tels que les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background",
        children: "arrière-plans"
      }), ", les marges ou le remplissage, les noms de classe sont automatiquement calculés pour vos sections, colonnes, lignes et modules. Les valeurs de propriété que vous avez assignées sont alors ajoutées à ces noms de classe créés automatiquement et le code CSS qui en résulte est ensuite placé devant la balise de fermeture ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), " de la page dans une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/responsive-breakpoints",
        children: "Les styles de glisser-déposer peuvent également être différents à différents points de rupture"
      }), " pour offrir un aspect réactif."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}