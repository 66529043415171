'use es6';

export const toSnakeCase = str => {
  return str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('_');
};
export const toCamelCase = str => {
  return str.replace(/([-_\s][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '').replace(/\s+/g, '');
  });
};
export const toLowerCaseFirstLetter = str => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};