"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313530;
const slug = exports.slug = 'reference/cms/hubl/quote-variables';
const title = exports.title = 'Variáveis de modelo de orçamento';
const name = exports.name = '[PT-BR] Quote template variables';
const metaDescription = exports.metaDescription = 'Use o HubL para acessar e exibir dados de CRM dentro do seu modelo de orçamento personalizado. O objeto template_data contém a maioria dos dados associados ao orçamento. Para algo que não for encontrado lá, você pode associar o registro do objeto ao orçamento ou usar as funções HubL CRM_object.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "objeto-template_data",
  "label": "Objeto Template_data",
  "parent": null
}, {
  "depth": 0,
  "id": "vari%C3%A1veis-de-or%C3%A7amento",
  "label": "Variáveis de orçamento",
  "parent": null
}, {
  "depth": 0,
  "id": "objetos-associados",
  "label": "Objetos associados",
  "parent": null
}, {
  "depth": 0,
  "id": "objetos-personalizados",
  "label": "Objetos personalizados",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      br: "br",
      a: "a",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Variáveis de modelo de orçamento"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os modelos de orçamento personalizados podem acessar os dados de orçamento e alguns objetos associados diretamente dos modelos. Os dados disponíveis dependem dos dados que você tem em seu CRM, além dos dados adicionados ao próprio orçamento."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao desenvolver um modelo de orçamento, você pode usar dados simulados fornecidos pela HubSpot para preencher o modelo, o que pode ajudar na visualização do modelo. Na pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot"
      }), ", acesse a pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cms-quotes-theme"
      }), ". Na pasta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modelos"
      }), ", exiba os modelos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "basic.html"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modern.html"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "original.html"
      }), ". Esses modelos contêm o seguinte bloco de código na parte superior:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% from \"../imports/mock_data.html\" import SAMPLE_TEMPLATE_DATA as mock_data %}\n{% from \"../imports/module_defaults.html\" import MODULE_DEFAULTS as module_defaults %}\n{% set QUOTE = template_data.quote || mock_data.quote %}\n{% set CURRENCY = QUOTE.hs_currency || \"USD\" %}\n{% set LOCALE = QUOTE.hs_locale || \"en-US\" %}\n{% set ASSOCIATED_OBJECTS = QUOTE.associated_objects %}\n{% set LINE_ITEMS = ASSOCIATED_OBJECTS.line_items %}\n{% set ADDITIONAL_FEES = ASSOCIATED_OBJECTS.additional_fees %}\n{% set TOTALS = ASSOCIATED_OBJECTS.totals || ASSOCIATED_OBJECTS.totals %}\n{% set QUOTE_TOTAL = TOTALS.total %}\n{% set SUB_TOTALS = TOTALS.subtotals %}\n{% set DEAL = ASSOCIATED_OBJECTS.deal %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os dados falsos são primeiro importados do arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mock_data.html"
      }), " e definido como a variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " para usar os dados encontrados em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), ", se disponível. A variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " também é usada para preencher as outras variáveis desta lista, como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ASSOCIATED_OBJECTS"
      }), ", para tornar o acesso a esses dados mais sucinto. No entanto, você pode estruturar seus dados de forma diferente, dependendo das suas preferências."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No código acima, você notará que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " também é usado para definir a variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " principal. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " é um objeto contendo todos os dados reais para o orçamento e negociação na página. Se esse objeto não for encontrado no modelo, o HubSpot carregará os dados ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mock_data.html"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objeto Template_data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A grande maioria dos dados pode ser acessada diretamente através do objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), ". Você pode usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ template_data|pprint }}"
      }), " em seu modelo para ver o objeto completo fornecido."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um dicionário contendo o orçamento, quote.associated_objects e dicionários totais."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis de orçamento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As informações específicas para este orçamento individual."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dicionário contendo todos os dados para o orçamento em si."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.associated_objects.deal.hs_object_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID do negócio"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de proprietário do negócio"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_all_owner_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "integer or array of integers"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "IDs de proprietário do negócio"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_created_by_user_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Usuário que criou o orçamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_lastmodifieddate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Data em que o orçamento foi modificado pela última vez. Em formato de tempo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hubspot_owner_assigneddate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Data em que o orçamento foi atribuído a um proprietário. Em formato de tempo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_createdate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Data e hora em que o orçamento foi criado. Em formato de tempo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_expiration_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Data em que o orçamento expira. Em formato de tempo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Título do orçamento"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_template_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "\"CUSTOMIZABLE_QUOTE_TEMPLATE\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Slug de URL para página da web de orçamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_proposal_template_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Caminho do sistema de arquivos do desenvolvedor para o modelo. (inclui extensão de arquivo)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_quote_amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor em dinheiro"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Moeda em que está o valor do orçamento em código de moeda ISO 4217 de 3 caracteres.“USD”"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Código do idioma“pt-br”"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_locale"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cópia local“pt-br”"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_terms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto dos termos fornecido pelo criador do orçamento"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nome da pessoa que envia o orçamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nome da empresa da pessoa que envia o orçamento"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_company_image_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Logotipo da empresa para a pessoa que envia o orçamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Status do orçamento.\"APPROVAL_NOT_NEEDED\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_primary_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string/hex color code"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "\"#425b76\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_quote_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de ID de orçamento exclusivo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_payment_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Use para testar se os campos de pagamento precisam ser exibidos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_esign_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Use para testar se os campos de assinatura eletrônica precisam ser exibidos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Não consegue encontrar uma variável que você está procurando?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Há mais variáveis que você pode acessar dentro de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "template_data"
        }), ". Use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|pprint"
        }), " para visualizá-las. Além disso, algumas variáveis nas associações de orçamento podem estar disponíveis apenas com base no orçamento/negócio."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Nós faremos a iteração nesta documentação para mostrar e explicar mais dos dados aos quais você tem acesso. Além da bela impressão, você pode visualizar o arquivo de dados simulados dentro do cms-quote-theme, para ver o que está disponível e a estrutura na qual ele vem."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objetos associados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em um modelo de orçamento, você pode acessar dados de registros associados a um orçamento, como negócios ou empresas, usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associated_objects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, você pode adicionar o logotipo do registro da empresa associada do destinatário do orçamento a um orçamento usando o seguinte código:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set company_avatar_url = template_data.quote.associated_objects.company.hs_avatar_filemanager_key %}\n{% if company_avatar_url %}\n  <img src=\"{{ template_data.quote.associated_objects.company.hs_avatar_filemanager_key }}\" width=\"400\" alt=\"{{ template_data.quote.associated_objects.company.name }}\">\n{% else %}\n  <!-- company does not have an assigned image-->\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " apenas os logotipos definidos manualmente aparecerão. Os logotipos detectados automaticamente não aparecerão para impedir que os não intencionais apareçam no modelo de orçamento."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O código acima primeiro define uma variável que pesquisa o logotipo da empresa associada ao orçamento. Usando uma declaração ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", o modelo exibe esse logotipo, se disponível. Se nenhum logotipo foi definido manualmente para a empresa, nenhum logotipo será exibido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objetos personalizados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os dados de objetos personalizados podem ser exibidos ou usados em um orçamento de maneiras diferentes. Como a estrutura de cada objeto personalizado pode variar, você precisará obter propriedades específicas com base em como estruturou seu objeto personalizado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " do orçamento por padrão tem objetos associados personalizados. Por exemplo, objetos personalizados associados a negócios estão incluídos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para acessá-los, você pode usar o seguinte código:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set quote_associated_custom_objects = template_data.quote.associated_objects.deal.associated_objects.custom_objects %}\n\n{{ quote_associated_custom_objects|pprint }}\n{# |pprint is useful for understanding the structure of the data, you can leave it off when outputting values for display. #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " como os objetos personalizados são exclusivos para cada conta, os dados falsos não incluem um objeto personalizado de exemplo. Isso significa que, na visualização do modelo no gerenciador de design, você pode ver um erro ou os dados de objeto personalizados simplesmente não serão exibidos. Em vez disso, você precisará visualizar o modelo com seus dados reais de CRM, o que pode ser feito criando um orçamento a partir do modelo."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode acessar cada tipo de objeto personalizado anexando seu ID de tipo de objeto personalizado formatado com sublinhados. Por exemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data.quote.associated_objects.deal.associated_objects.custom_objects._2_2193031"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode procurar um objeto personalizado usando a função ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations()"
        })
      }), " e as funções ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, se você quiser procurar um objeto personalizado associado a um negócio, poderá passar dados de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set quote_associated_object = crm_associations(template_data.quote.associated_objects.deal.hs_object_id, \"USER_DEFINED\", 152) %}\n{# 152 is an example of an association type id, you would need to use the appropriate id for your use-case. #}\n{{ quote_associated_object }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/quotes",
          children: "Personalizar modelos de orçamento"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/getting-started-from-the-cms-quotes-theme-beta",
          children: "Introdução ao tema de orçamentos do CMS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/pt/deals/create-custom-quote-templates-beta",
          children: "Criar e usar modelos de orçamento personalizados (do ponto de vista de vendas, operações de vendas/gerente)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}