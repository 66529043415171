"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710048;
const slug = exports.slug = 'guides/cms/setup/getting-started-with-local-development';
const title = exports.title = 'Introdução ao desenvolvimento local';
const name = exports.name = 'Introdução ao desenvolvimento local';
const metaDescription = exports.metaDescription = 'Introdução ao desenvolvimento local no HubSpot CMS. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/local_development.png';
const featuredImageAltText = exports.featuredImageAltText = 'Introdução ao desenvolvimento local';
const toc = exports.toc = [{
  "depth": 0,
  "id": "instalar-depend%C3%AAncias",
  "label": "Instalar dependências",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-criar-um-diret%C3%B3rio-de-trabalho",
  "label": "1. Criar um diretório de trabalho",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-configurar-as-ferramentas-de-desenvolvimento-local",
  "label": "2. Configurar as ferramentas de desenvolvimento local",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-criar-um-recurso-para-buscar-no-hubspot",
  "label": "3. Criar um recurso para buscar no HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-buscar-o-m%C3%B3dulo-para-seu-ambiente-local",
  "label": "4. Buscar o módulo para seu ambiente local",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-fazer-altera%C3%A7%C3%B5es-e-carregar",
  "label": "5. Fazer alterações e carregar",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-executar-um-comando-watch-para-carregar-as-altera%C3%A7%C3%B5es-automaticamente",
  "label": "6. Executar um comando watch para carregar as alterações automaticamente",
  "parent": null
}, {
  "depth": 0,
  "id": "pr%C3%B3ximas-etapas",
  "label": "Próximas etapas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      h2: "h2",
      ol: "ol",
      strong: "strong",
      em: "em",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Introdução ao desenvolvimento local"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), " (Interface de Linha de Comando) conecta seu ambiente local ao HubSpot, o que significa que você terá cópias locais dos seus ativos web do HubSpot. Isso permite que você use o controle de versão, seu editor de texto favorito e várias tecnologias de desenvolvimento web ao desenvolver no HubSpot CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este guia é ideal para pessoas que já estão familiarizadas com o CMS, mas que querem aprender sobre como trabalhar com a CLI. Caso seja sua primeira vez trabalhando com desenvolvimento no HubSpot CMS Hub, consulte o guia de início rápido:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "Início rápido no desenvolvimento do CMS Hub"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Neste tutorial, você aprenderá:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Como instalar a CLI e conectá-la à sua conta da HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Como obter um módulo da sua conta da HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Como atualizar o módulo localmente e carregar suas alterações."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Como usar o comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), " para continuar carregando as alterações salvas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver mais comandos e formatos de arquivo locais, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "Referência de ferramentas de desenvolvimento local"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Instalar dependências"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para desenvolver no HubSpot localmente, você precisará:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Instalar o", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: " Node.js"
        }), ", um ambiente Javascript em tempo de execução que possibilita as ferramentas locais. A versão 16 ou superior do Node é suportada, mas recomendamos a versão de suporte a longo prazo (LTS)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Execute ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install -g @hubspot/cli"
        }), " na sua linha de comando para instalar a CLI da HubSpot globalmente. Para instalar as ferramentas em seu diretório atual, execute", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se preferir, você também pode usar o", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://classic.yarnpkg.com/en/docs/install",
        children: " Yarn"
      }), ". Se você estiver usando o Yarn, os comandos serão executados com o prefixo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yarn"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Recebeu um erro EACCES ao instalar?"
        }), " Consulte ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.npmjs.com/resolving-eacces-permissions-errors-when-installing-packages-globally",
          children: "NPM Como resolver erros de permissão EACCESS ao instalar pacotes globalmente"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Criar um diretório de trabalho"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Crie uma pasta para o trabalho que fará abaixo. Para este tutorial, nomeie a pasta como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "local-dev-tutorial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode fazer isso localmente executando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mkdir local-dev-tutorial"
      }), " na linha de comando, o que criará o diretório. Depois, execute ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd local-dev-tutorial"
      }), " para navegar até esse diretório."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Configurar as ferramentas de desenvolvimento local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em seguida, execute ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs init"
      }), " para conectar as ferramentas à sua conta da HubSpot. Este comando oferecerá orientação pelas etapas abaixo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Para conectar a CLI a uma conta da HubSpot, você precisará copiar a chave de acesso pessoal da conta. Se solicitado, pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), " para abrir hubspot.com na ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/l/personal-access-key",
            children: "página da chave de acesso pessoal da conta"
          }), ". Se você tiver várias contas, será solicitado no navegador que selecione uma conta primeiro."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Na página da chave de acesso pessoal, você pode gerar uma nova chave de acesso pessoal ou copiar o valor da chave existente, se já existir."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se estiver criando uma chave pela primeira vez, selecione a quais escopos a chave tem acesso. Você precisará selecionar pelo menos a permissão do ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Gerenciador de Design"
            }), " para interagir com as ferramentas de design da conta."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Depois de selecionar as permissões da chave, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Gerar chave de acesso pessoal"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Depois que uma chave for gerada, copie o valor clicando primeiro em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Mostrar"
          }), " sob a chave e em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cole a tecla na linha de comando e pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Em seguida, insira um nome para a conta. Esse nome só será visto e usado por você ao executar comandos. Por exemplo, você pode usar \"sandbox\" se estiver usando um sandbox de desenvolvedor ou \"company.com\" se estiver usando uma conta de cliente completa. Esse nome não pode conter espaços."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de concluir o fluxo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "init"
      }), ", você verá uma mensagem de sucesso confirmando que o arquivo de configuração,", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: " hubspot.config.yml"
      }), " foi criado no diretório atual."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " será parecido com isso:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: mainProd\nportals:\n  - name: mainProd\n    portalId: 123456\n    defaultMode: publish\n    authType: personalaccesskey\n    auth:\n      tokenInfo:\n        accessToken: >-\n          {accessTokenValue}\n\n\n        expiresAt: '2023-06-27T19:45:58.557Z'\n    personalAccessKey: >-\n      {personalAccessKeyValue}\n\n\n    sandboxAccountType: null\n    parentAccountId: null\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultPortal"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A conta com a qual se interage por padrão ao executar comandos da CLI.Para interagir com uma conta autenticada que não está definida como padrão, você pode adicionar um sinalizador ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account="
            }), " ao comando, seguido pelo nome ou ID da conta. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=12345"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=mainProd"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "portals"
            }), ", você encontrará uma entrada para cada conta conectada. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " especifica o nome fornecido para a conta. Você pode usar esse nome ao ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli#set-default-account",
              children: "definir uma nova conta padrão"
            }), " ou especificar uma conta com o sinalizador ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da conta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultMode"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ao carregar na conta, define o estado padrão no qual carregar o conteúdo. Pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "draft"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "authType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A forma de autenticação usada para autenticar a conta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sandboxAccountType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se a conta for uma conta de sandbox, indica o ID da conta de produção principal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "parentAccountId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "parentAccountId"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["O arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        }), " permite várias contas. Para autenticar mais contas, execute ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs auth"
          })
        }), " e siga as instruções."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Criar um recurso para buscar no HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para este tutorial, primeiro você criará um novo ativo no HubSpot para poder buscá-lo no seu ambiente local usando a CLI."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na sua conta da HubSpot, vá em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Arquivos e Modelos"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Ferramentas de design"
          }), ". O gerenciador de design será aberto e você poderá acessar os arquivos que deseja carregar usando a CLI. Esta árvore de arquivos e pastas também é conhecida como o ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/overview#developer-file-system",
            children: "sistema de arquivos do desenvolvedor"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na barra lateral esquerda do gerenciador de design, selecione a pasta ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "@hubspot"
          }), " para visualizar os ativos padrão da HubSpot, como temas e módulos."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-hubspot-folder.png",
            alt: "design-manager-hubspot-folder"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na barra lateral esquerda, role para baixo e clique com o botão direito no módulo ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ícone"
          }), " e, em seguida, selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Clonar módulo"
          }), ". O módulo será clonado na raiz do sistema de arquivos do desenvolvedor e a sua nova cópia do módulo será aberta à direita."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/default-icon-module-cloned.png",
            alt: "default-icon-module-cloned"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na parte superior da barra lateral esquerda, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Ações"
          }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar caminho"
          }), ". O caminho relativo para o módulo no sistema de arquivos do desenvolvedor será copiado e você o usará na próxima etapa para buscar o módulo localmente."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-actions-copy-path.png",
            alt: "module-actions-copy-path"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Buscar o módulo para seu ambiente local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com o módulo clonado, agora você usará o comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        })
      }), " para trazê-lo ao seu ambiente local."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No terminal, execute ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch '/icon copy.module'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch '/icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O módulo será transferido como um diretório contendo cinco arquivos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "fields.json"
          }), ": contém o código para os vários campos do módulo. Nesse caso, isso inclui o campo de ícone, dois campos de opção de acessibilidade e um conjunto de campos de estilo. Você pode ver esses campos na barra lateral direita do editor de módulos no HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), ": contém as informações básicas do módulo, como seu rótulo, ID e os tipos de modelos em que pode ser usado. Essas informações serão exibidas na barra lateral direita do editor do módulo."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.css"
          }), ": contém o CSS do módulo, que você também pode ver no painel CSS do editor de módulos no HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.html"
          }), ": contém o HTML do módulo, que você também pode ver no painel HubL + HTML do editor de módulos no HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.js"
          }), ": contém o JavaScript do módulo, que você também pode ver no painel JS do editor de módulos no HubSpot."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-directory-local.png",
            alt: "module-directory-local"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em seguida, você atualizará o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " do módulo, carregará esse arquivo na sua conta e verá a alteração no HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Fazer alterações e carregar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Primeiro, faça uma alteração no módulo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No editor de código que preferir, abra o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), " do módulo."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-open.png",
            alt: "module-meta-json-file-open"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Atualize o campo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "label"
          }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"Icon\""
          }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"CMS tutorial module\""
          }), ". Salve o arquivo."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-label-updated.png",
            alt: "module-meta-json-file-label-updated"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Antes de executar o próximo comando, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), ", vamos detalhar o comando e os argumentos necessários. Esse comando requer dois argumentos: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload <src> <dest>"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "src"
        }), ": o caminho relativo da pasta de origem que você está carregando do ambiente local."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "dest"
        }), ": o caminho do diretório de destino no HubSpot, local em relação à raiz do sistema de arquivos do desenvolvedor. Você pode criar um novo diretório na conta especificando o nome do diretório, como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload <src> /new-directory"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Com isso em mente, como você está carregando na raiz do sistema de arquivos do desenvolvedor, execute o seguinte comando:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs upload 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Depois que a CLI confirmar que o módulo foi carregado com sucesso, atualize o gerenciador de design para ver sua alteração no HubSpot. Agora você deve ver que o campo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Rótulo"
        }), " mostra o novo valor."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-label-updated.png",
        alt: "module-label-updated"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Executar um comando watch para carregar as alterações automaticamente"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de usar o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), " para carregar de uma vez seus arquivos locais, agora você usará o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " para carregar continuamente as alterações salvas. Esse comando usa os mesmos argumentos que o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), ", de modo que você pode especificar os mesmos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<src>"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dest>"
      }), " acima."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Executar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch 'icon copy.module' 'icon copy.module'"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs watch 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com o comando watch em execução, as alterações salvas serão carregadas automaticamente na conta da HubSpot. Como demonstração, faça a seguinte alteração local no módulo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "meta.json"
        }), ", atualize o campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "host_template_types"
        }), " para remover ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_LISTING\""
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_POST\""
        }), " de modo que o módulo fique disponível apenas para as páginas: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"host_template_types\"``:[\"PAGE\"]"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Salve o arquivo. Isso fará com que a CLI carregue automaticamente o arquivo no HubSpot."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Com o arquivo carregado, atualize o gerenciador de design no HubSpot para ver a alteração. A seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Tipos de modelos"
        }), " na barra lateral direita agora deve incluir apenas ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Página"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-editor-template-type.png",
        alt: "module-editor-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para finalizar o acompanhamento, pressione ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Control + C"
        }), ". É importante observar que você não poderá executar outros comandos na mesma janela de terminal em que o comando Watch está sendo executado. Para executar outros comandos durante a execução de um comando watch, você deve abrir outra janela de terminal."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Próximas etapas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora que você aprendeu a usar os comandos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetch"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), ", confira o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "guia completo de referência de comandos da CLI"
      }), " para saber o que mais você pode fazer com a CLI."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Também é recomendado conferir os seguintes tutoriais:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Criar um fluxo de trabalho de desenvolvimento eficaz"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "Como configurar integração contínua com o GitHub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Introdução aos módulos personalizados"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Introdução aos temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Introdução às áreas de arrastar e soltar"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}