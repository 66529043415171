"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710048;
const slug = exports.slug = 'guides/cms/setup/getting-started-with-local-development';
const title = exports.title = 'ローカル開発を開始する';
const name = exports.name = 'ローカル開発を開始する';
const metaDescription = exports.metaDescription = 'HubSpotのCMSでローカル開発を開始するためのガイドをご紹介します。 ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/local_development.png';
const featuredImageAltText = exports.featuredImageAltText = 'ローカル開発を開始する';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E4%BE%9D%E5%AD%98%E9%96%A2%E4%BF%82%E3%81%AE%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB",
  "label": "依存関係のインストール",
  "parent": null
}, {
  "depth": 0,
  "id": "1.%E4%BD%9C%E6%A5%AD%E3%83%87%E3%82%A3%E3%83%AC%E3%82%AF%E3%83%88%E3%83%AA%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "1.作業ディレクトリーを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "2.%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E9%96%8B%E7%99%BA%E3%83%84%E3%83%BC%E3%83%AB%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "2.ローカル開発ツールを設定する",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-hubspot%E3%81%A7%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "3. HubSpotで取得するアセットを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "4.%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E7%92%B0%E5%A2%83%E3%81%AB%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "4.モジュールをローカル環境に取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-%E5%A4%89%E6%9B%B4%E3%82%92%E5%8A%A0%E3%81%88%E3%81%A6%E3%82%A2%E3%83%83%E3%83%97%E3%83%AD%E3%83%BC%E3%83%89%E3%81%99%E3%82%8B",
  "label": "5. 変更を加えてアップロードする",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-watch%E3%82%92%E5%AE%9F%E8%A1%8C%E3%81%97%E3%81%A6%E5%A4%89%E6%9B%B4%E3%82%92%E8%87%AA%E5%8B%95%E7%9A%84%E3%81%AB%E3%82%A2%E3%83%83%E3%83%97%E3%83%AD%E3%83%BC%E3%83%89%E3%81%99%E3%82%8B",
  "label": "6. watchを実行して変更を自動的にアップロードする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%AC%A1%E3%81%AE%E3%82%B9%E3%83%86%E3%83%83%E3%83%97",
  "label": "次のステップ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      h2: "h2",
      ol: "ol",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ローカル開発を開始する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "HubSpot CLI"
      }), " （コマンド ライン インターフェイス）は、ローカル環境をHubSpotに接続します。つまり、HubSpotのウェブアセットのローカルコピーを取得することになります。これにより、HubSpot CMSでの開発時に、バージョン管理、お気に入りのテキストエディター、さまざまなウェブ開発テクノロジーを利用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このガイドは、CMSの使用経験があり、CLIの操作に関心をお持ちの方に適しています。HubSpotのCMS Hubの開発を始めて行う場合は、クイックスタートガイドの手順に従うことをお勧めします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "CMS Hub開発のクイックスタート"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このチュートリアルでは、次のことを学びます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CLIをインストールしてHubSpotアカウントに接続する方法。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントからモジュールを取得する方法。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モジュールをローカルに更新し、変更をアップロードする方法。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), "コマンドを使用して、保存した変更のアップロードを続行する方法。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コマンドとローカルファイル形式については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "ローカル開発ツールのリファレンス"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "依存関係のインストール"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotでローカルに開発するには、次のことを行う必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: "Node.js"
        }), "（ローカルツールを有効にするJavaScriptランタイム環境）をインストールします。バージョン16以上のNodeがサポートされていますが、LTS（長期サポート）バージョンが推奨されます。2.HubSpot CLIをグローバルにインストールするには、コマンドラインで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install -g @hubspot/cli"
        }), "を実行します。現在使用しているディレクトリーにのみツールをインストールする場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), "を実行します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["必要に応じて", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://classic.yarnpkg.com/en/docs/install",
        children: "Yarn"
      }), "も使用できます。Yarnを使用する場合は、コマンドに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yarn"
      }), "プレフィックスを付けて実行します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "インストール時にEACCESエラーが発生した場合"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.npmjs.com/resolving-eacces-permissions-errors-when-installing-packages-globally",
          children: "パッケージをグローバルにインストールする際のNPM Resolving EACCESアクセス許可エラー"
        }), "（英語）を参照してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1.作業ディレクトリーを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の作業用のフォルダーを作成します。このチュートリアルでは、フォルダー名を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "local-dev-tutorial"
      }), "とします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これをローカルで行うには、コマンドラインで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mkdir local-dev-tutorial"
      }), "を実行し、ディレクトリーを作成します。次に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd local-dev-tutorial"
      }), "を実行して、作成したディレクトリーに移動します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2.ローカル開発ツールを設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["次に、 ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs init"
      }), " を実行してツールをHubSpotアカウントに接続します。このコマンドは、以下の手順を案内します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["CLIをHubSpotアカウントに接続するには、アカウントのパーソナル アクセス キーをコピーする必要があります。プロンプトが表示されたら、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), "を押してhubspot.comを開くと、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/l/personal-access-key",
            children: "アカウントのパーソナル アクセス キーのページ"
          }), "に移動します。複数のHubSpotアカウントを持っている場合は、まずブラウザーでアカウントを選択するように指示されます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "パーソナル アクセス キー ページでは、新しいパーソナル アクセス キーを生成したり、既存のキー値が既に存在する場合は、既存のキー値をコピーしたりできます。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "初めてキーを作成する場合は、キーがアクセスできるスコープを選択します。アカウントのデザインツールを操作するには、少なくとも「デザインマネージャー」権限を選択する必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "キーの権限を選択したら、［パーソナル アクセス キーを生成］をクリックします。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "キーが生成されたら、キーの下にある［表示］をクリックしてから［コピー］をクリックし、値をコピーします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["キーをコマンドラインに貼り付け、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), "キーを押します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "次に、アカウントの名前を入力します。この名前は、コマンド実行時に自分だけに表示され使用するものです。例えば、開発者サンドボックスを使用する場合は「sandbox」と入力し、標準アカウントを使用する場合は「company.com」と入力することができます。この名前にはスペースを含めることができません。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), "を押します。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "init"
      }), "フローが完了すると、設定ファイル", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: "hubspot.config.yml"
      }), "が現在のディレクトリーに作成されたことを確認する成功メッセージが表示されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), "の例を次に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: mainProd\nportals:\n  - name: mainProd\n    portalId: 123456\n    defaultMode: publish\n    authType: personalaccesskey\n    auth:\n      tokenInfo:\n        accessToken: >-\n          {accessTokenValue}\n\n\n        expiresAt: '2023-06-27T19:45:58.557Z'\n    personalAccessKey: >-\n      {personalAccessKeyValue}\n\n\n    sandboxAccountType: null\n    parentAccountId: null\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "名前"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultPortal"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "任意"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["CLIコマンドの実行時に既定でやり取りする対象のアカウント。既定として設定されていない認証済みアカウントとやり取りするには、コマンドに", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account="
            }), "フラグを追加し、その後にアカウント名またはIDを続けます。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=12345"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=mainProd"
            }), "。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "任意"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portals"
            }), "portalsセクションに、接続されている各アカウントのエントリーが表示されます。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "は、そのアカウントに指定されている名前を示します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli#set-default-account",
              children: "新しい既定のアカウントを設定"
            }), "する際や、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account"
            }), "フラグでアカウントを指定する際は、この名前を使用できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultMode"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "任意"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アカウントにアップロードする際のコンテンツの既定の状態を設定します。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "draft"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish"
            }), "のいずれかにできます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "authType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントの認証に使用される認証形式。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sandboxAccountType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "任意"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウントがサンドボックスアカウントの場合、親の本番アカウントのIDが示されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "parentAccountId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "任意"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "parentAccountId"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        }), "ファイルは複数のアカウントをサポートします。さらに多くのアカウントを認証するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs auth"
          })
        }), "を実行してプロンプトに従います。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. HubSpotで取得するアセットを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このチュートリアルでは、まずHubSpotで新しいアセットを作成し、CLIを使用してローカル環境に取得できるようにします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["HubSpotアカウントにて、［マーケティング］＞［ファイルとテンプレート］＞［デザインツール］の順に進みます。これによりデザインマネージャーが開き、CLIを使用してアップロードしたファイルにアクセスできます。このファイルとフォルダーのツリーは、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/overview#developer-file-system",
            children: "デベロッパー ファイル システム"
          }), "とも呼ばれます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["デザインマネージャーの左のサイドバーで、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "@hubspot"
          }), "フォルダーを選択し、テーマやモジュールなど、HubSpotの既定のアセットを表示します。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-hubspot-folder.png",
            alt: "design-manager-hubspot-folder"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["左側のサイドバーで、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "アイコン"
          }), "モジュールまでスクロールして右クリックし、［モジュールを複製］を選択します。モジュールはデベロッパー ファイル システムのルートに複製され、新しいモジュールのコピーが右側で開きます。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/default-icon-module-cloned.png",
            alt: "default-icon-module-cloned"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "左側のサイドバーの上部にある［アクション］をクリックし、［パスをコピー］を選択します。これにより、デベロッパー ファイル システムのモジュールへの相対パスがコピーされます。これは、次のステップでモジュールをローカルに取得するために使用します。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-actions-copy-path.png",
            alt: "module-actions-copy-path"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4.モジュールをローカル環境に取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュール複製したら、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        })
      }), "コマンドを使用してローカル環境に取り込みます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ターミナルで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch '/icon copy.module'"
      }), "を実行します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch '/icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールは、5つのファイルを含むディレクトリーとしてダウンロードされます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "fields.json"
          }), "：モジュールのさまざまなフィールドのコードが含まれています。この場合、アイコンフィールド、2つのアクセシビリティー オプション フィールド、およびスタイルフィールドのセットが含まれます。これらのフィールドは、HubSpotのモジュールエディターの右側のサイドバーに表示されます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), "：ラベル、ID、使用できるテンプレートの種類など、モジュールの基本情報が含まれています。この情報は、モジュールエディターの右側のサイドバーに表示されます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.css"
          }), "：モジュールのCSSが含まれています。これは、HubSpotのモジュールエディターのCSSペインにも表示されます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.html"
          }), "：モジュールのHTMLが含まれています。これは、HubSpotのモジュールエディターのHubL + HTMLペインにも表示されます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.js"
          }), "：モジュールのJavaScriptが含まれています。これは、HubSpotのモジュールエディターのJSペインにも表示されます。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-directory-local.png",
            alt: "module-directory-local"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["次に、モジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "ファイルを更新し、それをアカウントにアップロードして、HubSpotで変更を確認します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. 変更を加えてアップロードする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "まず、モジュールに変更を加えます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["お好みのコードエディターで、モジュールの", (0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), "ファイルを開きます。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-open.png",
            alt: "module-meta-json-file-open"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "label"
          }), "フィールドを", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"Icon\""
          }), "から", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"CMS tutorial module\""
          }), "に更新します。そしてファイルを保存します。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-label-updated.png",
            alt: "module-meta-json-file-label-updated"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["次のコマンド", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), "を実行する前に、コマンドとその引数について見てみましょう。このコマンドは2つの引数を取ります：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload <src> <dest>"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "src"
        }), "：ローカル環境からアップロードするソースフォルダーの相対パス。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "dest"
        }), "：HubSpot内の宛先ディレクトリーのパスで、デベロッパー ファイル システムのルートを起点としています。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload <src> /new-directory"
        }), "などのディレクトリー名を指定することにより、アカウントに新しいディレクトリーを作成できます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "以上を踏まえて、デベロッパー ファイル システムのルートにアップロードするため、次のコマンドを実行します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs upload 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CLIでモジュールが正常にアップロードされたことが確認されたら、デザインマネージャーを更新してHubSpotで変更を表示します。これで、「ラベル」フィールドに新しい値が表示されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-label-updated.png",
        alt: "module-label-updated"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. watchを実行して変更を自動的にアップロードする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), "コマンドを使用してローカルファイルの1回限りのアップロードを実行したので、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "コマンドを使用して保存された変更を継続的にアップロードします。このコマンドは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), "と同じ引数を使用するため、上記の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<src>"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dest>"
      }), "を指定できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch 'icon copy.module' 'icon copy.module'"
        }), "を実行します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs watch 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "watchを実行すると、保存された変更は自動的にHubSpotアカウントにアップロードされます。デモとして、ローカルでモジュールに次の変更を加えます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "meta.json"
        }), "ファイルで、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "host_template_types"
        }), "を更新し、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_LISTING\""
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_POST\""
        }), "を削除することによって、モジュールが次のページでのみ使用できるようになります。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"host_template_types\"``:[\"PAGE\"]"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ファイルを保存します。これにより、CLIで自動的にファイルがHubSpotにアップロードされます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ファイルがアップロードされたら、HubSpotのデザインマネージャーを更新して変更を表示します。右側のサイドバーの**［テンプレートタイプ］セクション**には［ページ］のみ表示されているはずです。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-editor-template-type.png",
        alt: "module-editor-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["watchを終了するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Control + C"
        }), "を押します。watchコマンドが実行されているのと同じターミナルウィンドウで他のコマンドを実行することはできないことに注意してください。watchを実行している間に他のコマンドを実行するには、代わりに別のターミナルウィンドウを開き、そこでコマンドを実行する必要があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "次のステップ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ここまでで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetch"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "コマンドについて見てきました。CLIでさらにどんなことができるのか、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLIコマンドのリファレンスガイド"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "また、次のチュートリアルもお勧めします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "効率的な開発ワークフローの構築"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "GitHubでのCI（継続的インテグレーション）の設定方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "カスタムモジュールを使ってみる"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "テーマを使ってみる"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "ドラッグ＆ドロップエリアを使ってみる"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}