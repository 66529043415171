"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31121693267;
const slug = exports.slug = 'reference/cms/modules/files';
const title = exports.title = 'Coding Custom Modules';
const name = exports.name = 'module files';
const metaDescription = exports.metaDescription = 'Custom modules are reusable components you can use across your website. Build them following best practices for speed, user experience and accessibility.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "html-%2B-hubl-module.html",
  "label": "HTML + HubL (module.html)",
  "parent": null
}, {
  "depth": 0,
  "id": "css-module.css",
  "label": "CSS (module.css)",
  "parent": null
}, {
  "depth": 1,
  "id": "styling-based-on-module-field-values",
  "label": "Styling based on module field values",
  "parent": "css-module.css"
}, {
  "depth": 2,
  "id": "css-classes",
  "label": "CSS Classes",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "require_css-block",
  "label": "require_css block",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "add-inline-styles",
  "label": "Add inline styles",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "import-specific-css-files",
  "label": "Import specific CSS files",
  "parent": "css-module.css"
}, {
  "depth": 0,
  "id": "javascript-module.js",
  "label": "JavaScript (module.js)",
  "parent": null
}, {
  "depth": 1,
  "id": "scripting-based-on-field-values",
  "label": "Scripting based on field values",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "data-attributes",
  "label": "Data attributes",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "require_js-block",
  "label": "require_js block",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "require_js",
  "label": "require_js",
  "parent": "javascript-module.js"
}, {
  "depth": 0,
  "id": "related-information",
  "label": "Related Information",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      img: "img",
      h2: "h2",
      pre: "pre",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Module files"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When building a module for pages, blogs, and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/quotes",
        children: "quotes"
      }), ", the module will contain three front-end related files that control the content, styling, and functionality of the module:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.html"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.css"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.js"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Email modules don't support module.css and module.js. This is because email clients don't support JavaScript and support for linked CSS files is limited."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "These files will always be rendered to the page when an instance of the module is on the page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a page includes multiple instances of the same module, HubSpot will only load ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " from that module once. By default, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " do not load asynchronously, but you can change this by including ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options and js_render_options"
      }), " in the module’s meta.json."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Modules can be built within the design manager or locally using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "the HubSpot CLI"
      }), ". In the design manager, module files are displayed in a multi-pane editor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module1.png",
        alt: "cms-dev-custom-module1"
      }), "When viewing a module locally, the files are contained within module-name.module folders."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module0.png",
        alt: "cms-dev-custom-module0"
      }), "Whether you use the design manager or CLI to create and manage modules is based on your team’s preferences. See ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "creating an efficient developer workflow"
      }), " for recommendations."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTML + HubL (module.html)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The module.html file is intended for HTML and HubL. In general, wherever a module is placed in the page editor or template file determines where the contents of the module.html file are rendered."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This file acts like a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "HubL include"
      }), " in the page wherever the module is placed. The module.html file ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#using-module-field-data-to-render-html",
        children: "can access the module's field values through HubL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CSS (module.css)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " file to add CSS to a module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In general, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " supports a very limited subset of HubL. However, you can use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#module-asset-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_asset_url(\"my-image.png\")"
        })
      }), " for images added as module linked assets. This enables linking assets such as images, packaged with the module itself. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial-module__wrapper {\n  background: url('{{ module_asset_url('bg-pattern.png') }}');\n  background-repeat: repeat;\n  min-height: 200px;\n  width: 100%;\n  display: block;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to set up a module's CSS to change dynamically based on the module's fields."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styling based on module field values"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are a few ways you can influence the styling of your module based on the module’s fields. Choose the way that works best for your specific use case."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#css-classes",
          children: "CSS Classes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#require-css-block",
          children: "Require_css block"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#inline-styles",
          children: "Inline styles"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "CSS Classes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To set up predefined styling for the module with the option for editors to select from those options, you can add a module field to set classes in your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.htm"
      }), "l file which correspond to CSS classes in your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, you may have an image and text module. You want content creators to be able to position the image to the right or left of the text based on a choice field. To do this, you could set your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " files as follows:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n\n<section class=\"img-text__wrapper img-text--{{ module.positioning }}\" aria-label=\"{{ module.heading }}\">\n  {# module.position is a choice field with two values \"img-left\" or \"img-right\". This dictates the order they appear on desktop. Controlled by CSS #}\n  <div class=\"img-text__img\">\n      <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\">\n  </div>\n  <div class=\"img-text__text\">\n      <h2>\n        {% inline_text field=\"heading\" value=\"{{ module.heading }}\" %}\n      </h2>\n      {% inline_rich_text field=\"text\" value=\"{{ module.text }}\" %}\n  </div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "/* module.css */\n\n/* CSS that makes the image show adjacent to the text, \nand positioned based on the positioning field.*/\n\n/* The media query ensures that on mobile the image \nwill always appear above the text for \nvisual consistency. */\n\n@media (min-width: 768px) {\n  .img-text__wrapper {\n    display: flex;\n    align-items: row;\n  }\n  .img-text__img,\n  .img-text__text {\n    flex: 1;\n    padding: 10px;\n  }\n  .img-text--img-right {\n    flex-direction: row-reverse;\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_css block"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you need to give content creators direct control over specific properties and when classes are not ideal, style tags with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " blocks are the best option."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To give content creators direct control over specific properties without using classes, you can instead add styling to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " file within ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " tags. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n  <style>\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  </style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " can render HubL, you can use module field values as CSS variables. When a content creator updates the field in the page editor, the CSS will update to match. These block move the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), " tags into the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " of your page within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), " statement."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also set the CSS to be scoped to only the module instance by wrapping the CSS with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scope_css"
      }), " tags. For example, you could update the above module code as follows:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n<style>\n  {% scope_css %}\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  {% end_scope_css %}\n</style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add inline styles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When you need to give content creators granular control over only a few properties and when classes are not ideal, you can directly add the values to a style attribute in the HTML."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Module.html #}\n<div style=\"background: rgba({{ module.bg_color.color|convert_rgb }},{{ module.bg_color.opacity/100 }});\">\n  {% inline_rich_text field=\"richtext\" value=\"{{ module.richtext }}\" %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have many properties and the code becomes hard to read, consider switching to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " block method."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Import specific CSS files"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " is a HubL function that you can add to module.html which tells HubSpot that a particular module or template requires a particular CSS file to display. A link tag pointing to the css file is added to the page's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " inside of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " function will only load that CSS file once, regardless of how many times that same file is required by modules and templates on a particular page. This makes it great for situations where styles may be shared across multiple modules, but where adding the CSS directly to the main stylesheets used on every page for your site may not make sense."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " and linked CSS files fill the same purpose, but ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " can be used conditionally based on field values. This prevents loading unnecessary code."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{ require_css(get_asset_url(\"/modules/shared_layout_styles.css\")) }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScript (module.js)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " file to add JavaScript to a module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Like the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " file, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " file does not support HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Scripting based on field values"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are a few ways you can build modules, where the JavaScript acts differently based on field values. Understanding which method to use and when can mean performance benefits on every page the module is used."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, you have a custom image module, you want to give content creators the ability to make it so the image can open in a lightbox. Content creators only want that for specific images, and not all instances of the module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Data attributes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Data attributes are HTML 5 standard custom attributes that developers add to elements. Just as all elements support ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "class=\"yourClassName\""
      }), ", all elements support ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-your-attribute=\"yourValue\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html-->\n<div class=\"img-module img-module__wrapper\" data-lightbox=\"{{ module.is_lightbox_enabled }}\" data-caption=\"above\">\n  <!-- module.is_lightbox_enabled is a boolean field, module.caption_position is a choice field. -->\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use data attributes to pass the field values of your module instances to be handled by your module.js file."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To use the values in your module.js file, you will need to loop through all of the instances of your module. Adding a module-specific class name to the outermost wrapper element of your module will give you a target to use, so that you can loop through each of your module instances."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// module.js\n\nlet imgModules = document.getElementsByClassName('img-module');\nArray.from(imgModules).forEach(function (element) {\n  // loop through each of the instances of the module\n  // set data attributes to variables to make it easy to work with\n  let isLightboxEnabled = element.dataset.lightbox;\n  let captionStyle = element.dataset.caption;\n  if (isLightboxEnabled) {\n    element.addEventListener('click', function () {\n      showLightbox(captionStyle); // Execute your code for the action you want to take, you can pass your data attributes into functions from libraries.\n    });\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The data attributes will allow you to retrieve the field values for each module instance in your module.js."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_js block"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In advanced situations, perhaps when using a JavaScript templating library or a reactive framework like Vue.js or React.js, you may prefer outputting just the data, while the framework handles rendering."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In this case, use a script tag surrounded by a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " block to provide variables you can access from your templating script."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% require_js %}\n<script>\n  let myArray = [\n    {%- for item in module.repeating_text_field -%}\"{{ item }}\",{%- endfor -%}\n  ];\n</script>\n{% end_require_js %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This technique can be useful for supplying advanced applications with an initial set of data from which to render. This eliminates an initial JavaScript call to retrieve data."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " is a HubL function that tells HubSpot that a particular module or template requires a particular JavaScript file to load properly. The function takes two parameters: the path to the file and the location the file is to be added to (\"head\" or \"footer\")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In a module ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " can only be added to the module.html. The JavaScript file referred to in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " statement will only be loaded once per page, regardless of how many times it is required by modules and templates within the page. This reduces the number of HTTP requests and prevents duplicate code."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Some situations where this becomes handy:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you have multiple modules or templates that require the same JavaScript, you can use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " to share that script across modules."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you're working with a JavaScript bundler like webpack, it can be easier to output your js files to one specific location. Using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), ", you can associate the JavaScript with your module."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " and linked javascript files serve the same purpose, but ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " can be done conditionally based on field values. This prevents unnecessary code from being loaded. You also have the additional option of loading JavaScript in the head, should you need that."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Since JavaScript is render-blocking , the default location ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " places JavaScript is the \"footer\". ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Learn more about optimizing for performance."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related Information"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Optimize your CMS Hub site for speed"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Module fields"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}