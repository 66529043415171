"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694134;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/tutorial';
const title = exports.title = 'Creating a drag and drop area';
const name = exports.name = 'Getting started with drag and drop areas';
const metaDescription = exports.metaDescription = 'Using drag and drop areas to support content creators easily making layout, style and content changes';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-create-a-new-html-template",
  "label": "1. Create a new HTML template",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-create-a-drag-and-drop-area",
  "label": "2. Create a drag and drop area",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-create-a-section-with-a-module",
  "label": "3. Create a section with a module",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-include-multiple-modules",
  "label": "4. Include multiple modules",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-incorporate-columns-and-rows",
  "label": "5. Incorporate columns and rows",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-set-generic-drag-and-drop-component-styles",
  "label": "6. Set generic drag and drop component styles",
  "parent": null
}, {
  "depth": 0,
  "id": "related-tutorials",
  "label": "Related tutorials",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Getting started with drag and drop areas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag and drop areas"
      }), " are sections of a template that act as empty containers that can be modified directly from the page editor. By building drag and drop areas into a template, it enables content creators to add and manage modules in the page editor as needed, rather than relying on static modules built into the template."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/a%20new%20approach.gif",
        alt: "Animation of modules being dragged onto a page, with columns and rows being adjusted"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition to using drag and drop areas as empty drop zones for content creators, you can also pre-populate drag and drop areas with various modules, layouts, and content to act as a starting point."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This tutorial will take you through setting up a simple drag and drop area. For more developer resources on drag and drop areas, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
        children: "boilerplate"
      }), " for best practices on implementation as well as the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "drag and drop area HubL tag reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " a content creator can swap a page's template for another template of the same type, depending on whether it has ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), " tags."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Drag and drop templates built with the visual layout editor can be swapped for other drag and drop templates or coded templates with or without ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " tags."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Coded templates with ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " tags can only be swapped for other coded templates with ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " tags."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Coded templates without ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " tags can only be swapped for other coded templates without ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " tags."]
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "When the page's template is swapped, any existing content added to drag and drop areas of the first template will be retained."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Create a new HTML template"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Create a new HTML template to contain the HubL and HTML which will make up your drag and drop section."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Drag and drop areas are based on a 12 column responsive grid. Drag and drop tags render markup with class names designating columns and rows. You are responsible for adding a stylesheet to target those class names. An example of layout styles you could implement can be found in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
          children: "HubSpot CMS boilerplate"
        }), ". Your stylesheet can be added to the template using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-css",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ require_css() }}"
          })
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Create a drag and drop area"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " is the container that makes a portion of the web page editable in terms of its structure, design, and content. The body of a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " tag supplies the default content for the drag and drop area."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This tag on its own will generate a drop zone for content creators to drag modules into within the content creator."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n\t<!-- generates an empty drag and drop area drop-section -->\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Create a section with a module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " is a top-level row, and can only be a direct child of a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ". Sections support a variety of parameters that control default values for stylistic controls content creators have for sections within the content creators."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As an example, the code below creates a section with a background image (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_image"
      }), "). This section is centered by a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vertical-alignment"
      }), " parameter, and has a max width of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1000px"
      }), " field for child content. For a full list of supported parameters on the drag and drop HubL tags, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/cms/hubl/tags/dnd-areas",
        children: "drag and drop area HubL tag reference"
      }), " documentation."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To pre-populate the section with content, the code also includes a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " tag to include a module by referencing its path. For this example, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " is pulling in HubSpot's default rich text module, as set by the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "path"
      }), " parameter. A default value for the rich text module is specified using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_attribute"
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image = {\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n          },\n          max_width=1000,\n          vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text' %}\n            {% module_attribute \"html\"%}\n                This is your main headline.\n                Use this space to tell everyone about what you have to offer.\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This results in the drag and drop area containing a module that content creators can edit within the content editor. Note how setting the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "max_width"
      }), " on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " is affecting the content."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_text.png",
        alt: "screenshot of the page editor with the module toolbar displaying"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Include multiple modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To include more than one module, use multiple ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " tags. By setting the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " parameters, which are based off of a 12 column grid, you can place an image module next to the rich text module, as shown below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image={\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n        },\n        max_width=1000,\n        vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text', width=8, offset=0, label=\"Rich Text\" %}\n            {% module_attribute \"html\"%}\n            \t<h1>This is your main headline.</h1>\n            \t<p>Use this space to tell everyone about what you have to offer.</p>\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n        {% dnd_module path='@hubspot/linked_image',\n          width=4,\n          offset=8,\n          img={\n            \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n            \"alt\": \"Stock placeholder image\"\n          }\n        %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now the page has an editable image module as well as a drag handle, allowing content creators to change the width and offset of the modules. Note how setting a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vertical_alignment"
      }), " on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " is vertically centering our content."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_image.png",
        alt: "screenshot of page editor showing an image module added to a section"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Incorporate columns and rows"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To make the drag and drop area more complex, you can incorporate rows and columns using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " tags. Rows and columns act similarly to sections in the content editor, where content creators can drag them around, as well as clone, delete, and style them."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n  {% dnd_section\n    background_image={\n        'backgroundPosition': 'MIDDLE_CENTER',\n        'backgroundSize': 'cover',\n        'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n    },\n    max_width=1000,\n    vertical_alignment='MIDDLE'\n  %}\n    {% dnd_module path='@hubspot/linked_image',\n      width=6,\n      img={\n        \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n        \"alt\": \"Stock placeholder image\"\n      }\n    %}\n    {% end_dnd_module %}\n    {% dnd_column width=6, offset=6 %}\n      {% dnd_row\n        padding={\n            'bottom': 15\n        }\n      %}\n        {% dnd_module path='@hubspot/rich_text' %}\n          {% module_attribute \"html\"%}\n              <h1>This is your main headline.</h1>\n              <p>Use this space to tell everyone about what you have to offer.</p>\n          {% end_module_attribute %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n      {% dnd_row %}\n        {% dnd_module path='@hubspot/form' %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n    {% end_dnd_column %}\n  {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Now, content creators will have further stylistic and layout control over specific rows and columns, in addition to modules and sections."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_area_full.png",
        alt: "screenshot of page editor showing a row with two columns, an image module left, rich text module and form module on right."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Set generic drag and drop component styles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The various components of drag and drop areas, sections, columns, rows and modules all have classes which can be styled using CSS. The editable styles and options for these components can be set using CSS rather than HubL. For example, default padding can be set on ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_sections"
      }), " with the CSS:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".dnd-section {\n  padding: 80px 20px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The generic CSS selectors for the drag and drop area components are ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-section"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-column"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-row"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-module"
      }), ". Aside from these ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd"
      }), " prefixed classes, the actual grid class names in the markup are based on bootstrap 2 names. This does not mean you need to use bootstrap 2 with drag and drop areas. When you add a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " to your page, you are responsible for providing the styles that make the grid work. An example of layout styles you could implement can be found in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/css/objects",
        children: "HubSpot CMS boilerplate"
      }), ". Your stylesheet can be added to the template using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Getting started with themes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Getting started with custom modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "How to add theme capabilities to an existing site"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}