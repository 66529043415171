"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358754;
const slug = exports.slug = 'guides/api/crm/objects/contacts';
const title = exports.title = 'API del CRM | Contactos';
const name = exports.name = 'vNext Docs DP - Contactos';
const metaDescription = exports.metaDescription = 'Los registros de contactos almacenan información sobre personas. Los puntos de terminación de contactos te permiten administrar estos datos y sincronizarlos entre HubSpot y otros sistemas.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-contactos",
  "label": "Crear contactos",
  "parent": null
}, {
  "depth": 1,
  "id": "propiedades",
  "label": "Propiedades",
  "parent": "crear-contactos"
}, {
  "depth": 1,
  "id": "asociaciones",
  "label": "Asociaciones",
  "parent": "crear-contactos"
}, {
  "depth": 0,
  "id": "recuperar-contactos-por-id-de-registro%2C-correo-o-propiedad-de-valor-%C3%BAnico-personalizado",
  "label": "Recuperar contactos por ID de registro, correo o propiedad de valor único personalizado",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-registros-de-contacto",
  "label": "Actualizar registros de contacto",
  "parent": null
}, {
  "depth": 1,
  "id": "asociar-registros-de-contacto-existentes-con-registros-o-actividades",
  "label": "Asociar registros de contacto existentes con registros o actividades",
  "parent": "actualizar-registros-de-contacto"
}, {
  "depth": 1,
  "id": "eliminar-una-asociaci%C3%B3n",
  "label": "Eliminar una asociación",
  "parent": "actualizar-registros-de-contacto"
}, {
  "depth": 0,
  "id": "anclar-una-actividad-en-un-registro-de-contacto",
  "label": "Anclar una actividad en un registro de contacto",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-contactos",
  "label": "Eliminar contactos",
  "parent": null
}, {
  "depth": 0,
  "id": "l%C3%ADmites",
  "label": "Límites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Contactos"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En HubSpot, los registros de contacto almacenan información sobre las personas que interactúan con tu empresa. Los puntos de terminación de los contactos te permiten crear y administrar registros de contacto en tu cuenta de HubSpot, así como sincronizar los datos de los contactos entre HubSpot y otros sistemas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre las API de objetos, registros, propiedades y asociaciones en la guía ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Comprender el CRM"
      }), ". Para obtener más información general sobre objetos y registros de HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://knowledge.hubspot.com/contacts-user-guide",
        children: "aprende a administrar la base de datos del CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear contactos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear nuevos registros de contactos, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En tu solicitud, incluye los datos del contacto en el objeto propiedades. También puedes agregar el objeto asociaciones para asociar el nuevo registro de empresa con registros existentes (por ejemplo, contactos, negocios) o actividades (por ejemplo, reuniones, notas)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los detalles de los contactos se almacenan en las propiedades de los contactos. Hay ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/hubspots-default-contact-properties",
        children: "propiedades de contacto de HubSpot predeterminadas"
      }), ", pero también puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "crear propiedades de contacto personalizadas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear un nuevo registro de contacto, debes incluir ", (0, _jsxRuntime.jsx)("u", {
        children: "al menos una"
      }), " de las siguientes propiedades en tu solicitud: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), ". Se recomienda incluir siempre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ya que la dirección de correo electrónico es el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/deduplication-of-contacts-companies-deals-tickets#automatic-deduplication-in-hubspot",
        children: "identificador único principal"
      }), " para evitar la duplicación de registros de contactos en HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver todas las propiedades disponibles, puedes obtener una lista de las propiedades de contacto de tu cuenta haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/contacts"
      }), ". Más información sobre la", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: " API de propiedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si has incluido ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lifecyclestage"
        }), " en tu solicitud, los valores deben referirse al nombre interno de la etapa del ciclo de vida. Los nombres internos de las etapas predeterminadas son valores de texto y no cambian aunque edites la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties#:~:text=the%20properties%20settings.-,Label/Name%3A,-enter%20a%20unique",
          children: "etiqueta"
        }), " de la etapa (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriber"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketingqualifiedlead"
        }), "). Los nombres internos de las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages",
          children: "etapas personalizadas"
        }), " son valores ", (0, _jsxRuntime.jsx)("u", {
          children: "numéricos"
        }), ". Puedes encontrar el ID interno de una etapa en la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages#:~:text=To%20edit%20a%20lifecycle%20stage%2C%20hover%20over%20the%20stage%20and%20click%20Edit.%20In%20the%20right%20panel%2C%20edit%20the%20Stage%20name%2C%20then%20click%20Edit%20lifecycle%20stage%20to%20confirm.%20Click%20the%20code%20codcode%20icon%20to%20view%20the%20stage%27s%20internal%20ID%2C%20which%20is%20used%20by%20integrations%20and%20APIs.",
          children: "configuración de la etapa del ciclo de vida"
        }), " o recuperando la propiedad de la etapa del ciclo de vida a través de la API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, para crear un nuevo registro de contacto, tu solicitud puede ser similar a la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear un nuevo registro de contacto, también puedes asociarlo con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "registros"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "actividades existentes"
      }), " incluyendo el objeto Asociaciones. Por ejemplo, para asociar un nuevo contacto con una empresa y correo electrónico existentes, tu solicitud se vería de la siguiente manera:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 279\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 556677\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 197\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el objeto asociaciones, debes incluir lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El registro o actividad que quieres asociar con el contacto, especificado por su valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " único."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de asociación entre el contacto y el registro/actividad. Incluye ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Los ID de los tipos de asociación predeterminados se enumeran ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aquí"
            }), ", o puedes recuperar el valor de los tipos de asociación personalizados (es decir, etiquetas) a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar contactos por ID de registro, correo o propiedad de valor único personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes recuperar contactos individualmente o en lotes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para obtener un registro de contacto individual, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{contactId} or"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{email}?idProperty=email"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para solicitar una lista de todos los registros de contactos, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En estos puntos de terminación, puedes incluir los siguientes parámetros de consulta en la URL de la solicitud:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades que se devolverán en la respuesta. Si el registro de contacto solicitado no tiene un valor para una propiedad, este no aparecerá en la respuesta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades actuales e históricas que se devolverán en la respuesta. Si el registro de contacto solicitado no tiene un valor para una propiedad, este no aparecerá en la respuesta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista separada por comas de los objetos de los cuales recuperar los ID asociados. No se devolverán en la respuesta las asociaciones especificadas que no existan. Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de asociaciones."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para obtener un lote de contactos específicos por ID de registro, dirección de correo electrónico o una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "propiedad de identificador único personalizada"
        }), ", haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/contacts/batch/read"
        }), ". El punto de terminación por lote ", (0, _jsxRuntime.jsx)("u", {
          children: "no puede"
        }), " recuperar asociaciones. Obtener más información sobre cómo leer asociaciones por lotes con la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "API de asociaciones"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para el punto de terminación de lectura por lotes puedes usar el parámetro opcional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " para obtener contactos por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " o por una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propiedad de identificador único"
      }), " personalizada. De forma predeterminada, los valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la solicitud se refieren al ID de registro (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), por lo que no se requiere el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " al recuperar por ID de registro. Si estás utilizando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " o una propiedad de valor único personalizada para obtener contactos, debes incluir el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, para recuperar un lote de contactos en función de los valores de ID de registro, tu solicitud podría verse así (solo valores actuales o valores actuales e históricos):"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body with record ID (current values)\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"inputs\": [\n    {\n      \"id\": \"1234567\"\n    },\n    {\n      \"id\": \"987456\"\n    }\n  ]\n}\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"lifecyclestage\", \"hs_lead_status\"],\n  \"inputs\": [\n    {\n      \"id\": \"1234567\"\n    },\n    {\n      \"id\": \"987456\"\n    }\n  ]\n}\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para recuperar contactos en función de la dirección de correo electrónico o una propiedad de identificador único personalizado (por ejemplo, un número de identificación de cliente único para tu negocio), tu solicitud sería la siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with email address\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"idProperty\": \"email\",\n  \"inputs\": [\n    {\n      \"id\": \"lgilmore@thedragonfly.com\"\n    },\n    {\n      \"id\": \"sstjames@thedragonfly.com\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with a unique value property\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"idProperty\": \"internalcustomerid\",\n  \"inputs\": [\n    {\n      \"id\": \"12345\"\n    },\n    {\n      \"id\": \"67891\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar registros de contacto"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes actualizar registros de contacto individualmente o en lotes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para actualizar registros de contactos ", (0, _jsxRuntime.jsx)("u", {
        children: "individuales"
      }), ", puedes usar el ID del registro (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ") o la dirección de correo electrónico del contacto (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para actualizar un registro de contacto individual por su ID de registro, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{contactId}"
        }), " e incluye los datos que quieres actualizar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para actualizar un registro de contacto individual por su correo electrónico, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " a", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{email}?idProperty=email"
        }), " e incluye los datos que quieres actualizar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with record ID\n// PATCH /crm/v3/objects/contacts/123456789\n{\n  \"properties\": {\n    \"favorite_food\": \"burger\",\n    \"jobtitle\": \"Manager\",\n    \"lifecyclestage\": \"Customer\"\n  }\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with email address\n// PATCH /crm/v3/objects/contacts/lgilmore@thedragonfly.com?idProperty=email\n{\n  \"properties\": {\n    \"favorite_food\": \"Donut\",\n    \"jobtitle\": \"Owner\",\n    \"phone\": \"5555555555\"\n  }\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para actualizar registros de contactos por ", (0, _jsxRuntime.jsx)("u", {
        children: "lotes"
      }), ", puedes utilizar los valores de ID de registro de los contactos (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "). Para actualizar varios registros de contactos, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/batch/update"
      }), " En el cuerpo de la solicitud, incluye el ID de registro de cada contacto como el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " e incluye las propiedades que quieres actualizar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n// POST /crm/v3/objects/contacts/batch/update\n{\n  \"inputs\": [\n    {\n      \"id\": \"123456789\",\n      \"properties\": {\n        \"favorite_food\": \"burger\"\n      }\n    },\n    {\n      \"id\": \"56789123\",\n      \"properties\": {\n        \"favorite_food\": \"Donut\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociar registros de contacto existentes con registros o actividades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asociar un registro de contacto con otros registros del CRM o con una actividad, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para recuperar el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), ", consulta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "esta lista"
        }), " de valores predeterminados o haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de asociaciones."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eliminar una asociación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una asociación entre un registro de contacto y un registro o actividad, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " en la siguiente URL: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactID}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anclar una actividad en un registro de contacto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "anclar una actividad"
      }), " en un registro de contacto incluyendo el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " en tu solicitud. En el campo, incluye el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la actividad a anclar, que se puede recuperar a través de las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API de interacciones"
      }), ". Puedes anclar una actividad por registro, y la actividad ya debe estar asociada con el contacto antes de anclarla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para definir o actualizar la actividad anclada de un contacto, tu solicitud podría ser la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body PATCH /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "También puedes crear un registro de contacto, asociarlo a una actividad existente y anclar la actividad en la misma solicitud. Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body POST /crm/v3/objects/contacts\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 201\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar contactos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes eliminar registros de contacto individualmente o por lotes, lo que agregará el registro de contacto a la papelera de reciclaje en HubSpot. Más adelante puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "restaurar el registro de contacto dentro de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar un registro de contacto individual por su ID, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la eliminación de registros de contacto por lotes en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las operaciones por lotes están limitadas a 100 registros a la vez. Por ejemplo, no puedes actualizar por lotes más de 100 registros de contactos en una solicitud. También hay límites para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/api/faq#limits_contacts",
        children: "contactos y envíos de formularios"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}