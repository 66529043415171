"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611085;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/overview';
const title = exports.title = 'Serverless Functions Overview';
const name = exports.name = 'Serverless Overview';
const metaDescription = exports.metaDescription = 'Serverless functions are written in JavaScript and use the NodeJS runtime.  Use them to add new and advanced capabilities to HubSpot CMS websites.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "examples",
  "label": "Examples",
  "parent": null
}, {
  "depth": 0,
  "id": "limits",
  "label": "Limits",
  "parent": null
}, {
  "depth": 1,
  "id": "execution-limits",
  "label": "Execution limits",
  "parent": "limits"
}, {
  "depth": 1,
  "id": "split-dependencies",
  "label": "Split dependencies",
  "parent": "limits"
}, {
  "depth": 0,
  "id": "accessing-serverless-functions",
  "label": "Accessing serverless functions",
  "parent": null
}, {
  "depth": 1,
  "id": "serverless-function-folders",
  "label": "Serverless function folders",
  "parent": "accessing-serverless-functions"
}, {
  "depth": 1,
  "id": "serverless.json",
  "label": "Serverless.json",
  "parent": "accessing-serverless-functions"
}, {
  "depth": 1,
  "id": "function.js",
  "label": "Function.js",
  "parent": "accessing-serverless-functions"
}, {
  "depth": 0,
  "id": "secrets",
  "label": "Secrets",
  "parent": null
}, {
  "depth": 0,
  "id": "viewing-serverless-function-logs",
  "label": "Viewing serverless function logs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Serverless functions"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you're building a serverless function as a part of a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "developer project"
        }), ", visit the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "developer projects serverless function documentation"
        }), " instead. The documentation below is for building serverless functions outside of the developer project platform."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Serverless functions provide a way to write server-side code that interacts with HubSpot and third-party services through ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "APIs"
      }), ". APIs requiring authentication are not safe for the front-end of a website, as your credentials would be exposed. Serverless functions can act as an intermediary, enabling you to keep credentials secret."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With serverless functions, you don’t need to spin up and manage new servers. Serverless functions require less overhead and as a result they are easier to scale as a business grows."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can experiment with serverless functions using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "CMS developer sandbox account"
      }), ". To build your first serverless function, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
        children: "getting started with serverless functions guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Examples"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "The list of things you can use HubSpot serverless functions for is up to your imagination. You could use them for:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Collecting data and storing it in HubDB or the HubSpot CRM"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Complex data calculators"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Dynamically displaying data from other systems"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/cms/overview",
                children: "Event registration systems"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Form submissions that send data to other systems"
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172853952400"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using the event registration system example, you could use serverless functions to handle registration and update how many open slots there are for an event. The flow would work as follows:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The website visitor navigates to your event registration page, showing there is room for 15 more people to attend. The visitor fills out a custom form to sign up for the event, and submits."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["That submission we've set to send a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yourwebsite.com/_hcms/api/event/participants"
        }), ". ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "event/participants"
        }), " is your serverless function."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Your serverless function receives the user submitted data and takes a few actions before returning a response to the browser:"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Submits the form field data to the HubSpot submit form API to add this form submission information to the HubSpot CRM."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Uses the HubDB api, to subtract 1 from the participant count for this event which is stored in HubDB."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sends a response back to the web browser."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Javascript in the page receives the response from the serverless function and displays a confirmation message to the end-user, and adjusting the count of how many slots are left for participants."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot’s serverless functions are written in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
        children: "JavaScript"
      }), " and use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/about/",
        children: "NodeJS"
      }), " runtime. HubSpot's serverless functions are intended to be used to add functionality to your HubSpot site, such as supporting advanced form submissions and pulling in data from other APIs. It’s not intended as a generic computing platform where you would run code unrelated to HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limits"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Serverless functions are intended to be fast and have a narrow focus. That speed enables them to be perfect companions to the front-end of websites and apps, enabling a quick call and response. To maintain performance, HubSpot serverless functions are limited to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "50 secrets per account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128MB of memory."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "no more than 100 endpoints per HubSpot account."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["the contentType ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), " when calling a function."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6MB per invocation payload, which you might encounter when trying to upload a file with a serverless function, for example."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "4KB for the amount of data that can be logged. When hitting this limit, it's recommended to log after individual actions, rather than the final output."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Execution limits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Each function has a maximum of 10 seconds of execution time."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Each account is limited to 600 total execution seconds per minute."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This means either of these scenarios can happen within one minute:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Up to 60 function executions that take 10 seconds each to complete."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Up to 6,000 function executions that take 100 milliseconds to complete."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Functions that exceed those limits will throw an error. Execution count and time limits will return a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " response. The execution time of each function is included in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#viewing-serverless-function-logs",
        children: "serverless function logs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Split dependencies"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Serverless functions don't support splitting JavaScript between multiple files when deployed. Instead, your serverless function must include one JavaScript file to execute the function. If you're building a serverless function with multiple JavaScript files, you should instead copy the shared code into the one JavaScript file, or use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), " to bundle your code. Learn more about using webpack as a solution on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/Import-packages-in-serverless-functions/m-p/346620",
        children: "HubSpot's Community"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Accessing serverless functions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot, serverless functions is stored in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/overview#developer-file-system",
        children: "developer file system"
      }), ", visible in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/a-quick-tour-of-the-design-manager",
        children: "design manager"
      }), ". You can access and edit your serverless functions locally through the CLI."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For including serverless functions in a developer project, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "JavaScript building blocks documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Serverless function folders"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["HubSpot Serverless functions live inside a functions folder. This folder can be named anything, but must contain the suffix ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".functions"
            }), ". Files stored in this folder are not publicly accessible."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Within the functions folder, include your ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), " file along with a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#function-js",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: ".js"
              })
            }), " file which contains your functions. You might consider adding a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#readme-md",
              children: "README"
            }), " markdown file to communicate what the functions are for, how they work, and if you have a build process to author them."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-03%20at%208.24.31%20AM.png",
            alt: "Serverless .functions folder"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To prevent accidental edits from within the design manager, you can lock your folder. To lock a folder, navigate to the design manager, then right-click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "folder"
        }), " and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lock folder"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Serverless.json"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " serverless functions included in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "developer projects"
        }), " have been updated as of platform version ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2023.2"
        }), ", including a new ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " schema. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/platform-versioning",
          children: "project platform versioning"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#serverless-json",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        })
      }), " is the serverless function's config file which specifies the runtime environment and any ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "environment variables"
      }), " you plan to use in your functions."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This file also handles the routing of your endpoints. You specify the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#endpoints",
        children: "endpoint"
      }), " paths you want to map to your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#function-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "function.js"
        })
      }), " files. For an example of what your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " file should look like, view the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#serverless-json",
        children: "serverless functions reference guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Function.js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Your actual serverless function can be named anything as long as it is a ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/serverless-functions#function-file",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: ".js"
        }), " file"]
      }), ". For your serverless function to work, it must be mapped to an endpoint ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/serverless-functions#serverless-json",
        children: ["defined in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " file"]
      }), ". For troubleshooting purposes, it's recommended to name the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".js"
      }), " file similarly to your endpoint name in your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " configuration file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secrets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When authenticating a call made by a serverless function, you should use secrets to store API keys, private app access tokens, and other authentication information for security. This will allow for authentication without exposing your key or access token."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create and manage secrets, you can use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#serverless-function-commands",
        children: "HubSpot CLI commands"
      }), ", such as:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add"
        }), " to create a new secret."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets list"
        }), " to view your currently available secrets by name."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets update"
        }), " to update an existing secret."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once added through the CLI, they can be made available to functions by including a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), " array containing the name of the secret. This enables you to store your function code in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "version control"
      }), " and use secrets without exposing them. However, you should ", (0, _jsxRuntime.jsx)("u", {
        children: "never"
      }), " return your secret's value through console logging or as a response, as this will expose the secret in logs or in front-end pages that call your serverless function."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " due to caching, it can take about one minute to see updated secret values. If you've just updated a secret but are still seeing the old value, check again after about a minute."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Viewing serverless function logs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To help with troubleshooting your serverless functions, the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), " has an ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        }), " command"]
      }), " which gives you the ability to view your function’s logs. In addition to individual function invocation responses, time of execution, and execution time, any ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), " statement will also appear in function logs. Do not ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), " secrets like API keys."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}