'use es6';

import CodeMirror from 'codemirror';
import { LexRules, ParseRules, isIgnored, onlineParser } from "../internal/graphql-language-service-parser";
CodeMirror.defineMode('graphql', config => {
  const parser = onlineParser({
    eatWhitespace: stream => stream.eatWhile(isIgnored),
    lexRules: LexRules,
    parseRules: ParseRules,
    editorConfig: {
      tabSize: config.tabSize
    }
  });
  return {
    config,
    startState: parser.startState,
    token: parser.token,
    indent,
    electricInput: /^\s*[})\]]/,
    fold: 'brace',
    lineComment: '#',
    closeBrackets: {
      pairs: '()[]{}""',
      explode: '()[]{}'
    }
  };
});
function indent(state, textAfter) {
  var _a, _b;
  const levels = state.levels;
  const level = !levels || levels.length === 0 ? state.indentLevel : levels[levels.length - 1] - (((_a = this.electricInput) === null || _a === void 0 ? void 0 : _a.test(textAfter)) ? 1 : 0);
  return (level || 0) * (((_b = this.config) === null || _b === void 0 ? void 0 : _b.indentUnit) || 0);
}