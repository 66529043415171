"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937504;
const slug = exports.slug = 'guides/api/crm/objects/companies';
const title = exports.title = 'API de CRM | Empresas';
const name = exports.name = 'vNext Docs DP - Empresas';
const metaDescription = exports.metaDescription = 'Los registros de empresa almacenan datos sobre las empresas. Los puntos finales de las empresas te permiten administrar estos datos y sincronizarlos entre HubSpot y otros sistemas. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-empresas",
  "label": "Crear empresas",
  "parent": null
}, {
  "depth": 1,
  "id": "propiedades",
  "label": "Propiedades",
  "parent": "crear-empresas"
}, {
  "depth": 1,
  "id": "asociaciones",
  "label": "Asociaciones",
  "parent": "crear-empresas"
}, {
  "depth": 0,
  "id": "recuperar-registros-de-empresa",
  "label": "Recuperar registros de empresa",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-empresas",
  "label": "Actualizar empresas",
  "parent": null
}, {
  "depth": 1,
  "id": "asociar-empresas-existentes-con-registros-y-actividades",
  "label": "Asociar empresas existentes con registros y actividades",
  "parent": "actualizar-empresas"
}, {
  "depth": 1,
  "id": "eliminar-una-asociaci%C3%B3n",
  "label": "Eliminar una asociación",
  "parent": "actualizar-empresas"
}, {
  "depth": 0,
  "id": "anclar-una-actividad-en-un-registro-de-empresa",
  "label": "Anclar una actividad en un registro de empresa",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-empresas",
  "label": "Eliminar empresas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Empresas"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En HubSpot, los registros de empresa almacenan información sobre las organizaciones que interactúan con tu empresa. Los puntos de terminación de las empresas te permiten crear y administrar registros de empresa, así como sincronizar los datos de los registros de empresa entre HubSpot y otros sistemas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre las API de objetos, registros, propiedades y asociaciones en la guía ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Comprender el CRM"
      }), ". Para obtener más información general sobre objetos y registros de HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "aprende a administrar la base de datos del CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear empresas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear nuevas empresas, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En tu solicitud, incluye los datos del registro de empresa en el objeto propiedades. También puedes agregar el objeto asociaciones para asociar el nuevo registro de empresa con registros existentes (por ejemplo, contactos, negocios) o actividades (por ejemplo, reuniones, notas)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los detalles de la empresa se almacenan en las propiedades de empresa. Hay propiedades de empresa de HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
        children: "predeterminadas"
      }), ", pero también puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "crear propiedades personalizadas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear un nuevo registro de empresa, debes incluir ", (0, _jsxRuntime.jsx)("u", {
        children: "al menos una"
      }), " de las siguientes propiedades en tu solicitud: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ". Se recomienda incluir siempre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ", porque los nombres de dominio son el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/deduplication-of-contacts-companies-deals-tickets#automatic-deduplication-in-hubspot",
        children: "identificador único principal"
      }), " para evitar la duplicación de registros de empresa en HubSpot. Si una empresa tiene ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/companies/add-multiple-domain-names-to-a-company-record",
        children: "varios dominios"
      }), ", puedes agregarlos a través de la API utilizando el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_additional_domains"
      }), " con punto y coma que separa cada dominio. Por ejemplo:", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"hs_additional_domains\" : \"domain.com; domain2.com; domain3.com\""
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver todas las propiedades disponibles, puedes recuperar una lista de las propiedades de empresa de tu cuenta haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/companies"
      }), ". Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API de propiedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si has incluido ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lifecyclestage"
        }), " en tu solicitud, los valores deben referirse al nombre interno de la etapa del ciclo de vida. Los nombres internos de las etapas predeterminadas son valores de texto y no cambian aunque edites la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties#:~:text=the%20properties%20settings.-,Label/Name%3A,-enter%20a%20unique",
          children: "etiqueta"
        }), " de la etapa (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriber"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketingqualifiedlead"
        }), "). Los nombres internos de las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages",
          children: "etapas personalizadas"
        }), " son valores numéricos. Puedes encontrar el ID interno de una etapa en la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages#:~:text=To%20edit%20a%20lifecycle%20stage%2C%20hover%20over%20the%20stage%20and%20click%20Edit.%20In%20the%20right%20panel%2C%20edit%20the%20Stage%20name%2C%20then%20click%20Edit%20lifecycle%20stage%20to%20confirm.%20Click%20the%20code%20codcode%20icon%20to%20view%20the%20stage%27s%20internal%20ID%2C%20which%20is%20used%20by%20integrations%20and%20APIs.",
          children: "configuración de la etapa del ciclo de vida"
        }), " o recuperando la propiedad de la etapa del ciclo de vida a través de la API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, para crear un nuevo registro de empresa, tu solicitud puede ser similar a la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"name\": \"HubSpot\",\n    \"domain\": \"hubspot.com\",\n    \"city\": \"Cambridge\",\n    \"industry\": \"Technology\",\n    \"phone\": \"555-555-555\",\n    \"state\": \"Massachusetts\",\n    \"lifecyclestage\": \"51439524\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear un nuevo registro de empresa, también puedes asociarlo con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "registros"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "actividades"
      }), " existentes en un objeto asociaciones. Por ejemplo, para asociar un nuevo registro de empresa con un registro de contacto y correo electrónico existentes, tu solicitud se vería de la siguiente manera:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"name\": \"HubSpot\",\n    \"domain\": \"hubspot.com\",\n    \"city\": \"Cambridge\",\n    \"industry\": \"Technology\",\n    \"phone\": \"555-555-555\",\n    \"state\": \"Massachusetts\",\n    \"lifecyclestage\": \"51439524\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 101\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 280\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 556677\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 185\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el objeto asociaciones, debes incluir lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El registro o actividad que deseas asociar con el registro de empresa, especificado por su valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " único."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de asociación entre la empresa y el registro o actividad. Incluye ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Los ID de los tipos de asociación predeterminados se enumeran ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aquí"
            }), ", o puedes recuperar el valor de los tipos de asociación personalizados (es decir, etiquetas) a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar registros de empresa"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes recuperar registros de empresa individualmente o en lotes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar un registro de empresa individual, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/companies/{companyId}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para solicitar una lista de todas las empresas, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/companies"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En estos puntos de terminación, puedes incluir los siguientes parámetros de consulta en la URL de la solicitud:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades que se devolverán en la respuesta. Si el registro de empresa solicitado no tiene un valor para una propiedad, no aparecerá en la respuesta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades actuales e históricas que se devolverán en la respuesta. Si el registro de empresa solicitado no tiene un valor para una propiedad, no aparecerá en la respuesta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista separada por comas de los objetos de los cuales recuperar los ID asociados. No se devolverán en la respuesta las asociaciones especificadas que no existan. Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de asociaciones."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar un lote de empresas específicas por ID de registro o una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "propiedad de identificador único personalizado"
        }), ", haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/companies/batch/read"
        }), ". El punto de terminación por lote ", (0, _jsxRuntime.jsx)("u", {
          children: "no puede"
        }), " recuperar asociaciones. Obtener más información sobre cómo leer asociaciones por lotes con la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "API de asociaciones"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para el punto de terminación de lectura por lotes, también puedes utilizar el parámetro opcional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " para recuperar empresas mediante una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propiedad de identificador único"
      }), " personalizado. De forma predeterminada, los valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la solicitud se refieren al ID de registro (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), por lo que no se requiere el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " al recuperar por ID de registro. Para utilizar una propiedad de valor único personalizada para recuperar empresas, debes incluir el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, para recuperar un lote de empresas, tu solicitud podría ser cualquiera de las siguientes:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"name\", \"domain\"],\n  \"inputs\": [\n    {\n      \"id\": \"56789\"\n    },\n    {\n      \"id\": \"23456\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"name\", \"domain\"],\n  \"idProperty\": \"uniquepropertyexample\",\n  \"inputs\": [\n    {\n      \"id\": \"abc\"\n    },\n    {\n      \"id\": \"def\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para recuperar empresas con valores actuales e históricos para una propiedad, tu solicitud podría ser la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"name\"],\n  \"inputs\": [\n    {\n      \"id\": \"56789\"\n    },\n    {\n      \"id\": \"23456\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar empresas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes actualizar los registros de empresa individualmente o por lotes. Para los registros de empresa existentes, el ID de la empresa es un valor único que puedes usar para actualizar el registro de empresa a través de la API."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para actualizar una empresa individual por su ID de empresa, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}"
      }), " e incluye los datos que deseas actualizar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociar empresas existentes con registros y actividades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asociar un registro de empresa con otros registros del CRM o con una actividad, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para recuperar el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), ", consulta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "esta lista"
        }), " de valores predeterminados o haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre cómo asociar registros a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de asociaciones"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eliminar una asociación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una asociación entre un registro de empresa y un registro o actividad, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a la siguiente URL: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anclar una actividad en un registro de empresa"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes anclar una actividad en un registro de empresa a través de la API incluyendo el campo en tu solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), ". En el campo, incluye la ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la actividad a anclar, que se puede recuperar a través de las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API de interacciones"
      }), ". Puedes anclar una actividad por registro, y la actividad ya debe estar asociada con la empresa antes de anclarla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para establecer o actualizar la actividad anclada de una empresa, tu solicitud podría ser la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/companies/{companyId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "También puedes crear una empresa, asociarla a una actividad existente y anclar la actividad en la misma solicitud. Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/companies\n{\n  \"properties\": {\n    \"domain\": \"example.com\",\n    \"name\": \"Example Company\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 189\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar empresas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes eliminar registros de empresa individualmente o por lotes, lo que agregará el registro de empresa a la papelera de reciclaje en HubSpot. Más tarde puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "restaurar el registro de empresa dentro de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una empresa individual por su ID, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la eliminación de registros de empresa por lotes en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}