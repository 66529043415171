"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694139;
const slug = exports.slug = 'guides/cms/content/overview';
const title = exports.title = 'CMS building blocks overview';
const name = exports.name = 'CMS building blocks overview';
const metaDescription = exports.metaDescription = 'Developers can use a series of building blocks to create websites on the HubSpot CMS.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "themes",
  "label": "Themes",
  "parent": null
}, {
  "depth": 0,
  "id": "templates",
  "label": "Templates",
  "parent": null
}, {
  "depth": 0,
  "id": "modules",
  "label": "Modules",
  "parent": null
}, {
  "depth": 0,
  "id": "module-and-theme-fields",
  "label": "Module and Theme Fields",
  "parent": null
}, {
  "depth": 0,
  "id": "global-content",
  "label": "Global Content",
  "parent": null
}, {
  "depth": 0,
  "id": "menus-and-navigation",
  "label": "Menus and Navigation",
  "parent": null
}, {
  "depth": 0,
  "id": "forms",
  "label": "Forms",
  "parent": null
}, {
  "depth": 0,
  "id": "website-settings",
  "label": "Website Settings",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS building blocks overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Developers utilize a series of building blocks to create websites on HubSpot's CMS software. The building blocks can be used to create a design system for content creators to work within, with varying degrees of flexibility and guardrails depending on your business needs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Themes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Theme"
      }), " is a portable and contained package of developer created assets designed to work together to enable a marketer-friendly content editing experience. A theme is a top-level building block that define the look and feel of a website and create a marketer-friendly content-editing experience. A theme will include templates, modules, CSS files, JavaScript files, images, and more."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/cms-hierarchy-image-only.png",
        alt: "cms-hierarchy-image-only"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using themes, you can create a set of fields that content creators use to gain global stylistic control over a website without having to edit CSS. You can specify in CSS where these controls are applied, arrange controls to inherit from others, and manage how they are presented and organized to marketers in the Theme Editor. Content creators use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-fields",
        children: "theme editor"
      }), " to modify theme fields, preview those changes against existing templates within a theme, and publish their changes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "These theme fields can be set either globally across a site or overridden at a page level."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Vitality%20Theme%20Editor.png",
        alt: "Vitality Theme Editor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Templates"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Templates"
      }), " define the base markup and style of a set of pages that use a template. They can contain HubL, HTML, and links to JavaScript and CSS files, and can be restricted to use with specific content types. You have full flexibility in the markup and style you can include in a template, but you’re encouraged to adhere to a few best practices and use some key features to ensure marketers can edit pages easily. Some best practices include:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Building ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/templates/overview",
            children: "templates"
          }), " as part of a theme and using theme-level CSS, including theme fields, to do the majority of styling within a template. This’ll make it easy for content creators to make global and local style changes in a consistent way without needing to get into editing CSS."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Using ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/modules/overview",
            children: "modules"
          }), " for the majority of components on your page, which allows them to be rearranged and reused across a website. Learn more about modules below."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Using ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/templates/types/drag-and-drop-templates",
            children: "drag-and-drop areas"
          }), " where possible for core page content, especially on internal pages. Drag-and-drop areas let you set a default layout for the modules that comprise a page but give marketers flexibility to edit layout and style independently."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Using ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
            children: "global partials"
          }), " to contain shared content like headers and footers that you want to look consistent across a website."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Vitality%20Homepage%20Template%20html.png",
            alt: "Vitality Homepage Template html"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Modules"
      }), " are reusable components that you can place on templates and pages in the HubSpot CMS. They include controls for marketers and contain HubL/HTML markup, CSS, and JavaScript that together build reusable and editable components of a website."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The controls for a module are defined in fields, so building a great module means considering both the resulting appearance on a page, as well as the editing experience for content editors."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.em, {
        children: "HubSpot provides a set of"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "default modules"
      }), " like headers, rich text, images, buttons, and CTAs that you’ll use as fundamental components. You’ll also likely want to build out elements that can have more interesting layouts that fit into your theme and templates. Some common examples of modules you might want to build are accordions, sliders, and tabbers. ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Module%20form%20in%20editor.png",
        alt: "Module form in editor"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can think of a module as an object and modules on pages as instances of that object, meaning updates to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), ", CSS, or JavaScript of a module are applied across all instances of that module inside pages or templates in a given portal. This means you can implement a design solution once and use it across pages and portals, and when you need to update it, those changes can be applied across pages without having to edit multiple pages or multiple templates."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Modules may also be included in themes, which allows you to use theme fields to manipulate the look of modules and ensure they’re prominently displayed in the page editor so content creators can have easy access to modules that’ll look great with the designs you’ve built."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module and Theme Fields"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "NavigatFields"
      }), " are the controls that content creators use to adjust the parameters passed into your themes and modules. Fields are typed, including simple types like boolean, text, URL, choice, and file, but also have more complex fields like font with styling as well as HubSpot-specific fields like links to other pieces of content or forms in the HubSpot system."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fields can also be placed inside repeaters that’ll pass an array to the module—an example of this could be an image carousel where you want a set of images with associated `alt` text passed in. Rather than creating a number of image and text fields, you can create one of each and put them in a repeating group."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fields of a module are specified either inside the design manager or with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#the-user-interface-for-editing",
        children: "this syntax in a fields.json file."
      }), " Fields for a theme must be specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file at the root of the theme."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Global Content"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Global content"
      }), " allows markup and content to be globally shared across a website. Global content makes managing elements like headers, footers, sidebars or other global elements of a website simple."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menus and Navigation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/menus-and-navigation",
        children: "Menus"
      }), " allow you to easily build a navigation hierarchy and information architecture for navigating your website. Navigation menus"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Forms"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/forms",
        children: "Forms"
      }), " allow you to capture multiple types of information from users (such as address information, email addresses, and feedback) which you can then use throughout the HubSpot Ecosystem in areas such as smart content, workflows, content personalization, and more in your day-to-day operations."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Website Settings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/website-settings",
        children: "Website settings"
      }), " is a single place where various global and system-level settings can be configured for your website, such as your logo, favicon, system templates and more."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}