"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76633369011;
const slug = exports.slug = 'guides/cms/marketplace/theme-requirements';
const title = exports.title = 'Requisitos de temas del mercado de plantillas de HubSpot';
const name = exports.name = 'Mercado de plantillas | Requisitos de los temas (2022)';
const metaDescription = exports.metaDescription = 'Más información sobre los requisitos que un tema debe cumplir para poder enviarlo al mercado de materiales.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "l%C3%ADmites-del-tema",
  "label": "Límites del tema",
  "parent": null
}, {
  "depth": 0,
  "id": "requisitos-generales-de-los-temas",
  "label": "Requisitos generales de los temas",
  "parent": null
}, {
  "depth": 1,
  "id": "estructura-de-los-archivos-del-tema",
  "label": "Estructura de los archivos del tema",
  "parent": "requisitos-generales-de-los-temas"
}, {
  "depth": 1,
  "id": "rutas-de-archivo-locales-relativas-para-las-plantillas",
  "label": "Rutas de archivo locales relativas para las plantillas",
  "parent": "requisitos-generales-de-los-temas"
}, {
  "depth": 0,
  "id": "rendimiento-del-tema",
  "label": "Rendimiento del tema",
  "parent": null
}, {
  "depth": 1,
  "id": "vista-previa-de-url-de-temas",
  "label": "Vista previa de URL de temas",
  "parent": "rendimiento-del-tema"
}, {
  "depth": 0,
  "id": "usando-jquery",
  "label": "Usando jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "configuraci%C3%B3n-del-tema-theme.json",
  "label": "Configuración del tema (Theme.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "configuraci%C3%B3n-del-tema-fields.json",
  "label": "Configuración del tema (Fields.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "plantillas-css%2C-secciones%2C-plantillas-de-p%C3%A1gina%2C-etc.",
  "label": "Plantillas (CSS, Secciones, Plantillas de página, etc.)",
  "parent": null
}, {
  "depth": 1,
  "id": "secciones",
  "label": "Secciones",
  "parent": "plantillas-css%2C-secciones%2C-plantillas-de-p%C3%A1gina%2C-etc."
}, {
  "depth": 1,
  "id": "plantillas-de-p%C3%A1gina",
  "label": "Plantillas de página",
  "parent": "plantillas-css%2C-secciones%2C-plantillas-de-p%C3%A1gina%2C-etc."
}, {
  "depth": 1,
  "id": "denominaci%C3%B3n-de-plantillas-de-p%C3%A1gina",
  "label": "Denominación de plantillas de página",
  "parent": "plantillas-css%2C-secciones%2C-plantillas-de-p%C3%A1gina%2C-etc."
}, {
  "depth": 0,
  "id": "m%C3%B3dulos",
  "label": "Módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "contenido-global",
  "label": "Contenido global",
  "parent": null
}, {
  "depth": 1,
  "id": "parciales-globales",
  "label": "Parciales globales",
  "parent": "contenido-global"
}, {
  "depth": 0,
  "id": "compatibilidad-con-varios-idiomas",
  "label": "Compatibilidad con varios idiomas",
  "parent": null
}, {
  "depth": 0,
  "id": "elementos-m%C3%B3viles-y-adaptables",
  "label": "Elementos móviles y adaptables",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      Tabs,
      Tab,
      IFrame
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Requisitos de temas del mercado de plantillas de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Más información sobre los requisitos que debe cumplir un tema para enviarlo a mercado de plantillas de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si recién estás comenzando tu viaje de creación de temas, te recomendamos encarecidamente que utilices nuestro tema CMS gratuito, que se puede descargar desde nuestro ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Repositorio Git"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.youtube.com/watch",
        children: "importarlo desde la interfaz de usuario del administrador de diseño de tu cuenta"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además, revisa los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "requisitos de la página de índice de la plantilla"
      }), " cuando envíes tu plantilla al Mercado de plantillas de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites del tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ten en cuenta los siguientes límites por tema:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Las cuentas gratuitas ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "_CMS de Hub"
          }), " no pueden usar la búsqueda en el sitio, la funcionalidad de CTA o el video nativo de HubSpot. Más información sobre lo que se incluye con las suscripciones de ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "_CMS Hub"
          }), " en el ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
            children: "Catálogo de servicios y productos de HubSpot"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Los temas no pueden contener más de:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 plantillas"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 módulos"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 secciones"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Los temas ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "no deben"
          }), " contener:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Plantillas de correo electrónico"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Funcionalidad de HubDB"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Funciones sin servidor"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Campos de objetos del CRM"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/drag-and-drop-templates#flexible-columns",
              children: "Columnas flexibles en plantillas"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Módulos vs. secciones"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Las secciones son útiles porque los creadores de contenido solo pueden ubicarlas en zonas de colocación de ancho completo en la página, lo que te ayuda como desarrollador a garantizar un excelente producto terminado."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "En particular, las secciones de diseño fijo, en las que el creador de contenido no puede mover elementos dentro de la sección, son una gran herramienta para proporcionar un formato y un diseño creativos que de otro modo no se podrían lograr con el editor de arrastrar y soltar."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Las secciones también ofrecen beneficios de usabilidad adicionales para el creador de contenido, ya que puede seleccionar módulos individuales dentro de la sección, lo que hace que sus formularios de módulos sean más cortos y estén más orientados al elemento que está editando."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requisitos generales de los temas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Todos los temas enviados deben ser distintos y originales. Por ejemplo, el mismo tema con diferente contenido de texto o marcadores de posición no cuenta como un tema distinto. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/template-policies#template-marketplace-compliance",
          children: "Más información sobre el cumplimiento del Mercado de plantillas de HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Un tema debe construirse con plantillas HTML y HubL, y etiquetas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los temas deben respetar una cuadrícula de 12 columnas."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estructura de los archivos del tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos los temas deben contener una estructura de carpetas adecuada y estar agrupados en una carpeta principal, que debería describir el anuncio del producto. Por ejemplo, si estás construyendo un tema con nombre \"SuperAwesome\" para el mercado, tu estructura debe verse similar a la imagen de abajo. Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-file-structure",
        children: "estructura de archivos del tema"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/template-name.png",
          alt: "template-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/file-structure.png",
          alt: "file-structure"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Rutas de archivo locales relativas para las plantillas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al hacer referencia a los materiales del tema, ", (0, _jsxRuntime.jsx)("u", {
        children: "debes"
      }), " usar rutas de archivo locales relativas. La mejor manera de incluirlos es utilizar la función ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "get_asset_url"
      }), ", que devuelve la URL pública de un recurso, archivo o plantilla. También puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "generar esta función"
      }), " en la aplicación, bien haciendo clic con el botón derecho en un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "archivo"
      }), " y seleccionando ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copiar URL pública"
      }), ", o bien haciendo clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Acciones"
      }), " y seleccionando luego ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copiar URL pública"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, una hoja de estilo a la que hace referencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "get_asset_url"
      }), " debe tener el siguiente formato:"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{require_css(get_asset_url('../../css/main.css')) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "//cdn2.hubspot.net/hub/1234567/hub_generated/template_assets/1565970767575/custom/styles/style.min.css\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el siguiente video, revisa las diferencias en la estructura de archivos en tu cuenta de desarrollador en comparación con los archivos entregados a un cliente del mercado:"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SSOdhqOciuk?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rendimiento del tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al usar Google Lighthouse, un tema debe obtener una puntuación ", (0, _jsxRuntime.jsx)("u", {
        children: "superior"
      }), " a los siguientes umbrales:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Accesibilidad en equipos de escritorio:"
        }), " 65"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Prácticas recomendadas en equipos de escritorio:"
        }), " 80"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rendimiento en equipos de escritorio:"
        }), " 70"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rendimiento en dispositivos móviles:"
        }), " 40"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aprende a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#evaluate-themes-and-templates-for-seo-and-accessibility",
        children: "generar un informe de Google Lighthouse para el tema usando la CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El tamaño de los archivos de tema debe poder reducirse."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Todos los archivos de imagen deben tener menos de 1 MB de tamaño."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Todas las etiquetas de imagen deben tener un atributo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "alt"
        }), " (un valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), " es aceptable)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Todas las etiquetas de imagen deben tener un atributo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "loading"
        }), " (un valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), " es aceptable)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vista previa de URL de temas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Debes usar tu propio nombre de dominio al crear URL de vista previa. No puedes usar el dominio proporcionado por HubSpot con esta estructura de URL: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[AccountID].hs-sites.com"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se debe usar un sitio web en directo y no una imagen del sitio de demostración."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si en algún momento tu demo en directo se vuelve inaccesible, HubSpot se reserva el derecho, con notificación al proveedor, de eliminar/eliminar tu tema hasta que la demo en directo vuelva a ser accesible."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usando jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["jQuery ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/include-jquery-across-your-hubspot-pages",
        children: "no está habilitado de forma predeterminada "
      }), " en la cuenta de HubSpot de un cliente. Si el tema se basa en jQuery, se debe incluir una versión de jQuery para garantizar que el tema funcione como se espera."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, si incluyes un módulo que requiere jQuery cuando el resto del sitio no lo requiere, necesitas usar el siguiente código para cargar jQuery:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# this checks if the \"Include jQuery\" option in Settings > CMS > Pages is checked #}\n{% if not site_settings.include_jquery %}\n  {{ require_js(\"../jquery-3.4.1.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuración del tema (Theme.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " debe incluir los siguientes parámetros:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Revisa tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " y asegúrate de lo siguiente:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El nombre de la etiqueta coincide con el nombre que aparece en el anuncio de tu tema."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si estás utilizando el modelo de tema CMS gratuito de HubSpot, los valores de modelo no deben estar presentes. Esto incluye información del autor, URL de la documentación, URL de ejemplo, etc."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La URL de documentación resuelve y tiene documentación sobre cómo usar tu tema."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La ruta de vista previa es un archivo válido en su tema."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La ruta de la captura de pantalla es un archivo válido y está relacionada con tu tema."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La URL de ejemplo resuelve y conduce a una demostración de tu tema. No utilices subdominios ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        }), " para la URL de ejemplo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-json",
        children: "parámetros de theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuración del tema (Fields.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " controla los campos disponibles y los grupos de campos en el editor de temas, incluidos los campos de estilo. Los campos que incluyas dependerán del control que quieras que tengan los creadores de contenido en el editor de páginas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los archivos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " deben contener al menos tres campos de color."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para garantizar la compatibilidad entre los temas y los módulos independientes, los temas deben incluir las siguientes convenciones de nomenclatura estándar de fuente y color: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "heading_font"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "body_font"
        }), ". Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/beta-docs/guides/cms/marketplace/template-policies#module-compatibility-with-themes",
          children: "compatibilidad módulo-tema"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si los campos de tema no tienen campos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primary_color"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secondary_color"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "heading_font"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body_font"
      }), " pueden usar el campo", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alternate_names"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre estos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#fields-json",
        children: "parámetros de fields.json"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/fields.json",
        children: "revise un archivo de ejemplo fields.json"
      }), " de la plantilla de HubSpot CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La configuración del tema también debe:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No ser incompatible con los estilos del editor o los estilos establecidos mediante un módulo. Por ejemplo, no uses ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "!important"
        }), " en tu hoja de estilos CSS, ya que hace que sea difícil para los usuarios finales anularla y causaría un conflicto."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Usar etiquetas descriptivas para cada valor de configuración para que los creadores de contenido sepan lo que están actualizando."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Aplicarse a todas las plantillas del tema, a menos que haya un caso de uso específico para estilos adicionales. Por ejemplo, los cambios en el estilo y el tamaño de los títulos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " en la configuración del tema deben aplicarse a todas las etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " del tema."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como mínimo, un tema ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "debe"
      }), " incluir los siguientes campos:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Campos de tipografía:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Campos de fuente del texto del cuerpo (etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " a través ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h6"
        }), " de campos de fuente"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Color del hipervínculo (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "a"
        }), " etiquetas), incluido el estilo al pasar el cursor"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Campos de formulario:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fondo del título del formulario"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Texto del título, incluyendo al menos estilos de color de fuente del formulario"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Color de fondo del formulario"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Color del borde del formulario"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Color de etiqueta de formulario"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Color del borde del campo del formulario"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Botón de formulario -  esto incluye la configuración del texto del botón, el color de fondo y el estilo al pasar el cursor."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los campos que haya dentro del tema deben agruparse lógicamente donde corresponda. Por ejemplo, varios campos relacionados con la tipografía deben agruparse en un grupo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Typography"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los campos de tema deben tener controles de color y fuente separados para los botones y formularios, así como controles de color, logotipo y fuente separados para el encabezado y el pie de página."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Una parte de los campos de color y logotipo del tema debe heredarse de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/brand-and-settings-inheritance",
          children: "configuración de marca de la cuenta"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Como mínimo, dos campos de color deben heredar colores de la configuración de marca de la cuenta. Los campos de color adicionales pueden tener otros colores de forma predeterminada, incluidos el blanco y el negro."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si los módulos dentro de un tema usan logotipos, por lo menos un campo de logotipo debe heredar de la configuración de marca de la cuenta. Si se utiliza un campo de imagen para representar un logotipo, el campo de imagen no tiene que heredar de la configuración de la marca."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Cómo afectan los colores de la marca a la estética de tu tema"
      })
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/voyJupcZYeQ?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Plantillas (CSS, Secciones, Plantillas de página, etc.)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Secciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Debes usar las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections#reusable-sections",
          children: "secciones"
        }), " donde corresponda. ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Debe"
        }), " haber un mínimo de cinco secciones en un tema."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Las secciones deben tener capturas de pantalla únicas y de funcionamiento."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Las secciones y los módulos no deben ser redundantes."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Plantillas de página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como mínimo, los temas ", (0, _jsxRuntime.jsx)("u", {
        children: "deben"
      }), " incluir los siguientes tipos de plantillas:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Una plantilla de página de sitio web o una plantilla de página de destino."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Al incluir varias plantillas de página, cada plantilla debe tener un propósito diferente. Por ejemplo, una página de inicio, una página ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Acerca de nosotros"
            }), ", una página de destino de ancho completo y una página de destino con una barra lateral derecha."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se recomienda incluir al menos ocho plantillas de página en un tema."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Separa las plantillas de anuncio de blog y publicación de blog."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Plantilla de anuncio de blog:"
            }), " la página que muestra todas las publicaciones de blog en un formato de lista (conocido como blogroll). El título de la plantilla debe reflejar que es para la página del anuncio."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Plantilla de publicación de blog:"
            }), " la página de detalles de publicaciones de blog que muestra publicaciones de blog individuales. El título de la plantilla debe reflejar que es para la página de publicación del blog."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Además, el diseño de los comentarios del blog y de las casillas de los autores del blog debe corresponderse con el tema."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Las siguientes plantillas de página del sistema:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Plantilla de error 404"
            }), ": se muestra cuando los visitantes acceden a una página que no existe."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Plantilla de error 500"
            }), ": se muestra cuando el sitio encuentra un error interno."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Plantilla de solicitud de contraseña"
            }), ": se muestra cuando una página está protegida con contraseña."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Plantilla de suscripción"
            }), ": página de preferencias de suscripción donde los destinatarios de correo electrónico pueden administrar los tipos de correos electrónicos a los que se suscriben."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Plantilla de actualización de suscripciones"
            }), ": página de confirmación que aparece cuando un destinatario de correo electrónico actualiza sus preferencias de suscripción a los correos electrónicos."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Respaldo de plantilla para anular suscripción"
            }), ": la página que aparece para destinatarios de correos electrónicos que intentan cancelar la suscripción, si HubSpot no puede determinar la dirección de correo electrónico."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Plantilla de resultados de búsqueda"
            }), ": muestra los resultados de búsqueda que se devuelven al usar la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/content-search",
              children: "búsqueda en el sitio"
            }), ". Disponible solo para cuentas de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "CMS Hub"
            }), "** de pago."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Denominación de plantillas de página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si tienes plantillas con nombres similares, agrega palabras descriptivas que denoten la diferencia entre ellas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Usa las mayúsculas uniformemente, elimina los guiones y evita el uso de abreviaturas (por ejemplo, deletrear «fondo» en lugar de usar »fon»)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "No es necesario incluir el nombre de su empresa o el nombre de tu tema en el nombre de la plantilla."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre los requisitos para los módulos de tema y los módulos individuales ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/module-requirements",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contenido global"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Parciales globales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "parciales globales"
      }), " son un tipo de plantilla creada con HTML y HubL que se puede reutilizar en todo el sitio web. Los tipos de parciales más comunes son los encabezados, las barras laterales y los pies de página. Descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#creating-a-global-content-partial-template-using-local-development-tools",
        children: "crear parciales globales"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los temas ", (0, _jsxRuntime.jsx)("u", {
          children: "deben"
        }), " incluir particiones globales."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los parciales globales ", (0, _jsxRuntime.jsx)("u", {
          children: "deben"
        }), " incluir un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "área útil de arrastrar y soltar"
        }), ". Por ejemplo, no puedes ocultar el área de arrastrar y soltar con una clase \"ocultar\"."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)("u", {
          children: "Debes"
        }), " incorporar áreas de arrastrar y soltar que se puedan usar en encabezados\ny pies de página."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para los menús que se utilizan globalmente, los usuarios también deben poder ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/menus-and-navigation",
          children: "seleccionar un menú de navegación de HubSpot"
        }), " que hayan creado en la configuración de su cuenta."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": evita incluir módulos globales dentro de los parciales globales, ya que se puede crear una experiencia negativa para el usuario final."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Compatibilidad con varios idiomas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los temas ", (0, _jsxRuntime.jsx)("u", {
        children: "deben"
      }), " poder admitir versiones en varios idiomas, y deben especificar los idiomas que admiten. Esto se puede hacer utilizando el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#language-switcher",
        children: "módulo de selector de idioma"
      }), " en un encabezado global, que permite a los clientes localizar fácilmente las opciones de idioma y elegir el idioma que deseen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Solo ", (0, _jsxRuntime.jsx)("u", {
          children: "debes"
        }), " mostrar un idioma a la vez. Por ejemplo, evita tener inglés y español en la interfaz de usuario al mismo tiempo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Evitar el uso de texto codificado. Por ejemplo, en lugar de codificar el texto de un botón de contenido de blog como ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Leer más"
        }), ", establece el texto dentro de un campo para que el usuario final pueda actualizar el texto sin tener que ir al código."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Elementos móviles y adaptables"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los temas deben ser capaces de adaptar su contenido al dispositivo en el que se están viendo. También deben proporcionar una buena experiencia de usuario en varios dispositivos. Esto incluye, pero no está limitado a:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Navegación principal"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Controles deslizantes y pestañas"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Imágenes grandes"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Evitar el desplazamiento horizontal (a menos que sea intencional)"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}