"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158721871211;
const slug = exports.slug = 'getting-started/what-to-build';
const title = exports.title = 'O que criar';
const name = exports.name = 'O que criar (documentos do desenvolvedor 2024)';
const metaDescription = exports.metaDescription = 'Saiba sobre as diferentes maneiras de desenvolver no HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "usar-apis-e-criar-integra%C3%A7%C3%B5es",
  "label": "Usar APIs e criar integrações",
  "parent": null
}, {
  "depth": 1,
  "id": "come%C3%A7ar-a-autenticar-chamadas-com-um-aplicativo-privado",
  "label": "Começar a autenticar chamadas com um aplicativo privado",
  "parent": "usar-apis-e-criar-integra%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "criar-para-o-marketplace-de-aplicativos",
  "label": "Criar para o marketplace de aplicativos",
  "parent": "usar-apis-e-criar-integra%C3%A7%C3%B5es"
}, {
  "depth": 0,
  "id": "personalizar-a-interface-do-hubspot",
  "label": "Personalizar a interface do HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-sites-personalizados",
  "label": "Criar sites personalizados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      strong: "strong",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "O que criar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existem várias rotas de desenvolvimento possíveis com o HubSpot, dependendo dos seus objetivos. Você encontrará uma visão geral de alto nível de cada caminho nesta página, juntamente com as ferramentas e as contas necessárias para cada um deles. Você também encontrará links para documentos detalhados para fornecer mais contexto, bem como a guias de início rápido relevantes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#use-apis-and-build-integrations",
          children: "Usar APIs e criar integrações"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#get-started-authenticating-calls-with-a-private-app",
              children: "Começar a autenticar chamadas com um aplicativo privado"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#build-for-the-app-marketplace",
              children: "Criar para o marketplace de aplicativos"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#customize-the-hubspot-ui",
          children: "Personalizar a interface do HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-custom-websites",
          children: "Criar sites personalizados"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar APIs e criar integrações"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use as APIs da HubSpot para criar soluções personalizadas, como compartilhar dados entre o HubSpot e sistemas externos, usar webhooks para monitorar alterações na conta e criar objetos personalizados para armazenar dados específicos da sua empresa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplos de casos de uso:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use objetos personalizados para personalizar a forma como o CRM armazena dados para que representem melhor sua empresa."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sincronize dados de sistemas externos para fornecer uma imagem mais rica das atividades de entrada no mercado."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Amplie os recursos de interface do CRM para melhor atender aos seus processos."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Duas maneiras de criar aplicativos"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Existem dois ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "tipos de aplicativos"
        }), ", dependendo do que você está criando. Se você está começando com APIs e integrações, é recomendado iniciar com um aplicativo privado, pois eles são mais rápidos para configurar e usar em termos de autenticação. Depois de se familiarizar com a construção de aplicativos, saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-for-the-app-marketplace",
          children: "aplicativos públicos"
        }), ", que podem ser instalados em várias contas e permitem construir outros tipos de extensões."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exemplo de caso de uso para aplicativos privados:"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "“Quero criar um aplicativo para minha empresa/equipe.”"
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Começar a autenticar chamadas com um aplicativo privado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A maioria das chamadas de API exige autenticação para interagir com os dados da sua conta da HubSpot. Para começar a fazer chamadas para sua conta, crie um aplicativo privado e use seu token de acesso para autenticação."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " permissões de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "Superadministrador"
        }), " são necessárias para criar aplicativos privados em sua conta da HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como começar:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Criar um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "aplicativo privado"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
          children: "fazer chamadas de API autenticadas com aplicativos privados"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você também pode usar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/overview",
          children: "bibliotecas de cliente"
        }), " para fazer chamadas de API"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar para o marketplace de aplicativos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Desenvolva integrações para o Marketplace de aplicativos da HubSpot com um aplicativo público, para permitir que os usuários da HubSpot instalem a integração em suas contas. A criação de aplicativos públicos para o marketplace requer adesão às diretrizes do Marketplace de aplicativos da HubSpot e uma conta de desenvolvedor de aplicativos."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Aplicativos públicos são autenticados com OAuth. Os aplicativos públicos podem ser instalados em várias contas e distribuídos no marketplace."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exemplos de casos de uso para aplicativos públicos:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“Sou um parceiro da HubSpot que deseja criar um aplicativo reutilizável que possa ser adaptado para meus clientes.”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“Sou um parceiro da HubSpot que deseja disponibilizar um aplicativo no marketplace para promover nossos recursos para o maior número possível de clientes da HubSpot.”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como começar:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-hubspot/developers",
            children: "Crie uma conta de desenvolvedor de aplicativos"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Início rápido:"
          }), " ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "Crie um aplicativo público"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/oauth-quickstart-guide",
            children: "Siga o guia de início rápido de OAuth"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
            children: "Revise os requisitos de listagem do Marketplace de aplicativos"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "Envie seu aplicativo para o Marketplace de aplicativos"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Personalizar a interface do HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " a criação de extensões de UI requer uma assinatura do ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Sales Hub"
          })
        }), " ou do ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Service Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ". No entanto, você pode começar a construí-los gratuitamente em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "contas de teste de desenvolvedor"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além dos elementos de interface que o HubSpot fornece para registros do CRM, você também pode personalizar o CRM com extensões de UI. Essas extensões são criadas localmente usando a ferramenta de projetos do desenvolvedor, que permite criar e implantar no HubSpot usando a CLI. O SDK de extensões de UI fornece um conjunto de métodos, funcionalidades, ferramentas e componentes para personalizar sua extensão. Se você não tem certeza por onde começar com as extensões de UI, confira os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "exemplos de projetos da HubSpot."
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Os projetos permitem criar e implantar localmente aplicativos privados, extensões de UI e funções sem servidor usando a CLI da HubSpot."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exemplos de casos de uso para extensões de UI:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“Quero adicionar um formulário personalizado aos registros de contato e de empresa que permita que nossa equipe de suporte ao cliente crie tickets Jira durante chamadas com clientes.”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“Preciso mostrar resumos detalhados do pipeline de vendas através dos registros de negócios para que nossos parceiros de gerenciamento possam encontrar as informações de que precisam rapidamente.”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como começar:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você não tiver uma assinatura do ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " ou ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Service Hub"
          }), " Enterprise"]
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "crie uma conta de teste de desenvolvedor"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Participe da ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/overview#get-started",
          children: "versão beta dos projetos de desenvolvedor"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Início rápido:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/quickstart",
          children: "Guia de início rápido das extensões de UI"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Saiba mais sobre o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sdk",
          children: "SDK de extensões de UI"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar sites personalizados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando o software de CMS (sistema de gerenciamento de conteúdo) da HubSpot, você pode criar sites poderosos e landing pages que se adaptam aos visitantes que acessam seu site. Ao construir no CMS, cada interação dos visitantes do seu site será diretamente conectada ao CRM da conta, a mesma ferramenta usada pelas equipes de vendas, marketing, atendimento e operações de receita para aumentar sua eficiência."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Crie sites da HubSpot localmente no seu ambiente preferido com a CLI da HubSpot, utilizando tecnologias como:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML, CSS e JavaScript"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubL, que é a linguagem de modelagem baseada em Jinjava do HubSpot"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "React, que pode ser usado para construir módulos e parciais renderizados em JavaScript"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "GraphQL, que você pode usar para consultar dados do HubSpot"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["E se preferir usar o GitHub para controle de versões, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "configurar uma integração usando as Ações do GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "O CMS permite que você crie sites localmente com a CLI da HubSpot. Você terá acesso a uma variedade de ferramentas versáteis e adaptáveis, como suporte a vários idiomas, CTAs, formulários, associações e muito mais. A HubSpot também cuida da segurança, do desempenho do servidor, da CDN, da hospedagem e ainda garante a disponibilidade para você."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exemplos de casos de uso para o CMS:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“Quero criar páginas de listagem de produtos que extraiam informações diretamente do inventário mantido no CRM.”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“Preciso de um site que apresente um blog público e um blog exclusivo para membros.”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Criar uma ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-hubspot/cms-developers",
          children: "Conta de sandbox do CMS"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Instalar a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "CLI da HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Instalar a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
          children: "Extensão para Visual Studio Code da HubSpot"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Início rápido:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Guia de início rápido do HubSpot CMS"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Confira os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms",
          children: "Guias de CMS"
        }), " e os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms",
          children: "documentos de referência"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}