"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 179648987574;
const slug = exports.slug = 'reference/ui-components/standard-components/charts/bar-chart';
const title = exports.title = 'BarChart | UI components (BETA)';
const name = exports.name = 'BarChart | UI components (BETA)';
const metaDescription = exports.metaDescription = 'Learn about the BarChart component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "colors",
  "label": "Colors",
  "parent": null
}, {
  "depth": 0,
  "id": "stacking",
  "label": "Stacking",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tag,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "BarChart | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BarChart"
      }), " component renders a bar chart for visualizing data. This type of chart is best suited for comparing categorical data. Alternatively, you can use a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/line-chart",
        children: "LineChart"
      }), " component for time series plots or visualizing trend data. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/overview",
        children: "charts"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To see an example of how to implement charts in a UI extension, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#create-line-and-bar-charts",
        children: "HubSpot's Charts example projects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/bar-chart-diagram-labels-2.png",
        alt: "bar-chart-diagram-labels-2"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Title:"
        }), " the title of the chart."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Legend:"
        }), " lists the data categories with their corresponding color for readability."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Axis label:"
        }), " the label for the axis."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Data labels:"
        }), " labels for data points."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { BarChart } from '@hubspot/ui-extensions';\n\nconst dailyInventorySample = [\n    {\n      Product: 'Hats',\n      Amount: 187,\n    },\n    {\n      Product: 'Socks',\n      Amount: 65,\n    },\n    {\n      Product: 'Ascots',\n      Amount: 120,\n    }\n  ];\n  return (\n    <BarChart\n      data={dailyInventorySample}\n      axes={{\n        x: { field: 'Product', fieldType: 'category' },\n        y: { field: 'Amount', fieldType: 'linear' },\n        options: {groupFieldByColor: 'Product'}\n      }}\n      options={{\n        title: \"My chart\",\n        showLegend: true,\n        showDataLabels: true,\n        showTooltips: true\n      }}\n    />\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "data"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object containing the chart's data in an array. Data should be formatted as comma-separated objects containing key-value pairs. Data will be displayed in the order it's provided, so any sorting will need to be done before passing it to the component.While it's recommended to pre-format your data to be human-readable, you can also provide the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyLabels"
            }), " parameter via this prop's ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            }), " to relabel data values. See example in the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#stacking",
              children: "Stacking section"
            }), " below.Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/charts/overview#formatting-data",
              children: "formatting data"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "axes"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configures the chart's axes. Using the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "x"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "y"
            }), " fields, you'll configure each axis individually with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "field"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fieldType"
            }), " parameters, along with an optional ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), " parameter:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "field"
                }), " (Required): the field from your dataset to use. This value will be used as the displayed axis label if no ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "label"
                }), " is specified."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fieldType"
                }), " (Required): the type of field. Can be ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "category"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "datetime"
                }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "linear"
                }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/ui-components/standard-components/charts/overview#configuring-axes",
                  children: "field types"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "label"
                }), ": the axis label. If not specified, the ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "field"
                }), " value will be used."]
              })]
            }), "You can also include an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            }), " field to further configure the axes with the following options:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "groupFieldByColor"
                }), " (string): specify a field to ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#colors",
                  children: "apply color"
                }), " to for visual clarity.", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#color-grouping"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "stacking"
                }), " (boolean): ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#stacking",
                  children: "stack grouped data"
                }), " instead of always rendering separate bars."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "colors"
                }), " (object): ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#specify-colors-per-field-value",
                  children: "specify colors for values"
                }), " in the field specified in ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "groupFieldByColor"
                }), "."]
              })]
            }), "Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/charts/overview#configuring-axes",
              children: "chart axes"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Additional chart configuration options. Options include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), " (string): a title for the chart."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "showLegend"
                }), " (boolean): set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to display a legend above the chart. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "showDataLabels"
                }), " (boolean): set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to display labels above data points."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "showTooltips"
                }), " (boolean): set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to display tooltips for data points on hover."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "colorList"
                }), " (array): specify a custom order for colors to used in the report."]
              })]
            }), "Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/charts/overview#chart-options",
              children: "chart options"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Colors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To apply colors to a chart for visual clarity, you can group data fields by color using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), " parameter within the axes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), ". For example, the bar chart below use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), " to add colors to each ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Product"
      }), " defined in the dataset."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/Screenshot%202024-09-30%20at%2010.38.53%20AM.png",
        alt: "Screenshot 2024-09-30 at 10.38.53 AM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const dailyInventorySample = [\n  {\n    Product: 'Standalone product A',\n    Sales: 159,\n  },\n  {\n    Product: 'Bundle A',\n    Sales: 53,\n  },\n  {\n    Product: 'Bundle B',\n    Sales: 99,\n  },\n];\nreturn (\n  <BarChart\n    data={dailyInventorySample}\n    axes={{\n      x: { field: 'Product', fieldType: 'category' },\n      y: { field: 'Sales', fieldType: 'linear' },\n      options: { groupFieldByColor: 'Product' },\n    }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For comparison, below is the same chart without ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), " configured for the axes:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/Screenshot%202024-09-30%20at%2010.42.11%20AM.png",
        alt: "Screenshot 2024-09-30 at 10.42.11 AM"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Colors will be automatically assigned in a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/overview#colors",
        children: "preset order"
      }), ". To customize the color selection order, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "colorList"
      }), " field in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), " prop, then specify the colors to pick from as shown below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/bar-charts-with-colorlist.png",
        alt: "bar-charts-with-colorlist"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const dailyInventorySample = [\n  {\n    Product: 'Standalone product A',\n    Sales: 159,\n  },\n  {\n    Product: 'Bundle A',\n    Sales: 53,\n  },\n  {\n    Product: 'Bundle B',\n    Sales: 99,\n  },\n];\nreturn (\n  <BarChart\n    data={dailyInventorySample}\n    axes={{\n      x: { field: 'Product', fieldType: 'category' },\n      y: { field: 'Sales', fieldType: 'linear' },\n      options: { groupFieldByColor: 'Product' },\n    }}\n    options={{\n      colorList: ['purple', 'green', 'darkBlue'],\n    }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Or you can specify colors to use for specific values in the field specified in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), ". To do so, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "colors"
      }), " field within the axes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), ", then specify each field value and color, as shown below. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/overview#colors",
        children: "colors"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/bar-chart-with-field-specific-colors.png",
        alt: "bar-chart-with-field-specific-colors"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const dailyInventorySample = [\n  {\n    Product: 'Standalone product A',\n    Sales: 159,\n  },\n  {\n    Product: 'Bundle A',\n    Sales: 53,\n  },\n  {\n    Product: 'Bundle B',\n    Sales: 99,\n  },\n];\nreturn (\n  <BarChart\n    data={dailyInventorySample}\n    axes={{\n      x: { field: 'Product', fieldType: 'category' },\n      y: { field: 'Sales', fieldType: 'linear' },\n      options: {\n        groupFieldByColor: 'Product',\n        colors: {\n          'Standalone product A': 'blue',\n          'Bundle A': 'orange',\n          'Bundle B': 'yellow',\n        },\n      },\n    }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Stacking"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the stacking axes option to stack grouped data rather than rendering individual bars. For example, the following bar chart displays the number of deals by deal stage. The data also includes the sales rep who owns each deal. Because two sales reps have deals in the same deal stage, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "stacking"
      }), " has been set to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " to visually combine the data into one bar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/bar-chart-stacked-data.png",
        alt: "bar-chart-stacked-data"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const Extension = ({ context }) => {\n  const dealCountSample = [\n    {\n      count: 1,\n      dealstage: 'appointmentScheduled',\n      user_id: '194784',\n    },\n    {\n      count: 2,\n      dealstage: 'closedWon',\n      user_id: '295834',\n    },\n    {\n      count: 1,\n      dealstage: 'closedWon',\n      user_id: '938453',\n    },\n  ];\n\n  return (\n    <BarChart\n      data={{\n        data: dealCountSample,\n        options: {\n          propertyLabels: {\n            dealstage: {\n              appointmentScheduled: 'Appointments scheduled (Sales)',\n              closedWon: 'Closed won (Sales)',\n            },\n            user_id: {\n              194784: 'Sales user A',\n              295834: 'Sales user B',\n              938453: 'Sales user C',\n            },\n          },\n        },\n      }}\n      axes={{\n        x: {\n          field: 'dealstage',\n          fieldType: 'category',\n          label: 'Deal Stage',\n        },\n        y: {\n          field: 'count',\n          fieldType: 'linear',\n          label: 'Count of Deals',\n        },\n        options: { groupFieldByColor: 'user_id', stacking: true },\n      }}\n      options={{\n        showLegend: true,\n        showDataLabels: true,\n        showTooltips: true,\n      }}\n    />\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Because the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dealstage"
        }), " field data is not written in a human-readable format (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "appointmentScheduled"
        }), "), the data prop includes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyLabels"
        }), " in its ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), " to convert the labels. Note that the data prop formatting is slightly different to accommodate both the dataset and its ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/charts/overview#data-options",
          children: "data options"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For comparison, below is the same chart without stacking. In this version, each sales rep has their own bar in the deal stage category."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/bar-chart-no-stacking-comparison.png",
        alt: "bar-chart-no-stacking-comparison"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " title your data categories with human-readable text so they are easy to understand."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " use sentence-casing for the data categories and chart title (only first letter capitalized)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " sort your data in ascending/descending order of your x-axis field to prevent unordered rendering prior to passing it to a charting component. If you intend to display information over time, your data will be displayed in the order you provide it."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " display the chart legend if you’re graphing more than one category of data. This prevents your users from having to rely only on color to identify different data on your chart."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " for readability, use larger surfaces to showcase charts, such as the record page middle column. Avoid using charts with many data points on smaller surfaces such as the preview panel or sidebar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON’T:"
        }), " use more than 14 data categories unless it cannot be avoided for your use case."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON’T:"
        }), " use the same colors to indicate different data categories."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/charts/bar-chart",
          children: "BarChart"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/statistics",
          children: "Statistics"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/table",
          children: "Table"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}