"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61657991578;
const slug = exports.slug = 'guides/api/conversations/inbox-and-messages';
const title = exports.title = 'Conversations inbox and messages APIs (BETA)';
const name = exports.name = 'Conversations inbox and messages APIs';
const metaDescription = exports.metaDescription = 'The conversations APIs available for inboxes, channels, threads, and messages allow you to retrieve message information, as well as post new messages.';
const position = exports.position = 2;
const toc = exports.toc = [{
  "depth": 0,
  "id": "filter-and-sort-results",
  "label": "Filter and sort results",
  "parent": null
}, {
  "depth": 0,
  "id": "inboxes",
  "label": "Inboxes",
  "parent": null
}, {
  "depth": 0,
  "id": "channels",
  "label": "Channels",
  "parent": null
}, {
  "depth": 0,
  "id": "threads-%26-messages",
  "label": "Threads & messages",
  "parent": null
}, {
  "depth": 1,
  "id": "retrieve-threads",
  "label": "Retrieve threads",
  "parent": "threads-%26-messages"
}, {
  "depth": 1,
  "id": "retrieve-messages",
  "label": "Retrieve messages",
  "parent": "threads-%26-messages"
}, {
  "depth": 1,
  "id": "retrieve-a-subset-of-messages-associated-with-a-specific-contact",
  "label": "Retrieve a subset of messages associated with a specific contact",
  "parent": "threads-%26-messages"
}, {
  "depth": 1,
  "id": "get-actors",
  "label": "Get actors",
  "parent": "threads-%26-messages"
}, {
  "depth": 1,
  "id": "update-or-restore-threads",
  "label": "Update or restore threads",
  "parent": "threads-%26-messages"
}, {
  "depth": 1,
  "id": "archive-threads",
  "label": "Archive threads",
  "parent": "threads-%26-messages"
}, {
  "depth": 1,
  "id": "add-comments-to-threads",
  "label": "Add comments to threads",
  "parent": "threads-%26-messages"
}, {
  "depth": 1,
  "id": "send-messages-to-threads",
  "label": "Send messages to threads",
  "parent": "threads-%26-messages"
}, {
  "depth": 0,
  "id": "include-attachments-in-messages",
  "label": "Include attachments in messages",
  "parent": null
}, {
  "depth": 0,
  "id": "webhooks",
  "label": "Webhooks",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      code: "code",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Conversations inbox and messages APIs"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The conversations APIs enable you to manage and interact with the conversations inbox, channels, and messages. For example, you can use these APIs to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Get and sort conversations inboxes, channels, threads, and messages."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Update thread statuses."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Delete and restore threads."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Send outbound messages via existing conversations channels."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Send an internal comment to an agent."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Retrieve conversation data to create advanced reports and analytics in external tools."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You could also use these APIs to integrate existing channels with other apps such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/how-do-i-use-the-slack-integration",
        children: "Slack"
      }), " or Microsoft Teams to send replies or receive notifications."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get started with the conversations APIs, make sure you have ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "set up a HubSpot developer account"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "created an app"
      }), ". You will need a developer account ID and app ID to get access to these APIs."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also use webhooks with the conversations API. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#webhooks",
        children: "available webhook events"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you're planning on defining a custom channel that users can connect to their HubSpot account, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/conversations/create-custom-channels",
          children: "custom channels API"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available endpoints and their requirements, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/conversations/inbox-and-messages",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " to make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to any endpoints, or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " batch read request to the get actors endpoint, you must have ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "conversations.read"
        }), " access. All other endpoints require ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "conversations.write"
        }), " access."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filter and sort results"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When retrieving inboxes, channels, channel accounts, threads, and messages using the endpoints outlined in this article, you can use different query parameters to filter and sort your responses."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Set the sort order of the response. You can sort by multiple properties."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "after"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The paging cursor token of the last successfully read resource will be returned as the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "paging.next.after"
            }), " JSON property of a paged response containing more results."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The maximum number of results to display per page."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also sort your results by any field on the channel, channel accounts, or inbox objects. For example, you could sort inboxes by name, or channel accounts by both channel ID and name."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inboxes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve a list of inboxes set up in your account, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/inboxes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you make a successful request, the response will include the inbox IDs of the different inboxes set up in your account. To retrieve details about a specific inbox, use the inbox ID to make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/inboxes/{inboxId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Channels"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can retrieve a list of the channels connected to your inboxes by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/channels"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you make a successful request, the response will include the channel IDs for the different channels connected to your inbox. To retrieve a specific channel, use the channel ID to make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/channels/{channelId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also retrieve channel accounts, which are instances of a channel that are used to send and receive messages, like a specific chatflow created for one of your chat channels or a team email address connected as an email channel."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve a list of channel accounts, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/channel-accounts"
      }), ". You can limit the results by including the following parameters in the request URL:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the channel type for which you're retrieving a channel instance."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inboxId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the inbox that the channel is connected to."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, your request may look similar to the following: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/conversations/v3/conversations/channel-accounts?channelId=1000&inboxId=448"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you make a successful request, the response will include an ID number, which is the channel account ID.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/channel-account-id-in-response-headers.png",
        alt: "channel-account-id-in-response-headers"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the channel account ID to retrieve a single channel account, such as a specific chatflow. To retrieve a specific channel account, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/channel-accounts/{channelAccountId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Threads & messages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieve threads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Threads are a group of related messages that make up a conversation in the inbox. To retrieve a list of all threads in your conversations inbox, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads"
      }), ". To filter and search your results, you can use the following parameters in your request:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "archived"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["To retrieve archived threads, use the value ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set the sort order of the response. Valid options include ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " , which is the default, and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "latestMessageTimestamp"
            }), " , which requires the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "latestMessageTimestampAfter"
            }), " field to also be set. Results are always returned in ascending order."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "after"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The paging cursor token of the last successfully read resource will be returned as the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "paging.next.after"
            }), " JSON property of a paged response containing more results. Use this parameter when sorting by ID."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "latestMessageTimestampAfter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The minimum ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "latestMessageTimestamp"
            }), ". This is required only when sorting by ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "latestMessageTimestamp"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The maximum number of results to display per page."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When you make a successful request, the response will include the thread ID, which you can use to retrieve, update, or create a new message in a thread."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve a specific thread by thread ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads/{threadId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieve messages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get the entire message history, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads/{threadId}/messages"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you make a successful request, the response will include the message ID, which you can use to retrieve a specific message. To do so, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads/{threadId}/messages/{messageId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For email messages, HubSpot cuts off the reply history section of an email. This is similar to how common mail clients handle reply history and longer emails. When you retrieve a message, the response will include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "truncationStatus"
      }), " field. The value will either be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "NOT_TRUNCATED"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TRUNCATED_TO_MOST_RECENT_REPLY,"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TRUNCATED"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If a message is truncated, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads/{threadId}/messages/{messageId}/original-content"
      }), ". This will retrieve the full original version of the message."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieve a subset of messages associated with a specific contact"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also fetch messages associated with a single contact, which can be helpful if you're creating a view where a customer can review the conversations they've had with your business."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To filter for messages associated with a contact, provide the ID of the contact as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associatedContactId"
      }), " as a query parameter in the URL of your request, along with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "threadStatus"
      }), " parameter, which can be either ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLOSED"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to retrieve open threads associated with a contact whose ID is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "53701"
      }), ", you'd make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the following URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/conversations/v3/conversations/threads?associatedContactId=53701&threadStatus=OPEN"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get actors"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Actors are entities that interact with conversations, like a HubSpot user responding to a message or a visitor sending a message. When you make a request to retrieve threads or messages, the response will include actor IDs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "All actor IDs are a single letter representing the actor type, followed by a hyphen, followed by an identifier for the actor. The identifier could be a number, a string, etc. depending on the actor type. In the table below, learn more about the different actor IDs:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Actor Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Identifier"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "A-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot user ID (number)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Agent actor, i.e. a user in the account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "E-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Email address (string)"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Email actor. This is used for email addresses that HubSpot didn't try to resolve to an agent actor or a visitor actor. For example, HubSpot will generally resolve the from email address to a contact, but if an incoming email has any additional email addresses included in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "To"
            }), " field, ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "CC"
            }), " field, etc., HubSpot won't try to resolve those email addresses to contacts."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "I-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "App ID (number)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integration actor. This is used for actions taken by an integration."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "S-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "S-hubspot (string)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "System actor. This is used for actions taken by the HubSpot system itself."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "V-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contact ID (number)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Visitor actor. Keep in mind that it's possible the visitor isn't a contact yet."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads/{threadId}/messages"
      }), ", the response will include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sender"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "recipient"
      }), " fields. These fields will include the actor IDs for the visitor or agent. For example, your response may look similar to the following: ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/actorID-response-headers.png",
        alt: "actorID-response-headers"
      }), "To retrieve a single actor, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/actors/{actorId}"
      }), ". You will need the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "actorId"
      }), ", which is included in the response to a request to get a message or thread. The response will return details like the actor name, email address, avatar, and actor type."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update or restore threads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can update a thread's status by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads/{threadId}"
      }), ". In the request body, include the thread properties to update."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The status of the thread, which can be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OPEN"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CLOSED"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To restore any soft-deleted threads, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads/{threadId}?archived=true"
      }), " to retrieve the archived thread, then in the request body set the archived property to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "archived"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " to restore a thread."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " at this time you can only update the thread's status or restore a thread when making a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " request to this endpoint."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Archive threads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can archive a thread by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads/{threadId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you use this endpoint to archive a thread, the thread will be moved to the trash and will be permanently deleted after 30 days. In the section above, learn how to restore a thread before it is permanently deleted."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add comments to threads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add a comment to an existing thread, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads/{threadId}/messages"
      }), ". Comments only appear in the inbox and are not sent to visitors."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can include the following fields in the request body when sending a comment:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of message you're sending. This value can either be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "MESSAGE"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMMENT"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The content of the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "richText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The content of the message in HTML format. This is optional."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attachments"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any attachments to attach to the comment, specified as an object containing a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileId"
            }), ". If you want to include a specific attachment, you can upload it using the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/library/files",
              children: "Files API"
            }), " before making the call to add the comment."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request body may look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to conversations/v3/conversations/threads/{threadId}/messages\n\n{\n  \"type\": \"COMMENT\",\n  \"text\": \"Can you follow up?\",\n  \"richText\": \"<p>Can you follow up?</p>\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Send messages to threads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using this endpoint, an integration can send outgoing messages as a user in a HubSpot account. These messages are sent from the channel, just like if a user typed and sent a response in the inbox. To send a message to an existing thread, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations/v3/conversations/threads/{threadId}/messages"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When sending a message to a thread, you can include the following fields. You can include both the message details, as well as recipient information, in the request body."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of message you're sending. This value can either be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "MESSAGE"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMMENT"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The content of the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "richText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The content of the message in HTML format. This is optional."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recipients"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object representing the recipients of the message. This is the most important for email, where you can specify multiple recipients. This object includes the following fields:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actorID"
            }), ": the ID associated with the message recipient.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ": the name of the recipient.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "recipientField"
            }), ": for email messages, this is the type of recipient. Available values are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CC"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BCC"
            }), ".", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deliveryIdentifiers"
            }), ": for email messages, this indicates the email addresses used for the visitor and agent."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "senderActorId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This must be an agent actor ID that is associated to the HubSpot user in the account who is sending the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of a generic channel returned from the channels endpoint, like ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1000"
            }), " for live chat, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1001"
            }), " for Facebook Messenger, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1002"
            }), " for email, etc."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelAccountId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of an account that is part of the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelId"
            }), " channel. On an existing thread, it is recommended to copy the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelId"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelAccountId"
            }), " of the most recent message on the thread."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The subject line of the email. This is ignored when sending a message to a non-email channel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attachments"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any attachments to attach to the message, which can be specified as an object that contains a URL to a file hosted in your HubSpot account, or a list of quick replies if you're sending a message via Facebook Messenger or LiveChat. Learn more about how to include attachments ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#include-attachments-in-messages",
              children: "in the section below."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request body may look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to conversations/v3/conversations/threads/{threadId}/messages\n\n{\n  \"type\": \"MESSAGE\",\n  \"text\": \"Hey there, following up\",\n  \"richText\": \"<p>Hey there, following up</p>\",\n  \"recipients\": [\n    {\n      \"actorId\": \"E-user@hubspot.com\",\n      \"name\": \"Leslie Knope\",\n      \"recipientField\": \"TO\",\n      \"deliveryIdentifiers\": [\n        {\n          \"type\": \"HS_EMAIL_ADDRESS\",\n          \"value\": \"lknope@hubspot.com\"\n        }\n      ]\n    }\n  ],\n  \"senderActorId\": \"A-3892666\",\n  \"channelId\": \"1002\",\n  \"channelAccountId\": \"42423411\",\n  \"subject\": \"Follow up\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you make a successful request, the new message will be sent to the visitor. This can appear as a message in the chat widget, an email in the thread, a message in Facebook Messenger, etc."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Include attachments in messages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Attachments can be links to a file hosted in the HubSpot files tool, or a set of quick replies if you're sending a message using Facebook Messenger or LiveChat."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To include an attachment from a file in your HubSpot account, provide the absolute URL as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fileId"
      }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "attachments"
      }), " field of the request body. For example, the corresponding part of the request body is shown in lines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "10-12"
      }), " below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to conversations/v3/conversations/threads/{threadId}/messages\n{\n  \"type\": \"MESSAGE\",\n  \"text\": \"Hey there, following up\",\n  \"recipients\": {\n    \"actorID\": \"E-user@hubspot.com\",\n    \"name\": \"Leslie Knope\",\n    \"recipientField\": \"TO\",\n    \"deliveryIdentifiers\": [\n      { \"type\": \"HS_EMAIL_ADDRESS\", \"value\": \"lknope@hubspot.com\" }\n    ]\n  },\n  \"senderActorId\": \"A-3892666\",\n  \"channelId\": \"1002\",\n  \"channelAccountId\": \"424232411\",\n  \"subject\": \"Follow up\",\n  \"attachments\": {\n    \"fileId\": \"https://12345678.fs1.hubspotusercontent-na1.net/hubfs/12345678/doggo_video.mp4\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you connected Facebook Messenger or LiveChat as a channel, you can also specify a set of quick replies within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "attachments"
      }), " field, which prompt the recipient with certain options that appear as tappable buttons below a message. Once tapped, the corresponding value of that option will be recorded."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quick replies should be provided as a list of objects that each contain the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ": the visible text that appears to the recipient (e.g., ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Red"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "value"
        }), ": the associated value of the button that you want to record (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "RED"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "valueType"
        }), ": the type of the quick reply option, which can be either ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "TEXT"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "URL"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example request body below demonstrates how to specify two quick reply options, ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Yes"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "No"
      }), ", on lines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "10-23"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to conversations/v3/conversations/threads/{threadId}/messages\n{\n  \"type\": \"MESSAGE\",\n  \"text\": \"Did that answer your question?\",\n  \"recipients\": { \"actorID\": \"E-user@hubspot.com\", \"name\": \"Leslie Knope\", \"recipientField\": \"TO\", \"deliveryIdentifiers\": [{\"type\": \"HS_EMAIL_ADDRESS\", \"value\": \"lknope@hubspot.com\"}]},\n  \"senderActorId\": \"A-3892666\",\n  \"channelId\": \"1002\",\n  \"channelAccountId\": \"424232411\",\n  \"subject\": \"Follow up\",\n  \"attachments\": [\n    \"type\": \"QUICK_REPLIES\",\n    \"quickReplies\": [\n      {\n        \"label\": \"Yes\",\n         \"value\": \"Yes\",\n         \"valueType\": \"URL\"\n      },\n      {\n        \"label\": \"No\",\n        \"value\": \"No\",\n        \"valueType\": \"TEXT\"\n      }\n    ]\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhooks"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhooks for conversations are also supported and can be used in tandem with the conversations API. You can use webhooks to subscribe to events about conversations, including thread creation, thread status and assignment changes, and new messages on threads. Learn more about using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks",
        children: "webhooks"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You must have the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations.read"
      }), " scope to get access to following webhooks."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The available conversation webhook events are:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Event"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A new Conversations thread has been created."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A Conversations thread has been archived."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A Conversations thread has been permanently deleted."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A property of a Conversations thread has been updated."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.newMessage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A new message has been posted on a Conversations thread."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The available properties for each event type are:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Event"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Properties"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "All events"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), ": the ID of the Conversations thread that the webhook corresponds to."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.propertyChange"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "assignedTo"
            }), ": a thread's assignment has changed. The value is the user ID of the new assignee, unless the owner was removed.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "status"
            }), ": a thread's status has changed, either to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OPEN"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CLOSED"
            }), ".", (0, _jsxRuntime.jsx)(_components.code, {
              children: "isArchived"
            }), ": when a thread is restored a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.propertyChange"
            }), " webhook will be sent and the value will be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.newMessage"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "messageId"
            }), ": the ID of the new message.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageType"
            }), ": the type of the new message. One of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "MESSAGE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMMENT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WELCOME_MESSAGE"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}