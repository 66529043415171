"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694157;
const slug = exports.slug = 'reference/cms/hubl/loops';
const title = exports.title = 'For Loops';
const name = exports.name = '[new] For loops';
const metaDescription = exports.metaDescription = 'For loops can be used in HubL to iterate through sequences of objects. ';
const position = exports.position = 5;
const toc = exports.toc = [{
  "depth": 0,
  "id": "loop-properties",
  "label": "Loop properties",
  "parent": null
}, {
  "depth": 0,
  "id": "nested-loops",
  "label": "Nested loops",
  "parent": null
}, {
  "depth": 0,
  "id": "cycle",
  "label": "cycle",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-within-loops",
  "label": "Variables within loops",
  "parent": null
}, {
  "depth": 0,
  "id": "key%2C-value-pairs-in-loops",
  "label": "Key, value pairs in loops",
  "parent": null
}, {
  "depth": 0,
  "id": "iterate-a-set-number-of-times",
  "label": "Iterate a set number of times",
  "parent": null
}, {
  "depth": 0,
  "id": "using-hubl-tags-in-loops",
  "label": "Using HubL tags in loops",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "For loops"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For loops can be used in HubL to iterate through sequences of objects. They will most commonly be used with rendering ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "blog content"
      }), " in a listing format, but they can also be used to sort through other sequence variables."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For loops begin with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% for %}"
      }), " statement and end with an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% endfor %}"
      }), " statement. Within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% for %}"
      }), " statement a single sequence item is named followed by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "in"
      }), " and then the name of the sequence. The code between the opening and closing ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), " statements is printed with each iteration, and generally includes the printed variable of the individual sequence item. Below is the basic syntax of a for loop:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for item in items %}\n\t{{ item }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is a basic example that shows how to print a sequence of variable values into a list."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set languages = [\"HTML\", \"CSS\", \"Javascript\", \"Python\", \"Ruby\", \"PHP\", \"Java\"] %}\n\n<h1>Languages</h1>;\n<ul>\n    {% for language in languages %}\n    <li>{{ language }}</li>\n    {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Languages</h1>\n<ul>\n  <li>HTML</li>\n  <li>CSS</li>\n  <li>Javascript</li>\n  <li>Python</li>\n  <li>Ruby</li>\n  <li>PHP</li>\n  <li>Java</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Loop properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As a loop iterates, you can use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "conditional logic"
      }), " to define the loop's behavior. The variable property ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loop.index"
      }), " keeps a count of the current number of the iterations of the loop. There are several other loop variable properties that count the iterations in different ways. These properties are described below:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.cycle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A helper function to cycle between a list of sequences. See the explanation below."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.depth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indicates how deep in deep in a recursive loop the rendering currently is. Starts at level 1"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.depth0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indicates how deep in deep in a recursive loop the rendering currently is. Starts at level 0"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.first"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This variable evaluates to true, if it is the first iteration of the loop."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.index"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The current iteration of the loop. This variable starts counting at 1."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.index0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The current iteration of the loop. This variable starts counting at 0."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.last"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This variable evaluates to true, if it is the last iteration of the loop."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.length"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The number of items in the sequence."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.revindex"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The number of iterations from the end of the loop. Counting down to 1."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.revindex0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The number of iterations from the end of the loop. Counting down to 0."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below are some examples that use different loop variables. The following basic example uses ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loop.index"
      }), " to keep a count that is printed with each iteration."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set loopy = [\"Content\", \"Social\", \"Contacts\", \"Reports\"] %}\n{% for app in loopy %}\n    {{ loop.index }}. {{app}} <br>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1. Content <br />\n2. Social <br />\n3. Contacts <br />\n4. Reports <br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The next example uses conditional logic to check whether the length of the loop is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "divisibleby"
      }), " certain numbers. It then renders the width of the post-item div accordingly. The example uses the standard blog post loop and assumes that there are 6 posts in the loop."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n{% if loop.length is divisibleby 4 %}\n<div style=\"width:25%\">Post content</div>\n\n{% elif loop.length is divisibleby 3 %}\n<div style=\"width:33.33332%\">Post content</div>\n\n{% else %}\n<div style=\"width:50%\">Post content</div>\n\n{% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Nested loops"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Loops can also be nested with loops. The child for loop will run with each iteration of the parent for loop. In the example below, a list of child items is printed in a nested ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<ul>"
      }), " within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<ul>"
      }), " of parent items."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set parents = [\"Parent item 1\", \"Parent item 2\", \"Parent item 3\"] %}\n{% set children = [\"Child item 1\", \"Child item 2\", \"Child item 3\"] %}\n<ul>\n{% for parent in parents %}\n<li>{{parent}}<ul>\n    {% for child in children %}\n    <li>{{child}}</li>\n    {% endfor %}\n    </ul>\n</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    Parent item 1\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n\n  <li>\n    Parent item 2\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n\n  <li>\n    Parent item 3\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "cycle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The cycle tag can be used within a for loop to cycle through a series of string values and print them with each iteration. One of the most practical applications to this technique is applying alternating classes to your blog posts in a listing. This tag can be used on more than two values and will repeat the cycle if there are more loop iterations than cycle values. In the example below, a class of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "odd"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "even"
      }), " are applied to posts in a listing (the example assumes that there are 5 posts in the loop)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Please note that there are ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "no spaces"
      }), " between the comma-separated cycle string values."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    <div class=\"post-item {% cycle \"odd\",\"even\" %}\">Blog post content</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd\">Blog post content</div>\n<div class=\"post-item even\">Blog post content</div>\n<div class=\"post-item odd\">Blog post content</div>\n<div class=\"post-item even\">Blog post content</div>\n<div class=\"post-item odd\">Blog post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables within loops"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Any variables defined within loops are limited to the scope of that loop and cannot be called from outside of the loop."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can call variables that are defined outside of a loop, from within a loop, but not the other way around."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Key, value pairs in loops"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If the dict of information you are looping through has key and value pairs, a simple for loop would only have access to the values. If you wish to have access to both the keys and values within the for loop, the HubL would be formatted as such:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set dict_var = {\"name\": \"Cool Product\", \"price\": \"$20\", \"size\":\"XL\"} %}\n{% for key, val in dict_var.items() %}\n    {{ key }}: {{ val }}<br>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "name: Cool Product <br />\nprice: $20 <br />\nsize: XL <br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Iterate a set number of times"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sometimes you want to iterate a set number of times, this can be useful in generating HTML or CSS. You can do this using the range function."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for x in range(0,5) %}\n\t{{loop.index}}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1 2 3 4 5\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using HubL tags in loops"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you add a tag to page HubSpot automatically assigns an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " to the wrapping HTML. That tag is unique per tag \"name\". In situations where you need to use a tag in a for loop setting unique names is not practical. Add the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unique_in_loop"
      }), " parameter to your tag to generate unique ids. This parameter appends the module name with the current loop iteration number, ensuring that it is unique. Unique id's are not only needed for valid HTML but are important for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "accessibility"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for item in module.icon_field %}\n\t{% icon\n\t\tname=\"{{ item.name }}\",\n\t\tstyle=\"{{ item.type }}\",\n\t\tunicode=\"{{ item.unicode }}\",\n        unique_in_loop=True\n\t%}\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}