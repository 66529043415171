"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42750989737;
const slug = exports.slug = 'guides/apps/public-apps/overview';
const title = exports.title = 'Öffentliche Apps';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Creating and installing apps';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie eine App in HubSpot erstellen und installieren. Apps sind Integrationen, die über einen OAuth-Verknüpfungsprozess in Kunden-Accounts installiert werden können.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eine-%C3%B6ffentliche-api-erstellen",
  "label": "Eine öffentliche API erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "bereiche-konfigurieren",
  "label": "Bereiche konfigurieren",
  "parent": "eine-%C3%B6ffentliche-api-erstellen"
}, {
  "depth": 2,
  "id": "bereichstypen",
  "label": "Bereichstypen",
  "parent": "eine-%C3%B6ffentliche-api-erstellen"
}, {
  "depth": 2,
  "id": "die-bereiche-ihrer-%C3%B6ffentlichen-app-konfigurieren",
  "label": "Die Bereiche Ihrer öffentlichen App konfigurieren",
  "parent": "eine-%C3%B6ffentliche-api-erstellen"
}, {
  "depth": 0,
  "id": "eine-app-installieren",
  "label": "Eine App installieren",
  "parent": null
}, {
  "depth": 1,
  "id": "ihre-app-mit-einem-kunden-account-autorisieren",
  "label": "Ihre App mit einem Kunden-Account autorisieren",
  "parent": "eine-app-installieren"
}, {
  "depth": 1,
  "id": "die-oauth-token-f%C3%BCr-den-erstmaligen-zugriff-generieren",
  "label": "Die OAuth-Token für den erstmaligen Zugriff generieren",
  "parent": "eine-app-installieren"
}, {
  "depth": 0,
  "id": "%C3%B6ffentliche-apps-in-hubspot-verwalten",
  "label": "Öffentliche Apps in HubSpot verwalten",
  "parent": null
}, {
  "depth": 1,
  "id": "find-an-app-s-id",
  "label": "Find an app's ID",
  "parent": "%C3%B6ffentliche-apps-in-hubspot-verwalten"
}, {
  "depth": 1,
  "id": "app-verhalten-%C3%BCberwachen",
  "label": "App-Verhalten überwachen",
  "parent": "%C3%B6ffentliche-apps-in-hubspot-verwalten"
}, {
  "depth": 0,
  "id": "eine-verifizierte-domain-hinzuf%C3%BCgen",
  "label": "Eine verifizierte Domain hinzufügen",
  "parent": null
}, {
  "depth": 1,
  "id": "eine-verifizierte-domain-hinzuf%C3%BCgen",
  "label": "Eine verifizierte Domain hinzufügen",
  "parent": "eine-verifizierte-domain-hinzuf%C3%BCgen"
}, {
  "depth": 2,
  "id": "zus%C3%A4tzliche-anmerkungen",
  "label": "Zusätzliche Anmerkungen",
  "parent": "eine-verifizierte-domain-hinzuf%C3%BCgen"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      ul: "ul",
      li: "li",
      h2: "h2",
      strong: "strong",
      img: "img",
      h3: "h3",
      code: "code",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Öffentliche Apps"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot ist eine öffentliche App eine Art von Integration, die Kunden in Ihren Accounts installieren können oder die im App Marketplace gelistet werden kann. Sie erfordert eine Authentifizierung über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), ". Sobald ein Benutzer Ihre App in seinem HubSpot-Account ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#installing-apps",
        children: "installiert"
      }), " hat, können Sie API-Aufrufe mithilfe eines ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth-Zugriffstokens"
      }), " an diesen Account vornehmen. Außerdem wird Ihre App in den Einstellungen von ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Verknüpfte Apps"
      }), " des Accounts angezeigt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verknüpfte Apps können außerdem davon profitieren, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "sich für Änderungen mithilfe von Webhooks anzumelden"
      }), " und benutzerdefinierte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "Chronik-Events"
      }), " zu erstellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend erfahren Sie, wie Sie:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-public-app",
          children: "Eine öffentliche API erstellen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#install-an-app",
          children: "Eine öffentliche App in einem Account installieren"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#manage-public-apps-in-hubspot",
          children: "Die App verwalten, einschließlich Überwachung der Nutzung"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "Der App eine verifizierte Domain hinzufügen"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine öffentliche API erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine App in HubSpot erstellen, verknüpfen Sie im Grunde eine von Ihnen erstellte App mit einem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "App-Entwickler-Account"
      }), ". So legen Sie mit dem Erstellen Ihrer HubSpot-App los:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem App-Entwickler-Account in der Hauptnavigationsleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App erstellen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Füllen Sie als Nächstes einige grundlegende Informationen und Einstellungen für Ihre App aus. Wenn Benutzer Ihre App mit ihrem HubSpot-Account authentifizieren, sehen sie den Namen, die Beschreibung, das Logo und alle Support-Kontaktinformationen, die Sie auf dieser Seite bereitstellen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Der App-Name wird überall dort verwendet, wo Ihre App in HubSpot angezeigt wird. Dazu gehören die Installation der App sowie die ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Powered by"
        }), "-Footer für ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "CRM-Karten"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/timeline",
          children: "Chronik-Events"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/new_app_setup.png?width=600&name=new_app_setup.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authentifizierung"
        }), ", um Ihre Client-ID und das Client-Geheimnis sowie die der App zugewiesenen Bereiche anzuzeigen. Sie benötigen diese Informationen beim ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "Initiieren einer OAuth-Verknüpfung"
        }), " zwischen Ihrer App und HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/app_auth_settings.png?width=600&name=app_auth_settings.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bereiche konfigurieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bereiche bestimmen die Berechtigungen Ihrer App für den Zugriff auf Datenquellen oder Tools in einem Account, der Ihre App installiert hat. Die von Ihnen konfigurierten Bereiche werden als ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scope"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), "-Abfrageparameter in einer Installations-URL angezeigt, die Sie dann den Benutzern zur Verfügung stellen können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Bereichstypen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Authentifizierung"
      }), " stehen Ihnen drei verschiedene Bereichstypen zur Konfiguration zur Verfügung. Sie müssen die Bereiche angeben, die Ihre App für die Installation erfordert, aber Sie können auch zwei andere Bereichstypen angeben: bedingt erforderliche Bereiche und optionale Bereiche."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erforderliche Bereiche:"
        }), " Bereiche, die vom Benutzer autorisiert werden müssen ", (0, _jsxRuntime.jsx)("u", {
          children: "und"
        }), " im ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), "-Abfrageparameter in der Installations-URL Ihrer App für eine erfolgreiche Installation vorhanden sein müssen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bedingt erforderliche Bereiche:"
        }), " Bereiche, die nur dann vom Benutzer autorisiert werden müssen, wenn sie im ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), "-Abfrageparameter in der Installations-URL Ihrer App für eine erfolgreiche Installation vorhanden sind.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Mit diesem Bereichstyp können Sie flexibel sein und eine separate Installations-URL für mehrstufige Funktionen oder Bereiche bereitstellen, die nur erforderlich sind, wenn Benutzer bestimmte Funktionen in Ihrer App aktivieren. Beispielsweise könnten Sie Ihren Benutzern zwei Installations-URLs anbieten: Eine Installations-URL könnte den bedingt erforderlichen Bereich in den ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            }), "-Abfrageparameter für Benutzer mit Zugriff auf eine Funktion aufnehmen, während eine andere Installations-URL diesen Bereich im ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            }), "-Abfrageparameter für Benutzer ohne Zugriff auslässt."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn in Ihrer App-Installations-URL ein bedingt erforderlicher Bereich vorhanden ist und ein Benutzer ohne Zugriff auf die zugehörige Funktion versucht, Ihre App über diese URL zu installieren, schlägt die Installation fehl."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Optionale Bereiche:"
        }), " Bereiche, die ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " erforderlich sind, um Ihre App erfolgreich zu installieren. Diese Bereiche werden im ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), "-Abfrageparameter in der Installations-URL Ihrer App angegeben. Wenn Sie beispielsweise möchten, dass Ihre App Daten von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "benutzerdefinierten Objekten"
        }), " abrufen kann (die nur für ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), "-Accounts von HubSpot verfügbar sind), können Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.custom.read"
        }), "-Bereich als optionalen Bereich hinzufügen. Wenn dann ein Account Zugriff auf die benutzerdefinierten Objekte hat, wird der Bereich autorisiert. Andernfalls können sie die App weiterhin ohne den Bereiche „Benutzerdefinierten Objekte“ installieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Die Bereiche Ihrer öffentlichen App konfigurieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So passen Sie Ihre Bereichseinstellungen an und fügen neue Bereiche hinzu:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um bedingt erforderliche oder optionale Bereiche zu konfigurieren, klicken Sie auf den Schalter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erweiterte Bereichseinstellungen aktivieren"
        }), ", um ihn zu aktivieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/turn-on-advanced-scope-settings-toggle-in-public-app-auth-tab.png",
        alt: "turn-on-advanced-scope-settings-toggle-in-public-app-auth-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hinweis:"
        }), " Ab dem 21. Oktober 2024 sind für alle Apps erweiterte Bereichseinstellungen erforderlich. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/advanced-auth-and-scope-settings-for-public-apps",
          children: "Änderungsprotokoll für Entwickler von HubSpot"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Bereiche"
        }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Neuen Bereich hinzufügen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie im rechten Bereich die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suchleiste"
        }), ", um nach einem Bereich zu suchen, und aktivieren Sie dann das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kontrollkästchen"
        }), " neben dem Bereich, den der Benutzer autorisieren soll. Wenn Sie die erweiterten Bereichseinstellungen aktiviert haben, klicken Sie auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dropdown-Menü"
        }), " neben dem Bereich und wählen Sie einen ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bereichstyp"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aktualisieren"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/add-new-scope-panel-in-public-app-setup.png",
        alt: "add-new-scope-panel-in-public-app-setup"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Überprüfen Sie Ihre konfigurierten Bereiche. Wenn Sie die erweiterten Bereichseinstellungen aktiviert haben, können Sie den Bereichstyp eines beliebigen Bereichs ändern, indem Sie auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dropdown-Menü"
        }), " neben dem Bereich klicken. Sie können auch auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Löschen"
        }), " klicken, um einen der Bereiche Ihrer App zu entfernen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/review-new-scope-settings-in-public-app-setup.png",
        alt: "review-new-scope-settings-in-public-app-setup"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sobald Sie Ihre App-Einstellungen eingerichtet haben, klicke unten links auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App erstellen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit der erstellten App können Sie nun den Installationsprozess durchlaufen."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Es wird empfohlen, der App eine verifizierte Domain ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "hinzuzufügen"
        }), ", um den Benutzern, die Ihre App installieren, eine weitere Vertrauensebene zu verleihen. Andernfalls zeigt die App ein Banner an, das besagt, dass die App nicht verifiziert ist."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine App installieren"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Bevor Sie Ihre App installieren, sollten Sie Folgendes bedenken:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Eine App wird erst auf der Seite ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Verknüpfte Apps"
          }), " eines Accounts angezeigt, wenn die Token für den erstmaligen Zugriff und die Aktualisierung erstellt wurden."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Nur Benutzer mit Zugriff auf die erforderlichen oder bedingt erforderlichen Bereiche einer App können eine App installieren."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Apps können nicht in Entwickler-Accounts installiert werden. Um Ihre App zu testen, müssen Sie in Ihrem Entwickler-Account einen ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types",
            children: "Test-Account erstellen"
          }), " und sie dort installieren."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Installation einer App kann in zwei Schritte unterteilt werden: Autorisierung und Token-Generierung."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ihre App mit einem Kunden-Account autorisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um Ihre App mit einem HubSpot-Account zu autorisieren, müssen Sie eine Autorisierungs-URL erstellen. Rufen Sie dazu die Client-ID für Ihre App ab und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "initiieren Sie den OAuth-Prozess"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Ihre URL bereit ist, öffnen Sie sie in Ihrem BVrowser, um eine Liste aller Ihrer HubSpot-Accounts anzuzeigen. Dies ist auch, was Benutzer sehen, sobald Sie sie zu dieser URL weiterleiten."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Account"
        }), " aus, in dem Sie Ihre App installieren möchten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/select_account-1.webp",
        alt: "select_account-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Nachdem Sie einen Account ausgewählt haben, wird Ihnen eine Liste von Bereichen basierend auf den Parametern ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&scope="
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&optional_scope="
        }), ", die Sie für die Autorisierungs-URL festgelegt haben, angezeigt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " berücksichtigen und der ausgewählte Account keinen Zugriff darauf hat (z. B. der Content-Bereich für einen reinen CRM-Account), wird er nicht aufgeführt."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zugriff gewähren"
        }), ", um die Verknüpfung zu autorisieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/approve_scopes-1.webp",
        alt: "approve_scopes-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Nachdem Sie Zugriff gewährt haben, werden Sie basierend auf dem ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&redirect_uri="
        }), "-Parameter in der ursprünglichen Autorisierungs-URL weitergleitet und ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?code="
        }), "-Parameter wird an die URL angehängt. Verwenden Sie diesen Code im nächsten Schritt, um ein Zugriffstoken zu generieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Die OAuth-Token für den erstmaligen Zugriff generieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Ihre Token für den erstmaligen Zugriff und die Aktualisierung zu generieren, benötigen Sie den Code vom ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?code="
      }), "-Parameter der Autorisierungs-URL, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "redirect_url"
      }), ", die Client-ID und das Client-Geheimnis. Detaillierte Anweisungen finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "hier"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie Ihre App autorisiert haben und die Token für den erstmaligen Zugriff generiert haben, ist die Installation abgeschlossen. Sie wird auf Ihrer Seite ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/integrations-beta",
        children: "Verknüpfte Apps"
      }), " gelistet und Sie erhalten jetzt Anfragen zum Abrufen von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "Webhooks"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "CRM-Karten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/connected_apps-1.webp",
        alt: "connected_apps-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Öffentliche Apps in HubSpot verwalten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Find an app's ID"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können die ID einer öffentlichen App in Ihrem App-Entwickler-Account auf eine der folgenden Arten finden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem Entwickler-Account in der Hauptnavigationsleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " und zeigen Sie dann die ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App-ID"
        }), " an, die unter dem Namen Ihrer App aufgeführt ist."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id.png",
        alt: "find-app-id"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem Entwickler-Account in der Hauptnavigationsleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " und klicken Sie dann auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " der App. Klicken Sie auf der Seite ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Grundlegende Informationen"
        }), " auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authentifizierung"
        }), " und zeigen Sie dann die ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App-ID"
        }), " an."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id-auth-settings.png",
        alt: "find-app-id-auth-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "App-Verhalten überwachen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot protokolliert alle Anfragen, die an oder von einer verknüpften App vorgenommen werden, einschließlich eingehender Anfragen mithilfe eines ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth-Zugriffstokens"
      }), " oder ausgehender Anfragen für Webhooks oder CRM-Karten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So zeigen Sie dieses Anfrageprotokoll an:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem Entwickler-Account in der Hauptnavigationsleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " der App."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Monitoring"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Registerkarten"
        }), ", um verschiedene Typen von Anfragen anzuzeigen, die an die oder von der App vorgenommen werden. Beim Anzeigen dieser Protokolle können Sie auf eine einzelne Anfrage klicken, um weitere Informationen zu dieser anzuzeigen, einschließlich:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["bei ", (0, _jsxRuntime.jsx)("u", {
              children: "erfolgreichen"
            }), " Anfragen die Anfragemethode, der Pfad und die Uhrzeit der Anfrage."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["bei ", (0, _jsxRuntime.jsx)("u", {
              children: "nicht erfolgreichen"
            }), " Anfragen zusätzliche Fehlerinformationen wie Antwort-Header und -Text."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/request_details.webp",
        alt: "request_details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Folgenden erfahren Sie mehr über die einzelnen Registerkarten der Seite ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Monitoring"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "API-Aufrufe:"
        }), " Die Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "API-Aufrufe"
        }), " zeigt alle Anfragen an, die mithilfe eines OAuth-Zugriffstokens an Ihre App durchgeführt wurden. Es kann nach HTTP-Methode, Antwortcode, Zeitrahmen oder Anfrage-URL gefiltert werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks:"
        }), " Die Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Webhooks"
        }), " zeigt HubSpot-Anfragen für alle ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "Webhook-Abonnements"
        }), " Ihrer App an. Filtern Sie nach Antwort (einschließlich Timeouts und Verknüpfungsfehler), Status (Erfolg, erneuter Versuch wird vorgenommen oder Fehler), Abonnementtyp, Zeitrahmen, Versuch, Batch, Event oder Account-ID."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Die Versuch-ID wäre eine Kombination von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriptionId"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "eventId"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "attemptNumber"
        }), " von einer spezifischen Anfrage."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM-Erweiterungen:"
        }), " Auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "CRM-Erweiterungen"
        }), " werden Anfragen für die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "CRM-Karten"
        }), " Ihrer App angezeigt. Filtern Sie nach Erweiterungsobjekttyp, CRM-Objekttyp (Kontakt, Unternehmen, Ticket oder Deal), Fehler- oder Warnungstyp, Zeitrahmen, Anfrage-ID oder CRM-Datensatz-ID (d. h., eine bestimmte Kontakt-ID)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "App-Einstellungen:"
        }), " Die Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App-Einstellungen"
        }), " ermöglicht es Ihnen, die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/create-an-app-settings-page",
          children: "Einstellungsseite"
        }), " Ihrer App zu konfigurieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf jeder Registerkarte können Sie, wenn in den letzten 30 Tagen zugehörige Ereignisse aufgetreten sind (z. B. ein Webhook-Trigger aufgetreten ist oder ein API-Aufruf durchgeführt wurde), auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Protokolle exportieren"
      }), " klicken, um die zugehörigen Event-Daten in eine CSV-Datei zu exportieren:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Konfigurieren Sie im Dialogfeld, Daten von wie vielen Tagen der Export umfassen soll (bis zu 30 Tage)."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportieren"
        }), ". Es wird eine E-Mail-Benachrichtigung an die E-Mail-Adresse gesendet, die Ihrem Benutzer in Ihren HubSpot-Einstellungen zugeordnet ist."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine verifizierte Domain hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn HubSpot-Benutzer eine App installieren, stimmen sie zu, dem Entwickler der App Zugriff auf seine Account-Daten zu gewähren. Die Identität und der Ruf des Entwicklers spielen jeweils eine wichtige Rolle bei der Entscheidung eines Benutzers, mit der Installation fortzufahren. Um die vollständige Zustimmung der Benutzer bei der Installation einer App zu gewährleisten, zeigt HubSpot auf dem App-Installationsbildschirm eine Meldung an, in der die Verifizierungsstufe der App und das Listing im App Marketplace angezeigt werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Wenn eine App nicht über eine verifizierte Domain verfügt, zeigt HubSpot auf dem Installationsbildschirm ein Banner an, das besagt, dass die App nicht verifiziert wurde."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/not-verified.png",
            alt: "not-verified"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wenn die App über eine verifizierte Domain verfügt, aber nicht ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "im App Marketplace gelistet ist"
          }), ", zeigt HubSpot die verifizierte Domain zusammen mit einem Banner auf dem Installationsbildschirm an, das besagt, dass die App nicht von HubSpot überprüft oder genehmigt wurde."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-not-listed.png",
        alt: "verified-not-listed"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Wenn eine App im App Marketplace gelistet wurde und den App-Überprüfungsprozess von HubSpot bestanden hat, zeigt HubSpot keines der oben genannten Banner an. Sie müssen die Domain nicht verifizieren, wenn Ihre App im App Marketplace gelistet ist."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-and-listed.png",
            alt: "verified-and-listed"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine verifizierte Domain hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um eine verifizierte Domain zur App hinzuzufügen, müssen Sie zuerst die Domain zu den Einstellungen der App hinzufügen und dann einen TXT-Eintrag zu den DNS-Einstellungen der Domain hinzufügen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem App-Entwickler-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " der App."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kontakt & Support"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie im Feld ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Unternehmensdomain"
        }), " Ihre Domain ein und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Speichern"
        }), ". Unterhalb der ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Unternehmensdomain"
        }), " wird eine Meldung angezeigt, dass die Domain noch nicht verifiziert wurde."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verifizieren"
        }), ", um den Verifizierungsvorgang zu starten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/domain-verification-for-app.png",
        alt: "domain-verification-for-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Bestätigen Sie im rechten Bereich, dass die Domain korrekt eingegeben wurde, und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Weiter"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Kopieren Sie den erforderlichen TXT-Eintragswert, indem Sie in der Spalte ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Wert"
          }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Kopieren"
          }), " klicken."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verify-app-domain-copy-value.png",
            alt: "verify-app-domain-copy-value"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Erstellen Sie bei Ihrem DNS-Anbieter einen TXT-Eintrag mit dem kopierten Wert. Nachfolgend finden Sie Anweisungen für einige gängige DNS-Anbieter:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.godaddy.com/help/add-a-txt-record-19232",
              children: "GoDaddy"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://my.bluehost.com/hosting/help/559#add",
              children: "Bluehost"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.namecheap.com/support/knowledgebase/article.aspx/317/2237",
              children: "Namecheap"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/360019093151-Managing-DNS-records-in-Cloudflare#h_60566325041543261564371",
              children: "Cloudflare"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.hover.com/hc/en-us/articles/217282457-How-to-Edit-DNS-records-A-AAAA-CNAME-MX-TXT-SRV-",
              children: "Hover"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.name.com/support/articles/115004972547-Adding-a-TXT-Record",
              children: "Name"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.uniteddomains.com/hc/en-us/articles/115000887125-How-to-set-up-a-TXT-record-on-a-domain-name",
              children: "United Domains"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nachdem Sie Ihre DNS-Einstellungen aktualisiert haben, gehen Sie zurück zu HubSpot und klicken Sie im rechten Bereich auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Weiter"
          }), ". Die Aktualisierung von DNS-Einträgen kann bis zu 48 Stunden dauern, sodass HubSpot die Änderung möglicherweise nicht sofort erkennt. Sie können jederzeit zu diesem Bildschirm zurückkehren, indem Sie die Option ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Jetzt verifizieren"
          }), " auf der Seite mit den Einstellungen für ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Unternehmensdetails"
          }), " erneut auswählen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nach der Verifizierung sehen Sie im Feld ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Unternehmensdomain"
          }), " eine Erfolgsstatusanzeige."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Domain%20verified__export.png",
        alt: "Domain verified__export"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Zusätzliche Anmerkungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um das weitere Eigentum an der Domain sicherzustellen, überprüft HubSpot weiterhin regelmäßig, ob der TXT-Eintrag vorhanden ist. Die Installationswarnung wird zurückgegeben, wenn der TXT-Eintrag entfernt oder geändert wird."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Derzeit können Sie nur eine verifizierte Domain pro Entwickler-Account haben. Alle Apps in einem Account teilen die verifizierte Domain. Die Domain auf der Installationsseite verlinkt zu Ihrer Root-Domain."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie Ihre verifizierte Domain löschen, erhalten alle Apps in Ihrem Entwickler-Account erneut die Installationswarnung. Sie können eine andere Domain verifizieren, der Vorgang dauert jedoch einige Stunden."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}