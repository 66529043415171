"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 118019880814;
const slug = exports.slug = 'guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps';
const title = exports.title = 'Create and edit webhook subscriptions in private apps';
const name = exports.name = 'Create and edit webhook subscriptions in private apps';
const metaDescription = exports.metaDescription = 'Learn how to create and edit webhook subscriptions in private apps.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "limitations",
  "label": "Limitations",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-webhook-in-your-private-app",
  "label": "Create a webhook in your private app",
  "parent": null
}, {
  "depth": 0,
  "id": "review-and-test-webhook-subscriptions",
  "label": "Review and test webhook subscriptions",
  "parent": null
}, {
  "depth": 0,
  "id": "check-webhook-subscription-logs",
  "label": "Check webhook subscription logs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Create and edit webhook subscriptions in private apps"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhooks allow you to subscribe to events in a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private app"
      }), " that you've created in your HubSpot account. Rather than making an API call to query for event changes in your HubSpot account, you can subscribe to events which will trigger HubSpot to send an API request to an endpoint that you configure in your private app. Using webhooks allows your private app to be more scalable than having to regularly poll for changes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In your private app settings, you can subscribe to CRM object events, which includes contacts, companies, deals, tickets, products and line items, as well as conversations events."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limitations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following limitations apply to webhook subscriptions in private apps:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you're currently enrolled in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/overview",
          children: "CRM development tools beta"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/creating-private-apps",
          children: "private apps created in a project"
        }), " do ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " currently support webhook subscriptions. You can still follow the instructions in this article to create standalone private apps, separate from the ones you create in your projects, to create webhook subscriptions."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Managing your private app's webhook subscriptions via API is not currently supported. Subscriptions can only be managed in your private app settings."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A limit of 1000 webhook subscriptions applies per private app."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a webhook in your private app"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of your private app. If you haven't created a private app yet, follow the instructions in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#create-a-private-app",
          children: "this article"
        }), " to create one."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit webhooks"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Target URL"
        }), ", enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL"
        }), " that HubSpot will make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to when events trigger."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create subscription"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, configure your subscription:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "object types"
            }), " that you want to subscribe to, then select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "events"
            }), " associated with those objects (e.g., created, merged, deleted) that will trigger HubSpot to send a request to the endpoint you configured."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If you select an object type that requires scopes your app hasn't been authorized for, you'll be prompted to add those scopes to your app."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you select ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Property changed"
            }), " for the event type, you can then select any of the associated object properties that you want to subscribe to changes for."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Subscribe"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/create-new-webhook-subscription-in-private-app.png",
        alt: "create-new-webhook-subscription-in-private-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you don't want your webhook subscriptions to be active immediately, or if you want to delete a subscription you mistakenly created, you can hover over the webhook and manage its status, unsubscribe to delete it, or review the subscription details. If you've configured multiple webhook subscriptions, you can edit their statuses in bulk by selecting the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "checkboxes"
        }), " next to each one then clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activate"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Pause"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/edit-webhook-settings-in-private-app-1.png",
        alt: "edit-webhook-settings-in-private-app-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When you're ready to save your changes, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Commit changes"
        }), " in the top right."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you've configured your webhook subscriptions, HubSpot will begin sending ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " requests to your ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Target URL"
      }), " once any of the events associated with your active subscriptions are triggered."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " for each private app, HubSpot sets a concurrency limit of 10 requests when sending subscription event data. This concurrency limit is the maximum number of in-flight requests that HubSpot will attempt at a time. Each request can contain up to 100 events."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Review and test webhook subscriptions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Webhooks"
      }), " tab of your private app, you can review, edit, and test all your configured subscriptions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/review-webhooks-in-private-apps-dashboard.png",
        alt: "review-webhooks-in-private-apps-dashboard"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To edit a subscription, hover over it then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit subscriptions"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Hover over an existing subscription to pause, activate, or delete it. You can also click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Create subscription"
            }), " to configure a new webhook."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["When you're done making changes, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Commit changes"
            }), " in the top right. If the changes you made required changes to your private app's scopes, you'll be prompted to confirm the scope changes."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To test a webhook subscription, hover over it then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "View details"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "In the right panel, review the details of your webhook subscription, including the event payload that HubSpot will send in the request."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To test that your target URL is working properly, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Test"
            }), ". This will send the listed sample event payload to your configured target URL, and the resulting response from your endpoint will appear at the bottom of the panel."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/test-webhook-subscription-for-private-app.png",
        alt: "test-webhook-subscription-for-private-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To ensure that the requests you're receiving in your webhook endpoint are actually coming from HubSpot, HubSpot populates a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " header with a SHA-256 hash that's built using your private app's client secret along with data from the request itself. You can find your private app's client secret by navigating to the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Auth"
      }), " tab in your private app's settings, then clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Show secret"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/private-app-client-secret-for-request-validation.png",
        alt: "private-app-client-secret-for-request-validation"
      }), "Learn how to use your app's client secret to validate requests in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "this article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Check webhook subscription logs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To help you debug and analyze the volume of your configured subscriptions, webhook triggers and the corresponding responses from your endpoint will be provided in your private app logs."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the details page of your private app, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logs"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Review the history of your event subscription triggers, which includes both successful and unsuccessful API requests that HubSpot performed. At the top of the table, you can click the dropdown menus to filter by subscription type, status, time period, or you can search for specific log entries by batch ID or log ID."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/webhook-subscription-logs-in-private-app.png",
        alt: "webhook-subscription-logs-in-private-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To get more details about a log entry, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "entry"
        }), " to see additional details about when the event triggered, what fields were included in the payload, along with other metadata for the event."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}