"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61303889553;
const slug = exports.slug = 'guides/api/crm/engagements/email';
const title = exports.title = 'Interacciones | Correo electrónico';
const name = exports.name = 'Interacciones | Correo electrónico';
const metaDescription = exports.metaDescription = 'Usa la API de interacción de correo electrónico para registrar y gestionar correos electrónicos en registros de CRM. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-un-correo-electr%C3%B3nico",
  "label": "Crear un correo electrónico",
  "parent": null
}, {
  "depth": 1,
  "id": "propiedades",
  "label": "Propiedades",
  "parent": "crear-un-correo-electr%C3%B3nico"
}, {
  "depth": 1,
  "id": "propiedades-de-solo-lectura",
  "label": "Propiedades de solo lectura",
  "parent": "crear-un-correo-electr%C3%B3nico"
}, {
  "depth": 1,
  "id": "establecer-encabezados-de-correo-electr%C3%B3nico",
  "label": "Establecer encabezados de correo electrónico",
  "parent": "crear-un-correo-electr%C3%B3nico"
}, {
  "depth": 1,
  "id": "asociaciones",
  "label": "Asociaciones",
  "parent": "crear-un-correo-electr%C3%B3nico"
}, {
  "depth": 0,
  "id": "recuperar-correos-electr%C3%B3nicos",
  "label": "Recuperar correos electrónicos",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-correos-electr%C3%B3nicos",
  "label": "Actualizar correos electrónicos",
  "parent": null
}, {
  "depth": 1,
  "id": "asociar-correos-electr%C3%B3nicos-existentes-con-registros",
  "label": "Asociar correos electrónicos existentes con registros",
  "parent": "actualizar-correos-electr%C3%B3nicos"
}, {
  "depth": 1,
  "id": "eliminar-una-asociaci%C3%B3n",
  "label": "Eliminar una asociación",
  "parent": "actualizar-correos-electr%C3%B3nicos"
}, {
  "depth": 0,
  "id": "anclar-un-correo-electr%C3%B3nico-a-un-registro",
  "label": "Anclar un correo electrónico a un registro",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-correos-electr%C3%B3nicos",
  "label": "Eliminar correos electrónicos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Correo electrónico"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usa la API de interacción de correo electrónico para registrar y gestionar correos electrónicos en registros de CRM. Puedes registrar las actividades de correo electrónico ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "en HubSpot"
      }), " o a través de la API de correos electrónicos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, aprenderás los métodos básicos de gestión de correos electrónicos a través de la API. Para ver todos los puntos de terminación disponibles y sus requisitos, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear un correo electrónico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una interacción de correo electrónico, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el cuerpo de la solicitud, agrega detalles del correo electrónico en un objeto ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propiedades"
      }), ". También puedes agregar el objeto ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "asociaciones"
      }), " para asociar tu nuevo correo electrónico con un registro existente (por ejemplo, contactos, empresas)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el objeto propiedades, puedes incluir los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Requerido. Este campo marca la hora de creación del correo electrónico y determina dónde se encuentra el correo en la cronología del registro. Puedes usar una marca de tiempo Unix en milisegundos o en formato UTC."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID del propietario"
            }), " asociado con el correo electrónico. Este campo determina el usuario que aparece como creador del correo electrónico en la cronología del registro."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_direction"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La dirección con la que se envió en el correo Los valores posibles incluyen:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            }), ": el correo electrónico se envió desde el CRM o se envió y se registró en el CRM con la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
              children: "dirección CCO."
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "INCOMING_EMAIL"
            }), ": el correo electrónico era una respuesta a un correo saliente registrado. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FORWARDED_EMAIL"
            }), ": el correo electrónico se ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
              children: "reenvió al CRM."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El cuerpo de un correo electrónico si se envía desde un registro del CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El estado de envío del correo El valor puede ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BOUNCED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FAILED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SCHEDULED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENDING"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_subject"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La línea de asunto del correo electrónico registrado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El cuerpo del correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los ID de los archivos adjuntos del correo electrónico. Los ID de varios archivos adjuntos están separados por un punto y coma."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_headers"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Los encabezados del correo electrónico. El valor de esta propiedad rellenará automáticamente ciertas propiedades de correo electrónico de solo lectura. Aprende a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/email#set-email-headers",
              children: "configurar encabezados de correo electrónico."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre cómo crear interacciones de correo electrónico por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades de solo lectura"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También hay algunas propiedades de correo electrónico que son de solo lectura, las cuales son completadas automáticamente por HubSpot. Las propiedades de la tabla siguiente se rellenan automáticamente a partir del valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La dirección de correo electrónico del remitente del correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre del remitente del correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El apellido del remitente del correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Las direcciones de correo electrónico de los destinatarios del correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los nombres de los destinatarios del correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los apellidos del destinatario del correo electrónico."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " al recuperar un encabezado de correo electrónico, puedes notar que hay valores tanto para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), " como para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), ". Suelen ser los mismos, pero como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), " identifica lo que realmente envió un correo electrónico, hay escenarios en los que los valores pueden diferir. Por ejemplo, si se envía un correo electrónico desde un alias de correo electrónico, el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), " hará referencia a la dirección de correo electrónico real del usuario y el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), " al alias de correo electrónico."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Establecer encabezados de correo electrónico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dado que los encabezados rellenan automáticamente las propiedades de solo lectura, es posible que desees establecer manualmente los encabezados de correo electrónico. Para establecer el valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), ", puedes usar una cadena de escape JSON con los siguientes datos:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example data\n{\n  \"from\": {\n    \"email\": \"from@domain.com\",\n    \"firstName\": \"FromFirst\",\n    \"lastName\": \"FromLast\"\n  },\n  \"to\": [\n    {\n      \"email\": \"ToFirst ToLast<to@test.com>\",\n      \"firstName\": \"ToFirst\",\n      \"lastName\": \"ToLast\"\n    }\n  ],\n  \"cc\": [],\n  \"bcc\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, tu solicitud para crear un correo electrónico puede verse así:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"47550177\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for youremail\",\n    \"hs_email_headers\": \"{\\\"from\\\":{\\\"email\\\":\\\"from@domain.com\\\",\\\"firstName\\\":\\\"FromFirst\\\",\\\"lastName\\\":\\\"FromLast\\\"},\\\"sender\\\":{\\\"email\\\":\\\"sender@domain.com\\\",\\\"firstName\\\":\\\"SenderFirst\\\",\\\"lastName\\\":\\\"SenderLast\\\"},\\\"to\\\":[{\\\"email\\\":\\\"ToFirst+ToLast<to@test.com>\\\",\\\"firstName\\\":\\\"ToFirst\\\",\\\"lastName\\\":\\\"ToLast\\\"}],\\\"cc\\\":[],\\\"bcc\\\":[]}\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear y asociar un correo electrónico con registros existentes, incluye el objeto asociaciones en tu solicitud. Por ejemplo, para crear un correo electrónico y asociarlo con un negocio y un contacto, el cuerpo de tu solicitud podría verse de la siguiente manera:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 601\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 210\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 602\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 198\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el objeto asociaciones, debes incluir lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El registro que deseas asociar con el correo electrónico, especificado por su valor único ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de asociación entre el correo electrónico y el registro. Incluye la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " y el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Los ID de los tipos de asociación predeterminados se enumeran ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aquí"
            }), ", o puedes recuperar el valor de los tipos de asociación personalizados (es decir, etiquetas) a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar correos electrónicos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes recuperar correos electrónicos individualmente o en masa. Obtén más información sobre la recuperación por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar un correo electrónico individual por su ID de correo electrónico, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), ". También puedes incluir los siguientes parámetros en la URL de la solicitud:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades que se devolverán."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista separada por comas de los tipos de objetos para recuperar los ID asociados. No se devolverán en la respuesta las asociaciones especificadas que no existan. Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de asociaciones."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para solicitar una lista de todos los correos electrónicos, envía una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/emails"
      }), ". Puedes incluir los siguientes parámetros en la URL de la solicitud:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número máximo de resultados que se mostrarán por página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades que se devolverán."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar correos electrónicos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes actualizar los correos electrónicos individualmente o por lotes. Para actualizar un correo electrónico individual por su ID de correo electrónico, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el cuerpo de la solicitud, incluye las propiedades de correo electrónico que deseas actualizar. Por ejemplo, el cuerpo de tu solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk tomorrow\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect!\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignorará los valores de las propiedades de solo lectura e inexistentes. Para borrar un valor de propiedad, pasa una cadena vacía para la propiedad en el cuerpo de la solicitud."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la actualización por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociar correos electrónicos existentes con registros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asociar un correo electrónico con registros, como un contacto y sus empresas asociadas, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". La URL de la solicitud contiene los siguientes campos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emailId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El tipo de objeto con el que deseas asociar el correo electrónico (por ejemplo, contacto o empresa)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del registro con el que deseas asociar el correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un identificador único para indicar el tipo de asociación entre el correo electrónico y el otro objeto. El ID se puede representar numéricamente o con palabras separadas por guion bajo (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_to_contact"
            }), "). Puedes recuperar el valor a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, la URL de tu solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/emails/17691787884/associations/contact/104901/198"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eliminar una asociación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una asociación entre un correo electrónico y un registro, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " en la misma URL que la anterior:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anclar un correo electrónico a un registro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "anclar un correo electrónico"
      }), " a un registro para que permanezca en la parte superior de la cronología del registro. El correo electrónico ya debe estar asociado con el registro antes de anclar, y solo puedes anclar una actividad por registro. Para anclar un correo electrónico, incluye el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " del correo electrónico en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " al crear o actualizar un registro a través de las API de objetos. Obtén más información sobre el uso de las API de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "empresas"
      }), ",", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contactos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "negocios"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar correos electrónicos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando eliminas un correo electrónico, este se elimina permanentemente y ", (0, _jsxRuntime.jsx)("u", {
        children: "no se puede"
      }), " restaurar. Puedes eliminar correos electrónicos individualmente o por lotes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar un correo electrónico individual por su ID de correo electrónico, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la eliminación por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}