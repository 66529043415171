"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 64443492790;
const slug = exports.slug = 'guides/crm/private-apps/serverless-functions';
const title = exports.title = 'Fetching data with serverless functions (BETA)';
const name = exports.name = 'Fetching data with serverless functions (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to include serverless functions within a HubSpot project (BETA) to fetch data for private apps.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-serverless-function",
  "label": "Create a serverless function",
  "parent": null
}, {
  "depth": 1,
  "id": "configuration",
  "label": "Configuration",
  "parent": "create-a-serverless-function"
}, {
  "depth": 1,
  "id": "building-the-function",
  "label": "Building the function",
  "parent": "create-a-serverless-function"
}, {
  "depth": 1,
  "id": "endpoint-functions",
  "label": "Endpoint functions",
  "parent": "create-a-serverless-function"
}, {
  "depth": 0,
  "id": "authenticate-calls",
  "label": "Authenticate calls",
  "parent": null
}, {
  "depth": 1,
  "id": "managing-secrets",
  "label": "Managing secrets",
  "parent": "authenticate-calls"
}, {
  "depth": 0,
  "id": "best-practices",
  "label": "Best practices",
  "parent": null
}, {
  "depth": 2,
  "id": "variable-assignment-inside-functions",
  "label": "Variable assignment inside functions",
  "parent": "best-practices"
}, {
  "depth": 2,
  "id": "stay-up-to-date-with-the-latest-platform-version",
  "label": "Stay up to date with the latest platform version",
  "parent": "best-practices"
}, {
  "depth": 0,
  "id": "including-dependencies",
  "label": "Including dependencies",
  "parent": null
}, {
  "depth": 0,
  "id": "debug-a-serverless-function",
  "label": "Debug a serverless function",
  "parent": null
}, {
  "depth": 1,
  "id": "in-app-debugging",
  "label": "In-app debugging",
  "parent": "debug-a-serverless-function"
}, {
  "depth": 1,
  "id": "local-debugging",
  "label": "Local debugging",
  "parent": "debug-a-serverless-function"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      strong: "strong",
      a: "a",
      h1: "h1",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      img: "img",
      h2: "h2",
      h3: "h3",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink,
      ProductTier,
      DndSection,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the serverless function features described in this article are specifically for developer projects. This includes both UI extensions and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.hubspot.com/cms-react/",
          children: "CMS React modules"
        }), ". For information about building serverless functions for websites outside of developer projects, visit the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "CMS documentation"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Fetching data with serverless functions (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise', 'marketing_hub-enterprise', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When needing to fetch data for a project-based private app, whether for a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview",
        children: "UI extension"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/build-modules-and-partials-with-react",
        children: "CMS React project"
      }), ", you'll use a serverless function. When executed, HubSpot runs the serverless function server-side using JavaScript. Because HubSpot runs the function, you don't need to manage your own server."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your access to using serverless functions depends on your HubSpot subscription:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "UI extensions:"
        }), " using serverless functions for UI extensions requires an ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " subscription. You can also test out UI extensions for private apps for free in a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "developer test account"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS:"
        }), " using serverless functions for CMS React projects requires a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " subscription."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)("div", {
        children: [(0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Within the project file structure, serverless functions live in the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "src/app"
          }), " directory within a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "<AnyName>.functions"
          }), " folder. At the most basic level, this folder should include:"]
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["One or more JavaScript files that export a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "main"
            }), " function."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), " file that registers and configures your functions. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#configuration",
              children: "serverless function configuration"
            }), "."]
          }), "\n"]
        })]
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/example-project-serverless-structure-simple.png",
            alt: "example-project-serverless-structure-simple"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a serverless function"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Serverless functions for private apps in projects can be executed in two ways:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "App function:"
        }), " a serverless function that gets executed directly from within the project. The function is made available to UI extension's React file through the ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/guides/crm/ui-extensions/sdk#registering-the-extension",
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "runServerless"
          }), " argument"]
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Endpoint function"
        }), " (", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Content Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " only)", (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " an app function that is invoked by calling a public URL. The URL is determined by the account's connected domains and the path specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " file. This is more common for implementing serverless functions in CMS React modules."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " serverless functions cannot import other functions. You'll need to include all the code the serverless function needs to execute within the serverless function file."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configuration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the serverless functions directory, configure the serverless function with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " file. In this file, you'll configure your serverless function name and the path of the JavaScript file to execute, along with any secrets that might need to be included for authentication."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " subscription, you can configure a serverless function to be invoked by a public URL by including an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endpoint"
      }), " object in the config. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#endpoint-functions",
        children: "endpoint functions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example serverless.json\n{\n  \"appFunctions\": {\n    \"functionName\": {\n       \"file\": \"function1.js\",\n       \"secrets\": [\"SOME_SECRET\"],\n       \"endpoint\": {\n         \"path\": \"path/to/endpoint\",\n         \"method\": [\"GET\"]\n        }\n     }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".js"
            }), " file in the project to execute."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secrets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["To authenticate requests, you can include secrets as string values in this array. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#managing-secrets",
              children: "managing secrets"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoint"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["If invoking the function by a public URL (", (0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "Content Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " only), this object defines the endpoint details:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "path"
                }), ": the endpoint path."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "method"
                }), ": the request method."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Building the function"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each serverless function exports a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "main"
      }), " function that gets called when HubSpot executes it. The function receives the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context"
      }), " argument, which is an object that contains data based on how the function is being used. This includes context about where the card is loaded as well as the authenticated user and account. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#fetch-account-user-and-extension-context",
        children: "what's included in the context object"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is an example of a function that returns a 200 status code and a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Hello World"
      }), " message:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "//helloWorld.js\n\nexports.main = async (context) => {\n  return {\n    statusCode: 200,\n    body: { message: 'Hello World' },\n  };\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For a more complete example of an app function, HubSpot's boilerplate quickstart template includes the following function:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "When invoked, the function returns a response containing static text along with variable text (which is entered into the extension's input field)."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " file specifies the name of the function, which is used in the extension's React file. It also specifies where the function file is located in the project."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The extension includes invokes the function on button click, providing a text value to the function if text is included in the input field. The React file below is based on the boilerplate quickstart template, but has been simplified to highlight the serverless function."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "// example-function.js\nexports.main = async (context = {}) => {\n  const { text } = context.parameters;\n\n  const response = `This is coming from a serverless function! You entered: ${text}`;\n\n  return response;\n};\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"appFunctions\": {\n    \"myFunc\": {\n      \"file\": \"example-function.js\",\n      \"secrets\": []\n    }\n  }\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "import React, { useState } from 'react';\nimport { Button, Flex, Input, hubspot } from '@hubspot/ui-extensions';\n\n// Define the extension to be run within the Hubspot CRM\nhubspot.extend(({ context, runServerlessFunction, actions }) => (\n  <Extension\n    context={context}\n    runServerless={runServerlessFunction}\n    sendAlert={actions.addAlert}\n  />\n));\n\n// Define the Extension component, taking in runServerless, context, & sendAlert as props\nconst Extension = ({ context, runServerless, sendAlert }) => {\n  const [text, setText] = useState('');\n\n  // Call serverless function to execute with parameters.\n  // The `myFunc` function name is configured inside `serverless.json`\n  const handleClick = async () => {\n    const { response } = await runServerless({\n      name: 'myFunc',\n      parameters: { text: text },\n    });\n    sendAlert({ message: response });\n  };\n\n  return (\n    <Flex direction=\"row\" align=\"end\" gap=\"small\">\n      <Input name=\"text\" label=\"Send\" onInput={(t) => setText(t)} />\n      <Button type=\"submit\" onClick={handleClick}>\n        Click me\n      </Button>\n    </Flex>\n  );\n};\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " serverless functions have a response limit of 15 seconds. Functions that take longer to execute will fail."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Endpoint functions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " subscription, you can configure a serverless function to be invoked by a public URL. When calling the URL of a HubSpot-hosted serverless function, you can use any ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/domains-and-urls/connect-a-domain-to-hubspot",
        children: "domain connected to your account"
      }), " with the following URL structure: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://<domain>/hs/serverless/<endpoint-path-from-config>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if both ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "website.com"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "subdomain.brand.com"
      }), " are connected to the account, you could call the function using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://website.com/hs/serverless/path/to/endpoint"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://subdomain.brand.com/hs/serverless/path/to/endpoint"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the URL to call the function, the endpoint path is global rather than scoped to the app or project. If you have identical endpoint paths across multiple apps or projects, the most recently deployed endpoint function will take precedence."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example serverless.json\n{\n  \"appFunctions\": {\n    \"functionName\": {\n       \"file\": \"somefunction.js\",\n       \"secrets\": [\"SOME_SECRET\"],\n       \"endpoint\": {\n          \"path\": \"path/to/endpoint\",\n          \"method\": [\"GET\"]\n        }\n     }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authenticate calls"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When developing a private app with projects, each private app comes with a private access token that you can use to authenticate calls to HubSpot's APIs. You can also authenticate calls using secrets, which you'll ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#managing-secrets",
        children: "manage through the CLI"
      }), ". To make your private app access token or secrets ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#managing-secrets",
        children: "available for local development"
      }), ", you'll need to create a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".env"
      }), " file in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " directory."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Pleas note:"
        }), " calls authenticated with private app access tokens count against your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "API call limits"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you wanted to use Node to make a request to a HubSpot API:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/overview#get-started",
          children: "@hubspot/api-client library"
        }), " is ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#including-dependencies",
          children: "included as a dependency"
        }), " in your project."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Include ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot/api-client"
        }), " in function file."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Instantiate it within ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "exports.main"
        }), " and include ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PRIVATE_APP_ACCESS_TOKEN"
        }), " for authentication:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Include HubSpot node API client\nconst hubspot = require('@hubspot/api-client');\n\nexports.main = async (context = {}) => {\n  // Get hs_object_id of the record in context\n  const { hs_object_id } = context.propertiesToSend;\n  console.log(`Looking up contact by ID [${hs_object_id}]`);\n\n  // Instantiate HubSpot Node API client\n  const hubspotClient = new hubspot.Client({\n    accessToken: process.env['PRIVATE_APP_ACCESS_TOKEN'],\n  });\n\n  // Your function\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then configure the rest of the function using Node.js. For example, the following code would create a serverless function that retrieves the current contact by ID using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "contacts API"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Include HubSpot node API client\nconst hubspot = require('@hubspot/api-client');\n\nexports.main = async (context = {}) => {\n  // Get hs_object_id of the record in context\n  const { hs_object_id } = context.propertiesToSend;\n  console.log(`Looking up contact by ID [${hs_object_id}]`);\n\n  // instantiate HubSpot Node API client\n  const hubspotClient = new hubspot.Client({\n    accessToken: process.env['PRIVATE_APP_ACCESS_TOKEN'],\n  });\n  const properties = ['firstname', 'lastname'];\n\n  try {\n    const apiResponse = await hubspotClient.crm.contacts.basicApi.getById(\n      hs_object_id,\n      properties\n    );\n    console.log(JSON.stringify(apiResponse));\n  } catch (e) {\n    e.message === 'HTTP request failed'\n      ? console.error(JSON.stringify(e.response, null, 2))\n      : console.error(e);\n  }\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To get started, you can find Node.js code snippets on the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Endpoints"
        }), " tabs of ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "HubSpot's API docs."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Managing secrets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your serverless function requires secrets, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), " field in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " configuration file. Follow the steps below to make them accessible when the function is deployed and when running the local development server."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create your secrets by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add <secret name>"
        }), ". HubSpot will securely store this secret on its backend and inject them into the runtime environment when a function is invoked in production."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " file, list the names of the secrets needed by each function. Do not include ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PRIVATE_APP_ACCESS_TOKEN"
        }), " in this array, as this is automatically created for you and already available in the serverless function."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// serverless.json\n{\n  \"appFunctions\": {\n    \"functionName\": {\n      \"file\": \"function1.js\",\n      \"secrets\": [\"GITHUB_ACCESS_TOKEN\"]\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To make secrets available for local development, create a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".env"
        }), " file in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<AnyName>.functions"
        }), " directory. HubSpot will never retrieve your secrets outside of its protected infrastructure, so you'll need to specify the secret values that you want to use when the function is executed locally. If your function uses ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PRIVATE_APP_ACCESS_TOKEN"
        }), ", you'll need to copy the private app's access token from HubSpot and store it in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".env"
        }), " file for local access. The following snippet demonstrates what an example ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".env"
        }), " file might look like:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "PRIVATE_APP_ACCESS_TOKEN=pat-na1-******\nANOTHER_SECRET=my-secret-value\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After saving secrets to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".env"
        }), " file, you can access them in your function using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "process.env[\"<keyName>\"]"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "export const main = async (context) => {\n  const secret = process.env[\"ANOTHER_SECRET\"];\n  const token = process.env[\"PRIVATE_APP_ACCESS_TOKEN\"];\n  ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To update a secret's value, you can run the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets update"
        }), " command. If you're using a Node runtime of 14 or higher, updated secret values will automatically be updated in your deployed function within one minute, meaning you won't have to build and deploy to get the updated secret."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "To limit exposure of a secret, it's strongly recommended to never include it in console statements to prevent it from being recorded in logs."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["If your project is ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/developer-projects/link-a-github-repository-to-a-project",
            children: "linked to a GitHub repository"
          }), ", be sure to ", (0, _jsxRuntime.jsx)("u", {
            children: "never"
          }), " commit the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: ".env"
          }), " file when uploading to GitHub. You can include an entry for ", (0, _jsxRuntime.jsx)(_components.code, {
            children: ".env"
          }), " in your ", (0, _jsxRuntime.jsx)(_components.code, {
            children: ".gitignore"
          }), " file to ensure that it is omitted from your commits."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Best practices"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Keep the following recommendations in mind while you develop and test your serverless function:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Variable assignment inside functions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To ensure that variables are correctly assigned and initialized with every function invocation, you should opt to assign variables within the function itself. This practice prevents potential issues related to stale or persistent variable states, which can lead to unexpected behaviors. See the example below for additional context:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Preferred\nexports.myHandler = async (event, context) => {\n  const myDynamicVariable = process.env['SOME_KEY'];\n  // The rest of your handler logic\n};\n\n// Discouraged\nconst myDynamicVariable = process.env['SOME_KEY'];\nexports.myHandler = async (event, context) => {\n  // The rest of your handler logic\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Stay up to date with the latest platform version"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsprojects.json"
      }), " configuration file includes a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "platformVersion"
      }), " field which specifies which ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/platform-versioning",
        children: "platform version"
      }), " to run the project on. It's strongly encouraged to use the latest platform version to ensure that your project and its assets are up to date with the latest improvements, optimizations, and feature enhancements. In addition, some previously available features may not be available in older versions due to deprecation."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The platform version also dictates which version of Node the project runs on. The latest version, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2023.2"
      }), ", uses Node18, and doesn't support older versions of Node."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Including dependencies"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, HubSpot provides a small number of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.npmjs.com/",
        children: "NPM"
      }), " dependencies in addition to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/download/",
        children: "Node.js"
      }), " standard library. To add your own dependencies, you can list the package in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dependencies"
      }), " within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "package.json"
      }), " file. When the app is built, dependencies will be bundled with your function code. All dependencies must be published to NPM and be public."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you wanted to add the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.npmjs.com/package/lodash",
        children: "lodash"
      }), " library in a serverless function, you would first update ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "package.json"
      }), " to include the dependency:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// package.json\n{\n  \"name\": \"app.functions\",\n  \"version\": \"1.0.0\",\n  \"description\": \"\",\n\n  \"dependencies\": {\n    \"lodash\": \"^4.17.21\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Then, at the top of your serverless function JavaScript file, you would require the lodash dependency:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// myFunction.js\n\nconst _ = require('lodash');\n\nexports.main = async (context) => {\n  const objectA = {};\n  const objectB = {};\n  const equal = _.isEqual(objectA, objectB);\n  return {\n    statusCode: 200,\n    body: {\n      isEqual: equal,\n    },\n  };\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Debug a serverless function"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Log messages are produced every time HubSpot executes a serverless function. Below, learn how to access logs in HubSpot and locally using the CLI."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "In-app debugging"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In HubSpot, you can view a serverless function's log history for both app functions and endpoint functions, including successful requests and errors."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To access a serverless function's logs in HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM Development"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "private app"
        }), " that contains the serverless function."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logs"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To view logs for a serverless app function, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Serverless functions"
        }), " tab. To view logs for a serverless endpoint function, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Endpoint functions"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In each tab, you can view logs for specific requests by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "request"
        }), ". You can also use the search bar to search by request ID."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, you can then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "View log trace"
        }), " for a more in-depth breakdown of the request."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/view-serverless-app-function-logs.gif",
        alt: "view-serverless-app-function-logs"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log()"
      }), " in your serverless function code for debugging purposes, then view its output in the function log details sidebar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Local debugging"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Log messages are produced every time HubSpot executes a serverless function. To view a serverless function's logs in the CLI, run the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project logs"
      }), " command. Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/project-cli-commands#view-logs",
        children: "hs project logs command"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are two types of log messages produced:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Log messages that record the execution of a function, along with its status and timing. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2021-04-28T19:19:21.666Z - SUCCESS - Execution Time: 279ms"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Log messages that are produced through console statements in the function code. For example, your serverless function JavaScript might include:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// helloWorld.js\n\nexports.main = async (context) => {\n  console.log('Log some debug info');\n  console.error('An error occurred');\n  return {\n    statusCode: 200,\n    body: { message: 'Hello World' },\n  };\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A log output for the above code would then produce the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "2021-04-28T19:15:13.200Z    INFO   Log some debug info"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "2021-04-28T19:15:14.200Z    ERROR    An error occurred"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}