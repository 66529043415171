"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501378062;
const slug = exports.slug = 'guides/api/marketing/emails/transactional-emails';
const title = exports.title = 'API de Marketing |Correos electrónicos transaccionales ';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Transactional Emails';
const metaDescription = exports.metaDescription = 'Un resumen de la característica Correo electrónico transaccional en HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "m%C3%A9todos-para-enviar-correo-electr%C3%B3nico-transaccional",
  "label": "Métodos para enviar correo electrónico transaccional",
  "parent": null
}, {
  "depth": 0,
  "id": "api-smtp",
  "label": "API SMTP",
  "parent": null
}, {
  "depth": 1,
  "id": "crear-un-nuevo-token-api-smtp",
  "label": "Crear un nuevo token API SMTP",
  "parent": "api-smtp"
}, {
  "depth": 1,
  "id": "recuperar-tokens-de-smtp",
  "label": "Recuperar tokens de SMTP",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "lista-de-tokens-de-api-smtp-por-campa%C3%B1a",
  "label": "Lista de tokens de API SMTP por campaña",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "consulta-un-solo-token-api-smtp",
  "label": "Consulta un solo token API SMTP",
  "parent": "api-smtp"
}, {
  "depth": 3,
  "id": "detalles-de-la-respuesta",
  "label": "Detalles de la respuesta",
  "parent": "api-smtp"
}, {
  "depth": 1,
  "id": "administrar-tokens-existentes",
  "label": "Administrar tokens existentes",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "restablecer-contrase%C3%B1a",
  "label": "Restablecer contraseña",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "eliminar-un-token",
  "label": "Eliminar un token",
  "parent": "api-smtp"
}, {
  "depth": 1,
  "id": "iniciar-sesi%C3%B3n-en-el-servidor-smtp-de-hubspot",
  "label": "Iniciar sesión en el servidor SMTP de HubSpot",
  "parent": "api-smtp"
}, {
  "depth": 0,
  "id": "api-de-env%C3%ADo-individual",
  "label": "API de envío individual",
  "parent": null
}, {
  "depth": 1,
  "id": "crear-y-publicar-tu-plantilla-de-correo-electr%C3%B3nico",
  "label": "Crear y publicar tu plantilla de correo electrónico",
  "parent": "api-de-env%C3%ADo-individual"
}, {
  "depth": 1,
  "id": "env%C3%ADa-el-correo-electr%C3%B3nico-utilizando-la-api-de-env%C3%ADo-%C3%BAnico",
  "label": "Envía el correo electrónico utilizando la API de envío único",
  "parent": "api-de-env%C3%ADo-individual"
}, {
  "depth": 1,
  "id": "solicitar-propiedades",
  "label": "Solicitar propiedades",
  "parent": "api-de-env%C3%ADo-individual"
}, {
  "depth": 2,
  "id": "emailid",
  "label": "emailId",
  "parent": "api-de-env%C3%ADo-individual"
}, {
  "depth": 2,
  "id": "message",
  "label": "message",
  "parent": "api-de-env%C3%ADo-individual"
}, {
  "depth": 2,
  "id": "contactproperties",
  "label": "contactProperties",
  "parent": "api-de-env%C3%ADo-individual"
}, {
  "depth": 2,
  "id": "customproperties",
  "label": "customProperties",
  "parent": "api-de-env%C3%ADo-individual"
}, {
  "depth": 1,
  "id": "consulta-el-estado-de-un-env%C3%ADo-de-correo-electr%C3%B3nico",
  "label": "Consulta el estado de un envío de correo electrónico",
  "parent": "api-de-env%C3%ADo-individual"
}, {
  "depth": 2,
  "id": "sendresult",
  "label": "sendResult",
  "parent": "api-de-env%C3%ADo-individual"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      strong: "strong",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      code: "code",
      h4: "h4",
      h5: "h5",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Correo electrónico transaccional"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tienes el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/email/transactional-email",
        children: "complemento de correo electrónico transaccional"
      }), ", puedes enviar correos electrónicos a través de una dirección IP dedicada para obtener recibos de comercio, actualizaciones de cuentas, cambios de servicio y otras transacciones comerciales esenciales."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los correos electrónicos transaccionales son interacciones basadas en relaciones, a diferencia de los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/emails/marketing-emails",
        children: "correos electrónicos de marketing"
      }), ", que generalmente se usan para promocionar contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Métodos para enviar correo electrónico transaccional"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay tres maneras de implementar correo electrónico transaccional:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
          children: "Configurar correo electrónico transaccional en la aplicación"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#smtp-api",
          children: "API SMTP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#single-send-api",
          children: "API de envío individual"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Método"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Vista general"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Ejemplo de uso"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Correo electrónico transaccional en la aplicación"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Crea correos electrónicos transaccionales usando el editor de correo electrónico de HubSpot.Esto proporciona los mismos beneficios de los correos electrónicos estándar de HubSpot, como contenido inteligente, personalización y plantillas.Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
              children: "configurar correos electrónicos transaccionales en la aplicación"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envía un correo electrónico de actualización de política a tus clientes con un enlace a una nueva página de política. Esta es una actualización de servicio, no un correo electrónico de marketing, por lo que no necesitas incluir enlaces de suscripción (por ejemplo, enlaces CAN-SPAM). No necesitas usar ninguna propiedad o información personalizada de sistemas externos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "API SMTP"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Envía correos electrónicos transaccionales a través de tu propio sitio o aplicación mientras también haces seguimiento del rendimiento de los correos electrónicos y creas información de contacto dentro de HubSpot. La capacidad opcional para crear información de contacto se basa en la creación del token de smtp.Más información en la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#smtp-api",
              children: "sección API SMTP a continuación"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enviar un correo electrónico de confirmación de registro de cuenta desde un sistema de correo electrónico transaccional separado, además de hacer seguimiento del rendimiento de los correos electrónicos y crear contactos en HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "API de envío individual"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una combinación de correo electrónico transaccional y API SMTP.Crea correos electrónicos transaccionales usando el editor de correo electrónico de HubSpot y agrega tokens externos personalizados a tu correo electrónico que puedes enviar a HubSpot a través de la API.Más información en la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#single-send",
              children: "API de envío individual abajo"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envía un correo electrónico de recibo de compra a tu cliente usando HubSpot. El correo electrónico se activa cuando se realiza la compra y pasa valores personalizados de otro sistema (por ejemplo, artículo comprado y total de compra). Además, hace seguimiento del rendimiento de este correo electrónico en HubSpot."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " no se hará seguimiento de los contactos con copia en un correo electrónico transaccional y el correo no aparecerá en la cronología del contacto con copia."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API SMTP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El correo electrónico transaccional enviado usando la API SMTP se activa automáticamente según criterios específicos, como hacer una compra en un sitio web de comercio electrónico. Esta API se integra con cualquier sistema interno o de terceros para activar el correo electrónico e incorporar datos almacenados fuera de HubSpot (por ejemplo, información de envío o precio de compra). El correo se envía desde tu sistema, pero está con códigos de seguimiento de HubSpot que permiten el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
        children: "seguimiento completo de las interacciones y la medición"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar un correo usando la API SMTP, debes usar un token API SMTP para obtener credenciales de inicio de sesión para el servidor SMTP de HubSpot. Una vez que inicies sesión en el servidor, puedes enviar el correo electrónico a SMTP. Si no has creado tokens API SMTP, primero deberás ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#create-a-new-smtp-api-token",
        children: "generar un nuevo token"
      }), ". Si ya has creado tokens API SMTP, descubre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-existing-smtp-api-tokens",
        children: "diferentes métodos para obtener tus tokens a través de la API"
      }), ". Después de obtener tu token, descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "iniciar sesión en el servidor SMTP de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cualquier dominio que utilices como ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "dirección de remitente"
      }), " de tus correos electrónicos debe estar conectado como un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/domains-and-urls/connect-your-email-sending-domain",
        children: "dominio de envío de correo electrónico"
      }), " en HubSpot. Encontrarás un error si envías correos electrónicos transaccionales a través de la API SMTP usando un dominio que no está autorizado a enviar en nombre de tu cuenta de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": todos los métodos de la API SMTP requieren un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "token de OAuth"
        }), " para la autenticación."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si lo prefieres, todos los métodos a continuación para crear, recuperar, eliminar y restablecer contraseñas para tokens de API SMTP se pueden hacer ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "dentro de tu cuenta de HubSpot"
        }), ", en lugar de la API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear un nuevo token API SMTP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear un nuevo token API SMTP, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El cuerpo de la solicitud debe ser un objeto con formato JSON con las siguientes propiedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indica si se debe crear un contacto para destinatarios de correo electrónico."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": un nombre para la campaña asociada con el token API SMTP."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La respuesta incluye el objeto token de SMTP, que contiene:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": nombre de usuario para iniciar sesión en el servidor SMTP de HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": dirección de correo electrónico del usuario que envió la solicitud de creación de token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "password"
        }), ": la contraseña para iniciar sesión en el servidor SMTP de HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": el identificador asignado a la campaña proporcionada en la solicitud de creación de token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": la marca de tiempo generada cuando se crea un token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indica si se debe crear un contacto para los destinatarios de correo electrónico."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": el nombre de la campaña asociada con el token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con tu token creado, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "iniciar sesión en el servidor SMTP de HubSpot"
      }), " usando los valores de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contraseña"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La contraseña de un token solo se puede recuperar en el momento de la creación. Si pierdes la contraseña o quieres establecer una contraseña nueva, deberás ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manage-existing-tokens",
        children: "restablecer la contraseña del token"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " los tokens de la API SMTP generados a través de la API pública vencen después de 12 meses. Una vez vencidos, se eliminan automáticamente. Los tokens creados directamente ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "en tu cuenta de HubSpot"
        }), " ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " vencen automáticamente."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Recuperar tokens de SMTP"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, aparecen los métodos disponibles para obtener datos de token usando la API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Lista de tokens de API SMTP por campaña"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener una lista de tokens por nombre de campaña u obtener un solo token por ID de campaña, realiza una solicitud de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También tendrás que incluir un parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "campaignName"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailCampaignId"
      }), " con la solicitud. Puedes encontrar todos los detalles de la solicitud en la pestaña Puntos finales en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Detalles de la respuesta"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La respuesta contiene ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "paging"
      }), " como sus campos de nivel superior:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "results"
        }), ": una recopilación de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "SmtpApiTokenView"
        }), " que contiene:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), ": nombre de usuario para iniciar sesión en el servidor SMTP de HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            }), ": dirección de correo electrónico del usuario que envió la solicitud de creación de token."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "emailCampaignId"
            }), ": el identificador asignado a la campaña proporcionada en la solicitud de creación de token."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            }), ": la marca de tiempo generada cuando se crea un token."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createContact"
            }), ": indica si se debe crear un contacto para los destinatarios de correo electrónico."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "campaignName"
            }), ": el nombre de la campaña asociada con el token."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "paging"
        }), ": contiene un campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "next.after"
        }), " que se puede usar para solicitar más resultados."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Consulta un solo token API SMTP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para consultar un solo token API SMTP por ID, realiza una solicitud de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Detalles de la respuesta"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La respuesta incluye ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), ", que contiene:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": nombre de usuario para iniciar sesión en el servidor SMTP de HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": dirección de correo electrónico del usuario que envió la solicitud de creación de token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": el identificador asignado a la campaña proporcionada en la solicitud de creación de token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": la marca de tiempo generada cuando se crea un token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indica si se debe crear un contacto para los destinatarios de correo electrónico."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": el nombre de la campaña asociada con el token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Administrar tokens existentes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de crear tokens, puedes restablecer una contraseña o eliminar el token usando la API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Restablecer contraseña"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para restablecer una contraseña de token, realiza una solicitud POST ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}/password-reset"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La respuesta incluye ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), ", que contiene:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": nombre de usuario para iniciar sesión en el servidor SMTP de HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": dirección de correo electrónico del usuario que envió la solicitud de creación de token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": el identificador asignado a la campaña proporcionada en la solicitud de creación de token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": la marca de tiempo generada cuando se crea un token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indica si se debe crear un contacto para los destinatarios de correo electrónico."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": el nombre de la campaña asociada con el token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Eliminar un token"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar un token de API SMTP, realiza una solicitud de DELETE ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La respuesta no incluye ningún contenido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Iniciar sesión en el servidor SMTP de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, aparecen los detalles de la sesión en el servidor SMTP de HubSpot, usando el nombre de usuario (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ") y la contraseña proporcionada por tu token."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nombre de host de SMTP:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si no estás en la UE, usa ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp.hubapi.com"
            }), " para el nombre del host."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si estás en la UE, usa ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp-eu1.hubapi.com"
            }), " para el nombre del host."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Puerto SMTP:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Para STARTTLS, puedes usar el puerto 25 o 587."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Para TLS directo, usa el puerto 465."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nombre del usuario SMTP:"
        }), " proporcionada en el campo ID"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contraseña SMTP:"
        }), " proporcionada en el campo de contraseña"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API de envío individual"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La API de envío individual envía correos electrónicos de plantillas creados en la herramienta de correo electrónico de HubSpot usando una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " con formato JSON. Cualquier correo electrónico enviado a través de esta API se asociará automáticamente con registros de contactos según la dirección de correo electrónico. Si no hay contacto con una dirección de correo electrónico coincidente, se creará un nuevo contacto con ese correo. Si quieres enviar correos electrónicos sin crear contactos, usa la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "API SMTP"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear y publicar tu plantilla de correo electrónico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La API de envío individual envía correos electrónicos de plantillas creados en la herramienta de correo electrónico de HubSpot usando una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " con formato JSON. Cualquier correo electrónico enviado a través de esta API se asociará automáticamente con registros de contactos según la dirección de correo electrónico. Si no hay contacto con una dirección de correo electrónico coincidente, se creará un nuevo contacto con ese correo. Si quieres enviar correos electrónicos sin crear contactos, usa la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "API SMTP"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Primero, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
        children: "configura tu correo electrónico en HubSpot"
      }), ". Después de crear el correo electrónico, puedes establecer los detalles del destinatario, incluyendo cualquier propiedad de contacto o personalización establecida en la plantilla de correo electrónico, en el cuerpo de la solicitud de API. Para de poder realizar la solicitud de API, necesitarás el ID del correo electrónico:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si dejas el correo electrónico redactado sin publicarlo, puedes obtener el ID de correo electrónico de la URL cuando estés en el editor de correo electrónico. El ID es el valor numérico final antes del carácter de barra oblicua final (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/"
        }), ") en la URL (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/email/ {PORTAL_ID}/edit/{EMAIL_ID}/settings"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/email-id-for-drafted-single-send-api-email.png",
        alt: "email-id-for-drafted-single-send-api-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si publicas tu correo electrónico, puedes copiar el ID de correo electrónico desde la página de detalles del correo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-15%20at%201.00.37%20PM.png",
        alt: "Screen Shot 2020-04-15 at 1.00.37 PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " HubSpot no guarda el código HTML/JSON enviado a través de esta API. Puedes revisar la plantilla de correo electrónico desde la cronología del contacto del destinatario, pero si quieres mantener un registro del contenido del correo electrónico, se recomienda agregar un destinatario con copia oculta al correo electrónico."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Envía el correo electrónico utilizando la API de envío único"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar un correo electrónico con la API de envío individual, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/single-email/send"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La respuesta contiene los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), ": la marca de tiempo de cuando se solicitó el envío."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), ": un identificador que se puede usar para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-the-status-of-an-email-send",
            children: "consultar el estado del envío"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), ": el estado de la solicitud de envío. Incluye ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Solicitar propiedades"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El cuerpo de la solicitud debe ser un objeto con formato JSON con las siguientes propiedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "message"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contactProperties"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "emailId"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailId"
      }), " contiene el ID de contenido del correo transaccional, que se puede encontrar en la herramienta de correo electrónico de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "message"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El campo de mensaje es un objeto JSON que contiene cualquier cosa que deseas anular. Como mínimo, debes incluir el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "to"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Campo de objeto de mensaje:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "to"
        }), ": el destinatario del correo electrónico"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "from"
        }), ": el encabezado «De» para el correo electrónico. Puedes definir un nombre de remitente con el siguiente formato: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "“De”:“Nombre del remitente <sender@hubspot.com>”"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), ": el ID de un envío específico. Solo se enviará un correo electrónico ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), " con un envío determinado por cuenta, por lo que puedes incluir este campo para evitar envíos de correos duplicados."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "replyTo"
        }), ": una lista JSON de valores de encabezado de “Responder a” para el correo electrónico."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "cc"
        }), ": una lista JSON de direcciones de correo electrónico para enviar como CC."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "bcc"
        }), ": una lista JSON de direcciones de correo electrónico para enviar como CCO."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "contactProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactProperties"
      }), " es un mapa JSON de valores de propiedad de contacto. Cada valor de propiedad de contacto contiene un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "nombre"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "valor"
      }), ". Cada propiedad se establecerá en el registro de contacto y será visible en la plantilla en:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/name-token-in-template-for-transactional-email.png",
        alt: "name-token-in-template-for-transactional-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usa estas propiedades cuando desees establecer una propiedad de contacto mientras estás enviando el correo electrónico. Por ejemplo, cuando envíes un recibo, es recomendable que establezcas una propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "last_paid_date"
      }), ", ya que el envío del recibo tendrá información sobre el último pago."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"contactProperties\": {\n    \"last_paid_date\": \"2022-03-01\",\n    \"firstname\": \"jane\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "customProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " es un mapa JSON de valores clave de propiedad. Estas propiedades generalmente se relacionan con el correo electrónico en sí; no con el contacto que recibe el correo electrónico. Estos no aparecerán en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/create-a-web-version-of-your-marketing-email",
        children: "versión de página web del correo electrónico"
      }), " ni en la vista del correo electrónico de la cronología del contacto. Estas propiedades tampoco se almacenan en HubSpot y solo se incluirán en el correo electrónico enviado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se puede hacer referencia a cada clave en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " en la plantilla utilizando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "expresión HubL"
      }), " para los campos contenidos dentro de la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "custom"
      }), " (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ custom.NAME_OF_PROPERTY }}"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, si tu plantilla de correo electrónico hace referencia a dos propiedades: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "purchaseUrl"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "productName"
      }), "; podrías proporcionar los valores asociados para estas propiedades con el siguiente cuerpo de solicitud:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"customProperties\": {\n    \"purchaseUrl\": \"https://example.com/link-to-product\",\n    \"productName\": \"vanilla\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes hacer referencia a estas propiedades en tu plantilla de correo electrónico:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Congrats on purchasing some of the best ice cream around.\n  </p>\n  <a href={{custom.purchaseUrl}}>{{custom.productName}}</a>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " solo admite matrices cuando se usa con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "contenido de correo electrónico programable"
      }), ". En tu plantilla de correo electrónico, puedes hacer referencia a los elementos definidos en tu campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " usando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "expresión HubL"
      }), " (por ejemplo, usando un bucle ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for"
      }), " para representar cada elemento en una lista). Por ejemplo, si las ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CustomProperties"
      }), " que incluyó en el cuerpo de la solicitud se estructuraron como el siguiente fragmento de código JSON a continuación:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643122,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"7\"\n  },\n  \"customProperties\": {\n    \"exampleArray\": [\n      {\"firstKey\": \"someValue\", \"secondKey\": \"anotherValue\"},\n      {\"firstKey\": \"value1\", \"secondKey\": \"value2\" }\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes hacer referencia a los valores de cada elemento en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exampleArray"
      }), " con el siguiente código HubL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Thanks for your recent purchase! Here are the details of the items you'll be receiving:\n  </p>\n  <ul>\n    {% for item in custom.exampleArray %}\n      <li>First key: {{ item.firstKey }}, Second key: {{item.secondKey}}</li>\n    {% endfor %}\n  </ul>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez enviado, el correo electrónico transaccional representaría el contenido de la plantilla de correo electrónico programable asociada de la siguiente manera:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-transactional-email-with-customProperties-array.png",
        alt: "example-transactional-email-with-customProperties-array"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Consulta el estado de un envío de correo electrónico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener el estado del envío de correo electrónico, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/marketing/v3/email/send-statuses/{statusId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La respuesta contiene los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "sendResult"
          }), ": una enumeración que representa el estado de envío del correo electrónico. Los valores posibles se ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#sendresult-values",
            children: "enumeran a continuación"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), ": la marca de tiempo desde que se solicitó el envío."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "startedAt"
          }), ": la marca de tiempo cuando el envío comenzó a procesarse."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "completedAt"
          }), ": la marca de tiempo cuando el envío finalizó."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), ": un identificador que se puede usar para consulta el estado del envío."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), ": el estado de la solicitud de envío. Incluye ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "eventId"
          }), ": si se envía, el ID y la marca de tiempo creados del evento enviado."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendResult"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sendResult"
      }), " es una enumeración que refleja el resultado de un intento de envío de correo electrónico. Sus valores posibles son:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "SENT"
        }), ": el correo se envió correctamente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "QUEUED"
        }), ": el correo se puso en cola y se enviará a medida que se procese la cola."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PORTAL_SUSPENDED"
        }), ": debido a violaciones de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/acceptable-use",
          children: "Política de uso aceptable"
        }), ", el correo electrónico del cliente de HubSpot ha sido suspendido."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_TO_ADDRESS"
        }), ": la dirección del destinatario no es válida. Este error también se producirá si intentas enviar un correo con cualquiera de los siguientes prefijos basados en roles en la dirección de correo electrónico: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "abuse"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "no-reply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "noreply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "root"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "spam"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "security"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undisclosed-recipients"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "unsubscribe"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inoc"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "postmaster"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "privacy"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOCKED_DOMAIN"
        }), ": el dominio no puede recibir correos electrónicos de HubSpot en este momento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUSLY_BOUNCED"
        }), ": el destinatario rebotó previamente y la lógica de envío no generó ningún envío."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUS_SPAM"
        }), ": el destinatario marcó previamente un correo similar como spam."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_FROM_ADDRESS"
        }), ": la dirección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "De"
        }), " no es válida."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_CONTENT"
        }), ": el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " no es válido o el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " corresponde a un correo electrónico que no se configuró para envío único."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_TEMPLATE_PROPERTIES"
        }), ": hay propiedades configuradas en la plantilla que no se han incluido en las ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        }), " enviadas en la solicitud."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}