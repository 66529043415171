'use es6';

export function stylize(config) {
  const {
    value
  } = config;
  if (Array.isArray(value)) {
    return encodeArray(config);
  }
  if (typeof value === 'object') {
    return encodeObject(config);
  }
  return encodePrimitive(config);
}
export function encodeDisallowedCharacters(str, {
  escape
} = {}, parse) {
  if (typeof str === 'number') {
    str = str.toString();
  }
  if (typeof str !== 'string' || !str.length) {
    return str;
  }
  if (!escape) {
    return str;
  }
  if (parse) {
    return JSON.parse(str);
  }

  // Why is there no encoding you ask?
  // Please refer to https://git.hubteam.com/HubSpot/developer-documentation-planning/issues/131
  // Divergence from copied source https://github.com/readmeio/api-explorer/blob/320d3cba7c8b0816479570bec0970eab156e0412/packages/oas-to-har/src/lib/style-formatting/style-serializer.js#L57-L60
  return str;
}
function encodeArray({
  key,
  value,
  style,
  explode,
  escape
}) {
  const valueEncoder = str => encodeDisallowedCharacters(str, {
    escape
  });
  if (style === 'simple') {
    return value.map(val => valueEncoder(val)).join(',');
  }
  if (style === 'label') {
    return `.${value.map(val => valueEncoder(val)).join('.')}`;
  }
  if (style === 'matrix') {
    return value.map(val => valueEncoder(val)).reduce((prev, curr) => {
      if (!prev || explode) {
        return `${prev || ''};${key}=${curr}`;
      }
      return `${prev},${curr}`;
    }, '');
  }
  if (style === 'form') {
    const after = explode ? `&${key}=` : ',';
    return value.map(val => valueEncoder(val)).join(after);
  }
  if (style === 'spaceDelimited') {
    const after = explode ? `${key}=` : '';
    return value.map(val => valueEncoder(val)).join(` ${after}`);
  }
  if (style === 'pipeDelimited') {
    const after = explode ? `${key}=` : '';
    return value.map(val => valueEncoder(val)).join(`|${after}`);
  }
  return undefined;
}
function encodeObject({
  key,
  value,
  style,
  explode,
  escape
}) {
  const valueEncoder = str => encodeDisallowedCharacters(str, {
    escape
  });
  const valueKeys = Object.keys(value);
  if (style === 'simple') {
    return valueKeys.reduce((prev, curr) => {
      const val = valueEncoder(value[curr]);
      const middleChar = explode ? '=' : ',';
      const prefix = prev ? `${prev},` : '';
      return `${prefix}${curr}${middleChar}${val}`;
    }, '');
  }
  if (style === 'label') {
    return valueKeys.reduce((prev, curr) => {
      const val = valueEncoder(value[curr]);
      const middleChar = explode ? '=' : '.';
      const prefix = prev ? `${prev}.` : '.';
      return `${prefix}${curr}${middleChar}${val}`;
    }, '');
  }
  if (style === 'matrix' && explode) {
    return valueKeys.reduce((prev, curr) => {
      const val = valueEncoder(value[curr]);
      const prefix = prev ? `${prev};` : ';';
      return `${prefix}${curr}=${val}`;
    }, '');
  }
  if (style === 'matrix') {
    // no explode
    return valueKeys.reduce((prev, curr) => {
      const val = valueEncoder(value[curr]);
      const prefix = prev ? `${prev},` : `;${key}=`;
      return `${prefix}${curr},${val}`;
    }, '');
  }
  if (style === 'form') {
    return valueKeys.reduce((prev, curr) => {
      const val = valueEncoder(value[curr]);
      const prefix = prev ? `${prev}${explode ? '&' : ','}` : '';
      const separator = explode ? '=' : ',';
      return `${prefix}${curr}${separator}${val}`;
    }, '');
  }
  return undefined;
}
function encodePrimitive({
  key,
  value,
  style,
  escape
}) {
  const valueEncoder = str => encodeDisallowedCharacters(str, {
    escape
  });
  if (style === 'simple') {
    return valueEncoder(value);
  }
  if (style === 'label') {
    return `.${valueEncoder(value)}`;
  }
  if (style === 'matrix') {
    // This conditional added by Aaron to be more accurate to the spec
    if (value === '') {
      return `;${key}`;
    }
    return `;${key}=${valueEncoder(value)}`;
  }
  if (style === 'form') {
    return valueEncoder(value);
  }
  if (style === 'deepObject') {
    return valueEncoder(value, {}, true);
  }
  return undefined;
}