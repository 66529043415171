"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 170130091884;
const slug = exports.slug = 'guides/api/conversations/mobile-chat-sdk/android';
const title = exports.title = 'Integrate the HubSpot mobile chat SDK into your Android app (BETA)';
const name = exports.name = 'Integrate the HubSpot mobile chat SDK into your Android app (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to use the HubSpot mobile chat SDK in your Android app (BETA).';
const toc = exports.toc = [{
  "depth": 0,
  "id": "set-up-and-run-the-demo-app",
  "label": "Set up and run the demo app",
  "parent": null
}, {
  "depth": 0,
  "id": "install-and-configure-the-sdk",
  "label": "Install and configure the SDK",
  "parent": null
}, {
  "depth": 0,
  "id": "open-hubspot-chat-view",
  "label": "Open HubSpot chat view",
  "parent": null
}, {
  "depth": 0,
  "id": "identify-users-with-the-hubspot-visitor-identification-token",
  "label": "Identify users with the HubSpot visitor identification token",
  "parent": null
}, {
  "depth": 0,
  "id": "add-custom-chat-data-properties",
  "label": "Add custom chat data properties",
  "parent": null
}, {
  "depth": 0,
  "id": "clear-data-on-logout",
  "label": "Clear data on logout",
  "parent": null
}, {
  "depth": 0,
  "id": "reference-documentation",
  "label": "Reference documentation",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Integrate the HubSpot mobile chat SDK into your Android app (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "This functionality is currently in development"
          }), ". It is subject to change based on testing and feedback. By using these instructions you agree to adhere to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/hs-developer-terms",
            children: "HubSpot's Developer Terms"
          }), " & ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/developerbetaterms",
            children: "HubSpot's Developer Beta Terms"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["To ask questions or provide feedback on the beta, you can join the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/slack/developer-slack",
            children: "HubSpot Developer Slack Community"
          }), " and join the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://hubspotdev.slack.com/channels/mobile-chat-sdk-beta",
            children: "#mobile-chat-sdk-beta"
          }), " channel."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Learn how to opt your account into this beta ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/account-management/opt-your-hubspot-account-into-a-public-beta-feature",
            children: "here"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use the HubSpot mobile chat SDK to integrate your Android mobile application with HubSpot's live chat functionality."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The mobile chat SDK allows you to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Integrate HubSpot chat into your mobile app to deliver real-time, in-app customer support."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Leverage the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/chatflows/create-a-bot",
          children: "bot"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/knowledge-base/create-and-customize-knowledge-base-articles",
          children: "knowledge base"
        }), " tools to help deflect customer inquires when your support agents are unavailable."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Alert users of new messages via push notifications."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Customize the chat experience to align with your business's brand and UI."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up and run the demo app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To set up the mobile chat SDK dependencies to your project:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "google-services.json"
        }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "app/"
        }), " folder, and ensure that the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "package_name"
        }), " field in the file is set to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "com.example.demo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot-info.json"
        }), " file in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "app/"
        }), " folder. The full path of the file should be ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "app/src/main/assets/hubspot-info.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Add the HubSpot package to your build gradle file:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "implementation \"com.hubspot.mobilechatsdk:mobile-chat-sdk-android::LATEST_VERSION\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.gradle.org/current/dsl/org.gradle.api.tasks.Sync.html",
          children: "Sync"
        }), " your Android project with its gradle files."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Build and run the app."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Install and configure the SDK"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The SDK needs to be configured once per app launch. The most convenient time to configure the SDK is during app initialization."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Start by getting an instance of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotManager"
      }), " class, then call the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "configure"
      }), " method"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "val manager = HubspotManager.getInstance(context)\nmanager.configure()\n    try! HubspotManager.configure()\n\n    return true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Failure to include the config file, or forgetting to include the file as being part of your apps target will cause initialization to throw an errors. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubspotConfigError"
      }), " class represents different errors when missing properties."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Open HubSpot chat view"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The chat view can be opened by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubspotWebActivity"
      }), " which extends the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Activity"
      }), " class. You can open it by using any of the following approaches:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Directly calling ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "startActivity"
        }), " via intent"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create any button type, then within an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "onClick"
        }), " listener of that button, open the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "HubspotWebActivity"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "HubspotFloatingActionButton"
        }), " in the file which handle the clicks and open the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "HubspotWebActivity"
        }), " automatically."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you wanted to go with the second approach above and specify the behavior within an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onClick"
      }), " listener of a button:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "binding.anybutton.setOnClickListener {\n    startActivity(Intent(requireContext(), HubspotWebActivity::class.java))\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identify users with the HubSpot visitor identification token"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can identify users by using the visitor identification API, detailed in this article. This API is best implemented in your own server project, where you can pass the identity token to your app based on whether suits your specific setup (i.e., passing a token in response to a visitor going through a login page as a result of a dedicated API that you own)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once a token is generated, it can be associated with a contact's email address by calling ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubspotManager.setUserIdentity(email, identityToken)"
      }), ". This should be called before opening a chat view."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add custom chat data properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The SDK supports setting key-value pairs to keep track of data you might need to track while a chat session is open. You can set your own custom values, or declare common permission preferences such as photo or notification permissions."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubspotManager.setChatProperties(key, value)"
      }), " method and provide the associated key and value you want to set. This is best called before starting a chat, and will apply to all new chats. You could set an account status, or other identifiers when setting up your user. These will then appear in all chats opened for the remainder of the app launch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the following code would set permissions for camera, photo, notifications, and location data to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"false\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "val keyValuePair = mapOf(\n    ChatPropertyKey.CameraPermissions.chatPropertyValue to \"false\",\n    ChatPropertyKey.PhotoPermissions.chatPropertyValue to \"false\",\n    ChatPropertyKey.NotificationPermissions.chatPropertyValue to \"false\",\n    ChatPropertyKey.LocationPermissions.chatPropertyValue to \"false\"\n)\n\nhubspotManager.setChatProperties(keyValuePair)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Clear data on logout"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The SDK stores in memory identification tokens, email address, and any properties set. The push token for the app is also associated with the current user, if applicable. You may want to clear this data when a user is logging out, or changing users in a multi user app. To clear this data, call ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubspotManager.logout()"
      }), " at an appropriate time in in your app."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " calling the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "HubspotManager.logout()"
        }), " only impacts the data used for future chat sessions. It has no impact on data or chat sessions already stored in HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reference documentation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can consult the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/mobile-chat-sdk-android/",
        children: "reference documentation"
      }), " for details on how to use each of the components in the HubSpot mobile SDK. The binary file is also hosted ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://search.maven.org/artifact/com.hubspot.mobilechatsdk/mobile-chat-sdk-android/1.0.0/jar",
        children: "here"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}