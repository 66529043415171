"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 38327084601;
const slug = exports.slug = 'guides/api/cms/source-code';
const title = exports.title = 'CMS | Source Code API';
const name = exports.name = 'vNext Docs DP - Source Code API';
const metaDescription = exports.metaDescription = 'Create, fetch, update, and delete, files in the HubSpot developer file system. Additionally you can validate files for different types of errors & warnings';
const toc = exports.toc = [{
  "depth": 0,
  "id": "basic-features",
  "label": "Basic Features",
  "parent": null
}, {
  "depth": 0,
  "id": "environment-and-path",
  "label": "Environment and path",
  "parent": null
}, {
  "depth": 0,
  "id": "downloading-a-file",
  "label": "Downloading a file",
  "parent": null
}, {
  "depth": 0,
  "id": "fetching-file-and-folder-metadata",
  "label": "Fetching file and folder metadata",
  "parent": null
}, {
  "depth": 0,
  "id": "uploading-a-file",
  "label": "Uploading a file",
  "parent": null
}, {
  "depth": 0,
  "id": "validating-file-contents",
  "label": "Validating file contents",
  "parent": null
}, {
  "depth": 0,
  "id": "deleting-a-file",
  "label": "Deleting a file",
  "parent": null
}, {
  "depth": 0,
  "id": "extracting-a-file-package",
  "label": "Extracting a file package",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      strong: "strong",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS Source Code"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Basic Features"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The CMS Source Code API allows you to interact with the files stored in your HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/overview#developer-file-system",
        children: "Developer File System"
      }), ". These files include all of the templates, modules, CSS, JS, and other CMS assets seen in the Design Manager. Through this API, you can:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Upload new files to their HubSpot account, or upload changes to existing files."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Download or delete CMS assets from their account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fetch metadata for each file or folder in the Design Manager."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Validate file contents for use in HubSpot CMS, including HubL syntax."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Upload zip files as file packages and extract them inside the account."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With this API you can edit all your CMS assets locally as well as build tooling to help you move even faster."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Environment and path"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Source Code API endpoints use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "environment"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "path"
      }), " parameters to identify files in the CMS Developer File System. These parameters are generally specified in the endpoint path itself, as in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/content/{path}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "environment"
      }), " parameter refers to whether you are interacting with the unpublished or live version of each file. For unpublished changes, use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "draft"
      }), ". For live changes, use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "published"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Note that uploading to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "published"
        }), " is equivalent to pressing the “Publish” button in the Design Manager. As such, whatever is currently in ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "draft"
        }), " will be cleared."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "path"
      }), " parameter refers to the location of the file in the CMS Developer File System. Top level assets are not preceded by a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/"
      }), " character as you would see in a UNIX based operating system. When uploading a new file, this should be the desired location where the file should be created. When retrieving or uploading changes to an existing file, this should match the path of the existing file."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["We use the local file formats for all asset types, which means that certain types of assets are broken up into multiple files. For instance, a module is actually represented as a directory ending in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".module"
      }), " suffix, so to retrieve the HTML for a module, one would need to use the path ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "foo.module/module.html"
      }), ". See the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "local development documentation"
      }), " for further information."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Downloading a file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To download a file from your HubSpot account, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/content/{path}"
      }), " and set the header to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Accept: application/octet-stream"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "File data will be downloaded in binary format. You cannot download the entire contents of a folder. Instead, you must fetch the folder metadata and retrieve each of its children individually."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fetching file and folder metadata"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To fetch file and folder metadata, such as path, filename, and created/updated timestamps, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/metadata/{path}"
      }), " and set the header to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Accept: application/json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "File and folder metadata will be returned in a JSON object:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Folder metadata will be indicated by the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "folder: true"
        }), " property."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "children"
        }), " array will show the names of files and subfolders within the folder. These filenames can be used to traverse the folder tree: simply fetch one folder metadata and recursively fetch the children of the folder and all subfolders."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uploading a file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To upload a local file to your HubSpot account, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/content/{path}"
      }), ". You must upload the file using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), " content type, and the binary file data must be included as a field named ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "file"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Uploading a new file:"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "PUT ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/source-code/published/content/my-new-file.html"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type: multipart/form-data"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Form Data: { file: [_binary file data_] }"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Updating an existing file draft:"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "PUT ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/source-code/draft/content/path/to/existing-file.html"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type: multipart/form-data"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Form Data: { file: [_binary file data_] }"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot currently supports the following file types:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "css"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "js"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "html"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "txt"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "md"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "jpg"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "jpeg"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "png"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "gif"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "map"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "svg"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ttf"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "woff"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "woff2"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "zip"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validating file contents"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To validate the contents of a local file, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/validate/{path}"
      }), ". You must upload the file using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), " content type, and the binary file data must be included as a field named ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "file"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This can be used to validate HubL in a template/module or JSON for a theme or module. If there are validation errors, you will receive a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "400"
      }), " response with the list of relevant errors. These are the same warnings and errors you would see within the design manager."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Note that invalid files will be rejected if you try and publish them directly. It is recommended to validate files first before publishing."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "For example:"
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "POST ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/published/validate/my-file.html"
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Content-Type: multipart/form-data"
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Form Data: { file: [_binary file data_] }"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Deleting a file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete a file, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/content/{path}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Deleting from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "published"
      }), " environment will remove the file entirely, while deleting from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "draft"
      }), " environment will simply clear out any unpublished changes. Note that deleting published files will immediately impact live content if used anywhere, so make sure to remove all existing references to the file before deleting."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Extracting a file package"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To extract a zip file, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/extract/{path}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "path"
      }), " must be a zip file already uploaded to the account. The extraction process is asynchronous and can take up to a minute depending on how many and how large the compressed files are. The contents of the zip are extracted in place to the same folder that contains the zip file, and the original zip file is not deleted automatically upon successful extraction."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}