"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529768;
const slug = exports.slug = 'reference/cms/modules/configuration';
const title = exports.title = 'Configurar um módulo';
const name = exports.name = 'LATAM BR (PT) Configuring a Module';
const metaDescription = exports.metaDescription = 'Saiba como configurar um módulo personalizado.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "adicionar-um-%C3%ADcone",
  "label": "Adicionar um ícone",
  "parent": null
}, {
  "depth": 0,
  "id": "alterar-o-r%C3%B3tulo",
  "label": "Alterar o rótulo",
  "parent": null
}, {
  "depth": 0,
  "id": "tornar-um-m%C3%B3dulo-global",
  "label": "Tornar um módulo global",
  "parent": null
}, {
  "depth": 0,
  "id": "controlar-onde-um-m%C3%B3dulo-est%C3%A1-dispon%C3%ADvel-para-uso",
  "label": "Controlar onde um módulo está disponível para uso",
  "parent": null
}, {
  "depth": 0,
  "id": "adicionar-depend%C3%AAncias-de-css-e-javascript",
  "label": "Adicionar dependências de CSS e JavaScript",
  "parent": null
}, {
  "depth": 0,
  "id": "adicionar-categorias-e-tags",
  "label": "Adicionar categorias e tags",
  "parent": null
}, {
  "depth": 1,
  "id": "categorias",
  "label": "Categorias",
  "parent": "adicionar-categorias-e-tags"
}, {
  "depth": 1,
  "id": "tags",
  "label": "Tags",
  "parent": "adicionar-categorias-e-tags"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      em: "em",
      ul: "ul",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configurar um módulo"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Ao criar um módulo, há várias opções disponíveis que afetam onde um módulo está visível, como ele é identificado e como é editado."
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Ao ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "desenvolver localmente"
            }), ", os módulos são armazenados como pastas com o sufixo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".module"
            }), " Essas pastas contêm os arquivos que compõem as configurações do módulo e o código usado para processá-lo. A configuração de um módulo é mantida no arquivo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#meta-json",
              children: "meta.json"
            }), ", enquanto os campos são configurados separadamente em um arquivo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview#fields-json",
              children: "fields.json"
            }), "."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["A maioria da configuração também pode ser modificada usando o editor de módulo no ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/design-manager",
              children: "Gerenciador de design"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/module%20structure.png",
            alt: "Estrutura de pastas do módulo exibida localmente"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em um nível alto, você configura as opções do módulo localmente no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), ", que pode incluir as seguintes propriedades:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL para uma imagem a ser usada como o ícone de um módulo."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rótulo usado quando os módulos são exibidos nos editores de conteúdo"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID exclusivo para o módulo que é independente do caminho."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O valor para a alternância no canto superior direito do editor de módulos no HubSpot. Determina se o módulo pode ser usado no conteúdo."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "global"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indica se o módulo é global ou não"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host_template_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview",
              children: "tipos de conteúdo"
            }), " nos quais o módulo pode ser usado. Um ou mais de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "[\"PAGE\", \"BLOG_POST\", \"BLOG_LISTING\", \"EMAIL\"]"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " de arquivos CSS da qual o módulo depende. Dá suporte a caminhos relativos.por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"css_assets\": [{ \"path\": \"../path/to/file.css\" }]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Defina se o módulo CSS renderiza de forma assíncrona com ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "async"
            }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"async\": false}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " de arquivos JavaScript da qual o módulo depende. Dá suporte a caminhos relativos.por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"js_assets\": [{ \"path\": \"../path/to/file.js\" }]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Modifica a tag JavaScript do módulo adicionada à página renderizada. As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "position"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "head"
                }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "footer"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "async"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "defer"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "type"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "string"
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"position\":\"footer\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inline_help_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Texto de ajuda que será exibido na parte superior do módulo em uma caixa de informações azul (limite de 300 caracteres).Fornece as informações necessárias para usar o módulo. Se você tiver informações de texto de ajuda específicas que deve transmitir, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#properties-used-by-all-fields",
              children: "documentação do campo de texto de ajuda"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "master_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Com as", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-translations-of-your-modules#:~:text=To%20create%20translations%20of%20a,the%20right%2C%20click%20Add%20translations.",
              children: "traduções ativadas"
            }), ", o código do idioma no qual os campos do módulo foram originalmente escritos.por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt-br"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Define o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
              children: "conteúdo do espaço reservado"
            }), " para o módulo. Inclui as seguintes propriedades:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_module_icon"
                }), ": se o ícone do módulo for exibido. ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), ": o título que aparece no módulo no editor. String."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), ": a descrição que aparece no módulo no editor. String."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "categories"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma matriz contendo até três ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-categories",
              children: "categorias de módulos"
            }), ".Por exemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"categories\":[\"FORMS_AND_BUTTONS\"]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma matriz de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#adding-categories-and-tags",
              children: "objetos de tag de módulo"
            }), " que contém o nome da tag e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "source"
            }), " igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"USER\""
            }), ".Por exemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"content_tags\": [{"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\" : \"BUTTONS\","
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"source\" : \"USER\"``}]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba mais sobre as opções de configuração de módulos individuais."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adicionar um ícone"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os módulos podem incluir um ícone que aparece no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Gerenciador de design"
      }), " e nos editores de página e e-mail para fornecer contexto visual aos criadores de conteúdo. É recomendado ter ícones diferentes para os diferentes tipos de módulos em seu tema. Os ícones são ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "obrigatórios para provedores do marketplace"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Há duas maneiras de adicionar um ícone, através do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-design-manager",
        children: "gerenciador de design"
      }), " ou do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-cli",
        children: "CMS CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ícones do módulo devem ser um arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".svg"
      }), " e ter tamanho inferior a 10 Kb. Para obter melhores resultados, o ícone deve ser simples e usar apenas uma cor. Os ícones que usam mais de uma cor serão convertidos automaticamente para você. O ícone do módulo padrão exibido é um ícone de chave inglesa e pincel."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para adicionar um ícone usando o gerenciador de design:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.ol, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Files and Templates"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Design Tools"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Use the left sidebar to locate the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module"
              }), " you want to edit, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module name"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In the right sidebar of the module editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "icon"
              }), " next to the module title."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Upload/select your icon. After publishing your changes, the icon will appear within the page editors and design manager."
            }), "\n"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-icon-crop.png",
            alt: "edit-module-icon-crop"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para adicionar um ícone ao desenvolver localmente, abra o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " do módulo e adicione ou edite o valor do parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "icon"
      }), " para ser um SVG do gerenciador de arquivos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"global\": false,\n  \"host_template_types\": [\"PAGE\"],\n  \"icon\": \"http://example.com/hubfs/code.svg\",\n  \"label\": \"Code block\",\n  \"smart_type\": \"NOT_SMART\",\n  \"is_available_for_new_content\": true,\n  \"inline_help_text\": \"This module is for code snippets.\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alterar o rótulo"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "O rótulo usado quando os módulos são exibidos no editor pode ser ajustado separadamente do nome do módulo. Isso é útil ao desenvolver localmente, pois você pode ter um nome que faz sentido enquanto tem um criador de conteúdo diferente."
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Localmente, você altera o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), " para definir o rótulo. No gerenciador de design há um campo no editor de módulo abaixo do nome do módulo."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-label.png",
            alt: "edit-module-label"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tornar um módulo global"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para módulos normais, o conteúdo de cada instância de um módulo em uma página, e-mail ou modelo é independente. Para alguns casos de uso, é útil poder fazer com que todas as instâncias de um módulo compartilhem o mesmo conteúdo. Ao desenvolver localmente, você pode tornar um módulo global definindo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/use-global-content-across-multiple-templates#create-new-global-content-in-the-design-manager:~:text=To%20convert%20an%20existing%20local%20module%20or%20group",
        children: "converter módulos como globais em um modelo de arrastar e soltar"
      }), " usando o gerenciador de design."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Controlar onde um módulo está disponível para uso"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao desenvolver localmente, você pode controlar em quais tipos de conteúdo um módulo pode ser usado por meio da propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hostTemplateTypes"
      }), ". Saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "tipos de modelos disponíveis"
      }), ". Os módulos também podem ficar ocultos para que não possam ser adicionados diretamente às páginas por meio da configuração ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_available_for_new_content"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), ". Por exemplo, isso pode ser útil para módulos criados para menus de navegação e pesquisa."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode atualizar isso no gerenciador de design clicando na opção ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Tipo de modelo"
      }), " na barra lateral direita."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-template-type.png",
        alt: "edit-module-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adicionar dependências de CSS e JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além de usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " para adicionar CSS e JavaScript que serão adicionados a todas as páginas que incluem uma instância de módulo, as dependências que são compartilhadas entre os módulos podem ser anexadas usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "js_assets"
      }), ". Os caminhos podem ser absolutos ou relativos ao arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"css_assets\": [{ \"path\": \"../path/to/file.css\" }],\n  \"js_assets\": [{ \"path\": \"../path/to/file.js\" }]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aviso:"
        }), " ao usar caminhos relativos a dependências de referência, executar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch --overwrite"
        }), " para atualizar o módulo para desenvolvimento local substituirá os caminhos relativos com caminhos absolutos."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando o gerenciador de design, você pode vincular arquivos CSS e JavaScript a um módulo usando a seção ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Arquivos vinculados"
      }), " na barra lateral direita do editor de módulos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-linked-files.png",
        alt: "edit-module-linked-files"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adicionar categorias e tags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode atribuir categorias e tags aos módulos para organizá-los nas ferramentas da HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#categories",
          children: "Categorias"
        }), ": atribua categorias a um módulo para organizá-los em grupos dentro da interface do usuário do editor de conteúdo. Isso permite que os criadores de conteúdo encontrem módulos com mais facilidade ao criar conteúdo no HubSpot. Observe o seguinte sobre as categorias:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Um módulo pode ter até três categorias, que são pré-definidas e não podem ser personalizadas."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Atualmente, as categorias não são exibidas na interface do usuário do editor de conteúdo. No entanto, você pode atribuir categorias para quando a categorização for disponibilizada nos editores."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Módulos não categorizados estarão disponíveis em ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "todas"
            }), " as categorias."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#tags",
          children: "Tags"
        }), ": atribua tags para organizar os módulos dentro do gerenciador de design. Isso permite que você encontre módulos com mais facilidade ao criar modelos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No gerenciador de design, você pode atribuir categorias e tags usando a seção ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Filtrar tags"
      }), " da barra lateral direita no editor de módulos. Saiba mais sobre as categorias e tags disponíveis abaixo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-filter-tags.png",
        alt: "edit-module-filter-tags"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Localmente, você pode adicionar categorias e tags ao arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " de um módulo da seguinte forma:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"label\": \"Custom module\",\n  \"categories\": [\"image_and_video\", \"commerce\"],\n  \"content_tags\": [\n    {\n      \"name\": \"BUTTONS\",\n      \"source\": \"USER\"\n    },\n    {\n      \"name\": \"CTA\",\n      \"source\": \"USER\"\n    }\n  ],\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Categorias"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "categories"
      }), " de um módulo pode conter até três das seguintes categorias (não diferencia maiúsculas de minúsculas):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Categoria"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos específicos do blog, como uma listagem de posts recentes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos formatados para exibir conteúdo gráfico, como uma galeria de imagens."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos específicos de comércio, como cartões de preços."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "design"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que afetam a estrutura e o layout do conteúdo, como accordions."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "functionality"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que incluem respostas dinâmicas ou comportamento na página, como menus."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms_and_buttons"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que permitem que os visitantes do site insiram e enviem dados."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que contêm elementos como imagens, ícones, vídeos e banners."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos específicos para redes sociais, como compartilhamento social."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que contêm apenas texto."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tags"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content_tags"
      }), " de um módulo pode conter um dos seguintes objetos de tag de módulo (diferencia maiúsculas de minúsculas):"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Tipos de conteúdo:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ACCORDION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ANIMATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG_POST"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BUTTONS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CODE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CTA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FEED"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FORM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ICON"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "IMAGE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LISTS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LOGO"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MENU"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "RICH_TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SLIDER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "VIDEO"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Funções:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BANNER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BRANDING"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CALCULATOR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CONVERSION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "EMAIL"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "GALLERY"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "HEADERS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "INTERACTION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LAYOUT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MAP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MULTIMEDIA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "NAVIGATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROGRESS_BAR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SEARCH"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SETTINGS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SOCIAL"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TRANSLATION"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}