"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694131;
const slug = exports.slug = 'guides/cms/content/themes/hubspot-cms-boilerplate';
const title = exports.title = 'The HubSpot CMS Boilerplate';
const name = exports.name = '[new] The HubSpot CMS Boilerplate';
const metaDescription = exports.metaDescription = 'The HubSpot CMS Boilerplate illustrates best practices and helps developers create a website quickly on the HubSpot CMS. ';
const position = exports.position = 3;
const toc = exports.toc = [{
  "depth": 0,
  "id": "why-should-developers-use-the-hubspot-cms-boilerplate%3F",
  "label": "Why should developers use the HubSpot CMS Boilerplate?",
  "parent": null
}, {
  "depth": 0,
  "id": "how-to-get-started-using-the-hubspot-cms-boilerplate",
  "label": "How to get started using the HubSpot CMS Boilerplate",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-cms-boilerplate-structure",
  "label": "HubSpot CMS Boilerplate structure",
  "parent": null
}, {
  "depth": 0,
  "id": "jquery",
  "label": "jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      strong: "strong",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "The HubSpot CMS Boilerplate"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 9,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "The HubSpot CMS Boilerplate"
            }), " serves as a starting point for helping developers get a website up and running quickly on the HubSpot CMS while illustrating best practices developers can use when building on the HubSpot CMS Platform. The boilerplate is an open-source GitHub project where all are welcome to suggest changes and fork for their own use. If you are new to the HubSpot CMS, and want to get started on a new project based off of the boilerplate, follow the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/quickstart",
              children: "quick start guide to developing on the HubSpot CMS"
            }), "."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 3,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/subscription",
              children: "Watch"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/fork",
              children: "Fork"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "Star"
            })]
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Why should developers use the HubSpot CMS Boilerplate?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot CMS Boilerplate is built and actively maintained by HubSpot. When building the boilerplate, we incorporated best practices that were influenced by how developers created the best website building experience and then applied those to building a website on the HubSpot CMS. This provides developers with a clean, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "performant"
      }), ", and ready to modify website that saves developers a significant amount of developmental time. The boilerplate also provides comprehensive CSS for HubSpot related assets such as forms, menu modules, base classes and more. You can view a live demo of the boilerplate in action by visiting ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/",
        children: "https://boilerplate.hubspotcms.com/"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How to get started using the HubSpot CMS Boilerplate"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get started with using the boilerplate in your local development environment, simply follow our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "Getting Started Developing Websites on the HubSpot CMS guide."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot CMS Boilerplate structure"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Developers can work with the boilerplate using ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "local development tools"
            }), " or directly inside of the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cos-general/get-started-with-the-design-manager",
              children: "Design Manager"
            }), ". The boilerplate uses relative path references for all of the assets which makes it easy to adapt to whatever your project may be. This also allows for the boilerplate to be completely portable between accounts on the HubSpot Platform. Below is a screenshot of the folder structure of the boilerplate’s assets. The boilerplate silos its assets into multiple directories for easy identification of where they reside."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844633478"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/boilerplate/boilerplate-structure-tree-view-4.png",
        alt: "Folder Structure of the HubSpot CMS Boilerplate"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot CMS Boilerplate’s underlying template structure revolves around a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "common base layout"
      }), ", located in the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "templates > layouts"
      }), " folder, that is then ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% extends %}"
      }), " tag and referencing the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% block body %}"
      }), " block for its main content. A sample of how the extend tag and blocks are being used can be seen in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "any of the html files inside of the templates directory."
      }), " Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#blocks-and-extends",
        children: "blocks and extends"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This is a common method of developing on CMS systems where you have a base (sometimes called a main/parent) template that contains all the main common structural pieces of content on your site. These are often items that are inside of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " element on your site such as common meta properties (ex: Title and Meta Description), Favicon links, CSS links, and 3rd party scripts"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n <head>\n   <meta charset=\"utf-8\">\n   <title>{{ page_meta.html_title }}</title>\n   {% if site_settings.favicon_src %}<link rel=\"shortcut icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n   <meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n   {{ require_css(get_asset_url(\"../../css/layout.css\")) }}\n   {{ require_css(get_asset_url(\"../../css/main.css\")) }}\n   {{ require_css(\"https://fonts.googleapis.com/css?family=Merriweather:400,700|Lato:400,700&display=swap\") }}\n   {{ require_js(get_asset_url(\"../../js/main.js\")) }}\n   {{ standard_header_includes }}\n </head>\n <body>\n   <div class=\"body-wrapper {{ builtin_body_classes }}\">\n     {% block header %}\n       {% global_partial path=\"../partials/header.html\" %}\n     {% endblock header %}\n\n     {% block body %}\n       <!-- Nothing to see here -->\n     {% endblock body %}\n\n     {% global_partial path=\"../partials/footer.html\" %}\n   </div>\n   {{ standard_footer_includes }}\n </body>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inside of this base layout, there are also calls to our global header and footer partials. This allows us to be able to keep the code for these partials in their own separate files for modularity and, because they are global partials, can then be easily edited using our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Global Content Editor"
      }), " by your content creators."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For more depth into the assets included in the boilerplate ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/wiki",
        children: "check out the boilerplate's wiki on GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "The HubSpot Theme boilerplate doesn't require jQuery in order to function"
      }), ". For older HubSpot accounts jQuery is loaded by default. Newer HubSpot accounts have jQuery disabled by default."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Historically HubSpot scripts required jQuery to function properly, so the domain-wide setting was there to help ensure compatibility. HubSpot scripts no longer use jQuery. Because JQuery is not required, and there are better ways for developers to include libraries that also work with source control. It is advised to disable the jQuery settings for new websites."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Be aware if disabling jQuery on a domain that has an existing website - any landing pages or existing web pages you may have could break if they depend on jQuery."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "If you wish to use jQuery on your new website it is recommended that you use the latest version of jQuery."
      }), " There are two easy ways to do that:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Upload the latest version of jQuery to your developer file system and use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " to load it where and when you need it."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a CDN you trust, and use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " to load jQuery where and when you need it."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Getting started with themes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "How to optimize your CMS Hub site for performance"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Getting started with accessibility"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}