"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32448404615;
const slug = exports.slug = 'guides/api/cms/blogs/blog-authors';
const title = exports.title = 'CMS API | Blog Authors';
const name = exports.name = 'vNext Docs DP - Blog Authors';
const metaDescription = exports.metaDescription = 'The blog authors endpoints are used to create and manage hubspot blog authors';
const toc = exports.toc = [{
  "depth": 0,
  "id": "changes-in-v3",
  "label": "Changes in V3",
  "parent": null
}, {
  "depth": 0,
  "id": "search-blog-authors",
  "label": "Search blog authors",
  "parent": null
}, {
  "depth": 1,
  "id": "filtering",
  "label": "Filtering",
  "parent": "search-blog-authors"
}, {
  "depth": 1,
  "id": "sorting-and-paginating",
  "label": "Sorting and paginating",
  "parent": "search-blog-authors"
}, {
  "depth": 0,
  "id": "create-blog-authors",
  "label": "Create blog authors",
  "parent": null
}, {
  "depth": 0,
  "id": "edit-blog-authors",
  "label": "Edit blog authors",
  "parent": null
}, {
  "depth": 0,
  "id": "multi-language-management",
  "label": "Multi-language management",
  "parent": null
}, {
  "depth": 1,
  "id": "create-a-new-language-variant",
  "label": "Create a new language variant",
  "parent": "multi-language-management"
}, {
  "depth": 1,
  "id": "attach-a-blog-author-to-an-existing-multi-language-group",
  "label": "Attach a blog author to an existing multi-language group",
  "parent": "multi-language-management"
}, {
  "depth": 1,
  "id": "detach-a-blog-author-from-a-multi-language-group",
  "label": "Detach a blog author from a multi-language group",
  "parent": "multi-language-management"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em",
      h3: "h3",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 9,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Blog Authors"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 3
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the blog authors API to manage author information for your blog posts. Learn more about how to create and maintain your blog on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website/topics#blog",
        children: "HubSpot Knowledge Base."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Changes in V3"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following properties are deprecated and will not be included in the response of any of the V3 endpoints:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "user_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "username"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "googlePlus"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "gravatarUrl"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "twitterUsername"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hasSocialProfiles"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Search blog authors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve an account's blog authors, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/blogs/authors"
      }), ". You can filter and sort the authors returned in the response using the operators and properties described below. You can also use the standard filters using the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "createdAt"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "updatedAt"
      }), " dates."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Filtering"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Provide any filters as query parameters in your request, by adding the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "property name"
      }), ", followed by ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "two underscore characters"
      }), ", then include the associated ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "operator"
      }), " as a suffix. For example, you can filter the results to only include authors where the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "displayName"
      }), " property contains the word ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "J__ohn"
      }), " using the parameter: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&displayName__icontains=john"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can include any number of filters as query parameters in the request URL. All filters are ANDed together. ORing filters is not currently supported."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The available filter types are listed below:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operator"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Equal to"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ne"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not equal to"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contains"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Less than"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Less than or equal to"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Greater than"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Greater than or equal to"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Null"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not null"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Is like"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not like"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icontains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contains (case sensitive)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startswith"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Starts with"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "In"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The table below lists the properties that can be filtered on, along with their supported filter types."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operator"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_in"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fullName"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ne"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "updatedAt"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deletedAt"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdById"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "updatedById"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "translatedFromId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To filter blog authors based on a multi-language group, you can include one of the query parameters in the table below. For example, to get blog authors associated with the German variation of your blog, you'd include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "language_in=de"
      }), " as a query parameter."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "translatedFromId__is_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Primary blog author in a multi-language group."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "translatedFromId__not_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Variation blog author in a multi-language group."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "language__in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog author with a specific language."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sorting and paginating"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can provide sorting and pagination options as query parameters. Specify the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "property name"
      }), " as the value to the sort query parameter to return the blog authors in the natural order of that property. You can reverse the sorting order by including a dash character before the property name (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort=-createdAt"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By combining query parameters for filtering, sorting, and paging, you can retrieve blog authors that match more advanced search criteria. For example, the request below fetches blog authors that don't have a language assigned, ordered by the most recently updated. The limit and offset parameters below return the second page of results."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl\nhttps://api.hubapi.com/cms/v3/blogs/authors?sort=-updatedAt&&language__not_null&limit=10&offset=10 \\\n  --request POST \\\n  --header \"Content-Type: application/json\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create blog authors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a blog author, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/blogs/authors"
      }), ", and include a JSON payload that represents the blog author model. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fullName"
      }), " field is required when creating a blog author. To set the URL of a blog author profile page, you must provide the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "slug"
      }), " field in your request. Review the required parameters and the structure of the blog author model in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/cms/blogs/blog-authors",
        children: "endpoints reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/blog/create-and-manage-your-blog-authors#create-a-new-blog-author",
        children: "create blog authors directly in your HubSpot account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Edit blog authors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update a blog author, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/blogs/authors/{objectId}"
      }), ", where ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " is the ID of the associated author. Include a JSON payload representing the blog author model in your request. Review the required parameters and the structure of the blog author model in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/cms/blogs/blog-authors",
        children: "endpoints reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/blog/create-and-manage-your-blog-authors#edit-a-blog-author-s-profile",
        children: "edit a blog author's profile directly in your HubSpot account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Multi-language management"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To help you maintain blog authors across multiple languages, HubSpot's CMS allows you to group together blog authors of language variants of the same content. A blog author with a language set may only be used on blog posts of the same language. Blog authors that do not have a language set are considered global and may be used on all blog posts."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To learn more about working with multi-language blog authors, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/blog/create-a-multi-language-blog#create-blog-authors-in-multiple-languages",
        children: "this Knowledge Base article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a new language variant"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a new language variant for an existing blog author, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/multi-language/create-language-variant"
      }), " and include a JSON payload containing the ID of the blog author to clone and the language identifier of the new variant."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Attach a blog author to an existing multi-language group"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add a blog author to an existing multi-language group, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/multi-language/attach-to-lang-group"
      }), " and include a JSON payload containing the ID of the target blog author, the language identifier of the blog author being added, and the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primaryId"
      }), " of the blog author designated as the primary author in the target multi-language group."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Detach a blog author from a multi-language group"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove a blog author from a multi-language group, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/multi-language/detach-from-lang-group"
      }), " endpoint, and include a JSON payload that contains the ID of the target blog author."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}