"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313452;
const slug = exports.slug = 'guides/cms/marketplace/listing-templates';
const title = exports.title = 'Lister et mettre à jour des modèles sur le marketplace des modèles HubSpot';
const name = exports.name = 'EMEA (FR) Marketplace Requirements Overview > Listing CMS Assets';
const metaDescription = exports.metaDescription = "Une fois que vous avez créé un compte de fournisseur dans le marketplace des modèles, vous pouvez l'utiliser pour créer des listings de modèle et les soumettre pour approbation. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-un-listing-de-mod%C3%A8le",
  "label": "Créer un listing de modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-un-listing-de-mod%C3%A8le",
  "label": "Mettre à jour un listing de modèle",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      img: "img",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Lister et mettre à jour des modèles sur le marketplace des modèles HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous avez créé un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "compte de fournisseur dans le marketplace des modèles"
      }), ", vous pouvez l'utiliser pour créer des listings de modèle et les soumettre pour approbation afin qu'ils soient répertoriés sur le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "marketplace des modèles HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " vous ne pouvez consulter qu'", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "une seule"
        }), " nouvelle soumission de chaque type (module ou modèle) à la fois. Une soumission est considérée comme « nouvelle » si elle n'est actuellement pas publiée ou approuvée."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avant de publier votre modèle sur le marketplace des modèles, remplissez votre profil de fournisseur :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de fournisseur du marketplace des modèles, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace de modèles"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Profil de fournisseur"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informations sur l'entreprise"
        }), ", remplissez tous les champs. Ces champs apparaîtront dans vos listings de modèle. Vous ne pourrez pas modifier le nom de votre entreprise une fois qu'il aura été choisi et vous devrez contacter le support HubSpot pour le modifier."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informations de contact"
        }), ", remplissez tous les champs des sections ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contact principal"
        }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contact développeur"
        }), ". Ces informations ne seront utilisées que si HubSpot doit vous contacter au sujet d'un listing et n'apparaîtront sur ", (0, _jsxRuntime.jsx)("u", {
          children: "aucune"
        }), " page de listing."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informations de paiement"
        }), ", ajoutez les modes de paiement pris en charge. Vous pouvez connecter PayPal ou Stripe en tant que passerelle de paiement. Si vos informations de paiement entraînent l'arrêt du fonctionnement de la passerelle de paiement, vos modèles seront temporairement retirés de la liste pour éviter un impact négatif sur les clients."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un listing de modèle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer et soumettre un listing de thème ou de module :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de fournisseur du marketplace des modèles, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace de modèles"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer une liste"
        }), " dans l'angle supérieur droit."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer un listing de thème"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer un listing de module"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Passez en revue les informations sur la page ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informations sur le fournisseur"
        }), ", puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suivant"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vérifiez vos informations de paiement sur la page ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informations de paiement"
        }), ", puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suivant"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "thème ou le module"
        }), " que vous souhaitez lister, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enregistrer et continuer"
        }), ". Vous accéderez alors à l'éditeur de listing"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/theme-listing-marketplace.png",
        alt: "theme-listing-marketplace"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'éditeur de listing est divisé en cinq onglets : ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Informations sur le listing"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Détails du thème/module"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Catégorie"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Support"
      }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Avis"
      }), ". Toutes les informations sont requises, sauf indication contraire. Consultez toutes les conditions relatives aux pages de listing ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/marketplace-guidelines/general-requirements",
        children: "ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Vérifier"
        }), ", validez le code de votre modèle et prévisualisez votre listing. Si tout vous convient, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Soumettre pour analyse."
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " ne modifiez pas le nom du dossier modèle une fois soumis. Le listing des modèles est directement lié à ce dossier lors de la soumission. Vous ne pourrez pas renvoyer ou mettre à jour le modèle après avoir modifié le nom du dossier."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour un listing de modèle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Après avoir créé un listing de modèle, vous pourrez le mettre à jour dans votre compte fournisseur du marketplace des modèles. Si vous avez apporté des modifications à un modèle, vous devrez le revalider avant que ces modifications ne soient appliquées. Si vous ne revalidez pas, seul le contenu de la page de listing du marketplace sera mis à jour."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour mettre à jour un listing de modèle :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre compte de fournisseur du marketplace des modèles, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketplace de modèles"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom"
          }), " du listing que vous souhaitez mettre à jour. Vous serez redirigé vers l'éditeur de listing."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Apportez les modifications nécessaires aux informations du listing, aux détails du modèle, aux catégories et aux options de support, puis cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Vérifier"
          }), " pour vérifier vos modifications."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Vérifier"
          }), ", vérifiez si le code de votre modèle a changé depuis sa dernière validation :"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si vous n'avez apporté aucune modification au modèle, HubSpot affichera un message de confirmation dans la vignette ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Code source du thème"
            }), " ou dans la vignette ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Code source du module"
            }), " indiquant que votre validation est à jour."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-validation-up-to-date.png",
            alt: "asset-marketplace-validation-up-to-date"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si vous avez apporté des modifications au modèle, HubSpot affichera une alerte dans les vignettes du code source. Pour envoyer ces modifications au marketplace, vous devez d'abord cliquer sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Lancer la validation"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-run-validation.png",
            alt: "asset-marketplace-run-validation"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "5."
          }), " Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Soumettre pour vérification"
          }), " dans l'angle supérieur droit."]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}