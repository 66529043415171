"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890274;
const slug = exports.slug = 'guides/cms/content/content-search';
const title = exports.title = 'Pesquisa de conteúdo';
const name = exports.name = 'LATAM BR (PT) Content Search';
const metaDescription = exports.metaDescription = 'Utilizando a funcionalidade de pesquisa nativa do site dentro do HubSpot CMS, a Pesquisa de conteúdo. O HubSpot CMS inclui uma funcionalidade de pesquisa do site para permitir que os seus visitantes encontrem facilmente o conteúdo que procuram. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "pesquisar-conte%C3%BAdo",
  "label": "Pesquisar conteúdo",
  "parent": null
}, {
  "depth": 0,
  "id": "implementar-pesquisas-no-seu-site",
  "label": "Implementar pesquisas no seu site",
  "parent": null
}, {
  "depth": 1,
  "id": "entrada-de-pesquisa-no-site",
  "label": "Entrada de pesquisa no site",
  "parent": "implementar-pesquisas-no-seu-site"
}, {
  "depth": 1,
  "id": "resultados-de-pesquisa-do-site",
  "label": "Resultados de pesquisa do site",
  "parent": "implementar-pesquisas-no-seu-site"
}, {
  "depth": 0,
  "id": "modelo-de-resultados-de-pesquisa",
  "label": "Modelo de resultados de pesquisa",
  "parent": null
}, {
  "depth": 0,
  "id": "como-a-preced%C3%AAncia-da-pesquisa-%C3%A9-determinada%3F",
  "label": "Como a precedência da pesquisa é determinada?",
  "parent": null
}, {
  "depth": 0,
  "id": "controlar-a-indexa%C3%A7%C3%A3o-durante-o-desenvolvimento",
  "label": "Controlar a indexação durante o desenvolvimento",
  "parent": null
}, {
  "depth": 0,
  "id": "comportamento-padr%C3%A3o-de-indexa%C3%A7%C3%A3o",
  "label": "Comportamento padrão de indexação",
  "parent": null
}, {
  "depth": 0,
  "id": "como-posso-excluir-p%C3%A1ginas-de-serem-retornadas-nos-resultados-de-pesquisa%3F",
  "label": "Como posso excluir páginas de serem retornadas nos resultados de pesquisa?",
  "parent": null
}, {
  "depth": 0,
  "id": "como-excluir-se%C3%A7%C3%B5es-de-uma-p%C3%A1gina-de-serem-indexadas-para-pesquisa",
  "label": "Como excluir seções de uma página de serem indexadas para pesquisa",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      code: "code",
      pre: "pre",
      h3: "h3",
      img: "img",
      strong: "strong",
      ol: "ol",
      li: "li",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Pesquisa de conteúdo"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot CMS inclui uma funcionalidade de pesquisa do site para permitir que os seus visitantes encontrem facilmente o conteúdo que procuram. Todo o conteúdo do seu HubSpot é automaticamente indexado pelo mecanismo de busca nativo da HubSpot, eliminando o incômodo de configurar uma ferramenta de busca de terceiros para o seu site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pesquisar conteúdo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O mecanismo de busca pode ser acessado por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/cms/site-search",
        children: "API de pesquisa do site"
      }), ". Esta API oferece suporte a inúmeras opções de filtragem para permitir que você crie uma experiência de pesquisa altamente personalizada e poderosa em seu site. Por exemplo, se você quiser criar uma pesquisa em seu blog, poderá consultar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type=BLOG_POST"
      }), " para retornar apenas os posts do blog. Ou, se você quisesse criar a pesquisa na versão espanhola do seu site, poderia consultar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "language=es"
      }), " para retornar apenas páginas em espanhol."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A API retorna JSON que pode ser analisado com JavaScript para exibir os resultados no site. Todos os tipos de conteúdo retornarão o domínio da página, o título, a url e o idioma. A descrição retornada é uma amostra de texto do conteúdo que melhor corresponde ao termo de pesquisa. Um elemento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span class=\"hs-search-highlight hs-highlight-html\">"
      }), " envolverá um texto correspondente, permitindo realçar o texto com CSS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dependendo do tipo de conteúdo pesquisado, os resultados retornam informações ligeiramente diferentes, de modo que você pode exibir os resultados para tipos de conteúdo exclusivos de maneira diferente. Por exemplo, os posts de blog retornarão informações sobre quais tags eles têm, quem é o autor e quando ele foi publicado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// json\n{\n  \"total\": 850,\n  \"offset\": 0,\n  \"limit\": 10,\n  \"results\": [\n    {\n      \"id\": 3761238962,\n      \"score\": 0.30858153,\n      \"type\": \"SITE_PAGE\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://developers.hubspot.com/docs/cms/search\",\n      \"language\": \"en\",\n      \"title\": \"Content Search\",\n      \"description\": \"Using HubSpot’s native search engine, content search, to implement <span class=\"hs-search-highlight hs-highlight-html\">search on your website</span>\"\n    },\n    {\n      \"id\": 3254581958,\n      \"score\": 0.30858153,\n      \"type\": \"BLOG_POST\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://designers.hubspot.com/blog/getting-started-with-local-development\",\n      \"featuredImageUrl\":\"https://designers.hubspot.com/hubfs/local-development.jpg\"\n      \"title\": \"Getting Started with Local Development\",\n      \"description\":\"The beta Local Development Tooling connects your local workflow to HubSpot, allowing you to utilize <span class=\"hs-search-highlight hs-highlight-html\">version control</span>, your favorite text editor and various web development technologies when developing on the HubSpot CMS.\"\n      \"authorFullName\": \"William Spiro\",\n      \"tags\": [\n        \"Website Development\",\n        \"Local Development\"\n      ],\n      \"publishedDate\": 1447938000000\n    }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implementar pesquisas no seu site"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Existem dois módulos padrão que você pode usar para implementar facilmente as pesquisas no seu site utilizando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/cms/site-search",
        children: "API de pesquisa"
      }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_input"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_results"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Entrada de pesquisa no site"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um campo de entrada para os visitantes digitarem os termos de pesquisa. Esse módulo pode existir em qualquer lugar do seu site. Esse módulo pode ser incluído em um modelo com ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Resultados de pesquisa do site"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Retorna uma lista de conteúdo com base no termo de pesquisa. Esse módulo pode existir em qualquer lugar do seu site. Esse módulo pode ser incluído em um modelo com"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se a funcionalidade desses módulos não é como você quer que a pesquisa funcione em seu site, você pode criar seus próprios módulos ou funcionalidade de pesquisa. Os módulos de pesquisa padrão são projetados para serem estendidos com base em suas necessidades de pesquisa. Você pode visualizar e clonar esses módulos no Gerenciador de design, procurando seus nomes no Finder, ou você pode buscá-los em seu ambiente local usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cms-cli",
        children: "CLI do CMS"
      }), " e o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_input.module"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_results.module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/default%20search%20module.png",
        alt: "módulo de pesquisa padrão"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modelo de resultados de pesquisa"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada domínio tem sua própria página de resultados de pesquisa por padrão. O modelo e o caminho para esta página estão definidos em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Configurações > Site > Páginas"
      }), " na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "guia Páginas do sistema"
      }), " para domínios específicos. Consulte o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "modelo de resultados de pesquisa do boilerplate de temas do CMS"
      }), " como exemplo. O domínio definido para a página de resultados de pesquisa é automaticamente conectado aos módulos de pesquisa padrão. No entanto, você pode usar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/cms/site-search",
        children: "API de pesquisa do site"
      }), " para criar seus resultados de pesquisa como quiser em qualquer página do site."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Como a precedência da pesquisa é determinada?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A ordem dos resultados de pesquisa é determinada por uma série de comparações de página de conteúdo para o termo de pesquisa do visitante. O conteúdo da página é separado em campos de comparação com peso variável com base em onde o conteúdo abrange no HTML de suas páginas. Campos de comparação estão agrupados em ordem de:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Título HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Metadescrição"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML elementos H1"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Elementos H2 HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Elementos H3 HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Outros elementos HTML"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Observe que essa lista está sujeita a alterações."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você quiser solicitar para o nosso indexador acelerar especificamente certos elementos em uma página, você pode envolver o conteúdo em uma classe ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-keyword"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Controlar a indexação durante o desenvolvimento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao desenvolver um site novo, é útil poder testar a pesquisa do site sem se preocupar se ele foi indexado pelos mecanismos de busca como o Google."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No seu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), ", você pode instruir o HubSpot a rastrear tudo, enquanto bloqueia outros bots."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /\n\n# Allow HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nAllow: /\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Se alguma de suas páginas tiver uma tag meta que não especifica um índice, a página ainda não será indexada, mesmo se permitido no ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt."
        })]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Lembre-se também de revisar seu ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " antes do lançamento para garantir que tudo seja indexado como desejado."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comportamento padrão de indexação"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como o CMS sabe a intenção de determinados tipos de páginas, a pesquisa de conteúdo é capaz de limitar com segurança a indexação para permitir a indexação de páginas de tipo de conteúdo. Exemplos de páginas de tipo de conteúdo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Páginas do site"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Landing pages"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Artigos de conhecimento"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Posts de blog"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "As páginas do sistema e as páginas protegidas por senha não são indexadas."
      }), " As páginas restritas a membros do CMS só serão exibidas na pesquisa de conteúdo do HubSpot para os usuários que estão registados e têm acesso às páginas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Como posso excluir páginas de serem retornadas nos resultados de pesquisa?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você bloquear uma página de ser indexada pelos mecanismos de busca no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), " dos seus sites ou por meio de tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta"
      }), ", elas não serão indexadas para pesquisa."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em seu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "robôs.txt"
      }), ", adicione um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "disallow"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /path-to-page\n\n# Block just HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nDisallow: /path-to-page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode adicionar uma tag meta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "NOINDEX, NOFOLLOW"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " no nível da página ou do modelo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<meta name=\"ROBOTS\" content=\"NOINDEX, NOFOLLOW\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Você não precisa bloquear robôs usando o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " e a tag meta. Isso pode se tornar confuso mais tarde, caso você decida permitir a indexação de uma página."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Como excluir seções de uma página de serem indexadas para pesquisa"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Às vezes, há regiões de uma página que não são úteis para retornar nos resultados de pesquisa. Elas incluem o conteúdo de um cabeçalho, um rodapé, uma barra lateral etc. Nesse caso, você pode adicionar a classe ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-hidden"
      }), " ao seu HTML para que o conteúdo dessas regiões seja ignorado pelas pesquisas."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}