"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 102629641400;
const slug = exports.slug = 'guides/crm/overview';
const title = exports.title = 'Visão geral das ferramentas de desenvolvimento de CRM (BETA)';
const name = exports.name = 'Visão geral das ferramentas de desenvolvimento de CRM (BETA)';
const metaDescription = exports.metaDescription = 'A API da HubSpot permite que os desenvolvedores criem aplicativos e integrações personalizadas. Comece lendo nossa visão geral da API e criando uma conta de desenvolvedor.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "ferramentas-de-desenvolvimento-de-crm",
  "label": "Ferramentas de desenvolvimento de CRM",
  "parent": null
}, {
  "depth": 1,
  "id": "projetos",
  "label": "Projetos",
  "parent": "ferramentas-de-desenvolvimento-de-crm"
}, {
  "depth": 1,
  "id": "aplicativos-privados",
  "label": "Aplicativos privados",
  "parent": "ferramentas-de-desenvolvimento-de-crm"
}, {
  "depth": 1,
  "id": "extens%C3%B5es-de-ui",
  "label": "Extensões de UI",
  "parent": "ferramentas-de-desenvolvimento-de-crm"
}, {
  "depth": 1,
  "id": "fun%C3%A7%C3%B5es-sem-servidor",
  "label": "Funções sem servidor",
  "parent": "ferramentas-de-desenvolvimento-de-crm"
}, {
  "depth": 1,
  "id": "sandboxes-de-desenvolvimento",
  "label": "Sandboxes de desenvolvimento",
  "parent": "ferramentas-de-desenvolvimento-de-crm"
}, {
  "depth": 1,
  "id": "integra%C3%A7%C3%A3o-do-github",
  "label": "Integração do GitHub",
  "parent": "ferramentas-de-desenvolvimento-de-crm"
}, {
  "depth": 0,
  "id": "primeiros-passos",
  "label": "Primeiros passos",
  "parent": null
}, {
  "depth": 0,
  "id": "mais-recursos",
  "label": "Mais recursos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      img: "img",
      h2: "h2",
      h3: "h3",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral das ferramentas de desenvolvimento de CRM (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As ferramentas de desenvolvimento de CRM da HubSpot permitem personalizar seu CRM com extensões de UI, incluindo recursos como sandboxes de desenvolvimento, controle de versão e integração com o GitHub para agilizar seu processo de desenvolvimento. Começando em seu ambiente local, você pode usar essas ferramentas para criar e implantar extensões de UI em registros do CRM para personalizar a experiência do usuário, além de enviar e recuperar dados conforme necessário."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, você pode criar um cartão de aplicativo que recupere dados de uma fonte externa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/custom-extension-example.png",
        alt: "custom-extension-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba mais sobre as ferramentas de desenvolvimento de CRM e como começar a usá-las."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ferramentas de desenvolvimento de CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Projetos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os projetos são o contêiner de maior nível que você criará usando as ferramentas de desenvolvimento de CRM. Os projetos permitem criar e implantar localmente aplicativos privados, extensões de UI e funções sem servidor usando a CLI. Depois de implantado em uma conta, você poderá visualizar e gerenciar o projeto e seus aplicativos e extensões de UI no HubSpot. Isso inclui visualizar o histórico de compilação e monitorar chamadas de API."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project",
        children: "criar projetos"
      }), " e confira a lista crescente de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "amostras de projetos"
      }), " para ver exemplos do que é possível fazer."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicativos privados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os aplicativos privados permitem enviar e recuperar dados usando o token de acesso do aplicativo privado para autenticação. Também executam funções sem servidor que servem como back-end para as extensões de UI. Um projeto pode conter um aplicativo privado e cada aplicativo privado pode conter várias extensões de UI e funções sem servidor."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-private-apps-with-projects",
        children: "criar aplicativos privados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Atualmente, os aplicativos privados criados com projetos oferecem suporte apenas à criação de extensões de UI. Se você quiser criar um aplicativo privado para funcionar com outras extensões da HubSpot, saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "diferentes tipos de aplicativos e suas compatibilidades"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Extensões de UI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As extensões de UI são elementos de interface personalizados que os usuários da conta da HubSpot podem visualizar e interagir. No momento, os cartões de aplicativo para registros do CRM e help desk são a única extensão de UI disponível. Por exemplo, você pode criar um cartão que permita que os usuários enviem dados de formulário para um banco de dados externo de qualquer registro de contato ou um cartão que os representantes de suporte ao cliente podem usar para agir em tickets abertos na interface do usuário do help desk."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As extensões de UI são criadas usando projetos e consistem em um front-end e um back-end:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Front-end da extensão da UI:"
        }), " parte da extensão destinada ao usuário, sob a forma de um cartão de aplicativo com ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview",
          children: "componentes fornecidos pela HubSpot"
        }), ". Além de exibir informações, os usuários podem interagir com os componentes para realizar diversas ações. Você criará o front-end com React ou TypeScript."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Back-end da extensão de UI:"
        }), " a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/serverless-functions",
          children: "função sem servidor"
        }), " que permite que uma extensão de UI envie e recupere dados para exibição nos componentes. Desenvolvida pelo aplicativo privado do projeto, uma função sem servidor pode ser reutilizada por vários componentes conforme necessário."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os cartões de aplicativo que você pode criar nos projetos não dependem da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/extensions/overview",
        children: "extensão de API de cartões do CRM da HubSpot"
      }), " e não podem ser criados de forma intercambiável."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview",
        children: "como funcionam as extensões de UI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Funções sem servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma função sem servidor executa JavaScript no lado do servidor para fornecer funcionalidade de back-end para as extensões de UI. As funções sem servidor estão contidas em aplicativos privados. As funções sem servidor consistem em uma pasta que contém um ou mais arquivos JavaScript que exportam uma função ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "main"
      }), " e um arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " que registra e configura suas funções."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/serverless-functions",
        children: "criar funções sem servidor com projetos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Embora semelhantes em conceito, as funções sem servidor usadas nos projetos são diferentes das usadas pelo CMS da HubSpot. Algumas dessas diferenças incluem:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Você não pode criar uma função sem servidor em um projeto com o comando ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs create function"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "As funções sem servidor criadas nos projetos não podem ser usadas para páginas de CMS."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "As funções sem servidor criadas nos projetos não aparecem no gerenciador de design."
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para criar funções sem servidor para páginas de CMS, veja a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "documentação para desenvolvedores de CMS"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sandboxes de desenvolvimento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As sandboxes de desenvolvimento permitem desenvolver projetos em um ambiente de testes leve para garantir que os componentes do seu projeto funcionem conforme o esperado antes da implantação em uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "sandbox padrão ou conta de produção"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As sandboxes de desenvolvimento são criadas por meio da CLI e podem ser acessadas na conta de produção da HubSpot. As sandboxes de desenvolvimento sincronizam alguns ativos da conta no momento da criação, mas não todos, e têm limites adicionais em comparação com as sandboxes padrão."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/developer-projects-setup",
        children: "configurar uma sandbox de desenvolvimento"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Integração do GitHub"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se preferir usar o GitHub para controle de versão, você pode conectar um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/link-a-github-repository-to-a-project",
        children: "Repositório GitHub a um projeto"
      }), " para acionar automaticamente compilações de projetos ao enviar uma alteração para o repositório conectado. Assim, você pode usar as ferramentas e os fluxos de trabalho do GitHub para agilizar o desenvolvimento, quer trabalhe sozinho ou em equipe."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Primeiros passos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você for um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/user-management/hubspot-user-permissions-guide#super-admin",
        children: "Superadministrador"
      }), " em uma conta ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Sales Hub"
      }), " ou ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["Service Hub ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enterprise"
        })]
      }), ", poderá se ingressar nesta versão beta na página ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Atualizações do produto"
      }), " na sua conta da HubSpot:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na sua conta da HubSpot, clique no seu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome de conta"
          }), " no canto superior direito e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Atualizações do produto"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na barra lateral esquerda, selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Em versão beta"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na lista de versões beta, pesquise ou navegue até a versão beta das ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Ferramentas de desenvolvimento de CRM para criar extensões de interface do usuário com React como front-end"
          }), " e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Participar da versão beta"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/crm-development-tools-join-beta.png",
            alt: "crm-development-tools-join-beta"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de ingressar na versão beta, comece com uma das seguintes opções:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Siga o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/quickstart",
          children: "guia de início rápido"
        }), " para criar e implantar rapidamente um exemplo de cartão de aplicativo funcional."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Veja ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/sample-projects/overview",
          children: "amostras de projetos da HubSpot"
        }), " para ver exemplos do que é possível fazer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Construa seu projeto do zero começando com o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "guia de projetos da HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mais recursos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/CRM-Development-Tools-Beta/gh-p/crm-development-tools-beta",
          children: "Ferramentas de desenvolvimento de CRM na comunidade da HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/crm-development-tools-for-hubspot-developers-beta",
          children: "Ferramentas de desenvolvimento de CRM no Blog do desenvolvedor da HubSpot"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}