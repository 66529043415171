import { isEmpty } from 'underscore';
export const EMPTY_JSON_BLOCK = '{\n  \n}';

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export const getJsonString = (val, prettyPrint = false) => {
  let json = EMPTY_JSON_BLOCK;
  if (!val || isEmpty(val)) {
    return json;
  }
  try {
    json = prettyPrint ? JSON.stringify(val, null, 2) : JSON.stringify(val);
  } catch (e) {
    // Swallow error
  }
  return json;
};