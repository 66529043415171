"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 157882689159;
const slug = exports.slug = 'guides/apps/authentication/scopes';
const title = exports.title = 'Scopes';
const name = exports.name = 'Scopes';
const metaDescription = exports.metaDescription = 'Learn about the different scopes available for private and public apps in HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "find-required-scopes-for-an-endpoint",
  "label": "Find required scopes for an endpoint",
  "parent": null
}, {
  "depth": 0,
  "id": "list-of-available-scopes",
  "label": "List of available scopes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      em: "em",
      strong: "strong",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Scopes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Scopes provide access to a specific set of HubSpot API endpoints and the associated data from a HubSpot account. If you created a private app, you can specify which scopes your app has access to in your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#create-a-private-app",
        children: "private app settings"
      }), ". If you're developing a public app, you'll ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#configure-scopes",
        children: "configure both required and optional scopes"
      }), " that users who install your app will be prompted to authorize via the app's install URL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Find required scopes for an endpoint"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any scopes required to make a request to a specific endpoint will be listed under the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Requirements"
      }), " section in the endpoint documentation, which can be accessed by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " tab in an API reference article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/find-scopes-on-endpoints-tab.png",
        alt: "find-scopes-on-endpoints-tab"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Some scopes may list both ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Standard"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Granular"
      }), " scopes. If both scope types are listed, you should opt for using the granular scopes when possible, as they specify more explicit access for your API requests."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "List of available scopes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Access to specific APIs or endpoints depends on HubSpot account tier. You can find a full list of available scopes and accessible endpoints in the table below."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Provides access to"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Required account tier"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["List ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/cms/domains",
              children: "connected domains"
            }), "in an account."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Create, update, and delete ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/cms/domains",
              children: "connected domains"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View all ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/cms/content/data-driven-content/serverless-functions/overview",
              children: "Content Hub serverless functions"
            }), ", any related secrets, and function execution results."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Content Hub"
              }), " Enterprise"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Grants access to write ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/cms/content/data-driven-content/serverless-functions/overview",
              children: "Content Hub serverless functions"
            }), " and secrets."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Content Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about knowledge articles."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to update knowledge articles."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.publish"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to update and publish knowledge articles."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View general and template knowledge base settings, such as the domain or root URL."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to update general and template knowledge base settings. This includes write access to knowledge articles."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.membership.access_groups.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/cms/content/memberships/overview",
              children: "membership access groups"
            }), " and their definitions."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " or ", (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Content Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.membership.access_groups.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Create, edit, and delete ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/cms/content/memberships/overview",
              children: "membership access groups"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "Service Hub"
              })
            }), " or ", (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Content Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about contact lists."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "List endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to contact lists"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "List endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.appointments.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about appointments."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Appointments endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.appointments.sensitive.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties for appointments."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Appointments endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.appointments.sensitive.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties and values for appointments."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Appointments endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.appointments.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to appointments."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Appointments endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.carts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about carts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Carts endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.carts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to carts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Carts endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.commercepayments.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about commerce payments."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Commerce payments endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.highly_sensitive.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#highly-sensitive-scopes-beta",
              children: "Highly Sensitive Data"
            }), "properties for companies."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Companies endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.highly_sensitive.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#highly-sensitive-scopes-beta",
              children: "Highly Sensitive Data"
            }), "properties and values for companies."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Companies endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about companies."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Companies endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.sensitive.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties for companies."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Companies endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.sensitive.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties and values for companies."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Companies endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and create, delete, or make changes to companies."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Companies endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.highly_sensitive.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#highly-sensitive-scopes-beta",
              children: "Highly Sensitive Data"
            }), "properties for contacts."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.highly_sensitive.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#highly-sensitive-scopes-beta",
              children: "Highly Sensitive Data"
            }), "properties and values for contacts."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.sensitive.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties for contacts."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.sensitive.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties and values for contacts."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and create, delete, and make changes to contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.courses.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about courses."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Courses endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.courses.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to courses."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Courses endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.highly_sensitive.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#highly-sensitive-scopes-beta",
              children: "Highly Sensitive Data"
            }), "properties for custom objects."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom objects endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.highly_sensitive.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#highly-sensitive-scopes-beta",
              children: "Highly Sensitive Data"
            }), "properties and values for custom objects."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom objects endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about custom objects."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom objects endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.sensitive.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties for custom objects."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom objects endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.sensitive.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties and values for custom objects."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom object endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to custom objects."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom objects endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.highly_sensitive.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#highly-sensitive-scopes-beta",
              children: "Highly Sensitive Data"
            }), "properties for deals."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.highly_sensitive.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#highly-sensitive-scopes-beta",
              children: "Highly Sensitive Data"
            }), "properties and values for deals."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about deals."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.sensitive.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties for deals."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.sensitive.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties and values for deals."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and create, delete, or make changes to deals."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.feedback_submission.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about submissions to any of your feedback surveys."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feedback surveys endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.goals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View all goal types."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Goals endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Sales Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.invoices.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about invoices."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Invoices endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.leads.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about leads."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Leads endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Sales Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.leads.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to leads."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Leads endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "Sales Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about line items."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Line items endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to line items."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Line items endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.listings.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about listings."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Listings endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.listings.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to listings."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Listings endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about marketing events."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketing events endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to marketing events."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketing events endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.orders.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View properties and other details about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/crm/commerce/orders",
              children: "orders"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Orders endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.orders.write"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Create, delete, or make changes to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/crm/commerce/orders",
              children: "orders"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Orders endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.owners.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about users assigned to a CRM record."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Owners endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about quotes and quote templates."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quotes endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to quotes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quotes endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.services.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about services."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Services endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.services.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to services."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Services endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.subscriptions.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View properties and other details about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/crm/commerce/subscriptions",
              children: "commerce subscriptions"
            }), "."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/crm/commerce/subscriptions",
              children: "Commerce subscriptions"
            }), " endpoints"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.users.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View properties and other details about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/settings/users/user-details",
              children: "users"
            }), "."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/settings/users/user-details",
              children: "Users"
            }), " endpoints"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.users.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to users."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/settings/users/user-details",
              children: "Users"
            }), " endpoints"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.carts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about property settings for carts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Carts endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.carts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to property settings for carts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Carts endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.commercepayments.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about property settings for commerce payments."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Commerce payments endpoints."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), " account"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about property settings for companies"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to property settings for companies."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about property settings for contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to property settings for contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about custom object definitions in the HubSpot CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom objects endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about property settings for deals."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to property settings for deals."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schema.invoices.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about property settings for invoices."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Invoices endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about line items."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Line items endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about quotes and quotes templates."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quotes endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.subscriptions.read"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["View details about property settings for ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/crm/commerce/subscriptions",
              children: "commerce subscriptions"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Commerce subscriptions endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.billing.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Make changes to your account's billing settings. This includes managing and assigning paid seats for users."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Settings endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reads existing exchange rates along with the current company currency associated with your portal."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Account information endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, update and delete exchange rates along with updating the company currency associated with your portal."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Account information endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about account users and their permissions."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "User Provisioning endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Manage users and user permissions on your HubSpot account. This includes creating new users, assigning permissions and roles, and deleting existing users."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "User Provisioning endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.teams.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "See details about the account's teams."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "User Provisioning endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.team.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Assign users to teams on your HubSpot account."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "User Provisioning endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account-info.security.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Includes access to account activity logs and other account security information."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Account activity API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accounting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Allows HubSpot and the accounting integration to share invoice, product, and contact details."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Accounting Extension API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "actions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Add forms to the contact's pages that do custom actions."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM Extensions API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "analytics.behavioral_events.send"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Includes access to send custom behavioral events."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Analytics API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes workflows."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Automation API (Workflows endpoints)"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "behavioral_events.event_definitions.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, read, update, or delete behavioral events. This includes behavioral event properties."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Analytics API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business_units.view.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View business unit data, including logo information."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Business Units API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Business Units Add-on"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business-intelligence"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes endpoints that sit on top of sources and email."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Analytics API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_query.execute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Query data from your HubSpot account using the GraphQL API endpoint"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphQL API endpoint"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_schema.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Perform introspection queries via GraphQL application clients such as GraphiQL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphiQL and other 3rd party GraphQL clients"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details of your contacts' subscription preferences."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscription Preferences API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscribe/unsubscribe contacts to your subscription types. It won't subscribe contacts who have unsubscribed."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscription Preferences API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscribe/unsubscribe contacts to your subscription types. It won't subscribe contacts who have unsubscribed."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscription Preferences API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes sites, landing pages, email, blog, and campaigns."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API and Calendar, Email and Email Events endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise,"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about threads in the conversations inbox."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Conversations inbox and messages API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.visitor_identification.tokens.create"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fetch identification tokens for authenticated website visitors interacting with the HubSpot chat widget."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Visitor Identification API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Send messages in conversations. Create and update message threads."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Conversations inbox and messages API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.export"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Export records from your CRM for all CRM data types."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM Exports API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.import"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Allows you to import records into your CRM. This includes creating new records or modifying any of your existing records for all CRM data types (contacts, companies, deals, tickets, etc). It doesn't include archiving or deleting any data."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM Imports API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ctas.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Allows read access for CTAs."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No publicAPI available"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })]
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter, Professional or Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes access to e-commerce features."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Products and line items endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any account", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Note:"
            }), " Only ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " accounts can use this scope for the Products API."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "external_integrations.forms.access"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Includes the ability to rename, delete, and clone existing forms."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Forms endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes access to File Manager."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Files (File Manager) and file mapper (CMS templates, modules, and layout) endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files.ui_hidden.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details or download user files, attachments, and system files from all HubSpot tools."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Files (File Manager) and file mapper (CMS templates, modules, and layout) endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes access to the Forms endpoints."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Forms endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms-uploaded-files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Download files submitted through a form."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get a file uploaded via form submission endpoint"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes access to HubDB."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDB endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " with ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/website",
              children: "Website Add-on"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "integration-sync"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This exposes the sync API, which allows syncing of most CRM objects."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ecommerce Bridge API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "marketing-email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to send marketing emails through the single-send API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketing emails API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " or the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/marketing/transactional-email",
              children: "transactional email add-on"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to events and objects from the media bridge."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Media Bridge API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to create and update events and objects from the media bridge."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Media Bridge API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oauth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Basic scope required for OAuth. This scope is added by default to all apps."
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sales-email-read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to read all details of one-to-one emails sent to contacts."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Engagements endpoints", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Note:"
            }), " This scope is required to get the content of email engagements. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/engagement-details#email-content",
              children: "Engagements overview"
            }), " for more details."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes Social Inbox."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Social Media API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes access to tickets."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets.highly_sensitive"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Grants access to view and edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#highly-sensitive-scopes-beta",
              children: "Highly Sensitive Data"
            }), "properties and values for tickets."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any Enterprise account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets.sensitive"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Grants access to view and edit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/sensitive-data#sensitive-scopes",
              children: "Sensitive Data"
            }), "properties and values for tickets."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any Enterprise account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to manage custom events on HubSpot CRM records. This includes creating or updating records."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Timeline Events endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transactional-email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes transactional emails and the transactional emails endpoints."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Transactional email endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["**Marketing Hub** Professional or Enterprise with ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/email/transactional-email",
              children: "Transactional Email Add-on"
            })]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}