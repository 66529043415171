"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400696;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/overview';
const title = exports.title = 'Áreas de arrastar e soltar';
const name = exports.name = '[NEW] Drag and Drop Areas_PT';
const metaDescription = exports.metaDescription = 'As áreas de arrastar e soltar permitem aos criadores de conteúdo adicionar módulos e alterar o layout e parte do estilo dentro dos editores de conteúdo. Os desenvolvedores criam layouts predefinidos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "a-experi%C3%AAncia-do-criador-de-conte%C3%BAdo",
  "label": "A experiência do criador de conteúdo",
  "parent": null
}, {
  "depth": 0,
  "id": "a-experi%C3%AAncia-do-desenvolvedor",
  "label": "A experiência do desenvolvedor",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A1reas-de-arrastar-e-soltar-e-seus-elementos",
  "label": "Áreas de arrastar e soltar e seus elementos",
  "parent": null
}, {
  "depth": 1,
  "id": "se%C3%A7%C3%A3o",
  "label": "Seção",
  "parent": "%C3%A1reas-de-arrastar-e-soltar-e-seus-elementos"
}, {
  "depth": 1,
  "id": "coluna",
  "label": "Coluna",
  "parent": "%C3%A1reas-de-arrastar-e-soltar-e-seus-elementos"
}, {
  "depth": 1,
  "id": "linha",
  "label": "Linha",
  "parent": "%C3%A1reas-de-arrastar-e-soltar-e-seus-elementos"
}, {
  "depth": 1,
  "id": "m%C3%B3dulo",
  "label": "Módulo",
  "parent": "%C3%A1reas-de-arrastar-e-soltar-e-seus-elementos"
}, {
  "depth": 0,
  "id": "estrutura-e-estilo-em-html",
  "label": "Estrutura e estilo em HTML",
  "parent": null
}, {
  "depth": 1,
  "id": "estilo-de-atributo-e-do-editor",
  "label": "Estilo de atributo e do editor",
  "parent": "estrutura-e-estilo-em-html"
}, {
  "depth": 0,
  "id": "migra%C3%A7%C3%A3o",
  "label": "Migração",
  "parent": null
}, {
  "depth": 0,
  "id": "tutoriais-e-guias-relacionados",
  "label": "Tutoriais e guias relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Áreas de arrastar e soltar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta página ilustra e explica a experiência e os conceitos de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ". Como essa experiência é bastante abrangente, é uma boa ideia familiarizar-se com ela antes de se debruçar sobre o código."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando você estiver pronto para começar, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "introdução a dnd_area"
      }), " e a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "referência dnd_area"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As áreas de arrastar e soltar permitem aos desenvolvedores criar áreas de páginas e partials globais que são úteis para os criadores de conteúdo. As áreas de arrastar e soltar permitem aos criadores de conteúdo adicionar módulos e alterar o layout e parte do estilo dentro dos editores de conteúdo. Dessa forma, você precisa criar menos modelos, que os criadores de conteúdo podem usar para fazer uma infinidade de páginas com diferentes propósitos e layouts por conta própria. Os criadores de conteúdo podem fazer isso sem se preocupar com codificação ou a necessidade de criar novos modelos para fazer pequenas alterações de layout. Por serem tão flexíveis e úteis, é uma boa ideia ter pelo menos um modelo no seu tema com áreas de arrastar e soltar."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " as áreas de arrastar e soltar não podem ser usadas em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "posts de blog"
        }), " e em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#email",
          children: "modelos de e-mail"
        }), " no momento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "A experiência do criador de conteúdo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando um criador de conteúdo cria uma página usando um modelo com áreas de arrastar e soltar, o que ele vê primeiro é a página com os módulos predefinidos dispostos em um layout pelo desenvolvedor. Isso ajuda o criador do conteúdo a compreender como a maioria das páginas que usam esse modelo devem ou tendem a parecer visualmente. Esse é um ponto de partida, mas não uma aparência fechada. O criador do conteúdo pode arrastar e soltar módulos, seções, linhas e colunas para reordená-los. Eles podem redimensioná-los, editar o seu conteúdo e ajustar várias configurações de estilo visual que lhes permitem alinhar verticalmente e horizontalmente o conteúdo e adicionar fundos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para os criadores de conteúdo, isso significa muito. Isso lhes dá flexibilidade suficiente para fazer mudanças de página simples, sem incomodar um desenvolvedor por pequenos ajustes."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " um criador de conteúdo pode trocar o modelo de uma página por outro do mesmo tipo, dependendo se tem tags ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos criados com o editor visual de layout de arrastar e soltar podem ser trocados por outros modelos de arrastar e soltar ou codificados com ou sem as tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos codificados com tags ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " somente podem ser trocados por outros modelos codificados com tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos codificados ", (0, _jsxRuntime.jsx)("u", {
            children: "sem"
          }), " tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " somente podem ser trocados por outros modelos codificados sem tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/drag-and-drop.gif",
        alt: "A experiência do editor de páginas para dnd_areas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "A experiência do desenvolvedor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para os desenvolvedores, o trabalho com dnd_area é parecido com o trabalho com frameworks CSS comuns e seus grids. Os desenvolvedores compreendem a página usando containers (chamados seções) que contêm linhas. Dentro dessas linhas estão os módulos e as colunas. Quase tudo o que os criadores de conteúdo podem fazer no editor de páginas com tags de arrastar e soltar os desenvolvedores podem fazer com código nos próprios modelos. O que os desenvolvedores estão criando é o conteúdo padrão das páginas que usam esse modelo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O benefício de usar áreas de arrastar e soltar em comparação às tags de módulo com codificação complexa no modelo é que os criadores de conteúdo podem alterar o conteúdo e o layout dentro delas. Devido a essa flexibilidade, um modelo com áreas de arrastar e soltar pode ser usado para uma infinidade de designs de página."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consulte os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "modelos de temas do boilerplate do CMS"
      }), " para ver as tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " em uso."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd%20coded.png",
        alt: "dnd_area coded in VS Code"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Áreas de arrastar e soltar e seus elementos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As áreas de arrastar e soltar são constituídas por componentes básicos que definem o layout e permitem alinhar o estilo com flexibilidade. A tag ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), " cria uma região na página que o editor de páginas reconhece, o que permitindo adicionar, remover e reorganizar os elementos de arrastar e soltar. Você os define usando HubL dentro dos modelos HTML+HubL. Todos os elementos de arrastar e soltar residem em uma área de arrastar e soltar. Não é possível aninhar áreas de arrastar e soltar, e os elementos não podem conter áreas de arrastar e soltar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um aspecto importante para compreender como desenvolvedor é que o conteúdo da área de arrastar e soltar que você define no modelo é um ponto de partida para páginas que usam esse modelo. Os criadores de conteúdo podem alterar completamente o conteúdo dentro de uma área de arrastar e soltar. O seu trabalho é fornecer um ponto de partida que faça sentido, para que eles possam fazer as modificações."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Example%20structure.png",
        alt: "Exemplo de estrutura"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este diagrama apresenta os detalhes de como os vários elementos de arrastar e soltar podem ser aninhados. Um conceito importante é que tanto as seções quanto as linhas podem conter colunas e módulos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Drag%20and%20Drop%20relationships.png",
        alt: "Relações entre elementos de arrastar e soltar"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seção"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As seções são um tipo especial de linha. As seções são criadas nos modelos usando a tag ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-section-code-dnd-section-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        })
      }), ". Elas são o único elemento de arrastar e soltar que pode ser um descendente direto de uma área de arrastar e soltar. Você pode pensar nas seções como um container de encapsulamento. Elas podem permitir que o conteúdo tenha uma largura total ou uma largura máxima confinada ao centro. Como as seções envolvem as colunas e os módulos, elas facilitam a reorganização e a implementação de grandes porções de conteúdo. A tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " não renderiza um elemento HTML ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Section%20in%20page%20editor.png",
        alt: "Seção no editor de página"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A aparência de uma seção no editor de páginas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os desenvolvedores podem fornecer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-templates",
        children: "modelos de seções"
      }), " que criam seções reutilizáveis no editor de páginas. Estas são seções predefinidas pelo desenvolvedor que os criadores de conteúdo podem usar como ponto de partida. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#reusable-sections",
        children: "As seções reutilizáveis"
      }), " têm alguns recursos exclusivos, incluindo a possibilidade de usá-las de forma similar a um partial de modelo padrão do hubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Coluna"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As colunas são wrappers para filas e módulos. Você coloca colunas dentro de uma linha, ou seção que é uma linha especializada. As colunas são criadas nos modelos usando a tag ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-column-code-dnd-column-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use várias colunas dentro de uma linha para colocar as linhas e os módulos que elas contêm na horizontal."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As colunas são regiões verticais que podem conter linhas. Você pode alterar o tamanho das colunas alterando sua largura. O tamanho de uma linha é de 12 \"colunas\" de largura; isso se refere à grid do CSS. As colunas dentro de uma linha podem ter qualquer tamanho inferior a 12, mas não podem somar mais de 12."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando várias linhas são colocadas dentro de uma coluna, os módulos dentro dessas linhas aparecerão empilhados na vertical. Como os módulos são colunas em si, um módulo não pode ser um descendente direto de uma coluna, eles devem estar contidos dentro de uma linha."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20column%20in%20page%20editor.png",
        alt: "A coluna dnd_area no editor de páginas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A aparência de uma coluna no editor de páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Linha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As linhas são wrappers de colunas. As linhas são criadas nos modelos usando a tag ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-row-code-dnd-row-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        })
      }), ". Como os módulos são colunas, você pode colocá-los diretamente dentro de uma linha. Isso fará com que os módulos apareçam na horizontal, uns ao lado dos outros."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os módulos podem ser organizados na vertical ao colocá-los dentro de linhas. Se você quiser colocar um módulo acima de outro, será necessário colocar o módulo dentro de uma linha e então adicionar o outro módulo em uma linha acima ou abaixo daquela primeira linha."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20row%20in%20page%20editor.png",
        alt: "A linha dnd_area no editor de páginas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A aparência de uma linha no editor de páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Os módulos"
      }), " são uma parte fundamental do HubSpot CMS e funcionam como os componentes básicos reutilizáveis que você usa para criar um site e exibir o conteúdo. Ao construir um modelo, você coloca os módulos dentro de linhas e seções de arrastar e soltar usando a tag ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-module-code-dnd-module-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), ". Os módulos também são colunas. Ou seja, se você colocar duas tags de módulo, ou um módulo e uma coluna diretamente um ao lado do outro, elas aparecerão lado a lado horizontalmente."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nenhum elemento de arrastar e soltar pode ser colocado dentro de um módulo. Os módulos não podem ser elementos secundários diretos de uma tag ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Estrutura e estilo em HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando renderizados, as áreas de arrastar e soltar e seus elementos têm nomes de classe para um grid de 12 colunas com base no bootstrap 2. Para facilitar, você pode usar o arquivo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "_layout.css"
      }), " do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/",
        children: "Boilerplate de temas do CMS"
      }), ". Isso fornece estilos padrão para esses nomes de classe."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você não é obrigado a usar esta folha de estilos e pode fornecer seus próprios estilos. Se você está construindo seu site com base no Boilerplate de temas do CMS e quer usar seu próprio CSS, talvez queira ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/5abaf2a4c45a95dbed1d459f7f0f6407350752ac/src/templates/layouts/base.html#L8",
        children: "remover a chamada do layout.css no base.html"
      }), ". Para o seu próprio grid CSS, você precisará direcionar esses mesmos nomes de classes de grid, mas o estilo ficará ao seu critério."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando renderizadas, as áreas de arrastar e soltar criam divs com classes que são usadas pelo editor de páginas. Os exemplos são ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-span"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-type-cell"
      }), ". Você não deve direcionar diretamente essas classes, pois elas são usadas pelo editor de páginas e podem mudar ao longo do caminho."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em vez disso, na tag ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), " do HubL, adicione um parâmetro de classe com o nome de classe que você gostaria de usar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"container-fluid my-custom-class\">\n  <div class=\"row-fluid-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n      <!--end widget-span -->\n    </div>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilo de atributo e do editor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com as áreas de arrastar e soltar, os criadores de conteúdo podem influenciar o estilo da página. Por exemplo, eles podem definir uma seção para ter um fundo. Os desenvolvedores podem passar valores padrão para essas configurações através de atributos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando a página é renderizada, os estilos gerados com base nessas configurações são adicionados a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Na inicialização de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), ", esses estilos são carregados de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        }), ". Isso foi ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/drag-and-drop-area-styles-moved-from-footer-to-header",
          children: "alterado recentemente"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        }), " e está sendo implementado em todas as contas da HubSpot que usam o HubSpot CMS."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migração"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se estiver alterando modelos construídos com colunas flexíveis para usar áreas de arrastar e soltar, há algumas coisas que você deve saber. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "As colunas flexíveis não são como as áreas de arrastar e soltar. Você não pode trocar um modelo que só tem uma coluna flexível por outro que só tem uma área de arrastar e soltar"
      }), ". Não permitimos isso por precaução. O conteúdo não seria mapeado da coluna flexível para a área de arrastar e soltar. Para ilustrar o porquê disso, suponha que você criou um novo modelo para exibir uma barra lateral e uma área de conteúdo principal. A barra lateral é uma coluna flexível; o conteúdo principal é uma área de arrastar e soltar. A ferramenta de troca mapearia a coluna flexível para a coluna flexível."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriais e guias relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites#drag-and-drop-areas",
          children: "Adicionar áreas de arrastar e soltar a sites existentes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Criar uma área para soltar"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}