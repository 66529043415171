"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492335032;
const slug = exports.slug = 'guides/cms/content/data-driven-content/crm-objects';
const title = exports.title = 'CRM-Objekte in CMS Hub';
const name = exports.name = 'EMEA DACH (DE) CRM Custom Objects in CMS Hub';
const metaDescription = exports.metaDescription = 'CRM-Objekte können in von HubSpot gehosteten Inhalten abgefragt und gerendert werden, sodass Daten zwischen Ihren Geschäftsprozessen, Ihrer Website und Ihren E-Mails ausgetauscht werden können.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "unterst%C3%BCtzte-crm-objekttypen",
  "label": "Unterstützte CRM-Objekttypen",
  "parent": null
}, {
  "depth": 1,
  "id": "f%C3%BCr-alle-seiten-verf%C3%BCgbare-crm-objektdaten",
  "label": "Für alle Seiten verfügbare CRM-Objektdaten",
  "parent": "unterst%C3%BCtzte-crm-objekttypen"
}, {
  "depth": 1,
  "id": "f%C3%BCr-private-seiten-verf%C3%BCgbare-crm-objektdaten",
  "label": "Für private Seiten verfügbare CRM-Objektdaten",
  "parent": "unterst%C3%BCtzte-crm-objekttypen"
}, {
  "depth": 0,
  "id": "daten-von-einem-einzelnen-crm-datensatz-mithilfe-der-crm_object-funktion-anzeigen",
  "label": "Daten von einem einzelnen CRM-Datensatz mithilfe der crm_object-Funktion anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "daten-von-mehreren-crm-datens%C3%A4tzen-mithilfe-der-crm_object-funktion-anzeigen",
  "label": "Daten von mehreren CRM-Datensätzen mithilfe der crm_object-Funktion anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "verkn%C3%BCpfte-datens%C3%A4tze-anzeigen",
  "label": "Verknüpfte Datensätze anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "abrufen-der-details-eines-benutzerdefinierten-objekttyps",
  "label": "Abrufen der Details eines benutzerdefinierten Objekttyps",
  "parent": null
}, {
  "depth": 0,
  "id": "crm-objektmodulfeld",
  "label": "CRM-Objektmodulfeld",
  "parent": null
}, {
  "depth": 0,
  "id": "tutorials-und-ressourcen-zu-hubdb-objekten",
  "label": "Tutorials und Ressourcen zu HubDB-Objekten",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h3: "h3",
      em: "em",
      h2: "h2",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      img: "img",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CRM-Objektdaten in CMS Hub verwenden"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können CRM-Objekte abfragen, um Daten aus den Datensätzen des Objekts in den von HubSpot gehosteten Inhalten zu verwenden, sodass Daten zwischen Ihren Geschäftsprozessen, Ihrer Website und Ihren E-Mails ausgetauscht werden können. Unter Verwendung der HubL-Funktionen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        })
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), " können Sie die Logik auf der Grundlage Ihrer CRM-Objektdaten anzeigen und steuern."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Verwendung von CRM-Daten auf Ihrer Website bedeutet, dass Ihre Vertriebs-, Marketing- und Website-Daten alle am selben Ort sind und immer dieselben Informationen enthalten. Und da Sie CRM-Datensätze miteinander verknüpfen können, können Sie auch die zugehörigen Daten auf Ihre Webseiten holen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ebenso können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "Sätze dynamischer Seiten erstellen, die automatisch aus CRM-Objekt- oder HubDB-Daten generiert werden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Weitere Informationen zum Erstellen datenbasierter CMS-Seiten finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "Kurs zu CMS-datengestütztem Content"
        }), " der HubSpot Academy."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Anwendungsfall als Beispiel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Beispiel für die Verwendung von CRM-Objektdaten in Seiten ist eine Seite mit Immobilienangeboten. Mit einem benutzerdefinierten Objekt namens ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "property"
      }), " können für jedes Haus, das aufgelistet werden soll, individuelle Objektdatensätze erstellt werden. Immobilienmakler können dann Informationen zu Objekteigenschaften hinzufügen, um Details wie Standort, Anzahl der Schlafzimmer und Preisvorstellungen zu speichern."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Website-Seiten können dann diese Daten für jede Immobilie übernehmen, um eine Angebotsseite und Detailseiten für jede Immobilie zu erstellen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-custom-objects-example",
        children: "Besuchen Sie das GitHub-Repository"
      }), " , um das vollständige Beispiel zu sehen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Einen Überblick über dieses Beispiel finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/developer-day-2020",
        children: "Aufzeichnung des HubSpot Developer Day 2020"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unterstützte CRM-Objekttypen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden sind die Typen von CRM-Objekten aufgeführt, aus denen Sie Daten für Ihre CMS Hub-Seiten abrufen können. Ob Sie die Daten auf allen Seiten oder nur auf privaten Seiten verwenden können, hängt vom Objekttyp ab."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In den folgenden Tabellen erfahren Sie, welche Objekttypen für CMS-Content zur Verfügung stehen und wie deren Objekttypnamen und vollständig qualifizierten Namen lauten."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Bei Standardobjektnamen wie „Kontakt“ wird nicht zwischen Groß- und Kleinschreibung unterschieden, sie müssen jedoch in der Singularform angegeben werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Für alle Seiten verfügbare CRM-Objektdaten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Daten aus den folgenden CRM-Objekten können auf jeder CMS-Seite verwendet werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Objekttyp"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "object_type name"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Vollständig qualifizierter Name"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "Produkte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRODUCT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "Marketing-Events"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "marketing_event"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/custom-objects",
              children: "Benutzerdefinierte Objekte"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Nur ", (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Sales Hub"
              }), " Enterprise"]
            }), " Sie können entweder den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "vollständig qualifizierten Namen"
            }), " des Objekts oder den Namen verwenden, der zum Zeitpunkt der Erstellung eingegeben wurde. Wenn Sie beispielsweise ein Objekt mit dem Namen „Autos“ erstellen, können Sie es nicht mit „autos“ oder „Auto“ referenzieren.Sie müssen den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "vollständig qualifizierten Namen verwenden"
            }), ", wenn das benutzerdefinierte Objekt einen Namen mit einem Standardobjekt teilt."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Für private Seiten verfügbare CRM-Objektdaten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Daten aus den folgenden CRM-Objekten können nur auf Seiten verwendet werden, die entweder ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/password-protect-a-page",
        children: "Passwort"
      }), " oder eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/require-member-registration-to-access-private-content",
        children: "Mitgliederanmeldung"
      }), " erfordern."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Objekttyp"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "object_type name"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "VOLLSTÄNDIG QUALIFIZIERTER NAME"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Kontakte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "Unternehmen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPANY"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "Deals"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DEAL"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/tickets",
              children: "Tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TICKET"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes",
              children: "Angebote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUOTE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Integratorobjekte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um den Namen eines Integratorobjekts abzurufen, verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "CRM-Objektschema-API"
            }), ".Für Integratorobjekte, die den gleichen Namen wie die Standardobjekte haben, verwenden Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "vollständig qualifizierten Namen"
            }), " des Integratorobjekts."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Daten von einem einzelnen CRM-Datensatz mithilfe der crm_object-Funktion anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), "-Funktion, um einen einzelnen Datensatz aus dem CRM von HubSpot über eine Abfrage oder anhand der CRM-Datensatz-ID abzurufen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Objektdatensätze werden als ein Dictionary von Eigenschaften und Werten zurückgegeben."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom object by query #}\n{% set event = crm_object(\"event\", \"name=Defensive Health\") %}\n{{ event.name }}\n\n{# Render custom objects specifying the id of the object #}\n{% set event = crm_object(\"event\", 289236) %}\n{{ event.name }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>Defensive Heatlh</p>\n\n<p>Defensive Heatlh</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Wenn eine Abfrage eine Sammlung von Datensätzen zurückgibt, gibt die Funktion den ersten Datensatz in der Sammlung zurück."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Daten von mehreren CRM-Datensätzen mithilfe der crm_object-Funktion anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), "-Funktion, um CRM-Datensätze nach Objekttyp aus dem HubSpot CRM über eine Abfrage oder anhand der CRM-Datensatz-ID abzurufen. Datensätze werden als ein Dictionary von Eigenschaften und Werten zurückgegeben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der zurückgegebene Datensatz enthält eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), "-Eigenschaft, die in einer Schleife durchlaufen werden kann, um die Informationen in den Elementen des Datensatzes anzuzeigen."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom objects by query #}\n{% set events = crm_objects(\"event\", \"limit=3&type=virtual\") %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n\n{# Render custom objects by ids #}\n{% set events = crm_objects(\"event\", [289236,289237,289238]) %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  3 New Events:\n  <h3>\n    <ul>\n      <li>Name: Defensive Health</li>\n      <li>Name: Body Balance</li>\n      <li>Name: Happy Heart</li>\n      <ul>\n        <h3>\n          3 New Events:\n          <h3>\n            <ul>\n              <li>Name: Defensive Health</li>\n              <li>Name: Body Balance</li>\n              <li>Name: Happy Heart</li>\n              <ul></ul>\n            </ul>\n          </h3>\n        </h3>\n      </ul>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verknüpfte Datensätze anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die HubL-Funktion ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), ", um eine Liste der verknüpften Datensätze aus dem CRM von HubSpot abzurufen, die auf der angegebenen Datensatz-ID, der Verknüpfungskategorie und der ID der Verknüpfungsdefinition basiert.", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#getting-a-custom-object-s-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Datensätze werden als ein Dictionary von Eigenschaften und Werten zurückgegeben."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set associated_objects = crm_associations(289236, \"USER_DEFINED\", 3) %}\n<h3>Contacts Associated With Event</h3>\n<ul>\n{% for contact in associated_objects.results %}\n\t<li>Name: {{ contact.firstname }} {{ contact.lastname }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  Contacts Associated With Event\n  <h3>\n    <ul>\n      <li>Name: Brian Halligan</li>\n\n      <li>Name: Dharmesh Shah</li>\n\n      <li>Name: Yamini Rangan</li>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abrufen der Details eines benutzerdefinierten Objekttyps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fullyQualifiedName"
      }), ", Verknüpfungs-IDs und andere Details eines benutzerdefinierten Objekttyps abzurufen, können Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/crm/objects/custom-objects",
        children: "CRM-Objektschema-API"
      }), " vornehmen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), " für account-spezifische Objekttypen enthält die HubSpot-Account-ID. Sie sollten daher vermeiden, diesen zu verwenden, wenn Sie Ihren Code für mehrere HubSpot-Accounts entwickeln."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRM-Objektmodulfeld"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Content-Autoren die Möglichkeit zu geben, CRM-Datensätze zur Anzeige oder zum Ausführen von Logik auszuwählen, können Sie Module erstellen, die das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/en/docs/cms/building-blocks/module-theme-fields#crm-object",
        children: "CRM-Objektfeld"
      }), " enthalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So können Sie beispielsweise Informationen zu einem bestimmten Produkt, Kontakt, Unternehmen, Deal, Angebot, Ticket oder benutzerdefinierten Objekt anzeigen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/CRM%20Object%20Field.png",
        alt: "CRM-Objektfeld"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutorials und Ressourcen zu HubDB-Objekten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/essentials-for-getting-started-with-custom-objects",
          children: "Grundlagen für den Einstieg in benutzerdefinierte Objekte"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-think-like-an-architect-by-building-scalable-custom-objects",
          children: "Denken Sie wie ein Architekt: Erstellen Sie skalierbare benutzerdefinierte Objekte"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/crm-objects",
          children: "Dynamische Seiten mit CRM-Objekten erstellen"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}