import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import devLogger from 'react-utils/devLogger';
import { MOBILE_MENU_BREAKPOINT, NUM_GRID_BREAKPOINT_LARGE, viewportSizes } from '../utils/mediaQueries';
/**
 * Themes for the developer-docs-ui site, passed down via a styled-components
 * `ThemeProvider`.
 * @see https://docs.google.com/spreadsheets/d/1IrwN7zZQu7wvFUOUDUfO-9LP5GFAYAn9Z3gG0-XuTXs/edit#gid=0
 */
const themes = {
  PUBLIC: {
    themeName: 'PUBLIC',
    mobileBreakpoint: MOBILE_MENU_BREAKPOINT,
    belowLaptopBreakpoint: viewportSizes.laptop - 1
  },
  PORTAL_AUTHED: {
    themeName: 'PORTAL_AUTHED',
    mobileBreakpoint: NUM_GRID_BREAKPOINT_LARGE,
    belowLaptopBreakpoint: viewportSizes.laptop - 1
  }
};

/**
 * Accesses the ThemeProvider context value.
 *
 *  @example
 * ```tsx
 * const { isPublicTheme } = useDevDocsTheme();
 * ```
 */
export const useDevDocsTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    devLogger.warn({
      message: 'useDevDocsTheme must be used within ThemeProvider',
      key: null
    });
  }
  return Object.assign({}, context, {
    isPublicTheme: context && context.themeName === themes.PUBLIC.themeName
  });
};
export default themes;