"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358755;
const slug = exports.slug = 'guides/api/crm/objects/tickets';
const title = exports.title = 'API du CRM | Tickets';
const name = exports.name = 'vNext Docs DP - Tickets';
const metaDescription = exports.metaDescription = "Un ticket représente une demande de support ou d'aide d'un client. Les points de terminaison des tickets vous permettent de gérer ces données et de les synchroniser entre HubSpot et d'autres systèmes.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-des-tickets",
  "label": "Créer des tickets",
  "parent": null
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s",
  "label": "Propriétés",
  "parent": "cr%C3%A9er-des-tickets"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "cr%C3%A9er-des-tickets"
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-des-tickets",
  "label": "Récupérer des tickets",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-des-tickets",
  "label": "Mettre à jour des tickets",
  "parent": null
}, {
  "depth": 1,
  "id": "associer-des-tickets-existants-%C3%A0-des-fiches-d-informations-ou-activit%C3%A9s",
  "label": "Associer des tickets existants à des fiches d'informations ou activités",
  "parent": "mettre-%C3%A0-jour-des-tickets"
}, {
  "depth": 1,
  "id": "supprimer-une-association",
  "label": "Supprimer une association",
  "parent": "mettre-%C3%A0-jour-des-tickets"
}, {
  "depth": 0,
  "id": "%C3%A9pingler-une-activit%C3%A9-%C3%A0-un-ticket",
  "label": "Épingler une activité à un ticket",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-des-tickets",
  "label": "Supprimer des tickets",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans HubSpot, les tickets représentent des demandes d'aide des clients. Les tickets sont suivis tout au long de votre processus d'assistance avec des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/tickets/customize-ticket-pipelines-and-statuses",
        children: "statuts de pipeline"
      }), " jusqu'à leur fermeture. Les points de terminaison des tickets vous permettent de créer et de gérer des fiches d'informations de ticket ainsi que de synchroniser des données de ticket entre HubSpot et d'autres systèmes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les API d'objets, de fiches d'informations, de propriétés et d'associations dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "guide Comprendre le CRM"
      }), ". Pour plus d'informations générales sur les objets et les fiches d'informations dans HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "découvrez comment gérer votre base de données CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer des tickets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer de nouveaux tickets, effectuez une demande de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans votre demande, incluez les données de votre ticket dans un objet de propriétés. Vous pouvez également ajouter un objet d'association pour associer votre nouveau ticket à des fiches d'informations existantes (par exemple, des contacts ou des entreprises) ou à des activités (par exemple, des réunions ou des notes)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les détails du ticket sont stockés dans des propriétés de ticket. Il existe des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
        children: "propriétés de ticket HubSpot par défaut"
      }), ", mais vous pouvez également créer des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "propriétés personnalisées"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez un nouveau ticket, vous devez inclure les propriétés suivantes dans votre demande : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subject"
      }), " (le nom du ticket), ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pipeline_stage"
      }), " (le statut du ticket) et, si vous avez plusieurs pipelines, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pipeline"
      }), ". Si aucun pipeline n'est spécifié, le pipeline par défaut sera utilisé."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour afficher toutes les propriétés disponibles, vous pouvez récupérer une liste des propriétés de tickets de votre compte en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/tickets"
      }), ". Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API des propriétés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : Vous devez utiliser l'ID interne d'un statut de ticket ou d'un pipeline lors de la création d'un ticket via l'API. L'ID interne est un numéro, qui sera également retourné lorsque vous récupérerez des tickets via l'API. Vous pouvez trouver le statut de ticket ou l'ID interne d'un pipeline dans les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/customize-ticket-pipelines-and-statuses",
          children: "paramètres de votre pipeline de tickets."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour créer un nouveau ticket, votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"hs_pipeline\": \"0\",\n    \"hs_pipeline_stage\": \"1\",\n    \"hs_ticket_priority\": \"HIGH\",\n    \"subject\": \"troubleshoot report\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez un nouveau ticket, vous pouvez également associer le ticket à des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "fiches d'informations"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "activités"
      }), " existantes en incluant un objet d'associations. Par exemple, pour associer un nouveau ticket à un contact et à une entreprise existants, votre demande ressemblerait à ce qui suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"hs_pipeline\": \"0\",\n    \"hs_pipeline_stage\": \"1\",\n    \"hs_ticket_priority\": \"HIGH\",\n    \"subject\": \"troubleshoot report\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 201\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 16\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 26\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'objet d'association, vous devez ajouter les éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La fiche d'informations ou l'activité à laquelle vous souhaitez associer le ticket, en fonction de sa valeur unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'association entre le ticket et la fiche d'informations/l'activité. Inclure la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "et le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Les ID de types d'association par défaut sont répertoriés ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "ici"
            }), ", ou vous pouvez récupérer la valeur des types d'associations personnalisés (c'est-à-dire les libellés) via l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API des associations"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer des tickets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez récupérer des tickets individuellement ou par lots."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer un ticket individuel, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets/{ticketId}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour demander une liste de tous les tickets, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ces points de terminaison, vous pouvez inclure les paramètres suivants dans l'URL de la requête :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer dans la réponse. Si le ticket demandé n'a pas de valeur pour une propriété, il n'apparaîtra pas dans la réponse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés actuelles et historiques à renvoyer dans la réponse. Si le ticket demandé n'a pas de valeur pour une propriété, il n'apparaîtra pas dans la réponse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste séparée par des virgules des objets pour lesquels récupérer les ID associés. Les associations spécifiées qui n'existent pas ne seront pas renvoyées dans la réponse. Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API des associations."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer un lot de tickets spécifiques par ID de fiche d'informations ou selon une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "propriété d'identifiant unique personnalisée"
        }), ", effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/tickets/batch/read"
        }), ". Le point de terminaison du lot ", (0, _jsxRuntime.jsx)("u", {
          children: "ne peut pas"
        }), " récupérer les associations. Découvrez comment lire par lots les associations avec l'API ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "des associations"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour le point de terminaison de lecture par lot, vous pouvez également utiliser le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " facultatif pour récupérer les tickets en fonction d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propriété d'identifiant unique"
      }), " personnalisée. Par défaut, les valeurs d'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " dans la demande font référence à l'ID de fiche d'informations (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), de sorte que le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " n'est pas requis lors de la récupération par ID de fiche d'informations. Pour utiliser une propriété de valeur unique personnalisée pour récupérer des tickets, vous devez inclure le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour récupérer un lot de tickets, votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"subject\", \"hs_pipeline_stage\", \"hs_pipeline\"],\n  \"inputs\": [\n    {\n      \"id\": \"4444888856\"\n    },\n    {\n      \"id\": \"666699988\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"subject\", \"hs_pipeline_stage\", \"hs_pipeline\"],\n  \"idProperty\": \"uniquepropertyexample\",\n  \"inputs\": [\n    {\n      \"id\": \"abc\"\n    },\n    {\n      \"id\": \"def\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour récupérer des tickets avec des valeurs actuelles et historiques pour une propriété, votre demande pourrait ressembler à :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"hs_pipeline_stage\"],\n  \"inputs\": [\n    {\n      \"id\": \"4444888856\"\n    },\n    {\n      \"id\": \"666699988\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour des tickets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez mettre à jour des tickets individuellement ou par lots. Pour les tickets existants, l'ID de fiche d'informations est une valeur unique par défaut que vous pouvez utiliser pour mettre à jour le ticket via l'API, mais vous pouvez également identifier et mettre à jour les tickets à l'aide de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propriétés d'identifiant unique personnalisées."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour mettre à jour un ticket individuel avec son ID de fiche d'informations, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets/{ticketId}"
        }), " et incluez les données que vous souhaitez mettre à jour."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour mettre à jour plusieurs tickets, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " vers ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets/batch/update"
        }), ". Dans le corps de la requête, incluez un tableau avec les identifiants des tickets et les propriétés que vous souhaitez mettre à jour."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer des tickets existants à des fiches d'informations ou activités"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour associer un ticket à d'autres fiches d'informations de CRM ou à une activité, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets/{ticketId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour récupérer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), ", reportez-vous à ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "cette liste"
        }), " de valeurs par défaut ou effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API des associations."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer une association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une association entre un ticket et une fiche d'informations ou une activité, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à l'URL suivante : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets/{ticketId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Épingler une activité à un ticket"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez épingler une activité à une fiche d'informations d'un ticket via l'API en incluant le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " dans votre demande. Dans le champ, incluez l'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de l'activité à épingler, qui peut être récupéré via les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API d'engagement"
      }), ". Vous pouvez épingler une activité par fiche d'informations, et l'activité doit déjà être associée au ticket avant l'épinglage."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour définir ou mettre à jour l'activité épinglée d'un ticket, votre demande peut ressembler à :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/tickets/{ticketId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez également créer un ticket, l'associer à une activité existante et épingler l'activité dans la même demande. Par exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/tickets\n{\n  \"properties\": {\n    \"hs_pipeline\": \"0\",\n    \"hs_pipeline_stage\": \"1\",\n    \"hs_ticket_priority\": \"HIGH\",\n    \"subject\": \"troubleshoot report\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 227\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer des tickets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez supprimer des tickets individuellement ou par lots, ce qui placera le ticket dans la corbeille dans HubSpot. Vous pourrez ensuite ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "restaurer le ticket dans HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer un ticket avec son ID, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " vers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets/{ticketId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la suppression de tickets par lots, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}