"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31315810589;
const slug = exports.slug = 'guides/apps/marketplace/app-marketplace-listing-requirements';
const title = exports.title = 'アプリマーケットプレイス | アプリの掲載に必要な条件';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | App Listing Requirements | 202211';
const metaDescription = exports.metaDescription = 'アプリマーケットプレイスにアプリを掲載するために必要なHubSpotのガイドラインと条件を1か所で参照できるようになりました。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%9C%80%E4%BD%8E%E9%99%90%E3%81%AE%E6%9D%A1%E4%BB%B6",
  "label": "最低限の条件",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%81%AB%E5%BF%85%E8%A6%81%E3%81%AA%E6%9D%A1%E4%BB%B6",
  "label": "ブランドに必要な条件",
  "parent": "%E6%9C%80%E4%BD%8E%E9%99%90%E3%81%AE%E6%9D%A1%E4%BB%B6"
}, {
  "depth": 1,
  "id": "%E6%8E%B2%E8%BC%89%E3%81%AB%E5%BF%85%E8%A6%81%E3%81%AA%E6%9D%A1%E4%BB%B6",
  "label": "掲載に必要な条件",
  "parent": "%E6%9C%80%E4%BD%8E%E9%99%90%E3%81%AE%E6%9D%A1%E4%BB%B6"
}, {
  "depth": 0,
  "id": "%E5%AF%A9%E6%9F%BB%E3%80%81%E3%83%95%E3%82%A3%E3%83%BC%E3%83%89%E3%83%90%E3%83%83%E3%82%AF%E3%80%81%E6%89%BF%E8%AA%8D",
  "label": "審査、フィードバック、承認",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%8E%B2%E8%BC%89%E3%81%95%E3%82%8C%E3%81%9F%E3%82%A2%E3%83%97%E3%83%AA%E3%83%91%E3%83%BC%E3%83%88%E3%83%8A%E3%83%BC%E3%81%95%E3%81%BE%E3%81%AE%E7%89%B9%E5%85%B8",
  "label": "掲載されたアプリパートナーさまの特典",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "関連コンテンツ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "アプリマーケットプレイスへの掲載に必要な条件"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ご提出いただいたアプリ掲載情報はHubSpotエコシステム品質チームが手動で審査を行い、以下の条件を満たさない場合は却下とさせていただきます。アプリが以下の要件を満たしたら、アプリ開発者アカウント内で［アプリマーケットプレイス］>［掲載情報］>［掲載情報を作成］に移動して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "アプリの掲載情報を作成"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "最低限の条件"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["**一つのHubSpotアプリID：**アプリがAPIリクエストを許可するには、アプリの掲載情報に関連付けられている、HubSpotの公開アプリのアプリID（および", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "OAuthクライアントID"
          }), "）が必要です。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "掲載情報を別の公開アプリや非公開アプリにリダイレクトしないでください。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "掲載されている公開アプリが機能するのに、別の公開アプリや非公開アプリを使用する必要はありません。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "掲載されている公開アプリは、独自のものである必要があります。すでに掲載しているアプリを置き換える必要がある場合は、新しいアプリを掲載するのではなく、既存のアプリを更新する必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "同じユースケースを解決する複数のアプリを作成しないでください。類似する機能を持ち、同じAPIを使用する複数のアプリは、1つのアプリに統合するのが適切です。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["**OAuth：**アプリでは、OAuthを唯一の権限付与の方法として使用する必要があります。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "OAuthの利用"
          }), "をご確認ください。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["**インストール：**アプリには、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/certification-requirements#:~:text=Active%20installs%20are%20the%20number%20of%20unique%20HubSpot%20production%20accounts%2C%20unaffiliated%20with%20your%20organization%2C%20showing%20successful%20app%20activity%20within%20the%20past%2030%20days.",
            children: "アクティブなインストールが（ユニーク数で）"
          }), "少なくとも3つ必要です。この数が満たされていない場合、アプリ掲載情報を提出することはできません。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["**スコープ：**スコープをリクエストする際は、アプリに必要なスコープに限定してください。", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth#scopes",
            children: "スコープを確認"
          }), "して、不要なアクセス権限を求めていないことを確かめてください。この条件に準拠するアプリは、コンバージョン率が向上する傾向があります。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["アプリの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "高度なスコープ設定"
            }), "がオンになっている必要があります。エラーを防ぐには、すべての必須スコープ、条件付き必須スコープ、およびオプションのスコープが選択されている必要があります。この設定は、アプリを管理する", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/getting-started/account-types#app-developer-accounts",
              children: "開発者アカウント"
            }), "で確認できます。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "条件："
          }), (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/app-program-agreement",
            children: "HubSpotのアプリ パートナー プログラム契約"
          }), "の条件に同意いただく必要があります。同意していただくことで、貴社、HubSpot、および貴社とHubSpotの共通の顧客が保護されます。このステップを完了せずにアプリ掲載情報を提出いただくことはできません。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["**規制されている業界：**アプリは、HubSpotで", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/acceptable-use#Restricted-Industries",
            children: "規制されている業界"
          }), "内のいずれかの顧客向けの専用アプリとして適合していたり、そのような顧客にのみ機能を提供したりしてはなりません。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ブランドに必要な条件"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリとそれに関連付けられている資料（ドキュメントやランディングページなど）が", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/branding-guidelines",
          children: "HubSpotのブランドガイドライン"
        }), "を満たしている必要があります。例えば、HubSpotに言及するときはいつでも、「HubSpot」の「S」を大文字にします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリとそれに関連付けられている資料（ドキュメントやランディングページなど）が", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "HubSpotの商標使用ガイドライン"
        }), "（英語）を侵害しないものとします。例えば、ご自分のアプリ名やロゴに、HubSpotの名前（「Hub」と「HubSpot」を含む）を組み合わせることはできません。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "掲載に必要な条件"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "最低限の条件を満たしたら、アプリ掲載情報を提出することができます。アプリ掲載情報を提出する際は、全ての情報をもれなく正確に入力する必要があります。これらのフィールドは特に重要であり、これらの要件を満たさない場合、掲載情報は下書きモードにしか設定されません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["一般的な製品情報とは対照的に、掲載情報の内容は連携に固有のものでなければなりません。顧客がこの連携をダウンロードして使用することから期待できる、具体的な価値に関する情報を含める必要があります。好例としては、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/calling/aircall",
          children: "Aircall"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/sales-enablement/cloudfiles",
          children: "CloudFiles"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/partner-relationship-management/reveal-191193",
          children: "Reveal"
        }), "が挙げられます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot連携に固有の、一般公開されている（サインインや支払いが不要の）セットアップドキュメントへのリンク。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "セットアップガイドを単にホームページや一般的なナレッジベースにすることはできません。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "代わりに、連携をインストールして構成する手順を記載する必要があります。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["一例として、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://orgcharthub.com/guides/setup",
              children: "OrgChartHubセットアップガイド"
            }), "をご確認ください。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリをHubSpotと簡単に連携できるページに顧客を誘導する、関連するインストールボタンのURLを含めます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリのサポートリソース（サポートウェブ、HubSpotコミュニティーフォーラム、ケーススタディー）のURLは、有効で最新、かつ一般公開されている必要があります。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "貴社のアプリに関する利用規約およびプライバシーポリシーのURLは、有効かつ最新のものでなければなりません。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "全てのURLフィールドが250文字に制限されています。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリとHubSpotの間の情報の流れをユーザーに明示する「共有データ」は、正確かつ最新で、アプリが要求する", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements#:~:text=Webhooks%20API.-,Scopes,-%3A%20You%20must",
          children: "スコープ"
        }), "を反映したものでなければなりません。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "OAuthスコープで選択された全オブジェクトは、「共有データ」テーブルに文書化する必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アプリが読み取りと書き込み両方のオブジェクトスコープを要求する場合は、データ同期を、これらのオブジェクトの双方向の同期としてアドバタイズする必要があります。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリマーケットプレイス掲載情報に、明確な価格情報が記載されている必要があります。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubSpot連携に関連する、少なくとも1つの料金プランが、ウェブサイトに公開されている情報と一致していなければなりません。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "料金無料のプランは、無期限無料モデルまたはフリーミアム版の価格モデルにのみ使用してください。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "サポートへの連絡手段を少なくとも1つ記載する必要があります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリ掲載情報のテスト用ユーザー資格情報を提供するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/testing-credentials",
          children: "こちら"
        }), "に記載されているガイドラインに従ってください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "審査、フィードバック、承認"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["掲載情報を提出していただくと、HubSpotエコシステム品質チームが10営業日以内に初回審査を完了します。提供された情報に誤り、誤解を招く部分、または不備がある場合は、フィードバックをお送りいたします。アプリの審査およびフィードバックのプロセス全体にかかる期間は、フィードバックが共有されてから最大60日間です。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/app-program-agreement",
        children: "アプリマーケットプレイスに関する条件"
      }), "に記載のとおり、HubSpotはいつでもアプリ掲載情報の公開を取消または却下する権利を有するものとします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "掲載されたアプリパートナーさまの特典"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotのアプリマーケットプレイスでの専用ページの掲載"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "専用サポートエイリアスによる開発者サポートへの優先的なお問い合わせ"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ウェビナーやフォーラムなどの開発者コミュニティー向けリソースへのアクセス"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "PRテンプレートや開始ガイドなど、厳選されたマーケティングリソースへのアクセス"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "INBOUNDイベントのスポンサーシップ、ブース、チケットを特別価格で提供"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpot for Startupsシードステージプログラムを通じて特別価格でソフトウェアを提供"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "マーケティングの最新情報や製品リリースなどに関するニュースレターを毎月配信"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連コンテンツ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/listing-your-app",
          children: "アプリを掲載する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/certification-requirements",
          children: "アプリの認定に必要な条件"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "APIリファレンスドキュメント"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/overview",
          children: "HubSpot APIの使い方"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
          children: "開発者コミュニティーフォーラム"
        }), "（英語）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/contact-our-partnerships-team",
          children: "アプリ パートナー チームに問い合わせる"
        }), "（英語）"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}