'use es6';

import { Map as ImmutableMap } from 'immutable';
import { TryItActionTypes } from './actions';
import RequestStatus from '../../lib/models/RequestStatus';
const defaultState = new ImmutableMap({
  auth: {},
  authType: null,
  oAuthToken: null,
  responses: new ImmutableMap({})
});
export default function (state = defaultState, action) {
  switch (action.type) {
    case TryItActionTypes.SEND_REQUEST_STARTED:
      {
        // If this action contains authDetails in the payload, auth details have been updated
        if (action.payload.authDetails) {
          return state.merge(Object.assign({}, action.payload.authDetails, {
            auth: action.payload.auth,
            requestStatus: RequestStatus.pending
          })).setIn(['responses', action.payload.docId], new ImmutableMap({
            status: RequestStatus.pending
          }));
        }
        return state.setIn(['responses', action.payload.docId], new ImmutableMap({
          status: RequestStatus.pending
        }));
      }
    case TryItActionTypes.SEND_REQUEST_FINISHED:
      {
        return state.setIn(['responses', action.payload.docId], new ImmutableMap({
          status: RequestStatus.succeeded,
          response: action.payload.response
        }));
      }
    default:
      return state;
  }
}