"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42817127325;
const slug = exports.slug = 'guides/api/crm/imports';
const title = exports.title = 'API do CRM | Importações';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Imports';
const metaDescription = exports.metaDescription = 'As importações são usadas para preencher uma conta da HubSpot com dados de objeto que podem ser usados com as ferramentas de vendas, marketing e atendimento.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "iniciar-uma-importa%C3%A7%C3%A3o",
  "label": "Iniciar uma importação",
  "parent": null
}, {
  "depth": 1,
  "id": "formatar-os-dados-de-importrequest",
  "label": "Formatar os dados de importRequest",
  "parent": "iniciar-uma-importa%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "mapear-colunas-de-arquivo-para-propriedades-do-hubspot",
  "label": "Mapear colunas de arquivo para propriedades do HubSpot",
  "parent": "iniciar-uma-importa%C3%A7%C3%A3o"
}, {
  "depth": 0,
  "id": "importar-um-arquivo-com-um-objeto",
  "label": "Importar um arquivo com um objeto",
  "parent": null
}, {
  "depth": 0,
  "id": "importar-um-arquivo-com-v%C3%A1rios-objetos",
  "label": "Importar um arquivo com vários objetos",
  "parent": null
}, {
  "depth": 0,
  "id": "importar-v%C3%A1rios-arquivos",
  "label": "Importar vários arquivos",
  "parent": null
}, {
  "depth": 0,
  "id": "obter-importa%C3%A7%C3%B5es-anteriores",
  "label": "Obter importações anteriores",
  "parent": null
}, {
  "depth": 0,
  "id": "cancel-an-import",
  "label": "Cancel an import",
  "parent": null
}, {
  "depth": 0,
  "id": "view-and-troubleshoot-import-errors",
  "label": "View and troubleshoot import errors",
  "parent": null
}, {
  "depth": 0,
  "id": "limites",
  "label": "Limites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      img: "img",
      h3: "h3",
      strong: "strong",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Importações"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a API de importações para importar registros e atividades do CRM para sua conta da HubSpot, como contatos, empresas e observações. Depois da importação, você pode acessar e atualizar os registros e atividades por meio dos vários endpoints da API do CRM, incluindo as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "APIs de contato,"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "APIs de associações"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "APIs de envolvimento."
      }), " Você também pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/import-objects",
        children: "importar registros e atividades usando a ferramenta de importação guiada no HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Antes de iniciar a importação, saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file",
        children: "objetos e atividades que podem ser importados, bem como os requisitos de arquivo e propriedade."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Iniciar uma importação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode iniciar uma importação fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports"
      }), " com um corpo de solicitação que especifique como mapear as colunas do arquivo de importação para as propriedades do CRM associadas no HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As importações de API são enviadas como solicitações de tipo de dados de formulário, com o corpo de solicitação contendo os seguintes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-the-importrequest-data",
          children: "importRequest"
        }), ": um campo de texto que contém o JSON da solicitação."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#map-file-columns-to-hubspot-properties",
          children: "arquivos"
        }), ": um campo de arquivo que contém o arquivo de importação."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para o cabeçalho da solicitação, adicione um cabeçalho de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Content-Type"
      }), " com um valor de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A captura de tela a seguir mostra como a solicitação pode parecer ao usar um aplicativo como o Postman:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-import-request-no-response0.png",
        alt: "postman-import-request-no-response0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formatar os dados de importRequest"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No JSON da solicitação defina os detalhes do arquivo de importação, incluindo o mapeamento das colunas da planilha para os dados do HubSpot. O JSON da sua solicitação deve incluir os seguintes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome"
          }), ": o nome da importação. No HubSpot, esse é o nome ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/crm-setup/view-and-analyze-previous-imports",
            children: "exibido na ferramenta de importação"
          }), ", bem como o nome que você pode usar em outras ferramentas, como listas."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "importOperations"
          }), ": um campo opcional usado para indicar se a importação deve ", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/crm-setup/import-objects#choose-how-to-import",
            children: [(0, _jsxRuntime.jsx)("u", {
              children: "apenas"
            }), " criar e atualizar, ", (0, _jsxRuntime.jsx)("u", {
              children: "apenas"
            }), " criar ou ", (0, _jsxRuntime.jsx)("u", {
              children: "apenas"
            }), " atualizar registros"]
          }), " para um determinado objeto ou atividade. Inclua o ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "objectTypeId"
          }), " do objeto/atividade e se deseja apenas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPSERT"
          }), " (criar e atualizar), ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CREATE"
          }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPDATE"
          }), " os registros. Por exemplo, o campo ficaria assim na solicitação: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"importOperations\": {\"0-1\": \"CREATE\"}"
          }), ". Se você não incluir este campo, o valor padrão usado para a importação será ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPSERT"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "dateFormat"
          }), ": o formato para datas incluídas no arquivo. Por padrão, isso é definido para ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "MONTH_DAY_YEAR"
          }), ", mas você também pode usar ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "DAY_MONTH_YEAR"
          }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "YEAR_MONTH_DAY"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "marketableContactImport"
          }), ": o ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/contacts/marketing-contacts",
            children: "status de marketing"
          }), " dos contatos no arquivo de importação. Isso é usado apenas ao importar contatos para contas com ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/contacts/marketing-contacts#check-your-access-to-marketing-contacts",
            children: "acesso aos contatos de marketing"
          }), ". Para definir os contatos no arquivo como autorizados para marketing, use o valor ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), ". Para definir os contatos no arquivo como não autorizados para marketing, use o valor ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "createContactListFromImport"
          }), ": um campo opcional para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/crm-setup/import-objects#create-list-from-import",
            children: "criar uma lista estática dos contatos"
          }), " da sua importação. Para criar uma lista a partir do seu arquivo, use o valor ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "arquivos"
          }), ": uma matriz que contém as informações do arquivo de importação."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileName"
            }), ": o nome do arquivo de importação."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileFormat:"
            }), " o formato do arquivo de importação. Para arquivos CSV, use o valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), ". Para planilhas do Excel, use o valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SPREADSHEET"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileImportPage"
            }), ": contém a matriz ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), " necessária para mapear os dados do seu arquivo de importação para os dados do HubSpot. Saiba mais sobre o mapeamento de colunas abaixo."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mapear colunas de arquivo para propriedades do HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "columnMappings"
      }), ", inclua uma entrada para cada coluna no arquivo de importação, que corresponda à ordem da planilha. Para cada coluna, inclua os seguintes campos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnObjectTypeId:"
        }), " o nome ou valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), " do objeto ou atividade ao qual os dados pertencem. Consulte ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "este artigo"
        }), " para obter uma lista completa de valores ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnName:"
        }), " o nome do cabeçalho da coluna."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "propertyName:"
        }), " o nome interno da propriedade do HubSpot para a qual os dados serão mapeados. Para a coluna comum em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "importações de vários arquivos"
        }), ", o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyName"
        }), " deve ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " quando o campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toColumnObjectTypeId"
        }), " é usado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnType:"
        }), " usado para especificar que uma coluna contém uma ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file#:~:text=Name%2C%20Email%20Address",
          children: "propriedade de identificador exclusivo."
        }), ".-,Unique%20identifier,-%3A%20a%20property) Dependendo da propriedade e da meta da importação, use um dos seguintes valores:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "HUBSPOT_OBJECT_ID:"
            }), " o ID de um registro. Por exemplo, o arquivo de importação de contatos pode conter uma coluna de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "ID do registro"
            }), ", que armazena o ID da empresa à qual você deseja associar os contatos."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "HUBSPOT_ALTERNATE_ID:"
            }), " um identificador exclusivo que não seja o ID do registro. Por exemplo, o arquivo de importação de contatos pode conter uma coluna de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "E-mail"
            }), ", que armazena os endereços de e-mail dos contatos."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "FLEXIBLE_ASSOCIATION_LABEL"
            }), ": inclua este tipo de coluna para indicar que a coluna contém rótulos de associação."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "ASSOCIATION_KEYS"
            }), ": apenas para as importações de uma mesma associação de objeto, inclua este tipo de coluna para o identificador exclusivo dos mesmos registros de objeto que você está associando. Por exemplo, na solicitação de importação de uma associação de contatos, a coluna ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Contato associado [e-mail/ID de registo]"
            }), " deve ter o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnType"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASSOCIATION_KEYS"
            }), ". Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file#same-object-association-imports",
              children: "configurar o seu arquivo de importação para uma importação de mesma associação de objeto"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "toColumnObjectTypeId"
        }), ": para importações de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "vários arquivos"
        }), " ou de vários objetos, o nome ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), " do objeto a que pertence a propriedade de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file#sample-import-files:~:text=To%20import%20and%20associate%20multiple%20objects%20in%20one%20file%2C%20include%20information%20about%20associated%20records%20and/or%20activities%20in%20the%20same%20row.%20In%20two%20files%2C%20use%20a%20common%20column%20to%20connect%20the%20records%20in%20each%20file.%20You%20can%20refer%20to%20the%20example%20files%20for%20more%20help%20importing%20and%20associating%20records.",
          children: "coluna comum"
        }), " ou o rótulo de associação. Inclua esse campo para a propriedade de coluna comum no arquivo do objeto ao qual a propriedade ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " pertence. Por exemplo, se estiver associando contatos e empresas em dois arquivos com o ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-mail"
        }), " da propriedade do contato como a coluna comum, inclua ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toColumnObjectTypeId"
        }), " para a coluna ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-mail"
        }), " no arquivo da ", (0, _jsxRuntime.jsx)("u", {
          children: "empresa"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "foreignKeyType"
        }), ": apenas para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "importações de vários arquivos"
        }), ", o tipo de associação que a coluna comum deve usar, especificada pelo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationCategory"
        }), ". Inclua esse campo para a propriedade de coluna comum no arquivo do objeto ao qual a propriedade ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " pertence. Por exemplo, se estiver associando contatos e empresas em dois arquivos com o ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-mail"
        }), " da propriedade do contato como a coluna comum, inclua ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "foreignKeyType"
        }), " para a coluna ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-mail"
        }), " no arquivo da ", (0, _jsxRuntime.jsx)("u", {
          children: "empresa"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "associationIdentifierColumn"
        }), ": apenas para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "importações de vários arquivos"
        }), ", indica a propriedade usada na coluna comum para associar os registros. Inclua esse campo para a propriedade de coluna comum no arquivo do objeto ao qual a propriedade pertence. Por exemplo, se estiver associando contatos e empresas em dois arquivos com o ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-mail"
        }), " da propriedade do contato como a coluna comum, defina ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationIdentifierColumn"
        }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " para a coluna de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-mail"
        }), " no arquivo do ", (0, _jsxRuntime.jsx)("u", {
          children: "contato"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Importar um arquivo com um objeto"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja abaixo um exemplo do corpo de solicitação de importação de um arquivo para criar contatos:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"Nov-event-leads.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "# This example a local file named 'test_import.csv'\n# This file contains a list of contact records to import.\n\nimport requests\nimport json\nimport os\n\nurl = \"https://api.hubapi.com/crm/v3/imports\"\n\nYOUR_ACCESS_TOKEN = 'xxxxxxx';\n\n# Content-Type header will be set automatically by the requests library\nheaders = {\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\ndata = {\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"Nov-event-leads.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": True,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\n\ndatastring = json.dumps(data)\n\npayload = {\"importRequest\": datastring}\n\ncurrent_dir = os.path.dirname(__file__)\nrelative_path = \"./test_import.csv\"\n\nabsolute_file_path = os.path.join(current_dir, relative_path)\n\nfiles = [\n    ('files', open(absolute_file_path, 'rb'))\n]\nprint(files)\n\nresponse = requests.request(\"POST\", url, data=payload, files=files, headers=headers)\n\nprint(response.text.encode('utf8'))\nprint(response.status_code)\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "# Using this endpoint requires using sending multi-part form encoded data. Here is an example curl request:\n# importing a file named import_file.csv\n\n# create a variable for the importRequest JSON\nmyJSON=$(cat <<EOF\n{\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"import_file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\nEOF\n)\n\nYOUR_ACCESS_TOKEN=\"xxx-xxx-xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx\"\n\ncurl -v \\\n  -F \"files=@import_file.csv;type=text/csv\" \\\n  -F \"importRequest=$myJSON;type=application/json\" \\\n  -H \"Authorization: Bearer $YOUR_ACCESS_TOKEN\" \\\n  https://api.hubapi.com/crm/v3/imports\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Importar um arquivo com vários objetos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja abaixo um exemplo de solicitação de importação e associação de contatos e empresas em um único arquivo com rótulos de associação:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"Association Label Import\",\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"association label example.xlsx\",\n      \"fileFormat\": \"SPREADSHEET\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Domain\",\n            \"propertyName\": \"domain\"\n          },\n          {\n            \"columnName\": \"Association Label\",\n            \"columnType\": \"FLEXIBLE_ASSOCIATION_LABEL\",\n            \"columnObjectTypeId\": \"0-1\",\n            \"toColumnObjectTypeId\": \"0-2\"\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Importar vários arquivos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja abaixo um exemplo de corpo de solicitação de importação e associação de contatos e empresas em dois arquivos, onde o ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "E-mail"
      }), " da propriedade do contato é a coluna comum nos arquivos:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"Contact Company import\",\n  \"dateFormat\": \"YEAR_MONTH_DAY\",\n  \"files\": [\n    {\n      \"fileName\": \"contact-import-file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"associationIdentifierColumn\": true\n          }\n        ]\n      }\n    },\n    {\n      \"fileName\": \"company-import-file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Company name\",\n            \"propertyName\": \"name\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Company domain name\",\n            \"propertyName\": \"domain\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"toColumnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationTypeId\": 280,\n              \"associationCategory\": \"HUBSPOT_DEFINED\"\n            }\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em uma solicitação bem-sucedida, a resposta incluirá um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "importId"
      }), ", que poderá ser usado para recuperar ou cancelar a importação. Uma vez concluída, você poderá ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/import-and-export/view-and-analyze-previous-imports",
        children: "exibir a importação no HubSpot"
      }), ", mas as importações concluídas por meio da API não estarão disponíveis como uma opção ao filtrar registros por importação em exibições, listas, relatórios ou fluxos de trabalho."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obter importações anteriores"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar todas as importações da sua conta da HubSpot, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/"
      }), ". Para recuperar informações para uma importação específica, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao recuperar importações, as informações serão retornadas, incluindo o nome, a fonte, o formato de arquivo, o idioma, o formato de data e os mapeamentos de coluna da importação. O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "estado"
      }), " da importação também será retornado, que pode ser qualquer um dos seguintes:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INICIADO"
        }), ": o HubSpot reconhece que a importação existe, mas a importação ainda não começou a ser processada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSANDO"
        }), ": A importação está sendo processada ativamente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CONCLUÍDO"
        }), ": A importação está completa. Todos os objetos, atividades ou associações foram atualizados ou criados."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "FALHOU"
        }), ": Ocorreu um erro que não foi detectado quando a importação foi iniciada. A importação não foi concluída."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELADO"
        }), ": o usuário cancelou a exportação enquanto estava em qualquer um dos estados ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "INICIADO"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSANDO"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DIFERIDO"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DIFERIDO"
        }), ": O número máximo de importações (três) estão sendo processadas ao mesmo tempo. A importação começará assim que uma das outras importações terminar o processamento."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre a paginação e limitação de resultados na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpoints"
      }), ", na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": ao recuperar importações usando um token de acesso de aplicativo privado, a resposta incluirá apenas as importações realizadas por esse aplicativo privado. As importações realizadas no HubSpot ou por meio de outro aplicativo privado não serão retornadas."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cancel an import"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To cancel an active import, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}/cancel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View and troubleshoot import errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view errors for a specific import, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}/errors"
      }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/troubleshoot-import-errors",
        children: "common import errors and how to resolve them"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For errors such as ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Incorrect number of columns"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Unable to parse"
      }), " JSON or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "404 text/html is not accepted"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that there is a column header for each column in your file, and that the request body contains a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnMapping"
        }), " entry for each column. The following criteria should be met:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "The column order in the request body and import file should match. If the column order doesn't match, the system will attempt to automatically reorder but may be unsuccessful, resulting in an error when the import is started."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Every column needs to be mapped. If a column is not mapped, the import request may still be successful, but would result in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Incorrect number of columns"
            }), " error when the import is started."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that the file's name and the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fileName"
        }), " field in your request JSON match, and that you've included the file extension in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fileName"
        }), " field. For example, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "import_name.csv."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that your header includes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type"
        }), " with a value of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "multipart/form-data"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "Example POST URL:\nhttps://api.hubapi.com/crm/v3/imports?\n\nExample importRequest JSON data:\nThis example contains 3 columns:\n - First name, mapped to the firstname contact property\n - Email, mapped to the email contact property\n - Company ID, which contains a list of company record IDs\n   that the contact will be assocated with.\n{\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"ignored\": false,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": null,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "# This example a local file named 'final_emails.csv'\n# This file contains a list of contact records to import.\n# Each row in the file contains a column named \"Company ID\" that contains the companyId\n# that the contact should be associated with.\n\nimport requests\nimport json\n\npost_url = 'http://api.hubapi.com/crm/v3/imports?hapikey=4324...5432'\n\nimportRequest = {\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": True,\n        \"columnMappings\": [\n          {\n            \"ignored\": False,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": None,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": None,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          },\n          {\n            \"ignored\": False,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": None,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          },\n          {\n            \"ignored\": False,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": None,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          }\n        ]\n      }\n    }\n  ]\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "<?php\n// This example imports a local file named 'import_file.csv'\n\n    $post_url = \"http://api.hubapi.com/crm/v3/imports?hapikey=1234...5342\";\n\n    $csv_file = new CURLFile('import_file.csv','text/csv');\n\n    $config_json = '{\"name\":\"test_import\",\"files\":[{\"fileName\":\"final_emails.csv\",\"fileImportPage\":{\"hasHeader\":true,\"columnMappings\":[{\"ignored\":false,\"columnName\":\"First Name\",\"idColumnType\":null,\"propertyName\":\"firstname\",\"foreignKeyType\":null,\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false},{\"ignored\":false,\"columnName\":\"Email\",\"idColumnType\":\"HUBSPOT_ALTERNATE_ID\",\"propertyName\":\"email\",\"foreignKeyType\":null,\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false},{\"ignored\":false,\"columnName\":\"Company ID\",\"idColumnType\":\"HUBSPOT_OBJECT_ID\",\"propertyName\":null,\"foreignKeyType\":{\"associationCategory\":\"HUBSPOT_DEFINED\",\"associationTypeId\":1},\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false}]}}]};type=application/json';\n\n    $post_data = array(\n        \"files\" => $csv_file,\n        \"importRequest\" => $config_json\n    );\n\n    $ch = curl_init();\n    @curl_setopt($ch, CURLOPT_POST, true);\n    @curl_setopt($ch, CURLOPT_URL, $post_url);\n    @curl_setopt($ch, CURLOPT_POSTFIELDS, $post_data);\n\n    $response    = @curl_exec($ch); //Log the response from HubSpot as needed.\n    $status_code = @curl_getinfo($ch, CURLINFO_HTTP_CODE); //Log the response status code\n    @curl_close($ch);\n    echo $status_code . \" \" . $response;\n?>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "# Using this endpoint requires using sending multi-part form encoded data. Here is an example curl request:\n# importing a file named import_file.csv\n\n# create a variable for the importRequest JSON\nmyJSON=$(cat <<EOF\n{\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"ignored\": false,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": null,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          }\n        ]\n      }\n    }\n  ]\n}\nEOF\n)\n\ncurl -v \\\n  -F \"files=@import_file.csv;type=text/csv\" \\\n  -F \"importRequest=$myJSON;type=application/json\" \\\n  http://api.hubapi.com/crm/v3/imports?hapikey=4123...4321\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " se você receber um erro, verifique se há cabeçalhos duplicados, como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type"
        }), ". Isso pode ocorrer se você estiver usando o Postman ou se ele estiver incluído no cabeçalho do seu script Python. Remova o objeto duplicado antes de concluir a solicitação."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao usar a API de importações, você pode importar até 80.000.000 linhas por dia. No entanto, os arquivos de importação individuais são limitados a 1.048.576 linhas ou 512 MB, o que ocorrer primeiro."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se a solicitação exceder o limite de linhas ou de tamanho, o HubSpot emitirá um erro 429 HTTP. Ao se aproximar desses limites, é recomendável dividir sua importação em várias solicitações."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}