"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50631386761;
const slug = exports.slug = 'guides/cms/content/multi-language-content';
const title = exports.title = '多言語コンテンツ';
const name = exports.name = 'APAC JAPAN (ja) | [new] Multi-language_JA | 202108';
const metaDescription = exports.metaDescription = 'HubSpotでウェブサイトを構築する開発者は、多言語ウェブサイトを簡単にサポートできます。ビジネスの内容によっては、いくつかの開発作業が必要になる場合があります。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "hubspot%E3%81%AB%E3%82%88%E3%82%8B%E8%87%AA%E5%8B%95%E5%87%A6%E7%90%86",
  "label": "HubSpotによる自動処理",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot%E3%81%AB%E3%82%88%E3%81%A3%E3%81%A6%E5%87%A6%E7%90%86%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E9%83%A8%E5%88%86",
  "label": "HubSpotによって処理されない部分",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E5%A4%9A%E8%A8%80%E8%AA%9E%E7%94%A8%E3%81%AB%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "ウェブサイトを多言語用に設定する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%9F%BA%E6%9C%AChtml%E3%81%AE%E8%A8%80%E8%AA%9E%E8%A8%AD%E5%AE%9A",
  "label": "基本htmlの言語設定",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E5%A4%9A%E8%A8%80%E8%AA%9E%E7%94%A8%E3%81%AB%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E7%B7%A8%E9%9B%86%E5%8F%AF%E8%83%BD%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "ページ編集可能モジュールを使用する",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E5%A4%9A%E8%A8%80%E8%AA%9E%E7%94%A8%E3%81%AB%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%8A%E3%82%88%E3%81%B3%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E4%B8%AD%E3%81%AB%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E7%BF%BB%E8%A8%B3%E3%82%92%E5%90%AB%E3%82%81%E3%82%8B",
  "label": "カスタムモジュールおよびテーマの中にフィールド翻訳を含める",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E5%A4%9A%E8%A8%80%E8%AA%9E%E7%94%A8%E3%81%AB%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E9%96%8B%E7%99%BA",
  "label": "ローカル開発",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E5%A4%9A%E8%A8%80%E8%AA%9E%E7%94%A8%E3%81%AB%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC",
  "label": "デザインマネージャー",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E5%A4%9A%E8%A8%80%E8%AA%9E%E7%94%A8%E3%81%AB%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E8%A8%80%E8%AA%9E%E5%88%87%E3%82%8A%E6%9B%BF%E3%81%88%E6%A9%9F%E8%83%BD%E3%82%92%E5%90%AB%E3%82%81%E3%82%8B",
  "label": "言語切り替え機能を含める",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E5%A4%9A%E8%A8%80%E8%AA%9E%E7%94%A8%E3%81%AB%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E5%A4%9A%E8%A8%80%E8%AA%9E%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%B8%E3%81%AE%E6%A4%9C%E7%B4%A2%E3%81%AE%E5%AE%9F%E8%A3%85",
  "label": "多言語ウェブサイトへの検索の実装",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E5%A4%9A%E8%A8%80%E8%AA%9E%E7%94%A8%E3%81%AB%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%81%A8%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "グローバルパーシャルとモジュールを使用する",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E5%A4%9A%E8%A8%80%E8%AA%9E%E7%94%A8%E3%81%AB%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      strong: "strong",
      h3: "h3",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "多言語コンテンツ"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["複数の地域にわたるビジネス、または複数の言語を使用する顧客とのビジネスに取り組む企業は、相手の言語で関係を構築できなければなりません。HubSpotのCMSでは、コンテンツの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/website-pages/create-pages-in-multiple-languages",
        children: "各国語版"
      }), "を作成できるので、ユーザーは最適な言語でコンテンツを表示できるようになります。HubSpotでは多言語ウェブサイトの多くの機能が自動設定されますが、適切な多言語対応のためには開発者が実施しなければならない作業も少なくありません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotによる自動処理"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot上でページの多言語バリエーションが作成されると、次の処理が自動的に行われます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "XMLサイトマップに、翻訳されるページの名前とURLを示す新しい項目が作成されます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ドラッグ＆ドロップ機能を使用して作成したテンプレートのページ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), "の中に、コンテンツの言語が指定されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["多言語コンテンツグループ内の適切な標準化書式に準拠した他のページが代替ページとして識別されて、重複コンテンツエラーが回避されます。さらに言語翻訳に関連する", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes",
          children: "ISO 639-1"
        }), "コードが特定されます。", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "<link rel=\"alternate\" hreflang=\""
          })
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "[**lang_code**](https://support.google.com/webmasters/answer/189077#language-codes)\" href=\"url_of_page\" />"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "多言語ページ上のリンクが、同じ言語のリンク先ページにつながるように修正されます。これは、同じ言語で閲覧を続ける訪問者にとって役立つだけでなく、ページ翻訳の際にリンクを1つずつ更新する必要がなくなります。特定の要素では、クラスhs-skip-lang-url-requiredを要素に追加することにより、この修正を無効にすることができます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotによって処理されない部分"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次の事項はHubSpotのCMSによって自動的には処理されません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページのコンテンツを翻訳する。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "GeoIPに基づいてユーザーを多言語版に自動的に振り向ける。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ヘッダーまたはウェブサイトに言語切り替えモジュールを含める。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コードファイルのページの言語を指定する。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コードファイルについて、左から右ではなく、右から左に読む言語の翻訳についてコンテンツの方向属性を設定する。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ウェブサイトを多言語用に設定する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "基本htmlの言語設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["言語宣言やコンテンツの言語方向属性はコードファイルに自動的には含められないため、コードテンプレートに手動で設定する必要があります。言語変数はHTMLの中で設定するか、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L2",
        children: "CMSボイラープレートテンプレート"
      }), "の中など、HubL経由で設定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubLを使用してこれらのプロパティーを設定すると、HubSpotのCMS内のページの言語設定に基づいて、ページのHTML内にデータを動的に反映させることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ページ編集可能モジュールを使用する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートを使用したインスタンスのコンテンツをローカライズするには、可能な限り、ハードコードされたHTMLコンテンツではなく", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "を活用してください。ページ側で編集できるモジュールを作成することにより、制作担当者は、テンプレートコードを調整しなくても各ページに表示される特定のコンテンツを設定できます。テンプレートを共有するページの間で一貫したコンテンツを使用することも可能になります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタムモジュールおよびテーマの中にフィールド翻訳を含める"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "グローバルチームに対応するために、HubSpot上に作成したモジュールの翻訳を公開できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュール内のコンテンツを翻訳し、チームメンバーの言語で公開した後、ユーザーには、モジュールのフィールドラベルがアカウントの既定の言語で表示されます。翻訳対象のモジュールのコンテンツは自動的には翻訳されないため、自分で翻訳する必要があります。モジュールの翻訳は、サポートされているどの言語でも作成できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["翻訳は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#local-module-file-structure",
        children: "ローカル開発ツール"
      }), "を使用して、またはデザインマネージャーを通じて設定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ローカル開発"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカル開発ツールを使用して翻訳を設定するには、各モジュールフォルダーおよび各テーマフォルダーに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_locales"
      }), "フォルダーを、さらに言語ロケールのサブフォルダーを格納し、それぞれにモジュールフィールドの翻訳を含めた", (0, _jsxRuntime.jsx)(_components.code, {
        children: "messages.json"
      }), "ファイルを配置できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/local%20module%20translations.png",
        alt: "VS Codeのローカル環境でモジュール翻訳を編集する作業のスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "デザインマネージャー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "デザインマネージャー"
      }), "を通じてフィールド翻訳を設定するには、画面右側から［翻訳を追加］オプションに進みます。ドロップダウンメニューからチームが使用する言語を選択します。ここから、各言語を選択し、言語別に各フィールドのラベル表記を設定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/field_translations.gif",
        alt: "デザインマネージャーのフィールド翻訳のスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["テーマフィールドの翻訳には、デザインマネージャー上にインターフェイスがないため、", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".json"
        }), "ファイルを使用して編集する必要があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "言語切り替え機能を含める"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "使用可能な翻訳をエンドユーザーが切り替えることができるように、ウェブサイトへの言語切り替えモジュールの追加を検討してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/09ad0b2c3bbc8400e64c5617268cb0504392e8e5/src/templates/partials/header.html#L26-L47",
        children: "言語切り替え機能を実装する方法の例"
      }), "については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "CMSテーマボイラープレート"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Header navigation row one #}\n      <div class=\"header__row-1\">\n        {% if content.translated_content.values()|selectattr('published')|length || is_listing_view && group.translations %}\n          <div class=\"header__language-switcher header--element\">\n            <div class=\"header__language-switcher--label\">\n              {% module 'language-switcher' path='@hubspot/language_switcher',\n                label='Language switcher',\n                display_mode='localized'\n              %}\n              <div class=\"header__language-switcher--label-current\"> {{ locale_name(locale) }}</div>\n            </div>\n          </div>\n        {% endif %}\n        <div class=\"header__search header--element\">\n          {% module 'site_search' path='@hubspot/search_input',\n            label='Search',\n            field_label='Search',\n            placeholder=''\n          %}\n        </div>\n      </div>\n      {# End header navigation row one #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "多言語ウェブサイトへの検索の実装"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "コンテンツ検索"
      }), "は、貴社のウェブサイト上のさまざまな言語によるコンテンツに対応しています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "/contentsearch/v2/search"
      }), "（英語）の利用時に言語フィルターパラメーターを使用することで、指定した言語のみが返されます。これにより、ウェブサイト上で各言語での検索や、複数言語での横断検索を実現できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "グローバルパーシャルとモジュールを使用する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ヘッダー、フッター、サイドバーのテキストを編集可能にするには、モジュールフィールドを使用します。これらのモジュールをグローバルパーシャルの中に配置します。制作担当者にとっては編集が簡単になるだけでなく、グローバルパーシャルによって", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/website-pages/create-pages-in-multiple-languages",
        children: "言語ごとの設定"
      }), "がサポートされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/edit-multilanguage-global-content.png",
        alt: "ページエディターにヘッダーパーシャルが表示されているスクリーンショット"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}