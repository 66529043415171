"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831376;
const slug = exports.slug = 'guides/cms/content/accessibility';
const title = exports.title = 'Barrierefreiheit';
const name = exports.name = 'EMEA DACH (DE) Accessibility';
const metaDescription = exports.metaDescription = 'HubSpot ist bestrebt, sicherzustellen, dass wir die erforderlichen Tools bereitgestellt haben, um Ihnen zu helfen, Ihre Inhalte barrierefrei zu machen. Auch wenn es sich nicht um einen endgültigen Leitfaden handelt, sind hier einige Schritte, mit denen Sie Ihre Inhalte barrierefrei machen können.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "barrierefreiheit-ist-kein-feature",
  "label": "Barrierefreiheit ist kein Feature",
  "parent": null
}, {
  "depth": 0,
  "id": "textalternativen-f%C3%BCr-alle-nicht-text-inhalte-bereitstellen",
  "label": "Textalternativen für alle Nicht-Text-Inhalte bereitstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "a11y-freundliche-lazy-loading-l%C3%B6sungen-verwenden",
  "label": "A11Y-freundliche Lazy-Loading-Lösungen verwenden",
  "parent": "textalternativen-f%C3%BCr-alle-nicht-text-inhalte-bereitstellen"
}, {
  "depth": 0,
  "id": "stellen-sie-sicher%2C-dass-alle-links-und-formulareingaben-beschreibenden-text-haben",
  "label": "Stellen Sie sicher, dass alle Links und Formulareingaben beschreibenden Text haben",
  "parent": null
}, {
  "depth": 0,
  "id": "%E2%80%9Ezum-inhalt-springen%E2%80%9C-link-hinzuf%C3%BCgen",
  "label": "„Zum Inhalt springen“-Link hinzufügen",
  "parent": null
}, {
  "depth": 0,
  "id": "semantisches-markup-verwenden",
  "label": "Semantisches Markup verwenden",
  "parent": null
}, {
  "depth": 0,
  "id": "tastaturnavigation",
  "label": "Tastaturnavigation",
  "parent": null
}, {
  "depth": 0,
  "id": "falls-erforderlich%2C-r%C3%BCckgriff-auf-rollen",
  "label": "Falls erforderlich, Rückgriff auf Rollen",
  "parent": null
}, {
  "depth": 0,
  "id": "zus%C3%A4tzliche-designtipps",
  "label": "Zusätzliche Designtipps",
  "parent": null
}, {
  "depth": 0,
  "id": "verfassen-von-content",
  "label": "Verfassen von Content",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-spezifische-empfehlungen",
  "label": "HubSpot-spezifische Empfehlungen",
  "parent": null
}, {
  "depth": 1,
  "id": "verwenden-sie-benutzerdefinierte-module-und-vorlagen%2C-die-bew%C3%A4hrten-a11y-methoden-folgen",
  "label": "Verwenden Sie benutzerdefinierte Module und Vorlagen, die bewährten a11y-Methoden folgen",
  "parent": "hubspot-spezifische-empfehlungen"
}, {
  "depth": 0,
  "id": "vorsicht-vor-l%C3%B6sungen-aus-einer-hand",
  "label": "Vorsicht vor Lösungen aus einer Hand",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-entwickler-podcast",
  "label": "HubSpot-Entwickler-Podcast",
  "parent": null
}, {
  "depth": 0,
  "id": "weitere-informationen-zur-barrierefreiheit",
  "label": "Weitere Informationen zur Barrierefreiheit",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      code: "code",
      pre: "pre",
      h3: "h3",
      ul: "ul",
      li: "li",
      ol: "ol",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      IFrame
    } = _components;
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Barrierefreiheit"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da Websites von immer größerer Bedeutung für Unternehmen sind, ist es wichtiger denn je, sicherzustellen, dass Inhalte auf Websites für alle Besucher barrierefrei sind. In den Vereinigten Staaten ist wird dies häufig als Section 508 Compliance bezeichnet, also die Einhaltung von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www.section508.gov/",
        children: "Abschnitt 508"
      }), ". Dies bezieht sich auf den Abschnitt des Reauthorized Rehabilitation Act von 1998, der Bundesbehörden verpflichtet, elektronische und Informationstechnologien für Menschen mit Behinderungen zugänglich zu machen. Während die Einhaltung von Abschnitt 508 eine gute Grundlage darstellt, werden zunehmend die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility/Understanding_WCAG",
        children: "Web Content Accessibility Guidelines (WCAG)"
      }), " als Standard für die Erstellung von barrierefreien Inhalten im Web herangezogen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Barrierefreiheit ist kein Feature"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der rechtliche Aspekt sollte nicht Ihr Motivator sein, um Ihren Benutzern ein gutes Erlebnis zu bieten. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.cdc.gov/ncbddd/disabilityandhealth/infographic-disability-impacts-all.html",
        children: "Jeder vierte Amerikaner hat laut CDC eine Behinderung"
      }), ". Die Entscheidung, 25 % der Besucher keine benutzerfreundliche Umgebung zu bieten, ist so, als würde ein Ladengeschäft jedem vierten Besucher den Eintritt verwehren. Diese Kunden sind dann nicht nur unzufrieden, sie werden wahrscheinlich nicht zurückkommen oder Ihre Dienstleistungen empfehlen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein gängiger Satz unter Entwicklern ist, dass „Barrierefreiheit kein Feature ist“. Dahinter steht die Vorstellung, dass Barrierefreiheit nicht wie eine Bonus-Sache zu behandeln, oder etwas, mit dem man sich am Ende eines Projekts beschäftigt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Barrierefreiheit erfordert Nachdenken, Einfühlungsvermögen und Verständnis. Wenn man dies in einem Projekt frühzeitig mitdenkt, kann man Lösungen entwerfen und entwickeln, anstatt später etwas neu zu entwerfen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Oftmals werden durch die Verbesserung der Barrierefreiheit auch andere Prioritäten des Entwicklers umgesetzt"
      }), ": Nutzererlebnis, Performance und SEO sind einige der wichtigsten Anliegen, die direkt mit der Barrierefreiheit zusammenhängen. Die Verbesserung des einen sorgt häufig auch für eine Verbesserung des anderen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Einfühlungsvermögen ist ein wichtiger Baustein von Barrierefreiheit, der leicht zu übersehen ist. Als Entwickler wollen wir alles automatisieren, wenn etwas schwierig oder zeitaufwendig ist, wollen wir Tools, die es entweder für uns tun oder uns sagen, wie wir es richtig machen. Leider können diese Tools nur an der Oberfläche kratzen, denn sie sind nicht menschlich, sie können die Seite nicht wirklich so erleben wie ein Mensch. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Es ist technisch möglich, eine Webseite zu erstellen, die die meisten automatischen Barrierefreiheitstests mit Bravour besteht, aber für alle Menschen, ob sehend, blind, taub, farbenblind, mit oder ohne Einschränkung von motorischen Funktionen, völlig unbrauchbar ist."
      }), " Sie können die Anforderungen technisch erfüllen, aber ein unbrauchbares, frustrierendes Erlebnis bieten, das die Menschen völlig abschreckt. Das Fazit ist: Verlassen Sie sich nicht auf die Tools, testen Sie Ihre Arbeit, fühlen Sie sich in Ihre Benutzer ein, holen Sie Feedback ein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es handelt sich zwar nicht um einen endgültigen Leitfaden, aber hier finden Sie einige Schritte, mit denen Sie Ihre Inhalte barrierefrei machen können, sowie Ressourcen für eine vertiefte Beschäftigung mit dem Thema."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Textalternativen für alle Nicht-Text-Inhalte bereitstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle Bilder, Symbole, Videos und Schaltflächen, die Bedeutung vermitteln oder interaktiv sind, sollten eine Textalternative haben. Dies ist nicht nur gut für Besucher, die Ihre Inhalte über ein Bildschirmleseprogramm konsumieren, sondern auch für Suchmaschinen. Der ALT-Text sollte den Inhalt des Bildes beschreiben. Die meisten Bilder auf Ihrer Website können wahrscheinlich im Seiten-Editor bearbeitet werden, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cos-pages-editor/how-do-i-add-alt-text-to-my-image",
        children: "das Festlegen von ALT-Text im Seiten-Editor ist einfach"
      }), ". Innerhalb von benutzerdefinierten Modulen und Vorlagen müssen Sie jedoch sicherstellen, dass der in Seiten-Editoren angegebene ALT-Text beachtet wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies bedeutet, dass Sie bei einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<img>"
      }), "-Element sicherstellen müssen, dass der vom Benutzer angegebene ALT-Text auch tatsächlich verwendet wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" />\n\nBad:\n<img src=\"{{ module.image.src }}\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie aus irgendeinem Grund ein Bild im Seiten-Editor nicht bearbeiten können, sollten Sie den ALT-Text hart kodieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt eine Ausnahme von dieser ALT-Textregel. Wenn Ihr Bild keinen zusätzlichen Kontext bietet und rein repräsentativ ist, ist es besser, einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.w3.org/WAI/tutorials/images/tips/",
        children: "ALT-Textwert von „Null“"
      }), " zu haben, als ganz auf ein ALT-Attribut zu verzichten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good if only presentational: <img src=\"{{ module.image.src }}\" alt=\"\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie für Videos einen Dienst, der Bildbeschriftungen unterstützt, und erwägen Sie, ein Transkript hinzuzufügen. Einige Dienste, die das Hochladen von Transkripten unterstützen, sind ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.vidyard.com/hc/en-us/articles/360009876234-Make-your-Vidyard-players-accessible",
        children: "Vidyard"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://support.google.com/youtube/answer/2734799",
        children: "YouTube"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://help.vimeo.com/hc/en-us/articles/224968828-Captions-and-subtitles",
        children: "Vimeo"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://wistia.com/support/player/captions",
        children: "Wistia"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "A11Y-freundliche Lazy-Loading-Lösungen verwenden"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lazy Loading von Bildern ist eine gängige Technik zur Performance-Steigerung beim Erstellen von Websites. Die Methode, die tatsächlich für das Lazy Loading verwendet wird, spielt für die Barrierefreiheit eine Rolle."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScript-Lösungen hierfür verlassen sich in der Regel darauf, dass das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), "-Attribut von Bildern etwas Unwahres ist (wie eine kleine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".gif"
      }), "-Datei) anstelle der wahren Quelle des Bildes, die in einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-src"
      }), "-Attribut enthalten ist, bis der Benutzer das Bild in der Nähe der Ansicht scrollt. Dies bedeutet, dass das Bild zunächst für den Benutzer eines Bildschirmleseprogramms, der über die Seite navigiert, nicht barrierefrei ist, insbesondere wenn er mit einem Rotor den gesamten Inhalt der Seite durchsucht, ohne überhaupt zu scrollen. Wenn JavaScript auf einer Seite nicht geladen ist, schlagen diese Lösungen für Lazy Loading-Lösungen außerdem fehl, sodass Benutzer von Hilfstechnologien nicht die richtigen Bilder auf der Seite sehen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Durch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/native-lazy-loading/",
        children: "natives Lazy Loading von Bildern"
      }), " wird dieses Problem vermieden und dabei das Bild-Markup beibehalten, unabhängig davon, ob JavaScript geladen wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img\n  src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n  loading=\"lazy\"\n  height=\"200px\"\n  width=\"400px\"\n/>\n\nBad:\n<img\n  src=\"1px.gif\"\n  data-src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n/>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot unterstützt ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/lazy-loading",
        children: "browserbasiertes Lazy Loading"
      }), " in benutzerdefinierten Modulen. Es wird empfohlen, es zu verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Stellen Sie sicher, dass alle Links und Formulareingaben beschreibenden Text haben"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Links, Schaltflächen und Formularelemente müssen alle über einen tatsächlichen Text verfügen, der angibt, was sie tun oder wohin sie weiterleiten. Andernfalls lesen Bildschirmleseprogramme heraus, dass der Benutzer einen Link oder eine Schaltfläche ausgewählt hat, aber keine Ahnung hat, was er tut."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie ein Symbol verwenden, geben Sie den Text des Bildschirmleseprogramms ein. Einige Standard HubSpot-Vorlagen verwenden beispielsweise einen Symbol-Kurzcode:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"https://www.facebook.com\"\n  ><i class=\"fab fa-facebook-f\"></i\n  ><span class=\"screen-reader-text\">Facebook</span></a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie nicht ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'display: none'"
      }), ", um Formularlabels und anderen beschreibenden Text auszublenden. Dadurch wird verhindert, dass der Text für Sehbehinderte laut vorgelesen wird. Außerdem benachteiligt es Benutzer, die zwar sehen können, aber den Text aufgrund des fehlenden Kontrasts des Platzhaltertextes nicht erkennen können. Oder denken Sie an folgende Situation: Sie haben ein Formular noch nicht komplett ausgefüllt oder ein Formular wurde automatisch ausgefüllt, wissen aber nicht sicher, ob es korrekt eingegeben wurde, weil die Formularfeldlabels unsichtbar sind."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie unbedingt ausgeblendeten Text haben müssen oder ausgeblendeter Text zusätzlichen Kontext bieten könnte, den ein ohne Einschränkung sehender Benutzer nicht benötigt, verwenden Sie CSS, das den Text unsichtbar macht, ohne ihn vor Bildschirmleseprogrammen zu verbergen, oder fügen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\".screen-reader-text\""
      }), "-Klasse hinzu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".screen-reader-text {\n  clip: rect(1px, 1px, 1px, 1px);\n  height: 1px;\n  overflow: hidden;\n  position: absolute !important;\n  width: 1px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "„Zum Inhalt springen“-Link hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn ein Besucher mit einem Bildschirmleseprogramm oder einfach nur mit seiner Tastatur navigiert, ist es äußerst hilfreich, wenn es eine Möglichkeit gibt, die Teile der Seite zu überspringen, die wiederholt werden, z. B. ein Header. Wir können dies tun, indem wir einen Link hinzufügen, der auf den Hauptinhalt der Seite verweist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fügen Sie in Ihrem Header HTML mit dem folgenden Inhalt hinzu:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"#content\" class=\"screen-reader-text\"\n  >Five ways to make your site more accessible</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Stellen Sie dann in jeder Vorlage sicher, dass ein Element mit einem ID-Attribut des Inhalts vorhanden ist. In unserem Beispiel haben wir den Artikeltitel als den Text verwendet, zu dem gesprungen werden soll. Dadurch können Suchmaschinen nachvollziehen, zu was verlinkt wird. Es ist eine semantischere Art, zum Inhalt zu springen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<main id=\"content\">\n  <!-- your page or post's actual content -->\n</main>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Semantisches Markup verwenden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Semantisches Markup ist HTML, das Bedeutung vermittelt. Einige Beispiele für semantische Elemente sind ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<time>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<code>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<aside>"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<article>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Einige Beispiele für Elemente, die nicht semantisch sind, sind ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span>"
      }), ". Nicht semantische Elemente haben immer noch ihren Platz, sollten aber in erster Linie zur Darstellung und nicht zur Vermittlung von Bedeutung verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Semantische Elemente können von Suchmaschinen und Hilfstechnologien verstanden werden. Beides wirkt sich positiv auf Ihre SEO aus und verbessert Ihr Benutzererlebnis."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie keine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), "-Elemente als interaktiven Elemente wie Schaltflächen, Registerkarten oder Links, es sei denn, Sie sind sicher, dass Sie über Aria-Attribute ein gutes Erlebnis bereitgestellt haben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die Links (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<a />"
      }), ") und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<button />"
      }), " in angemessener Weise."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie Links, um zu Abschnitten einer Seite zu verlinken und zu anderen Seiten zu navigieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie Schaltflächen für Interaktionen, die nicht dazu führen, dass die Seite verlassen oder zu einer ID navigiert wird."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei der Arbeit mit Tabellen gilt: wenn eine Tabelle einen beschreibenden Titel hat, fügen Sie ihn in ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<caption>"
      }), "-Element direkt nach dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<table>"
      }), "-Element ein."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), "-Elemente mit dem richtigen Bereichsattribut in Tabellen für Spalten- und Zeilenüberschriften und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<thead>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tbody>"
      }), " sowie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tfoot>"
      }), ", um Abschnitte zu bezeichnen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie das Bereichsattribut für ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), "-Attribute, um anzugeben, ob die Überschrift für die Zeile oder die Spalte gilt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Benötigen Sie ein Accordion? Vergessen Sie nicht die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<details>"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<summary>"
      }), "-Elemente, die Ihnen die meisten dieser Funktionen kostenlos zur Verfügung stellen, und sie sind barrierefrei. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://caniuse.com/#feat=details",
        children: "Achten Sie darauf, ein Polyfill oder Fallback hinzuzufügen, wenn Sie alte Browser unterstützen müssen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tastaturnavigation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Einige Benutzer ziehen es vor, mithilfe ihrer Tastatur durch Webseiten und Formulare zu navigieren. Einige Besucher müssen die Tastatur oder eine Art Hilfsgerät verwenden, das eine Tastatur emuliert, um Websites zu navigieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stellen Sie sicher, dass ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_focus.asp",
          children: "':focus'"
        }), " so formatiert ist, dass ein Benutzer, wenn er über die Tastatur navigiert, sehen kann, was fokussiert ist. Deaktivieren Sie die CSS-Umrisseigenschaft nur, wenn Sie eine akzeptable alternative visuelle Anzeige bereitstellen. Verwenden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/almanac/selectors/f/focus-within/",
          children: ":focus-win"
        }), ", wenn sich damit besser die Aufmerksamkeit der Benutzer sinnvoll lenken lässt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie Teile der Seite mit ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_hover.asp",
          children: "':hover'"
        }), " oder über Javascript ausblenden/anzeigen, stellen Sie sicher, dass Benutzer diese Steuerelemente auch über Javascript navigieren können oder dass es einen alternativen Pfad zu den Informationen gibt."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Stellen Sie sicher, dass die Hauptnavigation Ihrer Website die Tastaturnavigation unterstützt. Ein häufig übersehenes Problem ist, dass Dropdown-Menüs und Fly-outs nicht barrierefrei gemacht werden. Dies verhindert, dass Benutzer zu Teilen von Websites gelangen, die möglicherweise kritisch sind."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stellen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hidden"
        }), "-, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-expanded"
        }), "-, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-current"
        }), "-, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-selected"
        }), " und andere Statusattribute nach Bedarf bereit bzw. aktualisieren Sie diese, um sicherzustellen, dass Bildschirmleseprogramme den Status der Elemente ordnungsgemäß kommunizieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Falls erforderlich, Rückgriff auf Rollen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Vorlagen oder Code-Dateien erstellen, sollten Sie korrekte semantische Elemente (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dialog>"
      }), " usw.) verwenden, um Webbrowsern und Bildschirmleseprogrammen mitzuteilen, welche Art von Inhalten sich in Ihren Elementen befindet."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für den Fall, dass semantische Elemente nicht für Ihr Szenario geeignet sind, sollten Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles",
        children: "Rollenattribute"
      }), " hinzufügen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Hinzufügen von Orientierungspunkten erleichtert es Benutzern von Bildschirmleseprogrammen, zwischen den Hauptabschnitten einer Webseite hin- und herzuspringen. HubSpot berücksichtigt 'role'-Attribute für das Menü, das einfache Menü und die Google-Suchmodule."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zusätzliche Designtipps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Stellen Sie sicher, dass der Inhalt des Browsers sichtbar und lesbar bleibt, wenn ein Benutzer ihn auf 200 % vergrößert."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Es wird nicht empfohlen, Schriften viel kleiner als 16 Px zu machen, da sie dann für Besucher möglicherweise schwer zu lesen sind."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vermeiden Sie die Verwendung von Farbe als einzige Möglichkeit der Kommunikation von Informationen. Ein überraschend hoher Prozentsatz der Weltbevölkerung ist farbenblind."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Stellen Sie sicher, dass zwischen der Farbe des Textes und dem Hintergrund ein ausreichender Kontrast besteht, damit Benutzer mit eingeschränkter Sicht den Inhalt lesen können."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vermeiden Sie Animationen, die schnell blinken (mehr als dreimal pro Sekunde), da sie bei einigen Besuchern Anfälle auslösen können."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Unterstützen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/introduction-reduced-motion-media-query/",
          children: "preferences-reduced-motion"
        }), ", wenn Sie Animationen auf Ihrer Website haben."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Heutzutage gilt beim Entwickeln einer Website ein responsives Design als zwingend notwendig. Stellen Sie sicher, dass alle interaktiven Elemente auf Mobilgeräten erwartungsgemäß funktionieren. Interaktive Elemente müssen einfach anzutippen sein, und es sollte viel Platz zwischen den Regionen zum Antippen geben."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verfassen von Content"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Was leicht vergessen werden kann, dass Barrierefreiheit auch in Ihren Textinhalten berücksichtigt werden sollte."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vermeiden Sie die richtungsorientierte Sprache wie „siehe Seitenleiste auf der linken Seite“. Wenn der Benutzer gerade das Handy benutzt, ist ergibt dies für ihn keinen Sinn, da Inhalte dort normalerweise gestapelt werden."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie Überschriften wie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h1>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h2>"
        }), " usw. und verschachteln Sie diese nacheinander. Überspringen Sie nicht eine Überschrift der visuellen Wirkung wegen."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie einen Link hinzufügen, vermeiden Sie unbedingt den Linktext „Hier klicken“. Dieser ist für Bildschirmleseprogramme nicht aussagekräftig und ergibt auf Touchscreens oder anderen Geräten keinen Sinn. Stattdessen sollte Ihr Text angeben, was den Benutzer bei diesem Link erwartet. Dies ist auch besser für die SEO, weil Suchmaschinen verstehen, zu was verlinkt wird."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stellen Sie sicher, dass Sie bei Verwendung spezieller Stile im Text zur Vermittlung von Bedeutung das entsprechende semantische Tag wie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<mark>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<strong>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<em>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<sup>"
        }), " usw. verwenden. Ansonsten kann Ihre Bedeutung für einige Besucher verloren gehen."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie Besucher weltweit ansprechen, vermeiden Sie Witze, die regionsspezifisch sind. Wenn Sie Ihre Website übersetzt werden soll, vermeiden Sie Wortspiele. So sehr wir kleine Wortspielereien mögen: sie lassen sich oft nicht gut übersetzen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Nutzen Sie Tools, um Ihre Grammatik und Rechtschreibung zu verbessern. Dies hilft beim Verständnis und führt zu besseren Übersetzungen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Schreiben Sie Ihre Inhalte auf eine Weise, die der Zielgruppe Ihrer Website entspricht. Sie würden ein Thema aus dem Bereich Astrophysik einem Schüler der 5. Klasse nicht auf die gleiche Weise wie einem Physiker mit Abschluss erklären. Das heißt, vermeiden Sie ausgefallene Wörter nur um ihrer Verwendung willen, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/plain-language-experts/",
          children: "einfach ist besser"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot-spezifische Empfehlungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die meisten Maßnahmen zur Verbesserung der Barrierefreiheit sind nicht HubSpot-spezifisch. Das heißt, wir möchten Sie für den Erfolg mit HubSpot aufstellen und es gibt einige Dinge, die Sie entweder als Entwickler oder Content-Autor tun können, um Benutzern ein besseres Erlebnis zu bieten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Verwenden Sie benutzerdefinierte Module und Vorlagen, die bewährten a11y-Methoden folgen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Egal, ob Sie ein Modul oder eine Vorlage vom Marketplace installieren, entwickeln oder für sich erstellen lassen, Sie sollten auf jeden Fall sicherstellen, dass sie bewährten a11y-Methoden entsprechen. Benutzerdefinierte Module können den größten Einfluss auf die Barrierefreiheit Ihrer Website haben, da sie oft mehrmals verwendet werden, manchmal sogar auf derselben Seite. Ebenso kann eine einzige Vorlage hunderte oder tausende Male auf einer einzigen Website verwendet werden. Wenn Ihre Website nicht barrierefrei ist, wird sich das sehr wahrscheinlich finanziell bemerkbar machen. Wie bei der Werbung sollten man lieber etwas mehr investieren, damit Ihre Website auf jeden Fall eine größere Zielgruppe erreicht. Einer der Vorteile von Modulen ist, dass Sie den Code oft später verbessern können, wenn sich Richtlinien und Best Practices weiterentwickeln. Dadurch wird die Barrierefreiheit auf einen Schlag für jede Seite verbessert, auf der sich das Modul befindet."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suchen Sie im HubSpot-Marketplace nach Modulen und Vorlagen, die Barrierefreiheit thematisieren und die WCAG-Anforderungen in ihren Beschreibungen erfüllen. Wenn Sie mit einem Entwickler zusammenarbeiten, stellen Sie sofort klar, dass Ihnen Barrierefreiheit wichtig ist. Nicht alle Entwickler berücksichtigen in ihrer Arbeit a11y von Anfang an. Wenn Sie ihnen das erst spät in einem Projekt mitteilen, wird die Sache wahrscheinlich kostspieliger. Denn anstatt Barrierefreiheit von Beginn an zu priorisieren, müssen sie jetzt rückwirkend Probleme bei der Barrierefreiheit beheben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie ein Entwickler sind, erstellen Sie Module und Vorlagen, die die Einhaltung von a11y-Richtlinien für Content-Autoren einfach machen. Verwenden Sie geeignete Überschriften, semantisches HTML, sorgen Sie dafür, dass interaktive Elemente richtige Rollen und Kontext bereitstellen. Content-Autoren, die sich der Bedeutung von Barrierefreiheit bewusst sind, zahlen in der Regel gerne ein wenig mehr für Module und Vorlagen, die inklusiv sind. Das heißt, Sie müssen zeigen, dass Ihre Module Barrierefreiheit berücksichtigen. Fügen Sie „Skip“-Links in Vorlagen ein, machen Sie globale Gruppen und globale Teilvorlagen, die in Ihren Vorlagen verwendet werden, a11y-freundlich. Diese werden auf der gesamten Website verwendet und können den größten Einfluss auf die Benutzerfreundlichkeit einer Website haben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie für den HubSpot-Marketplace erstellen, sollten Sie wissen, dass Ihre Inhalte auf Tausenden, ja sogar Millionen von Seiten im Internet verwendet werden können. Sie treffen eine Entscheidung, die sich auf Menschen auswirkt, wenn Sie Ihre Inhalte veröffentlichen und Ihre Arbeit teilen, unabhängig davon, ob Ihre Arbeit inklusiv ist oder nicht."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorsicht vor Lösungen aus einer Hand"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt Dutzende von Tools, die Versprechungen wie ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Fügen Sie einfach unser Skript zu Ihrer Website hinzu und Ihre Website ist barrierefrei"
      }), " machen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Einige dieser Tools versuchen, Teile der Seite zu interpretieren und HTML und Attribute zu ändern, um Probleme zu beheben. Dennoch beruht ihr Vorgehen nur auf Vermutungen und sie können falsch liegen oder die Funktionalität Ihrer Website beeinträchtigen bzw. die Benutzerfreundlichkeit verschlechtern."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Tools funktionieren möglicherweise nicht immer wie erwartet und stellen manchmal ihr eigenes Bildschirmlesesystem bereit. Wenn Sie eine komplizierte Benutzeroberfläche haben, kann die Bedienung Ihrer Website durch das hinzugekommene Tool tatsächlich schwieriger werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aufgrund dieser Probleme sind Tools wie diese kein Ersatz für das Entwickeln Ihrer Website mit Blick auf Barrierefreiheit. Investieren Sie Zeit und Geld, um allen Ihren Benutzern das bestmögliche Erlebnis zu bieten. Sich auf Lösungen aus einer Hand zu verlassen, kann Sie genauso viel oder mehr kosten, wie die Dinge auf die richtige Art und Weise zu entwickeln. Das Testen und Entwickeln von Websites unter dem Gesichtspunkt der Barrierefreiheit hat außerdem den Vorteil, das die Website mit Einfühlungsvermögen und Verständnis für Ihre Kunden erstellt wird."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um dies klar zu stellen: dies bezieht sich nicht auf Tools zum Testen auf Barrierefreiheit, es bezieht sich ganz spezifisch auf Tools, die behaupten, Probleme mit der Barrierefreiheit für Sie zu lösen. Tools für das Testen selbst sind großartig, und Sie sollten sie zusätzlich zu den manuellen Tests verwenden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://overlayfactsheet.com/",
        children: "Erfahren Sie mehr über Skripts/-Overlays für Barrierefreiheit."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot-Entwickler-Podcast"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Januar 2021 sprachen HubSpotters zusammen mit Jon Sasala von Morey Creative zum Thema Barrierefreiheit."
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/xOVTQN8phIs?feature=oembed",
      maxHeight: "113px",
      maxWidth: "200px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Weitere Informationen zur Barrierefreiheit"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt einige wirklich großartige Ressourcen für das Erstellen von barrierefreien Websites. Wir empfehlen Ihnen dringend, sich folgende Ressourcen anzusehen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://a11yproject.com/",
          children: "Das A11Y-Projekt"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility",
          children: "MDN-Dokumente zur Barrierefreiheit"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/WAI/",
          children: "Web Accessibility Initiative"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/",
          children: "WebAIM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/wcag/checklist",
          children: "Vereinfachte WCAG 2-Checkliste"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/508/checklist",
          children: "Checkliste zu Abschnitt 508"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/TR/WCAG22/",
          children: "Detaillierte WCAG 2.2-Empfehlungen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.deque.com/axe/",
          children: "AXE von Deque"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://wave.webaim.org/",
          children: "WAVE"
        }), " – ein Tool zum Testen der Barrierefreiheit einer Webseite"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/website/web-accessibility",
          children: "Der ultimative Leitfaden zur Barrierefreiheit im Web"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}