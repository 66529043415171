"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35403878124;
const slug = exports.slug = 'guides/cms/setup/js-frameworks';
const title = exports.title = 'How to use JavaScript frameworks on HubSpot';
const name = exports.name = 'How to use JavaScript frameworks on HubSpot';
const metaDescription = exports.metaDescription = 'Using the HubSpot CMS, you can create advanced JS-based web applications. There are boilerplates available to make it easier to get up and running.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "what-tier-of-hubspot-cms-is-needed%3F",
  "label": "What tier of HubSpot CMS is needed?",
  "parent": null
}, {
  "depth": 0,
  "id": "frameworks-and-libraries",
  "label": "Frameworks and libraries",
  "parent": null
}, {
  "depth": 0,
  "id": "what-you-should-know",
  "label": "What you should know",
  "parent": null
}, {
  "depth": 1,
  "id": "things-to-consider-when-building",
  "label": "Things to consider when building",
  "parent": "what-you-should-know"
}, {
  "depth": 2,
  "id": "server-side-rendering-and-client-side-rendering",
  "label": "Server-side rendering and client side rendering",
  "parent": "what-you-should-know"
}, {
  "depth": 2,
  "id": "single-page-app-spa-analytics",
  "label": "Single Page App (SPA) analytics",
  "parent": "what-you-should-know"
}, {
  "depth": 2,
  "id": "building-your-app-utilizing-hubspot-modules",
  "label": "Building your app utilizing HubSpot modules",
  "parent": "what-you-should-know"
}, {
  "depth": 2,
  "id": "building-a-full-template-instead",
  "label": "Building a full template instead",
  "parent": "what-you-should-know"
}, {
  "depth": 2,
  "id": "delimiters",
  "label": "Delimiters",
  "parent": "what-you-should-know"
}, {
  "depth": 0,
  "id": "vuejs",
  "label": "VueJS",
  "parent": null
}, {
  "depth": 1,
  "id": "without-a-build-step",
  "label": "Without a build step",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "add-the-vue-library-to-your-module",
  "label": "Add the vue library to your module",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "add-the-html-code",
  "label": "Add the HTML code",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "add-your-javascript-code",
  "label": "Add your JavaScript code",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "with-a-build-step",
  "label": "With a build step",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "working-with-hubspot-forms-and-ctas-within-vue-components",
  "label": "Working with HubSpot forms and CTAs within Vue components",
  "parent": "vuejs"
}, {
  "depth": 0,
  "id": "reactjs",
  "label": "ReactJS",
  "parent": null
}, {
  "depth": 0,
  "id": "other-javascript-libraries",
  "label": "Other JavaScript libraries",
  "parent": null
}, {
  "depth": 1,
  "id": "use-require_js-instead-of-script-tags",
  "label": "Use require_js instead of script tags",
  "parent": "other-javascript-libraries"
}, {
  "depth": 0,
  "id": "recommended-tutorials-and-guides",
  "label": "Recommended tutorials and guides",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      em: "em",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "How to use JavaScript frameworks and libraries on HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using the HubSpot CMS, you can create JavaScript-based web applications."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "What tier of HubSpot CMS is needed?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your website requires server-side code or a content membership mechanism, you can take advantage of HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverless functions"
      }), " and the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "content membership"
      }), " feature if you have an ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " subscription. However, you could alternatively build your own system using third-party providers such as AWS Lambda combined with an API gateway to run server-side code."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're building a web application that needs to hit API endpoints that require authentication such a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private app access token"
      }), ", you shouldn't run that code in the browser. You would be exposing your credentials to anyone who views the page. The right approach is to create a layer of abstraction between the browser and the authenticated API: a custom API endpoint that does not require exposing your credentials and is served from the same domain as the website calling it."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hitting the custom API endpoint will run server-side code that can make the authenticated request. Then you can do any formatting of the data or business logic you want to keep secret, and send the result to the browser."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Commonly, serverless functions are used to do this because they have incredible scalability, and they don't require managing and maintaining your own server. You can use providers like AWS Lambda combined with an API gateway, or you can use HubSpot's first-party ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverless functions"
      }), ". The advantage of HubSpot serverless functions is that you don't need to manage multiple separate services. The experience is simplified and directly integrated with the same developer file system which themes, templates and modules all exist in."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you don't need to make authenticated API calls, then you don't need enterprise for your app. React and Vue are front end frameworks that don't need serverless functions to work, it is what you do with them that matters."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Frameworks and libraries"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For web applications, developers commonly use JavaScript frameworks that help manage state and User Interface (UI)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub was not purpose-built to work with a specific framework in mind, but many common JavaScript frameworks work on HubSpot CMS. Building on HubSpot, you may need to think about how you work with those frameworks differently. But the core things needed to work with these frameworks are available: the ability to write ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "custom templates"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), ", and JavaScript. We also enable you to do your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "coding locally"
      }), ", so that you can use a build step."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "What you should know"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "We are collaborating with our developer community to establish the best practices for building with common JavaScript frameworks on HubSpot. While it is possible to do it, there are aspects of how the HubSpot CMS works that may require you to consciously set up your project differently than you might on a simple HTML page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There may also be some parts of your development workflow that aren't what you're used to. We ask that you let us know your feedback so we can improve the experience for all developers. Currently the best place to do that is our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "Developer forum"
      }), ". As we experiment and learn, we will continue to update our documentation accordingly."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Things to consider when building"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot CMS has a powerful ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "module system"
      }), ", enabling you to create re-usable chunks of CSS, JavaScript and HTML with access to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL, the HubSpot templating language"
      }), ". HubSpot modules provide a way for you to give a lot of control and power to content creators. Modern JavaScript frameworks often have their own module systems. These systems are all built independent from each other and as a result often have different solutions for issues you might encounter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Server-side rendering and client side rendering"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Server-side rendering is when the HTML for a page is generated from templating logic on the server before sending any HTML to a browser."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Client-side rendering is when a lighter or \"incomplete\" version of the HTML is sent from the server, and JavaScript is used to generate the HTML. This transfers the processing of logic from the server to the web browser (the client)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hydration is the act of combining both techniques. First, on the server, as much HTML as possible is generated. Then JavaScript evaluates the HTML provided and makes smaller changes to it as needed when the user interacts with the page or data is received. This reduces the load on the client and potentially reduces the time it takes for the user to see the loaded content."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "On HubSpot CMS, HubL is processed server-side and then cached at the CDN level. You can then use JavaScript to hydrate or client-side render the HTML the browser serves to the site visitor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Single Page App (SPA) analytics"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Analytics is important for your company's ability to grow and adapt to solve for your customers and prospects. When building a single page app that contains multiple views, you may want to track the visitor seeing different views, as pages."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Most analytics platforms provide a way to do this with JavaScript, HubSpot is no different. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code#tracking-in-single-page-apps",
        children: "Push the page-view"
      }), " when your app changes views."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Building your app utilizing HubSpot modules"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot's module system is a server-side module system, generating an HTML document from HubL + HTML partials and generating minified CSS and JavaScript for each module within a page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you build using HubSpot modules, there are several benefits that come along with it:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Content creators can add your module to pages that have drag and drop areas or flexible columns. They can also move and remove the module themselves."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You can provide fields to the content creator that let them configure settings for your app."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Your code is only rendered to the page only if the module is actually used."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Module.css"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.js"
        }), " is automatically minified."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The cost of using the HubSpot module system is that it requires modules to be made up of specific files and in different places than you might normally place your code."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Building a full template instead"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You could also build your application as a template rather than within the module framework. This gives you more flexibility with your file structure. But you do not get the benefits that modules provide; content creators will not be able to add this application to pages within drag and drop areas and flexible columns."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Delimiters"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Some JavaScript frameworks use curly braces ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ }"
      }), " to delimit their code. The HubL language uses these braces, as well. There are three strategies you can use to ensure you don't have conflicts between your framework and HubL: You can use the raw HubL tag to wrap around your JSX, set the framework to use a different delimiter, or use a build step that compiles the JavaScript beforehand."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "VueJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The popular ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/",
        children: "Vue.js"
      }), " framework can be used with and without a build step. See ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/installation.html",
        children: "Vue's own documentation"
      }), " for a more detailed breakdown of the pros and cons of each method. On HubSpot there are specific pros and cons you should also be keeping in mind."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Without a build step"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Integrating Vue.js without a build step into a module is easy."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Add the vue library to your module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " file, use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " to add the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/installation.html#Direct-lt-script-gt-Include",
        children: "Vue library"
      }), " ensuring it will only load once when your module is added to a page."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["While developing, use the dev build to get useful information for debugging. Once in production, it is recommended to use either the CDN URL for the specific Vue version, or download that file and host it as a JavaScript file in the HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview#developer-file-system",
          children: "developer file system"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Add the HTML code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Copy the HTML code from the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/index.html#Declarative-Rendering",
        children: "Vue.js introduction"
      }), ", and paste it into your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " file. Wrap this code in a HubL raw tag to prevent it from being evaluated as HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# raw prevents code within it from being evaluated as HubL #}\n\n<div id=\"app\">\n  {{ message }}\n</div>\n\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Add your JavaScript code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Copy the JavaScript from the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/index.html#Declarative-Rendering",
        children: "Vue.js introduction"
      }), ", and paste it into your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), ". Wrap this code in an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/API/Window/DOMContentLoaded_event",
        children: "event listener to ensure it's executed once the DOM content has finished loading"
      }), ". Publish your module, and preview it. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "You should now see your basic Vue app working."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var app = new Vue({\n  el: '#app',\n  data: {\n    message: 'Hello Vue!',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "With a build step"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["We've built a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate",
        children: "boilerplate"
      }), " [BETA] to help you get up and running with the HubSpot module approach to building a VueJS application. The easiest way to take advantage of it is to run the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create vue-app"
      }), " command from the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), ". Directions can be found in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/",
        children: "repository"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This boilerplate is new and we would love to hear your feedback! Let us know what could be improved and any issues you encounter. The best way to provide feedback is by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/issues",
        children: "submitting issues to the GitHub repository"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Working with HubSpot forms and CTAs within Vue components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot CTAs and forms have their own script tags, and manage their own HTML themselves. To ensure your vue component doesn't modify the form or CTA, create an HTML element around the CTA/form embed code. ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://vuejs.org/v2/api/#v-once",
        children: ["Apply ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v-once"
        }), " to that element."]
      }), " This ensures the code will be rendered once and then ignored by your Vue component."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ReactJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Rather than using HubL to build modules and partials, you can use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
        children: "JavaScript and React"
      }), ". In addition to stitching server-rendered React components into the HTML generated by HubL, JavaScript modules and partials support both server-side and client-side interactivity. Learn more in HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "Introduction to JS Building Blocks"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "React boilerplate"
      }), " to get up and running quickly with a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://reactjs.org/",
        children: "React"
      }), " app inside of a HubSpot module. The easiest way to take advantage of it is to run the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create react-app"
      }), " command from the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), ". From there follow the instructions in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "repository"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This boilerplate is new and we would love to hear your feedback! Let us know what could be improved and any issues you run into. The best way to provide feedback is by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate/issues",
        children: "submitting issues to the GitHub repository"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Other JavaScript libraries"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are a lot of JavaScript libraries out there and it is impossible for us to document all of them individually. There are some core best practices to know and understand when using JavaScript libraries on HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Use require_js instead of script tags"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can have dozens of modules, and templates that use shared JavaScript libraries, and not worry about loading those libraries multiple times. To do this you need to use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " HubL function. Scripts loaded using this function will only load once per page regardless of how many modules, partials, and the template, requires them."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{{ require_js(get_asset_url('/js/jquery-latest.js')) }}\n\n{{ require_js(\"https://cdnjs.cloudflare.com/ajax/libs/d3/6.2.0/d3.min.js\") }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get_asset_url()"
        }), " to require files stored within the developer file system. The advantage aside from just co-locating your development files and consolidating security of these files, is that it will result in fewer DNS lookups."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using require can be amazing for performance, because not only will you only load the file once. If assets on a page page don't need that library, it won't be loaded at all. You can even use requires with HubL logic to load resources only when you truly need it."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recommended tutorials and guides"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Optimizing for performance"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Accessibility is not a feature"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "How to use web components on HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Getting started with modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "Getting started with serverless functions"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Creating an efficient developer workflow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "Building dynamic pages with HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
          children: "Build modules and partials with JavaScript"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}