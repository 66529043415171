import { useEffect } from 'react';
export const useKeyboardShortcut = (combinations, callback) => {
  useEffect(() => {
    function handleKeyDown(event) {
      const {
        key,
        metaKey,
        target
      } = event; // metaKey is for Cmd on macOS and Windows key on Windows

      // Ignore keydown events from input or textarea elements
      if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) {
        return;
      }
      const keyCombination = `${metaKey ? 'Cmd+' : ''}${key.toUpperCase()}`;
      if (Array.isArray(combinations) && combinations.includes(keyCombination) || !Array.isArray(combinations) && combinations === keyCombination) {
        event.preventDefault();
        callback();
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [combinations, callback]);
};