"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29913023567;
const slug = exports.slug = 'reference/cms/modules/default-modules';
const title = exports.title = 'Módulos web predeterminados';
const name = exports.name = 'Módulos web predeterminados';
const metaDescription = exports.metaDescription = 'Descubre los módulos predeterminados que HubSpot proporciona para crear plantillas de páginas web, publicaciones de blog y listas de blog. Estos módulos son distintos de los módulos de correo predeterminados, que son para plantillas de correo.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "comentarios-del-blog",
  "label": "Comentarios del blog",
  "parent": null
}, {
  "depth": 0,
  "id": "suscripci%C3%B3n-a-los-correos-del-blog",
  "label": "Suscripción a los correos del blog",
  "parent": null
}, {
  "depth": 0,
  "id": "publicaciones-de-blog",
  "label": "Publicaciones de blog",
  "parent": null
}, {
  "depth": 0,
  "id": "filtro-de-publicaci%C3%B3n-de-blog",
  "label": "Filtro de publicación de blog",
  "parent": null
}, {
  "depth": 0,
  "id": "lista-de-publicaciones-de-blog",
  "label": "Lista de publicaciones de blog",
  "parent": null
}, {
  "depth": 0,
  "id": "bot%C3%B3n",
  "label": "Botón",
  "parent": null
}, {
  "depth": 0,
  "id": "llamada-a-la-acci%C3%B3n",
  "label": "Llamada a la acción",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-datos",
  "label": "Eliminar datos",
  "parent": null
}, {
  "depth": 0,
  "id": "divisor",
  "label": "Divisor",
  "parent": null
}, {
  "depth": 0,
  "id": "descargar-datos",
  "label": "Descargar datos",
  "parent": null
}, {
  "depth": 0,
  "id": "formulario",
  "label": "Formulario",
  "parent": null
}, {
  "depth": 0,
  "id": "encabezado",
  "label": "Encabezado",
  "parent": null
}, {
  "depth": 0,
  "id": "espaciador-horizontal",
  "label": "Espaciador horizontal",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%ADcono",
  "label": "Ícono",
  "parent": null
}, {
  "depth": 0,
  "id": "imagen",
  "label": "Imagen",
  "parent": null
}, {
  "depth": 0,
  "id": "cuadr%C3%ADcula-de-im%C3%A1genes",
  "label": "Cuadrícula de imágenes",
  "parent": null
}, {
  "depth": 0,
  "id": "galer%C3%ADa-de-im%C3%A1genes",
  "label": "Galería de imágenes",
  "parent": null
}, {
  "depth": 0,
  "id": "galer%C3%ADa-de-controles-deslizantes-de-im%C3%A1genes",
  "label": "Galería de controles deslizantes de imágenes",
  "parent": null
}, {
  "depth": 0,
  "id": "conmutador-de-idiomas",
  "label": "Conmutador de idiomas",
  "parent": null
}, {
  "depth": 0,
  "id": "logotipo",
  "label": "Logotipo",
  "parent": null
}, {
  "depth": 0,
  "id": "cuadr%C3%ADcula-del-logotipo",
  "label": "Cuadrícula del logotipo",
  "parent": null
}, {
  "depth": 0,
  "id": "reuniones",
  "label": "Reuniones",
  "parent": null
}, {
  "depth": 0,
  "id": "inicios-de-sesi%C3%B3n-en-redes-sociales-con-gesti%C3%B3n-de-miembros",
  "label": "Inicios de sesión en redes sociales con gestión de miembros",
  "parent": null
}, {
  "depth": 0,
  "id": "men%C3%BA",
  "label": "Menú",
  "parent": null
}, {
  "depth": 0,
  "id": "pie-de-p%C3%A1gina",
  "label": "Pie de página",
  "parent": null
}, {
  "depth": 0,
  "id": "solicitud-de-contrase%C3%B1a",
  "label": "Solicitud de contraseña",
  "parent": null
}, {
  "depth": 0,
  "id": "pagos",
  "label": "Pagos",
  "parent": null
}, {
  "depth": 0,
  "id": "producto",
  "label": "Producto",
  "parent": null
}, {
  "depth": 0,
  "id": "descarga-de-cotizaciones",
  "label": "Descarga de cotizaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "pago-de-cotizaciones",
  "label": "Pago de cotizaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "firma-de-la-cotizaci%C3%B3n",
  "label": "Firma de la cotización",
  "parent": null
}, {
  "depth": 0,
  "id": "elementos-de-pedido",
  "label": "Elementos de pedido",
  "parent": null
}, {
  "depth": 0,
  "id": "texto-enriquecido",
  "label": "Texto enriquecido",
  "parent": null
}, {
  "depth": 0,
  "id": "listado-de-rss",
  "label": "Listado de RSS",
  "parent": null
}, {
  "depth": 0,
  "id": "entrada-de-b%C3%BAsqueda-en-el-sitio",
  "label": "Entrada de búsqueda en el sitio",
  "parent": null
}, {
  "depth": 0,
  "id": "resultados-de-la-b%C3%BAsqueda",
  "label": "Resultados de la búsqueda",
  "parent": null
}, {
  "depth": 0,
  "id": "encabezado-de-la-secci%C3%B3n",
  "label": "Encabezado de la sección",
  "parent": null
}, {
  "depth": 0,
  "id": "men%C3%BA-simple",
  "label": "Menú simple",
  "parent": null
}, {
  "depth": 0,
  "id": "seguimiento-redes-sociales",
  "label": "Seguimiento redes sociales",
  "parent": null
}, {
  "depth": 0,
  "id": "intercambio-en-las-redes-sociales",
  "label": "Intercambio en las redes sociales",
  "parent": null
}, {
  "depth": 0,
  "id": "tarjeta-con-pesta%C3%B1as",
  "label": "Tarjeta con pestañas",
  "parent": null
}, {
  "depth": 0,
  "id": "una-l%C3%ADnea-de-texto",
  "label": "Una línea de texto",
  "parent": null
}, {
  "depth": 0,
  "id": "video",
  "label": "Video",
  "parent": null
}, {
  "depth": 0,
  "id": "inserci%C3%B3n-de-video-landing-page",
  "label": "Inserción de video (landing page)",
  "parent": null
}, {
  "depth": 0,
  "id": "enlace-de-whatsapp",
  "label": "Enlace de WhatsApp",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Módulos web predeterminados"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abajo encontrarás los módulos predeterminados que HubSpot proporciona para crear plantillas para páginas web, publicaciones de blog y páginas de índice de blog. También encontrarás módulos predeterminados que puedes usar para crear plantillas de cotización."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al desarrollar localmente, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch-files",
        children: "obtener"
      }), " un módulo predeterminado específico utilizando la ruta del módulo (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/linked_image.module"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver el código de un módulo predeterminado, puedes ver y clonar el módulo dentro de la ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot"
      }), " carpeta del administrador de diseño."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-default-modules.png",
        alt: "design-manager-default-modules"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " Los módulos web predeterminados son distintos de los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules",
          children: "módulos de correo predeterminados"
        }), ", que son para plantillas de correo. Si las plantillas de correo incluyen cualquiera de los siguientes módulos web predeterminados, deberías ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/default-email-modules",
          children: "reemplazarlos con el módulo específico de correo correspondiente"
        }), ":"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "cta"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "header"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "linked_image"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "logo"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "post_filter"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "post_listing"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "section_header"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "social_sharing"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comentarios del blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_comments\" path=\"@hubspot/blog_comments\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suscripción a los correos del blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_subscribe\" path=\"@hubspot/blog_subscribe\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El blog que se usará en el módulo."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Título del módulo (envuelto en una etiqueta h3)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Subscribe Here!\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "response_message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto enriquecido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El mensaje que aparece al enviar el formulario."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Thanks for subscribing!"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Publicaciones de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agrega este módulo a páginas de índice de blog para mostrar vistas preliminares de publicaciones de blog que contienen el título, la imagen destacada, el autor, la fecha de publicación y más de cada publicación con un botón en el que se puede hacer clic para navegar hasta la publicación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este módulo predeterminado se creó utilizando React, y puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react/tree/main/default-react-modules/src/components/modules/BlogPosts-V0",
        children: "ver su código fuente en GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["No se puede acceder a este módulo desde el administrador de diseño. Se puede hacer referencia al módulo con HubL en plantillas codificadas y agregarlo en el ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/blog/edit-a-blog-listing-page",
            children: "editor de la página de índice del blog"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Este módulo reemplaza al módulo anterior ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "blog_listing"
          }), ", que ha quedado obsoleto."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_posts\"\n  path=\"@hubspot/blog_posts\"\n  layout=\"grid\",\n  columns=4,\n  displayForEachListItem=[\n   \"image\",\n   \"title\",\n   \"authorName\",\n   \"tags\",\n   \"publishDate\",\n   \"description\",\n   \"button\"\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "layout"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La disposición del contenido para cada vista previa de publicación de blog.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "grid"
                }), " (predeterminado): alinea las publicaciones en una cuadrícula básica."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "singleColumn"
                }), ": alinea las publicaciones en una sola columna con imágenes destacadas en su propia fila encima del resto del contenido de la publicación."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "sideBySide"
                }), ": alinea las publicaciones en una columna con imágenes destacadas alineadas horizontalmente con el contenido de la publicación."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cuando se utiliza la disposición ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            }), ", el número de publicaciones por fila. Puede ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alternateImage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Al usar la disposición ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sideBySide"
            }), ", definir como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " para alinear la imagen destacada en los lados derecho e izquierdo de la vista previa de la publicación, alternando."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fullImage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Al usar las disposiciones ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "singleColumn"
            }), ", definir esta campo como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " para que la imagen destacada sea el fondo de la vista previa de la publicación."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "displayForEachListItem"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El contenido que se incluirá en cada vista previa de la publicación del blog. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image"
                }), ": la imagen destacada de la publicación."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), ": el título de la publicación."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "authorImage"
                }), ": la imagen del autor de la publicación."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "authorName"
                }), ": el nombre del autor de la publicación."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tags"
                }), ": las etiquetas del blog de la publicación."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "publishDate"
                }), ": la fecha de publicación del artículo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), ": la metadescripción de la publicación."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button"
                }), ": el botón Más información que se enlaza con la publicación del blog."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ 'image', 'title', 'authorImage', 'authorName', 'tags', 'publishDate', 'description', 'button' ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "buttonText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El texto que aparece en el botón Más información, si está incluido."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Read more"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filtro de publicación de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"post_filter\" path=\"@hubspot/post_filter\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Seleccionar un blog para mostrarlo. El valor predeterminado usará el blog actual cuando se use en una plantilla de blog o en el blog principal cuando se use en otro lugar."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filter_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tipos de enlaces de filtrado que se mostrarán. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "tag"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "month"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "author"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tag"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "order_by"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ordenación de los valores de los enlaces del filtro. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "post_count"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "name"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "post_count"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "list_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un encabezado H3."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Posts by Tag\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de enlaces de filtro que se mostrarán. Dejar en blanco para mostrar todos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "expand_link_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Texto para mostrar si hay disponible más que el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            }), " valor para mostrar."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"See all\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lista de publicaciones de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " Una nueva versión de este módulo fue lanzada a finales de marzo de 2024. Más información sobre el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-module-versioning#post-listing",
          children: "nuevo módulo de listas de publicaciones predeterminado"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"post_listing\" path=\"@hubspot/post_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Seleccionar un blog para mostrarlo. El valor predeterminado usará el blog actual cuando se use en una plantilla de blog o en el blog principal cuando se use en otro lugar."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listing_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de lista para las publicaciones. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "recent"
                }), ": más reciente."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_all_time"
                }), ": más popular de todos los tiempos."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_year"
                }), ": más popular el año pasado."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_six_months"
                }), ": más popular en los últimos seis meses."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_month"
                }), ": más popular el mes pasado."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recent"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "list_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un encabezado H3."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Recent Posts\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número máximo de publicaciones que se mostrarán."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Botón"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"button\" path=\"@hubspot/button\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enlace"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: ": La URL a la que redirecciona el botón."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"url\": { \"type\": \"EXTERNAL\", \"href\": \"www.hubspot.com\", \"content_id\": null }, \"open_in_new_tab\": false, \"no_follow\": false }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto que se mostrará en el botón."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Add a button link here\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_default_style"
                }), " (booleano)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_font"
                }), " (fuente)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_color"
                }), " (color)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_hover_color"
                }), " (color)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_hover_color"
                }), " (color)"]
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"override_default_style\": false, \"button_font\": { \"color\": \"#FFFFFF\", \"size_unit\": \"px\" }, \"button_color\": { \"color\": \"#000000\", \"opacity\": 100 }, \"text_hover_color\": { \"color\": \"#000000\", \"opacity\": 100 }, \"button_hover_color\": { \"color\": \"#CCCCCC\", \"opacity\": 100 } }"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Llamada a la acción"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"cta\" path=\"@hubspot/cta\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "guid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Identificador único global de la CTA."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar datos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes agregar este módulo a tu página de preferencias de suscripción para permitir que los contactos soliciten que sus datos personales se eliminen. Esta función es necesaria en virtud de ciertas leyes sobre privacidad de datos. Una vez que un contacto solicita que se eliminen sus datos, tiene 30 minutos para confirmarlo en un correo que se enviará automáticamente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los usuarios con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "permisos de superadministrador"
      }), " recibirán un correo de notificación sobre estas solicitudes. Descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#download-data",
        children: "permitir que los contactos soliciten la descarga de sus datos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"delete_data\" path=\"@hubspot/delete_data\", label=\"delete_data.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto enriquecido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensaje que se muestra encima del botón."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ### Delete Data  Permanently delete your personal data stored by {{ site_settings.company_name}}. Personal data is information that can be used to identify you and doesn't include anonymized data.  You'll get a follow-up email where you'll need to verify your request.  "
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto del botón."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Request data deletion"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_alerts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El grupo de campos de alerta de éxito y fallo. Incluye los siguientes grupos de campos:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_success_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": contenido de texto enriquecido de la alerta de éxito."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_fail_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": contenido de texto enriquecido de la alerta de fallo."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_close_icon"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "icon"
                    }), ": el ícono para cerrar la alerta."]
                  })
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Divisor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Disponible en páginas. Hay una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-module-versioning#divider",
        children: "nueva versión de este módulo"
      }), " disponible en las cuentas creadas después del 25 de agosto de 2022. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog/divider-module-v1",
        children: "Más información sobre este cambio"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"divider\" path=\"@hubspot/divider\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Altura en píxeles (px) de la línea divisoria."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Porcentaje (%) de anchura de la línea divisoria."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Color"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Color (hexadecimal) y opacidad (número) de la línea divisoria."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"color\": \"#000000\", \"opacity\": 100 }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tipo de línea. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "solid"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "dotted"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "dashed"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "solid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alineación horizontal de la línea divisoria. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "left"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "center"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "right"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "center"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrar/ocultar los márgenes superior e inferior en el divisor."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Valor en píxeles (px) del margen superior e inferior de la línea divisoria. Opción disponible cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_padding"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Descargar datos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes agregar este módulo a tu página de preferencias de suscripción para permitir que los contactos soliciten una copia de sus datos. Esta función es necesaria en virtud de ciertas leyes sobre privacidad de datos. Los usuarios con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "permisos de superadministrador"
      }), " recibirán un correo de notificación sobre estas solicitudes. Descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#delete-data",
        children: "permitir que los contactos soliciten la eliminación de sus datos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"download_data\" path=\"@hubspot/download_data\", label=\"download_data.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto enriquecido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensaje que se muestra encima del botón."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ### Download Data  Download your personal data stored by {{ site_settings.company_name}}.  Personal data is information that can be used to identify you and doesn't include anonymized data.  "
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto del botón."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Request data download"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_alerts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El grupo de campos de alerta de éxito y fallo. Incluye los siguientes grupos de campos:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_success_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": contenido de texto enriquecido de la alerta de éxito."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_fail_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": contenido de texto enriquecido de la alerta de fallo."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_close_icon"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "icon"
                    }), ": el ícono para cerrar la alerta."]
                  })
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Formulario"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Encabezado H3"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "form"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto Formulario que incluye:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "form_id"
                }), ": ID del formulario que se usará"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), ": lo que el visitante verá después de enviar el formulario:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "inline"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "redirect"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "message"
                }), ": mensaje en línea si ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " se define como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "inline"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect_id"
                }), ": ID de la página a la que se redireccionará si ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " se define como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect_url"
                }), ": URL a la que se redireccionará si ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " se define como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect"
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"form_id\": \"\", \"response_type\": \"redirect\", \"message\": \"Thanks for submitting the form.\", \"redirect_id\": null, \"redirect_url\": \"http://www.google.com\" }"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_are_overridden"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Correo al que enviar la notificación en lugar de los valores predeterminados del formulario."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_override_email_addresses"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Correo"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lista de comandos separados por comas para enviarlos cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_are_overridden"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "follow_up_type_simple"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se habilitó el envío de un correo de seguimiento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "simple_email_for_live_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Correo de seguimiento"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ID del correo de seguimiento. Disponible cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "follow_up_type_simple"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sfdc_campaign"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Campaña de Salesforce"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuando la integración con Salesforce está activa, el ID de la campaña."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Encabezado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"header\" path=\"@hubspot/header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto del encabezado."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A clear and bold header\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header_tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Elige un nivel de encabezado. La opción incluye ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " a través de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Espaciador horizontal"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog, publicaciones de blog y correos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"horizontal_spacer\" path=\"@hubspot/horizontal_spacer\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ícono"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog. Los íconos se pueden extraer de los conjuntos de íconos de Font Awesome 5.0.10 y 5.14.0."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"icon\" path=\"@hubspot/icon\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre del ícono."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"hubspot\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "purpose"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una opción de accesibilidad que clasifica el propósito del ícono para los lectores de pantalla. Los valores disponibles son:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "decorative:"
                }), " ignorados por el lector de pantalla."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "semantic:"
                }), " leídos por el lector de pantalla."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"decorative\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una opción de accesibilidad que asigna un título al ícono."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de ícono. Puede ser una de las siguientes: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "solid"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "regular"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "light"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thin"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duotone"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"solid\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unicode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El valor unicode del ícono."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "f3b2"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Imagen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"linked_image\" path=\"@hubspot/linked_image\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "img"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imagen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto Imagen que contiene:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": URL de la imagen"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": texto alternativo para la imagen"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "loading"
                }), ": las opciones de carga diferida incluyen:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "disabled"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "lazy"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "width"
                }), ": valor en píxeles"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "height"
                }), ": valor en píxeles"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"src\": \"https://static.hubspot.com/final/img/content/email-template-images/placeholder_200x200.png\", \"alt\": \"placeholder_200x200\", \"loading\": \"disabled\", \"width\": 200, \"height\": 200 }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enlace opcional para la imagen."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abre el enlace en una nueva pestaña."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cuadrícula de imágenes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"image_grid\" path=\"@hubspot/image_grid\", label=\"image_grid.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contiene información para cada diapositiva. El objeto contiene:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": URL de la imagen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL donde se debe enlazar la diapositiva."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hover_messages"
                }), ": texto que se superpone a la imagen al pasar el cursor por encima."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ { \"show_caption\": false, \"open_in_new_tab\": false } ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Modo de visualización de la galería de imágenes. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), ": control deslizante estándar."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                }), ": navegador de miniaturas."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                }), ": galería lightbox."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Número de filas en la galería lightbox cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Permite recorrer las diapositivas en bucle con siguiente/anterior cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Avanza automáticamente a la siguiente diapositiva cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cantidad de tiempo (segundos) entre el avance a la siguiente diapositiva cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Mostrar botones de navegación cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Fija la altura de las diapositivas cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), ": altura fija."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), ": altura variable."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "static"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Estilos de transición de diapositiva cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": transición de deslizamiento."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), ": transición de desvanecimiento."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slide"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Posición de las leyendas cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), ": mantener siempre las leyendas debajo de la imagen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), ": superponer las leyendas encima de las imágenes."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Galería de imágenes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"gallery\" path=\"@hubspot/gallery\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contiene información para cada diapositiva. El objeto contiene:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": URL de la imagen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_caption"
                }), ": valor booleano para mostrar la leyenda de la diapositiva."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), ": texto enriquecido para la leyenda."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL donde la diapositiva debe enlazarse."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_in_new_tab"
                }), ": valor booleano para indicar si el enlace debe abrirse en una nueva pestaña."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ { \"show_caption\": false, \"open_in_new_tab\": false } ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Modo de visualización de la galería de imágenes. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), ": control deslizante estándar."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                }), ": navegador de miniaturas."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                }), ": galería lightbox."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Número de filas en la galería lightbox cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Permite recorrer las diapositivas en bucle con siguiente/anterior cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Avanza automáticamente a la siguiente diapositiva cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cantidad de tiempo (segundos) entre el avance a la siguiente diapositiva cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Mostrar botones de navegación cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Fija la altura de las diapositivas cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), ": altura fija."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), ": altura variable."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "static"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Estilos de transición de diapositiva cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": transición de deslizamiento."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), ": transición de desvanecimiento."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slide"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Posición de las leyendas cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), ": mantener siempre las leyendas debajo de la imagen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), ": superponer las leyendas encima de las imágenes."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Galería de controles deslizantes de imágenes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_name\"\n    path=\"@hubspot/image_slider_gallery\",\n    slides=[\n        {\n        \"img\": {\n            \"src\": \"\",\n            \"alt\": \"Default image alt text\"\n        },\n        \"caption\": \"<strong>1</strong> An optional caption for the image that will be added to the gallery. Enter any descriptive text for this image that you would like visitors to be able to read.\",\n        \"link_url\": \"\"\n        },\n        {\n        \"img\": {\n            \"src\": \"\",\n            \"alt\": \"Default image alt text\"\n        },\n        \"caption\": \"<strong>2</strong> An optional caption for the image that will be added to the gallery. Enter any descriptive text for this image that you would like visitors to be able to read.\",\n        \"link_url\": \"\"\n        }\n    ]\n    slideshow_settings={\n        \"slides\": {\n        \"per_page\": 1,\n        \"sizing\": \"natural\",\n        \"aspect_ratio\": \"16/9\",\n        \"show_captions\": true,\n        \"caption_position\": \"below\"\n        },\n        \"movement\": {\n        \"transition\": \"slide\",\n        \"loop_slides\": false,\n        \"auto_advance\": false,\n        \"auto_advance_speed_seconds\": 5\n        },\n        \"navigation\": {\n        \"show_main_arrows\": true,\n        \"show_thumbnails\": false,\n        \"show_dots\": false\n        }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un grupo de campos que contiene los siguientes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": URL de la imagen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), ": texto enriquecido para la leyenda de la imagen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL con la que se enlaza la diapositiva."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slideshow_settings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El grupo de campos de configuración del control deslizante de la imagen. Incluye los siguientes grupos de campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slides"
                }), ": ajustes de diapositivas, que incluyen:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "per_page"
                    }), ": número de diapositivas por página."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "sizing"
                    }), ": tamaño de la imagen."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "aspect_ratio"
                    }), ": relación de aspecto de la imagen."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "show_captions"
                    }), ": un valor booleano"]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "movement"
                }), ": configuración del comportamiento del deslizamiento de imágenes."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "navigation"
                }), ": configuración de navegación por imágenes."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Los elementos de texto predeterminados del módulo, que incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "default_caption"
                }), ": leyenda de la imagen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "default_image_alt_text"
                }), ": texto de la imagen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slider_aria_label"
                }), ": la etiqueta aria predeterminada del módulo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "arial_label_thumbnails"
                }), ": la miniatura aria predeterminada del módulo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slider_nav_aria_label"
                }), ": la etiqueta aria predeterminada de la barra de navegación del módulo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "prev"
                }), ": texto de la diapositiva anterior."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "next"
                }), ": texto de la diapositiva siguiente."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "first"
                }), ": ir al texto de la primera diapositiva."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slideX"
                }), ": ir al texto de la diapositiva X."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pageX"
                }), ": ir al texto de la página X."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "play"
                }), ": iniciar texto de reproducción automática."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pause"
                }), ": pausar texto de reproducción automática."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "carousel"
                }), ": texto del carrusel."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "select"
                }), ": texto para seleccionar una diapositiva para mostrarla."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": texto de la diapositiva."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slidelabel"
                }), ": etiqueta de la diapositiva."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conmutador de idiomas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"language_switcher\" path=\"@hubspot/language_switcher\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El idioma del texto del conmutador de idiomas. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pagelang"
                }), ": el nombre de los idiomas aparecerá en el idioma de la página en la que está el conmutador."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "localized"
                }), ": el nombre de cada idioma aparecerá en ese idioma."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hybrid"
                }), ": una combinación de ambas opciones."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "localized"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Logotipo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo\" path=\"@hubspot/logo\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "img"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imagen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto Imagen que contiene:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_inherited_src"
                }), ": anular el logotipo predeterminado desde la configuración"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": URL de la imagen"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": texto alternativo para el logotipo"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"override_inherited_src\": false, \"src\": null, \"alt\": null }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enlace opcional para el logotipo. Si no se especifica ninguna URL, el logotipo se vinculará al ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "logotipo URL"
            }), " definido en la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors#edit-your-logo",
              children: "configuración de la marca"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "open_in_new_tab"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abre el enlace en una nueva pestaña."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "suppress_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ocultar el nombre de la empresa cuando no se selecciona una imagen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Elegir un nivel de encabezado cuando no hay ninguna imagen seleccionada y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "suppress_company_name"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " Las opciones incluyen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " a través de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cuadrícula del logotipo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una cuadrícula personalizable de contenedores para mostrar los logotipos de manera uniforme. Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo grid\"\n  path=\"@hubspot/logo_grid\"\n  group_logos=[\n    {\n      \"logo\": {\n        \"loading\": \"lazy\",\n        \"alt\": \"company_logo\",\n        \"src\": \"https://www.example.com/Logos/color/logo.png\"\n      }\n    },\n    {\n      \"logo\": {\n        \"loading\": \"lazy\",\n        \"alt\": \"company2_logo\",\n        \"src\": \"https://www.example.com/Logos/color/logo2.png\"\n      }\n    },\n    {\n      \"logo\": {\n        \"alt\": \"lorem-logo\",\n        \"height\": 40,\n        \"loading\": \"lazy\",\n        \"max_height\": 40,\n        \"max_width\": 175,\n        \"src\": \"https://www.example.com/Logos/color/logo3.png\",\n        \"width\": 175\n      }\n    }\n  ],\n  styles={\n    \"group_logo\": {\n      \"group_background\": {\n        \"aspect_ratio\": \"1/1\",\n        \"background_color\": {\n          \"color\": \"#8E7CC3\",\n          \"opacity\": 100\n        }\n      },\n      \"group_spacing\": {\n        \"padding\": {\n          \"padding\": {\n            \"bottom\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"left\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"right\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"top\": {\n              \"units\": \"px\",\n              \"value\": 75\n            }\n          }\n        }\n      },\n      \"max_logo_height\": 85\n    },\n    \"group_logo_grid\": {\n      \"column_count\": 3,\n      \"grid_gap\": 54\n    }\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_logos"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una matriz que contiene un objeto para cada logotipo de la cuadrícula."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "logo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["En ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_logos"
            }), ", un objeto para cada logotipo de la cuadrícula. Cada logotipo puede incluir los siguientes parámetros:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": la ruta del archivo del logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "loading"
                }), ": el comportamiento de la ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/performance/lazy-loading",
                  children: "carga diferida"
                }), " del logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": el texto alternativo del logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "height"
                }), ": la altura del logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "width"
                }), ": la anchura del logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "max_height"
                }), ": la altura máxima del logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "max_width"
                }), ": la anchura máxima del logotipo."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "styles"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una matriz que contiene los campos de estilo que afectan a la disposición de la cuadrícula, a los contenedores de logotipos y a las imágenes de logotipos. Esta matriz contiene los siguientes grupos de estilos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_logo"
                }), ": estilos que afectan a los contenedores e imágenes de logotipos. Contiene lo siguiente:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "group_background"
                    }), ": estilos que definen la relación de aspecto y el color de fondo de los contenedores de cuadrícula. Las relaciones de aspecto incluyen: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "1/1"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "4/3"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "16/9"
                    }), "."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "group_spacing"
                    }), ": estilos que definen el relleno interior del contenedor del logotipo. Para logotipos más anchos, un valor de relleno más alto puede disminuir la anchura del logotipo."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "max_logo_height"
                    }), ": la altura máxima de cada logotipo."]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_logo_grid"
                }), ": estilos que definen el diseño de la cuadrícula, incluidos:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "column_count"
                    }), ": el número de columnas de la cuadrícula."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "grid_gap"
                    }), ": el espaciado entre los elementos de la cuadrícula."]
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reuniones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"meetings\" path=\"@hubspot/meetings\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meeting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reunión"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Seleccionar un enlace de reunión."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inicios de sesión en redes sociales con gestión de miembros"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este módulo proporciona la función de inicio de sesión en Google y Facebook en los sitios con gestión de miembros. El usuario debe iniciar sesión con una cuenta vinculada al correo del contacto en el CRM. Puedes elegir cuáles inicios de sesión en las redes sociales activar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Disponible en páginas de inicio de sesión con gestión de miembros."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social\" path=\"@hubspot/membership_social_logins\",\n        clientid=\"\"\n        appid=\"\"\n        facebook_enabled=true\n        google_enabled=true\n        %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Facebook requiere que se tenga una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.facebook.com/docs/development/register",
          children: "cuenta de desarrollador de Facebook"
        }), " y una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.facebook.com/docs/development/create-an-app",
          children: "aplicación de Facebook creada"
        }), " con una configuración básica. Una vez que hayas hecho eso, el ID de la aplicación es lo que pasas al módulo."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Google requiere que exista una cuenta de Google y que se ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/identity/sign-in/web/sign-in#create_authorization_credentials",
          children: "creen credenciales de autorización"
        }), ". Cuando lo tengas, el ID de cliente de tu aplicación será lo que pases al módulo."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_appid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de tu aplicación de Facebook."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Activar el botón de inicio de sesión de Facebook ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_appid"
            }), " es obligatorio**.**"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_clientid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tu ID de cliente de Google."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Activar el botón de inicio de sesión de Google ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_clientid"
            }), " es obligatorio**.**"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menú"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["¿Quieres crear tu propio menú personalizado? Prueba la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#menu",
        children: "función menu()."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"menu\" path=\"@hubspot/menu\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menú"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID del menú."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "root_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tipo de menú avanzado. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "site_root"
                }), ": mostrar siempre las páginas de nivel superior en el menú."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "top_parent"
                }), ": mostrar las páginas del menú que están relacionadas con la sección que se está viendo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "parent"
                }), ": mostrar las páginas del menú que están relacionadas con la página que se está viendo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "breadcrumb"
                }), ": ruta de estilo de ruta de navegación (usa flujo horizontal)."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_root"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_levels"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Determina el número de árboles de menú secundarios que se pueden expandir en el menú. Las opciones incluyen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " a través de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Orientación del menú. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "horizontal"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "vertical"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flyouts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Función de habilitación con el cursor encima en los elementos del menú secundario."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pie de página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"page_footer\" path=\"@hubspot/page_footer\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Solicitud de contraseña"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"password_prompt\" path=\"@hubspot/password_prompt\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submit_button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto que aparece en el botón de envío."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Submit\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "password_placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: ": Texto del marcador de posición para el campo de contraseña."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Password\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bad_password_message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto enriquecido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensaje para mostrar cuando se introduce incorrectamente una contraseña."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Sorry, please try again. \""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pagos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"payments\" path=\"@hubspot/payments\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para configurar el módulo para que utilice un enlace de pago específico, incluir el ID del enlace de pago. Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#link#find-a-payment-link-s-id",
              children: "encontrar el ID"
            }), " en la URL mientras editas el enlace de pago."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout_location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Define si el formulario de pago se abre en una nueva pestaña o en una superposición. Los valores disponibles son:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "new_tab"
                }), ": abre el formulario de pago en una nueva pestaña."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "overlay"
                }), ": abre el formulario de pago en una superposición deslizante."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"new_tab\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Definir el texto del botón de pago."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Checkout\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si el botón utiliza un enlace de pago de HubSpot o un enlace externo. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "payment_link"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"payment_link\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enlace"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_target"
            }), " se define como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_link"
            }), ", define el destino del enlace externo. Los tipos de enlaces compatibles incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "EXTERNAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTENT"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Producto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Muestra un producto de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
        children: "biblioteca de productos"
      }), " de la cuenta. Disponible en páginas, publicaciones de blog y listas de blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/product\",\n  product={\n    \"id\" : 2124070179\n  },\n  group_button={\n    \"button_text\" : \"Buy\",\n    \"override_product_button\" : true,\n    \"button_override\" : {\n      \"no_follow\" : false,\n      \"open_in_new_tab\" : false,\n      \"sponsored\" : false,\n      \"url\" : {\n        \"href\" : \"https://www.test.com\",\n        \"type\" : \"EXTERNAL\"\n      }\n  },\n  group_description={\n    \"description_override\" : \"Monthly gym membership with access to shared locker facilities.\",\n    \"override_product_description\" : true\n  },\n  group_name={\n    \"heading_level\" : \"h3\",\n    \"name_override\" : \"Gym membership\",\n    \"override_product_name\" : true\n  },\n  group_image={\n    \"image_override\" : {\n      \"alt\" : \"360_F_317724775_qHtWjnT8YbRdFNIuq5PWsSYypRhOmalS\",\n      \"height\" : 360,\n      \"loading\" : \"lazy\",\n      \"src\" : \"https://2272014.fs1.hubspotusercontent-na1.net/hubfs/2272014/360_F_317724775_qHtWjnT8YbRdFNIuq5PWsSYypRhOmalS.jpg\",\n      \"width\" : 640\n    },\n    \"override_product_image\" : true\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Producto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El producto que se mostrará, especificado por ID de producto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["De forma predeterminada, el módulo incluye un botón que dirige a los usuarios a la URL fijada para el producto Para personalizar el destino del botón, incluye este grupo de campos opcionales junto con los siguientes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_text"
                }), ": una cadena que define el texto del botón."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_button"
                }), ": definido como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " para anular la configuración predeterminada del enlace del botón. Luego, incluye el objeto ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_override"
                }), ". Más información sobre ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_override"
                }), " abajo."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_override"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["En el grupo de objetos ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_button"
            }), " define el comportamiento de la URL del botón cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "override_product_button"
            }), " se define como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ".Incluye los siguientes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "no_follow"
                }), ": campo booleano para el comportamiento ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://ahrefs.com/blog/nofollow-links",
                  children: "no_follow"
                }), " del enlace."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_link_in_new_tab"
                }), ": campo booleano para el comportamiento del enlace abierto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "url"
                }), ": un objeto que especifica el destino del botón."]
              })]
            }), "En el campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            }), ", puedes definir el tipo de destino a través del campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), ", que admite los siguientes tipos de contenidos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EXTERNAL"
                }), ": una URL estándar. Incluir la URL en un campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTENT"
                }), ": una página de HubSpot. Incluir el ID de la página en un campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content_id"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "PAYMENT"
                }), ": un enlace de pago. Incluir el enlace de pago en un campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EMAIL_ADDRESS"
                }), ": una dirección de correo electrónico. Incluir la dirección en un campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["De forma predeterminada, el módulo mostrará el nombre del producto en la parte superior del módulo como un h3. Para personalizar el nombre, incluye este grupo de campos opcionales junto con los siguientes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "heading_level"
                }), ": el tamaño del encabezado. Puede ser ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "h1"
                }), " - ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "h6"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_name"
                }), ": definir como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " para especificar texto en lugar del nombre del producto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "name_override"
                }), ": la cadena que quieres mostrar en la parte superior del módulo."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["De forma predeterminada, el módulo mostrará la descripción del conjunto del producto. Para personalizar la descripción, incluye este grupo de campos opcionales junto con los siguientes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_description"
                }), ": definir como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " para personalizar la descripción del producto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description_override"
                }), ": una cadena que contiene la nueva descripción."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["De forma predeterminada, el módulo mostrará la imagen seleccionada para el producto. Para personalizar esta imagen, incluye este grupo de campos opcionales con los siguientes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_image"
                }), ": definir como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " para especificar una nueva imagen. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image_override"
                }), ": un objeto que especifica la nueva imagen, incluyendo las siguientes propiedades:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "alt"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "height"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "loading"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "src"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "width"
                    })
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Descarga de cotizaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en plantillas de cotización."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"download\" path=\"@hubspot/quote_download\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El texto que se muestra en el botón de descarga."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Download"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "download_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensaje de error que se muestra si falla la descarga."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem downloading the quote. Please try again."
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pago de cotizaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en plantillas de cotización."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"payment\" path=\"@hubspot/quote_payment\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El encabezado que aparece encima de la sección de pago de una plantilla de cotización."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de encabezado utilizado para mostrar ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_text"
            }), ". Los valores incluyen: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h3"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h4"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h5"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El texto del botón de pago."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pay now"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "needs_signature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto del botón cuando se requiere una firma."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment can't be made because the quote isn't fully signed."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto enriquecido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensaje que se muestra cuando hay un error durante el pago."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem setting up checkout. Please contact the person who sent you this quote."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment_status_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto enriquecido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensaje que se muestra cuando hay un error al intentar realizar un pago."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem loading the payment status for this quote. Please try refreshing the page."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "paid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un indicador de estado de pago hecho correctamente."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Paid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment_processing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un indicador de estado del procesamiento de un pago."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment processing"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Firma de la cotización"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en plantillas de cotización."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"signature\" path=\"@hubspot/quote_signature\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando una cotización requiere una firma electrónica, los siguientes campos están disponibles dentro de un objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "esignature"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pretext"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto enriquecido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto explicativo de la firma electrónica."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Before you sign this quote, an email must be sent to you to verify your identity. Find your profile below to request a verification email."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "verify_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto que aparece en el botón de verificación."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Verify to sign"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "failure"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto de alerta que aparece cuando no se puede obtener la información de la firma."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem retrieving the signature information. Please reload the page."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "verification_sent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un indicador de estado que aparece cuando la solicitud de verificación se envió correctamente al firmante de la cotización."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Verification sent"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "signed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto que se muestra cuando la cotización fue firmada correctamente."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signed"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando una cotización requiere una firma impresa, los siguientes campos están disponibles dentro de un objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "print_signature"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto enriquecido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto que se muestra en la parte superior de la sección de la firma."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signature"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "signature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto que se muestra junto al campo de firma."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signature"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto que se muestra junto al campo de fecha."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "printed_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto que se muestra junto al campo del nombre impreso."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Printed name"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "countersignature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un objeto que tiene contenido para la sección de refrendo. Este objeto puede contener los siguientes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "header"
                }), ": texto que se muestra en la parte superior de la sección de refrendo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "countersignature"
                }), ": texto que se muestra junto al campo de refrendo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "date"
                }), ": texto que se muestra junto al campo de fecha."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "printed_name"
                }), ": texto que se muestra junto al campo de nombre impreso."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signed"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Elementos de pedido"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en plantillas de cotización. Este módulo también incluye el texto predeterminado utilizado en las cotizaciones personalizadas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"module_165350106057652\" path=\"@hubspot/line_items\", label=\"line_items.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El título de la columna del elemento de pedido."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Column description"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "use_additional_product_property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrar casilla de verificación para permitir a los usuarios seleccionar columnas de elementos de pedido adicionales de las propiedades del producto."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "additional_product_properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una propiedad de producto. Las opciones incluyen: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "price"
                }), ": precio de elemento de pedido. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "item_description"
                }), ": descripción de elemento de pedido. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "quantity"
                }), ": número de elementos de pedido. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "amount"
                }), ": coste total de elementos de pedido. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hs_recurring_billing_start_date"
                }), ": fecha de inicio de facturación para elementos de pedido recurrentes. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "discount"
                }), ": la cantidad del descuento que se aplica al elemento de pedido. "]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm_product_property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Propiedad de objeto del CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Seleccionar cualquier propiedad de producto para que aparezca como un encabezado de columna de elemento de pedido."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Texto enriquecido"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en todos los tipos de plantillas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"rich_text\" path=\"@hubspot/rich_text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto enriquecido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bloque HTML."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ## Something Powerful  \\n  ### Tell The Reader More  \\n  The headline and subheader tells us what you're [offering](\\\"#\\\"), and the form header closes the deal. Over here you can explain why your offer is so great it's worth filling out a form for.  \\n  Remember:  \\n<ul>\\n<li>Bullets are great</li>\\n<li>For spelling out [benefits](\\\"#\\\") and</li>\\n<li>Turning visitors into leads.</li>\\n</ul>"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listado de RSS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"rss_listing\" path=\"@hubspot/rss_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un encabezado H3."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Test\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tipo de fuente RSS. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog"
                }), " - Blog alojado en HubSpot."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "external"
                }), " - Una fuente RSS externa."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["URL de RSS que se usará cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "external"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_group_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ID del blog que se usará cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "topic_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Etiqueta"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ID de la etiqueta con la que filtrar cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_items"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número máximo de publicaciones que se mostrarán."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "include_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Incluir la imagen destacada."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrar el nombre del autor."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attribution_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Texto que atribuye un autor a una publicación. Se muestra cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_author"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"by\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrar texto de resumen de la publicación."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit_to_chars"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Limita la extensión del texto de resumen cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "200"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "click_through_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El texto que se mostrará para el enlace de clickthrough al final del resumen de una publicación cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Read more\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrar fecha de publicación."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish_date_format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Formato de la fecha de publicación cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ". Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                }), " (ejemplo: 11/06/06 12:00)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                }), " (ejemplo: 6 de junio de 2006 12:00:00)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                }), " (ejemplo: 6 de junio de 2017 12:00:00 EDT)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MMMM d, yyyy 'at' h:mm a"
                }), " (ejemplo: 6 de junio de 2006 a las 12:00)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "h:mm a 'on' MMMM d, yyyy"
                }), " (ejemplo: 12:00 del 6 de junio de 2006)"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "short"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish_date_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El texto que indica cuándo se publicó una entrada cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"posted at\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Entrada de búsqueda en el sitio"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"./local-search_input\"\n  placeholder=\"Search\"\n  include_search_button=true\n  results={\n   \"use_custom_search_results_template\": \"true\",\n   \"path_id\": \"77977569400\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "field_label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto de etiqueta de entrada de búsqueda"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto del marcador de posición para el campo de entrada."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Search\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "include_search_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Incluir un botón de búsqueda."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Qué tipos de contenido incluir en los resultados de búsqueda. El objeto contiene las siguientes claves con valores booleanos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "website_pages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "landing_pages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_posts"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "knowledge_articles"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"website_pages\": true, \"landing_pages\": false, \"blog_posts\": true, \"knowledge_articles\": false }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "results"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un objeto que habilita los controles para usar una página de resultados de búsqueda personalizada. Incluye las siguientes propiedades:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "use_custom_search_results_template"
                }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "(booleano):"
                }), " cuando se define como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", los usuarios pueden seleccionar una página de resultados de búsqueda personalizada. El valor predeterminado es ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "path_id"
                }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "(cadena):"
                }), " el ID de la página que se utilizará para los resultados de búsqueda. La página referenciada debe contener el módulo de resultados de búsqueda."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Resultados de la búsqueda"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, publicaciones de blog y listas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_featured_images"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrar imágenes destacadas para los elementos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Encabezado de la sección"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"section_header\" path=\"@hubspot/section_header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contenido del encabezado de la sección."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A clear and bold header\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Nivel de encabezado para ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            }), ". Las opciones incluyen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " a través de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subheader"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto del párrafo del subencabezado para la sección."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A more subdued subheader\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menú simple"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"simple_menu\" path=\"@hubspot/simple_menu\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "menu_tree"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menú simple"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto de menú simple."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "orientation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Orientación del menú. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "horizontal"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "vertical"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Seguimiento redes sociales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agrega enlaces a tus perfiles de redes sociales, reordenando mediante la función de arrastrar y soltar en el editor de contenido. Inserta automáticamente íconos basados en la URL de la red social, pero estos pueden anularse."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_follow\" path=\"@hubspot/social_follow\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El enlace de destino para la página de redes sociales agregada en el editor."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "open_in_new_window"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cuando se define como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", el enlace se abrirá en la misma pestaña del navegador."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_podcast"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Se define como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " para que aparezca un ícono de pódcast en lugar de un ícono predeterminado o personalizado."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["De forma predeterminada, el texto alternativo se genera automáticamente. Si se define como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", puedes anular el texto alternativo predeterminado con un valor personalizado usando el campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria_label"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria-label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_alt_text"
            }), " es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", este campo define el texto alternativo del ícono (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Follow us on Facebook"
            }), "). Los lectores de pantalla lo usan para describirles el ícono a usuarios con discapacidad visual."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["De forma predeterminada, los íconos se rellenan automáticamente en función de la URL del enlace. Cuando se define como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", puedes usar el campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_icon"
            }), " para seleccionar uno de los otros íconos proporcionados (Font Awesome 6.4.2)."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ícono"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_icon"
            }), " es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_podcast"
            }), " es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", utiliza este campo para especificar un ícono personalizado del conjunto proporcionado. Los íconos disponibles son de Font Awesome 6.4.2."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Intercambio en las redes sociales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_sharing\" path=\"@hubspot/social_sharing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nota: La variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "social_link_url"
      }), " en la columna predeterminada que aparece abajo es el mismo valor que el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "link"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Este es el enlace de destino que se acortará para compartirlo más fácilmente en las redes sociales."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contiene:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": valor booleano para activar el elemento de redes sociales"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": URL personalizada para la URL del usuario que comparte en las redes sociales"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://www.facebook.com/share.php?u={{ social_link_url }}\" }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "twitter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contiene:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": valor booleano para activar el elemento de redes sociales"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": URL personalizada para la URL del usuario que comparte en las redes sociales"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("code", {
              children: ["{ \"enabled\": false, \"custom_link_format\": \"", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://twitter.com/intent/tweet?original_referer=%5C%7B%5C%7B",
                children: "https://twitter.com/intent/tweet?original_referer=\\{\\{"
              }), " social_link_url }}&url={{ social_link_url }}&source=tweetbutton&text={{ social_page_title|urlencode }}\" }"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "linkedin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contiene:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": valor booleano para activar el elemento de redes sociales"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": URL personalizada para la URL del usuario que comparte en las redes sociales"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://www.linkedin.com/shareArticle?mini=true&url={{ social_link_url }}\" }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pinterest"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contiene:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": valor booleano para activar el elemento de redes sociales."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": URL personalizada para la URL del usuario que comparte en las redes sociales."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pinterest_media"
                }), ": objeto Imagen que incluye:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "src"
                    }), ": URL de imagen."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "alt"
                    }), ": texto alternativo de la imagen."]
                  })]
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://pinterest.com/pin/create/button/?url={{ social_link_url }}&media={{ pinterest_media }}\", \"pinterest_media\": { \"src\": \"\", \"alt\": null } }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contiene:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": valor booleano para activar el elemento de redes sociales"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": URL personalizada para la URL del usuario que comparte en las redes sociales"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"mailto:?subject=Check out {{ social_link_url }} &body=Check out {{ social_link_url }}\" }"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tarjeta con pestañas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/tabbed_card\",\n  tabs=[\n   {\n    \"content\" : \"<p>This is the descriptive text contained in tab 1.</p>\",\n    \"tab_label\" : \"Tab 1 label\"\n   },\n   {\n    \"content\" : \"<p>This is the descriptive text contained in tab 2.</p>\",\n    \"tab_label\" : \"Tab 2 label\"\n   }\n  ],\n  fixed_height=false\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tabs"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un grupo de campos que contiene la etiqueta de la pestaña y el contenido del texto de cada pestaña. Contiene los siguientes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tab_label"
                }), ": la etiqueta de la pestaña."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "content"
                }), ": el contenido del texto enriquecido de la pestaña."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Some additional information in one line\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fixed_height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["De forma predeterminada, las pestañas tendrán una altura fija para que la altura del contenedor permanezca igual al cambiar entre pestañas. Puedes definirlo como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " si quieres que la altura del contenedor de tarjetas con pestañas se base en el contenido de la pestaña activa."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Una línea de texto"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"text\" path=\"@hubspot/text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Agrega el texto a este parámetro."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Some additional information in one line\""
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Video"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas, listas de blog y publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"video\" path=\"@hubspot/video\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tipo de video. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "embed"
                }), ": insertar código de una fuente externa."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hubspot_video"
                }), ": video alojado en HubSpot."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_video"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reproductor de video"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Video alojado en HubSpot. Se utiliza cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_video"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed_field"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inserción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Incluye los tipos de inserción:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "oembed"
                }), (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "video"
                    }), ": URL de video."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "html"
                }), ": código de inserción de video."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed_thumbnail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imagen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Anula la imagen en miniatura insertada cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed_field"
            }), " es igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contiene ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed_thumbnail_play_button_color"
            }), " - Campo de color."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"oembed_thumbnail_play_button_color\":\"#ffffff\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder_fields"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contiene:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "placeholder_title"
                }), ": campo de texto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "placeholder_description"
                }), ": campo de texto."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"placeholder_title\":\"No video selected\", \"placeholder_description\":\"Select a video type in the sidebar.\"}"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inserción de video (landing page)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Disponible en páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"video_embed_lp\" path=\"@hubspot/video_embed_lp\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL de video. Las URL de Vimeo y YouTube son compatibles."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://www.youtube.com/watch?v=X1Rr5BFO5rg\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Reproducir el video en ancho completo (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ") o fijar manualmente la anchura y la altura (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ")."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anchura máxima (px)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "800"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Altura máxima (px)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "450"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Enlace de WhatsApp"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Disponible en páginas, publicaciones de blog y páginas de índice de blog. Requiere un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/connect-whatsapp-to-the-conversations-inbox",
        children: "canal de WhatsApp conectado"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"whatsapp_link\" path=\"@hubspot/whatsapp_link\", label=\"whatsapp_link\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Seleccionar uno de los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/connect-whatsapp-to-the-conversations-inbox",
              children: "canales de WhatsApp conectados a la cuenta"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optin_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/collect-consent-for-whatsapp-conversations#subscribe-a-contact-using-opt-in-keywords-in-a-whatsapp-thread",
              children: "texto para darse de alta y de baja"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_display"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Seleccionar si el botón muestra texto, un ícono de WhatsApp o ambos. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_and_icon"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "icon"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_and_icon"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opción"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La posición del ícono en el botón. Las opciones incluyen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "left"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "right"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "left"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El texto del botón."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Chat on WhatsApp"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_icon_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El texto del ícono para los lectores de pantalla."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WhatsApp Icon"
            })
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}