"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021629;
const slug = exports.slug = 'guides/apps/api-usage/usage-details';
const title = exports.title = 'HubSpot-APIs | Richtlinien zur Nutzung';
const name = exports.name = 'Richtlinien zur API-Nutzung';
const metaDescription = exports.metaDescription = 'In den Richtlinien zur API-Nutzung von HubSpot werden unsere Nutzungsrichtlinie, API-Begriffe, Ratenbeschränkungen sowie die Bereiche Authentifizierung und Sicherheit behandelt.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "authentifizierung-und-sicherheit",
  "label": "Authentifizierung und Sicherheit",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%BCberpr%C3%BCfen-der-api-nutzung",
  "label": "Überprüfen der API-Nutzung",
  "parent": null
}, {
  "depth": 1,
  "id": "private-apps",
  "label": "Private Apps",
  "parent": "%C3%BCberpr%C3%BCfen-der-api-nutzung"
}, {
  "depth": 1,
  "id": "%C3%B6ffentliche-apps%2C-die-oauth-verwenden",
  "label": "Öffentliche Apps, die OAuth verwenden",
  "parent": "%C3%BCberpr%C3%BCfen-der-api-nutzung"
}, {
  "depth": 0,
  "id": "ratenbeschr%C3%A4nkungen",
  "label": "Ratenbeschränkungen",
  "parent": null
}, {
  "depth": 2,
  "id": "%C3%B6ffentliche-apps",
  "label": "Öffentliche Apps",
  "parent": "ratenbeschr%C3%A4nkungen"
}, {
  "depth": 2,
  "id": "private-apps",
  "label": "Private Apps",
  "parent": "ratenbeschr%C3%A4nkungen"
}, {
  "depth": 0,
  "id": "sonstige-beschr%C3%A4nkungen",
  "label": "Sonstige Beschränkungen",
  "parent": null
}, {
  "depth": 0,
  "id": "beschr%C3%A4nkungen-f%C3%BCr-anfragen",
  "label": "Beschränkungen für Anfragen",
  "parent": null
}, {
  "depth": 0,
  "id": "leistungsbeschr%C3%A4nkungen",
  "label": "Leistungsbeschränkungen",
  "parent": null
}, {
  "depth": 0,
  "id": "fehlerantworten",
  "label": "Fehlerantworten",
  "parent": null
}, {
  "depth": 1,
  "id": "nach-m%C3%B6glichkeit-batch-apis-und-cache-ergebnisse-verwenden",
  "label": "Nach Möglichkeit Batch-APIs und Cache-Ergebnisse verwenden",
  "parent": "fehlerantworten"
}, {
  "depth": 1,
  "id": "webhooks-verwenden%2C-um-aktualisierte-daten-von-hubspot-zu-erhalten",
  "label": "Webhooks verwenden, um aktualisierte Daten von HubSpot zu erhalten",
  "parent": "fehlerantworten"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      ul: "ul",
      li: "li",
      img: "img",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Richtlinien zur API-Nutzung"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot überwacht aufmerksam die Nutzung unserer öffentlich-APIs, um jedem Benutzer ein erstklassiges Erlebnis zu gewährleisten. Alle Entwickler von Apps und Integrationen müssen die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "Richtlinie zur akzeptablen Nutzung"
      }), " und die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/developer-terms",
        children: "API-Bedingungen"
      }), " von HubSpot einhalten. HubSpot behält sich das Recht vor, APIs im Laufe der Zeit zu ändern oder einzustellen. Updates werden jedoch stets im Voraus über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Änderungsprotokoll für Entwickler"
      }), " bereitgestellt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authentifizierung und Sicherheit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für optimale Sicherheit müssen alle Apps das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth-Protokoll"
      }), " von HubSpot direkt verwenden oder sie müssen das Zugriffstoken Ihrer App verwenden, wenn Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private App"
      }), " erstellen. Apps sind für die Speicherung von Time-to-live (TLS)-Daten und die Aktualisierung von Benutzerzugriffstoken gemäß diesem Protokoll verantwortlich. Wenn ein Zugriffstoken generiert wird, enthält es einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), "-Parameter, der angibt, wie lange es für API-Aufrufe verwendet werden kann, bevor es aktualisiert wird. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Unauthorized (401)"
      }), "-Anfragen sind kein zulässiger Indikator, dass ein neues Zugriffstoken abgerufen werden muss."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Überprüfen der API-Nutzung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Private Apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "So zeigen Sie die API-Nutzung für eine private App an:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in Ihrem HubSpot-Account in der Hauptnavigationsleiste auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zahnrad-Symbol"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrationen"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " der privaten App."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf der Seite mit den App-Details auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Protokolle"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Überprüfen Sie die in der Tabelle aufgeführten API-Aufrufe. Sie können auch die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suchleiste"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Filter"
        }), " und ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Datumsauswahlen"
        }), " verwenden, um die angezeigten API-Aufrufe weiter zu verfeinern."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/Screenshot%202023-08-31%20at%205.28.03%20PM.png",
        alt: "Screenshot 31.08.2023 um 17.28.03 Uhr"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#view-api-call-logs",
          children: "Überprüfen der API-Nutzung in privaten Apps"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Öffentliche Apps, die OAuth verwenden"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So zeigen Sie die API-Nutzung für eine öffentliche App an, die OAuth verwendet:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem Entwickler-Account in der Hauptnavigationsleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " der App."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Monitoring"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Registerkarten"
        }), ", um verschiedene Typen von Anfragen anzuzeigen, die an oder von der App vorgenommen werden. Beim Anzeigen dieser Protokolle können Sie auf eine ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "einzelne Anfrage"
        }), " klicken, um weitere Informationen anzuzeigen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/6-request_details.png",
        alt: "6-request_details"
      }), "Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#monitor-app-behavior",
        children: "Überwachen der API-Nutzung für öffentliche Apps"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ratenbeschränkungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Öffentliche Apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei OAuth-Apps ist jeder HubSpot-Account, der Ihre App installiert, auf 100 Anfragen pro 10 Sekunden beschränkt. Dies schließt die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search",
        children: "Suche-API"
      }), " aus, wie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-limits",
        children: "Sonstige Beschränkungen"
      }), " unten erläutert. Beschränkungen im Zusammenhang mit dem API-Add-on gelten nicht."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Private Apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jede private App unterliegt ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "den Richtlinien zur API-Nutzung von HubSpot"
      }), ". Die Anzahl der Aufrufe, die Ihre private App durchführen kann, hängt von Ihrem Account-Abonnement ab und davon, ob Sie das API-Add-on erworben haben:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Produktstufe"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Pro 10 Sekunden"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Pro Tag"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Private Apps"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Alle Produkte) Kostenlose Version und Starter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100/private App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250.000/Account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Alle Produkte) Professional und Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "150/private App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "500.000/Account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Private Apps mit API-Add-on"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Alle Produkte) Kostenlose Version, Starter, Professional und Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "200/private App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1.000.000/Account"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sonstige Beschränkungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie können bis zu 100 Apps pro Entwickler-Account erstellen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie können bis zu 20 private Apps pro HubSpot-Account erstellen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie können bis zu 1.000 Webhook-Abonnements pro App erstellen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie können bis zu 25 CRM-Erweiterungseinstellungen pro App erstellen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie können bis zu 750 Chronik-Event-Typen pro App erstellen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie können bis zu 500 Eigenschaften pro Chronik-Event-Typ erstellen"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beschränkungen für Anfragen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beachten Sie die folgenden allgemeinen Beschränkungen, wenn Sie Anfragen an die API-Endpunkte von HubSpot durchführen. Einige APIs können auch ihre eigenen, spezifischeren Beschränkungen haben, die auf der API-Referenzseite unter _ Beschränkungen_ aufgeführt werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/single-send-limits-example.png",
        alt: "single-send-limits-example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bei ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search",
          children: "Such-API"
        }), "-Endpunkten gibt es eine Ratenbeschränkung von vier Anfragen pro Sekunde pro Authentifizierungstoken Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search#limitations",
          children: "Suchbeschränkungen "
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["API-Anfragen, die von täglichen oder sekundären Limits ausgenommen sind, werden ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " in HubSpot protokolliert. Wenn Sie diese ausgenommenen Anfragen speichern möchten, müssen Sie diese Anfragen extern protokollieren."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Batch-Anfragen an CRM-Objekt-Endpunkte sind auf 100 Datensätze pro Anfrage beschränkt. Sie können beispielsweise nicht mehr als 100 Kontakte pro Anfrage per Batch lesen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Leistungsbeschränkungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Weitere Informationen zu Leistungsbeschränkungen und Preisen finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/pricing/service-limits",
        children: "hier"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fehlerantworten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine App oder eine Integration, die über die Ratenbeschränkungen hinausgeht, erhält eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), "-Fehlerantwort für alle nachfolgenden API-Aufrufe. Anfragen, die zu einer Fehlerantwort führen, sollten nicht mehr als 5 % Ihrer gesamten täglichen Anfragen ausmachen. Wenn Sie Ihre App im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "HubSpot App Marketplace"
      }), " listen möchten, muss sie unter 5 % liegen, um zertifiziert zu werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), "-Antwort weist das folgende Format auf:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example\n{\n  \"status\": \"error\",\n  \"message\": \"You have reached your daily limit.\",\n  \"errorType\": \"RATE_LIMIT\",\n  \"correlationId\": \"c033cdaa-2c40-4a64-ae48-b4cec88dad24\",\n  \"policyName\": \"DAILY\",\n  \"requestId\": \"3d3e35b7-0dae-4b9f-a6e3-9c230cbcf8dd\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "message"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "policyName"
      }), " geben an, welche Beschränkung Sie (entweder täglich oder Sekunden) erreicht haben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die** tägliche **Beschränkung wird auf der Grundlage Ihrer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-set-your-time-zone-in-hubspot",
        children: "Zeitzoneneinstellung"
      }), " um Mitternacht zurückgesetzt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In der folgenden Tabelle sind die Ratenbeschränkungen-Header aufgeführt, die in der Antwort jeder API-Anfrage an HubSpot enthalten sind, vorbehaltlich der unter der Tabelle aufgeführten Ausnahmen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Header"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beschreibung"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Daily"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Anzahl der API-Anfragen, die pro Tag zulässig sind. Beachten Sie, dass dieser Header nicht in der Antwort auf API-Anfragen enthalten ist, die mit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth",
              children: "OAuth"
            }), " autorisiert wurden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Daily-Remaining"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Anzahl der API-Anfragen, die für den aktuellen Tag noch erlaubt sind. Beachten Sie, dass dieser Header nicht in der Antwort auf API-Anfragen enthalten ist, die mit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth",
              children: "OAuth"
            }), " autorisiert wurden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Das Zeitfenster, für das die Kopfzeilen und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Max"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Remaining"
            }), " gelten. Ein Wert von 10.000 wäre beispielsweise ein Fenster von 10 Sekunden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Max"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Anzahl der Anfragen, die im in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), " angegebenen Fenster zulässig sind. Wenn beispielsweise dieser Header einen Wert von 100 hat und der Header ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), " 10.000 war, würde die erzwungene Beschränkung bei 100 Anfragen pro 10 Sekunden liegen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Remaining"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Anzahl der API-Anfragen, die für das in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), " angegebene Fenster noch zulässig sind."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Die Header ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly"
          }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly-Remaining"
          }), " werden weiterhin berücksichtigt und verfügen weiterhin über genaue Daten, aber die von diesen Headern referenzierten Beschränkungen werden nicht mehr durchgesetzt und diese beiden Header sollten als veraltet betrachtet werden."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Antworten von den ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/search",
            children: "Suche-API-Endpunkten"
          }), " enthalten ", (0, _jsxRuntime.jsx)("u", {
            children: "keine"
          }), " der oben aufgeführten Ratenbeschränkungen-Header."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mithilfe ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "dieses Endpunkts"
      }), " können Sie auch die Anzahl der Aufrufe überprüfen, die während des aktuellen Tags verbraucht wurden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TEN_SECONDLY_ROLLING"
      }), "-Beschränkung erreichen, sollten Sie die Anfragen, die Ihre Anwendung vornimmt, unter diese Beschränkung drosseln. Neben dem Drosseln von Anfragen sollten Sie auch folgende Vorschläge beachten (diese sind auch hilfreich, wenn Sie die tägliche Beschränkung erreichen)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie trotz Umsetzung dieser Vorschläge weiterhin die Aufrufbeschränkungen erreichen, bitten Sie in HubSpots", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://integrate.hubspot.com/",
        children: " Entwicklerforen"
      }), " nach Unterstützung. Sie sollten so viele Details wie möglich über die die von Ihnen genutzten APIs angeben: wie Sie diese verwenden und welche Beschränkung Sie erreichen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Nach Möglichkeit Batch-APIs und Cache-Ergebnisse verwenden"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Ihre Website oder App bei jedem Laden von Seiten Daten von HubSpot verwendet, sollten diese Daten im Cache gespeichert und von dort geladen werden, anstatt jedes Mal von den HubSpot-APIs angefordert zu werden. Wenn Sie wiederholt Aufrufe vornehmen, um Einstellungen von Ihrem Account für einen Batch-Auftrag abzurufen (z. B. wenn Sie Ihre Objekteigenschaften, zuständigen Mitarbeiter oder Einstellungen für ein Formular abrufen), sollten diese Einstellungen wenn möglich ebenfalls im Cache gespeichert werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhooks verwenden, um aktualisierte Daten von HubSpot zu erhalten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie über ein Marketing Hub Enterprise-Abonnement verfügen, können Sie Webhook-Aktionen in Workflows verwenden, damit Daten für Kontaktdatensätze an Ihr System gesendet werden. Webhooks können in jedem Workflow als Aktion ausgelöst werden. Daher können Sie beliebige Workflow-", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows-user-guide-v2/how-to-choose-the-workflow-type-and-the-starting-condition-best-suited-for-your-goal",
        children: "Startbedingungen"
      }), " als das Kriterium verwenden, über das Kontaktdaten an Ihr System gesendet werden. Weitere Informationen zur Verwendung von Webhooks finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "hier"
      }), ", Beispiel-Webhook-Daten finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/create-manage-workflows/v3#create-a-workflow",
        children: "hier"
      }), ". Webhook-Aufrufe, die über Workflows getätigt werden, werden nicht auf die API-Ratenbeschränkung angerechnet."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}