"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358753;
const slug = exports.slug = 'guides/api/crm/objects/deals';
const title = exports.title = 'CRM API | Deals';
const name = exports.name = 'vNext Docs DP - Deals';
const metaDescription = exports.metaDescription = 'A deal stores data about an ongoing transaction. The deals endpoints allow you to manage this data and sync it between HubSpot and other systems. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-deals",
  "label": "Create deals",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "create-deals"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "create-deals"
}, {
  "depth": 0,
  "id": "retrieve-deals",
  "label": "Retrieve deals",
  "parent": null
}, {
  "depth": 0,
  "id": "update-deals",
  "label": "Update deals",
  "parent": null
}, {
  "depth": 1,
  "id": "associate-existing-deals-with-records-or-activities",
  "label": "Associate existing deals with records or activities",
  "parent": "update-deals"
}, {
  "depth": 1,
  "id": "remove-an-association",
  "label": "Remove an association",
  "parent": "update-deals"
}, {
  "depth": 0,
  "id": "pin-an-activity-on-a-deal-record",
  "label": "Pin an activity on a deal record",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-deals",
  "label": "Delete deals",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Deals"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot, deals represent transactions with contacts or companies. Deals are tracked through your sales process in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "pipeline stages"
      }), " until they're won or lost. The deals endpoints allow you to manage create and manage deal records, as well as sync deal data between HubSpot and other systems."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about objects, records, properties, and associations APIs in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Understanding the CRM"
      }), " guide. For more general information about objects and records in HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "learn how to manage your CRM database"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create deals"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create new deals, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In your request, include your deal data in a properties object. You can also add an associations object to associate your new deal with existing records (e.g., contacts, companies), or activities (e.g., meetings, notes)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Deal details are stored in deal properties. There are ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/hubspots-default-deal-properties",
        children: "default HubSpot deal properties"
      }), ", but you can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "create custom properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating a new deal, you should include the following properties in your request: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealname"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealstage"
      }), " and if you have multiple pipelines, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pipeline"
      }), ". If a pipeline isn't specified, the default pipeline will be used."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available properties, you can retrieve a list of your account's deal properties by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/deals"
      }), ". Learn more about the the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": you must use the internal ID of a deal stage or pipeline when creating a deal via the API. The internal ID will also be returned when you retrieve deals via the API. You can find a deal stage's or pipeline's internal ID in your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages#to-customize-your-deal-stages:~:text=To%20view%20the%20internal%20name%20for%20a%20deal%20stage%2C%20hover%20over%20the%20stage%20and%20click%20the%20code%20code%20icon.%20The%20internal%20name%20is%20used%20by%20integrations%20and%20the%20API.",
          children: "deal pipeline settings."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to create a new deal, your request may look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"amount\": \"1500.00\",\n    \"closedate\": \"2019-12-07T16:50:06.678Z\",\n    \"dealname\": \"New deal\",\n    \"pipeline\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hubspot_owner_id\": \"910901\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating a new deal, you can also associate the deal with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "existing records"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "activities"
      }), " in an associations object. For example, to associate a new deal with an existing contact and company, your request would look like the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"amount\": \"1500.00\",\n    \"closedate\": \"2019-12-07T16:50:06.678Z\",\n    \"dealname\": \"New deal\",\n    \"pipeline\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hubspot_owner_id\": \"910901\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 201\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 5\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 3\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the associations object, you should include the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The record or activity you want to associate with the deal, specified by its unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " value."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of the association between the deal and the record/activity. Include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Default association type IDs are listed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
              children: "here"
            }), ", or you can retrieve the value for custom association types (i.e. labels) via the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#retrieve-association-types",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve deals"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can retrieve deals individually or in batches."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve an individual deal, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/{dealId}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To request a list of all deals, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For these endpoints, you can include the following query parameters in the request URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned in the response. If the requested deal doesn't have a value for a property, it will not appear in the response."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the current and historical properties to be returned in the response. If the requested deal doesn't have a value for a property, it will not appear in the response."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A comma separated list of objects to retrieve associated IDs for. Any specified associations that don't exist will not be returned in the response. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4",
              children: "associations API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a batch of specific deals by record ID or a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "custom unique identifier property"
        }), ", make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/deals/batch/read"
        }), ". The batch endpoint ", (0, _jsxRuntime.jsx)("u", {
          children: "cannot"
        }), " retrieve associations. Learn how to batch read associations with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4",
          children: "associations API"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For the batch read endpoint, you can also use the optional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " parameter to retrieve deals by a custom ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "unique identifier property"
      }), ". By default, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " values in the request refer to the record ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), so the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " parameter is not required when retrieving by record ID. To use a custom unique value property to retrieve deals, you must include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to retrieve a batch of deals, your request could look like either of the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"dealname\", \"dealstage\", \"pipeline\"],\n  \"inputs\": [\n    {\n      \"id\": \"7891023\"\n    },\n    {\n      \"id\": \"987654\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"dealname\", \"dealstage\", \"pipeline\"],\n  \"idProperty\": \"uniqueordernumber\",\n  \"inputs\": [\n    {\n      \"id\": \"0001111\"\n    },\n    {\n      \"id\": \"0001112\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To retrieve deals with current and historical values for a property, your request could look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"dealstage\"],\n  \"inputs\": [\n    {\n      \"id\": \"7891023\"\n    },\n    {\n      \"id\": \"987654\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update deals"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can update deals individually or in batches. For existing deals, the deal ID is a default unique value that you can use to update the deal via API, but you can also identify deals using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "custom unique identifier properties."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To update an individual deal by its record ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/{dealId}"
        }), ", and include the data you want to update."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To update multiple deals, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/batch/update"
        }), ". In the request body, include an array with the identifiers for the deals and the properties you want to update."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associate existing deals with records or activities"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate a deal with other CRM records or an activity, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To retrieve the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), " value, refer to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
          children: "this list"
        }), " of default values, or make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about associating records with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remove an association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove an association between a deal and a record or activity, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to the following URL: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pin an activity on a deal record"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can pin an activity on a deal record via API by including the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " field in your request. In the field, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " of the activity to pin, which can be retrieved via the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "engagements APIs"
      }), ". You can pin one activity per record, and the activity must already be associated with the deal prior to pinning."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To set or update a deal's pinned activity, your request could look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/deals/{dealId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also create a deal, associate it with an existing activity, and pin the activity in the same request. For example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/deals\n{\n  \"properties\": {\n    \"dealname\": \"New deal\",\n    \"pipelines\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 213\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete deals"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can delete deals individually or in batches, which will add the deal to the recycling bin in HubSpot. You can later ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "restore the deal within HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete an individual deal by its ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch deleting deals in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/objects/deals",
        children: "endpoint reference documentation"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}