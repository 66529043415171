"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816705801;
const slug = exports.slug = 'guides/apps/public-apps/overview';
const title = exports.title = 'Applications publiques';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Creating and installing apps';
const metaDescription = exports.metaDescription = 'Découvrez comment créer et installer une application dans HubSpot. Les applications sont des intégrations qui peuvent être installées sur des comptes clients via un processus de connexion OAuth.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-une-application-publique",
  "label": "Créer une application publique",
  "parent": null
}, {
  "depth": 1,
  "id": "configurer-des-domaines",
  "label": "Configurer des domaines",
  "parent": "cr%C3%A9er-une-application-publique"
}, {
  "depth": 2,
  "id": "types-de-domaine",
  "label": "Types de domaine",
  "parent": "cr%C3%A9er-une-application-publique"
}, {
  "depth": 2,
  "id": "configurer-les-domaines-de-votre-application-publique",
  "label": "Configurer les domaines de votre application publique",
  "parent": "cr%C3%A9er-une-application-publique"
}, {
  "depth": 0,
  "id": "installer-une-application",
  "label": "Installer une application",
  "parent": null
}, {
  "depth": 1,
  "id": "autoriser-votre-application-avec-un-compte-client",
  "label": "Autoriser votre application avec un compte client",
  "parent": "installer-une-application"
}, {
  "depth": 1,
  "id": "g%C3%A9n%C3%A9rer-les-jetons-oauth-initiaux",
  "label": "Générer les jetons OAuth initiaux",
  "parent": "installer-une-application"
}, {
  "depth": 0,
  "id": "g%C3%A9rer-les-applications-publiques-dans-hubspot",
  "label": "Gérer les applications publiques dans HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "find-an-app-s-id",
  "label": "Find an app's ID",
  "parent": "g%C3%A9rer-les-applications-publiques-dans-hubspot"
}, {
  "depth": 1,
  "id": "suivre-le-comportement-de-l-application",
  "label": "Suivre le comportement de l'application",
  "parent": "g%C3%A9rer-les-applications-publiques-dans-hubspot"
}, {
  "depth": 0,
  "id": "ajouter-un-domaine-v%C3%A9rifi%C3%A9",
  "label": "Ajouter un domaine vérifié",
  "parent": null
}, {
  "depth": 1,
  "id": "ajouter-un-domaine-v%C3%A9rifi%C3%A9",
  "label": "Ajouter un domaine vérifié",
  "parent": "ajouter-un-domaine-v%C3%A9rifi%C3%A9"
}, {
  "depth": 2,
  "id": "notes-suppl%C3%A9mentaires",
  "label": "Notes supplémentaires",
  "parent": "ajouter-un-domaine-v%C3%A9rifi%C3%A9"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      ul: "ul",
      li: "li",
      h2: "h2",
      strong: "strong",
      img: "img",
      h3: "h3",
      code: "code",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Applications publiques"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans HubSpot, une application publique est un type d'intégration qui peut être installé sur les comptes de clients ou listé sur le marketplace des applications. Elle nécessite une authentification via ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), ". Une fois qu'un utilisateur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#installing-apps",
        children: "installe"
      }), " votre application sur son compte HubSpot, vous pourrez effectuer des appels d'API vers ce compte en utilisant un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "jeton d'accès OAuth"
      }), ". Votre application apparaîtra également dans les paramètres ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Applications connectées"
      }), " du compte."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les applications connectées peuvent également tirer parti de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "abonnement aux modifications via les webhooks"
      }), " et de la création d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "événements chronologiques"
      }), " personnalisés."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ci-dessous, découvrez comment :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-public-app",
          children: "créer une application publique"
        }), " ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#install-an-app",
          children: "installer une application publique dans un compte"
        }), " ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#manage-public-apps-in-hubspot",
          children: "gérer l'application, y compris la surveillance de l'utilisation"
        }), " ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "ajouter un domaine vérifié à l'application"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer une application publique"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez une application dans HubSpot, vous associez principalement une application que vous avez conçue à un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "compte de développeur d'applications"
      }), ". Pour commencer à créer votre application HubSpot :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de développeur d'applications, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " dans la barre de navigation."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer"
        }), "dans l'angle supérieur droit."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ensuite, vous devrez renseigner certaines informations et paramètres de base pour votre application. Lorsque les utilisateurs authentifient votre application avec leurs comptes HubSpot, ils verront le nom, la description, le logo et toute coordonnée de support que vous fournissez sur cette page."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : Le nom de l'application sera utilisé partout où votre application s'affichera dans HubSpot. C'est le cas notamment lors de l'installation de l'application ainsi que dans le pied de page ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Géré par"
        }), " pour les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "cartes CRM"
        }), " et les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/timeline",
          children: "événements de chronologie"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/new_app_setup.png?width=600&name=new_app_setup.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authentification"
        }), " pour afficher votre ID et votre secret client ainsi que les périmètres attribués à l'application. Vous aurez besoin de ces informations pour ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "lancer une connexion OAuth"
        }), " entre votre application et HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/app_auth_settings.png?width=600&name=app_auth_settings.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurer des domaines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les domaines déterminent les autorisations de votre application pour accéder aux sources de données ou aux outils dans un compte qui a installé votre application. Les domaines que vous configurez apparaîtront comme paramètres de requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scope"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), " dans une URL d'installation que vous pourrez ensuite fournir aux utilisateurs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Types de domaine"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Authentification"
      }), ", vous pouvez configurer trois types de domaine différents. Vous devez spécifier les domaines dont votre application aura besoin pour l'installation, mais vous pouvez également spécifier deux autres types de domaine : les domaines conditionnellement requis et les domaines facultatifs."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Domaines requis :"
        }), " les domaines qui doivent être autorisés par l'utilisateur ", (0, _jsxRuntime.jsx)("u", {
          children: "et"
        }), " présents dans le paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " dans l'URL d'installation de votre application pour une installation réussie."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Domaines conditionnellement requis :"
        }), " les domaines qui doivent être autorisés par l'utilisateur uniquement s'ils sont présents dans le paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " dans l'URL d'installation de votre application pour une installation réussie.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ce type de domaine vous permet d'être flexible et de fournir une URL d'installation distincte pour les fonctionnalités hiérarchisées ou les domaines qui ne sont requis que lorsque les utilisateurs activent certaines fonctionnalités dans votre application. Par exemple, vous pouvez proposer deux URL d'installation à vos utilisateurs : une URL d'installation peut inclure le domaine conditionnellement requis dans le paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            }), " pour les utilisateurs ayant accès à une fonctionnalité, et une autre URL d'installation qui omet ce domaine dans le paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            }), " pour les utilisateurs sans accès."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si un domaine conditionnellement requis est présent dans l'URL d'installation de votre application et qu'un utilisateur sans accès à la fonctionnalité associée tente d'installer votre application à l'aide de cette URL, l'installation échouera."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Domaines facultatifs :"
        }), " les domaines qui ", (0, _jsxRuntime.jsx)("u", {
          children: "ne sont pas"
        }), " nécessaires pour installer votre application avec succès. Ces domaines sont spécifiés dans le paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " dans l'URL d'installation de votre application. Par exemple, si vous souhaitez que votre application puisse récupérer des données d'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "objet personnalisé"
        }), " (qui ne sont disponibles que pour les comptes ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Entreprise"
        }), " HubSpot), vous pouvez ajouter le domaine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.custom.read"
        }), " en tant que domaine facultatif. Ensuite, si un compte a accès aux objets personnalisés, le domaine sera autorisé. Autrement, il pourra toujours installer l'application sans le domaine pour les objets personnalisés."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Configurer les domaines de votre application publique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour personnaliser vos paramètres de domaine et ajouter de nouveaux domaines :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour configurer des domaines conditionnellement requis ou facultatifs, cliquez sur le bouton ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activer les paramètres de domaine avancés"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/turn-on-advanced-scope-settings-toggle-in-public-app-auth-tab.png",
        alt: "turn-on-advanced-scope-settings-toggle-in-public-app-auth-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " À partir du 21 octobre 2024, des paramètres de domaine avancés seront requis pour toutes les applications. Découvrez-en davantage dans le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/advanced-auth-and-scope-settings-for-public-apps",
          children: "changelog pour les développeurs"
        }), " de HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Domaines"
        }), ", cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ajouter un nouveau champ d'application"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le panneau de droite, utilisez la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "barre de recherche"
        }), " pour rechercher un domaine, puis cochez la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "case"
        }), " à côté du domaine que vous souhaitez que l'utilisateur autorise. Si vous avez activé les paramètres de domaine avancés, cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "menu déroulant"
        }), " à côté du domaine et sélectionnez un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "type de domaine"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mettre à jour"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/add-new-scope-panel-in-public-app-setup.png",
        alt: "add-new-scope-panel-in-public-app-setup"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vérifiez vos domaines configurés. Si vous avez activé les paramètres de domaine avancés, vous pouvez changer le type de domaine de n'importe quel domaine en cliquant sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "menu déroulant"
        }), " à côté du domaine. Vous pouvez également cliquer sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Supprimer"
        }), " pour supprimer l'un des domaines de votre application."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/review-new-scope-settings-in-public-app-setup.png",
        alt: "review-new-scope-settings-in-public-app-setup"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Une fois que vous avez terminé de configurer les paramètres de votre application, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer une application"
        }), " en bas à gauche."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois votre application créée, vous pourrez suivre le processus d'installation."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Il est recommandé d'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "ajouter un domaine vérifié"
        }), " à l'application pour ajouter un autre niveau de confiance aux utilisateurs qui installent votre application. Sinon, l'application affichera une bannière indiquant que l'application n'est pas vérifiée."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Installer une application"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Avant d'installer votre application, tenez compte des éléments suivants."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Une application ne s'affiche pas sur la page ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Applications connectées"
          }), " d'un compte tant que l'accès initial et les jetons d'actualisation ne sont pas créés."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Seuls les utilisateurs disposant d'un accès aux domaines requis ou conditionnellement requis de l'application peuvent installer une application."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les applications ne peuvent pas être installées sur des comptes de développeur. Pour tester votre application, vous devez créer un ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types",
            children: "compte de test"
          }), " dans votre compte de développeur d'applications et l'installer dessus."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'installation d'une application peut être divisée en deux étapes : l'autorisation et la génération d'un jeton."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Autoriser votre application avec un compte client"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour autoriser votre application avec un compte HubSpot, vous devez créer une URL d'autorisation. Pour cela, vous devez obtenir l'ID de client pour votre application et", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "lancer le processus OAuth"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Une fois que votre URL est prête, ouvrez-la dans votre navigateur pour afficher une liste de tous vos comptes HubSpot C'est également cela que les utilisateurs verront une fois que vous aurez commencé à les rediriger vers cette URL."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "compte"
        }), " sur lequel vous souhaitez installer votre application."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/select_account-1.webp",
        alt: "select_account-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Après avoir choisi un compte, vous disposerez d'une liste de périmètres d'accès en fonction des paramètres ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&scope="
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&optional_scope="
        }), " définis pour l'URL d'autorisation."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si vous ajoutez un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " et que le compte sélectionné n'y a pas accès (comme la portée de contenu pour un compte CRM uniquement), il ne sera pas répertorié."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Accorder l'accès"
        }), " pour autoriser la connexion."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/approve_scopes-1.webp",
        alt: "approve_scopes-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Après avoir autorisé l'accès, vous serez redirigé en fonction du paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&redirect_uri="
        }), " dans l'URL d'autorisation d'origine et un paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?code="
        }), " sera ajouté à l'URL. Utilisez ce code à l'étape suivante pour générer un jeton d'accès."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Générer les jetons OAuth initiaux"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour générer les jetons d'actualisation et d'accès initiaux, vous aurez besoin du code du paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?code="
      }), " de l'URL d'autorisation, de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "redirect_url"
      }), " ainsi que de l'ID et du secret de client. Des instructions détaillées sont disponibles ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous avez autorisé votre application et généré les jetons initiaux, l'installation est terminée. L'application sera répertoriée sur votre page ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/integrations-beta",
        children: "Applications connectées"
      }), " et vous commencerez à obtenir des demandes d'indexation (fetch) de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "webhook"
      }), " et de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "cartes CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/connected_apps-1.webp",
        alt: "connected_apps-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gérer les applications publiques dans HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Find an app's ID"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez trouver l'ID d'une application publique dans votre compte de développeur d'application en utilisant l'une des méthodes ci-dessous :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de développeur, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applications"
        }), " dans la barre de navigation principale, puis affichez l'", (0, _jsxRuntime.jsx)(_components.em, {
          children: "ID d'application"
        }), " répertorié sous le nom de votre application."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id.png",
        alt: "find-app-id"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de développeur, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applications"
        }), " dans la barre de navigation principale, puis cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " de l'application. Sur la page ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informations de base"
        }), ", cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authentification"
        }), ", puis consultez l'", (0, _jsxRuntime.jsx)(_components.em, {
          children: "ID de l'application"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id-auth-settings.png",
        alt: "find-app-id-auth-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Suivre le comportement de l'application"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot enregistre toutes les demandes effectuées vers ou à partir d'une application connectée, y compris les demandes entrantes utilisant un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "jeton d'accès OAuth"
      }), " ou les demandes sortantes pour des webhooks ou des cartes de CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour afficher ce journal des demandes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de développeur, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " dans la barre de navigation."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " de l'application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suivi"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilisez les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "onglets"
        }), " pour afficher les différents types de demandes effectuées vers ou à partir de l'application. Lors de l'affichage de ces journaux, vous pouvez cliquer sur une demande pour afficher plus d'informations à son sujet, y compris :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["pour les requêtes ", (0, _jsxRuntime.jsx)("u", {
              children: "réussies"
            }), ", la méthode de requête, le chemin et la durée ;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["pour les requêtes ", (0, _jsxRuntime.jsx)("u", {
              children: "infructueuses"
            }), ", des informations d'erreur supplémentaires telles que l'en-tête et le corps de la réponse."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/request_details.webp",
        alt: "request_details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez ci-dessous chaque onglet de la page ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Suivi"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Appels d'API :"
        }), " l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Appels d'API"
        }), " répertorie toutes les requêtes concernant votre application via un jeton d'accès OAuth. Il peut être filtré par méthode HTTP, par code de réponse, par période ou par URL de demande."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks :"
        }), " l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Webhooks"
        }), " affiche les requêtes HubSpot pour les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "souscriptions aux webhooks"
        }), " de votre application. Filtrez par réponse (y compris les délais d'attente dépassés et les échecs de connexion), par statut (succès, nouvelle tentative ou échec), par type de souscription, par période, par tentative, par lot, par événement ou par ID de compte."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " L'ID de tentative sera une combinaison de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriptionId"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "eventId"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "attemptNumber"
        }), " de la requête en question."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Extensions de CRM :"
        }), " l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Extensions de CRM"
        }), " affiche les requêtes pour les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "cartes de CRM"
        }), " de votre application. Filtrez par type d'fiche d'informations d'extension, par type d'objet de CRM (contact, entreprise, ticket ou transaction), par type d'erreur ou d'avertissement, par période, par ID de requête ou par ID d'objet de CRM (par exemple, ID de contact spécifique)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Paramètres de l'application :"
        }), " l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Paramètres de l'application"
        }), " vous permet de configurer la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/create-an-app-settings-page",
          children: "page de paramètres"
        }), " fournie avec votre application."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sur chaque onglet, si des événements associés se sont produits au cours des 30 derniers jours (par exemple, un déclencheur de webhook s'est produit ou un appel d'API a été effectué), vous pouvez cliquer sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exporter les journaux"
      }), " pour exporter les données d'événements associés vers un CSV :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dans la boîte de dialogue, configurez le nombre de jours de données à exporter (jusqu'à 30 jours)."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exporter"
        }), ". Une notification par e-mail sera envoyée à l'adresse e-mail associée à votre utilisateur dans vos paramètres HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ajouter un domaine vérifié"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque les utilisateurs de HubSpot installent une application, ils consentent à donner accès aux données de leur compte au développeur de l'application. L'identité et la réputation du développeur jouent un rôle important dans la décision d'un utilisateur de poursuivre l'installation. Pour garantir le consentement total de l'utilisateur lors de l'installation d'une application, HubSpot affichera un message sur l'écran d'installation de l'application pour indiquer le niveau de vérification de l'application et le listing sur le marketplace des applications :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Lorsqu'une application n'a pas de domaine vérifié, HubSpot affichera une bannière sur l'écran d'installation indiquant que l'application n'a pas été vérifiée."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/not-verified.png",
            alt: "not-verified"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Lorsque l'application a un domaine vérifié mais n'est pas ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "répertoriée sur le marketplace des applications"
          }), ", HubSpot affichera le domaine vérifié ainsi qu'une bannière sur l'écran d'installation indiquant que l'application n'a pas été évaluée ou approuvée par HubSpot."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-not-listed.png",
        alt: "verified-not-listed"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Lorsqu'une application est répertoriée sur le marketplace et a passé le processus d'évaluation des applications de HubSpot, HubSpot n'affichera aucune des bannières ci-dessus. Vous n'êtes pas tenu de vérifier le domaine si votre application est répertoriée sur le marketplace des applications."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-and-listed.png",
            alt: "verified-and-listed"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ajouter un domaine vérifié"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ajouter un domaine vérifié à l'application, vous devrez d'abord ajouter le domaine aux paramètres de l'application, puis ajouter un enregistrement TXT aux paramètres DNS du domaine :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de développeur d'applications, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " de l'application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contact et support"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le champ ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Domaine de l'entreprise"
        }), ", saisissez votre domaine, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enregistrer"
        }), ". Un message apparaîtra sous le ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "domaine de l'entreprise"
        }), " indiquant que le domaine n'a pas encore été vérifié."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vérifier maintenant"
        }), " pour commencer le processus de vérification."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/domain-verification-for-app.png",
        alt: "domain-verification-for-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le panneau de droite, confirmez que le domaine a été saisi correctement, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Suivant"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Copiez la valeur d'enregistrement TXT requise en cliquant sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copier"
          }), " dans la colonne ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Valeur"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verify-app-domain-copy-value.png",
            alt: "verify-app-domain-copy-value"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Dans votre fournisseur DNS, créez un enregistrement TXT avec la valeur copiée. Vous trouverez ci-dessous des instructions sur certains fournisseurs DNS courants :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.godaddy.com/help/add-a-txt-record-19232",
              children: "GoDaddy"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://my.bluehost.com/hosting/help/559#add",
              children: "BlueHost"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.namecheap.com/support/knowledgebase/article.aspx/317/2237",
              children: "Namecheap"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/360019093151-Managing-DNS-records-in-Cloudflare#h_60566325041543261564371",
              children: "Cloudflare"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.hover.com/hc/en-us/articles/217282457-How-to-Edit-DNS-records-A-AAAA-CNAME-MX-TXT-SRV-",
              children: "Hover"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.name.com/support/articles/115004972547-Adding-a-TXT-Record",
              children: "Name"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.uniteddomains.com/hc/en-us/articles/115000887125-How-to-set-up-a-TXT-record-on-a-domain-name",
              children: "United Domains"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Après avoir mis à jour vos paramètres DNS, revenez à HubSpot, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Suivant"
          }), " dans le panneau de droite. La mise à jour des enregistrements DNS peut prendre jusqu'à 48 heures. Par conséquent, les modifications peuvent ne pas être immédiatement reconnues par HubSpot. Vous pouvez revenir à cet écran à tout moment en sélectionnant à nouveau ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Vérifier maintenant"
          }), " à partir de la page de paramètres ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Informations sur l'entreprise"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Une fois la vérification effectuée, un indicateur de réussite apparaîtra dans le champ ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Domaine de l'entreprise"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Domain%20verified__export.png",
        alt: "Domain verified__export"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Notes supplémentaires"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pour assurer la propriété continue du domaine, HubSpot continuera de vérifier régulièrement la présence de l'enregistrement TXT. L'avertissement d'installation reviendra si l'enregistrement TXT est supprimé ou modifié."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Actuellement, vous ne pouvez avoir qu'un seul domaine vérifié par compte de développeur. Toutes les applications d'un compte partagent le domaine vérifié. Le domaine sur la page d'installation sera lié à votre domaine racine."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous supprimez votre domaine vérifié, toutes les applications de votre compte de développeur recevront à nouveau l'avertissement d'installation. Vous pouvez vérifier un autre domaine, mais le processus prendra quelques heures."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}