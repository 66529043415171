"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 142116065450;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-definitions';
const title = exports.title = 'Definir eventos personalizados';
const name = exports.name = 'Definir eventos personalizados';
const metaDescription = exports.metaDescription = 'Saiba mais sobre como criar eventos personalizados usando a API da HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-uma-defini%C3%A7%C3%A3o-de-evento",
  "label": "Criar uma definição de evento",
  "parent": null
}, {
  "depth": 0,
  "id": "propriedades-de-evento",
  "label": "Propriedades de evento",
  "parent": null
}, {
  "depth": 1,
  "id": "propriedades-de-evento-padr%C3%A3o-do-hubspot",
  "label": "Propriedades de evento padrão do HubSpot",
  "parent": "propriedades-de-evento"
}, {
  "depth": 1,
  "id": "definir-novas-propriedades",
  "label": "Definir novas propriedades",
  "parent": "propriedades-de-evento"
}, {
  "depth": 1,
  "id": "atualizar-propriedades-personalizadas-existentes",
  "label": "Atualizar propriedades personalizadas existentes",
  "parent": "propriedades-de-evento"
}, {
  "depth": 1,
  "id": "excluir-uma-propriedade",
  "label": "Excluir uma propriedade",
  "parent": "propriedades-de-evento"
}, {
  "depth": 0,
  "id": "atualizar-um-evento",
  "label": "Atualizar um evento",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-um-evento",
  "label": "Excluir um evento",
  "parent": null
}, {
  "depth": 0,
  "id": "obter-defini%C3%A7%C3%B5es-de-eventos-existentes",
  "label": "Obter definições de eventos existentes",
  "parent": null
}, {
  "depth": 0,
  "id": "exemplo-de-evento-personalizado",
  "label": "Exemplo de evento personalizado",
  "parent": null
}, {
  "depth": 1,
  "id": "criar-defini%C3%A7%C3%A3o-de-evento-personalizado",
  "label": "Criar definição de evento personalizado",
  "parent": "exemplo-de-evento-personalizado"
}, {
  "depth": 1,
  "id": "criar-propriedade-na-defini%C3%A7%C3%A3o-de-evento-personalizado",
  "label": "Criar propriedade na definição de evento personalizado",
  "parent": "exemplo-de-evento-personalizado"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      ul: "ul",
      li: "li",
      pre: "pre",
      h3: "h3",
      ol: "ol",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      ProductTier,
      Table,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Definir eventos personalizados"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os eventos personalizados permitem que você defina e rastreie eventos exclusivos da sua empresa, como eventos no seu site ou em um aplicativo. Você pode configurar eventos para armazenar informações nas propriedades, que podem ser usadas nas ferramentas do HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar dados de um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "evento personalizado"
      }), " para o HubSpot, primeiro você precisa definir o evento. Esse processo é semelhante aos objetos personalizados do CRM, onde você primeiro define o objeto personalizado e depois cria registros individuais para esse objeto. Uma definição de evento inclui detalhes como metadados, associações de objetos do CRM e propriedades."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abaixo, saiba mais sobre como criar e gerenciar definições de eventos usando a API. Para saber como criar definições de eventos sem usar a API, confira a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events-with-the-code-wizard",
        children: "Central de conhecimento da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar uma definição de evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar o esquema de evento personalizado, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions"
      }), ". No corpo da solicitação, inclua as definições do seu esquema de evento, incluindo rótulo, nome, associações de objetos do CRM e propriedades personalizadas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja abaixo um exemplo de corpo de solicitação, juntamente com as definições dos parâmetros. Para um exemplo completo, confira o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#custom-event-example",
        children: "exemplo de evento personalizado abaixo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "label"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (obrigatório)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "O rótulo legível do evento que será exibido no HubSpot (até 100 caracteres). Rótulos longos podem ser cortados em certas partes da interface do HubSpot."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "name"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["O nome interno exclusivo do evento que você usará para fazer referência ao evento por meio da API. Se nenhum valor for fornecido, o HubSpot gerará um automaticamente com base em ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "label"
                    }), ". Esta propriedade ", (0, _jsxRuntime.jsx)("u", {
                      children: "não"
                    }), " poderá ser alterada depois que a definição do evento for criada."]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Observe os seguintes requisitos de nomenclatura:"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "Esta propriedade somente pode conter letras minúsculas, números, sublinhados e hifens (até 50 caracteres)."
                    }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "O primeiro caractere deve ser uma letra."
                    }), "\n"]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "description"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "A descrição do evento que será exibida no HubSpot."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "primaryObject"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["O objeto do CRM ao qual o evento será associado. As conclusões do evento serão exibidas nas linhas do tempo dos registros do CRM para o objeto especificado. Pode ser um dos seguintes: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "CONTACT"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "COMPANY"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "DEAL"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "TICKET"
                    }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "CUSTOM OBJECT NAME"
                    }), "."]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Se nenhum valor for fornecido, o HubSpot associará automaticamente o evento aos contatos. Isso ", (0, _jsxRuntime.jsx)("u", {
                      children: "não"
                    }), " poderá ser alterado depois que a definição do evento for criada."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.strong, {
                      children: "Matriz"
                    }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "propertyDefinitions"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Para cada definição de evento personalizado, o HubSpot fornece um conjunto de propriedades padrão que você pode usar para enviar dados de eventos. Inclua essa matriz para definir as propriedades personalizadas exigidas pelo seu evento além dessas propriedades padrão. Você pode incluir até 50 propriedades personalizadas por evento."
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Para cada propriedade, inclua:"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "label"
                      }), ": o rótulo da propriedade no aplicativo."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "name"
                      }), ": o nome interno da propriedade que você usará ao enviar dados da propriedade por meio da API."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "type"
                      }), ": o ", (0, _jsxRuntime.jsx)(_components.a, {
                        href: "#custom-property-types",
                        children: "tipo de propriedade"
                      }), ". O padrão é ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "string"
                      }), "."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "options"
                      }), ": para propriedades ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "enumeration"
                      }), ", a matriz de valores predefinidos, incluindo um ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "label"
                      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "value"
                      }), " para cada um deles."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "description"
                      }), ": texto opcional para descrever a propriedade."]
                    }), "\n"]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "#event-properties",
                      children: "propriedades de eventos personalizados"
                    }), " abaixo."]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request body\n{\n  \"label\": \"My event label\",\n  \"name\": \"unique_event_name\",\n  \"description\": \"An event description that helps users understand what the event tracks.\",\n  \"primaryObject\": \"COMPANY\",\n  \"propertyDefinitions\": [\n    {\n      \"name\": \"choice-property\",\n      \"label\": \"Choice property\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"label\": \"Option 1\",\n          \"value\": \"1\"\n        },\n        {\n          \"label\": \"Option 2\",\n          \"value\": \"2\"\n        }\n      ]\n    },\n    {\n      \"name\": \"string-property\",\n      \"label\": \"String property\",\n      \"type\": \"string\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriedades de evento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As propriedades de evento personalizado são usadas para armazenar informações sobre conclusões de eventos personalizados individuais. Elas devem ser usadas quando apropriado para enviar conclusões de eventos, mas não são obrigatórias para que uma conclusão de evento seja válida. Para cada definição de evento, o HubSpot fornece um conjunto padrão de 32 propriedades. Além disso, você pode criar até 50 propriedades personalizadas por definição de evento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As propriedades podem ter os seguintes tipos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), ": uma propriedade que recebe strings de texto simples. Se o nome da propriedade contiver as palavras ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "referrer"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "link"
        }), ", o valor da propriedade poderá ter até 1.024 caracteres. Caso contrário, poderá ter até 256 caracteres."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        }), ": propriedade que recebe valores numéricos com até uma casa decimal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), ": Uma propriedade com opções predefinidas. Ao criar esse tipo de propriedade, inclua uma matriz ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), " para definir os valores disponíveis."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "datetime"
        }), ": Uma propriedade que recebe valores de milissegundos de era ou ISO8601 que representam uma data/hora."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abaixo, saiba mais sobre as propriedades de evento padrão do HubSpot, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#define-new-properties",
        children: "definir novas propriedades para eventos existentes"
      }), " e como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#update-existing-custom-properties",
        children: "atualizar propriedades de eventos personalizados existentes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriedades de evento padrão do HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_asset_description"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_asset_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_browser"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_campaign_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_city"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_country"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_device_name"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_device_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_class"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_text"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_language"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_link_href"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_operating_system"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_operating_version"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_content_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_title"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_url"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_parent_module_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_referrer"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_region"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_screen_height"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_screen_width"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_touchpoint_source"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_tracking_name"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_user_agent"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_campaign"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_content"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_medium"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_source"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_term"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir novas propriedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para definir uma nova propriedade em um evento personalizado existente, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property"
      }), ". No corpo da solicitação, inclua a definição da sua propriedade."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"name\": \"property-name\",\n  \"label\": \"Property name\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"label\": \"label\",\n      \"value\": \"value\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao nomear sua propriedade, tenha em mente o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Depois de criar uma propriedade, o nome da propriedade não poderá ser alterado."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O nome somente pode conter letras minúsculas, números, sublinhados e hifens."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O primeiro caractere do nome da propriedade deve ser uma letra."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O nome e o rótulo da propriedade podem ter até 50 caracteres cada."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se um nome de propriedade não for fornecido, um será gerado automaticamente com base no rótulo da propriedade."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rótulos longos podem ser cortados em certas partes da interface do HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Atualizar propriedades personalizadas existentes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para atualizar uma propriedade existente em um evento personalizado, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property"
      }), ". Os únicos campos que podem ser atualizados em uma propriedade são ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), " para propriedades de enumeração."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " para alterar o tipo de propriedade, utilize o ponto de extremidade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), " para excluir a propriedade e recriá-la com o tipo correto."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Excluir uma propriedade"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir uma propriedade existente em um evento personalizado, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property/{propertyName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando uma propriedade é excluída, ela não pode ser usada em conclusões de eventos futuros. Conclusões anteriores ainda terão os valores das propriedades."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " ao excluir um evento:"]
      }), (0, _jsxRuntime.jsxs)(_components.ol, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Todos os eventos dessa definição de evento serão excluídos e não poderão ser recuperados."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "eventName"
          }), "'S excluídos anteriormente não podem ser usados novamente."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizar um evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os únicos campos que podem ser atualizados em uma definição de evento são ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para atualizar um esquema de evento personalizado existente, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir um evento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando você exclui um evento personalizado, as referências a esse evento em outras ferramentas do HubSpot, como fluxos de trabalho e relatórios, deixarão de usar o evento."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir um evento personalizado, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obter definições de eventos existentes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para buscar uma definição de evento único, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para pesquisar definições de eventos por critérios específicos, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions"
      }), ". Você pode fornecer os seguintes parâmetros de consulta para refinar sua pesquisa:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "searchString"
        }), ": pesquisa eventos que contenham os caracteres especificados no campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ". A pesquisa ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " é flexível, mas sim uma pesquisa simples de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "contém"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        }), ": uma string com hash fornecida nas respostas paginadas para visualização da próxima página de resultados de pesquisa."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), ": o número máximo de resultados a serem retornados."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "includeProperties"
        }), ": um valor booleano que especifica se as propriedades do evento devem ser incluídas nos resultados retornados."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemplo de evento personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja a seguir um passo a passo de como criar um evento personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este passo a passo abrange:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "como criar uma definição de evento personalizado."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "como criar uma nova propriedade na definição do evento."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Meta:"
      }), " uma concessionária de automóveis chamada CarSpot possui um inventário online de todos os carros à venda. A CarSpot deseja rastrear quando os visitantes de seu site visualizam um carro específico disponível para venda. Para isso, eles criam um evento personalizado, que associarão aos registros de contato para rastrear quem visualiza a listagem. Este evento também precisará de um conjunto de propriedades personalizadas para armazenar os detalhes da listagem de carros visualizados durante cada evento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar definição de evento personalizado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de decidir como chamar o evento, quais propriedades incluir em cada evento acionado e o objeto do CRM ao qual desejam associar os eventos, eles criam o esquema do evento enviando uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/event-definitions"
      }), " com o seguinte corpo de solicitação:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for event definitions\n\n{\n  \"label\": \"Viewed Car\",\n  \"name\": \"viewed_car\",\n  \"description\": \"An event that fires when visitor views a car listing in the online inventory\",\n  \"primaryObject\": \"CONTACT\",\n  \"propertyDefinitions\": [\n    {\n      \"name\": \"condition\",\n      \"label\": \"Condition\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"label\": \"New\",\n          \"value\": \"new\"\n        },\n        {\n          \"label\": \"Used\",\n          \"value\": \"used\"\n        }\n      ]\n    },\n    {\n      \"name\": \"year\",\n      \"label\": \"Year\",\n      \"type\": \"number\"\n    },\n    {\n      \"name\": \"make\",\n      \"label\": \"Make\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"model\",\n      \"label\": \"Model\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"mileage\",\n      \"label\": \"Mileage\",\n      \"type\": \"number\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar propriedade na definição de evento personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Logo após implementar o evento em seu site, a CarSpot decide que gostaria de ver se o preço de listagem influenciaria a taxa de cliques. Para acompanhar isso, eles criam uma nova propriedade contendo o preço de listagem."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para definir uma nova propriedade, eles enviam uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/event-definitions/viewed_car/property"
      }), " com o seguinte corpo de solicitação:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for creating a property\n{\n  \"name\": \"price\",\n  \"label\": \"Price\",\n  \"type\": \"number\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com o evento personalizado definido, agora eles podem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "enviar dados de eventos para o HubSpot usando esta definição de evento personalizada"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}