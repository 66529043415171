"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358695;
const slug = exports.slug = 'guides/api/crm/understanding-the-crm';
const title = exports.title = 'CRM API | Understanding the CRM';
const name = exports.name = 'vNext Docs DP - Understanding the CRM';
const metaDescription = exports.metaDescription = 'HubSpot’s CRM is a system for managing customer relationships and data. Learn about CRM object types, properties, associations, owners, and engagements. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "objects",
  "label": "Objects",
  "parent": null
}, {
  "depth": 0,
  "id": "associations",
  "label": "Associations",
  "parent": null
}, {
  "depth": 1,
  "id": "activities-and-attachments",
  "label": "Activities and attachments",
  "parent": "associations"
}, {
  "depth": 1,
  "id": "data-syncing",
  "label": "Data syncing",
  "parent": "associations"
}, {
  "depth": 1,
  "id": "object-type-ids",
  "label": "Object type IDs",
  "parent": "associations"
}, {
  "depth": 0,
  "id": "batch-actions",
  "label": "Batch actions",
  "parent": null
}, {
  "depth": 0,
  "id": "properties",
  "label": "Properties",
  "parent": null
}, {
  "depth": 1,
  "id": "default-properties",
  "label": "Default properties",
  "parent": "properties"
}, {
  "depth": 1,
  "id": "custom-properties",
  "label": "Custom properties",
  "parent": "properties"
}, {
  "depth": 1,
  "id": "record-owners",
  "label": "Record owners",
  "parent": "properties"
}, {
  "depth": 0,
  "id": "unique-identifiers-and-record-ids",
  "label": "Unique identifiers and record IDs",
  "parent": null
}, {
  "depth": 1,
  "id": "default-unique-identifiers",
  "label": "Default unique identifiers",
  "parent": "unique-identifiers-and-record-ids"
}, {
  "depth": 1,
  "id": "creating-your-own-unique-identifiers",
  "label": "Creating your own unique identifiers",
  "parent": "unique-identifiers-and-record-ids"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3",
      ul: "ul",
      li: "li",
      pre: "pre",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Understanding the CRM"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The foundation of your HubSpot account is a database of your business relationships\nand processes, called the CRM (Customer Relationship Management). To manage this\ndata, HubSpot accounts include objects, which represent types of relationships or\nprocesses. Individual instances of objects, called records, represent the individual\nentities under each object type (e.g., John Smith is a contact). To store data in\neach record, you'll use properties (e.g., the email property) and to represent the\nrelationships between individual entities, you can associate records with one another\n(e.g., associate John Smith with a company Smith & Co.) Further, CRM records can\nalso store information about interactions through associated engagements/activities,\nsuch as emails, calls, and meetings.Below, learn about CRM objects, records, properties,\nand additional functionalities. To learn more about managing your CRM database from\nwithin HubSpot, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/get-started/manage-your-crm-database",
        children: "HubSpot's Knowledge Base"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Objects API provides access to objects, records, and activities. For supported objects, you can use object endpoints and replace the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{objectTypeId}"
      }), " in the request URL with the desired object. For example, to create contacts, you'd make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/0-1"
      }), " and to create courses, the request would be to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/0-410"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": some objects have limited API functionality. For more details, click the name of an object to view its API reference article. If an object listed doesn't have its own article, you can refer to the objects API doc and substitute the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{objectTypeId}"
        }), " in each endpoint to your desired object."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type ID"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-2"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Companies"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Stores information about a business or organization. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "companies API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-1"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Stores information about an individual person. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "contacts API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-3"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent sales opportunities and transactions, tracked through pipeline stages. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "deals API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-5"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent customer requests for help or support, tracked through pipeline statuses. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/tickets",
              children: "tickets API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-421"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Appointments"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent encounters or services scheduled for an individual. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/objects/custom-objects",
              children: "objects API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-48"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Calls"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A type of activity that represents phone call interactions associated with your records. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/calls",
              children: "calls API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-18"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Communications"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A type of activity that represents SMS, LinkedIn, and WhatsApp message interactions associated with your records. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/communications",
              children: "communications API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-410"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Courses"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent structured programs or series of lessons, trainings, or educational modules. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/objects/custom-objects",
              children: "objects API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "2-XXX"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom objects"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Stores data that doesn't fit in with existing objects. To find the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            }), " for a custom object, make a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/schemas"
            }), ". View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/custom-objects",
              children: "custom objects API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-49"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Emails"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A type of activity that represents one-to-one email interactions associated with your records. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/email",
              children: "email API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-19"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feedback submissions"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Stores information submitted to a feedback survey. Feedback submissions are associated with contact records. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/feedback-submissions",
              children: "feedback submissions API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-52"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Invoices"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent the invoices sent for sales transactions. Invoices can be associated with contacts, companies, deals, line items, discounts, fees, and taxes. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/invoices",
              children: "invoices API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-136"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Leads"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent potential customers who have shown interest in your products or services. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/leads",
              children: "leads API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-8"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Line items"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent individual products and services sold in a deal. Line items can be created from existing products in your product library, or can be created standalone. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/line-items",
              children: "line items API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-420"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Listings"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent properties or units to be bought, sold, or rented. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/objects/custom-objects",
              children: "objects API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-54"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketing events"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent events related to your marketing efforts, specifically including events from connected integrations. You can specify whether or not a contact attended, registered for, or cancelled attending a marketing event. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "marketing events API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-47"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Meetings"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A type of activity that represents meeting interactions associated with your records. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/meetings",
              children: "meetings API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-46"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Notes"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A type of activity that represents notes associated with your records. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/notes",
              children: "notes API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-101"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Payments"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The payments made by buyers through invoices, payment links, and quotes. Payments can be associated with contacts, companies, deals, invoices, quotes, line items, subscriptions, discounts, fees, and taxes. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/payments",
              children: "payments API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-116"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Postal mail"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A type of activity that represents physical mail interactions associated with your records. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/postal-mail",
              children: "postal mail API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-7"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Products"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent goods or services for sale. Products can't be associated with other objects, but you can create line items based on products and associate those with deals and quotes. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "products API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-14"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quotes"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent pricing information shared with potential buyers. Quotes can be associated with contacts, companies, deals, and line items. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes",
              children: "quotes API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-162"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Services"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent intangible offerings provided to customers. Examples include onboarding and consulting, repairs and maintenance, and personal care. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/api/crm/objects/custom-objects",
              children: "objects API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-69"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscriptions"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent recurring payments scheduled through payment links and quotes. Invoices can be associated with contacts, companies, deals, quotes, line items, payments, discounts, fees, and taxes. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/subscriptions",
              children: "subscriptions API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-27"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tasks"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A type of activity that represents to-dos associated with your records. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/tasks",
              children: "tasks API"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-115"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Users"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Represent the users in your HubSpot account. Users cannot be associated with other objects, but can be retrieved and updated via API. View the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/settings/users/user-details",
              children: "users API"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot, you can show how objects are related to one another by associating their records. For example, you can associate multiple contacts with a company, and then associate the company and relevant contacts with a deal.When using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API endpoints"
      }), ", you can substitute objects for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{toObjectTypeId}"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{fromObjectTypeId}"
      }), " in the request URLs and request bodies. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/api/crm/associations-v4#retrieve-association-labels",
        children: "retrieving association types"
      }), " to understand which objects can be associated to one another. If you have access to a HubSpot account, you can also review your account's unique object relationships by navigating to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-model-of-your-crm-object-and-activity-relationships",
        children: "the data model tool."
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/data-model-overview-updated.gif",
        alt: "data-model-overview-updated"
      }), "Depending on your subscription, you can describe the specific relationship between records using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "association labels"
      }), ", and your account may have additional custom objects, which can be associated with the other standard objects. Other objects, such as products and line items, can only be associated with certain objects. Learn more about these ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-objects",
        children: "additional objects and their associations"
      }), ".Learn more about object relationships and managing associations using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations endpoints"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Activities and attachments"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Engagements"
      }), ", also called activities, store data from interactions with records. For example, if you call a prospect, you can log a call to the contact record, and also associate the call with their associated company. Possible activities include notes, tasks, meetings, emails, calls, postal mail, SMS, LinkedIn messages, and WhatsApp messages.You can also store attachments on records to keep track of relevant files. These are often related to engagements.Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "engagements APIs."
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Data syncing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Syncing engagement data is not required to sync object data. Because an object can be associated with numerous engagements, it’s also important to keep ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "API limits"
      }), " in mind before syncing.However, you may want to sync engagements rather than properties when an integration is a precursor to a full migration. In this case, syncing engagements across both systems will ensure all users have the data they need during the transition. For example, when a business development team working in HubSpot is handing deals to an inside sales rep working in another CRM, you should sync engagements so both teams have the context they need to close a sale."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Object type IDs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When using certain APIs, you'll need to use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypeId"
      }), " field. The object type ID values are listed in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#objects",
        children: "object table above"
      }), ". While you can always use the numerical ID value, in some cases, you can also use the object's name for contacts, companies, deals, tickets, or notes. For example:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When starting an import with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/imports",
          children: "imports API"
        }), ", the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), " specifies which object the data in your file belongs to. To import data for contacts, your value for ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), " could be ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4",
          children: "associations API"
        }), ", the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), " values specify the objects and the direction of the association. To view association types for contacts to companies, your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request URL could be ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/contact/company/labels"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/0-1/0-2/labels"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Batch actions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each object provides batch endpoints that let you create, read, update, and archive multiple object records in a single request. Batch endpoints have a limit of 100 records per call. For example, the following request would batch update two contact records identified by their ID.", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/batch/update"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"inputs\": [\n    {\n      \"id\": \"609051\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    },\n    {\n      \"id\": \"569751\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For batch create actions, you can enable multi-status errors which tell you which records were successfully created and which were not. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/other-resources/error-handling#multi-status-errors",
        children: "setting up multi-status error handling"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Information about records are stored in fields called properties, which are then organized into ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "groups"
      }), ". HubSpot provides a set of default properties for each object. In addition to each object’s default properties, you can store custom data by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "creating custom properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Default properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM objects are defined by a primary ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "type"
      }), " and a set of ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "properties"
      }), ". Each type has a unique set of standard properties, represented by a map of name-value pairs. Learn more about default properties for different objects:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "Contacts"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "Companies"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "Deals"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: "Feedback submissions"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties",
          children: "Invoices"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "Payments"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "Subscriptions"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Custom properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Create ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "custom properties"
      }), " to store specialized information for an object. Custom properties can be managed through the CRM object properties endpoints."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Record owners"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can assign HubSpot users as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: "owners"
      }), " of records. Any HubSpot user with access to an object can be assigned as an owner, and multiple owners can be assigned to an object by creating a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/how-can-i-create-a-custom-owner-property",
        children: "custom property"
      }), " for this purpose. Owners can only be created in HubSpot, but you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "owners endpoint"
      }), " to get their identifying details, including IDs and email addresses. This data can then be assigned to CRM records in HubSpot or via property change API calls."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unique identifiers and record IDs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A unique identifier is a value that differentiates one record in a database from another, even if they have otherwise identical information. For example, a database for a bank might have records for two people named John Smith. To avoid accidentally sending money to the wrong John Smith, each record is assigned a number as their unique ID.In HubSpot, you'll use these unique identifiers to send your data to the correct records and manage deduplication. Learn more about the ways that HubSpot handles deduplication in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/deduplication-of-contacts-companies-deals-tickets",
        children: "Knowledge Base"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Default unique identifiers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a record is created in HubSpot, a unique ID is automatically generated and should be treated as a string. These IDs are unique only within the object type, so there can be both a contact and company with the same ID. For contacts and companies, there are additional unique identifiers, including a contact's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " and a company's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " name."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Creating your own unique identifiers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In many cases, you can use the record ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ") generated by HubSpot to drive the logic of your integration. However, your data may require other properties with unique values, or there may be times when record ID either cannot be used or complicates the integration logic of your app. In these cases, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "create a custom unique identifier property via the Properties API."
      }), "Once you've created a custom unique ID property, you can use it in API calls to to identify and update specific records in the same way you can use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " for contacts, or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " for companies. For example, to retrieve a deal based on its value for a custom unique ID property, your request URL could look like: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}