"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937486;
const slug = exports.slug = 'guides/api/marketing/emails/transactional-emails';
const title = exports.title = 'Marketing API | Transactional Emails ';
const name = exports.name = 'vNext Docs DP - Transactional Emails';
const metaDescription = exports.metaDescription = 'An overview of the Transactional Email feature in HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "methods-for-sending-transactional-email",
  "label": "Methods for sending transactional email",
  "parent": null
}, {
  "depth": 0,
  "id": "smtp-api",
  "label": "SMTP API",
  "parent": null
}, {
  "depth": 1,
  "id": "create-a-new-smtp-api-token",
  "label": "Create a new SMTP API token",
  "parent": "smtp-api"
}, {
  "depth": 1,
  "id": "retrieving-smtp-tokens",
  "label": "Retrieving SMTP tokens",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "list-smtp-api-tokens-by-campaign",
  "label": "List SMTP API tokens by campaign",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "query-a-single-smtp-api-token",
  "label": "Query a single SMTP API token",
  "parent": "smtp-api"
}, {
  "depth": 3,
  "id": "response-details",
  "label": "Response details",
  "parent": "smtp-api"
}, {
  "depth": 1,
  "id": "manage-existing-tokens",
  "label": "Manage existing tokens",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "reset-password",
  "label": "Reset password",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "delete-a-token",
  "label": "Delete a token",
  "parent": "smtp-api"
}, {
  "depth": 1,
  "id": "log-in-to-hubspot-s-smtp-server",
  "label": "Log in to HubSpot's SMTP server",
  "parent": "smtp-api"
}, {
  "depth": 0,
  "id": "single-send-api",
  "label": "Single-send API",
  "parent": null
}, {
  "depth": 1,
  "id": "create-and-publish-your-email-template",
  "label": "Create and publish your email template",
  "parent": "single-send-api"
}, {
  "depth": 1,
  "id": "send-your-email-using-the-single-send-api",
  "label": "Send your email using the single-send API",
  "parent": "single-send-api"
}, {
  "depth": 1,
  "id": "request-properties",
  "label": "Request properties",
  "parent": "single-send-api"
}, {
  "depth": 2,
  "id": "emailid",
  "label": "emailId",
  "parent": "single-send-api"
}, {
  "depth": 2,
  "id": "message",
  "label": "message",
  "parent": "single-send-api"
}, {
  "depth": 2,
  "id": "contactproperties",
  "label": "contactProperties",
  "parent": "single-send-api"
}, {
  "depth": 2,
  "id": "customproperties",
  "label": "customProperties",
  "parent": "single-send-api"
}, {
  "depth": 1,
  "id": "query-the-status-of-an-email-send",
  "label": "Query the status of an email send",
  "parent": "single-send-api"
}, {
  "depth": 2,
  "id": "sendresult",
  "label": "sendResult",
  "parent": "single-send-api"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      strong: "strong",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      code: "code",
      h4: "h4",
      h5: "h5",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Transactional Email"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/email/transactional-email",
        children: "transactional email add-on"
      }), ", you can send emails over a dedicated IP address for commerce receipts, account updates, terms of service changes, and other essential business transactions."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Transactional emails are for relationship-based interactions, unlike ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/emails/marketing-emails",
        children: "marketing emails"
      }), ", which are typically used to promote content."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Methods for sending transactional email"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are three ways to implement transactional email:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
          children: "Set up transactional email in-app"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#smtp-api",
          children: "SMTP API"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#single-send-api",
          children: "Single-send API"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Method"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Overview"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Example use case"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "In-app transactional Email"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Create transactional emails using HubSpot's email editor.This provides the same benefits of standard HubSpot emails, such as smart content, personalization and templates.Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
              children: "setting up transactional emails in-app"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Send a policy update email to your customers with a link to a new policy page. This is a service update, not a marketing email, so you don't need to include subscription links (e.g CAN-SPAM links). You don't need to use any custom properties or info from external systems."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "SMTP API"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Send transactional email through your own site or app while also tracking email performance and create contact information within HubSpot. The optional ability to create contact information is based on the smtp token creation.Learn more in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#smtp-api",
              children: "SMTP API section below"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Send an account signup confirmation email from a separate transactional email system, while also tracking email performance and creating contacts in HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Single-send API"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A combination of in-app transactional email and SMTP API.Create transactional emails using HubSpot's email editor, and add custom external tokens to your email which you can send to HubSpot via the API.Learn more in the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#single-send",
              children: "single-send API below"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Send a purchase receipt email to your customer using HubSpot. The email is triggered when the purchase is made, and passes custom values from another system (e.g. purchased item and purchase total). In addition, track the performance of this email in HubSpot."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " any contacts who are CC'd on a transactional email will not be tracked and the email will not appear on the record timeline of the CC'd contact."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SMTP API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Transactional email sent using the SMTP API is automatically triggered by specific criteria, like making a purchase on an e-commerce website. This API integrates with any internal or third-party systems to both trigger the email and incorporate data stored outside of HubSpot (e.g. shipping info or purchase price). The email is sent from your system, but is wrapped with HubSpot tracking codes that allow full ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
        children: "engagement tracking and measurement"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To send an email using the SMTP API, you need to use an SMTP API token to get login credentials for the HubSpot SMTP server. Once you log in to the server, you can send the email over SMTP. If you haven't created any SMTP API tokens, you'll first need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#create-a-new-smtp-api-token",
        children: "generate a new token"
      }), ". If you've already created SMTP API tokens, learn about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-existing-smtp-api-tokens",
        children: "different methods for getting your tokens through the API"
      }), ". After getting your token, learn about how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "log in to HubSpot's SMTP server"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any domains you use as the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "From Address"
      }), " of your emails must be connected as an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/domains-and-urls/connect-your-email-sending-domain",
        children: "email sending domain"
      }), " in HubSpot. You will encounter an error if you send transactional emails through the SMTP API using a domain that isn't authorized to send on behalf of your HubSpot account."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": all methods in the SMTP API require an ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "OAuth token"
        }), " for authentication."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If you prefer, all of the methods below for creating, retrieving, deleting, and resetting passwords for SMTP API tokens can be done ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "within your HubSpot account"
        }), ", rather than the API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a new SMTP API token"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a new SMTP API token, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The request body must be a JSON-formatted object with the following properties:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indicates whether a contact should be created for email recipients."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": a name for the campaign associated with the SMTP API token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response includes an SMTP token object, which contains:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": username to log into the HubSpot SMTP server."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": email address of the user that sent the token creation request."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "password"
        }), ": the password for logging in to the HubSpot SMTP server."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": identifier assigned to the campaign provided in the token creation request."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": timestamp generated when a token is created."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indicates whether a contact should be created for email recipients."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": the name of the campaign associated with the token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your token created, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "log in to HubSpot's SMTP server"
      }), " using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "password"
      }), " values."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A token's password can only be retrieved at the time of creation. If you lose the password, or want to set a new password, you'll need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manage-existing-tokens",
        children: "reset the token's password"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " SMTP API tokens generated through the public API expire after 12 months. Once expired, they're automatically deleted. Tokens created directly ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "within your HubSpot account"
        }), " do ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " expire automatically."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieving SMTP tokens"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are the available methods of getting token data using the API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "List SMTP API tokens by campaign"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get either a list of tokens by campaign name, or get a single token by campaign ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You'll also need to include either a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "campaignName"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailCampaignId"
      }), " parameter with the request. You can find all request details in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/marketing/emails/transactional-emails",
        children: "transactional email endpoint reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Response details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response contains ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "paging"
      }), " as its top-level fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "results"
        }), ": a collection of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "SmtpApiTokenView"
        }), " containing:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), ": username to log into the HubSpot SMTP server."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            }), ": email address of the user that sent the token creation request."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "emailCampaignId"
            }), ": identifier assigned to the campaign provided in the token creation request."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            }), ": timestamp generated when a token is created."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createContact"
            }), ": indicates whether a contact should be created for email recipients."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "campaignName"
            }), ": the name of the campaign associated with the token."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "paging"
        }), ": contains a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "next.after"
        }), " field that can be used to request more results."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Query a single SMTP API token"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To query a single SMTP API token by ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Response details"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response includes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), ", which contains:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": username to log into the HubSpot SMTP server."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": email address of the user that sent the token creation request."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": identifier assigned to the campaign provided in the token creation request."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": timestamp generated when a token is created."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indicates whether a contact should be created for email recipients."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": the name of the campaign associated with the token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Manage existing tokens"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After creating tokens, you can reset a password or delete the token using the API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Reset password"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To reset a token password, make a POST request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}/password-reset"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response includes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), ", which contains:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": username to log into the HubSpot SMTP server."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": email address of the user that sent the token creation request."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": identifier assigned to the campaign provided in the token creation request."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": timestamp generated when a token is created."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indicates whether a contact should be created for email recipients."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": the name of the campaign associated with the token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Delete a token"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete a single SMTP API token, make a DELETE request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response does not include any content."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Log in to HubSpot's SMTP server"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below are the details for logging in to HubSpot's SMTP server, using the username (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ") and password provided by your token."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMTP Hostname:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you're not based in the EU, use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp.hubapi.com"
            }), " for the hostname."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you're based in the EU, use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp-eu1.hubapi.com"
            }), " for the hostname."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMTP Port:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "For STARTTLS, you can use port 25 or 587."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "For direct TLS, use port 465."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMTP User Name:"
        }), " provided in the ID field"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMTP Password:"
        }), " provided in the password field"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Single-send API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Single-Send API sends template emails created in the HubSpot email tool using a JSON-formatted ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request. Any emails sent through this API will be automatically associated with contact records based on email address. If there's no contact with a matching email address, a new contact with that email will be created. If you want to send emails without creating contacts, use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "SMTP API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create and publish your email template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Single-Send API sends template emails created in the HubSpot email tool using a JSON-formatted ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request. Any emails sent through this API will be automatically associated with contact records based on email address. If there's no contact with a matching email address, a new contact with that email will be created. If you want to send emails without creating contacts, use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "SMTP API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["First, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
        children: "set up your email in HubSpot"
      }), ". After you create the email, you can set the recipient details, including any contact or custom properties set up in the email template, in the body of the API request. Before you can make the API request, you'll need the ID of the email:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you leave the email drafted without publishing it, you can get the email ID from the URL when you're in the email editor. The ID is the final numeric value before the final slash character (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/"
        }), ") in the URL (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/email/{PORTAL_ID}/edit/{EMAIL_ID}/settings"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/email-id-for-drafted-single-send-api-email.png",
        alt: "email-id-for-drafted-single-send-api-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you publish your email, you can copy the email ID from the email details page."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-15%20at%201.00.37%20PM.png",
        alt: "Screen Shot 2020-04-15 at 1.00.37 PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " HubSpot does not save the HTML/JSON sent through this API. You can review the email template from the recipient contact's timeline, but if you want to keep a record of the email's contents, it's recommended to add a BCC to the email."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Send your email using the single-send API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To send an email with the Single-Send API, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/single-email/send"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response contains the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), ": the timestamp of when the send was requested."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), ": an identifier that can be used to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-the-status-of-an-email-send",
            children: "query the status of the send"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), ": the status of the send request. Includes ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Request properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The request body must be a JSON-formatted object with the following properties:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "message"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contactProperties"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "emailId"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailId"
      }), " field contains the transactional email's content ID, which can be found in HubSpot's email tool."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "message"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The message field is a JSON object containing anything that you want to override. At the minimum, you must include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "to"
      }), " field."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Message object fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "to"
        }), ": the recipient of the email"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "from"
        }), ": the \"From\" header for the email. You can define a from name with the following format: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"from\":\"Sender Name <sender@hubspot.com>\""
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), ": the ID of a particular send. Only one email with a given ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), " will be sent per account, so you can include this field to prevent duplicate email sends."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "replyTo"
        }), ": a JSON list of \"Reply-To\" header values for the email."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "cc"
        }), ": a JSON list of email addresses to send as Cc."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "bcc"
        }), ": a JSON list of email addresses to send as Bcc."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "contactProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactProperties"
      }), " field is a JSON map of contact property values. Each contact property value contains a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "value"
      }), ". Each property will be set on the contact record and will be visible in the template under:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/name-token-in-template-for-transactional-email.png",
        alt: "name-token-in-template-for-transactional-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use these properties when you want to set a contact property while you’re sending the email. For example, when sending a receipt you may want to set a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "last_paid_date"
      }), " property, as the sending of the receipt will have information about the last payment."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"contactProperties\": {\n    \"last_paid_date\": \"2022-03-01\",\n    \"firstname\": \"jane\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "customProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " field is a JSON map of key-value properties. These properties are generally related to the email itself, not the contact receiving the email. They will not appear in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/create-a-web-version-of-your-marketing-email",
        children: "web page version of the email"
      }), ", or in the view of the email from the contact's timeline. These properties are also not stored in HubSpot and will only be included in the sent email."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each key in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " field can be referenced in the template using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL expression"
      }), " for fields contained within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "custom"
      }), " variable (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ custom.NAME_OF_PROPERTY }}"
      }), " )."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if your email template references two properties, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "purchaseUrl"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "productName"
      }), ", you could provide the associated values for these properties with the following request body:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"customProperties\": {\n    \"purchaseUrl\": \"https://example.com/link-to-product\",\n    \"productName\": \"vanilla\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can then reference these properties in your email template:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Congrats on purchasing some of the best ice cream around.\n  </p>\n  <a href={{custom.purchaseUrl}}>{{custom.productName}}</a>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " field only supports arrays when used with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "programmable email content"
      }), ". In your email template, you can reference the items defined in your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " field by using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL expression"
      }), " (e.g., using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for loop"
      }), " to render each item in a list). For example, if the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " you included in your request body was structured like the following JSON snippet below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643122,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"7\"\n  },\n  \"customProperties\": {\n    \"exampleArray\": [\n      {\"firstKey\": \"someValue\", \"secondKey\": \"anotherValue\"},\n      {\"firstKey\": \"value1\", \"secondKey\": \"value2\" }\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You could then reference the values for each item in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exampleArray"
      }), " with the following HubL code:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Thanks for your recent purchase! Here are the details of the items you'll be receiving:\n  </p>\n  <ul>\n    {% for item in custom.exampleArray %}\n      <li>First key: {{ item.firstKey }}, Second key: {{item.secondKey}}</li>\n    {% endfor %}\n  </ul>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once sent, the transactional email would render the contents of the associated programmable email template as follows:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-transactional-email-with-customProperties-array.png",
        alt: "example-transactional-email-with-customProperties-array"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Query the status of an email send"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get the status of the email send, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/marketing/v3/email/send-statuses/{statusId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response contains the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "sendResult"
          }), ": an enumeration that represents the email's send status. The possible values are ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#sendresult",
            children: "listed below"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), ": the timestamp from when the send was requested."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "startedAt"
          }), ": the timestamp when the send began processing."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "completedAt"
          }), ": the timestamp when the send completed."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), ": an identifier that can be used to query the status of the send."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), ": the status of the send request. Includes ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "eventId"
          }), ": if sent, the ID and created timestamp of the sent event."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendResult"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sendResult"
      }), " is an enumeration that reflects the result of an email send attempt. Its possible values are:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "SENT"
        }), ": the email was sent successfully."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "QUEUED"
        }), ": the email was queued and will send as the queue gets processed."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PORTAL_SUSPENDED"
        }), ": due to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/acceptable-use",
          children: "Acceptable Use Policy"
        }), " violations, the HubSpot customer's email has been suspended."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_TO_ADDRESS"
        }), ": the recipient address is invalid. This error will also occur if you attempt to send an email with any of the following role-based prefixes in the email address: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "abuse"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "no-reply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "noreply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "root"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "spam"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "security"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undisclosed-recipients"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "unsubscribe"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inoc"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "postmaster"
        }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "privacy"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOCKED_DOMAIN"
        }), ": the domain cannot receive emails from HubSpot at this time."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUSLY_BOUNCED"
        }), ": the recipient has previously bounced, and the sending logic resulted in no send."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUS_SPAM"
        }), ": the recipient has previously marked similar email as spam."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_FROM_ADDRESS"
        }), ": the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "From"
        }), " address is invalid."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_CONTENT"
        }), ": the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " is invalid, or the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " corresponds to an email that wasn't set up for Single-Send."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_TEMPLATE_PROPERTIES"
        }), ": there are properties set up in the template that have not been included in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        }), " sent in the request."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}