"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093133;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions';
const title = exports.title = 'Premiers pas avec les fonctions sans serveur';
const name = exports.name = 'EMEA (FR) Getting started with serverless functions';
const metaDescription = exports.metaDescription = 'Créez votre première fonction sans serveur sur CMS Hub et obtenez une réponse à partir de celle-ci.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-cr%C3%A9er-un-dossier-de-projet",
  "label": "1. Créer un dossier de projet",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-cr%C3%A9er-un-dossier-de-fonctions",
  "label": "2. Créer un dossier de fonctions",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-cr%C3%A9er-un-fichier-de-configuration",
  "label": "3. Créer un fichier de configuration",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-cr%C3%A9er-un-fichier-de-fonction",
  "label": "4. Créer un fichier de fonction",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-enregistrer-votre-fonction-dans-votre-serverless.json",
  "label": "5. Enregistrer votre fonction dans votre serverless.json",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-tester-votre-fonction",
  "label": "6. Tester votre fonction",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-cr%C3%A9er-un-mod%C3%A8le-de-base-appelant-votre-fonction",
  "label": "7. Créer un modèle de base appelant votre fonction",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-cr%C3%A9er-une-page-en-utilisant-le-mod%C3%A8le-test-function.html",
  "label": "8. Créer une page en utilisant le modèle test-function.html",
  "parent": null
}, {
  "depth": 0,
  "id": "d%C3%A9boguer-votre-fonction-sans-serveur",
  "label": "Déboguer votre fonction sans serveur",
  "parent": null
}, {
  "depth": 0,
  "id": "qu-avez-vous-fait-%3F",
  "label": "Qu'avez-vous fait ?",
  "parent": null
}, {
  "depth": 0,
  "id": "que-faire-apr%C3%A8s-%3F",
  "label": "Que faire après ?",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Premiers pas avec les fonctions sans serveur"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " si vous créez une fonction sans serveur dans le cadre d'un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "projet de développement"
        }), ", consultez plutôt la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "documentation relative aux fonctions sans serveur dans les projets de développement"
        }), ". La documentation ci-dessous concerne la création de fonctions sans serveur en dehors de la plate-forme de projets de développement."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions sans serveur permettent d'écrire un code côté serveur qui interagit avec HubSpot et des services tiers via des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "API"
      }), ". Les API exigeant une authentification ne sont pas sécurisées pour l'interface d'un site web, car vos identifiants seraient exposés."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions sans serveur peuvent agir comme intermédiaires entre vos services frontaux et dorsaux qui nécessitent une authentification. Grâce aux fonctions sans serveur, les développeurs n'ont pas besoin d'installer et de gérer de nouveaux serveurs. Les fonctions sans serveur exigent moins de ressources et sont donc plus faciles à faire évoluer au fil de la croissance d'une entreprise. Nous proposons une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "vue d'ensemble de haut niveau de ce que sont les fonctions sans serveur HubSpot et de leur fonctionnement"
      }), ". Nous vous recommandons de vous y référer avant de suivre ce tutoriel."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ce tutoriel vous guidera à travers la création de votre première fonction sans serveur."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous allez créer un dossier de fonction sans serveur, configurer votre dossier de configuration, créer une fonction et obtenir une réponse à partir de celle-ci."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ce que vous devez faire avant de suivre ce tutoriel :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ayez accès à un compte CMS Hub Entreprise ou à un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/standalone-cms-developer",
          children: "compte d'environnement de test développeur CMS"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Assurez-vous d'avoir au moins une compréhension de base des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "fonctions sans serveur"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Familiarisez-vous avec les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "outils de développement local HubSpot"
        }), ", car ils sont nécessaires pour travailler avec des fonctions sans serveur."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Assurez-vous d'avoir la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#install-or-upgrade",
          children: "dernière version des outils de développement local HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous devriez déjà disposer des outils de développement local ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: "authentifiés avec votre compte HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Créer un dossier de projet"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ouvrez votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " et assurez-vous que ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "defaultPortal"
        })
      }), " est configuré soit sur votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "compte d'environnement de test développeur CMS"
      }), ", soit sur un compte CMS Hub Entreprise."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sur votre ordinateur, dans le dossier qui contient votre fichier ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "créez un dossier"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "."
      }), " Ce dossier contiendra tous nos fichiers, à la fois les fonctions elles-mêmes et un modèle qui utilisera la fonction."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans votre terminal, exécutez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "commande watch"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch serverless-tutorial serverless-tutorial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Toutes les modifications apportées à ce dossier entraîneront des chargements dans le gestionnaire de conception. Étant donné que le dossier n'a actuellement aucun contenu, cette commande indiquera simplement « Watcher is ready and watching »."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Créer un dossier de fonctions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À l'intérieur du dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "créez un dossier"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "."
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Ceci est similaire aux dossiers de modules personnalisés qui se terminent par ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".module"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " servant à indiquer que le dossier contient des fonctions sans serveur. Les fichiers stockés dans ce dossier ne sont pas accessibles publiquement."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Étant donné que ce dossier est actuellement vide, la commande ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), " que vous avez exécutée ne créera pas encore ce dossier dans le gestionnaire de conception."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Créer un fichier de configuration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Créez un nouveau fichier dans votre dossier"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: ", nommez-le"
      }), " ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "."]
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " est un fichier requis contenu dans un dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), ". Il sert de fichier de configuration pour les fonctions sans serveur. Définition de l'environnement d'exécution, de la version de la fonction sans serveur et des points de terminaison disponibles. Pour un aperçu de tout ce qui est défini dans ce fichier, consultez notre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "référence sur les fonctions sans serveur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si vous avez créé et enregistré le fichier vide, vous recevrez un message d'erreur dans votre terminal indiquant que vous ne pouvez pas télécharger un fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " vide. Vous pouvez ignorer ce message car vous allez ajouter ce code, puis l'enregistrer, ce qui déclenchera un nouveau téléchargement."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Collez le code ci-dessous dans votre serverless.json :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// place this in your serverless.json file, without this comment\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"endpoints\": {}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Enregistrez le fichier"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gardez ce fichier ouvert dans votre éditeur de code, nous y reviendrons."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En raison de l'exécution de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), ", si vous actualisez votre gestionnaire de conception, vous verrez désormais vos dossiers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), " ainsi que votre nouveau fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Créer un fichier de fonction"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Créez un nouveau fichier dans votre dossier"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "my-first-function.functions"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "et nommez-le"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "congratulation.js"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il s'agit du véritable fichier de fonction, qui sera exécuté pour effectuer une tâche."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Collez le code ci-dessous :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n  const functionResponse =\n    \"Congrats! You've just deployed a Serverless Function.\";\n\n  // sendResponse is a callback function you call to send your response.\n  sendResponse({ body: functionResponse, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'elle est exécutée, cette fonction sans serveur renvoie une chaîne ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Congrats! You just deployed a Serverless Function.\""
      }), " ainsi qu'un code de statut ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " indiquant la réussite de l'opération."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Dans un scénario réel, il est probable que vous utilisiez des API ou que vous effectuiez certains calculs que vous ne souhaitez pas rendre publics. Vous renverrez le résultat de ces calculs ou un code de statut simple basé sur le succès de vos transactions d'API."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous n'avez pas encore terminé car il n'existe aucun moyen d'exécuter cette fonction pour le moment."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Enregistrer votre fonction dans votre serverless.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ouvrez à nouveau votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), ". Dans votre fichier, trouvez votre objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"endpoints\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mettez à jour l'objet pour qu'il ressemble à ceci :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// update the endpoints object in your serverless.json to reflect this object.\n\"endpoints\": {\n   \"congratulation\": {\n     \"method\": \"GET\",\n     \"file\": \"congratulation.js\"\n   }\n }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'objet de point de terminaison contient un objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"congratulation\""
      }), ". ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"congratulation\""
      }), " est le point de terminaison que vous créez. Le nom du point de terminaison définit le chemin que vous utiliserez pour appeler votre fonction sans serveur."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions sans serveur sont exposées via un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "chemin d'accès au domaine de votre compte CMS Hub."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ces fonctions peuvent être consultées via le chemin :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/<endpoint-name/path>"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le cas de ce point de terminaison \"congratulation\" que vous avez créé, le chemin sera"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/congratulation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour cette raison, il est généralement judicieux de nommer votre point de terminaison de la même manière que le nom de votre fichier de fonction. Ces deux éléments doivent être nommés en fonction des informations qu'ils traitent et non de l'action effectuée par rapport à ces informations. Vous devez utiliser la méthode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods",
        children: "HTTP"
      }), " ou d'autres méthodes pour communiquer le type d'action que vous effectuez à l'égard de ces informations. Le paramètre \"method\" définit la méthode HTTP que votre fonction prend en charge. Il peut s'agir d'une seule chaîne ou d'un tableau de chaînes désignant les méthodes prises en charge par la fonction."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Tester votre fonction"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La façon la plus simple de tester une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " pour votre fonction sans serveur consiste à accéder directement à l'URL de votre point de terminaison dans le navigateur.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://your-domain.com/_hcms/api/congratulation",
        children: "https://votre-domaine.com/_hcms/api/congratulation"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Remplacez votre-domaine.com par le domaine de votre site HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous devriez voir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Congrats! You just deployed a serverless function\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bravo !"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.em, {
        children: "Si vous n'obtenez pas cette réponse, revenez au début de ce tutoriel en lisant attentivement chaque étape et en vérifiant le code. Il est probable que les instructions des étapes 4 ou 5 n'aient pas été suivies correctement."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour les requêtes plus compliquées, il est judicieux d'utiliser un outil comme ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.postman.com/downloads/",
          children: "Postman."
        }), " Postman facilite le test et le débogage des API. Une fonctionnalité pratique pour les développeurs frontaux est sa ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://learning.postman.com/docs/postman/sending-api-requests/generate-code-snippets/",
          children: "génération de code"
        }), " qui peut créer un point de départ pour votre appel JavaScript à votre fonction."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Créer un modèle de base appelant votre fonction"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À l'aide d'une nouvelle fenêtre de terminal, accédez à votre dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exécutez la commande suivante dans votre terminal :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template \"test-function\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cela crée un fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-fonction.html"
      }), ". ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ouvrez ce fichier dans votre éditeur de code."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Au-dessus de la balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</head>"
      }), ", ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["ajoutez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<script></script>"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copiez le JavaScript ci-dessous :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var requestOptions = {\n  method: 'GET',\n  headers: {\n    'Content-Type': 'application/json',\n  },\n};\n\nfetch('https://www.example.com/_hcms/api/congratulation', requestOptions)\n  .then((response) => response.text())\n  .then((result) => console.log(result))\n  .catch((error) => console.log('error', error));\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les fonctions sans serveur HubSpot ne prennent en charge que le type de contenu ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), ". Le laisser en dehors entraînera une erreur « Type de média non pris en charge »."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À l'intérieur de la balise de script que vous avez créée précédemment, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "collez le code JavaScript copié"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Modifiez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "www.example.com"
        }), " dans le domaine de votre compte."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Enregistrez le fichier"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. Créer une page en utilisant le modèle test-function.html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le gestionnaire de conception, trouvez votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), " (vous devrez peut-être rafraîchir la page)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Effectuez un clic droit sur le fichier, choisissez « Créer une page »"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nommez votre page « Test de fonction ». ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Créez une page"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Cliquez sur prévisualiser dans une nouvelle fenêtre"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inspectez la page ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "en effectuant un clic droit"
      }), " sur la page et ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "en sélectionnant « Inspecter »."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous avez tout fait correctement, vous devriez voir dans votre console le message de félicitations."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Félicitations, vous avez appelé votre fonction sans serveur depuis une page CMS Hub."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Bien que ce tutoriel consiste à appeler la fonction sans serveur au niveau du modèle, vous pouvez appeler des fonctions sans serveur partout où vous pouvez ajouter du code JavaScript sur votre site hébergé par CMS. Vos modules personnalisés constituent l'endroit le plus utilisé."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Déboguer votre fonction sans serveur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À ce stade, votre fonction sans serveur devrait fonctionner correctement. Plus votre fonction devient complexe, plus il est difficile de résoudre les problèmes. Tout comme la console de votre navigateur est utile pour déboguer JavaScript sur vos éléments frontaux, vous pouvez obtenir des journaux similaires pour vos fonctions sans serveur grâce à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        })
      }), ". Consultez notre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "référence de développement local"
      }), " pour plus d'informations sur cette commande."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs logs <endpoint-name> --follow\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Qu'avez-vous fait ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous avez créé un dossier de fonctions sans serveur, avec un fichier de configuration serverless.json et un fichier de fonction nommé ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "congratulation.js"
      }), ". Vous avez utilisé GET pour obtenir un texte de félicitations depuis la fonction sans serveur. Vous avez utilisé JavaScript pour passer un appel à votre fonction sans serveur depuis une page de CMS Hub."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant que vous comprenez la relation entre le fichier de configuration, le fichier de fonction et le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), ", l'ILC dispose d'une commande pratique que vous pouvez utiliser pour créer vos fonctions plus rapidement la prochaine fois."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create function <function name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette fonction crée un dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), ", un fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " et un fichier de fonction avec les noms que vous fournissez."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Que faire après ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "Vue d'ensemble de haut niveau des fonctions sans serveur"
        }), " sur CMS Hub"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/serverless-functions",
          children: "Référence sur les fonctions sans serveur"
        }), " décomposant les éléments d'une fonction sans serveur"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/introduction-to-hubspot-apis",
          children: "Cours de HubSpot Academy sur la présentation des API de HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "Intégration continue avec GitHub"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}