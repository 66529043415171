"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611103;
const slug = exports.slug = 'guides/cms/content/global-content';
const title = exports.title = 'Contenido global';
const name = exports.name = 'Contenido global';
const metaDescription = exports.metaDescription = 'El contenido global es el contenido que puede reutilizarse en todas tus plantillas para crear piezas coherentes de información coherentes, como encabezados de sitios web, pies de página, barras laterales, logotipos u otros componentes.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png';
const featuredImageAltText = exports.featuredImageAltText = 'Editor de contenido global';
const toc = exports.toc = [{
  "depth": 0,
  "id": "resumen",
  "label": "Resumen",
  "parent": null
}, {
  "depth": 1,
  "id": "parciales-globales-vs.-m%C3%B3dulos-globales",
  "label": "Parciales globales vs. módulos globales",
  "parent": "resumen"
}, {
  "depth": 0,
  "id": "parciales-globales",
  "label": "Parciales globales",
  "parent": null
}, {
  "depth": 1,
  "id": "crea-un-parcial-global",
  "label": "Crea un parcial global",
  "parent": "parciales-globales"
}, {
  "depth": 1,
  "id": "a%C3%B1ade-%C3%A1reas-de-arrastrar-y-soltar-a-los-parciales-globales",
  "label": "Añade áreas de arrastrar y soltar a los parciales globales",
  "parent": "parciales-globales"
}, {
  "depth": 1,
  "id": "incluye-un-parcial-global-en-tu-plantilla-actual",
  "label": "Incluye un parcial global en tu plantilla actual",
  "parent": "parciales-globales"
}, {
  "depth": 0,
  "id": "m%C3%B3dulos-globales",
  "label": "Módulos globales",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      pre: "pre",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Contenido global"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El contenido global es el contenido que se comparte en todas las partes de un sitio web. Ejemplos comunes son los encabezados, los pies de página y las barras laterales de los sitios web. Como desarrollador, especificarás qué componentes deben ser globales mediante el uso de parciales globales o haciendo que los módulos sean globales. HubSpot ofrece una experiencia de edición diferente a los editores de contenido que facilita la edición del contenido global y la visualización previa de los cambios en todas las páginas antes de su publicación. Para obtener más información sobre cómo editar tu contenido global, consulta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "cómo utilizar el contenido global en varias plantillas"
      }), " en la base de conocimientos de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png",
        alt: "Editor de contenido global"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Resumen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El contenido global es más eficaz para mostrar la misma información en varias páginas. Por ejemplo, el encabezado y el pie de página de tu sitio web, como el encabezado de la parte superior de esta página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubspot-developers-header.png",
        alt: "hubspot-developers-header"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación algunos ejemplos adicionales de áreas en las que puedes utilizar el contenido global:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Navegación secundaria para las diferentes secciones de tu sitio web"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pies de página de derechos de autor (o pies de página secundarios)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Barras laterales de publicaciones del blog (para mostrar las publicaciones recientes, listados de autores y más)"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Debido a que el contenido global se utiliza en múltiples lugares a lo largo de un sitio web, es aún más crucial ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "diseñar y construir tus parciales y módulos globales para la accesibilidad"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Parciales globales vs. módulos globales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como desarrollador, puedes crear parciales globales y módulos globales, con algunas diferencias clave entre ellos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los parciales globales son un tipo de plantilla creada con HTML y HubL que se puede reutilizar en todo el sitio web. Los tipos de parciales más comunes son los encabezados, las barras laterales y los pies de página."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los módulos globales son módulos que se componen de uno o varios contenidos que se pueden utilizar en varias páginas de tu sitio. Algunos tipos comunes de módulos globales pueden ser elementos como formularios de suscripción al blog, elementos de navegación secundaria y llamadas a la acción."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Debes evitar incluir módulos globales dentro de los parciales globales, ya que ello puede crear una experiencia negativa en la edición de contenido."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Todos los módulos y campos dentro de tus parciales globales y módulos globales son fácilmente editables dentro del ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
          children: "editor de contenido global"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parciales globales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crea un parcial global"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un parcial global es un tipo de plantilla que puedes crear localmente a través de la CLI de HubSpot utilizando el comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "create"
      }), ", como se muestra a continuación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <partial-file-name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se te pida que elijas un tipo de plantilla, selecciona ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global partial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esto creará tu plantilla en el directorio deseado con las siguientes anotaciones de plantilla incluidas en el archivo HTML."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: global_partial\n  label: Page Header\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver un ejemplo de un contenido parcial global, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/header.html",
        children: "consulta nuestra plantilla en GitHub."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Añade áreas de arrastrar y soltar a los parciales globales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes habilitar las capacidades de arrastrar y soltar contenido dentro de tus parciales globales agregando etiquetas de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " forma similar a la habilitación en las plantillas de página. Consulta nuestra ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "documentación sobre el área de arrastrar y soltar"
      }), " para más información."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Incluye un parcial global en tu plantilla actual"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para agregar un parcial global en una de tus plantillas existentes, utiliza la etiqueta de HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), " mientras haces referencia a la ruta del parcial. A continuación, verás un ejemplo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L21",
        children: "la plantilla del CMS"
      }), " que utiliza esta etiqueta."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se emiten parciales globales, contienen una envoltura ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " alrededor del parcial global. Esto lo utiliza el editor de la página para identificar que la pieza de contenido es un parcial global."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div\n  data-global-resource-path=\"cms-theme-boilerplate/templates/partials/header.html\"\n>\n  <!-- Your header.html code is output here -->\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " No utilices ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "global_partial"
        }), " dentro del ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), " de una plantilla. Hacerlo produciría un HTML no válido."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En la mayoría de las situaciones en las que se quieres utilizar un parcial global en el encabezado, puede tener más sentido utilizar un módulo global en su lugar con un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{%require_head%}"
          })
        }), " para insertar código personalizado en el encabezado y seguir proporcionando campos de módulo."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Módulos globales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes crear módulos globales al igual que cualquier otro módulo usando la CLI ejecutando el comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create"
      }), ", como se muestra abajo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create module <module_name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un módulo global se diferencia por el indicador ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), " en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " del módulo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json file\n{\n  \"css_assets\": [],\n  \"external_js\": [],\n  \"global\": true,\n  \"help_text\": \"\",\n  \"host_template_types\": [\"PAGE\"],\n  \"js_assets\": [],\n  \"other_assets\": [],\n  \"smart_type\": \"NOT_SMART\",\n  \"tags\": [],\n  \"is_available_for_new_content\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "crear módulos globales en HubSpot utilizando el administrador de diseño"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Más información sobre cómo trabajar con módulos en los siguientes recursos relacionados:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Resumen de los módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Módulos predeterminados de HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configurar módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Uso de módulos en plantillas"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}