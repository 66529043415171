"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51199955888;
const slug = exports.slug = 'getting-started/account-types';
const title = exports.title = 'Tipos de cuentas de HubSpot';
const name = exports.name = 'Tipos de cuentas de HubSpot';
const metaDescription = exports.metaDescription = 'Aprende sobre los diferentes tipos de cuentas de HubSpot y para qué se usan.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cuentas-de-hubspot-est%C3%A1ndar",
  "label": "Cuentas de HubSpot estándar",
  "parent": null
}, {
  "depth": 0,
  "id": "cuentas-de-desarrollador",
  "label": "Cuentas de desarrollador",
  "parent": null
}, {
  "depth": 0,
  "id": "cuentas-de-prueba-de-desarrollador",
  "label": "Cuentas de prueba de desarrollador",
  "parent": null
}, {
  "depth": 1,
  "id": "crea-una-cuenta-de-prueba-de-desarrollador",
  "label": "Crea una cuenta de prueba de desarrollador",
  "parent": "cuentas-de-prueba-de-desarrollador"
}, {
  "depth": 1,
  "id": "renovar-una-cuenta-de-desarrollador",
  "label": "Renovar una cuenta de desarrollador",
  "parent": "cuentas-de-prueba-de-desarrollador"
}, {
  "depth": 0,
  "id": "cuentas-de-entorno-de-pruebas",
  "label": "Cuentas de entorno de pruebas",
  "parent": null
}, {
  "depth": 1,
  "id": "cuentas-de-entorno-de-pruebas-est%C3%A1ndar",
  "label": "Cuentas de entorno de pruebas estándar",
  "parent": "cuentas-de-entorno-de-pruebas"
}, {
  "depth": 1,
  "id": "cuentas-de-entorno-de-pruebas-cms",
  "label": "Cuentas de entorno de pruebas CMS",
  "parent": "cuentas-de-entorno-de-pruebas"
}, {
  "depth": 1,
  "id": "cuentas-de-entornos-de-prueba-para-desarrollo-beta",
  "label": "Cuentas de entornos de prueba para desarrollo (BETA)",
  "parent": "cuentas-de-entorno-de-pruebas"
}, {
  "depth": 0,
  "id": "cuentas-de-proveedores-de-mercado",
  "label": "Cuentas de proveedores de mercado",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      em: "em",
      img: "img",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Tipos de cuentas de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay varios tipos de cuentas de HubSpot, cada una con un propósito diferente. A continuación, aprenden sobre cada tipo de cuenta y para qué están destinados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cuentas de HubSpot estándar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una cuenta de HubSpot estándar es el tipo más común de cuenta. Es donde encontrarás todas las herramientas, funciones y configuraciones incluidas en tu suscripción de HubSpot. Puede ser gratis o de pago, y se utiliza como tu entorno de producción."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una cuenta HubSpot estándar tendrá acceso a todas las herramientas y funciones incluidas en tu suscripción."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cuentas de desarrollador"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las cuentas de desarrollador de aplicaciones son cuentas gratuitas que están diseñadas para crear y administrar aplicaciones, integraciones y cuentas de prueba de desarrollador. También son las cuentas en las que puedes crear y administrar anuncios del mercado de aplicaciones. Sin embargo, las cuentas de desarrollador de aplicaciones y sus cuentas de prueba asociadas no están conectadas a una cuenta de HubSpot estándar. No pueden sincronizar datos ni materiales desde o hacia otra cuenta de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las cuentas de desarrollador de aplicaciones se pueden identificar mediante un banner en la parte superior de cualquier página que diga ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Esta"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "es una cuenta de"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "desarrollador de aplicaciones"
      }), ". ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://paper-attachments.dropbox.com/s_FE997E185BD47B083EA9B7C65FEC2822D18406AA9C2CBDB7FA3CA77217D16F19_1626814303990_App+dev+account+info+alert+option+3.png",
        alt: ""
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comienza por crear una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "cuenta de desarrollador de aplicaciones"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cuentas de prueba de desarrollador"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro de las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-developer-accounts",
        children: "cuentas de desarrollador de aplicaciones"
      }), ", puedes crear cuentas de prueba de desarrollador para probar aplicaciones e integraciones sin afectar los datos reales de HubSpot. Las cuentas de prueba para desarrolladores no reflejan las cuentas de producción, sino que son cuentas gratuitas de HubSpot con acceso a una prueba de 90 días de muchas funciones empresariales, con las siguientes limitaciones:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hub:"
        }), " solo puedes enviar correos electrónicos de marketing a las direcciones de los usuarios que hayas agregado a tu cuenta de prueba de desarrollador."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub:"
        }), " el número de páginas que puedes crear está sujeta a los siguientes límites:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Páginas de sitio web:"
            }), " 25"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Herramientas de blogs:"
            }), " 1 blog con hasta 100 publicaciones"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Landing pages:"
            }), " 25"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workflows:"
        }), " se puede inscribir un máximo de 100.000 registros por día en workflows creados en una cuenta de prueba para desarrolladores. Si se alcanza este límite diario:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Cualquier intento de inscripción adicional más allá de este límite se cancelará."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se informará a los usuarios en la aplicación que han alcanzado el límite de registro diario de inscripción, así como a qué hora se actualizará el límite."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes crear hasta 10 cuentas de prueba por cuenta de desarrollador. Este tipo de cuenta no puede sincronizar datos con otras cuentas y no se pueden conectar a una cuenta estándar de HubSpot. Las cuentas de prueba se pueden identificar con un banner en la parte superior de la página, que incluye cuántos días faltan para su expiración."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/trial-banner.png",
        alt: "trial-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, aprende más sobre cómo crear y administrar cuentas de prueba."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crea una cuenta de prueba de desarrollador"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear una cuenta de prueba de desarrollador:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la barra lateral de navegación izquierda de tu cuenta de desarrollador de aplicaciones, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Cuentas de prueba"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/left-sidebar-test-accounts.png",
            alt: "left-sidebar-test-accounts"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Creación de una cuenta de prueba de aplicaciones"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-test-account-create.png",
            alt: "developer-test-account-create"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Introduce un ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre de cuenta"
          }), " y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Crear"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para acceder y administrar tus cuentas de prueba de desarrollador:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra de navegación superior de tu cuenta de desarrollador de aplicaciones, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Prueba"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Coloca el cursor sobre la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "cuenta"
        }), " que deseas administrar y selecciona una acción:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para eliminar la cuenta, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Eliminar"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para copiar el ID de la cuenta, renovarla o cambiarle el nombre, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Más"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-account-test-accounts.png",
        alt: "developer-account-test-accounts"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Renovar una cuenta de desarrollador"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las cuentas de prueba de desarrollador vencerán después de 90 días si no se realizan llamadas API a la cuenta. Puedes renovar la cuenta manualmente desde la página de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Pruebas"
      }), " en HubSpot, o haciendo una llamada de API a la cuenta. Ten en cuenta lo siguiente al intentar renovar la cuenta a través de una llamada a la API:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Esto solo se aplica a solicitudes de API realizadas utilizando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "tokens de OAuth"
        }), " generados a partir de una aplicación en la misma cuenta de desarrollador como la cuenta de prueba que deseas renovar."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Las renovaciones deben realizarse antes de 30 días de la fecha de vencimiento de la cuenta de prueba."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cuentas de entorno de pruebas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las cuentas de entorno de pruebas te permiten probar los cambios sin afectar tu cuenta estándar. Aprenda más sobre los diferentes tipos de cuentas de entorno de pruebas en las siguientes secciones."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Cuentas de entorno de pruebas estándar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tienes una suscripción ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", puedes crear una cuenta de entorno de pruebas estándar que proporcione un entorno seguro donde puedes probar nuevos workflows, integraciones, páginas de sitio web y otros cambios importantes sin afectar nada en tu cuenta estándar. Estos entornos de prueba copian la estructura de tu cuenta estándar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Estas cuentas se pueden identificar mediante un banner amarillo en la parte superior de la página que incluye la etiqueta: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Estás en [nombre del entorno de pruebas], que es una cuenta estándar de entorno de pruebas"
      }), ". El banner también incluye un enlace a tu cuenta de producción para facilitar el cambio."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/standard-sandbox-banner.png",
        alt: "standard-sandbox-banner"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aprende más sobre las cuentas de entorno de prueba estándar en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/set-up-a-hubspot-standard-sandbox-account",
        children: "Base de conocimientos de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Cuentas de entorno de pruebas CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los entornos de pruebas de CMS son cuentas gratuitas que están diseñados para crear y probar cambios en el sitio web sin afectar tu cuenta estándar o sitio web activo. De forma similar a las cuentas de desarrollador de aplicaciones, las cuentas de entorno de pruebas de CMS ", (0, _jsxRuntime.jsx)("u", {
        children: "no"
      }), " están conectadas a tu cuenta estándar de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "crear una cuenta de entorno de pruebas de CMS de forma gratuita"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los entornos de prueba de CMS no tienen un banner, pero solo tienen acceso a las herramientas gratuitas de HubSpot y ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", menos la capacidad de conectar un dominio."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Cuentas de entornos de prueba para desarrollo (BETA)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tienes una suscripción a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "_Sales Hub _"
      }), "o ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", puedes crear una cuenta de entorno de prueba de desarrollo a través de la CLI para el desarrollo local. Puedes obtener acceso a cuentas de entorno de pruebas para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/opt-your-hubspot-account-into-a-beta-feature",
        children: "desarrolladores si optas por la versión beta de las herramientas de desarrollo de CRM en tu cuenta estándar de HubSpot."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los entornos de prueba de desarrollo se pueden identificar mediante un banner amarillo en la parte superior de la página que dice: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Estás en [nombre del entorno de pruebas], que es una cuenta estándar de entorno de pruebas."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/development-sandboxes-banner.png",
        alt: "development-sandboxes-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cuentas de proveedores de mercado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las cuentas de proveedores de mercado están destinadas a crear y administrar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "plantillas de anuncios"
      }), " y transacciones en el mercado. Para comenzar a vender en el Mercado de plantillas, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "crea una cuenta de proveedor de Mercado de plantillas"
      }), ". Si eres ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners",
        children: "partner de HubSpot"
      }), ", ya tienes la funcionalidad de proveedor del mercado en tu cuenta de partner."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una cuenta de proveedor del mercado puede identificarse por un elemento de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mercado"
      }), " de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "plantillas"
      }), " en el menú de navegación de la parte superior."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/template-marketplace-acct.png",
        alt: "template-marketplace-acct"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}