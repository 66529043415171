"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61045964892;
const slug = exports.slug = 'guides/api/crm/engagements/meetings';
const title = exports.title = 'Engagements | Réunions';
const name = exports.name = 'Engagements | Réunions';
const metaDescription = exports.metaDescription = "Utilisez l'API d'engagement des réunions pour enregistrer et gérer les réunions dans les fiches d'informations CRM.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-une-r%C3%A9union",
  "label": "Créer une réunion",
  "parent": null
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s",
  "label": "Propriétés",
  "parent": "cr%C3%A9er-une-r%C3%A9union"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "cr%C3%A9er-une-r%C3%A9union"
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-des-r%C3%A9unions",
  "label": "Récupérer des réunions",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-les-r%C3%A9unions",
  "label": "Mettre à jour les réunions",
  "parent": null
}, {
  "depth": 1,
  "id": "associer-des-r%C3%A9unions-existantes-%C3%A0-des-fiches-d-informations",
  "label": "Associer des réunions existantes à des fiches d'informations",
  "parent": "mettre-%C3%A0-jour-les-r%C3%A9unions"
}, {
  "depth": 1,
  "id": "supprimer-une-association",
  "label": "Supprimer une association",
  "parent": "mettre-%C3%A0-jour-les-r%C3%A9unions"
}, {
  "depth": 0,
  "id": "%C3%A9pingler-une-r%C3%A9union-sur-une-fiche-d-informations",
  "label": "Épingler une réunion sur une fiche d'informations",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-des-r%C3%A9unions",
  "label": "Supprimer des réunions",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Réunions"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez l'API d'engagement des réunions pour enregistrer et gérer les réunions dans les fiches d'informations CRM. Vous pouvez enregistrer des activités de réunions soit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "dans HubSpot"
      }), " soit via l'API des réunions. Vous pouvez récupérer, mettre à jour ou supprimer les engagements de réunion enregistrés manuellement sur une fiche d'informations, planifiés à l'aide de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/meetings-tool/use-meetings",
        children: "l'outil réunions"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/schedule-a-meeting-with-a-contact-in-a-record",
        children: "planifiés à l'aide de Google Agenda ou de l'intégration du calendrier Office 365"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ci-dessous, découvrez les méthodes de base pour gérer les réunions via l'API. Pour afficher tous les points de terminaison disponibles et leurs exigences, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer une réunion"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un engagement de réunion, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le corps de la requête, ajoutez les détails de réunion dans un objet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), ". Vous pouvez également ajouter un objet d'", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "association"
      }), " pour associer votre nouvelle réunion à une fiche d'informations existante (par exemple, des contacts ou des entreprises)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'objet de propriété, vous pouvez inclure les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Obligatoire. Ce champ marque la date et l'heure auxquelles la réunion a eu lieu. Vous pouvez utiliser soit un horodatage Unix en millisecondes, soit un format UTC.(BETA) Lorsque la valeur de propriété est absente, la valeur sera définie par défaut sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_start_time."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le titre du rendez-vous."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID du propriétaire"
            }), " associé à la réunion. Ce champ détermine l'utilisateur listé comme le créateur de réunion sur la chronologie de la fiche d'informations."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La description de la réunion"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_internal_meeting_notes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les notes internes que vous prenez pour votre équipe au cours d'une réunion et qui ne sont pas incluses dans la description de la réunion des participants."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_external_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'URL externe de la réunion pour l'événement du calendrier. Par exemple, il peut s'agir d'un lien vers Google Agenda ou un lien vers un calendrier Microsoft Outlook."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lieu où la réunion a lieu. Il peut s'agir d'une adresse physique, d'une salle de conférence, d'un lien vers une vidéoconférence ou d'un numéro de téléphone. Cela apparaît sur l'invitation de calendrier sur le calendrier du participant."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_start_time"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La date et l'heure auxquelles la réunion commence. La valeur de cette propriété doit correspondre à la valeur de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_end_time"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La date et l'heure auxquelles la réunion se termine."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_outcome"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le résultat de la réunion. Les valeurs de résultat sont : planifiée, terminée, replanifiée, désistement et annulée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_activity_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type de réunion. Les options sont basées sur les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types",
              children: "types de réunion définis dans votre compte HubSpot."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les ID des pièces jointes de la réunion. Les ID de pièces jointes multiples sont séparés par un point-virgule."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer et associer une réunion à des fiches d'informations existantes, incluez un objet d'association dans votre demande. Par exemple, pour créer et associer une réunion à des contacts, votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-23T01:02:44.872Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_meeting_title\": \"Intro meeting\",\n    \"hs_meeting_body\": \"The first meeting to discuss options\",\n    \"hs_internal_meeting_notes\": \"These are the meeting notes\",\n    \"hs_meeting_external_url\": \"https://Zoom.com/0000\",\n    \"hs_meeting_location\": \"Remote\",\n    \"hs_meeting_start_time\": \"2021-03-23T01:02:44.872Z\",\n    \"hs_meeting_end_time\": \"2021-03-23T01:52:44.872Z\",\n    \"hs_meeting_outcome\": \"SCHEDULED\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 101\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 200\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 102\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 200\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les objets d'associations doivent inclure :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La fiche d'informations à laquelle vous souhaitez associer la réunion, en fonction de sa valeur unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'association entre la réunion et la fiche d'informations. Inclure la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "et le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Les ID de types d'association par défaut sont répertoriés ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "ici"
            }), ", ou vous pouvez récupérer la valeur des types d'associations personnalisés (c'est-à-dire les libellés) via l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API des associations"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la création de réunions par lots, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer des réunions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez récupérer des réunions individuellement ou en lot. Pour en savoir plus sur la récupération par lot, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer une réunion individuelle par son ID d'appel, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), ". Vous pouvez aussi inclure les paramètres suivants dans l'URL de la requête :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des objets que voulez pour récupérer les ID des fiches d'informations associées."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour demander une liste de toutes les réunions, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/meetings"
      }), ". Vous pouvez inclure les paramètres suivants dans l'URL de la requête :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre maximum de résultats à afficher par page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour les réunions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez mettre à jour des réunions individuellement ou par lots. Pour mettre à jour une réunion individuelle par son ID d'appel, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le corps de la requête, incluez les propriétés de réunion que vous souhaitez mettre à jour : Par exemple, votre corps de requête peut ressembler aux éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example PATCH request to https://api.hubspot.com/crm/v3/objects/meetings/{meetingId}\n{\n  \"properties\": {\n     \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n     \"hubspot_owner_id\": \"11349275740\",\n     \"hs_meeting_title\": \"Intro meeting\",\n     \"hs_meeting_body\": \"The first meeting to discuss options\",\n     \"hs_internal_meeting_notes\": \"These are the meeting notes\",\n     \"hs_meeting_external_url\":\n     \"https://Zoom.com/0000\",\n     \"hs_meeting_location\": \"Remote\",\n     \"hs_meeting_start_time\": \"2021-03-23T01:02:44.872Z\",\n     \"hs_meeting_end_time\": \"2021-03-23T01:52:44.872Z\",\n     \"hs_meeting_outcome\": \"SCHEDULED\"\n  }\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignorera les valeurs des propriétés inexistantes ou en lecture seule. Pour effacer une valeur de propriété, passez une chaîne vide comme valeur de propriété dans le corps de la requête."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la mise à jour par lots, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer des réunions existantes à des fiches d'informations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour associer une réunion à des fiches d'informations, comme un contact et ses entreprises associées, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". L'URL de la requête contient les champs suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meetingId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de la réunion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le type d'objet auquel vous souhaitez associer la réunion (par exemple, contact ou entreprise)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de la fiche d'informations avec laquelle vous souhaitez associer la réunion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ID du type d'association entre la réunion et l'autre type d'objet. Vous pouvez récupérer cette valeur via l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API des associations"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, l'URL de votre requête peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/meetings/17612479134/associations/contact/104901/200"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer une association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une association entre une réunion et une fiche d'informations, faites une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à la même URL que ci-dessus :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Épingler une réunion sur une fiche d'informations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "épingler une réunion"
      }), " sur une fiche d'informations afin qu'elle reste en haut de la chronologie de la fiche d'informations. La réunion doit déjà être associée à la fiche d'informations avant d'être épinglée, et vous ne pouvez épingler qu'une seule activité par fiches d'informations. Pour épingler une réunion, incluez l'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la réunion dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " lors de la création ou de la mise à jour d'une fiche d'informations via les API objet. Découvrez-en davantage sur l'utilisation des API d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "entreprises"
      }), ", de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contacts"
      }), ", de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "transactions"
      }), ", de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " et d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objets personnalisés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer des réunions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez supprimer des réunions individuellement ou par lots, ce qui placera la réunion dans la corbeille dans HubSpot. Vous pouvez ensuite ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restaurer la réunion à partir de la chronologie des fiches d'informations"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une réunion individuelle par son ID d'appel, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la suppression par lot, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}