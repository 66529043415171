"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890269;
const slug = exports.slug = 'guides/cms/content/data-driven-content/crm-objects';
const title = exports.title = 'Objets CRM dans le CMS Hub';
const name = exports.name = 'EMEA (FR) CRM Custom Objects in CMS Hub';
const metaDescription = exports.metaDescription = 'Les objets CRM peuvent être demandés et rendus sur le contenu hébergé par HubSpot, ce qui permet de partager les données entre vos opérations commerciales, votre site Web et vos e-mails.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "types-d-objets-crm-pris-en-charge",
  "label": "Types d'objets CRM pris en charge",
  "parent": null
}, {
  "depth": 1,
  "id": "donn%C3%A9es-des-objets-crm-disponibles-pour-toutes-les-pages",
  "label": "Données des objets CRM disponibles pour toutes les pages",
  "parent": "types-d-objets-crm-pris-en-charge"
}, {
  "depth": 1,
  "id": "donn%C3%A9es-des-objets-crm-disponibles-pour-les-pages-priv%C3%A9es",
  "label": "Données des objets CRM disponibles pour les pages privées",
  "parent": "types-d-objets-crm-pris-en-charge"
}, {
  "depth": 0,
  "id": "afficher-les-donn%C3%A9es-d-un-seul-enregistrement-crm-avec-la-fonction-crm_object",
  "label": "Afficher les données d'un seul enregistrement CRM avec la fonction crm_object",
  "parent": null
}, {
  "depth": 0,
  "id": "afficher-les-donn%C3%A9es-de-plusieurs-enregistrements-crm-avec-la-fonction-crm_objects",
  "label": "Afficher les données de plusieurs enregistrements CRM avec la fonction crm_objects",
  "parent": null
}, {
  "depth": 0,
  "id": "affichage-des-enregistrements-associ%C3%A9s",
  "label": "Affichage des enregistrements associés",
  "parent": null
}, {
  "depth": 0,
  "id": "obtenir-les-d%C3%A9tails-d-un-type-d-objet-personnalis%C3%A9",
  "label": "Obtenir les détails d'un type d'objet personnalisé",
  "parent": null
}, {
  "depth": 0,
  "id": "champ-module-d-objet-crm",
  "label": "Champ Module d'objet CRM",
  "parent": null
}, {
  "depth": 0,
  "id": "tutoriels-et-ressources-concernant-les-objets-crm",
  "label": "Tutoriels et ressources concernant les objets CRM",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h3: "h3",
      em: "em",
      h2: "h2",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      img: "img",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Utiliser les données des objets CRM dans CMS Hub"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez interroger les objets CRM pour utiliser les données des enregistrements de l'objet sur le contenu hébergé par HubSpot, ce qui permet de partager les données entre vos opérations commerciales, votre site Web et vos e-mails. En utilisant les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objet"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objets"
        })
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), " HubL, vous pouvez afficher et contrôler la logique basée sur les données de vos objets CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'utilisation des données CRM sur votre site Web signifie que vos données sur les ventes, marketing et le site Web se trouvent toutes au même endroit et reflètent toujours les mêmes informations. En outre, comme vous pouvez associer des enregistrements CRM les uns aux autres, vous pouvez également intégrer les données associées dans les pages de votre site Web."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["De même, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "créer des ensembles de pages dynamiques qui se génèrent automatiquement à l'aide d'objets CRM ou de données HubDB"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Découvrez-en davantage sur la création de pages CMS basées sur les données dans le cours ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "CMS Data-Driven Content"
        }), " de HubSpot Academy."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemple de cas d'utilisation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une liste d'annonces immobilières est un exemple d'utilisation de données d'objets CRM dans des pages. Avec un objet personnalisé appelé ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "propriété"
      }), ", des enregistrements d'objets individuels peuvent être créés pour chaque maison qui doit être répertoriée. Les agents immobiliers peuvent ensuite ajouter des informations aux propriétés des objets afin de stocker des détails, tels que l'emplacement, le nombre de chambres et les prix demandés."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les pages du site Web peuvent alors extraire les données de l'enregistrement pour chaque propriété afin de créer une page d'inscription et des pages de détails pour chaque propriété."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-custom-objects-example",
        children: "Consultez le dépôt GitHub"
      }), " pour voir l'exemple complet."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour avoir une vue d'ensemble de cet exemple, consultez l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/developer-day-2020",
        children: "enregistrement de la Journée des développeurs HubSpot 2020"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types d'objets CRM pris en charge"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous les types d'objets CRM dont vous pouvez tirer des données pour les pages de votre CMS Hub. Le fait que vous puissiez utiliser les données sur toutes les pages ou uniquement sur les pages privées dépend du type d'objet."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans les tableaux ci-dessous, découvrez les types d'objets disponibles pour le contenu du CMS ainsi que leurs noms et noms entièrement qualifiés."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Les noms d'objets standard, tels que « contact », ne sont pas sensibles à la casse, mais doivent être au singulier."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Données des objets CRM disponibles pour toutes les pages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les données des objets CRM suivants peuvent être utilisées sur n'importe quelle page du CMS."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Type d'objet"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nom object_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nom entièrement qualifié"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "Produits"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRODUCT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "Événements marketing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "marketing_event"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/custom-objects",
              children: "Objets personnalisés"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Entreprise"]
            }), " uniquement. Vous pouvez utiliser le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "nom entièrement qualifié"
            }), " de l'objet ou le nom saisi au moment de la création. Par exemple, si vous créez un objet nommé « Voitures », vous ne pouvez pas le référencer avec « voitures » ou « Voiture ».Vous devez utiliser le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "nom entièrement qualifié"
            }), " si l'objet personnalisé partage un nom avec un objet standard."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Données des objets CRM disponibles pour les pages privées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les données des objets de CRM suivants ne peuvent être utilisées que sur les pages nécessitant un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/password-protect-a-page",
        children: "mot de passe"
      }), " ou une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/require-member-registration-to-access-private-content",
        children: "connexion de membre."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Type d'objet"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nom object_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "NOM ENTIÈREMENT QUALIFIÉ"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Contacts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "Entreprises"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPANY"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "Transactions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DEAL"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/tickets",
              children: "Tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TICKET"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes",
              children: "Devis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUOTE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Objets intégrateurs"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Pour obtenir le nom d'un objet intégrateur, utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "l'API schéma des objets CRM"
            }), ".Pour les objets intégrateurs portant le même nom que les objets standards, utilisez le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "nom complet"
            }), " de l'objet intégrateur."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Afficher les données d'un seul enregistrement CRM avec la fonction crm_object"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objet"
        })
      }), " pour obtenir un enregistrement unique de la fonctionnalité CRM de HubSpot par requête ou par ID d'enregistrement CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les enregistrements d'objets sont renvoyés sous la forme d'un dictionnaire de propriétés et de valeurs."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom object by query #}\n{% set event = crm_object(\"event\", \"name=Defensive Health\") %}\n{{ event.name }}\n\n{# Render custom objects specifying the id of the object #}\n{% set event = crm_object(\"event\", 289236) %}\n{{ event.name }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>Defensive Heatlh</p>\n\n<p>Defensive Heatlh</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Si une requête renvoie une collection d'enregistrements, la fonction renvoie le premier enregistrement de la collection."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Afficher les données de plusieurs enregistrements CRM avec la fonction crm_objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), " pour obtenir des enregistrements CRM par type d'objet à partir de la fonctionnalité CRM de HubSpot par requête ou par ID d'enregistrement. Les enregistrements sont renvoyés sous la forme d'un dictionnaire de propriétés et de valeurs."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'enregistrement renvoyé contient une propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " qui peut être mise en boucle pour afficher les informations dans les éléments de l'enregistrement."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom objects by query #}\n{% set events = crm_objects(\"event\", \"limit=3&type=virtual\") %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n\n{# Render custom objects by ids #}\n{% set events = crm_objects(\"event\", [289236,289237,289238]) %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  3 New Events:\n  <h3>\n    <ul>\n      <li>Name: Defensive Health</li>\n      <li>Name: Body Balance</li>\n      <li>Name: Happy Heart</li>\n      <ul>\n        <h3>\n          3 New Events:\n          <h3>\n            <ul>\n              <li>Name: Defensive Health</li>\n              <li>Name: Body Balance</li>\n              <li>Name: Happy Heart</li>\n              <ul></ul>\n            </ul>\n          </h3>\n        </h3>\n      </ul>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Affichage des enregistrements associés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), " de HubL pour obtenir une liste d'informations associées à partir de la fonctionnalité CRM de HubSpot en fonction de l'ID d'enregistrement, de la catégorie d'association et de l'ID de définition de l'association.", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#getting-a-custom-object-s-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les enregistrements sont renvoyés sous la forme d'un dictionnaire de propriétés et de valeurs."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set associated_objects = crm_associations(289236, \"USER_DEFINED\", 3) %}\n<h3>Contacts Associated With Event</h3>\n<ul>\n{% for contact in associated_objects.results %}\n\t<li>Name: {{ contact.firstname }} {{ contact.lastname }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  Contacts Associated With Event\n  <h3>\n    <ul>\n      <li>Name: Brian Halligan</li>\n\n      <li>Name: Dharmesh Shah</li>\n\n      <li>Name: Yamini Rangan</li>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtenir les détails d'un type d'objet personnalisé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour obtenir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ID"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fullyQualifiedName"
      }), ", les ID d'association et d'autres détails d'un type d'objet personnalisé, vous pouvez effectuer une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " sur l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/crm/objects/custom-objects",
        children: "API schéma d'objets CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), " pour les types d'objets spécifiques au compte inclut l'ID du compte HubSpot, il est donc recommandé d'éviter de l'utiliser lorsque vous développez votre code pour plusieurs comptes HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Champ Module d'objet CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour permettre aux créateurs de contenu de sélectionner les enregistrements CRM à afficher ou d'exécuter une logique, vous pouvez construire des modules qui incluent le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/en/docs/cms/building-blocks/module-theme-fields#crm-object",
        children: "champ d'objet CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, si vous souhaitez afficher les informations d'un produit, d'un contact, d'une entreprise, d'une transaction, d'un devis, d'un ticket ou d'un objet personnalisé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/CRM%20Object%20Field.png",
        alt: "Champ d'objet CRM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriels et ressources concernant les objets CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/essentials-for-getting-started-with-custom-objects",
          children: "Premiers pas avec les objets personnalisés"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-think-like-an-architect-by-building-scalable-custom-objects",
          children: "Penser comme un architecte : créer des objets personnalisés évolutifs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/crm-objects",
          children: "Créer des pages dynamiques avec des objets de CRM"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}