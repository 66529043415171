"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31236957802;
const slug = exports.slug = 'guides/api/crm/imports';
const title = exports.title = 'CRM-API | Importe';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Imports';
const metaDescription = exports.metaDescription = 'Mithilfe von Importen kann ein HubSpot-Account mit Daten von Objekten gefüllt werden, mit denen dann die Vertriebs-, Marketing- und Kundenservicetools arbeiten können.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "einen-import-starten",
  "label": "Einen Import starten",
  "parent": null
}, {
  "depth": 1,
  "id": "die-importrequest-daten-formatieren",
  "label": "Die importRequest-Daten formatieren",
  "parent": "einen-import-starten"
}, {
  "depth": 1,
  "id": "dateispalten-zu-hubspot-eigenschaften-zuordnen",
  "label": "Dateispalten zu HubSpot-Eigenschaften zuordnen",
  "parent": "einen-import-starten"
}, {
  "depth": 0,
  "id": "eine-datei-mit-einem-objekt-importieren",
  "label": "Eine Datei mit einem Objekt importieren",
  "parent": null
}, {
  "depth": 0,
  "id": "eine-datei-mit-mehreren-objekten-importieren",
  "label": "Eine Datei mit mehreren Objekten importieren",
  "parent": null
}, {
  "depth": 0,
  "id": "mehrere-dateien-importieren",
  "label": "Mehrere Dateien importieren",
  "parent": null
}, {
  "depth": 0,
  "id": "vorherige-importe-abrufen",
  "label": "Vorherige Importe abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "cancel-an-import",
  "label": "Cancel an import",
  "parent": null
}, {
  "depth": 0,
  "id": "view-and-troubleshoot-import-errors",
  "label": "View and troubleshoot import errors",
  "parent": null
}, {
  "depth": 0,
  "id": "beschr%C3%A4nkungen",
  "label": "Beschränkungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      img: "img",
      h3: "h3",
      strong: "strong",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Importe"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die Importe-API, um CRM-Datensätze und -Aktivitäten in Ihren HubSpot-Account zu importieren, z. B. Kontakte, Unternehmen und Notizen. Nach dem Import können Sie über die verschiedenen CRM-API-Endpunkte auf Datensätze und Aktivitäten zugreifen und diese aktualisieren, einschließlich der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "Kontakte-API"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-API"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Interaktionen-APIs"
      }), ". Sie können auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/crm-setup/import-objects",
        children: "Datensätze und Aktivitäten mit dem anleitenden Import-Tool nach HubSpot importieren"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bevor Sie mit dem Import beginnen, informieren Sie sich über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/crm-setup/set-up-your-import-file",
        children: "Objekte und Aktivitäten, die importiert werden können, sowie über die Datei- und Eigenschaftsanforderungen."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Import starten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können einen Import starten, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/Imports"
      }), " durchführen, die einen Anfragetext enthält, der angibt, wie die Spalten Ihrer Importdatei den zugehörigen CRM-Eigenschaften in HubSpot zugeordnet werden sollen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "API-Importe werden als form-data-Typ-Anfragen gesendet, wobei der Anfragetext die folgenden Felder enthält:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-the-importrequest-data",
          children: "importRequest"
        }), ": ein Textfeld, das den Anfrage-JSON enthält."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#map-file-columns-to-hubspot-properties",
          children: "files"
        }), ": Ein Dateifeld, das die Importdatei enthält."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie für den Anfrage-Header einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Content-Type"
      }), "-Header mit dem Wert ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), " hinzu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der folgende Screenshot zeigt, wie Ihre Anfrage bei der Verwendung einer Anwendung wie Postman aussehen könnte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-import-request-no-response0.png",
        alt: "postman-import-request-no-response0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Die importRequest-Daten formatieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Definieren Sie in der JSON-Anfrage die Details der Importdatei, einschließlich der Zuordnung der Spalten der Tabelle zu HubSpot-Daten. Ihr Anfrage-JSON sollte die folgenden Felder enthalten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), ": der Name der Imports. In HubSpot ist dies der Name, der im ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/de/crm-setup/view-and-analyze-previous-imports",
            children: "Import-Tool angezeigt wird"
          }), ", sowie der Name, auf den Sie in anderen Tools wie Listen verweisen können."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "importOperations"
          }), ": ein optionales Feld, das angibt, ob der Import für ein bestimmtes Objekt oder eine bestimmte Aktivität ", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/de/crm-setup/import-objects#choose-how-to-import",
            children: ["Datensätze erstellen und aktualisieren, sie ", (0, _jsxRuntime.jsx)("u", {
              children: "nur"
            }), " erstellen oder ", (0, _jsxRuntime.jsx)("u", {
              children: "nur"
            }), " aktualisieren soll"]
          }), ". Fügen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "objectTypeId"
          }), " für das Objekt/die Aktivität ein und ob Datensätze erstellt und aktualisiert (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPSERT"
          }), "), nur erstellt (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CREATE"
          }), ") oder nur aktualisiert (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPDATE"
          }), ") werden sollen. Zum Beispiel würde das Feld in Ihrer Anfrage wie folgt aussehen: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"importOperations\": {\"0-1\": \"CREATE\"}"
          }), ". Wenn Sie dieses Feld nicht einschließen, ist der Standardwert für den Import ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPSERT"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "dateFormat"
          }), ": das Format für Daten, die in der Datei berücksichtigt werden. Standardmäßig wird dieser auf ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "MONTH_DAY_YEAR"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "festgelegt, aber Sie können auch DAY_MONTH_YEAR"
          }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "YEAR_MONTH_DAY"
          }), " verwenden."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "marketableContactImport"
          }), ": ein optionales Feld, um den ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/de/contacts/marketing-contacts",
            children: "Marketingstatus"
          }), " von Kontakten in Ihrer Importdatei anzugeben. Dies wird nur verwendet, wenn Kontakte in Accounts importiert werden, die ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/de/contacts/marketing-contacts#check-your-access-to-marketing-contacts",
            children: "Zugriff auf Marketingkontakte haben"
          }), ". Um die Kontakte in der Datei als Marketingkontakte einzustufen, verwenden Sie den Wert ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), ". Um die Kontakte in der Datei als Nicht-Marketingkontakte einzustufen, verwenden Sie den Wert ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "createContactListFromImport"
          }), ": ein optionales Feld zum ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/de/crm-setup/import-objects#create-list-from-import",
            children: "Erstellen einer statischen Liste der Kontakte"
          }), " aus Ihrem Import. Um eine Liste aus Ihrer Datei zu erstellen, verwenden Sie den Wert ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "files"
          }), ": ein Array, das Ihre Importdateiinformationen enthält."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileName"
            }), ": der Name der Importdatei."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileFormat"
            }), ": das Format der Importdatei. Verwenden Sie bei CSV-Dateien den Wert ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), ". Verwenden Sie bei Excel-Dateien den Wert ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SPREADSHEET"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileImportPage"
            }), ": enthält das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), "-Array, das zum Zuordnen von Daten aus Ihrer Importdatei zu HubSpot-Daten erforderlich ist. Im Folgenden erfahren Sie mehr über das Zuordnen von Spalten."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Dateispalten zu HubSpot-Eigenschaften zuordnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie innerhalb des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "columnMappings"
      }), "-Arrays einen Eintrag für jede Spalte in Ihrer Importdatei ein, der der Reihenfolge Ihrer Tabelle entspricht. Fügen Sie für jede Spalte die folgenden Felder hinzu:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnObjectTypeId:"
        }), " der Name oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), "-Wert des Objekts oder der Aktivität, zu dem/der die Daten gehören. Eine vollständige Liste der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), "-Werte finden Sie in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "diesem Artikel"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnName:"
        }), " der Name der Spaltenüberschrift."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "propertyName:"
        }), " der interne Name der HubSpot-Eigenschaft, welcher die Daten zugeordnet werden. Für die gemeinsame Spalte in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "Importe von mehreren Dateien"
        }), " muss ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyName"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " sein, wenn das Feld ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toColumnObjectTypeId"
        }), " verwendet wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnType:"
        }), " Wird verwendet, um anzugeben, dass eine Spalte eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file#:~:text=Name%2C%20Email%20Address",
          children: "„Eindeutige ID“-Eigenschaft"
        }), ".-,Unique%20identifier,-%3A%20a%20property) enthält. Verwenden Sie abhängig von der Eigenschaft und dem Ziel des Imports einen der folgenden Werte:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "HUBSPOT_OBJECT_ID:"
            }), " die ID eines Datensatzes. Beispielsweise kann Ihre Kontaktimportdatei eine Spalte mit der ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Datensatz-ID"
            }), " enthalten, in der die ID des Unternehmens gespeichert ist, dem die Kontakte zugeordnet werden sollen."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "HUBSPOT_ALTERNATE_ID:"
            }), " eine eindeutige ID, die sich von der Datensatz-ID unterscheidet. Beispielsweise kann Ihre Kontaktimportdatei eine ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "E-Mail"
            }), "-Spalte enthalten, in der die E-Mail-Adressen der Kontakte gespeichert sind."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "FLEXIBLE_ASSOCIATION_LABEL"
            }), ": Berücksichtigen Sie diesen Spaltentyp, um anzugeben, dass die Spalte Zuordnungslabel enthält."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "ASSOCIATION_KEYS"
            }), ": Fügen Sie nur für Importe mit Zuordnungen gleicher Objekte diesen Spaltentyp für die eindeutige ID der Datensätze des gleichen Objekttyps hinzu, die Sie zuordnen. Beispielsweise muss in Ihrer Anfrage für einen Kontaktzuordnungsimport die Spalte ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "[E-Mail/Datensatz-ID] des zugeordneten Kontakt"
            }), " einen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnType"
            }), " vom Typ ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASSOCIATION_KEYS"
            }), " aufweisen. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file#same-object-association-imports",
              children: "Einrichten Ihrer Importdatei bei einem Import mit Zuordnungen des gleichen Objekttyps"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "toColumnObjectTypeId"
        }), ": bei Importen mit ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "mehreren Dateien"
        }), " oder mehreren Objekten der Name oder die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), " des Objekts, zu dem die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file#sample-import-files:~:text=To%20import%20and%20associate%20multiple%20objects%20in%20one%20file%2C%20include%20information%20about%20associated%20records%20and/or%20activities%20in%20the%20same%20row.%20In%20two%20files%2C%20use%20a%20common%20column%20to%20connect%20the%20records%20in%20each%20file.%20You%20can%20refer%20to%20the%20example%20files%20for%20more%20help%20importing%20and%20associating%20records.",
          children: "Gemeinsame Spalte"
        }), " oder das Zuordnungslabel gehört. Fügen Sie dieses Feld für die Eigenschaft „Gemeinsame Spalte“ in die Datei des Objekts ein, zu dem die Eigenschaft ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " gehört. Wenn Sie beispielsweise Kontakte und Unternehmen in zwei Dateien mit der Kontakteigenschaft ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-Mail"
        }), " als gemeinsame Spalte verknüpfen, fügen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toColumnObjectTypeId"
        }), " für die Spalte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-Mail"
        }), " in die ", (0, _jsxRuntime.jsx)("u", {
          children: "Unternehmensdatei"
        }), " ein."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "foreignKeyType"
        }), ": gilt nur für ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "Importe von mehreren Dateien"
        }), ", die Art der Zuordnung, die die gemeinsame Spalte verwenden soll, angegeben durch die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationCategory"
        }), ". Fügen Sie dieses Feld für die Eigenschaft „Gemeinsame Spalte“ in die Datei des Objekts ein, zu dem die Eigenschaft ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " gehört. Wenn Sie beispielsweise Kontakte und Unternehmen in zwei Dateien mit der Kontakteigenschaft ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-Mail"
        }), " als gemeinsame Spalte verknüpfen, fügen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "foreignKeyType"
        }), " für die Spalte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-Mail"
        }), " in die ", (0, _jsxRuntime.jsx)("u", {
          children: "Unternehmensdatei"
        }), " ein."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "associationIdentifierColumn"
        }), ": nur für ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "Importe von mehreren Dateien"
        }), ", gibt die Eigenschaft an, die in der gemeinsamen Spalte zum Zuordnen der Datensätze verwendet wird. Fügen Sie dieses Feld für die Eigenschaft „Gemeinsame Spalte“ in die Datei des Objekts ein, zu dem die Eigenschaft gehört. Wenn Sie beispielsweise Kontakte und Unternehmen in zwei Dateien mit der Kontakteigenschaft ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-Mail"
        }), " als gemeinsame Spalte verknüpfen, legen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationIdentifierColumn"
        }), " als ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " für die Spalte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-Mail"
        }), " in der ", (0, _jsxRuntime.jsx)("u", {
          children: "Kontaktdatei"
        }), " fest."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Datei mit einem Objekt importieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend finden Sie ein Beispiel für einen Anfragetext für das Importieren einer Datei, um Kontakte zu erstellen:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"Nov-event-leads.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "# This example a local file named 'test_import.csv'\n# This file contains a list of contact records to import.\n\nimport requests\nimport json\nimport os\n\nurl = \"https://api.hubapi.com/crm/v3/imports\"\n\nYOUR_ACCESS_TOKEN = 'xxxxxxx';\n\n# Content-Type header will be set automatically by the requests library\nheaders = {\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\ndata = {\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"Nov-event-leads.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": True,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\n\ndatastring = json.dumps(data)\n\npayload = {\"importRequest\": datastring}\n\ncurrent_dir = os.path.dirname(__file__)\nrelative_path = \"./test_import.csv\"\n\nabsolute_file_path = os.path.join(current_dir, relative_path)\n\nfiles = [\n    ('files', open(absolute_file_path, 'rb'))\n]\nprint(files)\n\nresponse = requests.request(\"POST\", url, data=payload, files=files, headers=headers)\n\nprint(response.text.encode('utf8'))\nprint(response.status_code)\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "# Using this endpoint requires using sending multi-part form encoded data. Here is an example curl request:\n# importing a file named import_file.csv\n\n# create a variable for the importRequest JSON\nmyJSON=$(cat <<EOF\n{\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"import_file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\nEOF\n)\n\nYOUR_ACCESS_TOKEN=\"xxx-xxx-xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx\"\n\ncurl -v \\\n  -F \"files=@import_file.csv;type=text/csv\" \\\n  -F \"importRequest=$myJSON;type=application/json\" \\\n  -H \"Authorization: Bearer $YOUR_ACCESS_TOKEN\" \\\n  https://api.hubapi.com/crm/v3/imports\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Datei mit mehreren Objekten importieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend finden Sie einen Beispiel-Anfragetext für das Importieren und Zuordnen von Kontakten und Unternehmen in einer Datei mit Zuordnungslabeln:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"Association Label Import\",\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"association label example.xlsx\",\n      \"fileFormat\": \"SPREADSHEET\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Domain\",\n            \"propertyName\": \"domain\"\n          },\n          {\n            \"columnName\": \"Association Label\",\n            \"columnType\": \"FLEXIBLE_ASSOCIATION_LABEL\",\n            \"columnObjectTypeId\": \"0-1\",\n            \"toColumnObjectTypeId\": \"0-2\"\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mehrere Dateien importieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachfolgend finden Sie ein Beispiel für einen Anfragetext für das Importieren und Zuordnen von Kontakten und Unternehmen in zwei Dateien, wobei die Kontakteigenschaft ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "E-Mail"
      }), " die gemeinsame Spalte in den Dateien ist:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"Contact Company import\",\n  \"dateFormat\": \"YEAR_MONTH_DAY\",\n  \"files\": [\n    {\n      \"fileName\": \"contact-import-file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"associationIdentifierColumn\": true\n          }\n        ]\n      }\n    },\n    {\n      \"fileName\": \"company-import-file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Company name\",\n            \"propertyName\": \"name\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Company domain name\",\n            \"propertyName\": \"domain\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"toColumnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationTypeId\": 280,\n              \"associationCategory\": \"HUBSPOT_DEFINED\"\n            }\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei einer erfolgreichen Anfrage enthält die Antwort eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "importId"
      }), ", mit der Sie den Import abrufen oder abbrechen können. Nach Abschluss können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/import-and-export/view-and-analyze-previous-imports",
        children: "den Import in HubSpot anzeigen"
      }), ". Über API abgeschlossene Importe sind jedoch nicht als Option verfügbar, wenn Sie Datensätze nach Import in Ansichten, Listen, Berichten oder Workflows filtern."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorherige Importe abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um alle Importe von Ihrem HubSpot-Account abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/"
      }), " durch. Um Informationen für einen bestimmten Import abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Importe abrufen, werden Informationen zurückgegeben, einschließlich des Namens, der Quelle, des Dateiformats, der Sprache, des Datumsformats und der Spaltenzuordnungen des Imports. Der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), " des Imports wird ebenfalls zurückgegeben, was eine der folgenden Optionen sein kann:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "STARTED"
        }), ": HubSpot erkennt, dass der Import vorhanden ist, der Import hat jedoch noch nicht mit der Verarbeitung begonnen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), ": Der Import wird aktiv verarbeitet."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DONE"
        }), ": Der Import ist abgeschlossen. Alle Objekte, Aktivitäten oder Zuordnungen wurden aktualisiert oder erstellt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "FAILED"
        }), ": Es liegt ein Fehler vor, der beim Starten des Imports nicht erkannt wurde. Der Import wurde nicht abgeschlossen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELED"
        }), ": Der Benutzer hat den Export abgebrochen, während er sich in einem der Zustände \"", (0, _jsxRuntime.jsx)(_components.code, {
          children: "STARTED"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DEFERRED"
        }), " befand."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DEFERRED"
        }), ": Die maximale Anzahl von Importen (drei) wird gleichzeitig verarbeitet. Der Import wird gestartet, sobald einer der anderen Importe die Verarbeitung abgeschlossen hat."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Weitere Informationen zum Durchblättern und Beschränken der Ergebnisse finden Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpunkte"
      }), " oben in diesem Artikel."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Wenn Sie Importe mit einem Zugriffstoken für private Apps abrufen, enthält die Antwort nur Importe, die von dieser privaten App durchgeführt werden. Importe, die in HubSpot oder über eine andere private App abgeschlossen wurden, werden nicht zurückgegeben."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cancel an import"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To cancel an active import, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}/cancel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View and troubleshoot import errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view errors for a specific import, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}/errors"
      }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/troubleshoot-import-errors",
        children: "common import errors and how to resolve them"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For errors such as ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Incorrect number of columns"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Unable to parse"
      }), " JSON or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "404 text/html is not accepted"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that there is a column header for each column in your file, and that the request body contains a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnMapping"
        }), " entry for each column. The following criteria should be met:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "The column order in the request body and import file should match. If the column order doesn't match, the system will attempt to automatically reorder but may be unsuccessful, resulting in an error when the import is started."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Every column needs to be mapped. If a column is not mapped, the import request may still be successful, but would result in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Incorrect number of columns"
            }), " error when the import is started."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that the file's name and the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fileName"
        }), " field in your request JSON match, and that you've included the file extension in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fileName"
        }), " field. For example, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "import_name.csv."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that your header includes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type"
        }), " with a value of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "multipart/form-data"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "Example POST URL:\nhttps://api.hubapi.com/crm/v3/imports?\n\nExample importRequest JSON data:\nThis example contains 3 columns:\n - First name, mapped to the firstname contact property\n - Email, mapped to the email contact property\n - Company ID, which contains a list of company record IDs\n   that the contact will be assocated with.\n{\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"ignored\": false,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": null,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "# This example a local file named 'final_emails.csv'\n# This file contains a list of contact records to import.\n# Each row in the file contains a column named \"Company ID\" that contains the companyId\n# that the contact should be associated with.\n\nimport requests\nimport json\n\npost_url = 'http://api.hubapi.com/crm/v3/imports?hapikey=4324...5432'\n\nimportRequest = {\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": True,\n        \"columnMappings\": [\n          {\n            \"ignored\": False,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": None,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": None,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          },\n          {\n            \"ignored\": False,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": None,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          },\n          {\n            \"ignored\": False,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": None,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          }\n        ]\n      }\n    }\n  ]\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "<?php\n// This example imports a local file named 'import_file.csv'\n\n    $post_url = \"http://api.hubapi.com/crm/v3/imports?hapikey=1234...5342\";\n\n    $csv_file = new CURLFile('import_file.csv','text/csv');\n\n    $config_json = '{\"name\":\"test_import\",\"files\":[{\"fileName\":\"final_emails.csv\",\"fileImportPage\":{\"hasHeader\":true,\"columnMappings\":[{\"ignored\":false,\"columnName\":\"First Name\",\"idColumnType\":null,\"propertyName\":\"firstname\",\"foreignKeyType\":null,\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false},{\"ignored\":false,\"columnName\":\"Email\",\"idColumnType\":\"HUBSPOT_ALTERNATE_ID\",\"propertyName\":\"email\",\"foreignKeyType\":null,\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false},{\"ignored\":false,\"columnName\":\"Company ID\",\"idColumnType\":\"HUBSPOT_OBJECT_ID\",\"propertyName\":null,\"foreignKeyType\":{\"associationCategory\":\"HUBSPOT_DEFINED\",\"associationTypeId\":1},\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false}]}}]};type=application/json';\n\n    $post_data = array(\n        \"files\" => $csv_file,\n        \"importRequest\" => $config_json\n    );\n\n    $ch = curl_init();\n    @curl_setopt($ch, CURLOPT_POST, true);\n    @curl_setopt($ch, CURLOPT_URL, $post_url);\n    @curl_setopt($ch, CURLOPT_POSTFIELDS, $post_data);\n\n    $response    = @curl_exec($ch); //Log the response from HubSpot as needed.\n    $status_code = @curl_getinfo($ch, CURLINFO_HTTP_CODE); //Log the response status code\n    @curl_close($ch);\n    echo $status_code . \" \" . $response;\n?>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "# Using this endpoint requires using sending multi-part form encoded data. Here is an example curl request:\n# importing a file named import_file.csv\n\n# create a variable for the importRequest JSON\nmyJSON=$(cat <<EOF\n{\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"ignored\": false,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": null,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          }\n        ]\n      }\n    }\n  ]\n}\nEOF\n)\n\ncurl -v \\\n  -F \"files=@import_file.csv;type=text/csv\" \\\n  -F \"importRequest=$myJSON;type=application/json\" \\\n  http://api.hubapi.com/crm/v3/imports?hapikey=4123...4321\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie einen Fehler erhalten, überprüfen Sie, ob es doppelte Header gibt, z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type"
        }), ". Dies kann vorkommen, wenn Sie Postman verwenden oder wenn es im Header Ihres Python-Skripts enthalten ist. Entfernen Sie das Duplikat, bevor Sie die Anfrage abschließen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beschränkungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie die Importe-API verwenden, können Sie bis zu 80.000.000 Zeilen pro Tag importieren. Einzelne Importdateien sind jedoch auf 1.048.576 Zeilen oder 512 MB beschränkt, je nachdem, was zuerst erreicht wird."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Ihre Anfrage die Zeilen- oder Größenbeschränkung überschreitet, gibt HubSpot einen 429-HTTP-Fehler zurück. Wenn Sie sich diesen Beschränkungen nähern, wird empfohlen, Ihren Import in mehrere Anfragen aufzuteilen."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}