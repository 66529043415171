"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 124089652258;
const slug = exports.slug = 'guides/cms/marketplace/general-requirements';
const title = exports.title = 'HubSpot Template Marketplace listing page requirements';
const name = exports.name = 'Template Marketplace | Listing Page Requirements';
const metaDescription = exports.metaDescription = 'Learn more about the general requirements that a template needs to meet to be submitted to the Template Marketplace.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "list-a-template-on-the-hubspot-template-marketplace",
  "label": "List a template on the HubSpot Template Marketplace",
  "parent": null
}, {
  "depth": 1,
  "id": "listing-info",
  "label": "Listing Info",
  "parent": "list-a-template-on-the-hubspot-template-marketplace"
}, {
  "depth": 1,
  "id": "theme%2Fmodule-details",
  "label": "Theme/Module Details",
  "parent": "list-a-template-on-the-hubspot-template-marketplace"
}, {
  "depth": 1,
  "id": "category",
  "label": "Category",
  "parent": "list-a-template-on-the-hubspot-template-marketplace"
}, {
  "depth": 1,
  "id": "support",
  "label": "Support",
  "parent": "list-a-template-on-the-hubspot-template-marketplace"
}, {
  "depth": 1,
  "id": "review",
  "label": "Review",
  "parent": "list-a-template-on-the-hubspot-template-marketplace"
}, {
  "depth": 0,
  "id": "general-imagery-requirements",
  "label": "General imagery requirements",
  "parent": null
}, {
  "depth": 0,
  "id": "other-requirements",
  "label": "Other requirements",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      img: "img",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Listing page requirements"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to complying with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners/themes/branding-guidelines",
        children: "Theme Provider Branding Guidelines"
      }), ", template providers must adhere to the following requirements set by HubSpot when submitting templates and their listing pages to the HubSpot Template Marketplace. These standards apply to all templates on the Marketplace."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot reserves the right to change the information on your template listing submission to comply with the following requirements. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/best-practices-for-listing-your-theme-or-module-on-hubspots-asset-marketplace",
        children: "best practices"
      }), " for listing a theme or module on the HubSpot Template Marketplace."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "List a template on the HubSpot Template Marketplace"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your Template Marketplace provider account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Template Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create listing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select either ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create theme listing"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create module listing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review the information on the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Provider Information"
        }), " page, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review your payment information on the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Payment Information"
        }), " page, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "theme or module"
        }), " you want to list, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save and continue"
        }), ". You'll be directed to the listing editor."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-info.png",
        alt: "listing-info"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Listing Info"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Provide the following information:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listing name:"
          }), " the name that will appear on your template listing. Use a unique and descriptive name for your template. Please avoid the following:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The word ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "theme"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "module"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "section"
            }), " to identify the template type. Listings will already be categorized by type. (e.g. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Example Theme"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Easy Accordion Module"
            }), ")."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Your company name (e.g. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Growth by HubSpot"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Slider | HubSpot"
            }), ")."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Version numbers (e.g. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Growth - 1.21"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Growth v2"
            }), ") as the HubSpot Template Marketplace uses its own versioning system for published templates."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The word ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Hub"
            }), " (e.g. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Hub Growth"
            }), ")."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Using the term ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " when naming a free template. The marketplace can contain both free and paid versions of the same template, and naming a free template ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " can create confusion. The terms ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Plus"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Advanced"
            }), ", and other similar terms should only be used for the paid version of templates that have both free and paid versions."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Price:"
          }), " select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Free"
          }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Paid"
          }), ". If you’re listing a paid template, enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "price"
          }), ". After publishing, you cannot change between ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Free"
          }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Paid"
          }), " types."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Live example link:"
          }), " a link to a live version of your template so users can test it. Do not link to an image."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Thumbnail image:"
          }), " the image that appears in search results and on the storefront card."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "This image must showcase your theme or module as the primary focus of the image. At least 75% of the image's content should be your template."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Images will be removed at the HubSpot Ecosystem Team's discretion if they violate the guidelines below. Images ", (0, _jsxRuntime.jsx)("u", {
                children: "must not"
              }), " contain any of the following:"]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Excessive marketing verbiage, slogans, text, or branding (e.g. ", (0, _jsxRuntime.jsx)(_components.em, {
                  children: "Over 100 features plus support!!!!"
                }), " or superimposing text/branding over the image)."]
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Imagery of individuals (real or fictitious) posing, pointing, o holding items (e.g. an image of a person holding a computer with your template on the screen)."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Imagery that shows personal or private information."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Badges or banners containing information related to reviews and installs of your template, or website performance grading. These types of data can fluctuate and have the potential to mislead others. If you’re a HubSpot Partner and would like to display your partner tier or badge, please ensure it’s the most current version."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Examples of good theme thumbnail images:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/storefront-example.png",
            alt: "storefront-example"
          })
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/storefront-example-2.png",
          alt: "storefront-example-2"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Examples of good module thumbnail images:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-thumbnail-1.png",
            alt: "module-thumbnail-1"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-thumbnail-3.png",
            alt: "module-thumbnail-3"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Theme/Module Details"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Provide the following information:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Demo video (optional):"
          }), " the demo video should showcase your template in action and convey proper use cases. While optional, a video is recommended as buyers find them valuable when evaluating a purchase."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Videos must be in mp4 format."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "It is recommended to use a 4:3 or 16:9 ratio for optimal viewing."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Theme description/module overview:"
          }), " a summary of your template's key selling points. Descriptions should include the following:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "A summary"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Key selling points"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "How your template can help buyers achieve their goals"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "For theme descriptions, you do not need to include a listing of the modules or templates included in the theme as these are already listed on the template listing page."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For individual modules, it must be noted in the description if the module requires the use of a 3rd party account. For example, if your module makes use of the Google Maps Platform, include the note: ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "The use of this module requires a Google Cloud (Google Maps Platform) account"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Features:"
          }), " feature titles are displayed in the Template Marketplace list view and are one of the first things your buyers see."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "You must add a minimum of two and up to five features for a template."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Use the features section to highlight the following:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Key features of the template"
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Use cases and how it can solve your buyers' problems"
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "You can also add marketing-based imagery to convey feature sets."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Client Examples (Themes only):"
          }), " examples (i.e. functional websites of your clients) are important for customers to see how other companies are using your template. It allows potential buyers to get an idea of how to use your template and also see how customizable it can be. It's strongly recommended to ensure that you have written permission from your clients to link to their websites. If requested, please make your client’s written permission available for verification."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Module Images (Modules only):"
          }), " up to 20 images of your module to demonstrate how it integrates into a template. Modules must contain, at minimum, the following screenshots:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "How the module will appear on the page to front-end users."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Screenshots of both content and style settings."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/content-tab.png",
          alt: "content-tab"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/styles-tab.png",
          alt: "styles-tab"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The page-level editor settings of the module. If ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview#repeaters",
          children: "repeater content"
        }), " is used in the module, you must provide a screenshot of one instance of the repeating item settings."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/repeater-content.png",
        alt: "repeater-content"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Category"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Provide the following information:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Category and attributes:"
        }), " add categories and attributes for your themes or modules.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Themes:"
            }), " your business types, pages types, and page features must be relevant to the theme you are submitting."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modules:"
            }), " your template types and categories must be relevant to your module."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Search tags:"
        }), " add up to 15 keywords for your top features and style attributes.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "A set of default tags are assigned to your theme. If a particular tag isn't available, you can add a new tag."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Tags must be between 2–3 words long."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Use tags that accurately show the theme or module listed."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["You may not use tags that contain the following:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Company names such as ", (0, _jsxRuntime.jsx)(_components.em, {
                  children: "HubSpot"
                }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
                  children: "Example Company"
                }), "."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Subjective terms such as ", (0, _jsxRuntime.jsx)(_components.em, {
                  children: "top-rated"
                }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
                  children: "best"
                }), ", or ", (0, _jsxRuntime.jsx)(_components.em, {
                  children: "number one"
                }), "."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Tags that describe the template type such as ", (0, _jsxRuntime.jsx)(_components.em, {
                  children: "theme"
                }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
                  children: "module"
                }), ", or ", (0, _jsxRuntime.jsx)(_components.em, {
                  children: "email."
                }), " These are implied based on the template type being viewed."]
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Profanity or derogatory language."
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If you have more than two business types for your theme, you can add the extra types as tags in your listing."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Support"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Provide the following information:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Free support:"
        }), " select whether you offer free support to buyers of your template, and what languages you support."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Support channels:"
        }), " all support email addresses must be working email addresses. If you offer Live Chat, Facebook Messenger or Phone support, make sure these are in working order.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Avoid using ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "hubspot@[companyname].com"
            }), " as your support email address."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Self-support support resources:"
        }), " the setup documentation URL must link to a live website page with documentation on how to set up and use your template. You cannot use a contact page or a homepage. You can have downloadable documentation as a supplemental option, but live documentation must be present."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Terms of Service and Privacy Policy:"
        }), " all URLs entered must be linked to your website and refer to your company's terms and privacy policies. Do not use HubSpot's terms and privacy links."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Review"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Review"
      }), " tab, validate your template's code. To preview how your template will look like on the Marketplace, click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Preview listing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "General imagery requirements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Images and screenshots will be removed at HubSpot’s discretion if they violate the guidelines above. Templates must ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " include images from the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/files/how-can-i-use-stock-images-in-my-content-with-the-shutterstock-integration",
        children: "HubSpot-Shutterstock integration"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use full-size images of your template to showcase appearance and functionality. The following image dimensions are strongly recommended for the best viewing experience of your template listing:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Storefront Card thumbnail image:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Size:"
            }), " minimum 480px x 360px (4:3 ratio)"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Formats:"
            }), " JPG, JPEG, PNG"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Theme or Module images:"
          }), " you must have a minimum of two and up to 20 full-size images of your template."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Minimum width:"
            }), " 1160px"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Formats:"
            }), " JPG, JPEG, PNG"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Feature images:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Size:"
            }), " minimum 480px × 360px (4:3 ratio)"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Formats:"
            }), " JPG, JPEG, PNG"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Client examples"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Size:"
            }), " minimum 480px x 360px (4:3 ratio)"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Formats:"
            }), " JPG, JPEG, PNG"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Other requirements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "All verbiage, images, media, and default content used in the template must match what is advertised on the template listing page and demo site. For example, if your template listing is geared towards real estate with a working demo, the end user must receive that theme."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If upon purchase the end user is delivered a theme centered around fitness instead, this would be considered a bait-and-switch, which is unacceptable."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}