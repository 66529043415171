import { useViewport } from '../hooks/useViewport';
/**
 * Uses render props to render different desktop and mobile UIs based on
 * the `ViewportContext` queries. If no viewport context is provided, it will
 * render the desktop view.
 *
 * ```example
 *  <ResponsiveRenderer
 *    renderDesktop={() => (
 *      <DesktopView items={items} />
 *    )}
 *    renderMobile={() => (
 *      <MobileView items={items} />
 *    )}
 *  />
 * ```
 */
const ResponsiveRenderer = ({
  renderMobile,
  renderDesktop
}) => {
  const viewportContext = useViewport();
  return viewportContext && viewportContext.isMobile ? renderMobile() : renderDesktop();
};
export default ResponsiveRenderer;