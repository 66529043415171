"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694136;
const slug = exports.slug = 'guides/cms/content/forms';
const title = exports.title = 'Formularios de HubSpot';
const name = exports.name = 'Formularios de HubSpot';
const metaDescription = exports.metaDescription = 'Descubre cómo agregar formularios a tu sitio web de HubSpot o a páginas externas mediante módulos, HubL o el código de incrustación. Luego, aprende cómo diseñarlos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "el-m%C3%B3dulo-de-formulario-predeterminado",
  "label": "El módulo de formulario predeterminado",
  "parent": null
}, {
  "depth": 1,
  "id": "clonar-el-m%C3%B3dulo-predeterminado",
  "label": "Clonar el módulo predeterminado",
  "parent": "el-m%C3%B3dulo-de-formulario-predeterminado"
}, {
  "depth": 0,
  "id": "campos-de-formulario-en-m%C3%B3dulos-personalizados",
  "label": "Campos de formulario en módulos personalizados",
  "parent": null
}, {
  "depth": 1,
  "id": "limitar-las-opciones-de-formulario-en-el-editor",
  "label": "Limitar las opciones de formulario en el editor",
  "parent": "campos-de-formulario-en-m%C3%B3dulos-personalizados"
}, {
  "depth": 0,
  "id": "usar-el-c%C3%B3digo-de-incrustaci%C3%B3n-del-formulario",
  "label": "Usar el código de incrustación del formulario",
  "parent": null
}, {
  "depth": 1,
  "id": "a%C3%B1ade-internacionalizaci%C3%B3n",
  "label": "Añade internacionalización",
  "parent": "usar-el-c%C3%B3digo-de-incrustaci%C3%B3n-del-formulario"
}, {
  "depth": 0,
  "id": "eventos-de-formulario",
  "label": "Eventos de formulario",
  "parent": null
}, {
  "depth": 0,
  "id": "estilo-de-formulario",
  "label": "Estilo de formulario",
  "parent": null
}, {
  "depth": 1,
  "id": "formularios-de-estilo-a-trav%C3%A9s-del-m%C3%B3dulo-de-formulario-predeterminado-o-la-etiqueta-hubl",
  "label": "Formularios de estilo a través del módulo de formulario predeterminado o la etiqueta HubL",
  "parent": "estilo-de-formulario"
}, {
  "depth": 1,
  "id": "formularios-de-estilo-a-trav%C3%A9s-de-un-m%C3%B3dulo-personalizado",
  "label": "Formularios de estilo a través de un módulo personalizado",
  "parent": "estilo-de-formulario"
}, {
  "depth": 1,
  "id": "estilo-de-formularios-agregados-a-trav%C3%A9s-del-c%C3%B3digo-de-incrustaci%C3%B3n-de-formulario",
  "label": "Estilo de formularios agregados a través del código de incrustación de formulario",
  "parent": "estilo-de-formulario"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      strong: "strong",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Formularios de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usa los formularios de HubSpot para capturar información de los visitantes del sitio web, a la que puedes acceder a través de HubSpot. Puedes compartir enlaces a formularios directamente con los usuarios, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/forms",
        children: "enviar datos de formulario a través de la"
      }), " API e incrustarlos en las páginas de tu sitio web utilizando el CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los formularios son una parte central de la suite HubSpot y se pueden crear en cuentas de HubSpot de cualquier nivel de suscripción. Los formularios no solo son importantes para la conversión de clientes, sino también porque los datos de los formularios se pueden usar en otras herramientas y recursos de HubSpot, como contenido inteligente, listas, workflows, personalización de contenido y más."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms",
        children: "crear un formulario de HubSpot"
      }), ", puedes agregarlo a tus plantillas y páginas. Hay algunas maneras de agregar un formulario a una plantilla, dependiendo de su caso de uso:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-default-form-module",
          children: "Usar el módulo de formulario predeterminado"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#form-fields-in-custom-modules",
          children: "Agregar un campo de formulario a un módulo personalizado"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-hubl-tag"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-embed-code",
          children: "Incrustar usando el código de incrustación del formulario"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "El módulo de formulario predeterminado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tu plantilla tiene ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "áreas de arrastrar y soltar"
      }), ", los creadores de contenido pueden agregar el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "módulo de formulario predeterminado"
      }), " a una página desde el editor de páginas y, a continuación, configurar las opciones de formulario en la barra lateral izquierda."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para codificar un módulo de formulario directamente en una plantilla con áreas de arrastrar y soltar, haz referencia al módulo como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"dnd_area\" class='body-container body-container__landing', label='Main section' %}\n {% dnd_section vertical_alignment='MIDDLE' %}\n  {% dnd_column width=6, offset=6 %}\n   {% dnd_row %}\n\n    <!-- Form module tag for use in templates -->\n    {% dnd_module path='@hubspot/form' %}\n    {% end_dnd_module %}\n\n   {% end_dnd_row %}\n  {% end_dnd_column %}\n {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para agregar un módulo de formulario a una plantilla fuera de un área de arrastrar y soltar, haz referencia al módulo como un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), "estándar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con cualquier implementación, puedes agregar parámetros a la etiqueta del módulo para especificar configuraciones como el formulario a usar y las opciones de redireccionamiento, como se muestra en el ejemplo de código anterior. Consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "documentación predeterminada de los módulos"
      }), " para obtener más información sobre los parámetros disponibles."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Clonar el módulo predeterminado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de usar el módulo predeterminado tal como está, puedes clonarlo para que sea editable, lo que te permite personalizarlo según sea necesario. Por ejemplo, podrías clonar el módulo de formulario predeterminado, agregar un campo de color y luego envolver el HTML del módulo en una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), " con estilo para agregar el color como fondo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el administrador de diseño de la barra lateral izquierda, navega hasta la carpeta ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "@hubspot"
        }), ", luego haz clic con el botón derecho en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "form.module"
        }), " y selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Clonar módulo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/clone-form-module.png",
        alt: "clone-form-module"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Agregar campo"
        }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Color"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Agrega una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<section>"
        }), " alrededor del contenido HTML y luego incluye un estilo para hacer referencia al campo de color, como:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section style=\"background:{{ module.color_field.color }}\">"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/default-form-module-clone-section.png",
        alt: "default-form-module-clone-section"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campos de formulario en módulos personalizados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear un módulo personalizado, puedes incluir un formulario en él agregando un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#form",
        children: "campo de formulario"
      }), ", junto con la adición del fragmento de código del campo al HTML del módulo. Por ejemplo, es posible que desees agregar un formulario de solicitud de consulta a un módulo que contenga una imagen de un producto y una propuesta de valor descriptiva."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para agregar un campo de formulario a un módulo personalizado desde el gestor de diseño:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral derecha del editor de módulos, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Agregar campo"
        }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Formulario"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-manager-select-form-field.png",
        alt: "design-manager-select-form-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Después de agregar el campo, coloca el cursor sobre el campo en la barra lateral derecha, luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Acciones"
        }), " y selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar fragmento"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-copy-snippet.png",
        alt: "module-field-copy-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pega el fragmento en el campo HTML del módulo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-paste-snippet.png",
        alt: "module-field-paste-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Limitar las opciones de formulario en el editor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez agregados a una página, los creadores de contenido suelen tener control sobre muchos aspectos del formulario, incluido qué formulario usar y los campos del formulario en sí. Sin embargo, puedes limitar la cantidad de control dado en el editor de páginas modificando el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " del módulo de formulario ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "localmente"
      }), " para incluir los siguientes campos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Establece la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " para ocultar todos los controles de edición de formularios en línea en el módulo de formulario. Esto incluye los campos del formulario, el texto del botón de envío, las opciones de privacidad y consentimiento de datos y el CAPTCHA."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "required_property_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una matriz que especifica qué formularios se pueden seleccionar en función de los tipos de propiedad de los campos de formulario. Los valores incluyen: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"CONTACT\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"COMPANY\""
            }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"TICKET\""
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, si has creado un módulo que solo debe usarse para formularios que permiten a los visitantes ponerse en contacto con los diversos departamentos de servicios de tu empresa, podrías permitir que los creadores de contenido solo puedan seleccionar formularios que utilicen propiedades de tickets."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Form field\n{\n  \"id\": \"843b4f0f-0ed7-5b10-e86a-5cc8a0f11a0c\",\n  \"name\": \"form_field_1\",\n  \"display_width\": null,\n  \"label\": \"Form\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"form\",\n  \"disable_inline_form_editing\": true,\n  \"required_property_types\": [\"TICKET\"],\n  \"default\": {\n    \"response_type\": \"inline\",\n    \"message\": \"Thanks for submitting the form.\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar el código de incrustación del formulario"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando un módulo o etiqueta HubL no es ideal, o si quieres agregar un formulario a una página externa, puedes incrustarlo usando el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#add-the-form-embed-code",
        children: "código de incrustación del formulario"
      }), ". También puedes personalizar el código de incrustación del formulario para ampliar la funcionalidad del formulario. Ve todas las opciones de personalización del código de incrustación del formulario y más en la guía de referencia de formularios."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las opciones de personalización de formularios solo están disponibles para formularios creados en HubSpot que se han ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#with-css-in-your-external-stylesheet-marketing-hub-professional-enterprise-or-legacy-marketing-hub-basic-only",
            children: "configurado como HTML sin formato"
          }), ". La cuenta de HubSpot debe tener una suscripción a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "** Marketing Hub **"
          }), " o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "_ Content Hub _"
          }), " _ Professional _ o _ Enterprise_ ."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Los formularios de HubSpot sólo deben cargarse utilizando el archivo JavaScript alojado en HubSpot. No se ", (0, _jsxRuntime.jsx)("u", {}), " admite hacer una copia del código de incrustación del formulario y autoalojarlo. Cualquier mejora que HubSpot realice para mejorar la seguridad, el antispam, la accesibilidad y el rendimiento no se propagará a tu formulario si decides autoalojar el código de incrustación del formulario de HubSpot."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se muestra un ejemplo de un código de incrustación del formulario sin ninguna personalización."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script\n  charset=\"utf-8\"\n  type=\"text/javascript\"\n  src=\"//js.hsforms.net/forms/embed/v2.js\"\n></script>\n<script>\n  hbspt.forms.create({\n    region: 'na1',\n    portalId: '123456',\n    formId: 'df93f0c1-2919-44ef-9446-6a29f9a7f',\n  });\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número o cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de la cuenta de HubSpot en la que se creó el formulario. Se utiliza para recuperar la definición del formulario."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "formId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoria"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del formulario, que se utiliza para recuperar la definición del formulario."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "region"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-cloud-infrastructure-and-data-hosting-frequently-asked-questions",
              children: "región"
            }), " de la cuenta donde se creó el formulario. Se utiliza para recuperar la definición del formulario. Los valores posibles son ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "na1"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eu1"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Añade internacionalización"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por opción predeterminada, HubSpot proporciona una serie de mensajes de validación en muchos idiomas diferentes, a los que puedes acceder utilizando el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "locale"
      }), ". También puedes agregar idiomas personalizados o anular los mensajes de error y los meses/días del selector de fechas que se muestran en el formulario utilizando el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "translation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la internacionalización y los mensajes de error de validación de formularios en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "documentación de referencia de los formularios"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'en',\n  translations: {\n    en: {\n      fieldLabels: {\n        email: 'Electronic mail',\n      },\n      required: \"Hey! That's required!\",\n      submitText: 'Custom Submit Button Text',\n      submissionErrors: {\n        MISSING_REQUIRED_FIELDS: 'Please fill in all required fields!',\n      },\n    },\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eventos de formulario"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los formularios permiten dos maneras de vincular la funcionalidad a los eventos: devoluciones de llamada en el código de incrustación del formulario de HubSpot y eventos de formulario globales. Más información sobre las devoluciones de llamada del código de incrustación del formulario y los eventos de formulario globales disponibles en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "documentación de referencia de formularios"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example form embed code callback\nhbspt.forms.create({\n  portalId: '',\n  formId: '',\n  onBeforeFormSubmit: function ($form) {\n    // YOUR SCRIPT HERE\n  },\n});\n\n// Example listener\nwindow.addEventListener('message', (event) => {\n  if (\n    event.data.type === 'hsFormCallback' &&\n    event.data.eventName === 'onFormSubmitted'\n  ) {\n    someAnalyticsLib('formSubmitted');\n    console.log('Form Submitted! Event data: ${event.data}');\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Estilo de formulario"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si bien HubSpot ofrece un estilo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "formulario desde una configuración global"
      }), " y un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "nivel de configuración específico del formulario"
      }), ", también puedes diseñar un formulario dependiendo de cómo se agregue a tus páginas de CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " todos los formularios generados en el CMS de HubSpot (excluyendo el uso del código de incrustación del formulario) ignorarán cualquier estilo que se configure a través de los ajustes globales del formulario o los ajustes individuales del formulario."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formularios de estilo a través del módulo de formulario predeterminado o la etiqueta HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los formularios de HubSpot agregados a las páginas de HubSpot se pueden diseñar utilizando el CSS de tu sitio web. HubSpot incluye varias clases y atributos diferentes en los formularios que se generan donde se puede aplicar el estilo. Como punto de partida, consulta el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/elements/_forms.css",
        children: "formulario CSS de HubSpot Boilerplate"
      }), ", que representa las mejores prácticas sobre cómo diseñar formularios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formularios de estilo a través de un módulo personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los formularios dentro de los módulos personalizados pueden ser diseñados por CSS en el panel CSS del módulo dentro del administrador de diseño. Al mantener el alcance de CSS en el módulo, puedes asegurarte de que cada vez que se agrega el módulo a una página, el estilo viene con él. Se recomienda agregar un envoltorio al formulario y luego usar ese envoltorio como el selector de nivel superior para el CSS. Esto evitará que el estilo de formulario de tu módulo personalizado se sobrescriba con estilos adicionales en la hoja de estilos principal de tu sitio web. A continuación se muestra una captura de pantalla del módulo personalizado desde arriba con el estilo de formulario agregado al panel CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/form-module-css-pane-4.jpg",
        alt: "Módulo personalizado con CSS"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilo de formularios agregados a través del código de incrustación de formulario"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al usar el código de incrustación del formulario, puedes diseñar el formulario usando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "configuración de estilo global del formulario"
      }), " o usando el CSS de tu sitio web."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El uso de la configuración de estilo de formulario global te permite configurar los ajustes predeterminados para cada formulario en la cuenta. También puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "anular estos estilos en un formulario individual dentro del editor de formularios"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/global-form-styling-options-4.png",
        alt: "Estilos de formulario globales"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tienes una suscripción a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        })
      }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Professional"
      }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", puedes seleccionar la opción ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Establecer como formulario HTML sin formato"
      }), " al crear un formulario. Esta configuración hace que el formulario se muestre como HTML en lugar de un iframe, lo que facilita el estilo del formulario incrustado con CSS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site",
        children: "estilo de formularios incrustados en la Base de conocimientos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/set-as-raw-html-form-4.jpg",
        alt: "Establecer como formulario HTML sin formato"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}