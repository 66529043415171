"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42765024851;
const slug = exports.slug = 'guides/api/app-management/oauth/tokens';
const title = exports.title = 'OAuth | Verwalten von Token';
const name = exports.name = 'EMEA DACH (de) vNext Docs DP - OAuth Tokens';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "oauth-2.0-zugriffs--und-aktualisierungstoken-abrufen",
  "label": "OAuth 2.0-Zugriffs- und Aktualisierungstoken abrufen:",
  "parent": null
}, {
  "depth": 0,
  "id": "informationen-f%C3%BCr-oauth-2.0-zugriffs--oder-aktualisierungstoken-abrufen",
  "label": "Informationen für OAuth 2.0-Zugriffs- oder Aktualisierungstoken abrufen:",
  "parent": null
}, {
  "depth": 0,
  "id": "oauth-2.0-aktualisierungstoken-l%C3%B6schen",
  "label": "OAuth 2.0-Aktualisierungstoken löschen:",
  "parent": null
}, {
  "depth": 0,
  "id": "verwenden-von-oauth-2.0-zugriffstoken",
  "label": "Verwenden von OAuth 2.0-Zugriffstoken:",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      strong: "strong",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 10,
        children: [(0, _jsxRuntime.jsx)(_components.h1, {
          children: "Verwalten von Token"
        }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth 2.0-Zugriffs- und Aktualisierungstoken abrufen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie den Code, den Sie erhalten, nachdem ein Benutzer Ihre App autorisiert hat, um ein Zugriffs- und Aktualisierungstoken abzurufen. Das Zugriffstoken wird verwendet, um Anfragen zu authentifizieren, die Ihre App vornimmt. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Zugriffstoken laufen nach sechs Stunden ab"
      }), ". Sie können jedoch mithilfe des Aktualisierungstoken-Endpunkts ein neues Zugriffstoken abrufen, wenn das erste Zugriffstoken abgelaufen ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie ein zuvor erhaltenes Aktualisierungstoken, um ein neues Zugriffstoken zu generieren. Zugriffstoken laufen nach sechs Stunden ab. Wenn Sie Offline-Zugriff auf HubSpot-Daten benötigen, speichern Sie das Aktualisierungstoken, das Sie beim Initiieren Ihrer OAuth-Integration erhalten, und verwenden Sie es, um ein neues Zugriffstoken zu generieren, sobald das ursprüngliche abgelaufen ist."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Hinweis"
      }), ": HubSpot-Zugriff Zugriffstoken variieren in der Größe, da wir deren Code-Informationen ändern. Damit alle Änderungen berücksichtigt werden können, empfehlen wir, bis zu 300 Zeichen für Token einzuplanen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informationen für OAuth 2.0-Zugriffs- oder Aktualisierungstoken abrufen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rufen Sie die Meta-Daten für ein Zugriffs- oder Aktualisierungstoken ab. So können Sie die E-Mail-Adresse des HubSpot-Benutzers, für den das Token erstellt wurde, sowie die Hub-ID abrufen, mit der das Token verknüpft ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth 2.0-Aktualisierungstoken löschen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Löscht ein Aktualisierungstoken. Sie können diese Option verwenden, um Ihr Aktualisierungstoken zu löschen, wenn ein Benutzer Ihre App deinstalliert."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Hinweis:"
      }), " Dadurch wird nur das Aktualisierungstoken gelöscht. Mit dem Aktualisierungstoken generierte Zugriffstoken sind davon nicht betroffen. Darüber hinaus wird dadurch weder eine Anwendung von einem HubSpot-Account deinstalliert noch die Synchronisierung von Daten zwischen einem Account und einer verknüpften Anwendung behindert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwenden von OAuth 2.0-Zugriffstoken:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "// Authorization: Bearer {token}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "curl --request GET \\"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "--url 'https://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false' \\"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "--header 'authorization: Bearer CJSP5qf1KhICAQEYs-gDIIGOBii1hQIyGQAf3xBKmlwHjX7OIpuIFEavB2-qYAGQsF4'"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{contacts: .....}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Beispiel ist das Zugriffstoken:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "CJSP5qf1KhICAQEYs-gDIIGOBii1hQIyGQAf3xBKmlwHjX7OIpuIFEavB2-qYAGQsF4"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Hinweise:"
      }), " Wenn Sie OAuth 2.0-Zugriffstoken verwenden, sollten Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "nicht"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hapikey="
      }), " in der Anfrage einschließen. Der Autorisierungs-Header wird anstelle des Abfrageparameters verwendet."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}