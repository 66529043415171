"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358768;
const slug = exports.slug = 'guides/api/cms/hubdb';
const title = exports.title = 'CMS API | HubDB';
const name = exports.name = 'CMS API | HubDB';
const metaDescription = exports.metaDescription = 'HubDBエンドポイントを使用して、HubDBデータテーブル内のデータを取得および管理することができます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%AC%E3%83%BC%E3%83%88%E5%88%B6%E9%99%90",
  "label": "レート制限",
  "parent": null
}, {
  "depth": 0,
  "id": "%E4%B8%8B%E6%9B%B8%E3%81%8D%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%81%A8%E5%85%AC%E9%96%8B%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB",
  "label": "下書きテーブルと公開テーブル",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "HubDBテーブルを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E5%88%97%E3%81%AE%E8%BF%BD%E5%8A%A0",
  "label": "テーブル列の追加",
  "parent": "hubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E8%A1%8C%E3%81%AE%E8%BF%BD%E5%8A%A0",
  "label": "テーブル行の追加",
  "parent": "hubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "csv%E3%81%8B%E3%82%89%E3%81%AE%E8%A1%8C%E3%81%AE%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88",
  "label": "CSVからの行のインポート",
  "parent": "hubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E6%97%A5%E4%BB%98%E3%81%AE%E6%9B%B8%E5%BC%8F%E8%A8%AD%E5%AE%9A",
  "label": "日付の書式設定",
  "parent": "hubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%AA%E3%82%BB%E3%83%83%E3%83%88%E3%82%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "リセットオプション",
  "parent": "hubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "hubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%AE%E5%8F%96%E5%BE%97",
  "label": "HubDBデータの取得",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%BF%94%E3%81%95%E3%82%8C%E3%81%9F%E8%A1%8C%E3%81%AB%E5%AF%BE%E3%81%99%E3%82%8B%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC%E3%81%AE%E9%81%A9%E7%94%A8",
  "label": "返された行に対するフィルターの適用",
  "parent": "hubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%AE%E5%8F%96%E5%BE%97"
}, {
  "depth": 1,
  "id": "%E8%BF%94%E3%81%95%E3%82%8C%E3%81%9F%E8%A1%8C%E3%81%AE%E4%B8%A6%E3%81%B9%E6%9B%BF%E3%81%88",
  "label": "返された行の並べ替え",
  "parent": "hubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%AE%E5%8F%96%E5%BE%97"
}, {
  "depth": 0,
  "id": "%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8%E7%94%A8%E3%81%AEhubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%81%AE%E8%A8%AD%E5%AE%9A",
  "label": "動的ページ用のHubDBテーブルの設定",
  "parent": null
}, {
  "depth": 0,
  "id": "v3%E3%81%A7%E3%81%AE%E5%A4%89%E6%9B%B4%E7%82%B9",
  "label": "v3での変更点",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS API | HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDBは、スプレッドシートと同じように、テーブル（表）の行、列、セルでデータを表現するリレーショナル データ ストアです。HubDBテーブルを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview#creating-your-first-table",
        children: "HubSpotアカウント内"
      }), "で追加したり変更したりできますが、ここに記載されているAPIエンドポイントを使用することもできます。ウェブサイトや", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "プログラマブルEメール"
      }), "でHubDBテーブルのデータを使用する方法については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubSpotのCMS開発者向けドキュメント"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotウェブサイトページと同様に、HubDBテーブルは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "draft"
      }), "バージョンと", (0, _jsxRuntime.jsx)(_components.code, {
        children: "published"
      }), "バージョンをサポートしています。これにより、公開中のページに影響を与えずに、テスト用または手動による承認プロセス用にテーブル内のデータを更新することができます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#draft-vs-live-tables",
        children: "下書きテーブルと公開テーブル"
      }), "について詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "公開アクセスを許可する"
      }), "ようにテーブルが設定されている場合、クエリーパラメーター", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), "を介してHubSpotアカウントIDを指定すると、認証なしでテーブルと行の公開バージョンにアクセスできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDB APIのv2から移行しようとしている場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#changes-in-v3",
        children: "最新の（v3）APIの変更点"
      }), "について詳しくご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "をサポートするエンドポイントは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), "もサポートしていますから、JavaScriptとアカウントIDを使用してクライアントサイドでテーブル内のデータにアクセスできます。その他の方法および「Get all tables」エンドポイントでは認証が必要であり、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), "はサポートされていません。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "レート制限"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDBのAPIリクエストには、リクエストの種類に応じて異なるレート制限が適用されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["認証を必要としない", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエスト（クライアントサイドのJavaScriptリクエストを含む）は、1秒あたり10件に限定されます。これらのリクエストは1日あたりの上限には計上されません。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "認証を使用"
        }), "するその他全てのリクエストには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api",
          children: "標準的な制限"
        }), "が適用されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "下書きテーブルと公開テーブル"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDBテーブルには下書きバージョンと公開バージョンの両方があります。公開バージョンは非公開にすることもできます。非公開にしてテーブル内のデータを更新すれば、他の公開ページに影響を与えずに、ページのプレビューやテスト、手動による承認プロセスを行うことができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このAPIでは、テーブルの下書きバージョンと公開バージョンに別々のエンドポイントが指定されています。例えば、以下のエンドポイントに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信することで、テーブルの公開バージョンを取得できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、下書きはできているが未公開になっているコンテンツを取得するには、URLの末尾に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/draft"
      }), "を追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["下書きデータをレビューした後、HubSpotでプッシュできます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/push-live"
      }), "エンドポイントを使用することもできます。また、エンドポイント", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/reset"
      }), "を介して下書きデータを破棄することもでき、こうすれば中断なしでデータを現在の公開バージョンに戻すことができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDBテーブルを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDBテーブルを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエスト本文で、次の必須フィールドを指定します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テーブルの内部名。テーブルの作成後に、この「name」を変更することはできません。「name」には半角の英小文字、数字、アンダースコアのみ含めることができますが、数字で始めることはできません。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"my_table\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーがHubSpotでテーブルを編集するときに表示される、テーブルのラベル。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\":\"My table\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "さらに、次の任意のフィールドを指定できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["テーブルを", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#configuring-hubdb-tables-for-dynamic-pages",
              children: "動的ページの作成"
            }), "に使用可能にするかどうかを指定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"useForPages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowPublicAPIAccess"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "承認なしでテーブルを読み取り可能にするかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowPublicApiAccess\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowChildTables"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テーブルの子テーブルを作成できるかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowChildTables\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableChildTablePages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["子テーブルを使用して", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "マルチレベルの動的ページ"
            }), "を作成するかどうかを指定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"enableChildTablePages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["テーブルの列。列プロパティーの詳細については、「", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-table-columns",
              children: "テーブル列を追加する"
            }), "」のセクションをご覧ください。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "See \"Add table columns\" section below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "列を追加せずにテーブルを作成するためのリクエストは、次のような内容になります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"name\": \"test_table\",\n  \"label\": \"Test Table\",\n  \"useForPages\": false,\n  \"allowPublicApiAccess\": false,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"columns\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テーブル列の追加"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDBテーブルの各列は、次のプロパティーを使用して定義可能です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（必須）列の内部名。列の作成後にこれを変更することはできません。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"row_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "任意。ユーザーがHubSpotで列を編集するときに表示される、列のラベル。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\": \"Row label\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["列のデータタイプ。次のいずれかにする必要があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TEXT"
                }), "：テキストフィールド。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "RICHTEXT"
                }), "：基本的なHTML書式設定をサポートするテキストフィールド。HTMLがHubSpotで編集可能になるかどうかを左右する可能性があるため、未加工のHTMLには推奨されません。HubSpotでコードを編集すると、コードがレンダリングされる方法にも影響が及ぶ可能性があります。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NUMBER"
                }), "：数値フィールド。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOOLEAN"
                }), "：HubSpotでチェックボックスとして表示されます。チェックボックスをオフの状態で表示させるには", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "0"
                }), "を、オンの状態で表示させるには", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                }), "を使用します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATE"
                }), "：特定の日付を、UTC深夜0時に設定されたミリ秒単位のタイムスタンプとして格納します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATETIME"
                }), "：日付と時刻をミリ秒単位のタイムスタンプとして格納します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "SELECT"
                }), "：この列を、いくつかのオプションのうち1つにのみ設定できます。必須プロパティーについては、下記の", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), "フィールドを参照してください。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MULTISELECT"
                }), "：この列を、いくつかのオプションのうち1つ以上に設定できます。必須プロパティーについては、下記の", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), "フィールドを参照してください。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "LOCATION"
                }), "：場所の緯度と経度を格納します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "IMAGE"
                }), "：画像のURLを格納します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "VIDEO"
                }), "：動画のプレーヤーIDを格納します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FOREIGN_ID"
                }), "：この列は、別のHubDBテーブルに含まれる列を参照します。さらに、次のプロパティーを使用して、該当する別のHubDBテーブルを定義する必要もあります。", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: "foreignTableId：別のHubDBテーブルのID。"
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: "foreignColumnId：別のHubDBテーブル内の列のID。"
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CURRENCY"
                }), "：数値を通貨の値として格納します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), "：ファイルマネージャーからファイルを格納します。さらに、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "fileType"
                }), "フィールドを含めることで、全てのファイルタイプをフィールドに格納できるか（", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), "）、PDFなどの特定のドキュメントタイプのみ格納できるか（", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DOCUMENT"
                }), "）を指定する必要もあります。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"type\": \"type\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["selectタイプとmultiselectタイプの列で使用できるオプションのリスト。各オプションは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "に加え、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), "に設定された", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), "を使用して定義されます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option\": [{\"name\":\"Option 1\", \"type\":\"option\"}, {\"name\": \"Option 2\", \"type\": \"option\"}]"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記のフィールドを使用して新しいHubDBテーブルを作成するためのリクエストは、次のような内容になります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"label\": \"Test Table\",\n  \"name\": \"test_table\",\n  \"columns\": [\n    {\n      \"name\": \"text_column\",\n      \"label\": \"Text Column\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"number_column\",\n      \"label\": \"Number Column\",\n      \"archived\": false,\n      \"type\": \"NUMBER\"\n    },\n    {\n      \"name\": \"multiselect\",\n      \"label\": \"Multi Select Column\",\n      \"archived\": false,\n      \"type\": \"multiselect\",\n      \"options\": [\n        {\n          \"name\": \"Option 1\",\n          \"type\": \"option\"\n        },\n        {\n          \"name\": \"Option 2\",\n          \"type\": \"option\"\n        }\n      ]\n    }\n  ],\n  \"useForPages\": true,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"allowPublicApiAccess\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーブルの作成後、列には昇順のIDが割り当てられます。既存の列を更新する際には、入力オブジェクトに列の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "フィールドを含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テーブル行の追加"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["行を追加するには、APIを使用して手動で行う方法と、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#import-rows-from-csv",
        children: "CSVファイルから行をインポート"
      }), "する方法があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDBテーブルに行を追加するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/rows"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "各テーブル行には、次のフィールドを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["列名とその列に追加する値を指定する、キーと値のペアからなるリスト。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "いずれかの列に特定の値を設定する必要がない場合は、キー／値ペアのリストでその列名を省略できます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"values\": { \"text_column\": \"sample text\", \"number_column\": 76}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "動的ページに対して有効になっている"
            }), "テーブルの場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), "は、この行で作成されるページに使用されるパスサフィックスです。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"path\": \"example_url_path\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "動的ページに対して有効になっている"
            }), "テーブルの場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "は、この行で作成されるページに使用されるHTMLタイトルです。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"Example Title\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "マルチレベルの動的ページ"
            }), "を作成する場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), "は子テーブルのIDを指定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableId\": 123456"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記のフィールドを使用したリクエストの例を以下に示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"values\": {\n    \"text_column\": \"sample text value\",\n    \"number_column\": 76,\n    \"rich_text_column\": \"<strong>This is a styled paragraph.</strong>\",\n    \"date_column\": 1591228800000,\n    \"date_time_column\": 1604450520000,\n    \"boolean_column\": 1,\n    \"select_column\": {\n      \"name\": \"option 1\",\n      \"type\": \"option\"\n    },\n    \"multiselect_column\": [\n      {\n        \"name\": \"Option 1\",\n        \"type\": \"option\"\n      },\n      {\n        \"name\": \"Option 2\",\n        \"type\": \"option\"\n      }\n    ],\n    \"url_column\": \"https://www.hubspot.com/marketing\",\n    \"video_column\": 3392210008,\n    \"image_column\": {\n      \"url\": \"https://f.hubspotusercontentqa00.net/hubfs/99992002/image3%20(1).jpg\",\n      \"width\": 1600,\n      \"height\": 900,\n      \"type\": \"image\"\n    },\n    \"foreign_id_column\": [\n      {\n        \"id\": \"4364402239\",\n        \"type\": \"foreignid\"\n      },\n      {\n        \"id\": \"4364402240\",\n        \"type\": \"foreignid\"\n      }\n    ]\n  },\n  \"path\": \"test_path\",\n  \"name\": \"test_title\",\n  \"childTableId\": \"1902373\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CSVからの行のインポート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CSVファイルからHubDBテーブルにデータをインポートするには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft/import"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["インポートエンドポイントは、次のような", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを受け入れます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "config"
        }), "**：**インポートで使用されるJSON形式のオプションのセット。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "file"
        }), "**：**インポートするCSVファイル。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), "には、次のフィールドをJSON文字列として含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "skipRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["スキップするヘッダー行の数。このフィールドの値は既定で", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "に設定されます。この場合、最初の行はヘッダーとして扱われてスキップされます。全ての行が有効なデータである場合は、このフィールドの値を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), "に設定してください。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"skipRows\": 0"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "separator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["CSVファイル内の列区切り文字。既定では", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\",\""
            }), "（半角カンマ）に設定されます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"separator\": \",\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["行のID（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), "）が入っているソースファイル内の列のインデックス。この列が指定されている場合、インポートされるCSVファイル内のIDが一致する行によって、テーブル内の既存の行が更新されます。これは任意のフィールドであり、初めてデータをテーブルにインポートするときには無視できます。詳細については、下記の「", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "リセットオプション"
            }), "」セクションを参照してください。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"idSourceColumn\": 1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "resetTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["既定では", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "に設定されます。この場合、既存の行を1つも除去することなく、テーブルの行が更新されます。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定すると、スプレッドシートの行によってテーブルのデータが上書きされます。つまり、スプレッドシート内に含まれない行はテーブルから削除されます。詳細については、下記の「", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "リセットオプション"
            }), "」セクションを参照してください。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"resetTable\": true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "動的ページに対して有効になっている"
            }), "テーブルの場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            }), "は、行の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "が格納されているCSVファイル内の列を指定します。列番号は昇順で、最初の列は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "です。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"nameSourcecolumn\": 5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "動的ページに対して有効になっている"
            }), "テーブルの場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            }), "は、行の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), "が格納されているCSVファイル内の列を指定します。列番号は昇順で、最初の列は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "です。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"pathSourcecolumn\": 6"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["CSVファイル内の、行の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), "が入っている列を指定します。列番号は昇順で、最初の列は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "です。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableSourcecolumn\": 3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ソースファイル内の列とインポート先HubDBテーブル内の列の間のマッピングリスト。各マッピングは次の形式でなければなりません：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"source\":1,\"target”:\"columnIdOrName\"}"
            }), " ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["**source：**ソースファイル内の列インデックス。例えば", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                }), "は2番目の列です。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["**target：**HubDBテーブル列のIDまたは名前。", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#retrieve-hubdb-data",
                  children: "テーブルの詳細情報を取得する"
                }), "ことで、列のIDや名前を確認できます。"]
              })]
            }), "ファイルに", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), "列がある場合は、それを", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), "に含めないでください。代わりに、この列を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            }), "として含めて既存の行を更新します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"columnMappings\": [{\"source\":1, \"target\": 2}, {\"source\": 2, \"target\": \"column_name\"}]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryKeyColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "重複排除に使用される、ターゲットHubDBテーブル内の列の名前。このフィールドで列のIDを使用することはできません。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"primaryKeyColumn\": \"column_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "encoding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ファイルのエンコーディングの種類。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utf-8"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ascii"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-2"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-5"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-2022-jp"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "windows-1252"
            }), "。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"encoding\": \"utf-8\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CSVのみがサポートされています。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"format\": \"csv\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["上記の表を使用した", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), " JSONの例を以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example config JSON\n{\n  \"skipRows\": 1,\n  \"separator\": \",\",\n  \"idSourceColumn\": 1,\n  \"resetTable\": false,\n  \"columnMappings\": [\n    {\n      \"target\": 1,\n      \"source\": 2\n    },\n    {\n      \"target\": 2,\n      \"source\": \"zip_code\"\n    }\n  ],\n  \"primaryKeyColumn\": \"name\",\n  \"encoding\": \"utf-8\",\n  \"format\": \"csv\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "cURLを使用する場合、コマンドは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -L -X POST 'https://api.hubspotqa.com/hubdb/api/v2/tables/xxxxxx/import?portalId=xxxxxxx' \\\n-H 'Content-Type: multipart/form-data' \\\n-F 'config=\"{\\\"skipRows\\\":1,\\\"format\\\":\\\"csv\\\",\\\"separator\\\":\\\",\\\",\\\"encoding\\\":\\\"iso-8859-1\\\",\\\"columnMappings\\\":[{\\\"target\\\":1,\\\"source\\\":7},{\\\"target\\\":3,\\\"source\\\":8}],\\\"idSourceColumn\\\":1,\\\"pathSourceColumn\\\":null,\\\"nameSourceColumn\\\":null,\\\"childTableSourceColumn\\\":null,\\\"resetTable\\\":true}\"' \\\n-F 'file=@\"/Users/xxxxxxxxxxxxx/Downloads/filename.csv\"'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "日付の書式設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "データを日付型の列にインポートする際は、さまざまな日付形式を使用できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "整数"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yyyy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yy"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらの形式では、日付の前に月を指定する必要があります（つまり", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dd/mm/yy"
      }), "という形式は使用できません）。整数の区切り文字としてハイフン（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), "）またはスラッシュ（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/"
      }), "）を使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "準標準の日付形式"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "整数ベースの日付形式ほど標準化されていない日付形式をインポートすることもできます。例：**"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "The 1st of March in the year 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Fri Mar 4 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "March 4th '22"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "相対日付"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotでは、現在の日付を基準とする次の日付形式が解析されます：**"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "next Thursday"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Today"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "tomorrow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "3 days from now"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "リセットオプション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CSVファイルからHubDBテーブルにデータをインポートするときに、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "resetTable"
      }), "フィールドを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), "（既定）に設定すると、HubDBの行データを上書きするかどうかを管理できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), "が", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), "に設定されている場合："]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["CSVファイル内の行に行ID列（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), "）がない場合、または行IDが", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), "と指定されている場合、それらの行は、生成された新しい行IDを使って挿入されます。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "CSVファイル内の行IDがターゲットテーブルに既に存在する場合、テーブルの既存の行は入力ファイル内の新しい値で更新されます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "テーブルに行が存在するものの、入力CSVファイルにその行に対応する行IDがない場合、その行はターゲットテーブルから削除されます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "入力CSVファイル内の行IDがターゲットテーブル内に存在しない場合は、生成された新しい行IDを使ってそれらの行が挿入され、入力ファイル内で指定されている行IDは無視されます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "入力CSVファイル内に行IDの列が1つも含まれていない場合、ターゲットテーブルから全ての行が削除され、入力ファイル内の行が、生成された新しい行IDを使って挿入されます。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), "が", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), "（既定）に設定されている場合："]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "CSVファイル内の行IDがターゲットテーブルに既に存在する場合、テーブルの既存の行は入力ファイル内の新しい値で更新されます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "テーブルに行が存在するものの、入力CSVファイルにその行に対応する行IDがない場合、その行はターゲットテーブルから削除されず、変更が加えられないままの状態になります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "入力CSVファイル内の行IDがターゲットテーブル内に存在しない場合は、生成された新しい行IDを使ってそれらの行が挿入され、入力ファイル内で指定されている行IDは無視されます。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["CSVファイル内の行に行ID列がない場合、または行IDが", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), "と指定されている場合、それらの行は、生成された新しい行IDを使って挿入されます。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDBデータの取得"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーブルの詳細を取得するか、またはテーブルの特定の行を取得するかに応じて、HubDBデータを取得するにはさまざまな方法があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["公開されている全てのテーブルからテーブルの詳細を取得するには", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables"
        }), "に送信します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["公開されている特定のテーブルからテーブルの詳細を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}"
        }), "に送信します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["特定のテーブルから全ての行を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}/rows"
        }), "に送信します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーブルから特定の行を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}/rows/{rowId}"
        }), "に送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "行データを取得する際に、フィルターを適用して結果を絞り込んだり、並べ替えたりできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "公開アクセスを許可する"
      }), "ようにテーブルが設定されている場合、クエリーパラメーター", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), "を介してHubSpotアカウントIDを指定すると、認証なしでテーブルと行の公開バージョンにアクセスできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "返された行に対するフィルターの適用"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDBテーブルデータを取得する際に、特定のデータを取得するためのクエリーパラメーターとしてフィルターを適用できます。フィルター クエリー パラメーターは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "columnName__operator"
      }), "という形式で構成されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、", (0, _jsxRuntime.jsx)(_components.em, {
        children: "「bar」"
      }), " という名前の数値列がある場合、", (0, _jsxRuntime.jsx)(_components.em, {
        children: "「bar」"
      }), " が10より大きい行だけを結果に含めるには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&bar__gt=10"
      }), "とします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "全てのフィルターはAND結合されます（現在のところ、ORフィルターはサポートされていません）。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "フィルターを適用する際は、次の点に注意してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect"
          }), "の列の値を渡すときには、各値を半角カンマで区切る必要があります（例：", (0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect_column__contains=1,2"
          }), "）。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "datetime"
          }), "フィルターでは、現在の時刻を基準とする値を指定する目的で、タイムスタンプの代わりに相対日付を使用できます。例えば", (0, _jsxRuntime.jsx)(_components.code, {
            children: "-3h"
          }), "は今から3時間前のタイムスタンプに相当し、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "10s"
          }), "は10秒後のタイムスタンプに相当します。サポートされている時間単位は、ms（ミリ秒）、s（秒）、m（分）、h（時間）、d（日）です。現在の時刻を使用するには、値をゼロにします（0s）。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["これらのフィルター用に組み込む", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_id"
          }), "列は", (0, _jsxRuntime.jsx)(_components.code, {
            children: "number"
          }), "タイプ、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_created_at"
          }), "列は", (0, _jsxRuntime.jsx)(_components.code, {
            children: "datetime"
          }), "タイプ、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_path"
          }), "列および", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_name"
          }), "列は", (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          }), "タイプです。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下に、各タイプの列に適用できる演算子について説明します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "演算子"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "名前"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq (or none)"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "等しい"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全ての列タイプ。演算子が使用されていない場合、このフィルターが適用されます。multiselectの列でこれを使用すると、指定された値に完全に一致する行が返されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ne"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "等しくない"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全ての列タイプ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の全ての値を含む"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["テキスト、リッチテキスト、およびmultiselect。multiselectの列でこれを使用すると、指定された全ての値を含む行が返されます。このフィルターでは", (0, _jsxRuntime.jsx)("u", {
              children: "大文字と小文字が区別されます"
            }), "。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値より小さい"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値、日付、日時。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値以下"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値、日付、日時。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値より大きい"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値、日付、日時。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値以上"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値、日付、日時。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Null"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ブールを除く全てのタイプの列。このフィルターでは値は不要です（例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__is_null="
            }), "）。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nullではない"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ブールを除く全てのタイプの列。このフィルターでは値は不要です（例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__not_null="
            }), "）。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "いいね！"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキストとリッチテキスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "いいね！ではない"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキストとリッチテキスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icontains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の文字列を含む"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["テキストとリッチテキスト。このフィルターでは", (0, _jsxRuntime.jsx)("u", {
              children: "大文字と小文字が区別されません"
            }), "。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startswith"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の文字列で始まる"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキストとリッチテキスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値のいずれかを含む"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["数値、select、multiselect。渡されたオプションのうち少なくとも1つが列に入っている行が返されます。クエリーパラメーターの中に他の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sort"
            }), "がない場合、返される結果は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "演算子での値の指定順に並べ替えられます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "返された行の並べ替え"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDBデータを取得するときに、クエリーパラメーターとして並べ替えを適用することで、返されるデータの順序を指定できます。データを並べ替えるには、次のように", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort"
      }), "クエリーパラメーターを追加して、列名を指定します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=columnName"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既定では、列が指定されている順序でデータが返されます。これを逆順で並べ替えるには、列名に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), "を追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=-columnName"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このパラメーターを複数指定することにより、複数の列を基準に並べ替えができるようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "列を基準とした並べ替えに加えて、次の3つの関数を使用可能です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "**geo_distance（location_column_name、緯度、経度）：**所在地列の名前と座標を入力として受け入れ、指定された所在地列の値と指定された座標の間の距離が長い順に行を返します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "**length(column_name)：**列の名前を入力として受け入れ、（文字列として計算される）列値の長さの順に行を返します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "**random()：**ランダムな順序で行を返します。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この関数では逆順もサポートされます。例えば次の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), "は、距離が最も遠いものを最初にしてアイテムを返します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort=-geo_distance(location_column,42.37,-71.07)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "動的ページ用のHubDBテーブルの設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMSを使用すると、HubDBテーブルをデータソースとして使用して", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "動的ページを生成する"
      }), "ことができます。例えば、経営陣のメンバーごとに1行ずつ含むテーブルを作成し、ページに表示させたい情報が入った列をそれに含めることができます。ページの動的データソースとしてそのテーブルを選択すると、サマリー項目として全行を表示するリストページがそのページによって生成され、さらに行ごとに個別のページも生成されて、ブログ リスト ページとブログ記事ページに似た状態になります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンテンツエディターでテーブルをデータソースとして選択できるようにするには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "useForPage"
      }), "を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "に設定する必要があります。必要に応じて", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamicMetaTags"
      }), "を含めると、各ページのメタデータにどの列を使用するかを指定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamicMetaTags"
        }), "を指定するときには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), "ではなく", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), " HubLタグをページで必ず使用する必要があります。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb#metadata",
          children: "動的ページガイド"
        }), "をご覧ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば次のコードでは動的ページ用のテーブルを作成して、ページのメタデータに使われる3つの列を指定します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to create table\n{\n  \"name\": \"dynamic_page_table\",\n  \"label\": \"Dynamic page table\",\n  \"useForPages\": true,\n  \"columns\": [\n    {\n      \"name\": \"meta_description\",\n      \"label\": \"Meta description\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"featured_image\",\n      \"label\": \"Featured image\",\n      \"archived\": false,\n      \"type\": \"IMAGE\"\n    },\n    {\n      \"name\": \"canonical_url\",\n      \"label\": \"Canonical URL\",\n      \"archived\": false,\n      \"type\": \"URL\"\n    }\n  ],\n  \"dynamicMetaTags\": {\n    \"DESCRIPTION\": 1,\n    \"FEATURED_IMAGE_URL\": 2,\n    \"LINK_REL_CANONICAL_URL\": 3\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["テーブルを動的ページのデータソースとして使用できるようにするには、これを", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamicMetaTags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["各動的ページのメタデータに使用する列をIDによって指定します。以下を含めることができます：", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DESCRIPTION"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FEATURED_IMAGE_URL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINK_REL_CANONICAL_URL"
                })
              })]
            }), "メタデータフィールドが指定されない場合、ページでは親ページからそれぞれの値を継承します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DESCRIPTION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "各ページのメタディスクリプションに使用する列の数値ID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FEATURED_IMAGE_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "各ページのキービジュアルURLに使用する列の数値ID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINK_REL_CANONICAL_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "各ページのcanonical URLに使用する列の数値ID。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "v3での変更点"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーブルには", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), "の両方が必要です。テーブルの作成後に、この「name」を変更することはできません。「name」には半角の英小文字、数字、アンダースコアのみ含めることができますが、数字で始めることはできません。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), "はどらちも、アカウント内で一意である必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["APIは、URLパスの中でテーブル", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), "の両方をサポートします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["行を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "するエンドポイントは、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), "フィールドで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), "ではなく列", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), "を返します。また、行を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "／", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PUT"
        }), "／", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "するエンドポイントは、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), "フィールドで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), "ではなく列", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), "を必要とします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["行を更新する", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "エンドポイントで、スパース更新を使用できるようになりました。スパース更新とは、更新する必要のある列値だけを指定できることを意味します（以前のバージョンでは、全ての列値を指定する必要がありました）。複数選択型の列のように、値のリストを使用して列を更新する場合は、全ての値を含むリストを指定する必要があります。列の値を削除するには、リクエスト内でその列の値を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), "として指定する必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["行を更新する", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "エンドポイントを優先し、行のセルを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get"
        }), "／", (0, _jsxRuntime.jsx)(_components.code, {
          children: "update"
        }), "／", (0, _jsxRuntime.jsx)(_components.code, {
          children: "delete"
        }), "するエンドポイントを削除しました。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["インポートエンドポイントでは、JSON形式のオプションで既存のフィールドとともに任意指定フィールド", (0, _jsxRuntime.jsx)(_components.code, {
          children: "idSourceColumn"
        }), "を使用できるようになりました。このフィールドを使用すると、行IDが格納されているCSVファイル内の列を指定できます。新しい行を既存の行の新しい値とともにインポートするには、新しい行の行IDには単に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), "を指定し、既存の行には有効な行IDを指定できます。詳細については、以下の「インポート」セクションをご参照ください。さらに、JSON形式のオプションに含まれる列マッピングのターゲットフィールドで、列の名前またはIDを使用することもできます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "複製エンドポイントには、新しい名前と新しいラベルが必要です。"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}