"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29865107554;
const slug = exports.slug = 'guides/apps/authentication/validating-requests';
const title = exports.title = 'Webhooks | Validar solicitudes';
const name = exports.name = 'vNext Docs DP - Validar solicitudes de HubSpot';
const metaDescription = exports.metaDescription = 'Un resumen de las solicitudes procedentes de HubSpot a una integración. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "validar-solicitudes-usando-la-firma-de-solicitud-v1",
  "label": "Validar solicitudes usando la firma de solicitud v1",
  "parent": null
}, {
  "depth": 0,
  "id": "ejemplos-de-firma-de-solicitud-v1",
  "label": "Ejemplos de firma de solicitud v1:",
  "parent": null
}, {
  "depth": 0,
  "id": "validar-solicitudes-usando-la-firma-de-solicitud-v2",
  "label": "Validar solicitudes usando la firma de solicitud v2",
  "parent": null
}, {
  "depth": 1,
  "id": "ejemplo-de-una-solicitud-get",
  "label": "Ejemplo de una solicitud GET:",
  "parent": "validar-solicitudes-usando-la-firma-de-solicitud-v2"
}, {
  "depth": 1,
  "id": "ejemplo-de-una-solicitud-post",
  "label": "Ejemplo de una solicitud POST",
  "parent": "validar-solicitudes-usando-la-firma-de-solicitud-v2"
}, {
  "depth": 0,
  "id": "validar-la-firma-de-solicitud-v3",
  "label": "Validar la firma de solicitud v3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      code: "code",
      a: "a",
      h2: "h2",
      strong: "strong",
      pre: "pre",
      br: "br",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Validar solicitudes de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para garantizar que las solicitudes que tu integración recibe de HubSpot realmente provengan de HubSpot, hay dos encabezados en la solicitud. Puedes usar estos encabezados, junto con los campos de la solicitud entrante, para verificar la firma de la solicitud."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El método utilizado para verificar la firma depende de la versión de la firma:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para validar una solicitud usando la última versión de la firma de HubSpot, usa el encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-V3"
        }), " y sigue las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests#validate-the-v3-request-signature",
          children: "instrucciones asociadas para validar la versión v3 de la firma"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para la compatibilidad con versiones anteriores, las solicitudes de HubSpot también incluyen versiones anteriores de la firma. Para validar una versión anterior de la firma, verifica el encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-Version"
        }), ", y luego sigue las instrucciones asociadas que aparecen abajo según si la versión es ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v1"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v2"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En las siguientes instrucciones, descubre cómo obtener un valor del hash del secreto del cliente de tu aplicación y los campos de una solicitud entrante. Una vez que calcules el valor del hash, compáralo con la firma. Si los dos son iguales, entonces la solicitud pasó la validación. De lo contrario, la solicitud puede haber sido manipulada en tránsito o alguien puede estar falsificando solicitudes en tu punto de terminación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validar solicitudes usando la firma de solicitud v1"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tu aplicación está suscrita a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/webhooks/overview",
        children: "eventos de objetos del CRM a través de la API de webhooks"
      }), ", las solicitudes de HubSpot se enviarán con el encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), " definido como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v1"
      }), ". El encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " será un hash SHA-256 creado usando el secreto del cliente de tu aplicación junto con detalles de la solicitud."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para verificar esta versión de la firma, realiza los siguientes pasos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crea una cadena que concatene lo siguiente: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), " (si está presente)."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Crea un hash SHA-256 de la cadena resultante."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Compara el valor del hash con el valor del encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si son iguales, esta solicitud ha superado la validación."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si estos valores no coinciden, es posible que esta solicitud haya sido manipulada en tránsito o que alguien esté falsificando solicitudes a tu punto final."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejemplo para una solicitud con un cuerpo:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Client secret : yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\n// Request body: [\n{\"eventId\":1,\"subscriptionId\":12345,\"\nportalId\":62515\",\noccurredAt\":1564113600000\",\nsubscriptionType\":\"contact.creation\",\n\"attemptNumber\":0,\n\"objectId\":123,\n\"changeSource\":\"CRM\",\n\"changeFlag\":\"NEW\",\n\"appId\":54321}\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ejemplos de firma de solicitud v1:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n>>> import hashlib\n\n>>> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n>>> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> source_string = client_secret + request_body\n>>> source_string\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> hashlib.sha256(source_string).hexdigest()\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\nirb(main):003:0> require 'digest'\n=> true\nirb(main):004:0> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\"\nirb(main):005:0> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n=> \"[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):006:0> source_string = client_secret + request_body\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):007:0> Digest::SHA256.hexdigest source_string\n=> \"232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de\"\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n> const crypto = require('crypto')\nundefined\n> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n'[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> source_string = client_secret + request_body\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> hash = crypto.createHash('sha256').update(source_string).digest('hex')\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El hash resultante sería el siguiente:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validar solicitudes usando la firma de solicitud v2"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tu aplicación está manejando datos de una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "acción de webhook en un workflow"
      }), " o si estás devolviendo datos para una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "tarjeta del CRM personalizada"
      }), ", la solicitud de HubSpot se envía con el encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), " definido como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v2"
      }), ". El encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " será un hash SHA-256 creado usando el secreto del cliente de tu aplicación junto con detalles de la solicitud."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para verificar esta firma, realiza los siguientes pasos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crea una cadena que concatene lo siguiente: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "http method"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "URI"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), " (si está presente)."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Crea un hash SHA-256 de la cadena resultante."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Compara el valor del hash a la firma.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si son iguales, esta solicitud ha superado la validación."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si estos valores no coinciden, es posible que esta solicitud haya sido manipulada en tránsito o que alguien esté falsificando solicitudes a tu punto final."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Notas:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La URL utilizada para crear la cadena de origen debe coincidir exactamente con la solicitud original, incluyendo el protocolo. Si tienes problemas para validar la firma, asegúrate de que cualquier parámetro de consulta esté en el mismo orden que se enumeró en la solicitud original."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La cadena de origen debe tener codificación UTF-8 antes de calcular el hash SHA-256."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ejemplo de una solicitud GET:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el caso de una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ", necesitarías el secreto del cliente de tu aplicación y campos específicos de los metadatos de la solicitud. Estos campos se enumeran abajo con valores de marcadores de posición incluidos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Secreto del cliente"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Método HTTP:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Cuerpo de la solicitud: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"\""
          })]
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La cadena concatenada resultante sería: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyGEThttps://www.example.com/webhook_uri"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de calcular un hash SHA-256 de la cadena concatenada anterior, la firma resultante que esperarías que coincidiera con la del encabezado sería: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eee2dddcc73c94d699f5e395f4b9d454a069a6855fbfa152e91e88823087200e"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ejemplo de una solicitud POST"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el caso de una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", necesitarías el secreto del cliente de tu aplicación, campos específicos de los metadatos de la solicitud y una representación de cadena del cuerpo de la solicitud (por ejemplo, usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "JSON.stringify(request.body)"
      }), " para un servicio de Node.js). Estos campos se enumeran abajo con valores de marcadores de posición incluidos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Secreto del cliente"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Método HTTP:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cuerpo de la solicitud:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{\"example_field\":\"example_value\"}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La cadena concatenada resultante sería: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"example_value\"}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de calcular un hash SHA-256 de la cadena concatenada anterior, la firma resultante que esperarías que coincidiera con la del encabezado sería:", (0, _jsxRuntime.jsx)(_components.code, {
        children: "9569219f8ba981ffa6f6f16aa0f48637d35d728c7e4d93d0d52efaa512af7900"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de [SHA-ing] la firma, podrías comparar la firma esperada resultante con la proporcionada en el encabezado x-hubspot-signature de la solicitud:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El fragmento de código de Node.js que aparece abajo detalla cómo podrías incorporar la validación de solicitudes correspondiente a una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " si estuvieras ejecutando un servidor Express para manejar las solicitudes entrantes. Ten en cuenta que el bloque de código que aparece abajo es un ejemplo y omite ciertas dependencias que podrías necesitar para ejecutar un servicio Express con todas las funciones. Confirma que estás ejecutando las bibliotecas estables y seguras más recientes al implementar la validación de solicitudes para tu servicio específico."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Introduce any dependencies. Only several dependencies related to this example are included below:\nconst express = require('express');\nconst bodyParser = require('body-parser');\nconst crypto = require('crypto');\nconst app = express();\n\n// Add any custom handling or setup code for your Node.js service here.\napp.use(bodyParser.urlencoded({ extended: false }));\napp.use(bodyParser.json());\n\n// Example Node.js request validation code.\napp.get('/example-service', (request, response, next) => {\n  const { url, method, headers, hostname } = request;\n\n  const requestSignature = headers['x-hubspot-signature'];\n\n  // Compute expected signature\n  const uri = `https://${hostname}${url}`;\n  const encodedString = Buffer.from(\n    `${process.env.CLIENT_SECRET}${method}${uri}`,\n    'ascii'\n  ).toString('utf-8');\n  const expectedSignature = crypto\n    .createHash('sha256')\n    .update(encodedString)\n    .digest('hex');\n\n  console.log('Expected signature: %s', requestSignature);\n  console.log('Request signature: %s', expectedSignature);\n\n  // Add your custom handling to compare request signature to expected signature\n  if (requestSignature !== expectedSignature) {\n    console.log('Request of signature does NOT match!');\n    response.status(400).send('Bad request');\n  } else {\n    console.log('Request of signature matches!');\n    response.status(200).send();\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validar la firma de solicitud v3"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), " será un hash SHA-256 creado usando el secreto del cliente de tu aplicación junto con detalles de la solicitud. También incluirá un encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Request-Timestamp"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al validar una solicitud que utiliza el encabezado X-HubSpot-Signature-v3, deberás"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rechazar la solicitud si la marca de tiempo es anterior a 5 minutos."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "En la URI de la solicitud, decodifica cualquiera de los caracteres codificados por URL que se enumeran en la siguiente tabla. No necesitas decodificar el signo de interrogación que denota el comienzo de la cadena de consulta."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Valor codificado"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Valor decodificado"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ":"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "?"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%40"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "@"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%21"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%24"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "$"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%27"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%28"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "("
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%29"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ")"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2C"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ","
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3B"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ";"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crea una cadena codificada utf-8 que concatene lo siguiente: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestMethod"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestUri"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestBody"
        }), " + marca de tiempo. La marca de tiempo es proporcionada por el encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Request-Timestamp"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Crea un hash SHA-256 de la cadena resultante usando el secreto de la aplicación como el secreto de la función HMAC SHA-256."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Base64 codifica el resultado de la función HMAC."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Compara el valor del hash a la firma. Si son iguales, significa que esta solicitud se verificó como proveniente de HubSpot. Se recomienda que uses la comparación de cadenas en tiempo constante para protegerte de los ataques de sincronización."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "#NOTE: This is only an example for generating the expected hash.\n# You will need to compare this expected hash with the actual hash in the\n# X-HubSpot-Signature header.\n\n# Using Python 3\nimport hashlib\n\nclient_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\nhttp_method = 'POST'\nhttp_uri = 'https://www.example.com/webhook_uri'\nrequest_body = '{\"example_field\":\"サンプルデータ\"}'\n\nsource_string = client_secret + http_method + http_uri + request_body\nprint('source_string: {}'.format(source_string))\n\nhash_result = hashlib.sha256(source_string.encode('utf-8')).hexdigest()\nprint('hash_result: {}'.format(hash_result))\n\n# output:\n# source_string: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n# hash_result: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# NOTE: This is only an example for generating the expected hash.\n# You will need to compare this expected hash with the actual hash in the\n# X-HubSpot-Signature header.\n\nrequire 'digest'\n\nclient_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\nhttp_method = 'POST'\nhttp_uri = 'https://www.example.com/webhook_uri'\nrequest_body = '{\"example_field\":\"サンプルデータ\"}'\n\nsource_string = client_secret + http_method + http_uri + request_body\nputs \"source_string: %s\" % [source_string]\n\nhash_result = Digest::SHA256.hexdigest source_string\nputs \"hash_result: %s\" % [hash_result]\n\n# output:\n# source_string: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n# hash_result: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "// NOTE: This is only an example for generating the expected hash.\n// You will need to compare this expected hash with the actual hash in the\n// X-HubSpot-Signature header.\n\nconst crypto = require('crypto');\n\nclientSecret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy';\nhttpMethod = 'POST';\nhttpURI = 'https://www.example.com/webhook_uri';\nrequestBody = '{\"example_field\":\"サンプルデータ\"}';\n\nsourceString = clientSecret + httpMethod + httpURI + requestBody;\nconsole.log('sourceString: ' + sourceString);\n\nhash = crypto.createHash('sha256').update(sourceString).digest('hex');\nconsole.log('hash: ' + hash);\n\n// output:\n// sourceString: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n// hash: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}