"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866608851;
const slug = exports.slug = 'reference/api/overview';
const title = exports.title = 'HubSpot APIs | Getting started';
const name = exports.name = 'vNext Docs DP - Getting Started';
const metaDescription = exports.metaDescription = 'The HubSpot API allows developers to build custom apps and integrations. Get started by reading our API overview and creating a developer account.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "setting-up",
  "label": "Setting up",
  "parent": null
}, {
  "depth": 0,
  "id": "client-libraries",
  "label": "Client libraries",
  "parent": null
}, {
  "depth": 0,
  "id": "using-the-documentation",
  "label": "Using the documentation",
  "parent": null
}, {
  "depth": 1,
  "id": "reference-docs-and-guides",
  "label": "Reference docs and guides",
  "parent": "using-the-documentation"
}, {
  "depth": 1,
  "id": "integration-guides",
  "label": "Integration guides",
  "parent": "using-the-documentation"
}, {
  "depth": 0,
  "id": "building-on-hubspot-s-cms",
  "label": "Building on HubSpot's CMS",
  "parent": null
}, {
  "depth": 0,
  "id": "support-and-community-resources",
  "label": "Support and community resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      strong: "strong",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Table
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot API reference documentation"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot’s developer platform is a core part of our mission to empower organizations to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/grow-better",
        children: "grow better"
      }), ". Our APIs are designed to enable teams of any shape or size to build robust integrations that help them customize and get the most value out of HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All HubSpot APIs are ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/Representational_state_transfer",
        children: "built using REST conventions"
      }), " and designed to have a predictable URL structure. They use many standard HTTP features, including methods (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), ") and error response codes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All HubSpot API calls are made under ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com"
      }), " and all responses return standard JSON."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Setting up"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are several ways to build integrations with HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To build an internal integration for an individual HubSpot account (e.g., you want to build an app that can access and edit only authorized parts of your account to share or integrate with other parts of your organization), create a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "private app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you're looking to create a public app that can be installed across multiple HubSpot accounts, you should ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
          children: "create a developer account"
        }), ". A developer account is where you create HubSpot apps, each authenticated with OAuth and provided with a configurable set of features and permissions. You can also use your developer account to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "create test accounts"
        }), ", monitor app status and performance, or publish apps to the HubSpot App Marketplace."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview",
        children: "different types of apps and how to build them"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Client libraries"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Client libraries are designed to help you interact with the HubSpot APIs with less friction. They are written in several different languages and help bridge the gap between your application and HubSpot’s APIs. They take away the need to know the exact URL and HTTP method to use for each API call among other things leaving you more time to focus on making your application. Learn more about our client libraries ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/overview",
        children: "here"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Table, {
      children: [(0, _jsxRuntime.jsx)("thead", {
        children: (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("th", {
            children: "Language"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: "Package Link"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "Source Code"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_nodejs-512_339733.png",
              alt: "Node.Js"
            }), "Node.Js"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://www.npmjs.com/package/@hubspot/api-client",
              children: "npm install @hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "hubspot-api-nodejs"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/new-php-logo.png",
              alt: "PHP"
            }), "PHP"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://packagist.org/packages/hubspot/api-client",
              children: "composer require hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-php",
              children: "hubspot-api-php"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/ruby.png",
              alt: "Ruby"
            }), "Ruby"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://rubygems.org/gems/hubspot-api-client",
              children: "gem install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-ruby"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_267_Python_logo_4375050.png",
              alt: "Python"
            }), "Python"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://pypi.org/project/hubspot-api-client/",
              children: "pip install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-python"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using the documentation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot’s API documentation is split into two sections: reference docs and their accompanying guides, and the integration guides.", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Reference docs and guides"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["API reference documentation provides a list of the available endpoints for an API, along with the available parameters and example requests in multiple languages. Example request code blocks also include a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Test call"
      }), " button you can use to make test calls directly from the page. At the top of API reference pages, a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Run in Postman"
      }), " button is displayed with the API is included in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/hubspot/hubspot-public-api-workspace/overview",
        children: "HubSpot's Postman collection"
      }), ". The corresponding guide will provide an expanded summary of the API, along with any special considerations to keep in mind when using the API."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "https://developers.hubspot.com/docs"
      }), " site, each API reference doc and guide are presented on the same page, divided between ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Overview"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpoints"
      }), " tabs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/classic-docs-site-tabs.png",
        alt: "classic-docs-site-tabs"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the new ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/overview",
        children: "https://developers.hubspot.com/beta-docs/"
      }), " site, API reference docs are presented on one page while the corresponding guide is on another. A link will be included on each page for easy navigation."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/beta-docs-site-tabs.png",
        alt: "beta-docs-site-tabs"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Integration guides"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "HubSpot's integration guides"
      }), " includes documentation about general app development, including ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "authentication"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "listing apps on the App Marketplace"
      }), ". You'll also find guides for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview",
        children: "building UI extensions (BETA)"
      }), " and working with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/setup",
        children: "developer projects framework (BETA)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Building on HubSpot's CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While the API reference documentation includes information about using HubSpot's CMS APIs, such as the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/pages",
        children: "pages API"
      }), ", you'll find the full set of CMS developer documentation at ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/overview",
        children: "https://developers.hubspot.com/docs/cms"
      }), ". This includes guides that walk through building various CMS assets as well as reference documentation for HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Support and community resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Get your questions answered, make connections, and share your insights by joining HubSpot’s growing ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "developer community forums"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/developer-slack",
        children: "Slack community"
      }), ". These forums are a great place to make your voice heard — community feedback is incredibly important to us and our ongoing efforts to improve HubSpot’s developer experience."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also stay up to date on new features, announcements, and important changes by subscribing to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Changelog"
      }), " blog.", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}