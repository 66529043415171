"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694131;
const slug = exports.slug = 'guides/cms/content/themes/hubspot-cms-boilerplate';
const title = exports.title = 'La biblioteca de recursos de CMS Hub';
const name = exports.name = '[new] La biblioteca de recursos de CMS Hub';
const metaDescription = exports.metaDescription = 'La biblioteca de recursos de CMS Hub ilustra las mejores prácticas y ayuda a los desarrolladores a crear un sitio web rápidamente en el CMS de HubSpot. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%C2%BFpor-qu%C3%A9-los-desarrolladores-deber%C3%ADan-utilizar-la-biblioteca-de-recursos-de-cms-hub%3F",
  "label": "¿Por qué los desarrolladores deberían utilizar la biblioteca de recursos de CMS Hub?",
  "parent": null
}, {
  "depth": 0,
  "id": "c%C3%B3mo-empezar-a-utilizar-la-biblioteca-de-recursos-de-cms-hub",
  "label": "Cómo empezar a utilizar la biblioteca de recursos de CMS Hub",
  "parent": null
}, {
  "depth": 0,
  "id": "estructura-de-la-biblioteca-de-recursos-de-cms-hub",
  "label": "Estructura de la biblioteca de recursos de CMS Hub",
  "parent": null
}, {
  "depth": 0,
  "id": "jquery",
  "label": "jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      strong: "strong",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "La biblioteca de recursos de CMS Hub"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 9,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "La biblioteca de recursos de CMS Hub"
            }), " sirve como punto de partida para ayudar a los desarrolladores a poner en marcha un sitio web de inmediato en CMS de HubSpot y muestra buenas prácticas que los desarrolladores pueden usar a la hora de crear en la plataforma de CMS de HubSpot. La biblioteca es un proyecto de GitHub de código abierto donde todos pueden hacer sus aportes y adaptarla para su propio uso. Si eres nuevo en el CMS de HubSpot, y quieres empezar un nuevo proyecto basado en la plantilla, sigue la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/quickstart",
              children: "guía de inicio rápido para desarrollar en el CMS de HubSpot"
            }), "."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 3,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/subscription",
              children: "Ver"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/fork",
              children: "Horquilla"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "Estrella"
            })]
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Por qué los desarrolladores deberían utilizar la biblioteca de recursos de CMS Hub?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La biblioteca de recursos de CMS Hub está construida y mantenida activamente por HubSpot. Al construir la biblioteca, incorporamos las mejores prácticas que fueron influenciadas por cómo los desarrolladores crearon la mejor experiencia de construcción de sitios web y luego aplicamos las mismas a la construcción de un sitio web en el CMS de HubSpot. Esto proporciona a los desarrolladores un sitio web limpio, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "rendimiento"
      }), " y listo para ser modificado, lo que ahorra a los desarrolladores una cantidad significativa de tiempo de desarrollo. La plantilla también proporciona un CSS integral para los materiales relacionados con HubSpot, como formularios, módulos de menú, clases base y más. Puedes ver una demostración en directo de la plantilla en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/",
        children: "https://boilerplate.hubspotcms.com/"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cómo empezar a utilizar la biblioteca de recursos de CMS Hub"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para empezar a utilizar la plantilla en tu entorno de desarrollo local, sólo tienes que seguir nuestra ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "Guía de cómo empezar a desarrollar sitios web en el CMS de HubSpot."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Estructura de la biblioteca de recursos de CMS Hub"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Los desarrolladores pueden trabajar con la biblioteca utilizando ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "herramientas de desarrollo locales"
            }), " o directamente dentro del ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cos-general/get-started-with-the-design-manager",
              children: "Administrador de diseño"
            }), ". La biblioteca utiliza referencias de rutas relativas para todos los materiales, lo que facilita su adaptación a cualquier proyecto. Esto también permite que la biblioteca sea completamente portable entre cuentas en la Plataforma HubSpot. A continuación se muestra una captura de pantalla de la estructura de carpetas de los recursos de la biblioteca. La biblioteca almacena sus recursos en varios directorios para una fácil identificación de dónde residen."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844633478"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/boilerplate/boilerplate-structure-tree-view-4.png",
        alt: "Estructura de las carpetas de la biblioteca de recursos de CMS Hub"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La estructura subyacente de la biblioteca de recursos de CMS Hub gira en torno a un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "diseño base común"
      }), " ubicado en la carpeta ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "plantillas > diseños"
      }), ", que luego es la etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% extends %}"
      }), " y hace referencia al bloque ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% block body %}"
      }), " para su contenido principal. Se puede ver una muestra de cómo se utilizan la etiqueta extendida y los bloques en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "cualquiera de los archivos html dentro del directorio de plantillas."
      }), " Más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#blocks-and-extends",
        children: "bloqueos y las extensiones"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este es un método común de desarrollo en los sistemas CMS donde tienes una plantilla base (a veces llamada principal/padre) que contiene todas las principales piezas estructurales comunes de contenido en tu sitio. Estos suelen ser elementos que se encuentran dentro del elemento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " en tu sitio, como las meta propiedades comunes (por ejemplo: título y metadescripción), enlaces de favicono, enlaces de CSS y scripts de terceros"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n <head>\n   <meta charset=\"utf-8\">\n   <title>{{ page_meta.html_title }}</title>\n   {% if site_settings.favicon_src %}<link rel=\"shortcut icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n   <meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n   {{ require_css(get_asset_url(\"../../css/layout.css\")) }}\n   {{ require_css(get_asset_url(\"../../css/main.css\")) }}\n   {{ require_css(\"https://fonts.googleapis.com/css?family=Merriweather:400,700|Lato:400,700&display=swap\") }}\n   {{ require_js(get_asset_url(\"../../js/main.js\")) }}\n   {{ standard_header_includes }}\n </head>\n <body>\n   <div class=\"body-wrapper {{ builtin_body_classes }}\">\n     {% block header %}\n       {% global_partial path=\"../partials/header.html\" %}\n     {% endblock header %}\n\n     {% block body %}\n       <!-- Nothing to see here -->\n     {% endblock body %}\n\n     {% global_partial path=\"../partials/footer.html\" %}\n   </div>\n   {{ standard_footer_includes }}\n </body>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro de este diseño base, también hay llamadas a nuestros parciales globales de encabezados y pie de página. Esto nos permite mantener el código de estas parciales en sus propios archivos separados para la modularidad y, debido a que son parciales globales, pueden ser fácilmente editados usando nuestro ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Editor de contenido Global"
      }), " por sus creadores de contenido."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para profundizar en los recursos incluidos en la biblioteca ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/wiki",
        children: ", consulta la wiki de la plantilla en GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "La biblioteca de temas de HubSpot no requiere jQuery para funcionar"
      }), ". Para las cuentas más antiguas de HubSpot, jQuery se carga por opción predeterminada. Las nuevas cuentas de HubSpot, tienen jQuery desactivado por opción predeterminada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Anteriormente, los scripts de HubSpot requerían jQuery para funcionar correctamente, por lo que la configuración de todo el dominio estaba ahí para ayudar a garantizar la compatibilidad. Los scripts de HubSpot ya no usan jQuery. Porque JQuery no es requerido, y hay mejores formas para que los desarrolladores incluyan bibliotecas que también funcionan con el control de fuente. Se aconseja desactivar la configuración de jQuery en los nuevos sitios web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ten en cuenta que si deshabilitas jQuery en un dominio que tiene un sitio web existente, - las landing pages o páginas web que tengas podrían romperse si dependen de jQuery."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Si quieres utilizar jQuery en tu nuevo sitio web, te recomendamos que utilices la última versión de jQuery."
      }), " Hay dos maneras fáciles de hacerlo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sube la última versión de jQuery en tu sistema de archivos para desarrolladores y utiliza ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " para cargarlo donde y cuando lo necesites."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utiliza una CDN de tu confianza y usa ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " para cargar jQuery donde y cuando lo necesites."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Primeros pasos para usar temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Cómo optimizar el rendimiento de tu sitio de CMS Hub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Empezar con la accesibilidad"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}