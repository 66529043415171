"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890115;
const slug = exports.slug = 'reference/cms/hubl/overview';
const title = exports.title = 'Sintaxis de HubL';
const name = exports.name = 'LATAM (ES) HubL Syntax';
const metaDescription = exports.metaDescription = 'HubL es el lenguaje de plantillas inspirado en el jinja que se utiliza para construir plantillas y módulos en el CMS de HubSpot. Sentencias if, bucles for, includes, etc.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tipos-de-delimitadores",
  "label": "Tipos de delimitadores",
  "parent": null
}, {
  "depth": 1,
  "id": "sentencias",
  "label": "Sentencias",
  "parent": "tipos-de-delimitadores"
}, {
  "depth": 1,
  "id": "expresiones",
  "label": "Expresiones",
  "parent": "tipos-de-delimitadores"
}, {
  "depth": 2,
  "id": "etiqueta-do",
  "label": "Etiqueta do",
  "parent": "tipos-de-delimitadores"
}, {
  "depth": 1,
  "id": "comentarios",
  "label": "Comentarios",
  "parent": "tipos-de-delimitadores"
}, {
  "depth": 0,
  "id": "m%C3%B3dulos",
  "label": "Módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-y-macros",
  "label": "Variables y macros",
  "parent": null
}, {
  "depth": 0,
  "id": "filtros-y-funciones",
  "label": "Filtros y funciones",
  "parent": null
}, {
  "depth": 0,
  "id": "sentencias-if",
  "label": "Sentencias If",
  "parent": null
}, {
  "depth": 0,
  "id": "bucles-for",
  "label": "Bucles for",
  "parent": null
}, {
  "depth": 0,
  "id": "otras-caracter%C3%ADsticas-de-hubl",
  "label": "Otras características de HubL",
  "parent": null
}, {
  "depth": 1,
  "id": "escapar-los-delimitadores-de-hubl",
  "label": "Escapar los delimitadores de HubL",
  "parent": "otras-caracter%C3%ADsticas-de-hubl"
}, {
  "depth": 1,
  "id": "incluir-archivos-en-archivos",
  "label": "Incluir archivos en archivos",
  "parent": "otras-caracter%C3%ADsticas-de-hubl"
}, {
  "depth": 1,
  "id": "bloques-y-extensiones",
  "label": "Bloques y extensiones",
  "parent": "otras-caracter%C3%ADsticas-de-hubl"
}, {
  "depth": 1,
  "id": "copiar-secci%C3%B3n-hubl",
  "label": "Copiar sección HubL",
  "parent": "otras-caracter%C3%ADsticas-de-hubl"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      pre: "pre",
      code: "code",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visión general de la sintaxis de HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El CMS de HubSpot utiliza el HubSpot Markup Language, denominado HubL (pronunciado \"Hubble\"). HubL es la extensión de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), " de HubSpot, un motor de plantillas basado en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jinja.palletsprojects.com/en/latest/",
        children: "Jinja"
      }), ". HubL utiliza una buena cantidad de marcas que son únicas de HubSpot y no son compatibles con todas las características de Jinja."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este artículo te llevará a través de los fundamentos de las características y la sintaxis de HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de delimitadores"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al igual que otros lenguajes de plantillas de uso común, como PHP, HubL se puede mezclar con tu HTML en archivos de plantillas codificadas o módulos de plantillas de HubL. Para diferenciar dónde empieza y termina tu HubL, necesitaras aprender algunos símbolos clave que actúan como delimitadores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% %} - statement delimiters\n{{ }} - expression delimiters\n{# #} - comment delimiters\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Ten en cuenta anidar los comentarios en tu código, ya que puede resultar en que la etiqueta de comentario final se muestre como texto."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sentencias"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las sentencias HubL se utilizan para crear módulos editables, definir la lógica condicional de las plantillas, establecer bucles for, definir variables, etc. Las sentencias están delimitadas por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{%"
      }), ". No imprimen nada en la página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Expresiones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las expresiones imprimen valores almacenados en el contexto de la plantilla. Las expresiones están delimitadas por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{"
      }), ". Por ejemplo, una variable debe ser definida como una sentencia, pero luego se utilizaría una expresión HubL para imprimir la variable."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Etiqueta do"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La etiqueta 'do' funciona exactamente igual que las sentencias regulares ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% ... %}"
      }), "; Se pueden utilizar para modificar listas y diccionarios."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nota: cuando se agrega a las matrices, se quiere utilizar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#append",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".append()"
        })
      }), " y cuando se agrega a objetos, se quiere utilizar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#update",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".update()"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "# Arrays\n{% set navigation = [\"Home\", \"About\"] %}\n{% do navigation.append(\"Contact Us\") %}\n{{navigation}}\n\n# Objects\n{% set book = {\"name\" : \"Rocking HubL\", \"author\" : \"John Smith\" }%}\n{% do book.update({\"ebook\" : \"yes\"}) %}\n{{book}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "# Arrays [Home, About, Contact Us] # Objects {name=Rocking HubL, author=John\nSmith, ebook=yes}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comentarios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El último tipo de delimitador que puedes encontrar o decidir emplear mientras desarrollas con HubL, es un comentario HubL. Los comentarios son definidos por un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{#"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " son áreas dinámicas de una plantilla que pueden ser personalizadas por el usuario final en el editor de contenidos. Por ejemplo, si estuvieras codificando un archivo de plantilla desde cero, agregarías módulos a las plantillas con etiquetas HubL, para dar a tus creadores de contenido la capacidad de editar áreas de la página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las etiquetas de los módulos están formadas por los siguientes componentes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tipo de módulo:"
        }), " especifica qué módulo se va a renderizar. Consulta la página ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags",
          children: "Etiquetas admitidas por HubL"
        }), " para obtener una lista de los diferentes tipos de módulos disponibles."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Un nombre único para ese módulo:"
        }), " da al módulo una identidad única en el contexto de la plantilla."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ruta:"
        }), " dependiendo de la etiqueta, define la ubicación del módulo en el administrador de diseño. La ruta de acceso a los módulos por opción predeterminada de HubSpot debe empezar siempre por @hubspot/ seguido del tipo de módulo. Para más información, consulta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "página sobre el uso de módulos en plantillas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Parámetros:"
        }), " especifica opcionalmente información adicional del módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"unique_module_name\",\n  path=\"@hubspot/text\",\n  label=\"Single Text Line\",\n  value=\"This is a single text line\"\n%}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text widget-type-text\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"module\"\n  id=\"hs_cos_wrapper_text\"\n  style=\"\"\n>\n  <span\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"text\"\n    id=\"hs_cos_wrapper_text_\"\n    style=\"\"\n    >This is a single text line</span\n  >\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El ejemplo anterior es un módulo de texto con los parámetros de etiqueta y valor definidos. La etiqueta define el texto de ayuda en el editor y el valor establece el texto predeterminado para ese módulo. Consulta el gif de ejemplo a continuación para ver cómo se ve esto dentro del editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/module-label-value-min.gif",
        alt: "module-label-value-min"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "aprender más sobre el uso de módulos en las plantillas, aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables y macros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL incluye muchas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "variables predefinidas de HubSpot"
      }), " que imprimen sus valores útiles desde la aplicación. Además, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "definir tus propias variables en una plantilla"
      }), ". En el siguiente ejemplo, una variable llamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primaryColor"
      }), " se define en una sentencia y luego se imprime con una expresión HubL. Este ejemplo mezcla la variable HubL con CSS."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set primaryColor = '#F7761F' %} {# defines variable and assigns HEX color #}\n\na {\n  color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las macros HubL te permiten imprimir varias sentencias con un valor dinámico. Esta técnica resulta útil cuando te encuentras escribiendo los mismos bloques de código básico una y otra vez, pero sólo necesitas cambiar ciertos valores. En el siguiente ejemplo, se utiliza una macro para imprimir una propiedad de transición CSS3 que incluye todos los prefijos del vendedor. Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/variables-macros-syntax#macros",
        children: "aprender más sobre las macros, aquí"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n  {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filtros y funciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se pueden agregar filtros a tu HubL para transformar o alterar el valor de una variable de plantilla. Un ejemplo sencillo que se muestra a continuación es el de formatear una variable de fecha. Los filtros utilizan un | (símbolo de pipeline) y se aplican sin espacios a una variable."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el ejemplo siguiente, supongamos que una publicación del blog se publicó el 29 de abril. La fecha de publicación del post se formatea con un filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "datetimeformat"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "Puedes ver una lista completa de filtros aquí."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date_local_time|datetimeformat(\"%B %e, %Y\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "April 29, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mientras que los filtros afectan a la representación de las variables, las funciones procesan la información del valor y de la cuenta y la representan. Por ejemplo, se puede utilizar una función para obtener el número total de publicaciones de un determinado blog o para aclarar/oscurecer una variable de color en una cantidad determinada."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ejemplo imprimiría el número total de publicaciones del blog de designers.hubspot.com/blog. Este utiliza un parámetro de ID de blog (disponible en la URL del panel del blog) para especificar a qué blog debe dirigirse. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "Puedes ver la lista completa de funciones aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "// blog_total_post_count\n{{ blog_total_post_count(359485112) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sentencias If"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "sentencias If"
      }), " te permiten usar la lógica condicional para dictar cómo tu plantilla renderizará la lógica condicional en las sentencias HubL para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), ". Una sentencia if debe comenzar con un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " y terminar con un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El siguiente ejemplo define un módulo de elección que permite al usuario final seleccionar un departamento de un desplegable. Dependiendo de lo que el usuario seleccione en el editor, la plantilla generará un encabezado dinámico para una página de carreras. Este ejemplo requiere el uso del parámetro ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "export_to_template_context"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bucles for"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "bucles for"
      }), " permiten iterar sobre los elementos almacenados en una secuencia. Lo más común es que se utilicen para renderizar el contenido de un blog en formato de listado. Los bucles for comienzan con una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), " y terminan con una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endfor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el siguiente ejemplo, una matriz de tipos de osos se almacena como una variable llamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bears"
      }), ". Se utiliza un bucle for para iterar a través de los diferentes tipos de osos imprimiendo un elemento de la lista para cada tipo."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bears = [\"panda bear\", \"polar bear\", \"black bear\", \"grizzly bear\", \"koala bear\"] %}\n\n<h1>Types of bears</h1>\n<ul>\n  {% for bear in bears %}\n  <li>{{ bear }}</li>\n  {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Types of bears</h1>\n<ul>\n  <li>panda bear</li>\n  <li>polar bear</li>\n  <li>black bear</li>\n  <li>grizzly bear</li>\n  <li>koala bear</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Otras características de HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se presentan algunas otras características de las plantillas que pueden ser útiles, a medida que desarrollas con HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Escapar los delimitadores de HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Muchos otros idiomas comparten los mismos delimitadores que HubL, lo que puede crear problemas al trabajar en archivos codificados en el CMS. Si quieres utilizar un delimitador HubL para un idioma diferente, tienes que envolver ese código:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: " {{\"Code you want to escape\"}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{\"Code you want to escape\"}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Incluir archivos en archivos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes incluir varios archivos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".html"
      }), " en una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "plantilla HubL"
      }), " utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/html-hubl-templates#standard-partials",
        children: "etiqueta include"
      }), ". Para crear un archivo HTML que no requiera las variables de la plantilla estándar, debes desmarcar la opción \"Hacer que la plantilla esté disponible para el nuevo contenido\" La sintaxis se muestra a continuación:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"custom/page/web_page_basic/my_footer.html\" %}\n{% include \"hubspot/styles/patches/recommended.css\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "También puedes compilar varios archivos CSS en un solo archivo CSS con la misma etiqueta de inclusión. Cuando publiques el archivo padre, el archivo hijo se compilará en un único archivo CSS minificado con el código del padre"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bloques y extensiones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando creas plantillas complejas, puedes utilizar la creación de bloques compartimentados que amplíen una plantilla principal."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En primer lugar, crearás una plantilla principal que incluya las variables requeridas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), ". Dentro de esa plantilla, necesitas definir un bloque único usando la siguiente sintaxis donde ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_sidebar"
      }), " es un nombre único:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"custom/page/web_page_basic/my_template.html\" %}\n{% block my_sidebar %}\n<h3>Sidebar title</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, puedes crear un archivo HTML hijo que rellene ese bloque. En primer lugar, debes declarar una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/html-hubl-templates#blocks-and-extends",
        children: "declaración extends"
      }), " que haga referencia a la ruta del padre. Este bloque de código se renderizaría en la plantilla principal pero se mantendría en otro archivo más pequeño y manejable. Esta técnica no es para todo el mundo, pero puede ser útil para mantenerse organizado cuando se codifican plantillas complejas de correo electrónico o de páginas. Cuando se utiliza esta técnica, se debe elegir la plantilla infantil, al crear el contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Copiar sección HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el editor de páginas, puedes copiar el marcado HubL para una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: "sección de arrastrar y soltar"
      }), " para reutilizar el código según sea necesario. Esto puede ser útil cuando se quiere recrear una sección de arrastrar y soltar en un archivo codificado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-section-hubl-menu.png",
        alt: "copy-section-hubl-menu"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/drag-and-drop-areas/sections#copy-section-hubl",
        children: "copia de la sección HubL."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}