"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937480;
const slug = exports.slug = 'guides/api/crm/owners';
const title = exports.title = 'CRM API | Owners';
const name = exports.name = 'vNext Docs DP - Owners';
const metaDescription = exports.metaDescription = 'HubSpot uses owners to assign CRM objects to specific people. These endpoints are used to get a list of available owners for an account. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "retrieve-a-list-of-owners",
  "label": "Retrieve a list of owners",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-information-about-an-individual-owner",
  "label": "Retrieve information about an individual owner",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Owners"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot owners ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: "assign"
      }), " specific users to records, activities, or marketing tasks, and can be used in personalization tokens for your content. Owners are automatically created and updated in HubSpot when new users are added or existing owners are synced from ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/salesforce/how-will-a-salesforce-owner-will-be-recognized-by-hubspot",
        children: "Salesforce"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The owners API endpoints are read-only, so you can use them to retrieve an owner's identifying details, including the owner ID. This identifier can then be used to assign ownership to CRM records in HubSpot, via an integration, or via property change API calls."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve a list of owners"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve the current owners in your account, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners"
      }), ". The response will return each user's name, email, ID values, create/update dates, and if applicable, team information. Two ID values are returned, which are used for different purposes:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": the ID of the owner. This value should be used when retrieving information about a specific owner, and when assigning an owner to a record or activity."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), ": the ID of the user. This value can be used to specify users in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "settings API"
        }), ", but will result in an error if it is used to assign ownership."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your response will look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners\n{\n  \"results\": [\n    {\n      \"id\": \"41629779\",\n      \"email\": \"email@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"HubSpot\",\n      \"lastName\": \"Test Owner\",\n      \"userId\": 9586504,\n      \"userIdIncludingInactive\": 9586504,\n      \"createdAt\": \"2019-12-25T13:01:35.228Z\",\n      \"updatedAt\": \"2023-08-22T13:40:26.790Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"60158084\",\n      \"email\": \"email@gmail.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Test\",\n      \"lastName\": \"Email\",\n      \"userId\": 9274996,\n      \"userIdIncludingInactive\": 9274996,\n      \"createdAt\": \"2021-02-10T17:59:04.891Z\",\n      \"updatedAt\": \"2023-02-09T17:41:52.767Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"81538190\",\n      \"email\": \"salesmanager@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Sales\",\n      \"lastName\": \"Manager Example\",\n      \"userId\": 3892666,\n      \"userIdIncludingInactive\": 3892666,\n      \"createdAt\": \"2021-05-27T16:55:57.242Z\",\n      \"updatedAt\": \"2022-08-02T18:34:35.039Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also retrieve archived owners to view users that were deactivated. To do so, add the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "archived"
      }), " parameter with the value ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". For archived users, there is still an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " value, but the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userId"
      }), " value will be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), ". The user ID is instead stored in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userIdIncludingInactive"
      }), " field."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners/?archived=true\n{\n  \"results\": [\n    {\n      \"id\": \"42103462\",\n      \"email\": \"useremail@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"\",\n      \"lastName\": \"\",\n      \"userId\": null,\n      \"userIdIncludingInactive\": 9685555,\n      \"createdAt\": \"2020-01-09T20:28:50.080Z\",\n      \"updatedAt\": \"2020-01-09T20:28:50.080Z\",\n      \"archived\": true\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve information about an individual owner"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve a specific owner, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners/{ownerId}"
      }), ". You should use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " value to specify the owner for which you want more details."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), " value in the response changes based on updates to the Owner object itself. It will not be updated for changes to the User object. For example, changing a user's permissions will ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " update the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), " value."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}