"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 176144371784;
const slug = exports.slug = 'guides/crm/ui-extensions/local-development';
const title = exports.title = 'Local development for UI extensions (BETA)';
const name = exports.name = 'Local development for UI extensions (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to build UI extensions for public apps.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "prerequisites",
  "label": "Prerequisites",
  "parent": null
}, {
  "depth": 0,
  "id": "start-a-local-development-server",
  "label": "Start a local development server",
  "parent": null
}, {
  "depth": 0,
  "id": "authenticating-private-app-requests",
  "label": "Authenticating private app requests",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li",
      pre: "pre",
      a: "a",
      strong: "strong",
      img: "img",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Local development for UI extensions (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When building a UI extension, you can run a local development server to iterate and test functionality in real-time without needing to re-upload the project. The local development server is only available for the frontend portion of the app, and assumes the back-end is either running locally or is hosted."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Your app must be uploaded to your development account for the server to work. The local development server will only pick up changes made to React files. For changes to configuration files, you'll need to use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project upload"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prerequisites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To run a local development server, ensure that you meet the following prerequisites."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Your project must be already uploaded to your account (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You must install the necessary dependencies in your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "extensions"
        }), " directory. If you haven't done so yet, navigate into the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/src/app/extensions"
        }), " directory and run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You must be running v6.1.0 of the HubSpot CLI or above. You can check your version by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs --version"
        }), ", and update by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm i -g @hubspot/cli@latest"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd src/app/extensions\nnpm install\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Start a local development server"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before starting local development, keep in mind the following for public apps:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you're building a public app that's installed in production accounts, you should not use the production app for local development. Instead, work with a duplicate of the app to avoid making updates to the production app. You can clone an existing public app by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project clone-app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you have a locally running back-end, you can set up a proxy to remap ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.fetch()"
        }), " requests made during local development. This proxy is configured through a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "local.json"
        }), " file in your project. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/fetching-data#proxying-requests-to-a-locally-running-back-end",
          children: "proxying requests to a locally running back-end"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To start a local development server:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the terminal, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project dev"
        }), " command in your working project directory."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project dev\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You'll then be prompted to select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "account"
          }), " to run the server for. This should be the account that the app is installed in."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "If you're building a public app, you'll only be able to select from your connected developer test accounts."
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/cli-select-developer-test-account.png",
                alt: "cli-select-developer-test-account"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "If you're building a private app, you can select a production account or one of its development sandboxes, or you can create a new development sandbox."
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when creating a new development sandbox, if you receive the error ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "The personal access key you provided doesn't include sandbox permissions"
        }), ", you'll need to deactivate the account's Personal Access Key, then create a new one with sandbox permissions. To do so, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs auth"
        }), ", then follow the prompts to select your account. Then, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Deactivate"
        }), " next to the personal access key, and generate a new one with the proper scopes."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If your project has multiple extensions, you'll be prompted to select which extension to run. You can run multiple extensions from the same app, but not multiple extensions across multiple apps."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After selecting the account and extension, the local development server will then start. To view the locally running version of your UI extension:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "In your browser, navigate to where the app's UI extension is located, or refresh the page if it's already loaded."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["The extension will be updated with a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Developing locally"
          }), " tag, which indicates that changes to your React files will now be picked up automatically on save without needing to refresh. Changes made to configuration files will not be picked up by the local development server, and will instead need to be uploaded with ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs project upload"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-developing-locally.png",
            alt: "public-app-developing-locally"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you're ready to push your changes to HubSpot, end the server by pressing ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "q"
      }), " in the terminal, then run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project upload"
      }), ". If your project is not enabled for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/create-a-project#view-the-project-in-hubspot",
        children: "auto-deploy"
      }), ", you can then deploy it after successful build by running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project deploy"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authenticating private app requests"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For private apps, the private app access token, and any secrets you might need to use, will need to be made available to the local development server through a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".env"
      }), " file within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " directory. You can find the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps#view-the-app-in-hubspot",
        children: "private app access token in the app's settings in HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions",
        children: "serverless functions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "PRIVATE_APP_ACCESS_TOKEN=pat-na1-******\nANOTHER_SECRET=my-secret-value\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}