"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601073957;
const slug = exports.slug = 'getting-started/tools-to-help-you-build';
const title = exports.title = 'Tools to help you build';
const name = exports.name = 'Tools to help you build (developer docs 2024)';
const metaDescription = exports.metaDescription = 'Learn about the different tools and resources available to help you build on HubSpot.';
const position = exports.position = 3;
const toc = exports.toc = [{
  "depth": 0,
  "id": "hubspot-cli",
  "label": "HubSpot CLI",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-vs-code-extension",
  "label": "HubSpot VS Code extension",
  "parent": null
}, {
  "depth": 0,
  "id": "client-libraries",
  "label": "Client libraries",
  "parent": null
}, {
  "depth": 0,
  "id": "postman",
  "label": "Postman",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Tools to help you build"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot provides a range of tooling to help you as you build on its developer platform. HubSpot’s APIs are flexible and you can always use your preferred development environment to build an integration, but the tools listed below are available to all developers to enhance productivity and help streamline your development workflow."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot CLI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you’re building on the HubSpot CMS, you can install the HubSpot CLI to build on HubSpot locally. Once installed, you can use your preferred tools in your preferred environment. You can also set up continuous integration with a GitHub account to track changes and help you collaborate with other developers in your organization."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After installing the CLI, you can use it to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create and manage CMS assets, such as themes and modules. Check out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "CMS quickstart guide"
        }), " to get started."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create and manage UI extensions to customize CRM records and the help desk UI (BETA). Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/overview#get-started",
          children: "get started building UI extensions"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "install the HubSpot CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot VS Code extension"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot Visual Studio Code extension allows you to centralize your development workflow without having to leave VS Code. Once installed, you can use the HubSpot CLI directly to authenticate and manage your connected accounts, as well as leverage HubL syntax highlighting and support for autocomplete. You can also sync files with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager",
        children: "design manager"
      }), " of your default HubSpot account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
        children: "install and use the HubSpot VS Code extension"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Client libraries"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot provides client libraries so you can use your preferred programming language when using HubSpot APIs. Client libraries are available in JavaScript, Ruby, Python, and PHP. Each client library allows you to invoke function calls to read, edit, create, and delete data in your HubSpot account instead of having to craft API requests manually."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about setting up and using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "Node"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-php",
        children: "PHP"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-ruby",
        children: "Ruby"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-python",
        children: "Python"
      }), " client libraries."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Postman"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Postman is a feature-rich API client you can use to easily make authenticated requests to HubSpot. You can also browse curated collections of all of HubSpot’s public API endpoints. These collections are regularly maintained by HubSpot to keep the specifications for all request and response fields up-to-date."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Download the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/",
        children: "Postman client"
      }), ", then browse the official ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/hubspot",
        children: "HubSpot Postman collections"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}