"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694132;
const slug = exports.slug = 'guides/cms/content/modules/overview';
const title = exports.title = "Vue d'ensemble des modules";
const name = exports.name = "Vue d'ensemble des modules";
const metaDescription = exports.metaDescription = 'Il est essentiel de comprendre les modules pour pouvoir comprendre HubSpot CMS et sa puissance. Les modules sont des composants réutilisables qui peuvent être utilisés dans des modèles ou ajoutés à des pages via des zones de glisser-déposer et des colonnes flexibles.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/team-member-editor.png';
const featuredImageAltText = exports.featuredImageAltText = "Éditeur du module membre de l'équipe";
const toc = exports.toc = [{
  "depth": 0,
  "id": "voici-un-exemple",
  "label": "Voici un exemple",
  "parent": null
}, {
  "depth": 1,
  "id": "l-interface-utilisateur-pour-l-%C3%A9dition",
  "label": "L'interface utilisateur pour l'édition",
  "parent": "voici-un-exemple"
}, {
  "depth": 0,
  "id": "comparaison-du-module-et-de-l-instance-de-module",
  "label": "Comparaison du module et de l'instance de module",
  "parent": null
}, {
  "depth": 0,
  "id": "fields.json",
  "label": "Fields.json",
  "parent": null
}, {
  "depth": 1,
  "id": "utilisation-des-donn%C3%A9es-du-champ-du-module-pour-rendre-le-html",
  "label": "Utilisation des données du champ du module pour rendre le HTML",
  "parent": "fields.json"
}, {
  "depth": 0,
  "id": "utilisation-des-modules-dans-les-mod%C3%A8les",
  "label": "Utilisation des modules dans les modèles",
  "parent": null
}, {
  "depth": 0,
  "id": "les-modules-sont-un-excellent-outil-dans-la-bo%C3%AEte-%C3%A0-outils-d-accessibilit%C3%A9",
  "label": "Les modules sont un excellent outil dans la boîte à outils d'accessibilité",
  "parent": null
}, {
  "depth": 0,
  "id": "les-modules-peuvent-faciliter-la-localisation",
  "label": "Les modules peuvent faciliter la localisation",
  "parent": null
}, {
  "depth": 0,
  "id": "pour-commencer",
  "label": "Pour commencer",
  "parent": null
}, {
  "depth": 0,
  "id": "aller-plus-loin",
  "label": "Aller plus loin",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ol: "ol",
      li: "li",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      strong: "strong",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Vue d'ensemble des modules"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il est essentiel de comprendre les modules pour pouvoir comprendre HubSpot CMS et sa puissance. Les modules sont des composants réutilisables qui peuvent être utilisés dans des modèles ou ajoutés à des pages via des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "zones de glisser-déposer"
      }), " et des", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: " colonnes flexibles"
      }), ". En plus d'utiliser les modules fournis par HubSpot, les développeurs peuvent créer leurs propres modules pour tout, des témoignages aux galeries de photos. Les modules sont créés ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "à l'aide des outils de développement locaux"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "en utilisant le Gestionnaire de conception"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un module est composé de deux parties :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-user-interface-for-editing",
          children: "interface utilisateur"
        }), " créée par une liste de champs que les utilisateurs verront lors de l'édition d'une instance de module."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un fragment de modèle HTML+HubL avec CSS et JS associés qui définissent comment le HTML sera généré"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Voici un exemple"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour mieux comprendre ce qu'est un module, examinons un module simple, « Membre de l'équipe ». Le module se compose d'une photo, du nom du membre de l'équipe, de son titre et d'une courte biographie. Il ressemble à une partie d'une page Web du CMS :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-module-instance.png",
        alt: "Instance du module membre de l'équipe"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "L'interface utilisateur pour l'édition"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le développeur construit l'interface utilisateur (IU) des modules en utilisant des champs. Le développeur choisit ensuite les champs à utiliser en fonction du type de module à construire, des données nécessaires et de l'expérience d'édition. Dans ce cas, le module comprend :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "un champ d'image, pour la photo d'un membre de l'équipe"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "deux champs de texte, pour le nom et la fonction du membre de l'équipe"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "et un champ de texte enrichi, pour une courte biographie."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsqu'un créateur de contenu modifie un module, l'interface utilisateur est construite sur la base des champs que le développeur a ajoutés au module et de la façon dont chaque champ est configuré."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-editor.png",
        alt: "Éditeur du module membre de l'équipe"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comparaison du module et de l'instance de module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe deux termes fréquemment utilisés concernant les modules. Il est important de comprendre la différence entre les deux."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Module"
        }), " -  : blocs de construction réutilisables qui peuvent être ajoutés aux modèles et aux pages."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Instance de module"
        }), " -  : les modules individuels rendus sur la page. Ils peuvent avoir des valeurs de champ distinctes et, par conséquent, avoir un aspect différent des autres instances de module du même module."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fields.json"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les champs d'un module sont définis en JSON comme un tableau d'objets. Chaque champ a un nom, un type et une valeur par défaut. D'autres propriétés sont également disponibles en fonction du type de champ qui contrôle l'expérience d'édition."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"name\": \"team_member_photo\",\n    \"label\": \"Team Member Photo\",\n    \"required\": true,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"type\": \"image\",\n    \"default\": {\n      \"src\": \"\",\n      \"alt\": \"\"\n    }\n  },\n  {\n    \"name\": \"team_member_name\",\n    \"label\": \"Team member name\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"Joshua Beck\"\n  },\n  {\n    \"name\": \"team_member_position\",\n    \"label\": \"Team member position\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"CEO, Co-Founder\"\n  },\n  {\n    \"name\": \"team_member_bio\",\n    \"label\": \"Team member bio\",\n    \"required\": true,\n    \"type\": \"richtext\",\n    \"default\": \"<p>Joshua has over 20 years of experience in the tech industry. He helped start this company in 2015 with the mission of helping people grow. In his spare time he loves hanging out with his kids, going to the beach, and cooking.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur tous les champs disponibles, voir ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "Champs de module et de thème"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utilisation des données du champ du module pour rendre le HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les valeurs de chaque champ sont disponibles dans le fragment HTML+HubL d'un module via une variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ". Les données de chaque champ sont accessibles via les propriétés de la variable du module. En utilisant le module membre de l'équipe comme exemple, le nom du membre de l'équipe est accessible via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ module.team_member_name }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<section class=\"team-member\">\n  <img class=\"team-member__image\" src=\"{{ module.team_member_image.src }}\" alt=\"{{ module.team_member_image.alt }}\">\n  <h3 class=\"team-member__name\">{{ module.team_member_name }}</h3>\n  <p class=\"team-member__position\">{{ module.team_member_position }}</p>\n  <div class=\"team-member__bio\">{{ module.team_member_bio }}</div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation des modules dans les modèles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modules sont ajoutés aux modèles en utilisant la balise ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#basic-module-syntax",
        children: "module"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#block-syntax",
        children: "module_block"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_module"
      }), " et en indiquant le chemin d'accès au module comme paramètre. Les valeurs par défaut des champs d'un module peuvent également être remplacées au niveau du modèle en ajoutant des paramètres à la balise du module qui correspond au nom du champ, comme le montre la deuxième partie de l'exemple ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_identifier\" path=\"/modules/team-member.module\" %}\n\n\n{# override default values in a module instance #}\n\n{% module \"unique_identifier\"\n  path=\"/modules/team-member.module\",\n  team_member_name=\"Brian Halligan\",\n  team_member_position=\"CEO\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les modules ne peuvent pas être imbriqués les uns dans les autres. La plupart du temps, lorsque vous voulez le faire c'est pour des raisons de mise en page. Les sections dans les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "zones de glisser-déposer"
        }), " sont souvent la meilleure solution."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Les modules sont un excellent outil dans la boîte à outils d'accessibilité"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modules sont utilisés sur tout un site Web, parfois sur plusieurs pages, voire de nombreuses fois sur une page. C'est pourquoi l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "élaboration du HTML, de la CSS et du JS de votre module en tenant compte de l'accessibilité"
      }), " peut avoir un grand impact sur l'utilisation de votre site par les personnes handicapées ou non."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Les modules peuvent faciliter la localisation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["De la même manière que pour l'accessibilité, élaborer des modules afin que tout le contenu du module soit basé sur des champs permet de le localiser ultérieurement. Par exemple, vous pouvez avoir un module « Articles à la une ». Au lieu de coder en dur le texte « Articles à la une », utilisez un champ de texte ou de texte enrichi. Le texte peut ensuite être modifié dans d'autres langues. Pour en savoir plus sur la localisation sur le CMS, allez à", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: " multi-langue"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pour commencer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour commencer, consultez notre tutoriel ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "Démarrer avec les modules"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aller plus loin"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configurer un module"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Utilisation de modules dans les modèles"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Modules par défaut"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "L'éditeur de module"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}