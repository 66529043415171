"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 85488833916;
const slug = exports.slug = 'guides/api/crm/exports';
const title = exports.title = 'CRM API | Exports';
const name = exports.name = 'CRM API | Exports';
const metaDescription = exports.metaDescription = 'Learn how to use the Exports API to export records in views or lists from your HubSpot account.';
const position = exports.position = 5;
const toc = exports.toc = [{
  "depth": 0,
  "id": "start-an-export",
  "label": "Start an export",
  "parent": null
}, {
  "depth": 1,
  "id": "export-a-view",
  "label": "Export a view",
  "parent": "start-an-export"
}, {
  "depth": 1,
  "id": "export-a-list",
  "label": "Export a list",
  "parent": "start-an-export"
}, {
  "depth": 0,
  "id": "retrieve-exports",
  "label": "Retrieve exports",
  "parent": null
}, {
  "depth": 0,
  "id": "limits",
  "label": "Limits",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      pre: "pre",
      strong: "strong",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Exports"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the exports API to export records and property data from your HubSpot account, retrieve a URL to download an export file, or see the status of an export. Within HubSpot, you can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/export-contacts-companies-deals-or-tickets",
        children: "export records"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-log-of-your-users-exports-in-your-account",
        children: "view a log of past exports in your account."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Start an export"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To start an export, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async"
      }), ". Your request body should specify information such as the file format, the object and properties you want to export, and the type of export you're completing (e.g., exporting an object view or a list). You can also filter the property data to be exported based on specific operators."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For both view and list exports, you can include the following fields in your request:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of export, either ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VIEW"
            }), " (exports a view from an object index page) or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LIST"
            }), " (exports a list)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The file format. Options include ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLSX"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLS"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the export."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The language of the export file. Options include ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ES"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FI"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FR"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "JA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PT"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SV"
            }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-language-offerings",
              children: "supported languages."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The name or ID of the object you're exporting. For standard objects, you can use the object's name (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            }), "), but for custom objects, you must use the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            }), " value. You can retrieve this value by making a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/schemas"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associatedObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name or ID of an associated object to include in the export. If you include an associated object, the export will contain the associated record IDs of that object and the records' primary display property value (e.g., name). You can export only one associated object per request."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A list of the properties you want included in your export."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Export a view"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're exporting an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "index page view"
      }), ", your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), " value should be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "VIEW"
      }), ", and you can include the following field to filter and sort the records you're exporting:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publicCrmSearchRequest"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indicates which data should be exported based on certain property values and search queries. You can include the following within the object:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "filters"
            }), ": the properties and property values to filter records by.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "sorts"
            }), ": the sort order of a property's values, either ascending, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASC"
            }), ", or descending, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DES"
            }), ".", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "query"
            }), ": a string to search the records' values for."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to export a view of contacts and associated company records, filtered by the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " property, your request may look like the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"VIEW\",\n  \"exportName\": \"All contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"DE\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email\", \"firstname\", \"lastname\"],\n  \"associatedObjectType\": \"COMPANY\",\n  \"publicCrmSearchRequest\": {\n    \"filters\": [\n      {\n        \"value\": \"hello@test.com\",\n        \"propertyName\": \"email\",\n        \"operator\": \"EQ\"\n      }\n    ],\n    \"query\": \"hello\",\n    \"sorts\": [\n      {\n        \"propertyName\": \"email\",\n        \"order\": \"ASC\"\n      }\n    ]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Export a list"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're exporting a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/lists/create-active-or-static-lists",
        children: "list"
      }), ", your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), " value should be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LIST"
      }), ", but you also need to specify the list you're exporting with the following field:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/lists/lists-faq#ils-list",
              children: "ILS List ID"
            }), " of the list to export. You can find the ILS List ID value via the list details in HubSpot. Navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contacts"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Lists"
            }), ", hover over the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "list"
            }), " in the table, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Details"
            }), ". In the right panel, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Copy"
            }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "List ID"
            }), " next to the ILS List ID value. Contact lists have two different ID values, but you ", (0, _jsxRuntime.jsx)("u", {
              children: "must"
            }), " use the ILS List ID value in your request."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to export a list with the contacts' emails, your request may look like the following:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"LIST\",\n  \"listId\": 1234567,\n  \"exportName\": \"Marketing email contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"EN\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email\"]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve exports"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you successfully complete an export, the export's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " will be returned in the response. To retrieve an export from your HubSpot account, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async/tasks/{exportId}/status"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When retrieving exports, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "status"
      }), " of the export will also be returned. Possible statuses include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PENDING"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSING"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CANCELED"
      }), ". For exports with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), " status, a URL is returned that you can use to download the exported file. The download URL will expire five minutes after the completed request. Once expired, you can perform another ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to generate a new unique URL."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": prior to expiration, an export's download URL can be accessed without any additional authorization. To protect your data, proceed with caution when sharing a URL or integrating with HubSpot via this API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limits"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following limits apply:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When setting filters for your export, you can include a maximum of three ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " with up to three ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " in each group."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You can complete up to thirty exports within a rolling 24 hour window, and one export at a time. Additional exports will be queued until the previous export is completed."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "An exported CSV file will be automatically zipped if the resulting file is greater than 2MB."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}