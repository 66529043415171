"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866608851;
const slug = exports.slug = 'reference/api/overview';
const title = exports.title = 'API de HubSpot | Premiers pas';
const name = exports.name = 'vNext Docs DP - Commencer';
const metaDescription = exports.metaDescription = "L'API de HubSpot permet aux développeurs de créer des applications et des intégrations personnalisées. Commencez par lire la présentation des API et par créer un compte de développeur.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "configuration",
  "label": "Configuration",
  "parent": null
}, {
  "depth": 1,
  "id": "biblioth%C3%A8ques-clients",
  "label": "Bibliothèques clients",
  "parent": "configuration"
}, {
  "depth": 0,
  "id": "documentation-des-api",
  "label": "Documentation des API",
  "parent": null
}, {
  "depth": 1,
  "id": "documents-de-r%C3%A9f%C3%A9rence",
  "label": "Documents de référence",
  "parent": "documentation-des-api"
}, {
  "depth": 1,
  "id": "guides-d-int%C3%A9gration",
  "label": "Guides d'intégration",
  "parent": "documentation-des-api"
}, {
  "depth": 0,
  "id": "lister-une-application",
  "label": "Lister une application",
  "parent": null
}, {
  "depth": 0,
  "id": "support-et-ressources-de-la-communaut%C3%A9",
  "label": "Support et ressources de la communauté",
  "parent": null
}, {
  "depth": 0,
  "id": "tirer-parti-du-cms",
  "label": "Tirer parti du CMS",
  "parent": null
}, {
  "depth": 2,
  "id": "documents-associ%C3%A9s",
  "label": "Documents associés",
  "parent": "tirer-parti-du-cms"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Table
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Documentation de référence pour les API de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La plateforme de développement de HubSpot est une partie fondamentale de notre mission consistant à aider les organisations à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/grow-better",
        children: "mieux se développer"
      }), ". Nos API sont conçues pour permettre à toutes les équipes de mettre en place des intégrations solides afin de personnaliser et de tirer le meilleur parti de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Toutes les API de HubSpot sont ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/Representational_state_transfer",
        children: "conçues à l'aide de conventions REST"
      }), " et développées pour avoir une structure d'URL prévisible. Elles utilisent de nombreuses fonctionnalités HTTP standards, notamment des méthodes (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), ") et des codes de réponse d'erreur. Tous les appels d'API de HubSpot sont effectués sous ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com"
      }), " et toutes les réponses renvoient des données JSON standards."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuration"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Plusieurs méthodes permettent de créer des intégrations avec HubSpot :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour créer une intégration interne pour un compte HubSpot individuel (par exemple, vous souhaitez créer une application qui peut accéder et modifier uniquement les parties pouvant être partagées ou intégrées avec d'autres parties de votre organisation), créez une application ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "privée"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous souhaitez créer une application publique pouvant être installée sur plusieurs comptes HubSpot, vous devez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
          children: "créer un compte de développeur"
        }), ". Plusieurs raisons expliquent cela : un compte de développeur permet de créer des applications HubSpot, chacune authentifiée avec OAuth et fournie avec un ensemble configurable de fonctionnalités et d'autorisations. Vous pouvez également utiliser votre compte de développeur pour ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "créer des comptes de test"
        }), ", surveiller le statut et les performances des applications ou publier des applications sur le marketplace des applications de HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les différents types d'applications et de comptes dans ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#account-relationships",
        children: "cet article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bibliothèques clients"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les bibliothèques de clients sont conçues pour vous aider à interagir sans encombre avec les API de HubSpot. Elles sont rédigées dans plusieurs langages et permettent de combler les écarts entre vos applications et les API de HubSpot. Ainsi, il est inutile de connaître l'URL exacte et la méthode HTTP à utiliser pour chaque appel d'API, entre autres. Vous pouvez alors vous concentrer pleinement sur la conception de votre application. Découvrez-en davantage sur les bibliothèques de clients ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/overview",
        children: "ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Table, {
      children: [(0, _jsxRuntime.jsx)("thead", {
        children: (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("th", {
            children: "Langue"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: "Lien du package"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "Code source"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_nodejs-512_339733.png",
              alt: "Node.Js"
            }), "Node.Js"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://www.npmjs.com/package/@hubspot/api-client",
              children: "npm install @hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "hubspot-api-nodejs"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/new-php-logo.png",
              alt: "PHP"
            }), "PHP"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://packagist.org/packages/hubspot/api-client",
              children: "composer require hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-php",
              children: "hubspot-api-php"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/ruby.png",
              alt: "Ruby"
            }), "Ruby"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://rubygems.org/gems/hubspot-api-client",
              children: "gem install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-ruby"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_267_Python_logo_4375050.png",
              alt: "Python"
            }), "Python"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://pypi.org/project/hubspot-api-client/",
              children: "pip install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-python"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Documentation des API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La documentation des API de HubSpot est divisée en deux sections : les documents de référence et les guides d'intégration."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Documents de référence"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tous les documents de référence sur les API comprennent une vue d'ensemble et une section sur les points de terminaison. La vue d'ensemble d'une API comprend un bref récapitulatif de sa fonctionnalité, des cas d'utilisation et toute autre considération particulière pour la création d'une intégration. La section sur les points de terminaison liste chaque point de terminaison, ses paramètres et des exemples de demandes dans plusieurs langages."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que vous avez configuré les paramètres d'authentification de votre application dans votre compte de développeur, vous pouvez utiliser Postman ou réaliser des appels de test directement depuis la page de référence d'un point de terminaison."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guides d'intégration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez découvrir les fondamentaux de la plateforme de HubSpot ou voir un exemple avant d'effectuer votre premier appel d'API, vous pouvez trouver des exemples d'applications et des tutoriels ainsi que des informations détaillées sur les comptes de développeur, l'utilisation d'OAuth, les limites des API et bien plus encore dans la section ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "Guides d'intégration"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Associé :"
      }), " découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "méthodes d'authentification"
      }), " ou sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lister une application"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous êtes prêt à partager votre application avec le monde entier et à devenir un partenaire d'application, consultez les conditions de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "listing"
      }), " et de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "certification"
      }), ". Une fois cette étape terminée, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "créer"
      }), " et gérer votre listing du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "marketplace des applications"
      }), " à partir de votre compte de développeur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Support et ressources de la communauté"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtenez des réponses, tissez des liens et partagez vos idées en rejoignant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "les forums de la communauté des développeurs"
      }), " de HubSpot et la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/developer-slack",
        children: "communauté Slack"
      }), ". Ces forums sont d'excellents espaces pour faire entendre votre voix. Les retours de la communauté sont extrêmement précieux pour nous et nos efforts continus pour améliorer l'expérience de développeur de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également rester au fait des nouvelles fonctionnalités, des annonces et des modifications importantes en vous abonnant au ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "changelog"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tirer parti du CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous souhaitez créer un site web, un blog, une page de destination, une application légère ou un e-mail ? Consultez notre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "documentation des développeurs sur le CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documents associés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "Comment utiliser les API de HubSpot"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}