"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890177;
const slug = exports.slug = 'guides/cms/content/data-driven-content/crm-objects';
const title = exports.title = 'Objetos del CRM en CMS Hub';
const name = exports.name = 'LATAM (ES) CRM Custom Objects in CMS Hub';
const metaDescription = exports.metaDescription = 'Los objetos del CRM se pueden consultar y renderizar en el contenido alojado en HubSpot, lo que permite compartir datos entre tus operaciones comerciales, el sitio web y los correos electrónicos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tipos-de-objetos-del-crm-admitidos",
  "label": "Tipos de objetos del CRM admitidos",
  "parent": null
}, {
  "depth": 1,
  "id": "datos-del-objeto-crm-disponibles-para-todas-las-p%C3%A1ginas",
  "label": "Datos del objeto CRM disponibles para todas las páginas",
  "parent": "tipos-de-objetos-del-crm-admitidos"
}, {
  "depth": 1,
  "id": "datos-del-objeto-del-crm-disponibles-para-las-p%C3%A1ginas-privadas",
  "label": "Datos del objeto del CRM disponibles para las páginas privadas",
  "parent": "tipos-de-objetos-del-crm-admitidos"
}, {
  "depth": 0,
  "id": "visualizar-los-datos-de-un-solo-registro-de-crm-con-la-funci%C3%B3n-crm_object",
  "label": "Visualizar los datos de un solo registro de CRM con la función crm_object",
  "parent": null
}, {
  "depth": 0,
  "id": "visualizaci%C3%B3n-de-datos-de-varios-registros-del-crm-con-la-funci%C3%B3n-crm_objects",
  "label": "Visualización de datos de varios registros del CRM con la función crm_objects",
  "parent": null
}, {
  "depth": 0,
  "id": "mostrar-los-registros-asociados",
  "label": "Mostrar los registros asociados",
  "parent": null
}, {
  "depth": 0,
  "id": "obtener-los-detalles-de-un-tipo-de-objeto-personalizado",
  "label": "Obtener los detalles de un tipo de objeto personalizado",
  "parent": null
}, {
  "depth": 0,
  "id": "campo-del-m%C3%B3dulo-de-objetos-del-crm",
  "label": "Campo del módulo de objetos del CRM",
  "parent": null
}, {
  "depth": 0,
  "id": "tutoriales-y-recursos-relacionados-con-objetos-del-crm",
  "label": "Tutoriales y recursos relacionados con objetos del CRM",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h3: "h3",
      em: "em",
      h2: "h2",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      img: "img",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Utilizar los datos de los objetos del CRM en CMS Hub"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes consultar los objetos de CRM para utilizar los datos de registros del objeto en el contenido alojado en HubSpot, lo que permite compartir los datos entre tus operaciones comerciales, el sitio web y los correos electrónicos. Utilizando las funciones de Hubl ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        })
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), ", puedes mostrar y controlar la lógica basada en los datos de tu objeto del CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilizar los datos de CRM en tu sitio web significa que tus datos de ventas, marketing y sitio web se encuentran todos en el mismo lugar y siempre reflejarán la misma información. Además, debido a que puedes asociar registros de CRM entre sí, también puedes extraer datos asociados en las páginas de tu sitio web."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Del mismo modo, puede ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "crear conjuntos de páginas dinámicas que se generen automáticamente utilizando los datos del objeto de CRM o de HubDB"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Puedes obtener más información sobre la creación de páginas de CMS basadas en datos en el curso de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "Contenido basado en datos de CMS"
        }), " de HubSpot Academy."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Caso de uso de ejemplo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un ejemplo de uso de los datos de los objetos de CRM en las páginas es una página de contenido de bienes raíces. Con un objeto personalizado llamado ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "propiedad"
      }), ", se pueden crear registros de objetos individuales para cada casa que necesite ser listada. Los agentes de bienes raíces pueden agregar información a las propiedades objeto para almacenar detalles, como la ubicación, el número de habitaciones y los precios de venta."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las páginas del sitio web pueden entonces extraer los datos de los registros de cada propiedad para crear una página de contenido y páginas de detalles para cada propiedad."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consulta el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-custom-objects-example",
        children: "repositorio de GitHub"
      }), " para ver el ejemplo completo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener una visión general de este ejemplo, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/developer-day-2020",
        children: "grabación del día del desarrollador de HubSpot 2020"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de objetos del CRM admitidos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se muestran los tipos de objetos del CRM de los que puedes extraer datos para tus páginas de CMS Hub. La posibilidad de utilizar los datos en todas las páginas o sólo en las páginas privadas depende del tipo de objeto."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En las tablas siguientes, obtén más información sobre los tipos de objetos disponibles para el contenido CMS junto con sus nombres de tipo de objeto y nombres totalmente cualificados."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " los nombres de objetos estándar, como \"contacto\", no distinguen entre mayúsculas y minúsculas, pero deben ser singulares."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Datos del objeto CRM disponibles para todas las páginas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los datos de los siguientes objetos del CRM pueden utilizarse en cualquier página de CMS."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Tipo de objeto"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "object_type name"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nombre totalmente cualificado"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "Productos"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRODUCT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "Eventos de marketing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "marketing_event"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/custom-objects",
              children: "Objetos personalizados"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Solo ", (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Enterprise"]
            }), ". Puedes utilizar el nombre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "nombre totalmente cualificado"
            }), " del objeto o el nombre que se introdujo en el momento de la creación. Por ejemplo, si creas un objeto llamado Autos\", no puedes hacer referencia a él con autos\" o Auto\".Debes usar el ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "nombre totalmente cualificado"
            }), " si el objeto personalizado comparte un nombre con un objeto estándar."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Datos del objeto del CRM disponibles para las páginas privadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los datos de los siguientes objetos del CRM solo pueden utilizarse en páginas que requieran una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/website-pages/password-protect-a-page",
        children: "contraseña"
      }), " o un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/website-pages/require-member-registration-to-access-private-content",
        children: "inicio de sesión de miembro."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Tipo de objeto"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "object_type name"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "NOMBRE TOTALMENTE CUALIFICADO"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Contactos"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "Empresas"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPANY"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "Negocios"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DEAL"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/tickets",
              children: "Tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TICKET"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes",
              children: "Cotizaciones"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUOTE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Objetos integradores"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para obtener el nombre de un objeto integrador, utilice la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "API del esquema de objetos del CRM"
            }), ".Para los objetos integradores con el mismo nombre que los objetos estándar, utiliza el objeto integrador ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "nombre completamente calificado"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visualizar los datos de un solo registro de CRM con la función crm_object"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza la función ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), " para obtener un único registro del CRM de HubSpot por consulta o por el ID del registro del CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los registros de los objetos se devuelven como un dictado de propiedades y valores."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom object by query #}\n{% set event = crm_object(\"event\", \"name=Defensive Health\") %}\n{{ event.name }}\n\n{# Render custom objects specifying the id of the object #}\n{% set event = crm_object(\"event\", 289236) %}\n{{ event.name }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>Defensive Heatlh</p>\n\n<p>Defensive Heatlh</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Si una consulta devuelve una colección de registros, la función devolverá el primer registro de la colección."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visualización de datos de varios registros del CRM con la función crm_objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza la función ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), " para obtener registros del CRM por tipo de objeto desde HubSpot CRM por consulta o por ID de registro. Los registros se devuelven como un dictado de propiedades y valores."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El registro devuelto contiene una propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " que se puede recorrer en bucle para mostrar la información de los elementos del registro."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom objects by query #}\n{% set events = crm_objects(\"event\", \"limit=3&type=virtual\") %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n\n{# Render custom objects by ids #}\n{% set events = crm_objects(\"event\", [289236,289237,289238]) %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  3 New Events:\n  <h3>\n    <ul>\n      <li>Name: Defensive Health</li>\n      <li>Name: Body Balance</li>\n      <li>Name: Happy Heart</li>\n      <ul>\n        <h3>\n          3 New Events:\n          <h3>\n            <ul>\n              <li>Name: Defensive Health</li>\n              <li>Name: Body Balance</li>\n              <li>Name: Happy Heart</li>\n              <ul></ul>\n            </ul>\n          </h3>\n        </h3>\n      </ul>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mostrar los registros asociados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza la función de HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), " para obtener una lista de registros asociados del CRM de HubSpot basada en el ID del registro, la categoría de la asociación y el ID de definición de la asociación", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#getting-a-custom-object-s-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los registros se devuelven como un dictado de propiedades y valores."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set associated_objects = crm_associations(289236, \"USER_DEFINED\", 3) %}\n<h3>Contacts Associated With Event</h3>\n<ul>\n{% for contact in associated_objects.results %}\n\t<li>Name: {{ contact.firstname }} {{ contact.lastname }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  Contacts Associated With Event\n  <h3>\n    <ul>\n      <li>Name: Brian Halligan</li>\n\n      <li>Name: Dharmesh Shah</li>\n\n      <li>Name: Yamini Rangan</li>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtener los detalles de un tipo de objeto personalizado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener un tipo de objeto personalizado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "nombre"
      }), ", el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ID"
      }), ", el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fullyQualifiedName"
      }), ", los ID de asociación y otros detalles, puedes realizar una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/crm/objects/custom-objects",
        children: "API del esquema de objetos de CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), " para los tipos de objetos de la cuenta especifica incluye el ID de la cuenta de HubSpot, por lo que se recomienda evitar su uso cuando se desarrolle tu código para varias cuentas de HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campo del módulo de objetos del CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para proporcionar una forma para que los creadores de contenido seleccionen los registros del CRM para mostrar o ejecutar la lógica, puedes crear módulos que incluyan el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#crm-object",
        children: "campo de objeto del CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, puedes querer mostrar información de un producto específico, contacto, empresa, negocio, cotización, ticket u objeto personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/CRM%20Object%20Field.png",
        alt: "Campo de objeto del CRM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriales y recursos relacionados con objetos del CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/essentials-for-getting-started-with-custom-objects",
          children: "Elementos básicos para empezar a usar objetos personalizados"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-think-like-an-architect-by-building-scalable-custom-objects",
          children: "Piensa como un arquitecto: crea objetos personalizados escalables"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/crm-objects",
          children: "Crear páginas dinámicas con objetos del CRM"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}