"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50631386765;
const slug = exports.slug = 'guides/cms/storage/file-manager';
const title = exports.title = 'ファイルマネージャー';
const name = exports.name = 'APAC JAPAN (ja) | [new] File Manager_JA | 202108';
const metaDescription = exports.metaDescription = 'HubSpotのCMSが採用しているコンテンツ配信ネットワーク上に画像をホスティングし、制作担当者がコンテンツに簡単にファイルを追加できるように、HubSpotファイルマネージャーを活用します。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%81%AE%E7%94%A8%E9%80%94",
  "label": "ファイルマネージャーの用途",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%81%B8%E3%81%AE%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E3%82%A2%E3%83%83%E3%83%97%E3%83%AD%E3%83%BC%E3%83%89",
  "label": "ファイルマネージャーへのファイルのアップロード",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%81%AE%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E4%BD%BF%E7%94%A8",
  "label": "ファイルマネージャーのファイルの使用",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%9C%80%E9%81%A9%E5%8C%96",
  "label": "最適化",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%81%8B%E3%82%89%E3%81%AEhtml%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%A8js%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E9%85%8D%E4%BF%A1",
  "label": "ファイルマネージャーからのHTMLファイルとJSファイルの配信",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ファイルマネージャー"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ファイルの格納や配信には、デベロッパー ファイル システムに加えて、HubSpotのファイルマネージャーも使用できます。ファイルマネージャー内のファイルは、HubSpotのグローバルコンテンツ配信ネットワーク（CDN）経由で配信されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既定では、ファイルマネージャーにアップロードした全てのファイルは公開アクセスで、検索エンジンによってインデックス登録される場合があります。ファイルをアップロードした後、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/files/organize-edit-and-delete-files#edit-your-file-s-visibility-settings",
        children: "ファイルの可視性の設定を管理"
      }), "して、ファイルのインデックス登録またはファイルへのアクセスを防止することができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルマネージャーは、上部ナビゲーションメニューの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/files/",
        children: "［マーケティング］＞［ファイルとテンプレート］＞［ファイル］"
      }), "にあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/image-png-May-24-2023-05-49-31-9738-AM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルマネージャーの用途"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "一般的に、ファイルマネージャーはHubSpot上のファイル選択機能に使用するファイルに使用します。例えば、画像モジュールまたはリッチ テキスト モジュール内で画像を選択する場合などです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ファイルマネージャーで使用するファイルをアップロードする前に："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["特定の", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/files/supported-file-types#files-tool",
          children: "ファイルのサイズとタイプの制限"
        }), "が適用されます。ファイルマネージャーに", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/files/upload-files-to-use-in-your-hubspot-content#before-you-get-started",
          children: "ファイルをアップロードする前に考慮すべき事項"
        }), "について詳細をご確認ください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ファイルマネージャーにアップロードされたファイルは、軽微な画像ファイル編集を除いて、HubSpotアプリ内では編集できません。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "編集の必要があるテキストベースのファイルは、デザインマネージャーに保存してください。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ファイルマネージャーにアップロードされたテキストベースのファイルが軽量化または変更されることはありません。HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#javascript-minification",
          children: "JavaScript軽量化"
        }), "と", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "CSSの軽量化および結合"
        }), "を活用する場合は、ファイルをデザインマネージャーに保存します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルマネージャーへのファイルのアップロード"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ファイルマネージャーにファイルをアップロードする方法は以下のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotに直接ファイルをアップロードするには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/files/upload-files-to-use-in-your-hubspot-content",
          children: "ファイルマネージャーにファイルをアップロードする"
        }), "方法をご確認ください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS CLI"
        }), "経由でファイルをアップロードするには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload filemanager"
        }), "コマンドを使用します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["APIを使用してファイルをアップロードするには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/files/v3#post-%2Ffiles%2Fv3%2Ffiles",
          children: "HubSpotの新規ファイルアップロードAPI"
        }), "をご確認ください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルマネージャーにファイルをアップロードしたら、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/files/organize-edit-and-delete-files",
        children: "ファイルとファイルの詳細を整理および管理する方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルマネージャーのファイルの使用"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ファイルマネージャーにアップロードされたファイルは、以下の方法でアクセスできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ファイルマネージャーにアップロードされたファイルは、ページ上のリッチ テキスト モジュールや画像モジュールの中など、HubSpotおよびHubSpotのCMS全体のさまざまなファイル選択機能から利用できます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ファイルマネージャーにアップロードされたファイルは、直接ダウンロードリンク経由でアクセスできます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/files/provide-a-direct-download-link-to-a-file-hosted-on-the-files-tool",
          children: "ファイルの直接ダウンロードリンクを取得する"
        }), "方法をご確認ください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "最適化"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルマネージャーのファイルは、自動的に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#browser-and-server-caching",
        children: "キャッシュ処理"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#image-compression-optimization-and-automatic-image-resizing",
        children: "圧縮、サイズ変更されて効率的に配信"
      }), "されます。また、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#domain-rewriting",
        children: "クロスオリジンリクエスト（CORS）低減のため、ホスティングされた貴社の全ドメインを通じてアクセス可能"
      }), "です。HubSpotのCMSに関する", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "CDN、セキュリティー、パフォーマンス"
      }), "の詳細をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルマネージャーからのHTMLファイルとJSファイルの配信"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルマネージャーにアップロードされ、既定のHubSpotドメイン（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "f.hubspotusercontentXX.net"
      }), "など）を使用して配信されるHTMLファイルとJavaScriptファイルでは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "text/plain"
      }), "のコンテンツタイプが使用されます。つまり、ウェブブラウザーによるコードのレンダリングや評価は行われません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この場所のHTMLファイルにユーザーが直接アクセスした場合、HTMLコード自体がユーザーに表示されます。この状態を回避するために、このようなファイルはHubSpotの既定のドメインではなく、接続されたドメインの1つを使用して配信する必要があります。"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}