"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42748432497;
const slug = exports.slug = 'guides/apps/marketplace/app-marketplace-listing-requirements';
const title = exports.title = 'App Marketplace | App-Listing-Voraussetzungen';
const name = exports.name = 'EMEA DACH (DE) App Listing Requirements';
const metaDescription = exports.metaDescription = 'App-Partner können jetzt die Richtlinien und Voraussetzungen von HubSpot für ein Listing Ihrer App im App Marketplace an einem zentralen Ort einsehen. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "mindestanforderungen",
  "label": "Mindestanforderungen",
  "parent": null
}, {
  "depth": 1,
  "id": "markenvoraussetzungen",
  "label": "Markenvoraussetzungen",
  "parent": "mindestanforderungen"
}, {
  "depth": 1,
  "id": "listing-voraussetzungen",
  "label": "Listing-Voraussetzungen",
  "parent": "mindestanforderungen"
}, {
  "depth": 0,
  "id": "%C3%BCberpr%C3%BCfung%2C-feedback-und-genehmigung",
  "label": "Überprüfung, Feedback und Genehmigung",
  "parent": null
}, {
  "depth": 0,
  "id": "boni-f%C3%BCr-gelistete-app-partner",
  "label": "Boni für gelistete App-Partner",
  "parent": null
}, {
  "depth": 0,
  "id": "weiterf%C3%BChrende-ressourcen",
  "label": "Weiterführende Ressourcen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Voraussetzungen für ein Listing im App Marketplace"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["App-Listing-Einreichungen werden manuell vom HubSpot Ecosystem Quality-Team überprüft. Wenn die unten aufgeführten Kriterien nicht erfüllt sind, werden sie abgelehnt. Sobald Ihre App diese Anforderungen erfüllt, können Sie Ihr ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "App-Listing über Ihren App-Entwickler-Account erstellen"
      }), ", indem Sie zu ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "App Marketplace > Listings > Listing erstellen"
      }), " gehen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mindestanforderungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Einzelne HubSpot-App-ID:"
          }), " Ihre App muss API-Anfragen mit der öffentlichen HubSpot-App-ID (und ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "OAuth-Client-ID"
          }), ") autorisieren, die Ihrem App-Listing zugeordnet ist."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ein Listing darf nicht zu einer anderen öffentlichen oder privaten App weiterleiten."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ihre gelistete öffentliche App darf keine andere öffentliche oder private App benötigen, um zu funktionieren."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ihre gelistete öffentliche App muss eindeutig sein. Wenn bereits eine App von Ihnen gelistet ist und Sie diese ersetzen möchten, sollten Sie die bestehende App aktualisieren, anstatt eine neue zu listen."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Erstellen Sie nicht mehrere Apps, die für denselben Anwendungsfall eine Lösung bieten. Apps mit ähnlicher Funktionalität und Verwendung der gleichen APIs sollten in einer einzigen App konsolidiert werden."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "OAuth:"
          }), " Ihre App darf nur OAuth als Autorisierungsmethode verwenden. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "Arbeiten mit OAuth"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Installationen:"
          }), " Ihre App muss über mindestens drei ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/certification-requirements#:~:text=Active%20installs%20are%20the%20number%20of%20unique%20HubSpot%20production%20accounts%2C%20unaffiliated%20with%20your%20organization%2C%20showing%20successful%20app%20activity%20within%20the%20past%2030%20days.",
            children: "aktive, eindeutige Installationen"
          }), " verfügen. Ansonsten können Sie Ihr App-Listing nicht einreichen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Bereiche:"
          }), " Sie dürfen nur Bereiche anfragen, die Ihre App benötigt. ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth#scopes",
            children: "Überprüfen Sie Ihre Bereiche"
          }), " und stellen Sie sicher, dass Sie keine unnötigen Zugriffsberechtigungen anfordern. Apps, die dies tun, haben in der Regel bessere Konversionsraten."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In Ihrer App müssen ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "erweiterte Bereichseinstellungen"
            }), " aktiviert sein. Alle erforderlichen, bedingt erforderlichen und optionalen Bereiche sollten ausgewählt werden, um Fehler zu vermeiden. Diese Einstellungen finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/getting-started/account-types#app-developer-accounts",
              children: "Entwickler-Account"
            }), ", der Ihre App verwaltet."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Bedingungen:"
          }), " Sie müssen sich die Bedingungen in der ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/de/app-program-agreement",
            children: "Vereinbarung für das App-Partnerprogramm von HubSpot"
          }), " durchlesen und ihnen zustimmen. Dies dient Ihrem Schutz, dem von HubSpot und dem unserer gemeinsamen Kunden. Sie können Ihr App-Listing nicht einreichen, ohne diesen Schritt abzuschließen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Eingeschränkte Branchen:"
          }), " Ihre App darf keine Funktionen enthalten oder bereitstellen, die ausschließlich Kunden in einer der ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/acceptable-use#Restricted-Industries",
            children: "eingeschränkten Branchen"
          }), " von HubSpot dienen."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Markenvoraussetzungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ihre App und die zugehörigen Materialien (Dokumentation, Landingpages usw.) müssen die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/branding-guidelines",
          children: "Branding-Richtlinien von HubSpot erfüllen"
        }), ". Nutzen Sie beispielsweise das „S“ in „HubSpot“ immer dann, wenn Sie auf HubSpot verweisen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ihre App und die zugehörigen Materialien (Dokumentation, Landingpages usw.) dürfen nicht gegen die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "Markennutzungsrichtlinien von HubSpot"
        }), " verstoßen. Kombinieren Sie beispielsweise nicht den Namen von HubSpot (einschließlich „Hub“ und „HubSpot“) mit Ihrem App-Namen oder -Logo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Listing-Voraussetzungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie die Mindestanforderungen erfüllt haben, können Sie Ihr App-Listing einreichen. Beim Einreichen Ihres App-Listings müssen Sie alle Informationen vollständig und genau angeben. Diese Felder sind besonders wichtig und bei Nichteinhaltung dieser Anforderungen wird Ihr Listing nur im Entwurfsmodus angezeigt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Der Inhalt Ihres Eintrags sollte spezifisch für die Integration sein, im Gegensatz zu allgemeinen Produktinformationen. Es sollte Informationen über den Wert enthalten, den Kunden speziell vom Herunterladen und Verwenden dieser Integration erwarten können. Gute Beispiele sind: ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/calling/aircall",
          children: "Aircall"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/sales-enablement/cloudfiles",
          children: "CloudFiles"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/partner-relationship-management/reveal-191193",
          children: "Reveal"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ein Link zu einer öffentlich zugänglichen (keine Anmeldung, keine Paywall) Setup-Dokumentation, die für Ihre HubSpot-Integration spezifisch ist.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ihre Setup-Anleitung darf nicht einfach Ihre Homepage oder eine allgemeine Wissensdatenbank sein."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Stattdessen muss sie die Schritte zum Installieren und Konfigurieren der Integration enthalten."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Sehen Sie sich als Beispiel die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://orgcharthub.com/guides/setup",
              children: "Setup-Anleitung von OrgChartHub"
            }), " an."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fügen Sie eine entsprechende URL der Installationsschaltfläche hinzu, die Kunden zu einer Seite führt, auf der sie Ihre App ganz einfach mit HubSpot verknüpfen können."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "URLs für die Support-Ressourcen Ihrer App (Support-Website, HubSpot Community-Forum, Fallstudie) müssen veröffentlicht, auf dem neuesten Stand und öffentlich zugänglich sein."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "URLs für die Nutzungsbedingungen und Datenschutzrichtlinie Ihrer App müssen veröffentlicht und auf dem neuesten Stand sein."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Alle URL-Felder unterliegen einer Beschränkung von 250 Zeichen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "Geteilte Daten"
        }), ", die Benutzer informieren, wie Daten zwischen Ihrer App und HubSpot ausgetauscht werden, müssen genau und auf dem aktuellen Stand sein und die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements#:~:text=Webhooks%20API.-,Scopes,-%3A%20You%20must",
          children: "Bereiche"
        }), " darstellen, die Ihre App anfragt.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Alle in Ihren OAuth-Bereichen ausgewählten Objekte sollten in der Tabelle ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Geteilte Daten"
            }), " dokumentiert sein."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn Ihre App sowohl Lese- als auch Schreibobjektbereiche anfragt, sollte die Datensynchronisierung für diese spezifischen Objekte bidirektional beworben werden."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ihre App Marketplace-Listing muss klare und genaue Preisinformationen enthalten.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Mindestens ein für Ihre HubSpot-Integration relevanter Preisplan muss mit den auf Ihrer Website veröffentlichten Informationen übereinstimmen."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Kostenlose Preispläne sollten nur für „Kostenlos, auf unbegrenzte Zeit“- oder Freemium-Preismodelle verwendet werden."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie müssen mindestens eine Support-Kontaktmethode einschließen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Befolgen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/testing-credentials",
          children: "hier aufgeführten Richtlinien"
        }), " für die Bereitstellung von Testanmeldedaten für Ihr App-Listing."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Überprüfung, Feedback und Genehmigung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie Ihr Listing eingereicht haben, führt das HubSpot Ecosystem Quality-Team innerhalb von 10 Werktagen eine erste Überprüfung durch. Wenn eine der übermittelten Angaben falsch, irreführend oder unvollständig ist, werden Sie von uns diesbezüglich kontaktiert. Der gesamte Überprüfungs- und Feedback-Prozess der App sollte nicht länger als 60 Tage ab dem Zeitpunkt dauern, an dem das Feedback übermittelt wird. Wie in den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/de/app-program-agreement",
        children: "App Marketplace-Bedingungen"
      }), " angegeben, behält sich HubSpot das Recht vor, jederzeit die Veröffentlichung Ihres App-Listings rückgängig zu machen oder abzulehnen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Boni für gelistete App-Partner"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Eigenes HubSpot App Marketplace-Listing"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Exklusiver Zugriff auf Entwickler-Support über einen fest zugeordneten Support-Alias"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Zugang zu Ressourcen der Entwickler-Community, einschließlich Webinare, Foren und mehr"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Kuratierte Marketing-Ressourcen, einschließlich PR-Vorlagen und Startleitfäden"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rabatt auf INBOUND-Event-Sponsoring, -Stände und -Tickets"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rabatt auf Software über das „HubSpot for Startups“-Seed-Phasen-Programm"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Monatlicher Newsletter mit Marketing-Updates, Produkt-Releases und mehr"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Weiterführende Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/listing-your-app",
          children: "So listen Sie Ihre App"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/certification-requirements",
          children: "Voraussetzungen für die App-Zertifizierung"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "API-Referenzdokumentation"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/overview",
          children: "So verwenden Sie die HubSpot-APIs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
          children: "Entwickler-Community-Forum"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/contact-our-partnerships-team",
          children: "App-Partnerteam kontaktieren"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}