"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921797;
const slug = exports.slug = 'guides/api/crm/extensions/timeline';
const title = exports.title = 'API de CRM | Eventos de cronología';
const name = exports.name = 'CRM | Eventos de cronología';
const metaDescription = exports.metaDescription = 'Resumen y recorrido de la API de línea de tiempo.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "creaci%C3%B3n-de-una-plantilla-de-eventos",
  "label": "Creación de una plantilla de eventos",
  "parent": null
}, {
  "depth": 1,
  "id": "creaci%C3%B3n-de-plantillas-de-eventos-a-trav%C3%A9s-de-la-api",
  "label": "Creación de plantillas de eventos a través de la API",
  "parent": "creaci%C3%B3n-de-una-plantilla-de-eventos"
}, {
  "depth": 1,
  "id": "creaci%C3%B3n-de-plantillas-de-eventos-en-hubspot",
  "label": "Creación de plantillas de eventos en HubSpot",
  "parent": "creaci%C3%B3n-de-una-plantilla-de-eventos"
}, {
  "depth": 1,
  "id": "definir-tokens-de-eventos",
  "label": "Definir tokens de eventos",
  "parent": "creaci%C3%B3n-de-una-plantilla-de-eventos"
}, {
  "depth": 2,
  "id": "creaci%C3%B3n-de-tokens-de-eventos-a-trav%C3%A9s-de-la-api",
  "label": "Creación de tokens de eventos a través de la API",
  "parent": "creaci%C3%B3n-de-una-plantilla-de-eventos"
}, {
  "depth": 1,
  "id": "definici%C3%B3n-de-las-plantillas-de-encabezado-y-de-detalles",
  "label": "Definición de las plantillas de encabezado y de detalles",
  "parent": "creaci%C3%B3n-de-una-plantilla-de-eventos"
}, {
  "depth": 2,
  "id": "definici%C3%B3n-de-las-plantillas-de-encabezado-y-de-detalles-a-trav%C3%A9s-de-la-api",
  "label": "Definición de las plantillas de encabezado y de detalles a través de la API",
  "parent": "creaci%C3%B3n-de-una-plantilla-de-eventos"
}, {
  "depth": 1,
  "id": "definici%C3%B3n-de-todos-los-aspectos-de-una-plantilla-de-eventos-en-una-%C3%BAnica-llamada",
  "label": "Definición de todos los aspectos de una plantilla de eventos en una única llamada",
  "parent": "creaci%C3%B3n-de-una-plantilla-de-eventos"
}, {
  "depth": 0,
  "id": "crear-un-evento",
  "label": "Crear un evento",
  "parent": null
}, {
  "depth": 1,
  "id": "establecer-la-marca-de-tiempo-del-evento",
  "label": "Establecer la marca de tiempo del evento",
  "parent": "crear-un-evento"
}, {
  "depth": 1,
  "id": "asociaci%C3%B3n-de-un-evento-con-un-objeto-de-crm",
  "label": "Asociación de un evento con un objeto de CRM",
  "parent": "crear-un-evento"
}, {
  "depth": 1,
  "id": "extensiones-de-l%C3%ADnea-de-tiempo",
  "label": "Extensiones de línea de tiempo",
  "parent": "crear-un-evento"
}, {
  "depth": 1,
  "id": "marcaci%C3%B3n-de-los-datos-de-eventos-en-las-propiedades-de-objetos-de-crm",
  "label": "Marcación de los datos de eventos en las propiedades de objetos de CRM",
  "parent": "crear-un-evento"
}, {
  "depth": 1,
  "id": "entender-",
  "label": "Entender ",
  "parent": "crear-un-evento"
}, {
  "depth": 1,
  "id": "configurar-un-icono-personalizado",
  "label": "Configurar un icono personalizado",
  "parent": "crear-un-evento"
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "crear-un-evento"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      h3: "h3",
      pre: "pre",
      em: "em",
      strong: "strong",
      h4: "h4",
      ul: "ul",
      li: "li",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Eventos en la línea de tiempo"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las extensiones de CRM permiten que la información de otros sistemas aparezca en los objetos de contacto, empresa o negocio de HubSpot. Los puntos de terminación de los eventos de línea de tiempo te permiten hacer esto creando eventos de línea de tiempo personalizados. Si quieres que tus datos sean editables por los usuarios, pero ninguno de los objetos CRM predeterminados satisface tus necesidades, puedes considerar el uso de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_api/event_expanded-2.png",
        alt: "event_expanded-2"
      }), "Por ejemplo, quieres segmentar mejor tus contactos en función de sus interacciones con tu empresa y su contenido. Para ello, necesitas más información sobre ellos. Tu aplicación puede crear eventos personalizados (contactos que se registraron, pero no asistieron a un webinario reciente, qué variante de un flujo de registro completó un contacto, etc.) que te proporcionen más contexto sobre las interacciones que los contactos tengan con tu empresa."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Creación de una plantilla de eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de comenzar a crear eventos, debes crear una plantilla de evento. Las plantillas de eventos describen las acciones que tu aplicación agregará a la línea de tiempo de un objeto de contacto, empresa o negocio en HubSpot. Ejemplos de estas acciones incluyen ver un video, registrarse para un webinario o completar una encuesta. Una sola aplicación puede crear hasta 750 plantillas de eventos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas de eventos se crean para contactos de forma predeterminada, pero pueden crearse para empresas o negocios usando el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), ". Ver la creación de una plantilla de evento de la línea de tiempo para más detalles."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada plantilla de eventos tiene su propio conjunto de tokens y plantillas. Puedes usar eventos creados para contactos como criterio al crear listas o workflows para contactos nuevos, tales como 'Crear una lista de todos los contactos con un Video similar cuyo nombre contenga las letras XYZ’, en el que la plantilla de video se denomina «Video similar» y tiene un token de evento denominado «nombre de video».\""
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Creación de plantillas de eventos a través de la API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para este ejemplo, crearemos una nueva plantilla de evento «Ejemplo de Registro en Webinario». Para autenticación, usa la clave de API de desarrolladores que se encuentra en tu cuenta de desarrollador de aplicaciones."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST\n-H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Example Webinar Registration\",\n  \"objectType\": \"contacts\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>''\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Asegúrate de reemplazar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<appId>>"
      }), " con tu propio ID de aplicación, que se puede encontrar tanto en las páginas de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Mis aplicaciones"
      }), " como en las de detalles de la aplicación en tu cuenta de desarrollador. También tendrás que reemplazar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<developerHapikey>>"
      }), " con tu propia clave de API de desarrollador, que puedes encontrar navegando a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Aplicaciones"
      }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Obtener clave de API de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las propiedades ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "headerTemplate"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "detailTemplate"
      }), " también se pueden proporcionar aquí. (Ver «Definición de plantillas» a continuación)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " devolverá la definición completa de la plantilla de eventos guardada. Asegúrese de tener en cuenta la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " en esta respuesta. Este es el ID de la plantilla de eventos, que necesitarás para hacer cualquier actualización de esta plantilla o tokens de evento en el futuro."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes ver todas las plantillas de eventos definidas para una aplicación mediante este comando GET, que también devolverá los ID de las plantillas de eventos:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Creación de plantillas de eventos en HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además de usar la API para crear y administrar plantillas de eventos de línea de tiempo, también puedes administrar plantillas de eventos en tu cuenta de desarrollador de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En la configuración de tu aplicación, navega a Eventos de la línea de tiempo y usa el botón “Crear tipo de evento” para crear una nueva plantilla de evento para esta aplicación. Si has creado plantillas de eventos antes, también las verás aquí."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/image%20(3)-Jul-23-2020-10-02-24-50-PM.png",
        alt: "image (3)-Jul-23-2020-10-02-24-50-PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Comenzarás con un borrador de tu nueva plantilla de eventos. Una vez que hayas establecido el tipo de objeto y las plantillas de detalles y encabezado para el evento, haz clic en «Crear».\""
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/image%20(4)-Jul-23-2020-10-02-24-66-PM.png",
        alt: "image (4)-Jul-23-2020-10-02-24-66-PM"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando creas o editas tu plantilla de eventos, establece los tokens que deseas usar con la plantilla en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Datos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/data-tab-1.png",
        alt: "data-tab-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**Nota: **Si eliminas una plantilla, una vez eliminada, los eventos existentes que usen esa plantilla se eliminarán permanentemente de las cuentas con tu aplicación conectada. Ya no podrás crear nuevos eventos de este tipo, pero aún verás los datos de eventos ya existentes en listas e informes. Puede tardar varias horas para ver estos cambios reflejados en HubSpot."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir tokens de eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que hayas definido una plantilla de evento, probablemente desees definir sus tokens. Los tokens de plantillas de eventos te permiten adjuntar datos personalizados a eventos que se pueden mostrar en la línea de tiempo o usar para segmentación y automatización de listas. Puedes crear hasta 500 tokens por plantilla de evento de línea de tiempo."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Nota: **los eventos de empresa y negocio ", (0, _jsxRuntime.jsx)("u", {
          children: "no se pueden"
        }), " utilizar en la segmentación o automatización de listas."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Creación de tokens de eventos a través de la API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando el ID de plantilla de evento creado en el paso 1, agregaremos algunos tokens para identificar el webinario para el que se registraron nuestros contactos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarName\",\n  \"label\": \"Webinar Name\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarId\",\n  \"label\": \"Webinar Id\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarType\",\n  \"label\": \"Webinar Type\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"value\": \"regular\",\n      \"label\": \"Regular\"\n    },\n    {\n      \"value\": \"ama\",\n      \"label\": \"Ask me anything\"\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Del mismo modo, un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " devolverá todos los tokens definidos en una plantilla de evento:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET -H \"Content-Type: application/json\" 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los tipos de token admitidos incluyen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), " — Una de un conjunto de opciones. Consulta el ejemplo anterior webinarType."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "date"
        }), " — Todas las fechas deben estar en milisegundos en tiempo Unix."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": los tokens de eventos no se pueden denominar log o lookup. Estos tokens están reservados como apoyo por Handlbars.js, la biblioteca utilizada para eventos dentro de la aplicación. Para obtener más información, echa un vistazo a los documentos de Handlebars.js ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://handlebarsjs.com/builtin_helpers.html",
          children: "aquí."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definición de las plantillas de encabezado y de detalles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas de encabezado y de detalles definen cómo mostrar un evento de línea de tiempo. Puedes especificar documentos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://daringfireball.net/projects/markdown/syntax",
        children: "Markdown"
      }), " con plantillas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://handlebarsjs.com/",
        children: "Handlebars"
      }), ". La plantilla de encabezado debe ser una descripción de una línea del evento; y la plantilla de detalles es la vista detallada del evento (ejemplos a continuación)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los tokens de eventos se pasan como datos a las plantillas. Usando nuestro ejemplo, puedes hacer referencia al token ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " en la plantilla usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{webinarName}}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " de un evento (que se describe más adelante en \"Comprensión extraData\"\") solo se puede referenciar en la plantilla de detalles."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Definición de las plantillas de encabezado y de detalles a través de la API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas de encabezado y de detalles pueden definirse en las plantillas de eventos a través de los puntos de terminación de plantillas de eventos. Por ejemplo, podemos agregar plantillas a nuestro 'Ejemplo de registro en un webinario' modificándolo con un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"id\": \"<<eventTemplateId>>\",\n  \"name\": \"Example Name Change\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ten en cuenta el uso de la directiva", (0, _jsxRuntime.jsx)(_components.code, {
        children: "#formatDate"
      }), ": es algo que hemos definido para permitir un formato de fecha fácil de usar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que se crea un evento para un contacto usando esto (ver \"", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#creating-an-event",
        children: "Creación de un evento"
      }), "\" abajo), aquí se mostrará lo que aparecerá en la línea de tiempo de ese contacto:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al hacer clic en «Mostrar detalles», muestra la plantilla de detalles:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_expanded.png?width=640&name=event_expanded.png",
        alt: "event_expanded.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para establecer el icono que se muestra junto a los eventos, consulta \"", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline#customicon",
        children: "Configuración de un icono personalizado\""
      }), " abajo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El texto «Ejemplo de nombre de aplicación» anterior es el nombre de la aplicación. En la línea de tiempo del CRM, los eventos se pueden filtrar por la aplicación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definición de todos los aspectos de una plantilla de eventos en una única llamada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora que has visto cómo se define progresivamente cada aspecto de una plantilla de eventos, puedes definirlo todo al mismo tiempo en una sola llamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Another Webinar Registration\",\n  \"objectType\": \"contacts\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\",\n  \"tokens\": [\n    {\n      \"name\": \"webinarName\",\n      \"label\": \"Webinar Name\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarId\",\n      \"label\": \"Webinar Id\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarType\",\n      \"label\": \"Webinar Type\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"value\": \"regular\",\n          \"label\": \"Regular\"\n        },\n        {\n          \"value\": \"ama\",\n          \"label\": \"Ask me anything\"\n        }\n      ]\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear un evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora que una plantilla de evento está configurada con tokens y plantillas, estamos listos para crear eventos para los contactos, empresas, negocios y tickets de nuestros clientes. En los ejemplos a continuación se supone que estamos trabajando con la plantilla de eventos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contacts"
      }), " creados anteriormente. Si la plantilla de evento anterior no está configurada para tener los tokens ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarId"
      }), ", recibirás un error al intentar crear el evento. Se muestra un ejemplo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para crear un evento:"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": las claves API de desarrollador y los tokens de acceso a aplicaciones privadas ", (0, _jsxRuntime.jsx)("u", {
          children: "no se pueden"
        }), " utilizar como autenticación al crear eventos. Para crear un evento, la cuenta de HubSpot asociada debe otorgar acceso a tu aplicación a través de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth"
        }), ". Una vez que obtengas un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "token de acceso de OAuth"
        }), ", puedes usarlo para agregar eventos a la cuenta."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esto genera un evento en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a.test.contact@email.com"
      }), "'la cronología (suponiendo que se usan las plantillas en 'Definición de plantillas' anteriores):"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Establecer la marca de tiempo del evento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La marca de tiempo del evento determina dónde aparecerá el evento en la línea de tiempo del objeto. Por opción predeterminada, la marca de tiempo del evento es cuando se envía el comando POST. Puedes personalizar la hora del evento proporcionando la solicitud en el cuerpo de la solicitud de una propiedad de marca de tiempo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"timestamp\": \"2020-03-18T15:30:32Z\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este método es preferido si se conoce la hora exacta a la que ocurrió una acción. En este ejemplo, si tenemos la marca de tiempo para este registro de webinario, deberíamos proporcionarla en este POST."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las marcas de tiempo pueden estar en milisegundos de hora Epoch o en formato ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/ISO_8601",
        children: "ISO 8601"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociación de un evento con un objeto de CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear un evento, debes poder asociar el evento con un contacto, empresa o negocio en la cuenta del cliente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En los ejemplos anteriores, el objectType se estableción en contacto y usamos correo electrónico para asociar el evento con un contacto. Las direcciones de correo electrónico deben ser únicas para los contactos en HubSpot, por lo que si hay un contacto existente con el correo electrónico proporcionado, ese contacto se actualizará. Si no hay un contacto existente, se creará uno nuevo. Por opción predeterminada, este nuevo contacto solo tendrá la propiedad de contacto de correo electrónico proporcionada. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#stamp-event-data-onto-crm-object-properties",
        children: "marcación de los datos del evento en las propiedades del contacto"
      }), " para agregar datos adicionales a las propiedades de contactos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás trabajando con contactos conocidos, también puedes utilizar el contacto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), " para asociar el evento. En esos casos, usarías ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " en la solicitud JSON. Debes incluir el vid de un contacto existente, ya que no podrás crear nuevos contactos usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". En este ejemplo, se utiliza el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " en lugar de correo electrónico:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"29851\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes asociar un evento con un contacto por usertoken o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ". El usertoken es usado por el código de seguimiento de HubSpot para hacer seguimiento a los visitantes y se almacena en la cookie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspotutk"
      }), ". Usa el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " para asociar un evento con un contacto por usertoken. Nota: no es posible asociar eventos con visitantes anónimos usando usertoken, por lo que si el evento está asociado con solo el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " y el usertoken proporcionado no está asociado ya con un contacto, no se creará un nuevo contacto y el evento no se sería visible en HubSpot. Sin embargo, el evento aparecerá en la línea de tiempo si un nuevo contacto se asoció con el usertoken a través de otro medio (generalmente a través de un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "envío de formulario incluido el hutk"
      }), " o a través del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code",
        children: "método de identificación de la API del código de seguimiento"
      }), "). Por esta razón, recomendamos incluir el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " además de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " para asegurarnos de que el evento se asocia con un contacto nuevo o existente."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás trabajando con una plantilla de evento para contactos, es posible incluir varios parámetros de identificación con el evento, por lo que cualquier combinación de los parámetros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " puede incluirse. Si se incluyen varios parámetros, el objectId (vid) tendrá la mayor prioridad al determinar qué contacto asociar con el evento, seguido de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ", siendo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " la prioridad más baja. Esto significa que puedes actualizar la dirección de correo electrónico de un objeto existente incluyendo una nueva dirección de correo electrónico en el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " con la ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), " de un objeto conocido en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". Este ejemplo usa la dirección de correo electrónico y el usertoken:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"utk\": \"89b5afb740d41f4cd6651ac5237edf09\"\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de trabajar con contactos, también es posible crear plantillas de eventos para empresas y negocios. Para esas plantillas de eventos, debes utilizar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " para asociar el evento con la empresa o negocio. Para empresas, la ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " debe establecerse en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " de la empresa con la que deseas asociar el evento, y para negocios debes establecer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " en el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealId"
      }), " del objeto del negocio."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el siguiente ejemplo, suponiendo que la plantilla de evento se configuró en el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPANY"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), ", este evento se asociaría con el objeto de la empresa con ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " 528253914:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"3001\",\n  \"tokens\": {\n    \"dealProperty\": \"Custom property for deal\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Extensiones de línea de tiempo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La característica de extensiones de cronología se puede usar para mostrar datos de un sistema externo usando un iFrame. Cuando se incluyan, el evento mostrará un enlace que abrirá una ventana modal mostrando el contenido de iFrame cuando se hace clic. Los detalles de iFrame se establecen en el campo timelineIFrame, que es un objeto que contiene los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "linkLabel"
        }), " - El texto utilizado para mostrar el enlace que mostrará el IFrame."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "headerLabel"
        }), " - La etiqueta de la ventana modal que muestra el contenido de IFrame."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), " - La URI del contenido de IFrame."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "width"
        }), " - El ancho de la ventana modal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "height"
        }), " - La altura de la ventana modal."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, usar estos datos para un evento:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"timelineIFrame\": {\n    \"linkLabel\":\"View external data\",\n    \"headerLabel\":\"Example iframe\",\n    \"url\":\"https://www.example.com\",\n    \"width\":800,\n    \"height\":300\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Crearía este evento, incluyendo el enlace «Ver datos externos»:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/external_data_link.png?width=640&name=external_data_link.png",
        alt: "external_data_link.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al hacer clic en ese enlace, se abrirá una ventana modal que muestra la página establecida en la ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "url"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/iframe_modal.png?width=640&name=iframe_modal.png",
        alt: "iframe_modal.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Marcación de los datos de eventos en las propiedades de objetos de CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En muchos casos, deberás modificar las propiedades para los contactos, empresas o negocios a los que estás agregando eventos. Esto suele suceder en casos en los que se creará un contacto al agregar el evento, probablemente deseas actualizar las propiedades de nombre y apellido en el contacto para que no solo crees un contacto con una dirección de correo electrónico y un evento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes marcar datos al objeto asociado desde un evento asignando tus tokens de evento personalizados a las propiedades de contactos, empresas o negocios."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Considera este comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para actualizar una plantilla de evento personalizada, ten en cuenta el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"label\" : \"Updated Webinar Name\",\n  \"objectPropertyName\": \"zz_webinar_name\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens/<<tokenName>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esto utiliza ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), " para asignar este token de evento personalizado a la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), " del objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), ". Esto significa que cuando creamos un nuevo evento que especifique un token ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), ", también se establecerá la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " del ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), " asociado. Puedes establecer estas propiedades de HubSpot en valores personalizados o predefinidos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, supongamos que ya creamos un token ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyName"
      }), " hacer referencia a una propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), " personalizada en el contacto. La creación de un evento como este hace que se establezcan las propiedades ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " en el contacto con la dirección de correo electrónico ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "mailto:a.test.contact@email.com",
        children: "a.test.contact@email.com"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/set_property.png?width=1024&name=set_property.png",
        alt: "set_property.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nota: si un token de evento se marca en una propiedad personalizada y esa propiedad personalizada no está presente para una cuenta de HubSpot, el valor seguirá establecido para el evento, pero será ignorado para el objeto correspondiente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h3, {
      children: ["Entender ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es posible que debas agregar datos detallados a un evento que no se ajusta a la sencilla estructura token-valor utilizada por los tokens de las plantilla de eventos. Es posible que debas agregar una lista o algunos desgloses jerárquicos a un evento de integración. Aquí es donde entra ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " en juego."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes agregar un atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " al cuerpo JSON de un evento. El valor de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " puede ser cualquier JSON válido. Por ejemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"extraData\": {\n    \"pollData\": [\n      {\n        \"question\": \"How excited are you for this webinar?\",\n        \"answer\":\"Quite!\"\n      },\n      {\n        \"question\": \"How frequently do you use our product?\",\n        \"answer\":\"Daily\"\n      }\n    ],\n    \"coWorkers\": [\n      {\n        \"name\": \"Joe Coworker\",\n        \"email\":\"joe.coworker@testco.com\"\n      },\n      {\n        \"name\": \"Jane Coworker\",\n        \"email\":\"jane.coworker@testco.com\"\n      }\n    ]\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un ejemplo de uso de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " en una plantilla de detalles:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "//\nRegistration occurred at {{#formatDate timestamp}}{{/formatDate}}\n\n#### Poll Questions\n{{#each extraData.pollData}}\n  **{{question}}**: {{answer}}\n{{/each}}\n\n#### Co-Workers\n{{#each extraData.coWorkers}}\n  * {{name}}\n{{/each}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esto creará un evento de línea de tiempo que luce como esto:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/extra_data.png?width=640&name=extra_data.png",
        alt: "extra_data.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nota: solo se puede hacer referencia al atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " en la plantilla de detalles de un evento. No se puede usar ni en la plantilla de encabezado ni en la segmentación de listas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurar un icono personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para agregar un atractivo visual a tus elementos de pedido de tiempo, deberás agregar un icono personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este archivo de imagen para este icono debe:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tener dimensiones aproximadamente cuadradas"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tener un fondo transparente"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tener el contenido en el centro del icono"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Poder reducir el tamaño de hasta 30 x 30 píxeles"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tener un tamaño de archivo de 5 MB o menos"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para establecer el icono utilizado para eventos de línea de tiempo, navega a Eventos de línea de tiempo. Haz clic en la imagen del marcador de posición o el icono existente para configurarlo o actualizarlo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_assets.png",
        alt: "timeline_assets"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que establezcas el(los) ícono(s), se mostrarán junto a todos los eventos de la línea de tiempo asociados con esta aplicación:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/timeline_icon.png?width=640&name=timeline_icon.png",
        alt: "timeline_icon.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Comprender el CRM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "Tarjetas CRM"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}