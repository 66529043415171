export const CARD_BORDER_RADIUS = 24;
/**
 * Standard box shadow applied to cards
 */
export const CARD_BOX_SHADOW = '0px 2px 4px 0px #00000040';
/**
 * For Cards that are used inside another card
 */
export const INNER_CARD_BORDER_RADIUS = 12;
export const INNER_CARD_PADDING = 24;
/**
 * For the spacing between elements within a section with a header
 */
export const NEXT_COMPONENT_SPACING = 32;

// Greys
export const ECLIPSE = '#213343';
export const METEOR = '#3E5974';
export const COMET = '#7691AD';

// Neutrals / Beige
export const CHAPATI = '#FCEAD7';
export const OATMILK = '#FEF4EA';