"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694148;
const slug = exports.slug = 'guides/cms/content/website-settings';
const title = exports.title = 'Website-Einstellungen';
const name = exports.name = '[neu] Website-Einstellungen';
const metaDescription = exports.metaDescription = 'Website-Einstellungen sind ein einziger Ort, an dem verschiedene globale und systembasierte Inhaltseinstellungen für den Blog, die Navigation, die Seiten und die Designs Ihrer Website konfiguriert werden können.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg';
const featuredImageAltText = exports.featuredImageAltText = 'Bereich für Website-Einstellungen';
const toc = exports.toc = [{
  "depth": 0,
  "id": "blog-einstellungen",
  "label": "Blog-Einstellungen",
  "parent": null
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Eallgemein%E2%80%9C",
  "label": "Registerkarte „Allgemein“",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "blog-name",
  "label": "Blog-Name",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "blog-header",
  "label": "Blog-Header",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "seitentitel",
  "label": "Seitentitel",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "metabeschreibung",
  "label": "Metabeschreibung",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "blog-haupt-url",
  "label": "Blog-Haupt-URL",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "zielgruppenzugriff-steuern",
  "label": "Zielgruppenzugriff steuern",
  "parent": "blog-einstellungen"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Evorlagen%E2%80%9C",
  "label": "Registerkarte „Vorlagen“",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "aktuelle-vorlage",
  "label": "Aktuelle Vorlage",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "vorlage-f%C3%BCr-listing-seiten-optional",
  "label": "Vorlage für Listing-Seiten (optional)",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "anzahl-der-beitr%C3%A4ge-pro-listing-seite",
  "label": "Anzahl der Beiträge pro Listing-Seite",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "header-html",
  "label": "Header-HTML",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "footer-html",
  "label": "Footer-HTML",
  "parent": "blog-einstellungen"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Eabonnements%E2%80%9C",
  "label": "Registerkarte „Abonnements“",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "benachrichtigungs-e-mails-f%C3%BCr-blog-abonnenten",
  "label": "Benachrichtigungs-E-Mails für Blog-Abonnenten",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "rss-feed",
  "label": "RSS-Feed",
  "parent": "blog-einstellungen"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Edatumsformate%E2%80%9C",
  "label": "Registerkarte „Datumsformate“",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "sprache-f%C3%BCr-datumsangaben",
  "label": "Sprache für Datumsangaben",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "format-des-ver%C3%B6ffentlichungsdatums",
  "label": "Format des Veröffentlichungsdatums",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "format-f%C3%BCr-beitr%C3%A4ge-nach-monat",
  "label": "Format für Beiträge nach Monat",
  "parent": "blog-einstellungen"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Ekommentare%E2%80%9C",
  "label": "Registerkarte „Kommentare“",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "blog-kommentare-aktivieren-oder-deaktivieren",
  "label": "Blog-Kommentare aktivieren oder deaktivieren",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "benachrichtigungen-zu-blog-kommentaren",
  "label": "Benachrichtigungen zu Blog-Kommentaren",
  "parent": "blog-einstellungen"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Esocial-media-sharing%E2%80%9C",
  "label": "Registerkarte „Social-Media-Sharing“",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "standard-x-konto",
  "label": "Standard-X-Konto",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "social-media-sharing-buttons",
  "label": "Social-Media-Sharing-Buttons",
  "parent": "blog-einstellungen"
}, {
  "depth": 1,
  "id": "registerkarte%C2%A0%E2%80%9Egoogle-amp%E2%80%9C",
  "label": "Registerkarte „Google AMP“",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "google-amp-aktivieren-oder-deaktivieren",
  "label": "Google AMP aktivieren oder deaktivieren",
  "parent": "blog-einstellungen"
}, {
  "depth": 2,
  "id": "amp-spezifische-einstellungen-f%C3%BCr-ein-logo%2C-header-formatierung",
  "label": "AMP-spezifische Einstellungen für ein Logo, Header-Formatierung",
  "parent": "blog-einstellungen"
}, {
  "depth": 0,
  "id": "navigationseinstellungen",
  "label": "Navigationseinstellungen",
  "parent": null
}, {
  "depth": 0,
  "id": "seiteneinstellungen",
  "label": "Seiteneinstellungen",
  "parent": null
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Evorlagen%E2%80%9C",
  "label": "Registerkarte „Vorlagen“",
  "parent": "seiteneinstellungen"
}, {
  "depth": 2,
  "id": "website-header-html",
  "label": "Website-Header-HTML",
  "parent": "seiteneinstellungen"
}, {
  "depth": 2,
  "id": "website-footer-html",
  "label": "Website-Footer-HTML",
  "parent": "seiteneinstellungen"
}, {
  "depth": 2,
  "id": "jquery",
  "label": "jQuery",
  "parent": "seiteneinstellungen"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Ebranding%E2%80%9C",
  "label": "Registerkarte „Branding“",
  "parent": "seiteneinstellungen"
}, {
  "depth": 2,
  "id": "favicon",
  "label": "Favicon",
  "parent": "seiteneinstellungen"
}, {
  "depth": 2,
  "id": "logo-alt-text%2C-ma%C3%9Fe%2C-link",
  "label": "Logo (ALT-Text, Maße, Link)",
  "parent": "seiteneinstellungen"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Epersonalisierung%E2%80%9C",
  "label": "Registerkarte „Personalisierung“",
  "parent": "seiteneinstellungen"
}, {
  "depth": 2,
  "id": "standardeinstellungen-f%C3%BCr-kontakte-und-unternehmen",
  "label": "Standardeinstellungen für Kontakte und Unternehmen",
  "parent": "seiteneinstellungen"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Eintegrationen%E2%80%9C",
  "label": "Registerkarte „Integrationen“",
  "parent": "seiteneinstellungen"
}, {
  "depth": 2,
  "id": "js-integrationen",
  "label": "JS-Integrationen",
  "parent": "seiteneinstellungen"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Eseo-%26-crawler%E2%80%9C",
  "label": "Registerkarte „SEO & Crawler“",
  "parent": "seiteneinstellungen"
}, {
  "depth": 2,
  "id": "kanonische-urls",
  "label": "Kanonische URLs",
  "parent": "seiteneinstellungen"
}, {
  "depth": 2,
  "id": "hosting-domain-f%C3%BCr-den-standard-datei-manager",
  "label": "Hosting-Domain für den Standard-Datei-Manager",
  "parent": "seiteneinstellungen"
}, {
  "depth": 2,
  "id": "robots.txt",
  "label": "Robots.txt",
  "parent": "seiteneinstellungen"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Esystemseiten%E2%80%9C",
  "label": "Registerkarte „Systemseiten“",
  "parent": "seiteneinstellungen"
}, {
  "depth": 0,
  "id": "404--und-500-fehlerseiten",
  "label": "404- und 500-Fehlerseiten",
  "parent": null
}, {
  "depth": 0,
  "id": "seite-mit-aufforderung-zur-passworteingabe",
  "label": "Seite mit Aufforderung zur Passworteingabe",
  "parent": null
}, {
  "depth": 2,
  "id": "url-der-suchergebnisseite",
  "label": "URL der Suchergebnisseite",
  "parent": "seite-mit-aufforderung-zur-passworteingabe"
}, {
  "depth": 0,
  "id": "designeinstellungen",
  "label": "Designeinstellungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      h4: "h4",
      code: "code",
      em: "em",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Website-Einstellungen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Website-Einstellungen sind ein einziger Ort, an dem verschiedene globale und systembasierte Inhaltseinstellungen für den Blog, die Navigation, die Seiten und die Designs Ihrer Website konfiguriert werden können. Navigieren Sie zu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/blogs/",
        children: "Einstellungen > Website"
      }), " und wählen Sie aus, für welchen Inhaltsbereich Sie auf Ihre Inhaltseinstellungen zugreifen möchten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog-Einstellungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Bereich steuern Sie die Inhaltseinstellungen für Ihre Websites-Blogs. Wenn Sie mehrere Blogs haben, können Sie über das Dropdown-Menü unter der Überschrift „Blog zum Ändern auswählen“ zwischen ihnen wechseln."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg",
        alt: "Bildschirm mit den Blog-Einstellungen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Allgemein“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Blog-Name"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die HubL-Variablen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ content.blog }}"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.public_title }}"
      }), " rendern den hier festgelegten Namen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Blog-Header"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die HubL-Variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.header }}"
      }), " rendert den hier festgelegten Header."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Seitentitel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die HubL-Variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.html_title }}"
      }), " rendert den hier festgelegten Titel."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Metabeschreibung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die HubL-Variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.description }}"
      }), " rendert die hier festgelegte Beschreibung. Diese Metabeschreibung wird auf Blog-Listing-Seiten verwendet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Blog-Haupt-URL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Blog-Haupt-URL steht vor einzelnen Blogbeitrags-Slugs. Die HubL-Variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.absolute_url }}"
      }), " rendert die hier festgelegte URL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Zielgruppenzugriff steuern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Über diese Einstellung können Sie den Zielgruppenzugriff auf einen gesamten Blog steuern. Mehr dazu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages",
        children: "hier"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Vorlagen“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Aktuelle Vorlage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies ist die Vorlage, die für alle Blogbeiträge in einem bestimmten Blog verwendet wird. Die gleiche Vorlage wird standardmäßig auch für Blog-Listing-Seiten verwendet. Innerhalb des Beitragsinhaltsmoduls können unterschiedliche Inhalte für Listing-Seiten im Vergleich zu Beiträgen geschrieben werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Vorlage für Listing-Seiten (optional)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Einstellung aktiviert eine andere Vorlage für Blog-Listing-Seiten als die für Blogbeiträge verwendete Vorlage."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Anzahl der Beiträge pro Listing-Seite"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies bestimmt die Anzahl von Beitragselementen, die standardmäßig auf einer Blog-Listing-Seite angezeigt werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Header-HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sämtlicher dem Header-HTML für Blog-Listing-Seiten hinzugefügte Code wird über die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "-Variable zu allen Listing-Seiten hinzugefügt. Sämtlicher dem Header-HTML für Blogbeiträge hinzugefügte Code wird über die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "-Variable zu allen Blogbeiträgen hinzugefügt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Footer-HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sämtlicher dem Footer-HTML für Blog-Listing-Seiten hinzugefügte Code wird über die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), "-Variable zu allen Listing-Seiten hinzugefügt. Sämtlicher dem Footer-HTML für Blogbeiträge hinzugefügte Code wird über die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), "-Variable zu allen Blogbeiträgen hinzugefügt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Abonnements“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Benachrichtigungs-E-Mails für Blog-Abonnenten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sofortige, tägliche, wöchentliche und monatliche Blog-Benachrichtigungs-E-Mails können über diese Einstellung aktiviert und bearbeitet werden. Diese E-Mails werden automatisch versendet, wenn neue Blogbeiträge im angegebenen Zeitraum veröffentlicht werden. Lesen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-blog/set-up-your-blog-subscription-features-in-hubspot",
        children: "hier"
      }), " mehr."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "RSS-Feed"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Über diese Einstellung kann die Anzahl der Beitragselemente im Blog-RSS-Feed bestimmt werden. Es sind maximal 50 Beiträge möglich."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Datumsformate“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Sprache für Datumsangaben"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Einstellung bestimmt die Sprache der Monate und Tage, die in Datumsangaben von Blogs angezeigt werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Format des Veröffentlichungsdatums"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das hier festgelegte Format bestimmt die Reihenfolge und das Muster von Veröffentlichungsdaten in Blogs. Mithilfe von Local Data Markup Language ist es möglich, ein benutzerdefiniertes Datumsformat festzulegen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Format für Beiträge nach Monat"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das hier festgelegte Format bestimmt die Reihenfolge und das Muster von Beiträgen nach Monat. Mithilfe von Local Data Markup Language ist es möglich, ein benutzerdefiniertes Datumsformat festzulegen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Kommentare“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Blog-Kommentare aktivieren oder deaktivieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Über diese Einstellung können Blog-Kommentare aktiviert oder deaktiviert werden. Kommentare können eine Moderation erfordern oder einen festgelegten Zeitrahmen haben, nach dem Kommentare automatisch geschlossen werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Benachrichtigungen zu Blog-Kommentaren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Blog-Kommentare können über diese Einstellung E-Mail-Benachrichtigungen an bestimmte Benutzer auslösen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Social-Media-Sharing“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Standard-X-Konto"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das hier angegebene Twitter-Konto wird für Twitter-Karten verwendet, wenn Inhalte auf Twitter geteilt werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Social-Media-Sharing-Buttons"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Social-Media-Sharing-Buttons für Twitter, LinkedIn und Facebook können über diese Einstellung automatisch zu Blogbeiträgen hinzugefügt werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Google AMP“"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei Accelerated Mobile Pages (AMP) werden Ihre Inhalte sofort geladen. Lesen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "hier"
      }), " mehr. Um Inhalte so schnell zu laden, ist ein vereinfachtes Seitenerlebnis erforderlich. AMP-Inhalte haben aus diesem Grund eine begrenzte Steuerung von Stilen"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Google AMP aktivieren oder deaktivieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Google AMP-formatierte Seiten können über diese Einstellung aktiviert werden. Dies umfasst Einstellungen für AMP-Logo, Schriftart und Farbe."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "AMP-spezifische Einstellungen für ein Logo, Header-Formatierung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um AMP-Inhalte bereitzustellen, sind vereinfachte Stile erforderlich. Legen Sie über diese Einstellungen AMP-spezifische Stile für das Logo, die Header-Formatierung, die Schriftarten und die Farben fest."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Navigationseinstellungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Ihre Menü-Links und Labels in diesem Bereich verwalten. Sie können zwischen den Menüs, die Sie konfigurieren möchten, wechseln, indem Sie das Dropdown-Menü und das gewünschte Menü auswählen. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/set-up-your-site-s-navigation-menus",
        children: "Erfahren Sie hier mehr über das Einrichten von Navigationsmenüs für Ihre Websites."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/navigation-settings-screen-4.jpg",
        alt: "Bildschirm für Navigationseinstellungen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Seiteneinstellungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Einstellungen werden nach Domains aufgeschlüsselt und es können Standardwerte für alle Domains festgelegt werden. Die \"", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Default für alle domains"
      }), "”-Einstellungen wird angezeigt, wenn Sie zu den Seiteneinstellungen navigieren. Es gibt einen Schalter am oberen Bildschirmrand, um die Einstellungen für bestimmte Subdomains anzuzeigen und zu ändern. Einstellungen, die auf bestimmte Subdomains angewendet werden, überschreiben die Standardeinstellungen für alle Domains."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nur Benutzer mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "Marketingberechtigung „Website-Einstellungen bearbeiten“"
      }), " können auf die Content-Einstellungen zugreifen und diese bearbeiten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/page-settings-screen-4.jpg",
        alt: "Bildschirm für Seiteneinstellungen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Vorlagen“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Website-Header-HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sämtlicher in das Website-Header-HTML-Feld in den Seiteneinstellungen hinzugefügte Code wird in die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "-Variable aufgenommen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Website-Footer-HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sämtlicher in das Website-Footer-HTML-Feld in den Seiteneinstellungen hinzugefügte Code wird in die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), "-Variable aufgenommen. In der Regel ist dies ein guter Ort für das Hinzufügen von Tracking-Codes und anderen Skripten, die für die Funktionsweise und ein ansprechendes Erscheinungsbild Ihrer Website „nicht wesentlich“ sind. Auf diese Weise wirkt sich dies nicht negativ auf Ihre Vorlagen oder Seiten aus."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können das Laden der jQuery-Version auf Ihrer Seite über Seiteneinstellungen ändern."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können über diese Einstellung auch festlegen, dass jQuery von Ihrem Footer geladen wird. Durch Anhängen von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter=True"
      }), " an Ihre Seiten-URL können Sie diese Änderung testen und so sicherstellen, dass sie das Erscheinungsbild Ihrer Website nicht negativ beeinflusst."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Option zum Deaktivieren von jQuery befindet sich ebenfalls in den Seiteneinstellungen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Branding“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das hier für jede Domain festgelegte Logo-Bild wird automatisch im Standard-Modul „Logo“ verwendet."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Favicon"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Logo (ALT-Text, Maße, Link)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ihre Favicon-Bildquellen-URL kann aus dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "site_settings"
      }), "-Dictionary abgerufen und in Ihren Code-Dateien verwendet werden:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if site_settings.favicon_src %}<link rel=\"icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Personalisierung“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Standardeinstellungen für Kontakte und Unternehmen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies sind die Standardwerte, die für Personalisierungstoken auf Seiten verwendet werden, wenn der Besucher unbekannt ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Integrationen“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "JS-Integrationen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aktivieren Sie JS-Integrationen (wie Google Analytics oder AdRoll) für alle Domains oder wählen Sie hier Domains aus. Wenn Sie andere Tracking-Skripts oder Google Tag Manager anstelle von Google Analytics verwenden, sollte dieser Code zum ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#site-footer-html",
        children: "Website-Footer-HTML"
      }), " hinzugefügt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „SEO & Crawler“"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Kanonische URLs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wählen Sie hier Ihre standardmäßige Kanonisierungseinstellung für einzelne Seiten und Beiträge sowie Listing-Seiten aus."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Hosting-Domain für den Standard-Datei-Manager"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies steuert die Domain, unter der Elemente vom Datei-Manager scheinbar gehostet werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Robots.txt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ändern Sie Ihre Datei „robots.txt“ für jede Domain hier."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Systemseiten“"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für mehrere Systemseiten können Sie Ihre Vorlagen in den Seiteneinstellungen auswählen. Für diese Seiten existiert kein Seiten-Editor, sondern nur Vorlagen im Design-Manager, die mit dem Vorlagentyp „System“ erstellt werden. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Bitte beachten"
        })
      }), (0, _jsxRuntime.jsx)(_components.em, {
        children: ": E-Mail-Einstellungen und Abonnementvorlagen befinden sich in den E-Mail-Einstellungen, nicht in den Seiteneinstellungen."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "404- und 500-Fehlerseiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies sind die Seiten, die für 404- oder 500-Statuscodes zurückgegeben werden. Wenn Sie eine neue Vorlage erstellen, wählen Sie im Design-Manager den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#error-pages",
        children: "Vorlagentyp „Fehlerseite“"
      }), " aus, um eine Vorlage für diese Systemseiten zur Verfügung zu stellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Seite mit Aufforderung zur Passworteingabe"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies ist die Seite, die für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "passwortgeschützte Seiten zurückgegeben wird"
      }), ", wenn ein Besucher aufgefordert wird, ein Passwort einzugeben. Wenn Sie eine neue Vorlage erstellen, wählen Sie im Design-Manager den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#password-prompt",
        children: "Vorlagentyp „Seite mit Aufforderung zur Passworteingabe“"
      }), ", um eine Vorlage für diese Art von Systemseite verfügbar zu machen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "URL der Suchergebnisseite"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies ist die Seite, die Suchergebnisse für die Abfragen auflistet, die in das HubSpot-Modul für die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-do-i-set-up-a-results-page-for-my-search-field-in-hubspot",
        children: "Website-Suche"
      }), " eingegeben wurden. Lesen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "hier"
      }), " mehr darüber, wie Sie Ihre Suche anpassen können. Wenn Sie eine neue Vorlage erstellen, wählen Sie im Design-Manager den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#search-results-page",
        children: "Vorlagentyp „Suchergebnisseite“"
      }), ", um eine Vorlage für diese Art von Systemseite verfügbar zu machen. Sie können auch Ihre Suchergebnisseiten-URL in den Seiteneinstellungen festlegen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Designeinstellungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hier finden Sie alle Designs, die zu Ihrer Website hinzugefügt wurden. Sie können in den Design-Editor gehen, indem Sie auf eines der auf Ihrer Website verfügbaren Designs klicken."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/theme-settings-screen-4.jpg",
        alt: "Bildschirm für die Designeinstellungen"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}