"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694137;
const slug = exports.slug = 'guides/cms/content/modules/quickstart';
const title = exports.title = 'Empezar a usar módulos';
const name = exports.name = 'Empezar a usar módulos';
const metaDescription = exports.metaDescription = 'Crea un componente reutilizable en tus páginas y plantillas llamado módulo personalizado. Aprenderás sobre los campos y su uso con HubL.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Module%20Editor-1-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Empezar a usar módulos';
const toc = exports.toc = [{
  "depth": 0,
  "id": "antes-de-comenzar",
  "label": "Antes de comenzar",
  "parent": null
}, {
  "depth": 0,
  "id": "configura-tu-entorno-de-desarrollo-local",
  "label": "Configura tu entorno de desarrollo local",
  "parent": null
}, {
  "depth": 0,
  "id": "agrega-la-plantilla-del-cms-a-tu-cuenta",
  "label": "Agrega la plantilla del CMS a tu cuenta",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-un-m%C3%B3dulo-en-hubspot",
  "label": "Crear un módulo en HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "agregar-campos-al-m%C3%B3dulo",
  "label": "Agregar campos al módulo",
  "parent": "crear-un-m%C3%B3dulo-en-hubspot"
}, {
  "depth": 2,
  "id": "agregar-el-campo-de-texto-para-el-nombre-del-cliente",
  "label": "Agregar el campo de texto para el nombre del cliente",
  "parent": "crear-un-m%C3%B3dulo-en-hubspot"
}, {
  "depth": 2,
  "id": "agregar-el-campo-de-imagen-para-la-imagen-de-perfil-del-cliente",
  "label": "Agregar el campo de imagen para la imagen de perfil del cliente",
  "parent": "crear-un-m%C3%B3dulo-en-hubspot"
}, {
  "depth": 2,
  "id": "agrega-el-campo-de-texto-enriquecido-para-el-testimonio-de-sally",
  "label": "Agrega el campo de texto enriquecido para el testimonio de Sally",
  "parent": "crear-un-m%C3%B3dulo-en-hubspot"
}, {
  "depth": 1,
  "id": "agregar-hubl-para-mostrar-datos-de-campos",
  "label": "Agregar HubL para mostrar datos de campos",
  "parent": "crear-un-m%C3%B3dulo-en-hubspot"
}, {
  "depth": 0,
  "id": "ver-y-modificar-el-m%C3%B3dulo-localmente",
  "label": "Ver y modificar el módulo localmente",
  "parent": null
}, {
  "depth": 1,
  "id": "abre-la-carpeta-del-m%C3%B3dulo-en-tu-entorno-local",
  "label": "Abre la carpeta del módulo en tu entorno local",
  "parent": "ver-y-modificar-el-m%C3%B3dulo-localmente"
}, {
  "depth": 1,
  "id": "ver-el-archivo-fields.json-del-m%C3%B3dulo",
  "label": "Ver el archivo fields.json del módulo",
  "parent": "ver-y-modificar-el-m%C3%B3dulo-localmente"
}, {
  "depth": 1,
  "id": "ver-el-archivo-module.html-del-m%C3%B3dulo",
  "label": "Ver el archivo module.html del módulo",
  "parent": "ver-y-modificar-el-m%C3%B3dulo-localmente"
}, {
  "depth": 1,
  "id": "ver-el-archivo-module.css-del-m%C3%B3dulo",
  "label": "Ver el archivo module.css del módulo",
  "parent": "ver-y-modificar-el-m%C3%B3dulo-localmente"
}, {
  "depth": 0,
  "id": "mover-cambios-locales-a-tu-cuenta-de-hubspot",
  "label": "Mover cambios locales a tu cuenta de HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "muestra-preliminarmente-tus-cambios-locales-en-hubspot",
  "label": "Muestra preliminarmente tus cambios locales en HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "agregar-el-m%C3%B3dulo-a-una-plantilla",
  "label": "Agregar el módulo a una plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "muestra-preliminarmente-tus-cambios-en-hubspot",
  "label": "Muestra preliminarmente tus cambios en HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "personalizar-el-m%C3%B3dulo-en-la-plantilla-localmente",
  "label": "Personalizar el módulo en la plantilla localmente",
  "parent": null
}, {
  "depth": 0,
  "id": "agregar-dos-m%C3%B3dulos-de-testimonios-m%C3%A1s-a-la-plantilla",
  "label": "Agregar dos módulos de testimonios más a la plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "modificar-el-nombre-del-tema",
  "label": "Modificar el nombre del tema",
  "parent": null
}, {
  "depth": 0,
  "id": "pasos-siguientes",
  "label": "Pasos siguientes",
  "parent": null
}, {
  "depth": 0,
  "id": "otros-tutoriales",
  "label": "Otros tutoriales",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      pre: "pre",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Empezar a usar módulos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En este tutorial, utilizarás el tema boilerplate de HubSpot CMS para aprender a crear un módulo y recorrer cómo puedes utilizarlo en plantillas y páginas como parte de un tema. Al final del tutorial, habrás creado un módulo de testimonios de clientes que incluye un campo de texto, campo de imagen y un campo de texto enriquecido."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Si esta es tu primera experiencia con el desarrollo de CMS Hub te recomendamos:"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Inicio rápido del desarrollo de CMS Hub"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si estás buscando desarrollar un módulo para usarlo en un tema que deseas publicar en el mercado de materiales de HubSpot, echa un vistazo a los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/module-requirements",
          children: "Requisitos del módulo del mercado de materiales"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Antes de comenzar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de comenzar con este tutorial:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Configurar un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-cms-developer-sandbox",
          children: "Sandbox para desarrolladores de HubSpot CMS"
        }), ". Puedes utilizar tu cuenta existente, pero el entorno de prueba te permitirá desarrollar sin afectar los materiales de tu cuenta principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Instala ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: "Node.js"
        }), ", lo que habilita las herramientas de desarrollo local de HubSpot. Se admiten las versiones 10 o superiores."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si quieres avanzar, puedes mostrar preliminarmente los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
        children: "archivos del proyecto"
      }), " terminado. Dado que el código del tema boilerplate de CMS puede cambiar con el tiempo, solo se incluyen los archivos más críticos que los desarrolladores tendrán que crear o editar durante el tutorial. Estos archivos incluyen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial.module:"
        }), " la carpeta que contiene los archivos que componen el módulo personalizado que construirás como parte del tutorial."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "homepage.html:"
        }), " la plantilla que se editará para incluir el módulo personalizado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "images:"
        }), " la carpeta que contiene las imágenes de perfil que utilizarás en el módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configura tu entorno de desarrollo local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Durante este tutorial, desarrollarás el módulo localmente. Para interactuar con HubSpot en tu interfaz de línea de comandos, tendrás que instalar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "CLI de HubSpot"
      }), " y configurarla para acceder a tu cuenta."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el terminal, ejecuta el siguiente comando para instalar la CLI globalmente. Para instalar la CLI solo en tu directorio actual, ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm install -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "En el directorio donde almacenarás tus archivos de tema, autentica tu cuenta de HubSpot para que puedas interactuar con ella a través de la CLI."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs init\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Presiona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intro"
        }), " para abrir la página de la clave de acceso personal en tu navegador."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "cuenta"
        }), " en la que deseas realizar el despliegue y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Continuar con esta cuenta"
        }), ". Se te redirigirá a la página de la clave de acceso personal de la cuenta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Junto a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Clave de acceso personal"
        }), ", haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mostrar"
        }), " para revelar tu clave. Luego, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar"
        }), " para copiarlo en el portapapeles."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pega la clave copiada en el terminal y presiona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intro"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Introduce un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " único para la cuenta. Este nombre solo será visto y utilizado por ti cuando ejecutes comandos. Luego, presiona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intro"
        }), ". Para conectar las herramientas de desarrollo local a tu cuenta:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Entonces verás un mensaje de éxito confirmando que se creó el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Agrega la plantilla del CMS a tu cuenta"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ejecuta el siguiente comando para crear un nuevo tema llamado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), ". Se creará entonces una carpeta de temas con el nombre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), " en tu directorio de trabajo que contendrá los materiales de la plantilla."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create website-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sube los archivos a tu cuenta."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs upload <src> <destination>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La ruta de acceso a tus archivos locales, relativa a tu directorio de trabajo actual."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La ruta de destino en tu cuenta de HubSpot. Puede ser una nueva carpeta."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme my-new-theme"
      }), " subiría la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-theme"
      }), " de tu máquina a una carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-new-theme"
      }), " en HubSpot"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Por opción predeterminada, HubSpot se subirá en la cuenta predeterminada en tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config,yml"
        }), ". Sin embargo, también puedes especificar una cuenta incluyendo el indicador ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "--account=<accountNameOrId>"
        }), " en el comando. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload --portal=mainProd"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Obtén más información en la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "referencia de comandos de la CLI"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear un módulo en HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con la plantilla de CMS en tu entorno local, ahora crearás un nuevo módulo para el tema."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para los fines de este tutorial, crearás el módulo en HubSpot y luego lo introducirás en el tema utilizando la CLI. Sin embargo, también puedes crear módulos desde cero localmente usando el comando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#create-new-files",
          children: "hs create module"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Inicia sesión en tu cuenta de HubSpot, luego navega al administrador de diseño navegando a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing > Archivos y plantillas > Herramientas de diseño"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la barra lateral izquierda del administrador de diseño, abre la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "carpeta del tema"
          }), " que acabas de cargar."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la carpeta del tema, abre la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "carpeta de módulos"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la parte superior izquierda, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Archivo > Nuevo archivo"
          }), " para crear un nuevo módulo en esta carpeta."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el cuadro de diálogo, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "menú desplegable"
          }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Módulo"
          }), " y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Siguiente"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/new-module-dropdown-menu0.png",
            alt: "new-module-dropdown-menu0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Selecciona la casilla de verificación ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Páginas"
          }), " para que el módulo esté disponible para el sitio web y las páginas de destino."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nombra el módulo ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Testimonio"
          }), " y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Crear"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Agregar campos al módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, agregarás tres campos al módulo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un campo de texto para almacenar el nombre del cliente que da el testimonio."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un campo de imagen que almacenará la foto de perfil del cliente."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un campo de texto enriquecido que almacenará el testimonio del cliente."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Agregar el campo de texto para el nombre del cliente"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la barra lateral derecha del editor de módulos, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Agregar campo"
          }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Texto"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-add-field.png",
            alt: "testimonial-module-add-field"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la barra lateral derecha, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ícono del lápiz"
          }), " en la esquina superior derecha para nombrar el campo. Para este tutorial, escribe el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Nombre del cliente"
          }), ". Verás que el nombre de la variable HubL cambia a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "customer_name"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Establece el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Texto predeterminado"
          }), " en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sally"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Text-Field-3.png",
        alt: "Ejemplo de campo de texto"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral derecha, haz clic en el icono ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "breadcrumb"
        }), " para volver al menú principal del módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-breadcrumb-icon0.png",
        alt: "module-breadcrumb-icon0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Agregar el campo de imagen para la imagen de perfil del cliente"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Agrega otro campo utilizando el mismo método, esta vez seleccionando el tipo de campo ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Imagen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Etiqueta el campo de la imagen como ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Imagen de perfil"
        }), " y luego define el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre de la variable de HubL"
        }), " como ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "profile_pic"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Imagen predeterminada"
        }), ", selecciona la imagen de perfil proporcionada para Sally en la carpeta de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "images"
        }), " de los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
          children: "archivos del proyecto"
        }), " completados."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Establece el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Texto alternativo"
        }), " en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Imagen de perfil del cliente"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/getting-started-with-modules-profile-pic.png",
        alt: "getting-started-with-modules-profile-pic"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Agrega el campo de texto enriquecido para el testimonio de Sally"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Agrega otro campo utilizando el mismo método, esta vez seleccionando el tipo de campo de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "texto enriquecido"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Etiqueta el campo de texto enriquecido como ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonio"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el cuadro ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Texto enriquecido predeterminado"
        }), " y luego escribe «Solo he tenido experiencias maravillosas con esta empresa».\""]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Rich-Text-Field-5--.png",
        alt: "Ejemplo de campo de texto enriquecido"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hasta ahora, has agregado datos en varios campos del módulo. En este punto, sin embargo, el módulo no contiene ningún HTML para renderizar esos datos. En el editor de módulos, se refleja en el estado vacío de la sección ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ". ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-html-empty0.png",
        alt: "module-html-empty0"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, agregarás HubL a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " para mostrar los datos del campo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Agregar HubL para mostrar datos de campos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para mostrar los datos de los campos creados anteriormente, agrega el siguiente HubL al panel ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{module.customer_name}}\n<img src={{module.profile_pic.src}} alt=\"{{module.profile_pic.alt}}\">\n{{module.testimonial}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los tokens HubL anteriores usan notación de puntos para acceder a los datos de cada campo. En este caso, debido a que queremos extraer datos de los campos del módulo, cada token comienza con ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ", seguido por el nombre de la variable de HubL del campo. Puedes usar la notación de puntos para acceder a propiedades específicas de un campo, que puedes ver en los tokens ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "profile_pic"
      }), " en la línea 3 que aparece arriba."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Con el HubL agregado a tu módulo, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista preliminar"
        }), " en la parte superior derecha para ver el aspecto del módulo hasta el momento."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-previewer.png",
        alt: "design-manager-previewer"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Luego, en la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publicar cambios"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de crear y publicar el módulo en HubSpot, ahora usarás la CLI para llevar el módulo a tu entorno local para que puedas ver su contenido y realizar más cambios."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ver y modificar el módulo localmente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ver el módulo localmente, primero deberás bajarlo a tu tema local:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el terminal, ejecuta el siguiente comando: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs fetch <hs_src> <destination>"
          }), ":"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<hs_src>"
              }), " representa la ruta del módulo en HubSpot. Para obtener la ruta del archivo, puedes hacer clic con el botón derecho del ratón sobre el módulo en la barra lateral izquierda del administrador de diseño y luego seleccionar ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Copiar ruta"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-copy-path0.png",
                alt: "design-manager-copy-path0"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<destination>"
              }), " representa la ruta del directorio local que contiene tu tema. Si se omite, el comando se dirigirá por opción predeterminada al directorio de trabajo actual."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, si ya estás en el directorio de trabajo, el comando fetch puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch my-theme/modules/testimonial.module\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abre la carpeta del módulo en tu entorno local"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En tu editor de código preferido, navega hasta la carpeta del módulo que acabas de sacar de tu cuenta de HubSpot. Dentro de tu carpeta de módulos, verás cinco archivos diferentes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fields.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un objeto JSON que contiene los campos de tu módulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un objeto JSON que contiene meta información sobre tu módulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.css"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El archivo CSS que da estilo a tu módulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El HTML y HubL para tu módulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.js"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El JavaScript para tu módulo."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes encontrar información más detallada en nuestra documentación sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "estructura de archivos del módulo"
      }), ", especialmente en lo que respecta a los archivos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), ". En este tutorial, nos centraremos en los archivos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " y en cómo se generan, se descargan y se suben en el editor de módulos en el administrador de diseño."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ver el archivo fields.json del módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abre el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " del módulo. Aparte de algunos de los números de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", el atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " del campo de la imagen, y potencialmente el orden de los campos, el archivo debe contener un objeto JSON similar al siguiente:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//fields.json\n\n[\n  {\n    \"label\": \"Customer Name\",\n    \"name\": \"customer_name\",\n    \"id\": \"2a025cd5-7357-007f-ae2f-25ace762588e\",\n    \"type\": \"text\",\n    \"required\": true,\n    \"locked\": false,\n    \"validation_regex\": \"\",\n    \"allow_new_line\": false,\n    \"show_emoji_picker\": false,\n    \"default\": \"Sally\"\n  },\n  {\n    \"label\": \"Profile Picture\",\n    \"name\": \"profile_pic\",\n    \"id\": \"7877fb84-eb8a-d2c7-d939-77e6e9557d8f\",\n    \"type\": \"image\",\n    \"required\": false,\n    \"locked\": false,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"default\": {\n      \"src\": \"https://cdn2.hubspotqa.net/hubfs/101140939/profile-pic-sally.svg\",\n      \"alt\": \"Sally Profile Picture\",\n      \"width\": 100,\n      \"height\": 100,\n      \"max_width\": 100,\n      \"max_height\": 100\n    }\n  },\n  {\n    \"label\": \"Testimonial\",\n    \"name\": \"testimonial\",\n    \"id\": \"c5524ece-1ab5-f92d-a5de-e2bf53199dfa\",\n    \"type\": \"richtext\",\n    \"required\": false,\n    \"locked\": false,\n    \"default\": \"<p>I’ve had nothing but wonderful experiences with this company.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los valores de los siguientes campos coincidirán con los valores que agregaste en el paso 3:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": el nombre del campo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ": la etiqueta del campo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "default"
        }), ": el valor predeterminado del campo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ver el archivo module.html del módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " debe contener el HubL y el HTML que escribiste en el editor de módulos HubL + HTML anteriormente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para hacer este código más interesante y listo para estilo CSS, copia y pega el siguiente código en el archivo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"testimonial\">\n  <h1 class=\"testimonial__header\"> {{ module.customer_name }} </h1>\n  <img class=\"testimonial__picture\" src={{ module.profile_pic.src }} alt={{ module.profile_pic.alt }}>\n  {{ module.testimonial }}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Al escribir el HTML como se indica arriba se utiliza la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/bem-101/",
          children: "estructura de clases BEM"
        }), " de acuerdo con la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/STYLEGUIDE.md#css-code-formatting",
          children: "guía de estilo"
        }), " del tema boilerplate de HubSpot CMS."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ver el archivo module.css del módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " debería estar vacío en este punto. Para agregar el estilo, copia y pega el siguiente código en el archivo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial {\n  text-align: center;\n}\n\n.testimonial__header {\n  font-weight: bold;\n}\n\n.testimonial__picture {\n  display: block;\n  margin: auto;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de agregar el código, guarda el archivo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mover cambios locales a tu cuenta de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de guardar los cambios locales, devuélvelos a tu cuenta de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Navega a tu terminal y asegúrate de que estás en el directorio correcto."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ejecuta el comando watch para enviar los cambios a HubSpot al guardar: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <destination>"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch my-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Muestra preliminarmente tus cambios locales en HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivos y plantillas"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Herramientas de diseño"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral izquierda, navega hasta el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tema"
        }), " que has creado, luego abre la carpeta de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "módulos"
        }), " y selecciona el módulo de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonios"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Con el módulo abierto, ahora deberías ver tus cambios en los paneles ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.css"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista preliminar"
        }), ". Se abrirá una nueva pestaña para mostrar la vista preliminar del módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Module-Preview-7.png",
        alt: "Ejemplo de vista preliminar del módulo"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para recapitular en este tutorial hasta ahora:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Creaste un módulo en HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Bajaste ese módulo a tu entorno local."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Realizaste cambios en el HubL + HTML y CSS utilizando tus herramientas de desarrollo locales."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En la siguiente parte de este tutorial, aprende a utilizar el módulo en una plantilla."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Agregar el módulo a una plantilla"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para esta parte del tutorial, trabajarás principalmente con las carpetas de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modules"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templates"
      }), " dentro de los archivos de temas locales. ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/theme-folder-structure-getting-started-with-modules0.png",
        alt: "theme-folder-structure-getting-started-with-modules0"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por su definición más básica, los módulos son áreas editables de las plantillas y páginas de HubSpot. Puedes insertar módulos en plantillas en HubSpot utilizando el administrador de diseño, pero aquí vas a utilizar HubL para agregar el módulo a la plantilla en tu entorno local."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu editor de código, abre la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "templates"
          }), " y luego abre el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), ", ve hasta la ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), " final, que comienza alrededor de la línea 28. En esta sección agregarás tu nuevo módulo."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/home-html-add-testimonial-module-to-section0.png",
            alt: "home-html-add-testimonial-module-to-section0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dentro de esta ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), " y encima de los otros ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_modules"
          }), ", copia y pega la siguiente etiqueta de módulo HubL:"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path= “../modules/Testimonial.module”,\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta etiqueta HubL hace referencia a tu nuevo módulo por su ruta de archivo relativa. Para lograr que el módulo encaje uniformemente con los otros dos módulos en la sección ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), ", también asigna ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " del módulo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El CMS de HubSpot utiliza un sistema de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview#columns",
          children: "cuadrícula de 12 columnas"
        }), ", por lo que para espaciar este módulo de manera uniforme con los otros dos, deberás actualizar cada módulo en la ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), " para tener una anchura de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Entonces, el primer ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " del grupo (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), ") tendrá un desplazamiento de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), " para posicionarlo primero."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El segundo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "linked_image"
        }), ") tendrá un desplazamiento de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), " para posicionarlo en segundo lugar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El segundo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "rich_text"
        }), ") tendrá un desplazamiento de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "8"
        }), " para posicionarlo en segundo lugar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tras los ajustes del ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " de cada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ", tu código será similar al siguiente:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n    background_color=\"#f8fafc\",\n    vertical_alignment=\"MIDDLE\"\n  %}\n\n    {% dnd_module\n      path= “../modules/Testimonial.module”,\n      offset=0,\n      width=4\n    %}\n    {% end_dnd_module %}\n\n    {% dnd_module\n      path=\"@hubspot/linked_image\",\n      img={\n        \"alt\": \"Stock placeholder image with grayscale geometrical mountain landscape\",\n        \"loading\": \"lazy\",\n        \"max_height\": 451,\n        \"max_width\": 605,\n        \"size_type\": \"auto_custom_max\",\n        \"src\": get_asset_url(\"../images/grayscale-mountain.png\")\n      },\n      offset=4,\n      width=4\n    %}\n    {% end_dnd_module %}\n    {% dnd_module\n      path=\"@hubspot/rich_text\",\n      html=\"<h2>Provide more details here.</h2><p>Use text and images to tell your company’s story. Explain what makes your product or service extraordinary.</p>\"\n      offset=8,\n      width=4\n    %}\n    {% end_dnd_module %}\n  {% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Cuando se agrega un módulo a un área de arrastrar y soltar, la etiqueta del módulo ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " requiere un nombre único. Sin embargo, cuando se agrega un módulo a un archivo HTML fuera de las áreas de arrastrar y soltar, debes asignar al módulo un nombre único. También se utilizaría una sintaxis ligeramente diferente, por ejemplo:"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% module \"testimonial_one\" path=\"../modules/Testimonial.module\" %}"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Puedes aprender más sobre el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "uso de módulos en las plantillas"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Muestra preliminarmente tus cambios en HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si no has mantenido la ejecución del comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), " para hacer un seguimiento automático de tu trabajo guardado, ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <dest>"
        }), ". Asegúrate de que este comando se siga ejecutando, mientras completas los siguientes pasos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, abre administrador de diseño (", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivos y plantillas"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Herramientas de diseño"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral izquierda del administrador de diseño, selecciona el archivo ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "home.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista preliminar"
        }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista preliminar en directo con opciones de presentación"
        }), " para abrir la vista preliminar de la plantilla en una nueva ventana."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Live-Preview-10.png",
        alt: "Ejemplo de vista preliminar en vivo"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "En la nueva pestaña, consulta la vista preliminar de la plantilla, que debería contener tu módulo de testimonios recién agregado."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-added-to-theme.png",
        alt: "testimonial-module-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Personalizar el módulo en la plantilla localmente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para hacer más interesante la plantilla de la página de inicio:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vuelve a tu editor de código y luego abre el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "home.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Agrega los siguientes parámetros a la etiqueta del módulo de testimonios:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n testimonial = \"Wow, what a product! I can't wait to recommend this to all my family and friends!\",\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los parámetros anteriores anulan los valores predeterminados que asignaste originalmente a los tres campos. Cada parámetro utiliza el nombre de la variable HubL que asignaste a cada campo previamente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "customer_name"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " este parámetro pasa el nombre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Mary"
        }), " al campo del nombre del cliente y sobrescribe el valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sally"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "profile_pic"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " este parámetro es un objeto que contiene dos propiedades:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["La propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "src"
            }), " utiliza la función HubL ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "get_asset_url"
            }), " para obtener la URL de la nueva foto de perfil. Ten en cuenta que la ruta del archivo de la imagen es relativa a tu directorio de trabajo. Primero deberás agregar esta imagen a la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), " en tus archivos de tema locales. Puedes encontrar la imagen en la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), " de los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
              children: "archivos de proyecto completados."
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["La propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " asigna el texto alternativo de la imagen."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " este parámetro pasa un nuevo texto al campo de testimonio."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alternativamente, para el campo de texto enriquecido, podrías utilizar la sintaxis de bloque de HubL para escribir un gran bloque de HTML o texto:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n offset=0,\n width=4\n%}\n {% module_attribute \"testimonial\" %}\n   Wow, what a product! I can't wait to recommend this to all my family and friends!\n {% end_module_attribute %}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "la sintaxis del bloque de módulos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si has mantenido el comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " en ejecución en tu terminal, guardar los cambios los enviará a HubSpot. Puedes volver a navegar por el administrador de diseño para obtener una vista preliminar de la plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Mary-Module-in-Template-12.png",
        alt: "Módulo de Mary en plantillas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Agregar dos módulos de testimonios más a la plantilla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En este paso, agregarás otros dos módulos de testimonios a la plantilla siguiendo los mismos pasos que antes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Ve al editor de código y luego abre el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Debajo del módulo de testimonios que agregaste anteriormente, agrega otra instancia del módulo copiando y pegando el código de ese módulo. En el nuevo módulo de testimonios, especifica los siguientes detalles siguiendo los mismos pasos anteriores:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["El nombre del cliente es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["El testimonio de Ollie dice: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "I can't believe that I ever conducted business without the use of this product!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para la imagen de Ollie, agrega la ruta relativa del archivo dentro de la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), ". Puedes obtener la imagen en sí de los archivos de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
              children: "proyecto terminados"
            }), ". Entonces dale a la imagen de Ollie el atributo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie Profile Picture"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para mantener el espaciado, fija ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Debajo del segundo módulo de testimonios, agrega un tercero con los siguientes datos:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["El nombre del cliente es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["El testimonio de Erin dice: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "My business has nearly tripled since I began to use this product! Don't wait, start now!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para la imagen de Erin, agrega la ruta relativa del archivo dentro de la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), ". Luego dale a la imagen de Erin el atributo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin Profile Picture"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para mantener el espaciado, fija ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "8"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Guarda los cambios."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si has mantenido el comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " en ejecución en tu terminal, puedes volver al administrador de diseño para previsualizar tus cambios en la plantilla. La vista preliminar de la plantilla debería contener ahora los tres módulos de testimonios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-modules-added-to-theme.png",
        alt: "testimonial-modules-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como paso final, prepararás el tema para usarlo en una página activa modificando el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modificar el nombre del tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si quieres modificar el nombre predeterminado del tema, puedes hacerlo configurando los campos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example theme.json\n\n{\n  \"name\": \"my_first_theme\",\n  \"label\": \"My first theme\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de agregar ese código, guarda los cambios para que el comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " los envíe a HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pasos siguientes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora que creaste un módulo personalizado, lo agregaste a la plantilla ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "home.html"
      }), " y, opcionalmente, personalizaste el nombre del tema, puedes para crear una página activa desde el tema."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si quieres seguir un tutorial similar que se centra en temas, echa un vistazo al tutorial de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/getting-started",
        children: "Introducción a los temas"
      }), " a continuación. De lo contrario, puedes obtener más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-customize-pages",
        children: "crear y personalizar páginas"
      }), " en la Base de conocimientos de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O, si deseas obtener más información sobre los módulos, consulta las siguientes guías de módulos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configurar un módulo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Uso de módulos en plantillas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Módulos predeterminados"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Otros tutoriales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Primeros pasos para usar temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "Comenzar a usar las funciones sin servidor"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Creación de un proceso de desarrollo eficiente"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Empezar con la accesibilidad"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/js-frameworks",
          children: "Cómo utilizar los frameworks de JavaScript en CMS Hub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "Cómo usar componentes web en módulos personalizados"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-build-a-code-block-web-component",
          children: "Cómo crear un módulo basado en componentes web de bloques de código"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}