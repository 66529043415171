"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 43177490027;
const slug = exports.slug = 'guides/apps/authentication/oauth-quickstart-guide';
const title = exports.title = 'OAuthの利用 | OAuthクイックスタートガイド';
const name = exports.name = 'APAC JAPAN (JA) | vNext Docs DP - OAuth Quickstart | 202007';
const metaDescription = exports.metaDescription = 'HubSpotのクイックスタートガイドとサンプルのnode.jsアプリを使用して、OAuth認証によってHubSpotに接続します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%A7%8B%E3%82%81%E3%82%8B%E5%89%8D%E3%81%AB",
  "label": "始める前に",
  "parent": null
}, {
  "depth": 1,
  "id": "%E4%BB%95%E7%B5%84%E3%81%BF",
  "label": "仕組み",
  "parent": "%E5%A7%8B%E3%82%81%E3%82%8B%E5%89%8D%E3%81%AB"
}, {
  "depth": 1,
  "id": "%E3%81%93%E3%81%AE%E3%82%AC%E3%82%A4%E3%83%89%E3%81%AE%E5%86%85%E5%AE%B9",
  "label": "このガイドの内容",
  "parent": "%E5%A7%8B%E3%82%81%E3%82%8B%E5%89%8D%E3%81%AB"
}, {
  "depth": 0,
  "id": "%E3%82%AF%E3%82%A4%E3%83%83%E3%82%AF%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "クイックスタートアプリ",
  "parent": null
}, {
  "depth": 0,
  "id": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E5%8F%96%E5%BE%97",
  "label": "OAuth 2.0トークンの取得",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B9%E3%83%86%E3%83%83%E3%83%971%EF%BC%9A%E8%AA%8D%E8%A8%BCurl%E3%82%92%E4%BD%9C%E6%88%90%E3%81%97%E3%80%81%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%82%92hubspot%E3%81%AEoauth-2.0%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%81%AB%E5%B0%8E%E3%81%8F",
  "label": "ステップ1：認証URLを作成し、ユーザーをHubSpotのOAuth 2.0サーバーに導く",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E5%8F%96%E5%BE%97"
}, {
  "depth": 3,
  "id": "%E4%BE%8B",
  "label": "例",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E5%8F%96%E5%BE%97"
}, {
  "depth": 3,
  "id": "%E8%BF%BD%E5%8A%A0%E3%81%AE%E3%83%AA%E3%83%80%E3%82%A4%E3%83%AC%E3%82%AF%E3%83%88%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E7%8A%B6%E6%85%8B%E3%82%92%E3%82%A8%E3%83%B3%E3%82%B3%E3%83%BC%E3%83%89%E3%81%99%E3%82%8B",
  "label": "追加のリダイレクトユーザー状態をエンコードする",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E5%8F%96%E5%BE%97"
}, {
  "depth": 1,
  "id": "%E3%82%B9%E3%83%86%E3%83%83%E3%83%972%EF%BC%9Ahubspot%E3%81%8C%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%AB%E5%90%8C%E6%84%8F%E3%82%92%E6%B1%82%E3%82%81%E3%82%8B",
  "label": "ステップ2：HubSpotがユーザーに同意を求める",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E5%8F%96%E5%BE%97"
}, {
  "depth": 1,
  "id": "%E3%82%B9%E3%83%86%E3%83%83%E3%83%973%EF%BC%9Aoauth-2.0%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%81%AE%E3%83%AC%E3%82%B9%E3%83%9D%E3%83%B3%E3%82%B9%E3%82%92%E5%87%A6%E7%90%86%E3%81%99%E3%82%8B",
  "label": "ステップ3：OAuth 2.0サーバーのレスポンスを処理する",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E5%8F%96%E5%BE%97"
}, {
  "depth": 3,
  "id": "%E4%BE%8B%EF%BC%9A",
  "label": "例：",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E5%8F%96%E5%BE%97"
}, {
  "depth": 1,
  "id": "%E3%82%B9%E3%83%86%E3%83%83%E3%83%974%EF%BC%9A%E8%AA%8D%E8%A8%BC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%A8%E4%BA%A4%E6%8F%9B%E3%81%99%E3%82%8B",
  "label": "ステップ4：認証コードをトークンと交換する",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E5%8F%96%E5%BE%97"
}, {
  "depth": 3,
  "id": "%E4%BE%8B%EF%BC%9A",
  "label": "例：",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E5%8F%96%E5%BE%97"
}, {
  "depth": 0,
  "id": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E4%BD%BF%E7%94%A8",
  "label": "OAuth 2.0トークンの使用",
  "parent": null
}, {
  "depth": 3,
  "id": "%E4%BE%8B%EF%BC%9A",
  "label": "例：",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E4%BD%BF%E7%94%A8"
}, {
  "depth": 0,
  "id": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E3%83%AA%E3%83%95%E3%83%AC%E3%83%83%E3%82%B7%E3%83%A5",
  "label": "OAuth 2.0トークンのリフレッシュ",
  "parent": null
}, {
  "depth": 3,
  "id": "%E4%BE%8B%EF%BC%9A",
  "label": "例：",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E3%83%AA%E3%83%95%E3%83%AC%E3%83%83%E3%82%B7%E3%83%A5"
}, {
  "depth": 2,
  "id": "%E9%96%A2%E9%80%A3%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "関連ドキュメント",
  "parent": "oauth-2.0%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E3%83%AA%E3%83%95%E3%83%AC%E3%83%83%E3%82%B7%E3%83%A5"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      hr: "hr",
      h3: "h3",
      ol: "ol",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h5: "h5",
      pre: "pre",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "OAuthクイックスタートガイド"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "始める前に"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotでOAuthを使用するには、次のものが必要です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers",
          children: "開発者アカウント"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["開発者アカウントに関連付けられている", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/api/creating-an-app",
          children: "アプリ"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリをインストールするためのHubSpotアカウント*（既存のアカウントを使用するか、または", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "テストアカウントを作成する"
        }), "ことができます）"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "*HubSpotアカウント上にアプリをインストールするには、スーパー管理者の権限が必要です"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "仕組み"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.okta.com/blog/2018/04/10/oauth-authorization-code-grant-type",
        children: "OAuth 2.0認証コード付与方式"
      }), "に対応しています。この処理は次の4つの基本ステップに分けることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリによってブラウザーウィンドウを開き、ユーザーをHubSpot OAuth 2.0サーバーに導く"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "要求された権限をユーザーが確認し、アプリにアクセス権を付与する"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "クエリー文字列の中に認証コードを指定した状態で、ユーザーがアプリにリダイレクトされる"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリが、アクセストークンの認証コードを交換するためのリクエストをOAuth 2.0サーバーに送信する"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "このガイドの内容"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#quick-start-app",
          children: "クイックスタートアプリ"
        }), "：HubSpotのOAuth 2.0サーバーで認証されるNode.jsデモアプリ"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#getting_tokens",
          children: "OAuth 2.0トークンの取得"
        }), "：アプリでユーザーを認証する方法"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#using_tokens",
          children: "OAuth 2.0トークンの使用"
        }), "：トークンによりクエリーを実行する方法"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#refreshing_tokens",
          children: "OAuth 2.0トークンのリフレッシュ"
        }), "：HubSpotで提供されるリフレッシュトークンの使い方"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**注：**このガイドの全てのコードサンプルは、JavaScript（Node.js）で記述されています"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "クイックスタートアプリ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのAPIでOAuth認証を初めて使用する場合、Node.jsで記述されている", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
        children: "OAuth 2.0クイックスタートアプリ"
      }), "（英語）を確認することを強くお勧めします。このサンプルアプリは、OAuth 2.0の利用をできるだけ短時間で開始することを意図して設計されており、後述の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth 2.0トークンの取得"
      }), "に記載の全ての手順を実際に使用しています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
          children: "Githubでアプリを取得する"
        }), "（英語）"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth 2.0トークンの取得"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ステップ1：認証URLを作成し、ユーザーをHubSpotのOAuth 2.0サーバーに導く"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのOAuth 2.0サーバーにユーザーを導くための最初のステップは、認証URLの作成です。これによりアプリが特定されると共に、ユーザーに代わってアクセス権を求めるリソース（スコープ）が定義されます。認証URLの一部として指定できるクエリーパラメーターは、以下のとおりです。このステップの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "リファレンスドキュメント"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "必須"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "はい"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "クライアントIDによってアプリが識別されます。アプリの設定ページで確認します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "はい"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アプリが要求している", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth#scopes",
              children: "スコープ"
            }), "（URLエンコードのスペース区切り）。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contacts%20social"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "はい"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アプリが要求したスコープの認証後にユーザーがリダイレクトされるURL。", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "本番環境アプリでは、httpsが必須です。"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optional_scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "いいえ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アプリにとって任意のスコープ。選択されたHubSpotポータルがその製品にアクセスできない場合は破棄されます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "いいえ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リダイレクトされてアプリに戻ってきたユーザーの状態を維持するために使用できる一意の文字列値。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WeHH_yy2irpl8UYAvv-my"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "URLを作成したら、URLにユーザーを誘導してOAuth 2.0プロセスを開始します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "例"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サーバーサイドのリダイレクトを使用："
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Build the auth URL\nconst authUrl =\n  'https://app.hubspot.com/oauth/authorize' +\n  `?client_id=${encodeURIComponent(CLIENT_ID)}` +\n  `&scope=${encodeURIComponent(SCOPES)}` +\n  `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;\n+`&state=${encodeURIComponent(STATE)}`;\n\n// Redirect the user\nreturn res.redirect(authUrl);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HTMLリンクを使用："
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a\n  href=\"https://app.hubspot.com/oauth/authorize?scope=contacts%20social&redirect_uri=https://www.example.com/auth-callback&client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&state=xxxxxxxx\"\n  >Install</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "追加のリダイレクトユーザー状態をエンコードする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["一部のアプリでは、ユーザーを別の場所にリダイレクトしなければならない場合があります。例えば、アプリによっては、連携されている別のサブドメイン（userA.integration.comやuserB.integration.comなど）にリダイレクトする必要がある場合があります。これを行うには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), "パラメーターを使用して、ユーザー状態に関する詳細情報をエンコードします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "1. stateパラメーターのnonce値を生成して保存します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "2. nonceをキーとして使用して、ユーザーの状態をローカルデータストアに保存します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "3. 認証URLに、状態パラメーターとしてnonce値を含めます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "4. ユーザーが認証され、リダイレクトURLにリダイレクトされたら、stateパラメーターを検証し、保存されたユーザー状態を取得するためのキーとして使用します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "5. そこから、必要に応じてユーザーをリダイレクトします（例：ユーザー固有のURLに再度リダイレクトする）。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ステップ2：HubSpotがユーザーに同意を求める"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotは、アプリの名前と、アプリがアクセス権限を要求しているHubSpot APIサービスに関する簡単な説明を含む同意ウィンドウをユーザーに提示します。ここで、ユーザーはアプリにアクセス権を付与できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/%5BExample%5D%20Install%20Screen.png",
        alt: "［例］インストール画面"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**注：**アプリをインストールするユーザーは、要求された全てのスコープに対するアクセス権を持っている必要があります。必要なアクセス権を持っていない場合は、インストールが失敗し、エラーページが表示されます。この権限エラーページが表示された場合は、スーパー管理者にアプリのインストールを依頼する必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この段階でアプリが行う処理はありません。アクセス権が付与されると、HubSpot OAuth 2.0サーバーが、認証URLで定義されたコールバックURIにリクエストを送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ステップ3：OAuth 2.0サーバーのレスポンスを処理する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ユーザーがステップ2の同意確認を完了すると、OAuth 2.0サーバーは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを認証URLで指定されたリダイレクトURIに送信します。問題が発生せず、ユーザーがアクセス要求を承認した場合、リダイレクトURIに対するリクエストが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "code"
      }), "クエリーパラメーター付きで返されます。ユーザーがアクセス権を付与しなかった場合、リクエストは送信されません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "例："
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "app.get('/oauth-callback', async (req, res) => {\n  if (req.query.code) {\n    // Handle the received code\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ステップ4：認証コードをトークンと交換する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリはOAuth 2.0サーバーから認証コードを受け取ったら、コードをアクセス／リフレッシュトークンと交換できます。そのためには、URL形式でエンコードされた", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), "に送信します（以下に記載の値を使用）。このステップの詳細については、この", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "リファレンスドキュメント"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            }), "でなければなりません"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アプリのクライアントID"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アプリのクライアントシークレット"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーがアプリを認証した場合のリダイレクトURI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "OAuth 2.0サーバーから受信した認証コード"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5771f587-2fe7-40e8-8784-042fb4bc2c31"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "例："
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'authorization_code',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  code: req.query.code\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "トークンレスポンスの本文は、次の形式のJSONデータになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["**注：**アクセストークンは、レスポンスの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), "フィールドで示された秒数の経過後に期限切れになります（現時点では30分）。新しいアクセストークンの取得方法については、OAuth 2.0トークンのリフレッシュを参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth 2.0トークンの使用"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["認証コードのフローが完了すると、ユーザーに代わってリクエストを行う権限がアプリに付与されます。そのためには、トークンをベアラートークンとして", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " HTTPヘッダーの中に指定します。具体的には、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "リファレンスドキュメント"
      }), "で確認できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "例："
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "request.get(\n  'https://api.hubapi.com/contacts/v1/lists/all/contacts/all?count=1',\n  {\n    headers: {\n      Authorization: `Bearer ${ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**アクセストークンは、アプリから要求されたスコープを反映しますが、ユーザーがHubSpotアカウントで実行できる操作に関する権限や制限を反映することはありません。例えば、担当するコンタクトのみを表示する権限を持つユーザーが、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), "スコープに対する要求を許可した場合、生成されるアクセストークンでは、許可したユーザーの担当するコンタクトだけでなく、アカウント内の全てのコンタクトを表示できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth 2.0トークンのリフレッシュ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["OAuthアクセストークンは定期的に有効期限が切れます。これは、トークンが侵害されても、攻撃者によるアクセスを極力短時間に限定するためです。トークンの利用可能な秒数は、認証コードがアクセストークンと交換された時点で、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), "フィールドに指定されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリは受信したリフレッシュトークンを新しいアクセストークンと交換できます。そのためには、URL形式でエンコードされた", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), "に送信します。その際に、以下の値を使用します。このステップの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "リファレンスドキュメント"
      }), "を確認してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            }), "でなければなりません"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アプリのクライアントID"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アプリのクライアントシークレット"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーがアプリを認証した場合のリダイレクトURI"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーがアプリを認証したときに受け取るリフレッシュトークン"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b9443019-30fe-4df1-a67e-3d75cbd0f726"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "例："
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'refresh_token',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  refresh_token: REFRESH_TOKEN\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "トークンレスポンスの本文は、次の形式のJSONデータになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Sample response\n{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この新しいアクセストークンを、ユーザーの代わりに呼び出しを行う際に使用します。新しいトークンの有効期限が切れたら、同じ手順を繰り返して新たにトークンを取得できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "関連ドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "HubSpotでの認証方式"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuthの利用"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "トークンを管理する"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}