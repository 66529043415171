"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125359;
const slug = exports.slug = 'guides/cms/storage/hubdb';
const title = exports.title = 'HubDB';
const name = exports.name = 'EMEA DACH (DE) HubDB';
const metaDescription = exports.metaDescription = 'Erstellen Sie datengestützte Inhalte mithilfe von HubDB, dem nativen Datenbank-Tool von HubSpot. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "hubdb-architektur",
  "label": "HubDB-Architektur",
  "parent": null
}, {
  "depth": 0,
  "id": "technische-beschr%C3%A4nkungen-von-hubdb",
  "label": "Technische Beschränkungen von HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "eine-hubdb-tabelle-erstellen",
  "label": "Eine HubDB-Tabelle erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "mehrere-hubdb-tabellen-verbinden",
  "label": "Mehrere HubDB-Tabellen verbinden",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-eine-%E2%80%9Efremd-id%E2%80%9C-spalte-zur-haupttabelle-hinzuf%C3%BCgen",
  "label": "1. Eine „Fremd-ID“-Spalte zur Haupttabelle hinzufügen",
  "parent": "mehrere-hubdb-tabellen-verbinden"
}, {
  "depth": 1,
  "id": "2.-fremdtabellenzeilen-zu-den-zeilen-ihrer-tabelle-hinzuf%C3%BCgen",
  "label": "2. Fremdtabellenzeilen zu den Zeilen Ihrer Tabelle hinzufügen",
  "parent": "mehrere-hubdb-tabellen-verbinden"
}, {
  "depth": 1,
  "id": "3.-ihre-verbundenen-hubdb-tabellendaten-rendern",
  "label": "3. Ihre verbundenen HubDB-Tabellendaten rendern",
  "parent": "mehrere-hubdb-tabellen-verbinden"
}, {
  "depth": 0,
  "id": "hubdb-daten-mithilfe-von-hubl-aufrufen",
  "label": "HubDB-Daten mithilfe von HubL aufrufen",
  "parent": null
}, {
  "depth": 1,
  "id": "abrufen-von-zeilen",
  "label": "Abrufen von Zeilen",
  "parent": "hubdb-daten-mithilfe-von-hubl-aufrufen"
}, {
  "depth": 2,
  "id": "zeilenattribute",
  "label": "Zeilenattribute",
  "parent": "hubdb-daten-mithilfe-von-hubl-aufrufen"
}, {
  "depth": 1,
  "id": "metadaten-von-tabellen-abrufen",
  "label": "Metadaten von Tabellen abrufen",
  "parent": "hubdb-daten-mithilfe-von-hubl-aufrufen"
}, {
  "depth": 1,
  "id": "table-attribute",
  "label": "table-Attribute",
  "parent": "hubdb-daten-mithilfe-von-hubl-aufrufen"
}, {
  "depth": 1,
  "id": "spaltenmetadaten-abrufen",
  "label": "Spaltenmetadaten abrufen",
  "parent": "hubdb-daten-mithilfe-von-hubl-aufrufen"
}, {
  "depth": 2,
  "id": "column-attribute",
  "label": "column-Attribute",
  "parent": "hubdb-daten-mithilfe-von-hubl-aufrufen"
}, {
  "depth": 2,
  "id": "spaltenmethoden",
  "label": "Spaltenmethoden",
  "parent": "hubdb-daten-mithilfe-von-hubl-aufrufen"
}, {
  "depth": 2,
  "id": "rich-text-spalten",
  "label": "Rich-Text-Spalten",
  "parent": "hubdb-daten-mithilfe-von-hubl-aufrufen"
}, {
  "depth": 0,
  "id": "hubdb-tutorials-und--ressourcen",
  "label": "HubDB-Tutorials und -Ressourcen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      em: "em",
      img: "img",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h3: "h3",
      pre: "pre",
      h4: "h4",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB ist ein Tool, mit dem Sie Tabellen erstellen können, um Daten in Zeilen, Spalten und Zellen zu speichern, ähnlich wie in einer Kalkulationstabelle. Sie können die Spalten, Zeilen und anderen Einstellungen einer HubDB-Tabelle nach Ihren Bedürfnissen anpassen. Sie könnten beispielsweise eine HubDB-Tabelle verwenden, um:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Feedback von einem externen Mechanismus zu speichern und zu einem späteren Zeitpunkt abzurufen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["strukturierte Daten zu speichern, mit denen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "dynamische CMS-Seiten erstellen"
        }), " können (nur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Daten zur Verwendung in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/email/create-programmable-emails",
          children: "programmierbaren E-Mail-Inhalten"
        }), " zu speichern (nur ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "**_Marketing Hub"
        }), "**_ ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-example0.png",
        alt: "hubdb-tabelle-beispiel0"
      }), "Auf HubDB-Tabellen kann sowohl ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/website-pages/create-and-populate-tables-in-hubdb",
        children: "innerhalb von HubSpot"
      }), " als auch über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb",
        children: "HubDB-API"
      }), " zugegriffen werden, und Sie können die Daten einer Tabelle auf mehrere Arten abrufen, je nach Ihrem Anwendungsfall. Um Daten von einer HubDB-Tabelle abzurufen, können Sie:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die Daten extern über die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/cms/hubdb",
          children: "HubDB-API"
        }), " abrufen."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die Daten mithilfe der HubL-Markup-Tags von HubSpot als strukturierte Inhalte in das CMS übernehmen"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die HubDB-API mit ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "serverlosen Funktionen"
        }), " verwenden, um ein interaktives Web-App-Erlebnis zu bieten"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
            children: "HubDB-Daten in Seiten zu verwenden"
          }), ", benötigen Sie ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CMS-Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Um HubDB-Daten in ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/de/email/create-programmable-emails",
            children: "programmierbaren E-Mail-Inhalten"
          }), " zu verwenden, benötigen Sie ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Für das Veröffentlichen, Bearbeiten und Anzeigen vorhandener Tabellen sind ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/de/settings/hubspot-user-permissions-guide#website-tools",
            children: "HubDB-Berechtigungen"
          }), " erforderlich. Für das Erstellen, Klonen, Löschen und Bearbeiten der Einstellungen einer HubDB-Tabelle sind ", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/de/settings/hubspot-user-permissions-guide#settings",
            children: ["Berechtigungen für ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "HubDB-Tabelleneinstellungen"
            })]
          }), " erforderlich."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB-Architektur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eine HubDB-Tabelle besteht aus Zeilen, Spalten und Zellen, ähnlich einer Kalkulationstabelle."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tables:"
        }), " Eine Tabelle ist eine 2-dimensionale Anordnung von Zeilen und Spalten. Wenn eine Tabelle erstellt wird, wird ihr eine global eindeutige ID zugewiesen, die Sie verwenden können, wenn Sie eine Tabelle in HubL oder über die API angeben müssen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zeilen:"
        }), " Zeilen sind horizontale Segmente einer Tabelle. In einer Kalkulationstabellenanwendung werden Zeilen durch Zahlen dargestellt, mit 1 beginnend. Jeder Tabellenzeile wird bei der Erstellung eine global eindeutige ID zugewiesen. Jede Zeile in einer Tabelle enthält standardmäßig einige Spalten:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Spalte"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine automatisch zugewiesene, global eindeutige, numerische ID für diese Zeile."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Zeitstempel, wann diese Zeile erstellt wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bei Verwendung mit dynamischen Seiten ist diese Zeichenfolge das letzte Segment des Pfades der URL für die Seite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bei Verwendung mit dynamischen Seiten ist dies der Titel der Seite."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Die Spalten des Rich-Text-Bereichs sind in HubDB auf 65.000 Zeichen begrenzt. Weitere Informationen hierzu finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/hubdb-rich-text-area-limited-to-65000-characters-and-a-new-meetings-module",
          children: "Ankündigung in unserem Änderungsprotokoll"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Spalten:"
        }), " Spalten sind vertikale Segmente einer Tabelle. Jede Spalte hat einen Typ, der zum Speichern von Typen von Daten verwendet wird. Eine Tabelle kann beliebig viele Spalten enthalten, und jeder Spalte wird bei der Erstellung eine global eindeutige ID zugewiesen. Die Spalten-ID beginnt bei einem Wert von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1"
        }), ", ist jedoch nicht unbedingt sequentiell und kann nicht wiederverwendet werden. Zu den Spaltentypen gehören:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Text"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Rich-Text"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "URL"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Bild"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Auswahl"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Mehrfachauswahl"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Datum"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Datum und Uhrzeit"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Zahl"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Währung"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Kontrollkästchen"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ort (Längengrad, Breitengrad)"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Fremd-ID"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Video"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zellen:"
        }), " In Zellen werden die Werte gespeichert, bei denen sich eine Zeile und eine Spalte überschneiden. Zellen können einzeln oder als Teil einer Zeile gelesen oder aktualisiert werden. Den Wert einer Zelle auf ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " festlegen ist gleichbedeutend mit dem Löschen des Wertes der Zelle."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Technische Beschränkungen von HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beachten Sie die folgenden technischen HubDB-Beschränkungen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Account-Beschränkungen:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1.000 HubDB-Tabellen pro Account"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1 Million HubDB-Zeilen pro Account"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Tabellenbeschränkungen:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "250 Spalten pro Tabelle"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "10.000 Zeilen pro HubDB-Tabelle"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 Zeichen pro Tabellenname"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 Zeichen pro Tabellenlabel"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Spaltenbeschränkungen:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "65.000 Zeichen pro Rich-Text-Spalte"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "10.000 Zeichen in jedem Textspalte"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 Zeichen pro Spaltenname"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 Zeichen pro Label"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "300 Zeichen pro Spaltenbeschreibung"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 Zeichen pro auswählbarer Option innerhalb einer Spalte"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Seitenbeschränkungen:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["10 Aufrufe der ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb_table_rows"
            }), "-HubL-Funktion pro CMS-Seite"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["10 ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "dynamische Seiten"
            }), ", die dieselbe HubDB-Tabelle verwenden"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubDBs mit aktivierten dynamischen Seiten müssen über Pfade in Kleinbuchstaben verfügen., damit bei URLs zu diesen Seiten nicht zwischen Groß- und Kleinschreibung unterschieden muss."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine HubDB-Tabelle erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können HubDB-Tabellen entweder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/website-pages/create-and-populate-tables-in-hubdb",
        children: "über die Benutzeroberfläche von HubSpot"
      }), " oder über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#create-a-new-table",
        children: "HubDB-API"
      }), " erstellen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle neu erstellten Tabellen erhalten den Status eines Entwurfs. Sie können erst dann zur Ausgabe von Daten über HubL oder API verwendet werden, wenn Sie die Tabelle veröffentlichen. Beim Erstellen einer Tabelle können Sie auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "deren Einstellungen verwalten"
      }), ", z. B. öffentlichen API-Zugriff zulassen und ob deren Daten zum ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "Erstellen dynamischer Seiten"
      }), " verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mehrere HubDB-Tabellen verbinden"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB-Tabellen können mit dem Spaltentyp „Fremd-ID“ verbunden werden, mit dem Sie die kombinierten Daten aus mehreren Tabellen rendern können. Dies kann hilfreich sein, wenn einige Daten über mehrere Datenspeicher hinweg gemeinsam genutzt werden können, wodurch eine zentrale Datentabelle dieser Informationen ermöglicht wird, auf die dann über mehrere andere HubDB-Tabellendatenspeicher hinweg zugegriffen werden kann."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über das Verbinden mehrerer HubDB-Tabellen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Eine „Fremd-ID“-Spalte zur Haupttabelle hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dateien und Vorlagen"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Suchen Sie die Tabelle, der Sie eine Tabellenverbindung hinzufügen möchten, klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aktionen"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bearbeiten"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bearbeiten"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Spalte hinzufügen"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie ein ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Label"
        }), " und einen ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " für die neue Spalte ein."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Spaltentyp"
        }), " und wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fremd-ID"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tabelle auswählen"
        }), " und wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tabelle"
        }), " aus, der Sie mit Ihrer aktuellen Tabelle verbinden möchten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Spalte auswählen"
        }), " und wählen Sie dann die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Spalte"
        }), " von der von Ihnen ausgewählten Verbindungstabelle aus, die im Feld „Fremd-ID“ angezeigt werden soll."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Spalte hinzufügen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Der von Ihnen für ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Spalte auswählen"
        }), " gewählte Wert bestimmt nur, welcher Spaltenwert im Feld „Fremd-ID“ in der HubDB-Benutzeroberfläche angezeigt wird. Beim Rendern der verbundenen HubDB-Tabellen stehen alle Tabellenspalten zur Verfügung."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id.png",
        alt: "hubdb_foreign_id"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Fremdtabellenzeilen zu den Zeilen Ihrer Tabelle hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jetzt, da Sie eine ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Fremd-ID"
      }), "-Spalte haben, haben Sie in jeder Zeile in Ihrer HubDB-Tabelle ein Mehrfachauswahl-Spaltenfeld, mit dem Sie die Zeilen einer Fremdtabelle auswählen können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das von Ihnen gewählte Feld ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Spalte auswählen"
      }), " wird in diesem Mehrfachauswahlfeld verwendet, um zu ermitteln, welche Zeile Sie aus der Fremdtabelle auswählen. Im folgenden Beispiel sind die Mehrfachauswahlwerte für das Feld ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Expertise Table Join"
      }), " die Werte, die in der Spalte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Name"
      }), " der fremden HubDB-Tabelle verfügbar sind."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id_ui.png",
        alt: "hubdb_foreign_id_ui"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie können das Feld ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Spalte auswählen"
        }), " Ihrer ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Fremd-ID"
        }), "-Spalte ohne Bedenken bearbeiten. Dabei wird einfach aktualisiert, von welcher Spalte die Werte in der HubDB-Benutzeroberfläche angezeigt werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Ihre verbundenen HubDB-Tabellendaten rendern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sämtliche Zeilendaten einer fremden Tabelle sind über HubL zum Rendern aufrufbar, nicht nur das Feld ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Spalte auswählen"
      }), ". Auf HubDB-Fremdzeilendaten kann mithilfe einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "verschachtelten for-Schleife"
      }), " zugegriffen werden, die alle Fremdzeilen durchläuft, die einer einzelnen Zeile zugeordnet sind."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(tableId, filterQuery) %}\n  the name for row {{ row.hs_id }} is {{ row.name }}\n  {% for foreign_row in row.foreign_table %}\n  \tthe name for foreign row {{ foreign_row.hs_id }} is {{ foreign_row.name }}\n  {% endfor %}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB-Daten mithilfe von HubL aufrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mithilfe von HubL können Sie HubDB-Daten abrufen, um sie als strukturierte Inhalte auf Website-Seiten zu verwenden. Im Folgenden erfahren Sie mehr über das Abrufen von Tabellen-, Zeilen- und Spaltendaten mithilfe von HubL."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Entworfene HubDB-Tabellendaten werden im Seiteneditor und in Live-Vorschauen angezeigt, aber nur veröffentlichte HubDB-Inhalte werden auf der Live-Seite angezeigt. Wenn Tabellendaten im Editor oder in der Vorschau angezeigt werden, die nicht auf der Live-Seite angezeigt werden, überprüfen Sie, ob die Tabelle seit dem Hinzufügen dieser Tabellendaten veröffentlicht wurde."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abrufen von Zeilen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Zeilen einer Tabelle aufzulisten, verwenden Sie die HubL-Funktion ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: "hubdb_table_rows()"
      }), ". Sie können auf eine Tabelle entweder über ihre ID oder ihren Namen zugreifen. Es wird empfohlen, eine HubDB-Tabelle nach ihrem Namen zu referenzieren, da dies die Übertragbarkeit des Codes über verschiedene HubSpot-Accounts hinweg erleichtern kann. Der unveränderliche Tabellenname wird bei der Erstellung einer neuen Tabelle festgelegt und kann jederzeit durch Auswahl von ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Aktionen > Einstellungen verwalten"
      }), " im Tabellen-Editor gefunden werden. Die ID einer Tabelle finden Sie in der Adressleiste des Tabellen-Editors oder im HubDB-Tabellen-Dashboard in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ID"
      }), "-Spalte."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Table%20Create.png",
        alt: "Screenshot des Modalfensters „Tabelle erstellen“"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachfolgend finden Sie ein Beispiel für die Verwendung von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows()"
      }), " zum Abrufen von Daten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(<tableId or name>, <filterQuery>) %}\n  the value for row {{ row.hs_id }} is {{ row.<column name> }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Standardmäßig beträgt die maximale Anzahl der zurückgegebenen Zeilen 1.000. Um weitere Zeilen abzurufen, geben Sie mit ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), " eine Beschränkung in der Funktionsabfrage an. Zum Beispiel:"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hudb_table_rows (12345, \"random()&limit=1500\")"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), " verwendet die gleiche Syntax wie die HTTP-API. Zum Beispiel ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows(123, „mitarbeiter__gt=10&orderBy=count\")"
      }), " würde eine Liste der Zeilen zurückgeben, in denen die Spalte „Mitarbeiter“ größer als 10 ist, geordnet nach der Spalte „Anzahl“. Eine vollständige Liste der optionalen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), "-Parameter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "finden Sie hier"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anstatt mehrere Zeilenabfragen mit unterschiedlichen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), "-Parametern zu verwenden, sollten Sie eine einzige Abfrage erstellen und die Filter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "selectattr()"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rejectattr()"
      }), " verwenden, um Ihre Zeilen zu filtern:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set all_cars = hubdb_table_rows(<tableId or name>) %}\n\n{% set cars_with_windows = all_cars|selectattr('windows') %}\n\n{% set teslas = all_cars|selectattr('make','equalto','tesla') %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine einzelne Zeile abzurufen, verwenden Sie die HubL-Funktion ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_row()"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set row = hubdb_table_row(<tableId or name>, <rowId>) %}\nthe value for {{ row.hs_id }} is {{ row.<column name> }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei integrierten und benutzerdefinierten Spaltennamen wird nicht zwischen Groß- und Kleinschreibung unterschieden. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HS_ID"
      }), " funktioniert dabei genauso ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_id"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Zeilenattribute"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Attribut"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die global eindeutige ID für diese Zeile."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bei dynamischen Seiten ist diese Zeichenfolge der Spaltenwert „Seitenpfad“ und das letzte Segment des Pfades der URL."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bei der Verwendung dynamischer Seiten ist diese Zeichenfolge der Wert der Spalte „Seitentitel“ für die Zeile."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unix-Zeitstempel für den Zeitpunkt, zu dem die Zeile erstellt wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_child_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bei der Verwendung dynamischer Seiten ist dies die ID der anderen Tabelle, die die Daten für die Zeile auffüllt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.column_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ruft den Wert der benutzerdefinierten Spalte anhand des Namens der Spalte ab."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row[\"column name\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ruft den Wert der benutzerdefinierten Spalte anhand des Namens der Spalte ab."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Metadaten von Tabellen abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die Metadaten einer Tabelle zu erhalten, einschließlich ihres Namens, ihrer Spalten, der letzten Aktualisierung usw., verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table()"
      }), "-Funktion."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table(<tableId or name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "table-Attribute"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Die unten aufgeführten Attribute beziehen sich auf die Variable, der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table()"
        }), " im obigen Code zugewiesen wurde. Ihre Variable kann abweichen.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["Hinweis: Es wird empfohlen, dies zur einfacheren Verwendung einer Variablen zuzuweisen. Wenn Sie das nicht tun möchten, können Sie auch folgenden Code verwenden:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table(<tableId>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Attribut"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID der Tabelle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name der Tabelle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.columns"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Liste von Spalteninformationen. Sie können eine ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/loops",
              children: "for-Schleife"
            }), " verwenden, um die in diesem Attribut verfügbaren Informationen zu durchsuchen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeitstempel des Zeitpunkts, zu dem die Tabelle erstmals erstellt wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.published_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeitstempel des Zeitpunkts, zu dem diese Tabelle veröffentlicht wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.updated_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeitstempel des Zeitpunkts, zu dem diese Tabelle zuletzt aktualisiert wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.row_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anzahl der Zeilen in der Tabelle."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Spaltenmetadaten abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table_column(<tableId or name>, <columnId or column name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Informationen zu einer Spalte in einer Tabelle abzurufen, z. B. ihr Label, ihren Typ und ihre Optionen, verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_column()"
      }), "-Funktion."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "column-Attribute"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Die unten aufgeführten Attribute beziehen sich auf die Variable, der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_column()"
        }), " im obigen Code zugewiesen wurde. Ihre Variable kann abweichen.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["Hinweis: Es wird empfohlen, dies zur einfacheren Verwendung einer Variablen zuzuweisen. Wenn Sie das nicht tun möchten, können Sie auch folgenden Code verwenden:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table_column(<tableId>,<columnId or column name>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Attribut"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID der Spalte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name der Spalte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das für die Spalte zu verwendende Label."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Typ dieser Spalte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Für den select-Spaltentyp ist dies eine Zuordnung von ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "optionId"
            }), " zu ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), "-Informationen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.foreignIds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Bei foreignId-Spaltentypen ist dies eine Liste von foreignIds (mit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "-Eigenschaften)."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Spaltenmethoden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Methode"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "getOptionByName(\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rufen Sie bei select-Spaltentypen Optionsinformationen über den Optionsnamen ab."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Rich-Text-Spalten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "richtext"
      }), "-Spaltentyp funktioniert ähnlich wie das Rich-Text-Feld für Module."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Daten werden als HTML gespeichert, und die HubDB-UI bietet eine Textbearbeitungsoberfläche. Wenn Sie jedoch eine HubDB-Tabelle über die Benutzeroberfläche von HubSpot bearbeiten, können Sie den Quellcode nicht direkt bearbeiten. Dies verhindert Situationen, in denen ein technisch nicht versierter Benutzer ungültige HTML-Daten eingeben könnte, und beugt so unbeabsichtigten Problemen mit dem Erscheinungsbild oder der Funktionalität Ihrer Website vor. Für Situationen, in denen Sie einen Einbettungscode oder mehr benutzerdefinierten HTML-Code benötigen, können Sie die Einbettungsfunktion im Rich-Text-Editor verwenden, um Ihren benutzerdefinierten Code zu platzieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB-Tutorials und -Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "App für die Eventregistrierung"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Erstellen einer dynamischen Seite für Teammitglieder mithilfe von HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "Hinzufügen von Videos zu dynamischen Seiten"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Erstellen von dynamischen Seiten mit mehreren Ebenen mithilfe von HubDB"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}