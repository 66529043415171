"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529651;
const slug = exports.slug = 'reference/cms/serverless-functions';
const title = exports.title = 'Référence relative aux fonctions sans serveur';
const name = exports.name = 'EMEA (FR) Serverless Reference';
const metaDescription = exports.metaDescription = "Profitez d'informations de référence pour votre serverless.json, vos fichiers de fonction, vos points de terminaison, vos commandes d'ILC et la gestion des packs, le tout au même endroit.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "serverless.json",
  "label": "Serverless.json",
  "parent": null
}, {
  "depth": 1,
  "id": "points-de-terminaison",
  "label": "Points de terminaison",
  "parent": "serverless.json"
}, {
  "depth": 0,
  "id": "fichier-de-fonction",
  "label": "Fichier de fonction",
  "parent": null
}, {
  "depth": 1,
  "id": "objet-contextuel",
  "label": "Objet contextuel",
  "parent": "fichier-de-fonction"
}, {
  "depth": 1,
  "id": "en-t%C3%AAtes",
  "label": "En-têtes",
  "parent": "fichier-de-fonction"
}, {
  "depth": 2,
  "id": "rediriger-en-envoyant-un-en-t%C3%AAte",
  "label": "Rediriger en envoyant un en-tête",
  "parent": "fichier-de-fonction"
}, {
  "depth": 1,
  "id": "d%C3%A9finir-des-cookies-%C3%A0-partir-de-votre-point-de-terminaison",
  "label": "Définir des cookies à partir de votre point de terminaison",
  "parent": "fichier-de-fonction"
}, {
  "depth": 1,
  "id": "d%C3%A9finir-plusieurs-valeurs-pour-un-seul-en-t%C3%AAte",
  "label": "Définir plusieurs valeurs pour un seul en-tête",
  "parent": "fichier-de-fonction"
}, {
  "depth": 0,
  "id": "secrets",
  "label": "Secrets",
  "parent": null
}, {
  "depth": 0,
  "id": "utilisation-de-fonctions-sans-serveur-avec-l-%C3%A9l%C3%A9ment-de-formulaire",
  "label": "Utilisation de fonctions sans serveur avec l'élément de formulaire",
  "parent": null
}, {
  "depth": 0,
  "id": "cors",
  "label": "CORS",
  "parent": null
}, {
  "depth": 1,
  "id": "demandes-get",
  "label": "Demandes GET",
  "parent": "cors"
}, {
  "depth": 0,
  "id": "packs-pr%C3%A9charg%C3%A9s",
  "label": "Packs préchargés",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-d-utilisation",
  "label": "Limites d'utilisation",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      h4: "h4",
      em: "em",
      ul: "ul",
      li: "li",
      br: "br",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Référence relative aux fonctions sans serveur"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si vous créez une fonction sans serveur dans le cadre d'un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "projet de développement"
        }), ", consultez plutôt la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "documentation relative aux fonctions sans serveur dans les projets de développement"
        }), ". La documentation ci-dessous concerne la création de fonctions sans serveur en dehors de la plate-forme de projets de développement."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans cet article, découvrez les fichiers contenus dans un ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: ["dossier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        }), " sans serveur"]
      }), " ainsi que les commandes d'ILC que vous pouvez utiliser avec les fonctions sans serveur."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour un aperçu de haut niveau des fonctions sans serveur, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "présentation des fonctions sans serveur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour plus d'informations sur la création de fonctions sans serveur avec des projets pour les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "modules et partials rendus JavaScript"
      }), ", consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions",
        children: "documentation relative aux projets de développement"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Serverless.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: ["dossier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        })]
      }), ", le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " stocke la configuration de la fonction sans serveur. Ce fichier est obligatoire et mappe vos fonctions à leurs ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#endpoints",
        children: "points de terminaison"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// serverless.json\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"environment\": {\n    \"globalConfigKey\": \"some-value\"\n  },\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"events\": {\n      \"file\": \"function1.js\",\n      \"method\": \"GET\"\n    },\n    \"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"CONFIG_KEY\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleKeyName\", \"otherkeyname\"]\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "clé"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "runtime"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'environnement d'exécution. Prend en charge les versions de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/en/about/",
              children: "Node.js"
            }), " suivantes :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Node 20 (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs20.x"
                }), ") (recommandé)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Node 18 (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs18.x"
                }), ")"]
              })]
            }), "Notez que HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/changelog/deprecation-of-node-v16-in-all-serverless-functions",
              children: "ne prendra plus en charge le Node 16"
            }), " après le 12 juillet 2024."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Version du schéma de la fonction sans serveur HubSpot. (Version actuelle 1.0)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "environment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Variables de configuration transmises à la fonction d'exécution en tant que ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
              children: "variables d'environnement"
            }), " au moment de l'exécution. Vous pouvez utiliser cela pour ajouter une logique permettant d'utiliser une version de test d'une API au lieu de la véritable API, en fonction d'une variable d'environnement."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secrets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un tableau contenant les noms des secrets que votre fonction sans serveur utilisera à des fins d'authentification. Ne stockez pas les secrets directement dans ce fichier. Indiquez seulement leurs noms."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoints"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les points de terminaison définissent les chemins qui sont exposés et leur correspondance avec des fichiers JavaScript spécifiques dans votre dossier de fonctions. Découvrez-en davantage sur les points de terminaison ci-dessous."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " N'attribuez pas le même nom à vos secrets et à vos variables d'environnement. Cela entraînera des conflits lors du renvoi de leurs valeurs dans la fonction."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Points de terminaison"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque point de terminaison peut avoir ses propres ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "variables d'environnement"
      }), " et secrets. Les variables spécifiées en dehors des points de terminaison doivent être utilisées pour les paramètres de configuration qui s'appliquent à toutes les fonctions et à tous les points de terminaison."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"configKey\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleAPIKeyName\",\"otherKeyName\"]\n    }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les points de terminaison ont deux clés uniques."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "clé"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "method"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne ou tableau de chaînes"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Methods",
              children: "La ou les méthodes HTTP"
            }), " que le point de terminaison prend en charge. La valeur par défaut est ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Methods/GET",
              children: "GET"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chemin d'accès au fichier de fonction JavaScript avec l'implémentation pour le point de terminaison."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions sans serveur sont exposées via un chemin d'accès au domaine de votre compte CMS Hub. Cela inclut les sous-domaines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-sites.com"
      }), " par défaut."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez accéder à ces fonctions à l'URL suivante :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "https://{domainName}/_hcms/api/{endpoint-name/path}?portalid={hubId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ci-dessous, découvrez chaque composant de l'URL :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "domainName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom de votre domaine."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/_hcms/api/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le chemin d'accès réservé aux fonctions sans serveur. Tous les points de terminaison se trouvent à l'intérieur de ce chemin."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoint-name/path"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le nom ou le chemin d'accès du point de terminaison que vous avez spécifié dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Votre HubID. En fournissant ces informations dans la demande, vous pourrez tester vos fonctions dans les aperçus de modules et de modèles."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fichier de fonction"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Outre le fichier de configuration ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), ", le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " contiendra également un fichier JavaScript ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " qui définit la fonction. Vous pouvez également utiliser la bibliothèque de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/request/request#readme",
        children: "requêtes"
      }), " pour effectuer une requête HTTP vers les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "API de HubSpot"
      }), " et d'autres API."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Require axios library, to make API requests.\nconst axios = require('axios');\n// Environment variables from your serverless.json\n// process.env.globalConfigKey\n\nexports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n\n  // context.params\n  // context.body\n  // context.accountId\n  // context.limits\n\n  // secrets created using the CLI are available in the environment variables.\n  // process.env.secretName\n\n  //sendResponse is what you will send back to services hitting your serverless function.\n  sendResponse({ body: { message: 'my response' }, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objet contextuel"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'objet contextuel contient des informations contextuelles sur l'exécution de la fonction, stockées dans les paramètres suivants."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accountId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID du compte HubSpot contenant la fonction."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si la demande est envoyée comme une demande ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " avec du contenu de type ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "application/json"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si la demande provient d'un contact intégré dans des cookies, l'objet de contact sera renseigné avec un ensemble de propriétés de contact de base, ainsi que les informations suivantes :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), " : l'ID visiteur du contact."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "isLoggedIn"
                }), " : lorsque vous utilisez le contrôle de l'accès au CMS, la valeur sera ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " si le contact est connecté au domaine."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "listMemberships"
                }), " : un tableau d'ID de listes de contacts dont le contact est membre."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "headers"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contient les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#headers",
              children: "en-têtes"
            }), " envoyés par le client qui atteint votre point de terminaison."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "params"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Renseigné avec les valeurs de la chaîne de requête ainsi que les valeurs HTML Form-POST. Celles-ci sont structurées comme une carte avec des chaînes de caractères comme clés et un tableau de chaînes de caractères pour chaque valeur.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "context.params.yourvalue"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limits"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Renvoie le degré de rapprochement quant aux ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview#know-your-limits",
              children: "limites associées aux fonctions sans serveur"
            }), ".", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "executionsRemaining"
                }), " : nombre d'exécutions par minute restantes."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "timeRemaining"
                }), " : temps d'exécution autorisé restant."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "En-têtes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous avez besoin de connaître les en-têtes du client qui atteint votre point de terminaison, vous pouvez y accéder via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.headers"
      }), ", de la même manière que vous accéderiez aux informations via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.body"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez ci-dessous certains des en-têtes courants fournis par HubSpot. Pour obtenir la liste complète, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/fr/docs/Web/HTTP/Headers",
        children: "documentation des en-têtes HTTP de MDN"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "En-tête"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indique quels types de contenu, exprimés en ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Basics_of_HTTP/MIME_types",
              children: "types MIME"
            }), ", le client comprend.", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Headers/Accept",
              children: "Consultez MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-encoding"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Communique le codage du contenu que le client comprend. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Headers/Accept",
              children: "Consultez MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indique la langue et les paramètres régionaux préférés. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Headers/Accept",
              children: "Consultez MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cache-control"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contient des directives pour la mise en cache. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Headers/Accept",
              children: "Consultez MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "connection"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indique si la connexion réseau reste ouverte. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Headers/Accept",
              children: "Consultez MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cookie"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contient les cookies envoyés par le client. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Headers/Accept",
              children: "Consultez MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Communique le nom de domaine et le numéro de port TCP d'un serveur en écoute. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Headers/Accept",
              children: "Consultez MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true-client-ip"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Adresse IP de l'utilisateur final. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/206776727-What-is-True-Client-IP-",
              children: "Consultez Cloudflare true-client-ip"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "upgrade-insecure-requests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Communique la préférence du client pour une réponse chiffrée et authentifiée. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Headers/Accept",
              children: "Consultez MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "user-agent"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Chaîne définie par le fournisseur identifiant l'application, le système d'exploitation, le fournisseur de l'application et la version. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Headers/Accept",
              children: "Consultez MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "x-forwarded-for"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Identifie l'adresse IP d'origine d'un client via un proxy ou un équilibreur de charge. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Headers/Accept",
              children: "Consultez MDN"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Rediriger en envoyant un en-tête"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez effectuer une redirection depuis votre fonction sans serveur en envoyant une réponse avec un en-tête de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "localisation"
      }), " et un code de statut ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "301"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "sendResponse({\n  statusCode: 301,\n  headers: {\n    Location: 'https://www.example.com',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir des cookies à partir de votre point de terminaison"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depuis votre fonction sans serveur, vous pouvez indiquer au client (navigateur web) de définir un cookie."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n    sendResponse({\n      body: { ... },\n      'Set-Cookie': 'myCookie1=12345; expires=...; Max-Age=...',\n      statusCode: 200\n    });\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir plusieurs valeurs pour un seul en-tête"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les en-têtes qui prennent en charge plusieurs valeurs, vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multiValueHeaders"
      }), " pour transmettre les valeurs. Par exemple, vous pouvez demander au navigateur d'installer plusieurs cookies."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  sendResponse({\n    body: { ... },\n    multiValueHeaders: {\n      'Set-Cookie': [\n        'myCookie1=12345; expires=...; Max-Age=...',\n        'myCookie2=56789; expires=...; Max-Age=...'\n       ]\n    },\n    statusCode: 200\n  });\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secrets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous devez authentifier une demande de fonction sans serveur, vous utiliserez des secrets pour stocker des valeurs telles que des clés d'API ou des jetons d'accès à des applications privées. À l'aide de la CLI, vous pouvez ajouter des secrets à votre compte HubSpot pour stocker ces valeurs, auxquelles vous pourrez accéder ultérieurement via des variables d'environnement (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "process.env.secretName"
      }), "). Les secrets sont gérés via l'ILC de HubSpot à l'aide des commandes suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#list-secrets",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets list"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#add-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets add"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#remove-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets delete"
          })
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois ajoutés par le biais de la CLI, les secrets peuvent être disponibles pour des fonctions en incluant un tableau de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), " contenant le nom du secret. Cela vous permet de stocker votre code de fonction dans le système de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "contrôle de version"
      }), " et d'utiliser des secrets sans les diffuser. Cependant, vous ne devez ", (0, _jsxRuntime.jsx)("u", {
        children: "jamais"
      }), " renvoyer la valeur de votre secret par le biais du journal de la console ou comme réponse, sous peine de diffuser le secret dans les journaux ou dans les interfaces qui exécutent votre fonction sans serveur."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " En raison de la mise en cache, il peut s'écouler environ une minute pour que les valeurs secrètes se mettent à jour. Si vous venez de mettre à jour un secret mais que vous voyez toujours l'ancienne valeur, vérifiez à nouveau au bout d'une minute environ."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation de fonctions sans serveur avec l'élément de formulaire"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la soumission des fonctions sans serveur, utilisez JavaScript pour gérer la soumission du formulaire et utilisez l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"contentType\" : \"application/json\""
      }), " dans votre requête. N'utilisez pas l'attribut ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "action"
      }), " des éléments ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<form>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CORS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/CORS",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Le partage des ressources entre origines multiples (CORS)"
        })
      }), " est une fonction de sécurité du navigateur. Par défaut, les navigateurs limitent les demandes d'origines multiples initiées par JavaScript. Cela permet d'éviter qu'un code malveillant exécuté sur un autre domaine n'affecte votre site. Il s'agit de la règle d'origine unique. Comme l'envoi et la récupération de données à partir d'autres serveurs sont parfois nécessaires, le serveur externe peut fournir des en-têtes HTTP qui indiquent les origines autorisées à lire les informations dans un navigateur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous ne devriez pas rencontrer de problèmes CORS en appelant votre fonction sans serveur dans vos pages hébergées par HubSpot. Si c'est le cas, vérifiez que vous utilisez le bon protocole."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vous rencontrez cette erreur CORS ?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"Access to fetch at [your function url] from origin [page making request] has been blocked by CORS policy: Response to preflight request doesn't pass access control check: No 'Access-Control-Allow-Origin' header is present on the requested resource. If an opaque response serves your needs, set the request's mode to 'no-cors' to fetch the resource with CORS disabled.\""
        })]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Votre demande a-t-elle une origine différente de celle du site qui l'appelle ?"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Si le nom de domaine est différent, oui."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Si vous utilisez un protocole différent (http, https), oui."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Si vous utilisez un protocole différent, il suffit de modifier le protocole en conséquence et le problème sera résolu."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour l'instant, vous ne pouvez pas modifier l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Access-Control-Allow-Origin"
        }), " de HubSpot."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/CORS/Errors",
          children: "Consultez MDN pour obtenir des informations plus détaillées sur la résolution des erreurs CORS."
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Demandes GET"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les demandes GET peuvent effectuer des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/CORS",
        children: "demandes CORS"
      }), " selon le client. Les requêtes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/fr-FR/docs/Web/HTTP/Methods/GET",
        children: "GET"
      }), " n'écrivent rien, elles ne font que renvoyer des données."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Packs préchargés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les fonctions sans serveur de HubSpot sont actuellement préchargées avec les packs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/@hubspot/api-client",
          children: "@hubspot/api-client"
        }), " : ^1.0.0-beta"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), " : ^0.19.2"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/request",
          children: "request"
        }), " : ^2.88.0"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/requests",
          children: "requests"
        }), " : ^0.2.2"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour utiliser la dernière version prise en charge d'un pack préchargé ou pour utiliser un pack nouvellement ajouté :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Clonez ou copiez votre fichier de fonction."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Modifiez le point de terminaison de votre fonction dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " pour qu'il pointe vers votre nouveau fichier de fonction. Vous pouvez supprimer l'ancienne version en toute sécurité."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez inclure des packs en dehors de l'ensemble de packs préchargés, vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), " pour combiner vos modules de nœud et faire en sorte que vos fichiers groupés soient vos fichiers de fonction."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites d'utilisation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les fonctions sans serveur sont destinées à être rapides avec un objectif restreint. Pour permettre des appels et des réponses rapides, les fonctions sans serveur de HubSpot sont soumises aux limites suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "50 secrets par compte."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128 Mo de mémoire."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pas plus de 100 points de terminaison par compte HubSpot."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Du ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contenu de type"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), " doit être utilisé lors de l'appel d'une fonction."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les journaux des fonctions sans serveur sont conservés pendant 90 jours."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6 Mo sur une charge utile d'invocation AWS Lambda."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Limites d'exécution"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Chaque fonction a un temps d'exécution maximum de 10 secondes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Chaque compte est limité à 600 secondes d'exécution totale par minute."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cela signifie que l'une ou l'autre de ces situations peut se produire :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "60 exécutions de fonctions qui prennent chacune 10 secondes à être terminées."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6 000 exécutions de fonctions qui prennent 100 millisecondes à être terminées."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions qui dépassent ces limites de temps envoient un message d'erreur. Le nombre d'exécutions et les limites de temps renverront une réponse ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), ". Le temps d'exécution de chaque fonction est inclus dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "journaux des fonctions sans serveur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour éviter d'atteindre ces limites, les données relatives aux limites sont fournies automatiquement selon le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#function-file",
        children: "contexte de la fonction"
      }), " pendant l'exécution. Vous pouvez vous en servir pour influencer votre demande afin de respecter ces limites. Par exemple, si votre application exige que votre point de terminaison soit interrogé, vous pouvez renvoyer avec vos données une variable pour influencer la fréquence de l'interrogation. Ainsi, lorsque le trafic est élevé, vous pouvez ralentir le taux d'interrogation pour éviter de dépasser les limites, puis le réaugmenter lorsque le trafic est faible."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}