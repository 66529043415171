import noAuthClient from 'hub-http/clients/noAuthApiClient';
import { FETCH_ALL_SPECS_FAILED, FETCH_ALL_SPECS_STARTED, FETCH_ALL_SPECS_SUCCEEDED } from './ActionTypes';
import { SPEC_API_BASE } from './FetchPublicApiAction';
import { getRequestOptionsForSpecCall } from './helpers';
const fetchAllSpecsStarted = {
  type: FETCH_ALL_SPECS_STARTED
};
const fetchAllSpecsSucceeded = data => ({
  type: FETCH_ALL_SPECS_SUCCEEDED,
  payload: {
    data
  }
});
const fetchAllSpecsFailed = error => ({
  type: FETCH_ALL_SPECS_FAILED,
  payload: {
    error
  }
});
export const fetchAllAvailableSpecs = () => dispatch => {
  dispatch(fetchAllSpecsStarted);
  return noAuthClient.get(`${SPEC_API_BASE}/v1/specs`, getRequestOptionsForSpecCall()).then(data => {
    return dispatch(fetchAllSpecsSucceeded(data));
  }).catch(error => {
    return dispatch(fetchAllSpecsFailed(error));
  });
};