"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 115766035856;
const slug = exports.slug = 'guides/crm/private-apps/quickstart';
const title = exports.title = 'UI extensions for private apps quickstart (BETA)';
const name = exports.name = 'UI extensions for private apps quickstart (BETA)';
const metaDescription = exports.metaDescription = 'Get started developing UI extensions for a private app.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "prerequisites",
  "label": "Prerequisites",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-set-up-your-local-environment",
  "label": "1. Set up your local environment",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-create-a-new-project",
  "label": "2. Create a new project",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-start-local-development",
  "label": "3. Start local development",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-view-the-extension-in-hubspot",
  "label": "4. View the extension in HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-add-a-new-component-to-the-card",
  "label": "5. Add a new component to the card",
  "parent": null
}, {
  "depth": 0,
  "id": "next-steps",
  "label": "Next steps",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "UI extensions for private apps quickstart (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise', 'marketing_hub-enterprise', 'cms-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This quickstart guide walks through setting up HubSpot's example UI extension, which includes a card for contact records. This card sends data from the React front end to the serverless function back end, then displays that data in a success alert. You’ll then customize the card by adding a new component. By the end of this guide, you'll be familiar with the basic processes of developing UI extensions locally with React."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " building UI extensions for private apps in a standard HubSpot account requires an ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " subscription. However, you can try out building private apps using projects in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "developer test accounts"
        }), " for free."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prerequisites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before proceeding, ensure that you've ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/overview#get-started",
        children: "opted in to the CRM development tools beta"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This guides assumes that you're already familiar with:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The basics of React and JavaScript (or optionally Typescript)"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI for local development"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Set up your local environment"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Because UI extensions are developed locally, you'll first need to set up your environment:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/download/",
          children: "Node.js"
        }), " which enables HubSpot’s local development tools. Versions 18 or higher are supported. It's recommended to use a package manager like ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://brew.sh/",
          children: "Homebrew"
        }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/nvm-sh/nvm",
          children: "nvm"
        }), " to install Node."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Navigate to the directory where you'll be storing your project, app, and extension files."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install -g @hubspot/cli@latest"
        }), " to update the HubSpot CLI to the latest version."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs init"
        }), ", then follow the prompts to initialize the HubSpot configuration file to connect the CLI to your HubSpot account."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After setting up your environment, proceed to the next steps where you'll create your project."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Create a new project"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Create a new project in your working directory."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project create\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After running the command, you'll be prompted to name the new project, select its location, then select a template. For this guide, select the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CRM getting started project"
        }), ". A new directory will then be created using the project name you assigned."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate into the new directory by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cd <project-directory>"
        }), ", then run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install"
        }), " to load the dependencies required to start local development server."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd <project-template-directory>\nnpm install\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["This sample project automatically includes some of the local dependencies specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "package.json"
          }), " file. If you're modifying these dependencies in other projects, you may need to run ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "npm install"
          }), " in both the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "src/app/extensions"
          }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "src/app/app.functions"
          }), " directories."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["This sample project uses a free API which doesn't require any secret handling. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/private-apps/serverless-functions#managing-secrets",
            children: "how to include secrets"
          }), " so that they're accessible during local development and when deployed."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Start local development"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To start local development, you'll use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project dev"
      }), " command. This command will walk you through setting up a development sandbox and allow you to test changes locally before uploading to your development sandbox or production account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project dev\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project dev"
        }), ", select the account you want to work in:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To create your extension in an existing sandbox, use the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "arrow keys"
            }), " to select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "sandbox"
            }), ", then press ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Enter"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To create and test your extension in a new development sandbox, select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "< Test on a new development sandbox >"
            }), ". Then, name the sandbox and press ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Enter"
            }), ". HubSpot will then create the new development sandbox in the production account. This sandbox will sync with the production account's data, including CRM object definitions and up to 100 of the most recently created contacts and their associated deals, tickets, and companies (up to 100 each)."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you receive the error ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "The personal access key you provided doesn't include sandbox permissions"
        }), ", you'll need to deactivate the account's Personal Access Key, then create a new one with sandbox permissions. To do so, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs auth"
        }), ", then follow the prompts to select your account. Then, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Deactivate"
        }), " next to the personal access key, and generate a new one with the proper scopes."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To create and test your extension in the production account, select ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "< ! Test on this production account ! >"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "If a project has multiple extensions, you'll be prompted to select which extension to run. You can run multiple extensions from the same app, but not multiple extensions across multiple apps."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once the project is created, built, and deployed in the selected account, the local development server will start and you can begin building and modifying your extension."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The browser will automatically refresh to pick up the latest saved front end code. This includes changes made to React files and serverless functions. Note that changes to serverless functions will be picked up at invocation time."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Changes made to configuration files, such as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "app.json"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsproject.json"
        }), ", require a manual upload before you can continue development. To upload those changes, first stop the local development server with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "q"
        }), ", then run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload"
        }), ". After your changes are uploaded, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project dev"
        }), " again to restart the server."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. View the extension in HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the local development server running, you can add the card to the contact record view, then view the card:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Log in to your HubSpot account."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Contacts"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Contacts"
          }), ". Then, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of a contact to view its record."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["At the top of the contact record, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Customize tabs"
          }), ". A new tab will open showing the record editor sidebar."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/crm-recrd-customize-tabs.png",
            alt: "crm-recrd-customize-tabs"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right sidebar, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Default view"
          }), " to edit the default contact record view."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["For the purposes of this tutorial, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "+ plus icon tab"
          }), " at the top of the editor to add a new tab."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/crm-record-page-editor-add-tab.png",
            alt: "crm-record-page-editor-add-tab"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " for your new tab, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Done"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["With the new tab added, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Add cards"
          }), " dropdown menu, then select your ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "new card"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Save and exit"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Navigate back to the contact record, then refresh the page. You should now see your new tab, which will contain your new card. With the local development server running, you'll see a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Developing locally"
          }), " tag displayed at the top of the card."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-ext-card-quickstart-result.png",
        alt: "ui-ext-card-quickstart-result"
      }), "Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/customize-the-record-middle-column",
        children: "customizing record views on HubSpot's Knowledge Base"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Add a new component to the card"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With the card uploaded and local development server running, you'll now add a new ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview",
        children: "UI component"
      }), " to the card and refresh the page to see your changes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because the React file serves as the extension's front end, you'll find the UI extension components in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Example.jsx"
      }), " file in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "app/extensions"
      }), " directory. For the purposes of this guide, you'll add a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Link"
      }), " component to link out to the UI extension components reference document."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["First, add ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Link"
        }), " to the import at the top of the file to make the component available for use."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import {\n  Tile,\n  Button,\n  Text,\n  Input,\n  Stack,\n  hubspot,\n  Link,\n} from '@hubspot/ui-extensions';\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Then add the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Link"
        }), " component beneath the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Button"
        }), " component."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "return (\n    <>\n      <Text>\n        <Text format={{ fontWeight: 'bold' }}>\n          Your first UI Extension is ready!\n        </Text>\n        Congratulations {context.user.firstName}! You just deployed your first\n        HubSpot UI extension. This example demonstrates how you would send\n        parameters from your React frontned to the serverless function and get\n        response back.\n      </Text>\n        <Input\n          name=\"text\"\n          label=\"Send to serverless\"\n          onInput={(t) => setText(t)}\n        />\n        <Button type=\"submit\" onClick={run}>\n          Click me\n        </Button>\n        <Link\n         href=\"https://developers.hubspot.com/docs/platform/ui-extension-components\">\n         See all UI extension components\n        </Link>\n    &lt;/>\n  );\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Save your local changes. This will trigger the local development server to update and reload the extension."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The CRM record page should now show the new component without requiring a refresh."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-ext-card-quickstart-step-5.png",
        alt: "ui-ext-card-quickstart-step-5"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Next steps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now that you're familiar with the basics of creating, uploading, and updating a UI extension, you can continue customizing the example card with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview",
        children: "other UI components"
      }), ", updating the serverless function to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview#fetch-hubspot-crm-data",
        children: "fetch different data"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "create a new card from scratch"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}