"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 52096466550;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/sections';
const title = exports.title = 'セクション';
const name = exports.name = 'APAC JAPAN (ja) | Sections_JA | 202109';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%86%8D%E5%88%A9%E7%94%A8%E5%8F%AF%E8%83%BD%E3%81%AA%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "再利用可能なセクション",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
  "label": "セクションテンプレート",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC",
  "label": "セクションのプレビュー",
  "parent": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88"
}, {
  "depth": 1,
  "id": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%B3%E3%83%B3%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88",
  "label": "セクションコンテキスト",
  "parent": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88"
}, {
  "depth": 1,
  "id": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%AF%E3%83%A9%E3%82%B9",
  "label": "セクションクラス",
  "parent": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88"
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "関連コンテンツ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      code: "code",
      pre: "pre",
      h2: "h2",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "セクション"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "ドラッグ＆ドロップエリア"
      }), "の最も外側にあるコンテナはセクションと呼ばれます。セクションは他の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#drag-and-drop-areas-and-their-elements",
        children: "dnd"
      }), "要素内にネスト（入れ子構造に）することはできませんが、モジュール、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#row",
        children: "行"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#column",
        children: "列"
      }), "を含めることができます。制作担当者はページエディター上でセクションを変更し、ドラッグ＆ドロップで操作できます。セクションには、背景を使用したスタイルや、パディングを設定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/section%20in%20cms%20page%20editor.png",
        alt: "cmsページエディターでのセクションのスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["セクションは制作担当者がコンテンツエディターで作成するか、または開発者が", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/hubl/tags/dnd-areas#drag-and-drop-section-code-dnd-section-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        })
      }), "タグを使用して", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/drag-and-drop-areas",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "に構築することができます。エディター内で使用できるスタイル設定オプションは、テンプレートのコーディング時にも使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<main class=\"body-container-wrapper\">\n  {% dnd_area 'dnd_area'\n    label='Main section',\n  %}\n    {% dnd_section\n      background_image={\n        'backgroundPosition': 'MIDDLE_CENTER',\n        'backgroundSize': 'cover',\n        'imageUrl': 'https://example.com/path/to/image.jpg'\n      },\n      margin={\n        'top': 32,\n        'bottom': 32\n      },\n      padding={\n        'top': '1em',\n        'bottom': '1em',\n        'left': '1em',\n        'right': '1em'\n      },\n      max_width=1200,\n      vertical_alignment='MIDDLE'\n    %}\n\n    {% end_dnd_section %}\n\n  {% end_dnd_area %}\n</main>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["利用可能な全てのドラッグ＆ドロップ要素パラメーターと使用例の一覧については、", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/hubl/tags/dnd-areas",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), "タグ"]
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "再利用可能なセクション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["事前設定済みの再利用可能なセクションを作成できます。制作担当者はページエディター上で、このセクションをページ内のドラッグ&ドロップエリアにドラッグ＆ドロップできます。このような事前設定済みのセクションのコーディングには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "内と同じ構文を使用しますが、セクションテンプレートと呼ばれる専用のファイルに格納します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "通常のドラッグ＆ドロップセクションで制御できる全ての内容を制御できますが、制作担当者が出発点として使用できるように再利用可能な方法で提供してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "事前設定済みのセクションはテンプレート内で使用するか、またはエディター上で制作担当者に提供することができます。"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "HubL includeタグ"
      }), "を使用してテンプレートにコンテンツを挿入できるパーシャルテンプレートと同様に、セクションには独自の機能があります。事前設定済みのセクションを参照するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "include_dnd_partial"
      }), "を使用してセクションファイルを示すパス属性を指定します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "include_dnd_partial"
      }), "は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "の内部でのみ使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area 'dnd_area' class='body-container body-container--home-page', label='Main section' %}\n   {# Banner Section #}\n   {% include_dnd_partial path='../sections/banner.html' context={} %}\n   {# End Banner Section #}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "include_dnd_partial"
        }), "タグのコンテキスト引数に注意してください。このタグにより、ページテンプレートからセクションにインスタンス固有の変数を渡すことができ、セクションファイルの既定値よりも優先されます。詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#section-context",
          children: "セクションコンテキスト"
        }), "を参照してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このようにセクションを使用すると、読みやすいテンプレートを簡単に作成できます。コンテキストでテンプレート固有のインスタンスが異なる場所のみを指定するため、戻ってそのセクションテンプレートを変更することができます。テーマの全てのテンプレート内で、このセクションの全てのインスタンスが更新されます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "注：セクションがインクルードされたテンプレートを使って以前に作成されたページは更新されません。これにより、破損につながる変更ミスが防止されます。制作担当者は必要に応じて、ページエディター上でセクションの最新バージョンを追加してから、古いバージョンを削除することにより、セクションの最新バージョンを取得できます。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "セクションテンプレート"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "セクションテンプレートは、開発者によって定義される再利用可能なコードセクションです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["セクションテンプレートは、その", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/html-hubl-templates#template-annotations",
        children: "テンプレートアノテーション"
      }), "に含まれる", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: section"
      }), "によって指定されます。セクションに固有の全てのテンプレートアノテーションを以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n templateType: section\n label: Banner\n description: \"A banner typically used at the top of a page highlighting a product or main topic.\"\n isAvailableForNewContent: true\n screenshotPath: ../images/section-previews/banner.png\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Value"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "templateType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テンプレートの使用箇所とテンプレートでの使用データを決める、テンプレートタイプを設定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "section"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページエディターで、人間に分かりやすいセクション名を指定するために使用します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ラベルに収まらない、セクションに関する詳しい説明です。ページエディター内に表示されます。最大255文字です。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshotPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String/path"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "セクションのスクリーンショットのパスです。セクションがどのように表示されるかを示すビジュアルリファレンスを制作担当者に提供するために使用します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/page%20editor%20add%20reusable%20section%20UI.gif",
        alt: "ページエディターでの再利用可能なセクションを追加するUI"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**セクションスクリーンショットのヒント：**ChromeまたはFirefoxを使用して公開ページのセクションラッパーを調べます。次に、セクションのラッパー要素を右クリックします。表示されるコンテキストメニューに、［screenshot node］/［capture node］という項目が表示されます。注：スティッキーヘッダーがある場合は、スクリーンショットに表示される可能性があるため、インスペクターを使用して非表示にすることができます。"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["セクションテンプレートの開始と終了には", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "タグを使用する必要があります。セクションテンプレートに含めることができるセクションは1つのみです。このセクションの内部には、モジュール、行、列を配置できます。これらは全て、ページテンプレートに追加するdndエリアと同じ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "ルールに従います。例外として、定義は1つのセクションのコンテンツとその子ドラッグ＆ドロップ要素が対象になります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!--\n templateType: section\n label: Banner\n description: \"A banner typically used at the top of a page highlighting a product or main topic.\"\n isAvailableForNewContent: true\n screenshotPath: ../images/section-previews/banner.png\n-->\n{% dnd_section\n padding={\n   'top': 200,\n   'right': 20,\n   'bottom': 200,\n   'left': 20\n },\n background_image={\n   'backgroundPosition': 'MIDDLE_CENTER',\n   'backgroundSize': 'cover',\n   'imageUrl': context.backgroundImage || get_asset_url('../images/blank-page-banner.png')\n },\n max_width=778,\n vertical_alignment='MIDDLE'\n%}\n {% dnd_column %}\n   {% dnd_row %}\n     {% dnd_module path='@hubspot/rich_text' %}\n     {% module_attribute 'html' %}\n       <div style=\"text-align: center\">\n         {{ context.content || '<h1 style=\"color: #fff;\">Communicate <span style=\"font-weight: 400;\">Your Way</span></h1><p style=\"color: #fff;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus posuere mi, in pretium ante posuere a. Aliquam a risus at eros molestie pretium.</p>' }}\n       </div>\n     {% end_module_attribute %}\n     {% end_dnd_module %}\n   {% end_dnd_row %}\n   {% dnd_row %}\n     {% dnd_module\n       path='../modules/button',\n       button_text={{ context.buttonText || 'Subscribe' }}\n       horizontal_alignment='CENTER'\n     %}\n     {% end_dnd_module %}\n   {% end_dnd_row %}\n {% end_dnd_column %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "セクションのプレビュー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発中にセクションをプレビューする最も簡単な方法は、デザインマネージャーを使用することです。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "を含むテンプレートを開きます。これにより、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "include_dnd_partial"
      }), "タグを使用してセクションテンプレートが呼び出されます。右上にあるプレビューをクリックします。これにより、セクションを更新すると変更がすぐに反映されるようになります。これは、変更のたびに新しいページを作成するよりも大幅に効率的です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "セクションコンテキスト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "セクションコンテキスト変数を使用して、セクションとモジュールの既定値を変更できます。セクションコンテキスト変数は開発者が定義します。つまり、モジュールとそのフィールドには直接関連付けられていません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページテンプレートでは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "include_dnd_partial"
      }), "タグ内でコンテキストパラメーターを使用してこれらのコンテキスト変数を設定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area 'dnd_area' class='body-container body-container--home-page', label='Main section' %}\n\n   {# Banner Section #}\n   {% include_dnd_partial path='../sections/banner.html'\n     context={\n       'content': '<h1 style=\"color: #fff;\">Home Page</h1><p style=\"color: #fff;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus posuere mi, in pretium ante posuere a. Aliquam a risus at eros molestie pretium.</p>',\n       'buttonText': 'Buy Now'\n     }\n   %}\n   {# End Banner Section #}\n\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンテキストパラメーターに追加する変数は、セクションテンプレート内で参照できるようになります。コンテキストでも、カスタムHubL変数を使用したモジュールパラメーターとdnd要素パラメーターと同様に設定ができます。次の例では、画像URLがコンテキストに存在する場合にはそのURLを使用するように、画像URLを設定しています。リッチ テキスト エリアとボタンコンテンツについても同様です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!--\n templateType: section\n label: Banner\n description: \"A banner typically used at the top of a page highlighting a product or main topic.\"\n isAvailableForNewContent: true\n screenshotPath: ../images/section-previews/banner.png\n-->\n{% dnd_section\n  background_image={\n   'backgroundPosition': 'MIDDLE_CENTER',\n   'backgroundSize': 'cover',\n   'imageUrl': context.backgroundImage || get_asset_url('../images/blank-page-banner.png')\n },\n max_width=778\n%}\n {% dnd_column %}\n   {% dnd_row %}\n     {% dnd_module path='@hubspot/rich_text' %}\n     {% module_attribute 'html' %}\n       <div style=\"text-align: center\">\n         {{ context.content || '<h1 style=\"color: #fff;\">Communicate <span style=\"font-weight: 400;\">Your Way</span></h1><p style=\"color: #fff;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus posuere mi, in pretium ante posuere a. Aliquam a risus at eros molestie pretium.</p>' }}\n       </div>\n     {% end_module_attribute %}\n     {% end_dnd_module %}\n   {% end_dnd_row %}\n   {% dnd_row %}\n     {% dnd_module\n       path='../modules/button',\n       button_text={{ context.buttonText || 'Subscribe' }}\n       horizontal_alignment='CENTER'\n     %}\n     {% end_dnd_module %}\n   {% end_dnd_row %}\n {% end_dnd_column %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["コンテキスト変数が使用されている全ての箇所には", (0, _jsxRuntime.jsx)(_components.code, {
          children: "||"
        }), "（or）があることに注意してください。最もシンプルな例は、ボタンモジュールです。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "context.buttonText"
        }), "に値がある場合は、その値を使用してください。それ以外の場合はテキストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'Subscribe'"
        }), "に設定します。これにより、何も指定されない場合には、代替の既定コンテンツを提供できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "セクションクラス"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者はclassパラメーターを使用してセクションラッパーにクラスを追加できます。これにより、dndセクションのhtml要素のclassフィールドに、指定したクラスが追加されます。可能な限り、スタイル設定コントロールをセクションに組み込んで、セクションを制作担当者が変更できるようにしておくことをお勧めします。クラスオプションでは、さらに洗練されたスタイルを設定できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n class='my-hero-section'\n padding={\n   'top': 200,\n   'right': 20,\n   'bottom': 200,\n   'left': 20\n },\n background_image={\n   'backgroundPosition': 'MIDDLE_CENTER',\n   'backgroundSize': 'cover',\n   'imageUrl': context.backgroundImage || get_asset_url('../images/blank-page-banner.png')\n },\n max_width=778,\n vertical_alignment='MIDDLE'\n%}\n...\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "制作担当者は、クラスの編集、追加、または削除を行うことはできません。エディター内でセクションを手動で再作成することによってのみ、取り除くことが可能です。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "また、CSSまたはJavaScriptを使用してセクションの子のレイアウトを変更することは避けてください。制作担当者がページエディター内で作業しにくくなるためです。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連コンテンツ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/drag-and-drop-areas",
          children: "ドラッグ＆ドロップエリアの概要"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/hubl/tags/dnd-areas",
          children: "ドラッグ＆ドロップエリアのHubLタグ"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/building-blocks/modules/using-modules-in-templates",
          children: "テンプレートでモジュールを使用する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/guides/creating-a-drag-and-drop-area",
          children: "ドラッグ＆ドロップエリアを使ってみる"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}