"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 163938684561;
const slug = exports.slug = 'reference/ui-components/standard-components/list';
const title = exports.title = 'List';
const name = exports.name = 'List | UI components';
const metaDescription = exports.metaDescription = 'Learn about the List component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "List | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "List"
      }), " component renders a list of items. Each item in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "List"
      }), " will be wrapped in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<li>"
      }), " tags. A list can be styled as inline, ordered, or unordered with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " prop."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-components-list-example.png",
        alt: "ui-components-list-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { List } from '@hubspot/ui-extensions';\n\nconst Extension() {\n  return (\n    <List variant=\"unordered-styled\">\n      <Link href=\"www.hubspot.com\">List item 1</Link>\n      <Link href=\"www.developers.hubspot.com\">List item 2</Link>\n      <Link href=\"www.knowledge.hubspot.com\">List item 3</Link>\n    </List>\n   );\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'unordered'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'unordered-styled'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'ordered'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'ordered-styled'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'inline'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'inline-divided'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of list to render. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#variants",
              children: "variants section"
            }), " below for examples."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, lists will be configured as vertically stacked list items without bullets. To customize the styling, use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " prop, as shown below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a bulleted unordered list:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-components-list-variants_2.png",
        alt: "ui-components-list-variants_2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<List variant=\"unordered-styled\">\n  <Link href=\"www.hubspot.com\">List item 1</Link>\n  <Link href=\"www.developers.hubspot.com\">List item 2</Link>\n  <Link href=\"www.knowledge.hubspot.com\">List item 3</Link>\n</List>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a numbered list without styling:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-components-list-variants_3.png",
        alt: "ui-components-list-variants_3"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<List variant=\"ordered\">\n  <Link href=\"www.hubspot.com\">List item 1</Link>\n  <Link href=\"www.developers.hubspot.com\">List item 2</Link>\n  <Link href=\"www.knowledge.hubspot.com\">List item 3</Link>\n</List>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a numbered list with styling:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-components-list-variants_4.png",
        alt: "ui-components-list-variants_4"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<List variant=\"ordered-styled\">\n  <Link href=\"www.hubspot.com\">List item 1</Link>\n  <Link href=\"www.developers.hubspot.com\">List item 2</Link>\n  <Link href=\"www.knowledge.hubspot.com\">List item 3</Link>\n</List>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To stack list items horizontally:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-components-list-variants_5.png",
        alt: "ui-components-list-variants_5"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<List variant=\"inline\">\n  <Link href=\"www.hubspot.com\">List item 1</Link>\n  <Link href=\"www.developers.hubspot.com\">List item 2</Link>\n  <Link href=\"www.knowledge.hubspot.com\">List item 3</Link>\n</List>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To stack list items horizontally with a divider between each item:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-components-list-variants_6.png",
        alt: "ui-components-list-variants_6"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<List variant=\"inline-divided\">\n  <Link href=\"www.hubspot.com\">List item 1</Link>\n  <Link href=\"www.developers.hubspot.com\">List item 2</Link>\n  <Link href=\"www.knowledge.hubspot.com\">List item 3</Link>\n</List>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/text",
          children: "Text"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/accordion",
          children: "Accordion"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/description-list",
          children: "DescriptionList"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}