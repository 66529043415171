"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890037;
const slug = exports.slug = 'reference/cms/hubl/variables-macros-syntax';
const title = exports.title = 'Variables de HubL y sintaxis de macros';
const name = exports.name = 'EMEA ES HubL variables & macros syntax';
const metaDescription = exports.metaDescription = 'Aprende a utilizar las variables de HubL y las macros para construir páginas dinámicas en el CMS de HubSpots y correos electrónicos. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variables",
  "label": "Variables",
  "parent": null
}, {
  "depth": 0,
  "id": "macros",
  "label": "Macros",
  "parent": null
}, {
  "depth": 0,
  "id": "llamada",
  "label": "Llamada",
  "parent": null
}, {
  "depth": 0,
  "id": "importaci%C3%B3n",
  "label": "Importación",
  "parent": null
}, {
  "depth": 0,
  "id": "from",
  "label": "From",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-dentro-de-los-bucles",
  "label": "Variables dentro de los bucles",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      strong: "strong",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Variables de HubL y sintaxis de macros"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubL utiliza variables para almacenar y enviar valores a la plantilla. Las variables se pueden utilizar en la lógica de la plantilla o iterar con los bucles for. Además de las variables, las macros son otra herramienta útil para imprimir secciones de código repetitivas pero dinámicas a lo largo de tus plantillas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las variables son expresiones delimitadas por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "}}"
      }), ". La sintaxis básica de las variables es la siguiente:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// variables\n{{ variable }}\n{{ dict.attribute }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las variables son una sola palabra en una expresión o un atributo de un diccionario. HubL utiliza estructuras de datos basadas en Python llamadas diccionarios o ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "dicts"
      }), " para almacenar diversos conjuntos de variables. Por ejemplo, HubSpot utiliza un diccionario \"contenido\" para albergar muchos atributos que pertenecen al contenido creado con esa plantilla. Por ejemplo, el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.absolute_url"
      }), " imprime la URL del contenido específico."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot tiene muchas variables predefinidas que se pueden utilizar en toda tu página, blog y plantillas de correo electrónico. Tenemos una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "lista de referencia de las variables"
      }), ", también puedes ver la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/debugging-and-errors#developer-info",
        children: "información del desarrollador"
      }), " al navegar por cualquier página de tu cuenta para ver las variables disponibles dentro de esa página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además de imprimir los valores de las variables y los atributos del diccionario en una plantilla, también puedes definir tus propias variables. Puedes almacenar cadenas, booleanos, enteros, secuencias o crear diccionarios dentro de una sola variable. Las variables se definen dentro de los delimitadores de las sentencias utilizando la palabra \"set\". Una vez almacenadas, las variables pueden imprimirse indicando el nombre de la variable como una expresión. A continuación puedes ver varios tipos de información almacenada en variables y luego imprimir."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las variables deben ser palabras sueltas o utilizar guiones bajos para los espacios (por ejemplo, mi_variable). ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubL no admite nombres de variables con guiones."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set string_var = \"This is a string value stored in a variable\" %}\n{{ string_var}}\n\n{% set bool_var = True %}\n{{ bool_var}}\n\n{% set int_var = 53 %}\n{{ int_var}}\n\n{% set seq_var = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{{ seq_var}}\n\n{% set var_one = \"String 1\" %}\n{% set var_two = \"String 2\" %}\n{% set sequence = [var_one,  var_two] %}\n{{ sequence }}\n\n{% set dict_var = {\"name\": \"Item Name\", \"price\": \"$20\", \"size\":\"XL\"} %}\n{{ dict_var.price }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This is a string value stored in a variable True 53 [Item1, Item2, Item3]\n[String 1, String 2] $20\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada ejemplo anterior almacena un tipo de variable diferente, y el último ejemplo almacena dos variables diferentes en una secuencia."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de imprimir valores, las variables pueden utilizarse en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "sentencias if"
      }), ", como parámetros de", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "filtro"
      }), " , como parámetros de", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "función"
      }), " así como iterar con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "bucles for"
      }), " (solo variables de secuencia)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un uso común es utilizar variables para definir valores CSS comunes en su hoja de estilos. Por ejemplo, si tienes un color que utilizas una y otra vez en todo tu archivo CSS. De esta manera, si necesitas cambiar ese color, puedes cambiar el valor de la variable, y todas las referencias a esa variable se actualizarán, la próxima vez que publiques el archivo."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<!-- defines variable and assigns HEX color -->\n{% set primaryColor = \"#F7761F\" %}\n\na {\n    color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Macros"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las macros HubL te permiten imprimir varias sentencias con un valor dinámico. Por ejemplo, si hay un bloque de código que escribes una y otra vez, una macro puede ser una buena solución, porque imprimirá el bloque de código mientras intercambia ciertos argumentos que le pasa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La macro se define, se nombra y se le dan argumentos dentro de una sentencia HubL. A continuación, se llama a la macro en una sentencia que pasa sus valores dinámicos, que imprime el bloque de código final con los argumentos dinámicos. La sintaxis básica de una macro es la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% macro name_of_macro(argument_name, argument_name2)  %}\n    {{ argument_name }}\n    {{ argument_name2 }}\n{% endmacro %}\n{{ name_of_macro(\"value to pass to argument 1\", \"value to pass to argument 2\")  }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tu macro devuelve espacios en blanco en forma de nuevas líneas, puedes eliminar a mano los espacios en blanco en las plantillas. Si agregas un signo menos (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), ") al principio o al final de un bloque, un comentario o una expresión variable, se eliminarán los espacios en blanco que haya antes o después de ese bloque."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% macro name_of_macro(argument_name, argument_name2) -%}\n    {{ argument_name }}\n    {{ argument_name2 }}\n{%- endmacro %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se muestra una aplicación práctica de una macro para imprimir una propiedad CSS3 con los distintos prefijos de proveedor, con un valor dinámico. Esto te permite imprimir 5 líneas de código con una sola etiqueta de macro."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n    {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Las macros introducen la posibilidad de tener código recursivo. Para evitar problemas de fiabilidad y rendimiento, solo puedes anidar macros a 20 niveles de profundidad. Si sobrepasas este límite obtendrás el error: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "se ha alcanzado el límite máximo de recursión de 20 para la macro <your macro name>"
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Llamada"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En algunos casos, es posible que desees pasar información dinámica adicional al bloque de macros. Por ejemplo, puedes tener un gran trozo de código que quieras devolver a la macro, además de los argumentos. Esto se puede hacer utilizando el bloque de llamada y caller(). Un bloque de llamada funciona esencialmente como una macro, pero no tiene su propio nombre. La expresión caller() especifica dónde se mostrará el contenido del bloque de llamada."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el ejemplo siguiente, se agrega una ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<p>"
      }), " en una macro además de los dos argumentos."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro render_dialog(title, class) %}\n  <div class=\"{{ class }}\">\n      <h2>{{ title }}</h2>\n      <div class=\"contents\">\n          {{ caller() }}\n      </div>\n  </div>\n {% endmacro %}\n\n {% call render_dialog(\"Hello World\", \"greeting\") %}\n     <p>This is a paragraph tag that I want to render in my.</p>\n {% endcall %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"greeting\">\n  <h2>Hello World</h2>\n  <div class=\"contents\">\n    <p>This is a simple dialog rendered by using a macro and a call block.</p>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Importación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Otra característica útil de las macros es que pueden utilizarse en varias plantillas importando un archivo de plantilla en otro. Para ello, deberás utilizar la etiqueta ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "import"
      }), ". La etiqueta de importación te permitirá especificar una ruta de archivo de Administrador de diseño a la plantilla que contiene tus macros y dar a las macros un nombre en la plantilla en la que las estás incluyendo. A continuación, puedes pasar valores a estas macros sin necesidad de redefinirlas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, digamos que tienes un archivo de plantilla .html que contiene las siguientes 2 macros. Se define una macro para configurar una etiqueta de encabezado y otra para generar una etiqueta de pie de página. Este archivo se guarda en el Administrador de diseño con el nombre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- my_macros.html file -->\n{% macro header(tag, title_text) %}\n    <header> <{{ tag }}>{{ title_text }} </{{tag}}> </header>\n{% endmacro %}\n\n{% macro footer(tag, footer_text) %}\n     <footer> <{{ tag }}>{{ footer_text }} </{{tag}}> </footer>\n{% endmacro %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la plantilla que utilizará estas macros, se utiliza una etiqueta de importación que especifica la ruta del archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), ". También nombra el grupo de macros (en este ejemplo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "header_footer"
      }), "). Las macros pueden ejecutarse agregando el nombre de la macro al nombre dado a la plantilla importada. Consulta el ejemplo siguiente."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% import \"custom/page/web_page_basic/my_macros.html\" as header_footer %}\n{{ header_footer.header(\"h1\", \"My page title\") }}\n<p>Some content</p>\n{{ header_footer.footer(\"h3:\", \"Company footer info\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<header><h1>My page title</h1></header>\n<p>Some content</p>\n<footer><h3>Company footer info</h3></footer>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "From"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si solo deseas importar macros específicas, en lugar de todas las macros contenidas en un archivo .html separado, puedes utilizar la etiqueta ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "from"
      }), ". Con la etiqueta from, especifica solo las macros que deseas importar. Por lo general, usar ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "import"
      }), " proporcionará más flexibilidad, pero esta alternativa también es compatible."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El siguiente ejemplo accede al mismo archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), " de la sección anterior de este artículo. Pero esta vez en lugar de importar todas las macros, sólo accede a la macro de pie de página."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% from \"custom/page/web_page_basic/my_macros.html\" import footer %}\n{{ footer(\"h2\", \"My footer info\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<footer><h2>My footer info</h2></footer>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables dentro de los bucles"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Cualquier variable definida dentro de los bucles está limitada al ámbito de ese bucle y no puede ser llamada desde fuera del mismo."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes llamar a las variables definidas fuera de un bucle, desde dentro de un bucle, pero no a la inversa."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También se pueden utilizar funciones para mutar objetos para configurar valores en dict o realizar operaciones de lista. En el siguiente ejemplo se utiliza la lista de operación ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#update",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".update"
        })
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set obj = {val : 0} %}\n{% for i in range(0, 10) %}\n   {% do obj.update({val: obj.val + i }) %}\n{% endfor %}\n{{ obj.val }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "45\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}