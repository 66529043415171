"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51261306631;
const slug = exports.slug = 'guides/api/crm/associations';
const title = exports.title = 'CRM-API |  Zuordnungen v4';
const name = exports.name = 'vNext Docs DP - Zuordnungen v4';
const metaDescription = exports.metaDescription = 'Die Endpunkte für CRM-Zuordnungen werden verwendet, um Zuordnungen zwischen Tickets, Produkten, Artikeln und ihren zugehörigen Kontakten, Unternehmen und Deals zu verwalten.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "von-hubspot-definierte-zuordnungstypen",
  "label": "Von HubSpot definierte Zuordnungstypen",
  "parent": null
}, {
  "depth": 0,
  "id": "einzelne-vs.-gepaarte-labels",
  "label": "Einzelne vs. gepaarte Labels",
  "parent": null
}, {
  "depth": 0,
  "id": "zuordnungstypen-erstellen",
  "label": "Zuordnungstypen erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "zuordnungstypen-abrufen",
  "label": "Zuordnungstypen abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "datens%C3%A4tze-verkn%C3%BCpfen",
  "label": "Datensätze verknüpfen",
  "parent": null
}, {
  "depth": 1,
  "id": "datens%C3%A4tze-ohne-label-zuordnen",
  "label": "Datensätze ohne Label zuordnen",
  "parent": "datens%C3%A4tze-verkn%C3%BCpfen"
}, {
  "depth": 1,
  "id": "datens%C3%A4tze-einem-label-zuordnen",
  "label": "Datensätze einem Label zuordnen",
  "parent": "datens%C3%A4tze-verkn%C3%BCpfen"
}, {
  "depth": 0,
  "id": "zuordnungslimits-festlegen-und-verwalten",
  "label": "Zuordnungslimits festlegen und verwalten",
  "parent": null
}, {
  "depth": 1,
  "id": "zuordnungslimits-erstellen-oder-aktualisieren",
  "label": "Zuordnungslimits erstellen oder aktualisieren",
  "parent": "zuordnungslimits-festlegen-und-verwalten"
}, {
  "depth": 1,
  "id": "zuordnungslimits-abrufen",
  "label": "Zuordnungslimits abrufen",
  "parent": "zuordnungslimits-festlegen-und-verwalten"
}, {
  "depth": 1,
  "id": "zuordnungslimits-l%C3%B6schen",
  "label": "Zuordnungslimits löschen",
  "parent": "zuordnungslimits-festlegen-und-verwalten"
}, {
  "depth": 0,
  "id": "zu-hoher-zuordnungsnutzung-berichterstatten",
  "label": "Zu hoher Zuordnungsnutzung berichterstatten",
  "parent": null
}, {
  "depth": 0,
  "id": "id-werte-des-zuordnungstyps",
  "label": "ID-Werte des Zuordnungstyps",
  "parent": null
}, {
  "depth": 0,
  "id": "v1-zuordnungen-alt",
  "label": "v1 Zuordnungen (alt)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Zuordnungen v4"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Informationen zur vorherigen Version finden Sie in der Dokumentation für die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/associations/association-details/v3",
          children: "v3 Zuordnungen-API"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zuordnungen stellen die Beziehungen zwischen Objekten und Aktivitäten im HubSpot-CRM dar. Datensatzzuordnungen können sowohl zwischen Datensätzen verschiedener Objekte (z. B. Kontakt zu Unternehmen) als auch innerhalb desselben Objekts (z. B. Unternehmen zu Unternehmen) bestehen. Sie können die Zuordnungen-Endpunkte verwenden, um Zuordnungen zwischen Datensätzen oder Datensätzen und Aktivitäten zu erstellen, abzurufen, zu aktualisieren oder zu löschen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Zuordnungsschema-Endpunkte ermöglichen es Ihnen, die unterstützten Zuordnungstypen in Ihrem Account anzuzeigen, Ihre eigenen Zuordnungstypen zu erstellen und Ihren Datensatzbeziehungen Label zuzuweisen. Zuordnungslabel werden zwischen Kontakten, Unternehmen, Deals, Tickets und benutzerdefinierten Objekten unterstützt und können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels#use-association-labels-in-hubspot-tools",
        children: "in ganz HubSpot in Tools wie Listen und Workflows verwendet werden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über Objekte, Datensätze, Eigenschaften und Zuordnungen-APIs im Leitfaden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Grundlegendes zum CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Die v4-Zuordnungen-API wird in Version 9.0.0 oder höher des NodeJS-HubSpot-Clients unterstützt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Von HubSpot definierte Zuordnungstypen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot bietet eine Reihe vordefinierter Zuordnungstypen (z. B. Kontakt ohne Label zu Unternehmen), aber Account-Admins können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "ihre eigenen Zuordnungslabel definieren"
      }), ", um zusätzlichen Kontext für Datensatzbeziehungen bereitzustellen (z. B. Manager und Mitarbeiter). Es gibt zwei von HubSpot definierte Zuordnungstypen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Primär:"
        }), " das Hauptunternehmen, dem der andere Datensatz zugeordnet ist. Primäre Zuordnungen können in HubSpot-Tools wie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/associations-enhancements-beta#use-associations-in-hubspot-tools",
          children: "Listen und Workflows"
        }), " verwendet werden. Für Datensätze mit mehreren zugeordneten Unternehmen unterstützt diese API das Ändern, welches Unternehmen als primäres Unternehmen gilt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ohne Label:"
        }), " ein Zuordnungstyp, der hinzugefügt wird, wenn ein beliebiger Kontakt-, Unternehmens-, Deal-, Ticket- oder benutzerdefinierter Objektdatensatz zugeordnet wird. Dieser Typ gibt an, dass eine Zuordnung vorhanden ist, und wird immer in Antworten mit einem ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Label"
        }), "-Wert von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " zurückgegeben. Wenn ein Datensatz über eine primäre Zuordnung oder ein benutzerdefiniertes Zuordnungslabel verfügt, werden diese Typen neben dem nicht benannten Zuordnungstyp aufgeführt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#association-type-id-values",
        children: "diesem Abschnitt"
      }), " können Sie alle von HubSpot definierten Zuordnungstypen anzeigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einzelne vs. gepaarte Labels"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt zwei Typen von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "Zuordnungslabeln"
      }), ", mit denen Sie die Beziehungen zwischen Datensätzen beschreiben können:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Einzeln:"
        }), " ein Label, das für beide Datensätze in der Beziehung gilt. Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Freund"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Kollege"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Paar"
        }), ": ein Label-Paar, wenn unterschiedliche Wörter verwendet werden, um jede Seite der Beziehung zwischen den zugeordneten Datensätzen zu beschreiben. Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Übergeordnet"
        }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Untergeordnet"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Arbeitgeber"
        }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Arbeitnehmer"
        }), ". Um gepaarte Label zu erstellen, müssen Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inverseLabel"
        }), "-Feld in Ihre Anfrage aufnehmen, um das zweite Label im Paar zu benennen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zuordnungstypen erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können benutzerdefinierte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "Zuordnungstypen entweder in HubSpot"
      }), " oder über den API-Endpunkt des Zuordnungsschemas erstellen. Sie können bis zu 10 Zuordnungstypen zwischen den einzelnen Objektpaaren erstellen (z. B. Kontakte und Unternehmen, Kontakte und Kontakte)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Zuordnungstyp über die API zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
      }), " durch und schließen Sie Folgendes in Ihre Anfrage ein:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), ": der interne Name des Zuordnungstyps. Dieser Wert darf ", (0, _jsxRuntime.jsx)("u", {
          children: "keine"
        }), " Bindestriche enthalten oder mit einem numerischen Zeichen beginnen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Label"
        }), " : der Name des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
          children: "Zuordnungslabels, wie in HubSpot angezeigt"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "inverseLabel"
        }), " (Nur gepaarte Labels): der Name des zweiten Labels im Label-Paar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre Anfrage könnte beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "///Example request body - Single label\n{\n  \"label\": \"Partner\",\n  \"name\": \"partner\"\n}\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "///Example request body - Paired labels\n{\n  \"label\": \"Manager\",\n  \"inverseLabel\": \"Employee\",\n  \"name\": \"manager_employee\"\n}\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zuordnungstypen abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die Zuordnungstypen zwischen bestimmten Objekten anzuzeigen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie erhalten ein Array, jedes Element enthält:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "category:"
        }), " Gibt an, ob der Zuordnungstyp von HubSpot (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "HUBSPOT_DEFINED"
        }), ") oder von einem Benutzer (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "USER_DEFINED"
        }), ") erstellt wurde."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "typeId:"
        }), " die numerische ID für diesen Zuordnungstyp. Dies wird verwendet, um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#associate-with-label",
          children: "beim Zuordnen von Datensätzen ein Label festzulegen"
        }), ". In ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#association-type-id-values",
          children: "dieser Liste"
        }), " finden Sie alle von HubSpot definierten ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "typeId"
        }), "-Werte."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Label:"
        }), " das alphanumerische Label. Dies ist dann ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " für den Zuordnungstyp ohne Label."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie finden diese Werte auch in HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/create-and-use-association-labels#association-label-api-details",
        children: "in Ihren Zuordnungseinstellungen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Datensätze verknüpfen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Datensätze ohne Label zuordnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können eine standardmäßige Zuordnung ohne Label zwischen zwei Datensätzen erstellen oder mehrere Zuordnungen ohne Label für mehrere Datensätze gleichzeitig einrichten. Um eine individuelle Standardzuordnung zwischen zwei Datensätzen einzurichten, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage durch an"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/{fromObjectType}/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Berücksichtigen Sie in der Anfrage-URL Folgendes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " die ID des Objekts, das Sie zuordnen. Die ID-Werte finden Sie in dieser ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "Liste der Objekttyp-IDs,"
        }), " oder für Kontakte, Unternehmen, Deals, Tickets und Notizen können Sie den Objektnamen verwenden (z. B ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectId"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " die ID des zuzuordnenden Datensatzes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " die ID des Objekts, dem Sie den Datensatz zuordnen. Die ID-Werte finden Sie in dieser ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "Liste der Objekttyp-IDs,"
        }), " oder für Kontakte, Unternehmen, Deals, Tickets und Notizen können Sie den Objektnamen verwenden (z. B ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectId"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " die ID des Datensatzes, der zugeordnet werden soll."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um beispielsweise einen Kontaktdatensatz mit der ID 12345 einem Unternehmensdatensatz mit der ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "67891"
      }), " zuzuordnen, würde Ihre Anfrage-URL so lauten: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/contact/12345/associations/default/company/67891"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um mehrere Standardzuordnungen gleichzeitig einzurichten, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v4/associations/{fromObjectType}/{toObjectType}/batch/associate/default"
      }), " durch. Fügen Sie im Anforderungstext Werte für die Datensätze eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ", die Sie zuordnen möchten."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Die Anzahl der Zuordnungen, die ein Datensatz haben kann, hängt vom ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/hubspot-product-and-services-catalog#TechnicalLimits:~:text=CRM%20Record%20Association%20Limits",
          children: "Objekt und Ihrem HubSpot-Abonnement ab."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Datensätze einem Label zuordnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um zwei Datensätze zuzuordnen und ein Label zur Beschreibung der Zuordnung festzulegen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/{objectType}/{objectId}/associations/{toObjectType}/{toObjectId}"
      }), " durch. Fügen Sie im Anforderungstext die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " ein, um den Typ der Zuordnung anzugeben, die Sie erstellen möchten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie nicht benannte Zuordnungen erstellen, können Sie die im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#associate-records-without-a-label",
        children: "obigen Abschnitt"
      }), " beschriebenen Standardendpunkte verwenden, für die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " nicht erforderlich ist. Wenn Sie Zuordnungen mit einem Label erstellen, können Sie auf diese ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#association-type-id-values",
        children: "Liste der Standardtyp-IDs"
      }), " verweisen oder Sie müssen die benutzerdefinierten Zuordnungstypen zwischen diesen Objekten abrufen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Stellen Sie für objektübergreifende und gepaarte Label-Beziehungen sicher, dass Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "typeId"
        }), " verwenden, die sich auf die richtige Richtung bezieht (z. B. Kontakt zu Unternehmen vs. Unternehmen zu Kontakt, Mitarbeiter zu Manager vs. Manager zu Mitarbeiter)."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So ordnen Sie beispielsweise einen Kontakt mithilfe eines benutzerdefinierten Labels einem Deal zu:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1. Führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/contact/deal/labels"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["2. Sehen Sie sich in Ihrer Antwort die Werte ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), " für das Label an. Die ID ist eine Zahl (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "36"
      }), "), und die Kategorie ist immer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "USER_DEFINED"
      }), " für benutzerdefinierte Label."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["3. Führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/contact/{objectId}/associations/deal/{toObjectId}"
      }), "mit dem folgenden Anforderungstext durch:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "/// Example request body\n[\n  {\n    \"associationCategory\": \"USER_DEFINED\",\n    \"associationTypeId\": 36\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zuordnungslimits festlegen und verwalten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/set-limits-for-record-associations",
        children: "Limits"
      }), " für die Anzahl der zwischen Objekten zugeordneten Datensätze festlegen oder wie oft ein Label zur Beschreibung von Zuordnungen verwendet werden kann. Darüber hinaus gibt es ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "technische Beschränkungen und Limits, die von Ihrem HubSpot-Abonnement abhängen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungslimits erstellen oder aktualisieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können neue Zuordnungslimits zwischen Objekten erstellen oder bestehende aktualisieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um Limits zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/create"
        }), " durch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um bestehende Limits zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/update"
        }), " durch."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie im Anforderungstext ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), " mit Folgendem hinzu:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "category"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Kategorie der Zuordnung, für die Sie ein Limit festlegen, entweder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_DEFINED"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "USER_DEFINED"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "typeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die numerische ID für den Zuordnungstyp, für den Sie ein Limit festlegen möchten. Beziehen Sie sich auf ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#association-type-id-values",
              children: "diese Liste"
            }), " der Standard-", (0, _jsxRuntime.jsx)(_components.code, {
              children: "typeId"
            }), "-Werte oder ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#retrieve-association-types",
              children: "rufen Sie den Wert"
            }), " für benutzerdefinierte Label ab."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxToObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die maximal zulässige Anzahl von Zuordnungen für den Zuordnungstyp."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um beispielsweise Limits festzulegen, dass ein Deal mit maximal fünf Kontakten verknüpft werden kann, wobei nur ein Kontakt als ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ansprechpartner"
      }), " für einen Deal gekennzeichnet ist, würde Ihre Anfrage wie folgt aussehen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request POST crm/v4/associations/definitions/configurations/deal/contact/batch/create\n{\n  \"inputs\": [\n    {\n      \"category\": \"HUBSPOT_DEFINED\",\n      \"typeId\": 3,\n      \"maxToObjectIds\": 5\n    },\n    {\n      \"category\": \"USER_DEFINED\",\n      \"typeId\": 35,\n      \"maxToObjectIds\": 1\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungslimits abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um alle definierten Zuordnungslimits zu lesen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/definitions/configurations/all"
        }), " durch. Dadurch werden benutzerdefinierte Zuordnungslimits zurückgegeben, die für alle Objekte definiert sind."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um Zuordnungslimits zwischen zwei bestimmten Objekten zu lesen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}"
        }), " durch."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei beiden Anfragen gibt die Antwort die Werte der Zuordnungen für ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "maxToObjectIds"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), "zurück. Wenn Sie beispielsweise Limits zwischen Deals und Kontakten abrufen, würde die Antwort wie folgt aussehen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example response GET crm/v4/associations/definitions/configurations/deal/contact\n{\n  \"results\": [\n    {\n      \"category\": \"HUBSPOT_DEFINED\",\n      \"typeId\": 3,\n      \"userEnforcedMaxToObjectIds\": 5,\n      \"label\": null\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungslimits löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um bestimmte Zuordnungslimits zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/purge"
      }), " durch. Fügen Sie im Anforderungstext die Werte ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " der Zuordnungstypen ein, für die Sie Limits aufheben möchten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um beispielsweise das ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ansprechpartner"
      }), "-Limit für Deals und Kontakte aufzuheben, müsste die Anfrage wie folgt aussehen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request POST crm/v4/associations/definitions/configurations/deal/contact/batch/purge\n{\n  \"inputs\": [\n    {\n      \"category\": \"USER_DEFINED\",\n      \"typeId\": 35\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie erfolgreich sind, erhalten Sie eine 204-Antwort und das enthaltene Limit wird auf den Systemstandard zurückgesetzt (d. h., viele Kontakte können das Label ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Point ofcontact"
      }), " haben)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zu hoher Zuordnungsnutzung berichterstatten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt technische ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "Beschränkungen für die Anzahl der Zuordnungen, die ein Datensatz haben kann"
      }), ". Sie können die Zuordnungen-API verwenden, um einen Bericht mit Datensätzen abzurufen, die sich dem Höchstlimit für Zuordnungen nähern oder dieses erreicht haben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um den Bericht abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v4/associations/usage/high-usage-report/{userID}"
      }), " durch. Die Datei enthält Datensätze, die 80 % oder mehr ihres Zuordnungslimits nutzen. Wenn ein Unternehmen beispielsweise bis zu 50.000 Kontakten zugeordnet werden kann, wird das Unternehmen in der Datei aufgenommen, wenn es über 40.000 oder mehr zugeordnete Kontakte verfügt. Die Datei wird an die E-Mail-Adresse des Benutzers gesendet, dessen ID in der Anfrage-URL enthalten war. Erfahren Sie, wie Sie Benutzer-IDs mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/users/user-provisioning",
        children: "Benutzer-API"
      }), " abrufen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ID-Werte des Zuordnungstyps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die folgenden Tabellen enthalten die von HubSpot definierten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), "-Werte, die den Typ der Zuordnung angeben. Die Zuordnungstypen variieren je nach den enthaltenen Objekten und der Richtung der Zuordnung (z. B. ist Kontakt zu Unternehmen anders als Unternehmen zu Kontakt). Wenn Sie benutzerdefinierte Objekte oder benutzerdefinierte Zuordnungslabel erstellen, haben die zugehörigen Zuordnungstypen „Eindeutige ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), "“-Werte, die Sie in Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/create-and-use-association-labels#association-label-api-details",
        children: "Zuordnungseinstellungen in HubSpot"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieve-association-type",
        children: "abrufen"
      }), " oder suchen müssen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Zu den Standardzuordnungstypen für Unternehmen gehören ein Zuordnungstyp ohne Label und ein primärer Zuordnungstyp. Wenn ein Datensatz mehr als ein zugeordnetes Unternehmen hat, kann nur eines das primäre Unternehmen sein. Die anderen Zuordnungen können entweder kein Label enthalten oder über benutzerdefinierte Zuordnungslabel verfügen."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Kontakt zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "449"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "279"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Unternehmen (primär)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "4"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "15"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "193"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Anruf"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "197"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu E-Mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "199"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Meeting"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "201"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Notiz"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "203"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Aufgabe"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "82"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Kommunikation (SMS, WhatsApp oder LinkedIn-Nachricht)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "454"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Briefpost"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "587"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Warenkorb"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "508"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Bestellung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "178"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Rechnung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "388"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Zahlung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "296"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontakt zu Abonnement"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Unternehmen zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "450"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "14"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Vom untergeordneten zum übergeordneten Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "13"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Übergeordnete Unternehmen zu untergeordnetem Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "280"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Kontakt (primär)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "342"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Deal (primär)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "340"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "25"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Ticket (primär)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "181"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Anruf"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "185"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu E-Mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "187"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Meeting"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "189"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Notiz"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "191"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Aufgabe"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "88"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Kommunikation (SMS, WhatsApp oder LinkedIn-Nachricht)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "460"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Briefpost"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "180"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Rechnung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "510"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Bestellung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "390"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Zahlung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "298"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Unternehmen zu Abonnement"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Deal zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "451"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "341"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "5"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Unternehmen (primär)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "27"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "205"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Anruf"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "209"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu E-Mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "211"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Meeting"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "213"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Notiz"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "215"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Aufgabe"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "86"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Kommunikation (SMS, WhatsApp oder LinkedIn-Nachricht)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "458"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Briefpost"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "313"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Deal-Aufteilung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "19"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Artikel"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "176"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Rechnung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "511"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Bestellung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "392"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Zahlung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "630"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Produkt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "63"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Angebot"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "300"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Deal zu Abonnement"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Ticket zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "452"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "16"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "339"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "26"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Unternehmen (primär)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "28"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "219"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Anruf"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "223"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu E-Mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "225"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Meeting"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "227"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Notiz"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "229"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Aufgabe"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "84"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Kommunikation (SMS, WhatsApp oder LinkedIn-Nachricht)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "456"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Briefpost"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "32"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Thread"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "278"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Konversation"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "526"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket zu Bestellung"
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "Lead zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "578"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead zu primärem Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "596"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead zu Anruf"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "598"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead zu E-Mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "600"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead zu Meeting"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "602"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead zu Kommunikation"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "608"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "610"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "646"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead zu Aufgabe"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Anruf zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "194"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Anruf zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "182"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Anruf zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "206"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Anruf zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "220"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Anruf zu Ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "E-Mail zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "198"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-Mail zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "186"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-Mail zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "210"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-Mail zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "224"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-Mail zu Ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Meeting zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "200"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Meeting zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "188"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Meeting zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "212"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Meeting zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "226"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Meeting zu Ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Notiz zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "202"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Notiz zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "190"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Notiz zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "214"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Notiz zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "228"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Notiz zu Ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Briefpost zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "453"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Briefpost zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "459"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Briefpost zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "457"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Briefpost zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "455"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Briefpost zu Ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Angebot zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "69"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "71"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "64"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "67"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Artikel"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "286"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Angebotsvorlage"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "362"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Rabatt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "364"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Gebühr"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "366"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Steuer"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "702"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kontaktunterzeichner (für elektronische Unterschriften)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "733"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Warenkorb"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "408"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Rechnungsstellung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "731"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Bestellung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "398"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Zahlung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "304"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Angebot zu Abonnement"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Aufgabe zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "204"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Aufgabe zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "192"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Aufgabe zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "216"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Aufgabe zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "230"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Aufgabe zu Ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Kommunikation (SMS, WhatsApp oder LinkedIn-Nachricht) zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "81"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kommunikation (SMS, WhatsApp oder LinkedIn-Nachricht) zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "87"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kommunikation (SMS, WhatsApp oder LinkedIn-Nachricht) zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "85"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kommunikation (SMS, WhatsApp oder LinkedIn-Nachricht) zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "83"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Kommunikation (SMS, WhatsApp oder LinkedIn-Nachricht) zu Ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Bestellung zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "593"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Warenkorb"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "507"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "509"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Unternehmen"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "512"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Deal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "519"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Rabatt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "521"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Rabattcode"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "518"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Rechnung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "513"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Artikel"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "523"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Zahlung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "730"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Angebot"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "516"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Abonnement"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "726"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Aufgabe"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "525"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Bestellung zu Ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Warenkorb zu Objekt"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "TYP-ID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Zuordnungstyp"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "586"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Warenkorb zu Kontakt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "588"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Warenkorb zu Rabatt"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "590"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Warenkorb zu Artikel"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "592"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Warenkorb zu Bestellung"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "732"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Warenkorb zu Angebot"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "728"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Warenkorb zu Aufgabe"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "594"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Warenkorb zu Ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "v1 Zuordnungen (alt)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie die v1-Zuordnungen-API verwenden, finden Sie in der folgenden Tabelle Informationen zu den IDs, die beim Zuordnen von Datensätzen zu verwenden sind."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Zuordnungstyp"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "ID"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Kontakt zu Unternehmen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmen zu Kontakt (Standard)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmen zu Kontakt (alle Label)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "280"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal zu Kontakt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Kontakt zu Deal"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal zu Unternehmen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "5"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmen zu Deal"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "6"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmen zu Interaktion"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "7"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interaktion zu Unternehmen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "8"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Kontakt zu Interaktion"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "9"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interaktion zu Kontakt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "10"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal zu Interaktion"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "11"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interaktion zu Deal"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "12"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Übergeordnetes Unternehmen zu untergeordnetem Unternehmen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "13"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Untergeordnetes Unternehmen zu übergeordnetem Unternehmen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "14"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Kontakt zu Ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "15"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket zu Kontakt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "16"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket zu Interaktion"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "17"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interaktion zu Ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "18"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal zu Artikel"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "19"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Artikel zu Deal"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "20"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmen zu Ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "25"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket zun Unternehmen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "26"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal zu Ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "27"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket zu Deal"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}