"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32924700882;
const slug = exports.slug = 'guides/apps/marketplace/measuring-app-performance';
const title = exports.title = 'Medición del rendimiento de la aplicación';
const name = exports.name = 'Medición del rendimiento de la aplicación';
const metaDescription = exports.metaDescription = 'Aprende más sobre cómo medir el rendimiento de tu aplicación a través de la vista detalles de la página de anuncios de aplicaciones.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "revisar-los-an%C3%A1lisis-de-tu-aplicaci%C3%B3n",
  "label": "Revisar los análisis de tu aplicación",
  "parent": null
}, {
  "depth": 1,
  "id": "anal%C3%ADticas-del-mercado",
  "label": "Analíticas del mercado",
  "parent": "revisar-los-an%C3%A1lisis-de-tu-aplicaci%C3%B3n"
}, {
  "depth": 0,
  "id": "feedback-de-clientes",
  "label": "Feedback de clientes",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-clave",
  "label": "Recursos clave",
  "parent": null
}, {
  "depth": 0,
  "id": "mide-la-interacci%C3%B3n-con-par%C3%A1metros-utm",
  "label": "Mide la interacción con parámetros UTM",
  "parent": null
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "mide-la-interacci%C3%B3n-con-par%C3%A1metros-utm"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em",
      img: "img",
      code: "code",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Medición del rendimiento de la aplicación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes ver métricas de rendimiento para cualquier aplicación que aparezca en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "Mercado de aplicaciones"
      }), " en tu cuenta de desarrollador."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Revisar los análisis de tu aplicación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "cuenta de desarrollador de aplicaciones"
        }), ", navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mercado"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anuncios de aplicaciones"
        }), ". Esto te llevará a un panel de control con todos los anuncios de tu mercado de aplicaciones."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "aplicación"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Analíticas del mercado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Instalaciones"
      }), ", revisa las métricas detalladas sobre el rendimiento de tu aplicación. Para analizar los datos durante un período específico, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Frecuencia"
      }), " en la parte superior de la página y selecciona un período."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes analizar los siguientes datos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Instalaciones de aplicaciones"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Desinstalaciones de aplicaciones"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Instalaciones vs. desinstalaciones de aplicaciones"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Instalaciones gratuitas vs. de pago"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Desinstalaciones gratuitas vs. de pago"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Instalación por país"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Instalaciones por Hub"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/marketplace-app-analytics.png",
        alt: "marketplace-app-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Analíticas del anuncio"
      }), ", revisa las métricas de la página del anuncio de la aplicación. Para analizar los datos durante un período específico, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Frecuencia"
      }), " en la parte superior de la página y selecciona un período."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes analizar los siguientes datos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Visitas de páginas"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fuente de visita de página"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Uso de la CTA"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Visitas de página gratuitas vs de pago"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Visitas de página por país"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Visitas de página por Hub"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-analytics.png",
        alt: "listing-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Feedback de clientes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver todas las reseñas que los clientes han enviado a tu aplicación, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Comentarios de los clientes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/customer-feedback-tab.png",
        alt: "customer-feedback-tab"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Calificaciones y reseñas"
      }), ", puedes filtrar las reseñas por el número de estrellas dado, la industria del cliente y el tamaño de la empresa del cliente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para responder a una reseña:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Responder"
        }), " en una reseña."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el cuadro emergente, escribe tu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "respuesta"
        }), " al cliente, y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar"
        }), ". Esta respuesta será visible públicamente en tu anuncio de aplicaciones."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para editar las notificaciones de las reseñas recibidas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Acciones"
        }), " y selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Editar notificaciones."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la página Notificaciones, busca ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mercado"
        }), " y selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nuevas valoraciones de anuncio"
        }), " para recibir una notificación cuando un cliente deje una reseña."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para enviar tu enlace de reseña a un cliente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Acciones"
        }), " y luego en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar enlace de valoración"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Envía un correo electrónico o este enlace a tu cliente para que deje una reseña."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Desinstalar respuestas de encuestas"
      }), ", haz una reseña de todas las respuestas de encuestas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para exportar las respuestas, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportar"
        }), " y selecciona el formato de archivo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Comentario privado"
      }), ", reseña las respuestas de comentarios privados a tu equipo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos clave"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Recursos clave"
      }), ", revisa los siguientes recursos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La guía de beneficios del App Partner Program que enumera todos los beneficios que obtienes como partner con una aplicación incluida en el Mercado de aplicaciones de HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Una encuesta de comentarios que puedes completar para dar tu opinión sobre la experiencia del partner de la aplicación."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La información de contacto de tu mánager de App Partner."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mide la interacción con parámetros UTM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además de las métricas anteriores, puedes medir aún más la interacción al incluir los parámetros UTM en las URL en la página de índice de tu aplicación. Esto te permite ver cuánto tráfico llega a tu sitio web desde la página tu página de índice, utilizando HubSpot, Google Analytics u otras plataformas de análisis de tu elección."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se recomienda agregar parámetros UTM a las siguientes URL incluidas en tu página de índice:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Contenido de apoyo en tu página de anuncio del Mercado de aplicaciones, como el sitio web de tu empresa, la documentación de apoyo, el caso de éxito y la política de privacidad."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La URL de instalación de OAuth que los clientes utilizan al instalar la aplicación."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, podrías agregar la siguiente cadena de parámetros UTM al final de la URL de tu documentación:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "?utm_campaign=appmarketplacelisting&utm_medium=referral&utm_source=hubspot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " se recomienda usar parámetros de UTM que sean consistentes con otro seguimiento de UTM que tú o tu equipo de marketing puedan estar usando. Aprende más sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/customers/understanding-basics-utm-parameters",
          children: "conceptos básicos de los parámetros de UTM"
        }), " y cómo crear URL de seguimiento de UTM con ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/how-do-i-create-a-tracking-url",
          children: "HubSpot"
        }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/what-are-utm-tracking-codes-ht",
          children: "Google Analytics"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para agregar parámetros UTM a la URL de instalación de OAuth:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador de aplicaciones, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de la aplicación para editar sus detalles."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Información básica"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Autentificación"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "URL de redireccionamiento"
        }), ", actualiza tu URL de redireccionamiento para que contenga tus parámetros UTM. Esto actualizará la URL de instalación de la aplicación después de guardar, por lo que deberás asegurarte de haber actualizado los enlaces orientados al usuario para usar la nueva URL."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/update-redirect-url.png",
        alt: "update-redirect-url"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Guardar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " el campo URL del botón de instalación tiene un límite de 250 caracteres. Si tus parámetros UTM exceden ese límite, es posible que debas usar un acortador de URL, como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://bitly.com/",
          children: "Bitly"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para agregar parámetros UTM al contenido de soporte de la aplicación:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu cuenta de desarrollador, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Mercado de aplicaciones"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Anuncios"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la tabla ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Anuncios del mercado"
          }), ", haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre"
          }), " de la aplicación."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Información del anuncio"
          }), ", actualiza la URL en el campo ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "URL"
          }), " de botón de instalación con tus parámetros UTM."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/install-button-url.png",
            alt: "install-button-url"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Información de asistencia"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En las secciones ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Información de contacto"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Recursos de asistencia"
          }), ", y ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Términos de servicio y Política de privacidad"
          }), ", actualiza las URL con los parámetros de UTM."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/support-resources-section.png",
            alt: "support-resources-section"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Una vez que hayas actualizado tus URL, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enviar para revisión"
          }), " en la esquina superior derecha."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez revisadas y aprobadas, las URL de la página de índice de tu aplicación se actualizarán con tus parámetros UTM. Puedes usar herramientas de análisis, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "HubSpot"
      }), " o Google Analytics, para ver el tráfico que proviene de tus URL según la categoría de tus parámetros UTM."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debido a que el anuncio de tu aplicación se puede encontrar a través de Google y otros motores de búsqueda, también es importante asegurarte de que tu anuncio sea compatible con SEO. Una estrategia recomendada para mejorar tu SEO es a través de enlaces externos. Ya sea que estés escribiendo contenido de sitios web, enviando boletines por correo electrónico o redactando mensajes de redes sociales, considera agregar enlaces a tu página de índice, junto con información relevante sobre tu integración. Puedes expandir aún más tu alcance a través de estrategias como los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/guest-blogging",
          children: "blogs de invitados"
        }), " para mejorar la autoridad del SEO."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Aprende más sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/backlink-strategies",
          children: "formas creativas de ganar enlaces externos"
        }), ", y echa un vistazo a la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/link-building-tutorial",
          children: "lección gratuita de HubSpot Academy sobre construcción de enlaces"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Requisitos de certificación de aplicación"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Requisitos de anuncio de aplicación"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}