"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51199955888;
const slug = exports.slug = 'getting-started/account-types';
const title = exports.title = 'HubSpot Account Types';
const name = exports.name = 'HubSpot Account Types';
const metaDescription = exports.metaDescription = 'Learn about the different types of HubSpot accounts and what each one is used for.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "standard-hubspot-accounts",
  "label": "Standard HubSpot accounts",
  "parent": null
}, {
  "depth": 0,
  "id": "app-developer-accounts",
  "label": "App developer accounts",
  "parent": null
}, {
  "depth": 0,
  "id": "developer-test-accounts",
  "label": "Developer test accounts",
  "parent": null
}, {
  "depth": 1,
  "id": "create-a-developer-test-account",
  "label": "Create a developer test account",
  "parent": "developer-test-accounts"
}, {
  "depth": 1,
  "id": "renew-a-developer-test-account",
  "label": "Renew a developer test account",
  "parent": "developer-test-accounts"
}, {
  "depth": 0,
  "id": "sandbox-accounts",
  "label": "Sandbox accounts",
  "parent": null
}, {
  "depth": 1,
  "id": "standard-sandbox-accounts",
  "label": "Standard sandbox accounts",
  "parent": "sandbox-accounts"
}, {
  "depth": 1,
  "id": "cms-sandbox-accounts",
  "label": "CMS sandbox accounts",
  "parent": "sandbox-accounts"
}, {
  "depth": 1,
  "id": "development-sandbox-accounts-beta",
  "label": "Development sandbox accounts (BETA)",
  "parent": "sandbox-accounts"
}, {
  "depth": 0,
  "id": "marketplace-provider-accounts",
  "label": "Marketplace provider accounts",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      em: "em",
      img: "img",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot Account Types"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are several types of HubSpot accounts, each with a distinct purpose. Below, learn about each account type and what they're intended for."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Standard HubSpot accounts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A standard HubSpot account is the most common type of account. It’s where you’ll find all the tools, features, and settings included with your HubSpot plan. It can be free or paid, and is used as your production environment."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A standard HubSpot account will have access to all the tools and features included with your plan."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App developer accounts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "App developer accounts are free accounts intended for creating and managing apps, integrations, and developer test accounts. They're also where you can create and manage App Marketplace listings. However, app developer accounts and their associated test accounts aren’t connected to a standard HubSpot account. They can’t sync data or assets to or from another HubSpot account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["App developer accounts can be identified by a banner at the top of any page that says ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "This"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "is an app"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "developer account"
      }), ". ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://paper-attachments.dropbox.com/s_FE997E185BD47B083EA9B7C65FEC2822D18406AA9C2CBDB7FA3CA77217D16F19_1626814303990_App+dev+account+info+alert+option+3.png",
        alt: ""
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Get started by creating an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "app developer account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Developer test accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Within ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-developer-accounts",
        children: "app developer accounts"
      }), ", you can create developer test accounts to test apps and integrations without affecting any real HubSpot data. Developer test accounts do not mirror production accounts, but are instead free HubSpot accounts with access to a 90-day trial of many enterprise features, with the following limitations:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hub:"
        }), " you can only send marketing emails to addresses of users who you've added to your developer test account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub:"
        }), " the number of pages you can create are subject to the following limits:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Website pages:"
            }), " 25"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Blogging tools:"
            }), " 1 blog with up to 100 posts"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Landing pages:"
            }), " 25"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workflows:"
        }), " a maximum of 100,000 records can be enrolled per day in workflows created in a developer test account. If this daily limit is reached:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Any additional attempted enrollment beyond this limit will be dropped."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Users will be informed in app that they reached the daily record enrollment limit, as well as what time the limit will be refreshed."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can create up to 10 test accounts per developer account. This type of account cannot sync data with other accounts, and they can't be connected to a standard HubSpot account. Test accounts can be identified by a banner at the top of the page, which includes how many days are left before it expires."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/trial-banner.png",
        alt: "trial-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about creating and managing test accounts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a developer test account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a developer test account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left navigation sidebar of your app developer account, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Test accounts"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/left-sidebar-test-accounts.png",
            alt: "left-sidebar-test-accounts"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create an app test account"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-test-account-create.png",
            alt: "developer-test-account-create"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Enter an ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "account name"
          }), ", then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To access and manage your developer test accounts:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top navigation bar of your app developer account, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hover over the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "account"
        }), " you want to manage, then select an action:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To delete the account, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Delete"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To copy the account ID, renew the account, or rename it, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "More"
            }), " dropdown menu."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-account-test-accounts.png",
        alt: "developer-account-test-accounts"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Renew a developer test account"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Developer test accounts will expire after 90 days if no API calls are made to the account. You can either manually renew the account from the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Testing"
      }), " page in HubSpot, or by making an API call to the account. Keep the following in mind when attempting to renew the account via API call:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["This only applies to API requests made using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/oauth-tokens",
          children: "OAuth tokens"
        }), " generated from an application in the same developer account as the test account you want to renew."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Renewals must be made no more than 30 days before the test account’s expiration date."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sandbox accounts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sandbox accounts allow you to test out changes without impacting your standard account. Learn more about the different types of sandbox accounts in the sections below."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standard sandbox accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have an ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " subscription, you can create a standard sandbox account that provides a safe and secure environment where you can test new workflows, integrations, website pages, and other important changes without impacting anything in your standard account. These sandboxes copy the structure of your standard account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["These accounts can be identified by a yellow banner at the top of the page that includes the label: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "You're in [name of sandbox], which is a standard sandbox account"
      }), ". The banner will also include a link back to your production account for easy switching."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/standard-sandbox-banner.png",
        alt: "standard-sandbox-banner"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about standard sandbox accounts on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/set-up-a-hubspot-standard-sandbox-account",
        children: "HubSpot's Knowledge Base"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CMS sandbox accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS sandboxes are free accounts intended for building and testing website changes without impacting your standard account or live website. Similar to app developer accounts, CMS sandbox accounts are ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " connected to your standard HubSpot account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "create a CMS sandbox account for free"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS sandboxes don’t have a banner, but they only have access to HubSpot’s free tools and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", minus the ability to connect a domain."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Development sandbox accounts (BETA)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sales Hub"
        })
      }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " subscription, you can create a development sandbox account through the CLI for local development. You can gain access to development sandbox accounts by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/opt-your-hubspot-account-into-a-beta-feature",
        children: "opting into the CRM developer tools beta"
      }), " in your standard HubSpot account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Development sandboxes can be identified by a yellow banner at the top of the page that reads: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "You're in [name of sandbox], which is a development sandbox account"
      }), ". Learn more about creating and using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/setup#create-and-use-development-sandboxes",
        children: "development sandbox accounts"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/development-sandboxes-banner.png",
        alt: "development-sandboxes-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Marketplace provider accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Marketplace Provider accounts are intended for creating and managing ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "Template Marketplace listings"
      }), " and transactions. To get started selling on the Template Marketplace, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "create a Template Marketplace provider account"
      }), ". If you're a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners",
        children: "HubSpot Partner"
      }), ", you already have Marketplace Provider functionality in your Partner account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A Marketplace Provider account can be identified by a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Template"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketplace"
      }), " item in the top navigation menu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/template-marketplace-acct.png",
        alt: "template-marketplace-acct"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}