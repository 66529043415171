"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164592946577;
const slug = exports.slug = 'reference/ui-components/overview';
const title = exports.title = 'Componentes de extensão da UI';
const name = exports.name = 'Visão geral dos componentes da UI';
const metaDescription = exports.metaDescription = 'Saiba mais sobre os componentes que você pode usar para criar extensões de UI.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "componentes-padr%C3%A3o",
  "label": "Componentes padrão",
  "parent": null
}, {
  "depth": 0,
  "id": "componentes-de-dados-do-crm",
  "label": "Componentes de dados do CRM",
  "parent": null
}, {
  "depth": 0,
  "id": "componentes-de-a%C3%A7%C3%A3o-do-crm",
  "label": "Componentes de ação do CRM",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral dos componentes da UI (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao criar uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "Extensão de UI"
      }), ", você pode incluir qualquer número de componentes reutilizáveis fornecidos pela HubSpot. Esses componentes variam de campos de texto simples a relatórios de objetos do CRM prontos para uso, e cada um deles oferece opções de personalização por meio de propriedades."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os componentes são importados no topo do arquivo de extensão ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "tsx"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "jsx"
      }), ". Dependendo do tipo de componente, você precisará importá-lo de um dos dois diretórios do SDK."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#standard-components",
          children: "Componentes padrão"
        }), " são importados de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubspot/ui-extensions'"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#crm-data-components",
          children: "Dados do CRM"
        }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#crm-action-components",
          children: "componentes de ação do CRM"
        }), " são importados de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubspot/ui-extensions/crm'"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Alert, Text } from '@hubspot/ui-extensions';\nimport { CrmAssociationPivot, CrmActionLink } from '@hubspot/ui-extensions/crm';\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " para acessar os componentes mais recentes, certifique-se de ter instalado o pacote npm mais recente executando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm i @hubspot/ui-extensions"
        }), " no diretório ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "extensions"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Componentes padrão"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os componentes padrão podem ser usados para dados internos e externos. Eles não buscam dados isoladamente, mas têm uma implementação mais flexível."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esses componentes são importados de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'@hubspot/ui-extensions'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Componente"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/accordion",
              children: "Accordion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma seção accordion colapsável que pode conter outros componentes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/alert",
              children: "Alert"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alertas para indicar o status e os resultados de ações, como mensagens de sucesso e de erro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/box",
              children: "Box"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Componente usado para gerenciamento de layout. Pode ser usado com ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/flex",
              children: "Flex"
            }), ". Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/manage-ui-extension-layout",
              children: "gerenciar o layout de extensões"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Botões que permitem aos usuários realizar ações, como enviar ou recuperar dados."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button-row",
              children: "Button row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Para renderizar vários botões."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/checkbox",
              children: "Checkbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma única entrada de caixa de seleção. Para exibir várias caixas de seleção, use ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/date-input",
              children: "DateInput"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um campo que permite aos usuários selecionar uma data."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/description-list",
              children: "DescriptionList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exibe pares de rótulos e valores personalizados; semelhante à forma como as propriedades do HubSpot aparecem na barra lateral esquerda dos registros do CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/divider",
              children: "Divider"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma linha horizontal que separa componentes dentro de uma extensão."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/dropdown",
              children: "Dropdown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um menu suspenso para seleção de valores, estilizado na forma de botões ou hiperlinks."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/empty-state",
              children: "EmptyState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma ilustração rotulada para indicar um componente sem conteúdo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/error-state",
              children: "ErrorState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ilustrações rotuladas para indicar erros."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/flex",
              children: "Flex"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Envolve outros componentes em uma ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "div"
            }), " vazia definida como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display=flex"
            }), ". Use este componente e ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/box",
              children: "Box"
            }), " para ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/manage-ui-extension-layout",
              children: "gerenciar o layout de extensões"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um formulário para envio de dados que pode conter outros componentes relacionados, como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/input",
              children: "Input"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/heading",
              children: "Heading"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Renderiza texto grande para títulos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/icon",
              children: "Icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Renderiza um ícone visual dentro de outros componentes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma imagem usada principalmente para adicionar logotipos ou outros ativos de identidade visual da marca ou para acentuar outro conteúdo de extensão."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/input",
              children: "Input"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um campo de entrada de texto no qual os usuários podem inserir valores de texto personalizados. Usado principalmente dentro de componentes ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um hiperlink clicável para navegar para páginas externas e do aplicativo HubSpot ou para acionar funções."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/list",
              children: "List"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista classificada ou não classificada de itens."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NOVO"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Semelhante a um componente ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), " com opções adicionais de estado de carregamento."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-spinner",
              children: "LoadingSpinner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um indicador visual de que o cartão está sendo carregado ou processado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/modal",
              children: "Modal"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NOVO"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma sobreposição pop-up contendo outros componentes. Útil para mensagens curtas e caixas de confirmação. Pode ser aberto com os componentes ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/multi-select",
              children: "MultiSelect"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um campo de seleção suspenso no qual os usuários podem selecionar vários valores. Para permitir que apenas um valor seja selecionado, use ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            }), ". Usado principalmente dentro de componentes ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/number-input",
              children: "NumberInput"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um campo de entrada de número. Usado principalmente dentro de componentes ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/panel",
              children: "Painel"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NOVO"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um painel que abre no lado direito da página, contendo outros componentes. Pode ser aberto com os componentes ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/progress-bar",
              children: "ProgressBar"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma representação visual de dados em movimento em direção a um alvo positivo ou negativo. Pode exibir números e porcentagens."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/radio-button",
              children: "RadioButton"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um botão de seleção de opções. Se você quiser incluir mais de dois botões de opção ou estiver criando um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), ", é recomendado usar ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um campo de seleção suspenso no qual um usuário pode selecionar um único valor. Para permitir a seleção de vários valores, use ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/multi-select",
              children: "MultiSelect"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/statistics",
              children: "Statistics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um destaque visual de um ou mais pontos de dados. Inclui valores numéricos e indicadores de tendência (porcentagem crescente/decrescente)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/status-tag",
              children: "StatusTag"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NOVO"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um indicador visual para exibir o status atual de um item."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/step-indicator",
              children: "StepIndicator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um indicador visual que descreve o progresso em um processo de várias etapas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/stepper-input",
              children: "StepperInput"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Semelhante ao componente ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/number-input",
              children: "NumberInput"
            }), ", mas este campo permite que os usuários aumentem ou diminuam o valor por uma quantia definida."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/table",
              children: "Table"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exibe dados em colunas e linhas. As tabelas podem ser paginadas e classificadas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rótulos coloridos para categorizar informações ou outros componentes. Pode ser estático ou clicável para acionar funções."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text",
              children: "Text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Renderiza texto com opções de formatação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tile",
              children: "Tile"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um contêiner retangular com bordas para criar grupos de componentes relacionados."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text-area",
              children: "TextArea"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Semelhante a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text",
              children: "Text"
            }), ", mas para conjuntos de texto mais longos. Inclui props para configurar o tamanho do campo, o número máximo de caracteres e a capacidade de redimensionamento."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle",
              children: "Toggle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um botão booleano de alternância que pode ser configurado com opções de dimensionamento e rótulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista de caixas de seleção ou botões de opção selecionáveis."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Componentes de dados do CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os componentes de dados do CRM podem extrair dados diretamente do registro do CRM que está sendo exibido, incluindo informações sobre registros associados e relatórios de objetos individuais. Esses componentes somente podem ser colocados na coluna central dos registros do CRM. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-data-components/overview",
        children: "componentes de dados do CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esses componentes são importados de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Componente"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-pivot",
              children: "CrmAssociationPivot"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista de registros associados ao registro que está sendo exibido, organizados pelo rótulo de associação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-property-list",
              children: "CrmAssociationPropertyList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista editável de propriedades do CRM pertencentes a um registro associado ao registro que está sendo exibido."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-stage-tracker",
              children: "CrmAssociationStageTracker"
            }), " NOVO"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma barra de progresso de fase do ciclo de vida ou do pipeline que exibe dados dos registros associados. Pode incluir uma lista de propriedades que podem ser editadas em linha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-table",
              children: "CrmAssociationTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma tabela de registros associada ao registro que está sendo exibido."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-data-highlight",
              children: "CrmDataHighlight"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista de propriedades do CRM pertencentes ao registro que está sendo exibido ou a outro registro especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-property-list",
              children: "CRMPropertyList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista editável de propriedades do CRM pertencentes ao registro que está sendo exibido ou a outro registro especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-report",
              children: "CrmReport"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exiba um relatório de objeto individual existente. Inclui opções de filtragem ou pode exibir todos os dados não filtrados do relatório."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-stage-tracker",
              children: "CRMStageTracker"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma barra de progresso do ciclo de vida ou da fase do pipeline com uma lista de propriedades."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-statistics",
              children: "CrmStatistics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exiba resumos de dados de cálculo com base nas associações do registro que está sendo exibido. Por exemplo, a receita média das empresas associadas de um contato."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Componentes de ação do CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os componentes de ação do CRM fornecem um conjunto integrado de ações relacionadas ao CRM, incluindo adição de observações aos registros, abertura de uma janela de composição de um e-mail individual, criação de novos registros e muito mais. Cada componente pode executar o mesmo conjunto de ações; portanto, o componente escolhido dependerá das suas necessidades e preferências. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-action-components/overview",
        children: "componentes de ação do CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os componentes de ação do CRM são importados de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Componente"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-action-button",
              children: "CrmActionButton"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um botão que pode executar um conjunto integrado de ações do CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-action-link",
              children: "CrmActionLink"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um link clicável que pode executar um conjunto integrado de ações do CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-card-actions",
              children: "CrmCardActions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Botões menores, independentes ou de menu suspenso, que podem conter várias ações do CRM."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}