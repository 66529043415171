"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 30866940858;
const slug = exports.slug = 'guides/api/conversations/visitor-identification';
const title = exports.title = 'Besucheridentifikation';
const name = exports.name = 'vNext Docs DP - Besucheridentifikation';
const metaDescription = exports.metaDescription = 'Die API für die Besucheridentifikation wird verwendet, um Besucher Ihrer Website zu identifizieren, die mit externen Authentifizierungssystemen authentifiziert wurden.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "beispiel-f%C3%BCr-einen-integrationsprozess",
  "label": "Beispiel für einen Integrationsprozess",
  "parent": null
}, {
  "depth": 0,
  "id": "die-integration-verifizieren",
  "label": "Die Integration verifizieren",
  "parent": null
}, {
  "depth": 0,
  "id": "chat-widget-sdk-primer",
  "label": "Chat-Widget SDK-Primer",
  "parent": null
}, {
  "depth": 1,
  "id": "sdk-referenz",
  "label": "SDK-Referenz",
  "parent": "chat-widget-sdk-primer"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      h2: "h2",
      a: "a",
      img: "img",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Besucheridentifikation"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise', 'sales_hub-professional', 'sales_hub-enterprise', 'cms-professional', 'cms-enterprise', 'service_hub-professional', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie die Besucheridentifikation-API, um Besucher Ihrer Website zu identifizieren, die mit Ihrem eigenen externen Authentifizierungssystem authentifiziert wurden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein von dieser API zurückgegebenes Identifikationstoken kann verwendet werden, um Informationen über Ihren bereits authentifizierten Besucher an das Chat-Widget weiterzugeben, sodass es den Besucher als bekannten Kontakt behandelt. Die Mitarbeiter im Postfach können dann sicher sein, mit wem sie sprechen, und die Besucher können geräteübergreifend auf frühere Threads zugreifen. Beispiel:"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Die Besucheridentifikation-API dient dazu, HubSpot mitzuteilen, wer der Besucher ist. Sie sollten sich nicht auf sie verlassen, wenn es darum geht, Benutzer auf Ihrer Plattform zu authentifizieren."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Der Zugriff auf die Besucheridentifikation-API erfordert ein Abonnement auf ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), "- oder ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "-Stufe. Wenn der Account kein qualifiziertes Abonnement hat, erhalten Sie von der API eine ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "403"
          }), "-Fehlerantwort."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beispiel für einen Integrationsprozess"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um mit dieser Funktion zu integrieren, müssen Sie über eine vorhandene Webanwendung mit einem Authentifizierungssystem verfügen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bevor Sie loslegen, stellen Sie sicher, dass Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private App"
      }), " eingerichtet haben und der Account, den Sie zu integrieren versuchen, über ein qualifizierendes* Professional*- oder ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "-Abonnement verfügt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hier ist ein Beispiel für einen möglichen Integrationsprozess:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hubfs/Possible%20User%20Identification%20Flow.png",
        alt: "Möglicher Benutzeridentifikationsprozess"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sobald Ihr Kunde in Ihrem System angemeldet und verifiziert ist, führen Sie die folgenden Schritte aus, um ihn im Livechat zu identifizieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "1."
      }), " Legen Sie in Ihrem Frontend loadImmediately für das Objekt hsConversationsSettings im Fenster auf false fest. Wenn Sie dies nicht tun, kann das Chat-Widget geladen werden, bevor die Identifikationsinformationen weitergegeben werden. Weitere Informationen zur API finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#chat-widget-sdk-primer",
        children: "unten im Chat-Widget-SDK-Primer"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Legen Sie die Eigenschaften von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), " außerhalb der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isConversationsAPIReady"
        }), "-Funktion fest."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Außerdem müssen die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), " vor dem Aufruf festgelegt werden, da es sonst zu einer Race Condition kommen kann, die das Laden des Widgets behindert."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "2."
      }), " Generieren Sie ein Token von der API für die Besucheridentifikation, indem Sie die E-Mail-Adresse Ihres authentifizierten Besuchers übergeben. Dies sollte im Backend Ihrer Webanwendung erfolgen. Eine Beispielanfrage finden Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpunkte"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubspot.com/conversations/v3/visitor-identification/tokens/create \\\n--data '{\n  \"email\": \"gob@bluth.com\",\n  \"firstName\": \"Gob\",\n  \"lastName\": \"Bluth\"\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Der angegebene Vor- und Nachname werden in den Kontaktdatensatz in HubSpot festgelegt, nachdem der Chat begonnen hat:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "es handelt sich um einen neuen Kontakt, der über die Besucheridentifikation-API erstellt wurde."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "es handelt sich um einen bestehenden Kontakt, dessen Name noch nicht bekannt ist."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Dies kann nützlich sein, wenn Nachrichten an identifizierte Besucher personalisiert werden, wenn Ihr externes System bereits über Namensinformationen verfügt, diese jedoch noch nicht in HubSpot vorhanden sind. Hierbei handelt es sich um optionale Parameter, die nicht erforderlich sind."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "3."
      }), " Legen Sie unter Verwendung des Tokens aus Schritt 2 die folgenden Eigenschaften für das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), "-Objekt im Fenster fest."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "4."
      }), " Laden Sie das Widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das Token und die E-Mail müssen jedes Mal, wenn die Seite für einen authentifizierten Besucher geladen wird, auf das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), "-Objekt im Fenster festgelegt werden. Dieser Kontext wird nicht automatisch über Seitenladevorgänge übertragen, wenn diese Parameter nicht mehr festgelegt sind. Token bestehen vorübergehend und laufen nach 12 Stunden ab. Token können, solange sie mindestens alle 12 Stunden aktualisiert werden, zwischengespeichert werden, um zu vermeiden, dass das Token bei jedem Laden der Seite erneut abgerufen wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Integration verifizieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sobald Sie Ihre Integration der Besucheridentifikationsfunktion abgeschlossen haben, können Sie überprüfen, ob sie wie erwartet funktioniert. Dies kann auf zwei Arten geschehen, je nach Ihrer Implementierung, sodass Sie die folgenden Beispiele möglicherweise an Ihre spezifischen Anforderungen anpassen müssen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Wenn Sie das Chat-Widget für eine oder mehrere öffentlichen Seiten sowie hinter einem Authentifizierungssystem hinzugefügt haben:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "Gehen Sie zu einer Seite, auf der das Chat-Widget keine Besucher identifiziert und eine Konversation startet."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Öffnen Sie in HubSpot das Postfach und verifizieren Sie, dass der gerade eingegangene Chat in die Kategorie ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Unbekannter Besucher"
              }), " fällt. Wenn dies nicht der Fall ist, versuchen Sie, diese Schritte in einem privaten Browserfenster durchzuführen."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "Gehen Sie zu einer Seite, auf der das Chat-Widget Besucher über die API für die Besucheridentifikation identifiziert und eine Konversation startet."
                }), "\n"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "Öffnen Sie in HubSpot das Postfach und verifizieren Sie, dass der Chat korrekt dem Kontakt zugeordnet ist, unter dem Sie angemeldet sind. Sie sollten neben dem Namen des Kontakts ein Abzeichen sehen, das angibt, dass dieser Kontakt über diese API erfolgreich identifiziert wurde."
                }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: (0, _jsxRuntime.jsx)(_components.img, {
                    src: "https://f.hubspotusercontent00.net/hubfs/53/visitor_identity_badge.png",
                    alt: "visitor_identity_badge"
                  })
                }), "\n"]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Wenn Sie das Chat-Widget nur für Seiten hinter einem Authentifizierungssystem hinzugefügt haben und Zugriff auf mehrere Testbenutzer-Accounts haben:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Melden Sie sich als erster Testbenutzer bei HubSpot an, gehen Sie dann zu einer Seite, auf der das Chat-Widget geladen wird, und starten Sie eine Konversation."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Melden Sie sich von HubSpot ab und als zweiter Testbenutzer wieder an. Gehen Sie zu einer Seite, auf der das Chat-Widget geladen wird, und beginnen Sie eine Konversation."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Öfnnen Sie dann in HubSpot das Postfach und verifizieren Sie, ob die eingegangenen Chats vom ersten bzw. zweitenTest-Account stammen und ob Sie das Abzeichen neben den Kontaktnamen für beide Datensätze sehen."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Für Besucher, die mit dieser API identifiziert werden, setzt HubSpot nicht das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), "-Cookie. HubSpot überspringt auch alle Fragen zur E-Mail-Erfassung, da die E-Mail-Adresse bereits bekannt ist. Da das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), "-Cookie und die E-Mail-Erfassung nicht bei diesen Chats angewendet werden, werden die zugehörigen Einstellungen im Chatflow für Besucher, die über die Besucheridentifikation-API identifiziert wurden, nicht angezeigt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Chat-Widget SDK-Primer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die API ist im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), "-Objekt untergebracht. Es können alle verfügbaren Methoden über dieses Objekt aufgerufen werden. Der HubSpot-Skriptlader auf Ihrer Seite erstellt dieses Objekt für Sie, aber es ist möglicherweise nicht sofort verfügbar. Um den Zugriff auf die API aufzuschieben, bis sie initialisiert ist, können Sie sich mit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " behelfen. Beispiel:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "SDK-Referenz"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "-Array"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies ist ein optionales Feld, das Sie für das Fensterobjekt definieren können und das es Ihnen ermöglicht, Code anzugeben, der ausgeführt wird, sobald das Widget verfügbar ist. Sobald die API initialisiert wurde, überprüft sie auf das Vorhandensein dieses Arrays und führt seine Funktionen der Treihe nach aus."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "if (window.HubSpotConversations) {\n  console.log('The api is ready already');\n} else {\n  window.hsConversationsOnReady = [\n    () => {\n      console.log('Now the api is ready');\n    },\n  ];\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "-Objekt"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieses Objekt ermöglicht es Ihnen, vor dem Initialisieren einige Konfigurationsoptionen für das Widget anzugeben. Um die Besucheridentifikationsfunktion verwenden zu können, müssen Sie die folgenden Felder festlegen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ob das Widget implizit geladen werden soll oder ob gewartet werden soll, bis die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            }), "-Methode aufgerufen wird"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationToken"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wird zur Integration mit der Besucheridentifikation-API verwendet. Dies ist das Token, das vom Endpunkt der Token-Generierung in der Besucheridentifikation-API bereitgestellt wird und als Nachweis dafür dient, dass dieser Besucher identifiziert wurde."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationEmail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die E-Mail-Adresse des Besuchers, den Sie als Benutzer identifiziert haben, der das Widget lädt."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/conversations/chat-widget-sdk",
        children: "Conversations SDK"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}