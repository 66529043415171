"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400681;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/overview';
const title = exports.title = 'Zones de glisser-déposer';
const name = exports.name = 'EMEA FR - Drag and Drop Areas';
const metaDescription = exports.metaDescription = 'Les zones de glisser-déposer permettent aux créateurs de contenu de placer des modules, de modifier la mise en page et certains styles dans les éditeurs de contenu. Les développeurs créent des mises en page prédéfinies.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "l-exp%C3%A9rience-du-cr%C3%A9ateur-de-contenu",
  "label": "L'expérience du créateur de contenu",
  "parent": null
}, {
  "depth": 0,
  "id": "l-exp%C3%A9rience-du-d%C3%A9veloppeur",
  "label": "L'expérience du développeur",
  "parent": null
}, {
  "depth": 0,
  "id": "zones-de-glisser-d%C3%A9poser-et-%C3%A9l%C3%A9ments-associ%C3%A9s",
  "label": "Zones de glisser-déposer et éléments associés",
  "parent": null
}, {
  "depth": 1,
  "id": "section",
  "label": "Section",
  "parent": "zones-de-glisser-d%C3%A9poser-et-%C3%A9l%C3%A9ments-associ%C3%A9s"
}, {
  "depth": 1,
  "id": "colonne",
  "label": "Colonne",
  "parent": "zones-de-glisser-d%C3%A9poser-et-%C3%A9l%C3%A9ments-associ%C3%A9s"
}, {
  "depth": 1,
  "id": "ligne",
  "label": "Ligne",
  "parent": "zones-de-glisser-d%C3%A9poser-et-%C3%A9l%C3%A9ments-associ%C3%A9s"
}, {
  "depth": 1,
  "id": "module",
  "label": "MODULE",
  "parent": "zones-de-glisser-d%C3%A9poser-et-%C3%A9l%C3%A9ments-associ%C3%A9s"
}, {
  "depth": 0,
  "id": "structure-et-style-html",
  "label": "Structure et style HTML",
  "parent": null
}, {
  "depth": 1,
  "id": "style-de-l-%C3%A9diteur-et-des-attributs",
  "label": "Style de l'éditeur et des attributs",
  "parent": "structure-et-style-html"
}, {
  "depth": 0,
  "id": "migration",
  "label": "Migration",
  "parent": null
}, {
  "depth": 0,
  "id": "tutoriels-et-guides-associ%C3%A9s",
  "label": "Tutoriels et guides associés",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Zones de glisser-déposer"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette page illustre et explique l'expérience et les concepts des zones de glisser-déposer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ". Il s'agit d'un élément très avancé. Il est donc nécessaire de se familiariser au préalable avec le code."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous êtes prêt à développer, consultez la section ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "Démarrer avec dnd_area"
      }), ", et la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "Référence dnd_area"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les zones de glisser-déposer permettent aux développeurs de créer des zones de pages et des partials globaux qui aident les créateurs de contenu dans la réalisation de leurs tâches. Les zones de glisser-déposer permettent aux créateurs de contenu de placer des modules, de modifier la mise en page et certains styles dans les éditeurs de contenu. Cela vous permet de créer moins de modèles utilisables par les créateurs de contenu pour générer eux-mêmes une multitude de pages aux objectifs et aux mises en page variés. Les créateurs de contenu peuvent le faire sans avoir à manipuler de code ou à utiliser de nouveaux modèles pour de petites modifications de la mise en page. Les zones de glisser-déposer sont polyvalentes et pratiques. C'est pourquoi au moins un modèle de votre thème devrait en être doté."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " pour le moment, les zones de glisser-déposer ne sont pas utilisables dans les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "articles de blog"
        }), " ni dans les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#email",
          children: "modèles d'e-mail"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "L'expérience du créateur de contenu"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsqu'un créateur de contenu crée une page à l'aide d'un modèle qui comporte des zones de glisser-déposer, il voit d'abord la page avec des modules prédéfinis placés dans une mise en page donnée déterminée par le développeur. Cela donne au créateur de contenu une idée de ce à quoi ressembleront la plupart des pages lors du rendu. Il s'agit d'un point de départ pour les créateurs de contenu et non pas d'une apparence figée. Les créateurs de contenu peuvent glisser et déposer les modules, les sections, les lignes et les colonnes pour les réorganiser. Ils peuvent les redimensionner, modifier leur contenu et ajuster divers paramètres de style visuel afin d'aligner verticalement et horizontalement le contenu et d'ajouter des arrière-plans."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les créateurs de contenu disposent ainsi d'un vaste panel d'options. Ils peuvent ainsi apporter de simples modifications aux pages sans avoir à faire appel à un développeur pour de petits ajustements."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " un créateur de contenu peut échanger le modèle d'une page contre un autre modèle du même type, selon qu'il possède ou non des balises ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles construits avec l'éditeur visuel de mise en page par glisser-déposer peuvent être remplacés par d'autres modèles par glisser-déposer ou par des modèles codés avec ou sans les balises", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles codés avec des balises ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " ne peuvent être échangés que contre d'autres modèles codés avec des balises ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles codés ", (0, _jsxRuntime.jsx)("u", {
            children: "sans"
          }), " balises ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " ne peuvent être échangés que contre d'autres modèles codés sans balises ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/drag-and-drop.gif",
        alt: "L'expérience de l'éditeur de page pour les zones de glisser-déposer dnd_areas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "L'expérience du développeur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour les développeurs, travailler avec dnd_area revient à travailler avec les cadres CSS courants et leurs grilles. Les développeurs organisent la page à l'aide de conteneurs appelés « sections », qui contiennent des lignes. À l'intérieur de ces lignes se trouvent des modules et des colonnes. Presque tout ce que les créateurs de contenu peuvent faire dans l'éditeur de page avec les balises de zones de glisser-déposer, les développeurs peuvent le faire eux-mêmes sous forme de code dans les modèles. Ce que les développeurs construisent est le contenu par défaut des pages utilisant ce modèle."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'avantage d'utiliser des zones de glisser-déposer plutôt que de coder en dur les balises des modules dans le modèle est que les créateurs de contenu peuvent modifier le contenu et la mise en page à l'intérieur de ces zones. Grâce à cette flexibilité, un seul modèle doté de zones de glisser-déposer peut servir à la conception d'une multitude de pages."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consultez les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "modèles de boilerplate du thème CMS"
      }), " pour avoir un aperçu des balises ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " en situation."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd%20coded.png",
        alt: "dnd_area codé en VS Code"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zones de glisser-déposer et éléments associés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les zones de glisser-déposer sont constituées de blocs de construction qui définissent la mise en page et assurent une certaine souplesse en matière d'alignement du texte. La balise ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), " crée une zone dans la page reconnaissable par l'éditeur de page, ce qui permet d'ajouter, de supprimer et de réorganiser des éléments par glisser-déposer. Elles sont définies à l'aide de HubL dans des modèles HTML et HubL. Tous les éléments de glisser-déposer se trouvent dans une zone de glisser-déposer. Les zones de glisser-déposer ne peuvent pas être imbriquées. Les éléments de glisser-déposer ne peuvent pas contenir eux-mêmes des zones de glisser-déposer."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le contenu de la zone de glisser-déposer définie par le développeur dans le modèle constitue un point de départ pour les pages utilisant ce modèle. Les créateurs de contenu peuvent complètement modifier le contenu présent dans une zone de glisser-déposer. Votre travail consiste à leur fournir un bon point de départ qu'ils peuvent ensuite modifier à leur convenance."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Example%20structure.png",
        alt: "Exemple de structure"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce diagramme illustre la manière dont les différents éléments de glisser-déposer peuvent être imbriqués. L'un des concepts clés est que les sections et les lignes peuvent contenir des colonnes et des modules."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Drag%20and%20Drop%20relationships.png",
        alt: "Relations entre les éléments de glisser-déposer"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Section"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les sections sont un type spécifique de ligne. Les sections sont créées dans les modèles à l'aide de la balise ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-section-code-dnd-section-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        })
      }), ". Elles sont le seul élément de glisser-déposer pouvant être un descendant direct d'une zone de glisser-déposer. Les sections servent d'encadrement extérieur. Elles peuvent permettre au contenu d'être en pleine largeur ou d'avoir une largeur maximale confinée au centre. Comme les sections s'articulent autour des colonnes et des modules, il est facile de réorganiser et d'implémenter de grandes quantités de contenu. La balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " n'assure pas le rendu d'un élément HTML ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Section%20in%20page%20editor.png",
        alt: "Section dans l'éditeur de page"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'apparence d'une section dans l'éditeur de page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les développeurs peuvent fournir des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-templates",
        children: "modèles de section"
      }), " qui créent des sections réutilisables dans l'éditeur de page. Il s'agit de sections préconfigurées définies par le développeur, que les créateurs de contenu peuvent utiliser comme point de départ. Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#reusable-sections",
        children: "sections réutilisables"
      }), " ont de nombreuses spécificités : elles peuvent notamment être utilisées comme un modèle de partial standard de hubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Colonne"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les colonnes servent de cadre aux lignes et aux modules. Elles sont placées à l'intérieur d'une ligne, ou d'une section qui est une ligne spécialisée. Les colonnes peuvent être créées dans les modèles à l'aide de la balise ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-column-code-dnd-column-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisez plusieurs colonnes à l'intérieur d'une ligne pour placer horizontalement les lignes et les modules qu'elles contiennent."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les colonnes sont des zones verticales qui peuvent contenir des lignes. Vous pouvez créer des colonnes de tailles différentes en modifiant leur largeur. La largeur d'une ligne est de 12 « colonnes », en référence à la grille CSS. Les colonnes situées à l'intérieur d'une ligne peuvent avoir toute taille inférieure à 12, mais leur total ne peut pas dépasser 12."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque plusieurs lignes sont placées à l'intérieur d'une colonne, les modules de ces lignes apparaissent empilés verticalement. Comme les modules sont eux-mêmes des colonnes, ils ne peuvent pas être des descendants directs d'une colonne. Ils doivent être contenus dans une ligne."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20column%20in%20page%20editor.png",
        alt: "Colonne dnd_area dans l'éditeur de page"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'apparence d'une colonne dans l'éditeur de page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ligne"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les lignes encadrent les colonnes. Les lignes peuvent être créées dans les modèles à l'aide de la balise ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-row-code-dnd-row-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        })
      }), ". Comme les modules sont des colonnes, vous pouvez les placer directement à l'intérieur d'une ligne. Ainsi, les modules apparaîtront horizontalement les uns à côté des autres."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les modules peuvent être organisés verticalement en les plaçant à l'intérieur de lignes. Si vous voulez placer un module au-dessus d'un autre, vous devez placer ce module à l'intérieur d'une ligne. Vous devez ensuite ajouter un autre module dans une ligne au-dessus ou en dessous de cette première ligne."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20row%20in%20page%20editor.png",
        alt: "Ligne dnd_area dans l'éditeur de page"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'apparence d'une ligne dans l'éditeur de page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "MODULE"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), " sont une partie fondamentale du CMS HubSpot, agissant comme des blocs de construction réutilisables destinés à assembler un site et afficher du contenu. Lors de la création d'un modèle, vous placez les modules à l'intérieur des lignes et des sections de glisser-déposer en utilisant la balise ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-module-code-dnd-module-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), ". Les modules sont également des colonnes. Cela signifie que si vous placez deux balises de module, ou un module et une colonne directement l'un à côté de l'autre, ils apparaîtront côte à côte horizontalement."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il est impossible de placer des éléments de glisser-déposer dans un module. Les modules ne peuvent pas être les enfants directs de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Structure et style HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois rendus, les zones de glisser-déposer et leurs éléments associés ont des noms de classe pour une grille à 12 colonnes développée sous bootstrap 2. Pour faciliter la mise en route, vous pouvez utiliser le fichier ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "_layout.css"
      }), " du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/",
        children: "boilerplate du thème CMS"
      }), ". Ils fournissent des styles par défaut pour ces noms de classe."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous n'êtes pas obligé d'utiliser cette feuille de style et pouvez définir vos propres styles à la place. Si vous construisez votre site à partir du boilerplate du thème CMS et que vous souhaitez utiliser votre propre CSS, vous devez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/5abaf2a4c45a95dbed1d459f7f0f6407350752ac/src/templates/layouts/base.html#L8",
        children: "supprimer l'appel de layout.css dans base.html"
      }), ". Pour votre propre grille CSS, vous devrez cibler ces mêmes noms de classe de grille, mais le style vous appartient."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors du rendu, les zones de glisser-déposer créent des balises div avec des classes utilisées par l'éditeur de page. Les exemples sont ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-span"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-type-cell"
      }), ". Ne ciblez pas directement ces classes, car elles sont utilisées par l'éditeur de pages et peuvent évoluer dans le temps."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Au lieu de cela, ajoutez dans votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), " HubL un paramètre de classe avec le nom de la classe que vous souhaitez utiliser."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"container-fluid my-custom-class\">\n  <div class=\"row-fluid-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n      <!--end widget-span -->\n    </div>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Style de l'éditeur et des attributs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Grâce aux zones de glisser-déposer, les créateurs de contenu peuvent avoir une certaine influence sur le style de la page. Ils peuvent par exemple faire en sorte qu'une section ait un arrière-plan. Les développeurs peuvent transmettre des valeurs par défaut pour ces paramètres par le biais d'attributs."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors du rendu de la page, les styles générés en fonction de ces paramètres sont ajoutés au fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Au lancement de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), ", ces styles étaient chargés à partir de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        }), ". Cela a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/drag-and-drop-area-styles-moved-from-footer-to-header",
          children: "récemment"
        }), " été remplacé par ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        }), " et est en cours de déploiement sur tous les comptes HubSpot via le CMS HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous changez des modèles construits avec des colonnes flexibles pour utiliser des zones de glisser-déposer, gardez à l'esprit certains éléments. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Les colonnes flexibles ne sont pas les mêmes que les zones de glisser-déposer. Vous ne pouvez pas passer d'un modèle qui ne comporte qu'une colonne flexible à un modèle qui ne comporte qu'une zone de glisser-déposer"
      }), ". Par mesure de sécurité, cette option n'est pas autorisée. Le contenu ne peut pas être transféré de la colonne flexible à la zone de glisser-déposer. Pour illustrer ce phénomène, supposons que vous ayez construit votre nouveau modèle de manière à avoir une barre latérale et une zone de contenu principal. Votre barre latérale est une colonne flexible, votre contenu principal est une zone de glisser-déposer. L'outil de permutation fait correspondre la colonne flexible à la colonne flexible."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriels et guides associés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites#drag-and-drop-areas",
          children: "Ajout de zones de glisser-déposer à des sites web existants"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Création d'une zone de dépôt"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}