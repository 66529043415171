"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 99666557868;
const slug = exports.slug = 'guides/cms/marketplace/module-requirements';
const title = exports.title = 'Marketplace des modèles | Exigences relatives aux modules';
const name = exports.name = 'EMEA (FR) Asset Marketplace | Module requirements';
const metaDescription = exports.metaDescription = "Découvrez les exigences auxquelles les modules d'un thème doivent répondre lors de la soumission au marketplace des modèles de HubSpot.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "restrictions-relatives-aux-modules",
  "label": "Restrictions relatives aux modules",
  "parent": null
}, {
  "depth": 0,
  "id": "contenu-du-module",
  "label": "Contenu du module",
  "parent": null
}, {
  "depth": 1,
  "id": "libell%C3%A9s-de-module-et-texte-d-aide",
  "label": "Libellés de module et texte d'aide",
  "parent": "contenu-du-module"
}, {
  "depth": 1,
  "id": "contenu-par-d%C3%A9faut",
  "label": "Contenu par défaut",
  "parent": "contenu-du-module"
}, {
  "depth": 1,
  "id": "ic%C3%B4nes-de-module",
  "label": "Icônes de module",
  "parent": "contenu-du-module"
}, {
  "depth": 1,
  "id": "modules-n%C3%A9cessitant-des-comptes-tiers",
  "label": "Modules nécessitant des comptes tiers",
  "parent": "contenu-du-module"
}, {
  "depth": 0,
  "id": "champs-de-module",
  "label": "Champs de module",
  "parent": null
}, {
  "depth": 1,
  "id": "configuration-des-fichiers-fields.json-et-module.html",
  "label": "Configuration des fichiers fields.json et module.html",
  "parent": "champs-de-module"
}, {
  "depth": 0,
  "id": "qualit%C3%A9-du-code-du-module",
  "label": "Qualité du code du module",
  "parent": null
}, {
  "depth": 1,
  "id": "les-modules-doivent-%C3%AAtre-autonomes",
  "label": "Les modules doivent être autonomes",
  "parent": "qualit%C3%A9-du-code-du-module"
}, {
  "depth": 2,
  "id": "modules-de-th%C3%A8me",
  "label": "Modules de thème",
  "parent": "qualit%C3%A9-du-code-du-module"
}, {
  "depth": 2,
  "id": "modules-ind%C3%A9pendants",
  "label": "Modules indépendants",
  "parent": "qualit%C3%A9-du-code-du-module"
}, {
  "depth": 1,
  "id": "restrictions-de-code-pour-les-modules-ind%C3%A9pendants",
  "label": "Restrictions de code pour les modules indépendants",
  "parent": "qualit%C3%A9-du-code-du-module"
}, {
  "depth": 1,
  "id": "cat%C3%A9gories",
  "label": "Catégories",
  "parent": "qualit%C3%A9-du-code-du-module"
}, {
  "depth": 1,
  "id": "s%C3%A9lecteurs-de-nom-de-classe",
  "label": "Sélecteurs de nom de classe",
  "parent": "qualit%C3%A9-du-code-du-module"
}, {
  "depth": 1,
  "id": "styles-et-javascript",
  "label": "Styles et JavaScript",
  "parent": "qualit%C3%A9-du-code-du-module"
}, {
  "depth": 0,
  "id": "organisation-des-champs",
  "label": "Organisation des champs",
  "parent": null
}, {
  "depth": 1,
  "id": "onglet-contenu",
  "label": "Onglet Contenu",
  "parent": "organisation-des-champs"
}, {
  "depth": 1,
  "id": "onglet-styles",
  "label": "Onglet Styles",
  "parent": "organisation-des-champs"
}, {
  "depth": 1,
  "id": "exemples-d-organisation-des-champs",
  "label": "Exemples d'organisation des champs",
  "parent": "organisation-des-champs"
}, {
  "depth": 2,
  "id": "pr%C3%A9r%C3%A9glages",
  "label": "Préréglages",
  "parent": "organisation-des-champs"
}, {
  "depth": 2,
  "id": "regroupement-multi-niveaux",
  "label": "Regroupement multi-niveaux",
  "parent": "organisation-des-champs"
}, {
  "depth": 2,
  "id": "regroupement-de-champs-individuels",
  "label": "Regroupement de champs individuels",
  "parent": "organisation-des-champs"
}, {
  "depth": 0,
  "id": "alias",
  "label": "Alias",
  "parent": null
}, {
  "depth": 1,
  "id": "mise-en-%C5%93uvre-simple",
  "label": "Mise en œuvre simple",
  "parent": "alias"
}, {
  "depth": 0,
  "id": "example-of-a-v0-module",
  "label": "Example of a v0 module",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v1-module",
  "label": "Example of a v1 module",
  "parent": null
}, {
  "depth": 0,
  "id": "complex-implementation",
  "label": "Complex implementation",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v0-module",
  "label": "Example of a v0 module",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v1-module",
  "label": "Example of a v1 module",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      h3: "h3",
      em: "em",
      img: "img",
      code: "code",
      h4: "h4",
      strong: "strong",
      pre: "pre",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      Alert,
      DndModule,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Exigences relatives aux modules du marketplace des modèles HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez les exigences pour soumettre un module dans le marketplace des modèles. Ces exigences s'appliquent à la fois aux modules d'un thème et aux modules indépendants."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Restrictions relatives aux modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modules ne doivent pas contenir la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "fonctionnalité HubDB"
      }), ", des appels à des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "fonctions sans serveur"
      }), " ou des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#crm-object",
        children: "champs d'objet CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les types de modules suivants ne doivent pas être créés en tant que modules indépendants."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modules en pleine largeur"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Formulaires et formulaires à plusieurs étapes"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modules d'espacement ou modules qui créent une structure de page hors interface utilisateur"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modules qui dupliquent les fonctionnalités de module par défaut"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modules commerciaux spécifiques"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modules spécifiques aux e-mails"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contenu du module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez les exigences relatives aux libellés des modules et au texte d'aide, aux champs et au contenu par défaut."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Libellés de module et texte d'aide"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les modules doivent avoir des libellés descriptifs qui précisent l'objectif du module. Le libellé ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero banner avec défilement parallaxe"
        }), " est descriptif, alors que les libellés ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero banner"
        }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Galerie"
        }), " ne le sont pas."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les libellés de module ne doivent pas contenir de chiffres, comme ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero banner 01"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les libellés de module ne doivent pas contenir de traits de soulignement."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les libellés de module ne doivent pas contenir d'abréviations, comme ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Col"
        }), " au lieu de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Colonne"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les modules doivent contenir ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#meta-json",
          children: "du texte d'aide en ligne"
        }), " (le cas échéant) pour indiquer plus en détail comment utiliser le module."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les modules ne doivent pas être nommés de la même manière qu'un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "module par défaut"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour les modules indépendants, le libellé du module doit correspondre au nom figurant sur le listing de modèles. Par exemple, si votre listing de modèles est ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Superbe bannière avec défilement"
        }), ", le libellé de votre module doit être le même."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-name.png",
          alt: "listing-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-label.png",
          alt: "module-label"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Contenu par défaut"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le champ par défaut ne peut pas inclure de texte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "lorem ipsum"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le contenu du champ par défaut doit représenter l'objectif du champ :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Lors de l'inclusion des champs de menu, les modules doivent utiliser par défaut l'option de contenu ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Sélectionner un menu"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Lors de l'inclusion de champs de formulaire, les modules doivent utiliser par défaut l'option de contenu ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Sélectionner un formulaire"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Lors de l'inclusion de champs de sélecteur de blog, les modules doivent utiliser par défaut l'option de contenu ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Sélectionner un blog"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si l'ajout de contenu par défaut à un module n'est pas pertinent, utilisez plutôt un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
          children: "espace réservé de module"
        }), " pour aider le créateur de contenu à visualiser l'espace qu'il remplira."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Icônes de module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modules doivent inclure une icône personnalisée attribuée au module (remplaçant l'icône par défaut). N'utilisez pas les logos d'entreprise comme icônes, tels que les logos Apple ou Amazon. Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-an-icon",
        children: "icônes de module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modules nécessitant des comptes tiers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les modules individuels, si le module nécessite un compte tiers, il doit être noté dans la description du modèle. Par exemple, si votre module utilise la plate-forme Google Maps, vous devez inclure une note ", (0, _jsxRuntime.jsx)(_components.em, {
        children: ": « L'utilisation de ce module nécessite un compte Google Cloud (plate-forme Google Maps). »"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Champs de module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Consultez les conditions spécifiques pour les modules d'un thème et les modules indépendants ci-dessous :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour les modules d'un thème :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ils doivent contenir du texte d'aide en ligne et un contenu par défaut spécifique pour certains champs."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Une partie de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#color",
              children: "couleur"
            }), " du thème et du ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#logo",
              children: "logo"
            }), " doit hériter des ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/fields/brand-and-settings-inheritance",
              children: "paramètres de marque"
            }), " du compte.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Au minimum, trois champs de couleur doivent hériter des couleurs des paramètres de marque du compte. Les champs de couleur supplémentaires peuvent être remplacés par défaut par d'autres couleurs, y compris le noir et le blanc."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Au moins un champ de logo doit hériter des paramètres de marque du compte. Si vous utilisez un champ d'image pour afficher un logo, le champ d'image n'a pas à hériter des paramètres de marque."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour les modules d'un thème et des modules indépendants :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Les noms des champs du module doivent décrire l'intention du champ. Par exemple, si un champ de texte est destiné à inclure l'intitulé du poste d'une personne, ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Intitulé de poste"
            }), " serait une description appropriée alors que ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Intitulé"
            }), " ne le serait pas."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Tous les modules par défaut de HubSpot doivent être stylisés et doivent s'afficher correctement sur tous les modèles soumis."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configuration des fichiers fields.json et module.html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour garantir une fonctionnalité compatible entre les thèmes et les modules indépendants, les modules doivent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#inherited-fields",
        children: "hériter"
      }), " des champs de couleur et de police en définissant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "default_value_path"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_path"
      }), ", ou les deux dans leurs fichiers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " et ajouter une référence aux champs du thème dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/general-requirements",
        children: "Découvrez-en davantage sur ces exigences."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Qualité du code du module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Les modules doivent être autonomes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Modules de thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les fichiers nécessaires à votre module de thème, tels que CSS ou JavaScript, doivent être contenus dans le dossier du thème et inclus dans le répertoire du thème. Vous pouvez utiliser la fonction de fichier lié dans le gestionnaire de conception. Autrement, vous pouvez inclure les fichiers en utilisant les fonctions ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "require_js()"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-css",
        children: "require_css()"
      }), " avec un chemin relatif vers le fichier."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les bibliothèques courantes, telles que slick.js, vous pouvez les inclure à l'aide des fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), " avec une URL absolue vers le réseau de diffusion de contenu où ils sont hébergés."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " N'utilisez pas d'URL absolues pour les ressources contenues dans votre portail de développement, car les références entre les portails ne seront pas résolues."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Modules indépendants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les modules indépendants, tous les fichiers CSS et JavaScript doivent être contenus dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), ". Vous pouvez également inclure les fichiers à l'aide des fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), " avec une URL absolue vers le réseau de diffusion de contenu où ils sont hébergés. Il n'est pas possible d'utiliser la fonctionnalité de fichier lié dans le gestionnaire de conception, car elle n'est disponible que pour les modules de thème."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Étant donné que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " est inclus dans le DOM avant tout fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), ", le JavaScript contenu dans la section ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " doit être différé à l'aide de l'annotation ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "document.addEventListener('DOMContentLoaded', function () {\n  // Put Javascript here\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les scripts et fichiers doivent être rendus dans l'en-tête du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "HTML"
      }), " du module."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Restrictions de code pour les modules indépendants"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les restrictions suivantes s'appliquent uniquement aux modules indépendants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Il est recommandé d'utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://vanilla-js.com/",
          children: "Vanilla JS"
        }), " dans la mesure du possible. L'ajout d'une bibliothèque jQuery à un site qui n'utilise pas jQuery peut potentiellement provoquer des conflits et ralentir la page du site Web."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous utilisez une bibliothèque jQuery, utilisez la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js()"
        }), " pour inclure la bibliothèque si jQuery est désactivé avec la case à cocher (booléen) dans les paramètres du compte afin d'éviter les conflits provenant de plusieurs bibliothèques jQuery."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "{% if not site_settings.include_jquery %}\n{{ require_js(\"https://code.jquery.com/jquery-3.7.0.min.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Catégories"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tous les modules indépendants doivent avoir au moins une catégorie. Il n'est pas obligatoire d'inclure des catégories pour les modules soumis dans le cadre d'un thème, mais il est recommandé d'en inclure au moins une. Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "ajout de catégories à des modules"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sélecteurs de nom de classe"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tout sélecteur de nom de classe doit être précédé du nom du module, en remplaçant les espaces par des traits d'union. Par exemple, ci-dessous se trouve le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " d'un bouton intitulé ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "example-button"
        }), ", avec chaque nom de classe et sélecteur CSS reflétant son nom."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<style>\n{% scope_css %}\n{# Button wrapper #}\n {% if module.styles.group_alignment.alignment %}\n  .example-button-wrapper {\n   text-align: {{ module.styles.group_alignment.alignment.horizontal_align }};\n   }\n {% endif %}\n\n{# Button #}\n\n.example-button {\n {% if module.styles.group_background.color.color %}\n  background-color: rgba({{ module.styles.group_background.color.color|convert_rgb }}, {{ module.styles.group_background.color.opacity / 100 }});\n {% endif %}\n }\n {% end_scope_css %}\n</style>\n{% end_require_css %}\n\n{##### Module HTML #####}\n\n<div class=\"example-button-wrapper\">\n <a href=\"{{ href }}\" class=\"example-button\"\n {% if module.button_link.open_in_new_tab %}target=\"_blank\"{% endif %}\n {% if rel %}rel=\"{{ rel|join(\" \") }}\"{% endif %}\n >\n  {{ module.button_text }}\n </a>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styles et JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Styles :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les modules ne doivent pas avoir de groupe de style vide."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Le codage en dur des styles en ligne dans les modules est déconseillé. Au lieu de cela, utilisez des styles en ligne dynamiques en activant les champs pour contrôler le style."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["JavaScript", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "JavaScript doit pouvoir représenter plusieurs instances d'un module. Javascript dans JS Pane ne sera chargé qu'une fois par page, quel que soit le nombre d'occurrences de modules."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "JavaScript doit référencer les éléments DOM par des noms de classe spécifiques pour garantir que les éléments extérieurs au module ne sont pas affectés par inadvertance."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la création de modules, vous pouvez utiliser une variable intégrée appelée ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{name}}"
      }), ". Cette variable extrait l'ID d'instance du module (qui peut être utilisé dans le panneau HTML+HubL uniquement) pour favoriser le balisage CSS et JS pour les modules complexes. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/files#require-css-block",
        children: "Découvrez-en davantage à ce sujet dans la documentation des développeurs."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Organisation des champs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les exigences suivantes en matière d'organisation et de regroupement des champs doivent être respectées."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Contenu"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Lorsqu'il y a au moins un contrôle au sein d'un groupe de champs, tous les contrôles doivent être regroupés en catégories libellées selon leur fonction."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les champs de module ajoutés à l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contenu"
        }), " doivent permettre de personnaliser le contenu d'un module. Par exemple, les contrôles d'image, d'icône, de texte alternatif et de lien."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Styles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les groupes de champs de style de module doivent être cohérents et suivre un modèle. Découvrez ci-dessous l'ordre recommandé pour vos groupes de champs de style. Ces groupes peuvent être soit au niveau supérieur, soit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#multi-level-grouping",
        children: "imbriqués dans un groupe"
      }), ". Les groupes vides peuvent également être supprimés :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#presets",
          children: "Préréglages"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Texte"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Arrière-plan"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Bordure"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Survol"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Angle"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Espacement"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Alignement"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Groupes de styles personnalisés qui ne correspondent pas à ce qui précède"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avancé"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les types de champs suivants doivent être contenus dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Styles"
      }), ", le cas échéant :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#alignment",
          children: "Alignement"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#background-image",
          children: "Image d'arrière-plan"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "Bordure"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "Couleur"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "Police"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#gradient",
          children: "Dégradé"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "Espacement"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#text-alignment",
          children: "Alignement du texte"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Lorsque vous déplacez des champs de l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contenu"
        }), " vers l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Styles"
        }), ", découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#aliases",
          children: "utiliser le mappage d'alias"
        }), " pour préserver le style des modules déjà utilisés sur les pages en direct."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Les options d'animation doivent toujours être positionnées à la fin de la liste des groupes de champs."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Les options qui permettent aux créateurs de contenu d'ajouter des extraits de code ou des CSS doivent être regroupées à la fin de la liste des groupes de champs sous un champ intitulé ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Avancé"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Les contrôles doivent être uniformisés dans tous les modules. Par exemple, tous les éléments qui peuvent avoir un contrôle de rayon de bordure doivent proposer ce contrôle. Évitez de proposer des contrôles sur des modules qui sont absents sur d'autres."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Les champs de module ajoutés à l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Style"
          }), " doivent permettre de styliser le module. Par exemple :"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les options de style incluent la couleur, le style de texte, l'alignement, l'espacement, la bordure et le rayon d'angle."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les animations incluent des effets au survol et concernant les diapositives."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les préréglages incluent des thèmes sombres et clairs qui sont destinés à changer de nombreux styles en même temps."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemples d'organisation des champs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Préréglages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les préréglages peuvent être utilisés lorsque vous souhaitez donner aux créateurs de contenu un ensemble limité d'options, souvent liées aux paramètres du thème. Par exemple, le module ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Icône"
      }), " inclus dans le thème Growth contient des préréglages pour les couleurs ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Dark"
      }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Light"
      }), ", ce qui permet une cohérence lorsqu'il est utilisé sur le site web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Regroupement multi-niveaux"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous décidez de garder les champs de style au niveau supérieur ou de les imbriquer, tenez compte de l'exemple suivant."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Le module ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Icône"
            }), " inclus dans le thème Growth répertorie tous ses styles au niveau supérieur, car il s'agit d'un composant unique. Ses options de style ont donc toutes un impact sur le composant unique."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-icon-module.png",
            alt: "growth-theme-icon-module"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Le module ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Carte d'intervenant"
            }), " inclus dans le thème Growth contient plusieurs composants : l'image de la carte et son contenu textuel. Les styles de module sont donc regroupés par composant afin que le créateur de contenu dispose d'un processus plus clair pour le style de chaque composant."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-speaker-card.png",
            alt: "growth-theme-speaker-card"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Regroupement de champs individuels"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le module de bouton ci-dessous contient des groupes pour les ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "préréglages"
      }), ", le ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "texte"
      }), ", l'", (0, _jsxRuntime.jsx)(_components.em, {
        children: "arrière-plan"
      }), " et plus encore. Bien que le groupe de champs ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Angle"
      }), " contienne uniquement le contrôle du rayon, il est toujours regroupé pour créer une expérience de création de contenu uniforme."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-requirements-2_1.png",
        alt: "module-requirements-2_1"
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/button-styles.png",
        alt: "button-styles"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alias"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le mappage d'alias vous permet de créer des mappages de champs dans un module afin de pouvoir déplacer, renommer ou remplacer ses champs sans affecter les pages utilisant le module."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, un module est utilisé sur une page en direct. Vous souhaitez déplacer certains champs dans l'onglet ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Styles"
        })
      }), ", tels que la couleur ou la police, mais un créateur de contenu a déjà sélectionné des valeurs pour ces champs dans l'éditeur. Si vous deviez déplacer ces champs sans configurer de mappage d'alias, HubSpot ne serait pas en mesure de les déplacer et ils reviendraient à leurs valeurs par défaut, ce qui annulerait le style de la page en direct."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Au lieu de cela, vous pouvez ajouter une propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), " à un champ pour le mapper à un autre. Ensuite, lorsqu'une valeur n'a pas été définie pour le champ d'origine, HubSpot vérifiera si une valeur existe dans le champ mappé. Si aucune valeur n'existe dans le champ mappé, il utilisera la valeur par défaut à la place. Cette propriété peut être utilisée pour mapper les valeurs de champ entre différentes versions d'un module uniquement lorsque le type de données stockées de l'ancien champ est identique au type de données stockées du nouveau champ."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour une présentation visuelle de cette fonctionnalité, consultez la vidéo ci-dessous."
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SvESTX8SLJQ",
      maxHeight: "367px",
      maxWidth: "700px",
      allowFullScreen: true
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour migrer des champs existants vers des alias :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Créez de nouveaux champs et mappez-les à d'anciens champs à l'aide de la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aliases_mapping"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Supprimez l'ancienne définition de champ."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Mettez à jour le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " pour utiliser la nouvelle définition de champs."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Vous ne pouvez pas mapper des champs qui sont d'un type de données différent les uns des autres. Par exemple, vous ne pouvez pas mapper un champ de dégradé d'arrière-plan à un champ d'image. La valeur stockée doit être une valeur valide pour le type du nouveau champ."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Lors de la création d'un nouveau champ avec un mappage d'alias vers un ancien champ, les valeurs par défaut et les propriétés requises des deux champs devront être identiques."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous des exemples de mise en œuvre pour des changements simples et complexes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#simple-implementation",
          children: "Mise en œuvre simple"
        }), " : mappage d'un champ de couleur à un autre champ de couleur."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#complex-implementation",
          children: "Mise en œuvre complexe"
        }), " : mappage d'un champ numérique au sous-champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "size"
        }), " du champ de police pour contrôler la taille de police."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mise en œuvre simple"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans des situations simples, le type de champ de l'ancien champ et le type de champ du nouveau champ doivent être les mêmes. Par exemple :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ancien champ de couleur vers nouveau champ de couleur."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ancien champ de texte vers nouveau champ de texte."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ancien champ d'espacement vers nouveau champ d'espacement."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez ci-dessous un exemple d'utilisation de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), " lors du déplacement d'un champ de couleur de l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Contenu"
      }), " du module vers l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Styles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v0 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Button Color\",\n    \"name\": \"old_button_color_field\",\n    \"type\": \"color\",\n    \"required\": true,\n    \"default\": {\n      \"color\": \"#FFFFFF\",\n      \"opacity\": 100\n    }\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v1 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Styles\",\n    \"name\": \"styles\",\n    \"type\": \"group\",\n    \"tab\": \"STYLE\",\n    \"children\": [\n      {\n        \"label\": \"Button Color\",\n        \"name\": \"new_button_color_field\",\n        \"type\": \"color\",\n        \"required\": true,\n        \"aliases_mapping\": {\n          \"property_aliases_paths\": {\n            \"new_button_color_field\": [\"old_button_color_field\"]\n          }\n        },\n        \"default\": {\n          \"color\": \"#FFFFFF\",\n          \"opacity\": 100\n        }\n      }\n    ]\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Complex implementation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans les situations plus complexes, vous pouvez également mapper des champs à des sous-champs ou à d'autres types de champs de module, à condition que le type de données soit le même et que le type de sous-champ du nouveau champ corresponde. Les sous-champs sont les propriétés de l'objet de valeur stocké dans le champ. Par exemple :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Mappage d'un champ de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "texte enrichi"
        }), " à un champ de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "texte"
        }), ", car les valeurs des deux champs sont stockées sous forme de chaînes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consolidez les champs de typographie, par exemple en passant d'un champ numérique pour la taille de police, à un champ de police (qui a un sous-champ de taille de police). Vous pouvez ajouter un alias pour le sous-champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "size"
        }), " pour le mapper à l'ancien champ numérique en utilisant la notation par points."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous un exemple de modification de l'option de dimensionnement de police d'un champ numérique à un champ de police qui comporte un sous-champ de taille de police."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v0 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_number_field\",\n    \"label\": \"Number field\",\n    \"required\": false,\n    \"locked\": false,\n    \"display\": \"text\",\n    \"step\": 1,\n    \"type\": \"number\",\n    \"min\": null,\n    \"max\": null,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"default\": null\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v1 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_font_field\",\n    \"label\": \"font_field\",\n    \"required\": false,\n    \"locked\": false,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"load_external_fonts\": true,\n    \"type\": \"font\",\n    \"aliases_mapping\": {\n      \"property_aliases_paths\": {\n        \"my_font_field.size\": [\"my_number_field\"]\n      }\n    },\n    \"default\": {\n      \"size\": 12,\n      \"font\": \"Merriweather\",\n      \"font_set\": \"GOOGLE\",\n      \"size_unit\": \"px\",\n      \"color\": \"#000\",\n      \"styles\": {}\n    }\n  }\n]\n"
          })
        })
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}