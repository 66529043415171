"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 48816364594;
const slug = exports.slug = 'guides/cms/content/fields/brand-and-settings-inheritance';
const title = exports.title = 'Héritage des paramètres de marque';
const name = exports.name = 'Héritage des paramètres de marque';
const metaDescription = exports.metaDescription = 'Découvrez comment utiliser les paramètres de marque, tels que les logos et les couleurs de marque, dans un thème ou dans des fichiers HubL/HTML et CSS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variables-des-param%C3%A8tres-de-marque",
  "label": "Variables des paramètres de marque",
  "parent": null
}, {
  "depth": 1,
  "id": "couleurs",
  "label": "Couleurs",
  "parent": "variables-des-param%C3%A8tres-de-marque"
}, {
  "depth": 1,
  "id": "logos",
  "label": "Logos",
  "parent": "variables-des-param%C3%A8tres-de-marque"
}, {
  "depth": 1,
  "id": "favicons",
  "label": "Favicons",
  "parent": "variables-des-param%C3%A8tres-de-marque"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Héritage des paramètres de marque"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Grâce aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/customize-branding-for-your-hubspot-content#customize-your-company-logo-and-colors",
        children: "paramètres de marque"
      }), ", les utilisateurs peuvent configurer les couleurs, les logos et les favicons de l'entreprise à utiliser dans les contenus HubSpot. Cela vous permet d'accéder à ces paramètres de marque avec des jetons dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " d'un thème et dans les fichiers HTML/HubL et CSS. Vous pouvez également accéder aux couleurs de la marque dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " d'un module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir ajouté ces jetons dans un fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ", les créateurs de contenu peuvent modifier leurs valeurs dans l'éditeur de paramètres de thème. Lorsque vous ajoutez ces jetons dans un HTML, un HubL ou un CSS, les valeurs seront fortement codées et ne pourront pas être modifiées dans l'éditeur de page par les créateurs de contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous les variables de configuration de marque disponibles ainsi que des exemples de mise en œuvre."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables des paramètres de marque"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La liste suivante est une liste des options accessibles depuis la zone des paramètres de la marque, dans la valeur de l'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), " ou dans les fichiers HTML/HubL et CSS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Couleurs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les couleurs de la marque sont accessibles à la fois dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json-color",
        children: "fichier fields.json"
      }), " d'un thème ou d'un module et dans les fichiers HTML/HubL et CSS à l'aide des jetons HubL suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Principal :"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.primaryColor}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[0]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-primary.png",
                alt: "brand-colors-primary"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Secondaire :"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{brand_settings.secondaryColor}}"
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-secondary.png",
            alt: "brand-colors-secondary"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Couleurs du thème :"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor1}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor2}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor3}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-accent.png",
                alt: "brand-colors-accent"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Couleurs supplémentaires :"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[1]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[2]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[3]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-additional.png",
                alt: "brand-colors-additional"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour accéder directement au code hexadécimal d'une couleur, incluez un filtre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hex"
      }), " dans le jeton. Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryColor.hex}}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour inclure les couleurs des paramètres de marque dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " d'un thème ou d'un module, utilisez le format suivant :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example of using the primary color in within a theme's\n// field.json file\n{\n  \"name\": \"branding_color\",\n  \"label\": \"branding_color\",\n  \"type\": \"color\",\n  \"default\": {\n    \"color\": \"#26ff55\",\n    \"opacity\": 60\n  },\n  \"inherited_value\": {\n    \"property_value_paths\": {\n      \"color\": \"brand_settings.primaryColor\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Il se peut que les comptes n'aient pas de couleurs supplémentaires configurées dans leurs paramètres de marque. Si votre code fait référence à une couleur héritée qui n'existe pas dans les paramètres de marque, la logique de repli suivante est utilisée :"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "secondaryColor"
          }), " revient à la première couleur supplémentaire (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[1]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor1"
          }), " revient à la deuxième couleur supplémentaire (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[2]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor2"
          }), " revient à la troisième couleur supplémentaire (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor3"
          }), " revient à la quatrième couleur supplémentaire (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[4]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les couleurs supplémentaires (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), ") reviendront à la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "default"
          }), ". S'il n'y a pas de jeu de couleur de propriété par défaut, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "primaryColor"
          }), " sera utilisé."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Logos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les logos de marques sont accessibles dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " d'un module et dans les fichiers HTML/HubL et CSS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez utiliser les jetons suivants pour accéder au logo principal défini dans les paramètres de la marque :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.logos[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-logo0.png",
        alt: "brand-settings-logo0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les logos supplémentaires sont accessibles en utilisant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.logos[1-19]}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En outre, vous pouvez accéder aux attributs de logo suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL du logo :"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.link}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Texte alternatif du logo :"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.alt}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hauteur du logo :"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.height}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Largeur du logo :"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.width}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lien vers l'image du logo :"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.src}}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Favicons"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les favicons de marques ne sont accessibles que dans les fichiers HTML/HubL et CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez utiliser les jetons suivants pour accéder au logo principal défini dans les paramètres de la marque :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryFavicon}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.favicons[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-favicon0.png",
        alt: "brand-settings-favicon0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les favicons supplémentaires sont accessibles en utilisant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.favicons[1-19]}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez accéder directement à l'URL du logo en incluant un filtre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), ". Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryFavicon.src}}"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}