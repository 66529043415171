"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279894;
const slug = exports.slug = 'reference/cms/hubl/tags/related-blog-posts';
const title = exports.title = "Création d'une liste d'articles de blog connexes avec la balise HubL pour les articles de blog connexes";
const name = exports.name = 'EMEA FR Creating a related blog post listing with the blog related posts HubL tag';
const metaDescription = exports.metaDescription = "La balise HubL related_blog_posts peut être utilisée pour créer une liste dynamique et un listing d'articles de blog connexes en fonction d'une variété de paramètres. Elle permet de générer des listings d'articles à travers les blogs, avec la possibilité de filtrer par balise, auteur, chemin de publication et date de publication.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "param%C3%A8tres",
  "label": "Paramètres",
  "parent": null
}, {
  "depth": 0,
  "id": "exemples-d-utilisation-de-la-balise-hubl-related_blog_posts",
  "label": "Exemples d'utilisation de la balise HubL related_blog_posts",
  "parent": null
}, {
  "depth": 1,
  "id": "afficher-les-articles-d-un-auteur-sp%C3%A9cifique-sur-plusieurs-blogs",
  "label": "Afficher les articles d'un auteur spécifique sur plusieurs blogs",
  "parent": "exemples-d-utilisation-de-la-balise-hubl-related_blog_posts"
}, {
  "depth": 1,
  "id": "afficher-les-articles-avec-la-balise-%22sales-enablement%22-dans-une-p%C3%A9riode-de-publication-sp%C3%A9cifique",
  "label": "Afficher les articles avec la balise \"sales enablement\" dans une période de publication spécifique",
  "parent": "exemples-d-utilisation-de-la-balise-hubl-related_blog_posts"
}, {
  "depth": 1,
  "id": "afficher-les-articles-%C3%A0-l-aide-d-un-rappel-js-pour-contr%C3%B4ler-la-sortie-html",
  "label": "Afficher les articles à l'aide d'un rappel JS pour contrôler la sortie HTML",
  "parent": "exemples-d-utilisation-de-la-balise-hubl-related_blog_posts"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Création d'une liste d'articles de blog connexes avec la balise HubL pour les articles de blog connexes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La balise HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#blog-related-posts",
        children: "related_blog_posts"
      }), " peut être utilisée pour créer une liste dynamique et un listing d'articles de blog connexes en fonction d'une variété de paramètres. Elle permet de générer des listings d'articles à travers les blogs, avec la possibilité de filtrer par balise, auteur, chemin de publication et date de publication. Les développeurs peuvent également spécifier la sortie HTML du module à l'aide d'une macro. Cette balise HubL peut être utilisée à la fois sur les articles de blog et les pages. Ce tutoriel vous guidera à travers les paramètres et les options d'utilisation de la balise HubL pour les articles de blog connexes."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Veuillez noter que la balise HubL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "related_blog_posts"
        }), " ne génère pas de module modifiable au niveau de l'article/la page. Elle est configurée dans son intégralité avec HubL."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La liste des articles est générée à partir d'un score de pertinence basé sur une comparaison des valeurs de paramètres définies avec les articles correspondant à ces paramètres ou concernant l'article sur lequel la balise HubL apparaît. Aucun paramètre n'est requis. Cependant, vous pouvez spécifier des paramètres pour mieux contrôler les articles qui sont renvoyés. Pour les paramètres séparés par des virgules, plus vous définissez de valeurs, plus la liste renvoyée sera diversifiée. Le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "post_formatter"
      }), " vous permet de spécifier une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "macro"
      }), " pour générer la sortie HTML du module. Pour obtenir une liste complète des paramètres et un exemple de sortie HTML par défaut, veuillez consulter la spécification ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#blog-related-posts",
        children: "related_blog_posts"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre maximal d'articles de blog à lister."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "'default' or blog id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le ou les ID des blogs dont vous souhaitez inclure les articles."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La ou les balises qui doivent être utilisées pour déterminer si un article est pertinent (séparées par des virgules). Si un article de blog comporte l'une de ces balises ou une balise similaire, la pertinence de l'article est accrue, ce qui améliore son classement dans la liste."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_authors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le ou les noms des auteurs dont les articles doivent être inclus (séparés par des virgules)."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_post_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le ou les ID des articles de blog à utiliser pour trouver les articles de blog pertinents à lister (séparés par des virgules). Ce paramètre ne doit être utilisé que lorsque le widget apparaît sur des pages, car sur les articles de blog, il sera attribué par défaut à l'article sur lequel le widget apparaît."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "post_formatter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le nom d'une macro personnalisée pour restituer les articles de blog renvoyés. La macro reçoit trois paramètres qui sont l'objet d'article de blog à formater, le compte dans l'itération des articles de blog et le compte total des articles de blog dans les résultats. S'ils ne sont pas spécifiés ou s'ils sont définis sur Par défaut, le formateur intégré sera utilisé pour formater chaque article.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Remarque : Il est recommandé d'utiliser le paramètre de rappel ci-dessous à la place du paramètre 'post_formatter' car le HTML de la balise s'affichera plus rapidement, diminuant ainsi le temps de chargement de la page."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callback"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom d'une fonction JavaScript pour restituer les articles de blog renvoyés. La fonction reçoit un tableau d'objets d'articles de blog à formater. Si ni le paramètre callback, ni le paramètre post_formatter ne sont spécifiés, la balise générera du HTML dans un format par défaut."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path_prefixes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les chemins d'URL ou sous-répertoires dont les articles doivent être inclus (séparés par des virgules). Si un article de blog a un préfixe similaire dans son chemin, la pertinence de l'article est accrue, ce qui améliore son classement dans la liste."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "date/time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet de filtrer les articles publiés après une date/heure."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date/Time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet de filtrer les articles publiés avant une date/heure."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_post_override"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le ou les ID des articles de blog qui doivent toujours apparaître dans la liste renvoyée, malgré toutes les autres valeurs des paramètres et des filtres (séparés par des virgules)."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Nous vous recommandons fortement d'utiliser le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "callback"
        }), " au lieu de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "post_formatter"
        }), " pour assurer des temps de chargement de page plus rapides."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Veuillez noter que si la balise HubL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "related_blog_posts"
        }), " est utilisée sur un article, le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_post_ids"
        }), " ne doit pas être spécifié, comme sur les articles de blog. Il sera par défaut sur l'article sur lequel le widget apparaît."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemples d'utilisation de la balise HubL related_blog_posts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Voici quelques exemples d'utilisation de cette balise pour améliorer votre site web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Afficher les articles d'un auteur spécifique sur plusieurs blogs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans cet exemple, nous générons un listing d'articles rédigés par l'un des trois ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_authors"
      }), " sur deux blogs différents."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts blog_ids=\"3241539189,3261083894\", limit=6, blog_authors=\"John Smith,Joe Smith,Frank Smith\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Afficher les articles avec la balise \"sales enablement\" dans une période de publication spécifique"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans cet exemple, nous générons une liste de 10 articles associés à un article de blog spécifique, avec la balise \"sales enablement\" dans une période de publication spécifique. Cet exemple spécifie le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post_ids"
      }), ". Il sera donc utilisé sur une page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts blog_post_ids=\"3267910554\", limit=10, tags=\"sales enablement\", start_date=\"2018-02-05\", end_date=\"2018-06-10\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Afficher les articles à l'aide d'un rappel JS pour contrôler la sortie HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans cet exemple, nous générons une liste de 5 articles à l'aide du paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callback"
      }), " pour contrôler la sortie HTML du listing d'articles. (Au lieu du paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "post_formatter"
      }), " à l'aide d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "macro"
      }), ".)"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts limit=5, callback=\"blog_post_formatter\" %}\n\n<script>\n  var blog_post_formatter = function(blogposts) {\n\n    var formatted = \"<div>\";\n    for (var i = 0; i < blogposts.length; i++) {\n      var blogpost = blogposts[i];\n      formatted += '<div class=\"related-blog-item\">';\n      formatted += `<span>Related Post ${i + 1}/${blogposts.length}</span><br>`;\n      formatted += `<a class=\"related-blog-title\" href=\"${blogpost.url}\"><span>${blogpost.name}</span></a>`;\n      formatted += `<div class=\"hs-related-blog-byline\">by <span class=\"related-blog-author\">${blogpost.blogAuthor.fullName}</span><span class=\"related-blog-posted-at\"> posted on </span><span class=\"related-blog-date\">${new Date(blogpost.publishDate).toLocaleDateString()}</span></div>`;\n      formatted += `<p class=\"related-blog-post-summary\">${blogpost.postSummary}<a href=\"${blogpost.url}\">Read more</a></p>`;\n      formatted += '<div class=\"related-blog-tags\">';\n      if (blogpost.tagList.length > 0) {\n        formatted += `Tags: ${blogpost.tagList.map(tag => tag.label).join(\", \")}`;\n      }\n      formatted += '</div>';\n\n      if (blogpost.featuredImage) {\n        formatted += `<img src=\"${blogpost.featuredImage}\" alt=\"${blogpost.featuredImageAltText}\">`;\n      }\n      formatted += '</div>';\n    }\n    formatted += '</div>';\n    return formatted;\n  }\n</script>\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}