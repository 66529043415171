"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29905990573;
const slug = exports.slug = 'guides/api/overview';
const title = exports.title = "How to use HubSpot's APIs";
const name = exports.name = "vNext Docs DP - How to use HubSpot's APIs";
const metaDescription = exports.metaDescription = 'The HubSpot API allows developers to build custom apps and integrations. Get started by reading our API overview and creating a developer account.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "get-started",
  "label": "Get started",
  "parent": null
}, {
  "depth": 0,
  "id": "authentication",
  "label": "Authentication",
  "parent": null
}, {
  "depth": 0,
  "id": "usage-and-limits",
  "label": "Usage and limits",
  "parent": null
}, {
  "depth": 0,
  "id": "app-partners-and-the-app-marketplace",
  "label": "App partners and the App Marketplace",
  "parent": null
}, {
  "depth": 0,
  "id": "learn-on-the-academy",
  "label": "Learn on the Academy",
  "parent": null
}, {
  "depth": 0,
  "id": "additional-resources",
  "label": "Additional resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API developer guides & resources"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot's APIs, which you can find the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "reference documentation here"
      }), ", allow you to build a functional app or integration quickly and easily. Here's an overview of what you'll need to use them."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get started"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To get started building apps and using HubSpot's APIs, check out the following guides that will help you decide what to build and how to build it on HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build",
          children: "What to build on HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/tools-to-help-you-build",
          children: "Tools to help you build"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "Account types"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're building an integration, you'll likely need to start with building a private or public app, depending on your needs. If you want to offer your app on HubSpot's App Marketplace, you'll need to create a public app. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview",
        children: "differences between app types"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authentication"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Most HubSpot API endpoints support both ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "private app access tokens"
      }), ", while some app configuration APIs require you to use a developer API key. You can find OAuth and private app access token details in an app's settings page in HubSpot:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "OAuth"
          }), ": OAuth details can be found on the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Auth"
          }), " tab of an app's settings in HubSpot:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "Log in to your developer account."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Apps"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-account-apps-nav.png",
                alt: "developer-account-apps-nav"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "name"
              }), " of an app."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Auth"
              }), " tab, then view your app's OAuth details."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-auth-tab.png",
                alt: "public-app-auth-tab"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Private app access token:"
          }), " private app access token details can be found in the app settings page in the account where the app was installed:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "Log in to the account where the app was installed."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the top bar, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "settings"
              }), " icon."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Integrations"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Private apps"
              }), "."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "name"
              }), " of the app."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Auth"
              }), " tab, then view your app's access token."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/private-app-auth-tab.png",
                alt: "private-app-auth-tab"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Developer API key:"
          }), " your developer API key can be found in your developer account:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "Log in to your developer account."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Apps"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-account-apps-nav.png",
                alt: "developer-account-apps-nav"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Get HubSpot API key"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-account-api-key.png",
                alt: "developer-account-api-key"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " as of November 30, 2022, HubSpot API Keys were deprecated and are no longer supported. Continued use of HubSpot API Keys is a security risk to your account and data."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You should instead authenticate using a private app access token or OAuth. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "this change"
        }), " and how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migrate an API key integration"
        }), " to use a private app instead."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage and limits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn about our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "usage guidelines"
      }), ", rate limits, and how to check your API call usage."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App partners and the App Marketplace"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to offer your app on HubSpot's App Marketplace, learn more about HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "app listing requirements"
      }), ". When your app is ready to submit for review, learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "create your app listing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Learn on the Academy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to the documentation you'll find on this site, you can learn more about development on HubSpot through HubSpot Academy videos, courses, and certifications. For example, you may want to start with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses/integrating-with-hubspot-foundations",
        children: "Integrating With HubSpot I: Foundations"
      }), " course or the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses/building-your-first-web-app",
        children: "Web App Development"
      }), " course."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Additional resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
          children: "Create a developer account"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "Set up a developer test account"
        }), " to install your app and test API calls"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stay up-to-date by subscribing to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "Changelog"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Join the conversation or ask questions in HubSpot’s ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
          children: "developer community forums"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Become a member of our developer ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "Slack community"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}