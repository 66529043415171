'use es6';

import { noAuthClient } from '../../lib/utils/httpClient';
function constructRequest(har) {
  if (!har) throw new Error('Missing har file');
  if (!har.log || !har.log.entries || !har.log.entries.length) throw new Error('Missing log.entries array');
  const {
    request
  } = har.log.entries[0];
  const {
    url
  } = request;
  const fn = noAuthClient[`${request.method.toLowerCase()}WithResponse`];
  const options = {};
  if (request.postData.text) {
    try {
      const json = JSON.parse(request.postData.text);
      options.data = json;
    } catch (e) {
      options.data = request.postData.text;
    }
  }
  if (request.headers.length) {
    options.headers = request.headers.map(header => {
      return {
        [header.name]: header.value
      };
    }).reduce((headers, next) => {
      return Object.assign(headers, next);
    }, {});
  }
  if (request.queryString.length) {
    const query = request.queryString.reduce((acc, pair) => {
      acc[pair.name] = acc[pair.name] ? acc[pair.name].concat(pair.value) : [pair.value];
      return acc;
    }, {});
    options.query = query;
  }
  return {
    fn,
    url,
    options
  };
}
export function fetchHar(har) {
  const {
    fn,
    url,
    options
  } = constructRequest(har);
  return fn(url, options);
}