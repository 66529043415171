"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280249471;
const slug = exports.slug = 'guides/api/crm/objects/line-items';
const title = exports.title = 'API de CRM | Elementos de línea';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Line Items';
const metaDescription = exports.metaDescription = 'Cuando un producto está adjunto a un negocio, se convierte en elemento de línea. Los puntos de terminación de los elementos de línea te permiten administrar y sincronizar estos datos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-un-elemento-de-l%C3%ADnea",
  "label": "Crear un elemento de línea",
  "parent": null
}, {
  "depth": 0,
  "id": "recuperar-un-elemento-de-l%C3%ADnea",
  "label": "Recuperar un elemento de línea",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-un-elemento-de-l%C3%ADnea",
  "label": "Actualizar un elemento de línea",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-un-elemento-de-pedido",
  "label": "Eliminar un elemento de pedido",
  "parent": null
}, {
  "depth": 0,
  "id": "propiedades-del-elemento-de-l%C3%ADnea",
  "label": "Propiedades del elemento de línea",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Elementos de línea"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En HubSpot, los elementos de línea son instancias individuales de los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "productos"
      }), ". Cuando un producto está adjunto a un negocio, se convierte en elemento de línea. Puedes crear elementos de línea únicos para una cotización individual, pero no se agregarán a tu biblioteca de productos. Los puntos de terminación de elementos de línea permiten administrar estos datos y sincronizarlos entre HubSpot y otros sistemas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejemplo de uso:"
      }), " al crear un conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes",
        children: "cotizaciones"
      }), " para que los representantes de ventas las envíen a compradores potenciales, puedes usar esta API para crear elementos de línea independientes por cotización, así como elementos de línea que se adjuntan a productos existentes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear un elemento de línea"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear un elemento de línea, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items"
      }), ". En el cuerpo de la publicación, incluye los detalles del elemento de línea, como el nombre, la cantidad y el precio."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear un elemento de línea basado en un producto existente (creado a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "API de productos"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
        children: "en HubSpot"
      }), "), incluye ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_product_id"
      }), " en el cuerpo de la publicación."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes asociar el elemento de pedido con negocios, cotizaciones, facturas, enlaces de pago o suscripciones, al incluir una matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "asociaciones"
      }), " en el cuerpo de la publicación. Por ejemplo, el siguiente cuerpo de publicación crearía un elemento de pedido llamado \"Nuevo elemento de pedido independiente\" que está asociado con un negocio (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "12345"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"price\": 10,\n    \"quantity\": 1,\n    \"name\": \"New standalone line item\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 12345\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Los elementos de pedido pertenecen a un único objeto matriz. Si se asocian objetos, los elementos de pedido deben ser individuales para cada objeto. Por ejemplo, si estás creando un negocio y una cotización, debes crear un conjunto de elementos de pedido para el negocio y otro conjunto para la cotización. Esto ayudará a agilizar los datos de CRM en todos los objetos y evitará la pérdida inesperada de datos cuando se necesite modificar los elementos de pedido (por ejemplo, eliminar una cotización eliminará los elementos de pedido de la cotización, y si esos elementos de pedido están asociados con un negocio, los elementos de pedido de os negocios también se eliminarán)."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["El ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "precio"
          }), " especificado dentro del campo de ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "propiedades"
          }), " ", (0, _jsxRuntime.jsx)("u", {
            children: "no puede"
          }), " ser negativo."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["La propiedad ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Correo electrónico"
          }), " de los elementos de pedido (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_recurring_billing_period"
          }), ") acepta los ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://docs.oracle.com/javase/8/docs/api/java/time/Period.html#:~:text=exceeds%20an%20int-,parse,-public%20static%C2%A0",
            children: "formatos de período ISO-8601"
          }), " de PnYnMnD y PnW."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar un elemento de línea"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes recuperar elementos de línea individualmente o en masa."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar un elemento de línea específico, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items/{lineItemId}"
        }), ", donde ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lineItemId"
        }), " es el ID del elemento de línea."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar todos los elementos de línea, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En la URL de la solicitud, puedes incluir los siguientes parámetros:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propiedades"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades que se devolverán en la respuesta. Si alguna de las propiedades especificadas no está presente en los objetos solicitados, se ignorará."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades que se devolverán junto con su historial de valores anteriores. Si alguna de las propiedades especificadas no está presente en los objetos solicitados, se ignorará."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar un elemento de línea"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para actualizar un elemento de línea, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), ", donde ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), " es el ID del elemento de línea."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el cuerpo de la publicación, incluye los valores de propiedad que deseas actualizar. No ", (0, _jsxRuntime.jsx)("u", {
        children: "puedes"
      }), " actualizar asociaciones a través de este método. En su lugar, deberás usar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de asociaciones"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, el cuerpo de tu solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/line_item/{lineItemId}\n{\n  \"properties\": {\n    \"price\": 25,\n    \"quantity\": 3,\n    \"name\": \"Updated line item\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar un elemento de pedido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar un elemento de línea, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), ", donde ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), " es el ID del elemento de línea."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propiedades del elemento de línea"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al administrar los datos de los elementos de pedido, es posible que desee utilizar algunas de las propiedades comunes de la siguiente tabla. Para obtener todas las propiedades de los elementos de pedido, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/line_item"
      }), ". Más información sobre el uso de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API de propiedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Etiqueta en la interfaz de usuario"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre del elemento de pedido."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "descripción"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Descripción completa del producto"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "SKU"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Identificador único del producto"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fecha de inicio de facturación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fecha de inicio de un período de facturación fijo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fecha de finalización de facturación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fecha de finalización de un período de facturación fijo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recurringbillingfrequency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Frecuencia de facturación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Con qué frecuencia se factura un elemento de pedido con facturación periódica. Informa el cálculo de precios para negocios y cotizaciones. Los elementos de pedido con facturación única no están incluidas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cantidad"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuántas unidades de un producto hay en este elemento de pedido"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Precio unitario"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El costo del producto"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Precio neto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El costo total del elemento de pedido (es decir, la cantidad multiplicada por el precio unitario)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Moneda"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Código de moneda para el elemento de pedido."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}