"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313553;
const slug = exports.slug = 'guides/cms/marketplace/template-guidelines';
const title = exports.title = 'Diretrizes do Marketplace de modelos da HubSpot';
const name = exports.name = 'LATAM BR (PT) Marketplace Requirements Overview';
const metaDescription = exports.metaDescription = 'O Marketplace de modelos da HubSpot ajuda milhares de clientes sem acesso direto a um desenvolvedor para aumentar sua presença online. Ele é alimentado por desenvolvedores, como você, que estão criando temas, modelos e módulos bonitos e fáceis de usar.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "por-que-devo-vender-modelos-no-marketplace%3F",
  "label": "Por que devo vender modelos no marketplace?",
  "parent": null
}, {
  "depth": 0,
  "id": "vis%C3%A3o-geral-e-requisitos",
  "label": "Visão geral e requisitos",
  "parent": null
}, {
  "depth": 0,
  "id": "listar-e-atualizar-temas-no-marketplace-de-modelos",
  "label": "Listar e atualizar temas no Marketplace de modelos",
  "parent": null
}, {
  "depth": 0,
  "id": "gateways-de-pagamento-compat%C3%ADveis",
  "label": "Gateways de pagamento compatíveis",
  "parent": null
}, {
  "depth": 0,
  "id": "solicita%C3%A7%C3%B5es-de-reembolso",
  "label": "Solicitações de reembolso",
  "parent": null
}, {
  "depth": 0,
  "id": "gerenciar-suas-transa%C3%A7%C3%B5es",
  "label": "Gerenciar suas transações",
  "parent": null
}, {
  "depth": 0,
  "id": "remo%C3%A7%C3%A3o-de-listagens-de-modelos",
  "label": "Remoção de listagens de modelos",
  "parent": null
}, {
  "depth": 0,
  "id": "perguntas-frequentes",
  "label": "Perguntas frequentes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em",
      br: "br",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ContentCardsRow,
      ContentCard,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ContentCard) _missingMdxReference("ContentCard", true);
  if (!ContentCardsRow) _missingMdxReference("ContentCardsRow", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Diretrizes do Marketplace de modelos da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "Marketplace de modelos da HubSpot"
      }), " ajuda milhares de clientes sem acesso direto a um desenvolvedor para aumentar sua presença online. Ele é possibilitado por desenvolvedores que criam modelos fáceis de usar para os clientes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode vender dois tipos de modelos no Marketplace de modelos da HubSpot: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/website",
        children: "temas"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/modules",
        children: "módulos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Por que devo vender modelos no marketplace?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O Marketplace da HubSpot tem muito a oferecer aos desenvolvedores ou agências que buscam criar um novo fluxo de receita."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Não exclusivo:"
        }), " você se esforçou para criar seus modelos. Não deve ficar preso a ter seu modelo no nosso marketplace apenas e em nenhum outro lugar. Você também pode usar seus modelos fora do marketplace para seus próprios projetos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crie uma vez, venda várias vezes:"
        }), " quando seu modelo é listado no marketplace, ele se torna um produto que pode render várias vendas. Você simplesmente cria seu modelo uma vez e ele pode ser revendido para vários clientes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Expanda seus negócios:"
        }), " muitos dos desenvolvedores que vendem seus modelos (gratuitos e pagos) em nosso marketplace viram seus negócios aumentarem tanto a receita do marketplace quanto dos serviços adicionais solicitados pelos compradores."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Junte-se a uma comunidade de criadores e mostre suas habilidades:"
        }), " conecte-se a um ecossistema crescente e solidário para ajudar a moldar o futuro do Marketplace de modelos da HubSpot e, ao mesmo tempo, dar mais visibilidade às suas ofertas e serviços."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visão geral e requisitos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para começar a vender no Marketplace de modelos, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "crie uma conta de provedor do Marketplace de modelos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Todos os modelos enviados devem aderir aos requisitos de conformidade, design e qualidade de código. Cada tipo de modelo também tem seus próprios requisitos que devem ser atendidos para ser listado no marketplace. Você pode ver estes requisitos abaixo:"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " antes de começar a criar modelos para o marketplace, a HubSpot recomenda fortemente que você revise as diretrizes de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/template-providers",
          children: "Criação para o Marketplace de modelos da HubSpot"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/themes/branding-guidelines",
          children: "Branding"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "Uso de marcas registradas"
        }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/content-usage-guidelines",
          children: "Uso de conteúdo"
        }), ". Os modelos que violarem essas diretrizes serão removidos imediatamente do marketplace , conforme descrito nos termos adicionais da HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(ContentCardsRow, {
      children: [(0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Requisitos do tema"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Um conjunto portátil e contido de modelos, módulos, conteúdo global e configurações de estilo projetados para trabalhar em conjunto para permitir uma experiência de edição de conteúdo amigável para o profissional de marketing."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "en-us/guides/cms/marketplace/theme-requirements",
            children: "Exibir requisitos do tema"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Requisitos de módulos"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Temas enviados contêm módulos, que são componentes reutilizáveis que podem ser usados em modelos ou adicionados a páginas por meio de áreas de arrastar e soltar e colunas flexíveis. Você também pode enviar módulos independentes. Esses são módulos que podem ser adicionados a qualquer tema no editor de conteúdo do HubSpot."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "en-us/guides/cms/marketplace/module-requirements",
            children: "Exibir requisitos do módulo"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Requisitos da página de listagem"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Requisitos que devem ser cumpridos ao enviar modelos e páginas de listagem para o Marketplace de modelos da HubSpot. Esses padrões se aplicam a todos os ativos no Marketplace."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/general-requirements",
            children: "Ver requisitos da página de listagem"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Políticas do Marketplace de modelos"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Requisitos e detalhes de conformidade para modelos de listagem no Marketplace de modelos."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/template-policies",
            children: "Ver políticas do Marketplace de modelos"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listar e atualizar temas no Marketplace de modelos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de criar seu modelo, envie-o para aprovação para ser listado no Marketplace de modelos da HubSpot. Depois de enviados, os modelos passarão por um processo de revisão e aprovação pela equipe do Marketplace de modelos da HubSpot. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "Saiba como listar e atualizar seus modelos no Marketplace de modelos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gateways de pagamento compatíveis"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Atualmente, a HubSpot oferece a capacidade de os provedores do marketplace conectarem os seguintes gateways de pagamento para receber pagamentos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["PayPal (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/webapps/mpp/country-worldwide",
          children: "países compatíveis"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/smarthelp/article/what-are-the-cross-border-fees-when-selling-international-faq2927",
          children: "pagamentos transfronteiriços"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stripe (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "países compatíveis"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/docs/connect/cross-border-payouts",
          children: "pagamentos transfronteiriços"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os pagamentos dos clientes são recebidos por meio do recurso de pagamento conectado em sua conta de provedor do Marketplace:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Informações do provedor"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Método de pagamento"
        }), ", visualize ou atualize suas informações de pagamento."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A HubSpot não é responsável pelos problemas do gateway de pagamento que podem ocorrer durante o processo de compra. A HubSpot não aceita nenhuma transação e todas as transações são tratadas independentemente da plataforma HubSpot. Se seu método de pagamento deixar de funcionar por qualquer motivo, os ativos poderão ser removidos temporariamente da lista para evitar uma experiência negativa para o cliente."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " a HubSpot tem clientes em todo o mundo. Ao configurar gateways de pagamento, é altamente recomendável aceitar pagamentos de moedas internacionais (pagamentos transfronteiriços)."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A HubSpot incentiva fortemente os provedores do Marketplace de modelos a usar o gateway de pagamento do Stripe, se estiver disponível em seu país. Se o Stripe não estiver ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "disponível no seu país"
        }), ", recomendamos o uso do gateway de pagamento do PayPal."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Solicitações de reembolso"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode realizar a solicitação ocasional de um comprador para um reembolso. A HubSpot recomenda que você indique claramente sua política de reembolso na descrição de uma listagem e forneça um link para a documentação sobre sua política de reembolso. O HubSpot incentiva os fornecedores a honrarem solicitações de reembolso válidas. Saiba mais sobre as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/resources/marketplace-refund-guidelines",
        children: "diretrizes gerais para solicitações de reembolso"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gerenciar suas transações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um registro de negócio é criado na sua conta de provedor do Marketplace de modelos para cada modelo que um cliente baixa. Todos os negócios serão preenchidos em um pipeline de negócios chamado ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Transações do marketplace"
      }), " com a fase ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Transações"
      }), ". Quando um reembolso é emitido para uma transação, a propriedade ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Reembolso em"
      }), " será definido no registro do negócio."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os provedores podem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "personalizar o pipeline de negócios e as fases"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/choose-your-workflow-actions#property-management",
        children: "usar as propriedades de negócio em fluxos de trabalho"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Remoção de listagens de modelos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot reserva-se o direito de remover listagens de marketplace por razões que incluem, sem limitação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erros presentes no modelo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modelos de terceiros na quebra de envio."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O modelo é incompatível com os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/resources/which-browsers-are-supported-by-hubspot",
          children: "navegadores compatíveis com o HubSpot."
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Uso de material protegido por direitos autorais, como imagens, ícones, fontes, áudio e vídeo não licenciados para distribuição"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos que rastreiam usuários sem o consentimento informado e explícito."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Modelos (incluindo links para conteúdo do Marketplace de modelos ou para a demonstração do seu modelo) que incluem:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Promover ou participar de atividades ilegais"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Conteúdo depreciativo e/ou malicioso."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Alegações desonestas ou falsas."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Conteúdo ofensivo sobre raça, religião, idade, sexo ou orientação sexual."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os módulos serão removidos se afetarem outros elementos na página/e-mail em que for adicionado."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Perguntas frequentes"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Does the European Union P2B regulation (July 12,2020) apply to the App and Template Marketplaces?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No, the most recent European Union P2B regulation that took effect on July 12, 2020, does not apply to the HubSpot App and Template Marketplaces. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ec.europa.eu/digital-single-market/en/business-business-trading-practices",
          children: "Learn More"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Is there a specific HubSpot account type I need to submit to the Marketplace?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-marketplace-account",
          children: "Marketplace provider account"
        }), " is needed to submit to the Template Marketplace."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Some partners manage their Marketplace listings in their Partner account to use the templates in the same account. Other partners manage their listings in a separate Marketplace account to keep the templates separate from other templates in their design manager tool."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Do products published in the Marketplace live in my account or in the HubSpot Marketplace system?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Products submitted to or published in the Marketplace live in the HubSpot Marketplace system. This ensures that providers can continue to edit them in their own account without the changes affecting customers’ purchases. To update a Marketplace listing, a provider must resubmit the listing so it can be reviewed and republished."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Customers who have purchased previous versions of a product in a listing can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/resources/marketplace-customers-frequently-asked-questions#update",
          children: "update to the latest version"
        }), " free of charge."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I update my company name?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Once you have listed a template in the marketplace, you will need to contact ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://help.hubspot.com/",
          children: "HubSpot Support"
        }), "  to change your company name."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Why don't I see all of my products when trying to create a listing?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Possible reasons you may not see a product when trying to create a listing include:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is currently listed in the Marketplace or contains elements from a currently listed template."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template isn't supported in the Marketplace (transactional emails, ticket emails, etc.)"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is a clone of a template from the Marketplace."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "What should I use as a preview link?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["When creating preview URLs you must use your own domain name if available. If you don't have a domain available to use, HubSpot provides each account with a system domain in the following URL structure:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": HubSpot does not support template previews that use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " subdomain."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Can a single template be used in multiple themes?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes, a single template can be used in multiple themes. A common example of when a template may be used in multiple themes is if a provider offers a Starter and Premium version of the same theme."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Do I have to resubmit a theme or module if I make changes to it?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes. All changes to a theme or module must be re-submitted to the Marketplace for pre-validation and approval by the quality team. Once the changes are approved, the listing and template will be updated in the Marketplace for customers."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I adjust the price of a theme or module?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "To adjust the price of a submitted product, you must resubmit it to the Marketplace with updated information. Prices cannot be changed from free to paid, or vice versa once a template is published."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How do I receive Marketplace payments?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Payments are received through the payment method that you've connected in your Marketplace Provider Profile. In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Provider Info"
        }), ". In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Payment Method"
        }), " section, view or update your payment information."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot doesn’t take a cut of any transactions, and all transactions are handled independently of the HubSpot platform."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Where do I go to get peer-to-peer feedback or connect with other Marketplace providers? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Join the #marketplace channel in HubSpot's Developer Slack by signing up at ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "https://developers.hubspot.com/slack"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "When a customer requests a refund, do their downloaded files remain in their HubSpot account?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "All files associated with the template as well as any dependencies (e.g. pages created with the template, cloned templates, and modules) will be removed once the refund is initiated."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How can I stay informed on the latest developer requirements and changes? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To stay informed of the latest changes, visit and subscribe to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "HubSpot Developer Changelog"
        }), "."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}