"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51261306631;
const slug = exports.slug = 'guides/api/crm/associations';
const title = exports.title = 'API du CRM |  Associations v4';
const name = exports.name = 'vNext Docs DP - Associations v4';
const metaDescription = exports.metaDescription = 'Les points de terminaison pour les associations de CRM sont utilisés pour gérer les associations entre des tickets, des produits, des lignes de produit et leurs contacts, entreprises et transactions associés.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "hubspot-a-d%C3%A9fini-des-types-d%E2%80%99association",
  "label": "HubSpot a défini des types d’association",
  "parent": null
}, {
  "depth": 0,
  "id": "libell%C3%A9s-simples-ou-jumel%C3%A9s",
  "label": "Libellés simples ou jumelés",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-des-types-d%E2%80%99association",
  "label": "Créer des types d’association",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-les-types-d%E2%80%99association",
  "label": "Récupérer les types d’association",
  "parent": null
}, {
  "depth": 0,
  "id": "associer-des-fiches-d-informations",
  "label": "Associer des fiches d'informations",
  "parent": null
}, {
  "depth": 1,
  "id": "associer-des-fiches-d-informations-sans-libell%C3%A9",
  "label": "Associer des fiches d'informations sans libellé",
  "parent": "associer-des-fiches-d-informations"
}, {
  "depth": 1,
  "id": "associer-des-fiches-d-informations-avec-libell%C3%A9",
  "label": "Associer des fiches d'informations avec libellé",
  "parent": "associer-des-fiches-d-informations"
}, {
  "depth": 0,
  "id": "d%C3%A9finir-et-g%C3%A9rer-des-limites-d%E2%80%99association",
  "label": "Définir et gérer des limites d’association",
  "parent": null
}, {
  "depth": 1,
  "id": "cr%C3%A9er-ou-mettre-%C3%A0-jour-des-limites-d%E2%80%99association",
  "label": "Créer ou mettre à jour des limites d’association",
  "parent": "d%C3%A9finir-et-g%C3%A9rer-des-limites-d%E2%80%99association"
}, {
  "depth": 1,
  "id": "r%C3%A9cup%C3%A9rer-les-limites-d%E2%80%99association",
  "label": "Récupérer les limites d’association",
  "parent": "d%C3%A9finir-et-g%C3%A9rer-des-limites-d%E2%80%99association"
}, {
  "depth": 1,
  "id": "supprimer-les-limites-d%E2%80%99association",
  "label": "Supprimer les limites d’association",
  "parent": "d%C3%A9finir-et-g%C3%A9rer-des-limites-d%E2%80%99association"
}, {
  "depth": 0,
  "id": "rapport-sur-l%E2%80%99utilisation-fr%C3%A9quente-des-associations",
  "label": "Rapport sur l’utilisation fréquente des associations",
  "parent": null
}, {
  "depth": 0,
  "id": "valeurs-d%E2%80%99id-de-type-d%E2%80%99association",
  "label": "Valeurs d’ID de type d’association",
  "parent": null
}, {
  "depth": 0,
  "id": "associations%C2%A0v1-h%C3%A9rit%C3%A9",
  "label": "Associations v1 (hérité)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Associations v4"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour la version précédente, veuillez consulter la documentation de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/associations/association-details/v3",
          children: "l’API d’associations v3"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les associations représentent les relations entre les objets et les activités dans le CRM de HubSpot. Des associations de fiches d’informations peuvent exister entre des fiches d’informations de différents objets (par exemple : de Contact à Entreprise) ou au sein d’un même objet (par exemple : Entreprise à Entreprise). Vous pouvez utiliser les points de terminaison d'associations pour créer, récupérer, mettre à jour, ou supprimer des associations entre des fiches d'informations, ou des fiches d'informations et des activités."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les points de terminaison de schéma d’association vous permettent d’afficher les types d’association pris en charge dans votre compte, ainsi que de créer vos propres types d’association et d’attribuer des libellés à vos relations de fiche d’informations. Les libellés d’association sont pris en charge entre les contacts, les entreprises, les transactions, les tickets et les objets personnalisés, et peuvent être ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels#use-association-labels-in-hubspot-tools",
        children: "utilisés dans tous les outils HubSpot"
      }), " tels que les listes et les workflows."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les API d'objets, de fiches d'informations, de propriétés et d'associations dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "guide Comprendre le CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : L’API d’associations v4 est prise en charge dans la version 9.0.0 ou ultérieure du client NodeJS HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot a défini des types d’association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot fournit un ensemble de types d’association prédéfinis (par exemple : contact sans libellé de l’entreprise), mais les administrateurs de compte peuvent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "définir leurs propres libellés d’association"
      }), " pour fournir un contexte supplémentaire pour les relations entre les fiches d’informations (par exemple : responsable et employé). Il existe deux types d’association définis par HubSpot :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Principale"
        }), " : l’entreprise principale à laquelle l’autre fiche d’informations est associée. Les associations principales peuvent être utilisées dans les outils HubSpot tels que ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/associations-enhancements-beta#use-associations-in-hubspot-tools",
          children: "les listes et les workflows"
        }), ". Pour les fiches d’informations contenant plusieurs entreprises associées, cette API prend en charge la modification de l’entreprise considérée comme principale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sans libellé"
        }), " : un type d’association ajouté lorsqu’une fiche d’informations de contact, d’entreprise, de transaction, de ticket ou d’objet personnalisé est associée. Ce type indique qu’une association existe et sera toujours renvoyée dans les réponses avec une valeur de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "libellé"
        }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), ". Lorsqu’une fiche d’informations dispose d’une association principale ou d’un libellé d’association personnalisé, ces types seront répertoriés avec le type d’association sans libellé."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez visualiser tous les types d’association définis par HubSpot dans ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#association-type-id-values",
        children: "cette section"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Libellés simples ou jumelés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il existe deux types de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "libellés d’association"
      }), " que vous pouvez utiliser pour décrire les relations entre des fiches d’informations :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Unique"
        }), " : un libellé qui s’applique aux deux fiches d’informations de la relation. Par exemple : ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Ami"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Collègue"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Jumelé"
        }), " : une paire de libellés pour les cas où des mots différents sont utilisés pour décrire chaque aspect de la relation des fiches d’informations associées. Par exemple : ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Parent"
        }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enfant"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Employeur"
        }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Employé"
        }), ". Pour créer des libellés appariés, vous devez inclure le champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inverseLabel"
        }), " dans votre demande pour nommer le deuxième libellé de la paire."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer des types d’association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez créer des types d’association personnalisés ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "dans HubSpot"
      }), " ou via le point de terminaison d’API du schéma d’association. Vous pouvez créer jusqu’à 10 types d’association entre chaque paire d’objets (par exemple : contacts et entreprises, contacts et contacts)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un type d’association via l’API, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
      }), " et incluez les éléments suivants dans votre demande :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " : le nom interne du type d’association. Cette valeur ", (0, _jsxRuntime.jsx)("u", {
          children: "ne peut pas"
        }), " comprendre de traits d’union ni commencer par un caractère numérique."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "libellé"
        }), " : le nom du ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
          children: "libellé d’association tel qu’il apparaît dans HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "inverseLabel"
        }), " (libellés jumelés uniquement) : le nom du deuxième libellé de la paire de libellés."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, votre demande pourrait ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "///Example request body - Single label\n{\n  \"label\": \"Partner\",\n  \"name\": \"partner\"\n}\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "///Example request body - Paired labels\n{\n  \"label\": \"Manager\",\n  \"inverseLabel\": \"Employee\",\n  \"name\": \"manager_employee\"\n}\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer les types d’association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour afficher les types d’association entre des objets spécifiques, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous recevrez un tableau contenant chaque élément :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "catégorie"
        }), " : si le type d’association a été créé par HubSpot (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "HUBSPOT_DEFINED"
        }), ") ou par un utilisateur (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "USER_DEFINED"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**typeId: ** l’ID numérique pour ce type d’association. Ceci est utilisé pour ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#associate-with-label",
          children: "définir un libellé lors de l’association de fiches d’informations"
        }), ". Consultez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#association-type-id-values",
          children: "cette liste"
        }), " pour toutes les valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "typeId"
        }), " définies par HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "libellé"
        }), " : le libellé alphanumérique. Il s’agit de la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " du type d’association sans libellé."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également trouver ces valeurs dans HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/create-and-use-association-labels#association-label-api-details",
        children: "dans vos paramètres d’association"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associer des fiches d'informations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer des fiches d'informations sans libellé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez créer une association sans libellé par défaut entre deux fiches d’informations ou configurer des associations sans libellé pour des fiches d’informations en masse. Pour configurer une association individuelle par défaut entre deux fiches d’informations, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/{fromObjectType}/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'URL de la demande, comprend :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), "** :** l'ID de l'objet que vous associez. Pour trouver les valeurs d’ID, consultez cette ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "liste d’ID de types d’objet,"
        }), " ou pour les contacts, les entreprises, les transactions, les tickets et les notes, vous pouvez utiliser le nom d’objet (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectId"
        }), "** :** l’ID de la fiche d’informations à associer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), "** :** l'ID de l'objet auquel vous associez la fiche d'informations. Pour trouver les valeurs d’ID, consultez cette ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "liste d’ID de types d’objet,"
        }), " ou pour les contacts, les entreprises, les transactions, les tickets et les notes, vous pouvez utiliser le nom d’objet (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectId"
        }), "** :** l’ID de la fiche d’informations à associer."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple : pour associer une fiche d’informations de contact dont l’ID est 12345 à une fiche d’informations d’entreprise dont l’ID est ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "67891"
      }), ", l’URL de votre demande sera : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/contact/12345/associations/default/company/67891"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour configurer des associations par défaut en masse, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v4/associations/{fromObjectType}/{toObjectType}/batch/associate/default"
      }), ". Dans le corps de la demande, incluez les valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " des fiches d’informations que vous souhaitez associer."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : le nombre d’associations qu’une fiche d’informations peut avoir dépend de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/hubspot-product-and-services-catalog#TechnicalLimits:~:text=CRM%20Record%20Association%20Limits",
          children: "l’objet et de votre abonnement HubSpot."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer des fiches d'informations avec libellé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour associer deux fiches d’informations et définir un libellé pour décrire l’association, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/{objectType}/{objectId}/associations/{toObjectType}/{toObjectId}"
      }), ". Dans le corps de la demande, incluez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " pour indiquer le type d’association que vous souhaitez créer."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous créez des associations sans libellé, vous pouvez utiliser les points de terminaison par défaut décrits dans la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#associate-records-without-a-label",
        children: "section ci-dessus"
      }), ", qui ne nécessitent pas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), ". Si vous créez des associations avec un libellé, vous pouvez consultez cette ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#association-type-id-values",
        children: "liste d’ID de type par défaut"
      }), " ou vous devrez récupérer les types d’association personnalisés entre ces objets."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : pour les relations entre les objets croisés et les libellés jumelés, assurez-vous d’utiliser la méthode ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "typeId"
        }), " qui renvoie à la bonne direction (par exemple : Contact à Entreprise vs Entreprise à Contact, Employé à Responsable ou Responsable à Employé)."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple : pour associer un contact à une transaction à l’aide d’un libellé personnalisé :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1. Effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/contact/deal/labels"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["2. Dans la réponse, examinez les valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), " du libellé. L’ID sera un nombre (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "36"
      }), ") et la catégorie sera toujours ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "USER_DEFINED"
      }), " pour les libellés personnalisés."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["3. Effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/contact/{objectId}/associations/deal/{toObjectId}"
      }), " avec le corps de requête suivant :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "/// Example request body\n[\n  {\n    \"associationCategory\": \"USER_DEFINED\",\n    \"associationTypeId\": 36\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Définir et gérer des limites d’association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez définir des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/set-limits-for-record-associations",
        children: "limites"
      }), " pour le nombre de fiches d’informations associées entre les objets ou la fréquence à laquelle un libellé peut être utilisé pour décrire les associations. Il existe également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "des limites techniques et des limites en fonction de votre abonnement HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer ou mettre à jour des limites d’association"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez créer de nouvelles limites d’association ou mettre à jour celles qui existent déjà entre des objets."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour créer des limites, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/create"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour mettre à jour les limites existantes, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/update"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le corps de la demande, ajoutez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), " aux éléments suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "category"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La catégorie de l’association pour laquelle vous définissez une limite, soit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_DEFINED"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "USER_DEFINED"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "typeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L’ID numérique du type d’association pour lequel vous souhaitez définir une limite. Consultez ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#association-type-id-values",
              children: "cette liste"
            }), " de valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "typeId"
            }), " par défaut ou ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#retrieve-association-types",
              children: "récupérez la valeur"
            }), " pour les libellés personnalisés."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxToObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre maximal d’associations autorisé pour le type d’association."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, pour définir des limites auxquelles une transaction peut être associée à un maximum de cinq contacts, avec un seul contact intitulé ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Point de contact"
      }), " pour une transaction, votre demande ressemblerait à ce qui suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request POST crm/v4/associations/definitions/configurations/deal/contact/batch/create\n{\n  \"inputs\": [\n    {\n      \"category\": \"HUBSPOT_DEFINED\",\n      \"typeId\": 3,\n      \"maxToObjectIds\": 5\n    },\n    {\n      \"category\": \"USER_DEFINED\",\n      \"typeId\": 35,\n      \"maxToObjectIds\": 1\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Récupérer les limites d’association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour lire toutes les limites d’association définies, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/definitions/configurations/all"
        }), ". Cela renverra des limites d’association personnalisées définies pour tous les objets."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour lire les limites d’association entre deux objets spécifiques, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les deux demandes, la réponse renverra les valeurs des associations pour ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "maxToObjectIds"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), ". Par exemple, si vous récupérez les limites entre les transactions et les contacts, la réponse ressemblera à ceci :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example response GET crm/v4/associations/definitions/configurations/deal/contact\n{\n  \"results\": [\n    {\n      \"category\": \"HUBSPOT_DEFINED\",\n      \"typeId\": 3,\n      \"userEnforcedMaxToObjectIds\": 5,\n      \"label\": null\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer les limites d’association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer des limites d’association spécifiques, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/purge"
      }), ". Dans le corps de la demande, incluez les valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " des types d’association pour lesquels vous souhaitez supprimer les limites."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, pour supprimer la limite ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Point de contact"
      }), " entre les transactions et les contacts, la demande ressemblerait à ceci :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request POST crm/v4/associations/definitions/configurations/deal/contact/batch/purge\n{\n  \"inputs\": [\n    {\n      \"category\": \"USER_DEFINED\",\n      \"typeId\": 35\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En cas de réussite, vous recevrez une réponse 204 et la limite incluse reviendra au système par défaut (c’est-à-dire que de nombreux contacts peuvent avoir le libellé ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Point de contact"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rapport sur l’utilisation fréquente des associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il existe des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "limites techniques au nombre d’associations qu’une fiche d’informations peut avoir"
      }), ". Vous pouvez utiliser l’API d’associations pour récupérer un rapport de fiches d’informations qui approchent ou ont atteint la limite maximale pour les associations."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer le rapport, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v4/associations/usage/high-usage-report/{userID}"
      }), ". Le fichier contient des fiches d’informations utilisant 80 % ou plus de leur limite d’association. Par exemple, si une entreprise peut être associée à un maximum de 50 000 contacts, elle sera incluse dans le fichier si elle compte 40 000 contacts associés ou plus. Le fichier sera envoyé à l’e-mail de l’utilisateur dont l’ID a été inclus dans l’URL de la demande. Découvrez comment récupérer des ID utilisateur avec ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/users/user-provisioning",
        children: "l’API d’utilisateurs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Valeurs d’ID de type d’association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les tableaux suivants incluent les valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " définies par HubSpot qui précisent le type d’association. Les types d’associations varient en fonction des objets inclus et de la direction de l’association (par exemple : Contact à Entreprise est différent de Entreprise à Contact). Si vous créez des objets personnalisés ou des libellés d’association personnalisés, les types d’association associés auront des valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " uniques que vous devrez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieve-association-type",
        children: "récupérer"
      }), " ou localiser dans vos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/create-and-use-association-labels#association-label-api-details",
        children: "paramètres d’association dans HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : les types d’association d’entreprise par défaut incluent un type d’association sans libellé et un type d’association principal. Si une fiche d’informations a plusieurs entreprises associées, une seule peut être l’entreprise principale. Les autres associations peuvent être sans libellé ou avoir des libellés d’association personnalisés."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Contact à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "449"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "279"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à entreprise (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "4"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "15"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "193"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à appel"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "197"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à e-mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "199"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à réunion"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "201"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à note"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "203"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à tâche"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "82"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à communication (message SMS, WhatsApp ou LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "454"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à courrier postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "587"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à panier"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "508"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à commande"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "178"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à facture"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "388"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à paiement"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "296"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contact à abonnement"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Entreprise à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "450"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "14"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Enfant à entreprise parent"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "13"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Parent à entreprise enfant"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "280"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à contact (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "342"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à transaction (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "340"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "25"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à ticket (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "181"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à appel"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "185"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à e-mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "187"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à réunion"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "189"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à note"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "191"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à tâche"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "88"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à communication (message SMS, WhatsApp ou LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "460"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à courrier postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "180"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à facture"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "510"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à commande"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "390"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à paiement"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "298"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Entreprise à abonnement"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Transaction à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "451"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "341"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "5"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à entreprise (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "27"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "205"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à appel"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "209"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à e-mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "211"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à réunion"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "213"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à note"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "215"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à tâche"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "86"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à communication (message SMS, WhatsApp ou LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "458"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à courrier postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "313"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à répartition de transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "19"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à ligne de produit"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "176"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à facture"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "511"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à commande"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "392"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à paiement"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "630"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à produit"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "63"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à devis"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "300"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Transaction à abonnement"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Ticket à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "452"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "16"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "339"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "26"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à entreprise (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "28"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "219"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à appel"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "223"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à e-mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "223"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à réunion"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "227"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à note"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "229"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à tâche"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "84"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à communication (message SMS, WhatsApp ou LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "456"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à courrier postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "32"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à fil de conversation"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "278"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à conversation"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "526"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket à commande"
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "Lead à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "578"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead à contact principal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "596"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead à appel"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "598"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead à e-mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "600"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead à réunion"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "602"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead à communication"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "608"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "610"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "646"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead à tâche"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Appel à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "194"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Appel à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "182"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Appel à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "206"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Appel à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "220"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Appel à ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "E-mail à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "198"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-mail à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "186"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-mail à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "210"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-mail à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "224"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-mail à ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Réunion à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "200"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Réunion à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "188"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Réunion à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "212"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Réunion à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "226"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Réunion à ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Note à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "202"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Note à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "190"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Note à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "214"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Note à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "228"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Note à ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Courrier postal à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "453"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Courrier postal à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "459"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Courrier postal à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "457"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Courrier postal à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "455"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Courrier postal à ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Devis à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "69"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "71"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "64"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "67"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à ligne de produit"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "286"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à modèle de devis"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "362"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à remise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "364"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à frais"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "366"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à taxe"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "702"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Signataire du contact (pour les signatures électroniques)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "733"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à panier"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "408"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à facture"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "731"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à commande"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "398"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à paiement"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "304"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Devis à abonnement"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Tâche à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "204"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tâche à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "192"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tâche à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "216"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tâche à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "230"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tâche à ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Communication (message SMS, WhatsApp ou LinkedIn) à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "81"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Communication (message SMS, WhatsApp ou LinkedIn) à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "87"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Communication (message SMS, WhatsApp ou LinkedIn) à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "85"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Communication (message SMS, WhatsApp ou LinkedIn) à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "83"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Communication (message SMS, WhatsApp ou LinkedIn) à ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Commande à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "593"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à panier"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "507"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "509"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à entreprise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "512"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à transaction"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "362"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à remise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "521"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à code de remise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "408"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à facture"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "513"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à ligne de produit"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "523"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à paiement"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "730"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à devis"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "516"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à abonnement"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "726"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à tâche"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "525"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Commande à ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Panier à objet"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DU TYPE"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Type d'association"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "586"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Panier à contact"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "588"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Panier à remise"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "590"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Panier à ligne de produit"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "592"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Panier à commande"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "732"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Panier à devis"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "728"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Panier à tâche"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "594"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Panier à ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associations v1 (hérité)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous utilisez l’API d’associations v1, consultez le tableau ci-dessous pour plus d’informations sur les ID à utiliser lors de l’association de fiches d’informations."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type d'association"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "ID"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contact à entreprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Entreprise à contact (par défaut)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Entreprise à contact (tous les libellés)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "280"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Transaction à contact"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contact à transaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Transaction à entreprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "5"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Entreprise à transaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "6"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Entreprise à interaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "7"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interaction à entreprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "8"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contact à interaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "9"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interaction à contact"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "10"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Transaction à interaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "11"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interaction à transaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "12"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Entreprise parent à entreprise enfant"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "13"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Entreprise enfant à entreprise parent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "14"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contact à ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "15"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket à contact"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "16"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket à interaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "17"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interaction à ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "18"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Transaction à ligne de produit"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "19"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ligne de produit à transaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "20"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Entreprise à ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "25"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket à entreprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "26"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Transaction à ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "27"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket à transaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}