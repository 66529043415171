'use es6';

/**
 * @typedef {Object} Theme
 */

/**
 * Available themes.
 *
 * TODO:  The themes commented out need modification.
 *        They use .CodeMirror specifier class which breaks usage
 *        on panels, dialogs, etc.
 *        Also, some styles do not provide basic bg/color and instead rely on
 *        fallthrough from codemirror.css
 *
 * @enum {String}
 * @readonly
 */
export default Object.freeze({
  // DEFAULT: 'default',
  HUBSPOT_CANVAS_DARK: 'hubspot-canvas-dark',
  HUBSPOT_CANVAS_LIGHT: 'hubspot-canvas-light',
  // '3024_DAY': '3024-day',
  // '3024_NIGHT': '3024-night',
  // ABCDEF: 'abcdef',
  // AMBIANCE_MOBILE: 'ambiance-mobile',
  // AMBIANCE: 'ambiance',
  // BASE16_DARK: 'base16-dark',
  // BASE16_LIGHT: 'base16-light',
  // BESPIN: 'bespin',
  // BLACKBOARD: 'blackboard',
  // COBALT: 'cobalt',
  // COLORFORTH: 'colorforth',
  // DARCULA: 'darcula',
  // DRACULA: 'dracula',
  // DUOTONE_DARK: 'duotone-dark',
  // DUOTONE_LIGHT: 'duotone-light',
  // ECLIPSE: 'eclipse',
  // ELEGANT: 'elegant',
  // ERLANG_DARK: 'erlang-dark',
  // GRUVBOX_DARK: 'gruvbox-dark',
  // HOPSCOTCH: 'hopscotch',
  ICECODER: 'icecoder',
  // IDEA: 'idea',
  // ISOTOPE: 'isotope',
  // LESSER_DARK: 'lesser-dark',
  // LIQUIBYTE: 'liquibyte',
  // LUCARIO: 'lucario',
  // MATERIAL: 'material',
  // MBO: 'mbo',
  // MDN_LIKE: 'mdn-like',
  // MIDNIGHT: 'midnight',
  // MONOKAI: 'monokai',
  // NEAT: 'neat',
  // NEO: 'neo',
  // NIGHT: 'night',
  // OCEANIC_NEXT: 'oceanic-next',
  PANDA_SYNTAX: 'panda-syntax',
  // PARAISO_DARK: 'paraiso-dark',
  // PARAISO_LIGHT: 'paraiso-light',
  // PASTEL_ON_DARK: 'pastel-on-dark',
  // RAILSCASTS: 'railscasts',
  // RUBYBLUE: 'rubyblue',
  // SETI: 'seti',
  // SHADOWFOX: 'shadowfox',
  SOLARIZED_DARK: 'solarized dark',
  SOLARIZED_LIGHT: 'solarized light',
  // SSMS: 'ssms',
  // THE_MATRIX: 'the-matrix',
  // TOMORROW_NIGHT_BRIGHT: 'tomorrow-night-bright',
  // TOMORROW_NIGHT_EIGHTIES: 'tomorrow-night-eighties',
  // TTCN: 'ttcn',
  // TWILIGHT: 'twilight',
  // VIBRANT_INK: 'vibrant-ink',
  // XQ_DARK: 'xq-dark',
  // XQ_LIGHT: 'xq-light',
  // YETI: 'yeti',
  ZENBURN: 'zenburn'
});