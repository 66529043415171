"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 33627109177;
const slug = exports.slug = 'guides/api/settings/users/user-provisioning';
const title = exports.title = 'Settings API | User Provisioning';
const name = exports.name = 'vNext Docs DP - User Provisioning';
const metaDescription = exports.metaDescription = ' The User Provisioning endpoints allow you to provision and deprovision HubSpot users programatically.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "specifying-a-user",
  "label": "Specifying a user",
  "parent": null
}, {
  "depth": 0,
  "id": "permission-sets",
  "label": "Permission Sets",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      h2: "h2",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "User Provisioning"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the user provisioning API to create and manage users in the account, along with their permissions. You can also set user ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstName"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastName"
      }), " properties through this API."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve and update other user information, such as their job title and working hours, use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/users/user-details",
        children: "users API"
      }), " instead."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Specifying a user"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When specifying a user with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userId"
      }), " path parameter, you can either use the user's ID or the user's email. Specifying based on the user's ID is the default behavior but if you want to use the user's email, you can use the query parameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " to set that."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request is fetching a user with the email ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "mailto:_myUser@gmail.com",
        children: "_myUser@gmail.com"
      }), ":_"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/settings/v3/users/myUser@gmail.com?idProperty=EMAIL"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " query parameter in any endpoint that takes in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userId"
      }), " as a path parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Permission Sets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot accounts can define permission sets to easily manage multiple users' permissions at once. Once you've created a role and specified certain permissions for it, you can then assign new and existing users the role to grant them the same permissions. Permission sets that have paid seats attached to them can only be modified by applications that have the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "billing-write"
      }), " scope."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following is an example of a role definition for a user:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example specification of a role definition\n{\n  \"id\": \"1234\",\n  \"name\": \"a new role\",\n  \"requiresBillingWrite\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note that permission sets must be ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/create-roles",
        children: "created in the app"
      }), " before attempting to assign them to users."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}