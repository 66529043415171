"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601073957;
const slug = exports.slug = 'getting-started/tools-to-help-you-build';
const title = exports.title = '作成に役立つツール';
const name = exports.name = '作成に役立つツール（開発者ドキュメント2024）';
const metaDescription = exports.metaDescription = 'HubSpotでの作成に役立つさまざまなツールやリソースに説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "hubspot-cli",
  "label": "HubSpot CLI",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-vs-code%E6%8B%A1%E5%BC%B5%E6%A9%9F%E8%83%BD",
  "label": "HubSpot VS Code拡張機能",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E3%83%A9%E3%82%A4%E3%83%96%E3%83%A9%E3%83%AA%E3%83%BC",
  "label": "クライアントライブラリー",
  "parent": null
}, {
  "depth": 0,
  "id": "postman",
  "label": "Postman",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%EF%BC%88%E3%83%99%E3%83%BC%E3%82%BF%E7%89%88%EF%BC%89",
  "label": "プロジェクト（ベータ版）",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "作成に役立つツール"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotは、開発者プラットフォームで作成する際に役立つさまざまなツールを提供しています。HubSpotのAPIは柔軟性があり、使い慣れた開発環境を使用して連携を作成できますが、以下のツールは全ての開発者が利用できます。これらのツールを利用することで、生産性が向上し、開発ワークフローの合理化に役立ちます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot CLI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CMS Hubで作成している場合は、HubSpot CLIをインストールして、HubSpotでローカルに作成できます。インストールが完了すると、使い慣れた環境で使い慣れたツールを使用できるようになります。また、GitHubアカウントで継続的インテグレーションを設定して、変更を追跡し、組織内の他の開発者とのコラボレーションを促進することもできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "HubSpot CLIのインストール"
      }), "方法をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot VS Code拡張機能"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot Visual Studio Code拡張機能を使用すると、VS Codeを離れることなく、開発ワークフローを一元化できます。インストールが完了したら、HubSpot CLIを直接使用して、接続アカウントを認証および管理したり、HubL構文の強調表示とオートコンプリートのサポートを活用したりできます。既定のHubSpotアカウントの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager",
        children: "デザインマネージャー"
      }), "とファイルを同期することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
        children: "HubSpot VS Code拡張機能のインストールと使用方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "クライアントライブラリー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotはクライアントライブラリーを提供しているため、HubSpot APIを使用するときに使い慣れたプログラミング言語を使用できます。クライアントライブラリーは、JavaScript、Ruby、Python、およびPHPで利用できます。各クライアントライブラリーにより、APIリクエストを手動で作成する代わりに、HubSpotアカウントのデータの読み取り、編集、作成、削除を行うために関数呼び出しを呼び出すことができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "Node"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-php",
        children: "PHP"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-ruby",
        children: "Ruby"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-python",
        children: "Python"
      }), "のクライアントライブラリーの設定と使用について、詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Postman"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Postmanは、HubSpotへの認証リクエストを簡単に実行するために使用できる機能豊富なAPIクライアントです。また、HubSpotの全ての公開APIエンドポイントの精選されたコレクションを閲覧することもできます。これらのコレクションは、全てのリクエストフィールドとレスポンスフィールドの仕様を最新の状態に保つために、HubSpotによって定期的なメンテナンスが行われていますす。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/",
        children: "Postmanクライアント"
      }), "をダウンロードし、公式の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/hubspot",
        children: "HubSpot Postmanコレクション"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プロジェクト（ベータ版）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロジェクトは、HubSpotのCRMをカスタマイズするために使用できる上位レベルのコンテナーです。HubSpot CLIと併せて使用して、プライベートアプリのビルドとデプロイ、UI拡張機能の作成、CRMのカスタムコンポーネントのデータを動的に読み書きするためのサーバーレス関数の開発を行うことができます。デプロイが完了したら、HubSpot内でプロジェクトを管理できるため、ビルド履歴を確認し、API呼び出しを監視できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project",
        children: "プロジェクトをゼロから作成する方法"
      }), "を確認するか、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/quickstart",
        children: "UI拡張機能のクイックスタートガイド"
      }), "の説明に従ってください。どのように始めたらよいか分からない場合は、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "サンプルプロジェクト"
      }), "もご覧ください。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}