"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601074021;
const slug = exports.slug = 'getting-started/overview';
const title = exports.title = 'Getting started overview';
const name = exports.name = 'Getting started overview (developer docs 2024)';
const metaDescription = exports.metaDescription = 'Learn how to get started developing on HubSpot.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "the-advantages-of-a-connected-platform",
  "label": "The advantages of a connected platform",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-install-tools-to-help-you-build",
  "label": "1. Install tools to help you build",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-decide-what-to-build",
  "label": "2. Decide what to build",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-join-your-peers",
  "label": "3. Join your peers",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-learn-more%2C-your-way",
  "label": "4. Learn more, your way",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Getting started overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Whether you’re a new HubSpot developer or looking to expand your skills, this section will guide you through the process of getting up and running. You’ll explore the different development routes available, as well as the specific tooling and accounts you’ll need to achieve your goals."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "The advantages of a connected platform"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "First, it’s helpful to consider what makes HubSpot a powerful platform for developers."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The foundation of every HubSpot account is the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRM"
      }), " (Customer Relationship Management) platform, a database of business relationships and processes. HubSpot offers different routes for external developers and partners to work with the CRM so they can create extra value for HubSpot end users. This includes:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Creating integrations and/or extensions with HubSpot’s APIs to sync data between HubSpot and external platforms."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Building custom websites using the HubSpot CMS (Content Management System). In addition to a full suite of content management tools, the CMS fully integrates with the CRM. This makes campaign launches, capturing leads and ongoing customer communications easier to manage."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Customizing the CRM with UI extensions. Developers can use React to build flexible custom cards that integrate seamlessly with HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the next section, you’ll get an overview of the tooling that’s available for HubSpot developers. Then, you'll choose a route to continue on your HubSpot developer journey."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Install tools to help you build"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are a wide range of tools available for HubSpot developers. The mix of tools you’ll need will depend on your specific goals."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubSpot CLI:"
        }), " installing the HubSpot CLI for local development is a great starting point for many development tasks. The CLI is used when building UI Extensions and CMS assets, and can be used to create and manage secrets, HubDB tables, and custom object data from your account. You'll need to have ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: (0, _jsxRuntime.jsx)("u", {
            children: "Node.js"
          })
        }), " installed (version 18 or higher is required, and the LTS version is recommended). To install the CLI globally, run the following command:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Visual studio code:"
          }), " installing ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://code.visualstudio.com/",
            children: "Visual Studio Code"
          }), " means you can take advantage of the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
            children: "HubSpot Visual Studio Code extension"
          }), ", which allows you to centralize your development workflow."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Client libraries"
          }), ": HubSpot provides client libraries for ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-nodejs",
            children: "Node"
          }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-php",
            children: "PHP"
          }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-ruby",
            children: "Ruby"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-python",
            children: "Python"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Postman:"
          }), " use ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/",
            children: "Postman"
          }), " to access ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/hubspot",
            children: "HubSpot's Postman Collections"
          }), ". Throughout HubSpot's API documentation, you'll find buttons for running calls using the collection."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/run-in-postman-current-docs-site.png",
            alt: "run-in-postman-current-docs-site"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/tools-to-help-you-build",
        children: "HubSpot's developer tooling"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Decide what to build"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There’s no one-size-fits-all route to developing with HubSpot. It’s a flexible platform that can meet a wide range of needs."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["One of the four broad categories listed below should cover most of these needs. You can choose the one that’s most relevant for you right now for a brief introduction, along with some immediate steps to get you started. You can also get a sense of the best tools for the job, and whether a specific ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "type of HubSpot account"
      }), " is required."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#use-apis-and-build-integrations",
          children: "Use APIs and build integrations"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-for-the-app-marketplace",
          children: "Build apps for the marketplace"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#customize-the-hubspot-ui",
          children: "Customize the HubSpot UI"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-custom-websites",
          children: "Build custom websites"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Join your peers"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Connect with your peers that are already building on HubSpot. Ask questions, share knowledge, build community, and maybe even make some friends along the way."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "Developer Slack"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
          children: "Developer Forums"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/hubspotdev",
          children: "Developer Advocacy GitHub"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Learn more, your way"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you’re up and running, there are plenty of resources available to help you dig deeper. You can access in-depth guides, detailed reference docs or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses",
        children: "HubSpot academy courses"
      }), ", depending on your preferred learning style."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}