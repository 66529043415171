"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611103;
const slug = exports.slug = 'guides/cms/content/global-content';
const title = exports.title = 'Global Content';
const name = exports.name = 'Global content';
const metaDescription = exports.metaDescription = 'Global content is content that can be reused across your templates to create consistent pieces of information such as website headers, footers, sidebars, logos, or other components.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png';
const featuredImageAltText = exports.featuredImageAltText = 'Global Content Editor';
const toc = exports.toc = [{
  "depth": 0,
  "id": "overview",
  "label": "Overview",
  "parent": null
}, {
  "depth": 1,
  "id": "global-partials-vs-global-modules",
  "label": "Global partials vs global modules",
  "parent": "overview"
}, {
  "depth": 0,
  "id": "global-partials",
  "label": "Global partials",
  "parent": null
}, {
  "depth": 1,
  "id": "create-a-global-partial",
  "label": "Create a global partial",
  "parent": "global-partials"
}, {
  "depth": 1,
  "id": "add-drag-and-drop-areas-to-global-partials",
  "label": "Add drag and drop areas to global partials",
  "parent": "global-partials"
}, {
  "depth": 1,
  "id": "include-a-global-partial-in-your-existing-template",
  "label": "Include a global partial in your existing template",
  "parent": "global-partials"
}, {
  "depth": 0,
  "id": "global-modules",
  "label": "Global modules",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      pre: "pre",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Global content"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Global content is content that is shared across portions of a website. Common examples are website headers, footers, and sidebars. As a developer, you'll specify which components should be global by using global partials or by making modules global. HubSpot provides a different editing experience for content editors that makes it easy to edit the global content and preview the changes across pages before publishing. To learn more about how to edit your global content, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "how to use global content across multiple templates"
      }), " on HubSpot Knowledge Base."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png",
        alt: "Global content editor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Overview"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Global content is best used to display the same information across multiple pages. For example, your website's header and footer, such as the header at the top of this page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubspot-developers-header.png",
        alt: "hubspot-developers-header"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are some additional examples of areas where you can use global content:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Secondary navigation for different sections of your website"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Copyright footers (or sub footers)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Blog post sidebars (for showing recent posts, author listings, and more)"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Because global content is used in multiple places throughout a website, it is even more crucial to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "design and build your global partials and modules for accessibility"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Global partials vs global modules"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As a developer, you can create global partials and global modules, with a few key differences between them:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Global partials are a type of template built using HTML & HubL that can be reused across your entire website. The most common types of partials are website headers, sidebars, and footers."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Global modules are modules that are made up of single or multiple pieces of content that can be used across multiple pages on your site. Some common types of global modules can be items such as blog subscribe forms, secondary navigation elements, and calls-to-action."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You should avoid including global modules within global partials, as it can create a negative content editing experience."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["All modules and fields inside of your global partials and global modules are easily editable inside of the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
          children: "global content editor"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Global partials"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a global partial"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A global partial is a type of template, which you can create locally through the HubSpot CLI by using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "create command"
      }), ", as shown below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <partial-file-name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When prompted to pick a type of template, select ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global partial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This will create your template in the desired directory with the following template annotations included in the HTML file."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: global_partial\n  label: Page Header\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To see a sample of a global content partial, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/header.html",
        children: "please reference our boilerplate on GitHub."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add drag and drop areas to global partials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can enable drag and drop content capabilities inside of your global partials by adding in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " tags similar to enabling them in page templates. View our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "Drag and Drop Area documentation"
      }), " for more information."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Include a global partial in your existing template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add a global partial into one of your existing templates, use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), " HubL tag while referencing the path to your partial. Below is an example from ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L21",
        children: "the CMS boilerplate"
      }), " using this tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When global partials are output, they contain a wrapping ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " around the global partial. This is used by the page editor to identify that the piece of content is a global partial."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div\n  data-global-resource-path=\"cms-theme-boilerplate/templates/partials/header.html\"\n>\n  <!-- Your header.html code is output here -->\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " do not use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "global_partial"
        }), " within the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), " of a template. Doing so would result in invalid HTML."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["In most situations where you'd want to use a global partial in the header, it may make more sense to use a global module instead with a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{%require_head%}"
          })
        }), " to insert custom code into the head, and still provide module fields."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Global modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can create global modules like any other module using the CLI by running the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create"
      }), " command, as shown below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create module <module_name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A global module is differentiated by the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), " flag in the module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json file\n{\n  \"css_assets\": [],\n  \"external_js\": [],\n  \"global\": true,\n  \"help_text\": \"\",\n  \"host_template_types\": [\"PAGE\"],\n  \"js_assets\": [],\n  \"other_assets\": [],\n  \"smart_type\": \"NOT_SMART\",\n  \"tags\": [],\n  \"is_available_for_new_content\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "create global modules in HubSpot by using the design Manager"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Learn more about working with modules in the following related resources:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Modules overview"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "HubSpot's default modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configuring modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Using modules in templates"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}