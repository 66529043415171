"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const slug = exports.slug = 'reference/cms/overview';
const title = exports.title = 'Visão geral de referência da CMS';
const metaDescription = exports.metaDescription = 'Documentos de referência do Sistema de Gerenciamento de Conteúdo (CMS) da HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "guias-de-desenvolvimento-do-cms",
  "label": "Guias de desenvolvimento do CMS",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral de referência do CMS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta seção inclui documento de referência do desenvolvedor para o Sistema de gerenciamento de conteúdo (CMS) da HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fornece acesso rápido a documentos de referência relacionados a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "módulos"
      }), ", a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), " linguagem de modelos, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "campos"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "funções sem servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guias de desenvolvimento do CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter uma visão geral do desenvolvimento no HubSpot CMS, consulte a seção ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "Guias de desenvolvimento do CMS"
      }), ". Eles incluem uma visão geral dos recursos e ferramentas do CMS, bem como um guia de início rápido."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;