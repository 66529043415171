"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611099;
const slug = exports.slug = 'guides/cms/content/themes/default-themes';
const title = exports.title = 'Default Themes';
const name = exports.name = '[new] Default themes';
const metaDescription = exports.metaDescription = 'HubSpot provides a variety of themes for you to use, modify, or use as a reference for your own website theme. You can also download these themes to your local environment using our CLI tools.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "modifying-the-default-themes",
  "label": "Modifying the default themes",
  "parent": null
}, {
  "depth": 0,
  "id": "default-themes-listing",
  "label": "Default themes listing",
  "parent": null
}, {
  "depth": 1,
  "id": "growth-theme",
  "label": "Growth theme",
  "parent": "default-themes-listing"
}, {
  "depth": 0,
  "id": "default-themes-in-maintenance-mode",
  "label": "Default themes in maintenance mode",
  "parent": null
}, {
  "depth": 1,
  "id": "barricade-theme",
  "label": "Barricade theme",
  "parent": "default-themes-in-maintenance-mode"
}, {
  "depth": 1,
  "id": "education-theme",
  "label": "Education theme",
  "parent": "default-themes-in-maintenance-mode"
}, {
  "depth": 1,
  "id": "martech-theme",
  "label": "Martech theme",
  "parent": "default-themes-in-maintenance-mode"
}, {
  "depth": 1,
  "id": "rally-theme",
  "label": "Rally theme",
  "parent": "default-themes-in-maintenance-mode"
}, {
  "depth": 1,
  "id": "session-theme",
  "label": "Session theme",
  "parent": "default-themes-in-maintenance-mode"
}, {
  "depth": 1,
  "id": "sprout-theme",
  "label": "Sprout theme",
  "parent": "default-themes-in-maintenance-mode"
}, {
  "depth": 1,
  "id": "vitality-theme",
  "label": "Vitality theme",
  "parent": "default-themes-in-maintenance-mode"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      em: "em",
      h2: "h2",
      h3: "h3",
      img: "img",
      h4: "h4",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Default themes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot provides a set of default themes that content creators can use to build website pages without needing to modify the base theme. These themes are best suited for content creators who have less access to developer resources."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to develop your own theme, it's recommended to start with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "HubSpot CMS Boilerplate"
      }), ". However, you can download and modify default themes using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "HubSpot CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about the currently available default themes."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Starter"
        }), " accounts only have access to the Growth theme."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modifying the default themes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot’s default themes are located in a folder labeled @hubspot in the developer file system. This folder is read-only and the contents can only be modified by HubSpot. If you'd like to modify a theme, you will need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "fetch"
      }), " the theme you want, and then ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "upload"
      }), " the theme with your changes to a different folder than @hubspot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Default themes listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Feel free to download our default themes using the CLI commands listed. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "Learn more about the fetch command"
      }), " in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Growth theme"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/5Cdocs/default-themes/growth-homepage.jpg",
            alt: "Growth Theme Homepage"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/growth/home",
              children: "View Growth theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Any"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "12 Sections"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "16 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/growth <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Default themes in maintenance mode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following themes have been moved to maintenance mode. They will no longer be updated with new features and will only receive updates as they relate to security, accessibility, and bug fixes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Barricade theme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A theme geared towards construction businesses."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-barricade-theme-thumb-4.jpg",
            alt: "Barricade theme screenshot"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/barricade/home",
              children: "View Barricade theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Construction"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "10 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/barricade <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Education theme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A theme designed for Schools, Colleges, or other educational institutions."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-education-theme-thumb-4.jpg",
            alt: "Education theme screenshot"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/education/home",
              children: "View Education theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Education"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "13 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/education <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Martech theme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A website theme geared towards marketing technology businesses."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-martech-theme-thumb-4.jpg",
            alt: "Martech theme screenshot"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/martech/home",
              children: "View Martech theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Marketing Agency"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "9 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/martech <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Rally theme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A theme designed for Businesses and Agencies."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-rally-theme-thumbnail-1-4.png",
            alt: "Rally theme screenshot"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/rally/home",
              children: "View Rally theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Marketing Agency"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "14 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/cms-rally <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Session theme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A theme designed for Businesses."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-session-theme-thumb-4.jpg",
            alt: "Session theme screenshot"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/session/home",
              children: "View Session theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "9 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/session <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sprout theme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A theme designed for Businesses and Agencies."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-sprout-theme-thumb-4.jpg",
            alt: "Sprout theme screenshot"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/sprout/home",
              children: "View Sprout theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "15 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "21 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/sprout <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vitality theme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A theme designed for Businesses and Agencies."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-vitality-theme-thumb-4.jpg",
            alt: "Vitality theme screenshot"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/vitality/home",
              children: "View Vitality theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Healthcare, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "8 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/vitality <destination-folder>\n"
          })
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}