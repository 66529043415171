"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694132;
const slug = exports.slug = 'guides/cms/content/modules/overview';
const title = exports.title = 'モジュールの概要';
const name = exports.name = 'モジュールの概要';
const metaDescription = exports.metaDescription = 'HubSpot CMSとその機能性を理解するには、モジュールを理解することが重要です。モジュールとは、テンプレートで使用したり、ドラッグ＆ドロップエリアおよびフレキシブルカラムを使用してページに追加したりできる、再利用可能なコンポーネントのことです。';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/team-member-editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'チーム メンバー モジュール エディター';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E4%BE%8B",
  "label": "例",
  "parent": null
}, {
  "depth": 1,
  "id": "%E7%B7%A8%E9%9B%86%E7%94%A8%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%BC%E3%83%95%E3%82%A7%E3%82%A4%E3%82%B9",
  "label": "編集用ユーザーインターフェイス",
  "parent": "%E4%BE%8B"
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%A8%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%A4%E3%83%B3%E3%82%B9%E3%82%BF%E3%83%B3%E3%82%B9",
  "label": "モジュールとモジュールインスタンス",
  "parent": null
}, {
  "depth": 0,
  "id": "fields.json",
  "label": "fields.json",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB-%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89-%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6html%E3%82%92%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%99%E3%82%8B",
  "label": "モジュール フィールド データを使用してHTMLをレンダリングする",
  "parent": "fields.json"
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%A7%E3%81%AE%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E4%BD%BF%E7%94%A8",
  "label": "テンプレートでのモジュールの使用",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E5%90%91%E4%B8%8A%E3%81%AB%E3%82%82%E5%BD%B9%E7%AB%8B%E3%81%A4%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "アクセシビリティーの向上にも役立つモジュール",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AB%E3%82%88%E3%81%A3%E3%81%A6%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%A9%E3%82%A4%E3%82%BA%E3%81%8C%E5%AE%B9%E6%98%93%E3%81%AB",
  "label": "モジュールによってローカライズが容易に",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%81%AF%E3%81%98%E3%82%81%E3%81%AB",
  "label": "はじめに",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E6%83%85%E5%A0%B1",
  "label": "関連情報",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ol: "ol",
      li: "li",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      strong: "strong",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "モジュールの概要"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot CMSとその機能性を理解するには、モジュールを理解することが重要です。モジュールとは、テンプレートで使用したり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "ドラッグ＆ドロップエリア"
      }), "および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "フレキシブルカラム"
      }), "を使用してページに追加したりできる、再利用可能なコンポーネントのことです。HubSpot提供のモジュールを使用できるだけでなく、開発者は独自のモジュールを作成して、お客様の声やフォトギャラリーといったあらゆる目的で使用できます。モジュールを作成するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "ローカルの開発ツールを使用"
      }), "するか、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "デザインマネージャーを使用"
      }), "します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールは2つの要素で構成されています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["一連のフィールドを使用して作成された", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-user-interface-for-editing",
          children: "ユーザーインターフェイス"
        }), "。ユーザーがモジュールインスタンスを編集するときに表示されます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CSSとJSが関連付けられたHTML+HubLテンプレートフラグメント。これによりHTMLの出力が定義されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "例"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールの理解を深めるために、シンプルな「チームメンバー」モジュールを例として説明します。このモジュールは、写真、チームメンバーの名前と役職、簡単な自己紹介からなります。CMSウェブページに組み込まれると、次のように表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-module-instance.png",
        alt: "チーム メンバー モジュール インスタンス"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "編集用ユーザーインターフェイス"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者はフィールドを使用して、モジュールのユーザーインターフェイス（UI）を構築します。続いて、開発するモジュールの種類、必要なデータ、編集方法に基づいて、使用するフィールドを選択します。この例では、モジュールに次のフィールドを使用しています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "チームメンバーの写真に使用する画像フィールド"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "チームメンバーの名前と役職に使用する2つのテキストフィールド"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "簡単な自己紹介に使用するリッチ テキスト フィールド"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "制作担当者がモジュールを編集する際のUIは、開発者がモジュールに追加したフィールドと各フィールドの設定方法に基づいて組み立てられます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-editor.png",
        alt: "チーム メンバー モジュール エディター"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールとモジュールインスタンス"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールに関する2つの頻出用語があります。その違いを理解することが重要です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "モジュール"
        }), " - ：テンプレートやページに追加できる、再利用可能な構成要素。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "モジュールインスタンス"
        }), " - ：ページ上にレンダリングされた個々のモジュール。モジュールインスタンスはそれぞれ別個のフィールド値を使用できるため、同じモジュールであっても、モジュールインスタンスによっては外観が異なる場合があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "fields.json"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールのフィールドはオブジェクト配列としてJSONで定義されます。各フィールドに、名前、型、既定値があります。編集方法を制御するフィールドのタイプによっては、他にもプロパティーがあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"name\": \"team_member_photo\",\n    \"label\": \"Team Member Photo\",\n    \"required\": true,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"type\": \"image\",\n    \"default\": {\n      \"src\": \"\",\n      \"alt\": \"\"\n    }\n  },\n  {\n    \"name\": \"team_member_name\",\n    \"label\": \"Team member name\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"Joshua Beck\"\n  },\n  {\n    \"name\": \"team_member_position\",\n    \"label\": \"Team member position\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"CEO, Co-Founder\"\n  },\n  {\n    \"name\": \"team_member_bio\",\n    \"label\": \"Team member bio\",\n    \"required\": true,\n    \"type\": \"richtext\",\n    \"default\": \"<p>Joshua has over 20 years of experience in the tech industry. He helped start this company in 2015 with the mission of helping people grow. In his spare time he loves hanging out with his kids, going to the beach, and cooking.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["使用可能な全てのフィールドの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "モジュールとテーマのフィールド"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュール フィールド データを使用してHTMLをレンダリングする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["各フィールドの値は、モジュールのHTML+HubLフラグメントで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), "変数を介して使用できます。各フィールドのデータにアクセスするには、module変数のプロパティーを使用します。チーム メンバー モジュールの例では、チームメンバーの名前にアクセスするために", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ module.team_member_name }}"
      }), "プロパティーを使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<section class=\"team-member\">\n  <img class=\"team-member__image\" src=\"{{ module.team_member_image.src }}\" alt=\"{{ module.team_member_image.alt }}\">\n  <h3 class=\"team-member__name\">{{ module.team_member_name }}</h3>\n  <p class=\"team-member__position\">{{ module.team_member_position }}</p>\n  <div class=\"team-member__bio\">{{ module.team_member_bio }}</div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレートでのモジュールの使用"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールをテンプレートに追加するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#basic-module-syntax",
        children: "module"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#block-syntax",
        children: "module_block"
      }), "、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_module"
      }), "タグを使用し、パラメーターとしてモジュールのパスを指定します。モジュールに含まれるフィールドの既定値は、テンプレートレベルでのオーバーライドもできます。下の例の後半部分のように、フィールド名に対応するmoduleタグにパラメーターを追加してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_identifier\" path=\"/modules/team-member.module\" %}\n\n\n{# override default values in a module instance #}\n\n{% module \"unique_identifier\"\n  path=\"/modules/team-member.module\",\n  team_member_name=\"Brian Halligan\",\n  team_member_position=\"CEO\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["モジュールを相互にネストすることはできません。ほとんどの場合、モジュール同士をネストさせる必要が生じるのはレイアウト上の理由からです。その場合の対処法として、通常は", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "ドラッグ＆ドロップエリア"
        }), "内のセクションを使用するのが適切です。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アクセシビリティーの向上にも役立つモジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールはウェブサイト全体で使用されます。複数のページで使用されたり、同じページ上で複数回使用されたりすることもあります。このことから、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "アクセシビリティーを念頭にモジュールのHTML、CSS、JSを作成"
      }), "すると、障害がある人とない人の両方にとって非常に使いやすいサイトを実現できる可能性があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールによってローカライズが容易に"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アクセシビリティーの場合と同様に、モジュール内の全てのコンテンツがフィールドによって構築されるようにモジュールを作成しておくと、後でローカライズできます。例えば、「Featured articles」というモジュールがあるとします。「Featured articles」というテキストをハードコードする代わりに、テキストフィールドまたはリッチ テキスト フィールドを使用します。その結果、テキストを他の言語に変更することが可能になります。CMSのローカライズの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "多言語"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "はじめに"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールを使い始めるには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "モジュールの基本操作"
      }), "チュートリアルを参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連情報"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "モジュールの設定"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "テンプレートでモジュールを使用する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "既定のモジュール"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "モジュールエディター"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}