"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158721871211;
const slug = exports.slug = 'getting-started/what-to-build';
const title = exports.title = '作成内容';
const name = exports.name = '作成内容（開発者ドキュメント2024）';
const metaDescription = exports.metaDescription = 'HubSpotでのさまざまな開発方法について説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E9%80%A3%E6%90%BA%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "APIを使用して連携を作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E5%91%BC%E3%81%B3%E5%87%BA%E3%81%97%E3%81%AE%E8%AA%8D%E8%A8%BC%E3%82%92%E8%A1%8C%E3%81%86",
  "label": "非公開アプリを使用して呼び出しの認証を行う",
  "parent": "api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E9%80%A3%E6%90%BA%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9%E5%90%91%E3%81%91%E3%81%AB%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "アプリマーケットプレイス向けに作成する",
  "parent": "api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E9%80%A3%E6%90%BA%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "hubspot-ui%E3%82%92%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%9E%E3%82%A4%E3%82%BA%E3%81%99%E3%82%8B",
  "label": "HubSpot UIをカスタマイズする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "カスタムウェブサイトを作成する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      strong: "strong",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "作成内容"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotには、目標に応じていくつかの開発ルートがあります。このページには、各ルートの概要と、各ルートに必要なツールとアカウントが記載されています。また、詳細なドキュメントへのリンクや、関連するクイックスタートガイドも記載されています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#use-apis-and-build-integrations",
          children: "APIを使用して連携を作成する"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#get-started-authenticating-calls-with-a-private-app",
              children: "非公開アプリを使用して呼び出しの認証を行う"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#build-for-the-app-marketplace",
              children: "アプリマーケットプレイス向けに作成する"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#customize-the-hubspot-ui",
          children: "HubSpot UIをカスタマイズする"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-custom-websites",
          children: "カスタムウェブサイトを作成する"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "APIを使用して連携を作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのAPIを使用してカスタムソリューションを作成します。カスタムソリューションには、HubSpotと外部システム間でのデータの共有、Webhookを使用したアカウントの変更のリッスン、ビジネス固有のデータを保存するカスタムオブジェクトの作成などがあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "使用例："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "カスタムオブジェクトを使用して、ビジネスを最適に表現できるようにCRMがデータを格納する方法をカスタマイズする。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "市場投入戦略活動のより詳細なイメージを提示するため、外部システムのデータを同期する。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プロセスに最適なCRM UIの機能を拡張する。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "アプリの2種類の作成方法"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["作成する内容に応じて、2", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "種類のアプリ"
        }), "があります。APIと連携を初めて利用する場合には、非公開アプリから始めることをお勧めします。これは、非公開アプリの方がより迅速に設定して認証に使用できるためです。アプリの作成に慣れたら、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-for-the-app-marketplace",
          children: "公開アプリ"
        }), "について詳しく学びます。公開アプリは複数のアカウントにインストールでき、他のタイプの拡張機能を作成できるようになります。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "非公開アプリの使用例："
        }), (0, _jsxRuntime.jsx)(_components.em, {
          children: "「自分の会社/チームのためにアプリケーションを作成したいと考えています。」”"
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "非公開アプリを使用して呼び出しの認証を行う"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ほとんどのAPI呼び出しでは、HubSpotアカウントのデータを操作するために認証が必要になります。アカウントへの呼び出しを開始するには、非公開アプリを作成し、そのアクセストークンを認証に使用します。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注意：**HubSpotアカウント内で非公開アプリを作成するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "スーパー管理者"
        }), "権限が必要です。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "使い方："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "非公開アプリ"
        }), "を作成します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
          children: "非公開アプリを使用してAPI呼び出しを認証する方法"
        }), "をご確認ください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/overview",
          children: "クライアントライブラリー"
        }), "を使用してAPI呼び出しを行うこともできます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アプリマーケットプレイス向けに作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのアプリマーケットプレイスと公開アプリの連携を作成します。これにより、HubSpotユーザーが連携を各自のアカウントにインストールできるようになります。マーケットプレイス向けの公開アプリを作成するには、HubSpotのアプリ マーケットプレイス ガイドラインに従う必要があります。また、アプリ開発者アカウントが必要です。"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "公開アプリの認証にはOAuthが使用されます。公開アプリは複数のアカウントにインストールでき、またマーケットプレイスで配布できます。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "公開アプリの使用例："
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "「私はHubSpotのパートナーであり、クライアントに適用できる再利用可能なアプリを作成したいと考えています。」”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "「私はHubSpotのパートナーであり、できるだけ多くのHubSpotのお客様に当社の機能を宣伝するために、アプリをマーケットプレイスで利用可能にしたいと考えています。」”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "使い方："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-hubspot/developers",
            children: "アプリ開発者アカウントを作成します。"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "クイックスタート："
          }), (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "公開アプリを作成します"
          }), "。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/oauth-quickstart-guide",
            children: "OAuthクイックスタートガイドに従います。"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
            children: "アプリマーケットプレイスへの掲載に必要な条件を確認します。"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "アプリマーケットプレイスにアプリを提出します。"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot UIをカスタマイズする"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["注意：", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "UI拡張機能を作成するには、"
          }), "__"]
        }), "Sales Hubまたは**__**Service Hub __Enterpriseサブスクリプションが必要です。ただし、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "開発者アカウント"
        }), "では無料でUI拡張機能を作成できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotが提供するCRMレコードのUI要素の他に、UI拡張機能を使用してCRMをカスタマイズすることもできます。これらの拡張機能は、開発者プロジェクトツールを使用してローカルに作成されます。このツールでは、CLIを使用して作成し、HubSpotにデプロイできます。UI拡張機能SDKは、拡張機能をカスタマイズするためのメソッド、機能、ツール、およびコンポーネントを提供します。UI拡張機能の作成をどのように始めたらよいか分からない場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "HubSpotのサンプルプロジェクト"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "プロジェクトを使用すると、HubSpot CLIを使って非公開アプリ、UI拡張機能、サーバーレス関数をローカルに作成してデプロイできます。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "UI拡張機能の使用例："
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "「コンタクトレコードと会社レコードにカスタムフォームを追加して、カスタマーサポートチームが顧客との通話中にJIRAチケットを作成できるようにしたいと考えています。」”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "「管理パートナーが必要な情報を一目で見つけることができるように、取引記録全体を対象とした詳細な取引パイプラインの要約を表示する必要があります。」”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "使い方："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Sales Hub"
          })
        }), "または__", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Service Hub"
        }), " Enterpriseサブスクリプションをご利用でない場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "開発テストアカウント"
        }), "を作成してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/overview#get-started",
          children: "開発プロジェクトのベータ版"
        }), "に参加します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "クイックスタート："
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/quickstart",
          children: "UI拡張機能クイックスタート ガイド"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sdk",
          children: "UI拡張機能SDK"
        }), "の詳細をご確認ください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カスタムウェブサイトを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMS（コンテンツ マネジメント システム）ソフトウェアを使用すると、サイトにアクセスするユーザーに適応して自動的にカスタマイズされる強力なウェブサイトとランディングページを作成できます。CMSを使用して作成することで、全てのウェブサイト訪問者のインタラクションがアカウントのCRMに直接接続されます。CRMは、セールス、マーケティング、サービス、レベニューオペレーションの各チームが作業の効率化のために使用しているツールです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のようなテクノロジーを使用して、使い慣れた環境でHubSpot CLIを使用してHubSpotウェブサイトをローカルに作成します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML、CSS、JavaScript"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubL（HubSpotのJinjavaベースのテンプレート言語）"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "React（JavaScriptでレンダリングされるモジュールとパーシャルを作成するために使用できる）"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "GraphQL（HubSpotデータのクエリーに使用できる）"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["バージョン管理にGitHubを使用する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "GitHubアクションを使用して連携をセットアップ"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "CMSにより、HubSpot CLIを使用してローカルにウェブサイトを作成できます。多言語サポート、CTA、フォーム、アクセス権設定など、汎用性と適応性に優れたさまざまなツールを利用できます。HubSpotは、セキュリティ、サーバー側のパフォーマンス、CDN、ホスティング、および稼働時間の保証にも対応します。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMSの使用例："
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "「CRMで管理されているインベントリから情報を直接抽出する製品リストページを作成したいと考えています。」”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "「公開ブログとメンバー限定ブログを表示するウェブサイトが必要です。」”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-hubspot/cms-developers",
          children: "CMSサンドボックスアカウント"
        }), "を作成します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI"
        }), "をインストールします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
          children: "HubSpot Visual Studio Code拡張機能"
        }), "をインストールします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "クイックスタート："
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "CMS Hubクイックスタートガイド"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms",
          children: "CMSガイド"
        }), "と", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms",
          children: "リファレンスドキュメント"
        }), "を確認します。"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}