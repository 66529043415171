"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 100018760849;
const slug = exports.slug = 'guides/cms/setup/install-and-use-hubspot-code-extension';
const title = exports.title = 'Install and use the HubSpot VS Code extension';
const name = exports.name = 'Install and use the HubSpot VS Code extension';
const metaDescription = exports.metaDescription = 'Learn how to install and use the HubSpot VS Code extension for local development.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "install-the-extension",
  "label": "Install the extension",
  "parent": null
}, {
  "depth": 0,
  "id": "manage-authenticated-hubspot-accounts",
  "label": "Manage authenticated HubSpot accounts",
  "parent": null
}, {
  "depth": 1,
  "id": "authenticate-a-new-account",
  "label": "Authenticate a new account",
  "parent": "manage-authenticated-hubspot-accounts"
}, {
  "depth": 1,
  "id": "manage-connected-accounts",
  "label": "Manage connected accounts",
  "parent": "manage-authenticated-hubspot-accounts"
}, {
  "depth": 0,
  "id": "file-management",
  "label": "File management",
  "parent": null
}, {
  "depth": 1,
  "id": "view-files",
  "label": "View files",
  "parent": "file-management"
}, {
  "depth": 1,
  "id": "upload-files",
  "label": "Upload files",
  "parent": "file-management"
}, {
  "depth": 1,
  "id": "watch-folders",
  "label": "Watch folders",
  "parent": "file-management"
}, {
  "depth": 1,
  "id": "delete-and-fetch-files",
  "label": "Delete and fetch files",
  "parent": "file-management"
}, {
  "depth": 1,
  "id": "create-new-files",
  "label": "Create new files",
  "parent": "file-management"
}, {
  "depth": 0,
  "id": "hubl-language-support",
  "label": "HubL Language support",
  "parent": null
}, {
  "depth": 1,
  "id": "supported-hubl-syntax",
  "label": "Supported HubL syntax",
  "parent": "hubl-language-support"
}, {
  "depth": 1,
  "id": "enable-emmet-for-html-%2B-hubl-files",
  "label": "Enable Emmet for HTML + HubL files",
  "parent": "hubl-language-support"
}, {
  "depth": 1,
  "id": "enable-intellisense-suggestions",
  "label": "Enable IntelliSense suggestions",
  "parent": "hubl-language-support"
}, {
  "depth": 1,
  "id": "autocomplete-reference",
  "label": "Autocomplete reference",
  "parent": "hubl-language-support"
}, {
  "depth": 1,
  "id": "other-snippets",
  "label": "Other snippets",
  "parent": "hubl-language-support"
}, {
  "depth": 0,
  "id": "enable-extension-beta-features",
  "label": "Enable extension beta features",
  "parent": null
}, {
  "depth": 0,
  "id": "telemetry",
  "label": "Telemetry",
  "parent": null
}, {
  "depth": 0,
  "id": "contribute",
  "label": "Contribute",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      br: "br",
      img: "img",
      a: "a",
      h3: "h3",
      em: "em",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Install and use the HubSpot Visual Studio Code extension"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The HubSpot Visual Studio Code extension provides a set of tools to streamline local HubSpot development. Using the extension, you can install and manage the HubSpot CLI, authenticate and manage connected accounts, as well as quickly develop using HubL with syntax highlighting, autocomplete, boilerplate content, and more."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to install and use the extension."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Install the extension"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To install the HubSpot VS Code extension:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In VS Code, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preferences"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Extensions"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the search bar, search for ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubSpot"
        }), ", then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Install"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A HubSpot panel will then be added to the left sidebar, which you can click to access HubSpot-specific actions, resources, and more.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/vs-code-hubspot-extension-installed.png",
        alt: "vs-code-hubspot-extension-installed"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Manage authenticated HubSpot accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the extension, you can manage the accounts that you want connected to the CLI, similar to using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: "authentication CLI commands"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Authenticate a new account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To authenticate a HubSpot account using the extension:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the extension panel, under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Authentication"
        }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authenticate HubSpot Account"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/authenticate-hubspot-account.png",
        alt: "authenticate-hubspot-account"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Open"
          }), " to open HubSpot in a browser window."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your browser, select the HubSpot account you want to authenticate, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Continue with this account"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If you haven't generated an access key for your account yet, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "permissions"
          }), " you need, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Generate personal access key"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Open Visual Studio Code"
          }), " to navigate back to VS Code."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You'll then be prompted to select whether this account should be set as the default account. Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Yes"
          }), " if you'd like CLI commands to interact with this account by default. You can change this at any time in the extension panel after authentication."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["To authenticate more accounts, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Authenticate additional HubSpot account"
          }), ", then repeat the above process."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/vs-code-extension-authenticate-additional-accounts.png",
            alt: "vs-code-extension-authenticate-additional-accounts"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Manage connected accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Accounts"
      }), ", the extension panel will display all currently authenticated HubSpot accounts, with the current default account marked by a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "star"
      }), " icon."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/vs-code-extension-accounts.png",
        alt: "vs-code-extension-accounts"
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "You can right-click the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "account"
      }), " to view account management options:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Open design manager:"
        }), " navigate to the account's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/design-manager",
          children: "design manager"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Show personal access key info:"
        }), " navigate to the account's personal access key page. This can be useful if you need to copy the key, see its scopes, or deactivate it."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Set as default account:"
        }), " instruct the CLI to interact with the account by default when running commands."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rename account:"
        }), " change the account's name in the CLI. This will only change the label that the CLI uses."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Delete account:"
        }), " remove the account from your list of authenticated accounts. This will not ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/how-do-i-cancel-my-hubspot-account#crm-free-only",
          children: "delete the account"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/vs-code-extension-right-click-account.png",
        alt: "vs-code-extension-right-click-account"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "File management"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Remote file system"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "(default account)"
      }), " section of the extension, you can view, upload, and manage files stored in the default account's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/overview#developer-file-system",
        children: "developer file system"
      }), ", which will reflect the files in the account's design manager. The files displayed in this section are read-only. Below, learn how to view, fetch, upload, watch, delete, and create new files."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "View files"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view the default account's files, click to expand the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Remote File System"
      }), " section in the left sidebar. You can refresh this view by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "... options icon"
      }), " and selecting ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Refresh"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/remote-file-system-refresh.png",
        alt: "remote-file-system-refresh"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Upload files"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To upload a new file or folder to the account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "... options icon"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Upload"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/remote-file-system-upload.png",
        alt: "remote-file-system-upload"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "file"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "folder"
        }), " that you want to upload to the account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "destination path"
        }), " where the file or folder will live in HubSpot, including any file extensions (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/my-theme/images/examplefile.jpg"
        }), "). You can nest files within another folder by including those folders in the path."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/remote-file-system-path-enter.png",
        alt: "remote-file-system-path-enter"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You can find the path of an existing folder in the account's design manager. To open the design manager, right-click the account in the VS Code extension, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Open design manager"
        }), ". Then, locate the folder in the left sidebar of the design manager, then right-click it and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy path"
        }), "."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-manager-copy-path%20(1).png",
          alt: "design-manager-copy-path (1)"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), " to upload the file. The file system viewer will then update after upload. If an error occurs during upload, it will display in the bottom right of VS Code."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Watch folders"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can set a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "watch"
      }), " on a folder to automatically upload changes within the folder on save. To watch a folder:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "... options icon"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Watch"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/remote-file-system-watch.png",
        alt: "remote-file-system-watch"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "folder"
        }), " that you want to watch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter the destination path where the folder will live in HubSpot, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/remote-file-system-path-enter.png",
        alt: "remote-file-system-path-enter"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["The extension will display a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "sync icon"
          }), " next to the folder in the left sidebar to indicate that it's being watched."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/remote-file-system-sync-icon.png",
            alt: "remote-file-system-sync-icon"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["To end a watch, right-click the folder being watched, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "End Watch"
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " only one file or folder can be watched at a time. To watch multiple files or folders simultaneously, you can instead watch the root directory or other parent directory."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Delete and fetch files"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To delete a file or folder from the account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Right-click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "file"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "folder"
        }), " that you want to delete, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Delete"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You'll then be prompted to confirm whether you want to delete it. Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Okay"
        }), " to confirm the deletion."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/delete-confirmation.png",
        alt: "delete-confirmation"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To download a file or folder from the account to your local environment:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Right-click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "file"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "folder"
        }), " that you want to fetch, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fetch"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You'll then be prompted to select the folder that you want to download it to."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/vs-code-extension-right-click-file.png",
        alt: "vs-code-extension-right-click-file"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create new files"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To generate scaffolded files for new CMS assets in VS Code:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Explorer icon"
          }), " to view your working directory."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/vs-code-explorer-icon.png",
            alt: "vs-code-explorer-icon"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Right-click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "File explorer panel"
          }), " and select one of the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "New"
          }), " options:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New template:"
            }), " generates a new global partial, page, partial, or section template with boilerplate content."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New module:"
            }), " generates a new module with boilerplate content."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New Serverless Function folder:"
            }), " generates a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".functions"
            }), " folder that contains boilerplate ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.js"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), " files. You can add another serverless function to the folder by right-clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "folder"
            }), ", then selecting ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "New serverless function"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/create-new-files-extension.png",
        alt: "create-new-files-extension"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubL Language support"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To enable HubL language support in your files, configure your VS Code file association settings:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In VS Code, open the command prompt by pressing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cmd"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "shift"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Search for and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preferences: Open User Settings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Choose the scope of your settings by clicking either the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "User"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workspace"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the settings search bar, search for ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "files.associations"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add item"
        }), ", then add the following item-value pairs:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "*html"
            }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html-hubl"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "*css"
            }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "css-hubl"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/files-associations.png",
        alt: "files-associations"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supported HubL syntax"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When developing with HubL, the extension offers the following ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL syntax"
      }), " support:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Inline HubL linting:"
        }), " checks your HTML, CSS, HTML + HubL, and CSS + HubL files for HubL-related errors and displays them inline."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubL syntax highlighting:"
        }), " supports HubL syntax highlighting in the files associated through your VS Code language mode settings. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-hubl-file-associations",
          children: "set your file associations"
        }), " below."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Statement wrapping:"
        }), " supports ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% %}"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{# #}"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ undefined.undefined }}"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/overview#types-of-delimiters",
          children: "delimiters"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Block comment toggling:"
        }), " create HubL comments by pressing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cmd"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Autocomplete:"
        }), " offers autocomplete suggestions for supported HubL tags, filters, expression tests, and functions. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#autocomplete-reference",
          children: "Learn more about autocomplete"
        }), " below."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Emmet"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "for HTML + HubL files:"
        }), " allows ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://code.visualstudio.com/docs/editor/emmet",
          children: "Emmet"
        }), " abbreviation and snippet expansions for HTML + HubL files. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enable-emmet-for-html-hubl-files",
          children: "enable Emmet support"
        }), " below."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "IntelliSense suggestions:"
        }), " enables ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://code.visualstudio.com/docs/editor/intellisense#:~:text=You%20can%20trigger%20IntelliSense%20in,name%20to%20limit%20the%20suggestions.",
          children: "IntelliSense"
        }), " suggestions when in snippet placeholders. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enable-intellisense-suggestions",
          children: "enable IntelliSense Suggestions"
        }), " below."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Enable Emmet for HTML + HubL files"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To enable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://code.visualstudio.com/docs/editor/emmet",
        children: "Emmet"
      }), " support for abbreviations and snippet expansion in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "html-hubl"
      }), " files, map ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "html-hubl"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "html"
      }), " in your Emmet settings:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In VS Code, open the command prompt by pressing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cmd"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "shift"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Search for and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preferences: Open User Settings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Choose the scope of your settings by clicking either the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "User"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workspace"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the settings search bar, search for ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Emmet: Include Languages"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add item"
        }), ", then add the following item-value pair: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "html-hubl"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "html"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/files-associations.png",
        alt: "files-associations"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Enable IntelliSense suggestions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To enable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://code.visualstudio.com/docs/editor/intellisense#:~:text=You%20can%20trigger%20IntelliSense%20in,name%20to%20limit%20the%20suggestions.",
        children: "IntelliSense"
      }), " suggestions when in snippet placeholders:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In VS Code, open the command prompt by pressing ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "cmd"
          }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "shift"
          }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "p"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Search for and select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Preferences: Open User Settings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Choose the scope of your settings by clicking either the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "User"
          }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Workspace"
          }), " tab."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the settings search bar, search for ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Editor"
          }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Suggest: Snippets Prevent Quick Suggestions"
          }), " and ensure that the checkbox is ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "cleared"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/intellisense-quick-suggestion.png",
            alt: "intellisense-quick-suggestion"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Then, in the settings search bar, search for ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Editor Parameter Hints Enabled"
          }), " and ensure that the checkbox is ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "selected"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/intellisense-hints-enabled.png",
            alt: "intellisense-hints-enabled"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Autocomplete reference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The HubSpot extension enables quick access to snippets that map to support HubL tags, filters, expression tests, and functions. To access autocomplete suggestions, you'll need to use the following prefixes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/operators-and-expression-tests",
          children: "Expression tests"
        }), ": type the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "test name"
        }), " alone, then pres ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ". For example, typing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "div"
        }), " produces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "divisibleby"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/filters",
          children: "Filters"
        }), ": type ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|"
        }), " followed by the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "filter"
        }), ", then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ". For example, typing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|se"
        }), " produces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|selectattr('attr', exp_test)"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions",
          children: "Functions"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags",
          children: "tags"
        }), ": type ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "~"
        }), " followed by the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "function"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tag"
        }), ", then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ". For example, typing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "~hub"
        }), " produces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_rows(${table_id}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/variables",
          children: "HubL supported variables"
        }), ": type the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "variable name"
        }), " alone, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ". For example, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content.ab"
        }), " produces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ content.absolute_url"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "}}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Module fields"
        }), ": in JSON files, type the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "field type"
        }), ", then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ". For example: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ri"
        }), " produces:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// typing ri > Enter produces:\n{\n  \"name\": \"richtext_field\",\n  \"label\": \"Rich text field\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"richtext\",\n  \"inline_help_text\": \"\",\n  \"help_text\": \"\",\n  \"default\": null\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Other snippets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Other snippets you can generate with the VS Code extension include:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Snippet prefix"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "otrue"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Generates ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "overrideable=True"
            }), " for HubL tags."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overrideable=True"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ofalse"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Generates ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "overrideable=False"
            }), " for ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/tags/standard-tags",
              children: "HubL tags"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overrideable=False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "for"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Returns a basic ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/loops",
              children: "for loop"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "&#123;% for iterable in dict %&#125;``&#123;&#123; iterable &#125;&#125;``&#123;% endfor %&#125;"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "if"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Returns a basic ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/if-statements",
              children: "if statement"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "&#123;% if &#123;condition&#125; %&#125; do_something &#123;% endif %&#125;"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "elif"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Returns an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/if-statements#using-elif-and-else",
              children: "else if"
            }), " statement to be used within an if statement."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "&#123;% elif &#123;condition&#125; %&#125;"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "else"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Returns an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/if-statements#using-elif-and-else",
              children: "else"
            }), " statement to be used within an if statement."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "&#123;% else %&#125;"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubldoc"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Returns a boilerplate HTML + HubL document."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hublblog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Returns boilerplate blog markup."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The extension also offers autocomplete for file paths when used with the HubL tags and parameters below. To access file path autocompletions, start typing the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubL tag"
      }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "parameter"
      }), " followed by a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "quotation mark"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% include \"... %}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% import \"... %}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% tag_name path=\"... %}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% extend \"... %}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/autocomplete-path.png",
        alt: "autocomplete-path"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " autocomplete will not pull in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "HubSpot default module"
        }), " file paths."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Enable extension beta features"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To enable HubSpot VS Code extension beta features:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In VS Code, open the command prompt by pressing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cmd"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "shift"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Search for and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preferences: Open User Settings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Choose the scope of your settings by clicking either the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "User"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workspace"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the settings search bar, search for ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubSpot: Beta"
        }), " and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "checkbox"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/enable-beta-features.png",
        alt: "enable-beta-features"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Telemetry"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot for VS Code collects user data in order to improve the extension’s experience. You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/privacy-policy",
        children: "review HubSpot’s privacy policy here"
      }), ". Additionally, you may opt out of data collection by changing the setting for global telemetry in VS Code. To read more about VS Code and telemetry, including disabling telemetry reporting, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://code.visualstudio.com/docs/getstarted/telemetry",
        children: "please read the official VS Code documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contribute"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This extension is open source and HubSpot welcomes contributions as well as issues for feature requests and bug reports. For more information about contributing, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/blob/master/CONTRIBUTING.md",
        children: "contributing docs"
      }), " to get started."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}