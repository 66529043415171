"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77681100075;
const slug = exports.slug = 'guides/cms/setup/js-frameworks';
const title = exports.title = 'Utiliser des infrastructures JavaScript sur HubSpot';
const name = exports.name = 'EMEA (FR) How to use JavaScript frameworks on HubSpot_FR';
const metaDescription = exports.metaDescription = "À l'aide de CMS Hub, vous pouvez créer des applications web avancées basées sur JavaScript. Des boilerplates sont disponibles pour faciliter la mise en service. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "quel-niveau-de-cms-hub-est-n%C3%A9cessaire-%3F",
  "label": "Quel niveau de CMS Hub est nécessaire ?",
  "parent": null
}, {
  "depth": 0,
  "id": "infrastructures-et-biblioth%C3%A8ques",
  "label": "Infrastructures et bibliothèques",
  "parent": null
}, {
  "depth": 0,
  "id": "ce-que-vous-devez-savoir",
  "label": "Ce que vous devez savoir",
  "parent": null
}, {
  "depth": 1,
  "id": "points-%C3%A0-prendre-en-compte-lors-de-la-cr%C3%A9ation",
  "label": "Points à prendre en compte lors de la création",
  "parent": "ce-que-vous-devez-savoir"
}, {
  "depth": 2,
  "id": "rendu-c%C3%B4t%C3%A9-serveur-et-rendu-c%C3%B4t%C3%A9-client",
  "label": "Rendu côté serveur et rendu côté client",
  "parent": "ce-que-vous-devez-savoir"
}, {
  "depth": 2,
  "id": "analyses-d-application-monopage",
  "label": "Analyses d'application monopage",
  "parent": "ce-que-vous-devez-savoir"
}, {
  "depth": 2,
  "id": "cr%C3%A9er-votre-application-%C3%A0-l-aide-des-modules-hubspot",
  "label": "Créer votre application à l'aide des modules HubSpot",
  "parent": "ce-que-vous-devez-savoir"
}, {
  "depth": 2,
  "id": "cr%C3%A9er-un-mod%C3%A8le-complet",
  "label": "Créer un modèle complet",
  "parent": "ce-que-vous-devez-savoir"
}, {
  "depth": 2,
  "id": "d%C3%A9limiteurs",
  "label": "Délimiteurs",
  "parent": "ce-que-vous-devez-savoir"
}, {
  "depth": 0,
  "id": "vuejs",
  "label": "VueJS",
  "parent": null
}, {
  "depth": 1,
  "id": "sans-%C3%A9tape-de-construction",
  "label": "Sans étape de construction",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "ajouter-la-biblioth%C3%A8que-vue-%C3%A0-votre-module",
  "label": "Ajouter la bibliothèque Vue à votre module",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "ajouter-le-code-html",
  "label": "Ajouter le code HTML",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "ajouter-votre-code-javascript",
  "label": "Ajouter votre code JavaScript",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "avec-une-%C3%A9tape-de-construction",
  "label": "Avec une étape de construction",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "utilisation-des-formulaires-et-des-cta-hubspot-dans-les-composants-vue",
  "label": "Utilisation des formulaires et des CTA HubSpot dans les composants Vue",
  "parent": "vuejs"
}, {
  "depth": 0,
  "id": "reactjs",
  "label": "ReactJS",
  "parent": null
}, {
  "depth": 0,
  "id": "autres-biblioth%C3%A8ques-javascript",
  "label": "Autres bibliothèques JavaScript",
  "parent": null
}, {
  "depth": 1,
  "id": "utiliser-require_js-au-lieu-de-balises-de-script",
  "label": "Utiliser require_js au lieu de balises de script",
  "parent": "autres-biblioth%C3%A8ques-javascript"
}, {
  "depth": 0,
  "id": "tutoriels-et-guides-recommand%C3%A9s",
  "label": "Tutoriels et guides recommandés",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      em: "em",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Comment utiliser les infrastructures et les bibliothèques JavaScript sur HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "À l'aide du CMS Hub, vous pouvez créer des applications web basées sur JavaScript."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quel niveau de CMS Hub est nécessaire ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si votre site web nécessite du code côté serveur ou un mécanisme d'abonnement au contenu, vous pouvez profiter des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "fonctions sans serveur"
      }), " de HubSpot et de la fonctionnalité d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "abonnement au contenu"
      }), " si vous avez un abonnement ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), ". Cependant, vous pouvez également créer votre propre système à l'aide de fournisseurs tiers tels qu'AWS Lambda combinés à une passerelle API pour exécuter du code côté serveur."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous créez une application web qui doit atteindre les points de terminaison d'API nécessitant une authentification telle qu'un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "jeton d'accès d'application privée"
      }), ", vous ne devez pas exécuter ce code dans le navigateur. Vous exposerez vos informations d'identification à toute personne qui consulte la page. La bonne approche consiste à créer une couche d'abstraction entre le navigateur et l'API authentifiée : un point de terminaison d'API personnalisé qui ne nécessite pas d'exposer vos identifiants et qui est servi à partir du même domaine que le site web qui l'appelle."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'activation du point de terminaison d'API personnalisé exécutera le code côté serveur qui peut effectuer la demande authentifiée. Ensuite, vous pouvez effectuer n'importe quel formatage des données ou de la logique commerciale que vous souhaitez garder secrète, et envoyer le résultat au navigateur."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Habituellement, les fonctions sans serveur sont utilisées pour le faire car elles offrent une grande évolutivité et ne nécessitent pas de gérer et de maintenir votre propre serveur. Vous pouvez utiliser des fournisseurs comme AWS Lambda en association avec une passerelle d'API ou les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "fonctions sans serveur"
      }), " prioritaires de HubSpot. L'avantage des fonctions sans serveur HubSpot est que vous n'avez pas besoin de gérer plusieurs services distincts. L'expérience est simplifiée et directement intégrée au même système de fichiers de développeur dans lequel se trouvent les thèmes, les modèles et les modules."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous n'avez pas besoin d'effectuer des appels d'API authentifiés, vous n'avez pas besoin de CMS Hub Entreprise pour votre application. React et Vue sont des infrastructures front-end qui n'ont pas besoin de fonctions sans serveur pour fonctionner. Ce que vous en faites est le plus important."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Infrastructures et bibliothèques"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour les applications web, les développeurs utilisent généralement des infrastructures JavaScript qui permettent de gérer le statut et l'interface utilisateur."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub n'a pas été spécialement conçu pour fonctionner avec une infrastructure spécifique, mais de nombreuses infrastructures JavaScript courantes fonctionnent sur CMS Hub. En vous appuyant sur HubSpot, vous devrez peut-être réfléchir différemment à la façon dont vous travaillez avec ces infrastructures. Toutefois, les principaux aspects nécessaires pour travailler avec ces infrastructures sont disponibles : la possibilité de rédiger des", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modèles personnalisés"
      }), ", des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), " et du code JavaScript. Nous vous permettons également d'effectuer votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "codage localement"
      }), " afin que vous puissiez utiliser une étape de construction."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ce que vous devez savoir"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nous collaborons avec notre communauté de développeurs dans le but d'établir des bonnes pratiques de création avec des infrastructures JavaScript courantes sur HubSpot. Bien qu'il soit possible de le faire, certains aspects du fonctionnement de CMS Hub peuvent vous obliger à configurer volontairement votre projet différemment de ce que vous pourriez sur une simple page HTML."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Certains aspects de votre workflow de développement peuvent également différer de ce à quoi vous êtes habitué. Nous vous demandons de nous faire part de vos commentaires afin que nous puissions améliorer l'expérience pour tous les développeurs. Pour ce faire, le meilleur endroit actuellement est notre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "forum des développeurs"
      }), ". À mesure que nous expérimenterons et apprendrons, nous continuerons à mettre à jour notre documentation en conséquence."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Points à prendre en compte lors de la création"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub dispose d'un puissant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "système de modules"
      }), ", qui vous permet de créer des éléments CSS, JavaScript et HTML réutilisables avec accès à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL, le langage de modélisation HubSpot"
      }), ". Les modules HubSpot vous permettent d'offrir beaucoup de contrôle et de puissance aux créateurs de contenu. Les infrastructures JavaScript modernes disposent souvent de leurs propres systèmes de modules. Ces systèmes sont tous créés indépendamment les uns des autres et, par conséquent, ont souvent des solutions différentes pour les problèmes que vous pourriez rencontrer."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Rendu côté serveur et rendu côté client"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le rendu côté serveur consiste à générer le code HTML pour une page à partir de la logique de modélisation sur le serveur avant d'envoyer un code HTML à un navigateur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le rendu côté client se fait lorsqu'une version plus légère ou « incomplète » du code HTML est envoyée depuis le serveur et que JavaScript est utilisé pour générer le code HTML. Cela transfère le traitement de la logique du serveur au navigateur web (le client)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'hydratation consiste à combiner les deux techniques. Tout d'abord, sur le serveur, autant de HTML que possible est généré. Ensuite, JavaScript évalue le code HTML fourni et y apporte des modifications plus légères lorsque l'utilisateur interagit avec la page ou les données reçues. Cela réduit la charge sur le client et potentiellement le temps nécessaire à l'utilisateur pour voir le contenu chargé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sur CMS Hub, le code HubL est traité côté serveur, puis mis en cache au niveau du réseau de diffusion de contenu. Vous pouvez ensuite utiliser JavaScript pour hydrater ou rendre côté client le code HTML du navigateur au visiteur du site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Analyses d'application monopage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'analytique est importante pour la capacité de croissance de votre entreprise et son adaptation afin de résoudre les problèmes de vos clients et prospects. Lorsque vous créez une application monopage qui contient plusieurs vues, vous pouvez suivre le visiteur qui consulte différentes vues, sous forme de pages."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La plupart des plateformes d'analyse permettent de le faire avec JavaScript et HubSpot n'échappe pas à la règle. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code#tracking-in-single-page-apps",
        children: "Transférez la vue de page"
      }), " lorsque votre application change de vue."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Créer votre application à l'aide des modules HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le système de modules de HubSpot est un système côté serveur, générant un document HTML à partir de partials HubL + HTML et générant des CSS et du code JavaScript minifiés pour chaque module dans une page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous créez en utilisant les modules HubSpot, vous profiterez de différents avantages :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les créateurs de contenu peuvent ajouter votre module à des pages qui présentent des zones de glisser-déposer ou des colonnes flexibles. Ils peuvent également déplacer et retirer le module eux-mêmes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous pouvez fournir des champs au créateur de contenu qui lui permettront de configurer les paramètres de votre application."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Votre code n'est restitué sur la page que si le module est réellement utilisé."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Module.css"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.js"
        }), " sont automatiquement minifiés."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Toutefois, le système de modules HubSpot nécessite que les modules soient composés de fichiers spécifiques et à des endroits différents de ceux où vous pourriez normalement placer votre code."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Créer un modèle complet"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez également créer votre application en tant que modèle plutôt que dans le cadre du module. Cela vous offre davantage de flexibilité avec la structure de votre fichier. Cependant, vous ne bénéficierez pas des avantages que les modules fournissent ; les créateurs de contenu ne seront pas en mesure d'ajouter cette application aux pages dans les zones de glisser-déposer et les colonnes flexibles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Délimiteurs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Certaines infrastructures JavaScript utilisent des accolades ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ }"
      }), " pour délimiter leur code. Le langage HubL utilise également ces caractères. Trois méthodes peuvent être utilisées pour vous assurer que vous n'avez pas de conflits entre votre infrastructure et HubL : vous pouvez utiliser la balise HubL brute pour envelopper votre JSX, définir le cadre pour utiliser un délimiteur différent ou utiliser une étape de construction qui compile le JavaScript au préalable."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "VueJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'infrastructure populaire ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/",
        children: "Vue.js"
      }), " peut être utilisée avec ou sans étape de construction. Consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/installation.html",
        children: "documentation de Vue"
      }), " pour une présentation plus détaillée des avantages et des inconvénients de chaque méthode. Concernant HubSpot, il est important de prendre en compte des avantages et des inconvénients spécifiques."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sans étape de construction"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'intégration de Vue.js sans étape de construction dans un module est facile."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ajouter la bibliothèque Vue à votre module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ", utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " pour ajouter la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/installation.html#Direct-lt-script-gt-Include",
        children: "bibliothèque Vue"
      }), " en vous assurant qu'elle ne sera chargée qu'une seule fois lors de l'ajout de votre module à une page."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Lors du développement, utilisez la version de développement pour obtenir des informations utiles pour le débogage. Une fois en production, il est recommandé d'utiliser soit l'URL du réseau de diffusion de contenu pour la version Vue spécifique, soit de télécharger ce fichier et de l'héberger en tant que fichier JavaScript dans le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview#developer-file-system",
          children: "système de fichiers de développeur"
        }), " HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ajouter le code HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Copiez le code HTML de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/index.html#Declarative-Rendering",
        children: "introduction Vue.js"
      }), " et collez-le dans votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ". Encadrez ce code dans une balise brute HubL pour l'empêcher d'être considéré comme du HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# raw prevents code within it from being evaluated as HubL #}\n\n<div id=\"app\">\n  {{ message }}\n</div>\n\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ajouter votre code JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Copiez le code JavaScript de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/index.html#Declarative-Rendering",
        children: "introduction Vue.js"
      }), " et collez-le dans votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), ". Encadrez ce code dans un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/API/Window/DOMContentLoaded_event",
        children: "écouteur d'événement pour vérifier qu'il est exécuté une fois le chargement du contenu DOM terminé."
      }), " Publiez votre module et prévisualisez-le. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Votre application Vue de base devrait maintenant fonctionner."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var app = new Vue({\n  el: '#app',\n  data: {\n    message: 'Hello Vue!',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Avec une étape de construction"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nous avons créé un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate",
        children: "boilerplate"
      }), " (bêta) pour vous aider à bien démarrer avec les modules HubSpot dans le cadre de la création d'une application VueJS. La façon la plus simple d'en tirer parti consiste à exécuter la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create vue-app"
      }), " à partir de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "ILC de CMS"
      }), ". Des instructions sont disponibles dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/",
        children: "référentiel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ce boilerplate est nouveau et nous serions ravis d'avoir votre retour ! Faites-nous savoir ce qui pourrait être amélioré et tous les problèmes que vous rencontrez. La meilleure façon de fournir des commentaires consiste à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/issues",
        children: "soumettre les problèmes dans le référentiel GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utilisation des formulaires et des CTA HubSpot dans les composants Vue"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les CTA et les formulaires HubSpot ont leurs propres balises de script et gèrent eux-mêmes leur propre code HTML. Pour vous assurer que votre composant Vue ne modifie pas le formulaire ou le CTA, créez un élément HTML autour du code d'intégration de CTA/formulaire. ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://vuejs.org/v2/api/#v-once",
        children: ["Appliquez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v-once"
        }), " à cet élément."]
      }), " Cela garantit que le code sera rendu une fois, puis ignoré par votre composant Vue."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ReactJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Plutôt que d'utiliser HubL pour créer des modules et des partials, vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
        children: "JavaScript et React"
      }), ". En plus d'assembler les composants React rendus par le serveur dans le code HTML généré par HubL, les modules et partials JavaScript prennent en charge l'interactivité côté serveur et côté client. Pour en savoir plus, consultez l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "introduction aux éléments constitutifs JS de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également consulter le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "boilerplate React"
      }), " pour bien démarrer avec une application ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://reactjs.org/",
        children: "React"
      }), " dans un module HubSpot. La façon la plus simple d'en tirer parti consiste à exécuter la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create react-app"
      }), " à partir de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "ILC de CMS"
      }), ". Puis, suivez les instructions dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "référentiel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ce boilerplate est nouveau et nous serions ravis d'avoir votre retour ! Faites-nous savoir ce qui pourrait être amélioré et tous les problèmes que vous rencontrez. La meilleure façon de fournir des commentaires consiste à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate/issues",
        children: "soumettre les problèmes dans le référentiel GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autres bibliothèques JavaScript"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe beaucoup de bibliothèques JavaScript et il nous est impossible de les documenter toutes individuellement. Des bonnes pratiques de base sont à connaître lorsque vous utilisez des bibliothèques JavaScript sur HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utiliser require_js au lieu de balises de script"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez avoir des dizaines de modules et de modèles qui utilisent des bibliothèques JavaScript partagées. Ne vous inquiétez pas de charger ces bibliothèques plusieurs fois. Pour ce faire, vous devez utiliser la fonction HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), ". Les scripts chargés à l'aide de cette fonction ne seront chargés qu'une fois par page, indépendamment du nombre de modules ou de partials et du modèle qui les nécessite."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{{ require_js(get_asset_url('/js/jquery-latest.js')) }}\n\n{{ require_js(\"https://cdnjs.cloudflare.com/ajax/libs/d3/6.2.0/d3.min.js\") }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get_asset_url()"
        }), " pour demander des fichiers stockés dans le système de fichiers de développeur. Outre la co-implantation de vos fichiers de développement et la consolidation de la sécurité de ces fichiers, vous profiterez d'un nombre réduit de recherches DNS."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cette utilisation peut dynamiser vos performances, car vous ne chargerez le fichier qu'une seule fois. Si des éléments d'une page n'ont pas besoin de cette bibliothèque, elle ne sera pas chargée du tout. Vous pouvez même utiliser cela avec la logique HubL pour charger des ressources uniquement si vous en avez vraiment besoin."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriels et guides recommandés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Optimisation pour les performances"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "L'accessibilité n'est pas une fonctionnalité"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "Comment utiliser les composants web sur HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Premiers pas avec les modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "Premiers pas avec les fonctions sans serveur"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Créer un workflow de développement efficace"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "Création de pages dynamiques avec HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
          children: "Créer des modules et des partials avec JavaScript"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}