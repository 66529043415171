"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044629;
const slug = exports.slug = 'guides/cms/content/multi-language-content';
const title = exports.title = 'Conteúdo em vários idiomas';
const name = exports.name = 'PT-BR Multi-language';
const metaDescription = exports.metaDescription = 'Os desenvolvedores que criam sites no HubSpot podem dar suporte facilmente a um site em vários idiomas. Algum esforço de desenvolvimento pode ser necessário com base em seu negócio específico.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "funcionalidades-padr%C3%A3o-de-v%C3%A1rios-idiomas-do-hubspot",
  "label": "Funcionalidades padrão de vários idiomas do HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "o-que-o-hubspot-n%C3%A3o-faz",
  "label": "O que o HubSpot não faz",
  "parent": "funcionalidades-padr%C3%A3o-de-v%C3%A1rios-idiomas-do-hubspot"
}, {
  "depth": 0,
  "id": "definir-vari%C3%A1veis-de-idioma",
  "label": "Definir variáveis de idioma",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-m%C3%B3dulos-edit%C3%A1veis-em-n%C3%ADvel-de-p%C3%A1gina",
  "label": "Usar módulos editáveis em nível de página",
  "parent": null
}, {
  "depth": 0,
  "id": "incluir-tradu%C3%A7%C3%B5es-de-campos-em-m%C3%B3dulos-e-temas-personalizados",
  "label": "Incluir traduções de campos em módulos e temas personalizados",
  "parent": null
}, {
  "depth": 1,
  "id": "desenvolvimento-local",
  "label": "Desenvolvimento local",
  "parent": "incluir-tradu%C3%A7%C3%B5es-de-campos-em-m%C3%B3dulos-e-temas-personalizados"
}, {
  "depth": 1,
  "id": "gerente-de-design",
  "label": "Gerente de design",
  "parent": "incluir-tradu%C3%A7%C3%B5es-de-campos-em-m%C3%B3dulos-e-temas-personalizados"
}, {
  "depth": 0,
  "id": "traduzir-p%C3%A1ginas-do-sistema",
  "label": "Traduzir páginas do sistema",
  "parent": null
}, {
  "depth": 0,
  "id": "incluir-um-alternador-de-idioma",
  "label": "Incluir um alternador de idioma",
  "parent": null
}, {
  "depth": 0,
  "id": "implementar-pesquisa-em-sites-com-v%C3%A1rios-idiomas",
  "label": "Implementar pesquisa em sites com vários idiomas",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-parciais-e-m%C3%B3dulos-globais",
  "label": "Usar parciais e módulos globais",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      h3: "h3",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Conteúdo em vários idiomas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Qualquer empresa que faça negócios entre regiões ou com uma base de clientes que fale vários idiomas precisa ser capaz de se conectar com seu público no idioma dele. Com o HubSpot CMS, os usuários podem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cos-general/how-to-manage-multi-language-content-with-hubspots-cos",
        children: "criar variações de conteúdo em vários idiomas"
      }), ", permitindo que o usuário final visualize o conteúdo no idioma com o qual se sente mais confortável."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A HubSpot define um número de facetas de um site em vários idiomas para você automaticamente, mas também há um número de etapas que os desenvolvedores devem tomar para garantir que o site esteja pronto para vários idiomas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Funcionalidades padrão de vários idiomas do HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sempre que uma variante de idioma for criada para uma página no HubSpot, nós automaticamente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Criamos uma nova entrada no mapa do site XML indicando o nome e a URL da página traduzida."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Especificamos o idioma do conteúdo no ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "<head>"
          }), " da página para modelos criados usando a funcionalidade de arrastar e soltar."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Identificamos outras páginas dentro do grupo de conteúdo em vários idiomas seguindo o formato padrão apropriado, que marca as outras páginas como alternativas para evitar erros de conteúdo duplicado e também identifica o código ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes",
            children: "ISO 639-1"
          }), " associado à(s) tradução(ões):"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "<link rel=\"alternate\" hreflang=\"[**_lang_code_**](https://support.google.com/webmasters/answer/189077#language-codes)\" href=\"_url_of_page_\" />"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Reescrevemos os links nas páginas do idioma para navegar para versões intra-idioma da página associada. Isso ajudar os visitantes a permanecerem no idioma e evitar a necessidade de atualizar cada link em cada tradução de página. Para um determinado elemento, você pode desativar essa reescrita adicionando a classe \"hs-skip-lang-url-rewrite\" ao elemento."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "O que o HubSpot não faz"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com o HubSpot CMS, o HubSpot não:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "traduz automaticamente o conteúdo da página para você."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "direciona automaticamente os usuários para uma variação de idioma com base no GeoIP."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "inclui automaticamente um módulo de troca de idioma no cabeçalho ou no site."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "especifica automaticamente o idioma de uma página para arquivos codificados."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "define automaticamente o atributo de direção do conteúdo para traduções que usam um idioma que lê da direita para a esquerda em vez de da esquerda para a direita para arquivos codificados."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Definir variáveis de idioma"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como os arquivos codificados não incluem automaticamente declarações de idioma ou atributos de direção do idioma, isso precisará ser configurado manualmente para os modelos codificados. As variáveis de idioma podem ser definidas em HTML ou preenchidas via HubL, como no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L2",
        children: "modelo de Boilerplate do CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Definir essas propriedades usando o HubL permitirá que esses dados sejam preenchidos dinamicamente no HTML de uma página com base no conjunto de idiomas da página no HubSpot CMS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar módulos editáveis em nível de página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para garantir que o conteúdo possa ser localizado em cada instância de uso de um modelo, sempre que possível, utilize ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos personalizados"
      }), " em vez de conteúdo HTML codificado. A criação de módulos que podem ser editados em nível de página permitirá que os criadores de conteúdo definam o conteúdo específico que deve aparecer em cada página sem ter que ajustar o código do modelo. Também permitirá o uso de conteúdo exclusivo em páginas que compartilham um modelo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Incluir traduções de campos em módulos e temas personalizados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para apoiar sua equipe global, você pode publicar traduções dos módulos que você criou no HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de traduzir o conteúdo do módulo e publicá-lo nos idiomas dos membros da equipe, os usuários verão os rótulos de campo para esse módulo no idioma padrão de suas contas. O conteúdo em um módulo traduzido não será traduzido automaticamente; você precisará fazer isso sozinho. Você pode criar traduções do seu módulo em qualquer idioma compatível."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode definir traduções usando as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#local-module-file-structure",
        children: "ferramentas de desenvolvimento local"
      }), " ou por meio do Gerenciador de design."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Desenvolvimento local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para definir traduções usando as ferramentas de desenvolvimento local, cada pasta de módulo e cada pasta de tema pode conter uma pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_locales"
      }), " com subpastas do idioma local, cada uma com um arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "messages.json"
      }), " contendo as traduções dos campos do módulo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/local%20module%20translations.png",
        alt: "Captura de tela da edição das traduções do módulo localmente no código VS"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gerente de design"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para definir as traduções de campos por meio do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Gerenciador de design"
      }), ", depois de exibir o módulo, acesse a opção \"Adicionar traduções\" no lado direito da tela. Selecione os idiomas com os quais sua equipe trabalha no menu suspenso. A partir daí, você poderá selecionar cada idioma e especificar as convenções de rotulagem para cada campo em cada idioma."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/field_translations.gif",
        alt: "Captura de tela das traduções de campos no gerenciador de design"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["As traduções dos campos dos temas não têm uma interface no gerenciador de design e precisam ser editadas nos arquivos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".json"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Traduzir páginas do sistema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para configurar traduções para as páginas do sistema, incluindo páginas de redefinição de senha e assinatura de e-mail, você pode personalizar os campos de tag do HubL e do módulo com seu conteúdo traduzido. Saiba mais sobre os campos disponíveis para os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "módulos"
      }), " e as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#system-page-tags",
        children: "tags HubL de páginas do sistema"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Incluir um alternador de idioma"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para permitir que os usuários finais alternem entre as traduções disponíveis, é aconselhável que um módulo de troca de idioma seja adicionado ao seu site."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/09ad0b2c3bbc8400e64c5617268cb0504392e8e5/src/templates/partials/header.html#L26-L47",
        children: "exemplo de como implementar um botão de troca de idioma"
      }), " pode ser encontrado no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "Boilerplate de temas do CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Header navigation row one #}\n      <div class=\"header__row-1\">\n        {% if content.translated_content.values()|selectattr('published')|length || is_listing_view && group.translations %}\n          <div class=\"header__language-switcher header--element\">\n            <div class=\"header__language-switcher--label\">\n              {% module 'language-switcher' path='@hubspot/language_switcher',\n                label='Language switcher',\n                display_mode='localized'\n              %}\n              <div class=\"header__language-switcher--label-current\"> {{ locale_name(locale) }}</div>\n            </div>\n          </div>\n        {% endif %}\n        <div class=\"header__search header--element\">\n          {% module 'site_search' path='@hubspot/search_input',\n            label='Search',\n            field_label='Search',\n            placeholder=''\n          %}\n        </div>\n      </div>\n      {# End header navigation row one #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implementar pesquisa em sites com vários idiomas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "Pesquisa de conteúdo"
      }), " permite consultar conteúdo nos diferentes idiomas do seu site. O parâmetro de filtro de idioma pode ser usado ao aplicar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "/contentsearch/v2/search"
      }), " para retornar apenas os idiomas especificados, o que permite criar experiências de pesquisa para cada idioma ou permitir que os visitantes pesquisem em vários idiomas em seu site."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar parciais e módulos globais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use campos de módulo para tornar o texto editável nos cabeçalhos, rodapés e barras laterais. Coloque esses módulos em parciais globais. Os criadores de conteúdo se beneficiarão da facilidade de edição e as parciais globais também permitem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cos-general/how-to-manage-multi-language-content-with-hubspots-cos#edit-global-content-in-a-multi-language-page",
        children: "definir configurações para cada idioma"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/edit-multilanguage-global-content.png",
        alt: "Captura de tela do editor de página mostrando o cabeçalho parcial"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}