"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694158;
const slug = exports.slug = 'guides/cms/content/templates/types/html-hubl-templates';
const title = exports.title = 'Modelos HTML + HubL';
const name = exports.name = 'Modelos HTML + HubL';
const metaDescription = exports.metaDescription = 'Os modelos do HubSpot CMS são escritos em HTML e HubL. Esses modelos são capazes de conter regiões amigáveis para que os criadores de conteúdo possam editar por conta própria.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image1-2.png';
const featuredImageAltText = exports.featuredImageAltText = 'Captura de tela de um modelo básico de HTML + HubL';
const toc = exports.toc = [{
  "depth": 0,
  "id": "familiaridade-e-ferramentas",
  "label": "Familiaridade e ferramentas",
  "parent": null
}, {
  "depth": 0,
  "id": "anota%C3%A7%C3%B5es-do-modelo",
  "label": "Anotações do modelo",
  "parent": null
}, {
  "depth": 0,
  "id": "inclus%C3%B5es-em-cabe%C3%A7alho-e-rodap%C3%A9",
  "label": "Inclusões em cabeçalho e rodapé",
  "parent": null
}, {
  "depth": 0,
  "id": "parciais",
  "label": "Parciais",
  "parent": null
}, {
  "depth": 1,
  "id": "parciais-padr%C3%A3o",
  "label": "Parciais padrão",
  "parent": "parciais"
}, {
  "depth": 1,
  "id": "parciais-globais",
  "label": "Parciais globais",
  "parent": "parciais"
}, {
  "depth": 1,
  "id": "blocos-e-extens%C3%B5es",
  "label": "Blocos e extensões",
  "parent": "parciais"
}, {
  "depth": 1,
  "id": "grupos-globais",
  "label": "Grupos globais",
  "parent": "parciais"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Modelos HTML + HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Os modelos HTML + HubL podem ser usados para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/templates/overview#template-types",
            children: "cada tipo de modelo"
          }), " no HubSpot CMS. Esses modelos são arquivos .html que suportam a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/cms/hubl/overview",
            children: "linguagem de modelos HubL"
          }), ". Como esses modelos codificados suportam HubL, a melhor experiência de visualização é usar a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/review-your-hubspot-template-setup",
            children: "visualização do modelo no Gerenciador de design "
          }), "ou visualizar as páginas em uma conta de ambiente de testes. Os modelos HTML + HubL podem conter ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#partials",
            children: "parciais"
          }), ", que podem ser usadas para separar fragmentos de código comumente usados, tais como um cabeçalho ou rodapé."]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844649367"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos HTML + HubL oferecem maior controle aos desenvolvedores do que os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "modelos de arrastar e soltar do gerenciador de design visual"
      }), ". Os desenvolvedores, por sua vez, podem fornecer melhores experiências para os criadores de conteúdo por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "funcionalidade de arrastar e soltar"
      }), " — que só é possível com os modelos HTML + HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-2.png",
        alt: "image1-2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["O modelo acima é o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
          children: "modelo base.html"
        }), ", incluído no ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "boilerplate do HubSpot CMS"
        }), ", que é uma ótima maneira de começar a desenvolver com o HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Familiaridade e ferramentas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como os modelos HTML + HubL são arquivos codificados, você pode usar suas ferramentas preferidas para editá-los localmente. É recomendado usar suas próprias ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "ferramentas de desenvolvimento local"
      }), " da HubSpot para que você possa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "fazer upload"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "buscar"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "monitorar"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "criar"
      }), " e gerenciar com segurança arquivos no sistema de arquivos do desenvolvedor, bem como no gerenciador de arquivos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Criar modelos HTML + HubL com o HubSpot é como usar outra linguagem de modelos que você pode ter usado antes. A principal diferença é que a HubSpot determina as melhores maneiras para oferecer a melhor experiência para os criadores de conteúdo, além de reduzir muito do trabalho de manutenção e otimização de desempenho por parte dos desenvolvedores."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, se você quiser carregar o arquivo CSS em uma página para determinados módulos, em vez de usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<link rel=\"stylesheet\" type=\"text/css\" href=\"theme.css\">"
      }), ", você deve incluir a folha de estilo por meio de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), " no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
        children: "arquivo meta.json do módulo"
      }), ". Isso permite que o HubSpot carregue condicionalmente o CSS somente quando o módulo estiver presente em uma página, minimizando a quantidade de CSS desnecessário carregado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "otimizar o fluxo de trabalho de desenvolvimento do HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anotações do modelo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As anotações de modelo, incluídas na parte superior de um modelo, definem configurações de modelo importantes, como o tipo de modelo e se ele pode ser usado para criar novo conteúdo. As anotações do modelo podem ser alteradas a qualquer momento durante o processo de desenvolvimento. Saiba mais sobre as anotações de modelo disponíveis."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: page\n  isAvailableForNewContent: false\n  enableDomainStylesheets: false\n  label: Homepage\n  screenshotPath: ../images/template-previews/home.png\n-->\n\n<!doctype html>\n\n<html>\n  ...\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Anotação"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "templateType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Especifica o tipo de modelo de um arquivo. Os valores incluem:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Modelos padrão:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#page",
                      children: "página"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email",
                      children: "e-mail"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-listing",
                      children: "blog_listing"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-post",
                      children: "blog_post"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog",
                      children: "blog"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Parciais:", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
                  children: "global_partial"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Modelos do sistema:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#error-pages",
                      children: "error_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#password-prompt",
                      children: "password_prompt_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-login",
                      children: "membership_login_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-register",
                      children: "membership_register_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-password-reset",
                      children: "membership_reset_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-reset-request",
                      children: "membership_reset_request_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-preferences",
                      children: "email_subscription_preferences_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-backup-unsubscribe",
                      children: "email_backup_unsubscribe_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-unsubscribe-confirmation",
                      children: "email_subscriptions_confirmation_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#search-results-page",
                      children: "search_results_page"
                    })
                  })]
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isAvailableForNewContent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Especifica se um modelo está disponível para seleção no processo de criação de conteúdo. Os valores incluem: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ".Modelos definidos como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " não precisam incluir as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/variables#required-page-template-variables",
              children: "variáveis obrigatórias"
            }), ". Os modelos do tipo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "page"
            }), " que são definidos como false também podem ser usados como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#partials",
              children: "parciais padrão"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableDomainStylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Especifica se o modelo deve carregar ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-edit-and-attach-css-files-to-style-your-site#attach-or-remove-stylesheets-on-a-domain-level",
              children: "folhas de estilo do domínio"
            }), ". Os valores incluem: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Descrição simples do modelo, exibida na tela de seleção do modelo. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "About Page"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Homepage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pricing"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshotPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A captura de tela a ser exibida quando um criador de conteúdo estiver selecionando um modelo. Essa captura de tela deve facilitar a diferenciação entre os seus modelos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inclusões em cabeçalho e rodapé"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os modelos do HubSpot exigem a presença de duas tags, a menos que o arquivo seja um parcial de modelo. As duas tags são:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_header_includes }}"
        }), " - Usada para adicionar o CSS necessário de forma combinada e minificada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_footer_includes }}"
        }), " - Usada para adicionar javascript ao final de uma página de forma dinâmica, para itens como o script de rastreamento do HubSpot e os módulos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Essas tags devem estar presentes no modelo ou em seus ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#partials",
        children: "parciais"
      }), " secundários para serem publicados e utilizados."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parciais"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os parciais de modelos são arquivos HTML + HubL que podem ser incluídos em outros arquivos codificados. As parciais permitem adotar uma abordagem mais modular, compartilhando a marcação entre vários modelos. Por exemplo, um cabeçalho pode ser transformado em parcial para que você possa incluí-lo facilmente como um componente sem a necessidade de codificá-lo novamente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Parciais padrão"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma parcial padrão é um modelo ou componente reutilizável com conteúdo que pode ser editado em páginas individuais. Isso permite que os criadores de conteúdo alterem o conteúdo conforme necessário, diferente das parciais globais que sempre compartilham o conteúdo. Por exemplo, o código a seguir extrairia o módulo da barra lateral para outro arquivo de modelo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As parciais padrão devem incluir as seguintes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "anotações"
      }), " na parte superior do arquivo de modelo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateType: page"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "isAvailableForNewContent: false"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"../partial/sidebar.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Parciais globais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma parcial global é um tipo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#global-partials",
        children: "conteúdo global"
      }), " que pode ser usado em vários modelos com o conteúdo compartilhado em todas as instâncias da parcial. As parciais globais são frequentemente usadas para cabeçalhos e rodapés. Veja um exemplo no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/partials/header.html",
        children: "cabeçalho"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/footer.html",
        children: "rodapé"
      }), " do HubSpot CMS Boilerplate. Essas parciais são então chamadas no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "base.html"
      }), " usando a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As parciais globais devem incluir a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "anotação"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: global_partial"
      }), " na parte superior do arquivo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blocos e extensões"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao criar modelos complexos, você pode criar blocos compartimentados que estendem um modelo principal."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, você pode criar um modelo principal que inclui as variáveis obrigatórias ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), ". Dentro desse modelo, você define um bloco exclusivo usando a seguinte sintaxe, em que o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), " é um nome exclusivo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% block body %}\n<!-- Content to display -->\n{% endblock body %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em seguida, no modelo secundário, você pode estender o modelo principal e inserir mais conteúdo no bloco do ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"./layouts/base.html\" %}\n{% block body %}\n<h3>Page Content</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esse método é usado no modelo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
        children: "base.html"
      }), " do boilerplate do HubSpot CMS, que é estendido pelos outros modelos na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/main/src/templates",
        children: "pasta de modelos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Grupos globais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "grupos globais"
      }), " criados usando o construtor de modelos de arrastar e soltar no Gerenciador de design também podem ser incluídos. A sintaxe é mostrada abaixo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"/path/to/global_header.template.json\" %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}