"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694131;
const slug = exports.slug = 'guides/cms/content/themes/hubspot-cms-boilerplate';
const title = exports.title = 'Die CMS Hub-Boilerplate';
const name = exports.name = '[neu] Die CMS Hub-Boilerplate';
const metaDescription = exports.metaDescription = 'Die CMS Hub-Boilerplate veranschaulicht bewährte Methoden und hilft Entwicklern beim schnellen Erstellen einer Website mit CMS Hub. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "warum-sollten-entwickler-die-cms-hub-boilerplate-verwenden%3F",
  "label": "Warum sollten Entwickler die CMS Hub-Boilerplate verwenden?",
  "parent": null
}, {
  "depth": 0,
  "id": "erste-schritte-bei-der-verwendung-der-cms-hub-boilerplate",
  "label": "Erste Schritte bei der Verwendung der CMS Hub-Boilerplate",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-hub-boilerplate-struktur",
  "label": "CMS Hub-Boilerplate-Struktur",
  "parent": null
}, {
  "depth": 0,
  "id": "jquery",
  "label": "jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "verwandte-ressourcen",
  "label": "Verwandte Ressourcen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      strong: "strong",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Die CMS Hub-Boilerplate"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 9,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "Die CMS Hub-Boilerplate"
            }), " bietet Entwicklern Unterstützung, die in CMS Hub eine Website einrichten möchten. Dazu werden zum Beispiel bewährte Methoden für die Nutzung der CMS Hub-Plattform bei der Entwicklung von Websites vermittelt. Die Boilerplate ist ein Open-Source-GitHub-Projekt, in dem alle Interessierten Änderungsvorschläge einbringen und Ideen für ihre eigenen Projekte finden können. Wenn Sie neu in CMS Hub sind und mit einem neuen Projekt auf der Grundlage der Boilerplate beginnen möchten, folgen Sie der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/quickstart",
              children: "Schnellstartanleitung für die Entwicklung in CMS Hub"
            }), "."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 3,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/subscription",
              children: "Watch"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/fork",
              children: "Fork"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "Star"
            })]
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Warum sollten Entwickler die CMS Hub-Boilerplate verwenden?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die CMS Hub-Boilerplate wird von HubSpot entwickelt und aktiv gepflegt. Bei der Erstellung der Boilerplate haben wir bewährte Methoden berücksichtigt, die von der Art und Weise beeinflusst wurden, wie Entwickler das Erstellen von Websites optimal gestaltet haben und diese Erkenntnisse dann auf die Erstellung einer Website mit dem CMS Hub angewendet. Damit steht den Entwicklern eine saubere, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "leistungsfähige"
      }), " und modifizierbare Website zur Verfügung, die eine erhebliche Zeitersparnis beim Entwickeln ermöglicht. Die Boilerplate bietet auch umfassende CSS für Elemente von HubSpot wie Formulare, Menümodule, Basisklassen und mehr. Wenn Sie die Boilerplate einmal in Aktion sehen möchten, gehen Sie zu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/",
        children: "https://boilerplate.hubspotcms.com/"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erste Schritte bei der Verwendung der CMS Hub-Boilerplate"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um mit der Verwendung der Boilerplate in Ihrer lokalen Entwicklungsumgebung zu beginnen, folgen Sie einfach unserer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "Schnellanleitung zur Entwicklung von Websites in CMS Hub."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS Hub-Boilerplate-Struktur"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Die Entwickler können mit der Boilerplate mit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "Tools für lokale Entwicklung"
            }), " oder direkt im ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cos-general/get-started-with-the-design-manager",
              children: "Design-Manager"
            }), " arbeiten. Die Boilerplate verwendet relative Pfadreferenzen für alle Elemente, was eine einfache Anpassung an Ihr Projekt ermöglicht. Auf diese Weise kann die Boilerplate auch vollständig zwischen den Accounts in der CRM-Plattform übertragen werden. Nachfolgend sehen Sie einen Screenshot der Ordnerstruktur der Elemente der Boilerplate. In der Boilerplate werden die Elemente in mehrere Verzeichnisse aufgeteilt, sodass leicht ermittelt werden kann, wo sie sich befinden."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844633478"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/boilerplate/boilerplate-structure-tree-view-4.png",
        alt: "Ordnerstruktur der CMS Hub-Boilerplate"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die zugrundeliegende Vorlagenstruktur der CMS Hub Boilerplate dreht sich um ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "gemeinsames Basislayout"
      }), ", das sich im Ordner ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Vorlagen > Layouts"
      }), " befindet und dann mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% extends %}"
      }), "-Tag erweitert wird und den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% block body %}"
      }), "-Block für seinen Hauptinhalt referenziert. Ein Beispiel dafür, wie das extend-Tag und die Blöcke verwendet werden, finden Sie in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "jeder der HTML-Dateien im Vorlagenverzeichnis."
      }), " Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#blocks-and-extends",
        children: "Blöcke und Erweiterungen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies ist eine gängige Methode beim Entwickeln von CMS-Systemen, bei der Sie eine Basisvorlage (manchmal auch als Haupt- oder übergeordnete Vorlage bezeichnet) haben, die alle wichtigen strukturellen Teile des Inhalts Ihrer Website enthält. Dies sind häufig Elemente, die sich innerhalb des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), "-Elements auf Ihrer Website befinden, z. B. allgemeine Metaeigenschaften (Bsp.: Titel und Metabeschreibung), Favicon-Links, CSS-Links und Skripte von Drittanbietern"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n <head>\n   <meta charset=\"utf-8\">\n   <title>{{ page_meta.html_title }}</title>\n   {% if site_settings.favicon_src %}<link rel=\"shortcut icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n   <meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n   {{ require_css(get_asset_url(\"../../css/layout.css\")) }}\n   {{ require_css(get_asset_url(\"../../css/main.css\")) }}\n   {{ require_css(\"https://fonts.googleapis.com/css?family=Merriweather:400,700|Lato:400,700&display=swap\") }}\n   {{ require_js(get_asset_url(\"../../js/main.js\")) }}\n   {{ standard_header_includes }}\n </head>\n <body>\n   <div class=\"body-wrapper {{ builtin_body_classes }}\">\n     {% block header %}\n       {% global_partial path=\"../partials/header.html\" %}\n     {% endblock header %}\n\n     {% block body %}\n       <!-- Nothing to see here -->\n     {% endblock body %}\n\n     {% global_partial path=\"../partials/footer.html\" %}\n   </div>\n   {{ standard_footer_includes }}\n </body>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Innerhalb dieses Basislayouts gibt es auch Aufrufe an unsere globalen Header- und Footer-Teilvorlagen. Dies ermöglicht es uns, den Code für diese Teilvorlagen aus Gründen der Modularität in separaten Dateien aufzubewahren. Da es sich um globale Teilvorlagen handelt, können sie von Ihren Content-Autoren problemlos mit unserem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Editor für globalen Content"
      }), " bearbeitet werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Weitere Informationen zu den in der Boilerplate enthaltenen Elementen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/wiki",
        children: "finden Sie im Wiki der Boilerplate auf GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Die HubSpot Theme Boilerplate benötigt kein jQuery, um zu funktionieren"
      }), ". Bei älteren HubSpot-Accounts wird jQuery standardmäßig geladen. Bei neueren HubSpot-Accounts ist jQuery standardmäßig deaktiviert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In der Vergangenheit war für HubSpot-Skripte jQuery erforderlich, um ordnungsgemäß zu funktionieren, sodass die domainübergreifende Einstellung dazu diente, die Kompatibilität sicherzustellen. HubSpot-Skripte verwenden kein jQuery mehr. Denn JQuery ist nicht erforderlich, und es gibt bessere Möglichkeiten für Entwickler, Bibliotheken einzubinden, die auch mit der Versionskontrolle funktionieren. Es wird empfohlen, die jQuery-Einstellungen für neue Websites zu deaktivieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beachten Sie bitte, dass durch das Deaktivieren von jQuery auf einer Domain, die über eine bestehende Website verfügt, Landingpages oder bestehende Webseiten, die Sie haben, beschädigt werden können, wenn sie von jQuery abhängen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**Wenn Sie jQuery auf Ihrer neuen Website nutzen möchten, ist es empfehlenswert, die neueste Version von jQuery zu verwenden.**Es gibt zwei einfache Möglichkeiten, dies zu tun:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Laden Sie die neueste Version von jQuery in Ihr Entwickler-Dateisystem hoch und verwenden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), ", um es zum gewünschten Zeitpunkt zu laden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie ein CDN, dem Sie vertrauen, und verwenden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), ", um jQuery zum gewünschten Zeitpunkt zu laden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwandte Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Erste Schritte mit Designs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Optimieren Sie die Performance Ihrer CMS Hub-Website"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Barrierefreiheit"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}