"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158721871211;
const slug = exports.slug = 'getting-started/what-to-build';
const title = exports.title = 'Qué crear';
const name = exports.name = 'Qué crear (documentos para desarrolladores 2024)';
const metaDescription = exports.metaDescription = 'Conoce las diferentes formas de desarrollar en HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "utiliza-api-y-crea-integraciones",
  "label": "Utiliza API y crea integraciones",
  "parent": null
}, {
  "depth": 1,
  "id": "comenzar-a-autenticar-llamadas-con-una-aplicaci%C3%B3n-privada",
  "label": "Comenzar a autenticar llamadas con una aplicación privada",
  "parent": "utiliza-api-y-crea-integraciones"
}, {
  "depth": 1,
  "id": "crear-para-el-mercado-de-aplicaciones",
  "label": "Crear para el mercado de aplicaciones",
  "parent": "utiliza-api-y-crea-integraciones"
}, {
  "depth": 0,
  "id": "personalizaci%C3%B3n-de-la-interfaz-de-usuario-de-hubspot",
  "label": "Personalización de la interfaz de usuario de HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-sitios-web-personalizados",
  "label": "Crear sitios web personalizados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      strong: "strong",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Qué crear"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existen varias vías de desarrollo posibles con HubSpot, en función de tus metas. En esta página encontrarás una visión general de alto nivel de cada ruta, junto con las herramientas y cuentas que necesitarás para cada una. También encontrarás enlaces a documentos detallados que te darán más contexto, así como guías de inicio rápido."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#use-apis-and-build-integrations",
          children: "Utiliza API y crea integraciones"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#get-started-authenticating-calls-with-a-private-app",
              children: "Comenzar a autenticar llamadas con una aplicación privada"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#build-for-the-app-marketplace",
              children: "Crear para el mercado de aplicaciones"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#customize-the-hubspot-ui",
          children: "Personalización de la interfaz de HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-custom-websites",
          children: "Crear sitios web personalizados"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utiliza API y crea integraciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utiliza las API de HubSpot para crear soluciones personalizadas, como compartir datos entre HubSpot y sistemas externos, utilizar webhooks para escuchar los cambios en la cuenta y crear objetos personalizados para almacenar datos específicos de tu negocio."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Casos de uso de ejemplo:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utiliza objetos personalizados para personalizar la forma en que el CRM almacena los datos para que represente mejor a tu empresa."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sincroniza datos de sistemas externos para obtener una imagen más rica de las actividades de salida al mercado."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Amplía las capacidades de la interfaz de usuario de CRM para que se adapte mejor a tus procesos."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dos formas de crear aplicaciones"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Hay dos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "tipos de aplicaciones"
        }), ", dependiendo de lo que estés creando. Si te estás iniciando en las API y las integraciones, se recomienda empezar con una aplicación privada, ya que son más rápidas de configurar y utilizar para la autenticación. Una vez que te hayas familiarizado con la creación de aplicaciones, quizá quieras saber más sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-for-the-app-marketplace",
          children: "aplicaciones públicas"
        }), ", que pueden instalarse en varias cuentas y te permiten crear otros tipos de extensiones."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ejemplo de caso de uso para aplicaciones privadas:"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"Quiero crear una aplicación para mi empresa/equipo.”"
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comenzar a autenticar llamadas con una aplicación privada"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La mayoría de las llamadas a la API requieren autenticación para interactuar con los datos de tu cuenta de HubSpot. Para empezar a hacer llamadas a tu cuenta, crea una aplicación privada y utiliza su token de acceso para la autenticación."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " Son obligatorios ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "Permisos de Superadministrador"
        }), " para crear aplicaciones privadas en tu cuenta de HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cómo empezar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crear una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "aplicación privada"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
          children: "hacer llamadas autenticadas a la API con aplicaciones privadas"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["También puedes utilizar las bibliotecas cliente ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/overview"
        }), " para hacer llamadas a la API"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear para el mercado de aplicaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Desarrolla integraciones para el mercado de aplicaciones de HubSpot con una aplicación pública, para que los usuarios de HubSpot puedan instalar tu integración en su cuenta. La creación de aplicaciones públicas para el mercado requiere el cumplimiento de las directrices del Mercado de aplicaciones de HubSpot y una cuenta de desarrollador de aplicaciones."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Las aplicaciones públicas se autentican con OAuth. Las aplicaciones públicas pueden instalarse en varias cuentas y distribuirse en el mercado."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ejemplos de casos de uso para aplicaciones públicas:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"Soy un partner de HubSpot que quiere crear una aplicación reutilizable que pueda adaptar para mis clientes.”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"Soy un partner de HubSpot que quiere crear una app disponible en el mercado para promocionar nuestras capacidades entre el mayor número posible de clientes de HubSpot.”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cómo empezar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-hubspot/developers",
            children: "Crear una cuenta de desarrollador de aplicaciones"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Inicio rápido:"
          }), " ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "Crear una aplicación pública"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/oauth-quickstart-guide",
            children: "Seguir la guía de inicio rápido de OAuth"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
            children: "Revisar los requisitos de anuncio en el Mercado de aplicaciones"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "Envía tu aplicación al Mercado de aplicaciones"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Personalización de la interfaz de usuario de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Por favor, ten en cuenta que:"
        }), " para crear extensiones de IU se requiere una suscripción a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Sales Hub"
          })
        }), " o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Service Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ". Sin embargo, puedes comenzar a crearlas gratis en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "cuentas de prueba para desarrolladores"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de los elementos de interfaz de usuario que HubSpot proporciona para los registros de CRM, también puedes personalizar el CRM con extensiones de interfaz de usuario. Estas extensiones se construyen localmente utilizando la Herramienta de proyectos para desarrolladores, que te permite construir y desplegar en HubSpot utilizando la CLI. El SDK de extensiones de IU proporciona una caja de herramientas de métodos, funcionalidades, herramientas y componentes para personalizar tu extensión. Si no estás seguro de por dónde empezar con las extensiones de interfaz de usuario, echa un vistazo a los proyectos de muestra de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Los proyectos te permiten crear y desplegar localmente aplicaciones privadas, extensiones de interfaz de usuario y funciones sin servidor utilizando la CLI de HubSpot."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ejemplos de uso de las extensiones de IU:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"Quiero agregar un formulario personalizado a los registros de contactos y empresas que permita a nuestro equipo de atención al cliente crear tickets de Jira mientras atienden las llamadas de los clientes\".”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"Necesito sacar a la superficie resúmenes detallados de pipelines de ventas a través de registros de negocios para que nuestros socios de administración puedan encontrar la información que necesitan de un vistazo.”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cómo empezar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si no tienes una suscripción a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " o ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Service Hub"
          }), " Enterprise"]
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "crea una cuenta de prueba de desarrollador"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Únete a la beta de los proyectos de desarrollo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/overview#get-started"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Inicio rápido:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/quickstart",
          children: "Guía de inicio rápido de las extensiones de IU"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Más información sobre el SDK de extensiones de IU ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sdk"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear sitios web personalizados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con el software CMS (Content Management System) de HubSpot, puedes crear potentes sitios web y landing pages que se adapten y ajusten a las personas que llegan a tu sitio. Al basarse en el CMS, todas las interacciones de los visitantes del sitio web se conectan directamente al CRM de la cuenta, la misma herramienta que utilizan los equipos de Ventas, Marketing, Servicios y RevOps para hacer su trabajo con más eficacia."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Crea sitios web de HubSpot localmente en tu entorno preferido con la CLI de HubSpot, utilizando tecnologías como:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML, CSS y JavaScript"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubL, que es el lenguaje de plantillas de HubSpot basado en Jinjava"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "React, que puede utilizarse para crear módulos y parciales renderizados en JavaScript"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "GraphQL, que puedes utilizar para consultar datos de HubSpot"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Y si prefieres utilizar GitHub para el control de versiones, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "configurar una integración utilizando las acciones de GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "El CMS te permite crear sitios web localmente con el CLI de HubSpot. Tendrás acceso a una serie de herramientas versátiles y adaptables, como asistencia multilingüe, CTA, formularios, afiliaciones y mucho más. HubSpot también controla por ti la seguridad, el rendimiento del servidor, la CDN, el alojamiento y la garantía de tiempo de actividad."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ejemplos de uso del CMS:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"Quiero crear páginas de listas de productos que extraigan información directamente del inventario mantenido en el CRM.”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"Necesito un sitio web que incluya un blog público y un blog sólo para miembros.”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crea una cuenta en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-hubspot/cms-developers",
          children: "CMS Sandbox"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Instalar la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "CLI de HubSpot"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Instalar la extensión ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
          children: "HubSpot Visual Studio Código"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Inicio rápido:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Guía de inicio rápido del CMS de HubSpot"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revisa las guías CMS ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms"
        }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms",
          children: "documentos de referencia"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}