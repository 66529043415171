"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742958;
const slug = exports.slug = 'guides/api/analytics-and-events/event-analytics';
const title = exports.title = 'API de eventos | Análise de eventos';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Web Analytics';
const metaDescription = exports.metaDescription = 'O endpoint de Análise de eventos permite localizar e filtrar eventos associados a um objeto de CRM de qualquer tipo. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "conclus%C3%B5es-individuais-de-evento",
  "label": "Conclusões individuais de evento",
  "parent": null
}, {
  "depth": 1,
  "id": "selecionar-por-id-de-objeto",
  "label": "Selecionar por ID de objeto",
  "parent": "conclus%C3%B5es-individuais-de-evento"
}, {
  "depth": 1,
  "id": "selecionar-por-propriedade-de-objeto",
  "label": "Selecionar por propriedade de objeto",
  "parent": "conclus%C3%B5es-individuais-de-evento"
}, {
  "depth": 0,
  "id": "consultar-e-filtrar-tipos-de-eventos",
  "label": "Consultar e filtrar tipos de eventos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Análise de eventos"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a API de análise da eventos para buscar eventos associados a qualquer tipo de registro do CRM (somente para ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sales Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ". Isso inclui eventos padrão, como visualizações de página do site e aberturas de e-mail, bem como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "eventos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, use essa API para visualizar uma linha do tempo das interações que um contato teve com seu conteúdo. Você pode usar essa linha do tempo para criar um conjunto de dados para análise personalizada ou apresentar uma linha do tempo de contato em um aplicativo externo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conclusões individuais de evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta API retorna eventos para um registro de CRM por vez. Você pode selecionar o registro ao especificar o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " e incluir o parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Selecionar por ID de objeto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para especificar um registro pelo ID, adicione parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". Por exemplo, para especificar um registro do contato com o ID ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "2832"
      }), ", você faria a seguinte solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectId=224834"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Selecionar por propriedade de objeto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para especificar um registro por uma propriedade exclusiva em vez de ID de contato, adicione o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), ". Consulte a propriedade incluindo o nome da propriedade e o valor no seguinte formato:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty.{propname}={propvalue}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, para especificar um contato pelo endereço de e-mail, você faria a seguinte solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectProperty.email=user12@dev.com"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Consultar e filtrar tipos de eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando consultar eventos associados a um determinado objeto de CRM, a resposta incluirá todos os tipos de evento, incluindo eventos de comportamentos personalizados."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para retornar apenas conclusões de um tipo de evento específico, você pode incluir um parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " seguido do nome do evento. Para obter uma lista de todos os tipos de eventos disponíveis, pode fazer uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/event-types"
      }), ". A resposta retornará todos os tipos de eventos da sua conta. Você pode utilizar um destes nomes de eventos como parâmetro de consulta em uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para o ponto de extremidade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId=224834"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}