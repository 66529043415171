'use es6';

export const argumentType = {
  OBJECT: 'object',
  REF: 'ref',
  STRING: 'string',
  BOOLEAN: 'boolean',
  INTEGER: 'integer',
  ARRAY: 'array',
  UNDEFINED: 'undefined'
};
export const formatTypes = {
  BINARY: 'binary'
};
export const httpFormContentTypes = ['application/x-www-form-urlencoded', 'multipart/form-data'];