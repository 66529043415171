"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76633369011;
const slug = exports.slug = 'guides/cms/marketplace/theme-requirements';
const title = exports.title = 'HubSpotテンプレートマーケットプレイスでのテーマの要件';
const name = exports.name = 'テンプレートマーケットプレイス | テーマの要件（2022）';
const metaDescription = exports.metaDescription = 'アセットマーケットプレイスにテーマを提出する際に満たす必要がある要件について説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E5%88%B6%E9%99%90",
  "label": "テーマの制限",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%85%A8%E4%BD%93%E7%9A%84%E3%81%AA%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E8%A6%81%E4%BB%B6",
  "label": "全体的なテーマの要件",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E6%A7%8B%E9%80%A0",
  "label": "テーマファイルの構造",
  "parent": "%E5%85%A8%E4%BD%93%E7%9A%84%E3%81%AA%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E8%A6%81%E4%BB%B6"
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E7%9B%B8%E5%AF%BE%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB-%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB-%E3%83%91%E3%82%B9",
  "label": "テンプレートの相対ローカル ファイル パス",
  "parent": "%E5%85%A8%E4%BD%93%E7%9A%84%E3%81%AA%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E8%A6%81%E4%BB%B6"
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9",
  "label": "テーマのパフォーマンス",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BCurl",
  "label": "テーマのプレビューURL",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9"
}, {
  "depth": 0,
  "id": "jquery%E3%81%AE%E4%BD%BF%E7%94%A8",
  "label": "jQueryの使用",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E6%A7%8B%E6%88%90%EF%BC%88teme.json%EF%BC%89",
  "label": "テーマ構成（teme.json）",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E8%A8%AD%E5%AE%9A%EF%BC%88fields.json%EF%BC%89",
  "label": "テーマ設定（fields.json）",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%EF%BC%88css%E3%80%81%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%80%81%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AA%E3%81%A9%EF%BC%89",
  "label": "テンプレート（CSS、セクション、ページテンプレートなど）",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "セクション",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%EF%BC%88css%E3%80%81%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%80%81%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AA%E3%81%A9%EF%BC%89"
}, {
  "depth": 1,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
  "label": "ページテンプレート",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%EF%BC%88css%E3%80%81%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%80%81%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AA%E3%81%A9%EF%BC%89"
}, {
  "depth": 1,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E5%91%BD%E5%90%8D",
  "label": "ページテンプレートの命名",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%EF%BC%88css%E3%80%81%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%80%81%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AA%E3%81%A9%EF%BC%89"
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "モジュール",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "グローバルコンテンツ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB",
  "label": "グローバルパーシャル",
  "parent": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84"
}, {
  "depth": 0,
  "id": "%E5%A4%9A%E8%A8%80%E8%AA%9E%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88",
  "label": "多言語サポート",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%83%90%E3%82%A4%E3%83%AB%E3%81%8A%E3%82%88%E3%81%B3%E3%83%AC%E3%82%B9%E3%83%9D%E3%83%B3%E3%82%B7%E3%83%96%E8%A6%81%E7%B4%A0",
  "label": "モバイルおよびレスポンシブ要素",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      Tabs,
      Tab,
      IFrame
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotテンプレートマーケットプレイスでのテーマの要件"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotテンプレートマーケットプレイスへの提出のために、テーマが満たす必要のある要件について、詳しくご確認ください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマ作成の取り組みを始めたばかりの場合は、HubSpotの無料のCMSテーマボイラープレートを利用することを強くお勧めします。このテンプレートは、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Gitリポジトリー"
      }), "からダウンロードすることも、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.youtube.com/watch",
        children: "アカウントのデザインマネージャーUI内でインポート"
      }), "することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、テンプレートをHubSpotテンプレートマーケットプレイスに提出する際は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "テンプレート リスト ページの要件"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマの制限"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマごとに以下の制限が適用されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["無料の", (0, _jsxRuntime.jsxs)(_components.strong, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: "CMS"
            }), " Hub"]
          }), "アカウントでは、サイト検索、CTA機能、ネイティブHubSpot動画を使用できません。", (0, _jsxRuntime.jsxs)(_components.strong, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: "CMS"
            }), " Hub"]
          }), "サブスクリプションでご利用可能な機能の詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
            children: "HubSpot製品・サービスカタログ"
          }), "をご覧ください。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "テーマに含めるコンポーネントの数の上限は次のとおりです。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50個のテンプレート"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50個のモジュール"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50個のセクション"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["テーマに次のコンポーネントを含めることは", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "できません"
          }), "。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Eメールテンプレート"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubDB機能"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "サーバーレス関数"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "CRMオブジェクトフィールド"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/drag-and-drop-templates#flexible-columns",
              children: "テンプレート内のフレキシブルカラム"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "モジュールとセクション"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "コンテンツ制作者がページ上でセクションをドロップできる場所は、全幅ドロップゾーンのみです。そのため、セクションは開発者が優れた完成品を保証するのに役立ちます。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "特に、コンテンツ制作者がセクション内の要素を移動できない固定レイアウトセクションは、ドラッグ＆ドロップエディターを使用して他の手段では実現できないクリエイティブな書式設定とレイアウトを提供するための絶好のツールです。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "コンテンツ制作者はモジュールフォームを短くして編集中の要素によりターゲットを絞れるようにするために、セクション内で個々のモジュールを選択できることから、セクションは、さらに使いやすさのメリットもコンテンツ制作者にもたらします。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "全体的なテーマの要件"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["提出される全てのテーマは、明確に区別できるオリジナルのものでなければなりません。例えば、コピーまたはプレースホルダーの内容が異なる同一のテーマは、別個のテーマとして見なされません。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/template-policies#template-marketplace-compliance",
          children: "HubSpotテンプレートマーケットプレイスのコンプライアンスについて詳細をご確認ください"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマの作成には、HTMLおよびHubLテンプレートと、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "タグを使用する必要があります。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "12列グリッドを考慮したテーマでなければなりません。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テーマファイルの構造"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのテーマに適切なフォルダー構造が含まれる必要があり、製品掲載情報を示す単一の親フォルダーの下でグループ化されている必要があります。例えば、マーケットプレイス用に「SuperAwesome」というテーマを作成している場合は、以下の図のような構造になります。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-file-structure",
        children: "テーマファイルの構造"
      }), "について詳しくご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/template-name.png",
          alt: "template-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/file-structure.png",
          alt: "file-structure"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テンプレートの相対ローカル ファイル パス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマアセットを参照する際には、相対ローカル ファイル パスを使用する", (0, _jsxRuntime.jsx)("u", {
        children: "必要があります"
      }), "。相対パスを含める際の最善の方法は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "get_asset_url関数"
      }), "を使用することです。この関数は、アセット、ファイル、テンプレートの公開URLを返します。また、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ファイル"
      }), "を右クリックして［公開URLをコピー］*", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "*を選択するか、［アクション］**"
      }), "をクリックして［公開URLをコピー］****を選択することで、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "この関数を生成"
      }), "することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "get_asset_url"
      }), "で参照されるスタイルシートを次のように書式設定する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "入力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{require_css(get_asset_url('../../css/main.css')) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "//cdn2.hubspot.net/hub/1234567/hub_generated/template_assets/1565970767575/custom/styles/style.min.css\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の動画で、開発者アカウント内のファイルとマーケットプレイスの顧客に配信されるファイルとのファイル構造の違いをご確認ください。"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SSOdhqOciuk?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマのパフォーマンス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Google Lighthouseを使用する場合は、テーマのスコアが次のしきい値を", (0, _jsxRuntime.jsx)("u", {
        children: "上回る"
      }), "必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**デスクトップのアクセシビリティー：**65"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**デスクトップのベストプラクティス：**80"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**デスクトップのパフォーマンス：**70"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**モバイルのパフォーマンス：**40"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#evaluate-themes-and-templates-for-seo-and-accessibility",
        children: "CLIを使用してテーマのGoogle Lighthouseレポートを生成する方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テーマファイルはミニフィケーション可能でなければなりません。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "全ての画像ファイルはサイズが1MB未満である必要があります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["全ての画像タグは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "alt"
        }), "属性を持つ必要があります（値", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), "は使用可能です）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["全ての画像タグは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "loading"
        }), "属性を持つ必要があります（値", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), "は使用可能です）。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テーマのプレビューURL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プレビューURLを作成する場合は、独自のドメイン名を使用する必要があります。次のような構造を持つHubSpot提供のドメインを使用することはできません：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[AccountID].hs-sites.com"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デモサイトの画像ではなく、公開中のウェブサイトを使用する必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ライブデモがアクセス不可になった場合、HubSpotはプロバイダーへの通知をもって、ライブデモが再びアクセス可能になるまでテーマを除外または除去する権利を留保します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "jQueryの使用"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["お客さまのHubSpotアカウントでは、jQueryが", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/include-jquery-across-your-hubspot-pages",
        children: "既定で有効にされることはありません"
      }), "。テーマがjQueryに依存している場合は、テーマが想定通りに機能するように、jQueryのバージョンを含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、jQueryを必要とするモジュールを含める場合、ウェブサイトの残りの部分ではjQueryが必要でなければ、次のコードを使用してjQueryを読み込む必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# this checks if the \"Include jQuery\" option in Settings > CMS > Pages is checked #}\n{% if not site_settings.include_jquery %}\n  {{ require_js(\"../jquery-3.4.1.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマ構成（teme.json）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "ファイルには、次のパラメーターを含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "ファイルをチェックして、次のことを確認してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ラベル名が、テーマ掲載情報での名前と一致していること。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotの無料のCMSテーマボイラープレートを使用している場合は、ボイラープレートの値が存在していないこと。これには、作成者情報、ドキュメントURL、サンプルURLなどが含まれます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ドキュメントURLが解決され、このURLにテーマの使い方に関するドキュメントが用意されていること。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プレビューパスがテーマ内の有効なファイルを指していること。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "スクリーンショットパスが有効なファイルを指していて、テーマに関連していること。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["サンプルURLが解決されて、テーマのデモにつながること。サンプルURLで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), "サブドメインや", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        }), "サブドメインが使用されていないこと。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-json",
        children: "theme.jsonのパラメーター"
      }), "について詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマ設定（fields.json）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイルは、テーマエディターで利用可能なフィールドとフィールドグループ（スタイルフィールドを含む）を制御します。含めるフィールドは、ページエディターでの制作担当者による制御をどの程度可能にするかに応じて異なります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), "ファイルに少なくとも3つの色フィールドが含まれている必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマと独立モジュールの間の互換性を確保するために、テーマにはフォントと色に関する標準的な命名規則として、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary_color"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary_color"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "heading_font"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "body_font"
        }), "が含まれている必要があります。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/beta-docs/guides/cms/marketplace/template-policies#module-compatibility-with-themes",
          children: "モジュールとテーマの互換性"
        }), "についての詳細をご確認ください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマフィールドに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primary_color"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secondary_color"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "heading_font"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body_font"
      }), "フィールドがない場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alternate_names"
      }), "フィールドを使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#fields-json",
        children: "fields.jsonのパラメーター"
      }), "についての詳細と、HubSpot CMSボイラープレートの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/fields.json",
        children: "サンプルfields.jsonファイル"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマ設定には次の要件も適用されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマ設定がモジュールで設定されているエディタースタイルまたはスタイルと競合しないこと。例えば、CSSスタイルシートでは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "!important"
        }), "を使用しないでください。このプロパティーを使用すると、エンドユーザーによる上書きが困難になり、競合の原因となる可能性があります。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コンテンツ制作者が更新内容を把握できるよう、テーマ設定では各設定に分かりやすい説明ラベルを使用すること。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマ設定を、テーマに含まれる全てのテンプレートに適用すること（ただし、追加のスタイルに特定の用途がある場合を除きます）。例えば、テーマ設定で", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), "見出しのスタイルとサイズを変更した場合は、テーマに含まれる全ての", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), "タグにその変更を適用する必要があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマには、少なくとも次のテーマフィールドを含める", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "必要があります"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "タイポグラフィーフィールド："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["本文テキストのフォントフィールド（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "タグ）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), "～", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h6"
        }), "のフォントフィールド"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ホバースタイルの設定を含むハイパーリンクの色（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "a"
        }), "タグ）"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "フォームフィールド："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フォームタイトルの背景"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "少なくともフォントの色のスタイルを含むフォーム タイトル テキスト"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フォームの背景色"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フォーム境界線の色"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フォームラベルの色"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フォームフィールドの境界線の色"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フォームボタン（ボタンテキスト、背景色、ホバースタイルの設定を含む）"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "さらに、次の要件も適用されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマ内のフィールドは、必要に応じて論理的にグループ化する必要があります。例えば、タイポグラフィーに関連する複数のフィールドを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Typography"
        }), "グループとしてグループ化します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テーマフィールドには、ボタンとフォーム用の個別の色とフォントの制御設定に加えて、ヘッダーとフッター用の個別の色、ロゴ、フォントの制御設定が必要です。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマの色フィールドとロゴフィールドの一部は、アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/brand-and-settings-inheritance",
          children: "ブランディング設定"
        }), "を継承する必要があります。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "少なくとも2つの色フィールドが、アカウントのブランド設定から色を継承する必要があります。その他の色フィールドでは、白と黒を含む他の色を既定の色として使用できます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "テーマ内のモジュールでロゴが使用されている場合、少なくとも1つのロゴフィールドは、アカウントのブランド設定を継承する必要があります。画像フィールドを使用してロゴをレンダリングする場合、画像フィールドはブランド設定を継承する必要はありません。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ブランドカラーがテーマの美的品質に与える影響"
      })
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/voyJupcZYeQ?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレート（CSS、セクション、ページテンプレートなど）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "セクション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["適用可能な場合は常に", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections#reusable-sections",
          children: "セクション"
        }), "を使用する必要があります。テーマにはそれぞれ少なくとも5個のセクションが", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "必要です"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "セクションには固有の実用的なスクリーンショットが必要です。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "セクションとモジュールを冗長にしないでください。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ページテンプレート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマには、少なくとも次のテンプレートタイプを含める", (0, _jsxRuntime.jsx)("u", {
        children: "必要があります"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "ウェブサイト ページ テンプレートまたはランディング ページ テンプレート。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "複数のページテンプレートを含める場合、各テンプレートは別個の目的を持つ必要があります。例えばホームページ、会社概要__ページ、全幅ランディングページ、右サイドバー付きランディングページなどです。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1つのテーマに少なくとも8個のページテンプレートを含めることをお勧めします。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "別個のブログ リスト テンプレートとブログ記事テンプレート。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**ブログ リスト テンプレート：**リスト形式で全てのブログ記事を表示するページ（「ブログロール」と呼ばれる）。テンプレートのタイトルは、リストページ用であることを示す必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**ブログ記事テンプレート：**個々のブログ記事を表示する、ブログ記事の詳細ページ。テンプレートのタイトルは、ブログ記事ページ用であることを示す必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "さらに、ブログコメント欄とブログ執筆者欄のスタイルを、テーマに合わせて設定する必要があります。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "次のシステム ページ テンプレート："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**404エラーテンプレート：**存在しないページに訪問者がアクセスしようとしたときに表示されます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**500エラーテンプレート：**ウェブサイトで内部エラーが発生したときに表示されます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**パスワード プロンプト テンプレート：**ページがパスワードで保護されているときに表示されます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**サブスクリプションテンプレート：**配信（サブスクリプション）設定ページ。このページで、Eメール受信者は配信登録するEメールの種類を管理できます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**サブスクリプション更新テンプレート：**Eメール受信者が配信設定を更新したときに表示される確認ページ。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**配信登録解除バックアップテンプレート：**配信登録を解除しようとしているEメール受信者に対し、HubSpotがそのEメールアドレスを判断できない場合に表示されるページ。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "検索結果テンプレート："
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/content-search",
              children: "サイト検索"
            }), "を使用したときに返される検索結果を表示します。有料の", (0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "CMS Hub"
              })
            }), "アカウントでのみご利用いただけます。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ページテンプレートの命名"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "同様の名前が付けられたテンプレートがある場合は、それらの違いを説明する単語を名前に追加します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "大文字と小文字の一貫性を保ち、ハイフンを削除し、短縮形を使用しないようにします（例えば、「bg」を使用する代わりに「background」とスペルアウトします）。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "会社名やテーマ名をテンプレート名に含める必要はありません。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマモジュールと個々のモジュールの要件については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/module-requirements",
        children: "こちら"
      }), "で詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "グローバルコンテンツ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "グローバルパーシャル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "グローバルパーシャル"
      }), "は、HTMLとHubLを使用して作成されたテンプレートの一種であり、ウェブサイト全体で再利用できます。典型的なパーシャルは、ウェブサイトのヘッダー、ページのサイドバー、ウェブサイトのフッターです。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#creating-a-global-content-partial-template-using-local-development-tools",
        children: "グローバルパーシャルを作成する"
      }), "方法をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマにはグローバルパーシャルが含まれている", (0, _jsxRuntime.jsx)("u", {
          children: "必要があります"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["グローバルパーシャルには、使用可能な", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "ドラッグ＆ドロップエリア"
        }), "を含める", (0, _jsxRuntime.jsx)("u", {
          children: "必要があります"
        }), "。例えば、ドラッグ＆ドロップエリアを「非表示」クラスで非表示にすることはできません。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["使用可能なドラッグアンドドロップエリアをヘッダーとフッターに組み込む", (0, _jsxRuntime.jsx)("u", {
          children: "必要があります"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["また、サイト全体でグローバルに使用されるメニューの場合、ユーザーが自分のアカウント設定で作成した", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/menus-and-navigation",
          children: "HubSpotナビゲーションメニューを選択"
        }), "できるようにする必要もあります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注"
        }), "：グローバルパーシャル内にグローバルモジュールを含めることは避けてください。エンドユーザーのユーザーエクスペリエンスに悪影響を及ぼす可能性があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "多言語サポート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマは複数の言語バージョンに対応可能で", (0, _jsxRuntime.jsx)("u", {
        children: "なければならず"
      }), "、対応可能な言語を指定する必要があります。グローバルヘッダー内で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#language-switcher",
        children: "言語切り替えモジュール"
      }), "を追加すると、顧客が簡単に言語のオプションを見つけて目的の言語を選択できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["一度に1つの言語のみが表示されるようにする", (0, _jsxRuntime.jsx)("u", {
          children: "必要があります"
        }), "。例えば、UIに英語とスペイン語の両方が同時に表示されないようにしてください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ハードコーディングされたテキストを使用しないでください。例えば、ブログ リスト ボタンのテキストを__「続きを読む」とハードコーディングするのではなく、エンドユーザーがコードに触れずにテキストを更新できるように、フィールド内でテキストを設定します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モバイルおよびレスポンシブ要素"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマでは、コンテンツの閲覧に使用されるデバイスに合わせたコンテンツの適応に対応できる必要があります。また、さまざまなデバイスで一貫して優れたユーザーエクスペリエンスを提供できるテーマである必要もあります。これに該当する要素には以下のものが含まれますが、これらに限定されません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "メインナビゲーション"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "スライダーとタブ"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "大きな画像"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "水平スクロールは使用しないようにします（意図的な場合を除く）"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}