"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158146559520;
const slug = exports.slug = 'reference/ui-components/standard-components/button-row';
const title = exports.title = 'ButtonRow';
const name = exports.name = 'ButtonRow | UI components';
const metaDescription = exports.metaDescription = 'Learn about the ButtonRow component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "dropdown-variants",
  "label": "Dropdown variants",
  "parent": null
}, {
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ButtonRow | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ButtonRow"
      }), " component renders a row of specified ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/button",
        children: "Button"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview"
      }), " components. Use this component when you want to include multiple buttons in a row."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When the number of included buttons exceeds the available space, the extra buttons will be presented as a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/dropdown",
        children: "Dropdown"
      }), " style button, which you can configure using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dropDownButtonOptions"
      }), " prop."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-guide-button-row-component.png",
        alt: "design-guide-button-row-component"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Primary button:"
        }), " only use one per extension."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Secondary button:"
        }), " only use with a primary and/or destructive button."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Destructive button:"
        }), " only use for actions that are destructive, paired with a secondary button."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Button, ButtonRow } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <ButtonRow disableDropdown={false}>\n      <Button\n        onClick={() => {\n          console.log('Regular button clicked');\n        }}\n      >\n        Regular Button\n      </Button>\n      <Button\n        onClick={() => {\n          console.log('Reset button clicked');\n        }}\n        variant=\"destructive\"\n        type=\"reset\"\n      >\n        Reset\n      </Button>\n      <Button\n        onClick={() => {\n          console.log('Submit button clicked');\n        }}\n        variant=\"primary\"\n        type=\"submit\"\n      >\n        Submit\n      </Button>\n    </ButtonRow>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Prop"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableDropdown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default, when the number of buttons exceeds the available horizontal space, the extra buttons will collapse into a single dropdown menu button. Set this prop to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " to prevent the dropdown button from being interacted with. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dropDownButtonOptions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When the included buttons exceed the available space, use this prop to customize the dropdown button. This prop takes an object containing the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "key: 'value'"
            }), " pairs:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "size"
                }), ": the size of the button. Can be ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "'xs'"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "'sm'"
                }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "'md'"
                }), " (default)."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "text"
                }), ": the button's text. By default, is set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "More"
                }), ". If set to an empty value, will display a gear icon."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "variant"
                }), ": the button variation. Can be ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "'primary'"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "'secondary'"
                }), " (default), or ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "'transparent'"
                }), "."]
              })]
            }), "Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#dropdown-variants",
              children: "dropdown button variants"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Dropdown variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Buttons that exceed available space will be presented in one ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/dropdown",
        children: "Dropdown"
      }), " style button. You can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dropDownButtonOptions"
      }), " prop to customize its appearance. This prop takes an object that can include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "size"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "text"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " fields. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "size"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " fields use the same options available for those props in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Dropdown"
      }), " component."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/buttonrow-dropdown-variants.png",
        alt: "buttonrow-dropdown-variants"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comma-separate values in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dropDownButtonOptions"
      }), " to configure multiple options."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/buttonrow-dropdown-multiple-values-example.png",
        alt: "buttonrow-dropdown-multiple-values-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Button, ButtonRow } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <ButtonRow\n      dropDownButtonOptions={{\n        text: 'Extra',\n        size: 'sm',\n        variant: 'transparent',\n      }}\n    >\n      <Button variant=\"primary\">Primary</Button>\n      <Button variant=\"destructive\" type=\"reset\">\n        Destructive\n      </Button>\n      <Button type=\"submit\">Submit</Button>\n      <Button type=\"button\">Other</Button>\n    </ButtonRow>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "A primary and secondary button in a row to progress through a multi-step form."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guide-button-row-example.png?width=217&height=69&name=design-guide-button-row-example.png",
            alt: "design-guide-button-row-example"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "A destructive and secondary button in a row to confirm and cancel a contact deletion."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/buttonrow-example-cancel-delete-contact.png",
            alt: "buttonrow-example-cancel-delete-contact"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " include a secondary button with a destructive button to allow users to cancel the action."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " use multiples of the same button type in a row. For example, don't include more than one primary button in one row."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " use more than two secondary buttons in a single extension."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " use more than three buttons in a row."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button",
          children: "Button"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/overview",
          children: "CRM action components"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-action-button",
          children: "CrmActionButton"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}