"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400703;
const slug = exports.slug = 'guides/cms/setup/creating-an-efficient-development-workflow';
const title = exports.title = 'Optimiser votre workflow de développement HubSpot';
const name = exports.name = 'EMEA (FR) Development Workflow';
const metaDescription = exports.metaDescription = 'Créer un workflow de développement efficace lors de la création de sites web sur HubSpot CMS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-en-tenant-compte-de-la-portabilit%C3%A9",
  "label": "Créer en tenant compte de la portabilité",
  "parent": null
}, {
  "depth": 0,
  "id": "configuration-de-votre-environnement-de-d%C3%A9veloppement",
  "label": "Configuration de votre environnement de développement",
  "parent": null
}, {
  "depth": 1,
  "id": "configuration-de-votre-%C3%A9diteur-de-code",
  "label": "Configuration de votre éditeur de code",
  "parent": "configuration-de-votre-environnement-de-d%C3%A9veloppement"
}, {
  "depth": 2,
  "id": "code-vs",
  "label": "Code VS",
  "parent": "configuration-de-votre-environnement-de-d%C3%A9veloppement"
}, {
  "depth": 2,
  "id": "autres-%C3%A9diteurs-de-code-et-ide",
  "label": "Autres éditeurs de code et IDE",
  "parent": "configuration-de-votre-environnement-de-d%C3%A9veloppement"
}, {
  "depth": 0,
  "id": "test",
  "label": "Test",
  "parent": null
}, {
  "depth": 2,
  "id": "%C3%A9diteur",
  "label": "Éditeur",
  "parent": "test"
}, {
  "depth": 2,
  "id": "aper%C3%A7u-du-module",
  "label": "Aperçu du module",
  "parent": "test"
}, {
  "depth": 2,
  "id": "d%C3%A9bogage",
  "label": "Débogage",
  "parent": "test"
}, {
  "depth": 2,
  "id": "sandboxes",
  "label": "Sandboxes",
  "parent": "test"
}, {
  "depth": 0,
  "id": "d%C3%A9ploiement",
  "label": "Déploiement",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      h4: "h4",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Optimiser votre workflow de développement HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La mise en place d'un workflow efficace pour les développeurs vous aidera à travailler plus efficacement lors de la création de sites web sur HubSpot CMS. Selon la nature de votre équipe de développement web, ou la nature d'un projet spécifique, votre workflow peut différer."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, un développeur unique qui crée un nouveau site dans un nouveau compte HubSpot CMS doit moins se soucier des tests et de la collaboration. D'autre part, une équipe de développeurs travaillant sur un site web plus important aura besoin d'un processus de développement et de mise en place plus clair, d'un workflow de déploiement, et d'un code vivant dans le contrôle de source afin de travailler efficacement."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce guide est conçu pour vous aider à mettre en place un workflow efficace pour les développeurs, que vous pouvez adapter à vos besoins."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ce guide suppose que vous créez des sites web à l'aide de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), ". Suivez le tutoriel ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "Démarrage avec le développement local"
      }), " pour commencer. Ce guide suppose également que vous avez parcouru le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart#quick_start",
        children: "guide de démarrage rapide du développement sur HubSpot CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer en tenant compte de la portabilité"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avant de commencer à mettre en place notre workflow de développeur, il est important de reconnaître la portabilité comme un concept clé pour avoir un workflow de développeur efficace. La portabilité de votre projet garantit qu'il est facile de passer d'un environnement à l'autre avec peu de frictions et d'explications, ce qui permet de tester et de mettre en scène les changements avant de les mettre en ligne."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Boilerplate du thème CMS"
      }), " est un exemple de projet portable, qui utilise des fonctionnalités telles que les chemins de fichiers relatifs et le format de fichier réel pour toutes les ressources du projet à l'aide de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), ", ce qui lui permet de vivre dans le contrôle source et de fonctionner dans n'importe quel compte HubSpot. Ce projet est un excellent point de départ ou de référence pour les développeurs travaillant sur un nouveau projet. Tous les thèmes par défaut de HubSpot sont créés à partir de ce boilerplate et peuvent également être utilisés comme point de départ portable et efficace."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuration de votre environnement de développement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour votre environnement de développement individuel, chaque développeur de votre équipe doit créer un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "compte Sandbox pour développeur CMS"
      }), " gratuit. Ces comptes n'expirent jamais et disposent de toutes les fonctionnalités des comptes payants HubSpot CMS (sauf la possibilité de connecter des domaines personnalisés)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le CMS CLI permet d'interagir facilement avec plusieurs comptes HubSpot CMS. Créez une nouvelle ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "saisie de configuration"
      }), " pour votre compte Sandbox pour développeur CMS. Définissez le nom de la saisie de votre sandbox comme étant du type « DEV » ou « SANDBOX » afin qu'il soit clair que ce compte est un environnement de développement. En outre, définissez le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "defaultPortal"
      }), " comme étant votre compte sandbox, de sorte que lorsque vous exécutez des commandes à l'aide de la CLI du CMS, il interagira automatiquement avec votre sandbox, et réduira les déploiements de production accidentels. À ce stade, votre fichier de configuration ressemblera à quelque chose comme ceci :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: DEV\nportals:\n  - name: PROD\n    portalId: 123\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n  - name: DEV\n    portalId: 456\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant, lorsque vous exécutez des commandes dans le CMS CLI, comme ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload"
        })
      }), " si vous n'indiquez pas de portail, les fichiers seront téléchargés sur votre compte « DEV »."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configuration de votre éditeur de code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez utiliser votre éditeur de code préféré lorsque vous créez sur HubSpot, que vous préfériez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#vs-code",
        children: "VS Code"
      }), ", ou d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#autres-%C3%A9diteurs-de-code-et-ide",
        children: "autres éditeurs de code et IDE"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Code VS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un nombre important de développeurs travaillant sur HubSpot utilisent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://code.visualstudio.com/",
        children: "Visual Studio Code"
      }), ". Cela a inspiré l'extension HubSpot VS Code. L'extension ajoute des blocs de texte prédéfinis intellisense pratiques, la finalisation de code HubL, la mise en valeur de la syntaxe HubL et la mise en forme de HubL. Le projet est ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode",
        children: "open source"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/blob/master/CONTRIBUTING.md",
        children: "les contributions sont les bienvenues"
      }), ". Si vous avez des commentaires, veuillez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/issues",
        children: "déposer une plainte sur le référentiel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "c0ab15c7-27f7-4f4d-a884-5468e852577a",
      external: true,
      label: "Get VS Code Extension"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/vs%20code%20extension%20-%20hubl%20variable%20suggestion.gif",
        alt: "extension de code vs capture d'écran animée montrant la suggestion de variable hubl"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Autres éditeurs de code et IDE"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bien qu'il existe une extension officielle VS Code, il n'y a aucune raison pour que vous ne puissiez pas utiliser un autre éditeur préféré. HubL est le dérivé privé de HubSpot pour Jinjava, qui est basé sur Jinja. En raison des similitudes syntaxiques, les extensions de la mise en valeur syntaxique Jinja fonctionnent relativement bien. Les extensions et les outils complémentaires varient selon l'éditeur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Test"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe deux méthodes principales pour tester les modifications :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Test avec watch/upload :"
        }), " Lorsque vous travaillez dans votre environnement de développement, il est prudent d'utiliser la commande ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#watch",
          children: "consulter"
        }), " pour télécharger automatiquement les modifications lorsque vous enregistrez des fichiers dans votre éditeur de texte pour développer rapidement. Si vous utilisez l'outil « Aperçu en direct avec options d'affichage » du gestionnaire de conception pour un modèle, lorsque vous enregistrez les modifications, elles sont automatiquement reflétées dans le rendu de l'aperçu du modèle. Pour afficher l'aperçu en direct d'un modèle, sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aperçu > Aperçu en linge"
        }), " avec les options d'affichage dans l'éditeur de modèle du gestionnaire de conception."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Test local :"
        }), " pour prévisualiser vos modifications localement sans les télécharger sur le compte, vous pouvez exécuter la commande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs theme preview"
        }), " dans le répertoire racine du thème. Cette commande exécutera un serveur proxy local sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://hslocal.net:3000/",
          children: "https://hslocal.net:3000/"
        }), " que vous pourrez ensuite utiliser pour prévisualiser les modèles et les modules du thème. Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#locally-preview-theme",
          children: "commande hs theme preview"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Éditeur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un autre élément essentiel de la phase de développement consiste à tester vos modifications dans les outils de création de contenu. Si vous créez des modules ou des modèles destinés à être manipulés dans l'éditeur de contenu, créez des pages dans votre environnement de développement pour vous assurer que l'expérience d'édition de contenu est conforme à vos attentes. Faites glisser les modules dans des configurations bizarres et saisissez du contenu factice pour vous assurer que l'équipe marketing ne peut pas « casser » vos modules lorsqu'ils créent des pages. L'utilisation des éditeurs de contenu vous aidera à illustrer les principes fondateurs que vous souhaitez intégrer dans vos modèles et modules. Actuellement, il n'est pas possible de déplacer du contenu, tel que des pages ou des articles de blog, entre les comptes HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Aperçu du module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'éditeur de module du gestionnaire de conception, sélectionnez le bouton « Aperçu ». Il ouvrira un éditeur d'aperçu de la façon dont le module et ses champs se comporteront dans les éditeurs de contenu. Ce qui vous permet de tester les champs, les groupes et les répéteurs de votre module avec un contenu factice dans un environnement sûr."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/module-preview.gif",
        alt: "module preview"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Débogage"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Savoir comment déboguer et résoudre les problèmes de votre site web est essentiel à la santé et au succès de votre site. Familiarisez-vous avec les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting",
        children: "techniques de débogage lors du développement sur le HubSpot CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Sandboxes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comme indiqué ci-dessus dans la rubrique sur la configuration de votre environnement de développement, vous pouvez créer des comptes gratuits ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "Sandbox pour les développeurs CMS"
      }), " à utiliser pour les tests et comme environnement de développement sûr."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Déploiement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous avez testé vos modifications et que vous êtes prêt à les mettre en ligne, il est temps de déployer vos modifications sur votre portail de production. En fonction de votre configuration locale, vous devrez exécuter la commande Sandbox pour les développeurs CMS avec l'argument ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "--portal"
      }), " pour interagir avec votre compte de production, comme ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme/src my-theme --portal=PROD"
      }), ". Lorsque vous téléchargez des fichiers vers votre compte de production, faites attention s'il y a eu des erreurs à diagnostiquer, et assurez-vous de parcourir brièvement votre site web en ligne pour vous assurer qu'il n'y a pas eu de conséquences involontaires du déploiement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous travaillez au sein d'une équipe de développement web, il est recommandé d'avoir l'ensemble du centre unique d'informations de votre base de code de production dans le contrôle de version, et de le déployer sur votre portail de produit lorsque les changements sont fusionnés dans référence. Ainsi, votre équipe de développeurs peut utiliser votre système de contrôle de version préféré pour collaborer, suivre les modifications et annuler facilement les modifications."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la mise en place de l'intégration continue avec les référentiels git, suivez ce guide sur l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "utilisation des actions GitHub pour déployer vers votre compte de production lorsque des modifications sont fusionnées dans référence"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}