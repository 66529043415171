"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 176122437734;
const slug = exports.slug = 'guides/crm/public-apps/quickstart';
const title = exports.title = 'UI extensions for public apps quickstart';
const name = exports.name = 'UI extensions for public apps quickstart';
const metaDescription = exports.metaDescription = 'Learn how to build UI extensions for public apps.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "prerequisites",
  "label": "Prerequisites",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-set-up-your-local-environment",
  "label": "1. Set up your local environment",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-create-a-boilerplate-project",
  "label": "2. Create a boilerplate project",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-add-your-app-s-credentials-to-your-backend",
  "label": "3. Add your app's credentials to your backend",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-install-the-app-in-your-developer-test-account",
  "label": "4. Install the app in your developer test account",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-add-the-card-to-the-record-page",
  "label": "5. Add the card to the record page",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-run-local-development",
  "label": "6. Run local development",
  "parent": null
}, {
  "depth": 0,
  "id": "next-steps",
  "label": "Next steps",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      em: "em",
      a: "a",
      h1: "h1",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The features described in this guide are in Early Access beta, separate from the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "CRM development tools to build UI extensions with React as frontend"
        }), " beta for private app UI extension development."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/build-app-cards",
          children: "Request access to the UI extensions for public apps beta"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "UI extensions for public apps quickstart (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Follow this tutorial to create a UI extension for a public app based on a HubSpot-provided template, which includes an app card for contact records. If you're looking to migrate an existing public app to the projects framework, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/migrate-a-public-app-to-projects",
        children: "migration guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By the end of this tutorial, you will have:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Configured your local environment for project development"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Created a project and public app locally using HubSpot's boilerplate Get Started project"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Installed the public app in a test account"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Added the app card to contact records"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prerequisites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before getting started, ensure you've also set up the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sign up for the UI extensions for public apps beta."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install the latest version of the CLI by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm i -g @hubspot/cli@latest"
        }), ". The CLI will need to be version 6.1.0 or later."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-hubspot/developers",
          children: "developer account"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your developer account, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#create-a-developer-test-account",
          children: "create a test account"
        }), " that you can use to install and test the app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Because you'll be using OAuth, you'll need to set up a self-hosted backend environment to complete the OAuth process. For the purposes of this tutorial, it's recommended to set up the sample ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
          children: "OAuth Node.js app"
        }), ", which is configured to work with the example project you'll be creating."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Set up your local environment"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Because projects are developed locally, you'll first need to set up your local environment:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/download/",
          children: "Node.js"
        }), " which enables HubSpot’s local development tools. Versions 18 or higher are supported. It's recommended to use a package manager like ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://brew.sh/",
          children: "Homebrew"
        }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/nvm-sh/nvm",
          children: "nvm"
        }), " to install Node."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the terminal, use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cd"
        }), " command to navigate to the directory where you'll be storing your project, app, and extension files."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install the latest version of the CLI by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install -g @hubspot/cli@next"
        }), ". The CLI will need to be version ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "5.2.1-beta.5"
        }), " or later."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Connect the CLI to your developer account:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you haven't yet created a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot.config.yml"
            }), " file, run ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs init"
            }), ", then follow the prompts to initialize the HubSpot configuration file to connect the CLI to your developer account."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you've already set up the initial HubSpot configuration file (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot.config.yml"
            }), "), you can add your developer account to the list of authorized accounts by running ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs auth"
            }), ". Follow the prompts to generate a personal access key in your account, then copy and paste it into the terminal."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Create a boilerplate project"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To get started, HubSpot provides a boilerplate project template you can use by following the steps below."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your working directory, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project create"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project create\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " for the project, then confirm the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "local destination"
        }), " for the boilerplate project."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When prompted to select a project template, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM getting started project with public apps (BETA)"
        }), " template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["With the boilerplate project created, navigate into the new directory by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cd <project-directory>"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd <project-template-directory>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Upload the project to your developer account by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload"
        }), ". This will create a new public app in your developer account which you'll then continue to update as a part of this guide."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Add your app's credentials to your backend"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To find your app's client ID and secret:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Navigate to your developer account."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Manage apps"
        }), ", then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Get started with public apps"
        }), " app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Auth"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App credentials"
        }), ", copy your app's ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client ID"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client Secret"
        }), " and paste them into the environment configuration (e.g., entries in an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".env"
        }), " file) for your backend."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-auth-tab-credentials.png",
        alt: "public-app-auth-tab-credentials"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["This guide assumes you're using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
          children: "OAuth Node.js app"
        }), ", which is already configured to use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "http://localhost:3000/oauth-callback"
        }), " as the redirect URL."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Install the app in your developer test account"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left navigation bar, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), ", then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Get started with public apps"
        }), " app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Auth"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Next to ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sample install URL (OAuth)"
        }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy full URL"
        }), ", then open that URL in your browser."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Follow the prompts to authorize and install the app in your developer test account."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to your developer test account to confirm that it's been installed:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the left sidebar of your developer account, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Test accounts"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "name"
            }), " of the test account you installed the app in."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the top navigation bar, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Settings"
            }), " icon."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Integrations"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Connected apps."
            }), " You should see the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Get started with public apps"
            }), " app listed on the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Connected apps"
            }), " page."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Add the card to the record page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Get started"
      }), " app comes with an example app card for contact record pages. After installing the app in your developer test account, you'll need to configure the app card to appear on your record pages by following the steps below."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the account where you installed the app, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Settings"
          }), " icon in the main navigation bar."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar menu, under ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Data Management"
          }), ", navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Objects"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Contacts"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Record customization"
          }), " tab."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Default view"
          }), " to customize the default contact record view."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/record-customization-settings.png",
            alt: "record-customization-settings"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "+"
          }), " tab to create a new ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Custom"
          }), " tab. Then, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Custom"
          }), " tab and click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Add cards"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/custom-tab-add-cards.png",
            alt: "custom-tab-add-cards"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right panel, under ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Card types"
          }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Apps"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/add-app-card-to-middle-column-hello-public-ap.png",
            alt: "add-app-card-to-middle-column-hello-public-ap"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "checkbox"
          }), " next to the example extension that's part of your public app."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "X"
          }), " at the top of the right panel to close it."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Save and exit"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the card added to the contact record, navigate to a contact record to view the card:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contacts"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of a contact."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Custom"
        }), " tab, then view your card."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/custom-tab-card-appear.png",
        alt: "custom-tab-card-appear"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With the card displaying on the contact record, you can make updates to the app or extension and re-upload to your account using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project upload"
      }), ". However, for faster iteration, you can run a local development server, which will show your React file updates on save."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Run local development"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When building a UI extension, you can run a local development server to iterate and test functionality in real-time without needing to re-upload the project. The local development server is only available for the frontend portion of the app, and assumes the backend is either running locally or is hosted."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Your app must be uploaded to your development account for the server to work. The local development server will only pick up changes made to React files. For changes to configuration files, you'll need to use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project upload"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you should not use the production app for local development if it's installed in production accounts. Instead, work with a replica project to avoid making updates to the production app. You can clone an existing public app by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project clone-app"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To start a local development server:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["First install dependencies by navigating to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "src/app/extensions"
        }), " and running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd src/app/extensions\nnpm install\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project dev"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project dev\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "You'll then be prompted to select an existing develop test account or create a new one. For this tutorial, select the developer test account you previously used."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/cli-select-developer-test-account.png",
            alt: "cli-select-developer-test-account"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "If you select a developer account that doesn't have the app installed, the terminal will prompt you to install the app."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["The local development server will start, and can be ended by pressing ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "q"
          }), " in the terminal. In HubSpot, refresh the contact record page, which should update the card with a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Developing locally"
          }), " tag."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-developing-locally.png",
            alt: "public-app-developing-locally"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Changes to your React files should now get picked up automatically in the browser on save without needing to refresh the page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To test this out, open the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions/ExampleCard.jsx"
      }), " file and add the following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Text"
      }), " component above the rest of the components."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const Extension = () => {\n  return (\n    <>\n      <Text>\n        New text!\n      </Text>\n      <Text>\n        Congrats! You just deployed your first App card. What's next? Here are\n        some pointers to get you started:\n      </Text>\n      ...\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After saving your changes, the card will automatically pick up the new component and display the text."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you wanted to publish this change to your app, you would end the development server by pressing ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "q"
      }), ", then run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project upload"
      }), ". Because the app itself is stored in your app developer account, you’ll need to ensure you’re uploading to that account, not your test account. After your changes are built and deployed, you would see them reflected in the test account after refreshing the contact record page."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " changes made to your configuration files will not automatically get picked up by the local development server, and instead will need to be uploaded with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Next steps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Now that you’ve successfully created, uploaded, installed, and started local development for your UI extension, check out the following resources to learn more:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/fetching-data",
          children: "Fetching data for public apps"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/local-development",
          children: "Local development for UI extensions"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sdk",
          children: "UI extensions SDK reference"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/webhooks",
          children: "Subscribing to events with webhooks"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sample-extensions/overview",
          children: "HubSpot’s sample UI extensions"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview",
          children: "UI extension components"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}