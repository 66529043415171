"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921781;
const slug = exports.slug = 'guides/api/crm/search';
const title = exports.title = 'API du CRM | Recherche';
const name = exports.name = 'vNext Docs DP - Recherche';
const metaDescription = exports.metaDescription = "Les points de terminaison de recherche CRM permettent de rendre plus efficace l'obtention de données en permettant aux développeurs de filtrer, de trier et de rechercher tout type d'objet de CRM.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "effectuer-une-demande-de-recherche",
  "label": "Effectuer une demande de recherche",
  "parent": null
}, {
  "depth": 0,
  "id": "objets-et-interactions-de-crm-consultables",
  "label": "Objets et interactions de CRM consultables",
  "parent": null
}, {
  "depth": 1,
  "id": "objets",
  "label": "Objets",
  "parent": "objets-et-interactions-de-crm-consultables"
}, {
  "depth": 1,
  "id": "engagements",
  "label": "Engagements",
  "parent": "objets-et-interactions-de-crm-consultables"
}, {
  "depth": 0,
  "id": "recherchez-des-propri%C3%A9t%C3%A9s-par-d%C3%A9faut-interrogeables",
  "label": "Recherchez des propriétés par défaut interrogeables",
  "parent": null
}, {
  "depth": 0,
  "id": "filtrer-les-r%C3%A9sultats-de-la-recherche",
  "label": "Filtrer les résultats de la recherche",
  "parent": null
}, {
  "depth": 0,
  "id": "rechercher-dans-des-associations",
  "label": "Rechercher dans des associations",
  "parent": null
}, {
  "depth": 0,
  "id": "trier-les-r%C3%A9sultats-de-la-recherche",
  "label": "Trier les résultats de la recherche",
  "parent": null
}, {
  "depth": 0,
  "id": "pagination-des-r%C3%A9sultats",
  "label": "Pagination des résultats",
  "parent": null
}, {
  "depth": 0,
  "id": "limites",
  "label": "Limites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Recherche"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez les points de terminaison de recherche dans le CRM pour filtrer, trier et\nrechercher des objets, des fiches d'informations et des interactions dans votre CRM.\nPar exemple, utilisez les points de terminaison pour obtenir une liste de contacts\ndans votre compte ou une liste de toutes les transactions ouvertes. Pour utiliser\nces points de terminaison depuis une application, un périmètre d'accès CRM est obligatoire.\nReportez-vous à cette ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "liste de périmètres d'accès disponibles"
      }), "\npour savoir quels périmètres d'accès CRM granulaires peuvent être utilisés pour atteindre\nvotre objectif."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Effectuer une demande de recherche"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour rechercher dans votre CRM, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " au point de terminaison de recherche de l'objet. Les points de terminaison de recherche dans le CRM sont créés selon le format suivant : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/{object}/search"
      }), ". Dans le corps de la demande, vous inclurez des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#filter-search-results",
        children: "filtres"
      }), " pour affiner votre recherche par valeurs de propriété du CRM. Par exemple, le bloc de code ci-dessous récupérerait une liste de tous les contacts ayant une adresse e-mail d’entreprise spécifique."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /crm/v3/objects/contacts/search\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"email\",\n          \"operator\": \"CONTAINS_TOKEN\",\n          \"value\": \"*@hubspot.com\"\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque objet que vous recherchez comprendra un ensemble de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#crm-objects",
        children: "propriétés par défaut"
      }), " qui seront renvoyées. Par exemple, une recherche de contacts renverra ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdate"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastmodifieddate"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), ". Pour renvoyer un ensemble spécifique de propriétés, incluez un tableau ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " dans le corps de la requête."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /crm/v3/objects/companies/search\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"annualrevenue\",\n          \"operator\": \"GT\",\n          \"value\": \"10000000\"\n        }\n      ]\n    }\n  ],\n  \"properties\": [\"annualrevenue\", \"name\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " l’inclusion d’un tableau ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), " remplacera les propriétés renvoyées par défaut, vous devrez donc les indiquer dans le tableau si vous souhaitez les renvoyer."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objets et interactions de CRM consultables"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les tableaux ci-dessous contiennent les points de terminaison de recherche d'objets, les objets auxquels ils se réfèrent et les propriétés renvoyées par défaut."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Point de terminaison de recherche"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriétés renvoyées par défaut"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/carts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Paniers"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/companies/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entreprises"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "domain"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/contacts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "firstname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deals/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Transactions"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "dealname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "closedate,``pipeline"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealstage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deal_split/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Répartitions de transactions"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/discounts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Remises"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/feedback_submissions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Soumissions de feedback"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/fees/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Frais"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/invoices/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Factures"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/leads/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Leads"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/line_items/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lignes de produit"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/orders/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Commandes"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/commerce_payments/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Paiements"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/products/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Produits"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), " ,", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/quotes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Devis"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_expiration_date"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_public_url_key"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_status"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/subscriptions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abonnements (Commerce),"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/taxes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Taxes"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tickets/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_category"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_priority"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Engagements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le tableau ci-dessous contient les points de terminaison de recherche d'engagement, les engagements auxquels ils se réfèrent et les propriétés renvoyées par défaut."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Point de terminaison de recherche"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Interaction"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriétés renvoyées par défaut"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/calls/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Appels"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/emails/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-mails"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/meetings/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Réunions"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/notes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Remarques"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tasks/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tâches"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recherchez des propriétés par défaut interrogeables"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Recherchez toutes les propriétés de texte par défaut dans des fiches d'informations de l'objet spécifié pour trouver toutes les fiches d'informations ayant une valeur contenant la chaîne spécifiée. Par défaut, les résultats seront renvoyés selon la date de création des objets (les plus anciens en premier), mais vous pouvez remplacer cela grâce au ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#sorting",
        children: "tri"
      }), ". Par exemple, la demande ci-dessous recherches tous les contacts avec une valeur de propriété de texte par défaut contenant la lettre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"query\": \"x\"\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Voici les propriétés qui sont recherchées par défaut par la méthode ci-dessus :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Point de terminaison de recherche"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriétés renvoyées par défaut"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/calls/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Appels"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_body_preview"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/companies/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entreprises"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "website"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phone"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "domain"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/contacts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "firstname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phone"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_additional_emails"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fax"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mobilephone"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_marketable_until_renewal"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/{objectType}/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objets personnalisés"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : jusqu'à 20 propriétés sélectionnées."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deals/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Transactions"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "dealname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pipeline"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealstage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealtype"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/emails/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-mails"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_subject"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/feedback_submissions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Soumissions de feedback"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_submission_name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_content"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/meetings/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Réunions"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_body"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/notes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Notes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_note_body"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/products/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Produits"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), " ,", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/quotes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Devis"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_firstname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_lastname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_proposal_slug"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_company_name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_email"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_quote_number"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_public_url_key"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tasks/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tâches"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_task_body"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_task_subject"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tickets/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_category"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filtrer les résultats de la recherche"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez les filtres dans le corps de la demande pour limiter les résultats uniquement aux objets de CRM avec les valeurs de propriété correspondantes. Par exemple, la demande ci-dessous recherche tous les contacts avec le prénom ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Alice"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : lorsque vous filtrez les résultats de recherche pour les appels, les conversations, les e-mails, les réunions, les notes ou les tâches, la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_body_preview_html"
        }), " n’est pas prise en charge. Pour les e-mails, les propriétés ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_email_html"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_body_preview"
        }), " ne sont pas non plus prises en charge."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n\n  --data '{\n    \"filterGroups\":[\n      {\n        \"filters\":[\n          {\n            \"propertyName\": \"firstname\",\n            \"operator\": \"EQ\",\n            \"value\": \"Alice\"\n          }\n        ]\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour inclure plusieurs critères de filtre, vous pouvez regrouper les ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filters"
      }), " dans ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        })
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour appliquer une logique ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "AND"
        }), ", incluez une liste de conditions séparées par des virgules dans un ensemble de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour appliquer la logique ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "OR"
        }), ", incluez plusieurs ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroup"
        }), " dans un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), ". Vous pouvez inclure un maximum de cinq ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " avec jusqu’à 10 ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " dans chaque groupe, avec un maximum de 25 filtres au total. Si vous avez inclus trop de groupes ou de filtres, vous recevrez une réponse d’erreur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "VALIDATION_ERROR"
        }), ". Par exemple, la demande ci-dessous recherche des contacts avec le prénom ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Alice"
        }), " ET un nom de famille autre que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Smith"
        }), (0, _jsxRuntime.jsx)(_components.em, {
          children: ","
        }), " OR des contacts qui n'ont pas de valeur pour la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "email"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"firstname\",\n          \"operator\": \"EQ\",\n          \"value\": \"Alice\"\n        },\n        {\n          \"propertyName\": \"lastname\",\n          \"operator\": \"NEQ\",\n          \"value\": \"Smith\"\n        }\n      ]\n    },\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"email\",\n          \"operator\": \"NOT_HAS_PROPERTY\"\n        }\n      ]\n    }\n  ]\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez utiliser des opérateurs dans les filtres pour spécifier les fiches d'informations à renvoyer. Les valeurs dans les filtres sont insensibles à la casse, à deux exceptions près :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Lors du filtrage pour une propriété d’énumération, la recherche est sensible à la casse pour tous les opérateurs de filtre."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lors du filtrage pour une propriété de chaîne utilisant les opérateurs ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IN"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "NOT_IN"
        }), ", les valeurs recherchées doivent être en minuscules. Vous trouverez ci-dessous les opérateurs de filtre disponibles :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opérateur"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inférieure à la valeur indiquée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LTE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inférieure ou égale à la valeur indiquée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Supérieure à la valeur indiquée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GTE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Supérieure ou égale à la valeur indiquée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EQ"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Égale à la valeur indiquée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NEQ"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Différent de la valeur indiquée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BETWEEN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Dans la plage spécifiée. Dans votre demande, utilisez des paires (clé, valeur) pour définir ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "highValue"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            }), ". Consultez ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#between-operator",
              children: "l'exemple ci-dessous"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Inclus dans la liste spécifiée. Recherches effectuées par correspondance exacte. Dans votre demande, ajoutez les valeurs de liste dans un tableau ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            }), ". Lors de la recherche d’une chaîne propriété avec cet opérateur, les valeurs doivent être en minuscules. Consultez ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#in-operator",
              children: "l'exemple ci-dessous"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_IN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Non inclus dans la liste spécifiée. Dans votre demande, ajoutez les valeurs de liste dans un tableau ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            }), ". Lors de la recherche d’une chaîne propriété avec cet opérateur, les valeurs doivent être en minuscules."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A une valeur pour la propriété spécifiée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "N'a pas de valeur pour la propriété spécifiée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTAINS_TOKEN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contient un jeton. Dans votre demande, vous pouvez utiliser des caractères génériques (*) pour effectuer une recherche partielle. Par exemple, utilisez la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "*@hubspot.com"
            }), " pour récupérer des contacts avec une adresse e-mail HubSpot."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_CONTAINS_TOKEN"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ne contient pas de jeton."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À titre d'exemple supplémentaire, vous pouvez utiliser l'opérateur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BETWEEN"
      }), " pour rechercher toutes les tâches qui ont été modifiées pour la dernière fois dans une plage horaire spécifique :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/tasks/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n   \"filterGroups\":[{\n      \"filters\":[\n        {\n          \"propertyName\":\"hs_lastmodifieddate\",\n          \"operator\":\"BETWEEN\",\n          \"highValue\": \"1642672800000\",\n          \"value\":\"1579514400000\"\n        }\n      ]\n    }]\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour un autre exemple, vous pouvez utiliser l’opérateur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IN"
      }), " pour rechercher des entreprises pour lesquelles des valeurs indiquées ont été sélectionnées dans une propriété déroulante."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/companies/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"filterGroups\":[\n      {\n        \"filters\":[\n          {\n           \"propertyName\":\"enumeration_property\",\n           \"operator\":\"IN\",\n          \"values\": [\"value_1\", \"value_2\"]\n        }\n        ]\n      }\n    ],\n   \"properties\": [\"annualrevenue\", \"enumeration_property\", \"name\"]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rechercher dans des associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Recherchez des fiches d'informations associées à d'autres fiches d'informations spécifiques en utilisant la pseudo-propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations.{objectType}"
      }), ". Par exemple, la demande ci-dessous recherche tous les tickets associés à un contact dont l'ID de contact est ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/tickets/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"filters\": [\n      {\n        \"propertyName\": \"associations.contact\",\n        \"operator\": \"EQ\",\n        \"value\": \"123\"\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " L'option de recherche dans des associations d'objets personnalisés n'est actuellement pas prise en charge via des points de terminaison de recherche. Pour trouver des associations d'objets personnalisés, vous pouvez utiliser l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "API d'associations"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Trier les résultats de la recherche"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisez une règle de tri dans le corps de la demande pour lister les résultats dans l'ordre croissant ou décroissant. Une seule règle de tri peut être appliquée à une recherche. Par exemple, la demande ci-dessous trie les contacts renvoyés selon la plus récente création :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"sorts\": [\n      {\n        \"propertyName\": \"createdate\",\n        \"direction\": \"DESCENDING\"\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pagination des résultats"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par défaut, les points de terminaison de recherche renvoient des pages de 10 fiches d'informations. Cela peut être modifié en définissant le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "limit"
      }), " dans le corps de votre demande. Le nombre maximum d'objets pris en charge par page est de 100. Par exemple, la demande ci-dessous renverra des pages contenant 20 résultats chacune."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"limit\": 20\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour accéder à la page de résultats suivante, vous devez inclure le paramètre ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        })
      }), " fourni dans la propriété ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "paging.next.after"
        })
      }), " de la réponse précédente. Si la propriété ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "paging.next.after"
        })
      }), " n'est pas fournie, il n'y aura aucun résultat supplémentaire à afficher. Vous devez formater la valeur dans le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "after"
      }), " sous forme de nombre entier. Par exemple, la demande ci-dessous renverra la page de résultats suivante :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"after\": \"20\"\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "L'affichage d'objets CRM nouveaux ou mis à jour dans les résultats de recherche peut prendre quelques instants."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les objets de CRM archivés ne s'afficheront pas dans les résultats de recherche."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les points de terminaison de recherche sont ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "limités"
        }), " à quatre demandes par seconde."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Une demande peut contenir jusqu'à 3 000 caractères. Si le corps de votre demande dépasse 3 000 caractères, une erreur 400 sera renvoyée."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les points de terminaison de recherche sont limités à 10 000 résultats par requête. La tentative de pagination au-delà de 10 000 résultats entraînera une erreur 400."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lors du ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#filter-search-results",
          children: "filtrage"
        }), ", vous pouvez inclure un maximum de cinq ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " avec jusqu’à 10 ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " dans chaque groupe, avec un maximum de 25 filtres au total."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lors de la recherche de numéros de téléphone, HubSpot utilise des propriétés calculées spéciales pour uniformiser le format. Ces propriétés commencent toutes par ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_searchable_calculated_*"
        }), ". Dans le cadre de cette uniformisation, HubSpot utilise uniquement l'indicatif régional et le numéro local. Vous ne devez pas inclure le code pays dans vos critères de recherche ou de filtrage."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}