"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35219164725;
const slug = exports.slug = 'guides/cms/content/data-driven-content/emails-with-programmable-content';
const title = exports.title = 'E-Mails mit programmierbaren Inhalten erstellen';
const name = exports.name = 'E-Mails mit programmierbaren Inhalten erstellen';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "limits-f%C3%BCr-den-e-mail-versand",
  "label": "Limits für den E-Mail-Versand",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-ein-modul-mit-programmierbaren-e-mail-inhalten-erstellen",
  "label": "1. Ein Modul mit programmierbaren E-Mail-Inhalten erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "standardobjekte",
  "label": "Standardobjekte",
  "parent": "1.-ein-modul-mit-programmierbaren-e-mail-inhalten-erstellen"
}, {
  "depth": 1,
  "id": "benutzerdefinierte-objekte",
  "label": "Benutzerdefinierte Objekte",
  "parent": "1.-ein-modul-mit-programmierbaren-e-mail-inhalten-erstellen"
}, {
  "depth": 1,
  "id": "hubdb",
  "label": "HubDB",
  "parent": "1.-ein-modul-mit-programmierbaren-e-mail-inhalten-erstellen"
}, {
  "depth": 0,
  "id": "2.-das-modul-zu-einer-e-mail-hinzuf%C3%BCgen",
  "label": "2. Das Modul zu einer E-Mail hinzufügen",
  "parent": null
}, {
  "depth": 0,
  "id": "weitere-hubdb-fokussierte-tutorials",
  "label": "Weitere HubDB-fokussierte Tutorials",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-academy",
  "label": "HubSpot Academy",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "E-Mails mit programmierbaren Inhalten erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Diese Funktion steht derzeit in der Betaversion zur Verfügung. Durch die Nutzung dieser Funktionalität erklären Sie sich mit den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "Entwickler-Beta-Bedingungen"
        }), " einverstanden. Dieser Leitfaden bezieht sich auf Funktionen, die nur im Rahmen der Beta-Version verfügbar sind. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "Melden Sie sich in Ihren Vorlagen oder Modulen für die Beta-Version an."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwendung von programmierbaren Inhalten zur Personalisierung von E-Mails mit Daten aus Ihrem HubSpot-Account mithilfe von HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Datenquellen, die Sie in E-Mails mit programmierbaren Inhalten verwenden können, hängen von Ihrem HubSpot-Abonnement ab:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Mit einem ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), "-Abonnement können Sie Daten von Standard-CRM-Objekten wie Kontakten, Unternehmen und Produkten verwenden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Mit einem ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), "-Abonnement können Sie auch strukturierte Datenquellen wie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "HubDB-Tabellen"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "benutzerdefinierte Objekte"
        }), " verwenden. Diese Daten können nach den Kontakteigenschaften des Empfängers gefiltert werden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Auf einer Immobilien-Website könnten Interessenten beispielsweise ein Formular ausfüllen und ihre Ansprüche an Ihr Zuhause angeben. Die Formulareinsendung könnte dann einen Workflow auslösen, durch den Interessenten eine E-Mail mit Angeboten zugesendet wird, die passen könnten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limits für den E-Mail-Versand"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können die CRM-HubL-Funktionen", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), " in programmierbare E-Mail-Inhalte aufnehmen, aber jede E-Mail, die diese spezifischen Funktionen enthält, unterliegt den folgenden Beschränkungen, die von der Anzahl der Empfänger abhängen, an die Sie senden:"]
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "Empfänger insgesamt"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "Maximale Anzahl an CRM-HubL-Funktionen"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "500.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "250.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "165.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "125.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "100.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "5"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Versenden einer E-Mail, die eine der oben genannten Limit erreicht oder überschreitet, führt zu einer Verzögerung oder einem Abbruch des Versands Ihrer E-Mail."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zusätzlich zu den oben genannten Limits sollten Sie die unten aufgeführten zusätzlichen Vorbehalte beachten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie eine E-Mail mit programmierbaren Inhalten klonen, kann sie nicht gesendet werden, solange sich das Original noch in einem Bearbeitungszustand befindet. Sie sollten zwischen jedem Versenden einer E-Mail mindestens 1 Stunde warten."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie können keinen A/B-Test für eine programmierbare E-Mail durchführen, die eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        }), "-, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        }), "- oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        }), "-HubL-Funktion enthält."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Ein Modul mit programmierbaren E-Mail-Inhalten erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So erstellen Sie das E-Mail-Modul für den Zugriff auf Ihre HubDB- oder benutzerdefinierten Objektdaten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Dateien und Vorlagen"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Design-Manager"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie oben links auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Datei"
          }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Neue Datei"
          }), " aus."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wählen Sie im Dialogfeld ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Modul"
          }), " für den Dateityp aus und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Weiter"
          }), ". Aktivieren Sie dann das Kontrollkästchen ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "E-Mails"
          }), " und geben Sie einen ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Namen"
          }), " für die Datei ein."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Erstellen"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "So aktivieren Sie programmierbare E-Mail-Inhalte für das Modul:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Aktivieren Sie im Inspektor auf der rechten Seite den Schalter ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modul für programmierbare E-Mail-Inhalte (Beta) aktivieren"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/programmable-email-switch0.png",
            alt: "programmable-email-switch0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Sie können programmierbaren E-Mail-Inhalte auch für E-Mail-Code-Vorlage aktivieren, indem Sie ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "isEnabledForEmailV3Rendering: true"
          }), " am Anfang der Datei hinzufügen."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hs-fs/hubfs/Imported%20sitepage%20images/enable-design-manager-template-for-programmable-email.png?width=510&name=enable-design-manager-template-for-programmable-email.png",
            alt: "enable-design-manager-template-for-programmable-email"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit dem erstellten Modul fügen Sie dann Code hinzu, um auf Daten aus Ihrem CRM zuzugreifen. In den folgenden Beispielen wird veranschaulicht, wie Abfragen aus verschiedenen Datenquellen durchgeführt werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standardobjekte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können HubL-Funktionen wie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), " verwenden, um Daten von Standardobjekten in Ihrem Account wie Kontakten, Unternehmen oder Produkten abzufragen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der folgende Code verwendet die HubL-Funktion ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), ", um die Daten von einem Produkt mit der ID von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2444498793"
      }), " abzufragen und den Namen, die Beschreibung und den Preis darzustellen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set product = crm_object(\"product\", 2444498793, \"name,description,price\") %}\n\n<div>\n  <p>\n    <span>Name: </span>\n    <span>{{ product.name }}</span>\n  </p>\n  <p>\n    <span>Description: </span>\n    <span>{{ product.description }}</span>\n  </p>\n  <p>\n    <span>Price: </span>\n    <span>{{ product.price }}</span>\n  </p>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Benutzerdefinierte Objekte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie über einen ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        }), " Enterprise"]
      }), "-Account verfügen, können Sie Daten von einem benutzerdefiniertem Objekt abfragen, den Sie in Ihrem Account erstellt haben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der folgende Code ruft Daten von einem benutzerdefinierten Objekt mit dem Namen ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Eigenschaften"
      }), " ab und gibt dabei Werte (z. B. Standort, Preis) zurück, die in den Eigenschaften des benutzerdefiniertes Objekts gespeichert sind."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beachten Sie, dass im folgenden Beispiel der vollständig qualifizierte Name des benutzerdefiniertes Objekts als erstes Argument beim Aufrufen der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "HubL-Funktion"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm_objects"
      }), " verwendet wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Der vollständig qualifizierte Name beginnt mit der HubSpot-Account-ID (mit dem Präfix ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "), gefolgt von einem Unterstrich und dem kleingeschriebenen Pluralnamen des benutzerdefinierten Objekts (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie können den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), " eines Objekts abrufen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "CRM-Objekte-Schema-API"
        }), " durchführen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = crm_objects(\"p2990812_properties\", \"\", \"listing_name,location, price, address, type\") %}\n\n{% for home in real_estate_listings.results %}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn der Name Ihres benutzerdefinierten Objekts Bindestriche enthält (z. B. ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Mein-Benutzerdefiniertes-Objekt"
        }), "), kann die Eigenschaften ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " in einer E-Mail mit programmierbaren Inhalten dargestellt werden. Sie können das benutzerdefinierte Objekt ohne die Bindestriche ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-custom-objects",
          children: "direkt in Ihrem HubSpot-Account"
        }), " neu erstellen, oder Sie können die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "„Benutzerdefiniertes Objekt“-API"
        }), " verwenden."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die zurückgegebenen Daten für jeden Empfänger zu filtern, können Sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), "-Parameter hinzufügen, der die Ergebnisse nach den Kontakteigenschaften des Empfängers filtert. Zeigen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "vollständige Liste der Filteroptionen"
      }), " an."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&city=\"~contact.city\"&order=listing_name\" %}\n{% set real_estate_listings = crm_objects(\"p2990812_Property\", query, \"listing_name,location, price, address, type\") %}\n{% for home in real_estate_listings.results %}\n\n  ...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie einen ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "-Account haben, können Sie Daten aus einer HubDB-Tabelle in Ihrer E-Mail verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der folgende Code verwendet die HubL-Funktion ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_rows"
        })
      }), ", um alle Daten aus der Tabelle abzurufen. Dadurch werden alle Immobilien in der E-Mail aufgelistet, wobei die Details zu jeder Immobilie zusammen mit ihrem Bild ausgegeben werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = hubdb_table_rows(1234567) %}\n\n{% for home in real_estate_listings%}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image.url }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die zurückgegebenen Daten für jeden Empfänger zu filtern, können Sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), "-Parameter hinzufügen, der die Ergebnisse nach den angegebenen Kontakteigenschaften filtert. Zeigen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb#filter-returned-rows",
        children: "vollständige Liste der Filteroptionen"
      }), " an."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&persona=\"~contact.hs_persona.value~\"&order=listing_name\" %}\n\n{% for home in real_estate_listings %}\n\n\t...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Im obigen Beispiel wird auf die Kontakteigenschaft ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Budget max"
        }), " mit ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.budget_max"
        }), " verwiesen, während auf ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Persona"
        }), " mit ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.hs_persona.value"
        }), " verwiesen wird. Dies liegt daran, dass ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Persona"
        }), " eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/property-field-types-in-hubspot",
          children: "Enumerationseigenschaft"
        }), " ist, die einen zusätzlichen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".value"
        }), " benötigt, um den Wert der Eigenschaft zu analysieren, was bei anderen Eigenschaftstypen nicht der Fall ist."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Das Modul zu einer E-Mail hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn das Modul veröffentlicht ist, fügen Sie es nun zum Text der Drag-&-Drop-E-Mail hinzu."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigieren Sie in Ihrem HubSpot-Konto zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-Mail"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-Mail"
        }), " aus, die Sie erstellt haben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in der linken Seitenleiste unter ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Inhalte"
        }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mehr"
        }), ". Suchen Sie nach Ihrem Modul für programmierbare E-Mail-Inhalte und ziehen Sie es in den E-Mail-Text."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/drag-and-drop-email-editor-more-modules.png",
        alt: "drag-and-drop-email-editor-more-modules"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie die Modul eingerichtet haben, um Daten nach bestimmten Kontakteigenschaften zu filtern, wird die E-Mail-Vorschau leer angezeigt. Dies liegt daran, dass das E-Mail-Tool nicht für die Vorschau der E-Mail durch einen bestimmten Kontakt eingerichtet wurde."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So zeigen Sie in einer Vorschau an, wie die E-Mail für einen bestimmten Kontakt aussehen wird:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aktionen"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vorschau"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/email-actions-menu0.png",
        alt: "email-actions-menu0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im nächsten Bildschirm auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vorschau der Seite für einen bestimmten Kontakt anzeigen"
        }), " und wählen Sie dann einen ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kontakt"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/preview-email-as-specific-contact0.png",
        alt: "preview-email-as-specific-contact0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie sollten jetzt nur die für den Empfänger relevanten Daten sehen, gefiltert nach dessen Kontakteigenschaften."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/preview%20window.png",
        alt: "Screenshot der E-Mail-Vorschau"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn eine E-Mail-Vorlage oder ein E-Mail-Modul benutzerdefinierten Code mit nicht aufgelöstem HubL enthält, wird stattdessen ein Platzhalter-Modul angezeigt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/placeholder-module-in-programmable-email.png",
        alt: "placeholder-module-in-programmable-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Es ist wichtig, immer über Fallback-Daten zu verfügen, die gesendet werden können, falls es keine HubDB-Zeilen oder Datensätze für benutzerdefinierte Objekte gibt, die die von Ihnen festgelegten Kriterien erfüllen. Andernfalls erhält der Empfänger möglicherweise eine leere E-Mail."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Diese Beta-Version kann Probleme mit vorhandenen Vorlagen verursachen. Sie sollten unbedingt alle Änderungen gründlich testen, bevor Sie eine E-Mail veröffentlichen und live schalten."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Weitere HubDB-fokussierte Tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "Erstellen von dynamischen Seiten mit HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Zusammenführen mehrerer HubDB-Tabellen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Erstellen einer dynamischen Seite für Teammitglieder mithilfe von HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Erstellen von dynamischen Seiten mit mehreren Ebenen mithilfe von HubDB"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot Academy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/other_data_sources_hubdb_and_custom_objects",
          children: "Verwenden von HubDB und benutzerdefinierten Objekten in CMS Hub"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}