"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501377957;
const slug = exports.slug = 'reference/api/deprecated/overview';
const title = exports.title = 'API de HubSpot | API obsoletas';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Deprecated APIs';
const metaDescription = exports.metaDescription = 'Una lista de las API de HubSpot obsoletas';
const toc = exports.toc = [{
  "depth": 0,
  "id": "api-eliminadas",
  "label": "API eliminadas",
  "parent": null
}, {
  "depth": 0,
  "id": "api-obsoletas",
  "label": "API obsoletas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API eliminadas y obsoletas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las API que se indican en las secciones a continuación ", (0, _jsxRuntime.jsx)("u", {
        children: "no"
      }), " recibirán futuras actualizaciones de la funcionalidad y, en algunos casos, se eliminarán por completo y no estarán disponibles para su uso en tus integraciones."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es recomendable que te suscribas al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Changelog de desarrollador de HubSpot"
      }), " para seguir las últimas actualizaciones, cambios de última hora y otros cambios significativos en la funcionalidad."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API eliminadas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las siguientes API se han eliminado por completo y devolverán un error al realizar una llamada a sus puntos de terminación asociados:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Fecha de puesta de sol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Notas"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Rendimiento del CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "9 de abril de 2024"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para los datos de análisis del sitio web, utiliza la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/reporting",
              children: "API de Analytics"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ecommerce Bridge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28 de febrero de 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si creaste una integración utilizando estas API, puedes seguir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "esta guía de migración"
            }), " para cambiar tu integración a aplicaciones privadas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Extensión de contabilidad"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28 de febrero de 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si creaste una integración utilizando estas API, puedes seguir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration",
              children: "esta guía de migración"
            }), " para cambiar tu integración a aplicaciones privadas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Calendario de marketing"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "31 de agosto de 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Puedes seguir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/campaigns/use-your-marketing-calendar",
              children: "utilizando el calendario de marketing en HubSpot"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API obsoletas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los puntos de terminación preexistentes que se indican a continuación ", (0, _jsxRuntime.jsx)("u", {
        children: "no"
      }), " recibirán una actualización de versión. Estos puntos finales son funcionales y estables, pero no se actualizarán más allá de su versión actual. HubSpot seguirá ofreciendo asistencia técnica para el futuro previsible y anunciaremos cualquier cambio venidero con un aviso detallado en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Changelog de desarrollador"
      }), " de HubSpot.", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Notas"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Redes sociales"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Incluye los siguientes puntos de terminación:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-publishing-channels",
                  children: "Obtener canales de publicación"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
                  children: "Obtener mensajes de transmisión"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
                  children: "Recibir un mensaje de transmisión"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message",
                  children: "Crear un mensaje de transmisión"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message",
                  children: "Cancelar un mensaje de transmisión"
                })
              })]
            })]
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}