"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 49334172328;
const slug = exports.slug = 'guides/cms/content/fields/best-practices';
const title = exports.title = 'Meilleures pratiques pour le champ Module et thème';
const name = exports.name = 'Meilleures pratiques pour le champ Module et thème';
const metaDescription = exports.metaDescription = 'La possibilité de créer et de regrouper des champs pose un problème d’expérience utilisateur (UX). Nous vous encourageons à utiliser cette page afin d’offrir une expérience intuitive aux créateurs de contenu.';
const featuredImage = exports.featuredImage = 'https://www.hubspot.com/hubfs/options%20and%20style%20tabs.png';
const featuredImageAltText = exports.featuredImageAltText = 'Onglet Champs de style du module dans l’éditeur de page du CMS Hub';
const toc = exports.toc = [{
  "depth": 0,
  "id": "comparaison-des-champs-de-style-et-des-champs-de-contenu",
  "label": "Comparaison des champs de style et des champs de contenu",
  "parent": null
}, {
  "depth": 0,
  "id": "organisation-des-champs",
  "label": "Organisation des champs",
  "parent": null
}, {
  "depth": 1,
  "id": "exemple",
  "label": "Exemple",
  "parent": "organisation-des-champs"
}, {
  "depth": 0,
  "id": "champs-obligatoires",
  "label": "Champs obligatoires",
  "parent": null
}, {
  "depth": 0,
  "id": "typographie",
  "label": "Typographie",
  "parent": null
}, {
  "depth": 0,
  "id": "comparaison-menu-activer%2Fd%C3%A9sactiver-et-case-%C3%A0-cocher-dans-les-champs-bool%C3%A9ens",
  "label": "Comparaison menu Activer/Désactiver et case à cocher dans les champs booléens",
  "parent": null
}, {
  "depth": 0,
  "id": "articles-connexes",
  "label": "Articles connexes",
  "parent": null
}, {
  "depth": 2,
  "id": "tutoriels",
  "label": "Tutoriels",
  "parent": "articles-connexes"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      h2: "h2",
      a: "a",
      em: "em",
      img: "img",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Meilleures pratiques pour les champs des modules et des thèmes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de l’incorporation de champs dans votre module ou votre thème, il est conseillé d’observer quelques bonnes pratiques, notamment :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Regroupement de champs"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Organisation logique des champs entre les modules"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Offrir une expérience de style cohérente avec les champs de style"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cet article présente quelques meilleures pratiques recommandées pour créer une expérience efficace et cohérente de modification de champs de modules et de thèmes pour les créateurs de contenu."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comparaison des champs de style et des champs de contenu"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ajouter des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "champs de style"
      }), " à la fois aux modules et aux thèmes, et ils permettent au créateur de contenu d’ajuster l’apparence du module, comme les bordures ou les images d’arrière-plan derrière le texte. Les champs de style ne doivent pas communiquer de sens ni être nécessaires à la compréhension du contenu de la page. Par exemple, si vous avez un module de texte et d’image et que l’image est nécessaire pour comprendre le contenu du texte, l’image doit être un champ de contenu plutôt qu’un champ de style."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu’elles sont ajoutées à un module, les options de style apparaissent dans l’onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Styles"
      }), " de l’éditeur de page. Lorsqu’elles sont ajoutées à un thème, les options de style s'affichent dans la barre latérale gauche de l’éditeur de thème."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/style-field-module-editor0.png",
        alt: "style-field-module-editor0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Gardez l’accessibilité à l’esprit lorsque vous décidez entre l’utilisation d’une image ou d’un champ d’image d’arrière-plan. Si le créateur de contenu doit avoir la possibilité d’ajouter du texte alternatif, utilisez un champ d’image plutôt qu’un champ d’image d’arrière-plan."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Organisation des champs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La façon dont vous organisez les champs joue un rôle important dans la capacité du créateur de contenu à apporter rapidement des modifications. Voici les meilleures pratiques recommandées pour l’organisation des champs :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Regroupez les champs de style en fonction du composant qu’ils contrôlent plutôt que de l’élément stylistique."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Ne laissez que les champs les plus élevés et les plus importants en dehors des groupes."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Privilégiez la création d’un groupe de composants plutôt que de groupes non imbriqués. Si vous avez besoin d’ajouter des fonctionnalités à votre module ultérieurement, vous ne pouvez pas déplacer vos modules vers un groupe ultérieurement sans mettre à jour manuellement toutes les instances du module sur les pages."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Classer les groupes de champs dans l’ordre dans lequel les composants apparaissent en fonction de la langue principale de la majorité des créateurs de contenu qui seront chargés de la maintenance du site. Exemple : l’anglais se lit de gauche à droite, de haut en bas. Si les utilisateurs du site lisent généralement de droite à gauche dans leur langue, vous devez le fournir dans cet ordre. En cas de doute, basez-vous sur la langue principale du contenu de votre site."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#field-groups",
        children: "groupes de champs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemple"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Voici un exemple de module de carte."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/card%20and%20style%20settings.png",
        alt: "Une disposition de carte typique contenant une icône, du texte et un bouton. Les options de style sont présentées groupées sur la droite en catégories Icône, Bouton et Carte."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le panneau des styles regroupe les champs du module en 3 groupes en fonction des composants de la carte qui peuvent être stylisés."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Icône"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Bouton"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Carte"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de l’affichage de la carte, on voit d’abord l’icône, puis le texte et le bouton. Le groupe d’icônes et ses options de style s'affichent avant le groupe de boutons et ses options de style. Par conséquent, le champ de couleur de l’icône se trouve dans le groupe ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Icône"
      }), ", tandis que le champ de couleur de la couleur d’arrière-plan du bouton se trouve dans le groupe ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Bouton"
      }), " ."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Champs obligatoires"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les champs obligatoires sont des champs que le créateur de contenu doit définir afin d’afficher le module et de publier la page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "N’exigez que les champs aient une valeur uniquement si cela casse le module pour qu’il n’ait pas de valeur."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous devez disposer d’un champ obligatoire, indiquez une valeur par défaut si possible."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, vous créez un module de carrousel d’images qui vous permet de configurer le nombre de diapositives à afficher en même temps. Une valeur minimale de 1 est nécessaire, et sans valeur, vous ne savez pas comment afficher le carrousel d’images. Il s’agit d’une situation où il peut être logique d’exiger une valeur mais d’en définir une ou deux."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Typographie"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Étant donné que les champs de texte enrichi offrent un meilleur contrôle typographique, il est recommandé d’utiliser des champs de texte enrichi plutôt qu’une combinaison de champ de texte et de champ de police lorsque cela est possible."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans certains cas, vous devrez peut-être fournir des styles typographiques qui s’appliquent à plusieurs éléments de contenu dans le même module, tels qu’un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#text",
        children: "champ de texte"
      }), " destiné aux en-têtes. Dans ce cas, vous pourrez peut-être rendre le travail du créateur de contenu plus efficace en fournissant des champs de style de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#font",
        children: "police"
      }), " et de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#color",
        children: "couleur"
      }), " dans le champ de texte."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous incluez des champs de texte enrichi, il est recommandé d’autoriser les contrôles typographiques du champ à remplacer les champs de style ajoutés manuellement. Si un champ de style contrôle un champ de texte enrichi, il peut être utile de définir un texte d’aide, afin de s’assurer que le créateur de contenu en est conscient."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comparaison menu Activer/Désactiver et case à cocher dans les champs booléens"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous incluez un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#boolean",
        children: "champ booléen"
      }), ", vous pouvez le configurer pour qu’il s’affiche sous la forme d’un interrupteur ou d’une case à cocher."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Définissez le booléen sur un interrupteur lorsque le champ contrôle une option de conception ou de mise en page majeure. Par exemple, la conversion de la disposition d’une carte d’une orientation verticale à une orientation horizontale."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Définissez le booléen sur une case à cocher lorsque le changement est moins drastique. Par exemple, masquer ou afficher la date de publication d’un article de blog dans un module d’articles de blog en vignette."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Articles connexes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields",
          children: "Types de champ pour les modules et les thèmes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Présentation des modules et des champs de thème"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Vue d'ensemble des modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview",
          children: "Vue d'ensemble des thèmes"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Tutoriels"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Premiers pas avec les modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Premiers pas avec les thèmes"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}