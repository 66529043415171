"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694131;
const slug = exports.slug = 'guides/cms/content/themes/hubspot-cms-boilerplate';
const title = exports.title = 'HubSpot CMSボイラープレート';
const name = exports.name = '[新規] HubSpot CMSボイラープレート';
const metaDescription = exports.metaDescription = 'HubSpot CMSボイラープレートを使用して、HubSpot CMSプラットフォームを活用するためのベストプラクティスを理解することができます。開発者はボイラープレートを利用することで、HubSpot CMS上で迅速にウェブサイトを構築できます。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E9%96%8B%E7%99%BA%E3%81%ABhubspot-cms%E3%83%9C%E3%82%A4%E3%83%A9%E3%83%BC%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E7%90%86%E7%94%B1",
  "label": "開発にHubSpot CMSボイラープレートを使用する理由",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-cms%E3%83%9C%E3%82%A4%E3%83%A9%E3%83%BC%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E4%BD%BF%E3%81%84%E6%96%B9",
  "label": "HubSpot CMSボイラープレートの使い方",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-cms%E3%83%9C%E3%82%A4%E3%83%A9%E3%83%BC%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E6%A7%8B%E6%88%90",
  "label": "HubSpot CMSボイラープレートの構成",
  "parent": null
}, {
  "depth": 0,
  "id": "jquery",
  "label": "jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%83%AA%E3%82%BD%E3%83%BC%E3%82%B9",
  "label": "関連リソース",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot CMSボイラープレート"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 9,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "HubSpot CMSボイラープレート"
            }), "を出発点として利用すれば、開発者はCMS Hub上でウェブサイトを短時間で稼働できるだけでなく、HubSpot CMSプラットフォームで適用できるベストプラクティスを理解できます。ボイラープレートはオープンソースのGitHubプロジェクトです。誰でも変更を提案したり、フォークして用途に合わせてカスタマイズしたりできます。CMS Hubをこれから利用する場合、ボイラープレートを出発点に新しいプロジェクトを開始するには、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/quickstart",
              children: "CMS Hub上での開発手順を説明しているクイック スタート ガイド"
            }), "に従ってください。"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 3,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/subscription",
              children: "Watch"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/fork",
              children: "Fork"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "Star"
            })]
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "開発にHubSpot CMSボイラープレートを使用する理由"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot CMSボイラープレートは、HubSpotによって作成され、積極的に更新が行われています。HubSpotはこのボイラープレートを作成する際に、開発者が最良のウェブサイト構築手法を確立する上で実践してきた数々のベストプラクティスを導入し、HutSpot CMSでのウェブサイトの構築に適用してきました。その結果、簡潔で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "パフォーマンスに優れ"
      }), "、柔軟な変更も可能なウェブサイトを開発者が構築できるようになり、開発時間も大幅に短縮されています。ボイラープレートでは、フォーム、メニューモジュール、基底クラスといった、HubSpot関連のアセットに対する包括的なCSSも提供されます。ボイラープレートが実装されたデモ環境は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/",
        children: "https://boilerplate.hubspotcms.com/"
      }), "でご覧になれます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot CMSボイラープレートの使い方"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカル開発環境でボイラープレートを使用するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "CMS Hub上でのウェブサイト開発スタートガイド"
      }), "の手順に従って簡単に行えます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot CMSボイラープレートの構成"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["開発者は", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "ローカル開発ツール"
            }), "を使用してボイラープレートを操作することも、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cos-general/get-started-with-the-design-manager",
              children: "デザインマネージャー"
            }), "で直接作業することもできます。ボイラープレートでは、全てのアセットを相対パスで参照するため、あらゆるプロジェクトへの調整も容易です。相対パスで参照することから、HubSpotプラットフォーム上のどのアカウント間でもボイラープレートを移植できます。下のスクリーンショットは、ボイラープレートのアセットのフォルダー構造を示しています。ボイラープレートのアセットは複数のディレクトリーとしてまとめられるので、アセットがどこにあるのかを簡単に識別できます。"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844633478"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/boilerplate/boilerplate-structure-tree-view-4.png",
        alt: "HubSpot CMSボイラープレートのフォルダー構造"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot CMSボイラープレートの基礎となるテンプレート構造は、［templates］>［layouts］フォルダーに格納されている", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "共通のベーステンプレート"
      }), "を中心に展開します。つまり、このベーステンプレートで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% extends %}"
      }), "タグを使用して、メインコンテンツの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% block body %}"
      }), "ブロックを参照します。extendタグとブロックの使用例は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "templatesディレクトリーに格納されているいずれかのhtmlファイル"
      }), "内で確認できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#blocks-and-extends",
        children: "ブロックと拡張"
      }), "の詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このようにCMSシステムでの開発では、ベーステンプレート（メイン／親テンプレートとも呼ばれます）にサイト上のコンテンツを構成する主要な共通要素を含める方法が一般的です。共通の構成要素は通常、ウェブサイトに設定されている", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), "要素に含まれる項目です。項目の例としては、共通メタプロパティー（タイトル、メタディスクリプションなど）、ファビコンのリンク、CSSのリンク、サードパーティーのスクリプトなどがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n <head>\n   <meta charset=\"utf-8\">\n   <title>{{ page_meta.html_title }}</title>\n   {% if site_settings.favicon_src %}<link rel=\"shortcut icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n   <meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n   {{ require_css(get_asset_url(\"../../css/layout.css\")) }}\n   {{ require_css(get_asset_url(\"../../css/main.css\")) }}\n   {{ require_css(\"https://fonts.googleapis.com/css?family=Merriweather:400,700|Lato:400,700&display=swap\") }}\n   {{ require_js(get_asset_url(\"../../js/main.js\")) }}\n   {{ standard_header_includes }}\n </head>\n <body>\n   <div class=\"body-wrapper {{ builtin_body_classes }}\">\n     {% block header %}\n       {% global_partial path=\"../partials/header.html\" %}\n     {% endblock header %}\n\n     {% block body %}\n       <!-- Nothing to see here -->\n     {% endblock body %}\n\n     {% global_partial path=\"../partials/footer.html\" %}\n   </div>\n   {{ standard_footer_includes }}\n </body>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このベースレイアウト内には、ヘッダーやフッターなどのグローバルパーシャルも含まれるため、これらのパーシャルのコードを専用のファイルに保持してモジュール方式で開発できます。さらに、これらはグローバルパーシャルなので、制作担当者も", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "グローバル コンテンツ エディター"
      }), "を使って簡単に編集できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ボイラープレートに含まれるアセットの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/wiki",
        children: "GitHubのボイラープレートのwiki"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**HubSpotテーマボイラープレートは、jQueryがなくても機能します。**古いHubSpotアカウントでは、既定でjQueryが読み込まれます。新しいHubSpotアカウントでは、jQueryは既定で無効になっています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "従来、HubSpotスクリプトの正常な動作にはjQueryが必要で、そのためドメイン全体の設定があり、互換性が確保されていました。現在のHubSpotスクリプトにjQueryは使用されていません。jQueryが必須ではないため、開発者はソース管理にも使用できるライブラリーを適切な方法で組み込むことができます。新しいウェブサイトの場合は、jQuery設定を無効にすることをお勧めします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "既存のウェブサイトがあるドメインのjQueryを無効にした場合、jQueryに依存している - ランディングページまたは既存のウェブページが機能しなくなる恐れがあることに注意してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**新しいウェブサイトでjQueryを使用する場合は、最新バージョンのjQueryを使用することをお勧めします。**これには次の2つの簡単な方法があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["最新バージョンのjQueryをデベロッパー ファイル システムにアップロードし、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), "を使用することで、jQueryを必要に応じて読み込みます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["信頼できるCDNを採用し、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), "を使用することで、jQueryを必要に応じて読み込みます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連リソース"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "テーマを使ってみる"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "CMS Hubで構築したウェブサイトのパフォーマンスの最適化"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "アクセシビリティーについて"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}