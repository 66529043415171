"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601073957;
const slug = exports.slug = 'getting-started/tools-to-help-you-build';
const title = exports.title = 'Herramientas para ayudarte a crear';
const name = exports.name = 'Herramientas para ayudarte a crear (documentos para desarrolladores 2024)';
const metaDescription = exports.metaDescription = 'Descubre las distintas herramientas y recursos disponibles para ayudarte a crear contenido en HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cli-de-hubspot",
  "label": "CLI de HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "extensi%C3%B3n-hubspot-vs-code",
  "label": "Extensión HubSpot VS Code",
  "parent": null
}, {
  "depth": 0,
  "id": "biblioteca-de-clientes",
  "label": "Biblioteca de clientes",
  "parent": null
}, {
  "depth": 0,
  "id": "postman",
  "label": "Postman",
  "parent": null
}, {
  "depth": 0,
  "id": "proyectos-beta",
  "label": "Proyectos (BETA)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Herramientas para ayudarte a crear"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot proporciona una serie de herramientas para ayudarte a crear componentes en su plataforma para desarrolladores. Las API de HubSpot son flexibles y siempre puedes utilizar tu entorno de desarrollo preferido para crear una integración, pero las herramientas que se enumeran a continuación están a disposición de todos los desarrolladores para mejorar la productividad y ayudar a agilizar tu workflow de desarrollo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CLI de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si estás usando el CMS de HubSpot como base, puedes instalar la CLI de HubSpot para construir en HubSpot localmente. Una vez instalado, puedes utilizar las herramientas que prefieras en el entorno que prefieras. También puedes configurar la integración continua con una cuenta de GitHub para realizar un seguimiento de los cambios y ayudarte a colaborar con otros desarrolladores de tu organización."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aprende a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "instalar el CLI de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Extensión HubSpot VS Code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La extensión HubSpot Visual Studio Code te permite centralizar tu workflow de desarrollo sin tener que salir de VS Code. Una vez instalado, puedes utilizar directamente la CLI de HubSpot para autenticar y administrar tus cuentas conectadas, así como aprovechar el resaltado de sintaxis de HubL y la asistencia para autocompletar. También puedes sincronizar archivos con el administrador de diseño ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager"
      }), " de tu cuenta predeterminada de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aprende a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
        children: "instalar y utilizar la Extensión de VS Code de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Biblioteca de clientes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot proporciona bibliotecas de clientes para que puedas utilizar tu lenguaje de programación preferido cuando utilices las API de HubSpot. Las bibliotecas de clientes están disponibles en JavaScript, Ruby, Python y PHP. Cada biblioteca de clientes te permite invocar llamadas a funciones para leer, editar, crear y eliminar datos de tu cuenta de HubSpot, en lugar de tener que elaborar solicitudes API manualmente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre la configuración y el uso de las bibliotecas de cliente ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "Node"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-php",
        children: "PHP"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-ruby",
        children: "Ruby"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-python",
        children: "Python"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Postman"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Postman es un cliente API con numerosas características que puedes utilizar para realizar fácilmente solicitudes autenticadas a HubSpot. También puedes explorar colecciones curadas de todos los puntos de terminación de la API pública de HubSpot. HubSpot mantiene regularmente estas colecciones para mantener actualizadas las especificaciones de todos los campos de solicitudes y de respuesta."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Descarga el cliente ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/",
        children: "Postman"
      }), ", luego navega por las colecciones oficiales ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/hubspot",
        children: "HubSpot Postman"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Proyectos (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los proyectos son un contenedor de alto nivel que puedes utilizar para personalizar HubSpot CRM. Junto con la CLI de HubSpot, puedes crear y desplegar aplicaciones privadas, crear extensiones de interfaz de usuario y desarrollar funciones sin servidor para leer o escribir datos dinámicamente en componentes personalizados del CRM. Una vez desplegado, puedes gestionar tu proyecto dentro de HubSpot, lo que te permite revisar el historial de creación y monitorizar las llamadas a la API."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project",
        children: "crear un proyecto desde cero"
      }), ", o seguir la guía de inicio rápido de las extensiones de interfaz de usuario ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/quickstart"
      }), ". Si no estás seguro de por dónde empezar, también puedes consultar los proyectos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "muestra de HubSpot"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}