"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 157857115036;
const slug = exports.slug = 'guides/crm/ui-extensions/sdk';
const title = exports.title = 'UI extensions SDK (BETA)';
const name = exports.name = 'UI extensions SDK (BETA)';
const metaDescription = exports.metaDescription = 'Learn about the different functionalities and methods available in the UI extensions SDK.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "registering-the-extension",
  "label": "Registering the extension",
  "parent": null
}, {
  "depth": 1,
  "id": "runserverlessfunction",
  "label": "runServerlessFunction",
  "parent": "registering-the-extension"
}, {
  "depth": 0,
  "id": "display-alert-banners",
  "label": "Display alert banners",
  "parent": null
}, {
  "depth": 0,
  "id": "fetch-crm-property-data",
  "label": "Fetch CRM property data",
  "parent": null
}, {
  "depth": 1,
  "id": "fetchcrmobjectproperties",
  "label": "fetchCrmObjectProperties",
  "parent": "fetch-crm-property-data"
}, {
  "depth": 0,
  "id": "refresh-properties-on-the-crm-record",
  "label": "Refresh properties on the CRM record",
  "parent": null
}, {
  "depth": 0,
  "id": "listen-for-property-updates",
  "label": "Listen for property updates",
  "parent": null
}, {
  "depth": 1,
  "id": "error-handling",
  "label": "Error handling",
  "parent": "listen-for-property-updates"
}, {
  "depth": 0,
  "id": "open-an-overlay",
  "label": "Open an overlay",
  "parent": null
}, {
  "depth": 1,
  "id": "panel-overlay",
  "label": "Panel overlay",
  "parent": "open-an-overlay"
}, {
  "depth": 1,
  "id": "modal-overlay",
  "label": "Modal overlay",
  "parent": "open-an-overlay"
}, {
  "depth": 0,
  "id": "open-an-iframe-in-a-modal",
  "label": "Open an iframe in a modal",
  "parent": null
}, {
  "depth": 0,
  "id": "copy-text-to-clipboard",
  "label": "Copy text to clipboard",
  "parent": null
}, {
  "depth": 0,
  "id": "upload-files",
  "label": "Upload files",
  "parent": null
}, {
  "depth": 0,
  "id": "fetch-account%2C-user%2C-and-extension-context",
  "label": "Fetch account, user, and extension context",
  "parent": null
}, {
  "depth": 0,
  "id": "send-custom-log-messages-for-debugging",
  "label": "Send custom log messages for debugging",
  "parent": null
}, {
  "depth": 1,
  "id": "notes-and-limitations",
  "label": "Notes and limitations",
  "parent": "send-custom-log-messages-for-debugging"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      h3: "h3",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "UI extensions SDK (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The UI extensions SDK is the foundation for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "building UI extensions in HubSpot"
      }), ", containing an assortment of methods, functionalities, tools, and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview",
        children: "UI components"
      }), " to customize your extensions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about the utilities available in the SDK along with usage examples and boilerplate code."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Registering the extension"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["UI extensions, like any React front-end, are written as React components. However, unlike usual react components, you must register them with HubSpot by including ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.extend()"
      }), " inside the component file instead of exporting them. This is not required when you create a sub-component. For better code, reuse and include them inside your extension."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.extend()"
      }), " function receives the following arguments:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "context"
        }), ": the context object that includes information about the account and user."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "runServerlessFunction"
        }), ": the serverless functions to run in the component (private apps only)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "actions"
        }), ": the actions to include in the component."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you followed the steps above to create your UI extension from scratch, you'll have already copied the code below into your React front-end ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Example.jsx"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hubspot.extend(({ context, runServerlessFunction, actions }) => (\n  <HelloWorld\n    context={context}\n    runServerless={runServerlessFunction}\n    onAlertClick={actions.addAlert}\n  />\n));\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "runServerlessFunction"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For UI extensions powered by a private app, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "runServerlessFunction"
      }), " argument enables the front-end React code to run a serverless function defined within the project. It can receive the following parameters:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": the name of the serverless function to execute."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "propertiesToSend"
        }), ": an array of properties to send the serverless function from the front-end. Property values are pulled from the currently displaying CRM record. This enables retrieving property values on the server-side when the function is called. You can also use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#fetch-crm-property-data",
          children: "fetchFromObejctProperties"
        }), " to fetch property data on the client-side when the extension loads."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "parameters"
        }), ": additional parameters to be supplied to the function."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To access these parameter values, you'll include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.<parameterName>"
      }), " in the serverless function JavaScript file. For example, if you're passing ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertiesToSend"
      }), " from the front-end, you'll access them in the serverless function file with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.propertiesToSend"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the example function below displays the currently displaying contact record's first and last name in an alert on button click. To facilitate this, the React code sends the property data via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertiesToSend"
      }), ", which the serverless function receives and accesses using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.propertiesToSend"
      }), ". In addition, the extension includes an input field where the user can enter custom text that gets sent to the serverless function via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "parameters"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "// Example React front-end file\n\nimport React, { useState } from \"react\";\nimport {\n  Button,\n  Input,\n  hubspot,\n  Text,\n  Flex\n} from \"@hubspot/ui-extensions\";\n\n// Define the extension to be run within the Hubspot CRM\nhubspot.extend(({ context, runServerlessFunction, actions }) => (\n  <Extension\n    context={context}\n    runServerless={runServerlessFunction}\n    sendAlert={actions.addAlert}\n  />\n));\n\n// Define the Extension component, taking in runServerless, context, & sendAlert as props\nconst Extension = ({ context, runServerless, sendAlert }) => {\n  const [text, setText] = useState(\"\");\n\n  // Call serverless function to execute with parameters.\n  // The `myFunc` function name is configured inside `serverless.json`\n  const handleClick = async () => {\n    const { response } = await runServerless({ name: \"myFunc\", parameters: { text: text }, propertiesToSend: [\"firstname\", \"lastname\", \"hs_object_id\"] });\n    sendAlert({ message: response});\n  };\n\n  return (\n    <>\n      <Text>\n        Click the button to see the current contact's name in an alert, along with custom text entered below.\n      </Text>\n      <Flex direction=\"row\" align=\"end\" gap=\"small\">\n        <Input name=\"text\" label=\"Send\" onInput={(t) => setText(t)} />\n        <Button type=\"submit\" onClick={handleClick}>\n          Click me\n        </Button>\n      </Flex>\n    &lt;/>\n  );\n};\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "// Example serverless function file exports.main = async (context = {}) => {\nconst { text } = context.parameters; const { firstname } =\ncontext.propertiesToSend const { lastname } = context.propertiesToSend const\nresponse = `You're currently on ${firstname} ${lastname}'s contact record. You\nentered: ${text}`; return response; };\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "status"
        }), " returned by ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "runServerlessFunction"
        }), " is automatically determined by HubSpot and cannot be set manually using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sendResponse()"
        }), ". If the serverless function runs successfully, it will automatically return a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "status"
        }), " of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "SUCCESS"
        }), ". To trigger an error status, you'll need to build it into your serverless function."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Display alert banners"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "addAlert"
      }), " method to send alert banners as a feedback for any actions to indicate success or failure. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "addAlert"
      }), " is a part of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "actions"
      }), " object that can be passed to extension via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.extend"
      }), ". If you want to render an alert within a card, check out the ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/ui-components/standard-components/alert",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Alert"
        }), " component"]
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "addAlert"
            }), " method to send alert banners as a feedback for any actions to indicate success or failure. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "addAlert"
            }), " is a part of the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actions"
            }), " object that can be passed to extension via ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot.extend"
            }), "."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "AddAlert"
            }), " method accepts the following props:"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "title"
              }), ": the bolded title text of the alert."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "message"
              }), ": the alert text."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "type"
              }), ": the alert color variant. Can be one of:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
                children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "info"
                  }), ": a blue alert to provide general information."]
                }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "success"
                  }), ": a green alert indicating a positive outcome."]
                }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "warning"
                  }), ": a yellow alert indicating caution."]
                }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "danger"
                  }), ": a red alert indicating a negative outcome."]
                }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "tip"
                  }), ": a white alert to provide guidance."]
                }), "\n"]
              }), "\n"]
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-alert-example%20(1).png",
              alt: "ui-extension-alert-example (1)"
            })
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/alert-row.png",
        alt: "alert-row"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Snippet from app/extensions/HelloWorld.jsx\n\nhubspot.extend(({ context, runServerlessFunction, actions }) => (\n  <HelloWorld\n    context={context}\n    runServerless={runServerlessFunction}\n    onAlertClick={actions.addAlert}\n  />\n));\n\n// the following method executes a serverless function\n// and displays an alert based on the response\nconst HelloWorld = ({ context, runServerless, onAlertClick }) => {\n  const [inputValue, setInputValue] = useState(\"\");\n  const executeServerless = async () => {\n    const serverlessResult = await runServerless({\n      name: \"get-data\",\n      parameters: { inputValue },\n    });\n    if (serverlessResult.status === 'SUCCESS') {\n      onAlertClick({title: \"Heads up\", message: serverlessResult.response.alertMessage, type: \"success\"});\n    } else {\n      console.error(\"Error executing serverless: \", serverlessResult.message);\n    }\n  };\n\nreturn (\n// components\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In line 21 above, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alertMessage"
      }), " value can be passed by the serverless function by including it in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sendResponse"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Snippet from app/app.functions/get-data.js\n */\nexport.main = async (context = {}, callback) => {\n  const message = context.parameters['inputValue'];\n  callback({alertMessage: `You typed \"${message}\".`});\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fetch CRM property data"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are three ways to fetch CRM property data:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fetchCrmObjectProperties"
        }), ", which can be included in your React files to fetch property data client side at extension load time."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "propertiesToSend"
        }), ", which can be included in your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#runserverlessfunction",
          children: "serverless functions"
        }), " to fetch property data on the back-end at function invocation time. See the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#runserverlessfunction",
          children: "above example"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use GraphQL to query CRM data through the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/collector/graphql"
        }), " endpoint. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/graphql/query-hubspot-data-using-graphql",
          children: "querying CRM data using GraphQL"
        }), ". To see an example of using GraphQL to fetch data, check out ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sample-extensions/overview#contact-duplicator",
          children: "HubSpot's contact duplicator sample project"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " to make GraphQL requests, your app must include the following scopes:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "collector.graphql_schema.read"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "collector.graphql_query.execute"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "fetchCrmObjectProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetchCrmObjectProperties"
      }), " method, you can get property values from the currently displaying CRM record without having to use HubSpot's APIs. This method is a part of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "actions"
      }), " object that can be passed to the extension via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.extend"
      }), ". You'll first need to add the object to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypes"
      }), " inside the card's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".json"
      }), " config file. The objects you specify in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypes"
      }), " will also set which CRM objects will display the extension."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hubspot.extend(({ actions }) => (\n  <HelloWorld fetchProperties={actions.fetchCrmObjectProperties} />\n));\n\nconst HelloWorld = ({ runServerless, fetchProperties }) => {\n  const [firstName, setFirstName] = useState('');\n  const [lastName, setLastName] = useState('');\n\n  useEffect(() => {\n    fetchProperties(['firstname', 'lastname']).then((properties) => {\n      setFirstName(properties.firstname);\n      setLastName(properties.lastname);\n    });\n  }, [fetchProperties]);\n\n  return (\n    <Text>\n      Hello {firstName} {lastName}\n    </Text>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can specify individual properties or fetch all properties with an asterisk:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "fetchCrmObjectProperties('*').then((properties) => console.log(properties));\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetchCrmObjectProperties"
      }), " is formatted as:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example fetchCrmObjectProperties response\n{\n \"property1Name\": \"property1Value\",\n \"property2Name\": \"property2Value\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Refresh properties on the CRM record"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "refreshObjectProperties"
      }), " to refresh the property data on the CRM record, and any CRM data components on the record without needing to refresh the page. This includes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/customize-the-middle-column-of-records#manage-cards-in-the-middle-column",
        children: "cards added to the record through HubSpot's UI"
      }), ". This method will work for the CRM objects that you include in the extension's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".json"
      }), " file in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypes"
      }), " array."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this method will ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " refresh property values in app cards that are fetched using HubSpot's APIs. Only HubSpot's built-in property fields and properties in CRM data components will be refreshed."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import React, { useState } from 'react';\nimport {\n  Divider,\n  Button,\n  Input,\n  Flex,\n  hubspot\n} from '@hubspot/ui-extensions';\n\nhubspot.extend(({ runServerlessFunction, actions }) => (\n  <Extension\n    runServerless={runServerlessFunction}\n    refreshObjectProperties={actions.refreshObjectProperties}\n  />\n));\n\nconst Extension = ({\n  runServerless,\n  refreshObjectProperties,\n}) => {\n\n// Your extension logic goes here\n// Refresh all properties of the object on the page\n        refreshObjectProperties();\n      }\n    });\n  };\n\nreturn (\n// Your extension body\n)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listen for property updates"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onCrmPropertiesUpdate"
      }), " to subscribe to changes made to properties on the CRM record and run functions based on those changes. This only includes changes made from within the HubSpot UI, not property updates from outside the UI, such as via APIs. This action is intended to be used like a React hook."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The full API for this method is as follows:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "export type onCrmPropertiesUpdateAction = (\n properties: string[] | '*',\n callback: (\n   properties: Record<string, string>,\n   error?: { message: string }\n   ) => void\n) => void;\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As an example, the following function subscribes to updates made to the contact's first and last name properties, then logs those properties to the console."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCrmPropertiesUpdate(['firstname', 'lastname'], (properties) =>\n  console.log(properties)\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can subscribe to all properties by using an asterisk."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCrmPropertiesUpdate('*', (properties) => console.log(properties));\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Error handling"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can handle potential errors by passing the error argument to the callback."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCrmPropertiesUpdate(['firstname','lastname'], (properties, error) => {\n   if(error) {\n    console.log(error.message}\n   }\n   else {\n     console.log(properties)\n   }\n})\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Open an overlay"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " as of June 24, 2024, the method for including ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Panel"
        }), " components has changed. Moving forward:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Panels are nested within the components that open them."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Opening the panel is automatically handled by the parent component, rather than requiring reactions."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Extensions using the old method will continue to function, but it's recommended to review the new functionality below and update your extensions as needed."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add another layer of UI to your extension, you can include overlays using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/modal",
        children: "Modal"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/panel",
        children: "Panel"
      }), " components. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Panel"
      }), " component adds a sidebar overlay, while the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Modal"
      }), " component adds a pop-up box overlay. Whichever type of overlay you want to use, you'll incorporate it in the same way. To see an example of using overlays, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/overlay-example",
        children: "HubSpot's Overlay example project"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When choosing which type of overlay to use, keep in mind the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modal"
        }), ": better suited for short messages and action confirmations. A ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'danger'"
        }), " variant is included for destructive actions, such as deleting a contact."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Panel:"
        }), " better suited for longer, compartmentalized tasks that users might need to perform, such as multi-step forms. Includes a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'modal'"
        }), " variant to obscure page content outside of the panel to focus the user on the panel task."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add either type of overlay to your extension:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "overlay"
        }), " prop to a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button",
          children: "Button"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/loading-button",
          children: "LoadingButton"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/link",
          children: "Link"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/tag",
          children: "Tag"
        }), ", or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/image",
          children: "Image"
        }), " component."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Modal"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Panel"
        }), " component into the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "overlay"
        }), " prop."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By default, both types of overlay come with a close button in the top right."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-component-panel-close.png",
        alt: "ui-extension-component-panel-close"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add a secondary closing mechanism, you can include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Button"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LoadingButton"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Link"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Tag"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Image"
      }), " component within ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "overlay"
      }), " that triggers the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "closeOverlay"
      }), " action in an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onClick"
      }), " event. To use this action, you'll need to include the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#registering-the-extension",
        children: "actions argument"
      }), " in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.extend()"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Only one ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Modal"
          }), " can be open at a time per extension. Opening a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Modal"
          }), " when another one is already opened will cause the first one to close."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Modal"
          }), " can be opened from a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Panel"
          }), ", but a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Panel"
          }), " can't be opened from a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Modal"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are examples of a panel overlay and a modal overlay."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Panel overlay"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/panel-example-gif.gif",
        alt: "panel-example-gif"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import {\n  Button,\n  Panel,\n  PanelSection,\n  PanelBody,\n  PanelFooter,\n  Text,\n  hubspot,\n} from '@hubspot/ui-extensions';\n\nhubspot.extend(({ actions }) => <OverlayExampleCard actions={actions} />);\n\nconst OverlayExampleCard = ({ actions }) => {\n  return (\n    <>\n      <Button\n        overlay={\n          <Panel id=\"my-panel\" title=\"Example panel\">\n            <PanelBody>\n              <PanelSection>\n                <Text>Welcome to my panel. Thanks for stopping by!</Text>\n                <Text>\n                  Close the panel by clicking the X in the top right, or using\n                  the button below\n                </Text>\n              </PanelSection>\n            </PanelBody>\n            <PanelFooter>\n              <Button\n                variant=\"secondary\"\n                onClick={() => {\n                  actions.closeOverlay('my-panel');\n                }}\n              >\n                Close\n              </Button>\n            </PanelFooter>\n          </Panel>\n        }\n      >\n        Open panel\n      </Button>\n    </>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modal overlay"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-component-modal-example%20(1).gif",
        alt: "uie-component-modal-example (1)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import {\n  Button,\n  Modal,\n  ModalBody,\n  ModalFooter,\n  Text,\n  hubspot,\n} from '@hubspot/ui-extensions';\n\nhubspot.extend(({ actions }) => <OverlayExampleCard actions={actions} />);\n\nconst OverlayExampleCard = ({ actions }) => {\n  return (\n    <>\n      <Button\n        overlay={\n          <Modal id=\"default-modal\" title=\"Example modal\" width=\"md\">\n            <ModalBody>\n              <Text>Welcome to my modal. Thanks for stopping by!</Text>\n              <Text>\n                Close the modal by clicking the X in the top right, or using the\n                button below\n              </Text>\n            </ModalBody>\n            <ModalFooter>\n              <Button onClick={() => actions.closeOverlay('default-modal')}>\n                Close modal\n              </Button>\n            </ModalFooter>\n          </Modal>\n        }\n      >\n        Open modal\n      </Button>\n    </>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Open an iframe in a modal"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Similar to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "addAlert"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetchCrmObjectProperties"
      }), ", you can pass ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "openIframeModal"
      }), " to the extension through the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "actions"
      }), " object. This action includes a callback, which you can use to run a function when the iframe modal is closed. The callback doesn't receive any parameters."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more by checking out HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#display-an-iframe-modal",
        children: "Display an iframe modal sample project"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "export type OpenIframeModalAction = (\n  action: OpenIframeActionPayload,\n  onClose?: () => void\n) => void;\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "openIframeModal"
      }), " takes the following payload:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "interface OpenIframeActionPayload {\n  uri: string;\n  height: number;\n  width: number;\n  title?: string;\n  flush?: boolean;\n  associatedObjectProperties?: string[];\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, the following code would result in an extension that opens an iframe on button click. The iframe is configured to contain the Wikipedia homepage with a height and width of 1000px and no padding. Upon closing the modal, a message will be logged to the console."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Link, Button, Text, Box, Flex, hubspot } from '@hubspot/ui-extensions';\n\n// Define the extension to be run within the Hubspot CRM\nhubspot.extend(\n  (\n    { actions } // serverless function is not required for simply displaying an iframe\n  ) => <Extension openIframe={actions.openIframeModal} />\n);\n\n// Define the Extension component, taking in openIframe as a prop\nconst Extension = ({ openIframe }) => {\n  const handleClick = () => {\n    openIframe(\n      {\n        uri: 'https://wikipedia.org/', // this is a relative link. Some links will be blocked since they don't allow iframing\n        height: 1000,\n        width: 1000,\n        title: 'Wikipedia in an iframe',\n        flush: true,\n      },\n      () => console.log('This message will display upon closing the modal.')\n    );\n  };\n\n  return (\n    <>\n      <Flex direction=\"column\" align=\"start\" gap=\"medium\">\n        <Text>\n          Clicking the button will open a modal dialog with an iframe that\n          displays the content at the provided URL. Get more info on how to do\n          this .\n          <Link href=\"https://developers.hubspot.com/docs/platform/create-ui-extensions#open-an-iframe-in-a-modal\">\n            here\n          </Link>\n        </Text>\n\n        <Box>\n          <Button type=\"submit\" onClick={handleClick}>\n            Click me\n          </Button>\n        </Box>\n      </Flex>\n    </>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Copy text to clipboard"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "copyTextToClipboard"
      }), " action to copy text to your clipboard. This action can be accessed through the actions argument (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "actions.copyTextToClipboard"
      }), ") and returns a promise that resolves once the system clipboard has been updated. Its functionality is provided by the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText",
        children: "Clipboard: writeText() method"
      }), " and follows the same requirements."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This action only works after the user has interacted with the page after loading (", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/Security/User_activation#transient_activation",
        children: "transient activation"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import React from 'react';\nimport { Button, Flex, hubspot, TextArea } from '@hubspot/ui-extensions';\n\nhubspot.extend(({ actions }) => <Extension actions={actions} />);\n\nfunction Extension({ actions }) {\n  const textToCopy = `Copy me!`;\n\n  // Use copy action on event handler\n  async function handleOnClick() {\n    try {\n      // The function is async, make sure to await it.\n      await actions.copyTextToClipboard(textToCopy);\n      actions.addAlert({\n        type: 'success',\n        message: 'Text copied to clipboard.',\n      });\n    } catch (error) {\n      // User error handling. copyTextToClipboard can fail with a `notAllowed` error.\n      console.log(error);\n      actions.addAlert({\n        type: 'warning',\n        message: \"Couldn't copy text.\",\n      });\n    }\n  }\n\n  return (\n    <Flex direction=\"column\" gap=\"md\">\n      <TextArea label=\"Text\" value={textToCopy} />\n      <Button onClick={handleOnClick}>Copy text</Button>\n    </Flex>\n  );\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This action should be run by explicit user interaction, otherwise the action will fail by running before the page has rendered. For example, the following implementation would fail:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "function CopyButtonBadExample({ actions }) {\n  const textToCopyWithoutUserPermission = `Please don't try this, it will fail`;\n\n  useEffect(() => {\n    /**\n     * Don't run copyTextToClipboard without explicit interaction.\n     * This will fail because the action will run before the page\n     * has rendered.\n     */\n    async function badStuff() {\n      try {\n        await actions.copyTextToClipboard(textToCopyWithoutUserPermission);\n        actions.addAlert({\n          type: 'success',\n          message: 'text copied to clipboard',\n        });\n      } catch (error) {\n        console.log(error);\n        actions.addAlert({\n          type: 'warning',\n          message: \"can't copy value\",\n        });\n      }\n    }\n\n    badStuff();\n  }, []);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Upload files"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While there is no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview",
        children: "component"
      }), " for uploading files, there are a few ways you can upload files:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/create-and-edit-properties",
          children: "Create a custom file type property"
        }), ", then use a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-property-list",
          children: "CRM property list component"
        }), " to display and manage the property from CRM records. You can upload up to 10 files per file property, and file uploaded via file properties have the same ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/files/supported-file-types",
          children: "size and type limitations"
        }), " as files uploaded to the file manager."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Include an ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#open-an-iframe-in-a-modal",
          children: "iframe modal"
        }), " in the extension that loads an upload page, then upload files through the iframe."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fetch account, user, and extension context"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context"
      }), " object, which is passed to the extension component via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.extend"
      }), ", contains information related to the authenticated user and HubSpot account, along with information about where the extension is loading. The following is an example of the information you can retrieve via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "{\n  location: 'crm.record.tab' | 'crm.record.sidebar',\n  crm: {\n    objectId: number;        // the current CRM record's ID\n    objectTypeId: string;    // the current CRM record's object type\n  },\n  extension: {\n    appId: number;           // the extension's app ID\n    appName: string;         // the extension's app name\n    cardTitle: string;       // the extension's title\n    location: string;        // the location where the extension loads\n  },\n  portal: {\n    id: number;              // HubSpot account ID\n    timezone: string;        // account timezone\n  },\n  user: {\n    email: string;           // the logged in user's primary email\n    emails: string[];        // all associated emails\n    firstName: string;       // user's first name\n    id: number;              // user's ID\n    lastName: string;        // user's last name\n    locale: string;          // user's locale\n    roles: string[];         // role names\n    teams: Team[\n      {\n        id: number;\n        name: string;\n        teammates: number[];  // IDs of the user's teammates\n    ];\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Send custom log messages for debugging"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "logger"
      }), " methods, you can send custom log messages to HubSpot for more in-depth troubleshooting of deployed extensions. Custom log messages will appear in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps#logs",
        children: "app's logs in HubSpot"
      }), ", searchable by trace ID. Check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#custom-logger-example",
        children: "HubSpot's custom logger sample project"
      }), " to see an implementation example."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following methods are available:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "logger.info"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "logger.debug"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "logger.warn"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "logger.error"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Each method accepts a single string argument."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, the following extension code includes few different log messages to help better identify where an error has occurred:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { logger } from '@hubspot/ui-extensions';\n\nlogger.debug('Information before my extension');\n\nhubspot.extend(({ runServerlessFunction }) => {\n  logger.warn('Logging a warning inside extension');\n\n  useEffect(() => {\n    runServerlessFunction('customers')\n      .then((result) => {\n        if (result.status === 'ERROR') {\n          logger.error(`Customer fetch failed`);\n          return;\n        }\n\n        logger.info(JSON.stringify(result));\n      })\n      .catch((error) => {\n        logger.error(`Customer fetch failed: ${error.message}`);\n      });\n  }, []);\n\n  return <Text>Hello</Text>;\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When an extension fails to load on a CRM record, an error message will display. This error message will contain a trace ID, which you can copy."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/logger-debug-on-crm-record.png",
        alt: "logger-debug-on-crm-record"
      }), "Using that trace ID, you can then locate the custom log messages ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps#log-traces",
        children: "within the private app's logs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Notes and limitations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Custom log messages are not sent while in local development mode. They are logged to the browser console instead."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "All logs are sent as batches with a maximum of 100 logs per batch."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Each HubSpot account is rate limited to 1,000 logs per minute. After exceeding that limit, all logging is stopped until the page is reloaded."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The logger will queue a maximum of 10,000 pending messages. Any subsequent logs will be dropped until the queue is below the maximum."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Queued logs are processed at a rate of five seconds per log batch."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Queued logs are dropped when the page or is refreshed or closed."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}