"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 38575989237;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-completions';
const title = exports.title = 'Benutzerdefinierten Event-Abschlüsse senden';
const name = exports.name = 'Benutzerdefinierten Event-Abschlüsse senden';
const metaDescription = exports.metaDescription = 'Mit benutzerdefinierten Events können Sie erweiterte Aktivitäten über eine JavaScript- oder HTTP-API nachverfolgen. Die Events-API kann verwendet werden, um Details zu Ihren Events abzurufen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "das-event-definieren",
  "label": "Das Event definieren",
  "parent": null
}, {
  "depth": 0,
  "id": "event-daten-senden",
  "label": "Event-Daten senden",
  "parent": null
}, {
  "depth": 0,
  "id": "event-daten-abrufen",
  "label": "Event-Daten abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "event-eigenschaften",
  "label": "Event-Eigenschaften",
  "parent": null
}, {
  "depth": 0,
  "id": "attribution-berichterstattung",
  "label": "Attribution-Berichterstattung",
  "parent": null
}, {
  "depth": 1,
  "id": "elementtyp",
  "label": "Elementtyp",
  "parent": "attribution-berichterstattung"
}, {
  "depth": 1,
  "id": "elementtitel",
  "label": "Elementtitel",
  "parent": "attribution-berichterstattung"
}, {
  "depth": 1,
  "id": "interaktionsquellen",
  "label": "Interaktionsquellen",
  "parent": "attribution-berichterstattung"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      em: "em",
      strong: "strong",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Tag,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Benutzerdefinierten Event-Abschlüsse senden"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Benutzerdefinierte Events sind Account-definierte Events, die Event-Details in Event-Eigenschaften speichern. Es gibt drei Typen von benutzerdefinierten Events, die Sie in HubSpot erstellen können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Events der Typen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "Angeklicktes Element"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "Aufgerufene URL"
        }), " sind benutzerdefinierte Events, die vom Tracking-Code automatisch mit Daten ausgefüllt werden. Sie können diese Events weiter anpassen, indem Sie Ihren Tracking-Code mit der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code#events-js-api",
          children: "trackCustomEvent"
        }), "-Funktion aktualisieren."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
          children: "Manuell nachverfolgte Events"
        }), " sind benutzerdefinierte Events, die einzigartig für Ihr Unternehmen sind und nicht automatisch von HubSpot oder einer Integration erfasst werden. Sie können manuell über diese API Daten an diese Events senden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie, wie Sie ein manuell nachverfolgtes benutzerdefiniertes Event erstellen, Event-Daten über die API senden und Event-Daten nach der Erfassung nutzen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Das Event definieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Daten zu Event-Abschlüssen an HubSpot zu senden, müssen Sie zunächst ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "das Event selbst definieren"
      }), ", einschließlich seiner Metadaten, CRM-Objektzuordnungen und Eigenschaften. Sie können Events mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions",
        children: "„Benutzerdefinierte Event-Definition“-API"
      }), " definieren, oder wenn Sie ein ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        }), " Enterprise"]
      }), "-Abonnement haben, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
        children: "das Event in HubSpot erstellen"
      }), ". Beim Erstellen des Events berücksichtigt HubSpot eine Reihe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "Standard-Event-Eigenschaften"
      }), ", die Sie zum Speichern von Event-Daten verwenden können. Sie können auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "zusätzliche Eigenschaften für das Event erstellen"
      }), ". Diese Eigenschaften können jederzeit erstellt oder bearbeitet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sobald Sie Ihr Event eingerichtet haben, können Sie Daten über die API an das Event senden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event-Daten senden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Event-Daten an HubSpot zu senden, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/events/v3/send"
      }), " mit Ihren Event-Daten im Anfragetext durch. Bevor Sie Event-Daten senden, überprüfen Sie die folgenden Beschränkungen, da ein Überschreiten dieser Beschränkungen zu einem Fehler führt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST to https://api.hubspot.com/events/v3/send\n{\n  \"eventName\": \"pe1234567_login_event\",\n  \"objectId\": \"608051\",\n  \"occurredAt\": \"2024-06-28T12:09:31Z\",\n  \"properties\": {\n    \"hs_city\": \"Cambridge\",\n    \"hs_country\": \"United States\",\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\",\n    \"hs_touchpoint_source\": \"DIRECT_TRAFFIC\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der interne Name des Events. Sie finden dies durch ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "Abfragen Ihrer bestehenden Event-Definitionen"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/reports/create-custom-behavioral-events-with-the-code-wizard#find-internal-name",
              children: "in der HubSpot-App"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ID des CRM-Datensatzes, dem das Event zugeordnet wird. Bei Kontakten können Sie alternativ das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), "-Feld oder das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utk"
            }), "-Feld verwenden, um den Kontakt anhand der E-Mail-Adresse oder des ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/tracking-code",
              children: "HubSpot-Benutzer-Tokens"
            }), " zu identifizieren. Alle anderen Objekttypen erfordern ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Standardmäßig legt HubSpot den Zeitstempel für den Abschluss des Events auf den Zeitpunkt fest, an dem die Anfrage gesendet wird. Um die Zeit des Event-Abschlusses anzugeben, fügen Sie einen Zeitstempel in ein ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            }), "-Feld im ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), "-Anfragetext ein (", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_8601",
              children: "ISO 8601-Format"
            }), "). Dies kann besonders für die Rückdatierung von Event-Daten hilfreich sein, um den Abschluss von Events im wirklichen Leben genauer widerzuspiegeln."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Event-Eigenschaften, an die Daten gesendet werden sollen. Dies kann die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
              children: "Standard-Event-Eigenschaften von HubSpot"
            }), " oder jede ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
              children: "benutzerdefinierte Eigenschaft"
            }), " umfassen, die Sie für die Event definiert haben. Die meisten Standard-Event-Eigenschaften sind Zeichenfolgenegenschaften, aber Sie können alle Event-Eigenschaften überprüfen, indem Sie entweder ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "die Event Definition abfragen"
            }), " oder zum Event in HubSpot gehen. Erfahren Sie im Folgenden mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#event-properties",
              children: "Event-Eigenschaften"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Ein Überschreiten einer der folgenden Beschränkungen führt zu einer fehlgeschlagenen Anfrage:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Das Eigenschaftslabel und der interne Name sind auf 50 Zeichen begrenzt."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "URL- und Referrer-Eigenschaften können bis zu 1024 Zeichen enthalten, alle anderen Eigenschaften dagegen bis zu 256 Zeichen."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Jeder Event-Abschluss kann Daten für bis zu 50 Eigenschaften enthalten."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Interne Eigenschaftsnamen müssen mit einem Buchstaben beginnen und dürfen nur Kleinbuchstaben von a-z, die Zahlen 0-9 und Unterstriche enthalten."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Eigenschaften mit dem gleichen internen Namen nach der Kleinschreibung werden als Duplikate betrachtet, und nur eine der Eigenschaften wird beim Abschluss verwendet. HubSpot sortiert in aufsteigender lexikografischer Reihenfolge, wobei die zuletzt angezeigte Eigenschaft unter den ersten 50 Eigenschaften beibehalten wird."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Es gibt ein Limit von 500 eindeutigen Event-Definitionen pro Account."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Es gibt ein Limit von 30 Millionen Event-Abschlüssen pro Monat."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event-Daten abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "die Event-Daten eines Kontakts abzurufen"
      }), ", führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId={CONTACT_ID}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die obige Anfrage umfasst:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "eventType:"
        }), " der interne Name des Events."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType:"
        }), " der Objekttyp des Datensatzes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectId:"
        }), " die ID des Kontakts."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event-Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Event-Daten werden in Eigenschaften gespeichert, entweder im Satz der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "Standard-Event-Eigenschaften"
      }), " oder in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "benutzerdefinierten Eigenschaften"
      }), ". Beim Senden von Event-Daten können Sie Eigenschaften aktualisieren, indem Sie sie in ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "-Objekt aufnehmen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"properties\": {\n    \"property1\": \"string\",\n    \"property2\": \"string\",\n    \"property3\": \"string\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Welche Werte Sie senden, hängt vom Typ der Event Eigenschaft ab. Die meisten Standard-Event-Eigenschaften sind einzeiliger Text (Zeichenfolge). Sie können jedoch benutzerdefinierte Eigenschaften beliebigen Typs für jedes Event erstellen. Überprüfen Sie die folgende Tabelle, wenn Sie Eigenschaftswerte formatieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftstyp"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Zeichenfolge, die eine Reihe von Optionen enthält. Trennen Sie beim Senden mehrerer Werte diese durch ein Semikolon. In HubSpot entspricht dieser Typ Dropdown-Auswahl-, Optionsfeld-Auswahl und „Mehrere Kontrollkästchen“-Eigenschaften."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Zeitstempel im Formular von Epochen-Millisekunden oder ISO8601. In HubSpot entspricht dieser Typ den Datumsauswahl-Eigenschaften."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Klartext-Zeichenfolge, die auf 65.536 Zeichen begrenzt ist. In HubSpot entspricht dieser Typ Eigenschaften Eigenschaften der Typen „Einzeiliger Text“ und „Mehrzeiliger Text“."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Zahlenwert mit numerischen Ziffern und höchstens einer Dezimalstelle. In HubSpot entspricht dieser Typ Zahlen- und berechneten Eigenschaften."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So zeigen Sie die verfügbaren Eigenschaften eines Events an:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigieren Sie in Ihrem HubSpot Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Berichterstattung"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Datenmanagement"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Benutzerdefinierte Events"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " des Events."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eigenschaften"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Zeigen Sie in der Eigenschaftentabelle den Eigenschaftstyp unter dem Namen der Eigenschaft an."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-event-properties-table.png",
        alt: "custom-event-properties-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Attribution-Berichterstattung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScript-Events wie Events vom Typ ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "Angeklicktes Element"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "Aufgerufene URL"
      }), " werden automatisch mit Ressourcentyp- und Interaktionsdaten für Attribution-Berichte ausgefüllt. Um die gleichen Daten für manuell nachverfolgte Events zu berücksichtigen, müssen Sie die Daten manuell mithilfe von Event-Eigenschaften in die Anfragetext aufnehmen. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/analyze-custom-behavioral-events",
        children: "Analysieren von benutzerdefinierten Events"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über die verfügbaren Werte für Ressourcentypen und Interaktionsquellen sowie Beispielanfragen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Elementtyp"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen bestimmten Elementtyp einer benutzerdefinierte verhaltensorientierte Event-Anfrage zuzuschreiben, fügen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_content_type"
      }), "-Eigenschaft in den Anfragetext ein. Zum Beispiel:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Sie können auch die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), "-Eigenschaft verwenden. Wenn sowohl ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), " als auch ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), " in einer Anfrage enthalten sind, überschreibt ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), " den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), "-Wert."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Standardinhaltstypen von HubSpot, wie Landingpages und Blogbeiträge, können mit den folgenden Werten dargestellt werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Wert"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "STANDARD_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einer Website-Seite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einer Landingpage."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einem Blogbeitrag."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einem Wissensdatenbankartikel."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie für alle anderen Elementtypen die folgenden Werte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Wert"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "AD"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einer Anzeige, z. B. einer Facebook- oder Google-Anzeige."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einem Anruf."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT_IMPORT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion über einen Kontaktimport."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion im Zusammenhang mit einer HubSpot-Konversation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CUSTOM_BEHAVIORAL_EVENT_NAME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der interne Name eines benutzerdefinierten Events, z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pe123456_manually_tracked_event"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einer E-Mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einer externen Seite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion über eine Integration."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Interaktion mit einem ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "Marketingevent"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEDIA_BRIDGE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Interaktion über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/cms/media-bridge",
              children: "Media Bridge"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einem Meeting."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES_EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Interaktion mit einer persönlichen Vertriebs-E-Mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SEQUENCE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einer Sequenz."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einem Social-Media-Beitrag."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Interaktion mit einem Inhaltselement, das nicht in eine der oben genannten Kategorien fällt."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Elementtitel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein benutzerdefiniertes Event einem Element zuzuschreiben, schließen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title"
      }), "- oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_asset_title"
      }), "-Eigenschaft in Ihre Anfrage mit dem Namen des Element ein, der als Zeichenfolge formatiert ist. Zum Beispiel:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_title\": \"Sweepstakes Sign Up\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Interaktionsquellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein benutzerdefiniertes verhaltensorientiertes Event einer bestimmten Quelle zuzuschreiben, schließen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_touchpoint_source"
      }), "-Eigenschaft in Ihre Anfrage mit einem der folgenden Werte ein:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Wert"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist eine Konversation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DIRECT_TRAFFIC"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist direkter Traffic."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL_MARKETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist eine Marketing-E-Mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_CRM"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist das HubSpot-CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist eine Integration."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Interaktionsquelle ist ein ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "Marketingevent"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OFFLINE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist offline."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ORGANIC_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist die organische Suche."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER_CAMPAIGNS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist von einer nicht kategorisierten Kampagne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist eine bezahlte Suchanzeige."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SOCIAL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist eine bezahlte Social-Media-Anzeige."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "REFERRALS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist eine Empfehlung."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist der Vertrieb."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_MEDIA"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Interaktionsquelle ist Social Media (keine bezahlten Social-Media-Anzeigen)."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}