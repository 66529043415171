"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 128010049357;
const slug = exports.slug = 'guides/crm/developer-projects/create-a-project';
const title = exports.title = 'Create a project (BETA)';
const name = exports.name = 'Create a project (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to include source code files in a private app using HubSpot projects.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-project",
  "label": "Create a project",
  "parent": null
}, {
  "depth": 0,
  "id": "view-the-project-in-hubspot",
  "label": "View the project in HubSpot",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Create a project (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using projects, you can package, build, and deploy to HubSpot locally using the CLI. Depending on your HubSpot subscription, you can use projects to build the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "UI extensions:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Building UI extensions for private apps in standard HubSpot accounts requires a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "Sales Hub"
              })
            }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " subscription. However, a paid subscription is not required for creating UI extensions for private apps in test accounts within developer accounts."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Public apps are built in developer accounts, which don't require a paid subscription. If you're enrolled in the UI extensions for public apps Early Access beta, learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/public-apps/overview",
              children: "building UI extensions for public apps"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS:"
        }), " all accounts can create projects to build ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/build-modules-and-partials-with-react",
          children: "CMS React modules"
        }), ". However, to include serverless functions that are invoked by public URLs, you'll need a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " subscription."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This guide walks through how to create a project from scratch. After creating and uploading the project, you'll then create a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps",
        children: "private app"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/creating-public-apps",
        children: "public app"
      }), " within it, followed by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "UI extensions"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "CMS React modules"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "To get started developing a project, app, and UI extension from a template, check out the following quickstart guides:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/private-apps/quickstart",
            children: "UI extensions for private apps"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/public-apps/quickstart",
            children: "UI extensions for public apps"
          })
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Or, check out ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sample-extensions/overview",
          children: "HubSpot's sample UI extensions"
        }), " to examples of how these pieces fit together."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a project"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a project from scratch:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the terminal, navigate to the folder where you'll be storing the project locally. A project can live anywhere locally, but will be stored in HubSpot as a root-level directory in the developer file system."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project create"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " for your project, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), " to create the project with the suggested directory. Or enter a new directory path, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "No template"
        }), " to create a project with no template."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A project directory will then be created with the following assets:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["An ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsproject.json"
        }), " file in the root directory to configure the project."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example project config file\n{\n  \"name\": \"my_project\",\n  \"srcDir\": \"src\",\n  \"platformVersion\": \"2023.2\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The project's name"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "srcDir"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The directory that contains the rest of your project files."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "platformVersion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/developer-projects/platform-versioning",
              children: "version of the developer project platform"
            }), " you're developing on. As improvements are made and features are added to the projects platform, some might include breaking changes. By specifying a version, you can control which features are accessible in your project. If not specified, the project will fall back to the current version."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["An ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "src"
        }), " folder where you'll store your app's files. This folder can have any name as long as it matches the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "srcDir"
        }), " value in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsproject.json"
        }), " file."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/project-from-scratch.png",
        alt: "project-from-scratch"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Next, upload the project to HubSpot by running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project upload"
      }), ". This will upload the empty project to your account where you can then view it."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the source directory cannot be greater than 50MB uncompressed, otherwise it will fail to upload."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View the project in HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view your deployed project in HubSpot, you can run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project open"
      }), " in the terminal, or navigate to it in HubSpot:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM Development"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Projects"
        }), ". The project card will display a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "This project is empty"
        }), " message, which is expected because you've uploaded a project without a private app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the project. The project details page will display information about build history once a private app is added to the project."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "From the project details page, you can also manage auto-deploy settings:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the project page, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click to toggle the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Auto-deploy successful builds"
        }), " switch off."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2021/Developer/project-private-app-settings-tab0.png?width=748&name=project-private-app-settings-tab0.png",
        alt: "project-private-app-settings-tab0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Next, create a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps",
        children: "private app"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/creating-public-apps",
        children: "public app"
      }), " in the project so that you can start building your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "UI extensions"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/build-modules-and-partials-with-react",
        children: "CMS React modules"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}