"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358768;
const slug = exports.slug = 'guides/api/cms/hubdb';
const title = exports.title = 'API do CMS | HubDB';
const name = exports.name = 'API do CMS | HubDB';
const metaDescription = exports.metaDescription = 'Os pontos de extremidade do HubDB são usados para obter e gerenciar dados nas tabelas de dados HubDB.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "limites-de-taxa",
  "label": "Limites de taxa",
  "parent": null
}, {
  "depth": 0,
  "id": "tabelas-de-rascunho-e-ativas",
  "label": "Tabelas de rascunho e ativas",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-uma-tabela-hubdb",
  "label": "Criar uma tabela HubDB",
  "parent": null
}, {
  "depth": 1,
  "id": "adicionar-colunas-da-tabela",
  "label": "Adicionar colunas da tabela",
  "parent": "criar-uma-tabela-hubdb"
}, {
  "depth": 1,
  "id": "adicionar-linhas-da-tabela",
  "label": "Adicionar linhas da tabela",
  "parent": "criar-uma-tabela-hubdb"
}, {
  "depth": 1,
  "id": "importar-linhas-do-csv",
  "label": "Importar linhas do CSV",
  "parent": "criar-uma-tabela-hubdb"
}, {
  "depth": 1,
  "id": "formata%C3%A7%C3%A3o-de-data",
  "label": "Formatação de data",
  "parent": "criar-uma-tabela-hubdb"
}, {
  "depth": 1,
  "id": "redefinir-op%C3%A7%C3%B5es",
  "label": "Redefinir opções",
  "parent": "criar-uma-tabela-hubdb"
}, {
  "depth": 0,
  "id": "recuperar-dados-do-hubdb",
  "label": "Recuperar dados do HubDB",
  "parent": null
}, {
  "depth": 1,
  "id": "filtrar-linhas-retornadas",
  "label": "Filtrar linhas retornadas",
  "parent": "recuperar-dados-do-hubdb"
}, {
  "depth": 1,
  "id": "classificar-as-linhas-retornadas",
  "label": "Classificar as linhas retornadas",
  "parent": "recuperar-dados-do-hubdb"
}, {
  "depth": 0,
  "id": "configurar-tabelas-hubdb-para-p%C3%A1ginas-din%C3%A2micas",
  "label": "Configurar tabelas HubDB para páginas dinâmicas",
  "parent": null
}, {
  "depth": 0,
  "id": "altera%C3%A7%C3%B5es-na-v3",
  "label": "Alterações na V3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      em: "em",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API do CMS | HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubDB é um armazenamento de dados relacional que apresenta dados como linhas, colunas e células em uma tabela, como uma planilha. As tabelas HubDB podem ser adicionadas ou modificadas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview#creating-your-first-table",
        children: "dentro da sua conta da HubSpot"
      }), ", mas você também pode usar os pontos de extremidade de API documentados aqui. Para obter informações sobre como usar dados de tabelas HubDB no seu site ou em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "e-mails programáveis"
      }), ", confira a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "Documentação do desenvolvedor do CMS da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Semelhante às páginas do site do HubSpot, as tabelas HubDB oferecem suporte às versões ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "draft"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "published"
      }), ". Isso permite que você atualize os dados na tabela, seja para teste ou para permitir um processo de aprovação manual, sem afetar nenhuma página ativa. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#draft-vs-live-tables",
        children: "tabelas de rascunho versus tabelas dinâmicas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se uma tabela estiver definida para ter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "acesso pelo público"
      }), ", você pode acessar a versão publicada da tabela e das linhas sem qualquer autenticação especificando seu ID de conta da HubSpot através do parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver migrando da v2 da API de HubDB, saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#changes-in-v3",
        children: "alterações na API atual (v3)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " pontos de extremidade com suporte a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " também oferecem suporte a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), ", para que você possa acessar dados em uma tabela do lado do cliente usando JavaScript e o ID da conta. Outros métodos e o ponto de extremidade ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Obter todas as tabelas"
        }), " requerem autenticação e não oferecem suporte a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites de taxa"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As solicitações de API do HubDB têm limites de taxa diferentes, dependendo do tipo de solicitação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Quaisquer solicitações ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " que não exijam autenticação (incluindo solicitações JavaScript do lado do cliente) estão limitadas a 10 solicitações por segundo. Essas solicitações não contarão para o limite diário."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Todas as outras solicitações ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "usando autenticação"
        }), " siga as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api",
          children: "limites padrão"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tabelas de rascunho e ativas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As tabelas HubDB têm versões de rascunho e ativas, e as versões ativas podem ser publicadas ou ter sua publicação cancelada. Assim, você poderá atualizar os dados na tabela, seja para visualizações de página ou testes ou para permitir um processo de aprovação manual, sem afetar nenhuma página ativa."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nessa API, pontos de extremidade separados são designados para as versões rascunho e publicada de uma tabela. Por exemplo, você pode recuperar a versão publicada de uma tabela fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para o seguinte ponto de extremidade:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["E para recuperar qualquer conteúdo em rascunho, mas que ainda não foi publicado, você adicionaria ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/draft"
      }), " ao final do URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os dados de rascunho podem ser revisados e depois enviados por push ao HubSpot ou usando o ponto de extremidade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/push-live"
      }), ". Os dados de rascunho também podem ser descartados por meio do ponto de extremidade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/reset"
      }), ", permitindo reverter para a versão atual ativa dos dados sem interrupção."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar uma tabela HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar uma tabela HubDB, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No corpo da solicitação, especifique nos seguintes campos obrigatórios:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome interno da tabela. Este nome não pode ser alterado após a criação da tabela. Os nomes somente podem incluir letras minúsculas, dígitos e sublinhados e não podem começar com um número."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"my_table\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O rótulo da tabela que os usuários veem ao editar a tabela no HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\":\"My table\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além disso, você pode especificar os seguintes campos opcionais:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Se a tabela pode ser usada para ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#configuring-hubdb-tables-for-dynamic-pages",
              children: "criação de páginas dinâmicas"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"useForPages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowPublicAPIAccess"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se a tabela pode ser lida sem autorização."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowPublicApiAccess\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowChildTables"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se tabelas secundárias podem ser criadas para a tabela."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowChildTables\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableChildTablePages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Se ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "páginas dinâmicas de vários níveis"
            }), " devem ser criadas usando tabelas secundárias."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"enableChildTablePages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["As colunas da tabela. Saiba mais sobre as propriedades de coluna nas seções ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-table-columns",
              children: "Adicionar colunas da tabela"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "See \"Add table columns\" section below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sem nenhuma coluna adicionada, sua solicitação de criação poderá ter a seguinte aparência:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"name\": \"test_table\",\n  \"label\": \"Test Table\",\n  \"useForPages\": false,\n  \"allowPublicApiAccess\": false,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"columns\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Adicionar colunas da tabela"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada coluna de uma Tabela HubDB pode ser definida com as seguintes propriedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obrigatório. O nome interno da coluna. Não pode ser alterado depois que a coluna for criada."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"row_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opcional. O rótulo da coluna que os usuários verão ao editar a tabela no HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\": \"Row label\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de dados da coluna. Deve ser um dos seguintes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TEXT"
                }), ": um campo de texto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "RICHTEXT"
                }), ": um campo de texto que oferece suporte à formatação HTML básica. Não recomendado para HTML bruto, pois pode afetar se o HTML é editável no HubSpot. Editar o código no HubSpot também pode afetar a maneira como o código é renderizado. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NUMBER"
                }), ": um campo de número. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOOLEAN"
                }), ": representado como uma caixa de seleção no HubSpot. Use ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "0"
                }), " para desmarcados e ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                }), " para marcados."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATE"
                }), ": armazena uma data específica como uma data/hora em milissegundos, definido como meia-noite UTC."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATETIME"
                }), ": armazena uma data e uma hora como uma data/hora em milissegundos."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "SELECT"
                }), ": a coluna somente pode ser definida como uma de um conjunto de opções. Veja o campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), " abaixo para propriedades obrigatórias."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MULTISELECT"
                }), ": a coluna pode ser definida para uma ou mais opções de um conjunto. Veja o campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), " abaixo para propriedades obrigatórias."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "LOCATION"
                }), ": armazena a latitude e a longitude de uma localização."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "IMAGE"
                }), ": armazena o URL de uma imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "VIDEO"
                }), ": armazena o ID do reprodutor do vídeo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FOREIGN_ID"
                }), ": a coluna fará referência a uma coluna de outra tabela HubDB. Além disso, você deve definir a outra tabela HubDB com as seguintes propriedades:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: "foreignTableId:o ID da outra tabela HubDB."
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: "foreignColumnId: o ID da coluna na outra tabela HubDB."
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CURRENCY"
                }), ": armazena o número como um valor de moeda."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), ": armazena um arquivo do gerenciador de arquivos. Você também precisará incluir um ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "fileType"
                }), " para especificar se o campo pode armazenar todos os tipos de arquivo (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), ") ou somente tipos de documentos, como PDF (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DOCUMENT"
                }), ")."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"type\": \"type\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista de opções para colunas de seleção e seleção múltipla. Cada opção é definida com um ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", juntamente com um ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), " igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option\": [{\"name\":\"Option 1\", \"type\":\"option\"}, {\"name\": \"Option 2\", \"type\": \"option\"}]"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando os campos acima, sua solicitação para criar uma nova tabela HubDB pode ter a seguinte aparência:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"label\": \"Test Table\",\n  \"name\": \"test_table\",\n  \"columns\": [\n    {\n      \"name\": \"text_column\",\n      \"label\": \"Text Column\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"number_column\",\n      \"label\": \"Number Column\",\n      \"archived\": false,\n      \"type\": \"NUMBER\"\n    },\n    {\n      \"name\": \"multiselect\",\n      \"label\": \"Multi Select Column\",\n      \"archived\": false,\n      \"type\": \"multiselect\",\n      \"options\": [\n        {\n          \"name\": \"Option 1\",\n          \"type\": \"option\"\n        },\n        {\n          \"name\": \"Option 2\",\n          \"type\": \"option\"\n        }\n      ]\n    }\n  ],\n  \"useForPages\": true,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"allowPublicApiAccess\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Após a criação de uma tabela, IDs serão atribuídos às colunas em ordem crescente. Ao atualizar as colunas existentes, inclua o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " da coluna no objeto de entrada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Adicionar linhas da tabela"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode adicionar linhas manualmente por meio da API ou pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#import-rows-from-csv",
        children: "importar linhas de um arquivo CSV"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para adicionar linhas a uma tabela HubDB, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/rows"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para cada linha da tabela, você pode incluir os seguintes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista de pares chave-valor com o nome da coluna e o valor que você deseja adicionar a ela.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "Se não quiser definir um valor específico para uma coluna, você pode omitir o nome da coluna na lista de pares chave-valor."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"values\": { \"text_column\": \"sample text\", \"number_column\": 76}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para tabelas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "habilitadas para páginas dinâmicas"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), " é o sufixo do caminho usado para a página criada para esta linha."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"path\": \"example_url_path\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para tabelas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "habilitadas para páginas dinâmicas"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " é o título HTML usado para a página criada para esta linha."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"Example Title\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ao criar ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "páginas dinâmicas de vários níveis"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), " especifica o ID da tabela secundária."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableId\": 123456"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando os campos acima, o corpo da solicitação pode ser parecido com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"values\": {\n    \"text_column\": \"sample text value\",\n    \"number_column\": 76,\n    \"rich_text_column\": \"<strong>This is a styled paragraph.</strong>\",\n    \"date_column\": 1591228800000,\n    \"date_time_column\": 1604450520000,\n    \"boolean_column\": 1,\n    \"select_column\": {\n      \"name\": \"option 1\",\n      \"type\": \"option\"\n    },\n    \"multiselect_column\": [\n      {\n        \"name\": \"Option 1\",\n        \"type\": \"option\"\n      },\n      {\n        \"name\": \"Option 2\",\n        \"type\": \"option\"\n      }\n    ],\n    \"url_column\": \"https://www.hubspot.com/marketing\",\n    \"video_column\": 3392210008,\n    \"image_column\": {\n      \"url\": \"https://f.hubspotusercontentqa00.net/hubfs/99992002/image3%20(1).jpg\",\n      \"width\": 1600,\n      \"height\": 900,\n      \"type\": \"image\"\n    },\n    \"foreign_id_column\": [\n      {\n        \"id\": \"4364402239\",\n        \"type\": \"foreignid\"\n      },\n      {\n        \"id\": \"4364402240\",\n        \"type\": \"foreignid\"\n      }\n    ]\n  },\n  \"path\": \"test_path\",\n  \"name\": \"test_title\",\n  \"childTableId\": \"1902373\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Importar linhas do CSV"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para importar dados de um arquivo CSV para uma tabela HubDB, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft/import"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ponto de extremidade de importação assume uma faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "config"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " um conjunto de opções formatadas em JSON para a importação."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "file"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " o arquivo CSV que você deseja importar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), ", inclua os seguintes campos como uma string JSON:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "skipRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O número de linhas de cabeçalho que devem ser ignoradas. Este campo assume ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " por padrão, ignorando a primeira linha e tratando-a como um cabeçalho. Defina como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " se todas as linhas forem dados válidos."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"skipRows\": 0"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "separator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O delimitador de coluna no arquivo CSV. Defina ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\",\""
            }), " como padrão."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"separator\": \",\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O índice da coluna no arquivo de origem que contém a ID da linha (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), ").Se esta coluna for especificada, a importação atualizará as linhas existentes na tabela para as IDs de linha correspondentes do arquivo CSV. Isso é opcional e você pode ignorá-lo durante a primeira importação de dados para uma tabela.Consulte a seção ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "Opções de redefinição"
            }), " abaixo para obter informações mais detalhadas."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"idSourceColumn\": 1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "resetTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O padrão é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", significando que as linhas da tabela serão atualizadas sem remover nenhuma linha existente. Se definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", as linhas da planilha substituirão os dados da tabela, o que significa que as linhas da tabela que não estiverem na planilha serão excluídas.Consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "Opções de redefinição"
            }), " abaixo para obter informações mais detalhadas."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"resetTable\": true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para tabelas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "habilitadas para páginas dinâmicas"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            }), " especifica a coluna no arquivo CSV que contém o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " da linha. Os números das colunas estão em ordem crescente, com a primeira coluna sendo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"nameSourcecolumn\": 5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para tabelas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "habilitadas para páginas dinâmicas"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            }), " especifica a coluna no arquivo CSV que contém o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), " da linha. Os números das colunas estão em ordem crescente, com a primeira coluna sendo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"pathSourcecolumn\": 6"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Especifica a coluna no arquivo CSV que contém as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), ". Os números das colunas estão em ordem crescente, com a primeira coluna sendo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableSourcecolumn\": 3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista de mapeamentos das colunas no arquivo de origem para as colunas na Tabela HubDB de destino. Cada mapeamento deve ter o seguinte formato: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"source\":1,\"target”:\"columnIdOrName\"}"
            }), " ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "fonte:"
                }), " o índice de coluna no arquivo de origem. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                }), " para a segunda coluna."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "destino:"
                }), " o ID ou o nome da coluna da tabela HubDB. Você pode obter o ID ou o nome de uma coluna ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#retrieve-hubdb-data",
                  children: "obtendo os detalhes da tabela"
                }), "."]
              })]
            }), "Se seu arquivo tiver uma coluna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), ", ela não deve ser incluída em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), ". Em vez disso, inclua-o como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            }), " para atualizar as linhas existentes."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"columnMappings\": [{\"source\":1, \"target\": 2}, {\"source\": 2, \"target\": \"column_name\"}]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryKeyColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome de uma coluna na tabela HubDB de destino que será usado para desduplicação. O ID da coluna não pode ser usado para este campo."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"primaryKeyColumn\": \"column_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "encoding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de codificação do arquivo. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utf-8"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ascii"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-5"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-2022-jp"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "windows-1252"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"encoding\": \"utf-8\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Somente CSV é suportado."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"format\": \"csv\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando a tabela acima, seu JSON ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), " pode ter a seguinte aparência:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example config JSON\n{\n  \"skipRows\": 1,\n  \"separator\": \",\",\n  \"idSourceColumn\": 1,\n  \"resetTable\": false,\n  \"columnMappings\": [\n    {\n      \"target\": 1,\n      \"source\": 2\n    },\n    {\n      \"target\": 2,\n      \"source\": \"zip_code\"\n    }\n  ],\n  \"primaryKeyColumn\": \"name\",\n  \"encoding\": \"utf-8\",\n  \"format\": \"csv\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se estiver usando cURL, seu comando pode se parecer com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -L -X POST 'https://api.hubspotqa.com/hubdb/api/v2/tables/xxxxxx/import?portalId=xxxxxxx' \\\n-H 'Content-Type: multipart/form-data' \\\n-F 'config=\"{\\\"skipRows\\\":1,\\\"format\\\":\\\"csv\\\",\\\"separator\\\":\\\",\\\",\\\"encoding\\\":\\\"iso-8859-1\\\",\\\"columnMappings\\\":[{\\\"target\\\":1,\\\"source\\\":7},{\\\"target\\\":3,\\\"source\\\":8}],\\\"idSourceColumn\\\":1,\\\"pathSourceColumn\\\":null,\\\"nameSourceColumn\\\":null,\\\"childTableSourceColumn\\\":null,\\\"resetTable\\\":true}\"' \\\n-F 'file=@\"/Users/xxxxxxxxxxxxx/Downloads/filename.csv\"'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formatação de data"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há vários formatos que você pode usar ao importar dados para uma coluna de tipo de data."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Inteiros"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yyyy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yy"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esses formatos exigem que o mês preceda o dia (ou seja, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dd/mm/yy"
      }), " não é aceito). Inteiros podem ser separados por hifens (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), ") ou barras (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Datas flexíveis"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Também é possível importar formatos de data que sejam menos padronizados do que as datas baseadas em inteiros. Por exemplo:**"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "The 1st of March in the year 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Fri Mar 4 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "March 4th '22"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Datas relativa"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot analisará os seguintes formatos de data relativos ao dia atual:**"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "next Thursday"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Today"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "tomorrow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "3 days from now"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Redefinir opções"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao importar dados de um arquivo CSV para uma tabela HubDB, você pode definir o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "resetTable"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " (padrão) para controlar se os dados da linha do HubDB serão substituídos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Se ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), " estiver definido como ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), ":"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se as linhas no arquivo CSV não tiverem uma coluna de ID de linha (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), " ou o ID da linha for especificado como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", essas linhas serão inseridas com os novos IDs de linha gerados."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se os IDs de linha no arquivo CSV já existirem na tabela de destino, as linhas existentes na tabela serão atualizadas com os novos valores do arquivo de entrada."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se a tabela tiver linhas, mas o arquivo CSV de entrada não tiver os IDs dessas linha, as linhas serão excluídas da tabela de destino."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se os IDs de linha no arquivo CSV de entrada não existirem na tabela de destino, essas linhas serão inseridas com os novos IDs de linha gerados e os IDs de linha fornecidos no arquivo de entrada serão ignorados."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se o arquivo CSV de entrada não contiver a coluna de ID de linha, todas as linhas serão excluídas da tabela de destino e as linhas do arquivo de entrada serão inseridas com os novos IDs de linha gerados."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Se ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), " estiver definido como ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), " (padrão):"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se os IDs de linha no arquivo CSV já existirem na tabela de destino, as linhas existentes na tabela serão atualizadas com os novos valores do arquivo de entrada."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se a tabela tiver linhas, mas o arquivo CSV de entrada não tiver os IDs dessas linhas, as linhas ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "não"
            }), " serão excluídas da tabela de destino e permanecerão inalteradas."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se os IDs de linha no arquivo CSV de entrada não existirem na tabela de destino, essas linhas serão inseridas com os novos IDs de linha gerados e os IDs de linha fornecidos no arquivo de entrada serão ignorados."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se as linhas no arquivo CSV não tiverem uma coluna de ID de linha ou se o ID de linha for especificado como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", essas linhas serão inseridas com os novos IDs de linha gerados."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar dados do HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há várias maneiras de recuperar dados do HubDB, dependendo se você está procurando detalhes da tabela ou as linhas de uma tabela:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar detalhes de todas as tabelas publicadas, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar os detalhes de uma tabela publicada específica, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar todas as linhas de uma tabela específica, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}/rows"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar uma linha específica de uma tabela, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}/rows/{rowId}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao recuperar dados de linha, você pode filtrar e classificar os resultados."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se uma tabela estiver definida para ter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "acesso pelo público"
      }), ", você pode acessar a versão publicada da tabela e das linhas sem qualquer autenticação especificando seu ID de conta da HubSpot através do parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Filtrar linhas retornadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao recuperar dados da tabela HubDB, você pode aplicar filtros como parâmetros de consulta para receber dados específicos. Os parâmetros de consulta de filtro são construídos da seguinte forma: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "columnName__operator"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, se você tiver uma coluna numérica chamada ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "barra"
      }), ", poderá filtrar os resultados para incluir somente linhas onde ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "barra"
      }), " for superior a 10: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&bar__gt=10"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Todos os filtros são unidos por E (filtros OU não são suportados no momento)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao filtrar, lembre-se do seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Ao transmitir valores para colunas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect"
          }), ", eles devem ser separados por vírgulas (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect_column__contains=1,2"
          }), ")."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Para filtros ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "datetime"
          }), ", você pode usar datas relativas no lugar de data/hora para especificar um valor relativo à hora atual. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "-3h"
          }), " corresponderia à data e hora de 3 horas antes, ao passo que ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "10s"
          }), " corresponderia a 10 segundos no futuro. As unidades de tempo suportadas são ms (milissegundos), s (segundos), m (minutos), h (horas), d (dias). A hora atual pode ser usada especificando um valor zero: 0s"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Para efeitos destes filtros, a coluna incorporada ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_id"
          }), " é uma coluna ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "number"
          }), ", a coluna ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_created_at"
          }), " é ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "datetime"
          }), " e as colunas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_path"
          }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_name"
          }), " são colunas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba quais operadores podem ser aplicados a quais tipos de coluna:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operador"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq (or none)"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Igual a"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos os tipos de coluna.Este filtro será aplicado se nenhum operador for usado. Quando usado com colunas de seleção múltipla, retorna linhas que correspondem exatamente aos valores fornecidos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ne"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não é igual a"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos os tipos de coluna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contém"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Texto, rich text e seleção múltipla.Quando usado com colunas de seleção múltipla, retorna as linhas que contêm todos os valores fornecidos. Este filtro ", (0, _jsxRuntime.jsx)("u", {
              children: "diferencia maiúsculas e minúsculas"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor que"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número, data e data/hora."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor que ou igual a"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número, data e data/hora."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maior que"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número, data e data/hora."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maior que ou igual a"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número, data e data/hora."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nulo"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Todos os tipos de coluna, exceto booleano. Esse filtro não requer um valor (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__is_null="
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não nulo"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Todos os tipos de coluna, exceto booleano. Esse filtro não requer um valor (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__not_null="
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "É como"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto e richtext."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não é como"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto e richtext."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icontains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contém"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Texto e rich text.Este filtro ", (0, _jsxRuntime.jsx)("u", {
              children: "não"
            }), " diferencia maiúsculas de minúsculas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startswith"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Começa com"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto e richtext."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Em"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Número, seleção e multiseleção.Retorna linhas em que a coluna inclui pelo menos uma das opções transmitidas. Quando não houver outro ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sort"
            }), " no parâmetro de consulta, os resultados serão classificados na ordem em que os valores são especificados no operador ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Classificar as linhas retornadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao recuperar dados do HubDB, você pode aplicar a classificação como um parâmetro de consulta para determinar a ordem dos dados retornados. Para classificar dados, adicione um parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort"
      }), " e especifique o nome da coluna:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=columnName"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por padrão, os dados serão retornados na ordem natural da coluna especificada. Você pode inverter a classificação adicionando um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), " ao nome da coluna:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=-columnName"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode incluir este parâmetro várias vezes para classificar por várias colunas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além de classificar por coluna, há três funções que podem ser usadas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "geo_distance(location_column_name, latitude, longitude):"
          }), " pega o nome de uma coluna de localização e as coordenadas, retorna as linhas ordenadas por quão longe os valores da coluna de localização especificada estão das coordenadas fornecidas."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "length(column_name):"
          }), " pega o nome de uma coluna, retorna as linhas ordenadas pelo comprimento do valor da coluna (calculado como uma string)"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "random():"
          }), " retorna as linhas em ordem aleatória."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Essas funções também suportam classificação inversa. Por exemplo, a seguinte classificação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), " retorna os itens mais distantes primeiro:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort=-geo_distance(location_column,42.37,-71.07)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurar tabelas HubDB para páginas dinâmicas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando o CMS do HubSpot, você pode usar uma tabela HubDB como fonte de dados para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "gerar páginas dinâmicas"
      }), ". Por exemplo, você pode criar uma tabela que contenha uma linha para cada membro da equipe executiva, com colunas contendo informações que você deseja exibir em uma página. Depois de selecionar essa tabela como a fonte de dados dinâmicos de uma página, esta gerará uma página de listagem que exibirá todas as linhas como itens de resumo, juntamente com páginas separadas para cada linha, semelhante a uma página de listagem de blog e posts de blog."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para permitir que uma tabela seja selecionada como fonte de dados no editor de conteúdo, será necessário definir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "useForPage"
      }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Opcionalmente, você pode incluir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamicMetaTags"
      }), " para especificar quais colunas usar para os metadados de cada página."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " ao especificar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamicMetaTags"
        }), ", você precisará verificar se a página está usando as tags ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), " do HubL em vez de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ". Saiba mais no ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb#metadata",
          children: "guia de páginas dinâmicas"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, o código abaixo criaria uma tabela que pode ser usada para páginas dinâmicas e especifica as três colunas a serem usadas para metadados de página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to create table\n{\n  \"name\": \"dynamic_page_table\",\n  \"label\": \"Dynamic page table\",\n  \"useForPages\": true,\n  \"columns\": [\n    {\n      \"name\": \"meta_description\",\n      \"label\": \"Meta description\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"featured_image\",\n      \"label\": \"Featured image\",\n      \"archived\": false,\n      \"type\": \"IMAGE\"\n    },\n    {\n      \"name\": \"canonical_url\",\n      \"label\": \"Canonical URL\",\n      \"archived\": false,\n      \"type\": \"URL\"\n    }\n  ],\n  \"dynamicMetaTags\": {\n    \"DESCRIPTION\": 1,\n    \"FEATURED_IMAGE_URL\": 2,\n    \"LINK_REL_CANONICAL_URL\": 3\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Defina como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " para permitir que a tabela seja usada como uma fonte de dados para páginas dinâmicas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamicMetaTags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Especifica as colunas por ID a serem usadas para metadados em cada página dinâmica. Pode conter:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DESCRIPTION"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FEATURED_IMAGE_URL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINK_REL_CANONICAL_URL"
                })
              })]
            }), "Para qualquer campo de metadados não especificado, as páginas herdarão os respectivos valores da página principal."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DESCRIPTION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID numérico da coluna a ser usado para as metadescrições de cada página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FEATURED_IMAGE_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID numérico da coluna a ser usado para o URL da imagem em destaque em cada página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINK_REL_CANONICAL_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID numérico da coluna a ser usado para o URL canônico de cada página."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alterações na V3"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["As tabelas devem ter ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ". Este nome não pode ser alterado após a criação da tabela. Os nomes somente podem incluir letras minúsculas, dígitos e sublinhados e não podem começar com um número. Ambos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " devem ser exclusivos na conta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A API oferece suporte a ambas as tabelas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " nos caminhos de URL."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " pontos de extremidade da linha retornam o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), "da coluna em vez de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " no campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), ". Além disso, os pontos de extremidade de linha ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PUT"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " exigem a coluna ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " em vez de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " no campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os pontos de extremidade de atualização ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " agora aceitam atualizações esparsas, o que significa que você pode especificar apenas os valores de coluna que precisa atualizar (nas versões anteriores, era necessário especificar todos os valores de coluna). Quando você atualiza uma coluna com uma lista de valores, como seleção múltipla, é necessário especificar a lista de todos os valores. Para excluir o valor de uma coluna, você precisa especificar a coluna com o valor como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " na solicitação."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Removidos os pontos de extremidade para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "update"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "delete"
        }), " uma célula de linha em favor de pontos de extremidade de atualização ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " para linhas."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O recurso para importar ponto de extremidade agora dá suporte a um campo opcional ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "idSourceColumn"
        }), ", juntamente com os campos existentes nas opções formatadas em JSON. Você pode usar esse campo para especificar a coluna no arquivo csv que contém ids de linha. Para importar novas linhas, juntamente com os novos valores para as linhas existentes, você pode simplesmente especificar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), " como o id para as novas linhas e os ids de linha válidos para as colunas existentes. Veja mais detalhes na seção Importar abaixo. Também é possível usar nomes ou ids de coluna no campo de destino dos mapeamentos de coluna nas opções formatadas em JSON."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O clone de ponto de extremidade requer um novo nome e um novo rótulo."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}