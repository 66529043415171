"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 43188701585;
const slug = exports.slug = 'reference/cms/modules/configuration';
const title = exports.title = 'モジュールを設定する';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | [new] Configuring a Module | 20209';
const metaDescription = exports.metaDescription = 'カスタムモジュールを設定する方法についてご確認ください。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B3%E3%81%AE%E8%BF%BD%E5%8A%A0",
  "label": "アイコンの追加",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A9%E3%83%99%E3%83%AB%E3%82%92%E5%A4%89%E6%9B%B4%E3%81%99%E3%82%8B",
  "label": "ラベルを変更する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%81%AB%E3%81%99%E3%82%8B",
  "label": "モジュールをグローバルにする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E4%BD%BF%E7%94%A8%E7%AE%87%E6%89%80%E3%82%92%E5%88%B6%E5%BE%A1%E3%81%99%E3%82%8B",
  "label": "モジュールの使用箇所を制御する",
  "parent": null
}, {
  "depth": 0,
  "id": "css%E3%81%A8javascript%E3%81%AE%E4%BE%9D%E5%AD%98%E9%96%A2%E4%BF%82%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "CSSとJavaScriptの依存関係を追加する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E3%83%BC%E3%81%A8%E3%82%BF%E3%82%B0%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "カテゴリーとタグを追加する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E3%83%BC",
  "label": "カテゴリー",
  "parent": "%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E3%83%BC%E3%81%A8%E3%82%BF%E3%82%B0%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%BF%E3%82%B0",
  "label": "タグ",
  "parent": "%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E3%83%BC%E3%81%A8%E3%82%BF%E3%82%B0%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      ul: "ul",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "モジュールを設定する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "モジュールを作成する際には、モジュールの表示場所、識別される方法、編集方法などを決定するオプションが多数あります。"
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "ローカルで開発する"
            }), "場合、モジュールは", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".module"
            }), "サフィックスが付いたフォルダーとして保存されます。これらのフォルダー内には、モジュールの設定用ファイルと、モジュールのレンダリングに使用されるコードが含まれています。モジュールの設定は", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#meta-json",
              children: "meta.json"
            }), "ファイルに保存されますが、フィールドは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview#fields-json",
              children: "fields.json"
            }), "ファイルで個別に設定されます。"]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["設定の大半は、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/design-manager",
              children: "デザインマネージャー"
            }), "のモジュールエディターからも変更できます。"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/module%20structure.png",
            alt: "ローカルに表示されるモジュールフォルダー構造"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["高いレベルでは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "ファイル内でモジュールオプションをローカルで設定します。これには次のプロパティーを含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュールのアイコンとして使用する画像のURL。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツエディターに表示されるモジュールのラベル"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "パスから独立した、モジュールの固有ID。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotのモジュールエディターの右上隅にあるトグルの値。モジュールがコンテンツで使用可能かどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "global"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュールがグローバルかどうかを示します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host_template_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["モジュールを使用できる", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview",
              children: "コンテンツタイプ"
            }), "の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), "。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "[\"PAGE\", \"BLOG_POST\", \"BLOG_LISTING\", \"EMAIL\"]"
            }), "のうち1つまたは複数です。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["モジュールが依存するCSSファイルを含む", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), "。相対パスを使用できます。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"css_assets\": [{ \"path\": \"../path/to/file.css\" }]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "async"
            }), "を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "にして、モジュールCSSによる非同期レンダリングを行うかどうかを設定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"async\": false}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["モジュールが依存するJavaScriptファイル含む", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), "。相対パスを使用できます。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"js_assets\": [{ \"path\": \"../path/to/file.js\" }]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["レンダリングされたページに追加するモジュールのJavaScriptタグを変更します。次のオプションがあります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "position"
                }), "：", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "head"
                }), "、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "footer"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "async"
                }), "：", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "defer"
                }), "：", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "type"
                }), "：", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "string"
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"position\":\"footer\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inline_help_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["モジュール上部の青い情報ボックスに表示されるヘルプテキスト（300文字まで）。モジュールの使用に必要な情報を指定します。フィールド固有のヘルプテキスト情報を伝達したい場合は、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#properties-used-by-all-fields",
              children: "ヘルプ テキスト フィールドのドキュメント"
            }), "を参照してください。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "master_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-translations-of-your-modules#:~:text=To%20create%20translations%20of%20a,the%20right%2C%20click%20Add%20translations.",
              children: "翻訳が有効になっている"
            }), "場合、モジュールのフィールドの最初の作成時に使用された言語の言語コード。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "en"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["モジュールの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
              children: "プレースホルダーコンテンツ"
            }), "を設定します。次のプロパティーが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_module_icon"
                }), "：モジュールアイコンを表示するかどうか。", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), "：エディターでモジュールに表示するタイトル（文字列）"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), "：エディターでモジュールに表示する説明（文字列）"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "categories"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["最大3つの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-categories",
              children: "モジュールカテゴリー"
            }), "を含む配列。例として、以下のような場合が挙げられます。 ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"categories\":[\"FORMS_AND_BUTTONS\"]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["タグ名と", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"USER\""
            }), "の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "source"
            }), "を含む", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#adding-categories-and-tags",
              children: "モジュール タグ オブジェクト"
            }), "の配列。例として、以下のような場合が挙げられます。 ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"content_tags\": [{"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\" : \"BUTTONS\","
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"source\" : \"USER\"``}]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "個々のモジュール設定オプションの詳細については、以下をご覧ください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アイコンの追加"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "デザインマネージャー"
      }), "に表示されるアイコンと、コンテンツクリエイターのために視覚的なコンテキストを提供するページおよびEメールエディターを含めることができます。テーマ内の異なるタイプのモジュールに対して異なるアイコンを使用することをお勧めします。アイコンは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "マーケットプレイスプロバイダーの場合は必須"
      }), "です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アイコンの追加方法は2種類あり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-design-manager",
        children: "デザインマネージャー"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-cli",
        children: "CMS CLI"
      }), "を使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールアイコンには、サイズが10KB以下の", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".svg"
      }), "ファイルを使用する必要があります。アイコンを最適化するには、シンプルな単色のアイコンにしてください。複数の色を使用するアイコンは自動的に変換されます。レンチとペイントブラシのアイコンが、既定のモジュールアイコンとして表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーを使用してアイコンを追加するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.ol, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Files and Templates"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Design Tools"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Use the left sidebar to locate the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module"
              }), " you want to edit, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module name"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In the right sidebar of the module editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "icon"
              }), " next to the module title."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Upload/select your icon. After publishing your changes, the icon will appear within the page editors and design manager."
            }), "\n"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-icon-crop.png",
            alt: "edit-module-icon-crop"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカルで開発する際にアイコンを追加するには、モジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "ファイルを開き、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "icon"
      }), "パラメーターの値を追加または編集して、ファイルマネージャーからのSVGを指定します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"global\": false,\n  \"host_template_types\": [\"PAGE\"],\n  \"icon\": \"http://example.com/hubfs/code.svg\",\n  \"label\": \"Code block\",\n  \"smart_type\": \"NOT_SMART\",\n  \"is_available_for_new_content\": true,\n  \"inline_help_text\": \"This module is for code snippets.\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ラベルを変更する"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "エディター内に表示されるモジュールのラベルは、モジュールの名前とは別に調整できます。自分に分かりやすい名前を付け、コンテンツ作成者には異なる名前を表示できるので、ローカル環境で開発を行う際に便利です。"
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["ローカル環境では", (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), "パラメーターを変更してラベルを設定します。デザインマネージャーでは、モジュールエディターのモジュール名の下にフィールドがあります。"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-label.png",
            alt: "edit-module-label"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールをグローバルにする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["通常のモジュールの場合、ページ、Eメール、テンプレートの各モジュールインスタンスのコンテンツは独立しています。使い方によっては、モジュールの全てのインスタンスのコンテンツを同じにすると便利な場合があります。ローカルで開発する際は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), "を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "に設定すると、モジュールをグローバルにすることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["デザインマネージャーを使用して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/use-global-content-across-multiple-templates#create-new-global-content-in-the-design-manager:~:text=To%20convert%20an%20existing%20local%20module%20or%20group",
        children: "ドラッグ＆ドロップテンプレートのモジュールをグローバルに変換する"
      }), "こともできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールの使用箇所を制御する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカルで開発する際は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hostTemplateTypes"
      }), "プロパティーにより、モジュールを使用できるコンテンツのタイプを制御できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "利用可能なテンプレートタイプ"
      }), "について詳細をご確認ください。モジュールでは、ページに直接追加できないように、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_available_for_new_content"
      }), "を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), "に設定して非表示にすることもできます。これは、ナビゲーションメニュー用および検索用に作成されたモジュールなどで役立ちます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーでこの更新を行うには、右側のサイドバーにある［テンプレートタイプ］オプションをクリックします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-template-type.png",
        alt: "edit-module-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CSSとJavaScriptの依存関係を追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールインスタンスを含む全てのページにCSSとJavaScriptを追加する際に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "を使用することに加えて、モジュール間で共有される依存関係は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), "および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "js_assets"
      }), "で付加することができます。パスには", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "ファイルに対する絶対パスまたは相対パスを使用することが可能です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"css_assets\": [{ \"path\": \"../path/to/file.css\" }],\n  \"js_assets\": [{ \"path\": \"../path/to/file.js\" }]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**警告：**相対パスを使用して依存関係を参照している場合に、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch --overwrite"
        }), "を実行してローカル開発用モジュールを更新すると、相対パスが絶対パスによって上書きされます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーでは、モジュールエディターの右側のサイドバーにある［リンク済みファイル］セクションを使用して、CSSファイルとJavaScriptファイルをモジュールにリンクできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-linked-files.png",
        alt: "edit-module-linked-files"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カテゴリーとタグを追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールにカテゴリーとタグを割り当てて、HubSpotツール内でモジュールを分類することができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#categories",
          children: "カテゴリー"
        }), "：モジュールにカテゴリーを割り当てると、コンテンツエディターUI内でモジュールをグループに分類できます。これにより、コンテンツクリエイターはHubSpotでコンテンツを作成する際に、より簡単にモジュールを見つけることができます。カテゴリーについては、次の点に注意してください。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1つのモジュールには最大3つのカテゴリーを割り当てることができます。カテゴリーは事前定義されており、カスタマイズすることはできません。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "現在、カテゴリーはコンテンツエディターUIには表示されません。ただし、エディターで分類が利用可能な場合は、カテゴリーを割り当てることができます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "未分類のモジュールは「全ての」カテゴリーで使用できます。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#tags",
          children: "タグ"
        }), "：タグを割り当てると、デザインマネージャー内でモジュールを分類できます。これにより、テンプレートを作成する際に、より簡単にモジュールを見つけることができます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーでは、モジュールエディターの右側のサイドバーにある［タグをフィルター］セクションを使用して、カテゴリーとタグを割り当てることができます。利用可能なカテゴリーとタグの詳細については、以下をご覧ください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-filter-tags.png",
        alt: "edit-module-filter-tags"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカルでは、次のようにモジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "ファイルにカテゴリーとタグを追加できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"label\": \"Custom module\",\n  \"categories\": [\"image_and_video\", \"commerce\"],\n  \"content_tags\": [\n    {\n      \"name\": \"BUTTONS\",\n      \"source\": \"USER\"\n    },\n    {\n      \"name\": \"CTA\",\n      \"source\": \"USER\"\n    }\n  ],\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カテゴリー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "categories"
      }), "配列には、次のカテゴリーを3つまで含めることができます（大文字と小文字は区別されません）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "カテゴリー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ固有のモジュール（最近の投稿リストなど）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツをグラフィカルに表示するようにフォーマットされたモジュール（画像ギャラリーなど）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コマース固有のモジュール（価格カードなど）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "design"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツの構造とレイアウトに影響を与えるモジュール（アコーディオンなど）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "functionality"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページ上の動的な応答または動作を含むモジュール（メニューなど）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms_and_buttons"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サイト訪問者がデータを入力して送信できるモジュール。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "画像、アイコン、動画、バナーなどの要素を含むモジュール。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ソーシャルメディア固有のモジュール（ソーシャルシェアなど）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキストのみを含むモジュール。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "タグ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content_tags"
      }), "配列には、次のモジュール タグ オブジェクトのいずれかを含めることができます（大文字と小文字は区別されます）。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "コンテンツタイプ："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ACCORDION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ANIMATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG_POST"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BUTTONS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CODE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CTA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FEED"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FORM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ICON"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "IMAGE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LISTS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LOGO"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MENU"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "RICH_TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SLIDER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "VIDEO"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "関数："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BANNER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BRANDING"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CALCULATOR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CONVERSION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "EMAIL"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "GALLERY"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "HEADERS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "INTERACTION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LAYOUT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MAP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MULTIMEDIA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "NAVIGATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROGRESS_BAR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SEARCH"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SETTINGS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SOCIAL"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TRANSLATION"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}