"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280489370;
const slug = exports.slug = 'guides/api/crm/pipelines';
const title = exports.title = 'API du CRM | Pipelines';
const name = exports.name = 'EMEA FRANCE (fr) vNext Docs DP - Pipelines_FR';
const metaDescription = exports.metaDescription = 'Les points de terminaison des pipelines sont utilisés pour consulter, créer et gérer des pipelines dans le HubSpot CRM et les outils de service client.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h3: "h3",
      code: "code",
      a: "a",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Pipelines"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans HubSpot, les phases de transaction et les statuts de ticket sont définis dans un pipeline. Pour les ventes, les pipelines peuvent être utilisés pour prédire le chiffre d'affaires et identifier les obstacles. Pour le service client, les pipelines peuvent être utilisés pour gérer les statuts de tickets et analyser les obstacles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Chaque phase d'un pipeline est identifiée par un ID interne unique. Autrement dit, il ne peut y avoir qu'un membre d'un pipeline. Chaque pipeline contient toujours au moins une phase et chaque compte au moins un pipeline."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pipelines par défaut"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque compte contient initialement un pipeline par défaut avec « Par défaut » comme ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pipelineId"
      }), ". Sur les comptes présentant un seul pipeline, la propriété du pipeline pour tout objet sera automatiquement définie sur « Par défaut ». Sur les comptes avec plusieurs pipelines, si vous définissez une phase qui n'est pas dans le pipeline par défaut, vous devrez également définir la propriété de pipeline correspondante."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pipelines multiples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Seuls les comptes Hub Sales Pro ou Entreprise peuvent créer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "plusieurs pipelines de transaction"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["De même, vous devez disposer d'un abonnement Hub Services Pro ou Entreprise pour créer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/tickets/customize-ticket-pipelines-and-statuses",
        children: "plusieurs pipelines de tickets"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/fr/hubspot-product-and-services-catalog",
        children: "niveaux d'abonnement de HubSpot ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemples de cas d'utilisation :"
      }), " Lorsque vous travaillez avec des transactions, un compte peut avoir un pipeline pour « Nouvelles ventes » et un autre pour « Renouvellements de contrat ». Pour les tickets, vous disposez d'une file d'attente de support principale et d'une file d'attente distincte pour les remontées. Chacune de ces files constitue un pipeline de tickets distinct. Les points de terminaison des pipelines peuvent être utilisés pour synchroniser un pipeline ou un autre vers un CRM externe."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}