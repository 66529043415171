"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79429782453;
const slug = exports.slug = 'guides/crm/developer-projects/link-a-github-repository-to-a-project';
const title = exports.title = 'Link a GitHub repository to a project (BETA)';
const name = exports.name = 'Link a GitHub repository to a project (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to connect a GitHub account and link a repository to one of your HubSpot projects (BETA)';
const toc = exports.toc = [{
  "depth": 0,
  "id": "prerequisites",
  "label": "Prerequisites",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-project-in-hubspot",
  "label": "Create a project in HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "link-and-unlink-a-repository",
  "label": "Link and unlink a repository",
  "parent": null
}, {
  "depth": 0,
  "id": "uninstall-the-hubspot-app-in-your-github-account",
  "label": "Uninstall the HubSpot app in your GitHub account",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      code: "code",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Link a GitHub repository to a project (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Connect a GitHub account to HubSpot to automatically trigger a new project build when your team pushes a change to an associated GitHub repository. You can then use familiar GitHub tools and workflows to streamline development on your project. Follow the instructions below to create a new project and associate it with an existing GitHub repository."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prerequisites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before you link your repository, make sure you've run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project create"
      }), " in the root directory of your repository, and you've committed and pushed the resulting code to GitHub. You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/getting-started-project-template",
        children: "getting started project template"
      }), " as a reference for the proper directory structure. If you're creating a project for the first time, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/setup",
        children: "setup guide"
      }), " to configure your local environment."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a project in HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To link your GitHub repository to a new project:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM Development"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Projects"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "New project"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Project from GitHub"
        }), ", then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Connect to GitHub"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Log into your GitHub account, then authorize the HubSpot Projects integration:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To grant HubSpot full access to all repositories in your account, select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "All repositories"
            }), ". If you only want to link a single repository to a project, select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Only select repositories"
            }), ", then select a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "repository"
            }), ". After you've installed the integration, you can always authorize other repositories if needed by updating repository access in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "HubSpot Projects"
            }), " application configuration in your ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/settings/installations/",
              children: "GitHub account settings"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Install & Authorize"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hs-projects-authorize-gh-integration.png",
        alt: "hs-projects-authorize-gh-integration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Back in HubSpot, in the dialog box, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "radio button"
        }), " next to the repository you want to link to your new project."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select branch"
        }), " dropdown menu and select a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "branch"
        }), " that will trigger new builds when you or a team member pushes a change."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Link GitHub repo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hs-projects-confirm-gh-repo.png",
        alt: "hs-projects-confirm-gh-repo"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once linked, HubSpot will create a new build of your project any time someone pushes a change to the repository. If you've turned on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project#builds-and-deploying",
        children: "auto-deploy"
      }), ", the project will be deployed after the build completes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hs-projects-gh-integration-build-details-tab.png",
        alt: "hs-projects-gh-integration-build-details-tab"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["When a project is linked to a GitHub repository, team members ", (0, _jsxRuntime.jsx)("u", {
            children: "cannot"
          }), " upload code directly to the project using the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/developer-projects/project-cli-commands#upload-to-hubspot",
            children: "HubSpot CLI"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["After linking a GitHub repository, if you upgrade your GitHub plan to Enterprise and enforce SAML SSO, you'll need to reauthorize the HubSpot connection. Otherwise, HubSpot will not be able to retrieve existing project repositories or link new ones. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://docs.github.com/en/enterprise-cloud@latest/authentication/authenticating-with-saml-single-sign-on/about-authentication-with-saml-single-sign-on#about-oauth-apps-github-apps-and-saml-sso",
            children: "SAML SSO for GitHub Enterprise"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Link and unlink a repository"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition to creating a new project from an existing repository, you can also link an existing project to a repository. To link a GitHub repository to a project:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CRM Development"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar menu, select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Projects"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the project, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Settings"
          }), " tab."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "GitHub connection"
          }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Link now"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/link-github-repo.png",
            alt: "link-github-repo"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right sidebar, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "repository"
          }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "branch"
          }), " to connect to, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Link GitHub repo"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To unlink a GitHub repository from one of your projects:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Development"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Projects"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of a project."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "GitHub Connection"
        }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Unlink project from GitHub."
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hs-projects-unlink-project-from-github.png",
        alt: "hs-projects-unlink-project-from-github"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Unlink project"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uninstall the HubSpot app in your GitHub account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To fully disconnect GitHub from your HubSpot account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Connected Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the GitHub integration tile, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Uninstall"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The GitHub account will then be disconnected from the account, unlinking any projects in the account that have been linked to the connected branch. Commits to the branch will no longer trigger new builds in HubSpot."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}