"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921797;
const slug = exports.slug = 'guides/api/crm/extensions/timeline';
const title = exports.title = 'CRM API | タイムラインイベント';
const name = exports.name = 'CRM | タイムラインイベント';
const metaDescription = exports.metaDescription = 'タイムラインAPIの概要および使い方。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "イベントテンプレートを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "APIを使用してイベントテンプレートを作成する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "hubspot%E3%81%A7%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "HubSpotでイベントテンプレートを作成する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%82%92%E5%AE%9A%E7%BE%A9%E3%81%99%E3%82%8B",
  "label": "イベントトークンを定義する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "APIを使用してイベントトークンを作成する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%98%E3%83%83%E3%83%80%E3%83%BC%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%8A%E3%82%88%E3%81%B3%E8%A9%B3%E7%B4%B0%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E5%AE%9A%E7%BE%A9%E3%81%99%E3%82%8B",
  "label": "ヘッダーテンプレートおよび詳細テンプレートを定義する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%83%98%E3%83%83%E3%83%80%E3%83%BC%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%8A%E3%82%88%E3%81%B3%E8%A9%B3%E7%B4%B0%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E5%AE%9A%E7%BE%A9%E3%81%99%E3%82%8B",
  "label": "APIを使用してヘッダーテンプレートおよび詳細テンプレートを定義する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "1%E5%9B%9E%E3%81%AE%E5%91%BC%E3%81%B3%E5%87%BA%E3%81%97%E3%81%A7%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E5%85%A8%E3%81%A6%E3%81%AE%E8%A6%81%E7%B4%A0%E3%82%92%E5%AE%9A%E7%BE%A9%E3%81%99%E3%82%8B",
  "label": "1回の呼び出しでイベントテンプレートの全ての要素を定義する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "イベントを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%A0%E3%82%B9%E3%82%BF%E3%83%B3%E3%83%97%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "イベントタイムスタンプを設定する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AB%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "イベントをCRMオブジェクトに関連付ける",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%BF%E3%82%A4%E3%83%A0%E3%83%A9%E3%82%A4%E3%83%B3%E6%8B%A1%E5%BC%B5%E6%A9%9F%E8%83%BD",
  "label": "タイムライン拡張機能",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AE%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AB%E5%AF%BE%E3%81%97%E3%81%A6%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E4%BB%98%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "CRMオブジェクトのプロパティーに対してイベントデータを付加する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "extradata",
  "label": "extraData",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B3%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "カスタムアイコンを設定する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "%E9%96%A2%E9%80%A3%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "関連ドキュメント",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      h3: "h3",
      pre: "pre",
      strong: "strong",
      h4: "h4",
      ul: "ul",
      li: "li",
      em: "em",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "タイムラインイベント"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM拡張機能を使用すると、HubSpotのコンタクト／会社／取引オブジェクトについて外部のシステムからの情報を表示できます。そのためにはカスタム タイムライン イベントを作成し、タイムライン イベント エンドポイントを使用します。ユーザーによるデータ編集を可能にしたいが、既定のCRMオブジェクトの中にニーズに合うものがない場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "カスタムオブジェクト"
      }), "の使用を検討してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_api/event_expanded-2.png",
        alt: "event_expanded-2"
      }), "例えば、自社とのやり取りやコンテンツに基づいて、コンタクトのセグメンテーションを強化したいとします。そのためには、さらに多くの情報が必要です。コンタクトとのやり取りに関して詳細なコンテキスト情報を示すカスタムイベント（最近のウェビナーへの登録のみで欠席したコンタクト、コンタクトが登録を完了したフローなど）を、アプリで作成することが考えられます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントテンプレートを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントを作成するには、事前にイベントテンプレートを作成しておく必要があります。イベントテンプレートでは、HubSpotの中のコンタクト、会社、または取引オブジェクトのタイムラインにアプリが追加するアクションを記述します。アクションの例としては、動画表示、ウェビナー登録、アンケート記入などがあります。アプリごとに、最大750件のイベントテンプレートを作成できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既定では、イベントテンプレートはコンタクトに対して作成されますが、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), "フィールドを使用することによって会社や取引について作成することもできます。詳細は、タイムライン イベント テンプレートの作成に関する説明を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントテンプレートごとに、トークンとテンプレートの固有のセットがあります。コンタクトに対して作成されたイベントを、新しいコンタクトリストまたはワークフローを作成する際の条件として使用できます。例えば、「動画名にXYZが含まれる動画にいいね！を付けた全てのコンタクトのリストを作成する」というように設定できます。この場合、イベントテンプレートは「Video Like」という名前が付けられ、「video name」というイベントトークンを持っています。\""
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "APIを使用してイベントテンプレートを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この例では、新しいイベントテンプレート「Example Webinar Registration」を作成します。認証には開発者APIキーを使用します。これは、アプリ開発者アカウントで確認できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST\n-H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Example Webinar Registration\",\n  \"objectType\": \"contacts\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>''\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "<<appId>>"
      }), "は自分のアプリIDに置き換えてください。アプリIDは、開発者アカウントの__［マイアプリ］またはアプリの詳細ページで確認できます。また、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<developerHapikey>>"
      }), "も自分の開発者APIキーに置き換える必要があります。APIキーは、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "****"
      }), "［アプリ］＞［HubSpot APIキーを取得］の順に進むことで確認できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ここで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "headerTemplate"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "detailTemplate"
      }), "プロパティーも指定できます（下記の「テンプレートの定義」を参照）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストは、保存されているイベントテンプレート定義全体を返します。レスポンス内にある", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "プロパティーに注目してください。これがイベントテンプレートIDで、今後このイベントテンプレートまたはトークンを更新する際に必要になります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のGETコマンドを使用すれば、アプリで定義されている全てのイベントテンプレートを確認できます。この場合も、イベントテンプレートIDが返されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubSpotでイベントテンプレートを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "APIによるタイムライン イベント テンプレートの作成および管理に加えて、HubSpot開発者アカウントでイベントテンプレートを管理することもできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリの設定から［タイムラインイベント］に進み、［イベントタイプを作成］ボタンを使用してアプリ用の新しいイベントテンプレートを作成します。以前に作成したイベントテンプレートがある場合は、ここに表示されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["![image (3)-Jul-23-2020-10-02-24-50-PM](", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://f.hubspotusercontent00.net/hubfs/53/image%20(3)-Jul-23-2020-10-02-24-50-PM.png%EF%BC%89",
        children: "https://f.hubspotusercontent00.net/hubfs/53/image%20(3)-Jul-23-2020-10-02-24-50-PM.png）"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "新しいイベントテンプレートの下書きから始めます。イベントのオブジェクトタイプ、さらに詳細テンプレートとヘッダーテンプレートを設定したら、［作成］をクリックします。\""
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["![image (4)-Jul-23-2020-10-02-24-66-PM](", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://f.hubspotusercontent00.net/hubfs/53/image%20(4)-Jul-23-2020-10-02-24-66-PM.png%EF%BC%89",
        children: "https://f.hubspotusercontent00.net/hubfs/53/image%20(4)-Jul-23-2020-10-02-24-66-PM.png）"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントテンプレートの作成または編集を行う際には、使用するトークンを［データ］タブで設定します。__"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/data-tab-1.png",
        alt: "data-tab-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**テンプレートを削除すると、そのテンプレートを使用していた既存のイベントが、アプリが接続されているアカウントから完全に削除されます。そのタイプのイベントは新規に作成できなくなりますが、リストやレポートでは従来から存在しているタイプのイベントデータは確認できます。この変更がHubSpotに反映されるまでに数時間かかる場合があります。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "イベントトークンを定義する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントテンプレートを定義したら、トークンも定義してみてください。イベント テンプレート トークンを使用することにより、カスタムデータをイベントに添付してタイムラインに表示できます。コンタクトでは、カスタムデータをリストのセグメンテーションや自動化に使用できます。1つのタイムライン イベント テンプレートにつき最大500件のトークンを作成できます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**会社および取引イベントは、リストのセグメンテーションおよび自動化では使用", (0, _jsxRuntime.jsx)("u", {
          children: "できません"
        }), "。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "APIを使用してイベントトークンを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ステップ1で作成したイベントテンプレートIDを使用して、コンタクトが登録しているウェビナーを特定するためのトークンを追加します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarName\",\n  \"label\": \"Webinar Name\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarId\",\n  \"label\": \"Webinar Id\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarType\",\n  \"label\": \"Webinar Type\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"value\": \"regular\",\n      \"label\": \"Regular\"\n    },\n    {\n      \"value\": \"ama\",\n      \"label\": \"Ask me anything\"\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["同様に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "からはイベントテンプレートに定義されている全てのトークンが返されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET -H \"Content-Type: application/json\" 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サポートされているトークンの種類は次のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), " — 選択肢のセット。上記のwebinarTypeの例を参照してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "date"
        }), " — 日付には必ずミリ秒単位のUnix時刻を使用します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注"
        }), "：イベントトークンにlogまたはlookupという名前を付けることはできません。これらのトークンは、Handlebars.js（アプリ内イベントのレンダリングに使用されるライブラリー）によるヘルパーとして予約済みです。詳細は、Handlebars.jsのドキュメント（", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://handlebarsjs.com/builtin_helpers.html",
          children: "こちら"
        }), "）を参照してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ヘッダーテンプレートおよび詳細テンプレートを定義する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ヘッダーテンプレートと詳細テンプレートは、タイムラインイベントの表示方法を定義します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://daringfireball.net/projects/markdown/syntax",
        children: "Markdown"
      }), "ドキュメントを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://handlebarsjs.com/",
        children: "Handlebars"
      }), "テンプレートで指定できます。ヘッダーテンプレートはイベントについての1行の説明文で、詳細テンプレートはイベントに関する具体的な情報です。以下に例を示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベントトークンは、データとしてテンプレートに渡されます。この例では、テンプレートの中で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{webinarName}}"
      }), "を使用することによって", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), "トークンを参照できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベントの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "（後述の「extraDataについて」を参照）は、詳細テンプレートの中でのみ参照できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "APIを使用してヘッダーテンプレートおよび詳細テンプレートを定義する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ヘッダーテンプレートと詳細テンプレートは、イベント テンプレート エンドポイント経由でイベントテンプレートに定義できます。例えば、「Example Webinar Registration」の例では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "を使用して変更を加えることにより、テンプレートを追加できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"id\": \"<<eventTemplateId>>\",\n  \"name\": \"Example Name Change\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "#formatDate"
      }), "ディレクティブの使用にも注目してください。ユーザーにとって分かりやすい日付形式にするために定義しています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このようにコンタクトに関するイベントを作成すると（後述の「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#creating-an-event",
        children: "イベントの作成"
      }), "」を参照）、コンタクトのタイムラインに次のように表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［詳細を表示］をクリックすると、詳細テンプレートが表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_expanded.png?width=640&name=event_expanded.png",
        alt: "event_expanded.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベントの横に表示されるアイコンを設定するには、後述の「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline#customicon",
        children: "カスタムアイコンの設定"
      }), "」を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここでの「Example App Name」というテキストはアプリの名前です。CRMタイムライン内では、アプリ単位でイベントを絞り込むことができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1回の呼び出しでイベントテンプレートの全ての要素を定義する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ここまではイベントテンプレートの各要素を紹介してきましたが、1回の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "呼び出しで全ての要素を定義できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Another Webinar Registration\",\n  \"objectType\": \"contacts\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\",\n  \"tokens\": [\n    {\n      \"name\": \"webinarName\",\n      \"label\": \"Webinar Name\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarId\",\n      \"label\": \"Webinar Id\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarType\",\n      \"label\": \"Webinar Type\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"value\": \"regular\",\n          \"label\": \"Regular\"\n        },\n        {\n          \"value\": \"ama\",\n          \"label\": \"Ask me anything\"\n        }\n      ]\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["トークンとテンプレートを備えたイベントテンプレートを設定できました。次は、顧客のコンタクト、会社、取引、およびチケットのイベントを作成します。ここまでで作成した", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contacts"
      }), "イベントテンプレートを、以降の例でも使用します。イベントテンプレートの設定に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarId"
      }), "のトークンが含まれていない場合は、イベントを作成する際にエラーが発生します。イベントを作成するための", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "の例を次に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注"
        }), "：開発者APIキーと非公開アプリのアクセストークンを、イベントの作成時に認証として使用することは", (0, _jsxRuntime.jsx)("u", {
          children: "できません"
        }), "。イベントを作成するには、関連するHubSpotアカウントから、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth"
        }), "を使用してアプリにアクセス権を付与する必要があります。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "OAuthアクセストークン"
        }), "を取得したら、それを使用してアカウントにイベントを追加できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これにより、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a.test.contact@email.com"
      }), "'のタイムライン上にイベントが生成されます（前述の「テンプレートの定義」のテンプレートがある前提で）。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "イベントタイムスタンプを設定する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントがオブジェクト内のタイムラインに現れる位置は、イベントのタイムスタンプによって決まります。イベントタイムスタンプには、既定でPOSTコマンドの送信時刻が設定されます。イベント時刻は、タイムスタンププロパティーのリクエスト本文に明示することによりカスタマイズできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"timestamp\": \"2020-03-18T15:30:32Z\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "特定のアクションが発生した正確な時刻が分かっている場合は、明示することが適切です。この例の場合、このウェビナー登録のタイムスタンプが分かっていればPOST内で指定してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["タイムスタンプは、ミリ秒単位のエポック時間、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/ISO_8601",
        children: "ISO 8601"
      }), "形式です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "イベントをCRMオブジェクトに関連付ける"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントを作成するには、顧客のアカウント内のコンタクト、会社、または取引に対し、イベントを関連付けなければなりません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["前述の例では、objectTypeにコンタクトを指定し、Eメールを使用してイベントをコンタクトに関連付けました。HubSpot上ではコンタクトのEメールアドレスは重複しないため、指定されたEメールのコンタクトがすでに存在する場合、そのコンタクトが更新されることになります。既存のコンタクトがない場合は、新しいコンタクトが作成されます。既定では、指定したEメール コンタクト プロパティーのみがこの新しいコンタクトに格納されます。コンタクトプロパティーにデータを追加する方法については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#stamp-event-data-onto-crm-object-properties",
        children: "コンタクトプロパティーに対するイベントデータの付加"
      }), "に関する説明を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既存のコンタクトの場合、イベントの関連付けにコンタクトの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), "を使用することもできます。その場合、リクエストJSONの中で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "を使用します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "を使用してコンタクトを新規に作成することはできないため、既存のコンタクトのvidを含める必要があります。この例では、Eメールではなく", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "を使用しています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"29851\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ユーザートークンまたは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), "により、イベントをコンタクトに関連付けることもできます。ユーザートークンは、HubSpotトラッキングコードでの訪問者のトラッキングに使用され、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspotutk"
      }), "のCookie内に格納されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), "パラメーターは、ユーザートークンによってイベントをコンタクトに関連付ける際に使用します。注：匿名の訪問者にはユーザートークンを使用してイベントを関連付けることができません。したがってイベントの関連付けに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), "のみが使用されていて、かつ提供されているユーザートークンがまだコンタクトに関連付けられていない場合は、新しいコンタクトが作成されず、イベントはHubSpot上で確認できなくなります。しかし、新しいコンタクトが別の手段（通常は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "hutkを含むフォーム送信"
      }), "（英語）、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code",
        children: "トラッキングコードAPIのidentifyメソッド"
      }), "（英語））によってユーザートークンに関連付けられていた場合は、イベントがタイムラインに表示されます。そのため、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), "に加えて", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "を指定することにより、イベントを新しいコンタクトまたは既存のコンタクトに関連付けることをお勧めします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクトのイベントテンプレートの場合、イベントに複数の識別パラメーターを指定できるため、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), "のパラメーターを組み合わせて指定することができます。複数のパラメーターを含めた場合、イベントに関連付けるコンタクトの判定ではobjectId（vid）が最も優先され、次に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), "、そして最後に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "が使用されます。したがって、既存のオブジェクトの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), "を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "に、また", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "パラメーターに新しいEメールアドレスを指定することによって、既存のオブジェクトのEメールアドレスを更新することができます。この例では、Eメールアドレスとユーザートークンを組み合わせています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"utk\": \"89b5afb740d41f4cd6651ac5237edf09\"\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクトだけでなく、会社や取引についてのイベントテンプレートも作成できます。その場合、イベントテンプレートに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "を指定して、会社または取引にイベントを関連付ける必要があります。会社の場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "にイベントを関連付ける会社の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), "を設定する必要があります。また、取引の場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "に取引オブジェクトの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealId"
      }), "を設定します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の例の場合、イベントテンプレートが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPANY"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), "に設定されているとすると、このイベントは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " 528253914の会社オブジェクトに関連付けられることになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"3001\",\n  \"tokens\": {\n    \"dealProperty\": \"Custom property for deal\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "タイムライン拡張機能"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "タイムライン拡張機能を使用すると、iFrameを使用して外部システムのデータを表示できるようになります。含まれている場合、イベントにモーダルウィンドウを開くリンクが表示されます。リンクをクリックすると、iFrameのコンテンツが表示されます。iFrameの詳細はtimelineIFrameフィールドに指定します。このオブジェクトには、次のフィールドがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "linkLabel"
        }), " - ：iFrameを表示するリンクに示されるテキスト。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "headerLabel"
        }), " - ：iFrameコンテンツを表示するモーダルウィンドウのラベル。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), " - ：iFrameコンテンツのURI。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "width"
        }), " - ：モーダルウィンドウの幅。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "height"
        }), " - ：モーダルウィンドウの高さ。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、イベントに次のデータを使用した場合："
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"timelineIFrame\": {\n    \"linkLabel\":\"View external data\",\n    \"headerLabel\":\"Example iframe\",\n    \"url\":\"https://www.example.com\",\n    \"width\":800,\n    \"height\":300\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "「View external data」リンクを含む、次のイベントが作成されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/external_data_link.png?width=640&name=external_data_link.png",
        alt: "external_data_link.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このリンクをクリックするとモーダルウィンドウが開き、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "url"
      }), "に設定されているページが表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/iframe_modal.png?width=640&name=iframe_modal.png",
        alt: "iframe_modal.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CRMオブジェクトのプロパティーに対してイベントデータを付加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントを追加するコンタクト、会社、取引のプロパティーに、変更が必要になるケースは少なくありません。特に、イベントを追加することでコンタクトが作成される場合が該当します。Eメールアドレスとイベントだけのコンタクトを作成しないためにも、コンタクトの姓名のプロパティーを更新する必要があるはずです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタム イベント トークンをコンタクト、会社、または取引のプロパティーにマッピングすることにより、イベントからの関連オブジェクトのデータを付加することができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタム イベント テンプレートを更新するための次の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "コマンドで、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), "フィールドに注目してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"label\" : \"Updated Webinar Name\",\n  \"objectPropertyName\": \"zz_webinar_name\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens/<<tokenName>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), "を使用して、このカスタム イベント トークンを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), "オブジェクトの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), "プロパティーにマッピングしています。つまり、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), "トークンを指定した新しいイベントを作成すると、関連する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), "の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), "プロパティーも設定されます。この設定は、カスタムまたは事前定義済みのHubSpotプロパティーに対して行うことができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、コンタクトの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), "カスタムプロパティーを参照する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyName"
      }), "トークンを事前に作成しているとします。その場合に次のようなイベントを作成すると、Eメールアドレスが", (0, _jsxRuntime.jsx)(_components.a, {
        href: "mailto:a.test.contact@email.com",
        children: "a.test.contact@email.com"
      }), "のコンタクトに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), "プロパティーが設定されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/set_property.png?width=1024&name=set_property.png",
        alt: "set_property.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "注：イベントトークンがカスタムプロパティーに付加される際に該当するカスタムプロパティーがHubSpotアカウント上に存在しない場合、イベントには値が設定されますが、対応するオブジェクト側では無視されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h3, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "について"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベント テンプレート トークンによって使用される「トークンと値」の単純な構造が適さない詳細なデータを、イベントに追加することが必要になるかもしれません。あるいは連携のイベントに、リストまたは階層状の内訳が必要になる場合も考えられます。こうした状況で役立つのが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "属性をイベントのJSON本文に追加することができます。この", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "の値として、任意の有効なJSONを指定できます。以下に例を示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"extraData\": {\n    \"pollData\": [\n      {\n        \"question\": \"How excited are you for this webinar?\",\n        \"answer\":\"Quite!\"\n      },\n      {\n        \"question\": \"How frequently do you use our product?\",\n        \"answer\":\"Daily\"\n      }\n    ],\n    \"coWorkers\": [\n      {\n        \"name\": \"Joe Coworker\",\n        \"email\":\"joe.coworker@testco.com\"\n      },\n      {\n        \"name\": \"Jane Coworker\",\n        \"email\":\"jane.coworker@testco.com\"\n      }\n    ]\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳細テンプレートでの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "の使用例："]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "//\nRegistration occurred at {{#formatDate timestamp}}{{/formatDate}}\n\n#### Poll Questions\n{{#each extraData.pollData}}\n  **{{question}}**: {{answer}}\n{{/each}}\n\n#### Co-Workers\n{{#each extraData.coWorkers}}\n  * {{name}}\n{{/each}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "タイムラインイベントには次のように表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/extra_data.png?width=640&name=extra_data.png",
        alt: "extra_data.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["注：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "属性は、イベントの詳細テンプレート内でのみ参照できます。ヘッダーテンプレートやリストセグメンテーションの中では使用できません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタムアイコンを設定する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "タイムライン上の項目にビジュアル効果を付けるために、必要に応じてカスタムアイコンを追加できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アイコンには、次のような画像ファイルを使用してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ほぼ正方形に近い形"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "背景を透過にする"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コンテンツをアイコンの中央に配置する"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "30×30ピクセルまで縮小可能"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ファイルサイズが5MB以下"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "タイムラインイベントに使用するアイコンを設定するには、［タイムラインイベント］に進みます。プレースホルダー画像または既存のアイコンをクリックして、設定または変更します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_assets.png",
        alt: "timeline_assets"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "設定したアイコンは、アプリに関連する全てのタイムラインイベントの横に表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/timeline_icon.png?width=640&name=timeline_icon.png",
        alt: "timeline_icon.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "関連ドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRMについて"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "CRMカード"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}