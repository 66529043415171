"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358754;
const slug = exports.slug = 'guides/api/crm/objects/contacts';
const title = exports.title = 'CRM API | コンタクト';
const name = exports.name = 'vNext Docs DP - コンタクト';
const metaDescription = exports.metaDescription = 'コンタクトレコードには、個人に関する情報が保存されます。コンタクトエンドポイントを使用することにより、コンタクトデータを管理したり、HubSpotと他のシステムとの間で同期したりできます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "コンタクトを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "プロパティー",
  "parent": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91",
  "label": "関連付け",
  "parent": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89id%E3%80%81e%E3%83%A1%E3%83%BC%E3%83%AB%E3%80%81%E3%81%BE%E3%81%9F%E3%81%AF%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%81%AE%E5%9B%BA%E6%9C%89%E5%80%A4%E3%81%AE%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E6%8C%87%E5%AE%9A%E3%81%97%E3%81%A6%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "レコードID、Eメール、またはカスタムの固有値のプロパティーを指定してコンタクトを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "コンタクトを更新する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E4%BB%96%E3%81%AE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%BE%E3%81%9F%E3%81%AF%E3%82%A2%E3%82%AF%E3%83%86%E3%82%A3%E3%83%93%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AB%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "既存のコンタクトを他のレコードまたはアクティビティーに関連付ける",
  "parent": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "関連付けを削除する",
  "parent": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%A7%E3%82%A2%E3%82%AF%E3%83%86%E3%82%A3%E3%83%93%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E3%83%94%E3%83%B3%E7%95%99%E3%82%81%E3%81%99%E3%82%8B",
  "label": "コンタクトレコードでアクティビティーをピン留めする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E5%89%8A%E9%99%A4",
  "label": "コンタクトを削除",
  "parent": null
}, {
  "depth": 0,
  "id": "%E4%B8%8A%E9%99%90",
  "label": "上限",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "コンタクト"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのコンタクトレコードには、自社とやり取りする個人に関する情報が保存されます。コンタクトエンドポイントを使用することにより、HubSpotアカウントでコンタクトレコードを作成および管理したり、HubSpotと他のシステムの間でコンタクトデータを同期したりできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["オブジェクト、レコード、プロパティー、および関連付けAPIの詳細については、「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRMについて"
      }), "」のガイドをご確認ください。HubSpotのオブジェクトとレコードに関する一般的な情報については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://knowledge.hubspot.com/contacts-user-guide",
        children: "CRMデータベースを管理する方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンタクトを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しいコンタクトを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエスト内のpropertiesオブジェクトにコンタクトデータを含めます。また、associationsオブジェクトを追加して、新しいコンタクトを既存のレコード（会社、取引など）やアクティビティー（ミーティング、メモなど）に関連付けることもレコードできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "プロパティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクトの詳細は、コンタクトプロパティーに保存されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/hubspots-default-contact-properties",
        children: "HubSpotの既定のコンタクトプロパティー"
      }), "がありますが、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "カスタム コンタクト プロパティーを作成"
      }), "することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しいコンタクトを作成する際は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), "、または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), "プロパティーのうち、", (0, _jsxRuntime.jsx)("u", {
        children: "少なくとも1つ"
      }), "をリクエストに含める必要があります。Eメールアドレスはコンタクトを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/deduplication-of-contacts-companies-deals-tickets#automatic-deduplication-in-hubspot",
        children: "一意に識別する主要なID"
      }), "であるため、HubSpotでコンタクトが重複しないようにするために、常に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "を含めることを推奨します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["利用可能な全てのプロパティーを確認するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/contacts"
      }), "に送信することで、アカウントのコンタクトプロパティーのリストを取得できます。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "プロパティーAPI"
      }), "をご参照ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**リクエストに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lifecyclestage"
        }), "を含める場合、値はライフサイクルステージの内部名を参照する必要があります。既定のステージの内部名は", (0, _jsxRuntime.jsx)("u", {
          children: "テキスト"
        }), "値であり、ステージの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties#:~:text=the%20properties%20settings.-,Label/Name%3A,-enter%20a%20unique",
          children: "ラベル"
        }), "（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriber"
        }), "や", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketingqualifiedlead"
        }), "など）を編集してもこれは変更されません。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages",
          children: "カスタムステージ"
        }), "の内部名は", (0, _jsxRuntime.jsx)("u", {
          children: "数値"
        }), "です。ステージの内部IDを見つけるには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages#:~:text=To%20edit%20a%20lifecycle%20stage%2C%20hover%20over%20the%20stage%20and%20click%20Edit.%20In%20the%20right%20panel%2C%20edit%20the%20Stage%20name%2C%20then%20click%20Edit%20lifecycle%20stage%20to%20confirm.%20Click%20the%20code%20codcode%20icon%20to%20view%20the%20stage%27s%20internal%20ID%2C%20which%20is%20used%20by%20integrations%20and%20APIs.",
          children: "ライフサイクルステージ設定"
        }), "を確認するか、APIを使ってライフサイクル ステージ プロパティーを取得できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、新しいコンタクトを作成する場合、リクエストの内容は次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付け"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、新しいコンタクトを作成する際に、associationsオブジェクトを含めることでそのコンタクトを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "既存のレコード"
      }), "や", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "アクティビティー"
      }), "に関連付けることもできます。例えば、新しいコンタクトを既存の会社とEメールに関連付ける場合、リクエストの内容は次のようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 279\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 556677\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 197\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のものをassociationsオブジェクトに含める必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンタクトに関連付けるレコードまたはアクティビティー。一意の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "値で指定します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンタクトとレコード／アクティビティーの間の関連付けのタイプ。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "および", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), "を含めます。既定の関連付けタイプIDは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "このページ"
            }), "に記載されています。または、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "関連付けAPI"
            }), "を使用して、カスタム関連付けタイプ（ラベル）の値を取得することもできます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "レコードID、Eメール、またはカスタムの固有値のプロパティーを指定してコンタクトを取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンタクトレコードを個別に、または一括で取得できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["個々のコンタクトを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{contactId} or``/crm/v3/objects/contacts/{email}?idProperty=email"
        }), "に送信します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["全てのコンタクトのリストを取得するには", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts"
        }), "に送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらのエンドポイントに対し、リクエストのURLに次のクエリーパラメーターを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レスポンスで返されるプロパティーからなるカンマ区切りリスト。リクエスト対象のコンタクトレコードで、指定したプロパティーに値が設定されていない場合、そのプロパティーはレスポンスに含まれません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レスポンスで返される現在および過去のプロパティーからなるカンマ区切りリスト。リクエスト対象のコンタクトレコードで、指定したプロパティーに値が設定されていない場合、そのプロパティーはレスポンスに含まれません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["関連付けられたIDを取得する対象のオブジェクトからなるカンマ区切りリスト。存在しない関連付けを指定した場合、その関連付けはレスポンスで返されません。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "関連付けAPI"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["レコードID、Eメールアドレス、または", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "カスタムの固有の識別子のプロパティー"
        }), "を指定して、特定のコンタクトを一括で取得するには", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/contacts/batch/read"
        }), "に対して", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "リクエストを送信します。このバッチエンドポイントは関連付けを取得", (0, _jsxRuntime.jsx)("u", {
          children: "できません"
        }), "。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "関連付けAPI"
        }), "を使用してバッチが関連付けを読み取る方法について説明します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["一括読み取りエンドポイントの場合は、任意指定の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "パラメーターを使用し、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "またはカスタムの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "固有の識別子のプロパティー"
      }), "を指定して、コンタクトを取得することもできます。既定で、リクエストの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "値はレコードID（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "）を参照しますから、レコードIDを使って取得するときには", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "パラメーターは不要です。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "またはカスタムの固有の値のプロパティーを使用してコンタクトを取得する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "パラメーターを含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、レコードIDの値に基づいてコンタクトを一括で取得する場合のリクエストは、次のようになります（現在の値のみ、または現在と過去の値）。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body with record ID (current values)\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"inputs\": [\n    {\n      \"id\": \"1234567\"\n    },\n    {\n      \"id\": \"987456\"\n    }\n  ]\n}\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"lifecyclestage\", \"hs_lead_status\"],\n  \"inputs\": [\n    {\n      \"id\": \"1234567\"\n    },\n    {\n      \"id\": \"987456\"\n    }\n  ]\n}\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eメールアドレスまたはカスタムの固有の識別子のプロパティー（例：貴社に固有のお客様ID番号）に基づいてコンタクトレコードを取得する場合のリクエストは、次のようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with email address\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"idProperty\": \"email\",\n  \"inputs\": [\n    {\n      \"id\": \"lgilmore@thedragonfly.com\"\n    },\n    {\n      \"id\": \"sstjames@thedragonfly.com\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with a unique value property\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"idProperty\": \"internalcustomerid\",\n  \"inputs\": [\n    {\n      \"id\": \"12345\"\n    },\n    {\n      \"id\": \"67891\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンタクトを更新する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンタクトレコードを個別に、または一括で更新できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)("u", {
        children: "個別の"
      }), "\nコンタクトを更新するには、レコードID（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "）またはコンタクトのEメールアドレス（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "）を使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["レコードIDを基準に個別のコンタクトを更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{contactId}"
        }), "に送信し、更新対象のデータを含めます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Eメールを指定して個別のコンタクトを更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "リクエストを ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{email}?idProperty=email"
        }), "に送信し、更新対象のデータを含めます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下に例を示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with record ID\n// PATCH /crm/v3/objects/contacts/123456789\n{\n  \"properties\": {\n    \"favorite_food\": \"burger\",\n    \"jobtitle\": \"Manager\",\n    \"lifecyclestage\": \"Customer\"\n  }\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with email address\n// PATCH /crm/v3/objects/contacts/lgilmore@thedragonfly.com?idProperty=email\n{\n  \"properties\": {\n    \"favorite_food\": \"Donut\",\n    \"jobtitle\": \"Owner\",\n    \"phone\": \"5555555555\"\n  }\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)("u", {
        children: "一括で"
      }), "\nコンタクトを更新するには、コンタクトのレコードID値（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "）を使用できます。複数のコンタクトを更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/batch/update"
      }), "に送信します。リクエスト本文に、各コンタクトのレコードIDを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "として含め、更新対象のプロパティーを含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下に例を示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n// POST /crm/v3/objects/contacts/batch/update\n{\n  \"inputs\": [\n    {\n      \"id\": \"123456789\",\n      \"properties\": {\n        \"favorite_food\": \"burger\"\n      }\n    },\n    {\n      \"id\": \"56789123\",\n      \"properties\": {\n        \"favorite_food\": \"Donut\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既存のコンタクトを他のレコードまたはアクティビティーに関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクトを他のCRMレコードまたはアクティビティーに関連付けるには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), "値を取得するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "この既定値リスト"
        }), "を参照するか、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "に送信します。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "関連付けAPI"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付けを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクトとレコードまたはアクティビティーの間の関連付けを削除するには、次のURLに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを送信します：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactID}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンタクトレコードでアクティビティーをピン留めする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエストに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "フィールドを含めることで、コンタクトレコードで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "アクティビティーをピン留め"
      }), "できます。フィールドに、ピン留めするアクティビティーの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "を含めます。このIDは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "エンゲージメントAPI"
      }), "を介して取得できます。レコードごとに1つのアクティビティーをピン留めすることができ、そのアクティビティーはピン留めする前に既にコンタクトに関連付けられている必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンタクトのピン留めされたアクティビティーを設定または更新するには、次のようにリクエストします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body PATCH /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンタクトを作成し、既存のアクティビティーに関連付け、同じリクエストにアクティビティーをピン留めすることもできます。以下に例を示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body POST /crm/v3/objects/contacts\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 201\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンタクトを削除"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクトレコードを個別に、または一括で削除できます。削除したコンタクトレコードは、HubSpot内のごみ箱に追加されます。そのため、必要に応じて後で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "HubSpot内でコンタクトを復元"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["IDを基準に個々のコンタクトを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンタクトを一括で削除する方法については、この記事の上部にある［エンドポイント］__タブをクリックしてください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "上限"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["一度に100レコードまで一括処理できます。例えば、1つのリクエストで100件を超えるコンタクトを一括で更新することはできません。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/api/faq#limits_contacts",
        children: "コンタクトとフォーム送信"
      }), "にも上限があります。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}