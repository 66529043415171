"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125387;
const slug = exports.slug = 'reference/cms/hubl/if-statements';
const title = exports.title = 'Sentencias If';
const name = exports.name = 'LATAM (ES) if statements';
const metaDescription = exports.metaDescription = 'Una guía práctica de las declaraciones if de Hubl de HubSpot para desarrolladores de CMS.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "sintaxis-b%C3%A1sica-de-la-sentencia-if",
  "label": "Sintaxis básica de la sentencia if",
  "parent": null
}, {
  "depth": 0,
  "id": "usando-elif-y-else",
  "label": "Usando elif y else",
  "parent": null
}, {
  "depth": 0,
  "id": "sentencias-unless",
  "label": "Sentencias unless",
  "parent": null
}, {
  "depth": 0,
  "id": "ifchanged",
  "label": "ifchanged",
  "parent": null
}, {
  "depth": 0,
  "id": "sentencias-if-en-l%C3%ADnea",
  "label": "Sentencias if en línea",
  "parent": null
}, {
  "depth": 0,
  "id": "operadores-ternarios",
  "label": "Operadores ternarios",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      code: "code",
      h2: "h2",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Sentencias If"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes incluir lógica condicional en tus módulos y plantillas utilizando las", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#basic-if-statement-syntax",
        children: "sentencias if"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#unless-statements",
        children: "sentencias unless"
      }), " de HubL. Las sentencias If suelen contener ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "operadores compatibles"
      }), " con HubL y pueden utilizarse para ejecutar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "pruebas de expresión"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si utilizas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#personalization-token",
          children: "tokens de personalización"
        }), " dentro de una sentencia condicional de tu módulo de correo electrónico, debes ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
          children: "activar el correo electrónico programable para el módulo"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["La información pasada a través de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/transactional-emails#single-send-api",
          children: "v3"
        }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/single-send-api",
          children: "v4"
        }), " de la API de envío único no funcionará dentro de las declaraciones ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if"
        }), ", ya que las plantillas se compilan antes de que la información se rellene."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sintaxis básica de la sentencia if"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL utiliza sentencias if para ayudar a definir la lógica de una plantilla. La sintaxis de las sentencias if de HubL es muy similar a la lógica condicional de Python. Las sentencias ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " están envueltas en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "delimitadores de sentencias"
      }), ", comenzando con una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " de apertura y terminando con un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El ejemplo siguiente proporciona la sintaxis básica de una sentencia if, en la que \"condición\" se sustituiría por la regla booleana que ibas a evaluar como verdadera o falsa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if condition %}\n\tIf the condition is true print this to template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora que has visto la sintaxis básica, veamos algunos ejemplos reales de sentencias if básicas. Los siguientes ejemplos muestran sentencias if que comprueban si un módulo HubL con el nombre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), " y si una variable llamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), " están presentes en una plantilla. Observa que sin ningún operador, la sentencia if evaluará si el módulo está definido o no en el contexto de la plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_module\" path=\"@hubspot/rich_text\", label=\"My rich text module\", html=\"Default module text\" export_to_template_context=true %}\n\n{% if widget_data.my_module %}\n\tA module named \"my_module\" is defined in this template.\n{% endif %}\n\n\n{% set my_variable = \"A string value for my variable\" %}\n{% if my_variable %}\n\tThe variable named my_variable is defined in this template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Observa que al evaluar el módulo HubL, el nombre del módulo se deja entre comillas dentro de la sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " y al probar la variable no se utilizan comillas alrededor del nombre de la variable. En los dos ejemplos anteriores, el módulo y la variable existen en la plantilla, por lo que las sentencias se evalúan para imprimir la marca. Ten en cuenta que estos ejemplos solo prueban si el módulo y la variable están definidos, no si tienen o no un valor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora veamos una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " que evalúa si un módulo tiene un valor, en lugar de evaluar si existe en la plantilla. Para ello, debemos utilizar el parámetro ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "export_to_template_context"
      }), " . En el ejemplo siguiente, si se valora el módulo de texto en el editor de contenidos, se imprimiría la marca. Si el campo de texto del módulo estuviera vacío, no se mostraría ninguna marca. Si estás trabajando con módulos personalizados, existe una sintaxis simplificada de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.widget_name"
      }), " que se describe en el", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "ejemplo aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"product_names\" path=\"@hubspot/text\", label=\"Enter the product names that you would like to render the coupon ad for\", value=\"all of our products\", export_to_template_context=True %}\n\n{% if widget_data.product_names.value %}\n<div class=\"coupon-ad\">\n<h3>For a limited time, get 50% off {{ widget_data.product_names.value}}! </h3>\n</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"coupon-ad\">\n  <h3>For a limited time get 50% off all of our products!</h3>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usando elif y else"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las sentencias ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " pueden hacerse más sofisticadas con sentencias condicionales adicionales o con una regla que se ejecuta cuando la condición o las condiciones son falsas. Las sentencias ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " te permiten agregar condiciones adicionales a tu lógica que se evaluarán después de la condición anterior. Las sentencias ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "else"
        })
      }), " definen una regla que se ejecuta cuando todas las demás condiciones son falsas. Puedes tener un número ilimitado de sentencias ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " dentro de una sola sentencia if, pero solo una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación se muestra el ejemplo de sintaxis básica de la sentencia if que utiliza el ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: ["operador ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<="
        })]
      }), " para comprobar el valor de una variable. En este ejemplo, la plantilla se imprimirá con la leyenda: \"El número con nombre de la variable es menor que o igual a 6.\""]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set number = 5 %}\n\n{% if number <= 2 %}\n\tVariable named number is less than or equal to 2.\n{% elif number <= 4 %}\n\tVariable named number is less than or equal to 4.\n{% elif number <= 6 %}\n\tVariable named number is less than or equal to 6.\n{% else %}\n\tVariable named number is greater than 6.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación se muestra otro ejemplo que utiliza un módulo de elección para mostrar diferentes encabezados para una página de carreras, en función del departamento elegido por el usuario. El ejemplo utiliza el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: "operador =="
      }), ", para comprobar ciertos valores predefinidos en el módulo de elección."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sentencias unless"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las sentencias ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), " son condicionales al igual que las sentencias ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", pero funcionan con la lógica inversa. Se renderizará y compilará el código entre las etiquetas de apertura y cierre, a menos que la condición booleana única se evalúe como verdadera. Las sentencias unless comienzan con un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "unless"
        })
      }), " y terminan con un ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endunless"
        }), "."]
      }), " Las sentencias ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), " admiten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " pero no ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se muestra un ejemplo que imprime un encabezado \"En construcción\", a menos que se valore el campo de texto enriquecido. Si el campo de texto enriquecido tiene contenido, entonces ese contenido se mostrará."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_page_content\" path=\"@hubspot/rich_text\", label=\"Enter your page content\", html=\"\" export_to_template_context=true %}\n\n{{ widget_data.my_page_content.html }}\n\n{% unless widget_data.my_page_content.html %}\n<h1>This page is under construction.</h1>\n<h3>Come back soon!</h3>\n{% endunless %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ifchanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de las sentencias if y unless, HubL admite las sentencias ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ifchanged"
      }), ". Estas sentencias pueden utilizarse para que solo se muestre la marca cuando una variable ha cambiado desde una invocación anterior de esta etiqueta."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sentencias if en línea"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL admite las sentencias ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " en línea. Se pueden utilizar para escribir lógica condicional de forma concisa con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "operadores y pruebas de expresión"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set color = \"Blue\" if is_blue is truthy else \"Red\" %}     // color == \"blue\"\n\n{{ \"Blue\" if is_blue is truthy else \"Red\" }}     // \"Blue\"\n\n{% set dl = true %}\n<a href=\"http://example.com/some.pdf\" {{\"download\" if dl }} >Download PDF</a>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Operadores ternarios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También es posible utilizar operadores ternarios para escribir rápidamente lógica condicional con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#logical",
        children: "operadores y pruebas de expresión"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// If the variable is_blue is true, output \"blue\", otherwise output\"red\"\n{{ is_blue is truthy ? \"blue\" : \"red\" }}\n\n// Set the variable is_red to false if is_blue is true, otherwise set to true\n{% set is_red = is_blue is truthy ? false : true %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}