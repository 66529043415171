"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 52099237780;
const slug = exports.slug = 'guides/cms/content/memberships/social';
const title = exports.title = '会員ページにソーシャルログインを追加する方法';
const name = exports.name = 'APAC JAPAN (ja) | [Developers] How to add social login for membership pages_JA | 202109';
const metaDescription = exports.metaDescription = 'このガイドでは、ログインテンプレートにソーシャルログイン機能を追加します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%A7%8B%E3%82%81%E3%82%8B%E5%89%8D%E3%81%AB%E5%BF%85%E8%A6%81%E3%81%AA%E3%82%82%E3%81%AE",
  "label": "始める前に必要なもの",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E9%96%8B%E3%81%8F",
  "label": "1. ログインテンプレートを開く",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97-%E3%82%BD%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB-%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3-%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "2. メンバーシップ ソーシャル ログイン モジュールを追加する",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-%E3%82%BD%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%82%92%E3%83%86%E3%82%B9%E3%83%88%E3%81%99%E3%82%8B",
  "label": "3. ソーシャルログインをテストする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%AE%8C%E4%BA%86",
  "label": "完了",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "関連コンテンツ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      h3: "h3",
      strong: "strong",
      ol: "ol",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "会員ページにソーシャルログインを追加する方法"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "毎日使用する数多くのサイトのパスワードを記憶しておくことは簡単ではありません。ベストプラクティスに従って、全てのアカウントに異なるパスワードを設定している場合はなおさらです。FacebookやGoogleなどのソーシャルアカウントを使用してログインできる機能を提供することで、ウェブサイトの会員になったユーザーがパスワードを容易に管理できるようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このガイドでは、ログインテンプレートにソーシャルログイン機能を追加します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "始める前に必要なもの"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アクセス権設定機能（CMS Hub Enterpriseが必要です）"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "メンバーシップ ログイン テンプレート"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アクセスを制限するウェブサイトページ"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページアクセス権を付与するコンタクトリスト（ご自分のEメールだけが含まれているリストが、実際のリストを使用する前のテストに適しています）"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. ログインテンプレートを開く"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマ内のログインテンプレートを見つけて開きます。メンバーシップ ログイン テンプレートには、テンプレートアノテーション", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: membership_login_page"
      }), "が含まれている必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. メンバーシップ ソーシャル ログイン モジュールを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "membership_social_logins"
      }), "モジュールのコードを、ボタンを表示するテンプレートに追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social\" path=\"@hubspot/membership_social_logins\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "このモジュールはGoogleとFacebookの両方のログインをサポートしています。両方、またはいずれか１つをログインページに追加できます。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このモジュールはGoogleとFacebookの両方のログインをサポートしています。両方、またはいずれか１つをログインページに追加できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Googleログインボタンを追加する"
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Google Developer Consoleで認証情報を作成します。モジュールタグで使用する", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "クライアントID"
            }), "が提供されます。"]
          }), (0, _jsxRuntime.jsxs)(_components.ol, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.a, {
                href: "https://console.developers.google.com/apis/credentials",
                children: "［Credentials］ページ"
              }), "に進みます。"]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "［Create credentials］>［OAuth client ID］を選択します。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "アプリケーションタイプとして［Web application］を選択します。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "HubSpotサイト上で、何にログインしているかをユーザーが理解できる名前をアプリに付けます。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "［Create］をクリックします。"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["新しく作成されたOAuthクライアントの", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "クライアントID"
              }), "をコピーします。"]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["テンプレートファイル内でソーシャル ログイン モジュールを見つけ、パラメーター", (0, _jsxRuntime.jsx)(_components.code, {
                children: "google_clientid=\"YourAppID\""
              }), "を追加し、\"YourAppID\"をコピーしたアプリのIDに置き換えます。"]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["パラメーター", (0, _jsxRuntime.jsx)(_components.code, {
                children: "google_enabled=true"
              }), "を追加します。これにより、Googleログインボタンが表示されます。"]
            }), "\n"]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Facebookログインボタンを追加する"
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Facebookのログインボタンを追加するには、Facebookの開発者向けダッシュボード内でアプリを作成します。このアプリに", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "FacebookアプリID"
            }), "が付与されます。"]
          }), (0, _jsxRuntime.jsxs)(_components.ol, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.facebook.com/apps/",
                children: "Facebookの開発者アカウントににログインします"
              }), "（まだない場合は", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.facebook.com/docs/development/register",
                children: "アカウントを新規に登録"
              }), "します）"]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "［Create App］ボタンを使用します。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "［Build connected experiences］を選択してから、［continue］をクリックします。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "HubSpotサイトへのログインであることをユーザーに示す名前をアプリに付けます。［Create app］を選択します。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "製品をアプリに追加するように求める画面が表示されます。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "［Facebook Login］を選択します。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "左のナビゲーションパネルから［Settings］を開き、［Client OAuth Settings］の［Valid OAuth Redirect URIs］にリダイレクトURLを入力します。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "最上部のバーにアプリDが表示されます。クリップボードにコピーします。"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["テンプレートファイル内のソーシャル ログイン モジュールを見つけ、", (0, _jsxRuntime.jsx)(_components.code, {
                children: "facebook_appid=\"YourAppID\""
              }), "パラメーターを追加し、「YourAppID」を先ほどコピーしたアプリIDで置き換えます。"]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "facebook_enabled=true"
              }), "パラメーターを追加します。これにより、Facebookログインボタンが表示されます。"]
            }), "\n"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpotは、GoogleとFacebookが提供するUIの制御に関与できる立場にはありません。UIが変わると、以下の手順は混乱を招くか、または機能しなくなる可能性があります。最も重要な点は、クライアント／アプリを作成し、そのIDを取得することです。そのプロバイダーの既定モジュールのパラメーターと、該当する「enabled」パラメーターにそのIDを指定します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次に、コードの例を示しています。いずれかのプロバイダーのみを追加する場合には、IDも、サポートしないサービスのenabledパラメーターも指定する必要はありません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social\" path=\"@hubspot/membership_social_logins\",\n        clientid=\"1234567890-abc123def456.apps.googleusercontent.com\"\n        appid=\"12345678\"\n        facebook_enabled=true\n        google_enabled=true\n        %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. ソーシャルログインをテストする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["自分のEメールアドレスだけが含まれている", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/lists/create-active-or-static-lists#set-up-a-new-list",
          children: "コンタクトリストを作成"
        }), "します。このEメールアドレスは、GoogleアカウントまたはFacebookアカウントに使用している必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/website-pages/require-member-registration-to-access-private-content#set-up-membership-registration-for-pages",
          children: "ページを［非公開 - 登録が必要］に設定し"
        }), "、新しく作成したテストリストを選択します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "サインイン状態を回避するために、シークレットモードを使用してページの1つにアクセスします。ソーシャルログイン機能を使用したログインテンプレートが表示されるはずです。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ソーシャル ログイン ボタンを使用してログインを試みます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "問題が発生した場合は、手順を再確認して、クライアントIDまたはアプリIDが正しく入力され、モジュールに渡されていることを確かめます。サイトのドメインがアプリ設定内に信頼できるドメインとして設定されていることを確かめます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "完了"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ログインテンプレートにソーシャルログイン機能を正常に追加できました。ユーザーは、GoogleやFacebookを使用してログインできるようになりました。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連コンテンツ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/memberships/overview",
          children: "アクセス権設定"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/memberships/sso",
          children: "アクセス権設定のSSO"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/features/custom-objects",
          children: "CMS HubのCRMオブジェクト"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "メンバーシップ ソーシャル ログインのモジュール リファレンス"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}