"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694132;
const slug = exports.slug = 'guides/cms/content/modules/overview';
const title = exports.title = 'Modules Overview';
const name = exports.name = 'Modules overview';
const metaDescription = exports.metaDescription = 'Understanding modules is key to understanding the HubSpot CMS and its power. Modules are reusable components that can be used in templates or added to pages through drag and drop areas and flexible columns.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/team-member-editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'Team member module editor';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "an-example",
  "label": "An Example",
  "parent": null
}, {
  "depth": 1,
  "id": "the-user-interface-for-editing",
  "label": "The User Interface for Editing",
  "parent": "an-example"
}, {
  "depth": 0,
  "id": "module-vs-module-instance",
  "label": "Module vs module instance",
  "parent": null
}, {
  "depth": 0,
  "id": "fields.json",
  "label": "Fields.json",
  "parent": null
}, {
  "depth": 1,
  "id": "using-module-field-data-to-render-html",
  "label": "Using module field data to render HTML",
  "parent": "fields.json"
}, {
  "depth": 0,
  "id": "using-modules-in-templates",
  "label": "Using Modules in Templates",
  "parent": null
}, {
  "depth": 0,
  "id": "modules-are-a-great-tool-in-the-accessibility-toolbox",
  "label": "Modules are a great tool in the accessibility toolbox",
  "parent": null
}, {
  "depth": 0,
  "id": "modules-can-make-localization-easier",
  "label": "Modules can make localization easier",
  "parent": null
}, {
  "depth": 0,
  "id": "getting-started",
  "label": "Getting Started",
  "parent": null
}, {
  "depth": 0,
  "id": "going-further",
  "label": "Going Further",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ol: "ol",
      li: "li",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      strong: "strong",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Modules overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Understanding modules is key to understanding the HubSpot CMS and its power. Modules are reusable components that can be used in templates or added to pages through ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "drag and drop areas"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "flexible columns"
      }), ". In addition to using the modules that HubSpot provides, developers can create their own modules for everything from testimonials to photo galleries. Modules are created ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "using the local development tools"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "using the Design Manager"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A module has two parts:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-user-interface-for-editing",
          children: "user interface"
        }), " created through a list of fields that users will see when editing a module instance."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "An HTML+HubL template fragment with associated CSS and JS that defines how HTML will be generated"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "An Example"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To better understand what a module is, let's take a look at a simple \"Team Member\" module. The module consists of a photo, the team member's name, their title, and a short bio and when part of a CMS web page looks like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-module-instance.png",
        alt: "Team member module instance"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "The User Interface for Editing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The developer builds the user interface (UI) for modules using fields. The developer then chooses which fields to use based on the kind of module being built, the data that is needed, and the editing experience. In this case, the module includes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "an image field, for a team member photo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "two text fields, for the team member's name and position"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "and a rich text field, for a short bio."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When a content creator edits a module, the UI is constructed based on the fields that the developer has added to the module and how each field is configured."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-editor.png",
        alt: "Team member module editor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module vs module instance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are two terms frequently used regarding modules. It's important to understand the difference between them."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Module"
        }), " - reusable building blocks that can be added to templates and pages."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Module instance"
        }), " - the individual rendered modules on the page. They can have separate field values and as a result look different from other module instances that are for the same module."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fields.json"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The fields for a module are defined in JSON as an array of objects. Each field has a name, type, and default value. Other properties are also available depending on the type of field that controls the editing experience."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"name\": \"team_member_photo\",\n    \"label\": \"Team Member Photo\",\n    \"required\": true,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"type\": \"image\",\n    \"default\": {\n      \"src\": \"\",\n      \"alt\": \"\"\n    }\n  },\n  {\n    \"name\": \"team_member_name\",\n    \"label\": \"Team member name\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"Joshua Beck\"\n  },\n  {\n    \"name\": \"team_member_position\",\n    \"label\": \"Team member position\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"CEO, Co-Founder\"\n  },\n  {\n    \"name\": \"team_member_bio\",\n    \"label\": \"Team member bio\",\n    \"required\": true,\n    \"type\": \"richtext\",\n    \"default\": \"<p>Joshua has over 20 years of experience in the tech industry. He helped start this company in 2015 with the mission of helping people grow. In his spare time he loves hanging out with his kids, going to the beach, and cooking.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To learn more about all of the fields that are available, see ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "Module and Theme Fields"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Using module field data to render HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The values for each field are available in the HTML+HubL fragment for a module via a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), " variable. The data for each field can be accessed via the properties of the module variable. Using the team member module as an example, the team member name can be accessed via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ module.team_member_name }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<section class=\"team-member\">\n  <img class=\"team-member__image\" src=\"{{ module.team_member_image.src }}\" alt=\"{{ module.team_member_image.alt }}\">\n  <h3 class=\"team-member__name\">{{ module.team_member_name }}</h3>\n  <p class=\"team-member__position\">{{ module.team_member_position }}</p>\n  <div class=\"team-member__bio\">{{ module.team_member_bio }}</div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using Modules in Templates"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Modules are added to templates using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#basic-module-syntax",
        children: "module"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#block-syntax",
        children: "module_block"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_module"
      }), " tag and specifying the path to the module as a parameter. The default values for fields in a module can also be overridden at the template level through adding parameters to the module tag that corresponds to the field name as shown in the second part of the example below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_identifier\" path=\"/modules/team-member.module\" %}\n\n\n{# override default values in a module instance #}\n\n{% module \"unique_identifier\"\n  path=\"/modules/team-member.module\",\n  team_member_name=\"Brian Halligan\",\n  team_member_position=\"CEO\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Modules can't be nested inside of each other. The majority of the time you would want to do this, it is usually for layout reasons. Sections in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "drag and drop areas"
        }), ", are often the better course of action."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modules are a great tool in the accessibility toolbox"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Modules are used throughout a website, some times on multiple pages, even multiple times on a page. Because of this ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "building your module's HTML, CSS, and JS, with accessibility in mind"
      }), " can have a profound effect on how usable your site is to those both with and without disabilities or impairments."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modules can make localization easier"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In a similar sense to accessibility, building modules so that all content in the module is based on fields, makes it possible to localize later. For example you may have a \"Featured articles\" module. Instead of hard-coding the text \"Featured Articles\" use a text or rich text field. Then the text can be changed for other languages. To learn more about localization on the CMS see ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "multi-language"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Getting Started"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get started, check out our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "Getting started with modules"
      }), " tutorial."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Going Further"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configuring a module"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Using modules in templates"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Default modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "The module editor"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}