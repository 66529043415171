"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021629;
const slug = exports.slug = 'guides/apps/api-usage/usage-details';
const title = exports.title = 'HubSpot API | 利用ガイドライン';
const name = exports.name = 'API利用ガイドライン';
const metaDescription = exports.metaDescription = 'HubSpotのAPI利用ガイドラインには、利用規定、APIに関する利用条件、レート制限、認証、セキュリティーに関する情報が網羅されています。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E8%AA%8D%E8%A8%BC%E3%81%A8%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "認証とセキュリティー",
  "parent": null
}, {
  "depth": 0,
  "id": "api%E5%88%A9%E7%94%A8%E7%8A%B6%E6%B3%81%E3%81%AE%E7%A2%BA%E8%AA%8D",
  "label": "API利用状況の確認",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "非公開アプリ",
  "parent": "api%E5%88%A9%E7%94%A8%E7%8A%B6%E6%B3%81%E3%81%AE%E7%A2%BA%E8%AA%8D"
}, {
  "depth": 1,
  "id": "oauth%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "OAuthを使用した公開アプリ",
  "parent": "api%E5%88%A9%E7%94%A8%E7%8A%B6%E6%B3%81%E3%81%AE%E7%A2%BA%E8%AA%8D"
}, {
  "depth": 0,
  "id": "%E3%83%AC%E3%83%BC%E3%83%88%E5%88%B6%E9%99%90",
  "label": "レート制限",
  "parent": null
}, {
  "depth": 2,
  "id": "%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "公開アプリ",
  "parent": "%E3%83%AC%E3%83%BC%E3%83%88%E5%88%B6%E9%99%90"
}, {
  "depth": 2,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "非公開アプリ",
  "parent": "%E3%83%AC%E3%83%BC%E3%83%88%E5%88%B6%E9%99%90"
}, {
  "depth": 0,
  "id": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AE%E5%88%B6%E9%99%90",
  "label": "その他の制限",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E5%88%B6%E9%99%90",
  "label": "リクエストの制限",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%AE%E5%88%B6%E9%99%90",
  "label": "サービスの制限",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A8%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E3%83%9D%E3%83%B3%E3%82%B9",
  "label": "エラーレスポンス",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%8F%AF%E8%83%BD%E3%81%AA%E9%99%90%E3%82%8A%E3%83%90%E3%83%83%E3%83%81api%E3%81%A8%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5%E7%B5%90%E6%9E%9C%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "可能な限りバッチAPIとキャッシュ結果を使用する",
  "parent": "%E3%82%A8%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E3%83%9D%E3%83%B3%E3%82%B9"
}, {
  "depth": 1,
  "id": "webhook%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6hubspot%E3%81%8B%E3%82%89%E6%9C%80%E6%96%B0%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "Webhookを使用してHubSpotから最新データを取得する",
  "parent": "%E3%82%A8%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E3%83%9D%E3%83%B3%E3%82%B9"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      ul: "ul",
      li: "li",
      img: "img",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API利用ガイドライン"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでは、全てのユーザーに安定した品質をお届けするために、公開APIの利用状況を詳しくモニタリングしています。アプリや連携の全ての開発に際し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "HubSpot利用規定"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/developer-terms",
        children: "APIに関する利用条件"
      }), "を順守していただく必要があります。HubSpotは随時APIを変更または廃止する権利を有しますが、更新情報は常に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "開発者変更ログ"
      }), "（英語）を通じて事前にお知らせします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "認証とセキュリティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["最適なセキュリティーを実現するために、全てのアプリにはHubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuthプロトコル"
      }), "を直接お使いいただく必要があります。または、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "非公開アプリ"
      }), "を開発する場合にはアプリのアクセストークンを使用します。アプリには、このプロトコルに準拠したTTL（time-to-live）データの保存とユーザー アクセス トークンのリフレッシュを実装いただく必要があります。アクセストークンの生成時には、リフレッシュまでのAPI呼び出しに使用できる期間を示す", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), "パラメーターが含まれています。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Unauthorized (401)"
      }), "（認証されていない）リクエストは、新しいアクセストークンを取得する必要があることを確実に示すものではありません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API利用状況の確認"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "非公開アプリ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "非公開アプリのAPIの使用状況を表示するには、次のようにします。"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotアカウントにて、メイン ナビゲーション バーにある", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "設定アイコン"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左のサイドバーメニューで、［連携］＞［非公開アプリ］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["非公開アプリの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "名前"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリの詳細ページで、［ログ］タブをクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["表に掲載されているAPI呼び出しを確認します。", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "検索バー"
        }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "フィルター"
        }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "日付入力"
        }), "を使用して、表示されるAPI呼び出しをさらに絞り込むこともできます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/Screenshot%202023-08-31%20at%205.28.03%20PM.png",
        alt: "Screenshot 2023-08-31 at 5.28.03 PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#view-api-call-logs",
          children: "非公開アプリでのAPIの使用状況の確認"
        }), "について詳しくは、こちらをご覧ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "OAuthを使用した公開アプリ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "OAuthを使用した公開アプリのAPIの使用状況を表示するには、次のようにします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "開発者アカウントで、メイン ナビゲーション バーにある［アプリ］に移動します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "アプリ名"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左のサイドバーメニューで［モニタリング］に移動します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "タブ"
        }), "を切り替えることで、さまざまなタイプのアプリに対するリクエストやアプリからのリクエストを表示できます。これらのログが表示された状態で、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "個々のリクエスト"
        }), "をクリックすると、詳細情報が表示されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/6-request_details.png",
        alt: "6-request_details"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#monitor-app-behavior",
        children: "公開アプリのAPI使用状況の監視"
      }), "の詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "レート制限"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "公開アプリ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["OAuthアプリの場合、アプリをインストールするHubSpotアカウントごとに、リクエスト数は10秒間に100回に制限されています。ただし、後述の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search",
        children: "「その他の制限」"
      }), "セクションで説明しているように、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-limits",
        children: "検索API"
      }), "は除きます。API追加オプションに関連する制限は適用されません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "非公開アプリ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["各非公開アプリには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "HubSpotのAPI利用ガイドライン"
      }), "が適用されます。非公開アプリから実行できる呼び出しの回数は、アカウントのご契約内容とAPI追加オプションのご購入状況に基づきます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "製品のエディション"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "10秒当たり"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "1日当たり"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開アプリ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（全ての製品の）無料ツールおよびStarter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100 / 非公開アプリ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250,000 / アカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（全ての製品の）Professional、Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "150 / 非公開アプリ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "500,000 / アカウント"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開アプリ（API追加オプション購入時）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（全ての製品の）無料ツール、Starter、Professional、Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "200 / 非公開アプリ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1,000,000 / アカウント"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "その他の制限"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "開発者アカウント1件につき最大100件のアプリを作成可能"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウント1件につき最大20件の非公開アプリを作成可能"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ1件につき1,000件までのWebhook配信登録を作成可能"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ1件につき最大25個のCRM拡張機能を作成可能"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ1件につき最大750個のタイムライン イベント タイプを作成可能"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "タイムライン イベント タイプ1個につき最大500個のプロパティーを作成可能"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "リクエストの制限"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot のAPIエンドポイントにリクエストを送信するときには、以下の一般的な制限事項に留意してください。一部のAPIには、より詳細な独自の制限が適用されていることがあります。これは、APIリファレンスページの「制限」に記載されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/single-send-limits-example.png",
        alt: "single-send-limits-example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search",
          children: "検索API"
        }), "エンドポイントのレート制限：認証トークン1つにつき1秒当たり最大4件のリクエスト", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search#limitations",
          children: "検索の制限"
        }), "の詳細をご確認ください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["日次制限または2次制限を免除されたAPIリクエストは、HubSpotのログに記録", (0, _jsxRuntime.jsx)("u", {
          children: "されません"
        }), "。免除対象のリクエストを保存するには、これらのリクエストを外部に記録する必要があります。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CRMオブジェクトエンドポイントへの一括リクエストのレコード数の制限は、リクエストあたり100レコードです。例えば、1回の一括リクエストで、100件を超えるコンタクトを読み取ることはできません。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サービスの制限"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サービスの制限と価格については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/pricing/service-limits",
        children: "こちら"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "エラーレスポンス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリまたは連携でレート制限を超えると、以降の全てのAPI呼び出しに対し、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), "のエラーレスポンスを受け取ることになります。エラーレスポンスが返されるリクエストの数が、1日の合計リクエスト数の5％を超えないようにしてください。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "HubSpotアプリマーケットプレイス"
      }), "へのアプリの掲載を予定している場合、この5％の制限を守っていただく必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), "レスポンスの形式を以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example\n{\n  \"status\": \"error\",\n  \"message\": \"You have reached your daily limit.\",\n  \"errorType\": \"RATE_LIMIT\",\n  \"correlationId\": \"c033cdaa-2c40-4a64-ae48-b4cec88dad24\",\n  \"policyName\": \"DAILY\",\n  \"requestId\": \"3d3e35b7-0dae-4b9f-a6e3-9c230cbcf8dd\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "message"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "policyName"
      }), "は、どちらの上限に達したかを示します（日次制限または2次制限）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "1日当たり"
      }), "の制限は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-set-your-time-zone-in-hubspot",
        children: "タイムゾーン設定"
      }), "に基づいて深夜0時にリセットされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次の表は、HubSpotへの各APIリクエストのレスポンスに含まれるレート制限ヘッダーの詳細を示しています。表の下部に例外が記載されています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "ヘッダー"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "説明"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Daily"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["1日当たりに実行可能なAPIリクエストの数。このヘッダーは、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth",
              children: "OAuth"
            }), "を使用して承認されたAPIリクエストへのレスポンスに含まれていません。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Daily-Remaining"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["当日に実行可能な残りのAPIリクエストの数。このヘッダーは、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth",
              children: "OAuth"
            }), "を使用して承認されたAPIリクエストへのレスポンスに含まれていません。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Max"
            }), "ヘッダーと", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Remaining"
            }), "ヘッダーが適用される時間。例えば、値が10000なら10秒間を表します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Max"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), "で指定された時間内で実行可能なリクエストの数。例えば、このヘッダー値が100で、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), "ヘッダーが10000の場合、リクエストの回数は10秒当たり100回に制限されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Remaining"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), "で指定された時間内で実行可能な残りのAPIリクエストの数。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly"
          }), "ヘッダーと", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly-Remaining"
          }), "ヘッダーが含まれている場合、そのデータ自体は正確ですが、この2つのヘッダーで参照されている制限は既に適用されていないため、廃止されたものとして扱ってください。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/search",
            children: "検索APIエンドポイント"
          }), "からのレスポンスに、上記のレート制限ヘッダーは", (0, _jsxRuntime.jsx)("u", {
            children: "含まれません"
          }), "。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "こちらのエンドポイント"
      }), "を使って、当日中に使用された呼び出し数を確認することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "TEN_SECONDLY_ROLLING"
      }), "の制限に到達した場合は、アプリで発行するリクエストをスロットリングして制限内に収めてください。リクエストのスロットリングと組み合わせる対策、または1日当たりの制限に達する場合の対策について、以下を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらの方法を試しても呼び出し制限に到達する場合は、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://integrate.hubspot.com/",
        children: "開発者フォーラム"
      }), "に投稿してください。その際には、ご使用のAPIや、その利用状況、到達した制限など、できるだけ詳しい情報をご提供ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "可能な限りバッチAPIとキャッシュ結果を使用する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サイトまたはアプリの各ページの読み込み時にHubSpotからのデータを使用する場合は、HubSpot APIで毎回リクエストするのではなく、データのキャッシュ処理によってキャッシュからデータを読み込むようにしてください。一括処理のためにアカウント設定を取得する呼び出しを繰り返し行う場合（オブジェクトのプロパティー、担当者、またはフォーム設定を取得するなど）、こうした設定情報についても可能な限りキャッシュ処理を行ってください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhookを使用してHubSpotから最新データを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのMarketing Hub Enterpriseをご契約されている場合、ワークフロー内でWebhookアクションを使用してコンタクトレコードのデータを貴社のシステムに送信できます。Webhookは、どのワークフローのアクションとしてもトリガーできるため、コンタクトデータを貴社のシステムに送信するための条件として、どのようなワークフローの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows-user-guide-v2/how-to-choose-the-workflow-type-and-the-starting-condition-best-suited-for-your-goal",
        children: "開始条件"
      }), "でも使用できます。Webhookの詳しい使い方については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "こちら"
      }), "を参照してください。また、Webhookデータの例については", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/create-manage-workflows/v3#create-a-workflow",
        children: "こちら"
      }), "をご覧ください。ワークフローを介して行われたWebhook呼び出しは、APIレート制限には計上されません。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}