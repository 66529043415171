"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890246;
const slug = exports.slug = 'reference/cms/modules/files';
const title = exports.title = 'Codage des modules personnalisés';
const name = exports.name = 'EMEA FR - module files';
const metaDescription = exports.metaDescription = "Les modules personnalisés sont des composants réutilisables que vous pouvez utiliser sur votre site web. Créez-les en respectant les meilleures pratiques en matière de vitesse, d'expérience utilisateur et d'accessibilité.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "html-et-hubl-module.html",
  "label": "HTML et HubL (module.html)",
  "parent": null
}, {
  "depth": 0,
  "id": "css-module.css",
  "label": "CSS (module.css)",
  "parent": null
}, {
  "depth": 1,
  "id": "stylisation-bas%C3%A9e-sur-les-valeurs-des-champs-du-module",
  "label": "Stylisation basée sur les valeurs des champs du module",
  "parent": "css-module.css"
}, {
  "depth": 2,
  "id": "classe-css",
  "label": "Classe CSS",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "bloc-require_css",
  "label": "bloc require_css",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "ajouter-un-style-intraligne",
  "label": "Ajouter un style intraligne",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "importer-des-fichiers-css-sp%C3%A9cifiques",
  "label": "Importer des fichiers CSS spécifiques",
  "parent": "css-module.css"
}, {
  "depth": 0,
  "id": "javascript-module.js",
  "label": "JavaScript (module.js)",
  "parent": null
}, {
  "depth": 1,
  "id": "script-bas%C3%A9-sur-les-valeurs-des-champs",
  "label": "Script basé sur les valeurs des champs",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "attributs-des-donn%C3%A9es",
  "label": "Attributs des données",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "bloc-require_js",
  "label": "bloc require_js",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "require_js",
  "label": "require_js",
  "parent": "javascript-module.js"
}, {
  "depth": 0,
  "id": "informations-associ%C3%A9es",
  "label": "Informations associées",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      img: "img",
      h2: "h2",
      pre: "pre",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Fichiers de modules"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez un module pour des pages, des blogs et des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/quotes",
        children: "devis"
      }), ", le module contient trois fichiers d'interface connexes qui contrôlent le contenu, le style et la fonctionnalité du module :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.html"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.css"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.js"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Les modules de messagerie ne prennent pas en charge module.css et module.js. En effet, les clients de messagerie ne prennent pas en charge JavaScript et la prise en charge des fichiers CSS associés est limitée."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ces fichiers seront toujours rendus sur la page lorsqu'une instance du module se trouve sur la page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'une page comprend plusieurs instances du même module, HubSpot ne chargera qu'une seule fois ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " de ce module. Par défaut, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " ne se chargent pas de manière asynchrone, mais vous pouvez changer cela en incluant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options et js_render_options"
      }), " dans le meta.json du module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modules peuvent être construits dans le gestionnaire de conception ou localement en utilisant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "HubSpot CLI"
      }), ". Dans le gestionnaire de conception, les fichiers de modules sont affichés dans un éditeur à plusieurs volets."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module1.png",
        alt: "cms-dev-custom-module1"
      }), "Lors de l'affichage d'un module en local, les fichiers sont contenus dans les dossiers module-name.module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module0.png",
        alt: "cms-dev-custom-module0"
      }), "La décision d'utiliser le gestionnaire de conception ou le CLI pour créer et gérer les modules dépend des préférences de votre équipe. Voir la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "création d'un workflow efficace pour les développeurs"
      }), " pour des recommandations."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTML et HubL (module.html)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le fichier module.html est destiné au HTML et au HubL. En général, l'endroit où un module est placé dans l'éditeur de page ou le fichier de modèle détermine l'endroit où le contenu du fichier module.html est rendu."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ce fichier agit comme un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "HubL"
      }), " dans la page où le module est placé. Le fichier module.html ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#using-module-field-data-to-render-html",
        children: "peut accéder aux valeurs des champs du module via HubL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CSS (module.css)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " pour ajouter de la CSS à un module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En général, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " prend en charge un sous-ensemble très limité de HubL. Cependant, vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#module-asset-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_asset_url(\"my-image.png\")"
        })
      }), " pour les images ajoutées en tant que ressources associées à un module. Cela permet d'associer des ressources telles que des images, compilées avec le module lui-même. Par exemple :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial-module__wrapper {\n  background: url('{{ module_asset_url('bg-pattern.png') }}');\n  background-repeat: repeat;\n  min-height: 200px;\n  width: 100%;\n  display: block;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous comment configurer la CSS d'un module pour qu'elle change dynamiquement en fonction des champs du module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Stylisation basée sur les valeurs des champs du module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe plusieurs façons d'influencer le style de votre module en fonction des champs du module. Choisissez la méthode qui convient le mieux à votre cas d'utilisation spécifique."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#css-classes",
          children: "Classe CSS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#require-css-block",
          children: "Bloc Require_css"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#inline-styles",
          children: "Styles intraligne"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Classe CSS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un style prédéfini pour le module avec la possibilité pour les éditeurs de choisir parmi ces options, vous pouvez ajouter un champ de module pour définir des classes dans votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " qui correspondent aux classes CSS dans votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, vous pouvez avoir un module image et un module texte. Vous souhaitez que les créateurs de contenu puissent positionner l'image à droite ou à gauche du texte en fonction d'un champ de sélection. Pour ce faire, vous pouvez définir vos fichiers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " comme suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n\n<section class=\"img-text__wrapper img-text--{{ module.positioning }}\" aria-label=\"{{ module.heading }}\">\n  {# module.position is a choice field with two values \"img-left\" or \"img-right\". This dictates the order they appear on desktop. Controlled by CSS #}\n  <div class=\"img-text__img\">\n      <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\">\n  </div>\n  <div class=\"img-text__text\">\n      <h2>\n        {% inline_text field=\"heading\" value=\"{{ module.heading }}\" %}\n      </h2>\n      {% inline_rich_text field=\"text\" value=\"{{ module.text }}\" %}\n  </div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "/* module.css */\n\n/* CSS that makes the image show adjacent to the text, \nand positioned based on the positioning field.*/\n\n/* The media query ensures that on mobile the image \nwill always appear above the text for \nvisual consistency. */\n\n@media (min-width: 768px) {\n  .img-text__wrapper {\n    display: flex;\n    align-items: row;\n  }\n  .img-text__img,\n  .img-text__text {\n    flex: 1;\n    padding: 10px;\n  }\n  .img-text--img-right {\n    flex-direction: row-reverse;\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "bloc require_css"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous devez donner aux créateurs de contenu un contrôle direct sur des propriétés spécifiques et que les classes ne sont pas idéales, les balises de style avec blocs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " sont la meilleure option."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour donner aux créateurs de contenu un contrôle direct sur des propriétés spécifiques sans utiliser de classes, vous pouvez ajouter du style au fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " dans les balises ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), ". Par exemple :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n  <style>\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  </style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comme ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " peut rendre HubL, vous pouvez utiliser les valeurs des champs du module comme variables CSS. Lorsqu'un créateur de contenu met à jour le champ dans l'éditeur de page, la CSS est mise à jour en conséquence. Ces blocs déplacent les balises ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), " dans le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " de votre page, dans l'annonce ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également configurer la CSS pour qu'elle ne s'applique qu'à l'instance du module en l'encadrant de balises ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scope_css"
      }), ". Par exemple, vous pouvez mettre à jour le code du module ci-dessus comme suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n<style>\n  {% scope_css %}\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  {% end_scope_css %}\n</style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ajouter un style intraligne"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous devez donner aux créateurs de contenu un contrôle poussé sur quelques propriétés seulement et que les classes ne sont pas idéales, vous pouvez ajouter directement les valeurs à un attribut de style dans le HTML."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Module.html #}\n<div style=\"background: rgba({{ module.bg_color.color|convert_rgb }},{{ module.bg_color.opacity/100 }});\">\n  {% inline_rich_text field=\"richtext\" value=\"{{ module.richtext }}\" %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous avez de nombreuses propriétés et que le code devient difficile à lire, envisagez de passer à la méthode du bloc ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Importer des fichiers CSS spécifiques"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " est une fonction HubL que vous pouvez ajouter à module.html et qui indique à HubSpot qu'un module ou un modèle particulier nécessite un fichier CSS particulier pour s'afficher. Une balise de lien pointant vers le fichier css est ajoutée à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " de la page, à l'intérieur de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " ne chargera ce fichier CSS qu'une seule fois, quel que soit le nombre de fois où ce même fichier est requis par les modules et les modèles sur une page particulière. Il est donc idéal pour les situations où les styles peuvent être partagés entre plusieurs modules, mais où l'ajout de CSS directement dans les feuilles de style principales utilisées sur chaque page de votre site n'a pas de sens."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " et les fichiers CSS associés remplissent le même objectif, mais ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " peut être utilisé de manière conditionnelle en fonction des valeurs des champs. Cela évite de charger du code inutile."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{ require_css(get_asset_url(\"/modules/shared_layout_styles.css\")) }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScript (module.js)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " pour ajouter du JavaScript à un module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comme le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), ", le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " ne prend pas en charge HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Script basé sur les valeurs des champs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe plusieurs façons de concevoir des modules, où JavaScript agit différemment en fonction des valeurs des champs. Comprendre quelle méthode utiliser et à quel moment peut se traduire par des gains de performance sur chaque page où le module est utilisé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, vous avez un module d'image personnalisé et vous voulez donner aux créateurs de contenu la possibilité d'ouvrir l'image dans une galerie. Les créateurs de contenu veulent l'utiliser que pour des images spécifiques, et non pour toutes les instances du module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Attributs des données"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les attributs de données sont des attributs personnalisés de la norme HTML 5 que les développeurs ajoutent aux éléments. De même que tous les éléments prennent en charge ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "class=\"yourClassName\""
      }), ", tous les éléments prennent en charge ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-your-attribute=\"yourValue\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html-->\n<div class=\"img-module img-module__wrapper\" data-lightbox=\"{{ module.is_lightbox_enabled }}\" data-caption=\"above\">\n  <!-- module.is_lightbox_enabled is a boolean field, module.caption_position is a choice field. -->\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez utiliser les attributs de données pour transmettre les valeurs des champs de vos instances de module qui seront traitées par votre fichier module.js."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour utiliser les valeurs de votre fichier module.js, vous devrez parcourir en boucle toutes les instances de votre module. L'ajout d'un nom de classe spécifique au module à l'élément encadreur le plus externe de votre module vous donnera une cible à utiliser, afin que vous puissiez parcourir en boucle chacune de vos instances de module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// module.js\n\nlet imgModules = document.getElementsByClassName('img-module');\nArray.from(imgModules).forEach(function (element) {\n  // loop through each of the instances of the module\n  // set data attributes to variables to make it easy to work with\n  let isLightboxEnabled = element.dataset.lightbox;\n  let captionStyle = element.dataset.caption;\n  if (isLightboxEnabled) {\n    element.addEventListener('click', function () {\n      showLightbox(captionStyle); // Execute your code for the action you want to take, you can pass your data attributes into functions from libraries.\n    });\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les attributs de données vous permettront de récupérer les valeurs des champs pour chaque instance de module dans votre module.js."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "bloc require_js"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans des situations avancées, par exemple lors de l'utilisation d'une bibliothèque de modèles JavaScript ou d'un cadre réactif comme Vue.js ou React.js, vous préférerez peut-être n'afficher que les données, et voir le cadre se charger du rendu."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans ce cas, utilisez une balise script entourée d'un bloc ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " pour fournir des variables auxquelles vous pouvez accéder à partir de votre script de modélisation."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% require_js %}\n<script>\n  let myArray = [\n    {%- for item in module.repeating_text_field -%}\"{{ item }}\",{%- endfor -%}\n  ];\n</script>\n{% end_require_js %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cette technique peut être utile pour fournir aux applications avancées un ensemble initial de données à partir desquelles effectuer le rendu. Cela élimine un appel JavaScript initial pour récupérer les données."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " est une fonction HubL qui indique à HubSpot qu'un module ou un modèle particulier nécessite un fichier JavaScript particulier pour être chargé correctement. La fonction prend deux paramètres : le chemin d'accès au fichier et l'emplacement où le fichier doit être ajouté (\"head\" ou \"footer\")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans un module, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " ne peut être ajouté que dans le module.html. Le fichier JavaScript mentionné dans l'annonce ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " ne sera chargé qu'une fois par page, quel que soit le nombre de fois où il est requis par les modules et les modèles de la page. Cela réduit le nombre de requêtes HTTP et évite la duplication du code."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Certaines situations où cela devient pratique :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous avez plusieurs modules ou modèles qui nécessitent le même JavaScript, vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " pour partager ce script entre les modules."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous travaillez avec un pack JavaScript comme webpack, il peut être plus facile d'envoyer vos fichiers js à un emplacement spécifique. En utilisant ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), ", vous pouvez associer JavaScript à votre module."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " et les fichiers JavaScript associés servent le même objectif, mais ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " peut être fait de manière conditionnelle en fonction des valeurs des champs. Cela évite de charger du code inutile. Vous avez également la possibilité de charger JavaScript dans l'en-tête, si vous en avez besoin."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Puisque JavaScript bloque le rendu, l'emplacement par défaut ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " pour JavaScript est le \"footer\". ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "En savoir plus sur l'optimisation des performances."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informations associées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Optimiser la vitesse de votre site CMS Hub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Champs de module"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}