"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61045964892;
const slug = exports.slug = 'guides/api/crm/engagements/meetings';
const title = exports.title = 'Engagements | Meetings';
const name = exports.name = 'Engagements | Meetings';
const metaDescription = exports.metaDescription = 'Use the meetings engagement API to log and manage meetings on CRM records. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-meeting",
  "label": "Create a meeting",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "create-a-meeting"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "create-a-meeting"
}, {
  "depth": 0,
  "id": "retrieve-meetings",
  "label": "Retrieve meetings",
  "parent": null
}, {
  "depth": 0,
  "id": "update-meetings",
  "label": "Update meetings",
  "parent": null
}, {
  "depth": 1,
  "id": "associate-existing-meetings-with-records",
  "label": "Associate existing meetings with records",
  "parent": "update-meetings"
}, {
  "depth": 1,
  "id": "remove-an-association",
  "label": "Remove an association",
  "parent": "update-meetings"
}, {
  "depth": 0,
  "id": "pin-a-meeting-on-a-record",
  "label": "Pin a meeting on a record",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-meetings",
  "label": "Delete meetings",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Meetings"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the meetings engagement API to log and manage meetings on CRM records. You can log meeting activities either ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "in HubSpot"
      }), " or through the meetings API. You can retrieve, update, or delete meeting engagements that are manually logged on a record, scheduled using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/meetings-tool/use-meetings",
        children: "meetings tool"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/schedule-a-meeting-with-a-contact-in-a-record",
        children: "scheduled using the Google Calendar or Office 365 calendar integration"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, learn the basic methods of managing meetings through the API. To view all available endpoints and their requirements, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/engagements/meetings",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a meeting"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a meeting engagement, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the request body, add meeting details in a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), " object. You can also add an ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associations"
      }), " object to associate your new meeting with an existing record (e.g., contacts, companies)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the properties object, you can include the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Required. This field marks the date and time that the meeting occurred. You can use either a Unix timestamp in milliseconds or UTC format.(BETA) When the property value is missing, the value will default to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_start_time."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The title of the meeting."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID of the owner"
            }), " associated with the meeting. This field determines the user listed as the meeting creator on the record timeline."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The meeting description."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_internal_meeting_notes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The internal notes you take for your team during a meeting that are not included in the attendee meeting description."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_external_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The external URL for the calendar event. For example, this could be a Google calendar link or a Microsoft Outlook calendar link."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Where the meeting takes place. The value could be a physical address, a conference room, a videoconference link, or a phone number. This appears on the calendar invite on the attendee's calendar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_start_time"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The date and time when the meeting starts. The value for this property should match the value for ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_end_time"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The date and time when the meeting ends."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_outcome"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The outcome of the meeting. The outcome values are scheduled, completed, rescheduled, no show, and canceled."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_activity_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of meeting. The options are based on the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types",
              children: "meeting types set in your HubSpot account."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The IDs of the meeting's attachments. Multiple attachment IDs are separated by a semi-colon."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create and associate a meeting with existing records, include an associations object in your request. For example, to create and associate a meeting with contacts, your request may look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-23T01:02:44.872Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_meeting_title\": \"Intro meeting\",\n    \"hs_meeting_body\": \"The first meeting to discuss options\",\n    \"hs_internal_meeting_notes\": \"These are the meeting notes\",\n    \"hs_meeting_external_url\": \"https://Zoom.com/0000\",\n    \"hs_meeting_location\": \"Remote\",\n    \"hs_meeting_start_time\": \"2021-03-23T01:02:44.872Z\",\n    \"hs_meeting_end_time\": \"2021-03-23T01:52:44.872Z\",\n    \"hs_meeting_outcome\": \"SCHEDULED\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 101\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 200\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 102\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 200\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The associations object should include:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The record you want to associate with the meeting, specified by its unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " value."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of the association between the meeting and the record. Include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Default association type IDs are listed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
              children: "here"
            }), ", or you can retrieve the value for custom association types (i.e. labels) via the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#retrieve-association-types",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch creating meetings by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " tab at the top of this article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve meetings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can retrieve meetings individually or in bulk. Learn more about batch retrieval by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " tab at the top of this article."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve an individual meeting by its meeting ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), ". You can also include the following parameters in the request URL:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of objects to you want to retrieve associated record IDs from."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To request a list of all of meetings, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/meetings"
      }), ". You can include the following parameters in the request URL:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The maximum number of results to display per page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update meetings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can update meetings individually or in batches. To update an individual meeting by its meeting ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the request body, include the meeting properties that you want to update. For example, your request body might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example PATCH request to https://api.hubspot.com/crm/v3/objects/meetings/{meetingId}\n{\n  \"properties\": {\n     \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n     \"hubspot_owner_id\": \"11349275740\",\n     \"hs_meeting_title\": \"Intro meeting\",\n     \"hs_meeting_body\": \"The first meeting to discuss options\",\n     \"hs_internal_meeting_notes\": \"These are the meeting notes\",\n     \"hs_meeting_external_url\":\n     \"https://Zoom.com/0000\",\n     \"hs_meeting_location\": \"Remote\",\n     \"hs_meeting_start_time\": \"2021-03-23T01:02:44.872Z\",\n     \"hs_meeting_end_time\": \"2021-03-23T01:52:44.872Z\",\n     \"hs_meeting_outcome\": \"SCHEDULED\"\n  }\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will ignore values for read-only and non-existent properties. To clear a property value, pass an empty string for the property in the request body."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch updating by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " tab at the top of this article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associate existing meetings with records"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate a meeting with records, such as a contact and its associated companies, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". The request URL contains the following fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meetingId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the meeting."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The type of object that you want to associate the meeting with (e.g., contact or company)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the record that you want to associate the meeting with."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the association type between the meeting and the other object type. You can retrieve this value through the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request URL might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/meetings/17612479134/associations/contact/104901/200"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remove an association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove an association between a meeting and a record, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to the same URL as above:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pin a meeting on a record"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "pin a meeting"
      }), " on a record so it remains on the top of the record's timeline. The meeting must already be associated with the record prior to pinning, and you an only pin one activity per record. To pin a meeting, include the meeting's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " field when creating or updating a record via the object APIs. Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "companies,"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contacts"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "deals"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "custom objects"
      }), " APIs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete meetings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can delete meetings individually or in batches, which will add the meeting to the recycling bin in HubSpot. You can later ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restore the meeting from the record timeline"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete an individual meeting by its meeting ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch deleting by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " tab at the top of this article."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}