"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921778;
const slug = exports.slug = 'guides/api/files';
const title = exports.title = 'API do CMS | Gerenciador de arquivos';
const name = exports.name = 'vNext DP de documentos - Gerenciador de arquivos';
const metaDescription = exports.metaDescription = 'Os pontos de extremidade de arquivo são usados para obter e gerenciar dados no gerenciador de arquivos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "upload-de-um-arquivo",
  "label": "Upload de um arquivo",
  "parent": null
}, {
  "depth": 0,
  "id": "verificar-o-estado-de-carregamento-de-um-arquivo",
  "label": "Verificar o estado de carregamento de um arquivo",
  "parent": null
}, {
  "depth": 0,
  "id": "exibir-detalhes-do-arquivo",
  "label": "Exibir detalhes do arquivo",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-um-arquivo",
  "label": "Excluir um arquivo",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-uma-pasta",
  "label": "Criar uma pasta",
  "parent": null
}, {
  "depth": 0,
  "id": "altera%C3%A7%C3%B5es-na-v3",
  "label": "Alterações na V3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Gerenciador de arquivos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a ferramenta de arquivos da HubSpot para gerenciar e armazenar arquivos no HubSpot. Os arquivos hospedados na HubSpot podem ser carregados e usados no HubSpot e no conteúdo externo. Eles também podem ser anexados a registros usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API de envolvimentos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se sua empresa estiver criando um site usando o HubSpot CMS, você poderá usar a API de arquivos para fazer upload e armazenar os ativos no HubSpot. Esses arquivos poderão ser veiculados e compartilhados por meio do HubSpot CMS."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode acessar a ferramenta de arquivos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/files/upload-files-to-use-in-your-hubspot-content",
        children: "no HubSpot"
      }), " ou por meio da API de arquivos. Veja abaixo sobre os arquivos de API e como carregar e excluir arquivos. Para obter uma lista completa de pontos de extremidade da API de arquivos, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " acima."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Upload de um arquivo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O upload dos arquivos pode ser feita usando uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "multipart/form-data para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files"
      }), " com os seguintes campos. Embora um ID de pasta específico não seja necessário para upload, recomendamos fazer upload dos arquivos em uma pasta e não no diretório raiz. Os requisitos da pasta para upload estão sujeitos a alterações futuras."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O arquivo para upload (obrigatório)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um objeto JSON que controla a privacidade e a indexabilidade do arquivo e contém dois campos: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "access"
            }), ", que é obrigatório, e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), ", que especifica um período de tempo após o qual o arquivo será excluído automaticamente. Se você estiver usando o campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), ":", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "o período mínimo que deve ser definido é 1 dia."
              }), (0, _jsxRuntime.jsx)("li", {
                children: "O período máximo que pode ser definido é 1 ano."
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Após o período definido, o arquivo será excluído permanentemente. Após a exclusão, o arquivo não poderá ser recuperado ou restaurado."
              })]
            }), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID da pasta para a qual o arquivo será carregado. Este campo ", (0, _jsxRuntime.jsx)("u", {
              children: "ou"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            }), " deve ser fornecido na sua solicitação (mas ", (0, _jsxRuntime.jsx)("u", {
              children: "não"
            }), " ambos)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O caminho da pasta para a qual o arquivo será carregado. Este campo ", (0, _jsxRuntime.jsx)("u", {
              children: "ou"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            }), " deve ser fornecido na sua solicitação (mas ", (0, _jsxRuntime.jsx)("u", {
              children: "não"
            }), " ambos)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do arquivo. Se nenhum nome for especificado, um nome será gerado do conteúdo do arquivo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charsetHunch"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rótulo do conjunto de caracteres do arquivo de upload. Se não for fornecido, ele será derivada do arquivo."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, se você quiser enviar um arquivo com os seguintes critérios para sua conta da HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nome de arquivo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cat.png"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Pasta de destino no gerenciador de arquivos do HubSpot:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/library/cat_archive"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Acessibilidade dos arquivos no HubSpot: **acesso privado"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os seguintes cabeçalhos e corpo da solicitação precisariam fazer parte da sua solicitação:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubapi.com/files/v3/files?=' \\\n  --header 'Authorization: Bearer pat-na1-00000000-0000-0000-0000-000000000000' \\\n  --header 'Content-type: multipart/form-data' \\\n  --form file=@/Users/person/Downloads/cat.png \\\n  --form 'options={\"access\": \"PRIVATE\"}' \\\n  --form folderPath=/library/cat_archive\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A resposta resultante incluirá o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " e o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "parentFolderId"
      }), " do arquivo carregado, que você poderá usar para recuperar o arquivo por meio de uma solicitação GET."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// 201 Response from successful file upload\n{\n  \"id\": \"122692044085\",\n  \"createdAt\": \"2023-06-28T17:56:45.393Z\",\n  \"updatedAt\": \"2023-06-28T17:56:45.393Z\",\n  \"archived\": false,\n  \"parentFolderId\": \"122692510820\",\n  \"name\": \"cat\",\n  \"path\": \"/library/cat_archive/cat.png\",\n  \"size\": 24574,\n  \"height\": 219,\n  \"width\": 225,\n  \"encoding\": \"png\",\n  \"type\": \"IMG\",\n  \"extension\": \"png\",\n  \"defaultHostingUrl\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"url\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"isUsableInContent\": true,\n  \"access\": \"PRIVATE\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verificar o estado de carregamento de um arquivo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao importar um arquivo de um URL para o gerenciador de arquivos enviando uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async"
      }), ", você poderá revisar o status de carregamento do arquivo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para fazer isso, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async/tasks/{taskId}/status"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de fazer esta solicitação, você receberá uma das seguintes respostas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING"
        }), ": o arquivo está na fila para ser carregado. O processo de importação ainda não foi iniciado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), ": o arquivo está em processo de carregamento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELED"
        }), ": o carregamento foi cancelado e o arquivo não será carregado. Para importar o arquivo na sua conta da HubSpot, você precisará carregar o arquivo novamente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "COMPLETE"
        }), ": o arquivo foi carregado para a ferramenta de arquivos com sucesso. O arquivo carregado aparecerá na ferramenta de arquivos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exibir detalhes do arquivo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para revisar os detalhes de um arquivo que foi enviado por upload para a ferramenta de arquivos, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), ". Isso retornará o arquivo com detalhes, como nome, altura e largura, codificação, URL e muito mais."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, para recuperar os detalhes de um arquivo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se um arquivo for definido como privado, o URL retornado resultará em um erro 404. Para obter uma URL visível do arquivo, você pode fazer uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/files/v3/files/{fileId}/signed-url"
      }), ". Ao fazer essa solicitação, você pode incluir parâmetros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property"
      }), " para retornar propriedades específicas, como altura e largura."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir um arquivo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir um arquivo, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), ". Isso marcará o arquivo como excluído e tornará o conteúdo do arquivo inacessível."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir permanentemente um arquivo, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}/gdpr-delete"
      }), ". Isso excluirá permanentemente o conteúdo e os metadados do arquivo em até 7 dias."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se um arquivo não for excluído de acordo com o GDPR, o conteúdo dele permanecerá nos servidores do HubSpot em um estado privado onde ninguém poderá acessá-lo. Para garantir que o conteúdo do arquivo seja totalmente excluído, use a funcionalidade de exclusão do GDPR."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar uma pasta"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar uma pasta, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/folders"
      }), ". Ao fazer a solicitação, você pode incluir os campos abaixo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Obrigatório"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sim"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nome da pasta que você deseja criar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para criar a pasta dentro de uma pasta existente, inclua este campo com o ID da pasta existente. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), " não podem ser definidos ao mesmo tempo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para criar a pasta dentro de uma pasta existente, inclua este campo com o caminho da pasta existente. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), " não podem ser definidos ao mesmo tempo."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body of POST request to /files/v3/folders\n{\n  \"name\": \"myNewFolder\",\n  \"parentFolderId\": 12345\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alterações na V3"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se estiver usando a versão anterior desta API, a v3 tem as seguintes alterações:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Todos os arquivos carregados através da API estarão visíveis no painel de arquivos e no seletor de arquivos. Não é possível criar arquivos ocultos. No entanto, arquivos privados e não indexáveis ainda podem ser criados."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Os arquivos de listagem não retornam arquivos ocultos ou excluídos. No entanto, uma grande variedade de filtros pode ser aplicada. Arquivos ocultos ainda podem ser buscados por ID, mas exigem um novo escopo: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "files_ui_hidden.read."
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Vários arquivos não podem ser carregados com uma única solicitação."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Ações de atualização de pasta, como mover e renomear, são agora assíncronas. Cada solicitação retornará um token que pode ser usado para verificar o status da edição da pasta."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Pontos de extremidade que criam ou substituem arquivos exigem o fornecimento de níveis de acesso para os arquivos. Esses níveis de acesso são:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_INDEXABLE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " o arquivo está acessível publicamente a qualquer um que tenha o URL. Os mecanismos de pesquisa podem indexar o arquivo."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_NOT_INDEXABLE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " o arquivo está acessível publicamente a qualquer um que tenha o URL. O cabeçalho X-Robots-Tag: noindex será enviado sempre que o arquivo for recuperado, instruindo aos mecanismos de pesquisa que não indexem o arquivo."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["**", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRIVATE"
            }), ": **o arquivo não está acessível publicamente. Requer um URL assinado para exibir o conteúdo. Os mecanismos de pesquisa não podem indexar o arquivo."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Pontos de extremidade que criam arquivos possibilitam um nível de pontuações duplicadas como parte das opções de upload do arquivo."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "ENTIRE_PORTAL"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " procure um arquivo duplicado na conta."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "EXACT_FOLDER"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " procura um arquivo duplicado na pasta fornecida."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "NONE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " não execute qualquer validação de arquivos duplicados."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "REJECT"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " rejeite o upload se um arquivo duplicado for encontrado."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "RETURN_EXISTING"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " se um arquivo duplicado for encontrado, não faça o upload de um novo arquivo, retorne o encontrado."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["A detecção de duplicados funciona em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationScope"
            }), ", o que afeta como procuramos um objeto duplicado."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Isso também exige um ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationStrategy"
            }), ", que instrui o que acontece se um arquivo duplicado for encontrado."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}