"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 37833970855;
const slug = exports.slug = 'guides/api/cms/media-bridge';
const title = exports.title = 'API du CMS | Media Bridge';
const name = exports.name = 'vNext Docs DP - Media Bridge';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "utiliser-l-api-de-passerelle-multim%C3%A9dia",
  "label": "Utiliser l'API de passerelle multimédia",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-et-personnaliser-vos-d%C3%A9finitions-d-objet-multim%C3%A9dia",
  "label": "Créer et personnaliser vos définitions d'objet multimédia",
  "parent": null
}, {
  "depth": 0,
  "id": "connecter-votre-application-de-passerelle-multim%C3%A9dia-au-compte-d-un-utilisateur-hubspot",
  "label": "Connecter votre application de passerelle multimédia au compte d'un utilisateur HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-vos-objets-multim%C3%A9dia",
  "label": "Créer vos objets multimédia",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-des-modules-de-cms-pour-int%C3%A9grer-du-contenu-multim%C3%A9dia",
  "label": "Créer des modules de CMS pour intégrer du contenu multimédia",
  "parent": null
}, {
  "depth": 1,
  "id": "configurer-un-domaine-oembed",
  "label": "Configurer un domaine oEmbed",
  "parent": "cr%C3%A9er-des-modules-de-cms-pour-int%C3%A9grer-du-contenu-multim%C3%A9dia"
}, {
  "depth": 0,
  "id": "cr%C3%A9er-un-module-personnalis%C3%A9",
  "label": "Créer un module personnalisé",
  "parent": null
}, {
  "depth": 0,
  "id": "envoyer-vos-%C3%A9v%C3%A9nements-multim%C3%A9dia",
  "label": "Envoyer vos événements multimédia",
  "parent": null
}, {
  "depth": 1,
  "id": "connecter-un-%C3%A9v%C3%A9nement-%C3%A0-une-fiche-d-informations-de-contact",
  "label": "Connecter un événement à une fiche d'informations de contact",
  "parent": "envoyer-vos-%C3%A9v%C3%A9nements-multim%C3%A9dia"
}, {
  "depth": 1,
  "id": "connexion-d-un-%C3%A9v%C3%A9nement-%C3%A0-un-contenu-m%C3%A9dia",
  "label": "Connexion d'un événement à un contenu média",
  "parent": "envoyer-vos-%C3%A9v%C3%A9nements-multim%C3%A9dia"
}, {
  "depth": 1,
  "id": "connecter-un-%C3%A9v%C3%A9nement-%C3%A0-une-page",
  "label": "Connecter un événement à une page",
  "parent": "envoyer-vos-%C3%A9v%C3%A9nements-multim%C3%A9dia"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API de passerelle multimédia"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'API de passerelle multimédia permet aux intégrateurs de transférer des objets multimédia tels que des fichiers vidéo et audio ainsi que des données de consommation multimédia dans HubSpot. Elle crée également les fonctionnalités suivantes dans le compte HubSpot de l'utilisateur :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modules pour intégrer des objets multimédia dans les éditeurs de glisser-déposer de HubSpot pour les pages et les e-mails."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Événements de chronologie CRM indiquant quand les prospects ou les clients ont interagi avec du contenu vidéo, audio et d'autres types de contenu multimédia."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Listes segmentées pour des expériences ciblées et personnalisées."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Workflows pour automatiser les interactions, en fonction des événements de consultation de contenu multimédia."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rapports pour mesurer l'impact des ressources multimédia."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La passerelle multimédia utilise à la fois des objets ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "personnalisés"
      }), " et des événements unifiés, le système de suivi des événements de HubSpot. Cela signifie que vous pouvez utiliser à la fois l'API de passerelle multimédia et l'API d'objets personnalisés pour créer votre intégration."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utiliser l'API de passerelle multimédia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous avez besoin d'un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "compte de développeur HubSpot"
      }), " pour enregistrer votre application de passerelle multimédia et configurer vos définitions d'objets multimédia initiales avant de connecter votre application au compte d'un utilisateur HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer et personnaliser vos définitions d'objet multimédia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour définir un objet multimédia, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/object-definitions"
      }), ". Vous utiliserez le paramètre ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaTypes"
      }), " pour définir l'objet : ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "DOCUMENT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "OTHER"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir défini vos objets multimédia, créez et modifiez les propriétés de l'objet multimédia en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/schemas/{objectType}"
      }), " et une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/properties/{objectType}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les appels d'API effectués incluront votre ID de compte de développeur en tant que paramètre de demande ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "portalID"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Connecter votre application de passerelle multimédia au compte d'un utilisateur HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour connecter votre application de passerelle multimédia au compte d'un utilisateur HubSpot, vous devez créer une définition d'application dans le compte de votre développeur HubSpot. Les définitions d'application incluent :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Des détails tels que le logo et le texte à afficher à l'utilisateur HubSpot lorsque votre intégration tentera de créer une connexion initiale à son compte ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les périmètres dont votre intégration a besoin dans le compte HubSpot de l'utilisateur."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour connecter votre application de passerelle multimédia à un compte utilisateur HubSpot :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Créez une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "définition d'application"
        }), " dans votre compte de développeur pour l'application de passerelle multimédia."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Incluez les périmètres suivants lors de la définition de votre application :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilisez l'authentification ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "OAuth"
        }), " lors de l'authentification des appels effectués par votre application. Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/intro-to-auth",
          children: "méthodes d'authentification"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour vérifier que l'application est correctement installée sur le portail d'un client :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Visitez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/media-bridge-demo/{HubID}"
        }), ", en remplaçant ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{HubID}"
        }), " par l'ID de compte."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'angle supérieur droit, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Application"
        }), " et sélectionnez votre ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "application de passerelle multimédia"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dans l'application, vous pouvez afficher les types de fichiers multimédia pris en charge par l'application et créer des exemples."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que l'application de passerelle multimédia a été installée sur le portail d'un client, vous pouvez :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-your-media-objects",
          children: "Créer des objets multimédia"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-cms-modules-to-embed-media",
          children: "Créer des modules CMS pour l'intégration de vos objets multimédia"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#send-your-media-events",
          children: "Envoyer des événements multimédia"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer vos objets multimédia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir créé vos définitions d'objet multimédia et installé votre application de passerelle multimédia dans le compte d'un utilisateur, vous pouvez utiliser le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "jeton OAuth"
      }), " pour créer et modifier des objets multimédia dans le compte. Étant donné que les objets multimédia sont des objets personnalisés, utilisez les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects#tab-2",
        children: "points de terminaison d'API d'objets personnalisés"
      }), " pour les créer :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/media-bridge/v1/{appId}/settings/object-definitions/{mediaType}"
        }), " pour trouver le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/{objectType}"
        }), " pour créer l'objet multimédia dans le compte de l'utilisateur."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un objet multimédia représente un élément de contenu pouvant être intégré dans un système tiers. Une fois qu'un objet multimédia est ajouté à la passerelle multimédia, il peut être intégré dans le contenu CMS de HubSpot et associé aux événements multimédia."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les objets multimédia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), " et ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), ", les tableaux ci-dessous répertorient toutes les propriétés par défaut et requises (* indique un élément obligatoire) :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un ID utilisé pour identifier l'élément multimédia spécifique dans le système de passerelle multimédia de HubSpot. Cette propriété est générée automatiquement par HubSpot et ne peut pas être définie par les développeurs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : une durée du contenu multimédia en millisecondes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : une URL qui doit renvoyer une réponse oEmbed valide respectant la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "spécification oEmbed"
            }), ". Nécessite un type ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), " avec un iFrame en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL du fichier multimédia brut. Elle pourra être utilisée à l'avenir à des fins d'intégration sur les réseaux sociaux."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL d'une image utilisée comme vignette pour l'intégration de l'élément multimédia dans le contenu La taille idéale pour cette miniature est de 640 x 480 pixels."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL d'une image représentant le contenu multimédia. Cette image doit avoir les mêmes dimensions que le média original et peut être utilisée aux endroits où une variable d'image est requise (par exemple quand le média est inséré dans un e-mail)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID du contenu multimédia dans le système du tiers. Il permet aux intégrateurs de récupérer du contenu multimédia à partir de la passerelle multimédia selon le même ID utilisé dans leur propre système (Il s'agit du point de terminaison d'API qui exploite ce mappage)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un chemin d'accès vers l'objet fourni par le fournisseur, désignant l'emplacement de l'objet dans le système de dossiers du tiers (le cas échéant). HubSpot tentera de représenter cette structure de répertoire lors de l'affichage de ces objets à l'utilisateur, mais pourra imbriquer les objets et dossiers de chaque fournisseur dans un dossier de premier niveau nommé en fonction du fournisseur."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom du contenu multimédia. Il sera affiché dans l'interface utilisateur HubSpot dans des endroits tels que le sélecteur de contenu multimédia."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL qui permet à un utilisateur de visualiser ou d'interagir avec le contenu multimédia dans le système du fournisseur de contenu multimédia. Elle est utilisée dans l'interface utilisateur HubSpot pour permettre aux utilisateurs d'identifier le contenu multimédia sans se baser uniquement sur son titre."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les objets multimédia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), ", les tableaux ci-dessous répertorient toutes les propriétés par défaut et requises (* indique un élément obligatoire) :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un ID utilisé pour identifier l'élément multimédia spécifique dans le système de passerelle multimédia de HubSpot. Cette propriété est générée automatiquement par HubSpot et ne peut pas être définie par les développeurs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : une URL qui doit renvoyer une réponse oEmbed valide respectant la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "spécification oEmbed"
            }), ". Nécessite un type ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), " avec un iFrame en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL du fichier multimédia brut. Elle pourra être utilisée à l'avenir à des fins d'intégration sur les réseaux sociaux."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL d'une image qui sera utilisée comme vignette pour l'intégration du contenu multimédia dans des endroits tels que le sélecteur de contenu multimédia. La taille idéale pour cette miniature est de 640 x 480 pixels."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL d'une image représentant le contenu multimédia. Cette image doit avoir les mêmes dimensions que le média original et peut être utilisée aux endroits où une variable d'image est requise (par exemple quand le média est inséré dans un e-mail)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID du contenu multimédia dans le système du tiers. Il permet aux intégrateurs de récupérer du contenu multimédia à partir de la passerelle multimédia selon le même ID utilisé dans leur propre système (Il s'agit du point de terminaison d'API qui exploite ce mappage)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un chemin d'accès vers l'objet fourni par le fournisseur, désignant l'emplacement de l'objet dans le système de dossiers du tiers (le cas échéant). HubSpot tentera de représenter cette structure de répertoire lors de l'affichage de ces objets à l'utilisateur, mais pourra imbriquer les objets et dossiers de chaque fournisseur dans un dossier de premier niveau nommé en fonction du fournisseur."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom du contenu multimédia. Il sera affiché dans l'interface utilisateur HubSpot dans des endroits tels que le sélecteur de contenu multimédia."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : une URL qui permet à un utilisateur de visualiser ou d'interagir avec le contenu multimédia dans le système du fournisseur de contenu multimédia. Elle est utilisée dans l'interface utilisateur HubSpot pour permettre aux utilisateurs d'identifier le contenu multimédia sans se baser uniquement sur son titre."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer des modules de CMS pour intégrer du contenu multimédia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque fournisseur d'applications de passerelle multimédia est responsable de la création de son propre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "module"
      }), " pour rendre son contenu multimédia dans CMS Hub."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'une application de passerelle multimédia est installée dans un compte HubSpot, le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#embed",
        children: "champ d'intégration"
      }), " du module présente le type de source supplémentaire ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Intégration de média"
      }), ". Cela permet à l'utilisateur de sélectionner du contenu multimédia à partir de l'application installée pour l'intégrer sur sa page de site web."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que l'utilisateur a sélectionné un élément multimédia à intégrer, les éléments ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_url"
      }), " et ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_response"
      }), " du contenu multimédia sont disponibles dans le HubL pour restituer facilement les lecteurs. De plus, les éléments ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "id"
      }), " et ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "media_type"
      }), " du contenu multimédia sélectionné sont stockés pour permettre l'interrogation de l'objet CRM sous-jacent via la fonction HubL ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "crm_objects"
      }), ". Cela peut être utilisé pour récupérer une ou toutes les propriétés qui font partie d'un objet multimédia."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Exemple d'utilisation de la fonction HubL crm_objects avec un objet multimédia dont les identifiants sont ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "459"
      }), " et ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "922"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set objects = crm_objects(\"a123_Videos\", [459,922]) %} {{ objects }}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer une image spécifique avec le même objet : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set object = crm_object(\"a123_Images\", 459) %} {{ object }}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les applications peuvent récupérer le type d'objet (« a123_Videos » dans l'exemple) en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/{appId}/settings/object-definitions/{mediaType}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les développeurs doivent utiliser les points de terminaison de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/source-code",
        children: "API de code source du CMS"
      }), " pour insérer leur code de module personnalisé dans les comptes des clients une fois que ceux-ci se sont connectés via oAuth. Une fois que le code du module est inséré dans le compte du client, il pourra automatiquement commencer à utiliser le module du développeur dans son contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurer un domaine oEmbed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour utiliser la fonction HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#oembed",
        children: "oEmbed"
      }), ", le domaine utilisé pour récupérer la réponse oEmbed doit être enregistré en effectuant une demande à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/oembed-domains"
      }), ". Les paramètres suivants doivent être inclus :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Schéma :"
          }), " la structure d'URL pour les URL de contenu multimédia. Cette structure d'URL est utilisée pour faire associer l'URL transmise dans la fonction HubL oEmbed à votre API oEmbed. Les caractères génériques sont pris en charge avec ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "*"
          }), " (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "www.domain.com/*"
          }), ")."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL :"
          }), " l'URL de votre API oEmbed. L du contenu multimédia est transmise à cette URL via un paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "URL"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Découverte (booléen) :"
          }), " détermine si votre API oEmbed prend en charge la fonctionnalité ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://oembed.com/#section4",
            children: "Découverte"
          }), " de oEmbed."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par défaut, les domaines oEmbed enregistrés seront mis à disposition de tous les clients ayant installé votre application. Si vous disposez de domaines personnalisés propres à chaque client, vous pouvez spécifier dans quel compte un domaine oEmbed doit être autorisé à être utilisé en transmettant une valeur portalId dans la demande d'API lors de la configuration du domaine oEmbed. Cela garantira que seul le compte HubSpot spécifié peut utiliser ce domaine oEmbed."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un module personnalisé"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer un module personnalisé :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fichiers et modèles"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Outils de conception"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'angle supérieur gauche, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fichier"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nouveau fichier"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la boîte de dialogue, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Que souhaiteriez-vous créer aujourd'hui ?"
        }), " et sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Module"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suivant"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez la case à cocher à côté de chaque type de contenu où votre module sera utilisé : ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "pages"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "articles de blog"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "listings de blog"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "e-mails"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "devis"
        }), ". Les modules utilisés dans les modèles d'e-mails ne peuvent pas inclure de code CSS ou JavaScript."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Indiquez si ce module sera un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "module local"
        }), " ou un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "module global"
        }), ". Si vous créez un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/design-manager-user-guide-v2/how-to-use-global-content-across-multiple-templates",
          children: "module global"
        }), ", la modification du contenu de ce module actualisera chaque emplacement où le module est utilisé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Saisissez un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom de fichier"
        }), " pour votre module, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Champs"
        }), " à droite, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ajouter un champ"
        }), " et sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intégrer"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/_cARgVZZLUD-nsJUIaG8XMUdSm1GRLr4Gfw96HeZ6SyXFG0t6yiiPKt5ToSe7nq8arSnG569_wg5X2XsD0XEtmEGiX9sbQxH7deUIJKGshXamME6vXrLN2oVSAt-cmrkTN2nggDsdkChDnEOxKrw5mo",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Types de sources pris en charge"
          }), ", sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Intégration de média"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Contenu intégré par défaut"
          }), ", cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sélectionner à partir de [application de passerelle multimédia]"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le panneau de droite, sélectionnez le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "contenu multimédia"
          }), " que vous souhaitez intégrer dans le module."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Définissez les options d'éditeur, conditions d'affichage du champ et options de répéteur de champ."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Nom de variable HubL"
          }), ", cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copier"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copier le bloc de texte"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Collez le bloc de texte dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "module.html"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Pour prévisualiser l'apparence du module sur votre page, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Aperçu"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la section de gauche, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sélectionner à partir de [[application de passerelle multimédia]"
          }), ", puis sélectionnez le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "contenu multimédia"
          }), " que vous souhaitez prévisualiser."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Envoyer vos événements multimédia"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un événement média est un événement qui se produit par rapport à un objet multimédia, comme un événement de lecture. Une fois qu'un événement multimédia est envoyé à l'application de passerelle multimédia, il peut être utilisé dans les rapports et dans les cartes CRM de chronologie."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe trois types d'événement multimédia :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Événement Lu :"
          }), " désigne lorsqu'un utilisateur a commencé à lire un contenu multimédia."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Événement Quartile :"
          }), " désigne lorsqu'un utilisateur a atteint des étapes (0 %, 25 %, 50 %, 75 %, 100 %) concernant le volume de contenu multimédia consulté."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Événement Niveau d'attention"
          }), " : désigne lorsqu'un utilisateur a entièrement consulté un contenu multimédia ou une fois qu'il a terminé sa session."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les événements peuvent être envoyés en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played-percent"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/attention-span respectively"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour que les événements multimédia soient affichés sur la chronologie de contact de l'utilisateur dans HubSpot, un événement ", (0, _jsxRuntime.jsx)("u", {
        children: "lu"
      }), " doit être envoyé à l'application de passerelle multimédia pour chaque session. Les événements d'une seule session seront affichés dans une carte sur la chronologie d'activité du contact."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque les événements sont envoyés à l'aide des points de terminaison d'événements v2, ils sont traités de manière asynchrone, contrairement à ceux envoyés via les points de terminaison v1. À ce titre, nous recommandons ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La version v1 des points de terminaison doit être utilisée pour n'importe quel test car une demande erronée sera immédiatement supprimée."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La version v2 des points de terminaison doit être utilisée en production car sa nature asynchrone permettra d'éviter les retards dans l'environnement client pendant que l'événement est rédigé sur la passerelle multimédia. Les événements sont également conservés et retentés en cas de défaillance temporaire du serveur de la passerelle multimédia."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Connecter un événement à une fiche d'informations de contact"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour connecter un événement multimédia à une fiche d'informations de contact, les intégrateurs doivent fournir le ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " ou un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), ". Si seul un élément ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), " est fourni, il sera converti en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), ". Si les deux sont fournis dans la demande, le ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " sera utilisé comme le centre unique d'informations. Ce paramètre permet à l'application de passerelle multimédia de créer une association entre la fiche d'informations de contact et l'événement."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois l'évènement multimédia connecté à la fiche d'informations de contact, il peut être utilisé dans des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/create-reports-with-the-custom-report-builder",
        children: "rapports d'objets croisés"
      }), ". Ainsi, les clients pourront associer leurs événements multimédia à des fiches d'informations de contact ainsi qu'à des entreprises et transactions associées."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Connexion d'un événement à un contenu média"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour associer un événement multimédia à un contenu multimédia, les paramètres ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "externalID"
      }), " doivent être inclus dans la demande. Si les deux sont fournis, le ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), " sera utilisé comme le centre unique d'informations."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Connecter un événement à une page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour associer un évènement multimédia à une page HubSpot, les paramètres suivants doivent être inclus dans la demande :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si la page est hébergée sur CMS Hub, le ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageId"
        }), " doit être fourni."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si la page ", (0, _jsxRuntime.jsx)("u", {
          children: "n'est pas"
        }), " hébergée sur CMS Hub, le ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageName"
        }), " et le ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageUrl"
        }), " doivent être inclus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le tableau ci-dessous présente les propriétés prises en charge pour les trois événements multimédia :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type d'événement"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tous les événements"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID du contenu multimédia auquel cet événement est relié."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ID du contenu multimédia dans le système du tiers. Il permet aux développeurs de mentionner le contenu multimédia dans la passerelle multimédia selon le même ID utilisé dans leur propre système Il peut être utilisé à la place de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " dans les événements. Si un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            }), " et un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " sont fournis, le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " sera utilisé et le externalId sera ignoré.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sessionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tous les événements"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un identifiant unique pour désigner une session de visualisation. Cela peut signifier différentes choses pour différents fournisseurs. HubSpot laisse les fournisseurs décider de ce qu'une session signifie pour eux. Il sera utilisé pour regrouper des événements qui se sont déroulés dans la même session. L'identifiant devrait être généré par le système du tiers."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tous les événements"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : l'identifiant du contact dans le système de HubSpot qui a consulté le contenu multimédia. Cela peut être récupéré à l'aide de l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/objects/contacts/v1#get-a-contact-by-its-user-token",
              children: "API Get contact by usertoken (utk) de HubSpot"
            }), ". L'API prend également en charge la fourniture d'un jeton d'utilisateur et gérera la conversion automatique de celui-ci en ID de contact."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactUtk"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tous les événements"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le jeton d'utilisateur (utk) qui identifie quel contact a consulté le contenu multimédia."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tous les événements"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID de contenu de la page sur laquelle un événement s'est produit."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tous les événements"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom ou le titre de la page sur laquelle un événement s'est produit."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tous les événements"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL de la page sur laquelle un événement s'est produit."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredTimestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tous les événements"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'horodatage auquel cet événement s'est produit (en millisecondes epoch)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString / attentionSpanMap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Niveau d'attention"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : ce sont les données brutes qui fournissent les données les plus granulaires sur les niveaux du contenu multimédia ainsi que sur le nombre de consultations de chaque niveau par l'utilisateur. Exemple : supposons une vidéo de 10 secondes où chaque seconde est un niveau. Si un visiteur regarde les 5 premières secondes de la vidéo, puis redémarre la vidéo et regarde les 2 premières secondes à nouveau, l'élément ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString"
            }), " résultant sera ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "“0=2;1=2;2=1;3=1;4=1;5=0;6=0;7=0;8=0;9=0;”"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Niveau d'attention"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le pourcentage du contenu multimédia consulté par l'utilisateur. Les fournisseurs peuvent calculer cela différemment en fonction de la façon dont ils considèrent les vues répétées de la même portion du contenu multimédia. Pour cette raison, l'API ne tentera pas de valider totalPercentWatched par rapport aux informations de niveau d'attention de l'événement. S'il est manquant, HubSpot calculera cela à partir de la carte de niveau d'attention comme suit : (nombre de niveaux avec une valeur supérieure ou égale à 1)/(nombre total de niveaux)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalSecondsPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Niveau d'attention"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : les secondes qu'un utilisateur a passées à consulter le contenu multimédia. La passerelle multimédia calcule cela comme suit : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            }), "*", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaDuration"
            }), ". Si un fournisseur souhaite que cela soit calculé différemment, il peut fournir la valeur précalculée lorsqu'il crée l'événement."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "playedPercent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Événement Quartile"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : une valeur de pourcentage de quartile (0, 25, 50, 75, 100) pour la quantité de contenu multimédia qui a été consultée jusqu'à présent."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "iframeUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Événement Lu"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : une URL qui peut être utilisée pour afficher des données provenant d'un système externe via un iFrame. Une fois inclus, l'événement sur la chronologie de contact affichera un lien vers une fenêtre modale qui affichera le contenu de iFrame en cliquant dessus."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le type multimédia auquel l'événement appartient (par exemple : VIDEO ou AUDIO). Cela nous permet d'assigner correctement l'événement aux bons objets lorsqu'un fournisseur prend en charge plusieurs types de contenu multimédia.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}