"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742941;
const slug = exports.slug = 'guides/api/crm/extensions/video-conferencing';
const title = exports.title = 'API du CRM | Extension de vidéoconférence';
const name = exports.name = 'EMEA (FR) vNext Docs DP - VCE';
const metaDescription = exports.metaDescription = 'Ces points de terminaison sont utilisés pour se connecter au flux de création des réunions dans HubSpot et ajouter des informations de vidéoconférence.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "api-de-param%C3%A8tres",
  "label": "API de paramètres",
  "parent": null
}, {
  "depth": 1,
  "id": "d%C3%A9finition-d-objet-de-param%C3%A8tres",
  "label": "Définition d'objet de paramètres",
  "parent": "api-de-param%C3%A8tres"
}, {
  "depth": 1,
  "id": "cr%C3%A9er-ou-mettre-%C3%A0-jour-des-param%C3%A8tres-d-extension-de-vid%C3%A9oconf%C3%A9rence",
  "label": "Créer ou mettre à jour des paramètres d'extension de vidéoconférence",
  "parent": "api-de-param%C3%A8tres"
}, {
  "depth": 1,
  "id": "r%C3%A9cup%C3%A9rer-des-param%C3%A8tres-d-extension-de-vid%C3%A9oconf%C3%A9rence",
  "label": "Récupérer des paramètres d'extension de vidéoconférence",
  "parent": "api-de-param%C3%A8tres"
}, {
  "depth": 1,
  "id": "cr%C3%A9er-un-webhook-de-r%C3%A9union",
  "label": "Créer un webhook de réunion",
  "parent": "api-de-param%C3%A8tres"
}, {
  "depth": 1,
  "id": "mettre-%C3%A0-jour-le-webhook",
  "label": "Mettre à jour le webhook",
  "parent": "api-de-param%C3%A8tres"
}, {
  "depth": 1,
  "id": "supprimer-le-webhook-de-r%C3%A9union",
  "label": "Supprimer le webhook de réunion",
  "parent": "api-de-param%C3%A8tres"
}, {
  "depth": 1,
  "id": "webhook-de-v%C3%A9rification-d-utilisateur",
  "label": "Webhook de vérification d'utilisateur",
  "parent": "api-de-param%C3%A8tres"
}, {
  "depth": 1,
  "id": "signature-du-webhook",
  "label": "Signature du webhook",
  "parent": "api-de-param%C3%A8tres"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ol: "ol",
      li: "li",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      strong: "strong",
      pre: "pre",
      ul: "ul",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Extension de vidéoconférence"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'API de vidéoconférence permet aux utilisateurs de se connecter au flux de création des réunions dans HubSpot et d'ajouter des informations de vidéoconférence. L'utilisation de cette API comprend les étapes suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Configurez votre application avec les webhooks d'extensions de vidéoconférence. Vous fournirez des URI que HubSpot utilisera pour vous informer lorsque les clients créent ou mettent à jour des réunions."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Gérez les webhooks de création de réunions et, éventuellement, les webhooks de mise à jour des réunions."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le cas échéant, gérez les webhooks de vérification d'identité des utilisateurs."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API de paramètres"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les développeurs utiliseront l'API de paramètres pour configurer une application existante. Vous pouvez utiliser le jeton d'accès de votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "application privée"
      }), " pour authentifier la demande."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définition d'objet de paramètres"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les paramètres présentent les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUrl"
      }), " : l'URL où nous enverrons des demandes de nouvelles vidéoconférences. Le protocole ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HTTPS"
      }), " doit être utilisé."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUrl"
      }), " (facultatif) : l'URL où nous enverrons des mises à jour de réunions existantes. Cet élément est généralement appelé lorsque l'utilisateur modifie le sujet ou les heures d'une réunion. Le protocole HTTPS doit être utilisé."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUrl"
      }), " (facultatif) : l'URL où nous vous informerons des réunions qui ont été supprimées dans HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUrl"
      }), " (facultatif) : l'URL utilisée pour vérifier qu'un utilisateur existe dans votre système."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer ou mettre à jour des paramètres d'extension de vidéoconférence"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de demande :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT /crm/v3/extensions/videoconferencing/settings/{appId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de réponse :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example 200 response\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de réponse :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les valeurs facultatives doivent être exclues de la demande. Des chaînes vides ou d'autres valeurs déboucheront probablement sur un comportement non désiré."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Récupérer des paramètres d'extension de vidéoconférence"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de demande :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET /crm/v3/extensions/videoconferencing/settings/{appId}   "
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de réponse :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example 200 response\n{\n\"createMeetingUrl\": \"https://example.com/create-meeting\",\n\"updateMeetingUrl\": \"https://example.com/update-meeting\"\n\"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n\"userVerifyUrl\": \"https://example.com/user-verify\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer un webhook de réunion"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'une réunion est créée, HubSpot enverra une demande à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUri"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de demande :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request\n{\n\"portalId\": 123123,\n\"userId\": 123,\n\"userEmail\": \"test.user@example.com\",\n\"topic\": \"A Test Meeting\",\n\"source\": \"MEETINGS\"\n\"startTime\": 1534197600000,\n\"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les champs de cette demande sont :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "portalId"
        }), " : l'ID du compte HubSpot (appelé portail)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), " : l'ID unique de l'utilisateur HubSpot qui possède la réunion."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userEmail"
        }), " : l'adresse e-mail de l'utilisateur HubSpot qui possède la réunion."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "topic"
        }), " : le sujet/titre de la réunion."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "source"
        }), " : MEETINGS ou MANUAL, indiquant la fonctionnalité de réunion dans", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "l'application HubSpot où la réunion a été créée. MEETINGS correspond à", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "la fonction Lien de réunion et MANUAL correspond à une réunion créée dans", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "le communicateur CRM."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startTime"
        }), " : l'heure de début de la réunion (en millisecondes epoch)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endTime"
        }), " : l'heure de fin de la réunion (en millisecondes epoch)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour gérer ce webhook, le développeur doit générer une vidéoconférence pour cette réunion (ou le lien vers une ligne de conférence existante) et répondre avec des informations concernant cette conférence."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de réponse :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"conferenceUrl\": \"https://example.com/join\",\n  \"conferenceDetails\": \"Click here to join: https://example.com/join\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les champs indiqués dans cette réponse sont les suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), " : un identifiant unique associé à la conférence sur cet événement.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Cet ID doit être globalement unique dans votre système. Nous renverrons cet ID", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "dans le webhook de mise à jour."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceUrl"
        }), " : le lien de connexion pour la conférence créée. Il peut être", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "placé dans le champ Localisation des événements."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceDetails"
        }), " : informations d'invitation en texte brut. Elles doivent décrire", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "l'accès des participants à l'événement à la vidéoconférence pour cet", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "événement. Les nouvelles lignes seront conservées dans les représentations de ce texte dans", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "notre système, mais aucun autre formatage n'est pris en charge."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mettre à jour le webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous avez défini une valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUri"
      }), ", HubSpot enverra à cet URI une demande en cas de modification de détails pertinents de la réunion. Cette notification est nécessaire si vous devez mettre à jour le sujet ou les heures d'une vidéoconférence."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de demande :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"userId\": 123,\n  \"userEmail\": \"test.user@example.com\",\n  \"portalId\": 123123,\n  \"topic\": \"A Test Meeting (updated)\",\n  \"startTime\": 1534197600000,\n  \"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les champs indiqués dans cette réponse sont les suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), " : l'ID unique de la conférence fournie par votre intégration dans la réponse au webhook de création de réunion."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), " : l'ID unique de l'utilisateur HubSpot qui possède la réunion. La valeur userId sera toujours la même que la demande de création de réunion."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userEmail"
        }), " : l'adresse e-mail de l'utilisateur HubSpot qui possède la réunion. La valeur userEmail sera toujours la même que la demande de création de réunion."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "topic"
        }), " : le sujet/titre de la réunion."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startTime"
        }), " : l'heure de début de la réunion (en millisecondes epoch)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "endTime"
      }), " : l'heure de fin de la réunion (en millisecondes epoch)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aucun corps de réponse n'est requis lors de la réponse à ces demandes. HubSpot exige uniquement", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "un code de réponse 200 ou 204 pour informer de la bonne réception", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "de ce webhook."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer le webhook de réunion"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'une réunion est supprimée dans HubSpot, nous enverrons une demande à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUri"
      }), ".", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de demande :"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette demande ne contient que la valeur conferenceId de la réunion supprimée.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Aucun corps de réponse n'est requis lors de la réponse à ces demandes. HubSpot exige uniquement", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "un code de réponse 200 ou 204 pour informer de la bonne réception", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "de ce webhook."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhook de vérification d'utilisateur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les systèmes HubSpot communiqueront toujours avec vous sur les utilisateurs concernant leurs ID d'utilisateur HubSpot et leurs adresses e-mail de compte HubSpot. Il est possible qu'un utilisateur dans le système HubSpot existe dans votre système avec une adresse e-mail ou un identifiant différent."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Avant de passer un appel à votre système pour créer, mettre à jour ou supprimer un lien de vidéoconférence, nous vérifierons tout d'abord vos paramètres pour une valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUri"
      }), ". Si cet URI a été défini, nous effectuerons un appel pour récupérer votre identifiant d'utilisateur natif. Nous enverrons alors cet identifiant en tant qu'adresse e-mail de l'utilisateur dans", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "l'appel suivant."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe des endroits dans l'application HubSpot où nous pouvons effectuer cet appel pour vérifier que l'utilisateur existe dans votre système avant d'afficher les composants de l'interface utilisateur avec lesquels il peut interagir, comme une sorte de vérification préalable. Si vous ne configurez pas cet URI, nous supposerons que l'identité de l'utilisateur est vérifiée."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il vous revient de prendre en charge cette fonctionnalité. Si vous devez mettre à jour un mappage d'utilisateur dans votre système, vous pouvez simplement mapper l'ID d'utilisateur ou l'adresse e-mail à votre ID d'utilisateur interne sur chaque appel."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "La demande ressemblera à ceci :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"portalId\": 123123,\n  \"userEmail\": \"test.user@example.com\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez renvoyer une erreur 200 ou tout autre code d'erreur (404 serait approprié). Si vous renvoyez une erreur 200, vous devriez renvoyer une charge utile contenant le nouvel ID que nous utilisons au lieu de l'adresse e-mail :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"id\": \"any-string-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Signature du webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les webhooks envoyés par HubSpot sont signés en HMAC avec le secret de votre application. Consultez la section Sécurité de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "cette page"
      }), " (le reste de la page Vue d'ensemble des webhooks ne s'applique pas aux webhooks d'extension de vidéoconférence)."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}