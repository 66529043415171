"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 152722459748;
const slug = exports.slug = 'guides/api/settings/currencies';
const title = exports.title = 'Settings API | Currencies';
const name = exports.name = 'Settings API | Currencies';
const metaDescription = exports.metaDescription = "Learn more about the currencies API endpoints to view and update your account's currencies and exchange rates.";
const badge = exports.badge = 'NEW';
const toc = exports.toc = [{
  "depth": 0,
  "id": "supported-currencies",
  "label": "Supported currencies",
  "parent": null
}, {
  "depth": 0,
  "id": "add-account-currencies-and-set-exchange-rates",
  "label": "Add account currencies and set exchange rates",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-account-currencies-and-exchange-rates",
  "label": "Retrieve account currencies and exchange rates",
  "parent": null
}, {
  "depth": 0,
  "id": "update-your-account-s-company-currency",
  "label": "Update your account's company currency",
  "parent": null
}, {
  "depth": 0,
  "id": "update-currency-exchange-rates",
  "label": "Update currency exchange rates",
  "parent": null
}, {
  "depth": 0,
  "id": "hide-a-currency",
  "label": "Hide a currency",
  "parent": null
}, {
  "depth": 0,
  "id": "limits-and-scopes",
  "label": "Limits and scopes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Currencies"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With the currencies API, you can manage the currencies used in your HubSpot account, including setting your account's company currency, creating additional currencies, and updating currency exchange rates. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/add-and-edit-your-account-currencies",
        children: "adding and editing currencies within HubSpot."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supported currencies"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Only certain currencies are supported for use in HubSpot. To retrieve a list of HubSpot's supported currencies and their codes, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/codes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use any of the returned codes as values for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "currencyCode"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fromCurrencyCode"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toCurrencyCode"
      }), " properties."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add account currencies and set exchange rates"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depending on your HubSpot subscription, you can add additional currencies for use in your account, and set their exchange rates compared to your company currency. Learn more about the number of currencies you can have in your account in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "HubSpot Product & Services Catalog."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add a currency and set the exchange rate, or add a new exchange rate for an existing currency, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/exchange-rates"
      }), ". In your request, the following fields are required:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromCurrencyCode"
        }), ": the currency code of the currency you want to add to your account. This must be one of HubSpot's supported currency codes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conversionRate"
        }), ": the exchange rate from the additional currency to your company currency. The value must be greater than 0 and can contain up to 6 decimal values (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1.36"
        }), "). Any values with more than 6 decimal places will be rounded (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1.2345678"
        }), " becomes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1.234568"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also include a timestamp using the following field:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "effectiveAt"
        }), ": the date and time the exchange rate takes effect. Values can be in either ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.jarte.com/help_new/date_and_time_formats.html",
          children: "long date"
        }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/api/faq#timestamps",
          children: "ISO 8601"
        }), " format."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request may look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request\n{\n  \"fromCurrencyCode\": \"EUR\",\n  \"conversionRate\": \"1.3\",\n  \"effectiveAt\": \"2023-05-08T14:59:19.813Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add multiple currencies and set their exchange rates in bulk, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/exchange-rates​/batch​/create"
      }), ". Your request must include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fromCurrencyCode"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversionRate"
      }), " properties for each currency."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request may look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body\n{\n  \"inputs\": [\n    {\n      \"fromCurrencyCode\": \"USD\",\n      \"conversionRate\": 2.7\n    },\n    { \"fromCurrencyCode\": \"EUR\", \"conversionRate\": 1.3 }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve account currencies and exchange rates"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can view your account's current and historical currencies and exchange rates."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view your account's company currency, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/company-currency"
      }), ". To view all of your account's current currencies and exchange rates, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/exchange-rates/current"
      }), ". When you make a successful request, the response will include the IDs of the exchange rates set in your account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view a specific currency and its exchange rate, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/exchange-rates/{exchangeRateId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all of your account's currencies and exchange rates, including historical currencies, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/exchange-rates"
      }), ". You can filter which currencies and exchange rates are returned using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "limit"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "after"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fromCurrencyCode"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toCurrencyCode"
      }), " query parameters in your request."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update your account's company currency"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Your account's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/add-and-edit-your-account-currencies#company-currency",
        children: "company currency"
      }), " is the currency used in deal amount totals, deal reports, and when creating new deals in HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update your account's company currency, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/company-currency"
      }), ". In your request, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "currencyCode"
      }), " value for the currency you want to set as your company currency. For example, your request may look like:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body\n{\n  \"currencyCode\": \"AED\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": for accounts with multiple currencies, the company currency will have an automatically generated self-referencing rate (e.g., EUR/EUR=1.0). This rate is ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " modifiable via API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update currency exchange rates"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once currencies are set up in your HubSpot account, you can update their exchange rates. To update an individual exchange rate, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/exchange-rates/{exchangeRateId}"
      }), ". In your request, include the new exchange rate in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversionRate"
      }), " property. Your request may look like:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body\n{\n  \"conversionRate\": 2\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update multiple currencies, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/exchange-rates/batch/update"
      }), ". In your request, include the exchange rate IDs and the new ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversionRate"
      }), " values. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body\n{\n  \"inputs\": [\n    {\n      \"id\": \"string\",\n      \"conversionRate\": 1.3\n    },\n    {\n      \"id\": \"string\",\n      \"conversionRate\": 2\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Hide a currency"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can hide a currency so that it's not visible in your HubSpot account. To hide an existing account currency, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/settings/v3/currencies/exchange-rates/update-visibility"
      }), ". In your request, include the following:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromCurrencyCode"
        }), ": the code of the currency you want to hide or show."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toCurrencyCode"
        }), ": the code of your company currency."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "visibleInUI"
        }), ": whether or not the currency and its exchange rate are shown in your HubSpot account. To hide a currency, the value should be ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), ". To show a previously hidden currency, set the value should be set to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To hide a currency, your request may look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body\n{\n  \"fromCurrencyCode\": \"USD\",\n  \"toCurrencyCode\": \"AED\",\n  \"visibleInUI\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limits and scopes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The daily limit for creating exchanges rates is 1000. This is a total limit which includes rates created both individually and in batches."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are different scopes that a users needs in order to view or edit currency and exchange rate data:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "multi-currency-read"
        }), ": required to retrieve and read currency data."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "multi-currency-write"
        }), ": required to edit currency data."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}