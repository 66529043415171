"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 30866940858;
const slug = exports.slug = 'guides/api/conversations/visitor-identification';
const title = exports.title = 'Identificación de visitantes';
const name = exports.name = 'vNext Docs DP - Identificación de visitantes';
const metaDescription = exports.metaDescription = 'La API de identificación de visitantes se utiliza para identificar a los visitantes de tu sitio que se autenticaron utilizando sistemas de autenticación externos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "flujo-de-integraci%C3%B3n-de-ejemplo",
  "label": "Flujo de integración de ejemplo",
  "parent": null
}, {
  "depth": 0,
  "id": "verifica-la-integraci%C3%B3n",
  "label": "Verifica la integración",
  "parent": null
}, {
  "depth": 0,
  "id": "manual-del-sdk-del-widget-de-chat",
  "label": "Manual del SDK del widget de chat",
  "parent": null
}, {
  "depth": 1,
  "id": "referencia-de-sdk",
  "label": "Referencia de SDK",
  "parent": "manual-del-sdk-del-widget-de-chat"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      h2: "h2",
      a: "a",
      img: "img",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Identificación de visitantes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise', 'sales_hub-professional', 'sales_hub-enterprise', 'cms-professional', 'cms-enterprise', 'service_hub-professional', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utiliza la API de identificación de visitantes para identificar a los visitantes de tu sitio que se autenticaron utilizando tu propio sistema de autenticación externo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un token de identificación devuelto por esta API puede utilizarse para pasar información sobre tu visitante ya autenticado al widget de chat, de modo que trate al visitante como un contacto conocido. Así, los agentes en la bandeja de entrada pueden estar seguros de con quién están hablando, y los visitantes pueden acceder al historial de conversaciones anteriores en todos los dispositivos. Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "La API Identificación de visitantes sirve para decirle a HubSpot quién es el visitante. No debes confiar en esto para autenticar a los usuarios en tu plataforma."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["El acceso a la API Identificación de visitantes requiere una suscripción de nivel ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Pro"
          }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), ". Si la cuenta no tiene una suscripción válida, recibirás una respuesta de error ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "403"
          }), " de la API."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Flujo de integración de ejemplo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para integrarte con esta característica, debes tener una aplicación web existente con un sistema de autenticación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Antes de comenzar, asegúrate de tener una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "aplicación privada"
      }), " configurada y de que la cuenta que intentas integrar tenga una suscripción ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Pro"
      }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " que cumpla con los requisitos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este es un ejemplo de un posible flujo de integración:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hubfs/Possible%20User%20Identification%20Flow.png",
        alt: "Posible flujo de identificación del usuario"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que el cliente haya iniciado sesión y haya sido verificado en tu sistema, sigue los pasos a continuación para identificarlo en el chat en vivo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "1."
      }), " En tu front end, establece loadImmediately en falso el objeto hsConversationsSettings de la ventana. Si no haces esto, el widget de chat se puede cargar antes de que se pase la información de identificación. Para más información, consulta el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#chat-widget-sdk-primer",
        children: "manual del SDK de widgets de chat"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Establece las propiedades de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), " fuera de la función ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isConversationsAPIReady"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Además, la dirección ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), " debe establecerse en un valor antes de la llamada; de lo contrario, puede producirse una condición de carrera que interfiera con la carga del widget."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "2."
      }), " Genera un token a partir de la API Identificación de visitantes introduciendo la dirección de correo electrónico de tu visitante autenticado. Esto debe hacerse en la parte posterior de tu aplicación web. Consulta la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Puntos de terminación"
      }), " para ver un ejemplo de solicitud."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubspot.com/conversations/v3/visitor-identification/tokens/create \\\n--data '{\n  \"email\": \"gob@bluth.com\",\n  \"firstName\": \"Gob\",\n  \"lastName\": \"Bluth\"\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "El nombre y apellidos proporcionados se establecerán en el registro de contacto en HubSpot después de que comience el chat si:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "es un nuevo contacto creado por la API Identificación de visitantes."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "es un contacto existente cuyo nombre aún no se conoce."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Esto puede ser útil al personalizar mensajes a visitantes identificados cuando tu sistema externo ya tiene información de nombre, pero aún no existe en HubSpot. Se trata de parámetros opcionales y no son obligatorios."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "3."
      }), " Utilizando el token Paso 2, establece en un valor las siguientes propiedades en el objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " de la ventana."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**4.**Carga el widget."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El token y el correo electrónico deben ajustarse en el objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " de la ventana cada vez que se cargue la página para un visitante autentificado. Este contexto no se llevará a través de las cargas de página automáticamente si estos parámetros ya no están establecidos. Los tokens son temporales y caducarán después de 12 horas. Los tokens se pueden almacenar en caché para evitar volver a obtener el token en cada carga de página, siempre y cuando se actualicen al menos cada 12 horas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verifica la integración"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que hayas completado la integración de la característica Identificación de visitantes, puedes comprobar que funciona como esperabas. Esto puede hacerse de un par de maneras, dependiendo de tu implementación, por lo que puede que tengas que adaptar los ejemplos siguientes a tus requisitos específicos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Si has agregado el widget de chat a una o más páginas públicas, así como detrás de un sistema de autenticación:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "Navega hasta una página en la que el widget de chat no deba identificar a los visitantes e inicia una conversación."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["En HubSpot, abre la bandeja de entrada y comprueba que el chat que acaba de entrar pertenece a un ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Visitante desconocido"
              }), ". Si no es así, intenta seguir estos pasos en una ventana de navegación privada:"]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "Navega hasta una página en la que el widget de chat deba identificar a los visitantes mediante la API Identificación de visitantes e inicia una conversación."
                }), "\n"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "En HubSpot, abre la bandeja de entrada y comprueba que el chat se atribuye correctamente al contacto con el que has iniciado sesión. Deberías ver una insignia junto al nombre del contacto, indicando que este contacto se identificó correctamente a través de esta API."
                }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: (0, _jsxRuntime.jsx)(_components.img, {
                    src: "https://f.hubspotusercontent00.net/hubfs/53/visitor_identity_badge.png",
                    alt: "visitor_identity_badge"
                  })
                }), "\n"]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Si sólo has agregado el widget de chat a páginas detrás de un sistema de autenticación, y tienes acceso a varias cuentas de usuario de prueba:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Inicia sesión en HubSpot como primer usuario de prueba, luego ve a una página en la que se cargue el widget de chat e inicia una conversación."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Sal de HubSpot y vuelve a entrar como segundo usuario de prueba. Navega a una página donde se carga el widget de chat y comienza una conversación."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "En HubSpot, abre la bandeja de entrada y comprueba que los chats que entraron proceden de la primera y segunda cuentas de prueba, respectivamente, y que ves la insignia junto a los nombres de contacto de ambos registros."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " para los visitantes identificados con esta API, HubSpot no instalará la cookie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), ". HubSpot también omitirá cualquier pregunta de captura de correo electrónico, ya que la dirección de correo electrónico ya se conoce. Dado que la cookie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), " y la captura de correo electrónico no se aplican a estos chats, la configuración asociada en el chatflow no se mostrará para los visitantes identificados a través de la API Identificación de visitantes."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Manual del SDK del widget de chat"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La API está alojada en el objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), ". Todos los métodos disponibles pueden acceder a través de este objeto. El cargador de scripts de HubSpot en tu página creará este objeto para ti, pero es posible que no esté disponible inmediatamente. Para aplazar el acceso a la API hasta que esté inicializada, puedes utilizar el ayudante ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), ". Por ejemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Referencia de SDK"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "matriz"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es un campo opcional que puedes definir en el objeto ventana y que te permite especificar el código que se ejecutará en cuanto el widget esté disponible. Una vez que la API haya sido inicializada, comprobará la existencia de esta matriz y ejecutará las funciones en serie."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "if (window.HubSpotConversations) {\n  console.log('The api is ready already');\n} else {\n  window.hsConversationsOnReady = [\n    () => {\n      console.log('Now the api is ready');\n    },\n  ];\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "objeto"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este objeto te permite proporcionar algunas opciones de configuración al widget antes de inicializar. Para usar la característica de identificación de visitantes, debes establecer los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si el widget debe cargarse implícitamente o esperar hasta que se llame al método ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationToken"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se utiliza para integrarse con la API Identificación de visitantes. Este es el token proporcionado por el punto de terminación de generación de tokens en la API de identificación de visitantes que se utiliza como prueba de que este visitante ha sido identificado."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationEmail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La dirección de correo electrónico del visitante que has identificado como el que carga el widget."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/conversations/chat-widget-sdk",
        children: "SDK de conversaciones"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}