"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 159687984292;
const slug = exports.slug = 'guides/api/crm/commerce/invoices';
const title = exports.title = 'CRM API | Invoices';
const name = exports.name = 'Retrieve invoices';
const metaDescription = exports.metaDescription = 'Invoices are used to bill customers. The invoices endpoint allows you to retrieve invoices. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "retrieve-invoices",
  "label": "Retrieve invoices",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "retrieve-invoices"
}, {
  "depth": 1,
  "id": "search-for-invoices-by-properties",
  "label": "Search for invoices by properties",
  "parent": "retrieve-invoices"
}, {
  "depth": 0,
  "id": "associations",
  "label": "Associations",
  "parent": null
}, {
  "depth": 1,
  "id": "retrieving-an-invoice-with-associated-line-items",
  "label": "Retrieving an invoice with associated line items",
  "parent": "associations"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Invoices"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the invoices API to fetch information about an account's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/invoices/create-and-manage-invoices",
        children: "invoices"
      }), ". This is a read-only API, so it cannot be used for creating new or managing existing invoices."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, use this API to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#search-for-invoices-by-properties",
        children: "fetch all currently open invoices"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve invoices"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depending on the information you need, there are a few ways to retrieve invoices:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve all invoices, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/invoices"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a specific invoice, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to the above URL and specify an invoice ID. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/invoices/44446244097"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve invoices that meet a specific set of criteria, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to the search endpoint and include filters in the request body. See an example of using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#search-for-invoices-by-filter-criteria",
          children: "search endpoint below"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include a few default properties, including the create date, last modified date."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"44446244097\",\n  \"properties\": {\n    \"hs_createdate\": \"2023-03-08T14:54:17.333Z\",\n    \"hs_lastmodifieddate\": \"2024-03-01T22:33:09.011Z\",\n    \"hs_object_id\": \"44446244097\"\n  },\n  \"createdAt\": \"2023-03-08T14:54:17.333Z\",\n  \"updatedAt\": \"2024-03-01T22:33:09.011Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To return specific properties, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " query parameter in the request URL along with comma-separated property names. For example, making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the following URL would result in the response below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/invoices?properties=hs_invoice_status,hs_amount_billed"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"44446244097\",\n  \"properties\": {\n    \"hs_amount_billed\": \"20.00\",\n    \"hs_createdate\": \"2023-03-08T14:54:17.333Z\",\n    \"hs_invoice_status\": \"open\",\n    \"hs_lastmodifieddate\": \"2024-03-01T22:33:09.011Z\",\n    \"hs_object_id\": \"44446244097\"\n  },\n  \"createdAt\": \"2023-03-08T14:54:17.333Z\",\n  \"updatedAt\": \"2024-03-01T22:33:09.011Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available invoice properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/invoices"
      }), ". Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are some common invoice properties that you may want to query."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Label in UI"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_invoice_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties#:~:text=Legacy%20Quickbooks%20integration.-,Invoice%20Status,-%3A%20the%20current",
              children: "Invoice status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The current status of the invoice. Values include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "draft"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "open"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "paid"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "voided"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_amount_billed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties#:~:text=creating%20the%20invoice.-,Amount%20billed,-%3A%20the%20total",
              children: "Amount billed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The amount billed on the invoice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_balance_due"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties#:~:text=on%20the%20invoice.-,Balance%20due,-%3A%20the%20current",
              children: "Balance due"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The balance due on the invoice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_due_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties#:~:text=USD%20is%20supported.-,Due%20date,-%3A%20the%20invoice%E2%80%99s",
              children: "Due date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The date the invoice is due."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties#:~:text=creating%20the%20invoice.-,Number,-%3A%20the%20unique",
              children: "Number"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The invoice number (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INV_1003"
            }), ")"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Search for invoices by properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the search ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search",
        children: "endpoint"
      }), " to retrieve invoices that meet a specific set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search#filter-search-results",
        children: "filter criteria"
      }), ". This will be a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request that includes your filter criteria in the request body."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to search for all open invoices, you would make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/invoices/search"
      }), " with the following request body:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example search request body\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"hs_invoice_status\",\n          \"value\": \"open\",\n          \"operator\": \"EQ\"\n        }\n      ]\n    }\n  ],\n  \"properties\": [\"hs_invoice_status\", \"hs_due_date\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note that the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filters"
      }), " array specifies the search criteria, while the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " array specifies which properties to return."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While you cannot set associations using this API, you can retrieve association information by making a GET request to the following URL:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/invoice/{`hs_object_id}`/associations/{associatedObjectName}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Associated objects can include ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "contacts"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies",
        children: "companies"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals",
        children: "deals"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "line items"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/discounts",
        children: "discounts"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/fees",
          children: "fees"
        })
      }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: "taxes"
        })
      }), ". To create associations between an invoice and these objects, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/invoices/create-and-manage-invoices",
        children: "update the invoice in HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is an example of how you might use this API combined with another API to get a specific set of association information."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " line items belong to one single parent object. For example, if retrieving line items from an invoice, the line item ID’s will be different to those on a deal, or a quote."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieving an invoice with associated line items"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve an invoice and the line items associated with it, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/invoice/{`hs_object_id}`/associations/line_items"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This will return the IDs of the currently associated line items, along with meta information about the association type."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"results\": [\n    {\n      \"id\": \"1526712436\",\n      \"type\": \"invoice_to_line_item\"\n    },\n    {\n      \"id\": \"1526712437\",\n      \"type\": \"invoice_to_line_item\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then use the returned IDs to request more information about the line items through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "line items API"
      }), ". For example, you could batch retrieve line items by ID by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to the following URL with the request body below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/line_items/batch/read"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"inputs\": [{ \"id\": \"1526712436\" }, { \"id\": \"1526712437\" }],\n  \"properties\": [\"name\", \"amount\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response would be formatted as follows:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n \"status\":\"COMPLETE\",\n \"results\":[\n  {\n   \"id\":\"1359205183\",\n   \"properties\":{\n    \"amount\":\"123.00\",\n    \"createdate\":\"2023-04-26T14:52:35.885Z\"\n    \"hs_lastmodifieddate\":\"2023-04-26T14:52:35.885Z\",\n    \"hs_object_id\":\"1359205183\",\n    \"name\":\"itemname\"\n   },\n   \"createdAt\":\"2023-04-26T14:52:35.885Z\",\n   \"updatedAt\":\"2023-04-26T14:52:35.885Z\",\n   \"archived\":false\n  }\n ],\n\"startedAt\":\"2024-03-11T20:09:44.151Z\",\n\"completedAt\":\"2024-03-11T20:09:44.195Z\"\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}