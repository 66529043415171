"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51199955888;
const slug = exports.slug = 'getting-started/account-types';
const title = exports.title = 'HubSpot-Account-Typen';
const name = exports.name = 'HubSpot-Account-Typen';
const metaDescription = exports.metaDescription = 'Erfahren Sie mehr über die verschiedenen Typen von HubSpot-Accounts und wozu sie jeweils verwendet werden.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "standard-hubspot-accounts",
  "label": "Standard-HubSpot-Accounts",
  "parent": null
}, {
  "depth": 0,
  "id": "app-entwickler-accounts",
  "label": "App-Entwickler-Accounts",
  "parent": null
}, {
  "depth": 0,
  "id": "entwickler-test-accounts",
  "label": "Entwickler-Test-Accounts",
  "parent": null
}, {
  "depth": 1,
  "id": "einen-entwickler-test-account-erstellen",
  "label": "Einen Entwickler-Test-Account erstellen",
  "parent": "entwickler-test-accounts"
}, {
  "depth": 1,
  "id": "einen-entwickler-test-account-verl%C3%A4ngern",
  "label": "Einen Entwickler-Test-Account verlängern",
  "parent": "entwickler-test-accounts"
}, {
  "depth": 0,
  "id": "sandbox-accounts",
  "label": "Sandbox-Accounts",
  "parent": null
}, {
  "depth": 1,
  "id": "standard-sandbox-accounts",
  "label": "Standard-Sandbox-Accounts",
  "parent": "sandbox-accounts"
}, {
  "depth": 1,
  "id": "cms-sandbox-accounts",
  "label": "CMS-Sandbox-Accounts",
  "parent": "sandbox-accounts"
}, {
  "depth": 1,
  "id": "entwicklungs-sandbox-accounts-beta",
  "label": "Entwicklungs-Sandbox-Accounts (BETA)",
  "parent": "sandbox-accounts"
}, {
  "depth": 0,
  "id": "marketplace-anbieter-accounts",
  "label": "Marketplace-Anbieter-Accounts",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      em: "em",
      img: "img",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot-Account-Typen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt mehrere Typen von HubSpot-Accounts, die jeweils einen bestimmten Zweck haben. Nachfolgend erfahren Sie mehr über die einzelnen Account-Typen und für was sie gedacht sind."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Standard-HubSpot-Accounts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Standard-HubSpot-Account ist der gebräuchlichste Account-Typ. Dort finden Sie alle Tools, Funktionen und Einstellungen, die in Ihrer HubSpot-Version enthalten sind. Er kann kostenlos oder kostenpflichtig sein und wird als Ihre Produktionsumgebung verwendet."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Standard-HubSpot-Account hat Zugriff auf alle Tools und Funktionen, die in Ihrer Version enthalten sind."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App-Entwickler-Accounts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "App-Entwickler-Accounts sind kostenlose Accounts und für das Erstellen und Verwalten von Apps, Integrationen und Entwickler-Test-Accounts vorgesehen. Sie sind auch der Ort, an dem Sie App Marketplace-Listings erstellen und verwalten können. App-Entwickler-Accounts und die zugehörigen Test-Accounts sind jedoch nicht mit einem Standard-HubSpot-Account verknüpft. Sie können keine Daten oder Elemente mit oder von einem anderen HubSpot-Account synchronisieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["App-Entwickler-Accounts können durch ein Banner oben auf jeder Seite identifiziert werden, auf dem ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Dies"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "ist ein Account für App"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "-Entwickler"
      }), " steht.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://paper-attachments.dropbox.com/s_FE997E185BD47B083EA9B7C65FEC2822D18406AA9C2CBDB7FA3CA77217D16F19_1626814303990_App+dev+account+info+alert+option+3.png",
        alt: ""
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Legen Sie los, indem Sie einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "App-Entwickler-Account"
      }), " erstellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Entwickler-Test-Accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Innerhalb von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-developer-accounts",
        children: "App-Entwickler-Accounts"
      }), " können Sie Entwickler-Test-Accounts erstellen, um Apps und Integrationen zu testen, ohne dass sich dies auf echte HubSpot-Daten auswirkt. Entwickler-Test-Accounts spiegeln keine Produktionskonten wider. Sie sind vielmehr kostenlose HubSpot-Accounts mit Zugriff auf einen 90-tägigen Testlauf vieler Enterprise-Funktionen, mit den folgenden Beschränkungen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hub:"
        }), " Sie können Marketing-E-Mails nur an Adressen von Benutzern senden, die Sie Ihrem Entwickler-Test-Account hinzugefügt haben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub:"
        }), " Die Anzahl der Seiten, die Sie erstellen können, unterliegt den folgenden Beschränkungen:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Website-Seiten:"
            }), " 25"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Blog-Tools:"
            }), " 1 Blog mit bis zu 100 Blogbeiträgen"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Landingpages:"
            }), " 25"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workflows:"
        }), " Maximal 100.000 Datensätze können pro Tag in Workflows aufgenommen werden, die in einem Entwickler-Test-Account erstellt wurden. Wenn dieses Tageslimit erreicht wird:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Jeder weitere versuchte Aufnahme, der über dieses Limit hinausgeht, wird verworfen."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Die Benutzer werden in der App darüber informiert, dass sie das tägliche Aufnahmelimit für Datensätze erreicht haben und wann das Limit aktualisiert wird."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können bis zu 10 Test-Accounts pro Entwickler-Account erstellen. Dieser Typ von Account kann keine Daten mit anderen Accounts synchronisieren und sie können nicht mit einem Standard-HubSpot-Account verbunden werden. Test-Accounts können durch ein Banner oben auf der Seite identifiziert werden, das angibt, wie viele Tage noch verbleiben, bevor sie ablaufen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/trial-banner.png",
        alt: "trial-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über das Erstellen und Verwalten von Test-Accounts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einen Entwickler-Test-Account erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So erstellen Sie einen Entwickler-Test-Account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie in der linken Navigationsseitenleiste Ihres App-Entwickler-Accounts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Test-Accounts"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/left-sidebar-test-accounts.png",
            alt: "left-sidebar-test-accounts"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "App-Test-Account erstellen"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-test-account-create.png",
            alt: "developer-test-account-create"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Geben Sie einen ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Account-Namen"
          }), " ein und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Erstellen"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So greifen Sie auf Ihre Entwickler-Test-Accounts zu und verwalten sie:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in der oberen Navigationsleiste Ihres App-Entwickler-Accounts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bewegen Sie den Mauszeiger über den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Account"
        }), ", den Sie verwalten möchten, und wählen Sie dann eine Aktion aus:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Um den Account zu löschen, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Löschen"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Um die Account-ID zu kopieren, den Account zu verlängern oder ihn umzubenennen, klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Mehr"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-account-test-accounts.png",
        alt: "developer-account-test-accounts"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einen Entwickler-Test-Account verlängern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Entwickler-Test-Accounts laufen nach 90 Tagen ab, wenn keine API-Aufrufe an das Account erfolgen. Sie können den Account entweder manuell von der ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Testseite"
      }), " in HubSpot aus verlängern oder einen API-Aufruf an das Account vornehmen. Beachten Sie Folgendes, wenn Sie versuchen, den Account per API-Aufruf zu verlängern:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dies gilt nur für API-Anfragen, die mithilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "OAuth-Token"
        }), " gestellt werden, die von einer Anwendung im selben Entwickler-Account wie der zu verlängernde Test-Account generiert wurden."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verlängerungen müssen bis zu 30 Tage vor dem Ablaufdatum des Test-Accounts erfolgen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sandbox-Accounts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mithilfe von Sandbox-Accounts können Sie Änderungen testen, ohne dass dies Auswirkungen auf Ihren Standard-Account hat. Erfahren Sie in den folgenden Abschnitten mehr über die verschiedenen Arten von Sandbox-Accounts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standard-Sandbox-Accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ein ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "-Abonnement haben, können Sie einen Standard-Sandbox-Account erstellen, der eine sichere Umgebung bietet, in der Sie neue Workflows, Integrationen, Website-Seiten und andere wichtige Änderungen testen können, ohne dass dies Auswirkungen auf Ihren Standard-Account hat. Diese Sandboxes kopieren die Struktur Ihres Standard-Accounts."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Accounts können durch ein gelbes Banner oben auf der Seite identifiziert werden, das folgendes Label enthält: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Sie befinden sich in[[Name der Sandbox], einem Standard-Sandbox-Account."
      }), ". Das Banner enthält auch einen Link zurück zu Ihrem Produktions-Account für einen einfachen Wechsel."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/standard-sandbox-banner.png",
        alt: "standard-sandbox-banner"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/set-up-a-hubspot-standard-sandbox-account",
        children: "in der Wissensdatenbank von HubSpot"
      }), " mehr über Standard-Sandbox-Accounts."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CMS-Sandbox-Accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS-Sandboxes sind kostenlose Accounts und dienen dem Erstellen und Testen von Website-Änderungen, ohne dass dies Auswirkungen auf Ihren Standard-Account oder Ihre Live-Website hat. Ähnlich wie bei App-Entwickler-Accounts sind CMS-Sandbox-Accounts ", (0, _jsxRuntime.jsx)("u", {
        children: "nicht"
      }), " mit Ihrem Standard-HubSpot-Account verknüpft."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "kostenlos einen CMS-Sandbox-Account erstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS-Sandboxes haben kein Banner, sondern nur Zugriff auf die kostenlosen Tools von HubSpot und ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "CMS Hub"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " (abzüglich der Möglichkeit, eine Domain zu verknüpfen)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Entwicklungs-Sandbox-Accounts (BETA)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ein ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sales Hub"
        })
      }), " oder ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "-Abonnement haben, können Sie über das CLI für lokale Entwicklung einen Entwicklungs-Sandbox-Account erstellen. Sie erhalten Sie Zugriff auf Entwicklungs-Sandbox-Accounts, indem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/opt-your-hubspot-account-into-a-beta-feature",
        children: "Sie sich in Ihrem HubSpot-Standard-Account für die Betaversion der CRM-Entwickler-Tools entscheiden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Entwicklungs-Sandboxes können durch folgendes gelbes Banner oben auf der Seite identifiziert werden: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Sie befinden sich in [Name der Sandbox], einem Entwicklungs-Sandbox-Account."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/development-sandboxes-banner.png",
        alt: "development-sandboxes-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Marketplace-Anbieter-Accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Marketplace-Anbieter-Accounts sind für das Erstellen und Verwalten von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "Vorlagen-Marketplace-Listings"
      }), " und -Transaktionen vorgesehen. Um mit dem Verkauf im HubSpot-Marketplace für Vorlagen zu beginnen, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "erstellen Sie einen Anbieter-Account für den Vorlagen-Marketplace"
      }), ". Wenn Sie ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners",
        children: "HubSpot-Partner"
      }), " sind, verfügen Sie in Ihrem Partner-Account bereitsüber die Marketplace-Anbieter-Funktionen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Marketplace-Anbieter-Account kann durch ein ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketplace"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "für Vorlagen"
      }), "-Element im oberen Navigationsmenü identifiziert werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/template-marketplace-acct.png",
        alt: "template-marketplace-acct"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}