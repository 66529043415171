"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501377963;
const slug = exports.slug = 'guides/api/library/files';
const title = exports.title = 'API de CMS | Administrador de archivos';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Files';
const metaDescription = exports.metaDescription = 'Los puntos de terminación de los archivos se utilizan para obtener y administrar datos en tu administrador de archivos';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cargar-un-archivo",
  "label": "Cargar un archivo",
  "parent": null
}, {
  "depth": 0,
  "id": "comprobar-el-estado-de-subida-de-un-archivo",
  "label": "Comprobar el estado de subida de un archivo",
  "parent": null
}, {
  "depth": 0,
  "id": "ver-los-detalles-del-archivo",
  "label": "Ver los detalles del archivo",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-un-archivo",
  "label": "Eliminar un archivo",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-una-carpeta",
  "label": "Crear una carpeta",
  "parent": null
}, {
  "depth": 0,
  "id": "cambios-en-la-v3",
  "label": "Cambios en la v3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Administrador de archivos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usa la herramienta de archivos de HubSpot para administrar y almacenar archivos en HubSpot. Los archivos alojados en HubSpot se pueden cargar y usar tanto en HubSpot como en contenido externo. También pueden adjuntarse a los registros con la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API de interacciones"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tu empresa está creando su sitio web usando CMS Hub, puedes usar la API de archivos para subir y almacenar recursos en HubSpot. Estos archivos se pueden servir y compartir a través de CMS Hub."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes acceder a la herramienta archivos desde ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/files/upload-files-to-use-in-your-hubspot-content",
        children: "HubSpot"
      }), " o a través de la API de archivos. A continuación, aprende sobre los archivos API y cómo cargar y eliminar archivos. Para ver una lista completa de puntos e terminación de API de archivos, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " arriba."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cargar un archivo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los archivos se pueden cargar usando una solicitud multipart/form-data ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files"
      }), " con los siguientes campos. Si bien un ID de carpeta específico no es obligatorio en la carga, se recomienda cargar archivos en una carpeta y no en el directorio raíz. Los requisitos de carpeta en la carga están sujetos a cambios en el futuro."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El archivo para subir (requerido)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un objeto JSON que controla la privacidad e indexabilidad del archivo y contiene dos campos: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "access"
            }), ", que es obligatorio, y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), ", que especifica un período de tiempo después del cual el archivo se eliminará automáticamente.Si estás usando el campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), ":", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "El plazo mínimo que se debe fijar es de 1 día."
              }), (0, _jsxRuntime.jsx)("li", {
                children: "El plazo máximo que se puede fijar es de 1 año."
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Después del periodo establecido, el archivo se eliminará permanentemente. Después de la eliminación, el archivo no se puede recuperar ni restaurar."
              })]
            }), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID de la carpeta a la que se subirá el archivo. En tu solicitud, se debe proporcionar este campo ", (0, _jsxRuntime.jsx)("u", {
              children: "o"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            }), " (pero ", (0, _jsxRuntime.jsx)("u", {
              children: "no"
            }), " ambos)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La ruta de la carpeta en la que se subirá el archivo. En tu solicitud, se debe proporcionar este campo ", (0, _jsxRuntime.jsx)("u", {
              children: "o"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            }), " (pero ", (0, _jsxRuntime.jsx)("u", {
              children: "no"
            }), " ambos)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre del archivo. Si no se especifica ningún nombre, se generará un nombre a partir del contenido del archivo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charsetHunch"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Codificación de conjunto de caracteres para el archivo cargado. Si no se proporciona, se obtendrá del archivo."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, si quieres subir un archivo con los siguientes criterios a tu cuenta de HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nombre del archivo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cat.png"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Carpeta de destino en el administrador de archivos de HubSpot:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/library/cat_archive"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Accesibilidad de archivos en HubSpot:"
        }), " acceso privado"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los siguientes encabezados y el cuerpo de la solicitud deberían formar parte de tu solicitud:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubapi.com/files/v3/files?=' \\\n  --header 'Authorization: Bearer pat-na1-00000000-0000-0000-0000-000000000000' \\\n  --header 'Content-type: multipart/form-data' \\\n  --form file=@/Users/person/Downloads/cat.png \\\n  --form 'options={\"access\": \"PRIVATE\"}' \\\n  --form folderPath=/library/cat_archive\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La respuesta resultante incluirá el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "parentFolderId"
      }), " del archivo subido, que puedes utilizar para recuperar el archivo a través de una solicitud GET."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// 201 Response from successful file upload\n{\n  \"id\": \"122692044085\",\n  \"createdAt\": \"2023-06-28T17:56:45.393Z\",\n  \"updatedAt\": \"2023-06-28T17:56:45.393Z\",\n  \"archived\": false,\n  \"parentFolderId\": \"122692510820\",\n  \"name\": \"cat\",\n  \"path\": \"/library/cat_archive/cat.png\",\n  \"size\": 24574,\n  \"height\": 219,\n  \"width\": 225,\n  \"encoding\": \"png\",\n  \"type\": \"IMG\",\n  \"extension\": \"png\",\n  \"defaultHostingUrl\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"url\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"isUsableInContent\": true,\n  \"access\": \"PRIVATE\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comprobar el estado de subida de un archivo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vas a importar un archivo desde una URL a tu administrador de archivos mediante una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async"
      }), ", puedes revisar el estado de subida del archivo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para hacerlo, usa una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async/tasks/{taskId}/status"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de hacer esta solicitud, recibirás una de las siguientes respuestas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING"
        }), ": el archivo está en la cola para subir. El proceso de importación aún no ha comenzado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), ": el archivo está en proceso de subir."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELED"
        }), ": la subida se ha cancelado y el archivo no se subirá. Para importar el archivo a tu cuenta de HubSpot, tendrás que subirlo de nuevo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "COMPLETE"
        }), ": el archivo se ha subido correctamente en la herramienta de archivos. El archivo subido aparecerá en tu herramienta de archivos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ver los detalles del archivo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para revisar los detalles de un archivo que se ha subido en la herramienta de archivos, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), ". Esto devolverá el archivo con detalles como nombre, alto y ancho, codificación, URL y más."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, para recuperar los detalles de un archivo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si un archivo está establecido como privado, la URL devuelta ocasionará un error 404. Para obtener una URL visible del archivo, puedes hacer una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/files/v3/files/{fileId}/signed-url"
      }), ". Al realizar esta solicitud, puedes incluir parámetros de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propiedad"
      }), " para devolver propiedades específicas como alto y ancho."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar un archivo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar un archivo, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), ". Esto marcará el archivo como eliminado y hará que el contenido del archivo sea accesible."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar permanentemente un archivo, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}/gdpr-delete"
      }), ". Esto eliminará permanentemente el contenido y los metadatos del archivo en 7 días."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si no se elimina el RGPD, su contenido permanecerá en los servidores de HubSpot en un estado privado donde nadie puede acceder a él. Para garantizar que el contenido del archivo se elimine completamente, usa la funcionalidad de eliminación de RGPD."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una carpeta"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una carpeta, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/folders"
      }), ". Al realizar la solicitud, puedes incluir los siguientes campos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Requerido"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Yes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre de la carpeta que deseas crear."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para crear la carpeta dentro de una carpeta existente, incluye este campo con el ID de la carpeta existente. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), " no se pueden establecer al mismo tiempo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para crear la carpeta dentro de una carpeta existente, incluye este campo con la ruta de la carpeta existente. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), " no se pueden establecer al mismo tiempo."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body of POST request to /files/v3/folders\n{\n  \"name\": \"myNewFolder\",\n  \"parentFolderId\": 12345\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cambios en la v3"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si usas la versión anterior de esta API, v3 tiene los siguientes cambios:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Todos los archivos cargados a través de la API serán visibles en el panel de archivos y el selector de archivos. No se pueden crear archivos ocultos. Sin embargo, se pueden crear archivos privados y archivos no indexables."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Enumerar archivos no devolverá los archivos ocultos o eliminados. Sin embargo, se puede aplicar un rango mucho más amplio de filtros. Los archivos ocultos aún se pueden recuperar por ID, pero requieren un nuevo alcance: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "files_ui_hidden.read."
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "No se pueden cargar varios archivos con una sola solicitud."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Las acciones de actualización de carpeta como mover y cambiar el nombre son ahora asíncronas. Cada solicitud devolverá un token que se puede usar para comprobar el estado de la edición de la carpeta."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Los puntos finales que crean o reemplazan archivos requieren que proporciones niveles de acceso para los archivos. Estos niveles de acceso son:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_INDEXABLE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " el archivo es accesible públicamente por cualquiera que tenga la URL. Los motores de búsqueda pueden indexar el archivo."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_NOT_INDEXABLE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " el archivo es accesible públicamente por cualquiera que tenga la URL. Las X-Robots-Tag: el encabezado noindex se enviará cada vez que se recupere el archivo, indicando a los motores de búsqueda que no indexen el archivo."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "PRIVATE"
              })
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: ":"
            }), " el archivo no es accesible públicamente. Requiere una URL firmada para mostrar el contenido. Los motores de búsqueda no pueden indexar el archivo."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Los puntos finales que crean archivos permiten un nivel de detecciones duplicadas como parte de las opciones de carga del archivo."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "ENTIRE_PORTAL"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " busca un archivo duplicado en la cuenta."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "EXACT_FOLDER"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " busca un archivo duplicado en la carpeta proporcionada."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "NONE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " no ejecutes ninguna validación duplicada."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "REJECT"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " rechaza la carga si se encuentra un duplicado."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "RETURN_EXISTING"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " si se encuentra un archivo duplicado, no cargues un nuevo archivo y, en su lugar, devuelve el duplicado encontrado."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["La detección duplicada funciona en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationScope"
            }), ", que afecta cómo buscamos un duplicado."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Esto también requiere una ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationStrategy"
            }), ", lo que determina qué sucede si se encuentra un duplicado."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}