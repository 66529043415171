"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279889;
const slug = exports.slug = 'guides/cms/content/accessibility';
const title = exports.title = 'Accessibilité';
const name = exports.name = 'EMEA (FR) Accessibility';
const metaDescription = exports.metaDescription = "HubSpot s'engage à fournir les outils nécessaires pour vous aider à rendre votre contenu accessible. Bien qu'il ne s'agisse pas d'un guide définitif, voici quelques étapes que vous pouvez suivre pour rendre votre contenu accessible.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "l-accessibilit%C3%A9-n-est-pas-une-fonctionnalit%C3%A9",
  "label": "L'accessibilité n'est pas une fonctionnalité",
  "parent": null
}, {
  "depth": 0,
  "id": "fournir-du-texte-alternatif-pour-tout-contenu-non-textuel",
  "label": "Fournir du texte alternatif pour tout contenu non textuel",
  "parent": null
}, {
  "depth": 1,
  "id": "utiliser-des-solutions-de-chargement-diff%C3%A9r%C3%A9-conviviales-pour-l-accessibilit%C3%A9",
  "label": "Utiliser des solutions de chargement différé conviviales pour l'accessibilité",
  "parent": "fournir-du-texte-alternatif-pour-tout-contenu-non-textuel"
}, {
  "depth": 0,
  "id": "v%C3%A9rifier-que-tous-les-liens-et-les-entr%C3%A9es-de-formulaire-disposent-d-un-texte-descriptif",
  "label": "Vérifier que tous les liens et les entrées de formulaire disposent d'un texte descriptif",
  "parent": null
}, {
  "depth": 0,
  "id": "ajout-d-un-lien-pour-ignorer-le-contenu",
  "label": "Ajout d'un lien pour ignorer le contenu",
  "parent": null
}, {
  "depth": 0,
  "id": "utiliser-un-balisage-s%C3%A9mantique",
  "label": "Utiliser un balisage sémantique",
  "parent": null
}, {
  "depth": 0,
  "id": "navigation-via-le-clavier",
  "label": "Navigation via le clavier",
  "parent": null
}, {
  "depth": 0,
  "id": "solution-de-secours-pour-les-r%C3%B4les%2C-le-cas-%C3%A9ch%C3%A9ant",
  "label": "Solution de secours pour les rôles, le cas échéant",
  "parent": null
}, {
  "depth": 0,
  "id": "autres-conseils-de-conception",
  "label": "Autres conseils de conception",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9daction-de-contenu",
  "label": "Rédaction de contenu",
  "parent": null
}, {
  "depth": 0,
  "id": "recommandations-sp%C3%A9cifiques-%C3%A0-hubspot",
  "label": "Recommandations spécifiques à HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "utiliser-des-modules-personnalis%C3%A9s-et-des-mod%C3%A8les-qui-r%C3%A9pondent-aux-bonnes-pratiques-d-accessibilit%C3%A9",
  "label": "Utiliser des modules personnalisés et des modèles qui répondent aux bonnes pratiques d'accessibilité",
  "parent": "recommandations-sp%C3%A9cifiques-%C3%A0-hubspot"
}, {
  "depth": 0,
  "id": "se-m%C3%A9fier-des-solutions-globales",
  "label": "Se méfier des solutions globales",
  "parent": null
}, {
  "depth": 0,
  "id": "podcast-des-d%C3%A9veloppeurs-hubspot",
  "label": "Podcast des développeurs HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "plus-d-informations-sur-l-accessibilit%C3%A9",
  "label": "Plus d'informations sur l'accessibilité",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      code: "code",
      pre: "pre",
      h3: "h3",
      ul: "ul",
      li: "li",
      ol: "ol",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      IFrame
    } = _components;
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Accessibilité"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les sites web sont plus que jamais essentiels pour les entreprises. Par conséquent, il est important de veiller à ce que leur contenu soit accessible à tous les visiteurs. Aux États-Unis, la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www.section508.gov/",
        children: "conformité à la section 508"
      }), " renvoie à l'article de la loi Reauthorized Rehabilitation Act de 1998, qui oblige les organismes fédéraux à rendre les technologies électroniques et informatiques accessibles aux personnes handicapées. Bien que la conformité à l'article 508 constitue un bon point de départ, les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility/Understanding_WCAG",
        children: "directives sur l'accessibilité web (WCAG)"
      }), " sont de plus en plus utilisées comme une référence pour la création de contenu accessible sur le web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "L'accessibilité n'est pas une fonctionnalité"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'aspect juridique ne devrait pas être votre motivation première pour offrir une bonne expérience à vos utilisateurs. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.cdc.gov/ncbddd/disabilityandhealth/infographic-disability-impacts-all.html",
        children: "Selon le CDC, un Américain sur quatre souffre d'un handicap"
      }), ". Ne pas offrir une bonne expérience à 25 % des visiteurs serait comme un magasin physique qui interdirait l'accès à un client sur quatre. Non seulement ces derniers seront mécontents, mais ils ne reviendront probablement pas ou ne recommanderont pas vos services."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une expression courante chez les développeurs est que « l'accessibilité n'est pas une fonctionnalité ». L'idée est de ne pas traiter l'accessibilité comme un bonus ou à la fin d'un projet."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'accessibilité exige de la réflexion, de l'empathie et de la compréhension. Dès le début d'un projet, vous pouvez concevoir et développer des solutions au lieu d'avoir à réorganiser vos activités plus tard."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Souvent, la résolution des problèmes d'accessibilité répond à vos autres priorités en tant que développeur"
      }), ". L'expérience utilisateur, les performances et le référencement sont quelques-unes des principales questions qui sont directement liées à l'accessibilité. L'amélioration de l'un ce ces aspects se répercute généralement positivement sur l'autre."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Trop souvent négligée, l'empathie constitue un élément clé de l'accessibilité. En tant que développeurs, nous voulons tout automatiser. Si une tâche est difficile ou chronophage, nous voulons des outils qui l'effectuent à notre place ou qui nous expliquent comment faire. Malheureusement, ces outils sont insuffisants, car ils ne sont pas humains et ne peuvent pas aborder l'expérience de la page comme nous. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Il est techniquement possible de créer une page web qui passe avec brio la plupart des tests d'accessibilité automatisée, mais qui est complètement inutilisable pour tous les humains, qu'ils soient voyants, aveugles, sourds, daltoniens, à fonction motrice limitée ou illimitée."
      }), " Vous pouvez techniquement répondre aux exigences, mais votre expérience sera frustrante pour les utilisateurs. Le point à retenir est le suivant : ne vous fiez pas aux outils, testez votre travail, faites preuve d'empathie avec vos utilisateurs et recueillez des commentaires."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bien qu'il ne s'agisse pas d'un guide complet, voici quelques étapes que vous pouvez suivre pour rendre votre contenu accessible ainsi que des ressources pour approfondir la question."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fournir du texte alternatif pour tout contenu non textuel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les images, icônes, vidéos et boutons qui véhiculent un sens ou qui sont interactifs doivent disposer d'un texte alternatif. Celui-ci sera non seulement utile pour les visiteurs qui consultent votre contenu via un lecteur d'écran, mais également pour les moteurs de recherche. Le texte alternatif doit décrire le contenu de l'image. La plupart des images de votre site peuvent probablement être modifiées dans l'éditeur de page. La ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cos-pages-editor/how-do-i-add-alt-text-to-my-image",
        children: "définition d'un texte alternatif dans l'éditeur de page est facile à effectuer"
      }), ". À l'intérieur des modules et des modèles personnalisés, vous devez cependant vous assurer que le texte alternatif spécifié dans les éditeurs de page est respecté."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Autrement dit, vous devez vous assurer que le texte alternatif fourni par l'utilisateur est effectivement utilisé si vous disposez d'un élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<img>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" />\n\nBad:\n<img src=\"{{ module.image.src }}\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si, pour une raison quelconque, vous ne rendez pas une image modifiable dans l'éditeur de page, vous devez coder le texte alternatif en dur."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il existe une exception à cette règle de texte alternatif. Si votre image ne fournit pas de contexte supplémentaire et sert uniquement à des fins de présentation, il est préférable d'avoir une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.w3.org/WAI/tutorials/images/tips/",
        children: "valeur de texte alternatif nulle"
      }), " plutôt que de ne pas définir d'attribut alternatif."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good if only presentational: <img src=\"{{ module.image.src }}\" alt=\"\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les vidéos, utilisez un service qui prend en charge les sous-titres et envisagez d'inclure une transcription. Certains services qui prennent en charge le téléchargement de transcriptions incluent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.vidyard.com/hc/en-us/articles/360009876234-Make-your-Vidyard-players-accessible",
        children: "Vidyard"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://support.google.com/youtube/answer/2734799",
        children: "YouTube"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://help.vimeo.com/hc/en-us/articles/224968828-Captions-and-subtitles",
        children: "Vimeo"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://wistia.com/support/player/captions",
        children: "Wistia"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utiliser des solutions de chargement différé conviviales pour l'accessibilité"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le chargement d'image différé est une technique courante d'amélioration des performances pour la conception de sites web. La méthode utilisée pour le chargement différé influe sur l'accessibilité."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les solutions JavaScript reposent généralement sur l'attribut ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " des images présente un élément faux (comme un petit fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".gif"
      }), ") au lieu de la source réelle de l'image, qui est conservée dans un attribut ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-src"
      }), " jusqu'à ce que l'utilisateur arrive à l'image. Cela signifie qu'au départ, l'image est inaccessible pour un utilisateur de lecteur d'écran qui navigue sur la page, en particulier un utilisateur utilisant un rotor pour parcourir tout le contenu de la page sans même le faire défiler. En outre, si JavaScript n'est pas chargé sur une page, ces solutions de chargement différé échoueront et ne proposeront pas les images appropriées aux utilisateurs de technologies d'assistance."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'utilisation du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/native-lazy-loading/",
        children: "chargement différé natif de l'image"
      }), " permet d'éviter ce problème, conservant ainsi le balisage de l'image, que JavaScript soit chargé ou non."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img\n  src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n  loading=\"lazy\"\n  height=\"200px\"\n  width=\"400px\"\n/>\n\nBad:\n<img\n  src=\"1px.gif\"\n  data-src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n/>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot prend en charge le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/lazy-loading",
        children: "chargement différé basé sur navigateur"
      }), " dans les modules personnalisés. Il est recommandé de l'utiliser."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vérifier que tous les liens et les entrées de formulaire disposent d'un texte descriptif"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les liens, les boutons et les éléments de formulaire doivent tous avoir un texte réel qui indique le rôle ou la destination de l'élément. Autrement, les lecteurs d'écran liront que l'utilisateur a un lien ou un bouton sélectionné, mais n'auront aucune idée de sa fonction."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous utilisez une icône, fournissez un texte pour le lecteur d'écran. Par exemple, certains modèles HubSpot standard utilisent un code court d'icône :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"https://www.facebook.com\"\n  ><i class=\"fab fa-facebook-f\"></i\n  ><span class=\"screen-reader-text\">Facebook</span></a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["N'utilisez pas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'display: none'"
      }), " pour masquer les libellés de formulaire et d'autres textes descriptifs. Cela empêche la lecture du texte à voix haute pour les personnes malvoyantes et heurte les utilisateurs voyants mais qui ont du mal à voir le texte en raison d'un manque de contraste sur le texte de la variable. Sans oublier ceux qui n'ont pas commencé à remplir un formulaire ou dont le formulaire a été automatiquement renseigné pour eux, sans aucune idée de ce qui a été saisi car les libellés des champs étaient invisibles."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous devez absolument avoir du texte caché ou si du texte caché peut fournir un contexte supplémentaire dont un utilisateur voyant n'aurait pas besoin. Utilisez la CSS qui rend le texte invisible sans le cacher pour les lecteurs d'écran ou ajoutez la classe ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'.screen-reader-text'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".screen-reader-text {\n  clip: rect(1px, 1px, 1px, 1px);\n  height: 1px;\n  overflow: hidden;\n  position: absolute !important;\n  width: 1px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ajout d'un lien pour ignorer le contenu"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsqu'un visiteur navigue à l'aide d'un lecteur d'écran ou simplement via son clavier, il est très utile qu'il puisse ignorer les parties de la page qui sont répétées telles qu'un en-tête. Pour ce faire, nous pouvons ajouter un lien qui pointe vers le contenu principal de la page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans votre en-tête, ajoutez le code HTML avec le contenu suivant :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"#content\" class=\"screen-reader-text\"\n  >Five ways to make your site more accessible</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ensuite, dans chaque modèle, assurez-vous qu'il y a un élément avec un attribut d'ID de contenu. Dans notre exemple, nous avons utilisé le titre de l'article comme texte à ignorer. Cela permet aux moteurs de recherche de comprendre ce à quoi il est lié. C'est une façon plus sémantique d'ignorer du contenu."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<main id=\"content\">\n  <!-- your page or post's actual content -->\n</main>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utiliser un balisage sémantique"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le balisage sémantique est un code HTML qui transmet le sens. Voici quelques exemples d'éléments sémantiques : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<time>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<code>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<aside>"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<article>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Voici quelques exemples d'éléments qui ne sont pas sémantiques : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span>"
      }), ". Les éléments non sémantiques ont toujours leur place, mais doivent être utilisés principalement pour la présentation et non pour transmettre du sens."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les éléments sémantiques peuvent être compris par les moteurs de recherche et les technologies d'assistance, ce qui a une incidence positive sur le référencement et améliore votre expérience utilisateur."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["N'utilisez pas d'éléments ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " interactifs comme des boutons, des onglets ou des liens à moins d'être sûr d'avoir fourni une bonne expérience via les attributs aria."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez les liens (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<a />"
      }), ") et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<button />"
      }), " de manière appropriée."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisez des liens pour créer un lien vers les sections d'une page et accéder à d'autres pages."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisez les boutons pour les interactions qui n'ont pas pour résultat de quitter la page ou d'accéder à un ID."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous travaillez avec des tableaux, si un tableau a un titre descriptif, incluez-le dans un élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<caption>"
      }), " juste après l'élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<table>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez des éléments ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), " avec l'attribut de domaine approprié dans les tableaux pour les en-têtes de colonne et de ligne et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<thead>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tbody>"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tfoot>"
      }), " pour désigner les sections."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez l'attribut de domaine sur les attributs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), " pour indiquer si l'en-tête s'applique à la ligne ou à la colonne."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Besoin d'un accordéon ? N'oubliez pas les éléments ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<summary>"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<details>"
      }), ", qui proposent une grande partie de cette fonctionnalité gratuitement et qui sont accessibles. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://caniuse.com/#feat=details",
        children: "Assurez-vous d'ajouter un polyfill ou une solution de secours si vous avez besoin de prendre en charge d'anciens navigateurs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Navigation via le clavier"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Certains utilisateurs préfèrent leur clavier pour naviguer dans les pages web et les formulaires. Certains visiteurs doivent utiliser le clavier ou une sorte d'appareil d'assistance qui reproduit un clavier pour naviguer sur les sites web."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Assurez-vous que ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_focus.asp",
          children: "':focus'"
        }), " est stylisé de sorte que lorsqu'un utilisateur navigue via le clavier, il peut voir ce qui est mis en évidence. Désactivez la propriété de contours CSS uniquement si vous fournissez un indicateur visuel alternatif acceptable. Utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/almanac/selectors/f/focus-within/",
          children: ":focus-within"
        }), " si cela peut permettre d'attirer efficacement l'attention des utilisateurs."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lorsque vous masquez/affichez des parties de la page avec ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_hover.asp",
          children: "':hover'"
        }), " ou via JavaScript, assurez-vous que les utilisateurs sont également capables de naviguer dans ces contrôles via JavaScript ou qu'il existe un chemin alternatif vers les informations."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Assurez-vous que la navigation principale de votre site prend en charge la navigation par clavier. Un problème fréquemment négligé est l'inaccessibilité des listes déroulantes et des menus volants. Cela empêche les utilisateurs d'accéder à des parties essentielles des sites web."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fournissez et mettez à jour les attributs d'état ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hidden"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-expanded"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-current"
        }), " ou encore ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-selected"
        }), " si nécessaire pour vous assurer que les lecteurs d'écran communiquent correctement l'état des éléments."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Solution de secours pour les rôles, le cas échéant"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la création de modèles ou de fichiers codés, vous devez utiliser des éléments sémantiques corrects (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), ",", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dialog>"
      }), ", etc.) pour communiquer aux navigateurs web et aux lecteurs d'écran le type de contenu qui se trouve à l'intérieur de vos éléments."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le cas où les éléments sémantiques ne sont pas appropriés pour votre situation, vous devez ajouter des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/fr-FR/docs/Web/Accessibility/ARIA/Roles",
        children: "attributs de rôle"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'ajout de repères permet aux utilisateurs qui utilisent des lecteurs d'écran de passer plus facilement d'une section principale d'une page web à l'autre. HubSpot inclut les attributs 'role' pour le menu, le menu simple et des modules de recherche Google."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autres conseils de conception"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Assurez-vous que lorsqu'un utilisateur zoome sur son navigateur à 200 %, le contenu reste visible et lisible."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Il est déconseillé d'utiliser des tailles de police inférieures à 16 px car la lisibilité peut devenir mauvaise pour les visiteurs."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Évitez d'utiliser la couleur comme seul moyen de communiquer des informations. Un étonnante part de la population mondiale est daltonienne."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Assurez-vous qu'il y a suffisamment de contraste entre la couleur du texte et l'arrière-plan afin que les utilisateurs ayant une vision limitée puissent lire le contenu."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Évitez les animations qui clignotent rapidement (plus de trois fois par seconde) car cela pourrait déclencher des crises d'épilepsie chez certains visiteurs."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Envisagez de prendre en charge ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/introduction-reduced-motion-media-query/",
          children: "prefers-reduc-motion"
        }), " si vous avez des animations sur votre site."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Aujourd'hui, la création d'un site web adaptatif est indispensable. Assurez-vous que tous les éléments interactifs fonctionnent comme prévu sur mobile. Les éléments interactifs doivent être facilement déclenchés et un espace suffisant est indispensable entre les différentes zones de pression."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rédaction de contenu"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "N'oubliez pas que l'accessibilité doit également être prise en compte dans votre contenu textuel."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Évitez le langage directionnel « voir barre latérale de gauche ». Si l'utilisateur est sur mobile, il est possible que cela n'ait aucun sens pour lui car le contenu s'empile généralement sur cette version."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilisez des rubriques comme ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h1>"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h2>"
        }), ", etc. et imbriquez-les l'une après l'autre. Ne sautez pas de rubrique pour l'effet visuel."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Lors de l'ajout d'un lien, n'utilisez jamais le texte « Cliquez ici ». Il ne transmet aucune signification aux lecteurs d'écran et n'a aucun sens sur les écrans tactiles ou d'autres types d'appareils. Au lieu de cela, votre texte doit mentionner ce qui figure dans ce lien. Cette pratique est également plus judicieuse pour le référencement, car les moteurs de recherche comprennent mieux le contenu lié."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Assurez-vous que si vous utilisez des styles spéciaux sur le texte pour transmettre le sens, vous utilisez la balise sémantique ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<mark>"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<strong>"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<em>"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<sup>"
        }), ", etc. appropriée. Autrement, le sens risque d'être perdu pour certains visiteurs."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous ciblez des visiteurs dans le monde entier, évitez des blagues spécifiques à une région. Si vous faites traduire votre site, évitez les jeux de mots. Bien que nous aimions l'humour, les traductions ne sont pas toujours optimales."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utilisez des outils pour améliorer votre grammaire et votre orthographe. Cela favorise la compréhension et permet d'obtenir de meilleures traductions."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Rédigez votre contenu en fonction du public de votre site. Vous n'expliqueriez pas l'astrophysique à des élèves de CM2 comme vous le feriez à un physicien aguerri. Cela dit, évitez les mots fantaisistes juste pour le plaisir de les utiliser et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/plain-language-experts/",
          children: "restez simple"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recommandations spécifiques à HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La plupart du travail visant à améliorer l'accessibilité n'est pas spécifique à HubSpot. Cela dit, nous avons à cœur de vous accompagner dans votre réussite avec HubSpot. En tant que développeur ou créateur de contenu, vous devez prendre note de certains aspects afin de proposer une meilleure expérience utilisateur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utiliser des modules personnalisés et des modèles qui répondent aux bonnes pratiques d'accessibilité"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Que vous installiez un module ou un modèle depuis le marketplace, que vous le développiez vous-même ou par le biais d'un tiers, assurez-vous qu'il répond aux bonnes pratiques en matière d'accessibilité. Les modules personnalisés peuvent avoir un énorme impact sur l'accessibilité de votre site car ils sont souvent utilisés plusieurs fois, parfois sur la même page. De même, un modèle unique peut être utilisé des centaines ou des milliers de fois sur un même site. Si votre site n'est pas accessible, vous diminuerez probablement vos profits. Comme avec les publicités, il est logique d'investir davantage pour vous assurer que votre site web touche un public plus large. L'un des avantages des modules est que vous pouvez généralement améliorer le code plus tard à mesure que les directives et les bonnes pratiques évoluent, favorisant ainsi l'accessibilité pour chaque page sur laquelle le module est en ligne."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sur le marketplace HubSpot, recherchez des modules et des modèles qui abordent l'accessibilité et répondent aux exigences WCAG dans leurs descriptions. Si vous travaillez avec un développeur, informez-le dès le début que l'accessibilité est importante pour vous. Les développeurs n'en font pas tous une priorité dans le cadre de leur travail. L'en informer tard dans un projet vous coûtera probablement plus cher que de le faire dès le départ. En effet, au lieu de faire de cette question une priorité dès le début, il devra résoudre rétroactivement les problèmes d'accessibilité."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous êtes un développeur, créez des modules et des modèles qui permettent aux créateurs de contenu de respecter les directives en matière d'accessibilité. Utilisez des titres appropriés, du code HTML sémantique et faites en sorte que les éléments interactifs fournissent des rôles et un contexte appropriés. Les créateurs de contenu conscients des problèmes d'accessibilité sont généralement contents de payer un peu plus pour des modules et des modèles inclusifs. Cela dit, vous devez montrer que vos modules tiennent compte de l'accessibilité. Incluez des liens dans les modèles, favorisez l'accessibilité des groupes et des partiels globaux utilisés dans vos modèles. Ceux-ci sont utilisés sur l'ensemble d'un site web et peuvent avoir un énorme impact sur la convivialité de celui-ci."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous développez pour le marketplace HubSpot, sachez que votre contenu pourrait être utilisé à travers des milliers, voire des millions de pages sur le web. Vous faites un choix qui affecte la publication de votre contenu et le partage de votre travail, que celui-ci soit inclusif ou non."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Se méfier des solutions globales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Plusieurs dizaines de fournisseurs proposent des outils avec lesquels ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "vous n'avez qu'à ajouter leur script à votre site web pour qu'il devienne accessible"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Certains de ces outils essaieront de donner un sens à certaines parties de la page et de modifier le HTML ainsi que les attributs pour tenter de résoudre certains problèmes. Reposant sur des hypothèses, ces dispositifs peuvent se tromper et nuire aux fonctionnalités de votre site, ou même aggraver les choses pour les utilisateurs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Proposant parfois leur propre système de lecture d'écran, ces outils peuvent ne pas toujours fonctionner comme prévu. Si vous disposez d'une interface utilisateur complexe, votre site web peut finalement devenir plus difficile à utiliser avec l'outil."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En raison de ces problèmes, de tels outils ne remplacent pas le développement de votre site selon des principes d'accessibilité. Investissez du temps et de l'argent pour offrir la meilleure expérience possible à tous vos utilisateurs. Le recours à des solutions globales pourrait vous coûter autant ou plus que de prendre le temps de développer votre site de façon appropriée. En testant et en développant votre site dans le respect des principes d'accessibilité, vous bénéficierez également de l'empathie et de la compréhension de vos clients."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour être clair, cela ne fait pas référence aux outils de test. Il s'agit en particulier des outils qui prétendent résoudre les problèmes d'accessibilité à votre place. Les outils de test eux-mêmes sont excellents et sont vivement recommandés en plus des tests manuels."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://overlayfactsheet.com/",
        children: "Découvrez-en davantage sur les scripts/superpositions d'accessibilité."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Podcast des développeurs HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En janvier 2021, des salariés de HubSpot et Jon Sasala de Morey Creative ont discuté de l'accessibilité."
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/xOVTQN8phIs?feature=oembed",
      maxHeight: "113px",
      maxWidth: "200px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Plus d'informations sur l'accessibilité"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe de très bonnes ressources pour créer des sites web accessibles à tous. Nous vous encourageons vivement à les consulter."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://a11yproject.com/",
          children: "Le projet A11Y"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/fr-FR/docs/Web/Accessibility",
          children: "Documents MDN sur l'accessibilité"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/WAI/",
          children: "Initiative sur l'accessibilité web"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/",
          children: "WebAIM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/wcag/checklist",
          children: "Check-list simplifiée WCAG 2.1"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/508/checklist",
          children: "Check-list relative à l'article 508"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/TR/WCAG21/",
          children: "Recommandations détaillées WCAG 2.1"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.deque.com/axe/",
          children: "AXE de Deque"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://wave.webaim.org/",
          children: "WAVE"
        }), " – Un outil pour tester l'accessibilité d'une page web"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/website/web-accessibility",
          children: "Le guide ultime de l'accessibilité web"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}