"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74627515061;
const slug = exports.slug = 'guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app';
const title = exports.title = 'Migrate an API key integration to a private app';
const name = exports.name = 'Migrate an API key integration to a private app';
const metaDescription = exports.metaDescription = 'Learn how to migrate an API key integration to a private app.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "in-this-guide",
  "label": "In this guide",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-new-private-app",
  "label": "Create a new private app",
  "parent": null
}, {
  "depth": 0,
  "id": "update-the-authorization-method-of-your-integration-s-api-requests",
  "label": "Update the authorization method of your integration's API requests",
  "parent": null
}, {
  "depth": 0,
  "id": "verify-requests-and-monitor-logs",
  "label": "Verify requests and monitor logs",
  "parent": null
}, {
  "depth": 0,
  "id": "implementation-examples",
  "label": "Implementation examples",
  "parent": null
}, {
  "depth": 1,
  "id": "serverless-functions",
  "label": "Serverless functions",
  "parent": "implementation-examples"
}, {
  "depth": 1,
  "id": "one-time-jobs",
  "label": "One-time jobs",
  "parent": "implementation-examples"
}, {
  "depth": 1,
  "id": "create-custom-objects",
  "label": "Create custom objects",
  "parent": "implementation-examples"
}, {
  "depth": 1,
  "id": "custom-code-workflow-actions",
  "label": "Custom code workflow actions",
  "parent": "implementation-examples"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      em: "em",
      h2: "h2",
      strong: "strong",
      img: "img",
      code: "code",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Migrate an API key integration to a private app"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "If you're seeing a banner in your account about deactivating your API key:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Ensure that you've migrated all impacted integrations, then ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
              children: "deactivate the API key"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["To check if the account's API key has been used in the past seven days, you can ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/integrations/how-do-i-get-my-hubspot-api-key#view-your-api-key-call-log",
              children: "view your API key call log history"
            }), ". The call log will not show any requests made with the key more than seven days ago."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Apps listed on the ", (0, _jsxRuntime.jsxs)(_components.a, {
              href: "https://knowledge.hubspot.com/integrations/connect-apps-to-hubspot#:~:text=You%20can%20view%20all%20your,Connected%20Apps%20page.",
              children: [(0, _jsxRuntime.jsx)(_components.em, {
                children: "Connected apps"
              }), " page of your account settings"]
            }), " do not need to be migrated, as they authenticate with OAuth."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Developer API keys are separate from standard HubSpot API keys, and are ", (0, _jsxRuntime.jsx)("u", {
              children: "not"
            }), " being deprecated. Developer API keys are used for managing settings related to your HubSpot apps, including ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks",
              children: "webhooks API"
            }), " subscriptions and ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/timeline",
              children: "timeline events API"
            }), " event types."]
          }), "\n"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you've built an internal integration that uses a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/how-do-i-get-my-hubspot-api-key",
        children: "HubSpot API key"
      }), ", your API key provides both read and write access to all of your HubSpot CRM data, which can be a security risk if your API key is compromised. By migrating to a private app, you can authorize the specific scopes that your integration requires, which generates an access token that limits the data that your integration can request or change in your account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Follow the steps below to migrate an existing API key integration to a private app. It's recommended you first use a test environment, such as a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "developer test account"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/set-up-a-hubspot-sandbox-account",
        children: "sandbox account"
      }), ", before making changes in production. If you have questions while migrating your app, visit the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "Developer Community"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For a video walkthrough of migrating an API key integration to a private app, check out the HubSpot Developers video below:"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/F3Qd9Hp4giM?start=29&feature=oembed",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In this guide"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-new-private-app",
          children: "Create a new private app"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#update-the-authorization-method-of-your-integration-s-api-requests",
          children: "Update the authorization method of your integration's API request"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#verify-requests-and-monitor-logs",
          children: "Verify requests and monitor logs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#implementation-examples",
          children: "Implementation examples"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " private apps do not support webhooks and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/extensions/overview",
          children: "certain types of extensions"
        }), ". If your existing integration uses any of these features, you should create a public app using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth"
        }), " instead."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a new private app"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "settings icon"
          }), " in the main navigation bar."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Integrations"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Private Apps"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create private app"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Basic Info"
          }), " tab, configure the details of your app:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Enter your app's ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "name"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Hover over the placeholder logo and click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "upload icon"
            }), " to upload a square image that will serve as the logo for your app."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "description"
            }), " for your app."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Scopes"
          }), " tab."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Next, select the scopes to authorize based on the APIs that your integration uses. To find out which scopes your app will need:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Compile a list of HubSpot APIs that your existing integration uses."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For each API request, navigate to the associated developer documentation (e.g., the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "contacts API"
            }), ")."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "reference documentation link"
            }), " at the top of the article, then scroll to the endpoint your integration is using."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Under the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Requirements"
            }), " section, locate the scopes required to use the endpoint. Whenever possible, you should opt for scopes listed under ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Granular scopes"
            }), " instead of the ones under ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Standard scopes"
            }), ". If no granular scopes are listed, use the standard scopes."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/locate-scope-in-endpoints-tab-for-private-app-migration.png",
            alt: "locate-scope-in-endpoints-tab-for-private-app-migration"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Back in the settings for your private app, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Read"
            }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Write"
            }), " checkboxes next to the matching scopes. You can also search for a scope using the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Find a scope"
            }), " search bar."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-matching-scope-for-private-app.png",
            alt: "select-matching-scope-for-private-app"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["After you're done selecting your scopes, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create app"
          }), " in the top right. You can always make changes to your app after you create it."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, review the info about your app's access token, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Continue creating"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your private app created, you can start making API requests using its access token. On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Details"
      }), " tab of the settings page of your private app, click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Show token"
      }), " under your access token to reveal it."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-migration-guide.png",
        alt: "show-private-app-access-token-migration-guide"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update the authorization method of your integration's API requests"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Instead of using a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hapiKey"
      }), " query parameter to make API requests, private app access tokens are included in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " header of your request. When making a request, set the value of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " header to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Bearer YOUR_ACCESS_TOKEN"
      }), ". Unless otherwise noted, this method of authorization is compatible with all public API endpoints, including the legacy APIs listed on HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "legacy developer documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your request may resemble the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$headers = [\n    'Content-Type: application/json',\n    'Authorization: Bearer ' . YOUR_ACCESS_TOKEN,\n];\n\n$curl = curl_init();\n\ncurl_setopt($curl, CURLOPT_HTTPHEADER, $headers);\ncurl_setopt($curl, CURLOPT_URL, 'https://api.hubapi.com/crm/v3/objects/contacts');\ncurl_setopt($curl, CURLOPT_RETURNTRANSFER, true);\n\n$contacts = curl_exec($curl);\ncurl_close($curl);\n\nvar_dump($contacts);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "require 'uri'\nrequire 'net/http'\nrequire 'openssl'\n\nurl = URI(\"https://api.hubapi.com/crm/v3/objects/contacts\")\n\nhttp = Net::HTTP.new(url.host, url.port)\nhttp.use_ssl = true\nhttp.verify_mode = OpenSSL::SSL::VERIFY_NONE\n\nrequest = Net::HTTP::Get.new(url)\nrequest['content-type'] = 'application/json'\ntoken = 'YOUR_ACCESS_TOKEN'\nrequest['authorization'] = \"Bearer #{token}\"\n\nresponse = http.request(request)\nputs response.read_body\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "import requests\n\nurl = \"https://api.hubapi.com/crm/v3/objects/contacts\"\n\nheaders = {\n  'content-type': 'application/json',\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\nresponse = requests.request(\"GET\", url, headers=headers)\n\nprint(response.text)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Private app access tokens are implemented on top of OAuth, so you can also make authenticated calls with your access token using one of HubSpot's client libraries. For example, if you're using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "Node.js client library"
      }), ", you can instantiate an OAuth client by passing in your app's access token."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$hubSpot = \\HubSpot\\Factory::createWithAccessToken('access-token');\n$response = $hubSpot->crm()->contacts()->basicApi()->getPage();\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# Load the gem\nrequire 'hubspot-api-client'\n\n# Setup client\nclient = Hubspot::Client.new(access_token: 'YOUR_ACCESS_TOKEN')\n\n# Get contacts\ncontacts = client.crm.contacts.basic_api.get_page\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "from hubspot import HubSpot\n\napi_client = HubSpot(access_token='YOUR_ACCESS_TOKEN')\napi_client.crm.contacts.get_page()\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To complete the migration over to your private app, remove all references to the HubSpot API key from your code, and instead use the approach above to use your private app's access token. Depending on the request you're making, you may want to create a secret to store your token, rather than hard coding it in your requests. Using a secret will prevent your token from being exposed, such as when using a token in a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "serverless function"
      }), ". To store the access token as a secret:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the terminal, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add secretName"
        }), ". It's recommended to name the secret something simple so that you can easily reference it in the future."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Paste the access token into the terminal, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then access your secret as an environment variable. Learn more in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "serverless functions example below"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To confirm that all references to your API key have been removed, you can check the call log in your HubSpot account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations > API key"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review the most recent requests in the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Call log"
        }), " tab to confirm that no recent requests have been made since removing all previous references over to use your private app's access token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/check-api-key-call-log-after-migration.png",
        alt: "check-api-key-call-log-after-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a paid ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        })
      }), " account with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/marketing-contacts",
        children: "marketing contacts"
      }), ", and you previously ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/manage-marketing-contacts-settings-for-your-integrations#set-contacts-created-by-api-key-apps-as-marketing-contacts",
        children: "set contacts created by integrations using your API key as marketing contacts"
      }), ", you must also do the same for your private app:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations > Marketing contacts"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Your connected apps"
        }), ", use the search bar to locate your private app, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Turn on to sync contacts as marketing contacts"
        }), " switch on."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/set-private-app-contacts-as-marketing-contacts.png",
        alt: "set-private-app-contacts-as-marketing-contacts"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you've finished setting up your private app and you've confirmed all references to your API key have been removed in your code, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
        children: "deactivate the key"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verify requests and monitor logs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've removed all references to the HubSpot API key in your code and replaced them with references to your private app's access token instead, no further code changes are required."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you followed the steps above in a developer test or sandbox account, repeat the same process in your production account. Then, monitor your private app's API call logs and confirm that none of your app's requests return ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "400"
      }), " errors:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of your private app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logs"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Any unsuccessful API request that failed due to a missing scope will appear as a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " error. If you access the runtime logs of your integration, the response from the corresponding API request should include an error message with details about any missing scopes."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/403-error-after-private-app-migration.png",
        alt: "403-error-after-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you need to include a new scope for your private app:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Details"
            }), " tab."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Edit details"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["At the top of the page, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Scopes"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "checkbox"
            }), " next to any missing scopes, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Commit changes"
            }), " in the top right when you're done."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-missing-scopes-private-app-migration.png",
        alt: "select-missing-scopes-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about creating and managing private apps, along with their limits, in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private apps guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implementation examples"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about common API key usages and how to migrate to private app access tokens instead."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Serverless functions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you’re using an API key within a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverless function"
      }), ", you can similarly use the private app’s access token for authentication. You'll need to ensure that the private app has the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "scopes"
      }), " that the function needs to execute."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To authenticate a serverless function with a private app access token:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Access"
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "token"
          }), " card, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Show token"
          }), " to reveal your access token. Then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copy"
          }), " to copy the token to your clipboard."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
            alt: "show-private-app-access-token-1"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "With your access token copied, create a new secret to store the token:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the terminal, run ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs secrets add secretName"
            }), ". It's recommended to name the secret something simple so that you can easily reference it in the future."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Paste the access token into the terminal, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Enter"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your serverless function's ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "serverless.json"
          }), " file, add the secret name to the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "secrets"
          }), " array:"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example serverless.json file\n\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"getPrompts\": {\n      \"method\": \"GET\",\n      \"file\": \"serverlessFunction.js\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your serverless function's JavaScript file, set the value of the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Authorization"
        }), " header to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Bearer secretName"
        }), ". For example, if you're making a call to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "Contacts API"
        }), " using Node.js and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ", the request would look like the following:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example serverless function\nconst axios = require('axios');\n\nexports.main = (context, sendResponse) => {\n  axios.get(`https://api.hubapi.com/crm/v3/objects/contacts`, {\n    headers: {\n      Authorization: `Bearer ${process.env.secretName}`,\n      'Content-Type': 'application/json',\n    },\n  });\n  sendResponse({ statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "making API calls with your app's token"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "One-time jobs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you’re using an API key for running one-time jobs, such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "creating a property"
      }), ", you can instead create a private app and use its access token to authenticate the call. Once a private app is created, you can reuse its access token for any one-time jobs, as long as the private app has the proper ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "scopes"
      }), ". You can update a private app’s scopes at any time from the private app’s settings in HubSpot. Or, you can delete the private app and create a new one specific to the job you need to run."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/private-app-edit-scopes.png",
        alt: "private-app-edit-scopes"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create custom objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Instead of using an API key to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "create a custom object"
      }), ", you can instead create a private app and use its access token to authenticate the call, as long as the app has the necessary ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "scopes"
      }), ". For example, when using Postman to create a custom object, set the authorization type to ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Bearer token"
      }), ", then enter the token into the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Token"
      }), " field."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-private-app-access-token-field.png",
        alt: "postman-private-app-access-token-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about creating a custom object using a private app on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-a-custom-object-using-private-apps",
        children: "HubSpot's developer blog"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Custom code workflow actions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you’re using an API key in a ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/api/automation/custom-code-actions#create-a-custom-code-action",
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "Custom code"
        }), " workflow action"]
      }), ", you can instead use the private app’s access token, as long as the app has the necessary ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "scopes"
      }), ". To make the update, open the custom action in the workflow editor, then make the following updates:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["First, ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/conversations/working-with-chatbots/code-snippets",
            children: "add a new secret"
          }), " that contains the private app access token."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/workflow-action-add-secret.png",
            alt: "workflow-action-add-secret"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Then ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/automation/custom-code-actions#secret",
            children: "update the action code"
          }), " with the new secret."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({\n  accessToken: process.env.secretName,\n});\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}