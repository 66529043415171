'use es6';

import getPath from './get-path';
export default function getPathOperation(swagger, doc) {
  if (swagger.paths && doc.swagger) {
    const path = getPath(swagger, doc);
    if (path) return path[doc.api.method];
  }
  return {
    parameters: []
  };
}