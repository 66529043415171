"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529629;
const slug = exports.slug = 'guides/cms/improve-older-sites/add-theme-features-to-existing-sites';
const title = exports.title = 'Hinzufügen von Designfunktionen zu einer bestehenden CMS Hub-Website';
const name = exports.name = 'EMEA DACH (DE) Add theme capabilities to an existing HubSpot CMS website';
const metaDescription = exports.metaDescription = 'Designfunktionen können älteren Websites additiv hinzugefügt werden. Überlegen Sie sich, wie sich die Website entwickeln soll und diese Funktionen verbessert werden können.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "design",
  "label": "Design",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-vorhandene-dateien-in-einem-enthaltenden-ordner-ablegen",
  "label": "1. Vorhandene Dateien in einem enthaltenden Ordner ablegen",
  "parent": "design"
}, {
  "depth": 1,
  "id": "2.-den-enthaltenden-ordner-zum-design-machen",
  "label": "2. Den enthaltenden Ordner zum Design machen",
  "parent": "design"
}, {
  "depth": 1,
  "id": "3.-designfelder-hinzuf%C3%BCgen",
  "label": "3. Designfelder hinzufügen",
  "parent": "design"
}, {
  "depth": 0,
  "id": "design-manager-drag-%26-drop-als-html-klonen",
  "label": "Design-Manager-Drag-&-Drop als HTML klonen",
  "parent": null
}, {
  "depth": 0,
  "id": "drag-%26-drop-bereiche",
  "label": "Drag-&-Drop-Bereiche",
  "parent": null
}, {
  "depth": 2,
  "id": "ist-es-sinnvoll%2C-jede-bestehende-seite-auf-die-verwendung-von-drag-%26-drop-bereichen-umzustellen%3F",
  "label": "Ist es sinnvoll, jede bestehende Seite auf die Verwendung von Drag-&-Drop-Bereichen umzustellen?",
  "parent": "drag-%26-drop-bereiche"
}, {
  "depth": 1,
  "id": "umwandeln-vorhandener-vorlagen",
  "label": "Umwandeln vorhandener Vorlagen",
  "parent": "drag-%26-drop-bereiche"
}, {
  "depth": 1,
  "id": "wie-kann-ich-bestehende-seiten-migrieren%3F",
  "label": "Wie kann ich bestehende Seiten migrieren?",
  "parent": "drag-%26-drop-bereiche"
}, {
  "depth": 0,
  "id": "globale-teilvorlagen",
  "label": "Globale Teilvorlagen",
  "parent": null
}, {
  "depth": 0,
  "id": "code-warnungen",
  "label": "Code-Warnungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      h3: "h3",
      strong: "strong",
      ol: "ol",
      code: "code",
      img: "img",
      h4: "h4",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Hinzufügen von Designfunktionen zu einer bestehenden CMS Hub-Website"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Einer der Vorteile von HubSpot ist, dass Sie es nicht ständig aktualisieren müssen. CMS Hub ist immer auf dem neuesten Stand. Die mit CMS Hub eingeführten neuen Funktionen sind additiv. Die Vorlagen, Module usw. für bestehende CMS Hub-Websites funktionieren also genauso, wie Sie sie erstellt haben. Sie sind sogar noch besser, denn hinter den Kulissen wird das CMS-System immer besser, schneller und einfacher zu bearbeiten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt einige spezifische Funktionen von CMS Hub, die ein Entwickler hinzufügen muss. Sie können einige dieser Funktionen anders implementieren, als Sie es bei einer brandneuen Website tun würden. Dieses Tutorial wird Sie dabei unterstützen, diese Funktionen in Ihre bestehende(n) Website(s) einzubauen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bevor Sie fortfahren:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lesen Sie sich die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "Schlüsselkonzepte von CMS Hub"
        }), " durch, auch wenn Sie schon lange auf Grundlage von HubSpot entwickeln. Sie bekommen dadurch ein besseres Verständnis dafür, wie alle Teile zusammenpassen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie müssen auch das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS-CLI"
        }), " verwenden. Wenn Sie es also noch nicht installiert haben, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "sollten Sie es einrichten"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können diese Funktionen unabhängig voneinander hinzufügen und nutzen. Beginnen Sie bei der Überschrift für bestimmte Funktionen, die Sie benötigen. Sie müssen diesem Tutorial nicht durchgehend linear folgen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Design"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub-", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Designs"
      }), " sind ein Paket von Vorlagen, Modulen, CSS-, JSON- und JS-Dateien. Dass Designs als Paket bereitgestellt werden, ist für das gesamte Content-Editor-Erlebnis von Bedeutung."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei der Erstellung einer neuen Website-Seite oder Landingpage wird dem Content-Autor ein Raster mit den Design in seinem Account angezeigt. Sie wählen das Design aus, das sie verwenden, und sehen dann ein Raster mit nur den Vorlagen in diesem Design."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie eine Seite mit einer Vorlage aus einem Design bearbeiten, werden die Module in diesem Design besonders behandelt, sodass sie im Bereich „Modul hinzufügen“ hervorgehoben werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Vorhandene Dateien in einem enthaltenden Ordner ablegen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie machen Ihre Vorlagen, Module, CSS- und JS-Dateien zu einem Design, wenn sie sich im selben enthaltenden Ordner befinden. Es spielt keine Rolle, ob sie in Unterordnern organisiert sind, wichtig ist nur, dass diese Elemente in einem Ordner enthalten sind. Wenn sie bereits auf diese Weise gespeichert sind, umso besser. Wenn nicht, erstellen Sie diesen Ordner und verschieben Sie Ihre Elemente dorthin. Benennen Sie den Ordner so, wie Sie Ihr Design benennen möchten. Der Name ist völlig frei wählbar. Benennen Sie ihn etwa nach der Marke des Unternehmens, das die Website widerspiegelt, und dem Jahr der Neugestaltung oder Aktualisierung."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Den enthaltenden Ordner zum Design machen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für Designs sind zwei JSON-Dateien erforderlich, von denen zunächst nur eine den tatsächlichen Inhalt benötigt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "theme.json"
        }), " – enthält die Einstellungen Ihres Designs."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "fields.json"
        }), " – enthält Felder, die verwendet werden können, um Änderungen am Design einer gesamten Website vorzunehmen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JSON-Dateien sind speziell und können derzeit nicht mit dem Design-Manager erstellt werden. Diese Dateien können über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS-CLI"
      }), " hochgeladen werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Holen Sie den im Schritt „Vorhandene Dateien in einem enthaltenden Ordner ablegen“ erstellten Ordner auf Ihren Computer."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erstellen Sie innerhalb des Ordners auf Ihrem Computer eine neue Datei und nennen Sie sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Kopieren Sie die ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/guides/cms/content/themes/overview#theme-json",
          children: ["Beispiel-", (0, _jsxRuntime.jsx)(_components.code, {
            children: "theme.json"
          }), "-Datei aus dem „Designs“-Dokument"]
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie den Beispielcode in Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "-Datei ein."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ändern Sie den Wert von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"label\""
        }), " in den Namen des Designs, der Content-Autoren angezeigt werden soll."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ändern Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"preview_path\""
        }), " in den Pfad entweder Ihrer Startseitenvorlage oder Ihrer am häufigsten verwendeten Vorlage."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Machen Sie einen Screenshot von der Startseite der Website. Benennen Sie das Bild ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.jpg"
        }), ". Legen Sie das Bild im selben Ordner wie Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "-Datei ab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ändern Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"screenshot_path\""
        }), " in theme.json in den Pfad Ihres ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.png"
        }), "-Bildes."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Speichern Sie die Datei."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erstellen Sie im selben Ordner wie Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), " eine neue Datei, nennen Sie sie „fields.json“. Geben Sie in dieser Datei einfach ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[]"
        }), " ein und speichern Sie die Datei."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Ihre Änderungen im Design-Manager zu sehen, führen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload command"
      }), " aus. Sie haben jetzt ein grundlegendes Design. Module und Vorlagen innerhalb dieses Designs werden in Verbindung mit dem Design angezeigt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Designfelder hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Designfelder sind Steuerelemente, die Sie einem Content-Autor zur Verfügung stellen können, damit dieser designübergreifende Stiländerungen vornehmen kann."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Während der ersten Erstellung einer Website können Content-Autoren mithilfe dieser Designsteuerelemente beim website-übergreifenden Branding einbezogen werden. Viele Teams schätzen diese, da sich der Entwickler dadurch auf die eher technischen Aspekte der Website-Entwicklung konzentrieren kann."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei bestehenden Websites können Designfelder überflüssig sein. Wenn die Website bereits benutzerdefiniert erstellt wurde, gibt es wahrscheinlich keinen Grund, Farb- und Typografiesteuerelemente für die gesamte Website hinzuzufügen. Wenn sich das Branding einer Website erheblich ändert, kann es sinnvoller sein, eine Neugestaltung vorzunehmen, als nachträglich Felder hinzuzufügen. Letztlich ist dies jedoch eine Entscheidung, die Sie gemeinsam mit den anderen an der Website Beteiligten treffen sollten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um dem Design Felder hinzuzufügen, fügen Sie deren JSON in Ihre fields.json-Datei ein. Die Felder folgen der gleichen Struktur wie die Modulfelder."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Stellen Sie sicher, dass die Benutzer, die Zugriff auf das Ändern der Werte von Designfeldern haben sollen, über die Berechtigung „Globalen Content und Designs bearbeiten“ verfügen. Bei Benutzern, die diese Einstellungen nicht bearbeiten dürfen, sollte sichergestellt sein, dass sie diese Funktion NICHT aktiviert haben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "Screenshot der Einstellung für globalen Content und Designs in den Benutzerberechtigungen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Design-Manager-Drag-&-Drop als HTML klonen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag-&-Drop-Bereiche"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "globale Teilverlagen"
      }), " erfordern die Verwendung von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML- und HubL-Code"
      }), "-Dateien. Wenn Ihre bestehende Website mit dem alten Drag-&-Drop-Design-Manager-System erstellt wurde und Sie die neuen Funktionen nutzen möchten, müssen diese Vorlagen möglicherweise als HTML geklont werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So klonen Sie diese Vorlagen als HTML + HubL-Vorlage:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Öffnen Sie den Design-Manager und suchen Sie die Vorlage im Finder."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Klicken Sie mit der rechten Maustaste auf die Vorlage."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wählen Sie im daraufhin angezeigten Kontextmenü die Option „Als HTML klonen“ aus."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Jetzt können Sie mit dem Hinzufügen der Drag-&-Drop-Bereiche sowie globalen Teilverlagen beginnen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Drag-&-Drop-Bereiche"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Durch das Hinzufügen von", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag-&-Drop-Bereichen"
      }), " zu Vorlagen können Content-Autoren die Module sowohl horizontal als auch vertikal auf einer Seite platzieren. Durch Drag-&-Drop-Bereiche erhält ein Content-Autor außerdem zusätzliche stilistische Möglichkeiten. Da sich mithilfe einer Vorlage mit einem Drag-&-Drop-Bereich viele verschiedene Layouts erstellen lassen, kann sich der Entwickler auf die technischen Aspekte der Website-Erstellung und -Aktualisierung konzentrieren."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Drag-&-Drop-Bereiche sind eine neue Funktion und entsprechen nicht den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "Drag-&-Drop-Vorlagen des Design-Managers"
        }), ". Sie werden auch nicht von Drag-&-Drop-Vorlagen des Design-Managers unterstützt. Um sie zu Vorlagen hinzuzufügen, die zuvor mit dem Drag-&-Drop-Vorlagendesigner erstellt wurden, siehe „Design-Manager-Drag-&-Drop als HTML klonen“."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ist es sinnvoll, jede bestehende Seite auf die Verwendung von Drag-&-Drop-Bereichen umzustellen?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das hängt ganz davon ab, ob die Seite Ihre Unternehmensziele erfüllt. Mit anderen Worten: Es gilt das alte Sprichwort „Repariere nichts, was nicht kaputt ist“. Wenn das Layout der Seite geändert werden muss, ist es wahrscheinlich ratsam, die Seite auf die Verwendung von Drag-&-Drop-Bereichen umzustellen. Wenn die Seite ihren Zweck erfüllt und das Layout nicht geändert werden muss, ist sie wahrscheinlich gut, sie so zu belassen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Umwandeln vorhandener Vorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es ist relativ einfach, einen Klon einer alten Vorlage zu erstellen und die flexible Spalte in einen Drag-&-Drop-Bereich zu ändern. Das ist eine gute Idee, denn damit eröffnen sich Content-Autoren viele Möglichkeiten. Auf diese Weise erhalten sie einen großen Gestaltungsspielraum für neue Seiten. Wenn es sich bei Ihrer Vorlage um eine Design-Manager-Drag-&-Drop-Vorlage handelt, siehe ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#clone-design-manager-drag-and-drop-as-html",
        children: "Design-Manager-Drag-&-Drop als HTML klonen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die einfachste Lösung besteht darin, die Instanzen der flexiblen Spalten zu finden, die das HubL-Tag ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% widget_container \"my_unique_column_name\" %}"
          })
        }), " verwenden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ersetzen Sie jede Instanz bei Bedarf durch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% dnd_area \"my_unique_area_name\" %}"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie die Standardmodule nicht festlegen möchten, lassen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " leer. Wenn Sie die Standardmodule für die Region übernehmen oder neue hilfreiche Standardwerte festlegen möchten, fügen Sie innerhalb des ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " einen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), " und innerhalb von diesem eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "hinzu."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Für jedes Modul-Tag innerhalb des alten ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_container"
        }), " müssen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), " erstellen, die ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " mit einem passenden Pfad zu den Modulen enthält, die Sie standardmäßig anzeigen möchten. Dadurch entsteht das gleiche vertikale Layout wie bei der flexiblen Spalte."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Wie kann ich bestehende Seiten migrieren?"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Wenn Sie Vorlagen, die mit flexiblen Spalten erstellt wurden, auf die Verwendung von Drag-&-Drop-Bereichen umstellen, sollten Sie einige Dinge beachten. ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Flexible Spalten sind nicht dasselbe wie Drag-&-Drop-Bereiche. Sie können nicht von einer Vorlage, die nur eine flexible Spalte hat, zu einer Vorlage wechseln, die nur einen Drag-&-Drop-Bereich hat"
        }), ". Aus Sicherheitsgründen erlauben wir dies nicht. Der Inhalt könnte in einem solchen Fall nicht von der flexiblen Spalte auf den Drag-&-Drop-Bereich übertragen werden. Zur Veranschaulichung, warum das so ist, nehmen wir an, Sie haben Ihre neue Vorlage so aufgebaut, dass Sie eine Seitenleiste und einen Hauptinhaltsbereich haben. Ihre Seitenleiste ist eine flexible Spalte, Ihr Hauptinhalt ist ein Drag-&-Drop-Bereich. Das Tool zum Austauschen würde die flexible Spalte auf die flexible Spalte übertragen."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine Seite in einen Drag-&-Drop-Bereich umwandeln wollen, ist die sicherste Methode, dies mit Hilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "Content-Staging"
      }), " zu tun."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/content/staging/domain",
          children: "Öffnen Sie das Content-Staging-Tool"
        }), ", suchen Sie die vorhandene Seite und stellen Sie sie bereit. Wählen Sie „Leere Seite in Staging-Umgebung bereitstellen“ aus."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wählen Sie Ihre neue Vorlage aus, die den Drag-&-Drop-Bereich verwendet."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Kopieren Sie die Informationen aus der Originalversion der Seite und fügen Sie sie in das gewünschte Layout ein."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie fertig sind, veröffentlichen Sie sie in der Staging-Umgebung."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Gehen Sie im Content-Staging-Tool zur Registerkarte „Veröffentlichen“. Wählen Sie Ihre Seite aus und veröffentlichen Sie sie von der Staging-Umgebung aus."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Globale Teilvorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Globale Teilvorlagen"
      }), " sind Teile von HTML & HubL-Inhalt, die auf Ihrer gesamten Website wiederverwendet werden können. Die gebräuchlichsten Typen von globalen Teilverlagen sind Header, Seitenleisten- und Footer von Websites. Für Content-Autoren manifestieren sich globale Teilverlagen im Content-Editor als Regionen, die sie zur Bereiche, die im Editor für globalen Content zum Bearbeiten auswählen können. Der Editor für globalen Content sieht genauso aus wie der Seiten-Editor, dient aber der Bearbeitung von globalem Content und Designs bearbeiten“ verfügen.."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zur Veranschaulichung könnten Sie den Header als eine globale Teilverlage mit Modulen für die Navigation und andere Inhalte implementieren. Wenn der Content-Autor einen Link zur Navigation hinzufügen möchte, kann er im Seiten-Editor auf den Header klicken, dann auf das Menümodul und das Menü aktualisieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Stellen Sie sicher, dass die Benutzer, die Zugriff auf das Bearbeiten von globalem Content haben ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "sollen"
      }), ", über die Berechtigung „Globalen Content und Designs bearbeiten“ verfügen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "Einstellung für globaler Content & Designs in den Benutzerberechtigungen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Code-Warnungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für Accounts in CMS Hub Enterprise bieten ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "Code-Warnungen"
      }), " einen zentralen Ort für Entwickler und IT-Manager, um einen Überblick über Probleme zu erhalten, die die Performance und das Rendering von Seiten und Inhaltselementen im Account beeinträchtigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Da Sie die Website aktiv optimieren, sollten Sie die Vorteile der neuen Funktionen nutzen. Es empfiehlt sich, die Code-Warnungen für Ihren Account zu überprüfen und eventuelle Probleme zu lösen."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}