import { useContext } from 'react';
import { MDXLayoutContext } from '../context/MDXLayoutContext';

/**
 * Contextual hook for descendants of the `MDXPage` wrapper. Returns `true` if
 * this component is a descendant of `MDXPage`.
 *
 * @default false
 *
 * @example
 * ```tsx
 * const isMDXLayout = useMDXLayout();
 * ```
 */
const useMDXLayout = () => {
  const context = useContext(MDXLayoutContext);
  return context;
};
export default useMDXLayout;