"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 59619794102;
const slug = exports.slug = 'guides/api/crm/engagements/calls';
const title = exports.title = 'Engagements | Calls';
const name = exports.name = 'Engagements | Calls';
const metaDescription = exports.metaDescription = 'Use the calls engagement API to log and manage calls on CRM records. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-call-engagement",
  "label": "Create a call engagement",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "create-a-call-engagement"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "create-a-call-engagement"
}, {
  "depth": 0,
  "id": "retrieve-calls",
  "label": "Retrieve calls",
  "parent": null
}, {
  "depth": 0,
  "id": "identify-voicemails-vs.-recorded-calls",
  "label": "Identify voicemails vs. recorded calls",
  "parent": null
}, {
  "depth": 0,
  "id": "update-calls",
  "label": "Update calls",
  "parent": null
}, {
  "depth": 1,
  "id": "associate-existing-calls-with-records",
  "label": "Associate existing calls with records",
  "parent": "update-calls"
}, {
  "depth": 1,
  "id": "remove-an-association",
  "label": "Remove an association",
  "parent": "update-calls"
}, {
  "depth": 0,
  "id": "pin-a-call-on-a-record",
  "label": "Pin a call on a record",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-calls",
  "label": "Delete calls",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Calls"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the calls engagement API to log and manage calls on CRM records and on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "calls index page"
      }), ". You can log calls either ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "in HubSpot"
      }), " or through the calls API. Below, learn the basic methods of managing calls through the API. To view all available endpoints and their requirements, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/engagements/calls",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a call engagement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a call engagement, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the request body, add call details in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " object. You can also add an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " object to associate your new call with an existing record (e.g.,contacts, companies)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is a list of HubSpot default calling properties that you can include in the properties object. You can also create custom properties using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Required. This field marks the call's time of creation and determines where the call sits on the record timeline. You can use either a Unix timestamp in milliseconds or UTC format."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The description of the call, including any notes that you want to add."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_callee_object_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the HubSpot record associated with the call. This will be the recipient of the call for ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), " calls, or the dialer of the call for ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), " calls."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_callee_object_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of the object to which the call's associated record belongs (e.g., specifies if the record is a contact or company). This will be the object of the recipient for ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), "calls, or the object of the dialer for ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), " calls."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_direction"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The direction of the call from the perspective of the HubSpot user. If the user is the call recipient, the direction should be set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), ". If the user initiated the call, the direction should be set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_disposition"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The outcome of the call. To set the call disposition, you need to use the internal GUID value. If your account has set up ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/calling/create-custom-call-and-meeting-outcomes",
              children: "custom call outcomes"
            }), ", you can find their disposition GUIDs using ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/engagements/engagement-details#get-call-engagement-dispositions",
              children: "this API"
            }), ". The default HubSpot outcome labels and their internal values are:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Busy: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "9d9162e7-6cf3-4944-bf63-4dff82258764"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Connected: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "f240bbac-87c9-4f6e-bf70-924b57d47db7"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Left live message: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "a4c4c377-d246-4b32-a13b-75a56a4cd0ff"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Left voicemail: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "b2cf5968-551e-4856-9783-52b3da59a7d0"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["No answer: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "73a0d17f-1163-4015-bdd5-ec830791da20"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Wrong number: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "17b47fee-58de-441e-a44c-c6300d46f273"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The duration of the call in milliseconds."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_from_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The phone number that the call was made from."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_recording_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The URL that stores the call recording. URLS to .mp3 or .wav files can be played back on CRM records. Only HTTPS, secure URLs will be accepted."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The status of the call. The statuses are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BUSY"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALLING_CRM_USER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CANCELED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPLETED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONNECTING"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FAILED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN_PROGRESS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NO_ANSWER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUEUED"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RINGING"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The title of the call."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_source"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The source of the call. This is not required, but it is required to leverage the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/extensions/calling-extensions/recordings-and-transcriptions#log-a-call-with-your-app-s-endpoint-using-the-engagements-api",
              children: "recording and transcriptions pipeline"
            }), ". If the property is set, it must be set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS_PLATFORM"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_to_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The phone number that received the call."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID of the owner"
            }), " associated with the call. This field determines the user listed as the call creator on the record timeline."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_activity_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of call. The options are based on the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types",
              children: "call types set in your HubSpot account."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The IDs of the call's attachments. Multiple attachment IDs are separated by a semi-colon."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create and associate a call with existing records, include an associations object in your request. For example, to create a call and associate it with a contact and a ticket, your request body might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Support call\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_call_body\": \"Resolved issue\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(857) 829 5489\",\n    \"hs_call_to_number\": \"(509) 999 9999\",\n    \"hs_call_recording_url\": \"https://api.twilio.com/2010-04-01/Accounts/AC890b8e6fbe0d989bb9158e26046a8dde/Recordings/RE3079ac919116b2d22\",\n    \"hs_call_status\": \"COMPLETED\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 500\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 194\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 1234\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 220\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the associations object, you should include the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The record you want to associate with the call, specified by its unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " value."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of the association between the call and the record. Include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Default association type IDs are listed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
              children: "here"
            }), ", or you can retrieve the value for custom association types (i.e. labels) via the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#retrieve-association-types",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch creating calls in the r", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/engagements/calls",
        children: "eference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve calls"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can retrieve calls individually or in bulk. Learn more about batch retrieval by checking out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/engagements/calls",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve an individual call by its call ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), ". You can include the following parameters in the request URL:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A comma separated list of the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#call-properties-1",
              children: "properties"
            }), " to be returned."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A comma separated list of object types to retrieve associated IDs for. Any specified associations that don't exist will not be returned in the response. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4",
              children: "associations API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To request a list of all of calls, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), ". You can include the following parameters in the request URL:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The maximum number of results to display per page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A comma separated list of the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#call-properties-1",
              children: "properties"
            }), " to be returned."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you make a successful request, the response will include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callId"
      }), " which you can use to retrieve, update, and delete the call."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identify voicemails vs. recorded calls"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For recorded calls and voicemails, a recording is stored in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_recording_url"
      }), " property. If your account has access to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/receive-calls-in-hubspot",
        children: "inbound calling"
      }), ", to differentiate between calls that were completed and recorded vs. inbound calls with a voicemail, include the following properties in your request: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_status"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If a call has a voicemail, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_status"
      }), " value will be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "missed"
      }), ", and the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), " value will be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), " value will be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " for an inbound call where no voicemail was left, or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), " if the call has a status other than missed."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update calls"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can update calls individually or in batches. To update an individual call by its call ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the request body, include the call properties that you want to update:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example PATCH request to https://api.hubspot.com/crm/v3/objects/calls/{callID}\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Discovery call\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_call_body\": \" Decision maker out, will call back tomorrow\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(857) 829 5489\",\n    \"hs_call_to_number\": \"(509) 999 9999\",\n    \"hs_call_recording_url\": \"https://api.twilio.com/2010-04-01/Accounts/AC890b8e6fbe0d989bb9158e26046a8dde/Recordings/RE3079ac919116b2d22\",\n    \"hs_call_status\": \"COMPLETED\"\n  }\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will ignore values for read-only and non-existent properties. To clear a property value, pass an empty string for the property in the request body."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch updating in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/engagements/calls#post-%2Fcrm%2Fv3%2Fobjects%2Fcalls%2Fbatch%2Fupdate",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associate existing calls with records"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate a call with records, such as a contact and its associated companies, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". The request URL contains the following fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the call."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The type of object that you want to associate the call with (e.g., contact or company)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the record that you want to associate the call with."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A unique identifier to indicate the association type between the call and the other object. The ID can be represented numerically or in snake case (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "call_to_contact"
            }), "). You can retrieve the value through the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request URL might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/calls/17591596434/associations/contact/104901/194"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remove an association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove an association between a call and a record, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to the same URL as above:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pin a call on a record"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "pin a call"
      }), " on a record so it remains on the top of the record's timeline. The call must already be associated with the record prior to pinning, and you an only pin one activity per record. To pin a call, include the call's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " field when creating or updating a record via the object APIs. Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "companies,"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contacts"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "deals"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "custom objects"
      }), " APIs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete calls"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can delete calls individually or in batches, which will add the call to the recycling bin in HubSpot. You can later ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restore the call from the record timeline"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete an individual call by its call ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch deleting by checking out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/engagements/calls#delete-%2Fcrm%2Fv3%2Fobjects%2Fcalls%2F%7Bcallid%7D",
        children: "reference documentation"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}