"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937505;
const slug = exports.slug = 'guides/api/crm/properties';
const title = exports.title = 'CRM API | Properties';
const name = exports.name = 'vNext Docs DP - Properties';
const metaDescription = exports.metaDescription = ' The CRM properties endpoints allow you to manage custom properties as well as view default property details for any object.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "default-properties",
  "label": "Default properties",
  "parent": null
}, {
  "depth": 0,
  "id": "property-groups",
  "label": "Property groups",
  "parent": null
}, {
  "depth": 0,
  "id": "property-type-and-fieldtype-values",
  "label": "Property type and fieldType values",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-property",
  "label": "Create a property",
  "parent": null
}, {
  "depth": 0,
  "id": "create-unique-identifier-properties",
  "label": "Create unique identifier properties",
  "parent": null
}, {
  "depth": 0,
  "id": "create-calculation-properties",
  "label": "Create calculation properties",
  "parent": null
}, {
  "depth": 1,
  "id": "calculation-property-syntax",
  "label": "Calculation property syntax",
  "parent": "create-calculation-properties"
}, {
  "depth": 2,
  "id": "literal-syntax",
  "label": "Literal syntax",
  "parent": "create-calculation-properties"
}, {
  "depth": 2,
  "id": "property-syntax",
  "label": "Property syntax",
  "parent": "create-calculation-properties"
}, {
  "depth": 2,
  "id": "operators",
  "label": "Operators",
  "parent": "create-calculation-properties"
}, {
  "depth": 2,
  "id": "functions",
  "label": "Functions",
  "parent": "create-calculation-properties"
}, {
  "depth": 2,
  "id": "conditional-statements",
  "label": "Conditional statements",
  "parent": "create-calculation-properties"
}, {
  "depth": 1,
  "id": "example-formulas",
  "label": "Example formulas",
  "parent": "create-calculation-properties"
}, {
  "depth": 0,
  "id": "retrieve-properties",
  "label": "Retrieve properties",
  "parent": null
}, {
  "depth": 0,
  "id": "update-or-clear-a-property-s-values",
  "label": "Update or clear a property's values",
  "parent": null
}, {
  "depth": 1,
  "id": "add-values-to-checkbox-type-properties",
  "label": "Add values to checkbox type properties",
  "parent": "update-or-clear-a-property-s-values"
}, {
  "depth": 1,
  "id": "assign-record-owners-with-user-properties",
  "label": "Assign record owners with user properties",
  "parent": "update-or-clear-a-property-s-values"
}, {
  "depth": 1,
  "id": "clear-a-property-value",
  "label": "Clear a property value",
  "parent": "update-or-clear-a-property-s-values"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3",
      h4: "h4",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Properties"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use properties to store information on CRM records. HubSpot provides a set of default properties for each CRM object, and you can also create and manage your own custom properties either ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: "in HubSpot"
      }), " or using the properties API."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating properties, it’s important to consider how to architect your data. In many cases, creating custom properties for HubSpot's standard objects is the right course of action. However, there may be times when you'll need to create a separate ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "custom object"
      }), " with its own set of properties."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Default properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM objects are defined by a primary ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "type"
      }), " and a set of ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), ". Each type has a unique set of standard properties, represented by a map of name-value pairs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Learn more about default properties for different objects:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "Contacts"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "Companies"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "Deals"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-activity-properties",
          children: "Activities"
        }), " (Calls, Emails, Meetings, Notes, Tasks)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-lead-properties",
          children: "Leads"
        }), "(", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Property groups"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Property ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties#create-and-edit-property-groups",
        children: "groups"
      }), " are used to group related properties. Any grouped properties will appear next to each other on HubSpot records. If your integration creates any custom object properties, a custom property group will make it easy to identify that data."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Property type and fieldType values"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating or updating properties, both ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " values are required. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " value determines the type of the property, i.e. a string or a number. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " property determines how the property will appear in HubSpot or on a form, i.e. as a plain text field, a dropdown menu, or a date picker."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the table below, learn about the available property ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " and corresponding ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " values."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsxs)(_components.th, {
            children: ["Valid ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fieldType"
            }), " values"]
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A field containing binary options (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Yes"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "No"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), ")."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A string representing a set of options, with options separated by a semicolon."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A value representing a specific day, month, and year. Values must be represented in UTC time and can be formatted as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "ISO 8601 strings or EPOCH-timestamps in milliseconds (i.e. midnight UTC)."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dateTime"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A value representing a specific day, month, year and time of day. Values must be represented in UTC time and can be formatted as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "ISO 8601 strings or UNIX-timestamps in milliseconds."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A plain text string, limited to 65,536 characters."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A number value containing numeric digits and at most one decimal."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object_coordinates"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A text value used to reference other HubSpot objects, used only for internal properties. Properties of this type cannot be created or edited, and are not visible in HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A text value stored as formatted JSON, used only for internal properties. Properties of this type cannot be created or edited, and are not visible in HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Valid values for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " include:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Fieldtype"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An input that will allow users to selected one of either Yes or No. When used in a form, it will be displayed as a single checkbox. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "add a value to single checkbox properties"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A custom equation that can calculate values based on other property values and/or associations. Learn how to define ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#create-calculation-properties",
              children: "calculation properties"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A list of checkboxes that will allow a user to select multiple options from a set of options allowed for the property. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "format values when updating multiple checkbox properties"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A date value, displayed as a date picker."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Allows for a file to be uploaded on a record or via a form. Stores a file ID."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A string, rendered as sanitized html, that enables the use of a rich text editor for the property."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A string of numerals or numbers written in decimal or scientific notation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A plain text string, displayed as a formatted phone number."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An input that will allow users to select one of a set of options allowed for the property. When used in a form, this will be displayed as a set of radio buttons."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A dropdown input that will allow users to select one of a set of options allowed for the property."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A plain text string, displayed in a single line text input."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A plain text string, displayed as a multi-line text input."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a property"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a property, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/{objectType}"
      }), ". In your request body, include the following required fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "groupName"
        }), ": the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/organize-and-export-properties",
          children: "property group"
        }), " the property will be in."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": the internal name of the property (e.g., favorite_food)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ": the name of the property as it appears in HubSpot (e.g., Favorite Food)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "type"
        }), ": the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#type",
          children: "type"
        }), " of property."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fieldType"
        }), ": the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-type",
          children: "field type"
        }), " of the property."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to create a contact property called ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Favorite Food"
      }), ", your request would look like:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body POST crm/v3/properties/contacts\n{\n  \"groupName\": \"contactinformation\",\n  \"name\": \"favorite_food\",\n  \"label\": \"Favorite Food\",\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create unique identifier properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a record is created in HubSpot, a unique ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Record ID"
      }), " (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ") is automatically generated and should be treated as a string. These IDs are unique only within that object, so there can be both a contact and company with the same ID. For contacts and companies, there are additional unique identifiers, including a contact's email address (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ") and a company's domain name (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In some cases, you want may to create your own unique identifier property so that you can't enter the same value for multiple records. You can have up to ten unique ID properties per object. To create a property requiring unique values via API:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your request body, for the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hasUniqueValue"
        }), " field, set the value to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body\n{\n  \"groupName\": \"dealinformation\",\n  \"name\": \"system_a_unique\",\n  \"label\": \"Unique ID for System A\",\n  \"hasUniqueValue\": true,\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you've created your unique ID property, you can use it in an API call to retrieve specific records. The request URL could look like this: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), ". This will return the deal with the value of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "abc"
      }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "system_a_unique"
      }), " field."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then use this unique identifier property value to identify and update specific records in the same way you could use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " (contacts), or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " (companies)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create calculation properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Calculation properties define a property value based on other properties within the same object record. They are defined using a formula, which may include operations like min, max, count, sum, or average. You can use the properties API to read or create calculation properties in your HubSpot account, using a field type of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculation_equation"
      }), " and a type of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "number"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bool"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "string"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enumeration"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can define the property's calculation formula with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), " field."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": calculation properties created via API ", (0, _jsxRuntime.jsx)("u", {
          children: "cannot"
        }), " be edited within HubSpot. You can only edit these properties via the properties API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Calculation property syntax"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), ", you can write your formula with arithmetic operators, comparison operators, logic operators, conditional statements, and other functions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Literal syntax"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "String literal"
        }), ": constant strings can be represented with either single quotes (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'constant'"
        }), ") or double quotes (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"constant\""
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Number literal"
        }), ": constant numbers can be any real numbers, and can include point notation. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1005"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1.5589"
        }), " are both valid constant numbers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Boolean literal"
        }), ": constant booleans can be ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Property syntax"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "String property variables:"
        }), " for an identifier string to be interpreted as a string property, it must be wrapped in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), " function. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string(var1)"
        }), "will be interpreted as the value for the string property var1."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Number property variables"
        }), ": all identifiers will be interpreted as number property variables. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "var1"
        }), " will be interpreted as the value for the number property var1."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Boolean property variables"
        }), ": for an identifier to be interpreted as a bool property, it must be wrapped in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool"
        }), " function. For example, the identifier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool(var1)"
        }), " will be interpreted as the value for the boolean property var1."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the language used is case sensitive for all types ", (0, _jsxRuntime.jsx)("u", {
          children: "except"
        }), " strings. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "If A ThEn B"
        }), " is exactly the same as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if a then b"
        }), " but ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'a'"
        }), " is not the same as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'A'"
        }), ". Spaces, tabs, and new lines will be used for tokenization but will be ignored."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Operators"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Operators can be used with literal and property values. For arithmetic operators, you can use prefix notation to multiply, and parenthesis can be used to specify the order of operations."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operator"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Examples"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "+"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Add numbers or strings."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subtract numbers."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100 - property2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Multiply numbers."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "10property1"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10 * property1"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Divide numbers."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 * (100 - property2/(50 - property3))"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks if a value is less than another. Supported by number properties or constants."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a < 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks if a value is greater than another. Supported by number properties or constants."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > 50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks if a value is less than or equal to another. Supported by number properties or constants."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a <= b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks if a value is greater than or equal to another. Supported by number properties or constants."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b>= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks if a value is equal to another. Supported by both numbers and strings."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "(a + b - 100c * 150.652) = 150-230b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "equals"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks if a value is equal to another. Supported by both numbers and strings."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a + b - 100.2c * 150 equals 150 - 230"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks if a value is not equal to another. Supported by both numbers and strings."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string(property1) != 'test_string'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "or"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks if either or two values are true."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > b or b <= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "and"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks if both values are true."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool(a) and bool(c)"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks if none of the values are true."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not (bool(a) and bool(c))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Functions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following are supported functions:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Function"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Examples"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Will have between 2 and 100 input numbers, and will return the maximum number out of all the inputs."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b, c, 100)"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "min"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Will have between 2 and 100 input numbers, and will return the minimum number of out all the inputs."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b, c, 100)"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether an expression can be evaluated."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), "= true if the property is boolean, but ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), " = false if the property is empty or not boolean."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Has two strings as inputs and will return true if the first input contains the second."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('hello', 'ello')"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " while ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('ello', 'hello')"
            }), " = false."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Joins a list of strings. The list of inputs can go from 2 up to 100."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate('a', 'b', string(a), string(b))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are also two parsing functions:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number_to_string"
        }), ": tries to convert the input number expression to a string."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string_to_number"
        }), ": tries to convert the input string expression to a number."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + num_cars"
      }), " is not a valid property because you can't add a string with a number, but ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + number_to_string(num_cars)"
      }), " is."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Conditional statements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also write your formula with conditional statements using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, a conditional statement could look like: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if boolean_expression then statement [elseif expression then statement]* [else statement | endif]"
      }), " where the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[a]"
      }), " brackets represent that a is optional, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a|b"
      }), " represent that either a or b will work, and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "*"
      }), " means 0 or more. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), " can be used to finish a conditional statement prematurely, ensuring that the parser can identify which ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " the next ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), " belongs to."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Example formulas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following are examples you can use to help define your own calculation formulas:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"closed - started\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A more advanced example with conditionals:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"if is_present(hs_latest_sequence_enrolled_date) then\n  if is_present(hs_sequences_actively_enrolled_count) an hs_sequences_actively_enrolled_count >= 1 then\n    true\n  else\n    false\nelse\n  ''\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can retrieve information for individual properties or all properties within an object."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve an individual property, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/properties/{object}/{propertyName}"
        }), ". For example, to retrieve the Favorite Food property, your request URL would be https://", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "api.hubspot.com/crm/v3/properties/contacts/favorite_food"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve all properties for an object, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": when retriving all properties, by default only non-sensitive properties are returned. To retrieve sensitive data properties, include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dataSensitivity"
        }), " query parameter with the value ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sensitive"
        }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/sensitive-data#manage-sensitive-data",
          children: "managing sensitive data via API"
        }), " (BETA, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " only)."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update or clear a property's values"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update a property value for a record, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/{objectType}/{recordId}"
      }), ". In your request body, include the properties and their values in an array. Learn more about updating records via the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "object APIs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add values to checkbox type properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When updating values for a record's checkbox type properties, format the values in the following ways:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Boolean"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "checkbox property"
        }), ": to display as ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Yes"
        }), ", or checked in HubSpot, your value must be ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), ". To display as ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "No"
        }), " or not checked in HubSpot, your value must be ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Multiple select"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "checkbox property"
        }), ": to add or append values to a multiple checkboxes property, add a semicolon before the first value, and separate the values with semicolons without a space between. If the property has an existing value, the leading semicolon will append the values instead of overwriting the value. For example, a contact has the existing value ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DECISION_MAKER"
        }), " for the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_buying_role"
        }), " property. To add additional values without replacing the existing value, your request would look like this:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example body for PATCH request to /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_buying_role\": \";BUDGET_HOLDER;END_USER\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Assign record owners with user properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When assigning users to CRM records via API, your value must be user's owner ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", which you can find in your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: "property settings"
      }), " or via the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "owners API"
      }), ". For example, to assign a user as owner of a contact, send a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/contacts/{contactId}"
      }), ", with the body ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\":{ \"hubspot_owner_id\": \"41629779\"}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Clear a property value"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can clear an object property value via the API by setting the property value to an empty string."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to clear the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), " from a contact object, send a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/contacts/{contactId}"
      }), " with the body ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\": { \"firstname\": \"\"}}"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}