"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 38575989237;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-completions';
const title = exports.title = 'Send custom event completions';
const name = exports.name = 'Send custom event completions';
const metaDescription = exports.metaDescription = 'Custom events allow you to track advanced activity via a JavaScript or HTTP API. The Events API can be used to get details about your events.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "define-the-event",
  "label": "Define the event",
  "parent": null
}, {
  "depth": 0,
  "id": "send-event-data",
  "label": "Send event data",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-event-data",
  "label": "Retrieve event data",
  "parent": null
}, {
  "depth": 0,
  "id": "event-properties",
  "label": "Event properties",
  "parent": null
}, {
  "depth": 0,
  "id": "attribution-reporting",
  "label": "Attribution reporting",
  "parent": null
}, {
  "depth": 1,
  "id": "asset-type",
  "label": "Asset type",
  "parent": "attribution-reporting"
}, {
  "depth": 1,
  "id": "asset-title",
  "label": "Asset title",
  "parent": "attribution-reporting"
}, {
  "depth": 1,
  "id": "interaction-sources",
  "label": "Interaction sources",
  "parent": "attribution-reporting"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      em: "em",
      strong: "strong",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Tag,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Send custom event completions"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Custom events are account-defined events that store event details in event properties. There are three types of custom events that you can create in HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "Clicked element"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "visited URL"
        }), " events are custom events that the tracking code automatically populates with data. You can further customize these events by updating your tracking code with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code#events-js-api",
          children: "trackCustomEvent"
        }), " function."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
          children: "Manually tracked events"
        }), " are custom events that are unique to your business that are not captured automatically by HubSpot or by an integration. You can manually send data to these events through this API."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to create a manually tracked custom event, send event data through the API, and to use event data once captured."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Define the event"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To send event completion data to HubSpot, you first need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "define the event itself"
      }), ", including its metadata, CRM object associations, and properties. You can define events using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions",
        children: "custom event definition API"
      }), ", or if you have a ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        }), " Enterprise"]
      }), " subscription you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
        children: "create the event in HubSpot"
      }), ". When creating the event, HubSpot will include a set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "default event properties"
      }), " that you can use to store event data. You can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "create additional properties"
      }), " for the event. These properties can be created or edited at any time."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you’ve set up your event, you can send data to it through the API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Send event data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To send event data to HubSpot, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/events/v3/send"
      }), " with your event data in the request body. Before sending event data, review the limits below, as exceeding these limits will result in an error."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST to https://api.hubspot.com/events/v3/send\n{\n  \"eventName\": \"pe1234567_login_event\",\n  \"objectId\": \"608051\",\n  \"occurredAt\": \"2024-06-28T12:09:31Z\",\n  \"properties\": {\n    \"hs_city\": \"Cambridge\",\n    \"hs_country\": \"United States\",\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\",\n    \"hs_device_type\": \"PDA;Smartphone\",\n    \"hs_touchpoint_source\": \"DIRECT_TRAFFIC\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The internal name of the event. You can find this by ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "querying your existing event definitions"
            }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/reports/create-custom-behavioral-events-with-the-code-wizard#find-internal-name",
              children: "within the HubSpot app"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the CRM record that the event will be associated with. For contacts, you can alternatively use the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utk"
            }), " field to identify the contact by email address or ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/tracking-code",
              children: "HubSpot User Token"
            }), ". All other object types require ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default, HubSpot will set the event completion timestamp to the time that the request is sent. To specify the time of event completion, include a timestamp in an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            }), " field in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " request body (", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_8601",
              children: "ISO 8601 format"
            }), "). This can be especially helpful for backdating event data to more accurately reflect real-life event completion."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The event properties to send data to. This can include ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
              children: "HubSpot's default event properties"
            }), " or any ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
              children: "custom properties"
            }), " you've defined for the event. Most default event properties are string properties, but you can review all event properties by either ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "querying the event definition"
            }), " or navigating to the event in HubSpot. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#event-properties",
              children: "event properties"
            }), " below."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Exceeding any of the following limits will result in a failed request:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The property label and internal name are limited to 50 characters."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "URL and referrer properties can receive up to 1024 characters, while all other properties can receive up to 256 characters."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Each event completion can contain data for up to 50 properties."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Property internal names must start with a letter and contain only lowercase letters a-z, numbers 0-9, and underscores."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Properties with the same internal name after lowercasing are considered duplicates, and only one of the properties will be used on completion. HubSpot will sort in ascending lexicographical order and keep the last property seen among the first 50 properties."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "There is a limit of 500 unique event definitions per account."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "There is a limit of 30 million event completions per month."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve event data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "retrieve a contact's event data"
      }), ", make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId={CONTACT_ID}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The above request includes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "eventType:"
        }), " the internal name of the event."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType:"
        }), " the record's object type."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectId:"
        }), " the contact's ID."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Event data is stored in properties, either in the set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "default event properties"
      }), " or in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "custom defined properties"
      }), ". When sending event data, you'll can update properties by including them in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " object."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"properties\": {\n    \"property1\": \"string\",\n    \"property2\": \"string\",\n    \"property3\": \"string\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The values you send will depend on the type of event property. Most of the default event properties are single-line text (string). However, you can create custom properties of any type for each event. Review the table below when formatting property values."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A string representing a set of options. When sending multiple values, separate them with a semicolon. In HubSpot, this type corresponds to dropdown select, radio select, and multiple checkbox properties."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A timestamp in the form of epoch milliseconds or ISO8601. In HubSpot, this type corresponds to date picker properties."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A plain text string limited to 65,536 characters. In HubSpot, this type corresponds to single-line and multi-line text properties."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A number value containing numeric digits and at most one decimal. In HubSpot, this type corresponds to number and calculation properties."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To view an event's available properties:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reporting"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Data Management"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Custom events"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the event."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Properties"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the properties table, view the property type under the name of the property."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-event-properties-table.png",
        alt: "custom-event-properties-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Attribution reporting"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScript events such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "clicked element"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "visited URL"
      }), " events are automatically populated with asset type and interaction data for attribution reporting. To include the same data for manually tracked events, you'll need to manually include the data in the request body using event properties. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/analyze-custom-behavioral-events",
        children: "analyzing custom events"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about the available values for asset types and interaction sources, along with example requests."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asset type"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To attribute a specific asset type to a custom behavioral event request, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_content_type"
      }), " property in the request body. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You can also use the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), " property. If both ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), " are included in one request, ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), " will override the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), " value."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot's standard content types, such as landing pages and blog posts, can be represented with the following values:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Value"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "STANDARD_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with a website page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with a landing page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with a blog post."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with a knowledge base article."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For all other types of assets, use the following values:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Value"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "AD"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with an ad, such as a Facebook or Google ad."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with a call."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT_IMPORT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction via a contact import."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction related to a HubSpot conversation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CUSTOM_BEHAVIORAL_EVENT_NAME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The internal name of a custom event, such as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pe123456_manually_tracked_event"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with an email."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with an external page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction via an integration."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An interaction with a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "marketing event"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEDIA_BRIDGE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An interaction via the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/cms/media-bridge",
              children: "media bridge"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with a meeting."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES_EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with a 1:1 sales email."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SEQUENCE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with a sequence."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with a social media post."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An interaction with an asset not in one of the above categories."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asset title"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To attribute a custom event to an asset, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_asset_title"
      }), " property in your request with the name of the asset formatted as a string. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_title\": \"Sweepstakes Sign Up\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Interaction sources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To attribute a custom behavioral event to a specific source, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_touchpoint_source"
      }), " property in your request with one of the following values:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Value"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is a conversation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DIRECT_TRAFFIC"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is direct traffic."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL_MARKETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is a marketing email."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_CRM"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is the HubSpot CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is an integration."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The interaction source is a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "marketing event"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OFFLINE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is offline."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ORGANIC_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is organic search."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER_CAMPAIGNS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is from an uncategorized campaign."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is a paid search ad."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SOCIAL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is a paid social ad."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "REFERRALS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is a referral."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is sales."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_MEDIA"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The interaction source is social media (not a paid social ad)."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}