"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 75327506237;
const slug = exports.slug = 'reference/api/other-resources/error-handling';
const title = exports.title = 'Manejo de errores';
const name = exports.name = 'Manejo de errores';
const metaDescription = exports.metaDescription = 'Descubre cómo manejar errores comunes de API al desarrollar con las API de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "errores-de-varios-estados",
  "label": "Errores de varios estados",
  "parent": null
}, {
  "depth": 0,
  "id": "reintentos",
  "label": "Reintentos",
  "parent": null
}, {
  "depth": 1,
  "id": "webhooks",
  "label": "Webhooks",
  "parent": "reintentos"
}, {
  "depth": 1,
  "id": "acciones-de-workflow-de-c%C3%B3digo-personalizado",
  "label": "Acciones de workflow de código personalizado",
  "parent": "reintentos"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      pre: "pre",
      h2: "h2",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Manejo de errores"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A menos que se especifique lo contrario, la mayoría de los puntos de terminación de HubSpot devolverá una respuesta satisfactoria ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " OK. Cualquier parámetro que regrese a un código de estado diferente especificará la respuesta devuelta en su documentación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además, HubSpot tiene varias respuestas de error comunes a múltiples API:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "207 Multi-Status"
        }), ": se devuelve cuando hay diferentes estados (por ejemplo, errores y éxitos), lo que ocurre cuando has habilitado el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#multi-status-errors",
          children: "manejo de errores de varios estados "
        }), " para los puntos de terminación de creación por lotes de la API de objetos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "401 Unauthorized"
        }), ": se devuelve cuando la autentificación proporcionada no es válida. Consulta nuestro ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "Resumen de autentificación"
        }), " para obtener información sobre las solicitudes de autentificación de API."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "403 Forbidden"
        }), ": se devuelve cuando la autentificación proporcionada no tiene los permisos adecuados para acceder a la URL específica. Como ejemplo, un token de OAuth que solo tiene acceso de contenido recibirá ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " cuando accede a la API de Negocios (que requiere acceso a contactos). Si has confirmado que tu clave API o aplicación privada tiene los permisos necesarios, ponte en contacto con ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "asistencia técnica de HubSpot "
        }), "para obtener ayuda."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "429 Too many requests"
        }), ": se devuelve cuando tu cuenta o aplicación supera los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "límites de la tasa"
        }), " de API. Encuentra sugerencias para trabajar dentro de esos límites ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/working-within-the-hubspot-api-rate-limits",
          children: "aquí"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "477 Migration in Progress"
        }), ": devuelto cuando una cuenta de HubSpot se está ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/eu-data-centre",
          children: "migrando actualmente entre ubicaciones de alojamiento de datos"
        }), ". HubSpot devolverá un encabezado de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Retry-After",
          children: "Reintentar después"
        }), " de la respuesta que indica cuántos segundos hay que esperar antes de volver a intentar la solicitud (normalmente hasta 24 horas)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "502/504 timeouts"
        }), ": se devuelve cuando se han cumplido los límites de procesamiento de HubSpot. Estos límites se implementan para evitar que un solo cliente cause un rendimiento degradado. Normalmente solo verás estas respuestas de tiempo de espera al realizar una gran cantidad de solicitudes durante un período de tiempo sostenido. Si obtienes una de estas respuestas, deberías pausar tus solicitudes por unos segundos y luego volver a intentarlo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "503 service temporarily unavailable"
        }), ": se devuelve cuando HubSpot no está disponible temporalmente. Si obtienes esta respuesta, deberías pausar tus solicitudes por unos segundos y luego volver a intentarlo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "521 web server is down"
        }), ": se devuelve cuando el servidor de HubSpot está inactivo, esto debería ser un problema temporal. Si obtienes esta respuesta, deberías pausar tus solicitudes por unos segundos y luego volver a intentarlo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "522 connnection timed out"
        }), ": se devuelve cuando se ha agotado el tiempo de espera de la conexión entre HubSpot y tu aplicación. Si has recibido esta respuesta, ponte en contacto con ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "asistencia técnica de HubSpot"
        }), " para obtener ayuda."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "523 origin is unreachable"
        }), ": se devuelve cuando HubSpot no puede ponerse en contacto con tu aplicación. Si obtienes esta respuesta, deberías pausar tus solicitudes por unos segundos y luego volver a intentarlo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "524 timeout"
        }), ": se devuelve cuando no se recibe una respuesta dentro de 100 segundos. Esto puede ocurrir cuando el servidor de HubSpot está sobrecargado, como con una consulta de datos grande. Si obtienes esta respuesta, deberías pausar tus solicitudes por unos segundos y luego volver a intentarlo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "525/526 SSL issues"
        }), ": se devuelve cuando el certificado SSL no es válido o el apretón de manos SSL falla. Si has recibido esta respuesta, ponte en contacto con ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "asistencia técnica de HubSpot"
        }), " para obtener ayuda."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además de estos errores generales, las respuestas de error de HubSpot están diseñadas para ser legibles por personas. La mayoría de los puntos finales no devuelven códigos de error, pero devuelven una respuesta con formato JSON con detalles sobre el error. Puedes encontrar más información sobre los errores específicos de puntos finales en las páginas de documentación correspondientes al punto final."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " los campos de la respuesta del ejemplo a continuación deben ser tratados como opcionales en cualquier error al analizar. Los campos específicos incluidos pueden variar entre diferentes API, por lo tanto, cualquier análisis de errores debería permitir que falten campos específicos en la respuesta."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Structure of an example error from HubSpot\n{\n  \"status\": \"error\",\n  \"message\": \"This will be a human readable message with details about the error.\",\n  \"errors\": [\n    {\n      \"message\": \"This will be a message with additional details about the error\",\n      \"in\": \"name\"\n    }\n  ],\n  \"category\": \"VALIDATION_ERROR\",\n  \"correlationId\": \"a43683b0-5717-4ceb-80b4-104d02915d8c\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Errores de varios estados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el caso de los puntos de terminación de creación por lotes de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "las API de objetos'"
      }), ", puedes habilitar respuestas de varios estados que incluyan errores parciales. Esto significa que la respuesta mostrará qué registros se crearon y cuáles no. Para hacerlo, incluye un valor único ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), " para cada entrada en tu solicitud. La ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), " puede ser cualquier cadena única."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, una solicitud para crear tickets podría verse así:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request to POST crm/v3/objects/tickets/batch/create\n{\n  \"inputs\": [\n    {\n      \"properties\": {\n        \"objectWriteTraceId\": \"549b1c2a9350\",\n        \"hs_pipeline_stage\": \"1\"\n      },\n      \"properties\": {\n        \"objectWriteTraceId\": \"549b1c2a9351\",\n        \"missing\": \"1\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En la respuesta, los estados se agrupan para que puedas ver qué creaciones tuvieron éxito y cuáles fallaron. Para la solicitud anterior, tu respuesta sería la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"id\": \"1145814089\",\n      \"properties\": {\n        \"createdate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_helpdesk_sort_timestamp\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_last_message_from_visitor\": \"false\",\n        \"hs_lastmodifieddate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_object_id\": \"1145814089\",\n        \"hs_object_source\": \"API\",\n        \"hs_object_source_label\": \"INTERNAL_PROCESSING\",\n        \"hs_pipeline\": \"0\",\n        \"hs_pipeline_stage\": \"1\",\n        \"hs_ticket_id\": \"1145814089\"\n      },\n      \"createdAt\": \"2024-08-15T17:09:13.648Z\",\n      \"updatedAt\": \"2024-08-15T17:09:13.648Z\",\n      \"archived\": false\n    }\n  ],\n  \"numErrors\": 1,\n  \"errors\": [\n    {\n      \"status\": \"error\",\n      \"category\": \"VALIDATION_ERROR\",\n      \"message\": \"Property values were not valid: [{\\\"isValid\\\":false,\\\"message\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"error\\\":\\\"PROPERTY_DOESNT_EXIST\\\",\\\"name\\\":\\\"missing\\\",\\\"localizedErrorMessage\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"portalId\\\":891936587}]\",\n      \"context\": {\n        \"objectWriteTraceId\": [\"549b1c2a9351\"]\n      }\n    }\n  ],\n  \"startedAt\": \"2024-08-15T17:09:13.610Z\",\n  \"completedAt\": \"2024-08-15T17:09:13.910Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reintentos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tu aplicación o integración proporciona un punto de terminación que HubSpot llamará, como suscripciones de webhook, cualquier error que tu punto de terminación lance hará que HubSpot vuelva a intentar la solicitud."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhooks"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tu servicio tiene problemas para manejar las notificaciones en cualquier momento, HubSpot intentará volver a enviar notificaciones fallidas hasta 10 Veces."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot lo intentará nuevamente en los casos siguientes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Falló la conexión:"
        }), " HubSpot no puede abrir una conexión http a la URL de webhook proporcionada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tiempo de espera:"
        }), " tu servicio demora más de 5 segundos en enviar una respuesta a un lote de notificaciones"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Códigos de error:"
        }), " tu servicio responde con cualquier código de estado HTTP (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4xx"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "5xx"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los workflows ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "no"
      }), " volverán a intentarlo después de recibir los códigos de estado de respuesta de la serie 4XX. Una excepción a esta regla son los errores de límite de frecuencia 429; los workflows reintentarán automáticamente después de recibir una respuesta 429 y respetarán el encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), " si está presente. Ten en cuenta que el valor de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), " está en milisegundos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las notificaciones se volverán a intentar hasta 10 Veces. Estos reintentos se distribuirán durante las próximas 24 horas, con distintos retrasos entre las solicitudes. Las notificaciones individuales serán enviadas de manera aleatoria, para evitar que un gran número de errores concurrentes se vuelva a intentar en el mismo momento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Acciones de workflow de código personalizado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás creando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/custom-code-actions",
        children: "acción de código personalizado"
      }), " en un workflow y una llamada a la API en tu acción falla debido a un error de límite de velocidad o un error ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "5XX"
      }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "axios"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/api-client"
      }), ", HubSpot volverá a intentar ejecutar tu acción durante un máximo de tres días, comenzando un minuto después del error. Las fallas posteriores se volverán a intentar a intervalos cada vez mayores, con un intervalo máximo de ocho horas entre los intentos."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}