export const TOC_CLICK = 'tocClick';
export const LINK_CLICK = 'linkClick';
export const SEARCH = 'search';
export const SEARCH_ORIGINS = {
  STANDARD_SEARCH: 'standard-search',
  DROPDOWN_SEARCH: 'dropdown-search'
};
export const SEARCH_ACTIONS = {
  SUBMITTED: 'search submitted',
  RESULT_SELECTED: 'result selected',
  SEARCH_CLEARED: 'search cleared',
  REDIRECT_TO_RESULTS_PAGE: 'redirect to results page'
};