"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937505;
const slug = exports.slug = 'guides/api/crm/properties';
const title = exports.title = 'API do CRM | Propriedades';
const name = exports.name = 'vNext DP de documentos - Propriedades';
const metaDescription = exports.metaDescription = ' Os pontos de extremidade de propriedades do CRM permitem que você gerencie propriedades personalizadas e veja detalhes das propriedades padrão de qualquer objeto.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "propriedades-padr%C3%A3o",
  "label": "Propriedades padrão",
  "parent": null
}, {
  "depth": 0,
  "id": "grupos-de-propriedades",
  "label": "Grupos de propriedades",
  "parent": null
}, {
  "depth": 0,
  "id": "tipo-de-propriedade-e-valores-fieldtype",
  "label": "Tipo de propriedade e valores fieldType",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-uma-propriedade",
  "label": "Criar uma propriedade",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-propriedades-de-identificador-exclusivo",
  "label": "Criar propriedades de identificador exclusivo",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-propriedades-de-c%C3%A1lculo",
  "label": "Criar propriedades de cálculo",
  "parent": null
}, {
  "depth": 1,
  "id": "sintaxe-da-propriedade-de-c%C3%A1lculo",
  "label": "Sintaxe da propriedade de cálculo",
  "parent": "criar-propriedades-de-c%C3%A1lculo"
}, {
  "depth": 2,
  "id": "sintaxe-literal",
  "label": "Sintaxe literal",
  "parent": "criar-propriedades-de-c%C3%A1lculo"
}, {
  "depth": 2,
  "id": "sintaxe-de-propriedade",
  "label": "Sintaxe de propriedade",
  "parent": "criar-propriedades-de-c%C3%A1lculo"
}, {
  "depth": 2,
  "id": "operadores",
  "label": "Operadores",
  "parent": "criar-propriedades-de-c%C3%A1lculo"
}, {
  "depth": 2,
  "id": "fun%C3%A7%C3%B5es",
  "label": "Funções",
  "parent": "criar-propriedades-de-c%C3%A1lculo"
}, {
  "depth": 2,
  "id": "declara%C3%A7%C3%B5es-condicionais",
  "label": "Declarações condicionais",
  "parent": "criar-propriedades-de-c%C3%A1lculo"
}, {
  "depth": 1,
  "id": "exemplos-de-f%C3%B3rmulas",
  "label": "Exemplos de fórmulas",
  "parent": "criar-propriedades-de-c%C3%A1lculo"
}, {
  "depth": 0,
  "id": "recuperar-propriedades",
  "label": "Recuperar propriedades",
  "parent": null
}, {
  "depth": 0,
  "id": "atualizar-ou-limpar-o-valor-de-uma-propriedade",
  "label": "Atualizar ou limpar o valor de uma propriedade",
  "parent": null
}, {
  "depth": 1,
  "id": "adicionar-valores-%C3%A0s-propriedades-de-tipo-de-caixa-de-sele%C3%A7%C3%A3o",
  "label": "Adicionar valores às propriedades de tipo de caixa de seleção",
  "parent": "atualizar-ou-limpar-o-valor-de-uma-propriedade"
}, {
  "depth": 1,
  "id": "atribuir-propriet%C3%A1rios-de-registos-usando-propriedades-do-usu%C3%A1rio",
  "label": "Atribuir proprietários de registos usando propriedades do usuário",
  "parent": "atualizar-ou-limpar-o-valor-de-uma-propriedade"
}, {
  "depth": 1,
  "id": "limpar-um-valor-de-propriedade",
  "label": "Limpar um valor de propriedade",
  "parent": "atualizar-ou-limpar-o-valor-de-uma-propriedade"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3",
      h4: "h4",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Propriedades"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use propriedades para armazenar informações em registros do CRM. A HubSpot fornece um conjunto de propriedades padrão para cada objeto do CRM e você também pode criar e gerenciar suas próprias propriedades personalizadas", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: " no HubSpot"
      }), " ou usando a API de propriedades."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao criar propriedades, é importante considerar como organizar seus dados. Em muitos casos, a estratégia correta é a criação de propriedades personalizadas para os objetos padrão da HubSpot. No entanto, em alguns casos, talvez você precise criar um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objeto personalizado"
      }), " separado com seu próprio conjunto de propriedades."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriedades padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os objetos de CRM são definidos por um ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "tipo"
      }), " principal e um conjunto de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propriedades"
      }), ". Cada tipo tem um conjunto exclusivo de propriedades padrão, representado por um mapeamento de pares nome-valor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre propriedades padrão para diferentes objetos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "Contatos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "Empresas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "Negócios"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-activity-properties",
          children: "Atividades"
        }), " (Chamadas, E-mails, Reuniões, Observações, Tarefas)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-lead-properties",
          children: "Leads"
        }), "(", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Grupos de propriedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties#create-and-edit-property-groups",
        children: "grupos"
      }), " de propriedades são usados para agrupar propriedades relacionadas. Todas as propriedades agrupadas aparecerão lado a lado nos registros do HubSpot. Se sua integração criar quaisquer propriedades de objeto personalizadas, um grupo de propriedades personalizadas facilitará a identificação desses dados."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipo de propriedade e valores fieldType"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao criar ou atualizar propriedades, os valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " são obrigatórios. O valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " determina o tipo da propriedade, ou seja, uma string ou um número. A propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " determina como a propriedade aparecerá no HubSpot ou em um formulário, ou seja, como um campo de texto simples, um menu suspenso ou um seletor de data."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na tabela abaixo, saiba mais sobre o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " de propriedade disponível e os valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " correspondentes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsxs)(_components.th, {
            children: ["Valores ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fieldType"
            }), " válidos"]
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um campo que contém opções binárias (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Yes"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "No"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), ")."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma string que representa um conjunto de opções separadas por ponto e vírgula."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um valor que representa um dia, mês e ano específicos. Os valores devem ser representados em hora UTC e podem ser formatados como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "strings de ISO 8601 ou registros de data e hora EPOCH em milissegundos (ou seja, meia-noite UTC)."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dateTime"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um valor que representa um dia, mês, ano e horário do dia específicos. Os valores devem ser representados em hora UTC e podem ser formatados como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "strings de ISO 8601 ou registros de data e hora UNIX em milissegundos."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma string de texto simples, com no máximo 65.536 caracteres."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "valor numérico que contém dígitos numéricos e, na maioria das vezes, um número decimal."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object_coordinates"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor de texto usado para referenciar outros objetos do HubSpot, usado apenas para Propriedades internas. As propriedades deste tipo não podem ser criadas ou editadas e não são visíveis no HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor de texto armazenado como JSON formatado, utilizado apenas para propriedades internas. As propriedades deste tipo não podem ser criadas ou editadas e não são visíveis no HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os valores válidos para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " incluem:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma entrada que permite que os usuários selecionem Sim ou Não. Quando usada em um formulário, essa entrada aparecerá como uma única caixa de seleção. Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "adicionar um valor a propriedades de caixa de seleção única"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma equação personalizada que pode calcular valores com base em outros valores de propriedades e/ou associações. Saiba como definir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#create-calculation-properties",
              children: "propriedades de cálculo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista de caixas de seleção que permite que um usuário selecione várias opções em um conjunto de opções válidas para a propriedade. Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "formatar valores ao atualizar propriedades de várias caixas de seleção"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor de data, que é exibido como um seletor de data."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permite que um arquivo seja carregado em um registro ou por meio de um formulário. Armazena um ID de arquivo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma string, renderizada como html corrigido, que permite o uso de um editor de rich text para a propriedade"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma string de numerais ou números escritos em formato decimal ou em notação científica."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma string de texto simples, exibida como um número de telefone formatado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma entrada que permite que os usuários selecionem um conjunto de opções válidas para a propriedade. Quando usada em um formulário, essa entrada é exibida como um conjunto de botões de opção."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma entrada suspensa que permite que os usuários selecionem um conjunto de opções válidas para a propriedade."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma string de texto simples, que é exibida em uma entrada de texto com uma única linha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma string de texto simples, que é exibida como uma entrada de texto com várias linhas."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar uma propriedade"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar uma propriedade, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/{objectType}"
      }), ". No corpo da solicitação, inclua os seguintes campos obrigatórios:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "groupName"
        }), ": o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/organize-and-export-properties",
          children: "grupo de propriedades"
        }), " em que a propriedade estará inserida."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": o nome interno da propriedade (por exemplo, favorite_food)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ": o nome da propriedade tal como aparece no HubSpot (por exemplo, Favorite Food)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "type"
        }), ": o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#type",
          children: "tipo"
        }), " de propriedade."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fieldType"
        }), ": o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-type",
          children: "tipo de campo"
        }), " da propriedade."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, para criar uma propriedade de contato chamada ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Favorite Food"
      }), ", a solicitação seria semelhante à seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body POST crm/v3/properties/contacts\n{\n  \"groupName\": \"contactinformation\",\n  \"name\": \"favorite_food\",\n  \"label\": \"Favorite Food\",\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar propriedades de identificador exclusivo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando um registro é criado no HubSpot, um ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "ID"
      }), " de registro exclusivo (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ") é gerado automaticamente e deve ser tratado como uma string. Esses IDs são exclusivos apenas dentro do tipo de objeto; pode haver um contato e uma empresa com o mesmo ID. Para contatos e empresas, existem identificadores exclusivos adicionais, incluindo o endereço de e-mail (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ") de um contato e o nome de domínio (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ") de uma empresa."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em alguns casos, você deseja criar sua própria propriedade de identificador exclusivo para que não seja possível inserir o mesmo valor para vários registros. Você pode ter até dez propriedades de ID exclusivas por objeto. Para criar uma propriedade que requer valores exclusivos via API:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No corpo da solicitação, para o campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hasUniqueValue"
        }), ", defina o valor como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body\n{\n  \"groupName\": \"dealinformation\",\n  \"name\": \"system_a_unique\",\n  \"label\": \"Unique ID for System A\",\n  \"hasUniqueValue\": true,\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de criar sua propriedade de ID exclusivo, você poderá usá-la em uma chamada de API para recuperar registros específicos. O URL de solicitação pode ser semelhante ao seguinte: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), ". Essa chamada retornará o negócio com o valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "abc"
      }), " no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "system_a_unique"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode usar esse valor de propriedade de identificador exclusivo para identificar e atualizar registros específicos da mesma forma que usaria ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " (contatos) ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " (empresas)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar propriedades de cálculo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As propriedades de cálculo definem um valor de propriedade com base em outras propriedades dentro do mesmo registro de objeto. Elas são definidas usando uma fórmula, que pode incluir operações como mín., máx., contagem, soma ou média. Você pode usar a API de propriedades para ler ou criar propriedades de cálculo na sua conta da HubSpot, usando um tipo de campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculation_equation"
      }), " e um tipo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "number"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bool"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "string"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enumeration"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode definir a fórmula de cálculo da propriedade com o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": as propriedades de cálculo criadas por meio da API ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " podem ser editadas no HubSpot. Elas somente podem ser editadas por meio da API de propriedades."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sintaxe da propriedade de cálculo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), ", você pode escrever sua fórmula com operadores aritméticos, operadores de comparação, operadores lógicos, instruções condicionais e outras funções."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Sintaxe literal"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Literal de string"
        }), ": strings constantes podem ser representadas com aspas simples (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'constant'"
        }), ") ou aspas duplas (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"constant\""
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Literal de número"
        }), ": os números constantes podem ser quaisquer números reais e podem incluir notação de pontos. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1005"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1.5589"
        }), " são números de constante válidos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Literal de booleano"
        }), ": booleanos de constantes podem ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Sintaxe de propriedade"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variáveis de propriedade de string:"
        }), " para uma string de identificador ser interpretada como uma propriedade de string, ela deve ser vinculada à função ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), ". Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string(var1)"
        }), " será interpretada como o valor var1 da propriedade de string."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variáveis de propriedade numérica"
        }), ": todos os identificadores serão interpretados como variáveis de propriedade numérica. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "var1"
        }), " será interpretado como o valor var1 da propriedade numérica."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variáveis de propriedade booleana"
        }), ": para um identificador ser interpretado como uma propriedade bool, ele deve ser vinculado à função ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool"
        }), ". Por exemplo, o identificador ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool(var1)"
        }), " será interpretado como o valor var1 da propriedade booleana."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " o texto usado diferencia maiúsculas de minúsculas para todos os tipos", (0, _jsxRuntime.jsx)("u", {
          children: ", exceto"
        }), " para strings. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "If A ThEn B"
        }), " é exatamente o mesmo que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if a then b"
        }), " mas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'a'"
        }), " não é o mesmo que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'A'"
        }), ". Espaços, tabulações e novas linhas serão usados para tokenização, mas serão ignorados."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Operadores"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os operadores podem ser usados com valores literais e de propriedade. Para operadores aritméticos, você pode usar a notação de prefixo para multiplicar; e parênteses podem ser usados para especificar a ordem das operações."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operador"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplos"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "+"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Some números ou strings."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subtraia números."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100 - property2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Multiplique números."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "10property1"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10 * property1"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Divida números."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 * (100 - property2/(50 - property3))"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se um valor é menor que outro. Compatível com propriedades numéricas ou constantes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a < 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se um valor é maior que outro. Compatível com propriedades numéricas ou constantes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > 50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se um valor é menor ou igual a outro. Compatível com propriedades numéricas ou constantes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a <= b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se um valor é maior ou igual a outro. Compatível com propriedades numéricas ou constantes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b>= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se um valor é igual a outro. Compatível com números e strings."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "(a + b - 100c * 150.652) = 150-230b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "equals"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se um valor é igual a outro. Compatível com números e strings."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a + b - 100.2c * 150 equals 150 - 230"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se um valor não é igual a outro. Compatível com números e strings."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string(property1) != 'test_string'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "or"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se dois valores são verdadeiros."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > b or b <= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "and"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se os valores são verdadeiros."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool(a) and bool(c)"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se nenhum dos valores é verdadeiro."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not (bool(a) and bool(c))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Funções"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As funções a seguir são compatíveis:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Função"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplos"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Terá entre 2 e 100 números de entrada e retornará o número máximo de todas as entradas."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b, c, 100)"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "min"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Terá entre 2 e 100 números de entrada e retornará o número mínimo de todas as entradas."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b, c, 100)"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Avalia se uma expressão pode ser avaliada."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), "= true se a propriedade for booleana, mas ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), " = false se a propriedade estiver vazia ou não for booleana."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tem duas strings como entradas e retornará true se a primeira entrada contiver a segunda."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('hello', 'ello')"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " enquanto ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('ello', 'hello')"
            }), " = false."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ingressa em uma lista de strings. A lista de entradas pode ir de 2 a 100."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate('a', 'b', string(a), string(b))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há também duas funções de análise:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number_to_string"
        }), ": tenta converter a expressão do número de entrada em uma string."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string_to_number"
        }), ": tenta converter a expressão da string de entrada em um número."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + num_cars"
      }), " não é uma propriedade válida, pois você não pode adicionar uma string com um número. A string válida seria ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + number_to_string(num_cars)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Declarações condicionais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode escrever sua fórmula com declarações condicionais, usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, uma declaração condicional pode ser semelhante a seguinte: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if boolean_expression then statement [elseif expression then statement]* [else statement | endif]"
      }), ", onde os colchetes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[a]"
      }), " representam que a é opcional, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a|b"
      }), " representa que a ou b funcionará e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "*"
      }), " significa 0 ou mais. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), " pode ser usado para terminar uma declaração condicional prematuramente, garantindo que o analisador possa identificar de qual ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " o próximo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), " pertence."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemplos de fórmulas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja os seguintes exemplos que podem ser usados para ajudar a definir suas próprias fórmulas de cálculo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"closed - started\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um exemplo mais avançado com condicionais:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"if is_present(hs_latest_sequence_enrolled_date) then\n  if is_present(hs_sequences_actively_enrolled_count) an hs_sequences_actively_enrolled_count >= 1 then\n    true\n  else\n    false\nelse\n  ''\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar propriedades"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "É possível obter informações sobre propriedades individuais ou todas as propriedades de um objeto."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar uma propriedade individual, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/properties/{object}/{propertyName}"
        }), ". Por exemplo, para obter a propriedade Favorite Food, o URL da solicitação seria https://", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "api.hubspot.com/crm/v3/properties/contacts/favorite_food"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para obter todas as propriedades de um objeto, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": ao recuperar todas as propriedades, por padrão, somente propriedades não confidenciais são retornadas. Para recuperar propriedades de dados confidenciais, inclua o parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dataSensitivity"
        }), " com o valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sensitive"
        }), ". Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/sensitive-data#manage-sensitive-data",
          children: "gerenciar dados confidenciais via API"
        }), " (BETA, somente ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizar ou limpar o valor de uma propriedade"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para atualizar um valor de propriedade para um registro, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/{objectType}/{recordId}"
      }), ". No corpo da solicitação, inclua as propriedades e os respectivos valores em uma matriz. Saiba mais sobre como atualizar registros através das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "APIs de objeto"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Adicionar valores às propriedades de tipo de caixa de seleção"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao atualizar valores para propriedades de tipo de caixa de seleção de um registro, formate os valores da seguinte maneira:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Propriedade de caixa de seleção"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Booleano"
        }), ": para ser exibido como ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sim"
        }), " ou aparecer como selecionado no HubSpot, seu valor deve ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), ". Para ser exibido como ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Não"
        }), " ou aparecer como não marcado no HubSpot, seu valor deve ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Propriedade de várias caixas de"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "seleção"
        }), ": para adicionar ou anexar valores a uma propriedade de várias caixas de seleção, adicione um ponto e vírgula antes do primeiro valor e inclua um ponto e vírgula entre cada valor. Se a propriedade tiver um valor existente, o ponto e vírgula inicial adicionará os valores em vez de sobrescrevê-los. Por exemplo, um contato tem o valor existente ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DECISION_MAKER"
        }), " para a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_buying_role"
        }), ". Para adicionar valores sem substituir o valor existente, sua solicitação seria assim:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example body for PATCH request to /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_buying_role\": \";BUDGET_HOLDER;END_USER\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Atribuir proprietários de registos usando propriedades do usuário"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao atribuir usuários a registros do CRM via API, o valor deve ser o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de proprietário do usuário, que você pode encontrar nas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: "configurações da propriedade"
      }), " ou por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "API de proprietários"
      }), ". Por exemplo, para atribuir um usuário como proprietário de um contato, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/contacts/{contactId}"
      }), ", com o corpo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\":{ \"hubspot_owner_id\": \"41629779\"}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Limpar um valor de propriedade"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode apagar o valor da propriedade de um objeto por meio da API configurando o valor da propriedade como uma string vazia."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, para limpar o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), " de um objeto de contato, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/contacts/{contactId}"
      }), ", com o corpo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\": { \"firstname\": \"\"}}"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}