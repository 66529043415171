"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 48816364594;
const slug = exports.slug = 'guides/cms/content/fields/brand-and-settings-inheritance';
const title = exports.title = 'Vererbung von Markeneinstellungen';
const name = exports.name = 'Vererbung von Markeneinstellungen';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie Markeneinstellungen wie Logos und Markenfarben innerhalb eines Designs oder in HubL/HTML- und CSS-Dateien verwenden können. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variablen-f%C3%BCr-markeneinstellungen",
  "label": "Variablen für Markeneinstellungen",
  "parent": null
}, {
  "depth": 1,
  "id": "farben",
  "label": "Farben",
  "parent": "variablen-f%C3%BCr-markeneinstellungen"
}, {
  "depth": 1,
  "id": "logos",
  "label": "Logos",
  "parent": "variablen-f%C3%BCr-markeneinstellungen"
}, {
  "depth": 1,
  "id": "favicons",
  "label": "Favicons",
  "parent": "variablen-f%C3%BCr-markeneinstellungen"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Vererbung von Markeneinstellungen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/customize-branding-for-your-hubspot-content#customize-your-company-logo-and-colors",
        children: "Markeneinstellungen"
      }), " können Benutzer festlegen, dass die Markenfarben, -Logos und -Favicons des Unternehmens überall in HubSpot verwendet werden. Dadurch können Sie mit Token in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei eines Designs und in HTML/HubL- und CSS-Dateien auf diese Markeneinstellungen zugreifen. Sie können auch auf Markenfarben innerhalb der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei eines Moduls zugreifen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nach dem Hinzufügen dieser Token in einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei können Content-Autoren ihre Werte im Editor für Designeinstellungen bearbeiten. Wenn Sie diese Token in einem HTML-, HubL- oder CSS-Code hinzufügen, werden die Werte fest kodiert und können im Seiten-Editor von Content-Autoren nicht geändert werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über die verfügbaren Variablen für Markeneinstellungen sowie Beispiele für die Umsetzung."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variablen für Markeneinstellungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Folgenden finden Sie eine Liste der Optionen, auf die Sie in den Markeneinstellungen innerhalb des Werts des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), "-Objekts oder in HTML/HubL- und CSS-Dateien zugreifen können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Farben"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf Markenfarben kann sowohl innerhalb der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json-color",
        children: "fields.json"
      }), "-Datei eines Designs oder Moduls als auch innerhalb von HTML/HubL- und CSS-Dateien mithilfe der folgenden HubL-Token zugegriffen werden:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Primär:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.primaryColor}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[0]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-primary.png",
                alt: "brand-colors-primary"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sekundär:"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{brand_settings.secondaryColor}}"
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-secondary.png",
            alt: "brand-colors-secondary"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Akzentfarben:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor1}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor2}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor3}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-accent.png",
                alt: "brand-colors-accent"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Weitere Farben:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[1]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[2]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[3]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-additional.png",
                alt: "brand-colors-additional"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um direkt auf den Hex-Code einer Farbe zuzugreifen, berücksichtigen Sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hex"
      }), "-Filter im Token. Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryColor.hex}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die Farben von Markeneinstellungen in die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei eines Designs oder Moduls aufzunehmen, verwenden Sie das folgende Format:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example of using the primary color in within a theme's\n// field.json file\n{\n  \"name\": \"branding_color\",\n  \"label\": \"branding_color\",\n  \"type\": \"color\",\n  \"default\": {\n    \"color\": \"#26ff55\",\n    \"opacity\": 60\n  },\n  \"inherited_value\": {\n    \"property_value_paths\": {\n      \"color\": \"brand_settings.primaryColor\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Es kann vorkommen, dass Acounts möglicherweise keine zusätzlichen Farben in ihren Markeneinstellungen konfiguriert haben. Wenn Ihr Code auf eine vererbte Farbe verweist, die in den Markeneinstellungen nicht vorhanden ist, wird die folgende Fallback-Logik verwendet:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "secondaryColor"
          }), " greift auf die erste zusätzliche Farbe (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[1]"
          }), ") zurück."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor1"
          }), " greift auf die zweite zusätzliche Farbe (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[2]"
          }), ") zurück."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor2"
          }), " greift auf die dritte zusätzliche Farbe (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), ") zurück."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor3"
          }), " greift auf die vierte zusätzliche Farbe (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[4]"
          }), ") zurück."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Zusätzliche Farben (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), ") greifen auf den ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "default"
          }), "-Wert zurück. Wenn keine Standardfarbe für die Eigenschaft festgelegt wurde, wird ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "primaryColor"
          }), " verwendet."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Logos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf Markenlogos kann in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei in einem Modul sowie in HTML/HubL- und CSS-Dateien zugegriffen werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können die folgenden Token verwenden, um auf das primäre Logo zuzugreifen, das in den Markeneinstellungen festgelegt wurde:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.logos[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-logo0.png",
        alt: "brand-settings-logo0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle zusätzlichen Logos können mithilfe von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.logos[1-19]}}"
      }), " aufgerufen werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Darüber hinaus können Sie auf die folgenden Logo-Attribute zugreifen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logo-URL:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.link}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logo-ALT-Text:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.alt}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logo-Höhe:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.height}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logo-Breite:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.width}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Link zum Bild des Logos:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.src}}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Favicons"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Auf Marken-Favicons kann nur innerhalb von HTML/HubL- und CSS-Dateien zugegriffen werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können die folgenden Token verwenden, um auf das primäre Logo zuzugreifen, das in den Markeneinstellungen festgelegt wurde:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryFavicon}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.favicons[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-favicon0.png",
        alt: "brand-settings-favicon0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle zusätzlichen Favicons können mithilfe von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.favicons[1-19]}}"
      }), " aufgerufen werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können direkt auf die URL des Logos zugreifen, indem Sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), "-Filter berücksichtigen. Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryFavicon.src}}"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}