"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50633310860;
const slug = exports.slug = 'guides/cms/setup/github-integration';
const title = exports.title = 'GitHubアクションを使用してGitHubリポジトリーでのCI（継続的インテグレーション）を設定する';
const name = exports.name = 'APAC JAPAN (JA) | [new] Setting up continuous integration with a GitHub repository using GitHub Actions | 202108';
const metaDescription = exports.metaDescription = 'GitHubアクションを使用してGitHubリポジトリーでのCI（継続的インテグレーション）を設定する。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92github%E3%81%AB%E9%80%81%E4%BF%A1%E3%81%99%E3%82%8B",
  "label": "ローカルファイルをGitHubに送信する",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-cms%E3%83%87%E3%83%97%E3%83%AD%E3%82%A4github%E3%82%A2%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%EF%BC%88%E6%8E%A8%E5%A5%A8%EF%BC%89",
  "label": "HubSpot CMSデプロイGitHubアクションを使用する（推奨）",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AB%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%97%E3%81%A6%E3%83%A1%E3%82%A4%E3%83%B3%E3%81%AB%E3%83%9E%E3%83%BC%E3%82%B8%E3%81%99%E3%82%8B",
  "label": "プルリクエストを作成してメインにマージする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%81%A7%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%82%92%E3%83%AD%E3%83%83%E3%82%AF%E3%81%99%E3%82%8B",
  "label": "デザインマネージャーでアセットをロックする",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "GitHubアクションを使用してGitHubリポジトリーでのCI（継続的インテグレーション）を設定する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "開発ワークフロー"
      }), "の一環として、本番コードベース全体をバージョン管理するのが適切な場合があります。開発チームの一員として働いている場合、変更を追跡し、必要に応じて素早くロールバックできるようにするには、これが特に役立つでしょう。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/features/actions",
        children: "GitHubアクション"
      }), "を使用して、GitHubリポジトリーとのCIを設定できます。このガイドでは、読者が次の事柄に精通していることを前提として、連携プロセスの概要を説明します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.github.com/en/get-started/using-git",
          children: "Git"
        }), "とGitHubの使用"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI"
        }), "を使用してウェブサイトを構築する"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下では、HubSpot CMSデプロイGitHubアクションの使用（推奨される方法）または手動によって連携をセットアップする方法を説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ローカルファイルをGitHubに送信する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "GitHubと連携するには、その前に、まずローカルにファイルを収集する必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマやテンプレートのセットなど、既存のCMSアセットがHubSpotに存在する場合は、次の", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#fetch",
          children: "fetch"
        }), "コマンドを実行してそれを取得できます：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch<HubSpot_src> <local_dest>"
        }), "または、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch /"
        }), "を実行して、アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/key-concepts#developer-file-system",
          children: "デベロッパー ファイル システム"
        }), "内の全てのファイルをダウンロードすることもできます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["新しいローカルプロジェクトを作成するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "CMSテーマボイラープレート"
        }), "から始めることをお勧めします。CMSテーマボイラープレートを使用したことがない場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "クイックスタートガイド"
        }), "をご覧ください。すでにHubSpot CLIのインストールとローカル環境の設定が済んでいる場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs create website-theme <new-theme-name>"
        }), "を実行して、ボイラープレートから新しいローカルテーマを作成することができます。その後、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#upload",
          children: "hs upload"
        }), "コマンドを使用してファイルをHubSpotにアップロードする必要があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカルでコードが利用可能になったら、それを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.github.com/en/get-started/importing-your-projects-to-github/importing-source-code-to-github/adding-locally-hosted-code-to-github",
        children: "GitHubリポジトリーに追加"
      }), "します。ファイルをGitHubに追加した後、次のステップに進んでHubSpotの既成のGitHubアクションをインストールするか（推奨される方法）、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manually-configure-the-action",
        children: "手動でアクションを設定"
      }), "します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot CMSデプロイGitHubアクションを使用する（推奨）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロセスの合理化のためにHubSpotが作成したGitHubアクションをGitHubプロジェクトにインストールすると、ブランチから本番HubSpotアカウントに変更内容が自動的にデプロイされるようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "e0132707-395d-4617-bd9d-0b21c8b129d5",
      external: true,
      label: "Install automatic CMS deploy GitHub action"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プルリクエストを作成してメインにマージする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "これでGitHubリポジトリーの中にシークレット、ワークフロー、スクリプトが用意されたので、次にプルリクエストを作成し、それをメインにマージします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プルリクエストをマージした後、［アクション］に移動します。デプロイアクション実行が表示され、コードがHubSpotアカウントにデプロイされます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "デザインマネージャーでアセットをロックする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "信頼できる情報源がGitHubに存在するようになったので、HubSpotで編集が行われないようにアセットをロックする必要があります。これにより、デプロイアクションを通してのみ変更が行われるようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーでアセットをロックするには："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、［マーケティング］＞［ファイルとテンプレート］＞［デザインツール］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["左側のサイドバーでアセットのフォルダーを見つけ、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "右クリック"
        }), "して［フォルダーをロック］を選択します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-lock-folder.png",
        alt: "design-manager-lock-folder"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}