/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

'use es6';

import memoizeOne from 'react-utils/memoizeOne';
import { findSchemaDefinition } from '../../ParamForm/utils/schemaUtils';
import { LinkedList } from './linked-list';
import { stringify } from 'hub-http/helpers/params';
import { WWW_FORM_CONTENT_TYPE } from '../constants';
function isFunc(thing) {
  return typeof thing === 'function';
}
function normalizeArray(arr) {
  if (Array.isArray(arr)) return arr;
  return [arr];
}

// Deeply strips a specific key from an object.
//
// `predicate` can be used to discriminate the stripping further,
// by preserving the key's place in the object based on its value.
function deeplyStripKey(input, keyToStrip, predicate = () => true) {
  if (typeof input !== 'object' || Array.isArray(input) || input === null || !keyToStrip) {
    return input;
  }
  const obj = Object.assign({}, input);
  Object.keys(obj).forEach(k => {
    if (k === keyToStrip && predicate(obj[k], k)) {
      delete obj[k];
      return;
    }
    obj[k] = deeplyStripKey(obj[k], keyToStrip, predicate);
  });
  return obj;
}
const primitives = {
  string: () => 'string',
  string_email: () => 'user@example.com',
  'string_date-time': () => new Date().toISOString(),
  string_date: () => new Date().toISOString().substring(0, 10),
  string_uuid: () => '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  string_hostname: () => 'example.com',
  string_ipv4: () => '198.51.100.42',
  string_ipv6: () => '2001:0db8:5b96:0000:0000:426f:8e17:642a',
  number: () => 0,
  number_float: () => 0.0,
  integer: () => 0,
  boolean: schema => typeof schema.default === 'boolean' ? schema.default : true
};
const primitive = schema => {
  const {
    type,
    format
  } = schema;
  const fn = primitives[`${type}_${format}`] || primitives[type];
  if (isFunc(fn)) return fn(schema);
  return `Unknown Type: ${schema.type}`;
};
export const sampleFromSchema = (schema, oas, visited, config = {}) => {
  const {
    maxCircularReferences
  } = config;
  if (maxCircularReferences !== undefined && maxCircularReferences < 1) {
    throw new Error('maxCircularReferences must be greater than 0');
  }
  const schemaIsARef = schema.$ref !== undefined ||
  /* schema.$$ref tells where the value came from once everything is resolved,
    Also checks to see if the schema referenced a model that was already resolved
  */
  schema.$$ref !== undefined || schema.items && schema.items.$ref !== undefined;
  if (schema.$ref) {
    // Avoid circular references by tracking visited refs and terminating
    // schema traversal when the number of circular references passes maxCircularReferences
    if (maxCircularReferences !== undefined) {
      visited.insertFirst(schema.$ref);
      if (visited.size > maxCircularReferences && visited.filter(ref => ref === schema.$ref).length > maxCircularReferences) {
        return null;
      }
    }
    schema = findSchemaDefinition(schema.$ref, oas);
  }
  let usePlainValue = schema.example !== undefined || schema.default !== undefined;
  // first check if there is the need of combining this schema with others required by allOf
  const hasOneOf = !usePlainValue && schema && schema.oneOf && schema.oneOf.length > 0;
  const hasAnyOf = !usePlainValue && schema && schema.anyOf && schema.anyOf.length > 0;
  if (!usePlainValue && (hasOneOf || hasAnyOf)) {
    let schemaToAdd = hasOneOf ? schema.oneOf[0] : schema.anyOf[0];
    // Retry lookup of nested schema if it isn't attached properly
    if (schemaToAdd.properties === undefined) {
      schemaToAdd = findSchemaDefinition(schemaToAdd.$ref, oas);
    }
    if (schema.example !== undefined && schemaToAdd.example !== undefined) {
      usePlainValue = true;
    } else if (schemaToAdd.properties) {
      if (!schema.properties) {
        schema.properties = {};
      }
      const props = schemaToAdd.properties;
      for (const propName in props) {
        if (!props.hasOwnProperty(propName)) {
          continue;
        }
        if (props[propName] && props[propName].deprecated) {
          continue;
        }
        if (props[propName] && props[propName].readOnly && !config.includeReadOnly) {
          continue;
        }
        if (props[propName] && props[propName].writeOnly && !config.includeWriteOnly) {
          continue;
        }
        if (!schema.properties[propName]) {
          schema.properties[propName] = props[propName];
          if (!schemaToAdd.required && Array.isArray(schemaToAdd.required) && schemaToAdd.required.indexOf(propName) !== -1) {
            if (!schema.required) {
              schema.required = [propName];
            } else {
              schema.required.push(propName);
            }
          }
        }
      }
    }
  }
  let {
    type
  } = schema;
  const {
    example,
    properties,
    additionalProperties,
    items
  } = schema;
  const {
    includeReadOnly,
    includeWriteOnly
  } = config;
  const shouldUseExample = example !== undefined && !schemaIsARef;

  // If the example is on the model and using property level examples, don't use the model example.  Instead, use the examples from the properties.
  if (shouldUseExample) {
    return deeplyStripKey(example, '$$ref', val => {
      // do a couple of quick sanity tests to ensure the value
      // looks like a $$ref that swagger-client generates.
      return typeof val === 'string' && val.indexOf('#') > -1;
    });
  }

  // try recover missing type
  if (!type) {
    if (properties) {
      type = 'object';
    } else if (items) {
      type = 'array';
    } else if (!usePlainValue) {
      return undefined;
    }
  }
  if (type === 'object') {
    const props = properties;
    const obj = {};
    for (const name in props) {
      if (props[name] && props[name].deprecated) {
        continue;
      }
      if (props[name] && props[name].readOnly && !includeReadOnly) {
        continue;
      }
      if (props[name] && props[name].writeOnly && !includeWriteOnly) {
        continue;
      }
      obj[name] = sampleFromSchema(props[name], oas, visited, config);
    }
    if (additionalProperties === true) {
      obj.additionalProp1 = {};
    } else if (additionalProperties) {
      const additionalProps = additionalProperties;
      const additionalPropVal = sampleFromSchema(additionalProps, oas, visited, config);
      for (let i = 1; i < 4; i++) {
        obj[`additionalProp${i}`] = additionalPropVal;
      }
    }
    return obj;
  }
  if (type === 'array' && !!items) {
    if (Array.isArray(items.anyOf)) {
      return items.anyOf.map(i => sampleFromSchema(i, oas, visited, config));
    }
    if (Array.isArray(items.oneOf)) {
      return [];
    }
    const sample = sampleFromSchema(items, oas, visited, config);
    return sample ? [sample] : [];
  }
  if (schema['enum']) {
    if (schema['default']) return schema['default'];
    return normalizeArray(schema['enum'])[0];
  }
  if (type === 'file') {
    return null;
  }
  return primitive(schema);
};
export const memoizedSampleFromSchema = memoizeOne(sampleFromSchema);
export default function getSampleSchema(schema, oas, contentType = '', config = {}) {
  if (/xml/.test(contentType)) {
    throw new Error('XML is not yet supported');
  }
  const {
    noPrettyPrint
  } = config;
  const res = memoizedSampleFromSchema(schema, oas, new LinkedList(), config);
  if (contentType === WWW_FORM_CONTENT_TYPE) {
    // The x-www-form-urlencoded content type is url encoded, not JSON encoded
    return stringify(res);
  }
  if (typeof res !== 'object' || config.noStringify) {
    return res;
  }
  return noPrettyPrint ? JSON.stringify(res) : JSON.stringify(res, null, 2);
}