'use es6';

import { groupBy } from 'underscore';

// const body = `
// \`\`\`javascript
// var a = '<<apiKey>>';
// var b = "<<apiKey>>";
// \`\`\`
// This is your <<apiKey>>.

// [block:code]
// {
//   "codes": [
//     {
//       "code": "<p>test</p>",
//       "language": "html"
//     },
//     {
//       "code": "var a = '<<apiKey>>';",
//       "language": "javascript"
//     },
//     {
//       "code": "& < > ' /",
//       "language": "text"
//     }
//   ]
// }
// [/block]

// testing
// correctnewlines
// option
// `;

export default ((oas, apiSetting) => {
  const docs = [];
  Object.keys(oas.paths).map(path => {
    return Object.keys(oas.paths[path]).forEach(method => {
      const operation = oas.paths[path][method];
      let isCategory;
      const tag = operation.tags && operation.tags.length ? operation.tags[0] : path;
      if (!docs.find(category => category.slug === tag && category.type === 'basic')) {
        docs.push({
          _id: Math.random().toString(16),
          title: operation.summary || path || tag,
          slug: tag,
          type: 'endpoint',
          category: {
            apiSetting
          },
          api: {
            method
            // Uncomment this if you want to test custom code samples
            // examples: {
            //   codes: [
            //     {
            //       language: 'javascript',
            //       code: 'console.log(1);',
            //     },
            //     {
            //       language: 'curl',
            //       code: 'curl http://example.com',
            //     },
            //   ],
            // },
          },
          swagger: {
            path
          },
          excerpt: operation.description
          // Uncomment this if you want some body content blocks
          // body,
        });
        isCategory = true;
      }
      if (!isCategory) {
        docs.push({
          _id: Math.random().toString(16),
          title: operation.summary || path || tag,
          slug: operation.operationId,
          type: 'endpoint',
          category: {
            apiSetting
          },
          api: {
            method
          },
          swagger: {
            path
          },
          body: '',
          excerpt: operation.description
        });
      }
    });
  });
  const endpointGroups = groupBy(docs, e => e.slug);
  const keys = Object.keys(endpointGroups);
  const sortedDocs = [];
  const hasGroupTags = docs[0].slug !== docs[0].swagger.path;
  const sortEndpoints = (a, b) => a.title.localeCompare(b.title);
  if (hasGroupTags) {
    keys.forEach(key => {
      const endpoints = endpointGroups[key];
      const groupedMethods = groupBy(endpoints, e => e.api.method);
      if (groupedMethods.get) {
        groupedMethods.get.sort(sortEndpoints);
        sortedDocs.push(groupedMethods.get);
      }
      if (groupedMethods.post) {
        groupedMethods.post.sort(sortEndpoints);
        sortedDocs.push(groupedMethods.post);
      }
      if (groupedMethods.put) {
        groupedMethods.put.sort(sortEndpoints);
        sortedDocs.push(groupedMethods.put);
      }
      if (groupedMethods.patch) {
        groupedMethods.patch.sort(sortEndpoints);
        sortedDocs.push(groupedMethods.patch);
      }
      if (groupedMethods.delete) {
        groupedMethods.delete.sort(sortEndpoints);
        sortedDocs.push(groupedMethods.delete);
      }
    });
  } else {
    // When we don't have tags to group endpoints by, remove all grouping.
    const groupedMethods = groupBy(docs, e => e.api.method);
    if (groupedMethods.get) {
      groupedMethods.get.sort(sortEndpoints);
      sortedDocs.push(groupedMethods.get);
    }
    if (groupedMethods.post) {
      groupedMethods.post.sort(sortEndpoints);
      sortedDocs.push(groupedMethods.post);
    }
    if (groupedMethods.put) {
      groupedMethods.put.sort(sortEndpoints);
      sortedDocs.push(groupedMethods.put);
    }
    if (groupedMethods.patch) {
      groupedMethods.patch.sort(sortEndpoints);
      sortedDocs.push(groupedMethods.patch);
    }
    if (groupedMethods.delete) {
      groupedMethods.delete.sort(sortEndpoints);
      sortedDocs.push(groupedMethods.delete);
    }
  }
  return sortedDocs.flat();
});