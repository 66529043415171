"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093147;
const slug = exports.slug = 'guides/cms/setup/github-integration';
const title = exports.title = 'Configurar a integração contínua com um repositório GitHub usando as Ações do GitHub';
const name = exports.name = 'LATAM BR (PT) Setting up continuous integration with a GitHub repository using GitHub Actions';
const metaDescription = exports.metaDescription = 'Configure a integração contínua com um repositório GitHub usando as Ações do GitHub.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "enviar-arquivos-locais-para-o-github",
  "label": "Enviar arquivos locais para o GitHub",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-a-a%C3%A7%C3%A3o-do-github-implantar-para-o-hubspot-cms-recomendado",
  "label": "Usar a ação do GitHub Implantar para o HubSpot CMS (recomendado)",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-e-mesclar-uma-solicita%C3%A7%C3%A3o-pull-com-o-principal",
  "label": "Criar e mesclar uma solicitação pull com o principal",
  "parent": null
}, {
  "depth": 0,
  "id": "bloquear-seu-ativo-no-gerenciador-de-design",
  "label": "Bloquear seu ativo no gerenciador de design",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configurar a integração contínua com um repositório GitHub usando as Ações do GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como parte do seu fluxo de trabalho de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/guides/creating-an-efficient-development-workflow",
        children: "desenvolvimento"
      }), ", você pode preferir manter o código-fonte da sua versão de produção como referência no controle de versão. Isso é especialmente útil se você faz parte de uma equipe de desenvolvimento, pois pode acompanhar as alterações e revertê-las rapidamente, se necessário."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/features/actions",
        children: "Ações do GitHub"
      }), ", você pode configurar uma integração contínua com um repositório do GitHub. Este guia explica o processo de integração e pressupõe que você esteja familiarizado com:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.github.com/en/get-started/using-git",
          children: "Como usar o Git"
        }), " e o GitHub"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Criação de sites usando a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/guides/getting-started-with-local-development",
          children: "CLI do HubSpot"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba como configurar a integração usando a ação do GitHub Implantar para o HubSpot CMS (recomendado) ou manualmente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Enviar arquivos locais para o GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de fazer a integração do GitHub, primeiro você precisará reunir seus arquivos localmente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você tiver um ativo CMS existente no HubSpot, como um tema ou conjunto de modelos, poderá buscá-lo executando o comando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cli#fetch",
          children: "fetch"
        }), " da seguinte maneira: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch <HubSpot_src> <local_dest>"
        }), ". Como alternativa, você pode baixar todos os arquivos no ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview#developer-file-system",
          children: "sistema de arquivos do desenvolvedor"
        }), " da conta executando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch /"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para criar um novo projeto local, é recomendado começar com o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/themes/hubspot-cms-boilerplate",
          children: "boilerplate de temas do CMS"
        }), ". Se você nunca usou um modelo de tema do CMS, confira o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/guides/getting-started",
          children: "guia de início rápido"
        }), ". Se você já instalou a CLI da HubSpot e configurou seu ambiente local, poderá criar um novo tema local a partir do boilerplate executando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs create website-theme <new-theme-name>"
        }), ". Você precisará enviar seus arquivos para o HubSpot com o comando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cli#upload",
          children: "hs upload"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com o código disponível localmente, você o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.github.com/en/get-started/importing-your-projects-to-github/importing-source-code-to-github/adding-locally-hosted-code-to-github",
        children: "adicionará a um repositório GitHub"
      }), ". Depois de adicionar seus arquivos ao GitHub, siga para a próxima etapa para instalar a Ação do GitHub criada pelo HubSpot (recomendado) ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manually-configure-the-action",
        children: "configure a Ação manualmente"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar a ação do GitHub Implantar para o HubSpot CMS (recomendado)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para agilizar o processo, a HubSpot criou uma Ação do GitHub que você pode instalar no seu projeto GitHub para executar a implantação automática das alterações de uma ramificação na sua conta de produção da HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "e0132707-395d-4617-bd9d-0b21c8b129d5",
      external: true,
      label: "Install automatic CMS deploy GitHub action"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar e mesclar uma solicitação pull com o principal"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Agora que seus segredos, fluxos de trabalho e scripts estão no repositório GitHub, crie uma solicitação pull e mescle-a com a principal."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Depois de mesclar a solicitação pull, vá para ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ações"
        }), ". Sua Ação de implantação deve ser executada, o que implantará o código na sua conta da HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bloquear seu ativo no gerenciador de design"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agora que o código-fonte está no GitHub, você deve bloquear o ativo no HubSpot para evitar que as edições sejam feitas lá. Isso garante que as alterações somente ocorram por meio da ação de implantação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para bloquear os ativos no gerenciador de design:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Arquivos e modelos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ferramentas de design"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Localize a pasta do ativo na barra lateral esquerda, clique ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "com o botão direito do mouse"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bloquear pasta"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-lock-folder.png",
        alt: "design-manager-lock-folder"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}