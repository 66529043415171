'use es6';

import { AuthTypes, AuthTypesWithVersions } from '../../modules/TryIt/constants';
function harValue(type, value) {
  if (!value.value) return undefined;
  return {
    type,
    value
  };
}
export default function configureSecurity(oas, values, scheme) {
  if (!scheme) return {};
  if (!oas.components.securitySchemes || !oas.components.securitySchemes[scheme]) {
    return undefined;
  }
  const security = oas.components.securitySchemes[scheme];
  if (security.type === 'oauth2' && (values.authType === AuthTypes.OAUTH_TOKEN || values.authType === AuthTypesWithVersions.LEGACY_OAUTH_TOKEN)) {
    if (!values[scheme]) return false;
    return harValue('headers', {
      name: 'Authorization',
      value: `Bearer ${values[scheme]}`
    });
  } else if (security.type === 'oauth2' && Object.keys(values || {}).length === 0) {
    // Add a default value for authorization tokens if none exist
    return harValue('headers', {
      name: 'Authorization',
      value: `Bearer YOUR_ACCESS_TOKEN`
    });
  }

  /*
    Though security.type is apiKey, we are treating private app
    code snippets similar to oauth. It's a limitation of Swagger
    to not be able to make the security type 'oauth' without things
    that don't apply like auth flow
  */
  if (security.type === 'apiKey' && (values.authType === AuthTypes.PRIVATE_APPS || values.authType === AuthTypesWithVersions.LEGACY_PRIVATE_APPS)) {
    if (!values[scheme]) return false;
    return harValue('headers', {
      name: 'Authorization',
      value: `Bearer ${values[scheme]}`
    });
  } else if ( /* eslint-disable-next-line no-dupe-else-if */
  security.type === 'apiKey' && (values.authType === AuthTypes.PRIVATE_APPS || values.authType === AuthTypesWithVersions.LEGACY_PRIVATE_APPS) && Object.keys(values || {}).length === 0) {
    // Add a default value for authorization tokens if none exist
    return harValue('headers', {
      name: 'Authorization',
      value: `Bearer YOUR_ACCESS_TOKEN`
    });
  }
  if (Object.keys(values || {}).length === 0) return undefined;
  if (security.type === 'http') {
    if (security.scheme === 'basic') {
      // Return with no header if user and password are blank
      if (!values[scheme].user && !values[scheme].pass) return false;
      return harValue('headers', {
        name: 'Authorization',
        value: `Basic ${btoa(`${values[scheme].user}:${values[scheme].pass}`)}`
      });
    }
    if (security.scheme === 'bearer') {
      if (!values[scheme]) return false;
      return harValue('headers', {
        name: 'Authorization',
        value: `Bearer ${values[scheme]}`
      });
    }
  }
  if (security.type === 'apiKey' && values.authType === AuthTypes.DEVELOPER_API_KEY) {
    if (security.in === 'query') {
      return harValue('queryString', {
        name: security.name,
        value: values[scheme]
      });
    }
    if (security.in === 'header') {
      const header = {
        name: security.name,
        value: values[scheme]
      };
      if (security['x-bearer-format']) {
        // Uppercase: token -> Token
        const bearerFormat = security['x-bearer-format'].charAt(0).toUpperCase() + security['x-bearer-format'].slice(1);
        header.name = security.name;
        header.value = `${bearerFormat} ${header.value}`;
      }
      return harValue('headers', header);
    }
  }
  return undefined;
}