"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890244;
const slug = exports.slug = 'reference/cms/hubl/overview';
const title = exports.title = 'Syntaxe HubL';
const name = exports.name = 'EMEA (FR) HubL Syntax';
const metaDescription = exports.metaDescription = 'HubL est le langage de modélisation inspiré de Jinja utilisé pour créer des modèles et des modules sur HubSpot CMS. Les fonctions SI, les boucles for, les inclusions, etc.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "types-de-s%C3%A9parateurs",
  "label": "Types de séparateurs",
  "parent": null
}, {
  "depth": 1,
  "id": "d%C3%A9clarations",
  "label": "Déclarations",
  "parent": "types-de-s%C3%A9parateurs"
}, {
  "depth": 1,
  "id": "expressions",
  "label": "Expressions",
  "parent": "types-de-s%C3%A9parateurs"
}, {
  "depth": 2,
  "id": "balise-do",
  "label": "Balise do",
  "parent": "types-de-s%C3%A9parateurs"
}, {
  "depth": 1,
  "id": "commentaires",
  "label": "Commentaires",
  "parent": "types-de-s%C3%A9parateurs"
}, {
  "depth": 0,
  "id": "modules",
  "label": "Modules",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-et-macros",
  "label": "Variables et macros",
  "parent": null
}, {
  "depth": 0,
  "id": "filtres-et-fonctions",
  "label": "Filtres et fonctions",
  "parent": null
}, {
  "depth": 0,
  "id": "fonctions-si",
  "label": "Fonctions SI",
  "parent": null
}, {
  "depth": 0,
  "id": "boucles-for",
  "label": "Boucles for",
  "parent": null
}, {
  "depth": 0,
  "id": "autres-fonctionnalit%C3%A9s-de-hubl",
  "label": "Autres fonctionnalités de HubL",
  "parent": null
}, {
  "depth": 1,
  "id": "%C3%A9chapper-les-s%C3%A9parateurs-hubl",
  "label": "Échapper les séparateurs HubL",
  "parent": "autres-fonctionnalit%C3%A9s-de-hubl"
}, {
  "depth": 1,
  "id": "inclure-des-fichiers-dans-des-fichiers",
  "label": "Inclure des fichiers dans des fichiers",
  "parent": "autres-fonctionnalit%C3%A9s-de-hubl"
}, {
  "depth": 1,
  "id": "blocs-et-extensions",
  "label": "Blocs et extensions",
  "parent": "autres-fonctionnalit%C3%A9s-de-hubl"
}, {
  "depth": 1,
  "id": "copie-de-section-hubl",
  "label": "Copie de section HubL",
  "parent": "autres-fonctionnalit%C3%A9s-de-hubl"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      pre: "pre",
      code: "code",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Présentation de la syntaxe HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot CMS utilise le langage de balisage HubSpot, appelé HubL (prononcer « Hubble »). HubL est l'extension de HubSpot de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), ", un moteur de modèles basé sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jinja.palletsprojects.com/en/latest/",
        children: "Jinja"
      }), ". HubL utilise une quantité considérable de balisages propres à HubSpot et ne prend pas en charge toutes les fonctionnalités de Jinja."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cet article vous permettra de découvrir les bases des fonctionnalités et de la syntaxe de HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types de séparateurs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Comme d'autres langages de création de modèles couramment utilisés, tels que PHP, HubL peut être intégré à votre HTML dans des fichiers de modèles codés ou des modules de modèles HubL. Afin de différencier le début et la fin de votre HubL, vous devez apprendre quelques symboles clés qui servent de séparateurs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% %} - statement delimiters\n{{ }} - expression delimiters\n{# #} - comment delimiters\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Faites attention aux commentaires imbriqués dans votre code, car cela peut entraîner le rendu de la balise de commentaire de fin sous forme de texte."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Déclarations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les déclarations HubL sont utilisées pour créer des modules éditables, définir une logique de modèle conditionnel, mettre en place des boucles, définir des variables, etc. Les déclarations sont délimitées par ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{%"
      }), ". Ils n'impriment rien sur la page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Expressions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les expressions impriment les valeurs stockées dans le contexte du modèle. Les expressions sont délimitées par ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{"
      }), ". Par exemple, une variable doit être définie en tant qu'instruction, mais une expression HubL serait ensuite utilisée pour imprimer la variable."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Balise do"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La balise 'do' fonctionne exactement comme les instructions ordinaires ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% ... %}"
      }), " ; elle peut être utilisée pour modifier des listes et des dictionnaires."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Remarque : Lorsque vous ajoutez à des tableaux, utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#append",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".append()"
        })
      }), " et lorsque vous ajoutez à des objets, utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#update",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".update()"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "# Arrays\n{% set navigation = [\"Home\", \"About\"] %}\n{% do navigation.append(\"Contact Us\") %}\n{{navigation}}\n\n# Objects\n{% set book = {\"name\" : \"Rocking HubL\", \"author\" : \"John Smith\" }%}\n{% do book.update({\"ebook\" : \"yes\"}) %}\n{{book}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "# Arrays [Home, About, Contact Us] # Objects {name=Rocking HubL, author=John\nSmith, ebook=yes}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Commentaires"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le dernier type de séparateur que vous pouvez rencontrer ou décider d'employer en développant avec HubL, est un commentaire HubL. Les commentaires sont définis par ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{#"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Les modules"
      }), " sont des zones dynamiques d'un modèle qui peuvent être personnalisées par l'utilisateur final dans l'éditeur de contenu. Par exemple, si vous codiez un fichier de modèle à partir de zéro, vous ajouteriez des modules aux modèles avec des balises HubL, pour donner à vos créateurs de contenu la possibilité de modifier des zones de la page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les balises de module sont constituées des éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Type de module :"
        }), " spécifie le module à rendre. Veuillez vous référer à la page ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags",
          children: "balise de prise en charge HubL"
        }), " pour une liste des différents types de modules disponibles."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Un nom unique pour ce module :"
        }), " donne au module une identité unique dans le contexte du modèle"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Chemin :"
        }), " en fonction de la balise, définit l'emplacement du module dans le gestionnaire de conception. Le chemin des modules par défaut de HubSpot doit toujours commencer par @hubspot/ suivi du type de module. Pour en savoir plus, consultez l'exemple ci-dessous et la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "page utiliser des modules dans les modèles"
        }), " ."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Paramètres :"
        }), " spécifiez éventuellement des informations supplémentaires sur le module."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"unique_module_name\",\n  path=\"@hubspot/text\",\n  label=\"Single Text Line\",\n  value=\"This is a single text line\"\n%}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text widget-type-text\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"module\"\n  id=\"hs_cos_wrapper_text\"\n  style=\"\"\n>\n  <span\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"text\"\n    id=\"hs_cos_wrapper_text_\"\n    style=\"\"\n    >This is a single text line</span\n  >\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessus est un module de texte dont les paramètres de libellé et valeur sont définis. Le libellé définit le texte d'aide dans l'éditeur et la valeur définit le texte par défaut pour ce module. Voir l'exemple de GIF ci-dessous pour voir comment cela se présente dans l'éditeur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/module-label-value-min.gif",
        alt: "module-label-value-min"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "en savoir plus sur l'utilisation des modules dans les modèles, ici"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables et macros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL comprend de nombreuses applications ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "variables HubSpot prédéfinies"
      }), " qui impriment leurs valeurs utiles à partir de l'application. En outre, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "définir vos propres variables dans un modèle"
      }), ". Dans l'exemple suivant, une variable nommée ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primaryColor"
      }), " est définie dans une instruction, puis imprimée avec une expression HubL. Cet exemple associe la variable HubL à une CSS"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set primaryColor = '#F7761F' %} {# defines variable and assigns HEX color #}\n\na {\n  color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les macros HubL vous permettent d'imprimer plusieurs déclarations avec une valeur dynamique. Cette technique s'avère utile lorsque vous vous retrouvez à écrire les mêmes blocs de code de base à plusieurs reprises, mais que vous devez seulement modifier certaines valeurs. Dans l'exemple suivant, une macro est utilisée pour imprimer une propriété de transition CSS3 qui comprend tous les préfixes des fournisseurs. Vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "en savoir plus sur les macros, ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n  {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filtres et fonctions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Des filtres peuvent être ajoutés à votre HubL pour transformer ou modifier la valeur d'une variable de modèle. Un exemple simple affiché ci-dessous consiste à formater une variable de date. Les filtres utilisent un | (symbole pipeline) et sont appliqués sans espace à une variable."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'exemple ci-dessous, supposons qu'un article de blog ait été publié le 29 avril. La date de publication de l'article est formatée avec un filtre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "datetimeformat"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "Vous pouvez consulter la liste complète des filtres ici."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date_local_time|datetimeformat(\"%B %e, %Y\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "April 29, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alors que les filtres affectent le rendu des variables, les fonctions traitent les informations relatives aux valeurs et aux comptes et rendent ces informations. Par exemple, une fonction peut être utilisée pour obtenir le nombre total d'articles pour un blog particulier ou pour éclaircir/assombrir une variable de couleur d'une valeur particulière."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'exemple imprimerait le nombre total d'articles de blog du site designers.hubspot.com/blog. Il utilise un paramètre ID de blog (disponible dans l'URL du tableau de bord du blog) pour spécifier le blog à cibler. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "Vous pouvez consulter la liste complète des fonctions ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "// blog_total_post_count\n{{ blog_total_post_count(359485112) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fonctions SI"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "fonctions SI"
      }), " vous permettent d'utiliser la logique conditionnelle pour dicter la manière dont votre modèle rendra la logique conditionnelle dans les instructions HubL pour ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), ". Une fonction SI doit commencer par un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " et se terminer par un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'exemple ci-dessous définit un module de choix qui permet à l'utilisateur final de sélectionner un service dans une liste déroulante. En fonction de ce que l'utilisateur sélectionne dans l'éditeur, le modèle génère un titre dynamique pour une page de recrutements. Cet exemple nécessite l'utilisation du paramètre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "export_to_template_context"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Boucles for"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "Les boucles for"
      }), " vous permettent d'itérer sur des éléments stockés dans une séquence. Elles seront le plus souvent utilisées pour rendre le contenu d'un blog dans un format de listing. Les boucles for commencent par une instruction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), " et se terminent par une instruction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endfor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'exemple ci-dessous, un tableau de types d'ours est stocké dans une variable appelée ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bears"
      }), ". Une boucle for est ensuite utilisée pour parcourir les différents types d'ours en imprimant un élément de liste pour chaque type."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bears = [\"panda bear\", \"polar bear\", \"black bear\", \"grizzly bear\", \"koala bear\"] %}\n\n<h1>Types of bears</h1>\n<ul>\n  {% for bear in bears %}\n  <li>{{ bear }}</li>\n  {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Types of bears</h1>\n<ul>\n  <li>panda bear</li>\n  <li>polar bear</li>\n  <li>black bear</li>\n  <li>grizzly bear</li>\n  <li>koala bear</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autres fonctionnalités de HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous quelques autres fonctions de modélisation diverses qui peuvent vous être utiles lorsque vous développez avec HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Échapper les séparateurs HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "De nombreuses autres langues partagent les mêmes délimiteurs que HubL, ce qui peut créer des problèmes lorsque l'on travaille dans des fichiers codés sur le CMS. Si vous souhaitez utiliser un séparateur HubL pour une autre langue, vous devez encadrer ce code :"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: " {{\"Code you want to escape\"}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{\"Code you want to escape\"}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Inclure des fichiers dans des fichiers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez inclure plusieurs fichiers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".html"
      }), " dans un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "modèle HubL"
      }), " à l'aide de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#standard-partials",
        children: "balise d'inclusion"
      }), ". Pour créer un fichier HTML qui ne nécessite pas les variables du modèle standard, vous devez décocher l'option « Rendre le modèle disponible pour le nouveau contenu » La syntaxe est affichée ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"custom/page/web_page_basic/my_footer.html\" %}\n{% include \"hubspot/styles/patches/recommended.css\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez également compiler plusieurs fichiers CSS en un seul fichier CSS avec la même balise d'inclusion. Lorsque vous publiez le fichier parent, le fichier enfant sera compilé en un seul fichier CSS minifié avec le code du parent."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blocs et extensions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous créez des modèles complexes, vous pouvez utiliser des blocs compartimentés qui étendent un modèle parent."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tout d'abord, vous allez créer un modèle principal qui comprend les variables suivantes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), ". Dans ce modèle, vous devez définir un bloc unique en utilisant la syntaxe suivante où ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_sidebar"
      }), " est un nom unique :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"custom/page/web_page_basic/my_template.html\" %}\n{% block my_sidebar %}\n<h3>Sidebar title</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ensuite, vous pouvez créer un fichier HTML enfant qui remplira ce bloc. Tout d'abord, vous devez déclarer une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#blocks-and-extends",
        children: "instruction extends"
      }), " qui fait référence au chemin d'accès au parent. Ce bloc de code serait rendu dans le modèle parent, mais maintenu dans un autre fichier plus petit et plus facile à gérer. Cette technique ne convient pas à tout le monde, mais elle peut être utile pour rester organisée lors du codage de modèles d'e-mails ou de modèles de pages. Lorsque vous utilisez cette technique, vous devez choisir le modèle enfant, lors de la création du contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Copie de section HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'éditeur de page, vous pouvez copier le balisage HubL d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: "section par glisser-déposer"
      }), " afin de réutiliser le code si nécessaire. Cela peut être utile lorsqu'on veut recréer une section de glisser-déposer dans un fichier codé."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-section-hubl-menu.png",
        alt: "copy-section-hubl-menu"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#copy-section-hubl",
        children: "copie de section HubL."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}