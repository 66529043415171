"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 30866940858;
const slug = exports.slug = 'guides/api/conversations/visitor-identification';
const title = exports.title = '訪問者の識別';
const name = exports.name = 'vNext Docs DP - 訪問者の識別';
const metaDescription = exports.metaDescription = '訪問者識別APIを使用すると、外部認証システムによって認証されたウェブサイト訪問者を識別することができます';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E9%80%A3%E6%90%BA%E3%83%95%E3%83%AD%E3%83%BC%E3%81%AE%E4%BE%8B",
  "label": "連携フローの例",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%80%A3%E6%90%BA%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B",
  "label": "連携を確認する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%81%E3%83%A3%E3%83%83%E3%83%88%E3%82%A6%E3%82%A3%E3%82%B8%E3%82%A7%E3%83%83%E3%83%88sdk%E5%85%A5%E9%96%80",
  "label": "チャットウィジェットSDK入門",
  "parent": null
}, {
  "depth": 1,
  "id": "sdk%E3%83%AA%E3%83%95%E3%82%A1%E3%83%AC%E3%83%B3%E3%82%B9",
  "label": "SDKリファレンス",
  "parent": "%E3%83%81%E3%83%A3%E3%83%83%E3%83%88%E3%82%A6%E3%82%A3%E3%82%B8%E3%82%A7%E3%83%83%E3%83%88sdk%E5%85%A5%E9%96%80"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      ul: "ul",
      li: "li",
      code: "code",
      h2: "h2",
      a: "a",
      img: "img",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "訪問者の識別"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise', 'sales_hub-professional', 'sales_hub-enterprise', 'cms-professional', 'cms-enterprise', 'service_hub-professional', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "訪問者識別APIを使用することで、独自の外部認証システムによって認証されたサイト訪問者を識別します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このAPIから返される識別トークンを使用し、すでに認証された訪問者に関する情報をチャットウィジェットに渡すことで、訪問者が既知のコンタクトとして扱われるようになります。その後、受信トレイのエージェントは、誰とやり取りしているかを明確に把握でき、訪問者は複数のデバイス間で過去のスレッド履歴にアクセスできます。以下に例を示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "訪問者識別APIの目的は、訪問者が誰であるかをHubSpotに伝えることです。ご使用のプラットフォーム内のユーザーを認証するためにこの機能に頼るべきではありません。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["訪問者識別APIを利用するには、「Professional」**または「Enterprise」**レベルのサブスクリプションが必要です。条件を満たすサブスクリプションがアカウントに存在しない場合、APIから", (0, _jsxRuntime.jsx)(_components.code, {
            children: "403"
          }), "エラー応答が返されます。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "連携フローの例"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "訪問者の識別機能との連携を使用するには、認証システムを備えた既存のWebアプリケーションが必要です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開始する前に、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "非公開アプリ"
      }), "がセットアップされていること、また、対象となる「Professional」**または「Enterprise」**のサブスクリプションが、連携しようとしているアカウントで使用されていることを確認してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下に、考えられる連携フローの例を示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hubfs/Possible%20User%20Identification%20Flow.png",
        alt: "考えられるユーザー識別フロー"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "顧客がシステムにログインして認証されたら、次の手順に従って、ライブチャットで顧客を識別します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "1."
      }), " フロントエンドで、ウィンドウ上のhsConversationsSettingsオブジェクトのloadImmediatelyをfalseに設定します。このように設定しないと、識別情報が渡される前にチャットウィジェットが読み込まれる場合があります。詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#chat-widget-sdk-primer",
        children: "以下のチャットウィジェットSDK入門"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "isConversationsAPIReady"
        }), "関数の外側で", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), "プロパティーを設定します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["さらに、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), "を呼び出し前に設定する必要があります。そうしないと、競合状態が発生してウィジェットの読み込みが妨げられる可能性があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**2. ** 認証済み訪問者のEメールアドレスを訪問者識別APIに渡すことで、トークンを生成します。この操作は、Webアプリケーションのバックエンドで行う必要があります。リクエストの例については、「エンドポイント」__タブを参照してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubspot.com/conversations/v3/visitor-identification/tokens/create \\\n--data '{\n  \"email\": \"gob@bluth.com\",\n  \"firstName\": \"Gob\",\n  \"lastName\": \"Bluth\"\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "以下が該当する場合、提供された氏名は、チャット開始後にHubSpotのコンタクトレコードで設定されます。"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "それが訪問者識別APIによって作成された新しいコンタクトである。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "名前がまだ分かっていない既存のコンタクトである。"
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "これは、外部システムにはすでに名前の情報がある一方、HubSpotにはまだ存在しない場合、識別された訪問者へのメッセージをパーソナライズするときに役立ちます。これらは任意指定のパラメーターであり、必須ではありません。"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["**3. ** ステップ2のトークンを使用して、ウィンドウ上の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), "オブジェクトに次のプロパティーを設定します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**4. ** ウィジェットを読み込みます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["認証された訪問者のページが読み込まれるたびに、ウィンドウ上の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), "オブジェクトにトークンと電子メールが設定される必要があります。これらのパラメーターが設定されなくなると、以降のページの読み込み時に、コンテキストは自動的に保持されません。トークンは一時的なもので、12時間後に期限切れになります。トークンが少なくとも12時間に1回更新される場合、トークンをキャッシュして、ページを読み込むたびにトークンを再取得しなくて済むようにできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "連携を確認する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "訪問者の識別機能との連携が完了したら、連携が想定通りに機能していることを確認します。以下に示すように、実装に応じて、2通りの方法で確認できます具体的な要件に合わせて、以下の例を調整する必要がある場合があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "1つ以上の公開ページ上および認証システムの背後にチャットウィジェットをすでに追加している場合："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "チャットウィジェットで訪問者を識別しないページに移動し、コミュニケーションを開始します。"
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "HubSpotで受信トレイを開き、受信したチャットが__「不明な訪問者」に属していることを確認します。そうでない場合は、非公開のブラウザーウィンドウで次の手順を試してみてください。"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "チャットウィジェットで訪問者識別APIを使用して訪問者を識別するページに移動し、コミュニケーションを開始します。"
                }), "\n"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "HubSpotで受信トレイを開き、ログインに使用したコンタクトにチャットが属性として正しく結び付けられていることを確認します。正しい関連付けが確認された場合、コンタクトの名前の横に、このコンタクトが訪問者の識別APIで正常に識別されたことを示すバッジが表示されます。"
                }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: (0, _jsxRuntime.jsx)(_components.img, {
                    src: "https://f.hubspotusercontent00.net/hubfs/53/visitor_identity_badge.png",
                    alt: "visitor_identity_badge"
                  })
                }), "\n"]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "認証システムの背後にあるページにのみチャットウィジェットを追加済みであり、複数のテスト ユーザー アカウントにアクセスできる場合:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "最初のテストユーザーとしてHubSpotにログインし、チャットウィジェットが読み込まれるページに移動して、コミュニケーションを開始します。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubSpotからログアウトし、2番目のテストユーザーとして再度ログインします。チャットウィジェットが読み込まれるページに移動し、コミュニケーションを開始します。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubSpotで受信トレイを開き、最初のテストアカウントと2番目のテストアカウントからそれぞれチャットが送信されていて、両方のレコードでコンタクト名の横にバッジが表示されていることを確認します。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**このAPIで識別された訪問者に関して、HubSpotは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), "Cookieをドロップしません。また、Eメールアドレスは既知であることから、HubSpotはEメールキャプチャーの質問をスキップします。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), " CookieとEメールキャプチャーはこれらのチャットには適用されないため、訪問者識別APIを介して識別された訪問者に関しては、チャットフローの関連設定が表示されません。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "チャットウィジェットSDK入門"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このAPIは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), "オブジェクトに格納されています。使用可能な全てのメソッドは、このオブジェクトを介して利用します。このオブジェクトはページ上のHubSpotスクリプトローダーによって自動的に作成されますが、直ちに利用可能にならない場合もあります。APIが初期化されるまでの間、APIへのアクセスを遅延させるには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), "ヘルパーを使用できます。以下に例を示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "SDKリファレンス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "array"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これは、ウィンドウオブジェクトで定義できる任意指定のフィールドです。これを使用すると、ウィジェットが使用可能になったらすぐに実行されるコードを指定できます。APIが初期化されると、この配列の有無が確認され、存在する場合は配列に含まれる一連の関数が実行されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "if (window.HubSpotConversations) {\n  console.log('The api is ready already');\n} else {\n  window.hsConversationsOnReady = [\n    () => {\n      console.log('Now the api is ready');\n    },\n  ];\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "object"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このオブジェクトを使用すると、設定オプションを指定してからウィジェットを初期化できます。訪問者の識別機能を使用するには、次のフィールドを設定する必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ウィジェットを暗黙的に読み込むか、それとも", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            }), "メソッドが呼び出されるまで待つか"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationToken"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "訪問者識別APIとの連携に使用されます。これは訪問者識別API上のトークン生成エンドポイントによって提供されるトークンであり、これを使用してこの訪問者が認証済みであることを証明します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationEmail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ウィジェットの読み込み中であることがすでに識別された訪問者のメールアドレス。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/conversations/chat-widget-sdk",
        children: "コミュニケーションSDK"
      }), "の詳細をご確認ください。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}