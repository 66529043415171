"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694158;
const slug = exports.slug = 'guides/cms/content/templates/types/html-hubl-templates';
const title = exports.title = 'HTML＋HubLテンプレート';
const name = exports.name = 'HTML＋HubLテンプレート';
const metaDescription = exports.metaDescription = 'CMS Hubのテンプレートは、HTMLとHubLで作成されています。これらのテンプレートには、制作担当者が自力で簡単に編集できる領域を含めることができます。';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image1-2.png';
const featuredImageAltText = exports.featuredImageAltText = '基本的なHTML+HubLテンプレートのスクリーンショット';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E7%BF%92%E7%86%9F%E5%BA%A6%E3%81%A8%E3%83%84%E3%83%BC%E3%83%AB%E9%81%B8%E3%81%B3",
  "label": "習熟度とツール選び",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%A2%E3%83%8E%E3%83%86%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "テンプレートアノテーション",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%98%E3%83%83%E3%83%80%E3%83%BC%E3%81%A8%E3%83%95%E3%83%83%E3%82%BF%E3%83%BC%E3%81%AE%E3%82%A4%E3%83%B3%E3%82%AF%E3%83%AB%E3%83%BC%E3%83%89",
  "label": "ヘッダーとフッターのインクルード",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB",
  "label": "パーシャル",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%A8%99%E6%BA%96%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB",
  "label": "標準パーシャル",
  "parent": "%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB"
}, {
  "depth": 1,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB",
  "label": "グローバルパーシャル",
  "parent": "%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB"
}, {
  "depth": 1,
  "id": "%E3%83%96%E3%83%AD%E3%83%83%E3%82%AF%E3%81%A8%E6%8B%A1%E5%BC%B5",
  "label": "ブロックと拡張",
  "parent": "%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB"
}, {
  "depth": 1,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97",
  "label": "グローバルグループ",
  "parent": "%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HTML＋HubLテンプレート"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["HTML＋HubLテンプレートは、CMS Hubのどの", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/templates/overview#template-types",
            children: "テンプレートのタイプ"
          }), "にも使用できます。このテンプレートは", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/cms/hubl/overview",
            children: "HubLテンプレート作成言語"
          }), "をサポートする.htmlファイルです。このようなHubL対応のコードテンプレートのプレビューには、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/review-your-hubspot-template-setup",
            children: "デザインマネージャーのテンプレートプレビュー"
          }), "またはサンドボックスアカウントを使用するのが最適です。HTML＋HubLテンプレートには", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#partials",
            children: "パーシャル"
          }), "を含めることができます。パーシャルはヘッダーやフッターなど、共通の要素に使用するコードを分ける際に使用できます。"]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844649367"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HTML＋HubLテンプレートを使用すると、開発者は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "ビジュアル デザイン マネージャーのドラッグ＆ドロップ方式のテンプレート"
      }), "を使用する場合よりも柔軟に制御できます。これにより、開発者は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "ドラッグ＆ドロップ機能"
      }), "を使用して、HTML＋HubLテンプレートでなければ実現できない、快適な編集環境を制作担当者に提供できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-2.png",
        alt: "image1-2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["上記のテンプレートは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
          children: "HubSpotのCMSボイラープレート"
        }), "に含まれている", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "base.htmlテンプレート"
        }), "（英語）です。HubSpotで開発を行う際にはこのボイラープレートから始めると便利です。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "習熟度とツール選び"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HTML＋HubLテンプレートはコード化されたファイルであるため、お好みのツールを使用してローカル環境でも編集できます。推奨されるのは、HubSpot独自の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "ローカル開発ツール"
      }), "の使用です。HubSpotの開発ツールでは、デベロッパー ファイル システムとファイルマネージャーのファイルを安全に管理し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "アップロード"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "取得"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "監視"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "作成"
      }), "することができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以前に他のテンプレート言語を使用した経験がある場合は、それと同様の方法で、HubSpotでもHTML＋HubLテンプレートを構築できます。主な違いは、HubSpotでは、制作担当者にとって最適な編集環境を実現するために注力していること、そしてメンテナンスとパフォーマンス最適化の開発者の作業負担の大部分を軽減していることです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、特定のモジュールを対象に所定のページでCSSファイルを読み込む必要がある場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<link rel=\"stylesheet\" type=\"text/css\" href=\"theme.css\">"
      }), "を使用する代わりに、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
        children: "モジュールのmeta.jsonファイル"
      }), "内にある", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), "を使用してスタイルシートをインクルードします。これにより、HubSpotは、モジュールがページに存在する場合にのみCSSを条件付きで読み込むため、不必要に読み込まれるCSSの量を最小限に抑えることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "HubSpot開発ワークフローの最適化方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレートアノテーション"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テンプレートの上部に含まれるテンプレートアノテーションは、重要なテンプレート設定を構成するためのもので、テンプレートのタイプや新しいコンテンツの作成に使用できるかどうかなどを設定できます。テンプレートアノテーションは、開発プロセス中にいつでも変更できます。利用可能なテンプレートアノテーションの詳細については、以下をご覧ください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: page\n  isAvailableForNewContent: false\n  enableDomainStylesheets: false\n  label: Homepage\n  screenshotPath: ../images/template-previews/home.png\n-->\n\n<!doctype html>\n\n<html>\n  ...\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "アノテーション"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "templateType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ファイルのテンプレートタイプを指定します。指定可能な値は、次のとおりです。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["標準テンプレート：", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#page",
                      children: "page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email",
                      children: "email"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-listing",
                      children: "blog_listing"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-post",
                      children: "blog_post"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog",
                      children: "blog"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["パーシャル：", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
                  children: "global_partial"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["システムテンプレート：", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#error-pages",
                      children: "error_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#password-prompt",
                      children: "password_prompt_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-login",
                      children: "membership_login_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-register",
                      children: "membership_register_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-password-reset",
                      children: "membership_reset_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-reset-request",
                      children: "membership_reset_request_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-preferences",
                      children: "email_subscription_preferences_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-backup-unsubscribe",
                      children: "email_backup_unsubscribe_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-unsubscribe-confirmation",
                      children: "email_subscriptions_confirmation_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#search-results-page",
                      children: "search_results_page"
                    })
                  })]
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isAvailableForNewContent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツ作成プロセスでテンプレートを選択できるかどうかを指定します。指定可能な値は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "および", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "です。このアノテーションが", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "に設定されているテンプレートには、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/variables#required-page-template-variables",
              children: "必須の変数"
            }), "を含める必要はありません。このアノテーションがfalseに設定されている、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "page"
            }), "タイプのテンプレートは、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#partials",
              children: "標準パーシャル"
            }), "としても使用できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableDomainStylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["テンプレートで", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-edit-and-attach-css-files-to-style-your-site#attach-or-remove-stylesheets-on-a-domain-level",
              children: "ドメインスタイルシート"
            }), "を読み込むかどうかを指定します。指定可能な値は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["テンプレート選択画面に表示される、テンプレートについての分かりやすい説明文。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "About Page"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Homepage"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pricing"
            }), "。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshotPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "制作担当者がテンプレートを選択しているときに表示されるスクリーンショット。このスクリーンショットによってテンプレートが区別しやすくなります。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ヘッダーとフッターのインクルード"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ファイルがテンプレートパーシャルでない限り、HubSpotのテンプレートを使用するには2つのタグが必要です。この2つのタグを次に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_header_includes }}"
        }), " - 必要なCSSを結合してミニフィケーションし、自動的に追加するために使用します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_footer_includes }}"
        }), " - HubSpotトラッキングスクリプトやモジュールなどの要素で使用するJavaScriptをページの下部に動的に追加するために使用します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらのタグは、テンプレート内に存在するか、またはテンプレートの子", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#partials",
        children: "パーシャル"
      }), "として公開して使用する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "パーシャル"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テンプレートパーシャルは、他のコードファイルにインクルードできるHTML＋HubLファイルです。パーシャルを使用して複数のテンプレートの間でマークアップを共有することで、モジュール方式の開発アプローチをとることができます。例えば、ヘッダーをパーシャルにすれば、コンポーネントとして簡単にインクルードでき、再度コード化する必要がなくなります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "標準パーシャル"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "標準パーシャルとは、個々のページで編集できるコンテンツを含む、再利用可能なテンプレートまたはコンポーネントのことです。常にコンテンツを共有するグローバルパーシャルとは異なり、標準パーシャルでは、制作担当者が個々のページで必要に応じてコンテンツを変更できます。例えば下記のコードでは、サイドバーモジュールが別のテンプレートファイルに取り込まれます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["標準パーシャルの場合、テンプレートファイルの先頭に次の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "アノテーション"
      }), "を組み込む必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateType: page"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "isAvailableForNewContent: false"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"../partial/sidebar.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "グローバルパーシャル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グローバルパーシャルとは、そのパーシャルの全てのインスタンスで共有されるコンテンツを含む一種の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#global-partials",
        children: "グローバルコンテンツ"
      }), "であり、複数のテンプレートで使用できます。多くの場合、ヘッダーとフッターにはグローバルパーシャルが使用されます。その一例として、HubSpotのCMSボイラープレートの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/partials/header.html",
        children: "ヘッダー"
      }), "（英語）と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/footer.html",
        children: "フッター"
      }), "（英語）をご確認ください。これらのパーシャルは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "base.html"
      }), "内で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), "タグを使用して呼び出されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グローバルパーシャルの場合、ファイルの先頭に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "アノテーション"
      }), "として", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: global_partial"
      }), "を組み込む必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ブロックと拡張"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "複雑なテンプレートを作成するときは、親テンプレートを拡張する区画となるブロックを作成できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、必須の変数である", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), "を含む親テンプレートを作成できます。このテンプレート内で、以下の構文を使用して一意のブロックを定義します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), "は一意の名前です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% block body %}\n<!-- Content to display -->\n{% endblock body %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これにより、子テンプレート内で親テンプレートを拡張して、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), "ブロックにさらにコンテンツを挿入することができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"./layouts/base.html\" %}\n{% block body %}\n<h3>Page Content</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この方法は、HubSpotのCMSボイラープレートに含まれる", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
        children: "base.html"
      }), "（英語）テンプレートで使用されています。このテンプレートが、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/main/src/templates",
        children: "templatesフォルダー"
      }), "（英語）内の他のテンプレートによって拡張されるようになっています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "グローバルグループ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["デザインマネージャーのドラッグ＆ドロップ テンプレート ビルダーを使用して作成された", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "グローバルグループ"
      }), "を含めることもできます。以下に構文を示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"/path/to/global_header.template.json\" %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}