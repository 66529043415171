"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164592946577;
const slug = exports.slug = 'reference/ui-components/overview';
const title = exports.title = 'UI-Erweiterungskomponenten';
const name = exports.name = 'Übersicht über UI-Komponenten';
const metaDescription = exports.metaDescription = 'Erfahren Sie mehr über die Komponenten, die Sie zum Erstellen von UI-Erweiterungen verwenden können.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "standardkomponenten",
  "label": "Standardkomponenten",
  "parent": null
}, {
  "depth": 0,
  "id": "crm-datenkomponenten",
  "label": "CRM-Datenkomponenten",
  "parent": null
}, {
  "depth": 0,
  "id": "crm-aktionskomponenten",
  "label": "CRM-Aktionskomponenten",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Übersicht über UI-Komponenten (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Erstellen einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "UI-Erweiterung"
      }), " können Sie eine beliebige Anzahl von wiederverwendbaren Komponenten einbinden, die von HubSpot bereitgestellt werden. Diese Komponenten reichen von einfachen Textfeldern bis hin zu vorgefertigten CRM-Objektberichten, und jede Komponente bietet über Eigenschaften Anpassungsmöglichkeiten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Komponenten werden am Anfang der Datei mit der Erweiterung ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "tsx"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "jsx"
      }), " importiert. Je nach Art der Komponente müssen Sie sie von einem der beiden SDK-Verzeichnisse importieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#standard-components",
          children: "Standardkomponenten"
        }), " werden von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubspot/ui-extensions'"
        }), " importiert"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#crm-data-components",
          children: "CRM-Daten"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#crm-action-components",
          children: "CRM-Aktionskomponenten"
        }), " werden von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubspot/ui-extensions/crm'"
        }), " importiert"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Alert, Text } from '@hubspot/ui-extensions';\nimport { CrmAssociationPivot, CrmActionLink } from '@hubspot/ui-extensions/crm';\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Um auf die neuesten Komponenten zuzugreifen, stellen Sie sicher, dass Sie das neueste npm-Paket installiert haben, indem Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm i @hubspot/ui-extensions"
        }), " im Verzeichnis ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "extensions"
        }), " ausführen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Standardkomponenten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Standardkomponenten sind Komponenten, die sowohl für interne als auch für externe Daten verwendet werden können. Diese Komponenten rufen nicht von sich aus Daten ab, sondern sind flexibler in ihrer Implementierung."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Komponenten werden von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'@hubspot/ui-extensions'"
      }), " importiert."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Komponente"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/accordion",
              children: "Accordion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein einklappbarer Accordion-Abschnitt, der andere Komponenten enthalten kann."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/alert",
              children: "Alert"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Warnungen zur Anzeige von Status und Aktionsergebnissen, z. B. Erfolgs- und Fehlermeldungen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/box",
              children: "Box"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Komponente für die Verwaltung des Layouts. Kann mit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/flex",
              children: "Flex"
            }), " verwendet werden. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/manage-ui-extension-layout",
              children: "Verwalten des Layouts von Erweiterungen"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Schaltflächen, mit denen Benutzer Aktionen durchführen können, z. B. Daten senden oder abrufen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button-row",
              children: "Button row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Für die Darstellung mehrerer Schaltflächen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/checkbox",
              children: "Checkbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Eingabe über ein einzelnes Kontrollkästchen. Um mehrere Kontrollkästchen anzuzeigen, verwenden Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/date-input",
              children: "DateInput"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Feld, in dem die Benutzer ein Datum auswählen können."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/description-list",
              children: "DescriptionList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigt Paare von benutzerdefinierten Labeln und Werten an, ähnlich wie HubSpot-Eigenschaften in der linken Seitenleiste von CRM-Datensätzen erscheinen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/divider",
              children: "Divider"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine horizontale Linie zur Trennung von Komponenten innerhalb einer Erweiterung."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/dropdown",
              children: "Dropdown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Dropdown-Menü zur Auswahl von Werten, das entweder als Schaltfläche oder als Hyperlink formatiert ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/empty-state",
              children: "EmptyState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Abbildung mit Label, die eine Komponente ohne Inhalte anzeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/error-state",
              children: "ErrorState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abbildungen mit Label zur Anzeige von Fehlern."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/flex",
              children: "Flex"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Umhüllt andere Komponenten in einem leeren ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "div"
            }), ", das auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display=flex"
            }), " festgelegt ist. Verwenden Sie diese Komponente und ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/box",
              children: "Box"
            }), ", um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/manage-ui-extension-layout",
              children: "das Layout der Erweiterung zu verwalten"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Formular"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Formular zur Übermittlung von Daten, das weitere verwandte Komponenten enthalten kann, wie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/input",
              children: "Input"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/heading",
              children: "Heading"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Stellt großen Text für Überschriften dar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/icon",
              children: "Icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Stellt ein visuelles Symbol innerhalb anderer Komponenten dar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Bild, das in erster Linie zum Hinzufügen von Logos oder anderen visuellen Ressourcen für die Markenidentität oder zur Hervorhebung anderer Erweiterungsinhalte verwendet wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/input",
              children: "Input"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Texteingabefeld, in das Benutzer eigene Textwerte eingeben können. Hauptsächlich verwendet in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), "-Komponenten."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein anklickbarer Hyperlink zum Navigieren zu externen und HubSpot-App-Seiten oder zum Auslösen von Funktionen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/list",
              children: "List"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine geordnete oder nicht geordnete Liste von Elementen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NEU"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ähnlich einer ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), "-Komponente mit zusätzlichen Optionen für den Ladezustand."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-spinner",
              children: "LoadingSpinner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine visuelle Anzeige, dass die Karte gerade geladen oder verarbeitet wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/modal",
              children: "Modal"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NEU"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Pop-up-Overlay, das andere Komponenten enthält. Hilfreich für Kurznachrichten und Felder zur Bestätigung von Aktionen. Kann mit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            }), "- und ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            }), "-Komponenten geöffnet werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/multi-select",
              children: "MultiSelect"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dropdown-Auswahlfeld, in dem Benutzer mehrere Werte auswählen können. Wenn nur ein Wert ausgewählt werden soll, verwenden Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            }), ". Hauptsächlich verwendet in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), "-Komponenten."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/number-input",
              children: "NumberInput"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Feld für die Eingabe von Zahlen. Hauptsächlich verwendet in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), "-Komponenten."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/panel",
              children: "Panel"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NEU"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Bereich, der sich auf der rechten Seite der Seite öffnet und andere Komponenten enthält. Kann mit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            }), "- und ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            }), "-Komponenten geöffnet werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/progress-bar",
              children: "ProgressBar"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine visuelle Darstellung von Daten, die sich in Richtung eines positiven oder negativen Ziels bewegen. Kann sowohl Zahlen als auch Prozentsätze anzeigen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/radio-button",
              children: "RadioButton"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Auswahl-Optionsfeld. Wenn Sie mehr als zwei Optionsfelder einfügen möchten oder ein ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), " erstellen, wird empfohlen, stattdessen ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            }), " zu verwenden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dropdown-Feld, in dem ein Benutzer einen einzelnen Wert auswählen kann. Um die Auswahl mehrerer Werte zu ermöglichen, verwenden Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/multi-select",
              children: "MultiSelect"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/statistics",
              children: "Statistics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein visuelles Spotlight mit einem oder mehreren Datenpunkten. Enthält numerische Werte und Trendanzeigen (zunehmender/abnehmender Prozentsatz)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/status-tag",
              children: "StatusTag"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NEU"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine visuelle Anzeige zur Darstellung des aktuellen Status eines Elements."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/step-indicator",
              children: "StepIndicator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine visuelle Anzeige zur Beschreibung des Fortschritts innerhalb eines Prozesses mit mehreren Schritten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/stepper-input",
              children: "StepperInput"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ähnlich wie die Komponente ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/number-input",
              children: "NumberInput"
            }), ", aber dieses Feld ermöglicht es dem Benutzer, den Wert um einen bestimmten Betrag zu erhöhen oder zu verringern."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/table",
              children: "Table"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigt Daten in Spalten und Zeilen an. Tabellen können paginiert und sortierbar sein."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Farbige Label zur Kategorisierung von Informationen oder anderen Komponenten. Kann statisch oder anklickbar sein, um Funktionen auszulösen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text",
              children: "Text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Stellt Text mit Formatierungsoptionen dar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tile",
              children: "Tile"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein rechteckiger, umrandeter Container zum Erstellen von Gruppen zusammengehöriger Komponenten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text-area",
              children: "TextArea"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ähnlich wie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text",
              children: "Text"
            }), ", aber für längere Textabschnitte. Enthält Eigenschaften zur Konfiguration der Feldgröße, der maximalen Zeichenanzahl und der Größenänderbarkeit."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle",
              children: "Toggle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein boolescher Umschalter, der mit Größen- und Label-Optionen konfiguriert werden kann."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Liste von auswählbaren Kontrollkästchen oder Optionsfeldern."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRM-Datenkomponenten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM-Datenkomponenten können Daten direkt aus dem aktuell angezeigten CRM-Datensatz abrufen, einschließlich Informationen zu zugeordneten Datensätzen und Einzelobjektberichten. Diese Komponenten können nur in der mittleren Spalte von CRM-Datensätzen platziert werden. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-data-components/overview",
        children: "CRM-Datenkomponenten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Komponenten werden von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), " importiert."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Komponente"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-pivot",
              children: "CrmAssociationPivot"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Liste von Datensätzen, die dem aktuell angezeigten Datensatz zugeordnet sind, geordnet nach ihrem Zuordnungslabel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-property-list",
              children: "CrmAssociationPropertyList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine bearbeitbare Liste von CRM-Eigenschaften, die zu einem Datensatz gehören, der dem aktuell angezeigten Datensatz zugeordnet ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-stage-tracker",
              children: "CrmAssociationStageTracker"
            }), " NEU"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Fortschrittsbalken für Lifecycle- oder Pipeline-Phasen, der Daten von zugeordneten Datensätzen anzeigt. Kann eine Liste von Eigenschaften enthalten, die inline bearbeitet werden können."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-table",
              children: "CrmAssociationTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Tabelle mit Datensätzen, die dem aktuell angezeigten Datensatz zugeordnet sind."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-data-highlight",
              children: "CrmDataHighlight"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Liste von CRM-Eigenschaften, die zu dem aktuell angezeigten Datensatz oder einem anderen angegebenen Datensatz gehören."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-property-list",
              children: "CrmPropertyList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine bearbeitbare Liste von CRM-Eigenschaften, die zu dem aktuell angezeigten Datensatz oder einem anderen angegebenen Datensatz gehören."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-report",
              children: "CrmReport"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anzeige eines vorhandenen Einzelobjektberichts. Beinhaltet Filteroptionen, oder kann alle Berichtsdaten ungefiltert anzeigen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-stage-tracker",
              children: "CrmStageTracker"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Fortschrittsbalken für eine Lifecycle- oder Pipeline-Phase mit einer Liste von Eigenschaften."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-statistics",
              children: "CrmStatistics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigt Zusammenfassungen von Daten an, die aus den Zuordnungen des aktuell angezeigten Datensatzes berechnet wurden. Zum Beispiel der durchschnittliche Umsatz der einem Kontakt zugeordneten Unternehmen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRM-Aktionskomponenten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM-Aktionskomponenten stellen eine integrierte Gruppe von CRM-bezogenen Aktionen bereit, z. B. das Hinzufügen von Notizen zu Datensätzen, das Öffnen eines Fensters zum Verfassen von persönlichen E-Mails, das Erstellen neuer Datensätze und vieles mehr. Jede Komponente kann dieselbe Reihe von Aktionen ausführen. Welche Komponente Sie wählen, hängt also von Ihren Bedürfnissen und Vorlieben ab. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-action-components/overview",
        children: "CRM-Aktionskomponenten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM-Aktionskomponenten werden von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), " importiert."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Komponente"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-action-button",
              children: "CrmActionButton"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Schaltfläche, die eine integrierte Reihe von CRM-Aktionen ausführen kann."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-action-link",
              children: "CrmActionLink"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein anklickbarer Link, der eine integrierte Reihe von CRM-Aktionen ausführen kann."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-card-actions",
              children: "CrmCardActions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kleinere eigenständige Schaltflächen oder Dropdown-Menüs, die mehrere CRM-Aktionen enthalten können."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}