"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280249510;
const slug = exports.slug = 'guides/apps/marketplace/applying-for-app-certification';
const title = exports.title = "Demander une certification d'application";
const name = exports.name = 'EMEA (FR) Applying for app certification';
const metaDescription = exports.metaDescription = "La certification d'application permet aux utilisateurs de savoir que votre application a été examinée et approuvée par HubSpot, afin de créer une relation de confiance et d'attester de sa qualité pour les utilisateurs potentiels.";
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h3: "h3",
      p: "p",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Demander une certification d'application"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Qu'est-ce qu'une certification d'application ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La certification d'application implique que l'équipe qualité de l'écosystème HubSpot évalue votre application listée et confirme que celle-ci répond à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "ces critères"
      }), " en matière de sécurité, de confidentialité, de fiabilité, de performances, de facilité d'utilisation, d'accessibilité et de valeur ajoutée. Une fois votre application approuvée, la page de listing de votre application affichera un badge « Application certifiée HubSpot »."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pourquoi est-ce important ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un badge de certification sur le listing d'application permet aux clients existants et potentiels de savoir que l'équipe qualité de l'écosystème HubSpot a examiné et approuvé votre application. Cela permet de symboliser la qualité et de créer une relation de confiance avec les utilisateurs de l'application. Découvrez-en davantage sur la création d'une relation de confiance avec le client grâce à la certification des applications ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-customer-trust-through-certification",
        children: "ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comment cela fonctionne-t-il ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tout ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "partenaire d'application éligible"
      }), " peut demander la certification via son compte de développeur d'applications. L'équipe qualité de l'écosystème HubSpot examinera alors votre soumission et vous contactera afin de fournir un feedback ou de confirmer la certification de votre application."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mon application est-elle éligible à la certification ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Assurez-vous que votre application est éligible à la certification en consultant nos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "critères de certification"
      }), ". Vous ne pourrez pas demander la certification si votre application ne compte pas au moins 60 installations actives et ne présente pas le trafic d'API requis. Les installations actives sont le nombre de comptes de production uniques de HubSpot, non affiliés à votre organisation, affichant une activité d'application réussie au cours des 30 derniers jours."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comment demander la certification ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous ne pouvez envoyer qu'une seule application à la fois pour la certification. Si vous soumettez plusieurs applications pour certification en même temps, elles seront rejetées en fonction de l'ordre de soumission. Une fois votre application certifiée, vous pouvez en soumettre une autre pour certification."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour demander une certification :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/account/why-can-t-i-log-into-hubspot",
            children: "Connectez-vous"
          }), " à votre compte de développeur et accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketplace des applications > Listes"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Passez le curseur de la souris à droite de l'application que vous souhaitez certifier et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Faire certifier l'application"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/undefined-Jun-28-2021-07-51-13-13-PM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Saisissez l'", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL"
        }), " pour la vidéo de démonstration. Passez en revue les exigences nécessaires pour la vidéo de démonstration de l'application ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-demo",
          children: "ci-dessous"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fournissez des identifiants de test pour votre application afin que l'équipe qualité de l'écosystème HubSpot puisse évaluer sa fonctionnalité."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-certification.png",
        alt: "app-certification"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Soumettre la demande de certification"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conditions relatives à la vidéo de démonstration de l'application"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Des vidéos de démonstration aident l'équipe qualité de l'écosystème HubSpot à tester votre application. L'équipe n'examinera ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " votre application à moins que vous ne soumettiez une vidéo de démonstration qui réponde à toutes les exigences. Les vidéos promotionnelles de vente et de marketing seront rejetées. HubSpot ne partagera et ne publiera pas vos vidéos de démonstration."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La vidéo de démonstration doit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "durer au moins trois minutes ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "inclure des descriptions audio (recommandé) ou textuelles ;"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["inclure des descriptions de l'objectif de votre application et des cas d'utilisation courants.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Par exemple, « L'application Fiction aide les représentants commerciaux et d'intégration à coordonner les CRM. Les transactions conclues dans l'un des CRM pris en charge par Fiction peuvent générer automatiquement des tickets et des tâches d'intégration dans HubSpot. » ;"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["démontrer et décrire comment les nouveaux utilisateurs doivent :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["installer votre application.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Par exemple, « « Dans le listing d'application de Fiction sur le marketplace des applications de HubSpot, cliquez sur Installer l'application, sélectionnez votre CRM, saisissez vos identifiants, cliquez sur Terminé, sélectionnez votre compte HubSpot, vérifiez les domaines demandés, cliquez sur Connecter l'application. » ;"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["configurer votre application après l'installation.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Par exemple, « Une fois l'application installée, sélectionnez un pipeline de tickets en accédant à Paramètres > Intégrations > Applications connectées > Application Fiction > Pipeline de tickets. Ensuite, configurez jusqu'à 10 tâches par défaut dans la section Modèles de tâches. Lorsque vous êtes prêt à activer la synchronisation, cliquez sur Synchronisation des tickets. » ;"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["utiliser les fonctionnalités principales de votre application pour répondre aux cas d'utilisation courants.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Par exemple, « Pour chaque transaction conclue dans le CRM connecté, la fonctionnalité Synchronisation des tickets créera une fiche d'informations de ticket dans HubSpot avec tous les contacts associés et vos tâches configurées. Cela permet à votre équipe d'intégration de se connecter immédiatement avec le nouveau client. » ;"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["interagir avec votre application à l'intérieur de leur compte HubSpot pour répondre aux cas d'utilisation courants (le cas échéant).", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Par exemple, « Pour créer des tickets d'intégration à partir de transactions HubSpot, utilisez l'action de workflow personnalisé Créer un ticket Fiction dans les workflows basés sur des transactions. Ces actions garantissent une personnalisation accrue par rapport aux paramètres de l'application pour d'autres CRM. » ;"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "déconnecter votre application de leur compte HubSpot ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "désinstaller votre application à partir de leur compte HubSpot, en décrivant comment cela affecte les comptes et les données des utilisateurs HubSpot."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Conseil : Vous pouvez utiliser l'outil gratuit"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.loom.com/",
          children: "Loom"
        }), " pour enregistrer une vidéo de démonstration."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pourquoi le CTA de certification n'apparaît-il pas pour mon application ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le bouton Faire certifier l'application apparaîtra uniquement si votre application est éligible. Veuillez consulter nos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "critères de certification"
      }), " ou contacter votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-partner-manager",
        children: "responsable des partenaires"
      }), " si vous avez des questions concernant l'éligibilité."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comment les utilisateurs savent-ils que mon application est certifiée ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois votre application certifiée, votre listing du marketplace des applications présente un badge « Application certifiée HubSpot »."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/dw7gvVRNzsrmtUwOYBsLVhpAEniaAZ5Z-_G70J-Bzs596yGR_ZzLXJyw821l7FVCV53XmKoI1VVEFxX9YKAJxtI-4NjwvCJadqfWyLG8IyFOWROZ6k8Nx-1307mp6cE0T8p980-N",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsqu'un client passe le curseur de la souris sur le badge, il verra des informations supplémentaires sur la certification."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Trouvez votre ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "gestionnaire de partenaires d'applications"
        }), " et ses coordonnées en vous connectant à votre compte de développeur et en accédant à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace des applications"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), ". Passez le curseur sur votre application et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Plus"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Afficher les détails du listing"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documents associés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Conditions relatives à la certification des applications"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Conditions relatives aux listings d'applications"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}