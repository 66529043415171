"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 43188646324;
const slug = exports.slug = 'guides/api/crm/pipelines';
const title = exports.title = 'CRM API | パイプライン';
const name = exports.name = 'APAC JAPAN (ja) | vNext Docs DP - Pipelines_JA | 202008';
const metaDescription = exports.metaDescription = 'パイプラインエンドポイントは、HubSpot CRMおよびサービスツールでパイプラインを表示、作成、管理するために使用されます。';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h3: "h3",
      code: "code",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "パイプライン"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのパイプラインは、取引ステージまたはチケットステータスが設定される場所です。営業部門では、パイプラインを活用して収益の予測や阻害要因の特定ができます。カスタマーサービス部門では、パイプラインを活用してチケットステータスの管理や課題の分析ができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パイプラインに含まれる各ステージは個別の内部IDで識別されます。つまり、ステージは1つのパイプラインにのみ含めることができます。各パイプラインには少なくとも1つのステージが含まれていて、各アカウントには少なくとも1つのパイプラインが存在している必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既定のパイプライン"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのアカウントには最初、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pipelineId"
      }), "が「default」である既定のパイプラインが含まれています。1つのパイプラインしかないアカウントでは、全てのオブジェクトのパイプラインプロパティーも自動的に「default」に設定されます。複数のパイプラインがあるアカウント上で既定のパイプラインにないステージを設定する場合、対応するパイプラインのプロパティーも設定する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "複数のパイプライン"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "複数の取引パイプライン"
      }), "は、Sales Hub ProfessionalまたはEnterpriseのアカウントのみで作成可能です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["同様に、複数の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/tickets/customize-ticket-pipelines-and-statuses",
        children: "チケットパイプライン"
      }), "を作成するには、Service Hub ProfessionalまたはEnterpriseの契約が必要です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotの契約レベルの詳細は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/jp/hubspot-product-and-services-catalog",
        children: "こちら"
      }), "で確認してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**使用例：**取引機能の活用に際し、アカウント上に「新規顧客」用のパイプラインを1つ、「契約更新」用としてもう1つ用意する場合があります。チケットについては、メインのサポートキューと、エスカレーション用のもう1つのキューを用意することがあります。各キューは別個のチケットパイプラインになります。1つのパイプラインまたはもう1つのパイプラインを外部のCRMに同期するために、パイプラインのエンドポイントを利用できます。"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}