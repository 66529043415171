"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164592946577;
const slug = exports.slug = 'reference/ui-components/overview';
const title = exports.title = 'Componentes de extensión de la IU';
const name = exports.name = 'Visión general de los componentes de IU';
const metaDescription = exports.metaDescription = 'Conoce los componentes que puedes utilizar para crear extensiones de interfaz de usuario.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "componentes-est%C3%A1ndar",
  "label": "Componentes estándar",
  "parent": null
}, {
  "depth": 0,
  "id": "componentes-de-datos-de-crm",
  "label": "Componentes de datos de CRM",
  "parent": null
}, {
  "depth": 0,
  "id": "componentes-de-acci%C3%B3n-de-crm",
  "label": "Componentes de acción de CRM",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visión general de los componentes de la interfaz de usuario (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear una [extensión de interfaz de usuario ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create"
      }), ", puedes incluir cualquiera de los componentes reutilizables proporcionados por HubSpot. Estos componentes van desde simples campos de texto a informes de objetos CRM listos para usar, y cada componente ofrece opciones de personalización mediante propiedades."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los componentes se importan en la parte superior de tu archivo de extensiones ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "tsx"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "jsx"
      }), ". Según el tipo de componente, tendrás que importarlos de uno de los dos directorios del SDK."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#standard-components",
          children: "Los componentes estándar"
        }), " se importan de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubspot/ui-extensions'"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#crm-data-components",
          children: "Los datos de CRM"
        }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#crm-action-components",
          children: "los componentes de la acción de CRM"
        }), " se importan de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubspot/ui-extensions/crm'"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Alert, Text } from '@hubspot/ui-extensions';\nimport { CrmAssociationPivot, CrmActionLink } from '@hubspot/ui-extensions/crm';\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " para acceder a los últimos componentes, asegúrate de que has instalado el último paquete npm ejecutando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm i @hubspot/ui-extensions"
        }), " en el directorio ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "extensions"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Componentes estándar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los componentes estándar son componentes que pueden utilizarse tanto para datos internos como externos. Estos componentes no obtienen datos por sí mismos, sino que son más flexibles en su implementación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Estos componentes se importan de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'@hubspot/ui-extensions'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Componente"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/accordion",
              children: "Acordeón"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una sección de acordeón plegable que puede contener otros componentes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/alert",
              children: "Alerta"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alertas para indicar estados y resultados de acciones, como mensajes de éxito y error."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/box",
              children: "Caja"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Componente utilizado para la gestión del diseño. Puede usarse con ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/flex",
              children: "Flex"
            }), ". Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/manage-ui-extension-layout",
              children: "gestión del diseño de extensiones"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Botón"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Botones que permiten a los usuarios realizar acciones, como enviar o recuperar datos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button-row",
              children: "Fila de botones"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Para representar varios botones."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/checkbox",
              children: "Casilla de verificación"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una entrada de casilla de verificación única. Para mostrar varias casillas de verificación, utiliza en su lugar ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/date-input",
              children: "DateInput"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Campo que permite a los usuarios seleccionar una fecha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/description-list",
              children: "DescriptionList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrando pares de etiquetas y valores personalizados, de forma similar a como aparecen las propiedades de HubSpot en la barra lateral izquierda de los registros de CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/divider",
              children: "Separador"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Línea horizontal para separar componentes dentro de una extensión."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/dropdown",
              children: "Menú desplegable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un menú desplegable para seleccionar valores, con estilo de botón o hipervínculo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/empty-state",
              children: "EmptyState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ilustración con etiqueta para indicar un componente sin contenido."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/error-state",
              children: "ErrorState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ilustraciones con etiquetas para indicar errores."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/flex",
              children: "Flex"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Envuelve otros componentes en un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "div"
            }), " vacío con el valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display=flex"
            }), ". Utiliza este componente y ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/box",
              children: "Caja"
            }), " para ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/manage-ui-extension-layout",
              children: "gestionar el diseño de las extensiones"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Formulario"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un formulario para enviar datos, que puede contener otros componentes relacionados como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/input",
              children: "Entrada"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Seleccionar"
            }), ", y ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Botón"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/heading",
              children: "Encabezamiento"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra texto grande para los títulos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/icon",
              children: "Icono"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Presenta un icono visual dentro de otros componentes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Imagen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una imagen, utilizada principalmente para agregar logotipos u otros recursos visuales de identidad de marca, o para acentuar otros contenidos de la Extensión."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/input",
              children: "Entrada"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Campo de entrada de texto donde los usuarios pueden introducir valores de texto personalizados. Se usa principalmente en los componentes del formulario](/reference/ui-components/standard-components/form) de [."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Enlace"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un hipervínculo en el que se puede hacer clic para navegar a páginas externas y de la aplicación de HubSpot, o para desencadenar funciones."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/list",
              children: "Lista"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista ordenada o desordenada de elementos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NUEVO"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Similar a un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "componente Button"
            }), " con opciones de estado de carga adicionales."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-spinner",
              children: "LoadingSpinner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un indicador visual de que la tarjeta se está cargando o procesando."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/modal",
              children: "Modal"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NUEVO"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una superposición emergente que contiene otros componentes. Útil para mensajes cortos y cuadros de confirmación de acciones. Se puede abrir con los componentes ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            }), " ."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/multi-select",
              children: "MultiSelect"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un campo de selección desplegable en el que los usuarios pueden seleccionar varios valores. Para permitir que sólo se seleccione un valor, utiliza en su lugar ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Seleccionar"
            }), ". Se usa principalmente en los componentes del formulario](/reference/ui-components/standard-components/form) de [."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/number-input",
              children: "NumberInput"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Campo de introducción de números. Se usa principalmente en los componentes del formulario](/reference/ui-components/standard-components/form) de [."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/panel",
              children: "Panel"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NUEVO"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Panel que se abre en la parte derecha de la página y que contiene otros componentes. Se puede abrir con los componentes ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            }), " ."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/progress-bar",
              children: "ProgressBar"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Representación visual de datos en movimiento hacia un objetivo positivo o negativo. Puede mostrar tanto números como porcentajes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/radio-button",
              children: "RadioButton"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un botón de radio de selección. Si quieres incluir más de dos botones de opción, o estás construyendo un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Formulario"
            }), ", se recomienda utilizar en su lugar ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Seleccionar"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Campo de selección desplegable en el que el usuario puede seleccionar un único valor. Para poder seleccionar varios valores, utiliza en su lugar ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/multi-select",
              children: "MultiSelect"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/statistics",
              children: "Estadísticas"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Presentación visual de uno o varios puntos de datos. Incluye valores numéricos e indicadores de tendencia (porcentaje creciente/decreciente)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/status-tag",
              children: "StatusTag (Etiqueta de estado)"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NUEVO"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un indicador visual para mostrar el estado actual de un elemento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/step-indicator",
              children: "StepIndicator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un indicador visual para describir el progreso dentro de un proceso de varios pasos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/stepper-input",
              children: "StepperInput"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Similar al componente ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/number-input",
              children: "NumberInput"
            }), ", pero este campo permite a los usuarios aumentar o disminuir el valor en una cantidad establecida."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/table",
              children: "Tabla"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrando datos en columnas y filas. Las tablas pueden paginarse y ordenarse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Etiqueta"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Etiquetas de colores para clasificar la información u otros componentes. Puede ser estático o se puede hacer clic para desencadenar funciones."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text",
              children: "Texto"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Representa texto con opciones de formato."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tile",
              children: "Azulejo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un contenedor rectangular con bordes para crear grupos de componentes relacionados."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text-area",
              children: "TextArea"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Similar a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text",
              children: "Texto"
            }), ", pero para conjuntos de texto más largos. Incluye accesorios para configurar el tamaño del campo, los caracteres máximos y la posibilidad de cambiar el tamaño."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle",
              children: "Toggle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un conmutador booleano que puede configurarse con opciones de tamaño y etiquetas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista de casillas de verificación o botones de radio que se pueden seleccionar."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Componentes de datos de CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los componentes de datos de CRM pueden extraer datos directamente del registro de CRM que se esté mostrando en ese momento, incluyendo información sobre registros asociados e informes de un solo objeto. Estos componentes sólo pueden colocarse en la columna central de los registros de CRM. Más información sobre los componentes de datos de CRM ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-data-components/overview"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Estos componentes se importan de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Componente"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-pivot",
              children: "CrmAssociationPivot"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lista de registros asociados al registro que se está reproduciendo, organizados por su etiqueta de asociación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-property-list",
              children: "CrmAssociationPropertyList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista editable de propiedades de CRM pertenecientes a un registro asociado con el registro que se está mostrando en ese momento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-stage-tracker",
              children: "CrmAssociationStageTracker"
            }), " NUEVO"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una barra de progreso de ciclo de vida o etapa de pipeline que muestra los datos de los registros asociados. Puede incluir una lista de propiedades que se pueden editar en línea."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-table",
              children: "CrmAssociationTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una tabla de registros asociados al registro que se está mostrando actualmente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-data-highlight",
              children: "CrmDataHighlight"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista de propiedades de CRM pertenecientes al registro que se está mostrando actualmente o a otro registro especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-property-list",
              children: "CrmPropertyList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista editable de propiedades de CRM pertenecientes al registro que se está mostrando actualmente o a otro registro especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-report",
              children: "CrmReport"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrando un informe existente de un solo objeto. Incluye opciones de filtrado, o puede mostrar todos los datos del informe sin filtrar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-stage-tracker",
              children: "CrmStageTracker"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una barra de progreso del ciclo de vida o de la etapa de pipeline con una lista de propiedades."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-statistics",
              children: "CrmStatistics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrando resúmenes de los datos calculados a partir de las asociaciones del registro que se está mostrando. Por ejemplo, los ingresos medios de las empresas asociadas a un contacto."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Componentes de acción de CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los componentes de acción de CRM proporcionan un conjunto integrado de acciones relacionadas con CRM, como agregar notas a los registros, abrir una ventana de composición de correo electrónico individual, crear nuevos registros y mucho más. Cada componente puede realizar el mismo conjunto de acciones, por lo que el componente que elijas dependerá de tus necesidades y preferencias. Más información sobre los componentes de la acción ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-action-components/overview",
        children: "CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los componentes de la acción CRM se importan de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Componente"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-action-button",
              children: "CrmActionButton"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un botón que puede ejecutar un conjunto integrado de acciones de CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-action-link",
              children: "CrmActionLink"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un enlace en el que se puede hacer clic y que puede ejecutar un conjunto integrado de acciones de CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-card-actions",
              children: "CrmCardActions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Botones de menú independientes o desplegables más pequeños que pueden contener varias acciones de CRM."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}