"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 44206971922;
const slug = exports.slug = 'getting-started/slack/code-of-conduct';
const title = exports.title = 'Código de conducta de la comunidad Developer Slack de HubSpot';
const name = exports.name = 'Código de conducta de Developer Slack';
const metaDescription = exports.metaDescription = 'Developer Slack de HubSpot es una comunidad dedicada a los desarrolladores más comprometidos de HubSpot para que se reúnan en nombre de la asistencia entre desarrolladores y la colaboración en torno a la plataforma HubSpot.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Colabora en Developer Slack';
const toc = exports.toc = [{
  "depth": 0,
  "id": "pre%C3%A1mbulo",
  "label": "Preámbulo",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizaciones-de-este-c%C3%B3digo-de-conducta",
  "label": "Actualizaciones de este código de conducta",
  "parent": null
}, {
  "depth": 0,
  "id": "expectativas-de-asistencia-de-hubspot",
  "label": "Expectativas de asistencia de HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "comportamiento-esperado",
  "label": "Comportamiento esperado",
  "parent": null
}, {
  "depth": 1,
  "id": "s%C3%A9-humilde",
  "label": "Sé humilde",
  "parent": "comportamiento-esperado"
}, {
  "depth": 1,
  "id": "s%C3%A9-emp%C3%A1tico",
  "label": "Sé empático",
  "parent": "comportamiento-esperado"
}, {
  "depth": 1,
  "id": "s%C3%A9-adaptable",
  "label": "Sé adaptable",
  "parent": "comportamiento-esperado"
}, {
  "depth": 1,
  "id": "s%C3%A9-notable",
  "label": "Sé notable",
  "parent": "comportamiento-esperado"
}, {
  "depth": 1,
  "id": "s%C3%A9-transparente",
  "label": "Sé transparente",
  "parent": "comportamiento-esperado"
}, {
  "depth": 0,
  "id": "comportamiento-inaceptable",
  "label": "Comportamiento inaceptable",
  "parent": null
}, {
  "depth": 1,
  "id": "amenazas-de-violencia",
  "label": "Amenazas de violencia",
  "parent": "comportamiento-inaceptable"
}, {
  "depth": 1,
  "id": "ataques-personales",
  "label": "Ataques personales",
  "parent": "comportamiento-inaceptable"
}, {
  "depth": 1,
  "id": "suplantaci%C3%B3n-de-empleo-en-hubspot",
  "label": "Suplantación de empleo en HubSpot",
  "parent": "comportamiento-inaceptable"
}, {
  "depth": 1,
  "id": "lenguaje-despectivo%2Fperjudicial-y-acoso",
  "label": "Lenguaje despectivo/perjudicial y acoso",
  "parent": "comportamiento-inaceptable"
}, {
  "depth": 1,
  "id": "solicitud-no-deseada-de-servicios",
  "label": "Solicitud no deseada de servicios",
  "parent": "comportamiento-inaceptable"
}, {
  "depth": 1,
  "id": "abuso-de-llamadas-de-voz%2Fvideo",
  "label": "Abuso de llamadas de voz/video",
  "parent": "comportamiento-inaceptable"
}, {
  "depth": 1,
  "id": "uso-no-divulgado-de-la-ia-en-preguntas%2C-respuestas-o-conversaciones",
  "label": "Uso no divulgado de la IA en preguntas, respuestas o conversaciones",
  "parent": "comportamiento-inaceptable"
}, {
  "depth": 1,
  "id": "spamming-de-canales-y-usuarios",
  "label": "Spamming de canales y usuarios",
  "parent": "comportamiento-inaceptable"
}, {
  "depth": 1,
  "id": "influencia-del-comportamiento-inaceptable",
  "label": "Influencia del comportamiento inaceptable",
  "parent": "comportamiento-inaceptable"
}, {
  "depth": 0,
  "id": "uso-de-developer-slack-de-hubspot-para-conversaciones-de-equipo-privados",
  "label": "Uso de Developer Slack de HubSpot para conversaciones de equipo privados",
  "parent": null
}, {
  "depth": 0,
  "id": "llamadas-de-voz%2Fvideo-%2B-compartir-pantalla",
  "label": "Llamadas de voz/video + Compartir pantalla",
  "parent": null
}, {
  "depth": 0,
  "id": "uso-de-las-%22apps%22-de-slack",
  "label": "Uso de las \"Apps\" de Slack",
  "parent": null
}, {
  "depth": 0,
  "id": "mantener-temas-juntos-mensajes-en-cadena",
  "label": "Mantener temas juntos (mensajes en cadena)",
  "parent": null
}, {
  "depth": 0,
  "id": "gifs-y-emojis",
  "label": "Gifs y Emojis",
  "parent": null
}, {
  "depth": 0,
  "id": "encuestas-de-usuarios-del-developer-slack",
  "label": "Encuestas de usuarios del Developer Slack",
  "parent": null
}, {
  "depth": 0,
  "id": "c%C3%B3mo-denunciar-infracciones-del-c%C3%B3digo-de-conducta",
  "label": "Cómo denunciar infracciones del código de conducta",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      a: "a",
      strong: "strong",
      h3: "h3",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Código de conducta de Developer Slack"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preámbulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Developer Slack de HubSpot es una comunidad dedicada a que los desarrolladores de HubSpot se reúnan en nombre de la asistencia de desarrollador a desarrollador y la colaboración en torno a la plataforma HubSpot. Al utilizar nuestro Developer Slack, aceptas cumplir el siguiente Código de conducta de Developer Slack (\"Código de conducta\"), ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "Política de uso aceptable de HubSpot"
      }), " según corresponda, y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://slack.com/acceptable-use-policy",
        children: "Política de uso aceptable de Slack"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizaciones de este código de conducta"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot se reserva el derecho de actualizar este código de conducta en cualquier momento. Cuando se produzcan actualizaciones, nos comprometemos a comunicar estos cambios a través del canal #releases-and-updates en el Developer Slack, pero como este código de conducta está redactado previendo escenarios comunes, y no podemos prever todos los escenarios posibles que puedan ocurrir, es posible que tengamos que actualizarlo y tomar medidas ante infracciones que no estén explícitamente descritas aquí sin notificación previa a la comunidad."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot se reserva el derecho a bajar de nivel o desactivar el Developer Slack en cualquier momento, pero se compromete a hacerlo con suficiente antelación a la comunidad."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Expectativas de asistencia de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El Developer Slack no es un canal de asistencia oficial de HubSpot. Aunque este Slack puede contener empleados de HubSpot que están dispuestos a ayudar cuando pueden, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "abstente de mencionar o enviar mensajes a empleados de HubSpot con preguntas directas"
      }), ". Si tienes alguna pregunta en la que necesites asistencia directa de HubSpot, por favor visita ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://help.hubspot.com/",
        children: "help.hubspot.com"
      }), " o los foros de la comunidad ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/"
      }), ". HubSpot se reserva el derecho de hacer cumplir, interpretar y ampliar estas directrices para la mejora de la comunidad."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comportamiento esperado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En HubSpot no tenemos páginas de políticas o procedimientos y en su lugar tenemos una política de tres palabras de Usa buen juicio y ten CORAZÓN. Animamos a nuestras comunidades a utilizar también el buen juicio y a compartir los siguientes valores fundamentales de HubSpot:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sé humilde"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nada es perfecto. Sé consciente de ti mismo y sé siempre respetuoso."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sé empático"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No te limites a ser comprensivo con los comentarios de los demás, actúa también con compasión y respeto hacia todos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sé adaptable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sé curioso y además, trata de estar siempre dispuesto a cambiar. Aprende durante toda la vida."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sé notable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En palabras del sabio Bill S. Preston, Esquire \"Debemos tener la mejor actitud hacia los demás\". Comparte tus conocimientos y no tengas miedo de ser una buena persona."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sé transparente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sé abierto y sincero con los demás y, sobre todo, contigo mismo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para saber más sobre nuestros valores, consulta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/34234/the-hubspot-culture-code-creating-a-company-we-love.aspx",
        children: "Código cultural de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comportamiento inaceptable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los siguientes comportamientos se consideran inaceptables y no serán tolerados por cualquiera de los usuarios del espacio de trabajo Slack."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Amenazas de violencia"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No se aceptan las amenazas de violencia hacia otro usuario. Esto incluye fomentar la violencia hacia un(os) usuario(s) o publicar/amenazar con publicar información de identificación personal de otras personas (conocido como \"Doxxing\")."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ataques personales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Siempre habrá momentos en los que dos partes tendrán una diferencia de opinión. Aunque esto puede ser frustrante, nunca debe convertirse en un ataque personal contra alguien. Atacar a la gente por sus opiniones, creencias personales, ideas o sugerencias no es aceptable. Por favor, sé profesional y respetuoso en todo momento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Suplantación de empleo en HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cualquiera de los usuarios que se haga pasar por un empleado de HubSpot (o que esté contratado por HubSpot) será expulsado del espacio de trabajo de Slack. Regla de tolerancia cero."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Lenguaje despectivo/perjudicial y acoso"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lenguaje perjudicial hacia un usuario:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fondo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Familiar"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Identidad o expresión de género"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Orientación sexual"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Raza/Etnicidad"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rasgos físicos (tamaño corporal, forma, cicatrices, etc.)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Edad"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Idioma materno"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Habilidades"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Origen nacional"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ubicación"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Religión"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Afiliación política"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Otros atributos personales"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "no es aceptable. Usa el buen juicio en todo momento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Solicitud no deseada de servicios"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es inaceptable enviar mensajes directamente a los usuarios ofreciendo tus servicios, a menos que el usuario haya solicitado específicamente esta información. Esto también incluye mensajes directos sobre oportunidades de empleo o proyectos que se realicen bajo la modalidad freelance. Hemos habilitado un canal #jobs dentro de nuestro espacio de trabajo en Slack donde se pueden publicar este tipo de mensajes, y un canal #showcase para destacar cualquier trabajo de HubSpot del que te sientas orgulloso con la comunidad. Estos son los dos únicos canales en los que se permite cualquiera de las formas de solicitud o autopromoción."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abuso de llamadas de voz/video"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack permite el uso de llamadas de voz/video dentro de su plataforma. Es inaceptable abusar de esta característica llamando directamente a los usuarios sin su permiso. Entendemos que a veces se producen errores y se hace clic en los botones por accidente. Si esto ocurre, sé profesional y simplemente envía un mensaje de disculpa al usuario."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Uso no divulgado de la IA en preguntas, respuestas o conversaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vas a utilizar una herramienta de IA para generar contenido que se utilizará en el Developer Slack, debes revelar claramente el uso de dichas herramientas en todos y cada uno de los casos en que las utilices. También debes ser consciente de la información que proporcionas a una herramienta de IA y no compartir cualquiera información confidencial o sensible de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Spamming de canales y usuarios"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El Developer Slack tiene muchos canales adaptados a determinadas funciones de nuestra plataforma y a muchos usuarios. Cuando publiques tu mensaje, por favor, utiliza el canal apropiado para publicarlo. Es inaceptable que simplemente copies/pegues tu mensaje en varios canales para aumentar la visibilidad. Es inaceptable publicar el mismo mensaje o un mensaje similar con frecuencia dentro del mismo canal y/o enviar spam directamente a los usuarios."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Influencia del comportamiento inaceptable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es inaceptable influir en cualquiera de los usuarios para que participen en cualquiera de los comportamientos inaceptables enumerados anteriormente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso de Developer Slack de HubSpot para conversaciones de equipo privados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al unirte al Developer Slack de HubSpot, te comprometes a contribuir a la Comunidad de desarrolladores de HubSpot en general. No se desalienta ningún tema de conversación (que cumpla con este código de conducta), pero te pedimos que no utilices el Developer Slack para llevar a cabo asuntos privados de la empresa a través de la mensajería directa u otros medios. Si se descubre tal conducta, nos reservamos el derecho a eliminarte a ti y a tu equipo del Developer Slack."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Llamadas de voz/video + Compartir pantalla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack ofrece a los usuarios la posibilidad de utilizar las llamadas de voz/video con la capacidad añadida de compartir la pantalla. Cuando utilices estas características, sé consciente y respetuoso con los demás. Usa tu buen juicio cuando compartas tu pantalla y ten en cuenta otras ventanas que puedan estar abiertas en tu computadora y que contengan información sensible o personal."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso de las \"Apps\" de Slack"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack tiene la posibilidad de agregar aplicaciones al espacio de trabajo para ayudar a ampliar la funcionalidad de Slack. No permitimos que los usuarios instalen aplicaciones por su cuenta en el espacio de trabajo Slack para desarrolladores. Todas las instalaciones de aplicaciones en el espacio de trabajo las hará HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mantener temas juntos (mensajes en cadena)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una de las grandes características de Slack es la posibilidad de encadenar comentarios. Para facilitar las conversaciones y que se puedan buscar/escanear fácilmente, recomendamos utilizar esta característica. Ayuda a mantener limpios los canales principales y agrupa el tema de las conversaciones en un solo lugar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gifs y Emojis"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando utilices gifs dentro de los canales o comentarios encadenados, ten en cuenta el contenido/contexto dentro del gif. Asimismo, Slack ofrece la posibilidad de que los emojis expresen emociones en relación con los comentarios. Ten en cuenta los emojis que utilizas cuando te comunicas con otras personas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Encuestas de usuarios del Developer Slack"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El espacio de trabajo del Developer Slack está pensado para ser utilizado en conversaciones sobre el desarrollo en la web y el desarrollo en HubSpot. Crear encuestas está permitido y hemos proporcionado la aplicación Polly Slack en nuestro espacio de trabajo para que cualquiera pueda utilizarla."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al utilizar Polly, requerimos lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La encuesta debe estar relacionada con el desarrollo en la web (como Bibliotecas CSS o JS) o el desarrollo en HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "\"Mostrar respuestas\" debe permanecer siempre marcada."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No se permite lo siguiente cuando se encuesta a personas en el Developer Slack:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Solicitud de información privada, incluyendo información demográfica, médica u otra información personal de los usuarios"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Enlace a encuestas alojadas en sitios de terceros (como formularios de google, formularios de Survey Monkey o sitios web personales).*"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Enviar mensajes directos a los usuarios pidiéndoles que rellenen encuestas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "* = Los empleados de HubSpot se reservan el derecho de encuestar en el Developer Slack de HubSpot con el propósito de realizar mejoras a los productos de HubSpot. Empleados de HubSpot se esforzarán por dejar claro que su encuesta es una encuesta oficial de HubSpot si está alojada en otro lugar."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cómo denunciar infracciones del código de conducta"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para denunciar una infracción de este código de conducta, o cualquier cosa que pueda haberte incomodado a ti o a otros en la comunidad de desarrolladores de HubSpot, puedes ponerte en contacto directamente con cualquiera de nuestros administradores. Todos los administradores actuales se indicarán en una publicación fijada en el canal #general."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si prefieres denunciar la infracción de forma anónima o prefieres no hacer DM a un administrador, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://form.asana.com/",
        children: "rellenar este formulario"
      }), " y se nos notificará inmediatamente."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}