"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45279037467;
const slug = exports.slug = 'guides/api/crm/pipelines';
const title = exports.title = 'API de CRM | Pipelines';
const name = exports.name = 'EMEA ES vNext Docs DP - Pipelines_ES';
const metaDescription = exports.metaDescription = 'Los puntos de terminación de pipelines se utilizan para ver, crear y administrar pipelines en HubSpot CRM y en las herramientas de servicio.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h3: "h3",
      code: "code",
      a: "a",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Pipelines"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En HubSpot, un pipeline es donde se establecen las etapas de negocios o los estados de tickets. Para las ventas, se pueden usar pipelines para predecir los ingresos e identificar obstáculos. Para el servicio, se pueden usar pipelines para administrar los estados de tickets y analizar los bloqueadores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada etapa en un pipeline se identifica mediante un ID interno único, lo que significa que solo puede ser miembro de un pipeline. Cada pipeline siempre tiene al menos una etapa y cada cuenta tiene al menos un pipeline."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pipelines predeterminados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada cuenta contiene inicialmente un pipeline predeterminado con el valor «predeterminado» para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pipelineId"
      }), ". En las cuentas con un solo pipeline, la propiedad de pipeline para cualquier objeto se establecerá automáticamente como «predeterminado». En las cuentas con múltiples pipelines, si estás configurando una etapa que no está en el pipeline predeterminado, también deberás establecer la propiedad de pipeline correspondiente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Varios pipelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Solo cuentas de Sales Hub Pro o Enterprise pueden crear ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "varios pipelines de negocios"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["De manera similar, debes tener una suscripción de Service Hub Pro o Enterprise para crear varios ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/tickets/customize-ticket-pipelines-and-statuses",
        children: "pipelines de tickets"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre los niveles de suscripción de HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Casos de uso de ejemplo:"
      }), " Cuando trabajas con negocios, una cuenta podría tener un pipeline para «Nuevas ventas» y otro para «Renovaciones de contratos». Para tickets, podrías tener una cola de asistencia técnica principal y otra diferente para escalaciones. Cada uno de esas colas sería un pipeline de tickets independiente. Los puntos de terminación de los pipelines se pueden usar para sincronizar un pipeline u otro para un CRM externo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}