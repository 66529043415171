"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 142116065450;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-definitions';
const title = exports.title = 'Définir des événements personnalisés';
const name = exports.name = 'Définir des événements personnalisés';
const metaDescription = exports.metaDescription = "Découvrez-en davantage sur la création d'événements personnalisés à l'aide de l'API de HubSpot.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-une-d%C3%A9finition-d-%C3%A9v%C3%A9nement",
  "label": "Créer une définition d'événement",
  "parent": null
}, {
  "depth": 0,
  "id": "propri%C3%A9t%C3%A9s-d-%C3%A9v%C3%A9nement",
  "label": "Propriétés d'événement",
  "parent": null
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s-d-%C3%A9v%C3%A9nement-par-d%C3%A9faut-de-hubspot",
  "label": "Propriétés d'événement par défaut de HubSpot",
  "parent": "propri%C3%A9t%C3%A9s-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "d%C3%A9finir-de-nouvelles-propri%C3%A9t%C3%A9s",
  "label": "Définir de nouvelles propriétés",
  "parent": "propri%C3%A9t%C3%A9s-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "mise-%C3%A0-jour-des-propri%C3%A9t%C3%A9s-personnalis%C3%A9es-existantes",
  "label": "Mise à jour des propriétés personnalisées existantes",
  "parent": "propri%C3%A9t%C3%A9s-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "supprimer-une-propri%C3%A9t%C3%A9",
  "label": "Supprimer une propriété",
  "parent": "propri%C3%A9t%C3%A9s-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 0,
  "id": "mise-%C3%A0-jour-d-un-%C3%A9v%C3%A9nement",
  "label": "Mise à jour d'un événement",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-un-%C3%A9v%C3%A9nement",
  "label": "Supprimer un événement",
  "parent": null
}, {
  "depth": 0,
  "id": "obtenir-des-d%C3%A9finitions-d-%C3%A9v%C3%A9nements-existants",
  "label": "Obtenir des définitions d'événements existants",
  "parent": null
}, {
  "depth": 0,
  "id": "exemple-d-%C3%A9v%C3%A9nement-personnalis%C3%A9",
  "label": "Exemple d'événement personnalisé",
  "parent": null
}, {
  "depth": 1,
  "id": "cr%C3%A9er-une-d%C3%A9finition-d-%C3%A9v%C3%A9nement-personnalis%C3%A9",
  "label": "Créer une définition d'événement personnalisé",
  "parent": "exemple-d-%C3%A9v%C3%A9nement-personnalis%C3%A9"
}, {
  "depth": 1,
  "id": "cr%C3%A9er-une-propri%C3%A9t%C3%A9-sur-la-d%C3%A9finition-d-un-%C3%A9v%C3%A9nement-personnalis%C3%A9",
  "label": "Créer une propriété sur la définition d'un événement personnalisé",
  "parent": "exemple-d-%C3%A9v%C3%A9nement-personnalis%C3%A9"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      ul: "ul",
      li: "li",
      pre: "pre",
      h3: "h3",
      ol: "ol",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      ProductTier,
      Table,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Définir des événements personnalisés"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les événements personnalisés vous permettent de définir et de suivre des événements propres à votre entreprise, tels que des événements sur votre site ou dans une application. Vous pouvez configurer des événements pour stocker des informations au sein des propriétés, que vous pouvez ensuite utiliser dans l'ensemble des outils de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour envoyer des données d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "événement personnalisé"
      }), " à HubSpot, vous devez d'abord configurer l'événement. Ceci est similaire aux objets CRM personnalisés, pour lesquels vous devez d'abord définir l'objet personnalisé avant de pouvoir créer des fiches d'informations individuelles pour cet objet. La définition d'un événement comprend des détails tels que ses métadonnées, ses associations d'objets CRM et ses propriétés."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez ci-dessous des informations sur la création et la gestion de définitions d'événements à l'aide de l'API. Pour découvrir comment créer des définitions d'événements sans utiliser l'API, consultez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events-with-the-code-wizard",
        children: "la base de connaissances de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer une définition d'événement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer le schéma d'événement personnalisé, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions"
      }), ". Dans le corps de la demande, incluez les définitions de votre schéma d'événement, y compris son libellé, son nom, ses associations d'objets CRM et ses propriétés personnalisées."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez ci-dessous un exemple de corps de requête ainsi que la définition des paramètres. Pour un exemple complet, consultez l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#custom-event-example",
        children: "exemple d'événement personnalisé ci-dessous"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "label"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "chaîne"
                    }), " (obligatoire)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Le libellé lisible par l'homme de l'événement, qui s'affichera dans HubSpot (jusqu'à 100 caractères). Les longs libellés peuvent être coupés dans certaines parties de l'interface utilisateur de HubSpot."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "name"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "chaîne"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Le nom interne unique de l'événement, que vous utiliserez pour référencer l'événement via l'API. Si aucune valeur n'est fournie, HubSpot en générera automatiquement une en fonction de ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "label"
                    }), ". Cette propriété ", (0, _jsxRuntime.jsx)("u", {
                      children: "ne peut pas"
                    }), " être modifiée après la création de la définition de l'événement."]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Notez les exigences suivantes en matière d'appellation :"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "Cette propriété ne peut contenir que des lettres minuscules, des chiffres, des tirets bas et des traits d'union (jusqu'à 50 caractères)."
                    }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "Le premier caractère doit être une lettre."
                    }), "\n"]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "description"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "chaîne"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "La description de l'événement, qui s'affichera dans HubSpot."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "primaryObject"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "chaîne"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["L'objet CRM auquel l'événement sera associé. Les événements terminés s'affichent sur la chronologie des fiches d'informations de CRM de l'objet spécifié. Peut être ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "CONTACT"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "COMPANY"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "DEAL"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "TICKET"
                    }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "CUSTOM OBJECT NAME"
                    }), "."]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Si aucune valeur n'est fournie, HubSpot associera automatiquement l'événement aux contacts. Cela ", (0, _jsxRuntime.jsx)("u", {
                      children: "ne peut pas"
                    }), " être modifiée après la création de la définition de l'événement."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "propertyDefinitions"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "tableau"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Pour chaque définition d'événement personnalisé, HubSpot fournit un ensemble de propriétés par défaut que vous pouvez utiliser lors de l'envoi de données d'événement. Incluez ce tableau pour définir toutes les propriétés personnalisées dont votre événement a besoin en dehors de ces propriétés par défaut. Vous pouvez inclure jusqu'à 50 propriétés personnalisées par événement."
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Pour chaque propriété, incluez les éléments suivants :"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "label"
                      }), " : le libellé de la propriété dans l'application."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "name"
                      }), " : le nom interne de la propriété, que vous utiliserez lorsque vous enverrez des données sur la propriété via l'API."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "type"
                      }), " : le ", (0, _jsxRuntime.jsx)(_components.a, {
                        href: "#custom-property-types",
                        children: "type de propriété"
                      }), ". La valeur par défaut est ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "string"
                      }), "."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "options"
                      }), " : pour les propriétés ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "enumeration"
                      }), ", le tableau des valeurs prédéfinies, y compris ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "label"
                      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "value"
                      }), " pour chacune d'entre elles."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "description"
                      }), " : texte facultatif pour décrire la propriété."]
                    }), "\n"]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "#event-properties",
                      children: "propriétés des événements personnalisés"
                    }), " ci-dessous."]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request body\n{\n  \"label\": \"My event label\",\n  \"name\": \"unique_event_name\",\n  \"description\": \"An event description that helps users understand what the event tracks.\",\n  \"primaryObject\": \"COMPANY\",\n  \"propertyDefinitions\": [\n    {\n      \"name\": \"choice-property\",\n      \"label\": \"Choice property\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"label\": \"Option 1\",\n          \"value\": \"1\"\n        },\n        {\n          \"label\": \"Option 2\",\n          \"value\": \"2\"\n        }\n      ]\n    },\n    {\n      \"name\": \"string-property\",\n      \"label\": \"String property\",\n      \"type\": \"string\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriétés d'événement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les propriétés des événements personnalisés sont utilisées pour stocker des informations sur l'achèvement de chaque événement personnalisé. Ces propriétés doivent être utilisées le cas échéant pour l'envoi d'achèvements d'événements, mais elles ne sont pas nécessaires pour qu'un achèvement d'événement soit valide. Pour chaque définition d'événement, HubSpot fournit un ensemble de 32 propriétés par défaut. En outre, vous pouvez créer jusqu'à 50 propriétés personnalisées par définition d'événement."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les propriétés peuvent être des types suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), " : une propriété qui reçoit des chaînes de texte en clair. Si le nom de la propriété contient les mots ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "referrer"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "link"
        }), ", la valeur de la propriété peut comporter jusqu'à 1 024 caractères. Autrement, les valeurs des propriétés peuvent comporter jusqu'à 256 caractères."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        }), " : une propriété qui reçoit des valeurs numériques avec un maximum d'une décimale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), " : Une propriété avec des options prédéfinies. Lors de la création de ce type de propriété, incluez un tableau ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), " pour définir les valeurs disponibles."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "datetime"
        }), " : Une propriété avec des valeurs epoch (en millisecondes) ou ISO8601 représentant un horodatage."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ci-dessous, vous découvrirez les propriétés des événements par défaut de HubSpot, comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#define-new-properties",
        children: "définir de nouvelles propriétés pour les événements existants"
      }), " et comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#update-existing-custom-properties",
        children: "mettre à jour les propriétés des événements personnalisés existants"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés d'événement par défaut de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_asset_description"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_asset_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_browser"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_campaign_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_city"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_country"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_device_name"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_device_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_class"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_text"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_language"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_link_href"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_operating_system"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_operating_version"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_content_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_title"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_url"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_parent_module_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_referrer"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_region"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_screen_height"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_screen_width"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_touchpoint_source"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_tracking_name"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_user_agent"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_campaign"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_content"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_medium"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_source"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_term"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir de nouvelles propriétés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour définir une nouvelle propriété sur un événement personnalisé existant, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property"
      }), ". Dans le corps de la requête, incluez la définition de votre propriété."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"name\": \"property-name\",\n  \"label\": \"Property name\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"label\": \"label\",\n      \"value\": \"value\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous donnez un nom à votre propriété, gardez à l'esprit les éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Une fois que vous avez créé une propriété, son nom ne peut plus être modifié."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le nom ne peut contenir que des lettres minuscules, des chiffres, des tirets bas et des tirets."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le premier caractère du nom de la propriété doit être une lettre."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le nom de la propriété et le libellé peuvent comporter chacun jusqu'à 50 caractères."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si aucun nom de propriété n'est fourni, un nom sera généré automatiquement en utilisant le libellé de la propriété."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les longs libellés peuvent être coupés dans certaines parties de l'interface utilisateur de HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mise à jour des propriétés personnalisées existantes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour mettre à jour une propriété existante sur un événement personnalisé, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property"
      }), ". Les seuls champs qui peuvent être mis à jour sur une propriété sont les champs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), ", et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), " pour les propriétés d'énumération."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Pour modifier le type de propriété, utilisez le point de terminaison ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), " pour supprimer la propriété et la recréer avec le bon type."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer une propriété"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une propriété existante sur un événement personnalisé, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property/{propertyName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsqu'une propriété est supprimée, elle ne pourra plus être utilisée dans le cadre d'événements futurs. Les achèvements antérieurs conservent leurs valeurs de propriété."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Lors de la suppression d'un événement :"]
      }), (0, _jsxRuntime.jsxs)(_components.ol, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Tous les événements correspondant à cette définition d'événement seront supprimés et irrécupérables."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les éléments ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "eventName"
          }), "' précédemment supprimés ne peuvent pas être réutilisés."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mise à jour d'un événement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les seuls champs qui peuvent être mis à jour dans une définition d'événement sont les champs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour mettre à jour un schéma d'événement personnalisé existant, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer un événement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous supprimez un événement personnalisé, les références à cet événement dans d'autres outils HubSpot, tels que les workflows et les rapports, n'utiliseront plus l'événement."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer un événement personnalisé, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtenir des définitions d'événements existants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer une seule définition d'événement, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour rechercher des définitions d'événements selon des critères spécifiques, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions"
      }), ". Vous pouvez fournir les paramètres de requête suivants pour affiner votre recherche :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "searchString"
        }), " : recherche les événements qui contiennent les caractères spécifiés dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ". La recherche n'est ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " floue, mais il s'agit plutôt d'une recherche naïve de type ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "contains"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        }), " : une chaîne de caractères hachée fournie dans les réponses paginées pour afficher la page suivante des résultats de la recherche."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), " : le nombre maximum de résultats à renvoyer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "includeProperties"
        }), " : une valeur booléenne qui spécifie si les propriétés de l'événement doivent être incluses dans les résultats renvoyés."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemple d'événement personnalisé"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cet exemple explique comment créer un événement personnalisé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cette section aborde :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Création d'une définition d'événement personnalisé."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Création d'une nouvelle propriété sur la définition de l'événement."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Objectif :"
      }), " un concessionnaire automobile appelé CarSpot dispose d'un inventaire en ligne de toutes les voitures disponibles sur son parc. CarSpot souhaite savoir quand les visiteurs de son site consultent une voiture spécifique disponible sur son site. Pour ce faire, il créera un événement personnalisé, qu'il associera à des fiches d'informations de contact afin de suivre les personnes qui consultent l'annonce. Cet événement aura également besoin d'un ensemble de propriétés personnalisées pour stocker les détails de la liste des voitures consultées lors de chaque événement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer une définition d'événement personnalisé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir décidé du nom de l'événement, des propriétés à inclure dans chaque événement déclenché et de l'objet CRM auquel associer les événements, le concessionnaire va créer le schéma de l'événement en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/event-definitions"
      }), " avec le corps de requête suivant :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for event definitions\n\n{\n  \"label\": \"Viewed Car\",\n  \"name\": \"viewed_car\",\n  \"description\": \"An event that fires when visitor views a car listing in the online inventory\",\n  \"primaryObject\": \"CONTACT\",\n  \"propertyDefinitions\": [\n    {\n      \"name\": \"condition\",\n      \"label\": \"Condition\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"label\": \"New\",\n          \"value\": \"new\"\n        },\n        {\n          \"label\": \"Used\",\n          \"value\": \"used\"\n        }\n      ]\n    },\n    {\n      \"name\": \"year\",\n      \"label\": \"Year\",\n      \"type\": \"number\"\n    },\n    {\n      \"name\": \"make\",\n      \"label\": \"Make\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"model\",\n      \"label\": \"Model\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"mileage\",\n      \"label\": \"Mileage\",\n      \"type\": \"number\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer une propriété sur la définition d'un événement personnalisé"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Juste après avoir mis en place l'événement sur son site web, CarSpot a décidé de vérifier si le prix de l'annonce influençait le taux de clics. Pour ce faire, il créera une nouvelle propriété contenant le prix de l'annonce."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour définir une nouvelle propriété, il effectuera une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/event-definitions/viewed_car/property"
      }), " avec le corps de requête suivant :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for creating a property\n{\n  \"name\": \"price\",\n  \"label\": \"Price\",\n  \"type\": \"number\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois l'événement personnalisé défini, il pourra ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "envoyer des données d'événement à HubSpot en utilisant cette définition d'événement personnalisé"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}