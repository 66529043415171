"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65056559120;
const slug = exports.slug = 'guides/cms/content/performance/speed';
const title = exports.title = 'Optimizing your HubSpot CMS site for speed';
const name = exports.name = 'Optimizing your HubSpot CMS site for Speed';
const metaDescription = exports.metaDescription = "Tips and strategies to improve your website's speed. Make your CMS Hub theme or site fast, and learn how to test your site's speed.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "common-website-performance-bottlenecks",
  "label": "Common website performance bottlenecks",
  "parent": null
}, {
  "depth": 0,
  "id": "what-hubspot-handles-for-you",
  "label": "What HubSpot handles for you",
  "parent": null
}, {
  "depth": 0,
  "id": "improve-your-website-speed-further",
  "label": "Improve your Website speed further",
  "parent": null
}, {
  "depth": 1,
  "id": "start-with-a-good-foundation",
  "label": "Start with a good foundation",
  "parent": "improve-your-website-speed-further"
}, {
  "depth": 1,
  "id": "images",
  "label": "Images",
  "parent": "improve-your-website-speed-further"
}, {
  "depth": 2,
  "id": "what-you-can-do",
  "label": "What you can do",
  "parent": "improve-your-website-speed-further"
}, {
  "depth": 1,
  "id": "autoplaying-video",
  "label": "Autoplaying video",
  "parent": "improve-your-website-speed-further"
}, {
  "depth": 2,
  "id": "what-you-can-do",
  "label": "What you can do",
  "parent": "improve-your-website-speed-further"
}, {
  "depth": 1,
  "id": "javascript",
  "label": "JavaScript",
  "parent": "improve-your-website-speed-further"
}, {
  "depth": 2,
  "id": "what-you-can-do",
  "label": "What you can do",
  "parent": "improve-your-website-speed-further"
}, {
  "depth": 0,
  "id": "seo-recommendations-tool",
  "label": "SEO recommendations tool",
  "parent": null
}, {
  "depth": 0,
  "id": "code-alerts",
  "label": "Code Alerts",
  "parent": null
}, {
  "depth": 0,
  "id": "additional-resources-for-improving-your-site-s-speed",
  "label": "Additional resources for improving your site's speed",
  "parent": null
}, {
  "depth": 1,
  "id": "image-optimization",
  "label": "Image Optimization",
  "parent": "additional-resources-for-improving-your-site-s-speed"
}, {
  "depth": 1,
  "id": "performance-testing",
  "label": "Performance testing",
  "parent": "additional-resources-for-improving-your-site-s-speed"
}, {
  "depth": 2,
  "id": "measurement-tools",
  "label": "Measurement tools",
  "parent": "additional-resources-for-improving-your-site-s-speed"
}, {
  "depth": 2,
  "id": "grading-tools",
  "label": "Grading tools",
  "parent": "additional-resources-for-improving-your-site-s-speed"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      h3: "h3",
      h4: "h4",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Optimizing your HubSpot CMS site for performance"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Great user experience is a factor of content quality, speed, security and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "accessibility."
      }), " Optimizing for these generally also improves Search Engine Optimization (SEO)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Better performance is all about providing a better experience for end users. Achieving better performance is all about solving for your individual site's bottlenecks."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Common website performance bottlenecks"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Most web performance optimization techniques and best practices are not HubSpot-specific. Instead, they fall into a few categories:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Loading performance:"
        }), " the efficiency of transferring all of the files needed for your web page to the user's browser. The quantity of files, size of files, and the delivery speed of those files determines loading performance."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rendering performance:"
        }), " the efficiency for the browser to take everything it downloaded, process it, and display the computed end result to the user."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rendering performance in particular is complex and is impacted by several factors, including:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The loading of Cascading Style Sheets (", (0, _jsxRuntime.jsx)("abbr", {
          children: "CSS"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The loading of JavaScript (", (0, _jsxRuntime.jsx)("abbr", {
          children: "JS"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The loading of media, such as images and videos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The device or web browser the visitor is using"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The speed of response to user interactions"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CSS is render-blocking, which means that poorly written CSS can cause Cumulative Layout Shift (", (0, _jsxRuntime.jsx)("abbr", {
        children: "CLS"
      }), ") during page rendering. Images can cause CLS, and take up RAM. Video players can cause CLS, some file formats require more processing work. JS can manipulate the ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Glossary/DOM",
        children: ["Document Object Model (", (0, _jsxRuntime.jsx)("abbr", {
          children: "DOM"
        }), ")"]
      }), " and ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Glossary/CSSOM",
        children: ["Cascading Style Sheet Object Model (", (0, _jsxRuntime.jsx)("abbr", {
          children: "CSSOM"
        }), ")"]
      }), "of a page, causing any of those issues. JS can also be resource intensive. All of these factors need to be balanced and best practices followed to ensure a fast experience for all visitors."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "What HubSpot handles for you"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot's CMS automatically handles many common performance issues, including:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CDN with Image optimization and automatic WebP conversion"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTTP2"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Javascript and CSS minification"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Browser and server caching"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "Prerendering"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Domain Rewriting"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#text-compression",
          children: "Brotli compression (with fallback to GZIP Compression)"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#accelerated-mobile-pages-amp-",
          children: "HubSpot Blog posts support AMP"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When including CSS in a custom module, HubSpot intelligently loads ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " only when a module is used on a page, and only loads it once regardless of how many instances of the module are on the page. By default, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " does not load asynchronously, but you can change this by including ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options"
      }), " in the module’s ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Improve your Website speed further"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Along with everything that HubSpot handles, there are some things you can do as a developer that have a big impact on your site's performance."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Start with a good foundation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It's easier to build from a great foundation that was built with performance in mind, than trying to fix performance issues later. Building a fast car from the ground up is easier than buying a slow car and trying to make it fast."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "HubSpot CMS Boilerplate"
      }), " was built to be fast, and encourage best practices. See the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "GitHub README"
      }), " to review the current scores in Lighthouse and Website Grader."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By building from the boilerplate, you're already starting from a set of high scores. This means that you can focus your attention on the code you want to add on top of the boilerplate."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "bdf71810-13e6-494f-8dbd-f733d5451ad5",
      label: "Build a site based on boilerplate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Images"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Images are prevalent on almost every page on the web. Images are usually the largest files on a page. The more images, and the larger the images, the longer your page will take to load. Animated images such as gifs and animated webp files also take up more space than non-animated images of the same size. Some image formats also are more performant than others, and better for certain scenarios."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "What you can do"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The most important thing you can do is ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/compress-image",
          children: "optimize your images"
        }), " for the web. Image optimization is very much a shared responsibility among both content creators and developers. While HubSpot converts your images to webp and you can resize images using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#resize-image-url",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "resize_image_url()"
          })
        }), ", uploading a non webp file that is already sized appropriately can help."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use fewer images per page."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/insiders/different-types-of-image-files",
          children: "Use the right image format for the use-case"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use Scalable Vector Graphics (SVGs) where possible. SVGs can scale in size infinitely without losing quality. Inlining your SVGs makes sense when you are making animations. In static graphics creating an SVG sprite sheet or simply treating it as a normal img element, or background image is typically better for performance."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Intelligently ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/lazy-loading",
          children: "lazy load images"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Make sure ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "img"
        }), " elements contain height and width HTML attributes. This makes it so web browsers can intelligently optimize for ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/cls/",
          children: "cumulative layout shift"
        }), " during render time and also makes it so HubSpot can generate a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "srcset"
        }), " for you."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio",
          children: "CSS aspect-ratio"
        }), " property to reserve space when img dimensions may change."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#resize-image-url",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "resize_image_url"
          })
        }), " to force images to be resized to a certain resolution."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For background images, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/optimize-css-background-images-with-media-queries/",
          children: "use media queries"
        }), " in combination with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url"
        }), " to deliver images at sizes that make sense for the device."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For large hero images - you can preload them by using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"preload\" as=\"image\" href=\"http://example.com/img_url.jpg\">"
        }), " within a ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "require_head"
          }), " tag"]
        }), ". Use this technique sparingly, overusing it can actually hurt performance."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you fully control the HTML for an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "img"
        }), " and can predict it's sizes at different viewport sizes, providing a custom ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "srcset"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sizes"
        }), " attribute can help. You can use the", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#resize-image-url",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "resize_image_url"
          })
        }), " function to generate the alternate sizes. A custom tailored ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "srcset"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sizes"
        }), " based on the actual usage of the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "img"
        }), " element, will likely be more effective than the HubSpot generated one, but the automatically generated one is better than nothing."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add to your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "img"
        }), " element ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/decoding",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "decoding=\"async\""
          })
        }), ". This tells the browser that it can start loading other content on the page at the same time as it's loading and processing the image."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Autoplaying video"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Video backgrounds and auto-playing videos can certainly set a website apart. Unfortunately they come at a cost. Video backgrounds are often used for website headers. When a video auto-plays, it means the browser needs to start loading the video right away. This can be especially problematic for users on slower connections or using cellphone data."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "What you can do"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avoid using autoplaying video. If what you're showing is a background animation, consider using CSS animations or javascript animations. If you must display an autoplaying video:"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Choose a reasonable resolution for the video based on your use-case, and apply an effect over the video to make a lower resolution less noticeable."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Make sure the video scales in quality based on the device and connection, the best way to do this is using a video sharing/hosting service like YouTube, Vidyard, or Vimeo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Disable autoplaying on mobile, show a fallback image instead."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If the video is loaded by iframe, add ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "loading=\"lazy\""
        }), ". This tells the browser it can wait to render and process the iframe until the user is close to displaying it on screen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScript (", (0, _jsxRuntime.jsx)("abbr", {
        children: "JS"
      }), ") is useful for adding interactivity to your website. Loading a lot of JS code in general increases the file size of the JS files and the amount of time it takes for the browser to render interactive elements. Loading JS in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " can also be a problem as javaScript is ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/render-blocking-resources/",
        children: "render blocking resource"
      }), " by default. Additionally JS is running on the visitors device, meaning it is limited to the resources that device has."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "What you can do"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When HubSpot's CMS first came out jQuery was loaded in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), " by default. You can remove it entirely in ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings > Website > Pages,"
        }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/upgrade-jquery",
          children: "upgrade to the latest version of jQuery"
        }), ". Take care when changing these settings on older websites if you did not build them, they may have been built reliant on jQuery or based on jQuery loading in the header."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure javascript is loaded just before the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "</body>"
        }), " to prevent render blocking. You can use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " to load js for modules or templates only when needed and without accidentally loading the javascript multiple times for multiple instances of a module."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consider refactoring your JS to be more efficient. Use fewer JS plugins, use semantic HTML where it can help. For example for dropdowns, use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<details>"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<summary>"
        }), ". For modals use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<dialog>"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you're using a giant JS library just for a few small features, consider using vanilla JS or loading a subset of the library if possible."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js"
        }), " to load JS only when necessary and only once per page. When using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), ", use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "async"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "defer"
        }), " attributes to improve page performance."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To control where and when a module's JavaScript loads, use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "js_render_options"
        }), " in the module's meta.json file."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If loading external resources use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/preconnect-and-dns-prefetch/",
          children: "preconnect and DNS prefetch"
        }), " appropriately to deliver a faster experience."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Limit the number of tracking scripts you use. Tracking scripts often try to understand all of the actions a user is taking on a page to provide you insights. That is a lot of code analyzing what the user is doing. Each tracking script amplifies this."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/articles/optimize-inp#optimize_interactions",
          children: "handling interactions from a user, prioritize how you respond"
        }), " to focus on what's most important to be done right away, and defer through ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setTimeOut"
        }), " and/or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "RequestAnimationFrame"
        }), " any code that needs to happen in response to the user interaction, but can happen later or is not going to be visible to the user right away."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SEO recommendations tool"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The HubSpot Recommendations tool is a great way to get performance and SEO feedback specific to your website."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/seo/view-seo-recommendations-in-hubspot",
        children: "Learn more about the recommendations tool"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Code Alerts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Code Alerts is a CMS Hub Enterprise feature which acts as a centralized overview of issues that are identified inside of your HubSpot CMS website. Fixing issues that are identified in Code Alerts can help to optimize your website performance. Issues identified comprise several different areas from HubL limits to CSS issues."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "Learn more about Code Alerts."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Additional resources for improving your site's speed"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There is a lot that can be done to optimize a site for speed and many of the topics warrant a further breakdown. We've compiled some great resources we encourage you to check out when working on optimizing your site."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/site-speed-and-performance-what-we-do-and-what-you-can-do",
          children: "Site Speed and Performance: what you can do, and how HubSpot Helps"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-we-improved-page-speed-on-hubspot.com",
          children: "How we improved page speed on HubSpot.com"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/15-tips-to-speed-up-your-website",
          children: "15 tips to speed up your website"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/how-to-reduce-your-websites-page-speed",
          children: "5 easy ways to help reduce your website page's loading time"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/google-page-speed",
          children: "8 step guide to achieving 100% Google Page Speed"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/website-optimization",
          children: "Website Optimization - HubSpot Academy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/learn/",
          children: "Web.dev"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "How we optimize the HubSpot CMS - Jeff Boulter"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.smashingmagazine.com/2021/04/humble-img-element-core-web-vitals/",
          children: "The humble img element and Core Web Vitals - Smashing Magazine"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Image Optimization"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Optimizing your images for the web prior to uploading and serving them helps ensure you won't serve an oversized image for the screen and use-case."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Popular image optimization tools:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imageoptim.com/mac",
          children: "ImageOptim"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/photoshop.html",
          children: "Adobe Photoshop"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/illustrator.html",
          children: "Adobe Illustrator"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imagecompressor.com/",
          children: "Optimizilla"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Performance testing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Testing performance and optimizing for it should be apart of any website build out. There are many tools available for testing a website's speed, some of which grade and some of which only measure. It's important to understand these tools and how they work, so you can make educated decisions around performance improvements."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Popular performance tools include:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://website.grader.com/",
          children: "Website Grader"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://gtmetrix.com/",
          children: "GTMetrix"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/speed/pagespeed/insights/",
          children: "Google Page Speed Insights"
        }), "and other ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/web/fundamentals/performance/speed-tools",
          children: "Google performance tools"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.pingdom.com/",
          children: "Pingdom"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.webpagetest.org/",
          children: "WebPageTest"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Measurement tools"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tools that measure will usually test the follow aspects of a page:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Loading time"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Script execution time"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Time until first contentful paint"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Network times for assets downloading"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "These tools will generally provide results that state specific times for each of these metrics. If you retest, generally the measurements will shift slightly because not every page load is exactly the same."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Grading tools"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition to measuring, grading tools will assign a grade to your page based on its testing, often in a letter or percent form. While these tools are intended to motivate making improvements, there are many different metrics and aspects to performance that need to be taken into account when reviewing results."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "It's recommended to use multiple tools and strive for the best score you can in each. Understand, though, they will weight things differently. Efforts that may improve a score in one tool may not improve it in others."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "It's not recommended to base your overall performance off of one metric's grade. Some metrics have different levels of affect on perceived performance, which results in some tools weighing these metrics differently to calculate their final grade."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["There is no industry-wide standard for how to weigh metrics for grading. Over time, weights can and will change, as has occurred with ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://googlechrome.github.io/lighthouse/scorecalc/",
          children: "Google Page Speed"
        }), ". There is also no industry-wide accepted for what is considered the minimum or maximum \"good\" value for individual metrics. Some tools base this off of a percentile of websites that have been tested., meaning that your scores are being compared to other sites."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Over time, a high grade for speed range has become more difficult to attain. Some tools instead look at user experience, visitor retention, and ROI-based research to determine what the threshold for a good score should be."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Not all tools take into account subsequent page load performance. For example, the HubSpot module system separates CSS and JS for individual modules, and only loads those assets when the module is actually placed on the page. This can result in several smaller CSS files, which could get flagged by Google Page Speed Insights. But by doing this, the next page load won't need to download any of the CSS or JS for any modules that repeat on the next page, as they're cached. This means that subsequent page loads would be kilobytes instead of a monolithic file."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Related:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/performance-scoring/",
          children: "How Lighthouse performance scoring works"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "Website Optimization Roadmap (Core Web Vitals) | Mark Ryba"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}