"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694148;
const slug = exports.slug = 'guides/cms/content/website-settings';
const title = exports.title = 'Website Settings';
const name = exports.name = '[new] Website Settings';
const metaDescription = exports.metaDescription = "Website settings is a single place where various global and system-level content settings can be configured for your website's blog, navigation, pages, and themes.";
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg';
const featuredImageAltText = exports.featuredImageAltText = 'Website settings area';
const toc = exports.toc = [{
  "depth": 0,
  "id": "blog-settings",
  "label": "Blog settings",
  "parent": null
}, {
  "depth": 1,
  "id": "general-tab",
  "label": "General Tab",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "blog-name",
  "label": "Blog name",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "blog-header",
  "label": "Blog header",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "page-title",
  "label": "Page Title",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "meta-description",
  "label": "Meta description",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "blog-root-url",
  "label": "Blog root URL",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "control-audience-access",
  "label": "Control audience access",
  "parent": "blog-settings"
}, {
  "depth": 1,
  "id": "templates-tab",
  "label": "Templates Tab",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "current-template",
  "label": "Current template",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "template-for-listing-pages-optional",
  "label": "Template for listing pages (optional)",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "number-of-posts-per-listing-page",
  "label": "Number of posts per listing page",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "header-html",
  "label": "Header HTML",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "footer-html",
  "label": "Footer HTML",
  "parent": "blog-settings"
}, {
  "depth": 1,
  "id": "subscriptions-tab",
  "label": "Subscriptions Tab",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "blog-subscriber-notification-emails",
  "label": "Blog subscriber notification emails",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "rss-feed",
  "label": "RSS feed",
  "parent": "blog-settings"
}, {
  "depth": 1,
  "id": "date-formats-tab",
  "label": "Date Formats Tab",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "language-for-dates",
  "label": "Language for dates",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "publish-date-format",
  "label": "Publish date format",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "posts-by-month-format",
  "label": "Posts by month format",
  "parent": "blog-settings"
}, {
  "depth": 1,
  "id": "comments-tab",
  "label": "Comments Tab",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "enable-or-disable-blog-comments",
  "label": "Enable or disable blog comments",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "blog-comment-notifications",
  "label": "Blog comment notifications",
  "parent": "blog-settings"
}, {
  "depth": 1,
  "id": "social-sharing-tab",
  "label": "Social Sharing Tab",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "default-twitter-account",
  "label": "Default Twitter account",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "social-sharing-buttons",
  "label": "Social sharing buttons",
  "parent": "blog-settings"
}, {
  "depth": 1,
  "id": "google-amp-tab",
  "label": "Google AMP Tab",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "enable-or-disable-google-amp",
  "label": "Enable or disable Google AMP",
  "parent": "blog-settings"
}, {
  "depth": 2,
  "id": "amp-specific-settings-for-a-logo%2C-header-formatting",
  "label": "AMP-specific settings for a logo, header formatting",
  "parent": "blog-settings"
}, {
  "depth": 0,
  "id": "navigation-settings",
  "label": "Navigation settings",
  "parent": null
}, {
  "depth": 0,
  "id": "page-settings",
  "label": "Page settings",
  "parent": null
}, {
  "depth": 1,
  "id": "templates-tab",
  "label": "Templates Tab",
  "parent": "page-settings"
}, {
  "depth": 2,
  "id": "site-header-html",
  "label": "Site header HTML",
  "parent": "page-settings"
}, {
  "depth": 2,
  "id": "site-footer-html",
  "label": "Site footer HTML",
  "parent": "page-settings"
}, {
  "depth": 2,
  "id": "jquery",
  "label": "jQuery",
  "parent": "page-settings"
}, {
  "depth": 1,
  "id": "branding-tab",
  "label": "Branding Tab",
  "parent": "page-settings"
}, {
  "depth": 2,
  "id": "favicon",
  "label": "Favicon",
  "parent": "page-settings"
}, {
  "depth": 2,
  "id": "logo-alt-text%2C-dimensions%2C-link",
  "label": "Logo (alt text, dimensions, link)",
  "parent": "page-settings"
}, {
  "depth": 1,
  "id": "personalization-tab",
  "label": "Personalization Tab",
  "parent": "page-settings"
}, {
  "depth": 2,
  "id": "contact-%26-company-defaults",
  "label": "Contact & Company defaults",
  "parent": "page-settings"
}, {
  "depth": 1,
  "id": "integrations-tab",
  "label": "Integrations Tab",
  "parent": "page-settings"
}, {
  "depth": 2,
  "id": "js-integrations",
  "label": "JS Integrations",
  "parent": "page-settings"
}, {
  "depth": 1,
  "id": "seo-%26-crawlers-tab",
  "label": "SEO & Crawlers Tab",
  "parent": "page-settings"
}, {
  "depth": 2,
  "id": "canonical-urls",
  "label": "Canonical URLs",
  "parent": "page-settings"
}, {
  "depth": 2,
  "id": "default-file-hosting-domain",
  "label": "Default File Hosting Domain",
  "parent": "page-settings"
}, {
  "depth": 2,
  "id": "robots.txt",
  "label": "Robots.txt",
  "parent": "page-settings"
}, {
  "depth": 1,
  "id": "system-pages-tab",
  "label": "System Pages Tab",
  "parent": "page-settings"
}, {
  "depth": 0,
  "id": "404-and-500-error-pages",
  "label": "404 and 500 error pages",
  "parent": null
}, {
  "depth": 0,
  "id": "password-prompt-page",
  "label": "Password Prompt Page",
  "parent": null
}, {
  "depth": 2,
  "id": "search-results-page-and-url",
  "label": "Search results page and URL",
  "parent": "password-prompt-page"
}, {
  "depth": 0,
  "id": "themes-settings",
  "label": "Themes settings",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      h4: "h4",
      code: "code",
      em: "em",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Website Settings"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Website settings is a single place where various global and system-level content settings can be configured for your website's blog, navigation, pages, and themes. Navigate to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/blogs/",
        children: "Settings > Website"
      }), " and choose which content area you want to access your Content Settings for."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog settings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this area you will control the content settings for your sites blog(s). If you have multiple blogs, you can switch between them using the dropdown under the \"Select a blog to modify\" heading."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg",
        alt: "Blog settings screen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "General Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Blog name"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubL variables ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ content.blog }}"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.public_title }}"
      }), " will render the name set here."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Blog header"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubL variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.header }}"
      }), " will render the header set here."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Page Title"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubL variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.html_title }}"
      }), " will render the title set here."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Meta description"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubL variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.description }}"
      }), " will render the description set here. This meta description will be used on blog listing pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Blog root URL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The blog root URL will precede individual blog post slugs. The HubL variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.absolute_url }}"
      }), " will render the URL set here."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Control audience access"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can control audience access to an entire blog via this setting. More on that ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Templates Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Current template"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This is the template used for all blog posts in a particular blog. The same template will be used for blog listing pages as well by default. Varying content for listing pages versus posts can be written within the Post Content module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Template for listing pages (optional)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This setting enables a different template for blog listing pages other than the template used for blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Number of posts per listing page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This determines the number of post items that appear on a blog listing page by default."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Header HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any code added to the blog listing header HTML will be added to all listing pages via the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), " variable. Any code added to the blog post header HTML will be added to all blog posts via the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), " variable."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Footer HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any code added to the blog listing footer HTML will be added to all listing pages via the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), " variable. Any code added to the blog post footer HTML will be added to all blog posts via the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), " variable."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Subscriptions Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Blog subscriber notification emails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Instant, Daily, Weekly, and Monthly blog notification emails can be enabled and edited via this setting. These emails go out automatically if new blog posts were published in the given timeframe. Read more ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-blog/set-up-your-blog-subscription-features-in-hubspot",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "RSS feed"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The number of post items in the blog RSS feed can be determined via this setting. There is a maximum of 50 posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Date Formats Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Language for dates"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This setting determines the language of months and days that appear in blog dates."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Publish date format"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The format set here determines the order and pattern of publish dates in blogs. Using Local Data Markup Language, it is possible to specify a custom date format."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Posts by month format"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The format set here determines the order and pattern of posts by month. Using Local Data Markup Language, it is possible to specify a custom date format."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comments Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Enable or disable blog comments"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It is possible to enable or disable blog comments via this setting. Comments can require moderation, or have an established timeframe after which comments are automatically closed."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Blog comment notifications"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Blog comments can trigger email notifications to specified users via this setting."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Social Sharing Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Default Twitter account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The Twitter account specified here will be used for Twitter Cards when content is shared on Twitter."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Social sharing buttons"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Social sharing buttons for Twitter, LinkedIn, and Facebook can be automatically added to blog posts via this setting."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Google AMP Tab"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Accelerated Mobile Pages (AMP) load your content instantly. Read more ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "here"
      }), ". In order to load content so quickly, a simplified page experience is required. AMP content has limited styling control for this reason."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Enable or disable Google AMP"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Google AMP formatted pages can be enabled via this setting. AMP logo, font, and color settings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "AMP-specific settings for a logo, header formatting"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In order to deliver AMP content, simplified styling is required. Determine AMP-specific styles for the logo, header formatting, fonts, and colors via these settings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Navigation settings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can manage your menu links and labels in this area. You can switch between which menus you want to configure by choosing the dropdown and selecting your desired menu. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/set-up-your-site-s-navigation-menus",
        children: "Learn more about setting up your sites navigation menus here."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/navigation-settings-screen-4.jpg",
        alt: "Navigation settings screen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Page settings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Settings are broken down by domains, and default values for all domains can be set. The “", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Default for all domains"
      }), "” settings will be displayed when navigating to Pages Settings. There is a toggle at the top of the screen to view and modify settings for specific subdomains. Settings applied to specific subdomains will override the default for all domains settings."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Only users with the “", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "Edit website settings” Marketing permission"
      }), " can access and edit Content Settings."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/page-settings-screen-4.jpg",
        alt: "Page settings screen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Templates Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Site header HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any code added into the site header HTML field in Pages Settings will be included in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), " variable."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Site footer HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any code added into the site footer HTML field in Pages Settings will be included in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), " variable. Typically this is a good place for adding tracking codes and other scripts that are \"non-essential\" to your site functioning or looking good. That way it will not negatively impact any of your templates or pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can modify the jQuery version loading on your page via Pages Settings."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also opt to load jQuery from your footer via this setting. Appending ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter=True"
      }), " to your page URL will allow you to test this change and ensure it does not impact your site’s appearance negatively."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The option to disable jQuery is also located within Pages Settings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Branding Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The logo image set for each domain here will automatically be used in the default “Logo” module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Favicon"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Logo (alt text, dimensions, link)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Your favicon image source URL can be pulled from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "site_settings"
      }), " dictionary and used in your coded files:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if site_settings.favicon_src %}<link rel=\"icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Personalization Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Contact & Company defaults"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "These are the default values used for personalization tokens used on pages when the visitor is unknown."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Integrations Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "JS Integrations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Enable JS Integrations (like Google Analytics or AdRoll) for all domains or select domains here. If using other tracking scripts or Google Tag Manager instead of Google Analytics that code should be added to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#site-footer-html",
        children: "site footer HTML"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "SEO & Crawlers Tab"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Canonical URLs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Select your default canonicalization setting for individual pages and posts, as well as listing pages, here."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Default File Hosting Domain"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This controls the domain that assets from the file manager appear to be hosted at."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Robots.txt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Modify your robots.txt file for each domain here."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "System Pages Tab"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For several system pages, you can select your templates in Pages Settings. No page editor exists for these pages, only templates in the Design Manager that are created with the “System” template type. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Please note"
        })
      }), (0, _jsxRuntime.jsx)(_components.em, {
        children: ": Email preferences and subscription templates are located in Email Settings, not Pages Settings."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "404 and 500 error pages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["These are the pages that are returned for 404 or 500 status codes. When creating a new template, select the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#error-pages",
        children: "“Error page” template type"
      }), " in the Design Manager to make a template available for these system pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Password Prompt Page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This is the page that is returned for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "password-protected pages"
      }), " when prompting a visitor to input a password. When creating a new template, select the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#password-prompt",
        children: "“Password prompt page” template type"
      }), " in the Design Manager to make a template available for this kind of system page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Search results page and URL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This is the page that lists search results for queries input into HubSpot’s ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-do-i-set-up-a-results-page-for-my-search-field-in-hubspot",
        children: "Site Search"
      }), " module. Read more on how to customize your search ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "here"
      }), ". When creating a new template, select the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#search-results-page",
        children: "“Search results page” template type"
      }), " in the Design Manager to make a template for this kind of system page. You can also determine your search results page URL in Pages Settings."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Themes settings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Here you can find all the themes added to your site. You can go into the themes editor by clicking on one of the themes available on your site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/theme-settings-screen-4.jpg",
        alt: "Theme settings screen"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}