"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 7;
const slug = exports.slug = 'reference/api/marketing/emails/transactional-emails/v1';
const title = exports.title = 'Transactional emails API - V1';
const name = exports.name = 'Transactional emails API - V1';
const metaDescription = exports.metaDescription = 'Transactional emails API - V1';
const orderedTitles = exports.orderedTitles = ['List SMTP API Tokens', 'Create SMTP API Tokens', 'SMTP API Tokens - Password Reset', 'Send a single transactional email'];
const orderedHttpMethods = exports.orderedHttpMethods = ['GET', 'POST', 'POST', 'POST'];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h4: "h4",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      EndpointCard,
      EndpointLeftColumn,
      Endpoint,
      EndpointRequirements,
      EndpointParameters,
      EndpointRightColumn,
      EndpointExample,
      EndpointParameter
    } = _components;
  if (!Endpoint) _missingMdxReference("Endpoint", true);
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameter) _missingMdxReference("EndpointParameter", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "List SMTP API Tokens"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/email/public/v1/smtpapi/tokens"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["List SMTP API tokens which can then be used to send email through HubSpot's SMTP API. Tokens listed here were created using the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#create-smtp-api-tokens",
            children: "Create SMTP API Tokens"
          }), " endpoint.\nNote that this API will ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "not"
          }), " return passwords. Passwords are only returned during token creation, and during password reset (at which point a new password is generated)."]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Note that use of API requires the Transactional Email add-on."
          })
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Example URL: ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://api.hubapi.com/email/public/v1/smtpapi/tokens?hapikey=demo",
            children: "https://api.hubapi.com/email/public/v1/smtpapi/tokens"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["The emailCampaignId and appId fields above can be used in the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/analytics-and-events/email-analytics#get-email-events",
            children: "Email Events API"
          }), "."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "transactional-email"
        }), (0, _jsxRuntime.jsx)(EndpointParameters, {})]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "ClsKICAgIHsKICAgICAgInBvcnRhbElkIjogNjI1MTUsCiAgICAgICJ1c2VyTmFtZSI6ICJocmZkdnQ1OGVsQDYyNTE1LnNtdHAuaHVic3BvdC5uZXQiLAogICAgICAiZW1haWxDYW1wYWlnbklkIjogMTQ4NDU4NDIsCiAgICAgICJjcmVhdGVkQXQiOiAxNDE1NjYwNjA2MjMyLAogICAgICAiZGVsZXRlZCI6IGZhbHNlLAogICAgICAiY3JlYXRlZEJ5IjogIm1heGlha0BodWJzcG90LmNvbSIsCiAgICAgICJhcHBJZCI6IDIyNzA5LAogICAgICAiY2FtcGFpZ25OYW1lIjogIlRyYW5zYWN0aW9uYWxUZXN0IgogICAgfSwKICAgIHsKICAgICAgInBvcnRhbElkIjogNjI1MTUsCiAgICAgICJ1c2VyTmFtZSI6ICJqanR3dzh4ZHVlQDYyNTE1LnNtdHAuaHVic3BvdC5uZXQiLAogICAgICAiZW1haWxDYW1wYWlnbklkIjogMTQ4NDU4NDgsCiAgICAgICJjcmVhdGVkQXQiOiAxNDE1NjYwNjE5MjMwLAogICAgICAiZGVsZXRlZCI6IGZhbHNlLAogICAgICAiY3JlYXRlZEJ5IjogIm1heGlha0BodWJzcG90LmNvbSIsCiAgICAgICJhcHBJZCI6IDIyNzA5LAogICAgICAiY2FtcGFpZ25OYW1lIjogIlRyYW5zYWN0aW9uYWxUZXN0MTIiCiAgICB9CiAgXQoK"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Create SMTP API Tokens"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/email/public/v1/smtpapi/tokens"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "This endpoint is used to create an SMTP API Token. An API token provides both a username and password which can then be used to send email through the HubSpot SMTP API:"
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["SMTP Hostname: ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "smtp.hubapi.com"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["SMTP Port: ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "587"
            }), " (for STARTTLS) or ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "465"
            }), " (for TLS)"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["SMTP User Name: ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "provided in the userName field"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["SMTP Password: ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "provided in the password field"
            })]
          }), "\n"]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Once an SMTP API Token is created, it can be used to send emails and track events throughout the HubSpot product. Here are a few ways to use a token once created:"
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/email-analytics#get-campaign-data-for-a-given-campaign",
              children: "Email Campaign Data API"
            }), " you can use the emailCampaignId and appId fields"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/email-analytics#get-email-events",
              children: "Email Event API"
            }), " you can get specific details about events that occurred for the email."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "In Contacts dynamic list definitions, under \"Email Event\" you can search for a campaign name to dynamically update a contacts list based on events sent with the token."
          }), "\n"]
        }), (0, _jsxRuntime.jsx)(_components.h4, {
          children: "Example CURL Request"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "curl -XPOST \\ -H \"Content-Type: application/json\" \\ -d '{\"createdBy\":\"demo@hubspot.com\",\"campaignName\":\"test Transactional Email\"}' \\ https://api.hubapi.com/email/public/v1/smtpapi/tokens"
          })
        }), (0, _jsxRuntime.jsx)(_components.h4, {
          children: "SMTP API"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "After creating the token above, you can use SMTP to send an email:"
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["SMTP Hostname: ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "smtp.hubapi.com"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["SMTP Port: ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "587"
            }), " (for STARTTLS) or ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "465"
            }), " for TLS"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["SMTP User Name: ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "mailto:hrfdvt58el@62515.smtp.hubspot.net",
              children: "hrfdvt58el@62515.smtp.hubspot.net"
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "SMTP Password: 3432fff8eljl1249fjjasdfnv3"
          }), "\n"]
        }), (0, _jsxRuntime.jsx)(_components.h4, {
          children: "Email Campaign Data"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["After you send email, use the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/analytics-and-events/email-analytics#get-campaign-data-for-a-given-campaign",
            children: "Email Campaign Data API"
          }), " to retrieve data for the specific token:\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://api.hubapi.com/email/public/v1/campaigns/14862038?appId=22709",
            children: "https://api.hubapi.com/email/public/v1/campaigns/14862038?appId=22709"
          })]
        }), (0, _jsxRuntime.jsx)(_components.h4, {
          children: "Email Events Data"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["After sending email, use the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/analytics-and-events/email-analytics#get-email-events",
            children: "Email Events API"
          }), " to get event data for the specific campaign:\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://api.hubapi.com/email/public/v1/events?campaignId=14862038&appId=22709",
            children: "https://api.hubapi.com/email/public/v1/events?campaignId=14862038&appId=22709"
          })]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Note that use of API requires the Transactional Email add-on."
          })
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "transactional-email"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "createdBy",
            schemaGroup: "Body",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The email address of the user creating the token"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "campaignName",
            schemaGroup: "Body",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "A name for the campaign tied to the SMTP API token. Currently used in the List segmentation UI and the SMTP Token API."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CnsKICAidXNlck5hbWUiOiAiaC4uLmxANjI1MTUuc210cC5odWJzcG90Lm5ldCIsCiAgInBhc3N3b3JkIjogIjM0Li4udjMiLAogICJwb3J0YWxJZCI6IDYyNTE1LAogICJlbWFpbENhbXBhaWduSWQiOiAxNDg0NTg0MiwKICAiY3JlYXRlZEF0IjogMTQxNTY2MDYwNjIzMiwKICAiZGVsZXRlZCI6IGZhbHNlLAogICJjcmVhdGVkQnkiOiAidGVzdEBodWJzcG90LmNvbSIsCiAgImFwcElkIjogMjI3MDksCiAgImNhbXBhaWduTmFtZSI6ICJUcmFuc2FjdGlvbmFsVGVzdCIKfQ=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "SMTP API Tokens - Password Reset"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/email/public/v1/smtpapi/tokens/:userName/password-reset"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Once you have an SMTP API, the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#list-smtp-api-tokens",
            children: "List SMTP API Tokens"
          }), " endpoint can be used to list all SMTP API Tokens in the portal. However, for security reasons the password is only provided during the time of the token creation. This endpoint allows for the creation of a replacement password for a given Token, keyed by the userName field. ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Once the password is successfully reset, the old password for the token will be invalid."
          })]
        }), (0, _jsxRuntime.jsx)(_components.h4, {
          children: "SMTP API"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "After resetting the password for the token above, you can use SMTP to send an email:"
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["SMTP Hostname: ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "smtp.hubapi.com"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["SMTP Port: ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "587"
            }), " (for STARTTLS) or ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "465"
            }), " for TLS"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["SMTP User Name: ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "mailto:hrfdvt58el@62515.smtp.hubspot.net",
              children: "hrfdvt58el@62515.smtp.hubspot.net"
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "SMTP Password: 3432fff8eljl1249fjjasdfnv3"
          }), "\n"]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Note that use of API requires the Transactional Email add-on."
          })
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "transactional-email"
        }), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "userName",
            schemaGroup: "Path",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The userName field of the SMTP API Token needing a password reset."
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CnsKICAicG9ydGFsSWQiOiA2MjUxNSwKICAidXNlck5hbWUiOiAiYWV6Y2t2aDNoeEA2MjUxNS5zbXRwLmh1YnNwb3QubmV0IiwKICAicGFzc3dvcmQiOiAiSXRVdUNjOGg0STVNaWhpeXNZOEhCNlhTYldabVZaIiwKICAicGFzc3dvcmRIYXNoIjogbnVsbCwKICAiZW1haWxDYW1wYWlnbklkIjogMTQ4NjA5NzksCiAgImNyZWF0ZWRBdCI6IDE0MTU3NDA1NjkwMDgsCiAgImRlbGV0ZWQiOiBmYWxzZSwKICAiY3JlYXRlZEJ5IjogImRlbW9AaHVic3BvdC5jb20iLAogICJhcHBJZCI6IDIyNzA5LAogICJjYW1wYWlnbk5hbWUiOiAidGVzdCB0cmFuc2FjdGlvbmFsIGVtYWlsIgp9CgoKCgoKCgo="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Send a single transactional email"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/email/public/v1/singleEmail/send"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsxs)(_components.strong, {
            children: ["Please note: The use of this API requires the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "http://www.hubspot.com/products/email/transactional-email",
              children: "Transactional Email"
            }), " add-on."]
          })
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["This method is used to send an email designed in the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/get-started-with-the-marketing-email-tool",
            children: "marketing email tool"
          }), "."]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "After you design and publish a transactional email in your HubSpot account, you can use this API to include customization options to the email, then send it to the intended recipient. The Email ID provided in the marketing email tool specifies which template to include in your API request."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Any emails sent through this API will automatically be associated with contact records based on the email address. If there is no contact with a matching email address, a new contact with that email will be created. Note: If you want to send emails without creating contacts, use the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legacydocs.hubspot.com/docs/methods/email/transactional_email/smtpapi_overview",
            children: "SMTP API"
          }), "."]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Note: The HTML/JSON that is sent through this API is not saved by HubSpot. You may review the template of the email from the contact's timeline event, but you cannot view the original email as sent to the contact. If you wish to keep a record of the email sent and its contents, we recommend adding a BCC to the email."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Use case for this API"
          }), ": This capability would be useful in a situation where an event on an external system should trigger an email send, for example."]
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "Publish a transactional email in your HubSpot account"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "First, design your transactional email in your HubSpot account using the email editor tool:"
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing > Email"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Create email"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "In the dialog box, select Regular for the email type."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Select a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "template"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/create-marketing-emails-in-the-drag-and-drop-email-editor#edit-email-layout-and-content",
              children: "Edit"
            }), " your email. You can use ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/articles/kcs_article/cos-general/how-to-use-personalization-with-your-content",
              children: "personalization tokens"
            }), " in transactional emails, just as you would with your marketing emails."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["When you're done editing your email, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Actions"
            }), " dropdown menu in the top right, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Convert to single-send API"
            }), "."]
          }), "\n"]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/transactional-email-convert-to-single-send-api.png",
            alt: ""
          })
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Finish configuring your email, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Review and save"
            }), " in the top right."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the right panel, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Save for single-send API"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Once the email has saved, you can copy the email ID to use in your API request."
          }), "\n"]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/email-details-page-single-send-api.png",
            alt: "email-details-page-single-send-api"
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Review the other parameters available in the table below."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "transactional-email"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "emailId",
            schemaGroup: "Body",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the email to send.\nThis is content ID for the transactional email, which can be found in email tool UI."
            })
          }), (0, _jsxRuntime.jsxs)(EndpointParameter, {
            name: "message",
            schemaGroup: "Body",
            required: true,
            children: [(0, _jsxRuntime.jsxs)(_components.p, {
              children: ["A JSON object holding the recipient (in its to field) as well as other customizations that can be made on the fly.\nThe message field contains anything that you want to override. At the minimum, the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "to"
              }), " field must be present.\nMessage object fields:"]
            }), (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "to"
                }), " — The recipient of the email"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "from"
                }), " — The From header for the email. You can define a from name with the following format: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "\"from\":\"Sender Name <sender@hubspot.com>\""
                })]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "sendId"
                }), " — The ID of a particular send. No more than one email with a given sendId will be send per portal, so including a sendId is a good way to prevent duplicate email sends."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "replyTo"
                }), " — The Reply-To header for the email"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "replyToList"
                }), " — A JSON list of Reply-To header values for the email"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "cc"
                }), " — A JSON list of email addresses to send as Cc"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "bcc"
                }), " — A JSON list of email addresses to send as Bcc"]
              }), "\n"]
            })]
          }), (0, _jsxRuntime.jsxs)(EndpointParameter, {
            name: "contactProperties",
            schemaGroup: "Body",
            children: [(0, _jsxRuntime.jsxs)(_components.p, {
              children: ["A list of JSON objects representing contact property values to set when sending the email. Useful if you have more information about the contact when sending the email.You can get the full list of available contact properties and their names using the ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/doc/methods/contacts/v2/get_contacts_properties",
                children: "Contact Properties API"
              }), "."]
            }), (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Each contact property value contains a ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "name"
              }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "value"
              }), " property. Each property will get set on the contact record and will be visible in the template under ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{ custom.NAME }}"
              }), ". Use these properties when you want to set a contact property while you’re sending the email. For example, when sending a receipt you may want to set a last_paid_date property, as the sending of the receipt will have information about the last payment."]
            })]
          }), (0, _jsxRuntime.jsxs)(EndpointParameter, {
            name: "customProperties",
            schemaGroup: "Body",
            children: [(0, _jsxRuntime.jsx)(_components.p, {
              children: "A list of JSON objects representing property values to set when sending the email. Useful if you have context in the email you don't want to set on the contact record."
            }), (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Each property value contains a ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "name"
              }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "value"
              }), " property. Each property will be visible in the template under ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{ custom.NAME }}"
              }), "."]
            }), (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Note: Custom properties do not currently support arrays."
              }), " To provide a listing in an email, one workaround is to build an HTML list (either with tables or ul) and specify it as a custom property."]
            }), (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Note:"
              }), " Custom properties are not stored in HubSpot, and will only be included in the email sent to the contact. These properties will not appear in the web page version of the email, or in the view of the email from the contact's timeline."]
            })]
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "Other Properties",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Other data about the email you want to send."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "ClRoZSBKU09OIHNhbXBsZSBiZWxvdyByZXByZXNlbnRzIHRoZSBzaW1wbGVzdCBkYXRhIHlvdSBuZWVkIAp0byBwYXNzIGluIHRoZSBib2R5IG9mIHlvdXIgUE9TVCByZXF1ZXN0IHRvIHNlbmQgYW4gZW1haWw6CnsKICAgICJlbWFpbElkIjogMjg1MzA0OTYzNSwKICAgICJtZXNzYWdlIjogewogICAgICAgICJ0byI6ICJleGFtcGxlQGh1YnNwb3QuY29tIgogICAgfQp9CgpIZXJlIGlzIGFuIGV4YW1wbGUgc3VwcGx5aW5nIHRoZSBmaXJzdCBuYW1lIHByb3BlcnR5IGluIHRoZSBlbWFpbCwgYW5kIGluY2x1ZGluZyBhIHNlbmRJZDoKCnsKICAgICJlbWFpbElkIjogMjg1MzA0OTYzNSwKICAgICJtZXNzYWdlIjogewogICAgICAgICJ0byI6ICJleGFtcGxlQGh1YnNwb3QuY29tIiwKICAgICAgICAic2VuZElkIjogImZvb2JhciIKICAgIH0sCiAgICAiY29udGFjdFByb3BlcnRpZXMiOiBbCiAgICAgICAgewogICAgICAgICAgICAibmFtZSI6ICJmaXJzdG5hbWUiLAogICAgICAgICAgICAidmFsdWUiOiAiSm9obiIKICAgICAgICB9CiAgICBdLAogICAgImN1c3RvbVByb3BlcnRpZXMiOiBbCiAgICAgICAgewogICAgICAgICAgICAibmFtZSI6ICJpdGVtXzEiLAogICAgICAgICAgICAidmFsdWUiOiAic29tZXRoaW5nIHRoZXkgYm91Z2h0IgogICAgICAgIH0KICAgIF0KfQoKSGVyZSBpcyBhbiBleGFtcGxlIHVzaW5nIEZyb20sIENjLCBhbmQgQmNjOgoKCnsKICAgICJlbWFpbElkIjogMjg1MzA0OTYzNSwKICAgICJtZXNzYWdlIjogewogICAgICAgICJ0byI6ICJleGFtcGxlQGh1YnNwb3QuY29tIiwKICAgICAgICAiZnJvbSI6ICJTZW5kZXIgTmFtZSAmbHQ7c2VuZGVyQGh1YnNwb3QuY29tJmd0OyIsCiAgICAgICAgImNjIjogWyJleGFtcGxlMUBodWJzcG90LmNvbSJdLAogICAgICAgICJiY2MiOiBbImV4YW1wbGViY2NAaHVic3BvdC5jb20iXQogICAgfSwKICAgICJjb250YWN0UHJvcGVydGllcyI6IFsKICAgICAgICB7CiAgICAgICAgICAgICJuYW1lIjogImZpcnN0bmFtZSIsCiAgICAgICAgICAgICJ2YWx1ZSI6ICJKb2huIgogICAgICAgIH0KICAgIF0sCiAgICAiY3VzdG9tUHJvcGVydGllcyI6IFsKICAgICAgICB7CiAgICAgICAgICAgICJuYW1lIjogIml0ZW1fMSIsCiAgICAgICAgICAgICJ2YWx1ZSI6ICJzb21ldGhpbmcgdGhleSBib3VnaHQiCiAgICAgICAgfQogICAgXQp9"
          })
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "Response details"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "The response contains the following fields:"
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "sendResult"
            }), " — A SendResult value (see below)"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "message"
            }), " — A description of the send result details."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "eventId"
            }), " — If sent, the id and created timestamp of the sent event."]
          }), "\n"]
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "SendResult"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["The SendResult is an enumeration of possible results when attempting to send the email. In practice, if the status code of the response is in the 400 range ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "you should not attempt to resend"
          }), ". If the response is in the 500 range then the request failed."]
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "SENT — The email was sent successfully."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "QUEUED — The email was queued, and will send as the queue gets processed."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "PORTAL_SUSPENDED — Due to Acceptable Use Policy violations, the HubSpot customer's email has been suspended."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["INVALID_TO_ADDRESS — The recipient address is invalid. This error will also occur if you attempt to send an email with any of the following role-based prefixes in the email address: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "abuse"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "no-reply"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "noreply"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "root"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "spam"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "security"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "undisclosed-recipients"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inoc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "postmaster"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "privacy"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "BLOCKED_DOMAIN — The domain cannot receive emails from HubSpot at this time."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "PREVIOUSLY_BOUNCED — The recipient has previously bounced, and the sending logic resulted in no send."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "PREVIOUS_SPAM — The recipient has previously marked similar email as spam."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "INVALID_FROM_ADDRESS — The From address is invalid."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "MISSING_CONTENT — The emailId is invalid, or the emailId is an email that is not set up for Single Send."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["MISSING_TEMPLATE_PROPERTIES — There are custom properties set up in the template that have not been included in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "customProperties"
            }), " sent in the request."]
          }), "\n"]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}