import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { usageTrackerMiddleware } from 'usage-tracker-redux';
import { rootReducer } from '../reducers';
import { getUsageTracker } from './usage-tracker';
import { mutateTrackerInstance } from 'developer-docs-shared-components/tracking/usageTracker';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default function configureStore(initialState = {}) {
  const tracker = getUsageTracker();
  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(usageTrackerMiddleware(mutateTrackerInstance(tracker)), thunk))(createStore);
  return createStoreWithMiddleware(rootReducer, initialState);
}