"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35807476584;
const slug = exports.slug = 'reference/api/analytics-and-events/cookie-banner';
const title = exports.title = 'Cookie banner';
const name = exports.name = 'Cookie banner';
const metaDescription = exports.metaDescription = 'The cookie consent banner enables you to enable/disable different analytics scripts based on user preferences.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "remove-cookies",
  "label": "Remove cookies",
  "parent": null
}, {
  "depth": 0,
  "id": "place-do-not-track-cookie",
  "label": "Place do not track cookie",
  "parent": null
}, {
  "depth": 0,
  "id": "get-privacy-consent-status",
  "label": "Get privacy consent status",
  "parent": null
}, {
  "depth": 0,
  "id": "cookies-not-by-category",
  "label": "Cookies not by category",
  "parent": null
}, {
  "depth": 0,
  "id": "%E2%80%8B%E2%80%8Benable-website-visitors-to-manage-their-consent",
  "label": "​​Enable website visitors to manage their consent",
  "parent": null
}, {
  "depth": 0,
  "id": "ui-examples",
  "label": "UI Examples",
  "parent": null
}, {
  "depth": 1,
  "id": "button",
  "label": "Button",
  "parent": "ui-examples"
}, {
  "depth": 1,
  "id": "fixed-position-button",
  "label": "Fixed position button",
  "parent": "ui-examples"
}, {
  "depth": 1,
  "id": "link",
  "label": "Link",
  "parent": "ui-examples"
}, {
  "depth": 0,
  "id": "block-third-party-cookies-manually",
  "label": "Block third party cookies manually",
  "parent": null
}, {
  "depth": 1,
  "id": "general-usage",
  "label": "General usage",
  "parent": "block-third-party-cookies-manually"
}, {
  "depth": 1,
  "id": "example-google-tag",
  "label": "Example: Google Tag",
  "parent": "block-third-party-cookies-manually"
}, {
  "depth": 1,
  "id": "example-hotjar",
  "label": "Example: HotJar",
  "parent": "block-third-party-cookies-manually"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      hr: "hr",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Cookie consent banner API"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Super admins and users with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#marketing",
        children: "permission to edit website settings"
      }), " can customize visitor cookie tracking and consent banners to comply with EU cookie laws and the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/data-privacy/gdpr",
        children: "General Data Protection Regulation (GDPR)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A cookie consent banner allows visitors to opt in or opt out of being tracked in your HubSpot account with cookies. This feature works for all HubSpot pages as well as any external pages with your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/reports/install-the-hubspot-tracking-code",
        children: "HubSpot tracking code"
      }), " installed. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/customize-your-cookie-tracking-settings-and-privacy-policy-alert",
        children: "Customize the cookie tracking settings and cookie consent banner."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this article, learn how to manage the cookies that are added to a visitor's browser through the cookie consent banner."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Remove cookies"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsp.push(['revokeCookieConsent']);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Remove the cookies created by the HubSpot tracking code that are included in the consent banner under GDPR, include the HubSpot cookies related to tracking the visitor. As a result of the cookies being removed, the visitor would see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-enable-a-privacy-policy-alert-if-you-are-doing-business-in-europe",
        children: "cookie consent banner"
      }), " on their next page load, as they would appear as a new visitor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This function ", (0, _jsxRuntime.jsx)("u", {
        children: "does not"
      }), " remove cookies placed by non-HubSpot banners. You can find the specific list of cookies that will be removed on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser#consent-banner-cookies",
        children: "HubSpot's Knowledge Base"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If cookie blocking is turned on, this function will revoke consent so any third-party cookies will not be updated or dropped during future visits to the website."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nExample code to remove the consent banner cookies\nwhen a visitor clicks an element with the 'removeCookies' id.\n*/\n\nvar _hsp = (window._hsp = window._hsp || []);\ndocument.getElementById('removeCookies').onclick = function () {\n  _hsp.push(['revokeCookieConsent']);\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Place do not track cookie"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['doNotTrack']);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Places the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "__hs_do_not_track"
      }), " cookie in the visitors browser, which will prevent the HubSpot tracking code from sending any information for the visitor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can remove the cookie by calling the function again and including the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{track: true}"
      }), " argument: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['doNotTrack', {track: true}]);"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this function prevents all information from being collected by the tracking code, including anonymized traffic and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "custom event"
        }), " data."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nExample code to place the __hs_do_not_track cookie for\nthe visitor when they click an element with the 'doNotTrack' id.\n*/\n\ndocument.getElementById('doNotTrack').onclick = function () {\n  _hsq.push(['doNotTrack']);\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get privacy consent status"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsp.push(['addPrivacyConsentListener', callbackFunction]);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Get the privacy consent status of the current visitor. There are 3 categories of consent that can be used to provide more granular control to the user. These each have their own keys within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "consent.categories"
      }), " object:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "consent.categories.analytics"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "consent.categories.advertisement"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "consent.categories.functionality"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "callbackFunction"
      }), " will be called, depending on the state of the page:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If the banner is ", (0, _jsxRuntime.jsx)("u", {
            children: "not"
          }), " enabled, or if the visitor has previously seen the banner and clicked accept or decline:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "callbackFunction"
            }), " will be called immediately if the banner code is already loaded."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "callbackFunction"
            }), " will be called after the tracking code loads if the function is pushed to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "_hsp"
            }), " before the tracking code loads."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "If the banner is enabled, the callback function will be called when the visitor clicks on the accept or decline button."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Log the analytics category consent status of the current visitor to the console\n\nvar _hsp = (window._hsp = window._hsp || []);\n\n// analytics\n_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    console.log(consent.categories.analytics);\n  },\n]);\n\n// advertisement\n_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    console.log(consent.categories.advertisement);\n  },\n]);\n\n// functionality\n_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    console.log(consent.categories.functionality);\n  },\n]);\n\n// or it can all be done in one call\n_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    console.log(`analytics: ${consent.categories.analytics}`);\n    console.log(`advertisement: ${consent.categories.advertisement}`);\n    console.log(`functionality: ${consent.categories.functionality}`);\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cookies not by category"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " This is provided for backward compatibility with older scripts. For all new websites you should use the cookies by category method, giving more granular control over cookie activation."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsp.push(['addPrivacyConsentListener', callbackFunction]);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Allows you to get the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "true"
      }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "false"
      }), " privacy consent status of the current visitor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "callbackFunction"
      }), " will be called, depending on the state of the page:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If the banner is ", (0, _jsxRuntime.jsx)("u", {
            children: "not"
          }), " enabled, or if the visitor has previously seen the banner and clicked accept or decline:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "callbackFunction"
            }), " will be called immediately if the banner code is already loaded."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "callbackFunction"
            }), " will be called after the tracking code loads if the function is pushed to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "_hsp"
            }), " before the tracking code loads."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "If the banner is enabled, the callback function will be called when the visitor clicks on the accept or decline button."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Log the consent status of the current visitor to the console\n\nvar _hsp = (window._hsp = window._hsp || []);\n_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    if (consent.allowed) {\n      console.log('something');\n    }\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callbackFunction"
      }), " accepts a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "consent"
      }), " object as its only argument."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "consent"
      }), " object has a single ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "allowed"
      }), " property that will be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " if:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-enable-a-privacy-policy-alert-if-you-are-doing-business-in-europe",
          children: "cookie consent banner"
        }), " is not enabled, or is enabled in notify-only mode."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The visitor clicks accept on the banner when opt-in mode is enabled."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The visitor has previously clicked accept on the banner when opt-in mode is enabled."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The property will be false if the consent banner is enabled in opt-in mode and the visitor clicks or has previously clicked the decline button."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "​​Enable website visitors to manage their consent"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Call the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "showBanner"
      }), " function to resurface the banner, enabling website visitors to make changes to their consent preferences. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "​​var _hsp = window._hsp = window._hsp || [];\n​​_hsp.push(['showBanner']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The behavior of", (0, _jsxRuntime.jsx)(_components.code, {
        children: "showBanner"
      }), "varies by policy and is only available for Opt-In and Cookie-By-Category policies."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For Opt-In policies, calling ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "showBanner"
      }), " will cause the banner to reappear, as shown in the video below:"]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/40218050953",
      width: "1788px",
      height: "450px"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For Cookies-By-Category policies, calling ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "showBanner"
      }), " will cause the modal for selecting each category to reappear, as shown in the video below:"]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/40218140317",
      width: "1788px",
      height: "744px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "UI Examples"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This functionality can be made available to visitors in the form of buttons/links on your website that they can use to re-open the banner and edit their preferences. The following are examples with code."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Button"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A button, often placed in the website footer."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<button\n  type=\"button\"\n  id=\"hs_show_banner_button\"\n  onClick=\"(function(){\n        var _hsp = window._hsp = window._hsp || [];\n        _hsp.push(['showBanner']);\n      })()\"\n>\n  Cookie Settings\n</button>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "#hs_show_banner_button {\n  display: inline-block;\n  text-align: center;\n  height: 50px;\n  background-color: #425b76;\n  border: 1px solid #425b76;\n  border-radius: 3px;\n  padding: 10px 16px;\n  text-decoration: none;\n  color: #fff;\n  font-family: inherit;\n  font-size: inherit;\n  font-weight: normal;\n  line-height: inherit;\n  text-shadow: none;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/40220919441",
      width: "1500px",
      height: "824px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fixed position button"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A button with fixed positioning on the bottom of the screen. This kind of button has the advantage of being readily available and easy to find, while being somewhat obtrusive UX."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<button\n  id=\"hs-hud-cookie-settings\"\n  onClick=\"(function(){\n      var _hsp = window._hsp = window._hsp || [];\n      _hsp.push(['showBanner']);\n    })()\"\n>\n  Cookie Settings\n</button>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "button#hs-hud-cookie-settings {\n  position: fixed !important;\n  bottom: 0px;\n  right: 10px;\n  color: white;\n  background-color: #425b76;\n  padding: 5px;\n  border-top-right-radius: 5px;\n  border-top-left-radius: 5px;\n  border-width: 0;\n  appearance: none;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/40220919737",
      width: "1782px",
      height: "920px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Link"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A link or highlighted text."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a\n  id=\"hs-cookie-settings-link\"\n  onClick=\"(function(){\n      var _hsp = window._hsp = window._hsp || [];\n      _hsp.push(['showBanner']);\n    })()\"\n>\n  Cookie Settings\n</a>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "#hs-cookie-settings-link {\n  cursor: pointer;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/40220920222",
      width: "1350px",
      height: "786px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Block third party cookies manually"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The HubSpot Consent Banner supports manual handling of third party tracking technologies and cookies. It's recommended to use manual handling if you have a complicated website and/or a dedicated web developer. If auto-blocking does not work for your site, manual blocking is also a good option."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Manual blocking is implemented through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner",
        children: "Cookie Banner Consent Listener API"
      }), ". This API is used to prevent tracking technologies from running until they have consent. To get started, take a look at the examples below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "General usage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you want to install a tracking script onto your website to display targeted ads to visitors. You could use something like the below:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<script src=”https://my.advertisement.script.com/ads”></script>"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When this script is pasted into the head HTML of a page on a website it would run anytime someone visits that page, regardless of their consent status. Visitors will have cookies placed on their browser without consent."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To prevent the script from running without consent, you can use the HubSpot Cookie Banner Consent Listener API to install the script when the visitor has consented to its cookies. Consent listeners are functions that run whenever the visitor submits their consent. To use this functionality, a consent listener needs to be created that adds the script to the page if the visitor has consented to advertisement cookies."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<script>\n var _hsp = window._hsp = window._hsp || [];\n_hsp.push(['addPrivacyConsentListener', (consent) => {\nif (consent.categories.advertisement) {\nconst script = document.createElement('script');\n        \tscript.src = \"https://my.advertisement.script.com/ads\";\n        \tdocument.head.appendChild(script)\n}\n}])\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This script will register the consent listener with the cookie banner. When consent to cookies is submitted, the consent listener will run, adding HubSpot's third party ads script to the page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Example: Google Tag"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.google.com/tag-platform/gtagjs",
        children: "Google Tag or gtag.js"
      }), " can be used to add Google Analytics. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<!-- Google tag (gtag.js) -->\n<script async src=\"https://www.googletagmanager.com/gtag/js?id=GA_TRACKING_ID\"></script>\n<script>\n  window.dataLayer = window.dataLayer || [];\n  function gtag(){window.dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'GA_TRACKING_ID');\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To load Google Analytics when analytics consent has been given, the gtag script needs to be added when consent is given:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<!-- Google tag (gtag.js) -->\n<script>\n     var _hsp = window._hsp = window._hsp || [];\n    _hsp.push(['addPrivacyConsentListener', (consent) => {\n    if (consent.categories.analytics) {\n        const script = document.createElement('script');\n          script.src = \"https://www.googletagmanager.com/gtag/js?id=GA_TRACKING_ID\";\n          script.async = 'true'\n          document.head.appendChild(script)\n      }\n    }])\n</script>\n<script>\n    window.dataLayer = window.dataLayer || [];\n    function gtag(){dataLayer.push(arguments);}\n    gtag('js', new Date());\n    gtag('config', 'GA_TRACKING_ID');\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Example: HotJar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-Your-Hotjar-Tracking-Code#manually",
        children: "HotJar"
      }), " is another example of analytics tracking. For example:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-Your-Hotjar-Tracking-Code#manually",
        children: "HotJar"
      }), " is another example of analytics tracking. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<!-- Hotjar Tracking Code -->\n<script>\n    (function(h,o,t,j,a,r){\n        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n        h._hjSettings={hjid:HOT_JAR_ID,hjsv:6};\n        a=o.getElementsByTagName('head')[0];\n        r=o.createElement('script');r.async=1;\n        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n        a.appendChild(r);\n    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To ensure Hotjar runs when analytics consent is given, the consent listener can be added."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<!-- Hotjar Tracking Code -->\n<script>\nvar _hsp = window._hsp = window._hsp || [];\n_hsp.push(['addPrivacyConsentListener', (consent) => {\n\tif (consent.categories.analytics){\n(function(h,o,t,j,a,r){\n        \t\th.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n        \t\th._hjSettings={hjid:HOT_JAR_ID,hjsv:6};\n        \t\ta=o.getElementsByTagName('head')[0];\n        \t\tr=o.createElement('script');r.async=1;\n        \t\tr.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n        \t\ta.appendChild(r);\n    \t\t})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');\n}\n\n}])\n</script>\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}