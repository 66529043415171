"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694148;
const slug = exports.slug = 'guides/cms/content/website-settings';
const title = exports.title = 'ウェブサイト設定';
const name = exports.name = '[新規]ウェブサイト設定';
const metaDescription = exports.metaDescription = 'ウェブサイト設定は、ウェブサイトのブログ、ナビゲーション、ページ、テーマについて、全体的なシステムのさまざまなコンテンツの設定を一元的に設定できる場所です。';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg';
const featuredImageAltText = exports.featuredImageAltText = 'ウェブサイト設定エリア';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A",
  "label": "ブログ設定",
  "parent": null
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E5%85%A8%E8%88%AC%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［全般］タブ",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E5%90%8D",
  "label": "ブログ名",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E3%83%98%E3%83%83%E3%83%80%E3%83%BC",
  "label": "ブログヘッダー",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%BF%E3%82%A4%E3%83%88%E3%83%AB",
  "label": "ページタイトル",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%A1%E3%82%BF%E3%83%87%E3%82%A3%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "メタディスクリプション",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E3%81%AE%E3%83%AB%E3%83%BC%E3%83%88url",
  "label": "ブログのルートURL",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%82%AA%E3%83%BC%E3%83%87%E3%82%A3%E3%82%A8%E3%83%B3%E3%82%B9%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%81%AE%E7%AE%A1%E7%90%86",
  "label": "オーディエンスアクセスの管理",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［テンプレート］タブ",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E7%8F%BE%E5%9C%A8%E3%81%AE%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
  "label": "現在のテンプレート",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%AA%E3%82%B9%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%EF%BC%88%E3%82%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3%EF%BC%89",
  "label": "リストページのテンプレート（オプション）",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%AA%E3%82%B9%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%82%E3%81%9F%E3%82%8A%E3%81%AE%E8%A8%98%E4%BA%8B%E6%95%B0",
  "label": "リストページあたりの記事数",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%98%E3%83%83%E3%83%80%E3%83%BChtml",
  "label": "ヘッダーHTML",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%95%E3%83%83%E3%82%BF%E3%83%BChtml",
  "label": "フッターHTML",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E9%85%8D%E4%BF%A1%E7%99%BB%E9%8C%B2%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［配信登録］タブ",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E7%99%BB%E9%8C%B2%E8%AA%AD%E8%80%85%E9%80%9A%E7%9F%A5e%E3%83%A1%E3%83%BC%E3%83%AB",
  "label": "ブログ登録読者通知Eメール",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "rss%E3%83%95%E3%82%A3%E3%83%BC%E3%83%89",
  "label": "RSSフィード",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E6%97%A5%E4%BB%98%E5%BD%A2%E5%BC%8F%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［日付形式］タブ",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E6%97%A5%E4%BB%98%E3%81%AE%E8%A8%80%E8%AA%9E",
  "label": "日付の言語",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E5%85%AC%E9%96%8B%E6%97%A5%E3%81%AE%E5%BD%A2%E5%BC%8F",
  "label": "公開日の形式",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E6%9C%88%E5%88%A5%E3%81%AE%E8%A8%98%E4%BA%8B%E3%81%AE%E5%BD%A2%E5%BC%8F",
  "label": "月別の記事の形式",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［コメント］タブ",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E3%81%AE%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88%E3%82%92%E6%9C%89%E5%8A%B9%E3%81%BE%E3%81%9F%E3%81%AF%E7%84%A1%E5%8A%B9%E3%81%AB%E3%81%99%E3%82%8B",
  "label": "ブログのコメントを有効または無効にする",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E3%81%AE%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88%E9%80%9A%E7%9F%A5",
  "label": "ブログのコメント通知",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E3%82%BD%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%82%B7%E3%82%A7%E3%82%A2%E3%83%AA%E3%83%B3%E3%82%B0%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［ソーシャルシェアリング］タブ",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AEtwitter%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88",
  "label": "既定のTwitterアカウント",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%82%BD%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB-%E3%82%B7%E3%82%A7%E3%82%A2-%E3%83%9C%E3%82%BF%E3%83%B3",
  "label": "ソーシャル シェア ボタン",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BBgoogle-amp%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［Google AMP］タブ",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "google-amp%E3%82%92%E6%9C%89%E5%8A%B9%E3%81%BE%E3%81%9F%E3%81%AF%E7%84%A1%E5%8A%B9%E3%81%AB%E3%81%99%E3%82%8B",
  "label": "Google AMPを有効または無効にする",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%AD%E3%82%B4%E3%80%81%E3%83%98%E3%83%83%E3%83%80%E3%83%BC%E3%81%AE%E5%BD%A2%E5%BC%8F%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8Bamp%E5%9B%BA%E6%9C%89%E3%81%AE%E8%A8%AD%E5%AE%9A",
  "label": "ロゴ、ヘッダーの形式に関するAMP固有の設定",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 0,
  "id": "%E3%83%8A%E3%83%93%E3%82%B2%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E8%A8%AD%E5%AE%9A",
  "label": "ナビゲーション設定",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A",
  "label": "ページ設定",
  "parent": null
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［テンプレート］タブ",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%82%B5%E3%82%A4%E3%83%88%E3%83%98%E3%83%83%E3%83%80%E3%83%BChtml",
  "label": "サイトヘッダーHTML",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%82%B5%E3%82%A4%E3%83%88%E3%83%95%E3%83%83%E3%82%BF%E3%83%BChtml",
  "label": "サイトフッターHTML",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "jquery",
  "label": "jQuery",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E3%83%96%E3%83%A9%E3%83%B3%E3%83%87%E3%82%A3%E3%83%B3%E3%82%B0%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［ブランディング］タブ",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%95%E3%82%A1%E3%83%93%E3%82%B3%E3%83%B3",
  "label": "ファビコン",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%83%AD%E3%82%B4%EF%BC%88%E4%BB%A3%E6%9B%BF%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%80%81%E5%A4%A7%E3%81%8D%E3%81%95%E3%80%81%E3%83%AA%E3%83%B3%E3%82%AF%EF%BC%89",
  "label": "ロゴ（代替テキスト、大きさ、リンク）",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E3%83%91%E3%83%BC%E3%82%BD%E3%83%8A%E3%83%A9%E3%82%A4%E3%82%BA%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［パーソナライズ］タブ",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%81%8A%E3%82%88%E3%81%B3%E4%BC%9A%E7%A4%BE%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E6%97%A2%E5%AE%9A",
  "label": "コンタクトおよび会社に関する既定",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E9%80%A3%E6%90%BA%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［連携］タブ",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "js%E9%80%A3%E6%90%BA",
  "label": "JS連携",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BBseo%E3%81%8A%E3%82%88%E3%81%B3%E3%82%AF%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［SEOおよびクローラー］タブ",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "canonical-url",
  "label": "canonical URL",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB-%E3%83%9B%E3%82%B9%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0-%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3",
  "label": "既定のファイル ホスティング ドメイン",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "robots.txt",
  "label": "Robots.txt",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%EF%BC%BB%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E3%83%9A%E3%83%BC%E3%82%B8%EF%BC%BD%E3%82%BF%E3%83%96",
  "label": "［システムページ］タブ",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 0,
  "id": "404%E3%81%8A%E3%82%88%E3%81%B3500%E3%81%AE%E3%82%A8%E3%83%A9%E3%83%BC%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "404および500のエラーページ",
  "parent": null
}, {
  "depth": 0,
  "id": "%EF%BC%BB%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%83%97%E3%83%AD%E3%83%B3%E3%83%97%E3%83%88%EF%BC%BD%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "［パスワードプロンプト］ページ",
  "parent": null
}, {
  "depth": 2,
  "id": "%E6%A4%9C%E7%B4%A2%E7%B5%90%E6%9E%9C%E3%81%AE%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%A8url",
  "label": "検索結果のページとURL",
  "parent": "%EF%BC%BB%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%83%97%E3%83%AD%E3%83%B3%E3%83%97%E3%83%88%EF%BC%BD%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E8%A8%AD%E5%AE%9A",
  "label": "テーマ設定",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      h4: "h4",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ウェブサイト設定"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ウェブサイト設定は、ウェブサイトのブログ、ナビゲーション、ページ、テーマについて、全体的なシステムのさまざまなコンテンツの設定を一元的に設定できる場所です。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/blogs/",
        children: "［設定］＞［ウェブサイト］"
      }), "の順に進み、コンテンツ設定にアクセスする対象のコンテンツエリアを選択します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブログ設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここでは、自分のサイトのブログのコンテンツ設定を管理します。複数のブログがある場合は、［修正するブログを選択］の下にあるドロップダウンを使用して切り替えることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg",
        alt: "ブログ設定画面"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［全般］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ブログ名"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ここに設定した名前は、HubL変数", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ content.blog }}"
      }), "および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.public_title }}"
      }), "によってレンダリングされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ブログヘッダー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ここに設定したヘッダーは、HubL変数", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.header }}"
      }), "によってレンダリングされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ページタイトル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ここに設定したタイトルは、HubL変数", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.html_title }}"
      }), "によってレンダリングされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "メタディスクリプション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ここに設定した説明は、HubL変数", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.description }}"
      }), "によってレンダリングされます。このメタディスクリプションは、ブログ リスト ページで使用されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ブログのルートURL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ブログのルートURLは、個別のブログ記事のスラッグの前に付加されます。ここに設定したURLは、HubL変数", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.absolute_url }}"
      }), "によってレンダリングされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "オーディエンスアクセスの管理"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この設定により、ブログ全体へのオーディエンスアクセスを制御できます。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages",
        children: "こちら"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［テンプレート］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "現在のテンプレート"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これは、特定のブログ内の全ブログ記事に使用されるテンプレートです。既定では、ブログ リスト ページにも同じテンプレートが使用されます。リストページのコンテンツを記事とは変化させる場合は、記事コンテンツモジュール内に記述できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "リストページのテンプレート（オプション）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これを設定すると、ブログ リスト ページに対して、ブログ記事に使用されているテンプレートとは別のテンプレートが有効になります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "リストページあたりの記事数"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これにより、既定でブログ リスト ページに表示される記事の数が決まります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ヘッダーHTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ブログ リスト ヘッダーHTMLに追加されたコードは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "変数により、全リストページに追加されます。ブログ記事ヘッダーHTMLに追加されたコードは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "変数により、全ブログ記事に追加されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "フッターHTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ブログ リスト フッターHTMLに追加されたコードは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), "変数により、全リストページに追加されます。ブログ記事フッターHTMLに追加されたコードは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), "変数により、全ブログ記事に追加されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［配信登録］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ブログ登録読者通知Eメール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この設定により、即時、日次、週次、月次のブログ通知Eメールを有効にしたり編集したりできます。指定した期間に新しいブログ記事が公開された場合、これらのEメールが自動的に送信されます。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-blog/set-up-your-blog-subscription-features-in-hubspot",
        children: "こちら"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "RSSフィード"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この設定により、ブログRSSフィードの中の記事の数が決まります。記事の最大件数は50です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［日付形式］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "日付の言語"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この設定により、ブログの日付に表示される月日の言語が決まります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "公開日の形式"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここに設定した形式により、ブログの公開日の順序とパターンが決まります。ロケール データ マークアップ言語（LDML）を使用すると、カスタム日付形式を指定できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "月別の記事の形式"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここに設定した形式により、月別の記事の順序とパターンが決まります。ロケール データ マークアップ言語（LDML）を使用すると、カスタム日付形式を指定できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［コメント］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ブログのコメントを有効または無効にする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この設定により、ブログのコメントを有効または無効にできます。コメントにはモデレーションが必要になる場合があります。また、自動的にクローズされるまでの期間を設定しておきます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ブログのコメント通知"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この設定により、ブログにコメントが付けられた際に、指定されたユーザーへのEメール通知をトリガーすることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［ソーシャルシェアリング］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "既定のTwitterアカウント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここに指定したTwitterアカウントは、Twitterでコンテンツを共有する際にTwitterカードに使用されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ソーシャル シェア ボタン"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この設定により、Twitter、LinkedIn、Facebookのソーシャルシェアリングのボタンを自動的にブログ記事に追加できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［Google AMP］タブ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["AMP（Accelerated Mobile Pages）を使用すると、コンテンツの読み込みが速くなります。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "こちら"
      }), "をご覧ください。コンテンツの読み込みを高速にするには、ページの簡略化が必要になります。そのため、AMPコンテンツでは、スタイルの制御が限られています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Google AMPを有効または無効にする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この設定により、Google AMP形式のページを有効にすることができます。AMPロゴ、フォント、カラーの設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ロゴ、ヘッダーの形式に関するAMP固有の設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "AMPコンテンツを配信するには、スタイルを簡略化する必要があります。この設定により、ロゴ、ヘッダー形式、フォント、色についてAMP固有のスタイルを指定します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ナビゲーション設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ここでは、メニューのリンクと関連付けラベルを管理できます。ドロップダウンを選択し、目的のメニューを選択することにより、設定するメニューを切り替えることができます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/set-up-your-site-s-navigation-menus",
        children: "サイトのナビゲーションメニューの設定に関する詳細をご覧ください。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/navigation-settings-screen-4.jpg",
        alt: "ナビゲーション設定画面"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ページ設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "設定はドメイン別に分類されていて、全てのドメインの既定値の設定が可能です。ページ設定に移動すると、“全てのdomainsの既定”の設定が表示されます。画面上部に切り替えがあり、特定のサブドメインの設定を表示したり変更したりできます。特定のサブドメインに適用される設定は、全ドメインの既定よりも優先されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンテンツ設定へのアクセスと編集ができるのは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "［ウェブサイト設定を編集］マーケティング権限"
      }), "を付与されたユーザーのみです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/page-settings-screen-4.jpg",
        alt: "ページ設定画面"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［テンプレート］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "サイトヘッダーHTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページ設定のサイトヘッダーHTMLフィールドに追加されたコードは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "変数に格納されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "サイトフッターHTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページ設定のサイトフッターHTMLフィールドに追加されたコードは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), "変数に格納されます。通常、サイトの機能や外観を良くする上で必須ではないトラッキングコードやスクリプトを追加する場所として最適です。テンプレートやページに悪影響が及ぶことがないためです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ設定を使用して、ページに読み込むjQueryバージョンを変更できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この設定により、フッターからjQueryを読み込むこともできます。ページURLの末尾に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter=True"
      }), "を追加すると、バージョンの変更をテストして、サイトの外観に悪影響がないことを確かめることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ設定には、jQueryを無効にするオプションもあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［ブランディング］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "既定の「ロゴ」モジュールには、ここで各ドメインに設定されたロゴ画像が自動的に使用されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ファビコン"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ロゴ（代替テキスト、大きさ、リンク）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファビコン画像ソースURLは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "site_settings"
      }), "ディクショナリー（辞書型）から取得して、コードファイルで使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if site_settings.favicon_src %}<link rel=\"icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［パーソナライズ］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "コンタクトおよび会社に関する既定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これは、訪問者が不明な場合に、ページで使用されるパーソナライズトークンに使用される既定値です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［連携］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "JS連携"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全ドメインに対してJS連携（GoogleアナリティクスやAdRollなど）を有効にするか、ここでドメインを選択します。Googleアナリティクスの代わりに他のトラッキングスクリプトまたはGoogleタグマネージャーを使用する場合は、コードを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#site-footer-html",
        children: "サイトのフッターHTML"
      }), "に追加する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［SEOおよびクローラー］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "canonical URL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "個々のページや記事、およびリストページの既定のcanonical化設定をここで選択します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "既定のファイル ホスティング ドメイン"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これは、ファイルマネージャーからのアセット（コンテンツ）をホスティングするドメインを制御します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Robots.txt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここで、各ドメインのrobots.txtファイルを変更します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "［システムページ］タブ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "複数のシステムページがある場合、ページ設定でテンプレートを選択できます。このようなページにはページエディターがありません。「システム」テンプレートタイプとして作成される、デザインマネージャーのテンプレートのみです。注：Eメール配信の設定と配信登録テンプレートは、ページ設定ではなくEメール設定にあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "404および500のエラーページ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらは、404または500のステータスコードで返されるページです。新しいテンプレートを作成する際には、該当するシステムページでテンプレートを使用できるように、デザインマネージャー内で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#error-pages",
        children: "「エラーページ」テンプレートタイプ"
      }), "を選択します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "［パスワードプロンプト］ページ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "パスワードで保護されたページ"
      }), "で訪問者にパスワードの入力を促すときに表示されるページです。新しいテンプレートを作成する際には、この種のシステムページでテンプレートを使用できるように、デザインマネージャー内で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#password-prompt",
        children: "「パスワード プロンプト ページ」テンプレートタイプ"
      }), "を選択します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "検索結果のページとURL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これは、入力された語句の検索結果をHubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-do-i-set-up-a-results-page-for-my-search-field-in-hubspot",
        children: "サイト検索"
      }), "モジュールの中にリスト出力するページです。検索をカスタマイズする方法については", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "こちら"
      }), "（英語）を参照してください。新しいテンプレートを作成する際には、この種のシステムページでテンプレートを使用できるように、デザインマネージャー内で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#search-results-page",
        children: "「検索結果ページ」テンプレートタイプ"
      }), "を選択します。ページ設定では、検索結果ページのURLを指定することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマ設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここでは、サイトに追加されている全テーマを確認できます。サイトで利用可能なテーマのいずれかをクリックすることにより、テーマエディターに進むことができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/theme-settings-screen-4.jpg",
        alt: "テーマ設定画面"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}