"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694137;
const slug = exports.slug = 'guides/cms/content/modules/quickstart';
const title = exports.title = 'Premiers pas avec les modules';
const name = exports.name = 'Premiers pas avec les modules';
const metaDescription = exports.metaDescription = 'Créez un composant réutilisable dans vos pages et modèles, appelé module personnalisé. Vous en découvrirez davantage sur les champs et leur utilisation avec HubL.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Module%20Editor-1-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Premiers pas avec les modules';
const toc = exports.toc = [{
  "depth": 0,
  "id": "avant-de-commencer",
  "label": "Avant de commencer",
  "parent": null
}, {
  "depth": 0,
  "id": "configurer-votre-environnement-de-d%C3%A9veloppement-local",
  "label": "Configurer votre environnement de développement local",
  "parent": null
}, {
  "depth": 0,
  "id": "ajouter-le-boilerplate-cms-%C3%A0-votre-compte",
  "label": "Ajouter le boilerplate CMS à votre compte",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-un-module-dans-hubspot",
  "label": "Créer un module dans HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "ajouter-des-champs-au-module",
  "label": "Ajouter des champs au module",
  "parent": "cr%C3%A9er-un-module-dans-hubspot"
}, {
  "depth": 2,
  "id": "ajouter-le-champ-de-texte-pour-le-nom-du-client",
  "label": "Ajouter le champ de texte pour le nom du client",
  "parent": "cr%C3%A9er-un-module-dans-hubspot"
}, {
  "depth": 2,
  "id": "ajouter-le-champ-d-image-pour-la-photo-de-profil-du-client",
  "label": "Ajouter le champ d'image pour la photo de profil du client",
  "parent": "cr%C3%A9er-un-module-dans-hubspot"
}, {
  "depth": 2,
  "id": "ajouter-le-champ-de-texte-enrichi-pour-le-t%C3%A9moignage-de-sally",
  "label": "Ajouter le champ de texte enrichi pour le témoignage de Sally",
  "parent": "cr%C3%A9er-un-module-dans-hubspot"
}, {
  "depth": 1,
  "id": "ajouter-hubl-pour-afficher-les-donn%C3%A9es-des-champs",
  "label": "Ajouter HubL pour afficher les données des champs",
  "parent": "cr%C3%A9er-un-module-dans-hubspot"
}, {
  "depth": 0,
  "id": "consulter-et-modifier-le-module-localement",
  "label": "Consulter et modifier le module localement",
  "parent": null
}, {
  "depth": 1,
  "id": "ouvrir-le-dossier-du-module-dans-votre-environnement-local",
  "label": "Ouvrir le dossier du module dans votre environnement local",
  "parent": "consulter-et-modifier-le-module-localement"
}, {
  "depth": 1,
  "id": "consulter-le-fichier-fields.json-du-module",
  "label": "Consulter le fichier fields.json du module",
  "parent": "consulter-et-modifier-le-module-localement"
}, {
  "depth": 1,
  "id": "consulter-le-fichier-module.html-du-module",
  "label": "Consulter le fichier module.html du module",
  "parent": "consulter-et-modifier-le-module-localement"
}, {
  "depth": 1,
  "id": "consulter-le-fichier-module.css-du-module",
  "label": "Consulter le fichier module.css du module",
  "parent": "consulter-et-modifier-le-module-localement"
}, {
  "depth": 0,
  "id": "transf%C3%A9rer-vos-modifications-locales-vers-votre-compte-hubspot",
  "label": "Transférer vos modifications locales vers votre compte HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "pr%C3%A9visualiser-vos-modifications-locales-dans-hubspot",
  "label": "Prévisualiser vos modifications locales dans HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "ajouter-le-module-%C3%A0-un-mod%C3%A8le",
  "label": "Ajouter le module à un modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "pr%C3%A9visualiser-vos-modifications-dans-hubspot",
  "label": "Prévisualiser vos modifications dans HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "personnaliser-le-module-dans-le-mod%C3%A8le-localement",
  "label": "Personnaliser le module dans le modèle localement",
  "parent": null
}, {
  "depth": 0,
  "id": "ajouter-deux-autres-modules-de-t%C3%A9moignage-au-mod%C3%A8le",
  "label": "Ajouter deux autres modules de témoignage au modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "modifier-le-nom-du-th%C3%A8me",
  "label": "Modifier le nom du thème",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A9tapes-suivantes",
  "label": "Étapes suivantes",
  "parent": null
}, {
  "depth": 0,
  "id": "autres-tutoriels",
  "label": "Autres tutoriels",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      pre: "pre",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3",
      h4: "h4",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Premiers pas avec les modules"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans ce tutoriel, vous utiliserez le thème boilerplate de CMS Hub afin de découvrir comment créer un module et l'utiliser dans des modèles et des pages dans le cadre d'un thème. À la fin du tutoriel, vous aurez créé un module de témoignage client comprenant un champ d'image, un champ de texte et un champ de texte enrichi."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "S'il s'agit de votre première expérience avec le développement CMS Hub, nous vous recommandons ce qui suit :"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Démarrage rapide du développement CMS Hub"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " si vous souhaitez développer un module à utiliser dans un thème que vous souhaitez répertorier sur le marketplace des ressources HubSpot, consultez les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/module-requirements",
          children: "exigences du marketplace des ressources concernant les modules"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Avant de commencer"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avant de commencer ce tutoriel :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Créez un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-cms-developer-sandbox",
          children: "compte sandbox de développeur CMS Hub"
        }), ". Vous pouvez utiliser votre compte existant, mais le compte sandbox vous permettra de développer sans impacter les éléments de votre compte principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Installez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: "Node.js"
        }), ", qui active les outils de développement local de HubSpot. Les versions 10 ou ultérieures sont prises en charge."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous voulez aller de l'avant, vous pouvez prévisualiser les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
        children: "fichiers de projet"
      }), " terminés. Comme le code du thème de boilerplate CMS peut changer au fil du temps, seuls les fichiers les plus importants que les développeurs devront créer ou modifier au cours du tutoriel sont inclus. Ces fichiers incluent :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial.module :"
        }), " le dossier contenant les fichiers qui composent le module personnalisé que vous allez créer dans le cadre du tutoriel."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "homepage.html :"
        }), " le modèle que vous allez modifier pour inclure le module personnalisé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "images :"
        }), " le dossier contenant les photos de profil que vous utiliserez dans le module."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurer votre environnement de développement local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Au cours de ce tutoriel, vous développerez le module localement. Pour interagir avec HubSpot dans votre interface de ligne de commande, vous devrez installer l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "ILC HubSpot"
      }), " et la configurer pour accéder à votre compte."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le terminal, exécutez la commande suivante pour installer l'ILC globalement. Pour installer l'ILC uniquement dans votre répertoire actuel, exécutez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm install -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dans le répertoire où vous stockerez vos fichiers de thème, authentifiez votre compte HubSpot afin de pouvoir interagir avec lui via l'ILC."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs init\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Appuyez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Entrée"
        }), " pour ouvrir la page de la clé d'accès personnelle dans votre navigateur."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "compte"
        }), " sur lequel vous souhaitez effectuer le déploiement, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Continuer avec ce compte"
        }), ". Vous serez alors redirigé vers la page de la clé d'accès personnelle du compte."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["À côté de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Clé d'accès personnelle"
        }), ", cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Afficher"
        }), " pour révéler votre clé. Cliquez ensuite sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copier"
        }), " pour la copier dans votre presse-papiers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Collez la clé dans le terminal, puis appuyez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Entrée"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Saisissez un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " unique pour le compte. Ce nom sera vu et utilisé uniquement par vous lors de l'exécution des commandes. Pour connecter les outils de développement locaux à votre compte, appuyez sur la touche ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Entrée"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous verrez alors un message de succès confirmant que le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " a été créé."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ajouter le boilerplate CMS à votre compte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Exécutez la commande ci-dessous pour créer un nouveau thème nommé ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), ". Un dossier de thème intitulé ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), " sera alors créé dans votre répertoire de travail, contenant les éléments du boilerplate."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create website-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Téléchargez les fichiers dans votre compte."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs upload <src> <destination>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le chemin d'accès au fichier local, relatif à votre répertoire de travail actuel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le chemin de destination dans votre compte HubSpot. Il peut s'agir d'un nouveau dossier."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme my-new-theme"
      }), " téléchargerait le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-theme"
      }), " de votre ordinateur vers un dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-new-theme"
      }), " dans HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Par défaut, HubSpot va télécharger sur le compte par défaut dans votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config,yml"
        }), ". Cependant, vous pouvez également spécifier un compte en incluant l'indicateur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "--account=<accountNameOrId>"
        }), " dans la commande. Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload --portal=mainProd"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Découvrez-en davantage dans la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "référence des commandes d'ILC"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un module dans HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avec le boilerplate de CMS dans votre environnement local, vous allez maintenant créer un nouveau module pour le thème."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Aux fins de ce tutoriel, vous créerez le module dans HubSpot, puis le transférerez dans le thème à l'aide de l'ILC. Cependant, vous pouvez également créer des modules à partir de zéro localement à l'aide de la commande ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#create-new-files",
          children: "hs create module"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Connectez-vous à votre compte HubSpot, puis accédez au gestionnaire de conception en accédant à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing > Fichiers et modèles > Outils de conception"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la barre latérale gauche du gestionnaire de conception, ouvrez le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "dossier du thème"
          }), " que vous venez de télécharger."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le dossier du thème, ouvrez le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "dossier des modules"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En haut à gauche, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Fichier > Nouveau fichier"
          }), " pour créer un nouveau module dans ce dossier."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la boîte de dialogue, cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "menu déroulant"
          }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Module"
          }), " et ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Suivant"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/new-module-dropdown-menu0.png",
            alt: "new-module-dropdown-menu0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Sélectionnez la case à cocher ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Pages"
          }), " pour que le module soit disponible pour les sites web et les pages de destination."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nommez le module ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Témoignage"
          }), ", puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Créer"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ajouter des champs au module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ensuite, vous allez ajouter trois champs au module :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un champ de texte pour stocker le nom du client qui donne son témoignage."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un champ d'image qui stockera la photo de profil du client."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un champ de texte enrichi qui stockera le témoignage du client."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ajouter le champ de texte pour le nom du client"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la barre latérale droite, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Ajouter un champ"
          }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Texte"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-add-field.png",
            alt: "testimonial-module-add-field"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la barre latérale de droite, cliquez sur l'", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "icône Crayon"
          }), " dans l'angle supérieur droit pour nommer le champ. Pour ce tutoriel, saisissez le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom du client"
          }), ". Vous verrez alors le nom de la variable HubL changer pour ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "customer_name"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Définissez le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "texte par défaut"
          }), " sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sally"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Text-Field-3.png",
        alt: "Exemple de champ de texte"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la barre latérale droite, cliquez sur l'icône du ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fil d'Ariane"
        }), " pour revenir au menu principal du module."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-breadcrumb-icon0.png",
        alt: "module-breadcrumb-icon0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ajouter le champ d'image pour la photo de profil du client"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ajoutez un autre champ en utilisant la même méthode, en sélectionnant cette fois le type de champ ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Image"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Intitulez le champ ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Image de profil"
        }), ", puis définissez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom de la variable HubL"
        }), " sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "profile_pic"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sous ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Image par défaut"
        }), ", sélectionnez la photo de profil fournie pour Sally dans le dossier d'", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "images"
        }), " des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
          children: "fichiers de projet terminés"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Définissez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "texte alternatif"
        }), " sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Image de profil de Sally"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/getting-started-with-modules-profile-pic.png",
        alt: "get-started-with-modules-profile-pic"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ajouter le champ de texte enrichi pour le témoignage de Sally"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ajoutez un autre champ en utilisant la même méthode, en sélectionnant cette fois le type de champ ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Texte enrichi"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Intitulez le champ de texte enrichi ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Témoignage"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur la zone ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Texte enrichi par défaut"
        }), ", puis saisissez « Je n'ai eu que de merveilleuses expériences avec cette entreprise.\""]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Rich-Text-Field-5--.png",
        alt: "Exemple de champ de texte enrichi"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jusqu'à présent, vous avez ajouté des données dans plusieurs champs de module. À ce stade, le module ne contient pas de code HTML pour restituer ces données. Dans l'éditeur de module, cela se traduit par l'état vide de la section ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ".", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-html-empty0.png",
        alt: "module-html-empty0"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ensuite, vous ajouterez HubL à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " pour afficher les données des champs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ajouter HubL pour afficher les données des champs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour afficher les données des champs précédemment créés, ajoutez le HubL suivant au volet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{module.customer_name}}\n<img src={{module.profile_pic.src}} alt=\"{{module.profile_pic.alt}}\">\n{{module.testimonial}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les jetons HubL ci-dessus utilisent la notation par points pour accéder aux données de chaque champ. Dans ce cas, étant donné que nous voulons extraire des données des champs de module, chaque jeton commence par ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ", suivi du nom de la variable HubL du champ. Vous pouvez utiliser la notation par points pour accéder davantage aux propriétés spécifiques d'un champ, que vous pouvez voir dans les jetons ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "profile_pic"
      }), " à la ligne 3 ci-dessus."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Une fois le HubL ajouté à votre module, vous pouvez cliquer sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aperçu"
        }), " en haut à droite pour voir à quoi ressemble le module jusqu'à présent."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-previewer.png",
        alt: "design-manager-previewer"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Puis, dans l'angle supérieur droit, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publier les modifications"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Après avoir créé et publié le module dans HubSpot, vous allez maintenant utiliser l'ILC pour transférer le module dans votre environnement local afin de pouvoir en consulter le contenu et y apporter d'autres modifications."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Consulter et modifier le module localement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour afficher le module localement, vous devez tout d'abord le transférer dans votre thème local :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le terminal, exécutez la commande : ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs fetch <hs_src> <destination>"
          }), " :"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<hs_src>"
              }), " représente le chemin du fichier du module dans HubSpot. Pour obtenir le chemin du fichier, vous pouvez effectuer un clic droit sur le module dans la barre latérale gauche du gestionnaire de conception, puis sélectionner ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Copier le chemin"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-copy-path0.png",
                alt: "design-manager-copy-path0"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<destination>"
              }), " représente le chemin d'accès au répertoire local où se trouve votre thème. S'il est omis, il s'agira par défaut de votre répertoire de travail actuel."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, si vous êtes déjà dans le répertoire de travail, votre commande fetch peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch my-theme/modules/testimonial.module\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ouvrir le dossier du module dans votre environnement local"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans votre éditeur de code préféré, accédez au dossier du module que vous venez de télécharger depuis votre compte HubSpot. Dans le dossier de votre module, vous verrez cinq fichiers différents :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fields.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un objet JSON qui contient les champs de votre module."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un objet JSON qui contient des méta-informations sur votre module."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.css"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le fichier CSS qui donne un style à votre module."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le HTML et le HubL de votre module."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.js"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le JavaScript de votre module."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez des informations plus détaillées dans notre documentation sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "structure des fichiers du module"
      }), ", notamment en ce qui concerne les fichiers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), ". Dans ce tutoriel, nous nous concentrerons sur les fichiers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " ainsi que sur la manière dont ils sont générés, téléchargés et chargés dans l'éditeur de modules du gestionnaire de conception."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Consulter le fichier fields.json du module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ouvrez le module du fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ". À l'exception de certains numéros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", de l'attribut ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " du champ d'image et, éventuellement, de l'ordre des champs, le fichier doit contenir un objet JSON similaire à ce qui suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//fields.json\n\n[\n  {\n    \"label\": \"Customer Name\",\n    \"name\": \"customer_name\",\n    \"id\": \"2a025cd5-7357-007f-ae2f-25ace762588e\",\n    \"type\": \"text\",\n    \"required\": true,\n    \"locked\": false,\n    \"validation_regex\": \"\",\n    \"allow_new_line\": false,\n    \"show_emoji_picker\": false,\n    \"default\": \"Sally\"\n  },\n  {\n    \"label\": \"Profile Picture\",\n    \"name\": \"profile_pic\",\n    \"id\": \"7877fb84-eb8a-d2c7-d939-77e6e9557d8f\",\n    \"type\": \"image\",\n    \"required\": false,\n    \"locked\": false,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"default\": {\n      \"src\": \"https://cdn2.hubspotqa.net/hubfs/101140939/profile-pic-sally.svg\",\n      \"alt\": \"Sally Profile Picture\",\n      \"width\": 100,\n      \"height\": 100,\n      \"max_width\": 100,\n      \"max_height\": 100\n    }\n  },\n  {\n    \"label\": \"Testimonial\",\n    \"name\": \"testimonial\",\n    \"id\": \"c5524ece-1ab5-f92d-a5de-e2bf53199dfa\",\n    \"type\": \"richtext\",\n    \"required\": false,\n    \"locked\": false,\n    \"default\": \"<p>I’ve had nothing but wonderful experiences with this company.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les valeurs des champs suivants correspondront aux valeurs que vous avez ajoutées à l'étape 3 :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " : nom du champ."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " : libellé du champ."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "default"
        }), " : valeur par défaut du champ."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Consulter le fichier module.html du module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " doit contenir le HubL et le HTML que vous avez saisis précédemment dans l'éditeur de module HubL + HTML."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour rendre ce code plus intéressant et adapté pour le style CSS, copiez et collez le code suivant dans le fichier :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"testimonial\">\n  <h1 class=\"testimonial__header\"> {{ module.customer_name }} </h1>\n  <img class=\"testimonial__picture\" src={{ module.profile_pic.src }} alt={{ module.profile_pic.alt }}>\n  {{ module.testimonial }}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En saisissant votre HTML comme ci-dessus, vous utilisez la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/bem-101/",
          children: "structure de classe BEM"
        }), " conformément au ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/STYLEGUIDE.md#css-code-formatting",
          children: "guide de style"
        }), " du thème de boilerplate CMS Hub."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Consulter le fichier module.css du module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " doit être vide à ce stade. Pour ajouter du style, copiez et collez le code suivant dans le fichier :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial {\n  text-align: center;\n}\n\n.testimonial__header {\n  font-weight: bold;\n}\n\n.testimonial__picture {\n  display: block;\n  margin: auto;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Après avoir ajouté le code, enregistrez le fichier."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Transférer vos modifications locales vers votre compte HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Après avoir enregistré vos modifications locales, transférez-les vers votre compte HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Accédez à votre terminal et assurez-vous que vous êtes dans le bon répertoire."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Exécutez la commande watch pour transférer les modifications vers HubSpot lors de la sauvegarde : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <destination>"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch my-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prévisualiser vos modifications locales dans HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fichiers et modèles"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Outils de conception"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la barre latérale gauche, accédez au ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "thème"
        }), " que vous avez créé, puis ouvrez le dossier des ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "modules"
        }), " et sélectionnez le module ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Témoignage"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le module étant ouvert, vous devriez maintenant voir vos modifications dans les volets ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.css"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'angle supérieur droit, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aperçu"
        }), ". Un nouvel onglet s'ouvrira pour afficher l'aperçu du module."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Module-Preview-7.png",
        alt: "Exemple d'aperçu de module"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour récapituler ce tutoriel jusqu'ici, vous avez :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "créé un module dans HubSpot ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "transféré ce module dans votre environnement local ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "apporté des modifications au HTML + HubL et aux CSS en utilisant vos outils de développement locaux."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans la prochaine partie de ce tutoriel, vous découvrirez comment utiliser le module dans un modèle."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ajouter le module à un modèle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour cette partie du tutoriel, vous travaillerez principalement avec les dossiers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modules"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templates"
      }), " dans vos fichiers de thème locaux.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/theme-folder-structure-getting-started-with-modules0.png",
        alt: "theme-folder-structure-getting-started-with-modules0"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans leur définition la plus élémentaire, les modules sont des zones modifiables des modèles et des pages HubSpot. Vous pouvez insérer des modules dans des modèles dans HubSpot en utilisant le gestionnaire de conception. Ici, vous allez utiliser HubL pour ajouter le module à un modèle dans votre environnement local."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre éditeur de code, ouvrez le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "templates"
          }), ", puis ouvrez le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), ", accédez au dernier élément ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), ", qui débute vers la ligne 28. C'est dans cette section que vous ajouterez votre nouveau module."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/home-html-add-testimonial-module-to-section0.png",
            alt: "home-html-add-testimonial-module-to-section0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), ", au-dessus des autres éléments ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_modules"
          }), ", copiez et collez la balise de module HubL suivante :"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path= “../modules/Testimonial.module”,\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette balise HubL référence votre nouveau module par son chemin de fichier relatif. Pour que le module s'adapte uniformément aux deux autres modules de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), ", il attribue également des valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le CMS de HubSpot utilise un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview#columns",
          children: "système de grille à 12 colonnes"
        }), ". Par conséquent, pour espacer ce module uniformément avec les deux autres, vous devrez mettre à jour chaque module dans ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), " pour avoir une largeur de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensuite, le premier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " dans le groupe (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), ") aura un décalage de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), " pour le positionner en premier."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le second ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "linked_image"
        }), ") aura un décalage de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), " pour le positionner en second."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le troisième ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "rich_text"
        }), ") aura un décalage de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "8"
        }), " pour le positionner en troisième."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir défini ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " pour chaque ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ", votre code se présentera comme suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n    background_color=\"#f8fafc\",\n    vertical_alignment=\"MIDDLE\"\n  %}\n\n    {% dnd_module\n      path= “../modules/Testimonial.module”,\n      offset=0,\n      width=4\n    %}\n    {% end_dnd_module %}\n\n    {% dnd_module\n      path=\"@hubspot/linked_image\",\n      img={\n        \"alt\": \"Stock placeholder image with grayscale geometrical mountain landscape\",\n        \"loading\": \"lazy\",\n        \"max_height\": 451,\n        \"max_width\": 605,\n        \"size_type\": \"auto_custom_max\",\n        \"src\": get_asset_url(\"../images/grayscale-mountain.png\")\n      },\n      offset=4,\n      width=4\n    %}\n    {% end_dnd_module %}\n    {% dnd_module\n      path=\"@hubspot/rich_text\",\n      html=\"<h2>Provide more details here.</h2><p>Use text and images to tell your company’s story. Explain what makes your product or service extraordinary.</p>\"\n      offset=8,\n      width=4\n    %}\n    {% end_dnd_module %}\n  {% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Lorsque vous ajoutez un module à une zone de glisser-déposer, la balise du module ne nécessite ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " de nom unique. Toutefois, lorsque vous ajoutez un module à un fichier HTML en dehors des zones de glisser-déposer, vous devez attribuer un nom unique au module. Vous utiliserez également une syntaxe légèrement différente, comme suit :"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% module \"testimonial_one\" path=\"../modules/Testimonial.module\" %}"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "utilisation des modules dans les modèles"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prévisualiser vos modifications dans HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous n'avez pas exécuté la commande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), " pour suivre votre travail automatiquement, exécutez à nouveau ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <dest>"
        }), ". Assurez-vous que cette commande continue d'être exécutée pendant que vous effectuez les étapes suivantes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, ouvrez le gestionnaire de conception (", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fichiers et modèles"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Outils de conception"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la barre latérale gauche du gestionnaire de conception, sélectionnez le fichier ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "home.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En haut à droite, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aperçu"
        }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aperçu en ligne avec options d'affichage"
        }), " pour ouvrir l'aperçu du modèle dans une nouvelle fenêtre."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Live-Preview-10.png",
        alt: "Exemple d'aperçu en ligne"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dans le nouvel onglet, affichez l'aperçu du modèle, qui doit contenir votre module de témoignage nouvellement ajouté."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-added-to-theme.png",
        alt: "testimonial-module-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Personnaliser le module dans le modèle localement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour rendre le modèle de la page d'accueil plus intéressant :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Retournez dans votre éditeur de code, puis ouvrez le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "home.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ajoutez les paramètres suivants à la balise du module de témoignage :"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n testimonial = \"Wow, what a product! I can't wait to recommend this to all my family and friends!\",\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les paramètres ci-dessus remplacent les valeurs par défaut que vous avez initialement attribuées à ces trois champs. Chaque paramètre utilise le nom de variable HubL que vous avez précédemment attribué à chaque champ :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "customer_name"
        }), "** :** ce paramètre transmet le nom ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Mary"
        }), " au champ du nom du client, remplaçant la valeur initiale ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sally"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "profile_pic"
        }), "** :** ce paramètre est un objet qui contient deux propriétés.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["La propriété ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "src"
            }), " utilise la fonction HubL ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "get_asset_url"
            }), " pour récupérer l'URL de la photo de profil. Notez que le chemin du fichier de l'image est relatif à votre répertoire de travail. Vous devez d'abord ajouter cette image au dossier ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), " dans vos fichiers de thème locaux. Vous pouvez trouver l'image dans le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), " des ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
              children: "fichiers de projet"
            }), " terminés."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["La propriété ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " permet d'attribuer le texte alternatif de l'image."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), "** :** ce paramètre permet de transmettre un nouveau texte dans le champ de témoignage."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour le champ de texte enrichi, vous pouvez également utiliser la syntaxe de bloc HubL pour écrire un grand bloc de HTML ou de texte :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n offset=0,\n width=4\n%}\n {% module_attribute \"testimonial\" %}\n   Wow, what a product! I can't wait to recommend this to all my family and friends!\n {% end_module_attribute %}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "syntaxe des blocs de module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous avez conservé la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " en cours d'exécution dans votre terminal, enregistrez vos modifications pour les envoyer à HubSpot. Vous pouvez ensuite retourner dans le gestionnaire de conception pour prévisualiser le modèle."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Mary-Module-in-Template-12.png",
        alt: "Module de Mary dans le modèle"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ajouter deux autres modules de témoignage au modèle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans cette étape, vous allez ajouter deux autres modules de témoignage au modèle en suivant les mêmes étapes que précédemment :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Accédez à votre éditeur de code, puis ouvrez le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Sous le module de témoignage que vous avez ajouté précédemment, ajoutez une autre instance du module en copiant et collant le code de ce module. Dans le nouveau module de témoignage, spécifiez les détails suivants en suivant les mêmes étapes que ci-dessus :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Le nom du client est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Le témoignage d'Ollie est le suivant : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "I can't believe that I ever conducted business without the use of this product!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pour la photo d'Ollie, ajoutez le chemin d'accès relatif du fichier dans le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), ". Vous pouvez obtenir l'image elle-même à partir des ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
              children: "fichiers de projet"
            }), " terminés. Puis, donnez à l'image d'Ollie l'attribut ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie Profile Picture"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pour conserver l'espacement, définissez ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Sous le deuxième module de témoignage, ajoutez-en un troisième avec les détails suivants :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Le nom du client est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Le témoignage d'Erin est le suivant : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "My business has nearly tripled since I began to use this product! Don't wait, start now!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pour la photo d'Erin, ajoutez le chemin d'accès relatif du fichier dans le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), ". Puis, donnez à l'image d'Erin l'attribut ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin Profile Picture"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pour conserver l'espacement, définissez ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "8"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Enregistrez vos modifications."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous avez conservé la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " en cours d'exécution dans votre terminal, vous pouvez revenir au gestionnaire de conception pour prévisualiser les modifications apportées au modèle. L'aperçu du modèle doit maintenant contenir les trois modules de témoignage."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-modules-added-to-theme.png",
        alt: "testimonial-modules-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour terminer, vous préparerez le thème à utiliser dans une page en direct en modifiant le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modifier le nom du thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez modifier le nom par défaut du thème, vous pouvez le faire en configurant les champs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " du fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example theme.json\n\n{\n  \"name\": \"my_first_theme\",\n  \"label\": \"My first theme\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir ajouté ce code, enregistrez vos modifications afin que la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " envoie vos modifications à HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Étapes suivantes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant que vous avez créé un module personnalisé, que vous l'avez ajouté au modèle ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "home.html"
      }), " et que vous avez éventuellement personnalisé le nom du thème, vous êtes prêt à créer une page en direct à partir du thème."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez suivre un tutoriel similaire qui se concentre sur les thèmes, consultez le tutoriel ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/getting-started",
        children: "Premiers pas avec les thèmes"
      }), ". Autrement, découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-customize-pages",
        children: "création et la personnalisation de pages"
      }), " dans la base de connaissances de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous souhaitez en savoir plus sur les modules, consultez les guides de module suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configurer un module"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Utilisation de modules dans les modèles"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Modules par défaut"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autres tutoriels"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Premiers pas avec les thèmes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "Démarrer avec les fonctions sans serveur"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Créer un workflow de développement efficace"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Premiers pas avec l'accessibilité"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/js-frameworks",
          children: "Utiliser les infrastructures JavaScript sur CMS Hub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "Comment utiliser des composants web dans des modules personnalisés"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-build-a-code-block-web-component",
          children: "Comment construire un module basé sur un composant web de bloc de code"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}