"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 85488833916;
const slug = exports.slug = 'guides/api/crm/exports';
const title = exports.title = 'CRM-API | Exporte';
const name = exports.name = 'CRM-API | Exporte';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie die Exporte-API verwenden, um Datensätze in Ansichten oder Listen von Ihrem HubSpot-Account zu exportieren.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "einen-export-starten",
  "label": "Einen Export starten",
  "parent": null
}, {
  "depth": 1,
  "id": "eine-ansicht-exportieren",
  "label": "Eine Ansicht exportieren",
  "parent": "einen-export-starten"
}, {
  "depth": 1,
  "id": "eine-liste-exportieren",
  "label": "Eine Liste exportieren",
  "parent": "einen-export-starten"
}, {
  "depth": 0,
  "id": "exporte-abrufen",
  "label": "Exporte abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "beschr%C3%A4nkungen",
  "label": "Beschränkungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      pre: "pre",
      strong: "strong",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Exporte"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die Exporte-API, um Datensätze und Eigenschaftsdaten aus Ihrer HubSpot-Account zu exportieren, eine URL zum Herunterladen einer Exportdatei abzurufen oder den Status eines Exports anzuzeigen. In HubSpot können Sie auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/export-contacts-companies-deals-or-tickets",
        children: "Datensätze exportieren"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-log-of-your-users-exports-in-your-account",
        children: "ein Protokoll vergangener Exporte in Ihrem Account anzeigen."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Export starten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Export zu starten, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async"
      }), " durch. Ihr Anfragetext sollte Informationen wie das Dateiformat, das Objekt und die Eigenschaften, die Sie exportieren möchten, sowie den Typ des Exports angeben, den Sie abschließen (z. B. das Exportieren einer Objektansicht oder einer Liste). Sie können die zu exportierenden Eigenschaftendaten auch nach bestimmten Operatoren filtern."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sowohl für Exporte von Ansichten als auch für Listen können Sie die folgenden Felder in Ihre Anfrage aufnehmen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ des Exports, entweder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VIEW"
            }), " (exportiert eine Ansicht von einer Objektindexseite) oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LIST"
            }), " (exportiert eine Liste)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Das Dateiformat. Die Optionen umfassen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLSX"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLS"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "der Name des Exports."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Sprache der Exportdatei. Die Optionen umfassen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ES"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FI"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FR"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "JA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PT"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SV"
            }), ". Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-language-offerings",
              children: "unterstützte Sprachen."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Name oder die ID des Objekts, das Sie exportieren. Bei Standardobjekten können Sie den Namen des Objekts verwenden (z. B . ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            }), "), für benutzerdefinierte Objekte müssen Sie jedoch den ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            }), "-Wert verwenden. Sie können diesen Wert abrufen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/schemas"
            }), " durchführen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associatedObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name oder die ID eines zugeordneten Objekts, das in den Export aufgenommen werden soll. Wenn Sie ein zugeordnetes Objekt berücksichtigen, enthält der Export die zugeordneten Datensatz-IDs dieses Objekts und den primären Anzeigewert der Eigenschaft des Datensatzes (z. B. Name). Sie können nur ein zugeordnetes Objekt pro Anfrage exportieren."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Liste der Eigenschaften, die Sie in Ihren Export aufnehmen möchten."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Ansicht exportieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "Ansicht einer Indexseite"
      }), " exportieren, sollte Ihr ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), "-Wert ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "VIEW"
      }), " sein. Sie können das folgende Feld hinzufügen, um die Datensätze, die Sie exportieren, zu filtern und zu sortieren:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publicCrmSearchRequest"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt an, welche Daten basierend auf bestimmten Eigenschaften und Suchanfragen exportiert werden sollen. Sie können Folgendes in das Objekt aufnehmen:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "filters"
            }), ": die Eigenschaften und Eigenschaftswerte, nach denen Datensätze gefiltert werden sollen.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "sorts"
            }), ": die Sortierreihenfolge der Werte einer Eigenschaft, entweder aufsteigend, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASC"
            }), ", oder absteigend, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DES"
            }), ".", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "query"
            }), ": eine Zeichenfolge, nach der die Werte der Datensätze durchsucht werden sollen."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um beispielsweise eine Ansicht der Kontakte und der zugehörigen Unternehmensdatensätze zu exportieren, die nach dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "-Eigenschaft gefiltert sind, kann Ihre Anfrage wie folgt aussehen:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"VIEW\",\n  \"exportName\": \"All contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"DE\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email, firstname, lastname\"],\n  \"associatedObjectType\": \"COMPANY\",\n  \"publicCrmSearchRequest\": {\n    \"filters\": [\n      {\n        \"value\": \"hello@test.com\",\n        \"propertyName\": \"email\",\n        \"operator\": \"EQ\"\n      }\n    ],\n    \"query\": \"hello\",\n    \"sorts\": [\n      {\n        \"propertyName\": \"email\",\n        \"order\": \"ASC\"\n      }\n    ]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Liste exportieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/lists/create-active-or-static-lists",
        children: "Liste"
      }), " exportieren, sollte Ihr ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), "-Wert ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LIST"
      }), " sein, aber Sie müssen die Liste, die Sie exportieren, auch mit dem folgenden Feld angeben:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/lists/lists-faq#ils-list",
              children: "ILS-Listen-ID"
            }), " der zu exportierenden Liste. Sie können den ILS-Listen-ID-Wert über die Listendetails in HubSpot finden. Gehen Sie zu ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Kontakte"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Listen"
            }), ", bewegen Sie den Mauszeiger über die ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Liste"
            }), " in der Tabelle und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Details"
            }), ". Klicken Sie im rechten Bereich neben dem Wert für die ILS-Listen-ID auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Listen-ID"
            }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "kopieren"
            }), ". Kontaktlisten haben zwei verschiedene ID-Werte, aber Sie ", (0, _jsxRuntime.jsx)("u", {
              children: "müssen"
            }), " den ILS-Listen-ID-Wert in Ihrer Anfrage verwenden."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise eine Liste mit den E-Mail-Adressen der Kontakte zu exportieren, kann Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"LIST\",\n  \"listId\": 1234567,\n  \"exportName\": \"Marketing email contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"EN\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email\"]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exporte abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie einen Export erfolgreich abgeschlossen haben, wird die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " des Exports in der Antwort zurückgegeben. Um einen Export von Ihrem HubSpot-Account abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async/tasks/{exportId}/status"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Abrufen von Exporten wird auch der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "status"
      }), " des Exports zurückgegeben. Mögliche Status sind ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PENDING"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSING"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CANCELED"
      }), ". Bei Exporten mit einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), "-Status wird eine URL zurückgegeben, mit der Sie die exportierte Datei herunterladen können. Die Download-URL läuft fünf Minuten nach Abschluss der Anfrage ab. Nach Ablauf können Sie eine weitere ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage durchführen, um eine neue eindeutige URL zu generieren."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Vor dem Ablauf kann ohne zusätzliche Autorisierung auf die Download-URL eines Exports zugegriffen werden. Um Ihre Daten zu schützen, gehen Sie beim Teilen einer URL oder der Integration mit HubSpot über diese API mit Vorsicht vor."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beschränkungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gelten folgende Limits:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Beim Festlegen von Filtern für Ihren Export können Sie maximal drei ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " und bis zu drei ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " in jede Gruppe aufnehmen."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie können bis zu dreißig Exporte innerhalb eines 24-Stunden-Zeitfensters und jeweils einen Export durchführen. Weitere Exporte werden in eine Warteschlange gestellt, bis der vorherige Export abgeschlossen ist."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Eine exportierte CSV-Datei wird automatisch gezippt, wenn die resultierende Datei größer als 2 MB ist."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}