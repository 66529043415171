"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742956;
const slug = exports.slug = 'guides/api/crm/extensions/video-conferencing';
const title = exports.title = 'API do CRM | Extensão de videoconferência';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - VCE';
const metaDescription = exports.metaDescription = 'Esses pontos são usados para conectar o fluxo de criação da reunião dentro do HubSpot e adicionar informações de videoconferência.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "api-de-configura%C3%A7%C3%B5es",
  "label": "API de configurações",
  "parent": null
}, {
  "depth": 1,
  "id": "defini%C3%A7%C3%A3o-de-objeto-de-configura%C3%A7%C3%B5es",
  "label": "Definição de objeto de configurações",
  "parent": "api-de-configura%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "crie-ou-atualize-as-configura%C3%A7%C3%B5es-de-extens%C3%A3o-de-videoconfer%C3%AAncia",
  "label": "Crie ou atualize as configurações de extensão de videoconferência",
  "parent": "api-de-configura%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "buscar-configura%C3%A7%C3%B5es-de-extens%C3%A3o-de-videoconfer%C3%AAncia",
  "label": "Buscar configurações de extensão de videoconferência",
  "parent": "api-de-configura%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "criar-webhook-de-reuni%C3%A3o",
  "label": "Criar webhook de reunião",
  "parent": "api-de-configura%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "atualizar-o-webhook-de-reuni%C3%A3o",
  "label": "Atualizar o Webhook de reunião",
  "parent": "api-de-configura%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "excluir-webhook-de-reuni%C3%A3o",
  "label": "Excluir webhook de reunião",
  "parent": "api-de-configura%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "webhook-de-verifica%C3%A7%C3%A3o-do-usu%C3%A1rio",
  "label": "Webhook de verificação do usuário",
  "parent": "api-de-configura%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "assinatura-do-webhook",
  "label": "Assinatura do webhook",
  "parent": "api-de-configura%C3%A7%C3%B5es"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ol: "ol",
      li: "li",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      strong: "strong",
      pre: "pre",
      ul: "ul",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Extensão de videoconferência"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A API de videoconferência aos integradores a capacidade de se conectar ao fluxo de criação de reuniões no HubSpot e adicionar informações de conferência de vídeo. Usar essa API envolverá as seguintes etapas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Configurar seu aplicativo com os webhooks de extensão de videoconferência. Você fornecerá URIs à HubSpot que ela usará para te notificar quando os clientes estiverem criando ou atualizando reuniões."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tratar dos webhooks da criação de reuniões e, opcionalmente, dos webhooks de atualização de reunião."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tratar dos webhooks de verificação de identidade do usuário se necessário."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API de configurações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os desenvolvedores usarão essa API de configurações para configurar um aplicativo existente. Você pode usar o token de acesso do seu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "app privado"
      }), " para autenticar a solicitação."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definição de objeto de configurações"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O objeto de configurações tem os campos a seguir:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUrl"
      }), ": o URL para o qual as solicitações para novas videoconferências serão enviadas. Ela deve usar o protocolo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUrl:"
      }), " (opcional) o URL para o qual as atualizações de reuniões existentes serão enviadas. Normalmente chamada quando o usuário muda o tópico ou o horário de uma reunião. Ela deve usar o protocolo https."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUrl:"
      }), " (opcional) o URL para o qual as reuniões excluídas no HubSpot serão notificadas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUrl:"
      }), " (opcional) o URL usado para verificar se um usuário existe no seu sistema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crie ou atualize as configurações de extensão de videoconferência"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de solicitação:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT /crm/v3/extensions/videoconferencing/settings/{appId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de resposta:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example 200 response\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de resposta:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Valores opcionais devem ser excluídos da solicitação. Fornecer strings vazias ou outros valores provavelmente resultará em comportamento indesejado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Buscar configurações de extensão de videoconferência"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de solicitação:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET /crm/v3/extensions/videoconferencing/settings/{appId}   "
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de resposta:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example 200 response\n{\n\"createMeetingUrl\": \"https://example.com/create-meeting\",\n\"updateMeetingUrl\": \"https://example.com/update-meeting\"\n\"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n\"userVerifyUrl\": \"https://example.com/user-verify\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar webhook de reunião"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando uma reunião é criada, a HubSpot enviará uma solicitação para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUri"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de solicitação:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request\n{\n\"portalId\": 123123,\n\"userId\": 123,\n\"userEmail\": \"test.user@example.com\",\n\"topic\": \"A Test Meeting\",\n\"source\": \"MEETINGS\"\n\"startTime\": 1534197600000,\n\"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os campos desta solicitação são:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "portalId"
        }), ": o ID da conta da HubSpot (chamada de portal)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), ": o ID exclusivo do usuário do HubSpot proprietário da reunião."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userEmail"
        }), ": o endereço de e-mail do usuário do HubSpot proprietário da reunião."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "topic"
        }), ": o tópico/título da reunião."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "source"
        }), ": REUNIÕES ou MANUAL, indicando o recurso de reunião no", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "aplicativo HubSpot onde a reunião foi criada, REUNIÕES corresponde à", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "função “link de reunião” e MANUAL corresponde a uma reunião criada no", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Comunicador do CRM."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startTime"
        }), ": o horário de início da reunião (em milissegundos de época)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endTime"
        }), ": o horário final da reunião (em milissegundos de época)"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para lidar com este webhook, o desenvolvedor deve gerar uma videoconferência para essa reunião (ou link para uma linha de conferência existente) e responder às informações sobre essa conferência."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de resposta:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"conferenceUrl\": \"https://example.com/join\",\n  \"conferenceDetails\": \"Click here to join: https://example.com/join\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os campos esperado nesta resposta são:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), ": um ID exclusivo associado à conferência neste evento.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Esse ID precisa ser globalmente exclusivo no seu sistema. Enviaremos este ID", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "de volta a você no webhook de atualização."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceUrl:"
        }), " o link de conexão da conferência criada. Ele pode ser", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "colocado no campo “localização” dos eventos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceDetails"
        }), ": informações de \"convite\" em texto simples. Ele deve descrever", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "como os participantes do evento podem acessar a videoconferência desse", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "evento. As novas linhas serão mantidas em representações desse texto no nosso", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "sistema, mas nenhuma outra formatação é compatível."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Atualizar o Webhook de reunião"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você especificar um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUri"
      }), ", a HubSpot enviará uma solicitação a essa URI sempre que os detalhes relevantes da reunião forem alterados. Essa notificação é necessária se você precisar manter o tópico ou horários mais atualizados para uma videoconferência."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de solicitação:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"userId\": 123,\n  \"userEmail\": \"test.user@example.com\",\n  \"portalId\": 123123,\n  \"topic\": \"A Test Meeting (updated)\",\n  \"startTime\": 1534197600000,\n  \"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os campos esperado nesta resposta são:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), ": o ID exclusivo para a conferência fornecido por sua integração na resposta ao webhook de reunião."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), ": o ID exclusivo do usuário do HubSpot proprietário da reunião. Ele sempre será o mesmo userId da solicitação de criação de reunião."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userEmail"
        }), ": o endereço de e-mail do usuário do HubSpot proprietário da reunião. Ele sempre será o mesmo userEmai da solicitação de criação de reunião."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "topic"
        }), ": o tópico/título da reunião."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startTime"
        }), ": o horário de início da reunião (em milissegundos de época)"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "endTime"
      }), ": o horário final da reunião (em milissegundos de época)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nenhum corpo de resposta é obrigatório ao responder a essas solicitações. Precisamos apenas de", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "um código de resposta 200 ou 204 para nos informar que esse webhook foi recebido", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "com sucesso."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Excluir webhook de reunião"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando uma reunião é excluída no HubSpot, enviaremos uma solicitação para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUri."
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de solicitação:"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Essa solicitação contém o conferenceId da reunião que foi excluída.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Nenhum corpo de resposta é obrigatório ao responder a essas solicitações. Precisamos apenas de", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "um código de resposta 200 ou 204 para nos informar que esse webhook foi recebido", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "com sucesso."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhook de verificação do usuário"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os sistemas da HubSpot sempre se comunicam com você sobre seus usuários em termos do seu ID de usuário do HubSpot e seu endereço de e-mail de conta da HubSpot. Há uma chance de um usuário no sistema da HubSpot existir no seu sistema com um endereço ou identificador de e-mail diferente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Antes de fazer uma chamada para o seu sistema para criar, atualizar ou excluir um link de conferência da web, verifique suas configurações para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUri"
      }), ". Se essa URI tiver sido definida, disponibilizaremos uma chamada para recuperar o identificador de usuário nativo. Vamos enviar esse identificador como o endereço de e-mail do usuário na", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "chamada subsequente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há locais no app HubSpot em que podemos fazer essa chamada para você validar que o usuário existe no seu sistema antes de exibir os componentes da UI com os quais ele pode interagir, como um tipo de pré-verificação. Se você não configurou essa URI, sempre supomos que a identidade do usuário foi verificada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depende de você permitir esse recurso. Se precisar manter um mapeamento de usuário no seu sistema, você pode simplesmente mapear o ID ou e-mail do usuário do HubSpot para seu ID de usuário interno em cada chamada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "A solicitação aparecerá assim:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"portalId\": 123123,\n  \"userEmail\": \"test.user@example.com\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode retornar um código de erro 200 ou qualquer outro (404 seria apropriado). Se retornar um 200, você deve retornar a uma carga útil que contém o novo ID que devemos usar no lugar de \"email\":"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"id\": \"any-string-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Assinatura do webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos os webhooks enviados pela HubSpot são assinados pela HubSpot usando o \"segredo do app\" do aplicativo. Veja a seção “Segurança” ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "nesta página"
      }), ": (o resto desta página de Visão geral do webhooks não se aplica a esses webhooks de extensão de videoconferência.)"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}