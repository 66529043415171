"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694127;
const slug = exports.slug = 'guides/cms/content/content-search';
const title = exports.title = 'Content Search';
const name = exports.name = '[new] Content Search';
const metaDescription = exports.metaDescription = 'Utilizing native the site search functionality within the HubSpot CMS, Content Search. The HubSpot CMS has built-in site search functionality to allow your visitors to easily find the content they are looking for. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "searching-content",
  "label": "Searching Content",
  "parent": null
}, {
  "depth": 0,
  "id": "implementing-search-on-your-website",
  "label": "Implementing search on your website",
  "parent": null
}, {
  "depth": 1,
  "id": "site-search-input",
  "label": "Site Search Input",
  "parent": "implementing-search-on-your-website"
}, {
  "depth": 1,
  "id": "site-search-results",
  "label": "Site Search Results",
  "parent": "implementing-search-on-your-website"
}, {
  "depth": 0,
  "id": "search-results-template",
  "label": "Search Results Template",
  "parent": null
}, {
  "depth": 0,
  "id": "how-is-the-search-precedence-determined%3F",
  "label": "How is the search precedence determined?",
  "parent": null
}, {
  "depth": 0,
  "id": "control-indexing-during-development",
  "label": "Control indexing during development",
  "parent": null
}, {
  "depth": 0,
  "id": "default-indexing-behavior",
  "label": "Default indexing behavior",
  "parent": null
}, {
  "depth": 0,
  "id": "how-can-i-exclude-pages-from-being-returned-in-search-results%3F",
  "label": "How can I exclude pages from being returned in search results?",
  "parent": null
}, {
  "depth": 0,
  "id": "how-to-exclude-sections-of-a-page-from-being-indexed-in-search",
  "label": "How to exclude sections of a page from being indexed in search",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h2: "h2",
      a: "a",
      code: "code",
      pre: "pre",
      h3: "h3",
      img: "img",
      ol: "ol",
      li: "li",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Content Search"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Site search functionality allows your visitors to search your website for specific content. All of content hosted on HubSpot is automatically indexed in HubSpot’s search engine."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " content beyond an HTML size of 2 MB will be trimmed before being stored in content search."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Searching Content"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The search engine is accessible through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "site search API"
      }), ". This API supports numerous filtering options to allow you to create a highly customized and powerful search experience on your website. For example, if you wanted to build a search into your blog, you can query for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type=BLOG_POST"
      }), " to only return blog posts. Or, if you wanted to build search into the Spanish version of your website, you could query ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "language=es"
      }), " to only return Spanish pages."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The API returns JSON that can be parsed with JavaScript to display the results on your website. All content types will return the page domain, title, url and language. The description returned is a sample of text from the content which best matches the search term. A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span class=\"hs-search-highlight hs-highlight-html\">"
      }), " element will wrap perfectly matching text, allowing you to highlight matching text with CSS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depending on the type of content searched, the results return slightly different information, so you can display results for unique content types differently. For example, blog posts will return information on which tags the post has, who the author is, and when it was published."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// json\n{\n  \"total\": 850,\n  \"offset\": 0,\n  \"limit\": 10,\n  \"results\": [\n    {\n      \"id\": 3761238962,\n      \"score\": 0.30858153,\n      \"type\": \"SITE_PAGE\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://developers.hubspot.com/docs/cms/search\",\n      \"language\": \"en\",\n      \"title\": \"Content Search\",\n      \"description\": \"Using HubSpot’s native search engine, content search, to implement <span class=\"hs-search-highlight hs-highlight-html\">search on your website</span>\"\n    },\n    {\n      \"id\": 3254581958,\n      \"score\": 0.30858153,\n      \"type\": \"BLOG_POST\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://designers.hubspot.com/blog/getting-started-with-local-development\",\n      \"featuredImageUrl\":\"https://designers.hubspot.com/hubfs/local-development.jpg\"\n      \"title\": \"Getting Started with Local Development\",\n      \"description\":\"The beta Local Development Tooling connects your local workflow to HubSpot, allowing you to utilize <span class=\"hs-search-highlight hs-highlight-html\">version control</span>, your favorite text editor and various web development technologies when developing on the HubSpot CMS.\"\n      \"authorFullName\": \"William Spiro\",\n      \"tags\": [\n        \"Website Development\",\n        \"Local Development\"\n      ],\n      \"publishedDate\": 1447938000000\n    }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implementing search on your website"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are two default modules you can use to easily implement search on your website, which use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "site search API"
      }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_input"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_results"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Site Search Input"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["An input field for visitors to enter search terms into. This module can exist anywhere on your website. This module can be included in a template with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Site Search Results"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Returns a listing of content based on the search term. This module can exist anywhere on your website. This module can be included in a template with"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If the functionality of these modules is not how you want search to work on your website, you can build your own search modules or functionality. The default search modules are designed to be extended based on your search needs. You can view and clone these modules in the Design Manager by searching for their names in the Finder, or, you can fetch them to your local environment using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), " by running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_input.module"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_results.module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/default%20search%20module.png",
        alt: "default search module"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Search Results Template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Every domain has its own search results page by default. The template and path for this page are set at ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Settings > Website > Pages"
      }), " under the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "System Pages tab"
      }), " for specific domains. See the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "CMS theme boilerplate search results template"
      }), " as an example. The domain set for the search results page is automatically connected to the default search modules. However, you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "site search API"
      }), " to build our your search results as you'd like on any pages of your website."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How is the search precedence determined?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The order of search results is determined by a series of weighted comparisons of page content to the visitor's search term. Page content is separated into comparison fields with varying weight based on where the content lives within the HTML of your pages. Comparison fields are grouped in order of weight:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML title"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Meta description"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "H1 HTML elements"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "H2 HTML elements"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "H3 HTML elements"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Other HTML elements"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Please note that this list is subject to change."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you wish to tell our indexer to specifically boost certain elements on a page, you can wrap the content in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-keyword"
      }), " class."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Control indexing during development"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While developing a new site, it's useful to be able to test site search without worrying about the site being indexed by search engines such as Google."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), " you can tell HubSpot to crawl everything, while blocking other bots."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /\n\n# Allow HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nAllow: /\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If any of your pages have a meta tag specifying no index, the page will still not be indexed, even if allowed in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Also remember to review your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " prior to launch to ensure everything indexes how you want it to."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Default indexing behavior"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Because the CMS knows the intent of certain types of pages, content search is able to safely limit indexing to allow indexing of content type pages. Examples of content type pages:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Site Pages"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Landing Pages"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Knowledge articles"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "blog posts"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "System pages and password protected pages are not indexed."
      }), " CMS Membership restricted pages will only display in hubspot content search for users that are signed in and have access to the pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How can I exclude pages from being returned in search results?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you block a page from being indexed to search engines via your websites ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), " file or via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta"
      }), " tags, they will not be indexed for site search."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "robots.txt"
      }), " add a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "disallow"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /path-to-page\n\n# Block just HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nDisallow: /path-to-page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also add a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "NOINDEX, NOFOLLOW"
      }), " meta tag in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " at the page or template level."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<meta name=\"ROBOTS\" content=\"NOINDEX, NOFOLLOW\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You don't need to block robots using both ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " and the meta tag. Doing so can make it confusing later if you decide to allow indexing of a page."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How to exclude sections of a page from being indexed in search"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sometimes there are regions of a page that are not useful to return in search results. This might be content from a header, a footer, sidebar, etc. When this is the case, you can add the class ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-hidden"
      }), " to your HTML for those regions to have search ignore the content inside."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}