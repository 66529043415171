"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492355345;
const slug = exports.slug = 'reference/cms/modules/files';
const title = exports.title = 'Codificar módulos personalizados';
const name = exports.name = 'EMEA ES module files';
const metaDescription = exports.metaDescription = 'Los módulos personalizados son componentes reutilizables que puedes utilizar en tu sitio web. Constrúyelos siguiendo las mejores prácticas de velocidad, experiencia de usuario y accesibilidad.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "html-%2B-hubl-module.html",
  "label": "HTML + HubL (module.html)",
  "parent": null
}, {
  "depth": 0,
  "id": "css-module.css",
  "label": "CSS (module.css)",
  "parent": null
}, {
  "depth": 1,
  "id": "estilo-basado-en-los-valores-de-los-campos-del-m%C3%B3dulo",
  "label": "Estilo basado en los valores de los campos del módulo",
  "parent": "css-module.css"
}, {
  "depth": 2,
  "id": "clases-css",
  "label": "Clases CSS",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "bloque-require_css",
  "label": "bloque require_css",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "agregar-estilo-en-l%C3%ADnea",
  "label": "Agregar estilo en línea",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "importar-archivos-css-espec%C3%ADficos",
  "label": "Importar archivos CSS específicos",
  "parent": "css-module.css"
}, {
  "depth": 0,
  "id": "javascript-module.js",
  "label": "JavaScript (module.js)",
  "parent": null
}, {
  "depth": 1,
  "id": "scripts-basados-en-los-valores-de-los-campos",
  "label": "Scripts basados en los valores de los campos",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "atributos-de-los-datos",
  "label": "Atributos de los datos",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "bloque-require_js",
  "label": "bloque require_js",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "require_js",
  "label": "require_js",
  "parent": "javascript-module.js"
}, {
  "depth": 0,
  "id": "informaci%C3%B3n-relacionada",
  "label": "Información relacionada",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      img: "img",
      h2: "h2",
      pre: "pre",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Archivos de módulos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se construye un módulo para páginas, blogs y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/quotes",
        children: "cotizaciones"
      }), ", el módulo contendrá tres archivos relacionados con el front-end que controlan el contenido, el estilo y la funcionalidad del módulo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.html"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.css"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.js"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Los módulos de correo electrónico no son compatibles con module.css y module.js. Debido a que los clientes de correo electrónico no admiten JavaScript y la compatibilidad con archivos CSS vinculados es limitada."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Estos archivos siempre se renderizarán en la página cuando una instancia del módulo esté en la página."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando una página incluye varias instancias del mismo módulo, HubSpot sólo cargará ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " de ese módulo una vez. Por opción predeterminada, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " no se cargan de forma asíncrona, pero puedes cambiar esto incluyendo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options y js_render_options"
      }), " en el meta.json del módulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los módulos se pueden construir dentro del administrador de diseño o localmente usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "la CLI de HubSpot"
      }), ". En el administrador de diseño, los archivos de los módulos se muestran en un editor de varios paneles."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module1.png",
        alt: "cms-dev-custom-module1"
      }), "Cuando se visualiza un módulo de forma local, los archivos están contenidos en las carpetas module-name.module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module0.png",
        alt: "cms-dev-custom-module0"
      }), "Si utilizas el administrador de diseño o la CLI para crear y administrar módulos depende de las preferencias de tu equipo. Consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "creación de un workflow eficiente para desarrolladores"
      }), " para recomendaciones."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTML + HubL (module.html)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El archivo module.html está destinado a HTML y HubL. En general, el lugar en el que se coloca un módulo en el editor de páginas o en el archivo de plantilla determina el lugar en el que se muestra el contenido del archivo module.html."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este archivo actúa como un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "HubL include"
      }), " en la página donde se coloca el módulo. El archivo module.html ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#using-module-field-data-to-render-html",
        children: "puede acceder a los valores de los campos del módulo a través de HubL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CSS (module.css)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " para agregar CSS a un módulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En general, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " soporta un subconjunto muy limitado de HubL. Sin embargo, puedes utilizar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#module-asset-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_asset_url(\"my-image.png\")"
        })
      }), " para las imágenes agregadas como materiales vinculados al módulo. Esto permite vincular materiales como imágenes, empaquetados con el propio módulo. Por ejemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial-module__wrapper {\n  background: url('{{ module_asset_url('bg-pattern.png') }}');\n  background-repeat: repeat;\n  min-height: 200px;\n  width: 100%;\n  display: block;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, aprende a configurar el CSS de un módulo para que cambie dinámicamente basado en los campos del módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilo basado en los valores de los campos del módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay algunas maneras de influir en el estilo de tu módulo basado en los campos del módulo. Elige la manera que mejor se adapte a tu caso de uso específico."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#css-classes",
          children: "Clases CSS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#require-css-block",
          children: "Bloque Require_css"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#inline-styles",
          children: "Estilos en línea"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Clases CSS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para establecer un estilo predefinido para el módulo con la opción de que los editores seleccionen entre esas opciones, puedes agregar un campo de módulo para establecer clases en tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.htm"
      }), "l que se correspondan con las clases CSS de tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, puedes tener un módulo de imagen y texto. Quieres que los creadores de contenidos puedan colocar la imagen a la derecha o a la izquierda del texto en función de un campo de elección. Para ello, puedes configurar tus archivos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " de la siguiente manera:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n\n<section class=\"img-text__wrapper img-text--{{ module.positioning }}\" aria-label=\"{{ module.heading }}\">\n  {# module.position is a choice field with two values \"img-left\" or \"img-right\". This dictates the order they appear on desktop. Controlled by CSS #}\n  <div class=\"img-text__img\">\n      <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\">\n  </div>\n  <div class=\"img-text__text\">\n      <h2>\n        {% inline_text field=\"heading\" value=\"{{ module.heading }}\" %}\n      </h2>\n      {% inline_rich_text field=\"text\" value=\"{{ module.text }}\" %}\n  </div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "/* module.css */\n\n/* CSS that makes the image show adjacent to the text, \nand positioned based on the positioning field.*/\n\n/* The media query ensures that on mobile the image \nwill always appear above the text for \nvisual consistency. */\n\n@media (min-width: 768px) {\n  .img-text__wrapper {\n    display: flex;\n    align-items: row;\n  }\n  .img-text__img,\n  .img-text__text {\n    flex: 1;\n    padding: 10px;\n  }\n  .img-text--img-right {\n    flex-direction: row-reverse;\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "bloque require_css"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando necesitas dar a los creadores de contenido un control directo sobre propiedades específicas y cuando las clases no son ideales, las etiquetas de estilo con bloques ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " son la mejor opción."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para dar a los creadores de contenido un control directo sobre propiedades específicas sin usar clases, puedes agregar estilos al archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " dentro de las etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), ". Por ejemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n  <style>\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  </style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Debido a que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " puede renderizar HubL, puedes utilizar los valores de los campos del módulo como variables CSS. Cuando un creador de contenido actualiza el campo en el editor de la página, el CSS se actualizará para coincidir. Este bloque mueve las etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), " dentro de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " de tu página dentro de la sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes establecer que el CSS se aplique sólo a la instancia del módulo envolviendo el CSS con etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scope_css"
      }), ". Por ejemplo, podrías actualizar el código del módulo anterior de la siguiente manera:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n<style>\n  {% scope_css %}\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  {% end_scope_css %}\n</style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Agregar estilo en línea"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando se necesita dar a los creadores de contenidos un control granular sobre sólo unas pocas propiedades y cuando las clases no son ideales, puedes agregar directamente los valores a un atributo de estilo en el HTML."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Module.html #}\n<div style=\"background: rgba({{ module.bg_color.color|convert_rgb }},{{ module.bg_color.opacity/100 }});\">\n  {% inline_rich_text field=\"richtext\" value=\"{{ module.richtext }}\" %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tienes muchas propiedades y el código se vuelve difícil de leer, considera cambiar al método de bloque ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Importar archivos CSS específicos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " es una función de HubL que puedes agregar a module.html y que indica a HubSpot que un módulo o plantilla en particular requiere un archivo CSS concreto para mostrarse. Se agrega una etiqueta de enlace que apunta al archivo css a la página ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " dentro de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La función ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " sólo cargará ese archivo CSS una vez, independientemente de las veces que ese mismo archivo sea requerido por los módulos y plantillas de una página concreta. Esto lo hace ideal para situaciones en las que los estilos pueden ser compartidos a través de múltiples módulos, pero donde agregar el CSS directamente a las hojas de estilo principales utilizadas en cada página de tu sitio puede no tener sentido."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " y los archivos CSS enlazados cumplen el mismo propósito, pero ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " puede utilizarse de forma condicional en función de los valores de los campos. Así se evita cargar un código innecesario."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{ require_css(get_asset_url(\"/modules/shared_layout_styles.css\")) }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScript (module.js)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " para agregar JavaScript a un módulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al igual que el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), ", el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " no soporta HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Scripts basados en los valores de los campos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay algunas maneras de construir módulos, donde el JavaScript actúa de manera diferente basándose en los valores de los campos. Entender qué método utilizar y cuándo puede significar beneficios de rendimiento en cada página en la que se utilice el módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, tienes un módulo de imágenes personalizado, quieres dar a los creadores de contenido la posibilidad de hacer que la imagen se abra en un lightbox. Los creadores de contenido sólo quieren eso para imágenes específicas, y no para todas las instancias del módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Atributos de los datos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los atributos de datos son atributos personalizados estándar de HTML 5 que los desarrolladores agregan a los elementos. Al igual que todos los elementos admiten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "class=\"yourClassName\""
      }), ", todos los elementos admiten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-your-attribute=\"yourValue\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html-->\n<div class=\"img-module img-module__wrapper\" data-lightbox=\"{{ module.is_lightbox_enabled }}\" data-caption=\"above\">\n  <!-- module.is_lightbox_enabled is a boolean field, module.caption_position is a choice field. -->\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes usar atributos de datos para pasar los valores de los campos de tus instancias de módulos para que sean manejados por tu archivo module.js."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para utilizar los valores de tu archivo module.js, tendrás que recorrer todas las instancias de tu módulo. Agregar un nombre de clase específico del módulo al elemento envolvente más externo de tu módulo te dará un objetivo a utilizar, para que puedas hacer un bucle a través de cada una de tus instancias del módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// module.js\n\nlet imgModules = document.getElementsByClassName('img-module');\nArray.from(imgModules).forEach(function (element) {\n  // loop through each of the instances of the module\n  // set data attributes to variables to make it easy to work with\n  let isLightboxEnabled = element.dataset.lightbox;\n  let captionStyle = element.dataset.caption;\n  if (isLightboxEnabled) {\n    element.addEventListener('click', function () {\n      showLightbox(captionStyle); // Execute your code for the action you want to take, you can pass your data attributes into functions from libraries.\n    });\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los atributos de datos te permitirán recuperar los valores de los campos para cada instancia del módulo en tu module.js."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "bloque require_js"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En situaciones avanzadas, tal vez cuando se utiliza una biblioteca de plantillas de JavaScript o un framework reactivo como Vue.js o React.js, puedes preferir la salida de sólo los datos, mientras que el framework maneja la representación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En este caso, utiliza una etiqueta de script rodeada por un bloque ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " para proporcionar variables a las que puedas acceder desde el script de la plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% require_js %}\n<script>\n  let myArray = [\n    {%- for item in module.repeating_text_field -%}\"{{ item }}\",{%- endfor -%}\n  ];\n</script>\n{% end_require_js %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta técnica puede ser útil para suministrar a las aplicaciones avanzadas un conjunto inicial de datos a partir del cual se puede renderizar. Esto elimina una llamada inicial de JavaScript para recuperar los datos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " es una función de HubL que indica a HubSpot que un módulo o plantilla en particular requiere un archivo JavaScript concreto para cargarse correctamente. La función toma dos parámetros: la ruta del archivo y la ubicación a la que se debe agregar el archivo (\"encabezado\" o \"pie de página\")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En un módulo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " sólo se puede agregar al module.html. El archivo JavaScript al que se hace referencia en la sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " sólo se cargará una vez por página, independientemente del número de veces que lo requieran los módulos y plantillas dentro de la página. Esto reduce el número de peticiones HTTP y evita la duplicación de código."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Algunas situaciones en las que esto resulta útil:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si tienes varios módulos o plantillas que requieren el mismo JavaScript, puedes usar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " para compartir ese script entre módulos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si trabajas con un bundler de JavaScript como webpack, puede ser más fácil enviar tus archivos js a una ubicación específica. Usando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), ", puedes asociar el JavaScript con tu módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " y los archivos javascript enlazados sirven para el mismo propósito, pero ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " puede hacerse condicionalmente en base a los valores de los campos. Esto evita que se cargue un código innecesario. También tienes la opción adicional de cargar JavaScript en el encabezado, si lo necesitas."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Debido aque JavaScript bloquea la visualización, la ubicación predeterminada en la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " coloca JavaScript es el \"pie de página\". ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Más información sobre la optimización del rendimiento."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Información relacionada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Optimizar la velocidad de tu sitio CMS Hub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Campo de módulo"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}