"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 43188701587;
const slug = exports.slug = 'guides/cms/debugging/code-alerts';
const title = exports.title = 'コードアラート';
const name = exports.name = 'APAC JAPAN (ja) | [new] Code Alerts_JA | 202104';
const metaDescription = exports.metaDescription = 'コードアラートでは、CMSページ内で特定されたCSS、モジュール、またはテンプレートに関する問題の概要を確認できます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%B3%E3%83%BC%E3%83%89%E3%82%A2%E3%83%A9%E3%83%BC%E3%83%88%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95",
  "label": "コードアラートを表示する方法",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97%E3%81%A8%E5%95%8F%E9%A1%8C",
  "label": "アセットタイプと問題",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97",
  "label": "アセットタイプ",
  "parent": "%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97%E3%81%A8%E5%95%8F%E9%A1%8C"
}, {
  "depth": 1,
  "id": "%E5%95%8F%E9%A1%8C",
  "label": "問題",
  "parent": "%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97%E3%81%A8%E5%95%8F%E9%A1%8C"
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%95%8F%E9%A1%8C%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95",
  "label": "アセットに関する問題を表示する方法",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%80%81%E3%83%9A%E3%83%BC%E3%82%B8%E3%80%81%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88",
  "label": "テンプレート、ページ、モジュールのアセット",
  "parent": "%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%95%8F%E9%A1%8C%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95"
}, {
  "depth": 1,
  "id": "css%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88",
  "label": "CSSアセット",
  "parent": "%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%95%8F%E9%A1%8C%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "コードアラート"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コードアラートにより、開発者やITマネージャーは、CMS Hubで見つかった問題の概要を1か所で確認できます。コードアラートで特定された問題を解決すると、総合的な顧客体験やサイトパフォーマンスを改善し、ウェブサイトを最適化する上で役立ちます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["HubSpotを活用してサイトの効果を最大限に引き出す方法は、他にも", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "CDN、セキュリティー、パフォーマンスのリファレンスページ"
        }), "で紹介しています。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alerts-dashboard.jpg",
        alt: "コードアラートのダッシュボード"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コードアラートを表示する方法"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ポータル全体のコードアラートを表示するには、権限をお持ちの公開済みCMSページであればスプロケットメニューをクリックし、［コードアラートを表示］を選択します。または、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/code-alerts",
        children: "コードアラート"
      }), "に直接進んでください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/view-code-alerts-menu.png",
        alt: "スプロケットメニューからコードアラートを表示する"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アセットタイプと問題"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アセットタイプ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コードアラートでは、HubSpotのCMSで構築されたウェブサイト内の多様なアセットをスキャンできます。以下に一覧を示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "アセットタイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Blog Post"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "貴社のHubSpotブログからのブログ記事。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Site Page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotのCMS上のウェブサイトページ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Landing Page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "特定の目的に特化したウェブサイトページ。ランディングページの目的は、訪問者をリードへと転換することです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "貴社のHubSpotブログのリストページ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Module"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テンプレート内で使用したり、ページに追加したりできる再利用可能な要素。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Template"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テンプレートは再利用可能なページラッパーまたはEメールラッパーです。通常はテンプレートによってモジュールやパーシャルがレイアウトに配置されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "カスケーディング スタイル シート ファイル。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Knowledge Article"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "貴社のHubSpotナレッジベースの記事。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Unknown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アセットタイプが不明な場合。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "問題"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アセットにはさまざまな種類の問題が発生する可能性があります。アセットに複数の問題がある場合は、コード アラート ダッシュボードに複数回表示されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "問題"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HubL limit"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The HubL function ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "blog_recent_tag_posts"
            }), " is used ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "11"
            }), " times on this page. ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "blog_recent_tag_posts"
            }), " has a limit of ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "5"
            }), " uses per page."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/not-found",
              children: "一部のHubL関数には使用の上限があります"
            }), "。関数が上限を超えた場合は、この問題が表示されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSS combining"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There is a syntax error in a line of code that is preventing the CSS files from being combined."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ファイルのミニフィケーション／結合の妨げとなる問題を含んでいるCSSファイルを示します。また、このアセットに関連する", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "構文エラーを表示"
            }), "するオプションもあります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Output too big"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This page is ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "10446 Kilobytes"
            }), ". The size limit is ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "9765 Kilobytes"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのために生成されたHTMLが上限を超えました。結果として空白ページまたは部分的なページが表示される可能性があります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Template error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There is an error in the code that is preventing this template from being rendered."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テンプレートコード内にテンプレートのレンダリングを妨げるエラーがあることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Unknown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unknown issue"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "システムによってアセットのエラーが特定されない場合は、不明な問題が割り当てられます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アセットに関する問題を表示する方法"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コードアラートには、アラートが検出されたアセットへのリンクが含まれます。行の上で、アセットタイプに応じたオプションが次のように表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テンプレート、ページ、モジュールのアセット"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［アクション］ボタンに、該当するテンプレートまたはモジュールをデザインマネージャーで開くためのリンクが表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/my-template-open-in-dm.jpg",
        alt: "テンプレートとモジュールの問題"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CSSアセット"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［アクション］ボタンに、該当するスタイルシートをデザインマネージャーで開いたり、デバッグ情報をファイルで確認したり、構文エラーを表示したりするためのリンクが表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alerts-css-options.jpg",
        alt: "CSSの問題"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［構文エラーを表示］を選択すると、モーダルウィンドウが表示され、構文エラーと該当する行番号などの詳細が示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alert-syntax-error-details.png",
        alt: "構文エラーの詳細モーダル"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}