"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79942329448;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/overview';
const title = exports.title = 'Visão geral das funções sem servidor';
const name = exports.name = 'LATAM BR (PT) Serverless Overview';
const metaDescription = exports.metaDescription = 'As funções sem servidor são escritas em JavaScript e usam o tempo de execução do NodeJS. Use-as para adicionar recursos novos e avançados aos sites do HubSpot CMS.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "exemplos",
  "label": "Exemplos",
  "parent": null
}, {
  "depth": 0,
  "id": "limites",
  "label": "Limites",
  "parent": null
}, {
  "depth": 1,
  "id": "limites-de-execu%C3%A7%C3%A3o",
  "label": "Limites de execução",
  "parent": "limites"
}, {
  "depth": 1,
  "id": "dividir-depend%C3%AAncias",
  "label": "Dividir dependências",
  "parent": "limites"
}, {
  "depth": 0,
  "id": "acessar-as-fun%C3%A7%C3%B5es-sem-servidor",
  "label": "Acessar as funções sem servidor",
  "parent": null
}, {
  "depth": 1,
  "id": "pastas-das-fun%C3%A7%C3%B5es-sem-servidor",
  "label": "Pastas das funções sem servidor",
  "parent": "acessar-as-fun%C3%A7%C3%B5es-sem-servidor"
}, {
  "depth": 1,
  "id": "serverless.json",
  "label": "Serverless.json",
  "parent": "acessar-as-fun%C3%A7%C3%B5es-sem-servidor"
}, {
  "depth": 1,
  "id": "function.js",
  "label": "Function.js",
  "parent": "acessar-as-fun%C3%A7%C3%B5es-sem-servidor"
}, {
  "depth": 0,
  "id": "segredos",
  "label": "Segredos",
  "parent": null
}, {
  "depth": 0,
  "id": "visualizar-logs-de-fun%C3%A7%C3%B5es-sem-servidor",
  "label": "Visualizar logs de funções sem servidor",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Funções sem servidor"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " se estiver criando uma função sem servidor como parte de um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/platform/create-a-project-for-ui-extensions",
          children: "projeto de desenvolvedor"
        }), ", visite a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/platform/serverless-functions",
          children: "documentação para desenvolvedores sobre a função sem servidor em projetos"
        }), ". A documentação abaixo é destinada a criação de funções sem servidor fora da plataforma do projeto do desenvolvedor."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As funções sem servidor oferecem uma forma de escrever código do lado do servidor que interaja com a HubSpot e serviços de terceiros através de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "APIs"
      }), ". As APIs que necessitam de autenticação não são seguras para o front-end de um site, já que sias credenciais estariam expostas. As funções sem servidor podem agir como um intermediário, permitindo que você mantenha suas credenciais em segredo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com as funções sem servidor, você não precisa criar e gerenciar novos servidores. As funções sem servidor exigem menos pessoal e, por isso, são mais fáceis de ampliar conforme a empresa cresce."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode experimentar as funções sem servidor usando uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/pt/signup/standalone-cms-developer",
        children: "conta de ambiente de testes para desenvolvedores de CMS"
      }), ". Para criar sua primeira função sem servidor, confira o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/guides/getting-started-with-serverless-functions",
        children: "guia de introdução às funções sem servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemplos"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Você pode usar as funções sem servidor do HubSpot para tudo o que quiser e o limite é a sua imaginação. Você pode usá-las para:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Coletar e armazenar dados no HubDB ou no HubSpot CRM"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Calcular dados complexos"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Exibir de forma dinâmica dados de outros sistemas"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/pt/docs/cms/features/serverless-functions/event-registration-app",
                children: "Sistemas de registro de eventos"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Envios de formulários que enviam dados para outros sistemas"
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172853952400"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com o exemplo do sistema de registro de eventos, você pode usar as funções sem servidor para controlar o registro e a atualização do número de vagas em aberto para um evento. O fluxo funcionaria da seguinte maneira:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O visitante do site navega até a página de inscrição do seu evento, que mostra que há vagas para mais 15 pessoas comparecerem. O visitante preenche um formulário personalizado para se inscrever no evento e o envia."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Esse envio que definimos para enviar uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yourwebsite.com/_hcms/api/event/participants"
        }), ". ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "event/participants"
        }), " é a sua função sem servidor."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sua função sem servidor recebe os dados enviados pelo usuário e toma algumas ações antes de retornar uma resposta ao navegador:"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Envia os dados de campo do formulário à API de envio de formulário do HubSpot para adicionar essa informação de envio ao HubSpot CRM."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utiliza a API do HubDB para subtrair 1 da contagem de participantes deste evento que está armazenada no HubDB."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Envia uma resposta para o navegador da web."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O Javascript na página recebe a resposta da função sem servidor e exibe uma mensagem de confirmação para o usuário final, ajustando o número de vagas restantes para participantes."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As funções sem servidor do HubSpot são escritas em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
        children: "JavaScript"
      }), " e usam o tempo de execução do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/about/",
        children: "NodeJS"
      }), ". As funções sem servidor do HubSpot devem ser usadas para adicionar funcionalidades ao seu site HubSpot, tais como oferecer suporte a envios de formulários avançados e obter dados de outras APIs. Não pretende ser uma plataforma de computação genérica na qual você executa código não relacionado ao HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As funções sem servidor destinam-se a ser rápidas e a ter foco restrito. Essa agilidade as torna companheiras perfeitas para o front-end de sites e aplicativos, possibilitando uma rápida chamada e resposta. Para manter o desempenho, as funções sem servidor HubSpot estão limitadas a:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "50 segredos por conta."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128 MB de memória."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "não mais que 100 endpoints por conta da HubSpot."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["o contentType ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), " ao chamar uma função."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6MB por carga útil de invocação, que você pode encontrar ao tentar carregar um arquivo com uma função sem servidor, por exemplo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "4KB para a quantidade de dados que podem ser registrados. Ao atingir esse limite, é recomendável registrar as ações individuais, em vez da saída final."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Limites de execução"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cada função tem no máximo 10 segundos de tempo de execução"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cada conta é limitada a um total de 600 segundos de execução por minuto."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Isso significa que qualquer um destes cenários pode acontecer dentro de um minuto:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Até 60 execuções de funções que levam 10 segundos para serem concluídas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Até 6.000 execuções de funções que levam 100 milissegundos para serem concluídas."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As funções que excedem esses limites geram um erro. A contagem de execução e os limites de tempo retornarão uma resposta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), ". O tempo de execução de cada função está incluído nos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#viewing-serverless-function-logs",
        children: "logs das funções sem servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Dividir dependências"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As funções sem servidor, quando implantadas, não oferecem suporte à divisão de JavaScript entre vários arquivos. Em vez disso, a função sem servidor deve incluir um arquivo JavaScript para executar a função. Se você estiver criando uma função sem servidor com vários arquivos JavaScript, deverá copiar o código compartilhado para o arquivo JavaScript, ou usar o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), " para agrupar seu código. Saiba mais sobre como usar o webpack como uma solução na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/Import-packages-in-serverless-functions/m-p/346620",
        children: "Comunidade da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Acessar as funções sem servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No HubSpot, as funções sem servidor são armazenadas no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/key-concepts#developer-file-system",
        children: "sistema de arquivos do desenvolvedor"
      }), " e podem ser exibidas no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cos-general/a-quick-tour-of-the-design-manager",
        children: "gerenciador de design"
      }), ". Você pode acessar e editar suas funções sem servidor localmente através da CLI."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para incluir funções sem servidor em um projeto de desenvolvedor, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/pt/cms-js-building-block-examples/",
        children: "Documentação de blocos de criação de JavaScript"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pastas das funções sem servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["As funções sem servidor do HubSpot residem dentro de uma pasta. Esta pasta pode ter qualquer nome, mas deve conter o sufixo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".functions."
            }), " Os arquivos armazenados nesta pasta não podem ser publicamente acessados."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Dentro da pasta de funções, inclua seu arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), " junto com um arquivo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#function-js",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: ".js"
              })
            }), " que contenha as suas funções. Você pode considerar adicionar um arquivo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#readme-md",
              children: "LEIAME"
            }), " para informar para que servem as funções, como elas funcionam e se há um processo definido para criá-las."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-03%20at%208.24.31%20AM.png",
            alt: "Pasta de funções sem servidor (.functions)"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para evitar edições acidentais dentro do gerenciador de design, você pode bloquear sua pasta. Para bloquear uma pasta, navegue até o gerenciador de design, clique com o botão direito do mouse na ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "pasta"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bloquear pasta"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Serverless.json"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " as funções sem servidor incluídas nos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/platform/serverless-functions",
          children: "projetos de desenvolvedores"
        }), " foram atualizadas a partir da versão ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2023.2 da plataforma"
        }), ", incluindo um novo esquema ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), ". Saiba mais sobre o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/platform/platform-versioning",
          children: "controle de versão da plataforma de projetos"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/features/serverless-functions/reference#serverless-json",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        })
      }), " é o arquivo de configuração da função sem servidor que especifica o ambiente de tempo de execução e todas as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "variáveis"
      }), " que você planeja usar em suas funções."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este arquivo também gerencia o roteamento de endpoints. Você especifica os caminhos dos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/features/serverless-functions/reference#endpoints",
        children: "endpoints"
      }), " que você deseja mapear aos seus arquivos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#function-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "function.js"
        })
      }), ". Para ver um exemplo de como seu arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " deve se parecer, consulte o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/features/serverless-functions/reference#serverless-json",
        children: "guia de referência das funções sem servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Function.js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A função sem servidor pode ser ter qualquer nome, desde que seja um ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/features/serverless-functions/reference#function-file",
        children: ["arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".js"
        })]
      }), ". Para que a sua função sem servidor funcione, ela deve ser mapeada para um endpoint definido no ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/features/serverless-functions/reference#serverless-json",
        children: ["arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json."
        })]
      }), " Para fins de resolução de problemas, é recomendado nomear o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".js"
      }), " de forma similar ao nome do endpoint no seu arquivo de configuração ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Segredos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao autenticar uma chamada feita por uma função sem servidor, você deve usar segredos para armazenar chaves de API, tokens de acesso a aplicativos privados e outras informações de autenticação para segurança. Isso permitirá a autenticação sem expor sua chave ou token de acesso."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar e gerenciar segredos, você pode usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cli#serverless-function-commands",
        children: "os comandos CLI do HubSpot"
      }), ", como:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add"
        }), " para criar um novo segredo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets list"
        }), " para visualizar seus segredos atualmente disponíveis por nome."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets update"
        }), " para atualizar um segredo existente."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma vez adicionados por meio da CLI, eles podem ser disponibilizados para as funções, incluindo uma matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), " contendo o nome do segredo. Isso permite armazenar seu código de função no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/guides/github-integration",
        children: "controle de versão"
      }), " e usar segredos sem expô-los. No entanto, você ", (0, _jsxRuntime.jsx)("u", {
        children: "nunca"
      }), " deve retornar o valor do seu segredo por meio do registro do console ou como uma resposta, pois isso irá expor o segredo nos registos ou nas páginas de front-end que chamam a função sem servidor."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " devido ao armazenamento em cache, pode levar cerca de um minuto para ver os valores secretos atualizados. Se você acabou de atualizar um segredo, mas ainda está vendo o valor antigo, verifique novamente após cerca de um minuto."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visualizar logs de funções sem servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ajudar a resolver problemas das suas funções sem servidor, a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cms-cli",
        children: "CLI"
      }), " tem o ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cms-cli#logs",
        children: ["comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        })]
      }), " que permite ver os logs das suas funções. Além das respostas individuais de invocação de funções, hora de execução e tempo de execução, qualquer instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), " também aparecerá nos logs de funções. Não inclua segredos como chaves de API no ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}