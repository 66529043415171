"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816705807;
const slug = exports.slug = 'guides/apps/authentication/intro-to-auth';
const title = exports.title = "API de HubSpot | Méthodes d'authentification dans HubSpot";
const name = exports.name = 'EMEA (FR) vNext Docs DP - Authentication methods on HubSpot';
const metaDescription = exports.metaDescription = "Les API de HubSpot permettent deux moyens d'authentification : OAuth et les clés d'API. Si la plupart des points de terminaison prennent en charge les deux méthodes, la méthode via OAuth est généralement recommandée.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "oauth",
  "label": "OAuth",
  "parent": null
}, {
  "depth": 0,
  "id": "jetons-d-acc%C3%A8s-d-application-priv%C3%A9e",
  "label": "Jetons d'accès d'application privée",
  "parent": null
}, {
  "depth": 2,
  "id": "documents-associ%C3%A9s",
  "label": "Documents associés",
  "parent": "jetons-d-acc%C3%A8s-d-application-priv%C3%A9e"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      strong: "strong",
      a: "a",
      h1: "h1",
      h2: "h2",
      pre: "pre",
      code: "code",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Depuis le 30 novembre 2022, les clés d'API HubSpot sont obsolètes et ne sont plus prises en charge. L'utilisation continue des clés d'API HubSpot constitue un risque pour la sécurité de votre compte et de vos données. Pendant cette phase, HubSpot peut désactiver votre clé à tout moment."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Veillez plutôt à vous authentifier à l'aide d'un jeton d'accès d'application privée ou d'OAuth. Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "cette modification"
        }), " et sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migration d'une intégration de clé d'API"
        }), " pour utiliser une application privée à la place."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Méthodes d'authentification dans HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il existe deux façons d'authentifier les appels vers les API de HubSpot : ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " et les jetons d'accès d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "application privée"
      }), ". Découvrez ci-dessous chaque méthode, y compris la façon de l'inclure dans votre code d'autorisation."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous utilisiez auparavant une clé d'API pour vous authentifier, découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
        children: "migrer vers un jeton d'accès d'application privée"
      }), " à la place."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Les intégrations conçues pour plusieurs clients ou un listing sur le marketplace des applications doivent être développées à l'aide du protocole OAuth de HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour faire une demande en utilisant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth"
      }), ", incluez le jeton d'accès OAuth dans l'en-tête d'autorisation :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer C4d***sVq\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Jetons d'accès d'application privée"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "De la même manière qu'avec OAuth, pour effectuer une demande à l'aide d'un jeton d'accès d'application privée, incluez le jeton dans l'en-tête d'autorisation :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer ***-***-*********-****-****-****-************\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documents associés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "Travailler avec OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "Guide de démarrage rapide pour OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "Applications privées"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}