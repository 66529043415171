"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937480;
const slug = exports.slug = 'guides/api/crm/owners';
const title = exports.title = 'CRM API | 所有者';
const name = exports.name = 'vNext Docs DP - 所有者';
const metaDescription = exports.metaDescription = 'HubSpotでは所有者を使用して特定のユーザーにCRMオブジェクトを割り当てます。これらのAPIエンドポイントはアカウント上で利用可能な所有者のリストを取得するために使用します。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%89%80%E6%9C%89%E8%80%85%E3%81%AE%E3%83%AA%E3%82%B9%E3%83%88%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "所有者のリストを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%80%8B%E3%80%85%E3%81%AE%E6%89%80%E6%9C%89%E8%80%85%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E6%83%85%E5%A0%B1%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "個々の所有者に関する情報を取得する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "所有者"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot所有者は特定のユーザーをレコード、アクティビティー、またはマーケティングタスクに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: "割り当て"
      }), "ます。所有者をコンテンツのパーソナライズトークンに使用できます。新しいユーザーが追加されたとき、または既存の所有者が", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/salesforce/how-will-a-salesforce-owner-will-be-recognized-by-hubspot",
        children: "Salesforce"
      }), "から同期されたときに、所有者がHubSpotで自動的に作成および更新されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "所有者APIエンドポイントは読み取り専用ですから、これらを使用して所有者識別情報（所有者IDなど）を取得できます。その後、この識別情報を使用し、連携またはプロパティー変更API呼び出しを介して、所有権をHubSpotのCRMレコードに割り当てることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "所有者のリストを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アカウントの現在の所有者を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners"
      }), "に送信します。レスポンスでは、各ユーザーの名前、Eメール、ID値、作成／更新日付、および該当する場合はチーム情報が返されます。返される次の2つのID値は、それぞれ異なる目的で使用されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), "：所有者のID。この値は、特定の所有者に関する情報を取得するとき、および所有者をレコードまたはアクティビティーに割り当てるときに使用されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), "：ユーザーのID。この値を使用して", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "設定API"
        }), "でユーザーを指定できます。ただし所有権の割り当てにこれを使用するとエラーが発生します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レスポンスは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners\n{\n  \"results\": [\n    {\n      \"id\": \"41629779\",\n      \"email\": \"email@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"HubSpot\",\n      \"lastName\": \"Test Owner\",\n      \"userId\": 9586504,\n      \"userIdIncludingInactive\": 9586504,\n      \"createdAt\": \"2019-12-25T13:01:35.228Z\",\n      \"updatedAt\": \"2023-08-22T13:40:26.790Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"60158084\",\n      \"email\": \"email@gmail.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Test\",\n      \"lastName\": \"Email\",\n      \"userId\": 9274996,\n      \"userIdIncludingInactive\": 9274996,\n      \"createdAt\": \"2021-02-10T17:59:04.891Z\",\n      \"updatedAt\": \"2023-02-09T17:41:52.767Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"81538190\",\n      \"email\": \"salesmanager@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Sales\",\n      \"lastName\": \"Manager Example\",\n      \"userId\": 3892666,\n      \"userIdIncludingInactive\": 3892666,\n      \"createdAt\": \"2021-05-27T16:55:57.242Z\",\n      \"updatedAt\": \"2022-08-02T18:34:35.039Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アーカイブされた所有者のリストを取得して、非アクティブにされたユーザーを確認することもできます。それには、値を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "に設定した", (0, _jsxRuntime.jsx)(_components.code, {
        children: "archived"
      }), "パラメーターを追加します。アーカイブされたユーザーにも", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "値はありますが、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userId"
      }), "値は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), "になります。この場合、ユーザーIDは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userIdIncludingInactive"
      }), "フィールドに格納されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例："
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners/?archived=true\n{\n  \"results\": [\n    {\n      \"id\": \"42103462\",\n      \"email\": \"useremail@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"\",\n      \"lastName\": \"\",\n      \"userId\": null,\n      \"userIdIncludingInactive\": 9685555,\n      \"createdAt\": \"2020-01-09T20:28:50.080Z\",\n      \"updatedAt\": \"2020-01-09T20:28:50.080Z\",\n      \"archived\": true\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "個々の所有者に関する情報を取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定の所有者の情報を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners/{ownerId}"
      }), "に送信します。詳細を取得する対象の所有者を指定するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "値を使用する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**レスポンスに含まれる", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), "値は、Ownerオブジェクト自体の更新に基づいて変更されます。Userオブジェクトに変更が加えられても更新されません。例えば、ユーザーの権限を変更しても", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), "値は", (0, _jsxRuntime.jsx)("u", {
          children: "更新されません"
        }), "。"]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}