"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021629;
const slug = exports.slug = 'guides/apps/api-usage/usage-details';
const title = exports.title = 'HubSpot APIs | Usage guidelines';
const name = exports.name = 'API Usage guidelines';
const metaDescription = exports.metaDescription = "HubSpot's API usage guidelines cover our Acceptable Use Policy, API Terms, rate limits, authentication, and security.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "authentication-and-security",
  "label": "Authentication and security",
  "parent": null
}, {
  "depth": 0,
  "id": "checking-api-usage",
  "label": "Checking API usage",
  "parent": null
}, {
  "depth": 1,
  "id": "private-apps",
  "label": "Private apps",
  "parent": "checking-api-usage"
}, {
  "depth": 1,
  "id": "public-apps-using-oauth",
  "label": "Public apps using OAuth",
  "parent": "checking-api-usage"
}, {
  "depth": 0,
  "id": "rate-limits",
  "label": "Rate Limits",
  "parent": null
}, {
  "depth": 2,
  "id": "public-apps",
  "label": "Public apps",
  "parent": "rate-limits"
}, {
  "depth": 2,
  "id": "private-apps",
  "label": "Private apps",
  "parent": "rate-limits"
}, {
  "depth": 0,
  "id": "other-limits",
  "label": "Other limits",
  "parent": null
}, {
  "depth": 0,
  "id": "request-limits",
  "label": "Request limits",
  "parent": null
}, {
  "depth": 0,
  "id": "service-limits",
  "label": "Service Limits",
  "parent": null
}, {
  "depth": 0,
  "id": "error-responses",
  "label": "Error Responses",
  "parent": null
}, {
  "depth": 1,
  "id": "use-batch-apis-and-cache-results-when-possible",
  "label": "Use batch APIs and cache results when possible",
  "parent": "error-responses"
}, {
  "depth": 1,
  "id": "use-webhooks-to-get-updated-data-from-hubspot",
  "label": "Use webhooks to get updated data from HubSpot",
  "parent": "error-responses"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      ul: "ul",
      li: "li",
      img: "img",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API usage guidelines"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot closely monitors usage of our public APIs to ensure a quality experience for every user. All app and integration developers must comply with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "HubSpot Acceptable Use Policy"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/developer-terms",
        children: "API Terms"
      }), ". While HubSpot reserves the right to change or deprecate the APIs over time, updates will always be provided in advance through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Developer Changelog"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authentication and security"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For optimal security, all apps must use HubSpot’s ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth protocol"
      }), " directly, or use your app's access token if you're building a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private app"
      }), ". Apps are responsible for storing time-to-live (TTL) data and refreshing user access tokens in accordance with this protocol. When an access token is generated, it will include an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " parameter indicating how long it can be used to make API calls before refreshing. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Unauthorized (401)"
      }), " requests are not a valid indicator that a new access token must be retrieved."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Checking API usage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Private apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "To view API usage for a private app:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the private app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the app details page, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logs"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review the API calls listed in the table. You can also use the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "search bar"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "filters"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "date pickers"
        }), " to further refine the displayed API calls."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/Screenshot%202023-08-31%20at%205.28.03%20PM.png",
        alt: "Screenshot 2023-08-31 at 5.28.03 PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#view-api-call-logs",
          children: "checking API usage in private apps"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Public apps using OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To view API usage for a public app using OAuth:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Monitoring"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tabs"
        }), " to view different types of requests being made to or from the app. While viewing these logs, you can click an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "individual request"
        }), " to view more information."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/6-request_details.png",
        alt: "6-request_details"
      }), "Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#monitor-app-behavior",
        children: "monitoring API usage for public apps"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rate Limits"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Public apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For OAuth apps, each HubSpot account that installs your app is limited to 110 requests every 10 seconds. This excludes the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search",
        children: "Search API"
      }), ", as noted in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-limits",
        children: "Other Limits"
      }), " section below. Limits related to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog#Addons",
        children: "API limit increase"
      }), " don't apply."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Private apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The number of calls your private app can make is based on your account subscription and whether you've purchased the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog#Addons",
        children: "API limit increase"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Product Tier"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Per 10 Seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Per Day"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Private Apps"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["(Any Hub) ", (0, _jsxRuntime.jsx)("br", {}), " Free and Starter"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100 / private app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250,000 / account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["(Any Hub) ", (0, _jsxRuntime.jsx)("br", {}), " Professional"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "190 / private app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "625,000 / account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["(Any Hub) ", (0, _jsxRuntime.jsx)("br", {}), " Enterprise"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "190 / private app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1,000,000 / account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Private Apps with ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://legal.hubspot.com/hubspot-product-and-services-catalog#Addons",
              children: "API Limit Increase"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["(Any Hub) ", (0, _jsxRuntime.jsx)("br", {}), " Free, Starter, Professional, and Enterprise"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250 / private app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1,000,000 / account on top of your base subscription, for each limit increase. You can purchase a maximum of two API limit increases."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " purchasing an API Limit Increase will increase the maximum number of requests you can make to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4",
          children: "associations API"
        }), " to 1,000,000 daily requests and 200 requests per 10 seconds, but these limits ", (0, _jsxRuntime.jsx)("u", {
          children: "cannot"
        }), " be increased further with an additional API Limit Increase purchase."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Other limits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You can create up to 100 apps per developer account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You can create up to 20 private apps per HubSpot account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You can create up to 1,000 webhook subscriptions per app."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You can create up to 25 CRM extension settings per app."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You can create up to 750 timeline event types per app."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You can create up to 500 properties per timeline event type."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Request limits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Keep the following general limits in mind when making requests to HubSpot's API endpoints. Some APIs may also have their own, more specific limits which will be listed on the API reference page under ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Limits"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/single-send-limits-example.png",
        alt: "single-send-limits-example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search",
          children: "search API"
        }), " endpoints are rate limited to ", (0, _jsxRuntime.jsx)("u", {
          children: "five"
        }), " requests per second per authentication token, and 200 records per page request. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search#limitations",
          children: "search limits"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["API requests that are exempt from daily or secondary limits will ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " be logged in HubSpot. If you want to store these exempted requests, you'll need to log these requests externally."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Batch requests to CRM object endpoints are limited to 100 records per request. For example, you can't batch read more than 100 contacts per request."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Service Limits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about service limits and pricing ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/pricing/service-limits",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Error Responses"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any app or integration exceeding its rate limits will receive a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " error response for all subsequent API calls. Requests resulting in an error response shouldn’t exceed 5% of your total daily requests. If you plan on listing your app in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "HubSpot App Marketplace"
      }), ", it must stay under this 5% limit to be certified."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " response will have the following format:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example\n{\n  \"status\": \"error\",\n  \"message\": \"You have reached your daily limit.\",\n  \"errorType\": \"RATE_LIMIT\",\n  \"correlationId\": \"c033cdaa-2c40-4a64-ae48-b4cec88dad24\",\n  \"policyName\": \"DAILY\",\n  \"requestId\": \"3d3e35b7-0dae-4b9f-a6e3-9c230cbcf8dd\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "message"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "policyName"
      }), " will indicate which limit you hit (either daily or secondly)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "daily"
      }), " limit resets at midnight based on your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-set-your-time-zone-in-hubspot",
        children: "time zone setting"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following table details the rate limit headers included in the response of each API request to HubSpot, subject to the exceptions listed below the table."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Header"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Daily"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The number of API requests that are allowed per day. Note that this header is not included in the response to API requests authorized using ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth",
              children: "OAuth"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Daily-Remaining"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The number of API requests still allowed for the current day. Note that this header is not included in the response to API requests authorized using ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth",
              children: "OAuth"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The window of time that the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Max"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Remaining"
            }), " headers apply to.For example, a value of 10000 would be a window of 10 seconds."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Max"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The number of requests allowed in the window specified in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), ".For example, if this header had a value of 100, and the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), " header was 10000, the enforced limit would be 100 requests per 10 seconds."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Remaining"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The number of API requests still allowed for the window specified in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly"
          }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly-Remaining"
          }), " headers are still included and will still have accurate data, but the limit referenced by these headers is no longer enforced and these two headers should be considered deprecated."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Responses from the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/search",
            children: "search API endpoints"
          }), " will ", (0, _jsxRuntime.jsx)("u", {
            children: "not"
          }), " include any of the rate limit headers listed above."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also check the number of calls used during the current day using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "this endpoint"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're running into the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TEN_SECONDLY_ROLLING"
      }), " limit, you should throttle the requests that your app is making to stay under that limit. In addition to throttling the requests, or if you're running into the daily limit, check out the suggestions below."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you find that you're still hitting the call limits after looking through these suggestions, please post on HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://integrate.hubspot.com/",
        children: "developer forums"
      }), ". You should include as many details as possible about the APIs you're using, how you're using them, and which limit you're hitting."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Use batch APIs and cache results when possible"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If your site or app uses data from HubSpot on each page load, that data should be cached and loaded from that cache instead of being requested from the HubSpot APIs each time. If you're making repeated calls to get settings from your account for a batch job (such as getting your object properties, owners, or settings for a form), those settings should also be cached when possible."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Use webhooks to get updated data from HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a HubSpot Marketing Enterprise subscription, you can use webhook actions in workflows to have data for contact records sent to your system. Webhooks can be triggered as an action in any workflow, so you can use any workflow ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows-user-guide-v2/how-to-choose-the-workflow-type-and-the-starting-condition-best-suited-for-your-goal",
        children: "starting conditions"
      }), " as the criteria to have contact data sent to your system. More details about using webhooks can be found ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "here"
      }), " and example webhooks data is ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/create-manage-workflows/v3#create-a-workflow",
        children: "here"
      }), ". Webhook calls made via workflows do not count towards the API rate limit."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}