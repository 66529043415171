"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358694;
const slug = exports.slug = 'guides/apps/marketplace/listing-your-app';
const title = exports.title = 'アプリマーケットプレイス | アプリの掲載';
const name = exports.name = 'vNext Docs DP - アプリの掲載';
const metaDescription = exports.metaDescription = 'HubSpotのアプリマーケットプレイスへの掲載のためにアプリを提出する流れをご紹介します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E6%8E%B2%E8%BC%89%E6%83%85%E5%A0%B1%E3%81%AE%E4%BD%9C%E6%88%90%E3%81%A8%E6%8F%90%E5%87%BA",
  "label": "アプリ掲載情報の作成と提出",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%8E%B2%E8%BC%89%E6%83%85%E5%A0%B1",
  "label": "掲載情報",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E8%A9%B3%E7%B4%B0",
  "label": "アプリの詳細",
  "parent": null
}, {
  "depth": 0,
  "id": "%E4%BE%A1%E6%A0%BC%E8%A8%AD%E5%AE%9A",
  "label": "価格設定",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E6%A9%9F%E8%83%BD",
  "label": "アプリの機能",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E6%83%85%E5%A0%B1",
  "label": "サポート情報",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%83%85%E5%A0%B1%E3%82%92%E5%AF%A9%E6%9F%BB%E3%81%97%E3%81%A6%E6%8E%B2%E8%BC%89%E6%83%85%E5%A0%B1%E3%82%92%E9%80%81%E4%BF%A1%E3%81%99%E3%82%8B",
  "label": "情報を審査して掲載情報を送信する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E3%82%A2%E3%83%97%E3%83%AA%E6%8E%B2%E8%BC%89%E6%83%85%E5%A0%B1%E3%81%AE%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%A9%E3%82%A4%E3%82%BA%E7%89%88%E3%81%AE%E4%BD%9C%E6%88%90%E3%81%A8%E6%9B%B4%E6%96%B0",
  "label": "既存のアプリ掲載情報のローカライズ版の作成と更新",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%85%AC%E9%96%8B%E4%B8%AD%E3%81%AE%E3%82%A2%E3%83%97%E3%83%AA%E6%8E%B2%E8%BC%89%E6%83%85%E5%A0%B1%E3%81%AE%E7%B7%A8%E9%9B%86",
  "label": "公開中のアプリ掲載情報の編集",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%85%AC%E9%96%8B%E4%B8%AD%E3%81%AE%E3%82%A2%E3%83%97%E3%83%AA%E6%8E%B2%E8%BC%89%E6%83%85%E5%A0%B1%E3%81%AE%E5%85%AC%E9%96%8B%E5%81%9C%E6%AD%A2",
  "label": "公開中のアプリ掲載情報の公開停止",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "アプリの掲載"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "マーケットプレイスへの掲載に必要な条件"
      }), "を満たすアプリを開発者アカウントで作成した後、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "HubSpotアプリマーケットプレイス"
      }), "での公開に向けて掲載情報を提出することができます。ご提出内容をHubSpotエコシステムの品質チームが審査し、承認または却下についてEメールでのフォローアップを行います。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**アプリ掲載情報を更新したり提出したりするには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "スーパー管理者"
        }), "の権限が必要です。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この記事では、以下の内容について説明します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "アプリ掲載情報の作成と提出"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-update-a-localized-listing-for-an-existing-app-listing",
          children: "既存のアプリ掲載情報のローカライズ版の作成と更新"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "アプリ パートナー プログラムの窓口を追加"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#edit-localized-listing",
          children: "公開中のアプリ掲載情報の編集"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unpublish-a-live-app-listing",
          children: "公開中のアプリ掲載情報の公開停止"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリ掲載情報の作成と提出"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**アプリ掲載情報を提出する前に、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "アプリマーケットプレイスへの掲載に必要な条件"
        }), "に関するページを確認して、掲載情報の入力方法を理解してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "アプリ開発者アカウント"
        }), "で［アプリマーケットプレイス］>［掲載情報］に移動します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上の［掲載情報を作成］をクリックします。このボタンがグレー表示になっている場合、全ての既存のアプリについて掲載情報が既に作成されています。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["掲載情報を作成する", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "アプリ"
        }), "を選択し、［次へ］をクリックします。アプリマーケットプレイスに既に掲載されているアプリはここに表示されません。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次の画面で、［アプリが対応している言語を選択］ドロップダウンメニューをクリックし、アプリのソフトウェアを提供する", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "言語"
        }), "を選択します。アプリマーケットプレイスは、英語、スペイン語、フランス語、ドイツ語、ポルトガル語、日本語、オランダ語、イタリア語の8つの言語で利用できます。貴社がアプリを提供している言語であれば、アプリの掲載情報を作成することができます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［[アプリ名]のプライマリー掲載情報言語を選択］ドロップダウンメニューをクリックし、アプリマーケットプレイスを閲覧するユーザーに表示される", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "既定の言語"
        }), "を選択します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［次へ］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリ掲載情報ウィザードには、情報入力用のタブが5つあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "掲載情報"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［掲載情報］タブ："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["［アプリ情報］には、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "公開アプリ名"
          }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "会社名"
          }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "タグライン（キャッチコピー）"
          }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "インストールボタンのURL"
          }), "を追加します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［アプリアイコン］で、アプリのアイコンを800px × 800pxのサイズでアップロードします。これはアプリマーケットプレイス、および接続されたユーザーアカウントに表示されます。アイコンについてのベストプラクティス："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**必要事項：**JPG、JPEG、PNGファイルを使用し、スペース全体（800px×800px）を活用 - （画像が少なくとも2つの縁に接するように）するとともに、ジャギーのない高解像度の画像を使用します。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**禁止事項：**アイコンに文言を含める、ワードマークを使用する、またはアイコンの周囲に余白を残すことは禁止されています。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/dev-doc-logo.png",
        alt: "dev-doc-logo"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［アプリのカテゴリー分け］では、アプリの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "カテゴリー"
        }), "と、アプリマーケットプレイスでのアプリ検索に使用できる", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "検索語"
        }), "を選択します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリの詳細"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［アプリの詳細］タブで、次の操作を行います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［デモ動画］には、アプリの動作を示す", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "動画"
        }), "をアップロードします。ベストプラクティスとデモ動画の作成方法の例については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
          children: "アプリの優れたデモ動画の作成方法"
        }), "ページを参照してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［スクリーンショット］には、アプリの動作を示す", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "画像"
        }), "および", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "代替テキスト"
        }), "を追加します。最大8つの画像を追加できます。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［共有されるデータを追加］をクリックします。［共有データ］では、アプリとHubSpotの間のデータの流れをユーザーに明示します。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["右側のパネルで、アプリのどのオブジェクトがHubSpotのどのオブジェクトと同期されるかに加えて、同期の向きを選択します。［アプリの概要］では、貴社のアプリがユーザーの目的にどのように役立つかを簡単に", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "説明"
            }), "します。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "同期オブジェクトを追加するには、［別のオブジェクトを追加］をクリックします。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/shared-data-app-listing.png",
        alt: "shared-data-app-listing"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［アプリと連携するHubSpot機能］では、貴社のアプリが連携する", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubSpotのツールや機能を10件"
        }), "まで追加できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［ソフトウェアと連携するその他のツール］では、貴社のソフトウェアが連携する", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "他のツールまたはアプリを6件"
        }), "まで選択できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［アプリが対応している言語を選択］では、貴社のアプリで利用可能な全ての", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "言語"
        }), "を選択します。この言語に対してのみ、追加のアプリマーケットプレイス掲載情報を作成できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "価格設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［価格設定］タブで、次の操作を行います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["上部のセクションで、アプリに掲載する", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "通貨"
          }), "を選択します。100種類を超える通貨の中から選択可能です。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["また、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "価格モデル"
          }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "プラン名"
          }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "タグライン（キャッチコピー）"
          }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "価格の詳細"
          }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "機能リスト"
          }), "を追加することで、アプリの", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "料金プラン"
          }), "を設定することもできます。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["選択した価格モデルによっては、支払い頻度、一括払い、月々の価格などの情報を追加する必要があります。各料金モデルの詳細については、", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "情報アイコン"
              }), "の内容をご確認ください。"]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/pricing-model-app-listing.png",
                alt: "pricing-model-app-listing"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "料金プランは5件まで追加可能です。料金プランを追加するには、［別のプランを追加］をクリックします。"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["［このソフトウェアの価格設定プランへのリンク］では、料金プランの詳細情報をユーザーが参照できる", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), "を入力します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["［パートナー価格プラン］には、パートナーやコンサルティングサービスの価格をユーザーが参照できる", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), "を入力します。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリの機能"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［アプリの機能］タブで、機能を追加し、お客様に対してその使用法を紹介します。アプリに作成できるアプリの機能数に制限はありません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［機能を追加］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［機能の詳細］ページで、次の操作を行います。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "機能名"
            }), "を入力します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["この機能で顧客に適用する必要のある", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "スコープグループ"
            }), "を全て選択します。スコープグループは、顧客のHubSpotアカウントでアプリの機能を利用できるかどうかを決定するために使用されます。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "説明"
            }), "を入力します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "画像"
            }), "を選択します。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "機能を表示する場所として、［アプリのマーケットプレイスの掲載情報］または［機能の発見カードとして］のいずれかを選択します。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリのマーケットプレイスの掲載情報には、最大5個のアプリの機能を表示することができ、発見カードとして表示する場合は最大10個のアプリの機能を表示することができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "機能の発見カードとして機能を表示することを選択した場合は、次のようにします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["プライマリーボタンを選択します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**機能にリンク：**ボタンをリンクするHubSpot機能を選択します。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "カスタムを作成："
            }), "［ボタンテキスト］および［ボタンURL］を入力します。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "プライマリーボタンがありません"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["顧客の導入支援を行うためのハウツーガイドを選択します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "ガイドをゼロから作成：タイトル"
            }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "説明"
            }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "画像または動画"
            }), "を入力します。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**ガイドへの外部リンク：ガイドURLを入力します。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**動画のみのガイド：動画をアップロードします。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "別のガイドを追加するには、［別のセクションを追加］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/feature-discovery-card.png",
        alt: "feature-discovery-card"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サポート情報"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［サポート情報］タブで、次の操作を行います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［コンタクト情報］には、アプリの利用ユーザーからの問い合わせ先として、サポートへの連絡方法を追加します。貴社の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "カスタマーサポートのEメール"
        }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "会社のウェブサイト"
        }), "、サポート対象の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "言語"
        }), "を追加します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［サポートリソース］には、貴社のアプリの設定用文書への", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "リンク"
        }), "を記入します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［サービス利用規約とプライバシーポリシー］には、貴社のプライバシー規約などに関する文書への", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "リンク"
        }), "を追加します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "情報を審査して掲載情報を送信する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［審査情報］タブで、次の操作を行います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［アプリのレビュー手順］に、HubSpotエコシステムの品質チームが貴社のアプリのテストと審査を行う際に必要な", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "アプリの審査手順"
        }), "を入力します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［アプリ パートナー プログラムの連絡先］には、アプリの掲載に関する連絡先、およびアプリの開発者の連絡先を記入します。担当者の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "役割"
        }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "氏名"
        }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "メールアドレス"
        }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "勤務国"
        }), "を含める必要があります。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "連絡先を追加するには、［別の連絡先を追加］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["掲載情報を審査に提出するには、右上の［審査に提出］をクリックします。［審査に提出］", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ボタンがグレー表示になっている場合は、全ての必須フィールドに入力したこと、またスーパー管理者"
      }), "権限があることを確かめてください。必須項目の記入漏れがある場合、不足項目の件数がタブ見出しに表示されます。各タブをクリックして不足している情報を入力してから、［審査情報］タブに戻り、［審査に提出］をクリックします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-features.png",
        alt: "app-features"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "既存のアプリ掲載情報のローカライズ版の作成と更新"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "他の言語の掲載情報を作成するには、既存のアプリ掲載情報でプライマリー言語を設定し、プライマリー掲載情報をHubSpotマーケットプレイスで公開済みにしている必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "HubSpotアプリ開発者アカウントで、［アプリマーケットプレイス］>［掲載情報］をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "マーケットプレイスに掲載済みのアプリがある場合は、掲載済みのアプリの上に黄色いバナーが表示され、掲載のプライマリー言語を設定することを求められます。［今すぐ設定］をクリックします。新しい言語で掲載情報を作成する前に、アプリ掲載情報のプライマリー言語を設定する必要があります。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["ダイアログボックスで、［アプリが対応している言語を選択］ドロップダウンメニューをクリックし、アプリのソフトウェアを提供する", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "言語"
          }), "を選択します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["［[アプリ名]のプライマリー掲載情報言語を選択］ドロップダウンメニューをクリックし、アプリマーケットプレイスを閲覧するユーザーに表示される", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "既定の言語"
          }), "を選択します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［保存］をクリックします。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プライマリー言語を設定すると、ローカライズされた新しい掲載情報を追加できるようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "アプリ掲載情報"
        }), "にカーソルを合せ、［その他］>［別の言語で掲載情報を作成］をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［この掲載情報の言語］ドロップダウンメニューをクリックし、掲載情報を作成する", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "言語"
        }), "を選択します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［作成］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "流れに従って、選択した言語の掲載情報を作成し、提出します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "製品のスクリーンショットやデモ動画を含め、ローカライズされた掲載情報の全ての部分は同じ言語でなければなりません。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "プライマリー掲載情報を更新しても、ローカライズされた掲載情報が自動的に更新されることはありません。それぞれ個別に更新する必要があります。"
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "公開中のアプリ掲載情報の編集"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリマーケットプレイスにアプリが公開された後で掲載情報を編集するには、次の作業を行います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "開発者アカウントで［アプリマーケットプレイス］＞［掲載情報］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "編集する掲載情報にカーソルを合せ、［その他］＞［編集］の順にクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "変更後、［審査に提出］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "公開中のアプリ掲載情報の公開停止"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "開発者アカウントで［アプリマーケットプレイス］＞［掲載情報］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "公開を停止する掲載情報にカーソルを合せ、［その他］>［掲載情報の公開を終了］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ダイアログボックスにアプリを公開を停止する理由を入力してから［公開終了の依頼を送信］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "非公開リクエストは、送信後10営業日以内にHubSpotマーケットプレイスチームによって処理されます。"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}