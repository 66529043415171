"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 168175145776;
const slug = exports.slug = 'reference/ui-components/standard-components/icon';
const title = exports.title = 'Icon';
const name = exports.name = 'Icon | UI components (BETA)';
const metaDescription = exports.metaDescription = 'Learn about the Icon component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "spacing",
  "label": "Spacing",
  "parent": null
}, {
  "depth": 0,
  "id": "available-icons",
  "label": "Available icons",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CardsRow,
      Card
    } = _components;
  if (!Card) _missingMdxReference("Card", true);
  if (!CardsRow) _missingMdxReference("CardsRow", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Icon | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Icon"
      }), " component to render a visual icon within other components. It can generally be used inside most components, excluding ones that don't support child components (e.g., the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Input"
      }), " component does not support icons)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icon-examples.png",
        alt: "uie-components-icon-examples"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Alert, Button, Flex, Icon, Text } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <Flex direction=\"column\" gap=\"small\">\n      <Alert title=\"AlertTitle\">\n        <Icon name=\"download\" /> Icon inside an Alert\n      </Alert>\n      <Button>\n        <Icon name=\"download\" /> Icon inside a button\n      </Button>\n      <Text>\n        <Icon name=\"download\" /> Icon with text\n      </Text>\n    </Flex>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " Required"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sets the icon to display. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#available-icons",
              children: "all available icons"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "size"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'small'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'medium'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'large'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "By default, the size of the icon is set automatically based on the parent component. If you need to override the default size, you can specify one to use instead."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenReaderText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sets the text that screen readers will read for the icon."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Spacing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, spacing is not added around the icon. However, there may be times when you want more space between the icon and neighboring text. You can do this by manually adding a space to the text string, or by using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&nbsp;"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{\" \"}"
      }), " as shown below.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icon-example-spacing.png",
        alt: "uie-components-icon-example-spacing"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Alert, Flex, Icon, Tile, Text } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <Flex direction=\"column\" gap=\"small\">\n      <Alert title=\"AlertTitle\">\n        <Icon name=\"warning\" /> Icon inside an alert\n      </Alert>\n      <Tile>\n        <Icon name=\"robot\" /> Icon inside a tile\n      </Tile>\n      <Text>\n        <Icon name=\"faceHappy\" /> Icon with text\n      </Text>\n    </Flex>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Available icons"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below are the currently available icons and their ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " values."]
    }), "\n", (0, _jsxRuntime.jsxs)(CardsRow, {
      columnSize: 140,
      children: [(0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons-add",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons-add.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "add"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_33",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_33.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "attach"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_bulb",
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/ui-extension-components-icons-bulb-large.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "bulb"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_32",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_32.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "clock"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_1",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_1.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "comment"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_31",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_31.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "contact"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_copy",
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/ui-extension-components-icons-copy-large.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "copy"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_30",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_30.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "dataSync"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_29",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_29.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "date"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_28",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_28.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "delete"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_27",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_27.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "downCarat"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-component-icon-in-button-download",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-component-icon-in-button-download.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "download"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_25",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_25.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "edit"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_24",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_24.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "email"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_23",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_23.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "exclamation"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_22",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_22.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "faceHappy"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "ui-extension-icon-faceneutral",
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-icon-faceneutral.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "faceNeutral"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_21",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_21.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "faceSad"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_20",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_20.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "file"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_home",
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/ui-extension-components-icons-home-large.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "home"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_19",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_19.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "imageGallery"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_18",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_18.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "left"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_17",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_17.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "location"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            style: {
              width: '100px'
            },
            alt: "uie-component-icon-in-button-notification2",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-component-icon-in-button-notification2.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "notification"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            style: {
              width: '100px'
            },
            alt: "uie-component-icon-in-button-question",
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/ui-extension-components-icons-question-large.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "question"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_15",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_15.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "refresh"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_14",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_14.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "remove"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_13",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_13.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "reports"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_12",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_12.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "right"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_11",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_11.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "robot"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_10",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_10.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "save"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_9",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_9.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "search"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_settings",
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/ui-extension-components-icons-settings-large.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "settings"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_8",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_8.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "shoppingCart"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_7",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_7.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "star"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_6",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_6.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "success"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_5",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_5.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "upCarat"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_4",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_4.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "upload"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_3",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_3.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "video"
          })]
        })
      }), (0, _jsxRuntime.jsx)(Card, {
        children: (0, _jsxRuntime.jsxs)("div", {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)("img", {
            alt: "uie-components-icons_2",
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-icons_2.png"
          }), (0, _jsxRuntime.jsx)("code", {
            style: {
              width: 'fit-content'
            },
            children: "warning"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Always pair icons with text. If that is not possible, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "screenReaderText"
      }), " prop to convey the icon's meaning for users with screen readers."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/alert",
          children: "Alert"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/tag",
          children: "Tag"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/text",
          children: "Text"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}