'use es6';

export function opt(ofRule) {
  return {
    ofRule
  };
}
export function list(ofRule, separator) {
  return {
    ofRule,
    isList: true,
    separator
  };
}
export function butNot(rule, exclusions) {
  const ruleMatch = rule.match;
  rule.match = token => {
    let check = false;
    if (ruleMatch) {
      check = ruleMatch(token);
    }
    return check && exclusions.every(exclusion => exclusion.match && !exclusion.match(token));
  };
  return rule;
}
export function t(kind, style) {
  return {
    style,
    match: token => token.kind === kind
  };
}
export function p(value, style) {
  return {
    style: style || 'punctuation',
    match: token => token.kind === 'Punctuation' && token.value === value
  };
}