"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158160969562;
const slug = exports.slug = 'reference/ui-components/standard-components/form';
const title = exports.title = 'Form';
const name = exports.name = 'Form | UI components';
const metaDescription = exports.metaDescription = 'Learn about the Form component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      ol: "ol",
      li: "li",
      strong: "strong",
      em: "em",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Form | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Form"
      }), " component renders a form that can contain other subcomponents, such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/input",
        children: "Input"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/select",
        children: "Select"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/button",
        children: "Button"
      }), ". Use this component to enable users to submit data to HubSpot or an external system."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-guide-form-component.png",
        alt: "design-guide-form-component"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Label:"
        }), " the input label."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Text input:"
        }), " an ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/input",
          children: "Input"
        }), " component with a placeholder value of ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "First name"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Label:"
        }), " the select input label."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select input:"
        }), " a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/select",
          children: "Select"
        }), " component with value of ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Customer"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Button:"
        }), " a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button",
          children: "Button"
        }), " component to submit the form's information."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Form, Input, Button } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <Form\n      onSubmit={() => {\n        console.log('Form submitted!');\n      }}\n    >\n      <Input\n        label=\"First Name\"\n        name=\"first-name\"\n        tooltip=\"Please enter your first name\"\n        description=\"Please enter your first name\"\n        placeholder=\"First name\"\n      />\n      <Input\n        label=\"Last Name\"\n        name=\"last-name\"\n        tooltip=\"Please enter your last name\"\n        description=\"Please enter your last name\"\n        placeholder=\"Last name\"\n      />\n      <Button\n        onClick={() => {\n          console.log('Submit button clicked');\n        }}\n        variant=\"primary\"\n        type=\"submit\"\n      >\n        Submit\n      </Button>\n    </Form>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Prop"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onSubmit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Function"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The function that is called when the form is submitted. It will receive a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RemoteEvent"
            }), " as an argument and its return value will be ignored."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "autoComplete"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'on'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'off'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set this field to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'off'"
            }), " to prevent autocompletion software (e.g., browser, password managers) from auto-filling form fields. Based on the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete",
              children: "autocomplete HTML attribute"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A form to submit customer information to an external database."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A form to place a product order on behalf of a customer."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " include text inputs when a user should be able to submit any value."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " include select inputs when a user should only be able to select from a set of values."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " include descriptions and placeholder text to provide context to users."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " always position the submit button at the bottom of the form."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " include a form without a submit button."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button",
          children: "Button"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/date-input",
          children: "DateInput"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/input",
          children: "Input"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/multi-select",
          children: "MultiSelect"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/number-input",
          children: "NumberInput"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/select",
          children: "Select"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}