"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61303889553;
const slug = exports.slug = 'guides/api/crm/engagements/email';
const title = exports.title = 'エンゲージメント | Eメール';
const name = exports.name = 'エンゲージメント | Eメール';
const metaDescription = exports.metaDescription = 'EメールエンゲージメントAPIを使用して、CRMレコードのEメールを記録および管理できます。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "Eメールを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "プロパティー",
  "parent": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E8%AA%AD%E3%81%BF%E5%8F%96%E3%82%8A%E5%B0%82%E7%94%A8%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "読み取り専用プロパティー",
  "parent": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%83%98%E3%83%83%E3%83%80%E3%83%BC%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "Eメールヘッダーを設定する",
  "parent": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91",
  "label": "関連付け",
  "parent": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "Eメールを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "Eメールを更新する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AEe%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%AB%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "既存のEメールをレコードに関連付ける",
  "parent": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "関連付けを削除する",
  "parent": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%A7e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E3%83%94%E3%83%B3%E7%95%99%E3%82%81%E3%81%99%E3%82%8B",
  "label": "レコードでEメールをピン留めする",
  "parent": null
}, {
  "depth": 0,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "Eメールを削除する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Eメール"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["EメールエンゲージメントAPIを使用して、CRMレコードのEメールを記録および管理できます。Eメールアクティビティーは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "HubSpot"
      }), "内で、またはEメールAPIを使用してログを記録できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この記事では、APIを使用したEメールの基本的な管理方法を説明します。利用可能な全てのエンドポイントとそれぞれの要件を確認するには、記事の上部にある［エンドポイント］****タブをクリックしてください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eメールを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメールエンゲージメントを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエスト本文で、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), "オブジェクト内にEメールの詳細を追加します。また、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associations"
      }), "オブジェクトを追加して、新しいEメールを既存のレコード（コンタクト、会社など）に関連付けることもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "プロパティー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "propertiesオブジェクトには、次のフィールドを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| フィールド | 説明 |\n| --- | --- | --- |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_timestamp"
      }), " | 必須。このフィールドは、Eメールの作成時刻を記録し、レコードタイムラインでのそのEメールの表示位置を決定します。ミリ秒単位またはUTC形式のUnixタイムスタンプを使用できます。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot_owner_id"
      }), " | Eメールに関連付けられている", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "所有者のID"
      }), "。このフィールドにより、レコードタイムラインにEメール作成者として表示されるユーザーが決まります。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_direction"
      }), " | Eメールが送信された方向。指定できる値は次のとおりです。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "EMAIL"
      }), "：", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
        children: "BCCアドレス"
      }), "を使用してEメールをCRMから送信したか、CRMに送信して記録された場合。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "INCOMING_EMAIL"
      }), "：送信して記録されたEメールに返信するEメールの場合。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORWARDED_EMAIL"
      }), "：", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
        children: "CRMに転送"
      }), "したEメールの場合。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_html"
      }), " | CRMレコードから送信された場合のEメールの本文。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_status"
      }), " | Eメールの送信ステータス。指定可能な値は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCED"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FAILED"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SCHEDULED"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENDING"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "のいずれかです。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_subject"
      }), " | 記録されたEメールの件名行。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_text"
      }), " | Eメールの本文。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_attachment_ids"
      }), " | Eメールの添付ファイルのID。複数の添付ファイルIDはそれぞれセミコロンで区切られます。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), " | Eメールのヘッダー。このプロパティーの値は、特定の読み取り専用Eメールプロパティーに自動的に入力されます。詳しくは後述の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/email#set-email-headers",
        children: "Eメールヘッダーを設定する方法"
      }), " | をご確認ください。 |"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eメールエンゲージメントを一括で作成する方法については、この記事の上部にある****［エンドポイント］タブでご確認いただけます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "読み取り専用プロパティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["読み取り専用のEメールプロパティーもあり、HubSpotによって自動的に入力されます。以下の表のプロパティーは全て", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), "値から自動的に入力されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメール送信者のEメールアドレス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメール送信者の名。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメール送信者の姓。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメール受信者のEメールアドレス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメール受信者の名。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメール受信者の姓。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**Eメールヘッダーを取得する際に、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), "の両方に値があることに気が付かれると思います。多くの場合、これらは同じ値ですが、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), "は実際のEメールの送信元を示すため、値が異なることがあります。例えば、EメールエイリアスからEメールが送信される場合、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), "の値はユーザーの実際のEメールアドレスを表し、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), "の値はEメールエイリアスを表します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eメールヘッダーを設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ヘッダーによって自動的に読み取り専用プロパティーが入力されるため、Eメールヘッダーを手動で設定する必要がある場合も考えられます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), "値を設定するには、次のデータを含むJSONエスケープ文字列を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example data\n{\n  \"from\": {\n    \"email\": \"from@domain.com\",\n    \"firstName\": \"FromFirst\",\n    \"lastName\": \"FromLast\"\n  },\n  \"to\": [\n    {\n      \"email\": \"ToFirst ToLast<to@test.com>\",\n      \"firstName\": \"ToFirst\",\n      \"lastName\": \"ToLast\"\n    }\n  ],\n  \"cc\": [],\n  \"bcc\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、Eメールを作成するリクエストは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"47550177\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for youremail\",\n    \"hs_email_headers\": \"{\\\"from\\\":{\\\"email\\\":\\\"from@domain.com\\\",\\\"firstName\\\":\\\"FromFirst\\\",\\\"lastName\\\":\\\"FromLast\\\"},\\\"sender\\\":{\\\"email\\\":\\\"sender@domain.com\\\",\\\"firstName\\\":\\\"SenderFirst\\\",\\\"lastName\\\":\\\"SenderLast\\\"},\\\"to\\\":[{\\\"email\\\":\\\"ToFirst+ToLast<to@test.com>\\\",\\\"firstName\\\":\\\"ToFirst\\\",\\\"lastName\\\":\\\"ToLast\\\"}],\\\"cc\\\":[],\\\"bcc\\\":[]}\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付け"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eメールを作成して既存のレコードと関連付けるには、リクエストにassociationsオブジェクトを含めます。例えば、Eメールを作成して取引とコンタクトに関連付ける場合、リクエスト本文は次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 601\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 210\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 602\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 198\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "associationsオブジェクトには、次のものを含める必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eメールに関連付けるレコード。一意の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "値で指定します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eメールとレコードの間の関連付けのタイプ。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "および", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), "が含まれます。既定の関連付けタイプIDは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "このページ"
            }), "に記載されています。または、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "関連付けAPI"
            }), "を使用して、カスタム関連付けタイプ（ラベル）の値を取得することもできます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eメールを取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eメールを個別に、または一括で取得することができます。Eメールを一括で取得する方法については、この記事の上部にある［エンドポイント］****タブでご確認いただけます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["EメールIDを指定して個々のコールを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "に送信します。また、リクエストのURLには次のパラメーターを含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "返されるプロパティーのカンマ区切りリスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["関連付けられているIDが取得されるオブジェクトタイプのカンマ区切りリスト。存在しない関連付けを指定した場合、その関連付けはレスポンスで返されません。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "関連付けAPI"
            }), "をご参照ください。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのEメールのリストを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/emails"
      }), "に送信します。リクエストのURLには、次のパラメーターを含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページごとに表示する結果の最大数。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "返されるプロパティーのカンマ区切りリスト。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eメールを更新する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメールを個別に、または一括で更新することができます。EメールIDを指定して個別にEメールを更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエスト本文に、更新するEメールプロパティーを含めます。例えば、リクエスト本文は次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk tomorrow\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect!\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotは、読み取り専用プロパティーおよび存在しないプロパティーの値を無視します。プロパティー値をクリアするには、リクエストの本文でプロパティーの空の文字列を渡します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eメールを一括で更新する方法については、この記事の上部にある［エンドポイント］****タブでご確認いただけます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既存のEメールをレコードに関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメールをレコード（コンタクトや、それに関連付けられている会社など）に関連付けるには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "に送信します。リクエストのURLは次のフィールドからなります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emailId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "EメールのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールに関連付けるオブジェクトのタイプ（コンタクト、会社など）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールに関連付けるレコードのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eメールと他のオブジェクトとの関連付けタイプを示す一意の識別子。このIDは、数値またはスネークケース（例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_to_contact"
            }), "）で表すことができます。該当する値は", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "関連付けAPI"
            }), "を介して取得できます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、リクエストのURLは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/emails/17691787884/associations/contact/104901/198"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付けを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメールとレコードの間の関連付けを削除するには、上記と同じ以下のURLに対して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "レコードでEメールをピン留めする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レコードで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "Eメールをピン留め"
      }), "して、レコードのタイムラインの上部に常に表示されるようにすることができます。ピン留めする前に、Eメールが既にレコードに関連付けられている必要があり、1つのレコードにつき1つのアクティビティーのみをピン留めできます。Eメールをピン留めするには、オブジェクトAPIを介してレコードを作成または更新するときに、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "フィールドにEメールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "を含めます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "会社"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "コンタクト"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "取引"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "チケット"
      }), "、および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "カスタムオブジェクト"
      }), "APIの使用の詳細については、こちらをご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eメールを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメールをいったん削除すると、完全に削除され、復元することは", (0, _jsxRuntime.jsx)("u", {
        children: "できません"
      }), "。Eメールを個別に、または一括で削除することができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["EメールIDを指定して個別にEメールを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eメールを一括で削除する方法については、この記事の上部にある［エンドポイント］****タブでご確認いただけます。"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}