"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358691;
const slug = exports.slug = 'guides/api/cms/content-audit';
const title = exports.title = 'CMS Content Audit API';
const name = exports.name = 'vNext Docs DP - CMS Content Audit API';
const metaDescription = exports.metaDescription = 'The CMS Content Audit API allows you to filter and sort on content object changes by type, time period, or HubSpot user ID, so you’re never left wondering what happened.';
const position = exports.position = 2;
const toc = exports.toc = [{
  "depth": 0,
  "id": "cms-event-type-listing",
  "label": "CMS Event Type Listing",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-object-types-listing",
  "label": "CMS Object Types Listing",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      em: "em",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "CMS Content Audit"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The CMS Content Audit API allows you to filter and sort content object changes by type, time period, or HubSpot user ID. This endpoint is only available in accounts with a ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub"
        }), " Enterprise"]
      }), " subscription."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " find out which user most recently made changes to a list of pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS Event Type Listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Event Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CREATED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object has been created."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DELETED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object has been deleted or disconnected."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLISHED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object has been published."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "UNPUBLISHED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object has been unpublished."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "UPDATED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object has been updated."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS Object Types Listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Object Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Changes made to your blog settings in your account settings."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog posts associated with your blog(s)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTENT_SETTINGS"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Changes made to your website settings in your account settings. The values changed will appear in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta"
            }), " JSON array."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CTA"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Changes made to your ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cta/get-started-with-calls-to-action-ctas",
              children: "Calls-to-action (CTAs)."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DOMAIN"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Changes made to the domains connected in your Domains & URLs settings in your account settings."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Changes made to emails in the email editor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FILE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Changes made to files in the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cos-general/organize-edit-and-delete-files",
              children: "files tool"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GLOBAL_MODULE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Changes made to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cos-general/can-i-make-changes-to-a-global-module-in-only-one-template",
              children: "global modules."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBDB_TABLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Changes made to HubDB tables."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_BASE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Changes made to your knowledge base settings in your account settings."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_BASE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Changes made to knowledge base articles in the content editor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Changes made to landing pages in the content editor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MODULE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Changes made to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview",
              children: "modules"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SERVERLESS_FUNCTION"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Changes made to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
              children: "serverless functions"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TEMPLATE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Changes made to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview",
              children: "templates"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "THEME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Changes made to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/edit-your-global-theme-settings",
              children: "Theme Settings"
            }), " and when ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/edit-your-global-theme-settings",
              children: "Themes"
            }), " are created."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "URL_MAPPING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Changes made to your URL Redirects in URL Redirects settings in your account settings."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBSITE_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Changes made to website pages in the content editor."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}