"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742906;
const slug = exports.slug = 'guides/api/marketing/emails/transactional-emails';
const title = exports.title = 'Marketing-API | Transaktions-E-Mails ';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Transactional Emails';
const metaDescription = exports.metaDescription = 'Ein Überblick über die Funktion „Transaktions-E-Mails“ in HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "methoden-zum-senden-von-transaktions-e-mails",
  "label": "Methoden zum Senden von Transaktions-E-Mails",
  "parent": null
}, {
  "depth": 0,
  "id": "smtp-api",
  "label": "SMTP-API",
  "parent": null
}, {
  "depth": 1,
  "id": "neues-smtp-api-token-erstellen",
  "label": "Neues SMTP-API-Token erstellen",
  "parent": "smtp-api"
}, {
  "depth": 1,
  "id": "abrufen-von-smtp-token",
  "label": "Abrufen von SMTP-Token",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "smtp-api-token-nach-kampagne-auflisten",
  "label": "SMTP-API-Token nach Kampagne auflisten",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "einzelnes-smtp-api-token-anfragen",
  "label": "Einzelnes SMTP-API-Token anfragen",
  "parent": "smtp-api"
}, {
  "depth": 3,
  "id": "antwortdetails",
  "label": "Antwortdetails",
  "parent": "smtp-api"
}, {
  "depth": 1,
  "id": "vorhandene-token-verwalten",
  "label": "Vorhandene Token verwalten",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "passwort-zur%C3%BCcksetzen",
  "label": "Passwort zurücksetzen",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "ein-token-l%C3%B6schen",
  "label": "Ein Token löschen",
  "parent": "smtp-api"
}, {
  "depth": 1,
  "id": "am-hubspot-smtp-server-anmelden",
  "label": "Am HubSpot SMTP-Server anmelden",
  "parent": "smtp-api"
}, {
  "depth": 0,
  "id": "single-send-api",
  "label": "Single-Send-API",
  "parent": null
}, {
  "depth": 1,
  "id": "ihre-e-mail-vorlage-erstellen-und-ver%C3%B6ffentlichen",
  "label": "Ihre E-Mail-Vorlage erstellen und veröffentlichen",
  "parent": "single-send-api"
}, {
  "depth": 1,
  "id": "ihre-e-mail-%C3%BCber-die-single-send-api-senden",
  "label": "Ihre E-Mail über die Single-Send-API senden",
  "parent": "single-send-api"
}, {
  "depth": 1,
  "id": "eigenschaften-anfordern",
  "label": "Eigenschaften anfordern",
  "parent": "single-send-api"
}, {
  "depth": 2,
  "id": "emailid",
  "label": "emailId",
  "parent": "single-send-api"
}, {
  "depth": 2,
  "id": "message",
  "label": "message",
  "parent": "single-send-api"
}, {
  "depth": 2,
  "id": "contactproperties",
  "label": "contactProperties",
  "parent": "single-send-api"
}, {
  "depth": 2,
  "id": "customproperties",
  "label": "customProperties",
  "parent": "single-send-api"
}, {
  "depth": 1,
  "id": "status-eines-e-mail-versands-abfragen",
  "label": "Status eines E-Mail-Versands abfragen",
  "parent": "single-send-api"
}, {
  "depth": 2,
  "id": "sendresult",
  "label": "sendResult",
  "parent": "single-send-api"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      strong: "strong",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      code: "code",
      h4: "h4",
      h5: "h5",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Transaktions-E-Mails"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/email/transactional-email",
        children: "transaktionalen E-Mail-Add-on"
      }), " können Sie E-Mails für Geschäftsbelege, Kontoaktualisierungen, Änderungen der Allgemeinen Geschäftsbedingungen und andere wichtige Geschäftsvorgänge über eine dedizierte IP-Adresse senden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Transaktions-E-Mails sind für beziehungsbasierte Interaktionen gedacht, im Gegensatz zu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/emails/marketing-emails",
        children: "Marketing-E-Mails"
      }), ", die typischerweise zur Bewerbung von Inhalten verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Methoden zum Senden von Transaktions-E-Mails"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt drei Möglichkeiten, Transaktions-E-Mails zu implementieren:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/email/how-to-use-transactional-email-in-hubspot",
          children: "Transaktions-E-Mail innerhalb der App einrichten"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#smtp-api",
          children: "SMTP-API"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#single-send-api",
          children: "Single-Send-API"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Methode"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Überblick"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Anwendungsfall als Beispiel"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Transaktions-E-Mail innerhalb der App"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Erstellen Sie Transaktions-E-Mails mithilfe des E-Mail-Editors von HubSpot.Sie erhalten die gleichen Vorteile wie bei HubSpot-Standard-E-Mails, z. B. Smart Content, Personalisierung und Vorlagen.Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/de/email/how-to-use-transactional-email-in-hubspot",
              children: "Einrichten von Transaktions-E-Mail innerhalb der App"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Senden Sie eine E-Mail zu einer Richtlinienaktualisierung an Ihre Kunden mit einem Link zu einer neuen Richtlinie. Da es sich um ein Service-Update und nicht um eine Marketing-E-Mail handelt, müssen Sie keine Abonnementlinks (z. B. CAN-SPAM-Links) einfügen. Sie müssen keine benutzerdefinierten Eigenschaften oder Informationen aus externen Systemen verwenden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "SMTP-API"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Senden Sie Transaktions-E-Mails über Ihre eigene Website oder App, während Sie die E-Mail-Performance verfolgen und Kontaktinformationen in HubSpot erstellen. Die optionale Möglichkeit, Kontaktinformationen zu erstellen, basiert auf der Erstellung des SMTP-Tokens.Weitere Informationen finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#smtp-api",
              children: "im Abschnitt „SMTP-API“ weiter unten."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Senden Sie eine E-Mail zur Bestätigung der Account-Anmeldung über ein separates transaktionsbezogene E-Mail-System, während Sie die E-Mail-Performance verfolgen und Kontakte in HubSpot erstellen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Single-Send-API"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Kombination aus Transaktions-E-Mail innerhalb der App und SMTP-API.Erstellen Sie Transaktions-E-Mails mit dem E-Mail-Editor von HubSpot und fügen Sie benutzerdefinierte externe Token zu Ihrer E-Mail hinzu, die Sie über die API an HubSpot senden können.Weitere Informationen finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#single-send",
              children: "im Abschnitt „Single-Send-API“ weiter unten."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Senden Sie eine Kaufbeleg\\-E-Mail an Ihren Kunden mit HubSpot. Die E-Mail wird ausgelöst, wenn der Kauf getätigt wird und benutzerdefinierte Werte aus einem anderen System übertragen werden (z. B. gekaufte Artikel und Kaufbetrag). Sie können außerdem die Performance dieser E-Mail in HubSpot verfolgen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Kontakte, die in einer Transaktions-E-Mail auf CC gesetzt sind, werden nicht nachverfolgt und die E-Mail wird nicht in der Datensatzchronik des auf CC gesetzten Kontakts angezeigt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SMTP-API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine Transaktions-E-Mail, die über die SMTP-API gesendet wird, wird automatisch von spezifischen Kriterien ausgelöst, z. B. beim Kauf auf einer E-Commerce-Website. Diese API kann in beliebige interne Systeme oder Systeme von Dritten integriert werden, um die E-Mail auszulösen und Daten außerhalb von HubSpot zu verknüpfen (z. B. Versandinformationen oder Kaufpreis). Die E-Mail wird von Ihrem System gesendet, ist jedoch mit den HubSpot-Trackingcodes verknüpft, die eine vollständige ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/email/analyze-your-marketing-email-campaign-performance",
        children: "Verfolgung und Messung der Interaktionen ermöglichen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine E-Mail mit der SMTP-API zu senden, müssen Sie ein SMTP-API-Token verwenden, um Anmeldeinformationen für den HubSpot SMTP-Server zu erhalten. Nach der Anmeldung beim Server können Sie die E-Mail über SMTP senden. Wenn Sie keine SMTP-API-Token erstellt haben, müssen Sie zuerst ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#create-a-new-smtp-api-token",
        children: "ein neues Token generieren"
      }), ". Wenn Sie bereits SMTP-API-Token erstellt haben, informieren Sie sich über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-existing-smtp-api-tokens",
        children: "verschiedenen Methoden zum Erstellen Ihrer Token über die API"
      }), ". Nachdem Sie Ihr Token abgerufen haben, informieren Sie sich über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "Anmeldung beim HubSpot SMTP-Server"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle Domains, die Sie als ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Absenderadresse"
      }), " Ihrer E-Mails verwenden, müssen als ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/domains-and-urls/connect-your-email-sending-domain",
        children: "E-Mail-Domain in HubSpot"
      }), " verknüpft werden. Ein Fehler tritt auf, wenn Sie Transaktions-E-Mails über die SMTP-API mithilfe einer Domain senden, die nicht für Ihren HubSpot-Account autorisiert ist."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Alle Methoden in der SMTP-API benötigen ein ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "OAuth-Token"
        }), " für die Authentifizierung."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Wenn Sie dies bevorzugen, können alle folgenden Methoden zum Erstellen, Abrufen, Löschen und Zurücksetzen von Passwörtern für SMTP-API-Token ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "in Ihrem HubSpot-Account und nicht über die API"
        }), " durchgeführt werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Neues SMTP-API-Token erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein neues SMTP-API-Token zu erstellen, stellen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Anfragetext muss ein JSON-formatiertes Objekt mit den folgenden Eigenschaften sein:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": gibt an, ob ein Kontakt für E-Mail-Empfänger erstellt werden soll."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": ein Name für die Kampagne, die dem SMTP-API-Token zugeordnet ist."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Antwort enthält ein SMTP-Token-Objekt, das Folgendes enthält:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": Benutzername, um sich am HubSpot SMTP-Server anzumelden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": E-Mail-Adresse des Benutzers, der die Anfrage zur Erstellung des Tokens gesendet hat."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "password"
        }), ": Passwort für die Protokollierung im HubSpot SMTP-Server."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": ID, die der in der Anfrage zur Token-Erstellung bereitgestellten Kampagne zugewiesen wurde."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": Zeitstempel, der beim Erstellen eines Tokens generiert wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": gibt an, ob ein Kontakt für E-Mail-Empfänger erstellt werden soll."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": der Name der Kampagne, die dem Token zugeordnet ist."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit Ihrem erstellten Token können Sie sich ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "am HubSpot SMTP-Server"
      }), " mit den Werten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "password"
      }), " anmelden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das Passwort eines Tokens kann nur zum Zeitpunkt der Erstellung abgerufen werden. Wenn Sie das Passwort verlieren oder ein neues Passwort festlegen möchten, müssen Sie das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manage-existing-tokens",
        children: "Passwort des Tokens zurücksetzen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " SMTP-API-Token, die über die öffentliche API generiert werden, laufen nach 12 Monaten ab. Nach Ablauf werden sie automatisch gelöscht. Token, die direkt ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "in Ihrem HubSpot-Account"
        }), " erstellt wurden, laufen ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " automatisch ab."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abrufen von SMTP-Token"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend finden Sie die verfügbaren Methoden zum Abrufen von Tokendaten mithilfe der API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "SMTP-API-Token nach Kampagne auflisten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um entweder eine Liste der Token nach Kampagnenname oder ein einzelnes Token nach Kampagnen-ID abzurufen, nehmen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens"
      }), " vor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie müssen auch einen Parameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "campaignName"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailCampaignId"
      }), " mit der Anfrage hinzufügen. In der Registerkarte „Endpunkte“ am Anfang dieses Artikels Sie finden Sie sämtliche Anfragedetails."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Antwortdetails"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Antwort umfasst ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "paging"
      }), " in den Top-Level-Feldern:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "results"
        }), ": eine Sammlung von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "SmtpApiTokenView"
        }), " umfasst:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), ": Benutzername, um sich am HubSpot SMTP-Server anzumelden."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            }), ": E-Mail-Adresse des Benutzers, der die Anfrage zur Erstellung des Tokens gesendet hat."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "emailCampaignId"
            }), ": ID, die der in der Anfrage zur Token-Erstellung bereitgestellten Kampagne zugewiesen wurde."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            }), ": Zeitstempel, der beim Erstellen eines Tokens generiert wird."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createContact"
            }), ": gibt an, ob ein Kontakt für E-Mail-Empfänger erstellt werden soll."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "campaignName"
            }), ": der Name der Kampagne, die dem Token zugeordnet ist."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "paging"
        }), ": enthält ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "next.after"
        }), "-Feld, das zur Anfrage weiterer Ergebnisse verwendet werden kann."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Einzelnes SMTP-API-Token anfragen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein einzelnes SMTP-API-Token nach ID anzufragen, nehmen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), " vor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Antwortdetails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Antwort enthält ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), ", die Folgendes enthält:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": Benutzername, um sich am HubSpot SMTP-Server anzumelden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": E-Mail-Adresse des Benutzers, der die Anfrage zur Erstellung des Tokens gesendet hat."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": ID, die der in der Anfrage zur Token-Erstellung bereitgestellten Kampagne zugewiesen wurde."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": Zeitstempel, der beim Erstellen eines Tokens generiert wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": gibt an, ob ein Kontakt für E-Mail-Empfänger erstellt werden soll."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": der Name der Kampagne, die dem Token zugeordnet ist."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorhandene Token verwalten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie Token erstellt haben, können Sie mit der API ein Passwort zurücksetzen oder das Token löschen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Passwort zurücksetzen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Token-Passwort zurückzusetzen, nehmen Sie eine POST-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}/password-reset"
      }), " vor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Antwort enthält ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), ", die Folgendes enthält:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": Benutzername, um sich am HubSpot SMTP-Server anzumelden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": E-Mail-Adresse des Benutzers, der die Anfrage zur Erstellung des Tokens gesendet hat."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": ID, die der in der Anfrage zur Token-Erstellung bereitgestellten Kampagne zugewiesen wurde."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": Zeitstempel, der beim Erstellen eines Tokens generiert wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": gibt an, ob ein Kontakt für E-Mail-Empfänger erstellt werden soll."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": der Name der Kampagne, die dem Token zugeordnet ist."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ein Token löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein einzelnes SMTP-API-Token zu löschen, nehmen Sie eine DELETE-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), " vor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Antwort enthält keine Inhalte."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Am HubSpot SMTP-Server anmelden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachfolgend finden Sie die Details für das Protokollieren des HubSpot SMTP-Servers mit dem Benutzernamen (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ") und dem von Ihrem Token bereitgestellten Passwort."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMTP-Hostname:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Verwenden Sie außerhalb der EU ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp.hubapi.com"
            }), " als Hostnamen."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Verwenden Sie innerhalb der EU ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp-eu1.hubapi.com"
            }), " als Hostnamen."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMTP-Port:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Für STARTTLS können Sie Port 25 oder 587 verwenden."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Verwenden Sie für direkte TLS-Verbindungen Port 465."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMTP-Benutzername:"
        }), " wird im Feld „ID“ angegeben"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMTP-Passwort:"
        }), " wird im Feld „Passwort“ angegeben"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Single-Send-API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Single-Send-API sendet Vorlagen-E-Mails, die im E-Mail-Tool von HubSpot mithilfe einer JSON-formatierten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage erstellt wurden. Alle E-Mails, die über diese API gesendet werden, werden basierend auf der E-Mail-Adresse automatisch mit Kontaktdatensätzen verknüpft. Wenn es keinen Kontakt mit einer übereinstimmenden E-Mail-Adresse gibt, wird ein neuer Kontakt mit dieser E-Mail erstellt. Wenn Sie E-Mails senden möchten, ohne Kontakte zu erstellen, verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "SMTP-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ihre E-Mail-Vorlage erstellen und veröffentlichen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Single-Send-API sendet Vorlagen-E-Mails, die im E-Mail-Tool von HubSpot mithilfe einer JSON-formatierten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage erstellt wurden. Alle E-Mails, die über diese API gesendet werden, werden basierend auf der E-Mail-Adresse automatisch mit Kontaktdatensätzen verknüpft. Wenn es keinen Kontakt mit einer übereinstimmenden E-Mail-Adresse gibt, wird ein neuer Kontakt mit dieser E-Mail erstellt. Wenn Sie E-Mails senden möchten, ohne Kontakte zu erstellen, verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "SMTP-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Richten Sie zunächst ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/email/how-to-use-transactional-email-in-hubspot",
        children: "Ihre E-Mail in HubSpot"
      }), " ein. Nachdem Sie die E-Mail erstellt haben, können Sie die Empfängerdetails festlegen, einschließlich beliebiger Kontakt- oder benutzerdefinierter Eigenschaften, die in der E-Mail-Vorlage im Text der API-Anfrage eingerichtet werden. Bevor Sie die API-Anfrage vornehmen können, benötigen Sie die ID der E-Mail:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie die E-Mail als Entwurf ohne Veröffentlichung belassen, können Sie die E-Mail-ID von der URL abrufen, wenn Sie sich im E-Mail-Editor befinden. Die ID ist der letzte numerische Wert vor dem letzten Schrägstrich (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/"
        }), ") in der URL (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/email/{PORTAL_ID}/edit/{EMAIL_ID}/settings"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/email-id-for-drafted-single-send-api-email.png",
        alt: "email-id-for-drafted-single-send-api-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie Ihre E-Mail veröffentlichen, können Sie die E-Mail-ID von der Seite mit den E-Mail-Details kopieren."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-15%20at%201.00.37%20PM.png",
        alt: "Screenshot 2020-04-15 um 1.00.37 PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " HubSpot speichert den über diese API gesendeten HTML/JSON nicht. Sie können die E-Mail-Vorlage in der Chronik des Empfängerkontakts überprüfen. Wenn Sie jedoch den Inhalt der E-Mail aufzeichnen möchten, wird empfohlen, der E-Mail ein BCC hinzuzufügen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ihre E-Mail über die Single-Send-API senden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine E-Mail mit der Single-Send-API zu senden, nehmen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/single-email/send"
      }), " vor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Antwort enthält die folgenden Felder:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), ": der Zeitstempel, an dem der Versand angefordert wurde."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), ": eine ID, die zum ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-the-status-of-an-email-send",
            children: "Abfragen des Versandstatus"
          }), " verwendet werden kann."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), ": der Status der Versandanfrage. Umfasst ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eigenschaften anfordern"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Anfragetext muss ein JSON-formatiertes Objekt mit den folgenden Eigenschaften sein:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "message"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contactProperties"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "emailId"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das Feld ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailId"
      }), " enthält die Content-ID der Transaktions-E-Mail, die im E-Mail-Tool von HubSpot gefunden werden kann."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "message"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das Feld „message“ ist ein JSON-Objekt, das alles enthält, was Sie überschreiben möchten. Es muss mindestens das Feld ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "to"
      }), " enthalten sein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachrichten-Objektfelder:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "to"
        }), ": der Empfänger der E-Mail"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "from"
        }), ": „Von“-Header der E-Mail. Sie können einen Namen in folgendem Format definieren: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"from\":“Sendername <sender@hubspot.com>\""
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), ": ID eines bestimmten Versands. Es wird nur eine E-Mail mit einer bestimmten ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), " pro Account gesendet. Daher können Sie dieses Feld einfügen, um einen doppelten E-Mail-Versand zu verhindern."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "replyTo"
        }), ": JSON-Liste mit den „Antwort an“-Headerwerten für die E-Mail."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "cc"
        }), ": JSON-Liste der E-Mail-Adressen, an die eine Kopie gesendet wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "bcc"
        }), ": JSON-Liste der E-Mail-Adressen, an die eine Blindkopie gesendet wird."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "contactProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das Feld ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactProperties"
      }), " ist eine JSON-Karte der Kontakteigenschaftswerte. Jeder Kontakteigenschaftswert enthält ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "value"
      }), ". Jede Eigenschaft wird im Kontaktdatensatz festgelegt und in der Vorlage angezeigt unter:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/name-token-in-template-for-transactional-email.png",
        alt: "name-token-in-template-for-transactional-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie diese Eigenschaften, wenn Sie eine Kontakteigenschaft festlegen möchten, während Sie die E-Mail senden. Wenn Sie z. B. einen Beleg senden, sollten Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "last_paid_date"
      }), "-Eigenschaft festlegen, da beim Versand des Belegs Informationen über die letzte Zahlung übermittelt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"contactProperties\": {\n    \"last_paid_date\": \"2022-03-01\",\n    \"firstname\": \"jane\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "customProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), "-Feld ist eine JSON-Zuordnung von Schlüssel-Wert-Eigenschaften. Diese Eigenschaften beziehen sich im Allgemeinen auf die E-Mail selbst, nicht auf den Kontakt, der die E-Mail erhält. Sie werden nicht in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/email/create-a-web-version-of-your-marketing-email",
        children: "Webseitenversion der E-Mail"
      }), " oder in der Ansicht der E-Mail in der Chronik des Kontakts angezeigt. Diese Eigenschaften werden auch nicht in HubSpot gespeichert und sind nur in der gesendeten E-Mail enthalten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf jeden Schlüssel im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), "-Feld kann in der Vorlage mit einem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL-Ausdruck"
      }), " für Felder verwiesen werden, die in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "custom"
      }), "-Variable enthalten sind (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ custom.NAME_OF_PROPERTY }}"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Ihre E-Mail-Vorlage beispielsweise auf zwei Eigenschaften verweist, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "purchaseUrl"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "productName"
      }), ", könnten Sie die zugehörigen Werte für diese Eigenschaften mit dem folgenden Anfragetext bereitstellen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"customProperties\": {\n    \"purchaseUrl\": \"https://example.com/link-to-product\",\n    \"productName\": \"vanilla\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können dann auf diese Eigenschaften in Ihrer E-Mail-Vorlage verweisen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Congrats on purchasing some of the best ice cream around.\n  </p>\n  <a href={{custom.purchaseUrl}}>{{custom.productName}}</a>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), "-Feld unterstützt nur Arrays, wenn es mit", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "programmierbaren E-Mail-Inhalten"
      }), " verwendet wird. In Ihrer E-Mail-Vorlage können Sie auf die in Ihrem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), "-Feld definierten Elemente mithilfe eines ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL-Ausdrucks"
      }), " verweisen (z. B. mithilfe einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for-Schleife"
      }), ", um jedes Element in einer Liste zu rendern). Zum Beispiel, wenn die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), ", die Sie in Ihren Anfragetext aufgenommen haben, wie der folgende JSON-Snippet strukturiert war:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643122,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"7\"\n  },\n  \"customProperties\": {\n    \"exampleArray\": [\n      {\"firstKey\": \"someValue\", \"secondKey\": \"anotherValue\"},\n      {\"firstKey\": \"value1\", \"secondKey\": \"value2\" }\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können dann für jedes Element in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exampleArray"
      }), " mit dem folgenden HubL-Code auf die Werte verweisen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Thanks for your recent purchase! Here are the details of the items you'll be receiving:\n  </p>\n  <ul>\n    {% for item in custom.exampleArray %}\n      <li>First key: {{ item.firstKey }}, Second key: {{item.secondKey}}</li>\n    {% endfor %}\n  </ul>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nach dem Senden würde die Transaktions-E-Mail den Inhalt der zugehörigen programmierbaren E-Mail-Vorlage wie folgt wiedergeben:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-transactional-email-with-customProperties-array.png",
        alt: "example-transactional-email-with-customProperties-array"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Status eines E-Mail-Versands abfragen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um den Status des E-Mail-Versands abzurufen, nehmen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/marketing/v3/email/send-statuses/{statusId}"
      }), " vor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Antwort enthält die folgenden Felder:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "sendResult"
          }), ": eine Aufzählung, die den Sendestatus der E-Mail darstellt. Die möglichen Werte sind ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#sendresult-values",
            children: "unten aufgeführt"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), ": der Zeitstempel, an dem der Versand angefordert wurde."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "startedAt"
          }), ": der Zeitstempel, an dem der Versand verarbeitet wurde."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "completedAt"
          }), ": der Zeitstempel, an dem der Versand abgeschlossen wurde."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), ": eine ID, die zum Abfragen des Versandstatus verwendet werden kann."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), ": der Status der Versandanfrage. Umfasst ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "eventId"
          }), ": bei einem Versand, die ID und der erstellte Zeitstempel des gesendeten Ereignisses."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendResult"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sendResult"
      }), " ist eine Aufzählung, die das Ergebnis eines E-Mail-Sendeversuchs darstelllt. Seine möglichen Werte sind:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "SENT"
        }), ": Die E-Mail wurde erfolgreich gesendet."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "QUEUED"
        }), ": Die E-Mail wurde in die Warteschlange eingefügt und wird gesendet, wenn die Warteschlange abgearbeitet wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PORTAL_SUSPENDED"
        }), ": Aufgrund von Verletzungen der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/acceptable-use",
          children: "Richtlinie zur akzeptablen Nutzung"
        }), " wurde die E-Mail des HubSpot-Kunden ausgesetzt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_TO_ADDRESS"
        }), ": Die Empfängeradresse ist ungültig. Dieser Fehler tritt auch auf, wenn Sie versuchen, eine E-Mail mit einem der folgenden rollenbasierten Präfixe in der E-Mail-Adresse zu senden: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "abuse"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "no-reply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "noreply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "root"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "spam"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "security"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undisclosed-recipients"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "unsubscribe"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inoc"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "postmaster"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "privacy"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOCKED_DOMAIN"
        }), ": Die Domain kann zu diesem Zeitpunkt keine E-Mails von HubSpot empfangen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUSLY_BOUNCED"
        }), ": Eine vorangegangene E-Mail konnte dem Empfänger nicht zugestellt werden und die Versandlogik hat keinen Versand zurückgegeben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUS_SPAM"
        }), ": Der Empfänger hat bereits eine ähnliche E-Mail als Spam gekennzeichnet."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_FROM_ADDRESS"
        }), ": Die ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Absenderadresse"
        }), " ist ungültig."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_CONTENT"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " ist ungültig oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " entspricht einer E-Mail, die nicht für Single-Send eingerichtet wurde."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_TEMPLATE_PROPERTIES"
        }), ": Es gibt Eigenschaften, die in der Vorlage festgelegt wurden, die nicht in den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        }), " der Anfrage gesendet wurden."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}