"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021628;
const slug = exports.slug = 'guides/apps/authentication/working-with-oauth';
const title = exports.title = 'Arbeiten mit OAuth';
const name = exports.name = 'vNext Docs DP - Arbeiten mit OAuth';
const metaDescription = exports.metaDescription = 'OAuth ist eine sichere Methode der Authentifizierung für Ihre App. Sie verwendet Autorisierungstoken anstelle eines Passworts, um Ihre App mit einem Kunden-Account zu verknüpfen. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "initiieren-einer-integration-mit-oauth-2.0",
  "label": "Initiieren einer Integration mit OAuth 2.0",
  "parent": null
}, {
  "depth": 0,
  "id": "abfrageparameter",
  "label": "Abfrageparameter",
  "parent": null
}, {
  "depth": 0,
  "id": "bereiche-konfigurieren",
  "label": "Bereiche konfigurieren",
  "parent": null
}, {
  "depth": 0,
  "id": "verwandte-dokumente",
  "label": "Verwandte Dokumente",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h5: "h5",
      h2: "h2",
      em: "em",
      img: "img",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Arbeiten mit OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["OAuth ist eine sichere Authentifizierungsmethode, die Autorisierungstoken anstelle eines Passworts verwendet, um Ihre App mit einem Kunden-Account zu verknüpfen. Das Initiieren von OAuth-Zugriff ist der erste Schritt, um Benutzern das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#install-an-app",
        children: "Installieren Ihrer App"
      }), " in ihren HubSpot-Accounts zu ermöglichen."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Jede App, die für die Installation durch mehrere HubSpot-Accounts oder für ein Listing im App Marketplace entwickelt wurde, muss OAuth verwenden."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Benutzer, die Apps in ihrem HubSpot-Account installieren, müssen entweder ein ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
            children: "Super-Admin"
          }), " sein oder über Berechtigungen für ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: "Zugriff auf den App-Marketplace"
          }), " verfügen."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Empfohlene Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "OAuth-Schnellanleitung"
        }), " ermöglicht Ihnen mithilfe einer Arbeitsbeispiel-App einen schnellen Einstieg."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dieses ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/academy/tracks/71/593/2967",
          children: "HubSpot Academy-Tutorial"
        }), " bietet eine schnelle Einführung in die Verwendung von OAuth mit HubSpot, einschließlich einer ausführlichen Darstellung des HubSpot-OAuth-Prozesses und der Aktualisierung eines Zugriffstokens."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Initiieren einer Integration mit OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So initiieren Sie eine Integration mit OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Zuerst müssen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "eine App erstellen"
        }), " in einem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers/",
          children: "HubSpot-Entwickler-Account"
        }), ". Nach dem Erstellen der App finden Sie die Client-ID und das Client-Geheimnis der App auf der Seite zur ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Authentifizierung"
        }), " Ihrer App-Einstellungen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/MyHubSpotApp.png",
        alt: "MyHubSpotApp"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Verwenden Sie die Client-ID und das Client-Geheimnis zusammen mit den unten beschriebenen ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-parameters",
            children: "Abfrageparametern"
          }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#scopes",
            children: "Bereichen"
          }), ", um Ihre Autorisierungs-URL zu erstellen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Leiten Sie Benutzer, die Ihre App installieren, zur Autorisierungs-URL weiter, wo ihnen ein Bildschirm angezeigt wird, auf dem sie ihren Account auswählen können und Zugriff auf Ihre Integration gewährt werden kann. Sie können die Autorisierungs-URL für einen bestimmten HubSpot-Account festlegen, indem Sie die Account-ID zwischen ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/oauth/"
          }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/authorize"
          }), " hinzufügen, wie unten gezeigt. Nachdem Sie Zugriff gewährt haben, gelangen sie über eine ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "redirect_url"
          }), ", der ein Code-Abfrageparameter angehängt ist, zurück zu Ihrer Anwendung. Sie verwenden diesen Code und das Client-Geheimnis, um ein ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/oauth/tokens",
            children: "access_token und refresh_token"
          }), " von HubSpot zu erhalten."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beispiel-Autorisierungs-URLs"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Alle Accounts:"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Spezifischer Account (ID 123456):"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/123456/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beispiel-Weiterleitungs-URL"
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://example.com/?code=xxxx"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beispielfehler"
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/?error=error_code&error_description=Human%20readable%20description%20of%20the%20error"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Verwenden Sie ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), ", um API-Aufrufe zu authentifizieren, die für diesen HubSpot-Account vorgenommen wurden."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nachdem das ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), " abgelaufen ist, verwenden Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "refresh_token"
          }), ", mum ein neues ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), " zu generieren."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Ihre App wird nicht als ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "verknüpfte App"
          }), " im Account eines Benutzers angezeigt, es sei denn, Sie generieren das Aktualisierungstoken und das Token für den ersten Zugriff."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Zugriffstoken spiegeln die von der App angeforderten Bereiche wider. Sie spiegeln ", (0, _jsxRuntime.jsx)("u", {
            children: "nicht"
          }), " die Berechtigungen oder Einschränkungen dessen wider, was ein Benutzer in seinem HubSpot-Account tun kann. Wenn beispielsweise ein Benutzer die Berechtigung hat, nur eigene Kontakte anzuzeigen, aber eine Anfrage für den ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "crm.objects.contacts.read"
          }), "-Bereich autorisiert, kann das resultierende Zugriffstoken alle Kontakte im Account anzeigen und nicht nur die, die dem autorisierenden Benutzer gehören."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abfrageparameter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Parameter sind erforderlich, wenn Sie eine Autorisierungs-URL für Ihre App erstellen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Parameter"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beschreibung"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Anwendung"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Client-ID"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id=x"
            }), "In der URL verwendet"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten diese von der Seite mit den Authentifizierungseinstellungen Ihrer App (wie oben beschrieben)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Weiterleitungs-URL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri=x"
            }), "Die URL, zu der Besucher weitergeleitet werden, nachdem Zugriff auf Ihre App gewährt wurde."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sie bestimmen diese ebenfalls auf der Seite mit den Authentifizierungseinstellungen Ihrer App.", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Hinweis:"
            }), " Aus Sicherheitsgründen ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "muss"
            }), " diese URL https im Produktions-Account verwenden. (Beim Testen mithilfe von localhost kann http verwendet werden.) Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "müssen"
            }), " auch eine Domain verwenden, da IP-Adressen nicht unterstützt werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Bereich"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein durch Leerzeichen getrennter Satz an Berechtigungen, auf die Ihre App Zugriff benötigt. Alle Bereiche, die Sie in den Authentifizierungseinstellungen Ihrer App aktiviert haben, werden als erforderlich behandelt. Sie müssen sie in diesem Parameter berücksichtigen oder die Autorisierungsseite zeigt einen Fehler an. Benutzern wird außerdem ein Fehler angezeigt, wenn sie versuchen, Ihre App in einem Account zu installieren, der keinen Zugriff auf einen berücksichtigten Bereich hat. Weitere Informationen dazu, welche Endpunkte durch spezifische Bereiche aufgerufen werden können, finden Sie in der Tabelle „Bereiche“."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Parameter sind optional:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Parameter"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Anwendung"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beschreibung"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Optionale Bereiche"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "&optional_scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein durch Leerzeichen getrennter Satz an optionalen Berechtigungen für Ihre App. Optionale Bereiche werden automatisch aus der Autorisierungsanforderung ausgeschlossen, wenn der Benutzer einen HubSpot-Account auswählt, der keinen Zugriff auf dieses Tool hat (z. B. wenn der Social-Media-Bereich in einem Nur-CRM-Portal angefordert wird). Wenn Sie optionale Bereiche verwenden, müssen Sie das Zugriffstoken und das Aktualisierungstoken überprüfen, um zu sehen, welche gewährt wurden. Weitere Informationen zu Bereichen finden Sie in der folgenden Tabelle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Zustand"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "&state=y"
            }), "Wenn dieser Parameter in der Autorisierungs-URL enthalten ist, wird der Wert in einem state-Abfrageparameter berücksichtigt, wenn der Benutzer zur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_url"
            }), " weitergeleitet wird."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Zeichenfolgenwert, der zur Aufrechterhaltung des Status des Benutzers verwendet werden kann, wenn er zurück zu Ihrer App weitergeleitet wird."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bereiche konfigurieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "OAuth erfordert, dass Sie Bereiche, oder Berechtigungen, für Ihre App festlegen. Jeder Bereich bietet Zugriff auf eine Reihe von HubSpot-API-Endpunkten und ermöglicht Benutzern, Ihrer App Zugriff auf bestimmte Tools in ihrem HubSpot-Account zu gewähren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/granular-scopes-selection.png",
        alt: "granular-scopes-selection"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Zugriff auf bestimmte APIs oder Endpunkte hängt von der HubSpot-Account-Stufe ab. In der folgenden Tabelle finden Sie eine vollständige Liste der verfügbaren Bereiche und aufrufbaren Endpunkte. Wenn Ihre App mit mehreren Typen von HubSpot-Accounts arbeitet, können Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), "-Parameter verwenden, um alle Stufen-Bereiche zu bierücksichtigen, mit denen Sie arbeiten. Auf diese Weise können Kunden, die Nur-CRM-Accounts verwenden, weiterhin Ihre App autorisieren, selbst wenn sie nicht auf alle ihre Bereiche zugreifen können. Ihre App muss auf alle Bereiche überprüfen, für die sie nicht autorisiert wird, und diese verarbeiten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Bereich"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beschreibung"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Bietet Zugriff auf"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Erforderliche Account-Stufe"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integratoren können CMS-Domains im Account eines Kunden listen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integratoren können benutzerdefinierte CMS-Domains erstellen, aktualisieren und löschen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integratoren können alle serverlosen CMS-Funktionen, alle damit verbundenen Geheimnisse und Funktionsausführungsergebnisse anzeigen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Enterprise"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integratoren können serverlose Funktionen und Geheimnisse für CMS schreiben."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu Wissensdatenbankartikeln an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: "** Service Hub**"
            }), " _ Professional_ oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gewährt Zugriff auf aktualisierte Wissensdatenbankartikel."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.publish"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gewährt Zugriff auf das Aktualisieren und Veröffentlichen von Wissensdatenbankartikeln."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie allgemeine Einstellungen sowie Vorlageneinstellungen für Wissensdatenbanken an, z. B. die Domain oder die Haupt-UR."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gewährt Zugriff auf das Aktualisieren der allgemeinen Einstellungen sowie der Vorlageneinstellungen für Wissensdatenbanken. Dazu gehört auch der Schreibzugriff auf Wissensdatenbankartikel."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: "** Service Hub** Professional"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.performance.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integratoren können CMS-Performance-Daten für alle Ihre Websites anzeigen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu Kontaktlisten an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Listen-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erstellen oder löschen Sie Kontaktlisten oder nehmen Sie Änderungen daran vor"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Listen-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Eigenschaften und andere Details zu Unternehmen an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmen-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Eigenschaften an und erstellen oder löschen Sie Unternehmen oder nehmen Sie Änderungen daran vor."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmen-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Eigenschaften und andere Details zu Kontakten an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kontakte-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeiget Eigenschaften an und erstellen, löschen und ändern Sie Kontakte."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kontakte-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu benutzerdefinierten Objekten im HubSpot-CRM an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Benutzerdefinierte Objekte-Endpunkte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alle ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise-Accounts"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erstellen, löschen oder ändern Sie benutzerdefinierte Objekte im HubSpot-CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Benutzerdefinierte Objekte-Endpunkte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alle ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise-Accounts"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Eigenschaften und andere Details zu Deals an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Eigenschaften an und erstellen, löschen oder ändern Sie Deals."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.feedback_submission.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu den Einsendungen zu einer Ihrer Feedbackumfragen an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eedbackumfragen-Endpunkte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["**", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Service Hub"
            }), " ** ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.goals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie alle Zieltypen an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ziele-Endpunkte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Sales Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Eigenschaften und andere Details zu Artikeln an"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Artikel-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erstellen, löschen oder ändern Sie Artikel."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Artikel-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeiget Details zu Marketingevents an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketingevents-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erstellen, löschen oder ändern Sie Marketingevents."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketingevents-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.owners.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu Benutzern an, die einem CRM-Datensatz zugewiesen sind."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zuständige Mitarbeiter-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Eigenschaften und andere Details zu Angeboten und Angebotsvorlagen an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Angebote-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erstellen, löschen oder ändern Sie Angebote."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Angebote-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu Eigenschaftseinstellungen für Unternehmen an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eigenschaften-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erstellen, löschen oder ändern Sie Eigenschaftseinstellungen für Unternehmen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eigenschaften-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeit Details zu Eigenschaftseinstellungen für Kontakte an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eigenschaften-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erstellen, löschen oder ändern Sie Eigenschaftseinstellungen für Kontakte."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eigenschaften-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu Definitionen von benutzerdefinierten Objekten im HubSpot-CRM an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Benutzerdefinierte Objekte-Endpunkte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alle ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise-Accounts"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu den Eigenschafteneinstellungen für Deals an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eigenschaften-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erstellen, löschen oder ändern Sie Eigenschaftseinstellungen für Deals."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eigenschaften-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu Artikeln an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Artikel-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu Angeboten und Angebotsvorlagen an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Angebote-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.billing.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nehmen Sie Änderungen an den Abrechnungseinstellungen Ihres Accounts vor. Dies umfasst die Verwaltung und Zuweisung von bezahlten Lizenzen für Benutzer."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Einstellungen-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liest vorhandene Wechselkurse zusammen mit der aktuellen Unternehmenswährung, die Ihrem Portal zugeordnet ist."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Account-Informationen-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erstellen, aktualisieren und löschen Sie Wechselkurse und aktualisieren Sie die mit Ihrem Portal verknüpfte Unternehmenswährung."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Account-Informationen-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu Account-Benutzern und deren Berechtigungen an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Benutzereinrichtung-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verwalten Sie Benutzer und Benutzerberechtigungen in Ihrem HubSpot-Account. Dies umfasst das Erstellen neuer Benutzer, das Zuweisen von Berechtigungen und Rollen sowie das Löschen bestehender Benutzer."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Benutzereinrichtung-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.teams.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu den Teams des Accounts an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Benutzereinrichtung-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.team.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Weisen Sie Teams in Ihrem HubSpot-Account Benutzer zu."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Benutzereinrichtung-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account-info.security.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Beinhaltet den Zugriff auf Account-Aktivitätsprotokolle und andere Account-Sicherheitsinformationen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Account-Aktivitäten-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accounting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ermöglicht HubSpot und der Buchhaltungsintegration das Teilen von Rechnungs-, Produkt- und Kontaktdetails."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Buchhaltungserweiterungs-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "actions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fügen Sie Formulare zu den Seiten des Kontakts hinzu, die benutzerdefinierte Aktionen ausführen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM-Erweiterungen-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "analytics.behavioral_events.send"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Beinhaltet Zugriff auf das Senden von benutzerdefinierten verhaltensorientierten Events."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Analytics-API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dies umfasst Workflows."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Automatisierungs-API (Workflows-Endpunkte)"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "behavioral_events.event_definitions.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erstellen, lesen, aktualisieren oder löschen Sie verhaltensorientierte Events. Dies umfasst verhaltensorientierte Event-Eigenschaften."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Analytics-API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business_units.view.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Unternehmensbereichsdaten einschließlich Logo-Informationen an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmensbereiche-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Add-on für Unternehmensbereiche"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business-intelligence"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Umfasst Endpunkte mit Bezug zu Quellendaten und E-Mail-Funktionen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Analytics-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_query.execute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fragen Sie Daten aus Ihrem HubSpot-Account mithilfe des GraphQL-API Endpunkts ab"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphQL-API-Endpunkt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_schema.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Führen Sie Introspection-Abfragen über GraphQL-Anwendungsclients wie GraphiQL durch."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphiQL- und andere GraphQL-Clients von Drittanbietern durch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " Professional* oder _Enterprise*"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu den Abo-Einstellungen Ihrer Kontakte an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abo-Einstellungen-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Melden Sie Kontakte für Ihre Abonnementtypen an bzw. von diesen ab. Es werden keine Kontakte für ein Abonnement angemeldet, die sich abgemeldet haben."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abo-Einstellungen-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Melden Sie Kontakte für Ihre Abonnementtypen an bzw. von diesen ab. Es werden keine Kontakte für ein Abonnement angemeldet, die sich abgemeldet haben."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abo-Einstellungen-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Umfasst Websites, Landingpages, E-Mail, Blog und Kampagnen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-API- und Kalender-, E-Mail- und E-Mail-Events-Endpunkte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details zu Threads im Konversationen-Postfach an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Konversationen-Postfach- und Nachrichten-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.visitor_identification.tokens.create"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rufen Sie Identifikationstoken für authentifizierte Website-Besucher ab, die mit dem HubSpot-Chat-Widget interagieren."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Besucheridentifikation-API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alle ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), "- oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), "-Accounts"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Senden Sie Nachrichten in Konversationen. Erstellen und aktualisieren Sie Nachrichten-Threads."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Konversationen-Postfach- und Nachrichten-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.export"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exportieren Sie Datensätze für alle CRM-Datentypen aus Ihrem CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM-Export-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.import"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ermöglicht das Importieren von Datensätzen in Ihr CRM. Umfasst das Erstellen neuer Datensätze oder Ändern bestehender Datensätze für alle CRM-Datentypen (Kontakte, Firmen, Deal-Datensätze, Tickets usw.). Umfasst nicht das Archivieren oder Löschen von Daten."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM-Import-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ctas.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ermöglicht den Lesezugriff für CTAs."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Keine publicAPI verfügbar"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              }), " oder ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })]
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter, Professional oder Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Umfasst den Zugriff auf E-Commerce-Funktionen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Produkte- und Artikel-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "external_integrations.forms.access"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Umfasst die Möglichkeit, vorhandene Formulare umzubenennen, zu löschen und zu kopieren."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Formulare-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Umfasst den Zugriff auf den Datei-Manager."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dateien (Datei-Manager)- und Datei-Mapper (CMS-Vorlagen, Module und Layout)-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files.ui_hidden.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigen Sie Details an oder laden Sie Benutzerdateien, Anhänge und Systemdateien von allen HubSpot-Tools herunter."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dateien (Datei-Manager)- und Datei-Mapper (CMS-Vorlagen, Module und Layout)-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Umfasst den Zugriff auf die Formulare-Endpunkte."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Formular-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms-uploaded-files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Laden Sie über ein Formular eingesendete Dateien herunter."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Endpunkt für das Abrufen einer über Formulareinsendung hochgeladenen Datei"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dies beinhaltet den Zugriff auf HubDB."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDB-Endpunkte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " mit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/website",
              children: "Website-Add-on"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "integration-sync"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erlaubt den Zugriff auf die Synchronisierungs-API, mit der die meisten CRM-Objekte synchronisiert werden können."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-Commerce Bridge-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gewährt Zugriff auf Events und Objekte von der Media Bridge aus."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Media Bridge-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gewährt Zugriff auf das Erstellen und Aktualisieren von Events und Objekten über die Media Bridge."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Media Bridge-API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oauth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grundlegender Bereich, der für OAuth erforderlich ist. Dieser Bereich wird standardmäßig allen Apps hinzugefügt."
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sales-email-read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gewährt Zugriff für das Lesen persönlicher E-Mails, die an Kontakte gesendet werden."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Interaktionen-Endpunkte", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Hinweis:"
            }), " Dieser Bereich ist erforderlich, um den Inhalt von E-Mail-Interaktionen abzurufen. Weitere Informationen finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/engagement-details#email-content",
              children: "Überblick über Interaktionen"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dies schließt den Zugriff auf das Social-Media-Postfach ein."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Social-Media-API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dies beinhaltet den Zugriff auf Tickets."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gewährt Zugriff auf das Verwalten benutzerdefinierter Events in HubSpot-CRM-Datensätzen. Dies umfasst das Erstellen oder Aktualisieren von Datensätzen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chronikeinträge-Endpunkte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Accounts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transactional-email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dies umfasst Transaktions-E-Mails und die Transaktions-E-Mails-Endpunkte."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Transaktions-E-Mails-Endpunkte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              }), " _Professional"]
            }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " mit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/email/transactional-email",
              children: "Transaktions-E-Mail-Add-on"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwandte Dokumente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Authentifizierungsmethoden in HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "OAuth-Schnellanleitung"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/oauth/tokens",
        children: "Verwalten von Token"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}