"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021629;
const slug = exports.slug = 'guides/apps/api-usage/usage-details';
const title = exports.title = "API de HubSpot | Règles d'utilisation";
const name = exports.name = "Règles d'utilisation des API";
const metaDescription = exports.metaDescription = "Les règles d'utilisation des API de HubSpot couvrent notre Politique d'utilisation acceptable, les Conditions des API, les limites de taux, l'authentification et la sécurité.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "authentification-et-s%C3%A9curit%C3%A9",
  "label": "Authentification et sécurité",
  "parent": null
}, {
  "depth": 0,
  "id": "v%C3%A9rification-de-l-utilisation-des-api",
  "label": "Vérification de l'utilisation des API",
  "parent": null
}, {
  "depth": 1,
  "id": "applications-priv%C3%A9es",
  "label": "Applications privées",
  "parent": "v%C3%A9rification-de-l-utilisation-des-api"
}, {
  "depth": 1,
  "id": "applications-publiques-utilisant-oauth",
  "label": "Applications publiques utilisant OAuth",
  "parent": "v%C3%A9rification-de-l-utilisation-des-api"
}, {
  "depth": 0,
  "id": "limites-de-taux",
  "label": "Limites de taux",
  "parent": null
}, {
  "depth": 2,
  "id": "applications-publiques",
  "label": "Applications publiques",
  "parent": "limites-de-taux"
}, {
  "depth": 2,
  "id": "applications-priv%C3%A9es",
  "label": "Applications privées",
  "parent": "limites-de-taux"
}, {
  "depth": 0,
  "id": "autres-limites",
  "label": "Autres limites",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-de-demande",
  "label": "Limites de demande",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-de-service",
  "label": "Limites de service",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9ponses-d-erreur",
  "label": "Réponses d'erreur",
  "parent": null
}, {
  "depth": 1,
  "id": "utiliser-les-api-de-transfert-en-masse-et-les-r%C3%A9sultats-du-cache-si-possible",
  "label": "Utiliser les API de transfert en masse et les résultats du cache si possible",
  "parent": "r%C3%A9ponses-d-erreur"
}, {
  "depth": 1,
  "id": "utiliser-des-webhooks-pour-obtenir-des-donn%C3%A9es-actualis%C3%A9es-depuis-hubspot",
  "label": "Utiliser des webhooks pour obtenir des données actualisées depuis HubSpot",
  "parent": "r%C3%A9ponses-d-erreur"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      ul: "ul",
      li: "li",
      img: "img",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Règles d'utilisation des API"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot suit attentivement l'utilisation de nos API publiques pour assurer une expérience de qualité pour chaque utilisateur. Tous les développeurs d'applications et d'intégrations doivent respecter la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "Politique d'utilisation acceptable de HubSpot"
      }), " et les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/developer-terms",
        children: "Conditions générales relatives aux API"
      }), ". Bien que HubSpot se réserve le droit de modifier ou de désapprouver les API au fil du temps, les mises à jour seront toujours fournies à l'avance via le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "changelog des développeurs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authentification et sécurité"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour une sécurité optimale, toutes les applications doivent utiliser directement le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "protocole OAuth"
      }), " de HubSpot ou le jeton d'accès de votre application si vous créez une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "application privée"
      }), ". Les applications sont chargées de stocker des données time-to-live (TTL) et d'actualiser les jetons d'accès des utilisateurs conformément à ce protocole. Lorsqu’un jeton d’accès est généré, il inclura un paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " indiquant la durée durant laquelle il peut être utilisé pour effectuer des appels d’API avant l’actualisation. Les demandes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Unauthorized (401)"
      }), " ne sont pas un indicateur valide qu’un nouveau jeton d’accès doit être récupéré."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vérification de l'utilisation des API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Applications privées"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pour afficher l'utilisation de l'API pour une application privée :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "l'icône Paramètres"
        }), " dans la barre de navigation principale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intégrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applications privées"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " de l'application privée."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sur la page de détails de l'application, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Journaux"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Passez en revue les appels d'API répertoriés dans le tableau. Vous pouvez également utiliser la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "barre de recherche"
        }), ", les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "filtres"
        }), " et les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "sélecteurs de dates"
        }), " pour affiner davantage les appels d'API affichés."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/Screenshot%202023-08-31%20at%205.28.03%20PM.png",
        alt: "Capture d'écran le 31-08-2023 à 5.28.03 PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#view-api-call-logs",
          children: "vérification de l'utilisation de l'API dans les applications privées"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Applications publiques utilisant OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour afficher l'utilisation de l'API pour une application publique utilisant OAuth :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans votre compte de développeur, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Applications"
      }), " dans la barre de navigation. Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "nom"
      }), " de l'application."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suivi"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilisez les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "onglets"
        }), " pour afficher les différents types de demandes effectuées vers ou à partir de l'application. Lors de l'affichage de ces journaux, vous pouvez cliquer sur une ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "demande individuelle"
        }), " pour afficher plus d'informations à son sujet."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/6-request_details.png",
        alt: "6-request_details"
      }), "Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#monitor-app-behavior",
        children: "le suivi de l’utilisation de l’API pour les applications publiques."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites de taux"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Applications publiques"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les applications OAuth, chaque compte HubSpot qui installe votre application est limité à 100 requêtes toutes les 10 secondes. Cela exclut l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search",
        children: "API de recherche"
      }), ", comme indiqué dans la section ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-limits",
        children: "Autres limites"
      }), " ci-dessous. Les limites liées au module complémentaire d'API ne s'appliquent pas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Applications privées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque application privée est soumise aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "directives sur l'utilisation des API HubSpot"
      }), ". Le nombre d'appels que votre application privée peut effectuer dépend de l'abonnement de votre compte et de l'achat du module complémentaire API :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Niveau de produit"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "** toutes les 10 secondes**"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "par jour"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Applications privées"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Tout abonnement Hub) Gratuit et Starter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100 / application privée"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250 000 / compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Tout abonnement Hub) Pro et Entreprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "150 / application privée"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "500 000 / compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Applications privées avec le module complémentaire API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Tout abonnement Hub) Gratuit, Starter, Pro et Entreprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "200 / application privée"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1 000 000 / compte"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autres limites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous pouvez créer jusqu'à 100 applications par compte de développeur."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous pouvez créer jusqu'à 20 applications privées par compte HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous pouvez créer jusqu'à 1 000 abonnements de webhook par application."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous pouvez créer jusqu'à 25 paramètres d'extension de CRM par application."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous pouvez créer jusqu'à 750 types d'événements chronologiques par application."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous pouvez créer jusqu'à 500 propriétés par type d'événement chronologique."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites de demande"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Gardez à l’esprit les limites générales suivantes lorsque vous effectuez des demandes aux points de terminaison d’API de HubSpot. Certaines API peuvent également avoir leurs propres limites, plus spécifiques, qui seront répertoriées sur la page de référence des API sous ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Limites"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/single-send-limits-example.png",
        alt: "single-send-limits-example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les points de terminaison de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search",
          children: "l'API de recherche"
        }), " sont limités à quatre demandes par seconde par jeton d'authentification. Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "les limites de recherche"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les demandes d'API qui sont exemptées des limites quotidiennes ou secondaires ne seront ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " enregistrées dans HubSpot. Si vous souhaitez enregistrer ces demandes exemptées, vous devez les enregistrer en externe."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les demandes de lot vers les points de terminaison des objets CRM sont limitées à 100 fiches d’informations par demande. Par exemple, vous ne pouvez pas lire plus de 100 contacts par demande."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites de service"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les limites de service et les tarifs ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/pricing/service-limits",
        children: "ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Réponses d'erreur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Toute application ou intégration dépassant ses limites de taux recevra une réponse d'erreur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " pour tous les appels d'API ultérieurs. Les demandes résultant d'une réponse d'erreur ne doivent pas dépasser 5 % de vos demandes quotidiennes. Si vous prévoyez de répertorier votre application dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "marketplace des applications de HubSpot"
      }), ", celle-ci doit rester sous cette limite de 5 % pour être certifiée."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La réponse ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " sera au format suivant :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example\n{\n  \"status\": \"error\",\n  \"message\": \"You have reached your daily limit.\",\n  \"errorType\": \"RATE_LIMIT\",\n  \"correlationId\": \"c033cdaa-2c40-4a64-ae48-b4cec88dad24\",\n  \"policyName\": \"DAILY\",\n  \"requestId\": \"3d3e35b7-0dae-4b9f-a6e3-9c230cbcf8dd\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "message"
      }), "et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "policyName"
      }), " indiqueront la limite que vous avez atteinte (quotidienne ou par seconde)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La limite ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "quotidienne"
      }), " se réinitialise à minuit selon votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-set-your-time-zone-in-hubspot",
        children: "fuseau horaire"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le tableau suivant détaille les en-têtes de limite de débit inclus dans la réponse de chaque appel d'API formulé à HubSpot, sous réserve des exceptions répertoriées sous le tableau."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "En-tête"
      }), " | ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Description"
      }), " |\n| --- | --- | --- |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-RateLimit-Daily"
      }), " | Le nombre d'appels d'API autorisés par jour. Notez que cet en-tête n'est pas inclus dans la réponse aux appels d'API autorisés utilisant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), ". |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-RateLimit-Daily-Remaining"
      }), " | Le nombre d'appels d'API encore autorisés pour le jour en cours. Notez que cet en-tête n'est pas inclus dans la réponse aux appels d'API autorisés utilisant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), ". |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-RateLimit-Interval-Milliseconds"
      }), " | La fenêtre de temps à laquelle s’appliquent les en-têtes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-RateLimit-Max"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-RateLimit-Remaining"
      }), ". Par exemple : une valeur de 10 000 serait une fenêtre de 10 secondes. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-RateLimit-Max"
      }), " | Le nombre de demandes autorisées dans la fenêtre indiquée dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-RateLimit-Interval-Milliseconds"
      }), ". Par exemple : si cet en-tête a une valeur de 100 et que l’en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-RateLimit-Interval-Milliseconds"
      }), " a la valeur 10 000, la limite appliquée sera de 100 demandes toutes les 10 secondes. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-RateLimit-Remaining"
      }), " | Le nombre de demandes d'API encore autorisés pour la fenêtre indiquée dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-RateLimit-Interval-Milliseconds"
      }), " | . |"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les en-têtes ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly"
          }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly-Remaining"
          }), " sont toujours inclus et présentent des données précises. Cependant, la limite référencée par ces en-têtes n'est plus appliquée et ces deux en-têtes doivent être considérés comme obsolètes. Les réponses des ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/search",
            children: "points de terminaison de l'API de recherche"
          }), " n'incluront ", (0, _jsxRuntime.jsx)("u", {
            children: "aucun"
          }), " des en-têtes de limite de débit répertoriés ci-dessus."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également vérifier le nombre d'appels utilisés le jour en cours à l'aide de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "ce point de terminaison"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous atteignez la limite ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TEN_SECONDLY_ROLLING"
      }), ", vous devez maintenir les demandes de votre application sous cette limite. Par ailleurs, si vous atteignez la limite quotidienne, consultez les suggestions ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous atteignez toujours les limites d'appel après avoir suivi ces suggestions, veuillez publier sur nos", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://integrate.hubspot.com/",
        children: " forums des développeurs"
      }), ". Fournissez autant de détails que possible quant aux API utilisées, à leur utilisation et à la limite atteinte."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utiliser les API de transfert en masse et les résultats du cache si possible"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si votre site ou votre application utilise des données de HubSpot sur chaque chargement de page, ces données doivent être mises en cache et chargées à partir de ce cache au lieu d'être demandées à chaque fois à partir des API de HubSpot. Si vous effectuez des appels répétés pour obtenir des paramètres depuis votre compte pour un traitement par lot (comme l'obtention de propriétés d'objet, de propriétaires ou de paramètres pour un formulaire), ces paramètres doivent également être mis en cache, le cas échéant."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utiliser des webhooks pour obtenir des données actualisées depuis HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous disposez d'un abonnement HubSpot Marketing Entreprise, vous pouvez utiliser des actions de webhook dans les workflows pour obtenir des données pour les fiches d'informations de contact envoyées à votre système. Les webhooks peuvent être déclenchés en tant qu'action dans n'importe quel workflow, afin que vous puissiez utiliser les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows-user-guide-v2/how-to-choose-the-workflow-type-and-the-starting-condition-best-suited-for-your-goal",
        children: "conditions initiales"
      }), " de workflow comme critères pour l'envoi des données de contact à votre système. Pour en savoir plus sur l'utilisation des webhooks, consultez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "cet article"
      }), " et des exemples de données de webhooks ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/create-manage-workflows/v3#create-a-workflow",
        children: "ici"
      }), ". Les appels de webhook effectués via des workflows ne sont pas comptabilisés dans la limite de l'API."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}