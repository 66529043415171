"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29905990569;
const slug = exports.slug = 'guides/cms/debugging/code-alerts';
const title = exports.title = 'Code Alerts';
const name = exports.name = 'Code alerts';
const metaDescription = exports.metaDescription = 'Code alerts provide you with an overview listing of issues that are identified inside of your CMS pages related to your CSS, Modules, or Templates.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "how-to-view-code-alerts",
  "label": "How to view code alerts",
  "parent": null
}, {
  "depth": 0,
  "id": "asset-types-and-issues",
  "label": "Asset types and issues",
  "parent": null
}, {
  "depth": 1,
  "id": "asset-types",
  "label": "Asset types",
  "parent": "asset-types-and-issues"
}, {
  "depth": 1,
  "id": "issues",
  "label": "Issues",
  "parent": "asset-types-and-issues"
}, {
  "depth": 0,
  "id": "how-to-view-the-issues-identified-for-your-assets",
  "label": "How to view the issues identified for your assets",
  "parent": null
}, {
  "depth": 1,
  "id": "template%2C-page%2C-and-module-assets",
  "label": "Template, page, and module assets",
  "parent": "how-to-view-the-issues-identified-for-your-assets"
}, {
  "depth": 1,
  "id": "css-assets",
  "label": "CSS assets",
  "parent": "how-to-view-the-issues-identified-for-your-assets"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Code alerts"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Code alerts provide a centralized location for developers and IT managers to see an overview listing of issues that are identified inside of your HubSpot CMS. By fixing the issues that are identified in Code Alerts it can help to optimize your website by helping to improve your customers experience and your sites performance as a whole."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To see other ways HubSpot helps to maximize your site's potential, check out ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "our reference page on CDN, Security, and Performance."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alerts-dashboard.jpg",
        alt: "Dashboard for code alerts"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How to view code alerts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["View your code alerts for your entire portal by clicking on the Sprocket Menu from any published CMS page you are authenticated to and choosing ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "View Code Alerts"
      }), " or go directly to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/code-alerts",
        children: "Code Alerts"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/view-code-alerts-menu.png",
        alt: "View Code Alerts from the Sprocket Menu"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Asset types and issues"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asset types"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are multiple types of assets that code alerts can scan within your HubSpot CMS website. These are listed below."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Asset type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Blog Post"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A blog post from one of your HubSpot blogs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Site Page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A website page on the HubSpot CMS."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Landing Page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A website page with a specific purpose — the objective of a landing page is to convert visitors into leads."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your HubSpot blog listing page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Module"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reusable components that can be used in templates or added to pages."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Template"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Templates are reusable page or email wrappers that generally place modules and partials into a layout."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A Cascading Style Sheet file."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Knowledge Article"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An article from your HubSpot Knowledge Base."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Unknown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When an asset type is unknown."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Issues"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are multiple types of issues that your assets can have. If your asset has more than one issue it will be listed multiple times inside of your code alert dashboard."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Issue"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HubL limit"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The HubL function ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "blog_recent_tag_posts"
            }), " is used ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "11"
            }), " times on this page. ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "blog_recent_tag_posts"
            }), " has a limit of ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "5"
            }), " uses per page."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/not-found",
              children: "Certain HubL functions have limits"
            }), " to their usage. If your function exceeds its limits, you will be shown this issue."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSS combining"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There is a syntax error in a line of code that is preventing the CSS files from being combined."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Identifies CSS files that contain issues that would prevent the minification/combining of the file. There is also an option to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Show syntax errors"
            }), " that are related to this asset."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Output too big"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This page is ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "10446 Kilobytes"
            }), ". The size limit is ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "9765 Kilobytes"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The generated HTML for the page has reached more than the limit. This may result in seeing a blank or partial page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Template error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There is an error in the code that is preventing this template from being rendered."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Identifies errors in your template code that prevent the template from being rendered."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Unknown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unknown issue"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When the system has a hard time identifying the error in the asset, an unknown issue will be assigned."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How to view the issues identified for your assets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Code alerts include deep linking to assets where alerts are detected. When hovering over a row, you will be provided with the following options per asset type."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Template, page, and module assets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The actions button will provide you with a link to open the corresponding template or module in the design manager."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/my-template-open-in-dm.jpg",
        alt: "Template and Module Issues"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CSS assets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The actions button will provide you with a link to open the corresponding stylesheet in the design manager, view your file with debugging info, or show you the syntax errors."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alerts-css-options.jpg",
        alt: "CSS Issues"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When choosing “Show syntax error” you will be shown a modal window with additional details about the syntax error along with the line number where the error exists."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alert-syntax-error-details.png",
        alt: "Syntax errors details modal"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}