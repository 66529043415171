"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742960;
const slug = exports.slug = 'guides/api/marketing/subscriptions-preferences';
const title = exports.title = 'API de marketing | Preferências de assinatura';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Subscriptions Preferences';
const metaDescription = exports.metaDescription = ' Os endpoints de preferências de assinatura permitem gerenciar detalhes de assinaturas para contatos em um portal do HubSpot';
const toc = exports.toc = [{
  "depth": 0,
  "id": "obter-status-da-assinatura-do-contato",
  "label": "Obter status da assinatura do contato",
  "parent": null
}, {
  "depth": 0,
  "id": "assinar-contato",
  "label": "Assinar contato",
  "parent": null
}, {
  "depth": 0,
  "id": "cancelar-a-assinatura-do-contato",
  "label": "Cancelar a assinatura do contato",
  "parent": null
}, {
  "depth": 0,
  "id": "obter-tipos-de-assinatura",
  "label": "Obter tipos de assinatura",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Preferências da assinatura"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os tipos de assinatura representam a base jurídica para se comunicar com seus contatos por e-mail.Os contatos podem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/articles/kcs_article/email/how-to-send-contacts-an-email-with-an-opt-out-link-so-they-can-manage-their-preferences",
        children: "gerenciar suas preferências"
      }), " de e-mail para que mantenham a assinatura somente dos e-mails que desejam receber."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " a API de preferências de assinatura", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " permite recuperar ou atualizar informações de assinatura do ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/pt/inbox/collect-consent-for-whatsapp-conversations",
          children: "WhatsApp"
        }), " para um contato."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obter status da assinatura do contato"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O ponto de status da assinatura do contato permite aos usuários recuperar os status de assinatura de um endereço de e-mail em uma conta."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esse endpoint é ideal para quando você tem uma central de preferências externa ou integração e precisa saber os status de assinatura de qualquer endereço de e-mail na sua conta."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Assinar contato"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O endpoint de contato de assinatura permite assinar um endereço de e-mail para qualquer tipo de assinatura em uma conta, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mas não permite assinar os contatos que optaram por não aderir."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de caso de uso:"
      }), " esse endpoint é ideal quando você tem uma integração ou um formulário externo que precisa optar por aderir contatos em um tipo de assinatura."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Observação"
      }), ": o endpoint de contato de assinatura deve ser usado apenas para honrar solicitações de contatos que forneceram permissão para assinar. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/contacts/how-do-subscription-preferences-and-types-work",
        children: "Revise as leis e regulamentos aplicáveis"
      }), " antes de inscrever um contato."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cancelar a assinatura do contato"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O endpoint de contato do cancelamento de assinatura permite cancelar a assinatura de um endereço de e-mail em uma conta de qualquer tipo de assinatura determinado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de caso de uso:"
      }), " esse endpoint é ideal quando você tem uma integração ou um formulário externo que precisa cancelar a adesão de contatos em um tipo de assinatura."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obter tipos de assinatura"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O endpoint de informação de assinatura permite que os usuários recuperem todos os tipos de assinatura em sua conta."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de caso de uso:"
      }), " esse endpoint é ideal para quando você tem uma central de preferências externa, integração ou formulário e precisa saber os tipos de assinatura que existem em sua conta para atualizar os status de assinatura dos contatos de maneira correta."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {})]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}