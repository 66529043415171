"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 21611793414;
const slug = exports.slug = 'guides/api/analytics-and-events/email-analytics';
const title = exports.title = 'Übersicht über die E-Mail-Events-API | E-Mail-Events-API';
const name = exports.name = 'Übersicht über die E-Mail-Events-API';
const metaDescription = exports.metaDescription = 'Die E-Mail-Events-API wird verwendet, um Informationen über Events zu erhalten, die durch Marketing-E-Mails oder E-Mail-Kampagnen generiert wurden, die über einen HubSpot-Account gesendet wurden.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "event-typen",
  "label": "Event-Typen",
  "parent": null
}, {
  "depth": 1,
  "id": "einsende-events",
  "label": "Einsende-Events",
  "parent": "event-typen"
}, {
  "depth": 2,
  "id": "gr%C3%BCnde-f%C3%BCr-das-verwerfen",
  "label": "Gründe für das Verwerfen",
  "parent": "event-typen"
}, {
  "depth": 1,
  "id": "zustellungsevents",
  "label": "Zustellungsevents",
  "parent": "event-typen"
}, {
  "depth": 2,
  "id": "zugestellt",
  "label": "Zugestellt",
  "parent": "event-typen"
}, {
  "depth": 2,
  "id": "zur%C3%BCckgestellt",
  "label": "Zurückgestellt",
  "parent": "event-typen"
}, {
  "depth": 2,
  "id": "bounce",
  "label": "Bounce",
  "parent": "event-typen"
}, {
  "depth": 2,
  "id": "bounce-kategorien",
  "label": "Bounce-Kategorien",
  "parent": "event-typen"
}, {
  "depth": 1,
  "id": "events-der-benutzerinteraktion",
  "label": "Events der Benutzerinteraktion",
  "parent": "event-typen"
}, {
  "depth": 1,
  "id": "events-des-benutzerstatus",
  "label": "Events des Benutzerstatus",
  "parent": "event-typen"
}, {
  "depth": 2,
  "id": "quellen-einer-abonnement%C3%A4nderung",
  "label": "Quellen einer Abonnementänderung",
  "parent": "event-typen"
}, {
  "depth": 1,
  "id": "-unbounce%E2%80%98-events",
  "label": "'Unbounce‘-Events",
  "parent": "event-typen"
}, {
  "depth": 0,
  "id": "event-referenzen",
  "label": "Event-Referenzen",
  "parent": null
}, {
  "depth": 1,
  "id": "-sentby-",
  "label": "'sentBy'",
  "parent": "event-referenzen"
}, {
  "depth": 1,
  "id": "-obsoletedby-",
  "label": "'obsoletedBy'",
  "parent": "event-referenzen"
}, {
  "depth": 1,
  "id": "-causedby-",
  "label": "'causedBy'",
  "parent": "event-referenzen"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h3: "h3",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      img: "img",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Übersicht über die E-Mail-Events-API"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die E-Mail-Events-API wird verwendet, um Informationen über Events zu erhalten, die durch Marketing-E-Mails oder E-Mail-Kampagnen generiert wurden, die über einen HubSpot-Account gesendet wurden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Anwendungsfall für diese API:"
      }), " Wenn ein Mitglied Ihres Marketing-Teams E-Mail-Kampagnen optimieren möchte, können Sie die E-Mail-Events-API verwenden, um Daten zu sammeln und ein Modell für maschinelles Lernen zu erstellen, das den besten Zeitpunkt für das Senden von E-Mails an verschiedene Kontaktsegmente bestimmt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "E-Mail-Events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jede über HubSpot gesendete E-Mail generiert eine Reihe von Events, die ihren Lifecycle und die Interaktion des Empfängers mit ihren Inhalten beschreiben. Ein Event wird eindeutig durch seine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "EventId"
      }), " identifiziert, die sich aus den folgenden Eigenschaften zusammensetzt:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine zufällig erzeugte ID für dieses Event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "created"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "64-Bit-Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Zeitstempel (in Millisekunden seit der Epoche), zu dem dieses Event erstellt wurde."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Eigenschaften können verwendet werden, um ein bestimmtes Event über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-event-by-id",
        children: "diesen Endpunkt"
      }), " zu suchen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Außerdem haben alle Event-Typen (mit Ausnahme von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), ") die folgenden Eigenschaften:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "type"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge (Aufzählung)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Typ des Events. Mehr Informationen zu den Event-Typen finden Sie unten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "recipient"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die E-Mail-Adresse des Empfängers der E-Mail-Nachricht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "32-Bit-Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine ID, die auf den HubSpot-Account verweist, von dem die E-Mail-Nachricht gesendet wurde. Diese entspricht Ihrem Account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "32-Bit-Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine ID, die auf die HubSpot-Anwendung verweist, die die E-Mail-Nachricht gesendet hat."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appName"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name der HubSpot-Anwendung, die die E-Mail-Nachricht gesendet hat. Beachten Sie, dass dies ein abgeleiteter Wert ist, der sich jederzeit ändern kann."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "emailCampaignId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "64-Bit-Ganzzahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine ID, die auf die E-Mail-Kampagne verweist, zu der die E-Mail-Nachricht gehört. Weitere Informationen zur E-Mail-Kampagne finden Sie über ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/email-analytics#get-campaign-data-for-a-given-campaign",
              children: "diesem Endpunkt"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Events können per Massenaktion über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-events",
        children: "diesen Endpunkt"
      }), " unter Verwendung von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'recipient'"
      }), ", sowohl ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'appId'"
      }), " als auch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'campaignId'"
      }), " oder einer beliebigen Kombination der oben genannten Eigenschaften gesucht werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die folgenden zusätzlichen Eigenschaften sind ebenfalls für alle Event-Typen verfügbar (einschließlich ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), "):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "sentBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die EventId zur eindeutigen Identifizierung des Events ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), " der E-Mail-Nachricht. Falls nicht zutreffend, wird diese Eigenschaft weggelassen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "obsoletedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die EventId, die das Follow-up-Event, das dieses aktuelle Event überflüssig macht, eindeutig identifiziert. Falls nicht zutreffend, wird diese Eigenschaft weggelassen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "causedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die EventId zur eindeutigen Identifizierung des Events, das dieses Event unmittelbar verursacht hat. Falls nicht zutreffend, wird diese Eigenschaft weggelassen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Event-Verweiseigenschaften -- ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " -- werden später in diesem Dokument ausführlich behandelt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event-Typen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt 12 Event-Typen, die von der E-Mail-API von HubSpot während des Lifecycles einer E-Mail-Nachricht generiert werden können. Sie werden grob in Kategorien eingeteilt: Einsendung/Einreichung, Zustellung, Benutzerinteraktion und Benutzerstatus. Event-Typen, Event-Kategorien und ihre Beziehungen werden im Folgenden dargestellt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://static.hsappstatic.net/developer_site_web/static-2.153/img/events.svg",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Event-Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nachricht wurde an unseren Zustellungsanbieter gesendet und von diesem empfangen. Die Nachricht befindet sich in der Warteschlange für weitere Schritte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DROPPED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nachricht wurde abgelehnt, entweder von HubSpot oder von unserem Zustellungsanbieter, und es wird kein Versuch unternommen, die Nachricht zuzustellen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PROCESSED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nachricht wurde von unserem Zustellungsanbieter empfangen. Dieser hat versucht, die Nachricht an den E-Mail-Server des Empfängers weiterzuleiten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DELIVERED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der E-Mail-Server des Empfängers hat die Nachricht akzeptiert und die Nachricht wurde dem Empfänger zugestellt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DEFERRED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der E-Mail-Server des Empfängers hat diese Nachricht vorübergehend zurückgewiesen. Es werden weitere Versuche unternommen, die Nachricht zuzustellen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BOUNCE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der E-Mail-Server des Empfängers konnte oder wollte die Nachricht nicht annehmen, und es werden keine weiteren Versuche unternommen, die Nachricht zuzustellen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "OPEN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger hat die Nachricht geöffnet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CLICK"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger hat auf einen Link in der Nachricht geklickt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "STATUSCHANGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger hat seinen E-Mail-Abonnement geändert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAMREPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger hat die Nachricht als Spam markiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SUPPRESSION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nachricht wurde nicht gesendet, der Empfänger hat nicht mit früheren Marketing-E-Mails interagiert."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Einige Event-Typen, z. B. Bot-Events, werden\nmöglicherweise in der Antwort an die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/events"
        }), "-API berücksichtigt, werden aber\nnicht angezeigt, wenn Sie die Registerkarte ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
          children: [(0, _jsxRuntime.jsx)(_components.em, {
            children: "Empfänger"
          }), " für eine\nMarketing-E-Mail in Ihrem HubSpot-Account analysieren.\n"]
        }), "Dabei\nwerden bestimmte unbearbeitete Event-Typen herausfiltert und die Gesamtzahl\nder angezeigten Events auf 30 Events pro Event-Typ beschränkt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einsende-Events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn eine E-Mail-Nachricht von HubSpot im Namen eines Kunden erstellt und versendet wird, überprüfen wir zunächst, ob der Empfänger für den Empfang in Frage kommt. Ist dies nicht der Fall, wird die Nachricht abgelehnt. Dadurch wird die Erstellung eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "-Events ausgelöst. Andernfalls leiten wir sie zur weiteren Bearbeitung an unseren Zustellungsanbieter weiter. Dadurch wird ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "-Event ausgelöst. Mit einer E-Mail-Nachricht ist fast immer genau ein Absende-Event verknüpft; so gibt es z. B. nie mehrere ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "-Events für eine Nachricht."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wir bemühen uns nach Kräften, Nachrichten abzulehnen, bevor wir sie an unseren Zustellungsanbieter weiterleiten. Es kann jedoch vorkommen, dass unser Zustellungsanbieter eine Nachricht ablehnt, obwohl wir ihre Berechtigung überprüft haben. Diese Folgeablehnung führt dazu, dass zusätzlich zu dem bereits erstellten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "-Event ein weiteres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "-Event erstellt wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alle Events haben die folgenden Eigenschaften:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "subject"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Betreffzeile der E-Mail-Nachricht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "from"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das „von“-Feld der E-Mail-Nachricht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "replyTo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste von Zeichenfolgen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das „Antwort-an“-Feld der E-Mail-Nachricht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "cc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste von Zeichenfolgen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das „Cc“-Feld der E-Mail-Nachricht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bcc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste von Zeichenfolgen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das „Bcc“-Feld der E-Mail-Nachricht."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Darüber hinaus haben die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "-Events die folgenden Eigenschaften:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropReason"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge (Aufzählung)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Grund, warum die E-Mail-Nachricht verworfen wurde. Siehe unten für die möglichen Werte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropMessage"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Rohnachricht, die beschreibt, warum die E-Mail-Nachricht verworfen wurde. Dies liefert in der Regel zusätzliche Informationen über ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), " hinaus."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Gründe für das Verwerfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Grund für das Verwerfen"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_BOUNCED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine frühere Nachricht an den Empfänger hat zu einem Bounce geführt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUS_SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine frühere Nachricht an den Empfänger wurde als Spam markiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_MESSAGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger hat sich zuvor von diesem Abo abgemeldet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_PORTAL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger hat sich zuvor von allen Abonnements des Accounts abgemeldet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_TO_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die E-Mail-Adresse im Feld „An“ wurde nicht korrekt validiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_FROM_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die E-Mail-Adresse im Feld „Von“ wurde nicht korrekt validiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_DOMAIN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Domain des Empfängers war gesperrt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger hat explizit darum gebeten, keine E-Mails via HubSpot zu erhalten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "EMAIL_UNCONFIRMED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Double-Opt-in war aktiviert und der Empfänger hat das Abo noch nicht bestätigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CAMPAIGN_CANCELLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die zugewiesene E-Mail-Kampagne wurde abgebrochen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MTA_IGNORE"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Unser Zustellungsanbieter hat beschlossen, die Nachricht zu verwerfen. Alle weiteren Details werden in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropMessage'"
            }), " berücksichtigt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_OVER_LIMIT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ihr Account hat das monatliche Limit für das Versenden von E-Mails überschritten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_SUSPENDED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ihr Account wurde wegen Nichteinhaltung von Vorschriften oder Zustellbarkeitsproblemen gesperrt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "QUARANTINED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger wurde aufgrund wiederholter hoher Raten an Hard Bounces in Quarantäne gestellt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ADDRESS_LIST_BOMBED"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" Der Empfänger wurde aufgrund verdächtiger Formularaktivitäten unter Quarantäne gestellt. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/remediate-list-bombing",
              children: "Erfahren Sie mehr"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zustellungsevents"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald unser Zustellungsanbieter eine E-Mail-Nachricht akzeptiert hat, erstellen wir ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSED"
      }), "-Event. Zu diesem Zeitpunkt hat der Zustellungsanbieter die Nachricht in die Warteschlange für die Zustellung gestellt. Wenn alles nach Plan verläuft, nimmt der Zustellungsanbieter die Nachricht aus der Warteschlange und stellt sie auf dem E-Mail-Server des Empfängers zu. Dabei wird ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELIVERED"
      }), "-Event erstellt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gelegentlich läuft nicht alles wie geplant, und es gibt zwei Möglichkeiten: Die Zustellung wird aufgrund einer vorübergehenden Ablehnung zurückgestellt, oder die Zustellung schlägt fehl und wird nicht erneut versucht."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im ersten Fall konnte die Nachricht dem E-Mail-Server des Empfängers aus einem nicht schwerwiegenden (meist vorübergehenden) Grund nicht zugestellt werden, z. B. augrund einer ungewollten Zeitüberschreitung. Der Zustellungsanbieter stellt die Nachricht für eine spätere Zustellung erneut in die Warteschlange, und wir erstellen ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DEFERRED"
      }), "-Event. Eine Nachricht kann mehrmals zurückgestellt werden, bevor sie die Zustellungsphase abschließt, wobei bei jedem Versuch ein neues Event erstellt wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn die Zustellung fehlschlägt, werden keine weiteren Versuche unternommen, die Nachricht zuzustellen, und es wird ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), "-Event erstellt. Dies kann aus verschiedenen Gründen geschehen, z. B. weil der Empfänger dem E-Mail-Server unbekannt ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die spezifischen Zustellungsevent-Typen haben die folgenden Eigenschaften:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Zugestellt"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die vollständige Antwort des E-Mail-Servers des Empfängers."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "smtpId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine von HubSpot an die Nachricht angehängte ID."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Zurückgestellt"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die vollständige Antwort des E-Mail-Servers des Empfängers."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "attempt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "32-Bit-Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nummer des Zustellversuchs."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Bounce"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "category"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge (Aufzählung)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die beste Vermutung über den Typ des aufgetretenen Bounce. Wenn keine passende Kategorie ermittelt werden konnte, wird diese Eigenschaft weggelassen. Siehe unten für die möglichen Werte. Beachten Sie, dass dies ein abgeleiteter Wert ist, der jederzeit geändert werden kann, um die Genauigkeit der Klassifizierung zu verbessern."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die vollständige Antwort des E-Mail-Servers des Empfängers."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "status"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der vom E-Mail-Server des Empfängers zurückgegebene Statuscode."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Bounce-Kategorien"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Bounce-Kategorie"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNKNOWN_USER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger existierte nicht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MAILBOX_FULL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Mailbox des Empfängers war voll und daher konnten keine Nachrichten zugestellt werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CONTENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Filter des Empfängers haben die Inhalte der E-Mail als verdächtig oder als Spam identifiziert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nachricht wurde als Spam markiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "POLICY"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nachricht wurde als Spam markiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "GREYLISTING"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der E-Mail-Server erfodert einen längeren Verlauf der E-Mail-Aktivitäten des Absenders."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MAILBOX_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Es wurde eine falsche Mailbox-Konfiguration entdeckt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ISP_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Es wurde eine ISP-Falschkonfiguration festgestellt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DOMAIN_REPUTATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die sendende Domain hat eine schlechte Reputation oder eine Reputation, die nicht den Standards des Empfängerservers entspricht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DMARC"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Domain des Absenders besteht die DMARC-Prüfung nicht. Bitte überprüfen Sie Ihre SPF- und DMARC-Richtlinien."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENDING_DOMAIN_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Domain-Authentifizierung ist aufgrund einer Richtlinie auf Empfängerseite fehlgeschlagen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TIMEOUT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der empfangende E-Mail-Server hat ein Zeitlimit überschritten und nimmt keine E-Mails mehr an."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "THROTTLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der E-Mail-Server des Empfängers hat den Empfang von Nachrichten gedrosselt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNCATEGORIZED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Es wurde ein nicht kategorisierter Fehler festgestellt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "IP_REPUTATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nachricht kam ursprünglich von einer verdächtigen (oder zuvor unbekannten) IP-Adresse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DNS_FAILURE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Einstellungen des Domain Name Severs des Empfängers waren zum Zeitpunkt, an dem die E-Mail gesendet wurde, falsch konfiguriert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TEMPORARY_PROBLEM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Es ist ein vorübergehendes Problem aufgetreten."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Events der Benutzerinteraktion"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald eine E-Mail-Nachricht ihren Empfänger erreicht, können vier verschiedene Event-Typen auftreten: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PRINT"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORWARD"
      }), ". Diese stellen die Interaktion des Empfängers mit der Nachricht und ihren Inhalten dar und können jeweils mehrfach auftreten. So wird beispielsweise jedes Mal, wenn eine beliebige URL angeklickt wird, ein neues ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), "-Event erstellt, auch wenn diese URL bereits zuvor angeklickt wurde und ein solches Event erzeugt hat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alle Events der Benutzerinteraktion haben die folgenden Eigenschaften:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "userAgent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der für das Event zuständige Benutzer-Agent, z. B. „Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_4) AppleWebKit/537.36 (KHTML, wie Gecko) Chrome/28.0.1500.95 Safari/537.36“"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "browser"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein JSON-Objekt, das den Browser darstellt, der das Event unterstützt hat. Es besteht aus diesen Eigenschaften: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'name'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'family'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer_url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'type'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'version'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "location"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein JSON-Objekt, das den Ort angibt, an dem das Event aufgetreten ist. Es besteht aus diesen Eigenschaften: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'city'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'state'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'country'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "filteredEvent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein boolescher Wert, der angibt, ob das Event anhand der Einstellungen für Kundenberichte aus der Berichterstattung herausgefiltert wurde oder nicht."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Darüber hinaus haben die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), "-Events die folgenden Eigenschaften:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "url"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die URL innerhalb der Nachricht, die der Empfänger angeklickt hat."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "referer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die URL der Webseite, die mit der angeklickten URL verlinkt ist. Ob und mit welchem Wert diese angegeben wird, hängt vom E-Mail-Client des Empfängers ab."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), "-Events können die folgenden Eigenschaften haben:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "duration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "64-Bit-Ganzzahl (Millisekunden)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Falls angegeben und ungleich Null, die ungefähre Anzahl der Millisekunden, die der Benutzer die E-Mail geöffnet hat."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Events des Benutzerstatus"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Empfänger kann auch seine Kommunikationseinstellungen über die E-Mail-Nachricht aktualisieren. Indem er auf den Link für die Abonnementeinstellungen in der Nachricht klickt, kann er seine Abonnements ändern, indem er sich bei verschiedenen Listen an- oder abmeldet, was ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), "-Event auslöst. Beachten Sie, dass eine Statusänderung für jede beliebige Liste erfolgen kann, nicht nur für diejenige, die der aktuellen E-Mail-Nachricht zugeordnet ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine E-Mail-Nachricht kann auch vom Empfänger als Spam gekennzeichnet werden, was zu einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SPAMREPORT"
      }), "-Event führt. Beachten Sie, dass dies unabhängig vom Abonnementstatus ist – wenn Sie eine Nachricht als Spam kennzeichnen, wird der Empfänger nicht einfach von der betreffenden Liste abgemeldet. Vielmehr bleibt der Status des Abonnements unverändert und es wird eine Kennzeichnung gesetzt, die anzeigt, dass der Empfänger nie wieder eine E-Mail-Nachricht von HubSpot erhalten soll. Sobald dies geschieht, muss HubSpot manuell eingreifen, um die Kennzeichnung zu entfernen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), "-Event hat die folgenden zusätzlichen Eigenschaften:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "source"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge (Aufzählung)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Quelle der Abonnementänderung. Siehe unten für die möglichen Werte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "requestedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die E-Mail-Adresse der Person, die die Änderung im Namen des Empfängers anfordert. Falls nicht zutreffend, wird diese Eigenschaft weggelassen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalSubscriptionStatus"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Zeichenfolge (Aufzählung, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'SUBSCRIBED'"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ")"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Status des Portal-Abonnements des Empfängers. (Beachten Sie, dass, wenn dies ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), " ist, die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'subscriptions'"
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "nicht"
            }), " unbedingt ein leeres Array ist und auch nicht bei allen darin enthaltenen Abonnements notwendigerweise ihr Status auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), " festgelegt wird.)"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "subscriptions"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Array von JSON-Objekten, die den Status der Abonnements für den Empfänger darstellen. Jedes JSON-Abonnementobjekt besteht aus den Eigenschaften: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'id'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'status'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bounced"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Boolesch (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", oder ganz weggelassen)"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein HubSpot-Mitarbeiter hat die Statusänderung explizit veranlasst, um Nachrichten für den Empfänger zu blockieren. (Beachten Sie, dass diese Verwendung veraltet ist zugunsten des Verwerfens von Nachrichten mit einem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), " vom Typ ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOCKED\\_ADDRESS"
            }), ")"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Quellen einer Abonnementänderung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Quelle"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_LIST_UNSUBSCRIBE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger hat einen List-Unsubscribe-Header verwendet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_RECIPIENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Empfänger hat die Abonnement-Benutzeroberfläche verwendet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_IMPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Kunde hat die Abonnements in sein Portal importiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CUSTOMER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Kunde hat die Abonnement-Benutzeroberfläche verwendet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_SPAM_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Es wurde ein von einem automatischen System generierter Spam-Bericht empfangen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_NON_DELIVERY_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Es wurde eine Nichtzustellungsmeldung (normalerweise ein Bounce) empfangen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_DIRECT_COMPLAINT"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Es ging eine direkte Beschwerde über ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "abuse@hubspot.com"
            }), " ein."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MTA_RECORD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unser Zustellungsanbieter hat die Änderung während unserer normalen Synchronisierung mit seinem Datensatz vorgenommen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein HubSpot-Mitarbeiter hat die Änderung vorgenommen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MIGRATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot hat die Abonnements aus einer früheren Version des Produkts migriert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CLEANUP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot hat die Abonnements bereinigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_KNOWN_SPAM_TRAP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Diese Empfängeradresse ist eine bekannte Spam-Falle und sollte keine E-Mails erhalten."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'Unbounce‘-Events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt einen 13. Event-Typ, der nicht mit einer bestimmten E-Mail-Nachricht in Verbindung steht. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), "-Events treten auf, wenn von HubSpot für eine bestimmte E-Mail-Adresse entweder automatisch oder manuell ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "das Zurückgeben von Bounces aufgehoben wird"
      }), ". Dadurch wird der Bounce-Status des Empfängers zurückgesetzt, sodass er möglicherweise E-Mails von Ihrem Portal erhalten kann."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "user"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die E-Mail-Adresse des Benutzers, der die Unbounce-Anfrage durchgeführt hat."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event-Referenzen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Viele Events stehen im Zusammenhang mit anderen Events, die entweder vor oder nach dem Event aufgetreten sind. Wie im ersten Abschnitt oben beschrieben, verwenden wir EventIds, um diese Referenzkette zu erstellen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beachten Sie, dass Event-Referenzen relativ neu sind und bei älteren Events möglicherweise nicht vorhanden sind."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'sentBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wie bereits erwähnt, ist jeder E-Mail-Nachricht entweder ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "- oder ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "-Event (oder jeweils eines von beiden) zugeordnet. Dies ist das erste Event, das für eine bestimmte Nachricht erzeugt wird. Wenn eine Nachricht ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "-Event erzeugt, verweisen alle nachfolgend erzeugten Events über die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), " auf dieses Event."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dieser Rückwärtsverweis kann nützlich sein, um weitere Informationen zum übergeordneten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "-Event zu erhalten oder um manuell alle einer bestimmten Nachricht zugeordneten Events zu finden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'obsoletedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Manchmal tritt ein Folge-Event für eine bestimmte Nachricht auf, was bedeutet, dass ein früheres Event ignoriert werden sollte. Diese Beziehung wird durch einen Vorwärtsverweis in der Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), " angegeben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn wir zum Beispiel sowohl ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "-Event als auch ein nachfolgendes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "-Event erzeugen, ist das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "-Event letztendlich irrelevant und wird durch das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "-Event ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "ausgemustert"
      }), ". Dementsprechend verweist dann das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), "-Event über ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), " auf das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "-Event."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'causedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bestimmte Events treten gerade wegen eines vorangegangenen Events auf, oft mit einer anderen Nachricht. Diese Beziehung wird durch einen Rückverweis in der Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " festgehalten. Es kann verwendet werden, um zusätzliche Details darüber zu erhalten, warum ein bestimmtes Event das folgende Event verursacht hat."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zum Beispiel tritt ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "-Event auf, wenn es ein vorheriges ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), "-Event für denselben Empfänger gab. In diesem Fall wird für das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), "-Event der Wert ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'dropReason'"
      }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PREVIOUSLY\\_BOUNCED"
      }), " gesetzt, und der Wert ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " verweist auf das vorherige ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), "-Event."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}