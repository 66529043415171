"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400716;
const slug = exports.slug = 'guides/cms/content/data-driven-content/crm-objects';
const title = exports.title = 'Objetos do CRM no CMS Hub';
const name = exports.name = 'LATAM BR (PT) [Developers] CRM Custom Objects in CMS Hub';
const metaDescription = exports.metaDescription = 'Os objetos do CRM podem ser consultados e renderizados no conteúdo hospedado pela HubSpot, o que permite compartilhar dados entre suas operações de negócio, sites e e-mails.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tipos-de-objetos-do-crm-suportados",
  "label": "Tipos de objetos do CRM suportados",
  "parent": null
}, {
  "depth": 1,
  "id": "dados-de-objetos-do-crm-dispon%C3%ADveis-para-todas-as-p%C3%A1ginas",
  "label": "Dados de objetos do CRM disponíveis para todas as páginas",
  "parent": "tipos-de-objetos-do-crm-suportados"
}, {
  "depth": 1,
  "id": "dados-de-objetos-do-crm-dispon%C3%ADveis-para-p%C3%A1ginas-privadas",
  "label": "Dados de objetos do CRM disponíveis para páginas privadas",
  "parent": "tipos-de-objetos-do-crm-suportados"
}, {
  "depth": 0,
  "id": "exibir-dados-de-um-%C3%BAnico-registro-do-crm-com-a-fun%C3%A7%C3%A3o-crm_object",
  "label": "Exibir dados de um único registro do CRM com a função crm_object",
  "parent": null
}, {
  "depth": 0,
  "id": "exibir-dados-de-um-%C3%BAnico-registro-do-crm-com-a-fun%C3%A7%C3%A3o-crm_objects",
  "label": "Exibir dados de um único registro do CRM com a função crm_objects",
  "parent": null
}, {
  "depth": 0,
  "id": "exibir-registros-associados",
  "label": "Exibir registros associados",
  "parent": null
}, {
  "depth": 0,
  "id": "obter-os-detalhes-de-um-tipo-de-objeto-personalizado",
  "label": "Obter os detalhes de um tipo de objeto personalizado",
  "parent": null
}, {
  "depth": 0,
  "id": "campo-do-m%C3%B3dulo-de-objetos-do-crm",
  "label": "Campo do módulo de objetos do CRM",
  "parent": null
}, {
  "depth": 0,
  "id": "tutoriais-e-recursos-sobre-objetos-do-crm",
  "label": "Tutoriais e recursos sobre objetos do CRM",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h3: "h3",
      em: "em",
      h2: "h2",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      img: "img",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Usar dados de objetos do CRM no CMS Hub"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode consultar objetos do CRM para usar dados dos registros no conteúdo hospedado pela HubSpot, permitindo que os dados sejam compartilhados entre suas operações de negócios, site e e-mails. Usando as funções ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        })
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), " do HubL, você pode exibir e controlar a lógica com base nos dados do objeto do CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilizar dados do CRM no seu site significa que as suas vendas, marketing e dados do site residem todos no mesmo lugar e refletirão sempre as mesmas informações. Além disso, como você pode associar registros de CRM uns aos outros, também poderá obter os dados associados para as páginas do seu site."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da mesma forma, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "criar conjuntos de páginas dinâmicas que são geradas automaticamente usando o objeto do CRM ou os dados do HubDB"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Saiba mais sobre como criar páginas do CMS baseadas em dados no curso de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "Conteúdo baseado em dados do CMS"
        }), " no HubSpot Academy."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemplo de caso de uso"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um exemplo de utilização de dados de objeto do CRM em páginas é uma página de listagem de imóveis. Com um objeto personalizado chamado ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "propriedade"
      }), ", podem ser criados registros de objetos individuais para cada casa que precisa ser listada. Os agentes imobiliários podem então adicionar informações às propriedades do objeto para armazenar detalhes, tais como localização, número de quartos e consultas de preço."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As páginas do site podem então obter os dados de registro para cada propriedade a fim de criar uma página de listagem e páginas de detalhes para cada propriedade."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-custom-objects-example",
        children: "Confira o repositório GitHub"
      }), " para ver o exemplo completo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para uma visão geral deste exemplo, confira a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/developer-day-2020",
        children: "gravação do HubSpot Developer Day 2020"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de objetos do CRM suportados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo estão os tipos de objetos do CRM dos quais você pode extrair dados para suas páginas do CMS Hub. Você pode usar os dados em todas as páginas ou apenas em páginas privadas, dependendo do tipo de objeto."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nas tabelas abaixo, saiba quais tipos de objetos estão disponíveis para o conteúdo do CMS, além de seus nomes de tipo de objeto e nomes totalmente qualificados."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": os nomes de objetos padrão, como \"contato\", não diferenciam maiúsculas de minúsculas, mas devem estar no singular."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Dados de objetos do CRM disponíveis para todas as páginas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os dados a seguir de objetos do CRM podem ser usados em qualquer página do CMS."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Tipo de objeto"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "nome object_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nome totalmente qualificado"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "Produtos"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRODUCT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "Eventos de marketing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "marketing_event"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/custom-objects",
              children: "Objetos personalizados"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Somente o ", (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Enterprise"]
            }), ". Você pode usar o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "nome totalmente qualificado"
            }), " do objeto ou o nome que foi inserido no momento da criação. Por exemplo, se você criar um objeto chamado \"Carros\", não poderá referenciá-lo com \"carros\" ou \"Carro\".Você deverá usar o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "nome totalmente qualificado"
            }), " se o objeto personalizado tiver o mesmo nome de um objeto padrão."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Dados de objetos do CRM disponíveis para páginas privadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os dados dos objetos do CRM a seguir somente podem ser usados em páginas que exigem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/password-protect-a-page",
        children: "senha"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/require-member-registration-to-access-private-content",
        children: "login de membro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Tipo de objeto"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "nome object_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "NOME TOTALMENTE QUALIFICADO"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Contatos"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "Empresas"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPANY"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "Negócios"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DEAL"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/tickets",
              children: "Tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TICKET"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes",
              children: "Orçamentos"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUOTE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Objetos integradores"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para obter o nome de um objeto integrador, use a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "API do esquema de objetos do CRM"
            }), ".Para objetos integradores que têm nomes idênticos aos dos objetos padrão, use o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "nome totalmente qualificado"
            }), " do objeto integrador."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exibir dados de um único registro do CRM com a função crm_object"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a função ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), " para obter um único registo do HubSpot CRM por consulta ou por ID de registo do CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os registros de objeto são retornados como um dicionário de propriedades e valores."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom object by query #}\n{% set event = crm_object(\"event\", \"name=Defensive Health\") %}\n{{ event.name }}\n\n{# Render custom objects specifying the id of the object #}\n{% set event = crm_object(\"event\", 289236) %}\n{{ event.name }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>Defensive Heatlh</p>\n\n<p>Defensive Heatlh</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Se uma consulta retornar um conjunto de registros, a função retornará o primeiro registro do conjunto."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exibir dados de um único registro do CRM com a função crm_objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a função ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), " para obter registros do CRM por tipo de objeto do HubSpot CRM por consulta ou por ID de registo. Os registros são retornados como um dicionário de propriedades e valores."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O registro retornado contém uma propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " que pode ser executada em loop para exibir as informações dos itens do registro."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom objects by query #}\n{% set events = crm_objects(\"event\", \"limit=3&type=virtual\") %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n\n{# Render custom objects by ids #}\n{% set events = crm_objects(\"event\", [289236,289237,289238]) %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  3 New Events:\n  <h3>\n    <ul>\n      <li>Name: Defensive Health</li>\n      <li>Name: Body Balance</li>\n      <li>Name: Happy Heart</li>\n      <ul>\n        <h3>\n          3 New Events:\n          <h3>\n            <ul>\n              <li>Name: Defensive Health</li>\n              <li>Name: Body Balance</li>\n              <li>Name: Happy Heart</li>\n              <ul></ul>\n            </ul>\n          </h3>\n        </h3>\n      </ul>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exibir registros associados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a função HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), " para obter uma lista de registros associados do HubSpot CRM com base no ID do registro, categoria de associação e ID de definição de associação.", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#getting-a-custom-object-s-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os registros são retornados como um dicionário de propriedades e valores."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set associated_objects = crm_associations(289236, \"USER_DEFINED\", 3) %}\n<h3>Contacts Associated With Event</h3>\n<ul>\n{% for contact in associated_objects.results %}\n\t<li>Name: {{ contact.firstname }} {{ contact.lastname }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  Contacts Associated With Event\n  <h3>\n    <ul>\n      <li>Name: Brian Halligan</li>\n\n      <li>Name: Dharmesh Shah</li>\n\n      <li>Name: Yamini Rangan</li>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obter os detalhes de um tipo de objeto personalizado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "nome"
      }), ", o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fullyQualifiedName"
      }), ", os IDs de associação e outros detalhes de um tipo de objeto personalizado, você pode fazer uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/crm/objects/custom-objects",
        children: "API do esquema de objetos do CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), " para tipos de objetos específicos da conta inclui o ID da conta da HubSpot; por isso, é recomendado evitar usá-lo ao desenvolver código para várias contas da HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campo do módulo de objetos do CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para fornecer uma forma de os criadores de conteúdo selecionarem registros do CRM para exibir ou executar lógica, você pode criar módulos que incluam o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/en/docs/cms/building-blocks/module-theme-fields#crm-object",
        children: "campo de objeto do CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, você pode querer exibir informações de um produto específico, contato, empresa, negócio, orçamento, ticket ou objeto personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/CRM%20Object%20Field.png",
        alt: "Campo de objeto do CRM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriais e recursos sobre objetos do CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/essentials-for-getting-started-with-custom-objects",
          children: "Introdução aos Objetos personalizados"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-think-like-an-architect-by-building-scalable-custom-objects",
          children: "Pense como um arquiteto: crie objetos personalizados escaláveis"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/crm-objects",
          children: "Crie páginas dinâmicas com objetos do CRM"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}