"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 179570070536;
const slug = exports.slug = 'reference/ui-components/standard-components/charts/overview';
const title = exports.title = 'Charts overview | UI components (BETA)';
const name = exports.name = 'Charts overview | UI components (BETA)';
const metaDescription = exports.metaDescription = 'Learn about the available chart components for visualizing data in UI extensions.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "formatting-data",
  "label": "Formatting data",
  "parent": null
}, {
  "depth": 1,
  "id": "datetime-values",
  "label": "datetime values",
  "parent": "formatting-data"
}, {
  "depth": 1,
  "id": "data-options",
  "label": "Data options",
  "parent": "formatting-data"
}, {
  "depth": 0,
  "id": "configuring-axes",
  "label": "Configuring axes",
  "parent": null
}, {
  "depth": 1,
  "id": "stacking",
  "label": "Stacking",
  "parent": "configuring-axes"
}, {
  "depth": 0,
  "id": "chart-options",
  "label": "Chart options",
  "parent": null
}, {
  "depth": 0,
  "id": "colors",
  "label": "Colors",
  "parent": null
}, {
  "depth": 1,
  "id": "default-color-set",
  "label": "Default color set",
  "parent": "colors"
}, {
  "depth": 0,
  "id": "design-guidelines",
  "label": "Design guidelines",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code",
      h2: "h2",
      h3: "h3",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Charts overview | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use charts to display data visualizations in UI extensions. HubSpot provides two chart components: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/bar-chart",
        children: "BarChart"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/line-chart",
        children: "LineChart"
      }), ". Both components use the same API, but the each type is better suited for different types of data. For example, a bar chart is generally recommended for comparing categorical data, while a line chart is recommended for time series plots or visualizing trends. To see an example of how to implement charts in a UI extension, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#create-line-and-bar-charts",
        children: "HubSpot's Charts example projects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about creating custom charts with UI extensions by watching ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.youtube.com/watch?v=5P6WuKyOiDE",
        children: "this video"
      }), " on the HubSpot Developers YouTube channel."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "On this page:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#formatting-data",
          children: "Formatting data"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#configuring-axes",
          children: "Configuring axes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#stacking",
          children: "Stacking"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#chart-options",
          children: "Chart options"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#colors",
          children: "Colors"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#design-guidelines",
          children: "Design guidelines"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { BarChart, LineChart } from '@hubspot/ui-extensions';\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For both types of charts, there are three main props:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "data"
        }), ": an object containing the chart data, with additional ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#formatting-data",
          children: "data formatting"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "axes"
        }), ": an object that specifies for the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "x"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "y"
        }), " axes, with additional ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#configuring-axes",
          children: "configuring axes"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), ": an object that specifies options for the chart, such as showing data labels and tooltips. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#chart-options",
          children: "chart options"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Formatting data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Data should be provided to a chart component in an array of objects containing key-value pairs, matching the following format ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{string: number | string}"
      }), ". Data will be displayed in the order it's provided to the component, so you will need to sort data beforehand if necessary. For example, to display data over time in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LineChart"
      }), ", you should sort the data in ascending/descending order of your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "datetime"
      }), " axis field before passing it to the chart component."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example data array\n[\n  {\n    type: 'referral',\n    count: 35,\n    location: 'location_A',\n  },\n  {\n    type: 'direct',\n    count: 12,\n    location: 'location_B',\n  },\n];\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When building out a chart, keep the following in mind:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A chart can only graph one dataset, so you'll need multiple charts if you're working with multiple datasets."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For performance and readability, it's recommended for a chart to include no more than a few hundred entries, depending on the data. When working with larger datasets, it's important to consider the information you want to convey with the chart. You'll likely encounter issues with visual clarity before you encounter performance issues. For example, a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "BarChart"
        }), " with hundreds of bars on it will likely not be readable even if it renders quickly."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Chart components do not support nested fields in data. Rather, all fields will need to be stored at the same level. For example, the following data format is not supported because it introduces a secondary level of data in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "type"
        }), " field."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example data array\n[\n  {\n    type: {\n      value: 'referral',\n      subType: 'subtypeValue',\n    },\n    count: 35,\n    location: 'location_A',\n  },\n];\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "datetime values"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For charts that include datetime data, you can use the following formats:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Unix millisecond timestamp (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1758791725"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ISO 8601 timestamp (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2025-09-25T09:15:25+0000"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ISO 8601 date (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2024-09-25"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Data options"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["It's recommended to pre-format your data into human-readable text so that it doesn't need any additional relabeling. However, if there are times when you can't pre-format certain values in your data, you can include an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), " field in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data"
      }), " prop to set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyLabels"
      }), ". Including ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), " will slightly change the way you format ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When only including a dataset array, you'll format the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "data"
        }), " prop as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "data={dataArray}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When including ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), ", you'll need to format the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "data"
        }), " prop as an object containing both ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "data"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), " fields as shown below. In ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), ", you'll include a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyLabels"
        }), " object, which then contains an object for each field and labels for each value."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the following chart is configured to relabel the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealstage"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "user_id"
      }), " values."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<BarChart\n  data={{\n    data: dealCountSample,\n    options: {\n      propertyLabels: {\n        dealstage: {\n          appointmentScheduled: 'Appointments scheduled (Sales)',\n          closedWon: 'Closed won (Sales)',\n         },\n         user_id: {\n           '194784': 'Sales user A',\n           '295834': 'Sales user B',\n           '938453': 'Sales user C',\n         },\n       },\n      },\n  }}\n  axes={{\n    x: {\n      field: 'dealstage',\n      fieldType: 'category',\n      label: 'Deal Stage',\n    },\n    y: {\n      field: 'count',\n      fieldType: 'linear',\n      label: 'Count of Deals',\n     },\n    options: { groupFieldByColor: 'user_id', stacking: true },\n  }}\n  options={{\n    showLegend: true,\n    showDataLabels: true,\n    showTooltips: true,\n  }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuring axes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "axes"
      }), " prop configures the chart's axes. Charts can have two axes (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "x"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "y"
      }), "), and each axis is configured by a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "field"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " parameter. By default, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "field"
      }), " value will be used as the axis label, but you can also include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " parameter to set it separately."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " one axis must have a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fieldType"
        }), " of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "linear"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<BarChart\n  axes={{\n    x: { field: 'Product', fieldType: 'category' },\n    y: { field: 'Amount', fieldType: 'linear', label: '# of sales' },\n  }}\n/>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "field"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the field from the dataset."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "fieldType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of data in the field. You can specify one of the following types:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "category"
                }), ": data that can be bucketed into categories or types, such as different types of products."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "datetime"
                }), ": date and time data. Charts support the following datetime formats:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: ["JavaScript timestamp (", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "1758791725"
                    }), ")"]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: ["ISO 8601 timestamp (", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "2025-09-25T09:15:25+0000"
                    }), ")"]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: ["ISO 8601 date (", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "2024-09-25"
                    }), ")"]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "linear"
                }), ": numerical data, such as quantity. One of the axes must have this ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "fieldType"
                }), "."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The label to display on the axis. If not specified, the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "field"
            }), " value will be used instead."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Additional configuration options for the axes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "groupFieldByColor"
                }), " (string): specify a ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "field"
                }), " to group by color. When not specified, only one color will be used for data visualization."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "stacking"
                }), " (boolean): when set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", grouped data will be stacked. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following bar chart displays sales data by type of product and count of sales. To add visual clarity, each bar is assigned a different ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#colors",
        children: "color"
      }), " via the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), " parameter in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/simple-bar-chart-example-sales.png",
        alt: "simple-bar-chart-example-sales"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const dailyInventorySample = [\n  {\n    Product: 'Hats',\n    Amount: 187,\n  },\n  {\n    Product: 'Socks',\n    Amount: 65,\n  },\n  {\n    Product: 'Ascots',\n    Amount: 120,\n  },\n];\nreturn (\n  <BarChart\n    data={dailyInventorySample}\n    axes={{\n      x: { field: 'Product', fieldType: 'category' },\n      y: { field: 'Amount', fieldType: 'linear' },\n      options: {\n        groupFieldByColor: 'Product',\n      },\n    }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Stacking"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "stacking"
      }), " axes option to stack data by group. For example, the following bar chart displays the number of deals by deal stage. The data also includes the sales rep who owns each deal. To visually distinguish sales reps in each column, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "stacking"
      }), " has been set to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/bar-chart-stacked-data.png",
        alt: "bar-chart-stacked-data"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const Extension = ({ context }) => {\n  const dealCountSample = [\n    {\n      count: 1,\n      dealstage: 'appointmentScheduled',\n      user_id: '194784',\n    },\n    {\n      count: 2,\n      dealstage: 'closedWon',\n      user_id: '295834',\n    },\n    {\n      count: 1,\n      dealstage: 'closedWon',\n      user_id: '938453',\n    },\n  ];\n\n  return (\n    <BarChart\n      data={{\n        data: dealCountSample,\n        options: {\n          propertyLabels: {\n            dealstage: {\n              appointmentScheduled: 'Appointments scheduled (Sales)',\n              closedWon: 'Closed won (Sales)',\n            },\n            user_id: {\n              194784: 'Sales user A',\n              295834: 'Sales user B',\n              938453: 'Sales user C',\n            },\n          },\n        },\n      }}\n      axes={{\n        x: {\n          field: 'dealstage',\n          fieldType: 'category',\n          label: 'Deal Stage',\n        },\n        y: {\n          field: 'count',\n          fieldType: 'linear',\n          label: 'Count of Deals',\n        },\n        options: { groupFieldByColor: 'user_id', stacking: true },\n      }}\n      options={{\n        showLegend: true,\n        showDataLabels: true,\n        showTooltips: true,\n      }}\n    />\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Compare the above visualization to the version below without stacking:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/bar-chart-no-stacking-comparison.png",
        alt: "bar-chart-no-stacking-comparison"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Similarly, you can use stacking in line charts, as shown in the example chart below. This chart measures website visits by date, broken down by source. Stacking in this example helps to emphasize volume over time."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/line-chart-stacked-data.png",
        alt: "line-chart-stacked-data"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const visitsPerSourceOverTime = [\n  {\n    'Session Date': '2019-09-01',\n    Breakdown: 'Direct',\n    Visits: 1277,\n  },\n  {\n    'Session Date': '2019-09-01',\n    Breakdown: 'Referrals',\n    Visits: 1882,\n  },\n  {\n    'Session Date': '2019-09-01',\n    Breakdown: 'Email',\n    Visits: 1448,\n  },\n  {\n    'Session Date': '2019-09-02',\n    Breakdown: 'Direct',\n    Visits: 1299,\n  },\n  {\n    'Session Date': '2019-09-02',\n    Breakdown: 'Referrals',\n    Visits: 1869,\n  },\n  {\n    'Session Date': '2019-09-02',\n    Breakdown: 'Email',\n    Visits: 1408,\n  },\n  {\n    'Session Date': '2019-09-03',\n    Breakdown: 'Direct',\n    Visits: 1357,\n  },\n  {\n    'Session Date': '2019-09-03',\n    Breakdown: 'Referrals',\n    Visits: 1931,\n  },\n  {\n    'Session Date': '2019-09-03',\n    Breakdown: 'Email',\n    Visits: 1391,\n  },\n];\n\nreturn (\n  <LineChart\n    data={visitsPerSourceOverTime}\n    axes={{\n      x: { field: 'Session Date', fieldType: 'category' },\n      y: { field: 'Visits', fieldType: 'linear' },\n      options: { groupFieldByColor: 'Breakdown', stacking: true },\n    }}\n    options={{ showLegend: true }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Compare the above visualization to the version below without stacking:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/line-chart-no-stacking-comparison.png",
        alt: "line-chart-no-stacking-comparison"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Chart options"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), " prop, you can configure a chart with options such as displaying a chart title, legend, data labels, or specifying the color list."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/numbered-chart-options-diagram.png",
        alt: "numbered-chart-options-diagram"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Title:"
          }), " the title of the chart."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Legend:"
          }), " lists the data categories with their corresponding color for readability."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Data labels:"
          }), " labels for data points."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Tooltips:"
          }), " displays details for data points on hover."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/barchart-component-with-tooltip.png",
            alt: "barchart-component-with-tooltip"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<BarChart\n  data={dailyInventorySample}\n  axes={{\n    x: { field: 'Product', fieldType: 'category' },\n    y: { field: 'Amount', fieldType: 'linear' },\n    options: { groupFieldByColor: 'Product' },\n  }}\n  options={{\n    title: 'My Chart',\n    showTooltips: true,\n    showLegend: true,\n    showDataLabels: true,\n    colorList: ['darkGreen', 'darkPurple', 'blue'],\n  }}\n/>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The title of the chart."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "showTooltips"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", displays tooltips for data points on hover. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "showLegend"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", displays a legend above the table to help users understand the data. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "showDataLabels"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", displays labels above data points for readability. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colorList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array of strings specifying the order that colors should be used in the chart. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#colors",
              children: "colors"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Colors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, HubSpot will apply colors to chart bars or lines using a default set of colors when ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), " is specified in axes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), ". You can customize these colors in two ways:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To customize the order of colors selected by HubSpot, include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "colorList"
        }), " field in the top-level ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), " prop, then specify the colors you want to prioritize."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To apply colors to specific values of the field specified in ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "groupFieldByColor"
        }), ", include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "colors"
        }), " field within the axes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the chart below is configured to apply colors to data in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Product"
      }), " field using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), ". The three colors (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "darkGreen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blue"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "darkPurple"
      }), ") will be applied first, then the standard color order will be applied to any additional bars."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/bar-chart-color-configurations_2.png",
        alt: "bar-chart-color-configurations_2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const dailyInventorySample = [\n  {\n    Product: 'Standalone product A',\n    Sales: 159,\n  },\n  {\n    Product: 'Bundle A',\n    Sales: 53,\n  },\n  {\n    Product: 'Bundle B',\n    Sales: 99,\n  },\n];\nreturn (\n  <BarChart\n    data={dailyInventorySample}\n    axes={{\n      x: { field: 'Product', fieldType: 'category' },\n      y: { field: 'Amount', fieldType: 'linear' },\n      options: { groupFieldByColor: 'Product' },\n    }}\n    options={{\n      colorList: ['darkGreen', 'blue', 'darkPurple'],\n    }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If instead you want to manually apply colors to specific field values, rather than have HubSpot assign colors in order, you could instead include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "colors"
      }), " field in the axes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), ". In colors, you'll need to specify each value from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), " field to assign a color to."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/bar-chart-with-field-specific-colors.png",
        alt: "bar-chart-with-field-specific-colors"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const dailyInventorySample = [\n  {\n    Product: 'Standalone product A',\n    Sales: 159,\n  },\n  {\n    Product: 'Bundle A',\n    Sales: 53,\n  },\n  {\n    Product: 'Bundle B',\n    Sales: 99,\n  },\n];\nreturn (\n  <BarChart\n    data={dailyInventorySample}\n    axes={{\n      x: { field: 'Product', fieldType: 'category' },\n      y: { field: 'Sales', fieldType: 'linear' },\n      options: {\n        groupFieldByColor: 'Product',\n        colors: {\n          'Standalone product A': 'blue',\n          'Bundle A': 'orange',\n          'Bundle B': 'yellow',\n        },\n      },\n    }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Default color set"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are the available colors in their default order."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Color"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Hex value"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Swatch"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "orange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#fea58e"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-orange-png-2.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "aqua"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#51d3d9"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-aqua-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "purple"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#bda9ea"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-purple-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "yellow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#f5c78e"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-yellow-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pink"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#ea90b1"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-pink-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blue"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#81c1fd"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-blue-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "green"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#a4d398"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-green-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "darkOrange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#c3705c"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-dark-orange-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "darkAqua"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#009ca2"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-dark-aqua-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "darkPurple"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#8775b2"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-dark-purple-1-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "darkYellow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#bb915b"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-dark-yellow-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "darkPink"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#b05c7d"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-dark-purple-png-1.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "darkBlue"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#468cc4"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-dark-blue-png-2.png",
              alt: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "darkGreen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "#6b9a5b"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/chart-color-dark-green-png-1.png",
              alt: "null"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Design guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " title your data categories with human-readable text so they are easy to understand."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " use sentence-casing for the data categories and chart title (only first letter capitalized)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " sort your data in ascending/descending order of your x-axis field to prevent unordered rendering prior to passing it to a charting component. If you intend to display information over time (such as in a LineChart), your data will be displayed in the order you provide it."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " display the chart legend if you’re graphing more than one category of data. This prevents your users from having to rely only on color to identify different data on your chart."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " for readability, use larger surfaces to showcase charts, such as the record page middle column. Avoid using charts with many data points on smaller surfaces such as the preview panel or sidebar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON’T:"
        }), " use more than 14 data categories unless it cannot be avoided for your use case."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON’T:"
        }), " use the same colors to indicate different data categories."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}