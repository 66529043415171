"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400705;
const slug = exports.slug = 'guides/cms/debugging/troubleshooting';
const title = exports.title = 'Depuração e erros';
const name = exports.name = 'PT-BR Debugging and Errors';
const metaDescription = exports.metaDescription = 'Compreenda como depurar e analisar erros ao desenvolver sites no CMS HubSpot. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/fatal%20error%20-%20CMS%20CLI.png';
const featuredImageAltText = exports.featuredImageAltText = 'Error publishing template';
const toc = exports.toc = [{
  "depth": 0,
  "id": "erros",
  "label": "Erros",
  "parent": null
}, {
  "depth": 1,
  "id": "erros-fatais",
  "label": "Erros fatais",
  "parent": "erros"
}, {
  "depth": 1,
  "id": "avisos",
  "label": "Avisos",
  "parent": "erros"
}, {
  "depth": 0,
  "id": "modo-de-depura%C3%A7%C3%A3o-em-p%C3%A1ginas-ativas",
  "label": "Modo de depuração em páginas ativas",
  "parent": null
}, {
  "depth": 0,
  "id": "modo-de-desenvolvedor-no-editor-de-p%C3%A1gina",
  "label": "Modo de desenvolvedor no editor de página",
  "parent": null
}, {
  "depth": 0,
  "id": "exibir-sa%C3%ADda-hubl",
  "label": "Exibir saída HubL",
  "parent": null
}, {
  "depth": 0,
  "id": "filtro-",
  "label": "Filtro ",
  "parent": null
}, {
  "depth": 0,
  "id": "informa%C3%A7%C3%B5es-do-desenvolvedor",
  "label": "Informações do desenvolvedor",
  "parent": null
}, {
  "depth": 0,
  "id": "analisar-o-desempenho-do-site-e-links-quebrados",
  "label": "Analisar o desempenho do site e links quebrados",
  "parent": null
}, {
  "depth": 0,
  "id": "melhorar-a-velocidade-do-site",
  "label": "Melhorar a velocidade do site",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      img: "img",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab,
      CTA
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Depuração e erros"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depurar código e compreender onde e como visualizar erros é uma parte importante do desenvolvimento no HubSpot CMS. Existem várias ferramentas que você pode usar para aumentar a eficiência na construção e depuração e para garantir que o seu site seja otimizado à medida que você continua a construí-lo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/overview#developer-file-system",
        children: "sistema de arquivos do desenvolvedor"
      }), " do HubSpot CMS tem muitas formas de validação para garantir que seus modelos e módulos sejam renderizados corretamente nas páginas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Erros fatais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os erros fatais são erros que impedem uma página de ser renderizada com sucesso. Para garantir que o conteúdo dinâmico seja renderizado corretamente, o HubSpot CMS evita a publicação de modelos que tenham erros fatais. Um exemplo de um erro fatal seria a falta de variáveis obrigatórias do HubL, tais como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), ". Isso causará erros ao desenvolver no Gerenciador de design ou ao fazer upload de arquivos por meio da CLI do CMS. A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "Extensão do VS Code"
      }), " suporta linting do HubL e pode exibir os erros fatais em contexto antes de fazer upload do arquivo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20design%20manager.png",
        alt: "captura de tela de um erro fatal - gerenciador de design"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20CMS%20CLI.png",
        alt: "captura de tela de um erro fatal - CLI do CMS"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os erros fatais devem ser resolvidos para que os arquivos sejam publicados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Avisos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os avisos são erros ou problemas que não impedem a publicação de arquivos. Os avisos são muitas vezes sugestões de sintaxe ou problemas em potencial que um desenvolvedor deixou passar. A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "Extensão do VS Code"
      }), " suporta linting do HubL e pode exibir os avisos em contexto antes de fazer upload do arquivo. Por exemplo, se você tentar incluir um arquivo que não existe, isso gera um aviso para alertar o desenvolvedor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/warning%20-design%20manager.png",
        alt: "aviso - gerenciador de design"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os avisos nunca impedirão a publicação de arquivos, no entanto, é recomendável investigá-los."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modo de depuração em páginas ativas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode ativar o modo de depuração em uma página ativa carregando a página com uma sequência de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), " na URL."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " o modo de depuração não pode ser usado em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#system-pages",
          children: "páginas do sistema"
        }), ", como as páginas 404 e de senha."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao carregar uma página ativa com esta string de consulta, a página será renderizada:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "com arquivos não minificados."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "com arquivos CSS não combinados (arquivos CSS individuais entregues)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "sem colocar os arquivos em cache."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além disso, quando você carrega uma página com ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), ", as informações de depuração serão adicionadas à parte inferior do código-fonte da página, incluindo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se a página pode ser ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "pré-renderizada"
        }), " e os motivos pelos quais ela não pode ser pré-renderizada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um detalhamento do tempo de solicitação de renderização, que pode ser útil para saber quais componentes da página levam mais tempo para renderizar. Esse detalhamento também será adicionado à guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Tempo"
        }), " no console do desenvolvedor do navegador nas solicitações ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Doc"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/timing-tab-request-details.png",
        alt: "timing-tab-request-details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erros e avisos, como limites de função HubL ou arquivos ausentes."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/debug-page-source-elements-tab.png",
        alt: "debug-page-source-elements-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modo de desenvolvedor no editor de página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode carregar o editor de páginas no HubSpot com a string de consulta para habilitar recursos do desenvolvedor, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#copy-section-hubl",
        children: "copiar seções como HubL"
      }), " e a capacidade de abrir módulos específicos no gerenciador de design a partir do editor de página."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No editor de página, adicione o seguinte parâmetro à URL e pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?developerMode=true"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Com a página recarregada, agora você estará no modo de desenvolvedor. Você pode sair do modo de desenvolvedor a qualquer momento clicando em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sair do modo de desenvolvedor"
        }), " no canto superior direito."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-top-navigation-bar.png",
        alt: "developer-mode-top-navigation-bar"
      }), "No modo de desenvolvedor, você pode navegar para o código de um módulo específico clicando no módulo associado e em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Abrir no gerenciador de design"
      }), " no editor da barra lateral."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-open-design-manager-for-module.png",
        alt: "developer-mode-open-design-manager-for-module"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você também pode redefinir quaisquer alterações não publicadas para o conteúdo padrão do modelo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Conteúdo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["À direita do nome do modelo, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Redefinir conteúdo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-reset-contents.png",
        alt: "developer-mode-reset-contents"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa de diálogo, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sim, redefinir"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exibir saída HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dentro do Gerenciador de design, os arquivos codificados têm a opção \"Mostrar saída\", que abre um segundo painel de editor de código com o código transposto do arquivo que está sendo exibido. Isso é útil para ver como seu código HubL será transposto para CSS, HTML ou JavaScript, em vez de recarregar as páginas nas quais o arquivo está incluído. Também é uma ferramenta útil ao explorar novas funcionalidades do HubL, ou aprender conceitos básicos sobre o HubL, pois você pode ver facilmente o que a sua entrada do HubL irá gerar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/View%20HubL%20output.png",
        alt: "Exibir saída HubL"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h2, {
      children: ["Filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), " do HubL"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), " do HubL pode ser usado em variáveis do HubL para imprimir informações valiosas de depuração. Ele imprimirá o tipo de variável do HubL, que pode ser útil para entender com que expressões, filtros, operadores ou funções ela pode ser usada."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ local_dt }}"
      }), " imprimirá ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2020-02-21 12:52:20"
      }), ". Se imprimirmos esta variável, poderemos ver que o valor é uma data ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "(PyishDate: 2020-02-21 12:52:20)"
      }), ". Isso significa que podemos usar filtros do HubL que operam ou formatam objetos de data, tais como o filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|datetimeformat"
      }), " do HubL."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|pprint }}\n{{ local_dt|datetimeformat('%B %e, %Y') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2020-02-21 12:55:13 (PyishDate: 2020-02-21 12:55:13) February 21, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informações do desenvolvedor"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Muitos dos dados encontrados nas informações do desenvolvedor são usados internamente e estão sujeitos a alterações se não forem documentados de outra forma."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A informação do desenvolvedor de uma página é o contexto de todos os dados disponíveis quando uma página está sendo renderizada. Este contexto de renderização pode ser acessado por meio do HubL. Para acessar as informações do desenvolvedor de uma página, selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ícone da roda dentada da HubSpot no canto superior direito das páginas > Informações do desenvolvedor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20sprocket%20menu.png",
        alt: "Menu de roda dentada de informações para o desenvolvedor"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Isso abrirá uma nova aba que retorna o contexto de renderização para uma determinada página na forma de JSON. É recomendado ter um formatador JSON instalado no seu navegador para facilitar a leitura das informações do desenvolvedor, como esta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa",
        children: "extensão de formatador JSON para Chrome"
      }), ". Embora grande parte da informação contida no contexto da página seja para fins internos, esta ferramenta pode ser valiosa para ver quais dados são disponibilizados por HubL ao criar modelos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, a imagem a seguir mostra a informação do desenvolvedor para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "https://desigers.hubspot.com/docs/developer-reference/cdn"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20example.png",
        alt: "Exemplo de informações do desenvolvedor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os valores desses dados são definidos pela guia Configurações do editor de conteúdo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/content%20editor%20-%20settings.png",
        alt: "editor de conteúdo - configurações"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os valores são então disponibilizados para renderização nas páginas por meio do HubL. Para imprimir o título e a metadescrição em um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "modelo básico"
      }), ", você usa o HubL a seguir."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<title>{{ page_meta.html_title }}</title>\n<meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os dados no contexto de renderização são disponibilizados por meio do HubL, e a árvore JSON pode ser analisada utilizando notação de pontos. Os dados nas informações do desenvolvedor que os desenvolvedores frequentemente imprimem incluem valores de campos de módulos e tags que foram ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "exportados para o contexto do modelo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Analisar o desempenho do site e links quebrados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["É importante verificar se os visitantes do seu site não estão sendo direcionados para links quebrados. Há duas ferramentas que você pode usar para ajudar a garantir que os visitantes do seu site cheguem ao lugar certo. Você pode usar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/overview",
        children: "API de desempenho de sites"
      }), " para obter os status HTTP, como erros 404, e ver o tempo de atividade dos sites."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você receber erros 404, talvez seja bom redirecionar o visitante para uma URL relevante."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode usar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/seo/view-seo-recommendations-in-hubspot",
        children: "ferramenta Recomendações de SEO"
      }), " para identificar links quebrados dentro do conteúdo da sua página e corrigi-los rapidamente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Melhorar a velocidade do site"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há muitos fatores que influenciam a optimização e o teste da velocidade do site. Para ver ferramentas e dicas para otimizar a velocidade do seu site, consulte nosso guia."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "841bbe22-70a6-4222-a6f0-85dd019711ae",
      label: "Optimize your CMS Hub site speed"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}