"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 174074731179;
const slug = exports.slug = 'guides/apps/public-apps/share-hubspot-reports-with-your-app';
const title = exports.title = 'Use your installed app to share HubSpot reports to channels and users (BETA)';
const name = exports.name = 'vNext Docs DP - Use your installed app to share HubSpot reports to channels and users (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to set up a feature so that any customer who has installed your app in their account can share HubSpot reports and dashboards with it. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "set-up-the-share-with-feature",
  "label": "Set up the Share With feature",
  "parent": null
}, {
  "depth": 0,
  "id": "check-permissions-endpoint",
  "label": "Check permissions endpoint",
  "parent": null
}, {
  "depth": 0,
  "id": "fetch-recipients-endpoint",
  "label": "Fetch recipients endpoint",
  "parent": null
}, {
  "depth": 0,
  "id": "fetch-recipient-endpoint",
  "label": "Fetch recipient endpoint",
  "parent": null
}, {
  "depth": 0,
  "id": "share-object-endpoint",
  "label": "Share object endpoint",
  "parent": null
}, {
  "depth": 0,
  "id": "test-the-functionality",
  "label": "Test the functionality",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      em: "em",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Use your installed app to share HubSpot reports to channels and users"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn how to set up the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Share With"
      }), " feature to allow your public app to share HubSpot reports and recurring updates to specific channels and users. This means you'll be able to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/dashboard/email-or-export-reports-and-dashboards",
        children: "share your HubSpot reports and dashboards"
      }), " with the installed app. This feature is only available for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "public apps"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up the Share With feature"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before you set up your app to use the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Share With"
      }), " feature, you'll need to create and host four API endpoints that will accept ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " requests from HubSpot when a customer who has installed your app shares their HubSpot reports and dashboards."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You'll need to set up the following endpoints to handle the four webhook event triggers:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CHECK_PERMISSIONS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FETCH_RECIPIENTS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FETCH_RECIPIENT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SHARE_OBJECT"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Requests are sent with the HubSpot Signature. To ensure that the requests that are received are from HubSpot, they can be validated using the signature: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "https://developers.hubspot.com/docs/api/webhooks/validating-requests"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Check permissions endpoint"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The URL of the endpoint that returns account data should be structured like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://BASE_PATH_OF_YOUR_SERVICE/feature/SHARE_VIA/event/CHECK_PERMISSIONS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will provide the following fields in the body of the request:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body that HubSpot will send your hosted service\n{\n  \"portalId\": 10,\n  \"appId\": 20,\n  \"payload\": {\n    \"requiredPermissions\": [\"upload-file\"]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response you return when HubSpot makes a POST request to the endpoint should be a JSON-formatted object with the following fields:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example response you should return after a POST request from HubSpot\n{\n  \"portalId\": 10,\n  \"appId\": 20,\n  \"eventType\": \"CHECK_PERMISSIONS\",\n  \"payload\": {\n    \"hasAllRequiredPermissions\": true\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fetch recipients endpoint"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The URL of the endpoint that returns account data should be structured like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://BASE_PATH_OF_YOUR_SERVICE/feature/SHARE_VIA/event/FETCH_RECIPIENTS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will provide the following fields in the body of the request:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 10,\n  \"appId\": 20,\n  \"payload\": {\n    \"recipientsFilter\": {\n      \"type\": 1, // integer: 0 for Channel or 1 for User\n      \"name\": \"Recipient\" // substring which denotes filtering by name\n    },\n    \"limit\": 5,\n    \"nextPageAfter\": \"hashkey\" //string hashkey to denote the next page\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response you return when HubSpot makes a POST request to the endpoint should be a JSON-formatted object with the following fields:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 10,\n  \"appId\": 20,\n  \"eventType\": \"FETCH_RECIPIENTS\",\n  \"payload\": {\n    \"recipients\": {\n      \"total\": 2,\n      \"results\": [\n        {\n          \"id\": \"R2\",\n          \"name\": \"Recipient Two\",\n          \"type\": 1\n        },\n        {\n          \"id\": \"R4\",\n          \"name\": \"Recipient Four\",\n          \"type\": 1\n        }\n      ]\n    }\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fetch recipient endpoint"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The URL of the endpoint that returns account data should be structured like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://BASE_PATH_OF_YOUR_SERVICE/feature/SHARE_VIA/event/FETCH_RECIPIENT"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will provide the following fields in the body of the request:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 10,\n  \"appId\": 20,\n  \"payload\": {\n    \"id\": \"R3\",\n    \"type\": 0\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response you return when HubSpot makes a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to the endpoint should be a JSON-formatted object with the following fields:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 0,\n  \"appId\": 0,\n  \"eventType\": \"FETCH_RECIPIENT\",\n  \"payload\": {\n    \"recipient\": {\n      \"id\": \"R3\",\n      \"name\": \"Recipient Three\",\n      \"type\": 0\n    }\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Share object endpoint"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The URL of the endpoint that returns account data should be structured like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://BASE_PATH_OF_YOUR_SERVICE/feature/SHARE_VIA/event/SHARE_OBJECT"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will provide the following fields in the body of the request:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 10,\n  \"appId\": 20,\n  \"payload\": {\n    \"sender\": {\n      \"id\": 2387654 // HubSpot Id of sender\n    },\n    \"recipient\": {\n      \"id\": \"R3\",\n      \"name\": \"Recipient Three\",\n      \"type\": 0\n    },\n    \"message\": \"New report for this week\",\n    \"objectMetadata\": {\n      \"id\": 2334567,\n      \"type\": 2, // integer denotes Type of Object: 2 for Report\n      \"name\": \"Chat Conversations\",\n      \"screenshotUrl\": \"https://api-na1.hubspotqa.com/filemanager/api/signed-url-redirect?portalId=10\"\n    }\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response you return when HubSpot makes a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to the endpoint should be a JSON-formatted object with the following fields:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 10,\n  \"appId\": 20,\n  \"eventType\": \"SHARE_OBJECT\",\n  \"payload\": {\n    \"recipientUrl\": \"https://example.com\" //optional redirect URL\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've set up your four endpoints, you can configure your app settings."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your app developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "apps"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "More features"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Target URL"
        }), " section, enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL"
        }), " that HubSpot will make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to when events trigger. Or, use this testing URL: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://api.hubspot.com/integration-components-example/v1/sample-app-webhooks/beta-app"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click to toggle the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Share via"
        }), " switch on."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-features-share-via.png",
        alt: "app-features-share-via"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Test the functionality"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After setting up your four endpoints and configuring your app settings, you can test this feature:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "Install your app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reporting & Data"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reports"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hover over a report and click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), " dropdown menu. Then, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Share Via"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "connected app"
        }), " and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "frequency"
        }), " of the message and which ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "channel or user"
        }), " you're sending the report to."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "optional message"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Send now"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/share-via-reports.png",
        alt: "share-via-reports"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}