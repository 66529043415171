"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694130;
const slug = exports.slug = 'guides/cms/content/themes/getting-started';
const title = exports.title = 'Introdução aos temas';
const name = exports.name = '[novo] Introdução aos temas';
const metaDescription = exports.metaDescription = 'Introdução aos temas no HubSpot CMS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-iniciar-um-projeto-de-temas-do-boilerplate",
  "label": "1. Iniciar um projeto de temas do boilerplate",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-carregar-o-cms-boilerplate-na-sua-conta-da-hubspot",
  "label": "2. Carregar o CMS Boilerplate na sua conta da HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-criar-uma-p%C3%A1gina",
  "label": "3. Criar uma página",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-editar-campos-de-tema",
  "label": "4. Editar campos de tema",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-preparar-para-fazer-altera%C3%A7%C3%B5es-locais",
  "label": "5. Preparar para fazer alterações locais",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-adicionar-um-campo-do-tema",
  "label": "6. Adicionar um campo do tema",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-referenciar-o-campo-no-css",
  "label": "7. Referenciar o campo no CSS",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-altera%C3%A7%C3%B5es-do-teste",
  "label": "8. Alterações do teste",
  "parent": null
}, {
  "depth": 0,
  "id": "pr%C3%B3ximas-etapas",
  "label": "Próximas etapas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      img: "img",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      em: "em",
      ul: "ul",
      li: "li",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Introdução aos temas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um tema é um pacote independente e portátil de ativos de desenvolvedor, projetado para trabalhar em conjunto, a fim de permitir uma experiência flexível de edição de conteúdo. Esses ativos podem incluir modelos, módulos, arquivos CSS, arquivos JavaScript, imagens e muito mais. Os temas permitem que os desenvolvedores criem um conjunto de campos de tema, semelhantes a campos de módulo, nos quais os criadores de conteúdo podem controlar estilos globais de site sem precisar editar o CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode usar o HubL para acessar os valores dos campos de tema em todo o CSS do tema. Os criadores de conteúdo podem usar o editor de temas para modificar os campos de tema, visualizar essas alterações em relação aos modelos existentes dentro de um tema e publicar suas alterações."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/cms-themes-animation.gif",
        alt: "As configurações do tema editam a animação da interface do usuário, mostrando a seleção de uma cor para elementos de um tema."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este documento explica como criar seu primeiro tema de acordo com o", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: " HubSpot CMS Boilerplate"
      }), ". Para obter mais informações sobre temas, consulte a", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: " documentação de referência para temas."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Se esta é a sua primeira experiência com o desenvolvimento do CMS Hub, recomendamos o seguinte:"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Início rápido no desenvolvimento do CMS Hub"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " antes de começar, você precisará instalar a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "CLI da HubSpot"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Iniciar um projeto de temas do boilerplate"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Execute ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create website-theme my-website-theme"
      }), " para criar um diretório ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-website-theme"
      }), " preenchido com arquivos do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Boilerplate do tema do CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Carregar o CMS Boilerplate na sua conta da HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Execute ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-website-theme my-website-theme"
      }), ". Isso fará o upload do boilerplate para o gerenciador de design da sua conta, em uma pasta intitulada ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "my-website-theme"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Criar uma página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar uma página do tema carregado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Site"
        }), " > **Páginas do site. **"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar"
        }), ", em seguida, selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Página do site"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa de diálogo, selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "domínio"
        }), " no qual a página será publicada e insira um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome de página"
        }), ". Em seguida, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar página"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/create-page-from-dashboard.gif",
        alt: "create-page-from-dashboard"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na tela de seleção de modelos, os modelos do seu", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/use-themes#use-an-active-theme",
          children: " tema ativo"
        }), " serão exibidos na parte superior da página.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se você não selecionou um tema ativo, passe o cursor do mouse sobre o ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Boilerplate de temas do CMS"
            }), " e clique em** Definir como tema ativo**."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se já tiver definido um tema ativo, selecione o novo tema, clicando no menu suspenso do ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Seletor de temas"
            }), " e selecionando ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Alterar tema"
            }), ". Em seguida, passe o cursor do mouse sobre o ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Boilerplate de temas do CMS"
            }), " e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Definir como tema ativo"
            }), ". Na próxima tela, selecione um ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "modelo"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/theme-selector.gif",
        alt: "theme-selector"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você será encaminhado ao editor de páginas, onde poderá editar os campos de tema."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Editar campos de tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral esquerda do editor de páginas, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Temas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Temas"
        }), ", clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Editar configurações do tema"
        }), ". Aqui, você pode modificar as configurações de tema existentes. Publicar as alterações nas configurações do tema atualizará os estilos em suas páginas usando esse tema que foi atualizado."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-theme-settings.gif",
        alt: "edit-theme-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Preparar para fazer alterações locais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Retorne ao terminal e execute ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch my-website-theme my-website-theme"
      }), ". Esse ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "comando"
      }), " monitora o diretório local e carrega automaticamente as seguintes alterações nos arquivos salvos na sua conta da HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Adicionar um campo do tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agora que estamos ouvindo as alterações locais, adicione um novo campo de tema:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Abra o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " no editor.  Este arquivo controla os campos disponíveis na barra lateral do editor de temas. Vamos adicionar um novo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview#fields-json",
          children: "campo"
        }), " para especificar a altura do rodapé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Perto da parte inferior do arquivo, localize o grupo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "footer"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Copie o código abaixo e cole o JSON no arquivo acima do primeiro item na matriz secundária do grupo de rodapé."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n      {\n      \"id\" : \"\",\n      \"name\" : \"height\",\n      \"label\" : \"Footer height\",\n      \"required\" : false,\n      \"locked\" : false,\n      \"display\" : \"text\",\n      \"step\" : 1,\n      \"type\" : \"number\",\n      \"min\" : 10,\n      \"max\" : 900,\n      \"help_text\":\"This footer will expand in height to accomodate any content added to the footer. You are setting the minimum height in px\",\n      \"default\" : 100\n      },\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Salve ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " e atualize o visualizador de tema no HubSpot. O novo campo deve ser exibido na barra lateral esquerda."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Referenciar o campo no CSS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No editor de código, abra o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-overrides.css"
        }), ". Em seguida, localize o seletor css para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".footer"
        }), ". Agora vamos adicionar um ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "min-height"
        }), " a este seletor."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para acessar um valor em um tema, use o objeto ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme"
        }), ". Por exemplo, você usaria ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ theme.footer.height }}"
        }), " para acessar o valor de altura definido em nosso campo de altura."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Substitua a declaração ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".footer"
        }), " no theme-overrides.css pelo seguinte:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".footer {\n  background-color: {{ footer_bg_color }};\n  min-height: {{theme.footer.height}}px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Salve ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-overrides.css"
        }), " para carregá-lo na sua conta da HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. Alterações do teste"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Retorne ao editor de temas e atualize a página para ver o novo campo exibido no rodapé. Atualize o valor da altura para que ele seja refletido imediatamente na visualização. Pode ser útil definir uma cor de fundo para o rodapé, para que você possa ver a alteração com mais facilidade."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Próximas etapas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora que você criou e atualizou o seu tema, crie mais campos de tema e personalize-os para os seus projetos. Para obter mais opções de personalização, confira a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "visão geral dos temas"
      }), ". Ao criar seu tema, pode ser útil visualizar as práticas recomendadas para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "otimizar sites no HubSpot CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubSpot tem vários ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/default-themes",
        children: "temas padrão"
      }), " fornecidos com o CMS Hub. Esses temas estão disponíveis para visualização, clonagem e atualização, para que você saiba como usar um tema em um cenário do mundo real."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de mexer com os temas, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "saiba como criar seu primeiro módulo personalizado"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}