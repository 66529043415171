"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 43859703449;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/video';
const title = exports.title = 'How to add videos to dynamic pages in HubDB';
const name = exports.name = 'How to add videos to dynamic pages in HubDB';
const metaDescription = exports.metaDescription = 'How to add videos to dynamic pages powered by HubDB.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-add-a--video--column-to-your-table.",
  "label": "1. Add a 'VIDEO' column to your table.",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-select-videos-for-each-row.",
  "label": "2. Select videos for each row.",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-add-the-video-player-widget-to-your-dynamic-template",
  "label": "3. Add the video player widget to your dynamic template",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      img: "img",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "How to add videos to dynamic pages in HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "dynamic website page"
      }), " is a CMS page whose content changes based on the path of the URL requested by an end user. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), " already allows you to store, filter, and display data in your HubSpot website pages. Multilevel dynamic pages take this concept further, allowing you to create up to five levels of pages within one dynamic template."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Each dynamic page includes its own unique, SEO-friendly URL, and offers page-specific analytics."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["This tutorial assumes you already have multiple HubDB tables created. Please see the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "HubDB documentation"
        }), " if you are unfamiliar with HubDB or want to create your first HubDB tables."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Add a 'VIDEO' column to your table."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Navigate to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/hubdb",
        children: "HubDB"
      }), " in your HubSpot portal, and edit the table you would like to be a parent of other tables. Click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "\"Add new column\""
      }), " and create a column with type ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "\"Video\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb-add-video.png",
        alt: "HubDB add column modal showing video column type selected"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Select videos for each row."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can now add a video to your row by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "\"choose\""
      }), " button in the video column."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/insert%20video%20interface.png",
        alt: "insert video interface screenshot"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Selecting a video will store the video ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "player_id"
      }), " as the column value in the row. The file thumbnail is used to visually represent the video in the UI."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/HubDB-with-videos-selected.png",
        alt: "HubDB UI with videos selected"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Add the video player widget to your dynamic template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can now reference the row data in your dynamic template to build a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#video-player",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "video_player"
        })
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n  {% video_player \"embed_player\" player_id=\"{{dynamic_page_hubdb_row.video}}\" %}\n{% endif %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}