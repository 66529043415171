"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937504;
const slug = exports.slug = 'guides/api/crm/objects/companies';
const title = exports.title = 'API du CRM | Entreprises';
const name = exports.name = 'vNext Docs DP - Entreprises';
const metaDescription = exports.metaDescription = "Les fiches d'informations d'entreprise stockent des données sur les entreprises. Les points de terminaison des entreprises vous permettent de gérer ces données et de les synchroniser entre HubSpot et d'autres systèmes.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-des-entreprises",
  "label": "Créer des entreprises",
  "parent": null
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s",
  "label": "Propriétés",
  "parent": "cr%C3%A9er-des-entreprises"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "cr%C3%A9er-des-entreprises"
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-des-entreprises",
  "label": "Récupérer des entreprises",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-des-entreprises",
  "label": "Mettre à jour des entreprises",
  "parent": null
}, {
  "depth": 1,
  "id": "associer-des-entreprises-existantes-%C3%A0-des-fiches-d-informations-et-activit%C3%A9s",
  "label": "Associer des entreprises existantes à des fiches d'informations et activités",
  "parent": "mettre-%C3%A0-jour-des-entreprises"
}, {
  "depth": 1,
  "id": "supprimer-une-association",
  "label": "Supprimer une association",
  "parent": "mettre-%C3%A0-jour-des-entreprises"
}, {
  "depth": 0,
  "id": "%C3%A9pingler-une-activit%C3%A9-%C3%A0-une-fiche-d-informations-d-une-entreprise",
  "label": "Épingler une activité à une fiche d'informations d'une entreprise",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-des-entreprises",
  "label": "Supprimer des entreprises",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Entreprises"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans HubSpot, les entreprises stockent des informations sur les organisations qui interagissent avec elles. Les points de terminaison des entreprises vous permettent de créer et de gérer des fiches d'informations d'entreprise ainsi que de synchroniser des données d'entreprise entre HubSpot et d'autres systèmes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les API d'objets, de fiches d'informations, de propriétés et d'associations dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "guide Comprendre le CRM"
      }), ". Pour plus d'informations générales sur les objets et les fiches d'informations dans HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: " découvrez comment gérer votre base de données CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer des entreprises"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer de nouvelles entreprises, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans votre demande, incluez les données de votre entreprise dans un objet de propriétés. Vous pouvez également ajouter un objet d'association pour associer votre nouvelle entreprise à des fiches d'informations existantes (par exemple, des contacts ou des transactions) ou à des activités (par exemple, des réunions ou des notes)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les détails de l'entreprise sont stockés dans des propriétés d'entreprise. Il existe des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
        children: "propriétés d'entreprise HubSpot par défaut"
      }), ", mais vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "créer des propriétés personnalisées"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez une nouvelle entreprise, vous devez inclure ", (0, _jsxRuntime.jsx)("u", {
        children: "au moins une"
      }), " des propriétés suivantes dans votre demande : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ". Il est recommandé de toujours inclure ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ", car les noms de domaine constituent le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/deduplication-of-contacts-companies-deals-tickets#automatic-deduplication-in-hubspot",
        children: "principal identifiant unique"
      }), " pour éviter les entreprises en double dans HubSpot. Si une entreprise possède ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/companies/add-multiple-domain-names-to-a-company-record",
        children: "plusieurs domaines"
      }), ", vous pouvez les ajouter via l'API en utilisant le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_additional_domains"
      }), " avec des points-virgules séparant chaque domaine. Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"hs_additional_domains\" : \"domain.com; domain2.com; domain3.com\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour afficher toutes les propriétés disponibles, vous pouvez récupérer une liste des propriétés d'entreprise de votre compte en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/companies"
      }), ". Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API des propriétés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si vous avez inclus ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lifecyclestage"
        }), " dans votre demande, les valeurs doivent faire référence au nom interne de la phase du cycle de vie. Les noms internes des phases par défaut sont des valeurs textuelles et ne changent pas, même si vous modifiez le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties#:~:text=the%20properties%20settings.-,Label/Name%3A,-enter%20a%20unique",
          children: "libellé"
        }), " de la phase (par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriber"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketingqualifiedlead"
        }), "). Les noms internes des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages",
          children: "phases personnalisées"
        }), " sont des valeurs numériques. Vous pouvez trouver l'ID interne d'une phase dans vos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages#:~:text=To%20edit%20a%20lifecycle%20stage%2C%20hover%20over%20the%20stage%20and%20click%20Edit.%20In%20the%20right%20panel%2C%20edit%20the%20Stage%20name%2C%20then%20click%20Edit%20lifecycle%20stage%20to%20confirm.%20Click%20the%20code%20codcode%20icon%20to%20view%20the%20stage%27s%20internal%20ID%2C%20which%20is%20used%20by%20integrations%20and%20APIs.",
          children: "paramètres de phase du cycle de vie"
        }), " ou en récupérant la propriété de phase du cycle de vie via l'API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour créer une nouvelle entreprise, votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"name\": \"HubSpot\",\n    \"domain\": \"hubspot.com\",\n    \"city\": \"Cambridge\",\n    \"industry\": \"Technology\",\n    \"phone\": \"555-555-555\",\n    \"state\": \"Massachusetts\",\n    \"lifecyclestage\": \"51439524\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez une nouvelle entreprise, vous pouvez également associer l'entreprise à des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "fiches d'informations"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "activités"
      }), " existantes dans un objet d'associations. Par exemple, pour associer une nouvelle entreprise à un contact et une adresse e-mail existants, votre demande ressemblerait à ce qui suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"name\": \"HubSpot\",\n    \"domain\": \"hubspot.com\",\n    \"city\": \"Cambridge\",\n    \"industry\": \"Technology\",\n    \"phone\": \"555-555-555\",\n    \"state\": \"Massachusetts\",\n    \"lifecyclestage\": \"51439524\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 101\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 280\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 556677\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 185\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'objet d'association, vous devez ajouter les éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La fiche d'informations ou l'activité à laquelle vous souhaitez associer l'entreprise, en fonction de sa valeur unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'association entre l'entreprise et la fiche d'informations/l'activité. Inclure la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "et le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Les ID de types d'association par défaut sont répertoriés ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "ici"
            }), ", ou vous pouvez récupérer la valeur des types d'associations personnalisés (c'est-à-dire les libellés) via l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API des associations"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer des entreprises"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez récupérer des entreprises individuellement ou par lots."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer une entreprise individuelle, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/companies/{companyId}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour demander une liste de toutes les entreprises, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/companies"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ces points de terminaison, vous pouvez inclure les paramètres suivants dans l'URL de la requête :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer dans la réponse. Si l'entreprise demandée n'a pas de valeur pour une propriété, elle n'apparaîtra pas dans la réponse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés actuelles et historiques à renvoyer dans la réponse. Si l'entreprise demandée n'a pas de valeur pour une propriété, elle n'apparaîtra pas dans la réponse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste séparée par des virgules des objets pour lesquels récupérer les ID associés. Les associations spécifiées qui n'existent pas ne seront pas renvoyées dans la réponse. Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API des associations."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer un lot d'entreprises spécifiques par ID de fiche d'informations ou selon une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "propriété d'identifiant unique personnalisée"
        }), ", effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/companies/batch/read"
        }), ". Le point de terminaison du lot ", (0, _jsxRuntime.jsx)("u", {
          children: "ne peut pas"
        }), " récupérer les associations. Découvrez comment lire par lots les associations avec l'API ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "des associations"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour le point de terminaison de lecture par lot, vous pouvez également utiliser le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " facultatif pour récupérer les entreprises en fonction d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propriété d'identifiant unique"
      }), " personnalisée. Par défaut, les valeurs d'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " dans la demande font référence à l'ID de fiche d'informations (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), de sorte que le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " n'est pas requis lors de la récupération par ID de fiche d'informations. Pour utiliser une propriété de valeur unique personnalisée pour récupérer des entreprises, vous devez inclure le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour récupérer un lot d'entreprises, votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"name\", \"domain\"],\n  \"inputs\": [\n    {\n      \"id\": \"56789\"\n    },\n    {\n      \"id\": \"23456\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"name\", \"domain\"],\n  \"idProperty\": \"uniquepropertyexample\",\n  \"inputs\": [\n    {\n      \"id\": \"abc\"\n    },\n    {\n      \"id\": \"def\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour récupérer des entreprises avec des valeurs actuelles et historiques pour une propriété, votre demande pourrait ressembler à :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"name\"],\n  \"inputs\": [\n    {\n      \"id\": \"56789\"\n    },\n    {\n      \"id\": \"23456\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour des entreprises"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez mettre à jour des entreprises individuellement ou par lots. Pour les entreprises existantes, l'ID de la fiche d'informations de l'entreprise est une valeur unique que vous pouvez utiliser pour mettre à jour l'entreprise via l'API."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour mettre à jour une entreprise individuelle avec son ID d'entreprise, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}"
      }), " et incluez les données que vous souhaitez mettre à jour."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer des entreprises existantes à des fiches d'informations et activités"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour associer une entreprise à d'autres fiches d'informations de CRM ou à une activité, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour récupérer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), ", reportez-vous à ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "cette liste"
        }), " de valeurs par défaut ou effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur l'association de fiches d'informations avec l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API des associations"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer une association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une association entre une entreprise et une fiche d'informations ou une activité, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à l'URL suivante : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Épingler une activité à une fiche d'informations d'une entreprise"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez épingler une activité à une fiche d'informations d'une entreprise via l'API en incluant le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " dans votre demande. Dans le champ, incluez l'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de l'activité à épingler, qui peut être récupéré via les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API d'engagement"
      }), ". Vous pouvez épingler une activité par fiche d'informations, et l'activité doit déjà être associée à l'entreprise avant l'épinglage."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour définir ou mettre à jour l'activité épinglée d'une entreprise, votre demande peut ressembler à :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/companies/{companyId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez également créer une entreprise, l'associer à une activité existante et épingler l'activité dans la même demande. Par exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/companies\n{\n  \"properties\": {\n    \"domain\": \"example.com\",\n    \"name\": \"Example Company\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 189\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer des entreprises"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez supprimer des entreprises individuellement ou par lots, ce qui placera l'entreprise dans la corbeille dans HubSpot. Vous pourrez ensuite ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "restaurer l'entreprise dans HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une entreprise avec son ID, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la suppression d'entreprises par lots, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}