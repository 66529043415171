import { FLINT } from 'HubStyleTokens/colors';
import I18n from 'I18n';
import { css } from 'styled-components';

/**
 * On non-English sites, adds the localized equivalent of [EN] as a
 * superscript to links that point to the `en-us` https://developers.hubspot.com
 * instance.
 *
 * @example
 * ```mdx
 * Nachrichten [EN]
 * where [EN] is localized superset text
 * ```
 */
const englishSupersetStyles = css(["html:not([lang='en-us']) &[hreflang='en-us']::after{content:'[", "]';color:", ";vertical-align:super;font-size:70%;display:inline-block;}"], () => I18n.text('developer-docs-mdx-components.localization.englishAbbreviation'), FLINT);
export default englishSupersetStyles;