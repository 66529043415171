"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611088;
const slug = exports.slug = 'getting-started/slack/developer-slack';
const title = exports.title = 'Join the HubSpot Developer Slack Community';
const name = exports.name = 'Slack Sign Up - Designers Developers';
const metaDescription = exports.metaDescription = "HubSpot's Developer Slack is a dedicated community for HubSpot's developers to gather for developer-to-developer support and collaboration around the HubSpot platform. ";
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Collaborate in the Developer Slack';
const toc = exports.toc = [{
  "depth": 0,
  "id": "who-is-the-developer-slack-community-for%3F",
  "label": "Who is the Developer Slack Community for?",
  "parent": null
}, {
  "depth": 0,
  "id": "what-can-i-expect-once-i-join-the-community%3F",
  "label": "What can I expect once I join the community?",
  "parent": null
}, {
  "depth": 0,
  "id": "join-our-developer-slack-by-signing-up-below",
  "label": "Join our Developer Slack by signing up below",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Form
    } = _components;
  if (!Form) _missingMdxReference("Form", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Join the HubSpot Developer Slack Community"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot's Developer Slack is a dedicated community for HubSpot's developers to gather for developer-to-developer support and collaboration around the HubSpot platform. Started in 2016, the developer Slack community has grown to hundreds of active developers from around the world gathered together to share their knowledge and expertise."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Who is the Developer Slack Community for?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Whether you are just starting out developing on the HubSpot Platform or you are a seasoned expert, the developer slack is for you. We have channels dedicated to multiple aspects of our platform from APIs, Email Development, Local Development using our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Templates"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Themes"
      }), " and more."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "What can I expect once I join the community?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can expect to find an active community of developers who support and teach each other. This is first and foremost a community, the more you put into it the more you'll get out of it. This community is global, but the majority of folks in it are in the western hemisphere, that means if you live in the eastern hemisphere you may not see fast responses. It's not that anyone is ignoring or not seeing your posts, it's probably that folks are sleeping. The way to change that is to be one of the folks in your time zone that's active and helps others. Through that the community will grow and be more useful to you. We at HubSpot care deeply about this community and are a part of the community. Treat others with respect, treat HubSpotters with respect. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/code-of-conduct",
        children: "Review our slack community code of conduct"
      }), ". Let's grow better together."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Join our Developer Slack by signing up below"
    }), "\n", ' ', "\n", (0, _jsxRuntime.jsx)(Form, {
      formId: "7fd756b1-d981-4b81-9caf-8a79a25bffb2",
      responseType: "inline",
      message: `<br/><ui-button target="_blank" href="https://cta-redirect.hubspot.com/cta/redirect/53/18a2bcd4-227f-46b9-b7e4-52dba8955ae5">Enter the Slack community</ui-button>`
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}