"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601074021;
const slug = exports.slug = 'getting-started/overview';
const title = exports.title = 'Überblick über die ersten Schritte';
const name = exports.name = 'Überblick über die ersten Schritte (Entwicklerdokumente 2024)';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie mit dem Entwickeln in HubSpot beginnen können.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "die-vorteile-einer-vernetzten-plattform",
  "label": "Die Vorteile einer vernetzten Plattform",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-die-tools-installieren%2C-die-sie-bei-der-erstellung-unterst%C3%BCtzen",
  "label": "1. Die Tools installieren, die Sie bei der Erstellung unterstützen",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-entscheiden%2C-was-erstellt-werden-soll",
  "label": "2. Entscheiden, was erstellt werden soll",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-sich-gleichgesinnten-anschlie%C3%9Fen",
  "label": "3. Sich Gleichgesinnten anschließen",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-ihren-bed%C3%BCrfnissen-entsprechend-lernen",
  "label": "4. Ihren Bedürfnissen entsprechend lernen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Überblick über die ersten Schritte"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ganz gleich, ob Sie ein neuer HubSpot-Entwickler sind oder Ihre Kenntnisse erweitern möchten, dieser Abschnitt führt Sie durch den Einstieg in das Entwickeln in HubSpot. Sie werden die verschiedenen verfügbaren Entwicklungswege sowie die spezifischen Tools und Accounts kennenlernen, die Sie benötigen, um Ihre Ziele zu erreichen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Vorteile einer vernetzten Plattform"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zunächst ist es hilfreich, sich vor Augen zu führe, was genau HubSpot zu einer leistungsstarken Plattform für Entwickler macht."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Grundlage jedes HubSpot-Accounts ist die CRM-Plattform ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm"
      }), " (Customer Relationship Management), eine Datenbank für Geschäftsbeziehungen und -prozesse. HubSpot bietet verschiedene Möglichkeiten für externe Entwickler und Partner, mit dem CRM zu arbeiten, damit sie einen Mehrwert für HubSpot-Endbenutzer schaffen können. Dazu gehören:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erstellen von Integrationen und/oder Erweiterungen mit den APIs von HubSpot, um Daten zwischen HubSpot und externen Plattformen zu synchronisieren."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erstellen von benutzerdefinierten Websites mit CMS Hub, dem Contentmanagement-System von HubSpot. CMS Hub verfügt nicht nur über eine vollständige Suite von Contentmanagement-Tools, sondern ist auch vollständig in das CRM integriert. Dies erleichtert das Verwalten von Kampagnen, das Erfassen von Leads und die laufende Kundenkommunikation."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Anpassen des CRM mit UI-Erweiterungen. Entwickler können React verwenden, um flexible benutzerdefinierte Karten zu erstellen, die sich nahtlos in HubSpot integrieren lassen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im nächsten Abschnitt erhalten Sie einen Überblick über die Tools, die für HubSpot-Entwickler verfügbar sind. Anschließend wählen Sie eine Route, um Ihre Customer Journey als HubSpot-Entwickler fortzusetzen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Die Tools installieren, die Sie bei der Erstellung unterstützen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für HubSpot-Entwickler ist eine breite Palette von Tools verfügbar. Welche genau Sie benötigen, hängt von Ihren spezifischen Zielen ab."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubSpot CLI:"
        }), " Die Installation des HubSpot CLI für die lokale Entwicklung ist ein guter Ausgangspunkt für viele Entwicklungsaufgaben. Das CLI wird bei der Erstellung von UI-Erweiterungen und CMS-Ressourcen verwendet und kann zum Erstellen und Verwalten von Geheimnissen, HubDB-Tabellen und benutzerdefinierten Objekten in Ihrem Account verwendet werden. Es muss ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: (0, _jsxRuntime.jsx)("u", {
            children: "Node.js"
          })
        }), " installiert sein (Version 18 oder höher ist erforderlich, und die LTS-Version wird empfohlen). Um das CLI global zu installieren, führen Sie den folgenden Befehl aus:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Visual Studio Code:"
          }), " Wenn Sie ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://code.visualstudio.com/",
            children: "Visual Studio Code"
          }), " installieren, können Sie die Vorteile der ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
            children: "HubSpot Visual Studio Code-Erweiterung"
          }), " nutzen, mit der Sie Ihren Entwicklungs-Workflow zentralisieren können."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Client-Bibliotheken"
          }), ": HubSpot bietet Client-Bibliotheken für ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-nodejs",
            children: "Node"
          }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-php",
            children: "PHP"
          }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-ruby",
            children: "Ruby"
          }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-python",
            children: "Python"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Postman:"
          }), " Verwenden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/",
            children: "Postman"
          }), " für den Zugriff auf ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/hubspot",
            children: "die Postman-Filtergruppe von HubSpot"
          }), ". In der gesamten API-Dokumentation von HubSpot finden Sie Schaltflächen zum Ausführen von Aufrufen unter Verwendung der Filtergruppe."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/run-in-postman-current-docs-site.png",
            alt: "run-in-postman-current-docs-site"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/tools-to-help-you-build",
        children: "HubSpots Tools für Entwickler"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Entscheiden, was erstellt werden soll"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für die Entwicklung mit HubSpot gibt es kein Patentrezept. Es ist eine flexible Plattform, die eine Vielzahl von Anforderungen erfüllen kann."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine der vier unten aufgeführten Kategorien dürfte die meisten dieser Bedürfnisse abdecken. Sie können diejenige auswählen, die für Sie im Moment am wichtigsten ist, um eine kurze Einführung zu erhalten, zusammen mit einigen Sofortmaßnahmen, die Ihnen den Einstieg erleichtern. Sie können sich auch ein Bild davon machen, welche Tools am besten geeignet sind und ob ein bestimmter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "Typ von HubSpot-Account"
      }), " erforderlich ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#use-apis-and-build-integrations",
          children: "APIs verwenden und Integrationen erstellen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-for-the-app-marketplace",
          children: "Apps für den App Marketplace erstellen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#customize-the-hubspot-ui",
          children: "Die Benutzeroberfläche von HubSpot anpassen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-custom-websites",
          children: "Benutzerdefinierte Websites erstellen"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Sich Gleichgesinnten anschließen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vernetzen Sie sich mit Peers, die bereits auf Grundlage von HubSpot entwickeln. Stellen Sie Fragen, tauschen Sie Wissen aus, bauen Sie eine Gemeinschaft auf und finden Sie vielleicht sogar Freunde."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "Entwickler-Community bei Slack (Developer Slack)"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
          children: "Entwickler-Foren"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/hubspotdev",
          children: "Entwickler-Advocacy GitHub"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Ihren Bedürfnissen entsprechend lernen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald Sie mit der Arbeit begonnen haben, stehen Ihnen zahlreiche Ressourcen zur Verfügung, die Sie bei der Vertiefung Ihrer Kenntnisse unterstützen. Sie können auf ausführliche Leitfäden, detaillierte Referenzdokumente oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses",
        children: "HubSpot Academy-Kurse"
      }), " zugreifen, je nach Ihrem bevorzugten Lernstil."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}