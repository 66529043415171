"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937497;
const slug = exports.slug = 'guides/api/crm/objects/products';
const title = exports.title = 'API do CRM | Produtos';
const name = exports.name = 'vNext DP de documentos - Produtos';
const metaDescription = exports.metaDescription = 'No HubSpot, produtos consistem nas mercadorias ou nos serviços que você vende. Os pontos de extremidade dos produtos permitem gerenciar e sincronizar esses dados.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-um-produto",
  "label": "Criar um produto",
  "parent": null
}, {
  "depth": 0,
  "id": "associar-produtos",
  "label": "Associar produtos",
  "parent": null
}, {
  "depth": 1,
  "id": "criar-e-associar-um-item-de-linha-v%C3%A1rias-chamadas",
  "label": "Criar e associar um item de linha (várias chamadas)",
  "parent": "associar-produtos"
}, {
  "depth": 1,
  "id": "criar-e-associar-um-item-de-linha-chamada-%C3%BAnica",
  "label": "Criar e associar um item de linha (chamada única)",
  "parent": "associar-produtos"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Produtos"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No HubSpot, produtos consistem nas mercadorias ou nos serviços que você vende. A criação de uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
        children: "biblioteca de produtos"
      }), " permite adicionar produtos aos negócios, gerar orçamentos e criar relatórios de desempenho do produto com rapidez. Os pontos de extremidade dos produtos permitem gerenciar esses dados e sincronizá-los entre o HubSpot e outros sistemas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os produtos, assim como as empresas, os contatos, os negócios, os tickets, os itens de linha e os orçamentos, são objetos no HubSpot CRM. Saiba mais sobre propriedades de objeto, associações, relacionamentos e muito mais em nosso guia ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Noções básicas do CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de caso de uso:"
      }), " para que os representantes de vendas possam adicionar com facilidade bens e serviços a negócios, orçamentos e muito mais, use a API de produtos para importar seu catálogo de produtos para o HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um produto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um produto, execute uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/products"
      }), ". No corpo da solicitação, inclua um objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " contendo as propriedades de produto que você deseja definir ao criar. Posteriormente, você poderá atualizar as propriedades de um produto por meio de uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para o mesmo ponto de extremidade."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver todas as propriedades disponíveis do produto, execute uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API de propriedades"
      }), ". Para recuperar as propriedades do produto, o URL da solicitação será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/products"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to crm/v3/objects/products\n{\n  \"properties\": {\n    \"name\": \"Implementation Service\",\n    \"price\": \"6000.00\",\n    \"hs_sku\": \"123456\",\n    \"description\": \"Onboarding service for data product\",\n    \"hs_cost_of_goods_sold\": \"600.00\",\n    \"hs_recurring_billing_period\": \"P12M\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Observe que o valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_recurring_billing_period"
        }), " é formatado como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "P#M"
        }), ", onde # é o número de meses."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associar produtos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os produtos em si não podem ser associados a outros objetos do CRM. No entanto, para associar as informações de um produto a uma oferta ou orçamento, você pode criar um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "item de linha"
      }), " com base nesse produto. Os itens de linha são instâncias individuais de produtos e são objetos separados dos produtos para que você possa personalizar os bens e serviços em um negócio ou orçamento, conforme necessário, sem a necessidade de atualizar o produto em si."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, ao criar um negócio em que um dos seus produtos é vendido, primeiro crie um item de linha a partir do produto e, em seguida, associe-o ao negócio. Você pode fazer isso executando duas chamadas separadas, ou uma chamada que cria e associa o item de linha. Ambas as opções são mostradas abaixo."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " os itens de linha pertencem a um único objeto principal. Ao associar objetos, os itens de linha devem ser individuais para cada objeto. Por exemplo, se estiver criando um negócio e um orçamento, você deverá criar um conjunto de itens de linha para o negócio e outro conjunto para o orçamento. Isso ajudará a otimizar os dados do CRM em todos os objetos e evitará a perda inesperada de dados se for necessário modificar os itens de linha. Por exemplo, excluir um orçamento também excluirá os itens de linha do orçamento. Se esses itens de linha também estiverem associados a um negócio, os itens de linha do negócio também serão excluídos."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar e associar um item de linha (várias chamadas)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Primeiro, você criará um item de linha com base em um produto com o ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234567"
      }), ". Para obter a lista completa das propriedades de item de linha disponíveis, execute uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API de propriedades"
      }), ". O URL dos itens de linha seria ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/line_items"
      }), ". Como você cria o item de linha a partir de um produto existente, ele herda os valores de propriedade do produto, como preço."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_product_id\": \"1234567\",\n    \"name\": \"New line item (product-based)\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A resposta retornará um ID de item de linha que você pode usar para associá-lo a um negócio usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de associações"
      }), ". Para este exemplo, suponha que o ID do item de linha retornado seja ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "7791176460"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para associar o item de linha a um negócio existente (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "14795354663"
      }), "), você executará uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663"
      }), ". Esta solicitação usa o tipo de associação padrão."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma resposta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " retornará informações semelhantes às seguintes:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663\n\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"14795354663\"\n      },\n      \"to\": {\n        \"id\": \"7791176460\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 19\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"7791176460\"\n      },\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 20\n      }\n    }\n  ],\n  \"startedAt\": \"2023-12-21T20:06:52.083Z\",\n  \"completedAt\": \"2023-12-21T20:06:52.192Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No HubSpot, o registro do negócio exibirá o item de linha no cartão ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Itens de linha"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar e associar um item de linha (chamada única)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um item de linha a partir de um produto existente e associá-lo a um negócio usando uma única chamada, você pode incluir uma matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " na solicitação de criação de item de linha."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar o item de linha, execute uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/line_item"
      }), ". O corpo da sua solicitação será semelhante ao mostrado a seguir. Observe que o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " da associação de item de linha/negócio é ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "20"
      }), ". Saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations#association-type-id-values",
        children: "tipos de associação entre diferentes tipos de registos do CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_product_id\": \"1234567\",\n    \"name\": \"New line item (product-based)\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma resposta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " retornará detalhes sobre o novo item de linha. No HubSpot, o registro do negócio exibirá o item de linha no cartão ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Itens de linha"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}