"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831382;
const slug = exports.slug = 'reference/cms/hubl/tags/related-blog-posts';
const title = exports.title = 'Erstellen eines Listings für verwandte Blog-Beiträge mit dem related_blog_posts-HubL-Tag.';
const name = exports.name = 'EMEA DE Creating a related blog post listing with the blog related posts HubL tag';
const metaDescription = exports.metaDescription = 'Das related_blog_posts-HubL-Tag kann verwendet werden, um dynamische Listings für verwandte Blog-Beiträge basierend auf einer Vielzahl von Parametern zu erstellen. Es ermöglicht die Erstellung von Listings von Beiträgen über Blogs hinweg, mit der Möglichkeit, nach Tags, Autoren, Beitragspfaden und Veröffentlichungsdaten zu filtern.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "parameter",
  "label": "Parameter",
  "parent": null
}, {
  "depth": 0,
  "id": "beispiel-f%C3%BCr-die-verwendung-des-related_blog_posts-hubl-tags",
  "label": "Beispiel für die Verwendung des related_blog_posts-HubL-Tags",
  "parent": null
}, {
  "depth": 1,
  "id": "beitr%C3%A4ge-von-einem-bestimmten-autor-%C3%BCber-mehrere-blogs-hinweg-anzeigen",
  "label": "Beiträge von einem bestimmten Autor über mehrere Blogs hinweg anzeigen",
  "parent": "beispiel-f%C3%BCr-die-verwendung-des-related_blog_posts-hubl-tags"
}, {
  "depth": 1,
  "id": "beitr%C3%A4ge-mit-dem-tag-%22sales-enablement%22-anzeigen%2C-beschr%C3%A4nkt-auf-ein-bestimmtes-zeitfenster-hinsichtlich-des-ver%C3%B6ffentlichungsdatums",
  "label": "Beiträge mit dem Tag \"sales enablement\" anzeigen, beschränkt auf ein bestimmtes Zeitfenster hinsichtlich des Veröffentlichungsdatums",
  "parent": "beispiel-f%C3%BCr-die-verwendung-des-related_blog_posts-hubl-tags"
}, {
  "depth": 1,
  "id": "beitr%C3%A4ge-mithilfe-eines-js-r%C3%BCckrufs-anzeigen%2C-um-die-html-ausgabe-zu-steuern",
  "label": "Beiträge mithilfe eines JS-Rückrufs anzeigen, um die HTML-Ausgabe zu steuern",
  "parent": "beispiel-f%C3%BCr-die-verwendung-des-related_blog_posts-hubl-tags"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Erstellen eines Listings für verwandte Blog-Beiträge mit dem related_blog_posts-HubL-Tag."
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#blog-related-posts",
        children: "related_blog_posts"
      }), "-HubL-Tag kann verwendet werden, um dynamische Listings für verwandte Blog-Beiträge basierend auf einer Vielzahl von Parametern zu erstellen. Es ermöglicht die Erstellung von Listings von Beiträgen über Blogs hinweg, mit der Möglichkeit, nach Tags, Autoren, Beitragspfaden und Veröffentlichungsdaten zu filtern. Entwickler können auch die HTML-Ausgabe des Moduls mithilfe eines Makros angeben. Dieses HubL-Tag kann sowohl für Blog-Beiträge als auch für Seiten verwendet werden. In diesem Tutorial werden die Parameter und Nutzungsoptionen für das HubL-Tag für verwandte Blog-Beiträge erläutert."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Bitte beachten Sie, dass das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "related_blog_posts"
        }), "-HubL-Tag kein bearbeitbares Modul auf der Beitrags-/Seitenebene generiert. Es ist in seiner Gesamtheit mit HubL konfiguriert."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parameter"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Liste der Beiträge wird anhand einer Relevanzbewertung generiert, die auf einem Vergleich der festgelegten Parameterwerte mit Beiträgen basiert, die diesen Parametern entsprechen, oder sich auf den Beitrag bezieht, auf dem das HubL-Tag angezeigt wird. Keiner der Parameter ist erforderlich. Wenn Sie jedoch Parameter angeben, können Sie weiter steuern, welche Beiträge zurückgegeben werden. Für kommagetrennte Parameter gilt: Je mehr Werte Sie festlegen, desto vielfältiger ist das zurückgegebene Listing. Mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "post_formatter"
      }), "-Parameter können Sie ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "Makro"
      }), " angeben, um die HTML-Ausgabe des Moduls zu generieren. Eine vollständige Liste der Parameter und ein Beispiel für die Standard-HTML-Ausgabe finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#blog-related-posts",
        children: "related_blog_posts"
      }), "-Spezifikation."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die maximale Anzahl an aufzulistenden Blog-Beiträgen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "'default' or blog id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID(s) eines Blogs, von dem Beiträge berücksichtigt werden sollen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das/die Tag(s), das/die verwendet werden soll(en), um festzustellen, ob ein Beitrag relevant ist (durch Komma getrennt). Wenn ein Blog-Beitrag eines dieser Tags oder ein ähnliches Tag enthält, werden die Relevanz des Beitrags erhöht und damit sein Ranking im Listing verbessert."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_authors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Namen der Autoren, von denen Beiträge aufgenommen werden sollen (durch Komma getrennt)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_post_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID(s) eines oder mehrerer Blog-Beiträge, die bei der Suche nach verwandten Blog-Beiträgen für das Listing verwendet werden soll(en) (durch Komma getrennt). Dieser Parameter sollte nur verwendet werden, wenn das Widget auf Seiten angezeigt wird, da es bei Blog-Beiträgen standardmäßig auf den Beitrag angewendet wird, in dem das Widget angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "post_formatter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Name eines benutzerdefinierten Makros zum Rendern zurückgegebener Blog-Beiträge. Dem Makro werden drei Parameter übergeben, nämlich das zu formatierende Blog-Beitragsobjekt, die Anzahl der Blog-Beiträge in der Iteration und die Gesamtzahl der Blog-Beiträge in den Ergebnissen. Wenn nicht angegeben oder auf „Standard“ gesetzt, wird der integrierte Formatierer verwendet, um jeden Beitrag zu formatieren.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Hinweis: Es wird empfohlen, den folgenden Callback-Parameter anstelle des post_formatter-Parameters zu verwenden, da der HTML-Code des Tags schneller gerendert wird, wodurch die Seitenladezeiten verringert werden."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callback"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name einer JavaScript-Funktion, um zurückgegebene Blog-Beiträge darzustellen. Der Funktion wird ein Array von Blog-Beitragsobjekten übergeben, die formatiert werden sollen. Wenn weder der callback- noch der post_formatter-Parameter angegeben sind, erzeugt das Tag HTML in einem Standardformat."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path_prefixes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL-Pfade oder Unterverzeichnisse, aus denen Beiträge aufgenommen werden sollen (durch Komma getrennt). Wenn ein Blog-Beitrag ein ähnliches Präfix in seinem Pfad hat, werden die Relevanz des Beitrags erhöht und damit sein Ranking im Listing verbessert."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "date/time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ermöglicht das Filtern von Beiträgen, die nach einem Datum/einer Uhrzeit veröffentlicht wurden."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date/Time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ermöglicht das Filtern von Beiträgen, die vor einem Datum/einer Uhrzeit veröffentlicht wurden."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_post_override"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID(s) eines oder mehrerer Blog-Beiträge, die trotz aller anderen Parameterwerte und Filter immer in dem zurückgegebenen Listing erscheinen soll(en) (durch Komma getrennt)."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Wir empfehlen dringend, den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "callback"
        }), "-Parameter anstelle des ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "post_formatter"
        }), "-Parameters zu verwenden, um schnellere Seitenladezeiten zu gewährleisten."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Bitte beachten Sie, dass bei Verwendung des ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "related_blog_posts"
        }), "-HubL-Tags in einem Beitrag der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_post_ids"
        }), "-Parameter nicht angegeben werden sollte, da er in Blog-Beiträgen standardmäßig in dem Beitrag verwendet wird, in dem das Widget angezeigt wird."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beispiel für die Verwendung des related_blog_posts-HubL-Tags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Unten finden Sie einige Beispiele, wie Sie dieses Tag verwenden können, um Ihre Website zu verbessern."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Beiträge von einem bestimmten Autor über mehrere Blogs hinweg anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Beispiel erstellen wir ein Listing von Beiträgen, die von einem der drei angegebenen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_authors"
      }), " in zwei verschiedenen Blogs verfasst wurden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts blog_ids=\"3241539189,3261083894\", limit=6, blog_authors=\"John Smith,Joe Smith,Frank Smith\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Beiträge mit dem Tag \"sales enablement\" anzeigen, beschränkt auf ein bestimmtes Zeitfenster hinsichtlich des Veröffentlichungsdatums"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Beispiel erstellen wir eine Liste mit 10 Beiträgen, die mit einem bestimmten Blog-Beitrag in Verbindung stehen, mit dem Tag \"sales enablement\" und beschränken sich auf einen bestimmten Veröffentlichungszeitraum. Dieses Beispiel gibt den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post_ids"
      }), "-Parameter an, sodass er auf einer Seite verwendet wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts blog_post_ids=\"3267910554\", limit=10, tags=\"sales enablement\", start_date=\"2018-02-05\", end_date=\"2018-06-10\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Beiträge mithilfe eines JS-Rückrufs anzeigen, um die HTML-Ausgabe zu steuern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Beispiel generieren wir ein Listing von 5 Beiträgen mithilfe des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callback"
      }), "-Parameters, um die HTML-Ausgabe des Beitrags-Listings zu steuern. (Anstelle des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "post_formatter"
      }), "-Parameters wird ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "Makro"
      }), " verwendet.)"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts limit=5, callback=\"blog_post_formatter\" %}\n\n<script>\n  var blog_post_formatter = function(blogposts) {\n\n    var formatted = \"<div>\";\n    for (var i = 0; i < blogposts.length; i++) {\n      var blogpost = blogposts[i];\n      formatted += '<div class=\"related-blog-item\">';\n      formatted += `<span>Related Post ${i + 1}/${blogposts.length}</span><br>`;\n      formatted += `<a class=\"related-blog-title\" href=\"${blogpost.url}\"><span>${blogpost.name}</span></a>`;\n      formatted += `<div class=\"hs-related-blog-byline\">by <span class=\"related-blog-author\">${blogpost.blogAuthor.fullName}</span><span class=\"related-blog-posted-at\"> posted on </span><span class=\"related-blog-date\">${new Date(blogpost.publishDate).toLocaleDateString()}</span></div>`;\n      formatted += `<p class=\"related-blog-post-summary\">${blogpost.postSummary}<a href=\"${blogpost.url}\">Read more</a></p>`;\n      formatted += '<div class=\"related-blog-tags\">';\n      if (blogpost.tagList.length > 0) {\n        formatted += `Tags: ${blogpost.tagList.map(tag => tag.label).join(\", \")}`;\n      }\n      formatted += '</div>';\n\n      if (blogpost.featuredImage) {\n        formatted += `<img src=\"${blogpost.featuredImage}\" alt=\"${blogpost.featuredImageAltText}\">`;\n      }\n      formatted += '</div>';\n    }\n    formatted += '</div>';\n    return formatted;\n  }\n</script>\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}