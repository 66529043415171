"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 30866940858;
const slug = exports.slug = 'guides/api/conversations/visitor-identification';
const title = exports.title = 'Identificação do visitante';
const name = exports.name = 'vNext Documentos DP - Identificação do visitante';
const metaDescription = exports.metaDescription = 'A API de identificação do visitante é usada para identificar os visitantes do seu site que foram autenticados usando sistemas de autenticação externos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "exemplo-de-fluxo-de-integra%C3%A7%C3%A3o",
  "label": "Exemplo de fluxo de integração",
  "parent": null
}, {
  "depth": 0,
  "id": "verifique-a-integra%C3%A7%C3%A3o",
  "label": "Verifique a integração",
  "parent": null
}, {
  "depth": 0,
  "id": "introdu%C3%A7%C3%A3o-ao-sdk-do-widget-de-chat",
  "label": "Introdução ao SDK do widget de chat",
  "parent": null
}, {
  "depth": 1,
  "id": "refer%C3%AAncia-do-sdk",
  "label": "Referência do SDK",
  "parent": "introdu%C3%A7%C3%A3o-ao-sdk-do-widget-de-chat"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      h2: "h2",
      a: "a",
      img: "img",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Identificação do visitante"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise', 'sales_hub-professional', 'sales_hub-enterprise', 'cms-professional', 'cms-enterprise', 'service_hub-professional', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use a API de identificação do visitante para identificar os visitantes do seu site que foram autenticados usando seu próprio sistema de autenticação externo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um token de identificação retornado desta API pode ser usado para transmitir informações sobre seu visitante já autenticado para o widget de chat, de modo que ele trate o visitante como um contato conhecido. Os agentes na caixa de entrada podem então ter confiança sobre com quem estão conversando e os visitantes podem acessar o histórico de conversas anteriores em todos os dispositivos. Por exemplo:"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "a API de identificação do visitante serve para informar ao HubSpot quem é o visitante. Você não deve confiar nisso para autenticar usuários em sua plataforma."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["O acesso à API de identificação do visitante requer uma assinatura nível ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), ". Se a conta não tiver uma assinatura qualificada, você receberá uma resposta de erro ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "403"
          }), " da API."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemplo de fluxo de integração"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para integrar este recurso, você deve ter um aplicativo web existente com um sistema de autenticação."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Antes de começar, certifique-se de ter um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "aplicativo privado"
      }), " configurado e que a conta que você está tentando integrar tenha uma assinatura ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Professional"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja este exemplo de um possível fluxo de integração:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hubfs/Possible%20User%20Identification%20Flow.png",
        alt: "Possível fluxo de identificação do usuário"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois que o cliente estiver logado e verificado em seu sistema, siga as seguintes etapas para identificá-lo no chat ao vivo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "1."
      }), " No seu front-end, defina loadImmediately para falso no objeto hsConversationsSettings na janela. Se você não fizer isso, o widget de chat poderá ser carregado antes que as informações de identificação sejam transmitidas. Veja a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#chat-widget-sdk-primer",
        children: "Introdução ao SDK do widget de chat abaixo"
      }), " para obter mais informações."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Defina as propriedades ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), " fora da função ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isConversationsAPIReady"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Além disso, o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), " precisa ser definido antes da chamada; caso contrário, você poderá enfrentar uma condição que poderá interferir no carregamento do widget."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "2."
      }), " Gere um token da API de identificação do visitante, passando o endereço de e-mail do seu visitante autenticado. Isso deve ser feito no back-end do seu aplicativo web. Veja a guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Pontos de extremidade"
      }), " para obter um exemplo de solicitação."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubspot.com/conversations/v3/visitor-identification/tokens/create \\\n--data '{\n  \"email\": \"gob@bluth.com\",\n  \"firstName\": \"Gob\",\n  \"lastName\": \"Bluth\"\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "O nome e o sobrenome fornecidos serão definidos no registro do contato no HubSpot após o início do chat se:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "é um contato novo criado pela API de identificação do visitante."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "é um contato existente cujo nome ainda não é conhecido."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Isso pode ser útil ao personalizar mensagens para visitantes identificados quando seu sistema externo já possui as informações de nome, mas elas ainda não existem no HubSpot. Esses são parâmetros opcionais e não obrigatórios."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "3."
      }), " Usando o token da Etapa 2, defina as seguintes propriedades no objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " na janela."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "4."
      }), " Carregue o widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O token e o e-mail devem ser configurados no objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " na janela sempre que a página for carregada para um visitante autenticado. Este contexto não será transportado automaticamente nos carregamentos de página se esses parâmetros não estiverem mais definidos. Os tokens são temporários e expirarão após 12 horas. Os tokens podem ser armazenados em cache para evitar uma nova busca de token a cada carregamento de página, desde que sejam atualizados pelo menos a cada 12 horas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verifique a integração"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de concluir a integração do recurso de identificação do visitante, você poderá verificar se ele está funcionando conforme o esperado. Isso pode ser feito de duas maneiras, dependendo da sua implementação; portanto, talvez seja necessário adaptar os exemplos abaixo aos seus requisitos específicos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Se você adicionou o widget de chat a uma ou mais páginas públicas, bem como atrás de um sistema de autenticação:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "Navegue até uma página onde o widget de chat não deveria identificar visitantes e inicie uma conversa."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["No HubSpot, abra a caixa de entrada e verifique se o chat que acabou de chegar pertence a um ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Visitante desconhecido"
              }), ". Se não for esse o caso, tente seguir estas etapas em uma janela de navegação privada:"]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "Navegue até uma página onde o widget de chat deve identificar os visitantes por meio da API de identificação do visitante e inicie uma conversa."
                }), "\n"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "No HubSpot, abra a caixa de entrada e verifique se o chat está atribuído corretamente ao contato com o qual você está conectado. Você deverá ver um selo ao lado do nome do contato, indicando que esse contato foi identificado com sucesso pela API."
                }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: (0, _jsxRuntime.jsx)(_components.img, {
                    src: "https://f.hubspotusercontent00.net/hubfs/53/visitor_identity_badge.png",
                    alt: "visitor_identity_badge"
                  })
                }), "\n"]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Se você adicionou o widget de chat apenas a páginas protegidas por um sistema de autenticação e tem acesso a diversas contas de usuário de teste:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Faça login no HubSpot como o primeiro usuário de teste, navegue até uma página onde o widget de chat é carregado e inicie uma conversa."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Saia do HubSpot e faça login novamente como o segundo usuário de teste. Navegue até uma página onde o widget de chat é carregado e inicie uma conversa."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "No HubSpot, abra a caixa de entrada e verifique se os chats recebidos eram da primeira e da segunda contas de teste, respectivamente, e se você vê o selo ao lado dos nomes dos contatos de ambos os registros."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " para visitantes identificados com esta API, o HubSpot não instalará o cookie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), ". O HubSpot também ignorará quaisquer perguntas de captura de e-mail, pois o endereço de e-mail já é conhecido. Como a captura de e-mail e do cookie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), " não se aplica a esses chats, as configurações associadas no fluxo de chat não serão exibidas para os visitantes identificados por meio da API de identificação do visitante."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Introdução ao SDK do widget de chat"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A API está hospedada no objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), ". Todos os métodos disponíveis podem ser acessados através deste objeto. O carregador de script do HubSpot em sua página criará esse objeto para você, mas ele pode não estar disponível imediatamente. Para adiar o acesso à API até que ela seja inicializada, você pode usar o auxiliar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), ". Por exemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Referência do SDK"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Matriz"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este é um campo opcional que você pode definir no objeto de janela que permite especificar o código a ser executado assim que o widget estiver disponível. Uma vez inicializada a API, ela verificará a existência desta matriz e executará suas funções em série."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "if (window.HubSpotConversations) {\n  console.log('The api is ready already');\n} else {\n  window.hsConversationsOnReady = [\n    () => {\n      console.log('Now the api is ready');\n    },\n  ];\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Objeto"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este objeto permite fornecer algumas opções de configuração ao widget antes de ele ser inicializado. Para utilizar o recurso de Identificação do visitante, você deve definir os seguintes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Se o widget deve carregar implicitamente ou esperar até que o método ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            }), " seja chamado"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationToken"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Usado para integração com a API de identificação do visitante. Este é o token fornecido pelo ponto de extremidade de geração de token na API de identificação do visitante que é usado como prova de que este visitante foi identificado."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationEmail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O endereço de e-mail do visitante que você identificou como responsável pelo carregamento do widget."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/conversations/chat-widget-sdk",
        children: "SDK de conversas"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}