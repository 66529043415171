"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492355253;
const slug = exports.slug = 'reference/cms/hubl/tags/dnd-areas';
const title = exports.title = 'Etiquetas HubL del área de arrastrar y soltar';
const name = exports.name = '[new] HubL Supported Tags | dnd_areas';
const metaDescription = exports.metaDescription = 'Las etiquetas HubL se utilizan para construir áreas de arrastrar y soltar que permiten a los desarrolladores crear secciones de páginas que admiten cambios de diseño, estilos y de contenido directamente dentro de los editores de contenido.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "dnd_area",
  "label": "dnd_area",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_section",
  "label": "dnd_section",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_column",
  "label": "dnd_column",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_row",
  "label": "dnd_row",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_module",
  "label": "dnd_module",
  "parent": null
}, {
  "depth": 0,
  "id": "fondo",
  "label": "Fondo",
  "parent": null
}, {
  "depth": 1,
  "id": "background_color",
  "label": "background_color",
  "parent": "fondo"
}, {
  "depth": 1,
  "id": "background_linear_gradient",
  "label": "background_linear_gradient",
  "parent": "fondo"
}, {
  "depth": 1,
  "id": "background_image",
  "label": "background_image",
  "parent": "fondo"
}, {
  "depth": 0,
  "id": "c%C3%B3mo-se-trasladan-los-par%C3%A1metros-de-estilo-de-dnd-a-la-p%C3%A1gina",
  "label": "Cómo se trasladan los parámetros de estilo de dnd a la página",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      ul: "ul",
      li: "li",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Etiquetas HubL del área de arrastrar y soltar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Las áreas de arrastrar y soltar"
      }), " permiten a los desarrolladores crear secciones de páginas y parciales globales que admiten cambios de diseño, estilo y contenido directamente en los editores de contenido. Consulta el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "tutorial sobre crear un área de arrastrar y soltar"
      }), " para una introducción a la configuración de las áreas de arrastrar y soltar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las áreas de arrastrar y soltar se basan en una cuadricula responsiva de 12 columnas. Las etiquetas de arrastrar y soltar generan marcas con nombres de clase que designan columnas y filas. Tendrás que agregar una hoja de estilos para orientar esos nombres de clase. Un ejemplo de estilos de diseño que podrías implementar se encuentra en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "plantilla de CMS de HubSpot"
      }), ". Tu hoja de estilo se puede agregar a la plantilla utilizando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172854037902"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " las áreas de arrastrar y soltar no se pueden utilizar en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "publicaciones de blog"
        }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates#email",
          children: "plantillas de correo electrónico"
        }), " en este momento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_area"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "área de arrastrar y soltar"
      }), " es un contenedor que hace que una parte de la página web sea editable en cuanto a su estructura, diseño y contenido. El cuerpo de una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " proporciona el contenido predeterminado del área de arrastrar y soltar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los módulos en sí no pueden contener áreas de arrastrar y soltar. Para proporcionar a los creadores de contenido una interfaz para agregar contenido uniforme dentro de un módulo, utiliza en su lugar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/module-theme-fields#repeaters",
        children: "campos y grupos repetibles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " puede contener los siguientes parámetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "class"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una clase agregada al div envolvente de un dnd_area"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se utiliza en el editor para etiquetar el área en la barra lateral."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_area \"unique_name\", class=\"main\" %}\n\n{% end_dnd_area %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"container-fluid main\">\n  <div class=\"row-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n    </div>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " también pueden contener etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " un creador de contenido puede cambiar la plantilla de una página por otra del mismo tipo, dependiendo de si tiene etiquetas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas construidas con el editor de diseño visual de arrastrar y soltar pueden cambiarse por otras plantillas de arrastrar y soltar o plantillas codificadas con o sin etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " ."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas codificadas con etiquetas ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " solo pueden cambiarse por otras plantillas codificadas con etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas codificadas ", (0, _jsxRuntime.jsx)("u", {
            children: "sin"
          }), " etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " solo pueden cambiarse por otras plantillas codificadas sin etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_section"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_section %}"
        })
      }), " es una fila de nivel superior, y debe estar anidada dentro de una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " . Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/drag-and-drop-areas/sections#section-templates",
        children: "secciones también pueden definirse como una plantilla"
      }), " y luego ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/drag-and-drop-areas/sections#section-context",
        children: "incluirse"
      }), " en una ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", lo que las hace ideales para estructurar rápidamente una plantilla."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " puede contener los siguientes parámetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "color de fondo"
            }), ". También se puede proporcionar como una cadena de texto."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar una ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "imagen de fondo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "fondo gradiente lineal"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un booleano que determina si la sección está destinada a ser de ancho completo o limitada por un contenedor interior."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar los valores de los márgenes en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Cuando no se proporciona ninguna unidad de medida, se aplica el valor predeterminado ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor en píxeles que establece el ancho máximo del contenido en un dict de envoltura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar valores de relleno en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Cuando no se proporciona ninguna unidad de medida, se aplica el valor predeterminado ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alineación vertical del contenido infantil. Los eventos disponibles incluyen:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " sólo puedes utilizar un parámetro de fondo por etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_section\n  background_image={\n    \"backgroundPosition\": \"MIDDLE_CENTER\",\n    \"backgroundSize\": \"cover\",\n    \"imageUrl\": \"https://example.com/path/to/image.jpg\"\n  },\n  margin={\n    \"top\": 32,\n    \"bottom\": 32\n  },\n  padding={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\",\n    \"left\": \"1em\",\n    \"right\": \"1em\"\n  },\n  max_width=1200,\n  vertical_alignment=\"MIDDLE\"\n%}\n\n{% end_dnd_section %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 unique_name-row-0-background-image dnd-section unique_name-row-0-max-width-section-centering unique_name-row-0-margin unique_name-row-0-padding\"\n>\n  <div class=\"row-fluid \"></div>\n  <!--end row-->\n</div>\n<!--end row-wrapper -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " también pueden contener las siguientes etiquetas:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_column"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#column",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_column %}"
        })
      }), " es un bloque estructural vertical que ocupa una o más columnas de diseño definidas por su fila principal."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta etiqueta HubL debe estar anidada dentro de una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " ."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " puede contener los siguientes parámetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "color de fondo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar una ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "imagen de fondo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "fondo gradiente lineal"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar los valores de los márgenes en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Cuando no se proporciona ninguna unidad de medida, se aplica el valor predeterminado ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor en píxeles que establece el ancho máximo del contenido en un dict de envoltura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar valores de relleno en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Cuando no se proporciona ninguna unidad de medida, se aplica el valor predeterminado ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alineación vertical del contenido infantil. Los eventos disponibles incluyen:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " solo puede utilizar un parámetro de fondo por etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_column\n  offset=0,\n  width=12,\n  background_color={\n    r: 255,\n    g: 0,\n    b: 0,\n    a: 1\n  },\n  margin={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\"\n  },\n%}\n\n{% end_dnd_column %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span12 widget-span widget-type-cell unique_name-column-1-margin unique_name-column-1-background-color unique_name-column-1-vertical-alignment dnd-column\"\n  style=\"\"\n  data-widget-type=\"cell\"\n  data-x=\"0\"\n  data-w=\"12\"\n></div>\n<!--end widget-span -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " también puede contener ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_row"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#row",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_row %}"
        })
      }), " es un bloque estructural horizontal que crea una cuadricula de diseño anidada de 12 columnas en la que se pueden colocar columnas y módulos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta etiqueta HubL debe estar anidada dentro de una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " ."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), " puede incluir los siguientes parámetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "color de fondo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar una ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "imagen de fondo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "fondo gradiente lineal"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar los valores de los márgenes en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Cuando no se proporciona ninguna unidad de medida, se aplica el valor predeterminado ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor en píxeles que establece el ancho máximo del contenido en un dict de envoltura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un dict que permite especificar valores de relleno en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Cuando no se proporciona ninguna unidad de medida, se aplica el valor predeterminado ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alineación vertical del contenido infantil. Los eventos disponibles incluyen:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " solo puedes utilizar un parámetro de fondo por etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_row\n  background_color={\n    r: 123,\n    g: 123,\n    b: 123,\n    a: 1.0\n  },\n  margin={\n    \"top\": 20,\n    \"bottom\": 200\n  },\n  padding={\n    \"top\": 20,\n    \"bottom\": 200,\n    \"left\": 20,\n    \"right\": 20\n  }\n%}\n\n{% end_dnd_row %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 main-row-0-padding main-row-0-background-color main-row-0-margin\"\n>\n  <div class=\"row-fluid \"></div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una dnd_row también puede contener las siguientes etiquetas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/drag-and-drop-areas#module",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_module %}"
        })
      }), " es un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulo"
      }), " envuelto dentro de un div donde se puede agregar diseño, estilos y contenido. El módulo se especifica haciendo referencia a su ruta, que puede ser un módulo predeterminado de HubSpot (usando el espacio de nombres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/"
      }), " ), o módulos que hayas construido, especificando su ruta dentro del árbol de archivos del administrador de diseño"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta etiqueta HubL debe estar anidada dentro de una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " ."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " puede contener los siguientes parámetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Requerido"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La ruta a un módulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El posicionamiento horizontal, admite:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IZQUIERDA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CENTRO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DERECHA"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El desplazamiento desde 0 en la cuadrícula de 12 columnas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número de columnas que ocupan la cuadrícula de 12 columnas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "flexbox_positioning"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Obsoletos"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Obsoleto no utilizar."
            }), " En su lugar, utiliza ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            }), " junto con el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            }), " de la fila o sección.", (0, _jsxRuntime.jsx)("br", {}), "Valor de la posición Flexbox para el módulo. Admite una cadena que indica la posición vertical seguida de la horizontal:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["¿Tienes un módulo antiguo cuyo nombre de campo coincide con uno de los parámetros de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " anteriores? Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/building-blocks/modules/using-modules-in-templates#passing-fields-that-have-dnd-associated-parameters",
          children: "pasar valores predeterminados a través de un parámetro de campos"
        }), ", como lo harías con un grupo de campos."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n  path=\"@hubspot/rich_text\",\n  offset=0,\n  width=8,\n%}\n  {% module_attribute \"html\" %}\n    <h1>Hello, world!</h1>\n  {% end_module_attribute %}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span8 widget-span widget-type-custom_widget\"\n  style=\"\"\n  data-widget-type=\"custom_widget\"\n  data-x=\"0\"\n  data-w=\"12\"\n>\n  <div\n    id=\"hs_cos_wrapper_main-module-1\"\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module\"\n    style=\"\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"module\"\n  >\n    <span\n      id=\"hs_cos_wrapper_module-1_\"\n      class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n      style=\"\"\n      data-hs-cos-general-type=\"widget\"\n      data-hs-cos-type=\"rich_text\"\n    >\n      <h1>Hello, world!</h1>\n    </span>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fondo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hay varias formas de establecer fondos en los elementos dnd de columna, sección y fila, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-image",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_image"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-linear-gradient",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_linear_gradient"
        })
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-color",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_color"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_color"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las etiquetas dnd de columna, sección y fila admiten colores de fondo. Puedes establecer el color de fondo predeterminado para un elemento de arrastrar y soltar utilizando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_color"
      }), ". Este parámetro es un parámetro basado en una cadena y puede incluir los siguientes formatos indicados en el ejemplo siguiente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section %}\n  // Hex Value (both 3 and 6 char length)\n  {% dnd_column background_color=\"#F7F7F7\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"#FFF\" %}\n  {% end_dnd_column %}\n// Both RGB and RGBA\n  {% dnd_column background_color=\"rgb(255,255,255)\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"rgba(0,0,0,.25)\" %}\n  {% end_dnd_column %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_linear_gradient"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los elementos dnd de columna, sección y fila admiten gradientes lineales de fondo. Puedes establecer un gradiente predeterminado utilizando el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_linear_gradient"
      }), ". El parámetro espera un dict. Actualmente solo admite dos paradas de color."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "direction"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La dirección del gradiente.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "hasta abajo"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "hasta arriba"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "a la izquierda"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "a la derecha"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Matriz de cadenas de color. Actualmente admite 2 valores, el inicial y el final. Los valores se proporcionan como cadenas, y se admiten los siguientes formatos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGB"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGBA"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3 caracteres hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6 caracteres hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "8 caracteres hex"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_linear_gradient={\n    \"direction\": \"to bottom\",\n    \"colors\": [\n      \"#1EB6C3\",\n      \"#2A2859\"\n    ]\n  }\n  %}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_image"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los elementos dnd de columna, sección y fila admiten imágenes de fondo. Puedes proporcionar una imagen de fondo predeterminada utilizando el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_image"
      }), " que espera un dict."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Clave"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundPosition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La posición de fondo de la imagen. Admite una cadena que indica la posición vertical seguida de la horizontal.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundSize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La propiedad de tamaño de fondo CSS utilizada para la imagen.", (0, _jsxRuntime.jsx)("br", {}), "Los valores admitidos son:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "portada"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "contiene"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "auto"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "imageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL absoluta de la imagen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_image = {\n      \"backgroundPosition\": \"MIDDLE_CENTER\",\n      \"backgroundSize\": \"cover\",\n      \"imageUrl\": \"https://www.example.com/bg-image.jpg\"\n    },\n%}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cómo se trasladan los parámetros de estilo de dnd a la página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando utilizas parámetros basados en estilo, como los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background",
        children: "fondos"
      }), ", los márgenes o el relleno, los nombres de las clases se calculan automáticamente para las secciones, columnas, filas y módulos. Los valores de propiedad que has asignado se agregan a los nombres de clase creados automáticamente y el código CSS resultante se coloca antes de la etiqueta de cierre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), " en la página en una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/responsive-breakpoints",
        children: "estilos de arrastrar y soltar también pueden ser diferentes en distintos puntos de ruptura"
      }), " para ofrecer un aspecto responsivo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}