"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 165176569516;
const slug = exports.slug = 'guides/api/crm/extensions/third-party-calling';
const title = exports.title = 'CRM API | Set up third-party calling in help desk (BETA)';
const name = exports.name = 'Set up third-party calling in help desk (BETA)';
const metaDescription = exports.metaDescription = 'You can set up third-party calling in your HubSpot help desk';
const badge = exports.badge = 'BETA';
const toc = exports.toc = [{
  "depth": 0,
  "id": "set-up-third-party-calling-in-help-desk-beta",
  "label": "Set up third-party calling in help desk (BETA)",
  "parent": null
}, {
  "depth": 1,
  "id": "set-up-help-desk-support",
  "label": "Set up help desk support",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}, {
  "depth": 2,
  "id": "webhook-details",
  "label": "Webhook details",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}, {
  "depth": 3,
  "id": "request",
  "label": "Request",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}, {
  "depth": 3,
  "id": "expected-response-schema",
  "label": "Expected response schema",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}, {
  "depth": 2,
  "id": "create-and-manage-your-webhook",
  "label": "Create and manage your webhook",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}, {
  "depth": 3,
  "id": "create-channel-connection-settings",
  "label": "Create channel connection settings",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}, {
  "depth": 3,
  "id": "fetch-existing-channel-connection-settings",
  "label": "Fetch existing channel connection settings",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}, {
  "depth": 3,
  "id": "update-channel-connections-settings",
  "label": "Update channel connections settings",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}, {
  "depth": 3,
  "id": "delete-existing-channel-connection-settings",
  "label": "Delete existing channel connection settings",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}, {
  "depth": 3,
  "id": "understand-the-isready-flag",
  "label": "Understand the isReady flag",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}, {
  "depth": 4,
  "id": "user-experience",
  "label": "User experience",
  "parent": "set-up-third-party-calling-in-help-desk-beta"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h2: "h2",
      h3: "h3",
      p: "p",
      strong: "strong",
      a: "a",
      h4: "h4",
      h5: "h5",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h6: "h6",
      ul: "ul",
      li: "li",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up third-party calling in help desk (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set up help desk support"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " developers must have completed the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/calling-sdk",
          children: "SDK setup"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/extensions/calling-extensions/receive-incoming-calls#integrate-the-hubspot-mobile-chat-sdk-into-your-ios-app-beta-",
          children: "inbound calling API"
        }), " steps to proceed."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To support connecting a phone number as a channel to your help desk, you’ll need to tell HubSpot how to fetch available numbers to be connected. To do this, register a webhook that'll return available numbers for the user. Whenever a HubSpot user wants to connect a device number from your extension to the help desk workspace, HubSpot will make a request to the registered webhook to get a list of available numbers that the user can select from."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Webhook details"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Request"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get the phone numbers available for channel connection, HubSpot will send a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to your registered webhook with the following request body parameters:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the app for which this request was made."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the HubSpot portal where the request originated from."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "userId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the HubSpot user making the request."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The request will also include HubSpot signature headers to prove that the request came from HubSpot. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "validate requests"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Expected response schema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The endpoint that you provide to HubSpot should return a JSON-formatted response that provides a list of available phone numbers. Each phone number in the list should include the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e164PhoneNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The phone number, which will be in E.164 format (e.g., +18001231234)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "extension"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "While the webhook response schema accepts phone numbers with extensions, phone numbers with extensions aren't connected to the help desk at this time. Any phone numbers with extensions won't be selectable by the user (e.g., \"1\")."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "friendlyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot enforces a 24-character limit for friendly names for phone numbers. Any friendly name that's longer than 24 characters will be truncated to the first 24 characters by HubSpot."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if your phone number is 18001231234, your extension is 1, and you want to label this number “My cell phone number”, the response you send back to HubSpot should be:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"phoneNumbers\": [\n    {\n      \"e164PhoneNumber\": \"+18001231234\",\n      \"extension\": \"1\",\n      \"friendlyName\": \"My cell phone number\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Create and manage your webhook"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following endpoints are available to create and manage your webhook."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Create channel connection settings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create channel connections settings, use the appId of your public app to make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/extensions/calling/{appId}/settings/channel-connection"
      }), ", and include the following in your request body:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The target URL for this webhook."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isReady"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Determines whether this webhook is ready to be released to users of this extension. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#isready",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "isReady"
              })
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if your endpoint URL was ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://example.com/my-help-desk-service"
      }), " and you wanted to make it available immediately for HubSpot to call, the request body would be:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"url\": \"https://example.com/my-help-desk-service\",\n  \"isReady\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Fetch existing channel connection settings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To fetch existing channel connection settings, use the appId of your public app to make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/extensions/calling/{appId}/settings/channel-connection"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If your channel connection settings were already set up correctly, the response will resemble the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"url\": \"https://example.com/my-help-desk-service,\n  \"createdAt\": \"2024-04-30 12:01\",\n  \"updatedAt\": \"2024-04-30 12:01\",\n  \"isReady\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Update channel connections settings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update channel connections settings, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/extensions/calling/{appId}/settings/channel-connection"
      }), ", and include one or both of the following fields in your request body:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The target URL for this webhook."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isReady"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Determines whether this webhook is ready to be released to users of this extension. Learn more about ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "isReady"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Delete existing channel connection settings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete existing channel connection settings, use the appId of your public app to make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/extensions/calling/{appId}/settings/channel-connection"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A successful ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " call will result in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "204 No Content"
      }), " success status response code."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Understand the isReady flag"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A third-party calling app is considered to support channel connection when the app has a webhook registered that's marked with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isReady=true"
      }), ". If your app supports third-party connections, all users who have this app installed will have the option to select the app when they connect a number to their help desk. Registering your webhook with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isReady=false"
      }), " at first allows you to register the webhook and test it out without releasing it to all the users of your application immediately. To test your webhook’s help desk channel connection, you can override the isReady flag in-browser by setting local storage flag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LocalSettings:Calling:supportsChannelConnection=true"
      }), " (see below). Once you have determined that the webhook’s help desk channel connection is working properly and you're ready for users to use your webhook, you can send a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to update the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isReady"
      }), " flag to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " and release the functionality to users."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// To unlock your webhook for in-browser help desk channel connection testing, run this\n// command in your browser's dev tools console\nwindow.localStorage.setItem(\n  'LocalSettings:Calling:supportsChannelConnection',\n  true\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h6, {
      children: "User experience"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are some examples of the user experience when choosing a calling provider for help desk. Learn more about the user experience."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isReady=false"
        }), " and local storage flag is ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), ": the app will appear greyed out for users when choosing a calling provider."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/test-app-1.png",
        alt: "test-app-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isReady=true"
        }), " or local storage flag is ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), ", the users will be able to select the app when choosing a calling provider."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/test-app-2.png",
        alt: "test-app-2"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}