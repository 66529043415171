"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77681100125;
const slug = exports.slug = 'guides/api/overview';
const title = exports.title = 'Como usar APIs da HubSpot';
const name = exports.name = "LATAM BR (PT) vNext Docs DP - How to use HubSpot's APIs";
const metaDescription = exports.metaDescription = 'A API da HubSpot permite que desenvolvedores criem integrações e aplicativos personalizados. Para começar, leia a visão geral sobre APIs e crie uma conta de desenvolvedor.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criando-aplicativos",
  "label": "Criando aplicativos",
  "parent": null
}, {
  "depth": 1,
  "id": "aplicativos-p%C3%BAblicos",
  "label": "Aplicativos públicos",
  "parent": "criando-aplicativos"
}, {
  "depth": 1,
  "id": "aplicativos-privados",
  "label": "Aplicativos privados",
  "parent": "criando-aplicativos"
}, {
  "depth": 0,
  "id": "autentica%C3%A7%C3%A3o",
  "label": "Autenticação",
  "parent": null
}, {
  "depth": 0,
  "id": "uso-e-limites",
  "label": "Uso e limites",
  "parent": null
}, {
  "depth": 0,
  "id": "parceiros-de-aplicativos-e-o-marketplace-de-aplicativos",
  "label": "Parceiros de aplicativos e o Marketplace de aplicativos",
  "parent": null
}, {
  "depth": 0,
  "id": "saiba-como-usar-apis-e-criar-aplicativos-na-hubspot-academy",
  "label": "Saiba como usar APIs e criar aplicativos na HubSpot Academy",
  "parent": null
}, {
  "depth": 2,
  "id": "links-r%C3%A1pidos",
  "label": "Links rápidos",
  "parent": "saiba-como-usar-apis-e-criar-aplicativos-na-hubspot-academy"
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "saiba-como-usar-apis-e-criar-aplicativos-na-hubspot-academy"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Guias e recursos para desenvolvedores de APIs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As APIs da HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "cuja documentação de referência está disponível aqui"
      }), ", permitem que você crie um aplicativo funcional ou uma integração de forma rápida e fácil. Esta é uma visão geral do que você precisará para usá-las."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criando aplicativos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de começar, você deve decidir o tipo de aplicativo que deseja criar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicativos públicos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você quiser criar um aplicativo que possa ser instalado por outros usuários da HubSpot fora da sua organização, como um aplicativo listado no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "marketplace de aplicativos"
      }), ", crie um aplicativo público."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você estiver criando um aplicativo público, será necessário criar uma conta de desenvolvedor de aplicativo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Primeiro, acesse ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/get-started",
          children: "esta página"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar uma conta de desenvolvedor de aplicativos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Autentique usando sua conta do Google ou da Microsoft ou insira seu e-mail."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Continue seguindo as instruções de configuração para criar sua conta de desenvolvedor."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em seguida, você poderá criar um novo aplicativo, configurar a OAuth e criar um ambiente de testes. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview",
        children: "Comece a criar agora."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicativos privados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se o seu objetivo é criar uma integração que somente será aproveitada por outros usuários na sua conta da HubSpot, como um aplicativo interno que pode acessar ou modificar dados de contatos da sua conta, você pode criar um aplicativo privado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como criar um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "aplicativo privado"
      }), " neste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autenticação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A maioria dos endpoints de APIs da HubSpot é compatível com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "tokens de acesso de aplicativo privado"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " a partir de 30 de novembro de 2022, as chaves de API da HubSpot serão desativadas e não terão mais suporte. Continuar usando as chaves de API da HubSpot representará um risco de segurança para sua conta e dados. Durante esta fase, a HubSpot poderá desativar sua chave a qualquer momento."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Você deve autenticar usando um token de acesso de aplicativo privado ou OAuth. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "essa alteração"
        }), " e como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migrar uma integração de chave de API"
        }), " para usar um aplicativo privado."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "No entanto, se você quiser listar seu app em nosso Marketplace de app da HubSpot ou se vários usuários quiserem instalar o app, use o OAuth."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você estiver criando um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "app privado"
        }), ", você pode fazer chamadas usando o token de acesso do seu app, que também implementa o OAuth."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso e limites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aprenda sobre nossas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "diretrizes de uso"
      }), " e limites de taxa e veja como verificar o uso de chamadas de API."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parceiros de aplicativos e o Marketplace de aplicativos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Revise os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "requisitos de listagem de aplicativos"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "crie sua lista de aplicativos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Saiba como usar APIs e criar aplicativos na HubSpot Academy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre as APIs da HubSpot e as contas de desenvolvedor e veja como fazer chamadas usando o OAuth e as chaves de API nestes vídeos curtos (e gratuitos!) ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/pt/courses/integrating-with-hubspot-foundations",
        children: "Vídeos"
      }), " da HubSpot Academy."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Links rápidos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
            children: "Criar uma conta de desenvolvedor"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types#developer-test-accounts",
            children: "Configurar uma conta de teste de desenvolvedor"
          }), " para instalar seu aplicativo e testar chamadas de API"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Inscrever-se no ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/changelog",
            children: "log de alterações"
          }), "para se manter atualizado"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Participar de conversas ou fazer perguntas nos ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
            children: "fóruns da comunidade de desenvolvedores"
          }), " da HubSpot"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Tornar-se membro da nossa ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/slack/developer-slack",
            children: "comunidade para desenvolvedores do Slack"
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "Documentos de referência de API"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}