"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 72826539949;
const slug = exports.slug = 'guides/api/crm/commerce/discounts';
const title = exports.title = 'CRM API | Discounts';
const name = exports.name = 'CRM API | Discounts';
const metaDescription = exports.metaDescription = 'Learn how to create and associate a discount as part of the pricing details of a quote.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-discount",
  "label": "Create a discount",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      pre: "pre",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Discounts"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you're ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes",
        children: "creating a quote in HubSpot"
      }), ", you can create and associate a discount as part of the pricing details of the quote."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a discount"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Discounts are used in conjunction with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/fees",
        children: "fees"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/taxes",
        children: "taxes"
      }), " when determining the pricing details for a quote. Any discounts you associate with your quote will be applied first, followed by associated fees, and then any associated taxes will apply."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubspi.com/crm/v3/objects/discount\n{\n  \"properties\": {\n    \"hs_label\": \"A fixed, one-time discount\",\n    \"hs_duration\": \"ONCE\",\n    \"hs_type\": \"PERCENT\",\n    \"hs_value\": \"50\",\n    \"hs_sort_order\": \"2\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you create a discount, you can use its ID to associate it with a quote. To retrieve a list of discounts you've created, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/discount"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available endpoints and their required fields, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " tab at the top of this article."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}