"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 41185540903;
const slug = exports.slug = 'reference/cms/modules/files';
const title = exports.title = 'カスタムモジュールのコーディング';
const name = exports.name = 'APAC JAPAN (ja) | module files_JA | 202103';
const metaDescription = exports.metaDescription = 'カスタムモジュールは、ウェブサイト全体で使用できる再利用可能な要素です。速度、ユーザーエクスペリエンス、アクセシビリティーに関するベストプラクティスを考慮しながら構築します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "html-%2B-hubl%EF%BC%88module.html%EF%BC%89",
  "label": "HTML + HubL（module.html）",
  "parent": null
}, {
  "depth": 0,
  "id": "css%EF%BC%88module.css%EF%BC%89",
  "label": "CSS（module.css）",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E5%80%A4%E3%81%AB%E5%9F%BA%E3%81%A5%E3%81%8F%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB",
  "label": "モジュールフィールド値に基づくスタイル",
  "parent": "css%EF%BC%88module.css%EF%BC%89"
}, {
  "depth": 2,
  "id": "css%E3%82%AF%E3%83%A9%E3%82%B9",
  "label": "CSSクラス",
  "parent": "css%EF%BC%88module.css%EF%BC%89"
}, {
  "depth": 1,
  "id": "require_css%E3%83%96%E3%83%AD%E3%83%83%E3%82%AF",
  "label": "require_cssブロック",
  "parent": "css%EF%BC%88module.css%EF%BC%89"
}, {
  "depth": 1,
  "id": "%E3%82%A4%E3%83%B3%E3%83%A9%E3%82%A4%E3%83%B3%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "インラインスタイルを追加する",
  "parent": "css%EF%BC%88module.css%EF%BC%89"
}, {
  "depth": 1,
  "id": "%E7%89%B9%E5%AE%9A%E3%81%AEcss%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88%E3%81%99%E3%82%8B",
  "label": "特定のCSSファイルをインポートする",
  "parent": "css%EF%BC%88module.css%EF%BC%89"
}, {
  "depth": 0,
  "id": "javascript%EF%BC%88module.js%EF%BC%89",
  "label": "JavaScript（module.js）",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E5%80%A4%E3%81%AB%E5%9F%BA%E3%81%A5%E3%81%8F%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%83%88%E8%A8%98%E8%BF%B0",
  "label": "フィールド値に基づくスクリプト記述",
  "parent": "javascript%EF%BC%88module.js%EF%BC%89"
}, {
  "depth": 1,
  "id": "%E3%83%87%E3%83%BC%E3%82%BF%E5%B1%9E%E6%80%A7",
  "label": "データ属性",
  "parent": "javascript%EF%BC%88module.js%EF%BC%89"
}, {
  "depth": 1,
  "id": "require_js%E3%83%96%E3%83%AD%E3%83%83%E3%82%AF",
  "label": "require_jsブロック",
  "parent": "javascript%EF%BC%88module.js%EF%BC%89"
}, {
  "depth": 1,
  "id": "require_js",
  "label": "require_js",
  "parent": "javascript%EF%BC%88module.js%EF%BC%89"
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E6%83%85%E5%A0%B1",
  "label": "関連情報",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      img: "img",
      h2: "h2",
      pre: "pre",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "モジュールファイル"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページ、ブログ、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/quotes",
        children: "見積もり"
      }), "（英語）のモジュールを作成する場合は、モジュールのコンテンツ、スタイル設定、機能を制御するための3つのフロントエンド関連ファイルがモジュール内にあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.html"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.css"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.js"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Eメールモジュールは、module.cssとmodule.jsに対応していません。理由としては、EメールクライアントではJavaScriptがサポートされないことと、リンクされたCSSファイルのサポートが限られていることが挙げられます。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらのファイルは、モジュールのインスタンスがページ上にある場合には、常にページ上にレンダリングされます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定のページ内に同じモジュールのインスタンスが複数ある場合、HubSpotによってそのモジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "が1回だけ読み込まれます。既定では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "が非同期的に読み込まれることはありませんが、モジュールのmeta.jsonに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/modules/configuration",
        children: "css_render_options and js_render_options"
      }), "を含めることで、この動作を変更できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールはデザインマネージャー内で作成するか、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "HubSpot CLI"
      }), "を使用してローカル環境で作成できます。モジュールファイルは、デザインマネージャー上ではマルチペインエディターに表示されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカルでモジュールを確認すると、ファイルはmodule-name.moduleフォルダーに格納されます。", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module1.png",
        alt: "cms-dev-custom-module1"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールの作成と管理にデザインマネージャーまたはCLIのいずれを使用するかは、チームの状況や好みに応じて決めます。", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module0.png",
        alt: "cms-dev-custom-module0"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "効率的な開発者ワークフローの構築"
      }), "に記載されている推奨事項を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTML + HubL（module.html）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "module.htmlファイルはHTMLおよびHubLを対象としています。一般に、モジュールがページエディターまたはテンプレートファイルのどこに配置されているかに応じて、module.htmlファイルの内容がどこにレンダリングされるかが決まります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このファイルは、モジュールがどこに配置されていても、ページ内で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/hubl#including-files-in-files",
        children: "HubL include"
      }), "と同じように動作します。module.htmlファイルからは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/modules#using-module-field-data-to-render-html",
        children: "HubLを使ってモジュールのフィールド値にアクセスできます"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CSS（module.css）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールにCSSを追加するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "ファイルを使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["一般に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "ではHubLのごく一部のサブセットのみがサポートされます。ただし、モジュールとリンクされたアセットとして画像を追加する場合に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#module-asset-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_asset_url(\"my-image.png\")"
        })
      }), "を使用できます。これにより、画像などのアセットをモジュール自体にパッケージ化できます。例："]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial-module__wrapper {\n  background: url('{{ module_asset_url('bg-pattern.png') }}');\n  background-repeat: repeat;\n  min-height: 200px;\n  width: 100%;\n  display: block;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここでは、モジュール内のフィールドに基づいてモジュールが動的に変化するように、モジュールのCSSを設定する方法を説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュールフィールド値に基づくスタイル"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールのフィールドに基づいてモジュールのスタイルに効果を与えるには、いくつかの方法があります。特定の用途に応じて最適な方法を選択してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#css-classes",
          children: "CSSクラス"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#require-css-block",
          children: "require_cssブロック"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#inline-styles",
          children: "インラインスタイル"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "CSSクラス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["編集時に選択可能なオプションをモジュールのスタイルにあらかじめ設定しておくには、モジュールフィールドを追加して、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "ファイルのCSSクラスに対応するクラスを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "ファイル内で設定することができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、画像とテキストの1つのモジュールがある状況が考えられます。選択フィールドに基づいてテキストの右または左に画像を制作担当者が配置できるようにする必要がありします。このためには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "ファイルを次のように設定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n\n<section class=\"img-text__wrapper img-text--{{ module.positioning }}\" aria-label=\"{{ module.heading }}\">\n  {# module.position is a choice field with two values \"img-left\" or \"img-right\". This dictates the order they appear on desktop. Controlled by CSS #}\n  <div class=\"img-text__img\">\n      <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\">\n  </div>\n  <div class=\"img-text__text\">\n      <h2>\n        {% inline_text field=\"heading\" value=\"{{ module.heading }}\" %}\n      </h2>\n      {% inline_rich_text field=\"text\" value=\"{{ module.text }}\" %}\n  </div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "/* module.css */\n\n/* CSS that makes the image show adjacent to the text, \nand positioned based on the positioning field.*/\n\n/* The media query ensures that on mobile the image \nwill always appear above the text for \nvisual consistency. */\n\n@media (min-width: 768px) {\n  .img-text__wrapper {\n    display: flex;\n    align-items: row;\n  }\n  .img-text__img,\n  .img-text__text {\n    flex: 1;\n    padding: 10px;\n  }\n  .img-text--img-right {\n    flex-direction: row-reverse;\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_cssブロック"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定のプロパティーを制作担当者が直接制御できる必要があり、クラスが適切でない場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "ブロックにスタイルタグを使用する方法が最適です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["クラスを使用することなく制作担当者が特定のプロパティーを直接制御できるようにするには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "タグ内に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "ファイルにスタイルを追加できます。例："]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n  <style>\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  </style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "ではHubLをレンダリングできるので、モジュールフィールド値をCSS変数として使用できます。制作担当者がページエディター上でフィールドを更新すると、該当するCSSが更新されます。このようなブロックによって、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), "タグが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "ステートメント内のページの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), "に移されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CSSを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scope_css"
      }), "タグで囲むことにより、CSSをモジュールインスタンスにのみ適用することもできます。例えば、上記のモジュールコードは次のように変更できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n<style>\n  {% scope_css %}\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  {% end_scope_css %}\n</style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "インラインスタイルを追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "わずかな数のプロパティーを制作担当者が細かく制御できる必要があり、クラスが適切でない場合は、HTMLのスタイル属性に値を直接追加することができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Module.html #}\n<div style=\"background: rgba({{ module.bg_color.color|convert_rgb }},{{ module.bg_color.opacity/100 }});\">\n  {% inline_rich_text field=\"richtext\" value=\"{{ module.richtext }}\" %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロパティーの数が多くてコードが読みにくくなった場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "ブロック手法への切り替えを検討してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "特定のCSSファイルをインポートする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "は、module.htmlに追加することで、特定のモジュールまたはテンプレートの表示に特定のCSSファイルが必要なことをHubSpotに示すためのHubL関数です。cssファイルを指すリンクタグが、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "内でページの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), "に追加されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "関数では、特定のページ上のモジュールやテンプレートで同じCSSファイルが何回必要とされるかにかかわらず、そのCSSファイルが1回だけ読み込まれます。複数のモジュール間でスタイルが共有される可能性があっても、サイトの全ページで使われるメインのスタイルシートにCSSを直接追加することが望ましくない状況では、この方法が優れています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "もリンクによるCSSファイルも同じ目的で利用できますが、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "はフィールド値に基づく条件付きで使用できます。これにより、不要なコードの読み込みが防止されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{ require_css(get_asset_url(\"/modules/shared_layout_styles.css\")) }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScript（module.js）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScriptをモジュールに追加するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "ファイルを使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "ファイルと同様に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "ファイルでもHubLはサポートされません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "フィールド値に基づくスクリプト記述"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールにはいくつかの作成方法があり、フィールド値に基づくJavaScriptの動作が異なります。どの方法をどのような状況で使用するかを理解しておくことで、モジュールが使用される全てのページでパフォーマンス面でのメリットが得られます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、カスタム画像モジュールがあり、Lightboxで画像が開くように制作担当者が設定できる必要があるとします。制作担当者がこの設定を必要とするのは特定の画像のみで、全てのモジュールインスタンスとは限りません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "データ属性"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["データ属性は、開発者が要素に追加するHTML 5標準のカスタム属性です。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "class=\"yourClassName\""
      }), "と同じように、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-your-attribute=\"yourValue\""
      }), "が全ての要素でサポートされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html-->\n<div class=\"img-module img-module__wrapper\" data-lightbox=\"{{ module.is_lightbox_enabled }}\" data-caption=\"above\">\n  <!-- module.is_lightbox_enabled is a boolean field, module.caption_position is a choice field. -->\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "データ属性を使用すると、module.jsファイルでの処理用としてモジュールインスタンスのフィールド値を渡すことができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この値をmodule.jsファイル内で使用するには、モジュールの全てのインスタンスに対してループ処理を行う必要があります。モジュールに固有のクラス名をモジュールの最も外側にあるラッパー要素に追加することで、その名前を対象として各モジュールインスタンスをループ処理できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// module.js\n\nlet imgModules = document.getElementsByClassName('img-module');\nArray.from(imgModules).forEach(function (element) {\n  // loop through each of the instances of the module\n  // set data attributes to variables to make it easy to work with\n  let isLightboxEnabled = element.dataset.lightbox;\n  let captionStyle = element.dataset.caption;\n  if (isLightboxEnabled) {\n    element.addEventListener('click', function () {\n      showLightbox(captionStyle); // Execute your code for the action you want to take, you can pass your data attributes into functions from libraries.\n    });\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "データ属性によって、module.js内で各モジュールインスタンスのフィールド値を取得できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_jsブロック"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "JavaScriptテンプレートライブラリーや、Vue.jsまたはReact.jsなどのリアクティブフレームワークを使用する高度なケースでは、データの出力だけを自分で対応し、レンダリングはフレームワーク側で処理する方が望ましい場合があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この場合、テンプレートスクリプトからアクセス可能な変数を提供するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), "ブロックで囲んだスクリプトタグを使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% require_js %}\n<script>\n  let myArray = [\n    {%- for item in module.repeating_text_field -%}\"{{ item }}\",{%- endfor -%}\n  ];\n</script>\n{% end_require_js %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この手法は、レンダリング元となる初期データセットを高度なアプリケーションに提供する際に役立ちます。これにより、データを取得するための最初のJavaScript呼び出しが不要になります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), "は、特定のモジュールまたはテンプレートの正常な読み込みに特定のJavaScriptファイルが必要であることを、HubSpotに示すためのHubL関数です。この関数には、ファイルへのパスと、ファイルの追加先となる位置（“head”または“footer”）の2つの入力パラメーターがあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュール内では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), "をmodule.htmlにのみ追加できます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), "ステートメントで参照されるJavaScriptファイルは、ページ内のモジュールやテンプレートで何回必要とされるかにかかわらず、ページごとに1回だけ読み込まれます。これによりHTTPリクエスト数が減少し、コードの重複も防止できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これは次のような状況で役立ちます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["同じJavaScriptを必要とする複数のモジュールまたはテンプレートがある場合、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), "を使用することでモジュール間でスクリプトを共有できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["webpackのようなJavaScriptバンドラーを使用する場合、jsファイルを特定の1つの位置に出力する方が簡単なことがあります。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), "を使用すると、JavaScriptをモジュールに関連付けることができます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), "もリンクによるJavaScriptファイルも同じ目的で利用できますが、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), "にはフィールド値に基づく条件を付けることができます。これにより、不要なコードの読み込みが防止されます。さらに、必要な場合にはJavaScriptをheadに読み込む方法もあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["JavaScriptはレンダリングの妨げになるので、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), "がJavaScriptを配置する位置は既定でfooterになっています。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "パフォーマンスの最適化に関する詳細をご覧ください。"
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連情報"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "CMS Hubで構築したウェブサイトのパフォーマンス最適化"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "モジュール"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "モジュールフィールド"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}