"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 48816364594;
const slug = exports.slug = 'guides/cms/content/fields/brand-and-settings-inheritance';
const title = exports.title = 'ブランド設定の継承';
const name = exports.name = 'ブランド設定の継承';
const metaDescription = exports.metaDescription = 'ロゴやブランドカラーなどのブランド設定を、テーマ内、またはHubL/HTMLファイルおよびCSSファイル全体で使用する方法について説明します。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E8%A8%AD%E5%AE%9A%E5%A4%89%E6%95%B0",
  "label": "ブランド設定変数",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%83%A9%E3%83%BC",
  "label": "カラー",
  "parent": "%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E8%A8%AD%E5%AE%9A%E5%A4%89%E6%95%B0"
}, {
  "depth": 1,
  "id": "%E3%83%AD%E3%82%B4",
  "label": "ロゴ",
  "parent": "%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E8%A8%AD%E5%AE%9A%E5%A4%89%E6%95%B0"
}, {
  "depth": 1,
  "id": "%E3%83%95%E3%82%A1%E3%83%93%E3%82%B3%E3%83%B3",
  "label": "ファビコン",
  "parent": "%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E8%A8%AD%E5%AE%9A%E5%A4%89%E6%95%B0"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ブランド設定の継承"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/customize-branding-for-your-hubspot-content#customize-your-company-logo-and-colors",
        children: "ブランド設定"
      }), "を使用すると、ユーザーはHubSpotコンテンツ全体で使用される会社のブランドカラー、ロゴ、ファビコンを設定できます。これにより、テーマの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイル内、HTML/HubLファイル内、CSSファイル内のトークンを使用して、これらのブランド設定にアクセスできるようになります。モジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイル内でブランドカラーにアクセスすることもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイル内にこれらのトークンを追加した後、コンテンツ作成者はテーマ設定エディター内で値を編集できます。HTML、HubL、またはCSSでこれらのトークンを追加すると、値はハードコードされ、コンテンツ作成者がページエディターで変更することはできなくなります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下では、利用可能なブランド設定変数と実装例について説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブランド設定変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), "オブジェクトの値内、またはHTML/HubLファイルとCSSファイル内でブランド設定エリアからアクセスできるオプションを以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カラー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ブランドカラーには、テーマまたはモジュールの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json-color",
        children: "fields.jsonファイル"
      }), "内、および次のHubLトークンを使用してHTML/HubLファイルおよびCSSファイル内からアクセスできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "プライマリー："
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.primaryColor}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[0]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-primary.png",
                alt: "brand-colors-primary"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "セカンダリー："
          }), (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{brand_settings.secondaryColor}}"
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-secondary.png",
            alt: "brand-colors-secondary"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "アクセントカラー："
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor1}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor2}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor3}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-accent.png",
                alt: "brand-colors-accent"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "追加の色："
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[1]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[2]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[3]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-additional.png",
                alt: "brand-colors-additional"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["色の16進コードに直接アクセスするには、トークンに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hex"
      }), "フィルターを含めます。例：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryColor.hex}}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマまたはモジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイルにブランド設定カラーを含めるには、次の形式を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example of using the primary color in within a theme's\n// field.json file\n{\n  \"name\": \"branding_color\",\n  \"label\": \"branding_color\",\n  \"type\": \"color\",\n  \"default\": {\n    \"color\": \"#26ff55\",\n    \"opacity\": 60\n  },\n  \"inherited_value\": {\n    \"property_value_paths\": {\n      \"color\": \"brand_settings.primaryColor\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "アカウントのブランド設定に追加の色が設定されていない場合があります。コードがブランド設定に存在しない継承色を参照している場合は、次のフォールバックロジックが使用されます。"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "secondaryColor"
          }), "は最初の追加色（", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[1]"
          }), "）にフォールバックします。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor1"
          }), "は2番目の追加色（", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[2]"
          }), "）にフォールバックします。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor2"
          }), "は3番目の追加色（", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), "）にフォールバックします。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor3"
          }), "は4番目の追加色（", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[4]"
          }), "）にフォールバックします。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["その他の色（", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), "など）は", (0, _jsxRuntime.jsx)(_components.code, {
            children: "default"
          }), "値にフォールバックします。既定のプロパティーの色が設定されていない場合は、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "primaryColor"
          }), "が使用されます。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ロゴ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ブランドロゴには、モジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイル内、HTML/HubLファイルおよびCSSファイル内からアクセスできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のトークンを使用して、ブランド設定内でプライマリーロゴセットにアクセスできます："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.logos[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-logo0.png",
        alt: "brand-settings-logo0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全ての追加のロゴには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.logos[1-19]}}"
      }), "を使用してアクセスできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "さらに、次のロゴ属性にアクセスできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ロゴのURL："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.link}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ロゴのaltテキスト："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.alt}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ロゴの高さ："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.height}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ロゴの幅："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.width}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ロゴの画像へのリンク："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.src}}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ファビコン"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ブランドファビコンには、HTML/HubLファイルおよびCSSファイル内からのみアクセスできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のトークンを使用して、ブランド設定内でプライマリーロゴセットにアクセスできます："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryFavicon}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.favicons[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-favicon0.png",
        alt: "brand-settings-favicon0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全ての追加のファビコンには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.favicons[1-19]}}"
      }), "を使用してアクセスできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), "フィルターを含めることで、ロゴのURLに直接アクセスできます。例：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryFavicon.src}}"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}