import I18n from 'I18n';
import { PageTypes, Paths } from '../../../components/RoutesPublic';
import { overrideLabelOnItemAndCheckBadge } from '../navUtils';
import memoize from 'react-utils/memoize';
import { keyToNavData } from 'developer-docs-shared-components/config/navData/keys';
import { navigation } from 'developer-docs-source/navigation';
const uiComponentsNavData = keyToNavData['/reference/ui-components'];
export const uiComponentsNavConfig = memoize(() => {
  const commonPath = `${Paths.REFERENCE}/ui-components`;
  const nav = navigation[commonPath];
  const navigationItems = nav.items.map(item => overrideLabelOnItemAndCheckBadge(item, uiComponentsNavData, commonPath));
  const config = {
    commonPath,
    badge: 'BETA',
    locatorPageType: PageTypes.REFERENCE,
    hasMdxOverview: true,
    sectionLabel: I18n.text('developer-docs-ui.navigation.reference.uiComponents'),
    items: navigationItems
  };
  return config;
});