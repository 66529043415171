"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611104;
const slug = exports.slug = 'reference/cms/hubl/operators-and-expression-tests';
const title = exports.title = 'Operators & Expression Tests';
const name = exports.name = '[new] Operators & Expression Tests';
const metaDescription = exports.metaDescription = 'In order to expand the logic and functionality of your templates, HubL supports several key operators and expression tests. The operators allow you to execute math functions, make comparisons, complicate template logic, and alter what markup renders. In addition, this article contains a comprehensive list of expression tests that can be used in HubL .';
const position = exports.position = 2;
const toc = exports.toc = [{
  "depth": 0,
  "id": "operators",
  "label": "Operators",
  "parent": null
}, {
  "depth": 1,
  "id": "math",
  "label": "Math",
  "parent": "operators"
}, {
  "depth": 1,
  "id": "comparison",
  "label": "Comparison",
  "parent": "operators"
}, {
  "depth": 1,
  "id": "logical",
  "label": "Logical",
  "parent": "operators"
}, {
  "depth": 1,
  "id": "other-hubl-operators",
  "label": "Other HubL operators",
  "parent": "operators"
}, {
  "depth": 0,
  "id": "expression-tests",
  "label": "Expression tests",
  "parent": null
}, {
  "depth": 1,
  "id": "boolean",
  "label": "boolean",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "containing",
  "label": "containing",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "containingall",
  "label": "containingall",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "defined",
  "label": "defined",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "divisibleby",
  "label": "divisibleby",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "equalto",
  "label": "equalto",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "even",
  "label": "even",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "float",
  "label": "float",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "integer",
  "label": "integer",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "iterable",
  "label": "iterable",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "lower",
  "label": "lower",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "mapping",
  "label": "mapping",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "none",
  "label": "none",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "number",
  "label": "number",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "odd",
  "label": "odd",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "sameas",
  "label": "sameas",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "sequence",
  "label": "sequence",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "string",
  "label": "string",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "string_containing",
  "label": "string_containing",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "string_startingwith",
  "label": "string_startingwith",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "truthy",
  "label": "truthy",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "undefined",
  "label": "undefined",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "upper",
  "label": "upper",
  "parent": "expression-tests"
}, {
  "depth": 1,
  "id": "within",
  "label": "within",
  "parent": "expression-tests"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Operators & Expression Tests"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In order to expand the logic and functionality of your templates, HubL supports several key operators and expression tests. The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#operators",
        children: "operators"
      }), " allow you to execute math functions, make comparisons, complicate template logic, and alter what markup renders. In addition, this article contains a comprehensive list of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "expression tests"
      }), " that can be used in HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Operators"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Operators are symbols that tell the HubL compiler to execute various operations that result in the final markup ouput. The following section includes a list of all of the supported HubL operators."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Math"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Standard math operators can be used to calculate values in the context of a template."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "+"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Adds two objects together. You will generally use this for the addition of numbers. If you are trying to concatenate strings of lists, you should use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "~"
            }), " instead."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "-"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subtracts one number from another."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "/"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Divides numbers"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "%"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Returns the remainder from dividing numbers"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "//"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Divide two numbers and return the truncated integer result. Example: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ 20 // 7 }}"
            }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "*"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Multiplies numbers"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "**"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Raise the left operand to the power of the right operand"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num + my_number }}<br/>  <!-- 11 + 2 = 13 -->\n{{ my_num - my_number }}<br/>  <!-- 11 - 2 = 9 -->\n{{ my_num / my_number }}<br/>  <!-- 11 / 2 = 5.5 -->\n{{ my_num % my_number }}<br/>  <!-- 11 % 2 = 1 -->\n{{ my_num // my_number }}<br/> <!-- 11 // 2 = 5 -->\n{{ my_num * my_number }}<br/>  <!-- 11 * 2 = 22 -->\n{{ my_num ** my_number }}<br/> <!-- 11 ** 2 = 121 -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "13 9 5.5 1 5 22 121\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comparison"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comparison operators can be used to evaluate values for template logic. You can see some examples of comparison operators being used on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if statements here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "shorthand"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "=="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Equal to. Evaluates to true if two objects are equal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "!="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not equal to. Evaluates to true if two objects are not equal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Greater than. Evaluates to true if the left-hand side is greater than the right-hand side."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Greater than or equal to. Evaluates to true if the left-hand side is greater or equal to the right-hand side."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Less than. Evaluates to true if the left-hand side is lower than the right-hand side."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Less than or equal to. Evaluates to true if the left-hand side is lower or equal to the right-hand side."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The shorthand version of the comparison operators are usable in filters that involve testing an expression such as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|selectattr()"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num == my_number }}<br/>  <!-- false -->\n{{ my_num != my_number }}<br/>  <!-- true -->\n{{ my_num > my_number }}<br/>   <!-- true -->\n{{ my_num >= my_number }}<br/>  <!-- true -->\n{{ my_num < my_number }}<br/>   <!-- false -->\n{{ my_num <= my_number }}<br/>  <!-- false -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "false true true true false false\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Logical"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Logical operators allow you to combine multiple expressions into single statements."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "and"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Return true if the left and the right operand are true."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "or"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Return true if the left or the right operand is true."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "not"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Negates a statement and is used in conjunction with is. See examples below."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "(expr)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Group an expression for the order of operations. For example, (10 - 2) * variable."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "?:"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/if-statements",
              children: "ternary operator"
            }), " accepts 3 arguments (expression, true condition, false condition). Evaluates an expression and returns the corresponding condition."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Other HubL operators"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are other important HubL operators that can be used to perform various tasks."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "in"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Checks to see if a value is in a sequence."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "is"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Performs an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
              children: "expression test"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "|"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Applies a filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "~"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concatenates values."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Expression tests"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Expression tests are various boolean conditions that can be evaluated by using logical operators."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "boolean"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "boolean"
      }), " expression test checks to see whether the object is boolean (in a strict sense, not in its ability to evaluate to a truthy expression)."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set isActive = false %}\n\n{% if isActive is boolean %}\nisActive is a boolean\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "isActive is a boolean\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "containing"
      }), " expression test checks to see whether a list variable has a value in it."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containing 2 %}\n\tSet contains 2!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containingall"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "containingall"
      }), " expression test checks if a list variable contains all of the values of another list."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containingall [2, 3] %}\n\tSet contains 2 and 3!\n{% endif %}\n\n{% if numbers is containingall [2, 4] %}\n\tSet contains 2 and 4!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2 and 3!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "defined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "defined"
      }), " expression test checks to see whether a variable is defined within the context of the template. While you can use this expression test, writing an if statement without any operators will default to checking whether or not the variable is defined."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the example below, a color module's color parameter is tested. If the color parameter had no value, the template would render a default black background color. If it is defined, it renders the background color set by the user."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% color \"my_color\" color=\"#930101\", export_to_template_context=True %}\n<style>\n{% if widget_data.my_color.color is defined %}\nbody{\n    background: {{ widget_data.my_color.color }};\n}\n{% else %}\nbody{\n    background: #000;\n}\n{% endif %}\n</style>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<style>\n  body {\n    background: #930101;\n  }\n</style>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "divisibleby"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The expression test ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "divisibleby"
      }), " can be used to test whether an object is divisible by another number."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, below a for loop is created that iterates through a list of types of animals. Each type of animal gets printed in a div, and every 5th div has different inline styling applied (width:100%). This concept could be applied to a blog where different markup is rendered for a certain pattern of posts. To learn more about for loops and loop.index, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "check out this article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set animals = [\"lions\", \"tigers\", \"bears\", \"dogs\", \"sharks\"] %}\n{% for animal in animals %}\n    {% if loop.index is divisibleby 5 %}\n    <div style=\"width:100%\">{{animal}}</div>\n    {% else %}\n    <div style=\"width:25%\">{{animal}}</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%\">lions</div>\n<div style=\"width:25%\">tigers</div>\n<div style=\"width:25%\">bears</div>\n<div style=\"width:25%\">dogs</div>\n<div style=\"width:100%\">sharks</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "equalto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "equalto"
      }), " expression test checks to see if a variable's value is equal to a constant or another variable. You can also use the operator == to do the same test."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the example below, the width of the blog posts is adjusted based on the total number of posts in the loop. The example output assumes there were 4 posts in the blog."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    {% if loop.length is equalto 2 %}\n        <div style=\"width:50%;\">Post content</div>\n    {% elif loop.length is equalto 3 %}\n        <div style=\"width:33.333332%;\">Post content</div>\n    {% elif loop.length is equalto 4 %}\n        <div style=\"width:25%;\">Post content</div>\n    {% else %}\n        <div style=\"width:100%;>Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "even"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "even"
      }), " expression test checks to see whether a numeric variable is an even number."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example below shows a simplified blog listing loop, where if the current iteration of the loop is even, a class of even-post is assigned to the post item div. Otherwise, a class of odd-post is assigned."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is even %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "float"
      }), " expression test checks to see whether a numeric variable is a a floating-point number."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 1.20 %}\n{% if quantity is float %}\n  quantity is a floating point number\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is a floating-point number\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "integer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Checks to see whether a variable is an ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "integer."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 120 %}\n{% if quantity is integer %}\n  quantity is an integer\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is an integer\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "iterable"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Checks to see whether a variable is ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "iterable"
      }), " and can be looped through."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This example checks a variable called \"jobs\" to see if it can be iterated through. Since the variable contains a list of jobs, the if statement would evaluate to true, and the loop would run. If the variable had contained a single value, the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if statement"
      }), " would print that value with different markup instead. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "Learn more about for loops"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set jobs = [\"Accountant\", \"Developer\", \"Manager\", \"Marketing\", \"Support\"] %}\n\n{% if jobs is iterable %}\n<h3>Available positions</h3>\n<ul>\n{% for job in jobs %}\n    <li>{{ job }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Available position</h3>\n<div class=\"single-position\">{{ jobs }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Available positions</h3>\n<ul>\n  <li>Accountant</li>\n  <li>Developer</li>\n  <li>Manager</li>\n  <li>Marketing</li>\n  <li>Support</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "lower"
      }), " expression test evaluates to true when a string is lowercase."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example below uses an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements#unless-statements",
        children: "unless statement"
      }), " and a lower filter to ensure that a string of text entered into a text module is always lowercase."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Lowercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is lower %}\n{{ widget_data.my_text.value|lower }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text that should be lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "mapping"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mapping"
      }), " expression test checks to see whether or not an object is a dict (dictionary)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example below is checking to see if the contact object is a dictionary, in which case it is."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if contact is mapping %}\nThis object is a dictionary.\n{% else %}\nThis object is not a dictionary.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This object is a dictionary.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "none"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "none"
      }), " expression test checks to see whether a variable has a null value."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"user_email\" path=\"@hubspot/text\" label=\"Enter user email\", value=\"example@hubspot.com\", export_to_template_context=True %}\n{% unless widget_data.user_email.value is none %}\n{{ widget_data.user_email.value }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "example@hubspot.com\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "number"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "number"
      }), " expression test checks to see whether or not the value of a variable is a number."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example below checks a variable to see whether or not it is a variable, and if so it converts it into millions."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = 40 %}\n{% if my_var is number %}\n{{ my_var * 1000000 }}\n{% else %}\nmy_var is not a number.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "40000000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "odd"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "odd"
      }), " expression test checks to see whether a numeric variable is an odd number."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is the same example as the inverse even expression test previously described."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is odd %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sameas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sameas"
      }), " expression test checks to see whether or not two variables have the same value."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example below sets two variables and then checks to see whether or not they are the same."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set var_one = True %}\n{% set var_two = True %}\n{% if var_one is sameas var_two  %}\nThe variables values are the same.\n{% else %}\nThe variables values are different.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The variables values are the same.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sequence"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sequence"
      }), " expression test is similar to the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "iterable"
      }), " test, in that it checks to see whether or not a variable is a sequence."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example below checks whether a variable is a sequence and then iterates through that sequence of musical genres."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set genres = [\"Pop\", \"Rock\", \"Disco\", \"Funk\", \"Folk\", \"Metal\", \"Jazz\", \"Country\", \"Hip-Hop\", \"Classical\", \"Soul\", \"Electronica\" ] %}\n{% if genres is sequence %}\n<h3>Favorite genres</h3>\n<ul>\n{% for genre in genres %}\n    <li>{{ genre }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Favorite genre:</h3>\n    <div class=\"single-genre\">{{ genres }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Pop</li>\n  <li>Rock</li>\n  <li>Disco</li>\n  <li>Funk</li>\n  <li>Folk</li>\n  <li>Metal</li>\n  <li>Jazz</li>\n  <li>Country</li>\n  <li>Hip-Hop</li>\n  <li>Classical</li>\n  <li>Soul</li>\n  <li>Electronica</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "string"
      }), " expression test checks to see whether the value stored in a variable is text."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example below checks whether or not a variable is a string, and if so it applies a title filter to change the capitalization."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = \"title of section\" %}\n{% if my_var is string %}\n{{ my_var|title }}\n{% else %}\nmy_var is not a string\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Title Of Section\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_containing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This test checks to see if a string is contained within another string. This expression test is used in conjunction with the \"is\" operator."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.domain is string_containing \".es\" %}\nMarkup that will only render on content hosted on .es domains\n{% elif content.domain is string_containing \".jp\" %}\nMarkup that will only render on content hosted on .jp domains\n{% else %}\nMarkup that will render on all other domains\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all other domains\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_startingwith"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This expression test checks to see if a string starts with a particular string. It is used in conjunction with the \"is\" operator."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.slug is string_startingwith \"es/\" %}\nMarkup that will only render on content hosted in a /es/ subdirectory\n{% elif content.slug is string_startingwith \"jp/\" %}\nMarkup that will only render on content hosted in a /jp/ subdirectory\n{% else %}\nMarkup that will render on all subdirectories\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all subdirectories\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "truthy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "truthy"
      }), " expression test checks to see whether an expression evaluates to True."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example below uses a boolean checkbox module to display an alert message."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% boolean \"check_box\" label=\"Show alert\", value=True, export_to_template_context=True %}\n\n{% if widget_data.check_box.value is truthy %}\n<div class=\"alert\">Danger!</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"alert\">Danger!</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "undefined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "undefined"
      }), " expression test checks to see whether a variable is undefined in the context of the template. This test is different from none, in that undefined will be true when the variable is present but has no value; whereas, none will be true when the variable has a null value."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example below checks a template for the existence of the variable \"my_var\"."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if my_var is undefined %}\nA variable named \"my_var\" does not exist on this template.\n{% else %}\n{{ my_var }}\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A variable named \"my_var\" does not exist on this template.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "upper"
      }), " expression test evaluates to true when a string is all uppercase. Below is an inverse example of the lower expression test above."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Uppercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is upper %}\n{{ widget_data.my_text.value|upper }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "SOME TEXT THAT SHOULD BE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "within"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "within"
      }), " expression tests checks if a variable is within a list."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if 2 is within numbers %}\n\t2 is in the list!\n{% endif %}\n\n{% if 4 is within numbers %}\n\t4 is in the list!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2 is in the list!\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}