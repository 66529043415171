"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313477;
const slug = exports.slug = 'guides/cms/content/themes/quotes-themes';
const title = exports.title = 'Erste Schritte mit dem CMS-Angebotsdesign';
const name = exports.name = 'EMEA DACH (de) Getting started from the CMS quotes theme';
const metaDescription = exports.metaDescription = 'Erstellen Sie ein benutzerdefiniertes Angebotsdesign für Ihr Unternehmen basierend auf dem von HubSpot bereitgestellten CMS-Angebotsdesign. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "voraussetzungen",
  "label": "Voraussetzungen",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-abrufen-des-designs-in-ihr-lokales-verzeichnis",
  "label": "1. Abrufen des Designs in Ihr lokales Verzeichnis",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-%C3%A4nderungen-hochladen-und-%C3%BCberwachen",
  "label": "2. Änderungen hochladen und überwachen",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-%C3%B6ffnen-sie-eine-vorlagenvorschau",
  "label": "3. Öffnen Sie eine Vorlagenvorschau",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-lokal-eine-%C3%A4nderung-vornehmen",
  "label": "4. Lokal eine Änderung vornehmen",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-das-vorlagenlabel-%C3%A4ndern",
  "label": "5. Das Vorlagenlabel ändern",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-die-angebotsvorlage-in-hubspot-anpassen",
  "label": "6. Die Angebotsvorlage in HubSpot anpassen",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-mithilfe-ihrer-neuen-vorlage-ein-angebot-erstellen",
  "label": "7. Mithilfe Ihrer neuen Vorlage ein Angebot erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "n%C3%A4chste-schritte",
  "label": "Nächste Schritte",
  "parent": null
}, {
  "depth": 0,
  "id": "weiterf%C3%BChrende-ressourcen",
  "label": "Weiterführende Ressourcen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      code: "code",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Erste Schritte mit dem CMS-Angebotsdesign"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit einem CMS-Angebotsdesign können Sie ein individuelles Angebotsdesign für Vertriebsmitarbeiter erstellen, das während des Kaufprozesses verwendet wird. In diesem Leitfaden erfahren Sie, wie Sie das standardmäßige CMS-Angebotsdesign lokal mithilfe des CLI klonen, den Klon in Ihren Account hochladen und bei Bedarf Anpassungen vornehmen. Sie erstellen dann ein Angebot mithilfe der Vorlage, um Ihre Arbeit anzuzeigen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Voraussetzungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie sollten sich sicher im Umgang mit HTML und CSS fühlen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie sollten die neueste Version der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI für Ihr Portal installiert und konfiguriert haben"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Bei diesem Tutorial wird die HubSpot CLI verwendet, Sie können dies jedoch alles in HubSpot auch mit dem Design-Manager erledigen, wenn Sie dies bevorzugen. Um diesen Vorgang in HubSpot abzuschließen, müssen Sie nur das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cms-quotes-theme"
        }), " im ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot"
        }), "-Ordner klonen, anstatt den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        }), "-Befehl auszuführen. wie in Schritt 1 gezeigt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Abrufen des Designs in Ihr lokales Verzeichnis"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Öffnen Sie Ihr Terminal und navigieren Sie zu dem Verzeichnis, in das Sie die Dateien herunterladen möchten. Dies ist Ihr Hauptarbeitsverzeichnis für den Rest dieses Tutorials."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um das Standardangebotsdesign herunterzuladen, führen Sie folgenden Befehl in Ihrem Terminal aus:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch @hubspot/cms-quotes-theme \"my-quotes-theme\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Ordner mit dem Namen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), " sollte nun in Ihrem lokalen Dateisystem vorhanden sein. Dieser Ordner enthält alle Ressourcen, die für das Angebotsdesign benötigt werden, einschließlich Mock-Daten und Modulstandardeinstellungen im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "imports"
      }), "-Ordner."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Änderungen hochladen und überwachen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn der Ordner heruntergeladen wurde, laden Sie ihn nach HubSpot hoch. Während Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "hs upload"
      }), "-Befehl verwenden können, um einen einzelnen Upload durchzuführen, können Sie stattdessen mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "-Befehl automatische Uploads bei jedem Speichern der Datei auslösen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch \"my-quotes-theme\" \"my-quotes-theme\" --initial-upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nach dem Hochladen können Sie nun den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), "-Ordner im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: "Design-Manager"
      }), " anzeigen. Um den Design-Manager vom Terminal aus zu öffnen, öffnen Sie eine neue Terminal-Registerkarte oder ein neues Fenster und führen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs open dm"
      }), "-Befehl aus."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Eine neue Terminal-Registerkarte oder ein neues Fenster wird benötigt, da Sie keine Befehle ausführen können, während der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), "-Prozess läuft. Sie können auch ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "q"
        }), " drücken, um die Überwachung zu beenden, einen anderen Befehl ausführen und dann ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), " erneut ausführen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Öffnen Sie eine Vorlagenvorschau"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So zeigen Sie eine Vorschau der Angebotsvorlage an:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie im Design-Manager zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > templates > bold.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts im Code-Editor auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Vorschau“"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Live-Vorschau mit Anzeigeoptionen“"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nehmen Sie dann bei geöffneter Vorlagenvorschau lokal eine Änderung vor, die, da ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch"
      }), " ausgeführt wird, beim Speichern automatisch hochgeladen wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Lokal eine Änderung vornehmen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Öffnen Sie in Ihrem lokalen Code-Editor ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > css > bold.css"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie den folgenden Code zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "bold.css"
        }), " hinzu und speichern Sie anschließend Ihre Änderungen:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".line-items__table tbody tr:nth-child(odd) {\n  background-color: #d6d6d6;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Aktualisieren Sie die Vorlagenvorschau in Ihrem Browser, um Ihre CSS-Änderungen anzuzeigen. Jede ungerade Zeile im Tabellentext sollte nun einen grauen Hintergrund haben."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie Ihr benutzerdefiniertes Angebotsdesign erstellen, können Sie diesen Workflow wiederholen, um die lokal vorgenommenen Änderungen schnell zu bestätigen."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Aufgrund der Komplexität des Signatursystems werden Signaturen nicht in Vorschauen angezeigt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Das Vorlagenlabel ändern"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie ein benutzerdefiniertes Angebotsdesign für dien praktischen Gebrauch vorbereiten, sollten Sie das Vorlagenlabel bewusst wählen, damit Vertriebsmitarbeiter es leicht unter den Standardangebotsoptionen von HubSpot finden können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So ändern Sie das Label einer Angebotsvorlage:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Öffnen Sie in Ihrem Code-Editor ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > templates > bold.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Öffnen Sie oben in der Datei die Anmerkungen zur Vorlage:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n templateType: quote\n isAvailableForNewContent: true\n label: Bold\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Aktualisieren Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), "-Parameter von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Fett"
        }), " auf einen Namen Ihrer Wahl, z. B. ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "Meine benutzerdefinierte Angebotsvorlage"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Speichern Sie die Datei, um sie nach HubSpot hochzuladen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Die Angebotsvorlage in HubSpot anpassen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bevor ein Vertriebsmitarbeiter Ihre Angebotsvorlage verwenden kann, muss sie in HubSpot angepasst werden. Dies wird in der Regel von einem Vertriebsleiter durchgeführt, damit er ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/deals/create-custom-quote-templates",
        children: "Angebotsvorlagen für sein Vertriebsteam erstellen"
      }), " kann. In diesem Lernprogramm werden Sie diesen Prozess jedoch selbst durchlaufen, damit Sie verstehen, wie die Erstellung von Inhalten abläuft."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So passen Sie die Angebotsvorlage an und stellen sie Vertriebsmitarbeitern zur Verfügung:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in Ihrem HubSpot-Account auf das settings ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zahnradsymbol"
        }), " in der Hauptnavigationsleiste."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie im Menü der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Objekte“ > „Angebote“"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Angebotsvorlagen“"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Angebotsvorlage anpassen“"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bewegen Sie den Mauszeiger über Ihre neue ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vorlage"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Auswählen“"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Im linken Bereich können Sie die in der Vorlage enthaltenen Module bearbeiten. Sie können beispielsweise auf ein ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modul"
        }), " klicken, um seine Eigenschaften zu bearbeiten oder die Sichtbarkeit zu ändern."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/quote-template-toggle-visibility.png",
        alt: "quote-template-toggle-visibility"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Speichern“"
        }), ", wenn Sie mit den Änderungen fertig sind."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Mithilfe Ihrer neuen Vorlage ein Angebot erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Da Ihre Änderungen gespeichert sind, können Sie jetzt ein Angebot mit der Vorlage erstellen, das das Erlebnis des Vertriebsmitarbeiters simuliert."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Sales“ > „Angebote“"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Angebot erstellen“"
        }), ". Sie werden dann zu einem Assistenten zur Erstellung von Angeboten weitergeleitet."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im ersten Bildschirm auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Einem Deal zuordnen“"
        }), " und wählen Sie dann entweder einen vorhandenen Deal aus oder ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Einen neuen Deal erstellen“"
        }), ", wenn Sie stattdessen einen Test-Deal verwenden möchten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie unten rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Weiter“"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im nächsten Bildschirm auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Angebot"
        }), "“ und wählen Sie dann Ihre benutzerdefinierte Angebotsvorlage aus."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Durchlaufen Sie den Rest des Angebots-Assistenten, um Ihr Angebot zu erstellen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Nach der Veröffentlichung des Angebots wird ein Dialogfeld mit einem Link zum Anzeigen des Angebots angezeigt. Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Kopieren“"
        }), ", um die URL zu kopieren, und fügen Sie sie dann in Ihren Browser ein, um das ausgefüllte Angebot anzuzeigen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-quote-url.png",
        alt: "copy-quote-url"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Nächste Schritte"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit Ihrer erstellten, hochgeladenen und angepassten Angebotsvorlage sollten Sie nun ein besseres Verständnis für den Ablauf der Bearbeitung der Angebotsvorlage und das Erlebnis des Content-Autors haben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie Angebotsvorlagen erstellen, die Ihren Geschäftsanforderungen entsprechen, sollten Sie versuchen, dem Angebot Ihre eigenen benutzerdefinierten Module zusammen mit den Standardmodulen von HubSpot hinzuzufügen."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Es wird empfohlen, das JavaScript der Zahlungs-, Signatur- und Download-Module ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " zu bearbeiten, da dies die Module beschädigen kann. In diesem Fall kann der Endbenutzer es möglicherweise nicht signieren, herunterladen oder sogar eine Zahlung leisten."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Weiterführende Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/quotes",
          children: "Benutzerdefinierte Angebotsvorlagen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/quote-variables",
          children: "Referenz zu benutzerdefinierten Angebotsvariablen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/deals/create-custom-quote-templates-beta",
          children: "Benutzerdefinierte Angebotsvorlagen erstellen und verwenden (aus der Perspektive des Vertriebsleiters, Sales Operations/Managers)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}