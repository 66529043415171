/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

// https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/core/src/utils.js

import { retrieveSchema } from './schemaUtils';
export function getTopLevelSchemaId( /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
schema, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
id, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
definitions, formData = {}, idPrefix = 'root') {
  const topLevelId = id || idPrefix;
  if ('$ref' in schema || 'dependencies' in schema) {
    const _schema = retrieveSchema(schema, definitions, formData);
    return getTopLevelSchemaId(_schema, id, definitions, formData, idPrefix);
  }
  if ('items' in schema && !schema.items.$ref) {
    return getTopLevelSchemaId(schema.items, id, definitions, formData, idPrefix);
  }
  if (schema.type !== 'object') {
    return topLevelId;
  }
  return topLevelId;
}
export function toIdSchema( /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
schema, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
id, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
definitions, formData = {}, idPrefix = 'root') {
  const idSchema = {
    $id: getTopLevelSchemaId(schema, id, definitions, formData, idPrefix)
  };
  for (const name in schema.properties || {}) {
    if (schema.properties.hasOwnProperty(name)) {
      const field = schema.properties[name];
      const fieldId = `${idSchema.$id}-${name}`;
      /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
      idSchema[name] = toIdSchema(field, fieldId, definitions,
      // It's possible that formData is not an object -- this can happen if an
      // array item has just been added, but not populated with data yet
      /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
      (formData || {})[name], idPrefix);
    }
  }
  return idSchema;
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export const getParamRowId = id => {
  if (id) {
    const m = id.match(/^(.*?)_.*?(-[^-]*)?$/);
    if (m) {
      if (m[2]) {
        return `${m[1]}${m[2]}`;
      }
      return m[1];
    }
  }
  return id;
};