"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31123933803;
const slug = exports.slug = 'guides/api/crm/extensions/video-conferencing';
const title = exports.title = 'Video conference extension';
const name = exports.name = 'Video conference extension';
const metaDescription = exports.metaDescription = 'These endpoints are used to plug into the meeting creation flow within HubSpot and add video conference information.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "prerequisites",
  "label": "Prerequisites",
  "parent": null
}, {
  "depth": 0,
  "id": "configure-webhook-urls",
  "label": "Configure webhook URLs",
  "parent": null
}, {
  "depth": 1,
  "id": "retrieve-webhook-urls",
  "label": "Retrieve webhook URLs",
  "parent": "configure-webhook-urls"
}, {
  "depth": 0,
  "id": "webhook-payloads",
  "label": "Webhook payloads",
  "parent": null
}, {
  "depth": 1,
  "id": "create-meeting-webhook",
  "label": "Create meeting webhook",
  "parent": "webhook-payloads"
}, {
  "depth": 1,
  "id": "update-meeting-webhook",
  "label": "Update meeting webhook",
  "parent": "webhook-payloads"
}, {
  "depth": 1,
  "id": "deleted-meeting-webhook",
  "label": "Deleted meeting webhook",
  "parent": "webhook-payloads"
}, {
  "depth": 1,
  "id": "user-verification-webhook",
  "label": "User verification webhook",
  "parent": "webhook-payloads"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ol: "ol",
      li: "li",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      ul: "ul",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tag,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Video conference extension"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the video conference API, you can configure a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "public app"
      }), " to give HubSpot users the ability to add a video conference link when ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/meetings-tool/create-and-edit-scheduling-pages#overview",
        children: "creating a meeting"
      }), ", similar to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/use-hubspots-integration-with-google-meet",
        children: "Google Meet"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/use-hubspots-integration-with-zoom",
        children: "Zoom"
      }), " integration. Using this API will involve:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Setting up your public app with video conference extension webhooks. You'll configure your app with the URIs that HubSpot will use to notify you when meetings are being created, updated, or deleted."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Setting up a custom back-end to handle meeting creation and update webhooks."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If needed, setting up your back-end to handle user identify verification webhooks."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All requests to the video conferencing API must be authenticated using your developer account's developer API key. You can include the key in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hapikey"
      }), " query parameter at the end of the request URL. You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#developer-api-keys",
        children: "find your developer API key"
      }), " in your developer account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prerequisites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before getting started, ensure you've created a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types#app-developer-accounts",
        children: "developer account"
      }), " and a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "public app"
      }), " within it."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configure webhook URLs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So that HubSpot knows where to send notifications when meetings are created, updated, or deleted, you'll first configure the extension's webhook URLs."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To do so, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/extensions/videoconferencing/settings/{appId}?hapikey=YOUR_HUBSPOT_DEVELOPER_API_KEY"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the request body, you'll need to include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUrl"
      }), " field to set the URL that HubSpot will send meeting creation webhook payloads. You can optionally include additional webhook URLs. These values can be updated later using the same request URL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createMeetingUrl"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The URL that HubSpot will notify when a new meeting has been created. This will include a JSON payload with the meeting details. The URL must use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https"
            }), " protocol."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "updateMeetingUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The URL that HubSpot will notify when an existing meeting has been updated. This will include a JSON payload with the updated meeting details. The URL must use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https"
            }), " protocol."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deleteMeetingUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The URL that HubSpot will notify when an existing meeting has been deleted. This will include a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "conferenceId"
            }), " of the deleted meeting. The URL must use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https"
            }), " protocol."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "userVerifyUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The URL that HubSpot will use to verify that the user exists in the external video conferencing system."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example Response:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example 200 response\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieve webhook URLs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve webhook URLs configured for an existing app, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/extensions/videoconferencing/settings/{appId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include the currently configured webhook URLs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example 200 response\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update- meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\",\n  \"userVerifyUrl\": \"https://example.com/user-verify\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhook payloads"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once webhook URLs are configured, HubSpot will send payloads to those URLs when a meeting create, update, or delete event occurs."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["All webhooks sent by HubSpot are HMAC signed using your app's secret. You can learn more about this in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks#security",
          children: "webhooks security documentation"
        }), " (the rest of the page does not apply to these video conference extension webhooks)."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create meeting webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a meeting is created, HubSpot will send a request to the URL specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUrl"
      }), " field. The request payload includes information about the HubSpot account, associated HubSpot user, and meeting details."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example payload\n{\n  \"portalId\": 123123,\n  \"userId\": 123,\n  \"userEmail\": \"test.user@example.com\",\n  \"topic\": \"A Test Meeting\",\n  \"source\": \"MEETINGS\",\n  \"startTime\": 1534197600000,\n  \"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the HubSpot account that the request is coming from."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "userId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the HubSpot user that is assigned to the meeting."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "userEmail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email address of the HubSpot user assigned to the meeting."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "topic"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The title of the meeting."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "source"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indicates where the meeting was created. Can be either:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MEETINGS"
                }), ": the meeting was creating from a ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/meetings-tool/create-and-edit-scheduling-pages#create-scheduling-pages",
                  children: "meeting scheduling page"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MANUAL"
                }), ": the meeting was created manually from a CRM record."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The start time of the meeting in epoch milliseconds."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The end time of the meeting in epoch milliseconds."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To successfully handle this webhook, you should generate a video conference for this meeting (or link it to an existing conference line), and respond with information about this conference. The fields expected in the response are:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), ": a unique ID associated with the conference on this event.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "This ID needs to be globally unique within your system. HubSpot will return this ID", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "back to you in the update webhook."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceUrl:"
        }), " the URL that users can use to join the conference."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceDetails"
        }), ": plain text invitation information. This should describe", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "how attendees of the event can access the video conference for this", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "event. Newlines will be maintained in representations of this text, but no other formatting is supported."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your response might look like the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"conferenceUrl\": \"https://example.com/join\",\n  \"conferenceDetails\": \"Click here to join: https://example.com/join\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update meeting webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you've specified a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUrl"
      }), " , HubSpot will send this URI a request whenever a meeting's relevant details have changed. This notification is necessary if you need to maintain the most up-to date topic or times for a video conference. The request payload includes information about the HubSpot account, associated HubSpot user, and meeting details."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"userId\": 123,\n  \"userEmail\": \"test.user@example.com\",\n  \"portalId\": 123123,\n  \"topic\": \"A Test Meeting (updated)\",\n  \"startTime\": 1534197600000,\n  \"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conferenceId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The unique identifier for the conference provided by your integration in the response to the create meeting webhook."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "userId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the HubSpot user that is assigned to the meeting. This will always be the same user as the one in the meeting creation payload."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "userEmail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email address of the HubSpot user assigned to the meeting. This will always be the same user as the one in the meeting creation payload."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the HubSpot account that the request is coming from."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "topic"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The title of the meeting."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The start time of the meeting in epoch milliseconds."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The end time of the meeting in epoch milliseconds."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No response body is required when responding to these requests. Only a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "204"
      }), " response code is required to let HubSpot know that this webhook was been received successfully."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Deleted meeting webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a meeting is deleted in HubSpot, a request will be sent to the URL specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUrl"
      }), " field. The payload will include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conferenceId"
      }), " of the deleted meeting."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example payload\n{\n  \"conferenceId\": \"some-unique-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No response body is required when responding to these requests. Only a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "204"
      }), " response code is required to let HubSpot know that this webhook was been received successfully."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "User verification webhook"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot's systems will always communicate with you about its users in terms of their HubSpot user ID and their HubSpot account email address. There is a chance that a user in HubSpot's system may exist in your system with a different email address or identifier."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before HubSpot makes a call to your system to create, update, or delete a video conference link, it will first check the app's settings for a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUrl"
      }), " field. If that URL has been set, HubSpot will make a call to it to retrieve your native user identifier. It will then send that identifier as the user's email address in the subsequent call. If you do not configure this URL, HubSpot will always assume that the user's identity is verified."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This setting is optional and is up to you to determine if it's needed. If you need to maintain a user mapping within your system, you may wish to simply map the HubSpot user ID or email to your internal user ID on each call."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The payload for this webhook contains the HubSpot account ID and the user's email address."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"portalId\": 123123,\n  \"userEmail\": \"test.user@example.com\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can return a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " response or any error code (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "404"
      }), " would be appropriate). If you return a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), ", you should return a payload containing the new ID that we should use in place of the email address:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"id\": \"any-string-id\"\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}