import styled, { css } from 'styled-components';
import { OLAF } from 'HubStyleTokens/colors';
import useMDXLayout from 'developer-docs-shared-components/hooks/useMDXLayout';
import { mediaQueries, MOBILE_MENU_BREAKPOINT } from 'developer-docs-shared-components/utils/mediaQueries';
import { TOC_RIGHT_OFFSET, TOC_WIDTH, TOC_WIDTH_NARROW } from './toc/constants';
import { CARD_BORDER_RADIUS, CARD_BOX_SHADOW, INNER_CARD_PADDING, NEXT_COMPONENT_SPACING } from 'developer-docs-shared-components/config/cssConstants';

/**
 * Indicates the main article of a public page.
 */
export const Article = styled.article.withConfig({
  displayName: "Article",
  componentId: "sc-3fs8hq-0"
})(["border-radius:", "px;padding:", "px 0;", "{margin-top:32px;width:calc(100% - ", "px);}", "{margin-top:32px;width:calc(100% - ", "px);}ul,p{margin-bottom:", "px;}p,td{line-height:1.6875rem;}li{margin-bottom:16px;}", ""], CARD_BORDER_RADIUS, INNER_CARD_PADDING, mediaQueries.aboveWidth(MOBILE_MENU_BREAKPOINT), TOC_WIDTH_NARROW + 16, mediaQueries.aboveLaptop, TOC_WIDTH + TOC_RIGHT_OFFSET + 32, NEXT_COMPONENT_SPACING, () => {
  const isMDXLayout = useMDXLayout();
  if (isMDXLayout) {
    return css(["", "{background-color:", ";box-shadow:", ";}"], mediaQueries.aboveWidth(MOBILE_MENU_BREAKPOINT), OLAF, CARD_BOX_SHADOW);
  }
  return null;
});
export default Article;