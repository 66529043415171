"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50633310868;
const slug = exports.slug = 'guides/cms/content/accessibility';
const title = exports.title = 'アクセシビリティー';
const name = exports.name = 'APAC JAPAN (JA) | [new] Accessibility | 202108';
const metaDescription = exports.metaDescription = 'HubSpotは、コンテンツのアクセシビリティー向上に役立つツールを提供できるように取り組んでいます。決して完全なガイドではないかもしれませんが、ここではコンテンツをアクセスしやすいものにするための手順について紹介します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AF%E6%A9%9F%E8%83%BD%E3%81%A7%E3%81%AF%E3%81%AA%E3%81%84",
  "label": "アクセシビリティーは機能ではない",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E4%BB%A5%E5%A4%96%E3%81%AE%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AB%E4%BB%A3%E6%9B%BF%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%82%92%E6%8F%90%E4%BE%9B%E3%81%99%E3%82%8B",
  "label": "テキスト以外のコンテンツに代替テキストを提供する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E7%82%B9%E3%81%A7%E6%9C%89%E5%88%A9%E3%81%AA%E9%81%85%E5%BB%B6%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%82%BD%E3%83%AA%E3%83%A5%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "アクセシビリティーの点で有利な遅延読み込みソリューションを使用する",
  "parent": "%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E4%BB%A5%E5%A4%96%E3%81%AE%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AB%E4%BB%A3%E6%9B%BF%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%82%92%E6%8F%90%E4%BE%9B%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%81%82%E3%82%89%E3%82%86%E3%82%8B%E3%83%AA%E3%83%B3%E3%82%AF%E3%81%A8%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E5%85%A5%E5%8A%9B%E3%81%AB%E8%AA%AC%E6%98%8E%E6%96%87%E3%82%92%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "あらゆるリンクとフォーム入力に説明文を付ける",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%B8%E3%81%AE%E3%82%B9%E3%82%AD%E3%83%83%E3%83%97%E3%83%AA%E3%83%B3%E3%82%AF%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "コンテンツへのスキップリンクを追加する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%BB%E3%83%9E%E3%83%B3%E3%83%86%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9E%E3%83%BC%E3%82%AF%E3%82%A2%E3%83%83%E3%83%97%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "セマンティックマークアップを使用する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E3%83%8A%E3%83%93%E3%82%B2%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "キーボードナビゲーション",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%BF%85%E8%A6%81%E3%81%AB%E5%BF%9C%E3%81%98%E3%81%A6role%E3%81%AB%E3%83%95%E3%82%A9%E3%83%BC%E3%83%AB%E3%83%90%E3%83%83%E3%82%AF%E3%81%99%E3%82%8B",
  "label": "必要に応じてroleにフォールバックする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AE%E3%83%92%E3%83%B3%E3%83%88",
  "label": "デザインに関するその他のヒント",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "コンテンツの作成",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot%E5%9B%BA%E6%9C%89%E3%81%AE%E6%8E%A8%E5%A5%A8%E4%BA%8B%E9%A0%85",
  "label": "HubSpot固有の推奨事項",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E3%83%99%E3%82%B9%E3%83%88%E3%83%97%E3%83%A9%E3%82%AF%E3%83%86%E3%82%A3%E3%82%B9%E3%81%AB%E6%BA%96%E6%8B%A0%E3%81%97%E3%81%9F%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%A8%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "アクセシビリティーのベストプラクティスに準拠したカスタムモジュールとテンプレートを使用する",
  "parent": "hubspot%E5%9B%BA%E6%9C%89%E3%81%AE%E6%8E%A8%E5%A5%A8%E4%BA%8B%E9%A0%85"
}, {
  "depth": 0,
  "id": "%E3%83%AF%E3%83%B3%E3%82%B9%E3%83%86%E3%83%83%E3%83%97%E3%82%BD%E3%83%AA%E3%83%A5%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AB%E6%B0%97%E3%82%92%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "ワンステップソリューションに気を付ける",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot%E9%96%8B%E7%99%BA%E8%80%85%E3%83%9D%E3%83%83%E3%83%89%E3%82%AD%E3%83%A3%E3%82%B9%E3%83%88",
  "label": "HubSpot開発者ポッドキャスト",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E8%BF%BD%E5%8A%A0%E6%83%85%E5%A0%B1",
  "label": "アクセシビリティーに関する追加情報",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      code: "code",
      pre: "pre",
      h3: "h3",
      ul: "ul",
      li: "li",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      IFrame
    } = _components;
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "アクセシビリティー"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["企業にとってウェブサイトの重要性が増し続ける中、これまでになく大切になっているのが、あらゆる訪問者にウェブサイトのコンテンツを利用してもらえるようにすることです。米国では、これは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www.section508.gov/",
        children: "508条"
      }), "コンプライアンスと呼ばれることがよくあります。これは連邦政府関係機関が障害者に電子情報や情報テクノロジーへのアクセスを可能にすることを要求する1998年の改訂リハビリテーション法の条文を指します。508条コンプライアンスも適切ですが、ウェブ上でアクセシビリティー対応コンテンツを作成するための標準としては次第に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/ja/docs/Web/Accessibility/Understanding_WCAG",
        children: "Web Content Accessibility Guidelines（WCAG）"
      }), "が使用されるようになっています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アクセシビリティーは機能ではない"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["法的な側面が、訪問者に優れたユーザーエクスペリエンスを提供する動機付けであってはなりません。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.cdc.gov/ncbddd/disabilityandhealth/infographic-disability-impacts-all.html",
        children: "CDCの報告によると、米国の4人に1人が何らかの障がいのある人です"
      }), "。ユーザーエクスペリエンスを向上する上で、訪問者の25％を無視することは、実店舗に例えれば4人の買い物客のうちの1人に対して入店を拒否するような行為です。このような顧客は不満に感じるだけでなく、再び来店することも、その店のサービスを他の人に薦めることもないでしょう。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者の間で使われる「アクセシビリティーは機能ではない」というフレーズがあります。これはアクセシビリティーを付加的な要素として扱わないこと、つまりプロジェクトの最後に対処するものと捉えないことを意味します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アクセシビリティーを確保するには、思慮、共感、理解が必要です。プロジェクトの早い段階でアクセシビリティーに対応すれば、後で設計し直す必要に迫られずに、ソリューションを設計して開発できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "多くの場合、アクセシビリティーに対応すると、開発者としての他の優先事項にも対処できます"
      }), "。ユーザーエクスペリエンス、パフォーマンス、SEOなどの懸念事項はアクセシビリティーと直接関係するからです。1つの改善が他の改善にもつながることは珍しくありません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "共感はアクセシビリティーを確保する上で重要な部分であるものの、失念されがちです。開発者は全てを自動化しようとします。何かを処理するのが難しい、または処理に時間がかかる場合、自動的に処理できるツールや、正しい処理方法を示してくれるツールを求めます。けれども残念ながら、こうしたツールはあくまで表面的な対応に過ぎません。ツールは人間ではなく、人間と同じ体験を共有することはできないからです。**ほとんどの自動アクセシビリティーテストに合格するウェブページを作成するのは、技術的には可能です。しかし、技術だけを頼りに作成したウェブページは、視覚や聴覚に障がいのある人、色盲の人、運動機能が限られているか運動機能に障がいのある人も含めた万人にとって、使い物になりません。**要件を技術的に満たすことはできても、使いにくく、操作が苛立たしいウェブページは訪問者から敬遠されてしまいます。重要な点は、ツールへの依存をやめて、成果物をテストし、ユーザーに共感し、フィードバックを集めることです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "決して完全なガイドではないかもしれませんが、ここではコンテンツをアクセスしやすいものにするための措置と、詳細に関する資料を紹介します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テキスト以外のコンテンツに代替テキストを提供する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メッセージ性がある、またはインタラクティブな全ての画像、アイコン、動画、ボタンには、代替テキストが必要です。これは、スクリーンリーダーを使用してコンテンツを利用している訪問者に適していることに加え、検索エンジン対応にも有効です。代替テキストでは、画像の内容について説明する必要があります。サイトのほとんどの画像はページエディターで編集することが可能です。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cos-pages-editor/how-do-i-add-alt-text-to-my-image",
        children: "ページエディターでは簡単に代替テキストを設定できます"
      }), "。ただし、カスタムモジュールとテンプレートの内部で、ページエディターで指定された代替テキストが使用されることを確認する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["つまり、ユーザーが入力した代替テキストが実際に使用される", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<img>"
      }), "要素が存在することを確かめる必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" />\n\nBad:\n<img src=\"{{ module.image.src }}\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "何らかの理由でページエディター上で画像を編集できない設定にしている場合は、代替テキストのハードコードが必要になります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["代替テキストに関するこのルールには例外が1つあります。画像に追加のコンテキストを用意せず、その画像をプレゼンテーション目的でのみ使用する場合は、alt属性を省略するよりも、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.w3.org/WAI/tutorials/images/tips/",
        children: "\"null\" alt text"
      }), "値を使用することが望まれます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good if only presentational: <img src=\"{{ module.image.src }}\" alt=\"\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["動画の場合、字幕に対応するサービスを利用し、文字起こし（台本）の組み込みを検討します。文字のアップロードに対応するサービスには", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.vidyard.com/hc/en-us/articles/360009876234-Make-your-Vidyard-players-accessible",
        children: "Vidyard"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://support.google.com/youtube/answer/2734799",
        children: "YouTube"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://help.vimeo.com/hc/en-us/articles/224968828-Captions-and-subtitles",
        children: "Vimeo"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://wistia.com/support/player/captions",
        children: "Wistia"
      }), "などがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アクセシビリティーの点で有利な遅延読み込みソリューションを使用する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "画像の遅延読み込みは、ウェブサイトの構築に広く使用されているパフォーマンス向上手法です。どのような遅延読み込み方法を使用するかが、アクセシビリティーに影響します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScriptソリューションでは、画像の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), "属性に、画像の真のソースではなく、代わりとなるもの（小さな", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".gif"
      }), "ファイルなど）が使われることが一般的で、ユーザーがスクロールしたビューが画像の近くに来るまでは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-src"
      }), "属性に保持されています。つまり、ページを閲覧するスクリーン リーダー ユーザーにとって、このような画像は当初、アクセス不可能になっています。特にローターを使用して、スクロールすることなくページ全体の内容を確認する場合に当てはまります。さらにJavaScriptがページに読み込まれない場合には、この遅延読み込みソリューションは正常に機能しないため、補助テクノロジーを必要とするユーザーにとって、ページ上に適切な画像がない状態になります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/native-lazy-loading/",
        children: "画像の遅延読み込みのネイティブ機能"
      }), "を使用すれば、JavaScriptが読み込まれるかどうかにかかわらず、画像のマークアップが同じ状態に保たれるので、前述の問題を回避できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img\n  src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n  loading=\"lazy\"\n  height=\"200px\"\n  width=\"400px\"\n/>\n\nBad:\n<img\n  src=\"1px.gif\"\n  data-src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n/>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのカスタムモジュールでは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/lazy-loading",
        children: "ブラウザーベースの遅延読み込み"
      }), "がサポートされています。ご活用ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "あらゆるリンクとフォーム入力に説明文を付ける"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リンク、ボタン、フォームなどの全ての要素には、その機能や移動先を示すテキストが必要です。テキストがない場合、スクリーンリーダーを使用してユーザーが選択したリンクまたはボタンが読み上げられても、何のためのものかが分かりません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アイコンを使用している場合は、スクリーンリーダー用テキストを用意してください。例えば、一部の標準HubSpotテンプレートではアイコンショートコードが使用されています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"https://www.facebook.com\"\n  ><i class=\"fab fa-facebook-f\"></i\n  ><span class=\"screen-reader-text\">Facebook</span></a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フォームラベルなどの説明文を非表示にするための", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'display: none"
      }), "'は使用しないでください。これを使用すると、視覚障がいのあるユーザーにテキストが読み上げられません。また、テキストは見えても、プレースホルダーのテキストとのコントラストを認識できないためにテキストを見分けにくいユーザーにとっては苦痛になります。ユーザーがフォームに入力していない場合やフォームが自動で入力される場合は言うまでもなく、フォームのフィールドラベルが見にくいと、フォームが正しく入力されているかどうかがユーザーには分かりません。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テキストを非表示にしなければならない場合、またはテキストを非表示にすると、視覚障がいのないユーザーには不要なコンテキストが追加される場合は、CSSを使用してスクリーンリーダーに対してはテキストを隠すことなく非表示にすることや、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'.screen-reader-text'"
      }), "クラスを追加することができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".screen-reader-text {\n  clip: rect(1px, 1px, 1px, 1px);\n  height: 1px;\n  overflow: hidden;\n  position: absolute !important;\n  width: 1px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンテンツへのスキップリンクを追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "訪問者がナビゲーションのためにスクリーンリーダーを使用している場合、または単に自分のキーボードを使用している場合、ページの繰り返し部分（ヘッダーなど）をスキップする方法があると非常に役立ちます。これはページの主要なコンテンツをポイントするリンクを追加することで実現できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ヘッダーに、以下の内容のHTMLを追加します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"#content\" class=\"screen-reader-text\"\n  >Five ways to make your site more accessible</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次に、全てのテンプレートにコンテンツのID属性を持つ要素が含まれていることを確かめます。この例では、テキストとして移動先の記事のタイトルを使用しています。これにより、検索エンジンによってリンク先の内容が認識されるようになります。意味に基づいてコンテンツに進むことが可能になります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<main id=\"content\">\n  <!-- your page or post's actual content -->\n</main>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "セマンティックマークアップを使用する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["セマンティックマークアップとは、要素の意味を伝えるHTMLのことです。セマンティック要素の例としては、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<time>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<code>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<aside>"
      }), "、および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<article>"
      }), "が挙げられます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["セマンティックでない要素の例としては、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), "や", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span>"
      }), "が挙げられます。セマンティックでない要素にも一定の意味はありますが、これらの要素は主にプレゼンテーション目的で使用し、意味を伝えるためには使用しないでください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "セマンティック要素は、検索エンジンによって意味が認識されるので、SEOの面で効果を高めるためにも、ユーザーエクスペリエンスの向上にも役立つテクノロジーです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["aria属性によって優れたユーザーエクスペリエンスを実現できる場合を除き、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), "要素はインタラクティブ要素（ボタン、タブ、リンクなど）として使用しないでください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リンク（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<a />"
      }), "）や", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<button />"
      }), "は適切に使用してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リンクは、同じページ内のセクションにリンクする場合や他のページに移動する場合に使用します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ボタンは、ページを離れたりIDに移動したりすることにならない操作に使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーブルを使用する場合、テーブルにその内容を説明するタイトルがあれば、そのタイトルを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<table>"
      }), "要素の直後にある", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<caption>"
      }), "要素に含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーブルの列と行の見出しには、テーブルの適切なscope属性を設定した", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), "要素を使用し、各セクションを示すには", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<thead>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tbody>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tfoot>"
      }), "を使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), "要素にはscope属性を設定して、行または列のどちらの見出しであるかを示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アコーディオンが必要な場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<details>"
      }), "要素", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<summary>"
      }), "要素を使用すると、アコーディオンの機能のほとんどを無償で使用できるだけでなく、使いやすいアコーディオンにすることができます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://caniuse.com/#feat=details",
        children: "古いブラウザーをサポートする必要がある場合は、必ずポリフィルまたはフォールバックを追加してください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "キーボードナビゲーション"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "一部のユーザーは、ウェブページやフォームの閲覧にキーボードを使用します。訪問者によっては、ウェブサイトの閲覧にキーボードを使用しなければならない場合や、キーボードをエミュレートするサポートデバイスが必要になる場合があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_focus.asp",
          children: "':focus"
        }), "'スタイルが設定されていることを確かめてください。これにより、ユーザーがキーボードを使用して閲覧する際にフォーカスの位置を確認できます。CSSのoutlineプロパティーを無効にするのは、許容できる別のビジュアルインジケーターを提供する場合に限ってください。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/almanac/selectors/f/focus-within/",
          children: ":focus-within"
        }), "はユーザーの注意喚起に役立つ場合に使用します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_hover.asp",
          children: "':hover"
        }), "'またはJavaScriptを使用してページの一部の表示／非表示を切り替える場合、ユーザーがJavaScriptを使用してそのコントロールを操作できること、また、情報へのアクセス方法が他にもあることを確認してください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "サイトのメインナビゲーションでキーボード操作がサポートされていることを確かめてください。ドロップダウンとフライアウトがアクセシビリティー対応になっていないという問題が失念される傾向にあります。このような問題があると、ウェブサイトの重要な部分にユーザーが到達できなくなります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["スクリーンリーダーがさまざまな要素の状態を適切に処理できるように、必要に応じて", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hidden"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-expanded"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-current"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-selected"
        }), "などの状態属性を使用し、最新の状態に維持してください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "必要に応じてroleにフォールバックする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートやコードファイルを作成する際には、ウェブブラウザーとスクリーンリーダーに対し、要素にどのような内容が含まれているかを伝えるために、適切なセマンティック要素（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dialog>"
      }), "など）を使用してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["セマンティック要素では意味が十分に伝わらないと考えられる状況では、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/ja/docs/Web/Accessibility/ARIA/Roles",
        children: "role（役割、ロール）属性"
      }), "を追加してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ランドマークを追加すると、スクリーンリーダーを使用しているユーザーがウェブページの主要なセクションの間を移動しやすくなります。HubSpotのメニュー、シンプルメニュー、Google 検索のモジュールには、role属性があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "デザインに関するその他のヒント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ユーザーがブラウザーのズーム率を200%にしても、コンテンツが表示されたままの状態で読みやすいことを確かめてください。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フォントサイズは16pxよりも極端に小さくしないことが推奨されます。訪問者がコンテンツを読みにくくなるためです。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "色だけで情報を伝えようとしないでください。色盲の方が世界の人口に占める割合は少なくありません。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "視力の弱い方がコンテンツを読めるように、テキストの色と背景色には十分なコントラストを確保してください。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "素早く（1秒あたり3回以上）点滅するアニメーションは使用しないでください。訪問者によっては発作の原因となる恐れがあります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["サイトでアニメーションを使用する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/introduction-reduced-motion-media-query/",
          children: "prefers-reduced-motion"
        }), "をサポートすることを検討してください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "現在、レスポンシブなウェブサイトの構築は必須と考えられています。全てのインタラクティブな要素がモバイル上で正常に機能することを確かめてください。インタラクティブな要素は簡単にタップできるように、タップ領域の間には十分なスペースを確保してください。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンテンツの作成"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テキストコンテンツでもアクセシビリティーを考慮する必要があることは忘れがちです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "「左側のサイドバーを参照」のような指示は使用しないでください。ユーザーがモバイルデバイスを使用している場合は通常、コンテンツが画面内に積み上げた状態に表示されるため、説明の意味がなくなります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "<h1>"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h2>"
        }), "のような見出しを使用して、見出しを連続的にネストしてください。視覚効果のために見出しを省略しないでください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "リンクを追加する場合は、「ここをクリック」というリンクテキストを使ってもスクリーンリーダーに意味は伝わりません。また、タッチスクリーンやその他のタイプのデバイスでは意味がありません。代わりに、テキストでそのリンク先の内容を説明してください。検索エンジンによってリンク先の内容も認識されるため、SEOの効果も期待できます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テキストに特殊なスタイルを設定して意味を伝える場合は、一部の訪問者に意味が伝わらなくなるため、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<mark>"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<strong>"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<em>"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<sup>"
        }), "などの適切なセマンティックタグを使用してください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "世界中の訪問者をターゲットにする場合は、地域に固有の冗談を避けます。翻訳されるサイトの場合、語呂合わせの使用は避けます。気の利いた冗談が好ましくても、適切には翻訳されないことが大半です。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "文法やスペルの誤りを回避するためのツールを使用してください。これによりテキストを理解しやすくなるだけでなく、翻訳もしやすくなります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["サイトのオーディエンスに合わせてコンテンツを作成してください。物理学者向けの天体物理学を、小学5年生にそのまま説明することはしないはずです。響きが良いというだけの理由で難しい言葉を使うのではなく、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/plain-language-experts/",
          children: "分かりやすさを優先"
        }), "してください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot固有の推奨事項"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アクセシビリティーを向上するための作業の多くは、HubSpotに固有ではありません。とはいえ当社としては、開発者や制作担当者の皆さまにビジネスの成功に向けてHubSpotを活用していただくことを願っています。開発や制作の業務においてユーザーエクスペリエンス向上のためにできることを紹介します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アクセシビリティーのベストプラクティスに準拠したカスタムモジュールとテンプレートを使用する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールまたはテンプレートをマーケットプレイスからインストールする、自分で開発する、誰かに作成してもらう、といったどのケースについても、適切なのはアクセシビリティー（a11y）のベストプラクティスに従うことです。カスタムモジュールは特に、サイトのアクセシビリティーに大きな影響を与えることがあります。例えば同じページ内だけでも、繰り返し使用されるからです。同様に1つのサイト上で、同じテンプレートが何百回あるいは何千回も使用されることがあります。サイトのアクセシビリティー対応が不十分な場合は、利益が損なわれることにもなりかねません。広告の場合と同じように、ウェブサイトを幅広いオーディエンスに利用してもらえるように、さらなる対応に取り組むことは有意義です。モジュールには、ガイドラインやベストプラクティスの発展に合わせて、コードを改良し、そのモジュールを利用している全てのページのアクセシビリティーを素早く向上できるメリットがあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのマーケットプレイスでは、アクセシビリティーに配慮し、WCAG要件を満たすことが説明に明記されているモジュールやテンプレートを探してください。開発者と連携して作業している場合は、当初から、アクセシビリティーを重要視していることを開発者に伝えてください。全ての開発者が初めからアクセシビリティーを考慮しているわけではありません。プロジェクトが進んでからでは、最初から検討することに比べて代償を伴います。優先事項として考慮していなかった場合、過去にさかのぼってアクセシビリティーの問題を修正することが必要になるからです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者の方は、制作担当者がアクセシビリティーのガイドラインに準拠しやすくなるモジュールやテンプレートを作成してください。適切な見出しやセマンティックHTMLを採用し、インタラクティブな要素には適切な役割とコンテキストを提供します。アクセシビリティーの課題を認識している制作担当者は、包摂性に配慮したモジュールやテンプレートのために作業が増えても歓迎してくれます。ただし、アクセシビリティーを考慮してモジュールを開発したことは説明しておきましょう。テンプレートにスキップリンクを含め、テンプレート内で使用するグローバルグループやグローバルパーシャルをアクセシビリティー対応にします。これはウェブサイト全体に使用され、サイトのユーザビリティーに大きな効果をもたらすことが期待できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotマーケットプレイス向けの開発では、コンテンツが、ウェブ上の膨大な数のページに使用されることがある点を十分考慮してください。コンテンツを公開したり共有したりする際には、包摂性への配慮の有無にかかわらず、多くの人に影響を及ぼすことになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ワンステップソリューションに気を付ける"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "「このスクリプトをウェブサイトに追加するだけでアクセシビリティーを実現」のように宣伝しているツールが数多く提供されています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "こうしたツールの中には、ページのさまざまな部分に意味を付加し、htmlと属性を変更することにより、問題の修正を試みるものがあります。しかし、あくまで推測による処理に過ぎないため、間違いがあったり、サイトの機能を損なったり、ユーザーにとって状況が悪化したりする場合があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "こうしたツールは、期待通りに動作するとは限らず、場合によっては専用のスクリーン リーダー システムが提供されていることもあります。ウェブサイトのUIが複雑な場合は、さらにツールが組み込まれることで、動作が困難になることもあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このような問題があるため、アクセシビリティーに対応したサイトを構築するための代用にはなりません。全てのユーザーに最適な体験を届けるためには、時間もコストも必要になります。ワンストップソリューションを採用しても、適切な開発を行う場合と比べて、コストは同程度か、割高になる可能性があります。テストおよび構築の際にアクセシビリティーを考慮する場合は、顧客に対する共感や理解が得られるメリットもあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "なお、ここで指摘しているのはテストツールではなく、アクセシビリティーの課題が解決されると宣伝しているツールです。テスト用としては優れたツールが提供されていますので、手動テストの補助として活用してみてください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アクセシビリティースクリプト／オーバーレイの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://overlayfactsheet.com/",
        children: "こちら"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot開発者ポッドキャスト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "2021年1月にHubSpotの複数の社員が、Morey Creative社のJon Sasala氏と共同でアクセシビリティーについての議論を行いました（英語）。"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/xOVTQN8phIs?feature=oembed",
      maxHeight: "113px",
      maxWidth: "200px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アクセシビリティーに関する追加情報"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アクセシビリティーに配慮したウェブサイト構築について、優れた資料が提供されています。ぜひ参照してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://a11yproject.com/",
          children: "A11Yプロジェクト"
        }), "（英語）"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/ja/docs/Web/Accessibility",
          children: "MDNのアクセシビリティーに関するドキュメント"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/WAI/",
          children: "Web Accessibility Initiative"
        }), "（英語）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/",
          children: "WebAIM"
        }), "（英語）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/wcag/checklist",
          children: "簡易版WCAG 2チェックリスト"
        }), "（英語）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/508/checklist",
          children: "508条チェックリスト"
        }), "（英語）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/TR/WCAG22/",
          children: "WCAG 2.2勧告の詳細"
        }), "（英語）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.deque.com/axe/",
          children: "DequeのAXE"
        }), "（英語）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://wave.webaim.org/",
          children: "WAVE"
        }), " - ウェブページのアクセシビリティーをテストするためのツール（英語）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/website/web-accessibility",
          children: "ウェブアクセシビリティー完全ガイド"
        }), "（英語）"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}