'use es6';

import { argumentType } from '../../common/constants.js';
import { toSnakeCase, toCamelCase, toLowerCaseFirstLetter } from '../../common/stringutils';
import { isNil, get } from '../../common/objutils';
import { extractModelNameFromRef } from '../../common/helpers';
import { Assignment } from './Assignment.js';
export function formatArgumentKey(key) {
  return toSnakeCase(key);
}
export function formatVariableName(name) {
  return `$${toLowerCaseFirstLetter(toCamelCase(name))}`;
}
export function formatArgumentValue({
  key,
  value,
  type,
  srtFormat
}) {
  switch (type) {
    case argumentType.OBJECT:
    case argumentType.REF:
      return typeof value === 'object' ? formatVariableName(key) : formatVariableName(value);
    case argumentType.STRING:
      if (srtFormat === 'raw') {
        return value;
      }
      return `'${value}'`;
    case argumentType.ARRAY:
      return JSON.stringify([value].flat());
    case argumentType.BOOLEAN:
      return value.toString();
    case argumentType.UNDEFINED:
      return 'null';
    default:
      return value;
  }
}
export function formatImportsPaths({
  imports,
  sdkCall
}) {
  const results = [];
  results.push(['HubSpot', 'Factory']);
  const sdkImportPath = ['HubSpot', 'Client', ...sdkCall.path.map(p => toCamelCase(p))].map(p => p.charAt(0).toUpperCase() + p.slice(1));
  const apiExceptionImportPath = [...sdkImportPath, 'ApiException'];
  results.push(apiExceptionImportPath);
  const importsArray = Array.from(imports);
  importsArray.sort();
  for (const modelName of importsArray) {
    const modelImportPath = [...sdkImportPath, 'Model', modelName];
    results.push(modelImportPath);
  }
  return results;
}
export function formatPhpArray(obj, inline) {
  let spaces = 4;
  if (inline) {
    spaces = undefined;
  }
  let result = JSON.stringify(obj || {}, null, spaces).replaceAll('{', '[').replaceAll('}', ']').replaceAll('"[', '[').replaceAll(']"', ']').replaceAll('"', "'").replaceAll(/:/g, ' =>').replaceAll(",'", ", '");
  const variables = Array.from(result.matchAll(/'\$[A-Z0-9]*'/gim));
  if (variables.length) {
    for (const variable of variables) {
      const replaceVariable = variable[0].replaceAll("'", '');
      result = result.replace(variable[0], replaceVariable);
    }
  }
  return result;
}
export function formatObjectAssignments({
  args
}, variableIndexStorage) {
  const assignments = [];
  for (const arg of args) {
    const value = get(arg, 'value', null);
    if (!isNil(value)) {
      assignments.push(prepareAssignment(arg, value, variableIndexStorage, get(arg, 'key', null), 'default'));
    }
  }
  return assignments;
}
function prepareAssignment(arg, value, variableIndexStorage, key, srtFormat) {
  const assignment = new Assignment();
  if (isNil(value)) {
    return assignment;
  }
  let modelName = null;
  if (get(arg, '$$ref', null)) {
    modelName = extractModelNameFromRef(get(arg, '$$ref', null));
  }
  if (!isNil(key)) {
    assignment.setName(key);
  }
  if ([argumentType.ARRAY, argumentType.OBJECT].includes(arg.type)) {
    assignment.generateVariable(modelName, variableIndexStorage);
  }
  if (arg.type === argumentType.OBJECT && !isNil(modelName)) {
    prepareObjectAssignment(assignment, arg, value, variableIndexStorage, modelName);
  } else if ([argumentType.ARRAY, argumentType.OBJECT].includes(arg.type)) {
    prepareArrayAssignment(assignment, arg, value, variableIndexStorage);
  } else {
    if (isNil(srtFormat)) {
      srtFormat = 'raw';
    }
    assignment.setVariable(formatArgumentValue({
      key,
      value,
      type: arg.type,
      srtFormat
    }));
  }
  return assignment;
}
function prepareObjectAssignment(assignment, arg, value, variableIndexStorage, modelName) {
  assignment.addImport(modelName);
  const properties = get(arg, 'properties', {});
  const preparedProperties = {};
  for (const index in properties) {
    if (properties.hasOwnProperty.call(properties, index)) {
      const property = prepareAssignment(properties[index], value[index], variableIndexStorage, index);
      if (!isNil(property.getVariable())) {
        preparedProperties[formatArgumentKey(index)] = property.getVariable();
        assignment.merge(property);
      }
    }
  }
  assignment.addLine(`${assignment.getVariable()} = new ${modelName}(${formatPhpArray(preparedProperties)});`);
}
function prepareArrayAssignment(assignment, arg, value, variableIndexStorage) {
  if (arg.type === argumentType.ARRAY && !Array.isArray(value)) {
    value = [value];
  }
  if (arg.type === argumentType.ARRAY && [argumentType.ARRAY, argumentType.OBJECT].includes(get(arg, 'items.type'))) {
    value = generateArrayForArrayAssignment(value, arg.items, assignment, variableIndexStorage);
  }
  if (Array.isArray(value) && value.length < 4) {
    if (value.length === 0) {
      assignment.setVariable(null);
    } else {
      assignment.setVariable(formatPhpArray(value, true));
    }
  } else if (JSON.stringify(value) === '{}') {
    assignment.setVariable(formatPhpArray(value, true));
  } else {
    if (isNil(assignment.getVariable())) {
      assignment.setVariable(formatPhpArray(value, true));
    } else {
      assignment.addLine(`${assignment.getVariable()} = ${formatPhpArray(value)};`);
    }
  }
  return assignment;
}
function generateArrayForArrayAssignment(value, items, assignment, variableIndexStorage) {
  const arrayOfValues = [];
  for (const selectedValue of value) {
    const preparedValue = prepareAssignment(items, selectedValue, variableIndexStorage);
    assignment.merge(preparedValue);
    arrayOfValues.push(preparedValue.getVariable());
  }
  return arrayOfValues;
}