"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492342707;
const slug = exports.slug = 'guides/cms/content/content-search';
const title = exports.title = 'Inhaltssuche';
const name = exports.name = 'EMEA DACH (DE) Content Search';
const metaDescription = exports.metaDescription = 'Nutzung der nativen Suchfunktionalität innerhalb von CMS Hub, Inhaltssuche. Das CMS von HubSpot verfügt über eine integrierte Suchfunktion, die es Ihren Besuchern ermöglicht, die gesuchten Inhalte leicht zu finden. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "suchen-nach-inhalt",
  "label": "Suchen nach Inhalt",
  "parent": null
}, {
  "depth": 0,
  "id": "implementieren-der-suche-auf-ihrer-website",
  "label": "Implementieren der Suche auf Ihrer Website",
  "parent": null
}, {
  "depth": 1,
  "id": "website-sucheingabe",
  "label": "Website-Sucheingabe",
  "parent": "implementieren-der-suche-auf-ihrer-website"
}, {
  "depth": 1,
  "id": "website-suchergebnisse",
  "label": "Website-Suchergebnisse",
  "parent": "implementieren-der-suche-auf-ihrer-website"
}, {
  "depth": 0,
  "id": "suchergebnisvorlage",
  "label": "Suchergebnisvorlage",
  "parent": null
}, {
  "depth": 0,
  "id": "wie-wird-festgelegt%2C-was-bei-der-suche-vorrang-hat%3F",
  "label": "Wie wird festgelegt, was bei der Suche Vorrang hat?",
  "parent": null
}, {
  "depth": 0,
  "id": "indexierung-w%C3%A4hrend-der-entwicklung-steuern",
  "label": "Indexierung während der Entwicklung steuern",
  "parent": null
}, {
  "depth": 0,
  "id": "standard-indexierungsverhalten",
  "label": "Standard-Indexierungsverhalten",
  "parent": null
}, {
  "depth": 0,
  "id": "wie-kann-ich-ausschlie%C3%9Fen%2C-dass-seiten-in-den-suchergebnissen-angezeigt-werden%3F",
  "label": "Wie kann ich ausschließen, dass Seiten in den Suchergebnissen angezeigt werden?",
  "parent": null
}, {
  "depth": 0,
  "id": "abschnitte-einer-seite-von-der-indexierung-in-der-suche-ausschlie%C3%9Fen",
  "label": "Abschnitte einer Seite von der Indexierung in der Suche ausschließen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      code: "code",
      pre: "pre",
      h3: "h3",
      img: "img",
      strong: "strong",
      ol: "ol",
      li: "li",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Inhaltssuche"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot CMS verfügt über eine integrierte Suchfunktion, die es Ihren Besuchern ermöglicht, die gesuchten Inhalte leicht zu finden. Ihr gesamter HubSpot-Inhalt wird automatisch in der HubSpot-eigenen Suchmaschine indexiert, sodass Sie kein Drittanbieter-Suchtool für Ihre Website einrichten müssen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suchen nach Inhalt"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Suchmaschine ist über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "Website-Suche-API"
      }), " zugänglich. Diese API unterstützt zahlreiche Filteroptionen, die es Ihnen ermöglichen, eine sehr individuelle und leistungsstarke Suche auf Ihrer Website zu erstellen. Wenn Sie z. B. eine Suche in Ihren Blog integrieren möchten, können Sie nach ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type=BLOG_POST"
      }), " suchen, damit nur Blog-Beiträge zurückgegeben werden. Wenn Sie die Suche in die spanische Version Ihrer Website integrieren möchten, könnten Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "language=es"
      }), " abfragen, um nur spanische Seiten zurückzugeben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die API gibt JSON zurück, der mit JavaScript geparst werden kann, um die Ergebnisse auf Ihrer Website anzuzeigen. Bei allen Inhaltstypen werden die Domain, der Titel, die URL und die Sprache der Seite zurückgegeben. Die zurückgegebene Beschreibung ist eine Textprobe aus dem Inhalt, die dem Suchbegriff am besten entspricht. Ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span class=\"hs-search-highlight hs-highlight-html\">"
      }), "-Element umschließt perfekt passenden Text und ermöglicht es Ihnen, passenden Text mit CSS hervorzuheben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Je nach Art des gesuchten Inhalts liefern die Ergebnisse leicht unterschiedliche Informationen, sodass Sie die Ergebnisse für einzelne Inhaltstypen unterschiedlich anzeigen können. Bei Blog-Beiträgen werden beispielsweise Informationen darüber zurückgegeben, welche Tags der Beitrag hat, wer der Autor ist und wann er veröffentlicht wurde."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// json\n{\n  \"total\": 850,\n  \"offset\": 0,\n  \"limit\": 10,\n  \"results\": [\n    {\n      \"id\": 3761238962,\n      \"score\": 0.30858153,\n      \"type\": \"SITE_PAGE\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://developers.hubspot.com/docs/cms/search\",\n      \"language\": \"en\",\n      \"title\": \"Content Search\",\n      \"description\": \"Using HubSpot’s native search engine, content search, to implement <span class=\"hs-search-highlight hs-highlight-html\">search on your website</span>\"\n    },\n    {\n      \"id\": 3254581958,\n      \"score\": 0.30858153,\n      \"type\": \"BLOG_POST\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://designers.hubspot.com/blog/getting-started-with-local-development\",\n      \"featuredImageUrl\":\"https://designers.hubspot.com/hubfs/local-development.jpg\"\n      \"title\": \"Getting Started with Local Development\",\n      \"description\":\"The beta Local Development Tooling connects your local workflow to HubSpot, allowing you to utilize <span class=\"hs-search-highlight hs-highlight-html\">version control</span>, your favorite text editor and various web development technologies when developing on the HubSpot CMS.\"\n      \"authorFullName\": \"William Spiro\",\n      \"tags\": [\n        \"Website Development\",\n        \"Local Development\"\n      ],\n      \"publishedDate\": 1447938000000\n    }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implementieren der Suche auf Ihrer Website"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt zwei Standardmodule, die Sie zur einfachen Implementierung der Suche auf Ihrer Website verwenden können und die die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "Website-Suche-API"
      }), " nutzen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_input"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_results"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Website-Sucheingabe"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Eingabefeld, in das die Besucher Suchbegriffe eingeben können. Dieses Modul kann sich an jeder Stelle auf Ihrer Website befinden. Dieses Modul kann folgendermaßen in eine Vorlage mit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}"
      }), " aufgenommen werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Website-Suchergebnisse"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gibt eine Auflistung von Inhalten auf der Grundlage des Suchbegriffs zurück. Dieses Modul kann sich an jeder Stelle auf Ihrer Website befinden. Dieses Modul kann folgendermaßen in eine Vorlage aufgenommen werden:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn die Funktionalität dieser Module nicht Ihren Vorstellungen von der Suche auf Ihrer Website entspricht, können Sie Ihre eigenen Suchmodule oder -funktionen erstellen. Die Standard-Suchmodule sind so konzipiert, dass sie je nach Ihren Suchanforderungen erweitert werden können. Sie können diese Module im Design-Manager anzeigen und klonen, indem Sie im Finder nach ihren Namen suchen, oder Sie können sie mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS-CLI"
      }), " in Ihre lokale Umgebung abrufen, indem Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_input.module"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_results.module"
      }), " ausführen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/default%20search%20module.png",
        alt: "Standard-Suchmodul"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suchergebnisvorlage"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jede Domain hat standardmäßig ihre eigene Suchergebnisseite. Die Vorlage und der Pfad für diese Seite werden unter ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "„Einstellungen“ > „Website“ > „Seiten“"
      }), " auf der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "Registerkarte „Systemseiten“"
      }), " für bestimmte Domains festgelegt. Die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "Suchergebnissevorlage der CMS Theme Boilerplate"
      }), " ist ein Beispiel dafür. Die für die Suchergebnisseite festgelegte Domain wird automatisch mit den Standardsuchmodulen verknüpft. Sie können jedoch die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "Website-Suche-API"
      }), " verwenden, um Ihre Suchergebnisse so zu erstellen, wie Sie es auf allen Seiten Ihrer Website wünschen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Wie wird festgelegt, was bei der Suche Vorrang hat?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Reihenfolge der Suchergebnisse wird durch mehrere gewichtete Vergleiche zwischen den Seiteninhalten und dem Suchbegriff des Besuchers festgelegt. Die Seiteninhalte werden in Vergleichsfelder mit unterschiedlichen Gewichtungen getrennt, je nachdem, wo der Inhalt im HTML-Code Ihrer Seiten verwendet wird. Vergleichsfelder werden nach Gewichtung gruppiert:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML-Titel"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Meta-Beschreibung"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "H1 HTML-Elemente"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "H2 HTML-Elemente"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "H3 HTML-Elemente"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Andere HTML-Elemente"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bitte beachten Sie, dass sich diese Liste ändern kann."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie unserem Indexierer mitteilen möchten, dass er bestimmte Elemente auf einer Seite gezielt verstärken soll, können Sie den Inhalt in eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-keyword"
      }), "-Klasse einpacken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Indexierung während der Entwicklung steuern"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei der Entwicklung einer neuen Website ist es nützlich, die Website-Suche zu testen, ohne sich Gedanken darüber zu machen, ob die Website von Suchmaschinen wie Google indexiert wird."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In Ihrer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), " können Sie HubSpot anweisen, alles zu crawlen, während Sie andere Bots blockieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /\n\n# Allow HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nAllow: /\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Wenn eine Ihrer Seiten ein Meta-Tag hat, das keinen Index angibt, wird die Seite trotzdem nicht indexiert, auch wenn dies in der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " erlaubt ist."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Denken Sie auch daran, Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " vor dem Start zu überprüfen, um sicherzustellen, dass alles so indexiert wird, wie Sie es wünschen."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Standard-Indexierungsverhalten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Da das CMS die Absicht bestimmter Seitentypen kennt, ist die Inhaltssuche in der Lage, die Indexierung auf sichere Weise einschränken, um die Indexierung von Seiten mit bestimmten Inhaltstypen zu ermöglichen. Beispiele für Seiten mit bestimmten Inhaltstypen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Website-Seiten"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Landingpages"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wissensdatenbankartikel"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Blog-Beiträge"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Systemseiten und passwortgeschützte Seiten werden nicht indexiert."
      }), " Seiten mit eingeschränkter CMS-Mitgliedschaft werden in der Hubspot-Inhaltssuche nur für Benutzer angezeigt, die angemeldet sind und Zugriff auf diese Seiten haben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Wie kann ich ausschließen, dass Seiten in den Suchergebnissen angezeigt werden?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine Seite für die Indexierung durch Suchmaschinen über Ihre Website ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), "-Datei oder über ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta"
      }), "-Tags blockieren, wird sie nicht für die Website-Suche indexiert."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie in Ihrer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "robots.txt"
      }), " ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "disallow"
      }), " hinzu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /path-to-page\n\n# Block just HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nDisallow: /path-to-page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können auch ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "NOINDEX, NOFOLLOW"
      }), "-Meta-Tag im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " auf Seiten- oder Vorlagenebene hinzufügen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<meta name=\"ROBOTS\" content=\"NOINDEX, NOFOLLOW\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Sie müssen Robots nicht sowohl mit ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " als auch mit dem Meta-Tag blockieren. Dies kann später zu Verwirrung führen, wenn Sie die Indexierung einer Seite zulassen möchten."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abschnitte einer Seite von der Indexierung in der Suche ausschließen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Manchmal gibt es Bereiche einer Seite, die nicht in den Suchergebnissen erscheinen sollten. Dies können Inhalte aus einem Header, einem Footer, einer Seitenleiste usw. sein. Wenn dies der Fall ist, können Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-hidden"
      }), "-Klasse zu Ihrem HTML-Code für diese Bereiche hinzufügen, damit die Suche den Inhalt darin ignoriert."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}