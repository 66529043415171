"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125391;
const slug = exports.slug = 'guides/cms/debugging/code-alerts';
const title = exports.title = 'Alertas de código';
const name = exports.name = 'EMEA ES Code Alerts';
const metaDescription = exports.metaDescription = 'Las alertas de código te proporcionan una lista general de los problemas que se identifican dentro de tus páginas CMS relacionadas con tu CSS, módulos o plantillas.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "c%C3%B3mo-ver-las-alertas-de-c%C3%B3digo",
  "label": "Cómo ver las alertas de código",
  "parent": null
}, {
  "depth": 0,
  "id": "tipos-de-material-y-problemas",
  "label": "Tipos de material y problemas",
  "parent": null
}, {
  "depth": 1,
  "id": "tipos-de-material",
  "label": "Tipos de material",
  "parent": "tipos-de-material-y-problemas"
}, {
  "depth": 1,
  "id": "problemas",
  "label": "Problemas",
  "parent": "tipos-de-material-y-problemas"
}, {
  "depth": 0,
  "id": "c%C3%B3mo-ver-los-problemas-identificados-para-tus-materiales",
  "label": "Cómo ver los problemas identificados para tus materiales",
  "parent": null
}, {
  "depth": 1,
  "id": "materiales-de-plantillas%2C-p%C3%A1ginas-y-m%C3%B3dulos",
  "label": "Materiales de plantillas, páginas y módulos",
  "parent": "c%C3%B3mo-ver-los-problemas-identificados-para-tus-materiales"
}, {
  "depth": 1,
  "id": "materiales-de-css",
  "label": "Materiales de CSS",
  "parent": "c%C3%B3mo-ver-los-problemas-identificados-para-tus-materiales"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Alertas de código"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las alertas de código proporcionan una ubicación centralizada para que los desarrolladores y los administradores de TI vean una lista general de los problemas que se identifican dentro de tu CMS de HubSpot. Solucionar los problemas que se identifican en las Alertas de código puede ayudar a optimizar tu sitio web ayudando a mejorar la experiencia de tus clientes y el rendimiento de tus sitios como un todo."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para ver otras formas en que HubSpot ayuda a maximizar el potencial de tu sitio, consulta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "nuestra página de referencia sobre CDN, seguridad y rendimiento."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alerts-dashboard.jpg",
        alt: "Panel de control para alertas de código"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cómo ver las alertas de código"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver las alertas de código de todo el portal, haz clic en el menú de rueda dentada desde cualquier página publicada del CMS en la que estés autenticado y elige ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ver alertas de código"
      }), " o ve directamente a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/code-alerts",
        children: "Alertas de código"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/view-code-alerts-menu.png",
        alt: "Ver las alertas de código desde el menú de la rueda dentada"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de material y problemas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tipos de material"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay múltiples tipos de material que las alertas de código pueden escanear dentro de tu sitio web HubSpot CMS. Estos se enumeran a continuación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de material"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Blog Post"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un post del blog de HubSpot"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Site Page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una página del sitio web en el CMS de HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Landing Page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una página web con un propósito específico: el objetivo de una página de destino es convertir a los visitantes en clientes potenciales."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tu página de listado de blogs de HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Module"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Componentes reutilizables que pueden utilizarse en plantillas o agregarse a las páginas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Template"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Las plantillas son envoltorios de páginas o correos electrónicos reutilizables que generalmente colocan módulos y parciales en un diseño."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un archivo de hoja de estilo en cascada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Knowledge Article"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un artículo de tu Base de conocimientos de HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Unknown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuando un tipo de material es desconocido."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Problemas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay múltiples tipos de problemas que pueden tener tus materiales. Si tu material tiene más de un problema, aparecerá varias veces en el panel de alertas de código."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Problema"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HubL limit"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The HubL function ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "blog_recent_tag_posts"
            }), " is used ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "11"
            }), " times on this page. ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "blog_recent_tag_posts"
            }), " has a limit of ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "5"
            }), " uses per page."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/not-found",
              children: "Algunas funciones de HubL tienen límites"
            }), " de uso. Si tu función excede tus límites, se te mostrará este problema."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSS combining"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There is a syntax error in a line of code that is preventing the CSS files from being combined."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Identifica los archivos CSS que contienen problemas que impedirían la minificación/combinación del archivo. También hay una opción para ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "mostrar los errores de sintaxis"
            }), " relacionados con este material."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Output too big"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This page is ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "10446 Kilobytes"
            }), ". The size limit is ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "9765 Kilobytes"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El HTML generado para la página ha superado el límite. Esto puede hacer que se vea una página en blanco o parcial."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Template error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There is an error in the code that is preventing this template from being rendered."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Identifica los errores en el código de tu plantilla que impiden"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Unknown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unknown issue"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuando el sistema tenga dificultades para identificar el error en el material, se asignará una incidencia desconocida."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cómo ver los problemas identificados para tus materiales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las alertas de código incluyen enlaces profundos a los materiales donde se detectan las alertas. Al colocar el cursor sobre una fila, se te ofrecerán las siguientes opciones por tipo de material."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Materiales de plantillas, páginas y módulos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El botón de acciones te proporcionará un enlace para abrir la plantilla o el módulo correspondiente en el administrador de diseño."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/my-template-open-in-dm.jpg",
        alt: "Problemas con las plantillas y los módulos"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Materiales de CSS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El botón de acciones te proporcionará un enlace para abrir la hoja de estilos correspondiente en el administrador de diseño, ver tu archivo con información de depuración o mostrarte los errores de sintaxis."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alerts-css-options.jpg",
        alt: "Problemas con el CSS"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al elegir \"Mostrar error de sintaxis\" se te mostrará una ventana modal con detalles adicionales sobre el error de sintaxis junto con el número de línea donde existe el error."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alert-syntax-error-details.png",
        alt: "Detalles de los errores de sintaxis modal"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}