'use es6';

import parse from './parse.js';
import { argumentType, formatTypes } from '../../common/constants.js';
import { formatArgumentKey, formatArgumentValue } from './formatters.js';
import { isNil, pull, jsonEquals } from '../../common/objutils';
const getMethodPath = sdkCall => pull(['hubspotClient'].concat([...sdkCall.path, formatArgumentKey(sdkCall.apiName), sdkCall.methodName]).map(formatArgumentKey), 'auth').join('.');
const getAssignmentsFromAssignment = (assignment, argTypes) => assignment.args.filter(arg => argTypes.includes(arg.type)).map(arg => jsonEquals(arg.format, formatTypes.BINARY) ? `const filePath = './import.csv'\nconst ${formatArgumentKey(arg.key)} = fs.createReadStream(filePath)` : `const ${formatArgumentKey(arg.key)} = ${JSON.stringify(arg.value, null, 2)};`);
const getAssignmentArguments = (assignment, assignmentString = ': ') => assignment.args.map(arg => {
  const key = formatArgumentKey(arg.key);
  const value = formatArgumentValue(arg);
  return key === value ? `${key}` : `${key}${assignmentString}${value}`;
}).join(', ');
const hasArgumentInBinaryFormat = sdkCall => !isNil(sdkCall.args.find(arg => arg.format === formatTypes.BINARY));
const getAuthParam = ({
  authTypes,
  apiKey,
  oAuthToken
}) => {
  if (oAuthToken) {
    return {
      accessToken: oAuthToken
    };
  } else if (apiKey) {
    if (authTypes.includes('developer_hapikey')) {
      return {
        developerApiKey: apiKey
      };
    }
    return {
      apiKey
    };
  } else {
    if (authTypes.includes('oauth2') || authTypes.includes('oauth2_legacy') || authTypes.includes('private_apps') || authTypes.includes('private_apps_legacy')) {
      return {
        accessToken: 'YOUR_ACCESS_TOKEN'
      };
    } else if (authTypes.includes('developer_hapikey')) {
      return {
        developerApiKey: 'YOUR_DEVELOPER_API_KEY'
      };
    } else {
      return {
        apiKey: 'YOUR_API_KEY'
      };
    }
  }
};
export default function generate(openApiSpecification, operationId, formData, additionalParams) {
  const {
    sdkCall,
    assignments
  } = parse(openApiSpecification, operationId, formData);
  assignments.push({
    variable: 'apiResponse',
    methodPath: getMethodPath(sdkCall),
    args: sdkCall.args
  });
  const lines = [];
  if (hasArgumentInBinaryFormat(sdkCall)) {
    lines.push("const fs = require('fs');");
  }
  lines.push("const hubspot = require('@hubspot/api-client');");
  lines.push('');
  const authParam = getAuthParam(Object.assign({}, sdkCall, additionalParams));
  lines.push(`const hubspotClient = new hubspot.Client(${JSON.stringify(authParam)});`);
  if (sdkCall.args.length > 0) {
    lines.push('');
  }
  for (const assignment of assignments) {
    if (isNil(assignment.methodPath)) {
      const assignmentsFromAssignment = getAssignmentsFromAssignment(assignment, [argumentType.OBJECT]);
      if (assignmentsFromAssignment.length > 0) {
        lines.push(...assignmentsFromAssignment);
      }
      let line = `const ${formatArgumentKey(assignment.variable)} = { `;
      line += getAssignmentArguments(assignment);
      line += ' };';
      lines.push(line);
    } else {
      const assignmentsFromAssignment = getAssignmentsFromAssignment(assignment, [argumentType.BOOLEAN, argumentType.INTEGER, argumentType.STRING, argumentType.ARRAY, argumentType.UNDEFINED]);
      if (assignmentsFromAssignment.length > 0) {
        lines.push(...assignmentsFromAssignment);
      }
      let line = `const ${assignment.variable} = await ${assignment.methodPath}(`;
      line += assignment.args.map(arg => formatArgumentKey(arg.key)).join(', ');
      line += ');';
      lines.push(line);
    }
  }
  const sdkCallLine = lines.pop();
  lines.push('\ntry {');
  lines.push(`  ${sdkCallLine}`);
  lines.push(`  console.log(JSON.stringify(apiResponse, null, 2));`);
  lines.push(`} catch (e) {`);
  lines.push("  e.message === 'HTTP request failed'");
  lines.push('    ? console.error(JSON.stringify(e.response, null, 2))');
  lines.push('    : console.error(e)');
  lines.push('}');
  return lines.join('\n');
}