"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45279037475;
const slug = exports.slug = 'guides/apps/marketplace/applying-for-app-certification';
const title = exports.title = 'Solicitar la certificación de la aplicación';
const name = exports.name = 'EMEA ES Applying for app certification';
const metaDescription = exports.metaDescription = 'La certificación de aplicaciones permite a los usuarios saber que tu aplicación fue revisada y aprobada por HubSpot, lo que genera confianza y asegura su calidad a los usuarios potenciales.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h3: "h3",
      p: "p",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Solicitar la certificación de la aplicación"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "¿Qué es la certificación de la aplicación?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La certificación de la aplicación implica que el equipo de Calidad del ecosistema de HubSpot revise y confirme que tu aplicación anunciada ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "cumple con los siguientes requisitos"
      }), " de seguridad, privacidad, confiabilidad, rendimiento, usabilidad, accesibilidad y valor. Una vez aprobada, la página de anuncio de tu anuncio de aplicación mostrará una insignia de \"Aplicación certificada de HubSpot\"."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "¿Por qué es importante?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una insignia certificada en el anuncio de venta de tu aplicación permite a los clientes existentes y potenciales saber que el equipo de Calidad del ecosistema de HubSpot revisó y aprobó tu aplicación. En última instancia, es una manera de simbolizar la calidad y generar confianza con los usuarios de la aplicación. Más información sobre cómo generar la confianza de los clientes mediante la certificación ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-customer-trust-through-certification",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "¿Cómo funciona?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cualquier partner de aplicación ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "elegible"
      }), " puede solicitar la certificación a través de su cuenta de desarrollador de aplicaciones. El equipo de Calidad del ecosistema de HubSpot revisará tu solicitud y se pondrá en contacto contigo para proporcionar feedback o confirmar la certificación de tu aplicación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "¿Mi aplicación es elegible para la certificación?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Asegúrate de que tu aplicación sea elegible para la certificación revisando nuestros ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Requisitos de certificación"
      }), ". No podrás presentar una solicitud a menos que tu aplicación tenga al menos 60 instalaciones activas y la cantidad necesaria de tráfico de API. Las instalaciones activas son la cantidad de cuentas de producción únicas de HubSpot, no afiliadas a tu organización, que muestran una actividad exitosa de la aplicación en los últimos 30 días."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "¿Cómo puedo solicitar certificación?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Solo puedes enviar una aplicación a la vez para la certificación. Si envías más de una aplicación para la certificación al mismo tiempo, se rechazarán en función del orden de envío. Una vez que tu aplicación esté certificada, puedes enviar otra para la certificación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para solicitar la certificación:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/account/why-can-t-i-log-into-hubspot",
            children: "Inicia sesión"
          }), " en tu cuenta de desarrollador y navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Mercado de aplicaciones > Anuncios de venta"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Coloca el cursor a la derecha de la aplicación que te gustaría certificar y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Solicitar certificación de la aplicación."
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/undefined-Jun-28-2021-07-51-13-13-PM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Introduce la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL"
        }), " para el video de demostración. Revisa los requisitos necesarios para el video de demostración de la aplicación ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-demo",
          children: "a continuación"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Proporciona credenciales de prueba para tu aplicación para que el equipo de Calidad del ecosistema de HubSpot pueda evaluar su funcionalidad."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-certification.png",
        alt: "app-certification"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar solicitud de certificación"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Requisitos para el video de demostración de la aplicación"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " los videos de demostración ayudan al equipo de Calidad del ecosistema de HubSpot a probar tu aplicación. El equipo ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " revisará tu aplicación a menos que envíes un video de demostración que cumpla con todos los requisitos. Ventas promocionales y videos de marketing serán rechazados. HubSpot no compartirá ni publicará tus videos de demostración."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El video de demostración debe cumplir con los siguientes requisitos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tener al menos tres minutos de duración."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Incluir audio (preferido) o descripciones de texto."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Incluir descripciones del propósito de tu aplicación y casos de uso comunes.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "P. ej., \"La aplicación Acme ayuda a los representantes de ventas e incorporación a coordinar a través de CRM. Los negocios cerrados ganados en cualquiera de los CRM compatibles con Acme pueden generar automáticamente tickets y tareas de incorporación en HubSpot."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Demostrar y describir cómo los nuevos usuarios deben:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Instalar tu aplicación.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "P. ej., \" En la lista de aplicaciones de Acme en el Mercado de aplicaciones de HubSpot, haz clic en Instalar aplicación, selecciona tu CRM, ingresa tus credenciales, haz clic en Listo, selecciona tu cuenta de HubSpot, revisa los alcances solicitados y haz clic en Conectar aplicación."
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Configura tu aplicación después de la instalación.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "P. ej., Una vez que la aplicación esté instalada, selecciona una pipeline de tickets navegando a Configuración > Integraciones > Aplicaciones conectadas > Aplicación Acme > Pipeline de tickets. Luego, configura hasta 10 tareas predeterminadas en la sección \"Plantillas de tareas\". Cuando estés listo para activar la sincronización, activa la opción \"Sincronización de tickets\"."
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Usa las características principales de tu aplicación para admitir casos de uso comunes.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "P. ej., Para cada transacción ganada cerrada en el CRM conectado, la función de sincronización de tickets creará un registro de tickets en HubSpot con los contactos asociados y las tareas configuradas. Esto permite que tu equipo de incorporación se conecte de inmediato con el nuevo cliente\"."
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Interactúa con tu aplicación dentro de su cuenta de HubSpot para admitir casos de uso comunes (si corresponde).", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "P. ej., \"Para crear tickets de incorporación a partir de negocios de HubSpot, usa la acción de workflow personalizado \"Crear ticket de Acme \"en los workflows basados en negocios. Estas acciones permiten una mayor personalización que la configuración de la aplicación para otros CRM."
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Desconecta tu aplicación de su cuenta de HubSpot."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Desinstala tu aplicación en su cuenta de HubSpot, describiendo cómo esto afecta a los usuarios de las cuentas de HubSpot y a sus datos."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sugerencia:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.loom.com/",
          children: "Loom"
        }), " es una herramienta gratuita que puedes usar para grabar un video de demostración."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "¿Por qué no aparece la CTA de certificación para mi aplicación?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El botón «Certificar aplicación» solo aparecerá si tu aplicación es elegible para solicitarla. Revisa nuestros ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "requisitos de certificación"
      }), " o ponte en contacto con tu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-partner-manager",
        children: "administrador de partners"
      }), " si tienes preguntas sobre la elegibilidad."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "¿Cómo sabrán los usuarios que mi aplicación está certificada?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que esté certificada, tu anuncio de venta en el Mercado de aplicaciones de HubSpot tendrá una marca destacada «Partner certificado de aplicaciones de HubSpot»."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/dw7gvVRNzsrmtUwOYBsLVhpAEniaAZ5Z-_G70J-Bzs596yGR_ZzLXJyw821l7FVCV53XmKoI1VVEFxX9YKAJxtI-4NjwvCJadqfWyLG8IyFOWROZ6k8Nx-1307mp6cE0T8p980-N",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando un cliente pasa el cursor sobre la insignia, verá información adicional sobre cómo se certifican las aplicaciones."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Encuentra tu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "mánager de partners de aplicaciones"
        }), " y su información de correo electrónico iniciando sesión en tu cuenta de desarrollador y navegando a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mercado de aplicaciones"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anuncios"
        }), ". Coloca el cursor sobre tu aplicación y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Más"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ver detalles del anuncio"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Requisitos de certificación de aplicación"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Requisitos de anuncio de aplicación"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}