"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29905990571;
const slug = exports.slug = 'docs/cms/features/cms-apis';
const title = exports.title = 'CMS APIs';
const name = exports.name = 'CMS APIs';
const metaDescription = exports.metaDescription = 'The HubSpot CMS is powerful and can be extended through APIs. This page is an aggregation of our APIs  most relevant to developers building with the CMS.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "analytics-api",
  "label": "Analytics API",
  "parent": null
}, {
  "depth": 0,
  "id": "conversations-api",
  "label": "Conversations API",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-blog-api",
  "label": "CMS Blog API",
  "parent": null
}, {
  "depth": 1,
  "id": "cms-blog-authors-api",
  "label": "CMS Blog Authors API",
  "parent": "cms-blog-api"
}, {
  "depth": 1,
  "id": "cms-blog-comments-api",
  "label": "CMS Blog Comments API",
  "parent": "cms-blog-api"
}, {
  "depth": 1,
  "id": "cms-blog-post-api",
  "label": "CMS Blog Post API",
  "parent": "cms-blog-api"
}, {
  "depth": 1,
  "id": "cms-blog-topics-api",
  "label": "CMS Blog Topics API",
  "parent": "cms-blog-api"
}, {
  "depth": 0,
  "id": "cms-domains-api",
  "label": "CMS Domains API",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-files-api",
  "label": "CMS Files API",
  "parent": null
}, {
  "depth": 0,
  "id": "forms-api",
  "label": "Forms API",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-hubdb-api",
  "label": "CMS HubDB API",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-page-publishing-api",
  "label": "CMS Page Publishing API",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-site-search-api",
  "label": "CMS Site Search API",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-templates-api",
  "label": "CMS Templates API",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-url-mappings-api",
  "label": "CMS URL Mappings API",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-content-audit-api",
  "label": "CMS Content Audit API",
  "parent": null
}, {
  "depth": 0,
  "id": "get-privacy-consent-status",
  "label": "Get Privacy Consent Status",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      h2: "h2",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS APIs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot CMS is powerful and can be extended through APIs. This page is an aggregation of our APIs that are most relevant to developers building with the CMS. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "This is not our"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "full list of APIs"
        })
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "."
      }), " Our APIs are subject to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details#rate-limits",
        children: "rate limits"
      }), " and support ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), ". We encourage reviewing our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "API Usage Guidelines"
      }), " for best practices. To test your integrations you will need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "create a developer account"
      }), ", which will enable you to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "create test accounts"
      }), " or an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "application"
      }), " to get started with OAuth. This is different from your CMS developer sandbox portal."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Analytics API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/reporting",
        children: "Analytics API"
      }), " allows you to export analytics and reporting data from HubSpot. It’s primarily used to connect metrics tracked in HubSpot to those stored in other business intelligence tools. You could use this API to display traffic and leads tracked in another CRM or analytics tool."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conversations API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Conversations Live Chat widget allows you to directly engage in conversations on your website. The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/conversations/chat-widget-sdk",
        children: "Conversations API"
      }), " helps you provide a more tailored visitor experience by giving you more control over this widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use this to customize how and when the conversations widget appears on your website."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS Blog API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/api/cms/blogs#list-blogs",
        children: "CMS Blog APIs"
      }), " enable you to sync your blog data with external applications and tools as well as modify blog content."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CMS Blog Authors API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/blogs/blog-authors",
        children: "CMS Blog Authors API"
      }), " enables you to list, search, create, delete, and get information for blog authors."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CMS Blog Comments API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/blog-comments#list-comments",
        children: "CMS Blog Comments API"
      }), " can be used to list, get, create, and delete comments as well as restore deleted comments."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CMS Blog Post API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/blogs/blog-posts",
        children: "CMS Blog Post API"
      }), " can be used to list, get, create, update, delete, clone, and control publishing of blog posts. The API can also be used to manage revisions of a post."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CMS Blog Topics API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/blogs/blog-tags",
        children: "CMS Blog Topics API"
      }), " can be used to list, search, get, create, update, delete and merge blog topics."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS Domains API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/domains/v1#list-domains",
        children: "CMS Domains API"
      }), " can be used to get a list of all the domains and their associated properties for a HubSpot account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS Files API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/files/v3-legacy#upload-a-new-file",
        children: "CMS Files API"
      }), " to upload, delete, organize and manage files in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/file-manager",
        children: "File Manager"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Forms API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/forms",
        children: "Forms API"
      }), " to deliver heavily customized forms to users by directly submitting form data to our forms API. This API can also be used to get form information, create, update and delete forms, and their associated fields. Note: when using the Forms API to submit forms data, the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "accessibility"
      }), " and validation of your forms is your responsibility."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS HubDB API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb",
        children: "HubDB API"
      }), " to create, modify and delete HubDB tables, and their rows of data. The HubDB API also supports ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#import-a-csv-to-the-draft-version-of-a-table",
        children: "importing a CSV file"
      }), " into an existing HubDB table. This API is useful for those storing and displaying complex information."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS Page Publishing API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/pages/v2#list-pages",
        children: "CMS Page Publishing API"
      }), " to create, clone, list, update, and delete pages on the CMS. This API can also be used to manage the publishing state and view and revert to revisions of a page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS Site Search API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "Site Search API"
      }), " to search the content (site pages, blog posts, landing pages, blog listing pages, and knowledge articles) of a HubSpot hosted site. This can be used to build a custom ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "site search"
      }), ". You can also use the API to access all of the indexed data for a document."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS Templates API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/templates#list-templates",
        children: "CMS Templates API"
      }), " to list, create, update, delete, and publish coded HTML+HubL templates. This API can also be used to get and restore previous revisions of a template as well as restore deleted templates."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS URL Mappings API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/url-redirects",
        children: "CMS URL Mappings API"
      }), " to list, create, update, delete, and get URL mappings from a HubSpot account. URL Mappings are used for redirects and proxy pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS Content Audit API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/content-audit",
        children: "CMS Content Audit API"
      }), " allows you to filter and sort on content object changes by type, time period, or HubSpot user ID, so you’re never left wondering what happened. ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "This API is currently in public beta."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get Privacy Consent Status"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/customize-your-cookie-tracking-settings-and-privacy-policy-alert",
        children: "GDPR privacy consent banner"
      }), " which can control whether different analytics scripts are run. To control script loading you'll need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner",
        children: "get the consent status"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}