import noAuthClient from 'hub-http/clients/noAuthApiClient';
//@ts-expect-error Untyped
import namespacedKeyMirror from 'developer-docs-shared-components/lib/utils/namespacedKeyMirror';
//@ts-expect-error Untyped
import { callFetchFunctionIfNotAMock } from 'developer-docs-shared-components/modules/Doc/actions';
import enviro from 'enviro';
import { localeToUnderscoredLocale } from 'developer-docs-shared-components/lib/locale/utils';
import { getRequestOptionsForSpecCall } from './helpers';
export const SPEC_API_BASE = 'public/api/spec';
export const SpecificationActionTypes = namespacedKeyMirror({
  SPECIFICATION_FETCH_STARTED: null,
  SPECIFICATION_FETCH_SUCCEEDED: null,
  SPECIFICATION_FETCH_FAILED: null,
  FORM_DATA_UPDATED: null
}, 'specification');

/**
 * Options object passed to `noAuthClient.get()` call.
 */

export const fetchPublicApiSpec = (specInfo, apiPath, locale) => dispatch => {
  /**
   * Use PROD data locally and on PROD, but not on the deployed QA env where
   * the QA rollout ID is different. Translations currently only exist on
   * PROD.
   *
   * @see https://hubspot.slack.com/archives/C6PL2HX0C/p1718054517626199
   */
  const isProdData = enviro.isProd() || enviro.isQa() && !enviro.deployed();
  let requestOpts;
  if (isProdData) {
    /** Get full locale from `I18n.getLangLocale` */
    /** Language/locale query param */
    const localeQueryParam = localeToUnderscoredLocale(locale);
    const apiObj = getRequestOptionsForSpecCall();
    requestOpts = Object.assign({}, apiObj, {
      query: {
        locale: localeQueryParam
      }
    });
  }
  if (!specInfo) {
    dispatch({
      type: SpecificationActionTypes.SPECIFICATION_FETCH_FAILED,
      payload: {
        error: 'No specification was provided'
      }
    });
    return;
  }
  const url = specInfo.openApi || `${SPEC_API_BASE}/v1/specs/${specInfo.group}/${specInfo.name}/versions/${specInfo.versionId}`;
  const fetchFunction = () => {
    dispatch({
      type: SpecificationActionTypes.SPECIFICATION_FETCH_STARTED
    });
    noAuthClient.get(url, requestOpts).then(data => {
      dispatch({
        type: SpecificationActionTypes.SPECIFICATION_FETCH_SUCCEEDED,
        payload: {
          data
        }
      });
    }).catch(error => {
      dispatch({
        type: SpecificationActionTypes.SPECIFICATION_FETCH_FAILED,
        payload: {
          error
        }
      });
    });
  };
  callFetchFunctionIfNotAMock(dispatch, fetchFunction, apiPath);
};