"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866608851;
const slug = exports.slug = 'reference/api/overview';
const title = exports.title = 'HubSpot-APIs | Erste Schritte';
const name = exports.name = 'vNext Docs DP - Erste Schritte';
const metaDescription = exports.metaDescription = 'Die HubSpot-API ermöglicht Entwicklern die Erstellung benutzerdefinierter Apps und Integrationen. Legen Sie los, indem Sie sich unsere API-Übersicht durchlesen und einen Entwickler-Account erstellen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "einrichtung",
  "label": "Einrichtung",
  "parent": null
}, {
  "depth": 1,
  "id": "client-bibliotheken",
  "label": "Client-Bibliotheken",
  "parent": "einrichtung"
}, {
  "depth": 0,
  "id": "api-dokumentation",
  "label": "API-Dokumentation",
  "parent": null
}, {
  "depth": 1,
  "id": "referenzdokumentation",
  "label": "Referenzdokumentation",
  "parent": "api-dokumentation"
}, {
  "depth": 1,
  "id": "integrationsleitf%C3%A4den",
  "label": "Integrationsleitfäden",
  "parent": "api-dokumentation"
}, {
  "depth": 0,
  "id": "listing-ihrer-app",
  "label": "Listing Ihrer App",
  "parent": null
}, {
  "depth": 0,
  "id": "support--und-community-ressourcen",
  "label": "Support- und Community-Ressourcen",
  "parent": null
}, {
  "depth": 0,
  "id": "erstellen-im-cms",
  "label": "Erstellen im CMS",
  "parent": null
}, {
  "depth": 2,
  "id": "verwandte-dokumente",
  "label": "Verwandte Dokumente",
  "parent": "erstellen-im-cms"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Table
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot API-Referenzdokumentation"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Entwickler-Plattform von HubSpot ist ein zentraler Bestandteil unserer Mission, Organisationen zu einem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/grow-better",
        children: "Wachstum mit System"
      }), " zu verhelfen. Unser APIs werden dafür entwickelt, Teams jeglicher Form oder Größe in die Lage zu versetzen, robuste Integrationen zu erstellen, mit denen sie HubSpot anpassen und optimal nutzen können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle HubSpot-APIs werden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/Representational_state_transfer",
        children: "mithilfe von REST-Konventionen erstellt"
      }), " und sind auf eine vorhersehbare URL-Struktur ausgelegt. Sie verwenden viele Standard-HTTP-Funktionen, einschließlich Methoden (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), ") und Fehlerantwortcodes. Alle HubSpot-API-Aufrufe werden unter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com"
      }), " vorgenommen und alle Antworten geben Standard-JSON zurück."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einrichtung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt mehrere Möglichkeiten, um Integrationen mit HubSpot zu entwickeln:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um eine interne Integration für einen einzelnen HubSpot-Account zu entwickeln (z. B. möchten Sie eine App entwickeln, die nur auf autorisierte Teile Ihres Accounts zugreifen und diese bearbeiten kann, um sie mit anderen Teilen Ihrer Organisation zu teilen oder zu integrieren), erstellen Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "private App"
        }), ". Wenn Sie eine öffentliche App erstellen möchten, die über mehrere HubSpot-Accounts installiert werden kann, sollten Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
          children: "einen Entwickler-Account erstellen"
        }), ". Dafür gibt es verschiedene Gründe: Ein Entwickler-Account ist der Ort, in dem Sie HubSpot-Apps erstellen, jede mit OAuth authentifiziert und mit einem konfigurierbaren Satz an Funktionen und Berechtigungen ausgestattet. Sie können Ihren Entwickler-Account auch verwenden, um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "Test-Accounts zu erstellen"
        }), ", den App-Status und die Performance zu überwachen oder Apps im HubSpot App Marketplace zu veröffentlichen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#account-relationships",
        children: "diesem Artikel"
      }), " mehr über die verschiedenen Typen von Apps und Account-Typen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Client-Bibliotheken"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Client-Bibliotheken sollen Ihnen helfen, möglichst reibungslos mit den HubSpot-APIs zu interagieren. Sie sind in mehreren Sprachen geschrieben und helfen Ihnen dabei, die Lücke zwischen Ihrer Anwendung und den APIs von HubSpot zu überbrücken. Sie machen es unter anderem überflüssig, die exakte URL- und HTTP-Methode für jeden einzelnen API-Aufruf zu kennen. So haben Sie mehr Zeit, sich auf das Erstellen Ihrer Anwendung zu konzentrieren. Erfahren Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/overview",
        children: "hier"
      }), "mehr über unsere Client-Bibliotheken."]
    }), "\n", (0, _jsxRuntime.jsxs)(Table, {
      children: [(0, _jsxRuntime.jsx)("thead", {
        children: (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("th", {
            children: "Sprache"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: "Paket-Link"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "Quellcode"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_nodejs-512_339733.png",
              alt: "Node.Js"
            }), "Node.Js"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://www.npmjs.com/package/@hubspot/api-client",
              children: "npm install @hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "hubspot-api-nodejs"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/new-php-logo.png",
              alt: "PHP"
            }), "PHP"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://packagist.org/packages/hubspot/api-client",
              children: "composer require hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-php",
              children: "hubspot-api-php"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/ruby.png",
              alt: "Ruby"
            }), "Ruby"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://rubygems.org/gems/hubspot-api-client",
              children: "gem install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-ruby"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_267_Python_logo_4375050.png",
              alt: "Python"
            }), "Python"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://pypi.org/project/hubspot-api-client/",
              children: "pip install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-python"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API-Dokumentation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die API-Dokumentation von HubSpot ist in zwei Abschnitte aufgeteilt: Referenzdokumente und Integrationsleitfäden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Referenzdokumentation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alle API-Referenzdokumente enthalten einen Überblick über einen Endpunkt-Abschnitt. Die API-Übersicht enthält eine kurze Zusammenfassung der Funktionen, Anwendungsfälle und alle Aspekte, die beim Erstellen einer Integration besonders zu beachten sind. Im Abschnitt mit Endpunkten sind die einzelnen Endpunkte, seine Parameter und Beispiele für Anfragen in mehreren Sprachen aufgeführt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie die Authentifizierungseinstellungen Ihrer App in Ihrem Entwickler-Account konfiguriert haben, können Sie Postman verwenden oder direkt über eine Endpunkt-Referenzseite Testanrufe tätigen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Integrationsleitfäden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie die Grundlagen der HubSpot-Plattform verstehen oder ein Beispiel sehen möchten, bevor Sie Ihren ersten API-Aufruf vornehmen, finden Sie in unserem Abschnitt mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "Integrationsleitfäden"
      }), " Beispiel-Apps und Tutorials sowie detaillierte Informationen zu Entwickler-Accounts, zur Arbeit mit OAuth, zu API-Ratenbeschränkungen und vieles mehr."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Verwandtes Thema:"
      }), " Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Authentifizierungsmethoden"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listing Ihrer App"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie bereit sind, Ihre App mit der Welt zu teilen und ein App-Partner zu werden, lesen Sie unsere Anforderungen für ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Listing"
      }), " und eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Zertifizierung"
      }), ". Im Anschluss können Ihr ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "App Marketplace"
      }), "-Listing direkt von Ihrem Entwickler-Account aus ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "erstellen"
      }), " und verwalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Support- und Community-Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erhalten Sie Antworten auf Ihre Fragen, vernetzen Sie sich und tauschen Sie Erkenntnisse aus, indem Sie den wachsenden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "Community-Foren"
      }), " und der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/developer-slack",
        children: "Slack-Community"
      }), " von HubSpot beitreten. Diese Foren sind ein toller Ort, um sich Gehör zu verschaffen – Community-Feedback ist unglaublich wichtig für uns und unsere fortlaufenden Bemühungen, das HubSpot-Entwicklererlebnis zu verbessern."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Durch ein Abonnement des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Änderungsprotokoll"
      }), "-Blogs haben Sie außerdem die Möglichkeit, stets auf dem neuesten Stand in puncto neue Features, Ankündigungen und wichtigen Änderungen zu bleiben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erstellen im CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie möchten eine Website, einen Blog, eine Landingpage, eine Lightweight App oder eine E-Mail erstellen? Wechseln Sie zu unserer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "CMS-Entwicklerdokumentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Verwandte Dokumente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "So verwenden Sie die HubSpot-APIs"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}