"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611096;
const slug = exports.slug = 'reference/cms/modules/using-modules-in-templates';
const title = exports.title = 'Using modules in templates';
const name = exports.name = '[new] Using modules in templates';
const metaDescription = exports.metaDescription = 'Modules are editable areas of HubSpot pages or emails. While you can add modules to a template layout using the drag and drop Template Builder, you can also create modules using HubL. HubL modules can be defined in a coded template file, a HubL template module, or a blog content module. Below is a comprehensive list of modules and their parameters.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "basic-module-syntax",
  "label": "Basic module syntax",
  "parent": null
}, {
  "depth": 0,
  "id": "passing-dicts-to-module-parameters",
  "label": "Passing dicts to module parameters",
  "parent": null
}, {
  "depth": 1,
  "id": "passing-fields-that-have-dnd-associated-parameters",
  "label": "Passing fields that have dnd associated parameters",
  "parent": "passing-dicts-to-module-parameters"
}, {
  "depth": 0,
  "id": "setting-template-level-default-values-for-fields",
  "label": "Setting template-level default values for fields",
  "parent": null
}, {
  "depth": 1,
  "id": "setting-default-values-for-nested-field-groups",
  "label": "Setting default values for nested field groups",
  "parent": "setting-template-level-default-values-for-fields"
}, {
  "depth": 1,
  "id": "setting-default-values-for-repeating-fields",
  "label": "Setting default values for repeating fields",
  "parent": "setting-template-level-default-values-for-fields"
}, {
  "depth": 1,
  "id": "setting-default-values-for-repeating-field-groups",
  "label": "Setting default values for repeating field groups",
  "parent": "setting-template-level-default-values-for-fields"
}, {
  "depth": 1,
  "id": "setting-default-values-for-style-fields",
  "label": "Setting default values for style fields",
  "parent": "setting-template-level-default-values-for-fields"
}, {
  "depth": 0,
  "id": "block-syntax",
  "label": "Block Syntax",
  "parent": null
}, {
  "depth": 0,
  "id": "content_attribute",
  "label": "content_attribute",
  "parent": null
}, {
  "depth": 0,
  "id": "parameters-available-for-all-modules",
  "label": "Parameters available for all modules",
  "parent": null
}, {
  "depth": 0,
  "id": "field-based-parameters",
  "label": "Field-based parameters",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Using Modules in Templates"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Modules can either be added directly to a template or added to individual pages with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "drag and drop areas"
      }), " and flexible columns. When a module is added to a template, the module will appear in that location by default. Modules in drag and drop areas and flexible columns can be moved and removed, and other modules can be added around them. These are module instances."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After a module has been defined, you can get its field values at the template level through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "content.widgets dict"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Basic module syntax"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL module tags are delimited by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% %}"
      }), " , and must specify ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ", a unique name, and the module's design manager path. A module can also include parameters for additional settings."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Module name:"
        }), " gives the module a unique identity in the context of the template.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "The name must be in quotes following the type of module, and must use underscores instead of spaces or dashes."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "This name is used to match the content set within the page/email editor with the corresponding HubL module tag. For example, if you code a HubL module tag with the same name in two different areas of a template, users will only have one module to edit in the editor, but changes to that module will apply in both locations."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Path:"
        }), " depending on the tag, defines the location of where the module is in the design manager.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            }), " means the root of the current drive;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "./"
            }), " means the current directory;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "../"
            }), " means the parent of the current directory."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The path for ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "HubSpot default modules"
        }), " always start with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot/"
        }), " followed by the type of module."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Parameters:"
        }), " additional settings for the module instance, specifying its behavior and how it renders. Includes template-level default values for module fields.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Parameters are comma-separated key-value pairs."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Parameters have different types, including: string, boolean, integer, enumeration, and JSON list object. String parameters values must be in single or double quotes, while boolean parameters do not require quotes around their ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), " values. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/using-modules-in-templates#parameters-available-for-all-modules",
              children: "parameters that are available for all modules"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Note that there is no in-editor validation for field values compared to the module's field settings. For example, if module has a number field that has a minimum value set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), ", and you pass into the parameter a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", you will not see a warning that the value is invalid."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Basic syntax #}\n{% module \"unique_module_name\" path=\"@hubspot/module_type\",\n  parameterString='String parameter value',\n  parameterBoolean=True\n%}\n\n{# Sample of a default HubSpot text module #}\n{% module \"job_title\" path=\"@hubspot/text\",\n  label=\"Job Title\",\n  value=\"Chief Morale Officer\"\n%}\n\n{# Sample of a custom module #}\n{% module \"faqs\" path=\"/myWebsite/modules/faq_module\",\n  label=\"Custom FAQ Module\"\n  faq_group_title=\"Commonly Asked Questions\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Passing dicts to module parameters"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For modules with fields that expect dicts, you can pass them like you would other parameters. If it's cleaner to you or you plan to re-use the values, you can set the dict to a variable, and pass the variable to the parameter instead."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_buttons\",\n  path=\"@hubspot/social_sharing\",\n  email={\n    \"default\": true,\n    \"enabled\": false,\n    \"img_src\": \"https://...\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Passing fields that have dnd associated parameters"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "Drag and drop tags"
      }), ", such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", come with a set of default parameters, such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), ". While the design manager will prevent you from creating new fields that use one of these reserved parameters, modules created before drag and drop tags were introduced may already use a reserved parameter."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To fix this, you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), " parameter. Just like you would pass field data to a group, you can pass the field name as a key on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), " object. Its value must be consistent with the format the field type expects."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"main_content\"%}\n     {% dnd_section %}\n        {% dnd_column %}\n          {% dnd_row %}\n            {% dnd_module path=\"@hubspot/divider\",\n               fields={width: \"90\"}\n            %}\n            {% end_dnd_module %}\n        {% end_dnd_row %}\n      {%end_dnd_column%}\n    {% end_dnd_section %}\n  {% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Setting template-level default values for fields"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can set default values for module fields at the template level by including parameters in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " tags. Below, learn how to set default field values in nested field groups, repeating fields, repeating field groups, and style fields."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Setting default values for nested field groups"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is an example of a custom drag and drop module with a custom ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "style"
      }), " field group containing other nested field groups. Compare its template-level configuration with how this same grouping would appear in the design manager."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n path=\"/Nested_Module.module\"\n style={\n  \"group\":{\n  \"section\":{\n   \"color_field\":{\n   \"color\" : \"#000\",\n   \"opacity\" : 100\n    }\n   }\n  }\n }\n%}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/multi-level%20field%20nesting%20.png",
            alt: "multi-level field nesting "
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Setting default values for repeating fields"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can set template level default values for repeating fields by passing an array to the field's parameter. The array's items must be in the format expected based on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#field-based-parameters",
        children: "field type"
      }), ". For example:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A simple text field only expects a string"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["An image repeater field expects an image field object. This applies to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-based-parameters",
          children: "all of the other field types"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/recipe_card.module',\n  ingredients=[\"Eggs\",\"Ham\",\"Cheese\"]\n%}\n\n{% module \"my_repeater_module\" path=\"/img_repeater_module\", label=\"img_repeater_module\",\nimage=[\n  {\n    \"src\" : \"https://cdn2.hubspot.net/hubfs/428357/Developer%20Site/assets/logo/Developers-LOGO.svg\",\n    \"alt\" : \"HubSpot Developers\",\n    \"width\" : 254,\n    \"height\" : 31\n  },{\n    \"src\" : \"https://www.hubspot.com/hs-fs/hub/53/file-733888614-jpg/assets/hubspot.com/about/management/dharmesh-home.jpg\",\n    \"alt\" : \"Dharmesh\",\n    \"width\" : 394,\n    \"height\" : 394\n  }\n]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Setting default values for repeating field groups"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Modules that contain repeating groups of fields - like you might see in a slideshow module or FAQ module - can have a template level default set for those groups. To do this you pass an array of objects to your field group's parameter. The key and value pairs of the object are the field names and their values."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/slideshow.module',\n  slides=[\n    {\n      \"caption\":\"Cute dog looking up\",\n      \"image_url\":\"http://example.com/image.jpg\",\n    },\n    {\n      \"caption\":\"Cuter cat not looking amused\",\n      \"image_url\":\"http://example.com/image2.jpg\",\n    }\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Setting default values for style fields"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["you can explicitly set default values for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "style fields"
      }), " using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "styles"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This works just like other groups do, where the parameter is the name of the group. You pass an object to that parameter with all of the fields you wish to set."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n    path=\"./path/to/module\",\n    styles={\n      \"background_color\":{\n          \"color\":\"#123\",\n          \"opacity\":50\n       }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Block Syntax"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While most modules have parameters that control default content, there may be situations where you need to add large code blocks to the default content of a module. For example, you may want to include a large block of HTML as the default content for a rich text or HTML module. Rather than trying to write that code into a value parameter, you can use HubL block syntax."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module_block module \"my_rich_text_module\" path=\"/My Rich Text Field Module\",\n  label=\"My Rich Text Field Module\"\n%}\n    {% module_attribute \"rich_text_field_variable\" %}\n       <div>My HTML block</div>\n    {% end_module_attribute %}\n{% end_module_block %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Prior to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_block"
        }), " syntax, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " was used. It follows the same pattern but the opening tags were ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_attribute"
        }), ". Closing tags were ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_attribute"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_block"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " syntax is deprecated but you don't need to update old code."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The parameter that immediately follows ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), "(deprecated) is the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In nearly all of our documentation you will find we use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "V2 HubSpot Modules"
      }), " are normal modules, like what you can create. Therefore there's no longer a need to use a different ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " is deprecated, and you should use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), ". If inheriting a website from another developer it may contain old code using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), " supports V1 HubSpot module names for example: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), ". Additional parameters can be added to the first line of HubL. The second line defines which parameter the contents of the block will be applied to. For example, for a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), " module this should be the html parameter. For a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), " module, this would be the value parameter (see both examples below)."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# widget_block is deprecated, use module_block instead. This example is only to\nexplain what type_of_module was used for, for those with legacy code. #}\n{% widget_block rich_text \"my_rich_text_module\" overrideable=True, label='My rich-text module'  %}\n        {% widget_attribute \"html\" %}\n            <h2>New Module</h2>\n            <p>Add content here.</p>\n        {% end_widget_attribute %}\n{% end_widget_block %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Example Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<span\n  id=\"hs_cos_wrapper_my_rich_text_module\"\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n  style=\"\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"rich_text\"\n>\n  <h2>New Module</h2>\n  <p>Add content here.</p>\n</span>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "content_attribute"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to regular and block syntax, there are certain instances where you may want to specify a large block default content for a predefined content variable. The most common example of this proves to be the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#email-variables",
        children: "content.email_body"
      }), " variable. This variable prints a standard email body that can be altered in the content editor. Since this isn't a standard HubL module, we use a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "content_attribute"
      }), " tag to specify a block of default content. The example below shows the email body variable populated with a default content code block."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% content_attribute \"email_body\" %}\n        <p>Hi {{ contact.firstname }},</p>\n        <p>Describe what you have to offer the customer. Why should they read? What did you promise them in the subject line? Tell them something cool. Make them laugh. Make them cry. Well, maybe don't do that...</p>\n        <p>Use a list to:</p>\n        <ul>\n            <li>Explain the value of your offer</li>\n            <li>Remind the reader what they’ll get out of taking action</li>\n            <li>Show off your skill with bullet points</li>\n            <li>Make your content easy to scan</li>\n        </ul>\n        <p><a href=\"http://hubspot.com\">LINK TO A LANDING PAGE ON YOUR SITE</a> (This is the really important part.)</p>\n        <p>Now wrap it all up with a pithy little reminder of how much you love them.</p>\n        <p>Aw. You silver-tongued devil, you.</p>\n        <p>Sincerely,</p>\n        <p>Your name</p>\n{% end_content_attribute %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parameters available for all modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While some modules have certain ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "special parameters"
      }), ", below is a list of parameters supported by all modules."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the module displayed in the content editor. This parameter can also be used to give users additional instructions."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overrideable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Controls whether or not the module can be edited in the content editor, equivalent to the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Prevent editing in content editors"
            }), " setting in the design manager."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "no_wrapper"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", removes the wrapping markup from around the content of a module.On pages, modules are always wrapped in a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<div>"
            }), " with special classes. This wrapping markup makes it so when you click the module in the preview pane, the editor scrolls to that module. There may be instances where you want to remove the wrapper, such as if you want to use a text module to populate the destination of an anchor tag ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            }), " attribute."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Adds classes to the module wrapper. You can add multiple classes by separating the classes with spaces. For example:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes='full-width panel'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "export_to_template_context"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", instead of rendering the HTML, the parameters from this widget will be available in the template context. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/export-to-template-context",
              children: "Learn how to use this parameter and the widget_data tag."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unique_in_loop"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When the module is defined within a loop, appends the module name with the loop.index. When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", a different version of the module will print within each iteration of the loop. Appends the module name with the loop.index."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To see a complete list of all module types and their parameters, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "click here."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Field-based parameters"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about the field-based module parameters you can use."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Keys"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer (blog ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "True/false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option_1\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Color"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"color\" : \"#ffffff\",  \"opacity\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["color ", (0, _jsxRuntime.jsx)("br", {}), "6 character hexidecimal format ", (0, _jsxRuntime.jsx)("br", {}), "opacity ", (0, _jsxRuntime.jsx)("br", {}), "integer 0 - 100 ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTA"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String (CTA ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"fb9c0055-6beb-489d-8dda-3e1222458750\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Date"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Timestamp"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1566360000000"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Timestamp"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1566360000000"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Email address"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array (email address strings)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[\"develop@hubspot.com\", \"design@hubspot.com\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "File"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String (URL of file)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://cdn2.hubspot.net/hubfs/file.pdf\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Follow Up Email"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer (follow up email ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Font"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"size\" : 12,  \"size_unit\" : \"px\",  \"color\" : \"#000\",  \"styles\" :{    \"text-decoration\" : \"underline\"  },  \"font\" : \"Alegreya\",  \"fallback\" : \"serif\",  \"variant\" : \"regular\",  \"font_set\" : \"GOOGLE\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["size ", (0, _jsxRuntime.jsx)("br", {}), "font size without unit type ", (0, _jsxRuntime.jsx)("br", {}), "size_unit ", (0, _jsxRuntime.jsx)("br", {}), "font size unit string", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"px\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"pt\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"em\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"rem\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"%\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"ex\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"ch\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "color ", (0, _jsxRuntime.jsx)("br", {}), "hex color code string ", (0, _jsxRuntime.jsx)("br", {}), "styles ", (0, _jsxRuntime.jsx)("br", {}), "supported properties\"font-weight\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"bold\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"italic\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"none\" / \"underline\" ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Form"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"form_id\" : \"9aa2e5f3-a46d-4774-897e-0bc37478521c\",  \"response_type\" : \"redirect\",  \"redirect_url\" : \"http://www.hubspot.com\",  \"redirect_id\" : null,  \"form_type\" : \"HUBSPOT\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["form_id ", (0, _jsxRuntime.jsx)("br", {}), "The form's ID. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/forms/find-your-form-guid",
              children: "How to get a form's id."
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "response_type ", (0, _jsxRuntime.jsx)("br", {}), "\"redirect\" / \"inline\" ", (0, _jsxRuntime.jsx)("br", {}), "message ", (0, _jsxRuntime.jsx)("br", {}), "Message displayed if using response_type \"inline\". String supporting html. ", (0, _jsxRuntime.jsx)("br", {}), "redirect_url ", (0, _jsxRuntime.jsx)("br", {}), "String, absolute URL to a webpage ", (0, _jsxRuntime.jsx)("br", {}), "redirect_id ", (0, _jsxRuntime.jsx)("br", {}), "Page/Post id to redirect to ", (0, _jsxRuntime.jsx)("br", {}), "form_type ", (0, _jsxRuntime.jsx)("br", {}), "\"HUBSPOT\" / \"TICKET_FORM\" ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDB Table"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer (HubDB table ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "123456789"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Icon"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"name\" : \"align-center\",  \"unicode\" : \"f037\",  \"type\" : \"SOLID\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["name ", (0, _jsxRuntime.jsx)("br", {}), "The icon's name ", (0, _jsxRuntime.jsx)("br", {}), "unicode ", (0, _jsxRuntime.jsx)("br", {}), "The unicode symbol for the font the icon is from ", (0, _jsxRuntime.jsx)("br", {}), "type ", (0, _jsxRuntime.jsx)("br", {}), "Symbol style. \"SOLID\" / \"REGULAR\" ", (0, _jsxRuntime.jsx)("br", {}), "It is recommended you set an icon field and view the values that way, to set the parameters properly."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Image"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"src\" : \"https://cdn2.hubspot.net/hubfs/image.jpeg\",  \"alt\" : \"an_image\",  \"width\" : 100,  \"height\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["src ", (0, _jsxRuntime.jsx)("br", {}), "Image URL ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "Image alt text, used by screen readers and search engines ", (0, _jsxRuntime.jsx)("br", {}), "width ", (0, _jsxRuntime.jsx)("br", {}), "The width at which the image is to be displayed ", (0, _jsxRuntime.jsx)("br", {}), "height ", (0, _jsxRuntime.jsx)("br", {}), "The height at which the image is to be displayed ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Link"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"url\" : {     \"type\" : \"EXTERNAL\",    \"href\" : \"www.hubspot.com\",    \"content_id\" : null   },  \"open_in_new_tab\" : false,  \"no_follow\" : false }"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["url ", (0, _jsxRuntime.jsx)("br", {}), "object storing URL data.type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"EXTERNAL\" for non HubSpot non-email URLs"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"CONTENT\" for pages, blog posts, and landing pages"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"FILE\" for files uploaded to the file manager"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"EMAIL_ADDRESS\" for email addresses"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"BLOG\" for blog listing pages"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "The URL you are linking to. ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {}), "open_in_new_tab ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\", determines if ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "target=\"_blank\""
            }), " should be added ", (0, _jsxRuntime.jsx)("br", {}), "no_follow ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\", determines if ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rel=\"nofollow\""
            }), " should be used ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Logo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"override_inherited_src\" : true,  \"src\" : \"https://cdn2.hubspot.net/hubfs/logo.png\",  \"alt\" : \"best_logo_ever\",  \"width\" : 100,  \"height\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["override_inherited_src ", (0, _jsxRuntime.jsx)("br", {}), "true/false overide the portal defaults ", (0, _jsxRuntime.jsx)("br", {}), "src ", (0, _jsxRuntime.jsx)("br", {}), "Image URL ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "Alt text, used for screen readers and search engines. ", (0, _jsxRuntime.jsx)("br", {}), "width ", (0, _jsxRuntime.jsx)("br", {}), "width the image is to be displayed at ", (0, _jsxRuntime.jsx)("br", {}), "height ", (0, _jsxRuntime.jsx)("br", {}), "height the image is to be displayed at ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Meeting"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String (meeting link)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://app.hubspot.com/meetings/developers-r-kewl\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Menu"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer (menu ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "123456789"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Page"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer (page ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "richtext"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String (can contain HTML)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"# Hello, world! <RelatedApiLink />\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Salesforce Campaign"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String (Salesforce campaign ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"7016A0000005S0tQAE\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Simple Menu"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array of menu item objects"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[   {     \"isPublished\" : true,    \"pageLinkId\" : 123456789,    \"pageLinkName\" : \"My page\",    \"isDeleted\" : false,    \"categoryId\" : 1,    \"subCategory\" : \"site_page\",    \"contentType\" : \"site_page\",    \"state\" : \"PUBLISHED_OR_SCHEDULED\",    \"linkLabel\" : \"This is a page\",    \"linkUrl\" : null,    \"linkParams\" : null,    \"linkTarget\" : null,    \"type\" : \"PAGE_LINK\",    \"children\" : [ ]  } ]"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["isPublished ", (0, _jsxRuntime.jsx)("br", {}), "true/false is the menu item's page published? ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkId ", (0, _jsxRuntime.jsx)("br", {}), "Page id in the CMS ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkName ", (0, _jsxRuntime.jsx)("br", {}), "The page's actual name in the CMS ", (0, _jsxRuntime.jsx)("br", {}), "isDeleted ", (0, _jsxRuntime.jsx)("br", {}), "true/false ", (0, _jsxRuntime.jsx)("br", {}), "categoryId ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "1 - Site Page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "3 - Blog post"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "subCategory ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "site_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "landing_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "blog"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "normal_blog_post"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "contentType ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "site_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "landing_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "blog"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "state ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "DRAFT"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "DRAFT_AB"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED_OR_SCHEDULED"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED_AB"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "SCHEDULED"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "linkLabel ", (0, _jsxRuntime.jsx)("br", {}), "text the user reads and clicks ", (0, _jsxRuntime.jsx)("br", {}), "linkUrl ", (0, _jsxRuntime.jsx)("br", {}), "actual URL the user is sent to upon clicking ", (0, _jsxRuntime.jsx)("br", {}), "linkParams ", (0, _jsxRuntime.jsx)("br", {}), "\\# links or ? query parameters ", (0, _jsxRuntime.jsx)("br", {}), "linkTarget ", (0, _jsxRuntime.jsx)("br", {}), "if open in new tab is enabled \"_blank\" otherwise \"null\" ", (0, _jsxRuntime.jsx)("br", {}), "type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"PAGE_LINK\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"PAGE_LINK_WITH_PARAMS\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"NO_LINK\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "children ", (0, _jsxRuntime.jsx)("br", {}), "array of menu item objects, identical to individual menu items. ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Tag"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer (tag ID or slug, ID is recommended)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"it's like any other string\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"type\" : \"CONTENT\",  \"href\" : null,  \"content_id\" : 123456789}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"EXTERNAL\" for non HubSpot non-email URLs"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"CONTENT\" for pages, blog posts, and landing pages"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"FILE\" for files uploaded to the file manager"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"EMAIL_ADDRESS\" for email addresses"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"BLOG\" for blog listing pages"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "String, the URL you are linking to. ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}