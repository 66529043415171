"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921778;
const slug = exports.slug = 'guides/api/files';
const title = exports.title = 'CMS-API | Datei-Manager';
const name = exports.name = 'vNext Docs DP - Datei-Manager';
const metaDescription = exports.metaDescription = 'Die Dateien-Endpunkte werden verwendet, um Daten in Ihrem Datei-Manager abzurufen und zu verwalten';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eine-datei-hochladen",
  "label": "Eine Datei hochladen",
  "parent": null
}, {
  "depth": 0,
  "id": "den-upload-status-einer-datei-%C3%BCberpr%C3%BCfen",
  "label": "Den Upload-Status einer Datei überprüfen",
  "parent": null
}, {
  "depth": 0,
  "id": "details-einer-datei-anzeigen",
  "label": "Details einer Datei anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "eine-datei-l%C3%B6schen",
  "label": "Eine Datei löschen",
  "parent": null
}, {
  "depth": 0,
  "id": "einen-ordner-erstellen",
  "label": "Einen Ordner erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A4nderungen-in-v3",
  "label": "Änderungen in v3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Datei-Manager"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie das Dateien-Tool von HubSpot, um Dateien in HubSpot zu verwalten und zu speichern. In HubSpot gehostete Dateien können hochgeladen und in HubSpot und externen Inhalten verwendet werden. Sie können auch über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Interaktionen-API"
      }), " an Datensätze angehängt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Ihr Unternehmen seine Website mit dem CMS-System von HubSpot erstellt, können Sie die Dateien-API verwenden, um Elemente in HubSpot hochzuladen und zu speichern. Diese Dateien können dann über CMS Hub bereitgestellt und geteilt werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können das Dateien-Tool ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/files/upload-files-to-use-in-your-hubspot-content",
        children: "innerhalb von HubSpot"
      }), " oder mithilfe der Dateien-API aufrufen. Im Folgenden erfahren Sie mehr über die Dateien-API und wie Sie Dateien hochladen und löschen. Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " oben, um eine vollständige Liste der API-Endpunkte anzuzeigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Datei hochladen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dateien können mithilfe einer multipart/form-data-", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files"
      }), " mit den folgenden Feldern hochgeladen werden. Beim Upload ist zwar keine bestimmte Ordner-ID erforderlich, es wird jedoch empfohlen, Dateien in einen Ordner hochzuladen und nicht in das Stammverzeichnis. Die Ordneranforderungen beim Upload können sich in Zukunft ändern."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Datei, die hochgeladen wird (erforderlich)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein JSON-Objekt, das den Datenschutz und die Indexierbarkeit der Datei steuert und zwei Felder enthält: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "access"
            }), ", das erforderlich ist, und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), ", das einen Zeitraum angibt, nach dem die Datei automatisch gelöscht wird. Wenn Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), "-Feld verwenden:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "Die Mindestdauer, die festgelegt werden muss, beträgt 1 Tag."
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Der maximale Zeitraum, der festgelegt werden kann, beträgt 1 Jahr."
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Nach dem festgelegten Zeitraum wird die Datei vollständig gelöscht. Nach dem Löschen kann die Datei nicht wiederhergestellt werden."
              })]
            }), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ID des Ordners, in den die Datei hochgeladen wird. Entweder dieses Feld ", (0, _jsxRuntime.jsx)("u", {
              children: "oder"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            }), " muss in Ihrer Anfrage angegeben werden (aber ", (0, _jsxRuntime.jsx)("u", {
              children: "nicht"
            }), " beides)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Pfad des Ordners, in den die Datei hochgeladen wird. Entweder dieses Feld ", (0, _jsxRuntime.jsx)("u", {
              children: "oder"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            }), " muss in Ihrer Anfrage angegeben werden (aber ", (0, _jsxRuntime.jsx)("u", {
              children: "nicht"
            }), " beides)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name der Datei. Wird kein Name angegeben, wird ein Name aus dem Inhalt der Datei generiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charsetHunch"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichensatzcodierung für die hochgeladene Datei. Falls nicht angegeben, wird sie aus der Datei abgeleitet."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im folgenden Beispiel soll eine Datei mit den folgenden Kriterien in Ihren HubSpot-Account hochgeladen werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dateiname:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cat.png"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zielordner im HubSpot-Datei-Manager:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/library/cat_archive"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zugriff auf die Datei in HubSpot:"
        }), " privat zugänglich"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Header und der Anfragetext müssten Teil Ihrer Anfrage sein:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubapi.com/files/v3/files?=' \\\n  --header 'Authorization: Bearer pat-na1-00000000-0000-0000-0000-000000000000' \\\n  --header 'Content-type: multipart/form-data' \\\n  --form file=@/Users/person/Downloads/cat.png \\\n  --form 'options={\"access\": \"PRIVATE\"}' \\\n  --form folderPath=/library/cat_archive\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die resultierende Antwort enthält die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "parentFolderId"
      }), " der hochgeladenen Datei, mit der Sie die Datei über eine GET-Anfrage abrufen können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// 201 Response from successful file upload\n{\n  \"id\": \"122692044085\",\n  \"createdAt\": \"2023-06-28T17:56:45.393Z\",\n  \"updatedAt\": \"2023-06-28T17:56:45.393Z\",\n  \"archived\": false,\n  \"parentFolderId\": \"122692510820\",\n  \"name\": \"cat\",\n  \"path\": \"/library/cat_archive/cat.png\",\n  \"size\": 24574,\n  \"height\": 219,\n  \"width\": 225,\n  \"encoding\": \"png\",\n  \"type\": \"IMG\",\n  \"extension\": \"png\",\n  \"defaultHostingUrl\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"url\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"isUsableInContent\": true,\n  \"access\": \"PRIVATE\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Den Upload-Status einer Datei überprüfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine Datei mithilfe einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async"
      }), " von einer URL in Ihren Datei-Manager importieren, können Sie den Upload-Status der Datei überprüfen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Führen Sie dazu eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async/tasks/{taskId}/status"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie diese Anfrage durchgeführt haben, erhalten Sie eine der folgenden Antworten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING"
        }), ": die Datei befindet sich in der Warteschlange, die hochgeladen werden soll. Der Importvorgang hat noch nicht begonnen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), ": die Datei wird gerade hochgeladen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELED"
        }), ": der Upload wurde abgebrochen und die Datei wird nicht hochgeladen. Um die Datei in Ihren HubSpot-Account zu importieren, müssen Sie die Datei erneut hochladen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "COMPLETE"
        }), ": die Datei wurde erfolgreich in das Dateien-Tool hochgeladen. Die hochgeladene Datei wird in Ihrem Dateien-Tool angezeigt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Details einer Datei anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um sich die Details einer Datei anzusehen, die in das Dateien-Tool hochgeladen wurde, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), " durch. Dadurch wird die Datei mit Details wie Name, Höhe und Breite, Codierung, URL und mehr zurückgegeben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So rufen Sie beispielsweise die Details einer Datei ab:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn eine Datei auf „privat“ festgelegt ist, führt die zurückgegebene URL zu einem 404-Fehler. Um eine aufrufbare URL der Datei zu erhalten, können Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/files/v3/files/{fileId}/signed-url"
      }), " vornehmen. Wenn Sie diese Anfrage durchführen, können Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property"
      }), "-Parameter einschließen, damit spezifische Eigenschaften wie Höhe und Breite zurückgegeben werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Datei löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Datei zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), " durch. Dadurch wird die Datei als gelöscht markiert und der Inhalt der Datei kann nicht mehr aufgerufen werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Datei vollständig zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}/gdpr-delete"
      }), " vor. Dadurch werden der Inhalt und die Metadaten der Datei innerhalb von 7 Tagen vollständig gelöscht."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn eine Datei nicht DSGVO-konform gelöscht wird, bleiben ihre Inhalte auf den HubSpot-Servern in einem privaten Zustand gespeichert, bei dem kein Benutzer darauf zugreifen kann. Um sicherzustellen, dass Dateiinhalte vollständig gelöscht werden, verwenden Sie die Funktionalität für eine DSGVO-konforme Löschung."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Ordner erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Ordner zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/folders"
      }), " durch. Bei der Anfrage können Sie die folgenden Felder einbeziehen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Erforderlich"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ja"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Name des Ordners, den Sie erstellen möchten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nein"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um den Ordner innerhalb eines vorhandenen Ordners zu erstellen, berücksichtigen Sie dieses Feld in der ID des vorhandenen Ordners. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), " können nicht gleichzeitig festgelegt werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nein"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um den Ordner innerhalb eines vorhandenen Ordners zu erstellen, berücksichtigen Sie dieses Feld im Pfad des vorhandenen Ordners ein. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), " können nicht gleichzeitig festgelegt werden."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body of POST request to /files/v3/folders\n{\n  \"name\": \"myNewFolder\",\n  \"parentFolderId\": 12345\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Änderungen in v3"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie bislang die vorherige Version dieser API verwendet haben, müssen Sie bei v3 folgende Änderungen berücksichtigen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Alle über die API hochgeladenen Dateien sind im Dateien-Dashboard und in der Dateiauswahl sichtbar. Ausgeblendete Dateien können nicht erstellt werden. Private Dateien und nicht-indexierbare Dateien können jedoch weiterhin erstellt werden."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Listing-Dateien geben ausgeblendete oder gelöschte Dateien nicht zurück. Es kann jedoch eine wesentlich breitere Spanne von Filtern angewendet werden. Ausgeblendete Dateien können weiterhin nach ID abgerufen werden, erfordern jedoch einen neuen Bereich: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "files_ui_hidden.read."
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Es können nicht mehrere Dateien mit einer einzigen Anfrage hochgeladen werden."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Ordneraktualisierungsaktionen wie das Verschieben und Umbenennen sind jetzt asynchron. Jede Anfrage gibt ein Token zurück, das verwendet werden kann, um den Status des Ordners zu überprüfen."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Für Endpunkte, die Dateien erstellen oder ersetzen, müssen Sie Zugriffsebenen für die Dateien angeben. Diese Zugriffsebenen sind:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_INDEXABLE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " Die Datei ist für alle, die die URL kennen, öffentlich zugänglich. Suchmaschinen können die Datei indexieren."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_NOT_INDEXABLE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " Die Datei ist für alle, die die URL kennen, öffentlich zugänglich. Das X-Robots-Tag: Der noindex-Header wird bei jedem Abrufen der Datei gesendet. Dabei werden Suchmaschinen angewiesen, die Datei nicht zu indexieren."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsxs)(_components.strong, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "PRIVATE"
              }), ":"]
            }), " Die Datei ist nicht öffentlich zugänglich. Eine signierte URL ist erforderlich, um Inhalte anzuzeigen. Suchmaschinen können die Datei nicht indexieren."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Endpunkte, die Dateien erstellen, ermöglichen ein gewisses Maß an Erkennung von Duplikaten als Teil der Upload-Optionen der Datei."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "ENTIRE_PORTAL"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " Es wird nach einer doppelten Datei im Account gesucht."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "EXACT_FOLDER"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " Es wird im angegebenen Ordner nach einer doppelten Datei gesucht."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "NONE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " Es wird keine Überprüfung auf Duplikate ausgeführt."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "REJECT"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " Der Upload wird abgelehnt, wenn ein Duplikat gefunden wird."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "RETURN_EXISTING"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " Wenn eine doppelte Datei gefunden wird, darf keine neue Datei hochgeladen werden. Stattdessen wird das gefundene Duplikat zurückgegeben."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Die Erkennung von Duplikaten erfolgt in einem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationScope"
            }), ", was sich auf die Art und Weise auswirkt, wie wir nach einem Duplikat suchen."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Dies erfordert auch eine ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationStrategy"
            }), ", die vorgibt, was passiert, wenn ein Duplikat gefunden wird."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}