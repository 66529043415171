"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358694;
const slug = exports.slug = 'guides/apps/marketplace/listing-your-app';
const title = exports.title = 'Marketplace de aplicativos | Listar seu aplicativo';
const name = exports.name = 'vNext DP de documentos - Listar seu aplicativo';
const metaDescription = exports.metaDescription = 'Siga estas etapas para enviar um aplicativo para ser listado no Marketplace de aplicativos da HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-e-enviar-uma-lista-de-aplicativos",
  "label": "Criar e enviar uma lista de aplicativos",
  "parent": null
}, {
  "depth": 0,
  "id": "informa%C3%A7%C3%A3o-da-lista",
  "label": "Informação da lista",
  "parent": null
}, {
  "depth": 0,
  "id": "informa%C3%A7%C3%B5es-do-aplicativo",
  "label": "Informações do aplicativo",
  "parent": null
}, {
  "depth": 0,
  "id": "pre%C3%A7os",
  "label": "Preços",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-do-aplicativo",
  "label": "Recursos do aplicativo",
  "parent": null
}, {
  "depth": 0,
  "id": "informa%C3%A7%C3%B5es-de-suporte",
  "label": "Informações de suporte",
  "parent": null
}, {
  "depth": 0,
  "id": "revisar-informa%C3%A7%C3%B5es-e-enviar-sua-listagem",
  "label": "Revisar informações e enviar sua listagem",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-e-atualizar-uma-listagem-localizada-para-uma-lista-de-aplicativos-existente",
  "label": "Criar e atualizar uma listagem localizada para uma lista de aplicativos existente",
  "parent": null
}, {
  "depth": 0,
  "id": "editar-uma-lista-de-aplicativos-ativa",
  "label": "Editar uma lista de aplicativos ativa",
  "parent": null
}, {
  "depth": 0,
  "id": "cancelar-a-publica%C3%A7%C3%A3o-de-uma-lista-de-aplicativos-ativa",
  "label": "Cancelar a publicação de uma lista de aplicativos ativa",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Listar seu aplicativo"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de criar um aplicativo na sua conta de desenvolvedor, que atenda aos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Requisitos para a listagem do Marketplace de aplicativos"
      }), ", você poderá enviar uma lista para adicioná-la ao ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "Marketplace de aplicativos da HubSpot"
      }), ". A equipe de Qualidade do Ecossistema da HubSpot analisará o seu envio e fará o acompanhamento por e-mail se o aplicativo tiver sido aprovado ou rejeitado."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você precisa ser ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "Superadministrador"
        }), " para atualizar e enviar uma lista de aplicativos."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Neste artigo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "Criar e enviar uma lista de aplicativos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-update-a-localized-listing-for-an-existing-app-listing",
          children: "Criar e atualizar uma listagem localizada para uma lista de aplicativos existente"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "Adicionar pontos de contato para o Programa de Parceiros de Aplicativos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#edit-localized-listing",
          children: "Editar uma lista de aplicativos ativa"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unpublish-a-live-app-listing",
          children: "Cancelar a publicação de uma lista de aplicativos ativa"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar e enviar uma lista de aplicativos"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Observação: **antes de enviar uma lista de aplicativos, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "Página de requisitos para a listagem de aplicativos"
        }), " para entender como preencher sua lista."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "conta de desenvolvedor de aplicativos"
        }), ", acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace de aplicativos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listagens"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "No canto superior direito, clique em** Criar lista**. Se esse botão não estiver disponível para seleção, isso indica que listas já foram criadas para todos os aplicativos existentes."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "aplicativo"
        }), " para o qual deseja criar uma lista e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Próximo"
        }), ". Os aplicativos que já estão listados no Marketplace de aplicativos não serão exibidos aqui."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na tela seguinte, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Selecionar os idiomas nos quais seu aplicativo está disponível"
        }), " e selecione os ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "idiomas"
        }), " nos quais o software do aplicativo é oferecido. O Marketplace de aplicativos está disponível em oito idiomas: inglês, espanhol, francês, alemão, português, japonês, holandês e italiano. Você pode criar listas de aplicativos em quantos idiomas quiser, desde que o software do seu aplicativo esteja disponível nesses idiomas."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Selecionar o idioma da lista principal para [nome do aplicativo]"
        }), " e selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "idioma padrão"
        }), " que os usuários verão quando navegarem no Marketplace de aplicativos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Próximo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O assistente de listagem de aplicativos tem cinco guias de informações a serem preenchidas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informação da lista"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Informações da lista"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Informações do aplicativo"
          }), ", adicione o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome público do aplicativo"
          }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "o nome da empresa"
          }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "o slogan"
          }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "a URL do botão para instalar"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Ícone do aplicativo"
          }), ", faça upload de um ícone de 800 px por 800 px para o aplicativo. Ele aparecerá no Marketplace de aplicativos e nas contas dos usuários conectados. As práticas recomendadas para os ícones são:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Permitido"
            }), ": use um arquivo JPG, JPEG ou PNG, preencha todo o espaço (800px por 800px) - sua imagem deve tocar pelo menos duas bordas e usar uma imagem de alta resolução e sem pixels."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Não Permitido"
            }), ": inclua um texto no seu ícone, use um logotipo ou deixe um espaço em branco extra ao redor do ícone."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/dev-doc-logo.png",
        alt: "dev-doc-logo"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Categorizar seu aplicativo"
        }), ", selecione uma ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "categoria"
        }), " para o aplicativo e quaisquer ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "termos de pesquisa"
        }), " que possam ser usados para encontrar seu aplicativo no Marketplace de aplicativos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informações do aplicativo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Detalhes do aplicativo"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Vídeo de demonstração"
        }), ", faça upload de um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "vídeo"
        }), " para mostrar como funciona o aplicativo. Consulte a página ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
          children: "Como fazer um ótimo vídeo de demonstração de aplicativo"
        }), " para obter as melhores práticas e exemplos de como criar um vídeo de demonstração."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Capturas de tela"
        }), ", adicione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "imagens"
        }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "texto alternativo"
        }), " para mostrar como funciona o aplicativo. Você pode adicionar até oito imagens.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Clique em Adicionar ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "dados compartilhados"
            }), ".Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Dados compartilhados"
            }), ", informe aos usuários como os dados transitarão entre o seu aplicativo e o HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["No painel direito, selecione qual objeto do aplicativo é sincronizado com qual objeto do HubSpot e a direção da sincronização. Na seção _Visão geral do aplicativo _, faça uma breve ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "descrição"
            }), " de como o seu aplicativo pode ajudar os usuários a realizar seus objetivos."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para adicionar outra sincronização de objeto, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Adicionar outro objeto"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/shared-data-app-listing.png",
        alt: "shared-data-app-listing"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Recursos do HubSpot compatíveis com o aplicativo"
        }), ", adicione até ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "10 ferramentas e recursos"
        }), " do HubSpot compatíveis com o seu aplicativo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Outras ferramentas com as quais seu software se integra"
        }), ", selecione até ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "seis outras ferramentas ou aplicativos"
        }), " com os quais seu software se integra."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Idiomas nos quais seu aplicativo está disponível"
        }), ", selecione todos os ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "idiomas"
        }), " disponíveis para o seu aplicativo. Você só pode criar listas adicionais do Marketplace de aplicativos nesses idiomas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preços"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Preços"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na seção superior, selecione a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "moeda"
          }), " na qual deseja listar o aplicativo. Você pode escolher entre mais de 100 moedas."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Você também pode definir ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "planos de preços"
          }), " para seu aplicativo, adicionando o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "modelo de preços"
          }), ", o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome do plano"
          }), ", o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "slogan"
          }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "detalhes de preços"
          }), " e a", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "lista de recursos"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Dependendo do modelo de preços selecionado, você pode precisar adicionar mais informações, como a frequência de pagamento, as taxas únicas ou os preços mensais. Passe o cursor do mouse sobre o ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "ícone de informações"
              }), " para saber mais sobre cada modelo de preços."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/pricing-model-app-listing.png",
                alt: "pricing-model-app-listing"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Você pode adicionar até cinco planos de preços. Para adicionar mais planos de preços, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Adicionar outro plano"
              })]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Adicionar um link para o plano de preços do seu software"
          }), ", insira o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " onde os usuários poderão encontrar mais informações sobre seus planos de preços."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Planos de preços de uma agência"
          }), ", insira o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " onde os usuários poderão saber mais sobre os preços para os serviços de consultoria ou parceria."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos do aplicativo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Recursos do aplicativo"
      }), ", adicione os recursos e oriente os clientes sobre como usá-los. Não há limite para o número de recursos que podem ser criados para o seu aplicativo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adicionar um recurso"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na página ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Detalhes do recurso"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Insira o ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "nome do recurso"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecione todos os ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "grupos de escopo"
            }), " que um cliente precisa para ter esse recurso. Os grupos de escopo são usados para determinar se a conta da HubSpot do cliente é compatível com os recursos do aplicativo."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Insira uma ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "descrição"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecione uma ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "imagem"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecione onde deseja exibir o recurso: ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "na listagem do marketplace do seu aplicativo"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "como um cartão de descoberta de recursos"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode mostrar um máximo de cinco recursos na listagem do marketplace de aplicativos e um máximo de 10 recursos como cartões de descoberta."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você optar por exibir o recurso como um cartão de descoberta de recurso:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecionar um botão principal:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Link para um recurso:"
            }), " selecione o recurso do HubSpot ao qual o botão deve se vincular."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Criar personalizado"
            }), ": insira ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Texto do botão"
            }), " e o ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "URL do Botão"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Sem botão principal"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecione um guia de instruções para integrar seus clientes:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Crie um guia do zero:"
            }), " insira um ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "título"
            }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "descrição"
            }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "imagem ou vídeo"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Link externo para o tutorial:"
            }), " insira o ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "URL do tutorial"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Guia apenas de vídeo:"
            }), " carregue um ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "vídeo"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para adicionar outro guia, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adicionar outra seção"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/feature-discovery-card.png",
        alt: "feature-discovery-card"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informações de suporte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Informações de suporte"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informações de contato"
        }), ", adicione um método de contato de suporte para os usuários que tenham dúvidas ao usar o aplicativo. Adicione o seu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "e-mail de suporte"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "o site da empresa"
        }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "os idiomas"
        }), " nos quais o suporte ao cliente é oferecido."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Recursos de suporte"
        }), ", inclua ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "links"
        }), " para a documentação de configuração do seu aplicativo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Termos de Serviço e Política de Privacidade"
        }), ", adicione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "links"
        }), " para a sua documentação de privacidade."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Revisar informações e enviar sua listagem"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Informações de revisão"
      }), " guia:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na janela ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Instruções de revisão do aplicativo"
        }), " , insira qualquer ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "instruções de revisão do aplicativo"
        }), " que a equipe de Qualidade do Ecossistema da HubSpot precisa para testar e analisar seu aplicativo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Pontos de contato do Programa de Parceiros de Aplicativos"
        }), ", inclua as informações de contato de qualquer pessoa que precise ser comunicada sobre a lista de aplicativos, além do desenvolvedor do aplicativo. Você deve incluir a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "função"
        }), " da pessoa, ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome e sobrenome"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "e-mail"
        }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "país"
        }), " onde ela está localizada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para adicionar outro ponto de contacto, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adicionar outro ponto de contato"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar a lista para revisão, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Enviar para revisão"
      }), " no canto superior direito. Se o botão ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enviar para revisão"
      }), " não estiver disponível para seleção, verifique se você preencheu todos os campos obrigatórios e se tem permissões de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Superadministrador"
      }), ". Caso tenha esquecido algum campo obrigatório, você verá um número no título da guia indicando o número de campos perdidos. Clique em cada guia para inserir as informações ausentes, em seguida, retorne à guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Revisar informações"
      }), " e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Enviar para revisão"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-features.png",
        alt: "app-features"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar e atualizar uma listagem localizada para uma lista de aplicativos existente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você precisará definir um idioma principal na sua lista de aplicativos existente e ter sua lista principal já publicada no Marketplace da HubSpot para criar listas em outros idiomas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na conta de desenvolvedor do aplicativo HubSpot, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketplace de aplicativos"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listagem"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Se você já tiver um aplicativo listado no Marketplace, verá um banner amarelo em cima dele, solicitando a definição do idioma principal da lista. Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Definir agora"
          }), ". Você precisará definir o idioma principal da lista de aplicativos antes de poder criar novas listas de idiomas."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na caixa de diálogo, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Selecionar os idiomas nos quais seu aplicativo está disponível"
          }), " e selecione os ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "idiomas"
          }), " nos quais o software está disponível."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Selecionar o idioma da lista principal para [nome do aplicativo]"
          }), " e selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "idioma padrão"
          }), " que os usuários verão quando navegarem no Marketplace de aplicativos."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Salvar"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de definir um idioma principal, você poderá adicionar uma nova listagem localizada:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Passe o cursor do mouse sobre a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "lista de aplicativos"
        }), " e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mais"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar lista em outro idioma"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Idioma para esta lista"
        }), " e selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "idioma"
        }), " no qual deseja criar essa lista."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Siga as etapas para criar e enviar uma lista no idioma selecionado."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Todas as partes da sua listagem localizada devem estar no mesmo idioma, incluindo capturas de tela de produtos e vídeos de demonstração."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "A atualização da sua lista principal não atualiza automaticamente a listagem localizada. Cada uma deve ser atualizada de forma independente."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Editar uma lista de aplicativos ativa"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois que uma lista de aplicativos estiver ativa no Marketplace de aplicativos, para editá-la:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na conta de desenvolvedor, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace de aplicativos > Listas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Arraste cursor do mouse sobre a lista que deseja editar e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mais"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Editar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Faça as alterações e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar para revisão"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cancelar a publicação de uma lista de aplicativos ativa"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na conta de desenvolvedor, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace de aplicativos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listagens"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Passe o cursor do mouse sobre a lista que deseja cancelar a publicação e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mais"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cancelar publicação da lista ativa"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa de diálogo, insira o motivo para cancelar a publicação do aplicativo e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar solicitação de cancelamento de publicação"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As solicitações de cancelamento de publicação são processadas pela equipe do Marketplace da HubSpot dentro de 10 dias úteis após o envio."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}