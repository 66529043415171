"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125363;
const slug = exports.slug = 'guides/cms/debugging/code-alerts';
const title = exports.title = 'Code-Warnungen';
const name = exports.name = 'EMEA DACH (de) Code Alerts';
const metaDescription = exports.metaDescription = 'Code-Warnungen bieten Ihnen eine Übersicht über Probleme, die innerhalb Ihrer CMS-Seiten im Zusammenhang mit Ihrem CSS, Ihren Modulen oder Ihren Vorlagen festgestellt wurden.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "anzeigen-von-code-warnungen",
  "label": "Anzeigen von Code-Warnungen",
  "parent": null
}, {
  "depth": 0,
  "id": "elementtypen-und-probleme",
  "label": "Elementtypen und Probleme",
  "parent": null
}, {
  "depth": 1,
  "id": "typen-von-inhaltselementen",
  "label": "Typen von Inhaltselementen",
  "parent": "elementtypen-und-probleme"
}, {
  "depth": 1,
  "id": "probleme",
  "label": "Probleme",
  "parent": "elementtypen-und-probleme"
}, {
  "depth": 0,
  "id": "anzeigen-der-f%C3%BCr-ihre-elemente-identifizierten-probleme",
  "label": "Anzeigen der für Ihre Elemente identifizierten Probleme",
  "parent": null
}, {
  "depth": 1,
  "id": "vorlagen-%2C-seiten--und-modulelemente",
  "label": "Vorlagen-, Seiten- und Modulelemente",
  "parent": "anzeigen-der-f%C3%BCr-ihre-elemente-identifizierten-probleme"
}, {
  "depth": 1,
  "id": "css-elemente",
  "label": "CSS-Elemente",
  "parent": "anzeigen-der-f%C3%BCr-ihre-elemente-identifizierten-probleme"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Code-Warnungen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Code-Warnungen bieten Entwicklern und IT-Managern einen zentralen Ort, an dem sie einen Überblick über die Probleme sehen können, die in Ihrem CMS von HubSpot identifiziert wurden. Durch die Behebung von Problemen, die in Code-Warnungen identifiziert werden, können Sie Ihre Website optimieren, indem Sie die Benutzerfreundlichkeit für Ihre Kunden und die Performance Ihrer Website insgesamt verbessern."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Weitere Möglichkeiten, wie HubSpot Ihnen hilft, das Potenzial Ihrer Website zu maximieren, finden Sie auf ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "unserer Referenzseite zu CDN, Sicherheit und Performance."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alerts-dashboard.jpg",
        alt: "Dashboard für Code-Warnungen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anzeigen von Code-Warnungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sehen Sie sich Ihre Code-Warnungen für Ihr gesamtes Portal an, indem Sie über jede veröffentlichte CMS-Seite, auf der Sie authentifiziert sind, auf das HubSpot-Menü klicken und ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "„Code-Warnungen anzeigen“"
      }), " auswählen oder direkt zu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/code-alerts",
        children: "Code-Warnungen"
      }), " gehen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/view-code-alerts-menu.png",
        alt: "Code-Warnungen über das HubSpot-Menü anzeigen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Elementtypen und Probleme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Typen von Inhaltselementen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt mehrere Arten von Inhaltselementen, auf die Code-Warnungen innerhalb Ihrer HubSpot CMS-Website überprüfen können. Diese sind im Folgenden aufgeführt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Elementtyp"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Blog Post"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Blog-Beitrag aus einem Ihrer HubSpot-Blogs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Site Page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Website-Seite in CMS Hub."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Landing Page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Website-Seite mit einem bestimmten Zweck – das Ziel einer Landing-Page ist es, Besucher in Leads zu konvertieren."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ihre HubSpot-Blog-Listing-Seite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Module"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wiederverwendbare Komponenten, die in Vorlagen verwendet oder zu Seiten hinzugefügt werden können."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Template"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vorlagen sind wiederverwendbare Seiten- oder E-Mail-Wrapper, die im Allgemeinen Module und Teilvorlagen in ein Layout platzieren."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine CSS-Datei (Cascading Style Sheet)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Knowledge Article"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Artikel aus Ihrer HubSpot-Wissensdatenbank."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Unknown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wenn ein Elementtyp unbekannt ist."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Probleme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt viele verschiedene Arten von Problemen, die Ihre Elemente betreffen können. Wenn Ihr Element mehr als ein Problem aufweist, wird es in Ihrem Dashboard für Code-Warnungen mehrfach aufgeführt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Problem"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiel"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HubL limit"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The HubL function ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "blog_recent_tag_posts"
            }), " is used ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "11"
            }), " times on this page. ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "blog_recent_tag_posts"
            }), " has a limit of ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "5"
            }), " uses per page."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/not-found",
              children: "Bestimmte HubL-Funktionen unterliegen Einschränkungen"
            }), " hinsichtlich ihrer Verwendung. Wenn Ihre Funktion diese Einschränkungen überschreitet, werden Sie auf dieses Problem hingewiesen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSS combining"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There is a syntax error in a line of code that is preventing the CSS files from being combined."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Identifiziert CSS-Dateien, die Probleme enthalten, die ein Minifizieren/Komprimieren der Datei verhindern würden. Es gibt auch eine Option zum ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Anzeigen von Syntaxfehlern"
            }), ", die sich auf dieses Element beziehen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Output too big"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This page is ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "10446 Kilobytes"
            }), ". The size limit is ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "9765 Kilobytes"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der generierte HTML-Code für die Seite hat das Limit überschritten. Dies kann dazu führen, dass eine leere oder unvollständige Seite angezeigt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Template error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There is an error in the code that is preventing this template from being rendered."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Identifiziert Fehler in Ihrem Vorlagencode, die verhindern, dass die Vorlage gerendert wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Unknown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unknown issue"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wenn es dem System nicht gelingt, den Fehler im Element zu identifizieren, wird ein unbekanntes Problem zugewiesen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anzeigen der für Ihre Elemente identifizierten Probleme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Code-Warnungen enthalten Deep Links zu Elementen, bei denen Warnungen entdeckt werden. Wenn Sie den Mauszeiger über eine Zeile bewegen, werden Ihnen die folgenden Optionen pro Elementtyp angezeigt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorlagen-, Seiten- und Modulelemente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Über die Schaltfläche „Aktionen“ erhalten Sie einen Link zum Öffnen der entsprechenden Vorlage oder des Moduls im Design-Manager."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/my-template-open-in-dm.jpg",
        alt: "Probleme mit Vorlagen und Modulen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CSS-Elemente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Über die Schaltfläche „Aktionen“ erhalten Sie einen Link, über den Sie das entsprechende Stylesheet im Design-Manager öffnen, Ihre Datei mit Debugging-Informationen aufrufen oder die Syntaxfehler anzeigen können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alerts-css-options.jpg",
        alt: "CSS-Probleme"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie „Syntaxfehler anzeigen“ auswählen, wird ein Modalfenster mit zusätzlichen Details zum Syntaxfehler und der Zeilennummer, in der der Fehler auftritt, angezeigt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alert-syntax-error-details.png",
        alt: "Modalfenster mit zusätzlichen Details zum Syntaxfehler"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}