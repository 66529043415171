"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77668268350;
const slug = exports.slug = 'guides/api/marketing/marketing-events';
const title = exports.title = 'API marketing | Événements marketing';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Marketing Events';
const metaDescription = exports.metaDescription = "Une vue d'ensemble de l'objet Événements marketing dans HubSpot.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "in-this-article",
  "label": "In this article",
  "parent": null
}, {
  "depth": 0,
  "id": "exigences-des-domaines",
  "label": "Exigences des domaines",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-et-mettre-%C3%A0-jour-des-%C3%A9v%C3%A9nements",
  "label": "Créer et mettre à jour des événements",
  "parent": null
}, {
  "depth": 0,
  "id": "points-de-terminaison-de-participation-%C3%A0-l-%C3%A9v%C3%A9nement",
  "label": "Points de terminaison de participation à l'événement",
  "parent": null
}, {
  "depth": 0,
  "id": "configurer-les-param%C3%A8tres-d-application",
  "label": "Configurer les paramètres d'application",
  "parent": null
}, {
  "depth": 1,
  "id": "%C3%A9tape-1--cr%C3%A9ez-une-api-dans-votre-application",
  "label": "Étape 1 : Créez une API dans votre application",
  "parent": "configurer-les-param%C3%A8tres-d-application"
}, {
  "depth": 1,
  "id": "%C3%A9tape-2--fournissez-%C3%A0-hubspot-le-chemin-d-url-vers-votre-api",
  "label": "Étape 2 : Fournissez à HubSpot le chemin d'URL vers votre API",
  "parent": "configurer-les-param%C3%A8tres-d-application"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      hr: "hr",
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      code: "code",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Événements marketing"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un événement marketing est un objet de CRM similaire aux contacts et aux entreprises. Celui-ci vous permet de suivre et d'associer des événements marketing, tels qu'un webinaire, à d'autres objets de CRM HubSpot. Découvrez ci-dessous comment utiliser l'API d'événements marketing pour intégrer des événements marketing dans une application."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In this article"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "Exigences des domaines"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "Points de terminaison de participation à l'événement"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-app-settings",
          children: "Configurer les paramètres d'application"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-1-create-an-api-in-your-app",
              children: "Étape 1 : Créez une API dans votre application"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-2-provide-hubspot-with-the-url-path-to-your-api",
              children: "Étape 2 : Fournissez à HubSpot le chemin d'URL vers votre API"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exigences des domaines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour effectuer une demande API vers l'un des points de terminaison d'événement marketing, les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "champs d'application suivants"
      }), " sont requis :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.read"
        }), " : autorise la récupération des données relatives aux événements marketing et à la participation."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.write"
        }), " : accorde la permission de créer, de supprimer ou d'apporter des modifications aux informations relatives à un événement marketing."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de l'authentification des appels effectués par votre application, vous pouvez utiliser un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "jeton d'accès d'application privée"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth"
      }), ". Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "méthodes d'authentification"
      }), ". Pour la liste complète des points de terminaison disponibles, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer et mettre à jour des événements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez créer ou mettre à jour des événements marketing en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " vers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/upsert"
      }), ". Vous pouvez inclure tous les éléments ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " qui correspondent aux événements que vous souhaitez mettre à jour dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), " de votre corps de demande, ainsi que tout autre détail de votre événement (y compris son nom, son heure de début et sa description)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si un événement marketing existe déjà avec l'ID spécifié dans votre demande, il sera mis à jour. Sinon, un nouvel événement sera créé."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, la demande suivante créera un événement avec un ID de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "4"
      }), " nommé « Cours de cuisine virtuel » :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for POST request to /marketing/v3/marketing-events/events/upsert\n{\n  \"inputs\": [\n    {\n      \"customProperties\": [\n        {\n          \"name\": \"property1\",\n          \"value\": \"1234\"\n        }\n      ],\n      \"eventName\": \"Virtual cooking class\",\n      \"startDateTime\": \"2023-11-30T17:46:20.461Z\",\n      \"eventOrganizer\": \"Chef Joe\",\n      \"eventDescription\": \"Join us for a virtual cooking class! Yum.\"\n      \"eventCancelled\": false,\n      \"externalAccountId\": \"CookingCo\",\n      \"externalEventId\": \"4\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez consulter la liste complète de tous les champs disponibles que vous pouvez inclure dans votre demande en cliquant sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Points de terminaison de participation à l'événement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les points de terminaison de participation aux événements vous permettent d'enregistrer un statut d'abonnement entre un contact HubSpot et un événement marketing. Par exemple, vous pouvez utiliser ce point de terminaison pour enregistrer l'inscription d'un contact HubSpot à un événement marketing."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il existe deux points de terminaison que vous pouvez utiliser pour mettre à jour le statut de participation d'un contact. Si vous utilisiez précédemment les points de terminaison", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/upsert"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/email-upsert"
      }), " pour mettre à jour le statut d'un participant, vous pouvez plutôt utiliser les points de terminaison répertoriés ci-dessous pour maintenir la même fonctionnalité tout en renseignant la durée de présence du contact sur sa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/customize-activities-on-a-contact-company-deal-ticket-record-timeline",
        children: "chronologie"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous souhaitez utiliser les identifiants des contacts existants :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Faites une demande ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/create"
            }), ", en utilisant l'ID de l'événement de votre application externe pour ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Dans le corps de la demande, fournissez un objet ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), " qui comprend les champs suivants :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), " : date et heure auxquelles le contact s'est inscrit à l'événement."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), " : l'ID d'un contact existant."]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous souhaitez utiliser l'adresse e-mail d'un des participants à l'événement :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Faites une demande ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/email-create"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Dans le corps de la demande, fournissez un objet ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), " qui comprend les champs suivants :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), " : date et heure auxquelles le contact s'est inscrit à l'événement."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "e-mail"
                }), " : adresse e-mail du participant en tant que valeur du champ d'adresse e-mail dans une entrée."]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si l'adresse e-mail que vous incluez ne correspond pas à l'adresse d'un contact existant, un nouveau contact sera créé."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour les deux points de terminaison ci-dessus, fournissez les valeurs suivantes pour les paramètres de chemin correspondants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), " : l'ID de l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events#view-edit-and-analyze-marketing-events",
          children: "événement marketing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriberState"
        }), " : une énumération qui correspond au nouveau statut de participation du contact :"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "REGISTERED"
        }), " : indique que le contact HubSpot s'est inscrit à l'événement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "ATTENDED"
        }), " : indique que le contact HubSpot a assisté à l'événement. Si vous mettez à jour le statut d'un contact vers ATTENDED, vous pouvez également inclure les horodatages ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "joinedAt"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "leftAt"
        }), " comme paramètres dans le corps de la demande, spécifié dans le format ISO8601 Instant."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELLED"
        }), " : indique que le contact HubSpot qui s'était préalablement inscrit à l'événement a annulé son inscription."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois votre événement terminé, vous pouvez le marquer comme terminé et calculer les indicateurs de présence (par exemple, la durée de présence pour tous les participants) en faisant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " vers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/{externalEventId}/complete"
      }), ". Notez que cette action est ", (0, _jsxRuntime.jsx)("u", {
        children: "irréversible"
      }), ", vous ne devez donc invoquer ce point de terminaison qu'une fois que tous les événements de changement d'état de présence ont déjà eu lieu."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Ces API sont idempotentes tant que l'ID du contact et la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "interactionDateTime"
        }), " dans l'événement n'ont pas changé. Cela vous permet de définir en toute sécurité l'état de l'abonné plusieurs fois sans que HubSpot ne crée d'événements en double dans les propriétés des événements marketing."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurer les paramètres d'application"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une certaine configuration est requise pour permettre aux événements marketing de se synchroniser correctement avec HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous envoyez à HubSpot un changement de statut d'abonné (par exemple, une inscription ou une annulation), HubSpot vérifiera d'abord si un événement marketing existe pour l'ID d'événement spécifié. Si ce n'est pas le cas, HubSpot appellera le point de terminaison configuré pour votre application afin de récupérer les détails de l'événement marketing, puis créera l'événement dans HubSpot et publiera le changement de statut d'abonné."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ceci est fourni pour des raisons de commodité ; cependant, il est toujours recommandé que vous créiez vous-même les événements marketing via nos méthodes CRUD, fournies dans l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article, sans vous appuyer sur cette fonctionnalité pour créer vos événements marketing dans HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Étape 1 : Créez une API dans votre application"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ce faire, HubSpot exige que chaque application qui utilise des événements marketing définisse une API pour récupérer des informations sur un événement marketing spécifique."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Exigences :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Accepte :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalAccountId"
            }), " : paramètre de requête spécifiant accountId pour le client dans l'application externe."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            }), " : paramètre de requête qui spécifie l'ID de l'application HubSpot qui demande les détails de l'événement. Il s'agira de l'ID de votre application."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), " : paramètre de chemin dans l'URL de la demande qui spécifie l'ID de l'événement dans l'application externe sur laquelle HubSpot nécessite des détails."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Renvoie :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Un objet JSON qui fournit les détails de l'événement marketing, qui comprend les champs suivants détaillés dans le tableau ci-dessous :"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom du champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Obligatoire"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description du champ"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom de l'événement marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventOrganizer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom de l'organisateur de l'événement marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Décrit le type d'événement concerné. Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBINAR"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONFERENCE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WORKSHOP"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La date et l'heure de début de l'événement marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La date et l'heure de fin de l'événement marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventDescription"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La description de l'événement marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une URL dans l'application d'événement externe vers l'événement marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventCancelled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "bool"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indique si l'événement marketing a été annulé. La valeur par défaut est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot envoie également un en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), " que vous pouvez utiliser pour vérifier que la demande provient de HubSpot. Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "signatures de demande"
      }), " pour plus de détails sur la signature et comment la valider."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Étape 2 : Fournissez à HubSpot le chemin d'URL vers votre API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant que vous avez créé l'API dans votre application qui renverra un objet fournissant les détails d'un événement marketing spécifique, vous devez fournir à HubSpot le chemin d'URL de votre API en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " vers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/{appId}/settings"
      }), ". Cela permettra à HubSpot de déterminer comment faire des demandes à votre application pour obtenir les détails d'un événement marketing."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le corps de votre demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", spécifiez votre URL à l'aide du champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), ". ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " doit répondre aux deux exigences suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Il doit contenir au moins une séquence de caractères ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "%s"
        }), ", que nous utilisons pour remplacer dans l'ID de l'événement (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), ") comme paramètre de chemin d'accès."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Il doit s'agir du chemin complet vers la ressource API, y compris le préfixe ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https ://"
        }), " et le nom de domaine (par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my.event.app"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, si vous configurez un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/%s"
      }), " et que vous devez faire une demande pour récupérer les détails d'un événement avec l'ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234-event-XYZ"
      }), ", pour l'application HubSpot avec l'ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "app-101"
      }), " et le compte avec l'ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ABC-account-789"
      }), ", HubSpot fera une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/1234-event-XYZ?appId=app-101&externalAccountId=ABC-account-789"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}