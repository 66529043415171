"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816705713;
const slug = exports.slug = 'guides/apps/authentication/intro-to-auth';
const title = exports.title = 'API de HubSpot | Métodos de autentificación en HubSpot';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Authentication methods on HubSpot';
const metaDescription = exports.metaDescription = 'Las API de HubSpot permiten dos medios de autentificación: OAuth y claves de API. Aunque la mayoría de los puntos finales admiten ambos métodos, generalmente se recomienda OAuth.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "oauth",
  "label": "OAuth",
  "parent": null
}, {
  "depth": 0,
  "id": "token-de-acceso-de-aplicaci%C3%B3n-privada",
  "label": "Token de acceso de aplicación privada",
  "parent": null
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "token-de-acceso-de-aplicaci%C3%B3n-privada"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      strong: "strong",
      a: "a",
      h1: "h1",
      h2: "h2",
      pre: "pre",
      code: "code",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " desde el 30 de noviembre de 2022, las claves de API de HubSpot están obsoletas y ya no son compatibles. El uso continuado de las claves de API de HubSpot es un riesgo de seguridad para tu cuenta y tus datos. Durante esta fase de obsolescencia, HubSpot puede desactivar tu clave en cualquier momento."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En su lugar, debes autentificarte usando un token de acceso de una aplicación privada u OAuth. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "este cambio"
        }), " y cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migrar una integración de claves de API"
        }), " para usar una aplicación privada en su lugar."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Métodos de autentificación en HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hay dos maneras de autenticar llamadas a las API de HubSpot: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth y"
      }), " tokens de acceso a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "aplicaciones privadas"
      }), ". A continuación, descubre más información sobre cada método y cómo incorporarlo a tu código de autorización."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si anteriormente usabas una clave de API para autenticarte, descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
        children: "migrar a usar un token de acceso a una aplicación privada"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " cualquier integración diseñada para el uso múltiples clientes o para ser anunciada en el Mercado de aplicaciones debe crearse como una aplicación usando el protocolo OAuth de HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para realizar una solicitud con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth"
      }), ", incluya el token de acceso de OAuth en el encabezado de autorización:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer C4d***sVq\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Token de acceso de aplicación privada"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "De forma similar a OAuth, para realizar una solicitud utilizando un token de acceso a aplicaciones privadas, incluye el token en el encabezado de autorización:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer ***-***-*********-****-****-****-************\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "Trabajando con OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "Guía de inicio de OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "Aplicaciones privadas"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}