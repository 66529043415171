"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529763;
const slug = exports.slug = 'reference/cms/hubl/operators-and-expression-tests';
const title = exports.title = "Opérateurs et tests d'expression";
const name = exports.name = 'EMEA FR Operators & Expression Tests';
const metaDescription = exports.metaDescription = "Afin d'étendre la logique et la fonctionnalité de vos modèles, HubL prend en charge plusieurs opérateurs et tests d'expression clés. Les opérateurs vous permettent d'exécuter des fonctions mathématiques, d'effectuer des comparaisons, de compliquer la logique des modèles et de modifier la restitution du balisage. En outre, cet article contient une liste complète des tests d'expression qui peuvent être utilisés dans HubL.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "op%C3%A9rateurs",
  "label": "Opérateurs",
  "parent": null
}, {
  "depth": 1,
  "id": "calcul",
  "label": "Calcul",
  "parent": "op%C3%A9rateurs"
}, {
  "depth": 1,
  "id": "comparaison",
  "label": "Comparaison",
  "parent": "op%C3%A9rateurs"
}, {
  "depth": 1,
  "id": "logique",
  "label": "Logique",
  "parent": "op%C3%A9rateurs"
}, {
  "depth": 1,
  "id": "autres-op%C3%A9rateurs-hubl",
  "label": "Autres opérateurs HubL",
  "parent": "op%C3%A9rateurs"
}, {
  "depth": 0,
  "id": "tests-d-expression",
  "label": "Tests d'expression",
  "parent": null
}, {
  "depth": 1,
  "id": "boolean",
  "label": "boolean",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "containing",
  "label": "containing",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "containingall",
  "label": "containingall",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "defined",
  "label": "defined",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "divisibleby",
  "label": "divisibleby",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "equalto",
  "label": "equalto",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "even",
  "label": "even",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "float",
  "label": "float",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "integer",
  "label": "integer",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "iterable",
  "label": "iterable",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "lower",
  "label": "lower",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "mapping",
  "label": "mapping",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "none",
  "label": "none",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "number",
  "label": "number",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "odd",
  "label": "odd",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "sameas",
  "label": "sameas",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "sequence",
  "label": "sequence",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "string",
  "label": "string",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "string_containing",
  "label": "string_containing",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "string_startingwith",
  "label": "string_startingwith",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "truthy",
  "label": "truthy",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "undefined",
  "label": "undefined",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "upper",
  "label": "upper",
  "parent": "tests-d-expression"
}, {
  "depth": 1,
  "id": "within",
  "label": "within",
  "parent": "tests-d-expression"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Opérateurs et tests d'expression"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Afin d'étendre la logique et la fonctionnalité de vos modèles, HubL prend en charge plusieurs opérateurs et tests d'expression clés. Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/fr/docs/cms/hubl/operators-and-expression-tests#operators",
        children: "opérateurs"
      }), " vous permettent d'exécuter des fonctions mathématiques, d'effectuer des comparaisons, de compliquer la logique des modèles et de modifier la restitution du balisage. En outre, cet article contient une liste complète des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/fr/docs/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "tests d'expression"
      }), " qui peuvent être utilisés dans HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Opérateurs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les opérateurs sont des symboles qui indiquent au compilateur HubL d'exécuter diverses opérations qui aboutissent à la sortie finale du balisage. La section suivante comprend une liste de tous les opérateurs HubL pris en charge."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Calcul"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les opérateurs mathématiques standard peuvent être utilisés pour calculer des valeurs dans le contexte d'un modèle."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbole"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "+"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ajoute deux objets ensemble. Vous l'utiliserez généralement pour l'addition de nombres. Si vous essayez de concaténer des chaînes de listes, utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "~"
            }), " à la place."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "-"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Soustrait un nombre d'un autre."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "/"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Divise des nombres."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "%"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Renvoie le reste de la division de nombres."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "//"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Divise deux nombres et renvoie le résultat entier tronqué. Exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&#123;%raw%&#125;&#123;&#123; 20 // 7 &#125;&#125;&#123;%endraw%&#125;"
            }), " est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "*"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Multiplie des nombres."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "**"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Élève l'opérande gauche à la puissance de l'opérande droit"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num + my_number }}<br/>  <!-- 11 + 2 = 13 -->\n{{ my_num - my_number }}<br/>  <!-- 11 - 2 = 9 -->\n{{ my_num / my_number }}<br/>  <!-- 11 / 2 = 5.5 -->\n{{ my_num % my_number }}<br/>  <!-- 11 % 2 = 1 -->\n{{ my_num // my_number }}<br/> <!-- 11 // 2 = 5 -->\n{{ my_num * my_number }}<br/>  <!-- 11 * 2 = 22 -->\n{{ my_num ** my_number }}<br/> <!-- 11 ** 2 = 121 -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "13 9 5.5 1 5 22 121\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comparaison"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les opérateurs de comparaison peuvent être utilisés pour évaluer les valeurs pour la logique de modèle. Vous pouvez consulter quelques exemples d'utilisation d'opérateurs de comparaison dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "fonctions if ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbole"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "sténographie"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "=="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Est égal à. Est défini sur true si deux objets sont égaux."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "!="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "N'est pas égal à. Est défini sur true si deux objets ne sont pas égaux."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Est supérieur à. Est défini sur true si le côté gauche est supérieur au côté droit."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Est supérieur ou égal à. Est défini sur true si le côté gauche est supérieur ou égal au côté droit."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Est inférieur à. Est défini sur true si le côté gauche est inférieur au côté droit."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Est inférieur ou égal à. Est défini sur true si le côté gauche est inférieur ou égal au côté droit."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["La version abrégée des opérateurs de comparaison est utilisable dans les filtres qui impliquent de tester une expression comme ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|selectattr()"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num == my_number }}<br/>  <!-- false -->\n{{ my_num != my_number }}<br/>  <!-- true -->\n{{ my_num > my_number }}<br/>   <!-- true -->\n{{ my_num >= my_number }}<br/>  <!-- true -->\n{{ my_num < my_number }}<br/>   <!-- false -->\n{{ my_num <= my_number }}<br/>  <!-- false -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "false true true true false false\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Logique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les opérateurs logiques vous permettent de combiner plusieurs expressions en une seule déclaration."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbole"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "and"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Renvoie true si l'opérande gauche et l'opérande droit sont vrais."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "or"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Renvoie true si l'opérande gauche ou l'opérande droit est vrai."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "not"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Invalide une déclaration et est utilisé en conjonction avec is. Consultez l'exemple ci-dessous."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "(expr)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Regroupe une expression pour l'ordre des opérations. Par exemple, (10 - 2) * variable."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "?:"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/if-statements",
              children: "opérateur ternaire"
            }), " accepte 3 arguments (expression, condition vraie, condition fausse). Évalue une expression et renvoie la condition correspondante."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Autres opérateurs HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous d'autres opérateurs HubL importants qui peuvent être utilisés pour effectuer diverses tâches."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbole"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "in"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifie si une valeur se trouve dans une séquence."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "is"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Effectue un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/fr/docs/cms/hubl/operators-and-expression-tests#expression-tests",
              children: "test d'expression"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "|"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Applique un filtre."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "~"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concatène les valeurs."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tests d'expression"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les tests d'expression sont diverses conditions booléennes qui peuvent être évaluées à l'aide d'opérateurs logiques."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "boolean"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "boolean"
      }), " vérifie si l'objet est booléen (au sens strict, pas dans sa capacité d'évaluation pour une expression véridique)."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set isActive = false %}\n\n{% if isActive is boolean %}\nisActive is a boolean\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "isActive is a boolean\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "containing"
      }), " vérifie si une variable de liste contient une valeur."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containing 2 %}\n\tSet contains 2!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containingall"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "containingall"
      }), " vérifie si une variable de liste contient toutes les valeurs d'une autre liste."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containingall [2, 3] %}\n\tSet contains 2 and 3!\n{% endif %}\n\n{% if numbers is containingall [2, 4] %}\n\tSet contains 2 and 4!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2 and 3!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "defined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "defined"
      }), " vérifie si une variable est définie dans le contexte du modèle. Bien que vous puissiez utiliser ce test d'expression, l'écriture d'une fonction if sans aucun opérateur aura pour effet de vérifier par défaut si la variable est définie ou non."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'exemple ci-dessous, le paramètre color d'un module de couleur est testé. Si le paramètre color n'avait pas de valeur, le modèle afficherait une couleur de fond noire par défaut. S'il est défini, la couleur d'arrière-plan définie par l'utilisateur sera restituée."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% color \"my_color\" color=\"#930101\", export_to_template_context=True %}\n<style>\n{% if widget_data.my_color.color is defined %}\nbody{\n    background: {{ widget_data.my_color.color }};\n}\n{% else %}\nbody{\n    background: #000;\n}\n{% endif %}\n</style>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<style>\n  body {\n    background: #930101;\n  }\n</style>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "divisibleby"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "divisibleby"
      }), " peut être utilisé pour vérifier si un objet est divisible par un autre nombre."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, ci-dessous, une boucle for est créée pour parcourir une liste de types d'animaux. Chaque type d'animal est imprimé dans une division et un style en ligne différent est appliqué toutes les 5 divisions (largeur :100 %). Ce concept pourrait être appliqué à un blog où un balisage différent est restitué pour un certain modèle d'article. Pour en savoir plus sur les boucles for et loop.index, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "consultez cet article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set animals = [\"lions\", \"tigers\", \"bears\", \"dogs\", \"sharks\"] %}\n{% for animal in animals %}\n    {% if loop.index is divisibleby 5 %}\n    <div style=\"width:100%\">{{animal}}</div>\n    {% else %}\n    <div style=\"width:25%\">{{animal}}</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%\">lions</div>\n<div style=\"width:25%\">tigers</div>\n<div style=\"width:25%\">bears</div>\n<div style=\"width:25%\">dogs</div>\n<div style=\"width:100%\">sharks</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "equalto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "equalto"
      }), " vérifie si la valeur d'une variable est égale à une constante ou à une autre variable. Vous pouvez également utiliser l'opérateur == pour effectuer le même test."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'exemple ci-dessous, la largeur des articles du blog est ajustée en fonction du nombre total d'articles dans la boucle. L'exemple de sortie suppose qu'il y a 4 articles dans le blog."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    {% if loop.length is equalto 2 %}\n        <div style=\"width:50%;\">Post content</div>\n    {% elif loop.length is equalto 3 %}\n        <div style=\"width:33.333332%;\">Post content</div>\n    {% elif loop.length is equalto 4 %}\n        <div style=\"width:25%;\">Post content</div>\n    {% else %}\n        <div style=\"width:100%;>Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "even"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "even"
      }), " vérifie si une variable numérique est un nombre pair."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessous montre une boucle simplifiée d'énumération de blog, où si l'itération actuelle de la boucle est paire, une classe even-post est attribuée à la division d'élément d'article. Autrement, une classe odd-post est attribuée."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is even %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "float"
      }), " vérifie si une variable numérique est un nombre à virgule flottante."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 1.20 %}\n{% if quantity is float %}\n  quantity is a floating point number\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is a floating-point number\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "integer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vérifie si une variable est un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "nombre entier."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 120 %}\n{% if quantity is integer %}\n  quantity is an integer\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is an integer\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "iterable"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vérifie si une variable est ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "itérable"
      }), " et peut être parcourue en boucle."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cet exemple vérifie une variable appelée « jobs » pour voir si elle peut être parcourue par itération. Comme la variable contient une liste de tâches, la fonction if est définie sur true et la boucle s'exécute. Si la variable avait contenu une seule valeur, la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://developers-web1-hubspot.sites.hubspot.com/fr/docs/hubl/if-statements",
        children: "fonction if"
      }), " aurait imprimé cette valeur avec un balisage différent à la place. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "Découvrez-en davantage sur les boucles for"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set jobs = [\"Accountant\", \"Developer\", \"Manager\", \"Marketing\", \"Support\"] %}\n\n{% if jobs is iterable %}\n<h3>Available positions</h3>\n<ul>\n{% for job in jobs %}\n    <li>{{ job }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Available position</h3>\n<div class=\"single-position\">{{ jobs }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Available positions</h3>\n<ul>\n  <li>Accountant</li>\n  <li>Developer</li>\n  <li>Manager</li>\n  <li>Marketing</li>\n  <li>Support</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "lower"
      }), " est défini sur true si une chaîne de caractères est en minuscules."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'exemple ci-dessous utilise une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://developers-web1-hubspot.sites.hubspot.com/fr/docs/hubl/if-statements",
        children: "fonction unless"
      }), " et un filtre pour vérifier qu'une chaîne de texte saisie dans un module de texte est toujours en minuscules."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Lowercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is lower %}\n{{ widget_data.my_text.value|lower }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text that should be lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "mapping"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mapping"
      }), " vérifie si un objet est un dictionnaire ou non."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessous vérifie si l'objet de contact est un dictionnaire, ce qui est le cas."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if contact is mapping %}\nThis object is a dictionary.\n{% else %}\nThis object is not a dictionary.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This object is a dictionary.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "none"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "none"
      }), " vérifie si une variable a une valeur nulle."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"user_email\" path=\"@hubspot/text\" label=\"Enter user email\", value=\"example@hubspot.com\", export_to_template_context=True %}\n{% unless widget_data.user_email.value is none %}\n{{ widget_data.user_email.value }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "example@hubspot.com\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "number"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "number"
      }), " vérifie si la valeur d'une variable est un nombre ou non."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessous vérifie si une variable est ou non une variable et, si tel est le cas, la convertit en millions."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = 40 %}\n{% if my_var is number %}\n{{ my_var * 1000000 }}\n{% else %}\nmy_var is not a number.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "40000000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "odd"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "odd"
      }), " vérifie si une variable numérique est un nombre impair."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous le même exemple que le test d'expression inverse even décrit précédemment."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is odd %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sameas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sameas"
      }), " vérifie si deux variables ont ou non la même valeur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessous définit deux variables et vérifie ensuite si elles sont identiques ou non."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set var_one = True %}\n{% set var_two = True %}\n{% if var_one is sameas var_two  %}\nThe variables values are the same.\n{% else %}\nThe variables values are different.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The variables values are the same.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sequence"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sequence"
      }), " est similaire au test ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "iterable"
      }), ", en ce sens qu'il vérifie si une variable est ou non une séquence."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessous vérifie si une variable est une séquence, puis parcourt cette séquence de genres musicaux."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set genres = [\"Pop\", \"Rock\", \"Disco\", \"Funk\", \"Folk\", \"Metal\", \"Jazz\", \"Country\", \"Hip-Hop\", \"Classical\", \"Soul\", \"Electronica\" ] %}\n{% if genres is sequence %}\n<h3>Favorite genres</h3>\n<ul>\n{% for genre in genres %}\n    <li>{{ genre }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Favorite genre:</h3>\n    <div class=\"single-genre\">{{ genres }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Pop</li>\n  <li>Rock</li>\n  <li>Disco</li>\n  <li>Funk</li>\n  <li>Folk</li>\n  <li>Metal</li>\n  <li>Jazz</li>\n  <li>Country</li>\n  <li>Hip-Hop</li>\n  <li>Classical</li>\n  <li>Soul</li>\n  <li>Electronica</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "string"
      }), " vérifie si la valeur stockée dans une variable est du texte."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessous vérifie si une variable est ou non une chaîne de caractères et, si tel est le cas, applique un filtre de titre pour modifier la capitalisation."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = \"title of section\" %}\n{% if my_var is string %}\n{{ my_var|title }}\n{% else %}\nmy_var is not a string\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Title Of Section\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_containing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce test vérifie si une chaîne est contenue dans une autre chaîne. Ce test d'expression est utilisé en conjonction avec l'opérateur is."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.domain is string_containing \".es\" %}\nMarkup that will only render on content hosted on .es domains\n{% elif content.domain is string_containing \".jp\" %}\nMarkup that will only render on content hosted on .jp domains\n{% else %}\nMarkup that will render on all other domains\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all other domains\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_startingwith"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce test d'expression vérifie si une chaîne commence par une chaîne particulière. Il est utilisé en conjonction avec l'opérateur is."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.slug is string_startingwith \"es/\" %}\nMarkup that will only render on content hosted in a /es/ subdirectory\n{% elif content.slug is string_startingwith \"jp/\" %}\nMarkup that will only render on content hosted in a /jp/ subdirectory\n{% else %}\nMarkup that will render on all subdirectories\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all subdirectories\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "truthy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "truthy"
      }), " vérifie si une expression est définie sur true."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessous utilise un module de case à cocher booléenne pour afficher un message d'alerte."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% boolean \"check_box\" label=\"Show alert\", value=True, export_to_template_context=True %}\n\n{% if widget_data.check_box.value is truthy %}\n<div class=\"alert\">Danger!</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"alert\">Danger!</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "undefined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "undefined"
      }), " vérifie si une variable est indéfinie dans le contexte du modèle. Ce test est différent de none, dans la mesure où le test undefined est défini sur true lorsque la variable est présente mais n'a pas de valeur, alors que none est défini sur true lorsque la variable a une valeur nulle."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessous vérifie l'existence de la variable my_var dans un modèle."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if my_var is undefined %}\nA variable named \"my_var\" does not exist on this template.\n{% else %}\n{{ my_var }}\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A variable named \"my_var\" does not exist on this template.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "upper"
      }), " est défini sur true lorsqu'une chaîne de caractères est entièrement en majuscules. Vous trouverez ci-dessous un exemple inverse du test d'expression lower ci-dessus."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Uppercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is upper %}\n{{ widget_data.my_text.value|upper }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "SOME TEXT THAT SHOULD BE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "within"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le test d'expression ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "within"
      }), " vérifie si une variable se trouve dans une liste."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if 2 is within numbers %}\n\t2 is in the list!\n{% endif %}\n\n{% if 4 is within numbers %}\n\t4 is in the list!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2 is in the list!\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}