"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 173637713244;
const slug = exports.slug = 'reference/cms/modules/deprecated';
const title = exports.title = 'Deprecated default modules';
const name = exports.name = 'Deprecated default modules';
const metaDescription = exports.metaDescription = "Learn more about HubSpot's deprecated web default modules.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "blog-listing",
  "label": "Blog listing",
  "parent": null
}, {
  "depth": 0,
  "id": "follow-me",
  "label": "Follow Me",
  "parent": null
}, {
  "depth": 0,
  "id": "follow-me---lp",
  "label": "Follow Me - LP",
  "parent": null
}, {
  "depth": 0,
  "id": "gallery",
  "label": "Gallery",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Deprecated default modules"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "HubSpot default web modules"
      }), " that have been deprecated. These modules can still be cloned in the design manager, and existing instances of these modules will continue to function on live pages, but they no longer appear in the page editor for content creators to use."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As of February 2024, the Blog Listing module was deprecated in favor of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#blog-posts",
        children: "Blog Posts module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_listing\" path=\"@hubspot/blog_listing\", label=\"blog_listing.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "layout"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The content layout for each blog post preview.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "basic_grid"
                }), " (default): aligns posts in a basic grid."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "single_column"
                }), ": aligns posts in a single column. with featured images on their own row above the rest of the post content."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "side_by_side"
                }), ": aligns posts in a column with featured images aligned horizontally with the post content."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When using the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "basic_grid"
            }), " layout, the number of posts per row. Can be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            }), " (default), or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alternate_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "layout"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "side_by_side"
            }), " and display_for_each_item is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "image"
            }), ", set this field to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " to align the featured image on the right and left side of the post preview, alternating. By default, this field is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "full_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "layout"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "basic_grid"
            }), " or single_column and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_for_each_item"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "image"
            }), ", set this field to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " to make the featured image the background of the post preview. By default, this field is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_for_each_list_item"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The content to include in each blog post preview. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image"
                }), ": the post's featured image."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), ": the post's title."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "author_image"
                }), ": the post author's image."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "author_name"
                }), ": the post author's name."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tags"
                }), ": the post's blog tags."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "publish_date"
                }), ": the post's publish date."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), ": the post's meta description."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button"
                }), ": the read more button that links to the blog post."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The text that displays on the read more button when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_for_each_list_item"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "button"
            }), ". By default, text is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Read more"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A field group containing default values for various text elements. Below are the default values set for each text element:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_post_summary_text"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "\"Blog post summary:\""
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "featured_image_text"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "\"Featured image:\""
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "read_full_post_text"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "\"Read full post:\""
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "picture_of_text"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "\"Picture of\""
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "author_alt_text"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "\"{{ picture_of_text }} {{ content.blog_author.display_name }}\""
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "read_full_post_aria_label"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "\"{{ read_full_post_text }} {{ content.name }}\""
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "full_featured-image_aria_label"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "\"{{ featured_image_text }} {{ content.featured_image_alt_text }}\""
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "full_blog_post_summary_text"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "\"{{ blog_post_summary_text }} {{ content.name }}\""
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "gated_content_describedby_text"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "\"This content is only available to logged in members.\""
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Follow Me"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On August 7th, 2024, the Follow Me module was deprecated in favor of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#social-follow",
        children: "Social Follow module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/follow_me\",\n  title=\"Follow me\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The h3 heading displayed at the top of the module."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default, clicked links will open in a new window. Set this to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " to open clicked links in the same browser window."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Follow Me - LP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On August 7th, 2024, the Follow Me - LP module was deprecated in favor of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#social-follow",
        children: "Social Follow module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"follow_me_lp\" path=\"@hubspot/follow_me_lp\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A field group containing details for each social network to include. Includes the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "network"
                }), ": a choice field for selecting the social network."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link"
                }), ": the link to the profile."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "network_image"
                }), ": the social network image to display."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "supporting_text"
                }), ": text to display the name of the social network, if ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "display"
                }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "icon_text"
                }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_only"
                }), "."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Display options for showing social networks. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "icon"
                }), " (default)"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "icon_text"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_only"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scale"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The size of the icon in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " value (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "25"
            }), " by default)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "spacing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Left and right padding for items in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " value (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            }), " by default)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["the alignment of the items on the page. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "left"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "center"
                }), " (default)"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "right"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "color_scheme"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Color scheme to use for icons. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "color"
                }), " (default)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "black"
                }), " (black and white)"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "grey"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "white"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Color"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Custom color to use when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "color_scheme"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom"
            }), " (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "#000000"
            }), " by default)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon_shape"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Shape of the social icons. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "circle"
                }), " (default)"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "square"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "original"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Font options for the social network text, when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon_text"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_only"
            }), ".Contains the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "size"
                }), (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "value"
                    }), ": the size number."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "units"
                    }), ": the unit of measure."]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "color"
                }), ": text color in hex value."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "styles"
                }), (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "bold"
                    }), ": a boolean that makes font bold."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "italic"
                    }), ": a boolean field that makes font italicized."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "underline"
                    }), ": a boolean field that makes font underlined."]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "font"
                }), ": the name of the font."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gallery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This module was deprecated as of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog/march-2023-rollup#deprecation-of-the-gallery-default-module",
        children: "March 2023"
      }), " in favor of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#image-grid",
        children: "Image Grid"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#image-slider-gallery",
        children: "Image Slider"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#tabbed-card",
        children: "Tabbed Card"
      }), " modules."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this also applies to the Growth theme's gallery module (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot/growth/modules/image-gallery.module"
        }), "). The same replacement modules listed above are recommended."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"gallery\" path=\"@hubspot/gallery\", label=\"gallery.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A field group containing fields for each slide. Contains the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": the image URL."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_caption"
                }), ": a boolean that sets whether to display the slide caption. Set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), " by default."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), ": the slide caption."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": the URL the slide leads to when clicked."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_in_new_tab"
                }), ": a boolean that sets whether to open the link in a new tab. Set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), " by default."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Display mode of the gallery. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), " (default)"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Number of rows in the gallery when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enables looping through the slides with next/prev when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Automatically advances to the next slide when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Amount of time in seconds between advancing to the next slide when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Show navigation buttons when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sets the height of the slides when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), ": fixed height (default)."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), ": variable height."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Slide transition styles when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": slide transition (default)."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), ": fade transition."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Position of the slide captions when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), ": always keep captions below the image (default)."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), ": superimpose captions on top of images."]
              })]
            })]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}