"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044564;
const slug = exports.slug = 'guides/cms/features';
const title = exports.title = 'CMS Hub-Funktionen';
const name = exports.name = 'EMEA DACH CMS Features';
const metaDescription = exports.metaDescription = 'Entwickler und Vermarkter können mithilfe einer Reihe leistungsstarker CMS Hub-Funktionen ihre Website erstellen und mit dem erstklassigen CRM von HubSpot unterstützen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "sandbox-f%C3%BCr-cms-entwickler",
  "label": "Sandbox für CMS-Entwickler",
  "parent": null
}, {
  "depth": 0,
  "id": "inhaltssuche",
  "label": "Inhaltssuche",
  "parent": null
}, {
  "depth": 0,
  "id": "mehrsprachig",
  "label": "Mehrsprachig",
  "parent": null
}, {
  "depth": 0,
  "id": "datei-manager",
  "label": "Datei-Manager",
  "parent": null
}, {
  "depth": 0,
  "id": "content-staging",
  "label": "Content-Staging",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb",
  "label": "HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "mitgliedschaft",
  "label": "Mitgliedschaft",
  "parent": null
}, {
  "depth": 0,
  "id": "serverlose-funktionen",
  "label": "Serverlose Funktionen",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-apis",
  "label": "HubSpot-APIs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      strong: "strong",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS Hub-Funktionen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Entwickler und Vermarkter können mithilfe einer Reihe leistungsstarker CMS Hub-Funktionen ihre Website erstellen und mit dem erstklassigen CRM von HubSpot unterstützen. Stellen Sie Ihre Website sicher in einer Staging-Umgebung bereit, während Sie mit HubDB erstellen, spezifische benutzerorientierte Inhalte bereitstellen und sicherstellen, dass Ihre Inhalte in einer Sprache präsentiert werden, mit der der Leser vertraut ist. Und das alles direkt im CMS Hub."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sandbox für CMS-Entwickler"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erstellen Sie eine kostenlose ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "CMS-Entwickler-Sandbox"
      }), ", um Zugriff auf alle HubSpot-Entwickler-Tools und -Funktionen zu erhalten. Nutzen Sie Ihre Sandbox, um zu erfahren, wie Sie großartige Websites im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.de/products/cms",
        children: "CMS Hub"
      }), " erstellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inhaltssuche"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es ist kein Drittanbieter-Tool für Suchen erforderlich. Mithilfe der nativen Suchmaschine von HubSpot können Ihre Besucher über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "integrierte Website-Suchfunktion"
      }), " von CMS Hub die gesuchten Inhalte finden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mehrsprachig"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem CMS von HubSpot können Benutzer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "mehrsprachige Varianten"
      }), " ihrer Inhalte erstellen. Dadurch können Endbenutzer Inhalte in der Sprache anzeigen, die sie am besten beherrschen. Darüber hinaus bietet HubSpot Tools, mit denen Entwickler sicherstellen können, dass dem Endbenutzer die richtige Sprache zur Verfügung steht."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Datei-Manager"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/file-manager",
        children: "Datei-Manager von HubSpot"
      }), " kann verwendet werden, um Dateien für Ihre Website zu speichern und bereitzustellen. Dateien können im Datei-Manager über die Benutzeroberfläche in der App oder über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS-CLI"
      }), " gespeichert werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Content-Staging"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "Content-Staging"
      }), " ermöglicht es Website-Erstellern, das Design und den Inhalt von Seiten zu aktualisieren, indem sie die Änderungen in einer Staging-Umgebung bereitstellen. Diese in der Staging-Umgebung bereitgestellten Änderungen können dann auf Ihrer Produktions-Website veröffentlicht werden. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Content-Staging ist eine Funktion von CMS Hub Professional."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), " ist ein relationaler Datenspeicher, ähnlich wie eine Tabellenkalkulation. Sie können Tabellen erstellen und Daten in HubDB über eine Benutzeroberfläche bearbeiten oder Sie können die HubDB-API verwenden. Sie können mithilfe der HubL-Markup-Tags von HubSpot Daten direkt in das CMS übernehmen und dynamisch Seiten basierend auf HubDB-Tabellenzeilen generieren. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubDB ist eine Funktion von CMS Hub Professional."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mitgliedschaft"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit der Funktion der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "Mitgliedschaften"
      }), " von CMS Hub Enterprise können Sie von Ihren CRM-Kontakten verlangen, dass sie zum Anzeigen bestimmter Inhalte Ihrer Website angemeldet sein müssen. Inhalte hinter Mitgliedschaftsseiten können ganz individuell an den angemeldeten Kontakt angepasst werden, und es können sogar Kontakte, Unternehmen, Deals und Produktdaten aus dem CRM gerendert werdlen.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Mitgliedschaften sind eine CMS Hub Enterprise-Funktion."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Serverlose Funktionen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverlosen Funktionen"
      }), " können Sie serverseitigen Code schreiben, der über APIs mit HubSpot und Drittanbieterdiensten interagiert. Nutzen Sie serverlose Funktionen, um Ihrer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.de/products/cms",
        children: "CMS Hub-Website"
      }), " erweiterte Funktionen hinzuzufügen. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Serverlose Funktionen sind eine CMS Hub Enterprise-Funktion."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot-APIs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "HubSpot-APIs"
      }), " bieten authentifizierten, sicheren Zugriff auf Ihre CMS- und CRM-Daten. Die APIs helfen Ihnen, schnell und einfach funktionsfähige Anwendungen oder Integrationen zu erstellen. Alle HubSpot-APIs sind um REST herum organisiert – wenn Sie bereits mit einer RESTful-API zu tun hatten, sind Ihnen viele der Konzepte vertraut."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}