"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358753;
const slug = exports.slug = 'guides/api/crm/objects/deals';
const title = exports.title = 'CRM API | 取引';
const name = exports.name = 'vNext Docs DP - 取引';
const metaDescription = exports.metaDescription = '取引レコードには、進行中の商取引に関するデータが保存されます。取引エンドポイントを使用することにより、取引データを管理したり、HubSpotと他のシステムとの間で同期したりできます。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%8F%96%E5%BC%95%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "取引を作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "プロパティー",
  "parent": "%E5%8F%96%E5%BC%95%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91",
  "label": "関連付け",
  "parent": "%E5%8F%96%E5%BC%95%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E5%8F%96%E5%BC%95%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "取引を取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%8F%96%E5%BC%95%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "取引を更新する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E5%8F%96%E5%BC%95%E3%82%92%E4%BB%96%E3%81%AE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%BE%E3%81%9F%E3%81%AF%E3%82%A2%E3%82%AF%E3%83%86%E3%82%A3%E3%83%93%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AB%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "既存の取引を他のレコードまたはアクティビティーに関連付ける",
  "parent": "%E5%8F%96%E5%BC%95%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "関連付けを削除する",
  "parent": "%E5%8F%96%E5%BC%95%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E5%8F%96%E5%BC%95%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E4%B8%8A%E3%81%A7%E3%82%A2%E3%82%AF%E3%83%86%E3%82%A3%E3%83%93%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E3%83%94%E3%83%B3%E7%95%99%E3%82%81%E3%81%99%E3%82%8B",
  "label": "取引レコード上でアクティビティーをピン留めする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%8F%96%E5%BC%95%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "取引を削除する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "取引"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotにおける取引は、コンタクトまたは会社との間で進めている商取引を表します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "パイプラインステージ"
      }), "上で成約または失注になるまでの間、トラッキングが行われます。取引エンドポイントを使用することにより、取引レコードを作成および管理したり、HubSpotと他のシステムの間で取引データを同期したりできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["オブジェクト、レコード、プロパティー、および関連付けAPIの詳細については、「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRMについて"
      }), "」のガイドをご確認ください。HubSpotのオブジェクトとレコードに関する一般的な情報については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "CRMデータベースを管理する方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "取引を作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しい取引を作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエスト内のpropertiesオブジェクトに取引データを含めます。また、associationsオブジェクトを追加して、新しい取引を既存のレコード（コンタクト、会社など）またはアクティビティー（ミーティング、メモなど）に関連付けることもできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "プロパティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["取引の詳細は、取引プロパティーに保存されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/hubspots-default-deal-properties",
        children: "HubSpotの既定の取引プロパティー"
      }), "がありますが、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "カスタムプロパティーを作成"
      }), "することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しいを作成する際には", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealname"
      }), "および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealstage"
      }), "プロパティーをリクエストに含める必要があります。また、複数のパイプラインがある場合は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pipeline"
      }), "も含めます。パイプラインが指定されていない場合は、既定のパイプラインが使用されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["利用可能な全てのプロパティーを確認するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/deals"
      }), "に送信することで、アカウントの取引プロパティーのリストを取得できます。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "プロパティーAPI"
      }), "をご参照ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注"
        }), "：APIを介して取引を作成するときには、取引ステージまたはパイプラインの内部IDを使用する必要があります。APIを介して取引を取得すると、内部IDも返されます。案件ステージまたはパイプラインの内部IDは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages#to-customize-your-deal-stages:~:text=To%20view%20the%20internal%20name%20for%20a%20deal%20stage%2C%20hover%20over%20the%20stage%20and%20click%20the%20code%20code%20icon.%20The%20internal%20name%20is%20used%20by%20integrations%20and%20the%20API.",
          children: "案件パイプライン設定"
        }), "で確認できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、新しい取引レコードを作成する場合、リクエストの内容は次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"amount\": \"1500.00\",\n    \"closedate\": \"2019-12-07T16:50:06.678Z\",\n    \"dealname\": \"New deal\",\n    \"pipeline\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hubspot_owner_id\": \"910901\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付け"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、新しい取引を作成する際には、associationsオブジェクトでその取引を", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "既存のレコード"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "アクティビティー"
      }), "に関連付けることもできます。例えば、新しい取引を既存のコンタクトと会社に関連付ける場合、リクエストの内容は次のようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"amount\": \"1500.00\",\n    \"closedate\": \"2019-12-07T16:50:06.678Z\",\n    \"dealname\": \"New deal\",\n    \"pipeline\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hubspot_owner_id\": \"910901\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 201\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 5\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 3\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のものをassociationsオブジェクトに含める必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["取引に関連付けるレコードまたはアクティビティー。一意の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "値で指定します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["取引とレコード／アクティビティーの間の関連付けのタイプ。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "および ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), "を含めます。既定の関連付けタイプIDは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "このページ"
            }), "に記載されています。または、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "関連付けAPI"
            }), "を使用して、カスタム関連付けタイプ（ラベル）の値を取得することもできます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "取引を取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "取引レコードを個別に、または一括で取得できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["個々の取引を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/{dealId}"
        }), "に送信します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["全ての取引のリストを要求するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals"
        }), "に送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらのエンドポイントに対し、リクエストのURLに次のクエリーパラメーターを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レスポンスで返されるプロパティーからなるカンマ区切りリスト。リクエスト対象の取引レコードで、指定したプロパティーに値が設定されていない場合、そのプロパティーはレスポンスに含まれません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レスポンスで返される現在および過去のプロパティーからなるカンマ区切りリスト。リクエスト対象の取引レコードで、指定したプロパティーに値が設定されていない場合、そのプロパティーはレスポンスに含まれません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["関連付けられたIDを取得する対象のオブジェクトからなるカンマ区切りリスト。存在しない関連付けを指定した場合、その関連付けはレスポンスで返されません。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "関連付けAPI"
            }), "をご参照ください。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["レコードIDまたは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "一意のカスタム識別子プロパティー"
        }), "を指定して特定の取引を一括で取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/deals/batch/read"
        }), "に送信します。このバッチエンドポイントは関連付けを取得", (0, _jsxRuntime.jsx)("u", {
          children: "できません"
        }), "。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "関連付けAPI"
        }), "を使用してバッチが関連付けを読み取る方法について説明します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、一括読み取りエンドポイントの場合は、任意指定の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "パラメーターを使用し、カスタムの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "一意の識別子プロパティー"
      }), "を指定して取引を取得することもできます。既定で、リクエストの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "値はレコードID（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "）を参照しますから、レコードIDを使って取得するときには", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "パラメーターは不要です。一意のカスタム値プロパティーを使用して取引を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "パラメーターを含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、取引レコードを一括で取得する場合のリクエストは、次のいずれかのようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"dealname\", \"dealstage\", \"pipeline\"],\n  \"inputs\": [\n    {\n      \"id\": \"7891023\"\n    },\n    {\n      \"id\": \"987654\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"dealname\", \"dealstage\", \"pipeline\"],\n  \"idProperty\": \"uniqueordernumber\",\n  \"inputs\": [\n    {\n      \"id\": \"0001111\"\n    },\n    {\n      \"id\": \"0001112\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロパティーの現在の値と過去の値を含む取引を取得する場合のリクエストは、次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"dealstage\"],\n  \"inputs\": [\n    {\n      \"id\": \"7891023\"\n    },\n    {\n      \"id\": \"987654\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "取引を更新する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["取引レコードを個別に、または一括で更新できます。既存の取引の場合、APIを介してそのレコードを更新するために使用できる一意の既定の値は、取引IDです。ただし、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "カスタムの一意識別子プロパティー"
      }), "を使用して取引を識別することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["レコードIDを基準に個々の取引を更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/{dealId}"
        }), "に送信し、更新対象のデータを含めます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["複数の取引を更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/batch/update"
        }), "に送信します。リクエストの本文に、取引の識別子と更新するプロパティーを含む配列を含めます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既存の取引を他のレコードまたはアクティビティーに関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["取引を他のCRMレコードやアクティビティーに関連付けるには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), "値を取得するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "この既定値リスト"
        }), "を参照するか、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "に送信します。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レコードを関連付ける方法について詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "関連付けAPI"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付けを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["取引とレコードまたはアクティビティーの間の関連付けを削除するには、次のURLに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを送信します：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "取引レコード上でアクティビティーをピン留めする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエストに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "フィールドを含めることで、APIを介して取引レコードでアクティビティーをピン留めできます。フィールドに、ピン留めするアクティビティーの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "を含めます。このIDは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "エンゲージメントAPI"
      }), "を介して取得できます。レコードごとに1つのアクティビティーをピン留めすることができ、そのアクティビティーはピン留めする前にすでに取引に関連付けられている必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "取引のピン留めされたアクティビティーを設定または更新するには、次のようにリクエストします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/deals/{dealId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "取引を作成し、既存のアクティビティーに関連付け、同じリクエストにアクティビティーをピン留めすることもできます。以下に例を示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/deals\n{\n  \"properties\": {\n    \"dealname\": \"New deal\",\n    \"pipelines\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 213\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "取引を削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["取引レコードを個別に、または一括で削除できます。削除した取引レコードは、HubSpot内のごみ箱に追加されます。後で、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "HubSpot内で取引を復元"
      }), "することができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["IDを基準に個々の取引を削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "取引を一括で削除する方法については、この記事の上部にある［エンドポイント］__タブをクリックしてください。"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}