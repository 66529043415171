"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35878035773;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/overview';
const title = exports.title = 'ドラッグ＆ドロップエリア';
const name = exports.name = 'APAC JAPAN (ja) | [NEW] Drag and Drop Areas_JA | 202011';
const metaDescription = exports.metaDescription = 'ドラッグ＆ドロップエリアを使うと制作担当者はモジュールの配置、レイアウトの変更、コンテンツエディター内での一部のスタイル設定ができます。開発者が定義済みレイアウトを作成します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2%E3%81%A8%E3%81%9D%E3%81%AE%E6%A7%8B%E6%88%90%E8%A6%81%E7%B4%A0",
  "label": "ドラッグ＆ドロップエリアとその構成要素",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "セクション",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2%E3%81%A8%E3%81%9D%E3%81%AE%E6%A7%8B%E6%88%90%E8%A6%81%E7%B4%A0"
}, {
  "depth": 1,
  "id": "%E5%88%97",
  "label": "列",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2%E3%81%A8%E3%81%9D%E3%81%AE%E6%A7%8B%E6%88%90%E8%A6%81%E7%B4%A0"
}, {
  "depth": 1,
  "id": "%E8%A1%8C",
  "label": "行",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2%E3%81%A8%E3%81%9D%E3%81%AE%E6%A7%8B%E6%88%90%E8%A6%81%E7%B4%A0"
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "モジュール",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2%E3%81%A8%E3%81%9D%E3%81%AE%E6%A7%8B%E6%88%90%E8%A6%81%E7%B4%A0"
}, {
  "depth": 0,
  "id": "html%E6%A7%8B%E9%80%A0%E3%81%A8%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E8%A8%AD%E5%AE%9A",
  "label": "HTML構造とスタイル設定",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%88%B6%E4%BD%9C%E6%8B%85%E5%BD%93%E8%80%85%E3%81%A8%E5%B1%9E%E6%80%A7%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E8%A8%AD%E5%AE%9A",
  "label": "制作担当者と属性スタイル設定",
  "parent": "html%E6%A7%8B%E9%80%A0%E3%81%A8%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 0,
  "id": "%E7%A7%BB%E8%A1%8C",
  "label": "移行",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%81%99%E3%82%8B%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%83%AA%E3%82%A2%E3%83%AB%E3%81%A8%E3%82%AC%E3%82%A4%E3%83%89",
  "label": "関連するチュートリアルとガイド",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      h2: "h2",
      img: "img",
      strong: "strong",
      h3: "h3",
      pre: "pre",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ドラッグ＆ドロップエリア"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このページでは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "の効果と概念について説明します。この効果は重要なポイントなので、コーディングに取り掛かる前にぜひ理解しておいてください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発の準備ができたら、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "dnd_area入門"
      }), "および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_areaリファレンス"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者がドラッグ＆ドロップエリアを使うと、制作担当者に役立つページエリアとグローバルパーシャルを提供できます。ドラッグ＆ドロップエリアを使うと制作担当者はモジュールの配置、レイアウトの変更、コンテンツエディター内での一部のスタイル設定ができます。これにより、作成するテンプレートの数を減らすことができ、制作担当者はこれを使用して目的に応じてさまざまなレイアウトのページを自分で作成できるようになります。小さなレイアウトを変更するためにコードに手を加えたり新しいテンプレートを必要としたりすることなく、制作担当者が自分で作業できます。柔軟で使いやすいので、テーマの少なくとも1つのテンプレートにドラッグ＆ドロップエリアを含めることをお勧めします。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["現時点では、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "ブログ記事"
        }), "と", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#email",
          children: "Eメールテンプレート"
        }), "でドラッグ＆ドロップエリアを使用することはできません。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)(_components.h2, {
          children: "制作担当者にとっての利便性"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "制作担当者がドラッグ＆ドロップエリアを含むテンプレートを使用してページを作成する際には、開発者がレイアウトに配置した定義済みモジュールを使用したページが最初に表示されます。これにより、制作担当者はこのテンプレートを使用する多くのページがどのような見た目になるか、あるいはどのような表示に役立つかを理解できます。これはあくまで初期状態の外観で、固定ではありません。制作担当者は、モジュール、セクション、行、列をドラッグ＆ドロップして再配置できます。これらのサイズ変更、コンテンツの編集、さまざまなビジュアルスタイル設定の調整を行うことで、コンテンツを垂直方向と水平方向にそろえたり、背景を追加したりできます。"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "制作担当者にとっては、非常に便利です。小さな調整のために開発者を煩わさずに、シンプルにページを変更できる豊富な柔軟性が得られます。"
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/drag-and-drop.gif",
            alt: "ページエディターでのdnd_areaの操作"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)(_components.h2, {
          children: "開発者にとっての利便性"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "開発者にとって、dnd_areaの作業は一般的なCSSフレームワークとそのグリッドを使用することとある程度似ています。開発者はセクションと呼ばれるコンテナーを使用してページをレイアウトします。セクションに行が含まれています。行の内部はモジュールと列です。ページエディターで制作担当者がドラッグ＆ドロップエリアでできるほとんど全ての作業は、テンプレート自体の中のコードで開発者が実行できることと近くなっています。開発者が構築するのは、テンプレートを使用するページの既定のコンテンツです。"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "テンプレートに入れるハードコードのモジュールタグと比べたドラッグ＆ドロップエリアのメリットは、制作担当者がコンテンツと内部レイアウトを変更できることです。この柔軟性があるので、ドラッグ＆ドロップエリアを使用する1つのテンプレートでさまざまなページデザインを解決できます。"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["実際のコードでの様子を紹介するために", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "を使用して", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
            children: "CMS Theme Boilerplateテンプレート"
          }), "を作成しました。"]
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/dnd%20coded.png",
            alt: "VS Codeでのdnd_areaコーディング"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ドラッグ＆ドロップエリアとその構成要素"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップエリアはわずかな構成要素で作成されており、それらがレイアウトを定義し、スタイル調整を柔軟にしています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "タグはページエディターが認識するページの領域を作成し、ドラッグ＆ドロップ要素の追加、削除、再配置を可能にします。これらはHTML+HubLテンプレート内のHubLを使用して定義します。ドラッグ＆ドロップ要素は全てドラッグ＆ドロップエリアの中にあります。ドラッグ＆ドロップエリアをネストすることはできず、ドラッグ＆ドロップ要素にドラッグ＆ドロップエリア自体を含めることはできません。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者が理解しておくべき重要な点は、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "テンプレートで定義したドラッグ＆ドロップエリアのコンテンツがそのテンプレートを使用するページの初期状態になる点です"
      }), "。制作担当者は、ドラッグ＆ドロップエリア内のコンテンツを完全に変更できます。開発者の役割は、コンテンツ作成者が変更を始めるための最適な初期状態を提供することです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Example%20structure.png",
        alt: "構造の例"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この図は、さまざまなドラッグ＆ドロップ要素をどのようにネストできるかを示しています。理解しておくべき重要な仕組みは、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "セクションと行の両方に列とモジュールを含めることができる"
      }), "点です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Drag%20and%20Drop%20relationships.png",
        alt: "ドラッグ＆ドロップ要素の関係"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "セクション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["セクションは特殊なタイプの行です。セクションは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-section-code-dnd-section-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        })
      }), "タグを使用してテンプレート内に作成します。これはドラッグ＆ドロップエリアの直接の子にすることができる唯一のドラッグ＆ドロップ要素です。セクションは外側のラッピングコンテナーと考えることができます。コンテンツは全幅に広げることも、中央で最大幅制限を持たせることもできます。セクションは列とモジュールを囲むので、コンテンツの大部分を簡単に再配置して実装できます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "タグはHTML", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), "要素をレンダリングしません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Section%20in%20page%20editor.png",
        alt: "ページエディターでのセクション"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページエディターでのセクションの表示。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者は、ページエディター内に再利用可能セクションを作成可能な", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-templates",
        children: "セクションテンプレート"
      }), "を提供できます。このように開発者によって定義された事前設定済みのセクションを、制作担当者は出発点として使用できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#reusable-sections",
        children: "再利用可能なセクション"
      }), "には固有の機能があり、例えば、標準のHubLパーシャルテンプレートのように使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "列"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["列は行とモジュールのラッパーです。列は、行の内部、または特殊な行であるセクションに配置します。列は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-column-code-dnd-column-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "タグを使用してテンプレート内に作成します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "行内の複数の列を使用して、水平方向に行とモジュールを配置します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "列は行を含めることができる垂直方向の領域です。幅を変更することで、異なるサイズの列を作成できます。行のサイズが12「列」幅だという場合、これはCSSグリッドを指します。行内の列は12以下ならどんなサイズにもできますが、12を超えて追加することはできません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "複数行を列に配置すると、それらの行内のモジュールは垂直方向に積み重なって表示されます。モジュールは列自体であるため、モジュールを列の直接の子にすることはできません。モジュールは行内に含める必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20column%20in%20page%20editor.png",
        alt: "ページエディターのdnd_area列"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページエディターでの列の表示。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "行"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["行は列のラッパーです。行は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-row-code-dnd-row-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        })
      }), "タグを使用してテンプレート内に作成します。モジュールは、行内に直接配置できる列です。これにより、モジュールは水平方向に隣り合って表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールを行内に配置することにより、垂直方向に整理できます。モジュールを他のモジュールの上に配置する場合は、そのモジュールを行内に配置します。次に、最初の行の上または下の行内に別のモジュールを追加します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20row%20in%20page%20editor.png",
        alt: "ページエディターのdnd_area行"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページエディターでの行の表示。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "はCMS Hubの基本パーツであり、サイトにまとめてコンテンツを表示するために使用する再利用可能な構築要素として機能します。テンプレートを作成するとき、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-module-code-dnd-module-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "タグを使用してドラッグ＆ドロップ行内およびセクション内にモジュールを配置します。モジュールは列でもあります。つまり、2つのモジュールタグを配置するか、モジュールと列を直接隣に配置すると、それらは水平方向に並べて表示されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップ要素をモジュール内に配置することはできません。モジュールを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "の直接の子にすることはできません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTML構造とスタイル設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップエリアとその要素はレンダリングされるとき、ブートストラップ2に基づいて12列グリッドのクラス名を持ちます。簡単に実行できるようにするため、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/",
        children: "CMS Theme Boilerplate"
      }), "の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "_layout.css"
      }), "ファイルを使用できます。これはそのクラス名の既定のスタイルを提供します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このスタイルシートを使用する必要はなく、代わりに独自のスタイルを提供できます。CMS Theme Boilerplateに基づいてサイトを構築していて独自のCSSを使用する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/5abaf2a4c45a95dbed1d459f7f0f6407350752ac/src/templates/layouts/base.html#L8",
        children: "base.htmlで呼び出されないようにlayout.cssを削除"
      }), "するとよいでしょう。独自のCSSグリッドを使用するには同じグリッドクラス名をターゲットにする必要がありますが、スタイルは自由に設定できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップエリアは、レンダリングされるときにページエディターで使用されるクラスを使用するdivを作成します。例として", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-span"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-type-cell"
      }), "があります。これらのクラスはページエディターで使用されるので直接ターゲットにするべきではありませんが、後で変更することはできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["代わりに、使用するクラス名を使うクラスパラメーターを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "にHubLが追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"container-fluid my-custom-class\">\n  <div class=\"row-fluid-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n      <!--end widget-span -->\n    </div>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "制作担当者と属性スタイル設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ドラッグ＆ドロップエリアで制作担当者はページのスタイルにエフェクトを付けることができます。例えば、セクションに背景を設定できます。開発者は、属性によって設定の既定値を渡すことができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページが実際にレンダリングされるとき、この設定に基づいて生成されるスタイルが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "に追加されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), "のリリース時点では、これらのスタイルは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        }), "から読み込まれていました。これは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        }), "に", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/drag-and-drop-area-styles-moved-from-footer-to-header",
          children: "最近変更され"
        }), "、CMS Hubを伴う全てのHubSpotアカウントに提供されていきます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "移行"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フレキシブルカラムで構築されたテンプレートを変更してドラッグ＆ドロップエリアを使用する場合は、理解しておく点があります。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "フレキシブルカラムはドラッグ＆ドロップエリアと同じではありません。フレキシブルカラムのみを持つテンプレートからドラッグ＆ドロップエリアのみを持つテンプレートに交換することはできません"
      }), "。安全上、この交換は認められません。コンテンツはフレキシブルカラムからドラッグ＆ドロップエリアにマッピングされません。この理由を考えてみましょう。新しいテンプレートを作成してサイドバーとメインのコンテンツエリアを設定したとします。サイドバーはフレキシブルカラムで、メインコンテンツはドラッグ＆ドロップエリアです。交換ツールはフレキシブルカラム同士をマッピングしようとします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連するチュートリアルとガイド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites#drag-and-drop-areas",
          children: "ドラッグ＆ドロップエリアを既存のウェブサイトに追加する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "ドロップエリアの作成"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}