'use es6';

import { createSelector } from 'reselect';
import RequestStatus from '../../lib/models/RequestStatus';
import { AuthTypes } from './constants';
const getState = state => state.tryIt;
const getResponseStateForDoc = (state, ownProps) => state.tryIt.getIn(['responses', ownProps.docId]);
const getSecurityTypesForEndpoint = (_, ownProps) => ownProps.securityTypes;
export const getAuthType = createSelector([getState, getSecurityTypesForEndpoint], (state, securityTypes) => {
  const authType = state.get('authType');
  if (authType && securityTypes.includes(authType)) {
    return authType;
  } else if (securityTypes.length > 0 && securityTypes.includes('OAUTH_TOKEN')) {
    // Default to OAUTH if it exists
    return AuthTypes.OAUTH_TOKEN;
  } else if (securityTypes.length > 0) {
    return securityTypes[0];
  }
  return AuthTypes.NONE;
});
export const getApiKey = createSelector([getState], state => state.get('apiKey'));
export const getOAuthToken = createSelector([getState], state => state.get('oAuthToken'));
export const getAuth = createSelector([getState], state => state.get('auth'));
export const getResponseStatus = createSelector([getResponseStateForDoc], respState => respState ? respState.get('status') : RequestStatus.uninitialized);
export const getResponse = createSelector([getResponseStateForDoc], respState => respState && respState.get('response'));