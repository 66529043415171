"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 30866940858;
const slug = exports.slug = 'guides/api/conversations/visitor-identification';
const title = exports.title = 'Visitor Identification';
const name = exports.name = 'Visitor Identification';
const metaDescription = exports.metaDescription = 'The visitor identification API is used to identify visitors to your site that were authenticated using external authentication systems.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "example-integration-flow",
  "label": "Example integration flow",
  "parent": null
}, {
  "depth": 0,
  "id": "verify-the-integration",
  "label": "Verify the integration",
  "parent": null
}, {
  "depth": 0,
  "id": "chat-widget-sdk-primer",
  "label": "Chat widget SDK primer",
  "parent": null
}, {
  "depth": 1,
  "id": "sdk-reference",
  "label": "SDK reference",
  "parent": "chat-widget-sdk-primer"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      h2: "h2",
      a: "a",
      img: "img",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visitor identification"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise', 'sales_hub-professional', 'sales_hub-enterprise', 'cms-professional', 'cms-enterprise', 'service_hub-professional', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use the visitor identification API to identify visitors to your site that were authenticated using your own external authentication system."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "An identification token returned from this API can be used to pass information about your already-authenticated visitor to the chat widget, so that it treats the visitor as a known contact. Agents in the inbox can then have confidence about who they are talking to, and visitors can access previous thread history across devices. For example:"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The visitor identification API is for telling HubSpot who the visitor is. You should not rely on this to authenticate users in your platform."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Access to the visitor identification API requires a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), " level subscription. If the account does not have a qualifying subscription, you will receive a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "403"
          }), " error response from the API."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example integration flow"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To integrate with this feature, you must have an existing web application with an authentication system. Before getting started, make sure you have a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private app"
      }), "set up and the account that you are trying to integrate has a qualifying ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Professional"
      }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " subscription."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Here’s an example of a possible integration flow:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hubfs/Possible%20User%20Identification%20Flow.png",
        alt: "Possible User Identification Flow"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once your customer is logged in and verified in your system, take the following steps to identify them within live chat:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "1."
      }), " On your front end, set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loadImmediately"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " object on the window. If you do not do this, the chat widget may load before the identification information is passed through. See the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#chat-widget-sdk-primer",
        children: "Chat Widget SDK primer below"
      }), " for more information."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Set the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), " properties outside the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isConversationsAPIReady"
        }), " function."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In addition, the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsConversationsSettings"
        }), " needs to be set prior to the call, otherwise you may experience a race condition that interferes with widget load."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "2."
      }), " Generate a token from the Visitor Identification API by passing in the email address of your authenticated visitor. This should be done on the back end of your web application. Check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/conversations/visitor-identification",
        children: "endpoints reference documentation"
      }), " for an example request."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubspot.com/conversations/v3/visitor-identification/tokens/create \\\n--data '{\n  \"email\": \"gob@bluth.com\",\n  \"firstName\": \"Gob\",\n  \"lastName\": \"Bluth\"\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The provided first and last name will be set on the contact record in HubSpot after the chat begins if:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "It's a new contact created by the Visitor Identification API."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "It's an existing contact where the name is not already known."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "This can be useful when personalizing messages to identified visitors when your external system already has name information, but it does not yet exist in HubSpot. These are optional parameters and not required."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "3."
      }), " Using the token Step 2, set the following properties on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " object on the window."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "4."
      }), " Load the widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The token and email must be set on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " object on the window every time the page loads for an authenticated visitor. This context will not be carried across page loads automatically if these parameters are no longer set. Tokens are temporary and will expire after 12 hours. Tokens can be cached to avoid re-fetching the token on every page load, as long as they are refreshed at least every 12 hours."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verify the integration"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've completed your integration of the Visitor Identification feature, you can verify that it’s working as expected. This can be done in a couple ways, depending on your implementation, so you may need to tailor the examples below to your specific requirements."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "If you've added the chat widget to one or more public pages as well as behind an authentication system:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "Navigate to a page where the chat widget should not be identifying visitors and start a conversation."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In HubSpot, open the inbox and verify that the chat that just came in belongs to an ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Unknown Visitor"
              }), ". If this is not the case, try following these steps in a private browsing window:"]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "Navigate to a page where the chat widget should be identifying visitors via the Visitor Identification API and start a conversation."
                }), "\n"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: "In HubSpot, open the inbox and verify that the chat is correctly attributed to the contact that you’re logged in as. You should see a badge next to the contact’s name, indicating that this contact was successfully identified through this API."
                }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
                  children: (0, _jsxRuntime.jsx)(_components.img, {
                    src: "https://f.hubspotusercontent00.net/hubfs/53/visitor_identity_badge.png",
                    alt: "visitor_identity_badge"
                  })
                }), "\n"]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "If you've only added the chat widget to pages behind an authentication system, and you have access to multiple test user accounts:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Log in to HubSpot as the first test user, then navigate to a page where the chat widget loads, and start a conversation."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Log out of HubSpot, then log back in as the second test user. Navigate to a page where the chat widget loads, and start a conversation."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "In HubSpot, open the inbox and verify that the chats that came in were from the first and second test accounts, respectively, and that you see the badge next to the contact names for both records."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " for visitors identified with this API, HubSpot will not drop the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), " cookie. HubSpot will also skip any email capture questions since email address is already known. Because the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        }), " cookie and email capture do not apply to these chats, the associated settings in the chatflow will not show for visitors identified through the Visitor Identification API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Chat widget SDK primer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The API is housed in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), " object. All available methods can be accessed via this object. The HubSpot script loader on your page will create this object for you, but it may not be available immediately. To defer accessing the API until it's initialized, you may use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " helper. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "SDK reference"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "array"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This is an optional field you can define on the window object that enables you to specify code to be executed as soon as the widget becomes available. Once the API has been initialized, it will check for the existence of this array and execute its functions in series."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "if (window.HubSpotConversations) {\n  console.log('The api is ready already');\n} else {\n  window.hsConversationsOnReady = [\n    () => {\n      console.log('Now the api is ready');\n    },\n  ];\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "object"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This object enables you to provide some configuration options to the widget before it initializes. In order to use the Visitor Identification feature, you must set the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the widget should implicitly load or wait until the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            }), " method is called"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationToken"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used to integrate with the Visitor Identification API. This is the token provided by the token generation endpoint on the Visitor Identification API that is used as proof that this visitor has been identified."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "identificationEmail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email address of the visitor that you’ve identified as loading the widget."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  identificationEmail: 'visitor-email@example.com',\n  identificationToken: '<TOKEN FROM STEP 1>',\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/conversations/chat-widget-sdk",
        children: "conversations SDK"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}