"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77330733884;
const slug = exports.slug = 'reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration';
const title = exports.title = 'Migrate an existing Account Extension integration';
const name = exports.name = 'Migrate an existing Account Extension integration';
const metaDescription = exports.metaDescription = 'Learn how to migrate an existing Account Extension integration. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "migrate-contact-and-product-sync",
  "label": "Migrate contact and product sync",
  "parent": null
}, {
  "depth": 0,
  "id": "migrate-the-deal-record-invoice-card",
  "label": "Migrate the deal record invoice card",
  "parent": null
}, {
  "depth": 1,
  "id": "display-invoice-data-on-a-crm-card",
  "label": "Display invoice data on a CRM card",
  "parent": "migrate-the-deal-record-invoice-card"
}, {
  "depth": 1,
  "id": "other-crm-card-actions",
  "label": "Other CRM card actions",
  "parent": "migrate-the-deal-record-invoice-card"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      strong: "strong",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Migrate an existing Accounting Extension API integration"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
        children: "API keys will be sunsetted"
      }), " on November 30th, 2022. If your Accounting Extension integration uses API keys, you will need to update it to a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
        children: "private app"
      }), ", then implement the following API changes below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrate contact and product sync"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Learn about the different APIs you can use for the contact and product sync. The respective API documentation includes details on relevant scope requirements."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For contact sync, you can instead use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "Contacts API"
        }), ". Specifically, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For product sync, you can instead use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/products",
          children: "Products API"
        }), ". Specifically, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/products"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The above APIs do not automatically retry if they return errors. If your integration experiences errors interacting with the above APIs, your system will need to retry API calls."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when using the Products API, deduplication will not happen automatically. When you create products using this API, you should record the ID so you know when to update existing products instead of creating new ones."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrate the deal record invoice card"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To display invoice data on a deal record, use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "CRM Cards API"
      }), " to create an invoice card."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Display invoice data on a CRM card"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To display invoice data on a CRM card:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Implement a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "data fetch request"
        }), " detailed in the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Webhooks"
        }), " section of the documentation."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "action hook action"
        }), " for HubSpot to retrieve invoice data from your system depending on which deal record a customer is viewing."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "With the CRM Cards API endpoints, you can customize which invoice data is displayed on each card, as well as actions customers can perform."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Your system will need to keep a record of what invoices to display depending on ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associatedObjectId"
        }), ", which is the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ObjectId"
        }), " of the current deal a customer is viewing."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Other CRM card actions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also implement actions, which allow customers to perform operations on the data displayed on the CRM card:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For example, you can choose where a customer can modify or delete a specific invoice in the CRM card by returning an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "action"
        }), " array from your implemented action hook API."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you want customers to be able to create net new invoices via your CRM card, you can achieve this by configuring and returning a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primaryAction"
        }), " attribute in the response from your built action hook API."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You can also implement a \"View invoice PDF\" action using an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IFRAME"
        }), " action type as the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondaryAction"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}