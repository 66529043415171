"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358768;
const slug = exports.slug = 'guides/api/cms/hubdb';
const title = exports.title = 'CMS-API | HubDB';
const name = exports.name = 'CMS-API | HubDB';
const metaDescription = exports.metaDescription = 'Die HubDB-Endpunkte werden verwendet, um Daten in Ihren HubDB-Datentabellen abzurufen und zu verwalten.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "ratenbeschr%C3%A4nkungen",
  "label": "Ratenbeschränkungen",
  "parent": null
}, {
  "depth": 0,
  "id": "entwurfs--vs.-live-tabellen",
  "label": "Entwurfs- vs. Live-Tabellen",
  "parent": null
}, {
  "depth": 0,
  "id": "eine-hubdb-tabelle-erstellen",
  "label": "Eine HubDB-Tabelle erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "tabellenzeilen-hinzuf%C3%BCgen",
  "label": "Tabellenzeilen hinzufügen",
  "parent": "eine-hubdb-tabelle-erstellen"
}, {
  "depth": 1,
  "id": "tabellenzeilen-hinzuf%C3%BCgen",
  "label": "Tabellenzeilen hinzufügen",
  "parent": "eine-hubdb-tabelle-erstellen"
}, {
  "depth": 1,
  "id": "zeilen-aus-csv-importieren",
  "label": "Zeilen aus CSV importieren",
  "parent": "eine-hubdb-tabelle-erstellen"
}, {
  "depth": 1,
  "id": "datumsformatierung",
  "label": "Datumsformatierung",
  "parent": "eine-hubdb-tabelle-erstellen"
}, {
  "depth": 1,
  "id": "optionen-zum-zur%C3%BCcksetzen",
  "label": "Optionen zum Zurücksetzen",
  "parent": "eine-hubdb-tabelle-erstellen"
}, {
  "depth": 0,
  "id": "hubdb-daten-abrufen",
  "label": "HubDB-Daten abrufen",
  "parent": null
}, {
  "depth": 1,
  "id": "zur%C3%BCckgegebene-zeilen-filtern",
  "label": "Zurückgegebene Zeilen filtern",
  "parent": "hubdb-daten-abrufen"
}, {
  "depth": 1,
  "id": "zur%C3%BCckgegebene-zeilen-sortieren",
  "label": "Zurückgegebene Zeilen sortieren",
  "parent": "hubdb-daten-abrufen"
}, {
  "depth": 0,
  "id": "konfigurieren-von-hubdb-tabellen-f%C3%BCr-dynamische-seiten",
  "label": "Konfigurieren von HubDB-Tabellen für dynamische Seiten",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A4nderungen-in-v3",
  "label": "Änderungen in v3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      em: "em",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS-API | HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDB ist ein relationaler Datenspeicher, der Daten als Zeilen, Spalten und Zellen in einer Tabelle darstellt, ähnlich wie in einer Kalkulationstabelle. HubDB Tabellen können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview#creating-your-first-table",
        children: "in Ihrem HubSpot-Account"
      }), " hinzugefügt oder geändert werden, aber Sie können auch die hier dokumentierten API-Endpunkte verwenden. Informationen zur Verwendung von Daten aus HubDB-Tabellen auf Ihrer Website oder in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "E-Mails mit programmierbaren Inhalten"
      }), " finden Sie in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "der CMS-Entwicklerdokumentation von HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ähnlich wie bei HubSpot-Website-Seiten unterstützen HubDB Tabellen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "draft"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "published"
      }), "-Versionen. Auf diese Weise können Sie Daten in der Tabelle aktualisieren, entweder zu Testzwecken oder für einen manuellen Genehmigungsprozess, ohne dass sich dies auf Live-Seiten auswirkt. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#draft-vs-live-tables",
        children: "Entwurfs- und Live-Tabellen."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn für eine Tabelle der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "öffentliche Zugriff zugelassen"
      }), " werden soll, können Sie ohne Authentifizierung auf die veröffentlichten Version der Tabelle und der Zeilen zugreifen, indem Sie Ihre HubSpot-Account-ID über den Abfrageparameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), " angeben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie von v2 der HubDB-API migrieren, erfahren Sie hier mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#changes-in-v3",
        children: "Änderungen in der aktuellen (v3)-API."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Endpunkte, die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " unterstützen , unterstützen auch ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), ", sodass Sie clientseitig mit JavaScript und Ihrer Account-ID auf Daten in einer Tabelle zugreifen können. Andere Methoden und der Endpunkt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Alle Tabellen abrufen"
        }), " erfordern eine Authentifizierung und unterstützen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), "nicht."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ratenbeschränkungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB-API-Anfragen unterliegen je nach Art der Anfrage unterschiedlichen Beschränkungen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Alle durchgeführten ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfragen, die keine Authentifizierung erfordern (einschließlich clientseitiger JavaScript-Anfragen), sind auf 10 Anfragen pro Sekunde beschränkt. Diese Anfragen werden nicht auf das tägliche Limit angerechnet."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Für alle anderen Anfragen, die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "Authentifizierung verwenden"
        }), ", gelten die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api",
          children: "Standardbeschränkungen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Entwurfs- vs. Live-Tabellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB-Tabellen verfügen sowohl über eine Entwurfs- als auch über eine Live-Version, und Live-Versionen können veröffentlicht oder ihre Veröffentlichung rückgängig gemacht werden. Auf diese Weise können Sie die Daten in der Tabelle aktualisieren, entweder für Seitenvorschauen oder Tests oder für einen manuellen Genehmigungsprozess, ohne dass sich dies auf Live-Seiten auswirkt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In dieser API sind separate Endpunkte für die Entwurfs- und die veröffentlichte Version einer Tabelle vorgesehen. Sie können beispielsweise die veröffentlichte Version einer Tabelle abrufen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an den folgenden Endpunkt durchführen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um alle Inhalte abzurufen, die bereits entworfen, aber noch nicht veröffentlicht wurden, fügen Sie am Ende der URL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/draft"
      }), " hinzu:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Entwurfsdaten können überprüft und dann in HubSpot oder mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/push-live"
      }), "-Endpunkt übertragen werden. Die Entwurfsdaten können auch über den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/reset"
      }), "-Endpunkt verworfen werden, sodass Sie ohne Unterbrechung zur aktuellen Live-Version der Daten zurückkehren können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine HubDB-Tabelle erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine HubDB-Tabelle zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Geben Sie im Anfragetext die folgenden Pflichtfelder an:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiel"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der interne Name der Tabelle. Dieser Name kann nicht mehr geändert werden, nachdem die Tabelle erstellt wurde. Namen dürfen nur Kleinbuchstaben, Ziffern und Unterstriche enthalten und dürfen nicht mit einer Zahl beginnen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"my_table\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Label der Tabelle, das Benutzer beim Bearbeiten der Tabelle in HubSpot sehen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\":\"My table\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Darüber hinaus können Sie die folgenden optionalen Felder angeben:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiel"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt an, ob die Tabelle zum ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#configuring-hubdb-tables-for-dynamic-pages",
              children: "Erstellen von dynamischen Seiten"
            }), " verwendet werden kann"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"useForPages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowPublicAPIAccess"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt an, ob die Tabelle ohne Autorisierung gelesen werden kann."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowPublicApiAccess\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowChildTables"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt an, ob untergeordnete Tabellen für die Tabelle erstellt werden können."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowChildTables\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableChildTablePages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt an, ob ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "dynamische Seiten mit mehreren Ebenen"
            }), " mithilfe von untergeordneten Tabellen erstellt werden sollen."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"enableChildTablePages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Spalten der Tabelle. Erfahren Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-table-columns",
              children: "Tabellenspalten hinzufügen"
            }), " mehr über die Eigenschaften von Spalten."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "See \"Add table columns\" section below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ohne dass noch Spalten hinzugefügt wurden, könnte Ihre Erstellungsanfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"name\": \"test_table\",\n  \"label\": \"Test Table\",\n  \"useForPages\": false,\n  \"allowPublicApiAccess\": false,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"columns\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tabellenzeilen hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Jede Spalte in einer HubDB-Tabelle kann mit den folgenden Eigenschaften definiert werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiel"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erforderlich. Der interne Name der Spalte. Kann nach dem Erstellen der Spalte nicht mehr geändert werden."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"row_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Optional. Das Label für die Spalte, das Benutzern beim Bearbeiten der Tabelle in HubSpot angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\": \"Row label\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Datentyp der Spalte. Muss einer der folgenden sein:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TEXT"
                }), ": ein Textfeld."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "RICHTEXT"
                }), ": ein Textfeld, das die grundlegende HTML-Formatierung unterstützt. Nicht empfohlen für unformatiertes HTML, da es Auswirkungen darauf haben kann, ob das HTML in HubSpot bearbeitet werden kann. Die Bearbeitung des Codes in HubSpot kann sich auch auf die Art und Weise auswirken, wie der Code gerendert wird. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NUMBER"
                }), ": ein Zahlenfeld."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOOLEAN"
                }), ": wird in HubSpot als Kontrollkästchen dargestellt. Verwendet ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "0"
                }), " für deaktiviert und ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                }), " für aktiviert."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATE"
                }), ": speichert ein bestimmtes Datum als Millisekunden-Zeitstempel, der auf Mitternacht UTC festgelegt ist."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATETIME"
                }), ": speichert ein Datum und eine Uhrzeit als Millisekunden-Zeitstempel."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "SELECT"
                }), ": Die Spalte kann nur auf eine aus einer Reihe von Optionen festgelegt werden. Siehe das ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), "-Feld unten für erforderliche Eigenschaften."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MULTISELECT"
                }), ": Die Spalte kann auf eine oder mehrere Optionen aus einer Reihe von Optionen festgelegt werden. Siehe das ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), "-Feld unten für erforderliche Eigenschaften."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "LOCATION"
                }), ": speichert einen Breiten- und Längengrad."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "IMAGE"
                }), ": speichert die URL eines Bildes."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "VIDEO"
                }), ": speichert die Player-ID des Videos."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FOREIGN_ID"
                }), ": Die Spalte verweist auf eine Spalte aus einer anderen HubDB-Tabelle. Darüber hinaus müssen Sie die andere HubDB-Tabelle mit den folgenden Eigenschaften definieren:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: "foreignTableId: die ID der anderen HubDB-Tabelle. "
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: "foreignColumnId: die ID der Spalte in der anderen HubDB-Tabelle."
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CURRENCY"
                }), ": speichert die Zahl als Währungswert."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), ": speichert eine Datei aus dem Datei-Manager. Sie müssen auch ein ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "fileType"
                }), "-Feld berücksichtigen, um anzugeben, ob das Feld alle Dateitypen (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), ") oder nur Dokumenttypen wie PDF (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DOCUMENT"
                }), ") speichern kann."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"type\": \"type\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Liste von Optionen für Auswahl- und Mehrfachauswahlspalten. Jede Option wird mit einem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " zusammen mit einem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), " definiert."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option\": [{\"name\":\"Option 1\", \"type\":\"option\"}, {\"name\": \"Option 2\", \"type\": \"option\"}]"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit den obigen Feldern könnte Ihre Anfrage zum Erstellen einer neuen HubDB-Tabelle wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"label\": \"Test Table\",\n  \"name\": \"test_table\",\n  \"columns\": [\n    {\n      \"name\": \"text_column\",\n      \"label\": \"Text Column\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"number_column\",\n      \"label\": \"Number Column\",\n      \"archived\": false,\n      \"type\": \"NUMBER\"\n    },\n    {\n      \"name\": \"multiselect\",\n      \"label\": \"Multi Select Column\",\n      \"archived\": false,\n      \"type\": \"multiselect\",\n      \"options\": [\n        {\n          \"name\": \"Option 1\",\n          \"type\": \"option\"\n        },\n        {\n          \"name\": \"Option 2\",\n          \"type\": \"option\"\n        }\n      ]\n    }\n  ],\n  \"useForPages\": true,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"allowPublicApiAccess\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nach dem Erstellen einer Tabelle werden den Spalten die IDs in aufsteigender Reihenfolge zugewiesen. Nehmen Sie beim Aktualisieren vorhandener Spalten das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "-Feld der Spalte in das Eingabeobjekt auf."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tabellenzeilen hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Zeilen entweder manuell über die API hinzufügen oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#import-rows-from-csv",
        children: "Zeilen aus einer CSV-Datei importieren"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einer HubDB-Tabelle Zeilen hinzuzufügen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/rows"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für jede Tabellenzeile können Sie die folgenden Felder berücksichtigen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiel"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Liste von Schlüssel-Wert-Paaren mit dem Spaltennamen und dem Wert, den Sie der Spalte hinzufügen möchten.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "Wenn Sie keinen bestimmten Wert für eine Spalte festlegen möchten, können Sie den Spaltennamen in der Liste der Schlüssel-Wert-Paare weglassen."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"values\": { \"text_column\": \"sample text\", \"number_column\": 76}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Für Tabellen, die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "für dynamische Seiten aktiviert"
            }), " sind, ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), " das Pfadsuffix, das für die für diese Zeile erstellte Seite verwendet wird."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"path\": \"example_url_path\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Bei Tabellen, die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "für dynamische Seiten aktiviert"
            }), " sind, ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " der HTML-Titel, der für die für diese Zeile erstellte Seite verwendet wird."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"Example Title\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Beim Erstellen ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "von dynamischen Seiten mit mehreren Ebenen"
            }), " gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), " die ID der untergeordneten Tabelle an."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableId\": 123456"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Unter Verwendung der oben aufgeführten Felder kann Ihre Anfrage beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"values\": {\n    \"text_column\": \"sample text value\",\n    \"number_column\": 76,\n    \"rich_text_column\": \"<strong>This is a styled paragraph.</strong>\",\n    \"date_column\": 1591228800000,\n    \"date_time_column\": 1604450520000,\n    \"boolean_column\": 1,\n    \"select_column\": {\n      \"name\": \"option 1\",\n      \"type\": \"option\"\n    },\n    \"multiselect_column\": [\n      {\n        \"name\": \"Option 1\",\n        \"type\": \"option\"\n      },\n      {\n        \"name\": \"Option 2\",\n        \"type\": \"option\"\n      }\n    ],\n    \"url_column\": \"https://www.hubspot.com/marketing\",\n    \"video_column\": 3392210008,\n    \"image_column\": {\n      \"url\": \"https://f.hubspotusercontentqa00.net/hubfs/99992002/image3%20(1).jpg\",\n      \"width\": 1600,\n      \"height\": 900,\n      \"type\": \"image\"\n    },\n    \"foreign_id_column\": [\n      {\n        \"id\": \"4364402239\",\n        \"type\": \"foreignid\"\n      },\n      {\n        \"id\": \"4364402240\",\n        \"type\": \"foreignid\"\n      }\n    ]\n  },\n  \"path\": \"test_path\",\n  \"name\": \"test_title\",\n  \"childTableId\": \"1902373\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zeilen aus CSV importieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Daten aus einer CSV-Datei in eine HubDB-Tabelle zu importieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft/import"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Import-Endpunkt nimmt eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), "-", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage entgegen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "config"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " eine Reihe von JSON-formatierten Optionen für den Import."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "file"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " die CSV-Datei, die Sie importieren möchten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nehmen Sie in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), " die folgenden Felder als JSON-Zeichenfolge auf:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiel"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "skipRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Anzahl der Kopfzeilen, die übersprungen werden sollen. Dieses Feld hat standardmäßig den Wert ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), ", wobei die erste Zeile übersprungen und als Kopfzeile behandelt wird. Legen Sie dies auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " fest, wenn alle Zeilen gültige Daten sind."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"skipRows\": 0"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "separator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Das Spaltentrennzeichen in der CSV-Datei. Standardmäßig auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\",\""
            }), " festgelegt."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"separator\": \",\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Index der Spalte in der Quelledatei, die die ID (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), ") der Zeile enthält. Wenn diese Spalte angegeben wird, aktualisiert der Import die vorhandenen Zeilen in der Tabelle für die übereinstimmenden Zeilen-IDs aus der CSV-Datei. Dies ist optional und Sie können dies beim ersten Importieren von Daten in eine Tabelle ignorieren. Weitere Informationen finden Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "Optionen zum Zurücksetzen"
            }), " weiter unten."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"idSourceColumn\": 1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "resetTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Standardwert ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", was bedeutet, dass die Zeilen der Tabelle aktualisiert werden, ohne vorhandene Zeilen zu entfernen. Bei Festlegung auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " überschreiben die Kalkulationstabellenzeilen die Tabellendaten, d. h., alle Zeilen in der Tabelle, die nicht in der Tabelle enthalten sind, werden gelöscht. Weitere Informationen finden Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "Optionen zum Zurücksetzen"
            }), " weiter unten."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"resetTable\": true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Für Tabellen, die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "für dynamische Seiten aktiviert sind"
            }), ", gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            }), " die Spalte in der CSV-Datei an, die den ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " der Zeile enthält. Die Spaltennummern sind in aufsteigender Reihenfolge, wobei ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " die erste Spalte ist."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"nameSourcecolumn\": 5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Für Tabellen, die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "für dynamische Seiten aktiviert sind"
            }), ", gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            }), " die Spalte in der CSV-Datei an, die den ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), " der Zeile enthält. Die Spaltennummern sind in aufsteigender Reihenfolge, wobei ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " die erste Spalte ist."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"pathSourcecolumn\": 6"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt die Spalte in der CSV-Datei an, die die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), " der Zeile enthält. Die Spaltennummern sind in aufsteigender Reihenfolge, wobei ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " die erste Spalte ist."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableSourcecolumn\": 3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Liste der Zuordnungen der Spalten in der Quelle-Datei zu den Spalten in der HubDB-Zieltabelle. Jede Zuordnung muss das folgende Format habent: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"source\":1,\"target”:\"columnIdOrName\"}"
            }), " ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Quelle:"
                }), " der Spaltenindex in der Quelldatei. Zum Beispiel ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                }), " für die zweite Spalte."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Ziel:"
                }), " die ID oder der Name der Spalte der HubDB-Tabelle. Sie können die ID oder den Namen einer Spalte abrufen, indem Sie ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#retrieve-hubdb-data",
                  children: "die Details für die Tabelle abrufen"
                }), "."]
              })]
            }), "Wenn Ihre Datei eine ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), "-Spalte enthält, sollte diese nicht in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), "enthalten sein. Berücksichtigen Sie sie stattdessen als die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            }), ", um vorhandene Zeilen zu aktualisieren."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"columnMappings\": [{\"source\":1, \"target\": 2}, {\"source\": 2, \"target\": \"column_name\"}]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryKeyColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name einer Spalte in der HubDB-Zieltabelle, die für die Deduplizierung verwendet wird. Die ID der Spalte kann nicht für dieses Feld verwendet werden."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"primaryKeyColumn\": \"column_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "encoding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Codierungstyp der Datei. Zum Beispiel ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utf-8"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ascii"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-5"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-2022-jp"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "windows-1252"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"encoding\": \"utf-8\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nur CSV wird unterstützt."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"format\": \"csv\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In der obigen Tabelle könnte Ihr ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), "-JSON wie folgt aussehen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example config JSON\n{\n  \"skipRows\": 1,\n  \"separator\": \",\",\n  \"idSourceColumn\": 1,\n  \"resetTable\": false,\n  \"columnMappings\": [\n    {\n      \"target\": 1,\n      \"source\": 2\n    },\n    {\n      \"target\": 2,\n      \"source\": \"zip_code\"\n    }\n  ],\n  \"primaryKeyColumn\": \"name\",\n  \"encoding\": \"utf-8\",\n  \"format\": \"csv\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie cURL verwenden, könnte Ihr Befehl wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -L -X POST 'https://api.hubspotqa.com/hubdb/api/v2/tables/xxxxxx/import?portalId=xxxxxxx' \\\n-H 'Content-Type: multipart/form-data' \\\n-F 'config=\"{\\\"skipRows\\\":1,\\\"format\\\":\\\"csv\\\",\\\"separator\\\":\\\",\\\",\\\"encoding\\\":\\\"iso-8859-1\\\",\\\"columnMappings\\\":[{\\\"target\\\":1,\\\"source\\\":7},{\\\"target\\\":3,\\\"source\\\":8}],\\\"idSourceColumn\\\":1,\\\"pathSourceColumn\\\":null,\\\"nameSourceColumn\\\":null,\\\"childTableSourceColumn\\\":null,\\\"resetTable\\\":true}\"' \\\n-F 'file=@\"/Users/xxxxxxxxxxxxx/Downloads/filename.csv\"'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Datumsformatierung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt mehrere Formate, die Sie verwenden können, wenn Sie Daten in eine Spalte vom Typ „Datum“ importieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ganzzahlen"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yyyy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yy"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei diesen Formaten muss der Monat vor dem Tag liegen (d. h., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dd/mm/yy"
      }), " wird nicht akzeptiert). Ganzzahlen können durch Bindestriche (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), ") oder Schrägstriche (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/"
      }), ") getrennt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Datumsangaben ohne festes Format"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können auch Datumsformate importieren, die weniger standardisiert sind als ganzzahlige Datumsangaben. Zum Beispiel:**"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "The 1st of March in the year 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Fri Mar 4 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "March 4th '22"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Relative Daten"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot analysiert die folgenden Datumsformate relativ zum aktuellen Tag:**"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "next Thursday"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Today"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "tomorrow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "3 days from now"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Optionen zum Zurücksetzen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Daten aus einer CSV-Datei in eine HubDB-Tabelle importieren, können Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "resetTable"
      }), "-Feld auf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " (Standard) setzen, um zu verwalten, ob HubDB-Zeilendaten überschrieben werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wenn ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), " festgelegt ist:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wenn die Zeilen in der CSV-Datei keine Zeilen-ID-Spalte (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), ") haben oder die Zeilen-ID als ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " angegeben ist, werden diese Zeilen mit den neu generierten Zeilen-IDs eingefügt."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn die Zeilen-IDs in der CSV-Datei bereits in der Zieltabelle vorhanden sind, werden die vorhandenen Zeilen in der Tabelle mit den neuen Werten aus der Eingabedatei aktualisiert."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn die Tabelle Zeilen enthält, die Eingabe-CSV-Datei jedoch nicht über diese Zeilen-IDs verfügt, werden diese Zeilen aus der Zieltabelle gelöscht."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn die Zeilen-IDs in der Eingabe-CSV-Datei nicht in der Zieltabelle vorhanden sind, werden diese Zeilen mit den neu generierten Zeilen-IDs eingefügt, und die in der Eingabedatei angegebenen Zeilen-IDs werden ignoriert."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn die Eingabe-CSV-Datei die Zeilen-ID-Spalte überhaupt nicht enthält, werden alle Zeilen aus der Zieltabelle gelöscht, und die Zeilen aus der Eingabedatei werden mit den neu generierten Zeilen-IDs eingefügt."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wenn ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), " festgelegt ist (Standard):"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn die Zeilen-IDs in der CSV-Datei bereits in der Zieltabelle vorhanden sind, werden die vorhandenen Zeilen in der Tabelle mit den neuen Werten aus der Eingabedatei aktualisiert."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wenn die Tabelle Zeilen enthält, die Eingabe-CSV-Datei jedoch nicht über diese Zeilen-IDs verfügt, werden diese Zeilen ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "nicht"
            }), " aus der Zieltabelle gelöscht, und diese Zeilen bleiben unverändert."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn die Zeilen-IDs in der Eingabe-CSV-Datei nicht in der Zieltabelle vorhanden sind, werden diese Zeilen mit den neu generierten Zeilen-IDs eingefügt, und die in der Eingabedatei angegebenen Zeilen-IDs werden ignoriert."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wenn die Zeilen in der CSV-Datei keine Spalte mit Zeilen-ID aufweisen oder die Zeilen-ID als ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " angegeben ist, werden diese Zeilen mit den neu generierten Zeilen-IDs eingefügt."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB-Daten abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt mehrere Möglichkeiten, HubDB-Daten abzurufen, je nachdem, ob Sie nach Tabellendetails oder nach den Zeilen einer Tabelle suchen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um Tabellendetails von allen veröffentlichten Tabellen abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables"
        }), " durch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um Tabellendetails aus einer bestimmten veröffentlichten Tabelle abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}"
        }), " durch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um alle Zeilen aus einer bestimmten Tabelle abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}/rows"
        }), " durch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um eine bestimmte Zeile aus einer Tabelle abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}/rows/{rowId}"
        }), " durch."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beim Abrufen von Zeilendaten können Sie die Ergebnisse weiter filtern und sortieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn für eine Tabelle der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "öffentliche Zugriff zugelassen"
      }), " werden soll, können Sie ohne Authentifizierung auf die veröffentlichten Version der Tabelle und der Zeilen zugreifen, indem Sie Ihre HubSpot-Account-ID über den Abfrageparameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), " angeben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zurückgegebene Zeilen filtern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Abrufen von HubDB-Tabellendaten können Sie Filter als Abfrageparameter anwenden, um bestimmte Daten zu erhalten. Die Filterabfrageparameter sind wie folgt aufgebaut: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "columnName__operator"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie z. B. eine Zahlenspalte mit dem Namen ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "bar"
      }), " haben, können Sie die Ergebnisse so filtern, dass nur Zeilen enthalten sind, bei denen ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "bar"
      }), " größer als 10 ist: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&bar__gt=10"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alle Filter werden mit UND verknüpft (ODER-Filter werden derzeit nicht unterstützt)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beachten Sie beim Filtern Folgendes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Bei der Übergabe von Werten für ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect"
          }), "-Spalten sollten die Werte durch Komma getrennt werden (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect_column__contains=1,2"
          }), ")."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Bei ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "datetime"
          }), "-Filtern können Sie relative Datumsangaben anstelle von Zeitstempeln verwenden, um einen Wert relativ zur aktuellen Zeit anzugeben. Beispielsweise entspricht ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "-3h"
          }), " dem Zeitstempel 3 Stunden vor jetzt, während ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "10s"
          }), " 10 Sekunden in der Zukunft entspricht. Unterstützte Zeiteinheiten sind ms (Millisekunden), s (Sekunden), m (Minuten), h (Stunden), d (Tage). Die aktuelle Uhrzeit kann durch Angabe eines Nullwerts verwendet werden: 0s"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Für die Zwecke dieser Filter ist die integrierte Spalte ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_id"
          }), " eine ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "number"
          }), "-Spalte, die ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_created_at"
          }), "-Spalte eine ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "datetime"
          }), " und die Spalten ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_path"
          }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_name"
          }), " sind ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          }), "-Spalten."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie, welche Operatoren auf welche Spaltentypen angewendet werden können:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operator"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq (or none)"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ist gleich"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Spaltentypen. Dieser Filter wird angewendet, wenn kein Operator verwendet wird. Bei Verwendung mit Mehrfachauswahlspalten werden Zeilen zurückgegeben, die genau mit den angegebenen Werten übereinstimmen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ne"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ungleich"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Spaltentypen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enthält"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Text, Rich-Text und Mehrfachauswahl. Bei Verwendung mit Mehrfachauswahlspalten werden Zeilen zurückgegeben, die alle angegebenen Werte enthalten. Bei diesem Filter wird ", (0, _jsxRuntime.jsx)("u", {
              children: "zwischen Groß- und Kleinschreibung unterschieden"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kleiner als"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anzahl, Datum und Datetime."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kleiner als oder gleich"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anzahl, Datum und Datetime."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Größer als"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anzahl, Datum und Datetime."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Größer als oder gleich"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anzahl, Datum und Datetime."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Null"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alle Spaltentypen außer boolesch. Für diesen Filter ist kein Wert erforderlich (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__is_null="
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nicht null"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alle Spaltentypen außer boolesch. Für diesen Filter ist kein Wert erforderlich (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__not_null="
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wie"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text und Rich-Text."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nicht wie"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text und Rich-Text."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icontains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enthält"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Text und Rich-Text. Bei diesem Filter wird ", (0, _jsxRuntime.jsx)("u", {
              children: "nicht"
            }), " zwischen Groß- und Kleinschreibung unterschieden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startswith"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Beginnt mit"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text und Rich-Text."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "In"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Anzahl, Auswahl und Mehrfachauswahl. Gibt Zeilen zurück, in denen die Spalte mindestens eine der übergebenen Optionen enthält. Wenn der Abfrageparameter keinen anderen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sort"
            }), "-Wert enthält, werden die Ergebnisse in der Reihenfolge sortiert, in der die Werte im ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "-Operator angegeben werden."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zurückgegebene Zeilen sortieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Abrufen von HubDB-Daten können Sie Sortierung als Abfrageparameter anwenden, um die Reihenfolge der zurückgegebenen Daten zu bestimmen. Um Daten zu sortieren, fügen Sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort"
      }), "-Abfrageparameter hinzu und geben Sie den Spaltennamen an:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=columnName"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Standardmäßig werden die Daten in der natürlichen Reihenfolge der angegebenen Spalte zurückgegeben. Sie können die Sortierung umkehren, indem Sie ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), " an den Spaltennamen anhängen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=-columnName"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können diesen Parameter mehrfach angeben, um nach mehreren Spalten zu sortieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Neben der Sortierung nach einer Spalte gibt es drei weitere Funktionen, die verwendet werden können:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "geo_distance(location_column_name, latitude, longitude):"
          }), " nimmt den Namen einer Standortspalte und Koordinaten an und gibt die Zeilen danach geordnet zurück, wie weit der Wert der angegebenen Standortspalte von den angegebenen Koordinaten entfernt ist."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "**length(column_name):**nimmt den Namen einer Spalte an und gibt die Zeilen geordnet nach der Länge des Spaltenwerts (berechnet als Zeichenfolge) zurück"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "random():"
          }), " gibt die Zeilen in zufälliger Reihenfolge zurück."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Funktionen unterstützen auch das Anordnen in umgekehrter Reihenfolge. Die folgende ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), "-Sortierung gibt z. B. die am weitesten entfernten Elemente zuerst zurück:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort=-geo_distance(location_column,42.37,-71.07)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Konfigurieren von HubDB-Tabellen für dynamische Seiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem CMS von HubSpot können Sie eine HubDB-Tabelle als Quelle verwenden, um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "dynamische Seiten zu erstellen"
      }), ". Sie können z. B. eine Tabelle erstellen, die eine Zeile für jedes Mitglied Ihres Führungsteams enthält, mit Spalten, die Informationen enthalten, die Sie auf einer Seite anzeigen möchten. Nachdem Sie diese Tabelle als dynamische Datenquelle für eine Seite ausgewählt haben, generiert diese Seite eine Listing-Seite, die alle Zeilen als Zusammenfassungselemente zusammen mit separaten Seiten für jede Zeile anzeigt, ähnlich wie bei einer Blog-Listing-Seite und Blogbeitragsseiten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die Auswahl einer Tabelle als Quelle im Content-Editor zu aktivieren, müssen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "useForPage"
      }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " festlegen. Sie können optional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamicMetaTags"
      }), " berücksichtigen, um anzugeben, welche Spalten für die Metadaten der einzelnen Seiten verwendet werden sollen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamicMetaTags"
        }), " angeben, müssen Sie sicherstellen, dass die Seite ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), "-HubL-Tags anstelle von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), " verwendet. Weitere Informationen finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb#metadata",
          children: "Leitfaden für dynamische Seiten"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beispielsweise würde der folgende Code eine Tabelle erstellen, die für dynamische Seiten verwendet werden kann, und gibt die drei Spalten an, die für Seitenmetadaten verwendet werden sollen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to create table\n{\n  \"name\": \"dynamic_page_table\",\n  \"label\": \"Dynamic page table\",\n  \"useForPages\": true,\n  \"columns\": [\n    {\n      \"name\": \"meta_description\",\n      \"label\": \"Meta description\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"featured_image\",\n      \"label\": \"Featured image\",\n      \"archived\": false,\n      \"type\": \"IMAGE\"\n    },\n    {\n      \"name\": \"canonical_url\",\n      \"label\": \"Canonical URL\",\n      \"archived\": false,\n      \"type\": \"URL\"\n    }\n  ],\n  \"dynamicMetaTags\": {\n    \"DESCRIPTION\": 1,\n    \"FEATURED_IMAGE_URL\": 2,\n    \"LINK_REL_CANONICAL_URL\": 3\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " festlegen, damit die Tabelle als Quelle für dynamische Seiten verwendet werden kann."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamicMetaTags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt die Spalten nach ID an, die für Metadaten auf jeder dynamischen Seite verwendet werden sollen. Kann enthalten:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DESCRIPTION"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FEATURED_IMAGE_URL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINK_REL_CANONICAL_URL"
                })
              })]
            }), "Für alle Metadatenfelder, die nicht angegeben sind, erben die Seiten die entsprechenden Werte von ihrer übergeordneten Seite."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DESCRIPTION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die numerische ID der Spalte, die für die Metabeschreibung der einzelnen Seiten verwendet werden soll."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FEATURED_IMAGE_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die numerische ID der Spalte, die für die Feature-Bild-URL der einzelnen Seiten verwendet werden soll."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINK_REL_CANONICAL_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die numerische ID der Spalte, die für die kanonische URL der einzelnen Seiten verwendet werden soll."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Änderungen in v3"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tabellen sollten sowohl ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " als auch ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " enthalten. Dieser Name kann nicht mehr geändert werden, nachdem die Tabelle erstellt wurde. Namen dürfen nur Kleinbuchstaben, Ziffern und Unterstriche enthalten und dürfen nicht mit einer Zahl beginnen. Sowohl ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " als auch ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " sollten im Account eindeutig sein."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["API unterstützt sowohl die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " als auch ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " von Tabellen in URL-Pfaden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Zeilenendpunkte geben den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " der Spalte statt die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " im ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), "-Feld zurück. Außerdem erfordern ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "/", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PUT"
        }), "/", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "-Zeilenendpunkte den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " einer Spalte statt die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " im ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), "-Feld."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "-Endpunkte für Zeilenaktualisierungen akzeptieren jetzt lückenhafte Aktualisierungen, d. h., Sie können nur die Spaltenwerte angeben, die Sie aktualisieren müssen (während Sie in früheren Versionen alle Spaltenwerte angeben mussten). Wenn Sie eine Spalte mit einer Liste von Werten aktualisieren, z. B. Mehrfachauswahl, müssen Sie die Liste aller Werte angeben. Um den Wert für eine Spalte zu löschen, müssen Sie die Spalte mit dem Wert wie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " in der Anfrage angeben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Es wurden die Endpunkte zum ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get"
        }), "/", (0, _jsxRuntime.jsx)(_components.code, {
          children: "update"
        }), "/", (0, _jsxRuntime.jsx)(_components.code, {
          children: "delete"
        }), " eine Zeilenzelle zugunsten der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "-Endpunkte für Zeilenaktualisierungen entfernt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Der Import-Endpunkt unterstützt jetzt das optionales Feld ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "idSourceColumn"
        }), " zusammen mit vorhandenen Feldern in den JSON-formatierten Optionen. Sie können dieses Feld verwenden, um die Spalte in der CSV-Datei anzugeben, die Zeilen-IDs enthält. Um neue Zeilen zusammen mit den neuen Werten für vorhandene Zeilen zu importieren, können Sie einfach ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), " als Zeilen-ID für die neuen Zeilen und die gültigen Zeilen-IDs für die vorhandenen Spalten angeben. Weitere Informationen finden Sie im Abschnitt zum Importieren. Außerdem können Sie Spaltennamen oder -IDs im Zielfeld der Spaltenzuordnungen in den JSON-formatierten Optionen verwenden."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der Klonen-Endpunkt erfordert einen neuen Namen und ein neues Label."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}