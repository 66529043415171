"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937483;
const slug = exports.slug = 'guides/api/app-management/webhooks';
const title = exports.title = 'Webhooks API ';
const name = exports.name = 'vNext Docs DP - Webhooks API';
const metaDescription = exports.metaDescription = 'Overview for the Webhooks API, which allows you to subscribe to events happening in a HubSpot account with your integration installed.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "scopes",
  "label": "Scopes",
  "parent": null
}, {
  "depth": 0,
  "id": "webhook-settings",
  "label": "Webhook settings",
  "parent": null
}, {
  "depth": 1,
  "id": "manage-settings-in-your-developer-account",
  "label": "Manage settings in your developer account",
  "parent": "webhook-settings"
}, {
  "depth": 1,
  "id": "manage-settings-via-api",
  "label": "Manage settings via API",
  "parent": "webhook-settings"
}, {
  "depth": 0,
  "id": "webhook-subscriptions",
  "label": "Webhook subscriptions",
  "parent": null
}, {
  "depth": 1,
  "id": "create-subscriptions-in-your-developer-account",
  "label": "Create subscriptions in your developer account",
  "parent": "webhook-subscriptions"
}, {
  "depth": 1,
  "id": "create-subscriptions-via-api",
  "label": "Create subscriptions via API",
  "parent": "webhook-subscriptions"
}, {
  "depth": 1,
  "id": "get-subscriptions",
  "label": "Get subscriptions",
  "parent": "webhook-subscriptions"
}, {
  "depth": 1,
  "id": "create-a-new-subscription",
  "label": "Create a new subscription",
  "parent": "webhook-subscriptions"
}, {
  "depth": 1,
  "id": "update-a-subscription",
  "label": "Update a subscription",
  "parent": "webhook-subscriptions"
}, {
  "depth": 1,
  "id": "delete-a-subscription",
  "label": "Delete a subscription",
  "parent": "webhook-subscriptions"
}, {
  "depth": 0,
  "id": "webhook-payloads",
  "label": "Webhook payloads",
  "parent": null
}, {
  "depth": 0,
  "id": "privacy-compliant-contact-deletions",
  "label": "Privacy compliant contact deletions",
  "parent": null
}, {
  "depth": 0,
  "id": "security",
  "label": "Security",
  "parent": null
}, {
  "depth": 0,
  "id": "retries",
  "label": "Retries",
  "parent": null
}, {
  "depth": 0,
  "id": "limits",
  "label": "Limits",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      h2: "h2",
      em: "em",
      code: "code",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Webhooks"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The Webhooks API allows you to subscribe to events happening in a HubSpot account with your integration installed. Rather than making an API call when an event happens in a connected account, HubSpot can send an HTTP request to an endpoint you configure. You can configure subscribed events in your app’s settings or using the endpoints detailed below. Webhooks can be more scalable than regularly polling for changes, especially for apps with a large install base."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using the Webhooks API requires the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You must set up a HubSpot app to use webhooks by subscribing to the events you want to be notified about, and by specifying a URL to send those notifications. See the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/integration-platform-api-requirements",
          children: "prerequisites documentation"
        }), " for more details about creating an app."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You must deploy a publicly available and secure (HTTPS) endpoint for that URL that can handle the webhook payloads specified in this documentation."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhooks are set up for a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "HubSpot app"
      }), ", not individual accounts. Any account that install your app by going through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth-tokens#initiate-an-integration-with-oauth-2.0",
        children: "OAuth flow"
      }), " will be subscribed to its webhook subscriptions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can subscribe to CRM object events, which includes contacts, companies, deals, tickets, products and line items, as well as conversations events."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["You can also ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
            children: "manage webhooks in a private app"
          }), ". In private apps, webhook settings can only be edited in the in-app settings for your private app, and cannot currently be edited through the API."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["To subscribe to conversations webhooks, you need access to the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/conversations/inbox-and-messages",
            children: "conversations inbox and messages APIs"
          }), ", which is currently ", (0, _jsxRuntime.jsx)("u", {
            children: "in beta"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Scopes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In order to use webhooks to subscribe to CRM events, your app will need to be configured to authorize the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/apps/public-apps/overview#scope-types",
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Required scopes"
        })
      }), " that corresponds to the CRM object type you want to subscribe to. For example, if you want to subscribe to contact events, you would need to request the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm.objects.contacts.read"
      }), " scope."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you're creating subscriptions in the settings UI of your public app, you'll be prompted to add the required scope in the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Create new webhook subscriptions"
        }), " panel before you finish creating your subscription."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you're creating a subscription by making a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/webhooks/v3/{appId}/subscriptions"
        }), " endpoint, the response will include an error that will provide the name of the scope you'll need to configure in the settings UI of your public app."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If your app is already using webhooks, you won't be able to remove any scopes required by active webhook subscriptions without first pausing and removing the subscriptions."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You can review the scopes required for each webhook subscription type in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#webhook-subscriptions",
          children: "table below."
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Review the OAuth documentation for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth-tokens#initiate-an-integration-with-oauth-2.0#scopes",
        children: "more details about scopes"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth-tokens#initiate-an-integration-with-oauth-2.0",
        children: "setting up the authorization URL"
      }), " for your app."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhook settings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before setting up your webhook subscriptions, you need to specify a URL to send those notifications to. Follow the instructions in the sections below to learn how to fully configure subscriptions for your app."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Webhook settings can be cached for up to five minutes. When making changes to the webhook URL, concurrency limits, or subscription settings, it may take up to five minutes to see your changes go into effect."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "HubSpot sets a concurrency limit of 10 requests when sending subscription event data associated with an account that installed your app. This concurrency limit is the maximum number of in-flight requests that HubSpot will attempt at a time. Each request can contain up to 100 events."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Manage settings in your developer account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can manage your URL and event throttling limit in your app’s configuration page in your developer account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your developer account, navigate to your ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "App"
          }), " dashboard."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of the app you want to set up webhooks for."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/app_id_list.png?width=600&name=app_id_list.png",
            alt: "app_id_list"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Webhooks"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Target URL"
          }), " field, enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " that HubSpot will make a POST request to when events trigger."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Use the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Event throttling"
          }), " setting to adjust the maximum number of events HubSpot will attempt to send."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_settings.png?width=600&name=webhook_settings.png",
        alt: "webhook_settings"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Manage settings via API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the following endpoints and your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/faq/developer-api-keys",
        children: "developer API key"
      }), " to programmatically configure webhook settings for an app."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view any webhook settings currently configured for an app, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You'll need to include the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/faq/how-do-i-find-the-app-id",
        children: "app ID"
      }), " in the request, which you can find below the name of the app in your ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Apps"
      }), " dashboard, or on the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Auth"
      }), " tab in your app's settings."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The settings object contains the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "webhookUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The URL that HubSpot will send webhook notifications to. This URL must be served over HTTPS."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The concurrency limit for the webhook URL. This value must be a number greater than five."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To make edits to these settings, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), " and include the following fields in the request body:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "targetUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The publicly available URL for HubSpot to call where event payloads will be delivered."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "throttling"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configure webhook throttling details in this object. The throttling object includes the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            }), " fields."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Time scale for this setting. Can be either ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SECONDLY"
            }), " (per second) or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ROLLING_MINUTE"
            }), " (per minute)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The maximum number of HTTP requests HubSpot will attempt to make to your app in a given time frame determined by ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to https://api.hubapi.com/webhooks/v3/{appId}/settings\n\n{\n  \"throttling\": {\n    \"period\": \"SECONDLY\",\n    \"maxConcurrentRequests\": 10\n  },\n  \"targetUrl\": \"https://www.example.com/hubspot/target\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhook subscriptions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you’ve set up your webhook URL and event throttling limit, you’ll need to create one or more subscriptions. Webhook subscriptions tell HubSpot which events your particular app would like to receive."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Subscriptions apply to all customers who have installed your integration. This means that you only need to specify what subscriptions you need once. Once you've turned on a subscription for an application, it will automatically start getting webhooks for all customers that have installed your application, and your integration will start receiving webhook triggers from any new customers."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For all ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationChange"
      }), " webhook subscriptions, the webhook will fire two events for both sides of the association."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When associating two contacts, a subscription to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), " will fire two events, representing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 1 to contact 2"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 2 to contact 1"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When associating a company, if you have two webhook subscriptions ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company.associationChange"
        }), ", you will receive two events. These will represent ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 1 to company 1"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company 1 to contact 1"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following subscription types are supported and can be used as the value for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " field when creating subscriptions via API:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Subscription type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Scope required"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any contact is created in a customer's account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any contact is deleted in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a contact is merged with another."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a contact has an association added or removed between itself and another supported webhook object (contact, company, deal, ticket, line item, or product)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a contact is restored from deletion."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Get notified if a contact is deleted for ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks",
              children: "privacy compliance reasons"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a specified property is changed for any contact in an account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any company is created in a customer's account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any company is deleted in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a specified property is changed for any company in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a company has an association added or removed between itself and another supported webhook object (contact, company, deal, ticket, line item, or product)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a company is restored from deletion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a company is merged with another."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any deal is created in a customer's account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any deal is deleted in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a deal has an association added or removed between itself and another supported webhook object (contact, company, deal, ticket, line item, or product)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a deal is restored from deletion."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a deal is merged with another."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a specified property is changed for any deal in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a ticket is created in a customer's account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any ticket is deleted in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a specified property is changed for any ticket in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a ticket has an association added or removed between itself and another supported webhook object (contact, company, deal, ticket, line item, or product)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a ticket is restored from deletion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a ticket is merged with another."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any product is created in a customer's account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any product is deleted in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a product is restored from deletion."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a product is merged with another."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a specified product is changed for any product in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any line item is created in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if any line item is deleted in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a line item has an association added or removed between itself and another supported webhook object (contact, company, deal, ticket, line item, or product)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a line item is restored from deletion."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a line item is merged with another."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a specified property is changed for any line item in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following conversations subscription types are available to you to subscribe to if you're using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "conversations inbox and messages API"
      }), ", which is currently ", (0, _jsxRuntime.jsx)("u", {
        children: "in beta"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Subscription type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Scope"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a new thread is created in an account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a thread is archived or soft-deleted in an account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a thread is permanently deleted in an account."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a property on a thread has been changed."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.newMessage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get notified if a new message on a thread has been received."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For property change subscriptions, you will need to specify which property you want to be notified of. You can specify multiple property change subscriptions. If a customer's account doesn't have the property you specify in a subscription, you will not get any webhooks from that customer for that property."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Certain properties are not available for CRM property change subscriptions. These properties are:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "num_unique_conversion_events"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_lastmodifieddate"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you are using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "conversations messages and inbox API"
      }), ", which is currently ", (0, _jsxRuntime.jsx)("u", {
        children: "in beta"
      }), ", the following properties are available:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "assignedTo"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the conversation thread has been reassigned or unassigned. If the thread was reassigned, the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " will be an actor ID in the webhooks payload; if unassigned, it will be empty."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "status"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the status of the conversation thread has changed. In the webhooks payload, the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " will either be ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OPEN"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CLOSED"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "isArchived"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the conversation thread has been restored. The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " in the webhooks payload will always be ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "FALSE"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create subscriptions in your developer account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can create webhook subscriptions in your HubSpot developer account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot developer account, navigate to the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " dashboard."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of an app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create subscription"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Which object types?"
        }), " dropdown menu and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "objects"
        }), " you want to create a subscription for."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listen for which events?"
        }), " dropdown menu and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "event types"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-contact-create-subscription.png",
        alt: "create-contact-create-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If you're creating a subscription for property change events, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Which properties?"
          }), " dropdown menu and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "properties"
          }), " to listen for."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_select_properties.png?width=450&name=webhook_select_properties.png",
            alt: ""
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Subscribe"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The subscription will appear in your webhooks settings. New subscriptions are created in a paused state, so you will need to activate the subscription for webhooks to send:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Event subscriptions"
        }), " section, hover over the object type and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "View subscriptions"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "checkbox"
        }), " next to the event, then in the table header click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activate"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/activate-subscription.png",
        alt: "activate-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create subscriptions via API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can programmatically create subscriptions using the following endpoints. You will need to use your developer API key when making requests to these endpoints."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A subscription object can include the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A number representing the unique ID of a subscription."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The time in milliseconds when this subscription was created."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The user ID associated with the user who created the subscription."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This indicates whether or not the subscription is turned on and actively triggering notifications. The value can be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of subscription. The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks#webhook-subscriptions",
              children: "table"
            }), " at the start of this section includes the available subscription types."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the property the subscription will listen for changes to. This is only needed for property change subscription types."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get subscriptions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve the list of subscriptions, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will be an array of objects representing your subscriptions. Each object will include information on the subscription like the ID, create date, type, and whether or not it’s currently active. Here’s what an example response would look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example GET request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n[\n  {\n    \"id\": 25,\n    \"createdAt\": 1461704185000,\n    \"createdBy\": 529872,\n    \"eventType\": \"contact.propertyChange\",\n    \"propertyName\": \"lifecyclestage\",\n    \"active\": false\n  },\n  {\n    \"id\": 59,\n    \"createdAt\": 1462388498000,\n    \"createdBy\": 529872,\n    \"eventType\": \"company.creation\",\n    \"active\": false\n  },\n  {\n    \"id\": 108,\n    \"createdAt\": 1463423132000,\n    \"createdBy\": 529872,\n    \"eventType\": \"deal.creation\",\n    \"active\": true\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a new subscription"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a new subscription, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the request body, you can include the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The type of subscription."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the property the subscription will listen for changes to. This is only needed for property change subscription types."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This indicates whether or not the subscription is turned on and actively triggering notifications. The value can be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You don't need to include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdAt"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdBy"
      }), ", as those fields are set automatically."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request body may appear similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n{\n  \"eventType\": \"company.propertyChange\",\n  \"propertyName\": \"companyname\",\n  \"active\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " must be a valid subscription type as defined in the above section and the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyName"
      }), " must be a valid property name. If a customer has no property defined that matches this value, then this subscription will not result in any notifications."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update a subscription"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To activate or pause a subscription, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the request body, include the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This indicates whether or not the subscription is turned on and actively triggering notifications. The value can be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Delete a subscription"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete a subscription, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhook payloads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The endpoint at the target URL that you specify in your app's webhooks settings will receive ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " requests containing JSON formatted data from HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To ensure that the requests you're getting at your webhook endpoint are actually coming from HubSpot, HubSpot populates a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " header with a SHA-256 hash built using the client secret of your app combined with details of the request. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "validating request signatures"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use the tables below to view details about fields that may be contained in the payload."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the object that was created, changed, or deleted. For contacts this is the contact ID; for companies, the company ID; for deals, the deal ID; and for conversations the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "thread ID"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This is only sent for property change subscriptions and is the name of the property that was changed."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyValue"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This is only sent for property change subscriptions and represents the new value set for the property that triggered the notification."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "changeSource"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The source of the change. This can be any of the change sources that appear in contact property histories."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the event that triggered this notification. This value is not guaranteed to be unique."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the subscription that triggered a notification about the event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The customer's ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/manage-multiple-hubspot-accounts#check-your-current-account",
              children: "HubSpot account ID"
            }), " where the event occurred."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of your application. This is used in case you have multiple applications pointing to the same webhook URL."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When this event occurred as a millisecond timestamp."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The type of event this notification is for. Review the list of supported subscription types in the webhooks subscription section above."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attemptNumber"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Starting at 0, which number attempt this is to notify your service of this event. If your service times-out or throws an error as describe in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Retries"
            }), " section below, HubSpot will attempt to send the notification again."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This is only sent when a webhook is listening for new messages to a thread. It is the ID of the new message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This is only sent when a webhook is listening for new messages to a thread. It represents the type of message you're sending. This value can either be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "MESSAGE"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMMENT"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the merge winner, which is the record that remains after the merge. In the HubSpot merge UI, this is the record on the right."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mergedObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An array of IDs that represent the records that are merged into the merge winner. In the HubSpot merge UI, this is the record on the left."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "newObjectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the record that is created as a result of the merge. This is separate from ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            }), " because in some cases a new record is created as a result of the merge."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numberOfPropertiesMoved"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An integer representing how many properties were transferred during the merge."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of association, which will be one of the following:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_CONTACT"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_COMPANY"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_LINE_ITEM"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_DEAL"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_TICKET"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINE_ITEM_TO_DEAL"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the record that the association change was made from."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the secondary record in the association event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationRemoved"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A boolean that represents the following:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": the webhook was triggered by removing an association."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), ": the webhook was triggered by creating an association."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isPrimaryAssociation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A boolean that represents the following:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": the secondary record is the ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/crm-setup/associate-records#primary-company-information",
                  children: "primary association"
                }), " of the record that the association change was made from."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), ": the record is ", (0, _jsxRuntime.jsx)("u", {
                  children: "not"
                }), " the primary association of the record that the association change was made from. "]
              })]
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Please note:"
            }), " creating a primary association instance between two object records will cause the corresponding non-primary association to also be created. This may result in two webhook messages."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n[\n  {\n    \"objectId\": 1246965,\n    \"propertyName\": \"lifecyclestage\",\n    \"propertyValue\": \"subscriber\",\n    \"changeSource\": \"ACADEMY\",\n    \"eventId\": 3816279340,\n    \"subscriptionId\": 25,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.propertyChange\",\n    \"attemptNumber\": 0\n  },\n  {\n    \"objectId\": 1246978,\n    \"changeSource\": \"IMPORT\",\n    \"eventId\": 3816279480,\n    \"subscriptionId\": 22,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.creation\",\n    \"attemptNumber\": 0\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As shown above, you should expect to receive an array of objects in a single request. The batch size can vary, but will be under 100 notifications. HubSpot will send multiple notifications when a lot of events have occurred within a short period of time. For example, if you've subscribed to new contacts and a customer imports a large number of contacts, HubSpot will send you the notifications for these imported contacts in batches and not one per request."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot does not guarantee that you'll receive these notifications in the order they occurred. Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "occurredAt"
      }), " property for each notification to determine when the event that triggered the notification occurred."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot also does not guarantee that you'll only get a single notification for an event. Though this should be rare, it is possible that HubSpot will send you the same notification multiple times."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Privacy compliant contact deletions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot users have the ability to permanently delete a contact record to comply with privacy laws. Learn more about performing a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-do-i-perform-a-gdpr-delete-in-hubspot",
        children: "GDPR compliant delete"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can subscribe to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact.privacyDeletion"
      }), " subscription type to receive webhook notifications when a user performs a privacy compliant contact deletion."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Privacy deletion notifications have some special behavior:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A privacy deletion event will also trigger the contact deletion event, so you will receive two notifications if you are subscribed to both events."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "These notifications will not necessarily be sent in any specific order or in the same batch of messages. You will need to use the object ID to match the separate messages."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Security"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To ensure that the requests you're getting at your webhook endpoint are actually coming from HubSpot, HubSpot populates a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " header with a SHA-256 hash of the concatenation of the app-secret for your application and the request body HubSpot is sending."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To verify this signature, concatenate the app secret of your application and the un-parsed request body of the request you're handling, and get a SHA-256 hash of the result. Compare the resulting hash with the value of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), ". If these values match, then this verifies that this request came from HubSpot. Or, the request came from someone else who knows your application secret. It's important to keep this value secret."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If these values do not match, then this request may have been tampered with in-transit or someone may be spoofing webhook notifications to your endpoint."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "validating signature requests"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retries"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If your service has problems handling notifications at any time, HubSpot will attempt to re-send failed notifications up to 10 times."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will retry in the following cases:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Connection failed:"
        }), " HubSpot cannot open an HTTP connection to the provided webhook URL."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Timeout:"
        }), " your service takes longer than five seconds to send back a response to a batch of notifications."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Error codes:"
        }), " your service responds with any HTTP status code (4xx or 5xx)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Notifications will be retried up to 10 times. These retries will be spread out over the next 24 hours, with varying delays between requests. Individual notifications will have some randomization applied, to prevent a large number of concurrent failures from being retried at the exact same time."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " requests that HubSpot sends to your service via your webhook subscriptions will ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " count against your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "app's API rate limits"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can create a maximum of 1000 subscriptions per application. If you attempt to create more you will receive a 400 bad request in return with the following body:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"status\": \"error\",\n  \"message\": \"Couldn't create another subscription. You've reached the maximum number allowed per application (1000).\",\n  \"correlationId\": \"2c9beb86-387b-4ff6-96f7-dbb486c00a95\",\n  \"requestId\": \"919c4c84f66769e53b2c5713d192fca7\"\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}