"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79942329406;
const slug = exports.slug = 'guides/cms/content/themes/quotes-themes';
const title = exports.title = 'Introdução ao tema de orçamentos de CMS';
const name = exports.name = '[PT-BR] Getting started from the CMS quotes theme';
const metaDescription = exports.metaDescription = 'Criar um tema de orçamento personalizado para sua empresa com base no tema de orçamentos do CMS fornecido pela HubSpot. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "pr%C3%A9-requisitos",
  "label": "Pré-requisitos",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-obtendo-o-tema-para-o-diret%C3%B3rio-local",
  "label": "1. Obtendo o tema para o diretório local",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-carregar-e-assistir-%C3%A0s-altera%C3%A7%C3%B5es",
  "label": "2. Carregar e assistir às alterações",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-abrir-uma-visualiza%C3%A7%C3%A3o-do-modelo",
  "label": "3. Abrir uma visualização do modelo",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-fazer-uma-altera%C3%A7%C3%A3o-localmente",
  "label": "4. Fazer uma alteração localmente",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-alterar-o-r%C3%B3tulo-do-modelo",
  "label": "5. Alterar o rótulo do modelo",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-personalize-o-modelo-de-or%C3%A7amento-no-hubspot",
  "label": "6. Personalize o modelo de orçamento no HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-crie-um-or%C3%A7amento-usando-seu-novo-modelo",
  "label": "7. Crie um orçamento usando seu novo modelo",
  "parent": null
}, {
  "depth": 0,
  "id": "pr%C3%B3ximas-etapas",
  "label": "Próximas etapas",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      code: "code",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Introdução ao tema de orçamentos de CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com um tema de orçamentos de CMS, você pode criar um tema de orçamento personalizado para os representantes de vendas usarem durante o processo de compra. Este guia orientará você a clonar o tema de orçamentos de CMS padrão localmente usando a CLI, enviando o clone para sua conta e fazendo ajustes conforme necessário. Você criará um orçamento usando o modelo para visualizar seu trabalho."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pré-requisitos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você deve se sentir confiante ao escrever HTML e CSS."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você deve ter a versão mais recente do ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/guides/getting-started-with-local-development",
          children: "CLI do HubSpot instalada e configurada para seu portal"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " embora este tutorial use a CLI do HubSpot, você pode fazer tudo isso no HubSpot usando o gerenciador de design, se preferir. Para concluir esse processo no HubSpot, você só precisará clonar o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cms-quotes-theme"
        }), " na pasta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot"
        }), " em vez de executar o comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        }), " mostrado na etapa 1."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Obtendo o tema para o diretório local"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abra o terminal e acesse o diretório para o qual deseja baixar os arquivos. Este será seu diretório de trabalho principal para o restante deste tutorial."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para baixar o tema de orçamentos padrão, execute o seguinte no seu terminal:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch @hubspot/cms-quotes-theme \"my-quotes-theme\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora você deve ver uma pasta chamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), " no sistema de arquivos local. Essa pasta contém todos os ativos necessários para o tema do orçamento, incluindo dados falsos e padrões do módulo dentro da pasta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "imports"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Carregar e assistir às alterações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com a pasta baixada, faça o upload para o HubSpot. Embora você possa usar o comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cli#upload",
        children: "hs upload"
      }), " para realizar um único upload, você pode usar o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " para disparar uploads automáticos em cada salvamento de arquivo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch \"my-quotes-theme\" \"my-quotes-theme\" --initial-upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Após o upload, agora você pode exibir a pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), " no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/pt/l/design-manager/",
        children: "gerenciador de design"
      }), ". Para abrir o gerenciador de design do terminal, abra uma nova guia ou janela do terminal e execute o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs open dm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Uma nova guia ou janela de terminal é necessária porque você não pode executar comandos enquanto o processo de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), " estiver em execução. Você também pode pressionar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "q"
        }), " para finalizar o comando, executar outro comando e executar novamente ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watc"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Abrir uma visualização do modelo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para visualizar o modelo de orçamento:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No gerenciador de design, navegue até os ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > templates > bold.html."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito do editor de código, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualização ativa com opções de exibição"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com a visualização do modelo aberta, você fará uma alteração localmente, que será carregada automaticamente ao salvar devido à execução do ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Fazer uma alteração localmente"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No editor de código local, abra ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > css > bold.css."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Adicione o código abaixo ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "bold.css"
        }), " e salve as alterações:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".line-items__table tbody tr:nth-child(odd) {\n  background-color: #d6d6d6;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Atualize a visualização do modelo no navegador para visualizar as alterações no CSS. Agora você deve ver que cada linha ímpar no corpo da tabela tem um fundo cinza."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao criar seu tema de orçamento personalizado, você pode repetir esse fluxo de trabalho para confirmar rapidamente as alterações que está fazendo localmente."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " devido à complexidade do sistema de assinatura, as assinaturas não serão exibidas em visualizações."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Alterar o rótulo do modelo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao preparar um tema de orçamentos personalizado para uso na vida real, você deve estar atento ao rótulo do modelo para que os representantes de vendas possam encontrá-lo facilmente entre as opções de orçamento padrão da HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para alterar o rótulo do modelo de orçamento:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No editor de código, abra ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > templates > bold.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "No canto superior do arquivo, visualize a anotação do modelo:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n templateType: quote\n isAvailableForNewContent: true\n label: Bold\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Atualize o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Bold"
        }), " para um nome de sua escolha, como ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "My custom quote template"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Salve o arquivo para carregá-lo no HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Personalize o modelo de orçamento no HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Antes que um representante de vendas possa usar seu modelo de orçamento, ele deve ser personalizado no HubSpot. Isso normalmente seria feito por um gerente de vendas para que possa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/deals/create-custom-quote-templates",
        children: "criar orçamentos prontos para sua equipe de vendas"
      }), ". No entanto, neste tutorial, você mesmo passará por esse processo para entender como é a experiência de criação de conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para personalizar o modelo de orçamento e disponibilizá-lo para representantes de vendas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Na sua conta da HubSpot, clique no ícone de configurações settings na barra de navegação principal."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No menu da barra lateral esquerda, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Objetos > Orçamentos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modelos de orçamento"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Personalizar modelo de orçamento"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Passe o cursor sobre o novo ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "modelo"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Escolher"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usando o painel esquerdo, você pode editar os módulos incluídos no modelo. Por exemplo, você pode clicar em um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "módulo"
        }), " para editar suas propriedades ou alternar a visibilidade."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/quote-template-toggle-visibility.png",
        alt: "quote-template-toggle-visibility"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Salvar"
        }), " quando terminar de fazer alterações."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Crie um orçamento usando seu novo modelo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com suas alterações salvas, você pode criar um orçamento com o modelo, simulando a experiência do representante de vendas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Em sua conta da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vendas > Orçamentos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar orçamento"
        }), ". Você será redirecionado para um assistente de criação de orçamentos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na primeira tela, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Associar com um negócio"
        }), " e, em seguida, selecione um negócio existente ou selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar um novo negócio"
        }), " se quiser usar um negócio de teste."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto inferior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Próximo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na tela seguinte, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Orçamento"
        }), " e selecione seu modelo de orçamento personalizado."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Prossiga pelo resto do assistente para criar seu orçamento."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Depois de publicar o orçamento, uma caixa de diálogo será exibida com um link para visualizá-lo. Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar"
        }), " para copiar a URL e cole no navegador para visualizar o orçamento concluído."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-quote-url.png",
        alt: "copy-quote-url"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Próximas etapas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com seu modelo de orçamento criado, carregado e personalizado, você deve ter uma melhor compreensão do processo de edição do modelo de orçamento e da experiência do criador do conteúdo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "À medida que você cria modelos de orçamento para atender às suas necessidades de negócios, é possível tentar adicionar seus próprios módulos personalizados ao orçamento, juntamente com os módulos padrão da HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observe:"
        }), " é recomendável ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " editar o JavaScript dos módulos de pagamento, assinatura e download, pois isso pode levar à quebra dos módulos. Se estiver danificado, o usuário final pode não conseguir assiná-lo, baixá-lo ou até mesmo fazer um pagamento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/quotes",
          children: "Personalizar modelos de orçamento"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/hubl/variables/quotes",
          children: "Referência de variável de orçamento personalizado"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/pt/deals/create-custom-quote-templates-beta",
          children: "Criar e usar modelos de orçamento personalizados (do ponto de vista de vendas, operações de vendas/gerente)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}