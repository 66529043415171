"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816620914;
const slug = exports.slug = 'reference/api/analytics-and-events/tracking-code';
const title = exports.title = 'Resumen de la API de código de seguimiento';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Tracking code';
const metaDescription = exports.metaDescription = 'El punto de terminación de analíticas web te permite buscar y filtrar eventos asociados con un objeto de CRM de cualquier tipo. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "identificaci%C3%B3n-de-contactos",
  "label": "Identificación de contactos",
  "parent": null
}, {
  "depth": 0,
  "id": "identificar-a-un-visitante",
  "label": "Identificar a un visitante",
  "parent": null
}, {
  "depth": 0,
  "id": "seguimiento-en-aplicaciones-single-page",
  "label": "Seguimiento en aplicaciones single-page",
  "parent": null
}, {
  "depth": 1,
  "id": "establecer-ruta-de-p%C3%A1gina",
  "label": "Establecer ruta de página",
  "parent": "seguimiento-en-aplicaciones-single-page"
}, {
  "depth": 1,
  "id": "seguimiento-de-vista-de-p%C3%A1gina",
  "label": "Seguimiento de vista de página",
  "parent": "seguimiento-en-aplicaciones-single-page"
}, {
  "depth": 0,
  "id": "pol%C3%ADtica-de-privacidad-en-el-seguimiento",
  "label": "Política de privacidad en el seguimiento",
  "parent": null
}, {
  "depth": 0,
  "id": "obtener-par%C3%A1metros-de-enlace-entre-dominios",
  "label": "Obtener parámetros de enlace entre dominios",
  "parent": null
}, {
  "depth": 0,
  "id": "aplicar-otra-vez-los-manejadores-de-eventos-de-anal%C3%ADticas",
  "label": "Aplicar otra vez los manejadores de eventos de analíticas",
  "parent": null
}, {
  "depth": 0,
  "id": "seguimiento-de-eventos-de-comportamiento-personalizados-marketing-hub-enterprise-%C3%BAnicamente",
  "label": "Seguimiento de eventos de comportamiento personalizados (Marketing Hub Enterprise únicamente)",
  "parent": null
}, {
  "depth": 1,
  "id": "trackcustombehavioralevent",
  "label": "trackCustomBehavioralEvent",
  "parent": "seguimiento-de-eventos-de-comportamiento-personalizados-marketing-hub-enterprise-%C3%BAnicamente"
}, {
  "depth": 1,
  "id": "personaliza-tu-c%C3%B3digo-de-seguimiento-para-enviar-datos-de-eventos-de-comportamiento-personalizados",
  "label": "Personaliza tu código de seguimiento para enviar datos de eventos de comportamiento personalizados",
  "parent": "seguimiento-de-eventos-de-comportamiento-personalizados-marketing-hub-enterprise-%C3%BAnicamente"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Resumen de la API de código de seguimiento"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Esta página se ha actualizado para trabajar con nuevos eventos de comportamiento personalizados. Para eventos personalizados preexistentes, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code/v1",
          children: "consulta la documentación preexistente"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además de hacer seguimiento a las vistas de página, el código de seguimiento de HubSpot te permite identificar visitantes, hacer seguimiento a eventos y hacer seguimiento manualmente a las vistas de página sin volver a cargar la página. La API de código de seguimiento te permite crear eventos dinámicamente y hacer seguimiento a los datos de eventos en HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tu sitio usa el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-enable-a-privacy-policy-alert-if-you-are-doing-business-in-europe",
        children: "banner de consentimiento de privacidad"
      }), ", obtén información sobre cómo administrar las cookies que se agregan al navegador de un visitante con la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "API del banner de cookies"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las llamadas de función se introducen en la matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), ": Por ejemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', { path string }])\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En este artículo, obtén información sobre cómo puedes agregar funciones al código de seguimiento para identificar a los visitantes, realizar un seguimiento de las vistas de página, enviar datos de eventos y más."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identificación de contactos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La herramienta de analíticas de HubSpot identifica los contactos usando dos datos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El usertoken, que se almacena en la cookie del navegador ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspotutk"
        }), " del visitante."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La dirección de email registro del contacto."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando el código de seguimiento de HubSpot hace seguimiento a una acción de un visitante, se asocia automáticamente esa acción con el usertoken del visitante. Cuando usas la API de código de seguimiento para identificar a un visitante por la dirección de correo electrónico, el sistema de analíticas vinculará ese correo electrónico con el valor usertoken, lo que permite a HubSpot actualizar un registro de contacto existente o crear uno nuevo. Los datos de analíticas asociados con el usertoken, como las vistas de página y la fuente original, aparecerán en el registro de contacto."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando conoces la identidad de un visitante (por ejemplo, la dirección de correo electrónico), puedes usar la función de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identify-a-visitor",
        children: "identificación"
      }), " para establecer identidades en el rastreador. Luego puedes enviar la identidad a HubSpot haciendo una llamada separada a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events-js-api",
        children: "o trackCustomBehavioralEvent"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando uses esta función, recuerda:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Evita usar un correo marcador de posición de correo para visitantes desconocidos, ya que HubSpot creará un registro de contacto con ese correo marcador de posición y la cookie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "usertoken"
        }), " del visitante se asociará con ese registro. Esto lleva a que todos los visitantes desconocidos se asocien con tu contacto de marcador de posición."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si estableces una identidad en un registro de contacto y tiene alguna propiedad que sea única, eliminaremos cualquier propiedad de la identidad que viole la unicidad."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identificar a un visitante"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si tu cuenta se creó antes del 8 de septiembre de 2021 y está configurada para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/prevent-contact-properties-update-through-tracking-code-api",
          children: "permitir que las propiedades de contacto se actualicen a través del código de seguimiento"
        }), ", también puedes incluir otras propiedades de contacto que se actualizarán con esta función. Para las cuentas creadas después del 8 de septiembre de 2021, esta funcionalidad no está en uso."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"identify\", { {identity details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usa este punto de terminación para identificar a los visitantes y contactos del sitio web."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visitante:"
        }), " se refiere a cualquier visitante del sitio web, independientemente de si es un contacto de HubSpot. HubSpot no crea registros para los visitantes como lo hace para los contactos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contacto:"
        }), " se refiere a un visitante o contacto fuera de línea que tiene un registro en HubSpot. Un contacto puede identificarse por su dirección de correo electrónico única."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para identificar manualmente a un visitante o contacto, puedes usar una dirección de correo electrónico o un ID externo único:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "correo electrónico:"
        }), " identifica a un visitante por dirección de correo electrónico cuando desees actualizar un contacto existente o crear uno nuevo. Si existe un contacto con ese correo electrónico en HubSpot, su registro de contacto se actualizará con los datos de analíticas. Si no existe ningún contacto en esa dirección de correo electrónico, se creará un nuevo registro."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "id:"
        }), " un ID externo personalizado que identifica al visitante. Identificar a un visitante con este ID asociará los datos de analíticas a ese visitante. Sin embargo, usar el ID solo no creará un contacto en HubSpot. Los datos de analíticas solo se pueden asociar con un contacto existente a través de este método cuando:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "el visitante fue identificado previamente tanto por ID como por correo electrónico."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "el visitante fue identificado previamente por ID y también tiene un envío de formulario asociado con su registro."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " este ID externo solo se puede usar con el código de seguimiento de HubSpot. Este ID no se puede usar para recuperar o actualizar cualquier registro a través de cualquier otra herramienta o API de HubSpot. Si conoces la dirección de correo electrónico del visitante, se recomienda usarla como identificador único. Del mismo modo, solo debes identificar a un visitante por ID cuando no conozcas su dirección de correo electrónico."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Si has enviado previamente datos de analíticas a HubSpot utilizando solo el ID del visitante, puedes incluir más tarde tanto el ID como una dirección de correo electrónico para asociar los datos de ese ID con un contacto. El contacto existente se actualizará o creará si no existe ningún contacto actualmente."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nThe below example gets the value of a query string parameter '?email='\nand uses that to identify the visitor\n*/\n\nfunction getParameterByName(name) {\n  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);\n  return match && decodeURIComponent(match[1].replace(/\\+/g, ' '));\n}\n\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n  },\n]);\n\n/*\nThe below example sets the email,\nas well as a custom property favorite_color\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n    favorite_color: 'orange',\n  },\n]);\n\n/*\nThe below example sets the email and a custom external ID for the visitor. \nThis assumes that your site includes a variable named \n'user' that includes that user's ID in an 'id' \nproperty, and their email in an 'email' property.\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: user.email,\n    id: user.id,\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al usar esta función, ten en cuenta lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Esta función almacena los datos en el rastreador, pero en realidad, los datos no se pasan a HubSpot con esta llamada. Los datos solo se pasarán cuando se realice un seguimiento de una visita de página o un evento (con las funciones ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#track-page-view",
          children: "trackPageView"
        }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackEvent"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un contacto solo puede tener una identificación y/o dirección de correo electrónico asociada con ellos. Si intentas asignar dos ID a un correo electrónico, solo se asociará el primer ID con la dirección de correo electrónico."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Debes incluir una dirección de correo electrónico para vincular los datos a un contacto."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si tu cuenta se creó antes del 8 de septiembre de 2021 y está configurada para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/prevent-contact-properties-update-through-tracking-code-api",
          children: "permitir que las propiedades de contacto se actualicen a través del código de seguimiento"
        }), ", también puedes incluir otras propiedades de contacto que se actualizarán con esta función."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Esta función ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "no"
        }), " restaurará contactos eliminados previamente. Estos contactos deben ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/restore-deleted-contacts-companies-deals-or-tickets",
          children: "restaurarse en HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Seguimiento en aplicaciones single-page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El código de seguimiento de HubSpot registrará automáticamente una vista de página cuando el código se carga por primera vez, pero también puedes hacer seguimiento manualmente de las vistas de página en una aplicación single-page sin volver a cargar el código de seguimiento. Puedes usar las funciones ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-path",
        children: "setPath"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), " para actualizar y hacer seguimiento a la página actual: Por ejemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<!-- Set up the path for the initial page view -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/home']);\n</script>\n\n<!-- Load the HubSpot tracking code -->\n<!-- Start of HubSpot Embed Code -->\n  <script type=\"text/javascript\" id=\"hs-script-loader\" async defer src=\"//js.hs-scripts.com/{hubId}.js\">\n</script>\n<!-- End of HubSpot Embed Code -->\n\n<!-- Tracking subsequent page views -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/about-us']);\n  _hsq.push(['trackPageView']);\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Establecer ruta de página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsq.push(['setPath', { path string }])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Actualizar la ruta de la página actual almacenada en el rastreador. Esta función debe usarse en aplicaciones single-page para actualizar la página actual cuando se carga una página. Después de usar esta función para actualizar la ruta, deberás llamar a la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "función trackPageView"
      }), " para hacer seguimiento a la vista de la página actual."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las aplicaciones single-page deben mover una llamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), " antes de que se cargue el código de seguimiento para establecer la URL a la que se hace seguimiento durante la primera vista de página. Consulta la sección de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "vista de página de seguimiento"
      }), " a continuación para obtener un ejemplo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando llames a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), ", incluirás la ruta de la página actual. La ruta establecida será tratada como relativa al dominio que se está viendo actualmente. La ruta siempre debe comenzar con una barra diagonal. Si tu URL también contiene parámetros, estos deben incluirse en la ruta. Ve el código anterior para ejemplos."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Al usar esta función, ten en cuenta lo siguiente:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Cualquier ruta establecida usando la función ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " anulará los datos en el encabezado del referente. Si llamas a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " una vez, tendrás que usar ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " para actualizar la ruta para cada vista que deseas hacer seguimiento."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las llamadas repetidas a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " anularán al remitente, lo que puede afectar a la fuente original de un contacto, dependiendo de cuándo se realice una solicitud de seguimiento."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Esta función solo puede actualizar la ruta de la URL. El dominio se establece automáticamente según la URL de la página de carga y la ruta que se establece usando esta función siempre se trata como relativa a ese dominio detectado."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// These examples assume that the domain of the site is\n// www.mydomain.com\n\n// Set the path to '/' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/\n\n// Set the path to '/contact-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/contact-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/contact-us\n\n// Set the path to '/blog/post?utm_campaign=my-campaign' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/blog/post?utm_campaign=my-campaign']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/blog/post?utm_campaign=my-campaign\n\n// Set the path to '/#/about-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/about-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/#/about-us\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seguimiento de vista de página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['trackPageView']);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Haz un seguimiento de la vista de página para la página actual. Esta función se llama cuando el código de seguimiento se carga en una página, pero puedes llamar manualmente a esta función para hacer seguimiento de las vistas subsiguientes en una aplicación de una página."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " llamar a esta función manualmente antes de o durante la carga de la página inicial podría conducir a hacer seguimiento de vistas duplicadas."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta función no admite ningún argumento. El título de la página al que se hace seguimiento será el valor actual de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "document.title"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La URL que se rastrea se basa en uno de los siguientes elementos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La ruta establecida usando la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "función setPath"
        }), ". Si el sitio es creado como una aplicación single-page, esta función es el método preferido para configurar la ruta a la que se hace seguimiento. Consulta la sección ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), " arriba para descargos de responsabilidad sobre la función."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), " no se ha llamado nunca, la URL con seguimiento será el encabezado HTTP del referente en la solicitud que el navegador del visitante debe hacer en los servidores de seguimiento de HubSpot (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/API/History_API#Adding_and_modifying_history_entries",
          children: "modificar el estado del historial del navegador"
        }), " actualizaría el valor utilizado para ese encabezado). El encabezado del referente no se admitirán los fragmentos de URL (cualquier cosa después del #en la URL), ya que los fragmentos no están incluidos en el encabezado del referente."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// Track a new page using setPath:\n// Update the path stored in the tracker:\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/new-page']);\n// Track the page view for the new page\n_hsq.push(['trackPageView']);\n\n\n// Track a new page by updating the browser state:\n// Update the browser state, showing \"updated.html\" in the browser address bar\nvar stateObj = { foo: 'updated' };\nhistory.pushState(stateObj, \"updated page\", \"updated.html\");\n//Track the page view for the new page, '/updated.html'\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['trackPageView']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ten en cuenta lo siguiente cuando uses esta configuración:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si bien no puedes evitar que esta función se llame automáticamente cuando se cargue el código de seguimiento, puedes controlar la URL grabada para la página moviendo una llamada a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "_hsq"
        }), " antes de que se cargue el código de seguimiento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si tu sitio es una aplicación single-page, debes evitar incluir una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), " en tu página. Si tu página usa etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), ", deberás usar la función ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), " para actualizar la ruta de tus nuevas páginas, ya que la URL canónica establecida en la etiqueta de enlace anulará cualquier URL detectada si solo estás actualizando el estado del historial del navegador."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Política de privacidad en el seguimiento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tu sitio tiene un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-enable-a-privacy-policy-alert-if-you-are-doing-business-in-europe",
        children: "banner de consentimiento de privacidad,"
      }), " puedes usar funciones para verificar y administrar las cookies colocadas en el navegador del visitante. Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "administrar cookies de banner de consentimiento de privacidad"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtener parámetros de enlace entre dominios"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['addIdentityListener', function(hstc, hssc, hsfp) {}])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El código de seguimiento de HubSpot se puede usar en varios sitios con dominios separados. Esta función te permitirá obtener los parámetros de consulta necesarios para crear enlaces que te permitirán hacer seguimiento a tus visitantes en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/set-up-your-site-domains#track-multiple-domains-with-cross-domain-linking",
        children: "esos dominios separados"
      }), ". Estos parámetros de consulta utilizan el código de seguimiento de HubSpot para identificar a un visitante en varios dominios, garantizando que las cookies separadas para los dominios separados se combinen para un solo visitante con seguimiento. También puedes usar parámetros de consulta entre dominios en enlaces que se agregan dinámicamente a la página después de que se cargue el código de seguimiento."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los enlaces entre dominios solo son necesarios para enlazar a un dominio distinto (por ejemplo, dominio-uno*.com* y ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "dominio-dos.com"
      }), ") al que también se le está siendo haciendo seguimiento en una única cuenta de HubSpot. No necesitas parámetros de enlace entre dominios al realizar un seguimiento de las visitas entre subdominios (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www",
        children: "www"
      }), ".", (0, _jsxRuntime.jsx)(_components.em, {
        children: "dominio-uno.com"
      }), " y ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "blog.dominio-uno.com"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Get the cross-domain query parameters and store them in a string,\n//so that they can be appended to links as needed.\n_hsq.push([\n  'addIdentityListener',\n  function (hstc, hssc, hsfp) {\n    // Add these query parameters to any links that point to a separate tracked domain\n    crossDomainTrackingParams =\n      '&__hstc=' + hstc + '&__hssc=' + hssc + '&__hsfp=' + hsfp;\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aplicar otra vez los manejadores de eventos de analíticas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['refreshPageHandlers'])"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta función aplica nuevamente los manejadores de eventos de analíticas que se configuraron en la configuración de analíticas para la cuenta de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esto incluiría la aplicación de cualquier ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events/create-clicked-element-events",
        children: "evento de elemento seleccionado"
      }), " que se haya configurado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes usar esta función para volver a aplicar automáticamente los manejadores de clics cuando se actualiza el contenido de la página, como actualizar una sección de contenido o mostrar una ventana modal en la página."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " esta funcionalidad se activa automáticamente como parte de la función ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "setPath"
          })
        }), " , por lo que solo tendrás que usar esta función al actualizar el contenido sin actualizar la URL de la página a la que se hace seguimiento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Reapply event handlers after updating the page content\n_hsq.push(['refreshPageHandlers']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Seguimiento de eventos de comportamiento personalizados (Marketing Hub Enterprise únicamente)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando eventos personalizados de comportamiento, puedes vincular las terminaciones de eventos a los registros de contactos y rellenar las propiedades del evento con metadatos sobre el evento. Para recuperar eventos de comportamiento personalizados, usa la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "API de analíticas web"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A través de la API, los eventos se pueden activar utilizando el nombre interno del evento, que se asigna automáticamente cuando se crea el evento. Puedes encontrar el nombre interno de un evento ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "en HubSpot"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/reporting#get-events",
        children: "usando la API de eventos"
      }), ". Descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "encontrar el nombre interno de un evento"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Imported%20sitepage%20images/custom-event-internal-name.png",
        alt: "custom-event-internal-name"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes crear tres tipos de eventos en HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "Eventos de elementos con clics"
        }), ": eventos vinculados a elementos en los que se puede hacer clic en una página de sitio web. Se rellenará automáticamente un conjunto de propiedades de evento de HubSpot predeterminadas a través del código de seguimiento. Puedes personalizar más estos eventos con la función ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "Eventos de URL visitada"
        }), ": eventos vinculados a cargas de página en URL específicas. Se rellenará automáticamente un conjunto de propiedades de evento de HubSpot predeterminadas a través del código de seguimiento. Puedes personalizar más estos eventos con la función ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "Eventos de comportamiento con seguimiento manual"
        }), ": eventos personalizados que son exclusivos de tu empresa, además de eventos que HubSpot o una integración pueden no capturar automáticamente. Envía datos manualmente a eventos de HubSpot a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "API HTTP"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para cada tipo de evento, HubSpot incluye un conjunto de propiedades estándar que pueden capturar ciertos metadatos en el momento de la finalización, incluidos los parámetros UTM o los metadatos del dispositivo y del sistema operativo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dado que esta función trabaja en conjunto con el seguimiento de analíticas de HubSpot, cualquier evento que se desencadene a través de la API de JavaScript se asociará automáticamente con la cookie de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "hubspotutk"
      }), " del visitante, por lo que el evento ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identities-overview",
        children: "se vincularía automáticamente al contacto asociado con esa ficha de usuario"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "trackCustomBehavioralEvent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"trackCustomBehavioralEvent\", { {event details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usa esta función para hacer seguimiento a un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events-user-guide-v2/a-quick-tour-of-events",
        children: "evento"
      }), " usando el código de seguimiento de JavaScript y HubSpot. Puedes usar eventos para hacer seguimiento a actividades específicas completadas por visitantes en tu sitio. Los eventos con seguimiento pueden aparecer en las cronologías de los contactos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nExample code to fire a custom behavioral event using the name \"clicked Buy Now button\" when a visitor clicks an element with the 'buyNow' id.\n*/\n\ndocument.getElementById(\"buyNow\").onclick = function() {_hsq.push([\"trackCustomBehavioralEvent\", {\n   name: \"pe123456_course_registration\",\n   properties: {\n       course_id: \"Math101\"\n   }\n}]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Argumentos"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Cómo utilizarlo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "name:\"internal_name\""
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El event_id o el nombre interno del evento que creaste en HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "property_name: \"property_value\""
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista de pares clave-valor, con un par clave-valor por propiedad.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_name"
            }), " es el nombre interno de la propiedad del evento que has creado para el evento, y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_value"
            }), " es el valor que se agregará a la propiedad. También puedes hacer seguimiento a las propiedades no definidas y volver a crearlas después del seguimiento de eventos."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Personaliza tu código de seguimiento para enviar datos de eventos de comportamiento personalizados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por opción predeterminada, HubSpot crea un conjunto de propiedades para cada evento que crees. Para los eventos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "elementos con clics"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "URL visitada"
      }), ", HubSpot rellenará automáticamente algunas de esas propiedades con datos. Pero también puedes personalizar tu código de seguimiento para enviar datos a las propiedades del evento."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Informes"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Herramientas de Analíticas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eventos de comportamiento personalizados"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " del evento al que que deseas hacerle seguimiento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Propiedades"
        }), ", copia el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre interno"
        }), " del evento."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-clicked-element-event-internal-name0.png",
        alt: "custom-clicked-element-event-internal-name0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Luego, en la tabla de propiedades, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre"
          }), " de la propiedad del evento a la que deseas enviar datos."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el panel derecho, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ícono de origen </>"
          }), " para ver el nombre interno de la propiedad. Usarás este nombre al personalizar el código de seguimiento."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-behavioral-event-property-internal-name0.png",
            alt: "custom-behavioral-event-property-internal-name0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Ahora que tienes los datos de tu evento y de la propiedad del evento, haz clic en el icono ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Configuración"
          }), " para navegar a la configuración de tu cuenta. En el menú de la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Seguimiento y analíticas"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Código de seguimiento"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Personalizar javascript"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/tracking-code-customize-javascript0.png",
            alt: "tracking-code-customize-javascript0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Agregar JavaScript personalizado"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la barra lateral derecha, escribe un ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre"
          }), " para tu Javascript personalizado y luego escribe el Javascript, incluyendo la función ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#events-js-api",
            children: "trackCustomBehavioralEvent"
          }), ". Este JavaScript se ejecutará después de que se cargue el código de seguimiento de una página."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example usage\n_hsq.push(['trackCustomBehavioralEvent',{\n   name: '((behavioral_event_internal_name))”,\n   properties: { internal_property_name: property_value}\n}\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, si tu evento hace seguimiento de un registro de curso cuando se hace clic en un botón con el ID de HTML ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "register_for_econ101"
      }), ", tu JavaScript podría tener el siguiente aspecto: ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/customize-tracking-code0-1.png",
        alt: "customize-tracking-code0-1"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Guardar"
        }), " para guardar tu JavaScript. Tu código de seguimiento ahora se cargará con tu JavaScript personalizado."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}