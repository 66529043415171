'use es6';

import { stringify } from 'hub-http/helpers/params';
import namespacedKeyMirror from '../../lib/utils/namespacedKeyMirror';
import { ACTIONS, EVENTS } from '../../tracking/events';
import * as TryItClient from './api';
import { TrackingAuthTypes } from './constants';
import { getAuth } from './utils/authUtils';
import { getRequestBody } from './utils/requestUtils';
import { getHeader } from './utils/responseUtils';
export const TryItActionTypes = namespacedKeyMirror({
  SEND_REQUEST_STARTED: null,
  SEND_REQUEST_FINISHED: null
}, 'tryIt');
const createSendRequestResponseActionType = ({
  resp,
  har,
  docId,
  authType
}) => dispatch => {
  const request = har.log.entries[0].request;
  const headers = resp.headers;
  const contentDisposition = getHeader(headers, 'content-disposition');
  let contentType;
  try {
    contentType = getHeader(headers, 'content-type').split(';')[0];
  } catch (err) {
    contentType = null;
  }
  const response = {
    method: request.method,
    requestHeaders: request.headers.reduce((acc, header) => {
      acc[header.name] = header.value;
      return acc;
    }, {}),
    requestBody: getRequestBody(har),
    responseHeaders: headers,
    type: contentType,
    isBinary: !!(contentDisposition && contentDisposition.match(/attachment/)),
    url: request.url + stringify(request.queryString),
    status: resp.status,
    responseBody: resp.responseJSON || resp.responseText
  };
  dispatch({
    type: TryItActionTypes.SEND_REQUEST_FINISHED,
    payload: {
      response,
      docId
    },
    meta: {
      '@@hublytics': {
        eventKey: EVENTS.TEST_CALL,
        eventProperties: {
          action: ACTIONS.MAKE_TEST_CALL,
          authType: TrackingAuthTypes[authType] || TrackingAuthTypes.NO_AUTH,
          endpoint: request.url,
          method: request.method.toUpperCase(),
          status: resp.status
        }
      }
    }
  });
};
export function sendRequest({
  authDetails: updatedAuthDetails,
  docId
}) {
  return (dispatch, getState) => {
    const auth = updatedAuthDetails ? getAuth({
      authDetails: updatedAuthDetails,
      oas: getState().doc.get('oas')
    }) : getState().tryIt.get('auth');
    const authType = updatedAuthDetails ? updatedAuthDetails.authType : getState().tryIt.get('authType');
    dispatch({
      type: TryItActionTypes.SEND_REQUEST_STARTED,
      payload: Object.assign({
        docId
      }, updatedAuthDetails && {
        authDetails: updatedAuthDetails,
        auth
      })
    });
    const har = getState().doc.getIn(['docDetails', docId, 'har']);
    return TryItClient.fetchHar(har).then(resp => {
      dispatch(createSendRequestResponseActionType({
        resp,
        har,
        docId,
        authType
      }));
    }).catch(resp => {
      dispatch(createSendRequestResponseActionType({
        resp,
        har,
        docId,
        authType
      }));
    });
  };
}