"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 56556322830;
const slug = exports.slug = 'guides/cms/content/data-driven-content/graphql/build-a-recruiting-agency-using-graphql';
const title = exports.title = 'Build a recruiting agency website using GraphQL (BETA)';
const name = exports.name = 'Build a recruiting agency website using GraphQL (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to use GraphQL to power a recruiting agency website (BETA).';
const badge = exports.badge = 'BETA';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-and-associate-crm-custom-objects",
  "label": "Create and associate CRM custom objects",
  "parent": null
}, {
  "depth": 0,
  "id": "create-the-job-application-form",
  "label": "Create the job application form",
  "parent": null
}, {
  "depth": 0,
  "id": "set-up-a-workflow-for-submitted-applications",
  "label": "Set up a workflow for submitted applications",
  "parent": null
}, {
  "depth": 0,
  "id": "create-role-listing-and-detail-pages",
  "label": "Create role listing and detail pages",
  "parent": null
}, {
  "depth": 0,
  "id": "create-applications-page",
  "label": "Create applications page",
  "parent": null
}, {
  "depth": 0,
  "id": "edit-website-header-and-menu-items",
  "label": "Edit website header and menu items",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      em: "em",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Build a recruiting agency website using GraphQL (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["This functionality is in beta and requires your account to be ungated. You can request access by submitting ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/devday2021/private-betas",
          children: "this form"
        }), ". By participating in this beta you agree to HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developer-terms",
          children: "Developer Terms"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "Developer Beta Terms"
        }), ". Please note that this functionality is currently under development and is subject to change based on testing and feedback."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This tutorial will walk you through how to set up a recruiting agency website, showcasing how GraphQL queries can pull contact and custom object properties from the HubSpot CRM and use the data to power dynamic website pages."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All code referenced in this tutorial is available to browse in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/recruiting-agency-graphql-theme",
        children: "this GitHub repository"
      }), ". If you want to learn more about how GraphQL works and how to construct your own queries, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/data/use-graphql-to-query-your-hubspot-data",
        children: "GraphQL reference guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before you get started, make sure you’ve ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "installed the HubSpot CLI tools"
      }), ". After you’ve installed the CLI tools, create a new directory for this tutorial. Navigate into the new directory, and run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs init"
      }), " to configure the project."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create and associate CRM custom objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["First, you'll need to create ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Role"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Job Application"
      }), " custom objects and associate them so they can be queried from the corresponding website pages."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Download the schemas directory from the GitHub repository and copy it into your project directory."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using the HubSpot CLI tools, create the two custom objects by running the following commands in the schemas directory:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs custom-object schema create job_application.json"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs custom-object schema create role.json"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After creating both custom objects, create an association between them:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["On the command line, run ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs custom-object schema list"
            }), " to get the objectTypeId of each object."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Make a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " call using the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "objectTypeId"
            }), " of the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Role"
            }), " object to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/schemas/{objectTypeId}/associations"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the body of your request, enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "objectTypeId"
            }), " of the Role and Job Application objects as the values for the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "fromObjectId"
            }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "toObjectId"
            }), " fields. For example, if the objectTypeId of the Role object is 2-3206084, and the objectTypeId of Job Application is 2-3206072, then the request body would be:"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// body example\n{\n  \"fromObjectTypeId\": \"2-3206084\",\n  \"toObjectTypeId\": \"2-3206072\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Download the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/sample-graphql-theme/blob/master/data/role_data.json",
          children: "role_data.json file from GitHub"
        }), ", then populate the available roles by running the command ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs custom-object create role role_data.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, create company records for ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "HubSpot"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Spotify"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Tesla"
        }), ", then ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/associate-records#associate-individual-records",
          children: "manually associate"
        }), " each of the roles with the corresponding example company."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Before you start creating the assets and pages for the site, download the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/sample-graphql-theme/tree/master/src",
          children: "src directory from GitHub"
        }), ", then upload the theme to your HubSpot account by running the command ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload src sample-graph-ql-theme"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create the job application form"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After you've populated the job listings for your site, you can configure the job application form. This form will prompt users to select the role they're interested in, and submit a cover letter as part of their application."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before you create the form, update the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Job Application"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Role"
      }), " property settings to allow the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Role identifier"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Cover letter"
      }), " properties to be used in forms:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings icon"
        }), " in the top right."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Properties"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select an object"
        }), " dropdown menu, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Job Application properties"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hover over the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Cover letter"
        }), " property in the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Properties"
        }), " table, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Use in forms, and bots"
        }), " checkbox."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select an object"
        }), " dropdown menu again, select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Role properties"
        }), ", then repeat the same steps to allow the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Role identifier"
        }), " property to be used in forms."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/role-identifier-use-in-forms-checkbox-1.png",
        alt: "role-identifier-use-in-forms-checkbox-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Now that the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Cover letter"
        }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Role Identifier"
        }), " properties can be used in a form, you can create the form itself:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Lead Capture"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Forms"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Create form"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Embedded form"
            }), ", then click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Next"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Start"
            }), " to begin creating a form from a blank template."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click and drag the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "First name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Last name"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Phone number"
            }), " fields onto the form preview on the right to include them on your form."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the left panel, scroll down and click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Job Application properties"
            }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Role properties"
            }), " sections to expand them."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click and drag all of the fields under ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Job Application Information"
            }), ", along with the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Role identifier"
            }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Title"
            }), " fields under ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Role Information"
            }), ", onto your form."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Set the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Job title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Role identifier"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Title"
            }), " fields to be ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/forms/pass-contact-property-values-with-hidden-form-fields",
              children: "hidden"
            }), ", since these fields will be auto-populated by a workflow you'll set up in the next step. When you're done configuring your form, enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "name"
            }), " for the form, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Update"
            }), " in the top right."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/recruiting-agency-job-application-form-1.png",
        alt: "recruiting-agency-job-application-form-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up a workflow for submitted applications"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Now that you've created the job application form, you need to set up a workflow to define the default state of a submitted job application."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Automation"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workflows"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create workflow"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left panel, on the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Start from scratch"
        }), " tab, select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Job application-based"
        }), ", then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Set Enrollment Trigger"
        }), " to set the enrollment criteria:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the right sidebar, under ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Filter type"
            }), ", select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Job application"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Object ID"
            }), ", select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "is known"
            }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Apply filter"
            }), ". Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Save"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "+ plus"
        }), " icon to create an action.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the right sidebar, under ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Property management"
            }), ", select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Set property value"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Property to set"
            }), " dropdown menu, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Application Status"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Application Status"
            }), " dropdown menu, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Applied"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Save"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/job-application-submission-workflow.png",
        alt: "job-application-submission-workflow"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " for your workflow, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Review and publish"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enrollment"
        }), ", select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "No, only enroll contacts who meet the trigger criteria after turning the workflow on"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Turn on"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create role listing and detail pages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've created your workflow, you can create the pages where users can view roles and submit applications for jobs they're interested in."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create the role listing page:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website Pages"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website page"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Role listing - GraphQL"
        }), " template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, enter an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "internal name"
        }), " for the page."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Page title"
        }), ", then enter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "roles"
        }), " as the content slug."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish"
        }), " in the top right."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With your role listing page ready to go, create the role details page:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right of the website pages dashboard, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website page"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Role Details"
        }), " template, then enter an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "internal name"
        }), " for your page in the dialog box."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Role details - GraphQL"
        }), " template, then enter an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "internal page name"
        }), " in the dialog box."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Page title"
        }), ", then enter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "roles/role"
        }), " as the content slug."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish"
        }), " in the top right."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create applications page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With your role pages published, the next step is to create a page where an applicant can view their submitted applications and check on the status of each one."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Since this page will be private to each applicant, you'll need to create a membership list to handle registration. Learn more about how membership works in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "this article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create the membership list:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contacts"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lists"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create List"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " for your list, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "+ Add filter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, configure the filter:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Job Application properties"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Object ID"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Object ID"
            }), ", click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "dropdown menu"
            }), " and select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "is known"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Save Group"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/list-membership-job-application-filter.png",
        alt: "list-membership-job-application-filter"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save list"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When a job application is submitted, the associated contact will automatically be added to the list you created. You can now create the application listing page and the application details page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create the application listing page:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website Pages"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create page"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website page"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Application Listing - GraphQL"
        }), " template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "internal name"
        }), " for your page."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click and drag the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Existing Application Listing"
        }), " module into your template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "page title"
        }), ", then enter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-applications"
        }), " for the content slug."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Scroll down and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Advanced options"
        }), " to expand the section."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Control Audience Access for this page"
        }), ", select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private - Registration required"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select lists"
        }), " dropdown menu and select the membership list you created."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/job-application-listing-membership-settings.png",
        alt: "job-application-listing-membership-settings"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create the application details page:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Back on the website pages dashboard, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website page"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Application details - GraphQL"
        }), " template, then enter an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "internal name"
        }), " for the page."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click and drag the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Application Details"
        }), " module into your template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "page title"
        }), ", then enter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "application-details"
        }), " for the content slug."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Edit website header and menu items"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The final step is to finalize the website header and create a menu that links between each of the pages. All of the application and role pages share a header, so you can edit the global module and the changes will apply to each of the pages."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the website pages dashboard, hover over one of the pages you created and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "website header"
        }), ". In the dialog box, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Open in global content header"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left pane, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Content"
        }), " tab, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Primary navigation"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add two new menu items:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Add menu item"
            }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Add page link"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Enter ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Roles"
            }), " for the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Menu item label"
            }), ", then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Select page"
            }), " dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Roles listing page"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Repeat the same steps to add a menu item for the application listing page."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/updated-agency-navigation-menu.png",
        alt: "updated-agency-navigation-menu"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With your menu updated, your recruiting agency website is ready to go."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To test out the site, open a new incognito window and navigate to the URL of the role listing page. You can submit an application for a role and confirm that the associated application appears on the application listing page you created."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to review the GraphQL queries that power this example site, you can find them in the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "data-queries"
      }), " directory of the GitHub repository or you can navigate to the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "sample-graphql-theme"
      }), " in the design manager."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To learn more about GraphQL, check out the reference documentation ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/data/use-graphql-to-query-your-hubspot-data",
        children: "here"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}