"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45279037462;
const slug = exports.slug = 'guides/api/app-management/webhooks/overview';
const title = exports.title = 'API de Webhooks ';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Webhooks API';
const metaDescription = exports.metaDescription = 'Resumen para la API de Webhooks, que te permite suscribirte a eventos que suceden en una cuenta de HubSpot con tu integración instalada.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "alcances",
  "label": "Alcances",
  "parent": null
}, {
  "depth": 0,
  "id": "configuraci%C3%B3n-de-webhook",
  "label": "Configuración de webhook",
  "parent": null
}, {
  "depth": 1,
  "id": "administrar-la-configuraci%C3%B3n-de-tu-cuenta-de-desarrollador",
  "label": "Administrar la configuración de tu cuenta de desarrollador",
  "parent": "configuraci%C3%B3n-de-webhook"
}, {
  "depth": 1,
  "id": "administrar-la-configuraci%C3%B3n-a-trav%C3%A9s-de-api",
  "label": "Administrar la configuración a través de API",
  "parent": "configuraci%C3%B3n-de-webhook"
}, {
  "depth": 0,
  "id": "suscripciones-a-webhooks",
  "label": "Suscripciones a webhooks",
  "parent": null
}, {
  "depth": 1,
  "id": "crear-suscripciones-en-tu-cuenta-de-desarrollador",
  "label": "Crear suscripciones en tu cuenta de desarrollador",
  "parent": "suscripciones-a-webhooks"
}, {
  "depth": 1,
  "id": "crear-suscripciones-a-trav%C3%A9s-de-la-api",
  "label": "Crear suscripciones a través de la API",
  "parent": "suscripciones-a-webhooks"
}, {
  "depth": 1,
  "id": "obtener-suscripciones",
  "label": "Obtener suscripciones",
  "parent": "suscripciones-a-webhooks"
}, {
  "depth": 1,
  "id": "crear-una-nueva-suscripci%C3%B3n",
  "label": "Crear una nueva suscripción",
  "parent": "suscripciones-a-webhooks"
}, {
  "depth": 1,
  "id": "actualizar-una-suscripci%C3%B3n",
  "label": "Actualizar una suscripción",
  "parent": "suscripciones-a-webhooks"
}, {
  "depth": 1,
  "id": "eliminar-una-suscripci%C3%B3n",
  "label": "Eliminar una suscripción",
  "parent": "suscripciones-a-webhooks"
}, {
  "depth": 0,
  "id": "cargas-%C3%BAtiles-de-webhook",
  "label": "Cargas útiles de webhook",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminaciones-de-contactos-conforme-con-la-privacidad",
  "label": "Eliminaciones de contactos conforme con la privacidad",
  "parent": null
}, {
  "depth": 0,
  "id": "seguridad",
  "label": "Seguridad",
  "parent": null
}, {
  "depth": 0,
  "id": "reintentos",
  "label": "Reintentos",
  "parent": null
}, {
  "depth": 0,
  "id": "l%C3%ADmites",
  "label": "Límites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      em: "em",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Webhooks"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La API de Webhooks permite suscribirse a eventos que ocurren en una cuenta de HubSpot con la integración instalada. En lugar de hacer una llamada de API cuando ocurre un evento en una cuenta conectada, HubSpot puede enviar una solicitud HTTP a un punto de terminación que configures. Puedes configurar eventos suscritos en la configuración de tu aplicación o usar los puntos de terminación que se detallan a continuación. Los webhooks pueden ser más escalables que la interrogación frecuente por cambios, especialmente para aplicaciones con una gran base instalada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El uso de la API de Webhooks requiere de lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Debes configurar una aplicación de HubSpot para usar webhooks mediante la suscripción a los eventos sobre los que deseas recibir notificaciones y la especificación de una URL para enviar esas notificaciones. Consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/integration-platform-api-requirements",
          children: "documentación de requisitos previos"
        }), " para obtener más detalles sobre cómo crear una aplicación."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Debes implementar un punto de terminación de HubSpot disponible públicamente y seguro (HTTPS) para esa URL que pueda manejar las cargas útiles del webhook especificadas en esta documentación."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los webhooks se configuran para una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "aplicación de HubSpot"
      }), ", no para cuentas individuales. Cualquier cuenta que instale tu aplicación a través del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0",
        children: "flujo de OAuth"
      }), " se suscribirá a las suscripciones a webhook de la aplicación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes suscribirte a eventos de objetos de CRM, que incluyen contactos, empresas, negocios, tickets, productos y elementos de línea, así como eventos de conversación."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["También puedes ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
            children: "gestionar webhooks en una aplicación privada"
          }), ". En aplicaciones privadas, la configuración del webhook solo se puede editar en la configuración de la aplicación privada y actualmente no se puede editar a través de la API."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Nota: para suscribirte a los webhooks de conversaciones, necesitas acceder a la ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/conversations/inbox-and-messages",
            children: "bandeja de entrada de conversaciones y a las API de mensajes"
          }), ", que se encuentran actualmente ", (0, _jsxRuntime.jsx)("u", {
            children: "en versión beta"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alcances"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para usar webhooks para suscribirte a eventos de CRM, tu aplicación deberá estar configurada para requerir el alcance asociado que corresponde al tipo de objeto de CRM al que quieres suscribirte. Por ejemplo, si deseas suscribirte a eventos de contacto, debersá solicitar el alcance ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm.objects.contacts.read"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vas a crear suscripciones en la interfaz de usuario de configuración de tu aplicación pública, se te pedirá que agregues el ámbito requerido en el panel ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Crear nuevas suscripciones a webhook"
        }), " antes de que termines de crear tu suscripción."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vas a crear una suscripción realizando una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " al punto de terminación", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/webhooks/v3/{appId}/subscriptions"
        }), ", la respuesta incluirá un error que proporcionará el nombre del ámbito que tendrás que configurar en la interfaz de usuario de configuración de tu aplicación pública."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si tu aplicación ya está usando webhooks, no podrás eliminar ningún ámbito requerido por las suscripciones de webhook activas sin primero pausar y eliminar las suscripciones."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Puedes revisar los alcances requeridos para cada tipo de suscripción de webhook en la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#webhook-subscriptions",
          children: "siguiente tabla."
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consulta la documentación de OAuth para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0#scopes",
        children: "obtener más detalles sobre los alcances"
      }), " y la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0",
        children: "configuración de la URL de autorización"
      }), " para tu aplicación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuración de webhook"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de configurar tus suscripciones de webhook, debes especificar una URL a la que se enviarán esas notificaciones. Sigue las instrucciones de las secciones a continuación para obtener información sobre cómo configurar completamente las suscripciones para tu aplicación."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "La configuración de Webhook puede ser almacenada en cache hasta por cinco minutos. Cuando se realizan cambios en la URL del webhook, en los límites de concurrencia o en la configuración de suscripción, puede tardar hasta cinco minutos para ver tus cambios entren en vigencia."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "HubSpot establece un límite de concurrencia de 10 solicitudes al enviar datos de eventos de suscripción asociados con una cuenta que instaló tu aplicación. Este límite de concurrencia es el número máximo de solicitudes en curso que HubSpot intentará a la vez. Cada solicitud puede contener hasta 100 eventos."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Administrar la configuración de tu cuenta de desarrollador"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes administrar tu URL y el límite de supresión a través de la página de configuración de tu aplicación en tu cuenta de desarrollador"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu cuenta de desarrollador, navega al panel de tu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "aplicación"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre"
          }), " de la aplicación para la que deseas configurar los webhooks."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/app_id_list.png?width=600&name=app_id_list.png",
            alt: "app_id_list"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el menú de la barra lateral izquierda, ve a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Webhooks"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el campo ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "URL de destino"
          }), ", escribe la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " a la que HubSpot realizará una solicitud POST cuando se desencadenen eventos."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Utiliza la configuración de ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Supresión de eventos"
          }), " para ajustar el número máximo de eventos que HubSpot intentará enviar."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_settings.png?width=600&name=webhook_settings.png",
        alt: "webhook_settings"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Guardar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Administrar la configuración a través de API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes usar los siguientes puntos de terminación y tu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/faq/developer-api-keys",
        children: "clave de API de desarrollador"
      }), " para configurar de forma programática la configuración de webhook para una aplicación."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver cualquier configuración de webhook configurada actualmente para una aplicación, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Deberás incluir el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/faq/how-do-i-find-the-app-id",
        children: "ID de la aplicación"
      }), " en la solicitud, que puedes encontrar debajo del nombre de la aplicación en tu panel de control de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Aplicaciones"
      }), " o en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Autenticación"
      }), " en la configuración de tu aplicación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El objeto de configuración contiene los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "webhookUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La URL a la que HubSpot enviará notificaciones de webhook. Esta URL se debe notificar por HTTPS."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El límite de concurrencia para la URL del webhook. Este valor debe ser un número mayor que cinco."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para hacer ediciones a esta configuración, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), " e incluye los siguientes campos en el cuerpo de la solicitud:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "targetUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La URL disponible públicamente para que HubSpot llame donde se entregarán las cargas útiles de eventos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "throttling"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configurar los detalles de la supresión de webhooks en este objeto. El objeto de supresión incluye los campos ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Escala de tiempo para esta configuración. Puede ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SECONDLY"
            }), " (por segundo) o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ROLLING_MINUTE"
            }), " (por minuto)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El número máximo de solicitudes HTTP que HubSpot intentará hacer a tu aplicación en un plazo determinado por ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, el cuerpo de tu solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to https://api.hubapi.com/webhooks/v3/{appId}/settings\n\n{\n  \"throttling\": {\n    \"period\": \"SECONDLY\",\n    \"maxConcurrentRequests\": 10\n  },\n  \"targetUrl\": \"https://www.example.com/hubspot/target\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suscripciones a webhooks"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que hayas establecido las configuraciones de tu URL de webhook y del límite de supresión de eventos, deberás crear una o más suscripciones. Las suscripciones a webhooks le indican a HubSpot los eventos que te gustaría recibir a una aplicación en particular."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las suscripciones se aplican a todos los clientes que han instalado tu integración. Esto significa que solo debes especificar las suscripciones que necesitas una vez. Una vez que hayas activado una suscripción para una aplicación, comenzarás automáticamente a recibir webhooks para todos los clientes que hayan instalado tu aplicación y tu integración comenzará a recibir desencadenantes de webhook desde cualquier cliente nuevo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para todas las suscripciones de webhook ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationChange"
      }), " , el webhook activará dos eventos para ambos lados de la asociación."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Al asociar dos contactos, una suscripción a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), " activará dos eventos, que representan el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contacto 1 al contacto 2"
        }), " y el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contacto 2 al contacto 1"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Al asociar una empresa, si tienes dos suscripciones de webhook ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company.associationChange"
        }), ", recibirás dos eventos. Estos representarán ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "el contacto 1 a la empresa 1"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "la empresa 1 al contacto 1"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los siguientes tipos de suscripción son compatibles y se pueden usar como valor para el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " al crear suscripciones a través de API:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de suscripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Alcance requerido"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibir una notificación si se crea algún contacto en la cuenta de un cliente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se elimina algún contacto en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se combinó un contacto con otro."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un contacto tiene una asociación agregada o eliminada entre él y otro objeto de webhook compatible (contacto, empresa, negocio, ticket, elemento de línea o producto)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se restaura un contacto después de la eliminación."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Recibe una notificación si se elimina un contacto por ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview",
              children: "motivos de cumplimiento de la privacidad"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se cambia una propiedad específica para cualquier contacto en una cuenta."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se crea alguna empresa en la cuenta de un cliente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se elimina alguna empresa en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se cambia una propiedad específica para cualquier empresa en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si una empresa tiene una asociación agregada o eliminada entre ella y otro objeto de webhook compatible (contacto, empresa, negocio, ticket, elemento de línea o producto)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si una empresa se restaura después de la eliminación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibir una notificación si una empresa se fusionó con otra."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se crea un negocio en la cuenta de un cliente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se elimina algún negocio en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibir una notificación si un negocio tiene una asociación agregada o eliminada entre él y otro objeto de webhook compatible (contacto, empresa, negocio, ticket, elemento de línea o producto)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se restaura un negocio después de la eliminación."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un negocio se fusiona con otro."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se cambia una propiedad específica para cualquier negocio en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se crea un ticket en la cuenta de un cliente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se elimina algún ticket en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibir una notificación si se cambia una propiedad específica para cualquier ticket en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un ticket tiene una asociación agregada o eliminada entre él y otro objeto de webhook compatible (contacto, empresa, negocio, ticket, elemento de línea o producto)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se restaura un ticket después de su eliminación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un ticket se combinó con otro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se crea algún producto en la cuenta de un cliente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se elimina algún producto en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un producto se restaura después de su eliminación."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un producto se combinó con otro."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se cambia un producto específico para cualquier producto en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se crea algún elemento de línea en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se elimina algún elemento de línea en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un elemento de línea tiene una asociación agregada o eliminada entre él y otro objeto de webhook compatible (contacto, empresa, negocio, ticket, elemento de línea o producto)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un elemento de línea se restaura después de la eliminación."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un elemento de línea se combinó con otro."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se cambia una propiedad específica para cualquier elemento de línea en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los siguientes tipos de suscripción de conversaciones están disponibles para que te suscribas si estás usando la bandeja de entrada de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "conversaciones y la API de mensajes"
      }), ", que actualmente está ", (0, _jsxRuntime.jsx)("u", {
        children: "en versión beta"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de suscripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Alcance"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe notificaciones si se crea un nuevo hilo en una cuenta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un hilo se archiva o se elimina temporalmente en una cuenta."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si un hilo se elimina permanentemente en una cuenta."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se ha cambiado una propiedad en un hilo."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.newMessage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recibe una notificación si se ha recibido un nuevo mensaje en un hilo."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para las suscripciones de cambio de propiedad, deberás especificar la propiedad de la que deseas recibir notificaciones. Puedes especificar varias suscripciones de cambios de propiedad. Si la cuenta de un cliente no tiene la propiedad que especificas en una suscripción, no recibirás ningún webhooks de ese cliente para esa propiedad."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Algunas propiedades no están disponibles para suscripciones de cambio de propiedad de CRM. Estas propiedades son:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "num_unique_conversion_events"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_lastmodifieddate"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás usando los mensajes de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "conversación y la API de la bandeja de entrada"
      }), ", que se encuentra actualmente ", (0, _jsxRuntime.jsx)("u", {
        children: "en versión beta"
      }), ", las siguientes propiedades están disponibles:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "assignedTo"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " el hilo de conversación se ha reasignado o no asignado. Si se reasignó el hilo, el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " será un ID de actor en la carga útil de webhooks; si no se asigna, estará vacío."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "status"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " el estado del hilo de conversación ha cambiado. En la carga útil de webhooks, el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " estará ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OPEN"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CLOSED"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "isArchived"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " se ha restaurado el hilo de conversación. El valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " en la carga útil de webhooks siempre será ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "FALSE"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear suscripciones en tu cuenta de desarrollador"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes crear suscripciones de webhook en tu cuenta de desarrollador de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador de HubSpot, navega al panel de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de una aplicación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el menú de la barra lateral izquierda, ve a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear suscripción"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el panel derecho, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "¿Qué tipos de objetos?"
        }), " y selecciona los ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "objetos"
        }), " para los que deseas crear una suscripción."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "¿Escuchar cuáles eventos?"
        }), " y selecciona los ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tipos de eventos"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-contact-create-subscription.png",
        alt: "create-contact-create-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Si estás creando una suscripción para eventos de cambio de propiedad, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "¿Qué propiedades?"
          }), " y selecciona las ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "propiedades"
          }), " que quieres escuchar."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_select_properties.png?width=450&name=webhook_select_properties.png",
            alt: ""
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Suscribirse"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La suscripción aparecerá en la configuración de tus webhooks. Las nuevas suscripciones se crean en un estado en pausa, por lo que deberás activar la suscripción para que se envíen webhooks:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Suscripciones de eventos"
        }), ", coloca el cursor sobre el tipo de objeto y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ver suscripciones"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "casilla de comprobación"
        }), " junto al evento y, a continuación, en el encabezado de la tabla, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/activate-subscription.png",
        alt: "activate-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear suscripciones a través de la API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes crear suscripciones mediante programación usando los siguientes puntos de terminación. Deberás usar tu clave de API de desarrollador al realizar solicitudes a estos puntos de terminación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un objeto de suscripción puede incluir los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un número que representa el ID único de una suscripción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El tiempo en milisegundos en que se creó esta suscripción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de usuario asociado con el usuario que creó la suscripción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Esto indica si la suscripción está activada o no y si activa notificaciones de forma activa. El valor puede ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de suscripción. La ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview#webhook-subscriptions",
              children: "tabla"
            }), " al comienzo de esta sección incluye los tipos de suscripción disponibles."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de la propiedad en la que la suscripción escuchará los cambios. Esto solo es necesario para los tipos de suscripción de cambio de propiedad."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obtener suscripciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar la lista de suscripciones, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La respuesta será una matriz de objetos que representan tus suscripciones. Cada objeto incluirá información sobre la suscripción, como el ID, la fecha de creación, el tipo y si está habilitado actualmente. Así es como se vería un ejemplo de respuesta:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example GET request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n[\n  {\n    \"id\": 25,\n    \"createdAt\": 1461704185000,\n    \"createdBy\": 529872,\n    \"eventType\": \"contact.propertyChange\",\n    \"propertyName\": \"lifecyclestage\",\n    \"active\": false\n  },\n  {\n    \"id\": 59,\n    \"createdAt\": 1462388498000,\n    \"createdBy\": 529872,\n    \"eventType\": \"company.creation\",\n    \"active\": false\n  },\n  {\n    \"id\": 108,\n    \"createdAt\": 1463423132000,\n    \"createdBy\": 529872,\n    \"eventType\": \"deal.creation\",\n    \"active\": true\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear una nueva suscripción"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una nueva suscripción, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el cuerpo de la solicitud, puedes incluir los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El tipo de suscripción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de la propiedad en la que la suscripción escuchará los cambios. Esto solo es necesario para los tipos de suscripción de cambio de propiedad."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Esto indica si la suscripción está activada o no y si activa notificaciones de forma activa. El valor puede ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No necesitas incluir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdAt"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdBy"
      }), ", ya que esos campos se establecen automáticamente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, el cuerpo de tu solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n{\n  \"eventType\": \"company.propertyChange\",\n  \"propertyName\": \"companyname\",\n  \"active\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " debe ser un tipo de suscripción válido según se define en la sección anterior y el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyName"
      }), " debe ser un nombre de propiedad válido. Si un cliente no tiene una propiedad definida que coincida con este valor, esta suscripción no generará notificaciones."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Actualizar una suscripción"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para activar o pausar una suscripción, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el cuerpo de la solicitud, incluye lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Esto indica si la suscripción está activada o no y si activa notificaciones de forma activa. El valor puede ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eliminar una suscripción"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una suscripción, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cargas útiles de webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El punto de terminación de la URL de destino que especifiques en la configuración de webhooks de tu aplicación recibirá solicitudes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " que contienen datos con formato JSON de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asegurarte de que las solicitudes que recibes en tu punto de terminación de webhook provengan de HubSpot, HubSpot rellena un encabezado de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " con un hash SHA-256 creado con el secreto del cliente de tu aplicación combinado con los detalles de la solicitud. Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "validar firmas de solicitudes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utiliza las tablas a continuación para ver detalles sobre los campos que pueden estar contenidos en la carga útil."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID del objeto que fue creado, cambiado o eliminado. Para contactos, este es el ID de contacto; para empresas, el ID de empresa; para negocios, el ID de negocio; y para conversaciones el ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "ID de hilo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto solo se envía para las suscripciones de cambio de propiedad y es el nombre de la propiedad que se cambió."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyValue"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto solo se envía para suscripciones de cambio de propiedad y representa el nuevo valor establecido para la propiedad que desencadenó la notificación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "changeSource"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente del cambio. Puede ser cualquiera de las fuentes de cambios que aparecen en los historiales de las propiedades de contactos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID único del evento que desencadenó esta notificación. No se garantiza que este valor sea único."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de la suscripción que desencadenó una notificación sobre el evento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/manage-multiple-hubspot-accounts#check-your-current-account",
              children: "ID de cuenta de HubSpot"
            }), " del cliente donde ocurrió el evento."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de tu aplicación. Esto se usa en caso de que tengas varias aplicaciones que apunten a la misma URL de webhook."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuándo ocurrió este evento como una marca de tiempo de milisegundos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El tipo de evento para el que es esta notificación. Revisa la lista de tipos de suscripción admitidos en la sección de suscripción de webhooks anterior."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attemptNumber"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A partir de 0, el número de intentos es para notificar a su servicio de este evento. Si se vence el tiempo de espera de tu servicio o emite un mensaje de error, tal como se describe en la sección ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Reintentos"
            }), " a continuación, HubSpot intentará enviar la notificación de nuevo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto solo se envía cuando un webhook está escuchando nuevos mensajes en un hilo. Es el ID del nuevo mensaje."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Esto solo se envía cuando un webhook está escuchando nuevos mensajes en un hilo. Representa el tipo de mensaje que estás enviando. Este valor puede ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "MESSAGE"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMMENT"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del ganador de la combinación, que es el registro que permanece después de la combinación. En la interfaz de usuario de fusión de HubSpot, este es el registro de la derecha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mergedObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una matriz de identificadores que representan los registros que se fusionan en el ganador de la fusión. En la interfaz de usuario de fusión de HubSpot, este es el registro a la izquierda."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "newObjectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID del registro que se crea como resultado de la combinación. Esto es independiente de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            }), " porque en algunos casos se crea un nuevo registro como resultado de la combinación."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numberOfPropertiesMoved"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un entero que representa cuántas propiedades se transfirieron durante la combinación."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de asociación, que será una de las siguientes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_CONTACT"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_COMPANY"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_LINE_ITEM"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_DEAL"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_TICKET"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINE_ITEM_TO_DEAL"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del registro desde el que se realizó el cambio de asociación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del registro secundario en el evento de asociación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationRemoved"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un booleano que representa lo siguiente:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": el webhook se desencadenó al eliminar una asociación."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), ": el webhook se desencadenó al crear una asociación."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isPrimaryAssociation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un booleano que representa lo siguiente:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": el registro secundario es la ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/crm-setup/associate-records#primary-company-information",
                  children: "asociación primaria"
                }), " del registro del que se realizó el cambio de asociación."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), ": el registro ", (0, _jsxRuntime.jsx)("u", {
                  children: "no"
                }), " es la asociación primaria del registro del que se hizo el cambio de asociación. "]
              })]
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nota:"
            }), " la creación de una instancia de asociación primaria entre dos registros de objetos hará que también se cree la asociación no primaria correspondiente. Esto puede generar dos mensajes de webhook."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n[\n  {\n    \"objectId\": 1246965,\n    \"propertyName\": \"lifecyclestage\",\n    \"propertyValue\": \"subscriber\",\n    \"changeSource\": \"ACADEMY\",\n    \"eventId\": 3816279340,\n    \"subscriptionId\": 25,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.propertyChange\",\n    \"attemptNumber\": 0\n  },\n  {\n    \"objectId\": 1246978,\n    \"changeSource\": \"IMPORT\",\n    \"eventId\": 3816279480,\n    \"subscriptionId\": 22,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.creation\",\n    \"attemptNumber\": 0\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como se muestra anteriormente, debes esperar recibir una matriz de objetos en una sola solicitud. El tamaño del lote puede variar, pero tendrá menos de 100 notificaciones. HubSpot enviará varias notificaciones solamente cuando hayan ocurrido muchos eventos dentro de un período corto de tiempo. Por ejemplo, si te has suscrito a nuevos contactos y un cliente importa una gran cantidad de contactos, HubSpot te enviará las notificaciones de estos contactos importados en lotes y no una por solicitud."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot no garantiza que recibas estas notificaciones en el orden en que ocurrieron. Usa la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "occurredAt"
      }), " para cada notificación para determinar cuándo ocurrió el evento que desencadenó la notificación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además, HubSpot no garantiza que solo recibas una notificación para un evento. Aunque esto debería ser poco común, es posible que HubSpot te envíe la misma notificación varias veces."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminaciones de contactos conforme con la privacidad"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los usuarios de HubSpot pueden eliminar permanentemente un registro de contacto para cumplir con las leyes de privacidad. Más información sobre cómo realizar una eliminación conforme al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-do-i-perform-a-gdpr-delete-in-hubspot",
        children: "RGPD"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes suscribirte al tipo de suscripción ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact.privacyDeletion"
      }), " para recibir notificaciones de webhook cuando un usuario realice una eliminación de contactos conforme con la privacidad."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las notificaciones de eliminación conforme con la privacidad tienen un comportamiento especial"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un evento de eliminación conforme con la privacidad también desencadenará el evento de eliminación de contacto, por lo que recibirás dos notificaciones si estás suscrito a ambos eventos."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Estas notificaciones no se enviarán necesariamente en ningún orden en particular o en el mismo lote de mensajes. Deberás usar el objectId para que coincida con los mensajes separados."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Seguridad"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asegurarte de que las solicitudes que recibes en tu punto de terminación de webhook provienen de HubSpot, HubSpot llena un encabezado de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " con un hash SHA-256 de la concatenación del secreto de aplicación para tu aplicación y el cuerpo de la solicitud que estamos enviando."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para verificar esta firma, concatena el secreto de la aplicación de tu aplicación y el cuerpo de solicitud no interpretado de la solicitud que estás manejando y obtén un hash SHA-256 del resultado. Compara el hash resultante con el valor de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), ". Si estos valores coinciden, se confirma que esta solicitud es proveniente de HubSpot. O bien, la solicitud provino de otra persona que conoce el secreto de tu aplicación. Es importante mantener este valor en secreto."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si estos valores no coinciden, es posible que esta solicitud haya sido manipulada en tránsito o que alguien esté falsificando notificaciones de webhook a tu punto de terminación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "validación de solicitudes de firmas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reintentos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tu servicio tiene problemas para manejar las notificaciones en cualquier momento, HubSpot intentará volver a enviar notificaciones fallidas hasta 10 Veces."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot lo intentará nuevamente en los casos siguientes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Falló la conexión:"
        }), " HubSpot no puede abrir una conexión http a la URL de webhook proporcionada"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tiempo de espera:"
        }), " tu servicio demora más de 5 segundos en enviar una respuesta a un lote de notificaciones"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Códigos de error:**tu servicio responde con cualquier código de estado HTTP (4xx o 5xx)."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las notificaciones se volverán a intentar hasta 10 Veces. Estos reintentos se distribuirán durante las próximas 24 horas, con distintos retrasos entre las solicitudes. Las notificaciones individuales serán enviadas de manera aleatoria, para evitar que un gran número de errores concurrentes se vuelva a intentar en el mismo momento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las solicitudes de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " que HubSpot envía a tu servicio a través de tus suscripciones de webhook ", (0, _jsxRuntime.jsx)("u", {
        children: "no"
      }), " contarán para los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "límites de tasa de API de tu aplicación"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes crear un máximo de 1000 suscripciones por aplicación. Si intentas crear más, recibirá una solicitud incorrecta 400 con el siguiente cuerpo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"status\": \"error\",\n  \"message\": \"Couldn't create another subscription. You've reached the maximum number allowed per application (1000).\",\n  \"correlationId\": \"2c9beb86-387b-4ff6-96f7-dbb486c00a95\",\n  \"requestId\": \"919c4c84f66769e53b2c5713d192fca7\"\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}