"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831432;
const slug = exports.slug = 'reference/cms/fields/rich-text-editor';
const title = exports.title = 'Configurar o editor de rich text';
const name = exports.name = 'PT BR Configuring the Rich Text Editor';
const metaDescription = exports.metaDescription = 'O editor de rich text nos módulos personalizados agora permite que os desenvolvedores removam componentes da barra de ferramentas de configuração.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "como-usar-enabled_features",
  "label": "Como usar enabled_features",
  "parent": null
}, {
  "depth": 0,
  "id": "listagens-de-recursos",
  "label": "Listagens de recursos",
  "parent": null
}, {
  "depth": 1,
  "id": "grupos-de-controles",
  "label": "Grupos de controles",
  "parent": "listagens-de-recursos"
}, {
  "depth": 1,
  "id": "formata%C3%A7%C3%A3o-de-texto",
  "label": "Formatação de texto",
  "parent": "listagens-de-recursos"
}, {
  "depth": 1,
  "id": "bot%C3%B5es-inserir",
  "label": "Botões Inserir",
  "parent": "listagens-de-recursos"
}, {
  "depth": 1,
  "id": "op%C3%A7%C3%B5es-avan%C3%A7adas",
  "label": "Opções avançadas",
  "parent": "listagens-de-recursos"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      h2: "h2",
      pre: "pre",
      img: "img",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configurar o editor de rich text"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como desenvolvedor, em alguns casos, os editores da WYSIWYG fornecem funcionalidades que, quando usadas incorretamente, podem atrapalhar o objetivo de uma marca unificada e causar problemas de design e fluxo de conteúdo. O editor de rich text nos módulos personalizados agora permite que os desenvolvedores removam componentes da barra de ferramentas de configuração por meio da propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), " contida no arquivo fields.json."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Observação: o seguinte se aplica a módulos personalizados que utilizam o campo de rich text apenas para desenvolvimento local. O uso deste recurso não desativará a funcionalidade das opções removidas do editor de rich text, apenas a exibição das opções. Isso é devido a questões de compatibilidade com versões anteriores e, portanto, o conteúdo existente não é afetado."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Como usar enabled_features"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No arquivo fields.json que inclui o objeto de campo de rich text, você pode habilitar certos recursos adicionando as opções de barra de ferramentas válidas em um array na propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), ", conforme ilustrado abaixo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Rich text field with only Bold, Link, and Image available in the Toolbar\n{\n  \"name\": \"description\",\n  \"label\": \"Description\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"richtext\",\n  \"default\": null,\n  \"enabled_features\": [\"bold\", \"link\", \"image\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O editor de conteúdo veria o editor de rich text somente com as opções incluídas habilitadas, conforme ilustrado na imagem abaixo:"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Observação: alguns recursos, como o botão \"Limpar estilos\", que permite reverter para o estilo padrão do editor, sempre estão habilitados e não podem ser removidos. Se a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "enabled_features"
        }), " for omitida, todos os recursos serão exibidos."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/custom_rte_toolbar.jpg",
        alt: "Um exemplo de uma barra de ferramentas RTE com recursos habilitados."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listagens de recursos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja abaixo uma lista de recursos que podem ser habilitados individualmente ao usar a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Grupos de controles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opção"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controles de cor do texto e do fundo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fonts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Família de fontes e controles de tamanho de fonte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controles externos e de recuo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lists"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controles de listas numeradas e com marcadores."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_emphasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controles de negrito, itálico e sublinhado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "advanced_emphasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controles de formato riscado, sobrescrito, subscrito e código."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "glyphs"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Controles de emojis, caracteres especiais e ícones. Não compatível com módulos de e-mail. Para adicionar o seletor de emojis aos módulos de e-mail, use ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#insert-buttons",
              children: "emoji"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formatação de texto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opção"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "block"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o menu suspenso do seletor de estilo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_family"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o menu suspenso do alternador de fonte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_size"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o menu suspenso do tamanho da fonte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bold"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de negrito."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "italic"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de itálico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "underline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de sublinhado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de cor do texto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de cor do fundo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de alinhamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bulleted_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de lista com marcadores."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numbered_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de listas numeradas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lineheight"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de altura da linha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "outdent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de recuo à esquerda."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de recuo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "strikethrough"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de riscado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "superscript"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de sobrescrito."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscript"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de subscrito."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code_format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de formato de código."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Botões Inserir"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opção"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de link."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de imagem. Não compatível com módulos de e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emoji"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o botão de emojis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "personalize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de personalização da barra de ferramentas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cta"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item do menu Call to action no menu Inserir."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de menu Incorporar no menu Inserir."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de menu Vídeo no menu Inserir."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de menu Tabela no menu Inserir."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charmap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de menu Caracteres especiais no menu Inserir."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "anchor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de menu Âncora no menu Inserir."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hr"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de menu Linha horizontal no menu Inserir."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nonbreaking_space"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de menu Espaço não separável no menu Inserir."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de menu Ícones no menu Inserir."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Opções avançadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opção"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "source_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de menu Código-fonte no menu Avançado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "visual_blocks"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o item de menu Mostrar blocos no menu Avançado."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}