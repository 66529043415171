"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280489368;
const slug = exports.slug = 'guides/api/crm/objects/line-items';
const title = exports.title = 'API du CRM | Lignes de produit';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Line Items';
const metaDescription = exports.metaDescription = "Lorsqu'un produit est associé à une transaction, il devient une ligne de produit. Les points de terminaison des lignes de produit permettent de gérer et de synchroniser ces données.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-une-ligne-de-produit",
  "label": "Créer une ligne de produit",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-une-ligne-de-produit",
  "label": "Récupérer une ligne de produit",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-une-ligne-de-produit",
  "label": "Mettre à jour une ligne de produit",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-une-ligne-de-produit",
  "label": "Supprimer une ligne de produit",
  "parent": null
}, {
  "depth": 0,
  "id": "propri%C3%A9t%C3%A9s-des-lignes-de-produit",
  "label": "Propriétés des lignes de produit",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Lignes de produit"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans HubSpot, les lignes de produit sont des instances individuelles de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "produits"
      }), ". Lorsqu'un produit est associé à une transaction, il devient une ligne de produit. Vous pouvez créer des lignes de produit propres à un devis, qui ne seront pas ajoutées à votre bibliothèque de produits. Les points de terminaison des lignes de produit vous permettent de gérer ces données et de les synchroniser entre HubSpot et d'autres systèmes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de cas d'utilisation :"
      }), " lors de la création d'un ensemble de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes",
        children: "devis"
      }), " que les représentants commerciaux enverront à de potentiels acheteurs, vous pouvez utiliser cette API pour créer des lignes de produit autonomes pour chaque devis ainsi que des lignes de produit associées à des produits existants."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer une ligne de produit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un ligne de produit, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items"
      }), ". Dans le corps de l'article, incluez les détails de la ligne de produit, tels que le nom, la quantité et le prix."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer une ligne de produit basée sur un produit existant (créé via l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "API de produits"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
        children: "dans HubSpot"
      }), "), incluez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_product_id"
      }), " dans le corps de l'article."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également associer la ligne de produit avec des transactions, des devis, des factures, des liens de paiement ou des abonnements en incluant un tableau ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " dans le corps de l'article. Par exemple, le corps d'article ci-dessous créerait une ligne de produit intitulée « Nouvelle ligne de produit autonome » associée à une transaction (ID : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "12345"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"price\": 10,\n    \"quantity\": 1,\n    \"name\": \"New standalone line item\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 12345\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Les lignes de produit appartiennent à un seul objet parent. Si vous associez des objets, les lignes de produit doivent être individuelles à chaque objet. Par exemple, si vous créez une transaction et un devis, vous devez créer un ensemble de lignes de produit pour la transaction et un autre ensemble pour le devis. Cela aidera à rationaliser les données CRM entre les objets et à prévenir la perte de données inattendue lorsque vous devez modifier des lignes de produit (par exemple, la suppression d'un devis supprimera les lignes de produit du devis, et si ces lignes de produit sont associées à une transaction, les lignes de produit des transactions seront également supprimées)."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["L'élément ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "price"
          }), " spécifié dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "properties"
          }), " ", (0, _jsxRuntime.jsx)("u", {
            children: "ne peut pas"
          }), " être négatif."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["La propriété ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Durée"
          }), " des lignes de produit (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_recurring_billing_period"
          }), ") accepte les ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://docs.oracle.com/javase/8/docs/api/java/time/Period.html#:~:text=exceeds%20an%20int-,parse,-public%20static%C2%A0",
            children: "formats de période ISO-8601"
          }), " de PnYnMnD et PnW."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer une ligne de produit"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez récupérer des lignes de produit de façon individuelle ou en masse."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer une ligne de produit spécifique, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items/{lineItemId}"
        }), " où ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lineItemId"
        }), " est l'ID de la ligne de produit."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer toutes les lignes de produit, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez inclure les paramètres suivants dans l'URL de la requête :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer dans la réponse. Si l'une des propriétés spécifiées n'est pas présente sur le(s) objet(s) demandé(s), elle sera ignorée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer avec un historique de leurs précédentes valeurs. Si l'une des propriétés spécifiées n'est pas présente sur le(s) objet(s) demandé(s), elle sera ignorée."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour une ligne de produit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour mettre à jour une ligne de produit spécifique, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), " où ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), " est l'ID de la ligne de produit."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le corps d'article, incluez les valeurs de propriété que vous souhaitez mettre à jour. Vous ", (0, _jsxRuntime.jsx)("u", {
        children: "ne pouvez pas"
      }), " mettre à jour des associations via cette méthode. Vous devrez plutôt utiliser l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API des associations"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, votre corps de requête peut ressembler aux éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/line_item/{lineItemId}\n{\n  \"properties\": {\n    \"price\": 25,\n    \"quantity\": 3,\n    \"name\": \"Updated line item\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer une ligne de produit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une ligne de produit spécifique, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), " où ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), " est l'ID de la ligne de produit."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriétés des lignes de produit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la gestion de vos données de ligne de produit, vous pouvez utiliser certaines des propriétés communes dans le tableau ci-dessous. Pour obtenir toutes les propriétés des lignes de produit, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/line_item"
      }), ". Découvrez-en davantage sur l'utilisation de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API des propriétés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Libellé dans l'interface utilisateur"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nom"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom de la ligne de produit."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Description complète du produit"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "UGS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Identifiant de produit unique"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date de début de la facturation"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date de début d'une période de facturation fixe"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date de fin de la facturation"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date de fin d'une période de facturation fixe"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recurringbillingfrequency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fréquence de facturation"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fréquence à laquelle une ligne de produit avec facturation récurrente est facturé. Elle informe le calcul des prix pour les transactions et les devis. Les lignes de produit avec facturation unique ne sont pas incluses."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quantité"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Combien d'unités d'un produit sont incluses dans cette ligne de produit"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prix unitaire"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le coût du produit"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prix net"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le coût total de la ligne de produit (c'est-à-dire la quantité multipliée par le prix unitaire)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Devise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Code de devise pour la ligne de produit"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}