"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 2;
const slug = exports.slug = 'reference/api/cms/blogs/blog-topics/v3';
const title = exports.title = 'Blog Topics API - V3';
const name = exports.name = 'Blog Topics API - V3';
const metaDescription = exports.metaDescription = 'Blog Topics API - V3';
const orderedTitles = exports.orderedTitles = ['List blog topics', 'Search blog topics', 'Get a blog topic by ID', 'Create a new blog topic', 'Group blog topics', 'Update a blog topic', 'Delete blog topic'];
const orderedHttpMethods = exports.orderedHttpMethods = ['GET', 'GET', 'GET', 'POST', 'POST', 'PUT', 'DELETE'];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h4: "h4",
      p: "p",
      code: "code",
      a: "a",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      EndpointCard,
      EndpointLeftColumn,
      Endpoint,
      EndpointRequirements,
      EndpointParameters,
      EndpointParameter,
      EndpointRightColumn,
      EndpointExample,
      EndpointJSONFields
    } = _components;
  if (!Endpoint) _missingMdxReference("Endpoint", true);
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointJSONFields) _missingMdxReference("EndpointJSONFields", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameter) _missingMdxReference("EndpointParameter", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "List blog topics"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/blogs/v3/topics"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "List all blog topics. Supports paging and filtering."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Some parameters support \"range\" filters via double-underscored param suffixes, like ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "__gt"
          }), ". If a parameter supports a filter type of 'gt', that means you can append a double-underscore and 'gt' to the parameter name to find all items greater than a specified value."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["For example, the following query will find all items with the 'updated' time greater than 1364424600000: ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://api.hubapi.com/blogs/v3/topics?created__gt=1364424600000",
            children: "https://api.hubapi.com/blogs/v3/topics?created__gt=1364424600000"
          })]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "content"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "id",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Search for topics by id. Supports exact value matching and the 'in' range filter (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "id__in=348109009,348109019"
              }), ")"]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "name",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Search for topics by name. Supports exact value matching, 'contains' searching, and 'icontains' case-insensitive searching (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "name__contains=topic1"
              }), ")"]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "slug",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Search for topics by slug. Supports exact value matching"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "created",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Filter results by creation date, in milliseconds since the epoch. Supports exact value matching and the following range filters: range, gt, gte, lt, lte"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "limit",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The maximum number of items to return. Defaults to 20."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "offset",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The offset from the beginning of the result set from which to start returning results. Used for paging. Defaults to 0."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "casing",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for all query parameters (including those above) and JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CjxoMz5FeGFtcGxlIEdFVCBVUkw8L2gzPgo8YSBocmVmPSJodHRwczovL2FwaS5odWJhcGkuY29tL2Jsb2dzL3YzL3RvcGljcyIgdGFyZ2V0PSJfYmxhbmsiPmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vYmxvZ3MvdjMvdG9waWNzPC9hPgoKRXhhbXBsZSBHRVQgcmVzcG9uc2U6CnsKICAib2JqZWN0cyI6IFsKICAgIHsKICAgICAgImlkIjogMzQ5MDAxMzI4LAogICAgICAicG9ydGFsSWQiOiA2MjUxNSwKICAgICAgIm5hbWUiOiAiYXBpLWRvY3MiLAogICAgICAic2x1ZyI6ICJhcGktZG9jcyIsCiAgICAgICJkZXNjcmlwdGlvbiI6ICIiLAogICAgICAiY3JlYXRlZCI6IDEzODE4OTYyMDAwMDAsCiAgICAgICJ1cGRhdGVkIjogMTM4MTg5NjIwMDAwMCwKICAgICAgImRlbGV0ZWRBdCI6IDAKICAgIH0sCiAgICB7CiAgICAgICJpZCI6IDQ0NzMzMjU2OCwKICAgICAgInBvcnRhbElkIjogNjI1MTUsCiAgICAgICJuYW1lIjogIlRlc3QgVG9waWMgMjAxNDAxMTAxNjA3MzMiLAogICAgICAic2x1ZyI6ICJ0ZXN0LXRvcGljLTIwMTQwMTEwMTYwNzMzIiwKICAgICAgImRlc2NyaXB0aW9uIjogIiIsCiAgICAgICJjcmVhdGVkIjogMTM4OTM4ODA1MzAwMCwKICAgICAgInVwZGF0ZWQiOiAxMzg5Mzg4MDUzMDAwLAogICAgICAiZGVsZXRlZEF0IjogMAogICAgfSwKICAgIHsKICAgICAgImlkIjogNDUwMTEzNTE3LAogICAgICAicG9ydGFsSWQiOiA2MjUxNSwKICAgICAgIm5hbWUiOiAiVGVzdCIsCiAgICAgICJzbHVnIjogInRlc3QiLAogICAgICAiZGVzY3JpcHRpb24iOiAiIiwKICAgICAgImNyZWF0ZWQiOiAxMzg5Mzg3NjY5MDAwLAogICAgICAidXBkYXRlZCI6IDEzODkzODc2NjkwMDAsCiAgICAgICJkZWxldGVkQXQiOiAwCiAgICB9CiAgXSwKICAibWVzc2FnZSI6IG51bGwsCiAgInRvdGFsIjogMywKICAibGltaXQiOiAxMDAwLAogICJvZmZzZXQiOiAwCn0K"
          })
        }), (0, _jsxRuntime.jsxs)(EndpointJSONFields, {
          children: [(0, _jsxRuntime.jsx)(_components.h3, {
            children: "JSON Fields returned in the response"
          }), (0, _jsxRuntime.jsxs)(_components.table, {
            children: [(0, _jsxRuntime.jsx)(_components.thead, {
              children: (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.th, {
                  children: "Field name"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Type"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Description"
                })]
              })
            }), (0, _jsxRuntime.jsxs)(_components.tbody, {
              children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "id"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The unique id of the topic"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "portalId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "int"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The hub id"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "created"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the topic was first created, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "updated"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the topic was last updated, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "deletedAt"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the topic was deleted, in milliseconds since the epoch. Zero if the topic was never deleted. Use a DELETE request to properly soft delete a topic - do not set this value directly."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "name"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The topic name"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "slug"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The URL-friendly version of the topic, used in blog urls"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "description"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The topic description"
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Search blog topics"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/blogs/v3/topics/search"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Search for blog topics by various criteria. See the options below for details about filtering. Supports paging for the case where a large number of topics match the criteria."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Some parameters support \"range\" filters via double-underscored param suffixes, like ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "__gt"
          }), ". If a parameter supports a filter type of 'gt', that means you can append a double-underscore and 'gt' to the parameter name to find all items greater than a specified value."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["For example, the following query will find all items with the 'updated' time greater than 1364424600000: ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://api.hubapi.com/blogs/v3/topics/search?created__gt=1364424600000",
            children: "https://api.hubapi.com/blogs/v3/topics/search?created__gt=1364424600000"
          })]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "content"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "id",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Search for topics by id. Supports exact value matching and the 'in' range filter (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "id__in=348109009,348109019"
              }), ")"]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "name",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Search for topics by name. Supports exact value matching, 'contains' searching, and 'icontains' case-insensitive searching (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "name__contains=topic1"
              }), ")"]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "slug",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Search for topics by slug. Supports exact value matching"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "created",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Filter results by creation date, in milliseconds since the epoch. Supports exact value matching and the following range filters: range, gt, gte, lt, lte"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "limit",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The maximum number of items to return. Defaults to 100."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "offset",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The offset from the beginning of the result set from which to start returning results. Used for paging. Defaults to 0."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "casing",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for all query parameters (including those above) and JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "q",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Search for topics whose names or url slugs contain the given string."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "active",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Filter topics by whether or not they are associated with any published blog posts (true or false)."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "blog",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Filter topics by a blog they are used on (value is a blog ID)."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgR0VUIHJlcXVlc3Q6CmhyZWY9Imh0dHBzOi8vYXBpLmh1YmFwaS5jb20vYmxvZ3MvdjMvdG9waWNzL3NlYXJjaD9jcmVhdGVkX19ndD0xNDM1MDAwOTExMDAwIiB0YXJnZXQ9Il9ibGFuayI+aHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My90b3BpY3Mvc2VhcmNoP2NyZWF0ZWRfX2d0PTE0MzUwMDA5MTEwMDAKCkV4YW1wbGUgcmVzcG9uc2U6CjxibG9jaz4KewogICJsaW1pdCI6IDEwMCwKICAib2JqZWN0cyI6IFsKICAgIHsKICAgICAgImFzc29jaWF0ZWRCbG9nSWRzIjogWwogICAgICAgIAogICAgICBdLAogICAgICAiY3JlYXRlZCI6IDE0MzUwMDEyMjIwNTksCiAgICAgICJkZWxldGVkQXQiOiAwLAogICAgICAiZGVzY3JpcHRpb24iOiAidGVzdGVyOSIsCiAgICAgICJpZCI6IDI5ODY2OTIwMTYsCiAgICAgICJsaXZlUG9zdHMiOiAwLAogICAgICAibmFtZSI6ICJ0ZXN0ZXI5IiwKICAgICAgInBvcnRhbElkIjogNjI1MTUsCiAgICAgICJzbHVnIjogInRlc3RlcjkiLAogICAgICAic3RhdHVzIjogImluYWN0aXZlIiwKICAgICAgInRvdGFsUG9zdHMiOiAwLAogICAgICAidXBkYXRlZCI6IDE0MzUwMDEyMjIwNTkKICAgIH0sCiAgICB7CiAgICAgICJhc3NvY2lhdGVkQmxvZ0lkcyI6IFsKICAgICAgICAKICAgICAgXSwKICAgICAgImNyZWF0ZWQiOiAxNDM1MDAwOTExNDQ3LAogICAgICAiZGVsZXRlZEF0IjogMCwKICAgICAgImRlc2NyaXB0aW9uIjogInRlc3RlcjciLAogICAgICAiaWQiOiAzMDAyOTk4NzI3LAogICAgICAibGl2ZVBvc3RzIjogMCwKICAgICAgIm5hbWUiOiAidGVzdGVyNyIsCiAgICAgICJwb3J0YWxJZCI6IDYyNTE1LAogICAgICAic2x1ZyI6ICJ0ZXN0ZXI3IiwKICAgICAgInN0YXR1cyI6ICJpbmFjdGl2ZSIsCiAgICAgICJ0b3RhbFBvc3RzIjogMCwKICAgICAgInVwZGF0ZWQiOiAxNDM1MDAwOTExNDQ3CiAgICB9LAogICAgewogICAgICAiYXNzb2NpYXRlZEJsb2dJZHMiOiBbCiAgICAgICAgCiAgICAgIF0sCiAgICAgICJjcmVhdGVkIjogMTQzNTAwMTE3NjIwNiwKICAgICAgImRlbGV0ZWRBdCI6IDAsCiAgICAgICJkZXNjcmlwdGlvbiI6ICJ0ZXN0ZXI4IiwKICAgICAgImlkIjogMzAwOTgyOTAyOSwKICAgICAgImxpdmVQb3N0cyI6IDAsCiAgICAgICJuYW1lIjogInRlc3RlcjgiLAogICAgICAicG9ydGFsSWQiOiA2MjUxNSwKICAgICAgInNsdWciOiAidGVzdGVyOCIsCiAgICAgICJzdGF0dXMiOiAiaW5hY3RpdmUiLAogICAgICAidG90YWxQb3N0cyI6IDAsCiAgICAgICJ1cGRhdGVkIjogMTQzNTAwMTE3NjIwNgogICAgfQogIF0sCiAgIm9mZnNldCI6IDAsCiAgInRvdGFsIjogMywKICAidG90YWxDb3VudCI6IDMKfQo8L2Jsb2NrPg=="
          })
        }), (0, _jsxRuntime.jsxs)(EndpointJSONFields, {
          children: [(0, _jsxRuntime.jsx)(_components.h3, {
            children: "JSON Fields returned in the response"
          }), (0, _jsxRuntime.jsxs)(_components.table, {
            children: [(0, _jsxRuntime.jsx)(_components.thead, {
              children: (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.th, {
                  children: "Field name"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Type"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Description"
                })]
              })
            }), (0, _jsxRuntime.jsxs)(_components.tbody, {
              children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "id"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The unique id of the topic"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "portalId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "int"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The hub id"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "created"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the topic was first created, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "updated"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the topic was last updated, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "deletedAt"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the topic was deleted, in milliseconds since the epoch. Zero if the topic was never deleted. Use a DELETE request to properly soft delete a topic - do not set this value directly."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "name"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The topic name"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "slug"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The URL-friendly version of the topic, used in blog urls"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "description"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The topic description"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "totalPosts"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "int"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The total count of posts (including drafts) associated with this topic."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "livePosts"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "int"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The total count of published posts associated with this topic."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "lastUsed"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The most recent publish date of a blog post associated with this topic, in milliseconds since the epoch."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "associatedBlogIds"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "array"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "A list of the blog IDs where this topic has been used."
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Get a blog topic by ID"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/blogs/v3/topics/:topic-id"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Get the details for a specific topic by its ID."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "topic-id",
            required: true,
            schemaGroup: "Path",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Unique identifier for a particular topic"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "casing",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for returned JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CjxoMz5FeGFtcGxlIEdFVCBVUkw6PC9oMz4KPGEgaHJlZj0iaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My90b3BpY3MvMzQ5MDAxMzI4IiB0YXJnZXQ9Il9ibGFuayI+aHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My90b3BpY3MvMzQ5MDAxMzI4PC9hPgoKRXhhbXBsZSBHRVQgcmVzcG9uc2U6CnsKICAiaWQiOiAzNDkwMDEzMjgsCiAgInBvcnRhbElkIjogNjI1MTUsCiAgIm5hbWUiOiAiYXBpLWRvY3MiLAogICJzbHVnIjogImFwaS1kb2NzIiwKICAiZGVzY3JpcHRpb24iOiAiIiwKICAiY3JlYXRlZCI6IDEzODE4OTYyMDAwMDAsCiAgInVwZGF0ZWQiOiAxMzgxODk2MjAwMDAwLAogICJkZWxldGVkQXQiOiAwLAogICJ0b3RhbFBvc3RzIjogbnVsbCwKICAibGl2ZVBvc3RzIjogbnVsbCwKICAibGFzdFVzZWQiOiBudWxsLAogICJhc3NvY2lhdGVkQmxvZ0lkcyI6IFsKICAgIAogIF0sCiAgInB1YmxpY1VybCI6IG51bGwsCiAgInN0YXR1cyI6ICJpbmFjdGl2ZSIKfQo="
          })
        }), (0, _jsxRuntime.jsxs)(EndpointJSONFields, {
          children: [(0, _jsxRuntime.jsx)(_components.h3, {
            children: "JSON Fields returned in the response"
          }), (0, _jsxRuntime.jsxs)(_components.table, {
            children: [(0, _jsxRuntime.jsx)(_components.thead, {
              children: (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.th, {
                  children: "Field name"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Type"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Description"
                })]
              })
            }), (0, _jsxRuntime.jsxs)(_components.tbody, {
              children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "id"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The unique id of the topic"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "portalId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The hub id"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "created"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the topic was first created, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "updated"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the topic was last updated, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "deletedAt"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the topic was deleted, in milliseconds since the epoch. Zero if the topic was never deleted. Use a DELETE request to properly soft delete a topic - do not set this value directly."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "name"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The topic name"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "slug"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The URL-friendly version of the topic, used in blog urls"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "description"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The topic description"
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Create a new blog topic"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/blogs/v3/topics"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Create a new blog topic."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "content"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "casing",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for allowed JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "name",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The topic name"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "description",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The topic description"
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CjxoMz5FeGFtcGxlIFBPU1QgVVJMOjwvaDM+CjxhIGhyZWY9Imh0dHBzOi8vYXBpLmh1YmFwaS5jb20vYmxvZ3MvdjMvdG9waWNzIiB0YXJnZXQ9Il9ibGFuayI+aHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My90b3BpY3M8L2E+Cgo8cD5FeGFtcGxlIFBPU1QgYm9keTo8L3A+CnsKIm5hbWUiOiAiSGV5IEEgTmV3IFRvcGljIiwKImRlc2NyaXB0aW9uIjogIlRoaXMgaXMgYSB0b3BpYyBkZXNjcmlwdGlvbiIKfQoKPHA+RXhhbXBsZSBQT1NUIHJlc3BvbnNlOjwvcD4KSFRUUC8xLjEgMjAxIENyZWF0ZWQKCnsKICAiaWQiOiAzNDkwMDExMjMxMjMxMjMsCiAgInBvcnRhbElkIjogNjI1MTUsCiAgIm5hbWUiOiAiSGV5IGEgbmV3IHRvcGljIiwKICAic2x1ZyI6ICJoZXktYS1uZXctdG9waWMiLAogICJkZXNjcmlwdGlvbiI6ICJUaGlzIGlzIGEgdG9waWMgZGVzY3JpcHRpb24iLAogICJjcmVhdGVkIjogMTM4MTg5NjIwMDAwMCwKICAidXBkYXRlZCI6IDEzODE4OTYyMDAwMDAsCiAgImRlbGV0ZWRBdCI6IDAsCiAgInRvdGFsUG9zdHMiOiBudWxsLAogICJsaXZlUG9zdHMiOiBudWxsLAogICJsYXN0VXNlZCI6IG51bGwsCiAgImFzc29jaWF0ZWRCbG9nSWRzIjogWwogICAgCiAgXSwKICAicHVibGljVXJsIjogbnVsbCwKICAic3RhdHVzIjogImluYWN0aXZlIgp9Cg=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Group blog topics"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/blogs/v3/topics/group-topics"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Merge multiple topics by ID into a single topic group."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "casing",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for allowed JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "groupedTopicName",
            type: "string",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The new name of the combined topics"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "topicIds",
            type: "list(long)",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The List of topic ids to merge"
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CjxoMz5FeGFtcGxlIFBPU1QgVVJMOjwvaDM+CjxhIGhyZWY9Imh0dHBzOi8vYXBpLmh1YmFwaS5jb20vYmxvZ3MvdjMvdG9waWNzL2dyb3VwLXRvcGljcyIgdGFyZ2V0PSJfYmxhbmsiPmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vYmxvZ3MvdjMvdG9waWNzL2dyb3VwLXRvcGljczwvYT4KCkV4YW1wbGUgUE9TVCBib2R5Ogp7CiAgImdyb3VwZWRUb3BpY05hbWUiOiAiTWVyZ2VkIFRvcGljIiwKICAidG9waWNJZHMiOlsxMjM0NTYsNzg5MDEyLDM0NTY3OF0KfQoKRXhhbXBsZSBQT1NUIHJlc3BvbnNlOgpIVFRQLzEuMSAyMDAgT0sKewogICJ0b3BpY0lkcyI6WzEyMzQ1Niw3ODkwMTIsMzQ1Njc4XSwKICAiZ3JvdXBlZFRvcGljTmFtZSI6Ik1lcmdlZCBUb3BpYyIsCiAgInBvcnRhbElkIjo2MjUxNQp9Cg=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Update a blog topic"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/blogs/v3/topics/:topic-id"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Update an existing blog topic."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "topic-id",
            required: true,
            schemaGroup: "Path",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Unique identifier for a particular topic"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "casing",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for returned JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "name",
            schemaGroup: "Body",
            type: "string",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The topic name"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "description",
            schemaGroup: "Body",
            type: "string",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The topic description"
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CjxoMz5FeGFtcGxlIFVzYWdlIGFuZCBSZXN1bHQ8L2gzPgo8cCBjbGFzcz0ibWV0aG9kLXVybCI+SGl0IHRoaXMgVVJMIHdpdGggYSBIVFRQIG1ldGhvZCBvZiBQVVQgCiAgPGEgaHJlZj0iaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My90b3BpY3MvMzQ5MDAxMTIzMTIzMTIzIiB0YXJnZXQ9Il9ibGFuayI+aHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My90b3BpY3MvMzQ5MDAxMTIzMTIzMTIzPC9hPjwvcD4KCjxwPmFuZCBpbmNsdWRpbmcgYSByZXF1ZXN0IGJvZHkgb2Y6PC9wPgo8YmxvY2s+CnsKIm5hbWUiOiAiSGV5IGFuIHVwZGF0ZWQgVG9waWMiLAoiZGVzY3JpcHRpb24iOiAiVGhpcyBpcyBhbiB1cGRhdGVkIHRvcGljIGRlc2NyaXB0aW9uIgp9CjwvYmxvY2s+Cgo8cD5UaGlzIHJldHVybnMgYSByZXNwb25zZSBsaWtlOjwvcD4KPGJsb2NrPgpIVFRQLzEuMSAyMDAgT0sKCnsKICAiaWQiOiAzNDkwMDExMjMxMjMxMjMsCiAgInBvcnRhbElkIjogNjI1MTUsCiAgIm5hbWUiOiAiSGV5IGFuIHVwZGF0ZWQgdG9waWMiLAogICJzbHVnIjogImhleS1hbi11cGRhdGVkLXRvcGljIiwKICAiZGVzY3JpcHRpb24iOiAiVGhpcyBpcyBhbiB1cGRhdGVkIHRvcGljIGRlc2NyaXB0aW9uIiwKICAiY3JlYXRlZCI6IDEzODE4OTYyMDAwMDAsCiAgInVwZGF0ZWQiOiAxMzgxODk2MjAwMDAwLAogICJkZWxldGVkQXQiOiAwLAogICJ0b3RhbFBvc3RzIjogbnVsbCwKICAibGl2ZVBvc3RzIjogbnVsbCwKICAibGFzdFVzZWQiOiBudWxsLAogICJhc3NvY2lhdGVkQmxvZ0lkcyI6IFsKICAgIAogIF0sCiAgInB1YmxpY1VybCI6IG51bGwsCiAgInN0YXR1cyI6ICJpbmFjdGl2ZSIKfQo8L2Jsb2NrPg=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Delete blog topic"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "DELETE",
          path: "/blogs/v3/topics/:topic-iid"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Marks a blog topic as deleted."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "content"
        }), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "topic-id",
            required: true,
            schemaGroup: "Path",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Unique identifier for the particular topic to delete"
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CjxoMz5FeGFtcGxlIERFTEVURSBVUkw6PC9oMz4KPGEgaHJlZj0iaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My90b3BpY3MvMjUzNzY3MjIyOD9oYXBpa2V5PWRlbW8iIHRhcmdldD0iX2JsYW5rIj5odHRwczovL2FwaS5odWJhcGkuY29tL2Jsb2dzL3YzL3RvcGljc3MvMjUzNzY3MjIyODwvYT4KCgo8cD5FeGFtcGxlIERFTEVURSByZXNwb25zZTo8L3A+CkhUVFAvMS4xIDIwNCBObyBDb250ZW50Cg=="
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}