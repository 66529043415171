"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50631386771;
const slug = exports.slug = 'reference/cms/hubl/tags/related-blog-posts';
const title = exports.title = 'related_blog_posts HubLタグを使用して関連するブログ記事のリストを作成する';
const name = exports.name = 'APAC JAPAN (ja) | [Developers] Creating a related blog post listing with the blog related posts HubL tag_JA | 202108';
const metaDescription = exports.metaDescription = 'related_blog_posts HubLタグを使用すると、さまざまなパラメーターに基づいて関連するブログ記事の動的なリストを作成できます。執筆者、記事パス、および公開日による絞り込み機能を使用して、複数のブログにまたがる記事のリストを生成できます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC",
  "label": "パラメーター",
  "parent": null
}, {
  "depth": 0,
  "id": "related_blog_posts-hubl%E3%82%BF%E3%82%B0%E3%81%AE%E4%BD%BF%E7%94%A8%E4%BE%8B",
  "label": "related_blog_posts HubLタグの使用例",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%A4%87%E6%95%B0%E3%81%AE%E3%83%96%E3%83%AD%E3%82%B0%E3%81%8B%E3%82%89%E7%89%B9%E5%AE%9A%E3%81%AE%E5%9F%B7%E7%AD%86%E8%80%85%E3%81%AB%E3%82%88%E3%82%8B%E8%A8%98%E4%BA%8B%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B",
  "label": "複数のブログから特定の執筆者による記事を表示する",
  "parent": "related_blog_posts-hubl%E3%82%BF%E3%82%B0%E3%81%AE%E4%BD%BF%E7%94%A8%E4%BE%8B"
}, {
  "depth": 1,
  "id": "%E3%80%8Csales-enablement%E3%80%8D%E3%82%BF%E3%82%B0%E3%82%92%E5%90%AB%E3%82%80%E8%A8%98%E4%BA%8B%E3%82%92%E3%80%81%E5%85%AC%E9%96%8B%E6%97%A5%E3%81%AE%E6%9C%9F%E9%96%93%E3%82%92%E9%99%90%E5%AE%9A%E3%81%97%E3%81%A6%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B",
  "label": "「sales enablement」タグを含む記事を、公開日の期間を限定して表示する",
  "parent": "related_blog_posts-hubl%E3%82%BF%E3%82%B0%E3%81%AE%E4%BD%BF%E7%94%A8%E4%BE%8B"
}, {
  "depth": 1,
  "id": "js%E3%82%B3%E3%83%BC%E3%83%AB%E3%83%90%E3%83%83%E3%82%AF%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E8%A8%98%E4%BA%8B%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%97%E3%81%A6html%E5%87%BA%E5%8A%9B%E3%82%92%E5%88%B6%E5%BE%A1%E3%81%99%E3%82%8B",
  "label": "JSコールバックを使用する記事を表示してHTML出力を制御する",
  "parent": "related_blog_posts-hubl%E3%82%BF%E3%82%B0%E3%81%AE%E4%BD%BF%E7%94%A8%E4%BE%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "related_blog_posts HubLタグを使用して関連するブログ記事のリストを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#blog-related-posts",
        children: "related_blog_posts"
      }), " HubLタグを使用すると、さまざまなパラメーターに基づいて関連するブログ記事の動的なリストを作成できます。執筆者、記事パス、および公開日による絞り込み機能を使用して、複数のブログにまたがる記事のリストを生成できます。開発者は、マクロを使用してモジュールのHTML出力を指定することもできます。このHubLタグはブログ記事とページの両方で使用できます。このチュートリアルでは、related_blog_posts HubLタグのパラメーターと使用方法について説明します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "related_blog_posts"
        }), " HubLタグは、記事／ページ側で編集可能なモジュールを生成せず、HubLで完全に設定されることに注意してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "パラメーター"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["記事のリストは、以下のパラメーターに一致する記事（このHubLタグが表示された記事に関連する記事）と設定されたパラメーター値を比較して得られた関連性スコアから生成されます。必須のパラメーターはありませんが、パラメーターを指定することで、返される記事を細かく制御できます。カンマ区切りパラメーターの場合は、設定する値が多いほど返されるリストの範囲が広がります。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "post_formatter"
      }), "パラメーターを使用すると、モジュールのHTML出力を生成する", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/hubl/variables-macros-syntax#macros",
        children: "マクロ"
      }), "を指定できます。パラメーターの完全なリストと既定のHTML出力の例については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#blog-related-posts",
        children: "related_blog_posts"
      }), "の仕様を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リストするブログ記事の最大数。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "'default' or blog id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リストする記事を含むブログのID。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "記事に関連性があるかどうかを判定するために使用するタグ（カンマ区切り）。ブログ記事にこれらのタグのいずれかまたは類似のタグがある場合、記事の関連性が高くなり、リストでのランキングが向上します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_authors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リストする記事の執筆者の名前（カンマ区切り）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_post_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リストする関連ブログ記事の検索に使用するブログ記事ID（カンマ区切り）。このパラメーターは、ウィジェットをページに表示する場合にのみ使用してください。ブログ記事の場合は既定で、ウィジェットが表示される記事になります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "post_formatter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["返されるブログ記事を表示するためのカスタムマクロの名前。このマクロには、フォーマットする記事オブジェクト、ブログ記事の反復数、および結果に含まれるブログ記事の合計数という3つのパラメーターが渡されます。指定されていないか、「default」に設定された場合は、組み込みのフォーマッターを使用して各記事がフォーマットされます。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.strong, {
              children: "注：post_formatterパラメーターの代わりに下のcallbackパラメーターを使用することをお勧めします。タグのHTMLのレンダリングが速くなり、ページの読み込み時間が短縮されるためです。"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callback"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "返されるブログ記事をレンダリングするためのJavaScript関数の名前。関数に、形式を設定するブログ記事オブジェクトの配列が渡されます。callbackパラメーターとpost_formatterパラメーターのどちらも指定されていない場合、タグは既定の形式のHTMLを生成します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path_prefixes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リストする記事のURLパスまたはサブディレクトリー（カンマ区切り）。ブログ記事のパスに類似するプレフィックスがある場合は、記事の関連性が高くなり、リストでのランキングが向上します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "date/time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ある日時より後に公開された記事に絞り込むことができます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date/Time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ある日時より前に公開された記事に絞り込むことができます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_post_override"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "他のパラメーター値やフィルターに関係なく、返されたリストに常に表示されるブログ記事のID（カンマ区切り）。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["ページの読み込みを高速化するために、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "post_formatter"
        }), "パラメーターではなく", (0, _jsxRuntime.jsx)(_components.code, {
          children: "callback"
        }), "パラメーターを使用することを強くお勧めします。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["1つの記事に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "related_blog_posts"
        }), " HubLタグを使用する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_post_ids"
        }), "パラメーターを指定しないでください。ブログ記事の場合は既定で、ウィジェットが表示される記事になります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "related_blog_posts HubLタグの使用例"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このタグを使用してウェブサイトを強化する方法の例を以下に示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "複数のブログから特定の執筆者による記事を表示する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この例では、2つの異なるブログから、指定された3人の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_authors"
      }), "（ブログ執筆者）によって書かれた記事のリストを生成します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts blog_ids=\"3241539189,3261083894\", limit=6, blog_authors=\"John Smith,Joe Smith,Frank Smith\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "「sales enablement」タグを含む記事を、公開日の期間を限定して表示する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この例では、特定のブログ記事に関連し、「sales enablement」タグを含む10件の記事のリストを、公開日の期間を限定して作成します。この例では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post_ids"
      }), "パラメーターをページで使用するために指定します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts blog_post_ids=\"3267910554\", limit=10, tags=\"sales enablement\", start_date=\"2018-02-05\", end_date=\"2018-06-10\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "JSコールバックを使用する記事を表示してHTML出力を制御する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この例では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callback"
      }), "パラメーターを使用して記事リストのHTML出力を制御することにより、5件の記事のリストを生成します。（", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "マクロ"
      }), "を使用した", (0, _jsxRuntime.jsx)(_components.code, {
        children: "post_formatter"
      }), "パラメーターを使用しない方法。）"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts limit=5, callback=\"blog_post_formatter\" %}\n\n<script>\n  var blog_post_formatter = function(blogposts) {\n\n    var formatted = \"<div>\";\n    for (var i = 0; i < blogposts.length; i++) {\n      var blogpost = blogposts[i];\n      formatted += '<div class=\"related-blog-item\">';\n      formatted += `<span>Related Post ${i + 1}/${blogposts.length}</span><br>`;\n      formatted += `<a class=\"related-blog-title\" href=\"${blogpost.url}\"><span>${blogpost.name}</span></a>`;\n      formatted += `<div class=\"hs-related-blog-byline\">by <span class=\"related-blog-author\">${blogpost.blogAuthor.fullName}</span><span class=\"related-blog-posted-at\"> posted on </span><span class=\"related-blog-date\">${new Date(blogpost.publishDate).toLocaleDateString()}</span></div>`;\n      formatted += `<p class=\"related-blog-post-summary\">${blogpost.postSummary}<a href=\"${blogpost.url}\">Read more</a></p>`;\n      formatted += '<div class=\"related-blog-tags\">';\n      if (blogpost.tagList.length > 0) {\n        formatted += `Tags: ${blogpost.tagList.map(tag => tag.label).join(\", \")}`;\n      }\n      formatted += '</div>';\n\n      if (blogpost.featuredImage) {\n        formatted += `<img src=\"${blogpost.featuredImage}\" alt=\"${blogpost.featuredImageAltText}\">`;\n      }\n      formatted += '</div>';\n    }\n    formatted += '</div>';\n    return formatted;\n  }\n</script>\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}