"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937503;
const slug = exports.slug = 'guides/api/crm/extensions/calling-sdk';
const title = exports.title = 'API de CRM | SDK de extensiones de llamada';
const name = exports.name = 'vNext Docs DP - SDK de extensiones de llamada';
const metaDescription = exports.metaDescription = 'Resumen del SDK de extensiones de llamada';
const toc = exports.toc = [{
  "depth": 0,
  "id": "ejecutar-la-aplicaci%C3%B3n-de-llamadas-de-demostraci%C3%B3n",
  "label": "Ejecutar la aplicación de llamadas de demostración",
  "parent": null
}, {
  "depth": 1,
  "id": "instalar-la-aplicaci%C3%B3n-de-llamadas-de-demostraci%C3%B3n",
  "label": "Instalar la aplicación de llamadas de demostración",
  "parent": "ejecutar-la-aplicaci%C3%B3n-de-llamadas-de-demostraci%C3%B3n"
}, {
  "depth": 1,
  "id": "iniciar-la-aplicaci%C3%B3n-de-llamadas-de-demostraci%C3%B3n-desde-hubspot",
  "label": "Iniciar la aplicación de llamadas de demostración desde HubSpot",
  "parent": "ejecutar-la-aplicaci%C3%B3n-de-llamadas-de-demostraci%C3%B3n"
}, {
  "depth": 0,
  "id": "instalar-el-sdk-de-extensiones-de-llamada-en-tu-aplicaci%C3%B3n-de-llamadas",
  "label": "Instalar el SDK de extensiones de llamada en tu aplicación de llamadas",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-la-sdk-de-extensiones-de-llamada",
  "label": "Usar la SDK de extensiones de llamada",
  "parent": null
}, {
  "depth": 0,
  "id": "probar-la-aplicaci%C3%B3n",
  "label": "Probar la aplicación",
  "parent": null
}, {
  "depth": 1,
  "id": "usar-el-punto-de-terminaci%C3%B3n-de-configuraci%C3%B3n-de-llamadas",
  "label": "Usar el punto de terminación de configuración de llamadas",
  "parent": "probar-la-aplicaci%C3%B3n"
}, {
  "depth": 1,
  "id": "anular-la-configuraci%C3%B3n-de-tu-extensi%C3%B3n-con-localstorage-localstorage",
  "label": "Anular la configuración de tu extensión con localStorage localStorage",
  "parent": "probar-la-aplicaci%C3%B3n"
}, {
  "depth": 0,
  "id": "prepara-tu-aplicaci%C3%B3n-para-la-producci%C3%B3n",
  "label": "Prepara tu aplicación para la producción",
  "parent": null
}, {
  "depth": 0,
  "id": "publica-tu-aplicaci%C3%B3n-de-llamadas-en-el-mercado-de-hubspot",
  "label": "Publica tu aplicación de llamadas en el mercado de HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "eventos",
  "label": "Eventos",
  "parent": null
}, {
  "depth": 1,
  "id": "enviar-mensajes-a-hubspot",
  "label": "Enviar mensajes a HubSpot",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "inicializado",
  "label": "Inicializado",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "userloggedin",
  "label": "userLoggedIn",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "userloggedout",
  "label": "userLoggedOut",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "outgoingcall",
  "label": "outgoingCall",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "callanswered",
  "label": "callAnswered",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "callended",
  "label": "callEnded",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "callcompleted",
  "label": "callCompleted",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "senderror",
  "label": "sendError",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "resizewidget",
  "label": "resizeWidget",
  "parent": "eventos"
}, {
  "depth": 1,
  "id": "recibir-mensajes-en-hubspot",
  "label": "Recibir mensajes en HubSpot",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "onready",
  "label": "onReady",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "ondialnumber",
  "label": "onDialNumber",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "onengagementcreated",
  "label": "onEngagementCreated",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "oncreateengagementsucceeded",
  "label": "onCreateEngagementSucceeded",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "oncreateengagementfailed",
  "label": "onCreateEngagementFailed",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "onvisibilitychanged",
  "label": "onVisibilityChanged",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "defaulteventhandler",
  "label": "defaultEventHandler",
  "parent": "eventos"
}, {
  "depth": 0,
  "id": "sdk-para-llamadas-%7C-preguntas-frecuentes",
  "label": "SDK para llamadas | Preguntas frecuentes",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFc%C3%B3mo-se-gestiona-la-autenticaci%C3%B3n-de-usuarios%3F",
  "label": "¿Cómo se gestiona la autenticación de usuarios?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFlas-extensiones-de-llamada-est%C3%A1n-alojadas-en-una-cdn%3F",
  "label": "¿Las extensiones de llamada están alojadas en una CDN?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFcu%C3%A1ndo-deber%C3%ADa-crearse-o-actualizarse-una-interacci%C3%B3n%3F",
  "label": "¿Cuándo debería crearse o actualizarse una interacción?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFqu%C3%A9-alcances-se-requieren-como-parte-de-la-integraci%C3%B3n%3F",
  "label": "¿Qué alcances se requieren como parte de la integración?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFse-puede-agregar-esta-funcionalidad-a-una-aplicaci%C3%B3n-ya-existente-en-el-mercado-o-creo-una-nueva-aplicaci%C3%B3n%3F",
  "label": "¿Se puede agregar esta funcionalidad a una aplicación ya existente en el mercado o creo una nueva aplicación?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFpuedo-integrar-mi-aplicaci%C3%B3n-de-softphone-existente-en-el-sdk%3F",
  "label": "¿Puedo integrar mi aplicación de softphone existente en el SDK?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFse-pueden-usar-varias-integraciones-al-mismo-tiempo%3F",
  "label": "¿Se pueden usar varias integraciones al mismo tiempo?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFlos-usuarios-gratuitos-pueden-instalar-integraciones-de-aplicaciones%3F",
  "label": "¿Los usuarios gratuitos pueden instalar integraciones de aplicaciones?",
  "parent": null
}, {
  "depth": 0,
  "id": "si-un-usuario-ya-tiene-mi-aplicaci%C3%B3n-instalada%2C-%C2%BFla-integraci%C3%B3n-aparece-autom%C3%A1ticamente%3F",
  "label": "Si un usuario ya tiene mi aplicación instalada, ¿la integración aparece automáticamente?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFcualquier-usuario-puede-instalar-o-desinstalar-una-aplicaci%C3%B3n%3F",
  "label": "¿Cualquier usuario puede instalar o desinstalar una aplicación?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFpuedo-crear-una-propiedad-de-llamadas-personalizada%3F",
  "label": "¿Puedo crear una propiedad de llamadas personalizada?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFpuedo-realizar-una-llamada-desde-un-objeto-personalizado%3F",
  "label": "¿Puedo realizar una llamada desde un objeto personalizado?",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      ol: "ol",
      li: "li",
      h2: "h2",
      ul: "ul",
      h3: "h3",
      code: "code",
      pre: "pre",
      img: "img",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "SDK de extensiones de llamada"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " Nuestros socios de la aplicación de llamadas ya no necesitan crear y actualizar las interacciones de llamadas manualmente; HubSpot lo hará por ellos. Más información ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "aquí"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/calling-extensions-sdk",
        children: "SDK de extensiones de llamada"
      }), " permite que las aplicaciones proporcionen una opción de llamadas personalizada a los usuarios de HubSpot directamente desde un registro en CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una extensión de llamadas consta de tres componentes principales:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk",
          children: "SDK de extensiones de llamada"
        }), ", un SDK de JavaScript que permite la comunicación entre tu aplicación y HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "puntos de terminación de configuración de llamadas"
        }), ", los cuales se usan para establecer la configuración de llamadas para tu aplicación. Cada cuenta de HubSpot que conecta tu aplicación utilizará esta configuración."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "iframe de llamadas"
        }), ", que es donde aparece tu aplicación para los usuarios de HubSpot y está configurado usando los puntos de terminación de la configuración de llamadas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener más información sobre la experiencia de llamadas en la aplicación, revisa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/use-the-calling-tool#call-from-your-phone",
        children: "este artículo de la base de conocimientos"
      }), ". Una vez que la aplicación de la extensión de llamadas esté conectada a HubSpot, aparecerá como opción en el conmutador de llamadas cuando un usuario ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
        children: "haga una llamada desde HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si no tienes una aplicación, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types#app-developer-accounts",
        children: "crear una desde tu cuenta de desarrollador de HubSpot"
      }), ". Si aún no tienes una cuenta de desarrollador de HubSpot, inicia sesión ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " actualmente solo se admiten llamadas salientes."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ejecutar la aplicación de llamadas de demostración"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tienes la opción de probar el SDK de extensiones de llamada en dos aplicaciones de demostración diferentes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/tree/master/demos/demo-minimal-js",
          children: " demo-minimal-js"
        }), " presenta una implementación mínima del SDK usando JavaScript, HTML y CSS. Ve cómo se instala el SDK en", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/blob/project-demo-v1/demos/demo-minimal-js/index.js",
          children: " index.js"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/tree/master/demos/demo-react-ts",
          children: " demo-react-ts"
        }), " presenta una implementación en tiempo real del SDK utilizando React, TypeScript y Styled Components para actuar como un plan para tu aplicación. Ve cómo se instala el SDK en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/blob/master/demos/demo-react-ts/src/hooks/useCti.ts",
          children: "useCti.ts"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " estas aplicaciones de demostración no son aplicaciones de llamadas completamente funcionales y usan datos simulados para proporcionar una experiencia más realista."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Instalar la aplicación de llamadas de demostración"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes ejecutar las aplicaciones de demostración con o sin instalación. Para instalar la demostración en tu entorno local:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Instala ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://nodejs.org/en",
            children: "Node.js"
          }), " en tu entorno."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clona, bifurca o ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/calling-extensions-sdk/archive/refs/heads/master.zip",
            children: "descarga el ZIP"
          }), " de este repositorio."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Abre tu terminal y navega al directorio raíz del proyecto."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Ejecuta uno de los siguientes comandos:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), ":"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd demos/demo-minimal-js && npm i && npm start\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-react-ts"
        }), ":"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd demos/demo-react-ts && npm i && npm start\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Estos cambiarán al directorio de demostración que desees e instalarán las dependencias de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " requeridas para el proyecto utilizando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.npmjs.com/cli/v9",
        children: "npm CLI"
      }), " e iniciarán la aplicación."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " el comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm start"
        }), " abrirá automáticamente una nueva pestaña en tu navegador en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://localhost:9025/",
          children: "https://localhost:9025/"
        }), ", y es posible que debas omitir una advertencia de \"Tu conexión no es segura\" para acceder a la aplicación."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Iniciar la aplicación de llamadas de demostración desde HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navega a tus registros:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contactos:"
            }), " En tu cuenta de HubSpot, dirígete a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contactos > Contactos"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Empresa:"
            }), " En tu cuenta de HubSpot, dirígete a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contactos > Empresas"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Abre la consola del desarrollador de tu navegador y ejecuta el siguiente comando:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si completaste los pasos de instalación, para el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), " o el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-react-ts"
            }), ":"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'local');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si has omitido los pasos de instalación:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), ":"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'app:js');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-react-ts"
        }), ":"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'app');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Actualiza la página y haz clic en el icono ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Llamar"
          }), " en la barra lateral izquierda. Haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Llamar desde"
          }), " y selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre"
          }), " de la aplicación de demostración del paso 2 (por ejemplo, Demo App Local, Demo App JS, Demo App React)."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/call-app-in-record.png",
            alt: "call-app-in-record"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Llamar"
          }), " para ver cómo se integra la aplicación de demostración con HubSpot a través del SDK de extensiones de llamada. También puedes ver los eventos registrados en la consola del desarrollador de tu navegador."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/calling-sdk-in-app.png",
        alt: "calling-sdk-in-app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Instalar el SDK de extensiones de llamada en tu aplicación de llamadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para agregar el SDK de extensiones de llamada como dependencia de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " a tu aplicación de llamadas:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para npm, ejecuta:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i --save @hubspot/calling-extensions-sdk\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para yarn, ejecuta:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "yarn add @hubspot/calling-extensions-sdk\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar la SDK de extensiones de llamada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El SDK de extensiones de llamada expone una API simple para HubSpot y una aplicación de llamadas para intercambiar mensajes. Los mensajes se envían a través de métodos expuestos por el SDK y se reciben a través de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventHandlers"
      }), ". Puedes encontrar una lista completa de los eventos disponibles en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events",
        children: "sección Eventos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La siguiente es una descripción de los eventos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Número de marcación:"
        }), " HubSpot envía el evento de número de marcación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Llamada saliente iniciada:"
        }), " la aplicación notifica a HubSpot cuando se inicia la llamada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear interacción:"
        }), " HubSpot crea ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: " una interacción de llamada"
        }), " con información mínima si la aplicación lo solicita."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Interacción creada:"
        }), " HubSpot creó una interacción."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "EngagementId enviado a la aplicación:"
        }), " HubSpot envía el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "engagementId"
        }), " a la aplicación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Llamada finalizada:"
        }), " la aplicación notifica cuando finaliza la llamada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Llamada completada:"
        }), " la aplicación notifica cuando el usuario ha terminado con la experiencia de usuario de la aplicación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Actualizar interacción: **la aplicación obtiene la interacción mediante el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "engagementId"
        }), ", luego combina y actualiza la interacción con detalles adicionales de la llamada. Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls#update-calls",
          children: "actualizar la participación de una llamada a través de la API"
        }), "o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "del SDK"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para comenzar, crea una instancia del objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CallingExtensions"
      }), ". Puedes definir el comportamiento de tu extensión proporcionando el objeto de una opción al crear tu instancia de extensiones. El objeto de esta opción proporciona un campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventHandlers"
      }), " donde puedes especificar el comportamiento de tu extensión. El siguiente bloque de código ilustra las opciones disponibles y los controladores de eventos que puedes definir:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import CallingExtensions from \"@hubspot/calling-extensions-sdk\";\n\nconst options = {\n  /** @property {boolean} debugMode - Whether to log various inbound/outbound debug messages to the console. If false, console.debug will be used instead of console.log */\n  debugMode: boolean,\n  // eventHandlers handle inbound messages\n  eventHandlers: {\n    onReady: () => {\n      /* HubSpot is ready to receive messages. */\n    },\n    onDialNumber: event => {\n      /* HubSpot sends a dial number from the contact */\n    },\n    onCreateEngagementSucceeded: event => {\n      /* HubSpot has created an engagement for this call. */\n    }\n    onEngagementCreatedFailed: event => {\n      /* HubSpot has failed to create an engagement for this call. */\n    }\n    onUpdateEngagementSucceeded: event => {\n      /* HubSpot has updated an engagement for this call. */\n    },\n    onUpdateEngagementFailed: event => {\n      /* HubSpot has failed to update an engagement for this call. */\n    }\n    onVisibilityChanged: event => {\n      /* Call widget's visibility is changed. */\n    }\n  }\n};\n\nconst extensions = new CallingExtensions(options);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Probar la aplicación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para iniciar las extensiones de llamada iFrame para usuarios finales, HubSpot requiere los siguientes parámetros iFrame."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n   name: string /* The name of your calling app to display to users. */,\n   url: string  /* The URL of your calling app, built with the Calling Extensions SDK */,\n   width: number /* The iFrame's width */,\n   height: number /* The iFrame's height */,\n   isReady: boolean /* Whether the widget is ready for production (defaults to true) */,\n   supportsCustomObjects : true /* Whether calls can be placed from a custom object */\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usar el punto de terminación de configuración de llamadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilizando tu herramienta de API (por ejemplo, Postman), envía la siguiente carga útil a la API de configuración de HubSpot. Asegúrate de obtener el APP_ID de tu aplicación de llamadas y la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#authentication",
        children: "DEVELOPER_ACCOUNT_API_KEY"
      }), " de tu aplicación."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " la marca ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isReady"
        }), " indica si la aplicación está lista para la producción. Esta marca debe establecerse en false durante la prueba."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Example payload to add the call widget app settings\ncurl --request POST \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"name\":\"demo widget\",\"url\":\"https://mywidget.com/widget\",\"height\":600,\"width\":400,\"isReady\":false}'\n\n\n# Note that this endpoint also supports PATCH, GET and DELETE\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Anular la configuración de tu extensión con localStorage localStorage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes anular cualquiera de los ajustes de tu extensión para fines de prueba. Abre la consola del desarrollador de tu navegador desde una pestaña de HubSpot, edita la configuración a continuación y ejecuta el comando:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const myExtensionSettings = {\n  isReady: true,\n  name: 'My app name',\n  url: 'My local/qa/prod URL',\n};\n\nlocalStorage.setItem(\n  'LocalSettings:Calling:CallingExtensions',\n  JSON.stringify(myExtensionSettings)\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prepara tu aplicación para la producción"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si ya has utilizado el punto de terminación POST al probar tu aplicación, puedes utilizar el punto de terminación PATCH para cambiar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isReady"
      }), " a true. De lo contrario, usando tu herramienta de API (por ejemplo, Postman), envía esta carga útil la API de configuración de HubSpot. Asegúrate de obtener el APP_ID de tu aplicación de llamadas y la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#authentication",
        children: "DEVELOPER_ACCOUNT_API_KEY"
      }), " de tu aplicación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Example payload to add the call widget app settings\ncurl --request POST \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"name\":\"demo widget\",\"url\":\"https://mywidget.com/widget\",\"height\":600,\"width\":400,\"isReady\":true}'\n\n\n# Note that this endpoint also supports PATCH, GET and DELETE\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Publica tu aplicación de llamadas en el mercado de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El paso final una vez que tu aplicación esté configurada es publicar tu aplicación de llamadas en el mercado de HubSpot. Puedes encontrar más detalles ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "aquí"
      }), ". También puedes elegir no publicarlo en el mercado si esta aplicación es solo para uso interno."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eventos de llamadas disponibles:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Enviar mensajes a HubSpot", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#demo-minimal-js"
          }), (0, _jsxRuntime.jsx)(_components.a, {
            href: "#yarn"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#initialized",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "initialized"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedIn",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "userLoggedIn"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedOut",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "userLoggedOut"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#outgoingCall",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "outgoingCall"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callAnswered",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callAnswered"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callEnded",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callEnded"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callCompleted",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callCompleted"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#sendError",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "sendError"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#resizeWidget",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "resizeWidget"
              })
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Recibir mensajes en HubSpot"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onReady",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onReady"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onDial",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onDialNumber"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onEngagementCreated",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onEngagementCreated"
              })
            }), " (obsoleto)"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onEngagementCreated"
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementSucceeded",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onCreateEngagementSucceeded"
              })
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onUpdateEngagementFailed"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onVisibilityChanged",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onVisibilityChanged"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onVisibilityChanged"
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "#defaultEventHandler",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "defaultEventHandler"
              })
            })]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Enviar mensajes a HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), " proporciona los siguientes controladores de eventos que puedes invocar para enviar mensajes a HubSpot o para especificar otro comportamiento asociado. Consulta los siguientes ejemplos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Inicializado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envía un mensaje que indica que el teléfono flexible está listo para la interacción."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const payload = {\n  isLoggedIn: boolean,\n};\n\nextensions.initialized(payload);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isLoggedIn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si un usuario ha iniciado sesión o no."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "userLoggedIn"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envía un mensaje que indica que el usuario ha iniciado sesión."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// This message is only needed when user isn't logged in when initialized\nextensions.userLoggedIn();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "userLoggedOut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envía un mensaje que indica que el usuario ha cerrado sesión."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.userLoggedOut();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "outgoingCall"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envía un mensaje para notificar a HubSpot que se ha iniciado una llamada saliente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  phoneNumber: string /** @deprecated Use toNumber instead **/,\n  callStartTime: number,\n  createEngagement: true,\n  toNumber: string,\n  fromNumber: string,\n};\nextensions.outgoingCall(callInfo);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callStartTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hora de inicio de la llamada, en milisegundos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createEngagement"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si HubSpot debería crear una interacción para esta llamada o no. Cuando es verdadero, HubSpot responderá con ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementSucceeded",
              children: "onCreateEngagementSucceeded"
            }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed",
              children: "onCreateEngagementFailed"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número de teléfono del destinatario."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número de la persona que llama. Parámetro obligatorio."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callAnswered"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envía un mensaje para notificar a HubSpot que se está respondiendo una llamada saliente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.callAnswered();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callEnded"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envía un mensaje para notificar a HubSpot que la llamada ha finalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the call ended event, the user can navigate away, can close the call widget.\nextensions.callEnded({\n  engagementId: number,\n  callEndStatus: EndStatus,\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de interacción creado por HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeración"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El estado de la llamada cuando finaliza. Estado disponibles:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_COMPLETED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_FAILED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_CANCELED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_BUSY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_NO_ANSWER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_REJECTED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_MISSED"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callCompleted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Envía un mensaje para notificar a HubSpot que la llamada se ha completado. Las propiedades de interacción son ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/calls",
        children: "propiedad de HubSpot"
      }), " y ya no es necesario crearlas o actualizarlas manualmente (ver resaltado)."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hideWidget"
        }), " se ignorará cuando el usuario esté en una cola de tareas con el tipo de tarea ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Call"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the call completed event, HubSpot will\n//   1) insert the engagement into the timeline\n//   2) set the default associations on the engagement\n//   3) closes the widget unless `hideWidget` is set to false.\n//   4) update the engagement with any engagement properties\nconst data = {\n  engagementId: number,\n  hideWidget: boolean,\n  engagementProperties: { [key: string]: string },\n  externalCallId: number,\n}\nextensions.callCompleted(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de interacción creado por HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hideWidget"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Si el widget debe ocultarse cuando finaliza la llamada. Parámetro opcional. El valor predeterminado es true."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Suscríbete a las interacciones propias de hs ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/calls#properties",
              children: "agregando propiedades"
            }), ". Hace que HubSpot responda con ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onUpdateEngagementSucceeded",
              children: "onUpdateEngagementSucceeded"
            }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onUpdateEngagementFailed",
              children: "onUpdateEngagementFailed"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalCallId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ID de llamada creada por la aplicación de llamadas. Se utiliza para habilitar ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/third-party-calling",
              children: "las llamadas en el centro de ayuda"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendError"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envía un mensaje para notificar a HubSpot que la aplicación que llama ha encontrado un error."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the sendError event, HubSpot will display an alert popup to the user with the error message provided.\nconst data = {\n  message: string,\n};\nextensions.sendError(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensaje de error que aparecerá en la ventana emergente de alerta."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "resizeWidget"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envía un mensaje para notificar a HubSpot que la aplicación de llamada necesita ser redimensionada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the resizeWidget event, HubSpot will use the provided height and width to resize the call widget.\nconst data = {\n  height: number,\n  width: number,\n};\nextensions.resizeWidget(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Altura deseada del widget de llamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ancho deseado del widget de llamada."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Recibir mensajes en HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), " proporciona los siguientes controladores de eventos que puedes invocar al recibir mensajes en HubSpot o para especificar otro comportamiento asociado. Consulta los siguientes ejemplos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onReady"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mensaje que indica que HubSpot está listo para recibir mensajes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example snippet for handling onReady event\nonReady() {\n    extensions.initialized(payload);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onDialNumber"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este evento se activará cuando un usuario en HubSpot active una llamada saliente. El evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onDialNumber"
      }), " proporcionará una carga útil que incluye todos los campos pertinentes asociados con la llamada. Estos campos se detallan en la siguiente tabla:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onDialNumber(data) {\n  const {\n    phoneNumber: string,\n    ownerId: number,\n    subjectId: number,\n    objectId: number,\n    objectType: CONTACT | COMPANY,\n    portalId: number,\n    countryCode: string,\n    calleeInfo {\n        calleeId: number,\n        calleeObjectTypeId: string,\n    },\n    startTimestamp: number,\n    toPhoneNumberSrc: string,\n   } = data;\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| Propiedad | Tipo | Descripción |\n| --- | --- | --- | --- |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "phoneNumber"
      }), " | Cadena | El número de teléfono de la persona a la que llamó el usuario de HubSpot. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ownerId"
      }), " | Cadena | Número | El ID del usuario que ha iniciado sesión (en HubSpot). |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subjectId"
      }), " | Número | El ID del sujeto. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " | Número | Tipo de objeto del número de teléfono. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " | Cadena | El tipo de objeto asociado al número de teléfono que se marcó (por ejemplo, el número de teléfono del contacto o de la empresa). Los valores posibles aquí son ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"CONTACT\""
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"COMPANY\""
      }), ". |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), " | Número | El ID del portal de HubSpot. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "countryCode"
      }), " | Cadena | El código de país del número de teléfono. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calleeInfo"
      }), " | Matriz | Información sobre el destinatario de la llamada. Debe incluir lo siguiente:", (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "calleeID: number"
          })
        }), (0, _jsxRuntime.jsx)("li", {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "calleeObjectTypeId: string"
          })
        })]
      }), " |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "startTimestamp"
      }), " | Número | Marca de tiempo del inicio de la llamada. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toPhoneNumberSrc"
      }), " | Cadena | El nombre de la propiedad de los números de teléfono ' ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/create-and-edit-properties",
        children: "en HubSpot"
      }), ". La propiedad puede ser un valor de propiedad estándar o una propiedad personalizada. Por ejemplo, un contacto puede tener tres números de teléfono de contacto, uno puede estar etiquetado como ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Oficina"
      }), ", otro puede estar etiquetado como ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Personal"
      }), " y el otro puede estar etiquetado como ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Móvil"
      }), ". |"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onEngagementCreated"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obsoletos. Use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#onCreateEngagementSucceeded",
        children: "onCreateEngagementSucceeded"
      }), " en su lugar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/** @deprecated Use onCreateEngagementSucceeded instead **/\nonEngagementCreated(data) {\n    const {\n      engagementId: number,\n    } = data;\n      ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de interacción creado por HubSpot."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onCreateEngagementSucceeded"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot envía un mensaje para notificar al App Partner de llamada que la actualización de la interacción tuvo éxito."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementSucceeded: (event) => {};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onCreateEngagementFailed"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot envía un mensaje para notificar al App Partner de llamada que falló la creación de la interacción."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementFailed: (event) => {};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onVisibilityChanged"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mensaje que indica si el usuario ha minimizado u ocultado la aplicación de llamada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onVisibilityChanged(data) {\n    const { isMinimized, isHidden } = data;\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "defaultEventHandler"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Controlador predeterminado para eventos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "defaultEventHandler(event) {\n   console.info(\"Event received. Do you need to handle it?\", event);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SDK para llamadas | Preguntas frecuentes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Cómo se gestiona la autenticación de usuarios?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La aplicación de llamada debe manejar la autentificación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Las extensiones de llamada están alojadas en una CDN?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sí. Puedes instalar el SDK de extensiones de llamada a través de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.jsdelivr.com/",
        children: "jsDeliver"
      }), ". Por ejemplo, para instalar calling-extensions-sdk@0.2.2, puedes usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn.jsdelivr.net/npm/@hubspot/calling-extensions-sdk@0.2.2/dist/main.js",
        children: "https://cdn.jsdelivr.net/npm/@hubspot/calling-extensions-sdk@0.2.2/dist/main.js"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Cuándo debería crearse o actualizarse una interacción?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un usuario puede iniciar una llamada desde dentro de la interfaz de usuario de HubSpot y fuera de la interfaz de usuario de HubSpot (por ejemplo, aplicación móvil, número redirigido, etc.) Si se inicia una llamada desde la interfaz de usuario de HubSpot, HubSpot creará una interacción de llamada y enviará la interacción a la aplicación de llamada. Una vez que finaliza la llamada, la aplicación de llamadas puede actualizar esta interacción con detalles adicionales de la llamada. Si se inicia una llamada fuera de la interfaz de usuario de HubSpot, la aplicación debe crear la interacción de llamada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Qué alcances se requieren como parte de la integración?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se debe agregar contactos y alcances de cronología. Estos alcances aseguran que tu aplicación tenga acceso a contactos y la capacidad de crear y actualizar interacciones de llamadas en el CRM."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Se puede agregar esta funcionalidad a una aplicación ya existente en el mercado o creo una nueva aplicación?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si ya tienes una aplicación existente que sirve para el caso de uso de llamadas, puedes agregar directamente esta funcionalidad a la aplicación existente. Todos los clientes que ya tienen tu aplicación instalada tendrán acceso a esta nueva funcionalidad sin tener que volver a instalar la aplicación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Puedo integrar mi aplicación de softphone existente en el SDK?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sí, integrar tu aplicación de softphone existente debería ser muy fácil. Simplemente sigue los pasos de la documentación anterior para tener tu aplicación en funcionamiento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Se pueden usar varias integraciones al mismo tiempo?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sí, los usuarios pueden usar múltiples integraciones de llamadas de terceros al mismo tiempo. Pueden usar el conmutador de proveedor que se presenta después de hacer clic en el botón de llamada para cambiar sin problemas entre proveedores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Los usuarios gratuitos pueden instalar integraciones de aplicaciones?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sí, todos los usuarios pueden instalar la aplicación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Si un usuario ya tiene mi aplicación instalada, ¿la integración aparece automáticamente?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sí, si un usuario ya ha instalado tu aplicación y estás actualizando la misma aplicación con las extensiones de llamada, la integración aparecerá automáticamente. Actualmente, no hay manera de que el desarrollador habilite la aplicación de llamada solo para un subconjunto de clientes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Cualquier usuario puede instalar o desinstalar una aplicación?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No, solo los usuarios que tengan los permisos necesarios pueden instalar y desinstalar una aplicación. Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/edit-user-permissions",
        children: "revisar los permisos de un usuario"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Puedo crear una propiedad de llamadas personalizada?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sí, puedes crear una propiedad de llamada personalizada utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API de propiedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Puedo realizar una llamada desde un objeto personalizado?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sí, las integraciones de llamadas pueden realizar llamadas desde objetos personalizados siempre que solo usen el SDK para crear la llamada. Cada integración deberá verificar que solo usan el SDK para llamadas para crear llamadas y notificar a HubSpot en el evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "outgoingCall"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Primero, verifica que la integración esté usando el SDK de llamadas para crear interacciones en el evento outgoingCall:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "outgoingCall({ createEngagement: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createEngagement"
      }), " es verdadero, descubre cómo actualizar la información de tu aplicación ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-your-app-ready-for-production",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este es el ejemplo de todo el evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "outgoingCall"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  phoneNumber: string, // optional unless call is initiated by the widget\n  createEngagement: true // whether HubSpot should create an engagement for this call\n  callStartTime: number // optional unless call is initiated by the widget\n};\nextensions.outgoingCall(callInfo);\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}