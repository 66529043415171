'use es6';

/**
 * Determines if one or more specified props in the two sets are equal.
 *
 * @param  {Object}                 propsA  Props
 * @param  {Object}                 propsB  Props
 * @param  {String|Array.<String>}  key     A prop key, or an array thereof.
 * @return {Boolean}
 */
export function isPropsEqual(propsA, propsB, key) {
  if (!Array.isArray(key)) {
    return propsA[key] === propsB[key];
  }
  return key.every(k => propsA[k] === propsB[k]);
}

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}