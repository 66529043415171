"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164070526011;
const slug = exports.slug = 'guides/api/crm/commerce/carts';
const title = exports.title = 'CRM API | Carts';
const name = exports.name = 'CRM API | Carts';
const metaDescription = exports.metaDescription = "Learn about using HubSpot's APIs to create and manage ecommerce cart data.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-carts",
  "label": "Create carts",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "create-carts"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "create-carts"
}, {
  "depth": 0,
  "id": "retrieve-carts",
  "label": "Retrieve carts",
  "parent": null
}, {
  "depth": 1,
  "id": "search-for-carts-by-properties",
  "label": "Search for carts by properties",
  "parent": "retrieve-carts"
}, {
  "depth": 1,
  "id": "retrieve-a-cart-with-associations",
  "label": "Retrieve a cart with associations",
  "parent": "retrieve-carts"
}, {
  "depth": 0,
  "id": "update-carts",
  "label": "Update carts",
  "parent": null
}, {
  "depth": 0,
  "id": "cart-properties",
  "label": "Cart properties",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      code: "code",
      h3: "h3",
      a: "a",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Carts"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use the carts API to create and manage data related to ecommerce purchases in HubSpot. This can be especially useful for keeping HubSpot data synced with external ecommerce platforms, such as Shopify and NetSuite."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, use the API to sync cart data with Shopify, including associating the cart with an order and contact record."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create carts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a cart, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/cart"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the request body, you can include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " objects to set property values and associate the cart with other CRM objects (e.g., contacts and line items). Learn more about order properties and associations below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cart details are stored in cart properties. HubSpot provides a set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#cart-properties",
        children: "default cart properties"
      }), ", but you can also create your own custom properties using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To include properties when creating a cart, add them as fields in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " object in the request body.For example, the request body below would create a cart with some basic product details based on the information provided by the buyer at checkout."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request body\n{\n  \"properties\": {\n    \"hs_cart_name\": \"name-of-cart\",\n    \"hs_external_cart_id\": \"1234567890\",\n    \"hs_external_status\": \"pending\",\n    \"hs_source_store\": \"name-of-store\",\n    \"hs_total_price\": \"500\",\n    \"hs_currency_code\": \"USD\",\n    \"hs_cart_discount\": \"12\",\n    \"hs_tax\": \"36.25\",\n    \"hs_shipping_cost\": \"0\",\n    \"hs_tags\": \"frames, lenses\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include the information you provided during creation along with a few other default properties."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"55262618747\",\n  \"properties\": {\n    \"hs_cart_discount\": \"12\",\n    \"hs_cart_name\": \"name-of-cart\",\n    \"hs_external_cart_id\": \"1234567890\",\n    \"hs_created_by_user_id\": \"959199\",\n    \"hs_createdate\": \"2024-04-11T20:42:01.734Z\",\n    \"hs_currency_code\": \"USD\",\n    \"hs_exchange_rate\": \"1.0\",\n    \"hs_external_status\": \"pending\",\n    \"hs_homecurrency_amount\": \"500.0\",\n    \"hs_lastmodifieddate\": \"2024-04-11T20:42:01.734Z\",\n    \"hs_object_id\": \"55262618747\",\n    \"hs_object_source\": \"CRM_UI\",\n    \"hs_object_source_id\": \"userId:959199\",\n    \"hs_object_source_label\": \"CRM_UI\",\n    \"hs_object_source_user_id\": \"959199\",\n    \"hs_shipping_cost\": \"0\",\n    \"hs_source_store\": \"name-of-store\",\n    \"hs_tags\": \"frames, lenses\",\n    \"hs_tax\": \"36.25\",\n    \"hs_total_price\": \"500\",\n    \"hs_updated_by_user_id\": \"959199\"\n  },\n  \"createdAt\": \"2024-04-11T20:42:01.734Z\",\n  \"updatedAt\": \"2024-04-11T20:42:01.734Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can associate carts with other HubSpot CRM objects at creation by including an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " object. You can also use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API"
      }), " to update existing carts after creation."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " array, include the following fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Fields"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the record that you want to associate the cart with."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A unique identifier to indicate the association type between the cart and the other object. Below are the CRM objects that you can associate orders with, along with their ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ":", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/objects/contacts",
                  children: "Contacts"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "586"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/commerce/discounts",
                  children: "Discounts"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "588"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/objects/line-items",
                  children: "Line items"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "590"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/commerce/orders",
                  children: "Orders"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "592"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/commerce/quotes",
                  children: "Quotes"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "732"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/engagements/tasks",
                  children: "Tasks"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "728"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/objects/tickets",
                  children: "Tickets"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "594"
                })]
              })]
            }), "To see a list of all association types, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
              children: "associations API documentation"
            }), ". Or, you can retrieve each value by making a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request body below would create a cart that's associated with a specific contact and two line items."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 586\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 1243313490\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 590\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 1243557166\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 590\n        }\n      ]\n    }\n  ],\n  \"properties\": {\n    \"hs_external_cart_id\": \"1234567890\",\n    \"hs_external_status\": \"pending\",\n    \"hs_source_store\": \"name-of-store\",\n    \"hs_total_price\": \"500\",\n    \"hs_currency_code\": \"USD\",\n    \"hs_tax\": \"36.25\",\n    \"hs_tags\": \"donuts, bagels\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve carts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depending on the information you need, there are a few ways to retrieve carts:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve all carts, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/cart"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a specific cart, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to the above URL and specify a cart ID. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/cart/44446244097"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve carts that meet a specific set of criteria, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to the search endpoint and include filters in the request body. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search#make-a-search-request",
          children: "searching the CRM"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include a few default properties, including the create date, last modified date."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"55226265370\",\n  \"properties\": {\n    \"hs_createdate\": \"2024-04-10T18:59:32.441Z\",\n    \"hs_lastmodifieddate\": \"2024-04-10T18:59:32.441Z\",\n    \"hs_object_id\": \"55226265370\"\n  },\n  \"createdAt\": \"2024-04-10T18:59:32.441Z\",\n  \"updatedAt\": \"2024-04-10T18:59:32.441Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To return specific properties, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " query parameter in the request URL along with comma-separated property names. For example, making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the following URL would result in the response below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/cart?properties=hs_external_cart_id&hs_external_status"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"results\": [\n    {\n      \"id\": \"55226265370\",\n      \"properties\": {\n        \"hs_createdate\": \"2024-04-10T18:59:32.441Z\",\n        \"hs_external_cart_id\": \"1234567890\",\n        \"hs_lastmodifieddate\": \"2024-04-10T18:59:32.441Z\",\n        \"hs_object_id\": \"55226265370\"\n      },\n      \"createdAt\": \"2024-04-10T18:59:32.441Z\",\n      \"updatedAt\": \"2024-04-10T18:59:32.441Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"55262618747\",\n      \"properties\": {\n        \"hs_createdate\": \"2024-04-11T20:42:01.734Z\",\n        \"hs_external_cart_id\": \"8675309\",\n        \"hs_lastmodifieddate\": \"2024-04-11T20:42:01.734Z\",\n        \"hs_object_id\": \"55262618747\"\n      },\n      \"createdAt\": \"2024-04-11T20:42:01.734Z\",\n      \"updatedAt\": \"2024-04-11T20:42:01.734Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available cart properties, use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), " by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/cart"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#cart-properties",
        children: "cart properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Search for carts by properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the search endpoint to retrieve carts that meet a specific set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search#filter-search-results",
        children: "filter criteria"
      }), ". This will be a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request that includes your filter criteria in the request body."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to search for all carts placed at a specific store, you would make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/cart/search"
      }), " with the following request body:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example search request body\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"hs_source_store\",\n          \"value\": \"Cat Cafe - Portland\",\n          \"operator\": \"EQ\"\n        }\n      ]\n    }\n  ],\n  \"properties\": [\"hs_external_cart_id\", \"hs_source_store\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieve a cart with associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve a cart and the contact associated with it, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/cart/{cartId}/associations/contact"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This will return the IDs of the currently associated contact, along with meta information about the association type."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"results\": [\n    {\n      \"id\": \"301\",\n      \"type\": \"cart_to_contact\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then use the returned IDs to request more information about the contact through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "contacts API"
      }), ". Using the above example response, you would make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/contacts/301"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"301\",\n  \"properties\": {\n    \"createdate\": \"2022-09-27T13:13:31.004Z\",\n    \"email\": \"tom.bombadil@oldforest.com\",\n    \"firstname\": \"Tom\",\n    \"hs_object_id\": \"301\",\n    \"lastmodifieddate\": \"2023-11- 07T17:14:00.841Z\",\n    \"lastname\": \"Bombadil\"\n  },\n  \"createdAt\": \"2022-09-27T13:13:31.004Z\",\n  \"updatedAt\": \"2023-11-07T17:14:00.841Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note that the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filters"
      }), " array specifies the search criteria, while the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " array specifies which properties to return."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update carts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update a cart, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/cart/{cartId}"
      }), ". In the request body, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " object containing the properties that you want to update."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you wanted to update a cart after it's been fulfilled, you could send the following request body:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_external_status\": \"fulfilled\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include a set of default properties along with the property that you just set."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"55263075218\",\n  \"properties\": {\n    \"hs_created_by_user_id\": \"959199\",\n    \"hs_createdate\": \"2024-04-11T20:52:47.212Z\",\n    \"hs_external_status\": \"fulfilled\",\n    \"hs_lastmodifieddate\": \"2024-04-11T20:56:00.234Z\",\n    \"hs_object_id\": \"55263075218\",\n    \"hs_updated_by_user_id\": \"959199\"\n  },\n  \"createdAt\": \"2024-04-11T20:52:47.212Z\",\n  \"updatedAt\": \"2024-04-11T20:56:00.234Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update the associations for an existing cart, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/cart/{cartId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". You can also use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["See the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#associations",
          children: "associations section"
        }), " for ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), " values for cart-to-object associations. You can also make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To see all a list of all values, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
          children: "associations API documentation"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to associate a cart with an existing order, you would make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to the following URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/cart/{cartId}/associations/order/{orderId}/592"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response will return a set of default properties along with an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " object containing information about the association that you set."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"55262618747\",\n  \"properties\": {\n    \"hs_createdate\": \"2024-04-11T20:42:01.734Z\",\n    \"hs_lastmodifieddate\": \"2024-04-11T20:42:01.734Z\",\n    \"hs_object_id\": \"55262618747\"\n  },\n  \"createdAt\": \"2024-04-11T20:42:01.734Z\",\n  \"updatedAt\": \"2024-04-11T20:42:01.734Z\",\n  \"archived\": false,\n  \"associations\": {\n    \"orders\": {\n      \"results\": [\n        {\n          \"id\": \"54807577390\",\n          \"type\": \"cart_to_order\"\n        }\n      ]\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove an association from an existing cart, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to the following URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/cart/{cartId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you wanted to remove an associated line item from a cart, your request URL would be the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/cart/{cartId}/associations/line_items/{lineItemId}/590"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cart properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When managing your cart data, you may want to use some of the common properties in the table below. To get all cart properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/cart"
      }), ". Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Label in UI"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_cart_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name in an external system."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_cart_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cart ID"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unique identifier from an external system."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_source_store"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Source Store"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Data used to identify which store the cart came from."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Status"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The current status of the cart."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_cart_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cart Url"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recovery URL that's sent to a customer so they can revive an abandoned cart."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_total_price"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Total Price"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The sum total amount associated with the cart."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_currency_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Currency Code"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The currency code used in the cart."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_cart_discount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cart Discount"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Amount of discount in the cart."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tags"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A collection of tag strings for the cart."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}