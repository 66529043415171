"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 85488833916;
const slug = exports.slug = 'guides/api/crm/exports';
const title = exports.title = 'API du CRM | Exports';
const name = exports.name = 'API du CRM | Exports';
const metaDescription = exports.metaDescription = 'Découvrez comment utiliser l’API d’exports pour exporter des fiches d’informations dans des vues ou des listes depuis votre compte HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "lancer-un-export",
  "label": "Lancer un export",
  "parent": null
}, {
  "depth": 1,
  "id": "exporter-une-vue",
  "label": "Exporter une vue",
  "parent": "lancer-un-export"
}, {
  "depth": 1,
  "id": "exporter-une-liste",
  "label": "Exporter une liste",
  "parent": "lancer-un-export"
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-les-exports",
  "label": "Récupérer les exports",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-d-utilisation",
  "label": "Limites d'utilisation",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      pre: "pre",
      strong: "strong",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Exports"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez l’API d’exports pour exporter des fiches d’informations et des données de propriété depuis votre compte HubSpot, récupérez une URL pour télécharger un fichier d’export ou voir le statut d’un export. Dans HubSpot, vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/export-contacts-companies-deals-or-tickets",
        children: "exporter des fiches d'informations"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-log-of-your-users-exports-in-your-account",
        children: "afficher un journal des exports passés dans votre compte."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lancer un export"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour démarrer un export, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async"
      }), ". Le corps de votre demande doit indiquer des informations telles que le format de fichier, l’objet et les propriétés que vous souhaitez exporter ainsi que le type d’export que vous effectuez (par exemple : l’export d’une vue d’objet ou d’une liste). Vous pouvez également filtrer les données de propriété à exporter en fonction d’opérateurs spécifiques."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour les exports de vues et de listes, vous pouvez inclure les champs suivants dans votre demande :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d’export, soit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VIEW"
            }), " (exporte une vue depuis une page index d’objet), soit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LIST"
            }), " (exporte une liste)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le format du fichier. Les options sont : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLSX"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLS"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom de l’export."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La langue du fichier d’export. Les options sont : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ES"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FI"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FR"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "JA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PT"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SV"
            }), ". En savoir plus sur ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-language-offerings",
              children: "les langues prises en charge."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le nom ou l’ID de l’objet que vous exportez. Pour les objets standards, vous pouvez utiliser le nom de l’objet (par ex : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            }), "), mais pour les objets personnalisés, vous devez utiliser la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            }), ". Vous pouvez récupérer cette valeur en faisant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/schemas"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associatedObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom ou l’ID de l’objet associé à inclure dans l’export. Si vous incluez un objet associé, l'export contiendra les ID de fiche d’informations associés de cet objet et la valeur de la propriété d’affichage principale des fiches d’informations (par exemple : nom). Vous ne pouvez exporter qu’un seul objet associé par demande."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste des propriétés que vous voulez inclure dans votre export."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exporter une vue"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous exportez une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "vue de page d'index"
      }), ", votre valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), " doit être", (0, _jsxRuntime.jsx)(_components.code, {
        children: "VIEW"
      }), ", et vous pouvez inclure le champ suivant pour filtrer et trier les fiches d’informations que vous exportez :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publicCrmSearchRequest"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indique quelles données doivent être exportées en fonction de certaines valeurs de propriétés et requêtes de recherche. Vous pouvez inclure les éléments suivants dans l’objet :", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "filters"
            }), " : les propriétés et les valeurs de propriété selon lesquelles filtrer les fiches d’informations.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "sorts"
            }), (0, _jsxRuntime.jsx)("br", {}), " : l’ordre de tri des valeurs d’une propriété, croissant, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASC"
            }), " ou décroissant, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DES"
            }), ".", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "query"
            }), " : une chaîne pour rechercher les valeurs des fiches d’informations."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, pour exporter une vue des contacts et des fiches d’informations d’entreprise associées, filtrée par la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", votre demande peut ressembler à ce qui suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"VIEW\",\n  \"exportName\": \"All contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"DE\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email, firstname, lastname\"],\n  \"associatedObjectType\": \"COMPANY\",\n  \"publicCrmSearchRequest\": {\n    \"filters\": [\n      {\n        \"value\": \"hello@test.com\",\n        \"propertyName\": \"email\",\n        \"operator\": \"EQ\"\n      }\n    ],\n    \"query\": \"hello\",\n    \"sorts\": [\n      {\n        \"propertyName\": \"email\",\n        \"order\": \"ASC\"\n      }\n    ]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exporter une liste"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous exportez une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/lists/create-active-or-static-lists",
        children: "liste"
      }), ", votre valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), " doit être ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LIST"
      }), ", mais vous devez également indiquer la liste que vous exportez avec le champ suivant :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/lists/lists-faq#ils-list",
              children: "L’ID de liste ILS"
            }), " de la liste à exporter. Vous pouvez trouver la valeur de l’ID de liste ILS via les détails de la liste dans HubSpot. Accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contacts"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Listes"
            }), ", survolez la ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "liste"
            }), " dans le tableau, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Détails"
            }), ". Dans le panneau de droite, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Copier"
            }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "l’ID de liste"
            }), " à côté de la valeur ID de liste ILS. Les listes de contacts ont deux valeurs d’ID différentes, mais vous ", (0, _jsxRuntime.jsx)("u", {
              children: "devez"
            }), " utiliser la valeur d’ID de liste ILS dans votre demande."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour exporter une liste contenant les adresses e-mail des contacts, votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"LIST\",\n  \"listId\": 1234567,\n  \"exportName\": \"Marketing email contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"EN\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email\"]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer les exports"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous terminez un export, l'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de l'export sera renvoyé dans la réponse. Pour récupérer un export depuis votre compte HubSpot, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async/tasks/{exportId}/status"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la récupération des exports, le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "status"
      }), " de l’export sera également renvoyé. Les statuts possibles sont : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PENDING"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSING"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CANCELED"
      }), ". Pour les exports avec un statut ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), ", une URL est renvoyée que vous pouvez utiliser pour télécharger le fichier exporté. L’URL de téléchargement expirera cinq minutes après la fin de la demande. Une fois expirée, vous pouvez effectuer une autre demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " pour générer une nouvelle URL unique."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : avant l’expiration, l’URL de téléchargement de l’export est accessible sans autorisation supplémentaire. Pour protéger vos données, soyez vigilant lorsque vous partagez une URL ou effectuez une intégration avec HubSpot via cette API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites d'utilisation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les limites suivantes s’appliquent :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lorsque vous configurez des filtres pour votre export, vous pouvez en inclure un maximum de trois ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " , avec jusqu’à trois ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " dans chaque groupe."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous pouvez effectuer jusqu’à trente exports dans une fenêtre de 24 heures consécutives, et un export à la fois. Les exports supplémentaires seront mis en file d’attente jusqu’à ce que l’export précédent soit terminé."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un fichier CSV exporté sera automatiquement compressé si la taille du fichier obtenu est supérieure à 2 Mo."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}