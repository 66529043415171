"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42366481035;
const slug = exports.slug = 'guides/api/marketing/subscriptions-preferences';
const title = exports.title = 'マーケティングAPI | 配信設定';
const name = exports.name = 'APAC JAPAN (JA) | vNext Docs DP - Subscriptions Preferences | 202104';
const metaDescription = exports.metaDescription = ' 配信設定エンドポイントでは、HubSpotポータル内のコンタクトに関する配信登録の詳細を管理できます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%81%AE%E9%85%8D%E4%BF%A1%E7%99%BB%E9%8C%B2%E3%82%B9%E3%83%86%E3%83%BC%E3%82%BF%E3%82%B9%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "コンタクトの配信登録ステータスを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E9%85%8D%E4%BF%A1%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B",
  "label": "コンタクトを配信登録する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E7%99%BB%E9%8C%B2%E8%A7%A3%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "コンタクトを登録解除する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%BF%E3%82%A4%E3%83%97%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "サブスクリプションタイプを取得する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      hr: "hr",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "配信設定"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["配信カテゴリーは、Eメールを通じてコンタクトと通信するための法的根拠を示すものです。コンタクトは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/articles/kcs_article/email/how-to-send-contacts-an-email-with-an-opt-out-link-so-they-can-manage-their-preferences",
        children: "Eメール設定を管理"
      }), "できるため、受信を希望するEメールにのみオプトインします。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**現時点では、配信設定APIは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/inbox/collect-consent-for-whatsapp-conversations",
          children: "WhatsApp"
        }), "の配信登録情報の受信および更新をサポート", (0, _jsxRuntime.jsx)("u", {
          children: "していません"
        }), "。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンタクトの配信登録ステータスを取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンタクト配信登録ステータスエンドポイントから、アカウント内のEメールアドレスに関する配信登録のステータスを取得できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "外部の配信設定センターまたは連携機能を使用している状態で、アカウント内の特定のEメールアドレスの配信登録ステータスを確認する場合に、このエンドポイントが役立ちます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンタクトを配信登録する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクト配信登録エンドポイントでは、アカウント内の特定の配信カテゴリー(サブスクリプションタイプ)にEメールアドレスを登録できますが、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "オプトアウトしたコンタクトの再登録はできません"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**使用例：**連携機能または外部フォームを通じて、特定の配信カテゴリー（サブスクリプションタイプ）へのコンタクトのオプトインを行う場合に、このエンドポイントが役立ちます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "注"
      }), "：コンタクト配信登録エンドポイントは、貴社による配信登録を認めたコンタクトからのリクエストにのみ使用してください。コンタクトの配信登録の前には、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/contacts/how-do-subscription-preferences-and-types-work",
        children: "適用される法令および規制を十分に確認"
      }), "してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンタクトを登録解除する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンタクト配信解除エンドポイントでは、アカウント内のEメールアドレスを特定の配信カテゴリー(サブスクリプションタイプ)から解除できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**使用例：**連携機能または外部フォームを通じて、特定の配信カテゴリー（サブスクリプションタイプ）からのコンタクトのオプトアウトを行う場合に、このエンドポイントが役立ちます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サブスクリプションタイプを取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "配信情報エンドポイントを使用すると、アカウント内の全てのサブスクリプションタイプを取得できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**使用例：**外部の配信設定センター、連携機能、またはフォームを使用している状態で、（コンタクトに関する最新の配信登録ステータスを取得する際に）アカウント内に存在する配信カテゴリー（サブスクリプションタイプ）を確認する場合に、このエンドポイントが役立ちます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {})]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}