"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358768;
const slug = exports.slug = 'guides/api/cms/hubdb';
const title = exports.title = 'API du CMS | HubDB';
const name = exports.name = 'API du CMS | HubDB';
const metaDescription = exports.metaDescription = 'Les points de terminaison HubDB sont utilisés pour obtenir et gérer des données dans vos tableaux de données HubDB.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "limites-de-taux",
  "label": "Limites de taux",
  "parent": null
}, {
  "depth": 0,
  "id": "brouillons-de-tableaux-compar%C3%A9s-aux-tableaux-en-ligne",
  "label": "Brouillons de tableaux comparés aux tableaux en ligne",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-un-tableau-hubdb",
  "label": "Créer un tableau HubDB",
  "parent": null
}, {
  "depth": 1,
  "id": "ajouter-des-colonnes-de-tableau",
  "label": "Ajouter des colonnes de tableau",
  "parent": "cr%C3%A9er-un-tableau-hubdb"
}, {
  "depth": 1,
  "id": "ajouter-des-lignes-de-tableau",
  "label": "Ajouter des lignes de tableau",
  "parent": "cr%C3%A9er-un-tableau-hubdb"
}, {
  "depth": 1,
  "id": "importer-des-lignes-depuis-csv",
  "label": "Importer des lignes depuis CSV",
  "parent": "cr%C3%A9er-un-tableau-hubdb"
}, {
  "depth": 1,
  "id": "formatage-de-la-date",
  "label": "Formatage de la date",
  "parent": "cr%C3%A9er-un-tableau-hubdb"
}, {
  "depth": 1,
  "id": "r%C3%A9initialiser-les-options",
  "label": "Réinitialiser les options",
  "parent": "cr%C3%A9er-un-tableau-hubdb"
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-les-donn%C3%A9es-hubdb",
  "label": "Récupérer les données HubDB",
  "parent": null
}, {
  "depth": 1,
  "id": "filtrer-les-lignes-renvoy%C3%A9es",
  "label": "Filtrer les lignes renvoyées",
  "parent": "r%C3%A9cup%C3%A9rer-les-donn%C3%A9es-hubdb"
}, {
  "depth": 1,
  "id": "trier-les-lignes-renvoy%C3%A9es",
  "label": "Trier les lignes renvoyées",
  "parent": "r%C3%A9cup%C3%A9rer-les-donn%C3%A9es-hubdb"
}, {
  "depth": 0,
  "id": "configurer-les-tableaux-hubdb-pour-les-pages-dynamiques",
  "label": "Configurer les tableaux HubDB pour les pages dynamiques",
  "parent": null
}, {
  "depth": 0,
  "id": "modifications-dans-la-v3",
  "label": "Modifications dans la v3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      em: "em",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API du CMS | HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDB est une banque de données relationnelles, qui présente les données sous forme de lignes, de colonnes et de cellules dans un tableau, un peu comme une feuille de calcul. Les tableaux HubDB peuvent être ajoutés ou modifiés ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview#creating-your-first-table",
        children: "dans votre compte HubSpot"
      }), ", mais vous pouvez également utiliser les points de terminaison d'API documentés ici. Pour plus d'informations sur l'utilisation des données des tableaux HubDB sur votre site web ou dans ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "les e-mails programmables"
      }), ", consultez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "la documentation du développeur du CMS Hub."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comme pour les pages de site web HubSpot, les tableaux HubDB prennent en charge les versions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "draft"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "published"
      }), ". Cela vous permet de mettre à jour les données du tableau, soit à des fins de test, soit pour permettre un processus d'approbation manuel, sans affecter les pages en ligne. Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#draft-vs-live-tables",
        children: "tableaux brouillons et les tableaux en ligne"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si un tableau est défini pour être ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "accessible au public"
      }), ", vous pouvez accéder à la version publiée du tableau et des lignes sans aucune authentification en spécifiant votre ID de compte HubSpot via le paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous effectuez une migration à partir de la v2 de l'API HubDB, découvrez les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#changes-in-v3",
        children: "modifications apportées à l'API (v3) actuelle"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " les points de terminaison qui prennent en charge ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " prennent également en charge ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), ", afin que vous puissiez accéder aux données d'un tableau côté client à l'aide de JavaScript et de votre ID de compte. D'autres méthodes et le point de terminaison ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Obtenir tous les tableaux"
        }), " nécessite une authentification et ne prend pas en charge ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites de taux"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les requêtes d'API HubDB ont des limites de taux différentes, selon le type de demande :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les demandes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " effectuées qui ne nécessitent pas d'authentification (dont les requêtes JavaScript côté client) sont limitées à 10 demandes par seconde. Ces demandes ne seront pas comptabilisées dans la limite quotidienne."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Toutes les autres demandes ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "utilisant l'authentification"
        }), " suivent les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api",
          children: "limites standard"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Brouillons de tableaux comparés aux tableaux en ligne"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les tableaux HubDB ont à la fois des brouillons et des versions en ligne, et les versions en ligne peuvent être publiées ou non publiées. Cela vous permettra de mettre à jour les données du tableau, soit pour des aperçus de page ou des tests, soit pour permettre un processus d'approbation manuel, sans affecter les pages en ligne."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans cette API, des points de terminaison distincts sont désignés pour les brouillons et les versions publiées d'un tableau. Par exemple, vous pouvez récupérer la version publiée d'un tableau en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " au point de terminaison suivant :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aussi, pour récupérer tout contenu brouillon qui n'a pas encore été publié, vous devez ajouter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/draft"
      }), " à la fin de l'URL :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les brouillons de données peuvent être analysés puis envoyés dans HubSpot ou avec le point de terminaison ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/push-live"
      }), ". Les données provisoires peuvent également être supprimées via un point de terminaison ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/reset"
      }), ", ce qui vous permet de revenir à la version actuelle en ligne des données sans interruption."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un tableau HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un tableau HubDB, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le corps de la requête, spécifiez les champs obligatoires suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom interne du tableau. Ce nom ne peut pas être modifié une fois le tableau créé. Les noms peuvent uniquement contenir des minuscules, des chiffres et des tirets du bas et ne peuvent pas commencer par un chiffre."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"my_table\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le libellé du tableau que les utilisateurs voient lorsqu'ils modifient le tableau dans HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\":\"My table\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En outre, vous pouvez spécifier les champs facultatifs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : précise si le tableau peut être utilisé pour ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#configuring-hubdb-tables-for-dynamic-pages",
              children: "créer de pages dynamiques"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"useForPages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowPublicAPIAccess"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : précise si le tableau peut être lu sans autorisation."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowPublicApiAccess\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowChildTables"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : précise si des tableaux enfants peuvent être créés pour le tableau."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowChildTables\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableChildTablePages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : précise si des ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "pages dynamiques à plusieurs niveaux"
            }), " doivent être créées en utilisant des tableaux enfants."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"enableChildTablePages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : les colonnes du tableau. Découvrez-en davantage sur les propriétés de colonne dans les sections ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-table-columns",
              children: "Ajouter des colonnes de tableau"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "See \"Add table columns\" section below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sans colonnes ajoutées pour le moment, votre demande de création pourrait ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"name\": \"test_table\",\n  \"label\": \"Test Table\",\n  \"useForPages\": false,\n  \"allowPublicApiAccess\": false,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"columns\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ajouter des colonnes de tableau"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Chaque colonne d'un tableau HubDB peut être définie avec les propriétés suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : obligatoire. Le nom interne de la colonne. Ne peut pas être modifié après la création de la colonne."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"row_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "facultative. Le libellé de la colonne que les utilisateurs voient lorsqu'ils modifient le tableau dans HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\": \"Row label\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le type de données de la colonne. Doit être l'un des éléments suivants :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TEXT"
                }), " : un champ de texte."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "RICHTEXT"
                }), " : un champ de texte qui prend en charge le formatage HTML de base. Cette option n'est pas recommandée pour le HTML brut, car elle peut avoir un impact sur la possibilité de modifier le code HTML dans HubSpot. La modification du code dans HubSpot peut également affecter la façon dont le code est affiché. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NUMBER"
                }), " : un champ numérique."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOOLEAN"
                }), " : représenté sous la forme d'une case à cocher dans HubSpot. Utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "0"
                }), " pour non coché et ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                }), " pour coché."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATE"
                }), " : stocke une date spécifique sous forme d'horodatage en millisecondes fixé à minuit UTC."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATETIME"
                }), " : stocke une date et une heure sous forme d'horodatage en millisecondes."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "SELECT"
                }), " : la colonne ne peut être définie que sur l'un des ensembles d'options. Consultez le champ ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), " ci-dessous pour les propriétés requises. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MULTISELECT"
                }), " : la colonne peut être définie sur un ou plusieurs ensembles d'options. Voir le champ ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), " ci-dessous pour les propriétés requises."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "LOCATION"
                }), " : stocke un emplacement de latitude et de longitude."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "IMAGE"
                }), " : stocke l'URL d'une image."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "VIDEO"
                }), " : stocke l'ID du lecteur de la vidéo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FOREIGN_ID"
                }), " : la colonne fera référence à une colonne d'un autre tableau HubDB. En outre, vous devez définir l'autre tableau HubDB avec les propriétés suivantes :", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: "foreignTableId : l'ID de l'autre tableau HubDB. "
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: "foreignColumnId : l'ID de la colonne dans l'autre tableau HubDB."
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CURRENCY"
                }), " : stocke le nombre comme une valeur monétaire."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), " : stocke un fichier à partir du gestionnaire de fichiers. Vous devrez également inclure un champ ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "fileType"
                }), " pour indiquer si le champ peut stocker tous les types de fichiers (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), ") ou seulement des types de documents tels que les PDF (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DOCUMENT"
                }), ")."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"type\": \"type\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : une liste d'options pour la sélection et la sélection multiple des colonnes. Chaque option est définie par un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " avec un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), " égal à ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option\": [{\"name\":\"Option 1\", \"type\":\"option\"}, {\"name\": \"Option 2\", \"type\": \"option\"}]"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En utilisant les champs ci-dessus, votre demande de création d'un nouveau tableau HubDB pourrait ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"label\": \"Test Table\",\n  \"name\": \"test_table\",\n  \"columns\": [\n    {\n      \"name\": \"text_column\",\n      \"label\": \"Text Column\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"number_column\",\n      \"label\": \"Number Column\",\n      \"archived\": false,\n      \"type\": \"NUMBER\"\n    },\n    {\n      \"name\": \"multiselect\",\n      \"label\": \"Multi Select Column\",\n      \"archived\": false,\n      \"type\": \"multiselect\",\n      \"options\": [\n        {\n          \"name\": \"Option 1\",\n          \"type\": \"option\"\n        },\n        {\n          \"name\": \"Option 2\",\n          \"type\": \"option\"\n        }\n      ]\n    }\n  ],\n  \"useForPages\": true,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"allowPublicApiAccess\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après la création d'un tableau, des ID seront attribués aux colonnes dans l'ordre croissant. Lors de la mise à jour de colonnes existantes, incluez le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la colonne dans l'objet d'entrée."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ajouter des lignes de tableau"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ajouter des lignes manuellement via l'API ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#import-rows-from-csv",
        children: "importer des lignes depuis un fichier CSV"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour ajouter des lignes à un tableau HubDB, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/rows"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour chaque ligne du tableau, vous pouvez inclure les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : une liste de paires clé-valeur avec le nom de la colonne et la valeur que vous souhaitez y ajouter.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "Si vous ne souhaitez pas définir de valeur spécifique pour une colonne, vous pouvez omettre le nom de la colonne dans la liste des paires clé-valeur."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"values\": { \"text_column\": \"sample text\", \"number_column\": 76}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : pour les tableaux ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "activés pour les pages dynamiques"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), " est le suffixe de chemin d'accès utilisé pour la page créée pour cette ligne."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"path\": \"example_url_path\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : pour les tableaux ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "activés pour les pages dynamiques"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " est le titre HTML utilisé pour la page créée pour cette ligne."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"Example Title\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : lors de la création de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "pages dynamiques à plusieurs niveaux"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), " spécifie l'ID de tableau enfant."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableId\": 123456"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En utilisant les champs ci-dessus, votre demande pourrait ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"values\": {\n    \"text_column\": \"sample text value\",\n    \"number_column\": 76,\n    \"rich_text_column\": \"<strong>This is a styled paragraph.</strong>\",\n    \"date_column\": 1591228800000,\n    \"date_time_column\": 1604450520000,\n    \"boolean_column\": 1,\n    \"select_column\": {\n      \"name\": \"option 1\",\n      \"type\": \"option\"\n    },\n    \"multiselect_column\": [\n      {\n        \"name\": \"Option 1\",\n        \"type\": \"option\"\n      },\n      {\n        \"name\": \"Option 2\",\n        \"type\": \"option\"\n      }\n    ],\n    \"url_column\": \"https://www.hubspot.com/marketing\",\n    \"video_column\": 3392210008,\n    \"image_column\": {\n      \"url\": \"https://f.hubspotusercontentqa00.net/hubfs/99992002/image3%20(1).jpg\",\n      \"width\": 1600,\n      \"height\": 900,\n      \"type\": \"image\"\n    },\n    \"foreign_id_column\": [\n      {\n        \"id\": \"4364402239\",\n        \"type\": \"foreignid\"\n      },\n      {\n        \"id\": \"4364402240\",\n        \"type\": \"foreignid\"\n      }\n    ]\n  },\n  \"path\": \"test_path\",\n  \"name\": \"test_title\",\n  \"childTableId\": \"1902373\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Importer des lignes depuis CSV"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour importer des données dans un tableau HubDB à partir d'un fichier CSV, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft/import"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le point de terminaison d'import accepte une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "config"
        }), "** :** un ensemble d'options JSON pour l'import."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "file"
        }), "** :** le fichier CSV que vous souhaitez importer."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), ", ajoutez les champs suivants en tant que chaîne JSON :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "skipRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : nombre de lignes d'en-tête à ignorer. La valeur par défaut de ce champ est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), ", en ignorant la première ligne et en la traitant comme un en-tête. Définissez ce paramètre sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " si toutes les lignes sont des données valides."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"skipRows\": 0"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "separator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : délimiteur de colonne dans le fichier CSV. Défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\",\""
            }), " par défaut."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"separator\": \",\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : l'index de la colonne dans le fichier source contenant l'ID de la ligne (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), "). Si cette colonne est spécifiée, l'import mettra à jour les lignes existantes dans le tableau pour les ID de lignes correspondants à partir du fichier CSV. Il s'agit d'une option facultative que vous pouvez ignorer la première fois que vous importez des données dans un tableau. Consultez la section ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "Options de réinitialisation"
            }), " ci-dessous pour plus d'informations."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"idSourceColumn\": 1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "resetTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : la valeur par défaut de booléen est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", ce qui signifie que les lignes du tableau seront mises à jour sans supprimer de lignes existantes. Si cette valeur est définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", les lignes de la feuille de calcul remplaceront les données du tableau, ce qui signifie que toutes les lignes du tableau qui n'y figurent pas seront supprimées. Consultez la section ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "Options de réinitialisation"
            }), " ci-dessous pour plus d'informations."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"resetTable\": true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : pour les tableaux ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "activés pour les pages dynamiques"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            }), " spécifie la colonne du fichier CSV qui contient le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " de la ligne. Les numéros de colonne sont dans l'ordre croissant, la première colonne étant ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"nameSourcecolumn\": 5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : pour les tableaux ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "activés pour les pages dynamiques"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            }), " spécifie la colonne du fichier CSV qui contient le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), " de la ligne. Les numéros de colonne sont dans l'ordre croissant, la première colonne étant ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"pathSourcecolumn\": 6"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : spécifie la colonne du fichier CSV qui contient le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), " de la ligne. Les numéros de colonne sont dans l'ordre croissant, la première colonne étant ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableSourcecolumn\": 3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : une liste des mappages entre les colonnes du fichier source et les colonnes du tableau HubDB de destination. Chaque mappage doit avoir le format suivant : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"source\":1,\"target”:\"columnIdOrName\"}"
            }), " ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "source :"
                }), " l'index des colonnes dans le fichier source. Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                }), " pour la deuxième colonne."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "cible :"
                }), " l'ID ou le nom de la colonne du tableau HubDB. Vous pouvez obtenir l'ID ou le nom d'une colonne en ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#retrieve-hubdb-data",
                  children: "obtenant les détails du tableau"
                }), "."]
              })]
            }), "Si votre fichier contient une colonne ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), ", vous ne devez pas l'inclure dans ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), ". Incluez-le à la place en tant que ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            }), " mettre à jour les lignes existantes."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"columnMappings\": [{\"source\":1, \"target\": 2}, {\"source\": 2, \"target\": \"column_name\"}]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryKeyColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom d'une colonne dans le tableau HubDB cible qui sera utilisée pour la déduplication. L'ID de la colonne ne peut pas être utilisé pour ce champ."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"primaryKeyColumn\": \"column_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "encoding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le type d'encodage du fichier. Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utf-8"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ascii"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-5"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-2022-jp"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "windows-1252"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"encoding\": \"utf-8\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : seul le format CSV est pris en charge."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"format\": \"csv\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En utilisant le tableau ci-dessus, votre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), " JSON pourrait ressembler à ce qui suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example config JSON\n{\n  \"skipRows\": 1,\n  \"separator\": \",\",\n  \"idSourceColumn\": 1,\n  \"resetTable\": false,\n  \"columnMappings\": [\n    {\n      \"target\": 1,\n      \"source\": 2\n    },\n    {\n      \"target\": 2,\n      \"source\": \"zip_code\"\n    }\n  ],\n  \"primaryKeyColumn\": \"name\",\n  \"encoding\": \"utf-8\",\n  \"format\": \"csv\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous utilisez cURL, votre commande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -L -X POST 'https://api.hubspotqa.com/hubdb/api/v2/tables/xxxxxx/import?portalId=xxxxxxx' \\\n-H 'Content-Type: multipart/form-data' \\\n-F 'config=\"{\\\"skipRows\\\":1,\\\"format\\\":\\\"csv\\\",\\\"separator\\\":\\\",\\\",\\\"encoding\\\":\\\"iso-8859-1\\\",\\\"columnMappings\\\":[{\\\"target\\\":1,\\\"source\\\":7},{\\\"target\\\":3,\\\"source\\\":8}],\\\"idSourceColumn\\\":1,\\\"pathSourceColumn\\\":null,\\\"nameSourceColumn\\\":null,\\\"childTableSourceColumn\\\":null,\\\"resetTable\\\":true}\"' \\\n-F 'file=@\"/Users/xxxxxxxxxxxxx/Downloads/filename.csv\"'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formatage de la date"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Plusieurs formats peuvent être utilisés lors de l'import de données dans une colonne de type date."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Entiers"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yyyy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yy"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ces formats exigent que le mois précède le jour (c'est-à-dire que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dd/mm/yy"
      }), " n'est pas accepté). Les entiers peuvent être séparés par des traits d'union (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), ") ou des barres obliques (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Dates détendues"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez également importer des formats de date moins standardisés que les dates basées sur des entiers. Par exemple : **"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "The 1st of March in the year 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Fri Mar 4 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "March 4th '22"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Dates relatives"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot analysera les formats de date suivants par rapport au jour en cours : **"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "next Thursday"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Today"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "tomorrow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "3 days from now"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Réinitialiser les options"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous importez des données depuis un fichier CSV dans un tableau HubDB, vous pouvez définir le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "resetTable"
      }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " (par défaut) pour gérer le remplacement des données de ligne HubDB."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Si ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), " est défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), " :"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si les lignes du fichier CSV ne disposent pas d'une colonne ID de ligne (si ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), " ou l'ID de ligne est spécifié comme ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", ces lignes seront insérées avec les nouveaux ID de lignes générés."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si les ID de ligne du fichier CSV existent déjà dans le tableau cible, les lignes existantes du tableau seront mises à jour avec les nouvelles valeurs du fichier d'entrée."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si le tableau contient des lignes, mais que le fichier CSV d'entrée ne contient pas ces ID de ligne, ces lignes seront supprimées du tableau cible."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si les ID de lignes du fichier CSV d'entrée n'existent pas dans le tableau cible, ces lignes seront insérées avec les nouveaux ID de lignes générés et les ID de lignes donnés dans le fichier d'entrée seront ignorés."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si le fichier CSV d'entrée ne contient pas du tout la colonne ID de ligne, toutes les lignes seront supprimées du tableau cible et les lignes du fichier d'entrée seront insérées avec les nouveaux ID de lignes générés."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Si ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), " est défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), " (valeur par défaut) :"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si les ID de ligne du fichier CSV existent déjà dans le tableau cible, les lignes existantes du tableau seront mises à jour avec les nouvelles valeurs du fichier d'entrée."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si le tableau contient des lignes, mais que le fichier CSV d'entrée ne contient pas ces ID de ligne, ces lignes ne seront ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "pas"
            }), " supprimées du tableau cible et ces lignes resteront inchangées."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si les ID de lignes du fichier CSV d'entrée n'existent pas dans le tableau cible, ces lignes seront insérées avec les nouveaux ID de lignes générés et les ID de lignes donnés dans le fichier d'entrée seront ignorés."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si les lignes du fichier CSV ne contiennent pas de colonne ID de ligne ou si l'ID de ligne est défini comme ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", ces lignes seront insérées avec les nouveaux ID de lignes générés."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer les données HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe plusieurs façons de récupérer des données HubDB, selon que vous recherchez les détails du tableau ou les lignes d'un tableau :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer les détails de tous les tableaux publiés, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer les détails d'un tableau publié spécifique, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer toutes les lignes d'un tableau spécifique, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}/rows"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer une ligne spécifique d'un tableau, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}/rows/{rowId}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous récupérez des données de ligne, vous pouvez filtrer et trier davantage les résultats."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si un tableau est défini pour être ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "accessible au public"
      }), ", vous pouvez accéder à la version publiée du tableau et des lignes sans aucune authentification en spécifiant votre ID de compte HubSpot via le paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Filtrer les lignes renvoyées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la récupération des données de tableau HubDB, vous pouvez appliquer des filtres comme paramètres de requête pour recevoir des données spécifiques. Les paramètres de requête de filtre sont construits comme suit : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "columnName__operator"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, si vous disposez d'une colonne numérique nommée ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "bar"
      }), ", vous pouvez filtrer les résultats pour n'inclure que les lignes où ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "bar"
      }), " est supérieur à 10 : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&bar__gt=10"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tous les filtres sont associés sous forme de ET (les filtres OU ne sont pas pris en charge actuellement)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors du filtrage, tenez compte des informations suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Lorsque vous transmettez des valeurs pour des colonnes ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect"
          }), ", les valeurs doivent être séparées par des virgules (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect_column__contains=1,2"
          }), ")."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Pour les filtres ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "datetime"
          }), ", vous pouvez utiliser des dates relatives à la place des horodatages afin de spécifier une valeur relative à l'heure actuelle. Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "-3h"
          }), " correspondrait à l'horodatage de 3 heures avant le moment présent, alors que ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "10s"
          }), " correspondrait à 10 secondes dans le futur. Les unités de temps prises en charge sont ms (millisecondes), s (secondes), m (minutes), h (heures), j (jours). L'heure actuelle peut être utilisée en spécifiant une valeur zéro : 0 s"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Pour les besoins de ces filtres, la colonne intégrée ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_id"
          }), " est une colonne ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "number"
          }), ", la colonne ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_created_at"
          }), " est une colonne ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "datetime"
          }), ", et les colonnes ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_path"
          }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_name"
          }), " sont des colonnes ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous quels opérateurs peuvent être appliqués à quels types de colonnes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opérateur"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq (or none)"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Égaux"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : tous les types de colonnes. Ce filtre est appliqué si aucun opérateur n'est utilisé. Lorsqu'il est utilisé avec des colonnes à sélection multiple, renvoie des lignes qui correspondent exactement aux valeurs fournies."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ne"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "N'est pas égal à"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : tous les types de colonnes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contient"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : texte, texte enrichi et sélection multiple. Lorsqu'utilisé avec des colonnes à sélection multiple, renvoie les lignes qui contiennent toutes les valeurs fournies. Ce filtre ", (0, _jsxRuntime.jsx)("u", {
              children: "prend en compte la casse"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inférieur à"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : nombre, date et date et heure."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inférieur ou égal à"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : nombre, date et date et heure."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Supérieur à"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : nombre, date et date et heure."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Supérieur ou égal à"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : nombre, date et date et heure."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nul"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : tous les types de colonnes sauf booléen. Ce filtre ne nécessite pas de valeur (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__is_null="
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Non nul"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : tous les types de colonnes sauf booléen. Ce filtre ne nécessite pas de valeur (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__not_null="
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comme"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : texte et texte enrichi."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Différent de"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : texte et texte enrichi."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icontains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contient"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : texte et texte enrichi. Ce filtre ", (0, _jsxRuntime.jsx)("u", {
              children: "ne prend pas en compte la casse"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startswith"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Commence par"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : texte et texte enrichi."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dans"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : nombre, sélection et sélection multiple. Renvoie les lignes où la colonne inclut au moins une des options transmises. S'il n'y a pas d'autre ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sort"
            }), " dans le paramètre de requête, les résultats sont triés dans l'ordre dans lequel les valeurs sont spécifiées dans l'opérateur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Trier les lignes renvoyées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la récupération des données HubDB, vous pouvez appliquer le tri comme paramètre de demande pour déterminer l'ordre des données renvoyées. Pour trier les données, ajoutez un paramètre de demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort"
      }), " et indiquez le nom de la colonne :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=columnName"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par défaut, les données seront renvoyées dans l'ordre naturel de la colonne spécifiée. Vous pouvez inverser le tri en ajoutant un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), " à un nom de colonne :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=-columnName"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez inclure ce paramètre plusieurs fois pour trier plusieurs colonnes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En plus du tri par colonne, trois fonctions peuvent être utilisées :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "geo_distance(location_column_name, latitude, longitude) :"
          }), " prend le nom d'une colonne d'emplacement et des coordonnées, renvoie les lignes classées en fonction de la distance entre les valeurs de la colonne d'emplacement indiquée et les coordonnées fournies."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "longueur(column_name) :"
          }), " prend le nom d'une colonne, renvoie les lignes classées selon la longueur de la valeur de la colonne (calculée sous forme de chaîne)"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "aléatoire() :"
          }), " renvoie les lignes dans un ordre aléatoire."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ces fonctions prennent également en charge le classement inverse. Par exemple, le tri suivant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), " trie d'abord les éléments les plus éloignés :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort=-geo_distance(location_column,42.37,-71.07)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurer les tableaux HubDB pour les pages dynamiques"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Grâce au CMS de HubSpot, vous pouvez utiliser un tableau HubDB comme source de données pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "générer des pages dynamiques"
      }), ". Par exemple, vous pouvez créer un tableau qui contient une ligne pour chaque membre de votre équipe de direction, avec des colonnes contenant les informations que vous souhaitez afficher sur une page. Une fois que vous avez sélectionné ce tableau comme source de données dynamique pour une page, cette page génère une page de listing qui affiche toutes les lignes sous forme d'éléments récapitulatifs, ainsi que des pages distinctes pour chaque ligne, similaire à une page de listing de blog et à des pages d'articles de blog."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour permettre la sélection d'un tableau comme source de données dans l'éditeur de contenu, vous devez définir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "useForPage"
      }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Vous pouvez éventuellement inclure ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamicMetaTags"
      }), " pour spécifier les colonnes à utiliser pour les métadonnées de chaque page."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " lors de la spécification de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamicMetaTags"
        }), ", vous devrez vous assurer que la page utilise des balises HubL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), " au lieu de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ". Découvrez-en davantage dans le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb#metadata",
          children: "guide des pages dynamiques"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, le code ci-dessous permet de créer un tableau qui peut être utilisé pour les pages dynamiques et spécifie les trois colonnes à utiliser pour les métadonnées de page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to create table\n{\n  \"name\": \"dynamic_page_table\",\n  \"label\": \"Dynamic page table\",\n  \"useForPages\": true,\n  \"columns\": [\n    {\n      \"name\": \"meta_description\",\n      \"label\": \"Meta description\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"featured_image\",\n      \"label\": \"Featured image\",\n      \"archived\": false,\n      \"type\": \"IMAGE\"\n    },\n    {\n      \"name\": \"canonical_url\",\n      \"label\": \"Canonical URL\",\n      \"archived\": false,\n      \"type\": \"URL\"\n    }\n  ],\n  \"dynamicMetaTags\": {\n    \"DESCRIPTION\": 1,\n    \"FEATURED_IMAGE_URL\": 2,\n    \"LINK_REL_CANONICAL_URL\": 3\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " pour permettre au tableau d'être utilisé comme source de données pour les pages dynamiques."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamicMetaTags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : spécifie les colonnes par ID à utiliser pour les métadonnées sur chaque page dynamique. Peut contenir :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DESCRIPTION"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FEATURED_IMAGE_URL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINK_REL_CANONICAL_URL"
                })
              })]
            }), "Pour tous les champs de métadonnées non spécifiés, les pages hériteront des valeurs respectives de leur page parent."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DESCRIPTION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID numérique de la colonne à utiliser pour la méta-description de chaque page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FEATURED_IMAGE_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID numérique de la colonne à utiliser pour l'URL de l'image en vignette de chaque page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINK_REL_CANONICAL_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID numérique de la colonne à utiliser pour l'URL canonique de chaque page."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modifications dans la v3"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les tableaux doivent avoir à la fois ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ". Ce nom ne peut pas être modifié une fois le tableau créé. Les noms peuvent uniquement contenir des minuscules, des chiffres et des tirets du bas et ne peuvent pas commencer par un chiffre. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " doivent les deux être uniques dans le compte."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["L'API prend en charge à la fois les tableaux ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " dans les chemins URL."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les points de terminaison des lignes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " renvoient la colonne ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " au lieu de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), ". De plus, les points de terminaison de lignes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PUT"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " nécessitent une colonne ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " plutôt que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les points de terminaison de mise à jour des lignes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " acceptent désormais les mises à jour plus rares, ce qui signifie que vous pouvez spécifier uniquement les valeurs de colonne que vous devez mettre à jour (alors que vous deviez spécifier toutes les valeurs de colonne dans les versions précédentes). Lorsque vous mettez à jour une colonne avec une liste de valeurs telle que la sélection multiple, vous devez spécifier la liste de toutes les valeurs. Pour supprimer la valeur d'une colonne, vous devez spécifier la colonne avec la valeur comme ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " dans la demande."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les points de terminaison ont été remplacés par ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "update"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "delete"
        }), " une cellule de ligne au profit des points de terminaison ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " de mise à jour des lignes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le point de terminaison d'import prend désormais en charge un champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "idSourceColumn"
        }), " facultatif avec les champs existants dans les options au format JSON. Vous pouvez utiliser ce champ pour spécifier la colonne du fichier CSV qui contient les ID de ligne. Pour importer de nouvelles lignes avec les nouvelles valeurs pour les lignes existantes, vous pouvez simplement spécifier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), " comme ID de ligne pour les nouvelles lignes et ID de lignes valides pour les colonnes existantes. Pour plus de détails, consultez la section Import ci-dessous. Vous pouvez également utiliser des noms de colonnes ou des identifiants dans le champ cible des mappages de colonnes dans les options au format JSON."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cloner le point de terminaison nécessite un nouveau nom et un nouveau libellé."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}