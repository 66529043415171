"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044570;
const slug = exports.slug = 'guides/cms/storage/file-manager';
const title = exports.title = 'Datei-Manager';
const name = exports.name = 'EMEA DACH (de) File Manager';
const metaDescription = exports.metaDescription = 'Bilder mithilfe des Datei-Managers von HubSpot im Content Delivery Network von CMS Hub hosten und Content-Autoren das einfache Hinzufügen von Dateien zu Inhalten ermöglichen. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "verwendung-des-datei-managers",
  "label": "Verwendung des Datei-Managers",
  "parent": null
}, {
  "depth": 0,
  "id": "hochladen-von-dateien-in-den-datei-manager",
  "label": "Hochladen von Dateien in den Datei-Manager",
  "parent": null
}, {
  "depth": 0,
  "id": "verwendung-von-datei-manager-dateien",
  "label": "Verwendung von Datei-Manager-Dateien",
  "parent": null
}, {
  "depth": 0,
  "id": "optimierungen",
  "label": "Optimierungen",
  "parent": null
}, {
  "depth": 0,
  "id": "bereitstellen-von-html--und-js-dateien-vom-datei-manager",
  "label": "Bereitstellen von HTML- und JS-Dateien vom Datei-Manager",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Datei-Manager"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zusätzlich zum Entwickler-Dateisystem können Dateien auch mit HubSpots Datei-Manager gespeichert und bereitgestellt werden. Dateien im Datei-Manager werden über das globale Content Delivery Network (CDN) von HubSpot bereitgestellt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Standardmäßig sind alle in den Datei-Manager hochgeladenen Dateien öffentlich zugänglich und können in Suchmaschinen indexiert werden. Nach dem Hochladen Ihrer Dateien können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/files/organize-edit-and-delete-files#edit-your-file-s-visibility-settings",
        children: "die Sichtbarkeitseinstellungen Ihrer Datei verwalten"
      }), ", um zu verhindern, dass Dateien indexiert werden oder darauf zugegriffen wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Den Datei-Manager finden Sie unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/files/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Marketing“ > „Dateien und Vorlagen“ > „Dateien“"
        })
      }), " im oberen Navigationsmenü."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/image-png-May-24-2023-05-49-31-9738-AM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwendung des Datei-Managers"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Allgemeinen sollte der Datei-Manager für Dateien eingesetzt werden, die für die Verwendung in Dateiauswahlen in ganz HubSpot vorgesehen sind. Zum Beispiel bei der Auswahl eines Bildes in einem Bild- oder Rich-Text-Modul."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vor dem Hochladen von Dateien für die Verwendung mit dem Datei-Manager:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Es gelten bestimmte ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/files/supported-file-types#files-tool",
          children: "Beschränkungen für Dateigröße und -typ"
        }), ". Erfahren Sie mehr darüber, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/files/upload-files-to-use-in-your-hubspot-content#before-you-get-started",
          children: "was Sie vor dem Hochladen von Dateien in den Datei-Manager beachten sollten"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dateien, die in den Datei-Manager hochgeladen wurden, können nicht in der HubSpot-App bearbeitet werden, außer bei der Bearbeitung kleinerer Bilddateien."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie beabsichtigen, textbasierte Dateien zu bearbeiten, müssen diese im Design-Manager gespeichert werden."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In den Datei-Manager hochgeladene textbasierte Dateien werden nicht verkleinert oder in irgendeiner Weise verändert. Um von HubSpots ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#javascript-minification",
          children: "JavaScript-Minifizierung"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "CSS-Minifizierung und -Kombination"
        }), " zu profitieren, speichern Sie diese Dateien im Design-Manager."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Hochladen von Dateien in den Datei-Manager"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dateien können über die folgenden Optionen in den Datei-Manager hochgeladen werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie Dateien direkt in HubSpot hochladen möchten, erfahren Sie in diesem Artikel, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/files/upload-files-to-use-in-your-hubspot-content",
          children: "Dateien in den Datei-Manager hochladen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um Dateien über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS-CLI hochzuladen"
        }), ", verwenden Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload filemanager"
        }), "-Befehl."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie Dateien mithilfe einer API hochladen möchten, erfahren Sie in diesem Dokument mehr über ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/api/files/v3#post-%2Ffiles%2Fv3%2Ffiles",
          children: ["HubSpots API zum ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Hochladen einer neuen Datei"
          })]
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie Ihre Dateien in den Datei-Manager hochgeladen haben, erfahren Sie hier, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/files/organize-edit-and-delete-files",
        children: "Ihre Dateien und Dateidetails organisieren und verwalten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwendung von Datei-Manager-Dateien"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Auf in den Datei-Manager hochgeladene Dateien kann über die folgenden Optionen zugegriffen werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In den Datei-Manager hochgeladene Dateien sind in jeder Dateiauswahl überall in HubSpot und im gesamten CMS von HubSpot zugänglich, z. B. in Rich-Text- oder Bildmodulen auf Seiten."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In den Datei-Manager hochgeladene Dateien sind über einen direkten Download-Link aufrufbar. Erfahren Sie, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/files/provide-a-direct-download-link-to-a-file-hosted-on-the-files-tool",
          children: "den Link zum direkten Herunterladen einer Datei abrufen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Optimierungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Datei-Manager-Dateien werden automatisch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#browser-and-server-caching",
        children: "zwischengespeichert"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#image-compression-optimization-and-automatic-image-resizing",
        children: "komprimiert und in der Größe angepasst, damit sie effizient bereitgestellt werden"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#domain-rewriting",
        children: "zur Reduzierung von ursprungsübergreifenden Anfragen über alle von Ihnen gehosteten Domains hinweg aufrufbar sind"
      }), ". Erfahren Sie mehr über die Bereiche ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "CDN, Sicherheit und Performance"
      }), " bei CMS Hub."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bereitstellen von HTML- und JS-Dateien vom Datei-Manager"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HTML- und JavaScript-Dateien, die in den Datei-Manager hochgeladen und mithilfe einer Standard-HubSpot-Domain (d. h. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "f.hubspotusercontentXX.net"
      }), ") bereitgestellt wurden, verwenden Content-Type: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "text/plain"
      }), ". Dies bedeutet, dass Webbrowser den Code nicht rendern und auswerten können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn ein Benutzer direkt zur einer HTML-Datei geht, wird der HTML-Code selbst dem Benutzer angezeigt. Um dies zu vermeiden, müssen Sie diese Dateien über eine Ihrer verknüpften Domains anstelle einer HubSpot-Standard-Domain bereitstellen."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}