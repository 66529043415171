"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890039;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/overview';
const title = exports.title = 'Visión general de las funciones sin servidor';
const name = exports.name = 'LATAM (ES) Serverless Overview';
const metaDescription = exports.metaDescription = 'Las funciones sin servidor están escritas en JavaScript y utilizan el tiempo de ejecución de NodeJS. Utilízalas para agregar nuevas y avanzadas capacidades a los sitios web de CMS de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "ejemplos",
  "label": "Ejemplos",
  "parent": null
}, {
  "depth": 0,
  "id": "l%C3%ADmites",
  "label": "Límites",
  "parent": null
}, {
  "depth": 1,
  "id": "l%C3%ADmites-de-ejecuci%C3%B3n",
  "label": "Límites de ejecución",
  "parent": "l%C3%ADmites"
}, {
  "depth": 1,
  "id": "dividir-dependencias",
  "label": "Dividir dependencias",
  "parent": "l%C3%ADmites"
}, {
  "depth": 0,
  "id": "acceso-a-las-funciones-sin-servidor",
  "label": "Acceso a las funciones sin servidor",
  "parent": null
}, {
  "depth": 1,
  "id": "funciones-sin-servidor",
  "label": "Funciones sin servidor",
  "parent": "acceso-a-las-funciones-sin-servidor"
}, {
  "depth": 1,
  "id": "serverless.json",
  "label": "Serverless.json",
  "parent": "acceso-a-las-funciones-sin-servidor"
}, {
  "depth": 1,
  "id": "function.js",
  "label": "Function.js",
  "parent": "acceso-a-las-funciones-sin-servidor"
}, {
  "depth": 0,
  "id": "secretos",
  "label": "Secretos",
  "parent": null
}, {
  "depth": 0,
  "id": "visualizaci%C3%B3n-de-los-registros-de-la-funci%C3%B3n-sin-servidor",
  "label": "Visualización de los registros de la función sin servidor",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Funciones sin servidor"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si estás creando una función sin servidor como parte de un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "proyecto de desarrollador"
        }), ", visita la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "documentación de la función sin servidor del proyecto de desarrollador"
        }), ". La siguiente documentación es para crear funciones sin servidor fuera de la plataforma del proyecto del desarrollador."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las funciones sin servidor ofrecen una manera de escribir código del lado del servidor que se relaciona con HubSpot y servicios de terceros a través de las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "API"
      }), ". Las API que requieren autentificación no son seguras para el front-end de un sitio web, ya que tus credenciales quedarían expuestas. Las funciones sin servidor pueden actuar como intermediarias, permitiéndote guardar las credenciales en secreto."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con las funciones sin servidor, no necesitaras crear y gestionar nuevos servidores. Las funciones sin servidor requieren menos gastos y son más fáciles de escalar a medida que un negocio crece."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes experimentar con las funciones sin servidor utilizando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "cuenta de entorno de pruebas para desarrolladores CMS"
      }), ". Para crear tu primera función sin servidor, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
        children: "guía de primeros pasos a las funciones sin servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ejemplos"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "La lista de cosas para las que puedes utilizar las funciones sin servidor de HubSpot depende de tu imaginación. Puedes utilizarlas para:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Recolectar datos y almacenarlos en HubDB o en el CRM de HubSpot"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Calculadoras de datos complejos"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Visualización dinámica de datos de otros sistemas"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/cms/overview",
                children: "Sistemas de registro de eventos"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Envíos de formularios que envían datos a otros sistemas"
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172853952400"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando el ejemplo del sistema de registro de eventos, podrías utilizar funciones sin servidor para manejar el registro y actualizar cuántos espacios hay para un evento. El flujo funcionaría como sigue:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El visitante del sitio web navega a la página de registro de tu evento, mostrando que hay espacio para que asistan 15 personas más. El visitante rellena un formulario personalizado para inscribirse en el evento y lo envía."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ese envío lo hemos configurado para que envíe una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yourwebsite.com/_hcms/api/event/participants"
        }), ". ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "event/participants"
        }), " es tu función sin servidor."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tu función sin servidor recibe los datos enviados por el usuario y realiza algunas acciones antes de devolver una respuesta al navegador:"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Envía los datos del campo del formulario a la API del formulario de envío de HubSpot para agregar esta información de envío del formulario al CRM de HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utiliza la api de HubDB, para restar 1 al recuento de participantes de este evento que se almacena en HubDB."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Envía una respuesta de vuelta al navegador web."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Javascript en la página recibe la respuesta de la función sin servidor y muestra un mensaje de confirmación al usuario final, y ajusta el recuento de cuántos espacios quedan para los participantes."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las funciones sin servidor de HubSpot están escritas en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
        children: "JavaScript"
      }), " y utilizan el tiempo de ejecución ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/about/",
        children: "NodeJS"
      }), ". Las funciones sin servidor de HubSpot están previstas para ser utilizadas para agregar funcionalidad a tu sitio de HubSpot, como el apoyo a los envíos de formularios avanzados y la extracción de datos de otras API. No está previsto como una plataforma informática genérica en la que puedas ejecutar un código no relacionado con HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las funciones sin servidor están previstas para ser rápidas y tener un enfoque limitado. Esa velocidad les permite ser compañeros perfectos del front-end de los sitios web y las aplicaciones, permitiendo una llamada y una respuesta rápidas. Para mantener el rendimiento, las funciones sin servidor de HubSpot se limitan a:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "50 secretos por cuenta."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128 MB de memoria."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "no más de 100 puntos de terminación por cuenta de HubSpot."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["el contentType ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), " cuando se llama a una función."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6MB por carga útil de invocación, que puede encontrarse al intentar cargar un archivo con una función sin servidor, por ejemplo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "4KB para la cantidad de datos que se pueden registrar. Al alcanzar este límite, se recomienda registrar después de acciones individuales, en lugar de la salida final."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Límites de ejecución"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cada función tiene un tiempo máximo de ejecución de 10 segundos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cada cuenta está limitada a un total de 600 segundos de ejecución por minuto."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esto significa que cualquiera de estos escenarios puede ocurrir en un minuto:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hasta 60 ejecuciones de funciones que tardan 10 segundos cada una en completarse."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hasta 6.000 ejecuciones de funciones que tardan 100 milisegundos en completarse."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las funciones que superen esos límites arrojarán un error. El recuento de ejecuciones y los límites de tiempo devolverán una respuesta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), ". El tiempo de ejecución de cada función se incluye en los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#viewing-serverless-function-logs",
        children: "registros de funciones sin servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Dividir dependencias"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las funciones sin servidor no admiten la división de JavaScript entre varios archivos cuando se implementan. En lugar de ello, tu función sin servidor debe incluir un archivo JavaScript para ejecutar la función. Si estás construyendo una función sin servidor con varios archivos JavaScript, deberías copiar el código compartido en el único archivo JavaScript o usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), " para agrupar tu código. Más información sobre cómo usar webpack como solución en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/Import-packages-in-serverless-functions/m-p/346620",
        children: "Comunidad de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Acceso a las funciones sin servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En HubSpot, las funciones sin servidor se almacenan en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/key-concepts#developer-file-system",
        children: "sistema de archivos del desarrollador"
      }), ", visible en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cos-general/a-quick-tour-of-the-design-manager",
        children: "administrador de diseño"
      }), ". Puedes acceder y editar tus funciones sin servidor localmente a través de la CLI."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para incluir funciones sin servidor en un proyecto de desarrollador, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "documentación de bloques de creación de JavaScript"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Funciones sin servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Las funciones sin servidor de HubSpot se encuentran dentro de una carpeta de funciones. Esta carpeta puede tener cualquier nombre, pero debe contener el sufijo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".functions"
            }), ". Los archivos almacenados en esta carpeta no son de acceso público."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["En la carpeta de funciones, incluye tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), " junto con el archivo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#function-js",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: ".js"
              })
            }), " que contiene tus funciones. Podrías considerar agregar un archivo de descuento ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#readme-md",
              children: "README"
            }), " para comunicar para qué son las funciones, cómo funcionan y si tiene un proceso de compilación para crearlas."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-03%20at%208.24.31%20AM.png",
            alt: "Carpeta .functions sin servidor"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para evitar ediciones accidentales desde el administrador de diseño, puedes bloquear tu carpeta. Para bloquear una carpeta, navega hasta el administrador de diseños, haz clic con el botón derecho en la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "carpeta"
        }), " y selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bloquear carpeta"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Serverless.json"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " las funciones sin servidor incluidas en los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "proyectos de desarrolladores"
        }), " se han actualizado a partir de la versión ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2023.2"
        }), " de la plataforma, incluido un nuevo esquema ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), ". Obtén más información sobre el control de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/platform-versioning",
          children: "versiones de la plataforma del proyecto"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/features/serverless-functions/reference#serverless-json",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        })
      }), " es el archivo de configuración de la función sin servidor que especifica el entorno de tiempo de ejecución y cualquier ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "variable de entorno"
      }), " que planees utilizar en tus funciones."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este archivo también maneja el enrutamiento de tus puntos de terminación. Especifica las rutas de los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/features/serverless-functions/reference#endpoints",
        children: "puntos de terminación"
      }), " que deseas mapear en tu archivo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#function-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "function.js"
        })
      }), ". Para ver un ejemplo de cómo debe ser tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), ", consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/features/serverless-functions/reference#serverless-json",
        children: "guía de referencia de las funciones sin servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Function.js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tu función real sin servidor puede tener cualquier nombre siempre que sea un archivo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/features/serverless-functions/reference#function-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".js"
        })
      }), ". Para que tu función sin servidor funcione, debe estar mapeada a un punto de terminación", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/features/serverless-functions/reference#serverless-json",
        children: ["definido en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        })]
      }), ". Para la resolución de problemas, se recomienda nombrar el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".js"
      }), " de forma similar al nombre de su punto de terminación en su archivo de configuración ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secretos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al autenticar una llamada realizada por una función sin servidor, debes usar secretos para almacenar claves de API, tokens de acceso a aplicaciones privadas y otra información de autenticación por seguridad. Esto permitirá la autenticación sin exponer tu clave o token de acceso."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear y administrar secretos, puedes usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cli#serverless-function-commands",
        children: "comandos de la CLI de HubSpot"
      }), ", tales como:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add"
        }), " para crear un nuevo secreto."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets list"
        }), " para ver tus secretos disponibles actualmente por nombre."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets update"
        }), " para actualizar un secreto existente."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez agregados a través de la CLI, pueden ponerse a disposición de las funciones mediante la inclusión de una matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secretos"
      }), " que contenga el nombre del secreto. Esto le permite almacenar tu código de función en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "control de versiones"
      }), " y usar secretos sin exponerlos. Sin embargo, ", (0, _jsxRuntime.jsx)("u", {
        children: "nunca debes"
      }), " devolver el valor de tu secreto a través del registro de la consola o como respuesta, ya que esto expondrá el secreto en los registros o en las páginas del front-end que llaman a tu función sin servidor."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debido al almacenamiento en caché, puede tardar aproximadamente un minuto en ver los valores secretos actualizados. Si acabas de actualizar un secreto pero todavía estás viendo el valor anterior, vuelve a comprobarlo después de aproximadamente un minuto."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visualización de los registros de la función sin servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ayudar a la solución de problemas de tus funciones sin servidor, la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), " tiene un comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#logs",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        })
      }), " que le da la capacidad de ver los registros de tu función. Además de las respuestas individuales de la invocación de la función, la hora de ejecución y el tiempo de ejecución, cualquier declaración ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), " también aparecerá en los registros de la función. No se deben ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "registrar en la consola"
      }), " los secretos como las claves de la API."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}