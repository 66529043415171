'use es6';

import { get } from '../../common/objutils';
export function capitalize(word) {
  return word[0].toUpperCase() + word.slice(1);
}
export function patchSdkPath(path) {
  if (get(path, '[0]') === 'oauth') {
    return ['o_auth'];
  }
  return path;
}