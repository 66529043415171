"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601074021;
const slug = exports.slug = 'getting-started/overview';
const title = exports.title = 'Premiers pas : présentation';
const name = exports.name = 'Premiers pas : présentation (documentation pour les développeurs 2024)';
const metaDescription = exports.metaDescription = 'Découvrez comment faire vos premiers pas de développement avec HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "les-avantages-d-une-plateforme-connect%C3%A9e",
  "label": "Les avantages d'une plateforme connectée",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-installer-des-outils-pour-vous-aider-dans-la-conception",
  "label": "1. Installer des outils pour vous aider dans la conception",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-choisir-ce-qu-il-faut-concevoir",
  "label": "2. Choisir ce qu'il faut concevoir",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-rejoignez-vos-pairs",
  "label": "3. Rejoignez vos pairs",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-%C3%A9tendez-vos-connaissances-%C3%A0-votre-fa%C3%A7on",
  "label": "4. Étendez vos connaissances à votre façon",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Premiers pas : présentation"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Que vous soyez un nouveau développeur HubSpot ou que vous cherchiez à étendre vos compétences, cette section vous guidera tout au long du processus de mise en route et de fonctionnement. Vous découvrirez les différentes options de développement disponibles ainsi que les outils et les comptes spécifiques dont vous aurez besoin pour atteindre vos objectifs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Les avantages d'une plateforme connectée"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tout d'abord, il convient de se pencher sur ce qui fait de HubSpot une plateforme puissante pour les développeurs."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le fondement de chaque compte HubSpot est la plateforme CRM ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm"
      }), " (gestion de la relation client), une base de données de relations et de processus commerciaux. HubSpot propose aux développeurs et partenaires externes différents moyens de travailler avec le système CRM afin de créer une valeur ajoutée pour les utilisateurs finaux de HubSpot. Celui-ci offre notamment les possibilités suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Créer des intégrations et/ou des extensions avec les API de HubSpot pour synchroniser les données entre HubSpot et des plateformes externes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Concevoir des sites web personnalisés en utilisant le CMS (système de gestion de contenu) de HubSpot. En plus d'une suite complète d'outils de gestion de contenu, le CMS s'intègre totalement au CRM. Cela facilite la gestion du lancement des campagnes, de l'acquisition de leads et de la communication continue avec les clients."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Personnaliser le CRM avec des extensions d'interface utilisateur. Les développeurs peuvent utiliser React pour créer des cartes personnalisées flexibles qui s'intègrent parfaitement à HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans la section suivante, vous aurez un aperçu des outils disponibles pour les développeurs HubSpot. Ensuite, vous choisirez une option pour poursuivre votre parcours de développeur HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Installer des outils pour vous aider dans la conception"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe une large gamme d'outils disponibles pour les développeurs HubSpot. La combinaison d'outils dont vous aurez besoin dépendra de vos objectifs spécifiques."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ILC HubSpot :"
        }), " l'installation de l'ILC HubSpot pour le développement local est un excellent point de départ pour de nombreuses tâches de développement. L'ILC est utilisée lors de la création d'extensions d'interface utilisateur et de ressources CMS. Elle peut être utilisée pour créer et gérer des secrets, des tables HubDB et des données d'objets personnalisés à partir de votre compte. Vous devez avoir installé ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: (0, _jsxRuntime.jsx)("u", {
            children: "Node.js"
          })
        }), " (la version 18 ou une version ultérieure est requise. La version LTS est recommandée). Pour installer l'ILC de manière globale, exécutez la commande suivante :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Visual Studio Code :"
          }), " l'installation de ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://code.visualstudio.com/",
            children: "Visual Studio Code"
          }), " vous permet de bénéficier de l'extension ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
            children: "HubSpot Visual Studio Code"
          }), ", qui vous permet de centraliser votre workflow de développement."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Bibliothèques clients"
          }), " : HubSpot fournit des bibliothèques clients pour ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-nodejs",
            children: "Node"
          }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-php",
            children: "PHP"
          }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-ruby",
            children: "Ruby"
          }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-python",
            children: "Python"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Postman :"
          }), " utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/",
            children: "Postman"
          }), " pour accéder aux ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/hubspot",
            children: "collections Postman de HubSpot"
          }), ". Dans la documentation sur les API de HubSpot, vous trouverez des boutons permettant d'exécuter des appels à l'aide de la collection."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/run-in-postman-current-docs-site.png",
            alt: "run-in-postman-current-docs-site"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/tools-to-help-you-build",
        children: "les outils de développement de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Choisir ce qu'il faut concevoir"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il n'y a pas de solution unique pour développer avec HubSpot. Il s'agit d'une plateforme flexible qui peut répondre à un large éventail de besoins."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'une des quatre grandes catégories énumérées ci-dessous devrait couvrir la plupart de ces besoins. Vous pouvez choisir celle qui vous convient le mieux pour une brève présentation, accompagnée de quelques actions immédiates pour vous aider à démarrer. Vous pouvez également découvrir les meilleurs outils pour ce travail et savoir si un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "type spécifique de compte HubSpot"
      }), " est nécessaire."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#use-apis-and-build-integrations",
          children: "Utiliser des API et créer des intégrations"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-for-the-app-marketplace",
          children: "Créer des applications pour le marketplace"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#customize-the-hubspot-ui",
          children: "Personnaliser l'interface utilisateur HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-custom-websites",
          children: "Créer des sites web personnalisés"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Rejoignez vos pairs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Entrez en contact avec vos pairs qui créent déjà sur HubSpot. Posez des questions, partagez vos connaissances, créez une communauté et tissez des liens."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "Slack pour les développeurs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
          children: "Forums des développeurs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/hubspotdev",
          children: "GitHub pour les développeurs"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Étendez vos connaissances à votre façon"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous êtes opérationnel, de nombreuses ressources sont disponibles pour vous aider à approfondir vos connaissances. Vous pouvez accéder à des guides approfondis, à des documents de référence détaillés ou aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses",
        children: "cours de HubSpot Academy"
      }), ", selon votre méthode d'apprentissage préférée."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}