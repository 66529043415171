"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 128972568640;
const slug = exports.slug = 'guides/cms/marketplace/template-policies';
const title = exports.title = 'HubSpot Template Marketplace policies';
const name = exports.name = 'Template Marketplace | Template Marketplace Policies';
const metaDescription = exports.metaDescription = 'Learn more about the HubSpot Template Marketplace Marketplace Policies.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "compliance-with-template-marketplace-policies",
  "label": "Compliance with Template Marketplace Policies",
  "parent": null
}, {
  "depth": 0,
  "id": "template-marketplace-compliance",
  "label": "Template Marketplace compliance",
  "parent": null
}, {
  "depth": 1,
  "id": "acceptable-template-marketplace-submissions",
  "label": "Acceptable Template Marketplace submissions",
  "parent": "template-marketplace-compliance"
}, {
  "depth": 2,
  "id": "free-template-limits",
  "label": "Free template limits",
  "parent": "template-marketplace-compliance"
}, {
  "depth": 1,
  "id": "marketplace-provider-information",
  "label": "Marketplace provider information",
  "parent": "template-marketplace-compliance"
}, {
  "depth": 1,
  "id": "template-ownership",
  "label": "Template ownership",
  "parent": "template-marketplace-compliance"
}, {
  "depth": 1,
  "id": "template-variants-as-separate-submissions-are-not-permitted",
  "label": "Template variants as separate submissions are not permitted",
  "parent": "template-marketplace-compliance"
}, {
  "depth": 0,
  "id": "design-and-code-requirements",
  "label": "Design and code requirements",
  "parent": null
}, {
  "depth": 1,
  "id": "mobile%2Fdesktop-resolution-and-browsers",
  "label": "Mobile/desktop resolution and browsers",
  "parent": "design-and-code-requirements"
}, {
  "depth": 1,
  "id": "design-aesthetics",
  "label": "Design aesthetics",
  "parent": "design-and-code-requirements"
}, {
  "depth": 1,
  "id": "stylesheets-and-scripts",
  "label": "Stylesheets and scripts",
  "parent": "design-and-code-requirements"
}, {
  "depth": 1,
  "id": "classes",
  "label": "Classes",
  "parent": "design-and-code-requirements"
}, {
  "depth": 2,
  "id": "id-and-class-naming-conventions",
  "label": "ID and class naming conventions",
  "parent": "design-and-code-requirements"
}, {
  "depth": 2,
  "id": "default-classes",
  "label": "Default classes",
  "parent": "design-and-code-requirements"
}, {
  "depth": 2,
  "id": "adding-classes-inside-the-rich-text-editor-source-code",
  "label": "Adding classes inside the rich text editor source code",
  "parent": "design-and-code-requirements"
}, {
  "depth": 1,
  "id": "redundant-and-unnecessary-code",
  "label": "Redundant and unnecessary code",
  "parent": "design-and-code-requirements"
}, {
  "depth": 1,
  "id": "templates-must-load-over-https",
  "label": "Templates must load over HTTPS",
  "parent": "design-and-code-requirements"
}, {
  "depth": 1,
  "id": "third-party-files",
  "label": "Third-party files",
  "parent": "design-and-code-requirements"
}, {
  "depth": 1,
  "id": "template-errors",
  "label": "Template errors",
  "parent": "design-and-code-requirements"
}, {
  "depth": 0,
  "id": "module-compatibility-with-themes",
  "label": "Module compatibility with themes",
  "parent": null
}, {
  "depth": 0,
  "id": "marketplace-listing-approval-review-process",
  "label": "Marketplace listing approval review process",
  "parent": null
}, {
  "depth": 0,
  "id": "disclaimers",
  "label": "Disclaimers",
  "parent": null
}, {
  "depth": 1,
  "id": "the-submission-queue",
  "label": "The submission queue",
  "parent": "disclaimers"
}, {
  "depth": 1,
  "id": "support-for-published-templates",
  "label": "Support for published templates",
  "parent": "disclaimers"
}, {
  "depth": 1,
  "id": "self-reviews-for-listed-templates-are-not-allowed",
  "label": "Self-reviews for listed templates are not allowed",
  "parent": "disclaimers"
}, {
  "depth": 1,
  "id": "customer-requests-for-receipt-of-purchase",
  "label": "Customer requests for receipt of purchase",
  "parent": "disclaimers"
}, {
  "depth": 1,
  "id": "template-listing-removal-and-rejection",
  "label": "Template listing removal and rejection",
  "parent": "disclaimers"
}, {
  "depth": 1,
  "id": "template-rejections",
  "label": "Template rejections",
  "parent": "disclaimers"
}, {
  "depth": 1,
  "id": "for-all-participants",
  "label": "For All Participants",
  "parent": "disclaimers"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      h3: "h3",
      h4: "h4",
      strong: "strong",
      pre: "pre",
      code: "code",
      img: "img",
      ol: "ol",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot Template Marketplace policies"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Thank you for your interest in building and listing templates on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "HubSpot Template Marketplace"
      }), ". At HubSpot, we value our developer community and focus on delighting our customers with amazing solutions. We strive to collaborate with our developers to bring new solutions to solve customer needs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, you'll find details about HubSpot's Template Marketplace Policies, which outline requirements and compliance for listing templates in the Template Marketplace."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To learn more, please review additional resources for further details:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/theme-requirements",
          children: "Theme"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/module-requirements",
          children: "module"
        }), " technical requirements"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Theme and module ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/general-requirements",
          children: "listing page requirements"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/providers/template/branding-guidelines",
          children: "Template Branding Guidelines"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/marketplace-tou",
          children: "HubSpot Marketplace Terms of Use"
        }), " for terms and conditions that apply to you when you list your template(s) on the HubSpot Template Marketplace."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Capitalized terms used but not defined in these policies have the meanings set forth in the HubSpot Marketplace Terms of Use. HubSpot uses the terms Service Offering and templates interchangeably."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Compliance with Template Marketplace Policies"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You agree to comply with the terms and conditions of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/marketplace-tou",
        children: "Marketplace Terms of Use"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/developer-terms",
        children: "Developer Terms"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-developer-policy",
        children: "Developer Policy"
      }), ", the Template Marketplace Policies, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "Template Marketplace Guidelines"
      }), ", and the Template Branding Guidelines at all times, which are incorporated herein by reference. The Template Marketplace Policies and Template Marketplace Guidelines may include requirements that you must complete in order to be listed in the Template Marketplace."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you use HubSpot Products or services, you agree to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/terms-of-service",
        children: "HubSpot Customer Terms of Service"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Template Marketplace compliance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Acceptable Template Marketplace submissions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can submit only themes and modules to the Template Marketplace."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Free template limits"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Template providers have the following limits for free templates:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Twenty (20) free themes"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Twenty (20) free standalone modules"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Marketplace provider information"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Marketplace providers must keep their provider information up to date at all times. Provider information must be kept accurate in the Provider Profile and Support Info sections. HubSpot reserves the right to remove or unapprove any template listing where any provider information (including email or website) is inaccurate, missing, or no longer functioning."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Template ownership"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your template must be built or owned by you/your company."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Templates must not use elements from an existing Template Marketplace listing created by another provider. Any template that was purchased or downloaded, or is a cloned version of a template purchased or downloaded from the Template Marketplace will be rejected or removed. Please refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/marketplace-tou",
        children: "Marketplace Terms of Use"
      }), ", specifically those terms addressing the Template Marketplace for more details on copyright infringement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Templates cannot be purchased or downloaded from other marketplaces and submitted to the HubSpot Template Marketplace."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Template variants as separate submissions are not permitted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Examples of ", (0, _jsxRuntime.jsx)("u", {
        children: "unacceptable variations"
      }), " include, but are not limited to:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Color variations:"
        }), " identical templates with different color schemes will not be counted as unique templates. For example, you cannot submit a template with a blue color theme, then submit a red version of the same template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Template layouts:"
        }), " submitting a template such as “Landing page with Right Sidebar” and “Landing Page with Left Sidebar” where the sidebar is simply swapped does not equate to being unique separate instances of a template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Content variation:"
        }), " submitting a template with content that is focused on Education and submitting the same template with content focused on Real Estate does not equate to being a unique separate instance of a template."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Design and code requirements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following requirements must be adhered to when designing templates for the HubSpot Template Marketplace."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mobile/desktop resolution and browsers"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your templates should display properly at common mobile and desktop resolutions/widths. This means templates should display properly when being viewed on both mobile and desktop screens. Your template should not require users to have to scroll off-page (unless this is the intended behavior of the template) or cause unexpected results across different browsers."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It's recommended to test using physical devices and different browsers. You can also use third-party services, such as:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.browserstack.com/",
          children: "BrowserStack"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.responsinator.com/",
          children: "Responsinator"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://saucelabs.com/resources/blog/getting-started-with-manual-live-testing",
          children: "Sauce Labs"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All templates must be supported by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account-security/browsers-supported-by-hubspot",
        children: "HubSpot’s supported browsers"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Design aesthetics"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Designs must display high aesthetic quality and be visually appealing. Below are some examples of poor design aesthetics that would invalidate a submission to the HubSpot Template Marketplace:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Designs are too similar to existing items:"
        }), " your design closely resembles an existing listing and could cause confusion in differentiating them."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Inconsistent spacing, padding, margin, or line-heights on elements:"
        }), " your design has inconsistent spacing among the elements causing users to be unable to visibly discern between sections or groups of texts."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Use of inappropriate, watermarked, pixelated, or unlicensed imagery:"
        }), " your design must use imagery that is appropriate. Images that are found through a search engine “image” search are not licensed for public use. If you are looking for free images, we recommend reading ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/free-stock-photo-websites",
          children: "HubSpot’s blog post about free image sites"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Use of colors that are not complementary:"
        }), " your design should contain a color scheme that is aesthetically pleasing. When choosing color schemes, we strongly encourage you to think of accessibility standards in order to create an inclusive design."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Misaligned or inconsistently-placed elements:"
        }), " your design should have a logical visual flow and not cause visual clutter. An example of this would be having floating text boxes unintentionally overlapping in areas where text wouldn't be expected to be placed."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Stylesheets and scripts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When including stylesheets or JavaScript files in your themes and modules, it's recommended to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-css",
          children: "require_css function"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js function"
        }), " to include these files."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Load render-blocking JavaScript in the footer to improve performance."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When including style sheets or JavaScript files in a theme's module, it is strongly recommend to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
        children: "link these file dependencies"
      }), " to the module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/module-requirements#module-code-quality",
        children: "module code quality requirements"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Classes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ID and class naming conventions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When applying IDs and classes, you must use appropriate names and follow a consistent naming convention. There are many different naming convention methodologies on the web. Below are a few examples:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["BEM: ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://en.bem.info/methodology/naming-convention/",
          children: "https://en.bem.info/methodology/naming-convention/"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ABEM: ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/abem-useful-adaptation-bem/",
          children: "https://css-tricks.com/abem-useful-adaptation-bem/"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ITCSS: ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/",
          children: "https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.creativebloq.com/web-design/manage-large-css-projects-itcss-101517528",
          children: "https://www.creativebloq.com/web-design/manage-large-css-projects-itcss-101517528"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Default classes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Do not style your content based on default generated classes that are a part of your site’s structural framework. This includes but is not limited to, the following list of classes and IDs that are generated by HubSpot on templates and modules:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "/* ids */\n#hs_cos_wrapper*, #hs_form_target_dnd*\n\n/* classes */\n.heading-container-wrapper, .heading-container, .body-container-wrapper,\n.body-container, .footer-container-wrapper, .footer-container,\n.container-fluid, .row-fluid, .row-fluid-wrapper,\n.row-depth-*, .row-number-*, .span*, .hs-cos-wrapper,  .hs-cos-wrapper-widget,\n.dnd-section, .dnd-column, .dnd-row, .dnd-module, .dnd_area*\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot default generated classes are always subject to change. Instead, use custom classes that are assigned either through the Design Tools in the designated class fields or through local development by adding a custom class to your element tags."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Adding classes inside the rich text editor source code"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Do not include custom classes or IDs inside of content in the rich text editor through the “Source Code” option. Classes and IDs added through this option can easily be overridden (either directly or indirectly) causing perceived issues with your template."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Redundant and unnecessary code"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your template must not include any unnecessary code or items. This includes but is not limited to, items such as:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Unused style sheets or scripts"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Commented out code"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Unused code"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There is a difference between commented out code and comments in code. If you are providing context in your code for clarification purposes, use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#types-of-delimiters",
        children: "comment delimiter"
      }), " as this will let you comment on your code while also not having these comments show in the “View Source” or “Inspect” views of a page. See below for an example."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<p>\n  This is a sample. When viewing source, you should only see the HTML Comment.\n</p>\n<p>HTML Comment Wrapper Start</p>\n<!-- This is an HTML Comment and will show in the View Source / Inspect -->\n<p>HTML Comment Wrapper End</p>\n<hr />\n<p>HubL Delimiter Comment Wrapper Start</p>\n{# This comment is using the HubL Delimiter for comments. These comments appear\nhere but do not render in the front end #}\n<p>HubL Delimiter Comment Wrapper End</p>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Templates must load over HTTPS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If using third-party files, your listing must be loaded over HTTPS to ensure proper security. Likewise, all code must render properly when being viewed over HTTPS. This is to avoid mixed content warnings in the browser console and make sure all content displays properly."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Third-party files"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Third-party files must be loaded on the HubSpot CDN unless they are from a reputable source such as JSDelivr, Google Hosted Libraries, or CDNJS. These files can be added to the stylesheet using the @import feature."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you are including files in a module, you must use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "css_assets"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "js_assets"
        }), " parameters in your meta.json file (Linked Files section in Design Tools). This only works with modules in themes, and will not work for standalone modules."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Template errors"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "All templates must not display any errors in either the Design Tools or the browser console. An example of errors is shown below."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-manager-errors-1.png",
        alt: "design-manager-errors-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module compatibility with themes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alternate_names"
      }), " attribute can be used to achieve module compatibility with themes in the Template Marketplace. It provides the bridge for a module to integrate with as many marketplace themes as possible without much effort from providers and module developers."]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/URRac3MfZuQ",
      maxHeight: "367px",
      maxWidth: "700px",
      allowFullScreen: true
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Theme providers define a new attribute called ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alternate_names"
      }), " which contains standard fields mapped to the module fields. Alternate names are supported for fonts and color fields. Module fields will ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#inherited-fields",
        children: "inherit"
      }), " from the standard color and font fields. This is a new attribute introduced for theme fields. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"label\": \"ButtonColor\",\n  \"name\": \"button_color\",\n  \"type\": \"color\",\n  \"visibility\": {\n    \"hidden_subfields\": {\n      \"opacity\": true\n    }\n  },\n  \"alternate_names\": [\"primary_color\"],\n  \"default\": {\n    \"color\": \"#516747\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the above example, developers get the ability to access the button color in two ways: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.button_color"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.primary_color"
      }), ". This way, providers can update their existing theme to meet template standards."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Modules and themes must adhere to the following requirements to ensure functionality when used across themes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The font and color style fields must follow these standard naming conventions: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "heading_font"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "body_font"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If theme fields do not have ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "heading_font"
        }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "body_font"
        }), " fields, they can use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "alternate_names"
        }), " attribute to map existing fields to standard fields. This way, when an independent module is added to the theme template, it has a similar look and feel of the themed module."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A user can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview#inherited-fields",
          children: "inherit"
        }), " either by defining ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "default_value_path"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "property_value_paths"
        }), ", or both. Review the code snippet below for an example:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_value_path"
            }), ", the accepted value is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "theme.primary_color"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_value_paths"
            }), " you must use trailing individual properties ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".color"
            }), " or .", (0, _jsxRuntime.jsx)(_components.code, {
              children: "opacity"
            }), " based on the property they are mapping."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"id\": \"d506e41f-7206-bb8f-7fa5-d4a7de75c61e\",\n    \"name\": \"color\",\n    \"display_width\": null,\n    \"label\": \"Color\",\n    \"required\": false,\n    \"locked\": false,\n    \"type\": \"color\",\n    \"inherited_value\": {\n      \"default_value_path\": \"theme.primary_color\",\n      \"property_value_paths\": {\n        \"color\": \"theme.primary_color.color\",\n        \"opacity\": \"theme.primary_color.opacity\"\n      }\n    },\n    \"default\": {\n      \"color\": \"#00FF03\",\n      \"opacity\": 100\n    }\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), ", these fields can be referred to with the following dot notation:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ theme.primary_color }} <br>\n{{ theme.primary_color.color }} <br>\n{{ theme.primary_color.css }}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When creating a theme, the same standard naming conventions (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "heading_font"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "body_font"
        }), ") must be used so that the module fields can be mapped with the standard field names. Developers can either create new module fields that meet the standard naming convention, or use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "alternate_names"
        }), " parameter to map existing fields to the standard fields."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The color field with an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "alternate_name"
        }), " can be accessed directly using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.primary_color"
        }), " or indirectly using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.colors.primary"
        }), ". Below is an example:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n \"label\": \"Colors\",\n \"name\": \"colors\",\n \"type\": \"group\",\n \"children\": [\n  {\n   \"label\": \"Primary\",\n   \"name\": \"primary\",\n   \"type\": \"color\",\n   \"visibility\": {\n    \"hidden_subfields\": {\n     \"opacity\": true\n    }\n   },\n \"alternate_names\" : [\"primary_color\"]\n \"default\": {\n  \"color\": \"#516747\"\n  }\n }]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The font field with an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "alternate_name"
        }), " can be accessed directly using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.heading_font"
        }), " or indirectly using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.fonts.primary"
        }), ". Review the snippet below for an example:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n    \"label\": \"Fonts\",\n    \"name\": \"fonts\",\n    \"type\": \"group\",\n    \"children\": [\n      {\n        \"label\": \"Primary\",\n        \"name\": \"primary\",\n        \"type\": \"font\",\n        \"visibility\": {\n          \"hidden_subfields\": {\n            \"bold\": true,\n            \"italic\": true,\n            \"size\": true,\n            \"underline\": true\n          }\n        },\n        \"default\": {\n          \"color\": \"#231f20\",\n          \"fallback\": \"sans-serif\",\n          \"font\": \"Montserrat\",\n          \"font_set\": \"GOOGLE\",\n          \"variant\": \"400\"\n        },\n        \"alternate_names\":[\"heading_font\"]\n      }\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Marketplace listing approval review process"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "All template submissions and resubmissions undergo a review process and all requested modifications or corrections are required. The HubSpot Template Marketplace listing approval review process is as follows:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Your submission will run through a series of automated checks to ensure that general requirements are met. If issues arise during this process, a modal will appear informing you of the issues with your template. You are then required to make the necessary changes and re-submit for approval."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Once your submission passes the automated checks, it will then be added to a queue for the Ecosystem Quality Team to review. They will review the code, functionality, listing content, included assets (such as images, scripts, and more), and additional information that are related to your submission."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If your submission is approved, an email will be sent to either the email address listed in your ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Provider Info"
        }), " section, or associated with the submission, notifying that your listing has been approved and will be listed in the Template Marketplace. If your submission is rejected, you will receive communications from the Ecosystem Quality Team with additional information and next steps for remediation."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "We may require you to make modifications or corrections to your template listing based on the results of the approval review process. Failure to make corrections or modifications may result in your termination or removal from the Template Marketplace."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Templates must be approved within three (3) reviews. Failure to make corrections or modifications may result in your termination or removal from the Template Marketplace per our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/marketplace-tou",
        children: "Marketplace Terms of Use"
      }), ", denial of the template from being listed in the future, or suspension from the Template Marketplace."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Disclaimers"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "The submission queue"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Our Ecosystem Quality Team works from a queue of submissions based on the order in which they were received. Most submissions are reviewed within one week, but review turnaround times can vary depending on the volume of submissions and complexity of said submissions. Please note that the team is not able to provide information on your template's current position in the queue or the timeframe until the review is completed."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Templates that do not pass the approval review process will be moved to the bottom of the queue when they are resubmitted. Resubmitting your template, either from fixing issues from previous rejections or resubmitting while your template is still pending review, will move your template to the bottom of the queue."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Our team is dedicated to completing reviews as quickly as possible without compromising quality or thoroughness. Following the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "directions and requirements"
      }), " for submitting and listing your templates will help to ensure your template passes review."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Support for published templates"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Template providers are responsible for all code and files that are uploaded to the Template Marketplace. They must support their own work for the duration of the listing in the marketplace if there are any issues with their code or files."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You must offer email support for your template(s) and provide documentation via a publicly accessible URL. Documents like PDFs and Google Docs can be used as supplemental resources, but the publicly accessible URL ", (0, _jsxRuntime.jsx)("u", {
        children: "must"
      }), " be a live webpage. Tickets, phone, live chat, and Facebook Messenger are all optional forms of support."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Self-reviews for listed templates are not allowed"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Template providers and their team (including others at their company who may not have worked on their template) are not allowed to publish reviews of their own listings in the Template Marketplace. Violation of this requirement may result in removal from the Template Marketplace."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Customer requests for receipt of purchase"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If requested, template providers must be able to provide a detailed receipt that includes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Template Listing Name"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Template Listing Description"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Date of Template Purchase"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Price of Template"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "VAT Tax Information (if applicable)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Template Marketplace Provider Company Information"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Template listing removal and rejection"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot reserves the right to remove marketplace listings for reasons including, but not limited to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Bugs and/or defects that are present in the templates."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Broken third party libraries, files, and/or assets in the submission"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Incompatibility with ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/resources/which-browsers-are-supported-by-hubspot",
          children: "HubSpot's supported browsers"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use of copyrighted material such as images, icons, fonts, audio, and video that are not licensed for distribution"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Templates that track users without their informed, explicit opt-in consent"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Templates (including linking to content from the Template Marketplace or from your template demo) that includes:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Promoting or engaging in illegal activities"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Derogatory, disparaging, and/or malicious content"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Dishonest or false claims"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Offensive content about race, religion, age, gender or sexual orientation"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Independent module listings will be removed if the module affects other elements of the page (view Module Requirements ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/module-requirements#5-module-code-quality",
          children: "here"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Template rejections"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot reserves the right to reject templates for reasons not listed above or in the Template Marketplace Guidelines, Marketplace Terms of Use, Template Marketplace Policies, or Platform Policies. In all cases, if your template is rejected, a HubSpot Technical Program manager will provide feedback via email."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "For All Participants"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Additionally, \"inbound\" is a method of attracting, engaging, and delighting people to grow a business that provides value and builds trust. This methodology extends to our Template Providers. As a Provider, your interactions with the various teams that work at HubSpot should mirror this methodology. Unsolicited outreach, whether 1:1 or through \"broadcast\", to HubSpot teams to promote your products and services could result in the removal of your company from the HubSpot Template Marketplace."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}