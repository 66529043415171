"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42376801971;
const slug = exports.slug = 'reference/api/deprecated';
const title = exports.title = 'HubSpot API ｜非推奨のAPI';
const name = exports.name = 'APAC JAPAN (JA) | vNext Docs DP - Deprecated API | 202104';
const metaDescription = exports.metaDescription = '非推奨のHubSpot APIのリスト';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%BB%83%E6%AD%A2%E3%81%95%E3%82%8C%E3%81%9Fapi",
  "label": "廃止されたAPI",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%9D%9E%E6%8E%A8%E5%A5%A8%E3%81%AEapi",
  "label": "非推奨のAPI",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      br: "br",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "廃止されたAPIおよび非推奨のAPI"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下のセクションに記載されているAPIは、今後機能が更新", (0, _jsxRuntime.jsx)("u", {
        children: "されることはなく"
      }), "、場合によっては完全に廃止され連携に使用できなくなります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["最新の更新、互換性が保たれなくなる変更、その他の重要な機能変更についていくために、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "HubSpot開発者変更ログ"
      }), "を購読することを強くお勧めします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "廃止されたAPI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のAPIは完全に削除され、関連するエンドポイントへの呼び出しを行うとエラーが返されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["2022年11月20日以降、APIキーは、認証方法として使用できなくなります。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "この変更ログのお知らせ"
        }), "で更新についての詳細をご確認ください。また、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "APIキー連携を移行して非公開アプリを使用する"
        }), "方法もご確認ください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["2023年2月28日以降、以下のAPIは利用できなくなりました。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated",
              children: "Ecommerce Bridge API"
            }), "：以前にこのAPIを使用して連携した場合は、連携を切り替えて非公開アプリを使用するために", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "この移行ガイド"
            }), "を使用できます。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated",
              children: "会計拡張API"
            }), "：以前にこのAPIを使用して連携した場合は、連携を切り替えて非公開アプリを使用するために", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration",
              children: "この移行ガイド"
            }), "を使用できます。", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["2023年8月31日より、カレンダーAPIはご利用いただけなくなりました。これには以下のエンドポイントが含まれます。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "コンテンツイベントのリスト"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ソーシャルイベントのリスト"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "カレンダー タスク イベントのリスト"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "全てのカレンダーイベントのリスト"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "カレンダータスクの作成"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "IDによるカレンダータスクの取得"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "カレンダータスクの更新"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "カレンダータスクの削除"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "非推奨のAPI"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下にリストされているレガシーエンドポイントのバージョンは更新", (0, _jsxRuntime.jsx)("u", {
        children: "されません"
      }), "。こうしたエンドポイントは安定的に動作しますが、現在のバージョン以降は更新されません。HubSpotは非推奨のエンドポイントも当面はサポートする予定です。今後の変更については、十分な時間的余裕を持ってHubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "開発者変更ログ"
      }), "（英語）で通知されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ソーシャルメディアAPI："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-publishing-channels",
              children: "公開チャネルの取得"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
              children: "複数のブロードキャストメッセージの取得"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
              children: "単一のブロードキャストメッセージの取得"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message",
              children: "ブロードキャストメッセージの作成"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message",
              children: "ブロードキャストメッセージのキャンセル"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}