"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.password = exports.pageId = exports.name = exports.metaDescription = exports.hidden = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 181304609706;
const slug = exports.slug = 'guides/api/crm/commerce/create-update-delete-invoices';
const title = exports.title = 'CRM API | Create, update, and delete invoices (BETA)';
const name = exports.name = 'Create, update, and delete invoices (BETA)';
const metaDescription = exports.metaDescription = 'Invoices are used to bill customers. The invoices endpoints allow you to to create, update and retrieve invoices. ';
const password = exports.password = 'Invoices_179952';
const hidden = exports.hidden = true;
const toc = exports.toc = [{
  "depth": 0,
  "id": "overview",
  "label": "Overview",
  "parent": null
}, {
  "depth": 0,
  "id": "create-an-invoice",
  "label": "Create an invoice",
  "parent": null
}, {
  "depth": 0,
  "id": "configure-an-invoice",
  "label": "Configure an invoice",
  "parent": null
}, {
  "depth": 1,
  "id": "update-invoice-properties",
  "label": "Update invoice properties",
  "parent": "configure-an-invoice"
}, {
  "depth": 1,
  "id": "add-associations",
  "label": "Add associations",
  "parent": "configure-an-invoice"
}, {
  "depth": 1,
  "id": "retrieving-ids-for-associations",
  "label": "Retrieving IDs for associations",
  "parent": "configure-an-invoice"
}, {
  "depth": 1,
  "id": "creating-associations",
  "label": "Creating associations",
  "parent": "configure-an-invoice"
}, {
  "depth": 0,
  "id": "configure-payment-settings",
  "label": "Configure payment settings",
  "parent": null
}, {
  "depth": 0,
  "id": "move-the-invoice-to-the-open-status",
  "label": "Move the invoice to the Open status",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-invoices",
  "label": "Retrieve invoices",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-invoices",
  "label": "Delete invoices",
  "parent": null
}, {
  "depth": 0,
  "id": "reference",
  "label": "Reference",
  "parent": null
}, {
  "depth": 1,
  "id": "common-properties",
  "label": "Common properties",
  "parent": "reference"
}, {
  "depth": 1,
  "id": "create-a-draft-with-associations-single-request",
  "label": "Create a draft with associations (single request)",
  "parent": "reference"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      h2: "h2",
      ol: "ol",
      li: "li",
      em: "em",
      code: "code",
      pre: "pre",
      ul: "ul",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Table,
      Tag,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Table) _missingMdxReference("Table", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Invoices"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the invoices API to create, update and retrieve invoices (", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/invoices",
        children: "refer to this documentation to fetch invoice information"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once configured, an invoice can be shared with a buyer at a specified URL. Users can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/invoices/create-and-manage-invoices#manage-invoices",
        children: "manage invoices in HubSpot"
      }), " to add details, update associations, and more."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you’ve set up either ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/set-up-payments",
        children: "HubSpot payments"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
        children: "Stripe payment processing"
      }), ", you can configure an invoice to be payable through this API."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/invoices_open_example.png",
        alt: "invoices_open_example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " You need to automatically create an invoice when a contract is signed by a customer."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to create an invoice through the API and configure its various properties, associations, states, and more."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Overview"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The invoice creation process can be broken up into the following steps:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create an invoice:"
        }), " create a draft invoice and set the currency."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configure the invoice:"
        }), " add associations to CRM records, such as a contact and line items to the invoice, properties, and configure payment settings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Move the invoice status to open:"
        }), " move the invoice from ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Draft"
        }), " to ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Open"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Share the invoice:"
        }), " share the invoice with the buyer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Retrieve invoices:"
        }), " retrieve invoices using the API."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Delete invoices:"
        }), " delete existing invoices."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reference:"
        }), " a section for additional API use cases."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Invoices can have four different statuses:"
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: (0, _jsxRuntime.jsx)("span", {
                  children: "Draft"
                })
              })
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: "Invoice is being edited and is not ready to send."
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Open"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)(_components.p, {
                children: "Invoice has all of the details needed to send to the buyer, and is\npayable."
              })
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paid"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)("span", {
                children: "Invoice has been paid by the buyer."
              })
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Voided"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: "Invoice has been voided."
            })
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create an invoice"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create an invoice, you'll first configure its basic details by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/invoices"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the post body, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " object to store basic invoice information. The only property required for creating a draft invoice is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_currency"
      }), ". You can modify a drafted invoice’s properties any time by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#configure-an-invoice",
        children: "updating the invoice"
      }), ". Additionally, the invoice will inherit other property values when you later associate a contact with it, such as contact and company details, which will be required before you can set the invoice to the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Open"
      }), " state. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#configure-an-invoice",
        children: "Learn more about required properties and associations"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(Table, {
        style: {
          "borderCollapse": "collapse",
          "tableLayout": "fixed",
          "marginLeft": "auto",
          "marginRight": "auto",
          "border": "1px solid #99acc2"
        },
        bordered: false,
        children: (0, _jsxRuntime.jsx)("tbody", {
          children: (0, _jsxRuntime.jsx)("tr", {
            children: (0, _jsxRuntime.jsx)("td", {
              style: {
                "border": "1pt solid #000000"
              },
              children: (0, _jsxRuntime.jsxs)(_components.p, {
                children: ["Depending on your preferred workflow, you can instead create a draft invoice with associations through one POST request, rather than making two separate requests. ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#create-a-draft-with-associations-single-request-",
                  children: "Learn more about creating a draft invoice with one request"
                }), "."]
              })
            })
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body\n{\n\"properties\":{\n\"hs_currency\": \"USD\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response will include an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", which you'll use to continue configuring the invoice. You can update invoice properties at any time by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/invoices/{invoiceId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configure an invoice"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To move the invoice to the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Open"
      }), " status so that it can be shared and paid, you’ll need to set a few required properties and associate it with other CRM records, such as line items and a contact. If you’ve set up ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payment-processing/set-up-payments",
        children: "HubSpot payments"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payment-processing/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
        children: "Stripe payment processing"
      }), ", you can also configure the invoice’s payment settings."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Updating invoice properties."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding associations."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Configuring payment settings."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update invoice properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update an invoice’s properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/invoices/{invoiceId}"
      }), ". In the request body, include a properties object with fields for the properties you want to update."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_currency\": \"USD\",\n    \"hs_purchase_order_number\": \"12345\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The table below lists which properties are required for an invoice, and others that are autoset."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_currency"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "default",
              children: "required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Currency of the invoice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_invoice_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date of the invoice. This is autoset if a date isn't provided."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_due_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Due date of the invoice. This is autoset if a date isn't provided."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_tax_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The account tax ID listed on this invoice. The tax ID includes a tax ID type and a tax ID number. This property must match a tax ID in the account settings. Or, it will autoset. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/invoices/set-up-the-hubspot-invoices-tool#add-a-tax-id",
              children: "Learn more about tax ID’s"
            }), ".", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/invoices/set-up-the-hubspot-invoices-tool#add-a-tax-id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate other CRM records with the invoice, make a PUT request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/invoices/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      }), ". The table below show which CRM record associations are required to move the invoice to the Open state, and which are optional."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Draft"
        }), " invoice can be created without any associations."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Object type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Required"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/line-items",
              children: "Line items"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Yes"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The goods and/or services being sold through the invoice. You can create line items from ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "products in your product library"
            }), " or create custom standalone line items."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Yes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specific buyers that you're addressing in the invoice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "Company"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A specific company that you're addressing in the invoice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/discounts",
              children: "Discounts"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/fees",
              children: "fees"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/taxes",
              children: "taxes"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any discounts, fees, and taxes to be applied at checkout. When determining the total invoice amount, HubSpot first applies discounts, followed by fees, then taxes. You can use the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sort_order"
            }), " field to reorder objects of the same type. Can be set to fixed values or percentages by setting ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_type"
            }), " to either ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FIXED"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PERCENT"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieving IDs for associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To make each association, you'll first need to retrieve the ID of each object you want to associate. To retrieve each ID, you'll make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the relevant object endpoint, which follows the same pattern across each CRM object. When making each request, you can also include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " query parameter to return specific properties when needed. Below are example ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " requests for each type of object."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "GET request for line items\n/crm/v3/objects/line_items?properties=name\n\nGET request for contacts\n/crm/v3/objects/contacts?properties=email\n\nGET request for companies\n/crm/v3/objects/companies?properties=name\n\nGET request for discounts\ncrm/v3/objects/discounts?properties=hs_type,hs_value\n\nGET request for fees\ncrm/v3/objects/fees?properties=hs_type,hs_value\n\nGET request for taxes\ncrm/v3/objects/taxes?properties=hs_type,hs_value\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#GET request for line items\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/line_items?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for contacts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/contacts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for companies\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/companies?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for discounts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/discounts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for fees\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/fees?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for taxes\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/taxes?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each successful call will return a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " response with details for each fetched object type. You'll use the value in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " field to set associations in the next step."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example taxes GET response\n{\n  \"results\": [\n    {\n      \"id\": \"54486102441\",\n      \"properties\": {\n        \"hs_createdate\": \"2024-03-18T07:34:29.586Z\",\n        \"hs_lastmodifieddate\": \"2024-03-18T07:34:29.586Z\",\n        \"hs_object_id\": \"54486102441\",\n        \"hs_type\": \"PERCENT\",\n        \"hs_value\": \"20.0000\"\n      },\n      \"createdAt\": \"2024-03-18T07:34:29.586Z\",\n      \"updatedAt\": \"2024-03-18T07:34:29.586Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Creating associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your IDs retrieved, you can now make calls to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations-v4",
        children: "associations API"
      }), " to create associations."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For each type of object you want to associate with an invoice, you'll need to make a separate call by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request using the URL structure below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/invoices/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the invoice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of object you're associating with. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_items"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the object you're associating the invoice with."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below are example ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " requests for each type of object."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "PUT request to associate a line item\n/crm/v4/objects/invoices/{invoiceID}/associations/default/line_items/{lineItemId}\n\nPUT request to associate contacts\n/crm/v4/objects/invoices/{invoiceID}/associations/default/contacts/{contactId}\n\nPUT request to associate companies\n/crm/v4/objects/invoices/{invoiceID}/associations/default/companies/{companyId}\n\nPUT request to associate discounts\n/crm/v4/objects/invoices/{invoiceID}/associations/default/discounts/{discountId}\n\nPUT request to associate fees\n/crm/v4/objects/invoices/{invoiceID}/associations/default/fees/{feeId}\n\nPUT request to associate taxes\n/crm/v4/objects/invoices/{invoiceID}/associations/default/taxes/{taxId}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#PUT request to associate line items\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/invoices/{invoiceID}/associations/default/line_items/{lineItemId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate contacts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/invoices/{invoiceID}/associations/default/contacts/{contactId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a company\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/invoices/{invoiceID}/associations/default/companies/{companyId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate discounts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/invoices/{invoiceID}/associations/default/discounts/{discountId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate fees\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/invoices/{invoiceID}/associations/default/fees/{feeId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate taxes\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/invoices/{invoiceID}/associations/default/line_items/{taxId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As an example, if your invoice has an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456"
      }), ", the requests to associate the invoice might include the following:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Line items (IDs: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "55555"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "66666"
          }), "):"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/invoices/123456/associations/default/line_items/55555"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/invoices/123456/associations/default/line_items/66666"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contact (ID:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "34545"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: "):"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/invoices/123456/associations/default/contacts/34545"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each successful association will return a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " response with details about the association. The above calls will associate the objects in both directions, with each direction have its own ID. For example, if you associate the invoice with a contact, the response will describe the association from both ends. In the example response below, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "178"
      }), " is the contact-to-invoice association type ID, and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "177"
      }), " is the invoice-to-contact association type ID."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"2171512734\"\n      },\n      \"to\": {\n        \"id\": \"375894146440\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 178\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"375894146440\"\n      },\n      \"to\": {\n        \"id\": \"2171512734\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 177\n      }\n    }\n  ],\n  \"startedAt\": \"2024-10-25T10:51:43.821Z\",\n  \"completedAt\": \"2024-10-25T10:51:43.925Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configure payment settings"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Recording payments manually is not possible via the API but is ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/invoices/create-and-manage-invoices#manage-finalized-invoices",
            children: "possible in the platform"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["The HubSpot account must have either ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/payments/set-up-payments",
            children: "HubSpot payments"
          }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
            children: "Stripe payment processing"
          }), " set up before using this capability."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your account is set up to collect digital payments using HubSpot payments or Stripe payment processing, and the currency of the invoice is set to a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payment-processing/use-multiple-currencies-with-stripe#available-currencies",
        children: "currency that is valid for transacting"
      }), ", the invoice will automatically be configured to collect payments. In addition, other properties will automatically be set based on the account ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/configure-your-buyer-checkout-experience#set-the-default-payment-method-and-shipping-information",
        children: "payment settings"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The properties below can be amended if required. This is not an exhaustive list. To get a list of all available properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/invoices"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_allowed_payment_methods"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The payment methods to be used. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "credit_or_debit_card"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ach"
            }), ". ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/payment-processing/set-up-payments",
                  children: "Accepted payment methods when using HubSpot payments"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/payment-processing/connect-your-stripe-account-as-a-payment-processor-in-hubspot#configure-currencies-and-payment-methods",
                  children: "Accepted payment methods when using Stripe as a payment processor"
                }), "."]
              })]
            }), "If no value, autoset based on the account ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/configure-your-buyer-checkout-experience#set-the-default-payment-method-and-shipping-information",
              children: "payment settings"
            }), ".", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/configure-your-buyer-checkout-experience#set-the-default-payment-method-and-shipping-information"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_invoice_payment_collection_method"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "manual"
            }), " if you want the invoice to have digital payment capability. Set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            }), " if you don't want digital payment capability. If no value, autoset based on the account ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/configure-your-buyer-checkout-experience#set-the-default-payment-method-and-shipping-information",
              children: "payment settings"
            }), ".", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/configure-your-buyer-checkout-experience#set-the-default-payment-method-and-shipping-information"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_collect_address_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The types of addresses that are collected while paying the invoice. Value can be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "billing_address"
            }), " and/or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "shipping_address"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_shipping_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The shipping address of the recipient."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_shipping_city"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The shipping city of the recipient."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_shipping_state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The shipping state or region of the recipient."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_shipping_zip"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The shipping postal or zip code of the recipient."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Move the invoice to the Open status"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When an invoice has been built in the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Draft"
      }), " status with all of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#configure-an-invoice",
        children: "required properties and associations"
      }), ", you can move it to the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Open"
      }), " status, meaning the invoice can be shared and is payable."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To move the invoice to the Open status, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/invoices"
      }), " and set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_invoice_status"
      }), " property to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "open"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " to move an invoice to the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Open"
        }), " status (open invoice that is payable), one contact and at least one line item must be associated."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n\"properties\":{\n\"hs_invoice_status\": \"open\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve invoices"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can retrieve invoices individually or in batches."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve an individual invoice, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/invoices/{invoiceID}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To request a list of all invoices , make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/invoices"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For these endpoints, you can include the following query parameters in the request URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned in the response. If the requested invoice doesn't have a value for a property, it will not appear in the response."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the current and historical properties to be returned in the response. If the requested invoice doesn't have a value for a property, it will not appear in the response."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A comma separated list of objects to retrieve associated IDs for. Any specified associations that don't exist will not be returned in the response. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations-v4",
              children: "associations API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve a batch of specific invoices by their IDs, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/invoices/batch/read"
      }), " and include the IDs in the request body. You can also include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " array to specify which properties to return. The batch endpoint cannot retrieve associations. Learn how to batch read associations with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations-v4",
        children: "associations API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"inputs\": [{ \"id\": \"59970098434\" }, { \"id\": \"375806106904\" }],\n  \"properties\": [\"hs_invoice_status\", \"hs_language\", \"hs_createdate\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete invoices"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can delete invoices individually or in batches. ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Draft"
      }), " invoices can be deleted, as can unpaid ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Open"
      }), " invoices, if the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/invoices/set-up-the-hubspot-invoices-tool#turn-on-the-ability-to-delete-unpaid-invoices",
        children: "setting is turned on in the account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete an invoice, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/invoices/{invoiceID}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Common properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is a list of properties commonly used. This is not an exhaustive list. To get a list of all available properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/invoices"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_currency"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "default",
              children: "required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Currency of the invoice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_invoice_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date/time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date of the invoice. Autoset if one is not provided."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_due_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date/time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Due date of the invoice. Autoset if one is not provided."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_purchase_order_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Add an associated PO number."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_comments"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Add comments to the invoice for the buyer to see."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_tax_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The account tax ID listed on this invoice. The tax ID includes a tax ID type and a tax ID number. This property must match a tax ID in the account settings. Or, it will autoset. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/invoices/set-up-the-hubspot-invoices-tool#add-a-tax-id",
              children: "Learn more about tax ID’s"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_allowed_payment_methods"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The payment methods to be used. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "credit_or_debit_card"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ach"
            }), ". ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/payment-processing/set-up-payments",
                  children: "Accepted payment methods when using HubSpot payments"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/payment-processing/connect-your-stripe-account-as-a-payment-processor-in-hubspot#configure-currencies-and-payment-methods",
                  children: "Accepted payment methods when using Stripe as a payment processor"
                }), "."]
              })]
            }), "If no value, autoset based on the account ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/configure-your-buyer-checkout-experience#set-the-default-payment-method-and-shipping-information",
              children: "payment settings"
            }), ".", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/configure-your-buyer-checkout-experience#set-the-default-payment-method-and-shipping-information"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_invoice_payment_collection_method"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "manual"
            }), " if you want the invoice to have digital payment capability. Set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            }), " if you don't want digital payment capability. If no value, autoset based on the account ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/configure-your-buyer-checkout-experience#set-the-default-payment-method-and-shipping-information",
              children: "payment settings"
            }), ".", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/configure-your-buyer-checkout-experience#set-the-default-payment-method-and-shipping-information"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_allow_partial_payments"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Allow your customer to pay less than the balance due."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_collect_address_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The types of addresses that are collected while paying the invoice. Value can be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "billing_address"
            }), " and/or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "shipping_address"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_shipping_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The shipping address of the recipient. This address is used as the billing address."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_shipping_address2"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The second line of the shipping address of the recipient. This address is used as the billing address."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_shipping_city"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The shipping city of the recipient."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_shipping_state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The shipping state or region of the recipient."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_shipping_zip"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The shipping postal or zip code of the recipient. This postal or zip code is used as the billing postal or zip code."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_shipping_country"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The shipping country of the recipient."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_company_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The address of the recipient's company."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_company_address2"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The second line of the address of the recipient's company."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_company_city"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The city of the recipient's company."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_company_state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The state or region of the recipient's company."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recipient_company_zip"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The postal or zip code of the recipient's company."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_custom_fields"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The custom fields that have been added to the invoice. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/invoices/set-up-the-hubspot-invoices-tool#add-or-remove-custom-fields",
              children: "Learn more about the configuration of this setting"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_invoice_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The URL of the invoice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Language of the invoice.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_locale"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Locale of the invoice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pdf_download_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "PDF download link."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a draft with associations (single request)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following request body will create a new draft invoice with an association to a contact."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/invoices/batch/create"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/invoices/batch/create\n{\n  \"inputs\": [\n    {\n      \"properties\": {\n        \"hs_currency\": \"USD\",\n        \"hs_invoice_date\": \"1729859279\",\n        \"hs_due_date\": \"1732537679\"\n      },\n      \"associations\": [\n        {\n          \"to\": {\n            \"id\": \"2171512734\"\n          },\n          \"types\": [\n            {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 177\n            }\n          ]\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_currency"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "default",
              children: "required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Currency of the invoice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_invoice_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date/time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date of the invoice. Autoset if one is not provided."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_due_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date/time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Due date of the invoice. Autoset if one is not provided."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The invoice’s associated records.To set each association, include a separate object in the associations array with the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "to: the ID of the record to associate with the invoice."
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["associationCategory: the ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/associations-v4#retrieve-association-types",
                  children: (0, _jsxRuntime.jsx)("u", {
                    children: "type of association"
                  })
                }), ". Can be HUBSPOT_DEFINED or USER_DEFINED."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["associationTypeId: the ID of the type of association being made:", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsx)("li", {
                    children: "177: invoice to contact"
                  })
                })]
              })]
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations-v4#association-type-id-values",
              children: "Learn more about association type IDs"
            }), "."]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}