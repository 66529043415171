"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 99666396934;
const slug = exports.slug = 'guides/cms/marketplace/module-requirements';
const title = exports.title = 'Mercado de plantillas | Requisitos de los módulos';
const name = exports.name = 'LATAM (ES) Asset Marketplace | Module requirements';
const metaDescription = exports.metaDescription = 'Obtén información sobre los requisitos que deben cumplir los módulos de un tema al enviarlos al mercado de plantillas de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "restricciones-de-los-m%C3%B3dulos",
  "label": "Restricciones de los módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "contenido-de-los-m%C3%B3dulos",
  "label": "Contenido de los módulos",
  "parent": null
}, {
  "depth": 1,
  "id": "etiquetas-de-los-m%C3%B3dulos-y-texto-de-ayuda",
  "label": "Etiquetas de los módulos y texto de ayuda",
  "parent": "contenido-de-los-m%C3%B3dulos"
}, {
  "depth": 1,
  "id": "contenido-predeterminado",
  "label": "Contenido predeterminado",
  "parent": "contenido-de-los-m%C3%B3dulos"
}, {
  "depth": 1,
  "id": "%C3%ADconos-de-m%C3%B3dulo",
  "label": "Íconos de módulo",
  "parent": "contenido-de-los-m%C3%B3dulos"
}, {
  "depth": 1,
  "id": "m%C3%B3dulos-que-requieren-cuentas-de-terceros",
  "label": "Módulos que requieren cuentas de terceros",
  "parent": "contenido-de-los-m%C3%B3dulos"
}, {
  "depth": 0,
  "id": "campos-de-m%C3%B3dulo",
  "label": "Campos de módulo",
  "parent": null
}, {
  "depth": 1,
  "id": "configuraci%C3%B3n-de-fields.json-y-module.html",
  "label": "configuración de fields.json y module.html",
  "parent": "campos-de-m%C3%B3dulo"
}, {
  "depth": 0,
  "id": "calidad-del-c%C3%B3digo-del-m%C3%B3dulo",
  "label": "Calidad del código del módulo",
  "parent": null
}, {
  "depth": 1,
  "id": "los-m%C3%B3dulos-deben-ser-independientes",
  "label": "Los módulos deben ser independientes",
  "parent": "calidad-del-c%C3%B3digo-del-m%C3%B3dulo"
}, {
  "depth": 2,
  "id": "m%C3%B3dulos-de-tema",
  "label": "Módulos de tema",
  "parent": "calidad-del-c%C3%B3digo-del-m%C3%B3dulo"
}, {
  "depth": 2,
  "id": "m%C3%B3dulos-independientes",
  "label": "Módulos independientes",
  "parent": "calidad-del-c%C3%B3digo-del-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "restricciones-de-c%C3%B3digo-para-m%C3%B3dulos-independientes",
  "label": "Restricciones de código para módulos independientes",
  "parent": "calidad-del-c%C3%B3digo-del-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "categor%C3%ADas",
  "label": "Categorías",
  "parent": "calidad-del-c%C3%B3digo-del-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "selectores-de-nombre-de-clase",
  "label": "Selectores de nombre de clase",
  "parent": "calidad-del-c%C3%B3digo-del-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "estilos-y-javascript",
  "label": "Estilos y JavaScript",
  "parent": "calidad-del-c%C3%B3digo-del-m%C3%B3dulo"
}, {
  "depth": 0,
  "id": "organizaci%C3%B3n-de-campos",
  "label": "Organización de campos",
  "parent": null
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-contenido",
  "label": "Pestaña Contenido",
  "parent": "organizaci%C3%B3n-de-campos"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-estilos",
  "label": "Pestaña Estilos",
  "parent": "organizaci%C3%B3n-de-campos"
}, {
  "depth": 1,
  "id": "ejemplos-de-organizaci%C3%B3n-de-campo",
  "label": "Ejemplos de organización de campo",
  "parent": "organizaci%C3%B3n-de-campos"
}, {
  "depth": 2,
  "id": "valores-preestablecidos",
  "label": "Valores preestablecidos",
  "parent": "organizaci%C3%B3n-de-campos"
}, {
  "depth": 2,
  "id": "agrupaci%C3%B3n-multinivel",
  "label": "Agrupación multinivel",
  "parent": "organizaci%C3%B3n-de-campos"
}, {
  "depth": 2,
  "id": "agrupar-campos-individuales",
  "label": "Agrupar campos individuales",
  "parent": "organizaci%C3%B3n-de-campos"
}, {
  "depth": 0,
  "id": "alias",
  "label": "Alias",
  "parent": null
}, {
  "depth": 1,
  "id": "implementaci%C3%B3n-simple",
  "label": "Implementación simple",
  "parent": "alias"
}, {
  "depth": 0,
  "id": "example-of-a-v0-module",
  "label": "Example of a v0 module",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v1-module",
  "label": "Example of a v1 module",
  "parent": null
}, {
  "depth": 0,
  "id": "complex-implementation",
  "label": "Complex implementation",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v0-module",
  "label": "Example of a v0 module",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v1-module",
  "label": "Example of a v1 module",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      h3: "h3",
      em: "em",
      img: "img",
      code: "code",
      h4: "h4",
      strong: "strong",
      pre: "pre",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      Alert,
      DndModule,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Requisitos de los módulos del mercado de plantillas de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Más información sobre los requisitos para enviar un módulo al Mercado de plantillas. Estos requisitos se aplican tanto a los módulos de un tema como a los módulos independientes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Restricciones de los módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los módulos no deben contener ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), ", llamadas a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "funciones sin servidor"
      }), " o el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#crm-object",
        children: "campo de objeto del CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los siguientes tipos de módulos no deben construirse como módulos independientes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos de ancho completo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Formularios y formularios de varios pasos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos espaciadores o módulos que crean una estructura de página que no es de interfaz de usuario"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos que duplican la funcionalidad del módulo predeterminado"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos específicos de comercio"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos específicos de correo electrónico"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contenido de los módulos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Más información sobre los requisitos para las etiquetas de los módulos y el texto de ayuda, los campos y el contenido predeterminado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Etiquetas de los módulos y texto de ayuda"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los módulos deben tener etiquetas descriptivas que transmitan el propósito del módulo. La etiqueta ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Banner Hero con desplazamiento Parallax"
        }), " es descriptiva, mientras que las etiquetas ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Banner Hero"
        }), " y ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Galería"
        }), " no lo son."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Las etiquetas del módulo no deben contener números, como ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Banner Hero 01"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Las etiquetas de los módulos no deben contener guiones bajos."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Las etiquetas de los módulos no deben contener abreviaturas, como ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Col"
        }), " en lugar de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Columna"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los módulos deben contener ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#meta-json",
          children: "texto de ayuda incorporado"
        }), " cuando corresponda para transmitir cómo usar el módulo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los módulos no deben tener el mismo nombre que un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "módulo predeterminado"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el caso de los módulos independientes, la etiqueta del módulo debe coincidir con el nombre que aparece en la lista de plantillas. Por ejemplo, si tu anuncio de plantilla es ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "SuperAwesome Banner con desplazamiento"
        }), ", la etiqueta de tu módulo debe ser la misma."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-name.png",
          alt: "listing-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-label.png",
          alt: "module-label"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Contenido predeterminado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El campo predeterminado no puede incluir texto de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Lorem ipsum"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El contenido predeterminado de los campos debe representar el propósito del campo:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Al incluir campos de menú, los módulos deben usar ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Seleccionar un menú"
            }), " como la opción de contenido predeterminada."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Al incluir campos de formulario, los módulos deben usar ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Seleccionar un formulario"
            }), " como la opción de contenido predeterminada."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Al incluir campos de selección de blog, los módulos deben usar ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Seleccionar un blog"
            }), " como la opción de contenido predeterminada."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si agregar contenido predeterminado a un módulo no tiene sentido, usa un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
          children: "marcador de posición de módulo"
        }), " en su lugar para ayudar al creador de contenido a visualizar el espacio que llenará con contenido."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Íconos de módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los módulos deben incluir un ícono personalizado asignado al módulo (que reemplaza el ícono predeterminado). No utilices logotipos de empresas como íconos, como los logotipos de Apple o Amazon. Más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-an-icon",
        children: "iconos de módulo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Módulos que requieren cuentas de terceros"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para módulos individuales, si el módulo requiere una cuenta de terceros, debe anotarse en la descripción de la plantilla. Por ejemplo, si tu módulo hace uso de Google Maps Platform, debes incluir una nota*: \"El uso de este módulo requiere una cuenta de Google Cloud (Google Maps Platform)\".*"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campos de módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Revisar los requisitos específicos para los módulos en un tema y los módulos independientes a continuación:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para módulos en un tema:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Por ejemplo, los módulos deben contener texto de ayuda incorporado y contenido predeterminado específico para ciertos campos."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Una parte del ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#color",
              children: "color"
            }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#logo",
              children: "logotipo"
            }), " del tema debe heredarse de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/fields/brand-and-settings-inheritance",
              children: "configuración de marca"
            }), " de la cuenta:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Como mínimo, tres campos de color deben heredar colores de la configuración de marca de la cuenta. Los campos de color adicionales pueden tener otros colores de forma predeterminada, incluidos el blanco y el negro."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Al menos un campo de logotipo debe heredar de la configuración de marca de la cuenta. Si se utiliza un campo de imagen para representar un logotipo, el campo de imagen no tiene que heredar de la configuración de la marca."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tanto para los módulos en un tema como para los módulos independientes:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Los nombres de los campos de módulo deben describir el propósito del campo. Por ejemplo, si un campo de texto está destinado a incluir el cargo laboral de una persona, ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Cargo"
            }), " sería una descripción adecuada, mientras que ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Título"
            }), " no lo sería."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Todos los módulos predeterminados de HubSpot deben tener un diseño y mostrarse correctamente en todas las plantillas enviadas."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "configuración de fields.json y module.html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para garantizar la funcionalidad compatible entre temas y módulos independientes, los módulos deben ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#inherited-fields",
        children: "heredar"
      }), " los campos de color y fuente definiendo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "default_value_path"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), ", o ambos en su archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " y agregar una referencia a los campos de tema en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/general-requirements",
        children: "Más información sobre estos requisitos."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Calidad del código del módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Los módulos deben ser independientes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Módulos de tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cualquier archivo necesario para su módulo de tema, como CSS o JavaScript, debe estar contenido en la carpeta del tema e incluido en el directorio del tema. Puedes usar la función Archivos vinculados en el Administrador de diseño. Alternativamente, incluye los archivos que usan las funciones ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "require_js()"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-css",
        children: "require_css()"
      }), " con una ruta relativa al archivo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para bibliotecas comunes, como slick.js, puedes incluirlas usando las funciones ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), " con una URL absoluta a la CDN donde está alojada."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " no uses URL absolutas para los activos contenidos en tu portal de desarrollo, ya que las referencias entre portales no se resolverán."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Módulos independientes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para módulos independientes, todos los archivos CSS y Javascript deben estar contenidos en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), ". Alternativamente, incluye los archivos que usan las funciones ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), " con una URL absoluta a la CDN donde está alojado. No es posible utilizar la función Archivos vinculados en el Administrador de diseño, ya que solo está disponible para los módulos de temas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dado que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " se incluye en el Dom antes de cualquier archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), ", Javascript contenido en la sección ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " debe diferirse utilizando la siguiente anotación:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "document.addEventListener('DOMContentLoaded', function () {\n  // Put Javascript here\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos los scripts y archivos deben representarse en el encabezado del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "HTML"
      }), " del módulo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Restricciones de código para módulos independientes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las siguientes restricciones se aplican solo a los módulos independientes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se recomienda usar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://vanilla-js.com/",
          children: "vanilla JS"
        }), " siempre que sea posible. Agregar una biblioteca de jQuery a un sitio que no está utilizando jQuery puede causar conflictos y ralentizar la página del sitio web."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si usas una biblioteca jQuery, usa la función ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js()"
        }), " para incluir la biblioteca en el evento de que jQuery esté desactivado con la casilla de verificación (Boolean) en la configuración de la cuenta para evitar conflictos de múltiples bibliotecas jQuery."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "{% if not site_settings.include_jquery %}\n{{ require_js(\"https://code.jquery.com/jquery-3.7.0.min.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Categorías"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Todos los módulos independientes deben tener al menos una categoría. Los módulos enviados como parte de un tema no están obligados a tener categorías, pero es una buena práctica incluir al menos una. Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "agregar categorías a los módulos"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Selectores de nombre de clase"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cualquier selector de nombre de clase debe ir precedido del nombre del módulo, reemplazando los espacios con guiones. Por ejemplo, a continuación se muestra el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " para un botón llamado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ejemplo-botón"
        }), ", con cada nombre de clase y selector CSS reflejando su nombre."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<style>\n{% scope_css %}\n{# Button wrapper #}\n {% if module.styles.group_alignment.alignment %}\n  .example-button-wrapper {\n   text-align: {{ module.styles.group_alignment.alignment.horizontal_align }};\n   }\n {% endif %}\n\n{# Button #}\n\n.example-button {\n {% if module.styles.group_background.color.color %}\n  background-color: rgba({{ module.styles.group_background.color.color|convert_rgb }}, {{ module.styles.group_background.color.opacity / 100 }});\n {% endif %}\n }\n {% end_scope_css %}\n</style>\n{% end_require_css %}\n\n{##### Module HTML #####}\n\n<div class=\"example-button-wrapper\">\n <a href=\"{{ href }}\" class=\"example-button\"\n {% if module.button_link.open_in_new_tab %}target=\"_blank\"{% endif %}\n {% if rel %}rel=\"{{ rel|join(\" \") }}\"{% endif %}\n >\n  {{ module.button_text }}\n </a>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilos y JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Estilos:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Los módulos deben tener un grupo de estilo no vacío."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "No se recomienda la codificación de estilos en línea dentro de los módulos. En cambio, usa estilos inline dinámicos al habilitar campos para controlar el estilo."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["JavaScript:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "JavaScript debe poder representar múltiples instancias de un módulo. Javascript en el panel de JS solo se cargará una vez por página, independientemente del número de eventos del módulo."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Javascript debe hacer referencia a los elementos DOM según los nombres de clase específicos del módulo para garantizar que los elementos que están fuera del módulo no sean afectados de manera accidental."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear módulos, puedes usar una variable incorporada llamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{name}}"
      }), ". Esta variable extrae el ID de instancia del módulo (que se puede usar solo en el panel HTML+ HubL) para ayudar en el marcado de CSS y JS para módulos complejos. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/files#require-css-block",
        children: "Más información sobre esto en nuestra documentación para desarrolladores."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Organización de campos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Deben cumplirse los siguientes requisitos de organización y agrupación de campos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Contenido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cuando hay al menos un control dentro de un grupo de campos, todos los controles deben agruparse en categorías etiquetadas según su función."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los campos de módulo agregados a la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contenido"
        }), " deben proporcionar formas de personalizar el contenido de un módulo. Por ejemplo, controles de imagen, ícono, texto alternativo y controles de enlaces."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Estilos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los grupos de campos de estilo de módulo deben ser coherentes y seguir un patrón. A continuación, se encuentra un orden recomendado para los grupos de campos de estilo. Estos grupos pueden estar en el nivel superior o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#multi-level-grouping",
        children: "anidados en un grupo profundo"
      }), ". Los grupos vacíos también se pueden eliminar:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#presets",
          children: "Valores preestablecidos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Texto"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fondo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Border"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Colocar cursor"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Esquina"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Espaciado"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Alignment"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Grupos de estilos personalizados que no se ajustan a lo anterior"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avanzado"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los siguientes tipos de campos deben estar incluidos en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Estilos"
      }), " si están presentes:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#alignment",
          children: "Alineación"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#background-image",
          children: "Imagen de fondo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "Borde"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "Color"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "Fuente"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#gradient",
          children: "Gradiente"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "Espaciado"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#text-alignment",
          children: "Alineación del texto"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Al mover campos de la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contenido"
        }), " a la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Estilos"
        }), ", descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#aliases",
          children: "usar la asignación de alias"
        }), " para conservar el estilo de los módulos que ya están en uso en páginas activas."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Las opciones de animación siempre deben colocarse cerca de la parte inferior de la lista de grupos de campos."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Las opciones que permiten a los creadores de contenido agregar fragmentos de código o CSS deben agruparse al final de la lista de grupos de campos bajo un campo con la etiqueta ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Avanzado"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Los controles deben estar estandarizados en todos los módulos. Por ejemplo, todos los elementos que pueden tener un control de radio de borde deben ofrecer ese control. Evita ofrecer controles en algunos módulos que estén ausentes en otros."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Los campos de módulo agregados a la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Estilo"
          }), " deben proporcionar formas de diseñar el módulo. Por ejemplo:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Las opciones de estilo, como color, estilo de texto, alineación, espaciado, borde y radio de esquina."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Animaciones, como efectos de colocar el cursor y elementos deslizantes."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Valores preestablecidos, como temas oscuros y claros que están destinados a cambiar muchos estilos al mismo tiempo."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ejemplos de organización de campo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Valores preestablecidos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los valores preestablecidos se pueden usar cuando se quiere dar a los creadores de contenido un conjunto limitado de opciones, a menudo vinculadas a la configuración del tema. Por ejemplo, el módulo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ícono"
      }), " incluido en el tema Growth contiene valores predeterminados para colores ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Oscuros"
      }), " y ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Claros"
      }), ", lo que permite que haya coherencia cuando se usa en todo el sitio web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Agrupación multinivel"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al decidir si mantener los campos de estilo en el nivel superior o anidarlos, considera el siguiente ejemplo."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["El módulo ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Ícono"
            }), " incluido en el tema Growth enumera todos sus estilos en el nivel superior porque es un componente y, por lo tanto, sus opciones de estilo afectan a un componente."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-icon-module.png",
            alt: "growth-theme-icon-module"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["El módulo ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Tarjeta de conferencista"
            }), " incluido en el tema Growth contiene múltiples componentes: la imagen de la tarjeta y su contenido de texto. Por lo tanto, los estilos de módulo se agrupan por componentes para que el creador de contenido tenga un proceso más claro para diseñar cada componente."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-speaker-card.png",
            alt: "growth-theme-speaker-card"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Agrupar campos individuales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El siguiente módulo de botón contiene agrupaciones para ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Valores preestablecidos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Texto"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Fondo"
      }), " y más. Aunque el grupo de campos ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Esquina"
      }), " contiene solo el control de radio de esquina, se agrupa para crear una experiencia uniforme en la creación de contenido."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-requirements-2_1.png",
        alt: "module-requirements-2_1"
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/button-styles.png",
        alt: "button-styles"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alias"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La asignación de alias te permite crear asignaciones de campos en un módulo para que puedas mover, cambiar el nombre o reemplazar sus campos sin afectar las páginas que usan el módulo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, se está utilizando un módulo en una página en directo. Deseas mover algunos campos a la pestaña ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Estilos"
        })
      }), ", como el color o la fuente, pero un creador de contenido ya ha seleccionado valores para esos campos en el editor. Si movieras esos campos sin configurar el mapeo de alias, HubSpot no podría reubicar esos campos y volverían a sus valores predeterminados, lo que desharía el estilo en la página en vivo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En cambio, puedes agregar una propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), " a un campo para asignarlo a otro. Luego, cuando no se ha establecido un valor para el campo original, HubSpot comprobará si existe un valor en el campo asignado. Si tampoco existe ningún valor en el campo asignado, usará el valor predeterminado. Esta propiedad se puede usar para asignar valores de campo entre diferentes versiones de un módulo solo cuando el tipo de datos almacenados del campo antiguo es el mismo que el tipo de datos almacenados del campo nuevo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ver una explicación visual de esta característica, echa un vistazo al video a continuación."
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SvESTX8SLJQ",
      maxHeight: "367px",
      maxWidth: "700px",
      allowFullScreen: true
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para migrar campos existentes a alias:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crea nuevos campos y asignarlos a campos antiguos utilizando la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aliases_mapping"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Elimina la definición de campo anterior."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Actualiza el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " para usar la nueva definición de campos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "No puedes asignar campos que son de un tipo de datos diferente entre sí. Por ejemplo, no puedes asignar un campo de gradiente de fondo a un campo de imagen. El valor almacenado debe ser un valor válido para el tipo de campo nuevo."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Si se crea un nuevo campo que contiene una asignación de alias a un campo antiguo, los valores predeterminados y las propiedades requeridas de ambos campos deben ser los mismos."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Más abajo aparecen ejemplos de implementación de esto para cambios simples y complejos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#simple-implementation",
          children: "Implementación simple"
        }), ": asignación de un campo de color a otro campo de color nuevo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#complex-implementation",
          children: "Implementación compleja"
        }), ": asigna un campo numérico al subcampo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "size"
        }), " de un campo de fuente para controlar el tamaño de la fuente."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Implementación simple"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En situaciones simples, el tipo de campo del campo antiguo y el tipo de campo del nuevo campo deben ser iguales. Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Campo de color antiguo a campo de color nuevo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Campo de texto antiguo a campo de texto nuevo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Campo de espaciado antiguo a campo de espaciado nuevo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, se muestra un ejemplo de uso de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), " al mover un campo de color de la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Contenido"
      }), " del módulo a la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Estilos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v0 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Button Color\",\n    \"name\": \"old_button_color_field\",\n    \"type\": \"color\",\n    \"required\": true,\n    \"default\": {\n      \"color\": \"#FFFFFF\",\n      \"opacity\": 100\n    }\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v1 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Styles\",\n    \"name\": \"styles\",\n    \"type\": \"group\",\n    \"tab\": \"STYLE\",\n    \"children\": [\n      {\n        \"label\": \"Button Color\",\n        \"name\": \"new_button_color_field\",\n        \"type\": \"color\",\n        \"required\": true,\n        \"aliases_mapping\": {\n          \"property_aliases_paths\": {\n            \"new_button_color_field\": [\"old_button_color_field\"]\n          }\n        },\n        \"default\": {\n          \"color\": \"#FFFFFF\",\n          \"opacity\": 100\n        }\n      }\n    ]\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Complex implementation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En situaciones más complejas, también puede asignar campos a subcampos u otros tipos de campo de módulo siempre que el tipo de datos sea el mismo y el tipo de subcampo del nuevo campo coincida. Los subcampos son las propiedades dentro del objeto de valor almacenado del campo. Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Asignación de un campo de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "texto enriquecido"
        }), " a un campo de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "texto"
        }), ", ya que los valores de ambos campos se almacenan como cadenas."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consolidación de campos de tipografía, como cambiar de un campo numérico para el tamaño de fuente, para usar un campo de fuente (que tiene un subcampo de tamaño de fuente). Puedes agregar un alias para el subcampo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "size"
        }), " para asignarlo al campo numérico anterior mediante la notación de puntos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se muestra un ejemplo de cambio de la opción de tamaño de fuente de un campo numérico a un campo de fuente que tiene un subcampo de tamaño de fuente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v0 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_number_field\",\n    \"label\": \"Number field\",\n    \"required\": false,\n    \"locked\": false,\n    \"display\": \"text\",\n    \"step\": 1,\n    \"type\": \"number\",\n    \"min\": null,\n    \"max\": null,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"default\": null\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v1 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_font_field\",\n    \"label\": \"font_field\",\n    \"required\": false,\n    \"locked\": false,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"load_external_fonts\": true,\n    \"type\": \"font\",\n    \"aliases_mapping\": {\n      \"property_aliases_paths\": {\n        \"my_font_field.size\": [\"my_number_field\"]\n      }\n    },\n    \"default\": {\n      \"size\": 12,\n      \"font\": \"Merriweather\",\n      \"font_set\": \"GOOGLE\",\n      \"size_unit\": \"px\",\n      \"color\": \"#000\",\n      \"styles\": {}\n    }\n  }\n]\n"
          })
        })
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}