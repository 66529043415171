"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529728;
const slug = exports.slug = 'reference/cms/hubl/if-statements';
const title = exports.title = 'Fonctions SI';
const name = exports.name = 'EMEA (FR) if statements';
const metaDescription = exports.metaDescription = "Profitez d'un guide pratique des fonctions if HubL de HubSpot pour les développeurs CMS.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "syntaxe-de-base-des-fonctions-if",
  "label": "Syntaxe de base des fonctions if",
  "parent": null
}, {
  "depth": 0,
  "id": "utilisation-de-elif-et-else",
  "label": "Utilisation de elif et else",
  "parent": null
}, {
  "depth": 0,
  "id": "fonctions-unless",
  "label": "Fonctions unless",
  "parent": null
}, {
  "depth": 0,
  "id": "ifchanged",
  "label": "ifchanged",
  "parent": null
}, {
  "depth": 0,
  "id": "fonctions-if-en-ligne",
  "label": "Fonctions if en ligne",
  "parent": null
}, {
  "depth": 0,
  "id": "op%C3%A9rateurs-ternaires",
  "label": "Opérateurs ternaires",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      code: "code",
      h2: "h2",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Fonctions if"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez inclure une logique conditionnelle dans vos modules et modèles en utilisant les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#basic-if-statement-syntax",
        children: "fonctions if"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#unless-statements",
        children: "unless"
      }), " de HubL. Les fonctions if contiennent souvent des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "opérateurs pris en charge"
      }), " par HubL et peuvent être utilisées pour exécuter des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "tests d'expression"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si vous utilisez des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#personalization-token",
          children: "jetons de personnalisation"
        }), " dans une fonction conditionnelle de votre module d'e-mail, vous devez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
          children: "activer les e-mails programmables pour ce module"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les informations transmises via les API d'envoi unique (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/transactional-emails#single-send-api",
          children: "v3"
        }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/single-send-api",
          children: "v4"
        }), ") ne fonctionneront pas dans les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if"
        }), ", car les modèles sont compilés avant que les informations ne soient renseignées."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Syntaxe de base des fonctions if"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL utilise des fonctions if pour définir la logique d'un modèle. La syntaxe des fonctions if de HubL est très similaire à celle de la logique conditionnelle dans Python. Les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " sont encadrées par des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "délimiteurs de fonction"
      }), ", commençant par une fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " d'ouverture et se terminant par une fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessous fournit la syntaxe de base d'une fonction if, où la condition est remplacée par la règle booléenne que vous allez évaluer comme étant vraie ou fausse."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if condition %}\n\tIf the condition is true print this to template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant que vous avez vu la syntaxe de base, penchons-nous sur quelques exemples concrets de fonctions if de base. Les exemples suivants présentent des fonctions if qui déterminent si un module HubL portant le nom ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), " et si une variable nommée ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), " sont présents ou non dans un modèle. Notez que sans aucun opérateur, la fonction if détermine si le module est défini ou non dans le contexte du modèle."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_module\" path=\"@hubspot/rich_text\", label=\"My rich text module\", html=\"Default module text\" export_to_template_context=true %}\n\n{% if widget_data.my_module %}\n\tA module named \"my_module\" is defined in this template.\n{% endif %}\n\n\n{% set my_variable = \"A string value for my variable\" %}\n{% if my_variable %}\n\tThe variable named my_variable is defined in this template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Notez que lors de l'évaluation du module HubL, le nom du module est laissé entre guillemets dans la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " et que lors du test de la variable, aucun guillemet n'est utilisé autour du nom de la variable. Dans les deux exemples ci-dessus, le module et la variable existent dans le modèle, de sorte que les fonctions sont évaluées pour imprimer le balisage. Notez que ces exemples testent uniquement si le module et la variable sont définis, et non s'ils ont une valeur ou non."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Examinons maintenant une fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " qui évalue si un module a une valeur, au lieu d'évaluer s'il existe dans le modèle. Pour ce faire, nous devons utiliser le paramètre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "export_to_template_context"
      }), ". Dans l'exemple ci-dessous, si l'élément de texte a une valeur dans l'éditeur de contenu, le balisage s'imprimera. Si le champ de texte du module était vide, aucun balisage ne serait restitué. Si vous travaillez dans des modules personnalisés, il existe une syntaxe simplifiée de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.widget_name"
      }), " décrite dans l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "exemple ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"product_names\" path=\"@hubspot/text\", label=\"Enter the product names that you would like to render the coupon ad for\", value=\"all of our products\", export_to_template_context=True %}\n\n{% if widget_data.product_names.value %}\n<div class=\"coupon-ad\">\n<h3>For a limited time, get 50% off {{ widget_data.product_names.value}}! </h3>\n</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"coupon-ad\">\n  <h3>For a limited time get 50% off all of our products!</h3>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation de elif et else"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " peuvent être restituées de manière plus sophistiquée avec des fonctions conditionnelles supplémentaires ou avec une règle qui s'exécute lorsque la ou les conditions sont fausses. Les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " vous permettent d'ajouter des conditions supplémentaires à votre logique, qui seront évaluées après la condition précédente. Les fonctions ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "else"
        })
      }), " définissent une règle qui s'exécute lorsque toutes les autres conditions sont fausses. Vous pouvez avoir un nombre illimité de fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " dans une seule fonction if, mais une seule fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Voici l'exemple de syntaxe de base d'une fonction if qui utilise l'", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: ["opérateur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<="
        })]
      }), " pour vérifier la valeur d'une variable. Dans cet exemple, le modèle imprime : « Le nombre de la variable nommée est inférieur ou égal à 6. »"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set number = 5 %}\n\n{% if number <= 2 %}\n\tVariable named number is less than or equal to 2.\n{% elif number <= 4 %}\n\tVariable named number is less than or equal to 4.\n{% elif number <= 6 %}\n\tVariable named number is less than or equal to 6.\n{% else %}\n\tVariable named number is greater than 6.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Voici un autre exemple qui utilise un module de choix pour rendre différents titres pour une page de carrières, en fonction du département choisi par l'utilisateur. L'exemple utilise l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: "opérateur =="
      }), ", pour vérifier certaines valeurs prédéfinies dans le module de choix."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fonctions unless"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), " sont des conditionnelles comme les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", mais elles fonctionnent selon la logique inverse. Elles restitueront et compileront le code entre les balises d'ouverture et de fermeture, sauf si la condition booléenne unique est vraie. Les fonctions commencent par ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "unless"
        })
      }), " et se terminent par ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endunless"
        }), ". Les fonctions"]
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), " prennent en charge les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), ", mais pas les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Voici un exemple qui imprime un en-tête En construction, sauf si le champ de texte enrichi a une valeur. Si le champ de texte enrichi a du contenu, celui-ci s'affichera."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_page_content\" path=\"@hubspot/rich_text\", label=\"Enter your page content\", html=\"\" export_to_template_context=true %}\n\n{{ widget_data.my_page_content.html }}\n\n{% unless widget_data.my_page_content.html %}\n<h1>This page is under construction.</h1>\n<h3>Come back soon!</h3>\n{% endunless %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ifchanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En plus des fonctions if et unless, HubL prend en charge les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ifchanged"
      }), ". Ces fonctions peuvent être utilisées pour ne rendre le balisage que lorsqu'une variable a été modifiée depuis une invocation antérieure de cette balise."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fonctions if en ligne"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL prend en charge les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " en ligne. Celles-ci peuvent être utilisées pour écrire une logique conditionnelle de manière concise avec des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "opérateurs et des tests d'expression"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set color = \"Blue\" if is_blue is truthy else \"Red\" %}     // color == \"blue\"\n\n{{ \"Blue\" if is_blue is truthy else \"Red\" }}     // \"Blue\"\n\n{% set dl = true %}\n<a href=\"http://example.com/some.pdf\" {{\"download\" if dl }} >Download PDF</a>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Opérateurs ternaires"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il est également possible d'utiliser des opérateurs ternaires pour écrire rapidement une logique conditionnelle avec des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#logical",
        children: "opérateurs et des tests d'expression"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// If the variable is_blue is true, output \"blue\", otherwise output\"red\"\n{{ is_blue is truthy ? \"blue\" : \"red\" }}\n\n// Set the variable is_red to false if is_blue is true, otherwise set to true\n{% set is_red = is_blue is truthy ? false : true %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}