'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["mediaType"];
import { findSchemaDefinition } from '../../ParamForm/utils/schemaUtils';
import getSampleSchema from '../../Response/utils/get-sample-schema';
function getMediaType(content) {
  let mt = '';
  if (content) {
    mt = Object.keys(content)[0];
  }
  return mt;
}
function getMediaTypesForResponse(response) {
  if (response.content) {
    return Object.keys(response.content);
  }
  return [];
}
function hasMultipleMediaTypes(response) {
  return response.content ? Object.keys(response.content).length > 1 : false;
}
function getExampleResponse(response, mediaType, oas, prettyPrint = true) {
  const responseMediaType = response.content[mediaType];
  if (responseMediaType.example) {
    return responseMediaType.example;
  }
  if (responseMediaType.examples) {
    if (responseMediaType.examples.response) {
      return responseMediaType.examples.response.value;
    }
    const exampleKeys = Object.keys(responseMediaType.examples);
    if (exampleKeys.length === 1) {
      return responseMediaType.examples[exampleKeys[0]];
    } else {
      // Handle this in getMultipleExamples
      return false;
    }
  }
  if (responseMediaType.schema) {
    // As a last resort, generate an example value from the schema
    const sampleResponse = getSampleSchema(responseMediaType.schema, oas, mediaType, {
      includeReadOnly: true,
      noStringify: true,
      noPrettyPrint: !prettyPrint,
      maxCircularReferences: 1
    });
    if (sampleResponse) {
      return sampleResponse;
    }
  }
  return false;
}
function getMultipleExamples(response, mediaType) {
  if (!response.content[mediaType].examples || response.content[mediaType].examples.response) return false;
  const {
    examples
  } = response.content[mediaType];
  return Object.keys(examples).map(key => {
    return {
      label: key,
      code: typeof examples[key] === 'object' ? JSON.stringify(examples[key], undefined, 2) : examples[key]
    };
  });
}
function constructMediaTypeObject(mediaType, response, example, prettyPrint = true) {
  const multipleExamples = getMultipleExamples(response, mediaType);
  if (!example && !multipleExamples) return false;
  return {
    mediaType,
    code: typeof example === 'object' ? prettyPrint ? JSON.stringify(example, undefined, 2) : JSON.stringify(example) : example,
    multipleExamples: !example ? multipleExamples : false,
    data: typeof example === 'object' ? example : undefined
  };
}
function getMediaTypeObjectExamples(object, mediaType, oas, prettyPrint = true) {
  const mediaTypes = [];
  const example = object.content[mediaType].code || getExampleResponse(object, mediaType, oas, prettyPrint);
  const clang = constructMediaTypeObject(mediaType, object, example, prettyPrint);
  if (clang) {
    mediaTypes.push(clang);
  }
  return mediaTypes;
}
export function getResponseExamples(pathOperation, oas) {
  pathOperation._cache = pathOperation._cache || {};
  if (pathOperation._cache.responseResults) {
    return pathOperation._cache.responseResults;
  }
  const codes = Object.keys(pathOperation.responses || {}).map(status => {
    let response = pathOperation.responses[status];
    if (response.$ref) {
      response = findSchemaDefinition(response.$ref, oas);
    }
    let mediaTypes = [];
    const responseMediaTypes = hasMultipleMediaTypes(response) ? getMediaTypesForResponse(response) : [response.language || getMediaType(response.content)];
    responseMediaTypes.forEach(mediaType => {
      if (!mediaType) return false;
      const mediaTypeExamples = getMediaTypeObjectExamples(response, mediaType, oas, true);
      mediaTypes = mediaTypes.concat(mediaTypeExamples);
      return true;
    });
    return {
      status,
      mediaTypes
    };
  }).filter(Boolean).reduce((acc, code) => {
    acc[code.status] = {
      examples: code.mediaTypes.reduce((_acc, ex) => {
        const {
            mediaType
          } = ex,
          rest = _objectWithoutPropertiesLoose(ex, _excluded);
        _acc[mediaType] = rest;
        return _acc;
      }, {}),
      mediaTypes: code.mediaTypes.map(mt => mt.mediaType)
    };
    return acc;
  }, {});
  pathOperation._cache.responseResults = codes;
  return codes;
}
export function getRequestBodyExample(pathOperation, oas, prettyPrint = true) {
  pathOperation._cache = pathOperation._cache || {};
  if (pathOperation._cache.requestResults) {
    return pathOperation._cache.requestResults;
  }
  let requestBody = pathOperation.requestBody;
  if (!requestBody) {
    return {};
  }
  if (requestBody.$ref) {
    requestBody = findSchemaDefinition(requestBody.$ref, oas);
  }
  const mediaType = getMediaType(requestBody.content);
  const example = getMediaTypeObjectExamples(requestBody, mediaType, oas, prettyPrint)[0];
  pathOperation._cache.requestResults = example;
  return example;
}