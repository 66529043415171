"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45756943282;
const slug = exports.slug = 'guides/cms/content/templates/types/quotes';
const title = exports.title = 'Custom quote templates';
const name = exports.name = 'Custom quote templates';
const metaDescription = exports.metaDescription = 'Build custom coded templates for the HubSpot quotes tool.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "overview",
  "label": "Overview",
  "parent": null
}, {
  "depth": 0,
  "id": "custom-enumeration-properties",
  "label": "Custom enumeration properties",
  "parent": null
}, {
  "depth": 0,
  "id": "enable-e-signatures",
  "label": "Enable e-signatures",
  "parent": null
}, {
  "depth": 0,
  "id": "print-and-pdf-versions-of-custom-quotes",
  "label": "Print and PDF versions of custom quotes",
  "parent": null
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related Resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      em: "em",
      h2: "h2",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Custom quote templates"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-professional', 'sales_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the sales process, a sales rep ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/create-deals",
        children: "creates a deal"
      }), ", then the sales rep ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/use-quotes",
        children: "creates a quote associated with that deal"
      }), ". The sales rep sends the quote URL or PDF to a prospect. The prospect then accepts or declines the quote. In some cases payment is exchanged right away, in some cases an e-signature is used."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you can create custom quote themes and templates with any HubSpot subscription, including ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Free"
          })
        }), ", but an account will need ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " to use those templates for their quotes."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you previous built proposal templates, learn ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.google.com/document/d/1Cv3p4wnbjkNmkvvU_r9A_wjFZVuN_gLuUJpp4SO4Tyw/edit",
        children: "how to migrate an existing proposal template to quotes."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Overview"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Custom quote templates are built using the same underlying systems that other types of templates use. For example:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Domain-level settings apply to quotes, including head and footer HTML and domain stylesheets. You can disable domain stylesheets using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
          children: "template annotations."
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Most of HubL's functionality works on quote templates, including functions, filters, if conditions, imports, and includes."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/personalize-your-content",
          children: "personalization tokens"
        }), " in a quote, HubSpot will not render them dynamically. Instead, the token is rendered at the time of publishing the quote, and will not update upon signing. For this reason, you should not use personalization tokens for properties that are updated after a quote is published, including:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Payment status"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Payment date"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Esign date"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Esign completed signatures"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Due to the specific use-case of quotes, however, there are some key differences between how quotes work from the way page and email templates work:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "More data is available to the quote template that is restricted for other template types. For example, quote and deal-related data is available to a quote template. You can also include contact data for quote recipients in a quote template."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["There is not currently a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "drag and drop"
        }), " editor for building quote templates. Instead, there is a module-based editor for customizing or hiding the modules that are already in a template."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Because more data is available to quote templates without requiring password protection. You should take care to only expose information that is truly required for the purpose of the quote."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Custom enumeration properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Enumeration properties such as dropdown menus, or multiple checkboxes, can be incorporated into custom quote templates. See a list of all property types ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/property-field-types-in-hubspot",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The JSON returned for custom enumeration properties includes both the internal value, as well as the external label."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " by default, quotes created after September 13th, 2024 will display the property label, rather than the internal value."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "custom_properties: {\n  my_custom_property: {\n    label:\"Label1\",\n    internal:\"value1\"\n  },\n  another_custom_property: {\n    label:\"Label99\",\n    internal:\"value99\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For CMS Developers using custom quote templates, the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/cms/hubl/functions#crm_property_definition",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_property_definition"
        })
      }), " function can be used to retrieve quote property data, and will allow you to replace the label with the value where necessary."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set dealEnum = template_data.quote.associated_objects.deal.deal_enum %}\n{% set dealEnumProp = crm_property_definition(\"DEAL\", \"deal_enum\").options|selectattr('label', \"equalto\", dealEnum)|first %}\n{{ dealEnumProp.value }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Enable e-signatures"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sales Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Starter"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Professional"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " accounts, quotes can be configured to include ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes",
        children: "e-signature functionality"
      }), ". To enable this for custom quote templates, add the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#quote-signature",
        children: "quote_signature module"
      }), " to the quote template."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<section class=\"signature\">\n  {% module \"signature\" path=\"@hubspot/quote_signature\" %}\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because Dropbox Sign renders the print version of a quote for signing, ensure that the signature field is displayed in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#print-and-pdf-versions-of-custom-quotes",
        children: "print version"
      }), " of your quote. Otherwise, Dropbox Sign will display an error when the user goes to verify their signature."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/esignature-error.png",
        alt: "esignature-error"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Print and PDF versions of custom quotes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you'd like to enable users to print or download a quote, it's recommended to include the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#quote-download",
        children: "download module"
      }), ". Alternatively, since a quote is a web page you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.w3schools.com/jsref/tryit.asp",
        children: "use JavaScript and a button element"
      }), " to provide an easy way to print the quote."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To optimize and style the print and PDF version of a quote template, you can use the ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/CSS/@media#print",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "@media print"
        }), " media query"]
      }), " in the template's stylesheet. For example, HubSpot's default ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Basic"
      }), " quote theme includes the following print styling in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "basic.css"
      }), " stylesheet:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "@media print {\n  .hs-quotes--basic {\n    max-width: unset;\n  }\n\n  .hs-quotes--basic .line-items__total-name {\n    float: left;\n  }\n\n  .hs-quotes--basic .comments,\n  .hs-quotes--basic .terms {\n    break-inside: avoid;\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To preview the print version in Chrome:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Open the web version of a quote."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Right-click the page, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Inspect"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the top right of the DevTools panel, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "three vertical dots"
          }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "⋮"
          }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "More tools"
          }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Rendering"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/chrome-settings-more-tools.png",
            alt: "chrome-settings-more-tools"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Rendering"
          }), " panel, scroll to the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Emulate CSS media type"
          }), " section. Then, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "dropdown menu"
          }), " and select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "print"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/chrome-rendering-media-type-print.png",
            alt: "chrome-rendering-media-type-print"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can now continue to testing out styling in Chrome. When you're ready to apply the styling to the template, copy the styles into your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@media print"
      }), " media query, then upload the template to HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Updated styling will only apply to quotes created after updating the template. Existing quotes using the template will not be updated."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related Resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/getting-started-from-the-cms-quotes-theme-beta",
          children: "Getting started with the CMS quotes theme"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/quote-variables",
          children: "Custom quote variable reference"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/create-custom-quote-templates-beta",
          children: "Create and use custom quote templates (from the sales, sales ops/manager perspective)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}