"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 23486111653;
const slug = exports.slug = 'docs/methods/tracking_code_api/tracking_code_overview';
const title = exports.title = 'Tracking Code API Overview';
const name = exports.name = 'Tracking Code API Overview';
const metaDescription = exports.metaDescription = 'The HubSpot tracking code allows you to identify visitors, track events, and track page views.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h3: "h3",
      strong: "strong",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Tracking Code API Overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The details for events on this page are only for legacy custom events. For the\nnew ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
          children: "custom behavioral\nevents"
        }), ",\nplease see the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "updated\ndocumentation"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition to tracking page views, the HubSpot tracking code allows you to identify visitors, track events, and manually track page views (without reloading the page). The Tracking Code API allows you to dynamically create events and track event data in HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Function calls are pushed into the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), " array:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var _hsq = window._hsq = window._hsq || [];\n\\_hsq.push([\"method\", \"arg1\"]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anything already in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), " array is processed when the tracking code is loaded, so you can push an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "identify"
      }), " call to the array before the code loads to have the identity information pushed with the initial ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "trackPageView"
      }), " call."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Note: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "trackPageView"
        }), " is automatically called when the tracking code loads, so you should not push any ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "trackPageView"
        }), " calls to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "_hsq"
        }), " before the page loads."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "How do identities work?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot analytics tool identifies unique records using two pieces of data, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "usertoken"
      }), " (stored in the visitors browser in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspotutk"
      }), " cookie) and the email address. When the HubSpot tracking code tracks an action (such as a page view) for a visitor, it automatically associates that action with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "usertoken"
      }), ". When you use the Tracking Code API to identify a visitor by email address, the analytics system will tie that email to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "usertoken"
      }), ", allowing HubSpot to update an existing contact record or create a new one. HubSpot will ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/faq/how-does-hubspot-validate-email-addresses",
        children: "validate the email address"
      }), " used for any process that would create or update a contact record. Analytics data (page views, original source, etc.) associated with the  ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "usertoken"
      }), " will show up on the contact record."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With this in mind, you should only use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "identify"
      }), " method when you know the identity (i.e. the email address) of the person viewing the page. Using a placeholder email for unknown visitors will create a contact record with that placeholder email, and the visitor's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "usertoken"
      }), " cookie will be associated with that record, meaning that all unknown visitors would be associated with that single placeholder contact."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Keep in mind that the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "identify"
      }), " function only sets the identities in the tracker. The identities do not get passed to HubSpot until you make a separate ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "trackPageView"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "trackEvent"
      }), " call."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Please note that the HTTP API cannot be used to associate analytics data with a contact record, though you can still use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email="
      }), " parameter in the URL to associate the event with the contact with that email address (or create a new contact if the email address does not belong to an existing record)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tracking events"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Note"
        }), ": Events will only be processed for accounts with ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub Enterprise"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "trackEvent"
      }), " function is used to track be ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-and-manage-your-events-with-the-events-analytics-tool",
        children: "behavioral events"
      }), " in your HubSpot reports. Events are used to track that some action occurred, and can be tied to a contact record so that you can see if/when a contact triggered that event. While you can set up simple events in HubSpot, you can use the Events API to track more complicated processes on your website or track events that occur offline."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Which one of the two Event APIs should I use?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are two APIs that work with behavioral events, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "trackEvent"
      }), " function in the Tracking Code API (which is the preferred API) and the HTTP API."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "trackEvent"
      }), " function requires that the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://help.hubspot.com/articles/KCS_Article/Reports/How-do-I-install-the-HubSpot-tracking-code-on-my-website",
        children: "HubSpot tracking code"
      }), " be installed on the page.  Since this function  works alongside HubSpot's analytics tracking, any events triggered through the JavaScript API will automatically be associated with the visitor's hubspotutk cookie (often called the usertoken), so the event would automatically be tied to the contact associated with that usertoken (see the section about identities below for more information)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The HTTP API can be used in cases where the HubSpot tracking code cannot be installed, so it can be used to track offline events (so you could trigger an event based on data processed in a CRM).  While the HTTP API cannot be used to tie analytics data to a contact record, you can identify a contact record by email address, so that offline events can still be tied to contact records."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Event IDs and names"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Events can be triggered using a numerical ID or a string name. If you're creating events in HubSpot (Reports > Analytics Tools > Behavioral Events), they'll automatically be assigned a numerical ID, which will be used to trigger that specific event. You can get the ID out of the URL when viewing the event in HubSpot. See ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://knowledge.hubspot.com/articles/KCS_Article/Reports/How-do-I-create-Events-in-HubSpot",
        children: "this page"
      }), " for more details about creating events."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also trigger an event using a string name for the event. If you use a name, and there is not an existing event that already has that name, a new event with that name will be dynamically created. If an event with that name was already dynamically, then the new event will count towards that existing one. This allows you to keep dynamically creating new events without doing manual work in HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Notes:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Events created in the HubSpot app cannot be triggered by name, only by ID."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Events created dynamically by name can only be created ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "once"
          }), ". If you delete an event that was created this way, a new event ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "will not be created"
          }), " if you try to dynamically trigger an event with the same name."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tracking in single-page applications"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot tracking code will automatically record a page view when the code is first loaded, but you can also manually track page views in a single-page application without reloading the tracking code. You can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "trackPageView"
      }), " functions to update and track the current page:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-<!--",
        children: "<script\\>\n  var \\_hsq \\= window.\\_hsq \\= window.\\_hsq || [];\n  \\_hsq.push(['setPath', '/home']);\n</script\\>\n\n<!-- Load the HubSpot tracking code -->\n<!-- Start of HubSpot Embed Code \\-->\n  <script type\\=\"text/javascript\" id\\=\"hs-script-loader\" async defer src\\=\"//[js.hs-scripts.com/{hubId}.js](http://js.hs-scripts.com/%7BhubId%7D.js)\"\\></script\\>\n<!-- End of HubSpot Embed Code \\-->\n\n<!-- Tracking subsequent page views -->\n<script\\>\n  var \\_hsq \\= window.\\_hsq \\= window.\\_hsq || [];\n  \\_hsq.push(['setPath', '/about-us']);\n  \\_hsq.push(['trackPageView']);\n</script\\>```\n\nSee the documentation for [setPath](/reference/api/analytics-and-events/tracking-code) and [trackPageView](/reference/api/analytics-and-events/tracking-code) for more details on those functions.\n\n### Checking consent banner status\n\nIf your site uses the [privacy consent banner](https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-enable-a-privacy-policy-alert-if-you-are-doing-business-in-europe), you can use the `addPrivacyConsentListener` function to check the consent status of the visitor. See the documentation for the [addPrivacyConsentListener](/reference/api/analytics-and-events/cookie-banner/cookie-banner-api) function for more details.\n\n### Removing consent banner cookies\n\nIf you need to remove the consent banner cookies for a visitor, such as in the case of a GDPR related deletion request, you can use the `revokeCookieConsent` function. See the documentation for the [revokeCookieConsent](/reference/api/analytics-and-events/cookie-banner#remove-cookies) function for more details.\n\n### Place do not track cookie\n\nIn the event where you need to prevent all tracking by the HubSpot tracking code, you can place the do not track cookie into a visitor's browser. See the documentation for the [doNotTrack](/reference/api/analytics-and-events/cookie-banner/cookie-banner-api) function for more details.\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}