"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816705799;
const slug = exports.slug = 'guides/apps/marketplace/certification-requirements';
const title = exports.title = "Marketplace des applications | Conditions relatives à la certification d'applications";
const name = exports.name = 'EMEA (FR) App Certification Requirements';
const metaDescription = exports.metaDescription = "Découvrez ce dont les partenaires d'application ont besoin pour certifier leurs applications dans le marketplace des applications.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "pr%C3%A9sentation",
  "label": "Présentation",
  "parent": null
}, {
  "depth": 0,
  "id": "avantages-de-la-certification",
  "label": "Avantages de la certification",
  "parent": null
}, {
  "depth": 0,
  "id": "crit%C3%A8res-d-%C3%A9valuation",
  "label": "Critères d'évaluation",
  "parent": null
}, {
  "depth": 1,
  "id": "s%C3%A9curit%C3%A9-et-confidentialit%C3%A9",
  "label": "Sécurité et confidentialité",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 2,
  "id": "exemple-de-commentaire",
  "label": "Exemple de commentaire",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 1,
  "id": "fiabilit%C3%A9-et-performance",
  "label": "Fiabilité et performance",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 2,
  "id": "exemples-de-retours",
  "label": "Exemples de retours",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 1,
  "id": "facilit%C3%A9-d-utilisation-et-accessibilit%C3%A9-%7C-application",
  "label": "Facilité d'utilisation et accessibilité | Application",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 2,
  "id": "exemple-de-commentaire",
  "label": "Exemple de commentaire",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 1,
  "id": "facilit%C3%A9-d-utilisation-et-accessibilit%C3%A9-%7C-listing-de-marketplace-des-applications",
  "label": "Facilité d'utilisation et accessibilité | Listing de marketplace des applications",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 2,
  "id": "exemples-de-retours",
  "label": "Exemples de retours",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 1,
  "id": "facilit%C3%A9-d-utilisation-et-accessibilit%C3%A9-%7C-documentation-de-support",
  "label": "Facilité d'utilisation et accessibilité | Documentation de support",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 2,
  "id": "exemple-de-commentaire",
  "label": "Exemple de commentaire",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 1,
  "id": "valeur",
  "label": "Valeur",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 2,
  "id": "exemple-de-commentaire",
  "label": "Exemple de commentaire",
  "parent": "crit%C3%A8res-d-%C3%A9valuation"
}, {
  "depth": 0,
  "id": "processus-d-%C3%A9valuation-de-certification-et-de-recertification-d-application",
  "label": "Processus d'évaluation de certification et de recertification d'application",
  "parent": null
}, {
  "depth": 0,
  "id": "foire-aux-questions",
  "label": "Foire aux questions",
  "parent": null
}, {
  "depth": 0,
  "id": "ressources-associ%C3%A9es",
  "label": "Ressources associées",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      h3: "h3",
      code: "code",
      h4: "h4",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Quote,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!Quote) _missingMdxReference("Quote", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Obtenir une certification dans le marketplace des applications"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La certification d'application implique que l'équipe qualité de l'écosystème HubSpot évalue et confirme que votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "application listée"
      }), " répond aux exigences ci-dessous en matière de sécurité, de confidentialité, de fiabilité, de performances, de facilité d'utilisation, d'accessibilité et de valeur ajoutée."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les applications certifiées se distinguent sur le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "marketplace des applications"
      }), " par leur réputation en termes de qualité et de fiabilité. Votre application bénéficiera également d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#certification-benefits",
        children: "avantages spéciaux"
      }), " et recevra des commentaires constructifs de l'équipe qualité de l'écosystème HubSpot lors de l'évaluation de la certification de l'application."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Ces exigences sont sujettes à modification, car HubSpot apporte continuellement des améliorations au marketplace des applications et à l'écosystème HubSpot. HubSpot peut rejeter une demande de certification d'application à sa discrétion si elle ne répond pas aux normes établies."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpot n'étudiera ", (0, _jsxRuntime.jsx)("u", {
            children: "pas"
          }), " votre application, sauf si vous soumettez une vidéo de démonstration, comme indiqué lors de la ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/applying-for-app-certification",
            children: "demande de certification de l'application."
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Vous ne pouvez envoyer qu'une seule application à la fois pour la certification. Si vous soumettez plusieurs applications pour certification en même temps, elles seront rejetées en fonction de l'ordre de soumission."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Présentation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez ci-dessous une présentation des exigences en matière de certification des applications. Pour plus de détails, consultez les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#review-criteria",
        children: "critères d'évaluation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Votre application doit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "être associée à un seul ID d'application HubSpot ;"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Votre application publique listée doit être unique. Si vous avez déjà publié une application et que vous souhaitez la remplacer, vous devez mettre à jour l'application existante au lieu d'en publier une nouvelle."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ne créez pas plusieurs applications qui répondent au même cas d'utilisation. Les applications ayant des fonctionnalités similaires et utilisant les mêmes API doivent être consolidées en une seule application."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["utiliser l'", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "authentification OAuth"
          }), " et tous les périmètres nécessaires ;"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["être associée à un ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "périmètre vérifié"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Les éléments publics associés à votre application doivent respecter les bonnes pratiques de sécurité."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#security-privacy",
        children: "liste détaillée des exigences en matière de sécurité et de confidentialité"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["L'activité de l'application est définie par les demandes authentifiées par OAuth aux ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "API"
        }), " de HubSpot et les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests",
          children: "demandes signées"
        }), " provenant des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "abonnements au webhook"
        }), " HubSpot et des extensions (par exemple, les demandes de récupération de données des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "cartes CRM"
        }), ")."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les installations actives sont le nombre de comptes de production uniques HubSpot, non affiliés à votre organisation, affichant une activité d'", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "application réussie"
        }), " au cours des 30 derniers jours."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Avantages de la certification"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Outre les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners/app",
        children: "avantages liés au listing de votre application"
      }), ", les applications certifiées bénéficient des avantages suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Badge « Application certifiée HubSpot » affiché sur son listing de marketplace des applications"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Visibilité supplémentaire dans le marketplace des applications de HubSpot :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Inclusion dans le filtre de recherche « Application certifiée HubSpot »"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Éligibilité pour l'inclusion dans des sélections du marketplace des applications"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Accès au badge « Application certifiée HubSpot » et aux images des réseaux sociaux pour partager l'obtention de la certification de l'application"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Prise en compte favorable dans les initiatives de partenariat et d'amplification de HubSpot"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Critères d'évaluation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour obtenir la certification, votre application doit démontrer sa qualité en répondant à des mesures quantitatives et à des descripteurs qualitatifs en matière de sécurité, de confidentialité, de fiabilité, de performances, de facilité d'utilisation, d'accessibilité et de valeur ajoutée. Les exigences ci-dessous sont organisées par ces catégories et comprennent des exemples de commentaires constructifs que vous pouvez recevoir."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sécurité et confidentialité"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Votre application doit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["être associée à un seul ID d'application HubSpot ; Votre application doit autoriser les demandes d'API avec l'ID d'application HubSpot public (et l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "ID de client OAuth"
        }), ") associé à votre listing d'application.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Un listing ne doit pas rediriger vers une autre application publique ou privée."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Votre application publique répertoriée ne doit pas nécessiter le fonctionnement d'une autre application publique ou privée."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["être authentifiée par le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "flux de code d'autorisation OAuth"
        }), " ;", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Il est interdit de demander aux utilisateurs de copier et de coller les codes ou les jetons OAuth. Les utilisateurs doivent uniquement être invités à autoriser l'accès."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les applications doivent demander, gérer et actualiser les jetons d'accès sans intervention de l'utilisateur."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilisez tous les paramètres ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "scope"
        }), " nécessaires à l'installation (c'est-à-dire le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " obligatoire et le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), ").", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "Activez les paramètres de domaine avancés"
            }), " et sélectionnez tous les domaines requis, conditionnellement requis et facultatifs que l'application demande d'installer."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les périmètres étrangers doivent être supprimés."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si certains domaines ne s'appliquent qu'à un sous-ensemble de la base d'utilisateurs de votre application, ils doivent être inclus en tant que domaines conditionnellement requis ou facultatifs."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["être associée à un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "périmètre vérifié"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vos éléments publics seront évalués en fonction des bonnes pratiques en matière de sécurité liées à des logiciels obsolètes et à diverses vulnérabilités et constatations des serveurs web."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemple de commentaire"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app currently requires four scopes: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contacts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "timeline"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ". According to our logs, however, it only made requests to the CRM Contacts and Timeline Events APIs in the last 30 days. Since the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), " scopes are not required for either of these functions, please remove them as required from the app’s settings to minimize the permissions users must accept."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fiabilité et performance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Votre application doit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "avoir une bonne réputation ;"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Votre application doit répondre à toutes les dispositions en vigueur."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Elle doit n'avoir eu aucun rejet de certification au cours des six derniers mois."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Elle doit n'avoir eu aucune remontée de support non résolue avec des clients mutuels."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "utiliser des versions publiques stables des API et extensions de HubSpot ;"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Il est recommandé d'utiliser les versions publiques les plus récentes."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les API de prévisualisation non documentées, bêta et de développeur sont considérées comme instables et ne doivent pas être utilisées dans votre application de production."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Remarque : Si votre application utilise des API qui ne sont pas stables ou publiques, veuillez le signaler lors de l'examen de la certification ou à l'équipe responsable des partenaires d'application pour aborder les différentes solutions."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["maintenir un volume d'", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "activité"
          }), " raisonnable de la part des comptes clients HubSpot non affiliés à votre entreprise ;"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["respecter les ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/api-usage/usage-details",
            children: "directives d'utilisation de l'API"
          }), " et les bonnes pratiques, notamment :"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "le respect des limites (c.-à-d. 100 demandes entrantes toutes les 10 secondes par compte HubSpot connecté) ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "l'actualisation des jetons d'accès OAuth avant leur expiration ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "la mise en cache des données pour les appels récurrents, le cas échéant ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "l'utilisation d'API de lot et des abonnements au webhook pour réduire le volume de demande, le cas échéant ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "l'utilisation d'API pour créer des propriétés, des workflows et des actions de workflow personnalisées au lieu d'exiger une action de la part de l'utilisateur."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["maintenir un taux de réussite moyen supérieur à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "95 %"
          }), " pour toutes les ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "activités"
          }), " ;"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les demandes débouchant sur des réponses d'erreur sont comptabilisées dans ce taux de réussite."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Certaines erreurs inévitables ou attendues peuvent être exclues lors du calcul des taux de réussite pour l'ensemble des ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/marketplace/certification-requirements#activity",
              children: "activités"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Votre application peut avoir une extension de navigateur pour offrir des fonctionnalités et une valeur supplémentaires aux clients :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les extensions de navigateur ne doivent pas être créées spécifiquement pour l'interface utilisateur de HubSpot ou comme solution de contournement des API de HubSpot."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Les extensions du navigateur ne doivent pas proposer de capacités ou de composants dans l'interface utilisateur de HubSpot.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Les extensions d'interface utilisateur officiellement prises en charge (par exemple, les ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "cartes CRM"
                }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/automation/custom-workflow-actions",
                  children: "les actions de workflow personnalisées"
                }), ") fournissent des expériences utilisateur plus cohérentes pour les clients."]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Votre application fera l'objet d'une évaluation de sécurité supplémentaire si elle inclut une extension de navigateur."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemples de retours"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s API success rate falls below the 95% threshold required for certification. Our logs show a 83% success rate in the last 30 days. The vast majority of these requests returned ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "429"
        }), " burst rate limit errors. To reduce this error rate, we recommend throttling requests to 100 requests per 10 seconds for each account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating errors around trying to update contacts using an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undefined"
        }), " email address, which will not work with this endpoint. Your app should skip these requests if a record does not have an email address."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is making requests with expired OAuth tokens and receiving ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors before refreshing the token. To minimize these errors, we recommend that your app keep track of when tokens expire or refresh tokens before making requests. If you start seeing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors for 100% of requests and are unable to refresh the access token, consider the app uninstalled and stop making requests for the account until a user re-authenticates your app."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors from trying to use the Contact Lists API with Marketing Hub Free accounts, which do not have access to contact lists. If your app repeatedly gets ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors for missing the proper scopes, it should stop making calls to retrieve lists from that account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s webhook subscriptions frequently fail with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "500"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "503"
        }), " errors. Make sure that your server can handle the volume of requests (currently limited to 150 per second) so that customer data is not lost."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is pulling many contacts one at a time instead of pulling them in batches. We recommend using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "batch endpoints"
        }), " instead."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Facilité d'utilisation et accessibilité | Application"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Votre application doit être ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "listée"
        }), " dans le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps",
          children: "marketplace des applications"
        }), " HubSpot pendant au moins six mois."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Votre application doit présenter ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/ten-usability-heuristics/",
          children: "des bonnes pratiques en termes de facilité d'utilisation"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "L'installation et l'utilisation ne doivent pas générer de confusion ou de frustration pour les clients de HubSpot, ni impacter négativement l'expérience utilisateur principale de HubSpot."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemple de commentaire"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app currently requires users to manually configure workflow webhook actions to send text messages. Consider creating custom workflow actions via the app which are flexible enough to accommodate many use cases."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Facilité d'utilisation et accessibilité | Listing de marketplace des applications"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Votre listing de marketplace des applications doit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["décrire avec précision les fonctionnalités actuelles de votre application ;", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si la fonctionnalité change selon le produit ou le niveau d'abonnement d'un utilisateur, que ce soit pour HubSpot ou votre solution, les différences doivent être clairement définies."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Votre listing doit contenir des informations claires et précises sur les tarifs, y compris un lien vers votre page tarifaire actuelle.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Si plusieurs forfaits prennent en charge votre application, le listing de marketplace des applications doit au moins inclure l'option la moins coûteuse."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "utiliser des données de substitution ou masquer des données pour ne pas afficher d'informations personnelles identifiables (PII) ;"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["inclure :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["des aides visuelles informatives et actualisées, qui peuvent inclure des captures d'écran ou une vidéo ; Reportez-vous à la page ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
              children: "Comment créer une excellente vidéo de démonstration d'application"
            }), " pour connaître les bonnes pratiques ainsi que des exemples de création d'une vidéo de démonstration."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "une URL vers de la documentation de configuration à jour qui contient un sous-domaine et mène directement à la page hébergeant la documentation pour votre application. Ce lien ne doit pas conduire à votre page d'accueil."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["exclure :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "toute donnée ou statistique, à moins qu'une étude de cas ne soit fournie en tant que ressource."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemples de retours"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your App Marketplace listing includes few specific details about your app’s functionality. Please enhance the listing with screenshots which depict app functionality and include more thorough descriptions of common use cases and in-app behavior."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot customers are used to a “try before you buy” experience when purchasing our products and services. For this reason, we recommend your app provide a free trial or freemium sign-up experience. Some app partners who do not have pricing pages or free trials have created “HubSpot plans,” offering mutual customers transparent pricing, touchless sign-up, and other benefits."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Facilité d'utilisation et accessibilité | Documentation de support"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La documentation de support pour votre application doit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "figurer sur une URL en ligne publique (c'est-à-dire sans pare-feu ou connexion requise) et respecter les normes actuelles en matière d'accessibilité, de confidentialité et de RGPD ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "être à jour et cohérente avec la version actuelle de votre application ;"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["décrire clairement :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "l'objet de l'application ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "comment installer votre application et connecter un compte HubSpot avec des captures d'écran de chaque étape, y compris l'écran d'approbation des périmètres d'accès ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "comment configurer l'application une fois installée ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "comment utiliser l'application, y compris les interactions manuelles et automatisées ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "comment déconnecter HubSpot de votre application ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "comment désinstaller votre application d'un compte HubSpot ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "l'impact de la déconnexion et de la désinstallation sur les comptes et données HubSpot des utilisateurs."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["inclure des images. Les images contenant des captures d'écran de l'interface utilisateur HubSpot doivent être mises à jour et cohérentes avec notre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://canvas.hubspot.com/",
          children: "système de conception"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les vidéos sont également recommandées, mais non obligatoires. Les vidéos doivent être mises à jour régulièrement et refléter la version actuelle de votre application."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemple de commentaire"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The setup guide for your app includes a screenshot depicting the scopes your app requires for installation. This screenshot does not show the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "business-intelligence"
        }), " scope, which is selected in your app’s settings. Please update the screenshot so that it reflects the current required scopes."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Valeur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Le nombre d'installations actives de votre application, sa conservation et les avis du marketplace des applications de HubSpot sont évalués comme des indicateurs de la valeur ajoutée de votre application pour les clients mutuels."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Votre application doit avoir au moins 60 installations ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#activity",
              children: "actives"
            }), " uniques pour prétendre à la certification. Les comptes avec des installations doivent être non affiliés à votre organisation. Les comptes de test seront également exclus.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Si votre application compte moins de 60 installations actives, il vous sera demandé d'annuler la demande de certification."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Si votre application compte moins que les trois installations actives requises, votre application peut être supprimée du marketplace des applications."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Votre listing d'application doit recevoir des réponses de votre équipe pour tout commentaire négatif sur votre application."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemple de commentaire"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app has not maintained at least 60 active installs over the trailing six month period. As such, its certified status will be removed. You may re-apply for certification in six months."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Processus d'évaluation de certification et de recertification d'application"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'équipe qualité de l'écosystème HubSpot répond aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "demandes de certification d'application"
      }), " dans un délai de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "10 jours ouvrables"
      }), ". L'ensemble du processus d'examen et de feedback sur l'application ne devrait pas prendre plus de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "60 jours"
      }), " à partir du moment où le feedback est partagé. Passez en revue les critères énumérés ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/testing-credentials",
        children: "ici"
      }), " pour fournir des informations d'identification de test pour votre application."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si votre application répond à toutes les exigences, elle obtiendra la certification et un badge « Application certifiée HubSpot » sera affiché aux clients et prospects sur le marketplace des applications. Votre application apparaîtra également lorsque les utilisateurs utiliseront le filtre « Application certifiée HubSpot »."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si votre application ne réussit pas l'évaluation, vous pourrez refaire une demande dans six mois."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Après un an de certification, l'équipe qualité de l'écosystème HubSpot peut examiner votre application pour s'assurer qu'elle répond toujours aux exigences de certification rigoureuses. Si votre application ne répond plus à ces normes, l'équipe collaborera avec vous pendant soixante jours maximum pour résoudre les problèmes."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si votre application n'est pas conforme aux exigences de certification indiquées ci-dessus à tout moment, HubSpot peut lancer immédiatement un processus de recertification, même si votre application est certifiée depuis moins d'un an. Comme indiqué dans le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/app-program-agreement",
          children: "contrat relatif au Programme Partenaires d'applications"
        }), ", nous nous réservons également le droit d'annuler la publication de votre application à tout moment."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Foire aux questions"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Are there any costs associated with app certification?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "No, we do not charge you a fee to list or certify your apps in the App Marketplace, nor a fee for installs generated through the App Marketplace. There is no revenue sharing. We are here to support you to make your app of higher quality."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Will I be notified when my app will be eligible again for certification if it was previously rejected?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No. At this time we do not have notifications enabled to notify you if and when you will be eligible to re-apply at this time. Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best resource to contact and ask if you are eligible before applying."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How should I promote my certified app to my customers?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Feel free to use the press release template on ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/resources",
          children: "this page"
        }), " to share the news that your app has earned certification."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "If you plan to post on social media, be sure to tag HubSpot — we love to celebrate alongside our app partners!"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I enjoy experimenting with the newest beta APIs. Is app certification right for me?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We recommend you reach out to your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " to see if app certification is right for your app."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Our goal is to ensure your app is well built for our mutual customers and limits breaking changes, which requires your app uses the latest stable APIs. We also love seeing and supporting entrepreneurs, early adopters, and developers who are eager to experiment with the newest beta APIs."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I'd like my app to be featured on the App Marketplace. Is this what app certification is for?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The benefits of being featured in collections and for customers to easily filter for a certified app within the App Marketplace are continuing to evolve. We’d like to learn more about how you would find being featured the most helpful (e.g. App Marketplace, HubSpot community, HubSpot curated newsletters or other forms)."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best contact to discuss potential future benefits and start this conversation."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Why and how will my public assets be assessed for security best practices?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "With the average customer using more than five integrations, it’s imperative apps are monitored and held to privacy, security, and quality standards over time. Any public assets will be assessed using information already provided during a listing process and findings will be analyzed using a non-invasive method."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "My app was certified over a year ago. What do I have to do to keep my certification status and what does it mean to get recertified?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The HubSpot Ecosystem Quality team will reach out if your app is out of compliance or due for annual recertification with next steps."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We encourage you to monitor your app’s performance, certification requirements, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "Developer Changelog"
        }), ", and any additional HubSpot resources related to any changes in technology used and how your app could stay up to date."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Trouvez votre ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "gestionnaire de partenaires d'applications"
        }), " et ses coordonnées en vous connectant à votre compte de développeur et en accédant à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace des applications"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), ". Passez le curseur sur votre application et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Plus"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Afficher les détails du listing"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ressources associées"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "Comment faire une demande de certification d'application"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "Comment lister votre application"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Conditions relatives aux listings d'applications"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
        children: "Forum de la communauté des développeurs"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/contact-our-partnerships-team",
        children: "Contacter l'équipe Partenaire d'application"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}