"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51199955888;
const slug = exports.slug = 'getting-started/account-types';
const title = exports.title = 'HubSpotアカウントのタイプ｜HubSpot（ハブスポット）';
const name = exports.name = 'HubSpotアカウントのタイプ｜HubSpot（ハブスポット）';
const metaDescription = exports.metaDescription = 'さまざまなHubSpotアカウントのタイプと各アカウントタイプの用途について説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%A8%99%E6%BA%96%E3%81%AEhubspot%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88",
  "label": "標準のHubSpotアカウント",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E9%96%8B%E7%99%BA%E8%80%85%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88",
  "label": "アプリ開発者アカウント",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%8B%E7%99%BA%E8%80%85%E3%83%86%E3%82%B9%E3%83%88%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88",
  "label": "開発者テストアカウント",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%96%8B%E7%99%BA%E8%80%85%E3%83%86%E3%82%B9%E3%83%88%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90",
  "label": "開発者テストアカウントを作成",
  "parent": "%E9%96%8B%E7%99%BA%E8%80%85%E3%83%86%E3%82%B9%E3%83%88%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88"
}, {
  "depth": 1,
  "id": "%E9%96%8B%E7%99%BA%E8%80%85%E3%83%86%E3%82%B9%E3%83%88%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "開発者テストアカウントを更新する",
  "parent": "%E9%96%8B%E7%99%BA%E8%80%85%E3%83%86%E3%82%B9%E3%83%88%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88"
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%83%B3%E3%83%89%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88",
  "label": "サンドボックスアカウント",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%A8%99%E6%BA%96%E3%81%AE%E3%82%B5%E3%83%B3%E3%83%89%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88",
  "label": "標準のサンドボックスアカウント",
  "parent": "%E3%82%B5%E3%83%B3%E3%83%89%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88"
}, {
  "depth": 1,
  "id": "cms%E3%82%B5%E3%83%B3%E3%83%89%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88",
  "label": "CMSサンドボックスアカウント",
  "parent": "%E3%82%B5%E3%83%B3%E3%83%89%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88"
}, {
  "depth": 1,
  "id": "%E9%96%8B%E7%99%BA%E7%94%A8%E3%82%B5%E3%83%B3%E3%83%89%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%EF%BC%88%E3%83%99%E3%83%BC%E3%82%BF%E7%89%88%EF%BC%89",
  "label": "開発用サンドボックスアカウント（ベータ版）",
  "parent": "%E3%82%B5%E3%83%B3%E3%83%89%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88"
}, {
  "depth": 0,
  "id": "%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9-%E3%83%97%E3%83%AD%E3%83%90%E3%82%A4%E3%83%80%E3%83%BC-%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88",
  "label": "マーケットプレイス プロバイダー アカウント",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      img: "img",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotアカウントのタイプ"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotアカウントにはいくつかの種類があり、それぞれ目的が異なります。この記事では、各アカウントタイプとその目的について説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "標準のHubSpotアカウント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "標準のHubSpotアカウントは最も一般的なタイプのアカウントです。このアカウントタイプには、ご契約いただいているHubSpotのエディションに含まれている全てのツール、機能、および設定が揃っています。無料または有料で利用でき、本番環境として使用されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "標準のHubSpotアカウントでは、エディションに含まれる全てのツールと機能にアクセスできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリ開発者アカウント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリ開発者アカウントは、アプリ、連携、開発者テストアカウントを作成、管理することを目的とした無料のアカウントです。また、このアカウントでアプリマーケットプレイスの掲載情報を作成、管理することもできます。ただし、アプリ開発者アカウントとその関連するテストアカウントは、標準のHubSpotアカウントとはつながりがありません。他のHubSpotアカウントとの間でデータやアセットを同期することはできません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリ開発者アカウントは、全てのページ上部に表示される「これはアプリ開発者アカウントです」というバナーによって識別できます。", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://paper-attachments.dropbox.com/s_FE997E185BD47B083EA9B7C65FEC2822D18406AA9C2CBDB7FA3CA77217D16F19_1626814303990_App+dev+account+info+alert+option+3.png",
        alt: ""
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "アプリ開発者アカウント"
      }), "の作成から始めてみましょう。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "開発者テストアカウント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-developer-accounts",
        children: "アプリ開発者アカウント"
      }), "内で、開発者がHubSpotの実データに影響を与えることなしにアプリと連携をテストするための、テストアカウントを作成できます。開発者テストアカウントは本番アカウントを反映するものではなく、多くのエンタープライズ機能を90日間試用的に利用可能な、無料のHubSpotアカウントです。ただし、次の制限があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub："
        }), " マーケティングEメールの送信先にできるのは、開発者テストアカウントに追加したユーザーのアドレスのみです。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**CMS Hub：**作成できるページ数には次の制限があります。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**ウェブサイトページ：**25件"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**ブログツール：**1件（最大100件のブログ記事の投稿が可能）"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**ランディングページ：**25件"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**ワークフロー：**開発者テストアカウントで作成されたワークフローに登録できるレコード数は、1日あたり最大100,000件です。1日あたりの上限に達した場合は次のようになります。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "この上限を超えて登録しようとしても登録できません。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1日のレコード登録上限に達したことと、上限が更新される時間がアプリ内でユーザーに通知されます。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者アカウント1件にあたり、テストアカウントを10件まで作成できます。テストアカウントでは、他のアカウントとデータを同期することはできません。また、標準のHubSpotアカウントに接続することもできません。有効期限が切れるまでの残りの日数がページ上部のバナーに表示されることにより、利用中のアカウントがテストアカウントであることを確認できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/trial-banner.png",
        alt: "trial-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下にて、テストアカウントを作成および管理する方法をご説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "開発者テストアカウントを作成"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者テストアカウントは、次の手順に従って作成します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "アプリ開発者アカウントの左にあるナビゲーションサイドバーで、［テストアカウント］をクリックします。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/left-sidebar-test-accounts.png",
            alt: "left-sidebar-test-accounts"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "右上の［アプリのテストアカウントを作成］をクリックします。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-test-account-create.png",
            alt: "developer-test-account-create"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "アカウント名"
          }), "を入力し、［作成］をクリックします。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の手順に従って、開発者テストアカウントへのアクセスと管理を行います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ開発者アカウントの上部にあるナビゲーションバーで、［テスト］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["管理する", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "アカウント"
        }), "にカーソルを合わせ、次のいずれかの操作を選択します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アカウントを削除する場合は、［削除］をクリックします。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アカウントIDのコピー、アカウントの更新、またはアカウントの名前変更を行う場合は、［その他］ドロップダウンメニューをクリックします。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-account-test-accounts.png",
        alt: "developer-account-test-accounts"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "開発者テストアカウントを更新する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者テストアカウントに対してAPI呼び出しが行われない場合、90日後にアカウントが期限切れになります。この場合、HubSpotの［テスト］ページでアカウントを手動で更新するか、アカウントに対してAPI呼び出しを行って更新できます。API呼び出しでアカウントの更新を試みる際は、次の点にご注意ください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["この方法で更新できるのは、更新するテストアカウントと同じ開発者アカウントのアプリから生成された", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "OAuthトークン"
        }), "を使用してAPIリクエストを行った場合のみです。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "更新は、テストアカウントの有効期限末日から30日以内に行う必要があります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サンドボックスアカウント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サンドボックスアカウントを使用すると、標準のアカウントに影響を与えることなく変更をテストできます。以降のセクションで、さまざまな種類のサンドボックスアカウントについて詳しく説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "標準のサンドボックスアカウント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "「Enterprise」サブスクリプションをご利用の場合、標準のアカウント内でサンドボックスアカウントを作成できます。サンドボックスとは、本番アカウントに影響を与えることなく、新しいワークフロー、連携、ウェブサイトページをテストできる安全で保護された環境です。サンドボックスアカウントには、標準アカウントの構造がコピーされます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらのアカウントは、ページの上部に表示される、「現在使用している[サンドボックス名]は標準サンドボックスアカウントです」というラベルを示す黄色のバナーで特定できます。バナーには本番アカウントへのリンクも含まれており、本番アカウントに簡単に戻れるようになっています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/standard-sandbox-banner.png",
        alt: "standard-sandbox-banner"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["標準のサンドボックスアカウントについて詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/set-up-a-hubspot-standard-sandbox-account",
        children: "HubSpotのナレッジベース"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CMSサンドボックスアカウント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMSサンドボックスアカウントは、標準のアカウントや公開中のウェブサイトに影響を与えることなく、ウェブサイトに変更を加えてテストする目的で使用できる無料のアカウントです。アプリ開発者アカウントと同様に、CMSサンドボックスアカウントは標準HubSpotアカウントに", (0, _jsxRuntime.jsx)("u", {
        children: "接続されません"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["こちらの英語ページから、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "CMSサンドボックスアカウントを無料でご作成"
      }), "いただけます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["バナーは表示されませんが、CMSサンドボックスでアクセスできるのはHubSpotの無料ツールと", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "（ドメインを接続する機能はありません）のみです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "開発用サンドボックスアカウント（ベータ版）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Sales Hub"
      }), "または", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Service Hub"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " 配信登録をご利用の場合は、CLIを使用したローカル開発を目的とした開発用サンドボックスアカウントを作成できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/opt-your-hubspot-account-into-a-beta-feature",
        children: "標準のHubSpotアカウントでCRM開発者ツールのベータ版にオプトイン"
      }), "することで、開発用サンドボックスアカウントにアクセスできるようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発用サンドボックスは、ページの上部に表示される、「現在使用している[サンドボックス名]は開発用サンドボックスアカウントです」というラベルを示す黄色のバナーで特定できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/development-sandboxes-banner.png",
        alt: "development-sandboxes-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "マーケットプレイス プロバイダー アカウント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["マーケットプレイス プロバイダー アカウントは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "テンプレートマーケットプレイスでの掲載"
      }), "と取引のために使用するアカウントです。テンプレートマーケットプレイスでの販売を開始するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "テンプレート マーケットプレイス プロバイダー アカウントを作成"
      }), "します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners",
        children: "HubSpotパートナー"
      }), "の場合、パートナーアカウントがマーケットプレイス プロバイダー アカウントとして機能します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "マーケットプレイス プロバイダー アカウントは、上部ナビゲーションメニューに含まれる［テンプレートマーケットプレイス］によって識別できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/template-marketplace-acct.png",
        alt: "template-marketplace-acct"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}