"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937480;
const slug = exports.slug = 'guides/api/crm/owners';
const title = exports.title = 'API de CRM | Propietarios';
const name = exports.name = 'vNext DP - Propietarios';
const metaDescription = exports.metaDescription = 'HubSpot usa propietarios para asignar objetos de CRM a personas específicas. Estos puntos de terminación se utilizan para obtener una lista de propietarios disponibles para una cuenta. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "recuperar-una-lista-de-propietarios",
  "label": "Recuperar una lista de propietarios",
  "parent": null
}, {
  "depth": 0,
  "id": "recuperar-informaci%C3%B3n-sobre-un-propietario-individual",
  "label": "Recuperar información sobre un propietario individual",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Propietarios"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los propietarios de HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: "asignan"
      }), " usuarios específicos a registros, actividades o tareas de marketing, y se pueden usar en tokens de personalización para tu contenido. Los propietarios se crean y actualizan automáticamente en HubSpot cuando se agregan nuevos usuarios o los propietarios existentes se sincronizan desde ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/salesforce/how-will-a-salesforce-owner-will-be-recognized-by-hubspot",
        children: "Salesforce"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los puntos finales de la API de propietarios son de solo lectura, por lo que puedes usarlos para recuperar los detalles de identificación de un propietario, incluido el ID de propietario. Este identificador puede usarse para asignar propiedad a registros de CRM en HubSpot, mediante una integración o mediante llamadas de API de cambio de propiedad."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar una lista de propietarios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar los propietarios de tu cuenta, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners"
      }), ". La respuesta devolverá el nombre, el correo electrónico, los valores de identificación, las fechas de creación/actualización y, si corresponde, la información del equipo de cada usuario. Se devuelven dos valores de ID, que se utilizan para diferentes propósitos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": el ID del propietario. Este valor debe usarse al recuperar información sobre un propietario específico y al asignar un propietario a un registro o actividad."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), ": el ID del usuario Este valor se puede usar para especificar usuarios en la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "API de configuración"
        }), ", pero se producirá un error si se usa para asignar la propiedad."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tu respuesta será similar a la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners\n{\n  \"results\": [\n    {\n      \"id\": \"41629779\",\n      \"email\": \"email@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"HubSpot\",\n      \"lastName\": \"Test Owner\",\n      \"userId\": 9586504,\n      \"userIdIncludingInactive\": 9586504,\n      \"createdAt\": \"2019-12-25T13:01:35.228Z\",\n      \"updatedAt\": \"2023-08-22T13:40:26.790Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"60158084\",\n      \"email\": \"email@gmail.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Test\",\n      \"lastName\": \"Email\",\n      \"userId\": 9274996,\n      \"userIdIncludingInactive\": 9274996,\n      \"createdAt\": \"2021-02-10T17:59:04.891Z\",\n      \"updatedAt\": \"2023-02-09T17:41:52.767Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"81538190\",\n      \"email\": \"salesmanager@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Sales\",\n      \"lastName\": \"Manager Example\",\n      \"userId\": 3892666,\n      \"userIdIncludingInactive\": 3892666,\n      \"createdAt\": \"2021-05-27T16:55:57.242Z\",\n      \"updatedAt\": \"2022-08-02T18:34:35.039Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes recuperar propietarios archivados para ver los usuarios que fueron desactivados. Para ello, añade el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "archived"
      }), " con el valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Para los usuarios archivados, todavía hay un valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", pero el valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userId"
      }), " será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), ". En su lugar, el ID de usuario se almacena en el ampo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userIdIncludingInactive"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners/?archived=true\n{\n  \"results\": [\n    {\n      \"id\": \"42103462\",\n      \"email\": \"useremail@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"\",\n      \"lastName\": \"\",\n      \"userId\": null,\n      \"userIdIncludingInactive\": 9685555,\n      \"createdAt\": \"2020-01-09T20:28:50.080Z\",\n      \"updatedAt\": \"2020-01-09T20:28:50.080Z\",\n      \"archived\": true\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar información sobre un propietario individual"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar un propietario específico, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners/{ownerId}"
      }), ". Debes usar el valor de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " para especificar el propietario para el que deseas más detalles."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": el valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), " en la respuesta cambia según las actualizaciones del objeto del propietario en sí. No se actualizará para cambios en el objeto del usuario. Por ejemplo, cambiar los permisos de un usuario ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " actualizará el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), "."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}