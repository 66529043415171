"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 59605144696;
const slug = exports.slug = 'guides/api/crm/engagements/notes';
const title = exports.title = 'Interaktionen | Notizen';
const name = exports.name = 'Interaktionen | Notizen';
const metaDescription = exports.metaDescription = 'In Kontaktdatensätzen werden Informationen zu Einzelpersonen gespeichert. Die Kontakte-Endpunkte ermöglichen es Ihnen, diese Daten zu verwalten und zwischen HubSpot und anderen Systemen zu synchronisieren.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eine-notiz-erstellen",
  "label": "Eine Notiz erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "eigenschaften",
  "label": "Eigenschaften",
  "parent": "eine-notiz-erstellen"
}, {
  "depth": 1,
  "id": "zuordnungen",
  "label": "Zuordnungen",
  "parent": "eine-notiz-erstellen"
}, {
  "depth": 0,
  "id": "notizen-abrufen",
  "label": "Notizen abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "notizen-aktualisieren",
  "label": "Notizen aktualisieren",
  "parent": null
}, {
  "depth": 1,
  "id": "vorhandene-notizen-zu-datens%C3%A4tzen-zuordnen",
  "label": "Vorhandene Notizen zu Datensätzen zuordnen",
  "parent": "notizen-aktualisieren"
}, {
  "depth": 1,
  "id": "eine-zuordnung-entfernen",
  "label": "Eine Zuordnung entfernen",
  "parent": "notizen-aktualisieren"
}, {
  "depth": 0,
  "id": "eine-notiz-in-einem-datensatz-anpinnen",
  "label": "Eine Notiz in einem Datensatz anpinnen",
  "parent": null
}, {
  "depth": 0,
  "id": "notizen-l%C3%B6schen",
  "label": "Notizen löschen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      h2: "h2",
      code: "code",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Notizen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Notizen in CRM-Datensätzen protokollieren, um Informationen zur Datensatzchronik hinzuzufügen oder einen Anhang einem Datensatz zuzuordnen. Wenn Sie z. B. eine Offline-Konversation nachverfolgen müssen, die Sie mit einem Kontakt geführt haben, können Sie eine Notiz zu dessen Kontaktdatensatz hinzufügen, die Details und Dokumente zu dieser Konversation enthält. Andere Benutzer im Account können diese Notiz dann anzeigen und darauf verweisen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Notizen entweder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "in HubSpot"
      }), " oder über die Notizen-API verwalten. Im Folgenden erfahren Sie, wie Sie Notizen über die API verwalten können. Auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpunkte"
      }), " oben in diesem Artikel können Sie alle verfügbaren Endpunkte überprüfen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Notiz erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Notiz zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie im Anfragetext Notizdetails in einem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), "-Objekt hinzu. Sie können auch ein ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associations"
      }), "-Objekt hinzufügen, um Ihre neue Notiz einem vorhandenen Datensatz (z. B. Kontakten, Unternehmen) zuzuordnen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im properties-Objekt können Sie die folgenden Felder einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erforderlich. Diese Feld markiert den Zeitpunkt der Erstellung der Notiz und bestimmt, wo sich die Notiz in der Datensatzchronik befindet. Sie können entweder einen Unix-Zeitstempel im Millisekunden- oder UTC-Format verwenden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_note_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Textinhalt der Notiz, begrenzt auf 65.536 Zeichen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID des zuständigen Mitarbeiters"
            }), ", die der zugeordnet ist. Diese Feld bestimmt den Benutzer, der in der Datensatzchronik in HubSpot als Notizersteller aufgeführt ist."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die IDs der Anhänge der Notiz. Mehrere Anhang-IDs sind durch ein Semikolon getrennt."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um eine Notiz zu erstellen und bestehenden Datensätzen zuzuordnen, schließen Sie ein associations-Objekt in Ihre Anfrage ein. Um beispielsweise eine Notiz zu erstellen und sie einem Unternehmen und einem Deal zuzuordnen, könnte Ihr Anfragetext wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubspot.com/crm/v3/objects/notes\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-11-12T15:48:22Z\",\n    \"hs_note_body\": \"Spoke with decision maker Carla. Attached the proposal and draft of contract.\",\n    \"hubspot_owner_id\": \"14240720\",\n    \"hs_attachment_ids\": \"24332474034;24332474044\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 190\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 401\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 214\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im associations-Objekt sollten Sie Folgendes einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Datensatz, den Sie der Notiz zuordnen möchten, angegeben durch seinen eindeutigen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "-Wert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ der Zuordnung zwischen Notiz und Datensatz. Beziehen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), " ein. Standardzuordnungstyp-IDs sind ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "hier"
            }), " aufgeführt. Sie können den Wert für benutzerdefinierte Zuordnungstypen (d. h. Label) über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpunkte"
      }), " oben in diesem Artikel mehr über das batchweise Erstellen von Notizen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Notizen abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Notizen einzeln oder mehrere gleichzeitig (batchweise) abrufen. Um eine individuelle Notiz abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Liste aller Notizen anzufordern, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können für beide Endpunkte die folgenden Abfrageparameter in die Anfrage-URL einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn die angefragte Notiz keinen Wert für eine Eigenschaft hat, wird sie nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine durch Kommas getrennte Liste von Objekttypen, für die zugehörige IDs abgerufen werden sollen. Alle angegebenen Zuordnungen, die nicht vorhanden sind, werden nicht in der Antwort zurückgegeben. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "Zuordnungen-API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise Notizen mit ihrem Textinhalt und allen zugehörigen Kontakt-IDs abzurufen, könnte Ihre Anfrage-URL wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/notes?limit=10&properties=hs_note_body&associations=contact&archived=false"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpunkte"
      }), " oben in diesem Artikel mehr über das Abrufen eines Batchs an Notizen nach interner ID oder eindeutigem Eigenschaftswert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Notizen aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Notizen einzeln oder mehrere gleichzeitig (batchweise) aktualisieren. Um eine einzelne Notiz anhand ihrer Notiz-ID zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fügen Sie im Anfragetext die Notizeigenschaften ein, die Sie aktualisieren möchten:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example PATCH request to https://api.hubspot.com/crm/v3/objects/notes/{noteID}\n{\n  \"properties\": {\n    \"hs_note_body\": \"Spoke with decision maker Carla.\",\n    \"hs_attachment_ids\": \"24332474034;24332474044\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignoriert Werte für schreibgeschützte und nicht vorhandene Eigenschaften. Um einen Eigenschaftswert zu löschen, übergeben Sie eine leere Zeichenfolge für die Eigenschaft im Anfragetext."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpunkte"
      }), " oben in diesem Artikel mehr über das batchweise Aktualisieren von Notizen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorhandene Notizen zu Datensätzen zuordnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Notiz anderen CRM-Datensätzen, z. B. einem Kontakt, zuzuordnen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), " durch. Die Anfrage-URL enthält die folgenden Felder:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "noteId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID der Notiz."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Typ des Objekts, dem Sie die Notiz zuordnen möchten (z. B. Kontakt oder Unternehmen)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Datensatzes, dem Sie die Notiz zuordnen möchten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine eindeutige ID, die den Zuordnungstyp zwischen der Notiz und dem anderen Objekt angibt. Die ID kann numerisch oder in Snake-Case (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "note_to_contact"
            }), ") dargestellt werden. Sie können den Wert über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre Anfrage-URL könnte beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/notes/17147287858/associations/contact/581751/202"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Zuordnung entfernen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die Zuordnung zwischen einer Notiz und einem Datensatz zu entfernen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an die gleiche URL wie oben durch:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Notiz in einem Datensatz anpinnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "eine Notiz in einem Datensatz anpinnen"
      }), ", sodass sie oben in der Chronik des Datensatzes bleibt. Die Notiz muss bereits vor dem Anpinnen dem Datensatz zugeordnet sein, und Sie können nur eine Aktivität pro Datensatz anpinnen. Um eine Notiz anzupinnen, berücksichtigen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " der Notiz im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "-Feld, wenn Sie einen Datensatz über die Objekt-APIs erstellen oder aktualisieren. Erfahren Sie mehr über das Verwenden der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "Unternehmen"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "Kontakte"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "Deals"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "Tickets"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "Benutzerdefinierte Objekte"
      }), "-APIs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Notizen löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Notizen einzeln oder mehrere gleichzeitig (batchweise) löschen, wodurch die Notiz dem Papierkorb in HubSpot hinzugefügt wird. Sie können später ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "die Notiz über die Datensatzchronik wiederherstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine einzelne Notiz anhand ihrer Notiz-ID zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das batchweise Löschen von Notizen, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpunkte"
      }), " klicken."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}