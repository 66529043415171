"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279879;
const slug = exports.slug = 'guides/cms/content/accessibility';
const title = exports.title = 'Accesibilidad';
const name = exports.name = 'LATAM (ES) Accessibility';
const metaDescription = exports.metaDescription = 'HubSpot se compromete a asegurarse de que hemos proporcionado las herramientas necesarias para ayudarte a que tu contenido sea accesible. Aunque no es una guía definitiva, aquí hay algunos pasos que puedes tomar para que tu contenido sea accesible.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "la-accesibilidad-no-es-una-caracter%C3%ADstica",
  "label": "La accesibilidad no es una característica",
  "parent": null
}, {
  "depth": 0,
  "id": "proporcionar-alternativas-de-texto-para-cualquier-contenido-que-no-sea-de-texto",
  "label": "Proporcionar alternativas de texto para cualquier contenido que no sea de texto",
  "parent": null
}, {
  "depth": 1,
  "id": "utiliza-soluciones-de-carga-diferida-compatibles-con-a11y",
  "label": "Utiliza soluciones de carga diferida compatibles con a11y",
  "parent": "proporcionar-alternativas-de-texto-para-cualquier-contenido-que-no-sea-de-texto"
}, {
  "depth": 0,
  "id": "aseg%C3%BArate-de-que-todos-los-enlaces-y-las-entradas-del-formulario-tengan-texto-descriptivo",
  "label": "Asegúrate de que todos los enlaces y las entradas del formulario tengan texto descriptivo",
  "parent": null
}, {
  "depth": 0,
  "id": "agregar-un-enlace-de-saltar-al-contenido",
  "label": "Agregar un enlace de saltar al contenido",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-marcado-sem%C3%A1ntico",
  "label": "Usar marcado semántico",
  "parent": null
}, {
  "depth": 0,
  "id": "navegaci%C3%B3n-por-teclado",
  "label": "Navegación por teclado",
  "parent": null
}, {
  "depth": 0,
  "id": "volver-a-los-roles-en-caso-necesario",
  "label": "Volver a los roles en caso necesario",
  "parent": null
}, {
  "depth": 0,
  "id": "consejos-de-dise%C3%B1o-adicionales",
  "label": "Consejos de diseño adicionales",
  "parent": null
}, {
  "depth": 0,
  "id": "redacci%C3%B3n-de-contenido",
  "label": "Redacción de contenido",
  "parent": null
}, {
  "depth": 0,
  "id": "recomendaciones-espec%C3%ADficas-sobre-hubspot",
  "label": "Recomendaciones específicas sobre HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "usa-m%C3%B3dulos-y-plantillas-personalizados-que-sigan-las-mejores-pr%C3%A1cticas-de-a11y",
  "label": "Usa módulos y plantillas personalizados que sigan las mejores prácticas de a11y",
  "parent": "recomendaciones-espec%C3%ADficas-sobre-hubspot"
}, {
  "depth": 0,
  "id": "cuidado-con-las-soluciones-integrales",
  "label": "Cuidado con las soluciones integrales",
  "parent": null
}, {
  "depth": 0,
  "id": "podcast-para-desarrolladores-de-hubspot",
  "label": "Podcast para desarrolladores de HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "m%C3%A1s-informaci%C3%B3n-sobre-accesibilidad",
  "label": "Más información sobre accesibilidad",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      code: "code",
      pre: "pre",
      h3: "h3",
      ul: "ul",
      li: "li",
      ol: "ol",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      IFrame
    } = _components;
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Accesibilidad"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A medida que los sitios web siguen siendo cada vez más importantes para las empresas, garantizar que el contenido en los sitios web sea accesible para todos los visitantes se ha vuelto más vital que nunca. En los Estados Unidos, esto a menudo se denomina cumplimiento de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www.section508.gov/",
        children: "sección 508"
      }), ", que se refiere a la sección de la Ley de Rehabilitación Reautorizada de 1998 que requiere que las agencias federales hagan que la tecnología electrónica y de la información sea accesible para las personas con discapacidades. Si bien el cumplimiento de la sección 508 es una buena base de referencia, cada vez más las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility/Understanding_WCAG",
        children: "Directrices de Accesibilidad para el Contenido Web (WCAG)"
      }), " se están utilizando como el estándar para crear contenido accesible en la web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "La accesibilidad no es una característica"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El aspecto legal no debería ser tu motivador para proporcionar una buena experiencia a tus usuarios. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.cdc.gov/ncbddd/disabilityandhealth/infographic-disability-impacts-all.html",
        children: "Uno de cada cuatro estadounidenses tiene una discapacidad según los CDC"
      }), ". Elegir no proporcionar una buena experiencia para el 25% de los visitantes es como una tienda física que niega la entrada a cada cuarto visitante. Esos clientes no solo estarán descontentos, sino que probablemente no volverán ni recomendarán tus servicios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una frase común entre los desarrolladores es que \"la accesibilidad no es una característica\". La idea es no tratar la accesibilidad como una cosa adicional para agregar, o algo con lo que lidiar al final de un proyecto."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La accesibilidad requiere pensamiento, empatía y comprensión. Cuando se aborda al principio de un proyecto, puedes diseñar y desarrollar soluciones en lugar de tener que volver a diseñar algo más tarde."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "A menudo, la resolución de la accesibilidad se resuelve para tus otras prioridades como desarrollador"
      }), ", la experiencia de usuario, el rendimiento y el SEO son algunas de las principales preocupaciones que están directamente relacionadas con la accesibilidad. Mejorar una a menudo mejora la otra."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La empatía es una gran parte de la accesibilidad que es fácil de pasar por alto. Como desarrolladores queremos automatizar todo, si algo es difícil o lleva mucho tiempo, queremos que las herramientas lo hagan por nosotros o nos digan la forma correcta de hacerlo. Desafortunadamente, estas herramientas solo pueden tocar la superficie, porque no son seres humanos, no pueden realmente experimentar la página de la manera en que un humano puede. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Es técnicamente posible crear una página web que pase la mayoría de las pruebas de accesibilidad automatizadas con gran éxito, pero que sea completamente inutilizable para todos los humanos, videntes, ciegos, sordos, daltónicos, con funciones motoras limitadas o ilimitadas."
      }), " Técnicamente, puedes cumplir con los requisitos, pero proporcionas una experiencia frustrante e inútil que aliena completamente a las personas. La conclusión es la siguiente: no te fíes de las herramientas, prueba tu trabajo, empatiza con tus usuarios, recopila comentarios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aunque no es una guía definitiva, aquí hay algunos pasos que puedes tomar para que tu contenido sea accesible, así como recursos para profundizar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Proporcionar alternativas de texto para cualquier contenido que no sea de texto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todas las imágenes, iconos, videos y botones que transmiten significado o son interactivos deben tener una alternativa de texto. Esto no solo es bueno para los visitantes que están consumiendo tu contenido a través de un lector de pantalla, sino que también es genial para los motores de búsqueda. El texto alternativo debe describir el contenido de la imagen. Es probable que la mayoría de las imágenes de tu sitio se puedan editar en el editor de páginas, por lo que es fácil ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cos-pages-editor/how-do-i-add-alt-text-to-my-image",
        children: "configurar texto alternativo en el editor de páginas"
      }), ". Sin embargo, dentro de los módulos y plantillas personalizados, debes asegurarte de que se respete el texto alternativo especificado en los editores de páginas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esto significa asegurarse de que si tienes un elemento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<img>"
      }), " que el texto alternativo que el usuario proporciona se utiliza realmente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" />\n\nBad:\n<img src=\"{{ module.image.src }}\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si por alguna razón no estás haciendo una imagen editable en el editor de páginas, debes codificar el texto alternativo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hay una excepción a esta regla de texto alternativo. Si tu imagen no proporciona ningún contexto adicional y es puramente de presentación, es mejor tener un valor de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.w3.org/WAI/tutorials/images/tips/",
        children: "texto alternativo \"nulo\""
      }), " que ir sin un atributo alt por completo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good if only presentational: <img src=\"{{ module.image.src }}\" alt=\"\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para videos, usa un servicio que admita subtítulos y considera incluir una transcripción. Algunos servicios que admiten la carga de transcripciones incluyen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.vidyard.com/hc/en-us/articles/360009876234-Make-your-Vidyard-players-accessible",
        children: "Vidyard"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://support.google.com/youtube/answer/2734799",
        children: "YouTube"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://help.vimeo.com/hc/en-us/articles/224968828-Captions-and-subtitles",
        children: "Vimeo"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://wistia.com/support/player/captions",
        children: "Wistia"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utiliza soluciones de carga diferida compatibles con a11y"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La carga diferida de imágenes es una técnica común de mejora del rendimiento para la construcción de sitios web. El método utilizado para la carga realmente diferida importa para la accesibilidad."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las soluciones de JavaScript para esto generalmente se basan en el atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " de las imágenes para ser algo falso (como un pequeño archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".gif"
      }), ") en lugar de la verdadera fuente de la imagen, que se mantiene en un atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-src"
      }), " hasta que el usuario desplaza la imagen cerca de la vista. Esto significa que inicialmente la imagen es inaccesible para un usuario de lector de pantalla que está navegando por la página, especialmente uno que usa un rotor para ver todo el contenido de la página sin siquiera desplazarse. Además, si JavaScript no se carga en una página, estas soluciones de carga diferida fallarán y, por lo tanto, dejarán a los usuarios de tecnología de asistencia sin las imágenes adecuadas en la página."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El uso de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/native-lazy-loading/",
        children: "carga diferida de imágenes nativas"
      }), " evita este problema, manteniendo el marcado de la imagen igual, independientemente de si se carga JavaScript."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img\n  src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n  loading=\"lazy\"\n  height=\"200px\"\n  width=\"400px\"\n/>\n\nBad:\n<img\n  src=\"1px.gif\"\n  data-src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n/>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot admite la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/lazy-loading",
        children: "carga diferida basada en el navegador"
      }), " en módulos personalizados. Se recomienda su uso."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Asegúrate de que todos los enlaces y las entradas del formulario tengan texto descriptivo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los enlaces, botones y elementos del formulario deben tener un texto real que indique qué hace o adónde va. De lo contrario, los lectores de pantalla leerán que el usuario tiene un enlace o botón seleccionado, pero no tendrán idea de lo que hace."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si utilizas un icono, proporciona el texto del lector de pantalla. Por ejemplo, algunas plantillas estándar de HubSpot utilizan un código corto de icono:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"https://www.facebook.com\"\n  ><i class=\"fab fa-facebook-f\"></i\n  ><span class=\"screen-reader-text\">Facebook</span></a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No uses ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'display: none'"
      }), " para ocultar etiquetas de formulario y otro texto descriptivo. Esto evita que el texto se lea en voz alta para aquellos con dificultades para ver, además de perjudicar a los usuarios que pueden ver pero tienen problemas para ver el texto debido a la falta de contraste en el texto del marcador de posición. Sin mencionar, quién no ha comenzado a completar un formulario o que ha tenido un formulario que se rellena automáticamente para ellos, pero no tenía idea de si se introdujo correctamente porque las etiquetas de los campos del formulario eran invisibles."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si es absolutamente necesario tener texto oculto o el texto oculto podría proporcionar un contexto adicional que un usuario vidente no necesitaría. Utiliza CSS que hace que el texto sea invisible sin esconderlo de los lectores de pantalla o agrega la clase ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'.screen-reader-text'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".screen-reader-text {\n  clip: rect(1px, 1px, 1px, 1px);\n  height: 1px;\n  overflow: hidden;\n  position: absolute !important;\n  width: 1px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Agregar un enlace de saltar al contenido"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando un visitante está navegando usando un lector de pantalla, o simplemente usando su teclado, es muy útil si hay una manera de saltar sobre las partes de la página que se repiten, como un encabezado. Podemos hacerlo agregando un enlace que apunte al contenido principal de la página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el encabezado, agrega HTML con el siguiente contenido:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"#content\" class=\"screen-reader-text\"\n  >Five ways to make your site more accessible</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Luego, en cada plantilla asegúrate de que hay un elemento con un atributo ID de contenido. En nuestro ejemplo, usamos el título del artículo como el texto al cual saltar. Esto permite a los motores de búsqueda comprender a qué se vincula. Es una forma más semántica de saltar al contenido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<main id=\"content\">\n  <!-- your page or post's actual content -->\n</main>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar marcado semántico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El marcado semántico es HTML que transmite significado. Algunos ejemplos de elementos que son semánticos son ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<time>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<code>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<aside>"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<article>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Algunos ejemplos de elementos que no son semánticos son ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span>"
      }), ". Los elementos no semánticos todavía tienen su lugar, pero deben usarse principalmente para la presentación y no para transmitir significado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los elementos semánticos pueden ser entendidos por los motores de búsqueda y las tecnologías de asistencia, afectando positivamente el SEO y mejorando tu experiencia de usuario."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No uses elementos como elementos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " interactivos, como botones, pestañas o enlaces, a menos que estés seguro de que has proporcionado una buena experiencia a través de los atributos de aria."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza los enlaces (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<a />"
      }), ") y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<button />"
      }), " apropiadamente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usa enlaces para enlazar a secciones de una página y navegar a otras páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usa botones para interacciones que no resulten en salir de la página o navegar a un ID."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando trabajas con tablas, si una tabla tiene un título descriptivo, inclúyela en un elemento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<caption>"
      }), " justo después del elemento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<table>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza elementos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), " con el atributo de ámbito adecuado en las tablas para los encabezados de columna y fila y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<thead>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tbody>"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tfoot>"
      }), " para denotar secciones."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza el atributo de alcance en los atributos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), " para indicar si el encabezado es para la fila o la columna."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["¿Necesitas un acordeón? No te olvides de los elementos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<summary>"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<details>"
      }), " y que te dan la mayor parte de esta funcionalidad de forma gratuita, y es accesible. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://caniuse.com/#feat=details",
        children: "Asegúrate de agregar un polyfill o un respaldo si necesitas admitir navegadores antiguos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Navegación por teclado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Algunos usuarios utilizan su teclado para navegar por páginas web y formularios de preferencia. Algunos visitantes deben usar el teclado o algún tipo de dispositivo de asistencia que emula un teclado para navegar por los sitios web."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Asegúrate de que ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_focus.asp",
          children: "':focus'"
        }), " esté diseñado para que cuando un usuario esté navegando a través del teclado pueda ver lo que está enfocado. Solo deshabilita la propiedad de esquema CSS si proporcionas un indicador visual alternativo aceptable. Usa ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/almanac/selectors/f/focus-within/",
          children: ":focus-within"
        }), " si puede ayudar a dirigir la atención de los usuarios de manera útil."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Al ocultar o mostrar partes de la página con ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_hover.asp",
          children: "':hover'"
        }), " o a través de Javascript, asegúrate de que los usuarios también puedan navegar por esos controles a través de Javascript o que haya una ruta alternativa a la información."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Asegúrate de que la navegación principal de tu sitio sea compatible con la navegación por teclado. Un problema que comúnmente se pasa por alto es que los menús desplegables y los controles flotantes no son accesibles. Esto evita que los usuarios lleguen a partes de sitios web que pueden ser fundamentales."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Proporciona y actualiza atributos de estado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hidden"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-expanded"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-current"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-selected"
        }), ", y otros según sea necesario para garantizar que los lectores de pantalla comuniquen correctamente el estado de los elementos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Volver a los roles en caso necesario"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear plantillas o archivos codificados, debes usar los elementos semánticos correctos (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dialog>"
      }), ", etc.) para comunicar a los navegadores web y lectores de pantalla qué tipo de contenido hay dentro de tus elementos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En los elementos semánticos fuera de la posibilidad no son apropiados para tu escenario debes agregar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles",
        children: "atributos role"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agregar puntos de referencia hace que sea más fácil para los usuarios que usan lectores de pantalla saltar entre las secciones principales de una página web. HubSpot incluye atributos 'role' para el menú, menú simple y módulos de búsqueda de Google."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Consejos de diseño adicionales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Asegúrate de que cuando un usuario amplía su navegador al 200%, el contenido permanezca visible y legible."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "No se recomienda hacer fuentes mucho más pequeñas que 16px, ya que puede ser difícil de leer para los visitantes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Evita el uso del color como única forma de comunicar la información. Un porcentaje sorprendente de la población mundial es daltónico."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Asegúrate de que haya suficiente contraste entre el color del texto y el fondo para que los usuarios con visión limitada puedan leer el contenido."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Evita las animaciones que parpadean rápidamente (más de tres veces por segundo), ya que podrían desencadenar convulsiones en algunos visitantes."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Considera admitir ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/introduction-reduced-motion-media-query/",
          children: "preferers-reduced-motion"
        }), " si tienes animaciones en tu sitio."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hoy en día, la creación de un sitio web adaptable se considera una necesidad. Asegúrate de que todos los elementos interactivos funcionen como se espera en el móvil. Los elementos interactivos deben ser fáciles de tocar y debe haber mucho espacio entre las regiones de toque."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Redacción de contenido"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una cosa fácil de olvidar es que la accesibilidad también debe considerarse en tu contenido de texto."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Evita el lenguaje direccional \"ver barra lateral a la izquierda\". Si el usuario está en el móvil, es probable que esto no tenga sentido para él, ya que el contenido generalmente se acumula en el dispositivo móvil."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usa encabezados como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h1>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h2>"
        }), " etc. y anídalos de manera consecutiva. No omitas un encabezado para obtener un efecto visual."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Al agregar un enlace, nunca hagas que el texto del enlace sea «Haz clic aquí», no transmite ningún significado a los lectores de pantalla, no tiene ningún sentido en las pantallas táctiles u otros tipos de dispositivos. En cambio, tu texto debe indicar qué hay en ese enlace. Esto también es mejor para el SEO, porque los motores de búsqueda entienden a qué se vincula."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Asegúrate de que si usas cualquier estilo especial en el texto para transmitir significado, estás usando la etiqueta semántica apropiada, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<mark>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<strong>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<em>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<sup>"
        }), " etc., de lo contrario tu significado puede perderse para algunos visitantes."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si te diriges a visitantes de todo el mundo, evita los chistes que son específicos de la región. Si tienes tu sitio traducido, evita los juegos de palabras. Por mucho que amemos una buena broma de papá, a menudo no se traducen bien."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utiliza herramientas para mejorar tu gramática y ortografía. Esto ayuda a la comprensión y da como resultado mejores traducciones."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Escribe tu contenido de una manera que se adapte a la audiencia de tu sitio. No le explicarías astrofísica a un estudiante de 5to grado, al igual que lo harías con un físico consumado. Dicho esto, evita las palabras elegantes solo por el hecho de usarlas, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/plain-language-experts/",
          children: "simple es mejor"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recomendaciones específicas sobre HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La mayor parte del trabajo para mejorar la accesibilidad no es específico de HubSpot. Dicho esto, queremos prepararte para el éxito en HubSpot y hay algunas cosas que puedes hacer como desarrollador o creador de contenido para proporcionar una mejor experiencia a los usuarios."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usa módulos y plantillas personalizados que sigan las mejores prácticas de a11y"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ya sea que estés instalando un módulo o plantilla del mercado, desarrollándolos o haciéndolos construir para ti, una de las mejores cosas que puedes hacer es asegurarte de que sigan las mejores prácticas. Los módulos personalizados pueden tener el mayor impacto en la accesibilidad de tu sitio porque a menudo se usan muchas veces, a veces incluso en la misma página. Del mismo modo, una sola plantilla se puede utilizar cientos o miles de veces en un solo sitio. Si tu sitio no es accesible, es probable que estés reduciendo tus ganancias. Al igual que con la publicidad, naturalmente tiene sentido pagar un poco más para asegurarte de que tu sitio web llegue a una audiencia más amplia. Uno de los beneficios de los módulos es que a menudo puedes mejorar el código más tarde a medida que evolucionan las pautas y las mejores prácticas, mejorando la accesibilidad para cada página en la que vive el módulo a la vez."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el mercado de HubSpot, busca módulos y plantillas que hablen sobre accesibilidad y cumplan con los requisitos de WCAG en sus descripciones. Si estás trabajando con un desarrollador, dile desde el principio que la accesibilidad es importante para ti. No todos los desarrolladores construyen con a11y en mente desde el principio. Decírselo al final de un proyecto probablemente te costará más que decirle desde el principio porque en lugar de priorizarlo desde el principio, ahora necesita solucionar problemas de accesibilidad de forma retroactiva."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si eres desarrollador, crea módulos y plantillas que faciliten el cumplimiento de las directrices de a11y para los creadores de contenido. Usa encabezados apropiados, HTML semántico, haz que los elementos interactivos proporcionen roles y contexto adecuados. Los creadores de contenido que conocen los problemas de accesibilidad generalmente están encantados de pagar un poco más por los módulos y plantillas que son inclusivos, dicho esto, debes demostrar que tus módulos tienen en cuenta la accesibilidad. Incluye enlaces en plantillas, haz que los grupos globales y los parciales globales utilizados en tus plantillas sean amigables, estos se utilizan en todo un sitio web y pueden tener el mayor impacto en la usabilidad de un sitio."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si creas para el mercado de HubSpot, debes saber que tu contenido podría usarse en miles, incluso millones de páginas en la web. Tú tomas una decisión que afecta a las personas al publicar tu contenido y compartir tu trabajo, independientemente de que tu trabajo sea inclusivo o no."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cuidado con las soluciones integrales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hay docenas de herramientas que hacen afirmaciones como ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "\"Solo agrega nuestro script a tu sitio web y tu sitio será accesible\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Algunas de estas herramientas intentarán dar sentido a partes de la página y cambiar el html y los atributos para intentar solucionar algunos problemas. Dicho esto, están adivinando, y pueden estar equivocadas o podrían romper la funcionalidad en tu sitio o empeorar las cosas para los usuarios."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es posible que estas herramientas no siempre funcionen como se esperaba, a veces suministrando su propio sistema de lectura de pantalla. Si tienes una interfaz de usuario complicada, tu sitio web puede llegar a ser más difícil de operar a través de la herramienta inyectada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Debido a estos problemas, las herramientas como esta no son un sustituto para construir tu sitio con la accesibilidad en mente. Invierte tiempo y dinero para proporcionar la mejor experiencia para todos tus usuarios. Confiar en soluciones integrales podría costarte lo mismo o más que simplemente construir las cosas de la manera correcta. Al probar y construir con la accesibilidad en mente, también obtendrás el beneficio de la empatía y la comprensión de tus clientes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ser claros, esto no se refiere a las herramientas de prueba, específicamente esto se refiere a las herramientas que afirman resolver problemas de accesibilidad para ti. Las herramientas de prueba en sí son geniales y deberías usarlas, además de las pruebas manuales."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://overlayfactsheet.com/",
        children: "Más información sobre scripts/superposiciones de accesibilidad."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Podcast para desarrolladores de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En enero de 2021, HubSpotters junto con Jon Sasala de Morey Creative conversaron sobre la accesibilidad."
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/xOVTQN8phIs?feature=oembed",
      maxHeight: "113px",
      maxWidth: "200px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Más información sobre accesibilidad"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existen algunos recursos realmente excelentes para crear sitios web accesibles. Recomendamos encarecidamente que les eches un vistazo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://a11yproject.com/",
          children: "The A11Y Project"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility",
          children: "Documentos de accesibilidad de MDN"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/WAI/",
          children: "Iniciativa de Accesibilidad Web"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/",
          children: "WebAIM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/wcag/checklist",
          children: "Lista de verificación WCAG 2 simplificada"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/508/checklist",
          children: "Lista de verificación de la sección 508"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/TR/WCAG22/",
          children: "Recomendaciones detalladas de WCAG 2.2"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.deque.com/axe/",
          children: "AXE por Deque"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://wave.webaim.org/",
          children: "WAVE"
        }), " - una herramienta para probar la accesibilidad de una página web"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/website/web-accessibility",
          children: "La guía definitiva para la accesibilidad web"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}