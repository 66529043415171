"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279903;
const slug = exports.slug = 'guides/cms/content/performance/overview';
const title = exports.title = 'Visão geral de CDN, segurança e desempenho';
const name = exports.name = 'PT BR CDN, security, and performance overview';
const metaDescription = exports.metaDescription = 'O HubSpot CMS oferece ferramentas de segurança, confiabilidade, desempenho e manutenção, além de uma CDN global e rápida';
const toc = exports.toc = [{
  "depth": 0,
  "id": "rede-de-entrega-de-conte%C3%BAdo-cdn",
  "label": "Rede de entrega de conteúdo (CDN)",
  "parent": null
}, {
  "depth": 0,
  "id": "secure-socket-layer-ssl",
  "label": "Secure Socket Layer (SSL)",
  "parent": null
}, {
  "depth": 0,
  "id": "http%2F2",
  "label": "HTTP/2",
  "parent": null
}, {
  "depth": 0,
  "id": "ipv6",
  "label": "IPv6",
  "parent": null
}, {
  "depth": 0,
  "id": "minifica%C3%A7%C3%A3o-de-javascript-e-css",
  "label": "Minificação de Javascript e CSS",
  "parent": null
}, {
  "depth": 0,
  "id": "cache-de-navegador-e-servidor",
  "label": "Cache de navegador e servidor",
  "parent": null
}, {
  "depth": 0,
  "id": "reescrita-de-dom%C3%ADnio",
  "label": "Reescrita de domínio",
  "parent": null
}, {
  "depth": 0,
  "id": "compress%C3%A3o-de-texto",
  "label": "Compressão de texto",
  "parent": null
}, {
  "depth": 0,
  "id": "compacta%C3%A7%C3%A3o%2Fotimiza%C3%A7%C3%A3o-e-redimensionamento-autom%C3%A1tico-de-imagens",
  "label": "Compactação/otimização e redimensionamento automático de imagens",
  "parent": null
}, {
  "depth": 0,
  "id": "accelerated-mobile-pages-amp",
  "label": "Accelerated Mobile Pages (AMP)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral de CDN, segurança e desempenho"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O CMS da HubSpot é um dos sistemas de gerenciamento de conteúdo mais avançados e otimizados disponíveis no mercado. Como o HubSpot CMS é uma solução SaaS (software como serviço), lidamos com as questões relacionadas à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/security",
        children: "segurança"
      }), ", à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/reliability",
        children: "confiabilidade"
      }), ", à manutenção e muito mais, para que sua equipe possa se concentrar em escrever código e criar experiências de usuário encantadoras."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A HubSpot trouxe várias melhorias de desempenho para você, mas ainda pode fazer muito mais. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "Como otimizar o desempenho no HubSpot CMS."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rede de entrega de conteúdo (CDN)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A rede de distribuição de conteúdo distribuída globalmente do HubSpot CMS garantirá tempos de carregamento de páginas extremamente rápidos, independentemente de onde seus visitantes estejam. Não é necessária nenhuma configuração, instalação ou conta adicional para utilizar a CDN para hospedar mídias ou páginas. O sistema gerencia a distribuição e a invalidação do cache para que você possa se concentrar na construção de um ótimo site, enquanto um firewall de aplicativos web e medidas de segurança integradas fornecem tranquilidade contra ataques online."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secure Socket Layer (SSL)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O SSL é incluído e provisionado automaticamente de forma gratuita em todos os domínios conectados nas contas do HubSpot CMS ou do Marketing Hub. Um certificado SAN próprio é provisionado em cada domínio, com opções configuráveis, como desativar o suporte para versões selecionadas do TLS, redirecionar solicitações feitas por HTTP para HTTPS e atender o cabeçalho HSTS para que solicitações futuras sejam feitas por https. Mediante solicitação, certificados SSL personalizados podem ser hospedados por uma taxa mensal adicional."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTTP/2"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todo o tráfego SSL dos sites hospedados pela HubSpot é atendido usando HTTP/2. O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://http2.github.io/",
        children: "HTTP/2"
      }), " substitui a forma como o HTTP é expresso durante a transferência. O protocolo não é reescrito do zero; os métodos HTTP, os códigos de status e a semântica são os mesmos, e deve ser possível usar as mesmas APIs que o HTTP/1.x (possivelmente com algumas pequenas adições) para representar o protocolo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O foco do protocolo é o desempenho, especificamente a latência percebida pelo usuário final e o uso de recursos de servidor e rede. Um dos principais objetivos é permitir o uso de uma única conexão entre os navegadores e um site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "IPv6"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos os sites hospedados pela HubSpot incluem endereços IPv6 para que possam ser acessados nativamente por IPv6. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.google.com/intl/en/ipv6/index.html",
        children: "IPv6"
      }), " é a versão mais recente do Protocolo de Internet e expande o número de endereços disponíveis para uma quantidade praticamente ilimitada – 340 trilhões de trilhões de endereços. Os endereços IPv4 estão acabando na Internet. A transição para o IPv6 permite que a Internet continue a crescer e que novos serviços inovadores sejam desenvolvidos, uma vez que mais dispositivos podem se conectar à Internet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Minificação de Javascript e CSS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao escrever JavaScript e CSS para páginas da HubSpot, você pode esperar o seguinte comportamento de minificação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O HubSpot minifica automaticamente o JavaScript e o CSS incluídos no gerenciador de design para remover espaços, quebras de linha e comentários desnecessários. Isso também se aplica ao JavaScript e ao CSS ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "carregados no gerenciador de design por meio da CLI"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " como a HubSpot reduz automaticamente o JavaScript e o CSS no gerenciador de design, você ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " deve adicionar código minificado diretamente ao gerenciador de design."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para incluir código minificado, você deve fazer upload do arquivo no ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/file-manager",
          children: "gerenciador de arquivos"
        }), " e anexá-lo ao gerenciador de design. Para vincular localmente um arquivo minificado a um módulo, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
          children: "atualizar o meta.json do módulo para incluir css_assets ou js_assets"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A HubSpot não minifica arquivos JavaScript ou CSS que são carregados no gerenciador de arquivos ou referenciados por meio de links externos. Você deve garantir que esses arquivos sejam minificados antes de fazer upload."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sempre que você atualizar um arquivo JavaScript ou CSS no gerenciador de design ou por meio de upload local, a HubSpot o minificará novamente de forma automática. Isso pode resultar em um pequeno atraso antes de a versão ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".min.js"
        }), " do arquivo ser distribuída às páginas ativas. Durante esse período, a HubSpot distribuirá a versão não minificada para garantir que os visitantes do site ainda obtenham a versão mais recente dos arquivos."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erros de sintaxe podem impedir a HubSpot de minificar um arquivo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além da minificação, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "usar inclusões do HubL para combinar vários arquivos CSS em um único arquivo"
      }), " de modo a aumentar ainda mais o desempenho."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cache de navegador e servidor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A HubSpot armazena automaticamente em cache as páginas e os arquivos tanto no servidor quanto no navegador para garantir alta velocidade de entrega de todos os artigos da página para todos os visitantes. Quando a sua página ou qualquer dependência dela (como um modelo ou módulo) muda, expiramos automaticamente os caches do servidor para essa página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reescrita de domínio"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada domínio adicional usado em seu site incorre em uma pesquisa e conexão DNS adicionais. Quanto menos domínios você usar, mais rápido o site será carregado. O HTTP/2 oferece suporte ao carregamento de vários arquivos de forma simultânea pela mesma conexão; portanto, as antigas diretrizes para \"fragmentar\" seus ativos entre vários domínios não se aplicam mais."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As URLs de ativos referenciados em páginas do CMS, como arquivos do sistema de arquivos do desenvolvedor, CSS, JS e imagens, são regravadas automaticamente para corresponder ao domínio da página atual, quando possível. Portanto, se você acessar uma imagem em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg",
        children: "http://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg"
      }), " em uma página exibida em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/",
        children: "www.hubspot.com"
      }), ", a URL será atualizada automaticamente para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg",
        children: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Compressão de texto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Arquivos baseados em texto, como HTML, CSS e JS, são todos compactados usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/brotli",
        children: "brotli"
      }), " antes de serem distribuídos aos navegadores. A compactação Brotli é ainda mais significativa do que GZIP. Se o cliente não indicar suporte à compactação Brotli, a compactação gzip será aplicada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Enquanto a minificação acelera o tempo de análise de arquivos CSS e JS no navegador, a compressão permite distribuir esses arquivos para o navegador de forma mais rápida."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Compactação/otimização e redimensionamento automático de imagens"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando você carrega uma imagem no gerenciador de arquivos, ela é otimizada automaticamente. Especificamente, JPEGs e PNGs têm seus metadados eliminados (EXIF e outros itens que tornam as imagens maiores). Todas as imagens (exceto arquivos GIF) também são recompactadas para não exibirem perdas. Além disso, podemos atender imagens em uma codificação diferente se puderem ser representadas como um PNG menor do que um JPEG."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "As imagens são salvas novamente em 72 dpi, independentemente da resolução original."
      }), " Se você carregar um arquivo em 300 dpi, originalmente criado para impressão, ele será convertido para 72 dpi, que é o padrão para a web."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Aprimoramos progressivamente as imagens para usar o formato WebP em navegadores compatíveis."
      }), " As imagens serão exibidas em formato WebP se o tamanho de arquivo dessa versão da imagem for menor. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Essa conversão acontece no lado do servidor e não altera a extensão do arquivo na URL"
      }), ". Uma imagem carregada como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), " ainda será exibida como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), " na URL, mas será distribuída como WebP. Assim, os links dessa imagem funcionarão para todos, independentemente do suporte a WebP."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "As imagens hospedadas pela HubSpot e inseridas no conteúdo do CMS serão redimensionadas automaticamente pela HubSpot"
      }), ", evitando que o navegador gaste recursos para fazer isso. Para isso, a HubSpot adiciona parâmetros de consulta de altura e/ou largura à URL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " de quaisquer imagens que tenham um atributo de largura ou altura. Observação: o redimensionamento da imagem leva menos de um minuto; se a página for solicitada antes de a imagem ser redimensionada, a imagem não redimensionada será exibida para essa solicitação. Os navegadores recebem várias opções para que a resolução da imagem seja carregada. Assim, suas imagens ficam nítidas em monitores padrão e de alta resolução."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A otimização/compressão e o redimensionamento automático de imagens são ativados por padrão para todas as páginas/arquivos hospedados pela HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/img_opt.png",
        alt: "Captura de tela do elemento img com srcset adicionado automaticamente em diferentes URLs de redimensionamento"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para arquivos jpg individuais, se a URL da imagem tiver o parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "quality=high"
        }), ", a imagem não sofrerá compressão."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os desenvolvedores que trabalham com o HubSpot CMS também podem fornecer contexto adicional do HubSpot às imagens para controlar ainda mais o redimensionamento. Isso é feito através da função ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#resize-image-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url()"
        })
      }), ". Assim, os desenvolvedores podem incluir contenções nos módulos para evitar que os criadores de conteúdo exibam imagens grandes em páginas e e-mails. A função também pode ser útil para situações em que o tamanho de uma imagem não é definido por atributos de altura e largura dentro do HTML, como uma imagem de fundo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Accelerated Mobile Pages (AMP)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O AMP, ou Accelerated Mobile Pages, é um formato de página específico para dispositivos móveis que carrega conteúdo quase que instantaneamente. O AMP pode ser ativado nos posts de blog hospedadas pela HubSpot com o toque de um botão. Consulte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "Como usar Accelerated Mobile Pages (AMP) no HubSpot"
      }), " para obter mais detalhes."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}