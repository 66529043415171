"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 170130091591;
const slug = exports.slug = 'guides/api/conversations/mobile-chat-sdk/ios';
const title = exports.title = 'Integrate the HubSpot mobile chat SDK into your iOS app (BETA)';
const name = exports.name = 'Integrate the HubSpot mobile chat SDK into your iOS app (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to use the HubSpot mobile chat SDK in your iOS app (BETA).';
const toc = exports.toc = [{
  "depth": 0,
  "id": "installation",
  "label": "Installation",
  "parent": null
}, {
  "depth": 0,
  "id": "configuration",
  "label": "Configuration",
  "parent": null
}, {
  "depth": 0,
  "id": "open-hubspot-chat-view",
  "label": "Open HubSpot Chat View",
  "parent": null
}, {
  "depth": 1,
  "id": "show-the-chat-view-as-a-sheet-in-swiftui",
  "label": "Show the chat view as a sheet in SwiftUI",
  "parent": "open-hubspot-chat-view"
}, {
  "depth": 1,
  "id": "show-the-chat-as-a-presented-view-controller-in-uikit",
  "label": "Show the chat as a presented view controller in UIKit",
  "parent": "open-hubspot-chat-view"
}, {
  "depth": 0,
  "id": "identify-users-with-the-hubspot-visitor-identification-token",
  "label": "Identify users with the HubSpot visitor identification token",
  "parent": null
}, {
  "depth": 0,
  "id": "add-custom-chat-data-properties",
  "label": "Add custom chat data properties",
  "parent": null
}, {
  "depth": 0,
  "id": "clearing-data-on-logout",
  "label": "Clearing data on logout",
  "parent": null
}, {
  "depth": 0,
  "id": "reference-documentation",
  "label": "Reference documentation",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      ul: "ul",
      li: "li",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Integrate the HubSpot mobile chat SDK into your iOS app (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "This functionality is currently in development"
          }), ". It is subject to change based on testing and feedback. By using these instructions you agree to adhere to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/hs-developer-terms",
            children: "HubSpot's Developer Terms"
          }), " & ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/developerbetaterms",
            children: "HubSpot's Developer Beta Terms"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["To ask questions or provide feedback on the beta, you can join the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/slack/developer-slack",
            children: "HubSpot Developer Slack Community"
          }), " and join the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://hubspotdev.slack.com/channels/mobile-chat-sdk-beta",
            children: "#mobile-chat-sdk-beta"
          }), " channel."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Learn how to opt your account into this beta ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/account-management/opt-your-hubspot-account-into-a-public-beta-feature",
            children: "here"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use the HubSpot mobile chat SDK to integrate your iOS mobile application with HubSpot's live chat functionality."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The mobile chat SDK allows you to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Integrate HubSpot chat into your mobile app to deliver real-time, in-app customer support."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Leverage the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/chatflows/create-a-bot",
          children: "bot"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/knowledge-base/create-and-customize-knowledge-base-articles",
          children: "knowledge base"
        }), " tools to help deflect customer inquires when your support agents are unavailable."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Alert users of new messages via push notifications."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Customize the chat experience to align with your business's brand and UI."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/mobile-chat-sdk-demo-screenshot.png",
        alt: "mobile-chat-sdk-demo-screenshot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Installation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To get start developing with the mobile chat SDK:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/mobile-chat-sdk-ios",
          children: "GitHub repository URL"
        }), " of the mobile chat SDK to your project using Swift Package Manager. From your project settings, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Package Dependencies"
        }), " tab, then search for the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/mobile-chat-sdk-ios",
          children: "HubSpot mobile chat SDK GitHub URL"
        }), " to add it to your project."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["While this feature is in beta, you may need to configure Xcode with your GitHub account since this repository is private:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To add your GitHub account directly in Xcode, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Xcode"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Settings"
            }), " in the top menu bar, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Account"
            }), ". Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "+ icon"
            }), " to add your GitHub account."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you're developing your app using a CLI tool like ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "xcodebuild"
            }), ", you may need to specify the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "-scmProvider system"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "-scmProvider xcode"
            }), " arguments to choose whether your system git credentials or xcode credentials are used."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you've added the mobile SDK to your project using the Swift Package Manager, include your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpot-Info.plist config"
      }), " file in your project, then mark it as included in the app target."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "During app startup, or in another suitable location in your app's code where you initialize your app components, call the configure method on the SDK."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {\n\n    // Override point for customization after application launch.\n\n    // This will configure the SDK using the `Hubspot-Info.plist` file that is bundled in app\n    try! HubspotManager.configure()\n\n    return true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Open HubSpot Chat View"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can present the chat view modally as a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.apple.com/design/human-interface-guidelines/sheets",
        children: "sheet"
      }), ", as a fullscreen view, or pushed into a navigation stack. The simplest way to get started is to present the chat as a sheet in response to a button press in the UI of your app."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The chat view can be initialized using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotChatView.init(manager:pushData:chatFlow:)"
      }), " method, using either default values or with customized chat settings."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Show the chat view as a sheet in SwiftUI"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The chat view is a SwiftUI View, meaning it can be the contents of a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.apple.com/design/human-interface-guidelines/sheets",
        children: "sheet"
      }), ", or embedded in your existing UI like any other view. You can present it as a sheet using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".sheet"
      }), " modifier, in response to a user action such as tapping a button."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "Button(action: {\n     showChat.toggle()\n }, label: {\n     Text(\"Chat Now\")\n }).sheet(isPresented: $showChat, content: {\n     HubspotChatView(chatFlow: \"support\")\n })\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the example above, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "$showChat"
      }), " is a state property in the view:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "@State var showChat = false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Show the chat as a presented view controller in UIKit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Although the HubSpot Chat View is a SwiftUI view, it will also work when contained in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UIHostingController"
      }), ". For example, you can present the chat view from a UIViewController button action."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "@IBAction\nfunc onButtonPress(_ source: Any) {\n\n    //Init chat view with no arguments , or use alternative initialiser for configuring chat specifics\n    let chatView = HubspotChatView()\n    //Create a hosting controller to hold the chat view\n    let hostingVC = UIHostingController(rootView: chatView)\n\n    // Present the view controller like any other (or push into a navigation stack)\n    self.present(hostingVC, animated: true)\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identify users with the HubSpot visitor identification token"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can identify users by using the visitor identification API, detailed in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/visitor-identification",
        children: "this article"
      }), ". This API is best implemented in your own server project, where you can pass the identity token to your app based on whether suits your specific setup (i.e., passing a token in response to a visitor going through a login page as a result of a dedicated API that you own)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once a token is generated, it can associated with a contact's email address by calling ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubspotManager/setUserIdentity(identityToken:email:)"
      }), ". This should be called before opening a chat view."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add custom chat data properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The SDK supports setting key-value pairs to keep track of data you might need to track while a chat session is open. You can set your own custom values, or declare common permission preferences such as photo or notification permissions."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubspotManager/setChatProperties(data:)"
      }), " method and provide the associated key and value you want to set. This is best called before starting a chat, and will apply to all new chats. You could set an account status, or other identifiers when setting up your user. These will then appear in all chats opened for the remainder of the app launch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, the following code would set several pre-defined permissions and custom properties:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "var properties: [String: String] = [\n     ChatPropertyKey.cameraPermissions.rawValue: self.checkCameraPermissions(),\n     \"myapp-install-id\": appUniqueId,\n     \"subscription-tier\": \"premium\"\n ]\n\nHubspotManager.shared.setChatProperties(data: properties)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Clearing data on logout"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The SDK stores in memory identification tokens, email address, and any properties set. The push token for the app is also associated with the current user, if applicable. You may want to clear this data when a user is logging out, or changing users in a multi user app. To clear this data, call ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubspotManager/clearUserData()"
      }), " at an appropriate time in your app."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " calling the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "HubspotManager/clearUserData()"
        }), " only impacts the data used for future chat sessions. It has no impact on data or chat sessions already stored in HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reference documentation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can consult the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/mobile-chat-sdk-ios/documentation/hubspotmobilesdk/",
        children: "reference documentation"
      }), " for details on how to use each of the components in the HubSpot mobile SDK."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}