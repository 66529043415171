'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["properties"],
  _excluded2 = ["properties"];
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import Raven from 'raven-js';
import { memoizedGetDefaultFormState } from '../../ParamForm/utils/defaults';
import { getTopLevelSchemaId } from '../../ParamForm/utils/idUtils';
import { shouldUseJsonField } from '../../ParamForm/utils/typeUtils';
import pickBy from 'hs-lodash/pickBy';
import isEmpty from 'hs-lodash/isEmpty';
export default class PropertyCollapser {
  constructor(maxProperties) {
    this.collapseProperties = (object, maxProps, required) => {
      if (maxProps < 0) {
        return 0;
      }
      const keys = Object.keys(object);
      const extraKeys = keys.slice(maxProps);
      const filteredObject = pickBy(object, (value, key) => {
        if (required.includes(key)) {
          return true;
        }
        return !extraKeys.includes(key);
      });
      this.collapseCount += extraKeys.length;
      return {
        notCollapsedCount: keys.length - extraKeys.length,
        filteredObject
      };
    };
    this.expandedProperties = (object, maxProps, required) => {
      if (maxProps < 0) {
        return 0;
      }
      const keys = Object.keys(object);
      const extraKeys = keys.slice(maxProps);
      const filteredObject = pickBy(object, (value, key) => {
        if (required.includes(key)) {
          return false;
        }
        return extraKeys.includes(key);
      });
      return {
        notCollapsedCount: keys.length - extraKeys.length,
        filteredObject
      };
    };
    this.getComponentPropertiesToKeep = (rootRef, schemas, propCount = 0, keptProperties = [], visited = []) => {
      const _this = this;
      function keep(schemaName, key, incrPropCount = true) {
        keptProperties.push({
          schema: schemaName,
          key
        });
        _this.collapseCount--;
        if (incrPropCount) {
          propCount++;
        }
      }
      const name = this.getSchemaName(rootRef);
      const root = schemas[name];
      if (!root.properties || visited.includes(name)) {
        keep(name, null);
        return keptProperties;
      }
      visited.push(name);
      const propKeys = Object.keys(root.properties);
      this.collapseCount += propKeys.length;
      for (const key of propKeys) {
        if (propCount >= this.maxProperties) {
          return keptProperties;
        }
        const prop = root.properties[key];
        keep(name, key, !root.required || !root.required.includes(key) || visited.length > 1);
        if (prop.type === 'array') {
          if (!prop.items) {
            // No items means malformed schema, log and skip
            Raven.captureMessage(`${name} component schema is malformed because object with property type 'array' doesn't contain an associated 'items' property`);
            continue;
          }
          if (prop.items.$ref) {
            keep(this.getSchemaName(prop.items.$ref), null);
            keptProperties = this.getComponentPropertiesToKeep(prop.items.$ref, schemas, propCount, keptProperties, visited);
          }
        } else if (prop.type === 'object' && prop.additionalProperties && prop.additionalProperties.$ref || prop.$ref) {
          // Object with additional properties or direct $ref
          const ref = prop.$ref || prop.additionalProperties.$ref;
          keep(this.getSchemaName(ref), null);
          keptProperties = this.getComponentPropertiesToKeep(ref, schemas, propCount, keptProperties, visited);
        }
      }
      return keptProperties;
    };
    this.maxProperties = maxProperties;
    this.collapsedSchema = null;
    this.expandedSchema = null;
    this.collapseCount = 0;
  }

  /**
   * "Collapses" object properties by deleting after `maxProperties`
   * @param {Object} object object to delete properties from
   * @param {Number} maxProps max number of properties to keep
   * @param {Array<String>} required array of required property keys that are
   * not collapsed. Required properties do not add to the "not collapsed" count
   * @returns {{notCollapsedCount:number, filteredObject:Object}} object
   *    notCollapsedCount number of properties that were NOT collapsed
   *    filteredObject is the object with properties deleted after `maxProperties`
   */

  getSchemaName($ref) {
    return $ref.split('/').slice(-1)[0];
  }

  /**
   * Creates a new schema containing only the properties passed
   * @param {Array<Object>} keptProperties properties to keep in the form of the structure
   * returned from `this.getComponentPropertiesToKeep()`
   * @param {Array<Object>} schemas component schemas to reference original properties from
   */
  getSchemaWithKeptProperties(keptProperties, schemas) {
    const newSchema = {};
    for (const kept of keptProperties) {
      if (!newSchema[kept.schema]) {
        const _schemas$kept$schema = schemas[kept.schema],
          otherProps = _objectWithoutPropertiesLoose(_schemas$kept$schema, _excluded);
        newSchema[kept.schema] = otherProps;
        newSchema[kept.schema].properties = {};
      }
      if (kept.key) {
        newSchema[kept.schema].properties[kept.key] = schemas[kept.schema].properties[kept.key];
      }
    }
    return newSchema;
  }

  /**
   * Creates a new schema without the properties passed
   * @param {Array<Object>} removedProperties properties to remove in the form of the structure
   * @param {Array<Object>} schemas component schemas to reference original properties from
   */
  getSchemaWithRemovedProperties(removedProperties, schemas) {
    const newSchema = {};
    for (const [key, value] of schemas) {
      if (!removedProperties.includes(key)) {
        const otherProps = _objectWithoutPropertiesLoose(value, _excluded2);
        newSchema[key] = otherProps;
      }
      for (const toRemove of removedProperties) {
        if (newSchema[key].properties[toRemove.key]) {
          const _newSchema$key$proper = newSchema[key].properties,
            _toRemove$key = toRemove.key,
            otherProperties = _objectWithoutPropertiesLoose(_newSchema$key$proper, [_toRemove$key].map(_toPropertyKey));
          newSchema[key].properties = Object.assign({}, otherProperties);
        }
      }
    }
    return newSchema;
  }

  /**
   * Recursively gathers up to `this.maxProperties` properties from schema components
   * in the form of the following structure:
   * @param {string} rootRef component schema `$ref` to start traversing from
   * @param {Array<Object>} schemas list of component schemas
   * @param {Number} propCount number of properties already kept
   * @param {Array<Object>} keptProperties list of properties that have already
   * been marked as kept
   * @example
   * {
   *  schema: string // name of schema in component definitions
   *  key: string // name of property in component's `properties` object
   * }
   * @returns kept properties as above structure
   */

  /**
   * Returns the passed properties list with properties after `this.maxProperties` removed,
   * sets `this.collapsedSchema` to same value and `this.collapseCount`
   * to the number of fields that were collapsed.
   * @param {Array<Object>} props properties list structure
   */
  calculateCollapsedSchema(props, formData, operation) {
    this.collapseCount = 0;

    // Track which props have already been expanded to avoid exceeding `this.maxProperties`
    let expandedProps = 0;

    // go through all groups of properties
    for (let i = 0; i < (props || []).length; i++) {
      const schema = props[i].schema;
      const properties = schema.properties;
      if (properties) {
        if (schema.$$ref) {
          const formState = memoizedGetDefaultFormState(schema, formData[schema.type], schema.definitions);
          const topLevelSchemaId = getTopLevelSchemaId(schema, null, schema.definitions, formState, operation.operationId);
          if (!shouldUseJsonField(schema, topLevelSchemaId)) {
            const {
              notCollapsedCount,
              filteredObject
            } = this.collapseProperties(properties, this.maxProperties - expandedProps, schema.required || []);
            expandedProps += notCollapsedCount;
            schema.properties = isEmpty(filteredObject) ? null : filteredObject;
          }
        } else {
          const {
            notCollapsedCount,
            filteredObject
          } = this.collapseProperties(properties, this.maxProperties - expandedProps, schema.required || []);
          expandedProps += notCollapsedCount;
          schema.properties = isEmpty(filteredObject) ? null : filteredObject;
        }
      }
      const ref = schema.$ref;
      if (ref) {
        const schemas = schema.definitions.components.schemas;
        const keptProperties = this.getComponentPropertiesToKeep(ref, schemas, expandedProps);
        expandedProps += (keptProperties || []).length;
        props[i].schema.definitions.components.schemas = this.getSchemaWithKeptProperties(keptProperties, schemas);
      }
    }
    this.collapsedSchema = props;
    return props;
  }

  /**
   * Returns the passed properties list with properties after `this.maxProperties` removed,
   * sets `this.collapsedSchema` to same value and `this.collapseCount`
   * to the number of fields that were collapsed.
   * @param {Array<Object>} props properties list structure
   */
  calculateExpandedSchema(props, formData, operation) {
    this.collapseCount = 0;

    // Track which props have already been expanded to avoid exceeding `this.maxProperties`
    let expandedProps = 0;

    // go through all groups of properties
    for (let i = 0; i < (props || []).length; i++) {
      const schema = props[i].schema;
      const properties = schema.properties;
      if (properties) {
        if (schema.$$ref) {
          const formState = memoizedGetDefaultFormState(schema, formData[schema.type], schema.definitions);
          const topLevelSchemaId = getTopLevelSchemaId(schema, null, schema.definitions, formState, operation.operationId);
          if (!shouldUseJsonField(schema, topLevelSchemaId)) {
            const {
              notCollapsedCount,
              filteredObject
            } = this.expandedProperties(properties, this.maxProperties - expandedProps, schema.required || []);
            expandedProps += notCollapsedCount;
            schema.properties = filteredObject;
          }
        } else {
          const {
            notCollapsedCount,
            filteredObject
          } = this.expandedProperties(properties, this.maxProperties - expandedProps, schema.required || []);
          expandedProps += notCollapsedCount;
          schema.properties = filteredObject;
        }
      }
      const ref = schema.$ref;
      if (ref) {
        const schemas = schema.definitions.components.schemas;
        const propertiesToRemove = this.getComponentPropertiesToKeep(ref, schemas, expandedProps);
        expandedProps += propertiesToRemove.length;
        props[i].schema.definitions.components.schemas = this.getSchemaWithRemovedProperties(propertiesToRemove, schemas);
      }
    }
    this.expandedSchema = props;
    return props;
  }
}