"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 142116065450;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-definitions';
const title = exports.title = 'Benutzerdefinierte Events definieren';
const name = exports.name = 'Benutzerdefinierte Events definieren';
const metaDescription = exports.metaDescription = 'Erfahren Sie mehr über das Erstellen von benutzerdefinierten Events mit der API von HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eine-event-definition-erstellen",
  "label": "Eine Event-Definition erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "event-eigenschaften",
  "label": "Event-Eigenschaften",
  "parent": null
}, {
  "depth": 1,
  "id": "standard-event-eigenschaften-von-hubspot",
  "label": "Standard-Event-Eigenschaften von HubSpot",
  "parent": "event-eigenschaften"
}, {
  "depth": 1,
  "id": "neue-eigenschaften-definieren",
  "label": "Neue Eigenschaften definieren",
  "parent": "event-eigenschaften"
}, {
  "depth": 1,
  "id": "bestehende-benutzerdefinierte-eigenschaften-aktualisieren",
  "label": "Bestehende benutzerdefinierte Eigenschaften aktualisieren",
  "parent": "event-eigenschaften"
}, {
  "depth": 1,
  "id": "eine-eigenschaft-l%C3%B6schen",
  "label": "Eine Eigenschaft löschen",
  "parent": "event-eigenschaften"
}, {
  "depth": 0,
  "id": "ein-event-aktualisieren",
  "label": "Ein Event aktualisieren",
  "parent": null
}, {
  "depth": 0,
  "id": "ein-event-l%C3%B6schen",
  "label": "Ein Event löschen",
  "parent": null
}, {
  "depth": 0,
  "id": "vorhandene-event-definitionen-abrufen",
  "label": "Vorhandene Event-Definitionen abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "beispiel-f%C3%BCr-ein-benutzerdefiniertes-event",
  "label": "Beispiel für ein benutzerdefiniertes Event",
  "parent": null
}, {
  "depth": 1,
  "id": "benutzerdefinierte-event-definition-erstellen",
  "label": "Benutzerdefinierte Event-Definition erstellen",
  "parent": "beispiel-f%C3%BCr-ein-benutzerdefiniertes-event"
}, {
  "depth": 1,
  "id": "eigenschaft-f%C3%BCr-benutzerdefinierte-event-definition-erstellen",
  "label": "Eigenschaft für benutzerdefinierte Event-Definition erstellen",
  "parent": "beispiel-f%C3%BCr-ein-benutzerdefiniertes-event"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      ul: "ul",
      li: "li",
      pre: "pre",
      h3: "h3",
      ol: "ol",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      ProductTier,
      Table,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Benutzerdefinierte Events definieren"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit benutzerdefinierten Events können Sie Events definieren und nachverfolgen, die für Ihr Unternehmen spezifisch sind, z. B. Events auf Ihrer Website oder in einer App. Sie können Events konfigurieren, um Informationen in Eigenschaften zu speichern, die Sie dann in allen HubSpot-Tools nutzen können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Daten von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "benutzerdefinierten Events"
      }), " an HubSpot zu senden, müssen Sie zunächst das Event definieren. Dies ist ähnlich wie bei benutzerdefinierten CRM-Objekten, bei denen Sie zunächst das benutzerdefinierte Objekt definieren müssen, bevor Sie einzelne Datensätze für dieses Objekt erstellen können. Eine Event-Definition enthält Details wie Metadaten, CRM-Objektzuordnungen und Eigenschaften."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Folgenden erfahren Sie mehr über das Erstellen und Verwalten von Event-Definitionen mithilfe der API. Um zu erfahren, wie Sie Event-Definitionen erstellen können, ohne die API zu verwenden, sehen Sie sich ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events-with-the-code-wizard",
        children: "diesen Artikel in der Wissensdatenbank von HubSpot"
      }), " an."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Event-Definition erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um das benutzerdefinierte Event-Schema zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions"
      }), " durch. Fügen Sie in den Anfragetext Definitionen für Ihr Event-Schema ein, einschließlich Label, Name, CRM-Objektzuordnungen und benutzerdefinierte Eigenschaften."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachfolgend finden Sie einen Beispielanfragetext mit Parameterdefinitionen. Ein vollständiges Beispiel finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#custom-event-example",
        children: "Beispiel für benutzerdefinierte Events unten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "label"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "Zeichenfolge"
                    }), " (erforderlich)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Das für Menschen lesbare Label des Events, das in HubSpot angezeigt wird (bis zu 100 Zeichen). Lange Label können in bestimmten Teilen der HubSpot-Benutzeroberfläche abgeschnitten werden."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "name"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "Zeichenfolge"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Der eindeutige, interne Name des Events, mit dem Sie über die API auf das Event verweisen können. Wenn kein Wert angegeben wird, generiert HubSpot automatisch einen anhand von ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "label"
                    }), ". Diese Eigenschaft kann ", (0, _jsxRuntime.jsx)("u", {
                      children: "nicht"
                    }), " mehr geändert werden, nachdem die Event-Definition erstellt wurde."]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Beachten Sie die folgenden Benennungsanforderungen:"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "Diese Eigenschaft kann nur Kleinbuchstaben, Zahlen, Unterstriche und Bindestriche enthalten (bis zu 50 Zeichen)."
                    }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "Das erste Zeichen muss ein Buchstabe sein."
                    }), "\n"]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "description"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "Zeichenfolge"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Die Beschreibung des Events, die in HubSpot angezeigt wird."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "primaryObject"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "Zeichenfolge"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Das CRM-Objekt, mit dem das Event verknüpft werden soll. Event-Abschlüsse werden in den Chroniken von CRM-Datensätzen des angegebenen Objekts angezeigt. Kann einer der folgenden Werte sein: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "CONTACT"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "COMPANY"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "DEAL"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "TICKET"
                    }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "CUSTOM OBJECT NAME"
                    }), "."]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Wenn kein Wert angegeben wird, ordnet HubSpot das Event automatisch Kontakten zu. Diese kann ", (0, _jsxRuntime.jsx)("u", {
                      children: "nicht"
                    }), " mehr geändert werden, nachdem die Event-Definition erstellt wurde."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "propertyDefinitions"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "array"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Für jede benutzerdefinierte Eigenschaft bietet HubSpot eine Reihe von Standardeigenschaften, die Sie beim Senden von Event-Daten verwenden können. Fügen Sie dieses Array ein, um alle benutzerdefinierten Eigenschaften zu definieren, die Ihr Event außerhalb dieser Standard-Eigenschaften benötigt. Sie können bis zu 50 benutzerdefinierte Eigenschaften pro Event berücksichtigen."
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Berücksichtigen Sie für jede Eigenschaft:"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "label"
                      }), ": das In-App-Label der Eigenschaft."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "name"
                      }), ": der interne Name der Eigenschaft, den Sie beim Senden von Eigenschaftsdaten über die API verwenden."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "type"
                      }), ": der ", (0, _jsxRuntime.jsx)(_components.a, {
                        href: "#custom-property-types",
                        children: "Typ der Eigenschaft"
                      }), ". Die Standardeinstellung ist ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "string"
                      }), "."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "options"
                      }), ": bei ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "enumeration"
                      }), "-Eigenschaften das Array der vordefinierten Werte, einschließlich jeweils ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "label"
                      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "value"
                      }), "."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "description"
                      }), ": optionaler Text zum Beschreiben der Eigenschaft."]
                    }), "\n"]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Erfahren Sie im Folgenden mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "#event-properties",
                      children: "benutzerdefinierte Eigenschaften"
                    }), "."]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request body\n{\n  \"label\": \"My event label\",\n  \"name\": \"unique_event_name\",\n  \"description\": \"An event description that helps users understand what the event tracks.\",\n  \"primaryObject\": \"COMPANY\",\n  \"propertyDefinitions\": [\n    {\n      \"name\": \"choice-property\",\n      \"label\": \"Choice property\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"label\": \"Option 1\",\n          \"value\": \"1\"\n        },\n        {\n          \"label\": \"Option 2\",\n          \"value\": \"2\"\n        }\n      ]\n    },\n    {\n      \"name\": \"string-property\",\n      \"label\": \"String property\",\n      \"type\": \"string\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event-Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Benutzerdefinierte Eigenschaften von Events werden verwendet, um Informationen über einzelne benutzerdefinierte Event-Abschlüsse zu speichern. Diese Eigenschaften sollten verwendet werden, wenn sie für das Senden von Event-Abschlüssen geeignet sind, aber sie sind nicht erforderlich, damit ein Event-Abschluss gültig ist. Für jede Event-Definition bietet HubSpot einen Standardsatz von 32 Eigenschaften. Darüber hinaus können Sie bis zu 50 benutzerdefinierte Eigenschaften pro Event-Eigenschaft erstellen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eigenschaften können einer der folgenden Typen sein:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), ": eine Eigenschaft, die Klartext-Zeichenfolgen empfängt. Wenn der Eigenschaftsname die Wörter ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "referrer"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "link"
        }), " enthält, kann der Eigenschaftswert bis zu 1024 Zeichen lang sein. Ansonsten können die Eigenschaftswerte bis zu 256 Zeichen lang sein."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        }), ": eine Eigenschaft, die numerische Werte mit bis zu einer Dezimalstelle empfängt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), ": Eine Eigenschaft mit vordefinierten Optionen. Fügen Sie bei der Erstellung dieses Eigenschaftstyps ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), "-Array ein, um die verfügbaren Werte festzulegen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "datetime"
        }), ": Eine Eigenschaft, die Epochen-Millisekunden- oder ISO8601-Werte empfängt, die einen Zeitstempel darstellen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Folgenden erfahren Sie mehr über die standardmäßigen Event-Eigenschaften von HubSpot, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#define-new-properties",
        children: "neue Eigenschaften für bestehende Events definieren"
      }), " und wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#update-existing-custom-properties",
        children: "bestehende benutzerdefinierte Event-Eigenschaften aktualisieren"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standard-Event-Eigenschaften von HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_asset_description"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_asset_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_browser"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_campaign_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_city"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_country"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_device_name"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_device_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_class"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_text"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_language"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_link_href"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_operating_system"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_operating_version"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_content_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_title"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_url"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_parent_module_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_referrer"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_region"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_screen_height"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_screen_width"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_touchpoint_source"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_tracking_name"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_user_agent"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_campaign"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_content"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_medium"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_source"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_term"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Neue Eigenschaften definieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine neue Eigenschaft für ein bestehendes benutzerdefiniertes Event zu definieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property"
      }), " durch. Fügen Sie im Anfragetext die Definition für Ihre Eigenschaft ein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"name\": \"property-name\",\n  \"label\": \"Property name\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"label\": \"label\",\n      \"value\": \"value\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei der Benennung Ihrer Eigenschaften sollten Sie Folgendes beachten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sobald Sie eine Eigenschaft erstellt haben, kann der Name der Eigenschaft nicht mehr geändert werden."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der Name darf nur Kleinbuchstaben, Zahlen, Unterstriche und Bindestriche enthalten."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Das erste Zeichen des Eigenschaftsnamens muss ein Buchstabe sein."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der Eigenschaftsname und das Label können jeweils bis zu 50 Zeichen lang sein."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn kein Eigenschaftsname angegeben wird, wird dieser automatisch anhand des Labels der Eigenschaft generiert."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Lange Label können in bestimmten Teilen der HubSpot-Benutzeroberfläche abgeschnitten werden."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bestehende benutzerdefinierte Eigenschaften aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine bestehende Eigenschaft eines benutzerdefinierten Events zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property"
      }), " durch. Die einzigen Felder, die für eine Eigenschaft aktualisiert werden können, sind die Felder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), " für Aufzählungseigenschaften."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Um den Typ der Eigenschaft zu ändern, verwenden Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), "-Endpunkt, um die Eigenschaft zu löschen und mit dem richtigen Typ neu zu erstellen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Eigenschaft löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine vorhandene Eigenschaft eines benutzerdefinierten Events zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property/{propertyName}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn eine Eigenschaft gelöscht wird, steht sie nicht mehr für die Verwendung in zukünftigen Event-Abschlüssen zur Verfügung. Bei früheren Abschlüssen bleiben die Eigenschaftenwerte erhalten."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " beim Löschen eines Events:"]
      }), (0, _jsxRuntime.jsxs)(_components.ol, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Alle Events für diese Event-Definition werden gelöscht und können nicht wiederhergestellt werden."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Zuvor gelöschte ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "eventName"
          }), "'n können nicht wieder verwendet werden."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Event aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die einzigen Felder, die bei einer Event-Definition aktualisiert werden können, sind die Felder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein bestehendes benutzerdefiniertes Event-Schema zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Event löschen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie ein benutzerdefiniertes Event löschen, verwende Verweise auf dieses Event in anderen HubSpot-Tools, z. B. in Workflows und Berichten, das Event nicht länger."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein benutzerdefiniertes Event zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorhandene Event-Definitionen abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine einzelne Event-Definition abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Event-Definitionen nach bestimmten Kriterien zu durchsuchen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions"
      }), " durch. Sie können die folgenden Abfrageparameter angeben, um Ihre Suche zu verfeinern:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "searchString"
        }), ": sucht nach Events, die die angegebenen Zeichen im Feld ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " enthalten. Die Suche ist ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " unscharf, sondern ist eine naive ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "contains"
        }), "-Suche."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        }), ": eine gehashte Zeichenfolge, die in seitenweisen Antworten zum Anzeigen der nächsten Seite der Suchergebnisse bereitgestellt wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), ": die maximale Anzahl der Ergebnisse, die zurückgegeben werden sollen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "includeProperties"
        }), ": ein boolescher Wert, der angibt, ob Event-Eigenschaften in die zurückgegebenen Ergebnisse aufgenommen werden sollen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beispiel für ein benutzerdefiniertes Event"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden wird die Erstellung eines benutzerdefinierten Events anhand eines Beispiels erläutert."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In dieser Anleitung wird Folgendes behandelt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "das Erstellen einer benutzerdefinierten Event-Definition."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "das Erstellen einer neuem Eigenschaft für die Event-Definition."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ziel:"
      }), " ein Autohaus mit dem Namen CarSpot verfügt über ein Online-Inventar aller Autos, die auf seinem Gelände verfügbar sind. CarSpot möchte nachverfolgen, wann sich Besucher ihrer Website ein bestimmtes Auto ansehen, das auf ihrem Gelände verfügbar ist. Dazu erstellt es ein benutzerdefiniertes Event, das es mit den Datensätzen der Kontakte verknüpft, um nachzuverfolgen, wer das Angebot anzeigt. Für dieses Event werden auch eine Reihe benutzerdefinierter Eigenschaften benötigt, um die während des Events angezeigten Details des Fahrzeugangebots zu speichern."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Benutzerdefinierte Event-Definition erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem CarSpot entschieden hat, welchen Namen haben soll, welche Eigenschaften bei jedem ausgelösten Event berücksichtigt werden sollen und mit welchem CRM-Objekt sie die Events verknüpfen wollen, erstellen sie das Event-Schema, indem sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Aufruf an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/event-definitions"
      }), " mit dem folgenden Anfragetext durchführen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for event definitions\n\n{\n  \"label\": \"Viewed Car\",\n  \"name\": \"viewed_car\",\n  \"description\": \"An event that fires when visitor views a car listing in the online inventory\",\n  \"primaryObject\": \"CONTACT\",\n  \"propertyDefinitions\": [\n    {\n      \"name\": \"condition\",\n      \"label\": \"Condition\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"label\": \"New\",\n          \"value\": \"new\"\n        },\n        {\n          \"label\": \"Used\",\n          \"value\": \"used\"\n        }\n      ]\n    },\n    {\n      \"name\": \"year\",\n      \"label\": \"Year\",\n      \"type\": \"number\"\n    },\n    {\n      \"name\": \"make\",\n      \"label\": \"Make\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"model\",\n      \"label\": \"Model\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"mileage\",\n      \"label\": \"Mileage\",\n      \"type\": \"number\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eigenschaft für benutzerdefinierte Event-Definition erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Kurz nach der Einführung des Events auf ihrer Website hat CarSpot beschlossen, dass sie herausfinden wollen, ob der Preis für das Angebot die Klickrate beeinflusst. Um dies nachzuverfolgen, wird eine neue Eigenschaft erstellt, die den Preis des Angebots enthält."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine neue Eigenschaft zu definieren, führt CarSpot eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/event-definitions/viewed_car/property"
      }), " mit dem folgenden Anfragetext durch:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for creating a property\n{\n  \"name\": \"price\",\n  \"label\": \"Price\",\n  \"type\": \"number\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit der Definition ihres benutzerdefinierten Events kann CarSpot nun ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "Event-Daten an HubSpot senden, indem es diese benutzerdefinierte Event-Definition verwendet"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}