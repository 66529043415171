"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937505;
const slug = exports.slug = 'guides/api/crm/properties';
const title = exports.title = 'CRM API | プロパティー';
const name = exports.name = 'vNext Docs DP - プロパティー';
const metaDescription = exports.metaDescription = ' CRMプロパティーAPIエンドポイントを使用すると、あらゆるオブジェクトを対象として、カスタムプロパティーを管理したり、既定のプロパティーの詳細を確認したりできます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "既定のプロパティー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97",
  "label": "プロパティーグループ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AEtype%E3%81%A8fieldtype%E3%81%AE%E5%80%A4",
  "label": "プロパティーのtypeとfieldTypeの値",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "プロパティーを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%9B%BA%E6%9C%89id%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "固有IDプロパティーを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A8%88%E7%AE%97%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "計算プロパティーを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%A8%88%E7%AE%97%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E6%A7%8B%E6%96%87",
  "label": "計算プロパティーの構文",
  "parent": "%E8%A8%88%E7%AE%97%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "%E3%83%AA%E3%83%86%E3%83%A9%E3%83%AB%E6%A7%8B%E6%96%87",
  "label": "リテラル構文",
  "parent": "%E8%A8%88%E7%AE%97%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E6%A7%8B%E6%96%87",
  "label": "プロパティーの構文",
  "parent": "%E8%A8%88%E7%AE%97%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "%E6%BC%94%E7%AE%97%E5%AD%90",
  "label": "演算子",
  "parent": "%E8%A8%88%E7%AE%97%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "%E9%96%A2%E6%95%B0",
  "label": "関数",
  "parent": "%E8%A8%88%E7%AE%97%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "%E6%9D%A1%E4%BB%B6%E6%96%87",
  "label": "条件文",
  "parent": "%E8%A8%88%E7%AE%97%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E5%BC%8F%E3%81%AE%E4%BE%8B",
  "label": "式の例",
  "parent": "%E8%A8%88%E7%AE%97%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "プロパティーを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E5%80%A4%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%BE%E3%81%9F%E3%81%AF%E3%82%AF%E3%83%AA%E3%82%A2%E3%81%99%E3%82%8B",
  "label": "プロパティーの値を更新またはクリアする",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%97%E3%81%AE%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AB%E5%80%A4%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "チェックボックスタイプのプロパティーに値を追加する",
  "parent": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E5%80%A4%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%BE%E3%81%9F%E3%81%AF%E3%82%AF%E3%83%AA%E3%82%A2%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%A7%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E6%89%80%E6%9C%89%E8%80%85%E3%82%92%E5%89%B2%E3%82%8A%E5%BD%93%E3%81%A6%E3%82%8B",
  "label": "ユーザープロパティーでレコード所有者を割り当てる",
  "parent": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E5%80%A4%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%BE%E3%81%9F%E3%81%AF%E3%82%AF%E3%83%AA%E3%82%A2%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E5%80%A4%E3%82%92%E6%B6%88%E5%8E%BB%E3%81%99%E3%82%8B",
  "label": "プロパティー値を消去する",
  "parent": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E5%80%A4%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%BE%E3%81%9F%E3%81%AF%E3%82%AF%E3%83%AA%E3%82%A2%E3%81%99%E3%82%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      strong: "strong",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "プロパティー"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロパティーは、CRMレコードに情報を格納するために使用します。HubSpotでは、CRMオブジェクトごとに既定のプロパティーのセットが用意されています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: "HubSpot上で"
      }), "、またはプロパティーAPIを使用して、独自のカスタムプロパティーを作成および管理することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロパティーを作成する際には、データの構築方法を考慮することが重要です。多くの場合、HubSpotの標準オブジェクト上にカスタムプロパティーを作成することで適切に対応できます。しかし場合によっては、独自のプロパティーを備えた", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "カスタムオブジェクト"
      }), "を別途作成することが必要になります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "既定のプロパティー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CRMオブジェクトは、主な「タイプ」と「プロパティー」によって定義されます。タイプごとに標準のプロパティーがあり、名前と値のペアで表されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "オブジェクトごとの既定のプロパティーをご覧ください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "コンタクト"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "会社"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "取引"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "チケット"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-activity-properties",
          children: "アクティビティー"
        }), "（コール、Eメール、ミーティング、メモ、タスク）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-lead-properties",
          children: "リード"
        }), "（", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " および ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), "）"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プロパティーグループ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロパティー", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties#create-and-edit-property-groups",
        children: "グループ"
      }), "は、関連する複数のプロパティーをグループ化するために使用します。グループ化されたプロパティーは各HubSpotレコード上に隣接して表示されます。カスタムオブジェクトのプロパティーを作成して連携を行う場合は、カスタムプロパティーのグループを活用することでデータの特定が容易になります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プロパティーのtypeとfieldTypeの値"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロパティーを作成または更新する際は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), "の両方の値が必要です。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), "の値により、プロパティーの型、つまり文字列、数値などのデータ型が決まります。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), "プロパティーでは、HubSpotでのプロパティーの表示形式（プレーンテキスト、ドロップダウンメニュー、日付入力など）を指定します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の表に、プロパティーの有効な", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), "値とそれに対応する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), "値を示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsxs)(_components.th, {
            children: ["有効な", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fieldType"
            }), "値"]
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["バイナリーオプション（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Yes"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "No"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), "など）を格納するフィールド。"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "一連のオプションを表す文字列。各オプションはセミコロンで区切ります。"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["特定の年、月、日を表す値。値はUTC時間で表す必要があります。値の形式は、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "ISO 8601文字列またはミリ秒単位のエポック時間のタイムスタンプ（UTC深夜0時）"
            }), "にできます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dateTime"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["特定の年、月、日、時刻を表す値。値はUTC時間で表す必要があります。値の形式は、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "ISO 8601文字列またはミリ秒単位のUNIXタイムスタンプ"
            }), "にできます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プレーンテキスト文字列。文字数の上限は65,536文字です。"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数字からなる、小数第1位までの数値。"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object_coordinates"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "他のHubSpotオブジェクトを参照するために使用されるテキスト値。内部プロパティーのみに使用されます。このタイプのプロパティーは作成／編集不可であり、HubSpotには表示されません。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "書式設定されたJSONとして格納されるテキスト値。内部プロパティーにのみ使用されます。このタイプのプロパティーは作成／編集不可であり、HubSpotには表示されません。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["有効な", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), "値は次のとおりです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールドのタイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ユーザーが「はい」または「いいえ」のいずれかを選択できる入力。フォーム内で使用する場合、1つのチェックボックスとして表示されます。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "単一チェックボックスプロパティーに値を追加する"
            }), "方法をご確認ください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["他のプロパティー値や関連付けに基づいて値を計算できるカスタム式。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#create-calculation-properties",
              children: "計算プロパティー"
            }), "を定義する方法をご確認ください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["プロパティー内で使用可能な選択肢の中からユーザーが複数選択できるチェックボックスのリスト。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "複数チェックボックスプロパティーの更新時に値を書式設定する"
            }), "方法をご確認ください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "日付値。日付入力として表示されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レコードまたはフォーム経由でファイルをアップロードできるようにします。ファイルIDを格納します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サニタイズ（エスケープ）されたHTMLとしてレンダリングされる文字列。プロパティーに対するリッチ テキスト エディターの使用が可能になります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "10進数または科学的表記で記述された、数字の文字列または数値。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "書式設定された電話番号として表示されるプレーンテキスト文字列。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティーで許容される選択肢の中からユーザーがいずれか1つを選択できる入力。フォーム内で使用する場合、一連のラジオボタンとして表示されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティーで許容される選択肢の中からユーザーがいずれか1つを選択できるドロップダウンの入力。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プレーンテキスト文字列。単行テキスト入力として表示されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プレーンテキスト文字列。単行テキスト入力として表示されます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プロパティーを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロパティーを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/{objectType}"
      }), "に送信します。リクエスト本文に、次の必須フィールドを含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "groupName"
        }), "：当該プロパティーが属する", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/organize-and-export-properties",
          children: "プロパティーグループ"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), "：当該プロパティーの内部名（例：favorite_food）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), "：HubSpotに表示される、当該プロパティーの名前（例：Favorite Food）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "type"
        }), "：プロパティーの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#type",
          children: "型"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fieldType"
        }), "：プロパティーの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-type",
          children: "フィールドタイプ"
        }), "。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、「Favorite Food」という名前のコンタクトプロパティーを作成する場合、リクエストは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body POST crm/v3/properties/contacts\n{\n  \"groupName\": \"contactinformation\",\n  \"name\": \"favorite_food\",\n  \"label\": \"Favorite Food\",\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "固有IDプロパティーを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでレコードが作成されると、そのレコードに固有の", (0, _jsxRuntime.jsx)(_components.em, {
        children: "ID"
      }), "（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "）が自動的に生成され、文字列として扱われます。これらのIDはそのオブジェクト内でのみ一意です。したがって、コンタクトと会社のIDが同じになる場合があります。コンタクトと会社の場合は、コンタクトのEメールアドレス（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "）や会社のドメイン名（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), "）など、他にも固有のIDがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "場合によっては、複数のレコードで同じ値を入力できないように、独自の固有IDプロパティーを作成することが推奨されます。固有IDプロパティーは、オブジェクトあたり最大10個まで作成できます。APIを介して一意の値を必要とするプロパティーを作成するには、次のようにします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), "に送信します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["リクエスト本文で、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hasUniqueValue"
        }), "フィールドの値を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), "に設定します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body\n{\n  \"groupName\": \"dealinformation\",\n  \"name\": \"system_a_unique\",\n  \"label\": \"Unique ID for System A\",\n  \"hasUniqueValue\": true,\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["固有IDプロパティーを作成したら、API呼び出しでそれを使用して、特定のレコードを取得することができます。リクエストURLは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), "のようになります。これにより、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "system_a_unique"
      }), "フィールドの値が", (0, _jsxRuntime.jsx)(_components.code, {
        children: "abc"
      }), "に設定された取引が返されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "（コンタクト）や", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), "（会社）を使用する場合と同じように、この固有IDプロパティーの値を使用して特定のレコードを識別し、更新できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "計算プロパティーを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["計算プロパティーは、同じオブジェクトレコード内の他のプロパティーに基づいてプロパティー値を定義します。計算プロパティーを定義するには、最小値、最大値、件数、合計、平均などを求める演算を含めた式を使用します。プロパティーAPIを使用すると、HubSpotアカウント内で計算プロパティーを読み取ったり、フィールドタイプとして", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculation_equation"
      }), "、データ型として", (0, _jsxRuntime.jsx)(_components.code, {
        children: "number"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bool"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "string"
      }), "、または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enumeration"
      }), "を使用して計算プロパティーを作成したりできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロパティーの計算式は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), "フィールドを使用して定義できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**APIを介して作成された計算プロパティーをHubSpot内で編集することは", (0, _jsxRuntime.jsx)("u", {
          children: "できません"
        }), "。これらのプロパティーは、プロパティーAPIを介してのみ編集できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "計算プロパティーの構文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), "を使用すると、算術演算子、比較演算子、論理演算子、条件文、およびその他の関数を使用して式を記述できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "リテラル構文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**文字列リテラル：**定数列は、単一引用符（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'constant'"
        }), "）または二重引用符（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"constant\""
        }), "）のいずれかを使用して表現できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**数値リテラル：**定数は任意の実数にできます。小数点表現を含めることもできます。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1005"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1.5589"
        }), "は、どちらも有効な定数です。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**ブール値リテラル：**ブール型定数には、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), "または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), "を指定できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "プロパティーの構文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**文字列プロパティー変数：**識別文字列を文字列プロパティーとして解釈させるには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), "関数でラップする必要があります。例えば、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string(var1)"
        }), "は文字列プロパティーvar1として解釈されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**数値プロパティー変数：**全ての識別子は数値プロパティー変数として解釈されます。例えば、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "var1"
        }), "は数値プロパティーvar1の値として解釈されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**ブール型プロパティー変数：**識別子をブール型プロパティーとして解釈させるには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool"
        }), "関数でラップする必要があります。例えば、識別子", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool(var1)"
        }), "はブール型プロパティーvar1の値として解釈されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**使用される言語では、文字列を", (0, _jsxRuntime.jsx)("u", {
          children: "除く"
        }), "全てのタイプで大文字と小文字が区別されます。例えば", (0, _jsxRuntime.jsx)(_components.code, {
          children: "If A ThEn B"
        }), "は", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if a then b"
        }), "とまったく同じですが、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'a'"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'A'"
        }), "は同じではありません。トークン化にスペース、タブ、改行が使用されますが、無視されます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "演算子"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "演算子は、リテラル値とプロパティー値で使用できます。算術演算子の場合、乗算には接頭辞表記を使用し、演算の順序を指定するには括弧を使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "演算子"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "+"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値または文字列を加算します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値または文字列を加算します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100 - property2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値を乗算します。"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "10property1"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10 * property1"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値を除算します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 * (100 - property2/(50 - property3))"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ある値が別の値より小さいかどうかを確認します。数値プロパティーまたは定数で使用できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a < 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ある値が別の値より大きいかどうかを確認します。数値プロパティーまたは定数で使用できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > 50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ある値が別の値以下であるかどうかを確認します。数値プロパティーまたは定数で使用できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a <= b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ある値が別の値以上であるかどうかを確認します。数値プロパティーまたは定数で使用できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b>= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ある値が別の値と等しいことを確認します。数字と文字列の両方で使用できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "(a + b - 100c * 150.652) = 150-230b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "equals"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ある値が別の値と等しいことを確認します。数字と文字列の両方で使用できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a + b - 100.2c * 150 equals 150 - 230"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ある値が別の値と等しくないことを確認します。数字と文字列の両方で使用できます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string(property1) != 'test_string'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "or"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2つの値のいずれかがtrueであることを確認します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > b or b <= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "and"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "両方の値がtrueであることを確認します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool(a) and bool(c)"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "いずれの値もtrueでないことを確認します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not (bool(a) and bool(c))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "関数"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サポートされている関数は次のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "関数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2個から100個までの入力値を取り、全ての入力値の中から最大値を返します。"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b, c, 100)"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "min"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2個から100個までの入力値を取り、全ての入力値の中から最小値を返します。"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b, c, 100)"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "式を評価できるかどうかを評価します。"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), "= true（プロパティーがブール型の場合）", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), " = false（プロパティーが空か、ブール型でない場合）"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "入力として2個の値を取り、最初の入力値に2番目の入力値が含まれている場合はtrueを返します。"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('hello', 'ello')"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('ello', 'hello')"
            }), " = false"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列のリストを結合します。リストには、2個から最大100個の入力を含めることができます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate('a', 'b', string(a), string(b))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次の2つの解析関数もあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number_to_string"
        }), "：入力された数値式を文字列に変換しようと試みます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string_to_number"
        }), "：入力された文字列式を数値に変換しようと試みます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、文字列と数値を加算することはできないため、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + num_cars"
      }), "は有効なプロパティーではありませんが、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + number_to_string(num_cars)"
      }), "は有効です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "条件文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "を使った条件文を使用して式を記述することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、条件文は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if boolean_expression then statement [elseif expression then statement]* [else statement | endif]"
      }), "のようになります。ここで、括弧で囲まれた", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[a]"
      }), "はオプションであることを表し、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a|b"
      }), "は「a」または「b」が有効であることを表します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "*"
      }), "は0以上であることを意味します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "を使用すると、条件文を早期に終了して、次の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), "が属する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "をパーサーが特定できるようにすることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "式の例"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例を参考にして、独自の計算式を定義できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"closed - started\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下に、条件文を使用した高度な例を示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"if is_present(hs_latest_sequence_enrolled_date) then\n  if is_present(hs_sequences_actively_enrolled_count) an hs_sequences_actively_enrolled_count >= 1 then\n    true\n  else\n    false\nelse\n  ''\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プロパティーを取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "オブジェクト内の個々のプロパティーまたは全てのプロパティーの情報を取得することができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["個々のプロパティーを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/properties/{object}/{propertyName}"
        }), "に送信します。例えばFavorite Foodプロパティーを取得する場合のリクエストURLは、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://api.hubspot.com/crm/v3/properties/contacts/favorite_food"
        }), "のようになります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["オブジェクトの全てのプロパティーを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), "に送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**全てのプロパティーを取得する場合、既定では機密性の低いプロパティーのみが返されます。センシティブデータのプロパティーを取得するには、値を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sensitive"
        }), "に設定した", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dataSensitivity"
        }), "クエリーパラメーターを含めます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/sensitive-data#manage-sensitive-data",
          children: "APIを使用したセンシティブデータの管理"
        }), "（ベータ版、", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " のみ）について詳細をご確認ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プロパティーの値を更新またはクリアする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レコードのプロパティー値を更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/{objectType}/{recordId}"
      }), "に送信します。リクエスト本文で、次のプロパティーとその値を配列に格納します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "オブジェクトAPI"
      }), "を介したレコードの更新の詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "チェックボックスタイプのプロパティーに値を追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レコードのチェックボックスタイプのプロパティーの値を更新する場合は、次の方法で値を書式設定します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**ブール型チェックボックスプロパティー：HubSpotで「はい」として表示するか、チェックが入った状態にするには、値が", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), "である必要があります。HubSpotで［いいえ］として表示するか、チェックが入っていない状態にするには、値が", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), "である必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**複数選択チェックボックスプロパティー：複数チェックボックスプロパティーに値を追加するには、最初の値の前にセミコロンを追加し、各値をセミコロンで区切ります。値とセミコロンの間にはスペースを入れないでください。プロパティーに既存の値がある場合、先頭のセミコロンによって値が上書きされるのではなく、値が付加されます。例えば、コンタクトの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_buying_role"
        }), "プロパティーに既存の値", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DECISION_MAKER"
        }), "があるとします。既存の値を置き換えずに追加の値を付加するリクエストは、次のようになります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example body for PATCH request to /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_buying_role\": \";BUDGET_HOLDER;END_USER\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ユーザープロパティーでレコード所有者を割り当てる"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["APIを介してCRMレコードにユーザーを割り当てる際は、値としてユーザーの所有者", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "を指定する必要があります。所有者IDは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: "プロパティー設定"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "所有者API"
      }), "を介して確認できます。例えば、ユーザーをコンタクトの所有者として割り当てるには、本文として", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\":{ \"hubspot_owner_id\": \"41629779\"}}"
      }), "を設定した", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/contacts/{contactId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "プロパティー値を消去する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "APIを介してオブジェクトプロパティーの値を消去するには、プロパティー値を空の文字列に設定します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、コンタクトオブジェクトから", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), "を消去するには、本文として", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\": { \"firstname\": \"\"}}"
      }), "を指定した", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/contacts/{contactId}"
      }), "に送信します。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}