"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601073957;
const slug = exports.slug = 'getting-started/tools-to-help-you-build';
const title = exports.title = 'Des outils pour vous aider à concevoir';
const name = exports.name = 'Des outils pour vous aider à concevoir (documentation 2024 pour les développeurs)';
const metaDescription = exports.metaDescription = 'Découvrez les différents outils et ressources disponibles pour vous aider à concevoir sur HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "ilc-hubspot",
  "label": "ILC HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "extension-visual-studio-code-de-hubspot",
  "label": "Extension Visual Studio Code de HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "biblioth%C3%A8ques-clients",
  "label": "Bibliothèques clients",
  "parent": null
}, {
  "depth": 0,
  "id": "postman",
  "label": "Postman",
  "parent": null
}, {
  "depth": 0,
  "id": "projets-b%C3%AAta",
  "label": "Projets (BÊTA)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Des outils pour vous aider à concevoir"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot propose une gamme d'outils pour vous aider à concevoir sur sa plateforme de développement. Les API de HubSpot sont flexibles et vous pourrez toujours utiliser votre environnement de développement préféré pour concevoir une intégration. Toutefois, les outils énumérés ci-dessous sont disponibles pour tous les développeurs afin d'améliorer leur productivité et de simplifier leur workflow de développement."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ILC HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous concevez sur le CMS HubSpot, vous pouvez installer l'ILC de HubSpot pour créer sur HubSpot localement. Après l'installation, vous pouvez utiliser vos outils préférés dans votre environnement préféré. Vous pouvez également mettre en place une intégration continue avec un compte GitHub pour suivre les modifications et vous aider à collaborer avec les autres développeurs de votre organisation."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "installer l'ILC HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Extension Visual Studio Code de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'extension Visual Studio Code de HubSpot vous permet de centraliser votre workflow de développement sans avoir à quitter Visual Studio Code. Après l'installation, vous pouvez utiliser l'ILC de HubSpot directement pour vous authentifier et gérer vos comptes connectés ainsi que pour tirer parti de la coloration syntaxique HubL et de la saisie automatique. Vous pouvez également synchroniser des fichiers avec le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager",
        children: "gestionnaire de conception"
      }), " de votre compte HubSpot par défaut."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
        children: "installer et utiliser l'extension Visual Studio Code de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bibliothèques clients"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot fournit des bibliothèques clients afin que vous puissiez utiliser votre langage de programmation préféré lors de l'utilisation des API de HubSpot. Les bibliothèques clients sont disponibles en JavaScript, Ruby, Python et PHP. Chaque bibliothèque client vous permet d'invoquer des appels de fonction pour lire, modifier, créer et supprimer des données dans votre compte HubSpot au lieu d'avoir à rédiger des requêtes API manuellement."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur la configuration et l'utilisation des bibliothèques clients ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "Node"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-php",
        children: "PHP"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-ruby",
        children: "Ruby"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-python",
        children: "Python"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Postman"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Postman est un client API riche en fonctionnalités que vous pouvez utiliser pour effectuer facilement des requêtes authentifiées auprès de HubSpot. Vous pouvez également parcourir des collections sélectionnées de tous les points de terminaison d'API publique de HubSpot. Ces collections sont régulièrement mises à jour par HubSpot afin d'actualiser les spécifications de tous les champs de demande et de réponse."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Téléchargez le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/",
        children: "client Postman"
      }), ", puis parcourez les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/hubspot",
        children: "collections officielles de HubSpot Postman"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Projets (BÊTA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les projets sont un conteneur de haut niveau que vous pouvez utiliser pour personnaliser le CRM de HubSpot. Avec l'ILC de HubSpot, vous pouvez créer et déployer des applications privées, créer des extensions d'interface utilisateur et développer des fonctions sans serveur pour lire ou saisir de façon dynamique des données dans des composants personnalisés du CRM. Après le déploiement, vous pouvez gérer votre projet dans HubSpot, ce qui vous permet de consulter l'historique de la conception et de surveiller les appels d'API."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur la façon de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project",
        children: "créer un projet à partir de zéro"
      }), " ou suivez le guide de démarrage rapide pour les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/quickstart",
        children: "extensions d'IU"
      }), ". Si vous ne savez pas par où commencer, vous pouvez également consulter les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "exemples de projets de HubSpot"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}