"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 182398334248;
const slug = exports.slug = 'guides/cms/content/memberships/microsoft-sign-in';
const title = exports.title = 'Microsoft Sign-In for Memberships';
const name = exports.name = 'Microsoft Sign-In for Memberships';
const metaDescription = exports.metaDescription = 'Learn more about setting up memberships for private content to allow visitors to sign in with their Microsoft account.  ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-an-application",
  "label": "Create an application",
  "parent": null
}, {
  "depth": 0,
  "id": "set-up-application-information",
  "label": "Set up application information",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      img: "img",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Microsoft Sign-In for Memberships"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise', 'cms-professional']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can allow members to access your private content using their Microsoft account by configuring an application in Microsoft Azure."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create an application"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In your Microsoft Azure account, you can create an application to connect to HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your Microsoft Azure account, open ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://portal.azure.com/#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/~/Overview",
          children: "Microsoft Entry ID (Azure Activity Directory)"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top left, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add"
        }), " dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App registration"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/add-app-registration-in-azure.png",
        alt: "add-app-registration-in-azure"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Name"
        }), " field, enter an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "internal name"
        }), " for your application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Supported account types"
        }), " section, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "account types"
        }), " that will be able to view your private content using a Microsoft sign-in. The ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Accounts in any organizational directory...and personal Microsoft accounts"
        }), " option is recommended to support the widest variety of accounts."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Redirect URI"
        }), " section, you can provide a URI for the authentication response. It's recommended to return to this step later."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Register"
        }), " to complete application creation."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up application information"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In your Microsoft Azure account, open your newly created application."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, click to expand ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Manage"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authentication"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/left-sidebar-menu-in-azure-authentication.png",
        alt: "left-sidebar-menu-in-azure-authentication"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Platform considerations"
        }), " section, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "+"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add a platform"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Web applications"
        }), " section, select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Web"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/web-applications-in-azure.png",
        alt: "web-applications-in-azure"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In a separate tab, locate your credentials in HubSpot:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "settings icon"
            }), " in the top navigation bar."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Content > Private Content"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Sign-in Options"
            }), " section, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Set up social sign-on"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the right panel, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Microsoft"
            }), " tab."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Copy"
            }), " next to the redirect URI."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In Microsoft Azure, paste the redirect URI."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Repeat the process for each separate domain you have connected to HubSpot. To change the domain you are setting up, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "domain"
        }), " dropdown menu above the redirect URI field and select a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "domain"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/select-new-domain-for-social-sign-in-in-hubspot.png",
        alt: "select-new-domain-for-social-sign-in-in-hubspot"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In Microsoft Azure, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Overview"
        }), " in the left sidebar menu."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click to expand the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Essentials"
        }), " section."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copy the Application (client) ID, then paste the ID into the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Client ID"
        }), " field in HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add new certificate or"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "secret"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/add-a-certificate-or-a-secret.png",
        alt: "add-a-certificate-or-a-secret"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "+"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "New client secret"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Edit your client secret description and expiration settings."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copy the client secret, then paste the value into the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Client Secret"
        }), " field in HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In HubSpot, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verify"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/set-up-social-sign-on-for-microsoft.png",
        alt: "set-up-social-sign-on-for-microsoft"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}