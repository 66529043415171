"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31237961323;
const slug = exports.slug = 'guides/api/crm/imports';
const title = exports.title = 'API du CRM | Imports';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Imports';
const metaDescription = exports.metaDescription = "Les imports sont utilisés pour remplir un compte HubSpot avec des données d'objets qui peuvent être utilisées avec les outils de vente, de marketing et de service client.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "d%C3%A9marrer-un-import",
  "label": "Démarrer un import",
  "parent": null
}, {
  "depth": 1,
  "id": "formater-les-donn%C3%A9es-importrequest",
  "label": "Formater les données importRequest",
  "parent": "d%C3%A9marrer-un-import"
}, {
  "depth": 1,
  "id": "mapper-des-colonnes-de-fichiers-%C3%A0-des-propri%C3%A9t%C3%A9s-hubspot",
  "label": "Mapper des colonnes de fichiers à des propriétés HubSpot",
  "parent": "d%C3%A9marrer-un-import"
}, {
  "depth": 0,
  "id": "importer-un-fichier-avec-un-type-d-objet",
  "label": "Importer un fichier avec un type d'objet",
  "parent": null
}, {
  "depth": 0,
  "id": "importer-un-fichier-avec-plusieurs-objets",
  "label": "Importer un fichier avec plusieurs objets",
  "parent": null
}, {
  "depth": 0,
  "id": "importer-plusieurs-fichiers",
  "label": "Importer plusieurs fichiers",
  "parent": null
}, {
  "depth": 0,
  "id": "obtenir-des-imports-pr%C3%A9c%C3%A9dents",
  "label": "Obtenir des imports précédents",
  "parent": null
}, {
  "depth": 0,
  "id": "cancel-an-import",
  "label": "Cancel an import",
  "parent": null
}, {
  "depth": 0,
  "id": "view-and-troubleshoot-import-errors",
  "label": "View and troubleshoot import errors",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-d-utilisation",
  "label": "Limites d'utilisation",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      img: "img",
      h3: "h3",
      strong: "strong",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Imports"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez l'API d'import pour importer des fiches d'informations et des activités du CRM dans votre compte HubSpot, comme des contacts, des entreprises et des notes. Une fois l'import terminé, vous pouvez accéder aux fiches d'informations et aux activités et les mettre à jour via les différents points de terminaison de l'API du CRM, y compris l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "API des contacts"
      }), ", l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API des associations"
      }), " et les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API des engagements."
      }), " Vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/import-objects",
        children: "importer des fiches d'informations et des activités à l'aide de l'outil d'import guidé dans HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Avant de commencer l'import, découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file",
        children: "objets et les activités pouvant être importés ainsi que sur les exigences en matière de fichiers et de propriétés."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Démarrer un import"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez commencer un import en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports"
      }), " avec un corps de demande qui spécifie comment mapper les colonnes de votre fichier d'import aux propriétés de CRM associées dans HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les imports d'API sont envoyés sous forme de demandes de données de formulaire, le corps de la demande contenant les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-the-importrequest-data",
          children: "importRequest"
        }), " : un champ de texte qui contient le JSON de demande."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#map-file-columns-to-hubspot-properties",
          children: "files"
        }), " : un champ de fichier qui contient le fichier d'import."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour l'en-tête de demande, ajoutez un en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Content-Type"
      }), " avec une valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La capture d'écran ci-dessous illustre ce à quoi pourrait ressembler votre demande lorsque vous utilisez une application telle que Postman :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-import-request-no-response0.png",
        alt: "postman-import-request-no-response0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formater les données importRequest"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le JSON de demande, définissez les détails du fichier d'import, y compris le mappage des colonnes de la feuille de calcul avec les données HubSpot. Votre JSON de demande doit inclure les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " : nom de l'import. Dans HubSpot, il s'agit du nom ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/crm-setup/view-and-analyze-previous-imports",
            children: "affiché dans l'outil d'import"
          }), " ainsi que du nom que vous pouvez mentionner dans d'autres outils, tels que des listes."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "importOperations"
          }), " : un champ facultatif utilisé pour indiquer si l'import doit ", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/crm-setup/import-objects#choose-how-to-import",
            children: ["créer et mettre à jour, ", (0, _jsxRuntime.jsx)("u", {
              children: "uniquement"
            }), " créer ou ", (0, _jsxRuntime.jsx)("u", {
              children: "uniquement"
            }), " mettre à jour des fiches d'informations"]
          }), " pour un objet ou une activité donné. Incluez ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "objectTypeId"
          }), " pour l'objet/l'activité et si vous souhaitez ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPSERT"
          }), " (créer et mettre à jour) ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CREATE"
          }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPDATE"
          }), " des fiches d'informations. Par exemple, le champ ressemblerait à ceci dans votre demande : ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"importOperations\": {\"0-1\": \"CREATE\"}"
          }), ". Si vous n'incluez pas ce champ, la valeur par défaut utilisée pour l'import sera ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPSERT"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "dateFormat"
          }), " : le format des dates incluses dans le fichier. Par défaut, cette valeur est définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "MONTH_DAY_YEAR"
          }), ", mais vous pouvez également utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "DAY_MONTH_YEAR"
          }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "YEAR_MONTH_DAY"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "marketableContactImport"
          }), " : un champ facultatif pour indiquer le ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/contacts/marketing-contacts",
            children: "statut marketing"
          }), " des contacts dans votre fichier d'import. Ceci est utilisé uniquement lors de l'import de contacts dans des comptes qui ont ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/contacts/marketing-contacts#check-your-access-to-marketing-contacts",
            children: "accès aux contacts marketing"
          }), ". Pour définir les contacts dans le fichier comme marketing, utilisez la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), ". Pour définir les contacts dans le fichier comme non marketing, utilisez la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "createContactListFromImport"
          }), " : un champ facultatif pour ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/crm-setup/import-objects#create-list-from-import",
            children: "créer une liste statique de contacts"
          }), " à partir de votre import. Pour créer une liste à partir de votre fichier, utilisez la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "files"
          }), " : un tableau qui contient des informations sur votre fichier d'import."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileName"
            }), " : le nom du fichier d'import."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileFormat"
            }), " : le format du fichier d'import. Pour les fichiers CSV, utilisez la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), ". Pour les fichiers Excel, utilisez la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SPREADSHEET"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileImportPage"
            }), " : contient le tableau ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), " requis pour mapper les données de votre fichier d'import aux données HubSpot. Découvrez-en davantage sur le mappage des colonnes ci-dessous."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mapper des colonnes de fichiers à des propriétés HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le tableau ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "columnMappings"
      }), ", incluez une entrée pour chaque colonne de votre fichier d'import, en respectant l'ordre de votre feuille de calcul. Pour chaque colonne, ajoutez les champs suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnObjectTypeId :"
        }), " le nom ou la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), " de l'objet ou de l'activité auquel/à laquelle les données appartiennent. Consultez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "cet article"
        }), " pour une liste complète des valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnName"
        }), " : le nom de l'en-tête de colonne."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "propertyName"
        }), " : le nom interne de la propriété HubSpot vers laquelle les données seront mappées. Pour la colonne commune dans les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "imports multi-fichiers"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyName"
        }), " doit avoir la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " lorsque le champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toColumnObjectTypeId"
        }), " est utilisé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnType :"
        }), " utilisé pour spécifier qu'une colonne contient une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file#:~:text=Name%2C%20Email%20Address",
          children: "propriété d'identifiant unique"
        }), ".-,Unique%20identifier,-%3A%20a%20property) En fonction de la propriété et de l'objectif de l'import, utilisez l'une des valeurs suivantes :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "HUBSPOT_OBJECT_ID"
            }), " : l'identifiant d'une fiche d'informations. Par exemple, votre fichier d'import de contacts peut contenir un ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "ID de fiche d'informations"
            }), " qui stocke l'ID de l'entreprise à laquelle vous souhaitez associer les contacts."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "HUBSPOT_ALTERNATE_ID"
            }), " : un identifiant unique autre que l'ID de fiche d'informations. Par exemple, votre fichier d'import de contacts peut contenir une colonne ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Adresse e-mail"
            }), " qui stocke les adresses e-mail des contacts."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "FLEXIBLE_ASSOCIATION_LABEL"
            }), " : incluez ce type de colonne pour indiquer que la colonne contient des libellés d'associations."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "ASSOCIATION_KEYS"
            }), " : pour les imports d'associations d'objets identiques uniquement, incluez ce type de colonne pour l'identifiant unique des mêmes fiches d'informations d'objets que vous associez. Par exemple, dans votre demande d'import d'associations de contacts, la colonne ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "[ID de fiche d'informations/adresse e-mail] du contact associé"
            }), " doit avoir un élément ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnType"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASSOCIATION_KEYS"
            }), ". Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/fr/crm-setup/set-up-your-import-file#same-object-association-imports",
              children: "la configuration de votre fichier d'import pour l'import d'associations d'objets identiques"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "toColumnObjectTypeId"
        }), " : pour les imports de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "plusieurs fichiers"
        }), " ou objets, le nom ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), " de l'objet auquel appartient la propriété de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file#sample-import-files:~:text=To%20import%20and%20associate%20multiple%20objects%20in%20one%20file%2C%20include%20information%20about%20associated%20records%20and/or%20activities%20in%20the%20same%20row.%20In%20two%20files%2C%20use%20a%20common%20column%20to%20connect%20the%20records%20in%20each%20file.%20You%20can%20refer%20to%20the%20example%20files%20for%20more%20help%20importing%20and%20associating%20records.",
          children: "colonne commune"
        }), " ou le libellé d'association. Incluez ce champ pour la propriété de colonne commune dans le fichier de l'objet auquel la propriété n'appartient ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), ". Par exemple, si vous associez des contacts et des entreprises dans deux fichiers avec la propriété de contact ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Adresse e-mail"
        }), " comme colonne commune, incluez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toColumnObjectTypeId"
        }), " pour la colonne ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Adresse e-mail"
        }), " dans le fichier de l'", (0, _jsxRuntime.jsx)("u", {
          children: "entreprise"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "foreignKeyType"
        }), " : pour les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "imports multi-fichiers"
        }), " uniquement, le type d'association que la colonne commune doit utiliser, spécifié par ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationCategory"
        }), ". Incluez ce champ pour la propriété de colonne commune dans le fichier de l'objet auquel la propriété n'appartient ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), ". Par exemple, si vous associez des contacts et des entreprises dans deux fichiers avec la propriété de contact ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Adresse e-mail"
        }), " comme colonne commune, incluez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "foreignKeyType"
        }), " pour la colonne ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Adresse e-mail"
        }), " dans le fichier de l'", (0, _jsxRuntime.jsx)("u", {
          children: "entreprise"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "associationIdentifierColumn"
        }), " : pour les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "imports multi-fichiers"
        }), " uniquement, indique la propriété utilisée dans la colonne commune pour associer les fiches d'informations. Incluez ce champ pour la propriété de colonne commune dans le fichier de l'objet auquel la propriété appartient. Par exemple, si vous associez des contacts et des entreprises dans deux fichiers avec la propriété de contact ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Adresse e-mail"
        }), " comme colonne commune, définissez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationIdentifierColumn"
        }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " pour la colonne ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-mail dans le"
        }), " fichier de ", (0, _jsxRuntime.jsx)("u", {
          children: "contact"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Importer un fichier avec un type d'objet"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Voici un exemple de corps de demande pour l'import d'un fichier pour créer des contacts :"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"Nov-event-leads.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "# This example a local file named 'test_import.csv'\n# This file contains a list of contact records to import.\n\nimport requests\nimport json\nimport os\n\nurl = \"https://api.hubapi.com/crm/v3/imports\"\n\nYOUR_ACCESS_TOKEN = 'xxxxxxx';\n\n# Content-Type header will be set automatically by the requests library\nheaders = {\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\ndata = {\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"Nov-event-leads.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": True,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\n\ndatastring = json.dumps(data)\n\npayload = {\"importRequest\": datastring}\n\ncurrent_dir = os.path.dirname(__file__)\nrelative_path = \"./test_import.csv\"\n\nabsolute_file_path = os.path.join(current_dir, relative_path)\n\nfiles = [\n    ('files', open(absolute_file_path, 'rb'))\n]\nprint(files)\n\nresponse = requests.request(\"POST\", url, data=payload, files=files, headers=headers)\n\nprint(response.text.encode('utf8'))\nprint(response.status_code)\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "# Using this endpoint requires using sending multi-part form encoded data. Here is an example curl request:\n# importing a file named import_file.csv\n\n# create a variable for the importRequest JSON\nmyJSON=$(cat <<EOF\n{\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"import_file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\nEOF\n)\n\nYOUR_ACCESS_TOKEN=\"xxx-xxx-xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx\"\n\ncurl -v \\\n  -F \"files=@import_file.csv;type=text/csv\" \\\n  -F \"importRequest=$myJSON;type=application/json\" \\\n  -H \"Authorization: Bearer $YOUR_ACCESS_TOKEN\" \\\n  https://api.hubapi.com/crm/v3/imports\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Importer un fichier avec plusieurs objets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous un exemple de corps de demande d'import et d'association de contacts et d'entreprises dans un fichier avec des libellés d'associations :"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"Association Label Import\",\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"association label example.xlsx\",\n      \"fileFormat\": \"SPREADSHEET\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Domain\",\n            \"propertyName\": \"domain\"\n          },\n          {\n            \"columnName\": \"Association Label\",\n            \"columnType\": \"FLEXIBLE_ASSOCIATION_LABEL\",\n            \"columnObjectTypeId\": \"0-1\",\n            \"toColumnObjectTypeId\": \"0-2\"\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Importer plusieurs fichiers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez ci-dessous un exemple de corps de demande d'import et d'association de contacts et d'entreprises dans deux fichiers, où la propriété de contact ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Adresse e-mail"
      }), " est la colonne commune dans les fichiers :"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"Contact Company import\",\n  \"dateFormat\": \"YEAR_MONTH_DAY\",\n  \"files\": [\n    {\n      \"fileName\": \"contact-import-file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"associationIdentifierColumn\": true\n          }\n        ]\n      }\n    },\n    {\n      \"fileName\": \"company-import-file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Company name\",\n            \"propertyName\": \"name\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Company domain name\",\n            \"propertyName\": \"domain\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"toColumnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationTypeId\": 280,\n              \"associationCategory\": \"HUBSPOT_DEFINED\"\n            }\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En cas de succès, la réponse comprendra un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "importId"
      }), " que vous pourrez utiliser pour récupérer ou annuler l'import. Une fois terminé, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/import-and-export/view-and-analyze-previous-imports",
        children: "afficher l'import dans HubSpot"
      }), ", mais les imports effectuées via l'API ne seront pas disponibles en tant qu'option lors du filtrage des fiches d'informations selon l'import dans les vues, les listes, les rapports ou les workflows."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtenir des imports précédents"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer tous les imports depuis votre compte HubSpot, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/"
      }), ". Pour récupérer des informations pour un import spécifique, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous récupérez des imports, des informations sont renvoyées, y compris le nom de l'import, la source, le format de fichier, la langue, le format de date et les mappages de colonnes. L'élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), " de l'import sera également renvoyé et peut être l'un des éléments suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "STARTED"
        }), " : HubSpot reconnaît que l'import existe, mais son traitement n'a pas encore commencé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), " : l'import est en cours de traitement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DONE"
        }), " : l'import est terminé. Tous les objets, activités ou associations ont été mis à jour ou créés."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "FAILED"
        }), " : une erreur n'a pas été détectée lors du démarrage de l'import. L'import n'a pas été terminé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELED"
        }), " : l'utilisateur a annulé l'export alors qu'il se trouvait dans l'un des états ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "STARTED"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DEFERRED"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DEFERRED"
        }), " : le nombre maximal d'imports simultanés (trois) est atteint. L'import commencera une fois que le traitement de l'un des autres sera terminé."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur la pagination et la limitation des résultats dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : Lorsque vous récupérez des imports à l'aide d'un jeton d'accès d'application privée, la réponse inclura uniquement les imports effectués par cette application privée. Les imports effectués dans HubSpot ou via une autre application privée ne seront pas renvoyés."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cancel an import"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To cancel an active import, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}/cancel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View and troubleshoot import errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view errors for a specific import, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}/errors"
      }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/troubleshoot-import-errors",
        children: "common import errors and how to resolve them"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For errors such as ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Incorrect number of columns"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Unable to parse"
      }), " JSON or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "404 text/html is not accepted"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that there is a column header for each column in your file, and that the request body contains a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnMapping"
        }), " entry for each column. The following criteria should be met:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "The column order in the request body and import file should match. If the column order doesn't match, the system will attempt to automatically reorder but may be unsuccessful, resulting in an error when the import is started."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Every column needs to be mapped. If a column is not mapped, the import request may still be successful, but would result in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Incorrect number of columns"
            }), " error when the import is started."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that the file's name and the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fileName"
        }), " field in your request JSON match, and that you've included the file extension in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fileName"
        }), " field. For example, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "import_name.csv."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that your header includes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type"
        }), " with a value of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "multipart/form-data"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "Example POST URL:\nhttps://api.hubapi.com/crm/v3/imports?\n\nExample importRequest JSON data:\nThis example contains 3 columns:\n - First name, mapped to the firstname contact property\n - Email, mapped to the email contact property\n - Company ID, which contains a list of company record IDs\n   that the contact will be assocated with.\n{\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"ignored\": false,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": null,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "# This example a local file named 'final_emails.csv'\n# This file contains a list of contact records to import.\n# Each row in the file contains a column named \"Company ID\" that contains the companyId\n# that the contact should be associated with.\n\nimport requests\nimport json\n\npost_url = 'http://api.hubapi.com/crm/v3/imports?hapikey=4324...5432'\n\nimportRequest = {\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": True,\n        \"columnMappings\": [\n          {\n            \"ignored\": False,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": None,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": None,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          },\n          {\n            \"ignored\": False,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": None,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          },\n          {\n            \"ignored\": False,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": None,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          }\n        ]\n      }\n    }\n  ]\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "<?php\n// This example imports a local file named 'import_file.csv'\n\n    $post_url = \"http://api.hubapi.com/crm/v3/imports?hapikey=1234...5342\";\n\n    $csv_file = new CURLFile('import_file.csv','text/csv');\n\n    $config_json = '{\"name\":\"test_import\",\"files\":[{\"fileName\":\"final_emails.csv\",\"fileImportPage\":{\"hasHeader\":true,\"columnMappings\":[{\"ignored\":false,\"columnName\":\"First Name\",\"idColumnType\":null,\"propertyName\":\"firstname\",\"foreignKeyType\":null,\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false},{\"ignored\":false,\"columnName\":\"Email\",\"idColumnType\":\"HUBSPOT_ALTERNATE_ID\",\"propertyName\":\"email\",\"foreignKeyType\":null,\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false},{\"ignored\":false,\"columnName\":\"Company ID\",\"idColumnType\":\"HUBSPOT_OBJECT_ID\",\"propertyName\":null,\"foreignKeyType\":{\"associationCategory\":\"HUBSPOT_DEFINED\",\"associationTypeId\":1},\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false}]}}]};type=application/json';\n\n    $post_data = array(\n        \"files\" => $csv_file,\n        \"importRequest\" => $config_json\n    );\n\n    $ch = curl_init();\n    @curl_setopt($ch, CURLOPT_POST, true);\n    @curl_setopt($ch, CURLOPT_URL, $post_url);\n    @curl_setopt($ch, CURLOPT_POSTFIELDS, $post_data);\n\n    $response    = @curl_exec($ch); //Log the response from HubSpot as needed.\n    $status_code = @curl_getinfo($ch, CURLINFO_HTTP_CODE); //Log the response status code\n    @curl_close($ch);\n    echo $status_code . \" \" . $response;\n?>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "# Using this endpoint requires using sending multi-part form encoded data. Here is an example curl request:\n# importing a file named import_file.csv\n\n# create a variable for the importRequest JSON\nmyJSON=$(cat <<EOF\n{\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"ignored\": false,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": null,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          }\n        ]\n      }\n    }\n  ]\n}\nEOF\n)\n\ncurl -v \\\n  -F \"files=@import_file.csv;type=text/csv\" \\\n  -F \"importRequest=$myJSON;type=application/json\" \\\n  http://api.hubapi.com/crm/v3/imports?hapikey=4123...4321\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si vous recevez une erreur, vérifiez s'il y a des en-têtes en double, tels que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type"
        }), ". Cela peut se produire si vous utilisez Postman ou s'il est inclus dans l'en-tête de votre script Python. Supprimez le doublon avant de terminer la demande."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites d'utilisation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous utilisez l'API d'import, vous pouvez importer jusqu'à 80 millions de lignes par jour. Cependant, un fichier d'import est limité à 1 048 576 lignes ou 512 Mo, selon la première éventualité."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si votre demande dépasse la limite de lignes ou la taille maximale, HubSpot renverra une erreur HTTP 429. Lorsque vous approchez de ces limites, il est recommandé de diviser votre import en plusieurs demandes."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}