"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611088;
const slug = exports.slug = 'getting-started/slack/developer-slack';
const title = exports.title = 'HubSpot開発者Slackコミュニティーに参加する';
const name = exports.name = ' - Slack登録 - デザイナーおよび開発者';
const metaDescription = exports.metaDescription = 'HubSpotの開発者Slackは、HubSpotプラットフォームを中心とした開発者間のサポートとコラボレーションのために、HubSpotの開発者が集まる専用のコミュニティーです。 ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = '開発者Slackでのコラボレーション';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E9%96%8B%E7%99%BA%E8%80%85slack%E3%82%B3%E3%83%9F%E3%83%A5%E3%83%8B%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E5%AF%BE%E8%B1%A1%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%AF%EF%BC%9F",
  "label": "開発者Slackコミュニティーの対象ユーザーは？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%9F%E3%83%A5%E3%83%8B%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AB%E5%8F%82%E5%8A%A0%E3%81%99%E3%82%8B%E3%81%A8%E4%BD%95%E3%81%8C%E3%81%A7%E3%81%8D%E3%82%8B%E3%82%88%E3%81%86%E3%81%AB%E3%81%AA%E3%82%8A%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "コミュニティーに参加すると何ができるようになりますか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E4%BB%A5%E4%B8%8B%E3%81%8B%E3%82%89%E7%99%BB%E9%8C%B2%E3%81%97%E3%81%A6%E3%80%81%E9%96%8B%E7%99%BA%E8%80%85slack%E3%81%AB%E5%8F%82%E5%8A%A0%E3%81%97%E3%81%BE%E3%81%97%E3%82%87%E3%81%86",
  "label": "以下から登録して、開発者Slackに参加しましょう",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Form
    } = _components;
  if (!Form) _missingMdxReference("Form", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot開発者Slackコミュニティーに参加する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotの開発者Slackは、HubSpotプラットフォームを中心とした開発者間のサポートとコラボレーションのために、HubSpotの開発者が集まる専用のコミュニティーです。2016年に創設された開発者Slackコミュニティーは、世界中の何百人ものアクティブな開発者が集まって知識と専門技術を共有するコミュニティーへと成長しました。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "開発者Slackコミュニティーの対象ユーザーは？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotプラットフォームでの開発を始めたばかりの方でも、経験豊富なエキスパートの方でも、開発者Slackをご利用いただけます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "テンプレート"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "テーマ"
      }), "などを使用して、API、メール開発、ローカル開発から、プラットフォームのさまざまな側面に特化したチャネルを提供しています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コミュニティーに参加すると何ができるようになりますか？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["お互いをサポートし、教え合う開発者の活発なコミュニティーを見つけることができます。これは何よりもまずコミュニティーであり、より多く貢献するほど、より多くを得ることができます。このコミュニティーはグローバルですが、大部分の参加者は西半球に住んでいます。つまり東半球に住んでいる場合、速やかな反応を得られない可能性があります。あなたの投稿が無視されたり見逃されたりしているわけではなく、参加者は寝ている可能性があります。この状況を変えるには、同じ時間帯にいて積極的に他の参加者を支援する存在になることです。そうすることで、コミュニティーは成長し、あなたにとってより有益なものとなります。HubSpotはこのコミュニティーを非常に大切にしており、HubSpotもコミュニティーの一員です。他者に敬意を持って接し、HubSpotの従業員に敬意を持って接します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/code-of-conduct",
        children: "Slackコミュニティーの行動規範をご確認ください"
      }), "。一緒に成長しましょう。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "以下から登録して、開発者Slackに参加しましょう"
    }), "\n", ' ', "\n", (0, _jsxRuntime.jsx)(Form, {
      formId: "7fd756b1-d981-4b81-9caf-8a79a25bffb2",
      responseType: "inline",
      message: `<br/><button target="_blank" href="https://cta-redirect.hubspot.com/cta/redirect/53/18a2bcd4-227f-46b9-b7e4-52dba8955ae5">Enter the Slack community</button>`
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}