"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694138;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions';
const title = exports.title = 'Getting started with serverless functions';
const name = exports.name = 'Getting started with serverless functions';
const metaDescription = exports.metaDescription = 'Create your first serverless function on the HubSpot CMS, and get a response from it.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-create-a-project-folder",
  "label": "1. Create a project folder",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-create-a-functions-folder",
  "label": "2. Create a functions folder",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-create-a-configuration-file",
  "label": "3. Create a configuration file",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-create-a-function-file",
  "label": "4. Create a function file",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-register-your-function-in-your-serverless.json",
  "label": "5. Register your function in your serverless.json",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-test-your-function",
  "label": "6. Test your function",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-create-a-basic-template-calling-your-function",
  "label": "7. Create a basic template calling your function",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-create-a-page-using-the-test-function.html-template",
  "label": "8. Create a page using the test-function.html template",
  "parent": null
}, {
  "depth": 0,
  "id": "debugging-your-serverless-function",
  "label": "Debugging your serverless function",
  "parent": null
}, {
  "depth": 0,
  "id": "what-did-you-do%3F",
  "label": "What did you do?",
  "parent": null
}, {
  "depth": 0,
  "id": "where-to-go-from-here%3F",
  "label": "Where to go from here?",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Getting started with serverless functions"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you're building a serverless function as a part of a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "developer project"
        }), ", visit the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "developer projects serverless function documentation"
        }), " instead. The documentation below is for building serverless functions outside of the developer project platform."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Serverless functions provide a way to write server-side code that interacts with HubSpot and third-party services through ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "APIs"
      }), ". APIs requiring authentication are not safe for the front-end of a website, as your credentials would be exposed."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Serverless functions can act as an intermediary between your front-end and back-end services that require authentication. With serverless functions, developers don’t need to spin up and manage new servers. Serverless functions require less overhead and as a result they are easier to scale as a business grows. We have a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "high level overview of what HubSpot serverless functions are and how they work"
      }), ", we recommend reading through before doing this tutorial."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "This tutorial will guide you through the creation of your first serverless function."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You will create a serverless function folder, set up your configuration folder, create a function and get a response from it."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you should do before taking this tutorial:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Have access to a CMS Hub Enterprise account or a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/standalone-cms-developer",
          children: "CMS Developer Sandbox account"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure you have at least a basic understanding of the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "concept of what a serverless function is"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Become familiar with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot local development tools"
        }), ", as they are required for working with serverless functions."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure you have the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#install-or-upgrade",
          children: "latest version of the HubSpot local development tools"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You should already have the local development tools ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: "authenticated with your HubSpot account"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Create a project folder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Open your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " file, and make sure your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "defaultPortal"
        })
      }), " is set to either your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "CMS Developer Sandbox"
      }), " account or an account with CMS Hub Enterprise."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On your computer, in the folder that contains your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        })
      }), " file, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "create a"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "folder."
      }), " This folder will contain all of our files, both the functions themselves and a template which will use the function."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In your terminal run the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "watch command"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch serverless-tutorial serverless-tutorial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This will cause any edits to this folder to result in uploads to the design manager. Since the folder currently has no content, this command will simply state Watcher is ready and watching."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Create a functions folder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inside of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " folder ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "create a"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "folder."
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "This is similar to custom modules folders which end in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".module"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " serves to communicate that folder contains serverless functions. Files stored in this folder are not publicly accessible."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because this folder is currently empty the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), " command you have running will not create this folder in the Design Manager yet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Create a configuration file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Create a new file in your"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "folder, name it"
      }), " ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "."]
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " is a required file contained within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " folder. It serves as a configuration file for serverless functions. Defining the runtime environment, serverless function version, and available endpoints. For a rundown of everything that gets defined in this file, see our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "serverless reference"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If you created and saved the file empty, you'll receive an error message in your terminal stating you can't upload an empty ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " file. That's okay, to ignore because you're going to add that code and then save it - triggering a new upload that will suceed."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Paste the code below into your serverless.json:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// place this in your serverless.json file, without this comment\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"endpoints\": {}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Save the file."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Keep this file open in your code editor, we will be coming back to it."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), " is running, if you refresh your design manager you will now see your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), " folders and your new ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Create a function file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Create a new file in your"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "my-first-function.functions"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "folder, name it"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "congratulation.js"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This is the actual function file, the file that will execute and perform a task."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Paste in the code below:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n  const functionResponse =\n    \"Congrats! You've just deployed a Serverless Function.\";\n\n  // sendResponse is a callback function you call to send your response.\n  sendResponse({ body: functionResponse, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This serverless function when executed returns a string ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Congrats! You just deployed a Serverless Function.\""
      }), " and a status code of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), ", indicating success."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "In a real world scenario it is likely you might use APIs or perform some calculations you don't want public. You would return the result of those calculations or, a simple status code based on how the success of your API transactions."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You are not done yet, there is no way to execute this function just yet."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Register your function in your serverless.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Open your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " file again. In your file find your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"endpoints\""
      }), " object."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Update the object to look like this:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// update the endpoints object in your serverless.json to reflect this object.\n\"endpoints\": {\n   \"congratulation\": {\n     \"method\": \"GET\",\n     \"file\": \"congratulation.js\"\n   }\n }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The endpoints object contains a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"congratulation\""
      }), " object. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"congratulation\""
      }), " is the endpoint you're creating. The endpoint's name is what defines the path that you will use to call your serverless function."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Serverless functions are exposed through ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "a path at your HubSpot CMS account’s domain."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "These functions can be accessed at:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/<endpoint-name/path>"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the case of this \"congratulation\" endpoint you've created, it will be"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/congratulation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because of this it is generally a good idea to name your endpoint similarly to your function file's name, and both should be named based on the information they act against, not the action taken against that information. You should use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods",
        children: "HTTP method"
      }), " or methods for communicating the type of action you are making against that information. The \"method\" parameter defines the HTTP method's your function supports. It can be a single string or an array of strings denoting the methods the function supports."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Test your function"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The simplest way to test a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to your serverless function is to go to your endpoint's URL directly in the browser.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://your-domain.com/_hcms/api/congratulation",
        children: "https://your-domain.com/_hcms/api/congratulation"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Replacing your-domain.com with your HubSpot site's domain."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You should see ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Congrats! You just deployed a serverless function\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Success, you did it!"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.em, {
        children: "If you do not get that response, start from the top of this tutorial carefully reading each step and verifying the code. It is likely instructions in step 4 or 5 were not followed correctly."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["For more complicated requests it's helpful to use a tool like ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.postman.com/downloads/",
          children: "Postman."
        }), " Postman makes it easier to test and debug APIs. A handy feature for front-end developers is it's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://learning.postman.com/docs/postman/sending-api-requests/generate-code-snippets/",
          children: "code generation"
        }), " which can generate a starting point for your javascript call to your function."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Create a basic template calling your function"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using a new terminal window navigate into your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " folder using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Run the following command in your terminal:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template \"test-function\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This creates a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), " file. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Open this file in your code editor."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Above the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</head>"
      }), " tag ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["add ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<script></script>"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copy the javascript below:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var requestOptions = {\n  method: 'GET',\n  headers: {\n    'Content-Type': 'application/json',\n  },\n};\n\nfetch('https://www.example.com/_hcms/api/congratulation', requestOptions)\n  .then((response) => response.text())\n  .then((result) => console.log(result))\n  .catch((error) => console.log('error', error));\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["HubSpot serverless functions only support the content type ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), ". Leaving it out will result in an \"Unsupported Media Type\" error."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inside of the script tag you created earlier, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "paste your copied JavaScript code"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Change ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "www.example.com"
        }), " to your account's domain."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Save the file."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. Create a page using the test-function.html template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the design manager find your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), " file (you may need to refresh)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Right click the file, choose \"create page\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Name your page \"Test Function\". ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Create page"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Click preview, preview in new window"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inspect the page by ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "right clicking anywhere"
      }), " on the page and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "selecting \"inspect\"."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you did everything correctly you should see in your console the congratulations message."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Congratulations, you've called your serverless function from within a HubSpot CMS page."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "While this tutorial has you call the serverless function at the template level, you can call serverless functions anywhere that you can add JavaScript on your CMS hosted site. The most common place you might do this is within your custom modules."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Debugging your serverless function"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["At this stage your serverless function should work fine. The more complicated your function gets, the harder it is to troubleshoot. Just like the console in your browser is useful for debugging javascript on your front-end, you can get similar logs for your serverless functions using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        })
      }), ". Check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "local development reference"
      }), " for more information on this command."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs logs <endpoint-name> --follow\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "What did you do?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You created a serverless function folder, with a serverless.json configuration file, and function file named ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "congratulation.js"
      }), ". You used \"GET\" to get congratulations text from the serverless function. You used javascript to make a call to your serverless function from a page on the HubSpot CMS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now that you understand how the configuration file, function file, and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " folder relate, the CLI has a handy command you can use to create your functions faster next time."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create function <function name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This function creates a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " folder, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " file and a function file with the names you provide."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Where to go from here?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "High level overview of serverless functions"
        }), " on the HubSpot CMS."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/serverless-functions",
          children: "Serverless Reference"
        }), " breaking down the parts of a serverless function"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/introduction-to-hubspot-apis",
          children: "Introduction to HubSpot APIs academy course."
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "Continuous integration with GitHub"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}