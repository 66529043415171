"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890253;
const slug = exports.slug = 'guides/cms/content/performance/speed';
const title = exports.title = 'Otimizando o seu site do CMS Hub para velocidade';
const name = exports.name = 'PT-BR Optimizing your CMS Hub site for Speed - original';
const metaDescription = exports.metaDescription = 'Dicas e estratégias para melhorar as velocidades de carregamento e renderização do seu site. Acelere o carregamento do seu tema ou site do CMS Hub e veja o que a HubSpot já faz por você.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "comece-com-uma-base-s%C3%B3lida",
  "label": "Comece com uma base sólida",
  "parent": null
}, {
  "depth": 0,
  "id": "obst%C3%A1culos-comuns-ao-desempenho-do-site",
  "label": "Obstáculos comuns ao desempenho do site",
  "parent": null
}, {
  "depth": 1,
  "id": "imagens",
  "label": "Imagens",
  "parent": "obst%C3%A1culos-comuns-ao-desempenho-do-site"
}, {
  "depth": 2,
  "id": "o-que-voc%C3%AA-pode-fazer",
  "label": "O que você pode fazer:",
  "parent": "obst%C3%A1culos-comuns-ao-desempenho-do-site"
}, {
  "depth": 1,
  "id": "reprodu%C3%A7%C3%A3o-autom%C3%A1tica-de-v%C3%ADdeos",
  "label": "Reprodução automática de vídeos",
  "parent": "obst%C3%A1culos-comuns-ao-desempenho-do-site"
}, {
  "depth": 2,
  "id": "o-que-voc%C3%AA-pode-fazer",
  "label": "O que você pode fazer:",
  "parent": "obst%C3%A1culos-comuns-ao-desempenho-do-site"
}, {
  "depth": 1,
  "id": "javascript",
  "label": "JavaScript",
  "parent": "obst%C3%A1culos-comuns-ao-desempenho-do-site"
}, {
  "depth": 2,
  "id": "o-que-voc%C3%AA-pode-fazer",
  "label": "O que você pode fazer:",
  "parent": "obst%C3%A1culos-comuns-ao-desempenho-do-site"
}, {
  "depth": 0,
  "id": "ferramenta-de-recomenda%C3%A7%C3%B5es",
  "label": "Ferramenta de Recomendações",
  "parent": null
}, {
  "depth": 0,
  "id": "alertas-codificados",
  "label": "Alertas codificados",
  "parent": null
}, {
  "depth": 0,
  "id": "o-que-o-hubspot-trata-para-voc%C3%AA%3F",
  "label": "O que o HubSpot trata para você?",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-adicionais-para-melhorar-a-velocidade-do-seu-site",
  "label": "Recursos adicionais para melhorar a velocidade do seu site",
  "parent": null
}, {
  "depth": 1,
  "id": "ferramentas-de-desempenho",
  "label": "Ferramentas de desempenho",
  "parent": "recursos-adicionais-para-melhorar-a-velocidade-do-seu-site"
}, {
  "depth": 2,
  "id": "otimiza%C3%A7%C3%A3o-de-imagem",
  "label": "Otimização de imagem",
  "parent": "recursos-adicionais-para-melhorar-a-velocidade-do-seu-site"
}, {
  "depth": 2,
  "id": "teste-de-desempenho",
  "label": "Teste de desempenho",
  "parent": "recursos-adicionais-para-melhorar-a-velocidade-do-seu-site"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      ol: "ol",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Otimizar o seu site do CMS Hub para desempenho"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O que determina uma excelente experiência do usuário é a qualidade do conteúdo, a velocidade, a segurança e a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "acessibilidade"
      }), ". Fazer essa otimização normalmente também melhora a otimização do mecanismo de pesquisa (SEO)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um desempenho melhor tem a ver com oferecer uma experiência melhor para os usuários finais. Ter uma performance melhor tem a ver com resolver os gargalos do seu site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comece com uma base sólida"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "É mais fácil criar partindo de uma base sólida, que foi construída com o desempenho em mente, do que tentar corrigir problemas de desempenho mais tarde. Uma metáfora para isso seria: é mais fácil construir um carro veloz do zero do que comprar um carro lento e tentar torná-lo veloz."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "HubSpot CMS Boilerplate"
      }), " foi desenvolvido para ser rápido e incentivar as práticas recomendadas. Consulte o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "LEIAME do GitHub"
      }), " para ver as pontuações atuais no lighthouse e no website grader."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao construir a partir do boilerplate, você sabe que, tirando o código adicionado por você, ele sempre atingirá essas pontuações. Isso significa que você pode focar sua atenção no código que adicionou com base no boilerplate."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "bdf71810-13e6-494f-8dbd-f733d5451ad5",
      label: "Build a site based on boilerplate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obstáculos comuns ao desempenho do site"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A maioria das técnicas e práticas recomendadas de otimização do desempenho da web não são específicas da HubSpot. Isso porque a maioria dos gargalos de desempenho do site não são específicos da HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A maioria dos problemas de desempenho se enquadra em duas categorias: renderização e carregamento."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "desempenho de carregamento"
        }), " é a eficiência com que todos os arquivos necessários são transferidos da página web para o navegador do usuário."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "desempenho de renderização"
        }), " é a eficiência com que o navegador consegue pegar tudo o que baixou e exibir o resultado final para o usuário."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O desempenho de carregamento é um pouco mais fácil de compreender — a quantidade de arquivos, o tamanho dos arquivos e a velocidade de entrega desses arquivos determina o desempenho de carregamento. Já o desempenho de renderização é mais complicado. Folhas de estilo em cascata ", (0, _jsxRuntime.jsx)("abbr", {
        children: "(CSS"
      }), "), JavaScript ", (0, _jsxRuntime.jsx)("abbr", {
        children: "(JS"
      }), "), imagens, vídeos, o dispositivo que o visitante está usando, o navegador web que está sendo usado etc. são fatores significativos. O CSS pode causar bloqueio de renderização. Um CSS mal escrito pode causar mudança cumulativa de layout ", (0, _jsxRuntime.jsx)("abbr", {
        children: "(CLS"
      }), ") durante a renderização da página. As imagens podem causar CLS e sobrecarregar a RAM. Os reprodutores de vídeo podem causar CLS; alguns formatos de arquivo exigem mais trabalho de processamento. O JS pode manipular o ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Glossary/DOM",
        children: ["Modelo de Objeto de Documento (", (0, _jsxRuntime.jsx)("abbr", {
          children: "DOM"
        })]
      }), " ) e o ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Glossary/CSSOM",
        children: ["Modelo de Objeto de Folhas de Estilo em Cascata (", (0, _jsxRuntime.jsx)("abbr", {
          children: "CSSOM"
        })]
      }), " ) de uma página, causando qualquer um desses problemas. O JS também pode fazer uso intensivo de recursos. Todos estes fatores precisam de ser equilibrados e as práticas recomendadas precisam ser seguidas para garantir uma experiência rápida para todos os visitantes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Imagens"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As imagens são predominantes em quase todas as páginas da web. As imagens geralmente são os maiores arquivos de uma página. Quanto mais imagens você tiver, e quanto maiores elas forem, mais tempo sua página levará para carregar. Imagens animadas, como gifs e webps animados, também ocupam mais espaço do que imagens não animadas do mesmo tamanho. Alguns formatos de imagem também são mais eficientes do que outros — e melhores para determinados cenários."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "O que você pode fazer:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A coisa mais importante que você pode fazer é ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/compress-image",
          children: "otimizar suas imagens"
        }), " para a web. A otimização de imagens é uma responsabilidade compartilhada entre os desenvolvedores e criadores de conteúdo."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use menos imagens por página."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/insiders/different-types-of-image-files",
          children: "Use o formato de imagem adequado para o caso de uso"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use Scalable Vector Graphics (SVGs) sempre que possível. Os SVGs podem ser redimensionados infinitamente sem perder a qualidade. Colocar os SVGs em linha faz sentido ao trabalhar com animações. Em gráficos estáticos, a criação de uma sprite sheet SVG, ou simplesmente tratá-la como um elemento img normal ou imagem de fundo, otimiza o desempenho."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/lazy-loading",
          children: "carregamento lento de imagens"
        }), " de forma inteligente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Certifique-se de que os elementos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "img"
        }), " contenham atributos HTML de altura e largura. Isso facilita a navegação nos navegadores durante o tempo de renderização e também faz com que o HubSpot possa adicionar de forma inteligente um ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "srcset"
        }), " para você. O HubSpot não só ajuda a optimizar, como também os navegadores web podem otimizar de forma inteligente a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/cls/",
          children: "mudança cumulativa de layout"
        }), " quando você fornece uma largura e altura."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a propriedade ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio",
          children: "CSS aspect-ratio"
        }), " para reservar espaço quando as dimensões das imagens podem mudar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#resize-image-url",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "resize_image_url"
          })
        }), " para forçar o redimensionamento das imagens para uma determinada resolução."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para imagens de fundo, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/optimize-css-background-images-with-media-queries/",
          children: "use consultas de mídia"
        }), " em combinação com ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url"
        }), " para fornecer imagens em tamanhos que façam sentido para o dispositivo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para grandes hero images, você pode pré-carregá-las usando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"preload\" as=\"image\" href=\"http://example.com/img_url.jpg\">"
        }), " dentro de uma tag ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "require_head"
          }), "."]
        }), " Use essa técnica com moderação; o uso excessivo pode prejudicar o desempenho."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Reprodução automática de vídeos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fundos de vídeo e vídeos com reprodução automática podem ser um diferencial em um site. Mas infelizmente, eles têm um custo. Os fundos de vídeo são frequentemente utilizados no cabeçalho dos sites. Quando um vídeo é reproduzido automaticamente, isso significa que o navegador precisa começar a carregar o vídeo de imediato. Isso pode ser especialmente problemático para usuários com conexões mais lentas ou que usam dados móveis."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "O que você pode fazer:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Evite usar o vídeo com reprodução automática. Se o que você está mostrando é uma animação de fundo, considere usar animações em CSS ou javascript. Se você precisa exibir um vídeo com reprodução automática:"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Escolha uma resolução razoável para o vídeo com base no seu caso de uso, e aplique um efeito sobre o vídeo para tornar uma resolução mais baixa menos perceptível."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Certifique-se de que a qualidade do vídeo seja ajustada com base no dispositivo e na conexão. A melhor maneira de fazer isso é usando um serviço de compartilhamento/hospedagem de vídeo, como YouTube, Vidyard ou Vimeo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Desative a reprodução automática em dispositivos móveis; exiba uma imagem de fallback em vez disso."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O JavaScript ", (0, _jsxRuntime.jsx)("abbr", {
        children: "(JS"
      }), ") é útil para adicionar interatividade ao seu site. O carregamento de muito código JS, em geral, aumenta o tamanho dos arquivos JS e o tempo que o navegador leva para renderizar os elementos interativos. Carregar JS no ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " também pode ser um problema, pois trata-se de um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/render-blocking-resources/",
        children: "recurso de bloqueio de renderização"
      }), " por padrão. Além disso, o JS é executado no dispositivo dos visitantes, o que significa que está limitado aos recursos do próprio dispositivo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "O que você pode fazer:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Quando o CMS do HubSpot foi lançado, o jQuery era carregado por padrão em ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), " . Você pode removê-lo totalmente em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configurações > Site > Páginas,"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/upgrade-jquery",
          children: "atualize para a versão mais recente do jQuery"
        }), ". Tenha cuidado ao alterar essas configurações em sites mais antigos desenvolvidos por terceiros. Eles podem ter sido construídos com base em jQuery ou com base no carregamento de jQuery no cabeçalho."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Certifique-se de que o javascript seja carregado antes de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "</body>"
        }), " para evitar o bloqueio da renderização. Você pode usar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " para carregar js para módulos ou modelos somente quando necessário e sem carregar acidentalmente o javascript várias vezes para várias instâncias de um módulo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Considere em reescrever seu JS para ser mais eficiente. Use menos plugins JS; use HTML semântico quando possível. Por exemplo, para menus suspenso, use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<details>"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<summary>"
        }), ". Para os modais, use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<dialog>"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se você tiver uma biblioteca JS gigantesca, mas usar apenas alguns pequenos recursos, considere usar o vanilla JS ou carregar um subconjunto da biblioteca, se possível."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js"
        }), " para carregar o JS somente quando necessário e apenas uma vez por página. Ao usar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), ", use atributos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "async"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "defer"
        }), " para melhorar o desempenho da página."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para controlar onde e quando o JavaScript de um módulo é carregado, use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "js_render_options"
        }), " no arquivo meta.json do módulo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao carregar recursos externos, use comandos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/preconnect-and-dns-prefetch/",
          children: "preconnect e dns-prefetch"
        }), " apropriadamente para proporcionar uma experiência mais rápida."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Limite o número de scripts de rastreamento em uso. Os scripts de rastreamento tentam compreender todas as ações que um usuário está realizando em uma página para fornecer insights. É muito código para analisar o que o usuário está fazendo. Cada scripts de rastreamento amplifica isso."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ferramenta de Recomendações"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A ferramenta de Recomendações da HubSpot é uma ótima maneira de obter feedback sobre desempenho e SEO específico para o seu site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/seo/view-seo-recommendations-in-hubspot",
        children: "Saiba mais sobre a ferramenta de recomendações"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alertas codificados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os alertas de código são um recurso do CMS Hub Enterprise que atua como uma visão geral centralizada dos problemas que são identificados dentro do seu site do HubSpot CMS. A correção dos problemas identificados nos Alertas de código pode ajudar a otimizar o desempenho do seu site. Os problemas identificados compreendem várias áreas diferentes, desde os limites do HubL até problemas de CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "Saiba mais sobre Alertas de código."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "O que o HubSpot trata para você?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A maioria das práticas recomendadas de desempenho/velocidade não são específicas do HubSpot. O CMS do HubSpot trata automaticamente de muitos problemas de desempenho comuns para você. Essas otimizações incluem:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "CDN com otimização de imagem e conversão automática de WebP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "HTTP2"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Minificação de Javascript"
        }), " e CSS"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Cache de navegador e servidor"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "Pré-renderização"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Reescrita de domínio"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#text-compression",
          children: "Compressão Brotli (com fallback para compressão GZIP)"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#accelerated-mobile-pages-amp-",
          children: "Posts de blog da HubSpot suportam AMP"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao incluir CSS em um módulo personalizado, o HubSpot carrega o module.css de forma inteligente apenas quando um módulo é usado em uma página, e só o carrega uma vez, independentemente do número de instâncias do módulo que existem na página. Por padrão, module.css não carrega de forma assíncrona, mas você pode alterar isso incluindo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options"
      }), " no arquivo meta.json do módulo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos adicionais para melhorar a velocidade do seu site"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há muita coisa que pode ser feita para otimizar um site para velocidade e muitos dos tópicos exigem mais detalhes. Compilamos alguns recursos fantásticos e incentivamos você a consultá-los ao trabalhar na otimização do seu site."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/site-speed-and-performance-what-we-do-and-what-you-can-do",
          children: "Velocidade e desempenho do site: o que você pode fazer, e como o HubSpot ajuda"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-we-improved-page-speed-on-hubspot.com",
          children: "Como melhoramos a velocidade da página no HubSpot.com"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/15-tips-to-speed-up-your-website",
          children: "Quinze dicas para acelerar o seu site"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/how-to-reduce-your-websites-page-speed",
          children: "Cinco maneiras fáceis de ajudar a reduzir o tempo de carregamento da sua página da web"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/google-page-speed",
          children: "Guia de oito passos para alcançar 100% de velocidade de página do Google"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/website-optimization",
          children: "Otimização do site - HubSpot Academy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/learn/",
          children: "Web.dev"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "Como otimizamos o HubSpot CMS - Jeff Boulter"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.smashingmagazine.com/2021/04/humble-img-element-core-web-vitals/",
          children: "O humilde elemento img e os Core Web Vitals - Smashing Magazine"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ferramentas de desempenho"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existem muitas ferramentas que podem ser usadas para melhorar o desempenho e testá-lo. É útil compreender o que são essas ferramentas e como usá-las. Esta lista não é exaustiva e foi fornecida pela nossa comunidade de desenvolvedores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Otimização de imagem"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Otimizar suas imagens para a web antes de carregá-las e exibi-las ajuda a garantir que você não apresentará uma imagem superdimensionada na tela e para um caso de uso específico."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ferramentas populares de otimização de imagens:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imageoptim.com/mac",
          children: "ImageOptim"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/photoshop.html",
          children: "Adobe Photoshop"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/illustrator.html",
          children: "Adobe Illustrator"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imagecompressor.com/",
          children: "Optimizilla"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Teste de desempenho"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O teste de desempenho e a otimização devem ser processos separados da criação de um site. Há uma infinidade de ferramentas disponíveis para testar a velocidade de um site. É importante compreender essas ferramentas e como elas funcionam, para que você possa tomar decisões informadas sobre melhorias de desempenho."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Algumas ferramentas medem de forma rigorosa, outras classificam. É importante compreender como isso realmente funciona."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ferramentas que medem"
      }), " geralmente testam o tempo de carregamento, o tempo de execução de scripts, o tempo até a primeira exibição de conteúdo, o tempo de rede para download de ativos etc. Essas ferramentas geralmente fornecem resultados que declaram tempos específicos para cada uma dessas métricas. Se você testar novamente, as medidas mudarão ligeiramente porque nem todas as páginas são exatamente idênticas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ferramentas que classificam"
      }), " fazem mais do que medir velocidades, elas informa se seu desempenho é bom ou ruim, muitas vezes usando uma porcentagem ou escala de letras. Essa escala deve ser usada como uma ferramenta para motivar os desenvolvedores e profissionais de marketing a melhorar. Há tantas métricas e aspectos diferentes de desempenho que precisam ser levados em consideração. Você não pode simplesmente basear o seu desempenho geral em uma métrica, além de que algumas métricas podem ter diferentes níveis de efeito sobre o desempenho percebido. Portanto, estas ferramentas ponderam as métricas de forma diferente para calcular o desempenho global. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Não existe um padrão do setor para ponderar as métricas."
      }), " Com o tempo, essa ponderação pode mudar, como ocorreu com o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://googlechrome.github.io/lighthouse/scorecalc/",
        children: "Google Page Speed"
      }), ". Também não há nenhum padrão do setor aceito para o que é considerado o valor mínimo ou máximo ideal para métricas individuais. Algumas ferramentas se baseiam em um percentil de sites que foram testados. O que significa que os seus resultados são comparados com outros sites que foram testados. O resultado é que a faixa de velocidade \"bom/excelente\" se torna cada vez mais difícil de ser alcançada com o tempo. Algumas ferramentas usam a experiência do usuário, a retenção de visitantes e a pesquisa baseada em ROI para determinar qual deve ser o limiar para uma boa pontuação. Tenha em mente também que nem todas as ferramentas consideram o desempenho de carregamento de página subsequente. Por exemplo, o sistema de módulos do HubSpot separa o css e o JS para módulos individuais, e só carrega esses ativos quando o módulo é realmente colocado na página. Isso pode resultar em vários arquivos css menores, que podem ser sinalizados pelo Google Page Speed Insights. A realidade, no entanto, é que você não precisará baixar nenhum css ou js para nenhum módulo que se repita na próxima página, eles serão armazenados no cache. Em vez disso, você só precisará baixar os arquivos para os módulos que ainda não exibiu, e isso representa kilobytes, em vez de um arquivo monolítico."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com relação às ferramentas de avaliação, você deve usar várias delas e esforçar-se para obter a melhor pontuação possível em cada uma delas. Porém, saiba que elas avaliarão as coisas de forma diferente. Esforços que podem melhorar uma pontuação em uma ferramenta podem não melhorá-la em outras."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ferramentas de teste de desempenho populares:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://website.grader.com/",
          children: "Website Grader"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://gtmetrix.com/",
          children: "GTMetrix"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/speed/pagespeed/insights/",
          children: "Google Page Speed Insights"
        }), " e outras ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/web/fundamentals/performance/speed-tools",
          children: "ferramentas de desempenho do Google"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.pingdom.com/",
          children: "Pingdom"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.webpagetest.org/",
          children: "WebPageTest"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Documentos relacionados:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/performance-scoring/",
          children: "Como funciona a pontuação de desempenho do Lighthouse"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "Roteiro de otimização de sites (Core Web Vitals) | Mark Ryba"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}