"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529741;
const slug = exports.slug = 'guides/cms/debugging/code-alerts';
const title = exports.title = 'Alertes de code';
const name = exports.name = 'EMEA FR Code Alerts';
const metaDescription = exports.metaDescription = 'Les alertes de code vous fournissent une liste générale des problèmes identifiés dans les pages de votre CMS concernant vos CSS, modules ou modèles.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "comment-consulter-les-alertes-de-code",
  "label": "Comment consulter les alertes de code",
  "parent": null
}, {
  "depth": 0,
  "id": "types-d-%C3%A9l%C3%A9ments-et-probl%C3%A8mes",
  "label": "Types d'éléments et problèmes",
  "parent": null
}, {
  "depth": 1,
  "id": "types-d-%C3%A9l%C3%A9ments",
  "label": "Types d'éléments",
  "parent": "types-d-%C3%A9l%C3%A9ments-et-probl%C3%A8mes"
}, {
  "depth": 1,
  "id": "probl%C3%A8mes",
  "label": "Problèmes",
  "parent": "types-d-%C3%A9l%C3%A9ments-et-probl%C3%A8mes"
}, {
  "depth": 0,
  "id": "visualiser-les-probl%C3%A8mes-identifi%C3%A9s-pour-vos-%C3%A9l%C3%A9ments",
  "label": "Visualiser les problèmes identifiés pour vos éléments",
  "parent": null
}, {
  "depth": 1,
  "id": "mod%C3%A8les%2C-pages-et-modules",
  "label": "Modèles, pages et modules",
  "parent": "visualiser-les-probl%C3%A8mes-identifi%C3%A9s-pour-vos-%C3%A9l%C3%A9ments"
}, {
  "depth": 1,
  "id": "css",
  "label": "CSS",
  "parent": "visualiser-les-probl%C3%A8mes-identifi%C3%A9s-pour-vos-%C3%A9l%C3%A9ments"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Alertes de code"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les alertes de code offrent un espace centralisé pour que les développeurs et les responsables informatiques puissent consulter une liste générale des problèmes identifiés dans votre CMS Hub. En corrigeant les problèmes identifiés dans les alertes de code, vous pouvez contribuer à l'optimisation de votre site web en améliorant l'expérience de vos clients et les performances globales de votre site."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour découvrir d'autres façons dont HubSpot vous aide à maximiser le potentiel de votre site, consultez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "notre page de référence sur le réseau de diffusion de contenu, la sécurité et les performances."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alerts-dashboard.jpg",
        alt: "Tableau de bord pour les alertes de code"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comment consulter les alertes de code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Visualisez vos alertes de code pour l'ensemble de votre portail en cliquant sur le logo HubSpot à partir de n'importe quelle page publiée du CMS sur laquelle vous êtes authentifié et en choisissant ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Afficher les alertes de code"
      }), " ou accédez directement à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/code-alerts",
        children: "Alertes de code"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/view-code-alerts-menu.png",
        alt: "Afficher les alertes de code à partir du menu de logo HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types d'éléments et problèmes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Types d'éléments"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe plusieurs types d'éléments que les alertes de code peuvent analyser dans votre site web CMS Hub. Ils sont énumérés ci-dessous."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type d'élément"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Blog Post"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un article de l'un de vos blogs HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Site Page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une page de site web sur le CMS Hub."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Landing Page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une page web avec un objectif spécifique (l'objectif d'une page de destination est de convertir les visiteurs en leads)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La page de listing de votre blog HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Module"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Des composants réutilisables qui peuvent être utilisés dans des modèles ou ajoutés à des pages."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Template"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modèles sont des encadreurs de pages ou d'e-mails réutilisables qui placent généralement des modules et des partials dans une mise en page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un fichier de feuille de style en cascade."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Knowledge Article"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un article de votre base de connaissances HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Unknown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lorsqu'un type d'élément est inconnu."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Problèmes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe plusieurs types de problèmes associés à vos éléments. Si votre élément présente plusieurs problèmes, il sera répertorié plusieurs fois dans votre tableau de bord des alertes de code."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Problème"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemple"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HubL limit"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The HubL function ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "blog_recent_tag_posts"
            }), " is used ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "11"
            }), " times on this page. ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "blog_recent_tag_posts"
            }), " has a limit of ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "5"
            }), " uses per page."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/not-found",
              children: "Certaines fonctions de HubL sont limitées"
            }), " dans leur utilisation. Si votre fonction dépasse ses limites, ce problème vous sera présenté."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSS combining"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There is a syntax error in a line of code that is preventing the CSS files from being combined."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Identifie les fichiers CSS qui contiennent des problèmes susceptibles d'empêcher la minification/combinaison du fichier. Il existe également une option permettant d'", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "afficher les erreurs de syntaxe"
            }), " liées à cet élément."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Output too big"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This page is ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "10446 Kilobytes"
            }), ". The size limit is ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "9765 Kilobytes"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le HTML généré pour la page a dépassé la limite. Cela peut entraîner l'affichage d'une page vierge ou partielle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Template error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There is an error in the code that is preventing this template from being rendered."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Identifie les erreurs dans le code de votre modèle qui empêchent sa restitution."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Unknown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unknown issue"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lorsque le système a du mal à identifier l'erreur dans l'élément, un problème inconnu est attribué."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visualiser les problèmes identifiés pour vos éléments"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les alertes de code comprennent des liens profonds vers les éléments pour lesquels des alertes sont détectées. Lorsque vous passez le curseur sur une ligne, les options suivantes vous sont proposées pour chaque type d'élément."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modèles, pages et modules"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le bouton d'action vous fournira un lien pour ouvrir le modèle ou le module correspondant dans le gestionnaire de conception."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/my-template-open-in-dm.jpg",
        alt: "Problèmes relatifs aux modèles et aux modules"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CSS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le bouton d'action vous fournira un lien pour ouvrir la feuille de style correspondante dans le gestionnaire de conception, afficher votre fichier avec des informations de débogage ou afficher les erreurs de syntaxe."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alerts-css-options.jpg",
        alt: "Problèmes relatifs aux CSS"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous choisissez Afficher l'erreur de syntaxe, une fenêtre modale s'affiche avec des détails supplémentaires sur l'erreur de syntaxe ainsi que le numéro de la ligne où se trouve l'erreur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/code-alerts/code-alert-syntax-error-details.png",
        alt: "Fenêtre modale des détails de l'erreur de syntaxe"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}