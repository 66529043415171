"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45824362289;
const slug = exports.slug = 'reference/cms/hubl/quote-variables';
const title = exports.title = 'Quote template variables';
const name = exports.name = '[developers] Quote template variables';
const metaDescription = exports.metaDescription = 'Use HubL to access and display CRM data within your Custom Quote Template. The template_data object holds most of the data associated to the quote. For anything not found there you can either associated the object record to the quote, or use the HubL CRM_object functions.';
const position = exports.position = 8;
const badge = exports.badge = 'BETA';
const toc = exports.toc = [{
  "depth": 0,
  "id": "template_data-object",
  "label": "Template_data object",
  "parent": null
}, {
  "depth": 0,
  "id": "quote-variables",
  "label": "Quote variables",
  "parent": null
}, {
  "depth": 0,
  "id": "associated-objects",
  "label": "Associated objects",
  "parent": null
}, {
  "depth": 0,
  "id": "custom-objects",
  "label": "Custom Objects",
  "parent": null
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related Resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      a: "a",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Quote template variables"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Custom quote templates can access quote data and some associated objects directly from the templates. The data available depends on data you have in your CRM, as well as data added to the quote itself."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While developing a quote template, you can use HubSpot-provided mock data to populate the template, which may help for previewing the template. In the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot"
      }), " folder, navigate to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cms-quotes-theme"
      }), " folder. Within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templates"
      }), " folder, view the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "basic.html"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modern.html"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "original.html"
      }), " templates. These templates contain the following code block at the top:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% from \"../imports/mock_data.html\" import SAMPLE_TEMPLATE_DATA as mock_data %}\n{% from \"../imports/module_defaults.html\" import MODULE_DEFAULTS as module_defaults %}\n{% set QUOTE = template_data.quote || mock_data.quote %}\n{% set CURRENCY = QUOTE.hs_currency || \"USD\" %}\n{% set LOCALE = QUOTE.hs_locale || \"en-US\" %}\n{% set ASSOCIATED_OBJECTS = QUOTE.associated_objects %}\n{% set LINE_ITEMS = ASSOCIATED_OBJECTS.line_items %}\n{% set ADDITIONAL_FEES = ASSOCIATED_OBJECTS.additional_fees %}\n{% set TOTALS = ASSOCIATED_OBJECTS.totals || ASSOCIATED_OBJECTS.totals %}\n{% set QUOTE_TOTAL = TOTALS.total %}\n{% set SUB_TOTALS = TOTALS.subtotals %}\n{% set DEAL = ASSOCIATED_OBJECTS.deal %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The mock data is first imported from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mock_data.html"
      }), " file, then is set to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " variable to use the data found in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " if available. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " variable is also used to populate the other variables in this list, such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ASSOCIATED_OBJECTS"
      }), ", to make accessing that data less verbose. However, you can structure your data differently, depending on your preferences."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the above code, you'll notice that ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " is also used to set the main ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " variable. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " is an object containing all of the actual data for the quote and deal in the page. If that object is not found in the template, HubSpot loads the data from ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mock_data.html"
      }), " instead."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Template_data object"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The vast majority of the data can be directly accessed through the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " object. You can use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ template_data|pprint }}"
      }), " in your template to see the full object provided."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A dict containing the quote, quote.associated_objects, and totals dicts."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quote variables"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The information specific to this individual quote."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict containing all of the data for the quote itself."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.associated_objects.deal.hs_object_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal Id"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal owner id"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_all_owner_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "integer or array of integers"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal owner ids"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_created_by_user_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "User that created the quote."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_lastmodifieddate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date the quote was last modified. In epoch format."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hubspot_owner_assigneddate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date the quote was assigned an owner. In epoch format."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_createdate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date and time the quote was created. In epoch format."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_expiration_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date quote expires. In epoch format."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quote Title"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_template_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "\"CUSTOMIZABLE_QUOTE_TEMPLATE\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL slug for quote web page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_proposal_template_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Developer file system path to template. (includes file extension)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_quote_amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Amount of money"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Currency the quote amount is in in 3 character ISO 4217 currency code.\"USD\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Language code\"en\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_locale"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Locale code\"en-us\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_terms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Terms text provided by quote creator"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "First name of the person sending the quote."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Company name of the person sending the quote"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_company_image_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Company logo for the person sending the quote."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Status of the quote.\"APPROVAL_NOT_NEEDED\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_primary_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string/hex color code"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "\"#425b76\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_quote_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unique quote id number."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_payment_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Use to test if payment fields need to be shown."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_esign_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Use to test if esignature fields need to be shown."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Can't find a variable you're looking for?"
        }), " There are more variables you can access within ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "template_data"
        }), ". Use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|pprint"
        }), " to view them. Additionally some variables in quote associations may only be available based on the quote/deal."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "We will be iterating on this documentation to showcase and explain more of the data you have access to. Aside from pretty printing, you can view the mock data file within the cms-quote-theme, to see what is available and the structure it comes in."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associated objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In a quote template, you can access data from a quote's associated records, such as deals or companies, by using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associated_objects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, you can add the logo from the quote recipient's associated company record to a quote by using the following code:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set company_avatar_url = template_data.quote.associated_objects.company.hs_avatar_filemanager_key %}\n{% if company_avatar_url %}\n  <img src=\"{{ template_data.quote.associated_objects.company.hs_avatar_filemanager_key }}\" width=\"400\" alt=\"{{ template_data.quote.associated_objects.company.name }}\">\n{% else %}\n  <!-- company does not have an assigned image-->\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " only manually set logos will appear. Automatically detected logos will not appear to prevent unintentional logos from appearing on the quote template."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The above code first sets a variable that searches for the quote's associated company's logo. Then, using an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " statement, the template displays that logo, if available. If no logo has been manually set for the company, no logo is displayed."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Custom Objects"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Custom object data can be displayed or used within a quote in a couple different ways. Because each custom object's structure may vary, you'll need to get specific properties based on how you've structured your custom object."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The quote ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " by default has custom associated objects in it. For example, custom objects associated with deals are included."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To access them, you can use the following code:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set quote_associated_custom_objects = template_data.quote.associated_objects.deal.associated_objects.custom_objects %}\n\n{{ quote_associated_custom_objects|pprint }}\n{# |pprint is useful for understanding the structure of the data, you can leave it off when outputting values for display. #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " because custom objects are unique to each account, the mock data doesn't include an example custom object. This means that in the template preview in the design manager you may see an error or the custom object data simply won't display. You'll instead need to preview the template with your real CRM data, which you can do by creating a quote from the template."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can then access each custom object type by appending its custom object type ID formatted with underscores. For example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data.quote.associated_objects.deal.associated_objects.custom_objects._2_2193031"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also look up a custom object by using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations()"
        })
      }), " function and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), " functions."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you wanted to look up a custom object associated with a deal, you could pass in data from ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set quote_associated_object = crm_associations(template_data.quote.associated_objects.deal.hs_object_id, \"USER_DEFINED\", 152) %}\n{# 152 is an example of an association type id, you would need to use the appropriate id for your use-case. #}\n{{ quote_associated_object }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related Resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/quotes",
          children: "Custom quote templates"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/getting-started-from-the-cms-quotes-theme-beta",
          children: "Getting started with the CMS quotes theme"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/create-custom-quote-templates-beta",
          children: "Create and use custom quote templates (from the sales, sales ops/manager perspective)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}