"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45939804001;
const slug = exports.slug = 'guides/cms/content/templates/types/blog';
const title = exports.title = 'ブログテンプレート';
const name = exports.name = 'APAC JAPAN (ja) |WEB| [new] Blog template markup_JA | 202212';
const metaDescription = exports.metaDescription = 'ブログ リスト ページ テンプレートとブログ記事テンプレートは、ウェブサイト上でのブログのレイアウトをカスタマイズするために使用できます。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%AA%E3%82%B9%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%A8%E8%A8%98%E4%BA%8B%E3%81%AE%E5%85%B1%E9%80%9A%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "リストページと記事の共通テンプレートを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AA%E3%82%B9%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E7%94%A8%E3%81%A8%E8%A8%98%E4%BA%8B%E7%94%A8%E3%81%AB%E5%80%8B%E5%88%A5%E3%81%AE%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "リストページ用と記事用に個別のテンプレートを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%AA%E3%82%B9%E3%83%88-%E3%83%9A%E3%83%BC%E3%82%B8-%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
  "label": "リスト ページ テンプレート",
  "parent": "%E3%83%AA%E3%82%B9%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E7%94%A8%E3%81%A8%E8%A8%98%E4%BA%8B%E7%94%A8%E3%81%AB%E5%80%8B%E5%88%A5%E3%81%AE%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0-%E3%83%AA%E3%82%B9%E3%83%88-%E3%83%9A%E3%83%BC%E3%82%B8-%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "ブログ リスト ページ モジュールを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E5%9F%B7%E7%AD%86%E8%80%85%E3%80%81%E3%82%BF%E3%82%B0%E3%80%81%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB-%E3%83%AA%E3%82%B9%E3%83%88-%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "ブログ執筆者、タグ、シンプル リスト ページ",
  "parent": null
}, {
  "depth": 1,
  "id": "blog_authors",
  "label": "blog_authors",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E5%9F%B7%E7%AD%86%E8%80%85%E3%80%81%E3%82%BF%E3%82%B0%E3%80%81%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB-%E3%83%AA%E3%82%B9%E3%83%88-%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 1,
  "id": "if-tag",
  "label": "If tag",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E5%9F%B7%E7%AD%86%E8%80%85%E3%80%81%E3%82%BF%E3%82%B0%E3%80%81%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB-%E3%83%AA%E3%82%B9%E3%83%88-%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 1,
  "id": "if-not-simple_list_page",
  "label": "If not simple_list_page",
  "parent": "%E3%83%96%E3%83%AD%E3%82%B0%E5%9F%B7%E7%AD%86%E8%80%85%E3%80%81%E3%82%BF%E3%82%B0%E3%80%81%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB-%E3%83%AA%E3%82%B9%E3%83%88-%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 0,
  "id": "%E3%83%AA%E3%82%B9%E3%83%88%E3%81%AE%E3%83%9A%E3%83%BC%E3%82%B8%E9%80%81%E3%82%8A",
  "label": "リストのページ送り",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9C%E3%82%A4%E3%83%A9%E3%83%BC%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%9E%E3%83%BC%E3%82%AF%E3%82%A2%E3%83%83%E3%83%97",
  "label": "ボイラープレートマークアップ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%A8%98%E4%BA%8B%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%9E%E3%83%BC%E3%82%AF%E3%82%A2%E3%83%83%E3%83%97",
  "label": "記事テンプレートマークアップ",
  "parent": "%E3%83%9C%E3%82%A4%E3%83%A9%E3%83%BC%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%9E%E3%83%BC%E3%82%AF%E3%82%A2%E3%83%83%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%83%AA%E3%82%B9%E3%83%88-%E3%83%9A%E3%83%BC%E3%82%B8-%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%83%9E%E3%83%BC%E3%82%AF%E3%82%A2%E3%83%83%E3%83%97",
  "label": "リスト ページ テンプレートのマークアップ",
  "parent": "%E3%83%9C%E3%82%A4%E3%83%A9%E3%83%BC%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%9E%E3%83%BC%E3%82%AF%E3%82%A2%E3%83%83%E3%83%97"
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "関連コンテンツ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      code: "code",
      a: "a",
      pre: "pre",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ブログテンプレート"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのブログは、ブログ リスト ページと個別のブログ記事で構成されます。ブログ リスト ページ テンプレートは個別のブログ記事をリスト化するために使用されるほか、執筆者およびタグのリストページをレンダリングするためにも使用されます。リストページ用とブログ記事用にそれぞれ個別のテンプレートを作成することも、両方を含めて全てのページをレンダリングする共通のテンプレートを作成することも可能です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この記事では、ブログテンプレートのマークアップ、テンプレートを構成する要素、そしてカスタマイズのオプションについて説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "リストページと記事の共通テンプレートを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リストページと記事の両方をレンダリングする共通のテンプレートを作成するには、テンプレートファイルの先頭に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: blog"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/html-hubl-templates#%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%A2%E3%83%8E%E3%83%86%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3",
        children: "アノテーション"
      }), "を追加します。共通のテンプレートを両方のレンダリングに使用する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if文"
      }), "を使用して、ユーザーがリストページと個別の記事のどちらを表示しているかを評価します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/drag-and-drop-templates",
        children: "デザインマネージャーのドラッグ＆ドロップレイアウト"
      }), "を使用している場合、ブログ コンテンツ モジュール ボタンのUIにこの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "文が組み込まれます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "If_listing_view"
      }), "ステートメントを使用すると、記事とリストのコードを別々に書くことができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if is_listing_view %}\n    Markup for blog listing template\n{% else %}\n    Markup for blog post template\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "リストページ用と記事用に個別のテンプレートを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates#:~:text=%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%98%E4%BA%8B%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
        children: "ブログ記事"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#:~:text=%E3%83%96%E3%83%AD%E3%82%B0%20%E3%83%AA%E3%82%B9%E3%83%88%20%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
        children: "リストページ"
      }), "に別々のテンプレートを用意することも可能です。個別のテンプレートを使用することにより、開発者はコードがすっきりして読みやすく、コンテンツ作成者はテンプレートを選びやすくなります。共通のテンプレートでは先頭に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: blog"
      }), "アノテーションを挿入しますが、各ページ用に個別のテンプレートを作成する場合はそれに代わり、テンプレートの先頭にそれぞれ以下の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/html-hubl-templates#%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%A2%E3%83%8E%E3%83%86%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3",
        children: "アノテーション"
      }), "を追加します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ブログ記事テンプレート:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateType: blog_post"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ブログ リスト ページ テンプレート:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateType: blog_listing"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/template-annotation-blog-listing.png",
        alt: "template - annotation - blog - listing"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["記事テンプレートとリスト ページ テンプレートを別々に作成する場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_listing_view"
      }), "チェックは必要ありませんが、代わりにアカウントのブログ設定で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/ja/blog/manage-your-blog-template-and-settings#%E3%83%96%E3%83%AD%E3%82%B0%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E9%81%B8%E6%8A%9E%E3%81%99%E3%82%8B",
        children: "個別のテンプレートを手動で選択する"
      }), "必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/guides/migrate/blog-to-blog_listing",
        children: "共通のブログテンプレートをリストページ用もしくは記事用のテンプレートに移行する"
      }), "ことも可能です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "リスト ページ テンプレート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: blog_listing"
      }), "アノテーションを挿入すると、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/ja/blog/manage-your-blog-template-and-settings#%E3%83%96%E3%83%AD%E3%82%B0%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E9%81%B8%E6%8A%9E%E3%81%99%E3%82%8B",
        children: "［ブログ設定］"
      }), "でリストページ用のテンプレートとして選択できるようになります。コンテンツ作成者はこのテンプレートタイプを使用して、ページエディター内でリストページを編集することも可能です。さらに、テンプレートに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/drag-and-drop-areas",
        children: "ドラッグ＆ドロップエリア"
      }), "も含めることで、他のCMSページと同様にページエディターにおけるモジュールの追加や削除が可能になります。ドラッグ＆ドロップエリアの追加例に関して、詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/pull/349",
        children: "CMSボイラープレートのブログテンプレート"
      }), "（英語）をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["記事のリストは、ブログ記事を反復処理する", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "forループ"
      }), "によって生成されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contents"
      }), "は、そのブログに含まれる全ての記事を含む事前定義されたコンテンツシーケンスです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n    <div class=\"post-item\">\n        Post item markup that renders with each iteration.\n    </div>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["ブログ リスト ページ テンプレートの全てのテキスト文字列は、フィールドで制御することをお勧めします。これにより、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/features/multi-language-content",
          children: "多言語"
        }), "ブログの作成が容易になり、コンテンツ作成者の操作性が向上します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブログ リスト ページ モジュールを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンテンツ作成者がモジュールをブログ リスト ページ内コンテンツの周囲（側面や上下など）に配置することを許可できます。これを有効にするには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog#for-",
        children: "ブログリストのforループ"
      }), "を使用したブログ リスト モジュールを作成することをお勧めします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotは、サマリーの表示やキービジュアルを使用するための", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/ja/blog/manage-your-blog-template-and-settings#%E3%83%96%E3%83%AD%E3%82%B0%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E9%81%B8%E6%8A%9E%E3%81%99%E3%82%8B",
        children: "ブログ設定"
      }), "を提供していますが、これらの機能をモジュールに組み込むこともできます。モジュールへの組み込みにより、コンテンツ作成者はブログの設定ページへ切り替えることなく、ページエディター内で必要な機能を使用できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブログ執筆者、タグ、シンプル リスト ページ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotブログにはブログ記事とブログ リスト ページに加えて、ブログ執筆者用のページ、ブログ記事のタグ用のページ、シンプル リスト ページもあります。こうした追加ページは、ブログ リスト ページと同じテンプレートを使用してコンテンツが表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "リストページのテンプレートはブログ執筆者、タグ、簡単なリストページでも共有されるため、テンプレートに公開された更新内容もこれらのページに適用されます。"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["追加ページのレイアウトを個別に構成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "文を使用して、各タイプのページのコンテンツを条件付きでレンダリングします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "blog_authors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["標準的なHubSpotブログ リスト ページのマークアップには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if blog_author"
      }), "ステートメントが含まれます。このステートメントは、執筆者が公開した全ての記事を一覧にした執筆者の個別ページを表示する際に［true］と評価されます。ボイラープレートテンプレートには、執筆者の名前、プロフィール、およびソーシャル メディア アカウントが含まれています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if blog_author %}\n  <div class=\"blog-header\">\n    <div class=\"blog-header__inner\">\n      {% if blog_author.avatar %}\n      <div class=\"blog-header__author-avatar\" style=\"background-image: url('{{ blog_author.avatar }}');\"></div>\n      {% endif %}\n      <h1 class=\"blog-header__title\">{{ blog_author.display_name }}</h1>\n      <h4 class=\"blog-header__subtitle\">{{ blog_author.bio }}</h4>\n      {% if blog_author.has_social_profiles %}\n        <div class=\"blog-header__author-social-links\">\n          {% if blog_author.website %}\n            <a href=\"{{ blog_author.website }}\" target=\"_blank\">\n              {% icon name=\"link\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.facebook %}\n            <a href=\"{{ blog_author.facebook }}\" target=\"_blank\">\n              {% icon name=\"facebook-f\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.linkedin %}\n            <a href=\"{{ blog_author.linkedin }}\" target=\"_blank\">\n              {% icon name=\"linkedin-in\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.twitter %}\n            <a href=\"{{ blog_author.twitter }}\" target=\"_blank\">\n              {% icon name=\"twitter\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n        </div>\n      {% endif %}\n    </div>\n  </div>\n{% else %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "If tag"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "if tag"
      }), " ステートメントを使用すると、ブログのトピックページにのみコードをレンダリングできます。訪問者がウェブサイト上でブログトピックをクリックすると、その値が表示されます。以下の例は、ページタイトル変数を使用してタグ リスト ページ上部にタグ名を自動出力するスニペットです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if tag %}\n    <h3>Posts about {{ page_meta.html_title|split(\" | \")|last }}</h3>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "If not simple_list_page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ブログ記事の一覧を表示するためにレンダリングされるブログ リスト ページには、標準のリストページとシンプル リスト ページの2種類があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "標準のリストページでは、記事リストのブログ設定で指定された記事の数だけレンダリングが反復し、それに応じてページ番号も付与されます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["シンプル リスト ページは全ての記事の一覧で、ページ番号には対応していません。シンプル リスト ページは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/building-blocks/website-settings#number-of-posts-per-listing-page",
          children: "ブログ設定の記事数制限"
        }), "の影響を受けることなく、通常は直近200件のブログ記事へのリンクが格納されます。シンプル リスト ページのアドレスは、ブログURLのパスの末尾に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/all"
        }), "を追加したものです。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "if not simple_list_page"
      }), "を使用して、シンプル リスト ページと標準のリストページのどちらをレンダリングするかを指定できます。このステートメントを簡略化したものを以下でご参照ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["この", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if"
        }), "文ではロジックが反転している点にご注意ください。つまり、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "else"
        }), "でシンプル リスト ビューを定義しています。必要に応じて、代わりに", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/hubl/if-statements#unless-",
          children: "unless文"
        }), "を使用することもできます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if not simple_list_page %}\n    Iterated post markup for regular listing\n{% else %}\n    <h2 class=\"post-listing-simple\"><a href=\"{{content.absolute_url}}\">{{ content.name }}</a></h2>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "リストのページ送り"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ブログ リスト ページではページ番号が自動生成されます。リスト ページ テンプレートには、訪問者がブログ記事内でページを簡単に切り替えられるようにするロジックを含めることが可能です。デモ用", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "ボイラープレートのブログ"
      }), "では、下記のようなマークアップを使って数値によるシンプルなページ送りを実現しています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if contents.total_page_count > 1 %}\n<div class=\"blog-pagination\">\n    {% set page_list = [-2, -1, 0, 1, 2] %}\n    {% if contents.total_page_count - current_page_num == 1 %}{% set offset = -1 %}\n    {% elif contents.total_page_count - current_page_num == 0 %}{% set offset = -2 %}\n    {% elif current_page_num == 2 %}{% set offset = 1 %}\n    {% elif current_page_num == 1 %}{% set offset = 2 %}\n    {% else %}{% set offset = 0 %}{% endif %}\n\n    <a class=\"blog-pagination__link blog-pagination__prev-link {{ \"blog-pagination__prev-link--disabled\" if !last_page_num }}\" href=\"{{ blog_page_link(last_page_num) }}\">\n    {% icon name=\"chevron-left\" style=\"SOLID\", width=\"13\", no_wrapper=True %}\n    Prev\n    </a>\n    {% for page in page_list %}\n    {% set this_page = current_page_num + page + offset %}\n    {% if this_page > 0 and this_page <= contents.total_page_count %}\n        <a class=\"blog-pagination__link blog-pagination__number-link {{ \"blog-pagination__link--active\" if this_page == current_page_num }}\" href=\"{{ blog_page_link(this_page) }}\">{{ this_page }}</a>\n    {% endif %}\n    {% endfor %}\n    <a class=\"blog-pagination__link blog-pagination__next-link {{ \"blog-pagination__next-link--disabled\" if !next_page_num }}\" href=\"{{ blog_page_link(current_page_num + 1) }}\">\n    Next\n    {% icon name=\"chevron-right\" style=\"SOLID\", width=\"13\", no_wrapper=True %}\n    </a>\n</div>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ボイラープレートマークアップ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下で、ブログ記事とブログ リスト ページ テンプレートのボイラープレートマークアップをご覧ください。各セクションに記載されているように、GitHubのCMSボイラープレートでこのマークアップを表示することもできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "記事テンプレートマークアップ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1つのブログ内の全ての記事は、共通のブログテンプレートによって生成されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Content"
      }), "は、リクエストされたブログ記事に関する情報を含む既定のデータオブジェクトです。デモ用", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog/blog-post-1",
        children: "ボイラープレート記事"
      }), "は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-post.html",
        children: "次のマークアップ"
      }), "（英語）を使ってレンダリングされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"content-wrapper\">\n  <div class=\"blog-post\">\n    <h1>{{ content.name }}</h1>\n    <div class=\"blog-post__meta\">\n      <a href=\"{{ blog_author_url(group.id, content.blog_post_author.slug) }}\">\n        {{ content.blog_post_author.display_name }}\n      </a>\n      <div class=\"blog-post__timestamp\">\n        {{ content.publish_date_localized }}\n      </div>\n    </div>\n    <div class=\"blog-post__body\">\n      {{ content.post_body }}\n    </div>\n    {% if content.tag_list %}\n    <div class=\"blog-post__tags\">\n      {% icon name=\"tag\" style=\"SOLID\" %}\n      {% for tag in content.tag_list %}\n        <a class=\"blog-post__tag-link\" href=\"{{ blog_tag_url(group.id, tag.slug) }}\">{{ tag.name }}</a>{% if not loop.last %},{% endif %}\n      {% endfor %}\n    </div>\n    {% endif %}\n  </div>\n  <div class=\"blog-comments\">\n    {% module \"blog_comments\" path=\"@hubspot/blog_comments\", label=\"Blog Comments\" %}\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、ブログ記事の執筆者情報も", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content"
      }), "データの中に含まれます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<img alt=\"{{ content.blog_post_author.display_name }}\" src=\"{{ content.blog_post_author.avatar }}\">\n<h3>Written by <a class=\"author-link\" href=\"{{ blog_author_url(group.id, content.blog_post_author.slug) }}\">{{ content.blog_post_author.display_name }}</a></h3>\n<p>{{ content.blog_post_author.bio }}</p>\n{% if content.blog_post_author.has_social_profiles %}\n    <div class=\"hs-author-social-section\">\n        <div class=\"hs-author-social-links\">\n            {% if content.blog_post_author.facebook %}\n                <a href=\"{{ content.blog_post_author.facebook }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-facebook\">Facebook</a>\n            {% endif %}\n            {% if content.blog_post_author.linkedin %}\n                <a href=\"{{ content.blog_post_author.linkedin }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-linkedin\">LinkedIn</a>\n            {% endif %}\n            {% if content.blog_post_author.twitter %}\n                <a href=\"{{ content.blog_post_author.twitter }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-twitter\">Twitter</a>\n            {% endif %}\n            {% if content.blog_post_author.google_plus %}\n                <a href=\"{{ content.blog_post_author.google_plus }}?rel=author\" target=\"_blank\" class=\"hs-author-social-link hs-social-google-plus\">Google+</a>\n            {% endif %}\n        </div>\n    </div>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "リスト ページ テンプレートのマークアップ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["デモ用", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "ボイラープレート ブログ リスト ページ"
      }), "のコンテンツのforループは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-index.html",
        children: "次のマークアップ"
      }), "（英語）によってレンダリングされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n    {# On the blog homepage the first post will be featured above older posts #}\n    {% if (loop.first && current_page_num == 1 && !topic) %}\n    <div class=\"blog-index__post blog-index__post--large\">\n        <a class=\"blog-index__post-image blog-index__post-image--large\"\n        {% if content.featured_image %}\n            style=\"background-image: url('{{ content.featured_image }}')\";\n        {% endif %}\n        href=\"{{ content.absolute_url }}\"></a>\n        <div class=\"blog-index__post-content  blog-index__post-content--large\">\n        <h2><a href=\"{{ content.absolute_url }}\">{{ content.name }}</a></h2>\n        {{ content.post_list_content }}\n        </div>\n    </div>\n    {% else %}\n    <div class=\"blog-index__post blog-index__post--small\">\n        <a class=\"blog-index__post-image blog-index__post-image--small\"\n        {% if content.featured_image %}\n            style=\"background-image: url('{{ content.featured_image }}')\";\n        {% endif %}\n        href=\"{{ content.absolute_url }}\"></a>\n        <div class=\"blog-index__post-content  blog-index__post-content--small\">\n        <h2><a href=\"{{ content.absolute_url }}\">{{ content.name }}</a></h2>\n        {{ content.post_list_content|truncatehtml(100) }}\n        </div>\n    </div>\n    {% endif %}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連コンテンツ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/hubl/variables#blog-variables",
          children: "ブログの変数"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/blog-template-markup",
          children: "ブログテンプレート"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/building-blocks/themes/hubspot-cms-boilerplate",
          children: "HubSpotテーマボイラープレート"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/ja/blog/create-a-new-blog",
          children: "ブログの作成方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/ja/blog/import-your-content-into-hubspot#%E3%83%96%E3%83%AD%E3%82%B0%E3%82%92%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88%E3%81%99%E3%82%8B",
          children: "ブログをHubSpotにインポートする"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}