"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358753;
const slug = exports.slug = 'guides/api/crm/objects/deals';
const title = exports.title = 'API du CRM | Transactions';
const name = exports.name = 'vNext Docs DP - Transactions';
const metaDescription = exports.metaDescription = "Une transaction stocke des données sur une transaction en cours. Les points de terminaison des transactions vous permettent de gérer ces données et de les synchroniser entre HubSpot et d'autres systèmes.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-des-transactions",
  "label": "Créer des transactions",
  "parent": null
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s",
  "label": "Propriétés",
  "parent": "cr%C3%A9er-des-transactions"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "cr%C3%A9er-des-transactions"
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-des-transactions",
  "label": "Récupérer des transactions",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-des-transactions",
  "label": "Mettre à jour des transactions",
  "parent": null
}, {
  "depth": 1,
  "id": "associer-des-transactions-existantes-%C3%A0-des-fiches-d-informations-ou-activit%C3%A9s",
  "label": "Associer des transactions existantes à des fiches d'informations ou activités",
  "parent": "mettre-%C3%A0-jour-des-transactions"
}, {
  "depth": 1,
  "id": "supprimer-une-association",
  "label": "Supprimer une association",
  "parent": "mettre-%C3%A0-jour-des-transactions"
}, {
  "depth": 0,
  "id": "%C3%A9pingler-une-activit%C3%A9-%C3%A0-une-fiche-d-informations-de-transaction",
  "label": "Épingler une activité à une fiche d'informations de transaction",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-des-transactions",
  "label": "Supprimer des transactions",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Transactions"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans HubSpot, les transactions représentent des transactions avec des contacts ou des entreprises. Les transactions sont suivies tout au long de votre processus de vente via des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "phases de pipeline"
      }), " jusqu'à ce qu'elles soient gagnées ou perdues. Les points de terminaison des transactions vous permettent de créer et de gérer des fiches d'informations de transaction ainsi que de synchroniser des données de transaction entre HubSpot et d'autres systèmes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les API d'objets, de fiches d'informations, de propriétés et d'associations dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "guide Comprendre le CRM"
      }), ". Pour plus d'informations générales sur les objets et les fiches d'informations dans HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "découvrez comment gérer votre base de données CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer des transactions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer de nouvelles transactions, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans votre demande, incluez les données de votre transaction dans un objet de propriétés. Vous pouvez également ajouter un objet d'association pour associer votre nouvelle transaction à des fiches d'informations existantes (par exemple, des contacts ou des entreprises) ou à des activités (par exemple, des réunions ou des notes)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les détails de la transaction sont stockés dans des propriétés de transaction. Il existe des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/hubspots-default-deal-properties",
        children: "propriétés de transaction HubSpot par défaut"
      }), ", mais vous pouvez également créer des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "propriétés personnalisées"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez une nouvelle transaction, vous devez inclure les propriétés suivantes dans votre demande : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealname"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealstage"
      }), " et, si vous disposez de plusieurs pipelines, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pipeline"
      }), ". Si aucun pipeline n'est spécifié, le pipeline par défaut sera utilisé."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour afficher toutes les propriétés disponibles, vous pouvez récupérer une liste des propriétés de transactions de votre compte en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/deals"
      }), ". Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API des propriétés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : Vous devez utiliser l'ID interne d'une phase de transaction ou d'un pipeline lors de la création d'une transaction via l'API. L'ID interne sera également retourné lorsque vous récupérerez des transactions via l'API. Vous pouvez trouver l'ID interne d'une phase de transaction ou d'un pipeline dans les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages#to-customize-your-deal-stages:~:text=To%20view%20the%20internal%20name%20for%20a%20deal%20stage%2C%20hover%20over%20the%20stage%20and%20click%20the%20code%20code%20icon.%20The%20internal%20name%20is%20used%20by%20integrations%20and%20the%20API.",
          children: "paramètres de votre pipeline de transactions."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour créer une nouvelle transaction, votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"amount\": \"1500.00\",\n    \"closedate\": \"2019-12-07T16:50:06.678Z\",\n    \"dealname\": \"New deal\",\n    \"pipeline\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hubspot_owner_id\": \"910901\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez une nouvelle transaction, vous pouvez également associer la transaction à des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "fiches d'informations"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "activités"
      }), " existantes dans un objet d'association. Par exemple, pour associer une nouvelle transaction à un contact et une entreprise existants, votre demande ressemblerait à ce qui suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"amount\": \"1500.00\",\n    \"closedate\": \"2019-12-07T16:50:06.678Z\",\n    \"dealname\": \"New deal\",\n    \"pipeline\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hubspot_owner_id\": \"910901\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 201\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 5\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 3\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'objet d'association, vous devez ajouter les éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La fiche d'informations ou l'activité à laquelle vous souhaitez associer la transaction, en fonction de sa valeur unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'association entre la transaction et la fiche d'informations/l'activité. Inclure la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "et le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Les ID de types d'association par défaut sont répertoriés ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "ici"
            }), ", ou vous pouvez récupérer la valeur des types d'associations personnalisés (c'est-à-dire les libellés) via l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API des associations"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer des transactions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez récupérer des transactions individuellement ou par lots."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer une transaction individuelle, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/{dealId}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour demander une liste de toutes les transactions, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ces points de terminaison, vous pouvez inclure les paramètres suivants dans l'URL de la requête :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer dans la réponse. Si la transaction demandée n'a pas de valeur pour une propriété, elle n'apparaîtra pas dans la réponse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés actuelles et historiques à renvoyer dans la réponse. Si la transaction demandée n'a pas de valeur pour une propriété, elle n'apparaîtra pas dans la réponse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste séparée par des virgules des objets pour lesquels récupérer les ID associés. Les associations spécifiées qui n'existent pas ne seront pas renvoyées dans la réponse. Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API des associations."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer un lot de transactions spécifiques par ID de fiche d'informations ou selon une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "propriété d'identifiant unique personnalisée"
        }), ", effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/deals/batch/read"
        }), ". Le point de terminaison du lot ", (0, _jsxRuntime.jsx)("u", {
          children: "ne peut pas"
        }), " récupérer les associations. Découvrez comment lire par lots les associations avec l'API ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "des associations"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour le point de terminaison de lecture par lot, vous pouvez également utiliser le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " facultatif pour récupérer les transactions en fonction d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propriété d'identifiant unique"
      }), " personnalisée. Par défaut, les valeurs d'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " dans la demande font référence à l'ID de fiche d'informations (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), de sorte que le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " n'est pas requis lors de la récupération par ID de fiche d'informations. Pour utiliser une propriété de valeur unique personnalisée pour récupérer des transactions, vous devez inclure le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour récupérer un lot de transactions, votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"dealname\", \"dealstage\", \"pipeline\"],\n  \"inputs\": [\n    {\n      \"id\": \"7891023\"\n    },\n    {\n      \"id\": \"987654\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"dealname\", \"dealstage\", \"pipeline\"],\n  \"idProperty\": \"uniqueordernumber\",\n  \"inputs\": [\n    {\n      \"id\": \"0001111\"\n    },\n    {\n      \"id\": \"0001112\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour récupérer des transactions avec des valeurs actuelles et historiques pour une propriété, votre demande pourrait ressembler à :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"dealstage\"],\n  \"inputs\": [\n    {\n      \"id\": \"7891023\"\n    },\n    {\n      \"id\": \"987654\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour des transactions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez mettre à jour des transactions individuellement ou par lots. Pour les transactions existantes, l'ID de transaction est une valeur unique par défaut que vous pouvez utiliser pour mettre à jour la transaction via l'API, mais vous pouvez aussi identifier des transactions en utilisant des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propriétés d'identifiant unique personnalisées."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour mettre à jour une transaction individuelle avec son ID de fiche d'informations, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/{dealId}"
        }), " et incluez les données que vous souhaitez mettre à jour."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour mettre à jour plusieurs transactions, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " dans ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/batch/update"
        }), ". Dans le corps de la requête, incluez un tableau avec les identifiants des transactions et les propriétés que vous souhaitez mettre à jour."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer des transactions existantes à des fiches d'informations ou activités"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour associer une transaction à d'autres fiches d'informations de CRM ou à une activité, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour récupérer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), ", reportez-vous à ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "cette liste"
        }), " de valeurs par défaut ou effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur l'association de fiches d'informations avec l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API des associations"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer une association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une association entre une transaction et une fiche d'informations ou une activité, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à l'URL suivante : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Épingler une activité à une fiche d'informations de transaction"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez épingler une activité à une fiche d'informations de transaction via l'API en incluant le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " dans votre demande. Dans le champ, incluez l'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de l'activité à épingler, qui peut être récupéré via les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API d'engagement"
      }), ". Vous pouvez épingler une activité par fiche d'informations, et l'activité doit déjà être associée à la transaction avant l'épinglage."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour définir ou mettre à jour l'activité épinglée d'une transaction, votre demande peut ressembler à :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/deals/{dealId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez également créer une transaction, l'associer à une activité existante et épingler l'activité dans la même demande. Par exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/deals\n{\n  \"properties\": {\n    \"dealname\": \"New deal\",\n    \"pipelines\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 213\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer des transactions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez supprimer des transactions individuellement ou par lots, ce qui placera la transaction dans la corbeille dans HubSpot. Vous pourrez ensuite ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "restaurer la transaction dans HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une transaction avec son ID, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la suppression de transactions par lots, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}