"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280249464;
const slug = exports.slug = 'guides/apps/marketplace/applying-for-app-certification';
const title = exports.title = 'Beantragen einer App-Zertifizierung';
const name = exports.name = 'EMEA DACH (DE) Applying for app certification';
const metaDescription = exports.metaDescription = 'In der App-Zertifizierung erfahren Benutzer, dass Ihre App von HubSpot überprüft und genehmigt wurde. So bauen Sie Vertrauen auf und liefern potenziellen Benutzern einen Nachweis von deren Qualität.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h3: "h3",
      p: "p",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Eine App-Zertifizierung beantragen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Was ist eine App-Zertifizierung?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die App-Zertifizierung beinhaltet, dass das HubSpot Ecosystem Quality-Team überprüft und bestätigt, dass Ihre gelistete App ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "diese Anforderungen"
      }), " an Sicherheit, Datenschutz, Zuverlässigkeit, Leistung, Benutzerfreundlichkeit, Barrierefreiheit und Wert erfüllt. Sobald sie genehmigt ist, wird auf Ihrer App-Listing-Seite ein „Von HubSpot zertifizierte App“ -Abzeichen angezeigt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Warum ist das wichtig?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anhand eines Zertifizierungsabzeichens in Ihrem App-Listing wissen vorhandene und potenzielle Kunden, dass das HubSpot Ecosystem Quality-Team Ihre App überprüft und genehmigt hat. Letztendlich ist es eine Möglichkeit, Qualität zu symbolisieren und Vertrauen bei App-Benutzern aufzubauen. Erfahren Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-customer-trust-through-certification",
        children: "hier"
      }), " mehr darüber, wie Sie durch eine App-Zertifizierung Kundenvertrauen aufbauen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Wie funktionieren das?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jeder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "berechtigte"
      }), " App-Partner kann über seinen App-Entwickler-Account eine Zertifizierung beantragen. Das HubSpot Ecosystem Quality-Team überprüft dann Ihre Einreichung und kontaktiert Sie, um Feedback zu geben oder die Zertifizierung Ihrer App zu bestätigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ist meine App für eine Zertifizierung berechtigt?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lesen Sie sich unsere ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Zertifizierungsvoraussetzungen"
      }), " durch, um sicherzustellen, dass Ihre App für eine Zertifizierung in Frage kommt. Sie können keine Zertifizierung beantragen, es sei denn, Ihre App verfügt über mindestens 60 aktive Installationen und den erforderlichen API-Traffic. Aktive Installationen sind die Anzahl der einzigartigen HubSpot-Produktions-Accounts, die nicht mit Ihrer Organisation verbunden sind und die erfolgreiche App-Aktivitäten in den letzten 30 Tagen zeigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Wie kann ich eine Zertifizierung beantragen?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können jeweils nur eine App zur Zertifizierung einreichen. Wenn Sie mehr als eine App gleichzeitig zur Zertifizierung einreichen, werden diese basierend auf der Reihenfolge der Einreichung abgelehnt. Sobald Ihre App zertifiziert ist, können Sie eine weitere zur Zertifizierung einreichen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So beantragen Sie eine Zertifizierung:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/de/account/why-can-t-i-log-into-hubspot",
            children: "Melden Sie sich bei Ihren Entwickler-Account an"
          }), " und gehen Sie zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "„App Marketplace“ > „Listings“"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Bewegen Sie den Mauszeiger rechts neben die App, die Sie zertifizieren möchten, und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "„App zertifizieren“"
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/undefined-Jun-28-2021-07-51-13-13-PM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL"
        }), " für das Demo-Video ein. Sehen Sie sich ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-demo",
          children: "unten"
        }), " die notwendigen Anforderungen an das App-Demovideo an."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Stellen Sie Test-Anmeldeinformationen für Ihre App bereit, damit das HubSpot Ecosystem Quality-Team deren Funktionen bewerten kann."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-certification.png",
        alt: "app-certification"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Antrag zur Zertifizierung einreichen“"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Anforderungen für das App-Demo-Video"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Demo-Videos helfen dem HubSpot Ecosystem Quality-Team, Ihre App zu testen. Das Team überprüft Ihre App ", (0, _jsxRuntime.jsx)("u", {
          children: "erst"
        }), ", wenn ein Demo-Video eingereicht wurde, das alle Anforderungen erfüllt. Werbe- und Marketingvideos werden abgelehnt. HubSpot wird Ihre Demo-Videos nicht teilen oder veröffentlichen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Demo-Video muss die folgenden Anforderungen erfüllen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Es dauert mindestens drei Minuten."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Es umfasst Audio- (bevorzugt) oder Textbeschreibungen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Es umfasst Beschreibungen des Zwecks Ihrer App und häufiger Anwendungsfälle.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Beispiel: „Die Acme-App hilft Vertriebs- und Onboarding-Mitarbeitern bei der CRM-übergreifenden Koordination. Abgeschlossene gewonnene Deals in einem der von Acme unterstützten CRM-Systeme können automatisch Tickets und Onboarding-Aufgaben in HubSpot generieren.“"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Es wird demonstriert und beschrieben, wie neue Benutzer Folgendes durchführen sollten:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ihre App installieren", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Beispiel: „ Klicken Sie in der Acme App-Liste im HubSpot App Marketplace auf „App installieren“, wählen Sie Ihr CRM aus, geben Sie Ihre Anmeldeinformationen ein, klicken Sie auf „Fertig“, wählen Sie Ihren HubSpot-Account aus, überprüfen Sie die angeforderten Bereiche und klicken Sie auf „App verknüpfen“.“"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ihre App nach der Installation einrichten oder sie konfigurieren.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Beispiel: \"Sobald die App installiert ist, wählen Sie eine Ticket-Pipeline aus, indem Sie zu „Einstellungen“ > „Integrationen > „Verknüpfte Apps“ > „Acme-App“ > „Ticket-Pipeline“ gehen. Konfigurieren Sie dann im Abschnitt „Aufgabenvorlagen“ bis zu 10 Standardaufgaben. Wenn Sie bereit sind, die Synchronisierung zu aktivieren, aktivieren Sie den Schalter für die Ticket-Synchronisierung.“"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Nutzen Sie die primären Funktionen Ihrer App, um gängige Anwendungsfälle zu unterstützen.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Beispiel: „Für jeden abgeschlossenen und gewonnenen Deal im verknüpften CRM erstellt die Ticket-Synchronisierungsfunktion einen Ticket-Datensatz in HubSpot mit allen zugehörigen Kontakten und Ihren konfigurierten Aufgaben. Dies ermöglicht es Ihrem Onboarding-Team, sofort mit dem neuen Kunden in Kontakt treten.“"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Interagieren Sie mit Ihrer App innerhalb ihres HubSpot-Accounts, um gängige Anwendungsfälle zu unterstützen (falls zutreffend).", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Beispiel: „Um Onboarding-Tickets über HubSpot-Deals zu erstellen, verwenden Sie die benutzerdefinierte Workflow-Aktion „Acme-Ticket erstellen“ in dealbasierten Workflows. Diese Aktionen ermöglichen mehr Anpassungen als die App-Einstellungen für andere CRM-Systeme.“"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Die Verknüpfung Ihrer App von ihrem HubSpot-Account entfernen."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ihre App von ihrem HubSpot-Account deinstallieren (einschließlich einer Beschreibung, wie sich dies auf die HubSpot-Accounts und Daten von Benutzern auswirkt)."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tipp:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.loom.com/",
          children: "Loom"
        }), " ist ein kostenloses Tool, das Sie verwenden können, um ein Demo-Video aufzunehmen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Warum wird der Zertifizierungs-CTA nicht für meine App angezeigt?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Schaltfläche „App zertifizieruen“ wird nur angezeigt, wenn Ihre App für einen Antrag berechtigt ist. Bitte lesen Sie sich unsere ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Zertifizierungsanforderungen"
      }), " durch oder wenden Sie sich an Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-partner-manager",
        children: "App-Partner-Manager"
      }), ", wenn Sie Fragen zu Ihrer Berechtigung haben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Wie erfahren Benutzer, dass meine App zertifiziert ist?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sobald sie zertifiziert ist, wird in Ihrem App Marketplace-Listing ein gut sichtbares „Von HubSpot zertifizierter App-Partner“-Abzeichnen angezeigt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/dw7gvVRNzsrmtUwOYBsLVhpAEniaAZ5Z-_G70J-Bzs596yGR_ZzLXJyw821l7FVCV53XmKoI1VVEFxX9YKAJxtI-4NjwvCJadqfWyLG8IyFOWROZ6k8Nx-1307mp6cE0T8p980-N",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn ein Kunde den Mauszeiger über das Abzeichen bewegt, sieht er zusätzliche Informationen dazu, wie Apps zertifiziert werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Finden Sie Ihren ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App-Partner-Manager"
        }), " und seine E-Mail-Informationen, indem Sie sich bei Ihrem Entwickler-Account anmelden und zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), " navigieren. Bewegen Sie den Mauszeiger über Ihre App und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mehr"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listing-Details anzeigen"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Verwandte Dokumente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Voraussetzungen für die App-Zertifizierung"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "App-Listing-Voraussetzungen"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}