"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 43352345482;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/multilevel';
const title = exports.title = 'How to build multilevel dynamic pages with HubDB';
const name = exports.name = 'How to build multilevel dynamic pages with HubDB';
const metaDescription = exports.metaDescription = 'Create dynamic pages with nested HubDB tables.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-enable-child-tables-in-your-table-s-settings.",
  "label": "1. Enable child tables in your table's settings.",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-select-child-tables-for-each-row",
  "label": "2. Select child tables for each row",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-create-the-multilevel-template",
  "label": "3. Create the multilevel template",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-populate-the-top-level-template",
  "label": "4. Populate the top-level template",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-populate-the-dynamic-level-templates",
  "label": "5. Populate the dynamic-level templates",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-link-parent-table-to-page",
  "label": "6. Link parent table to page",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      img: "img",
      em: "em",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "How to build multilevel dynamic pages with HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "dynamic website page"
      }), " is a CMS page whose content changes based on the path of the URL requested by an end user. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), " already allows you to store, filter, and display data in your HubSpot website pages. Multilevel dynamic pages take this concept further, allowing you to create up to five levels of pages within one dynamic template."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Each dynamic page includes its own unique, SEO-friendly URL, and offers page-specific analytics."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " that this tutorial assumes you already have multiple HubDB tables created. Please see the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "HubDB documentation"
        }), " if you are unfamiliar with HubDB or want to create your first HubDB tables."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Enable child tables in your table's settings."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Files and Templates"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "HubDB"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " the table that will act as the parent for any other tables that will be used for your nested child tables."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the top right, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Actions"
          }), " dropdown menu, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Manage settings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If you haven't yet enabled the table for dynamic pages, click to toggle the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enable creation of dynamic pages using row data"
          }), " switch on, then select the columns to populate the page data."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click to select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Allow child tables"
          }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Automatically create listing pages for child tables"
          }), " checkboxes."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-manage-settings-child-tables.png",
            alt: "hubdb-manage-settings-child-tables"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Save"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your changes saved, you'll then see a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Child table"
      }), " column added to the table. In this column, use the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "dropdown menu"
      }), " to select another HubDB table to pull data from."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "To streamline this process, keep the child table columns and their internal names the same. If they are not the same, you'll need to use conditional logic later to render unique content for a given table."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Select child tables for each row"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the parent table, use the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Child table"
      }), " column dropdown menus to select ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "tables"
      }), " to pull data from."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can only select tables that are enabled for dynamic page content creation. If you've enabled a table for dynamic page creation but aren't seeing it in the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Child table"
      }), " dropdown menu, ensure that you've clicked ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Publish"
      }), " in the child table."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " a parent table cannot reference a child table which also references the parent table. This will create a loop that results in an error when trying to select the child table within the parent table."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-menu-parent-table.png",
        alt: "hubdb-menu-parent-table"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the above example, the first row will be pulling its food data from the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Foods"
      }), " child table, which contains data about available foods, as shown below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-food-child-table.png",
        alt: "hubdb-food-child-table"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When setting multilevel dynamic pages, the page paths for each row in the child table will be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "parent_path/child_path"
      }), ". For example, the page path for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "banana"
      }), " row will be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "page_path/foods/banana"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By turning on the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Automatically create listing pages for child tables"
      }), " setting, HubSpot will also automatically create intermediate listing pages for the child table rows (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "page_path/foods"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "page_path/beverages"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you would rather have those intermediate routes not resolve and return a 404 page, deselect the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Automatically create listing pages child child tables"
      }), " checkbox in the table's settings."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Create the multilevel template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Through child tables, you can create up to five levels of pages with one dynamic template. You can configure each level by using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_route_level"
      }), " HubL variable. The top-level template starts at a value of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "0"
      }), " and increments for each table layer."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_route_level == 0 %}\n\tTop Level Template\n{% elif dynamic_page_route_level == 1 %}\n\tParent table template (/food /beverage)\n{% elif dynamic_page_route_level == 2 %}\n\tChild table template (/food/banana etc., /beverage/soda etc.)\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Populate the top-level template"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For this tutorial, the goal is to list the child rows and group them by parent category. The example code below does the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["First, it retrieves the category rows using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_table_id"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Then, the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_child_table_id"
        }), " property of each category row retrieves the table IDs of the child tables."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Last, it uses those table IDs to list the child rows under each parent category."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_route_level == 0 %}\n\t<h1>Categories</h1>\n    {% set rows = hubdb_table_rows(dynamic_page_hubdb_table_id) %}\n    {% for row in rows %}\n     \t<h2><a href=\"{{ request.path }}/{{ row.hs_path }}\">{{ row.hs_name }}</a></h2>\n      \t{% set childRows = hubdb_table_rows(row.hs_child_table_id) %}\n      \t{% for childRow in childRows %}\n        \t<li><a href=\"{{ request.path }}/{{ row.hs_path }}/{{childRow.hs_path}}\">{{ childRow.hs_name }}</a></li>\n      \t{% endfor %}\n     {% endfor %}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Populate the dynamic-level templates"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After populating the top-level template, you'll then need to define templates for the subsequent levels in a similar fashion."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While building out your templates, it can be useful to access parent table data within child templates. For example, when our example page resolves to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "foods/banana"
      }), ", the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " variable will be set to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "banana"
      }), " row. However, you may want to access data from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "food"
      }), " row. You can use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_parent_row"
      }), " value on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " to retrieve the parent row:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_route_level == 1 %}\n\t<h1>Categories</h1>\n\t<h2>{{dynamic_page_hubdb_row.hs_name}}</h2>\n    {% set rows = hubdb_table_rows(dynamic_page_hubdb_row.hs_child_table_id) %}\n    {% for row in rows %}\n    \t<li><a href=\"{{ request.path }}/{{ row.hs_path }}\">{{ row.hs_name }}</a></li>\n    {% endfor %}\n{% elif dynamic_page_route_level == 2 %}\n\t<h1>Categories</h1>\n    <h2>{{dynamic_page_hubdb_row.hs_parent_row.hs_name}}</h2>\n    <h3>{{dynamic_page_hubdb_row.hs_name}}</h3>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Continue building out templates for each needed level. Then, you'll need to l ink the parent table to a page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Link parent table to page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The final step is to create a page from the multilevel template and link the top-level parent table to the page. To do so, you'll ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-customize-pages#create-pages",
        children: "create a page"
      }), ", then access its settings. In the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Advanced Options"
      }), " settings of the page editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Data source"
      }), " dropdown menu and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Parent table"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/dynamic-pages-dropdown-menu.png",
        alt: "dynamic-pages-dropdown-menu"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}