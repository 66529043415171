"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158721871211;
const slug = exports.slug = 'getting-started/what-to-build';
const title = exports.title = 'Que concevoir';
const name = exports.name = 'Que concevoir (documentation 2024 pour les développeurs)';
const metaDescription = exports.metaDescription = 'Découvrez les différentes façons de développer sur HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "utiliser-des-api-et-cr%C3%A9er-des-int%C3%A9grations",
  "label": "Utiliser des API et créer des intégrations",
  "parent": null
}, {
  "depth": 1,
  "id": "commencer-%C3%A0-authentifier-les-appels-avec-une-application-priv%C3%A9e",
  "label": "Commencer à authentifier les appels avec une application privée",
  "parent": "utiliser-des-api-et-cr%C3%A9er-des-int%C3%A9grations"
}, {
  "depth": 1,
  "id": "concevoir-pour-le-marketplace-des-applications",
  "label": "Concevoir pour le marketplace des applications",
  "parent": "utiliser-des-api-et-cr%C3%A9er-des-int%C3%A9grations"
}, {
  "depth": 0,
  "id": "personnaliser-l-interface-utilisateur-hubspot",
  "label": "Personnaliser l'interface utilisateur HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-des-sites-web-personnalis%C3%A9s",
  "label": "Créer des sites web personnalisés",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      strong: "strong",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Que concevoir"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe plusieurs voies de développement possibles avec HubSpot, selon vos objectifs. Vous trouverez sur cette page une présentation de chaque option ainsi que les outils et les comptes dont vous aurez besoin à chaque fois. Vous trouverez également des liens vers des documents détaillés pour plus de contexte ainsi que des guides de démarrage rapide pertinents."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#use-apis-and-build-integrations",
          children: "Utiliser des API et créer des intégrations"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#get-started-authenticating-calls-with-a-private-app",
              children: "Commencer à authentifier les appels avec une application privée"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#build-for-the-app-marketplace",
              children: "Concevoir pour le marketplace des applications"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#customize-the-hubspot-ui",
          children: "Personnaliser l'interface utilisateur HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-custom-websites",
          children: "Créer des sites web personnalisés"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utiliser des API et créer des intégrations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisez les API de HubSpot pour concevoir des solutions personnalisées, telles que le partage de données entre HubSpot et des systèmes externes, l'utilisation de webhooks pour consulter les changements dans le compte et la création d'objets personnalisés pour stocker des données spécifiques à votre entreprise."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemples de cas d'utilisation :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utilisez des objets personnalisés pour adapter la façon dont le CRM stocke les données afin qu'il représente au mieux votre entreprise."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Synchronisez les données provenant de systèmes externes pour obtenir une vision plus riche des activités de mise sur le marché."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Étendez les capacités de l'interface utilisateur du CRM pour l'adapter au mieux à vos processus."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Deux façons de créer des applications"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Il existe deux ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "types d'applications"
        }), ", en fonction de ce que vous concevez. Si vous commencez à utiliser des API et des intégrations, il est recommandé de commencer par une application privée, car elle est plus rapide à configurer et à utiliser pour l'authentification. Une fois que vous serez familiarisé avec la création d'applications, vous souhaiterez peut-être en savoir plus sur les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-for-the-app-marketplace",
          children: "applications publiques"
        }), ", qui peuvent être installées sur plusieurs comptes et vous permettent de créer d'autres types d'extensions."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exemple de cas d'utilisation pour les applications privées :"
        }), " _« Je veux créer une application pour mon entreprise/équipe. »"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Commencer à authentifier les appels avec une application privée"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La plupart des appels d'API nécessitent une authentification pour interagir avec les données de votre compte HubSpot. Pour commencer à effectuer des appels vers votre compte, créez une application privée et utilisez son jeton d'accès pour l'authentification."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Des autorisations de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "super administrateur"
        }), " sont nécessaires pour créer des applications privées dans votre compte HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Comment commencer :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Créez une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "application privée"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
          children: "les appels d'API authentifiés avec des applications privées"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous pouvez également utiliser les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/overview",
          children: "bibliothèques clients"
        }), " pour effectuer des appels d'API."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Concevoir pour le marketplace des applications"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Développez des intégrations pour le marketplace des applications de HubSpot avec une application publique pour permettre aux utilisateurs de HubSpot d'installer votre intégration dans leur compte. La création d'applications publiques pour le marketplace nécessite le respect des directives du marketplace des applications de HubSpot ainsi qu'un compte de développeur d'applications."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Les applications publiques s'authentifient avec OAuth. Les applications publiques peuvent être installées sur plusieurs comptes et peuvent être distribuées sur le marketplace."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exemples de cas d'utilisation pour les applications publiques :"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_« Je suis un partenaire de HubSpot et souhaite créer une application réutilisable que je puisse adapter pour mes clients. »"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_« Je suis un partenaire de HubSpot et souhaite mettre une application à disposition sur le marketplace afin de promouvoir nos capacités auprès du plus grand nombre possible de clients de HubSpot. »"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Comment commencer :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-hubspot/developers",
            children: "Créez un compte de développeur d'applications"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Démarrage rapide :"
          }), " ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "créez une application publique"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/oauth-quickstart-guide",
            children: "Suivez le guide de démarrage rapide OAuth"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
            children: "Consultez les conditions relatives aux listings sur le marketplace des applications"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "Soumettez votre application sur le marketplace des applications"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Personnaliser l'interface utilisateur HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " La création d'extensions d'interface utilisateur nécessite un abonnement ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Sales Hub"
          })
        }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Service Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Entreprise"
        }), ". Cependant, vous pouvez commencer à les concevoir gratuitement avec des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "comptes de test pour développeurs"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En plus des éléments d'interface utilisateur que HubSpot fournit pour les fiches d'informations CRM, vous pouvez également personnaliser le CRM avec des extensions d'interface utilisateur. Ces extensions sont créées localement à l'aide de l'outil Projets développeur, ce qui vous permet de concevoir et de déployer vers HubSpot à l'aide de l'ILC. Le kit de développement logiciel pour les extensions d'IU fournit des outils avec des méthodes, des fonctionnalités, des outils et des composants permettant de personnaliser votre extension. Si vous ne savez pas par où commencer avec les extensions d'interface utilisateur, consultez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "les exemples de projets de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Les projets vous permettent de concevoir et de déployer localement des applications privées, des extensions d'interface utilisateur et des fonctions sans serveur à l'aide de l'ILC de HubSpot."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exemples de cas d'utilisation pour les extensions d'interface utilisateur :"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_« Je souhaite ajouter un formulaire personnalisé aux fiches d'informations de contact et d'entreprise pour permettre à notre équipe d'assistance client de créer des tickets Jira pendant les appels des clients. »"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_« J'ai besoin de faire apparaître des résumés détaillés du pipeline de vente dans les dossiers de transaction afin que nos partenaires de gestion puissent rapidement trouver les informations dont ils ont besoin. »"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Comment commencer :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous n'avez pas d'abonnement ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " ou ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Service Hub"
          }), " Entreprise"]
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "créez un compte de test de développeur"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Rejoignez la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/overview#get-started",
          children: "version bêta des projets de développement"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Démarrage rapide :"
        }), " consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/quickstart",
          children: "guide de démarrage rapide pour les extensions d'interface utilisateur"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Découvrez-en davantage sur le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sdk",
          children: "kit de développement logiciel pour les extensions d'IU"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer des sites web personnalisés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Grâce au logiciel CMS (système de gestion de contenu) de HubSpot, vous pouvez créer de puissants sites web et pages de destination qui s'adaptent et se personnalisent en fonction des visiteurs de votre site. Grâce au CMS, chaque interaction des visiteurs du site web est directement connectée au CRM du compte, le même outil que les équipes de vente, de marketing, de service client et de RevOps utilisent pour travailler plus efficacement."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Concevez des sites web HubSpot localement dans votre environnement préféré via l'ICL de HubSpot, en utilisant des technologies telles que :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML, CSS et JavaScript"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubL, le langage de modélisation basé sur Jinjava de HubSpot"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "React, qui peut être utilisé pour créer des modules et des partials en JavaScript"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "GraphQL, que vous pouvez utiliser pour interroger les données de HubSpot"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous préférez utiliser GitHub pour le contrôle des versions, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "configurer une intégration grâce aux actions GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Le CMS vous permet de créer des sites web localement avec l'ILC de HubSpot. Vous aurez accès à un éventail d'outils polyvalents et adaptables, tels que la prise en charge multilingue, les CTA, les formulaires, les adhésions, etc. HubSpot se charge également de la sécurité, des performances côté serveur, du réseau de diffusion de contenu, de l'hébergement et de l'assurance d'une disponibilité optimale pour vous."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exemples de cas d'utilisation du CMS :"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_« Je souhaite créer des pages de listing de produits qui extraient des informations directement à partir de l'inventaire géré dans le CRM. »"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_« J'ai besoin d'un site web qui comporte un blog public et un blog réservé aux membres. »"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Créez un compte ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-hubspot/cms-developers",
          children: "d'environnement de test CMS"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Installez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "l'ILC HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Installez l'extension ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
          children: "HubSpot Visual Studio Code"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Démarrage rapide :"
        }), " consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "guide de démarrage rapide du CMS HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consultez les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms",
          children: "guides CMS"
        }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms",
          children: "documents de référence"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}