"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501377975;
const slug = exports.slug = 'guides/api/conversations/working-with-chatbots/code-snippets';
const title = exports.title = 'Conversaciones | Ejecutar fragmentos de código en bots';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Running Code Snippets in bots';
const metaDescription = exports.metaDescription = 'Instrucciones y para ejecutar tus propios fragmentos de código en una acción de bot';
const toc = exports.toc = [{
  "depth": 0,
  "id": "limitaciones",
  "label": "Limitaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "bibliotecas-disponibles",
  "label": "Bibliotecas disponibles",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      h2: "h2"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Ejecutar fragmentos de código en bots"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/articles/kcs_article/conversations/create-a-bot",
        children: "crear o editar un bot"
      }), ", puedes agregar un fragmento de código haciendo clic en «+» para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/articles/kcs_article/conversations/a-guide-to-bot-actions",
        children: "agregar una acción"
      }), " como normalmente lo harías. En el panel de selección de acción, haz clic en «Ejecutar un fragmento de código»", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/KB%20Team/run-a-code-snippet-2.png?width=322&name=run-a-code-snippet-2.png",
        alt: "run-a-code-snippet-2"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, asígnale un apodo a tu acción. En el panel de edición de códigos, verás nuestra plantilla predeterminada para Node.js 10. x. Los detalles del objeto «event» y los formatos de objeto de respuesta posibles se detallan a continuación.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/KB%20Team/run-a-code-snippet-editor.png?width=388&name=run-a-code-snippet-editor.png",
        alt: "run-a-code-snippet-editor"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El código se activará cuando se alcance la acción guardada en una conversación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay tres aspectos principales que debes tener en cuenta al trabajar con fragmentos de código:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La función ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "exports.main()"
        }), " se llama cuando se ejecuta la acción del fragmento de código."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El argumento ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "event"
        }), " es un objeto que contiene detalles para el visitante y la sesión de chat."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La función ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "callback()"
        }), " se utiliza para pasar datos al chatbot y al usuario. Se debe llamar en la función ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "exports.main"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "event"
      }), " contendrá los siguientes datos:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example payload\n{\n  \"userMessage\": {\n  // Details for the last message sent to your bot\n    \"message\": \"100-500\",\n    // The last message received by your bot, sent by the visitor\n    \"quickReply\": {\n    // If the visitor selected any quick reply options, this will be a list of the selected options.\n    // Will be 'null' if no options were selected.\n      \"quickReplies\":[\n      // A list of quick reply options selected by the visitor\n         {\n            \"value\":\"100-500\",\n            \"label\":\"100-500\"\n         }\n      ],\n  },\n  \"session\": {\n    \"vid\": 12345,\n    // The contact VID of the visitor, if known.\n    \"properties\": {\n    // A list of properties collected by the bot in the current session.\n      \"CONTACT\": {\n        \"firstname\": {\n          \"value\": \"John\",\n          \"syncedAt\": 1534362540592\n        },\n        \"email\": {\n          \"value\": \"testing@domain.com\",\n          \"syncedAt\": 1534362541764\n        },\n        \"lastname\": {\n          \"value\": \"Smith\",\n          \"syncedAt\": 1534362540592\n        }\n      }\n    },\n  \"customState\":{myCustomCounter: 1, myCustomString:\"someString\"}\n  // Only present if it customState was passed in from a previous callback payload\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La función ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callback()"
      }), " se utiliza para enviar datos al bot. El argumento debe ser un objeto con los siguientes datos:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//sample payload\n{\n  \"botMessage\": \"Thanks for checking out our website!\",\n  // This is the message your bot will display to the visitor.\n  \"quickReplies\": [{ value:'option',\n  // Passed to the bot as the response on click\n   label:'Option' // Gets displayed as the button label\n    }],\n  // the quickReplies object is optional\n  \"nextModuleNickname\": \"SuggestAwesomeProduct\",\n  // The nickname of the next module the bot should execute. If undefined, the bot will follow the default configured behavior\n  \"responseExpected\": false\n  // If true, the bot will display the returned botMessage, wait for a response, then execute this code snippet again with that new response.\n  \"customState\":{myCustomCounter: 1, myCustomString:\"someString\"}\n  // Optional field to pass along to the next step.\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limitaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los fragmentos de código en los bots deben terminar en un lapso de 20 segundos y solo usar 128 MB de memoria. Exceder estos límites ocasionará un error."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bibliotecas disponibles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Varias bibliotecas de Node.js están disponibles para usar dentro del fragmento de código."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/async",
          children: "async"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/lodash",
          children: "lodash"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/mongoose",
          children: "mongoose"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/mysql",
          children: "mysql"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/redis",
          children: "redis"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/request",
          children: "request"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/aws-sdk",
          children: "aws-sdk"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las bibliotecas se pueden cargar usando la función ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require()"
      }), " normal en la parte superior de tu código."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const request = require('request');\n\nexports.main = (event, callback) => {\n  request('http://time.jsontest.com/', function (error, response, body) {\n    const responseJson = {\n      botMessage: 'The current time in GMT is ' + JSON.parse(body).time,\n      responseExpected: false,\n    };\n\n    callback(responseJson);\n  });\n};\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}