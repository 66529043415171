'use es6';

const namespacedKeyMirror = (obj, namespace) => {
  const result = {};
  if (!(obj instanceof Object) || Array.isArray(obj)) {
    throw new Error('namespacedKeyMirror expected the first argument to be an object');
  }
  if (!(namespace instanceof String || typeof namespace === 'string') || namespace.length === 0) {
    throw new Error('namespacedKeyMirror expected the namespace to be a non-zero lengthed string');
  }
  let key;
  for (key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = `${namespace}/${key}`;
    }
  }
  return result;
};
export default namespacedKeyMirror;