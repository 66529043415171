"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492284304;
const slug = exports.slug = 'reference/cms/hubl/overview';
const title = exports.title = 'HubL-Syntax';
const name = exports.name = 'EMEA DACH (DE) HubL Syntax';
const metaDescription = exports.metaDescription = 'HubL ist die von Jinja inspirierte Vorlagensprache, die für die Erstellung von Vorlagen und Modulen im CMS-System von HubSpot verwendet wird. if-Anweisungen, for-Schleifen, Includes usw.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "typen-von-trennzeichen",
  "label": "Typen von Trennzeichen",
  "parent": null
}, {
  "depth": 1,
  "id": "anweisungen",
  "label": "Anweisungen",
  "parent": "typen-von-trennzeichen"
}, {
  "depth": 1,
  "id": "ausdr%C3%BCcke",
  "label": "Ausdrücke",
  "parent": "typen-von-trennzeichen"
}, {
  "depth": 2,
  "id": "do-tag",
  "label": "do-Tag",
  "parent": "typen-von-trennzeichen"
}, {
  "depth": 1,
  "id": "kommentare",
  "label": "Kommentare",
  "parent": "typen-von-trennzeichen"
}, {
  "depth": 0,
  "id": "module",
  "label": "Module",
  "parent": null
}, {
  "depth": 0,
  "id": "variablen-und-makros",
  "label": "Variablen und Makros",
  "parent": null
}, {
  "depth": 0,
  "id": "filter-und-funktionen",
  "label": "Filter und Funktionen",
  "parent": null
}, {
  "depth": 0,
  "id": "if-anweisungen",
  "label": "if-Anweisungen",
  "parent": null
}, {
  "depth": 0,
  "id": "for-schleifen",
  "label": "for-Schleifen",
  "parent": null
}, {
  "depth": 0,
  "id": "andere-hubl-funktionen",
  "label": "Andere HubL-Funktionen",
  "parent": null
}, {
  "depth": 1,
  "id": "fluchtzeichen-f%C3%BCr-hubl-trennzeichen",
  "label": "Fluchtzeichen für HubL-Trennzeichen",
  "parent": "andere-hubl-funktionen"
}, {
  "depth": 1,
  "id": "einbinden-von-dateien-in-dateien",
  "label": "Einbinden von Dateien in Dateien",
  "parent": "andere-hubl-funktionen"
}, {
  "depth": 1,
  "id": "bl%C3%B6cke-und-erweiterungen",
  "label": "Blöcke und Erweiterungen",
  "parent": "andere-hubl-funktionen"
}, {
  "depth": 1,
  "id": "abschnitts-hubl-kopieren",
  "label": "Abschnitts-HubL kopieren",
  "parent": "andere-hubl-funktionen"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      pre: "pre",
      code: "code",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Überblick über die HubL-Syntax"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das CMS von HubSpot verwendet die HubSpot Markup Language, kurz HubL („Hubble“ ausgesprochen). HubL ist die HubSpot-Erweiterung von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), ", einer auf ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jinja.palletsprojects.com/en/latest/",
        children: "Jinja"
      }), " basierenden Templating-Engine. HubL verwendet eine ganze Reihe von Markups, die nur in HubSpot vorkommen, und unterstützt nicht alle Funktionen von Jinja."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Artikel werden Sie mit den Grundlagen der Funktionen und der Syntax von HubL vertraut gemacht."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Typen von Trennzeichen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ähnlich wie andere häufig verwendete Vorlagensprachen wie PHP kann HubL in Form von codierten Vorlagendateien oder HubL-Vorlagenmodulen in Ihr HTML eingebunden werden. Um zu unterscheiden, wo Ihr HubL-Code beginnt und endet, müssen Sie einige Schlüsselsymbole lernen, die als Trennzeichen dienen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% %} - statement delimiters\n{{ }} - expression delimiters\n{# #} - comment delimiters\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Seien Sie vorsichtig, wenn Sie Kommentare in Ihrem Code verschachteln, da dies dazu führen kann, dass das nachfolgende comment-Tag als Text gerendert wird."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Anweisungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL-Anweisungen werden verwendet, um bearbeitbare Module zu erstellen, bedingte Vorlagenlogik zu definieren, for-Schleifen einzurichten, Variablen zu definieren und vieles mehr. Anweisungen werden durch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{%"
      }), " getrennt. Es wird nichts auf die Seite ausgegeben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ausdrücke"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ausdrücke drucken Werte, die im Kontext der Vorlage gespeichert sind. Ausdrücke werden durch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{"
      }), " getrennt. Beispiel: eine Variable muss als Anweisung definiert werden, aber gedruckt wird sie über einen HubL-Ausdruck."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "do-Tag"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das 'do'-Tag funktioniert genau wie die regulären Anweisungen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% ... %}"
      }), "; Damit können Listen und Dictionaries bearbeitet werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hinweis: Wenn Sie Arrays hinzufügen, sollten Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#append",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".append()"
        })
      }), " verwenden, und beim Hinzufügen zu Objekten sollten Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#update",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".update()"
        })
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "# Arrays\n{% set navigation = [\"Home\", \"About\"] %}\n{% do navigation.append(\"Contact Us\") %}\n{{navigation}}\n\n# Objects\n{% set book = {\"name\" : \"Rocking HubL\", \"author\" : \"John Smith\" }%}\n{% do book.update({\"ebook\" : \"yes\"}) %}\n{{book}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "# Arrays [Home, About, Contact Us] # Objects {name=Rocking HubL, author=John\nSmith, ebook=yes}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Kommentare"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die letzte Art von Trennzeichen, auf die Sie beim Entwickeln mit HubL stoßen oder die Sie verwenden können, ist ein HubL-Kommentar. Kommentare werden durch ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{#"
      }), " definiert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Module"
      }), " sind dynamische Bereiche einer Vorlage, die vom Endbenutzer im Content-Editor angepasst werden können. Wenn Sie beispielsweise eine Vorlagendatei von Grund auf neu codieren möchten, müssen Sie den Vorlagen mit HubL-Tags Module hinzufügen, um Ihren Content-Autoren die Möglichkeit zu geben, Bereiche der Seite zu bearbeiten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Modul-Tags setzen sich aus folgenden Komponenten zusammen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modultyp:"
        }), " gibt an, welches Modul gerendert werden soll. Eine Liste der verschiedenen verfügbaren Modultypen finden Sie auf der Seite ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags",
          children: "Unterstützte HubL-Tags"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ein eindeutiger Name für dieses Modul:"
        }), " gibt dem Modul eine eindeutige Identität im Kontext der Vorlage."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Pfad:"
        }), " definiert je nach Tag den Ort, an dem sich das Modul im Design-Manager befindet. Der Pfad für HubSpot-Standardmodule sollte immer mit @hubspot/ beginnen, gefolgt von der Art des Moduls. Weitere Informationen finden Sie im nachstehenden Beispiel und auf der Seite für die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Verwendung von Modulen in Vorlagen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Parameter:"
        }), " geben Sie optional zusätzliche Modulinformationen an."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"unique_module_name\",\n  path=\"@hubspot/text\",\n  label=\"Single Text Line\",\n  value=\"This is a single text line\"\n%}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text widget-type-text\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"module\"\n  id=\"hs_cos_wrapper_text\"\n  style=\"\"\n>\n  <span\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"text\"\n    id=\"hs_cos_wrapper_text_\"\n    style=\"\"\n    >This is a single text line</span\n  >\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das obige Beispiel ist ein Textmodul, für den die Parameter „label“ und „value“ definiert sind. Die Bezeichnung definiert den Hilfetext im Editor und der Wert legt den Standardtext für dieses Modul fest. Wie das im Editor aussieht, zeigt das folgende Beispiel-GIF-Bild."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/module-label-value-min.gif",
        alt: "module-label-value-min"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Artikel erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "Verwendung von Modulen in Vorlagen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variablen und Makros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL enthält viele ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "vordefinierte HubSpot-Variablen"
      }), ", die ihre hilfreichen Werte über die App ausgeben. Darüber hinaus können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "ihre eigenen Variablen in einer Vorlage definieren"
      }), ". In dem folgenden Beispiel wird eine Variable namens ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primaryColor"
      }), " in einer Anweisung definiert und dann mit einem HubL-Ausdruck gedruckt. In diesem Beispiel wird die HubL-Variable mit CSS kombiniert."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set primaryColor = '#F7761F' %} {# defines variable and assigns HEX color #}\n\na {\n  color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit HubL-Makros können Sie mehrere Anweisungen mit einem dynamischen Wert drucken. Dieses Verfahren erweist sich als nützlich, wenn Sie immer wieder die gleichen grundlegenden Code-Blöcke schreiben, aber nur bestimmte Werte ändern müssen. Im folgenden Beispiel wird ein Makro verwendet, um eine CSS3-Übergangseigenschaft zu drucken, die alle Herstellerpräfixe enthält. In diesem Artikel ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "erfahren Sie mehr über Makros"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n  {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filter und Funktionen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filter können zu Ihrem HubL-Code hinzugefügt werden, um den Wert einer Vorlagenvariable zu transformieren oder zu ändern. Ein einfaches Beispiel für die Formatierung einer Datumsvariablen ist unten dargestellt. Filter verwenden ein | (Pipeline-Symbol) und werden ohne Leerzeichen auf eine Variable angewendet."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im folgenden Beispiel wird davon ausgegangen, dass ein Blog-Beitrag am 29. April veröffentlicht wurde. Das Veröffentlichungsdatum des Beitrags wird mit einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "datetimeformat"
      }), "-Filter formatiert. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "Eine vollständige Liste der Filter können Sie hier anzeigen."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date_local_time|datetimeformat(\"%B %e, %Y\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "April 29, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Während Filter die Darstellung von Variablen beeinflussen, verarbeiten Funktionen Wert- und Account-Informationen und stellen diese Informationen dar. Eine Funktion kann zum Beispiel verwendet werden, um die Gesamtzahl der Beiträge für einen bestimmten Blog zu ermitteln oder um eine Farbvariable um einen bestimmten Wert heller oder dunkler zu machen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das Beispiel würde die Gesamtzahl der Blog-Beiträge von designers.hubspot.com/blog ausgeben. Es verwendet einen Blog-ID-Parameter (verfügbar in der URL des Blog-Dashboards), um anzugeben, auf welchen Blog abgezielt werden soll. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "Eine vollständige Liste der Funktionen können Sie hier anzeigen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "// blog_total_post_count\n{{ blog_total_post_count(359485112) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "if-Anweisungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if-Anweisungen"
      }), " können Sie bedingte Logik verwenden, um festzulegen, wie Ihre Vorlage bedingte Logik in HubL-Anweisungen für ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "rendert. Eine if-Anweisung muss mit einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " beginnen und mit einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), " enden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das folgende Beispiel definiert ein Auswahlmodul, mit dem der Endbenutzer eine Abteilung in einem Dropdown-Menü auswählen kann. Je nachdem, was der Benutzer im Editor auswählt, generiert die Vorlage eine dynamische Überschrift für eine Karriereseite. Dieses Beispiel erfordert die Verwendung des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "export_to_template_context"
        })
      }), "-Parameters."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "for-Schleifen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for-Schleifen"
      }), " können Sie die in einer Sequenz gespeicherten Elemente wiederholt durchlaufen lassen. Sie werden am häufigsten bei der Darstellung von Blog-Inhalten in einem Listenformat verwendet. for-Schleifen beginnen mit einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), "-Anweisung und enden mit einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endfor"
      }), "-Anweisung."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im folgenden Beispiel wird ein Array von Bärenarten als Variable namens ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bears"
      }), " gespeichert. In einer for-Schleife werden dann die verschiedenen Arten von Bären durchlaufen und für jede Art ein Listenelement gedruckt."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bears = [\"panda bear\", \"polar bear\", \"black bear\", \"grizzly bear\", \"koala bear\"] %}\n\n<h1>Types of bears</h1>\n<ul>\n  {% for bear in bears %}\n  <li>{{ bear }}</li>\n  {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Types of bears</h1>\n<ul>\n  <li>panda bear</li>\n  <li>polar bear</li>\n  <li>black bear</li>\n  <li>grizzly bear</li>\n  <li>koala bear</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Andere HubL-Funktionen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend finden Sie einige weitere Funktionen für Vorlagen, die bei der Entwicklung mit HubL nützlich sein können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fluchtzeichen für HubL-Trennzeichen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Viele andere Sprachen verwenden dieselben Trennzeichen wie HubL, was bei der Arbeit in Code-Dateien im CMS zu Problemen führen kann. Wenn Sie ein HubL-Trennzeichen für eine andere Sprache verwenden möchten, müssen Sie diesen Code folgendermaßen umschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: " {{\"Code you want to escape\"}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{\"Code you want to escape\"}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einbinden von Dateien in Dateien"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#standard-partials",
        children: "include-Tag"
      }), " können Sie mehrere ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".html"
      }), "-Dateien in einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HubL-Vorlage"
      }), "berücksichtigen. Um eine HTML-Datei zu erstellen, die nicht die Standardvorlagenvariablen benötigt, müssen Sie die Option „Diese Vorlage für neuen Content zur Verfügung stellen“ deaktivieren. Die Syntax ist unten dargestellt:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"custom/page/web_page_basic/my_footer.html\" %}\n{% include \"hubspot/styles/patches/recommended.css\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können auch mehrere CSS-Dateien in eine einzige CSS-Datei mit demselben include-Tag kompilieren. Wenn Sie die übergeordnete Datei veröffentlichen, wird die untergeordnete Datei mit dem Code der übergeordneten Datei in eine einzige minifizierte CSS-Datei kompiliert."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blöcke und Erweiterungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei der Erstellung komplexer Vorlagen können Sie in Abschnitte unterteilte Blöcke erstellen, die eine übergeordnete Vorlage erweitern."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zunächst erstellen Sie eine Hauptvorlage, die die erforderlichen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), "- und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), "-Variablen einschließt. Innerhalb dieser Vorlage müssen Sie einen eindeutigen Block mit der folgenden Syntax definieren, wobei ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_sidebar"
      }), " ein eindeutiger Name ist:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"custom/page/web_page_basic/my_template.html\" %}\n{% block my_sidebar %}\n<h3>Sidebar title</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anschließend können Sie eine untergeordnete HTML-Datei erstellen, die diesen Block auffüllt. Zunächst müssen Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#blocks-and-extends",
        children: "extends-Anweisung"
      }), " deklarieren, die auf den Pfad zum übergeordneten Element verweist. Dieser Code-Block würde in der übergeordneten Vorlage gerendert, aber in einer anderen, kleineren und besser zu verwaltenden Datei gespeichert. Dieses Verfahren ist nicht jedermanns Sache, kann jedoch nützlich sein, um beim Programmieren komplexer E-Mail- oder Seitenvorlagen den Überblick zu behalten. Wenn Sie diese Technik verwenden, sollten Sie bei der Erstellung von Inhalten die untergeordnete Vorlage wählen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abschnitts-HubL kopieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Seiten-Editor können Sie das HubL-Markup für einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: "Drag-&-Drop-Abschnitt"
      }), " kopieren, um den Code bei Bedarf wiederzuverwenden. Dies kann hilfreich sein, wenn Sie einen Drag-&-Drop-Abschnitt in einer Code-Datei neu erstellen wollen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-section-hubl-menu.png",
        alt: "copy-section-hubl-menu"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#copy-section-hubl",
        children: "Kopieren von Abschnitts-HubL"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}