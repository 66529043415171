/* hs-eslint ignored failing-rules */
/* eslint-disable no-bitwise */

'use es6';

import { CODE_FONT_FAMILY, CODE_FALLBACK_FONT_STACK } from 'HubStyleTokens/misc';
import memoize from 'react-utils/memoize';

/**
 * Matches the font name without quotes.
 * @type {RegExp}
 */
const FONT_NAME_PATTERN = /^['"]?((?:\b\S+\b\s)+(?:\b\S+\b)|\b\S+\b)['"]?$/;

/**
 * Formatted HubStyleTokens/misc/CODE_FONT_FAMILY
 * @type {String}
 */
const DEFAULT_FONT = formatFontName(CODE_FONT_FAMILY);

/**
 * Strip and add quotes for multi-word fonts.
 * @param  {String} fontname
 * @return {String}
 */
function formatFontName(fontname) {
  if (!fontname) {
    return '';
  }
  const match = FONT_NAME_PATTERN.exec(fontname);
  const matchName = match && match[1];
  if (matchName) {
    return !~matchName.search(/\s/) ? matchName : `"${matchName}"`;
  }
  // Asserting that the regex would have matched any valid truthy string.
  // The regex will fail if something like `fontName="Foo, Bar"` is attempted.
  console.warn(`Invalid fontFamily: "${fontname}"`);
  /* eslint-enable no-console */
  return '';
}

/**
 * Creates and formats a font-family string with preferred font name.
 *
 * @param  {String} fontname
 * @return {String}
 */
export const createFontFamily = memoize(fontname => {
  const font = fontname ? formatFontName(fontname) : DEFAULT_FONT;
  const name = font && font !== DEFAULT_FONT ? `${font}, ` : '';
  return `${name}${DEFAULT_FONT}, ${CODE_FALLBACK_FONT_STACK}`;
});

/**
 * Creates the theme class(es) for a theme name. This is already done by CodeMirror
 * internally. This util is for when the class name(s) are needed by other components.
 *
 * @param  {String}   name            - Theme name(s).
 * @param  {Boolean}  withCodeMirror  - Many themes specify CodeMirror in their main selector.
 * @return {String}
 */
export function getThemeClassFromThemeName(name, withCodeMirror = false) {
  if (!name) {
    return '';
  }
  return name.trim().split(/\s+/).map(theme => `cm-s-${theme}`).concat(withCodeMirror ? 'CodeMirror' : '').join(' ');
}