"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937480;
const slug = exports.slug = 'guides/api/crm/owners';
const title = exports.title = 'CRM-API | Zuständige Mitarbeiter';
const name = exports.name = 'vNext Docs DP - Zuständige Mitarbeiter';
const metaDescription = exports.metaDescription = 'HubSpot verwendet zuständige Mitarbeiter, um CRM-Objekte zu bestimmten Personen zuzuweisen. Diese Endpunkte werden verwendet, um eine Liste der verfügbaren zuständigen Mitarbeiter für einen Account zu erhalten. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "liste-mit-zust%C3%A4ndigen-mitarbeitern-abrufen",
  "label": "Liste mit zuständigen Mitarbeitern abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "informationen-%C3%BCber-einen-einzelnen-zust%C3%A4ndigen-mitarbeiter-abrufen",
  "label": "Informationen über einen einzelnen zuständigen Mitarbeiter abrufen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Zuständige Mitarbeiter"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zuständige Mitarbeiter in HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: "weisen"
      }), " bestimmten Benutzern Datensätze, Aktivitäten oder Marketingaufgaben zu und können in Personalisierungstoken für Ihre Inhalte verwendet werden. Zuständige Mitarbeiter werden automatisch in HubSpot erstellt und aktualisiert, wenn neue Benutzer hinzugefügt oder bestehende zuständige Mitarbeiter mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/salesforce/how-will-a-salesforce-owner-will-be-recognized-by-hubspot",
        children: "Salesforce"
      }), " synchronisiert werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die API-Endpunkte der zuständigen Mitarbeiter sind schreibgeschützt, sodass Sie sie verwenden können, um die Identifizierungsdetails eines zuständigen Mitarbeiters abzurufen, einschließlich der ID. Diese ID kann dann verwendet werden, um CRM-Datensätzen in HubSpot über eine Integration oder über API-Aufrufe zum Ändern von Eigenschaften die Eigentümerschaft zuzuweisen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Liste mit zuständigen Mitarbeitern abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die aktuellen zuständigen Mitarbeiter in Ihrem Account abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners"
      }), " durch. In der Antwort werden Name, E-Mail-Adresse, ID-Werte, Erstellungs-/Aktualisierungsdaten und gegebenenfalls Teaminformationen jedes Benutzers zurückgegeben. Es werden zwei ID-Werte zurückgegeben, die für unterschiedliche Zwecke verwendet werden:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": die ID des zuständigen Mitarbeiters. Dieser Wert sollte beim Abrufen von Informationen über einen bestimmten zuständigen Mitarbeiter und beim Zuweisen eines zuständigen Mitarbeiters zu einem Datensatz oder einer Aktivität verwendet werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), ": die ID des Benutzers Dieser Wert kann verwendet werden, um Benutzer in der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "Einstellungs-API"
        }), " zu bestimmen, führt jedoch zu einem Fehler, wenn er zur Zuweisung des Eigentümerschaft verwendet wird."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre Antwort sieht wie folgt aus:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners\n{\n  \"results\": [\n    {\n      \"id\": \"41629779\",\n      \"email\": \"email@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"HubSpot\",\n      \"lastName\": \"Test Owner\",\n      \"userId\": 9586504,\n      \"userIdIncludingInactive\": 9586504,\n      \"createdAt\": \"2019-12-25T13:01:35.228Z\",\n      \"updatedAt\": \"2023-08-22T13:40:26.790Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"60158084\",\n      \"email\": \"email@gmail.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Test\",\n      \"lastName\": \"Email\",\n      \"userId\": 9274996,\n      \"userIdIncludingInactive\": 9274996,\n      \"createdAt\": \"2021-02-10T17:59:04.891Z\",\n      \"updatedAt\": \"2023-02-09T17:41:52.767Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"81538190\",\n      \"email\": \"salesmanager@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Sales\",\n      \"lastName\": \"Manager Example\",\n      \"userId\": 3892666,\n      \"userIdIncludingInactive\": 3892666,\n      \"createdAt\": \"2021-05-27T16:55:57.242Z\",\n      \"updatedAt\": \"2022-08-02T18:34:35.039Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können auch archivierte zuständige Mitarbeiter abrufen, um deaktivierte Benutzer anzuzeigen. Fügen Sie dazu den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "archived"
      }), "-Parameter mit dem Wert ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " hinzu. Für archivierte Benutzer gibt es immer noch einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "-Wert, aber der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userId"
      }), "-Wert ist ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), ". Stattdessen wird die Benutzer-ID im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userIdIncludingInactive"
      }), "-Feld gespeichert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners/?archived=true\n{\n  \"results\": [\n    {\n      \"id\": \"42103462\",\n      \"email\": \"useremail@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"\",\n      \"lastName\": \"\",\n      \"userId\": null,\n      \"userIdIncludingInactive\": 9685555,\n      \"createdAt\": \"2020-01-09T20:28:50.080Z\",\n      \"updatedAt\": \"2020-01-09T20:28:50.080Z\",\n      \"archived\": true\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informationen über einen einzelnen zuständigen Mitarbeiter abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen bestimmten zuständigen Mitarbeiter abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners/{ownerId}"
      }), " durch. Sie sollten den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "-Wert verwenden, um den zuständigen Mitarbeiter anzugeben, über den Sie weitere Details wünschen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), "-Wert in der Antwort ändert sich basierend auf Aktualisierungen des Objekts „Zuständiger Mitarbeiter“ selbst. Er wird nicht bei Änderungen am „Benutzer“-Objekt aktualisiert. Bei einer Änderung der Berechtigungen eines Benutzers wird zum Beispiel ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), "-Wert aktualisiert."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}