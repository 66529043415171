"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694125;
const slug = exports.slug = 'guides/cms/content/themes/overview';
const title = exports.title = 'Visão geral do tema';
const name = exports.name = 'Visão geral do tema';
const metaDescription = exports.metaDescription = 'Criando temas do HubSpot CMS que permitem aos desenvolvedores construir sistemas de design nos quais os criadores de conteúdo podem trabalhar. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/themes.gif';
const featuredImageAltText = exports.featuredImageAltText = 'Temas da HubSpot';
const toc = exports.toc = [{
  "depth": 0,
  "id": "temas-como-um-pacote",
  "label": "Temas como um pacote",
  "parent": null
}, {
  "depth": 1,
  "id": "cria%C3%A7%C3%A3o-de-p%C3%A1gina",
  "label": "Criação de página",
  "parent": "temas-como-um-pacote"
}, {
  "depth": 1,
  "id": "campos-de-tema",
  "label": "Campos de tema",
  "parent": "temas-como-um-pacote"
}, {
  "depth": 1,
  "id": "m%C3%B3dulos-do-tema",
  "label": "Módulos do tema",
  "parent": "temas-como-um-pacote"
}, {
  "depth": 0,
  "id": "estrutura-de-arquivos-do-tema",
  "label": "Estrutura de arquivos do tema",
  "parent": null
}, {
  "depth": 1,
  "id": "theme.json",
  "label": "theme.json",
  "parent": "estrutura-de-arquivos-do-tema"
}, {
  "depth": 1,
  "id": "fields.json",
  "label": "fields.json",
  "parent": "estrutura-de-arquivos-do-tema"
}, {
  "depth": 2,
  "id": "usar-valores-de-campo-de-tema",
  "label": "Usar valores de campo de tema",
  "parent": "estrutura-de-arquivos-do-tema"
}, {
  "depth": 0,
  "id": "visualizar-temas",
  "label": "Visualizar temas",
  "parent": null
}, {
  "depth": 1,
  "id": "modo-de-teste",
  "label": "Modo de teste",
  "parent": "visualizar-temas"
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h4: "h4",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral dos temas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um tema é um conjunto transferível e contido de recursos para desenvolvedores, projetado para permitir uma experiência flexível de edição de conteúdo. Você pode", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: " desenvolver temas localmente com a CLI do HubSpot"
      }), " usando suas ferramentas, tecnologias e fluxos de trabalho favoritos. Os temas e todos os seus arquivos também podem ser transferidos entre ambientes e contas. Para ver uma apresentação em vídeo sobre como começar a desenvolver temas, assista ao vídeo do HubSpot Academy abaixo:"]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172844605010"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Temas como um pacote"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os temas são um pacote que pode ser aplicado ao aplicativo HubSpot em vários locais para permitir uma experiência eficiente de criação de conteúdo. Os desenvolvedores podem usar temas para criar um sistema de design dentro do qual os criadores de conteúdo trabalharão. Qualquer nível de flexibilidade, ou de contenções, pode ser incorporado em um tema para atender às necessidades do seu negócio."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criação de página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando os criadores de conteúdo começam a criar novas páginas, eles são solicitados a selecionar um tema de partida para a página e o modelo dentro do tema a ser utilizado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20selection.gif",
        alt: "Seleção de um tema no HubSpot"
      }), "A imagem de visualização do tema, assim como outras configurações, estão definidas no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#theme-json",
        children: "arquivo theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Campos de tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os temas permitem aos desenvolvedores criar um conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "campos de tema"
      }), ", semelhantes aos Campos de módulo que, por sua vez, permitem aos criadores de conteúdo ajustar vários botões e mostradores projetados por um desenvolvedor para permitir o controle sobre o estilo global de um site sem a necessidade de editar o CSS. Os desenvolvedores ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#using-theme-field-values",
        children: "usam o HubL para acessar os valores dos campos do tema"
      }), " em todo o CSS. Os criadores de conteúdo usam o Editor de tema para modificar os Campos do tema, visualizar essas alterações em relação aos modelos existentes dentro de um tema e publicar suas alterações."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/themes.gif",
        alt: "temas"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os campos são determinados pelo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "arquivo fields.json de um tema"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao editar um tema no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#test-mode",
        children: "modo de teste"
      }), ", você também pode copiar o JSON das configurações do tema. Assim, você pode colar quaisquer atualizações no arquivo local ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " do tema.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode.png",
        alt: "copy-theme-settings-test-mode"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Módulos do tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os módulos dentro de um tema devem ser concebidos especificamente para utilização dentro dos modelos desse tema. O editor de conteúdo destacará esses módulos de tema, tornando rápido e fácil para os criadores de conteúdo adicionar módulos às páginas que estão construindo que são projetados para funcionar bem no contexto daquela página. Os módulos padrão e o resto dos módulos na sua conta da HubSpot ainda estarão disponíveis."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20modules.png",
        alt: "Módulos em um tema"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao desenvolver um tema, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/hide-modules-and-sections",
        children: "ocultar módulos e seções no editor de página"
      }), " para criar uma experiência de criação de conteúdo mais simplificada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Estrutura de arquivos do tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um tema é um diretório único de arquivos. Você pode incluir arquivos HTML, CSS e Javascript, módulos e arquivos adicionais que podem ser organizados de qualquer forma dentro dos subdiretórios da pasta de temas principal. Dois arquivos JSON são necessários para criar um tema: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ". Esses arquivos devem ser incluídos na pasta raiz do tema."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para começar com um exemplo, consulte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "HubSpot CMS Boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hs-fs/hubfs/theme%20file%20structure.png?width=599&height=452&name=theme%20file%20structure.png",
        alt: "estrutura de arquivos do tema"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No momento, os arquivos .json só podem ser criados e carregados em uma conta da HubSpot por meio das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "ferramentas de desenvolvimento local"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "theme.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " contém as informações de metadescrição do diretório do tema, como o rótulo legível do tema, captura de tela de visualização e várias configurações quanto ao comportamento do tema. O arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " será parecido com o seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O rótulo legível do tema, utilizado em vários locais em que o tema é mostrado na aplicativo HubSpot, como a tela de seleção de modelos e o editor de temas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "preview_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um caminho relativo para um arquivo de modelo no tema que deve ser o modelo padrão usado ao visualizar o tema no editor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshot_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um caminho relativo para um arquivo de imagem que é usado para fornecer uma imagem da aparência do tema nos vários locais em que ele é aplicado, por exemplo, na tela de seleção do modelo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enable_domain_stylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ativar ou desativar as folhas de estilo anexadas aos domínios nas Configurações do site que são incluídas nos modelos do tema. O valor por padrão é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Número inteiro da versão que oferece suporte a versões ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "."
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O objeto para fornecer informações sobre você como fornecedor do tema.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "O nome do fornecedor. ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "O endereço de e-mail de suporte do fornecedor. ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "O site do fornecedor.", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "documentation_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O link para a documentação do tema."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "example_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O link de exemplo dinâmico do tema."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "license"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://spdx.org/licenses/",
              children: "identificador SPDX"
            }), " válido ou o caminho relativo à licença dentro do seu tema.Essa licença determina os tipos de uso e modificação que são permitidos pelo criador do tema. É útil ao enviar para o marketplace."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Booleano que determina se um tema aparece na página do criador de conteúdo para seleção. O valor por padrão é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "fields.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " controla os campos e grupos de campos disponíveis no editor de temas, incluindo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "campos de estilo"
      }), ". Os campos que você decide incluir dependem de quanto controle você quer que os criadores de conteúdo tenham no editor de páginas. O número de campos disponíveis para os temas é mais limitado do que para os módulos, pois os campos de tema são melhores para opções de estilo, enquanto que o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "conteúdo global"
      }), " é melhor para o conteúdo do tema."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, em vez de adicionar um campo de texto ao ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "field.json"
      }), " do tema para o slogan do site, ele deve ser adicionado como um módulo global para que os criadores de conteúdo possam atualizar o slogan a partir do editor de páginas, não do editor de temas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os campos que estão disponíveis para uso nos temas são:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#boolean",
          children: "Booleano"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "Borda"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#choice",
          children: "Escolha"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "Cor"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "Fonte"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#image",
          children: "Imagem"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#number",
          children: "Número"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "Espaçamento"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver a documentação completa sobre as opções possíveis para os campos de tema, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "documentação dos campos de módulo e tema"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Usar valores de campo de tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para acessar os valores dos campos, use notação de pontos e adicione o prefixo ao caminho do valor em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " com o tema. Você pode usar um valor de campo de tema em suas folhas de estilo usando uma sintaxe como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ theme.path.to.value }}"
      }), ". Por exemplo, o campo de fonte descrito abaixo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"type\": \"group\",\n    \"name\": \"typography\",\n    \"label\": \"Typography\",\n    \"children\": [\n      {\n        \"type\": \"font\",\n        \"name\": \"h1_font\",\n        \"label\": \"Heading 1\",\n        \"load_external_fonts\": true,\n        \"default\": {\n          \"color\": \"#000\",\n          \"font\": \"Merriweather\",\n          \"font_set\": \"GOOGLE\",\n          \"variant\": \"700\",\n          \"size\": \"48\"\n        }\n      }\n    ]\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "É referenciado no CSS com:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "h1 {\n font-size: {{ theme.typography.h1_font.size }}px;\n font-family: {{ theme.typography.h1_font.font }};\n color: {{ theme.typography.h1_font.color }};\n text-decoration: {{ theme.typography.h1_font.styles.text-decoration }};\n font-style: {{ theme.typography.h1_font.styles.font-style }};\n font-weight: {{ theme.typography.h1_font.styles.font-weight }};\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visualizar temas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para desenvolvedores, às vezes você precisa ser capaz de testar se seus campos de tema estão funcionando corretamente, mas sem impactar as páginas reais. É aí que entra o modo de teste do tema."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modo de teste"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["O modo de teste fornece um ambiente seguro para poder brincar com os campos do seu tema e garantir que funcionem como o esperado. A interface se parece exatamente com a", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/edit-your-global-theme-settings#edit-theme-settings",
              children: " visualização/edição do tema que os criadores de conteúdo podem ver"
            }), "; porém, você pode ter certeza de que não está alterando as configurações do seu site real. Para proteger contra atualizações acidentais nas configurações do tema, a publicação é bloqueada no modo de teste. Quando você está no modo de teste, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "?testmode=true"
            }), " é mostrado na barra de endereço e um ícone de modo de teste é exibido no cabeçalho do editor de temas."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://f.hubspotusercontent00.net/hubfs/53/theme-test-mode.png",
            alt: "Teste do tema/modo de visualização"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há duas maneiras de ativar o modo de teste:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Para ativar o modo de teste no gerenciador de design:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["No gerenciador de design, selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "tema"
              }), " no Finder."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Clique no botão ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Visualizar"
              }), " no canto superior esquerdo da barra lateral."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode-design-manager%20(1).png",
                alt: "copy-theme-settings-test-mode-design-manager (1)"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Para ativar o modo de teste no editor de página:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["No editor de página, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Design"
              }), " na barra lateral esquerda e em ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Editar configurações do tema"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/page-editor-edit-theme.png",
                alt: "page-editor-edit-theme"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Adicione ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "?testmode=true"
              }), " ao URL e pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "enter"
              }), ". Você estará no modo de teste."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Outro método é abrir as configurações do tema diretamente do editor de páginas e, Em seguida, adicionar o parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?testmode=true"
      }), " ao URL na barra de endereço."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Introdução aos temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "Como adicionar recursos de tema a um site existente"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "Boilerplate de temas da HubSpot"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}