"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42817161642;
const slug = exports.slug = 'reference/api/analytics-and-events/tracking-code';
const title = exports.title = "Vue d'ensemble de l'API du code de suivi";
const name = exports.name = 'EMEA (FR) vNext Docs DP - Tracking code';
const metaDescription = exports.metaDescription = 'Le point de terminaison des analytics web vous permet de trouver et de filtrer des événements associés à un objet de CRM de tout type. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "identification-des-contacts",
  "label": "Identification des contacts",
  "parent": null
}, {
  "depth": 0,
  "id": "identifier-un-visiteur",
  "label": "Identifier un visiteur",
  "parent": null
}, {
  "depth": 0,
  "id": "suivi-dans-des-applications-d-une-seule-page",
  "label": "Suivi dans des applications d'une seule page",
  "parent": null
}, {
  "depth": 1,
  "id": "d%C3%A9finir-le-chemin-de-la-page",
  "label": "Définir le chemin de la page",
  "parent": "suivi-dans-des-applications-d-une-seule-page"
}, {
  "depth": 1,
  "id": "suivre-une-vue-de-page",
  "label": "Suivre une vue de page",
  "parent": "suivi-dans-des-applications-d-une-seule-page"
}, {
  "depth": 0,
  "id": "politique-de-confidentialit%C3%A9-dans-le-suivi",
  "label": "Politique de confidentialité dans le suivi",
  "parent": null
}, {
  "depth": 0,
  "id": "obtenir-des-param%C3%A8tres-de-liaison-inter-domaine-automatique",
  "label": "Obtenir des paramètres de liaison inter-domaine automatique",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9appliquer-les-gestionnaires-d-%C3%A9v%C3%A9nements-analytiques",
  "label": "Réappliquer les gestionnaires d'événements analytiques",
  "parent": null
}, {
  "depth": 0,
  "id": "suivre-les-%C3%A9v%C3%A9nements-comportementaux-personnalis%C3%A9s-marketing-hub-entreprise-uniquement",
  "label": "Suivre les événements comportementaux personnalisés (Marketing Hub Entreprise uniquement)",
  "parent": null
}, {
  "depth": 1,
  "id": "trackcustombehavioralevent",
  "label": "trackCustomBehavioralEvent",
  "parent": "suivre-les-%C3%A9v%C3%A9nements-comportementaux-personnalis%C3%A9s-marketing-hub-entreprise-uniquement"
}, {
  "depth": 1,
  "id": "personnaliser-votre-code-de-suivi-pour-envoyer-des-donn%C3%A9es-d-%C3%A9v%C3%A9nement-comportemental-personnalis%C3%A9",
  "label": "Personnaliser votre code de suivi pour envoyer des données d'événement comportemental personnalisé",
  "parent": "suivre-les-%C3%A9v%C3%A9nements-comportementaux-personnalis%C3%A9s-marketing-hub-entreprise-uniquement"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Vue d'ensemble de l'API du code de suivi"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Cette page a été mise à jour suite aux nouveaux événements comportementaux personnalisés. Pour les événements personnalisés hérités, veuillez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code/v1",
          children: "consulter la documentation héritée."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Outre le suivi des vues de page, le code de suivi HubSpot vous permet d'identifier les visiteurs, de suivre des événements et de suivre manuellement les vues de page sans recharger la page. L'API du code de suivi vous permet de créer de manière dynamique des événements et de suivre des données d'événement dans HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si votre site utilise la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/customize-your-cookie-tracking-settings-and-privacy-policy-alert",
        children: "bannière de consentement de confidentialité"
      }), ", découvrez comment gérer les cookies qui sont ajoutés au navigateur d'un visiteur avec l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "API de la bannière de cookies"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les appels de fonction sont déclenchés dans le tableau ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), ". Par exemple :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', { path string }])\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans cet article, découvrez comment ajouter des fonctions au code de suivi pour identifier les visiteurs, suivre les vues de page, envoyer des données d'événement et bien plus encore."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identification des contacts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'outil Analytics de HubSpot identifie les contacts à l'aide de deux types de données :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["le usertoken, qui est stocké dans le cookie de navigateur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspotutk"
        }), " du visiteur ;"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "l'adresse e-mail du contact."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque le code de suivi HubSpot suit l'action d'un visiteur, comme une vue de page, il associe automatiquement cette action avec le usertoken. Lorsque vous utilisez l'API du code de suivi pour identifier un visiteur avec l'adresse e-mail, le système d'analytics associera cette adresse e-mail au usertoken, permettant à HubSpot de mettre à jour une fiche d'informations de contact existante ou de créer une nouvelle fiche d'informations. Les données analytiques associées au usertoken, telles que les vues de page et la source d'origine, apparaîtront alors sur la fiche d'informations de contact."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous connaissez l'identité d'un visiteur (par exemple, l'adresse e-mail), vous pouvez utiliser la fonction d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identify-a-visitor",
        children: "identification"
      }), " pour définir des identités dans le traceur. Vous pouvez ensuite envoyer l'identité à HubSpot en faisant un appel ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events-js-api",
        children: "trackCustomBehavioralEvent"
      }), " distinct."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de l'utilisation de cette fonction, gardez à l'esprit ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Évitez d'utiliser un champ d'e-mail pour les visiteurs inconnus, car HubSpot créera une fiche d'informations de contact avec cet e-mail et le cookie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "usertoken"
        }), " du visiteur sera associé à cette fiche d'informations. Ainsi, tous les visiteurs inconnus seront associés à ce contact unique."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous définissez une identité sur une fiche d'informations de contact et que vous avez des propriétés uniques, nous supprimerons toutes les propriétés de l'identité contraires à cette spécificité."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identifier un visiteur"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si votre compte a été créé avant le 8 septembre 2021 et configuré pour ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/prevent-contact-properties-update-through-tracking-code-api",
          children: "permettre la mise à jour des propriétés de contact via le code de suivi"
        }), ", vous pouvez également inclure d'autres propriétés de contact à mettre à jour avec cette fonction. Cette fonctionnalité est rendue obsolète pour les comptes créés après le 8 septembre 2021."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"identify\", { {identity details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisez ce point de terminaison pour identifier les visiteurs et les contacts du site web."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visiteur :"
        }), " désigne tout visiteur du site web, qu'il s'agisse d'un contact HubSpot ou non. HubSpot ne crée pas de fiches d'informations pour les visiteurs comme il le fait pour les contacts."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contact :"
        }), " désigne un visiteur ou un contact hors ligne qui a une fiche d'informations dans HubSpot. Un contact peut être identifié par son adresse e-mail unique."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour identifier manuellement un visiteur ou un contact, vous pouvez utiliser une adresse e-mail ou un identifiant externe unique :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adresse e-mail :"
        }), " identifiez un visiteur par son adresse e-mail lorsque vous souhaitez mettre à jour un contact existant ou en créer un. Si un contact avec cette adresse e-mail existe dans HubSpot, sa fiche d'informations de contact sera mise à jour avec les données analytiques. Si aucun contact n'existe à cette adresse e-mail, une nouvelle fiche d'informations sera créée."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ID :"
        }), " un identifiant externe personnalisé qui identifie le visiteur. L'identification d'un visiteur avec cet ID associera les données analytiques à ce visiteur. Cependant, l'utilisation de l'ID seul ne créera pas de contact dans HubSpot. Les données analytiques peuvent être associées à un contact existant via cette méthode uniquement si :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "le visiteur a déjà été identifié à la fois par son ID et par son adresse e-mail ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "le visiteur a déjà été identifié par son ID et a également une soumission de formulaire associée à sa fiche d'informations."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Cet ID externe peut être utilisé avec le code de suivi HubSpot uniquement. Cet ID ne peut pas être utilisé pour récupérer ou mettre à jour des fiches d'informations via d'autres outils ou API de HubSpot. Si vous connaissez l'adresse e-mail du visiteur, il est recommandé de l'utiliser comme identifiant unique. De même, vous ne devez identifier un visiteur par son ID uniquement si vous ne connaissez pas son adresse e-mail."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Si vous avez précédemment envoyé des données analytiques à HubSpot uniquement à l'aide de l'ID du visiteur, vous pouvez ultérieurement inclure l'ID et une adresse e-mail pour associer les données de cet ID à un contact. Le contact existant sera alors mis à jour ou créé si aucun contact n'existe actuellement."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nThe below example gets the value of a query string parameter '?email='\nand uses that to identify the visitor\n*/\n\nfunction getParameterByName(name) {\n  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);\n  return match && decodeURIComponent(match[1].replace(/\\+/g, ' '));\n}\n\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n  },\n]);\n\n/*\nThe below example sets the email,\nas well as a custom property favorite_color\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n    favorite_color: 'orange',\n  },\n]);\n\n/*\nThe below example sets the email and a custom external ID for the visitor. \nThis assumes that your site includes a variable named \n'user' that includes that user's ID in an 'id' \nproperty, and their email in an 'email' property.\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: user.email,\n    id: user.id,\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de l'utilisation de cette fonction, gardez à l'esprit ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cette fonction stocke les données dans le traqueur (tracker), mais celles-ci ne sont pas transmises à HubSpot avec cet appel. Les données ne seront transmises que lors du suivi d'une vue de page ou d'un événement (avec les fonctions ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#track-page-view",
          children: "trackPageView"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackEvent"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un contact ne peut avoir qu'un seul ID et/ou une seule adresse e-mail. Si vous essayez d'attribuer deux ID à une adresse e-mail, seul le premier ID sera associé à l'adresse e-mail."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous devez inclure une adresse e-mail pour lier les données à un contact."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si votre compte a été créé avant le 8 septembre 2021 et configuré pour ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/prevent-contact-properties-update-through-tracking-code-api",
          children: "permettre la mise à jour des propriétés de contact via le code de suivi"
        }), ", vous pouvez également inclure d'autres propriétés de contact à mettre à jour avec cette fonction."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cette fonction ne restaurera ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "pas"
        }), " les contacts précédemment supprimés. Ces contacts doivent être ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/restore-deleted-contacts-companies-deals-or-tickets",
          children: "restaurés dans HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suivi dans des applications d'une seule page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le code de suivi HubSpot enregistre automatiquement une vue de page lorsque le code est chargé pour la première fois, mais vous pouvez également suivre manuellement les vues de page dans une application d'une seule page sans recharger le code de suivi. Vous pouvez utiliser les fonctions ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-path",
        children: "setPath"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view"
      }), "trackPageView pour mettre à jour et suivre la page actuelle. Par exemple :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<!-- Set up the path for the initial page view -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/home']);\n</script>\n\n<!-- Load the HubSpot tracking code -->\n<!-- Start of HubSpot Embed Code -->\n  <script type=\"text/javascript\" id=\"hs-script-loader\" async defer src=\"//js.hs-scripts.com/{hubId}.js\">\n</script>\n<!-- End of HubSpot Embed Code -->\n\n<!-- Tracking subsequent page views -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/about-us']);\n  _hsq.push(['trackPageView']);\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir le chemin de la page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsq.push(['setPath', { path string }])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mettez à jour le chemin de la page actuelle stocké dans le suivi. Cette fonction doit être utilisée par des applications d'une seule page pour mettre à jour la page actuelle lorsqu'une page est chargée. Après avoir utilisé cette fonction pour mettre à jour le chemin, vous devrez appeler la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), " pour suivre la vue de la page actuelle."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les applications d'une seule page doivent passer un appel ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), " dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), " avant le chargement du code de suivi pour définir l'URL suivie pour la première vue. Consultez la section ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "Suivre une vue de page"
      }), " ci-dessous pour obtenir un exemple."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous appelez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), ", vous inclurez le chemin de la page actuelle. Le chemin défini sera traité par rapport au domaine actuel consulté. Le chemin doit toujours commencer par une barre oblique. Si votre URL contient également des paramètres, ces derniers devront être inclus dans le chemin. Consultez le code ci-dessus pour des exemples."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Lors de l'utilisation de cette fonction, gardez à l'esprit ce qui suit :"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Tout chemin défini à l'aide de la fonction ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " remplacera les données dans l'en-tête de référent. Si vous appelez ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " une fois, vous devrez utiliser ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " pour mettre à jour le chemin pour chaque vue que vous souhaitez suivre."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["L'appel répété de ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " remplacera le référent, ce qui peut avoir un impact sur la source d'origine d'un contact, selon le moment où une demande de suivi est effectuée."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Cette fonction ne peut mettre à jour que le chemin de l'URL. Le domaine est défini automatiquement en fonction de l'URL de la page en chargement et le chemin défini à l'aide de cette fonction est toujours considéré par rapport à ce domaine détecté."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// These examples assume that the domain of the site is\n// www.mydomain.com\n\n// Set the path to '/' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/\n\n// Set the path to '/contact-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/contact-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/contact-us\n\n// Set the path to '/blog/post?utm_campaign=my-campaign' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/blog/post?utm_campaign=my-campaign']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/blog/post?utm_campaign=my-campaign\n\n// Set the path to '/#/about-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/about-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/#/about-us\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Suivre une vue de page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['trackPageView']);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suivez la vue de la page actuelle. Cette fonction est automatiquement appelée lorsque le code de suivi est chargé sur une page, mais vous pouvez appeler manuellement cette fonction pour suivre les vues suivantes dans une application d'une seule page."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " L'appel manuel de cette fonction avant ou pendant le chargement initial de la page pourrait entraîner un suivi de vues en double."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette fonction ne prend pas en charge des arguments. Le titre de page suivi sera la valeur actuelle de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "document.title"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'URL qui est suivie est basée sur l'un des éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le chemin défini à l'aide de la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), ". Si votre site est conçu comme une application d'une seule page, cette fonction est la méthode préférée pour définir le chemin suivi. Consultez la section ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), " ci-dessus pour consulter des avertissements sur la fonction."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), " n'a jamais été appelée, l'URL suivie sera l'en-tête HTTP du référent de la demande effectuée par le navigateur du visiteur aux serveurs de suivi HubSpot (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/fr-FR/docs/Web/API/History_API#Adding_and_modifying_history_entries",
          children: "la modification de l'état de l'historique du navigateur"
        }), " mettra à jour la valeur utilisée pour l'en-tête). L'utilisation de l'en-tête du référent ne prend pas en charge les fragments d'URL (tout élément après # dans l'URL), car ceux-ci ne sont pas inclus dans l'en-tête du référent."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// Track a new page using setPath:\n// Update the path stored in the tracker:\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/new-page']);\n// Track the page view for the new page\n_hsq.push(['trackPageView']);\n\n\n// Track a new page by updating the browser state:\n// Update the browser state, showing \"updated.html\" in the browser address bar\nvar stateObj = { foo: 'updated' };\nhistory.pushState(stateObj, \"updated page\", \"updated.html\");\n//Track the page view for the new page, '/updated.html'\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['trackPageView']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous utilisez cette fonction, tenez compte des informations suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["S'il n'est pas possible d'empêcher l'appel automatique de cette fonction lors du chargement du code de suivi, vous pouvez contrôler l'URL enregistrée pour la page en lançant un appel ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), " dans ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "_hsq"
        }), " avant le chargement du code de suivi."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Nous déconseillons d'inclure une balise ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), " dans votre page si votre site est une application d'une seule page. Si votre page utilise des balises ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), ", vous devez utiliser la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), " pour mettre à jour le chemin de vos nouvelles pages, car l'URL canonique définie dans la balise de lien remplacera toute URL détectée si vous ne mettez à jour que l'état de l'historique du navigateur."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Politique de confidentialité dans le suivi"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si votre site dispose d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/customize-your-cookie-tracking-settings-and-privacy-policy-alert",
        children: "bannière de consentement de confidentialité,"
      }), " vous pouvez utiliser des fonctions pour consulter et gérer les cookies placés dans le navigateur du visiteur. Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "la gestion des cookies de la bannière de consentement de confidentialité"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtenir des paramètres de liaison inter-domaine automatique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['addIdentityListener', function(hstc, hssc, hsfp) {}])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le code de suivi HubSpot peut être utilisé sur plusieurs sites avec des domaines distincts. Cette fonction vous permet d'obtenir les paramètres de requête nécessaires pour créer des liens qui vous permettront de suivre vos visiteurs ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/set-up-your-site-domains#track-multiple-domains-with-cross-domain-linking",
        children: "sur ces domaines distincts"
      }), ". Ces paramètres de requête sont utilisés par le code de suivi HubSpot afin d'identifier un visiteur sur plusieurs domaines en veillant à ce que les cookies distincts des domaines distincts soient fusionnés en un seul visiteur suivi. Vous pouvez également utiliser les paramètres de requête inter-domaine dans les liens qui sont ajoutés dynamiquement à la page après le chargement du code de suivi."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les liens inter-domaines sont uniquement nécessaires lors de la connexion d'un domaine distinct (comme domaine-un*.com* et ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "domaine-deux.com"
      }), ") qui est également suivi pour un seul compte HubSpot. Vous n'avez pas besoin de paramètres de lien inter-domaine lors du suivi des visites entre des sous-domaines (comme ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www",
        children: "www"
      }), ".", (0, _jsxRuntime.jsx)(_components.em, {
        children: "domaine-un.com"
      }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "blog.domaine-un.com"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Get the cross-domain query parameters and store them in a string,\n//so that they can be appended to links as needed.\n_hsq.push([\n  'addIdentityListener',\n  function (hstc, hssc, hsfp) {\n    // Add these query parameters to any links that point to a separate tracked domain\n    crossDomainTrackingParams =\n      '&__hstc=' + hstc + '&__hssc=' + hssc + '&__hsfp=' + hsfp;\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Réappliquer les gestionnaires d'événements analytiques"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['refreshPageHandlers'])"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cette fonction réapplique les gestionnaires d'événements analytiques configurés dans les paramètres analytiques du compte HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cela inclura les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events/create-clicked-element-events",
        children: "événements d'élément cliqué"
      }), " qui ont été configurés."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez utiliser cette fonction pour réappliquer automatiquement les gestionnaires de clic lorsque le contenu sur la page est mis à jour, comme la mise à jour d'une section de contenu ou l'affichage d'une fenêtre modale sur la page."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Cette fonctionnalité est automatiquement déclenchée dans le cadre de la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "setPath"
          })
        }), ". Par conséquent, vous n'aurez besoin de cette fonction que lors de la mise à jour du contenu, sans mise à jour de l'URL de la page suivie."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Reapply event handlers after updating the page content\n_hsq.push(['refreshPageHandlers']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suivre les événements comportementaux personnalisés (Marketing Hub Entreprise uniquement)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À l'aide d'événements comportementaux personnalisés, vous pouvez lier les finalisations d'événements aux fiches d'informations de contact et remplir les propriétés d'événement avec des métadonnées sur l'événement. Pour récupérer des événements comportementaux personnalisés, utilisez l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "API d'analyse web"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Grâce à l'API, les événements peuvent être déclenchés en utilisant le nom interne de l'événement, qui est attribué automatiquement lorsque vous créez l'événement. Vous pouvez trouver le nom interne d'un événement ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "dans HubSpot"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/reporting#get-events",
        children: "via l'API d'événements"
      }), ". Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "trouver le nom interne d'un événement"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Imported%20sitepage%20images/custom-event-internal-name.png",
        alt: "custom-event-internal-name"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe trois types d'événements que vous pouvez créer dans HubSpot :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "Événements d'élément cliqué"
        }), " : événements liés à des éléments cliqués sur une page web. Un ensemble de propriétés d'événement HubSpot par défaut sera automatiquement renseigné via le code de suivi. Vous pouvez personnaliser davantage ces événements avec la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "Événements d'URL visitée"
        }), " : événements liés aux chargements de page aux URL spécifiées. Un ensemble de propriétés d'événement HubSpot par défaut sera automatiquement renseigné via le code de suivi. Vous pouvez personnaliser davantage ces événements avec la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "Événements comportementaux suivis manuellement"
        }), " : les événements personnalisés propres à votre entreprise ainsi que les événements qui peuvent ne pas être automatiquement capturés par HubSpot ou par une intégration. Envoyez manuellement des données aux événements HubSpot via l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "API HTTP"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour chaque type d'événement, HubSpot inclut un ensemble de propriétés standard qui peuvent capturer certaines métadonnées au moment de la finalisation, y compris les paramètres UTM ou des métadonnées relatives au périphérique et au système d'exploitation."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Étant donné que cette fonction fonctionne avec le suivi des analytics de HubSpot, tous les événements déclenchés par l'API JavaScript seront automatiquement associés au cookie ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "hubspotutk"
      }), " du visiteur. Ainsi, l'événement sera automatiquement ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identities-overview",
        children: "lié au contact associé à ce usertoken"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "trackCustomBehavioralEvent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"trackCustomBehavioralEvent\", { {event details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez cette fonction pour suivre un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events-user-guide-v2/a-quick-tour-of-events",
        children: "événement"
      }), " utilisant JavaScript et le code de suivi de HubSpot. Vous pouvez utiliser des événements pour suivre des activités spécifiques effectuées par les visiteurs sur votre site. Les événements suivis peuvent apparaître dans la chronologie des contacts."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nExample code to fire a custom behavioral event using the name \"clicked Buy Now button\" when a visitor clicks an element with the 'buyNow' id.\n*/\n\ndocument.getElementById(\"buyNow\").onclick = function() {_hsq.push([\"trackCustomBehavioralEvent\", {\n   name: \"pe123456_course_registration\",\n   properties: {\n       course_id: \"Math101\"\n   }\n}]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Arguments"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Fonctionnement"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "name:\"internal_name\""
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID ou le nom interne de l'événement que vous avez créé dans HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "property_name: \"property_value\""
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste de paires (clé, valeur), avec une paire par propriété.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_name"
            }), " est le nom interne de la propriété d'événement que vous avez créée pour l'événement et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_value"
            }), " est la valeur à ajouter à la propriété. Vous pouvez également suivre les propriétés non définies et revenir en arrière pour les créer après le suivi des événements."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Personnaliser votre code de suivi pour envoyer des données d'événement comportemental personnalisé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par défaut, HubSpot crée un ensemble de propriétés pour chaque événement que vous créez. Pour les événements d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "élément cliqué"
      }), " ou d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "URL visitée"
      }), ", HubSpot remplira automatiquement certaines de ces propriétés avec des données. Mais vous pouvez également personnaliser votre code de suivi pour envoyer des données aux propriétés de l'événement."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reporting"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Outils d'analytics"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Événements comportementaux personnalisés"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " de l'événement que vous souhaitez suivre."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Propriétés"
        }), ", copiez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom interne"
        }), " de l'événement."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-clicked-element-event-internal-name0.png",
        alt: "custom-clicked-element-event-internal-name0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Ensuite, dans le tableau des propriétés, cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom"
          }), " de la propriété d'événement vers laquelle vous souhaitez envoyer des données."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le panneau de droite, cliquez sur l'icône Source ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "</>"
          }), " pour afficher le nom interne de la propriété. Vous utiliserez ce nom lors de la personnalisation du code de suivi."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-behavioral-event-property-internal-name0.png",
            alt: "custom-behavioral-event-property-internal-name0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Maintenant que vous disposez de vos données d'événement et de propriété d'événement, cliquez sur l'icône ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Paramètres"
          }), " pour accéder aux paramètres de votre compte. Puis, dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Suivi et analytics"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Code de suivi"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Personnaliser JavaScript"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/tracking-code-customize-javascript0.png",
            alt: "tracking-code-customize-javascript0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans l'angle supérieur droit, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Ajouter un Javascript personnalisé"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la barre latérale de droite, saisissez un ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom"
          }), " pour votre JavaScript personnalisé, puis saisissez le JavaScript, y compris la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#events-js-api",
            children: "trackCustomBehavioralEvent"
          }), ". Ce code JavaScript doit être exécuté après le chargement du code de suivi d'une page."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example usage\n_hsq.push(['trackCustomBehavioralEvent',{\n   name: '((behavioral_event_internal_name))”,\n   properties: { internal_property_name: property_value}\n}\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, si votre événement suit une inscription à un cours lorsqu'un bouton avec l'ID de HTML ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "register_for_econ101"
      }), " est cliqué, votre JavaScript peut ressembler à ce qui suit : ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/customize-tracking-code0-1.png",
        alt: "customize-tracking-code0-1"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enregistrer"
        }), " pour enregistrer votre JavaScript. Votre code de suivi va maintenant se charger avec votre JavaScript personnalisé."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}