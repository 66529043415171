"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937505;
const slug = exports.slug = 'guides/api/crm/properties';
const title = exports.title = 'API de CRM | Propiedades';
const name = exports.name = 'vNext DP - Propiedades';
const metaDescription = exports.metaDescription = ' Los puntos de terminación de las propiedades de CRM te permiten administrar propiedades personalizadas, así como ver los detalles de las propiedades predeterminadas para cualquier objeto.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "propiedades-predeterminadas",
  "label": "Propiedades predeterminadas",
  "parent": null
}, {
  "depth": 0,
  "id": "grupos-de-propiedades",
  "label": "Grupos de propiedades",
  "parent": null
}, {
  "depth": 0,
  "id": "tipo-de-propiedad-y-valores-de-fieldtype",
  "label": "Tipo de propiedad y valores de fieldType",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-una-propiedad",
  "label": "Crear una propiedad",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-propiedades-de-c%C3%A1lculo",
  "label": "Crear propiedades de cálculo",
  "parent": null
}, {
  "depth": 1,
  "id": "sintaxis-de-propiedad-de-c%C3%A1lculo",
  "label": "Sintaxis de propiedad de cálculo",
  "parent": "crear-propiedades-de-c%C3%A1lculo"
}, {
  "depth": 2,
  "id": "sintaxis-literal",
  "label": "Sintaxis literal",
  "parent": "crear-propiedades-de-c%C3%A1lculo"
}, {
  "depth": 2,
  "id": "sintaxis-de-propiedad",
  "label": "Sintaxis de propiedad",
  "parent": "crear-propiedades-de-c%C3%A1lculo"
}, {
  "depth": 2,
  "id": "operadores",
  "label": "Operadores",
  "parent": "crear-propiedades-de-c%C3%A1lculo"
}, {
  "depth": 2,
  "id": "funciones",
  "label": "Funciones",
  "parent": "crear-propiedades-de-c%C3%A1lculo"
}, {
  "depth": 2,
  "id": "declaraciones-condicionales",
  "label": "Declaraciones condicionales",
  "parent": "crear-propiedades-de-c%C3%A1lculo"
}, {
  "depth": 1,
  "id": "f%C3%B3rmulas-de-ejemplo",
  "label": "Fórmulas de ejemplo",
  "parent": "crear-propiedades-de-c%C3%A1lculo"
}, {
  "depth": 0,
  "id": "recuperar-propiedades",
  "label": "Recuperar propiedades",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-o-borrar-los-valores-de-una-propiedad",
  "label": "Actualizar o borrar los valores de una propiedad",
  "parent": null
}, {
  "depth": 1,
  "id": "agregar-valores-a-propiedades-de-tipo-de-casilla-de-comprobaci%C3%B3n",
  "label": "Agregar valores a propiedades de tipo de casilla de comprobación",
  "parent": "actualizar-o-borrar-los-valores-de-una-propiedad"
}, {
  "depth": 1,
  "id": "asignar-propietarios-de-registros-con-propiedades-de-usuario",
  "label": "Asignar propietarios de registros con propiedades de usuario",
  "parent": "actualizar-o-borrar-los-valores-de-una-propiedad"
}, {
  "depth": 1,
  "id": "borrar-un-valor-de-propiedad",
  "label": "Borrar un valor de propiedad",
  "parent": "actualizar-o-borrar-los-valores-de-una-propiedad"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Propiedades"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usa propiedades para almacenar información en registros de CRM. HubSpot proporciona un conjunto de propiedades predeterminadas para cada objeto CRM y también puedes crear y administrar sus propias propiedades personalizadas", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: " en HubSpot"
      }), " o mediante la API de propiedades."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear propiedades, es importante tener en cuenta cómo diseñar tus datos. En muchos casos, la creación de propiedades personalizadas para los objetos estándar de HubSpot es el curso de acción correcto. Sin embargo, puede haber ocasiones en las que debas crear un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objeto personalizado"
      }), " independiente con su propio conjunto de propiedades."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propiedades predeterminadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los objetos de CRM se definen por un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "tipo"
      }), " principal y un conjunto de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propiedades"
      }), ". Cada tipo tiene un conjunto único de propiedades estándar, representado por un mapa de pares nombre-valor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Más información sobre las propiedades predeterminadas para diferentes objetos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "Contactos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "Empresas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "Negocios"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-activity-properties",
          children: "Actividades"
        }), " (llamadas, correos electrónicos, reuniones, notas, tareas)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-lead-properties",
          children: "Leads"
        }), "(", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Pro"
        }), " y ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Grupos de propiedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties#create-and-edit-property-groups",
        children: "grupos"
      }), " de propiedades se utilizan para agrupar propiedades relacionadas. Cualquier propiedad agrupada aparecerá junto entre sí en los registros de HubSpot. Si tu integración crea cualquier propiedad personalizada de objeto, un grupo de propiedades personalizadas hará que sea fácil identificar esos datos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipo de propiedad y valores de fieldType"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se crean o actualizan propiedades, se requieren valores de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), ". El valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " determina el tipo de propiedad, es decir, una cadena o un número. La propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " determina cómo aparecerá la propiedad en HubSpot o en un formulario, es decir, como un campo de texto sin formato, un menú desplegable o un selector de fechas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la siguiente tabla, obtén información sobre propiedad disponible ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " y los valores de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " correspondientes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsxs)(_components.th, {
            children: ["Valores válidos ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fieldType"
            })]
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un campo que contiene opciones binarias (por ejemplo,", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Yes"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "No"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), ")."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una cadena que representa un conjunto de opciones, con opciones separadas por un punto y coma."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un valor que representa un día, mes y año específicos. Los valores deben representarse en tiempo UTC y pueden formatearse como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "cadenas ISO 8601 o marcas de tiempo EPOCH en milisegundos (es decir, UTC de medianoche)."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dateTime"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un valor con que representa un día, mes y hora específicos del día. Los valores deben representarse en tiempo UTC y pueden formatearse como cadenas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "ISO 8601 o marcas de tiempo UNIX en milisegundos."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una cadena de texto sin formato, limitada a 65.536 caracteres."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor de número que contiene dígitos numéricos y, como máximo, un decimal."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object_coordinates"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor de texto utilizado para hacer referencia a otros objetos de HubSpot, utilizado solo para propiedades internas. Las propiedades de este tipo no se pueden crear ni editar, y no son visibles en HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor de texto almacenado como JSON formateado, utilizado solo para propiedades internas. Las propiedades de este tipo no se pueden crear ni editar, y no son visibles en HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los valores válidos para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " incluyen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Fieldtype"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una entrada que permitirá a los usuarios seleccionar Sí o No. Cuando se usa en un formulario, se mostrará como una única casilla de comprobación. Descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "agregar un valor a las propiedades de casilla de comprobación única"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una ecuación personalizada que puede calcular valores basados en otros valores de propiedad y/o asociaciones. Más información sobre cómo definir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#create-calculation-properties",
              children: "propiedades de cálculo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista de casillas de comprobación que permitirán al usuario seleccionar varias opciones de un conjunto de opciones permitidas para la propiedad. Aprende a dar ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "formato a los valores al actualizar varias propiedades de casilla de comprobación"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor de fecha, que se muestra como selector de fechas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permite subir un archivo en un registro o a través de un formulario. Almacena un ID de archivo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una cadena, renderizada como html desinfectado, que permite el uso de un editor de texto enriquecido para la propiedad."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una cadena de numerales o números escritos en notación decimal o científica."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una cadena de texto sin formato, que se muestra como un número de teléfono formateado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una entrada que permitirá a los usuarios seleccionar una de un conjunto de opciones permitidas para la propiedad. Cuando se usa en un formulario, se mostrará como un conjunto de botones de radio."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una entrada desplegable que permitirá a los usuarios seleccionar una de un conjunto de opciones permitidas para la propiedad."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una cadena de texto sin formato, que se muestra en una entrada de texto de una sola línea."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una cadena de texto sin formato, que se muestra como entrada de texto de varias líneas."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una propiedad"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una propiedad, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/{objectType}"
      }), ". En el cuerpo de tu solicitud, incluye los siguientes campos obligatorios:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "groupName"
        }), ": el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/organize-and-export-properties",
          children: "grupo de propiedades"
        }), " en el que estará la propiedad."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": el nombre interno de la propiedad (por ejemplo, favorite_food)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ": el nombre de la propiedad tal como aparece en HubSpot (por ejemplo, Comida favorita)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "type"
        }), ": el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#type",
          children: "tipo"
        }), " de propiedad."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fieldType"
        }), ": el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-type",
          children: "tipo de campo"
        }), " de la propiedad."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, para crear una propiedad de contacto llamada ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Comida favorita"
      }), ", tu solicitud se vería así:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body POST crm/v3/properties/contacts\n{\n  \"groupName\": \"contactinformation\",\n  \"name\": \"favorite_food\",\n  \"label\": \"Favorite Food\",\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Crear propiedades de identificador único"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se crea un registro en HubSpot, se genera automáticamente un _ ID_ de registro (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ") único que debe ser tratado como una cadena de texto. Estos ID solo son únicos dentro de ese objeto, por lo que puede haber un contacto y una empresa con el mismo ID. Para los contactos y las empresas, hay identificadores únicos adicionales, incluida la dirección de correo electrónico (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ") de un contacto y el nombre de dominio (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ") de una empresa."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En algunos casos, es posible que quieras crear tu propia propiedad de identificador único para que no puedas introducir el mismo valor para varios registros. Puedes tener hasta diez propiedades de ID únicas por objeto. Para crear una propiedad que requiera valores únicos a través de la API:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el cuerpo de la solicitud, para el campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hasUniqueValue"
        }), ", establece el valor como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body\n{\n  \"groupName\": \"dealinformation\",\n  \"name\": \"system_a_unique\",\n  \"label\": \"Unique ID for System A\",\n  \"hasUniqueValue\": true,\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que hayas creado tu propiedad de ID único, puedes usarlo en una llamada a la API para recuperar registros específicos. La URL de la solicitud podría verse de la siguiente manera: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), ". Esto devolverá el negocio con el valor de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "abc"
      }), " en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "system_a_unique"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Luego puedes usar este valor de propiedad de identificador único para identificar y actualizar registros específicos de la misma manera que podrías usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " (contactos) o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " (empresas)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear propiedades de cálculo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las propiedades de cálculo definen un valor de propiedad basado en otras propiedades dentro del mismo registro de objeto. Se definen utilizando una fórmula, que puede incluir operaciones como min, max, count, sum o average. Puedes usar la API de propiedades para leer o crear propiedades de cálculo en tu cuenta de HubSpot, usando un tipo de campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculation_equation"
      }), " y un tipo de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "number"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bool"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "string"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enumeration"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes definir la fórmula de cálculo de la propiedad con el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": las propiedades de cálculo creadas mediante API ", (0, _jsxRuntime.jsx)("u", {
          children: "no se pueden"
        }), " editar en HubSpot. Solo puedes editar estas propiedades a través de la API de propiedades."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sintaxis de propiedad de cálculo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), ", puedes escribir tu fórmula con operadores aritméticos, operadores de comparación, operadores lógicos, sentencias condicionales y otras funciones."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Sintaxis literal"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cadena literal"
        }), ": las cadenas constantes se pueden representar con comillas simples (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'constant'"
        }), ") o comillas dobles (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"constant\""
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Número literal"
        }), ": los números constantes pueden ser cualquier número real y pueden incluir notación de puntos. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1005"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1.5589"
        }), " son números constantes válidos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Booleano literal"
        }), ": los booleanos constantes pueden ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Sintaxis de propiedad"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variables de propiedad de cadena:"
        }), " para que una cadena de identificador se interprete como una propiedad de cadena, debe estar envuelta en la función ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), ". Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string(var1)"
        }), " se interpretará como el valor de la propiedad de cadena var1."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variables de propiedad de número"
        }), ": todos los identificadores se interpretarán como variables de propiedad de número. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "var1"
        }), " se interpretará como el valor de la propiedad de número var1."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variables de propiedad booleana"
        }), ": para que un identificador se interprete como una propiedad bool, debe estar envuelto en la función ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool"
        }), ". Por ejemplo, el identificador ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool(var1)"
        }), " se interpretará como el valor de la propiedad booleana var1."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " el lenguaje utilizado distingue entre mayúsculas y minúsculas para todos los tipos, ", (0, _jsxRuntime.jsx)("u", {
          children: "excepto"
        }), " las cadenas. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "If A ThEn B"
        }), " es exactamente igual que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if a then b"
        }), " pero ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'a'"
        }), " no es lo mismo que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'A'"
        }), ". Se utilizarán espacios, pestañas y nuevas líneas para la tokenización, pero se ignorarán."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Operadores"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los operadores se pueden usar con valores literales y de propiedad. Para los operadores aritméticos, puedes usar notación de prefijo para multiplicar, y se pueden usar paréntesis para especificar el orden de las operaciones."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operador"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplos"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "+"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Agrega números o cadenas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Restar números."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100 - property2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Multiplicar números."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "10property1"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10 * property1"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dividir números."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 * (100 - property2/(50 - property3))"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si un valor es menor que otro. Compatible con propiedades numéricas o constantes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a < 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si un valor es mayor que otro. Compatible con propiedades numéricas o constantes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > 50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si un valor es menor o igual que otro. Compatible con propiedades numéricas o constantes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a <= b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si un valor es mayor o igual que otro. Compatible con propiedades numéricas o constantes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b>= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si un valor es igual a otro. Compatible tanto con números como con cadenas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "(a + b - 100c * 150.652) = 150-230b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "equals"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si un valor es igual a otro. Compatible tanto con números como con cadenas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a + b - 100.2c * 150 equals 150 - 230"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si un valor no es igual a otro. Compatible tanto con números como con cadenas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string(property1) != 'test_string'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "or"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si cualquiera o los dos valores es verdadero."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > b or b <= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "and"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si ambos valores son verdaderos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool(a) and bool(c)"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si ninguno de los valores es verdadero."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not (bool(a) and bool(c))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Funciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las siguientes son funciones compatibles:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Función"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplos"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tendrá entre 2 y 100 números de entrada, y devolverá el número máximo de todas las entradas."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b, c, 100)"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "min"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tendrá entre 2 y 100 números de entrada, y devolverá el número mínimo de todas las entradas."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b, c, 100)"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evalúa si se puede evaluar una expresión"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), "= true si la propiedad es booleana, pero ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), " = false si la propiedad está vacía o no es booleana."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tiene dos cadenas como entradas y devolverá true si la primera entrada contiene la segunda."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('hello', 'ello')"
            }), " = while ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('ello', 'hello')"
            }), " = false."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se une a una lista de cadenas. La lista de entradas puede ir de 2 a 100."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate('a', 'b', string(a), string(b))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "También hay dos funciones de análisis:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number_to_string"
        }), ": intenta convertir la expresión del número de entrada en una cadena."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string_to_number"
        }), ": intenta convertir la expresión de la cadena de entrada en un número."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + num_cars"
      }), " no es una propiedad válida porque no puedes agregar una cadena con un número, pero ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + number_to_string(num_cars)"
      }), " lo es."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Declaraciones condicionales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes escribir tu fórmula con sentencias condicionales usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, una sentencia condicional podría parecerse a: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if boolean_expression then statement [elseif expression then statement]* [else statement | endif]"
      }), " donde los corchetes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[a]"
      }), " representan que a es opcional, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a|b"
      }), " representa que a o b funcionarán, y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "*"
      }), " significa 0 o más. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), " se puede usar para terminar una sentencia condicional prematuramente, asegurando que el analizador sintáctico pueda identificar a qué ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " pertenece el siguiente ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fórmulas de ejemplo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los siguientes son ejemplos que puedes usar para ayudar a definir tus propias fórmulas de cálculo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"closed - started\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un ejemplo más avanzado con condicionales:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"if is_present(hs_latest_sequence_enrolled_date) then\n  if is_present(hs_sequences_actively_enrolled_count) an hs_sequences_actively_enrolled_count >= 1 then\n    true\n  else\n    false\nelse\n  ''\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar propiedades"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes recuperar información de propiedades individuales o de todas las propiedades dentro de un objeto."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar una propiedad individual, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/properties/{object}/{propertyName}"
        }), ". Por ejemplo, para recuperar la propiedad Comida favorita, la URL de tu solicitud sería https:// ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "api.hubspot.com/crm/v3/properties/contacts/favorite_food"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar todas las propiedades de un objeto, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": al recuperar todas las propiedades, de forma predeterminada sólo se devuelven las propiedades no confidenciales. Para recuperar propiedades de datos sensibles, incluye el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dataSensitivity"
        }), " de consulta con el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sensitive"
        }), ". Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/sensitive-data#manage-sensitive-data",
          children: "la administración de datos sensibles mediante API"
        }), " (BETA, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " solamente)."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar o borrar los valores de una propiedad"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para actualizar el valor de una propiedad para un registro, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/{objectType}/{recordId}"
      }), ". En el cuerpo de tu solicitud, incluye las propiedades y sus valores en una matriz. Más información sobre la actualización de registros a través de las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "API de objetos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Agregar valores a propiedades de tipo de casilla de comprobación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al actualizar los valores para las propiedades de tipo de casilla de comprobación de un registro, formatea los valores de las siguientes maneras:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Propiedad de casilla de verificación"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "booleana"
        }), ": para que se muestre como ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sí"
        }), ", o marcado en HubSpot, el valor debe ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), ". Para que aparezca como ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "No"
        }), " o sin marcar en HubSpot, tu valor debe ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Propiedad de casillas de verificación de"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "selección múltiple"
        }), ": para agregar o adjuntar valores a una propiedad de varias casillas de comprobación, agrega un punto y coma antes del primer valor e incluye un punto y coma entre cada valor. Si la propiedad tiene un valor existente, el punto y coma inicial agregará los valores en lugar de sobrescribir el valor. Por ejemplo, un contacto tiene el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DECISION_MAKER"
        }), " existente para la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_buying_role"
        }), ". Para agregar valores adicionales sin reemplazar el valor existente, tu solicitud se vería como lo siguiente:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example body for PATCH request to /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_buying_role\": \";BUDGET_HOLDER;END_USER\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asignar propietarios de registros con propiedades de usuario"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al asignar usuarios a registros de CRM a través de la API, tu valor debe ser el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de propietario del usuario, que puedes encontrar en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: "configuración de tu propiedad"
      }), " o a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "API de propietarios"
      }), ". Por ejemplo, para asignar un usuario como propietario de un contacto, envía una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/contacts/{contactId}"
      }), ",  con el cuerpo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\":{ \"hubspot_owner_id\": \"41629779\"}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Borrar un valor de propiedad"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes borrar un valor de propiedad de objeto a través de la API configurando el valor de propiedad en una cadena vacía."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, para borrar el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), " de un objeto de contacto, envía una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/contacts/{contactId}"
      }), " con el cuerpo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\": { \"firstname\": \"\"}}"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}