"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279801;
const slug = exports.slug = 'guides/cms/content/overview';
const title = exports.title = 'Grundlegende CMS-Komponenten von HubSpot';
const name = exports.name = 'EMEA DE CMS building blocks overview';
const metaDescription = exports.metaDescription = 'Entwickler können auf eine Reihe von grundlegenden Komponenten zurückgreifen, um Websites in HubSpots CMS Hub zu erstellen. Diese Bausteine können verwendet werden, um ein Design-System für Content-Autoren zu erstellen, mit unterschiedlichen Grad an Flexibilität und Richtlinien je nach Ihren Geschäftsanforderungen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "designs",
  "label": "Designs",
  "parent": null
}, {
  "depth": 0,
  "id": "vorlagen",
  "label": "Vorlagen",
  "parent": null
}, {
  "depth": 0,
  "id": "module",
  "label": "Module",
  "parent": null
}, {
  "depth": 0,
  "id": "felder-f%C3%BCr-module-und-designs",
  "label": "Felder für Module und Designs",
  "parent": null
}, {
  "depth": 0,
  "id": "globaler-content",
  "label": "Globaler Content",
  "parent": null
}, {
  "depth": 0,
  "id": "men%C3%BCs-und-navigation",
  "label": "Menüs und Navigation",
  "parent": null
}, {
  "depth": 0,
  "id": "formulare",
  "label": "Formulare",
  "parent": null
}, {
  "depth": 0,
  "id": "website-einstellungen",
  "label": "Website-Einstellungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Übersicht über grundlegende CMS-Komponenten"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Entwickler nutzen eine Reihe von grundlegenden Komponenten, um Websites mit der CMS-Software von HubSpot zu erstellen. Diese Bausteine können verwendet werden, um ein Design-System für Content-Autoren zu erstellen, mit unterschiedlichen Grad an Flexibilität und Richtlinien je nach Ihren Geschäftsanforderungen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Designs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Design"
      }), " ist ein übertragbares und eigenständiges Paket von von Entwicklern erstellen Elementen, die zusammen eine flexible Bearbeitung von Inhalten ermöglichen und so Marketingteams die Arbeit erleichtert. Ein Design ist ein übergeordneter Baustein beim Entwickeln, der das Erscheinungsbild einer Website definiert und ein marketingfreundliches Content-Editing ermöglicht."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Vorlagen"
      }), " definieren das Basis-Markup und den Stil einer Reihe von Seiten, die eine Vorlage verwenden. Wenn Content-Autoren eine Seite oder E-Mail erstellen, wählen sie eine Vorlage aus, die sie verwenden möchten, bevor sie sie mit Inhalt füllen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Module"
      }), " sind wiederverwendbare Komponenten, die Sie in Vorlagen und auf Seiten in CMS Hub platzieren können. Sie enthalten Steuerelemente für Marketer und enthalten HubL/HTML-Markup, CSS und JavaScript, mit denen sich zusammen wiederverwendbare und bearbeitbare Komponenten einer Website erstellen lassen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Felder für Module und Designs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "Felder"
      }), " sind die Steuerelemente, mit denen Content-Autoren die Parameter anpassen können, die an Ihre Designs und Module übergeben werden. Mit Feldern können die von Entwicklern erstellten Bausteine über verschiedene Content-Elemente hinweg wiederverwendet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Globaler Content"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "globalemContent"
      }), " können Markup und Inhalte global über eine Website hinweg geteilt werden. Globaler Content vereinfacht die Verwaltung von Elementen wie Headern, Fuootern, Seitenleisten oder anderen globalen Elementen einer Website."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menüs und Navigation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/menus-and-navigation",
        children: "Menüs"
      }), " können Sie ganz einfach eine Navigationshierarchie und Informationsarchitektur für die Navigation auf Ihrer Website erstellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Formulare"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/forms",
        children: "Formularen"
      }), " können Sie verschiedene Arten von Informationen von Benutzern erfassen (z. B. Adressangaben, E-Mail-Adressen und Feedback), die Sie dann im gesamten HubSpot-Ökosystem in Bereichen wie Smart-Content, Workflows, Content-Personalisierung und mehr in Ihren alltäglichen Aktivitäten verwenden können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Website-Einstellungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/website-settings",
        children: "Website-Einstellungen"
      }), " sind ein zentraler Ort, an dem verschiedene globale und systembasierte Inhaltseinstellungen für Ihre Website wie Ihr Logo, Favicon, Systemvorlagen und mehr konfiguriert werden können."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}