"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313482;
const slug = exports.slug = 'guides/cms/marketplace/listing-templates';
const title = exports.title = 'Vorlagen im HubSpot-Marketplace für Vorlagen listen und aktualisieren';
const name = exports.name = 'EMEA DACH (DE) Marketplace Requirements Overview > Listing CMS Assets';
const metaDescription = exports.metaDescription = 'Nachdem Sie einen Anbieter-Account für den HubSpot-Marketplace für Vorlagen erstellt haben, können Sie damit Vorlagen-Listings erstellen und diese zur Genehmigung einreichen. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "vorlagen-listing-erstellen",
  "label": "Vorlagen-Listing erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "vorlagen-listing-aktualisieren",
  "label": "Vorlagen-Listing aktualisieren",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      img: "img",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Vorlagen im HubSpot-Marketplace für Vorlagen listen und aktualisieren"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie einen Anbieter-Account für den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "HubSpot-Marketplace für Vorlagen"
      }), " erstellt haben, können Sie damit Vorlagen-Listings erstellen und diese zur Genehmigung einreichen, um diese im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "HubSpot-Marketplace für Vorlagen"
      }), " zu listen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Sie können jeweils nur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "eine"
        }), " neue Einreichung pro Typ (Modul oder Vorlage) überprüfen lassen. Eine Einreichung gilt als „neu“, wenn sie derzeit nicht veröffentlicht oder genehmigt ist."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bevor Sie Ihre Vorlage im HubSpot-Marketplace für Vorlagen listen können, müssen Sie Ihr Anbieterprofil ausfüllen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem Anbieter-Account für den HubSpot-Marketplace für Vorlagen zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubSpot-Marketplace für Vorlagen"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anbieterprofil"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Füllen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Unternehmensinformationen"
        }), " alle Felder aus. Diese Felder werden in Ihren Vorlagen-Listings angezeigt. Sie können Ihren Unternehmensnamen nicht mehr bearbeiten, sobald er ausgewählt wurde, und müssen sich an den HubSpot-Support wenden, um ihn zu ändern."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Füllen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Kontaktinformationen"
        }), " alle Felder in den beiden Abschnitten ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hauptkontakt"
        }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Entwicklerkontakt"
        }), " aus. Diese Informationen werden nur verwendet, wenn HubSpot Sie wegen eines Listings kontaktieren muss. Sie werden ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " auf den Listing-Seiten angezeigt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Zahlungsinformationen"
        }), " Ihre unterstützten Zahlungsmethoden hinzu. Sie können entweder PayPal oder Stripe als Zahlungsmethode verknüpfen. Wenn Ihre Zahlungsinformationen dazu führen, dass das Zahlungsgateway nicht mehr funktioniert, werden Ihre Vorlagen vorübergehend von der Liste entfernt, um negative Auswirkungen auf den Kunden zu vermeiden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorlagen-Listing erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So erstellen und übermitteln Sie ein Design- oder Modul-Listing:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem Anbieter-Account für den HubSpot-Marketplace für Vorlagen zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubSpot-Marketplace für Vorlagen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listing erstellen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie entweder ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design-Listing erstellen"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modul-Listing erstellen"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Überprüfen Sie die Informationen auf der Seite ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Anbieterinformationen"
        }), " und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Weiter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Überprüfen Sie Ihre Zahlungsinformationen auf der Seite ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Zahlungsinformationen"
        }), " und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Weiter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design oder Modul"
        }), " aus, das Sie listen möchten, und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Speichern und fortfahren"
        }), ". Sie werden dann zum Listing-Editor weitergeleitet."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/theme-listing-marketplace.png",
        alt: "theme-listing-marketplace"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Listing-Editor ist in fünf Registerkarten unterteilt: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Listing-Info"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Design-/Moduldetails"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Kategorie"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Support"
      }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Überprüfen"
      }), ". Es handelt sich um Pflichtfelder, sofern nicht anders angegeben: Überprüfen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/marketplace-guidelines/general-requirements",
        children: "hier"
      }), " alle Anforderungen an die Listing-Seite."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Validieren Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Überprüfen"
        }), " den Code Ihrer Vorlage und rufen Sie eine Vorschau Ihres Listings auf. Wenn Sie mit den Ergebnissen zufrieden sind, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zur Überprüfung einreichen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Ändern Sie den Namen des Vorlagenordners nicht, sobald Sie ihn eingereicht haben. Das Vorlagen-Listing wird bei der Einreichung direkt mit diesem Ordner verknüpft. Sie können die Vorlage nach dem Ändern des Ordnernamens nicht erneut einreichen oder aktualisieren."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorlagen-Listing aktualisieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie ein Vorlagen-Listing erstellt haben, können Sie es in Ihrem Anbieter-Account für den HubSpot-Marketplace für Vorlagen aktualisieren. Wenn Sie eine Vorlage geändert haben, muss diese erneut validiert werden, bevor die Änderungen angenommen und veröffentlicht werden. Ohne erneute Validierung werden nur die Inhalte auf Ihrer Marketplace-Listing-Seite aktualisiert."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So aktualisieren Sie ein Vorlagen-Listing:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie in Ihrem Anbieter-Account für den HubSpot-Marketplace für Vorlagen zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "HubSpot-Marketplace für Vorlagen"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Namen"
          }), " des Listings, das Sie aktualisieren möchten. Sie werden zum Listing-Editor weitergeleitet."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nehmen Sie alle erforderlichen Änderungen an den Listing-Informationen, Vorlagendetails, Kategorien und Supportoptionen vor und klicken Sie dann auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Überprüfen"
          }), ", um Ihre Änderungen zu überprüfen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Überprüfen Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Überprüfen"
          }), ", ob sich der Code Ihrer Vorlage seit der letzten Validierung geändert hat:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wenn Sie die Vorlage nicht geändert haben, wird in HubSpot eine Bestätigungsnachricht auf der Kachel ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Design-Quellcode"
            }), " oder auf der Kachel ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Modul-Quellcode"
            }), " angezeigt, aus der hervorgeht, dass Ihre Validierung auf dem neuesten Stand ist."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-validation-up-to-date.png",
            alt: "asset-marketplace-validation-up-to-date"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wenn Sie die Vorlage geändert haben, wird in HubSpot eine Warnung auf diesen Kacheln angezeigt. Um diese Änderungen an den Marketplace zu übermitteln, müssen Sie zunächst auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Validierung durchführen"
            }), " klicken."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-run-validation.png",
            alt: "asset-marketplace-run-validation"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "5."
      }), " Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Zur Überprüfung einreichen"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}