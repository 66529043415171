/* eslint-disable prefer-rest-params */
/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
function toInteger(argument) {
  // eslint-disable-next-line no-cond-assign
  return isNaN(argument = +argument) ? 0 : (argument > 0 ? Math.floor : Math.ceil)(argument);
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
function toAbsoluteIndex(index, length) {
  const integer = toInteger(index);
  return integer < 0 ? Math.max(integer + length, 0) : Math.min(integer, length);
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
function toLength(argument) {
  return argument > 0 ? Math.min(toInteger(argument), 0x1fffffffffffff) : 0; // 2 ** 53 - 1 == 9007199254740991
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
function requireObjectCoercible(it) {
  // eslint-disable-next-line eqeqeq
  if (it == undefined) throw TypeError(`Can't call method on ${it}`);
  return it;
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
function toObject(argument) {
  return Object(requireObjectCoercible(argument));
}

// https://github.com/zloirock/core-js
/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export default function fill(value /* , start = 0, end = @length */) {
  /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
  const O = toObject(this);
  const length = toLength(O.length);
  const argumentsLength = arguments.length;
  let index = toAbsoluteIndex(argumentsLength > 1 ? arguments[1] : undefined, length);
  const end = argumentsLength > 2 ? arguments[2] : undefined;
  const endPos = end === undefined ? length : toAbsoluteIndex(end, length);
  while (endPos > index) O[index++] = value;
  return O;
}